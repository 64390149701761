import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import AdPostedIcon from '@app/assets/icons/adPostedIcon.svg';

import UpSellingBanner from './upsellingBanner';

type Props = {
    packageSuccess?: boolean;
    packagesCount?: number;
};

const UpSellingAdSuccessBanner = ({ packageSuccess, packagesCount }: Props) => {
    const i18n = useI18n();
    const packageText = t(i18n)`${plural(packagesCount || 0, {
        zero: '',
        one: `Package`,
        two: `Packages`,
        few: `Packages`,
        many: `Packages`,
        other: `Packages`,
    })}`;

    const successBannerText = packageSuccess
        ? t(i18n)`${packageText} purchased successfully!`
        : t(i18n)`Your Ad has been uploaded successfully!`;

    return (
        <UpSellingBanner
            title={successBannerText}
            renderIcon={(iconStyle) => <AdPostedIcon className={iconStyle} />}
            ariaLabel={'Success message'}
        />
    );
};

export default UpSellingAdSuccessBanner;
