import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import { I18n } from '@lingui/core';

import { useLocalPrice } from 'strat/i18n/money';
import { LoadingSpinner } from 'strat/loadable';
import { useI18n } from 'strat/i18n/language';
import IconPositiveChange from '@app/assets/icons/iconPositiveChange_noinline.svg';
import IconNegativeChange from '@app/assets/icons/iconNegativeChange_noinline.svg';
import IconNoChange from '@app/assets/icons/iconNoChange_noinline.svg';
import EMPTY_OBJECT from 'strat/empty/object';

import { Area, Unit, UnitType, useLocalUnits } from '../i18n';
import Purpose from '../purpose';

import type { PropertyData } from './types';
import styles from './styles/propertyMostPopularCommunitiesSection.cssm';
import { fetchMostPopularCommunities } from './state';

type CommunityItem = {
    community: string;
    avgPricePerSqft: number;
    avgPricePerSqftChange: number;
};

/**
 * Gets the current quarter.
 */
const getCurrentQuarter = () => {
    const today = new Date();
    return Math.floor((today.getMonth() + 3) / 3);
};

/**
 * Gets the current year.
 */
const getCurrentYear = () => {
    const today = new Date();
    return today.getFullYear();
};

/**
 * Rounds a number to the hundreds. If smaller or equal to 100, returns it.
 */
const roundNumberToHundreds = (number: number) => {
    if (number <= 100) {
        return number;
    }
    return Math.round(number / 100) * 100;
};

/**
 * Builds up the small description string.
 */
const buildSmallDescriptionString = (
    i18n: I18n,
    bedroomCount: any,
    locationNames: any,
    categoryName: any,
) => {
    const locationName1 = locationNames[2];

    const bedsToken = t(i18n)`${plural(bedroomCount, {
        '=0': 'Studio',
        one: '# bed',
        two: '# bed',
        few: '# bed',
        many: '# bed',
        other: '# bed',
    })}`;

    return t(i18n)`with ${bedsToken} ${categoryName.toLowerCase()} in ${locationName1}`;
};

/**
 * Builds up the VS {quarter} string for the locations.
 */
const buildVersusString = (i18n: I18n) => {
    const currentQuarter = getCurrentQuarter();
    const currentYear = getCurrentYear();
    if (currentQuarter === 1) {
        return t(i18n)`VS Q${4} ${currentYear - 1}`;
    }
    return t(i18n)`VS Q${currentQuarter - 1} ${currentYear}`;
};

/**
 * Gets an icon corresponding to the change direction.
 */
const getPriceChangeIcon = (percentChange: number) => {
    if (percentChange === 0) {
        return <img src={IconNoChange} className={styles.priceChangeIcon} alt="iconNoChange" />;
    }
    if (percentChange < 0) {
        return (
            <img
                src={IconNegativeChange}
                className={styles.priceChangeIcon}
                alt="iconNegativeChange"
            />
        );
    }
    return (
        <img src={IconPositiveChange} className={styles.priceChangeIcon} alt="iconPositiveChange" />
    );
};

/**
 * Gets the average price per area unit string, in local currency, rounded to hundreds. If null, returns '-'.
 */
const getPricePerAreaUnitString = (toLocalPrice: any, areaUnit: any, avgPricePerSqft: number) => {
    if (avgPricePerSqft != null) {
        return roundNumberToHundreds(
            toLocalPrice(new Unit(avgPricePerSqft, UnitType.Area, areaUnit).convert(Area.SQFT)),
        ).toLocaleString();
    }
    return '-';
};

/**
 * Gets the table item for the price change. If the price change is null, returns a <td> containing '-'.
 */
const getPriceChangeTableItem = (avgPricePerSqftChange: number) => {
    if (avgPricePerSqftChange != null) {
        return (
            <td className={styles.locationsPercentChange}>
                <div className={styles.svgDiv}>
                    {getPriceChangeIcon(Math.round(avgPricePerSqftChange * 10) / 10)}
                </div>
                <span className={styles.percentText}>{avgPricePerSqftChange.toFixed(1)}%</span>
            </td>
        );
    }
    return (
        // reuse styling from price table item
        <td className={styles.locationsPrice}>-</td>
    );
};

type Props = {
    property: PropertyData;
    showNote?: boolean;
};

const PropertyMostPopularCommunitiesSection = ({ property, showNote = false }: Props) => {
    const dispatch = useDispatch();
    const { toLocalPrice } = useLocalPrice();
    const { areaUnit } = useLocalUnits();
    const i18n = useI18n();

    React.useEffect(() => {
        dispatch(
            // @ts-expect-error - TS2345 - Argument of type '() => Promise<{ data: any; status: number; }>' is not assignable to parameter of type 'Action'.
            fetchMostPopularCommunities({
                year: getCurrentYear(),
                quarter: getCurrentQuarter(),
            }),
        );
    }, [property.externalID, dispatch]);

    const mostPopularCommunitiesLoaded = useSelector(
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        (state) => state.mostPopularCommunities.loaded,
    );
    const mostPopularCommunitiesData = useSelector(
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        (state) => state.mostPopularCommunities.data || EMPTY_OBJECT,
    );

    if (!mostPopularCommunitiesLoaded) {
        return (
            <div className={styles.loading}>
                <LoadingSpinner />
            </div>
        );
    }
    if (
        !mostPopularCommunitiesData.communities ||
        mostPopularCommunitiesData.communities.length === 0
    ) {
        return null;
    }

    /**
     * Renders a single location item at the specified index.
     */
    const renderLocationItem = (communityItem: CommunityItem, index: number) => {
        return (
            <tr key={communityItem.community} className={styles.locationItem}>
                <td className={styles.locationsTitle}>
                    <span>{`${index + 1}   ${communityItem.community}`}</span>
                </td>
                <td className={styles.locationsPrice}>
                    {getPricePerAreaUnitString(
                        toLocalPrice,
                        areaUnit,
                        communityItem.avgPricePerSqft,
                    )}
                </td>
                {getPriceChangeTableItem(communityItem.avgPricePerSqftChange)}
            </tr>
        );
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.description}>
                <Trans>Popular locations**</Trans>
            </h3>
            <div className={styles.descriptionSmall}>
                {buildSmallDescriptionString(
                    i18n,
                    property.rooms,
                    property.labels.locationNames,
                    property.labels.categoryName,
                )}
            </div>
            <table className={styles.locations}>
                <thead className={styles.locationsHeader}>
                    <tr>
                        <th />
                        <th>
                            {Purpose.isForSale(property.purpose)
                                ? t(i18n)`Avg. price/${Area.abbreviation(i18n, areaUnit)}`
                                : t(i18n)`Avg. annual rent/${Area.abbreviation(i18n, areaUnit)}`}
                        </th>
                        <th>{buildVersusString(i18n)}</th>
                    </tr>
                </thead>
                <tbody>{mostPopularCommunitiesData.communities.map(renderLocationItem)}</tbody>
            </table>
            {showNote && (
                <span className={styles.note}>
                    {t(
                        i18n,
                    )`**Popularity is based on searches conducted by users on ${settings.getBrandName(
                        i18n,
                    )} over the last
                            quarter.`}
                </span>
            )}
        </div>
    );
};

export default PropertyMostPopularCommunitiesSection;
