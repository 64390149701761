import * as React from 'react';

import SentCheckMark from 'horizontal/assets/icons/iconCheck.svg';
import SeenCheckMark from 'horizontal/assets/icons/iconDoubleCheck.svg';

import styles from './styles/messageSeenStatus.cssm';

type Props = {
    readonly isSeen: boolean;
    readonly isReceived: boolean;
    readonly className?: string;
};

const MessageSeenStatus = ({ isSeen, isReceived, className }: Props) => {
    return (
        <div className={className || styles.default}>
            {isSeen || isReceived ? (
                <SeenCheckMark className={isSeen ? styles.blueCheck : styles.grayCheck} />
            ) : (
                <SentCheckMark className={styles.grayCheck} />
            )}
        </div>
    );
};

export default MessageSeenStatus;
