import { BackendSavedSearchParams } from '@app/search/savedSearches/constants';

import type { SavedSearchParams } from 'strat/search/savedSearches/types';
import { filterSavedSearchParams } from 'strat/search/savedSearches';
import { Area, ConversionTable } from 'strat/i18n';

import StratAPI from './strat';

class SavedSearchesAPI extends StratAPI {
    /**
     * Retrieves the list of saved searches of a user.
     */
    getSavedSearches(userID: string): Promise<any> {
        return super.request(`/api/user/${userID}/searches/saved/`).then(({ data, status }) => {
            if (status !== 200) {
                return { status, data: null };
            }

            return {
                status,
                data,
            };
        });
    }

    /**
     * Saves an entry of saved search.
     * Filters parameters required by the DjangoAPI.
     */
    addSavedSearch(
        userID: string,
        name: string,
        params: SavedSearchParams,
        convertAreaToSqm?: boolean,
    ): Promise<any> {
        const filteredSavedSearchParams = filterSavedSearchParams(params, BackendSavedSearchParams);
        if (convertAreaToSqm && filteredSavedSearchParams.area) {
            const conversionFunction = ConversionTable.get(Area.SQFT)?.get(Area.SQM);
            if (conversionFunction) {
                filteredSavedSearchParams.area = {
                    ...(filteredSavedSearchParams.area.min && {
                        min: conversionFunction(filteredSavedSearchParams.area.min),
                    }),
                    ...(filteredSavedSearchParams.area.max && {
                        max: conversionFunction(filteredSavedSearchParams.area.max),
                    }),
                };
            }
        }
        return super
            .post(`/api/user/${userID}/searches/saved/`, {
                ...filteredSavedSearchParams,
                name,
            })
            .then(({ data, status }) => {
                if (status !== 201) {
                    return { status, data: null };
                }

                return { status, data };
            });
    }

    /**
     * Removes a saved search of a user.
     */
    removeSavedSearch(userID: string, searchID: string | number): Promise<any> {
        return super.uncachedRequest(`/api/user/${userID}/searches/saved/${searchID}/`, null, {
            method: 'DELETE',
        });
    }
}

export default SavedSearchesAPI;
