import * as React from 'react';

import { useDialog } from 'strat/dialogs/dialogContext';
import DialogNames from 'strat/dialogs/dialogNames';
import type { KeycloakUser } from 'strat/auth/keycloak/user';

interface RegisterProps {
    stackGroup?: string;
    name?: string;
    onDone?: (arg1: KeycloakUser) => void;
    disableEmailLink?: boolean;
    disableFacebook?: boolean;
}

type UseRegisterDialog = [boolean, (visible: boolean, props?: RegisterProps) => void];

const useRegisterDialog = (): UseRegisterDialog => {
    const [visible, setVisible] = useDialog(DialogNames.REGISTER);
    return React.useMemo(() => [visible, setVisible], [visible, setVisible]);
};

export default useRegisterDialog;
