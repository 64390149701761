import * as React from 'react';

import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';

import { ThirdPartyProvider } from '../types';
import { LoginType, AuthFlow } from '../../types';

import useKeycloakThirdPartyLoginURL from './useKeycloakThirdPartyLoginURL';

type Props = {
    provider: ThirdPartyProvider;
    authFlow: AuthFlow;
    subscribeToMarketingEmails?: boolean;
};

type PopupOptions = {
    width: number;
    height: number;
};

/**
 * Computes the position of the popup window, centered to the current window.
 *
 * Do not modify this to use `window.screen` as that breaks multi-monitor
 * set ups.
 *
 * Do not modify this to use `window.innerWidth`, however tempting that is
 * to get correct behavior when the devtools are open. It breaks things
 * when the user is "zooming" in.
 */
const computePopupWindowCenterPosition = (
    desiredWidth: number,
    desiredHeight: number,
): [number, number] => {
    // window.top refers to the top-level window, might be different
    // from `window` in case we're in an iframe
    const currentWindow = window.top || window;

    // top/left are aliases of Y/X with various levels of browser support.
    const currentWindowY = currentWindow.screenY || currentWindow.screenTop;
    const currentWindowX = currentWindow.screenX || currentWindow.screenLeft;

    const top = currentWindow.outerHeight / 2 + currentWindowY - desiredHeight / 2;
    const left = currentWindow.outerWidth / 2 + currentWindowX - desiredWidth / 2;

    return [top, left];
};

const openPopup = (
    url: string,
    contextName: string,
    { width, height }: PopupOptions,
): Window | null => {
    const [top, left] = computePopupWindowCenterPosition(width, height);

    const options = [
        'popup=yes',
        'status=no',
        'location=no',
        'toolbar=no',
        'menubar=no',
        // Width, height, top, left are all ignored on mobile where browsers
        // just open a new tab/window instead.
        `width=${width}`,
        `height=${height}`,
        `top=${top}`,
        `left=${left}`,
    ];

    return window.open(url, contextName, options.join(','));
};

const determineLoginType = (provider: ThirdPartyProvider): LoginType => {
    switch (provider) {
        case ThirdPartyProvider.GOOGLE:
            return LoginType.GOOGLE_POPUP;

        case ThirdPartyProvider.FACEBOOK:
            return LoginType.FACEBOOK_POPUP;
    }

    throw new ContextfulError('Could not determine login type for thirdy party login', {
        provider,
    });
};

const useKeycloakThirdPartyLogin = ({ provider, authFlow, subscribeToMarketingEmails }: Props) => {
    const loginType = determineLoginType(provider);
    const loginURL = useKeycloakThirdPartyLoginURL(
        provider,
        loginType,
        authFlow,
        subscribeToMarketingEmails,
    );

    return React.useCallback((): LoginType => {
        const brandName = CONFIG.build.STRAT_BRAND_NAME;
        const envName = CONFIG.build.STRAT_ENVIRONMENT;
        const contextName = `${brandName}-${envName}-kc-${provider}`;

        try {
            const popupWindow = openPopup(loginURL, contextName, {
                width: 400,
                height: 600,
            });

            if (!popupWindow) {
                logError({
                    msg: 'Pop-up window for third party identity provider login did not open',
                    context: {
                        provider,
                        loginType,
                        url: loginURL,
                    },
                });

                window.location.href = loginURL;
                return loginType;
            }

            // We re-use the same context name to avoid opening multiple
            // windows when the user presses the button multiple times.
            //
            // In case the window is already open, re-focus it.
            popupWindow.focus();
        } catch (e) {
            logError({
                e,
                msg: 'Error while opening pop-up window for third party identity provider login',
                context: {
                    provider,
                    loginType,
                    url: loginURL,
                },
            });

            window.location.href = loginURL;
        }

        return loginType;
    }, [provider, loginType, loginURL]);
};

export default useKeycloakThirdPartyLogin;
