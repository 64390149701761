import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import type { Location } from 'horizontal/types';
import { selectLocationNameWithParent } from 'horizontal/location';
import { DropdownVariants } from 'horizontal/components';

import LocationDropdownWithSuggestions from './locationDropdownWithSuggestions';
import LocationRegionDropdown from './locationRegionDropdown';

type Props = {
    readonly title: string;
    readonly onChange: (arg1: Location) => void;
    readonly value?: Location;
    readonly choices: Array<Location>;
    readonly level: number;
    readonly hierarchy: Array<Location>;
    readonly readOnly?: boolean;
    readonly formatPlaceholder?: (title?: string) => string;
    readonly variant?: DropdownVariants;
    readonly lightPlaceholder?: boolean;
    readonly boldLabel?: boolean;
    readonly errorMessage?: string | undefined;
    readonly isMandatory?: boolean | null;
};

const LocationFieldLevel = ({
    title,
    onChange,
    value,
    choices: locationChoices,
    level,
    hierarchy,
    readOnly,
    formatPlaceholder,
    variant,
    lightPlaceholder,
    boldLabel,
    errorMessage,
    isMandatory,
}: Props) => {
    const i18n = useI18n();
    const choicesValues = React.useMemo(
        () =>
            locationChoices?.map((location: Location) => ({
                id: location.id,
                label:
                    level === 0
                        ? selectLocationNameWithParent(i18n, location.hierarchy)
                        : location.name,
                value: location.id,
            })),
        [locationChoices, i18n, level],
    );

    const onLocationChoiceSelected = React.useCallback(
        (locationID: Location['id']) => {
            if (!locationChoices) {
                return;
            }

            const selectedLocation = locationChoices.find(
                (location) => String(location.id) === String(locationID),
            );
            if (!selectedLocation) {
                return;
            }

            onChange(selectedLocation);
        },
        [locationChoices, onChange],
    );

    if (!locationChoices.length) {
        return null;
    }

    return level === 0 ? (
        <LocationRegionDropdown
            value={value ? selectLocationNameWithParent(i18n, value.hierarchy || [value]) : ''}
            title={title}
            name={title}
            choices={choicesValues}
            onLocationChoiceSelected={onLocationChoiceSelected}
            onFullLocationSelected={onChange}
            readOnly={readOnly}
            formatPlaceholder={formatPlaceholder}
            variant={variant}
            lightPlaceholder={lightPlaceholder}
            boldLabel={boldLabel}
            errorMessage={errorMessage}
            isMandatory={isMandatory}
        />
    ) : (
        <LocationDropdownWithSuggestions
            value={value?.id}
            title={title}
            name={title}
            choices={choicesValues}
            onChange={onLocationChoiceSelected}
            level={level}
            hierarchy={hierarchy}
            readOnly={readOnly}
            formatPlaceholder={formatPlaceholder}
            variant={variant}
            lightPlaceholder={lightPlaceholder}
            boldLabel={boldLabel}
            errorMessage={errorMessage}
            isMandatory={isMandatory}
        />
    );
};

export default LocationFieldLevel;
