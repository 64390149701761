import * as React from 'react';
import { t } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex, ListEntry } from 'strat/components';
import settings from '@app/branding/settings';

import styles from './styles/homeLink.cssm';

type Props = {
    onClick: (arg1: React.MouseEvent<any>) => void;
    renderLeft: () => JSX.Element;
};

const HomeLink = ({ onClick, renderLeft }: Props) => {
    const i18n = useI18n();

    const brand = `${settings.getAgencyPortalBrandName(i18n)} ${settings.countryName(i18n)}`;

    return (
        <Link route={RouteNames.HOME} title={t(i18n)`Open ${brand}`} onClick={onClick} openInNewTab>
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Flex alignCenter className={styles.container}>
                    <Text.Large>{t(i18n)`Open ${brand}`}</Text.Large>
                </Flex>
            </ListEntry>
        </Link>
    );
};

export default HomeLink;
