// A list of commonly used ad extra fields. This should be kept in sync
// with the backend enum with similar name.
const AdCommonExtraField = {
    ACCESSORY_TYPE: 'accessory_type',
    AGE: 'age',
    AREA: 'ft',
    AREA_UNIT: 'ft_unit',
    BATHROOMS: 'bathrooms',
    BEDROOMS: 'rooms',
    BUILDING_COMPOUND: 'compound',
    BUILDING_FEATURES: 'features',
    BUILDING_FURNISHING: 'furnished',
    BUILDING_FLOOR_LEVEL: 'floor_level',
    BUILDING_TOTAL_LEVELS: 'no_of_storeys',
    BUILDING_LOCATION: 'building_location',
    COLOR: 'color',
    CLOTHING_PURPOSE: 'clothing_purpose',
    COMPATIBILITY: 'compatibility',
    CONSTRUCTION_STATE: 'construction_state',
    DELIVERY_DATE: 'delivery_date',
    DOWN_PAYMENT: 'down_payment',
    EDUCATION_LEVEL: 'education_level',
    EMPLOYMENT_FORM: 'job_type',
    EMPLOYMENT_FIELD: 'job_field',
    EQUIPMENT_TYPE: 'equipment_type',
    EXPERIENCE_LEVEL: 'experience_level',
    GENDER: 'gender',
    INSURANCE_AMOUNT: 'insurance_amount',
    LANGUAGE: 'language',
    LOCATION_ZONING: 'zoning',
    NUMBER_PLATE_DIGITS: 'digits',
    PART_TYPE: 'part_type',
    PAYMENT_OPTION: 'payment_option',
    PET_TYPE: 'pet_type',
    PROPERTY_TYPE: 'property_type',
    PRICE: 'price',
    PRICE_TYPE: 'price_type',
    PURPOSE: 'purpose',
    RENTAL_DEPOSIT: 'deposit_required',
    RENTAL_OPTION: 'rental_option',
    RENTAL_INSURANCE: 'insurance',
    RENTAL_WITH_DRIVER: 'driver',
    RENTAL_PERIOD: 'rental_period',
    SALARY_FROM: 'salary_from',
    SALARY_TO: 'salary_to',
    SALES_TYPE: 'sales_type',
    SERVICE_TYPE: 'service_type',
    SECONDARY_PRICE: 'secondary_price',
    USAGE_CONDITION: 'new_used',
    TELCO_OPERATOR: 'operator',
    TELCO_MEMBERSHIP: 'membership',
    MANUFACTURING_BRAND: 'make',
    MANUFACTURING_MODEL: 'model',
    VACCINATED: 'vaccinated',
    VEHICLE_BODY_TYPE: 'body_type',
    VEHICLE_ENGINE_CAPACITY: 'engine_capacity',
    VEHICLE_FEATURES: 'extra_features',
    VEHICLE_FUEL_TYPE: 'petrol',
    VEHICLE_MILEAGE: 'mileage',
    VEHICLE_TRANSMISSION: 'transmission',
    VEHICLE_TYPE: 'vehicle_type',
    TYPE: 'type',
    WARRANTY: 'has_warranty',
    YEAR: 'year',
} as const;

export { AdCommonExtraField };
