type Callback<T extends unknown> = (signal: AbortSignal) => Promise<T>;

/**
 * Creates a signal using the {@see AbortController} that aborts after
 * the specified time passes.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 */
const withTimeoutSignal = async <T extends unknown>(
    timeout: number,
    callback: Callback<T>,
): Promise<T> => {
    const controller = new AbortController();
    const timer = setTimeout(() => controller.abort(), timeout);

    try {
        const result = await callback(controller.signal);

        clearTimeout(timer);

        return result;
    } catch (error) {
        clearTimeout(timer);
        throw error;
    }
};

export default withTimeoutSignal;
