import { useI18n } from 'strat/i18n/language';
import { buildAllCanonicalURLs } from 'strat/routing';
import type { AllCanonicalURLs } from 'strat/routing';
import createAdDetailsURL from '@app/routes/createAdDetailsURL';

import type { AdDetailsRouteParams } from 'horizontal/routes/createAdDetailsURL';

const useCanonicalURL = (
    params?: AdDetailsRouteParams | null,
): AllCanonicalURLs | null | undefined => {
    const i18n = useI18n();

    if (!params) {
        return null;
    }

    const enhancedLocation = createAdDetailsURL(params);
    // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
    return buildAllCanonicalURLs(enhancedLocation, i18n.locale);
};

export default useCanonicalURL;
