import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language/selectors';

import { selectCategories } from 'horizontal/categories';
import type {
    Location,
    LocationChoices,
    Category,
    LiteHierarchicalLocation,
} from 'horizontal/types';
import { getCategoryIDsDescendants } from 'horizontal/search/location/disabledLocations';
import {
    fetchLocationsByParent,
    fetchMultipleLocationsByExternalID,
} from 'horizontal/search/location';

type LocationState = {
    location: Location | null;
    index: number;
};

type LocationWithHierarchyState = {
    selectedLocation: Location | null;
    onLocationSelect: (location: Location, index: number) => void;
    locationsHierarchy: Array<Location>;
    locationChoicesList: LocationChoices;
};

const locationHasEnabledChildren = (
    hierarchy: Array<Location>,
    choices: LocationChoices,
    retrieveEnabledLocations: (arg1: Array<Location>, arg2: Category) => Array<Location>,
    activeCategory: Category,
): boolean => {
    // if there are no choices then there are no children
    if (choices.length === 0) {
        return false;
    }
    // if the latest choice list contains only disabled children, then there are no actual children to choose from
    const latestChoice = retrieveEnabledLocations(choices[choices.length - 1], activeCategory);
    if (latestChoice.length === 0) {
        return false;
    }
    // if there are choices, and you only had the country chosen then there are certainly children to choose from
    const filteredHierarchy = hierarchy.filter((item) => item.level !== 0);
    if (filteredHierarchy.length === 0) {
        return true;
    }
    // the last list of choices should have items with higher levels than the last entry from the hierarchy
    const lastChosenLevel = filteredHierarchy[filteredHierarchy.length - 1].level;
    const latestChoiceLevel = latestChoice[latestChoice.length - 1].level;

    return latestChoiceLevel > lastChosenLevel;
};

const useLocationWithHierarchy = (
    initialLocation: LiteHierarchicalLocation | null,
    activeCategory?: Category | null,
    onSetLocation?: (locationObject?: Location) => void,
): LocationWithHierarchyState => {
    /**
     * [
     *  This hook is responsible for handling the state of location hierarchy together with the choices of each location.level.
     *  It can be used together with LocationField component. Currently used in Post/Update Ad Pages, and addresses section.
     * ]
     * @param  {LiteHierarchicalLocation | null } initialLocation [the initial selected location]
     * @param  {?Category} activeCategory   [the current active category, can be optional]
     * @param  {(locationObject?: Location) => void} onSetLocation [callback to be fired when the selected location is changed]
     */
    const language = useSelector(selectLanguage);
    const categories = useSelector(selectCategories);
    const backend = useSelector(selectActiveSearchBackend);

    const [locationsHierarchy, setLocationsHierarchy] = React.useState<Array<Location>>([]);
    const [locationChoicesList, setChoices] = React.useState([]);

    /**
     The index is required due to business needs. The first dropdown allows us to select location from any level
     Thus this can make the hierarchy smaller than the current level in terms of level, so there are times where we
     need to slice in a specific place to create the desired output.
    */
    const [selectedLocation, setSelectedLocation] = React.useState<LocationState>({
        location: null,
        index: -1,
    });

    React.useEffect(() => {
        if (initialLocation?.externalID) {
            fetchMultipleLocationsByExternalID(
                [initialLocation.externalID],
                language,
                // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
                backend,
                1,
            ).then((data: Array<Location>) => {
                if (data.length) {
                    setSelectedLocation((prevState) => ({
                        index: prevState.index,
                        location: data[0],
                    }));
                }
            });
        }
    }, [backend, language, onSetLocation, initialLocation]);

    const onLocationSelect = React.useCallback(
        (location: Location, index: number): void => {
            setSelectedLocation(() => ({
                location,
                index,
            }));
            onSetLocation?.(location);
        },
        [onSetLocation],
    );

    React.useEffect(() => {
        if (selectedLocation.location && locationsHierarchy.length === 0) {
            const { location } = selectedLocation;
            const promises = location.hierarchy.map((l) =>
                // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
                fetchLocationsByParent(l, language, backend),
            );
            Promise.all(promises).then((values) => {
                const externalIDs = location.hierarchy.map((item) => item.externalID);
                // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
                fetchMultipleLocationsByExternalID(externalIDs, language, backend, 8).then(
                    (response) => {
                        const hierarchy = response;
                        // we wanna exclude level=0 when we edit an ad
                        // we check the below in order to avoid infinite re-renders
                        if (hierarchy.length > 1) {
                            hierarchy.shift();
                        }

                        setLocationsHierarchy(hierarchy);
                        const filteredValues = values.filter((item) => item.length);
                        // @ts-expect-error - TS2345 - Argument of type '(readonly SearchResponseHit[])[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
                        setChoices(filteredValues);
                    },
                );
            });
        }
    }, [backend, language, locationsHierarchy, selectedLocation]);

    const retrieveEnabledLocations = React.useCallback(
        (locations: Array<Location>, category: Category) => {
            return locations.filter((item: Location) => {
                if (!item.disabledCategoryIDs) {
                    return true;
                }
                const disabledCategoryIDs = getCategoryIDsDescendants(
                    item.disabledCategoryIDs,
                    categories,
                    true,
                );
                return !disabledCategoryIDs.includes(category.id);
            });
        },
        [categories],
    );

    React.useEffect(() => {
        const { location, index } = selectedLocation;

        if (location?.externalID) {
            // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
            fetchLocationsByParent(location, language, backend).then((locationChoices) => {
                // Default behaviour for setting the state
                setLocationsHierarchy((prevState) => [...prevState.slice(0, index), location]);

                if (locationChoices.some((item) => item.disabledCategoryIDs) && activeCategory) {
                    const enabledLocations = retrieveEnabledLocations(
                        locationChoices,
                        activeCategory,
                    );
                    if (enabledLocations.length) {
                        // @ts-expect-error - TS2345 - Argument of type '(prevState: never[]) => Location[][]' is not assignable to parameter of type 'SetStateAction<never[]>'.
                        setChoices((prevState) => [
                            ...prevState.slice(0, index + 1),
                            enabledLocations,
                        ]);
                    } else {
                        // if we have disabled children we wanna set Form value to pass the upper validation
                        setLocationsHierarchy((prevState) => [
                            ...prevState.slice(0, index),
                            location,
                        ]);
                        setChoices((prevState) => [...prevState.slice(0, index + 1)]);
                        onSetLocation?.(location);
                        return;
                    }
                }

                if (!locationChoices.length) {
                    // equivelant to !parentLocation.hasChildren
                    setChoices((prevState) => [...prevState.slice(0, index + 1)]);
                    return;
                }

                // @ts-expect-error - TS2345 - Argument of type '(prevState: never[]) => (readonly SearchResponseHit[])[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
                setChoices((prevState) => [...prevState.slice(0, index + 1), locationChoices]);
            });
        }
    }, [
        selectedLocation,
        backend,
        language,
        activeCategory,
        onSetLocation,
        categories,
        retrieveEnabledLocations,
    ]);

    // We wanna empty Form location_id in 2 cases.
    // In order for this effect to properly work, both locationsHierarchy and locationChoicesList
    // have to be consistent, so pay attention to the order of these 2 being updated with their setters
    // which can trigger this effect to run.
    React.useEffect(() => {
        const lastLocation = locationsHierarchy[locationsHierarchy.length - 1];
        if (lastLocation?.level + 1 > 0 && activeCategory) {
            const newLocationsHierarchy = retrieveEnabledLocations(
                locationsHierarchy,
                activeCategory,
            );
            // 1st case, when the new hierarchy is smaller than the current one based on the new category
            const newLastLocation = newLocationsHierarchy[newLocationsHierarchy.length - 1];
            if (newLastLocation?.level + 1 < locationsHierarchy.length) {
                onSetLocation?.();
                setLocationsHierarchy(newLocationsHierarchy);
                setChoices(
                    // @ts-expect-error - TS2345 - Argument of type '(prevState: never[]) => LocationChoices' is not assignable to parameter of type 'SetStateAction<never[]>'.
                    (prevState): LocationChoices =>
                        prevState.slice(0, newLocationsHierarchy.length),
                );
                setSelectedLocation(() => ({
                    location: newLocationsHierarchy[newLocationsHierarchy.length - 1],
                    index: newLocationsHierarchy.length - 1,
                }));
            }
            // 2nd case: when lastLocation has children and their children are not disabled for this category
            // TODO: This validation should be refactored and moved outside this effect.
            else if (
                newLastLocation?.hasChildren &&
                locationHasEnabledChildren(
                    newLocationsHierarchy,
                    locationChoicesList,
                    retrieveEnabledLocations,
                    activeCategory,
                ) &&
                !(newLastLocation.disabledCategoryIDs || []).includes(activeCategory.id) &&
                (activeCategory.locationDepthLimits == null ||
                    activeCategory.locationDepthLimits.min == null ||
                    newLastLocation.level <= activeCategory.locationDepthLimits.min)
            ) {
                onSetLocation?.();
            } else {
                // put back the location to Formik just in case it was previously
                // removed due to incomplete data
                onSetLocation?.(lastLocation);
            }
        }
    }, [
        activeCategory,
        locationsHierarchy,
        retrieveEnabledLocations,
        categories,
        onSetLocation,
        locationChoicesList,
        selectedLocation,
    ]);

    return {
        selectedLocation: selectedLocation.location,
        onLocationSelect,
        locationsHierarchy,
        locationChoicesList,
    };
};

export default useLocationWithHierarchy;
