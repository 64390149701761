import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { FilterValues } from 'strat/search';

import { SearchPageSearchType } from '../types';

const processLocationFilter = (
    filterCollection: FilterCollection,
    searchPageType: Values<typeof SearchPageSearchType>,
): FilterCollection => {
    switch (searchPageType) {
        case SearchPageSearchType.CIRCLE_BASED_SLOTTING:
        case SearchPageSearchType.PAID_FIRST_SLOTTED:
        case SearchPageSearchType.PAID_FIRST:
            // location should not be in the filters because it's not the same for all slots
            return filterCollection.copy([FilterValues.location.attribute]);
        default:
            return filterCollection;
    }
};

export default processLocationFilter;
