import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { Text, ListEntry } from 'strat/components';
import { useSelector } from 'react-redux';
import IconEyeLight from '@app/assets/icons/iconEyeLight.svg';
import { useActiveUser } from 'strat/user/session';
import { trigger, Triggers } from 'strat/gtm';

import { selectIsMyProfileLoading } from 'horizontal/profile/state';
import useMyProfile from 'horizontal/profile/hooks/useMyProfile';
import { AgencyUserDTO } from 'horizontal/dtos';

type FetchParams = { agencySlug: string } | { externalID: string };

const renderLeft = () => <IconEyeLight />;

const PublicProfileLink = ({ onClick }: { onClick: (event: React.MouseEvent<any>) => void }) => {
    const [params, setParams] = React.useState<FetchParams>();

    const i18n = useI18n();
    const user = useActiveUser();
    const isLoading = useSelector(selectIsMyProfileLoading);
    const { profile, isAgency } = useMyProfile();

    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trigger(Triggers.VIEW_PUBLIC_PROFILE);
            onClick(event);
        },
        [onClick],
    );

    React.useEffect(() => {
        if (!user) {
            return;
        }
        if (!isAgency) {
            setParams({ externalID: user.externalID });
        } else if (!isLoading && profile) {
            setParams({ agencySlug: (profile as AgencyUserDTO).slug });
        }
    }, [user, isAgency, isLoading, profile]);

    if (!user) {
        return null;
    }

    const isEnabled = !isAgency || (!isLoading && !!profile);

    return (
        <Link
            route={isAgency ? RouteNames.AGENCY_PROFILE : RouteNames.SELLER_PROFILE}
            params={params}
            title={t(i18n)`Public Profile`}
            onClick={onTrackedClick}
            enabled={isEnabled}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>{t(i18n)`Public Profile`}</Text.Large>
            </ListEntry>
        </Link>
    );
};

export default PublicProfileLink;
