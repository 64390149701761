import * as React from 'react';
import classNames from 'classnames';

import { OffScreen } from 'strat/modal';
import { stopSyntheticEventPropagation } from 'strat/util';

import styles from './styles/drawer.cssm';

const defaultRenderOverlay = (
    visible: boolean,
    onVisibilityChanged: (visibility: boolean) => void,
) => (
    <div
        className={classNames(styles.overlay, { [styles.visible]: visible })}
        onClick={(event) => {
            onVisibilityChanged(false);
            stopSyntheticEventPropagation(event);
        }}
    />
);

const Drawer = ({
    name,
    visible,
    children,
    defaultHeight = 240,
    defaultHeightUnit = 'px',
    forceHeight = false,
    renderHeader,
    onVisibilityChanged,
    renderOverlay = defaultRenderOverlay,
    expandable = true,
    isFullScreen = false,
    setIsFullScreen,
    className,
}: {
    name: string;
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
    children: React.ReactNode;
    defaultHeight?: number;
    defaultHeightUnit?: string;
    forceHeight?: boolean;
    renderOverlay?: (arg1: boolean, arg2: (arg1: boolean) => void) => React.ReactElement;
    renderHeader?: () => React.ReactElement;
    expandable?: boolean;
    isFullScreen: boolean;
    setIsFullScreen: (arg1: boolean) => void;
    className?: string;
}) => {
    const [isFullScreenAtTouchStart, setIsFullScreenAtTouchStart] = React.useState(isFullScreen);
    const [previousTouch, setPreviousTouch] = React.useState({ touch: null, time: null });

    // When hidden, revert the fullScreen state.
    React.useEffect(() => {
        if (!visible) {
            setIsFullScreen(false);
        }
    }, [visible, setIsFullScreen]);

    // Remember whether the drawer was fullscreen when starting to swipe.
    // This is used to make sure we don't directly hide the drawer when the user
    // just wants to minimize it.
    const onTouchStart = React.useCallback(() => {
        setIsFullScreenAtTouchStart(isFullScreen);
    }, [isFullScreen]);

    const onTouchMove = React.useCallback(
        (event, targetVelocity = 1) => {
            const touches = event.touches;
            if (touches.length !== 1) {
                return;
            }

            const currentTouch = touches[0];
            if (!previousTouch.touch) {
                // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
                setPreviousTouch({ touch: currentTouch, time: performance.now() });
                return;
            }

            const now = performance.now();
            const velocity =
                // @ts-expect-error - TS2339 - Property 'clientY' does not exist on type 'never'. | TS2531 - Object is possibly 'null'.
                (currentTouch.clientY - previousTouch.touch.clientY) / (now - previousTouch.time);

            if (Math.abs(velocity) < targetVelocity) {
                // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
                setPreviousTouch({ touch: currentTouch, time: now });
                return;
            }

            if (velocity <= 0 && !isFullScreenAtTouchStart && expandable) {
                setIsFullScreen(true);
            }

            if (velocity > 0) {
                if (isFullScreenAtTouchStart) {
                    setIsFullScreen(false);
                } else {
                    onVisibilityChanged(false);
                }
            }

            // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
            setPreviousTouch({ touch: currentTouch, time: now });
        },
        [setIsFullScreen, previousTouch, expandable, isFullScreenAtTouchStart, onVisibilityChanged],
    );

    const containerStyle = React.useMemo(() => {
        if (!expandable && visible) {
            return {
                transform: 'translateY(0)',
                height: forceHeight ? `${defaultHeight}${defaultHeightUnit}` : 'auto',
            };
        }

        if (!expandable && !visible) {
            return { transform: `translateY(100%)` };
        }

        if (!visible && !isFullScreen) {
            return { transform: 'translateY(100%)' };
        }

        if (!isFullScreen) {
            return { transform: `translateY(calc(100% - ${defaultHeight}${defaultHeightUnit}))` };
        }

        return { transform: `translateY(0)` };
    }, [visible, isFullScreen, defaultHeight, defaultHeightUnit, expandable, forceHeight]);

    return (
        <OffScreen name={name}>
            {renderOverlay && renderOverlay(visible, onVisibilityChanged)}
            <div
                className={classNames(
                    styles.container,
                    {
                        [styles.visible]: visible,
                        [styles.expandable]: expandable,
                    },
                    className,
                )}
                style={containerStyle}
            >
                <div
                    className={styles.header}
                    onTouchMove={onTouchMove}
                    onTouchStart={onTouchStart}
                >
                    {renderHeader && renderHeader()}
                </div>
                {children}
            </div>
        </OffScreen>
    );
};

export default Drawer;
