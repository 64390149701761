import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import { useFormattedPrice } from 'horizontal/util';

import PriceLabel from '../priceLabel';
import RejectedLabel from '../rejectedLabel';

import styles from './styles/sellerMadeOfferSeller.cssm';

type Props = {
    readonly lastRejectedPrice: number;
    readonly lastOfferPrice: number;
};

const SellerMadeOffer = ({ lastOfferPrice, lastRejectedPrice }: Props) => {
    const formattedLastRejectedPrice = useFormattedPrice(lastRejectedPrice);
    return (
        <Flex column fillContainer alignCenter justifyCenter className={styles.container}>
            <Flex>
                <Text.Regular uppercase nowrap>
                    <Trans>{`Buyer's offer: ${formattedLastRejectedPrice}`}</Trans>
                </Text.Regular>
                <RejectedLabel />
            </Flex>
            <Text.Base bold uppercase className={styles.title}>
                <Trans>Your counter offer</Trans>
            </Text.Base>
            <PriceLabel price={lastOfferPrice} />
            <Text.Regular>
                <Trans>{"Waiting for buyer's response"}</Trans>
            </Text.Regular>
        </Flex>
    );
};

export default React.memo<Props>(SellerMadeOffer);
