import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { StylelessButton } from 'strat/generic';
import { useDialog } from 'strat/dialogs/dialogContext';

import { CategoryImage, selectCategories } from 'horizontal/categories';
import { Category, LiteCategory } from 'horizontal/types';
import { findDeepList } from 'horizontal/util/findDeep';
import DialogNames from 'horizontal/dialogs/dialogNames';
import Context from 'horizontal/adCreation/context';

import styles from './styles/categoryFormField.cssm';

interface ReadonlyCategoryFormFieldProps {
    activeCategory?: Category | LiteCategory | null;
    iconClassName?: string;
    iconContainerClassName?: string;
}

export const ReadonlyCategoryFormField = ({
    activeCategory,
    iconClassName,
    iconContainerClassName,
}: ReadonlyCategoryFormFieldProps) => {
    const categories = useSelector(selectCategories);

    const { parentCategory, childCategories } = React.useMemo(() => {
        const categoryPath = findDeepList(
            categories,
            (category) => category.externalID === activeCategory?.externalID,
            true,
        );

        const [parentCategory, ...childCategories] = categoryPath;

        return {
            parentCategory,
            childCategories: childCategories.map((category) => category.name).join(' / '),
        };
    }, [categories, activeCategory]);

    return (
        <Flex>
            <CategoryImage
                category={parentCategory as LiteCategory}
                imageClassName={iconClassName ?? styles.icon}
                containerClassName={iconContainerClassName ?? styles.categoryIconContainer}
            />
            <Flex column justifySpaceAround>
                <Text.Regular bold>{parentCategory.name}</Text.Regular>
                <Text.Small>{childCategories}</Text.Small>
            </Flex>
        </Flex>
    );
};

interface CategoryFormFieldProps extends Omit<ReadonlyCategoryFormFieldProps, 'activeCategory'> {
    onCategoryChangeRequested?: () => void;
}

export const CategoryFormField = ({
    onCategoryChangeRequested,
    iconClassName,
    iconContainerClassName,
}: CategoryFormFieldProps) => {
    const [, setDialogVisible] = useDialog(DialogNames.POST_AD_CATEGORY_PICKER_DIALOG);
    const activeCategoryContext = React.useContext(Context);
    const [activeCategory] = activeCategoryContext;

    const onCategoryChangePress = () => {
        onCategoryChangeRequested?.();
        setDialogVisible(true, {
            onLeafCategorySelected: () => setDialogVisible(false),
            activeCategoryContext,
        });
    };

    return (
        <Flex fillContainer justifySpaceBetween>
            <ReadonlyCategoryFormField
                activeCategory={activeCategory}
                iconClassName={iconClassName}
                iconContainerClassName={iconContainerClassName}
            />
            <StylelessButton onClick={onCategoryChangePress}>
                <Text.Regular className={styles.changeButton}>
                    <Trans>Change</Trans>
                </Text.Regular>
            </StylelessButton>
        </Flex>
    );
};
