const getItem = (storageKey: string) => {
    if (process.env.IS_SERVER) {
        return null;
    }

    let data = null;

    try {
        const storageData = sessionStorage.getItem(storageKey);
        if (storageData) {
            data = JSON.parse(storageData);
        }
    } catch (err: any) {
        console.warn(
            `Tried to get sessionStorage key "${storageKey}" from session storage: ${JSON.stringify(
                err,
            )}`,
        );
    }

    return data;
};

const setItem = (storageKey: string, data: any) => {
    if (process.env.IS_SERVER) {
        return;
    }

    try {
        sessionStorage.setItem(storageKey, JSON.stringify(data));
    } catch (err: any) {
        console.warn(`Tried to setSession storage key "${storageKey}": ${JSON.stringify(err)}`);
    }
};

const removeItem = (storageKey: string) => {
    if (process.env.IS_SERVER) {
        return;
    }

    try {
        sessionStorage.removeItem(storageKey);
    } catch (err: any) {
        console.warn(`Tried to remove sessionStorage key "${storageKey}": ${JSON.stringify(err)}`);
    }
};

export { getItem, setItem, removeItem };
