import brandingSettings from '@app/branding/settings';
import { determineLocationsIndexName } from 'strat/search/indexNames';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';

import { LocationNodeData } from 'horizontal/property';

import { MatchParams } from '../types';

const searchLocationFromMatchParams = (
    params: MatchParams,
    language: string,
    activeSearchBackend: SearchBackend,
): Promise<SearchResponse<LocationNodeData>[]> => {
    const { categoryFieldsWithLocationSlug, locationSlug, locationExternalID } = params;

    const jobs: Array<SearchJob> = [];

    const realLocationFilter = new FilterCollection();
    if (locationExternalID) {
        realLocationFilter.refine(
            new ExactFilter({
                attribute: 'externalID',
                value: locationExternalID,
            }),
        );
    } else {
        realLocationFilter.refine(
            new ExactFilter({
                attribute: 'slug',
                value: locationSlug || brandingSettings.topLevelLocation.slug,
            }),
        );
    }

    const jobForRealLocation = new SearchJob(
        determineLocationsIndexName({ language }),
        realLocationFilter,
        {
            hitsPerPage: 1,
            exhaustiveNbHits: false,
        },
    );
    jobs.push(jobForRealLocation);

    if (categoryFieldsWithLocationSlug) {
        const categoryFieldsWithLocation = categoryFieldsWithLocationSlug.split('/');
        const jobNeededToDisambiguateMakeVsLocation = new SearchJob(
            determineLocationsIndexName({ language }),
            new FilterCollection().refine(
                new ExactFilter({
                    attribute: 'slug',
                    value: categoryFieldsWithLocation.slice(-1)[0],
                }),
            ),
            {
                hitsPerPage: 1,
                exhaustiveNbHits: false,
            },
        );
        jobs.push(jobNeededToDisambiguateMakeVsLocation);
    }

    return new SearchService({ backend: activeSearchBackend }).fetchJobs(jobs) as Promise<
        SearchResponse<LocationNodeData>[]
    >;
};

export default searchLocationFromMatchParams;
