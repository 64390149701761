import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import useI18n from 'strat/i18n/language/useI18n';

import AdStatePill from 'horizontal/adManagement/statePill';
import { FullAd } from 'horizontal/types';

import useFetchUserAds from './hooks/useFetchUserAds';
import styles from './styles/telesalesTool.cssm';
import TeleSalesTable from './teleSalesTable';

type Props = Readonly<{
    userExternalID: string;
    selectedAdExternalID: string | undefined;
    onAdSelectionChange: (ad: FullAd | undefined) => unknown;
    onError: (error: string | undefined) => unknown;
}>;

const UserAdsListCard = ({
    userExternalID,
    selectedAdExternalID,
    onAdSelectionChange,
    onError,
}: Props) => {
    const i18n = useI18n();
    const [loading, setLoading] = React.useState(false);
    const { userAds, fetchUserAds } = useFetchUserAds(setLoading, onError);

    const handleAdSelectionChange = React.useCallback(
        (adExternalID: string | undefined) => {
            let selectedAd: FullAd | undefined;

            if (adExternalID) {
                selectedAd = userAds.find((ad) => ad.externalID == adExternalID);
            }

            onAdSelectionChange(selectedAd);
        },
        [userAds, onAdSelectionChange],
    );

    React.useEffect(() => {
        fetchUserAds(userExternalID);
    }, [userExternalID, fetchUserAds]);

    React.useEffect(() => {
        handleAdSelectionChange(undefined);
    }, [userAds, handleAdSelectionChange]);

    return (
        <Flex column className={styles.cardElement}>
            <TeleSalesTable
                loading={loading}
                setSelectedItem={handleAdSelectionChange}
                items={userAds}
                headers={[
                    { text: t(i18n)`Ad External ID`, value: 'externalID' },
                    {
                        text: t(i18n)`State`,
                        value: (ad: FullAd) => <AdStatePill ad={ad} />,
                    },
                    { text: t(i18n)`Ad Title`, value: 'title' },
                    {
                        text: t(i18n)`Category`,
                        value: (ad) => ad.category[ad.category.length - 1].name,
                    },
                ]}
                itemKey="externalID"
                radioInputName="myAd"
                tableTitle={t(i18n)`Choose the targeted ad`}
                emptyTableMessage={t(i18n)`There are no ads`}
                selectedItem={selectedAdExternalID}
            />
        </Flex>
    );
};

export default React.memo(UserAdsListCard);
