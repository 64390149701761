import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikErrors, FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import Category from '@app/branding/category';

import {
    CategoryFieldValueType,
    type CategoryField,
    type Category as CategoryType,
} from 'horizontal/types';
import { ExtraFields, MileageField } from 'horizontal/fields/commonPostingFields';

const MILEAGE_LIMIT = 1000;
const AGE_LIMIT = 1;

const useCarsFormWarnings = (
    values: FormikValues,
    errors: FormikErrors<FormikValues>,
    category: CategoryType | null | undefined,
    flatCategoryFields: CategoryField[],
) => {
    const i18n = useI18n();
    const [warnings, setWarnings] = React.useState<Record<string, string>>({});
    const categoryFieldsMap = React.useMemo(
        () =>
            flatCategoryFields.reduce(
                (acc, field) => ({ ...acc, [field.attribute]: field }),
                {} as Record<string, CategoryField>,
            ),
        [flatCategoryFields],
    );

    React.useEffect(() => {
        if (!Category.isOfCarsType(category)) {
            return;
        }
        const currentYear = new Date().getFullYear();

        const hasValues = !!(values[MileageField.attribute] && values[ExtraFields.year.attribute]);
        const hasErrors = !!(errors[MileageField.attribute] && errors[ExtraFields.year.attribute]);
        const isMileageFieldInteger =
            categoryFieldsMap[MileageField.attribute]?.valueType === CategoryFieldValueType.INTEGER;
        const mileageRequiresWarning =
            isMileageFieldInteger &&
            values[MileageField.attribute] <= MILEAGE_LIMIT &&
            currentYear - values[ExtraFields.year.attribute] >= AGE_LIMIT;
        const showMileageWarning = hasValues && !hasErrors && mileageRequiresWarning;

        if (showMileageWarning) {
            if (!warnings[MileageField.attribute]) {
                setWarnings({
                    [MileageField.attribute]: t(
                        i18n,
                    )`Make sure you typed the right amount from your car's dashboard.`,
                });
            }
        } else if (Object.keys(warnings).length) {
            setWarnings({});
        }
    }, [values, errors, warnings, category, i18n, categoryFieldsMap]);

    return warnings;
};

export default useCarsFormWarnings;
