import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import Values from './values';

export type SortByChoice = {
    name: string;
    value: string;
};

/* The choices that are visible for the user */
const choices = (
    i18n: I18n,
    verifiedSortingEnabled?: boolean | null,
    developerSortingEnabled?: boolean | null,
): Array<SortByChoice> => [
    { name: t(i18n)`Popular`, value: '' },
    ...(verifiedSortingEnabled
        ? [{ name: t(i18n)`TruCheck™`, value: Values.VERIFIED_SCORE }]
        : []),
    ...(developerSortingEnabled
        ? [{ name: t(i18n)`Developer`, value: Values.PRODUCT_LABEL_SCORE }]
        : []),
    { name: t(i18n)`Newest`, value: Values.DATE_DESC },
    { name: t(i18n)`Lowest Price`, value: Values.PRICE_ASC },
    { name: t(i18n)`Highest Price`, value: Values.PRICE_DESC },
];

export default choices;
