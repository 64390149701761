import { getDeeplinkCookie } from 'strat/header/compact/bannerUtils';

import Actions from './actions';

type AppInstallBannersState = {
    isBottomBannerDismissed: boolean;
    isFifthDpv: boolean;
};

const defaultState: AppInstallBannersState = {
    isBottomBannerDismissed: false,
    isFifthDpv: false,
};

const appInstallBannersReducer = (
    state: AppInstallBannersState | null | undefined = defaultState,
    action: any,
) => {
    switch (action.type) {
        case Actions.DISMISS_BOTTOM_BANNER:
            return {
                ...state,
                isBottomBannerDismissed: true,
            };
        case Actions.UPDATE_BOTTOM_BANNER_STATE: {
            const isCookieValueDivisbleByFive = getDeeplinkCookie() % 5 == 0;
            return {
                ...state,
                isFifthDpv: isCookieValueDivisbleByFive,
            };
        }

        default:
            return state;
    }
};

export type { AppInstallBannersState };
export default appInstallBannersReducer;
