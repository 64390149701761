import * as React from 'react';
import { Flex, LoadingAnimation } from 'strat/components';
import classNames from 'classnames';

import styles from './styles/listEntry.cssm';
import placeholderStyles from './styles/loadingMyAdCard.cssm';

const LoadingMyAdCard = () => {
    return (
        <Flex className={styles.container}>
            <Flex className={styles.ad} fillContainer>
                <LoadingAnimation className={classNames(styles.image, placeholderStyles.image)} />
                <Flex column fillContainer className={styles.rightContainer}>
                    <Flex alignCenter stretchWidth className={styles.headerWrapper}>
                        <LoadingAnimation width={'20rem'} height={'3rem'} />
                        <LoadingAnimation width={'10rem'} height={'2rem'} />
                    </Flex>
                    <Flex
                        alignCenter
                        className={classNames(styles.detailsWrapper, placeholderStyles.details)}
                        wrap
                    >
                        <LoadingAnimation width={'14.2rem'} height={'2rem'} />
                        <LoadingAnimation width={'22rem'} height={'2rem'} />
                        <Flex className={placeholderStyles.state}>
                            <LoadingAnimation className={placeholderStyles.pill} />
                            <LoadingAnimation width={'14rem'} height={'2rem'} />
                        </Flex>
                    </Flex>
                    <Flex justifySpaceBetween stretchWidth wrap className={styles.bottomWrapper}>
                        <Flex className={styles.analytics}>
                            <LoadingAnimation width={'45rem'} height={'3.2rem'} />
                        </Flex>
                        <Flex className={styles.ctaWrapper}>
                            <LoadingAnimation className={placeholderStyles.button} />
                            <LoadingAnimation className={placeholderStyles.button} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default LoadingMyAdCard;
