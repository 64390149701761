import { useIsProfolioUser } from 'strat/user';

import { MixedAd } from 'horizontal/types';
import { AdFormat, AdVirtualState } from 'horizontal/types';

const useShouldShowOwnAdSection = (isOwnAd: boolean, ad: MixedAd | null | undefined) => {
    const isProfolioUser = useIsProfolioUser();

    if (!ad) {
        return {
            shouldShowOwnAdSection: false,
            shouldShowOwnAdAnalytics: false,
            shouldShowOwnAdBanner: false,
        };
    }

    const postedAdStates = [
        AdVirtualState.ACTIVE,
        AdVirtualState.FEATURED,
        AdVirtualState.ELITE,
        AdVirtualState.EXPIRED,
        AdVirtualState.OUTDATED,
        AdVirtualState.DISABLED,
        AdVirtualState.SOLD,
    ];

    const shouldShowOwnAdAnalytics = postedAdStates.includes(ad.virtualState);
    const shouldShowOwnAdBanner = !isProfolioUser;
    const shouldShowOwnAdSection =
        isOwnAd &&
        ad.format === AdFormat.FULL &&
        (shouldShowOwnAdAnalytics || shouldShowOwnAdBanner);

    return { shouldShowOwnAdSection, shouldShowOwnAdAnalytics, shouldShowOwnAdBanner };
};

export default useShouldShowOwnAdSection;
