import { RoutingContextWithMiddlewares } from 'strat/app';

import { Ad } from 'horizontal/types';
import { getRemarketingParams } from 'horizontal/routes/adDetails/remarketing';
import { renderSearchPageWithParams } from 'horizontal/routes/search';

const renderRemarketingPage = (context: RoutingContextWithMiddlewares, ad: Ad): Promise<void> => {
    const remarketingParams = getRemarketingParams(ad);
    return renderSearchPageWithParams(context, remarketingParams);
};

export default renderRemarketingPage;
