import * as React from 'react';
import { Trans } from '@lingui/macro';
import { DeleteFormText, DeleteButtonText } from '@app/user/deleteFormTexts';
import { StylelessButton } from 'strat/generic';
import { Text, Flex, Button } from 'strat/components';

import styles from './styles/deleteProfileForm.cssm';

type Props = {
    readonly onDelete: () => void;
    readonly onDeactivate: () => void;
};

const DeleteProfileForm = ({ onDeactivate, onDelete }: Props) => (
    <div className={styles.container}>
        <div className={styles.title}>
            <Text.XLarge bold>
                <Trans>Delete this account</Trans>
            </Text.XLarge>
        </div>
        <div className={styles.deleteAccountContainer}>
            <Text.Base className={styles.description}>
                <Text.XLarge bold>
                    <Trans>Are you sure you want to delete your account?</Trans>
                </Text.XLarge>
            </Text.Base>
            {CONFIG.runtime.ENABLE_OLX_ACCOUNT_DEACTIVATION && (
                <Flex className={styles.deletionButton}>
                    <Button secondary className={styles.button} onClick={onDeactivate}>
                        <Text.Large bold>
                            <Trans>Yes, delete my account</Trans>
                        </Text.Large>
                    </Button>
                </Flex>
            )}
            <DeleteFormText />
        </div>
        <StylelessButton onClick={onDelete} className={styles.submitContainer}>
            <Text.Base className={styles.submitButton} bold>
                <DeleteButtonText />
            </Text.Base>
        </StylelessButton>
    </div>
);

export default DeleteProfileForm;
