import loadLibPhoneNumber from './loadLibPhoneNumber';
import { formatPhoneNumber } from './formatPhoneNumber';

const parsePhoneNumberList = (
    phoneNumbers: Array<string | null | undefined>,
): Promise<Array<string | null>> =>
    loadLibPhoneNumber().then((lib) => {
        return phoneNumbers.map((phoneNumber) =>
            phoneNumber ? formatPhoneNumber(phoneNumber, lib) : null,
        );
    });
export default parsePhoneNumberList;
