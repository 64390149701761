import type { AnyAdData } from 'strat/types';

const getProjectOrPropertyID = (property: AnyAdData) => {
    const initialExternalID = property.project?.externalID || property.externalID;
    const externalID = parseInt(initialExternalID, 10);

    if (isNaN(externalID) || externalID.toString() !== initialExternalID) {
        return initialExternalID;
    }
    return externalID;
};

export default getProjectOrPropertyID;
