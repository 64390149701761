import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import featureAdExample from '@app/assets/images/featureAdExample.gif';
import { useI18n } from 'strat/i18n/language';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import featureAdExampleMobile from '@app/assets/images/featureAdExampleMobile.gif';

import styles from './styles/featuredAdExample.cssm';
import Example from './example';

type Props = {
    readonly onClick: () => void;
};

const FeatureAdExample = ({ onClick }: Props) => {
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobileLayout);

    return (
        <Example
            src={isMobile ? featureAdExampleMobile : featureAdExample}
            alt={t(i18n)`Package example`}
            onClick={onClick}
        >
            <span className={styles.description}>
                <Trans>Ad shows up with featured tag in top position</Trans>
            </span>
        </Example>
    );
};

export default FeatureAdExample;
