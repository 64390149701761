import React from 'react';

import { Dialog } from 'strat/modal';
import { LoadingThreeDots } from 'strat/loadable';

type Props = {
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
};

const ShareLoadingDialog = ({ visible, onVisibilityChanged }: Props) => (
    <Dialog visible={visible} onVisibilityChanged={onVisibilityChanged}>
        <LoadingThreeDots />
    </Dialog>
);

export default ShareLoadingDialog;
