import React from 'react';
import { Trans } from '@lingui/macro';

import { type ProjectPaymentPlan, ProjectPaymentPlanType } from 'strat/project/types';

import MilestonesPaymentPlan from './milestonesPaymentPlan';
import MonthlyPaymentPlan from './monthlyPaymentPlan';
import PercentagePaymentPlan from './percentagePaymentPlan';
import styles from './styles/paymentPlanTable.cssm';

export const MilestoneAxisSegment = () => (
    <td className={styles.milestoneAxis}>
        <div className={styles.topLine}></div>
        <div className={styles.point}></div>
        <div className={styles.bottomLine}></div>
    </td>
);

export const CommonHeader = () => (
    <>
        <th className={styles.milestoneAxis}></th>
        <th>
            <Trans>Milestone</Trans>
        </th>
        <th>
            <Trans>% of Property Value to be Paid</Trans>
        </th>
    </>
);

type Props = {
    paymentPlan: ProjectPaymentPlan;
};

const PaymentPlanTableContent = ({ paymentPlan }: Props) => {
    switch (paymentPlan.type) {
        case ProjectPaymentPlanType.MILESTONES:
            return <MilestonesPaymentPlan paymentPlan={paymentPlan} />;
        case ProjectPaymentPlanType.MONTHLY:
            return <MonthlyPaymentPlan paymentPlan={paymentPlan} />;
        case ProjectPaymentPlanType.PERCENTAGE:
            return <PercentagePaymentPlan paymentPlan={paymentPlan} />;
        default:
            return null;
    }
};

const PaymentPlanTable = ({ paymentPlan }: Props) => (
    <table className={styles.table}>
        <tbody>
            <PaymentPlanTableContent paymentPlan={paymentPlan} />
        </tbody>
    </table>
);

export default PaymentPlanTable;
