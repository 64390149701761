import * as React from 'react';
import { Trans } from '@lingui/macro';

import styles from './styles/adExternalID.cssm';

const AdExternalID = ({ externalID }: { readonly externalID: string }) => (
    <div className={styles.externalID}>
        <Trans>Ad id {externalID}</Trans>
    </div>
);

export default AdExternalID;
