import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { RequestDeliveryRouteParams } from 'horizontal/routes';
import { useEnsureHasActiveUser } from 'horizontal/user';

const useNavigateToRequestDeliveryForm = (params?: RequestDeliveryRouteParams): any => {
    const router = useRouter();

    return useEnsureHasActiveUser({
        onDone: () => {
            router.pushRoute(RouteNames.REQUEST_DELIVERY, params);
        },
    });
};

export default useNavigateToRequestDeliveryForm;
