import * as React from 'react';

const useIsPageFocused = () => {
    const [isFocused, setFocused] = React.useState(document.hasFocus());

    React.useEffect(() => {
        const handleFocusChange = () => {
            setFocused(document.hasFocus());
        };

        window.addEventListener('focus', handleFocusChange);
        window.addEventListener('blur', handleFocusChange);
        handleFocusChange();

        return () => {
            window.removeEventListener('focus', handleFocusChange);
            window.removeEventListener('blur', handleFocusChange);
            handleFocusChange();
        };
    }, []);

    return isFocused;
};

export default useIsPageFocused;
