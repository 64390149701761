import type { AgencyData } from 'strat/agency/types';
import type { AgencyData as PropertyAgencyData } from 'strat/property/types';
import { SchemaType, PostalAddressTag } from 'strat/schema/types';
import type { MetaData } from 'strat/schema/types';
import Layout from 'strat/layout';
import AgentsPerPage from 'strat/agency/constants';

import createBasicRealEstateAgentMarkup from './agent/metadataRealEstateAgent';
import {
    addNameTag,
    addPostalAddressTags,
    addTypeTag,
    addImageTag,
    addURLTag,
} from './tagsGeneral';
import { computeAgencyDetailsURL, getAgencyLogoThumbnail } from './utility';

/**
 * These tag functions are specific to AGENCY.
 * They take agency as a parameter to take metadata from it.
 */

const addAgencyURLTag = (
    metadata: MetaData,
    agency: AgencyData | PropertyAgencyData,
    language: string,
) => {
    if (!agency || !agency.slug) {
        return;
    }
    const params = { agencySlug: agency.slug, language } as const;
    const agencyUrl = computeAgencyDetailsURL(params, language);
    addURLTag(metadata, agencyUrl);
};

const addAgencyImageTag = (metadata: MetaData, agency: AgencyData) => {
    // add agency image if logo exists

    const agencyThumbnail = getAgencyLogoThumbnail(agency);

    // priority has the thumbnail, as it can be found in the actual html
    const agencyImage = agencyThumbnail || (agency.logo || {}).url;
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    addImageTag(metadata, agencyImage);
};

const addAgencyPostalAddressTags = (
    metadata: MetaData,
    agency: AgencyData,
    tagsToAdd: Array<string>,
) => {
    if (!agency.locations || agency.locations.length === 0) {
        return;
    }

    // add agency related geography and address tags
    const hierarchy = agency.locations[0].hierarchy || [];
    addPostalAddressTags(metadata, hierarchy, tagsToAdd);
};

const addSubOrganizationTag = (
    metadata: MetaData,
    agency: AgencyData,
    language: string,
    layout: string,
) => {
    // subOrganization represents an array which contains basic RealEstateAgents markups

    const agents = agency.agents;
    if (!agents || agents.length === 0) {
        return;
    }

    const subOrganization = agents.map((agent) =>
        createBasicRealEstateAgentMarkup(agent, agency, language),
    );

    // agents paginator is dummy and it works only in the state (pages are not indexable)
    // the number of agents on a page (for which metadata should be rendered)
    // depends on the device's type
    const limit = layout === Layout.MOBILE ? AgentsPerPage.COMPACT : AgentsPerPage.DEFAULT;

    metadata.subOrganization = subOrganization.slice(0, limit);
};

const addParentOrganizationTag = (
    metadata: MetaData,
    agency: AgencyData,
    language: string,
    withAddress = false,
) => {
    if (Object.keys(agency).length === 0) {
        return;
    }

    const parentOrganization: Record<string, any> = {};
    addTypeTag(parentOrganization, [SchemaType.ORGANIZATION]);
    addNameTag(parentOrganization, agency.name);
    addAgencyURLTag(parentOrganization, agency, language);

    if (withAddress) {
        addAgencyPostalAddressTags(parentOrganization, agency, [PostalAddressTag.ADDRESS_REGION]);
    }

    metadata.parentOrganization = parentOrganization;
};

export {
    addAgencyURLTag,
    addAgencyImageTag,
    addAgencyPostalAddressTags,
    addParentOrganizationTag,
    addSubOrganizationTag,
};
