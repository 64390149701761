import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import type { PopularSearchesGroups } from './types';

/**
 * Parameters to which popular searches are bound.
 */
type Params = {
    purpose: string;
    categorySlug: string;
    locationSlug: string;
    withRelatedCategory?: string;
};

/**
 * Factory for fetching popular searches.
 */
const popularSearchesFactory = new FetcherFactory(
    ['seo', 'popularSearches'],
    (params: Params, state: any) =>
        new StratAPI(state.i18n.language).popularSearches(
            params.purpose,
            params.categorySlug,
            params.locationSlug,
            params.withRelatedCategory,
        ),
);

/**
 * Fetches popular searches from the back-end.
 */
const fetchPopularSearches = popularSearchesFactory.creator();

/**
 * Reducer for popular searches.
 */
const popularSearchesReducer = popularSearchesFactory.reducer();

/**
 * Inserts popular searches that were already fetched.
 */
const insertPopularSearches = (params: Params, links: PopularSearchesGroups) => ({
    type: popularSearchesFactory.actions.success,
    params,
    data: links,
});

/**
 * Clears any currently stored popular searches.
 */
const clearPopularSearches = () => ({
    type: popularSearchesFactory.actions.clear,
});

export { fetchPopularSearches, clearPopularSearches, insertPopularSearches };

export default popularSearchesReducer;
