import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

export const CommuteSortByValues = Object.freeze({
    BALANCED: 'balanced',
    CLOSE_TO_A: 'close to A',
    CLOSE_TO_B: 'close to B',
});

const CommuteSortByOptions = [
    {
        name: (i18n: I18n) => t(i18n)`Balanced drive time`,
        value: CommuteSortByValues.BALANCED,
    },
    {
        name: (i18n: I18n) => t(i18n)`Nearest to A`,
        value: CommuteSortByValues.CLOSE_TO_A,
    },
    {
        name: (i18n: I18n) => t(i18n)`Nearest to B`,
        value: CommuteSortByValues.CLOSE_TO_B,
    },
];

export default CommuteSortByOptions;
