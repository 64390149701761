import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const FilterValues = {
    location: {
        attribute: 'location.externalID',
        placeholder: 'pakistan_g1000001',
        placeholderName: (i18n: I18n): string => t(i18n)`Pakistan`,
        placeholders: { en: 'pakistan_g1000001' },
    },
    category: {
        attribute: 'category.externalID',
        tree: (_i18n?: I18n) => ({}),
    },
    price: {
        attribute: 'price',
    },
} as const;

export default FilterValues;
