import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectDeviceID,
    selectIsUserLoggedIn,
    selectUserEmail,
    selectUserID,
    selectUserName,
    selectUserPhone,
} from 'strat/user/selectors';
import {
    selectPushNotificationsSubscription,
    selectRecommendedEmailAlertSubscription,
} from 'strat/alerts/selectors';
import type {
    PushNotificationsSubscriptionType,
    RecommendedEmailAlertSubscriptionType,
} from 'strat/alerts/types';
import { getSubscriptions } from 'strat/alerts/state';
import { selectLanguage } from 'strat/i18n/language';
import { useOnPushNotificationsPermissionChanged } from 'strat/notifications';

import loadMoEngageScript from './loadMoEngageScript';

const setPushNotificationsSubscription = (data: PushNotificationsSubscriptionType) => {
    // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
    if (window?.Moengage?.add_user_attribute && data) {
        Object.entries(data).forEach(([key, value]: [any, any]) => {
            // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
            window.Moengage.add_user_attribute(key === 'id' ? 'alert_id' : key, value);
        });
    }
};

const setRecommendedEmailAlertSubscription = (data: RecommendedEmailAlertSubscriptionType) => {
    // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
    if (window?.Moengage?.add_user_attribute && data) {
        Object.entries(data).forEach(([key, value]: [any, any]) => {
            if (['id', 'frequency', 'active', 'auto_frequency'].includes(key)) {
                // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                window.Moengage.add_user_attribute(`recommended_email_alert_${key}`, value);
            }
        });
    }
};

const moeLifecycleHandler = (e: CustomEvent, userDeviceID: string | null) => {
    if (e.detail.name === 'SDK_INITIALIZED') {
        // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
        if (window?.Moengage?.add_user_attribute) {
            // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
            window.Moengage.add_user_attribute('device_id', userDeviceID);
        }
    }
};

const MoEngageOptInEventType = Object.freeze({
    SHOWN: 'opt_in_shown',
    ALLOWED: 'opt_in_allowed',
    BLOCKED: 'opt_in_blocked',
    DISMISSED: 'opt_in_dismissed',
});

const moeOptInHandler = (
    e: CustomEvent<Values<typeof MoEngageOptInEventType>>,
    onSubscribe: () => Promise<void>,
    onUnsubscribe: () => Promise<void>,
) => {
    if (CONFIG.build.ENABLE_PUSH_NOTIFICATIONS) {
        // oneSignal is enabled, it will handle push notifications subscriptions
        return Promise.resolve();
    }
    const optInEventType = e.detail;
    switch (optInEventType) {
        case MoEngageOptInEventType.ALLOWED:
            return onSubscribe();
        case MoEngageOptInEventType.BLOCKED:
        case MoEngageOptInEventType.DISMISSED:
            return onUnsubscribe();
        case MoEngageOptInEventType.SHOWN:
        default:
            return Promise.resolve();
    }
};

const MoEngage = () => {
    const userDeviceID = useSelector(selectDeviceID);
    const dispatch = useDispatch();
    const pushNotificationsSubscription = useSelector(selectPushNotificationsSubscription);
    const recommendedEmailAlertSubscription = useSelector(selectRecommendedEmailAlertSubscription);
    const [initialized, setInitialized] = React.useState(false);
    const isLoggedIn = useSelector(selectIsUserLoggedIn);
    const language = useSelector(selectLanguage);
    const { onSubscribe, onUnsubscribe } = useOnPushNotificationsPermissionChanged();
    const [hasLoggedIn, setHasLoggedIn] = React.useState<boolean>(isLoggedIn);

    // User data
    const userID = useSelector(selectUserID);
    const userEmail = useSelector(selectUserEmail);
    const userName = useSelector(selectUserName);
    const userPhoneNumber = useSelector(selectUserPhone);

    // MoEngage initialisation
    React.useEffect(() => {
        // load moEngage SDK
        // @ts-expect-error - TS2339 - Property 'moe' does not exist on type 'Window & typeof globalThis'.
        if (!window.moe) {
            loadMoEngageScript();
        }

        // initialize moEngage instance from SDK only once
        if (!initialized) {
            // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'moe' does not exist on type 'Window & typeof globalThis'.
            window.Moengage = window.moe({
                app_id: CONFIG.build.MO_ENGAGE_APP_ID,
                debug_logs: CONFIG.build.MO_ENGAGE_DEBUG ? 1 : 0,
            });
            setInitialized(true);
        }
    }, [initialized, setInitialized]);

    // Handle push notifications data
    React.useEffect(() => {
        setPushNotificationsSubscription(pushNotificationsSubscription);
    }, [pushNotificationsSubscription]);

    // Handle user data
    React.useEffect(() => {
        // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
        if (window.Moengage) {
            if (isLoggedIn) {
                // The user is no longer in an anonymous session
                setHasLoggedIn(true);
                // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                window.Moengage.add_unique_user_id(userID);
                // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                window.Moengage.add_email(userEmail);
                // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                window.Moengage.add_mobile(userPhoneNumber);
                // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                window.Moengage.add_user_name(userName);
            }
            // If the user has logged in once, we need to destroy their current sessions upon a logout
            else if (!isLoggedIn && hasLoggedIn) {
                // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                window.Moengage.destroy_session();
            }
        }
    }, [isLoggedIn, userID, userEmail, userPhoneNumber, userName, hasLoggedIn]);

    // Handle recommended email data
    React.useEffect(() => {
        setRecommendedEmailAlertSubscription(recommendedEmailAlertSubscription);
    }, [recommendedEmailAlertSubscription]);

    React.useEffect(() => {
        // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
        if (window?.Moengage?.add_user_attribute) {
            // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
            window.Moengage.add_user_attribute('language', language);
        }
    }, [language]);

    React.useEffect(() => {
        if (isLoggedIn) {
            // @ts-expect-error - TS2345 - Argument of type '() => Promise<{ data: any; status: number; }>' is not assignable to parameter of type 'Action'.
            dispatch(getSubscriptions());
        }
    }, [isLoggedIn, dispatch]);

    React.useEffect(() => {
        const handler = (e: Event) => moeLifecycleHandler(e as CustomEvent, userDeviceID);

        window.addEventListener('MOE_LIFECYCLE', handler);

        return () => window.removeEventListener('MOE_LIFECYCLE', handler);
    }, [userDeviceID]);

    React.useEffect(() => {
        const handler = (e: Event) =>
            moeOptInHandler(
                e as CustomEvent<Values<typeof MoEngageOptInEventType>>,
                onSubscribe,
                onUnsubscribe,
            );
        window.addEventListener('MOE_OPT_IN', handler);

        return () => window.removeEventListener('MOE_OPT_IN', handler);
    }, [onSubscribe, onUnsubscribe]);

    return null;
};

export default MoEngage;
