const Gesture = Object.freeze({
    /**
     * The user is dragging the bottom sheet around the screen.
     */
    DRAG: 'drag',

    /**
     * The user is scrolling the contents of the bottom sheet.
     */
    SCROLL: 'scroll',

    NONE: null,
});

export default Gesture;
