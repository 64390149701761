import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import type { LiteAd } from 'horizontal/types';
import { useAdAgency, useAgencyLogo } from 'horizontal/ad';
import { formatDate } from 'horizontal/util';
import VerifiedProfileBadge from 'horizontal/profile/verifiedProfileBadge';

import AgencyLogo from './agencyLogo';
import styles from './styles/agencySummary.cssm';

type Props = {
    readonly ad: LiteAd;
    readonly hideDescription: boolean;
    readonly isGridLayout?: boolean;
    readonly hideVerifiedBadge?: boolean;
};
const formatDateOptions = { month: 'short', year: 'numeric' } as const;

const AgencySummary = ({ ad, hideDescription, isGridLayout, hideVerifiedBadge }: Props) => {
    const i18n = useI18n();
    const agency = useAdAgency(ad);
    const logo = useAgencyLogo(ad);
    if (!agency) {
        return null;
    }
    const creationDate = new Date(agency?.createdAt || 0).getTime();
    const showVerifiedBadge = ad.isSellerVerified && !hideVerifiedBadge;

    if (hideDescription) {
        return (
            <div className={styles.noDescriptionContainer}>
                <AgencyLogo
                    logo={logo}
                    className={classNames({
                        [styles.verifiedAgencyLogo]: !isGridLayout && showVerifiedBadge,
                        [styles.agencyLogo]: !showVerifiedBadge,
                    })}
                />
                {showVerifiedBadge && (
                    <VerifiedProfileBadge
                        isAgency
                        shortText={isGridLayout}
                        large={!isGridLayout}
                        stretch
                    />
                )}
            </div>
        );
    }
    return (
        <Flex column alignCenter justifyCenter className={styles.container}>
            <Text.Regular>{agency.name}</Text.Regular>
            <Text.Small>{t(i18n)`Member since ${formatDate(
                i18n,
                creationDate,
                formatDateOptions,
            )}`}</Text.Small>
            <div>
                <AgencyLogo
                    logo={logo}
                    className={classNames(styles.premierAgencyLogo, {
                        [styles.verifiedLogo]: !isGridLayout && showVerifiedBadge,
                    })}
                />
                {showVerifiedBadge && (
                    <VerifiedProfileBadge
                        isAgency
                        shortText={isGridLayout}
                        large={!isGridLayout}
                        stretch
                    />
                )}
            </div>
        </Flex>
    );
};

export default AgencySummary;
