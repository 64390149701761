import { createSelector, createStructuredSelector } from 'reselect';

import Layout from 'strat/layout';
import { BayutCompat, asNumberOrString } from 'strat/util';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { selectExperiments } from 'strat/experiments';
import { selectSortPreference } from 'strat/search/selectors';
import { buildCanonicalURL } from 'strat/routing/canonicalDomainTools';
import { selectRouteURL } from 'strat/routes/selectors';
import { selectSearchHitsLayout } from 'strat/settings/selectors';
import { selectUserID } from 'strat/user/selectors';
import { selectAPI6UserKey } from 'strat/auth/selectors';

import {
    selectFormattedReferrer,
    selectFormattedOriginalReferrer,
    selectFormattedLandingURL,
} from './selectors';

const selectCanonicalURL = createSelector([selectRouteURL, selectLanguage], (url, language) =>
    // @ts-expect-error
    buildCanonicalURL(url, language),
);
const selectQueryParameters = createSelector(
    selectCanonicalURL,
    (url) => `?${url.split('?')[1] || ''}`,
);
const selectUserProfile = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.user.profile,
    // @ts-expect-error - TS7006 - Parameter 'userProfile' implicitly has an 'any' type.
    (userProfile) => {
        if (!userProfile) {
            return { userStatus: 'Anonymous' };
        }
        return {
            userId: asNumberOrString(userProfile.id),
            userStatus: userProfile.status,
        };
    },
);

const selectVariables = createStructuredSelector({
    experiments: selectExperiments,
    // tablet layout equals desktop layout
    device_type: (state) => (state.layout === Layout.MOBILE ? 'm' : 'd'),

    app_agent_version: () => '1.0',
    app_agent: () => 'web',
    app_environment: () => CONFIG.build.STRAT_ENVIRONMENT,

    language: selectLanguage,
    // language is sometimes not picked up by GA4 so we also send the below
    lang: selectLanguage,

    area_unit: (state) => BayutCompat.Area.abbreviation(state.settings.area),
    sort_preference: selectSortPreference,
    currency_unit: (state) => state.settings.currency,
    layout_preference: (state) => selectSearchHitsLayout(state)?.toLowerCase(),
    url: selectCanonicalURL,
    query_params: selectQueryParameters,
    route: (state) => state.router,
    referrer: selectFormattedReferrer,
    website_referrer: selectFormattedOriginalReferrer,
    starting_page_url: selectFormattedLandingURL,
    anonymous_session_id: (state) => state.user.anonymousSessionID || null,

    user_profile: selectUserProfile,

    // allows differentiating between strat and laravel in GTM
    website_platform: () => 'strat',

    // as requested by bayut's marketing team, set to undefined
    // if there is not utm/ga campaign
    utm_source: (state) => (state.campaign ? state.campaign.source : undefined),
    utm_medium: (state) => (state.campaign ? state.campaign.medium : undefined),
    utm_campaign: (state) => (state.campaign ? state.campaign.name : undefined),
    utm_term: (state) => (state.campaign ? state.campaign.term : undefined),
    utm_content: (state) => (state.campaign ? state.campaign.content : undefined),
    custom_traffic_source: (state) =>
        state.campaign ? state.campaign.customTrafficSource : undefined,
    gclid: (state) => (state.campaign ? state.campaign.gclid : undefined),
    wbraid_id: (state) => (state.campaign ? state.campaign.wbraid : undefined),
});

// the following optional variables cannot be set to null
// or undefined because it makes the bayut legacy stats
// api crash when doing that
const getGlobalGTMVars = createSelector(
    [selectVariables, selectUserID, selectAPI6UserKey],
    (variables, userExternalID, api6UserKey) => ({
        ...variables,
        ...(userExternalID && { userid: asNumberOrString(userExternalID) }),
        ...(CONFIG.runtime.STRAT_EXPOSE_API6_USER_KEY_IN_GTM &&
            api6UserKey && { auth_token: api6UserKey }),
    }),
);

export default getGlobalGTMVars;
