import * as React from 'react';

import styles from './styles/hitCountLabel.cssm';
import useHitCountLabel from './useHitCountLabel';

type Props = {
    readonly count: number;
    readonly isExhaustive: boolean;
};

const HitCountLabel = ({ count, isExhaustive }: Props) => {
    const label = useHitCountLabel(count, isExhaustive);
    return (
        <div className={styles.label} aria-label="Ads count">
            {label}
        </div>
    );
};

export default HitCountLabel;
