import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';
import setupI18n from 'strat/i18n/language/setupI18n';
import getCatalogs from 'strat/i18n/language/getCatalogs';

import { localesFromLanguage, numberFormatterLocaleFromLanguage } from './locales';
import isLanguageRTL from './rtl';
import { makeNumberFormatterFactory } from './numberFormatter';
import { getGeocoderLanguage } from './geocoderLanguage';

/**
 * Selects the currently active language.
 */
const selectLanguage = createSelector(
    (state: GlobalState) => state.i18n,
    (i18n) => i18n.language,
);

const selectIsLanguageRTL = createSelector(
    (state: GlobalState) => state.i18n.language,
    (language) => isLanguageRTL(language),
);

/**
 * Selects the language that will be used to make autocomplete requests on GeoAPIfy.
 * This needs to be either an official language for the current country, or English as a default.
 */
const selectGeocoderLanguage = createSelector(
    (state: GlobalState) => state.i18n.language,
    (language) => getGeocoderLanguage(language),
);

/**
 * Creates the {@see I18n} object from the current language and the
 * configured language.
 */
const selectI18n = createSelector(
    selectLanguage,
    (state: GlobalState) => localesFromLanguage(state.i18n.language),
    (language, _locales) => {
        return setupI18n(language, getCatalogs());
    },
);

/**
 * Selects the currently active number formatter.
 */
const selectNumberFormatterFactory = createSelector(
    (state: GlobalState) => numberFormatterLocaleFromLanguage(state.i18n.language),
    (locales) => makeNumberFormatterFactory(locales),
);

export {
    selectLanguage,
    selectI18n,
    selectIsLanguageRTL,
    selectNumberFormatterFactory,
    selectGeocoderLanguage,
};
