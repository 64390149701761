import * as React from 'react';
import { Trans } from '@lingui/macro';
import type { KeycloakUser } from 'strat/auth/keycloak/user';
import { Text } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';
import { useI18n } from 'strat/i18n/language';
import { useActiveUser } from 'strat/user/session';

import type { UserDTO } from 'horizontal/dtos';
import { DialogNames } from 'horizontal/dialogs';
import type { ChatUserXID } from 'horizontal/chat/types';
import { useChatSDK } from 'horizontal/chat/hooks';
import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import defaultUserName from '@app/user/defaultUserName';

import styles from './styles/blockSeller.cssm';

type Props = WithChatStateProps & {
    readonly user: KeycloakUser | UserDTO;
    readonly textClassName?: string;
};

const BlockSeller = ({ user, textClassName }: Props) => {
    const i18n = useI18n();
    const sdk = useChatSDK();
    const chatUserXID = user.externalID as ChatUserXID;
    const activeUser = useActiveUser();
    const [, setVisible] = useDialog(DialogNames.CHAT_BLOCK_USER_DIALOG);
    const blockUser = sdk.useUpdateUserBlocking(chatUserXID);
    const { isBlocked, isLoading } = sdk.useIsUserBlockedByMe(chatUserXID);

    if (!activeUser || activeUser.externalID === user.externalID || isLoading) {
        return null;
    }

    return (
        <div
            className={styles.content}
            onClick={() => {
                setVisible(true, {
                    userName: user.name || defaultUserName(i18n),
                    shouldBlock: !isBlocked,
                    onClick: () => blockUser(!isBlocked),
                });
            }}
        >
            <Text.Regular bold className={textClassName}>
                {!isBlocked ? <Trans>Block user</Trans> : <Trans>Unblock user</Trans>}
            </Text.Regular>
        </div>
    );
};

export default withChatState<Props>(BlockSeller);
