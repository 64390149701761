import * as React from 'react';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';

import { useI18n } from 'strat/i18n/language';
import AgencyName from '@app/contact/agencyName';
import { withErrorBoundary } from 'strat/util';
import { type AgencyData, AgencyType } from 'strat/property/types';
import { ViewSections } from 'strat/gtm';

import AgencyLogo from './agencyLogo';
import styles from './styles/agencyInfoDefault.cssm';
import AgencyInfoPermitNumber from './agencyInfoPermitNumber';

/**
 * Properties for {@see AgencyInfoDefault}.
 */
export type Props = {
    agency: null | AgencyData;
    contactName: null | string;
    referenceNumber: null | string;
    permitNumber: null | string;
    projectNumber: null | string;
    agencyLogoClassName?: string;
    renderName?: (name: string) => React.ReactElement;
    viewSection: Values<typeof ViewSections>;
    className: string | null | undefined;
};

/**
 * Renders the header for a contact form.
 */
const AgencyInfoDefault = ({
    renderName = (name: string) => <AgencyName name={name} />,
    ...props
}: Props) => {
    const i18n = useI18n();
    const { agency, className, permitNumber } = props;

    if (agency?.type === AgencyType.REAL_ESTATE_INDIVIDUAL_AGENCY) {
        return null;
    }

    return (
        <div className={[styles.container, styles.flexColumn, className].join(' ')}>
            {agency && (
                <div className={styles.flexColumn}>
                    <AgencyLogo agency={agency} agencyLogoClassName={props.agencyLogoClassName} />
                    {renderName(agency.name)}
                </div>
            )}
            <AgencyInfoPermitNumber permitNumber={permitNumber} />
            {CONFIG.build.ENABLE_AGENCY && props.contactName && (
                <div className={styles.agent}>
                    <span>
                        <Trans>Agent</Trans>:
                    </span>
                    <span aria-label="Agent name" className={styles.agentName}>
                        {props.contactName}
                    </span>
                </div>
            )}
            {props.referenceNumber && (
                <div className={styles.referenceNumber}>
                    {settings.getBrandName(i18n)} - {props.referenceNumber}
                </div>
            )}
        </div>
    );
};

// @ts-expect-error - TS2345 - Argument of type 'typeof (Anonymous class)' is not assignable to parameter of type 'ComponentType<withI18nProps>'. | TS2554 - Expected 2 arguments, but got 1.
export default withErrorBoundary(AgencyInfoDefault);
