import * as React from 'react';

import { Dialog, CloseDialogButton } from 'strat/modal';

import AsyncReviewsManagerContent from './asyncReviewsManagerContent';
import styles from './styles/reviewsManager.cssm';

type Props = {
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
};

const ReviewsManagerDialog = ({ visible, onVisibilityChanged }: Props) => (
    <Dialog
        dismissible
        visible={visible}
        onVisibilityChanged={onVisibilityChanged}
        className={styles.dialog}
    >
        <CloseDialogButton className={styles.closeButton} />
        <AsyncReviewsManagerContent />
    </Dialog>
);

export default ReviewsManagerDialog;
