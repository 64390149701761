import * as React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import { selectDialogStack } from 'horizontal/dialogs/selectors';

import AskForMore from './askForMore';
import styles from './styles/askForMoreBottomSheet.cssm';

type Props = WithChatStateProps & {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly adPrice?: number;
    readonly lastOfferPrice?: number;
};

const AskForMoreDialog = ({ visible, onVisibilityChanged, adPrice, lastOfferPrice }: Props) => {
    const onCloseClick = React.useCallback(
        (event: React.ChangeEvent<HTMLButtonElement> | React.MouseEvent<SVGElement>) => {
            /**
             * This is needed when you are supporting stacking of
             * Dialogs which internally are using Dissmissible.
             * Dissmissible uses event listeners on the document:
             * document.addEventListener('click')
             * such events are `document` based, not React based.
             * So they can't be handled with our stopEventPropagation
             * function, which is meant for React synthetic events.
             */
            event.nativeEvent.stopImmediatePropagation();
            onVisibilityChanged(false);
        },
        [onVisibilityChanged],
    );
    const { stacked, stackGroup } = useSelector(selectDialogStack);

    if (!adPrice || !lastOfferPrice) {
        return null;
    }

    return (
        <Dialog
            dismissible
            visible={visible}
            stacked={stacked}
            stackGroup={stackGroup}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.container}
        >
            <div className={styles.content}>
                <IconClose onClick={onCloseClick} className={styles.iconClose} />
                <AskForMore
                    adPrice={adPrice}
                    lastOfferPrice={lastOfferPrice}
                    onVisibilityChanged={onVisibilityChanged}
                />
            </div>
        </Dialog>
    );
};

export default withChatState<Props>(AskForMoreDialog);
