const generateInitialValues = (form: string) => ({
    type: form,
    makeName: undefined,
    modelName: undefined,
    year: undefined,
    isRegistered: undefined,
    governateName: undefined,
    regionName: undefined,
    cityName: undefined,
    userName: '',
    askingPrice: '',
    phoneNumber: '',
    notes: '',
    acceptedTc: false,
});

export default generateInitialValues;
