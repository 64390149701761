import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/imageSlideshowBullets.cssm';

type Props = {
    currentIndex: number;
    numberOfItems: number;
    bulletContainerClassName?: string;
    allBulletsClassName?: string;
    circleClassName?: string;
    activeClassName?: string;
    smallClassName?: string;
    shiftUnit?: number;
    minBulletsForShift?: number;
    bulletIndexToStartShifting?: number;
};

const ImageSlideshowBullets = ({
    currentIndex,
    numberOfItems,
    bulletContainerClassName,
    allBulletsClassName,
    circleClassName,
    activeClassName,
    smallClassName,
    shiftUnit = -14,
    minBulletsForShift = 5,
    bulletIndexToStartShifting = 1,
}: Props) => {
    const circles: Array<React.ReactElement<React.ComponentProps<'div'>> | any> = [];
    // The first page's last visible bullet's index
    const firstPageLastBulletIndex = minBulletsForShift - 1;
    // The first page's bullet index on whose position the active bullet
    // Has to be positioned after shifting
    const shiftDisplacement = bulletIndexToStartShifting + 1;
    // Index displacement which has to be subtracted/added to the active bullet's index
    // In order to obtain the index of the first/last visible bullet
    const smallBulletIndexDisplacement = bulletIndexToStartShifting + 1;

    const allBulletsStyle = allBulletsClassName ?? styles.allBullets;
    const circleStyles = circleClassName ?? styles.circle;
    const activeStyles = activeClassName ?? styles.active;
    const smallStyles = smallClassName ?? styles.small;

    // The value with which the bullet container content has to be translated
    // So that the active bullet stays in the middle of the container
    let bulletsXOffset = 0;
    if (numberOfItems > minBulletsForShift) {
        bulletsXOffset =
            currentIndex > bulletIndexToStartShifting
                ? shiftUnit * (currentIndex - shiftDisplacement)
                : 0;
        if (currentIndex >= numberOfItems - 3) {
            bulletsXOffset = shiftUnit * (numberOfItems - minBulletsForShift);
        }
    }

    let firstVisibleBulletIndex = 0;
    if (numberOfItems > minBulletsForShift) {
        firstVisibleBulletIndex =
            currentIndex > bulletIndexToStartShifting
                ? currentIndex - smallBulletIndexDisplacement
                : 0;
        if (currentIndex >= numberOfItems - 3) {
            firstVisibleBulletIndex = numberOfItems - minBulletsForShift;
        }
    }

    let lastVisibleBullettIndex = numberOfItems - 1;
    if (numberOfItems > minBulletsForShift) {
        lastVisibleBullettIndex =
            currentIndex > bulletIndexToStartShifting
                ? currentIndex + smallBulletIndexDisplacement
                : firstPageLastBulletIndex;
        if (currentIndex >= numberOfItems - 3) {
            lastVisibleBullettIndex = numberOfItems - 1;
        }
    }

    for (let i = 0; i < numberOfItems; i++) {
        circles.push(
            <div
                key={i}
                className={classNames(circleStyles, {
                    [activeStyles]: currentIndex === i,
                    [smallStyles]:
                        (firstVisibleBulletIndex === i && i > 0) ||
                        (lastVisibleBullettIndex === i && i < numberOfItems - 1),
                })}
            />,
        );
    }

    return (
        <div className={bulletContainerClassName ?? styles.bulletContainer}>
            <div
                className={allBulletsStyle}
                style={{ transform: `translateX(${bulletsXOffset}px)` }}
            >
                {circles}
            </div>
        </div>
    );
};

export default ImageSlideshowBullets;
