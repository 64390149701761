import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';

import type { CategoryNodeData, LocationData } from 'strat/property';
import Purpose from 'strat/purpose';

import FilterValues from './filterValues';

class SearchHistory {
    static EQUALITY_RELEVANT_FILTERS = [
        FilterValues.purpose.attribute,
        FilterValues.category.attribute,
        FilterValues.location.attribute,
        FilterValues.beds.attribute,
        FilterValues.city.attribute,
        FilterValues.area.attribute,
        FilterValues.price.attribute,
    ];

    static shouldPushForLocation(oldLocation: LocationData, newLocation: LocationData): boolean {
        if (!newLocation && !oldLocation) {
            return false;
        }

        if (oldLocation && !newLocation) {
            return true;
        }

        if (!oldLocation && newLocation) {
            return false;
        }

        // @ts-expect-error - TS2339 - Property 'level' does not exist on type 'LocationData'. | TS2339 - Property 'level' does not exist on type 'LocationData'.
        const changedToParentLocation = newLocation && newLocation.level < oldLocation.level;
        const changedToOtherLocationOnSameLevel =
            // @ts-expect-error - TS2339 - Property 'level' does not exist on type 'LocationData'. | TS2339 - Property 'level' does not exist on type 'LocationData'.
            newLocation.level === oldLocation.level &&
            // @ts-expect-error - TS2339 - Property 'externalID' does not exist on type 'LocationData'. | TS2339 - Property 'externalID' does not exist on type 'LocationData'.
            newLocation.externalID !== oldLocation.externalID;

        const otherLocationTreeBranch =
            // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type 'LocationData'.
            newLocation.hierarchy &&
            // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type 'LocationData'.
            newLocation.hierarchy.findIndex(
                // @ts-expect-error - TS7006 - Parameter 'location' implicitly has an 'any' type. | TS2339 - Property 'externalID' does not exist on type 'LocationData'.
                (location) => location.externalID === oldLocation.externalID,
            ) === -1;

        return (
            changedToParentLocation || changedToOtherLocationOnSameLevel || otherLocationTreeBranch
        );
    }

    static shouldPushForCity(oldCity: LocationData, newCity: LocationData): boolean {
        if (!oldCity && !newCity) {
            return false;
        }

        if (!oldCity && newCity) {
            return false;
        }

        if (oldCity && !newCity) {
            return true;
        }

        // @ts-expect-error - TS2339 - Property 'externalID' does not exist on type 'LocationData'. | TS2339 - Property 'externalID' does not exist on type 'LocationData'.
        return oldCity.externalID !== newCity.externalID;
    }

    static shouldPushForPurpose(
        oldPurpose: Values<typeof Purpose>,
        newPurpose: Values<typeof Purpose>,
    ) {
        return (
            (!oldPurpose && newPurpose) || (!newPurpose && oldPurpose) || oldPurpose !== newPurpose
        );
    }

    static shouldPushForCategory(oldCategory: CategoryNodeData, newCategory: CategoryNodeData) {
        if (!oldCategory && !newCategory) {
            return false;
        }

        if (oldCategory && !newCategory) {
            return true;
        }

        if (!oldCategory && newCategory) {
            return false;
        }

        const moreGenericCategory = oldCategory.parent && !newCategory.parent;

        const changedSubcategory =
            oldCategory.parent &&
            newCategory.parent &&
            oldCategory.externalID !== newCategory.externalID;

        const changedMainCategory =
            !oldCategory.parent &&
            !newCategory.parent &&
            oldCategory.externalID !== newCategory.externalID;

        return moreGenericCategory || changedSubcategory || changedMainCategory;
    }

    static statesEqual(state: FilterCollection, otherState: FilterCollection) {
        return state
            .subset(SearchHistory.EQUALITY_RELEVANT_FILTERS)
            .equals(otherState.subset(SearchHistory.EQUALITY_RELEVANT_FILTERS));
    }

    static filterEqualStates(
        states: Array<SerializedFilterCollection>,
        targetState: FilterCollection,
    ): Array<SerializedFilterCollection> {
        return states.filter(
            (filters) => !SearchHistory.statesEqual(new FilterCollection(filters), targetState),
        );
    }

    static push(
        size: number,
        state: Array<SerializedFilterCollection>,
        newSearchFilters: FilterCollection,
    ): Array<FilterCollection> {
        const searchFilters = newSearchFilters.copy([FilterValues.page.attribute]);
        const lastState = state[0] ? new FilterCollection(state[0]) : new FilterCollection();

        const newPurpose = searchFilters.getFilterValue(FilterValues.purpose.attribute);
        const oldPurpose = lastState.getFilterValue(FilterValues.purpose.attribute);
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string | ((purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any) => any) | ((i18n: any, purpose: any, uppercaseVerb?: boolean) => any) | (() => string[]) | ((purpose: any) => 1 | ... 1 more ... | 3) | ((purpose:...'.
        const shouldPushForPurpose = SearchHistory.shouldPushForPurpose(oldPurpose, newPurpose);

        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const newLocation = searchFilters.getFilterValue(FilterValues.location.attribute, [])[0];
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const oldLocation = lastState.getFilterValue(FilterValues.location.attribute, [])[0];
        const shouldPushForLocation = SearchHistory.shouldPushForLocation(oldLocation, newLocation);

        const oldCategory = lastState.getFilterValue(FilterValues.category.attribute);
        const newCategory = searchFilters.getFilterValue(FilterValues.category.attribute);
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'CategoryNodeData'.
        const shouldPushForCategory = SearchHistory.shouldPushForCategory(oldCategory, newCategory);

        const oldCity = lastState.getFilterValue(FilterValues.city.attribute);
        const newCity = searchFilters.getFilterValue(FilterValues.city.attribute);
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'LocationData'.
        const shouldPushForCity = SearchHistory.shouldPushForCity(oldCity, newCity);

        const shouldPushNewSearch =
            shouldPushForPurpose ||
            shouldPushForLocation ||
            shouldPushForCategory ||
            shouldPushForCity;

        const serializedFilters = searchFilters.serialize();

        // @ts-expect-error - TS2322 - Type 'SerializedFilterCollection[]' is not assignable to type 'FilterCollection[]'.
        return (
            shouldPushNewSearch
                ? [serializedFilters, ...SearchHistory.filterEqualStates(state, searchFilters)]
                : [
                      serializedFilters,
                      ...SearchHistory.filterEqualStates(state.slice(1), searchFilters),
                  ]
        ).slice(0, size);
    }
}

export default SearchHistory;
