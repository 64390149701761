import * as React from 'react';
import { Input, Flex } from 'strat/components';

import IconSearch from 'horizontal/assets/icons/iconSearch.svg';

import styles from './styles/freeTextInput.cssm';

type Props = {
    readonly query?: string;
    readonly onChange?: (arg1: string) => void;
    readonly onSubmit?: () => void;
    readonly autoFocus?: boolean;
    readonly onFocusChanged?: (arg1: boolean) => void;
    readonly placeholder?: string;
};

const renderIconSearch = () => (
    <Flex alignCenter justifyCenter className={styles.iconSearch}>
        <IconSearch />
    </Flex>
);

const FreeTextInput = ({
    query,
    onChange,
    onSubmit,
    onFocusChanged,
    autoFocus,
    placeholder,
}: Props) => (
    <Flex className={styles.container} aria-label="Search input">
        <Input
            value={query}
            renderLeft={renderIconSearch}
            renderRight={null}
            placeholder={placeholder}
            onFocusChanged={onFocusChanged}
            onChange={onChange}
            onSubmit={onSubmit}
            autoFocus={autoFocus}
        />
    </Flex>
);

export default FreeTextInput;
