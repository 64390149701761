import * as React from 'react';
import type { FormikValues } from 'formik';

import type { FlatCategoryField } from 'horizontal/types';
import { sortByGroupIndex } from 'horizontal/util';

import useRenderedFields from './useRenderedFields';
import useClearHiddenFields from './useClearHiddenFields';

const isFieldEmptyOfChoices = (field: FlatCategoryField): boolean =>
    field.valueType === 'enum' && !field.choices?.length;

const useGroupedRenderedFields = (
    categoryFields: Array<FlatCategoryField>,
    values: FormikValues,
    setFieldValue: (field: keyof FormikValues, value?: any) => void,
): [boolean, Array<FlatCategoryField>] => {
    const [loading, renderedFields] = useRenderedFields(categoryFields);

    const groups = React.useMemo(
        () =>
            renderedFields.reduce<Array<any>>((fieldGroups, field) => {
                const groupIndex = field.groupIndex ?? 0;
                if (!fieldGroups[groupIndex]) {
                    fieldGroups[groupIndex] = [];
                }

                fieldGroups[groupIndex].push(field);

                return fieldGroups;
            }, []),
        [renderedFields],
    );

    const reducedFields = React.useMemo(() => {
        const groupedFields: Array<FlatCategoryField> = [];
        let shouldStop = false;
        for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
            const currentGroup = groups[groupIndex];
            if (!currentGroup?.length) {
                continue; // eslint-disable-line no-continue
            }

            for (let fieldIndex = 0; fieldIndex < currentGroup.length; fieldIndex++) {
                const field = currentGroup[fieldIndex];
                if (isFieldEmptyOfChoices(field)) {
                    continue; // eslint-disable-line no-continue
                }
                if (!values[field.attribute] && groupIndex > 0) {
                    shouldStop = true;
                }
                groupedFields.push(field);
            }
            if (shouldStop) {
                break;
            }
        }

        groupedFields.sort(sortByGroupIndex);
        return groupedFields;
    }, [groups, values]);

    useClearHiddenFields(renderedFields, reducedFields, values, setFieldValue);

    return [loading, reducedFields];
};

export default useGroupedRenderedFields;
