import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

const defaultUserName = (i18n: I18n): string => {
    const brandName = settings.getBrandName(i18n);
    return t(i18n)`${brandName} User`;
};

export default defaultUserName;
