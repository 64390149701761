import { SearchService } from '@sector-labs/fe-search-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { SearchResponseHit, type SearchResponse } from '@sector-labs/fe-search-redux/backend';
import type { SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';
import { selectLanguage } from 'strat/i18n/language';
import { FetcherFactoryWithContext } from 'strat/fetcher';
import { logError } from '@app/error/log';

import type { Ad, LiteAd } from 'horizontal/types';
import { selectDefaultSortOption } from 'horizontal/search/sorting';
import { hitsTransformer } from 'horizontal/search/hits';

import shuffleRelatedAds from './shuffleRelatedAds';
import createRelatedAdsSearchJob from './createRelatedAdsSearchJob';

export type FetchRelatedAdsParams = {
    ad: Ad;
};

type RelatedAdsData = { ads: LiteAd[]; filters: SerializedFilterCollection };

export type RelatedAdsState = {
    genericRelatedAds: RelatedAdsData;
    filteredRelatedAds: RelatedAdsData;
};

/**
 * Fetcher factory for fetching ads.
 */
const factory = new FetcherFactoryWithContext<FetchRelatedAdsParams>(
    'relatedAds',
    ({ params, state }) => {
        const backend = selectActiveSearchBackend(state);
        const sortOption = selectDefaultSortOption(state);
        const language = selectLanguage(state);
        const [relatedAdsJobs, jobsFilters] = createRelatedAdsSearchJob(
            params.ad,
            sortOption,
            language,
        );

        const relatedAdsPromise =
            backend && relatedAdsJobs && jobsFilters
                ? new SearchService({ backend }).fetchJobs(relatedAdsJobs)
                : Promise.resolve({} as SearchResponse<SearchResponseHit>[]);

        return relatedAdsPromise
            .then((adsResponse) => {
                return {
                    status: 200,
                    data: {
                        genericRelatedAds: {
                            filters: jobsFilters?.[0]?.serialize(),
                            ads: shuffleRelatedAds(
                                hitsTransformer<LiteAd>(adsResponse[0].hits as LiteAd[], language),
                            ),
                        },
                        filteredRelatedAds: {
                            filters: jobsFilters?.[1]?.serialize(),
                            ads: shuffleRelatedAds(
                                hitsTransformer<LiteAd>(adsResponse[1]?.hits as LiteAd[], language),
                            ),
                        },
                    },
                };
            })
            .catch((e) => {
                logError({
                    e,
                    msg: 'Error fetching related ads',
                });
                throw e;
            });
    },
    { catchServerErrors: true },
);

const fetchRelatedAds = factory.creator();

const relatedAdsReducer = factory.reducer();

const clearRelatedAds = () => ({
    type: factory.actions.clear,
});

export { fetchRelatedAds, clearRelatedAds };

export default relatedAdsReducer;
