// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';

import { fetchFeaturedAgencies } from 'horizontal/featuredAgencies/state';
import { selectCategories } from 'horizontal/categories';

import selectMatchedCategory from './selectMatchedCategory';
import { MatchParams } from './types';

const fetchAndStoreFeaturedAgencies = (
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch,
    getState: GetState,
    matchParams: MatchParams,
): Promise<void> => {
    const categories = selectCategories(getState());
    const category = selectMatchedCategory(categories, matchParams);
    const categoryExternalID = category?.externalID;
    return dispatch(fetchFeaturedAgencies({ categoryExternalID }));
};

export default fetchAndStoreFeaturedAgencies;
