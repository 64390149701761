import * as React from 'react';
import autoBind from 'react-autobind';
import PlayIcon from '@app/assets/icons/iconPlayButton.svg';

import stopEventPropagation from 'strat/util/stopEventPropagation';
import type { PropertyVideoData } from 'strat/property';
import type { GTMProps } from 'strat/gtm/withGTM';
import withGTM from 'strat/gtm/withGTM';

import VideoHostAdapter from './videoHostAdapter';
import './styles/imageSlideshow.css';
import styles from './styles/videoThumbnail.cssm';
import VideoThumbnailImage from './videoThumbnailImage';

type Props = GTMProps & {
    slidesCount: number;
    video: PropertyVideoData;
    coverPhotoID?: string;
    triggerName?: string;
    autoPlay: boolean;
    renderVideoThumbnail: (video: PropertyVideoData, coverPhotoID?: string) => React.ReactElement;
    onError?: (errorMessage: string) => void;
};

type State = {
    showVideo: boolean;
};

/**
 * Render a singe Video Thumbnail with video player
 * option on click.
 */
class VideoPlayer extends React.Component<Props, State> {
    static defaultProps = {
        autoPlay: false,
        renderVideoThumbnail: (video: PropertyVideoData, coverPhotoID?: string) => (
            <VideoThumbnailImage coverPhotoID={coverPhotoID} video={video} />
        ),
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = {
            showVideo: this.props.autoPlay,
        };
    }

    /**
     * Close the video player
     */
    resetVideo() {
        this.setState({ showVideo: false });
    }

    toggleShowVideo() {
        if (this.props.triggerName) {
            this.props.trigger(this.props.triggerName, {});
        }
        this.setState({ showVideo: true });
    }

    /**
     * If a slide button click happened close
     * the video playback.
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.slidesCount !== this.props.slidesCount) {
            this.resetVideo();
        }
    }

    render() {
        const { video, coverPhotoID } = this.props;
        return this.state.showVideo ? (
            <div key="video" className={styles.videoWrapper}>
                {VideoHostAdapter.renderVideo(video, styles.videoWrapperIframe, this.props.onError)}
            </div>
        ) : (
            <div key="videoThumb" className={styles.videoWrapper}>
                <PlayIcon
                    className={styles.playButton}
                    onClick={(event) => {
                        stopEventPropagation(event);
                        this.toggleShowVideo();
                    }}
                />
                {this.props.renderVideoThumbnail(video, coverPhotoID)}
            </div>
        );
    }
}

export default withGTM(VideoPlayer);
