import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import phoneNumberFetcher from '@app/branding/phoneNumberFetcher';
import IconCall from '@app/assets/icons/iconCall.svg';
import classNames from 'classnames';

import { useI18n } from 'strat/i18n/language';
import type { CancelablePromise } from 'strat/util';
import { isCanceled } from 'strat/util';
import { PhoneNumberText, splitPhoneNumbers } from 'strat/contact/phoneNumber';
import { useLoginRestrictedCallback } from 'strat/misc';
import { selectIsMobileLayout } from 'strat/layout/selectors';
import { PhoneNumberPriority } from 'strat/contact/mergePhoneNumbers';

import styles from './styles/contactFormCallButton.cssm';

/**
 * Calls the phone number.
 * If there are multiple phone numbers
 * only the first one will be called
 */
const call = (phoneNumber?: string | null) => {
    if (process.env.IS_BROWSER && phoneNumber) {
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '(string | Location) & Location'.
        window.location = `tel:${phoneNumber.split(',')[0]}`;
    }
};

/**
 * Callback for the call event for mobile
 */
const callIfMobile = (
    event: React.SyntheticEvent<any>,
    phoneNumber: string | null | undefined,
    isMobile: boolean,
    onPhoneNumberClick?: (event: React.SyntheticEvent<any>) => void,
) => {
    if (isMobile) {
        call(phoneNumber);
        if (onPhoneNumberClick) {
            onPhoneNumberClick(event);
        }
    }
};

type Props = {
    /**
     * Phone number to display when the button is clicked.
     */
    phoneNumber: string;
    /**
     * Optional CSS class name for styling.
     */
    className?: string;
    /**
     * Method to call when the button is clicked.
     */
    onClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Method to call when the phone number is clicked.
     */
    onPhoneNumberClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Property external id for proxy call.
     */
    externalID: number;
    /**
     * Whether the property this button is associated with is a new development.
     */
    isNewDevelopment?: boolean;
    /**
     * If true, a call icon will appear next to the button
     */
    withIcon?: boolean;
    /**
     * If true, phone number will be displayed only if user is logged in
     */
    requiresLoginForContact?: boolean;
    /**
     * Renders an icon for the button
     */
    renderIcon?: () => React.ReactElement;

    phoneNumberPriority?: Values<typeof PhoneNumberPriority>;
};

/**
 * Renders a call button, that when clicked reveal
 * a phone number.
 */
const ContactFormCallButton = ({
    renderIcon = () => <IconCall className={styles.icon} />,
    ...props
}: Props) => {
    const [showNumber, setShowNumber] = React.useState(false);
    const [phoneNumbers, setPhoneNumbers] = React.useState<any>(null);
    const [fetchPhoneNumberPromise, setFetchPhoneNumberPromise] = React.useState<
        CancelablePromise | null | undefined
    >(null);
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobileLayout);

    const {
        onPhoneNumberClick,
        onClick,
        externalID,
        phoneNumber,
        isNewDevelopment,
        className,
        phoneNumberPriority = PhoneNumberPriority.FAVOR_FETCHED,
    } = props;

    /**
     * Reveals the phone number to the user.
     * If the layout is MOBILE, call once the phoneNumber is loaded
     * @param event In order to trigger correct lead on callback
     */
    const reveal = React.useCallback(
        (event: React.SyntheticEvent<any>) => {
            setShowNumber(!showNumber);
            const promise = phoneNumberFetcher(i18n.locale, externalID, isNewDevelopment);
            promise.then(
                // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
                (data) => {
                    const phoneNumberData =
                        phoneNumberPriority === PhoneNumberPriority.FAVOR_FETCHED
                            ? (data || {}).mobile || phoneNumber
                            : phoneNumber || (data || {}).mobile;
                    const firstPhoneNumber = splitPhoneNumbers(phoneNumberData)[0];
                    setPhoneNumbers(firstPhoneNumber);
                    callIfMobile(event, firstPhoneNumber, isMobile, onPhoneNumberClick);
                },
                // @ts-expect-error - TS7006 - Parameter 'reason' implicitly has an 'any' type.
                (reason) => {
                    if (!isCanceled(reason)) {
                        setPhoneNumbers(phoneNumber);
                        callIfMobile(event, phoneNumber, isMobile, onPhoneNumberClick);
                    }
                },
            );
            setFetchPhoneNumberPromise(promise);
        },
        [
            showNumber,
            phoneNumber,
            i18n,
            externalID,
            isNewDevelopment,
            isMobile,
            onPhoneNumberClick,
            phoneNumberPriority,
        ],
    );

    React.useEffect(() => () => {
        if (fetchPhoneNumberPromise) {
            fetchPhoneNumberPromise.cancel();
        }
    });

    /**
     * Handler for when the button is clicked.
     */
    const onButtonClick = React.useCallback(
        (event: React.SyntheticEvent<any>): void => {
            if (!showNumber) {
                reveal(event);
                if (onClick) {
                    onClick(event);
                }
            } else {
                if (onPhoneNumberClick) {
                    onPhoneNumberClick(event);
                }
                call(phoneNumbers);
            }
        },
        [reveal, showNumber, phoneNumbers, onClick, onPhoneNumberClick],
    );

    const onClickLogInRestricted = useLoginRestrictedCallback({ onClick: onButtonClick });

    return (
        <button
            onClick={props.requiresLoginForContact ? onClickLogInRestricted : onButtonClick}
            className={className || classNames(styles.button, styles.roundedButton)}
            type="button"
            aria-label="Call"
        >
            {props.withIcon && !showNumber && renderIcon()}
            {showNumber && phoneNumbers ? (
                // @ts-expect-error - TS2322 - Type '{ numbers: any[]; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, WithCleanPhoneNumberProps> & RefAttributes<any> & { children?: ReactNode; }'.
                <PhoneNumberText numbers={[phoneNumbers]} />
            ) : (
                <Trans>Call</Trans>
            )}
        </button>
    );
};

export default ContactFormCallButton;
