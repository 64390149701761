const Actions = Object.freeze({
    PLAY_MODEL: 'FLOOR_PLAN_MODEL/PLAY',
    RESET_MODEL: 'FLOOR_PLAN_MODEL/RESET',
} as const);

type PlayFloorPlanModelAction = {
    type: 'FLOOR_PLAN_MODEL/PLAY';
    id: number;
};

type ResetFloorPlanModelAction = {
    type: 'FLOOR_PLAN_MODEL/RESET';
};

type Action = PlayFloorPlanModelAction | ResetFloorPlanModelAction;

export type FloorPlanModelState = {
    readonly playing?: number | null | undefined;
};

const floorPlanModelReducer = (
    state: FloorPlanModelState | null | undefined = {},
    action: Action,
) => {
    switch (action.type) {
        case Actions.PLAY_MODEL:
            return {
                ...state,
                playing: action.id,
            };
        case Actions.RESET_MODEL:
            return {
                ...state,
                playing: null,
            };
        default:
            return state;
    }
};

const playFloorPlanModel = (id: number): PlayFloorPlanModelAction => ({
    type: Actions.PLAY_MODEL,
    id,
});

const resetFloorPlanModel = (): ResetFloorPlanModelAction => ({
    type: Actions.RESET_MODEL,
});

export { playFloorPlanModel, resetFloorPlanModel };

export default floorPlanModelReducer;
