import { hasFloorPlans } from 'strat/property/floorPlans/utility';

import { PropertyState } from './types';
import type { PropertyData } from './types';

const getPanoramaCount = (propertyData: PropertyData): number => {
    /**
     * Currently, propertyTour is used for Zameen only and panoramaCount for
     * the rest of the portals that support tours.
     */
    if (propertyData.panoramaCount > 0) {
        return propertyData.panoramaCount;
    }
    if (propertyData.propertyTour) {
        return propertyData.propertyTour ? 1 : 0;
    }

    return 0;
};

const getPropertyFloorPlansCount = (propertyData: PropertyData): number => {
    return propertyData.floorPlanID || hasFloorPlans(propertyData) ? 1 : 0;
};

const isPropertyInactive = (state: string): boolean => {
    return state === PropertyState.INACTIVE;
};

export { getPanoramaCount, getPropertyFloorPlansCount, isPropertyInactive };
