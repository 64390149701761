import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import iconViews from 'horizontal/assets/icons/iconViews_noinline.svg';
import iconCall from 'horizontal/assets/icons/iconCall_noinline.svg';
import iconChat from 'horizontal/assets/icons/iconChatWithDots_noinline.svg';
import iconWhatApp from 'horizontal/assets/icons/iconWhatsapp_noinline.svg';
import iconLeads from 'horizontal/assets/icons/iconLeads_noinline.svg';
import { AdExtraFieldValue } from 'horizontal/types';

import AnalyticsTile from './analyticsTile';
import styles from './styles/analytics.cssm';
import AgentCode from './agentCode';

type Props = {
    readonly viewCount: number;
    readonly phoneViewCount: number;
    readonly chatCount: number;
    readonly whatsAppCount: number;
    readonly agentCode?: AdExtraFieldValue;
    readonly className?: string;
    readonly compact?: boolean;
    readonly wideTile?: boolean;
};

const Analytics = ({
    viewCount,
    phoneViewCount,
    chatCount,
    whatsAppCount,
    agentCode,
    className,
    compact,
    wideTile,
}: Props) => {
    const i18n = useI18n();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const compactFormatter = numberFormatterFactory({ notation: 'compact' });
    const tileClassName = classNames({ [styles.tile]: !wideTile, [styles.wideTile]: wideTile });
    const leadsCount = phoneViewCount + chatCount + whatsAppCount;
    return (
        <Flex column className={classNames(className || styles.analytics)}>
            <Flex
                justifySpaceBetween={!compact}
                className={compact ? styles.analyticsContainer : undefined}
            >
                <AnalyticsTile
                    value={compactFormatter.format(viewCount ?? 0)}
                    text={t(i18n)`Views`}
                    iconSrc={iconViews}
                    className={tileClassName}
                    compact={compact}
                />
                {!compact ? (
                    <>
                        <AnalyticsTile
                            value={compactFormatter.format(phoneViewCount ?? 0)}
                            text={t(i18n)`Phone`}
                            iconSrc={iconCall}
                            className={tileClassName}
                        />
                        <AnalyticsTile
                            value={compactFormatter.format(whatsAppCount ?? 0)}
                            text={t(i18n)`Whatsapp`}
                            iconSrc={iconWhatApp}
                            className={tileClassName}
                        />
                        <AnalyticsTile
                            value={compactFormatter.format(chatCount ?? 0)}
                            text={t(i18n)`Chats`}
                            iconSrc={iconChat}
                            className={tileClassName}
                        />
                        {agentCode && <AgentCode agentCode={agentCode} compact={compact} />}
                    </>
                ) : (
                    <AnalyticsTile
                        value={compactFormatter.format(leadsCount ?? 0)}
                        text={t(i18n)`Leads`}
                        iconSrc={iconLeads}
                        className={tileClassName}
                        compact
                    />
                )}
            </Flex>
            {agentCode && compact && <AgentCode agentCode={agentCode} compact={compact} />}
        </Flex>
    );
};
export default Analytics;
