import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/basicTag.cssm';

type Props = {
    readonly renderIcon?: (className: string) => React.ReactElement;
    readonly text: string;
    readonly ariaLabel?: string;
    readonly withEllipsis?: boolean;
    readonly disableTextTransformation?: boolean;
};

const BasicTag = ({
    renderIcon,
    text,
    ariaLabel,
    withEllipsis,
    disableTextTransformation,
}: Props) => (
    <span
        className={classNames(styles.container, { [styles.ellipsisContainer]: withEllipsis })}
        aria-label={ariaLabel}
    >
        {renderIcon?.(styles.icon)}
        <span
            className={classNames({
                [styles.tagText]: !disableTextTransformation,
                [styles.ellipsis]: withEllipsis,
            })}
        >
            {text}
        </span>
    </span>
);

export default BasicTag;
