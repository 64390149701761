export enum UserRole {
    // Used on small/simple portals where there aren't
    // different types of agencies etc. Mostly a left
    // over from simpler times.
    AGENT = 'agent',

    // Pro agencies are used on horizontal portals
    PRO_AGENT = 'pro-agent',
    PRO_ADMIN_AGENT = 'pro-admin-agent',
    PRO_OWNER_AGENT = 'pro-owner-agent',

    // Profolio agencies are used on real-estate portals
    PROFOLIO_AGENT = 'profolio-agent',
    PROFOLIO_ADMIN_AGENT = 'profolio-admin-agent',
    PROFOLIO_OWNER_AGENT = 'profolio-owner-agent',
    PROFOLIO_TRUCHECK_ADMIN = 'profolio-trucheck-admin',
    PROFOLIO_LANDLORD = 'profolio-landlord',
    PROFOLIO_PAID_USER = 'profolio-paid-user',

    // Exclusively used on Bayut SA
    PROFOLIO_PACKAGE_USER = 'profolio-package-user',

    // Used to distinguish between users who can buy products
    // and the ones who can buy only credits
    STRAT_CREDIT_USER = 'strat-credit-user',
}

// ------
// Selectors for pro Agencies
// ------
const proAgencyOwnerRoles = [UserRole.PRO_OWNER_AGENT];
const proAgencyAdminAgentRoles = [UserRole.PRO_ADMIN_AGENT, UserRole.PRO_OWNER_AGENT];
const proAgentRoles = [UserRole.PRO_AGENT, UserRole.PRO_ADMIN_AGENT, UserRole.PRO_OWNER_AGENT];

export const isProAgencyOwner = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => proAgencyOwnerRoles.includes(role));

export const isProAgencyAdminAgent = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => proAgencyAdminAgentRoles.includes(role));

export const isProAgent = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => proAgentRoles.includes(role));

// ------
// Selectors for Profolio agencies
// ------
const profolioAgencyOwnerRoles = [UserRole.PROFOLIO_OWNER_AGENT];
const profolioAgencyAdminAgentRoles = [
    UserRole.PROFOLIO_ADMIN_AGENT,
    UserRole.PROFOLIO_OWNER_AGENT,
];
const profolioAgentRoles = [
    UserRole.PROFOLIO_AGENT,
    UserRole.PROFOLIO_LANDLORD,
    UserRole.PROFOLIO_TRUCHECK_ADMIN,
    UserRole.PROFOLIO_ADMIN_AGENT,
    UserRole.PROFOLIO_OWNER_AGENT,
];
const profolioPaidUserRoles = [UserRole.PROFOLIO_PAID_USER];
const profolioPackageUserRoles = [UserRole.PROFOLIO_PACKAGE_USER];

export const isProfolioAgencyOwner = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => profolioAgencyOwnerRoles.includes(role));

export const isProfolioAdminAgent = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => profolioAgencyAdminAgentRoles.includes(role));

export const isProfolioAgent = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => profolioAgentRoles.includes(role));

export const isProfolioPaidUser = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => profolioPaidUserRoles.includes(role));

export const isProfolioPackageUser = (userRoles: readonly UserRole[]): boolean =>
    userRoles.some((role) => profolioPackageUserRoles.includes(role));

export const isProfolioUser = (userRoles: readonly UserRole[]): boolean =>
    isProfolioAgent(userRoles) ||
    isProfolioAgencyOwner(userRoles) ||
    isProfolioPaidUser(userRoles) ||
    isProfolioPackageUser(userRoles);

// ------
// Selectors for any type of agency
// ------

export const isAgent = (userRoles: readonly UserRole[]): boolean =>
    userRoles.includes(UserRole.AGENT) || isProAgent(userRoles) || isProfolioAgent(userRoles);

export const isAgencyAdminAgent = (userRoles: readonly UserRole[]): boolean =>
    isProAgencyAdminAgent(userRoles) || isProfolioAdminAgent(userRoles);

export const isAgencyOwner = (userRoles: readonly UserRole[]): boolean =>
    isProAgencyOwner(userRoles) || isProfolioAgencyOwner(userRoles);

// ------
// Selectors for credit users
// ------
export const isStratCreditUser = (userRoles: readonly UserRole[]): boolean =>
    userRoles.includes(UserRole.STRAT_CREDIT_USER);
