import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectIsLanguageRTL } from 'strat/i18n/language';

import IconArrowRight from 'horizontal/assets/icons/iconArrowRight.svg';
import IconArrowLeft from 'horizontal/assets/icons/iconArrowLeft.svg';

import styles from './styles/navButton.cssm';

type Props = {
    onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
};

const NavButtonRight = ({ onClick }: Props) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);
    return (
        <button
            onClick={onClick}
            className={classNames(styles.container, styles.right)}
            aria-label={'Next button'}
        >
            {isLanguageRTL ? <IconArrowLeft /> : <IconArrowRight />}
        </button>
    );
};

const NavButtonLeft = ({ onClick }: Props) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);
    return (
        <button
            onClick={onClick}
            className={classNames(styles.container, styles.left)}
            aria-label={'Previous button'}
        >
            {isLanguageRTL ? <IconArrowRight /> : <IconArrowLeft />}
        </button>
    );
};

export { NavButtonLeft, NavButtonRight };
