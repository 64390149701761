import * as React from 'react';
import classNames from 'classnames';
import IconEmail from '@app/assets/icons/iconEmail.svg';

import { ContactForm } from 'strat/contact';
import styles from '@app/contact/styles/contactFormButtons.cssm';
import Designs from 'strat/branding/designs';

import ContactFormCallButton from './contactFormCallButton';
import ContactFormEmailButton from './contactFormEmailButton';
import ContactButtonsWhatsApp from './compact/contactButtonsWhatsApp';

/**
 * Properties for {@see ContactFormProps}.
 */
type ContactFormProps = React.ComponentProps<typeof ContactForm> & {
    emailButtonClassName?: string;
    callButtonClassName?: string;
    enableWhatsAppButton?: boolean;
    hideCallButton?: boolean;
    design?: Values<typeof Designs>;
};

const EmailButton = (props: ContactFormProps) => (
    <ContactFormEmailButton
        sendEmail={props.sendEmail}
        renderIcon={() => props.withIcon && <IconEmail className={styles.icon} />}
        className={props.emailButtonClassName}
        emailButtonTitle={props.emailButtonTitle}
    />
);

const CallButton = (props: ContactFormProps) => (
    <ContactFormCallButton
        className={props.callButtonClassName}
        phoneNumber={props.phoneNumber}
        onClick={props.onCallClick}
        onPhoneNumberClick={props.onPhoneNumberClick}
        externalID={props.externalID}
        isNewDevelopment={props.isNewDevelopment}
        withIcon={props.withIcon}
        requiresLoginForContact={props.requiresLoginForContact}
    />
);

/**
 * Send email and call buttons.
 * Call button will reveal the phone number on click.
 */
const ContactFormButtons = (props: ContactFormProps) => {
    const shouldUseNewFormDesign = props.design !== Designs.FORM_DESIGN_2022;

    const {
        phoneNumber,
        enableWhatsAppButton,
        hasEmail = true,
        hideCallButton = shouldUseNewFormDesign,
        emailButtonClassName = classNames(styles.submitButton, {
            [styles.updatedButton]: shouldUseNewFormDesign,
        }),
        callButtonClassName = classNames(styles.callButton, {
            [styles.updatedButton]: shouldUseNewFormDesign,
        }),
        withIcon = shouldUseNewFormDesign,
    } = props;

    if (phoneNumber && enableWhatsAppButton) {
        return (
            <>
                <div className={styles.row}>
                    <ContactButtonsWhatsApp {...props} withIcon={withIcon} />
                </div>
            </>
        );
    }

    return (
        <>
            {hasEmail && (
                <EmailButton
                    {...props}
                    emailButtonClassName={emailButtonClassName}
                    withIcon={withIcon}
                />
            )}
            {!hideCallButton && phoneNumber && (
                <CallButton
                    {...props}
                    callButtonClassName={callButtonClassName}
                    withIcon={withIcon}
                />
            )}
        </>
    );
};

export default ContactFormButtons;
