import Loadable from 'strat/loadable';

export {
    useKeycloakSiteConfig,
    useKeycloakSiteConfigWithCallbacks,
} from './hooks/useKeycloakSiteConfig';
export {
    createKeycloakSiteConfig,
    createKeycloakSiteConfigWithCallbacks,
} from './keycloakSiteConfig';
export type { KeycloakSiteConfig, KeycloakSiteConfigWithCallbacks } from './keycloakSiteConfig';
export {
    KeycloakCrossWindowNotificationKey,
    sendKeycloakCrossWindowLoginCompleteNotification,
} from './keycloakCrossWindow';
export { createKeycloakThirdPartyLoginRedirectURI } from './keycloakThirdPartyLogin';
export { default as useKeycloakErrorData } from './hooks/useKeycloakErrorData';
export {
    initKeycloakSessionRefreshTimer,
    getKeycloakSessionRefreshTimer,
} from './keycloakSessionRefreshTimer';
export { default as KeycloakAuthDialog } from './components/keycloakAuthDialog';
export { default as useKeycloakLoginDialog } from './hooks/useKeycloakLoginDialog';
export { default as OTPForm } from './components/otpForm';
export { default as OTPError } from './components/otpError';
export { default as ChangePassword } from './components/changePassword';
export { default as useResendCountDown, secondsTillResend } from './hooks/useResendCountDown';
export { RequestCodeType } from './hooks/useSupportedOTPChannels';
export {
    getSupportedOTPChannels,
    isOTPChannelEnabled,
    isVoiceOTPFirst,
    getDefaultOTPChannel,
} from './utils';

export { default as determineKeycloakThirdPartyProviderID } from './determineKeycloakThirdPartyProviderID';
export { default as useKeycloakStoreSession } from './hooks/useKeycloakStoreSession';

export const KeycloakGoogleOneTap = Loadable({
    loader: () =>
        import(/* webpackChunkName: 'googleOneTap' */ './components/keycloakGoogleOneTap'),
    loading: () => null,
});
