import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/section.cssm';

type Props = {
    readonly children: React.ReactNode;
    readonly noPadding?: boolean;
    readonly primaryTopPadding?: boolean;
    readonly label?: string;
};

const Section = ({ children, noPadding, label, primaryTopPadding }: Props) => {
    return (
        <div
            className={classNames(styles.section, {
                [styles.noPadding]: noPadding,
                [styles.primaryTopPadding]: primaryTopPadding,
            })}
            aria-label={label}
        >
            {children}
        </div>
    );
};

export default Section;
