import { createSelector } from 'reselect';

const selectEmailAlerts = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.alerts.data,
    // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
    (data) => (data || {}).emailAlerts || [],
);

const selectPushNotificationsSubscription = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.alerts.data,
    // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
    (data) => (data || {}).pushNotificationsSubscription,
);

/*
 * We want the recommended email alert to show up, even if it does not exist.
 * If it does not exist, present it as deactivated and without frequency.
 */
const selectRecommendedEmailAlertSubscription = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.alerts.data,
    // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
    (data) =>
        (data || {}).recommendedEmailAlert || {
            id: null,
            active: false,
            frequency: null,
            autoFrequency: null,
        },
);

// As for the recommended email alert, recently viewed email alert always appears.
const selectRecentlyViewedEmailAlertSubscription = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.alerts.data,
    // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
    (data) =>
        (data || {}).recentlyViewedEmailAlert || {
            id: null,
            active: false,
            frequency: null,
            autoFrequency: null,
        },
);

export {
    selectEmailAlerts,
    selectPushNotificationsSubscription,
    selectRecommendedEmailAlertSubscription,
    selectRecentlyViewedEmailAlertSubscription,
};
