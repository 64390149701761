import { MatchParams } from 'horizontal/routes/search/types';
import { LocationNodeData } from 'horizontal/property';

import isLocationSpecifiedInURL from './isLocationSpecifiedInURL';

const isValidSearchPath = (
    matchParams: MatchParams,
    location: LocationNodeData | null | undefined,
) => {
    if (isLocationSpecifiedInURL(matchParams) && !location) {
        return false;
    }
    return true;
};

export default isValidSearchPath;
