import * as React from 'react';
import { Dialog } from 'strat/modal';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import type { ChatMessage } from 'horizontal/chat/types';

import Gallery from './gallery';
import styles from './styles/dialog.cssm';
import type { OpenAtConfig } from './types';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly messages?: Array<ChatMessage>;
    readonly openAt?: OpenAtConfig;
};

/**
 * Open the dialog and pass all the currently fetched messages and the openAt config
 * Filter the messages by image `messageType`
 */
const GalleryDialog = ({ visible, onVisibilityChanged, messages, openAt }: Props) => {
    const onCloseClick = React.useCallback(() => onVisibilityChanged(false), [onVisibilityChanged]);

    if (!messages || !openAt) {
        return null;
    }

    return (
        <Dialog
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.container}
        >
            <IconClose onClick={onCloseClick} className={styles.iconClose} />
            <div className={styles.galleryContainer} onClick={onCloseClick}>
                <Gallery messages={messages} openAt={openAt} />
            </div>
        </Dialog>
    );
};

export default GalleryDialog;
