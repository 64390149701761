import { createSelector } from 'reselect';
import EMPTY_OBJECT from 'strat/empty/object';

import { GlobalState } from 'horizontal/agencyPortal/state';

const selectAgentDetails = createSelector(
    (state: GlobalState) => state.agencyPortal?.agentDetails,
    (agentDetails) => agentDetails.data || EMPTY_OBJECT,
);

const selectAgentDetailsLoading = createSelector(
    (state: GlobalState) => state.agencyPortal?.agentDetails,
    (agentDetails) => agentDetails.loading || false,
);

export { selectAgentDetails, selectAgentDetailsLoading };
