// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import createAdDetailsURL from '@app/routes/createAdDetailsURL';

const createAdDetailsURLFromState = (getState: GetState) => {
    const state = getState();
    const adData = state.ad.data;
    const adParams = { externalID: adData.externalID, slug: adData.slug };
    return createAdDetailsURL(adParams).pathname;
};

export default createAdDetailsURLFromState;
