import * as React from 'react';
import Logo from '@app/branding/logo';
import { Image } from 'strat/util';
import { Flex, Header } from 'strat/components';

import errorImagePNG from '@app/assets/icons/iconStraightFace.png';
import errorImageWEBP from '@app/assets/icons/iconStraightFace.webp';

import styles from './styles/rejectPaymentBody.cssm';

type Props = {
    readonly children: React.ReactNode;
};

const RejectPaymentBody = ({ children }: Props) => {
    return (
        <>
            <Header className={styles.header}>
                <Logo className={styles.logo} />
            </Header>
            <Flex column alignCenter className={styles.container}>
                <Image
                    image={{ source: errorImagePNG, sourceWEBP: errorImageWEBP }}
                    className={styles.image}
                />
                {children}
            </Flex>
        </>
    );
};

export default RejectPaymentBody;
