import React from 'react';

import ProviderCashPaymentBody from 'horizontal/payment/providerCashPayment';
import { PaymentSource } from 'horizontal/payment/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly referenceNumber: string;
    readonly cashPaymentSource: Values<typeof PaymentSource>;
};

const ProviderCashPaymentPage = {
    head: () => <PrivateNonIndexableHead />,
    body: (props: Props) => <ProviderCashPaymentBody {...props} />,
} as const;

export default ProviderCashPaymentPage;
