// For most MENA countries, the standard screen width
// is 1366px. For those, and also for smaller screens,
// a smaller Wallpaper ad is shown if provided
import { type WallpaperTakeover } from 'strat/wallpaper/state';

const DEVICE_WIDTH_MENA = 1366;

const useGetWallpaperForSize = (wallpaper: WallpaperTakeover) => {
    if (window.innerWidth <= DEVICE_WIDTH_MENA) {
        return wallpaper.webpSmallImageURL || wallpaper.smallImageURL;
    }

    return wallpaper.webpImageURL || wallpaper.imageURL;
};

export default useGetWallpaperForSize;
