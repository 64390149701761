import * as React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import useRouter from 'react-true-router/useRouter';
import { RouteNames } from 'strat/routes';
import { useLanguage } from 'strat/i18n/language';
import { useDialog } from 'strat/dialogs/dialogContext';
import { useIsStratCreditUser } from 'strat/user';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { trackPostNowClick } from 'horizontal/gtm';
import { StratAPI } from 'horizontal/api';
import { useNavigateToApplyAdLimit } from 'horizontal/packages/navigation';
import useNavigateToPaymentSelectionFreeAd from 'horizontal/payment/hooks/useNavigateToPaymentSelectionFreeAd';
import { DialogNames } from 'horizontal/dialogs';
import { AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE } from 'horizontal/payment/types';
import { AdSoldMedium } from 'horizontal/constants';
import { selectIsBTTFeaturedEnabledForCategory } from 'horizontal/categories';
import type { GlobalState } from 'horizontal/state';
import type { Ad } from 'horizontal/types';
import { useNavigateToApplyAdProductCreditCosts } from 'horizontal/adProductCreditCost';
import useCreditSystemGuard from 'horizontal/authorization/useCreditSystemGuard';

const useAutoActivateAd = () => {
    const i18n = useI18n();
    const router = useRouter();
    const language = useLanguage();
    const isStratCreditUser = useIsStratCreditUser();
    const navigateToApplyAdLimit = useNavigateToApplyAdLimit();
    const navigateToApplyAdProductCreditCosts = useNavigateToApplyAdProductCreditCosts();
    const brandName = settings.getAgencyPortalBrandName(i18n);
    const withCreditSystemGuard = useCreditSystemGuard(
        t(i18n)`You can only post ads through ${brandName} Pro.`,
    );

    return React.useCallback(
        (ad: Ad) => {
            withCreditSystemGuard(() => {
                trackPostNowClick(ad);
                if (!isStratCreditUser) {
                    return new StratAPI(language)
                        .activateAd({
                            adExternalID: ad.externalID,
                        })
                        .then(({ status }) => {
                            if (status >= 200 && status < 300) {
                                router.pushRoute(RouteNames.POST_AD_SUCCESS, {
                                    adExternalID: ad.externalID,
                                });
                                return;
                            }
                            navigateToApplyAdLimit({ externalID: ad.externalID });
                        });
                }
                navigateToApplyAdProductCreditCosts({ adExternalID: ad.externalID });
            });
        },
        [
            router,
            language,
            isStratCreditUser,
            navigateToApplyAdLimit,
            navigateToApplyAdProductCreditCosts,
            withCreditSystemGuard,
        ],
    );
};

const useOpenMarkAsSoldDialog = (onCloseRemoveAdDialog: () => void) => {
    const [, setMarkAsSoldVisible] = useDialog(DialogNames.MARK_AS_SOLD);
    const navigateToPaymentSelectionFreeAd = useNavigateToPaymentSelectionFreeAd();

    return React.useCallback(
        (ad: Ad) => {
            setMarkAsSoldVisible(true, {
                ad,
                // @ts-expect-error - TS7006 - Parameter 'soldType' implicitly has an 'any' type.
                onSuccess: (soldType) => {
                    if (
                        soldType === AdSoldMedium.INSIDE_STRAT &&
                        ad.extraFields &&
                        ad.extraFields[AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE]
                    ) {
                        setMarkAsSoldVisible(false);
                        navigateToPaymentSelectionFreeAd({
                            adExternalID: ad.externalID,
                        });
                    } else {
                        setMarkAsSoldVisible(false);
                        onCloseRemoveAdDialog();
                    }
                },
            });
        },
        [setMarkAsSoldVisible, navigateToPaymentSelectionFreeAd, onCloseRemoveAdDialog],
    );
};

const useIsBTTEnabledForFeaturedAds = (ad: Ad): boolean | undefined => {
    return useSelector((state: GlobalState) =>
        selectIsBTTFeaturedEnabledForCategory(
            state,
            ad?.category[ad?.category?.length - 1]?.externalID ?? null,
        ),
    );
};

export { useAutoActivateAd, useOpenMarkAsSoldDialog, useIsBTTEnabledForFeaturedAds };
