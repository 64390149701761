import * as React from 'react';
import useI18n from 'strat/i18n/language/useI18n';
import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';

import { StratAPI } from 'horizontal/api';
import { FullAd, ProductPackageOffer } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';

const useAvailableAdPackageOffers = (
    setLoading: (loading: boolean) => unknown,
    setError: (error: string | undefined) => unknown,
) => {
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();

    const [packageOffers, setPackageOffers] = React.useState<ProductPackageOffer[]>([]);

    const fetchPackageOffers = React.useCallback(
        (ad: FullAd) => {
            setPackageOffers([]);
            setLoading(true);
            setError(undefined);

            makeCancelable(
                new StratAPI(i18n.locale)
                    .getAdProductPackageOffers(ad.externalID)
                    .then((response) => {
                        if (response.status >= 400) {
                            throw new ContextfulError(
                                `Unexpected response status while fetching ad package offers for TeleSales`,
                                {
                                    status: response.status,
                                    data: response.data,
                                },
                            );
                        }

                        setPackageOffers(response.data);
                    })
                    .catch((e) => {
                        logError({
                            e,
                            msg: 'Unable to fetch ad package offers',
                            context: {
                                adExternalID: ad.externalID,
                            },
                        });

                        setError("Couldn't fetch packages");
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            );
        },
        [setLoading, setError, makeCancelable, i18n.locale],
    );

    return { fetchPackageOffers, packageOffers };
};

export default useAvailableAdPackageOffers;
