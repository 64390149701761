import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Checkbox } from 'strat/components';

import useSelectSortingOption from './useSelectSortingOption';
import styles from './styles/sortOptionCheckbox.cssm';

const SortOptionCheckbox = () => {
    const { isSortedByVerified, selectOption } = useSelectSortingOption();

    return (
        <Checkbox
            containerClassname={styles.checkBoxContainer}
            checked={isSortedByVerified}
            iconClassname={styles.checkboxIcon}
            label={<Trans>Show Verified Accounts first</Trans>}
            labelClassname={styles.checkboxLabel}
            onChange={selectOption}
        />
    );
};

export default SortOptionCheckbox;
