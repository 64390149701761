import settings from '@app/branding/settings';

/**
 * Returns the dateLocales of a given language.
 * If dateLocales doesn't exist, it just returns the locales
 * For example for the 'en' language the locales can be 'en-UK' or 'en-US'.
 * @param lang the language code
 */
export const dateLocalesFromLanguage = (lang: string): string =>
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'dateLocales' does not exist on type '{ lang: string; name: string; locales: string; rtl?: boolean | undefined; isDisabled?: boolean | undefined; getLanguagePrefix?: ((requestTranslatedPage: boolean) => string) | undefined; }'.
    settings.languages.find((configLang) => configLang.lang === lang).dateLocales ||
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    settings.languages.find((configLang) => configLang.lang === lang).locales;
