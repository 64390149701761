//@ts-nocheck
/**
 * Available search hits layouts.
 */
const SearchHitsLayout = Object.freeze({
    LIST: 'LIST',
    GRID: 'GRID',
});

export default SearchHitsLayout;
