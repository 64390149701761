import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { AgentState } from 'strat/agency/agent/types';

import Page from 'horizontal/agencyPortal/pages/page';
import { fetchAgentDetails, selectAgentDetails } from 'horizontal/agencyPortal/agentDetail/state';
import { isAgencyOwner } from 'horizontal/agencyPortal/utils';

import { ensureCanAccessAgencyPortalAsOwner } from './ensureCanAccessAgencyPortal';
import { AdSubRoutes } from './agencyPortalAdsRoute';

const AgentSubRoutes = Object.freeze({
    QUOTA: 'quota',
    ADS: 'ads',
    INFO: 'info',
});

const defaultAgentSubRoute = AdSubRoutes.INFO;

export type AgentsRouteParams = {
    readonly section: Values<typeof AgentSubRoutes>;
    readonly agentExternalID: number;
};

class AgencyPortalAgentDetailRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_AGENT_DETAIL, [
            [
                '^/agencyPortal/agents/',
                { name: 'agentExternalID', pattern: '([A-z0-9-]+)' },
                { name: 'section', pattern: `(?:/(${Object.values(AgentSubRoutes).join('|')}))?` },
                '(?:\\?.*)?',
                '$',
            ],
        ]);
    }

    createURL(params: AgentsRouteParams): EnhancedLocation {
        const { section, agentExternalID } = params;
        const sectionSlug = section === defaultAgentSubRoute ? '' : `/${section}`;
        return { pathname: `/agencyPortal/agents/${agentExternalID}${sectionSlug}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortalAsOwner(context)) {
            return;
        }
        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { section, agentExternalID },
            },
        } = context;

        context.rendering.renderPage(Page.AGENCY_PORTAL_AGENT_DETAIL, {
            section: section || defaultAgentSubRoute,
            agentExternalID,
        });
        context.promise.wait(
            dispatch(
                fetchAgentDetails({
                    agentExternalID,
                    status: AgentState.ACTIVE,
                    countAdsByState: true,
                }),
            ).then(() => {
                const state = getState();
                const agent = selectAgentDetails(state);
                // validate that agent is not an empty object
                if (!Object.keys(agent).length || isAgencyOwner(agent)) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
                }
            }),
        );
    }
}

export default new AgencyPortalAgentDetailRoute();
export { AgentSubRoutes };
