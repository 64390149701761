import { useSelector } from 'react-redux';
import { selectUserRoles } from 'strat/user/session';
import {
    isAgent,
    isProAgencyOwner,
    isAgencyAdminAgent,
    isAgencyOwner,
    isProAgent,
} from 'strat/user/roles';

export const useIsProOwnerAgent = () => isProAgencyOwner(useSelector(selectUserRoles));
export const useIsProAgent = () => isProAgent(useSelector(selectUserRoles));
export const useIsAnyTypeOfAgent = () => {
    const userRoles = useSelector(selectUserRoles);
    return isAgent(userRoles) || isAgencyAdminAgent(userRoles) || isAgencyOwner(userRoles);
};
