import React from 'react';
import { Trans } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { scrollToElement, scrollToElementTop } from 'strat/navigation/scroll';
import { setProjectDescriptionExpanded } from 'strat/project/state';
import { SearchElementIdentifiers } from 'strat/search';
import { selectIsMobile } from 'strat/platform/selectors';
import { GTMSearchTrackingProps, withGTMSearchTracking } from 'strat/gtm';
import { CollapsibleText } from 'strat/generic';

import { aboutSectionElementID } from './projectIdentifiers';
import { ProjectPageSections } from './types';
import styles from './styles/projectTopDescription.cssm';

type Props = GTMSearchTrackingProps & {
    isAreaGuideDescription: boolean;
    description?: string | null;
};

export const ProjectTopDescription = ({
    isAreaGuideDescription,
    description,
    trackProjectPageViewDetails,
}: Props) => {
    const descriptionElement = {
        __html: description || '',
    };

    const dispatch = useDispatch();
    const isMobile = useSelector(selectIsMobile);

    const onClick = React.useCallback(() => {
        dispatch(setProjectDescriptionExpanded(true));
        const headerHeight =
            document.getElementById(
                isMobile
                    ? SearchElementIdentifiers.compactSearchPageHeaderId
                    : SearchElementIdentifiers.searchPageHeaderId,
            )?.offsetHeight || 0;
        scrollToElement(
            document.getElementById(aboutSectionElementID),
            -(headerHeight + 30) + document.documentElement.scrollTop,
        );
        trackProjectPageViewDetails(ProjectPageSections.DESCRIPTION);
    }, [dispatch, isMobile, trackProjectPageViewDetails]);

    return isAreaGuideDescription ? (
        <div>
            <div className={styles.description}>
                <CollapsibleText
                    height={72}
                    buttonClassName={styles.readMoreButton}
                    scrollToElementTop={(node) => scrollToElementTop(node)}
                >
                    <div dir="auto" dangerouslySetInnerHTML={descriptionElement} />
                </CollapsibleText>
            </div>
        </div>
    ) : (
        <div>
            <div className={classNames(styles.description, styles.projectDescription)}>
                <div dir="auto" dangerouslySetInnerHTML={descriptionElement} />
            </div>
            <span className={styles.readMoreButton} onClick={onClick}>
                <Trans>Read more</Trans>
            </span>
        </div>
    );
};

export default withGTMSearchTracking(ProjectTopDescription);
