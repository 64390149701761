import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import Page from 'horizontal/agencyPortal/pages/page';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';
import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';

class AgencyPortalNotFoundRoute extends Route {
    constructor() {
        super(RouteNames.NOT_FOUND, [['^/agencyPortal/notFound']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/agencyPortal/notFound' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context) && !ensureHasAccessToStratCredits(context)) {
            return;
        }
        context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
    }
}

export default new AgencyPortalNotFoundRoute();
