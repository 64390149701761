//@ts-nocheck
import { createMemoryHistory, createBrowserHistory } from 'history';

/**
 * Creates a history object that works on both the
 * server and in the browser.
 */
export default (props = {}) => {
    if (process.env.IS_SERVER) {
        return createMemoryHistory(props);
    }

    return createBrowserHistory(props);
};
