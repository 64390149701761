import React from 'react';

import useRouter from './useRouter';

type PushRouteParams = Record<string, any>;

const createPushRouteHook = <TParams extends PushRouteParams | void>(defaultRouteName: string) => {
    return (routeName?: string) => {
        const router = useRouter();

        return React.useCallback(
            (params: TParams): void => {
                router.pushRoute(
                    routeName ?? defaultRouteName,
                    params as PushRouteParams | undefined,
                );
            },
            [router, routeName],
        );
    };
};

export default createPushRouteHook;
