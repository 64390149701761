import { t } from '@lingui/macro';
import React from 'react';
import { Trans } from '@lingui/macro';

import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { LocalCurrency, LocalPrice } from 'strat/i18n/money';
import type { ProjectData } from 'strat/project/types';
import { PropertyCompletionStatus } from 'strat/property/types';
import {
    PROJECT_PRICE_FORMATTING_OPTIONS,
    useProjectCompletionPercentage,
    computeProjectHandoverQuarter,
    isProjectOverdue,
} from 'strat/project';

import CompletionStatusBadge from './completionStatusBadge';
import CompletionPercentageBadge from './completionPercentageBadge';
import styles from './styles/projectCardSummary.cssm';

type SummaryItemProps = {
    title: string;
    renderItem: () => React.ReactElement;
};

const SummaryItem = ({ title, renderItem }: SummaryItemProps) => (
    <Flex alignCenter justifySpaceBetween className={styles.item}>
        <div>{title}</div>
        <div className={styles.value}>{renderItem()}</div>
    </Flex>
);

type Props = {
    project: ProjectData;
};

const ProjectCardSummary = ({ project }: Props) => {
    const i18n = useI18n();

    const handover = computeProjectHandoverQuarter(project.completionDetails);
    const completionPercentage = useProjectCompletionPercentage(project.completionDetails);
    const isOverdue = isProjectOverdue(project);

    return (
        <Flex column className={styles.container}>
            {!!project.agency?.name && (
                <SummaryItem
                    title={t(i18n)`Developer`}
                    renderItem={() => <div aria-label="Developer">{project.agency?.name}</div>}
                />
            )}
            {!isOverdue && (
                <SummaryItem
                    title={t(i18n)`Project Status`}
                    renderItem={() => (
                        <CompletionStatusBadge light completionStatus={project.completionStatus} />
                    )}
                />
            )}
            {project.completionStatus === PropertyCompletionStatus.OFF_PLAN &&
                !!completionPercentage &&
                !isOverdue && (
                    <SummaryItem
                        title={t(i18n)`Progress`}
                        renderItem={() => (
                            <CompletionPercentageBadge percentage={completionPercentage} />
                        )}
                    />
                )}
            {!!handover && (
                <SummaryItem
                    title={t(i18n)`Handover`}
                    renderItem={() => {
                        const { quarter, year } = handover;

                        return (
                            <Trans>
                                Q{quarter} {year}
                            </Trans>
                        );
                    }}
                />
            )}
            {!!project.price && (
                <SummaryItem
                    title={t(i18n)`Starting from`}
                    renderItem={() => (
                        <div>
                            <LocalCurrency />{' '}
                            <LocalPrice
                                price={project.price}
                                options={PROJECT_PRICE_FORMATTING_OPTIONS}
                            />
                        </div>
                    )}
                />
            )}
        </Flex>
    );
};

export default ProjectCardSummary;
