import * as React from 'react';
import { serializeLocation } from 'react-true-router/location';
import { buildCanonicalURLWithoutLanguage } from 'strat/routing';
import { isCanceled } from 'strat/util';

import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';

import { PaymentSource, PaymentStatus } from '../types';
import type { EasyPaisaHostedPaymentsPayload, PaymentHookParams } from '../types';

import useNavigateToPaymentFailed from './useNavigateToPaymentFailed';
import useTrackPaymentInitiated from './useTrackPaymentInitiated';

const EASY_PAISA_FINISH_API_URL = '/api/payment/easypaisa/finish/';

const buildPaymentFinishURL = (path: string) =>
    buildCanonicalURLWithoutLanguage(
        serializeLocation({
            pathname: path,
        }),
    );

export const buildEasyPaisaHostedPaymentPayload = (
    orderExternalID: string,
    amount: number,
): EasyPaisaHostedPaymentsPayload => {
    const postBackURL = buildPaymentFinishURL(EASY_PAISA_FINISH_API_URL);

    return {
        orderExternalID,
        amount,
        postBackURL,
    };
};

const useEasyPaisaPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();

    return React.useCallback(() => {
        if (!order || !order.externalID) {
            return;
        }

        setIsLoading(true);

        const cancelablePromise = makeCancelable(
            new StratAPI().easyPaisaHostedPayment(
                buildEasyPaisaHostedPaymentPayload(
                    order.externalID,
                    order.grossTotal || order.total,
                ),
            ),
        );
        cancelablePromise
            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            .then(({ data, status }) => {
                if (status === 400 || data?.paymentStatus === PaymentStatus.AD_REJECTED) {
                    setIsLoading(false);
                    navigateToPaymentFailed(PaymentStatus.AD_REJECTED);
                    return;
                }

                if (status !== 200) {
                    setIsLoading(false);
                    navigateToPaymentFailed();
                    return;
                }
                const redirect = data.redirect;

                if (redirect) {
                    trackPaymentInitiated(PaymentSource.EASYPAISA);
                    window.location.href = redirect;
                } else {
                    setIsLoading(false);
                    navigateToPaymentFailed();
                }
            })
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            .catch((error) => {
                if (isCanceled(error)) {
                    return;
                }
                setIsLoading(false);
            });
        setPaymentPromise(cancelablePromise);
    }, [
        order,
        setIsLoading,
        setPaymentPromise,
        navigateToPaymentFailed,
        trackPaymentInitiated,
        makeCancelable,
    ]);
};

export default useEasyPaisaPayment;
