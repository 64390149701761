import { t } from '@lingui/macro';
import * as React from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { InternalAPI } from 'strat/api';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { useActiveUser } from 'strat/user/session';

import { selectAdID } from 'horizontal/ad/state';

import ReportDialog from './reportDialog';
import ReportForm from './reportForm';
import type { BaseReportFormProps } from './types';
import ReportStatusDialog from './reportStatusDialog';

const reportReasons = (i18n: I18n) => [
    { id: 1, label: t(i18n)`Offensive content`, value: 'offensive' },
    { id: 2, label: t(i18n)`Fraud`, value: 'fraud' },
    { id: 3, label: t(i18n)`Duplicate ad`, value: 'duplicate' },
    { id: 4, label: t(i18n)`Product already sold`, value: 'sold' },
    { id: 6, label: t(i18n)`Wrong category`, value: 'wrong_category' },
    { id: 7, label: t(i18n)`Product unavailable`, value: 'unavailable' },
    { id: 8, label: t(i18n)`Fake product`, value: 'fake' },
    { id: 9, label: t(i18n)`Indecent`, value: 'indecent' },
    { id: 5, label: t(i18n)`Other`, value: 'other' },
];

const makeValidationSchema = (i18n: I18n) =>
    Yup.object().shape({
        reason: Yup.string().required(t(i18n)`Please select a report reason`),
        comment: Yup.string().required(
            t(i18n)`Please add a comment to help us understand what's wrong with this item.`,
        ),
    });

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
};

const ReportAdDialog = ({ visible, onVisibilityChanged }: Props) => {
    const [reportStatus, setReportStatus] = React.useState({
        visible: false,
        success: false,
    });

    const i18n = useI18n();
    const adID = useSelector(selectAdID);
    const user = useActiveUser();
    const [errorMessage, setErrorMessage] = React.useState(
        t(i18n)`Could not send report. Please try again later.`,
    );
    const onSubmit = React.useCallback(
        (values) => {
            if (!user) {
                return;
            }
            trigger(Triggers.REPORT_ABUSE, {
                ad_id: adID,
                view_section: ViewSections.BODY,
            });

            const report = {
                issue: values.reason,
                description: values.comment,
                userID: user.externalID,
            } as const;

            new InternalAPI()
                // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
                .sendReport(adID, report)
                .then(({ status, data }) => {
                    if (status === 400 && data?.[0] === 'Too many reports today') {
                        setErrorMessage(t(i18n)`Too many reports today`);
                    }
                    if (status === 400 && data?.[0] === 'Ad already reported') {
                        setErrorMessage(t(i18n)`Ad already reported`);
                    }
                    onVisibilityChanged(false);
                    setReportStatus({
                        visible: true,
                        success: status === 200,
                    });
                })
                .catch(() => {
                    onVisibilityChanged(false);
                    setReportStatus({
                        visible: true,
                        success: false,
                    });
                });
        },
        [user, adID, onVisibilityChanged, i18n],
    );
    const validationSchema = React.useMemo(() => makeValidationSchema(i18n), [i18n]);
    const renderForm = React.useCallback(
        (props: BaseReportFormProps) => (
            <ReportForm
                {...props}
                reportReasons={reportReasons(i18n)}
                title={t(i18n)`Item report`}
            />
        ),
        [i18n],
    );

    if (!user) {
        return null;
    }

    return (
        <>
            <ReportStatusDialog
                visible={reportStatus.visible}
                onVisibilityChanged={onVisibilityChanged}
                success={reportStatus.success}
                onCancel={() =>
                    setReportStatus({
                        visible: false,
                        success: reportStatus.success,
                    })
                }
                successMessage={t(i18n)`This ad has been reported`}
                errorMessage={errorMessage}
            />
            <ReportDialog
                visible={visible}
                onVisibilityChanged={onVisibilityChanged}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                renderForm={renderForm}
            />
        </>
    );
};

export default ReportAdDialog;
