import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

export type SuggestedMessage = {
    readonly id: number;
    readonly displayText: string;
    readonly message: string;
};

const useSuggestedMessages = (isSeller: boolean): SuggestedMessage[] => {
    const i18n = useI18n();
    const messagesForBuyer = React.useMemo(
        () => [
            { id: 1, displayText: t(i18n)`Hello`, message: t(i18n)`Hello` },
            { id: 2, displayText: t(i18n)`Is it available?`, message: t(i18n)`Is it available?` },
            { id: 3, displayText: t(i18n)`Okay`, message: t(i18n)`Okay` },
            { id: 4, displayText: t(i18n)`No problem`, message: t(i18n)`No problem` },
            { id: 5, displayText: t(i18n)`Please reply`, message: t(i18n)`Please reply` },
            { id: 6, displayText: t(i18n)`Not interested`, message: t(i18n)`Not interested` },
        ],
        [i18n],
    );

    const messagesForSeller = React.useMemo(
        () => [
            {
                id: 1,
                displayText: t(i18n)`It's available`,
                message: t(i18n)`Yes, it's still available. Are you interested?`,
            },
            {
                id: 2,
                displayText: t(i18n)`Sorry, it's already been sold`,
                message: t(i18n)`Sorry, it's already been sold`,
            },
            { id: 3, displayText: t(i18n)`Hello`, message: t(i18n)`Hello` },
            { id: 4, displayText: t(i18n)`Okay`, message: t(i18n)`Okay` },
            { id: 5, displayText: t(i18n)`Will let you know`, message: t(i18n)`Will let you know` },
        ],
        [i18n],
    );

    if (isSeller) {
        return messagesForSeller;
    }

    return messagesForBuyer;
};

export default useSuggestedMessages;
