import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/loadingAnimation.cssm';

type Props = {
    readonly width?: string;
    readonly height?: string;
    readonly className?: string;
};

const LoadingAnimation = ({ width, height, className }: Props) => {
    const style: Record<string, any> = {};
    if (width) {
        style.width = width;
    }
    if (height) {
        style.height = height;
    }

    return <div style={style} className={classNames(styles.loadingAnimation, className)} />;
};

export default LoadingAnimation;
