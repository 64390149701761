import { OrderItemStatus, ProductPurchaseVirtualStatus } from 'horizontal/userOrders/types';
import type { ProductPurchase } from 'horizontal/types';

const getProductPurchaseVirtualStatus = (
    productPurchase: ProductPurchase,
): Values<typeof ProductPurchaseVirtualStatus> => {
    if (
        productPurchase.available === 0 ||
        productPurchase.status === OrderItemStatus.INACTIVE ||
        Date.parse(productPurchase.expiresAt) <= Date.now()
    ) {
        return ProductPurchaseVirtualStatus.EXPIRED;
    }
    if (Date.parse(productPurchase.startDate) <= Date.now()) {
        return ProductPurchaseVirtualStatus.ACTIVE;
    }
    return ProductPurchaseVirtualStatus.SCHEDULED;
};

export default getProductPurchaseVirtualStatus;
