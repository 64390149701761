import * as React from 'react';
import { useSelector } from 'react-redux';
import brandingSettings from '@app/branding/settings';
import CategoryTypeChecker from '@app/branding/category';
import { ViewSections } from 'strat/gtm';

import type { Category } from 'horizontal/types';
import { selectCategories } from 'horizontal/categories/selectors';
import { findDeepList } from 'horizontal/util/findDeep';
import { useLocationFilterValue } from 'horizontal/search/location';
import { SearchLink } from 'horizontal/search/routing';
import { useTrackCategoryClick } from 'horizontal/gtm';
import { useFreeTextQuery } from 'horizontal/search/freeText';
import { useRemarketingParams } from 'horizontal/search/remarketing';

import styles from './styles/categoryLink.cssm';

type Props = {
    readonly className?: string;
    readonly category: Category;
    readonly onClick?: (arg1: React.SyntheticEvent<any>) => void;
    readonly children?: React.ReactNode;
    readonly viewSection?: Values<typeof ViewSections>;
    readonly rel?: string;
};

const CategoryLink = ({ className, category, onClick, children, viewSection, rel }: Props) => {
    const location = useLocationFilterValue();
    const trackCategoryClick = useTrackCategoryClick();
    const freeTextQuery = useFreeTextQuery();

    const categories = useSelector(selectCategories);
    const categoryHierarchy = React.useMemo(
        () => findDeepList(categories, (cat) => cat.id === category?.id, true),
        [categories, category],
    );

    const restrictedLocation = React.useMemo(() => {
        if (brandingSettings.headerMaxLocationLevel) {
            if (location && location.level > brandingSettings.headerMaxLocationLevel) {
                const topLevelIncomingCategory = categoryHierarchy[0];
                if (!CategoryTypeChecker.isOfPropertyType(topLevelIncomingCategory)) {
                    const newLocationHierarchy = (location?.hierarchy || []).slice(
                        0,
                        brandingSettings.headerMaxLocationLevel + 1,
                    );
                    return {
                        ...newLocationHierarchy[newLocationHierarchy.length - 1],
                        hierarchy: newLocationHierarchy,
                    };
                }
            }
        }
        return location;
    }, [location, categoryHierarchy]);

    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trackCategoryClick(category, viewSection);
            if (onClick) {
                onClick(event);
            }
        },
        [onClick, trackCategoryClick, category, viewSection],
    );

    const remarketingParams = useRemarketingParams();

    return (
        <SearchLink
            params={{ category }}
            onClickParams={{
                // @ts-expect-error - TS2322 - Type 'Location | { hierarchy: LiteHierarchicalLocation[]; id: number; externalID: string; level: number; name: string; slug: string; } | null | undefined' is not assignable to type 'Location | null | undefined'.
                location: restrictedLocation,
                category,
                freeTextQuery,
                ...remarketingParams,
            }}
            className={className || styles.category}
            onClick={onTrackedClick}
            rel={rel}
        >
            {children || category.nameShort || category.name}
        </SearchLink>
    );
};

export default CategoryLink;
