import { ExactFilter, ExistFilter } from '@sector-labs/fe-search-redux/filters';

import FetcherFactory from 'strat/fetcher';
import { fetchFromPopularityTrends } from 'strat/search/elasticSearch';
import { BayutCompat } from 'strat/util';

const fetchMonthlyTrendsForLocationID = (params: any, locationID?: number): Promise<Array<any>> => {
    if (!locationID) {
        return Promise.resolve([]);
    }

    return fetchFromPopularityTrends(
        CONFIG.runtime.STRAT_SEARCH_INDEX_NAME_MONTHLY_PRICE_TRENDS,
        params,
        {
            hitsPerPage: 12,
            sort: {
                type: 'attributes',
                key: 'monthsSort',
                name: 'months sort',
                attributes: [{ name: 'month', order: 'desc' }],
            },
        },
        [
            new ExactFilter({ attribute: 'location_id', value: locationID }),
            new ExistFilter({ attribute: 'avg_price_aed' }),
        ],
    );
};

/**
 * Factory for fetching area trends from elasticsearch.
 */
const factory = new FetcherFactory(
    'areaTrends',
    (params) => {
        const trends: (index?: number) => Promise<any> = async (index = 0) => {
            const locationID = params.locations[index];
            const fetchedData = await fetchMonthlyTrendsForLocationID(params, locationID);
            const locationLevel = params.locations.length - index - 1;

            if (fetchedData && fetchedData.length > 4) {
                return {
                    data: { hits: fetchedData.reverse(), locationLevel: locationLevel },
                    status: 200,
                };
            }

            if (index === params.locations.length - 1) {
                return {
                    data: { hits: [], locationLevel: locationLevel },
                    status: 200,
                };
            }
            return trends(index + 1);
        };

        return trends();
    },
    {
        // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
        getParams: (params, getState) => {
            const property = getState().property.data || {};

            return {
                purposeID: BayutCompat.Purpose.toID(property.purpose),
                propertyTypeID: property.category[property.category.length - 1].externalID,
                bedrooms: Math.min(property.rooms, 8),
                locations: property.locations
                    //remove leaf location, starting from leaf + 1
                    .slice(0, -1)
                    //reverse location list, putting leaf location first
                    .reverse()
                    //grab the location externalID to use in the query
                    .map((location: any) => location.externalID),
            };
        },
    },
);
/**
 * Fetches area trends from elasticsearch.
 */
const fetchAreaTrends = factory.creator();

/**
 * Reducer for area trends from elasticsearch.
 */
const areaTrendsReducer = factory.reducer();

export { fetchAreaTrends };

export default areaTrendsReducer;
