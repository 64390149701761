import * as React from 'react';

export const secondsTillResend = 60;

const computeSecondsToCompletion = (targetTime: number) =>
    Math.max((targetTime - Date.now()) / 1000, 0);

const useResendCountDown = (countdownInSeconds: number, resetTimer: boolean): number => {
    const [targetTime, setTargetTime] = React.useState(Date.now() + countdownInSeconds * 1000);
    const [secondsToCompletion, setSecondsToCompletion] = React.useState(countdownInSeconds);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (secondsToCompletion <= 0 && resetTimer) {
                setTargetTime(Date.now() + countdownInSeconds * 1000);
                setSecondsToCompletion(countdownInSeconds);
                return;
            }

            setSecondsToCompletion(computeSecondsToCompletion(targetTime));
        }, 1000);

        return () => clearInterval(interval);
    }, [targetTime, secondsToCompletion, countdownInSeconds, resetTimer]);

    React.useEffect(() => {
        const targetTime = Date.now() + countdownInSeconds * 1000;
        setTargetTime(targetTime);
        setSecondsToCompletion(computeSecondsToCompletion(targetTime));
    }, [countdownInSeconds]);

    return Math.ceil(secondsToCompletion);
};

export default useResendCountDown;
