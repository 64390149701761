import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    findAllTemplates,
    renderAllTemplates,
    TemplatePage,
    TemplateType,
    selectTemplates,
} from 'strat/templates';
import { selectCurrency } from 'strat/i18n/money';

import type { MixedAd } from 'horizontal/types';
import { useAdFormattedExtraFields } from 'horizontal/ad';

const useAdDetailTemplate = (
    type: Values<typeof TemplateType>,
    ad?: MixedAd | null,
): string | null | undefined => {
    const formattedExtraFields = useAdFormattedExtraFields(ad);
    const templates = useSelector(selectTemplates);
    const { currency } = useSelector(selectCurrency);

    return React.useMemo(() => {
        if (!ad || !ad.category) {
            return null;
        }
        const { externalID, title, category, location, purpose, description, photos } = ad;
        const categoryExternalID = category.slice(-1)[0]?.externalID;
        if (!categoryExternalID) {
            return null;
        }

        const shortDescription = description && description.split(' ').slice(0, 8).join(' ');
        const photoCount = Array.isArray(photos) && photos.length;
        const categoryName = category.slice(-1)[0]?.name;
        const locationName = location.slice(-1)[0]?.name;
        const cityName = (location[CONFIG.build.CITY_LEVEL] || {}).name;
        const extraFields = formattedExtraFields.reduce<Record<string, any>>(
            (acc, field) => ({
                ...acc,
                [field.attribute]: field.formattedValue,
            }),
            {},
        );

        const foundTemplates = findAllTemplates({
            type,
            page: TemplatePage.AD_DETAILS,
            purpose,
            categoryExternalID,
            locationSlugs: location.map(({ slug }) => slug),
            templates,
        });
        if (!foundTemplates.length) {
            return null;
        }

        const renderedTemplate = renderAllTemplates({
            templates: foundTemplates,
            replaceParameters: {
                currency,
                externalID,
                title,
                purpose,
                category: categoryName,
                location: locationName,
                city: cityName,
                shortDescription,
                photoCount,
                extraFields,
            },
        });

        return renderedTemplate || null;
    }, [type, ad, formattedExtraFields, templates, currency]);
};

export default useAdDetailTemplate;
