import Loadable, { LoadingSpinner } from 'strat/loadable';

import computeBoundsOfPoints from './computeBoundsOfPoints';
import MapStyleTypes, { MAPBOX_OWNER } from './mapStyles';
import StaticMap from './staticMap';
import MapContactForm from './mapContactForm';
import MapViewToggle from './mapViewToggle';
import type { MapContactFormProps } from './mapContactForm';

const MapPopup = Loadable({
    loader: () => import(/* webpackChunkName: 'map' */ './mapPopup'),
    loading: LoadingSpinner,
});

export type { MapContactFormProps };
export {
    MapPopup,
    computeBoundsOfPoints,
    MAPBOX_OWNER,
    MapStyleTypes,
    StaticMap,
    MapContactForm,
    MapViewToggle,
};

/**
 * Loads the map component async.
 */
export default Loadable({
    loader: () => import(/* webpackChunkName: 'map' */ './map'),
    loading: LoadingSpinner,
});
