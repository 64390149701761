import type { Agent } from 'horizontal/types';
import { AgentRoles } from 'horizontal/types';

const isAgencyOwner = (agent?: Agent | null): boolean => {
    return agent?.roles?.indexOf(AgentRoles.OWNER) !== -1;
};

const agentTotalAdsCount = (agent: Agent): number => {
    // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number'.
    return agent?.adsStatus
        ? Object.values(agent.adsStatus).reduce((acc, value: any) => acc + value, 0)
        : 0;
};
const agentAvailableCredit = (agent: Agent): number => {
    return agent?.creditBalance?.availableCredit || 0;
};
export { isAgencyOwner, agentTotalAdsCount, agentAvailableCredit };
