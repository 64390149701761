import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { FloatingDialog } from 'strat/components';

import { selectDialogStack } from 'horizontal/dialogs/selectors';
import type { FullAd } from 'horizontal/types';
import { AdState } from 'horizontal/types';

import { RemoveAdContent } from './removeAd';

type Props = {
    ad: FullAd;
    onVisibilityChanged: (arg: boolean) => void;
    visible: boolean;
    onCancel: () => void;
    onSuccess: () => void;
};

const DisableAdDialog: React.ComponentType<Props> = ({
    ad,
    onCancel,
    onSuccess,
    ...rest
}: Props) => {
    const i18n = useI18n();
    const { stacked, stackGroup } = useSelector(selectDialogStack);

    return (
        <FloatingDialog {...rest} stacked={stacked} stackGroup={stackGroup} dismissible>
            {ad ? (
                <RemoveAdContent
                    ad={ad}
                    onCancelPress={onCancel}
                    onSuccess={onSuccess}
                    title={t(i18n)`Deactivate your ad`}
                    description={t(
                        i18n,
                    )`Keep in mind that you may have to pay to post the ad again`}
                    reason={AdState.EXPIRED}
                    disableButtonText={t(i18n)`Deactivate`}
                />
            ) : null}
        </FloatingDialog>
    );
};

export default DisableAdDialog;
