import * as React from 'react';

import styles from './styles/agentReviews.cssm';

type Props = {
    name: string;
};

const ReviewerImage = ({ name }: Props) => {
    const words = name.split(' ').filter((x) => x !== '');
    let letters = '';
    if (words.length >= 2) {
        letters = words[0][0] + words[1][0];
    } else if (words.length === 1) {
        letters = words[0].substring(0, 2);
    }
    letters = letters.toUpperCase();

    return (
        <div className={styles.newReviewerImage}>
            <div>{letters}</div>
        </div>
    );
};

export default ReviewerImage;
