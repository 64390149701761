import * as React from 'react';
// @ts-ignore
import { useMakeCancelable } from 'horizontal/util';

import { StratAPI } from '../api';

type Props = {
    children: React.ReactNode;
};

type State = {
    readonly token: string | null | undefined;
    readonly error: boolean | null | undefined;
};

const defaultState = Object.freeze({
    token: null,
    error: null,
});

type SetError = {
    type: 'SET_ERROR';
    error: boolean;
};

type SetToken = {
    type: 'SET_TOKEN';
    token: string;
};

type Clear = {
    type: 'CLEAR';
};

type Actions = SetError | SetToken | Clear;

const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case 'SET_ERROR':
            return {
                token: null,
                error: action.error,
            };
        case 'SET_TOKEN':
            return {
                token: action.token,
                error: null,
            };
        case 'CLEAR':
            return {
                token: null,
                error: null,
            };
        default:
            return state;
    }
};

export const HCaptchaContext = React.createContext<
    [State, (token: string) => void, () => void, () => void]
>([defaultState, (_token: string) => {}, () => {}, () => {}]);

const HCaptchaContextProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const makeCancelable = useMakeCancelable();

    const onError = React.useCallback(() => dispatch({ type: 'SET_ERROR', error: true }), []);

    const onTokenChange = React.useCallback(
        (token: string) => {
            if (!token) {
                return;
            }

            makeCancelable(
                new StratAPI().validateCaptcha(token).then(({ status }) => {
                    if (status !== 200) {
                        dispatch({ type: 'SET_ERROR', error: true });
                        return;
                    }

                    dispatch({ type: 'SET_TOKEN', token });
                }),
            );
        },
        [makeCancelable, dispatch],
    );

    const clearToken = React.useCallback(() => {
        dispatch({ type: 'CLEAR' });
    }, [dispatch]);

    return (
        <HCaptchaContext.Provider value={[state, onTokenChange, onError, clearToken]}>
            {children}
        </HCaptchaContext.Provider>
    );
};

export default HCaptchaContextProvider;
