import type { RoutingContextWithMiddlewares } from 'strat/app';
import { selectUserRoles } from 'strat/user/session';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';

import Page from 'horizontal/pages/page';

const ensureProAgentHasAccess = (context: RoutingContextWithMiddlewares): boolean => {
    const userRoles = selectUserRoles(context.redux.store.getState());
    if (isProAgent(userRoles) && !isProAgencyOwner(userRoles)) {
        context.rendering.renderPage(Page.NOT_FOUND);
        return false;
    }

    return true;
};

export default ensureProAgentHasAccess;
