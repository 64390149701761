import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';
import Category from '@app/branding/category';

import { AdCommonExtraField } from 'horizontal/branding/adExtraFields';
import type { FlatCategoryField, Category as CategoryType } from 'horizontal/types';

const getInputFieldPlaceholder = (
    i18n: I18n,
    field: FlatCategoryField,
    category: CategoryType | null | undefined,
) => {
    const title = field.name || field.attribute;
    if (!title) {
        return '';
    }

    const placeholder = t(i18n)`Enter ${title.toLowerCase()}`;
    if (Category.isOfCarsType(category) && field.attribute === AdCommonExtraField.VEHICLE_MILEAGE) {
        return placeholder + t(i18n)`, eg. 42,500`;
    }
    return placeholder;
};

export default getInputFieldPlaceholder;
