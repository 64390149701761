import * as React from 'react';
import { Flex, LoadingAnimation } from 'strat/components';

import styles from './styles/loadingCard.cssm';

const LoadingCard = () => {
    return (
        <Flex className={styles.ad} fillContainer>
            <LoadingAnimation className={styles.status} />
            <Flex column className={styles.date} justifyCenter>
                <LoadingAnimation className={styles.date} />
            </Flex>
            <Flex column fillContainer>
                <Flex className={styles.details} fillContainer alignCenter>
                    <LoadingAnimation className={styles.image} />
                    <LoadingAnimation width={'18rem'} height={'2rem'} />
                    <LoadingAnimation width={'7.5rem'} height={'2rem'} />
                    <LoadingAnimation width={'6rem'} height={'2rem'} className={styles.pill} />
                    <LoadingAnimation width={'10rem'} height={'2rem'} />
                </Flex>
                <Flex className={styles.analytics} fillContainer>
                    <Flex alignCenter>
                        <LoadingAnimation width={'7.5rem'} height={'2rem'} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default LoadingCard;
