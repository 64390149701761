import React from 'react';

const MAX_RETRIES = 3;
const RETRIES_INTERVAL_SECONDS = 20;

type Props = {
    callback: (retries: React.MutableRefObject<number>, maxRetries: number) => void;
    immediate?: boolean;
    maxRetries?: number;
    retriesInterval?: number;
};

const useCallbackWithInterval = ({
    callback,
    immediate = false,
    maxRetries = MAX_RETRIES,
    retriesInterval = RETRIES_INTERVAL_SECONDS,
}: Props) => {
    const retries = React.useRef(0);
    const [isTriggered, setIsTriggered] = React.useState(false);
    const executionInterval = React.useRef<ReturnType<typeof setInterval>>();

    React.useEffect(
        () => () => {
            if (executionInterval.current) {
                clearInterval(executionInterval.current);
            }
        },
        [],
    );

    const intervalCallBack = React.useCallback(
        (retries: React.MutableRefObject<number>) => {
            callback(retries, maxRetries);
        },
        [maxRetries, callback],
    );

    /**
     * Trigger function that should be triggered under an
     * interval, this should be under one interval only.
     */
    const trigger = React.useCallback(() => {
        if (isTriggered) {
            return;
        }
        setIsTriggered(true);
        /**
         * if we want to start from time 0, then we will simply
         * invoke the function immediately.
         */
        if (immediate) {
            intervalCallBack(retries);
        }
        executionInterval.current = setInterval(
            () => intervalCallBack(retries),
            retriesInterval * 1000,
        );
    }, [isTriggered, immediate, intervalCallBack, retriesInterval]);

    return { trigger };
};

export default useCallbackWithInterval;
