import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';

import type { ChatRoomsQuery } from 'horizontal/chat/types';

import styles from './styles/quickFilters.cssm';

type Props = {
    query: ChatRoomsQuery;
    setQuery: (prevState: ChatRoomsQuery | ((prevState: ChatRoomsQuery) => ChatRoomsQuery)) => void;
};

const quickFiltersDefault = {
    isImportant: false,
    isUnread: false,
};

const QuickFilters = ({ query, setQuery }: Props) => {
    const i18n = useI18n();
    const allSelected = !query.isImportant && !query.isUnread;

    const options = React.useMemo(() => {
        return [
            {
                label: t(i18n)`All`,
                option: null,
                onClick: () => setQuery((prev) => ({ ...prev, ...quickFiltersDefault })),
            },
            {
                label: t(i18n)`Unread Chats`,
                option: 'isUnread',
                onClick: () =>
                    setQuery((prev) => ({ ...prev, ...quickFiltersDefault, isUnread: true })),
            },
            {
                label: t(i18n)`Important`,
                option: 'isImportant',
                onClick: () =>
                    setQuery((prev) => ({ ...prev, ...quickFiltersDefault, isImportant: true })),
            },
        ];
    }, [i18n, setQuery]);

    const trackFilterClicked = () => {
        trigger(Triggers.CHAT_FILTER, { view_section: ViewSections.BODY });
    };

    return (
        <Flex column noShrink className={styles.container}>
            <Text.Small uppercase>{t(i18n)`Quick filters`}</Text.Small>
            <Flex className={styles.optionsContainer}>
                {options.map(({ onClick, label, option }) => (
                    <Text.Base
                        key={label}
                        className={classNames(styles.option, {
                            [styles.selected]: option
                                ? query[option as keyof ChatRoomsQuery] === true
                                : allSelected,
                        })}
                        onClick={() => {
                            onClick();
                            trackFilterClicked();
                        }}
                        role="button"
                    >
                        {label}
                    </Text.Base>
                ))}
            </Flex>
        </Flex>
    );
};

export default React.memo<Props>(QuickFilters);
