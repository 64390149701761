import * as React from 'react';
import { BackToTopButton } from 'strat/components';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { HomePageCampaign } from 'horizontal/marketingCampaigns';
import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import { GTMHomeDataLayer } from 'horizontal/gtm/components';
import Footer from 'horizontal/footer';
import {
    Wallpaper,
    BannerAdsCarousel,
    PopularCategories,
    CategoriesWithHitsSection,
} from 'horizontal/home';
import { SearchHistoryRecommendations } from 'horizontal/home';
import { HomeBanner, Position, Sizes } from 'horizontal/tagmanager';
import AppInstallBanner from '@app/home/appInstallBanner';
import { AllCategoriesSection } from 'horizontal/home';
import PromotedSearchesSection from 'horizontal/home/promotedSearchesSection';

import HomeHead from './homeHead';
import GptTokenResetter from './gptTokenResetter';
import styles from './styles/homePageDesktop.cssm';

const HomePageDesktop = {
    head: () => (
        <>
            <HomeHead />
            <GTMHomeDataLayer />
        </>
    ),
    body: () => (
        <Flex column stretchHeight fillContainer>
            <GptTokenResetter />
            <Header />
            <CategoryDropdownSwitcher />
            <BannerAdsCarousel />
            {settings.enableHomePageWallpaper && <Wallpaper />}
            {CONFIG.runtime.STRAT_MARKETING_CAMPAIGN && <HomePageCampaign />}
            <HomeBanner sizes={Sizes.desktop.home.top} position={Position.TOP} />
            {settings.enableHomePageAllCategories && <AllCategoriesSection />}
            <Flex fillContainer stretchWidth column className={styles.container}>
                <BackToTopButton />
                {settings.enableHomePagePopularCategories && <PopularCategories />}
                {settings.enableHomePageSearchHistoryRecommendations && (
                    <SearchHistoryRecommendations />
                )}
                <PromotedSearchesSection />
                <CategoriesWithHitsSection />
            </Flex>
            <HomeBanner sizes={Sizes.desktop.home.bottom} position={Position.BOTTOM} />
            <AppInstallBanner />
            <Footer />
        </Flex>
    ),
} as const;

export default HomePageDesktop;
