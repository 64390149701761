import * as React from 'react';
import classNames from 'classnames';
import AgencyInfo from '@app/contact/agencyInfo';
import ContactFormButtons from '@app/contact/contactFormModalButtons';

import { ViewSections } from 'strat/gtm';
import Designs from 'strat/branding/designs';

import ContactForm from './contactForm';
import ContactFormHeader from './contactFormHeader';
import styles from './styles/contactFormModal.cssm';

/**
 * Properties for {@see ContactFormHeader}.
 */
type HeaderProps = React.ComponentProps<typeof ContactFormHeader>;

/**
 * Properties for {@see ContactForm}.
 */
type ContactFormProps = React.ComponentProps<typeof ContactForm>;

/**
 * Properties for {@see AgencyInfo}.
 */
type AgencyInfoProps = React.ComponentProps<typeof AgencyInfo>;

/**
 * Properties for {@see ContactFormModal}.
 */
type Props = ContactFormProps &
    AgencyInfoProps & {
        visible: boolean;
        design?: Values<typeof Designs>;
        onVisibilityChanged: (visible: boolean) => void;
        renderHeader?: (props: HeaderProps) => React.ReactElement;
        renderAgencyInfo?: (props: AgencyInfoProps) => React.ReactElement;
        renderContactForm?: (props: ContactFormProps) => React.ReactElement;
        hasEmail: boolean | null | undefined;
    };

/**
 * Contents for a modal with a contact form.
 */
const ContactFormModal = (props: Props) => {
    const contactFormClassName = classNames(styles.form, props.className, {
        [styles.updatedFormPadding]: props.design !== Designs.FORM_DESIGN_2022,
    });

    return (
        <>
            {React.createElement(props.renderHeader, {
                onCloseClick: () => props.onVisibilityChanged(false),
                title: props.formTitle ? props.formTitle : props.title,
                design: props.design,
            })}
            {props.renderAgencyInfo &&
                React.createElement(props.renderAgencyInfo, {
                    agency: props.agency,
                    contactName: props.contactName,
                    referenceNumber: props.referenceNumber,
                    permitNumber: props.permitNumber,
                    projectNumber: props.projectNumber,
                })}
            <div className={contactFormClassName} aria-label="Contact form">
                {React.createElement(props.renderContactForm, {
                    referenceNumber: props.referenceNumber,
                    phoneNumber: props.phoneNumber,
                    propertyID: props.propertyID,
                    externalID: props.externalID,
                    contactButtons: ContactFormButtons,
                    onSendEmailClick: props.onSendEmailClick,
                    onKeepMeInformed: props.onKeepMeInformed,
                    onCallClick: props.onCallClick,
                    onPhoneNumberClick: props.onPhoneNumberClick,
                    inputClassName: classNames(styles.contactFormInput, {
                        [styles.updatedContactFormInput]: props.design !== Designs.FORM_DESIGN_2022,
                    }),
                    buttonsClassName: styles.contactFormButtons,
                    onSubmit: () => props.onVisibilityChanged(false),
                    message: props.message,
                    viewSection: ViewSections.EMAIL_POPUP,
                    hasEmail: props.hasEmail,
                    agencyName: props.agency && props.agency.name,
                    ...props,
                })}
            </div>
        </>
    );
};

ContactFormModal.defaultProps = {
    // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
    renderHeader: (props) => <ContactFormHeader {...props} />,
    // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
    renderAgencyInfo: (props) => <AgencyInfo viewSection={ViewSections.EMAIL_POPUP} {...props} />,
    // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
    renderContactForm: (props) => <ContactForm {...props} />,
};

export default ContactFormModal;
