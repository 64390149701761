import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLanguageRTL } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { Trans } from '@lingui/macro';

import AutoBoostIcon from 'horizontal/assets/icons/iconAutoBoostPackage_noinline.svg';
import FeaturedIcon from 'horizontal/assets/icons/iconFeaturedEn_noinline.svg';
import FeaturedIconArabic from 'horizontal/assets/icons/iconFeaturedAr_noinline.svg';
import PostMoreAdsIcon from 'horizontal/assets/icons/iconPostMoreAds_noinline.svg';

import styles from './styles/howToUseUserWallet.cssm';

const HowToUseUserWallet = () => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);

    return (
        <Flex column>
            <Flex className={styles.container}>
                <span className={styles.header}>
                    <Trans>
                        How to enjoy your <b> dubizzle </b> credit
                    </Trans>
                </span>
            </Flex>
            <Flex column className={styles.container}>
                <img src={PostMoreAdsIcon} className={styles.icon} />
                <span className={styles.title}>
                    <Trans>
                        <b>Post your Ads</b>
                    </Trans>
                </span>
                <span className={styles.text}>
                    <Trans>
                        You can use your dubizzle credit to post ads in categories with limited
                        availability for free ads.
                    </Trans>
                </span>
            </Flex>
            <Flex column className={styles.container}>
                <span className={styles.title}>
                    <Trans>
                        <b>Promote your Ads</b>
                    </Trans>
                </span>

                <span className={styles.text}>
                    <Trans>
                        Once your ad is active, you can promote it using paid advertising services
                        to reach more buyers.
                    </Trans>
                </span>

                <Flex>
                    <Flex className={styles.promoteAdsContainer}>
                        <Flex alignCenter className={styles.borderedContainer}>
                            <Flex column className={styles.section}>
                                {isLanguageRTL && (
                                    <img src={FeaturedIconArabic} className={styles.icon} />
                                )}
                                {!isLanguageRTL && (
                                    <img src={FeaturedIcon} className={styles.icon} />
                                )}
                                <Flex column>
                                    <span className={styles.text}>
                                        <Trans>
                                            <b> Feature your Ads </b>
                                        </Trans>
                                    </span>

                                    <span className={styles.smallText}>
                                        <Trans>
                                            Get noticed with the “Featured” tag among the top
                                        </Trans>
                                    </span>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex alignCenter className={styles.borderedContainer}>
                            <Flex column className={styles.section}>
                                <img src={AutoBoostIcon} className={styles.icon} />

                                <Flex column>
                                    <span className={styles.text}>
                                        <Trans>
                                            <b>Boost your Ad to Top</b>
                                        </Trans>
                                    </span>

                                    <span className={styles.smallText}>
                                        <Trans>Your ad will be auto-refreshed periodically</Trans>
                                    </span>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex alignCenter justifyCenter className={styles.link}>
                <Link route={RouteNames.BUSINESS_PACKAGES}>
                    <span className={styles.smallText}>
                        <Trans>Check out available offers on bundles</Trans>
                    </span>
                </Link>
            </Flex>
        </Flex>
    );
};

export default HowToUseUserWallet;
