import type { PatternsCollection } from 'react-true-router';

const defaultRoutePatterns: PatternsCollection = [
    [
        '^/ad/',
        {
            name: 'slug',
            pattern: '([0-9A-z\xA8-\xFE\u0600-\u06FF-]*)',
        },
        '-ID',
        {
            name: 'externalID',
            pattern: '([0-9]+)',
        },
        '.html',
        '(?:\\?)?',
    ],
    [
        '^/ad/',
        {
            name: 'slug',
            pattern: '([0-9A-z\xA8-\xFE\u0600-\u06FF-]*)',
        },
        '-ID',
        {
            name: 'hash',
            pattern: '([0-9a-zA-Z]+)',
        },
        '.html',
        '(?:\\?)?',
    ],
    // for convenience only
    [
        '^/ad/',
        {
            name: 'externalID',
            pattern: '([0-9]+)',
        },
        '(?:\\?)?',
    ],
];

export default defaultRoutePatterns;
