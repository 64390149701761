import * as React from 'react';
import { useDispatch } from 'react-redux';
import { PopUp } from 'strat/modal';
import IconAlert from '@app/assets/icons/iconAlert.svg';
import { Text } from 'strat/components';

import type { MixedAd } from '../types';

import { Actions } from './state';
import { usePopUpState } from './hooks';
import styles from './styles/favoritesLimitExceededPopUp.cssm';

type Props = {
    readonly ad: MixedAd;
};

const FavoritesLimitExceededPopUp = ({ ad }: Props) => {
    const dispatch = useDispatch();
    const [visible, errorMessage] = usePopUpState(ad);
    return (
        <PopUp
            className={styles.container}
            visible={visible}
            onVisibilityChanged={() => dispatch({ type: Actions.CLEAR_ERROR })}
        >
            <IconAlert className={styles.alertIcon} />
            <Text.Regular>{errorMessage}</Text.Regular>
        </PopUp>
    );
};

export default FavoritesLimitExceededPopUp;
