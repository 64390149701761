//@ts-nocheck
import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import type { PropertyData } from '../types';
import propertyTransformer from '../propertyTransformer';

export type FetchPropertyParams = {
    externalID?: string | null | undefined;
    slug?: string | null | undefined;
};

/**
 * Fetcher factory for fetching properties.
 */
const factory = new FetcherFactory('property', (params: FetchPropertyParams, state: any) => {
    const apiParams = { external_id: params.externalID, slug: params.slug } as const;
    return Promise.all([
        new StratAPI(state.i18n.language).ad(apiParams),
        new StratAPI(state.i18n.language).propertyPageInternalLinks(apiParams),
    ]).then(([ad, links]: [any, any]) => ({
        data: {
            ...propertyTransformer(ad.data, state.i18n.language, CONFIG.build.ENABLE_MERGED_INDEX),
            links: links.status === 200 ? links.data : [],
        },
        status: ad.status,
    }));
});

/**
 * Action creator for fetching a property from
 * the back-end.
 */
const fetchProperty = factory.creator();

/**
 * Action creator for pre-loading a property from
 * search results.
 */
const preloadProperty = (property: PropertyData) => ({
    type: factory.actions.preload,
    params: { externalID: property.externalID },
    data: property,
});

/**
 * Reducers for property.
 */
const propertyReducer = factory.reducer();

export { fetchProperty, preloadProperty };

export default propertyReducer;
