//@ts-nocheck
import type { Dispatch } from 'redux';

const Actions = Object.freeze({
    SET_HAS_SITE_UNDER_MAINTENANCE_BANNER: 'BANNERS/SET_HAS_SITE_UNDER_MAINTENANCE_BANNER',
});

const defaultState = {
    siteUnderMaintenanceBanner: false,
} as const;

const siteUnderMaintenanceBannerReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_HAS_SITE_UNDER_MAINTENANCE_BANNER:
            return {
                ...state,
                siteUnderMaintenanceBanner: action.visible,
            };

        default:
            return state;
    }
};

const setSiteUnderMaintenanceBannerVisible =
    (siteUnderMaintenanceBannerVisible: any) => (dispatch: Dispatch) => {
        dispatch({
            type: Actions.SET_HAS_SITE_UNDER_MAINTENANCE_BANNER,
            visible: siteUnderMaintenanceBannerVisible,
        });
    };

export { setSiteUnderMaintenanceBannerVisible };

export default siteUnderMaintenanceBannerReducer;
