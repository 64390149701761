import url from 'url';

import React from 'react';

import styles from './styles/GTMFrame.cssm';

/**
 * Renders a <iframe> tag which loads the Google Tag Manager (GTM).
 */
export default () => {
    const containerID = CONFIG.build.GTM_CONTAINER_ID;

    if (!containerID) {
        console.warn('Google Tag Manager disabled, GTM settings not set.');
        return null;
    }

    const frameURL = {
        protocol: 'https:',
        host: 'www.googletagmanager.com',
        pathname: '/ns.html',
        query: {
            id: containerID,
        },
    } as const;

    const src = url.format(frameURL);
    return (
        <noscript>
            <iframe className={styles.frame} src={src} />
        </noscript>
    );
};
