import { HTTPApi } from 'strat/util';

/**
 * The decoding of the Valhalla API routing return value is taken
 * from here: https://valhalla.github.io/valhalla/decoding/
 */
const decodePolyline = (str: string, precision = 6): [number, number][] => {
    let index = 0,
        lat = 0,
        lng = 0,
        shift = 0,
        result = 0,
        byte = null,
        latitude_change,
        longitude_change;
    const factor = 10 ** precision,
        coordinates: [number, number][] = [];

    while (index < str.length) {
        byte = null;
        shift = 0;
        result = 0;
        do {
            byte = str.charCodeAt(index) - 63;
            index += 1;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);

        latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
        shift = result = 0;

        do {
            byte = str.charCodeAt(index) - 63;
            index += 1;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);

        longitude_change = result & 1 ? ~(result >> 1) : result >> 1;
        lat += latitude_change;
        lng += longitude_change;
        coordinates.push([lng / factor, lat / factor]);
    }

    return coordinates;
};

/**
 * Provides access to the Valhalla API
 */
class ValhallaAPI extends HTTPApi {
    /**
     * Turns the specified relative path into a absolute URL.
     * @param {string} path The path to turn into an absolute URl.
     * @returns {string} An absoluate URL to the specified relative path.
     */
    static buildAbsoluteUrl(path: string) {
        const baseURL = process.env.IS_SERVER
            ? CONFIG.runtime.VALHALLA_PRIVATE_BACKEND_URL
            : CONFIG.runtime.VALHALLA_BACKEND_URL;
        return `${baseURL || ''}${path}`;
    }

    get(path: string, parameters?: Record<any, any> | null): Promise<any> {
        // @ts-expect-error - TS2339 - Property 'buildAbsoluteUrl' does not exist on type 'Function'.
        const url = this.constructor.buildAbsoluteUrl(path);
        const headers = CONFIG.runtime.VALHALLA_API_KEY
            ? {
                  Authorization: `Bearer ${CONFIG.runtime.VALHALLA_API_KEY}`,
                  'X-CSRFToken': null,
              }
            : {};
        return super.request(url, parameters, { headers });
    }

    commuteMatrix(sources: Array<any>, targets: Array<any>, costing = 'auto'): Promise<any> {
        return this.get(
            `/sources_to_targets?json=${JSON.stringify({ sources, targets, costing })}`,
        );
    }

    isochrone(locations: Array<any>, contours: Array<any>, costing = 'auto'): Promise<any> {
        return this.get(
            `/isochrone?json=${JSON.stringify({
                locations,
                contours,
                costing,
                polygons: true,
                denoise: 0.1,
                generalize: 0,
            })}`,
        );
    }

    route(
        source: Record<any, any>,
        target: Record<any, any>,
        costing = 'auto',
    ): Promise<[number, number][] | null> {
        return this.get(
            `/route?json=${JSON.stringify({
                locations: [
                    { lat: source.lat, lon: source.lng },
                    { lat: target.lat, lon: target.lng },
                ],
                costing,
                directions_type: 'none',
            })}`,
        ).then(({ data, status }) =>
            status === 200 ? decodePolyline(data.trip.legs[0].shape) : null,
        );
    }
}

export default ValhallaAPI;
