export type PhoneNumber = {
    readonly country: string;
    readonly number: string;
    readonly nationalNumber: string;
    readonly countryCallingCode: string;
    readonly ext: string | null | undefined;
    readonly carrierCode: string | null | undefined;
    readonly isValid: () => boolean;
    readonly getType: () => string;
    readonly format: (arg1: string) => string;
    readonly formatInternational: () => string;
    readonly formatNational: () => string;
    readonly getURI: () => string;
};

export type LibPhoneNumber = {
    parsePhoneNumberWithError: (arg1: string) => PhoneNumber;
};

export enum LibPhoneNumberMetadata {
    MAX = 'max',
    MIN = 'min',
    MOBILE = 'mobile',
}
