//@ts-nocheck
import { SearchService, SearchJob } from '@sector-labs/fe-search-redux';
import { AlgoliaSearchBackend } from '@sector-labs/fe-search-redux/backend/algolia';
import {
    FilterCollection,
    ExactFilter,
    RefinementFilter,
} from '@sector-labs/fe-search-redux/filters';

const fetchFromAlgolia = (
    { slicedData, status },
    appId: any,
    apiKey: any,
    indexName: any,
    count: any,
    filterAttr: any,
) => {
    if ((status !== 200 && status !== 304) || count === 0) {
        return {
            data: {
                hits: [],
            },
            status,
        };
    }

    const properties = slicedData;

    if (properties.length === 0) {
        return {
            data: {
                hits: [],
            },
            status,
        };
    }

    const filters = new FilterCollection();
    filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
    filters.refine(
        new RefinementFilter({
            attribute: filterAttr,
            value: properties,
        }),
    );

    const job = new SearchJob(indexName, filters, {
        facets: [],
        count,
    });

    const backend = new AlgoliaSearchBackend({ appId, apiKey });
    const service = new SearchService({ backend });

    return service
        .fetchJob(job)
        .then((content) => ({
            data: {
                hits: content.hits,
            },
            status: 200,
        }))
        .catch(() => ({
            data: {
                hits: [],
            },
            status: 404,
        }));
};

export default fetchFromAlgolia;
