import * as React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'strat/modal';
import { Triggers } from 'strat/gtm';

import type { Location } from 'horizontal/types';
import { useTrackLocationClick } from 'horizontal/gtm';

import LocationInput from './locationInput';
import LocationSuggestionList from './locationSuggestionList';
import styles from './styles/locationDropdown.cssm';

export type LocationDropdownProps = {
    readonly location: Location | null | undefined;
    readonly loading?: boolean;
    readonly onLocationSelected: (location?: Location | null | undefined) => void;
    readonly onCurrentLocationSelected?: () => void;
    readonly className?: string;
    readonly searchInputClassName?: string;
    readonly renderSearchIcon?: boolean;
    readonly searchIconClassName?: string;
    readonly showCurrentLocationSelector?: boolean;
    readonly withLocationRadiusFilter?: boolean;
    readonly maxLevel?: number;
    readonly shouldRenderTriggerRight?: boolean;
    readonly dropdownContainerClassname?: string;
};

const LocationDropdown = ({
    location,
    loading,
    onLocationSelected,
    onCurrentLocationSelected,
    className,
    searchInputClassName,
    renderSearchIcon,
    searchIconClassName,
    showCurrentLocationSelector,
    withLocationRadiusFilter,
    maxLevel,
    shouldRenderTriggerRight,
    dropdownContainerClassname = styles.content,
}: LocationDropdownProps) => {
    const [input, setInput] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const trackLocationClick = useTrackLocationClick();

    const renderTrigger = React.useCallback(
        (_, visible) => (
            <LocationInput
                location={location}
                onChange={setInput}
                onInputActiveChanged={setIsOpen}
                inputActive={visible}
                loading={loading}
                renderSearchIcon={renderSearchIcon}
                searchIcon={searchIconClassName}
                onLocationSelected={onLocationSelected}
                withLocationRadiusFilter={withLocationRadiusFilter}
                className={searchInputClassName}
                shouldRenderRight={shouldRenderTriggerRight}
            />
        ),
        [
            location,
            loading,
            setIsOpen,
            setInput,
            renderSearchIcon,
            searchIconClassName,
            onLocationSelected,
            withLocationRadiusFilter,
            searchInputClassName,
            shouldRenderTriggerRight,
        ],
    );

    const onLocationClicked = React.useCallback(
        (clickedLocation: Location) => {
            setInput('');
            setIsOpen(false);
            trackLocationClick(Triggers.CLICK_LOCATION, clickedLocation);
            onLocationSelected(clickedLocation);
        },
        [setInput, setIsOpen, onLocationSelected, trackLocationClick],
    );

    const onGeoPositionSelected = React.useCallback(() => {
        setInput('');
        setIsOpen(false);
        trackLocationClick(Triggers.CLICK_CURRENT_LOCATION);
        if (onCurrentLocationSelected) {
            onCurrentLocationSelected();
        }
    }, [setInput, setIsOpen, onCurrentLocationSelected, trackLocationClick]);

    return (
        <div className={classNames(styles.container, className)} aria-label="Location input">
            <Dropdown
                triggerIsDismissble
                open={isOpen}
                renderTrigger={renderTrigger}
                onDismissed={() => setIsOpen(false)}
            >
                <div className={dropdownContainerClassname} aria-label="Locations dropdown">
                    <LocationSuggestionList
                        input={input}
                        onLocationClicked={onLocationClicked}
                        onGeoPositionSelected={onGeoPositionSelected}
                        showCurrentLocationSelector={showCurrentLocationSelector}
                        maxLevel={maxLevel}
                    />
                </div>
            </Dropdown>
        </div>
    );
};

export default LocationDropdown;
