import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useRouter } from 'react-true-router';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { StylelessButton } from 'strat/generic';
import IconClose from '@app/assets/icons/iconClose.svg';
import { useSelector } from 'react-redux';
import { selectCurrency } from 'strat/i18n/money';
import Currency from 'strat/i18n/money/currency';
import { RouteNames } from 'strat/routes';
import { Text, Flex, Button } from 'strat/components';
import { Toast } from 'strat/modal';
import { selectLayout } from 'strat/layout/selectors';
import Layout from 'strat/layout/layout';

import IconSuccess from '@app/assets/icons/iconSuccess_noinline.svg';
import { UserWalletsSection } from 'horizontal/userWallet/userWallet';

import styles from './walletTopUpCampaign.cssm';

type ToastContentProps = {
    setToastVisible: (visible: boolean) => void;
    isCreateAccountFlow?: boolean;
};

const ToastContent = ({ setToastVisible, isCreateAccountFlow }: ToastContentProps) => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    const { baseCurrency } = useSelector(selectCurrency);
    const router = useRouter();
    const layout = useSelector(selectLayout);
    const isMobile = layout === Layout.MOBILE;

    return (
        <Flex className={styles.toastContainer}>
            <img src={IconSuccess} alt={'Icon Success'} className={styles.successIcon} />
            <Flex column={isMobile} justifySpaceBetween={!isMobile} stretchWidth={!isMobile}>
                <Flex column>
                    <Text.XLarge bold>
                        <Trans>Congratulations!</Trans>
                    </Text.XLarge>
                    {isCreateAccountFlow ? (
                        <Text.Large>
                            <Trans>
                                You now have a {brandName} account with{' '}
                                {CONFIG.runtime.STRAT_WALLET_TOP_UP_CAMPAIGN_GIFT_VALUE}{' '}
                                {Currency.symbol(i18n, baseCurrency)} in your {brandName} Wallet!
                            </Trans>
                        </Text.Large>
                    ) : (
                        <Text.Large>
                            <Trans>
                                You have successfully added your phone number to your profile and
                                received {CONFIG.runtime.STRAT_WALLET_TOP_UP_CAMPAIGN_GIFT_VALUE}{' '}
                                {Currency.symbol(i18n, baseCurrency)} in your {brandName} Wallet!
                            </Trans>
                        </Text.Large>
                    )}
                </Flex>
                <Flex>
                    <Button
                        tertiary
                        onClick={() => {
                            router.pushRoute(RouteNames.WALLET, {
                                section: UserWalletsSection.OVERVIEW,
                            });
                        }}
                        type="button"
                    >
                        <Text.Regular>
                            <Trans>Go to Wallet</Trans>
                        </Text.Regular>
                    </Button>
                </Flex>
            </Flex>
            <div className={styles.buttonContainer}>
                <StylelessButton
                    onClick={() => {
                        setToastVisible(false);
                    }}
                    aria-label={'Close button'}
                    type="button"
                >
                    <IconClose className={styles.closeButton} />
                </StylelessButton>
            </div>
        </Flex>
    );
};

type WalletTopUpSuccessToastProps = {
    isCreateAccountFlow?: boolean;
    setToastVisible: (visible: boolean) => void | undefined;
};
const WalletTopUpSuccessToast = ({
    isCreateAccountFlow,
    setToastVisible,
}: WalletTopUpSuccessToastProps) => (
    <Toast
        key="toast"
        onHide={() => {
            setToastVisible(false);
        }}
        timeout={10000}
    >
        <ToastContent setToastVisible={setToastVisible} isCreateAccountFlow={isCreateAccountFlow} />
    </Toast>
);
export default WalletTopUpSuccessToast;
