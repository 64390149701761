import { ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import { useSearchFilterValueOfType } from '@sector-labs/fe-search-redux/state';
import FilterValues from 'strat/search/filterValues';

import type { Location } from 'horizontal/types';

const useLocationFilterValue = (): Location | null | undefined =>
    // @ts-expect-error - TS2769 - No overload matches this call.
    useSearchFilterValueOfType<Location>(ObjectExactFilter, FilterValues.location.attribute);

export default useLocationFilterValue;
