import * as React from 'react';
import ReactDOM from 'react-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';

import type { Geoloc } from './types';
import styles from './styles/marker.cssm';

declare let mapboxgl: MapboxGLGlobal;

type Props = {
    /**
     * The position of the marker.
     */
    position: Geoloc;
    /**
     * The map.
     */
    map?: MapType;
    /**
     * The width of the marker.
     */
    width?: number;
    /**
     * The height of the marker.
     * Used together with width to compute the marker offset.
     */
    height?: number;
    /**
     * If this prop is present then it will be rendered on a popup on marker hover.
     */
    renderPopup?: () => React.ReactNode;
    /**
     * React children to be rendered inside the marker.
     */
    children?: React.ReactNode;
    /**
     * Optional className to pass to the marker.
     */
    className?: string;
    /**
     * Whether marker is the main marker of the property.
     * Setting this to true will add the main class to the marker container.
     */
    main?: boolean;
    /**
     * Whether the marker is active(aka. the popup is open).
     */
    active?: boolean;
    /**
     * Callback to when the mouse enters the marker surface.
     */
    onMouseEnter?: (arg1: any) => void;
    /**
     * Callback when a marker drag ended.
     */
    onDragEnd?: (arg1: { latitude: number; longitude: number }) => void;
    /**
     * Callback to when the mouse leaves the marker surface.
     */
    onMouseLeave?: (arg1: any) => void;
    /**
     * Callback to when the marker is clicked (the container).
     */
    onClick?: (arg1: any) => void;
    /**
     * Indicates if the map should fly to the marker when clicked.
     * Defaults to true.
     */
    flyOnClick?: boolean;
    /**
     * Indicates if the marker is draggable.
     */
    draggable?: boolean;
    /**
     * Indicates if the map should fit around the marker.
     * In case of multiple markers, the map will take all into account, and fit around their bounding box.
     */
    autoFit?: boolean;
    mapboxGL?: MapboxGLGlobal;
};

type State = {
    marker: MarkerType | null | undefined;
};

class Marker extends React.Component<Props, State> {
    /**
     * The element in which the marker gets rendered.
     */
    element: HTMLElement;

    popup: PopupType | null | undefined;

    static defaultProps = {
        flyOnClick: true,
        draggable: false,
        autoFit: false,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.element = document.createElement('div');

        this.popup = null;
        this.state = { marker: null };
    }

    initializeMarker() {
        const {
            map,
            position,
            width,
            height,
            renderPopup,
            main,
            active,
            draggable,
            autoFit,
            mapboxGL = mapboxgl,
        } = this.props;

        if (!map || !mapboxGL) {
            return;
        }

        this.element.className = classNames(styles.markerContainer, { [styles.main]: !!main });

        // @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
        const marker = new mapboxGL.Marker({
            element: this.element,
            offset: width && height ? { x: -width / 2, y: -height } : null,
            draggable,
            autoFit,
        })
            .setLngLat(position)
            .addTo(map);

        if (renderPopup) {
            // @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
            this.popup = new mapboxGL.Popup({
                offset: height,
                closeOnClick: false,
                closeButton: false,
                className: styles.popup,
                // @ts-expect-error - TS2345 - Argument of type 'ReactNode' is not assignable to parameter of type 'ReactElement<any, string | JSXElementConstructor<any>>'.
            }).setHTML(ReactDOMServer.renderToStaticMarkup(renderPopup()));
            marker.setPopup(this.popup);

            if (active) {
                marker.togglePopup();
            }
        }

        if (draggable) {
            marker.on('dragend', this.onDragEnd);
        }

        this.setState({ marker });
    }

    onClick() {
        const { map, position, onClick, flyOnClick } = this.props;

        if (onClick) {
            // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
            onClick();
        }

        if (map && flyOnClick) {
            map.flyTo({ center: position });
        }
    }

    onDragEnd() {
        const { onDragEnd } = this.props;
        const marker = this.state.marker;

        if (onDragEnd && marker) {
            const lngLat = marker.getLngLat();
            onDragEnd({ latitude: lngLat.lat, longitude: lngLat.lng });
        }
    }

    togglePopup() {
        const { marker } = this.state;

        if (marker) {
            marker.togglePopup();
        }
    }

    onMouseEnter() {
        const { onMouseEnter } = this.props;

        if (onMouseEnter) {
            // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
            onMouseEnter();
        }

        if (this.popup && !this.popup.isOpen()) {
            this.togglePopup();
        }
    }

    onMouseLeave() {
        const { onMouseLeave } = this.props;

        if (onMouseLeave) {
            // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
            onMouseLeave();
        }

        if (this.popup && this.popup.isOpen()) {
            this.togglePopup();
        }
    }

    componentDidMount() {
        if (process.env.IS_BROWSER) {
            this.initializeMarker();
        }
    }

    componentWillUnmount() {
        const { marker } = this.state;

        if (marker) {
            marker.off('dragend', this.onDragEnd);
            marker.remove();
        }
    }

    componentDidUpdate(oldProps: Props) {
        const { active, position } = this.props;
        const { marker } = this.state;

        if (oldProps.active !== active) {
            this.togglePopup();
        }

        if (oldProps.position !== position) {
            if (marker) {
                marker.off('dragend', this.onDragEnd);
                marker.remove();
            }
            this.initializeMarker();
        }
    }

    render() {
        return ReactDOM.createPortal(
            <div
                className={[styles.marker, this.props.className].join(' ')}
                onClick={this.onClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                aria-label="Marker"
            >
                {this.props.children}
            </div>,
            this.element,
        );
    }
}

export default Marker;
