const ImageSlideShowItemType = Object.freeze({
    PHOTO: 'photo',
    VIDEO: 'video',
    PANORAMA: 'panorama',
    FLOOR_PLAN: 'floor_plan',
    UNIT_PLAN: 'unit_plan',
    CONTACT_FORM: 'contact_form',
    WHATSAPP_CONTACT_FORM: 'whatsapp_contact_form',
});

export default ImageSlideShowItemType;
