import * as React from 'react';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { AdsCarousel, AdsCarouselCompact } from 'horizontal/ad';

import SearchHistoryRecommendationsTitle from './searchHistoryRecommendationsTitle';
import useSearchHistoryRecommendations from './useSearchHistoryRecommendations';
import styles from './styles/searchHistoryRecommendations.cssm';

const computeGroupSize = (windowWidth: number) => {
    if (windowWidth <= CONFIG.build.DEVICE_WIDTH_TABLET) {
        return 2;
    }
    if (windowWidth <= CONFIG.build.DEVICE_WIDTH_DESKTOP) {
        return 3;
    }
    return 4;
};

const SearchHistoryRecommendations = ({ compact }: { compact?: boolean }) => {
    const { lastSearchListings, searchHistory } = useSearchHistoryRecommendations();

    if (
        !lastSearchListings?.length ||
        // carousel doesn't look good with less than 4 listings
        lastSearchListings.length < 4 ||
        !searchHistory?.length ||
        !Object.keys(searchHistory[0]).length
    ) {
        return null;
    }

    const lastSearchHistoryFilters = new FilterCollection(searchHistory[0]);

    return (
        <>
            <div className={styles.container} aria-label="Search History Recommendations">
                <SearchHistoryRecommendationsTitle lastSearchFilters={lastSearchHistoryFilters} />
                {compact ? (
                    <AdsCarouselCompact ads={lastSearchListings} />
                ) : (
                    <AdsCarousel
                        ads={lastSearchListings}
                        computeGroupSize={computeGroupSize}
                        hideIncompletePages
                    />
                )}
            </div>
            <div className={styles.sectionSeparator} />
        </>
    );
};
export default SearchHistoryRecommendations;
