import React from 'react';

import styles from './styles/expandable.cssm';

/**
 * Properties for {@see Expandable}.
 */
type Props = {
    expanded: boolean;
    height: string;
    children: React.ReactNode;
    className?: string;
};

export default (props: Props): React.ReactElement => {
    let className = props.expanded
        ? `${styles.container} ${styles.expanded}`
        : `${styles.container} ${styles.collapsed}`;

    if (props.className) {
        className += ` ${props.className}`;
    }

    const style = {
        maxHeight: props.expanded ? '' : props.height,
    } as const;

    return (
        <div className={className} style={style}>
            {props.children}
        </div>
    );
};
