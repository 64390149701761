//@ts-nocheck
import { SearchRequestSortType, SearchRequestOrder } from '@sector-labs/fe-search-redux/backend';

import choices from './choices';
import Values from './values';

/* These settings are relevant for ElasticSearch backend */
const SortOptions = [
    {
        value: Values.PRICE_ASC,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'price', order: SearchRequestOrder.ASC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    {
        value: Values.PRICE_DESC,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'price', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    {
        value: Values.DATE_ASC,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'createdAt', order: SearchRequestOrder.ASC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    {
        value: Values.DATE_DESC,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'createdAt', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    // TODO The next attributes will have to be customized for each site.
    // There are multiple configurations on the backend side that will
    // have to be replicated here on the frontend side to keep the compatibility
    // of the sorting between Algolia and ES.
    {
        value: Values.CITY_LEVEL_SCORE,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'cityLevelScore', order: SearchRequestOrder.DESC },
            { name: 'verifiedScore', order: SearchRequestOrder.DESC },
            { name: 'score', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    {
        value: Values.POPULAR,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            // For best performance, make sure this matches backend/AdsIndex
            { name: 'productScore', order: SearchRequestOrder.DESC },
            { name: 'score', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    {
        value: Values.VERIFIED_SCORE,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'verifiedScore', order: SearchRequestOrder.DESC },
            { name: 'productScore', order: SearchRequestOrder.DESC },
            { name: 'score', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
    {
        value: Values.PRODUCT_LABEL_SCORE,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [
            { name: 'productLabelScore', order: SearchRequestOrder.ASC },
            { name: 'productScore', order: SearchRequestOrder.ASC },
            { name: 'score', order: SearchRequestOrder.DESC },
            { name: 'createdAt', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    },
];

const getSortOptionsByValue = (value: any) => SortOptions.find((option) => option.value === value);

/* The default choice used to sort the ads for an user */
const defaultValue = Values.POPULAR;
const defaultChoice = (i18n: any) => choices(i18n).find((choice) => choice.value === '');

const SortByValues = {
    ...Values,
    DEFAULT: defaultValue,

    choices,
    defaultChoice,
    getSortOptionsByValue,
} as const;

export default SortByValues;
