import settings from '@app/branding/settings';

import Currency from './currency';
import type { ExchangeRates } from './exchangeRates';
import { obtainDefaultExchangeRates } from './exchangeRates';

/**
 * Available i18n/money actions.
 */
const Actions = Object.freeze({
    SET_EXCHANGE_RATES: 'I18N/MONEY/SET_EXCHANGE_RATES',
    CHANGE_BASE_CURRENCY: 'I18N/MONEY/CHANGE_BASE_CURRENCY',
});

/**
 * The type of each dispatched action.
 */
type Action = {
    type: string;
    exchangeRates?: ExchangeRates;
    baseCurrency?: string;
};

/**
 * The state type.
 */
type State = {
    readonly exchangeRates: ExchangeRates;
    readonly baseCurrency: string;
};

/**
 * Default state for i18n/money.
 */
const defaultState: State = {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<{ AED: string; EGP: string; EUR: string; GBP: string; INR: string; PKR: string; RUB: string; SAR: string; USD: string; CAD: string; JOD: string; XPF: string; ANG: string; NPR: string; QAR: string; ... 10 more ...; all(): string[]; }>'.
    baseCurrency: Currency[settings.baseCurrencyName],
    exchangeRates: obtainDefaultExchangeRates(),
};

/**
 * Reducer for i18n/money.
 */
const i18nMoneyReducer = (state: State | null | undefined = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.SET_EXCHANGE_RATES:
            return {
                ...state,
                exchangeRates: action.exchangeRates,
            };
        case Actions.CHANGE_BASE_CURRENCY:
            return {
                ...state,
                baseCurrency: action.baseCurrency,
            };

        default:
            return state;
    }
};

/**
 * Setter for the exchange rates.
 */
const setExchangeRates = (exchangeRates: ExchangeRates): Action => ({
    type: Actions.SET_EXCHANGE_RATES,
    exchangeRates,
});

const setBaseCurrency = (baseCurrency: string): Action => ({
    type: Actions.CHANGE_BASE_CURRENCY,
    baseCurrency,
});

export { setExchangeRates, setBaseCurrency };

export default i18nMoneyReducer;
