/**
 * Available route names.
 */
const RouteNames = Object.freeze({
    HOME: 'home' as const,
    SEARCH: 'search' as const,
    PROPERTY: 'property',
    PROJECT: 'project',
    AGENCY_SEARCH: 'agencySearch',
    AGENT_SEARCH: 'agentSearch',
    NOT_FOUND: 'notfound',
    AGENCY_DETAIL: 'agencyDetail',
    AGENCY_HOME: 'agencyHome',
    AGENT_DETAIL: 'agentDetail',
    AGENT_REVIEW: 'agentReview',
    AGENT_REVIEW_EMAIL_CONFIRMATION: 'agentReviewEmailConfirmation',
    FLOOR_PLANS_HOME: 'floorPlansHome',
    FLOOR_PLANS_TREE_NODE: 'floorPlansTreeNode',
    FLOOR_PLANS_DETAIL: 'floorPlansDetail',
    REPORTING_AD_PERFORMANCE_REPORT: 'reportingAdPerformanceReport',
    PLOT_FINDER: 'plotFinder',
    PLOT_FINDER_PROPERTY: 'plotFinderProperty',
    INTERNAL_ERROR: 'internalerror',
    UNSUBSCRIBE_ALERT: 'unsubscribelalert',
    STATIC_PAGE: 'staticPage',
    RESET_PASSWORD: 'resetPassword',
    VIEW_ALL_CITIES: 'viewAllCities',
    FAVORITES: 'adFavorites',
    RECOMMENDED_PROPERTIES: 'recommendedProperties',
    PHONE_MESSAGE_CONFIRMATION: 'phoneMessageConfirmation',
    VIEW_ALERT: 'viewAlert',
    ADD_PROPERTY: 'addProperty',
    MOBILE_APP_REDIRECT: 'mobileAppRedirect',
    CREATE_AD: 'createAd',
    POST_AD_SUCCESS: 'postAdSuccess',
    AD_UNDERTAKING_TERMS: 'adUndertakingTerms',
    UPDATE_AD: 'updateAd',
    AD_DETAILS: 'adDetails',
    CHAT_WIDGET: 'chatWidget',
    CHAT: 'chat',
    AD_MANAGEMENT: 'adManagement',
    APPLY_AD_LIMIT_PACKAGE: 'applyAdLimitPackage',
    SELLER_PROFILE: 'sellerProfile',
    AGENCY_PROFILE: CONFIG.build.STRAT_ENABLE_LEADERBOARD ? 'brokers' : 'companies',
    SITEMAP: 'sitemap',
    PAYMENT_SELECTION: 'paymentSelection',
    PAYMENT_SELECTION_FREE_AD_UNDERTAKING: 'paymentSelectionFreeAdUndertaking',
    PAYMENT_AREEBA: 'paymentAreeba',
    PAYMENT_JAZZ_CASH_MWALLET: 'paymentJazzCashMWallet',
    PAYMENT_PAYMOB_MWALLET: 'paymentPaymobMWallet',
    PAYMENT_SUCCESS: 'paymentSuccess',
    PAYMENT_PROCESSING: 'paymentProcessing',
    PAYMENT_SUCCESS_JAZZ_CASH_VOUCHER: 'paymentSuccessJazzCashVoucher',
    PAYMENT_FAWRY: 'paymentFawry',
    PAYMENT_BANK_TRANSFER: 'paymentBankTransfer',
    LOGIN: 'login',
    LOGIN_CALLBACK: 'loginCallback',
    LINK_LOGIN_CALLBACK: 'linkLoginCallback',
    USER_SETTINGS: 'userSettings',
    EDIT_PROFILE: 'editProfile',
    SELECT_SINGLE_PACKAGE: 'selectSinglePackage',
    BUSINESS_PACKAGES: 'businessPackages',
    SELECT_MULTIPLE_PACKAGES: 'selectMultiplePackages',
    VIEW_BUSINESS_PACKAGE_CART_ROUTE: 'viewBusinessPackageRoute',
    USER_ORDERS: 'userOrders',
    SAVE_CARD: 'saveCard',
    SELECT_PACKAGE: 'selectPackage',
    SELECT_PACKAGE_SUCCESS: 'selectPackageSuccess',
    TRU_VALUE: 'truValue',
    PAYMENT_TPAY: 'paymentTPay',
    PAYMENT_TPAY_OTP: 'paymentTPayOTP',
    LINK_IDENTITY_PROVIDER: 'linkIdentityProvider',
    CAPTCHA_CHALLENGE: 'captchaChallenge',
    REQUEST_DELIVERY: 'requestDelivery',
    PROPERTY_MARKET_ANALYSIS: 'propertyMarketAnalysis',
    HORIZONTAL_VERTICALS: 'olxHorizontalVerticals',
    AGENCY_PORTAL_ACTION_CENTER: 'agencyPortalActionCenter',
    AGENCY_PORTAL_ADS: 'agencyPortalAds',
    AGENCY_PORTAL_AGENTS: 'agencyPortalAgents',
    AGENCY_PORTAL_AGENT_DETAIL: 'agencyPortalAgentDetail',
    AGENCY_PORTAL_COMPANY_PROFILE: 'agencyPortalCompanyProfile',
    AGENCY_PORTAL_CREATE_AD: 'agencyPortalCreateAd',
    AGENCY_PORTAL_UPDATE_AD: 'agencyPortalUpdateAd',
    AGENCY_PORTAL_REJECTED_AD: 'agencyPortalRejectedAd',
    AGENCY_PORTAL_POST_AD_SUCCESS: 'agencyPortalPostAdSuccess',
    AGENCY_PORTAL_APPLY_AD_LIMIT: 'agencyPortalApplyAdLimit',
    AGENCY_PORTAL_HELP: 'agencyPortalHelp',
    AGENCY_PORTAL_HOME: 'agencyPortalHome',
    AGENCY_PORTAL_LEADS: 'agencyPortalLeads',
    AGENCY_PORTAL_PACKAGES: 'agencyPortalPackages',
    AGENCY_PORTAL_PACKAGE_DETAIL: 'agencyPortalPackageDetail',
    AGENCY_PORTAL_SELECT_PACKAGE_SUCCESS: 'agencyPortalSelectPackageSuccess',
    AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST: 'agencyPortalApplyAdProductsCreditCost',
    AGENCY_PORTAL_CREDIT_INFO: 'agencyPortalCreditInfo',
    PROVIDER_CASH_PAYMENT: 'providerCashPayment',
    AGENCY_PORTAL_AD_DETAIL: 'agencyPortalAdDetail',
    SAVED_SEARCHES: 'savedSearches',
    SMART_SHARE: 'smartShare',
    SMART_SHARE_ADD_RECOMMENDATIONS: 'smartShareAddRecommendations',
    TELE_SALES: 'teleSales',
    PACKAGE_PURCHASE_ERROR: 'packagePurchaseError',
    TRU_ESTIMATE: 'truEstimate',
    TRU_ESTIMATE_LOCKED_REPORT: 'truEstimateLockedReport',
    TRU_ESTIMATE_REPORT: 'truEstimateReport',
    WALLET: 'userWallet',
    UNIT_PLAN: 'unitPlan',
    SEARCH_FILTERS_API: 'searchFiltersAPI',
});

/**
 * Routes that are restricted to logged in users in some way.
 *
 * This list is used to determine whether to redirect the user
 * to the home page if they logout. If they are on any of these
 * routes, the redirect to the home page kicks in.
 */
export const restrictedRouteNames: Values<typeof RouteNames>[] = [
    RouteNames.REPORTING_AD_PERFORMANCE_REPORT,
    RouteNames.UNSUBSCRIBE_ALERT,
    RouteNames.PHONE_MESSAGE_CONFIRMATION,
    RouteNames.VIEW_ALERT,
    RouteNames.ADD_PROPERTY,
    RouteNames.MOBILE_APP_REDIRECT,
    RouteNames.CREATE_AD,
    RouteNames.POST_AD_SUCCESS,
    RouteNames.AD_UNDERTAKING_TERMS,
    RouteNames.UPDATE_AD,
    RouteNames.CHAT_WIDGET,
    RouteNames.CHAT,
    RouteNames.AD_MANAGEMENT,
    RouteNames.APPLY_AD_LIMIT_PACKAGE,
    RouteNames.PAYMENT_SELECTION,
    RouteNames.PAYMENT_SELECTION_FREE_AD_UNDERTAKING,
    RouteNames.PAYMENT_AREEBA,
    RouteNames.PAYMENT_JAZZ_CASH_MWALLET,
    RouteNames.PAYMENT_PAYMOB_MWALLET,
    RouteNames.PAYMENT_SUCCESS,
    RouteNames.PAYMENT_PROCESSING,
    RouteNames.PAYMENT_SUCCESS_JAZZ_CASH_VOUCHER,
    RouteNames.PAYMENT_FAWRY,
    RouteNames.PAYMENT_BANK_TRANSFER,
    RouteNames.USER_SETTINGS,
    RouteNames.EDIT_PROFILE,
    RouteNames.SELECT_SINGLE_PACKAGE,
    RouteNames.BUSINESS_PACKAGES,
    RouteNames.SELECT_MULTIPLE_PACKAGES,
    RouteNames.VIEW_BUSINESS_PACKAGE_CART_ROUTE,
    RouteNames.USER_ORDERS,
    RouteNames.SAVE_CARD,
    RouteNames.SELECT_PACKAGE,
    RouteNames.SELECT_PACKAGE_SUCCESS,
    RouteNames.PAYMENT_TPAY,
    RouteNames.PAYMENT_TPAY_OTP,
    RouteNames.LINK_IDENTITY_PROVIDER,
    RouteNames.REQUEST_DELIVERY,
    RouteNames.AGENCY_PORTAL_ACTION_CENTER,
    RouteNames.AGENCY_PORTAL_ADS,
    RouteNames.AGENCY_PORTAL_AGENTS,
    RouteNames.AGENCY_PORTAL_AGENT_DETAIL,
    RouteNames.AGENCY_PORTAL_COMPANY_PROFILE,
    RouteNames.AGENCY_PORTAL_CREATE_AD,
    RouteNames.AGENCY_PORTAL_UPDATE_AD,
    RouteNames.AGENCY_PORTAL_REJECTED_AD,
    RouteNames.AGENCY_PORTAL_POST_AD_SUCCESS,
    RouteNames.AGENCY_PORTAL_APPLY_AD_LIMIT,
    RouteNames.AGENCY_PORTAL_HELP,
    RouteNames.AGENCY_PORTAL_HOME,
    RouteNames.AGENCY_PORTAL_LEADS,
    RouteNames.AGENCY_PORTAL_PACKAGES,
    RouteNames.AGENCY_PORTAL_PACKAGE_DETAIL,
    RouteNames.AGENCY_PORTAL_SELECT_PACKAGE_SUCCESS,
    RouteNames.AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST,
    RouteNames.AGENCY_PORTAL_CREDIT_INFO,
    RouteNames.AGENCY_PORTAL_AD_DETAIL,
    RouteNames.PROVIDER_CASH_PAYMENT,
    RouteNames.SAVED_SEARCHES,
    RouteNames.TELE_SALES,
    RouteNames.PACKAGE_PURCHASE_ERROR,
    RouteNames.WALLET,
    RouteNames.FAVORITES,
];

export default RouteNames;
