import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import IconEmail from '@app/assets/icons/iconEmail.svg';

import { useI18n } from 'strat/i18n/language';
import { ContactForm, ContactFormModal, ContactFormDialog } from 'strat/contact';
import type { AgencyData } from 'strat/agency/types';
import { ButtonVariant } from 'strat/components';
import Designs from 'strat/branding/designs';

import ListingButton from './listingButton';
import styles from './styles/listingEmailButton.cssm';

/**
 * Props for {@see ContactFormModal}.
 */
type ContactFormModalProps = React.ComponentProps<typeof ContactFormModal>;

/**
 * Props for {@see ListingEmailButton}.
 */
type Props = React.ComponentProps<typeof ContactForm> & {
    /**
     * Method to call when the email button is clicked.
     */
    onClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Optionally, a method to call to render a modal
     * contact form.
     */
    renderContactFormModal?: (props: ContactFormModalProps) => React.ReactElement;
    permitNumber?: string;
    projectNumber?: string;
    contactName?: string;
    agency?: AgencyData;
    phoneNumber?: string;
    propertyID?: number;
    onSendEmailClick?: (name: string, email: string, phone: string, body: string) => void;
    onCallClick?: () => void;
    onPhoneNumberClick?: () => void;
    disabled?: boolean;
    text?: string;
    formTitle?: string;
    displayText?: boolean;
    variant?: Values<typeof ButtonVariant>;
    // Toggles the old button design, which is still needed for Zameen
    sharp?: boolean;
    contactFormDesign?: Values<typeof Designs>;
};

/**
 * Renders a E-mail button to be emedded in a listing.
 *
 * Shows a contact form dialog when clicked.
 */
const ListingEmailButton = ({
    renderContactFormModal = (props: ContactFormModalProps) => <ContactFormDialog {...props} />,
    disabled = false,
    displayText = true,
    variant,
    sharp,
    ...props
}: Props) => {
    const i18n = useI18n();
    const [modalVisible, setModalVisible] = React.useState(false);

    /**
     * Renders the e-mail button and possibly the contact form.
     */
    return (
        <>
            <ListingButton
                disabled={disabled}
                key="button"
                className={classNames(styles.button, props.className, {
                    [styles.disabled]: disabled,
                    [styles.updatedButtons]: !sharp,
                    [styles.light]: !disabled && variant === ButtonVariant.LIGHT,
                })}
                aria-label="Send email"
                onClick={(event: React.SyntheticEvent<any>): void => {
                    setModalVisible(true);
                    if (props.onClick) {
                        props.onClick(event);
                    }
                }}
                icon={props.withIcon && <IconEmail />}
            >
                {displayText && (props.text ?? t(i18n)`Email`)}
            </ListingButton>
            {React.createElement(renderContactFormModal, {
                // @ts-expect-error - TS2769 - No overload matches this call.
                visible: modalVisible,
                // @ts-expect-error - TS7006 - Parameter 'visible' implicitly has an 'any' type.
                onVisibilityChanged: (visible) => {
                    setModalVisible(visible);
                },
                referenceNumber: props.referenceNumber,
                permitNumber: props.permitNumber,
                externalID: props.externalID,
                contactName: props.contactName,
                agency: props.agency,
                phoneNumber: props.phoneNumber,
                propertyID: props.propertyID,
                onSendEmailClick: props.onSendEmailClick,
                onKeepMeInformed: props.onKeepMeInformed,
                onCallClick: props.onCallClick,
                onPhoneNumberClick: props.onPhoneNumberClick,
                message: props.message,
                hasEmail: true,
                projectNumber: props.projectNumber,
                formTitle: props.formTitle,
                design: props.contactFormDesign,
            })}
        </>
    );
};

export default ListingEmailButton;
