//@ts-nocheck
export default Object.freeze({
    HOME: 'home',
    LOGIN: 'login',
    SEARCH: 'searchresults',
    DETAIL: 'offerdetail',
    AGENT: 'agent',
    AGENCY_DETAIL: 'agencydetailpage',
    AGENT_DETAIL: 'agentdetailpage',
    AGENCY_SEARCH: 'searchagencies',
    AGENT_SEARCH: 'searchagents',
    REMARKETING: 'alt_offerdetail',
    LEGACY_REMARKETING: 'alt_propertydetail',
    TRUVALUE_LANDING: 'truvalue_landing',
    MAP: 'map',
    FREE_AD: 'freead',
    FREE_AD_PAYMENT_SELECTION: 'free_ad_payment_selection',
    TRANSACTIONS: 'transactions',
    AGENCY_PROFILE: 'agency_profile',
    AGENCY_HOME: 'agency_home',
    SEARCH_RESULTS: 'searchresults',
    REMARKETING_PAGE_SEARCH_RESULTS: 'rempage_searchresults',
    DUBAI_TRANSACTIONS: 'dubai_transactions',
});
