import * as React from 'react';
import { Flex, LoadingAnimation } from 'strat/components';

import styles from './styles/loadingAdCardUpdatedDesign.cssm';

const LoadingAdCard = () => (
    <div className={styles.container}>
        <LoadingAnimation className={styles.photoContainer} />
        <Flex column className={styles.details}>
            <LoadingAnimation height={'3rem'} width={'80%'} />
            <LoadingAnimation height={'2em'} width={'50%'} />
            <LoadingAnimation height={'2.6em'} width={'65%'} />
            <LoadingAnimation height={'3.8em'} width={'30%'} />
            <LoadingAnimation height={'3.8em'} width={'100%'} className={styles.ctaContainer} />
            <LoadingAnimation />
        </Flex>
    </div>
);

export default LoadingAdCard;
