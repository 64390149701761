import * as React from 'react';
import { useActiveUser } from 'strat/user/session';

import { useFetchAdContactInfo, useAdAgency } from 'horizontal/ad';
import { useAd } from 'horizontal/ad/state';
import IconCall from 'horizontal/assets/icons/iconCall.svg';
import { ChatPhoneViewType } from 'horizontal/chat/constants';
import { PhoneViewChatNotifier } from 'horizontal/chat/widgets/large';
import { useEnsureHasActiveUser } from 'horizontal/user';
import { ContactPersonRole } from 'horizontal/types';

import useIsPhoneNumberPublicForCategory from '../useIsPhoneNumberPublicForCategory';

import PhoneNumberLink from './phoneNumberLink';
import DescriptionLink from './descriptionLink';

type Props = {
    readonly adExternalID?: string | null | undefined;
    readonly viewSection: string;
    readonly placeholder?: string | null | undefined;
    readonly onShowButtonClick?: () => boolean | null | undefined;
    readonly showButtonText: string;
    readonly displayIcon?: boolean | null | undefined;
    readonly phoneNumber?: string | null;
};

const PhoneNumberGuardedLink = ({
    adExternalID,
    viewSection,
    placeholder,
    onShowButtonClick,
    showButtonText,
    phoneNumber,
    displayIcon = false,
}: Props) => {
    const [isShowingNumber, setShowingNumber] = React.useState(false);
    const [clickedCount, setClickedCount] = React.useState(0);
    const isPhoneNumberPublic: boolean = useIsPhoneNumberPublicForCategory();
    const ad = useAd();
    const activeUser = useActiveUser();
    const seller = useAdAgency(ad);

    const {
        fetchContactInfo: fetchPhoneNumber,
        contactInfo,
        loading: isLoading,
        isError,
    } = useFetchAdContactInfo(adExternalID);

    const isShortNumber = contactInfo?.roles?.includes(ContactPersonRole.SHOW_SHORT_NUMBER);

    const contactPhoneNumber =
        phoneNumber ||
        (isShortNumber ? seller?.shortNumber : contactInfo?.proxyMobile || contactInfo?.mobile);

    let handleShowButtonClick = () => {
        if (!onShowButtonClick || onShowButtonClick()) {
            setShowingNumber(true);
            fetchPhoneNumber();
            setClickedCount((lastValue) => lastValue + 1);
        }
    };
    const guardedClickHandler = useEnsureHasActiveUser({ onDone: handleShowButtonClick });
    handleShowButtonClick = isPhoneNumberPublic ? handleShowButtonClick : guardedClickHandler;

    React.useEffect(() => {
        if (isShowingNumber && !isPhoneNumberPublic && !activeUser) {
            setShowingNumber(false);
        }
    }, [isShowingNumber, isPhoneNumberPublic, activeUser]);

    return (
        <>
            {ad && ad.userExternalID && (
                <PhoneViewChatNotifier
                    adXID={ad.externalID}
                    linkType={ChatPhoneViewType.CALL}
                    counter={contactPhoneNumber ? clickedCount : 0}
                />
            )}

            {displayIcon && <IconCall />}
            <PhoneNumberLink
                isLoading={isLoading}
                isError={isError}
                phoneNumber={isShowingNumber ? contactPhoneNumber : null}
                placeholder={placeholder}
                viewSection={viewSection}
            />
            {!isShowingNumber && !isLoading && (
                <DescriptionLink text={showButtonText} onClick={handleShowButtonClick} />
            )}
        </>
    );
};

export default PhoneNumberGuardedLink;
