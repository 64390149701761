import * as React from 'react';

import { Flex } from 'strat/components';

import styles from './styles/projectTopDetailsItem.cssm';

type Props = {
    renderLabel: () => JSX.Element | string;
    renderInfo: () => JSX.Element | string;
    linkOnClick?: () => void;
    linkText?: string;
};

const ProjectTopDetailsItem = ({ renderLabel, renderInfo, linkOnClick, linkText }: Props) => (
    <Flex column className={styles.detailsItem}>
        <Flex className={styles.label}>{renderLabel()}</Flex>
        <Flex className={styles.info}>{renderInfo()}</Flex>
        {linkText != null && linkOnClick != null && (
            <div className={styles.link} onClick={linkOnClick}>
                {linkText}
            </div>
        )}
    </Flex>
);

export default ProjectTopDetailsItem;
