import { t } from '@lingui/macro';
import { SearchRequestSortType, SearchRequestOrder } from '@sector-labs/fe-search-redux/backend';
import type { I18n } from '@lingui/core';

export type sortByChoice = {
    name: string;
    value: string;
};

/* All possible agent sort types */
const Values = {
    NAME: CONFIG.build.STRAT_ENABLE_LEADERBOARD ? 'all_time' : 'agents_name',
    RANDOM: 'random',
} as const;

/* These settings are relevant for ElasticSearch backend */
const SortOptions = [
    // the attribute names are the field names of the BI activity index
    {
        value: Values.NAME,
        type: SearchRequestSortType.ATTRIBUTES,
        attributes: [{ name: 'trubroker_first_ranking_overall', order: SearchRequestOrder.ASC }],
    },
    {
        value: Values.RANDOM,
        type: SearchRequestSortType.RANDOM,
        key: 'leaderboard-random-sort',
        name: 'leaderboard random sort',
    },
];

const getSortOptionsByValue = (value: Values<typeof Values>) =>
    SortOptions.find((option) => option.value === value);

/* The choices that are visible for the user */
const choices = (i18n: I18n) => [{ name: t(i18n)`Name`, value: Values.NAME }];
const defaultValue = Values.NAME;
const defaultChoice = (i18n: I18n) => choices(i18n).find((choice) => choice.value === defaultValue);

const AgentSortByValues = {
    ...Values,
    defaultSortOption: SortOptions[0],
    defaultChoice,
    getSortOptionsByValue,
    choices,
    defaultValue,
} as const;
export default AgentSortByValues;
