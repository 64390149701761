import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { useActiveUserExternalID } from 'strat/user/session';

import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';
import type { ActionButton, ChatMessage } from 'horizontal/chat/types';
import { useFormattedPrice } from 'horizontal/util';

import ExtraMessageInfo from './extraMessageInfo';
import MessageText from './messageText';
import QuickActionButtons from './quickActionButtons';
import styles from './styles/message.cssm';

type Props = {
    readonly message: ChatMessage;
    readonly isSeen: boolean;
    readonly isLoggedInUserSeller: boolean;
    readonly className: string;
    readonly renderFooter: (arg?: { className?: string }) => React.ReactElement;
    readonly actionButtons: Array<ActionButton>;
};

const Message = ({
    className,
    renderFooter,
    message,
    isLoggedInUserSeller,
    isSeen,
    actionButtons,
}: Props) => {
    const userXID = useActiveUserExternalID();
    const isMessageFromUser = userXID === message.senderXID;
    const content = message.content[ChatMessageType.NEGOTIATION];

    const formattedPrice = useFormattedPrice(content?.value ?? 0);
    if (!content) {
        return null;
    }

    const isOffer = [
        ChatNegotiationState.BUYER_MADE_OFFER,
        ChatNegotiationState.SELLER_MADE_OFFER,
    ].includes(content.state);

    return (
        <>
            <div
                className={classNames(styles.container, className)}
                aria-label="Negotiation message"
            >
                <Flex column>
                    <ExtraMessageInfo
                        formattedPrice={formattedPrice}
                        isMessageFromUser={isMessageFromUser}
                        isLoggedInUserSeller={isLoggedInUserSeller}
                        message={message}
                    />
                    <MessageText message={message} formattedPrice={formattedPrice} />
                </Flex>
                <div
                    className={classNames(styles.footer, {
                        [styles.ligherCheckmark]: !isSeen && isOffer && isMessageFromUser,
                    })}
                >
                    {renderFooter()}
                </div>
            </div>
            {!!actionButtons.length && (
                <QuickActionButtons
                    actionButtons={actionButtons}
                    isMessageFromUser={isMessageFromUser}
                />
            )}
        </>
    );
};

export default React.memo<Props>(Message);
