import qs from 'qs';
import { isNil } from 'lodash';

import { TransactionsSearchQueryParams } from './constructTransactionSearchQueryParams';

const singularForms: { [key: string]: string } = {
    transactionIDs: 'transactionID',
    locationLevels: 'locationLevel',
    locationExternalIDs: 'locationExternalID',
    beds: 'bed',
};

const buildTransactionSearchQueryString = (params: TransactionsSearchQueryParams) => {
    const filteredParams: Record<string, any> = {};
    Object.keys(params)
        .filter((key) => !isNil(params[key as keyof TransactionsSearchQueryParams]))
        .forEach((key) => {
            const paramValues = params[key as keyof TransactionsSearchQueryParams];
            const singularName = singularForms[key];

            if (singularName) {
                filteredParams[singularName] = paramValues;
            } else {
                filteredParams[key] = paramValues;
            }
        });

    return qs.stringify(filteredParams, { arrayFormat: 'repeat' });
};

export default buildTransactionSearchQueryString;
