import { decodeFilter, parseFilterComponents } from 'horizontal/routes/decodeURLFilters';
import { FilterTypeToFeSearchReduxFilter } from 'horizontal/search/routing/searchParamsToFilters';
import { encodeFilter as encodeSearchFilter } from 'horizontal/routes/encodeURLFilters';

import { AgencyExtraFields } from './types';
import { extraFieldsValues } from './constants';

const paramsToFilters = (fields: AgencyExtraFields) =>
    Object.entries(fields)
        .map(([key, value]: [string, any]) => {
            const FilterType = FilterTypeToFeSearchReduxFilter[extraFieldsValues[key].type];
            if (!FilterType) {
                return null;
            }

            return new FilterType({
                attribute: `extraFields.${key}`,
                value,
            });
        })
        .filter((filter) => !!filter);

const decodeExtraFields = (fields: string | undefined): AgencyExtraFields => {
    if (!fields) {
        return {};
    }

    const filterStrings = fields.split(',');
    return filterStrings.reduce<Record<string, any>>((acc, filterString) => {
        const { attribute, operator, value } = parseFilterComponents(filterString);
        if (!attribute || !extraFieldsValues[attribute]) {
            return acc;
        }

        const filterType = extraFieldsValues[attribute].type;
        if (!filterType) {
            return acc;
        }

        const decodedFilter = decodeFilter(filterType, attribute, operator, value);
        if (!decodedFilter) {
            return acc;
        }

        acc[attribute] = decodedFilter;
        return acc;
    }, {});
};

const encodeExtraFields = (fields: AgencyExtraFields | undefined): string => {
    if (!fields) {
        return '';
    }
    const searchExtraFields = Object.entries(fields)
        .map(([attribute, value]: [string, any]) =>
            extraFieldsValues[attribute]
                ? encodeSearchFilter(
                      {
                          attribute,
                          filterType: extraFieldsValues[attribute].type,
                          choices: extraFieldsValues[attribute].choices,
                      },
                      value,
                  )
                : undefined,
        )
        .filter((filter) => !!filter);
    return searchExtraFields.join(',');
};

export { decodeExtraFields, encodeExtraFields, paramsToFilters };
