import * as React from 'react';
import { selectIsMobile } from 'strat/platform/selectors';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';

import type { PartialCategory } from 'horizontal/types';
import CategoryImage from 'horizontal/categories/categoryImage';

import styles from './styles/category.cssm';

type Props = {
    readonly category: PartialCategory;
    readonly onClick?: () => void;
};

const truncateText = (text: string, charLimit: number): string => {
    if (text.length < charLimit) {
        return text;
    }
    return `${text.substr(0, charLimit)}...`;
};

const Category = ({ category, onClick }: Props) => {
    const isMobile = useSelector(selectIsMobile);

    const categoryName = category.nameShort || category.name;
    const title = isMobile ? truncateText(categoryName, 20) : categoryName;

    return (
        <Flex column alignCenter className={styles.container} onClick={onClick}>
            <CategoryImage
                category={category}
                imageClassName={styles.image}
                containerClassName={styles.imageContainer}
            />
            <span className={styles.title}>{title}</span>
        </Flex>
    );
};

export default Category;
