import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { Dropdown, HidingMethod } from 'strat/modal';

import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';
import type { Category } from 'horizontal/types';
import { useTrackCategoryDropdownClick } from 'horizontal/gtm';

import CategoryGroup from './categoryGroup';
import styles from './styles/allCategoriesDropdown.cssm';

const AllCategoriesDropdown = ({ categories }: { categories: Array<Category> }) => {
    const [open, setOpen] = React.useState(false);
    const trackCategoryDropdownClick = useTrackCategoryDropdownClick();

    const renderTrigger = React.useCallback((): React.ReactElement => {
        const onClick = () => {
            if (!open) {
                trackCategoryDropdownClick();
            }
            setOpen(!open);
        };
        return (
            <div className={styles.trigger} onClick={onClick}>
                <Trans>All categories</Trans>
                <img
                    src={iconArrowDown}
                    className={classNames(styles.icon, { [styles.open]: open })}
                    alt={'Dropdown arrow'}
                />
            </div>
        );
    }, [open, setOpen, trackCategoryDropdownClick]);

    return (
        <div className={styles.container}>
            <Dropdown
                hidingMethod={HidingMethod.DISPLAY_NONE}
                renderTrigger={renderTrigger}
                open={open}
                onDismissed={() => setOpen(false)}
            >
                <div className={styles.categories} aria-label="Categories">
                    {categories.map((category) => (
                        <CategoryGroup
                            key={category.id}
                            category={category}
                            onClick={() => setOpen(false)}
                        />
                    ))}
                </div>
            </Dropdown>
        </div>
    );
};
export default AllCategoriesDropdown;
