//@ts-nocheck
import React, { Component } from 'react';
import autoBind from 'react-autobind';

/**
 * Connects pagination navigation functions into the wrapped
 * component, allowing to compute navigation logic.
 */
export default (component: React.Component<any>) => {
    class paginationNavigationConnector extends Component {
        constructor(props: any) {
            super(props);
            autoBind(this);

            this.state = {
                pageCount: Math.min(
                    this.props.pageCount,
                    this.props.maxAccessible || this.props.pageCount,
                ),
            };
        }

        /**
         * Update the number of pages accessible based
         * on the configured maximum.
         */
        UNSAFE_componentWillReceiveProps(nextProps: any) {
            const pageCountChanged = this.props.pageCount !== nextProps.pageCount;
            const maxAccessibleChanged = this.props.maxAccessible !== nextProps.maxAccessible;

            if (pageCountChanged || maxAccessibleChanged) {
                this.setState({
                    pageCount: Math.min(
                        nextProps.pageCount,
                        nextProps.maxAccessible || nextProps.pageCount,
                    ),
                });
            }
        }

        /**
         * Gets whether there's a previous page.
         * @returns {bool}
         */
        get canGoBack() {
            return this.props.activePage !== 1;
        }

        /**
         * Gets whether there's a next page.
         * @returns {bool}
         */
        get canGoForward() {
            return this.props.activePage < this.state.pageCount;
        }

        /**
         * Gets the index of the previous page.
         * @returns {number}
         */
        get previousPage() {
            return this.props.activePage - 1;
        }

        /**
         * Gets the index of the next page.
         * @returns {number}
         */
        get nextPage() {
            return this.props.activePage + 1;
        }

        /**
         * Computes the page numbers to show.
         *
         * This is computed according to the `maxVisible` setting. It computes
         * a maximum amount of pages to show "around" the currently active page.
         * @returns {array}
         */
        computeVisiblePages() {
            let left = this.props.activePage;
            let right = this.props.activePage;

            const canGoLeft = () => left - 1 > 0;
            const canGoRight = () => right + 1 <= this.state.pageCount;

            // in case we have an uneven number, we prefer adding
            // more pages to the right than to the left, so with 7,
            // we end up with:
            //
            // 9 10 11 [current: 12] 13 14 15 16
            const maxVisibleLeft = Math.floor(this.props.maxVisible / 2);
            const maxVisibleRight = Math.ceil(this.props.maxVisible / 2);

            for (let i = 0; i < maxVisibleLeft; ++i) {
                if (canGoLeft()) {
                    left -= 1;
                } else if (canGoRight()) {
                    right += 1;
                }
            }

            for (let i = 0; i < maxVisibleRight; ++i) {
                if (canGoRight()) {
                    right += 1;
                } else if (canGoLeft()) {
                    left -= 1;
                }
            }

            const pages: any = [];
            for (let i = left; i <= right; i += 1) {
                pages.push(i);
            }

            return pages;
        }

        /**
         * Renders the wrapped component.
         */
        render(): any {
            const props = {
                ...this.props,
                pageCount: this.state.pageCount,
                canGoBack: this.canGoBack,
                canGoForward: this.canGoForward,
                previousPage: this.previousPage,
                nextPage: this.nextPage,
                computeVisiblePages: this.computeVisiblePages,
            } as const;

            return React.createElement(component, props);
        }
    }

    return paginationNavigationConnector;
};
