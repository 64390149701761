import * as React from 'react';

import ShareWhatsAppIcon from 'strat/assets/icons/shareIconWhatsapp.svg';
import ShareFacebookIcon from 'strat/assets/icons/shareIconFacebook.svg';
import ShareTwitterIcon from 'strat/assets/icons/shareIconTwitter.svg';
import ShareFBMessengerIcon from '@app/assets/icons/iconShareFBMessenger.svg';
import ShareGMailIcon from 'strat/assets/icons/shareIconGmail.svg';
import ShareEmailIcon from 'strat/assets/icons/shareIconMail.svg';
import {
    WhatsAppShareLink,
    FacebookShareLink,
    TwitterShareLink,
    FBMessengerShareLink,
    GMailShareLink,
    EmailShareLink,
} from 'strat/social/links';
import { PageTypes, ShareMediums } from 'strat/gtm';
import EMPTY_FUNCTION from 'strat/empty/function';
import type { PropertyData } from 'strat/property/types';

import styles from './styles/socialShareLinks.cssm';

const createSocialShareLinks = (
    url: string,
    headerText: string,
    triggerName: string,
    property: PropertyData | null,
    pageType: Values<typeof PageTypes>,
    shareMethods: typeof ShareMediums = ShareMediums,
    onClick: (shareMethod?: Values<typeof ShareMediums>) => void = EMPTY_FUNCTION,
    content?: string | null,
    onWhatsAppClick: () => void = EMPTY_FUNCTION,
) => [
    <FacebookShareLink
        url={url}
        text={headerText}
        onClick={() => {
            onClick(shareMethods.FACEBOOK);
        }}
        pageType={pageType}
        triggerName={triggerName}
        property={property}
        key="facebook-share-link"
    >
        <ShareFacebookIcon className={styles.facebook} />
    </FacebookShareLink>,
    <TwitterShareLink
        url={url}
        text={content ?? headerText}
        onClick={() => {
            onClick(shareMethods.TWITTER);
        }}
        pageType={pageType}
        triggerName={triggerName}
        property={property}
        key="twitter-share-link"
    >
        <ShareTwitterIcon className={styles.twitter} />
    </TwitterShareLink>,
    <FBMessengerShareLink
        url={url}
        text={headerText}
        onClick={() => {
            onClick(shareMethods.MESSENGER);
        }}
        pageType={pageType}
        triggerName={triggerName}
        property={property}
        key="messenger-share-link"
    >
        <ShareFBMessengerIcon className={styles.icon} />
    </FBMessengerShareLink>,
    <WhatsAppShareLink
        url={url}
        text={content ?? headerText}
        onClick={() => {
            onClick(shareMethods.WHATSAPP);
            onWhatsAppClick();
        }}
        pageType={pageType}
        triggerName={triggerName}
        property={property}
        key="whatsapp-share-link"
    >
        <ShareWhatsAppIcon className={styles.icon} />
    </WhatsAppShareLink>,
    <GMailShareLink
        url={url}
        text={headerText}
        onClick={() => {
            onClick(shareMethods.GMAIL);
        }}
        pageType={pageType}
        triggerName={triggerName}
        property={property}
        key="gmail-share-link"
        emailBody={content}
    >
        <ShareGMailIcon className={styles.gmail} />
    </GMailShareLink>,
    <EmailShareLink
        url={url}
        text={headerText}
        onClick={() => {
            onClick(shareMethods.EMAIL);
        }}
        pageType={pageType}
        triggerName={triggerName}
        property={property}
        key="email-share-link"
        emailBody={content}
    >
        <ShareEmailIcon className={styles.icon} />
    </EmailShareLink>,
];

export default createSocialShareLinks;
