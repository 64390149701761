import React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import { PropertyPhotoData } from 'strat/property';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';

import styles from './styles/projectCardPhotos.cssm';

type PhotoPreviewProps = {
    photo: PropertyPhotoData;
    className: string;
    containerClassName: string;
};

const PhotoPreview = ({ photo, className, containerClassName }: PhotoPreviewProps) => (
    <ThumbnailImage
        pictureClassName={containerClassName}
        className={className}
        imageID={photo.id}
        title={photo.title}
        alt={photo.title}
        size={ThumbnailSizes.NORMAL}
    />
);

type Props = {
    photos: PropertyPhotoData[];
};

const ProjectCardPhotos = ({ photos }: Props) => (
    <div className={styles.container}>
        <Flex className={styles.images}>
            {photos.length > 0 && (
                <PhotoPreview
                    photo={photos[0]}
                    className={classNames(styles.image, styles.topLeftImage, {
                        [styles.topRightImage]: photos.length === 1,
                    })}
                    containerClassName={classNames(styles.imageContainer, {
                        [styles.largeImageContainer]: photos.length > 2,
                    })}
                />
            )}
            <Flex stretchHeight stretchWidth column className={styles.rightContainer}>
                {photos.length > 1 && (
                    <PhotoPreview
                        photo={photos[1]}
                        className={classNames(styles.image, styles.topRightImage)}
                        containerClassName={classNames(styles.imageContainer, {
                            [styles.splitHeightContainer]: photos.length > 2,
                        })}
                    />
                )}
                {photos.length > 2 && (
                    <PhotoPreview
                        photo={photos[2]}
                        className={styles.image}
                        containerClassName={classNames(styles.imageContainer, {
                            [styles.splitHeightContainer]: photos.length > 2,
                        })}
                    />
                )}
            </Flex>
        </Flex>
    </div>
);

export default ProjectCardPhotos;
