import React from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from 'strat/loadable';
import type { PropertyPhotoData } from 'strat/property/types';
import {
    ClickBehavior,
    ImageGalleryDialog,
    ThumbnailImageWithFallback,
    ThumbnailSizes,
    ThumbnailSizeValues,
    thumbnailURL,
} from 'strat/image';
import usePropertyThumbnailSize from 'strat/property/usePropertyThumbnailSize';

import styles from './styles/photoGalleryDialog.cssm';
import CloseGalleryButton from './closeGalleryButton';

type Props = {
    slideIndex?: number;
    startIndex?: number;
    photos: Array<PropertyPhotoData>;
    visible: boolean;
    onCloseButtonClick: () => void;
    onSlide: (index: number) => void;
    renderCaption?: (photo: PropertyPhotoData) => React.ReactNode;
    closeButtonLabel?: React.ReactNode;
    withOverlay?: boolean;
    withCounter?: boolean;
};

const PhotoGalleryDialog = ({
    photos,
    slideIndex,
    startIndex,
    visible,
    onCloseButtonClick,
    onSlide,
    renderCaption,
    closeButtonLabel,
    withOverlay = false,
    withCounter = false,
}: Props) => {
    const thumbnailSize = usePropertyThumbnailSize();

    const renderPhoto = (photo: PropertyPhotoData) => {
        const fallbackURL = thumbnailURL(
            photo.id,
            'webp',
            ThumbnailSizeValues[ThumbnailSizes.LARGE],
        );
        return (
            <ThumbnailImageWithFallback
                imageID={photo.id}
                className={styles.image}
                pictureClassName={styles.picture}
                loadingIndicator={<LoadingSpinner />}
                alt={photo.title}
                size={thumbnailSize}
                fallbackUrl={fallbackURL}
                renderCaption={renderCaption ? () => renderCaption(photo) : undefined}
                showCaption={!!renderCaption}
                dynamicCaptionPosition
            />
        );
    };

    const renderCloseButton = () => (
        <div className={styles.closeButtonContainer}>
            <CloseGalleryButton onClick={onCloseButtonClick} label={closeButtonLabel} />
        </div>
    );

    const overlayClassName = classNames(styles.overlay, {
        [styles.hiddenOverlay]: !withOverlay,
    });

    return (
        <ImageGalleryDialog
            // @ts-expect-error - TS2322 - Type '{ persistent: true; slideIndex: number | undefined; startIndex: number | undefined; visible: boolean; photos: PropertyPhotoData[]; onSlide: (index: number) => void; ... 8 more ...; arrowContainerStyle: any; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<withErrorBoundary<Props>.(Anonymous class)> & Readonly<...> & Readonly<...>'.
            persistent
            dismissible
            slideIndex={slideIndex}
            startIndex={startIndex}
            visible={visible}
            photos={photos}
            onSlide={onSlide}
            clickBehavior={ClickBehavior.NEXT}
            renderItem={renderPhoto}
            renderCloseButton={renderCloseButton}
            onVisibilityChanged={onCloseButtonClick}
            containerClassName={styles.photoGalleryDialog}
            dismissibleClassName={styles.dismissible}
            overlayClassName={visible ? overlayClassName : styles.closedDialog}
            rightArrowStyle={styles.arrow}
            leftArrowStyle={styles.arrow}
            arrowContainerStyle={styles.arrowContainer}
            withCounter={withCounter}
        />
    );
};

export default PhotoGalleryDialog;
