/**
 * Available dialog stack actions.
 */
const Actions = Object.freeze({
    SET_DIALOG_STATE: 'DIALOG_STACK/SET_DIALOG_STACK',
});

/**
 * The type of the dispatched action.
 */
type Action = {
    type: string;
    stacked?: boolean;
    stackGroup?: string;
};

/**
 * The state type.
 */
type State = {
    readonly stacked: boolean;
    readonly stackGroup: string;
};

/**
 * Default state for the dialog stacking group.
 */
const defaultState: State = {
    stacked: false,
    stackGroup: '',
};

/**
 * Reducer for the dialog stack.
 */
const DialogStackReducer = (state: State | null | undefined = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.SET_DIALOG_STATE:
            return {
                ...state,
                stacked: action.stacked,
                stackGroup: action.stackGroup,
            };
        default:
            return state;
    }
};

/**
 * Setter for the dialog stack state.
 */
const setDialogStackState = (stacked: boolean, stackGroup: string): Action => ({
    type: Actions.SET_DIALOG_STATE,
    stacked,
    stackGroup,
});

export { setDialogStackState };

export default DialogStackReducer;
