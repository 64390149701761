import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';

import {
    selectFloorPlan2DImages,
    selectFloorPlan3DImages,
    selectFloorPlanModels,
} from 'strat/floorPlans/selectors';
import { GraphicMode } from 'strat/floorPlans/types';
import type { FloorPlan } from 'strat/floorPlans/types';

const useAvailableTabs = (floorPlan: FloorPlan, i18n: I18n) => {
    const models = selectFloorPlanModels(floorPlan);
    // @ts-expect-error - TS2345 - Argument of type 'FloorPlan' is not assignable to parameter of type 'never'.
    const images2D = selectFloorPlan2DImages(floorPlan);
    // @ts-expect-error - TS2345 - Argument of type 'FloorPlan' is not assignable to parameter of type 'never'.
    const images3D = selectFloorPlan3DImages(floorPlan);

    return React.useMemo(() => {
        const tabs: Array<
            | any
            | {
                  items: any;
                  name: any;
                  type: string;
              }
        > = [];

        if (CONFIG.build.ENABLE_3D_FLOOR_PLANS && models && models.length > 0) {
            tabs.push({
                name: t(i18n)`3D Live`,
                items: models,
                type: GraphicMode.INTERACTIVE_3D,
            });
        }

        if (images3D && images3D.length > 0) {
            tabs.push({
                name: t(i18n)`3D Image`,
                items: images3D,
                type: GraphicMode.IMAGE_3D,
            });
        }

        if (images2D && images2D.length > 0) {
            tabs.push({
                name: t(i18n)`2D Image`,
                items: images2D,
                type: GraphicMode.IMAGE_2D,
            });
        }

        return tabs;
    }, [models, images2D, images3D, i18n]);
};

const useFloorPlansTabs = (floorPlan: FloorPlan, i18n: I18n) => {
    const availableTabs = useAvailableTabs(floorPlan, i18n);

    // I don't know for sure why flow complained about this hook,
    // so I had to manually type in order to make it work.
    const [activeTabIndex, setActiveTabIndex]: [
        number,
        (arg1: ((arg1: number) => number) | number) => void,
    ] = React.useState(0);

    const activeItems = React.useMemo(
        () => (availableTabs[activeTabIndex] || {}).items,
        [activeTabIndex, availableTabs],
    );

    return { availableTabs, activeTabIndex, setActiveTabIndex, activeItems };
};

export default useFloorPlansTabs;
