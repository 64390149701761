import * as React from 'react';
import { useSearchHits } from '@sector-labs/fe-search-redux/state';

import type { LiteAd } from 'horizontal/types';

import type { HitsSections } from './types';

const getSectionHits = (hits: Array<LiteAd>) => {
    return {
        exactHitList: hits.filter((hit) => hit.requestIndex === 0),
        fallbackHitList: hits.filter((hit) => hit.requestIndex === 1),
        additionalFallbackHitList: hits.filter((hit) => hit.requestIndex === 2),
    };
};

const useSectionHits = (): HitsSections => {
    const hits = useSearchHits();
    // @ts-expect-error - TS2345 - Argument of type 'SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
    return React.useMemo(() => getSectionHits(hits), [hits]);
};

export { getSectionHits };

export default useSectionHits;
