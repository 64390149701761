import * as React from 'react';

import { useChatSDK } from 'horizontal/chat/hooks';

import styles from './styles/unreadChatsBadge.cssm';

const UnreadChatsBadge = () => {
    const sdk = useChatSDK();
    const hasUnreadMessages = sdk.useHasInboxUnreadMessages();

    if (!hasUnreadMessages) {
        return null;
    }

    return <div className={styles.unreadBadge} />;
};

export default UnreadChatsBadge;
