import type { GeoLocationData } from 'strat/user/types';
import type { Geoloc } from 'strat/map/types';

import type { Location } from 'horizontal/types';

const locationByPriority = (
    userLocation: GeoLocationData | null | undefined,
    location: Location,
): [Location | null | undefined, Geoloc | null | undefined] => {
    const userClosestLocation = userLocation?.closestLocation;
    if (userClosestLocation) {
        const coordinates = userLocation?.coordinates
            ? { lat: userLocation.coordinates[0], lng: userLocation.coordinates[1] }
            : userClosestLocation.geography;
        // @ts-expect-error - TS2322 - Type 'LocationNodeData' is not assignable to type 'Location'.
        return [userClosestLocation, coordinates];
    }

    if (location.hierarchy?.length > 1) {
        return [location, location.geography];
    }

    return [null, null];
};

export default locationByPriority;
