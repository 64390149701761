import type { RoutingContextWithMiddlewares } from 'strat/app';
import { selectActiveUser } from 'strat/user/session';

import Page from 'horizontal/pages/page';

const ensureHasActiveUser = (
    context: RoutingContextWithMiddlewares,
    routeParams?: any,
): boolean => {
    const user = selectActiveUser(context.redux.store.getState());
    if (!user) {
        context.rendering.renderPage(Page.LOGIN, {
            redirectRouteName: context.match.route.name,
            redirectParams: routeParams || context.match.params,
        });
        return false;
    }

    return true;
};

export default ensureHasActiveUser;
