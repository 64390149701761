import * as React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import type { AnyAdData } from 'strat/types';
import { PropertyType } from 'strat/property/types';

import { ProjectBadgeKey, ProjectBadgeStyle } from './types';
import PercentagePaidBadge from './percentagePaidBadge';
import AmountPaidBadge from './amountPaidBadge';
import HandoverBadge from './handoverBadge';
import OriginalPriceBadge from './originalPriceBadge';
import PaymentPlanBadge from './paymentPlanBadge';
import StartingFromBadge from './startingFromBadge';
import styles from './styles/projectBadgeList.cssm';

type ContentProps = {
    ad: AnyAdData;
    style: ProjectBadgeStyle;
    badgeKey: ProjectBadgeKey;
};

const BadgeContent = ({ ad, style, badgeKey }: ContentProps) => {
    if (ad.type === PropertyType.PROPERTY) {
        switch (badgeKey) {
            case ProjectBadgeKey.AMOUNT_PAID:
                return <AmountPaidBadge property={ad} style={style} />;
            case ProjectBadgeKey.PERCENTAGE_PAID:
                return <PercentagePaidBadge property={ad} style={style} />;
            case ProjectBadgeKey.ORIGINAL_PRICE:
                return <OriginalPriceBadge property={ad} style={style} />;
        }
    }

    if (ad.type === PropertyType.PROJECT) {
        switch (badgeKey) {
            case ProjectBadgeKey.STARTING_FROM:
                return <StartingFromBadge ad={ad} style={style} />;
        }
    }

    switch (badgeKey) {
        case ProjectBadgeKey.HANDOVER:
            return <HandoverBadge ad={ad} style={style} />;
        case ProjectBadgeKey.PAYMENT_PLAN:
            return <PaymentPlanBadge ad={ad} style={style} />;
    }

    return null;
};

type Props = {
    ad: AnyAdData;
    style: ProjectBadgeStyle;
    badges: ProjectBadgeKey[];
};

const ProjectBadgeList = ({ ad, style, badges }: Props) => (
    <Flex
        alignCenter
        className={classNames(styles.container, {
            [styles.withGap]: [ProjectBadgeStyle.SMALL_CARD, ProjectBadgeStyle.SMALL_CHIP].includes(
                style,
            ),
            [styles.withLargeGap]: ProjectBadgeStyle.LARGE_CARD === style,
        })}
    >
        {badges.map((key: ProjectBadgeKey) => (
            <BadgeContent key={key} ad={ad} badgeKey={key} style={style} />
        ))}
    </Flex>
);

export default ProjectBadgeList;
