import { Flow } from 'flow-to-typescript-codemod';
import { connect } from 'react-redux';

import type { Geoloc } from 'strat/map/types';

type ProvidedProps = {
    geography: Geoloc;
    hasExactGeography: boolean;
    location: string;
};

const withPropertyGeography = <T extends any>(
    component: Flow.AbstractComponent<any>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'ProvidedProps'.
): Flow.AbstractComponent<Flow.Diff<T, ProvidedProps>> =>
    // @ts-expect-error - TS2322 - Type 'ConnectedComponent<AbstractComponent<any, any>, { [x: string]: any; [x: number]: any; [x: symbol]: any; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } | { ...; }>' is not assignable to type 'AbstractComponent<Diff<T, ProvidedProps>, any>'.
    connect((state, ownProps) => {
        const {
            geography,
            location,
            hasExactGeography = true,
            // @ts-expect-error - TS2339 - Property 'property' does not exist on type '{}'. | TS2571 - Object is of type 'unknown'.
        } = ownProps.property || state.property.data;

        return {
            geography,
            hasExactGeography,
            location: location
                .slice(0, CONFIG.build.AD_LOCATION_BREADCRUMB_URL_MAX_LEVEL + 1)
                .slice(-1)[0].name,
        };
    })(component);

export default withPropertyGeography;
