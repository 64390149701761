import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex } from 'strat/components';
import { Dialog } from 'strat/components';
import { DialogComponentProps } from 'strat/dialogs/types';
import { Button } from 'strat/components';
import type { ChoiceValue, Choice } from 'strat/components/types';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';

import Context from 'horizontal/adCreation/context';
import { Category } from 'horizontal/types';
import { MultiSelect } from 'horizontal/components';
import CloseIcon from 'horizontal/assets/icons/iconClose.svg';

import CheckboxOptionsChoice from './checkboxOptionsChoice';
import styles from './styles/adCreationGroupedChoicesDialog.cssm';

type Props = {
    readonly title?: string;
    readonly choices: Array<Choice<ChoiceValue>>;
    readonly activeCategoryContext: [
        Category | null | undefined,
        React.Dispatch<Category | null | undefined>,
    ];
    readonly selectedValues: Set<ChoiceValue>;
    readonly groupedChoices: Array<CategoryFieldGroupedChoices>;
    readonly onChange: (arg1: ChoiceValue | ChoiceValue[]) => void;
    readonly autoFilledValues?: ChoiceValue[];
    readonly setAutoFilledValues?: (arg1: ChoiceValue[]) => void;
    readonly value: string;
    readonly errorMessage?: string;
};
const AdCreationGroupedChoicesDialog = ({
    title,
    visible,
    onVisibilityChanged,
    activeCategoryContext,
    groupedChoices,
    onChange,
    errorMessage,
    selectedValues,
    choices,
    autoFilledValues,
    setAutoFilledValues,
}: DialogComponentProps<Props>) => {
    const [selectedOptions, setSelectedOptions] = React.useState<Set<ChoiceValue>>(
        new Set() as Set<ChoiceValue>,
    );

    React.useEffect(() => {
        if (visible) {
            setSelectedOptions(selectedValues);
        }
    }, [visible, selectedValues]);

    if (!groupedChoices) {
        return null;
    }

    return (
        <Dialog
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.dialogContainer}
            dismissible
        >
            <CloseIcon className={styles.closeIcon} onClick={() => onVisibilityChanged(false)} />
            <span className={styles.title}>{title || <Trans>Extra Features</Trans>}</span>
            <Context.Provider value={activeCategoryContext ?? []}>
                <Flex className={styles.dialogContent}>
                    {groupedChoices.map((groupChoice: CategoryFieldGroupedChoices) => {
                        return (
                            <div key={groupChoice.id} className={styles.choiceGroupContainer}>
                                <span className={styles.choiceGroupTitle}>{groupChoice.label}</span>
                                <MultiSelect
                                    onChange={(newValues) => {
                                        setSelectedOptions(newValues);
                                        const newAutoSelected = (autoFilledValues || [])?.filter(
                                            (value) => newValues.has(value),
                                        );
                                        setAutoFilledValues?.(newAutoSelected);
                                    }}
                                    autoFilledValues={autoFilledValues}
                                    selectedValues={selectedOptions}
                                    choices={groupChoice.choices.all}
                                    errorMessage={errorMessage}
                                    leftSideLabel
                                    boldLabel
                                    choicesClassName={styles.choices}
                                    renderChoice={CheckboxOptionsChoice}
                                />
                            </div>
                        );
                    })}
                </Flex>
                <div className={styles.bottomContainer}>
                    <div className={styles.actionButtonsContainer}>
                        <span
                            className={styles.actionButton}
                            onClick={() => {
                                setSelectedOptions(
                                    new Set(
                                        choices.map((choice: Choice<ChoiceValue>) => choice.value),
                                    ),
                                );
                            }}
                        >
                            <Trans>Select all</Trans>
                        </span>
                        <div className={styles.separator} />
                        <span
                            className={styles.actionButton}
                            onClick={() => {
                                setSelectedOptions(new Set());
                                onChange([]);
                            }}
                        >
                            <Trans>Clear all</Trans>
                        </span>
                    </div>
                    <Button
                        small
                        onClick={() => {
                            onChange(Array.from(selectedOptions));
                            onVisibilityChanged(false);
                        }}
                    >
                        <Trans>Confirm</Trans>
                    </Button>
                </div>
            </Context.Provider>
        </Dialog>
    );
};

export default AdCreationGroupedChoicesDialog;
