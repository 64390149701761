import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Formik } from 'formik';
import { useRouter } from 'react-true-router';
import { useI18n } from 'strat/i18n/language';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { useIsAdDeliverable } from '@app/userOrders/hooks';
import { Flex, Text } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { useIsStratCreditUser } from 'strat/user';
import { useCombinedCategoryFields } from 'strat/categoryFields';

import { useSubmitAd } from '@app/adCreation/submitAd';
import { useAd } from 'horizontal/ad/state';
import { useFlatCategoryFields } from 'horizontal/categoryFields';
import {
    generateValidationSchema,
    generateInitialValues,
    generateInitialValuesWithDelivery,
} from 'horizontal/fields';
import { trackPostNowClick } from 'horizontal/gtm';
import type { CategoryField, Category } from 'horizontal/types';
import { AdState } from 'horizontal/types';
import { AdCreationSourceRoutes } from 'horizontal/constants';
import { useNavigateToApplyAdLimit } from 'horizontal/packages/navigation';
import { CategoryFormField } from '@app/fields/categoryFormField';
import { useNavigateToApplyAdProductCreditCosts } from 'horizontal/adProductCreditCost';

import Form from './form';
import { selectBundle } from './selectors';
import styles from './styles/adDetailsForm.cssm';
import AdCreationTips from './adCreationTips';
import useOnSubmitAdError from './useOnSubmitAdError';

const renderForm =
    (categoryFields: Array<CategoryField>) => (props: React.ComponentProps<typeof Form>) => (
        <Form {...props} categoryFields={categoryFields} />
    );

const AdDetailsForm = ({
    onCategoryChangeRequested,
    activeCategory,
}: {
    onCategoryChangeRequested?: () => void;
    activeCategory: Category | null | undefined;
}) => {
    const i18n = useI18n();
    const categoryFields = useCombinedCategoryFields(activeCategory?.id);
    const flatCategoryFields = useFlatCategoryFields(activeCategory?.id);

    const router = useRouter();
    const ad = useAd();
    const user = useActiveUser();
    const isStratCreditUser = useIsStratCreditUser();
    const submitAd = useSubmitAd();
    const onError = useOnSubmitAdError();

    const creationSource = useSelector(selectBundle);

    const isDeliverable = useIsAdDeliverable(activeCategory);

    const initialValues = React.useMemo(() => {
        if (isDeliverable) {
            return generateInitialValuesWithDelivery(flatCategoryFields);
        }
        return generateInitialValues(flatCategoryFields);
    }, [flatCategoryFields, isDeliverable]);

    const validationSchema = React.useMemo(
        () =>
            generateValidationSchema(
                i18n,
                flatCategoryFields,
                activeCategory?.roles,
                activeCategory,
            ),
        [i18n, flatCategoryFields, activeCategory],
    );

    const navigateToApplyAdLimit = useNavigateToApplyAdLimit();
    const navigateToApplyAdProductCreditCosts = useNavigateToApplyAdProductCreditCosts();

    const onSuccess = React.useCallback(
        ({
            data: { external_id: adExternalID, state, product, product_purchase_id: packageID },
        }) => {
            if (isStratCreditUser) {
                navigateToApplyAdProductCreditCosts({ adExternalID });
                return;
            }

            if (state === AdState.LIMITED) {
                trigger(Triggers.AD_CREATION_FAILED, {
                    view_section: ViewSections.BODY,
                    ad_id: adExternalID,
                });
                return navigateToApplyAdLimit({ externalID: adExternalID });
            }

            if (product === 'featured') {
                router.pushRoute(AdCreationSourceRoutes[creationSource].SELECT_PACKAGE_SUCCESS, {
                    packageID,
                    adExternalID,
                });
                return;
            }

            router.pushRoute(AdCreationSourceRoutes[creationSource].POST_AD_SUCCESS, {
                adExternalID,
            });
        },
        [
            router,
            creationSource,
            navigateToApplyAdLimit,
            isStratCreditUser,
            navigateToApplyAdProductCreditCosts,
        ],
    );

    const onSubmit = React.useCallback(
        (values, { setSubmitting, setFieldError }) => {
            trackPostNowClick(ad, user?.externalID);
            submitAd(flatCategoryFields, values)
                .then(onSuccess)
                .catch((error) => onError(error, setFieldError))
                .then(() => setSubmitting(false));
        },
        [flatCategoryFields, submitAd, onError, onSuccess, ad, user],
    );

    return (
        <Flex fillContainer>
            <Flex column className={styles.section}>
                <Flex justifySpaceBetween className={styles.fieldWrapper}>
                    <Text.Regular bold>
                        <Trans>Category</Trans>
                    </Text.Regular>
                    <Flex className={styles.fieldInput}>
                        <CategoryFormField onCategoryChangeRequested={onCategoryChangeRequested} />
                    </Flex>
                </Flex>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {renderForm(categoryFields)}
                </Formik>
            </Flex>
            <AdCreationTips />
        </Flex>
    );
};

export default AdDetailsForm;
