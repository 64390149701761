import type { EnhancedLocation } from 'react-true-router/location';
import type { AdDetailsRouteParams } from 'horizontal/routes';

const createURL = ({
    slug,
    externalID,
    productPurchaseID,
}: AdDetailsRouteParams): EnhancedLocation => {
    // remove non ascii and - character situated between them when computing url
    /* eslint-disable no-control-regex */
    const strippedSlug = (slug || '')
        .replace(/[^\x00-\x7F]([-]+)[^\x00-\x7F]/g, '')
        .replace(/[^\x00-\x7F]/g, '');
    /* eslint-enable no-control-regex */
    const pathname = `/item/${strippedSlug}-iid-${externalID}`;
    return { pathname, search: { productPurchaseID: productPurchaseID } };
};

export default createURL;
