import * as React from 'react';
import { useSearchPagination, useTotalSearchPageCount } from '@sector-labs/fe-search-redux/state';
import { ViewSections } from 'strat/gtm';
import { BackToTopButton } from 'strat/components';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { trackLoadMore } from 'horizontal/gtm';
import { RefinedSearchLink } from 'horizontal/search/routing';
import { PaginationType } from 'horizontal/types';
import { useFeaturedAdsCount } from 'horizontal/search';

import { RecentlyViewedHits } from './recentlyViewed';
import styles from './styles/searchResults.cssm';
import { useIsSearchLoading } from './hooks';
import Pagination from './pagination';
import { SectionHits } from './hits';
import { NoHits, LoadMoreHitsButton, HitListHeader } from './results';
import useShouldShowHitsAndNoHits from './useShouldShowHitsAndNoHits';

type Props = {
    readonly loading?: boolean;
};

const Hits = ({ loading }: Props) => {
    const isUsingPagination = settings.paginationType === PaginationType.PAGINATION;

    const [, nextPageNumber] = useSearchPagination();
    const [totalPageCount] = useTotalSearchPageCount();
    const { featuredCount, totalFeaturedCount } = useFeaturedAdsCount();

    const onClick = React.useCallback(() => {
        trackLoadMore(
            // @ts-expect-error - TS2345 - Argument of type 'number | null' is not assignable to parameter of type 'number'.
            nextPageNumber,
            totalPageCount,
            ViewSections.SEARCH_RESULTS,
            `${featuredCount}, ${totalFeaturedCount}`,
        );
    }, [nextPageNumber, totalPageCount, featuredCount, totalFeaturedCount]);

    return (
        <>
            <HitListHeader />
            <SectionHits loading={loading} />
            {isUsingPagination && <Pagination />}
            {!isUsingPagination && !loading && !!nextPageNumber && (
                <RefinedSearchLink
                    params={{ page: nextPageNumber }}
                    className={styles.loadMoreHits}
                    onClick={onClick}
                >
                    <LoadMoreHitsButton />
                </RefinedSearchLink>
            )}
        </>
    );
};

const SearchResults = () => {
    const loading = useIsSearchLoading();
    const { shouldShowNoHitsSection, shouldShowHitsSection } = useShouldShowHitsAndNoHits();

    return (
        <Flex column alignCenter stretchWidth>
            <BackToTopButton />
            {shouldShowNoHitsSection && <NoHits />}
            {shouldShowHitsSection && <Hits loading={loading} />}
            <RecentlyViewedHits />
        </Flex>
    );
};

export default SearchResults;
