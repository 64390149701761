import * as React from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'react-image-gallery'. 'node_modules/react-image-gallery/build/image-gallery.js' implicitly has an 'any' type.
import ImageGallery from 'react-image-gallery';

import 'strat/image/styles/imageSlideshow.css';

import { ChatMessageType } from 'horizontal/chat/constants';
import type { ChatMessage } from 'horizontal/chat/types';
import { useRenderNavButtons } from 'horizontal/gallery';

import Item from './item';
import type { OpenAtConfig } from './types';

type Props = {
    readonly messages: Array<ChatMessage>;
    readonly openAt: OpenAtConfig;
};

const ChatGallery = ({ messages, openAt }: Props) => {
    const imageMessages = React.useMemo(
        () => messages.filter((message) => !!message.content[ChatMessageType.IMAGES]),
        [messages],
    );
    const items = imageMessages
        .map(({ content }) => content[ChatMessageType.IMAGES])
        .filter(Boolean)
        .flat()
        .map((content) => content?.url);
    const startIndex = React.useMemo(() => {
        const messageIndex = imageMessages.findIndex(
            (message) => message.xid === openAt.messageXID,
        );
        const previousMessages = imageMessages.slice(0, messageIndex);

        return (
            previousMessages.reduce(
                (acc, { content }) => (content[ChatMessageType.IMAGES]?.length ?? 0) + acc,
                0,
            ) + openAt.index
        );
    }, [imageMessages, openAt]);

    const [renderLeftNav, renderRightNav] = useRenderNavButtons();

    const renderItem = React.useCallback((url: string) => <Item url={url} />, []);

    return (
        <ImageGallery
            items={items}
            startIndex={startIndex}
            renderItem={renderItem}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
            slideDuration={250}
            swipeThreshold={1}
            preventDefaultTouchmoveEvent
        />
    );
};

export default ChatGallery;
