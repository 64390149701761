import * as React from 'react';
import { JSXElementConstructor } from 'react';
import { connect } from 'react-redux';

import { withRouter, HistoryRouter } from 'react-true-router';
import { usePreviousState } from 'strat/util';
import { GlobalState } from 'strat/state';

import { Actions } from '../types';
import type { Action, DialogProps, DialogsCollection, State } from '../types';

import { reducer, computeDefaultState } from './state';
import { selectDefaultVisibleAlert } from './selectors';

type Props = {
    children: React.ReactNode;
    registeredDialogs: DialogsCollection;
    defaultVisibleAlert?: string;
    router: HistoryRouter;
};

type DialogContextType = [State, React.Dispatch<Action>];

export const Context = React.createContext<DialogContextType>([
    {} as State,
    (_action: Action) => {},
]);

Context.displayName = 'DialogContext';

const DialogContext = ({
    children,
    registeredDialogs = {},
    defaultVisibleAlert,
    router,
}: Props) => {
    const [state, dispatch] = React.useReducer(reducer, registeredDialogs, computeDefaultState);
    const wasDefaultAlertVisible = usePreviousState(
        defaultVisibleAlert && state[defaultVisibleAlert]
            ? state[defaultVisibleAlert].visible
            : null,
    );
    const previousDefaultAlert = usePreviousState(defaultVisibleAlert);

    const visibleAlert =
        defaultVisibleAlert && state[defaultVisibleAlert] && !state[defaultVisibleAlert].visible;

    React.useEffect(() => {
        if (visibleAlert) {
            dispatch({
                type: Actions.SET_VISIBLE,
                visible: true,
                name: defaultVisibleAlert,
                props: {},
            });
        }

        if (!defaultVisibleAlert && previousDefaultAlert) {
            dispatch({
                type: Actions.SET_VISIBLE,
                visible: false,
                name: previousDefaultAlert,
                props: {},
            });
        }
    }, [defaultVisibleAlert, previousDefaultAlert, visibleAlert]);

    React.useEffect(() => {
        if (visibleAlert && wasDefaultAlertVisible) {
            const history = router.history;
            router.history.push({
                // @ts-expect-error - TS2339 - Property 'location' does not exist on type '{ push: (location: Location) => void; replace: (location: Location) => void; goBack: () => void; }'.
                ...history.location,
                // @ts-expect-error - TS2339 - Property 'location' does not exist on type '{ push: (location: Location) => void; replace: (location: Location) => void; goBack: () => void; }'.
                search: history.location.search.replace(/alert=[a-zA-Z]+&?/, ''),
            });
        }
    }, [visibleAlert, router.history, wasDefaultAlertVisible]);

    React.useEffect(() => {
        const callback = router.listen((_, action) => {
            if (action === 'POP') {
                Object.keys(registeredDialogs).forEach((dialogName) => {
                    if (state[dialogName].visible) {
                        dispatch({
                            type: Actions.SET_VISIBLE,
                            visible: false,
                            name: dialogName,
                            props: state[dialogName].props,
                        });
                    }
                });
            }
        });
        return () => {
            router.unsubscribe(callback);
        };
    }, [router, registeredDialogs, state]);

    const dialogs = Object.entries(registeredDialogs).map(([key, element]) => {
        const dialogProps: DialogProps = {
            key,
            ...state[key].props,
        };

        return React.createElement(element as React.ComponentType<DialogProps>, dialogProps);
    });

    return (
        <Context.Provider value={[state, dispatch]}>
            {React.cloneElement(
                children as React.ReactElement<any, string | JSXElementConstructor<any>>,
                {
                    dialogs,
                },
            )}
        </Context.Provider>
    );
};

type DialogVisibilityFunction = (visible: boolean, props?: DialogProps) => void;

export const useDialog = (dialogName: string): [boolean, DialogVisibilityFunction] => {
    const [state, dispatch] = React.useContext(Context);

    const setVisible = React.useCallback(
        (visible: boolean, props = { name: dialogName }) => {
            dispatch({ type: Actions.SET_VISIBLE, visible, name: dialogName, props });
        },
        [dispatch, dialogName],
    );

    if (!state[dialogName]) {
        console.warn('Invalid dialog name specified', dialogName);
        return [false, (_visible: boolean, _dialogProps: DialogProps = {}) => {}];
    }
    const isVisible = state[dialogName].visible;

    return [isVisible, setVisible];
};

export const useCloseAllDialogs = () => {
    const [, dispatch] = React.useContext(Context);

    return React.useCallback(() => {
        dispatch({ type: Actions.CLOSE_ALL_DIALOGS });
    }, [dispatch]);
};

export default withRouter(
    connect((state: GlobalState) => ({
        defaultVisibleAlert: selectDefaultVisibleAlert(state),
        routeName: state.router.routeName,
        routeParams: state.router.routeParams,
    }))(
        DialogContext as React.ComponentType<{
            router: HistoryRouter;
            registeredDialogs: DialogsCollection;
        }>,
    ),
);
