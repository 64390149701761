import { ThumbnailSizes, ThumbnailSizeValues } from './thumbnailSizes';

/**
 * Generates a URL for a thumbnail.
 */
const thumbnailURL = (
    imageID: number | string,
    encoding: string,
    size: {
        width: number;
        height: number;
    } = ThumbnailSizeValues[ThumbnailSizes.NORMAL],
) => {
    let url: string = CONFIG.build.THUMBNAILS_URL || '';

    url += `/thumbnails/${imageID}-${size.width}x${size.height}.${encoding}`;
    return url;
};

export default thumbnailURL;
