import { t } from '@lingui/macro';
import * as React from 'react';
import FormikProps from 'formik';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';
import settings from '@app/branding/settings';

import ContactInfoPhoneNumberField from 'horizontal/fields/contactInfoPhoneNumberField';
import {
    ContactInfoRolesField,
    ContactInfoNameField,
    HotlineCheckbox,
    AgentCodeField,
} from 'horizontal/fields';
import { ContactInfoFields, ShortNumberField } from 'horizontal/fields/commonPostingFields';
import { selectUserAgencies, useIsAnyTypeOfAgent } from 'horizontal/agent/state';
import { ContactPersonRole } from 'horizontal/types';

import { useInitialSellerFieldsValues } from './hooks';
import styles from './styles/form.cssm';

// @ts-expect-error - TS2709 - Cannot use namespace 'FormikProps' as a type.
type Props = FormikProps & {
    readonly isPhoneEditDisabled?: boolean;
};

const SellerFields = ({ isPhoneEditDisabled, ...formikProps }: Props) => {
    const { values, setFieldValue } = formikProps;

    const i18n = useI18n();
    const userAgencies = useSelector(selectUserAgencies);
    const isUserAgent = useIsAnyTypeOfAgent();
    const { name, mobile, roles } = useInitialSellerFieldsValues();
    const disableOtpVerification = !settings.enableOtpValidation || isUserAgent;

    const showHotlineCheckbox = isUserAgent && userAgencies?.length > 0;

    React.useEffect(() => {
        setFieldValue(ContactInfoFields.name.attribute, name);
        setFieldValue(ContactInfoFields.phone_number.attribute, mobile);
        setFieldValue(ContactInfoFields.roles.attribute, roles);
        if (
            roles?.includes(ContactPersonRole.SHOW_SHORT_NUMBER) &&
            userAgencies?.length > 0 &&
            userAgencies[0].shortNumber != null
        ) {
            setFieldValue(ShortNumberField.attribute, true);
        }
        setFieldValue(
            ContactInfoFields.is_otp_verified.attribute,
            !!mobile || disableOtpVerification,
        );
    }, [setFieldValue, name, mobile, roles, userAgencies, disableOtpVerification]);

    return (
        <>
            <div className={styles.formTitle}>
                <Text.XLarge bold uppercase>
                    {t(i18n)`Review your details`}
                </Text.XLarge>
            </div>
            <ContactInfoNameField {...formikProps} />
            {isUserAgent && <AgentCodeField {...formikProps} />}
            <ContactInfoPhoneNumberField
                {...formikProps}
                disabled={!!isPhoneEditDisabled}
                title={t(i18n)`Mobile Phone Number`}
                showShortNumber={values[ShortNumberField.attribute]}
                disableOtpVerification={disableOtpVerification}
                enablePhoneNumberChangeWarningBanner
                isMandatory={!values[ShortNumberField.attribute]}
            />
            {showHotlineCheckbox && (
                <HotlineCheckbox
                    setFieldValue={setFieldValue}
                    checked={values[ShortNumberField.attribute]}
                    disabled={userAgencies[0].shortNumber == null}
                />
            )}
            <ContactInfoRolesField {...formikProps} />
        </>
    );
};
export default SellerFields;
