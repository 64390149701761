import type { FormikValues } from 'formik';
import settings from '@app/branding/settings';

const useFormattedPhoneNumber = (values: FormikValues) => {
    const countryPrefix = settings.defaultPhoneNumber.prefix;
    let phoneNumberValue = '';
    if (values['phoneNumber'] && values['phoneNumber'].substring(countryPrefix.length).length > 0) {
        phoneNumberValue = values['phoneNumber'].substring(countryPrefix.length);
    }
    return { phoneNumberValue, countryPrefix };
};

export default useFormattedPhoneNumber;
