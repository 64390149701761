//@ts-nocheck
/**
 * Stops the propagation of an event.
 * @param e The event whose propagation should be stopped.
 */
const stopEventPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
};

export default stopEventPropagation;
