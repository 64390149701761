import * as React from 'react';
import { Flex, Text } from 'strat/components';

import CategoryImage from 'horizontal/categories/categoryImage';
import { Category } from 'horizontal/types';
import IconArrowRight from 'horizontal/assets/icons/iconArrowRight.svg';

import styles from './styles/categoryListItem.cssm';

interface Props {
    category: Category;
    onSelectCategory: (category: Category) => void;
    iconClassName?: string;
    iconContainerClassName?: string;
}

export const CategoryListItem = ({
    category,
    onSelectCategory,
    iconClassName,
    iconContainerClassName,
}: Props) => {
    return (
        <Flex
            onClick={() => onSelectCategory(category)}
            alignCenter
            justifySpaceBetween
            className={styles.container}
            aria-label={`Category level ${category.level}`}
        >
            <Flex alignCenter>
                <CategoryImage
                    category={category}
                    imageClassName={iconClassName ?? styles.icon}
                    containerClassName={iconContainerClassName ?? styles.categoryIconContainer}
                />
                <Text.Base>{category.nameShort || category.name}</Text.Base>
            </Flex>
            <IconArrowRight className={styles.arrowIcon} />
        </Flex>
    );
};
