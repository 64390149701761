import React, { useContext } from 'react';

export type DismissibleStacksContextValues = {
    pushToStack: (dismissibleId: number, stackGroup?: string) => void;
    removeFromStack: (dismissibleId: number, stackGroup?: string) => void;
    topOfStack: (stackGroup?: string) => number | undefined;
};

const DismissibleStacksContext = React.createContext<DismissibleStacksContextValues>({
    pushToStack: () => {},
    removeFromStack: () => {},
    topOfStack: () => undefined,
});

export const DEFAULT_STACK_GROUP = 'default';

export const useDismissibleStacksContext = () => useContext(DismissibleStacksContext);

export const DismissibleStacksProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [stacks, setStacks] = React.useState<Record<string, number[]>>({});

    const pushToStack = React.useCallback(
        (dismissibleID: number, stackGroup: string = DEFAULT_STACK_GROUP) =>
            setStacks((stacks) => {
                if (!stacks[stackGroup]) {
                    return { ...stacks, [stackGroup]: [dismissibleID] };
                }

                return { ...stacks, [stackGroup]: [...stacks[stackGroup], dismissibleID] };
            }),

        [],
    );

    const removeFromStack = React.useCallback(
        (dismissibleID: number, stackGroup: string = DEFAULT_STACK_GROUP) =>
            setStacks((stacks) => ({
                ...stacks,
                [stackGroup]: stacks[stackGroup]?.filter((id) => id !== dismissibleID),
            })),
        [],
    );

    const topOfStack = React.useCallback(
        (stackGroup: string = DEFAULT_STACK_GROUP) => {
            if (!stacks[stackGroup]?.length) {
                return undefined;
            }

            const topOfStackIndex = stacks[stackGroup].length - 1;
            return stacks[stackGroup][topOfStackIndex];
        },
        [stacks],
    );

    const dismissibleStackContext = React.useMemo(
        () => ({ pushToStack, removeFromStack, topOfStack }),
        [pushToStack, removeFromStack, topOfStack],
    );

    return (
        <DismissibleStacksContext.Provider value={dismissibleStackContext}>
            {children}
        </DismissibleStacksContext.Provider>
    );
};

export default DismissibleStacksContext;
