import { trigger, Triggers } from 'strat/gtm';
import { RouteNames } from 'strat/routes';

const trackCustomerSupport = (route: Values<typeof RouteNames>) => {
    trigger(Triggers.CLICK_CUSTOMER_SUPPORT, {
        route: route,
    });
};

export { trackCustomerSupport };
