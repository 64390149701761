import * as React from 'react';
import classNames from 'classnames';
import { Text, Flex } from 'strat/components';

import IconCheck from 'horizontal/assets/icons/iconCheck.svg';

import styles from './styles/checkedText.cssm';

type Props = {
    readonly text: string;
    readonly className?: string;
    readonly light?: boolean;
};

const CheckedText = ({ text, light, className }: Props) => (
    <Flex className={className}>
        <IconCheck className={styles.iconCheck} />
        <Text.Base
            className={classNames(styles.text, {
                [styles.light]: light,
            })}
        >
            {text}
        </Text.Base>
    </Flex>
);

export default CheckedText;
