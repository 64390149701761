import type { URLProcessor } from 'react-true-router/urlProcessor';

// @ts-expect-error - TS7019 - Rest parameter 'fns' implicitly has an 'any[]' type.
const compose = (...fns) =>
    fns.reduce(
        (f, g) =>
            // @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
            (...args) =>
                f(g(...args)),
    );

const composeUrlProcessor = (processors: Array<URLProcessor>): URLProcessor => ({
    inbound: (url: string): string =>
        compose(...processors.map((processor) => processor.inbound))(url),
    outbound: (url: string): string =>
        compose(...processors.map((processor) => processor.outbound))(url),
});

export default composeUrlProcessor;
