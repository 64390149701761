import * as React from 'react';

import useKeyNavigation from './useKeyNavigation';

const useListKeyNavigation = <T>(
    list: Array<T>,
    onSelected: (arg1: T) => void,
    onClear?: () => void,
    onDelete?: () => void,
) => {
    const [selectedIndex, setSelectedIndex] = React.useState<any>(null);

    const reset = React.useCallback(() => {
        setSelectedIndex(null);
    }, [setSelectedIndex]);

    const mapIndexToListRange = React.useCallback(
        (index) => {
            if (!list) {
                return null;
            }

            const elementCount = list.length;

            if (index === null) {
                return null;
            }

            if (index >= 0) {
                return index % elementCount;
            }

            return (elementCount + (index % elementCount)) % elementCount;
        },
        [list],
    );

    const onNext = () => {
        if (selectedIndex === null) {
            setSelectedIndex(0);
            return;
        }

        setSelectedIndex(selectedIndex + 1);
    };

    const onPrevious = () => {
        if (selectedIndex === null) {
            setSelectedIndex(-1);
            return;
        }

        setSelectedIndex(selectedIndex - 1);
    };

    const onSubmit = () => {
        const index = mapIndexToListRange(selectedIndex);
        if (index === null) {
            return;
        }

        onSelected(list[index]);
    };

    return {
        onKeyDown: useKeyNavigation(onNext, onPrevious, onSubmit, onDelete, onClear),
        selectedIndex: mapIndexToListRange(selectedIndex),
        reset,
    };
};

export default useListKeyNavigation;
