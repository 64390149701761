import { makeCancelable } from 'strat/util';

import { StratAPI } from 'horizontal/api';

import { UserDeliveryOrderStatus } from '../types';

const updateDeliveryOrderStatus = (
    orderID: number,
    orderStatus: string | null | undefined = UserDeliveryOrderStatus.REJECTED,
    userExternalID: string,
    onSuccess: () => void,
) => {
    const cancelablePromise = makeCancelable(
        // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
        new StratAPI().updateDeliveryOrderStatus(userExternalID, orderID, orderStatus),
    );

    cancelablePromise.then(() => {
        onSuccess();
    });
};

export default updateDeliveryOrderStatus;
