/**
 * Action that sets the active language.
 */
export type SetLanguageAction = {
    type: string;
    language: string;
};

/**
 * Available language actions.
 */
export default Object.freeze({
    SET_LANGUAGE: 'LANGUAGE/SET',
});
