import type { I18n } from '@lingui/core';
import {
    ExactFilter,
    Filter,
    FilterCollection,
    PageFilter,
    RangeFilter,
    RefinementFilter,
    RangeFilterValue,
} from '@sector-labs/fe-search-redux/filters';

import { FilterValues } from 'strat/search';
import Purpose from 'strat/purpose';
import Category from 'strat/branding/category';
import { AgentCategory } from 'strat/agency/agent/types';
import { getCubeRankingField } from 'strat/agency/search/form/fetchDataFromProBoard';
import { getAgentLocationFilterValues } from 'strat/agency/search/form/filters';
import {
    LeaderboardRankingTypes,
    MiniLeaderboardIndexRankKeyMapping,
} from 'strat/leaderboard/miniLeaderboard/types';
import { getRankKeyByTab, getXPEarnedKeyByTab } from 'strat/leaderboard/miniLeaderboard/utils';

const getAgentCategoryFilterValue = (
    filters: FilterCollection,
    purposeAgent: string | null,
    i18n: I18n,
    mapToESIndexAttribute = true,
) => {
    const saleCategory: string = filters.getFilterValue(
        FilterValues.agentSaleCategory.attribute,
        FilterValues.agentSaleCategory.default,
    );
    const rentalCategory: string = filters.getFilterValue(
        FilterValues.agentRentalCategory.attribute,
        FilterValues.agentRentalCategory.default,
    );
    switch (purposeAgent) {
        case Purpose.FOR_SALE:
            return mapToESIndexAttribute
                ? FilterValues.agentSaleCategory.findSearchIndexAttribute(saleCategory, i18n)
                : saleCategory;
        case Purpose.FOR_RENT:
            return mapToESIndexAttribute
                ? FilterValues.agentRentalCategory.findSearchIndexAttribute(rentalCategory, i18n)
                : rentalCategory;
        default:
            return 'total';
    }
};

const getLPVCategoryFilterValue = (
    filters: FilterCollection,
    purpose: string | null,
    i18n: I18n,
    mapToESIndexAttribute = true,
) => {
    const category = filters.getFilterValue(
        FilterValues.category.attribute,
        FilterValues.category.default,
    );
    const categoryParent = category?.parent
        ? FilterValues.category
              .choices(i18n)
              .find((cat) => cat.externalID === category?.parent?.externalID)
        : null;
    const isResidential =
        category?.slug === Category.RESIDENTIAL ||
        (categoryParent && categoryParent.slug === Category.RESIDENTIAL);

    const rentFrequency = filters.getFilterValue(FilterValues.rentFrequency.attribute, null);
    const agentRentCategory = FilterValues.rentFrequency.proboardType(rentFrequency);
    switch (purpose) {
        case Purpose.FOR_SALE:
            return mapToESIndexAttribute
                ? FilterValues.agentSaleCategory.findSearchIndexAttribute(
                      isResidential ? AgentCategory.RESIDENTIAL : AgentCategory.COMMERCIAL,
                      i18n,
                  )
                : isResidential
                  ? AgentCategory.RESIDENTIAL
                  : AgentCategory.COMMERCIAL;
        case Purpose.FOR_RENT:
            return mapToESIndexAttribute
                ? FilterValues.agentRentalCategory.findSearchIndexAttribute(
                      isResidential ? agentRentCategory : AgentCategory.COMMERCIAL,
                      i18n,
                  )
                : isResidential
                  ? agentRentCategory
                  : AgentCategory.COMMERCIAL;
        default:
            return 'total';
    }
};

export const getPurposeCategoryFilterValues = (
    filters: FilterCollection,
    i18n: I18n,
    mapToESIndexAttribute = true,
) => {
    const purposeAgent: string | null = filters.getFilterValue(
        FilterValues.purpose.truBrokerAttribute,
        null,
    );
    const purposeLPV: string | null = filters.getFilterValue(FilterValues.purpose.attribute, null);
    const isAgentSearch = purposeAgent !== null;

    const categoryValue = isAgentSearch
        ? getAgentCategoryFilterValue(filters, purposeAgent, i18n, mapToESIndexAttribute)
        : getLPVCategoryFilterValue(filters, purposeLPV, i18n, mapToESIndexAttribute);

    return [purposeAgent || purposeLPV, categoryValue];
};

export const getPurposeCategoryFilters = (filters: FilterCollection, i18n: I18n) => {
    const [purposeValue, categoryValue] = getPurposeCategoryFilterValues(filters, i18n);

    return [
        new ExactFilter({
            attribute: 'purpose_sk',
            value: Purpose.leaderboardCubeValue(purposeValue),
        }),
        new ExactFilter({
            attribute: 'trubroker_listing_category_sk',
            value: categoryValue,
        }),
    ];
};

const getCommonFilters = (filters: FilterCollection, useLast3MonthData = false) => {
    const commonFilters = [];

    const languages: Array<any> | null = filters.getFilterValue(
        FilterValues.languages.attribute,
        null,
    );
    if (languages) {
        commonFilters.push(
            new RefinementFilter({
                attribute: 'user_lang_codes_en',
                value: languages,
            }),
        );
    }

    const agentExternalID = filters.getFilterValue('agent_external_id', null);
    if (agentExternalID) {
        commonFilters.push(
            new ExactFilter({
                attribute: `agent_external_id`,
                value: agentExternalID,
            }),
        );
    }

    const isTruBroker: string | null = filters.getFilterValue('isTruBroker', null);
    if (isTruBroker) {
        commonFilters.push(
            new ExactFilter({
                attribute: `is_trubroker_badge_prev_month`,
                value: isTruBroker,
            }),
        );
    }

    const comboXpRange: RangeFilterValue | null = filters.getFilterValue('combo_xp', null);
    if (comboXpRange) {
        commonFilters.push(
            new RangeFilter({
                attribute: `combo_xp`,
                value: comboXpRange,
            }),
        );
    }

    const rankingType = useLast3MonthData
        ? LeaderboardRankingTypes.LAST_3_MONTHS
        : LeaderboardRankingTypes.ALL_TIME;

    const rankingKey = getRankKeyByTab(rankingType, filters);
    const rankingKeyField = MiniLeaderboardIndexRankKeyMapping[rankingKey];
    const rankingFilterValue: RangeFilterValue | null = filters.getFilterValue(
        rankingKeyField,
        null,
    );
    if (rankingFilterValue) {
        commonFilters.push(
            new RangeFilter({
                attribute: rankingKeyField,
                value: rankingFilterValue,
            }),
        );
    }

    const xpField = getXPEarnedKeyByTab(rankingType, rankingKey);
    const xpFilterValue: RangeFilterValue | null = filters.getFilterValue(xpField, null);
    if (xpFilterValue) {
        commonFilters.push(
            new RangeFilter({
                attribute: xpField,
                value: xpFilterValue,
            }),
        );
    }

    const emirate: string = filters.getFilterValue('activeInEmirate', '');
    if (emirate.length > 0) {
        commonFilters.push(
            new ExactFilter({
                attribute: `most_active_emirate_prev_month`,
                value: emirate,
            }),
        );
    }

    return commonFilters;
};

export const getSearchFilters = (
    filters: FilterCollection,
    useLast3MonthData = false,
): Array<Filter> => {
    const page: number = filters.getFilterValue(FilterValues.page.attribute, 1);

    const rankingFilterValue: RangeFilterValue = filters.getFilterValue(
        'trubroker_first_ranking_overall',
        {
            min: 1,
            max: null,
        },
    );

    const newFiltersList: Array<ExactFilter | PageFilter | RefinementFilter> = [
        new PageFilter({ value: page }),
        new RangeFilter({
            attribute: `trubroker_first_ranking_overall`,
            value: rankingFilterValue,
        }),
    ];

    newFiltersList.push(...getCommonFilters(filters, useLast3MonthData));

    return newFiltersList;
};

export const getCubeSearchFilters = (
    filters: FilterCollection,
    i18n: I18n,
    locationsWithAds?: Array<any>,
    useLast3MonthData = false,
): Array<Filter> => {
    const page: number = filters.getFilterValue(FilterValues.page.attribute, 1);
    const newFiltersList: Array<ExactFilter | PageFilter | RefinementFilter> = [
        new PageFilter({ value: page }),
    ];

    const agentLocations: Array<any> | null = getAgentLocationFilterValues(filters);

    const locations = locationsWithAds || agentLocations;

    if (locations?.length) {
        newFiltersList.push(
            new RefinementFilter({
                attribute: `location_external_id`,
                value: locations.map((location) => location.externalID),
            }),
        );
    }

    const purposeAndCategoryFilters = getPurposeCategoryFilters(filters, i18n);
    newFiltersList.push(...purposeAndCategoryFilters);

    const cubeRankingField = getCubeRankingField(filters, locationsWithAds);
    const cubeRankingFilterValue: RangeFilterValue = filters.getFilterValue(cubeRankingField, {
        min: 1,
        max: null,
    });

    if (cubeRankingField) {
        newFiltersList.push(
            new RangeFilter({
                attribute: cubeRankingField,
                value: cubeRankingFilterValue,
            }),
        );
    }

    newFiltersList.push(...getCommonFilters(filters, useLast3MonthData));

    return newFiltersList;
};
