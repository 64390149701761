import * as React from 'react';

import { AlertsManagerDialog } from 'strat/alerts';
import { LoginPopUp, RegisterPopUp } from 'strat/auth/express';
import NotificationsApprovalDialog from 'strat/notifications/notificationApprovalDialog';
import { ReviewsManagerDialog } from 'strat/reviewsManager';
import CommuteSurveyDialog from 'strat/search/mapBasedSearch/commute/commuteSurvey';
import { ErrorDialog } from 'strat/modal';
import { ShareDialog } from 'strat/social/shortlink';
import { AgentReviewsDialog } from 'strat/agency/agent/review';
import KeycloakAuthDialog from 'strat/auth/keycloak/components/keycloakAuthDialog';
import KeycloakRegisterDialog from 'strat/auth/keycloak/components/keycloakRegisterDialog';
import { isKeycloakEnabled } from 'strat/auth/keycloak/config';

import { authorizedDialog, registerDialog } from './dialogContext';
import DialogNames from './dialogNames';
import { DialogComponentProps } from './types';

const wrapInKeycloakEnabledCondition =
    (
        KCComponent: React.ComponentType<DialogComponentProps>,
        ExpressComponent: React.ComponentType<DialogComponentProps>,
    ) =>
    (props: DialogComponentProps) => {
        if (isKeycloakEnabled()) {
            return React.createElement(KCComponent, props);
        }

        return React.createElement(ExpressComponent, props);
    };

const RegisteredAlertsManagerDialog = registerDialog(
    DialogNames.ALERTS_MANAGER,
    AlertsManagerDialog,
);
const RegisteredLoginDialog = registerDialog(
    DialogNames.LOGIN,
    wrapInKeycloakEnabledCondition(
        KeycloakAuthDialog as React.ComponentType<DialogComponentProps>,
        LoginPopUp as React.ComponentType<DialogComponentProps>,
    ),
);
const RegisteredRegisterDialog = registerDialog(
    DialogNames.REGISTER,
    wrapInKeycloakEnabledCondition(
        KeycloakRegisterDialog as React.ComponentType<DialogComponentProps>,
        RegisterPopUp as React.ComponentType<DialogComponentProps>,
    ),
);

const RegisteredNotificationsApprovalDialog = registerDialog(
    DialogNames.NOTIFICATIONS_APPROVAL,
    NotificationsApprovalDialog,
);

const AuthorizedAlertsManagerDialog = authorizedDialog(
    DialogNames.LOGIN,
    DialogNames.ALERTS_MANAGER,
    RegisteredAlertsManagerDialog,
);

const UserReviewsManagerDialog = registerDialog(
    DialogNames.AGENT_REVIEWS_MANAGER,
    ReviewsManagerDialog,
);

const RegisteredErrorDialog = registerDialog(DialogNames.ERROR, ErrorDialog);

const RegisteredShareLinkDialog = registerDialog(DialogNames.SHARE_LINK, ShareDialog);
const RegisteredAgentReviewsDialog = registerDialog(DialogNames.AGENT_REVIEWS, AgentReviewsDialog);
const RegisteredNBSSurveyDialog = registerDialog(DialogNames.NBS_SURVEY, CommuteSurveyDialog);

const RegisteredDialogs = {
    [DialogNames.ALERTS_MANAGER]: AuthorizedAlertsManagerDialog,
    [DialogNames.LOGIN]: RegisteredLoginDialog,
    [DialogNames.REGISTER]: RegisteredRegisterDialog,
    [DialogNames.NOTIFICATIONS_APPROVAL]: RegisteredNotificationsApprovalDialog,
    [DialogNames.AGENT_REVIEWS_MANAGER]: UserReviewsManagerDialog,
    [DialogNames.ERROR]: RegisteredErrorDialog,
    [DialogNames.SHARE_LINK]: RegisteredShareLinkDialog,
    [DialogNames.AGENT_REVIEWS]: RegisteredAgentReviewsDialog,
    [DialogNames.NBS_SURVEY]: RegisteredNBSSurveyDialog,
    ...{},
} as const;

export default RegisteredDialogs;
