import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import Logo from '@app/branding/logo';
import { BackButton, Header } from 'strat/components';

import type { Ad, ProductPackageOffer } from 'horizontal/types';
import ApplyLimitPackage from 'horizontal/adLimits/applyLimitPackage';
import { ProductPurchase } from 'horizontal/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/selectSinglePackagePageDesktop.cssm';

export const ApplyAdLimitPackagePageHeader = () => {
    const router = useRouter();
    const onBack = React.useCallback(() => {
        router.pushRoute(RouteNames.AD_MANAGEMENT);
    }, [router]);

    return (
        <Header className={styles.header}>
            <BackButton onBack={onBack} />
            <Logo className={styles.logo} />
        </Header>
    );
};

type Props = {
    readonly ad: Ad | null | undefined;
    readonly productPurchases: Array<ProductPurchase> | null | undefined;
    readonly offerPackages: Array<ProductPackageOffer> | null | undefined;
};

const ApplyAdLimitPackagePageDesktop = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ ad, productPurchases, offerPackages }: Props) => (
        <>
            <ApplyAdLimitPackagePageHeader />
            <ApplyLimitPackage ad={ad} productPurchases={productPurchases} offers={offerPackages} />
        </>
    ),
} as const;

export default ApplyAdLimitPackagePageDesktop;
