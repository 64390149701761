import * as React from 'react';

import styles from './styles/floatingDialogActionButtons.cssm';
import Button from './button';
import Flex from './flex';

type Props = {
    primaryButtonAction: (arg1?: any) => void;
    secondaryButtonAction: (arg1?: any) => void;
    primaryButtonText: string;
    secondaryButtonText: string;
};

const FloatingDialogActionButtons = ({
    primaryButtonAction,
    primaryButtonText,
    secondaryButtonAction,
    secondaryButtonText,
}: Props) => (
    <Flex stretchWidth>
        <div className={styles.button}>
            <Button onClick={secondaryButtonAction} disabled={false} secondary stretch>
                {secondaryButtonText}
            </Button>
        </div>
        <div className={styles.button}>
            <Button onClick={primaryButtonAction} disabled={false} stretch>
                {primaryButtonText}
            </Button>
        </div>
    </Flex>
);
export default FloatingDialogActionButtons;
