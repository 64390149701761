import type { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import Purpose from 'strat/purpose';
import { PropertyCompletionStatus, PropertyType } from 'strat/property/types';
import FilterValues from 'strat/search/filterValues';

import extractProjectPageLocationSlugs from './extractProjectPageLocationSlugs';

const isProjectRelatedSearchPage = (adFilters?: FilterCollection | null): boolean => {
    const purpose = adFilters?.getFilterValue<Values<typeof Purpose>>(
        FilterValues.purpose.attribute,
    );
    const completionStatus = adFilters?.getFilterValue<Values<typeof PropertyCompletionStatus>>(
        FilterValues.completionStatus.attribute,
    );
    const listingType = adFilters?.getFilterValue<PropertyType>(
        FilterValues.type.attribute,
        FilterValues.type.default,
    );

    return (
        purpose === Purpose.FOR_SALE &&
        completionStatus === PropertyCompletionStatus.OFF_PLAN &&
        listingType === PropertyType.PROPERTY
    );
};

/**
 * Checks whether the specified filters match the ones required
 * to fetch the child project count.
 *
 * We use the child count to display the "Switch to project view"
 * button. If the child count is zero, we don't display it.
 */
export const isProjectChildCountFetchingRequired = (adFilters?: FilterCollection | null): boolean =>
    CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH && isProjectRelatedSearchPage(adFilters);

/**
 * Checks whether the specified filters match the ones required to
 * display the project page.
 *
 * This is an estimation since the location filter might only be
 * a slug and we don't know yet whether it's a location marked
 * as `off-plan`.
 *
 * This function is used to guess whether it's worth trying to
 * find a matching project for the project page. The `selectProject`
 * and `selectIsProjectPage` selectors eventually determine whether
 * it's actually a project
 * page.
 *
 * The second argument (locationFilters) is used in the SearchRoute.
 * Before fetching the results from the search index, the location
 * filters are held separately because they are fetched from the
 * the separate locations index.
 */
export const isProjectPageFetchingRequired = (
    adFilters?: FilterCollection | null,
    locationFilters?: FilterCollection | null,
): boolean => {
    const locationSlugs = extractProjectPageLocationSlugs(adFilters, locationFilters);

    return (
        CONFIG.runtime.STRAT_ENABLE_PROJECT_PAGE &&
        isProjectRelatedSearchPage(adFilters) &&
        locationSlugs.length === 1
    );
};
