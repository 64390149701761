import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import useCustomExtraFieldsMetadata from '@app/search/seo/useCustomExtraFieldsMetadata';

import type { Category, Location, CategoryFieldsFilters } from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';
import { selectCustomMetaTitle } from 'horizontal/urlSpecificSEO/selectors';

import useTerm from './useTerm';
import usePurposeAdjective from './usePurposeAdjective';
import useLocationName from './useLocationName';
import capitalizeWords from './capitalizeWords';

type Props = {
    readonly category?: Category | null | undefined;
    readonly location?: Location | null | undefined;
    readonly freeTextQuery?: string | null | undefined;
    readonly extraFields?: CategoryFieldsFilters;
    readonly page?: number;
};

const useTitle = ({ category, location, freeTextQuery, extraFields, page }: Props): string => {
    const i18n = useI18n();
    const term = useTerm({ category, extraFields });
    const purposeAdjective = usePurposeAdjective(category, CategoryRole.INCLUDE_PURPOSE_IN_TITLE);
    const locationName = useLocationName(location);
    const domainName = settings.getDomainName(i18n);
    const customTitle = useSelector(selectCustomMetaTitle);
    const { title: customExtraFieldsTitle } = useCustomExtraFieldsMetadata({
        category,
        location,
        page,
    });
    if (customTitle) {
        return customTitle;
    }

    const termWithPurpose = purposeAdjective && term ? `${term} ${purposeAdjective}` : term;
    const capitalizedFreeTextQuery = freeTextQuery ? capitalizeWords(freeTextQuery) : null;

    if (termWithPurpose && capitalizedFreeTextQuery) {
        const termWithFreeTextQuery = `${capitalizedFreeTextQuery} - ${termWithPurpose}`;
        return t(i18n)`${termWithFreeTextQuery} in ${locationName} | ${domainName}`;
    }

    if (!freeTextQuery && customExtraFieldsTitle) {
        return customExtraFieldsTitle;
    }

    if (termWithPurpose && !capitalizedFreeTextQuery) {
        return t(i18n)`${termWithPurpose} in ${locationName} | ${domainName}`;
    }

    if (!termWithPurpose && capitalizedFreeTextQuery) {
        return t(
            i18n,
        )`${capitalizedFreeTextQuery} in ${locationName}, Free classifieds in ${locationName} | ${domainName}`;
    }

    return t(
        i18n,
    )`Free classifieds in ${locationName}, classified ads in ${locationName} (For Sale in ${locationName}, Vehicles in ${locationName}, Real Estate in ${locationName}, Community in ${locationName},...)`;
};

export default useTitle;
