import * as React from 'react';

import { ImageGalleryDialog, ImageSlideShowItemType } from 'strat/image';
import { PropertyPhotoData } from 'strat/property';
import BackButton from 'strat/property/compact/gallery/backButton';

import styles from './styles/compactUnitFloorPlanGalleryDialog.cssm';

type Props = {
    photos: PropertyPhotoData[];
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
};

const CompactUnitFloorPlanGalleryDialog = ({ photos, visible, onVisibilityChanged }: Props) => {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const slides = React.useMemo(() => {
        return photos.map((photo) => ({
            ...photo,
            type: ImageSlideShowItemType.PHOTO,
        }));
    }, [photos]);

    const renderBackButton = () => (
        <BackButton onClick={() => onVisibilityChanged(false)} className={styles.backButton} />
    );

    return (
        <ImageGalleryDialog
            // @ts-expect-error - TS2322 - Type '{ ariaLabel: string; photos: { render: (_: any) => Element; type: string; }[]; startIndex: number; onSlide: Dispatch<SetStateAction<number>>; visible: boolean; onVisibilityChanged: (visibility: any) => void; showCaption: true; swiping: true; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<(Anonymous class)> & Readonly<Props> & Readonly<...>'.
            photos={slides}
            startIndex={activeIndex}
            onSlide={setActiveIndex}
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            renderCloseButton={renderBackButton}
            swiping
        />
    );
};

export default CompactUnitFloorPlanGalleryDialog;
