import Category from './category';

export const ResidentialPropertyTypes = Object.freeze({
    APARTMENTS: 'apartments',
    VILLAS: 'villas',
    TOWNHOUSES: 'townhouses',
    PENTHOUSE: 'penthouse',
    VILLA_COMPOUND: 'villa-compound',
    HOTEL_APARTMENTS: 'hotel-apartments',
    RESIDENTIAL_PLOTS: 'residential-plots',
    RESIDENTIAL_FLOORS: 'residential-floors',
    RESIDENTIAL_BUILDING: 'residential-building',
    HOUSES: 'houses',
});

export const CommercialPropertyTypes = Object.freeze({
    OFFICES: 'offices',
    SHOPS: 'shops',
    WAREHOUSES: 'warehouses',
    LABOUR_CAMPS: 'labour-camps',
    COMMERCIAL_VILLAS: 'commercial-villas',
    BULK_UNITS: 'bulk-units',
    COMMERCIAL_PLOTS: 'commercial-plots',
    COMMERCIAL_FLOORS: 'commercial-floors',
    COMMERCIAL_BUILDINGS: 'commercial-buildings',
    FACTORIES: 'factories',
    INDUSTRIAL_LAND: 'industrial-land',
    MIXED_USE_LAND: 'mixed-use-land',
    SHOWROOMS: 'showrooms',
    COMMERCIAL_PROPERTIES: 'commerical-properties',
});

/**
 * Constant values for the property types. Also possible values for the Category filter.
 */
export const PropertyTypes = Object.freeze({
    ANY: 'any',
    // residential
    ...ResidentialPropertyTypes,

    // commercial
    ...CommercialPropertyTypes,
});

export type PropertyType = Values<typeof PropertyTypes>;

export const isOfCommercialPropertyType = (category: any) =>
    Object.values(CommercialPropertyTypes).findIndex((type: any) =>
        Category.isOfType(category, type),
    ) !== -1;
