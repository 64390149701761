import * as React from 'react';

import styles from './styles/sectionTitle.cssm';

type Props = {
    readonly title: string;
    readonly className?: string;
};

const SectionTitle = ({ title }: Props) => <div className={styles.title}>{title}</div>;

export default SectionTitle;
