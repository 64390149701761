import transformLocalizedFields from './transformLocalizedFields';

const locationTransformer = <T extends object>(
    location: T,
    lang: string,
    mergedIndex: boolean,
    keepOriginalTranslations = false,
): T => {
    if (!mergedIndex) {
        return location;
    }

    if (!location) {
        return location;
    }

    const newLocation: T = transformLocalizedFields(
        location,
        lang,
        mergedIndex,
        keepOriginalTranslations,
    );

    // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type 'T'.
    if (newLocation.hierarchy) {
        // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type 'T'. | TS2339 - Property 'hierarchy' does not exist on type 'T'. | TS7006 - Parameter 'item' implicitly has an 'any' type.
        newLocation.hierarchy = newLocation.hierarchy.map((item) =>
            transformLocalizedFields(item, lang, mergedIndex, keepOriginalTranslations),
        );
    }

    return newLocation;
};

const autocompleteLocationTransformer = <T extends object>(
    location: T,
    lang: string,
    mergedIndex: boolean,
): T => {
    if (!mergedIndex) {
        return location;
    }

    if (!location) {
        return location;
    }

    const newLocation = locationTransformer(location, lang, mergedIndex, true);
    // @ts-expect-error - TS2339 - Property '_highlightResult' does not exist on type 'T'.
    if (newLocation._highlightResult) {
        // @ts-expect-error - TS2339 - Property '_highlightResult' does not exist on type 'T'.
        newLocation._highlightResult = transformLocalizedFields(
            // @ts-expect-error - TS2339 - Property '_highlightResult' does not exist on type 'T'.
            newLocation._highlightResult,
            lang,
            mergedIndex,
            true,
        );
    }

    return newLocation;
};

export { locationTransformer, autocompleteLocationTransformer };
