import * as React from 'react';
import autoBind from 'react-autobind';

import { Scroll } from 'strat/util';
import { toggleViewportHeightFreeze } from 'strat/modal';

import DialogContext from './dialogContext';
import Transition from './transition';
import OffScreen from './offScreen';
import Dismissible from './dismissible';
import styles from './styles/dialog.cssm';

export type DialogPosition = {
    /**
     * Indicates the number of pixels from the top of the viewport
     */
    readonly top?: number;
    /**
     * Indicates the number of pixels from the left of the viewport
     */
    readonly left?: number;
};

/**
 * Properties for {@see Dialog}.
 */
type Props = {
    /**
     * Indicates whether the dialog should be visible.
     */
    readonly visible: boolean;
    /**
     * Callback for when the visibility needs to change.
     */
    readonly onVisibilityChanged: (visible: boolean) => void;
    /**
     * Indicates whether the dialog should be dismissible
     * by clicking the background.
     */
    readonly dismissible?: boolean;
    /**
     * Whether the dismissible should be stacked.
     */
    readonly stacked?: boolean;
    /**
     * The stack group the dismissible should be part of.
     */
    readonly stackGroup?: string;
    /**
     * Additional styles injected using a CSS class.
     */
    readonly className?: string;
    /**
     * Additional for the dismissible div.
     */
    readonly dismissibleClassName?: string;
    /**
     * Contents of the dialog.
     */
    readonly children?: React.ReactNode;
    /**
     * Prerender dialog so assets will be prefetched.
     */
    readonly preRender?: boolean;
    readonly zIndex?: number;
    /**
     * Sets the position of the dialog relative to the viewport
     */
    readonly dialogPosition?: DialogPosition;
    /**
     * Contents that should not be considered outside the dismissible's area.
     * This can be used for objects that are visually inside the dialog, but structurally outside.
     * (Such cases can be possible for objects with a high z-index)
     */
    readonly dismissibleIgnore?: HTMLElement;
    /**
     * Additional classname for the overlay div.
     */
    readonly overlayClassName?: string;
    /**
     * Whether to display the overlay or not (default: yes)
     */
    readonly withOverlay?: boolean;
    /**
     * Whether to hide the children instead of unmounting
     */
    readonly persistent?: boolean;
    /**
     * Aria label for a specific dialog
     */
    readonly label?: string;
    readonly style?: Object;
};

type DialogProviderProps = {
    readonly dialog: any;
    readonly children?: React.ReactNode;
};

/**
 * Exposes the dialog to the children through the
 * use of React context.
 *
 * This allows components such as DialogCloseButton
 * to close/open the dialog.
 */
const DialogProvider = (props: DialogProviderProps) => (
    <DialogContext.Provider value={{ dialog: props.dialog }}>
        {React.Children.only(props.children)}
    </DialogContext.Provider>
);

/**
 * Puts a dialog in the middle of the page, centered
 * horizontally and vertically with a darkened background.
 *
 * This component does not render within the tree it is
 * placed. Instead, it creates a dialog container at the
 * end of the <body> and renders the dialog in there.
 *
 * This component follows the "controlled component"
 * pattern: https://facebook.github.io/react/docs/forms.html
 */
// eslint-disable-next-line react/no-multi-comp
class Dialog extends React.Component<Props> {
    /**
     * Unique name for this dialog.
     */
    name: string;

    /**
     * Indicates whether the dialog was made visible once, if it
     * was, then we won't unmount it, but simply hide so we can
     * do fade-out animations.
     */
    wasRendered: boolean;

    /**
     * Initializes a new instance of {@see Dialog}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.name = `strat-dialog-${Math.floor(Math.random() * 9999)}`;
        this.wasRendered = false;
    }

    /**
     * Shows the dialog.
     */
    open(): void {
        this.props.onVisibilityChanged(true);
    }

    /**
     * Hides the dialog.
     */
    close(): void {
        this.props.onVisibilityChanged(false);
    }

    /**
     * Re-enable scroll when unmounting.
     */
    componentWillUnmount() {
        Scroll.enable(this.name);
        if (this.props.visible) {
            toggleViewportHeightFreeze(false);
        }
    }

    /**
     * Disable scroll when the dialog was made visible.
     */
    disableScrollWhenVisible() {
        if (this.props.visible) {
            Scroll.disable(this.name);
        } else {
            Scroll.enable(this.name);
        }
    }

    componentDidUpdate(oldProps: Props) {
        if (oldProps.visible === this.props.visible) {
            return;
        }

        this.disableScrollWhenVisible();
        toggleViewportHeightFreeze(this.props.visible);
    }

    componentDidMount() {
        this.disableScrollWhenVisible();
        if (this.props.visible) {
            toggleViewportHeightFreeze(true);
        }
    }

    /**
     * Renders the dialog in an offscreen container.
     */
    render() {
        const preRender = !this.props.visible && this.props.preRender;
        const dialogPosition = this.props.dialogPosition || {};
        const dialog = (
            <div
                className={this.props.className}
                aria-label={this.props.label || 'Dialog'}
                style={{ display: preRender ? 'none' : '', ...dialogPosition, ...this.props.style }}
            >
                {this.props.children}
            </div>
        );

        if (preRender) {
            return dialog;
        }

        if (!this.props.visible && !this.wasRendered) {
            return null;
        }

        // next time we render, don't unmount so we can do
        // fade out transitions etc
        this.wasRendered = true;

        let dialogContents = dialog;
        if (this.props.dismissible) {
            dialogContents = (
                <Dismissible
                    enabled={this.props.visible}
                    stacked={this.props.stacked}
                    stackGroup={this.props.stackGroup}
                    onDismissed={this.close}
                    className={this.props.dismissibleClassName}
                    ignoreNode={this.props.dismissibleIgnore}
                >
                    {dialogContents}
                </Dismissible>
            );
        }

        const dialogContentsWithOverlay =
            this.props.withOverlay !== false ? (
                <Transition
                    key="transition"
                    visible={this.props.visible}
                    mountClassName={styles.fadeIn}
                    unmountClassName={styles.fadeOut}
                    hiddenClassName={this.props.persistent ? styles.hidden : null}
                >
                    <div
                        className={this.props.overlayClassName ?? styles.overlay}
                        style={{ zIndex: this.props.zIndex || 100 }}
                    >
                        {dialogContents}
                    </div>
                </Transition>
            ) : (
                dialogContents
            );

        return (
            <OffScreen name={this.name}>
                <DialogProvider dialog={this}>{dialogContentsWithOverlay}</DialogProvider>
            </OffScreen>
        );
    }
}

export default Dialog;
