import { t, plural } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { ChoiceButton } from 'horizontal/generic/choicePage';
import type { ProductPackageOffer, Ad } from 'horizontal/types';
import { AdVirtualState, ProductType } from 'horizontal/types';

import PackagePriceTag from '../packagePriceTag';

import styles from './styles/packageChoiceBox.cssm';

type Props = {
    readonly isSelected: boolean;
    readonly choice: ProductPackageOffer;
    readonly ad: Ad | null | undefined;
};

const PackageChoiceBox = ({ isSelected, choice, ad }: Props) => {
    const i18n = useI18n();
    const showCreditsProposition =
        CONFIG.build.STRAT_ENABLE_FEATURED_BTT &&
        choice.productPackage.productItems[0].type === ProductType.REFRESH &&
        ad?.virtualState === AdVirtualState.FEATURED;

    return (
        <Flex justifySpaceBetween className={styles.container}>
            <Flex fillContainer className={styles.titleAndDescription}>
                <ChoiceButton isSelected={isSelected} />
                <Flex column>
                    <Flex>
                        <Text.Large bold>{choice.productPackage.name}</Text.Large>
                        {choice.label && (
                            <div
                                className={styles.label}
                                // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'BackgroundColor | undefined'.
                                style={{ backgroundColor: choice.labelColor }}
                            >
                                <Text.Large bold>{choice.label}</Text.Large>
                            </div>
                        )}
                    </Flex>
                    <div className={styles.valueProposition}>
                        {choice.productPackage.valueProposition && (
                            <Text.Regular
                                key="valueProposition-content"
                                dangerouslySetInnerHTML={{
                                    __html: choice.productPackage.valueProposition,
                                }}
                            />
                        )}
                    </div>{' '}
                    {showCreditsProposition && (
                        <div className={styles.valueProposition}>
                            <Text.Regular bold className={styles.consumptionCreditsProposition}>
                                {t(i18n)`${plural(choice.productConsumptionWeight, {
                                    one: '# credit',
                                    other: '# credits',
                                })}`}{' '}
                                to be used to boost the ad
                            </Text.Regular>
                        </div>
                    )}
                </Flex>
            </Flex>
            <div className={styles.price}>
                <PackagePriceTag fullPrice={choice.price} discountPrice={choice.discountPrice} />
            </div>
        </Flex>
    );
};

export default PackageChoiceBox;
