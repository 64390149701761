import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import type { ChatMessage } from 'horizontal/chat/types';
import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';

import InfoBox from './infoBox';
import { getInfoBoxText } from './replies';
import styles from './styles/extraMessageInfo.cssm';

type Props = {
    readonly message: ChatMessage;
    readonly formattedPrice: string;
    readonly isMessageFromUser: boolean;
    readonly isLoggedInUserSeller: boolean;
};

const ExtraMessageInfo = ({
    message,
    formattedPrice,
    isMessageFromUser,
    isLoggedInUserSeller,
}: Props) => {
    const i18n = useI18n();

    const content = message.content[ChatMessageType.NEGOTIATION];
    if (!content) {
        return null;
    }

    const { state } = content;
    const text = getInfoBoxText(state, isMessageFromUser, isLoggedInUserSeller, i18n);

    switch (state) {
        case ChatNegotiationState.SELLER_MADE_OFFER:
        case ChatNegotiationState.BUYER_MADE_OFFER: {
            return <InfoBox className={styles.simpleInfoBox}>{text}</InfoBox>;
        }
        case ChatNegotiationState.ACCEPTED:
        case ChatNegotiationState.OFFER_REJECTED: {
            const verdict =
                state === ChatNegotiationState.ACCEPTED ? t(i18n)`accepted` : t(i18n)`rejected`;
            const verdictStyle =
                state === ChatNegotiationState.ACCEPTED ? styles.acceptedText : styles.rejectedText;

            return (
                <InfoBox>
                    <Flex justifySpaceBetween>
                        <Text.Small>
                            {text}:&nbsp;
                            <Text.Regular bold>{formattedPrice}</Text.Regular>
                        </Text.Small>
                        <Text.Base className={verdictStyle} bold uppercase>
                            {verdict}
                        </Text.Base>
                    </Flex>
                </InfoBox>
            );
        }

        case ChatNegotiationState.INITIATED:
        default:
            return null;
    }
};

export default React.memo<Props>(ExtraMessageInfo);
