import * as React from 'react';
import classNames from 'classnames';

import { LiteAd } from 'horizontal/types';
import { doesAdContainVideoBadge } from 'horizontal/util';
import { FeaturedTag } from 'horizontal/adCard/tags';
import { useLocalizedAdTitle } from 'horizontal/ad';
import EliteStrip from '@app/branding/eliteStrip';

import CoverPhoto from '../coverPhoto';

import styles from './styles/imageContainer.cssm';
import VideoIcon from './videoIcon';

type Props = {
    ad: LiteAd;
    showElite: boolean;
    adLink: JSX.Element;
    showFeatured?: boolean;
    index?: number;
    coverPhotoSize?: number;
};

const ImageContainer = ({ ad, showElite, adLink, showFeatured, index, coverPhotoSize }: Props) => {
    const lazyLoadLimit = CONFIG.build.LAZY_LOAD_FROM_DESKTOP;

    const adLocalizedTitle = useLocalizedAdTitle(ad);

    const adContainVideoBadge = doesAdContainVideoBadge(ad);

    const lazyLoadPhoto = index === undefined || index + 1 >= lazyLoadLimit;
    const coverPhoto = React.useMemo(() => {
        if (!ad.coverPhoto) {
            return null;
        }

        return {
            ...ad.coverPhoto,
            title: adLocalizedTitle,
        };
    }, [ad, adLocalizedTitle]);

    return (
        <div className={styles.photoContainer}>
            {adLink}
            {showElite && <EliteStrip />}
            <CoverPhoto
                size={coverPhotoSize}
                cover={coverPhoto}
                lazyLoad={lazyLoadPhoto}
                category={ad.category}
            />
            {showFeatured && (
                <div className={classNames(styles.featuredTag)} aria-label="Featured">
                    <FeaturedTag />
                </div>
            )}
            <div className={styles.lowerImageGradient} />
            {adContainVideoBadge && (
                <div className={styles.videoIcon} aria-label="with video">
                    <VideoIcon />
                </div>
            )}
        </div>
    );
};

export default ImageContainer;
