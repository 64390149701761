import { HTTPApi } from 'strat/util';

class S3Api extends HTTPApi {
    fetchCashMyCarLocationTree() {
        return this.request(
            `${CONFIG.build.THUMBNAILS_URL || ''}/static/json/cash_my_car_location_tree.json`,
        );
    }
}

export default S3Api;
