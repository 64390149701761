import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import type { ProductPackageOffer } from 'horizontal/types';
import { ProductType, PackageType } from 'horizontal/types';
import { BusinessPackageSubsection } from 'horizontal/packages/types';
import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';

import Section from './sectionRedesign';

const PostMoreAndFeatureSection = ({
    getTitle,
    packageOffers,
}: {
    getTitle: (
        limitDuration?: number | null,
        featuredDuration?: number | null,
    ) => React.ReactElement;
    packageOffers: Array<ProductPackageOffer>;
}) => {
    const i18n = useI18n();
    const iconSrc = usePackagesIcons({ type: PackageType.LIMIT_AND_FEATURED });

    const getSubsectionsByDuration = React.useMemo(() => {
        packageOffers.sort(
            (a, b) =>
                (a.productPackage.productItems.find(
                    (product) => product.type === ProductType.FEATURED,
                )?.duration || 0) -
                (b.productPackage.productItems.find(
                    (product) => product.type === ProductType.FEATURED,
                )?.duration || 0),
        );

        const subsectionsMapping: Record<string, BusinessPackageSubsection> = {};

        packageOffers.forEach((offer) => {
            const limitBumpProduct = offer.productPackage.productItems.find(
                (product) => product.type === ProductType.LIMIT_BUMP,
            );
            const limitBumpProductDuration =
                limitBumpProduct?.duration || settings.defaultDaysDuration;

            const featuredProduct = offer.productPackage.productItems.find(
                (product) => product.type === ProductType.FEATURED,
            );
            const featuredProductDuration =
                featuredProduct?.duration || settings.defaultDaysDuration;

            if (subsectionsMapping[`${limitBumpProductDuration}${featuredProductDuration}`]) {
                subsectionsMapping[
                    `${limitBumpProductDuration}${featuredProductDuration}`
                ].packageOffers.push(offer);
            } else {
                subsectionsMapping[`${limitBumpProductDuration}${featuredProductDuration}`] = {
                    title: getTitle(limitBumpProductDuration, featuredProductDuration),
                    packageOffers: [offer],
                };
            }
        });
        return Object.values(subsectionsMapping);
    }, [packageOffers, getTitle]);

    const details = [t(i18n)`Post ads and get noticed with the 'Featured' tag among the top.`];

    return (
        <Section
            title={t(i18n)`Post more Ads and Get Featured`}
            details={details}
            subsections={getSubsectionsByDuration}
            renderIcon={() => iconSrc && <img src={iconSrc} alt="Post More and Get Featured" />}
        />
    );
};

export default PostMoreAndFeatureSection;
