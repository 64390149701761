import * as React from 'react';
import { isCanceled } from 'strat/util';
import { useI18n } from 'strat/i18n/language';

import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';

import { PaymentOrderStatus, PaymentSource, PaymentStatus } from '../types';
import type { PaymentHookParams } from '../types';

import useNavigateToPaymentFailed from './useNavigateToPaymentFailed';
import useTrackPaymentInitiated from './useTrackPaymentInitiated';

const usePaymobCreditPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();
    const i18n = useI18n();

    return React.useCallback(() => {
        if (!order || !order.externalID) {
            return;
        }
        setIsLoading(true);
        const cancelablePromise = makeCancelable(
            new StratAPI().paymobCheckout(order.externalID, i18n.locale),
        );
        cancelablePromise
            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            .then(({ data, status }) => {
                if (status === 422) {
                    setIsLoading(false);
                    navigateToPaymentFailed(PaymentStatus.MISSING_INFO);
                    return;
                }

                const redirect = data.iframe_url;

                if (
                    status !== 200 ||
                    redirect === undefined ||
                    data.status.toLowerCase() !== PaymentOrderStatus.SUCCESS
                ) {
                    setIsLoading(false);
                    navigateToPaymentFailed();
                    return;
                }

                trackPaymentInitiated(PaymentSource.PAYMOB);

                window.location.href = redirect;
            })
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            .catch((error) => {
                if (isCanceled(error)) {
                    return;
                }
                setIsLoading(false);
            });

        setPaymentPromise(cancelablePromise);
    }, [
        order,
        setIsLoading,
        setPaymentPromise,
        navigateToPaymentFailed,
        trackPaymentInitiated,
        makeCancelable,
        i18n,
    ]);
};

export default usePaymobCreditPayment;
