import * as React from 'react';
import classNames from 'classnames';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';

import type { AgencyLogo as Logo } from 'horizontal/types';

import styles from './styles/agencyLogo.cssm';

type Props = {
    readonly className?: string;
    readonly logo: Logo | null | undefined;
};

const AgencyLogo = ({ className, logo }: Props) => {
    if (!logo?.id) {
        return null;
    }

    return (
        <ThumbnailImage
            imageID={logo?.id}
            size={ThumbnailSizes.TINY}
            alt={logo?.name}
            title={logo?.name}
            label="Agency logo"
            className={classNames(className, styles.image)}
            pictureClassName={styles.picture}
            lazyLoad
        />
    );
};

export default AgencyLogo;
