import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { useHumanizedPeriod } from 'horizontal/util';
import type { ProductPurchase, Ad } from 'horizontal/types';

import { ChoiceButton } from '../generic/choicePage';
import { AdVirtualState, ProductType } from '../types';

import styles from './styles/choiceBox.cssm';

type Prop = {
    isSelected: boolean;
    choice: ProductPurchase;
    ad: Ad | null | undefined;
    isEnabled: boolean | undefined;
};

const ChoiceBox = ({ isSelected, choice, ad, isEnabled }: Prop) => {
    const i18n = useI18n();
    const showCreditsProposition =
        CONFIG.build.STRAT_ENABLE_FEATURED_BTT &&
        ad?.virtualState === AdVirtualState.FEATURED &&
        choice.productPackageOffer.productPackage.productItems[0].type === ProductType.REFRESH;

    return (
        <Flex justifySpaceBetween>
            <Flex column fillContainer>
                <Flex>
                    <ChoiceButton isSelected={isSelected} isEnabled={isEnabled} />
                    <Text.Large bold>{choice.productPackageOffer.productPackage.name}</Text.Large>
                </Flex>
                <Text.Base className={styles.usageLimit}>
                    <Trans>Available: {choice.available}</Trans>
                </Text.Base>
                {choice.totalQuotaAssignedToAgents ? (
                    <Text.Base className={styles.usageLimit}>
                        <Trans>Total Assigned: {choice.totalQuotaAssignedToAgents}</Trans>
                    </Text.Base>
                ) : null}

                {showCreditsProposition && (
                    <Text.Regular bold className={styles.consumptionCreditsProposition}>
                        {t(i18n)`${plural(choice.productConsumptionWeight, {
                            one: '# credit',
                            other: '# credits',
                        })}`}{' '}
                        to be used to boost the ad
                    </Text.Regular>
                )}
                <Text.Base className={styles.expirationDate}>
                    {t(i18n)`Package expires in ${useHumanizedPeriod(
                        // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type
                        new Date(choice.expiresAt) - Date.now(),
                    )}
                    `}
                </Text.Base>
            </Flex>
        </Flex>
    );
};

export default ChoiceBox;
