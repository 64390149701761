import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/fixedMobileWrapper.cssm';

type Props = {
    readonly children: React.ReactNode;
    readonly bottom?: boolean;
};

const FixedMobileWrapper = ({ children, bottom }: Props) => {
    return (
        <div className={classNames(styles.fixedOnMobile, { [styles.bottom]: bottom })}>
            {children}
        </div>
    );
};

export default FixedMobileWrapper;
