import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { StylelessButton } from 'strat/generic';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { selectIsMobile } from 'strat/platform/selectors';
import iconPlusSell from '@app/assets/icons/iconPlusSell_noinline.svg';
import { Text, Flex } from 'strat/components';

import iconSellBorder from 'horizontal/assets/icons/iconSellBorder_noinline.svg';
import iconSellBorderCompact from 'horizontal/assets/icons/iconSellCompactBorder_noinline.svg';
import { AppBundles } from 'horizontal/types';

import styles from './styles/sellButton.cssm';
import useOnSellButtonClick from './useOnSellButtonClick';

const SellButton = ({
    source = AppBundles.MAIN_BUNDLE_NAME,
    withBorder = true,
}: {
    source?: Values<typeof AppBundles>;
    withBorder?: boolean;
}) => {
    const i18n = useI18n();
    const onClick = useOnSellButtonClick(source);
    const isMobile = useSelector(selectIsMobile);

    return (
        <StylelessButton title={t(i18n)`Sell`} className={styles.container} onClick={onClick}>
            {withBorder && (
                <img
                    src={isMobile ? iconSellBorderCompact : iconSellBorder}
                    className={styles.borderIcon}
                    alt={'Button border'}
                />
            )}
            <Flex alignCenter justifyCenter className={styles.contentContainer}>
                <img src={iconPlusSell} className={styles.plusIcon} alt={'Plus icon'} />
                <Text.Regular bold uppercase>
                    <Trans>Sell</Trans>
                </Text.Regular>
            </Flex>
        </StylelessButton>
    );
};

export default SellButton;
