// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';
import type { FetchState } from 'strat/fetcher';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import { selectActiveUser } from 'strat/user/session';
import { ContextfulError } from 'strat/error/context';

import { UserRole } from 'horizontal/types';
import { StratAPI } from 'horizontal/api';

/**
 * User roles actions.
 */
const Actions = Object.freeze({
    SET_USER_ROLES: 'USER_ROLES/SET',
    SET_USER_ROLES_ERROR: 'USER_ROLES/SET_ERROR',
});

/**
 * An action that sets user roles.
 */
type SetUserRolesAction = {
    type: 'USER_ROLES/SET';
    userRoles: Array<Values<typeof UserRole>> | null | undefined;
};

/**
 * An action that resets user roles.
 */
type SetUserRolesErrorAction = {
    type: 'USER_ROLES/SET_ERROR';
    error: Error;
};

/**
 * Default user roles.
 */
const defaultState: FetchState = buildDefaultState();

/**
 * Fetcher factory for fetching user roles.
 */
const factory = new FetcherFactory(
    'userRoles',
    (
        {
            userExternalID,
        }: {
            userExternalID: string;
        },
        state: any,
    ) => {
        if (!userExternalID) {
            throw new ContextfulError('Missing user ID when requesting user roles.', {
                userExternalID,
            });
        }

        return new StratAPI(state.i18n.language).userRoles(userExternalID);
    },
);

const fetchUserRolesById = factory.creator();

/**
 * Action creator for fetching user roles.
 *
 * By using a wrapper function instead of returning factory.creator()
 * the caching is maintained for the authenticated user but a new refresh
 * is made if the authenticated user changes.
 */
// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const fetchUserRoles = (dispatch: Dispatch, getState: GetState) => {
    const profile = selectActiveUser(getState());
    const userExternalID = profile?.externalID;

    dispatch(fetchUserRolesById({ operation: 'fetch', userExternalID }));
};

const fetchUserRolesReducer = factory.reducer();

type Action = SetUserRolesAction | SetUserRolesErrorAction;

const userRolesReducer = (state: FetchState | null | undefined = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.SET_USER_ROLES:
            return {
                ...state,
                loaded: true,
                loading: false,
                // @ts-expect-error - TS2339 - Property 'userRoles' does not exist on type 'Action'.
                data: action.userRoles,
            };

        case Actions.SET_USER_ROLES_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                // @ts-expect-error - TS2339 - Property 'error' does not exist on type 'Action'.
                error: action.error || null,
            };

        default:
            return fetchUserRolesReducer(state, action);
    }
};

export { fetchUserRoles };

export default userRolesReducer;
