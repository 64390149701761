import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import styles from './styles/filterConfirmationButtons.cssm';

const defaultRenderCloseButton = (
    onCloseClick?: () => void,
    design?: string,
): React.ReactNode | null | undefined =>
    onCloseClick && (
        <button
            onClick={onCloseClick}
            className={classNames(styles.closeButton, design)}
            aria-label="Close"
        >
            <Trans>Close</Trans>
        </button>
    );

const defaultRenderResetButton = (
    onResetClick?: () => void,
    design?: string,
): React.ReactNode | null | undefined =>
    onResetClick && (
        <button
            onClick={onResetClick}
            className={classNames(styles.resetButton, design)}
            aria-label="Reset"
        >
            <Trans>Reset</Trans>
        </button>
    );

type Props = {
    /**
     * Current active design
     */
    design?: string;
    /**
     * Render custom close button
     */
    renderCloseButton?: (className: string, onClick?: () => void) => React.ReactNode;
    /**
     * Callback for when the close button is clicked.
     */
    onCloseClick?: () => void;
    /**
     * Render custom reset button
     */
    renderResetButton?: (className: string, onClick?: () => void) => React.ReactNode;
    /**
     * Callback for when the reset button is clicked.
     */
    onResetClick?: () => void;
};

const FilterConfirmationButtons = ({
    design,
    onCloseClick,
    onResetClick,
    renderCloseButton,
    renderResetButton,
}: Props) => {
    const resetButton = renderResetButton
        ? renderResetButton(classNames(styles.resetButton, design), onResetClick)
        : defaultRenderResetButton(onResetClick, design);

    const closeButton = renderCloseButton
        ? renderCloseButton(classNames(styles.closeButton, design), onCloseClick)
        : defaultRenderCloseButton(onCloseClick, design);

    if (!resetButton && !closeButton) {
        return null;
    }

    return (
        <div className={classNames(styles.controls, design)}>
            {resetButton}
            {closeButton}
        </div>
    );
};

export default FilterConfirmationButtons;
