import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';

import { Flex } from 'strat/components';
import PaymentPlanIcon from '@app/assets/icons/iconProjectPaymentPlan.svg';
import RightArrowIcon from '@app/assets/icons/iconRightArrowSquare.svg';
import AvailableUnitsIcon from '@app/assets/icons/iconProjectAvailableUnits.svg';
import MasterPlanIcon from '@app/assets/icons/iconProjectMasterPlan.svg';
import AmenitiesIcon from '@app/assets/icons/iconProjectAmenities.svg';
import AboutIcon from '@app/assets/icons/iconProjectAbout.svg';
import { SearchElementIdentifiers } from 'strat/search';
import { scrollToElement } from 'strat/navigation/scroll';
import type { ProjectData } from 'strat/project/types';
import { selectIsMobile } from 'strat/platform/selectors';
import { GTMSearchTrackingProps, withGTMSearchTracking } from 'strat/gtm';
import { selectBottomAreaGuide } from 'strat/areaGuide/selectors';

import useProjectMasterPlans from './useProjectMasterPlans';
import {
    availableUnitsSectionElementID,
    paymentPlanSectionElementID,
    amenitiesSectionElementID,
    masterPlanSectionElementID,
    areaGuideElementID,
} from './projectIdentifiers';
import { ProjectPageSections } from './types';
import styles from './styles/projectTopLinkTiles.cssm';

type LinkCardProps = GTMSearchTrackingProps & {
    elementID: string;
    children: React.ReactNode;
    isMobile: boolean;
    tileName: string;
};

const LinkCard = withGTMSearchTracking(
    ({ children, elementID, isMobile, trackProjectPageViewDetails, tileName }: LinkCardProps) => (
        <Flex
            aria-label="Project top link tile"
            alignCenter
            onClick={() => {
                const headerHeight =
                    document.getElementById(
                        isMobile
                            ? SearchElementIdentifiers.compactSearchPageHeaderId
                            : SearchElementIdentifiers.searchPageHeaderId,
                    )?.offsetHeight || 0;
                scrollToElement(
                    document.getElementById(elementID),
                    -(headerHeight + 30) + document.documentElement.scrollTop,
                );
                trackProjectPageViewDetails(tileName);
            }}
            className={styles.tile}
        >
            {children}
            <RightArrowIcon className={styles.rightArrowIcon} />
        </Flex>
    ),
);

const useProjectTopLinkTiles = (project: ProjectData): React.ReactNode[] => {
    const isMobile = useSelector(selectIsMobile);
    const masterPlans = useProjectMasterPlans(project);
    const areaGuide = useSelector(selectBottomAreaGuide);

    return React.useMemo(
        () =>
            [
                settings.projectPageSectionVisibility?.[ProjectPageSections.PAYMENT_PLANS] &&
                    !!project.paymentPlans &&
                    !!project.paymentPlans.length && (
                        <LinkCard
                            isMobile={isMobile}
                            elementID={paymentPlanSectionElementID}
                            key={paymentPlanSectionElementID}
                            tileName={ProjectPageSections.PAYMENT_PLANS}
                        >
                            <PaymentPlanIcon className={styles.cardIcon} />
                            <h2 className={styles.cardText}>
                                <Trans>Payment Plan</Trans>
                            </h2>
                        </LinkCard>
                    ),
                settings.projectPageSectionVisibility?.[ProjectPageSections.AVAILABLE_UNITS] &&
                    !!project.units &&
                    !!project.units.length && (
                        <LinkCard
                            isMobile={isMobile}
                            elementID={availableUnitsSectionElementID}
                            key={availableUnitsSectionElementID}
                            tileName={ProjectPageSections.AVAILABLE_UNITS}
                        >
                            <AvailableUnitsIcon className={styles.cardIcon} />
                            <h2 className={styles.cardText}>
                                <Trans>Unit Types</Trans>
                            </h2>
                        </LinkCard>
                    ),
                settings.projectPageSectionVisibility?.[ProjectPageSections.MASTER_PLAN] &&
                    !!masterPlans.length && (
                        <LinkCard
                            isMobile={isMobile}
                            elementID={masterPlanSectionElementID}
                            key={masterPlanSectionElementID}
                            tileName={ProjectPageSections.MASTER_PLAN}
                        >
                            <MasterPlanIcon className={styles.cardIcon} />
                            <h2 className={styles.cardText}>
                                <Trans>Master Plan</Trans>
                            </h2>
                        </LinkCard>
                    ),
                settings.projectPageSectionVisibility?.[ProjectPageSections.AMENITIES] &&
                    !!project.amenities &&
                    !!project.amenities.length && (
                        <LinkCard
                            isMobile={isMobile}
                            elementID={amenitiesSectionElementID}
                            key={amenitiesSectionElementID}
                            tileName={ProjectPageSections.AMENITIES}
                        >
                            <AmenitiesIcon className={styles.cardIcon} />
                            <h2 className={styles.cardText}>
                                <Trans>Amenities</Trans>
                            </h2>
                        </LinkCard>
                    ),
                areaGuide && (
                    <LinkCard
                        isMobile={isMobile}
                        elementID={areaGuideElementID}
                        key={areaGuideElementID}
                        tileName={ProjectPageSections.ABOUT}
                    >
                        <AboutIcon className={styles.cardIcon} />
                        <h2 className={styles.cardText}>
                            <Trans>About Project</Trans>
                        </h2>
                    </LinkCard>
                ),
            ].filter(Boolean),
        [project, areaGuide, masterPlans, isMobile],
    );
};

export default useProjectTopLinkTiles;
