import { Ad, CategoryFieldsFilters, SearchPageType } from 'horizontal/types';
import { TypeField } from 'horizontal/fields/commonPostingFields';
import { SearchParamsForPageRendering } from 'horizontal/routes/search/types';

const getRemarketingExtraFields = (ad: Ad): CategoryFieldsFilters => {
    const typeValue = ad.extraFields?.[TypeField.attribute];
    if (!typeValue) {
        return {};
    }
    return { [TypeField.attribute]: typeValue };
};

const getRemarketingParams = (ad: Ad): SearchParamsForPageRendering => {
    const adLocation = ad.location[ad.location.length - 1];
    const adCategory = ad.category[ad.category.length - 1];

    return {
        categoryExternalID: adCategory?.externalID,
        locationExternalID: adLocation?.externalID,
        extraFields: getRemarketingExtraFields(ad),
        pagetype: SearchPageType.REMARKETING,
        remarketingAdID: ad.externalID,
    };
};

export default getRemarketingParams;
