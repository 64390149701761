import * as React from 'react';
import { Formik } from 'formik';
import { Trans } from '@lingui/macro';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Button, Input, Text } from 'strat/components';
import { useActiveUserPhoneNumber } from 'strat/user/session';
import settings from '@app/branding/settings';

import {
    FieldAttribute,
    generateInitialValues,
    makeValidationSchema,
    MobileWalletPaymentFields,
    UserDetails,
} from './mobileWalletFields';
import styles from './styles/mobileWalletPaymentForm.cssm';

type FormProps = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly explanatoryNotes: React.ReactNode;
};

const Form = ({
    setFieldValue,
    values,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    explanatoryNotes,
}: FormProps) => {
    const i18n = useI18n();

    return (
        <div className={styles.formContainer}>
            {explanatoryNotes}
            <Text.Large block bold>
                <Trans>Payment details</Trans>
            </Text.Large>
            {MobileWalletPaymentFields.map((field) => (
                <Input
                    name={field.attribute}
                    title={field.title(i18n)}
                    type="text"
                    key={field.attribute}
                    unit={field.unit}
                    onChange={(value) => setFieldValue(field.attribute, value)}
                    onBlur={handleBlur}
                    value={values[field.attribute]}
                    errorMessage={touched[field.attribute] && errors[field.attribute]}
                    accepted={touched[field.attribute] && !errors[field.attribute]}
                />
            ))}
            <Button onClick={handleSubmit} stretch>
                <Trans>Pay now</Trans>
            </Button>
        </div>
    );
};

type Props = {
    readonly onSubmit: (userDetails: UserDetails) => void;
    readonly explanatoryNotes: React.ReactNode;
};

const MobileWalletPaymentForm = ({ onSubmit, explanatoryNotes }: Props) => {
    const i18n = useI18n();
    const userPhoneNumber = useActiveUserPhoneNumber();

    const validationSchema = React.useMemo(() => makeValidationSchema(i18n), [i18n]);

    const renderForm = (props: React.ComponentProps<typeof Form>) => (
        <Form {...props} explanatoryNotes={explanatoryNotes} />
    );

    const initialValues = React.useMemo(() => {
        const values = generateInitialValues();
        const countryPrefix = settings.defaultPhoneNumber.prefix;
        if (userPhoneNumber && userPhoneNumber.startsWith(countryPrefix)) {
            // JazzCash api receives only numbers without prefix and with a leading 0
            return {
                ...values,
                [FieldAttribute.PHONE_NUMBER]: `0${userPhoneNumber.replace(countryPrefix, '')}`,
            };
        }
        return values;
    }, [userPhoneNumber]);

    const handleSubmit = (userDetails: UserDetails) => {
        const phoneNumber = userDetails[FieldAttribute.PHONE_NUMBER];
        if (!phoneNumber.startsWith('0')) {
            userDetails[FieldAttribute.PHONE_NUMBER] = `0${phoneNumber}`;
        }
        // we prompt users for the whole CNIC but the backend needs only the last 6 digits
        onSubmit({
            ...userDetails,
            [FieldAttribute.CNIC]: userDetails[FieldAttribute.CNIC].slice(-6),
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            // @ts-expect-error - TS2322 - Type '(paymentDetails: PaymentDetails) => void' is not assignable to type '(values: object, formikActions: FormikActions<object>) => void'
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
};

export default MobileWalletPaymentForm;
