import React from 'react';

import HistoryRouter from './historyRouter';
import RouterContext from './routerContext';

const useRouter = (): HistoryRouter => {
    const context = React.useContext(RouterContext);
    if (!context || !context.router) {
        throw new Error('react-true-router: Your code is not wrapped in RouterProvider');
    }

    return context.router;
};

export default useRouter;
