import * as React from 'react';

import { PaymentSource } from '../types';
import type { PaymentOrder } from '../types';
import useTrackPaymentInitiated from '../hooks/useTrackPaymentInitiated';

import useNavigateToPaymobMWalletPayment from './useNavigateToPaymobMWalletPayment';

const usePaymobWalletPayment = (order?: PaymentOrder) => {
    const navigateToPaymobMWallet = useNavigateToPaymobMWalletPayment();
    const trackPaymentInitiated = useTrackPaymentInitiated(order);

    return React.useCallback(
        (paymentSource: Values<typeof PaymentSource>) => {
            if (!order || !order.externalID) {
                return;
            }

            trackPaymentInitiated(PaymentSource.PAYMOB_WALLET);
            navigateToPaymobMWallet({
                orderExternalID: order.externalID,
                paymentSource: paymentSource,
            });
        },
        [order, navigateToPaymobMWallet, trackPaymentInitiated],
    );
};

export default usePaymobWalletPayment;
