import React from 'react';
import Category from '@app/branding/category';

import type { LiteCategory } from 'horizontal/types';
import jobsThumbnail from '@app/assets/icons/iconJobsThumbnail_noinline.svg';
import servicesThumbnail from '@app/assets/icons/iconServicesThumbnail_noinline.svg';
import NoImage from 'horizontal/image/noImage';

import styles from './styles/defaultThumbnailImage.cssm';

type Props = {
    readonly className?: string;
    readonly category?: Array<LiteCategory>;
};

const DefaultThumbnailImage = ({ category, className = styles.image }: Props) => {
    const isJob =
        category && category.some((parentCategory) => Category.isOfJobsType(parentCategory));
    if (isJob) {
        return <img src={jobsThumbnail} alt={'Jobs thumbnail image'} className={className} />;
    }

    const isService =
        category && category.some((parentCategory) => Category.isOfServicesType(parentCategory));
    if (isService) {
        return (
            <img src={servicesThumbnail} alt={'Services thumbnail image'} className={className} />
        );
    }

    return <NoImage className={className} />;
};

export default DefaultThumbnailImage;
