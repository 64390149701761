import React from 'react';

import PaymentBankTransfer from 'horizontal/payment/paymentBankTransfer';
import type { FullAd } from 'horizontal/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly ad: FullAd;
    readonly commission: string;
};

const PaymentBankTransferPage = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ ad, commission }: Props) => <PaymentBankTransfer ad={ad} commission={commission} />,
} as const;

export default PaymentBankTransferPage;
