import { SearchJob } from '@sector-labs/fe-search-redux';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import { MultiJobSearchHandler } from 'strat/routes';

import { CategoryConfigurations } from 'horizontal/types';

import { SearchPageSearchType } from '../types';

import { SearchJobName } from './types';
import remarketingAdSearchJob from './common/remarketingAdSearchJob';

type Props = {
    readonly indexName: string;
    readonly processedFilters: FilterCollection;
    readonly settings: SearchSettings;
    readonly searchPageType: Values<typeof SearchPageSearchType>;
    readonly remarketingAdID: string | undefined;
    readonly categoryConfigurations: CategoryConfigurations;
};

const adSearchJobs = ({
    indexName,
    processedFilters,
    settings,
    searchPageType,
    remarketingAdID,
}: Props): MultiJobSearchHandler => {
    const jobSearchHandler = new MultiJobSearchHandler();

    // In the case of SearchPageSearchType.EXPANDED_RADIUS_PRODUCT_GROUPED
    // location filter needs to be removed so that the circleFilters work properly
    const job = new SearchJob(
        indexName,
        searchPageType == SearchPageSearchType.EXPANDED_RADIUS_PRODUCT_GROUPED
            ? processedFilters.copy(['location.externalID'])
            : processedFilters,
        {
            timeoutSeconds: CONFIG.runtime.STRAT_ES_REQUESTS_TIMEOUT_SECONDS,
            ...settings,
        },
    );
    jobSearchHandler.addNamedJob(SearchJobName.REGULAR, job);

    if (remarketingAdID) {
        jobSearchHandler.addNamedJob(
            SearchJobName.REMARKETING,
            remarketingAdSearchJob(indexName, processedFilters, remarketingAdID),
        );
    }

    return jobSearchHandler;
};

export default adSearchJobs;
