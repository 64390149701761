import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Link } from 'react-true-router';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import styles from './styles/popularPropertySection.cssm';

type Props = {
    // @ts-expect-error - TS2749 - 'Link' refers to a value, but is being used as a type here. Did you mean 'typeof Link'?
    title?: Link;
    children: Array<React.ReactNode>;
    className: string;
    titleStyle: string;
    elementStyle: string;
    containerStyle: string;
    ignoreDefaultTextStyles: boolean;
};

const PopularPropertySection = (props: Props): React.ReactElement => {
    const isMobile = useSelector(selectIsMobileLayout);
    const {
        title,
        children,
        className,
        titleStyle,
        elementStyle,
        containerStyle,
        ignoreDefaultTextStyles,
    } = props;

    /*
     * A race condition on css properties makes some font properties to overlap.
     * By allowing the default text style to be ignored, the correct properties will be selected
     */

    const computedTitleStyle = classNames(
        !ignoreDefaultTextStyles ? styles.title : null,
        titleStyle,
    );

    const computedElementStyle = classNames(
        !ignoreDefaultTextStyles ? styles.elementText : null,
        styles.element,
        elementStyle,
    );

    const shouldDisplayTitle = title && (title.length > 0 || (title.key && title.key.length > 0));

    return (
        <div
            className={classNames(
                containerStyle,
                styles.container,
                isMobile
                    ? classNames(styles.mobile, !ignoreDefaultTextStyles ? styles.mobileText : null)
                    : null,
            )}
        >
            {shouldDisplayTitle && <div className={computedTitleStyle}>{title}</div>}
            <ul className={classNames(styles.list, className)}>
                {React.Children.map(children, (link) => (
                    <li className={computedElementStyle}>{link}</li>
                ))}
            </ul>
        </div>
    );
};

export default PopularPropertySection;
