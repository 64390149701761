import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import useCustomExtraFieldsMetadata from '@app/search/seo/useCustomExtraFieldsMetadata';

import type { Category, Location, CategoryFieldsFilters } from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';
import { selectCustomH1 } from 'horizontal/urlSpecificSEO/selectors';

import useTerm from './useTerm';
import usePurposeAdjective from './usePurposeAdjective';
import useLocationName from './useLocationName';

type Props = {
    readonly category?: Category | null | undefined;
    readonly location?: Location | null | undefined;
    readonly locationRadius?: number | null | undefined;
    readonly freeTextQuery?: string | null | undefined;
    readonly extraFields?: CategoryFieldsFilters;
    readonly page?: number;
};

const useDefaultHeadingTitle = ({
    category,
    location,
    locationRadius,
    freeTextQuery,
    extraFields,
    page,
}: Props): string => {
    const i18n = useI18n();
    const term = useTerm(
        { category, freeTextQuery, extraFields },
        { useShortNames: !category?.roles?.includes(CategoryRole.USE_LONG_NAME_IN_HEADING) },
    );
    const purposeAdjective = usePurposeAdjective(category, CategoryRole.INCLUDE_PURPOSE_IN_HEADING);
    const locationName = useLocationName(location);
    const customHeading = useSelector(selectCustomH1);

    const { h1: customExtraFieldsH1 } = useCustomExtraFieldsMetadata({
        category,
        location,
        page,
    });
    if (customHeading) {
        return customHeading;
    }

    if (!freeTextQuery && customExtraFieldsH1) {
        return customExtraFieldsH1;
    }

    // It should be 'Cars', not 'Cars in Pakistan',
    // but if we have a free text query, it should
    // be '[text] in Pakistan'
    //
    // I don't know why, but we're just copying
    // the original OLX platform here.
    if (term && !freeTextQuery && !location) {
        return term;
    }

    if (term) {
        const termWithPurpose =
            purposeAdjective && !freeTextQuery ? `${term} ${purposeAdjective}` : term;
        if (locationRadius) {
            return t(i18n)`${termWithPurpose} in ${locationName} and within ${locationRadius} km`;
        }
        return t(i18n)`${termWithPurpose} in ${locationName}`;
    }

    return t(i18n)`${locationName} Free classifieds`;
};

export default useDefaultHeadingTitle;
