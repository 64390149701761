import { useSelector } from 'react-redux';

import { GlobalState } from 'horizontal/state';

const useSellerProfile = () =>
    useSelector((state: GlobalState) => state.sellerProfile.data || null);

const useSellerIsLoading = () => useSelector((state: GlobalState) => state.sellerProfile.loading);

export { useSellerProfile, useSellerIsLoading };
