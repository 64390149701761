import * as React from 'react';
import { CountBasedExpandable } from 'strat/modal';

import CollapsibleTrigger from './collapsibleTrigger';

const renderTrigger = (expanded: boolean, setExpanded: (arg1: boolean) => void) => (
    <CollapsibleTrigger expanded={expanded} setExpanded={setExpanded} />
);

type Props = {
    readonly visibleCount?: number;
    readonly children: React.ReactNode;
    readonly className?: string;
    readonly ariaLabel?: string;
};

const Expandable = ({ visibleCount = 5, children, className, ariaLabel }: Props) => (
    <CountBasedExpandable
        visibleCount={visibleCount}
        renderTrigger={renderTrigger}
        className={className}
        ariaLabel={ariaLabel}
    >
        {children}
    </CountBasedExpandable>
);

export default Expandable;
