import * as React from 'react';

import { SearchPageSearchType } from '../types';

import CircleSlotSectionHits from './withCircleSlottingType/sectionHits';
import DefaultSectionHits from './common/sectionHits';
import { useSearchPageType } from './determineSearchPageType';

type Props = {
    readonly loading?: boolean;
};

const SectionHits = (props: Props) => {
    const searchPageType = useSearchPageType();

    switch (searchPageType) {
        case SearchPageSearchType.CIRCLE_BASED_SLOTTING:
        case SearchPageSearchType.PAID_FIRST_SLOTTED:
        case SearchPageSearchType.PAID_FIRST:
            return <CircleSlotSectionHits {...props} />;
        default:
            return <DefaultSectionHits {...props} />;
    }
};

export default SectionHits;
