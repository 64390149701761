import * as React from 'react';
import { Flex } from 'strat/components';

import IconShare from 'horizontal/assets/icons/iconShare.svg';

import styles from './styles/shareButton.cssm';

const ShareButton = () => (
    <Flex className={styles.container} aria-label="Share button">
        <IconShare className={styles.icon} />
    </Flex>
);

export default ShareButton;
