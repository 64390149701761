//@ts-nocheck
import settings from '@app/branding/settings';
/**
 * Return the attributeName with all possible localized versions depending on the available languages
 * ex. 'slug' -> 'slug', 'slug_l1', 'slug_l2' etc
 * @param attributeName
 * @returns {*[]}
 */
const getLocalizedAttributeNames = (attributeName: string) => [
    attributeName,
    ...[...Array(settings.languages.length - 1).keys()].map(
        (index) => `${attributeName}_l${index + 1}`,
    ),
];

export default getLocalizedAttributeNames;
