import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { Ad } from 'horizontal/types';
import { useExternalWindowNavigation } from 'horizontal/util';

const useNavigateToAdDetailsFromPortal = (ad?: Ad | null) => {
    const router = useRouter();
    const externalWindowNavigation = useExternalWindowNavigation();

    return React.useCallback(() => {
        if (!ad) {
            return;
        }
        externalWindowNavigation(RouteNames.AD_DETAILS, {
            slug: ad.slug,
            externalID: ad.externalID,
        });
        router.pushRoute(RouteNames.AGENCY_PORTAL_ADS);
    }, [router, ad, externalWindowNavigation]);
};

export default useNavigateToAdDetailsFromPortal;
