import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useRefreshSession, useLinkIdentityProvider } from '@sector-labs/fe-auth-redux/thunk';
import {
    useKeycloakSiteConfigWithCallbacks,
    determineKeycloakThirdPartyProviderID,
} from 'strat/auth/keycloak';
import { useDialog } from 'strat/dialogs/dialogContext';
import { isCanceled } from 'strat/util';
import { useNavigateToHome } from 'strat/home/routing';
import { Flex, Text, LoadingSpinnerOverlay } from 'strat/components';
import { useActiveUser, useActiveUserIdentityProviders } from 'strat/user/session';
import { ThirdPartyProvider } from 'strat/auth/keycloak/types';

import { DialogNames } from 'horizontal/dialogs';
import { useMakeCancelable } from 'horizontal/util';

import useAvailableSocialLoginProviders from './useAvailableSocialLoginProviders';
import SocialLoginItem from './socialLoginItem';
import useEditProfileAbsoluteURL from './useEditProfileAbsoluteURL';

const SocialLogin = () => {
    const [loading, setLoading] = React.useState(true);
    const [linkProviderLoading, setLinkProviderLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [, setUnlinkDialogVisible] = useDialog(DialogNames.UNLINK_SOCIAL_LOGIN_DIALOG);
    const identityProviders = useActiveUserIdentityProviders();
    const availableProviders = useAvailableSocialLoginProviders();
    const refreshSession = useRefreshSession();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const editProfileURL = useEditProfileAbsoluteURL();
    const linkIdentityProvider = useLinkIdentityProvider();
    const makeCancelable = useMakeCancelable();
    const navigateToHome = useNavigateToHome();
    const activeUser = useActiveUser();

    React.useEffect(() => {
        if (!activeUser) {
            navigateToHome();
        }
    }, [activeUser, navigateToHome]);

    React.useEffect(() => {
        refreshSession(keycloakSiteConfig).then(() => setLoading(false));
    }, [refreshSession, keycloakSiteConfig]);

    const isConnected = React.useCallback(
        (socialLoginType: ThirdPartyProvider) =>
            !!(Array.isArray(identityProviders) ? identityProviders : [identityProviders]).find(
                (sl) => sl === determineKeycloakThirdPartyProviderID(socialLoginType),
            ),
        [identityProviders],
    );

    const onConnect = (provider: ThirdPartyProvider) => {
        setLinkProviderLoading(true);
        setError(false);
        makeCancelable(
            linkIdentityProvider(keycloakSiteConfig, {
                provider: determineKeycloakThirdPartyProviderID(provider),
                redirectURI: editProfileURL,
            }),
        ).then(
            // @ts-expect-error - TS7006 - Parameter 'linkURL' implicitly has an 'any' type.
            (linkURL) => {
                if (!linkURL) {
                    setError(true);
                    setLinkProviderLoading(false);
                    return;
                }
                window.location.href = linkURL;
            },
            // @ts-expect-error - TS7006 - Parameter 'err' implicitly has an 'any' type.
            (err) => {
                if (isCanceled(err)) {
                    return;
                }
                setError(true);
            },
        );
    };

    return (
        <Flex column>
            <LoadingSpinnerOverlay visible={linkProviderLoading} />
            {availableProviders.map((socialLoginProvider) => (
                <SocialLoginItem
                    key={socialLoginProvider.type}
                    provider={socialLoginProvider}
                    connected={isConnected(socialLoginProvider.type)}
                    loading={loading}
                    onDisconnect={(provider) => {
                        setError(false);
                        setUnlinkDialogVisible(true, { provider });
                    }}
                    onConnect={onConnect}
                />
            ))}
            {error && (
                <Text.Regular error>
                    <Trans>Something went wrong!</Trans>
                </Text.Regular>
            )}
        </Flex>
    );
};

export default React.memo<Record<any, any>>(SocialLogin);
