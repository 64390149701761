import { t } from '@lingui/macro';
import * as React from 'react';

import { useI18n } from 'strat/i18n/language';

import { CollapsibleContent } from './collapsibleContent';

/**
 * Properties for {@see CollapsibleText}.
 */
type Props = {
    height: number;
    label?: string;
    children: React.ReactNode;
    className?: string;
    buttonClassName?: string;
    scrollToElementTop: (node: HTMLDivElement) => void;
    arrowClassName?: string;
    containerClassName?: string;
    expanded: boolean;
    setExpanded: (val: boolean) => void;
};

const CollapsibleText = (props: Props): React.ReactElement => {
    const i18n = useI18n();
    const { className, scrollToElementTop, buttonClassName, arrowClassName, containerClassName } =
        props;

    return (
        <CollapsibleContent
            className={containerClassName}
            height={props.height}
            textMore={t(i18n)`Read More`}
            textLess={t(i18n)`Read Less`}
            scrollToElementTop={scrollToElementTop}
            buttonClassName={buttonClassName}
            arrowClassName={arrowClassName}
            expanded={props.expanded}
            setExpanded={props.setExpanded}
        >
            <div className={className} aria-label={props.label}>
                {props.children}
            </div>
        </CollapsibleContent>
    );
};

type UncontrolledCollapsibleTextProps = Omit<Props, 'setExpanded' | 'expanded'>;

const UncontrolledCollapsibleText = (
    props: UncontrolledCollapsibleTextProps,
): React.ReactElement => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <CollapsibleText {...props} expanded={expanded} setExpanded={setExpanded}>
            {props.children}
        </CollapsibleText>
    );
};
export { CollapsibleText };
export default UncontrolledCollapsibleText;
