import React from 'react';
import { isCanceled } from 'strat/util';

import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';

import type { CheckoutCreditCard } from '../types';

const useCheckoutDeleteCard = (instrument: CheckoutCreditCard, onSuccess?: () => void) => {
    const makeCancelable = useMakeCancelable();
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => setIsLoading(false), []);

    const deleteCard = React.useCallback(() => {
        if (!instrument) {
            return;
        }
        setIsLoading(true);
        const cancelablePromise = makeCancelable(
            new StratAPI().deleteCustomerInstrument(instrument.id),
        );

        cancelablePromise
            .then((response: any) => {
                setIsLoading(false);
                if (response.status === 204) {
                    onSuccess?.();
                    return;
                }
            })
            .catch((error: any) => {
                if (isCanceled(error)) {
                    return;
                }
                setIsLoading(false);
            });
    }, [instrument, makeCancelable, onSuccess]);

    return {
        deleteCard,
        isLoading,
    };
};

export default useCheckoutDeleteCard;
