import type { SessionData as KeycloakSessionData } from '@sector-labs/fe-auth-redux/state';

import { type UserProfileData } from './types';

const createUserProfileFromKeycloakSessionData = (
    sessionData: KeycloakSessionData,
): UserProfileData => {
    const {
        external_id: externalID,
        name,
        email,
        phone_number: phoneNumber,
        additional_phone_number: additionalPhoneNumbers,
        mobile_contact_phone_numbers: mobileContactPhoneNumbers,
        whatsapp_contact_phone_numbers: whatsappContactPhoneNumbers,
        realm_roles: realmRoles,
    } = sessionData.userProfile;

    const allPhoneNumbers = [
        ...(phoneNumber ? [phoneNumber] : []),
        ...(Array.isArray(additionalPhoneNumbers) ? additionalPhoneNumbers : []),
        ...(Array.isArray(mobileContactPhoneNumbers) ? mobileContactPhoneNumbers : []),
        ...(Array.isArray(whatsappContactPhoneNumbers) ? whatsappContactPhoneNumbers : []),
    ];

    return {
        id: externalID,
        name,
        email: email || null,
        phoneNumber: allPhoneNumbers[0] || null,
        roles: realmRoles,
    };
};

export default createUserProfileFromKeycloakSessionData;
