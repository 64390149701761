import { useSelector } from 'react-redux';

import type { MixedAd, AdPublicContactInfo, AdAgent, AdContactInfo } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

import {
    selectAd,
    selectAdExternalID,
    selectAdContactInfoRoles,
    selectAdPublicContactInfo,
    selectAdAgents,
} from './selectors';

const useAd = (): MixedAd | null | undefined => useSelector(selectAd);

const useAdIsLoading = () => useSelector((state: GlobalState) => state.ad.loading);

const useAdIsLoaded = () => useSelector((state: GlobalState) => state.ad.loaded);

const useAdExternalID = (): string | null | undefined => useSelector(selectAdExternalID);

const useAdContactInfoRoles = () => useSelector(selectAdContactInfoRoles);

const useAdPublicContactInfo = (): AdContactInfo | AdPublicContactInfo =>
    // @ts-expect-error - TS2322 - Type 'AdPublicContactInfo | null' is not assignable to type 'AdPublicContactInfo | AdContactInfo'.
    useSelector(selectAdPublicContactInfo);

// @ts-expect-error - TS2322 - Type 'AdAgent[] | null' is not assignable to type 'AdAgent[]'.
const useAdAgents = (): Array<AdAgent> => useSelector(selectAdAgents);

export {
    useAd,
    useAdIsLoaded,
    useAdIsLoading,
    useAdExternalID,
    useAdContactInfoRoles,
    useAdPublicContactInfo,
    useAdAgents,
};
