import * as React from 'react';

import useCategoriesWithHits from './useCategoriesWithHits';
import CategoryWithHitsSection from './categoryWithHitsSection';

const CategoriesWithHitsSection = () => {
    const categoriesWithHits = useCategoriesWithHits();
    return (
        <>
            {categoriesWithHits.map(({ category, hits }) => (
                <CategoryWithHitsSection key={category.slug} category={category} ads={hits} />
            ))}
        </>
    );
};

export default CategoriesWithHitsSection;
