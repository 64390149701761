//@ts-nocheck
import { getFilterCollection, selectSearchLoadingState } from '@sector-labs/fe-search-redux/state';
import isNil from 'lodash/isNil';
import { GlobalState } from '@app/state';

import { FilterValues } from 'strat/search';
import { selectIsMapBasedSearchActive, selectSearchResponse } from 'strat/search/selectors';
import EMPTY_OBJECT from 'strat/empty/object';
import EMPTY_ARRAY from 'strat/empty/array';
import { selectShouldRenderChildLocations } from 'strat/search/internalLinks/childLocations/selectors';

/**
 * Selects the total amount of hits from a search query.
 * If the count is present in the internal links from the backend,
 * that number of hits is used, else the one from Algolia is used.
 */
const numberOfHits = (state: GlobalState) => {
    if (selectIsMapBasedSearchActive(state)) {
        return (state.map.activeCluster || EMPTY_ARRAY).length;
    }

    const shouldUseInternalLinksCounts = selectShouldRenderChildLocations(state);
    if (shouldUseInternalLinksCounts) {
        const internalLinksAdCount = (state.internalLinks?.data || EMPTY_OBJECT).adcount;
        if (!isNil(internalLinksAdCount)) {
            return internalLinksAdCount;
        }
    }

    return (selectSearchResponse(state) || EMPTY_OBJECT).nbHits || 0;
};

const isNumberOfHitsLoading = (state: GlobalState) => {
    if (selectIsMapBasedSearchActive(state)) {
        return state.map.activeClusterLoading;
    }

    const shouldUseInternalLinksCounts = selectShouldRenderChildLocations(state);
    if (shouldUseInternalLinksCounts) {
        if (state.internalLinks?.loading) {
            return true;
        }
        if (!isNil(state.internalLinks?.data?.adcount)) {
            return false;
        }
    }

    return selectSearchLoadingState(state).loading;
};

const isBedroomPage = (state: GlobalState) => {
    const filters = getFilterCollection(state);
    const rooms = filters.getFilterValue(FilterValues.beds.attribute, EMPTY_ARRAY);
    return rooms.length === 1;
};

const recommendationCountFor404Pages = 5;
const recommendationCountForSearchPages = 15;

export {
    numberOfHits,
    isNumberOfHitsLoading,
    isBedroomPage,
    recommendationCountFor404Pages,
    recommendationCountForSearchPages,
};
