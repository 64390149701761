import * as React from 'react';

import { FavoriteButton as FavoriteButtonBase } from 'horizontal/favorites';

type Props = React.ComponentProps<typeof FavoriteButtonBase>;

const FavoriteButton = (props: Props) => {
    return <FavoriteButtonBase {...props} />;
};

export default FavoriteButton;
