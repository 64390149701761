import * as React from 'react';
import { GTMDataLayerVariables, Triggers } from 'strat/gtm';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';
import { useActiveUser } from 'strat/user/session';

import { useAd, useAdIsLoading } from 'horizontal/ad/state';
import { AdFormat } from 'horizontal/types';
import { usePageViewTrigger } from 'horizontal/gtm';
import useAdFeatures from 'horizontal/adDetails/useAdFeatures';

import useGlobalGTMVars from '../useGlobalGTMVars';
import { getAdVars } from '../utils';
import useTrackRelatedAds from '../useTrackRelatedAds';

import PageType from './pageType';

type Props = {
    ovation: OvationCallbackProps;
};

const GTMAdDetailDataLayer = (props: Props) => {
    const globalVars = useGlobalGTMVars();

    const ad = useAd();
    const adIsLoading = useAdIsLoading();
    const { externalID: userExternalID } = useActiveUser() || {};

    const featuresFormattedFields = useAdFeatures(ad);
    const featuresFormattedValues = featuresFormattedFields.map((formattedField) => {
        if (formattedField.attribute === 'features') {
            return formattedField.formattedValue;
        }
        return [];
    });

    const isFreehold = featuresFormattedValues.some((featureList) =>
        (featureList as string[]).includes('Freehold'),
    );

    const variables = {
        ...globalVars,
        ...getAdVars(ad),
        number_of_photos: ad && ad.format === AdFormat.FULL ? ad?.photos?.length : 0,
        page_type: PageType.AD_DETAIL,
        ownership_type: isFreehold ? 'freehold' : 'non-freehold',
    } as const;

    usePageViewTrigger(Triggers.AD_DETAIL_VIEW, variables, !ad || adIsLoading);
    useTrackRelatedAds(props.ovation);

    React.useEffect(() => {
        if (!ad || adIsLoading) {
            return;
        }
        if (userExternalID !== ad.userExternalID) {
            // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
            props.ovation.ingestAdView(ad);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ad, adIsLoading, userExternalID]);

    return <GTMDataLayerVariables variables={variables} />;
};

export default withOvation(GTMAdDetailDataLayer);
