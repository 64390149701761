import * as React from 'react';

type Props = {
    readonly callback: any;
    readonly milliseconds?: number;
};

const useDebounce = ({ callback, milliseconds = 500 }: Props) => {
    const timeout = React.useRef();

    const handleDebounceCallback = React.useCallback(
        (param: any) => {
            clearTimeout(timeout.current);

            // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'undefined'.
            timeout.current = setTimeout(() => {
                callback(param);
            }, milliseconds);
        },
        [callback, milliseconds],
    );

    React.useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);

    return handleDebounceCallback;
};

export default useDebounce;
