import * as React from 'react';

/**
 * Mechanisms for hiding the drop down contents.
 */
export const HidingMethod = Object.freeze({
    DISPLAY_NONE: 'displayNone',
    NO_RENDER: 'noRender',
});

type Props = {
    readonly children: React.ReactNode;
    readonly display: boolean;
    readonly hidingMethod?: Values<typeof HidingMethod>;
};

const HidingWrapper = ({
    children,
    display,
    hidingMethod = HidingMethod.NO_RENDER,
}: Props): JSX.Element | null => {
    if (hidingMethod === HidingMethod.DISPLAY_NONE) {
        return <div style={{ display: display ? 'block' : 'none' }}>{children}</div>;
    }

    // @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'Element | null'.
    return display ? children : null;
};

export default HidingWrapper;
