import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import { ImageInputProps } from './types';
import ImageCollectionInput from './imageCollectionInput';
import ImageCollectionInputCompact from './compact/imageCollectionInput';

const MAX_IMAGES_COUNT = 20;
const INITIAL_IMAGES_COUNT_DESKTOP = 13;

const ImageInput = (props: ImageInputProps) => {
    const isMobile = useSelector(selectIsMobileLayout);

    if (isMobile) {
        return (
            <ImageCollectionInputCompact
                {...props}
                maxImagesCount={MAX_IMAGES_COUNT}
                initialImagesCount={MAX_IMAGES_COUNT}
            />
        );
    }

    return (
        <ImageCollectionInput
            {...props}
            maxImagesCount={MAX_IMAGES_COUNT}
            initialImagesCount={INITIAL_IMAGES_COUNT_DESKTOP}
        />
    );
};

export default ImageInput;
