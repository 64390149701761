import * as React from 'react';
import classNames from 'classnames';

import { ButtonVariant } from 'strat/components';

import styles from './styles/button.cssm';

/**
 * Props for {@see ListingButtonCompact}.
 */
type Props = {
    className?: string;
    renderIcon?: () => React.ReactElement;
    children?: React.ReactNode;
    onClick?: (event: React.SyntheticEvent<any>) => void;
    // Toggles the old button design, which is still needed for Zameen
    sharp?: boolean;
    variant?: Values<typeof ButtonVariant>;
};

/**
 * Renders a button to be embedded in a listing.
 */
const Button = ({ variant, sharp, renderIcon, className, ...props }: Props) => {
    const buttonClassName = classNames(styles.button, className, {
        [styles.light]: variant === ButtonVariant.LIGHT,
        [styles.roundedButton]: !sharp,
    });

    return (
        <button className={buttonClassName} {...props}>
            {renderIcon?.()}
            {props.children}
        </button>
    );
};

export default Button;
