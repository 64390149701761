import * as React from 'react';
import { withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';

import { ChatMessageType, ChatRoomMemberRole } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatAd, ChatRoom } from 'horizontal/chat/types';
import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';

import type { SuggestedMessage } from './intelliChat';
import { useSuggestedMessages } from './intelliChat';
import styles from './styles/suggestedMessages.cssm';

type OwnProps = {
    readonly conversation: ChatRoom;
    readonly ad: ChatAd | null | undefined; // required for lead tracking
};
type Props = GTMLeadTrackingProps & OwnProps;

const SuggestedMessages = ({ conversation, trackChatLead }: Props) => {
    const sdk = useChatSDK();
    const sendTextMessage = sdk.useActiveRoomSendTextMessage();
    const isSeller = conversation.myRole !== ChatRoomMemberRole.BUYER;
    const suggestedMessages: Array<SuggestedMessage> = useSuggestedMessages(isSeller);
    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);

    const onMessageClick = React.useCallback(
        (message) => {
            sendTextMessage({ message }).then((messageSentResponse) => {
                if (messageSentResponse) {
                    trackSentChatMessageEvents(messageSentResponse, ChatMessageType.TEXT);
                }
            });
        },
        [sendTextMessage, trackSentChatMessageEvents],
    );

    const messageComponents = React.useMemo(
        () =>
            suggestedMessages.map(({ id, message, displayText }) => (
                <button
                    className={styles.suggestedMessage}
                    onClick={() => onMessageClick(message)}
                    key={id}
                >
                    {displayText}
                </button>
            )),
        [suggestedMessages, onMessageClick],
    );
    return <div className={styles.suggestedMessages}>{messageComponents}</div>;
};

export default withGTMLeadTracking<Props, ChatAd | null | undefined>(SuggestedMessages);
