import { useSelector } from 'react-redux';
import { useListingTemplate } from 'strat/listing';
import { TemplateType } from 'strat/templates';

import { selectCustomH1 } from 'horizontal/urlSpecificSEO/selectors';

import { useCurrentSearchRouteParams } from '../routing';

import useDefaultHeadingTitle from './useDefaultHeadingTitle';

const useHeadingTitle = () => {
    const params = useCurrentSearchRouteParams();
    const defaultTitle = useDefaultHeadingTitle(params);
    // Extra fields or the free text query cannot be used in templates yet
    // so they're pretty useless. We disable templating for pages with extra
    // field filters or a free text query so that the template doesn't override
    // when those filters are active. Remove this condition when templates
    // support extra fields and/or free text queries.
    const useDefaultTitle = Object.keys(params.extraFields || {}).length || !!params.freeTextQuery;
    const titleTemplate = useListingTemplate(TemplateType.H1);
    const useDefaultCustomTitle = !!useSelector(selectCustomH1);

    if (useDefaultCustomTitle || titleTemplate.isDefault || useDefaultTitle) {
        return defaultTitle;
    }
    return titleTemplate.text;
};

export default useHeadingTitle;
