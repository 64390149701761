import * as React from 'react';
import { Dialog } from 'strat/modal';
import { Flex, Text, Button } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconClose.svg';

import type { AddressInfo } from '../types';

import styles from './styles/actionConfirmDialog.cssm';

type Props = {
    readonly dialogDetails?: {
        [key: string]: string;
    };
    resetStatus: () => void;
    setConfirmDialogStatus: (flag: boolean) => void;
    proceedWithAction: (selectedAddress: AddressInfo, action: string) => void;
    selectedAddress: AddressInfo;
    currentAction: string;
};

function AddressConfirmationDialog({
    dialogDetails,
    resetStatus,
    setConfirmDialogStatus,
    proceedWithAction,
    selectedAddress,
    currentAction,
}: Props) {
    return (
        <Dialog dismissible visible onVisibilityChanged={resetStatus}>
            <Flex column alignCenter className={styles.confirmDialog}>
                <Flex justifySpaceBetween className={styles.confirmDialogTitle}>
                    <Text.XLarge>{dialogDetails?.title}</Text.XLarge>
                    <IconClose onClick={resetStatus} />
                </Flex>
                <Text.Base className={styles.confirmDialogDescription}>
                    {dialogDetails?.description}
                </Text.Base>
                <Flex alignCenter>
                    <div className={styles.cancelButtonContainer}>
                        <Button
                            small
                            secondary
                            onClick={() => {
                                resetStatus();
                            }}
                        >
                            {dialogDetails?.cancel}
                        </Button>
                    </div>
                    <Button
                        small
                        onClick={() => {
                            proceedWithAction(selectedAddress, currentAction);
                            setConfirmDialogStatus(false);
                        }}
                    >
                        {dialogDetails?.confirm}
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
}

export default AddressConfirmationDialog;
