import React, { Component } from 'react';
import type { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import autoBind from 'react-autobind';

import { computeAbsoluteTopOffset } from 'strat/util';

import withScrollNav from './withScrollNav';
import ScrollNavSection, { ScrollNavSectionName } from './scrollNavSection';

/**
 * Properties for {@see ScrollNav}.
 */
type Props = {
    /**
     * Amount of pixels to add/subtrat when scrolling. If the
     * the destination element is at 100px, and the compensation
     * is 50px, then you'd scroll to 150px.
     */
    compensation: number;
    /**
     * Amount of pixels to add/subtract for the top
     * element in addition to compensation prop when needed.
     */
    topCompensationDelta: number;
    /**
     * Navigation sections that you can scroll to.
     */
    children: ScrollNavSection[] | React.ReactNode[];
    /**
     * Navigation sections defined in the Redux store
     */
    sections: any;
    /**
     * Method that adds a set of navigation sections to the Redux store
     */
    setSections: (arg1: any) => void;
    /**
     * Method that scrolls to a navigation section given by its title
     */
    scrollToSection: (arg1: string) => void;

    renderStickySection?: (args: React.ReactNode) => React.ReactNode;
    renderPageContent?: (sections: ScrollNavSection[] | JSX.Element[]) => React.ReactNode;

    setNavBarHeight?: (height: number) => void;
    scrollDuration?: number;
};

/**
 * State for {@see ScrollNav}.
 */
type State = {
    index: number;
    isMounted: boolean;
};

/**
 * Properties passed by {@see connectScrollNav}.
 */
export type ScrollNavProps = {
    compensation: number;
    children: ScrollNavSection[] | React.ReactNode[];
    sections: Array<{
        title: string;
        shortTitle?: string;
        icon?: any;
    }>;
    index: number;
    scrollToSection: (sectionTitle: string) => void;
};

/**
 * {@see ScrollNav} helps implementing a form of navigation
 * where each section is at a different offset on the page.
 *
 * In order to switch between sections, you scroll to them.
 * The {@see ScrollNav} tells you what the currently active
 * section is and allows you to easily scroll to the right section.
 */
export default (component: ComponentType<any>) => {
    class ScrollNav extends Component<Props, State> {
        /**
         * Initializes a new instance of {@see ScrollNav}.
         */
        constructor(props: Props) {
            super(props);
            autoBind(this);

            this.state = {
                index: 0,
                isMounted: false,
            };
        }

        /**
         * Attaches the scroll event listener.
         */
        componentDidMount(): void {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ isMounted: true });
            this.props.setSections([]);
            window.addEventListener('scroll', this.onScroll, { passive: true });
        }

        /**
         * Detaches the scroll event listener.
         */
        componentWillUnmount(): void {
            // @ts-expect-error - TS2769 - No overload matches this call.
            window.removeEventListener('scroll', this.onScroll, { passive: true });
        }

        /**
         * Determines the currently active section based on
         * the scroll position.
         */
        onScroll(): void {
            const { sections, compensation } = this.props;

            const currentTitle = Object.keys(sections).find((title) => {
                const section = sections[title];
                if (!section) {
                    return false;
                }
                // eslint-disable-next-line react/no-find-dom-node
                const node = ReactDOM.findDOMNode(section.node) as HTMLElement | null | undefined;
                if (!node) {
                    return false;
                }
                const offsetTop = computeAbsoluteTopOffset(node) - compensation + node.clientHeight;
                return offsetTop > window.scrollY;
            });

            // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
            const index = sections[currentTitle]
                ? // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
                  sections[currentTitle].index
                : Object.keys(sections).length - 1;

            if (index !== this.state.index) {
                this.setState({ index });
            }
        }

        /**
         * Gets all children as an array.
         */
        children() {
            return React.Children.toArray(this.props.children);
        }

        isScrollNavSection(element: any) {
            return element.type.displayName === ScrollNavSectionName;
        }

        scrollNavSectionChildren() {
            return this.children().filter((child: any) => this.isScrollNavSection(child));
        }

        /**
         * Collects references and information about child elements
         * and stores them in a global state so that any other component
         * can scroll to these child elements.
         */
        collectRefs(): Array<Node> {
            const { sections, compensation, topCompensationDelta, scrollDuration, setSections } =
                this.props;
            const newSections: Array<
                | any
                | {
                      compensation: number;
                      index: number;
                      node: never;
                      topCompensationDelta: number;
                      scrollDuration?: boolean;
                  }
            > = [];
            let index = 0;
            const childrenClone = this.children().map((child: any) =>
                this.isScrollNavSection(child)
                    ? React.cloneElement(child, {
                          ...child.props,
                          // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
                          ref: (element) => {
                              if (element) {
                                  newSections[child.props.title] = {
                                      node: element,
                                      index,
                                      compensation,
                                      topCompensationDelta,
                                      scrollDuration,
                                  };
                                  index += 1;
                              }
                          },
                      })
                    : React.cloneElement(child, {
                          ...child.props,
                      }),
            );

            // check if there's any difference between the children components and components already in the store
            const storeMismatch =
                !sections ||
                this.scrollNavSectionChildren().length !== Object.keys(sections).length ||
                // @ts-expect-error - TS2339 - Property 'props' does not exist on type 'ReactChild | ReactFragment | ReactPortal'.
                this.scrollNavSectionChildren().some((child) => !sections[child.props.title]);

            // if there's any mismatch, update the store
            if (this.state.isMounted && storeMismatch) {
                setSections(newSections);
            }
            // @ts-expect-error - TS2322 - Type 'DetailedReactHTMLElement<any, HTMLElement>[]' is not assignable to type 'Node[]'.
            return childrenClone;
        }

        /**
         * Collects the names/titles of the sections.
         */
        collectSections(): Array<{
            title: string;
            icon?: any;
        }> {
            return this.scrollNavSectionChildren().map((child: any) => ({
                title: child.props.title,
                shortTitle: child.props.shortTitle ? child.props.shortTitle : child.props.title,
                icon: child.props.icon,
            }));
        }

        /**
         * Renders the wrapped component and passes
         * available sections and the currently active
         * section to the passed component.
         */
        render() {
            return React.createElement(
                component,
                {
                    ...this.props,
                    sections: this.collectSections(),
                    scrollToSection: this.props.scrollToSection,
                    index: this.state.index,
                },
                this.collectRefs(),
            );
        }
    }

    return withScrollNav(ScrollNav);
};
