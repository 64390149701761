import type { AdPhotoDTO } from 'horizontal/types';

type Props = {
    photos: Array<AdPhotoDTO>;
    externalID: string;
};

const drainLastUpsertedAdFromLocalStorage = (): Props | null | undefined => {
    try {
        const lastUpsertedAdItem = localStorage.getItem('lastUpsertedAd');
        const lastUpsertedAd: Props | null | undefined = lastUpsertedAdItem
            ? JSON.parse(lastUpsertedAdItem)
            : null;

        return lastUpsertedAd;
    } catch (err: any) {
        return null;
    }
};

export default drainLastUpsertedAdFromLocalStorage;
