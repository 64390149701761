import * as React from 'react';

import RouteNames from 'strat/routes/routeNames';
import type { RouteParameters } from 'react-true-router/route';
import { useRouter } from 'react-true-router';
import { redirectToURISafely } from 'strat/routing';

export type Props = {
    redirectRouteName?: string | null;
    redirectParams?: RouteParameters;
    externalRedirectPath?: string | null;
};

const useRedirect = ({ redirectRouteName, redirectParams, externalRedirectPath }: Props) => {
    const router = useRouter();

    const redirectToExternalPath = React.useCallback(() => {
        if (externalRedirectPath) {
            redirectToURISafely(router, externalRedirectPath);
        }
    }, [router, externalRedirectPath]);

    const redirect = React.useCallback(() => {
        if (externalRedirectPath) {
            redirectToExternalPath();
        } else {
            router.pushRoute(redirectRouteName || RouteNames.HOME, redirectParams);
        }
    }, [router, externalRedirectPath, redirectRouteName, redirectParams, redirectToExternalPath]);

    const goBack = React.useCallback(() => {
        if (externalRedirectPath) {
            redirectToExternalPath();
        } else {
            router.pop();
        }
    }, [router, redirectToExternalPath, externalRedirectPath]);

    return { redirectToExternalPath, redirect, goBack };
};

export default useRedirect;
