import * as React from 'react';
import type { CancelablePromise } from 'strat/util';
import { makeCancelable } from 'strat/util';

/**
 * Utility for wrapping promises so they get canceled on unmount.
 */
const useMakeCancelable = (): ((arg1: Promise<any>) => CancelablePromise) => {
    const cancelables = React.useRef<Array<CancelablePromise>>([]);

    React.useEffect(
        () => () => {
            cancelables.current.forEach((promise) => promise.cancel());
            cancelables.current = [];
        },
        [],
    );

    return React.useCallback((promise) => {
        const cancelable = makeCancelable(promise);
        cancelables.current.push(cancelable);
        return cancelable;
    }, []);
};

export default useMakeCancelable;
