import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

import type { UserDTO } from 'horizontal/dtos';
import { formatDate } from 'horizontal/util';
import { Agency } from 'horizontal/types';

type Props = {
    user: UserDTO | Agency;
};

const formatDateOptions = { month: 'short', year: 'numeric' } as const;

const CreationDate = ({ user }: Props) => {
    const i18n = useI18n();

    return (
        <Text.Regular>
            {/* @ts-expect-error - TS2345 - Argument of type 'Date' is not assignable to parameter of type 'number'. */}
            {t(i18n)`Member since ${formatDate(i18n, user.createdAt, formatDateOptions)}`}
        </Text.Regular>
    );
};

export default CreationDate;
