import * as React from 'react';
import { useSelector } from 'react-redux';

import { connectLocalUnits } from 'strat/i18n';
import { selectI18n } from 'strat/i18n/language/selectors';
import { ThumbnailSizes, ThumbnailSizeValues } from 'strat/image';
import { SchemaType, PostalAddressTag } from 'strat/schema/types';

import MetaData from '../metaData';
import {
    addContextTag,
    addTypeTag,
    addNameTag,
    addPostalAddressTags,
    addGeoTags,
} from '../tagsGeneral';
import {
    addPropertyURLTag,
    addAlternateNameTag,
    addPropertyImageTag,
    addFloorSizeTag,
    addRoomTags,
    addContainedInPlaceTag,
} from '../tagsProperty';

// @ts-expect-error - TS7031 - Binding element 'areaInLocalUnits' implicitly has an 'any' type. | TS7031 - Binding element 'formatLocalUnitsValue' implicitly has an 'any' type. | TS7031 - Binding element 'property' implicitly has an 'any' type.
const PropertyDetailsMetaData = ({ areaInLocalUnits, formatLocalUnitsValue, property }) => {
    const i18n = useSelector(selectI18n);

    const metadata: Record<string, any> = {};
    addContextTag(metadata);
    addTypeTag(metadata, [SchemaType.CATEGORY_BASED], property);
    addNameTag(metadata, property.title);
    addPropertyURLTag(metadata, property, i18n.locale);
    addAlternateNameTag(metadata, property, i18n);
    addGeoTags(metadata, property);
    addFloorSizeTag(metadata, property, { areaInLocalUnits, formatLocalUnitsValue });
    addRoomTags(metadata, property, i18n);
    addPropertyImageTag(metadata, property, ThumbnailSizeValues[ThumbnailSizes.LARGE]);
    addPostalAddressTags(metadata, property.location, [
        PostalAddressTag.ADDRESS_COUNTRY,
        PostalAddressTag.ADDRESS_REGION,
        PostalAddressTag.ADDRESS_LOCALITY,
    ]);
    addContainedInPlaceTag(metadata, property, 2, i18n.locale);

    // @ts-expect-error - TS2786 - 'MetaData' cannot be used as a JSX component.
    return <MetaData data={metadata} />;
};

export default connectLocalUnits(PropertyDetailsMetaData);
