/* https://stackoverflow.com/questions/47187242/flow-object-type-incompatible-with-arraymixed
   the problem is that the Object.values() type definition is:
   values(object: any) => Array<mixed>;
   This means that flow will not understand that sometimes we are passing an object where all
   the values are of the certain type 'T'. So this values() method is needed.
   'K' must be provided because it will not (in some cases) accept the key to be purely a 'string',
   in the case that keys are literals. What I mean is that in the use case of:
   values(activeProducts) frontend/horizontal/horizontal/userOrders/orders/listEntry.jsx
   activeProducts keys are one of:
   'featured_ad', 'ad_limit_bump', 'refresh_ad', 'auto_refresh_ad', 'auto_refresh_category'
   flow will trigger a warning because string is loose and doesn't match the literal values
*/
// @ts-expect-error - TS2344 - Type 'K' does not satisfy the constraint 'string | number | symbol'.
function values<T, K>(objs: Partial<Record<K, T>>): Array<T> {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, T>>'.
    return Object.keys(objs).map((key) => objs[key]);
}

export default values;
