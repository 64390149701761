import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import { Scroll } from 'strat/util';

import styles from './styles/loadingSpinnerOverlay.cssm';
import Flex from './flex';
import { Regular } from './text';
import LoadingSpinner from './loadingSpinner';

const SCROLL_KEY_NAME = 'loading-spinner';

type Props = {
    readonly visible: boolean;
    readonly text?: string;
    readonly light?: boolean;
};

const LoadingSpinnerOverlay = ({ visible, text, light }: Props) => {
    React.useEffect(() => {
        if (visible) {
            Scroll.disable(SCROLL_KEY_NAME);
        } else {
            Scroll.enable(SCROLL_KEY_NAME);
        }
        return () => Scroll.enable(SCROLL_KEY_NAME);
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <Flex
            column
            justifyCenter
            alignCenter
            className={classNames(styles.container, { [styles.light]: light })}
        >
            <LoadingSpinner className={styles.spinner} />
            <Regular bold className={styles.text}>
                {text || <Trans>Loading...</Trans>}
            </Regular>
        </Flex>
    );
};

export default LoadingSpinnerOverlay;
