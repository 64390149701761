import * as React from 'react';
import { Trans } from '@lingui/macro';
import styles from '@app/modal/styles/successDialog.cssm';

import SuccessIcon from '@app/assets/icons/iconSuccess.svg';

import MessageDialog from './messageDialog';

/**
 * Properties for {@see EmailSentDialog}.
 */
type Props = {
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
    children: React.ReactNode;
    renderFooter?: () => React.ReactElement;
};

/**
 * Displays a dialog informing the user that the e-mail
 * was succesfully send.
 */
export default (props: Props) => (
    <MessageDialog
        visible={props.visible}
        onVisibilityChanged={props.onVisibilityChanged}
        title={<Trans>Success!</Trans>}
        className={styles.container}
        icon={<SuccessIcon className={styles.icon} />}
        renderFooter={props.renderFooter}
    >
        {props.children}
    </MessageDialog>
);
