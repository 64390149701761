import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import PrivateNonIndexableHead from './privateNonIndexableHead';

const UserSettingsHead = () => {
    const i18n = useI18n();

    return <PrivateNonIndexableHead title={t(i18n)`Settings`} />;
};

export default UserSettingsHead;
