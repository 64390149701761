import * as React from 'react';
import type { FormikValues } from 'formik';
import { Checkbox } from 'strat/components';

import { PriceType } from 'horizontal/types';

import { CategoryPriceField, PriceTypeField, SecondaryPriceField } from '../commonPostingFields';

import styles from './styles/priceFieldCheckBox.cssm';

type Props = {
    readonly currentPriceType: string;
    readonly priceType: string;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly label: string;
};

const PriceFieldCheckBox = ({ setFieldValue, currentPriceType, priceType, label }: Props) => {
    return (
        <Checkbox
            checked={currentPriceType === priceType}
            name={priceType}
            label={label}
            iconClassname={styles.icon}
            onChange={() => {
                setFieldValue(
                    PriceTypeField.attribute,
                    currentPriceType === priceType ? PriceType.FIXED_PRICE : priceType,
                );
                setFieldValue(CategoryPriceField.attribute, undefined);
                setFieldValue(SecondaryPriceField.attribute, undefined);
            }}
        />
    );
};

export default PriceFieldCheckBox;
