import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text, Button } from 'strat/components';

import iconWallet from 'horizontal/assets/icons/iconDeliveryServiceWallet_noinline.svg';
import type { AddressInfo } from 'horizontal/userOrders/types';

import type { RequestDeliveryData } from './types';
import styles from './styles/reviewRequestDelivery.cssm';

type Props = {
    readonly deliveryInfo: RequestDeliveryData;
    readonly addressInfo: AddressInfo;
    readonly onEdit: () => void;
    readonly onSubmit: () => void;
};

type SectionProps = {
    readonly title: string;
    readonly onEdit: () => void;
    readonly children: React.ReactNode;
};

const ReviewSection = ({ title, onEdit, children }: SectionProps) => {
    return (
        <Flex className={styles.reviewDeliveryItem}>
            <Flex justifySpaceBetween className={styles.reviewDeliveryItemTitle}>
                <Text.Large bold>{title}</Text.Large>
                <Text.Base className={styles.editButton} onClick={onEdit}>
                    <Trans>Edit</Trans>
                </Text.Base>
            </Flex>
            {children}
        </Flex>
    );
};

type PersonalDetailsSectionProps = {
    readonly fullName: string;
    readonly phoneNumber: string;
    readonly alternativePhone?: string | null | undefined;
    readonly onEdit: () => void;
};
const PersonalDetailsReviewSection = ({
    fullName,
    phoneNumber,
    alternativePhone,
    onEdit,
}: PersonalDetailsSectionProps) => {
    const i18n = useI18n();

    return (
        <ReviewSection title={t(i18n)`Personal Details`} onEdit={onEdit}>
            <Flex column className={styles.reviewDeliveryItemDescription}>
                <Flex justifySpaceBetween>
                    <Text.Regular>
                        <Trans>Full name</Trans>:
                    </Text.Regular>
                    <Text.Regular bold>{fullName}</Text.Regular>
                </Flex>
                <Flex justifySpaceBetween>
                    <Text.Regular>
                        <Trans>Phone number</Trans>:
                    </Text.Regular>
                    <Text.Regular bold>{phoneNumber}</Text.Regular>
                </Flex>
                <Flex justifySpaceBetween>
                    <Text.Regular>
                        <Trans>Alternative number</Trans>:
                    </Text.Regular>
                    <Text.Regular bold>{alternativePhone}</Text.Regular>
                </Flex>
            </Flex>
        </ReviewSection>
    );
};

type DeliveryAddressSectionProps = {
    readonly addressInfo: AddressInfo;
    readonly onEdit: () => void;
};

const DeliveryAddressReviewSection = ({ addressInfo, onEdit }: DeliveryAddressSectionProps) => {
    const { name: addressTitle, locationPath } = addressInfo;
    const i18n = useI18n();
    const cityArea = React.useMemo(
        () =>
            locationPath
                ?.map((location: any) => location.name?.[i18n.locale])
                ?.reverse()
                ?.join(', ') ?? '',
        [locationPath, i18n],
    );

    return (
        <ReviewSection title={t(i18n)`Delivery address`} onEdit={onEdit}>
            <Flex column className={styles.reviewDeliveryItemDescription}>
                <Text.Regular bold>{addressTitle}</Text.Regular>
                <Text.Regular>
                    <Trans>Address line 1</Trans>: {addressInfo?.addressLineOne}
                </Text.Regular>
                <Text.Regular>
                    <Trans>City/Area</Trans>: {cityArea}
                </Text.Regular>
            </Flex>
        </ReviewSection>
    );
};

type PaymentMethodSectionProps = {
    readonly onEdit: () => void;
};

const PaymentMethodReviewSection = ({ onEdit }: PaymentMethodSectionProps) => {
    const i18n = useI18n();

    return (
        <ReviewSection title={t(i18n)`Payment method`} onEdit={onEdit}>
            <Flex className={styles.reviewDeliveryItemDescription}>
                <img src={iconWallet} className={styles.walletIcon} alt="wallet" />
                <Text.Regular bold>
                    <Trans>Cash on delivery</Trans>
                </Text.Regular>
            </Flex>
        </ReviewSection>
    );
};

export const ReviewRequestDelivery = ({ deliveryInfo, addressInfo, onEdit, onSubmit }: Props) => {
    const {
        name: fullName,
        phone_number: phoneNumber,
        alternative_phone: alternativePhone,
    } = deliveryInfo;

    return (
        <Flex column className={styles.reviewDeliveryItems}>
            <DeliveryAddressReviewSection addressInfo={addressInfo} onEdit={onEdit} />
            <PersonalDetailsReviewSection
                fullName={fullName}
                phoneNumber={phoneNumber}
                alternativePhone={alternativePhone}
                onEdit={onEdit}
            />
            <PaymentMethodReviewSection onEdit={onEdit} />
            <Flex rowReverse>
                <Button onClick={onSubmit} className={styles.reviewDeliveryItemButton}>
                    <Trans>Place the order</Trans>
                </Button>
            </Flex>
        </Flex>
    );
};
