import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import FetcherFactory from 'strat/fetcher';
import { determineUrlSpecificSEOIndexName } from 'strat/search/indexNames';

type Params = {
    readonly language: string;
    readonly url: string;
};

const factory = new FetcherFactory(
    ['urlSpecificSEOs'],
    (params: Params, state: any) => {
        if (!CONFIG.build.STRAT_ENABLE_URL_SPECIFIC_SEO) {
            return Promise.resolve({
                status: 200,
                data: null,
            });
        }
        const filters = new FilterCollection();

        filters.refine(
            new ExactFilter({
                attribute: 'url',
                value: params.url.trim(),
            }),
        );

        const indexName = determineUrlSpecificSEOIndexName({ language: params.language });
        const job = new SearchJob(indexName, filters, {
            exhaustiveNbHits: false,
            hitsPerPage: 5,
        });

        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend: selectActiveSearchBackend(state) });
        return service.fetchJob(job).then((response) => {
            return {
                status: 200,
                data: response.hits,
            };
        });
    },
    {
        catchServerErrors: true,
    },
);

const fetchURLSpecificSEOs = factory.creator();

const urlSpecificSEOsReducer = factory.reducer();

export { fetchURLSpecificSEOs };

export default urlSpecificSEOsReducer;
