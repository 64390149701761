import { RouteConfig } from 'react-true-router';
import CaptchaRoute from 'strat/routes/captchaRoute';
import LoginRoute from 'strat/routes/loginRoute';
import LoginCallbackRoute from 'strat/routes/loginCallbackRoute';
import PhoneMessageConfirmationRoute from 'strat/routes/phoneMessageConfirmationRoute';

import AgencyPortalRoutes from 'horizontal/agencyPortal/routes';

import HomeRoute from './homeRoute';
import SearchRoute, { SearchRouteClass } from './searchRoute';
import SearchRouteFiltersAPI from './searchRouteFiltersAPI';
import NotFoundRoute from './notFoundRoute';
import ComponentsRoute from './componentsRoute';
import ChatRoute from './chatRoute';
import CreateAdRoute from './createAdRoute';
import PostAdSuccessRoute from './postAdSuccessRoute';
import UpdateAdRoute from './updateAdRoute';
import AdDetailsRoute, { AdDetailsRouteClass } from './adDetailsRoute';
import InternalErrorRoute from './internalErrorRoute';
import AdManagementRoute from './adManagementRoute';
import ApplyAdLimitPackageRoute from './applyAdLimitPackageRoute';
import PaymentBankTransferRoute from './paymentBankTransferRoute';
import SellerProfileRoute from './sellerProfileRoute';
import AgencyProfileRoute from './agencyProfileRoute';
import SitemapRoute from './sitemapRoute';
import PaymentSelectionRoute from './paymentSelectionRoute';
import PaymentSelectionFreeAdUndertakingRoute from './paymentSelectionFreeAdUndertakingRoute';
import PaymentAreebaRoute from './paymentAreebaRoute';
import PaymentJazzCashMWalletRoute from './paymentJazzCashMWalletRoute';
import PaymentPaymobMWalletRoute from './paymentPaymobMWalletRoute';
import PaymentSuccessRoute from './paymentSuccessRoute';
import PaymentProcessingRoute from './paymentProcessingRoute';
import AdUndertakingTermsRoute from './adUndertakingTermsRoute';
import PaymentFawryRoute from './paymentFawryRoute';
import UserSettingsRoute from './userSettingsRoute';
import EditProfileRoute from './editProfileRoute';
import SelectSinglePackageRoute from './selectSinglePackageRoute';
import SelectPackageRoute from './selectPackageRoute';
import SelectPackageSuccessRoute from './selectPackageSuccessRoute';
import BusinessPackagesRoute from './businessPackagesRoute';
import SelectBusinessPackagesRoute from './selectMultiplePackagesRoute';
import ViewBusinessPackageCartRoute from './viewBusinessPackageCartRoute';
import UserOrdersRoute from './userOrdersRoute';
import FavoriteAdsRoute from './favoritesRoute';
import LinkIdentityProviderRoute from './linkIdentityProviderRoute';
import RequestDeliveryRoute from './requestDeliveryRoute';
import VerticalsRoute, { VerticalsRouteClass } from './verticalsRoute';
import PaymentTPayRoute from './paymentTPayRoute';
import PaymentTPayOTPRoute from './paymentTPayOTPRoute';
import TeleSalesToolRoute from './teleSalesToolRoute';
import PackagePurchaseError from './packagePurchaseErrorRoute';
import SaveCardRoute from './saveCardRoute';
import WalletRoute from './walletRoute';
import ChatWidgetRoute from './chatWidgetRoute';
import type { SearchRouteParams, SearchRouteQueryParams } from './createSearchURL';
import type { AdDetailsRouteParams } from './createAdDetailsURL';
import type { ApplyAdLimitPackageRouteParams } from './applyAdLimitPackageRoute';
import type { UserSettingsRouteParams } from './userSettingsRoute';
import type { EditProfileRouteParams } from './editProfileRoute';
import type { SellerProfileRouteParams } from './sellerProfileRoute';
import type { AgencyProfileRouteParams } from './agencyProfileRoute';
import type { SelectBusinessPackagesRouteParams } from './selectMultiplePackagesRoute';
import type { PaymentSelectionRouteParams } from './paymentSelectionRoute';
import type { PaymentAreebaRouteParams } from './paymentAreebaRoute';
import type { PaymentJazzCashMWalletRouteParams } from './paymentJazzCashMWalletRoute';
import type { PaymentPaymobMWalletRouteParams } from './paymentPaymobMWalletRoute';
import type { PaymentSuccessRouteParams } from './paymentSuccessRoute';
import type { PaymentProcessingRouteParams } from './paymentProcessingRoute';
import type { SelectSinglePackageRouteParams } from './selectSinglePackageRoute';
import type { SelectPackageRouteParams } from './selectPackageRoute';
import type { UserOrdersRouteParams } from './userOrdersRoute';
import type { UpdateAdRouteParams } from './updateAdRoute';
import type { SelectPackageSuccessRouteParams } from './selectPackageSuccessRoute';
import type { RequestDeliveryRouteParams } from './requestDeliveryRoute';
import type { SaveCardRouteParams } from './saveCardRoute';
import ProviderCashPaymentRoute from './providerCashPaymentRoute';
import SavedSearchesRoute from './savedSearchesRoute';
import SavedSearchNotificationRoute from './savedSearchNotificationRoute';
import type { AdManagementRouteParams } from './adManagementRoute';

const configuredRoutes = [...(CONFIG.runtime.ENABLE_OLX_VERTICALS ? [VerticalsRoute] : [])];

const routes = [
    HomeRoute,
    FavoriteAdsRoute,
    AdDetailsRoute,
    CreateAdRoute,
    UpdateAdRoute,
    InternalErrorRoute,
    ChatWidgetRoute,
    ChatRoute,
    AdManagementRoute,
    ApplyAdLimitPackageRoute,
    PostAdSuccessRoute,
    SellerProfileRoute,
    AgencyProfileRoute,
    SitemapRoute,
    PaymentSelectionRoute,
    PaymentSelectionFreeAdUndertakingRoute,
    PaymentBankTransferRoute,
    PaymentBankTransferRoute,
    PaymentAreebaRoute,
    PaymentJazzCashMWalletRoute,
    PaymentPaymobMWalletRoute,
    PaymentSuccessRoute,
    PaymentProcessingRoute,
    AdUndertakingTermsRoute,
    PaymentFawryRoute,
    ProviderCashPaymentRoute,
    LoginRoute,
    LoginCallbackRoute,
    UserSettingsRoute,
    EditProfileRoute,
    SelectSinglePackageRoute,
    BusinessPackagesRoute,
    SelectBusinessPackagesRoute,
    ViewBusinessPackageCartRoute,
    UserOrdersRoute,
    SelectPackageSuccessRoute,
    SelectPackageRoute,
    LinkIdentityProviderRoute,
    PaymentTPayRoute,
    PaymentTPayOTPRoute,
    CaptchaRoute,
    RequestDeliveryRoute,
    SavedSearchesRoute,
    SavedSearchNotificationRoute,
    TeleSalesToolRoute,
    CaptchaRoute,
    PackagePurchaseError,
    SaveCardRoute,
    PhoneMessageConfirmationRoute,
    WalletRoute,
    ...configuredRoutes,
    ...AgencyPortalRoutes,

    SearchRouteFiltersAPI,
    SearchRoute,

    // put all the routes above this otherwise the url will match for not found
    NotFoundRoute,
];

if (['development-review-app', 'development'].includes(CONFIG.build.STRAT_ENVIRONMENT)) {
    routes.unshift(ComponentsRoute);
}

export {
    HomeRoute,
    SearchRoute,
    SearchRouteClass,
    NotFoundRoute,
    ComponentsRoute,
    ChatRoute,
    ChatWidgetRoute,
    CreateAdRoute,
    UpdateAdRoute,
    PostAdSuccessRoute,
    AdDetailsRoute,
    AdDetailsRouteClass,
    InternalErrorRoute,
    AdManagementRoute,
    ApplyAdLimitPackageRoute,
    SellerProfileRoute,
    AgencyProfileRoute,
    PaymentSelectionRoute,
    PaymentSelectionFreeAdUndertakingRoute,
    AdUndertakingTermsRoute,
    PaymentAreebaRoute,
    PaymentJazzCashMWalletRoute,
    PaymentPaymobMWalletRoute,
    PaymentSuccessRoute,
    PaymentProcessingRoute,
    PaymentFawryRoute,
    ProviderCashPaymentRoute,
    LoginRoute,
    LoginCallbackRoute,
    UserSettingsRoute,
    EditProfileRoute,
    SitemapRoute,
    SelectSinglePackageRoute,
    SelectPackageRoute,
    BusinessPackagesRoute,
    SelectBusinessPackagesRoute,
    ViewBusinessPackageCartRoute,
    UserOrdersRoute,
    FavoriteAdsRoute,
    SelectPackageSuccessRoute,
    PaymentTPayRoute,
    PaymentTPayOTPRoute,
    CaptchaRoute,
    RequestDeliveryRoute,
    VerticalsRoute,
    VerticalsRouteClass,
    SavedSearchesRoute,
    SavedSearchNotificationRoute,
    TeleSalesToolRoute,
    SaveCardRoute,
    WalletRoute,
};

export type {
    SearchRouteParams,
    SearchRouteQueryParams,
    AdDetailsRouteParams,
    ApplyAdLimitPackageRouteParams,
    SellerProfileRouteParams,
    AgencyProfileRouteParams,
    UserSettingsRouteParams,
    EditProfileRouteParams,
    SelectBusinessPackagesRouteParams,
    PaymentSelectionRouteParams,
    PaymentSuccessRouteParams,
    PaymentProcessingRouteParams,
    PaymentAreebaRouteParams,
    PaymentJazzCashMWalletRouteParams,
    PaymentPaymobMWalletRouteParams,
    SelectSinglePackageRouteParams,
    SelectPackageRouteParams,
    SelectPackageSuccessRouteParams,
    UserOrdersRouteParams,
    UpdateAdRouteParams,
    RequestDeliveryRouteParams,
    SaveCardRouteParams,
    AdManagementRouteParams,
};

export default new RouteConfig(routes);
