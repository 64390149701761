import type { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { DeliveryType } from 'horizontal/types';

import { UserDeliveryOrderStatus } from '../types';

type Props = {
    readonly status: string;
    readonly isSeller: boolean;
    readonly deliveryType: DeliveryType;
    readonly i18n: I18n;
};

const useDeliveryOrderStatusDetails = ({ status, isSeller, deliveryType, i18n }: Props) => {
    switch (status) {
        case UserDeliveryOrderStatus.NEW:
            return {
                title: t(i18n)`New`,
                description: isSeller
                    ? t(i18n)`You received a new order which you can accept or decline.`
                    : '',
                enableCancelOrder: true,
            };

        case UserDeliveryOrderStatus.ACCEPTED:
            if (deliveryType === DeliveryType.SELF_DELIVERY) {
                return isSeller
                    ? {
                          title: t(i18n)`Accepted`,
                          description: t(
                              i18n,
                          )`Order accepted! After sending it, please mark it as Out for delivery.`,
                          enableCancelOrder: true,
                      }
                    : {
                          title: t(i18n)`Confirmed`,
                          description: t(i18n)`Contact seller to track your package.`,
                          enableCancelOrder: false,
                      };
            } else {
                return {
                    title: t(i18n)`Pending Verification`,
                    description: t(i18n)`Address details are being verified.`,
                    enableCancelOrder: true,
                };
            }

        case UserDeliveryOrderStatus.VERIFIED:
            return {
                title: t(i18n)`Processing Delivery`,
                description: '',
                enableCancelOrder: true,
            };

        case UserDeliveryOrderStatus.REJECTED:
            return {
                title: t(i18n)`Rejected`,
                description: t(i18n)`Transaction has been rejected`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.OUT_FOR_DELIVERY:
            if (deliveryType === DeliveryType.SELF_DELIVERY) {
                return isSeller
                    ? {
                          title: t(i18n)`Out for delivery`,
                          description: t(
                              i18n,
                          )`Your order is out for delivery. Upon receiving it, the buyer will mark it as delivered.`,
                          enableCancelOrder: false,
                      }
                    : {
                          title: t(i18n)`Out for delivery`,
                          description: '',
                          enableCancelOrder: false,
                      };
            } else {
                return {
                    title: t(i18n)`In Delivery`,
                    description: t(i18n)`Order is out for delivery`,
                    enableCancelOrder: false,
                };
            }

        case UserDeliveryOrderStatus.DELIVERED:
            return {
                title: t(i18n)`Delivered`,
                description:
                    deliveryType === DeliveryType.SELF_DELIVERY
                        ? t(i18n)`This order has been delivered.`
                        : t(i18n)`Order has been delivered successfully `,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.PAYMENT_EN_ROUTE:
            return {
                title: t(i18n)`Payment in process`,
                description: t(i18n)`Payment is in process`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.PAYMENT_DELIVERED:
            return {
                title: t(i18n)`Payment delivered`,
                description: t(i18n)`Payment has been received`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.CANCELED_BY_BUYER:
            return {
                title: t(i18n)`Cancelled`,
                description: t(i18n)`The buyer is no longer interested.`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.CANCELED_BY_SELLER:
            return {
                title: t(i18n)`Cancelled`,
                description: t(i18n)`The item is no longer available.`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.RETURN_EN_ROUTE:
            return {
                title: t(i18n)`Return in process`,
                description: t(i18n)`Returned order is on its way`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.RETURNED:
            return {
                title: t(i18n)`Returned`,
                description: t(i18n)`Order has been returned successfully`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.NOT_VERIFIED:
            return {
                title: t(i18n)`Cancelled`,
                description: t(i18n)`Order has been cancelled`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.CLOSED_BY_ADMIN:
            return {
                title: t(i18n)`Cancelled`,
                description: t(i18n)`Item can not be delivered.`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.EXPIRED:
            return {
                title: t(i18n)`Expired`,
                description: t(i18n)`Transaction has been expired`,
                enableCancelOrder: false,
            };

        case UserDeliveryOrderStatus.AWAIT_PICKUP:
            return {
                title: t(i18n)`Awaiting pickup`,
                description: '',
                enableCancelOrder: false,
            };

        default:
            return {
                title: t(i18n)`Cancelled`,
                description: t(i18n)`The item is no longer available.`,
                enableCancelOrder: false,
            };
    }
};

export default useDeliveryOrderStatusDetails;
