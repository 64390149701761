import React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';

import ToolTip from './toolTip';
import styles from './styles/checkBox.cssm';

/**
 * Properties for {@see CheckBox}.
 */
export type Props = {
    name?: string;
    label?: string;
    className?: string;
    labelClassName?: string;
    value?: boolean;
    onDismissError?: () => void;
    errorMessage?: string;
    checked: boolean;
    inputClassName?: string;
};

const omittedProps = [
    'hasError',
    'errorMessage',
    'onDismissError',
    'toolTipPosition',
    'label',
    'className',
    'labelClassName',
    'inputClassName',
    'checked',
    'value',
];

/**
 * Simple check box with a label.
 */
const CheckBox = (props: Props) => (
    <div className={props.className}>
        {props.errorMessage && (
            <ToolTip text={props.errorMessage} onCloseClick={props.onDismissError} />
        )}
        <div className={styles.checkBoxContainer}>
            <input
                type="checkbox"
                id={props.name}
                name={props.name}
                className={props.inputClassName || styles.checkBox}
                checked={props.checked || props.value || false}
                // @ts-expect-error - TS2322 - Type 'true | ""' is not assignable to type 'string | number | readonly string[] | undefined'.
                value={props.value || ''}
                {...omit(props, omittedProps)}
            />
        </div>
        <label className={classNames(styles.label, props.labelClassName)} htmlFor={props.name}>
            {props.label}
        </label>
    </div>
);

export default CheckBox;
