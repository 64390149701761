import * as React from 'react';
import { createPushRouteHook, useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';
import { useI18n } from 'strat/i18n/language';

import { AdCreationSourceRoutes } from 'horizontal/constants';
import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';
import { type Ad, type AdProductCreditCost, AppBundles } from 'horizontal/types';
import { useIsProAgent } from 'horizontal/agent/state';

export const useNavigateToApplyAdProductCreditCosts = () => {
    const isProAgent = useIsProAgent();

    // TODO select route based on bundle when we add the credit functionalities to normal users
    const routeName = isProAgent
        ? AdCreationSourceRoutes[AppBundles.AGENCY_PORTAL_BUNDLE_NAME].APPLY_AD_PRODUCTS_CREDIT_COST
        : RouteNames.NOT_FOUND;

    return createPushRouteHook<{
        adExternalID: string;
    }>(RouteNames.AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST)(routeName);
};

export const useFetchAdProductCreditCost = ({
    setError,
}: {
    setError: (isError: boolean) => void;
}) => {
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();
    const [adProductsCreditCost, setAdProductCreditCosts] = React.useState<AdProductCreditCost[]>(
        [],
    );
    const [loading, setLoading] = React.useState(false);

    const fetch = React.useCallback(
        (adExternalID: string) => {
            setError(false);
            setLoading(true);
            setAdProductCreditCosts([]);
            makeCancelable(
                new StratAPI(i18n.locale)
                    .getAdProductCreditCosts(adExternalID)
                    .then((response) => {
                        if (response.status >= 400) {
                            setError(true);
                            throw new ContextfulError(
                                `Unexpected response status while fetching ad product credit costs`,
                                {
                                    status: response.status,
                                    data: response.data,
                                },
                            );
                        }
                        setAdProductCreditCosts(response.data);
                    })
                    .catch((e) => {
                        setError(true);
                        logError({
                            e,
                            msg: 'Unable to fetch ad product credit costs',
                            context: {
                                adExternalID,
                            },
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            );
        },
        [i18n, makeCancelable, setError],
    );

    return {
        adProductsCreditCost,
        adProductsCreditCostLoading: loading,
        fetchAdProductsCreditCost: fetch,
    };
};

export const useApplyAdProductCreditCosts = ({
    onError,
    onSuccess,
}: {
    onError: () => void;
    onSuccess: () => void;
}) => {
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();
    const router = useRouter();

    return React.useCallback(
        (ad: Ad | null, adProductCreditCostExternalIDs: string[]) => {
            if (!ad) {
                return;
            }

            makeCancelable(
                new StratAPI(i18n.locale)
                    .applyAdProductCreditCosts(ad.externalID, adProductCreditCostExternalIDs)
                    .then((response) => {
                        if (response.status === 400 && !!response.data?.adState) {
                            router.pushRoute(RouteNames.AD_DETAILS, {
                                slug: ad.slug,
                                externalID: ad.externalID,
                            });
                            return;
                        }
                        if (response.status >= 400) {
                            onError();
                            throw new ContextfulError(
                                `Unexpected response status while applying ad product credit costs`,
                                {
                                    adExternalID: ad.externalID,
                                    adProductCreditCostExternalIDs,
                                    status: response.status,
                                    data: response.data,
                                },
                            );
                        }
                        onSuccess();
                    })
                    .catch((e) => {
                        onError();
                        logError({
                            e,
                            msg: 'Unable to apply ad product credit costs',
                            context: {
                                adExternalID: ad.externalID,
                                adProductCreditCostExternalIDs,
                            },
                        });
                    }),
            );
        },
        [i18n, makeCancelable, onError, onSuccess, router],
    );
};
