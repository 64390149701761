import { InternalAPI } from 'strat/api';
import { createPropertyURL, createPropertyURLParams } from 'strat/property/url';
import type { PropertyData } from 'strat/property';

/**
 * Generate a URL to a property details page.
 * Follows same conventions as {@see generateAgentProfileLink}.
 *
 * @param property
 * @param language
 * @returns {Promise<string>}
 */
const generatePropertyLink = (property: PropertyData, language: string): Promise<string> => {
    const parameters = createPropertyURLParams(property);
    const pathname = createPropertyURL(parameters).pathname;

    return new InternalAPI().generateShortLink(`/${language}${pathname}`);
};

export default generatePropertyLink;
