export const CAR_AD_FEATURES_KEYS = {
    YEAR: 'year',
    FUEL_TYPE: 'fuelType',
    MILEAGE: 'mileage',
} as const;

export const PROPERTY_AD_FEATURES_KEYS = {
    BEDS: 'beds',
    BATHROOMS: 'bathrooms',
    AREA: 'area',
} as const;

export type CarAdFeatures = {
    [key in (typeof CAR_AD_FEATURES_KEYS)[keyof typeof CAR_AD_FEATURES_KEYS]]: string | null;
};

export type PropertyAdFeatures = {
    [key in (typeof PROPERTY_AD_FEATURES_KEYS)[keyof typeof PROPERTY_AD_FEATURES_KEYS]]:
        | string
        | null;
};

export type HeroCategoryAdFeatures = {
    carFeatures: CarAdFeatures | null;
    propertyFeatures: PropertyAdFeatures | null;
};
