/**
 * Joins a list of items into a string separated by ;
 */
export default (items: Array<string | number | null>): string => {
    if (!items || items.length === 0) {
        return '';
    }

    return `;${items.join(';')};`;
};
