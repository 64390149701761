import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

type Props = {
    bold?: boolean;
    className?: string;
};

const ProBadge = ({ bold, className }: Props) => {
    return (
        <Flex justifyCenter alignCenter className={className}>
            <Text.XSmall bold={bold}>
                <Trans>Pro</Trans>
            </Text.XSmall>
        </Flex>
    );
};

export default ProBadge;
