import type { LocationData } from 'strat/property';

/**
 * Group a list of location nodes by type. Returns an Object
 * mapping each type present in the list of nodes to a list
 * of all nodes of that type.
 */
export default (locations?: LocationData | null) =>
    (locations || []).reduce<Record<string, any>>((accumulator, locationNode) => {
        if (!Object.keys(accumulator).includes(locationNode.type)) {
            accumulator[locationNode.type] = [];
        }

        accumulator[locationNode.type].push(locationNode);
        return accumulator;
    }, {});
