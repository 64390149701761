import { RoomMemberRole as ChatRoomMemberRole } from '@sector-labs/cable-taxonomies';

export {
    MessageType as ChatMessageType,
    NegotiationState as ChatNegotiationState,
    PhoneNumberViewType as ChatPhoneViewType,
    RoomDisabledReason as ChatRoomDisabledReason,
    RoomReadOnlyReason as ChatRoomReadOnlyReason,
    SystemMessageType as ChatSystemMessageType,
} from '@sector-labs/cable-taxonomies';

export { ChatRoomMemberRole };

export const firebaseConfig = {
    apiKey: CONFIG.build.FIREBASE_CHAT_JS_API_KEY,
    authDomain: CONFIG.build.FIREBASE_CHAT_JS_AUTH_DOMAIN,
    databaseURL: CONFIG.build.FIREBASE_CHAT_DATABASE_URL,
    projectId: CONFIG.build.FIREBASE_CHAT_PROJECT_ID,
    storageBucket: CONFIG.build.FIREBASE_CHAT_JS_STORAGE_BUCKET,
    messagingSenderId: CONFIG.build.FIREBASE_CHAT_JS_MESSAGING_SENDER_ID,
    appId: CONFIG.build.FIREBASE_CHAT_JS_APP_ID,
} as const;

export const ChatFeatureStatus = Object.freeze({
    AVAILABLE: 'available',
    MAINTENANCE: 'maintenance',
    UNAUTHENTICATED: 'unauthenticated',
    BANNED: 'banned',

    LOADED: 'loaded',
    LOADING: 'loading',
    ERROR: 'error',
});

export const ChatNavAction = Object.freeze({
    READ: 'user',
    CREATE: 'new',
});

export enum ChatSubjectType {
    AD = 'ad',
    ORDER = 'order',
}

export const ALL_CHATS_ROLE_FILTER = [ChatRoomMemberRole.BUYER, ChatRoomMemberRole.SELLER];
export const CHAT_SUPPLIER_ROLES = [ChatRoomMemberRole.OWNER, ChatRoomMemberRole.SELLER];
export const AGENCY_CHATS_ROLE_FILTER = CHAT_SUPPLIER_ROLES;
