//@ts-nocheck
import shuffle from 'lodash/shuffle';
import isEqual from 'lodash/isEqual';

import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import scoreUnits from './scoreUnits';

const UNITS_COUNT = 3;

/**
 * Picks random units for the new developments based on the currently active filters.
 */
const pickRandomUnits = (newDevelopment: any, filters: any) => {
    const { units } = newDevelopment;

    const scoredUnits = scoreUnits(units, filters);
    const map = new Map();
    scoredUnits.forEach((unit) => {
        let unitsArray = map.get(unit.score);
        if (!unitsArray) {
            unitsArray = [];
            map.set(unit.score, unitsArray);
        }
        unitsArray.push(unit.unit);
    });

    const unitsSortedByScore = Array.from(map).sort((a, b) => b[0] - a[0]);
    const pickedUnits: any = [];
    unitsSortedByScore.some(([_, unitsGroup]: [any, any]) => {
        const shuffledUnits = shuffle(unitsGroup);
        pickedUnits.push(...shuffledUnits.slice(0, UNITS_COUNT - pickedUnits.length));
        return pickedUnits.length === UNITS_COUNT;
    });

    return pickedUnits;
};

/**
 * Fetches new developments from the backend.
 * Picks random new developments for every position.
 * Picks random units for every new development, based on the current filters.
 */
const refetch = (params: any, state: any) =>
    new StratAPI(state.i18n.language).newDevelopments(params.locationHierarchy).then((response) => {
        if (response.status !== 200) {
            return response;
        }

        const shuffledDevelopments = Array.from(
            new Map(
                shuffle(response.data).map((development) => [development.position, development.ad]),
            ),
        );

        shuffledDevelopments.forEach(([_, shuffledDevelopment]: [any, any]) => {
            shuffledDevelopment.pickedUnits = pickRandomUnits(shuffledDevelopment, params.filters);
        });

        return {
            ...response,
            data: {
                developments: shuffledDevelopments,
                locationHierarchy: params.locationHierarchy,
            },
        };
    });

/**
 * If just the filters changed, we repick the units for the new developments based on them.
 */
const repickRandomUnits = (params: any, state: any) => {
    state.newDevelopments.data.developments.forEach(([_, development]: [any, any]) => {
        development.pickedUnits = pickRandomUnits(development, params.filters);
    });

    return Promise.resolve({ data: state.newDevelopments.data, status: 200 });
};

/**
 * Factory for creating action creators and reducers
 * for fetching new developments from the back-end.
 */
const newDevelopmentsFactory = new FetcherFactory(
    'newDevelopments',
    (params: any, state: any) => {
        if (
            !state.newDevelopments.data ||
            !isEqual(params.locationHierarchy, state.newDevelopments.data.locationHierarchy)
        ) {
            return refetch(params, state);
        }

        return repickRandomUnits(params, state);
    },
    {
        successCodes: [200, 404],
        catchServerErrors: true,
    },
);

/**
 * Fetches the new developments from the back-end, or changes the units based on the provided filters.
 */
const fetchNewDevelopments = newDevelopmentsFactory.creator();

/**
 * Reducer for new developments.
 */
const newDevelopmentsReducer = newDevelopmentsFactory.reducer();

export { fetchNewDevelopments };

export default newDevelopmentsReducer;
