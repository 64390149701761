import { KnownSearchBackendName } from '@sector-labs/fe-search-redux/backend';

import inactiveIndexNames from '@app/branding/inactiveIndexNames';
import { SortByValues } from 'strat/search/sortValues';
import { PropertyType } from 'strat/property/types';

import computeIndexNameLanguagePostFix from './computeIndexNameLanguagePostFix';

const adsIndexReplicas: {
    [sortBy in Values<typeof SortByValues>]: (language: string) => string;
} = {
    [SortByValues.ADS]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-${language}`,
    [SortByValues.CITY_LEVEL_SCORE]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-city-level-score-${language}`,
    [SortByValues.PRICE_ASC]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-price-asc-${language}`,
    [SortByValues.PRICE_DESC]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-price-desc-${language}`,
    [SortByValues.DATE_ASC]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-date-asc-${language}`,
    [SortByValues.DATE_DESC]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-date-desc-${language}`,
    [SortByValues.VERIFIED_SCORE]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-verified-score-${language}`,
    [SortByValues.PRODUCT_LABEL_SCORE]: (language: string) =>
        `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-product-label-score-${language}`,
} as const;

const buildAdsIndexName = ({
    language,
    sortBy,
}: {
    language: string;
    sortBy: Values<typeof SortByValues>;
}) => {
    if (CONFIG.build.STRAT_SEARCH_ACTIVE_BACKEND_NAME_FRONTEND === KnownSearchBackendName.ALGOLIA) {
        return adsIndexReplicas[sortBy](
            computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX),
        );
    }
    // For ES, we do not use distinct replica indices for sorting. Sorting happens at query time.
    return adsIndexReplicas[SortByValues.ADS](
        computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX),
    );
};

const buildProjectAdsIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_ADS}-project-${computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX)}`;
};

export const determineAdsIndexName = ({
    language,
    sortBy,
    adType,
}: {
    language: string;
    sortBy?: Values<typeof SortByValues> | null | undefined;
    adType?: Values<typeof PropertyType>;
}) => {
    if (adType === PropertyType.PROJECT) {
        return buildProjectAdsIndexName({ language });
    }

    // default in this context means the main index (algolia) or no sort (ES)
    // NOT SortByValues.DEFAULT, which is the default sorting option for the frontend
    const defaultIndexName = buildAdsIndexName({ language, sortBy: SortByValues.ADS });
    if (sortBy) {
        return buildAdsIndexName({ language, sortBy }) || defaultIndexName;
    }
    return defaultIndexName;
};

export const determineInactiveAdsIndexNames = ({ language }: { language: string }) => {
    if (CONFIG.build.STRAT_SEARCH_ACTIVE_BACKEND_NAME_FRONTEND === KnownSearchBackendName.ALGOLIA) {
        return inactiveIndexNames(
            computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX),
            Object.values(adsIndexReplicas),
        );
    }
    // For ES, we do not use distinct replica indices for sorting. Sorting happens at query time.
    return inactiveIndexNames(
        computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX),
        [adsIndexReplicas[SortByValues.ADS]],
    );
};
