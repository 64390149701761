import type { I18n } from '@lingui/core';

import { joinStringList } from 'strat/util';
import type { LocationData } from 'strat/property/types';

const locationHierarchyToString = (
    location: LocationData | Array<{ name: string }>,
    count?: number,
    i18n?: I18n,
    firstLocationLevel = 1,
    removedTrailingLocationLevels: number = CONFIG.build.REMOVED_TRAILING_LOCATION_LEVELS,
) => {
    const removedTrailingLevelsLocation =
        location.length - 1 > removedTrailingLocationLevels
            ? location.slice(0, location.length - removedTrailingLocationLevels)
            : location;

    let locationHierarchy = removedTrailingLevelsLocation.map((loc) => loc.name).reverse();
    if (locationHierarchy.length > firstLocationLevel) {
        locationHierarchy = locationHierarchy.slice(0, -firstLocationLevel);
        if (count !== undefined) {
            locationHierarchy = locationHierarchy.slice(-count);
        }
    }

    return joinStringList(locationHierarchy, i18n);
};

export default locationHierarchyToString;
