import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import { withI18n } from 'strat/i18n/language/withI18n';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import type { ChatMessage, ChatRoom } from 'horizontal/chat/types';

import styles from './styles/scrollContainer.cssm';

type Props = {
    readonly className?: string;
    readonly conversation: ChatRoom;
    readonly messages: Array<ChatMessage>;
    readonly loadMore: () => Promise<void>;
    readonly isLoading: boolean;
    readonly isEndReached: boolean;
    readonly children: React.ReactNode;
    readonly i18n: I18n;
};

type State = {
    readonly shouldShowNewMessageIndicator: boolean;
};

const BOTTOM_REACH_PRECISION = 50;

class ScrollContainer extends React.Component<Props, State> {
    scrollContainerRef: React.ElementRef<any>;

    state = {
        shouldShowNewMessageIndicator: false,
    };

    constructor(props: Props) {
        super(props);

        this.scrollContainerRef = React.createRef();
    }

    scrollToBottomOffset = () => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (!this.scrollContainerRef?.current) {
            return null;
        }
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const { scrollHeight, clientHeight } = this.scrollContainerRef.current;

        return scrollHeight - clientHeight;
    };

    keepScrollOffset = () => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (!this.scrollContainerRef?.current) {
            return null;
        }
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const { scrollHeight, scrollTop } = this.scrollContainerRef.current;

        return scrollTop - scrollHeight;
    };

    isTopVisible = () => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (!this.scrollContainerRef?.current) {
            return null;
        }
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const { scrollTop } = this.scrollContainerRef.current;

        return scrollTop === 0;
    };

    isOlderMessageLoaded = (prevProps: Props) => {
        const previousEarliest = prevProps.messages?.[0];
        const nextEarliest = this.props.messages?.[0];

        return previousEarliest?.xid !== nextEarliest?.xid;
    };

    isNewMessageLoaded = (prevProps: Props) => {
        const previousLatest = prevProps.messages?.[prevProps.messages.length - 1];
        const nextLatest = this.props.messages?.[this.props.messages.length - 1];

        return previousLatest?.xid !== nextLatest?.xid;
    };

    isMessageFromOtherUser = (message?: ChatMessage | null) => {
        return message?.senderXID === this.props.conversation.contactXID;
    };

    showNewMessageIndicator = () => {
        this.setState({ shouldShowNewMessageIndicator: true });
    };

    hideNewMessageIndicator = () => {
        this.setState({ shouldShowNewMessageIndicator: false });
    };

    scrollToBottom = () => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (this.scrollContainerRef.current) {
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            this.scrollContainerRef.current.scrollTo(
                0,
                // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                this.scrollContainerRef.current.scrollHeight,
            );
        }
    };

    getSnapshotBeforeUpdate(prevProps: Props) {
        if (prevProps?.messages && this.props?.messages) {
            const { messages } = this.props;

            if (this.isOlderMessageLoaded(prevProps)) {
                return { offset: this.keepScrollOffset() };
            }

            if (this.isNewMessageLoaded(prevProps)) {
                if (
                    this.isMessageFromOtherUser(messages?.[messages.length - 1]) &&
                    !this.isScrolledToBottom()
                ) {
                    this.showNewMessageIndicator();
                    return null;
                }

                return { offset: this.scrollToBottomOffset() };
            }
        }
        return null;
    }

    componentDidUpdate(
        prevProps: Props,
        prevState: State,
        snapshot: {
            offset: number;
        } | null,
    ) {
        if (prevProps.conversation.xid !== this.props.conversation.xid) {
            this.resetAll();
        }
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (this.scrollContainerRef?.current && snapshot) {
            const { offset } = snapshot;
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            const { scrollHeight } = this.scrollContainerRef.current;

            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            this.scrollContainerRef.current.scrollTo(0, offset + scrollHeight);
        }

        if (prevProps.isLoading && !this.props.isLoading && this.isTopVisible()) {
            this.loadMore();
        }
    }

    loadMore = () => {
        const { loadMore, isLoading, isEndReached } = this.props;

        if (isEndReached || isLoading) {
            return;
        }
        loadMore();
    };

    onScroll = (event: React.SyntheticEvent<HTMLDivElement>) => {
        const { scrollTop } = event.currentTarget;

        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (scrollTop === 0 && this.scrollContainerRef.current) {
            this.loadMore();
        }

        if (this.isScrolledToBottom()) {
            this.hideNewMessageIndicator();
        }
    };

    resetAll = () => {
        this.hideNewMessageIndicator();
    };

    isScrolledToBottom = () => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const { scrollTop, scrollHeight, clientHeight } = this.scrollContainerRef.current;

        const offsetToBottom = scrollHeight - clientHeight - scrollTop;

        return offsetToBottom < BOTTOM_REACH_PRECISION;
    };

    renderNewMessageIndicator = () => {
        const { i18n } = this.props;

        return (
            <div className={styles.newMessageIndicatorStickyContainer}>
                <Flex
                    alignCenter
                    justifyCenter
                    className={classNames(styles.newMessageIndicatorCenteringContainer, {
                        [styles.hide]: !this.state.shouldShowNewMessageIndicator,
                    })}
                >
                    <button
                        className={classNames(styles.newMessageIndicator, {
                            [styles.hide]: !this.state.shouldShowNewMessageIndicator,
                        })}
                        onClick={this.scrollToBottom}
                    >
                        <Text.Regular bold>{t(i18n)`New Message!`}</Text.Regular>
                    </button>
                </Flex>
            </div>
        );
    };

    render() {
        const { className } = this.props;

        return (
            <Flex
                alignCenter
                column
                className={className || styles.container}
                onScroll={this.onScroll}
                // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'Ref<HTMLDivElement> | undefined'.
                ref={this.scrollContainerRef}
            >
                <Flex
                    column
                    alignCenter
                    className={styles.innerContainer}
                    // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'Ref<HTMLDivElement> | undefined'.
                    ref={this.scrollContainerRef}
                >
                    {this.props.children}
                </Flex>
                {this.renderNewMessageIndicator()}
            </Flex>
        );
    }
}

export default withI18n()(ScrollContainer);
