import * as React from 'react';
import omit from 'lodash/omit';

import { ItemReferralContext } from './itemReferral';
import type { ItemReferral } from './itemReferral';

/**
 * Props passed to the wrapped component.
 */
export type ItemReferralProps = {
    readonly itemReferral?: ItemReferral | null | undefined;
};

const withItemReferral = <T extends ItemReferralProps>(
    component: React.ComponentType<T>,
): React.ComponentType<T> => {
    const ItemReferralHOC = (props: T) => {
        const context = React.useContext(ItemReferralContext);

        // compute the position of the item in the view section using the 'listOffset' and the
        // 'index' of the item in the current list of items
        const listOffset = context?.listOffset || 0;
        // @ts-expect-error - TS2339 - Property 'index' does not exist on type 'T'. | TS2339 - Property 'index' does not exist on type 'T'.
        const viewPosition = props.index !== undefined ? listOffset + props.index + 1 : undefined;

        const itemReferral = context && {
            ...omit(context, ['listOffset']),
            // if the viewPosition is already in the context, we use that value instead of the one
            // computed with the 'listOffset' and 'index'
            viewPosition: context.viewPosition === undefined ? viewPosition : context.viewPosition,
        };

        return React.createElement(component, {
            ...props,
            itemReferral,
        });
    };

    return ItemReferralHOC;
};

export default withItemReferral;
