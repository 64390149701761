import settings from '@app/branding/settings';

import type { Category, Location } from 'horizontal/types';

import { getCategoryIDsDescendants } from './getCategoryIDsDescendants';

/**
 *
 * @param {*} locations full location objects, either from ES or from API
 * @param {*} categories full category objects
 * @param {*} activeCategory the current selected category in filters
 * @returns return the locations that are not disabled for the current active category
 */

export const filterDisabledLocations = (
    locations: Array<Location> | undefined,
    categories: Array<Category>,
    activeCategory?: Category | null,
): Array<Location> => {
    if (!locations || locations.length === 0) {
        return [];
    }

    // The below check is  the desired functionality for olx-pk
    // when no category is selected, we wanna hide all the locations that contain disabled rules
    if (settings.extraLocationsCheckRule) {
        if (!activeCategory) {
            return locations
                .map((item) => item)
                .filter((location) => !location.disabledCategoryIDs);
        }
    }

    if (!activeCategory || !categories?.length) {
        return locations;
    }

    const disabledLocationIds = locations.map((location) =>
        getCategoryIDsDescendants(location.disabledCategoryIDs, categories, true),
    );

    return locations.filter((_, index) => !disabledLocationIds[index]?.includes(activeCategory.id));
};
