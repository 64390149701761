import * as React from 'react';

import type { Notification as NotificationType } from 'horizontal/types';

import Notification from './notification';

export type Props = {
    notifications: NotificationType[];
    loading: boolean;
    totalNotificationsCount: number;
    markAsRead: (notififation: NotificationType) => void;
    fetchNextPage: () => void;
};

const ObservedNotificationsList = ({
    notifications,
    loading,
    totalNotificationsCount,
    markAsRead,
    fetchNextPage,
}: Props) => {
    const [observable, setObservable] = React.useState<any>(null);
    React.useEffect(() => {
        // @ts-expect-error - TS2345 - Argument of type '([entry]: [any]) => void' is not assignable to parameter of type 'IntersectionObserverCallback'.
        const observer = new IntersectionObserver(([entry]: [any]) => {
            if (entry.isIntersecting) {
                fetchNextPage();
                if (observable) {
                    observer.unobserve(observable);
                }
            }
        });
        if (observable) {
            observer.observe(observable);
        }
        return () => {
            if (observable) {
                observer.unobserve(observable);
            }
        };
    }, [observable, fetchNextPage]);

    const renderNotification = (notification: NotificationType, index: number) => {
        if (
            !loading &&
            index === notifications.length - 1 &&
            notifications.length < totalNotificationsCount
        ) {
            return (
                <div key={`${notification.id}-${index}`} ref={setObservable}>
                    <Notification
                        key={notification.id}
                        notification={notification}
                        onClick={() => markAsRead(notification)}
                    />
                </div>
            );
        }
        return (
            <Notification
                key={notification.id}
                notification={notification}
                onClick={() => markAsRead(notification)}
            />
        );
    };

    return <>{notifications.map(renderNotification)}</>;
};

export default ObservedNotificationsList;
