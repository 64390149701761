const computeYearlyQuarters = (
    now: Date,
    options: { quarteredYears: number; totalYears: number; includeCurrentQuarter?: boolean },
) => {
    const { includeCurrentQuarter } = options;

    const currentMonth = now.getUTCMonth();

    let currentYear = now.getUTCFullYear();
    let currentQuarter = Math.floor((currentMonth + 3) / 3);

    if (includeCurrentQuarter) {
        if (currentQuarter === 0) {
            currentYear -= 1;
            currentQuarter = 3;
        } else {
            currentQuarter -= 1;
        }
    }

    let { quarteredYears, totalYears } = options;
    if (currentQuarter === 4) {
        quarteredYears += 1;
        totalYears += 1;
    }

    const targetYears = Array.from(Array(totalYears)).map((_, i) => currentYear + i);

    return targetYears.flatMap((year, index) => {
        if (index === 0 && quarteredYears > 0) {
            return [1, 2, 3, 4]
                .filter((i) => i > currentQuarter)
                .map((quarter) => ({
                    quarter,
                    year,
                }));
        }

        if (index < quarteredYears) {
            return [1, 2, 3, 4].map((quarter) => ({
                quarter,
                year,
            }));
        }

        return [{ year }];
    });
};

export default computeYearlyQuarters;
