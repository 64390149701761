import * as React from 'react';

import { useProductPurchaseStatusParam } from '../hooks';

import EmptyProductPurchases from './emptyProductPurchases';
import ProductPurchaseCard from './productPurchaseCard';
import useProductPurchases from './useProductPurchases';

const ProductPurchasesList = () => {
    const status = useProductPurchaseStatusParam();
    const orders = useProductPurchases(status);
    if (!status) {
        return null;
    }

    if (!orders || !orders.length) {
        return <EmptyProductPurchases status={status} />;
    }

    return orders.map<React.ReactNode>((productPurchase, index) => (
        <ProductPurchaseCard productPurchase={productPurchase} key={index} />
    ));
};

export default ProductPurchasesList;
