import FetcherFactory from 'strat/fetcher';
import settings from '@app/branding/settings';

import { StratAPI } from 'horizontal/api';

export type FetchUserAgencyParams = {
    userExternalID: string | null | undefined;
};

const factory = new FetcherFactory(
    'userAgencies',
    ({ userExternalID }: FetchUserAgencyParams, state: any) => {
        if (settings.disableAgency || !userExternalID) {
            return Promise.resolve({ data: [], status: 200 });
        }

        return new StratAPI(state.i18n.language).userAgencies(userExternalID);
    },
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

const fetchUserAgencies = factory.creator();

const userAgenciesReducer = factory.reducer();

export { fetchUserAgencies };

export default userAgenciesReducer;
