import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import styles from './styles/debugConsoleContent.cssm';
import SeoTab from './seoTab';
import ExitDebugModeButton from './exitDebugModeButton';

type Props = {
    readonly hidden: boolean;
};

const DebugConsoleContent = ({ hidden }: Props) => {
    const menuItems = [{ title: 'SEO information', content: <SeoTab /> }];
    const [selectedItem, setSelectedItem] = React.useState(0);
    return (
        <div className={classNames(styles.wrapper, { [styles.hidden]: hidden })}>
            <Flex className={styles.container}>
                <Flex column className={styles.menu}>
                    {menuItems.map(({ title }, currentIndex) => (
                        <div
                            className={classNames(styles.menuItem, {
                                [styles.selected]: currentIndex === selectedItem,
                            })}
                            onClick={() => setSelectedItem(currentIndex)}
                            key={currentIndex}
                        >
                            {title}
                        </div>
                    ))}
                </Flex>
                <div className={styles.content}>{menuItems[selectedItem].content}</div>
                <Flex columnReverse className={styles.exitButtonContainer}>
                    <ExitDebugModeButton />
                </Flex>
            </Flex>
        </div>
    );
};

export default DebugConsoleContent;
