import type { RoutingContextWithMiddlewares } from 'strat/app';

import RoutingContext from './routingContext';
import type { PatternsCollection } from './regex';
import type { EnhancedLocation } from './location';

/**
 * Parameters that can be passed to a route.
 */
export type RouteParameters = null | {
    [key: string]: any;
};

/**
 * A route that matches an incoming URL an invokes the handler.
 */
class Route {
    /**
     * Unique name of this route.
     */
    name: string;

    /**
     * Regular expressions to match the URL with.
     */
    patterns: PatternsCollection;

    /**
     * Initializes a new instance of {@see Route}.
     * @param name Unique name for this route.
     * @param patterns Regular expression patterns to use
     * for determining whether this route matches an
     * incoming request.
     */
    constructor(name: string, patterns: PatternsCollection) {
        this.name = name;
        this.patterns = patterns;
    }

    /**
     * Creates a canonical URL for this route with the specified
     * parameters.
     * @param params Parameters to encode in the resulting URL.
     * @returns A URL pointing to this route.
     */
    createURL(
        _params: RouteParameters,
        _context?: RoutingContext | RoutingContextWithMiddlewares | null,
        // @ts-expect-error - TS2355 - A function whose declared type is neither 'void' nor 'any' must return a value.
    ): EnhancedLocation {}

    /**
     * Processes the parameters extracted from a route match.
     */
    processParams(
        _params: RouteParameters,
        _contex: RoutingContext | RoutingContextWithMiddlewares,
    ): RouteParameters {
        return _params;
    }

    /**
     * Processes the parameters extracted from a route match. Use this for client-only routes.
     */
    processClientParams(
        _params: RouteParameters,
        _contex: RoutingContext | RoutingContextWithMiddlewares,
    ): RouteParameters {
        return _params;
    }

    /**
     * Called when the router routes to this route.
     */
    onEnter(_context: RoutingContext | RoutingContextWithMiddlewares): void {}

    /**
     * Called AFTER onEnter (if any) client-side only.
     *
     * This is also called after hydration. Use this for client-only routes.
     */
    onClientEnter(_context: RoutingContext | RoutingContextWithMiddlewares): void {}

    /*
     * Called when the router routes away from this route.
     */
    onLeave(
        _oldContext: RoutingContext | RoutingContextWithMiddlewares,
        _newContext: RoutingContext | RoutingContextWithMiddlewares,
    ): void {}
}

export default Route;
