import * as React from 'react';
import autoBind from 'react-autobind';

import { MinimizeMaximizeButton } from 'strat/modal';

import styles from './styles/dropUpWindow.cssm';

type Props = {
    /**
     * The children of the component.
     */
    readonly children: React.ReactNode;
    /**
     * DropupWindow title
     */
    readonly label: string;
    /**
     * DropupWindow aria-label
     */
    readonly ariaLabel: string;
    /**
     * Function that executes when the minimize toggle is fired
     */
    readonly onMinimizeToggle: () => any;
    /**
     * Whether the window is minimized or not.
     */
    readonly minimized: boolean;
};

type State = {
    /**
     * Whether the window is minimized or not.
     */
    minimized: boolean;
};

class DropUpWindow extends React.Component<Props, State> {
    state: State = {
        minimized: this.props.minimized,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);
    }

    minimize() {
        this.setState({ minimized: true });
        this.props.onMinimizeToggle();
    }

    maximize() {
        this.setState({ minimized: false });
        this.props.onMinimizeToggle();
    }

    render() {
        const { label, children, ariaLabel } = this.props;
        const { minimized } = this.state;

        return (
            <div className={styles.dropUpWindowWrapper} aria-label={ariaLabel}>
                <div className={styles.titleBar} aria-label="Title">
                    <span>{label}</span>
                    <MinimizeMaximizeButton
                        onClick={minimized ? () => this.maximize() : () => this.minimize()}
                        minimized={minimized}
                        className={styles.minimizeButton}
                    />
                </div>
                {minimized ? null : <div className={styles.content}>{children}</div>}
            </div>
        );
    }
}

export default DropUpWindow;
