import settings from '@app/branding/settings';

import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

/**
 * Factory for creating action creators and reducers
 * for fetching recent blog posts from the back-end.
 */
const recentBlogPostsFactory = new FetcherFactory(
    'recentBlogPosts',
    (params: any, state: any) =>
        new StratAPI(
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            settings.officialLanguages.includes(state.i18n.language) ? state.i18n.language : 'en',
        ).recentBlogPosts(),
    {
        catchServerErrors: true,
    },
);

/**
 * Fetches recent blog posts from backend.
 */
const fetchRecentBlogPosts = recentBlogPostsFactory.creator();

/**
 * Reducer for recent blog posts.
 */
const recentBlogPostsReducer = recentBlogPostsFactory.reducer();

export { fetchRecentBlogPosts };

export default recentBlogPostsReducer;
