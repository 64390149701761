import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import EMPTY_ARRAY from 'strat/empty/array';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { selectCategories } from 'horizontal/categories';
import CategorySelector from 'horizontal/businessPackages/categorySelector';

import styles from './styles/telesalesTool.cssm';

type Props = {
    setCategoryID: (categoryID: string) => void;
};

const BusinessPackagesFilter = ({ setCategoryID }: Props) => {
    const i18n = useI18n();
    const categories = useSelector(selectCategories);

    const [selectedCategoryExternalID, setSelectedCategoryExternalID] = React.useState('');
    const [selectedSubcategoryExternalID, setSelectedSubcategoryExternalID] = React.useState('');
    const onCategoryChange = React.useCallback(
        (categoryID) => {
            setSelectedCategoryExternalID(categoryID);
            setCategoryID('');
            setSelectedSubcategoryExternalID('');
        },
        [setCategoryID, setSelectedCategoryExternalID],
    );
    const onSubcategoryChange = React.useCallback(
        (subcategoryID) => {
            setCategoryID(subcategoryID);
            setSelectedSubcategoryExternalID(subcategoryID);
        },
        [setCategoryID, setSelectedSubcategoryExternalID],
    );

    const selectedCategory = React.useCallback(
        (allCategories) => {
            return allCategories.find(
                // @ts-expect-error - TS7006 - Parameter 'category' implicitly has an 'any' type.
                (category) => category.externalID === selectedCategoryExternalID,
            );
        },
        [selectedCategoryExternalID],
    );

    return (
        <Flex className={styles.container} column>
            <Flex column>
                <Text.Base className={styles.title} uppercase bold>
                    {t(i18n)`Select options to show packages`}
                </Text.Base>
            </Flex>
            <Flex className={styles.inputContainer} alignCenter column>
                <div className={styles.input}>
                    <CategorySelector
                        name={'parentCategory'}
                        categories={categories}
                        value={selectedCategoryExternalID || ''}
                        onChange={onCategoryChange}
                        defaultChoiceLabel={t(i18n)`Select Category *`}
                    />
                </div>
                <div className={styles.input}>
                    <CategorySelector
                        name={'subcategory'}
                        categories={selectedCategory(categories)?.children || EMPTY_ARRAY}
                        value={selectedSubcategoryExternalID || ''}
                        onChange={onSubcategoryChange}
                        defaultChoiceLabel={t(i18n)`Select Subcategory`}
                    />
                </div>
            </Flex>
        </Flex>
    );
};

export default BusinessPackagesFilter;
