import { OTPTriggerFlow, ThirdPartyProvider } from 'strat/auth/keycloak/types';
import type { RequestCodeType } from 'strat/auth/keycloak';
import { LoginType } from 'strat/auth/types';

import ViewSections from './viewSections';
import Triggers from './triggers';
import TriggerName from './triggerName';
import trigger from './trigger';

const mapLoginTypeToValue = new Map<LoginType, string>([
    [LoginType.PHONE_CODE, 'phone'],
    [LoginType.PHONE_PASSWORD, 'phone'],
    [LoginType.EMAIL_CODE, 'email'],
    [LoginType.EMAIL_LINK, 'link'],
    [LoginType.EMAIL_PASSWORD, 'email'],
    [LoginType.GOOGLE_REDIRECT, 'google'],
    [LoginType.GOOGLE_POPUP, 'google'],
    [LoginType.GOOGLE_ONETAP, 'google'],
    [LoginType.FACEBOOK_REDIRECT, 'facebook'],
    [LoginType.FACEBOOK_POPUP, 'facebook'],
    [LoginType.IMPERSONATION_REDIRECT, 'impersonation'],
    [LoginType.UNKNOWN_POPUP, 'unknown'],
    [LoginType.UNKNOWN_REDIRECT, 'unknown'],
]);

const mapLoginTypeToThirdPartyProvider = new Map<LoginType, ThirdPartyProvider>([
    [LoginType.GOOGLE_REDIRECT, ThirdPartyProvider.GOOGLE],
    [LoginType.GOOGLE_POPUP, ThirdPartyProvider.GOOGLE],
    [LoginType.GOOGLE_ONETAP, ThirdPartyProvider.GOOGLE],
    [LoginType.FACEBOOK_REDIRECT, ThirdPartyProvider.FACEBOOK],
    [LoginType.FACEBOOK_POPUP, ThirdPartyProvider.FACEBOOK],
]);

const mapLoginTypeToRegisterTrigger = new Map<LoginType, Values<typeof Triggers>>([
    [LoginType.PHONE_CODE, Triggers.REGISTER_PHONE],
    [LoginType.PHONE_PASSWORD, Triggers.REGISTER_PHONE],
    [LoginType.EMAIL_CODE, Triggers.REGISTER_EMAIL],
    [LoginType.EMAIL_LINK, Triggers.REGISTER_EMAIL],
    [LoginType.EMAIL_PASSWORD, Triggers.REGISTER_EMAIL],
    [LoginType.GOOGLE_REDIRECT, Triggers.REGISTER_GOOGLE],
    [LoginType.GOOGLE_POPUP, Triggers.REGISTER_GOOGLE],
    [LoginType.GOOGLE_ONETAP, Triggers.REGISTER_GOOGLE],
    [LoginType.FACEBOOK_REDIRECT, Triggers.REGISTER_FACEBOOK],
    [LoginType.FACEBOOK_POPUP, Triggers.REGISTER_FACEBOOK],
]);

export const trackLoginFlowChosen = (loginType: LoginType): void => {
    const option = mapLoginTypeToValue.get(loginType);
    if (option) {
        trigger(Triggers.LOGIN_ATTEMPT, {
            view_section: ViewSections.LOGIN_DIALOG,
            flow_type: `login_${option}`,
        });
    }
};

export const trackingSignUpFlowChosen = (signupType: LoginType): void => {
    const option = mapLoginTypeToValue.get(signupType);
    trigger(Triggers.SIGNUP_ATTEMPT, {
        view_section: ViewSections.LOGIN_DIALOG,
        flow_type: `signup_${option}`,
    });
};

export const trackLoginSuccess = (loginType: LoginType): void => {
    const option = mapLoginTypeToValue.get(loginType);
    trigger(Triggers.LOGIN_SUCCESSFUL, {
        flow_type: `login_${option}`,
    });
};

export const trackLoginFail = (errorMessage: string, loginType: LoginType): void => {
    if (!errorMessage) {
        return;
    }
    const option = mapLoginTypeToValue.get(loginType || LoginType.UNKNOWN_REDIRECT);

    trigger(Triggers.LOGIN_FAILED, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.LOGIN_FAILED,
        flow_type: `login_${option}`,
        login_error: errorMessage,
    });
};

export const trackForgotPasswordClick = (loginType: LoginType): void => {
    const option = mapLoginTypeToValue.get(loginType);
    trigger(Triggers.CLICK_FORGOT_PASSWORD, {
        view_section: ViewSections.LOGIN_DIALOG,
        flow_type: `login_${option}`,
    });
};

export const trackSignUpClick = (): void => {
    trigger(Triggers.CLICK_SIGNUP, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.CLICK_SIGNUP,
    });
};

export const trackSignupSuccess = (signupType: LoginType): void => {
    const option = mapLoginTypeToValue.get(signupType);
    trigger(Triggers.SIGNUP_SUCCESSFUL, {
        name: TriggerName.SIGNUP_SUCCESS,
        flow_type: `signup_${option}`,
    });
};
export const trackSignUpFail = (errorMessage: string, signupType: LoginType): void => {
    if (!errorMessage) {
        return;
    }
    const option = mapLoginTypeToValue.get(signupType);
    trigger(Triggers.SIGNUP_FAILED, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.SIGNUP_FAILED,
        flow_type: `signup_${option}`,
        signup_error: errorMessage,
    });
};

export const trackSetNewPassword = (): void => {
    trigger(Triggers.SET_NEW_PASSWORD, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.SET_NEW_PASSWORD,
    });
};

export const trackOTPSent = (
    sendCodeChannel: Values<typeof RequestCodeType>,
    flowType?: Values<typeof OTPTriggerFlow>,
): void =>
    trigger(Triggers.OTP_REQUEST_SENT, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.OTP_SENT,
        value: sendCodeChannel,
        flow_type: flowType,
    });

export const trackOTPResent = (
    sendCodeChannel: Values<typeof RequestCodeType>,
    flowType?: Values<typeof OTPTriggerFlow>,
): void =>
    trigger(Triggers.OTP_REQUEST_RESENT, {
        name: TriggerName.OTP_RESENT,
        value: sendCodeChannel,
        flow_type: flowType,
    });

export const trackEmailLinkSent = (): void =>
    trigger(Triggers.EMAIL_LINK_SENT, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.EMAIL_LINK_SENT,
    });

export const trackEmailLinkResent = (): void =>
    trigger(Triggers.EMAIL_LINK_RESENT, {
        view_section: ViewSections.LOGIN_DIALOG,
        name: TriggerName.EMAIL_LINK_RESENT,
    });

export const trackOTPSubmitted = (): void =>
    trigger(Triggers.TYPE_OTP, {
        view_section: ViewSections.LOGIN_DIALOG,
    });

export const trackLinkSocialAccount = (loginType: LoginType): void => {
    const provider = mapLoginTypeToThirdPartyProvider.get(loginType);
    if (!provider) {
        return;
    }

    trigger(Triggers.LINK_SOCIAL_ACCOUNT, {
        name: provider,
    });
};

export const trackRegisterSuccess = (loginType: LoginType): void => {
    const value = mapLoginTypeToValue.get(loginType);
    if (!value) {
        return;
    }

    trigger(Triggers.REGISTER_SUCCESSFUL, {
        name: TriggerName.REGISTER_SUCCESS,
        value,
    });
};

export const trackRegisterFail = (loginType: LoginType): void => {
    const value = mapLoginTypeToValue.get(loginType);
    if (!value) {
        return;
    }

    trigger(Triggers.REGISTER_FAILED, {
        name: TriggerName.REGISTER_FAILED,
        value,
    });
};

export const trackRegister = (loginType: LoginType): void => {
    const triggerName = mapLoginTypeToRegisterTrigger.get(loginType);
    if (!triggerName) {
        return;
    }

    trigger(triggerName);
};

export const trackLoginOpen = (userPath?: Values<typeof Triggers>) => {
    trigger(Triggers.LOGIN_OPEN, {
        view_section: ViewSections.LOGIN_DIALOG,
        ...(userPath && { user_path: userPath }),
    });
};

export const trackSignupOpen = (userPath?: Values<typeof Triggers>) => {
    trigger(Triggers.SIGNUP_OPEN, {
        view_section: ViewSections.LOGIN_DIALOG,
        ...(userPath && { user_path: userPath }),
    });
};

export const trackSubscribeToMarketingEmailsClick = (checked: boolean): void => {
    trigger(Triggers.CLICK_SUBSCRIBE_MARKETING_EMAILS, {
        view_section: ViewSections.LOGIN_DIALOG,
        checked: checked,
    });
};
