import * as React from 'react';
import className from 'classnames';
import { Text } from 'strat/components';

import styles from './styles/listFilter.cssm';

type Props = {
    readonly label: string;
    readonly count?: number | null | undefined;
    readonly selected?: boolean;
};

const ListNode = ({ label, count, selected }: Props) => (
    <div className={styles.node}>
        <Text.Base
            className={className(styles.label, {
                [styles.selected]: selected,
            })}
        >
            {label}
        </Text.Base>
        {CONFIG.runtime.STRAT_ENABLE_OLX_SHOW_AD_COUNT && !!count && (
            <Text.Base className={styles.count}>({count})</Text.Base>
        )}
    </div>
);

export default ListNode;
