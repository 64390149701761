import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

import type { MixedAd } from '../types';

import type { CreationStateType } from './state';
import { selectFavorites, selectCreationState } from './selectors';

export const useIsFavoriteAd = (adExternalID: string): boolean => {
    const favorites = useSelector(selectFavorites);
    return favorites.includes(adExternalID);
};

export const useCreationState = (): CreationStateType => useSelector(selectCreationState);

export const useIsCreationLoading = (ad: MixedAd): boolean => {
    const { loading, adExternalID } = useCreationState();
    return loading && ad.externalID === adExternalID;
};

const ErrorCodes: {
    [key: string]: string;
} = Object.freeze({
    PRECONDITION_FAILED: '412',
});

const ErrorMessages: {
    [code: string]: (i18n: I18n) => string;
} = Object.freeze({
    [ErrorCodes.PRECONDITION_FAILED]: (i18n) => t(i18n)`Maximum number of favorite ads exceeded.`,
});

export const usePopUpState = (ad: MixedAd): [boolean, string | null | undefined] => {
    const { loading, adExternalID, errorCode } = useCreationState();
    const i18n = useI18n();
    if (errorCode === undefined || ad.externalID !== adExternalID) {
        return [false, null];
    }
    const getErrorMessage = ErrorMessages[errorCode.toString()];
    const errorMesage = getErrorMessage?.(i18n) || t(i18n)`Something went wrong!`;
    return [!loading, errorMesage];
};
