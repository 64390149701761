import { CategoryRole } from 'horizontal/types';

export const SearchPageSearchType = Object.freeze({
    /**
     * This type of search is fetching ads based on the rules defined in @sector-labs/strat-horizontal-core/search
     * This produces 'main' hits (i.e. counted)  and 'fallback' hits (not counted) which are displayed in a "Showing
     * nearby ads" section. Fallback hits are only produced when there are not enough matching main hits.
     * */
    CIRCLE_BASED_SLOTTING: 'circleBasedSlotting',
    /**
     * This type of search is characterized by having all the featured ads at the beginning of the search, followed by
     * the regular ones.
     * Typically, only a number of pages are shown of featured ads. For the rest, the user can click a "View all" button
     * to see all the featured ads.
     */
    FEATURED_REGULAR_GROUPED: 'featuredRegularGrouped',
    /**
     * This type of search goes through the ads but it also shows a number of random featured ads on each page.
     * The featured ads are not paginated, so they can be duplicated when navigating between the pages.
     * The featured ads are also found among the other "regular" ads.
     *
     * If there are more than a given number of featured ads for a search page, a "View all" button is shown, allowing
     * the user to see all the featured ads.
     */
    FEATURED_REGULAR_EACH_PAGE: 'featuredRegularEachPage',

    /**
     * This type of search will sort the results based on the product score field and timestamp for the sorting
     * most relevant. This search type should only be used only with  most relevant sorting.
     */
    PAID_FIRST: 'paidFirst',

    /**
     * This search result is slotted, with 6 featured ads ordered based on freshness, and 14 normal
     * ads sorted on product score for the sorting Most Relevant. It's using the same mechanism
     * like CIRCLE_BASED_SLOTTING search type
     */
    PAID_FIRST_SLOTTED: 'paidFirstSlotted',

    /**
     * This search is only for `Promoted` ads, which are for now `Elite` and `Featured`
     */
    PROMOTED_ONLY: 'promotedOnly',

    /**
     * This type of search is characterized by having all the featured/elite ads at the beginning of the search, followed by
     * the regular ones.
     * Typically, only a number of pages are shown of featured ads. For the rest, the user can click a "View all" button
     * to see all the featured ads.
     * Also, the location is automatically expanded to circles.
     */
    EXPANDED_RADIUS_PRODUCT_GROUPED: 'expandedRadiusProductGrouped',

    fromCategoryRole: (categoryRole?: Values<typeof CategoryRole> | null) => {
        switch (categoryRole) {
            case CategoryRole.SEARCH_TYPE_PAID_FIRST:
                return SearchPageSearchType.PAID_FIRST;
            case CategoryRole.SEARCH_TYPE_PAID_FIRST_SLOTTED:
                return SearchPageSearchType.PAID_FIRST_SLOTTED;
            case CategoryRole.SEARCH_TYPE_FEATURED_GROUPED:
                return SearchPageSearchType.FEATURED_REGULAR_GROUPED;
            case CategoryRole.SEARCH_TYPE_FEATURED_EACH_PAGE:
                return SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE;
            case CategoryRole.SEARCH_TYPE_CIRCLE_BASED_SLOTTING:
                return SearchPageSearchType.CIRCLE_BASED_SLOTTING;
            default:
                return null;
        }
    },
});
