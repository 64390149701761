import * as React from 'react';
import Logo from '@app/branding/logo';
import { BackButton, Header as BaseHeader } from 'strat/components';

import styles from './styles/header.cssm';

type Props = {
    readonly onBack?: () => void;
    readonly popHistory?: boolean;
    readonly renderLogo?: (className: string) => React.ReactElement | null;
};

const Header = ({ onBack, popHistory, renderLogo }: Props) => (
    <BaseHeader className={styles.header}>
        <BackButton onBack={onBack} popHistory={popHistory} />
        {renderLogo ? renderLogo(styles.logo) : <Logo className={styles.logo} />}
    </BaseHeader>
);

export default Header;
