import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { selectUserExternalID, selectUserRoles } from 'strat/user/session';
import { isStratCreditUser } from 'strat/user/roles';

import { encodeStateFilter } from 'horizontal/adManagement/stateFilterEncoding';
import Page from 'horizontal/pages/page';
import type { AdStateFilterValues } from 'horizontal/types';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { fetchUserAgencies } from 'horizontal/agent/state';
import { ProductPurchaseStatus } from 'horizontal/packages';

import ensureHasActiveUser from './ensureHasActiveUser';

export type AdManagementRouteParams = {
    query?: string | null | undefined;
    agentCode?: string | null | undefined;
    category?: string;
    agent?: string;
    stateFilter?: AdStateFilterValues | null | undefined;
    createDate?: string | null | undefined;
    updateDate?: string | null | undefined;
    page?: number;
};

class AdManagementRoute extends Route {
    constructor() {
        super(RouteNames.AD_MANAGEMENT, [['^/myads', '(?:\\?)?']]);
    }

    createURL({
        query,
        agentCode,
        category,
        stateFilter,
        page,
    }: AdManagementRouteParams): EnhancedLocation {
        const filter = encodeStateFilter({
            query,
            agentCode,
            category,
            stateFilter,
        });
        const search: Record<string, any> = {};

        if (filter) {
            search.filter = filter;
        }

        if (page) {
            search.page = page;
        }

        if (Object.keys(search).length > 0) {
            return { pathname: '/myads', search };
        }

        return { pathname: '/myads' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const userRoles = selectUserRoles(context.redux.store.getState());
        if (!ensureHasActiveUser(context) || isStratCreditUser(userRoles)) {
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());

        context.rendering.renderPage(Page.AD_MANAGEMENT);
        context.promise.wait(
            Promise.all([
                dispatch(fetchProductPurchases({ status: ProductPurchaseStatus.ACTIVE })),
                dispatch(
                    fetchCategoryFields({
                        categoryExternalIDs: null,
                        includeChildCategories: true,
                        lite: true,
                    }),
                ),
                dispatch(fetchFreeAdLimitUsage()),
                dispatch(fetchUserAgencies({ userExternalID })),
            ]),
        );
    }
}

export default new AdManagementRoute();
