import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useDialog } from 'strat/dialogs/dialogContext';
import { Text } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import type { KeycloakUser } from 'strat/auth/keycloak/user';

import type { UserDTO } from 'horizontal/dtos';
import { DialogNames } from 'horizontal/dialogs';
import { useTrackReportAbuse } from 'horizontal/gtm';

import styles from './styles/reportSeller.cssm';

type Props = {
    readonly user: KeycloakUser | UserDTO;
    readonly textClassName?: string;
};

const ReportSeller = ({ user, textClassName }: Props) => {
    const activeUser = useActiveUser();
    const [, setVisible] = useDialog(DialogNames.REPORT_USER_DIALOG);
    const trackReportAbuse = useTrackReportAbuse();

    const { externalID: reportedUserExternalID, name: reportedUserName } = user;

    if (!activeUser || activeUser.externalID === reportedUserExternalID) {
        return null;
    }

    return (
        <div
            className={styles.content}
            onClick={() => {
                trackReportAbuse();
                setVisible(true, {
                    reportedUserExternalID,
                    reportedUserName,
                });
            }}
        >
            <Text.Base className={textClassName}>
                <Trans>Report user</Trans>
            </Text.Base>
        </div>
    );
};

export default ReportSeller;
