import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import withGTMLeadTracking from 'strat/gtm/withGTMLeadTracking';
import { LoadingSpinner, Text } from 'strat/components';
import settings from '@app/branding/settings';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';

import { useOnCallButtonClick } from 'horizontal/adDetails';

import styles from './styles/phoneNumberLink.cssm';

type Props = GTMLeadTrackingProps & {
    readonly isLoading?: boolean | null | undefined;
    readonly isError?: boolean | null | undefined;
    readonly phoneNumber?: string | null | undefined;
    readonly placeholder?: string | null | undefined;
    readonly viewSection: string;
    readonly lightTheme?: boolean;
    readonly largeText?: boolean;
    readonly lightThemeClass?: string;
};

const PhoneNumberLink = ({
    isLoading,
    isError,
    phoneNumber,
    placeholder,
    viewSection,
    trackCallLead,
    trackCallView,
    lightTheme = false,
    largeText = false,
    lightThemeClass = styles.lightText,
}: Props) => {
    React.useEffect(() => {
        if (phoneNumber) {
            trackCallView(viewSection);
        }
    }, [phoneNumber, trackCallView, viewSection]);

    const onCallButtonClick = useOnCallButtonClick({ viewSection, trackCallLead });

    if (isError) {
        <Text.Small>
            <Trans>Something went wrong</Trans>
        </Text.Small>;
    }

    if (isLoading || !phoneNumber) {
        return (
            <>
                {placeholder && <Text.Regular>{placeholder}</Text.Regular>}
                {isLoading && (
                    <LoadingSpinner dark={!settings.allowsPrimaryContactButton || !lightTheme} />
                )}
            </>
        );
    }

    if (!phoneNumber) {
        return null;
    }

    return (
        <>
            <Text.Base
                onClick={() => onCallButtonClick(phoneNumber)}
                bold
                className={classNames(styles.phoneNumber, {
                    [styles.largeText]: largeText,
                    [lightThemeClass]: lightTheme,
                })}
            >
                {phoneNumber}
            </Text.Base>
        </>
    );
};

export default withGTMLeadTracking<Props>(PhoneNumberLink);
