import * as React from 'react';
import { Trans } from '@lingui/macro';
import { BackButton, Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import ProviderCashPaymentDeviceIcon from 'horizontal/assets/icons/GeneralPaymentDevice_noinline.svg';

import { PaymentSource } from '../types';

import styles from './styles/providerCashPayment.cssm';

type Props = {
    readonly referenceNumber: string;
    readonly cashPaymentSource: Values<typeof PaymentSource>;
};

const ProviderCashPaymentBody = ({ referenceNumber, cashPaymentSource }: Props) => {
    const i18n = useI18n();
    const paymentSource = PaymentSource.text(i18n, cashPaymentSource);

    return (
        <section className={styles.wrapper}>
            <Flex column className={styles.content}>
                <Flex alignCenter>
                    <BackButton className={styles.backButton} />
                    <Text.XXLarge bold>{paymentSource}</Text.XXLarge>
                </Flex>
                <Flex column alignCenter className={styles.providerCashPaymentSection}>
                    <img
                        src={ProviderCashPaymentDeviceIcon}
                        alt={`${paymentSource} Payment Device`}
                        className={styles.providerCashPaymentDeviceIcon}
                    />
                    <Text.Large bold>
                        <Trans>Pay using {paymentSource}</Trans>
                    </Text.Large>
                    <Text.Small>
                        <Trans>
                            Use the code below while paying at any of {paymentSource}
                            &apos;s points of sale across Lebanon.
                        </Trans>
                    </Text.Small>
                    <Text.Base bold className={styles.referenceNumberWrapper}>
                        {referenceNumber}
                    </Text.Base>
                    <Text.Base bold underlined className={styles.myAdsLink}>
                        <Link route={RouteNames.AD_MANAGEMENT}>
                            <Trans>Go to My Ads</Trans>
                        </Link>
                    </Text.Base>
                    <Text.Small>
                        <Trans>Need help? Call {paymentSource}&apos;s support</Trans>
                    </Text.Small>
                </Flex>
            </Flex>
        </section>
    );
};

export default ProviderCashPaymentBody;
