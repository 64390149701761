import * as React from 'react';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { t } from '@lingui/macro';

import styles from './styles/adsSeeMoreButton.cssm';

type Props = {
    readonly onClick: () => void;
};

export const AdsSeeMoreButton = ({ onClick }: Props) => {
    const i18n = useI18n();
    return (
        <Flex alignCenter className={styles.seeMoreContainer} onClick={onClick}>
            <Text.Large bold>{t(i18n)`See All`}</Text.Large>
            <IconArrowRight className={styles.buttonIcon} />
        </Flex>
    );
};
