import * as React from 'react';
import debounce from 'just-debounce';

const getWindowSize = () => ({
    height: window.innerHeight,
    width: window.innerWidth,
});

/**
 * Whenever we have a component which renders both on the server and
 * on the client, we should have the first render from the client the same
 * as the one from the server.
 * Very strange bugs can happen if you don't. Trust me. Some components won't rerender
 * when they should.
 * See:
 *    https://reactjs.org/docs/react-dom.html#hydrate
 *    https://github.com/facebook/react/issues/13260
 *
 * On the server we don't have any window height or width. The trick we do here
 * is that we initially render the component on the client just like it is rendered
 * on the server. This is the recommended way to handle this from
 * https://reactjs.org/docs/react-dom.html#hydrate
 * */
const useWindowSize = (): {
    height: number | null | undefined;
    width: number | null | undefined;
} => {
    const [windowSize, setWindowSize] = React.useState(getWindowSize());

    const [resultWindowSize, setResultWindowSize] = React.useState({
        height: undefined,
        width: null,
    });

    // @ts-expect-error - TS2345 - Argument of type '{ height: number; width: number; }' is not assignable to parameter of type 'SetStateAction<{ height: undefined; width: null; }>'.
    React.useEffect(() => setResultWindowSize(getWindowSize()), [windowSize]);

    React.useEffect(() => {
        let mounted = true;
        const onResize = debounce(() => {
            if (mounted) {
                setWindowSize(getWindowSize());
            }
        }, 200);

        window.addEventListener('resize', onResize, { passive: true });
        return () => {
            mounted = false;
            // @ts-expect-error - TS2769 - No overload matches this call.
            window.removeEventListener('resize', onResize, { passive: true });
        };
    }, []);

    return resultWindowSize;
};

export default useWindowSize;
