import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';

import type { AdDetailsBadges } from 'horizontal/types';
import { AdBadgeType } from 'horizontal/types';
import DownloadIcon from 'horizontal/assets/icons/iconDownload.svg';
import VideoIcon from 'horizontal/assets/icons/iconVideoOutline.svg';

import styles from './styles/gallery.cssm';

type RenderBadgeInfo = {
    readonly className: any;
    readonly text: string;
    readonly url?: string;
    readonly icon?: React.ReactElement<
        React.ComponentProps<typeof VideoIcon | typeof DownloadIcon>
    >;
};

const useBadgesConfig = (
    badgeType: Values<typeof AdBadgeType>,
): RenderBadgeInfo | null | undefined => {
    const i18n = useI18n();
    const common = {
        className: styles.badgeContainer,
    } as const;

    switch (badgeType) {
        case AdBadgeType.PANORAMA:
            return {
                ...common,
                text: t(i18n)`Virtual`,
            };
        case AdBadgeType.VIDEO:
            return {
                ...common,
                icon: <VideoIcon className={styles.icon} />,
                text: t(i18n)`Video`,
            };
        case AdBadgeType.DELIVERY:
            return {
                ...common,
                text: t(i18n)`Delivery`,
            };
        case AdBadgeType.DELIVERY_VERIFIED:
            return {
                ...common,
                text: t(i18n)`Verified`,
            };
        case AdBadgeType.VERIFIED:
            return {
                ...common,
                icon: <DownloadIcon className={styles.icon} />,
                text: t(i18n)`Download Verified Doc.`,
            };
        case AdBadgeType.MANAGED:
            return {
                ...common,
                icon: <DownloadIcon className={styles.icon} />,
                text: t(i18n)`Download Managed Doc.`,
            };
        case AdBadgeType.DOCUMENT:
            return {
                ...common,
                icon: <DownloadIcon className={styles.icon} />,
                text: t(i18n)`Download Doc.`,
            };
        default: {
            return null;
        }
    }
};

const renderBadge = (badge: RenderBadgeInfo) => {
    const url = badge.url;

    return (
        <div
            className={classNames(styles.button, badge.className)}
            onClick={() => url && window.open(url, '_blank')}
            aria-label={`${badge.text}`}
        >
            {badge.icon}
            {badge.text}
        </div>
    );
};

type Props = {
    readonly badge: AdDetailsBadges;
    readonly className?: string;
};

const Badge = ({ badge, className }: Props) => {
    const badgeConfig = useBadgesConfig(badge.type);

    if (!badgeConfig) {
        return null;
    }

    return renderBadge({ ...badgeConfig, url: badge.url, ...(className ? { className } : {}) });
};

export default Badge;
