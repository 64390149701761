import { t } from '@lingui/macro';
import * as React from 'react';
import { withI18n } from 'strat/i18n/language/withI18n';
import type { I18n } from '@lingui/core';
import { Checkbox, Flex, Text } from 'strat/components';

import type { Category } from 'horizontal/types';

import styles from './styles/activeCategoryCheckbox.cssm';

type Props = {
    readonly i18n: I18n;
    readonly activeCategory: Category | null | undefined;
    readonly checked: boolean;
    readonly setChecked: (arg1: boolean) => void;
};
const ActiveCategoryCheckbox = ({ i18n, activeCategory, checked, setChecked }: Props) => {
    if (!activeCategory) {
        return null;
    }

    const categoryName = activeCategory.nameShort || activeCategory.name;

    return (
        <Flex alignCenter className={styles.container}>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
            <span className={styles.activeCategory}>
                <Text.Small>{t(i18n)`Only in ${categoryName}`}</Text.Small>
            </span>
        </Flex>
    );
};

export default withI18n()(ActiveCategoryCheckbox);
