import computeElementName from './computeElementName';
import computeElementTitle from './computeElementTitle';
import computeParameters from './computeParameters';
import { DisplayCategory } from './displayCategory';

export default Object.freeze({
    computeElementName,
    computeElementTitle,
    computeParameters,
    leaf: false,
    displayCategory: DisplayCategory.NONE,
    withHomepage: false,
    withPurposeHeading: false,
});
