import { t } from '@lingui/macro';
import * as React from 'react';
import type { Choice, ChoiceValue } from 'strat/components/types';
import { useI18n } from 'strat/i18n/language';

import DropdownChoice from './dropdownChoice';
import styles from './styles/dropdownPopularSuggestions.cssm';

interface Props<T extends ChoiceValue> {
    fieldName: string;
    value?: T | null;
    onClick: (value: T) => void;
    popularChoices: Array<Choice<T>>;
    renderIcon?: (value: Choice<T>) => React.ReactElement | null | undefined;
}

const DropdownPopularSuggestions = <T extends ChoiceValue>({
    value,
    onClick,
    fieldName,
    popularChoices,
    renderIcon,
}: Props<T>) => {
    const i18n = useI18n();

    return (
        <>
            <div className={styles.entriesTitle}>{t(i18n)`Popular ${fieldName}`}</div>
            {popularChoices.map((choice) => (
                <DropdownChoice
                    key={choice.id}
                    choice={choice}
                    selected={choice.value === value}
                    onClick={() => onClick(choice.value)}
                    renderIcon={renderIcon}
                />
            ))}
            <div className={styles.entriesTitle}>{t(i18n)`Others`}</div>
        </>
    );
};

export default DropdownPopularSuggestions;
