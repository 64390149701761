import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSearchHits } from '@sector-labs/fe-search-redux/state';
import settings from '@app/branding/settings';
import { isNonNullable } from 'strat/util';

import { selectCategories, findCategoryByIdentifier } from 'horizontal/categories';
import type { Category, LiteAd } from 'horizontal/types';

const adBelongsToCategory = (ad: LiteAd, categoryExternalID?: string | null): boolean => {
    return !!ad.category.filter((adCategory) => adCategory.externalID === categoryExternalID)
        .length;
};

export type CategoryWithHits = {
    readonly category: Category;
    readonly hits: Array<LiteAd>;
};

const useCategoriesWithHits = (): CategoryWithHits[] => {
    const hits = useSearchHits() as LiteAd[];
    const categories = useSelector(selectCategories) as Category[];

    return React.useMemo(
        () =>
            settings.homePageCategoriesWithHitsIdentifiers
                .map((categoryIdentifier) =>
                    findCategoryByIdentifier(categories, categoryIdentifier),
                )
                .filter(isNonNullable)
                .map((category) => {
                    const categoryHits = hits.filter((hit) =>
                        adBelongsToCategory(hit, category.externalID),
                    );
                    return { category, hits: categoryHits };
                }),
        [categories, hits],
    );
};

export default useCategoriesWithHits;
