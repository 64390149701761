import React from 'react';

import { PaymentOrder, PaymentProcessing } from 'horizontal/payment';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    order: PaymentOrder;
    paymentID: string;
};

const PaymentProcessingPage = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ order, paymentID }: Props) => (
        <PaymentProcessing order={order} paymentID={paymentID} />
    ),
} as const;

export default PaymentProcessingPage;
