import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSearchFacets } from '@sector-labs/fe-search-redux/state';

import { findDeepObject } from 'horizontal/util/findDeep';
import useCategoryFilterValue from 'horizontal/search/category/useCategoryFilterValue';
import type { Category } from 'horizontal/types';

const useCategoryChoicesTree = (): Category | null | undefined => {
    const facets = useSearchFacets();
    const selectedCategory = useCategoryFilterValue();
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const categories = useSelector((state) => state.categories.data);

    const chain = React.useMemo(
        // @ts-expect-error - TS2339 - Property 'externalID' does not exist on type '{ readonly level: number; readonly children: ...[]; }'.
        () => findDeepObject(categories, (cat) => cat.externalID === selectedCategory?.externalID),
        [selectedCategory, categories],
    );

    const facetsCount = React.useCallback(
        (category: Category) => {
            if (!facets) {
                return 0;
            }

            const facetsForLevel = facets[`category.lvl${category.level}.externalID`];
            if (!facetsForLevel) {
                return 0;
            }

            return facetsForLevel.find((facet) => facet.key === category.externalID)?.count || 0;
        },
        [facets],
    );

    const shouldDisplayCategory = React.useCallback(
        (category: Category) =>
            (selectedCategory && selectedCategory.level > category?.level) ||
            selectedCategory?.externalID === category?.externalID ||
            !!facetsCount(category) ||
            !!CONFIG.runtime.DISABLE_SEARCH_FACETING,
        [selectedCategory, facetsCount],
    );

    // @ts-expect-error - TS7022 - 'filterShouldDisplay' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
    const filterShouldDisplay = React.useCallback(
        (category: Category) => ({
            ...category,
            children: category.children.filter(shouldDisplayCategory).map(filterShouldDisplay),
        }),
        [shouldDisplayCategory],
    );

    if (!chain) {
        return null;
    }

    return filterShouldDisplay(chain);
};

export default useCategoryChoicesTree;
