import { CommonAdData, PropertyType } from 'strat/property/types';
import { ProjectUnit } from 'strat/property/types';

export type { ProjectUnit };

export enum ProjectPaymentPlanType {
    MILESTONES = 'milestones',
    MONTHLY = 'monthly',
    PERCENTAGE = 'percentage',
}

export type MilestonesPaymentPlanPayment = {
    valuePercentage: number | null;
    numberOfPayments?: number | null;
};

export type MilestonesPaymentPlanInstallment = {
    valuePercentage: number | null;
    description: string | null;
};

export type MilestonesProjectPaymentPlan = {
    type: ProjectPaymentPlanType.MILESTONES;
    downPayment: MilestonesPaymentPlanPayment;
    preHandoverPayment: MilestonesPaymentPlanPayment;
    handoverPayment: MilestonesPaymentPlanPayment;
    postHandoverPayment: MilestonesPaymentPlanPayment;
    preHandoverInstallments: Array<MilestonesPaymentPlanInstallment>;
    postHandoverInstallments: Array<MilestonesPaymentPlanInstallment>;
};

type MonthlyPaymentPlanPayment = {
    valuePercentage: number | null;
    paymentMonth: string | null;
};

type MonthlyPaymentPlanInstallment = {
    valuePercentage: number | null;
    paymentMonth: string | null;
};

export type MonthlyProjectPaymentPlan = {
    type: ProjectPaymentPlanType.MONTHLY;
    downPayment: MonthlyPaymentPlanPayment;
    handoverPayment: MonthlyPaymentPlanPayment;
    postHandoverPayment: MonthlyPaymentPlanPayment;
    installments: Array<MonthlyPaymentPlanInstallment>;
};

type PercentagePaymentPlanPayment = {
    valuePercentage: number | null;
    completionPercentage: number | null;
};

type PercentagePaymentPlanInstallment = {
    valuePercentage: number | null;
    completionPercentage: number | null;
};

export type PercentageProjectPaymentPlan = {
    type: ProjectPaymentPlanType.PERCENTAGE;
    downPayment: PercentagePaymentPlanPayment;
    handoverPayment: PercentagePaymentPlanPayment;
    postHandoverPayment: PercentagePaymentPlanPayment;
    installments: Array<PercentagePaymentPlanInstallment>;
};

export type ProjectPaymentPlan = (
    | MilestonesProjectPaymentPlan
    | MonthlyProjectPaymentPlan
    | PercentageProjectPaymentPlan
) & {
    preHandoverSum: number | null;
    postHandoverSum: number | null;
};

export type ProjectData = CommonAdData & {
    type: PropertyType.PROJECT;
    units: Array<ProjectUnit>;
    startDate?: number | null;
    paymentPlans?: Array<ProjectPaymentPlan>;
};
