import * as React from 'react';
import useHeaderLinks from '@app/header/compact/useHeaderLinks';

type Props = {
    readonly verticalSelector: string;
    readonly children: React.ReactElement;
    readonly className?: string;
    readonly onClick?: () => void;
};

const VerticalLink = ({ verticalSelector, className, children, onClick }: Props) => {
    const headerLinks = useHeaderLinks();
    const verticalLink = headerLinks.find((link) => link.name === verticalSelector)?.link;

    if (!verticalLink) {
        return null;
    }

    return (
        <a href={verticalLink} className={className} onClick={onClick}>
            {children}
        </a>
    );
};

export default VerticalLink;
