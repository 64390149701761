import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { trigger, Triggers } from 'strat/gtm';
import { Link } from 'strat/navigation';

import { useChatLink } from '@app/chat/hooks/useChatLink';
import IconClose from 'horizontal/assets/icons/iconClose.svg';
import { ChatRoomMemberRole, ChatRoomReadOnlyReason } from 'horizontal/chat/constants';
import { useChatProps, useChatSDK } from 'horizontal/chat/hooks';
import Head from 'horizontal/chat/inbox/head';
import type { ChatRoom } from 'horizontal/chat/types';
import { ContactPersonRole } from 'horizontal/types';

import ConversationMoreButton from './conversationMoreButton';
import PhoneNumberButton from './phoneNumberButton';
import ReportUserButton from './reportUserButton';
import SMSButton from './smsButton';
import styles from './styles/header.cssm';
import UserDetails from './userDetails';

type Props = {
    readonly className?: string;
    readonly conversation: ChatRoom;
};

const Header = ({ className, conversation }: Props) => {
    const sdk = useChatSDK();
    const { agencyMode } = useChatProps();
    const { contactXID, myRole, adXID } = conversation;
    const { contact, ad, isLoading: adIsLoading } = sdk.useRoomContactInfo(conversation);

    const isBuyer = myRole === ChatRoomMemberRole.BUYER;
    const adContactInfoRoles = ad?.contactInfo?.roles;

    // #180759880 remove fallback User Settings after we migrate contactpersons
    const showPhoneNumber = !adContactInfoRoles
        ? contact?.isPhoneNumberVisible
        : adContactInfoRoles.includes(ContactPersonRole.SHOW_PHONE_NUMBER);

    const shouldShowNumber = isBuyer && showPhoneNumber && !adIsLoading;

    const readonly = conversation?.readOnly === ChatRoomReadOnlyReason.READONLY_ROLE;

    const { route, params } = useChatLink({ adXID });

    return (
        <Flex alignCenter className={classNames(styles.container, className)}>
            {contactXID && <Head contactXID={contactXID} contactUser={contact} />}
            <UserDetails conversation={conversation} />
            <Flex className={styles.buttonsContainer}>
                {ad && !agencyMode && (
                    <ReportUserButton className={styles.icon} ad={ad} conversation={conversation} />
                )}
                {shouldShowNumber && (
                    <>
                        <PhoneNumberButton className={styles.icon} ad={ad} />
                        <SMSButton className={styles.icon} ad={ad} />
                    </>
                )}
                {!readonly && <ConversationMoreButton conversation={conversation} />}
                {!agencyMode && (
                    <Link route={route} params={params} className={styles.iconContainer}>
                        <IconClose
                            onClick={() => trigger(Triggers.CLICK_CLOSE_CHAT)}
                            className={styles.icon}
                        />
                    </Link>
                )}
            </Flex>
        </Flex>
    );
};

export default Header;
