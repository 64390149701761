import * as React from 'react';

import DialogNames from 'strat/dialogs/dialogNames';
import { useDialog } from 'strat/dialogs/dialogContext';
import type { PropertyData } from 'strat/property/types';
import { buildCanonicalURLWithoutLanguage } from 'strat/routing';
import { isCanceled, makeCancelable } from 'strat/util';

import { ShareLinkStates } from '../types';

const useShareLinkDialog = (
    generate: (arg1?: any) => Promise<any>,
    title: string,
    triggerName: string,
    pageType: string,
    property?: PropertyData | null,
    copyPadText?: string | JSX.Element,
    renderSubtitle?: (className: string) => React.ReactNode,
    renderCopyLinkText?: (className: string) => React.ReactNode,
) => {
    const [, setShareLinkDialog] = useDialog(DialogNames.SHARE_LINK);

    const constructFullShortURL = (shortLink: any) => {
        return buildCanonicalURLWithoutLanguage(`/l/${shortLink}`);
    };

    /**
     * Callback which shows the dialog, clearing its previous state, then creating the new link.
     */
    return React.useCallback(() => {
        const cancelablePromise = makeCancelable(generate());

        setShareLinkDialog(true, {
            activeState: ShareLinkStates.LOADING,
            linkData: null,
            title,
            triggerName,
            property,
            pageType,
            cancelablePromise,
        });

        cancelablePromise
            .then(({ data, status }) => {
                if (status !== 200 && status !== 201) {
                    throw new Error('Short link generation failed.');
                }
                setShareLinkDialog(true, {
                    activeState: ShareLinkStates.SUCCESS,
                    linkData: constructFullShortURL(data.shortenedLink),
                    title,
                    triggerName,
                    property,
                    pageType,
                    copyPadText,
                    renderSubtitle,
                    renderCopyLinkText,
                });
            })
            .catch((error) => {
                if (isCanceled(error)) {
                    setShareLinkDialog(false);
                } else {
                    setShareLinkDialog(true, {
                        activeState: ShareLinkStates.ERROR,
                        title,
                        triggerName,
                        property,
                        pageType,
                    });
                }
            });
    }, [
        generate,
        setShareLinkDialog,
        title,
        triggerName,
        property,
        pageType,
        copyPadText,
        renderSubtitle,
        renderCopyLinkText,
    ]);
};

export default useShareLinkDialog;
