import { useSelector } from 'react-redux';

import { selectAreHitsLoaded } from 'strat/search/selectors';
import { useVerticalScroller } from 'strat/navigation';

const SearchPageVerticalScroller = () => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const position = useSelector((state) => state.search.scrollYPositionToRestore);
    const loaded = useSelector(selectAreHitsLoaded);
    useVerticalScroller(position, loaded);

    return null;
};

export default SearchPageVerticalScroller;
