import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { getTimeWithMeridiem } from 'strat/util';

import type { ChatMessage } from 'horizontal/chat/types';
import { ChatMessageType, ChatPhoneViewType } from 'horizontal/chat/constants';
import PhoneInCircleIcon from 'horizontal/assets/icons/phoneInCircle.svg';

import styles from './styles/viewPhoneNumberMessage.cssm';

type Props = {
    readonly message: ChatMessage;
};

const ViewPhoneNumberMessage = ({ message }: Props) => {
    const i18n = useI18n();

    const content = message.content[ChatMessageType.PHONE_VIEW];
    if (!content) {
        return null;
    }

    const isSMSType = content.type === ChatPhoneViewType.SMS;
    const formattedTime: string = getTimeWithMeridiem(message.createdAt, i18n.locale);
    return (
        <Flex justifyCenter className={styles.container}>
            {!isSMSType && <PhoneInCircleIcon className={styles.icon} />}
            <Text.Small bold>
                {isSMSType ? (
                    <Trans>SMS at {formattedTime}</Trans>
                ) : (
                    <Trans>Phone number viewed at {formattedTime}</Trans>
                )}
            </Text.Small>
        </Flex>
    );
};

export default ViewPhoneNumberMessage;
