import { t } from '@lingui/macro';
import * as React from 'react';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import Designs from 'strat/branding/designs';
import RangeSelector from 'strat/search/rangeSelector';
import isNil from 'lodash/isNil';
import { Text } from 'strat/components';
import { useSelector } from 'react-redux';

import styles from './styles/verticalRangeFilter.cssm';
import DropdownWithButtons from './dropdrownWithButtons';

type Props = {
    readonly title: string;
    readonly value: {
        min: number | null;
        max: number | null;
    };
    readonly onValueSelected: (value: { min: number | null; max: number | null }) => void;
    readonly placeholderMax: string;
    readonly placeholderMin: string;
    readonly maxChoices: Array<number>;
    readonly minChoices: Array<number>;
    readonly filterUnit: string;
    readonly titleClassname?: string;
    readonly displayCompact?: boolean;
};

const VerticalRangeFilter = ({
    title,
    value,
    onValueSelected,
    placeholderMax,
    placeholderMin,
    maxChoices,
    minChoices,
    filterUnit,
    titleClassname = styles.label,
    displayCompact = false,
}: Props) => {
    const i18n = useI18n();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    const numberFormatter = numberFormatterFactory({
        notation: displayCompact ? 'compact' : 'standard',
    });
    const valueText =
        value.min || value.max
            ? `${(!isNil(value.min) && numberFormatter.format(value.min)) || placeholderMin} - ${
                  (!isNil(value.max) && numberFormatter.format(value.max)) || placeholderMax
              } (${filterUnit})`
            : null;
    const placeholerText = `${title} (${filterUnit})`;

    const placeholder = <Text.Base className={styles.placeholder}>{placeholerText}</Text.Base>;
    const renderValue = valueText
        ? () => <Text.Base className={styles.valueText}>{valueText}</Text.Base>
        : undefined;

    const onResetClick = () => onValueSelected({ min: null, max: null });

    return (
        <DropdownWithButtons
            title={title}
            titleClassname={titleClassname}
            placeholder={placeholder}
            renderValue={renderValue}
            onResetClick={onResetClick}
        >
            <RangeSelector
                value={value || { min: null, max: null }}
                onChange={onValueSelected}
                minChoices={minChoices}
                maxChoices={maxChoices}
                maxCanEqualMin
                placeholderMax={placeholderMax}
                placeholderMin={placeholderMin}
                minimumLabel={t(i18n)`Minimum`}
                maximumLabel={t(i18n)`Maximum`}
                design={Designs.FILTER_DESIGN_2022}
            />
        </DropdownWithButtons>
    );
};
export default VerticalRangeFilter;
