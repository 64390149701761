//@ts-nocheck
import FetcherFactory, { buildDefaultState, FetchState } from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import type { AgencyData } from 'strat/agency/types';
import { agencyTransformer } from 'strat/mergedIndex';
import { AppDispatch } from 'strat/state';
import { TabNames } from 'strat/agency/selectors';

/**
 * Fetcher factory for fetching agencies.
 */
const factory = new FetcherFactory('agency', (params: any, state: any) => {
    const fetchAgents = CONFIG.build.DISABLE_BAYUT_MPA
        ? Promise.resolve()
        : new StratAPI(state.i18n.language).agents(params.externalID);

    return Promise.all([
        new StratAPI(state.i18n.language).agency(params.externalID),
        fetchAgents,
    ]).then((values) => {
        const [agencyData, agentsData] = values;

        if (agencyData.status === 200 || agencyData.status === 304) {
            return {
                data: {
                    ...agencyTransformer(
                        agencyData.data,
                        state.i18n.language,
                        CONFIG.build.ENABLE_AGENCY_MERGED_INDEX,
                    ),
                    ...{ agents: agentsData && agentsData.data },
                },
                status: 200,
            };
        }
        return {
            data: null,
            status: 200,
        };
    });
});

/**
 * Action creator for fetching a agency from
 * the back-end.
 */
const fetchAgency = factory.creator();

/**
 * Action creator for pre-loading a agency
 */
const preloadAgency = (agency: AgencyData) => ({
    type: factory.actions.preload,
    params: { externalID: agency.externalID },
    data: agency,
});

/**
 * Reducers for agency.
 */
const agencyFetcherReducer = factory.reducer();

const Actions = Object.freeze({
    SET_ACTIVE_TAB: 'AGENCY/SET_ACTIVE_TAB',
});
export type AgencyDetailType = FetchState & {
    activeTab: string | null;
};

export type AgencyDetailState = {
    agency: AgencyDetailType;
};
const defaultState = {
    ...buildDefaultState(),
    activeTab: null,
};
type SetAgencyActiveTab = {
    type: Actions.SET_ACTIVE_TAB;
    activeTab: string;
};

type Action = SetAgencyActiveTab;

const agencyReducer = (state = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTab,
            };
        default:
            return agencyFetcherReducer(state, action);
    }
};

const setNewActiveTab = (activeTab: string | null) => ({
    type: Actions.SET_ACTIVE_TAB,
    activeTab,
});

export const setAgencyActiveTab =
    (activeTab: string | null) => (dispatch: Dispatch<AppDispatch>, _getState: GetState) => {
        if (Object.values(TabNames).includes(activeTab)) {
            dispatch(setNewActiveTab(activeTab));
        } else {
            dispatch(setNewActiveTab(null));
        }
    };

export { fetchAgency, preloadAgency };

export default agencyReducer;
