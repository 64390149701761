import { t } from '@lingui/macro';
import React from 'react';
import useI18n from 'strat/i18n/language/useI18n';
import { Dialog } from 'strat/modal';
import { Text, Flex } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import IconMap from '@app/assets/icons/iconMap_noinline.svg';
import IconTruck from '@app/assets/icons/iconTruck_noinline.svg';
import * as DeliveryInformationDialogText from '@app/userOrders/addresses/deliveryInformationDialogText';

import InformationBox from './informationBox';
import styles from './styles/deliveryInformationDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
};

const DeliveryInformationDialog = ({ visible, onVisibilityChanged }: Props) => {
    const i18n = useI18n();

    return (
        <Dialog dismissible visible={visible} onVisibilityChanged={onVisibilityChanged}>
            <Flex column className={styles.container}>
                <Flex justifySpaceBetween className={styles.header}>
                    <Text.Base bold className={styles.title}>
                        <DeliveryInformationDialogText.SellFasterHeader />
                    </Text.Base>
                    <IconClose
                        onClick={() => onVisibilityChanged(false)}
                        className={styles.closeButton}
                    />
                </Flex>

                <Flex justifySpaceBetween className={styles.informationContainer}>
                    <InformationBox
                        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'.
                        renderIcon={() => <img className={styles.image} src={IconMap} alt="Map" />}
                        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'.
                        title={<DeliveryInformationDialogText.FirstSectionTitle />}
                        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'.
                        content={<DeliveryInformationDialogText.FirstSectionContent />}
                    />
                    <InformationBox
                        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'.
                        renderIcon={() => (
                            <img className={styles.image} src={IconTruck} alt="Truck" />
                        )}
                        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'.
                        title={<DeliveryInformationDialogText.SecondSectionTitle />}
                        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'.
                        content={<DeliveryInformationDialogText.SecondSectionContent />}
                    />
                </Flex>
                <a
                    href={t(i18n)`aboutDeliveryService_url`}
                    target="_blank noopener noreferrer"
                    className={styles.link}
                >
                    <Text.Regular bold>
                        <DeliveryInformationDialogText.LearnMoreAboutDelivery />
                    </Text.Regular>
                </a>
            </Flex>
        </Dialog>
    );
};

export default DeliveryInformationDialog;
