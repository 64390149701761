// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import { FrontEndAPI } from 'strat/api';
import Purpose from 'strat/purpose';
import type { CategoryNodeData } from 'strat/property';

import FilterValues from '../filterValues';

export type PropertyMapCollectionParams = {
    purpose: Values<typeof Purpose>;
    category: string;
    locations?: Array<string> | null;
};

export const fetchPropertyMapCollections = (params: PropertyMapCollectionParams) =>
    // @ts-ignore-error - TS2339 - Property 'mapBasedSearch' does not exist on type 'ZameenFrontEndAPI'.
    new FrontEndAPI().mapBasedSearch(params.purpose, params.category, params.locations);

export const getPropertyMapCollectionParams = (getState: GetState): PropertyMapCollectionParams => {
    const filterCollection = getFilterCollection(getState());

    const purpose = filterCollection.getFilterValue<string>(
        FilterValues.purpose.attribute,
    ) as string;
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const category = filterCollection.getFilterValue<CategoryNodeData, Partial<CategoryNodeData>>(
        FilterValues.category.attribute,
    ).externalID as string;

    return {
        purpose,
        category,
    };
};
