import isNil from 'lodash/isNil';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { StratAPI } from 'strat/api';
import FetcherFactory from 'strat/fetcher';
import { FilterValues } from 'strat/search';

import hideStudioInternalLinks from './hideStudioInternalLinks';
import checkHasOnlyBasicFilters from './checkRequiredFilters';

const SECTIONS_WITH_STUDIO = ['recommendedSearches', 'nearbyNew', 'usefulLinks'];

/**
 * Factory for creating action creators and reducers
 * for fetching internal links from the back-end.
 */
const internalLinksFactory = new FetcherFactory('internalLinks', (params: any, state: any) => {
    let includeNextLevelLocations = false;
    let location = params.location;

    if (location instanceof Object) {
        location = location.slug;
        includeNextLevelLocations =
            CONFIG.build.STRAT_SHOW_DISTRICT_INTERNAL_LINKS_ON_CITY_PAGES &&
            params.location.level === CONFIG.build.CITY_LEVEL;
    }

    if (location === FilterValues.location.placeholder) {
        location =
            // @ts-ignore
            FilterValues.location.placeholders[state.i18n.language] ||
            FilterValues.location.placeholder;
    }

    return new StratAPI(state.i18n.language)
        .internalLinks(
            params.purpose,
            params.category,
            location,
            params.beds,
            params.completionStatus,
            params.listingType,
            includeNextLevelLocations,
        )
        .then((response) => {
            if (response.status !== 200 || !response.data) {
                return response;
            }

            const data = response.data;

            SECTIONS_WITH_STUDIO.forEach((section) => {
                if (data[section]) {
                    data[section] = hideStudioInternalLinks(data[section]);
                }
            });

            if (includeNextLevelLocations && data.nextLevelChildLocations) {
                // We'll put everything inside child locations (including grandchildren) to avoid changing the display logic
                data.childLocationsNew = [
                    ...data.childLocationsNew,
                    ...data.nextLevelChildLocations,
                ];
            }

            if (!params.withExtraRecommendedSearches && data.recommendedSearchesExtraParams) {
                data.recommendedSearchesExtraParams = [];
            }

            return {
                ...response,
                data,
            };
        });
});

/**
 * Reducer for internal links.
 */
const internalLinksReducer = internalLinksFactory.reducer();

/**
 * Fetches internal links according to the specified
 * filter collection.
 */
const fetchInternalLinks = (filters: FilterCollection) => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const category = filters.getFilterValue(FilterValues.category.attribute, {
        slug: null,
    }).slug;

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const location = filters.getFilterValue(FilterValues.location.attribute, [
        FilterValues.location.placeholder,
    ])[0];

    // treat 0 as truthy value
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const bedValue = filters.getFilterValue(FilterValues.beds.attribute, [])[0];
    const beds = isNil(bedValue) ? null : bedValue;
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    const completionStatus = filters.getFilterValue(FilterValues.completionStatus.attribute);
    const withExtraRecommendedSearches =
        CONFIG.build.STRAT_ENABLE_RECOMMENDED_SEARCHES_WITH_EXTRA_PARAMS &&
        checkHasOnlyBasicFilters(filters);
    const listingType = filters.getFilterValue(FilterValues.type.attribute);

    const creator = internalLinksFactory.creator();
    return creator({
        purpose,
        location,
        category,
        beds,
        completionStatus,
        listingType,
        withExtraRecommendedSearches,
    });
};

/**
 * Enables fetching of internal links.
 */
const enableInternalLinks = () => ({
    type: internalLinksFactory.actions.enable,
});

/**
 * Disables/blocks fetching of internal links.
 */
const disableInternalLinks = () => ({
    type: internalLinksFactory.actions.disable,
});

/**
 * Clears any currently stored internal links..
 */

const clearInternalLinks = () => ({
    type: internalLinksFactory.actions.clear,
});

export { fetchInternalLinks, enableInternalLinks, disableInternalLinks, clearInternalLinks };

export default internalLinksReducer;
