import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

import styles from './styles/discountFlag.cssm';

type Props = {
    readonly amount: number;
};

const PaymentMethod = ({ amount }: Props) => (
    <Text.Base bold className={styles.discountFlag}>
        {amount}
        <Trans>% OFF</Trans>
    </Text.Base>
);

export default PaymentMethod;
