import { RouteConfig } from 'react-true-router';
import horizontalRouteConfig, {
    AdDetailsRoute as HorizontalAdDetailsRoute,
} from 'horizontal/routes';

import AdDetailsRoute from './adDetailsRoute';

const horizontalRoutes = horizontalRouteConfig.routes.slice();
horizontalRoutes.splice(horizontalRoutes.indexOf(HorizontalAdDetailsRoute), 1);

const appRoutes = [AdDetailsRoute];

export { AdDetailsRoute };

export default new RouteConfig([...appRoutes, ...horizontalRoutes]);
