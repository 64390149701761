//@ts-nocheck
import Purpose from './purpose';

/**
 * Available actions in this app.
 */
const Actions = Object.freeze({
    SET_PURPOSE: 'PURPOSE/SET',
});

/**
 * Default state for this app.
 */
const defaultState = Purpose.FOR_RENT;

/**
 * Sets the current purpose.
 */
const setPurpose = (purpose: any) => ({
    type: Actions.SET_PURPOSE,
    purpose,
});

/**
 * Reducer for the app.
 */
const purposeReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_PURPOSE:
            return action.purpose;

        default:
            return state;
    }
};

export { setPurpose };

export default purposeReducer;
