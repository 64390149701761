import { t } from '@lingui/macro';
import * as React from 'react';
import { useRef } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'strat/modal';
import { useI18n } from 'strat/i18n/language';
import { Triggers } from 'strat/gtm';
import { setGeoLocation } from 'strat/user/state';
import { LoadingSpinner } from 'strat/loadable';
import settings from '@app/branding/settings';
import { Input, Flex, Text } from 'strat/components';
import { selectIsMobileLayout } from 'strat/layout/selectors';
import useLocationSuggestions from 'strat/search/useLocationSuggestions';

import { useTrackLocationClick } from 'horizontal/gtm';
import LocationList from 'horizontal/search/location/locationList';
import CurrentLocationSelector from 'horizontal/search/location/currentLocationSelector';
import useFetchChildLocations from 'horizontal/search/location/useFetchChildLocations';
import { useDisabledLocations, RegionLocationsList } from 'horizontal/search/location';
import { selectCategories } from 'horizontal/categories';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { useFullLocationsHierarchy } from 'horizontal/location';
import { useMakeCancelable } from 'horizontal/util';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';
import IconClose from 'horizontal/assets/icons/iconClose.svg';
import IconCurrentLocation from 'horizontal/assets/icons/iconCurrentLocation.svg';
import type { LiteHierarchicalLocation, Location } from 'horizontal/types';

import LocationDropdownBackButton from './locationDropdownBackButton';
import LocationTreeList from './nestedLocationDropdown/locationListOptions';
import LocationIcon from './locationIcon';
import { useLocationHighlightedName } from './locationEntry';
import useRestoreLocationDropdown from './useRestoreLocationDropdown';
import styles from './styles/nestedLocationDropdown.cssm';
import LocationRadiusFilter from './locationRadiusFilter';

export type LocationDropdownProps = {
    readonly location: Location | LiteHierarchicalLocation;
    readonly onLocationSelected: (location: Location) => void;
    readonly onLocationRemoved?: () => void;
    readonly onCurrentLocationSelected?: () => void;
    readonly maxLevel?: number;
    readonly loading?: boolean;
    readonly withLocationRadiusFilter?: boolean;
};

const NestedLocationDropdown = ({
    location,
    onLocationSelected,
    onLocationRemoved,
    onCurrentLocationSelected,
    maxLevel,
    loading,
    withLocationRadiusFilter,
}: LocationDropdownProps) => {
    const i18n = useI18n();
    const dispatch = useDispatch();
    const makeCancelable = useMakeCancelable();
    const highlightedName = useLocationHighlightedName(i18n, location);
    const topLevelLocation = settings.getTopLevelLocation(i18n);
    const isMobile = useSelector(selectIsMobileLayout);
    const contentDivRef = useRef<HTMLInputElement>(null);

    const scrollToTop = () => {
        if (contentDivRef.current) {
            contentDivRef.current.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const [locationSearchQuery, setLocationSearchQuery] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    // activeLocation represents the top location that
    // is currently visible in the nested dropdown
    const [activeLocation, setActiveLocation] = React.useState<Location | LiteHierarchicalLocation>(
        location || topLevelLocation,
    );
    const [locationTree, setLocationTree] = React.useState<Array<Location>>([]);
    const fetchChildLocations = useFetchChildLocations();
    const trackLocationClick = useTrackLocationClick();

    const suggestedLocs = useLocationSuggestions(locationSearchQuery) as Array<Location>;
    const categories = useSelector(selectCategories);
    const activeCategory = useCategoryFilterValue();
    const suggestedLocations = useDisabledLocations(suggestedLocs, categories, activeCategory);
    const fullLocations = useFullLocationsHierarchy(
        ('hierarchy' in activeLocation && activeLocation.hierarchy) || [],
    );

    const finalSuggestedLocations = maxLevel
        ? suggestedLocations.filter((item) => item.level <= maxLevel)
        : suggestedLocations;

    React.useEffect(() => {
        scrollToTop();
        if (!('hasChildren' in activeLocation) || activeLocation.hasChildren) {
            makeCancelable(fetchChildLocations(activeLocation)).then((data: Location[]) => {
                if (data.length) {
                    setLocationTree(data);
                }
            });
        }
    }, [makeCancelable, activeLocation, fetchChildLocations]);

    useRestoreLocationDropdown({
        isOpen,
        location,
        activeLocation,
        setLocationTree,
        setActiveLocation,
    });

    const onLocationClicked = (clickedLocation: Location) => {
        setLocationSearchQuery('');
        trackLocationClick(Triggers.CLICK_LOCATION, clickedLocation);
        onLocationSelected(clickedLocation);
        setActiveLocation(clickedLocation);
        setIsOpen(false);
        dispatch(setGeoLocation(null, null));
    };

    const onGeoPositionSelected = () => {
        setLocationSearchQuery('');
        setIsOpen(false);
        trackLocationClick(Triggers.CLICK_CURRENT_LOCATION);
        if (onCurrentLocationSelected) {
            onCurrentLocationSelected();
        }
        setActiveLocation(location || settings.topLevelLocation);
    };

    const renderTrigger = (_: () => void, visible: boolean) => (
        <Flex fillContainer justifySpaceBetween alignCenter className={styles.menuButton}>
            {location && (
                <Flex
                    alignCenter
                    className={classNames(styles.locationContainer, {
                        [styles.fixedLocationTextContainer]: withLocationRadiusFilter,
                    })}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    {loading ? (
                        <LoadingSpinner className={styles.loading} />
                    ) : (
                        <LocationIcon className={styles.icon} />
                    )}
                    <div className={styles.textContainer}>
                        <Text.Large isDangerousHTML>{highlightedName}</Text.Large>
                    </div>
                </Flex>
            )}
            {withLocationRadiusFilter ? (
                <>
                    <IconClose className={styles.closeIcon} onClick={() => onLocationRemoved?.()} />
                    <LocationRadiusFilter onClick={() => setIsOpen(false)} />
                </>
            ) : (
                <img
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    src={iconArrowDown}
                    alt={'Select location'}
                    className={classNames(styles.iconArrowDown, {
                        [styles.iconArrowUp]: visible,
                    })}
                />
            )}
        </Flex>
    );

    return (
        <div className={styles.container} aria-label="Location input">
            <Dropdown
                triggerIsDismissble
                open={isOpen}
                renderTrigger={renderTrigger}
                onDismissed={() => setIsOpen(false)}
            >
                <div className={styles.content} aria-label="Locations dropdown" ref={contentDivRef}>
                    <LocationDropdownBackButton
                        fullLocations={fullLocations}
                        activeLocation={'hierarchy' in activeLocation ? activeLocation : null}
                        setActiveLocation={setActiveLocation}
                    />

                    <div className={styles.searchDiv}>
                        <Input
                            name={'search for location'}
                            placeholder={t(i18n)`search for location`}
                            onChange={(value) => setLocationSearchQuery(value)}
                            value={locationSearchQuery}
                        />
                    </div>
                    {!('hierarchy' in activeLocation && activeLocation?.hierarchy?.length > 1) && (
                        <div className={styles.entry}>
                            <CurrentLocationSelector
                                className={styles.currentLocationSelector}
                                onGeoPositionSelected={onGeoPositionSelected}
                                renderIcon={() => (
                                    <IconCurrentLocation className={styles.currentLocationIcon} />
                                )}
                            />
                        </div>
                    )}
                    {locationSearchQuery?.length > 0 && (
                        <LocationList
                            locations={finalSuggestedLocations}
                            onLocationClicked={onLocationClicked}
                        />
                    )}

                    {!locationSearchQuery && (
                        <Flex column>
                            <Text.Small className={styles.parentLocationText}>{t(
                                i18n,
                            )`Choose location`}</Text.Small>
                            <Flex
                                className={styles.locationElement}
                                onClick={() => onLocationClicked(activeLocation as Location)}
                            >
                                <Text.Large className={styles.mainLocationText}>
                                    {t(i18n)`See ads in all ${activeLocation?.name}`}
                                </Text.Large>
                            </Flex>
                        </Flex>
                    )}

                    {isMobile ? (
                        <RegionLocationsList onLocationClicked={onLocationClicked} />
                    ) : (
                        !locationSearchQuery &&
                        locationTree && (
                            <LocationTreeList
                                locationTree={locationTree}
                                setActiveLocation={setActiveLocation}
                                onLocationClicked={onLocationClicked}
                                setLocationTree={setLocationTree}
                            />
                        )
                    )}
                </div>
            </Dropdown>
        </div>
    );
};

export default NestedLocationDropdown;
