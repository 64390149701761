import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import isNil from 'lodash/isNil';

import { PropertyType } from 'strat/property/types';
import getPropertyCompletionStatusToTextMapping from '@app/branding/getPropertyCompletionStatusToTextMapping';

import type { BreadcrumbsElement } from './types';

const bedsLabel = (i18n: I18n, value: string | number) =>
    value === 0 ? t(i18n)`Studio` : t(i18n)`${value} Bed`;

const computeElementName = (
    element: BreadcrumbsElement,
    categoryName: string | null,
    purpose: string,
    withHomepage: boolean,
    i18n: I18n,
) => {
    if (element.level === 1) {
        if (!isNil(element.beds)) {
            return t(i18n)`${element.name} ${bedsLabel(i18n, element.beds)}`;
        }

        if (element.completionStatus) {
            const completionStatus = getPropertyCompletionStatusToTextMapping(
                i18n,
                element.completionStatus,
            );

            if (element.listingType === PropertyType.PROJECT) {
                return t(i18n)`${element.name} ${completionStatus} Projects`;
            }

            return completionStatus
                ? t(i18n)`${element.name} ${completionStatus} ${categoryName}`
                : t(i18n)`${element.name} ${categoryName}`;
        }
        return t(i18n)`${element.name} ${categoryName}`;
    }
    return element.name;
};

export default computeElementName;
