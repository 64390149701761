import { selectLanguage } from 'strat/i18n/language/selectors';
import {
    selectCommuteTimes,
    selectCommuteSortBy,
} from 'strat/search/mapBasedSearch/commute/selectors';
import { SortByValues } from 'strat/search/sortValues';
import type {
    MapBasedSearchListing,
    MapBasedSearchProperty,
} from 'strat/search/mapBasedSearch/types';
import { CommuteSortByValues } from 'strat/search/mapBasedSearch/commute/sortByOptions';
import { selectSortValue } from 'strat/search/state/selectors';
import {
    selectIsProductLabelScoreSortingEnabled,
    selectIsProjectMapSearchActive,
} from 'strat/search/selectors';

import { selectIsCommuteActive } from '../selectors';

import { MapBasedSearchProject } from './project/types';
import { CommuteTimes } from './commute/types';

const productScore = (listing: MapBasedSearchProperty, shouldUseCityLevelScoring: boolean) => {
    if (shouldUseCityLevelScoring && listing.productScore === 0) {
        return 1;
    }

    return listing.productScore;
};

const sortMapBasedSearchListings =
    (
        language: string,
        shouldUseCityLevelScoring: boolean,
        verifiedSortingFirst: boolean,
        shouldUseProductLabelScoreSorting = false,
    ) =>
    (a: MapBasedSearchProperty, b: MapBasedSearchProperty) => {
        const verifiedScoreDiff = a.verifiedScore - b.verifiedScore;
        const productScoreDiff =
            productScore(a, shouldUseCityLevelScoring) - productScore(b, shouldUseCityLevelScoring);
        const productLabelScoreDiff = a.productLabelScore - b.productLabelScore;
        const productLabelScoreSorting = shouldUseProductLabelScoreSorting
            ? productLabelScoreDiff
            : 0;
        const firstSorting = verifiedSortingFirst ? verifiedScoreDiff : productScoreDiff;
        const secondSorting = verifiedSortingFirst ? productScoreDiff : verifiedScoreDiff;

        if (productLabelScoreSorting) {
            return productLabelScoreSorting;
        }

        if (firstSorting) {
            return firstSorting;
        }
        if (secondSorting) {
            return secondSorting;
        }

        if (language === CONFIG.build.LANGUAGE_CODE) {
            if (a.score !== b.score) {
                return b.score - a.score;
            }
        } else if (a.scoreL1 !== b.scoreL1) {
            return b.scoreL1 - a.scoreL1;
        }

        return b.createdAt - a.createdAt;
    };

const sortingTime = (commuteTimes: any, id: any, commuteSortBy: any): number => {
    if (commuteSortBy === CommuteSortByValues.BALANCED) {
        return (commuteTimes.A?.[id] ?? 0) ** 2 + (commuteTimes.B?.[id] ?? 0) ** 2;
    }
    if (commuteSortBy === CommuteSortByValues.CLOSE_TO_A) {
        return (commuteTimes.A?.[id] ?? 0) * 1.5 + (commuteTimes.B?.[id] ?? 0);
    }
    return (commuteTimes.A?.[id] ?? 0) + (commuteTimes.B?.[id] ?? 0) * 1.5;
};

const sortMapBasedSearchListingsByCommuteTime =
    (commuteTimes: CommuteTimes, language: string, commuteSortBy: any) =>
    (a: MapBasedSearchProperty, b: MapBasedSearchProperty) => {
        const timeA = sortingTime(commuteTimes, a.id, commuteSortBy);
        const timeB = sortingTime(commuteTimes, b.id, commuteSortBy);

        const commuteSorting = timeA - timeB;

        if (commuteSorting !== 0) {
            return commuteSorting;
        }

        const verifiedScoreDiff = a.verifiedScore - b.verifiedScore;
        if (verifiedScoreDiff !== 0) {
            return verifiedScoreDiff;
        }

        const productScoreDiff = a.productScore - b.productScore;
        if (productScoreDiff !== 0) {
            return productScoreDiff;
        }

        if (language === CONFIG.build.LANGUAGE_CODE) {
            if (a.score !== b.score) {
                return b.score - a.score;
            }
        } else if (a.scoreL1 !== b.scoreL1) {
            return b.scoreL1 - a.scoreL1;
        }

        return b.createdAt - a.createdAt;
    };

const sortPropertiesCompareFn = (state: any) => {
    const language = selectLanguage(state);
    const sortBy = selectSortValue(state);
    const commuteTimes = selectCommuteTimes(state);
    const isCommuteActive = selectIsCommuteActive(state);
    const commuteSortBy = selectCommuteSortBy(state);
    const isProductLabelScoreSortingEnabled = selectIsProductLabelScoreSortingEnabled(state);
    const shouldUseProductLabelScoreSorting =
        sortBy === SortByValues.PRODUCT_LABEL_SCORE && isProductLabelScoreSortingEnabled;

    return isCommuteActive
        ? sortMapBasedSearchListingsByCommuteTime(commuteTimes, language, commuteSortBy)
        : sortMapBasedSearchListings(
              language,
              sortBy === SortByValues.CITY_LEVEL_SCORE,
              sortBy === SortByValues.VERIFIED_SCORE,
              shouldUseProductLabelScoreSorting,
          );
};

// Use the same sorting logic as the customRanking on the projects index
// Current order: desc(hasPropertyAds), asc(completionDate), desc(completionPercentage), desc(externalID)
const sortProjectsCompareFn = (a: MapBasedSearchProject, b: MapBasedSearchProject) => {
    if (a.hasPropertyAds !== b.hasPropertyAds) {
        return a.hasPropertyAds && !b.hasPropertyAds ? -1 : 1;
    }

    if (a.completionDate !== b.completionDate) {
        if (!a.completionDate || !b.completionDate) {
            return b.completionDate - a.completionDate;
        }

        return a.completionDate - b.completionDate;
    }

    if (a.completionPercentage !== b.completionPercentage) {
        return b.completionPercentage - a.completionPercentage;
    }

    return b.externalID.localeCompare(a.externalID);
};

export const sortListings = (
    listings: MapBasedSearchListing[],
    state: any,
): MapBasedSearchListing[] => {
    const isProjectMapSearchActive = selectIsProjectMapSearchActive(state);
    if (isProjectMapSearchActive) {
        return (listings as MapBasedSearchProject[]).sort(sortProjectsCompareFn);
    }

    return (listings as MapBasedSearchProperty[]).sort(sortPropertiesCompareFn(state));
};
