import type { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { APIError, APIErrorReason } from 'strat/api/error';
import { selectLanguage } from 'strat/i18n/language';
import { selectSavedSearchParams } from '@app/search/savedSearches/selectors';
import SavedSearchesAPI from '@app/api/savedSearchesAPI';
import { GlobalState } from 'strat/state';
import { selectUserID } from 'strat/user/selectors';

import type { SavedSearchData, SavedSearchParams } from '../types';

import Actions from './actions';
import type { SetSavedSearchesAction } from './actions';

/**
 * Sets the specified saved searches in the Redux store.
 */
export const setSavedSearches = (
    savedSearches: Array<SavedSearchData>,
): SetSavedSearchesAction => ({
    type: Actions.SET_SAVED_SEARCHES,
    savedSearches,
});

/**
 * Adds a "saved search" for the current user with the specified name.
 */
export const addSavedSearch =
    (name: string): ThunkAction<Promise<unknown>, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const state = getState();
        const params = selectSavedSearchParams(state) as SavedSearchParams;
        const language = selectLanguage(state);
        const userExternalID = selectUserID(state);

        if (!userExternalID) {
            throw new Error('Missing userID');
        }

        return new SavedSearchesAPI(language)
            .addSavedSearch(userExternalID, name, params)
            .then((response) => {
                if (response.status === 412) {
                    throw new APIError('Adding saved search failed validation', {
                        reason: APIErrorReason.PRECONDITION_FAILED,
                        response,
                        id: userExternalID,
                        params: JSON.stringify(params),
                    });
                }

                if (response.status === 409) {
                    throw new APIError('Adding saved search failed validation', {
                        reason: APIErrorReason.ALREADY_EXISTS,
                        response,
                        userExternalID: userExternalID,
                        params: JSON.stringify(params),
                    });
                }

                dispatch({
                    type: Actions.ADD_SAVED_SEARCH,
                    search: response.data,
                });

                return response;
            });
    };

/**
 * Removes the "saved search" for the current user with
 * the specified id.
 */
export const removeSavedSearch =
    (id: number): ThunkAction<Promise<unknown>, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const state = getState();
        const userExternalID = selectUserID(state);

        if (!userExternalID) {
            throw new Error('Missing userID');
        }

        return new SavedSearchesAPI().removeSavedSearch(userExternalID, id).then((response) => {
            dispatch({
                type: Actions.REMOVE_SAVED_SEARCH,
                id,
            });

            return response;
        });
    };
