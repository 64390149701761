import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { isCanceled } from 'strat/util';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';

import Page from 'horizontal/pages/page';
import { getStratAPI } from 'horizontal/api';
import type { PaymentOrder } from 'horizontal/payment';
import { PaymentOrderStatus } from 'horizontal/payment';
import { PaymentSource } from '@app/payment/types';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type PaymentPaymobMWalletRouteParams = {
    readonly orderExternalID: string;
    readonly paymentSource: string;
};

class PaymentPaymobMWalletRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.PAYMENT_PAYMOB_MWALLET, [
            [
                '^/payment/paymob/mobileWallet',
                {
                    name: 'paymentSource',
                    pattern: `(?:/(${[
                        PaymentSource.ORANGE_CASH,
                        PaymentSource.VODAFONE_CASH,
                        PaymentSource.PAYMOB_WALLET,
                        PaymentSource.ETISALAT_CASH,
                        PaymentSource.BANK_WALLET,
                    ].join('|')}))?`,
                },
                {
                    name: 'orderExternalID',
                    pattern: '/([0-9]+)',
                },

                '$',
            ],
        ]);
    }
    createURL(
        params: PaymentPaymobMWalletRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/payment/paymob/mobileWallet/${params?.paymentSource}/${params?.orderExternalID}`,
        };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { orderExternalID, paymentSource },
            },
            redux: {
                store: { getState },
            },
        } = context;

        if (!orderExternalID) {
            this.renderNotFoundPage(context);
            return;
        }

        const promise = this.cancelable(getStratAPI(getState()).getOrder(orderExternalID)).then(
            ({ data: order, status }: { data: PaymentOrder; status: number }) => {
                if (status !== 200 || order.status === PaymentOrderStatus.ACTIVE) {
                    this.renderNotFoundPage(context);
                    return;
                }
                context.rendering.renderPage(Page.PAYMENT_PAYMOB_MWALLET, {
                    order,
                    paymentSource,
                });
            },
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }
}

export default new PaymentPaymobMWalletRoute();
