import * as React from 'react';
import { withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';

import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatAd } from 'horizontal/chat/types';
import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';

import MakeOfferForm from '../makeOfferForm';

type OwnProps = {
    readonly ad: ChatAd | null | undefined;
    readonly adPrice: number;
};
type Props = GTMLeadTrackingProps & OwnProps;

const Initiated = ({ ad, adPrice, trackChatLead }: Props) => {
    const sdk = useChatSDK();
    const getNegotiationActions = sdk.useSendNegotiationMessagesMap();
    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);

    return (
        <MakeOfferForm
            ad={ad}
            onSendClick={async (value?: number | null) => {
                const actions = await getNegotiationActions();
                const submitOffer = actions[ChatNegotiationState.BUYER_MADE_OFFER];

                if (!submitOffer) {
                    return;
                }

                const messageSentResponse = await submitOffer(value ?? undefined);

                if (messageSentResponse) {
                    trackSentChatMessageEvents(messageSentResponse, ChatMessageType.NEGOTIATION);
                }
            }}
            adPrice={adPrice}
        />
    );
};

export default withGTMLeadTracking<Props, ChatAd | null | undefined>(React.memo<Props>(Initiated));
