import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';

import type { GlobalState } from 'horizontal/state';
import type { LiteAd } from 'horizontal/types';

const selectVerticalsPromotedAds = createSelector(
    (state: GlobalState) => state?.verticalsPromotedAds || EMPTY_OBJECT,
    (verticalsPromotedAds) => verticalsPromotedAds.data || (EMPTY_ARRAY as LiteAd[]),
);

export { selectVerticalsPromotedAds };
