import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import EMPTY_OBJECT from 'strat/empty/object';

import { CategoryFieldRole } from 'horizontal/types';
import type { LiteCategory, CategoryFieldsFilters } from 'horizontal/types';

import capitalizeWords from './capitalizeWords';
import useExtraFieldsLabels from './useExtraFieldsLabels';

type Props = {
    readonly category?: LiteCategory | null | undefined;
    readonly freeTextQuery?: string | null | undefined;
    readonly extraFields?: CategoryFieldsFilters;
};

type Options = {
    useShortNames?: boolean;
};

const useTerm = (
    { category, freeTextQuery, extraFields }: Props,
    { useShortNames }: Options = EMPTY_OBJECT as Options,
): string => {
    const i18n = useI18n();

    const [labels, roles] = useExtraFieldsLabels(
        category?.id,
        extraFields,
        CategoryFieldRole.INCLUDED_IN_TITLE,
    );
    const formattedLabels = labels.join(' ');
    const overrideCategoryName = roles.has(CategoryFieldRole.OVERRIDES_CATEGORY_NAME);

    if (freeTextQuery) {
        return capitalizeWords(freeTextQuery);
    }

    const categoryName = useShortNames
        ? category?.nameShort || category?.name || ''
        : category?.name || '';
    if (formattedLabels && categoryName && !overrideCategoryName) {
        return t(i18n)`${formattedLabels} ${categoryName}`;
    }

    return formattedLabels || categoryName;
};

export default useTerm;
