import * as React from 'react';
import classNames from 'classnames';
import { OffScreen } from 'strat/modal';
import { Flex, Text } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import SuccessIcon from 'horizontal/assets/icons/iconBadgeGood_2.svg';
import { DialogNames } from 'horizontal/dialogs';

import styles from './styles/toast.cssm';

export type ConfigProps = {
    message: string;
    secondaryMessage?: string;
    timeoutMS?: number;
    className?: string;
    disclaimer?: boolean;
};

const Toast = ({
    onVisibilityChanged,
    message,
    secondaryMessage,
    timeoutMS = 3000,
    className,
    visible,
    disclaimer = false,
}: {
    visible: boolean;
    onVisibilityChanged: (visibleFlag: boolean) => void;
} & ConfigProps) => {
    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;
        if (visible) {
            timeout = setTimeout(() => onVisibilityChanged(false), timeoutMS);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onVisibilityChanged, visible, timeoutMS]);

    if (!visible) {
        return null;
    }

    return (
        <OffScreen name={DialogNames.TOAST}>
            <Flex
                alignCenter
                justifySpaceBetween
                className={classNames(
                    styles.toast,

                    {
                        [styles.visible]: visible,
                        [styles.disclaimer]: disclaimer,
                        [styles.extraHeight]: !!secondaryMessage,
                    },
                    className,
                )}
            >
                <Flex alignCenter className={styles.messageContainer}>
                    {!disclaimer && (
                        <SuccessIcon
                            className={classNames(styles.successIcon, {
                                [styles.largeIcon]: !!secondaryMessage,
                            })}
                        />
                    )}
                    <Flex
                        column={!!secondaryMessage}
                        className={classNames({ [styles.messages]: !!secondaryMessage })}
                    >
                        <Text.Regular
                            bold={!!secondaryMessage}
                            className={classNames(styles.mainMessage, {
                                [styles.largeMainMessage]: !!secondaryMessage,
                            })}
                        >
                            {message}
                        </Text.Regular>
                        {secondaryMessage && <Text.Regular>{secondaryMessage}</Text.Regular>}
                    </Flex>
                </Flex>
                <IconClose className={styles.dismiss} onClick={() => onVisibilityChanged(false)} />
            </Flex>
        </OffScreen>
    );
};

export default Toast;
