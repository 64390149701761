import type { Dispatch } from 'redux';

import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import { InternalAPI } from 'strat/api';
import type { FetchState } from 'strat/fetcher';

const factory = new FetcherFactory(
    'listingEvaluation',
    (params: Record<any, any>) =>
        new InternalAPI()
            .getListingEvaluation(InternalAPI.buildQueryString(params))
            .catch(() => ({ data: {}, status: 404 })),
    {
        successCodes: [200, 404],
    },
);

const Actions = Object.freeze({
    TOGGLE_EVALUATION_ACTIVE: 'LISTING_EVALUATION/TOGGLE_EVALUATION_ACTIVE',
});

type ToggleEvaluationActive = {
    type: 'LISTING_EVALUATION/TOGGLE_EVALUATION_ACTIVE';
    isEvaluationActive: boolean;
};

export type State = FetchState & {
    isEvaluationActive: boolean;
};

const defaultState: State = {
    ...buildDefaultState(),
    isEvaluationActive: false,
};

const fetchEvaluation = factory.creator();

const listingEvaluationFactoryReducer = factory.reducer();

const listingEvaluationReducer = (
    state: State | null | undefined = defaultState,
    action: ToggleEvaluationActive,
) => {
    switch (action.type) {
        case Actions.TOGGLE_EVALUATION_ACTIVE:
            return {
                ...state,
                isEvaluationActive: action.isEvaluationActive,
            };
        default:
            return listingEvaluationFactoryReducer(state, action);
    }
};

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const toggleEvaluationActive = (isEvaluationActive: boolean) => (dispatch: Dispatch) =>
    dispatch({ type: Actions.TOGGLE_EVALUATION_ACTIVE, isEvaluationActive });

export { fetchEvaluation, toggleEvaluationActive };

export default listingEvaluationReducer;
