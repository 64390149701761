import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Flex } from 'strat/components';
import EMPTY_ARRAY from 'strat/empty/array';
import { Text } from 'strat/components';

import type { Category } from 'horizontal/types';
import CategoryHelpText from '@app/adCreation/categoryHelpText';
import { selectCategories } from 'horizontal/categories';

import Context from '../context';

import styles from './styles/subcategoryPicker.cssm';
import SubCategoryList from './subCategoryList';

interface Props {
    numberOfLevels: number;
    selectedCategories: Array<Category | null>;
    setSelectedCategories: React.Dispatch<React.SetStateAction<Array<Category | null>>>;
    onLeafCategorySelected?: () => void;
    displayCategories?: Category[] | null;
}

const SubcategoryPicker = ({
    numberOfLevels,
    selectedCategories,
    setSelectedCategories,
    onLeafCategorySelected,
    displayCategories,
}: Props): React.ReactElement | null => {
    const [, setActiveCategory] = React.useContext(Context);
    const fullCategoriesTree = useSelector(selectCategories);
    const categories =
        displayCategories && displayCategories.length ? displayCategories : fullCategoriesTree;
    const isSelected = (level: number) => {
        return (category: Category) => {
            return category.id === selectedCategories[level]?.id;
        };
    };

    const onCategoryChanged = (level: number) => {
        return (newCategory: Category) => {
            const newCategories = selectedCategories.map((oldCategory, index) => {
                if (index < level) {
                    return oldCategory;
                }
                if (index === level) {
                    return newCategory;
                }
                return null;
            });

            setSelectedCategories(newCategories);

            if (!newCategory.children?.length) {
                setActiveCategory(newCategory);
                if (onLeafCategorySelected) {
                    onLeafCategorySelected();
                }
            }
        };
    };

    return (
        <>
            <div className={styles.title}>
                <Text.Large bold>
                    <Trans>Choose a category</Trans>
                </Text.Large>
            </div>
            <div
                style={{
                    gridTemplateColumns: `repeat(${numberOfLevels}, 1fr)`,
                }}
                className={styles.columns}
            >
                {[...selectedCategories.keys()].map((level) => {
                    const parentCategory: Category | null | undefined =
                        selectedCategories[level - 1];

                    return (
                        <Flex
                            className={styles.column}
                            column
                            justifySpaceBetween
                            stretchHeight
                            key={level}
                        >
                            <Flex column className={styles.columnListContent}>
                                <SubCategoryList
                                    borderless
                                    categories={
                                        level > 0
                                            ? parentCategory?.children || EMPTY_ARRAY
                                            : categories
                                    }
                                    onClick={onCategoryChanged(level)}
                                    isSelected={isSelected(level)}
                                />
                            </Flex>
                            <CategoryHelpText category={parentCategory} />
                        </Flex>
                    );
                })}
            </div>
        </>
    );
};

export default SubcategoryPicker;
