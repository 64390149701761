import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { buildAllCanonicalURLs } from 'strat/routing';
import settings from '@app/branding/settings';

import { useSellerProfile } from 'horizontal/seller/hooks';
import SellerPageRoute from 'horizontal/routes/sellerProfileRoute';

import { getHomePageDescription } from '../home/seo';

import CommonHead from './commonHead';

const SellerPageHead = () => {
    const i18n = useI18n();
    const description = getHomePageDescription(i18n);
    const domainName = settings.getBrandName(i18n);
    const seller = useSellerProfile();

    const { url, alternateURLs } = buildAllCanonicalURLs(
        // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
        SellerPageRoute.createURL({ externalID: seller && seller.id }).pathname,
        i18n.locale,
    );

    return (
        <>
            <title>{t(i18n)`${seller?.name || 'Unknown'} | ${domainName}`}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="noindex, nofollow" />
            <CommonHead url={url} alternateURLs={alternateURLs} />
        </>
    );
};

export default SellerPageHead;
