import isNil from 'lodash/isNil';

const ScheduleScope = Object.freeze({
    AFTER_RENDER: 'AFTER_RENDER',
    AFTER_CHANGE_ROUTE: 'AFTER_CHANGE_ROUTE',
    SYNC: 'SYNC',
});

type Task = (args?: {}) => void;

/**
 * Object containing queues for all schedule scopes {@see ScheduleScope}
 */
const tasks: Partial<Record<Values<typeof ScheduleScope>, Array<Task>>> = {};

const timeoutHandlers: Partial<Record<Values<typeof ScheduleScope>, number | null | undefined>> =
    {};

/**
 * Task scheduler for executing tasks in different moments through the application
 */
const TaskScheduler = Object.freeze({
    schedule: (task: Task, scheduleScope: Values<typeof ScheduleScope>): void => {
        if (scheduleScope === ScheduleScope.SYNC) {
            task();
            return;
        }

        if (!tasks[scheduleScope]) {
            tasks[scheduleScope] = [];
        }

        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        if (!tasks[scheduleScope].includes(task)) {
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            tasks[scheduleScope].push(task);
        }
    },

    flush: (scheduleScope: Values<typeof ScheduleScope>, args?: {}): void => {
        if (!isNil(timeoutHandlers[scheduleScope])) {
            // @ts-expect-error - TS2769 - No overload matches this call.
            clearTimeout(timeoutHandlers[scheduleScope]);
            timeoutHandlers[scheduleScope] = null;
        }

        if (tasks[scheduleScope]) {
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            while (tasks[scheduleScope].length > 0) {
                // get task from queue and executes it
                // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2722 - Cannot invoke an object which is possibly 'undefined'.
                tasks[scheduleScope].shift()(args);
            }
        }
    },

    run: (scheduleScope: Values<typeof ScheduleScope>, delay = 0, args?: {}): void => {
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        if (!tasks[scheduleScope] || tasks[scheduleScope].length === 0) {
            return;
        }

        if (!timeoutHandlers[scheduleScope]) {
            // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
            timeoutHandlers[scheduleScope] = setTimeout(
                () => TaskScheduler.flush(scheduleScope, args),
                delay,
            );
        }
    },
});

export default TaskScheduler;

export { ScheduleScope };
