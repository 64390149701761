import * as React from 'react';

import IconLocation from 'horizontal/assets/icons/iconLocation.svg';

type Props = {
    readonly className: string | undefined;
};

const LocationSearchIcon = ({ className }: Props) => {
    return <IconLocation className={className} />;
};

export default LocationSearchIcon;
