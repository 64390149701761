import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { selectSearchLoadingState } from '@sector-labs/fe-search-redux/state';

import { selectPropertyHits } from 'strat/search/selectors';

const selectPropertyHitsData = createSelector(
    [selectPropertyHits, selectSearchLoadingState],
    (hits, { loaded, loading }) => ({ hits, loaded, loading }),
);

// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '((...args: unknown[]) => {}) | { [x: string]: any; }'.
const connectPropertyHits = connect((state) => selectPropertyHitsData(state));

export default connectPropertyHits;
