import { t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { MonthlyProjectPaymentPlan } from 'strat/project/types';

import ordinalNumber from './ordinalNumber';

const ensureFormatAndParseDate = (date: string | null) => {
    // Format for a valid date: YYYY-MM-DD
    const dateFormatRegExp = /^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|[12][0-9]|0[1-9])$/;
    const isFormatValid = dateFormatRegExp.test(date || '');

    return date && isFormatValid ? Date.parse(date) : undefined;
};

export type MonthlyPaymentPlanMilestone = {
    title: string;
    valuePercentage: number;
    dueDate?: number | null;
};

const useMonthlyPaymentTimeline = (
    paymentPlan: MonthlyProjectPaymentPlan,
): Array<MonthlyPaymentPlanMilestone> => {
    const i18n = useI18n();
    const timeline = [];

    const downPayment = paymentPlan.downPayment;
    if (downPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`Down Payment`,
            valuePercentage: downPayment.valuePercentage,
            dueDate: ensureFormatAndParseDate(downPayment.paymentMonth),
        });
    }

    paymentPlan.installments?.forEach((installment, index) => {
        if (installment.valuePercentage) {
            timeline.push({
                title: t(i18n)`${ordinalNumber(index + 1)} Installment`,
                valuePercentage: installment.valuePercentage,
                dueDate: ensureFormatAndParseDate(installment.paymentMonth),
            });
        }
    });

    const handoverPayment = paymentPlan.handoverPayment;
    if (handoverPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`On Handover`,
            valuePercentage: handoverPayment.valuePercentage,
            dueDate: ensureFormatAndParseDate(handoverPayment.paymentMonth),
        });
    }

    const postHandoverPayment = paymentPlan.postHandoverPayment;
    if (postHandoverPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`Post Handover`,
            valuePercentage: postHandoverPayment.valuePercentage,
            dueDate: ensureFormatAndParseDate(postHandoverPayment.paymentMonth),
        });
    }

    return timeline;
};

export default useMonthlyPaymentTimeline;
