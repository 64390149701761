import * as React from 'react';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import WhatsappIcon from 'strat/assets/icons/iconWhatsapp.svg';
import { Button } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { GTMLeadTrackingProps, ViewSections, withGTMLeadTracking } from 'strat/gtm';

import { LiteAd } from 'horizontal/types';

import { useOnWhatsappCTACLick } from '../adDetails';

import styles from './styles/contactButtons.cssm';

type Props = GTMLeadTrackingProps & {
    readonly ad: LiteAd;
    readonly viewSection: Values<typeof ViewSections>;
    readonly fullWidth?: boolean;
    readonly showLabel?: boolean;
};

const WhatsAppCTA = ({
    ad,
    viewSection,
    trackWhatsAppLead,
    fullWidth,
    showLabel = true,
}: Props) => {
    const i18n = useI18n();
    const onWhatsAppCTAClick = useOnWhatsappCTACLick({
        ad,
        trackWhatsAppLead,
        viewSection,
        shouldOpenCTADialog: true,
    });

    return (
        <div
            className={classNames(styles.whatsAppButtonWrapper, {
                [styles.fullWidth]: fullWidth,
                [styles.hideLabel]: !showLabel,
            })}
            aria-label="WhatsApp"
        >
            <Button
                stretch
                className={styles.whatsAppButton}
                small
                smallIcon
                renderIcon={() => <WhatsappIcon className={styles.icon} />}
                onClick={onWhatsAppCTAClick}
            >
                {showLabel && t(i18n)`WhatsApp`}
            </Button>
        </div>
    );
};

export default withGTMLeadTracking<Props, LiteAd>(WhatsAppCTA);
