//@ts-nocheck
import 'whatwg-fetch';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import cookie from 'cookie';

import type { APIResponse } from 'strat/api/types';

import withTimeoutSignal from './withTimeoutSignal';

// do not change the condition there.. keep it simple,
// this prevents the 'http' module from ending up in
// the browser bundle
let httpsAgent = null;
let httpAgent = null;
if (process.env.IS_SERVER) {
    const http = require('http'); // eslint-disable-line global-require
    const https = require('https'); // eslint-disable-line global-require

    const agentConfig = {
        keepAlive: true,
        maxSockets: 10,
        keepAliveMsecs: 1000,
    } as const;

    httpsAgent = new https.Agent(agentConfig);
    httpAgent = new http.Agent(agentConfig);
}

export type RequestOptions = {
    timeoutMS?: number;
    disableCaching?: boolean;
};

/**
 * Base class for a container for API requests.
 */
class HTTPApi {
    /**
     * The language for which the content should be returned.
     */
    language: string;

    /**
     * Initializes a new instance of {@see HTTPApi}.
     */
    constructor(language: string | null | undefined = CONFIG.build.LANGUAGE_CODE) {
        this.language = language;
    }

    /**
     * Constructs headers to pass along in every request
     * for authentication.
     *
     * Derived classes should override this.
     */
    authHeader() {
        return {};
    }

    /**
     * Takes a object and transform it to a HTTP query string.
     * @param {Object} parameters The parameters to transform into a query string.
     * @returns {string} The query string.
     */
    static buildQueryString(parameters: any) {
        return Object.keys(parameters)
            .filter((key) => parameters[key] !== undefined && parameters[key] !== null)
            .map((key) => `${key}=${encodeURIComponent(parameters[key])}`)
            .join('&');
    }

    /**
     * Gets the current CSRF token.
     * @retuns {string}
     */
    static getCSRFToken() {
        return cookie.parse(document.cookie).csrftoken;
    }

    static buildRequest(url: any, parameters = null, init = {}, disableCaching = false) {
        const parametersSeparatorPosition = url.indexOf('?');
        const baseUrl =
            parametersSeparatorPosition === -1
                ? url
                : url.substring(0, parametersSeparatorPosition);
        const existingParameters =
            parametersSeparatorPosition === -1 ? '' : url.substring(parametersSeparatorPosition);
        const additionalParametersSeparator = existingParameters ? '&' : '?';
        const encodedParameters = parameters
            ? `${additionalParametersSeparator}${HTTPApi.buildQueryString(parameters)}`
            : '';
        const requestUrl = encodeURI(baseUrl) + existingParameters + encodedParameters;

        const config = {
            method: 'GET',
            credentials: 'same-origin',
            cache: 'default',
            ...init,
            headers: omitBy(
                {
                    'X-CSRFToken': HTTPApi.getCSRFToken(),
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    ...(init.headers || {}),
                },
                isNil,
            ),
        } as const;

        if (process.env.IS_SERVER && !disableCaching) {
            config.agent = requestUrl.startsWith('https') ? httpsAgent : httpAgent;
        }

        return { requestUrl, config };
    }

    static parseResponse(response: any): APIResponse<any> {
        return response.text().then((text) => {
            let data = text;
            try {
                data = text.length > 0 ? JSON.parse(text) : text;
            } catch (e: any) {} // eslint-disable-line

            return {
                data,
                status: response.status,
                headers: response.headers,
            };
        });
    }

    /**
     * Makes a request to the Strat API.
     * @param url {string} The URL to request (this must be relative).
     * @param parameters {Object} The GET parameters to pass.
     * @param init (Object) Extra init fields for fetching the data
     * @param disableCaching {boolean} Whether the caching should be disabled.
     * @returns {Promise}
     */
    request(
        url: any,
        parameters: Record<string, unknown> | null = null,
        init = {},
        disableCaching = false,
    ): Promise<APIResponse<any>> {
        const { requestUrl, config } = this.constructor.buildRequest(
            url,
            parameters,
            {
                ...(init || {}),
                headers: {
                    ...this.authHeader(),
                    ...(init?.headers || {}),
                },
            },
            disableCaching,
        );

        return fetch(requestUrl, config).then((response) => {
            if (!response) {
                return { data: null, status: 500 };
            }

            return this.constructor.parseResponse(response);
        });
    }

    requestBinary(
        url: any,
        parameters = null,
        init = {},
        disableCaching = false,
    ): Promise<APIResponse<any>> {
        const { requestUrl, config } = this.constructor.buildRequest(
            url,
            parameters,
            {
                ...(init || {}),
                headers: {
                    ...this.authHeader(),
                    ...(init?.headers || {}),
                },
            },
            disableCaching,
        );

        return fetch(requestUrl, config).then((response) => {
            if (!response) {
                return { data: null, status: 500, headers: new Headers() };
            }

            return response.arrayBuffer().then((buffer) => {
                return { data: buffer, status: response.status, headers: response.headers };
            });
        });
    }

    requestStream(
        url: string,
        parameters = null,
        init: RequestInit = {},
        options: RequestOptions = {},
    ) {
        const { requestUrl, config } = this.constructor.buildRequest(
            url,
            parameters,
            {
                ...(init || {}),
                headers: {
                    ...this.authHeader(),
                    ...(init?.headers || {}),
                },
            },
            options.disableCaching,
        );

        if (!options.timeoutMS) {
            return fetch(requestUrl, config).then((response) => {
                if (!response) {
                    return { data: null, status: 500 } as Response;
                }

                return response;
            });
        }

        return withTimeoutSignal(options.timeoutMS, async (signal: AbortSignal) => {
            return fetch(requestUrl, { ...config, signal }).then((response) => {
                if (!response) {
                    return { data: null, status: 500 } as Response;
                }

                return response;
            });
        });
    }

    /**
     * Makes a POST request to the specified URL.
     * @param url {string} The URL to request.
     * @param body {Object} The body of the request.
     * @returns {Promise}
     */
    post(url: any, body: any): Promise<APIResponse<unknown>> {
        const config = {
            method: 'POST',
            credentials: 'same-origin',
            body: this.constructor.buildQueryString(body),
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                ...this.authHeader(),
            },
        } as const;

        return fetch(url, config).then(this.constructor.parseResponse);
    }

    patch(url: any, body: any): Promise<APIResponse<unknown>> {
        const config = {
            method: 'PATCH',
            credentials: 'same-origin',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json; charset=UTF-8',
                ...this.authHeader(),
            },
        } as const;

        return fetch(url, config).then(this.constructor.parseResponse);
    }

    put(url: any, body: any, options: RequestOptions = {}): Promise<APIResponse<unknown>> {
        const config = {
            method: 'PUT',
            credentials: 'same-origin',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json; charset=UTF-8',
                ...this.authHeader(),
            },
        } as const;

        if (!options.timeoutMS) {
            return fetch(url, config).then(this.constructor.parseResponse);
        }

        return withTimeoutSignal(options.timeoutMS, async (signal: AbortSignal) => {
            return fetch(url, { ...config, signal }).then(this.constructor.parseResponse);
        });
    }

    postJSON(
        url: string,
        body: any,
        init: RequestInit = {},
        options: RequestOptions = {},
    ): Promise<APIResponse<any>> {
        const config = {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(body),
            ...init,
            headers: omitBy(
                {
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json; charset=UTF-8',
                    ...this.authHeader(),
                    ...(init.headers || {}),
                },
                isNil,
            ),
        } as const;

        if (!options.timeoutMS) {
            return fetch(url, config).then(this.constructor.parseResponse);
        }

        return withTimeoutSignal(options.timeoutMS, async (signal: AbortSignal) => {
            return fetch(url, { ...config, signal }).then(this.constructor.parseResponse);
        });
    }
}

export default HTTPApi;
