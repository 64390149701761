// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { createRandomEliteAndFeaturedFirstSlottingConfig } from '@sector-labs/strat-horizontal-core';
// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';

import { selectDefaultSortOption } from 'horizontal/search/sorting';
import { createRandomSeed } from 'horizontal/search/execution';

const HITS_PER_PAGE = 4;

const createCategoryWithHitsSearchSettings = (
    indexName: string,
    getState: GetState,
): SearchSettings | null | undefined => {
    /**
     * Returns the settings for fetching the first HITS_PER_PAGE listings.
     * It fetches some random featured ads first and in case there are not enough, it completes with regular ads.
     * It doesn't care about the total count of ads.
     */
    return {
        hitsPerPage: HITS_PER_PAGE,
        activeSlotRule: 'default',
        sort: selectDefaultSortOption(getState()),
        randomSeed: createRandomSeed(),
        slotRules: createRandomEliteAndFeaturedFirstSlottingConfig({
            indexName,
            hitsPerSlot: [HITS_PER_PAGE, 0, 0],
        }),
        exhaustiveNbHits: HITS_PER_PAGE,
    };
};

export default createCategoryWithHitsSearchSettings;
