import type { LiteCategory } from 'horizontal/types';
import HorizontalCategory from 'horizontal/branding/category';

const Category = {
    ...HorizontalCategory,
    BUSES_VANS_TRUCKS: 'buses-vans-trucks',
    TRACTORS_TRAILERS: 'tractors-trailers',
    RICKSHAW_CHINGCHI: 'rickshaw-chingchi',
    SCOOTERS: 'scooters',
    HOUSES: 'houses',
    APARTMENTS_FLATS: 'apartments-flats',
    PORTIONS_FLOORS: 'portions-floors',
    PORTIONS_FLOORS_RENT: 'portions-floors-rent',

    isOfBusesType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.BUSES_VANS_TRUCKS),

    isOfRickshawType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.RICKSHAW_CHINGCHI),

    isOfTractorsType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.TRACTORS_TRAILERS),

    isOfScootersType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.SCOOTERS),

    isOfHousesType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.HOUSES) ||
        HorizontalCategory.isOfType(category, HorizontalCategory.HOUSE_FOR_RENT),

    isOfApartmentsFlatsType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.APARTMENTS_FLATS) ||
        HorizontalCategory.isOfType(category, HorizontalCategory.APARTMENT_FOR_RENT),

    isOfPortionsFloorsType: (category?: LiteCategory | null) =>
        HorizontalCategory.isOfType(category, Category.PORTIONS_FLOORS) ||
        HorizontalCategory.isOfType(category, Category.PORTIONS_FLOORS_RENT),
} as const;

export default Category;
