import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import { useFormattedPrice } from 'horizontal/util';
import { PaymentProductPackageOffer } from 'horizontal/payment/types';

import styles from './styles/package.cssm';

type Props = {
    readonly productPackageOffer: PaymentProductPackageOffer;
};

const Package = ({ productPackageOffer }: Props) => {
    const price = productPackageOffer.discountPrice || productPackageOffer.price;

    return (
        <Flex
            justifySpaceBetween
            alignCenter
            key={productPackageOffer.packageOfferID}
            className={styles.package}
        >
            <Text.Base className={styles.packageName}>
                {productPackageOffer.cartQuantity > 1 ? (
                    <Trans>
                        <Text.Small bold>{productPackageOffer.cartQuantity} &times; </Text.Small>
                        {productPackageOffer.productPackage.name}
                    </Trans>
                ) : (
                    productPackageOffer.productPackage.name
                )}
            </Text.Base>
            <Text.Small gray>{useFormattedPrice(price) || '-'}</Text.Small>
        </Flex>
    );
};

export default Package;
