//@ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import styles from './styles/linkCopied.cssm';

type Props = {
    toastStyle?: string;
    message?: React.ReactElement;
};

const LinkCopied = ({ toastStyle, message = <Trans>Link copied</Trans>, ...props }: Props) => (
    <div
        {...props}
        className={classNames(styles.linkCopied, {
            [toastStyle]: toastStyle,
        })}
        aria-label="Link copied"
    >
        <div className={styles.anchorArrow} />
        <div className={styles.message}>{message}</div>
    </div>
);

export default LinkCopied;
