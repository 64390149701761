import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { RouteNames } from 'strat/routes';
import { catchCanceled } from 'strat/util';
import { selectUserExternalID } from 'strat/user/session';

import Page from 'horizontal/agencyPortal/pages/page';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { AdState } from 'horizontal/types';
import type { Ad } from 'horizontal/types';
import { ProductPurchaseStatus, selectConsumableLimitPackagesByAd } from 'horizontal/packages';
import { getStratAPI } from 'horizontal/api';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

export type ApplyAdLimitPackageRouteParams = {
    externalID: string;
};

type ExpectedAdReturn = Ad | null | undefined;

class AgencyPortalApplyAdLimitRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_APPLY_AD_LIMIT, [
            [
                '^/agencyPortal/posting/limited/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({ externalID }: ApplyAdLimitPackageRouteParams): EnhancedLocation {
        return { pathname: `/agencyPortal/posting/limited/${externalID}` };
    }

    // @ts-expect-error - TS2416 - Property 'onEnter' in type 'AgencyPortalNotFoundRoute' is not assignable to the same property in base type 'Route'.
    onEnter(context: RoutingContext): void {
        if (!ensureCanAccessAgencyPortal(context)) {
            return;
        }
        const {
            redux: {
                store: { getState },
            },
        } = context;

        context.rendering.renderPage(Page.AGENCY_PORTAL_APPLY_AD_LIMIT, {
            ad: null,
            productPurchases: null,
        });

        context.promise.wait(
            this.fetchData(context)
                .then(({ ad }: { ad: ExpectedAdReturn }) => {
                    if (!ad) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    const productPurchases = selectConsumableLimitPackagesByAd(getState(), { ad });
                    if (productPurchases && productPurchases.length) {
                        context.rendering.renderPage(Page.AGENCY_PORTAL_APPLY_AD_LIMIT, {
                            ad,
                            productPurchases,
                        });
                        return;
                    }

                    context.rendering.renderPage(Page.AGENCY_PORTAL_NO_LIMIT_PACKAGES, { ad });
                })
                .catch(catchCanceled),
        );
    }

    fetchData(context: RoutingContextWithMiddlewares): Promise<{ ad: ExpectedAdReturn }> {
        const {
            redux: {
                store: { dispatch },
            },
            match: {
                params: { externalID },
            },
        } = context;

        return Promise.all([
            this.fetchAd(context),
            this.cancelable(dispatch(fetchFreeAdLimitUsage())),
            this.cancelable(
                dispatch(
                    fetchProductPurchases({
                        status: ProductPurchaseStatus.ACTIVE,
                        adExternalID: externalID,
                    }),
                ),
            ),
        ]).then(([ad]: [ExpectedAdReturn, unknown, unknown]) => ({ ad }));
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<ExpectedAdReturn> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const state = getState();
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            return Promise.resolve(null);
        }

        return this.cancelable(getStratAPI(state).userAd(userExternalID, externalID)).then(
            (response) => {
                if (response.status >= 300) {
                    return null;
                }

                const ad = response.data;
                if (ad?.state !== AdState.LIMITED) {
                    return null;
                }

                return ad;
            },
        );
    }
}

export default new AgencyPortalApplyAdLimitRoute();
