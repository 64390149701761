import BaseSavedSearchesAPI from 'strat/api/savedSearchesAPI';
import type { SavedSearchParams } from 'strat/search/savedSearches/types';

class SavedSearchesAPI extends BaseSavedSearchesAPI {
    /**
     * Saves an entry of a saved search.
     */
    addSavedSearch(
        userID: string,
        name: string,
        params: SavedSearchParams,
        _convertAreaToSqm?: boolean,
    ): Promise<any> {
        return super
            .post(`/api/user/${userID}/searches/saved/`, {
                ...params,
                name,
            })
            .then(({ data, status }) => {
                if (status !== 201) {
                    return { status, data: null };
                }

                return { status, data };
            });
    }
}

export default SavedSearchesAPI;
