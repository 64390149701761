import type { CategoryField, CategoryFieldChoice, CategoryFieldChoicesKeysType } from 'strat/types';
import { CategoryFieldChoicesKeys } from 'strat/types';

import type { CategoryFields } from './types';

const transformFlatFields = (fields: CategoryFields): Array<CategoryField> =>
    fields.flatFields.map((field) => {
        if (field.choices && field.choices.length) {
            return {
                ...field,
                choices: { [CategoryFieldChoicesKeys.ALL]: field.choices },
                combinationChoices: { [CategoryFieldChoicesKeys.ALL]: field.combinationChoices },
            };
        }
        return {
            ...field,
            choices: undefined,
            combinationChoices: undefined,
        };
    });

const transformCategoryFieldsChildChoices = (
    choices?: Partial<Record<CategoryFieldChoicesKeysType, Array<CategoryFieldChoice>>>,
) =>
    Object.entries(choices || {}).reduce((acc, [_, childChoices]) => {
        const parentID = (childChoices || [])[0]?.parentID;
        if (!parentID) {
            return acc;
        }
        return {
            ...acc,
            [parentID]: [...(childChoices || [])],
        };
    }, {});

const transformCategoryFieldsChildren = (fields: CategoryFields): Array<CategoryField> =>
    fields.childrenFields.map((field: CategoryField) => ({
        ...field,
        choices: transformCategoryFieldsChildChoices(field.choices),
        combinationChoices: transformCategoryFieldsChildChoices(field.combinationChoices),
    }));

/**
 * Combines flatFields and categoryFields from CategoryFields.
 * TODO this function is temporary, all the code should work with the CategoryFields type instead of the combined array
 */
const combineCategoryFields = (fields: CategoryFields): Array<CategoryField> => [
    ...transformFlatFields(fields),
    ...transformCategoryFieldsChildren(fields),
];

export default combineCategoryFields;
