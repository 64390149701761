import * as React from 'react';
import { t } from '@lingui/macro';

import type { GTMNavigationBarTrackingProps } from 'strat/gtm';
import { withGTMNavigationBarTracking } from 'strat/gtm';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { useI18n } from 'strat/i18n/language';
import type { SavedSearchData } from 'strat/search/savedSearches/types';

type Props = GTMNavigationBarTrackingProps & {
    savedSearch: SavedSearchData;
    onClick?: () => void;
    className: string;
};

/**
 * Renders a link corresponding to a saved search.
 *
 * Transforms location parameter to format required by search route.
 * (API returns locations parameter as a list of slugs,
 * but the search route requires list of objects)
 */
const SavedSearchLink = ({ savedSearch, className, onClick, trackViewSavedSearches }: Props) => {
    const i18n = useI18n();
    const { name, params } = savedSearch;

    const routeParams = {
        ...params,
        locations:
            params?.locations?.map((location: any) =>
                typeof location === 'string' ? { slug: location } : location,
            ) || [],
    } as const;
    return (
        <Link
            route={RouteNames.SEARCH}
            params={routeParams}
            className={className}
            onClick={() => {
                trackViewSavedSearches();
                if (onClick) {
                    onClick();
                }
            }}
        >
            {name || t(i18n)`Unknown Search`}
        </Link>
    );
};

// @ts-expect-error
export default withGTMNavigationBarTracking(SavedSearchLink);
