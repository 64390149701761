import type { FloorPlanImage } from 'strat/property';

type GraphicModeType = {
    IMAGE_2D: string;
    IMAGE_3D: string;
    INTERACTIVE_3D: string;
};

const GraphicMode: GraphicModeType = Object.freeze({
    IMAGE_2D: 'image2D',
    IMAGE_3D: 'image3D',
    INTERACTIVE_3D: 'interactive3D',
});

export type FloorPlanResourceType = {
    id: number;
    title: string;
    alt?: string;
    thumbnailID?: number;
    graphicMode: Values<typeof GraphicMode>;
};

export type FloorPlanModel = {
    id: number;
    externalID: string;
    modelURL: string | null | undefined;
    label: string | null | undefined;
    placeholderImage: {
        id: number;
    };
};

export type FloorPlan = {
    id: number;
    bedroomCount: number | null | undefined;
    bathroomCount: number | null | undefined;
    category:
        | Array<{
              name: string;
              slug: string;
          }>
        | null
        | undefined;
    location: Array<{
        id: number;
        externalID: number;
        name: string;
        slug: string;
    }>;
    typeIdentifier: string;
    typeIdentifierValue: string;
    images: Array<FloorPlanImage>;
    models: Array<FloorPlanModel>;
    related?: Array<FloorPlan>;
};

export type FloorPlanThumbnailImage = {
    image2D: {
        id: number;
    };
    image3D:
        | {
              id: number;
          }
        | null
        | undefined;
    title?: string;
    alt: string;
};

export { GraphicMode };
