import * as React from 'react';
import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { OffScreen, Dismissible, HidingMethod, HidingWrapper } from 'strat/modal';
import { useDisableBodyScroll, useResizeObserver } from 'strat/util';

import styles from './styles/responsiveBottomSheet.cssm';

type Props = {
    // The unique name of the sheet
    name: string;
    isOpen?: boolean;
    onClose: () => void;
    className?: string;
    children: React.ReactNode;
    hidingMethod?: Values<typeof HidingMethod>;
};

export const BOTTOM_SHEET_TRANSITION_DURATION = 250; // ms

/**
 * A responsive bottom sheet component, which adapts based
 * on the height of its content.
 */
const ResponsiveBottomSheet = ({
    name,
    onClose,
    isOpen = false,
    className = styles.contentContainer,
    children,
    hidingMethod,
}: Props) => {
    const [sheetIsExpanded, setSheetIsExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const onResize = React.useCallback(
        (entries: ResizeObserverEntry[]) => {
            if (isOpen) {
                setContentHeight(entries[0].contentRect.height);
            } else {
                setContentHeight(0);
            }
        },
        [isOpen],
    );

    const observe = useResizeObserver(onResize);

    const { enableBodyScroll, disableBodyScroll } = useDisableBodyScroll();

    const shouldShowOverlay = isOpen && sheetIsExpanded;

    // We perform the mounting/unmounting of the content in two phases,
    // in order to allow the open and close animations take effect
    useEffect(() => {
        if (isOpen) {
            setSheetIsExpanded(true);
            return () => {};
        }

        // In case the open state changes again before the animation finishes
        let openStateChanged = false;

        // Allow the animation to finish before unmounting the content
        setTimeout(
            () => !openStateChanged && setSheetIsExpanded(false),
            BOTTOM_SHEET_TRANSITION_DURATION,
        );
        return () => {
            openStateChanged = true;
        };
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) {
            return () => {};
        }

        enableBodyScroll();
        return () => {
            disableBodyScroll();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const computeStyle = () => {
        // The initial and final state are both of height 0
        if (!isOpen || !sheetIsExpanded) {
            return {
                transition: `height ${BOTTOM_SHEET_TRANSITION_DURATION}ms ease-out`,
                height: 0,
            };
        }

        return {
            transition: `height ${BOTTOM_SHEET_TRANSITION_DURATION}ms ease-out`,
            height: contentHeight,
        };
    };

    return (
        <OffScreen name={name}>
            <HidingWrapper display={isOpen || sheetIsExpanded} hidingMethod={hidingMethod}>
                <div
                    className={classNames(styles.overlayTransition, {
                        [styles.overlay]: shouldShowOverlay,
                    })}
                >
                    <div
                        className={styles.container}
                        aria-label="Quick Filter Dialog"
                        style={computeStyle()}
                    >
                        <Dismissible
                            enabled={isOpen}
                            onDismissed={onClose}
                            className={styles.dismissible}
                        >
                            <div className={className}>
                                <div ref={observe}>{children}</div>
                            </div>
                        </Dismissible>
                    </div>
                </div>
            </HidingWrapper>
        </OffScreen>
    );
};

export default ResponsiveBottomSheet;
