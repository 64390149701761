import * as React from 'react';

import { AdVirtualState } from 'horizontal/types';
import { useAd, useAdExternalID } from 'horizontal/ad/state';

import DescriptionContactInfo from './descriptionContactInfo';

const PHONE_PLACEHOLDER = '[redacted phone number]';

const escapeString = (text: string): string => text.replace('[', '\\[').replace(']', '\\]');

const PHONE_PLACEHOLDER_EXPRESSION = new RegExp(`(?=${escapeString(PHONE_PLACEHOLDER)})`);

const renderTextElement = (index: number, text: string, placeholder?: string) => (
    <span
        key={index + text}
        dangerouslySetInnerHTML={{
            __html: placeholder ? text.replace(placeholder, '') : text,
        }}
    />
);

const CallLink = ({ phoneNumber }: { phoneNumber: string | null }) => {
    const ad = useAd();
    const adExternalID = useAdExternalID();
    const isAdExpired = [AdVirtualState.EXPIRED, AdVirtualState.OUTDATED].includes(
        // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        ad?.virtualState,
    );

    return (
        <DescriptionContactInfo
            adExternalID={adExternalID}
            isAdExpired={isAdExpired}
            phoneNumber={phoneNumber}
        />
    );
};
const extractPhoneNumber = (text: string) => {
    const matchedPhonenumbers = text.match(/#\((.+)\)/);
    if (!matchedPhonenumbers || matchedPhonenumbers.length < 2) {
        return null;
    }
    return matchedPhonenumbers[1];
};
type Props = {
    readonly description: string;
};

const DescriptionText = ({ description }: Props) => {
    const elements: Array<
        | React.ReactElement<React.ComponentProps<React.ComponentType>>
        | React.ReactElement<React.ComponentProps<'span'>>
        | any
    > = [];

    description.split(PHONE_PLACEHOLDER_EXPRESSION).forEach((text, index) => {
        if (text.startsWith(PHONE_PLACEHOLDER)) {
            let placeholder = PHONE_PLACEHOLDER;
            let phoneNumber = null;
            if (CONFIG.runtime.STRAT_DISPLAY_REDACTED_NUMBERS) {
                phoneNumber = extractPhoneNumber(text);
                placeholder = phoneNumber
                    ? `${PHONE_PLACEHOLDER}#(${phoneNumber})`
                    : PHONE_PLACEHOLDER;
            }
            elements.push(
                <React.Fragment key={index}>
                    <CallLink phoneNumber={phoneNumber} />
                </React.Fragment>,
            );
            elements.push(renderTextElement(index, text, placeholder));
            return;
        }
        elements.push(renderTextElement(index, text));
    });

    return elements;
};

export default DescriptionText;
