import * as React from 'react';
import { useSelector } from 'react-redux';

import { MapPopup } from 'strat/map';
import { selectIsAgentOwnListing } from 'strat/agency/agent/state/selectors';
import type { Geoloc } from 'strat/map/types';
import Designs from 'strat/branding/designs';

import withPropertyLocationFallback from './withPropertyLocationFallback';

type Props = {
    geography: Geoloc;
    shouldUseNewDesign?: boolean;
    contactFormDesign?: Values<typeof Designs>;
    renderPlacesSwitcher: (props: any) => React.ReactNode;
};

const PropertyCommuteNearby = ({
    geography,
    shouldUseNewDesign,
    renderPlacesSwitcher,
    contactFormDesign,
}: Props) => {
    const isOwnListing = useSelector(selectIsAgentOwnListing);

    return (
        <MapPopup
            // @ts-expect-error connectors are not properly typed
            geoloc={geography}
            contactFormDesign={contactFormDesign}
            shouldShowContactInfo={!isOwnListing}
            renderPlacesSwitcher={renderPlacesSwitcher}
            shouldUseNewDesign={shouldUseNewDesign}
        />
    );
};

// @ts-expect-error - TS2345 - Argument of type '({ geography, shouldUseNewDesign, renderPlacesSwitcher }: { geography: any; shouldUseNewDesign: any; renderPlacesSwitcher: any; }) => Element' is not assignable to parameter of type 'AbstractComponent<any, any>'.
export default withPropertyLocationFallback(PropertyCommuteNearby);
