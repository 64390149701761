import { logError } from 'strat/error/log';
import type { EnhancedLocation } from 'react-true-router/location';
import { serializeLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { buildCanonicalURL } from 'strat/routing';
import { selectActiveUser } from 'strat/user/session';

import Page from 'horizontal/pages/page';
import { isAdEditor } from 'horizontal/util';
import type { Ad } from 'horizontal/types';
import { fetchRelatedAds } from 'horizontal/adDetails/relatedAds/state';
import { fetchAdMetrics } from 'horizontal/adMetrics';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { ProductPurchaseStatus } from 'horizontal/packages';
import { fetchURLSpecificSEOs } from 'horizontal/urlSpecificSEO/state';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import { AdDetailPageType } from 'horizontal/types';
import { removeUnreachableAdFromRecentlyViewedAds } from 'horizontal/search/recentlyViewed';

import type { AdDetailsRouteParams } from '../createAdDetailsURL';

import getAdExternalID from './getAdExternalID';
import getAdData from './getAdData';
import isAdAccessible from './isAdAccessible';
import shouldRedirectToParentCategory from './shouldRedirectToParentCategory';
import redirectToParentCategory from './redirectToParentCategory';
import redirectToCanonical from './redirectToCanonical';
import { renderRemarketingPage } from './remarketing';

const renderPageVariation = (
    context: RoutingContextWithMiddlewares,
    createURL: (params: AdDetailsRouteParams) => EnhancedLocation,
): Promise<any> => {
    const {
        redux: {
            store: { dispatch, getState },
        },
        i18n: { locale: language },

        match: {
            params: { externalID, hash, pagetype, productPurchaseID },
        },
    } = context;

    const ad: Ad = getAdData(context);
    const adExternalID = getAdExternalID(externalID, hash);
    const userExternalID = selectActiveUser(getState())?.externalID;

    if (pagetype === AdDetailPageType.REMARKETING) {
        return renderRemarketingPage(context, ad);
    }

    // @ts-expect-error - TS2339 - Property 'isAdAccessible' does not exist on type 'Function'.
    if (!isAdAccessible(ad, userExternalID)) {
        if (shouldRedirectToParentCategory(ad)) {
            redirectToParentCategory(ad, context);
            return Promise.resolve();
        }

        dispatch(removeUnreachableAdFromRecentlyViewedAds(ad));
        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
        return Promise.resolve();
    }

    if (redirectToCanonical(context, createURL)) {
        return Promise.resolve();
    }

    context.rendering.renderPage(Page.AD_DETAILS, { productPurchaseID: productPurchaseID });

    const canonicalURL = buildCanonicalURL(
        serializeLocation(createURL({ slug: ad.slug, externalID: adExternalID })),
        language,
    );

    const promises: Array<any> = [];
    promises.push(
        dispatch(
            fetchURLSpecificSEOs({
                language,
                url: canonicalURL,
            }),
        ),
    );
    if (isAdEditor(ad, userExternalID)) {
        promises.push(
            dispatch(fetchAdMetrics({ adExternalIDs: [ad.externalID] })).catch((e: unknown) => {
                logError({
                    e,
                    msg: 'Failed to fetch ad metrics',
                    context: {
                        adExternalID: ad?.externalID,
                        userExternalID: userExternalID,
                    },
                });
            }),
        );
        promises.push(dispatch(fetchProductPurchases({ status: ProductPurchaseStatus.ACTIVE })));
        promises.push(dispatch(fetchFreeAdLimitUsage()));
    }

    const categoryExternalID = ad.category.slice(-1)[0]?.externalID;
    if (categoryExternalID) {
        promises.push(dispatch(fetchRelatedAds({ ad })));
    }

    if (productPurchaseID) {
        promises.push(dispatch(fetchProductPurchases()));
    }

    return Promise.all(promises);
};

export default renderPageVariation;
