import * as React from 'react';
import EMPTY_ARRAY from 'strat/empty/array';
import { Checkbox, Text, Flex } from 'strat/components';

import type { FlatCategoryField } from 'horizontal/types';
import { trackFilterChange } from 'horizontal/gtm';
import { RefinedSearchLink } from 'horizontal/search/routing';

import useFilter from './useFilter';
import Expandable from './expandable';
import styles from './styles/multipleChoice.cssm';
import useDisplayPriorityFilteredFacetedChoices from './useDisplayPriorityFilteredFacetedChoices';

type Props = {
    readonly field: FlatCategoryField;
    readonly visibleCount?: number;
};

const MultipleChoice = ({ field, visibleCount }: Props) => {
    const filter = useFilter(field, EMPTY_ARRAY as FlatCategoryField[]);
    // @ts-expect-error - TS2345 - Argument of type 'readonly never[] | null' is not assignable to parameter of type 'Primitive'.
    const choices = useDisplayPriorityFilteredFacetedChoices(field, filter.value);

    const newParams = React.useCallback(
        (value) => {
            const oldValues = new Set(filter.value);
            if (oldValues.has(value)) {
                oldValues.delete(value);
                return {
                    extraFields: { [field.attribute]: Array.from(oldValues) },
                };
            }
            return {
                // @ts-expect-error - TS2488 - Type 'readonly never[] | null' must have a '[Symbol.iterator]()' method that returns an iterator.
                extraFields: { [field.attribute]: [...filter.value, value] },
            };
        },
        [filter, field.attribute],
    );

    return (
        <Expandable visibleCount={visibleCount}>
            {choices.map((choice) => (
                <RefinedSearchLink
                    key={choice.value}
                    params={newParams(choice.value)}
                    onClick={() => trackFilterChange(field.attribute, choice.value)}
                >
                    <Flex alignCenter className={styles.row}>
                        <Checkbox
                            // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2345 - Argument of type 'any' is not assignable to parameter of type 'never'.
                            checked={filter.value.includes(choice.value)}
                            label={
                                <>
                                    <Text.Regular>{choice.label}</Text.Regular>
                                    {CONFIG.runtime.STRAT_ENABLE_OLX_SHOW_AD_COUNT &&
                                        !!choice.count && (
                                            <Text.Base className={styles.countLabel}>
                                                ({choice.count})
                                            </Text.Base>
                                        )}
                                </>
                            }
                            name={choice.value}
                            withHighlightLabel={false}
                        />
                    </Flex>
                </RefinedSearchLink>
            ))}
        </Expandable>
    );
};

export default MultipleChoice;
