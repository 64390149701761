import * as React from 'react';
import { useSelector } from 'react-redux';

import Link from 'strat/navigation/link';
import { RouteNames } from 'strat/routes';
import { selectSearchLink } from 'strat/search/url';
import { PropertyType } from 'strat/property/types';
import { GTMSearchTrackingProps, withGTMSearchTracking } from 'strat/gtm';

import type { ProjectData } from '../types';

import styles from './styles/projectListingLink.cssm';

type Props = GTMSearchTrackingProps & {
    project: ProjectData;
    openInNewTab?: boolean;
};

const ProjectListingLink = ({ project, trackProjectClick, openInNewTab }: Props) => {
    const searchLink = useSelector(selectSearchLink);

    const params = {
        ...searchLink.params,
        locations: project.location.slice(-1) || [],
        locationsByLanguage: undefined,
        listingType: PropertyType.PROPERTY,
        mapActive: false,
        page: 1,
        agencies: null,
    };

    return (
        <Link
            route={RouteNames.SEARCH}
            params={params}
            className={styles.link}
            onClick={() => trackProjectClick()}
            openInNewTab={openInNewTab}
            aria-label="Listing link"
        >
            <div className={styles.linkOverlay} />
        </Link>
    );
};

export default withGTMSearchTracking(ProjectListingLink);
