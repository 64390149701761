import { t } from '@lingui/macro';
import * as React from 'react';
import { RangeFilter } from '@sector-labs/fe-search-redux/filters';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import EMPTY_OBJECT from 'strat/empty/object';
import { convertPriceToAlphabeticalForm } from '@app/fields/convertPriceToAlphabeticalForm';
import settings from '@app/branding/settings';
import { Input } from 'strat/components';
import { useSelector } from 'react-redux';

import type { FlatCategoryField } from 'horizontal/types';

import Slider from './slider';
import styles from './styles/priceFilter.cssm';
import useFilter from './useFilter';
import useRefineFilter from './useRefineFilter';

// Constant used in case field.maxValue is missing
const DEFAULT_MAX = 2500000;

const PriceFilter = ({ field }: { field: FlatCategoryField }) => {
    const filter = useFilter(field, EMPTY_OBJECT);
    const i18n = useI18n();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    const numberFormatter = numberFormatterFactory({ maximumFractionDigits: 20 });
    const defaultMin = field.minValue || 0;
    const defaultMax = field.maxValue || DEFAULT_MAX;
    const [min, setMin] = React.useState<number>(
        filter.value?.min === 0 ? 0 : filter.value?.min || defaultMin || 0,
    );
    const [max, setMax] = React.useState<number | null>(filter.value?.max || defaultMax);
    // @ts-expect-error - TS2769 - No overload matches this call.
    const formattedMaxValue = numberFormatter.format(max);
    const formattedMinValue = numberFormatter.format(min);
    React.useEffect(() => {
        if (!filter.value) {
            const resetMin = defaultMin || 0;
            setMin(resetMin);
            if (defaultMax) {
                setMax(defaultMax);
            } else {
                setMax(null);
            }
        }
    }, [filter, defaultMin, defaultMax, numberFormatter]);

    const values = React.useMemo(() => [min, max], [min, max]);
    const shouldRefineOnUpdate = React.useRef(false);
    const refine = useRefineFilter(field.attribute);
    const minPriceDescription: string = (min && convertPriceToAlphabeticalForm(min, i18n)) || '';
    const maxPriceDescription: string = (max && convertPriceToAlphabeticalForm(max, i18n)) || '';
    const hasPriceDescription =
        (minPriceDescription && minPriceDescription !== '') ||
        (maxPriceDescription && maxPriceDescription !== '');
    const disablePriceSlider = settings.languages.some((lang) => lang.rtl);

    const handleBlur = React.useCallback(() => {
        const newMin = defaultMax ? Math.min(min, defaultMax) : min;
        const maxComparedToMin = max != null ? Math.max(max, newMin) : max;
        const newMax =
            defaultMax && maxComparedToMin
                ? Math.min(maxComparedToMin, defaultMax)
                : maxComparedToMin;
        const newValue = RangeFilter.safeGuard({ min: newMin, max: newMax });
        if (!isEqual(newValue, filter.value)) {
            // @ts-expect-error - TS2345 - Argument of type 'RangeFilterValue | null' is not assignable to parameter of type 'CategoryFieldFilterValue'.
            refine(newValue);
        }
        if (newMin !== min) {
            setMin(newMin);
        }
        if (max != null && newMax !== max) {
            setMax(newMax);
        }
    }, [min, max, defaultMax, filter, refine]);

    const setMinValue = React.useCallback(
        (value) => {
            const cleanedValue = value.replace(/[^0-9]/g, '');
            const newMin = parseInt(cleanedValue, 10);
            if (!isNaN(newMin) && max && newMin < max) {
                setMin(newMin);
            } else if (cleanedValue === '') {
                setMin(0);
            }
        },
        [max],
    );

    const onSliderDragEnd = React.useCallback(() => {
        refine({ min, max });
    }, [min, max, refine]);

    const setMaxValue = React.useCallback((value) => {
        const cleanedValue = value.replace(/[^0-9]/g, '');
        const newMax = parseInt(cleanedValue, 10);
        if (!isNaN(newMax)) {
            setMax(newMax);
        } else if (cleanedValue === '') {
            setMax(0);
        }
    }, []);

    const onChange = React.useCallback(
        ({ values: newValues }) => {
            let [newMin, newMax] = newValues;

            newMin = newMax != null ? Math.min(newMin, newMax) : newMin;
            newMax = newMax != null ? Math.max(newMax, newMin) : newMax;

            if (!isNil(defaultMax)) {
                newMin = Math.min(newMin, defaultMax);
                newMax = newMax ? Math.min(newMax, defaultMax) : newMax;
            }

            setMin(newMin);
            setMax(newMax);

            if (shouldRefineOnUpdate.current) {
                refine({ min: newMin, max: newMax });
                shouldRefineOnUpdate.current = false;
            }
        },
        [defaultMax, refine],
    );

    const onClick = React.useCallback(() => {
        shouldRefineOnUpdate.current = true;
    }, [shouldRefineOnUpdate]);

    return (
        <>
            <div
                className={classNames(styles.container, {
                    [styles.withDescription]: hasPriceDescription,
                })}
                onBlur={handleBlur}
            >
                <div className={styles.input}>
                    <Input
                        secondary
                        value={formattedMinValue}
                        type="text"
                        onChange={setMinValue}
                        placeholder={t(i18n)`Min`}
                        description={minPriceDescription}
                    />
                </div>
                <div className={styles.input}>
                    <Input
                        secondary
                        value={formattedMaxValue}
                        type="text"
                        onChange={setMaxValue}
                        placeholder={t(i18n)`Max`}
                        description={maxPriceDescription}
                    />
                </div>
            </div>
            {!disablePriceSlider && !isNil(defaultMin) && !isNil(defaultMax) && (
                <div className={styles.slider}>
                    <Slider
                        min={0}
                        max={defaultMax}
                        values={values}
                        onValuesUpdated={onChange}
                        onSliderDragEnd={onSliderDragEnd}
                        onClick={onClick}
                    />
                </div>
            )}
        </>
    );
};

export default PriceFilter;
