import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues } from 'formik';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { RadioButtonChoice, RadioGroup } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { ContactPersonRole } from 'horizontal/types';
import SettingsToggle from 'horizontal/userSettings/controls/settingsToggle';

import { ContactInfoFields } from './commonPostingFields';
import useContactMethodChoices from './useContactMethodChoices';

type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
};

const ContactInfoRolesField = ({ setFieldValue, values }: Props) => {
    const i18n = useI18n();

    const showPhoneNumber = values[ContactInfoFields.roles.attribute].includes(
        ContactPersonRole.SHOW_PHONE_NUMBER,
    );
    const allowChatCommunication = values[ContactInfoFields.roles.attribute].includes(
        ContactPersonRole.ALLOW_CHAT_COMMUNICATION,
    );

    const handleChange = React.useCallback(
        (contactSettings) => {
            const roles: Array<any | string> = [];
            if (contactSettings[ContactPersonRole.ALLOW_CHAT_COMMUNICATION]) {
                roles.push(ContactPersonRole.ALLOW_CHAT_COMMUNICATION);
            }
            if (contactSettings[ContactPersonRole.SHOW_PHONE_NUMBER]) {
                roles.push(ContactPersonRole.SHOW_PHONE_NUMBER);
            }
            setFieldValue(ContactInfoFields.roles.attribute, roles);
        },
        [setFieldValue],
    );

    const trackShowPhoneNumber = React.useCallback(
        (isSelected: boolean) => {
            trigger(Triggers.HIDE_NUMBER, {
                ad_title: values?.title,
                // eslint-disable-next-line camelcase
                ad_condition: values?.new_used,
                make: values?.make,
                price: values?.price,
                filter_name: 'number-toggle',
                filter_value: isSelected ? 'on' : 'off',
                view_section: ViewSections.BODY,
            });
        },
        [values],
    );

    const { choices, selectedValue, saveSettings, enableContactMethodControl } =
        useContactMethodChoices({
            showPhoneNumber,
            allowChatCommunication,
            onSettingsChanged: handleChange,
            trackShowPhoneNumber,
        });

    if (!enableContactMethodControl) {
        return (
            <SettingsToggle
                value={showPhoneNumber}
                onClick={() => saveSettings(!showPhoneNumber)}
                title={t(i18n)`Show my phone number in ads`}
            />
        );
    }

    return (
        <RadioGroup
            selectedValue={selectedValue}
            title={t(i18n)`Contact Method`}
            choices={choices}
            onChange={saveSettings}
            renderChoice={RadioButtonChoice}
            leftSideLabel
            boldLabel
        />
    );
};

export default ContactInfoRolesField;
