import type { Range } from 'strat/types';

import { Unit } from './units';
import Area from './area';

/**
 * Converts a {@see Range} object from one unit to another.
 */
const convertRange = (
    value: Range | null | undefined,
    fromUnit: string,
    toUnit: string,
): Range | null => {
    if (!value) {
        return null;
    }

    return {
        min: value.min === null ? null : new Unit(value.min, Area, fromUnit).convert(toUnit),
        max: value.max === null ? null : new Unit(value.max, Area, fromUnit).convert(toUnit),
    };
};

export default convertRange;
