import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Flex, LoadingAnimation } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { selectRouteParams } from 'strat/routes/selectors';

import FastForwardIcon from 'horizontal/assets/icons/fastForward.svg';
import MakeOfferIcon from 'horizontal/assets/icons/iconMakeOffer.svg';
import QuestionsIcon from 'horizontal/assets/icons/iconQuestions.svg';
import ExpandIcon from 'horizontal/assets/icons/iconThickArrowUp_noinline.svg';
import { ChatNegotiationState } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ActionButton, ChatAd, ChatNegotiationInfo, ChatRoom } from 'horizontal/chat/types';
import useChatGTMVarsProps from 'horizontal/chat/useChatGTMVarsProps';
import { useTrackMakeAnOffer } from 'horizontal/gtm';

import ChatAssistantBody from './chatAssistantBody';
import FixedMobileWrapper from './fixedMobileWrapper';
import styles from './styles/chatAssistant.cssm';

const isOfferTabAvailable = (ad?: ChatAd | null) => {
    const adPrice: number | null | undefined = ad?.extraFields?.price;
    return !!adPrice;
};

const findHeaderTabs = ({
    i18n,
    negotiation,
    ad,
    selectedTab,
    setSelectedTab,
    setExpanded,
    trackMakeAnOffer,
}: {
    i18n: I18n;
    negotiation: ChatNegotiationInfo;
    ad: ChatAd | null | undefined;
    selectedTab: number;
    setSelectedTab: (arg1: number) => void;
    setExpanded: (arg1: boolean) => void;
    trackMakeAnOffer: (arg1: undefined) => void;
}) => {
    const tabs = [[<QuestionsIcon />, t(i18n)`Questions`, null]];
    if (isOfferTabAvailable(ad)) {
        if (negotiation.state === ChatNegotiationState.ACCEPTED) {
            tabs.push([<FastForwardIcon />, t(i18n)`Next steps`, null]);
        } else {
            // @ts-expect-error - TS2322 - Type '(arg1: undefined) => void' is not assignable to type 'string | Element | null'.
            tabs.push([<MakeOfferIcon />, t(i18n)`Offer`, trackMakeAnOffer]);
        }
    }

    // @ts-expect-error - TS2345 - Argument of type '([svg, title, onTabClick]: [any, any, any], index: number) => Element' is not assignable to parameter of type '(value: (string | Element | null)[], index: number, array: (string | Element | null)[][]) => Element'.
    return tabs.map(([svg, title, onTabClick]: [any, any, any], index) => (
        <Flex
            fillContainer
            justifyCenter
            className={classNames(styles.headerTab, {
                [styles.selected]: index === selectedTab,
            })}
            onClick={() => {
                onTabClick?.();
                setSelectedTab(index);
                setExpanded(true);
            }}
            key={title}
        >
            {svg}
            <span className={styles.tabTitle}>{title}</span>
        </Flex>
    ));
};

type Props = {
    readonly conversation: ChatRoom;
    readonly actionButtons: Array<ActionButton>;
    readonly priceFormVisible: boolean;
    readonly setPriceFormVisible: (arg1: boolean) => void;
};

const ChatAssistant = ({
    conversation,
    actionButtons,
    priceFormVisible,
    setPriceFormVisible,
}: Props) => {
    const sdk = useChatSDK();
    const [expanded, setExpanded] = React.useState(false);
    const gtmProps = useChatGTMVarsProps();
    const trackMakeAnOffer = useTrackMakeAnOffer(gtmProps);
    const i18n = useI18n();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const { ad, isLoading } = sdk.useRoomContactInfo(conversation);
    const { makeOffer: shouldShowOffer } = useSelector(selectRouteParams);
    const negotiation = sdk.useRoomNegotiationInfo(conversation.xid);

    React.useEffect(() => {
        const showOfferTab = shouldShowOffer && isOfferTabAvailable(ad);
        setSelectedTab(showOfferTab ? 1 : 0);
        setExpanded(showOfferTab);
    }, [conversation.xid, ad, shouldShowOffer]);

    React.useEffect(() => {
        if (priceFormVisible) {
            setExpanded(true);
            setSelectedTab(1);
        }
    }, [priceFormVisible]);

    React.useEffect(() => {
        if (!(selectedTab === 1 && expanded)) {
            setPriceFormVisible(false);
        }
    }, [expanded, selectedTab, setPriceFormVisible]);

    const headerTabs = React.useMemo(
        () =>
            findHeaderTabs({
                i18n,
                negotiation,
                ad,
                selectedTab,
                setSelectedTab,
                setExpanded,
                trackMakeAnOffer,
            }),
        [i18n, selectedTab, negotiation, ad, trackMakeAnOffer],
    );

    if (isLoading) {
        return <LoadingAnimation className={styles.loading} />;
    }

    return (
        <div
            className={classNames(styles.chatAssistant, { [styles.expanded]: expanded })}
            aria-label={`Chat assistant ${expanded ? 'expanded' : 'closed'}`}
        >
            <FixedMobileWrapper bottom>
                <div className={styles.expandButtonWrapper}>
                    <div className={styles.expandButton} onClick={() => setExpanded(!expanded)}>
                        <img
                            src={ExpandIcon}
                            alt="Expand Icon"
                            className={classNames(styles.expandIcon, {
                                [styles.expanded]: expanded,
                            })}
                        />
                    </div>
                </div>
                <Flex className={styles.header}>{headerTabs}</Flex>
                <ChatAssistantBody
                    ad={ad}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    selectedTab={selectedTab}
                    conversation={conversation}
                    actionButtons={actionButtons}
                    priceFormVisible={priceFormVisible}
                    setPriceFormVisible={setPriceFormVisible}
                />
            </FixedMobileWrapper>
        </div>
    );
};

export default React.memo<Props>(ChatAssistant);
