import * as React from 'react';
import { LoadingAnimation } from 'strat/components';

import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatUser, ChatUserXID } from 'horizontal/chat/types';
import { UserDTO } from 'horizontal/dtos';
import ProfilePicture from 'horizontal/profile/profilePicture';

import styles from './styles/head.cssm';

type Props = {
    readonly className?: string;
    readonly contactXID: ChatUserXID;
    readonly contactUser: ChatUser | null | undefined;
    readonly isLoading?: boolean | null | undefined;
};

const Head = ({ className, contactXID, contactUser, isLoading }: Props) => {
    const sdk = useChatSDK();
    const { isOnline } = sdk.usePresenceByUser(contactXID);

    return (
        <div className={className || styles.container}>
            {isLoading ? (
                <LoadingAnimation className={styles.userImage} />
            ) : (
                <>
                    <ProfilePicture
                        user={contactUser as UserDTO | null | undefined}
                        className={styles.userImage}
                        fixedSize
                    />
                    {isOnline && <div className={styles.onlineBullet} />}
                </>
            )}
        </div>
    );
};

export default Head;
