import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Trans } from '@lingui/macro';

import { type ProductPackageOffer, PackageType } from 'horizontal/types';
import { BoostToTopAdExample } from 'horizontal/packages/components';
import { trackViewExampleClick } from 'horizontal/gtm';
import { BusinessPackageSubsection } from 'horizontal/packages/types';
import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';

import { BusinessPackageExampleType, FrequencyTimeUnitType } from '../types';
import BusinessPackageExampleContext from '../businessPackageExampleContext';
import { useLocalizedDays } from '../hooks';

import Section from './sectionRedesign';

const AutoBoostToTopSection = ({
    packageOffers,
    details,
}: {
    packageOffers: Array<ProductPackageOffer>;
    details: string;
}) => {
    const i18n = useI18n();
    const iconSrc = usePackagesIcons({ type: PackageType.AUTO_REFRESH });
    const localizedDays = useLocalizedDays();

    const [activeExample, setActiveExample] = React.useContext(BusinessPackageExampleContext);
    const closeExample = React.useCallback(() => setActiveExample(null), [setActiveExample]);
    const openExample = React.useCallback(() => {
        trackViewExampleClick();
        setActiveExample(BusinessPackageExampleType.BOOST_TO_TOP);
    }, [setActiveExample]);

    const getSubSectionTitle = React.useCallback(
        (offer: ProductPackageOffer) => {
            const frequency = offer.productPackage.productItems[0].frequency || 0;
            const duration = offer.productPackage.productItems[0].duration;
            const frequencyTimeUnit =
                offer.productPackage.productItems[0].frequencyTimeUnit ===
                FrequencyTimeUnitType.DAYS
                    ? t(i18n)`days`
                    : t(i18n)`hours`;

            return frequency > 1 ? (
                <Trans>
                    Auto Boost service valid for <b>{localizedDays(duration)}</b>, ad will be
                    auto-refreshed every{' '}
                    <b>
                        {frequency} {frequencyTimeUnit}
                    </b>
                </Trans>
            ) : (
                <Trans>
                    Auto Boost your ad daily for <b>{localizedDays(duration)}</b>
                </Trans>
            );
        },
        [i18n, localizedDays],
    );

    const getSubsectionsByDuration = React.useMemo(() => {
        packageOffers.sort(
            (a, b) =>
                (a.productPackage.productItems[0].duration || 0) -
                (b.productPackage.productItems[0].duration || 0),
        );

        let duration = packageOffers[0]?.productPackage.productItems[0].duration;
        let currentSubsection: Array<ProductPackageOffer> = [];
        const subsections: Array<BusinessPackageSubsection> = [];

        packageOffers.forEach((offer) => {
            if (offer.productPackage.productItems[0].duration !== duration) {
                subsections.push({
                    title: getSubSectionTitle(currentSubsection[0]),
                    packageOffers: currentSubsection,
                });
                currentSubsection = [];
                duration = offer.productPackage.productItems[0].duration;
            }
            currentSubsection.push(offer);
        });
        if (currentSubsection) {
            subsections.push({
                title: getSubSectionTitle(currentSubsection[0]),
                packageOffers: currentSubsection,
            });
        }

        return subsections;
    }, [packageOffers, getSubSectionTitle]);

    return (
        <>
            {activeExample === BusinessPackageExampleType.BOOST_TO_TOP && (
                <BoostToTopAdExample onClick={closeExample} />
            )}
            <Section
                title={t(i18n)`Auto Boost to Top`}
                details={[details]}
                showExampleButton
                exampleText={t(i18n)`Boosted Ad`}
                onClickSeeExample={openExample}
                renderIcon={() => iconSrc && <img src={iconSrc} alt="Auto Boost to Top" />}
                subsections={getSubsectionsByDuration}
                disclaimer={t(i18n)`Can only be used on active ad`}
            />
        </>
    );
};

export default AutoBoostToTopSection;
