import isEqual from 'lodash/isEqual';
import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';

import Page from 'horizontal/pages/page';
import {
    UserOrdersSection,
    ProductPurchaseVirtualStatus,
    UserOrdersSubSection,
} from 'horizontal/userOrders';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { fetchPayments, fetchUserPaymentOrders } from 'horizontal/payment/state';
import { fetchConsumedProductAds } from 'horizontal/userOrders/orders/state';
import { DEFAULT_SEARCH_SORT_OPTION_KEY } from 'horizontal/userOrders/payment';
import { DeliveryRequestStatus } from 'horizontal/userOrders/types';

import { ORDERS_PAGE_SIZE } from '../userOrders/orders/constants';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

const profolioAgentUnallowedSections = [
    UserOrdersSection.PAYMENTS,
    UserOrdersSection.PAYMENT_ORDERS,

    UserOrdersSection.ORDERS,
    UserOrdersSection.BILLING,
    UserOrdersSection.Addresses,
];

export type UserOrdersRouteParams = {
    readonly section?: Values<typeof UserOrdersSection>;
    readonly ordersStatus?: Values<typeof ProductPurchaseVirtualStatus>;
    readonly ordersStatusSubSection?: Values<typeof UserOrdersSubSection>;
    readonly productPurchaseID?: number;
    readonly deliveryOrderStatus?: Values<typeof DeliveryRequestStatus>;
    readonly page?: string;
    readonly sorting?: string;
};

class UserOrdersRoute extends Route {
    constructor() {
        super(RouteNames.USER_ORDERS, [
            [
                '^/myorders/',
                { name: 'section', pattern: `(${Object.values(UserOrdersSection).join('|')})` },
                '(?:\\?.*)?',
                '$',
            ],
            [
                `^/myorders/`,
                { name: 'section', pattern: `(${UserOrdersSection.ORDERS})` },
                '/',
                {
                    name: 'ordersStatus',
                    pattern: `(${[
                        ProductPurchaseVirtualStatus.EXPIRED,
                        ProductPurchaseVirtualStatus.SCHEDULED,
                    ].join('|')})`,
                },
                '(?:\\?.*)?',
                '$',
            ],
            [
                `^/myorders/`,
                { name: 'section', pattern: `(${UserOrdersSection.ORDERS})` },
                '/',
                {
                    name: 'ordersStatus',
                    pattern: `(${[
                        ProductPurchaseVirtualStatus.ACTIVE,
                        ProductPurchaseVirtualStatus.EXPIRED,
                        ProductPurchaseVirtualStatus.SCHEDULED,
                    ].join('|')})`,
                },
                '/',
                {
                    name: 'ordersStatusSubSection',
                    pattern: `(${UserOrdersSubSection.CONSUMPTION})`,
                },
                '/',
                {
                    name: 'productPurchaseID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?.*)?',
                '$',
            ],
            ['^/myorders$'],
            // legacy redirects
            [
                `^/`,
                { name: 'section', pattern: `(${UserOrdersSection.ORDERS})` },
                '/',
                {
                    name: 'ordersStatus',
                    pattern: `(${[
                        ProductPurchaseVirtualStatus.EXPIRED,
                        ProductPurchaseVirtualStatus.SCHEDULED,
                    ].join('|')})`,
                },
                '$',
            ],
            ['^/', { name: 'section', pattern: `(${UserOrdersSection.ORDERS})` }, '$'],
            [
                `^/m/myorders/`,
                { name: 'section', pattern: `(${UserOrdersSection.ORDERS})` },
                '/',
                {
                    name: 'ordersStatus',
                    pattern: `(${[
                        ProductPurchaseVirtualStatus.EXPIRED,
                        ProductPurchaseVirtualStatus.SCHEDULED,
                    ].join('|')})`,
                },
                '$',
            ],
            [
                '^/m/myorders/',
                { name: 'section', pattern: `(${Object.values(UserOrdersSection).join('|')})` },
                '$',
            ],
            [
                `^/myorders/`,
                { name: 'section', pattern: `(${UserOrdersSection.BUYING})` },
                '/',
                {
                    name: 'deliveryOrderStatus',
                    pattern: `(${[
                        DeliveryRequestStatus.ACTIVE,
                        DeliveryRequestStatus.ARCHIVED,
                    ].join('|')})`,
                },
                '$',
            ],
            [
                `^/`,
                { name: 'section', pattern: `(${UserOrdersSection.BUYING})` },
                '/',
                {
                    name: 'deliveryOrderStatus',
                    pattern: `(${[
                        DeliveryRequestStatus.ACTIVE,
                        DeliveryRequestStatus.ARCHIVED,
                    ].join('|')})`,
                },
                '$',
            ],
            [
                `^/myorders/`,
                { name: 'section', pattern: `(${UserOrdersSection.SELLING})` },
                '/',
                {
                    name: 'deliveryOrderStatus',
                    pattern: `(${[
                        DeliveryRequestStatus.ACTIVE,
                        DeliveryRequestStatus.ARCHIVED,
                    ].join('|')})`,
                },
                '$',
            ],
            [
                `^/`,
                { name: 'section', pattern: `(${UserOrdersSection.SELLING})` },
                '/',
                {
                    name: 'deliveryOrderStatus',
                    pattern: `(${[
                        DeliveryRequestStatus.ACTIVE,
                        DeliveryRequestStatus.ARCHIVED,
                    ].join('|')})`,
                },
                '$',
            ],
            ['^/m/myorders$'],
        ]);
    }

    createURL({
        section,
        ordersStatus,
        ordersStatusSubSection,
        productPurchaseID,
        deliveryOrderStatus,
        page,
        sorting,
    }: UserOrdersRouteParams): EnhancedLocation {
        if (!section) {
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'EnhancedLocation'.
            return '/myorders';
        }
        const pathname = `/myorders/${section}`;
        if (
            section === UserOrdersSection.ORDERS &&
            productPurchaseID &&
            ordersStatus &&
            ordersStatusSubSection
        ) {
            const search: Record<string, any> = {};
            if (page && page.toString() !== '1') {
                search.page = page;
            }
            if (sorting && sorting !== DEFAULT_SEARCH_SORT_OPTION_KEY) {
                search.sorting = sorting;
            }

            if (Object.keys(search).length > 0) {
                return {
                    pathname: `${pathname}/${ordersStatus}/${ordersStatusSubSection}/${productPurchaseID}`,
                    search,
                };
            }

            return {
                pathname: `${pathname}/${ordersStatus}/${ordersStatusSubSection}/${productPurchaseID}`,
            };
        }

        if (section === UserOrdersSection.ORDERS && ordersStatus) {
            return { pathname: `${pathname}/${ordersStatus}` };
        }

        if (
            [UserOrdersSection.SELLING, UserOrdersSection.BUYING].includes(section) &&
            deliveryOrderStatus
        ) {
            return { pathname: `${pathname}/${deliveryOrderStatus}` };
        }
        if ([UserOrdersSection.PAYMENTS, UserOrdersSection.PAYMENT_ORDERS].includes(section)) {
            const search: Record<string, any> = {};

            if (page && page.toString() !== '1') {
                search.page = page;
            }
            if (sorting && sorting !== DEFAULT_SEARCH_SORT_OPTION_KEY) {
                search.sorting = sorting;
            }

            if (Object.keys(search).length > 0) {
                return { pathname, search };
            }

            return { pathname };
        }

        return { pathname };
    }

    /**
     * Detects inconsistencies in the URL and redirects to the canonical
     * version of the URL.
     */
    correctURL(url: string): string | null | undefined {
        let redirectURL = url;

        // A URL without the /myorders$ prefix matches,
        // but should be redirected to /myorders/orders/, including all params
        if (redirectURL.startsWith('/orders')) {
            redirectURL = `/myorders${redirectURL}`;
        }

        // A URL starting with m should redirect to the same version, without m
        if (redirectURL.startsWith('/m')) {
            redirectURL = redirectURL.replace(/m\//, '');
        }

        if (isEqual(url, redirectURL)) {
            return null;
        }

        return redirectURL;
    }

    fetchRequiredData(context: RoutingContextWithMiddlewares) {
        const {
            redux: {
                store: { dispatch },
            },
            match: {
                params: {
                    section,
                    ordersStatusSubSection,
                    page,
                    sorting,
                    productPurchaseID,
                    ordersStatus,
                },
            },
        } = context;
        let fetchPromise = null;
        switch (section) {
            case UserOrdersSection.ORDERS:
                if (ordersStatusSubSection) {
                    fetchPromise = dispatch(
                        fetchConsumedProductAds({
                            page,
                            productPurchaseID,
                            pageSize: ORDERS_PAGE_SIZE,
                        }),
                    );
                } else {
                    fetchPromise = dispatch(
                        fetchProductPurchases({
                            status: ordersStatus || ProductPurchaseVirtualStatus.ACTIVE,
                        }),
                    );
                }
                break;
            case UserOrdersSection.PAYMENTS:
                fetchPromise = dispatch(
                    fetchPayments({
                        page: page || '1',
                        sorting: sorting || DEFAULT_SEARCH_SORT_OPTION_KEY,
                    }),
                );
                break;
            case UserOrdersSection.PAYMENT_ORDERS:
                fetchPromise = dispatch(
                    fetchUserPaymentOrders({
                        page: page || '1',
                        sorting: sorting || DEFAULT_SEARCH_SORT_OPTION_KEY,
                    }),
                );
                break;
            default:
                break;
        }
        return fetchPromise;
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        const {
            match: {
                params: { productPurchaseID, section },
                originalURL,
            },
        } = context;

        if (
            profolioAgentUnallowedSections.includes(section) &&
            !ensureActiveUserIsAllowedAccessAndRedirect(context)
        ) {
            return;
        }

        const correctedURL = this.correctURL(originalURL);
        if (correctedURL) {
            context.http.redirect(correctedURL, { status: 301 });
            return;
        }

        context.rendering.renderPage(Page.USER_ORDERS, { productPurchaseID });

        context.promise.wait(this.fetchRequiredData(context));
    }
}

export default new UserOrdersRoute();
