import * as React from 'react';
import { ThumbnailSizes } from 'strat/image';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { useIsProfolioUser } from 'strat/user';

import { CoverPhoto, Link } from 'horizontal/adCard';
import { FullAd } from 'horizontal/types';
import { useAdPhotos, useAdPriceLabel, useLocalizedAdTitle } from 'horizontal/ad';
import Analytics from 'horizontal/adDetails/components/analytics';
import {
    useAdViews,
    useAdPhoneViews,
    useAdChatLeadsCount,
    useAdWhatsAppLeadsCount,
} from 'horizontal/adMetrics';
import { AgentCodeField } from 'horizontal/fields/commonPostingFields';

import AdCTA from './adCTA';
import { useMainCTAInfo, useSecondaryCTAInfo } from './useCTA';
import DatesOfInterest from './datesOfInterest';
import Actions from './actions';
import styles from './styles/listEntry.cssm';
import AdState from './adState';

const ListEntry = ({ ad }: { ad: FullAd }) => {
    const priceLabel = useAdPriceLabel(ad);
    const [coverPhoto] = useAdPhotos(ad);
    const adLocalizedTitle = useLocalizedAdTitle(ad);
    const isProfolioUser = useIsProfolioUser();
    const adCategory = ad.category[ad.category.length - 1];

    const viewCount = useAdViews(ad.externalID);
    const phoneViewCount = useAdPhoneViews(ad.externalID);
    const chatCount = useAdChatLeadsCount(ad.externalID);
    const whatsAppCount = useAdWhatsAppLeadsCount(ad.externalID);
    const mainCTA = useMainCTAInfo(ad);
    const secondaryCTA = useSecondaryCTAInfo(ad);

    return (
        <div className={styles.container}>
            <Link preloadedAd={ad} params={{ slug: ad.slug, externalID: ad.externalID }}>
                <Flex className={styles.ad} fillContainer>
                    <div className={styles.image}>
                        <CoverPhoto
                            cover={coverPhoto}
                            size={ThumbnailSizes.SMALL}
                            lazyLoad
                            category={ad.category}
                            roundedCorners
                        />
                    </div>
                    <Flex column fillContainer className={styles.rightContainer}>
                        <Flex alignCenter stretchWidth className={styles.headerWrapper}>
                            <Text.XLarge bold aria-label="Ad title">
                                {adLocalizedTitle}
                            </Text.XLarge>
                            <Text.Large aria-label="Ad category">
                                - <Trans>in</Trans> {adCategory.name}
                            </Text.Large>
                        </Flex>
                        <Flex alignCenter className={styles.detailsWrapper} wrap>
                            {!!priceLabel && (
                                <>
                                    <div className={styles.price}>
                                        <Text.Large aria-label="Ad price">{priceLabel}</Text.Large>
                                    </div>
                                    <div className={styles.separator} />
                                </>
                            )}
                            <Flex justifyCenter className={styles.date} aria-label="Ad date">
                                <DatesOfInterest ad={ad} />
                            </Flex>
                            <div className={styles.separator} />
                            <AdState ad={ad} />
                        </Flex>
                        <Flex
                            justifySpaceBetween
                            stretchWidth
                            wrap
                            className={styles.bottomWrapper}
                        >
                            <div className={styles.analyticsWrapper}>
                                <Analytics
                                    viewCount={viewCount ?? 0}
                                    phoneViewCount={phoneViewCount ?? 0}
                                    chatCount={chatCount ?? 0}
                                    whatsAppCount={whatsAppCount ?? 0}
                                    agentCode={ad.extraFields?.[AgentCodeField.attribute]}
                                    className={styles.analytics}
                                    wideTile
                                />
                            </div>
                            {!isProfolioUser && (
                                <Flex className={styles.ctaWrapper}>
                                    <AdCTA cta={secondaryCTA} secondary />
                                    <AdCTA cta={mainCTA} />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Link>
            <div className={styles.actions}>{!isProfolioUser && <Actions ad={ad} />}</div>
        </div>
    );
};

export default ListEntry;
