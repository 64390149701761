import * as React from 'react';
import { Trans } from '@lingui/macro';

import { PaymentSource, CheckoutResponseCode, JazzCashResponseCode } from './types';

const processPaymentFailedError = (
    paymentMethod?: string | null,
    paymentResponseCode?: string | null,
    paymentResponseSummary?: string | null,
): {
    errorMessage: React.ReactNode | null | undefined;
    errorTitle?: React.ReactNode | null | undefined;
} => {
    if (paymentMethod === PaymentSource.PAYMOB || paymentMethod === PaymentSource.PAYMOB_WALLET) {
        return {
            errorMessage: <Trans>Transaction failed. Please try again or contact support!</Trans>,
        };
    }

    if (paymentMethod === PaymentSource.EASYPAISA) {
        return {
            errorMessage: <Trans>Transaction failed. Please try again or contact support!</Trans>,
        };
    }

    if (!paymentMethod || !paymentResponseCode) {
        return { errorMessage: paymentResponseSummary };
    }

    if (
        paymentMethod === PaymentSource.CHECKOUT &&
        parseInt(paymentResponseCode, 10) >= CheckoutResponseCode.RISK_ENGINE_LOWEST_CODE &&
        parseInt(paymentResponseCode, 10) <= CheckoutResponseCode.RISK_ENGINE_HIGHEST_CODE
    ) {
        return {
            errorMessage: (
                <Trans>
                    Checkout has declined the transaction. Please use another card or contact
                    Checkout Support!
                </Trans>
            ),
        };
    }

    if (paymentMethod === PaymentSource.JAZZ_CASH_MOBILE_WALLET) {
        // https://sandbox.jazzcash.com.pk/SandboxDocumentation/v4.2/Resources.html jazzcash returns either invalid valur or non-existent account
        // if an invalid account is provided. Also, error code 156 is not documented but returned in practice
        if (
            (paymentResponseCode === JazzCashResponseCode.INVALID_VALUE &&
                paymentResponseSummary === 'Please provide a valid value for pp_ Mobile Number.') ||
            paymentResponseCode === JazzCashResponseCode.NONEXISTENT_ACCOUNT
        ) {
            return {
                errorMessage: (
                    <Trans>
                        Please use a valid JazzCash account or call OLX helpline 0800-10101 for more
                        details. You can try processing your payment via Credit Card or Debit Card.
                    </Trans>
                ),
                errorTitle: <Trans>Your JazzCash wallet does not exist.</Trans>,
            };
        }
        if (paymentResponseCode === JazzCashResponseCode.INSUFFICIENT_FUNDS) {
            return {
                errorMessage: (
                    <Trans>
                        Please add funds in your JazzCash account or try payment from Credit or
                        Debit Card
                    </Trans>
                ),
                errorTitle: <Trans>You have Insufficient Balance in your JazzCash Account.</Trans>,
            };
        }
    }

    return { errorMessage: paymentResponseSummary };
};

export default processPaymentFailedError;
