import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { Flex, Button } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { Trans } from '@lingui/macro';

import { UploadedPhoto } from 'horizontal/types';
import GalleryIcon from 'horizontal/assets/icons/gallery.svg';
import AddIcon from 'horizontal/assets/icons/addCardButton.svg';
import Image from 'horizontal/fields/image';

import { ImageType, UploadingImage, useUploadImages } from '../useUploadImages';
import ImageInputFooter from '../imageInputFooter';
import { ImageCollectionInputProps } from '../types';

import styles from './styles/imageCollectionInput.cssm';

const generateImageID = (prefix: string) => `${prefix}-${uuid4()}`;

const mapImages = (images: Array<ImageType>): Array<UploadedPhoto> =>
    (images.filter((image) => !!image && !!image.src) as Array<UploadingImage>).map((image) => ({
        id: image.imageID,
        // we only set the url for new images (those do not have imageID)
        url: image.imageID ? null : image.src,
        externalID: image.externalID as string,
        width: image.width,
        height: image.height,
    }));

const ImageCollectionInput = ({
    maxImagesCount = 20,
    initialImagesCount = 13,
    initialValue = [],
    setFieldValue,
    touched,
    errors,
    onBlur,
    setLoading,
    field,
}: ImageCollectionInputProps) => {
    const user = useActiveUser();
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const {
        images,
        remove,
        retry,
        anyImageTooLarge,
        anyImageResolutionTooHigh,
        anyImageLoading,
        anyUploadFailed,
        uploadImages,
    } = useUploadImages({
        initialImagesCount,
        maxImagesCount,
        generateImageID: () => generateImageID(String(user?.id)),
        initialValue,
    });

    const onChange = React.useCallback(
        (event) => {
            const files = Array.from(event.target.files) as File[];

            uploadImages(files);

            event.target.value = null;
        },
        [uploadImages],
    );

    React.useEffect(() => {
        setLoading(anyImageLoading);
        if (anyImageTooLarge || anyUploadFailed || anyImageResolutionTooHigh) {
            setLoading(true);
        }
    }, [anyImageLoading, anyImageTooLarge, anyUploadFailed, anyImageResolutionTooHigh, setLoading]);

    React.useEffect(() => {
        setFieldValue(field.attribute, mapImages(images));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);

    const hasImages = React.useMemo(() => images.find((image) => !!image), [images]);

    const openFilePicker = (event: React.MouseEvent) => {
        inputRef.current?.click();
        event.preventDefault();
    };

    return (
        <Flex column alignCenter justifyCenter className={styles.fieldWrapper}>
            <label className={styles.imageInput}>
                <input
                    ref={inputRef}
                    type="file"
                    name={field.attribute}
                    accept="image/png, image/jpeg"
                    autoComplete="off"
                    multiple
                    className={styles.input}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {hasImages ? (
                    <Flex stretchWidth className={styles.listContainer}>
                        <Flex justifyCenter alignCenter className={styles.addImageButton}>
                            <AddIcon className={styles.addIcon} />
                        </Flex>
                        {images.map((image, index) => (
                            <Image
                                {...image}
                                isCompact
                                retry={() => (image ? retry(image) : null)}
                                remove={() => (image ? remove(image) : null)}
                                isCoverImage={index === 0}
                            />
                        ))}
                    </Flex>
                ) : (
                    <>
                        <GalleryIcon />
                        <Button secondary className={styles.button} onClick={openFilePicker}>
                            <Trans>Add images</Trans>
                        </Button>
                    </>
                )}
            </label>
            <div className={styles.footerContainer}>
                <ImageInputFooter
                    isTouched={touched[field.attribute]}
                    anyUploadFailed={anyUploadFailed}
                    anyImageTooLarge={anyImageTooLarge}
                    anyImageResolutionTooHigh={anyImageResolutionTooHigh}
                    errorMessage={errors[field.attribute]}
                />
            </div>
        </Flex>
    );
};

export default ImageCollectionInput;
