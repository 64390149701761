import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { StylelessButton } from 'strat/generic';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import { useFlatCategoryFields } from 'horizontal/categoryFields';
import { selectCategoryByExternalID } from 'horizontal/categories';
import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';

import type { SavedSearchData } from './types';
import styles from './styles/savedSearchesCardFilters.cssm';
import useSearchFiltersFormattedExtraFields from './useSearchFiltersFormattedExtraFields';
import { useSavedSearchesLocationData } from './useSavedSearchesLocationData';
import SavedSearchesCardFiltersRow from './savedSearchesCardFiltersRow';

const MIN_FILTERS_PER_CARD = 3;

const SavedSearchesCardFilters = ({ savedSearch }: { savedSearch: SavedSearchData }) => {
    const { params } = savedSearch;

    const [isOpen, setIsOpen] = React.useState(false);
    const [displayCount, setDisplayCount] = React.useState(MIN_FILTERS_PER_CARD);

    const categoryFields = useFlatCategoryFields(params.category?.id, params.extraFields);
    const formattedExtraFields = useSearchFiltersFormattedExtraFields(
        params.extraFields,
        categoryFields,
    );
    const filteredFormattedExtraFields = React.useMemo(
        () =>
            formattedExtraFields.filter(
                (element, index, self) => index === self.findIndex((t) => t.name === element.name),
            ),
        [formattedExtraFields],
    );
    const category = useSelector((state) =>
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'never'.
        selectCategoryByExternalID(state, params?.category?.externalID),
    );
    const location = useSavedSearchesLocationData(savedSearch);

    const filters = [
        location ? (
            <SavedSearchesCardFiltersRow
                key={'filter-location'}
                label={<Trans>Location</Trans>}
                value={location?.name}
            />
        ) : null,
        category ? (
            <SavedSearchesCardFiltersRow
                key={'filter-category'}
                label={<Trans>Category</Trans>}
                value={category?.name}
            />
        ) : null,
        params?.freeTextQuery ? (
            <SavedSearchesCardFiltersRow
                key={'filter-search-keyword'}
                label={<Trans>Search keyword</Trans>}
                value={params.freeTextQuery}
            />
        ) : null,
        params?.locationRadius ? (
            <SavedSearchesCardFiltersRow
                key={'filter-location-radius'}
                label={<Trans>Search location radius</Trans>}
                value={params.locationRadius}
            />
        ) : null,
        ...filteredFormattedExtraFields.map((formattedExtraField) => (
            <SavedSearchesCardFiltersRow
                key={`filter-${formattedExtraField.name}`}
                label={formattedExtraField.name}
                value={formattedExtraField.formattedValue}
            />
        )),
    ].filter((item) => item);

    const moreFiltersCount = filters.length - MIN_FILTERS_PER_CARD;

    return (
        <Flex column className={styles.container}>
            {filters.slice(0, displayCount)}
            {filters.length > MIN_FILTERS_PER_CARD && (
                <StylelessButton
                    className={styles.button}
                    onClick={() => {
                        setIsOpen(!isOpen);
                        setDisplayCount(isOpen ? MIN_FILTERS_PER_CARD : filters.length);
                    }}
                >
                    {isOpen ? (
                        <Trans>{`Hide ${moreFiltersCount} filters`}</Trans>
                    ) : (
                        <Trans>{`Show ${moreFiltersCount} filters`}</Trans>
                    )}
                    <IconArrowDown
                        className={classNames(styles.icon, { [styles.isOpen]: isOpen })}
                    />
                </StylelessButton>
            )}
        </Flex>
    );
};

export default SavedSearchesCardFilters;
