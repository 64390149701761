//@ts-nocheck
export default Object.freeze({
    CALL_BUTTON: 'call_button',
    SMS_BUTTON: 'sms_button',
    WHATSAPP_BUTTON: 'whatsapp_button',
    EMAIL_BUTTON: 'email_button',
    SETUP_ALERT: 'setup_alert',
    SAVE_SEARCH: 'save_search',
    FAVORITE_BUTTON: 'favourite_button',
    CONTACT_BUTTON: 'contact_button',
    CHAT_BUTTON: 'chat_button',
});
