import { t } from '@lingui/macro';
import * as React from 'react';
import { useRouter } from 'react-true-router';
import { useI18n } from 'strat/i18n/language';

import type { ChatRoom } from 'horizontal/chat/types';
import { useChatLink } from '@app/chat/hooks/useChatLink';

import useChatSDK from './useChatSDK';

const useChatListItemOptions = ({ chatRoom }: { chatRoom: ChatRoom }) => {
    const router = useRouter();
    const i18n = useI18n();
    const sdk = useChatSDK();
    const markAsImportant = sdk.useSetImportantChatRoom(chatRoom.xid);
    const deleteRoom = sdk.useDeleteChatRoom(chatRoom.xid);
    const isImportant = !!chatRoom.userFields?.isImportant;
    const { disabled } = sdk.useRoomDisabledInfo(chatRoom);
    const { route, params } = useChatLink({ adXID: chatRoom.adXID });

    return React.useMemo(() => {
        const options = [
            {
                id: 1,
                label: t(i18n)`Delete chat`,
                onClick: () => {
                    deleteRoom();
                    router.replaceRoute(route, params);
                },
            },
        ];
        if (disabled) {
            return options;
        }

        options.push({
            id: 2,
            label: isImportant ? t(i18n)`Remove important` : t(i18n)`Mark as important`,
            onClick: () => markAsImportant(!isImportant),
        });

        return options;
    }, [i18n, isImportant, markAsImportant, deleteRoom, router, disabled, params, route]);
};

export default useChatListItemOptions;
