import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';

import { selectFeaturedAgencies } from 'horizontal/featuredAgencies/selectors';

import FeaturedAgenciesCarousel from './featuredAgenciesCarousel';
import styles from './styles/featuredAgenciesCore.cssm';

type GroupSize = {
    readonly [key: number]: number;
};

type AgenciesCoreProps = {
    readonly groupSize: GroupSize;
    readonly computeGroupSize: (windowWidth?: number | null | undefined) => number;
    readonly renderPreviousButton: (
        previousFunction: () => void,
        canGoPrevious: boolean,
    ) => React.ReactElement;
    readonly renderNextButton: (nextFunction: () => void, canGoNext: boolean) => React.ReactElement;
    readonly compact: boolean;
};

const FeaturedAgenciesCore = ({
    groupSize,
    computeGroupSize,
    renderPreviousButton,
    renderNextButton,
    compact,
}: AgenciesCoreProps) => {
    const featuredAgencies = useSelector(selectFeaturedAgencies);
    const i18n = useI18n();
    const reversed = useSelector(selectIsLanguageRTL);

    if (!featuredAgencies.length) {
        return null;
    }

    return (
        <div className={styles.featuredAgencies}>
            <h4 className={styles.featuredAgenciesHeading}>{t(i18n)`Featured Businesses`}</h4>
            <FeaturedAgenciesCarousel
                // @ts-expect-error - TS2322 - Type 'readonly never[] | Agency[]' is not assignable to type 'Agency[]'.
                items={featuredAgencies}
                groupSize={groupSize}
                computeGroupSize={computeGroupSize}
                renderPreviousButton={renderPreviousButton}
                renderNextButton={renderNextButton}
                reversed={reversed}
                compact={compact}
            />
        </div>
    );
};

export default FeaturedAgenciesCore;
