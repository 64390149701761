/**
 * List of cookies we set.
 */
export default {
    MPA_CD: CONFIG.build.MPA_CD_COOKIE_NAME || 'byt_cd',
    SETTINGS: 'settings',
    EXPRESS_SESSION: 'session',
    ANONYMOUS_SESSION_ID: 'anonymous_session_id',
    EXPERIMENTS: 'abTests',
    BANNERS: 'banners',
    USER_CITY: 'userCity',
    USER_LOCATION: 'userLocation',
    USER_GEOLOCATION: 'userGeoLocation',
    REFERRER: 'referrer',
    ORIGINAL_REFERRER: 'original_referrer',
    LANDING_URL: 'landing_url',
    GOOGLE_ANALYTICS: '_ga',
    FACEBOOK_FBP: '_fbp',
    FACEBOOK_FBC: '_fbc',
    DEVICE_ID: 'device_id',
    NOTIFICATIONS_PROMPT_POSTPONED: 'notifications_prompt_postponed',
    NOTIFICATIONS_BACKOFF_UNTIL: 'notifications_backoff',
    SITE_UNDER_MAINTENANCE_PROMPT: 'SITE_UNDER_MAINTENANCE_PROMPT',
    DEEPLINK_DISMISSED: 'deeplink_dismissed',

    KEYCLOAK_ACCESS_TOKEN: 'kc_access_token',
    KEYCLOAK_REFRESH_TOKEN: 'kc_refresh_token',
    KEYCLOAK_ID_TOKEN: 'kc_id_token',

    STRAT_DEBUG: 'strat_debug',
    BAYUTGPT_LOGGED_OUT_LIMIT_REACHED: 'bayutgpt_rate_limit',
    MINI_FORM_ACTIVE_TAB: 'mini_form_active_tab',
} as const;
