import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import type { ChatAd } from 'horizontal/chat/types';
import useChatGTMVarsProps from 'horizontal/chat/useChatGTMVarsProps';
import { useTrackOfferOption, useTrackOfferSent } from 'horizontal/gtm';

import PriceFeedback from './priceFeedback';
import PriceInput from './priceInput';
import styles from './styles/makeOfferForm.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly onSendClick: (value?: number | null | undefined) => unknown;
    readonly adPrice: number;
    readonly rejectedPrice?: number | null | undefined;
    readonly lastSellerOfferPrice?: number | null | undefined;
};

const MakeOfferForm = ({
    ad,
    onSendClick,
    adPrice,
    rejectedPrice,
    lastSellerOfferPrice,
}: Props) => {
    const [offeredPrice, setOfferedPrice] = React.useState<number | null | undefined>(
        lastSellerOfferPrice || adPrice,
    );

    const gtmProps = useChatGTMVarsProps();
    const trackOfferSent = useTrackOfferSent(gtmProps);
    const trackOfferOption = useTrackOfferOption(gtmProps);

    const priceSuggestions = [
        ...new Set([...Array(5).keys()].map((item) => Math.round(adPrice * (1 - 0.05 * item)))),
    ];

    const onPriceOptionSelected = (price: number) => {
        trackOfferOption(price);
        setOfferedPrice(price);
    };

    return (
        <Flex column className={styles.container}>
            <Flex className={styles.priceSuggestions}>
                {rejectedPrice ? (
                    <Flex>
                        <Flex alignCenter className={styles.infoBox}>
                            {rejectedPrice.toLocaleString()}
                            <Text.Base uppercase className={styles.rejectedLabel}>
                                <Trans>Rejected</Trans>
                            </Text.Base>
                        </Flex>
                        {lastSellerOfferPrice ? (
                            <Flex alignCenter className={styles.infoBox}>
                                <Trans>{"Seller's offer"}</Trans>
                                <Text.Base className={styles.sellerOfferPrice}>
                                    {lastSellerOfferPrice.toLocaleString()}
                                </Text.Base>
                            </Flex>
                        ) : null}
                    </Flex>
                ) : (
                    priceSuggestions.map((price) => (
                        <Text.Base
                            key={price}
                            className={classNames(styles.priceSuggestion, {
                                [styles.selected]: price === offeredPrice,
                            })}
                            onClick={() => onPriceOptionSelected(price)}
                        >
                            {price.toLocaleString()}
                        </Text.Base>
                    ))
                )}
            </Flex>
            <PriceInput
                price={offeredPrice}
                setPrice={setOfferedPrice}
                className={styles.priceInput}
            />
            <PriceFeedback
                ad={ad}
                price={adPrice}
                offeredPrice={offeredPrice}
                onSendClick={(value?: number | null) => {
                    onSendClick(value);
                    trackOfferSent(value);
                }}
                minPrice={rejectedPrice ? rejectedPrice + 1 : null}
            />
        </Flex>
    );
};

export default React.memo<Props>(MakeOfferForm);
