// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import { StratAPI } from 'strat/api';
import type { CategoryNodeData } from 'strat/property';
import FilterValues from 'strat/search/filterValues';

export type ProjectMapCollectionParams = {
    category: string;
    locations?: Array<string> | null;
};

export const fetchProjectMapCollections = (params: ProjectMapCollectionParams) =>
    new StratAPI().projectMapBasedSearch(params.category, params.locations);

export const getProjectMapCollectionParams = (getState: GetState): ProjectMapCollectionParams => {
    const filterCollection = getFilterCollection(getState());
    const category = filterCollection.getFilterValue<CategoryNodeData, Partial<CategoryNodeData>>(
        FilterValues.category.attribute,
        FilterValues.category.default,
    ).externalID as string;

    return { category };
};
