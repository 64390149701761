import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID } from 'strat/user/session';
import { catchCanceled } from 'strat/util';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import { preloadAd } from 'horizontal/ad/state';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import { getStratAPI, StratAPI } from 'horizontal/api';
import { ProductPurchaseStatus } from 'horizontal/packages';
import Page from 'horizontal/pages/page';
import type { FullAd, ProductPackageOffer, ProductPurchase } from 'horizontal/types';
import { AdState } from 'horizontal/types';
import { isAdAgent } from 'horizontal/util';

import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';
import ensureHasActiveUser from './ensureHasActiveUser';

export type ApplyAdLimitPackageRouteParams = {
    externalID: string;
};

type FetchDataReturn = {
    readonly ad: FullAd | null | undefined;
    readonly productPurchases?: Array<ProductPurchase> | null | undefined;
    readonly offerPackages?: Array<ProductPackageOffer> | null | undefined;
};

class ApplyAdLimitPackageRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.APPLY_AD_LIMIT_PACKAGE, [
            [
                '^/payments/choosePackage/single/limits/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({ externalID }: ApplyAdLimitPackageRouteParams): EnhancedLocation {
        return { pathname: `/payments/choosePackage/single/limits/${externalID}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        context.rendering.renderPage(Page.APPLY_AD_LIMIT_PACKAGE);

        const { dispatch } = context.redux.store;
        context.promise.wait(
            this.fetchData(context)
                .then(({ ad, productPurchases, offerPackages }) => {
                    if (!ad) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    dispatch(preloadAd(ad));
                    this.renderTargetPage(context, { ad, productPurchases, offerPackages });
                })
                .catch(catchCanceled),
        );
    }

    renderTargetPage(context: RoutingContextWithMiddlewares, data: FetchDataReturn): void {
        const { getState } = context.redux.store;
        const isMobile = selectIsMobileLayout(getState());

        if (isMobile && !data.productPurchases) {
            context.rendering.renderPage(Page.SELECT_SINGLE_LIMIT_PACKAGE, data);
            return;
        }

        context.rendering.renderPage(Page.APPLY_AD_LIMIT_PACKAGE, data);
    }

    fetchData(context: RoutingContextWithMiddlewares): Promise<FetchDataReturn> {
        const {
            redux: {
                store: { dispatch },
            },
        } = context;

        return Promise.all([
            this.fetchPackages(context),
            this.cancelable(dispatch(fetchFreeAdLimitUsage())),
        ]).then(([{ ad, productPurchases, offerPackages }]) => ({
            ad,
            productPurchases,
            offerPackages,
        }));
    }

    fetchPackages(context: RoutingContextWithMiddlewares): Promise<FetchDataReturn> {
        return this.fetchAd(context).then(
            (ad) =>
                this.fetchProductPurchases(context, ad).then((productPurchases) => {
                    if (!productPurchases || !productPurchases.length) {
                        return this.fetchOffers(context, ad).then((offerPackages) => ({
                            ad,
                            productPurchases: null,
                            offerPackages,
                        }));
                    }

                    return {
                        ad,
                        productPurchases,
                        offerPackages: null,
                    };
                }),
            (ad) =>
                this.fetchOffers(context, ad).then((offerPackages) => ({
                    ad,
                    productPurchases: null,
                    offerPackages,
                })),
        );
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<FullAd | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!externalID || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest<FullAd | null>(context, (api) =>
            api.userAd(userExternalID, externalID),
        ).then((ad) => (ad?.state !== AdState.LIMITED ? null : ad));
    }

    fetchProductPurchases(
        context: RoutingContextWithMiddlewares,
        ad: FullAd | null | undefined,
    ): Promise<Array<ProductPurchase> | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!externalID || !ad || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) =>
            api.productPurchases({
                userExternalID: userExternalID,
                adExternalID: ad.externalID,
                status: ProductPurchaseStatus.ACTIVE,
            }),
        );
    }

    fetchOffers(
        context: RoutingContextWithMiddlewares,
        ad: FullAd | null | undefined,
    ): Promise<Array<ProductPackageOffer> | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!externalID || !ad || !userExternalID) {
            return Promise.resolve(null);
        }

        const adAgent = isAdAgent(ad, userExternalID);
        if (adAgent) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) =>
            api.getAdProductPackageOffers(externalID),
        );
    }

    private makeStratAPIRequest<T>(
        context: RoutingContextWithMiddlewares,
        callback: (api: StratAPI) => Promise<{ status: number; data: T }>,
    ) {
        const {
            redux: {
                store: { getState },
            },
        } = context;

        const api = getStratAPI(getState());
        const request = this.cancelable(callback(api));

        return request.then((response) => (response.status < 300 ? response.data : null));
    }
}

export default new ApplyAdLimitPackageRoute();
