import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { ContactPersonRole } from 'horizontal/types';

export type ContactSettings = {
    [ContactPersonRole.SHOW_PHONE_NUMBER]: boolean;
    [ContactPersonRole.ALLOW_CHAT_COMMUNICATION]: boolean;
};

interface Props {
    showPhoneNumber: boolean | null | undefined;
    allowChatCommunication: boolean | null | undefined;
    trackShowPhoneNumber?: (arg1: boolean) => void;
    onSettingsChanged?: (arg1: ContactSettings) => void;
}

const useContactMethodChoices = ({
    showPhoneNumber,
    allowChatCommunication,
    trackShowPhoneNumber,
    onSettingsChanged,
}: Props) => {
    const i18n = useI18n();
    const enableContactMethodControl = settings.enableContactMethodControl;
    const defaultAllowChatCommunication = settings.defaultAllowChatCommunication;

    const selectedValue = React.useMemo(() => {
        if (showPhoneNumber && allowChatCommunication) {
            return 'both';
        } else if (showPhoneNumber) {
            return ContactPersonRole.SHOW_PHONE_NUMBER;
        } else if (allowChatCommunication) {
            return ContactPersonRole.ALLOW_CHAT_COMMUNICATION;
        }
        return null;
    }, [showPhoneNumber, allowChatCommunication]);

    const isSelected = (settingName: string, value: undefined | boolean | string) =>
        settingName === value || value === 'both';

    const saveSettings = React.useCallback(
        (value): void => {
            let contactSettings;
            // enableContactMethodControl decides on whether we control both communication methods or only phone number
            // defaultAllowChatCommunication sets the default value for the chat communication if we control only the phone number
            if (!enableContactMethodControl) {
                contactSettings = {
                    [ContactPersonRole.SHOW_PHONE_NUMBER]: !showPhoneNumber,
                    [ContactPersonRole.ALLOW_CHAT_COMMUNICATION]: defaultAllowChatCommunication,
                };
                onSettingsChanged?.(contactSettings);
                trackShowPhoneNumber?.(!showPhoneNumber);
                return;
            }
            contactSettings = {
                [ContactPersonRole.SHOW_PHONE_NUMBER]: isSelected(
                    ContactPersonRole.SHOW_PHONE_NUMBER,
                    value,
                ),
                [ContactPersonRole.ALLOW_CHAT_COMMUNICATION]: isSelected(
                    ContactPersonRole.ALLOW_CHAT_COMMUNICATION,
                    value,
                ),
            };

            if (value === ContactPersonRole.SHOW_PHONE_NUMBER) {
                // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
                trackShowPhoneNumber?.(isSelected(ContactPersonRole.SHOW_PHONE_NUMBER));
            }
            onSettingsChanged?.(contactSettings);
        },
        [
            onSettingsChanged,
            trackShowPhoneNumber,
            defaultAllowChatCommunication,
            enableContactMethodControl,
            showPhoneNumber,
        ],
    );

    const brandName = settings.getBrandName(i18n);

    const choices = React.useMemo(
        () => [
            {
                value: ContactPersonRole.SHOW_PHONE_NUMBER,
                label: t(i18n)`Phone Number`,
            },
            {
                value: ContactPersonRole.ALLOW_CHAT_COMMUNICATION,
                label: t(i18n)`${brandName} Chat`,
            },
            {
                value: 'both',
                label: t(i18n)`Both`,
            },
        ],
        [i18n, brandName],
    );

    return {
        enableContactMethodControl,
        selectedValue,
        choices,
        saveSettings,
    };
};

export default useContactMethodChoices;
