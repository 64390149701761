import type { ChoiceValue, CategoryChoice } from 'strat/components/types';

interface Category {
    id: number;
    name: string;
    externalID: string;
    children?: Category[];
}

export const buildChoices = (categories: readonly Category[]): Array<CategoryChoice<string>> => {
    return categories.map((category) => ({
        label: category.name,
        id: category.id,
        value: category.externalID,
        children: category.children ? buildChoices(category.children) : [],
    }));
};

export const filterCategories = (
    categories: Array<CategoryChoice<ChoiceValue>>,
    query: string,
): Array<CategoryChoice<ChoiceValue>> => {
    const filtered: Array<CategoryChoice<ChoiceValue>> = [];

    for (const category of categories) {
        const categoryLabel = category.label.toLowerCase();
        const subCategories = category.children ? filterCategories(category.children, query) : [];

        if (categoryLabel.includes(query) || subCategories.length > 0) {
            filtered.push({
                ...category,
                children: subCategories,
            });
        }
    }

    return filtered;
};
