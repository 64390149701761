import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

import { Unit, UnitType, Area } from 'strat/i18n';
import { Currency } from 'strat/i18n/money';
import Purpose, { PurposeTextDisplay } from 'strat/purpose';
import { FilterValues } from 'strat/search';
import { locationHierarchyToString } from 'strat/misc';
import type { PropertyData } from 'strat/property/types';

/**
 * Compute the default property description for SEO purposes
 */
export default (i18n: I18n, property: PropertyData): string => {
    // @ts-expect-error - TS7006 - Parameter 'photos' implicitly has an 'any' type.
    const formatPhotos = (photos) =>
        t(i18n)`${plural(photos, {
            one: '# photo',
            two: '# photos',
            few: '# photos',
            many: '# photos',
            other: '# photos',
        })}`;

    const formatRooms = (isStudio: any, rooms: any) => {
        if (isStudio) {
            return t(i18n)`Studio`;
        }

        return t(i18n)`${plural(rooms, {
            '=0': '',
            one: '# bed,',
            two: '# beds,',
            few: '# beds,',
            many: '# beds,',
            other: '# beds,',
        })}`;
    };

    // @ts-expect-error - TS7006 - Parameter 'baths' implicitly has an 'any' type.
    const formatBaths = (baths) => {
        if (baths === 0) {
            return '';
        }

        return t(i18n)`${plural(baths, {
            '=0': '',
            one: '# bath,',
            two: '# baths,',
            few: '# baths,',
            many: '# baths,',
            other: '# baths,',
        })}`;
    };

    const formatArea = (area: any) => {
        const localizedArea = Math.round(
            new Unit(area, UnitType.Area, Area.SQM).convert(settings.defaultAreaUnit),
        );

        const unit = Area.abbreviation(i18n, settings.defaultAreaUnit);

        return t(i18n)`${localizedArea} ${unit}`;
    };

    const formatType = () =>
        FilterValues.category.render(i18n, property.category.slice(-1)[0].slug)?.toLowerCase();

    const formatPurpose = (purpose: any) =>
        Purpose.text(i18n, purpose, PurposeTextDisplay.CONSISTENT_PREPOSITION).toLowerCase();

    /**
     * Computes the default description, similar to:
     *
     * View details & 10 photos of this 1 bed, 2 bath, 704 sqft apartment for sale in Bay Central
     * West, Bay Central  for AED 1,350,000 listed by Property Junction International. Ref# 2909746
     */
    const photoCount = formatPhotos(property.photos ? property.photos.length : 0);
    const rooms = formatRooms(property.isStudio, property.rooms);
    const baths = formatBaths(property.baths);
    const area = formatArea(property.area);
    const type = formatType();
    const purpose = formatPurpose(property.purpose);
    const location = locationHierarchyToString(property.location, undefined, i18n);
    const price = `${Currency.acronym(i18n, settings.defaultCurrencyName)} ${property.price}`;
    const agency = property.agency ? property.agency.name : '';
    const reference = property.referenceNumber;

    return t(
        i18n,
    )`View details & ${photoCount} of this ${rooms} ${baths} ${area} ${type} ${purpose} in ${location} for ${price} listed by ${agency}. Ref # ${reference}`;
};
