import isEqual from 'lodash/isEqual';
import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RouteParameters } from 'react-true-router/route';
import RouteNames from 'strat/routes/routeNames';
import { selectUserExternalID, selectUserRoles } from 'strat/user/session';
import { isProfolioUser } from 'strat/user/roles';

import Page from 'horizontal/pages/page';
import { fetchUserAgencies } from 'horizontal/agent/state';
import AgencyPortalCreateAdRoute from 'horizontal/agencyPortal/routes/agencyPortalCreateAdRoute';

import AdManagementRoute from './adManagementRoute';
import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

class CreateAdRoute extends Route {
    constructor() {
        super(RouteNames.CREATE_AD, [
            ['^/post', { name: 'attributes', pattern: '(/attributes)?' }],
            // legacy routes
            ['^/post_item', { name: 'attributes', pattern: '(/attributes)?' }],
            ['^/adding', { name: 'attributes', pattern: '(/attributes)?' }],
            ['^/posting', { name: 'attributes', pattern: '(/attributes)?' }],
            ['^/m/post', { name: 'attributes', pattern: '(/attributes)?' }],
        ]);
    }

    createURL(params: RouteParameters): EnhancedLocation {
        const pathname = params?.attributes ? '/post/attributes' : '/post';
        return { pathname };
    }

    correctURL(url: string): string | null | undefined {
        let redirectURL = url;

        if (redirectURL.startsWith('/post_item')) {
            redirectURL = redirectURL.replace('post_item', 'post');
        }
        if (redirectURL.startsWith('/adding')) {
            redirectURL = redirectURL.replace('adding', 'post');
        }
        if (redirectURL.startsWith('/posting')) {
            redirectURL = redirectURL.replace('posting', 'post');
        }
        if (redirectURL.startsWith('/m')) {
            redirectURL = redirectURL.replace(/m\//, '');
        }

        if (isEqual(url, redirectURL)) {
            return null;
        }

        return redirectURL;
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const userRoles = selectUserRoles(context.redux.store.getState());

        // Profolio users will be redirected to MyAds page and credit system users to Agency Portal
        const redirectURL = isProfolioUser(userRoles)
            ? AdManagementRoute.createURL({})
            : AgencyPortalCreateAdRoute.createURL({});

        if (
            !ensureHasActiveUser(context) ||
            !ensureActiveUserIsAllowedAccessAndRedirect(context, redirectURL)
        ) {
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { attributes },
                originalURL,
            },
        } = context;

        const correctedURL = this.correctURL(originalURL);
        const userExternalID = selectUserExternalID(getState());
        if (correctedURL) {
            context.http.redirect(correctedURL, { status: 301 });
            return;
        }

        if (process.env.IS_SERVER && attributes) {
            // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
            context.http.redirect(this.createURL({}));
            return;
        }

        context.rendering.renderPage(Page.CREATE_AD);
        context.promise.wait(dispatch(fetchUserAgencies({ userExternalID })));
    }
}

export default new CreateAdRoute();
