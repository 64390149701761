import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import Link from 'strat/navigation/link';
import { selectRouteName } from 'strat/routes/selectors';
import { createSearchRouteParams } from 'strat/search/url';

import styles from './styles/filterLink.cssm';

type Props = {
    filterValue: any;
    filterChoice: any;
    children: React.ReactNode;
    className?: string;
};

const FilterLink = ({ filterValue, filterChoice, children, className }: Props) => {
    const routeName = useSelector(selectRouteName);
    const filters = useSelector(getFilterCollection);

    return (
        <Link
            route={routeName}
            params={createSearchRouteParams(filters, {
                [filterValue.attribute]: filterChoice.value,
            })}
            className={classNames(className, styles.filterLink)}
            invoke={false}
        >
            {children}
        </Link>
    );
};

export default FilterLink;
