import * as React from 'react';

import { trackSubscribeToMarketingEmailsClick } from 'strat/gtm';

import MarketingEmailsOptInCheckBox from '../components/marketingEmailsOptInCheckBox';

const useMarketingEmailsOtpIn = () => {
    const [subscribeToMarketingEmails, setSubscribeToMarketingEmails] = React.useState(
        CONFIG.build.STRAT_ENABLE_SHOW_MARKETING_CHECKBOX_ON_SIGNUP,
    );

    const onClickMarketingEmailsCheckBox = () => {
        trackSubscribeToMarketingEmailsClick(!subscribeToMarketingEmails);
        setSubscribeToMarketingEmails(!subscribeToMarketingEmails);
    };

    const renderMarketingEmailsOptIn = () => (
        <MarketingEmailsOptInCheckBox
            checked={subscribeToMarketingEmails}
            onChange={onClickMarketingEmailsCheckBox}
        />
    );

    return {
        subscribeToMarketingEmails,
        setSubscribeToMarketingEmails,
        renderMarketingEmailsOptIn,
    };
};

export default useMarketingEmailsOtpIn;
