import * as React from 'react';
import { Trans } from '@lingui/macro';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';

import { SearchLink } from 'horizontal/search/routing';
import type { LiteCategory } from 'horizontal/types';
import { sortByDisplayPriority } from 'horizontal/util';

import type { SitemapCategoryChild, SitemapFilter } from './types';
import styles from './styles/sitemapCategories.cssm';
import SitemapList from './sitemapList';
import { selectSitemapCategories } from './state/selectors';

const renderTitle = (category: LiteCategory) => (
    // @ts-expect-error - TS2322 - Type '{ name?: string | undefined; nameShort?: string | undefined; slug?: string | undefined; id?: number | undefined; level?: number | undefined; externalID?: string | undefined; locationDepthLimits?: LocationDepthLimits | ... 1 more ... | undefined; }' is not assignable to type 'Category'.
    <SearchLink params={{ category: { ...omit(category, ['children']) } }} className={styles.title}>
        <Text.Large bold>{category.name}</Text.Large>
    </SearchLink>
);

const renderFilters = (
    filter: string | SitemapFilter,
    filterIndex: number,
    category: LiteCategory,
) => {
    const filterValues = typeof filter === 'string' ? JSON.parse(filter) : filter;
    return (
        <li key={`${filterValues.value}${filterIndex}`} className={styles.listItem}>
            <SearchLink
                params={{
                    // @ts-expect-error - TS2322 - Type 'LiteCategory' is not assignable to type 'Category | null | undefined'.
                    category,
                    extraFields: filterValues.extraFields,
                }}
                className={styles.listItemLink}
            >
                <Text.Regular capitalized>{filterValues.value}</Text.Regular>
            </SearchLink>
        </li>
    );
};

const renderListItems = (items: Array<SitemapCategoryChild>) =>
    items.map((item, index) => (
        <li key={index}>
            {/* @ts-expect-error - TS2322 - Type 'SitemapCategory' is not assignable to type 'Category | null | undefined'. */}
            <SearchLink params={{ category: item.category }} className={styles.subtitle}>
                {item.category.name}
            </SearchLink>
            <ul className={styles.list}>
                <div>
                    {item.filters &&
                        item.filters.map((filter, filterIndex) =>
                            renderFilters(filter, filterIndex, item.category),
                        )}
                </div>
                <div>{item.category.children && renderListItems(item.category.children)}</div>
            </ul>
        </li>
    ));

const SitemapCategories = () => {
    const categories = useSelector(selectSitemapCategories);

    if (!categories || categories.length === 0) {
        return null;
    }

    const sortedCategories = categories[0].categories.sort(sortByDisplayPriority);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Text.XXLarge bold>
                    <Trans>Categories</Trans>
                </Text.XXLarge>
            </div>
            <Flex className={styles.container}>
                {/* @ts-expect-error - TS7006 - Parameter 'category' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type. */}
                {sortedCategories.map((category, index) => (
                    <SitemapList
                        renderTitle={() => renderTitle(category)}
                        // @ts-expect-error - TS2322 - Type 'Element[]' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
                        renderListItems={() => renderListItems(category.children)}
                        key={`${category.externalID}${index}`}
                        containerClass={styles.listContainer}
                    />
                ))}
            </Flex>
        </div>
    );
};

export default SitemapCategories;
