import Category from '@app/branding/category';

import type { LiteCategory } from 'horizontal/types';
import { VerticalSelection } from 'horizontal/types';
import { VerticalsRouteClass } from 'horizontal/routes';

type HeaderLinkType = {
    readonly isMotorsLink?: boolean;
    readonly isPropertyLink?: boolean;
    readonly name: string;
};

const shouldHighlightVertical = (
    headerLink: HeaderLinkType,
    withHighlightedVertical?: boolean,
    category?: LiteCategory | null,
    vertical?: Values<typeof VerticalsRouteClass.verticals> | null,
) => {
    if (!withHighlightedVertical) {
        return false;
    }

    if (headerLink.isMotorsLink && Category.isOfCarsOrCarsOnInstallmentsType(category)) {
        return true;
    }

    if (headerLink.isPropertyLink && Category.isOfPropertySubtype(category)) {
        return true;
    }

    if (headerLink.name === VerticalSelection.PROPERTY) {
        return (
            vertical === VerticalsRouteClass.verticals.PROPERTIES ||
            Category.isOfPropertySubtype(category)
        );
    }

    if (headerLink.name === VerticalSelection.CARCASH) {
        return (
            vertical === VerticalsRouteClass.verticals.CARS ||
            Category.isOfCarsOrCarsOnInstallmentsType(category)
        );
    }

    return false;
};

export default shouldHighlightVertical;
