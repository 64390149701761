import * as React from 'react';
import { Trans } from '@lingui/macro';
import { DialogWithHeader } from 'strat/components';
import LoadingSpinner from 'strat/components/loadingSpinner';

import { trackNotificationClick } from 'horizontal/gtm';

import useNotifications from '../useNotifications';
import ObservedNotificationsList from '../observedNotificationsList';

import styles from './styles/notifications.cssm';
import NotificationsTrigger from './notificationsTrigger';

const Notifications = ({ hiddenTitle }: { hiddenTitle?: boolean }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const {
        notifications,
        fetchNotificationsIfViewed,
        markAsViewed,
        markAsRead,
        fetchNextPage,
        newNotifications,
        loading,
        totalNotificationsCount,
    } = useNotifications();

    const closeDialog = React.useCallback(() => {
        setDialogOpen(false);
        fetchNotificationsIfViewed();
    }, [setDialogOpen, fetchNotificationsIfViewed]);

    const openDialog = React.useCallback(() => {
        trackNotificationClick();
        setDialogOpen(true);
        markAsViewed();
    }, [setDialogOpen, markAsViewed]);

    return (
        <>
            <DialogWithHeader
                visible={dialogOpen}
                onVisibilityChanged={setDialogOpen}
                onBack={closeDialog}
                title={<Trans>Notifications</Trans>}
                // Needed so the UserCenterDialog and this one would be stacked
                dismissible
            >
                <div className={styles.container}>
                    <ObservedNotificationsList
                        notifications={notifications}
                        loading={loading}
                        totalNotificationsCount={totalNotificationsCount}
                        markAsRead={markAsRead}
                        fetchNextPage={fetchNextPage}
                    />
                    {loading && (
                        <div className={styles.loadingSpinnerWrapper}>
                            <LoadingSpinner dark />
                        </div>
                    )}
                </div>
            </DialogWithHeader>
            <NotificationsTrigger
                onClick={openDialog}
                newNotifications={newNotifications}
                hiddenTitle={hiddenTitle}
            />
        </>
    );
};

export default Notifications;
