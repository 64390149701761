import * as React from 'react';
import { useResendEditEmailCode, useEditEmail } from '@sector-labs/fe-auth-redux/thunk';
import { useKeycloakSiteConfigWithCallbacks } from 'strat/auth/keycloak';
import { Dialog } from 'strat/components';
import { OTPForm, secondsTillResend } from 'strat/auth/keycloak';

import useProfileEditErrorMessage, { ProfileField } from './useProfileEditErrorMessage';
import styles from './styles/emailConfirmationDialog.cssm';

type Props = {
    readonly showEmailConfirmation: boolean;
    readonly setShowEmailDialog: (arg1: boolean) => void;
    readonly setShowEmailConfirmation: (arg1: boolean) => void;
    readonly email: string;
    readonly setUserConfirmedEmail: (arg1: string) => void;
};

const EmailConfirmationDialog = ({
    showEmailConfirmation,
    setShowEmailDialog,
    setShowEmailConfirmation,
    email,
    setUserConfirmedEmail,
}: Props) => {
    const [otp, setOTP] = React.useState('');
    const resendEditEmailCode = useResendEditEmailCode();
    const editEmail = useEditEmail();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const [errorMessage, resetErrorMessage] = useProfileEditErrorMessage(ProfileField.EMAIL);
    const [enableResendCode, setEnableResendCode] = React.useState(true);

    React.useEffect(() => {
        if (showEmailConfirmation) {
            setEnableResendCode(false);
            setTimeout(() => {
                setEnableResendCode(true);
            }, secondsTillResend * 1000);
        }
    }, [showEmailConfirmation]);

    const showEmailVerificationForm = React.useCallback(() => {
        setShowEmailDialog(true);
        setShowEmailConfirmation(false);
        resetErrorMessage();
    }, [setShowEmailDialog, setShowEmailConfirmation, resetErrorMessage]);

    const requestNewCode = React.useCallback(() => {
        setOTP('');
        resendEditEmailCode(keycloakSiteConfig, email).then((codeData) => {
            if (!codeData) {
                setShowEmailDialog(true);
                setShowEmailConfirmation(false);
            }
            resetErrorMessage();
            setEnableResendCode(false);
            setTimeout(() => {
                setEnableResendCode(true);
            }, secondsTillResend * 1000);
        });
    }, [
        keycloakSiteConfig,
        email,
        resendEditEmailCode,
        setShowEmailDialog,
        setShowEmailConfirmation,
        resetErrorMessage,
        setEnableResendCode,
    ]);

    const submitOTP = React.useCallback(() => {
        setOTP('');
        editEmail(keycloakSiteConfig, {
            email,
            code: otp,
        }).then((sessionData) => {
            if (!sessionData) {
                return;
            }
            setShowEmailConfirmation(false);
            setUserConfirmedEmail(email);
            resetErrorMessage();
            setEnableResendCode(false);
            setTimeout(() => {
                setEnableResendCode(true);
            }, secondsTillResend * 1000);
        });
    }, [
        otp,
        setShowEmailConfirmation,
        email,
        editEmail,
        keycloakSiteConfig,
        setUserConfirmedEmail,
        resetErrorMessage,
    ]);

    return (
        <Dialog
            dismissible
            visible={showEmailConfirmation}
            onVisibilityChanged={setShowEmailConfirmation}
            withCloseButton={!!setShowEmailConfirmation}
        >
            <div className={styles.container}>
                <OTPForm
                    value={otp}
                    onChange={setOTP}
                    onSubmit={submitOTP}
                    onBack={showEmailVerificationForm}
                    onRequestNewCode={requestNewCode}
                    userDescriptor={email}
                    errorMessage={errorMessage}
                    enableResendCode={enableResendCode}
                    isPhone={false}
                    withCaptcha={false}
                />
            </div>
        </Dialog>
    );
};

export default EmailConfirmationDialog;
