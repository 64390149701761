import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Flex, LoadingAnimation, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { useChatSDK, useIsVerified, useProfileDetails } from 'horizontal/chat/hooks';
import type { ChatRoom } from 'horizontal/chat/types';
import VerifiedProfileIcon from 'horizontal/profile/verifiedProfileIcon';
import { useDefaultUserName } from 'horizontal/user';

import AgencyName from './agencyName';
import styles from './styles/username.cssm';

type Props = {
    conversation: ChatRoom;
    compact?: boolean;
    small?: boolean;
};

const Username = ({ conversation, compact = false, small = false }: Props) => {
    const sdk = useChatSDK();
    const { contactName: loadedContactName, isLoading } = sdk.useRoomContactInfo(conversation);
    const isVerified = useIsVerified(conversation);
    const { agencyName } = useProfileDetails(conversation);
    const i18n = useI18n();

    const defaultContactName = useDefaultUserName();

    if (isLoading && !loadedContactName) {
        return <LoadingAnimation className={styles.loading} />;
    }

    const contactName = loadedContactName || defaultContactName;

    const renderExtraDetails = (children: React.ReactElement) => {
        if (!compact || !agencyName) {
            return children;
        }
        return (
            <Flex alignCenter className={styles.extraDetails}>
                {children}
            </Flex>
        );
    };

    if (contactName) {
        return (
            <div
                className={classNames({
                    [styles.userInfoContainer]: !compact || !agencyName,
                    [styles.userInfoContainerCompact]: compact && agencyName,
                })}
            >
                <Text.Base
                    bold
                    nowrap
                    className={classNames(styles.text, styles.largeText, {
                        [styles.small]: small,
                    })}
                    aria-label="Seller name"
                >
                    {contactName}
                </Text.Base>
                {renderExtraDetails(
                    <>
                        <AgencyName conversation={conversation} compact={compact} small={small} />
                        {isVerified && <VerifiedProfileIcon leftMargin />}
                    </>,
                )}
            </div>
        );
    }

    return (
        <Text.Base className={classNames(styles.text, styles.regularText)}>
            {t(i18n)`Couldn't load username`}
        </Text.Base>
    );
};

export default Username;
