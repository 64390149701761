import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import styles from './styles/paymentSelectionPriceDetails.cssm';

const renderPriceDetails = (
    formattedTotalPriceWithoutDiscount: string,
    formattedTotalDiscountPrice: string,
    formattedTax: string,
    formattedFinalPrice: string,
    taxPercentage: number,
    showTaxDetails: boolean,
) => (
    <Flex column className={styles.priceDetailsContainer}>
        <Flex>
            <Text.Large bold>
                <Trans>Price details</Trans>
            </Text.Large>
        </Flex>
        <Flex column>
            <Flex justifySpaceBetween className={styles.detailRow}>
                <Flex>
                    <Text.Large>
                        <Trans>Price</Trans>
                    </Text.Large>
                </Flex>
                <Flex>
                    <Text.Large>{formattedTotalPriceWithoutDiscount}</Text.Large>
                </Flex>
            </Flex>
            <Flex justifySpaceBetween className={styles.detailRow}>
                <Flex>
                    <Text.Large>
                        <Trans>Discount</Trans>
                    </Text.Large>
                </Flex>
                <Flex>
                    <Text.Large>-{formattedTotalDiscountPrice}</Text.Large>
                </Flex>
            </Flex>
            {showTaxDetails && (
                <Flex justifySpaceBetween className={styles.detailRow}>
                    <Flex>
                        <Text.Large>
                            <Trans>Sales Tax ({Math.round((taxPercentage || 0) * 100)}%)</Trans>
                        </Text.Large>
                    </Flex>
                    <Flex>
                        <Text.Large>{formattedTax}</Text.Large>
                    </Flex>
                </Flex>
            )}
            <Flex justifySpaceBetween className={styles.total}>
                <Flex>
                    <Text.Large bold>
                        <Trans>Total</Trans>
                    </Text.Large>
                </Flex>
                <Flex>
                    <Text.Large bold>{formattedFinalPrice}</Text.Large>
                </Flex>
            </Flex>
        </Flex>
    </Flex>
);

export default renderPriceDetails;
