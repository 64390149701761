import { createSelector } from 'reselect';

import EMPTY_ARRAY from 'strat/empty/array';

import { GraphicMode } from './types';

const selectPropertyFloorPlan = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'property' implicitly has an 'any' type.
    (property) => property.floorPlan,
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => floorPlan,
);

const selectFloorPlanModels = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => (floorPlan || {}).models || EMPTY_ARRAY,
    // @ts-expect-error - TS7006 - Parameter 'models' implicitly has an 'any' type.
    (models) =>
        models
            // @ts-expect-error - TS7006 - Parameter 'modelA' implicitly has an 'any' type. | TS7006 - Parameter 'modelB' implicitly has an 'any' type.
            .sort((modelA, modelB) => modelA.orderIndex - modelB.orderIndex)
            // @ts-expect-error - TS7006 - Parameter 'model' implicitly has an 'any' type.
            .map((model) => ({
                id: model.externalID,
                title: model.label,
                thumbnailID: model.placeholderImage.id,
                graphicMode: GraphicMode.INTERACTIVE_3D,
            })),
);

const selectFloorPlanImages = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => (floorPlan || {}).images || EMPTY_ARRAY,
    // @ts-expect-error - TS7006 - Parameter 'images' implicitly has an 'any' type. | TS7006 - Parameter 'imageA' implicitly has an 'any' type. | TS7006 - Parameter 'imageB' implicitly has an 'any' type.
    (images) => images.sort((imageA, imageB) => imageA.orderIndex - imageB.orderIndex),
);

const selectFloorPlan2DImages = createSelector(selectFloorPlanImages, (images) =>
    // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
    (images || []).map((image) => ({
        ...image.image2D,
        title: image.label,
        graphicMode: GraphicMode.IMAGE_2D,
    })),
);

const selectFloorPlan3DImages = createSelector(selectFloorPlanImages, (images) =>
    (images || [])
        // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
        .filter((image) => image.image3D && image.image3D.id)
        // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
        .map((image) => ({
            ...image.image3D,
            title: image.label,
            graphicMode: GraphicMode.IMAGE_3D,
        })),
);

const selectPropertyFloorPlanModels = createSelector(
    selectPropertyFloorPlan,
    selectFloorPlanModels,
);

const selectPropertyFloorPlanImages = createSelector(
    selectPropertyFloorPlan,
    selectFloorPlanImages,
);

// @ts-expect-error - TS2769 - No overload matches this call.
const selectPropertyFloorPlan2DImages = createSelector(
    selectPropertyFloorPlan,
    selectFloorPlan2DImages,
);

// @ts-expect-error - TS2769 - No overload matches this call.
const selectPropertyFloorPlan3DImages = createSelector(
    selectPropertyFloorPlan,
    selectFloorPlan3DImages,
);

const selectPropertyFloorPlanResources = createSelector(
    // @ts-expect-error - TS2769 - No overload matches this call.
    [
        selectPropertyFloorPlanModels,
        selectPropertyFloorPlan2DImages,
        selectPropertyFloorPlan3DImages,
    ],
    // @ts-expect-error - TS7006 - Parameter 'models' implicitly has an 'any' type. | TS7006 - Parameter 'images2D' implicitly has an 'any' type. | TS7006 - Parameter 'images3D' implicitly has an 'any' type.
    (models, images2D, images3D) => [...models, ...images3D, ...images2D],
);

const selectFloorPlanResources = createSelector(
    // @ts-expect-error - TS2769 - No overload matches this call.
    [selectFloorPlanModels, selectFloorPlan2DImages, selectFloorPlan3DImages],
    // @ts-expect-error - TS7006 - Parameter 'models' implicitly has an 'any' type. | TS7006 - Parameter 'images2D' implicitly has an 'any' type. | TS7006 - Parameter 'images3D' implicitly has an 'any' type.
    (models, images2D, images3D) => [...models, ...images3D, ...images2D],
);

export {
    selectPropertyFloorPlanResources,
    selectPropertyFloorPlanModels,
    selectPropertyFloorPlan3DImages,
    selectPropertyFloorPlan2DImages,
    selectPropertyFloorPlan,
    selectPropertyFloorPlanImages,
    selectFloorPlanResources,
    selectFloorPlanImages,
    selectFloorPlanModels,
    selectFloorPlan2DImages,
    selectFloorPlan3DImages,
};
