import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { AppDispatch } from 'horizontal/state';
import type { SelectBusinessPackagesRouteParams } from 'horizontal/routes';

import { clearBusinessPackageCart } from '../state';

type UseNavigateToSelectMultiplePackages = (
    arg1: Partial<SelectBusinessPackagesRouteParams>,
) => void;

const useNavigateToSelectMultiplePackages = (): UseNavigateToSelectMultiplePackages => {
    const router = useRouter();
    const dispatch = useDispatch<AppDispatch>();

    // @ts-expect-error - TS2322 - Type '(params: SelectBusinessPackagesRouteParams) => void' is not assignable to type 'UseNavigateToSelectMultiplePackages'.
    return React.useCallback(
        (params: SelectBusinessPackagesRouteParams) => {
            dispatch(clearBusinessPackageCart());
            router.pushRoute(RouteNames.SELECT_MULTIPLE_PACKAGES, params);
        },
        [router, dispatch],
    );
};

export default useNavigateToSelectMultiplePackages;
