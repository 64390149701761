import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Pill } from 'strat/components';

import { AdVirtualState } from 'horizontal/types';
import type { ConsumedProductAd, MixedAd } from 'horizontal/types';

import { adHasAutoBoost, getPillLabels } from './utils';

type Props = {
    readonly ad: MixedAd | ConsumedProductAd;
    readonly showBoostedPill?: boolean;
};

const AdStatePill = ({ ad, showBoostedPill }: Props): React.ReactElement | null => {
    const i18n = useI18n();
    const hasAutoBoostPill = adHasAutoBoost(ad);
    const pillLabels = getPillLabels(i18n);

    if (showBoostedPill) {
        return <Pill.Boosted>{pillLabels.BUMP_UP}</Pill.Boosted>;
    }

    switch (ad.virtualState) {
        case AdVirtualState.FEATURED:
            return <Pill.Featured>{pillLabels.FEATURED}</Pill.Featured>;

        case AdVirtualState.ACTIVE:
            return hasAutoBoostPill ? (
                <Pill.Boosted>{pillLabels.BUMP_UP}</Pill.Boosted>
            ) : (
                <Pill.Accentuated>{pillLabels.ACTIVE}</Pill.Accentuated>
            );

        case AdVirtualState.ELITE:
            return <Pill.Elite>{pillLabels.ELITE}</Pill.Elite>;

        case AdVirtualState.EXPIRED:
        case AdVirtualState.OUTDATED:
            return <Pill.Regular>{pillLabels.EXPIRED}</Pill.Regular>;

        case AdVirtualState.DISABLED:
            return <Pill.Disabled>{pillLabels.DISABLED}</Pill.Disabled>;

        case AdVirtualState.MODERATION_SOFT_REJECT:
        case AdVirtualState.MODERATION_HARD_REJECT:
            return <Pill.Error>{pillLabels.REJECTED}</Pill.Error>;

        case AdVirtualState.LIMITED:
            return <Pill.Regular>{pillLabels.NOT_POSTED}</Pill.Regular>;

        case AdVirtualState.MODERATION_PENDING:
        case AdVirtualState.FEATURED_PENDING:
        case AdVirtualState.ELITE_PENDING:
            return <Pill.Regular>{pillLabels.PENDING}</Pill.Regular>;

        case AdVirtualState.SOLD:
            return <Pill.Regular>{pillLabels.SOLD}</Pill.Regular>;

        default:
            return null;
    }
};

export default AdStatePill;
