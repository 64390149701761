import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

const areaGuideLinkFactory = new FetcherFactory(
    'areaGuideLink',
    (params: any, state: any) =>
        params.location
            ? new StratAPI(state.i18n.language).areaGuideLink(params.location)
            : Promise.resolve({ data: null, status: 200 }),
    {
        successCodes: [200, 404],
        catchServerErrors: true,
    },
);

const areaGuideLinkCreator = areaGuideLinkFactory.creator();
const areaGuideLinkReducer = areaGuideLinkFactory.reducer();

const fetchAreaGuideLink = (location: string) => areaGuideLinkCreator({ location });

const selectGuideLinks = (state: any) => state.areaGuideLink.data;

export { fetchAreaGuideLink, areaGuideLinkReducer, selectGuideLinks };
