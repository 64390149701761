import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { getCubeRankingField } from 'strat/agency/search/form/fetchDataFromProBoard';

import {
    LeaderboardRankingTypes,
    MiniLeaderboardIndexRankKeyMapping,
    MiniLeaderBoardRankKey,
    MiniLeaderBoardXpEarnedKey,
} from './types';

export const getMiniLeaderboardRankingField = (
    filters: FilterCollection,
): Values<typeof MiniLeaderBoardRankKey> => {
    const cubeRankingField = getCubeRankingField(filters, []);
    const rankKeys = Object.keys(MiniLeaderboardIndexRankKeyMapping) as Array<
        Values<typeof MiniLeaderBoardRankKey>
    >;
    const leaderboardRankingField = rankKeys.find(
        (key) => MiniLeaderboardIndexRankKeyMapping[key] === cubeRankingField,
    );
    return leaderboardRankingField || MiniLeaderBoardRankKey.GLOBAL_RANKING;
};

export const getRankKeyByTab = (
    tab: Values<typeof LeaderboardRankingTypes>,
    filters: FilterCollection,
): MiniLeaderBoardRankKey => {
    const cubeRankingField = getMiniLeaderboardRankingField(filters);
    return tab === LeaderboardRankingTypes.ALL_TIME
        ? cubeRankingField
        : MiniLeaderBoardRankKey.GLOBAL_RANKING_LAST_3_MONTHS;
};

export const getXPEarnedKeyByTab = (
    tab: Values<typeof LeaderboardRankingTypes>,
    rankingKey: Values<typeof MiniLeaderBoardRankKey>,
): MiniLeaderBoardXpEarnedKey => {
    if (
        rankingKey !== MiniLeaderBoardRankKey.GLOBAL_RANKING &&
        rankingKey !== MiniLeaderBoardRankKey.GLOBAL_RANKING_LAST_3_MONTHS
    ) {
        return MiniLeaderBoardXpEarnedKey.COMBO_XP;
    }

    return tab === LeaderboardRankingTypes.ALL_TIME
        ? MiniLeaderBoardXpEarnedKey.XP_EARNED_ALL_TIME
        : MiniLeaderBoardXpEarnedKey.XP_EARNED_LAST_3_MONTHS;
};
