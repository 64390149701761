import { t } from '@lingui/macro';
import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import IconCall from 'horizontal/assets/icons/iconCall.svg';
import IconSMS from 'horizontal/assets/icons/iconEnvelope.svg';
import { FlexSizeItemWrapper } from 'horizontal/adDetails/compact';
import { PhoneNumberLinkType } from 'horizontal/contact';
import { isEligibleForDelivery } from 'horizontal/util';
import { AgencyRoles, CTA, ContactPersonRole, LiteAd } from 'horizontal/types';
import { useShowWhatsAppButton, useIsAgencyExternallyManaged } from 'horizontal/adDetails';

import PhoneCTA from './phoneCTA';
import ChatCTA from './chatCTA';
import WhatsAppCTA from './whatsAppCTA';
import BuyWithDeliveryCTA from './buyWithDeliveryCTA';
import styles from './styles/contactButtons.cssm';

const renderCallIcon = (className: string) => <IconCall className={className} />;
const renderSMSIcon = (className: string) => <IconSMS className={className} />;

type Props = {
    ad: LiteAd;
    viewSection: Values<typeof ViewSections>;
};

const getAvailableCTAs = (ad: LiteAd, isAgencyExternallyManaged: boolean | undefined) => {
    const roles: Array<Values<typeof ContactPersonRole> | Values<typeof AgencyRoles>> = [
        ...(ad.contactInfo?.roles || []),
        ...(ad.agency?.roles || []),
    ];
    const isPhoneAvailable = roles.includes(ContactPersonRole.SHOW_PHONE_NUMBER);
    const isWhatsAppAvailable = roles.includes(ContactPersonRole.SHOW_WHATSAPP_PHONE_BUTTON);
    const isChatEnabled = roles.includes(ContactPersonRole.ALLOW_CHAT_COMMUNICATION);
    const isChatAvailable = isAgencyExternallyManaged
        ? isChatEnabled && !isWhatsAppAvailable
        : isChatEnabled;
    const isBuyWithDeliveryAvailable =
        isEligibleForDelivery(ad) &&
        roles.includes(ContactPersonRole.SHOW_BUY_WITH_DELIVERY_ON_SEARCH_PAGE);

    // We need to hide SMS if ad is posted by an agency that has whatsapp enabled
    const isSMSAvailable =
        isPhoneAvailable &&
        !roles.includes(ContactPersonRole.SHOW_SHORT_NUMBER) &&
        !(
            ad.agency?.roles?.includes(AgencyRoles.ALLOW_AGENTS_WHATSAPP_NUMBER) &&
            isWhatsAppAvailable
        );

    return {
        isPhoneAvailable,
        isSMSAvailable,
        isWhatsAppAvailable,
        isChatAvailable,
        isBuyWithDeliveryAvailable,
    };
};

/**
 * A hook that returns the CTAs to be displayed in the updated ad card design
 * The CTAs are showed based on some conditions, mainly from the ad roles
 * We can only show up to 3 buttons for each card
 *
 * @param ad Ad details
 * @param viewSection
 * @returns CTA buttons to be displayed
 */
const useAdAvailableCTAButtons = ({ ad, viewSection }: Props) => {
    const i18n = useI18n();
    const isAgencyExternallyManaged = useIsAgencyExternallyManaged(ad.agency);
    const {
        isPhoneAvailable,
        isSMSAvailable,
        isWhatsAppAvailable,
        isChatAvailable,
        isBuyWithDeliveryAvailable,
    } = getAvailableCTAs(ad, isAgencyExternallyManaged);

    const showWhatsappButton = useShowWhatsAppButton({
        isWhatsAppActive: isWhatsAppAvailable,
        isShowPhoneNumberActive: isPhoneAvailable,
        agency: ad?.agency,
    });

    const buttons = [
        {
            key: CTA.WHATSAPP,
            shouldBeDisplayed: showWhatsappButton,
            component: (
                <FlexSizeItemWrapper key={CTA.WHATSAPP}>
                    <WhatsAppCTA ad={ad} viewSection={viewSection} showLabel={false} fullWidth />
                </FlexSizeItemWrapper>
            ),
        },
        {
            key: CTA.CALL,
            shouldBeDisplayed: isPhoneAvailable,
            component: (
                <FlexSizeItemWrapper key={CTA.CALL}>
                    <PhoneCTA
                        ad={ad}
                        viewSection={viewSection}
                        phoneNumberLinkType={PhoneNumberLinkType.CALL}
                        text={t(i18n)`Call`}
                        renderIcon={renderCallIcon}
                        fullWidth
                        secondary={settings.lpvCTAConfigs[CTA.CALL].canBeSecondary}
                        className={
                            !settings.lpvCTAConfigs[CTA.CALL].canBeSecondary
                                ? styles.contactButton
                                : undefined
                        }
                    />
                </FlexSizeItemWrapper>
            ),
        },
        {
            key: CTA.CHAT,
            shouldBeDisplayed: isChatAvailable,
            component: (
                <FlexSizeItemWrapper key={CTA.CHAT}>
                    <ChatCTA
                        ad={ad}
                        adExternalID={ad.externalID}
                        userExternalID={ad.userExternalID}
                        viewSection={viewSection}
                        className={
                            !settings.lpvCTAConfigs[CTA.CHAT].canBeSecondary
                                ? styles.contactButton
                                : undefined
                        }
                        secondary={settings.lpvCTAConfigs[CTA.CHAT].canBeSecondary}
                    />
                </FlexSizeItemWrapper>
            ),
        },
        {
            key: CTA.SMS,
            shouldBeDisplayed: isSMSAvailable,
            component: (
                <FlexSizeItemWrapper key={CTA.SMS}>
                    <PhoneCTA
                        ad={ad}
                        viewSection={viewSection}
                        phoneNumberLinkType={PhoneNumberLinkType.SMS}
                        text={t(i18n)`SMS`}
                        renderIcon={renderSMSIcon}
                        fullWidth
                        secondary={settings.lpvCTAConfigs[CTA.SMS].canBeSecondary}
                        className={
                            !settings.lpvCTAConfigs[CTA.SMS].canBeSecondary
                                ? styles.contactButton
                                : undefined
                        }
                    />
                </FlexSizeItemWrapper>
            ),
        },
        {
            key: CTA.BUY,
            shouldBeDisplayed: isBuyWithDeliveryAvailable,
            component: (
                <FlexSizeItemWrapper key={CTA.SMS}>
                    <BuyWithDeliveryCTA ad={ad} fullWidth />
                </FlexSizeItemWrapper>
            ),
        },
    ];

    return buttons
        .filter((e) => e.shouldBeDisplayed)
        .sort((a, b) => settings.lpvCTAConfigs[a.key].order - settings.lpvCTAConfigs[b.key].order)
        .slice(0, 3)
        .map((e) => e.component);
};

export default useAdAvailableCTAButtons;
