const Page = Object.freeze({
    HOME: 'home',
    SEARCH: 'search',
    AD_DETAILS: 'adDetails',
    AD_MANAGEMENT: 'adManagement',
    APPLY_AD_LIMIT_PACKAGE: 'applyAdLimitPackage',
    SELECT_SINGLE_LIMIT_PACKAGE: 'selectSingleLimitPackage',
    FAVORITE_ADS: 'adFavorites',
    CREATE_AD: 'createAd',
    POST_AD_SUCCESS: 'postAdSuccess',
    AD_UNDERTAKING_TERMS: 'adUndertakingTerms',
    UPDATE_AD: 'updateAd',
    INTERNAL_ERROR: 'internalError',
    NOT_FOUND: 'notFound',
    CHAT: 'chat',
    CHAT_WIDGET: 'chatWidget',
    SELLER_PROFILE: 'sellerProfile',
    AGENCY_PROFILE: 'companies',
    SITEMAP: 'sitemap',
    PAYMENT_SELECTION: 'paymentSelection',
    PAYMENT_SELECTION_FREE_AD_UNDERTAKING: 'paymentSelectionFreeAdUndertaking',
    PAYMENT_SUCCESS: 'paymentSuccess',
    PAYMENT_PROCESSING: 'paymentProcessing',
    PAYMENT_AREEBA: 'paymentAreeba',
    PAYMENT_JAZZ_CASH_MWALLET: 'paymentJazzCashMWallet',
    PAYMENT_FAWRY: 'paymentFawry',
    PAYMENT_BANK_TRANSFER: 'paymentBankTransfer',
    PROVIDER_CASH_PAYMENT: 'providerCashPayment',
    PAYMENT_PAYMOB_MWALLET: 'paymentPaymobMWallet',
    LOGIN: 'login',
    LOGIN_CALLBACK: 'loginCallback',
    USER_SETTINGS: 'userSettings',
    EDIT_PROFILE: 'editProfile',
    SELECT_SINGLE_PACKAGE: 'selectSinglePackage',
    APPLY_AD_SINGLE_PACKAGE_ERROR: 'applyAdSinglePackageError',
    MISSING_INFO_ERROR: 'missingInfoError',
    SELECT_PACKAGE: 'selectPackage',
    SELECT_PACKAGE_SUCCESS: 'selectPackageSuccess',
    SAVE_CARD: 'saveCard',
    BUSINESS_PACKAGE: 'businessPackages',
    SELECT_MULTIPLE_PACKAGES: 'selectMultiplePackages',
    VIEW_BUSINESS_PACKAGE_CART: 'viewBusinessPackageCart',
    PACKAGES_NOT_FOUND: 'packagesNotFound',
    USER_ORDERS: 'userOrders',
    PAYMENT_TPAY: 'paymentTPay',
    PAYMENT_TPAY_OTP: 'paymentTPayOTP',
    LINK_IDENTITY_PROVIDER: 'linkIdentityProvider',
    CAPTCHA_CHALLENGE: 'captchaChallenge',
    REQUEST_DELIVERY: 'requestDelivery',
    VERTICAL_PROPERTIES: 'verticalProperties',
    VERTICAL_CARS: 'verticalCars',
    CASH_MY_CAR_SUCCESS: 'CashMyCarSuccess',
    SAVED_SEARCHES: 'savedSearches',
    TELE_SALES: 'teleSales',
    PACKAGE_PURCHASE_ERROR: 'packagePurchaseError',
    WALLET: 'Wallet',
    TRANSITION: 'transition',
    // Only for development
    COMPONENTS: 'components',
});

export default Page;
