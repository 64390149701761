import React, { Component } from 'react';
// @ts-expect-error - TS2724 - '"react"' has no exported member named 'Element'. Did you mean 'CElement'?
import type { Element } from 'react';
import ReactDOM from 'react-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames';

/**
 * Possible phases the transition can be in.
 */
const TransitionPhase = Object.freeze({
    UNMOUNTED: 'UNMOUNTED',
    MOUNTED: 'MOUNTED',
    UNMOUNTING: 'UNMOUNTING',
});

/**
 * Properties for {@see Transition}.
 */
type Props = {
    /**
     * Indicates whether the component should
     * be mounted or unmounted.
     */
    readonly visible: boolean;
    /**
     * Class name to apply when mounting.
     */
    readonly mountClassName: string;
    /**
     * Class name to apply when unmounting.
     */
    readonly unmountClassName: string;
    /**
     * Class name to apply when hidden.
     */
    readonly hiddenClassName?: string | null | undefined;
    /**
     * Element that that class names are
     * applied to.
     */
    readonly children: Element<any>;
};

/**
 * State for {@see Transition}.
 */
type State = {
    phase: keyof typeof TransitionPhase;
};

/**
 * A simpler clone of {@see ReactCSSTransitionGroup}.
 *
 * This assists in creating transitions for mounting
 * and unmounting of a component. Async unmounting
 * is impossible in React, making it very hard to
 * to do animations when a component is unmounting.
 *
 * Transition helps because it creates a wrapper
 * that never unmounts, but instead unmounts it
 * child. This means that it can keep the child
 * around long enough to do transitions.
 *
 * The major differences between {@see Transition}
 * and @{see ReactTransitionGroup}:
 *
 * - Automatically detects end of the transition.
 * - Does not require an intermediate DOM node.
 *
 * There is also the option to not unmount the component
 * and set its display property to none. This is useful
 * when we don't want to rerender a costly component like
 * the Map. This can be done by specifying a hiddenClassName.
 *
 */
class Transition extends Component<Props, State> {
    /**
     * Reference to the child element.
     */
    childRef: null | Element<any>;

    /**
     * Initializes a new instance of {@see Transition}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.childRef = null;

        // component is unmounted to start with
        this.state = {
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"UNMOUNTED" | "MOUNTED" | "UNMOUNTING"'.
            phase: TransitionPhase.UNMOUNTED,
        };
    }

    /**
     * Finds the DOM node for the child reference.
     */
    getDOMNode(): null | HTMLElement {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this.childRef as any);
        if (!node) {
            return node;
        }

        return node as any;
    }

    /**
     * Component mounts, depending on whether it is
     * visible the phase will be set.
     */
    componentDidMount(): void {
        if (this.props.visible) {
            // eslint-disable-next-line react/no-did-mount-set-state
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"UNMOUNTED" | "MOUNTED" | "UNMOUNTING"'.
            this.setState({ phase: TransitionPhase.MOUNTED });
        }
    }

    /**
     * Component is going to update, if the visibility
     * changes, then update the phase.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.visible === this.props.visible) {
            return;
        }

        this.setState({
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"UNMOUNTED" | "MOUNTED" | "UNMOUNTING"'.
            phase: this.props.visible ? TransitionPhase.UNMOUNTING : TransitionPhase.MOUNTED,
        });
    }

    /**
     * Component updated, if the phase is unmounting, then
     * we're going to wait for any animations to end before
     * actually unmounting the component.
     */
    componentDidUpdate(): void {
        if (this.state.phase !== TransitionPhase.UNMOUNTING) {
            return;
        }

        const node = this.getDOMNode();
        if (node) {
            node.addEventListener('animationend', this.componentAnimationEnd);
            node.addEventListener('transitionend', this.componentAnimationEnd);
        }
    }

    /**
     * Animations ended, unmount the component.
     */
    componentAnimationEnd(): void {
        const node = this.getDOMNode();
        if (node) {
            node.removeEventListener('animationend', this.componentAnimationEnd);
            node.removeEventListener('transitionend', this.componentAnimationEnd);
        }

        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"UNMOUNTED" | "MOUNTED" | "UNMOUNTING"'.
        this.setState({ phase: TransitionPhase.UNMOUNTED });
    }

    /**
     * Renders the child component (or not), the child component
     * is rendered according to the current phase:
     *
     * - UNMOUNTED: render nothing
     * - MOUNTED: render with `mountClassName`
     * - UNMOUNTING: render with `unmountClassName`
     */
    render() {
        const { phase } = this.state;
        const { mountClassName, unmountClassName } = this.props;

        const hiddenClassName = this.props.hiddenClassName ?? '';
        const persistent = !!this.props.hiddenClassName;

        if (phase === TransitionPhase.UNMOUNTED && !persistent) {
            return null;
        }

        const transitionClassName = classNames({
            [mountClassName]: phase === TransitionPhase.MOUNTED,
            [unmountClassName]: phase === TransitionPhase.UNMOUNTING,
            [hiddenClassName]: phase === TransitionPhase.UNMOUNTED && persistent,
        });

        const child = React.Children.only(this.props.children);

        return React.cloneElement(child, {
            ...child.props,
            // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
            ref: (element) => {
                this.childRef = element;
            },
            className: child.props.className
                ? `${child.props.className} ${transitionClassName}`
                : child.props.className,
        });
    }
}

export default Transition;
