import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { useFormattedPrice, formatDate } from 'horizontal/util';
import { PaymentOrder, PaymentSource } from 'horizontal/payment/types';

import PaymentOrdersEntryBreakdown from './paymentOrdersEntryBreakdown';
import styles from './styles/paymentOrderEntry.cssm';

type Props = {
    readonly paymentOrder: PaymentOrder;
};
const PaymentOrderEntry = ({ paymentOrder }: Props) => {
    const i18n = useI18n();

    const details = [
        { key: t(i18n)`Date`, value: formatDate(i18n, paymentOrder.createdAt * 1000) },
        { key: t(i18n)`Order ID`, value: paymentOrder.externalID },
        {
            key: t(i18n)`Payment method`,
            value:
                paymentOrder?.payments
                    ?.map((payment) => PaymentSource.text(i18n, payment.source))
                    ?.join(' + ') || '-',
        },
    ];
    return (
        <Flex column className={styles.wrapper}>
            <Flex column className={styles.header}>
                <Flex justifySpaceBetween>
                    <Text.Regular gray>{t(i18n)`Amount`}</Text.Regular>
                    <Text.Large bold>{useFormattedPrice(paymentOrder.total)}</Text.Large>
                </Flex>
                <PaymentOrdersEntryBreakdown productPackageOffers={paymentOrder.offers} />
            </Flex>
            {details.map(({ key, value }, index) => (
                <Flex key={index} justifySpaceBetween className={styles.detailLine}>
                    <Text.Small gray>{key}</Text.Small>
                    <Text.Small>{value || '-'}</Text.Small>
                </Flex>
            ))}
        </Flex>
    );
};

export default PaymentOrderEntry;
