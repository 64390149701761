import { t } from '@lingui/macro';
import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { ShareLink } from 'strat/social/links';
import useI18n from 'strat/i18n/language/useI18n';
import ShareWhatsAppIcon from '@app/assets/icons/iconShareWhatsApp.svg';
import { ShareMediums, PageTypes } from 'strat/gtm';
import { Text } from 'strat/components';

import styles from './styles/whatsappShareButton.cssm';

type Props = {
    readonly url: string;
    readonly compact?: boolean;
};

const WhatsappShareButton = ({ url, compact }: Props) => {
    const i18n = useI18n();
    const link = `https://wa.me/${url}`;
    return (
        <div className={classNames(styles.container, { [styles.compact]: compact })}>
            <ShareLink
                url={link}
                title={t(i18n)`Share on WhatsApp`}
                shareType={ShareMediums.WHATSAPP}
                target="_blank"
                pageType={PageTypes.DETAIL}
            >
                <div className={classNames(styles.wrapper, { [styles.compactOrder]: compact })}>
                    <ShareWhatsAppIcon className={styles.icon} />
                    <Text.Large className={styles.shareText} bold>
                        <Trans>Share your screenshot</Trans>
                    </Text.Large>
                </div>
            </ShareLink>
        </div>
    );
};

export default WhatsappShareButton;
