import React from 'react';
import type { FormikValues } from 'formik';
import invariant from 'invariant';
import type { UpsertAdPayload } from 'strat/api/types';
import { AdType } from 'strat/api/types';
import InternalAPI from 'strat/api/internal';
import Purpose from 'strat/purpose';
import { useActiveUser } from 'strat/user/session';

import { setAdImageDataToLocalStorage } from 'horizontal/app';
import CommonPostingFields, {
    ContactInfoFields,
    AdDeliveryFields,
    ShortNumberField,
    DownPaymentField,
} from 'horizontal/fields/commonPostingFields';
import { AdExtraFieldsKeys } from 'horizontal/ad/types';

import type { AdPhotoDTO, FlatCategoryField } from '../types';
import { AdState, CategoryFieldValueType, ContactPersonRole } from '../types';
import { FieldType, transformAdPhotosToAdPhotoDTOs } from '../fields';

export const prepareContactInfoPayload = (values: FormikValues) => {
    let roles: Array<ContactPersonRole> = values[ContactInfoFields.roles.attribute];
    const shortNumber: string = values[ShortNumberField.attribute];
    const mobile: string = values[ContactInfoFields.phone_number.attribute];

    const shouldContactByShortNumber = roles.includes(ContactPersonRole.SHOW_SHORT_NUMBER);

    if (shouldContactByShortNumber && !shortNumber) {
        roles = roles.filter((role) => role !== ContactPersonRole.SHOW_SHORT_NUMBER);
    } else if (!shouldContactByShortNumber && shortNumber) {
        roles.push(ContactPersonRole.SHOW_SHORT_NUMBER);
    }

    return {
        name: values[ContactInfoFields.name.attribute] as string,
        mobile: shortNumber ? '' : mobile,
        roles,
    };
};

export const preparePayload = (
    categoryFields: Array<FlatCategoryField>,
    values: FormikValues,
    userExternalID: string,
    adExternalID?: string | null,
): UpsertAdPayload => {
    invariant(
        CONFIG.build.STRAT_INTERNAL_AD_CREATION_SOURCE,
        'STRAT_INTERNAL_AD_CREATION_SOURCE should be set',
    );

    const deliveryInformation = values[AdDeliveryFields.is_deliverable.attribute] && {
        delivery_information: {},
    };

    const payload = {
        extra_fields: {},
        external_id: adExternalID,
        area: 0,
        type: AdType.GENERAL,
        user_external_id: userExternalID,
        purpose: Purpose.FOR_SALE,
        state: AdState.PENDING,
        source: CONFIG.build.STRAT_INTERNAL_AD_CREATION_SOURCE,
        contact_info: prepareContactInfoPayload(values),
        ...deliveryInformation,
    } as const;

    Object.entries(values).forEach(([key, value]: [string, any]) => {
        // if down payment exists with an empty value, send it with value 0 instead
        // for all categories except for_rent categories
        if (
            !value &&
            value !== 0 &&
            (key !== DownPaymentField.attribute ||
                (key === DownPaymentField.attribute && AdExtraFieldsKeys.RENTAL_PERIOD in values))
        ) {
            return;
        }
        if (key === CommonPostingFields.photos.attribute) {
            // value is considered to be mixed type which is not true in this case
            payload[key] = transformAdPhotosToAdPhotoDTOs(value);
            return;
        }

        let field: FieldType | FlatCategoryField | undefined = CommonPostingFields[key];
        if (field) {
            payload[field.attribute] = value;
            return;
        }

        field = AdDeliveryFields[key];

        if (field) {
            const deliveryAttributesList = [
                AdDeliveryFields.pickup_address_id.attribute,
                AdDeliveryFields.weight.attribute,
                AdDeliveryFields.length.attribute,
                AdDeliveryFields.height.attribute,
                AdDeliveryFields.width.attribute,
                AdDeliveryFields.delivery_type.attribute,
            ];
            if (
                deliveryAttributesList.includes(key) &&
                values[AdDeliveryFields.is_deliverable.attribute]
            ) {
                if (key === AdDeliveryFields.delivery_type.attribute) {
                    payload.delivery_information[key] = value;
                } else {
                    payload.delivery_information[key] = parseFloat(value);
                }
                return;
            }
            return;
        }

        field = categoryFields.find((potentialMatch) => potentialMatch.attribute === key);
        if (!field) {
            return;
        }

        if (
            [CategoryFieldValueType.FLOAT, CategoryFieldValueType.INTEGER].includes(
                (field as FlatCategoryField).valueType,
            )
        ) {
            payload.extra_fields[key] = Number(value);
            return;
        }

        payload.extra_fields[key] = value;
    });

    return payload;
};

const submitAd = (
    categoryFields: Array<FlatCategoryField>,
    values: FormikValues,
    userExternalID?: string | null,
    adExternalID?: string | null,
): Promise<any> => {
    if (!userExternalID) {
        return Promise.reject();
    }

    const payload = preparePayload(categoryFields, values, userExternalID, adExternalID);

    return new InternalAPI().upsertAd(payload).then((result) => {
        const photosAttribute = CommonPostingFields.photos.attribute as keyof UpsertAdPayload;

        setAdImageDataToLocalStorage(
            payload[photosAttribute] as AdPhotoDTO[],
            result.data.external_id,
        );

        return result;
    });
};

export const useSubmitAd = (adExternalID?: string) => {
    const user = useActiveUser();
    return React.useCallback(
        (categoryFields: Array<FlatCategoryField>, values: FormikValues) =>
            submitAd(categoryFields, values, user?.externalID, adExternalID),
        [user, adExternalID],
    );
};
