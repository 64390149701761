/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { getDuration } from 'strat/util';

import PlayPauseButton from './playPauseButton';
import styles from './styles/audioMessage.cssm';

type Props = {
    readonly audioUrl: string;
    readonly className: string;
    readonly renderFooter: () => React.ReactElement;
};

const AudioMessage = ({ audioUrl, className, renderFooter }: Props) => {
    const [paused, setPaused] = React.useState(true);
    const audioRef = React.useRef<HTMLAudioElement | null | undefined>(null);
    const [duration, setDuration] = React.useState(0);
    const [currentPosition, setCurrentPosition] = React.useState(0);

    const onPlayPauseClick = React.useCallback(() => {
        if (!audioRef.current) {
            return;
        }
        if (paused) {
            audioRef.current.play();
            setPaused(false);
        } else {
            audioRef.current.pause();
            setPaused(true);
        }
    }, [paused]);

    const onSliderChange = React.useCallback((e: React.SyntheticEvent<any>) => {
        if (!audioRef.current) {
            return;
        }
        const newValue = e.currentTarget.value;
        audioRef.current.currentTime = newValue;
        setCurrentPosition(newValue);
    }, []);

    const onLoadedMetadata = React.useCallback(() => {
        if (!audioRef.current) {
            return;
        }
        setDuration(Math.floor(audioRef.current.duration));
    }, []);

    const onTimeUpdate = React.useCallback(() => {
        if (!audioRef.current) {
            return;
        }
        setCurrentPosition(Math.floor(audioRef.current.currentTime));
    }, []);

    const onAudioEnded = React.useCallback(() => {
        setPaused(true);
    }, []);

    return (
        <div className={classNames(styles.container, className)} aria-label="Audio message">
            <Flex>
                <PlayPauseButton
                    paused={paused}
                    className={styles.playPauseButton}
                    onClick={onPlayPauseClick}
                />
                <Flex alignCenter>{getDuration(paused ? duration : currentPosition)}</Flex>
                <Flex alignCenter fillContainer className={styles.progressBarContainer}>
                    <span
                        className={styles.progressBar}
                        style={{ width: `${(100 * currentPosition) / duration}%` }}
                    />
                    <input
                        type="range"
                        max={duration}
                        min={0}
                        value={currentPosition}
                        onChange={onSliderChange}
                        className={styles.slider}
                    />
                </Flex>
            </Flex>
            <audio
                // @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLAudioElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLAudioElement> | undefined'.
                ref={audioRef}
                src={audioUrl}
                preload="metadata"
                onLoadedMetadata={onLoadedMetadata}
                onTimeUpdate={onTimeUpdate}
                onEnded={onAudioEnded}
            />
            <div className={styles.footer}>{renderFooter()}</div>
        </div>
    );
};

export default React.memo<Props>(AudioMessage);
