import * as React from 'react';
import { Dialog } from 'strat/components';
import { DialogComponentProps } from 'strat/dialogs/types';
import IconClose from '@app/assets/icons/iconClose.svg';
import { useSelector } from 'react-redux';

import type { Category } from 'horizontal/types';
import { selectAgencyCategoryFilters } from 'horizontal/agencyPortal/agency/state';
import useIsInAgencyPortal from 'horizontal/user/useIsInAgencyPortal';

import Context from '../context';

import CategoryPicker from './categoryPicker';
import styles from './styles/categoryPickerDialog.cssm';

interface Props {
    activeCategoryContext: [
        Category | null | undefined,
        React.Dispatch<Category | null | undefined>,
    ];
    onLeafCategorySelected: () => void;
}

const CategoryPickerDialog = ({
    visible,
    onVisibilityChanged,
    onLeafCategorySelected,
    activeCategoryContext,
}: DialogComponentProps<Props>) => {
    const isInAgencyPortal = useIsInAgencyPortal();
    const agencyCategoryFilters = useSelector(selectAgencyCategoryFilters);
    return (
        <Dialog
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            dismissible
            className={styles.dialog}
        >
            <Context.Provider value={activeCategoryContext ?? []}>
                <IconClose
                    onClick={() => onVisibilityChanged(false)}
                    className={styles.iconClose}
                />
                <div className={styles.container}>
                    <CategoryPicker
                        displayCategories={isInAgencyPortal ? agencyCategoryFilters : null}
                        onLeafCategorySelected={onLeafCategorySelected}
                    />
                </div>
            </Context.Provider>
        </Dialog>
    );
};

export default CategoryPickerDialog;
