import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { StylelessButton } from 'strat/generic';
import { Text } from 'strat/components';

import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import styles from './styles/sortOptionsDropdownTrigger.cssm';

type Props = {
    readonly label: string;
    readonly isOpen: boolean;
    readonly onClick: () => void;
};

const SortOptionsDropdownTrigger = ({ label, isOpen, onClick }: Props) => {
    const i18n = useI18n();

    return (
        <StylelessButton className={styles.button} onClick={onClick}>
            <Text.Regular uppercase bold>
                {t(i18n)`Sort by`}
                {': '}
            </Text.Regular>
            <Text.Regular>{label}</Text.Regular>
            <img
                src={iconArrowDown}
                alt={t(i18n)`Sort options dropdown`}
                className={classNames(styles.icon, { [styles.isOpen]: isOpen })}
            />
        </StylelessButton>
    );
};

export default SortOptionsDropdownTrigger;
