import type { Store } from 'redux';
import type { URLProcessor } from 'react-true-router/urlProcessor';
import { composeUrlProcessor } from 'react-true-router';
import { parseLocation, serializeLocation } from 'react-true-router/location';
import createLanguageUrlProcessor from 'strat/i18n/language/urlProcessor';

/**
 * The serializeLocation/parseLocation is needed to make sure that eventually all
 * the final urls are strings, because sometimes they are of EnhancedLocation type.
 */
const statelessUrlProcessor = {
    inbound: (url: string) => serializeLocation(parseLocation(url)),
    outbound: (url: string) => serializeLocation(parseLocation(url)),
} as const;

// @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
export default (store: Store): URLProcessor =>
    composeUrlProcessor([createLanguageUrlProcessor(store), statelessUrlProcessor]);

export { statelessUrlProcessor };
