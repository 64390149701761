import * as React from 'react';
import type { FormikTouched, FormikErrors, FormikValues } from 'formik';
import { useShowDismissibleOnTargetFocus } from 'strat/modal';
import { selectUserRoles } from 'strat/user/session';
import { useSelector } from 'react-redux';
import { isAgent } from 'strat/user/roles';

import { WalletTopUpSuccessToast } from 'horizontal/marketingCampaigns';

import { ContactInfoFields } from '../commonPostingFields';
import PhoneNumberField from '../phoneNumberField';

import PhoneNumberDialog from './phoneNumberDialog';

type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly disabled?: boolean | null | undefined;
    readonly enablePhoneNumberChangeWarningBanner?: boolean;
    readonly onDone?: (newPhoneNumber?: string | null, oldPhoneNumber?: string | null) => void;
    readonly placeholder?: string;
    readonly leftSideLabel?: boolean;
    readonly boldLabel?: boolean;
    readonly isMandatory?: boolean;
};

const UserPhoneNumberField = ({
    enablePhoneNumberChangeWarningBanner,
    boldLabel,
    ...props
}: Props) => {
    const { setFieldValue, values, onDone } = props;

    const [isDialogVisible, setIsDialogVisible, onMouseDown, onFocus] =
        useShowDismissibleOnTargetFocus();
    const userRoles = useSelector(selectUserRoles);

    const onChangePhoneNumber = React.useCallback(
        (newPhoneNumber?: string | null) => {
            const oldPhoneNumber = values[ContactInfoFields.phone_number.attribute];
            setFieldValue(ContactInfoFields.phone_number.attribute, newPhoneNumber);
            setIsDialogVisible(false);
            if (
                newPhoneNumber &&
                !oldPhoneNumber &&
                CONFIG.runtime.STRAT_ENABLE_WALLET_TOP_UP_CAMPAIGN &&
                !isAgent(userRoles)
            ) {
                // Only display the toast if the campaign is active and the user is adding a new
                // phone number, NOT changing an existing phone number regardless the user is using th campaign URL or not
                setToastVisible(true);
            }
            if (onDone) {
                onDone(newPhoneNumber, oldPhoneNumber);
            }
        },
        [setFieldValue, setIsDialogVisible, onDone, values, userRoles],
    );
    const [toastVisible, setToastVisible] = React.useState<boolean>(false);

    return (
        <>
            {toastVisible && <WalletTopUpSuccessToast setToastVisible={setToastVisible} />}
            <PhoneNumberField
                {...props}
                onMouseDown={onMouseDown}
                onFocus={onFocus}
                readOnly={true}
                boldLabel={boldLabel}
                lightPlaceholder
            />
            <PhoneNumberDialog
                phoneNumber={values[ContactInfoFields.phone_number.attribute]}
                visible={isDialogVisible}
                onVisibilityChanged={setIsDialogVisible}
                onDone={onChangePhoneNumber}
                enablePhoneNumberChangeWarningBanner={enablePhoneNumberChangeWarningBanner}
            />
        </>
    );
};

export default UserPhoneNumberField;
