import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { selectSavedSearches } from 'strat/search/savedSearches/selectors';
import { filterSavedSearchParams } from 'strat/search/savedSearches';

import { selectCurrentSearchRouteParams } from 'horizontal/search/routing/useCurrentSearchRouteParams';

import type { SavedSearchParams } from './types';

const SavedSearchParameters = Object.freeze([
    'category',
    'location',
    'extraFields',
    'freeTextQuery',
    'locationRadius',
]);

const compareSavedSearchParams = (
    savedSearchParams: SavedSearchParams,
    currentRouteParams: SavedSearchParams,
): boolean => {
    const filteredSavedParams = filterSavedSearchParams(savedSearchParams, SavedSearchParameters);
    const filteredRouteParams = filterSavedSearchParams(currentRouteParams, SavedSearchParameters);

    if (filteredSavedParams?.category?.id !== filteredRouteParams?.category?.id) {
        return false;
    }

    if (filteredSavedParams?.location?.id !== filteredRouteParams?.location?.id) {
        return false;
    }

    if (filteredSavedParams?.freeTextQuery !== filteredRouteParams?.freeTextQuery) {
        return false;
    }

    if (filteredSavedParams?.locationRadius !== filteredRouteParams?.locationRadius) {
        return false;
    }

    if (!isEqual(filteredSavedParams?.extraFields, filteredRouteParams?.extraFields)) {
        return false;
    }

    return true;
};

/**
 * Selects the saved search that matches the currently active search parameters (criteria).
 */
const selectCurrentSearchIfSaved = createSelector(
    selectSavedSearches,
    selectCurrentSearchRouteParams,
    (savedSearches, routeSearchParams) => {
        // @ts-expect-error - TS7006 - Parameter 'search' implicitly has an 'any' type.
        const savedSearch = savedSearches.find((search) =>
            // @ts-expect-error - TS2559 - Type 'FilterCollection' has no properties in common with type 'Partial<Partial<Partial<{ purpose: string; locations: SearchLocationNode[]; locationsByLanguage: { [key: string]: SearchLocationNode[]; }; locationPrefix: string | null | undefined; ... 56 more ...; showListingScore: boolean | ... 1 more ... | undefined; }> & { ...; }> & { ...; }>'.
            compareSavedSearchParams(search.params, routeSearchParams),
        );
        return savedSearch || null;
    },
);

export { selectCurrentSearchIfSaved };
