import { useSelector } from 'react-redux';
import Layout from 'strat/layout';
import { selectLayout } from 'strat/layout/selectors';

import type { LiteCategory } from 'horizontal/types';

import { PageType } from './types';

const useAdUnitName = (pageType: string, categoryHierarchy: Array<LiteCategory> = []) => {
    const layout = useSelector(selectLayout);
    const baseAdUnit =
        layout === Layout.DESKTOP
            ? CONFIG.build.DFP_BASE_AD_UNIT_DESKTOP
            : CONFIG.build.DFP_BASE_AD_UNIT_MOBILE;

    const adUnitLevels = [baseAdUnit];

    if (pageType === PageType.HOME) {
        adUnitLevels.push(PageType.HOME);
    }

    if (categoryHierarchy[0]) {
        adUnitLevels.push(`${categoryHierarchy[0].slug}_${pageType}`);
        if (categoryHierarchy[1]) {
            adUnitLevels.push(categoryHierarchy[1]?.slug);
        }
    }

    return adUnitLevels.join('/');
};

export default useAdUnitName;
