import * as React from 'react';
import { stopSyntheticEventPropagation } from 'strat/util';
import { Button } from 'strat/components';

import { CTAInfo } from './useCTA';

type Props = {
    cta: CTAInfo | undefined;
    stretch?: boolean;
    secondary?: boolean;
};

const AdCTA = ({ cta, stretch, secondary }: Props) => {
    const onClick = React.useCallback(
        (event: React.SyntheticEvent<any>) => {
            cta?.onClick();
            stopSyntheticEventPropagation(event);
        },
        [cta],
    );

    if (!cta) {
        return null;
    }

    return (
        <Button stretch={stretch} small onClick={onClick} secondary={secondary}>
            {cta.title}
        </Button>
    );
};

export default AdCTA;
