import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';

import withGTM from './withGTM';
import type { GTMProps } from './withGTM';
import Triggers from './triggers';

export type GTMPropertyTrendsTrackingProps = GTMProps & {
    trackPropertyPriceTrendsChartInteraction: () => void;
    trackRentalYieldInteraction: () => void;
};

const withGTMPropertyTrendsTracking = <T extends any>(
    component: Flow.AbstractComponent<any>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'GTMPropertyTrendsTrackingProps'.
): Flow.AbstractComponent<Flow.Diff<T, GTMPropertyTrendsTrackingProps>> => {
    // @ts-expect-error - TS2339 - Property 'trigger' does not exist on type 'unknown'. | TS2700 - Rest types may only be created from object types.
    const GTMPropertyTrendsTracking = ({ trigger, ...props }: T) =>
        React.createElement(component, {
            ...props,
            trackPropertyPriceTrendsChartInteraction: () =>
                trigger(Triggers.PRICE_HISTORY_INTERACTION, {}),
            trackRentalYieldInteraction: () => trigger(Triggers.RENTAL_YIELD_INTERACTION, {}),
        });

    // @ts-expect-error - TS2322 - Type 'AbstractComponent<Diff<T, GTMProps>, any>' is not assignable to type 'AbstractComponent<Diff<T, GTMPropertyTrendsTrackingProps>, any>'.
    return withGTM(GTMPropertyTrendsTracking);
};

export default withGTMPropertyTrendsTracking;
