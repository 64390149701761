//@ts-nocheck
// flow

import Loadable from 'strat/loadable';

/**
 * Loads the image slideshow component async.
 */
export default Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'imageSlideShowMinimalist' */
            './swipeableImageSlideshow'
        ),
    loading: () => null,
});
