import type { PropertyData } from 'strat/property/types';

/**
 * Available favorites actions.
 */
const Actions = Object.freeze({
    SET_FAVORITES: 'FAVORITES/SET_FAVORITES' as const,
    ADD_FAVORITE: 'FAVORITES/ADD_FAVORITE' as const,
    REMOVE_FAVORITE: 'FAVORITES/REMOVE_FAVORITE' as const,
    SET_INACTIVE_FAVORITES: 'FAVORITES/SET_INACTIVE_FAVORITES' as const,
    ADD_INACTIVE_FAVORITE: 'FAVORITES/ADD_INACTIVE_FAVORITE' as const,
    REMOVE_INACTIVE_FAVORITE: 'FAVORITES/REMOVE_INACTIVE_FAVORITE' as const,
});

/**
 * Type for the {@see Actions.SET_FAVORITES}.
 */
export type SetFavoritesAction = {
    type: 'FAVORITES/SET_FAVORITES';
    favorites: Array<PropertyData>;
    externalID?: string;
};

/**
 * Type for the {@see Actions.SET_INACTIVE_FAVORITES}.
 */
export type SetInactiveFavoritesAction = {
    type: 'FAVORITES/SET_INACTIVE_FAVORITES';
    inactiveFavorites: Array<PropertyData>;
    externalID?: string;
};

/**
 * Type for the {@see Actions.ADD_FAVORITE}.
 */
export type AddFavoriteAction = {
    type: 'FAVORITES/ADD_FAVORITE';
    property: PropertyData;
    externalID?: string;
};

/**
 * Type for the {@see Actions.ADD_INACTIVE_FAVORITE}.
 */
export type AddInactiveFavoriteAction = {
    type: 'FAVORITES/ADD_INACTIVE_FAVORITE';
    externalID: string;
};

/**
 * Type for the {@see Actions.REMOVE_FAVORITE}.
 */
export type RemoveFavoriteAction = {
    type: 'FAVORITES/REMOVE_FAVORITE';
    externalID: string;
};

/**
 * Type for the {@see Actions.REMOVE_INACTIVE_FAVORITE}.
 */
export type RemoveInactiveFavoriteAction = {
    type: 'FAVORITES/REMOVE_INACTIVE_FAVORITE';
    externalID: string;
};

/**
 * Union type for all available favorites related actions.
 */
export type FavoritesAction =
    | RemoveFavoriteAction
    | SetFavoritesAction
    | AddFavoriteAction
    | SetInactiveFavoritesAction
    | AddInactiveFavoriteAction
    | RemoveInactiveFavoriteAction;

export default Actions;
