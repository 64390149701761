import * as React from 'react';
import { useSelector } from 'react-redux';
import { trigger, Triggers, ViewSections } from 'strat/gtm';

import type { Category, Ad, MixedAd } from 'horizontal/types';
import { selectCategories } from 'horizontal/categories';

import { getAdVars, getDeepCategoryVars } from './utils';

const useTrackLoadMoreSearchRecommendations = () => {
    const categories = useSelector(selectCategories);

    return React.useCallback(
        (category: Category, totalPageCount: number) => {
            trigger(Triggers.CLICK_VIEW_MORE_SEARCH_RECOMMENDATIONS, {
                ...getDeepCategoryVars(categories, category?.externalID),
                view_section: ViewSections.BODY,
                page_count: totalPageCount,
            });
        },
        [categories],
    );
};

const trackPostNowClick = (ad?: Ad | null, userExternalId?: string | null) => {
    trigger(Triggers.PUBLISH_AD, {
        ...getAdVars(ad),
        user_id: userExternalId,
        view_section: ViewSections.BODY,
    });
};

const trackFavoriteToggle = (
    ad: Ad,
    viewSection: Values<typeof ViewSections>,
    isSelected: boolean,
) => {
    trigger(Triggers.FAVORITE_TOGGLE, {
        ...getAdVars(ad),
        filter_name: 'favourite-toggle',
        filter_value: isSelected ? 'on' : 'off',
        view_section: viewSection,
    });
};

const trackSearchHistoryScroll = () => {
    trigger(Triggers.CLICK_VIEW_MORE_SEARCH_RECOMMENDATIONS, {
        view_section: ViewSections.BODY,
    });
};

const trackMyAdsAdFilter = (adFilter: string, pageNumber: number) =>
    trigger(Triggers.AD_FILTER, { ad_filter: adFilter, page_number: pageNumber });

const trackClickPreviewAd = (ad?: Ad | null) => {
    trigger(Triggers.CLICK_PREVIEW_AD, {
        ...getAdVars(ad),
        view_section: ViewSections.BODY,
    });
};

const trackBuyWithDelivery = (ad: MixedAd, viewSection: Values<typeof ViewSections>) => {
    trigger(Triggers.CLICK_BUY_WITH_DELIVERY, {
        ...getAdVars(ad),
        view_section: viewSection,
    });
};

const trackCheckDelivery = (ad: MixedAd, viewSection: Values<typeof ViewSections>) => {
    trigger(Triggers.CLICK_CHECK_DELIVERY, {
        ...getAdVars(ad),
        view_section: viewSection,
    });
};

const trackPlaceOrder = (ad: MixedAd, viewSection: Values<typeof ViewSections>) => {
    trigger(Triggers.CLICK_PLACE_ORDER, {
        ...getAdVars(ad),
        view_section: viewSection,
    });
};

export {
    useTrackLoadMoreSearchRecommendations,
    trackPostNowClick,
    trackFavoriteToggle,
    trackSearchHistoryScroll,
    trackMyAdsAdFilter,
    trackClickPreviewAd,
    trackBuyWithDelivery,
    trackCheckDelivery,
    trackPlaceOrder,
};
