import * as React from 'react';
import { Text, Flex } from 'strat/components';

import styles from './styles/chip.cssm';

type Props = {
    readonly title?: string;
    readonly renderIcon?: () => React.ReactNode;
    readonly onClick?: () => void;
};
const Chip = ({ title, renderIcon, onClick }: Props) => {
    return (
        <Flex alignCenter className={styles.categoryChip} onClick={onClick}>
            <Text.Small bold>{title}</Text.Small>
            {renderIcon && renderIcon()}
        </Flex>
    );
};

export default Chip;
