/** compute a latitude/longitude coordinate given an initial coordinate
 *  and and x and an y offset in meters.
 *  This is an approximation, good for small distances and places far from the earth poles
 */
const addMetersToLatLong = ([latitude, longitude]: [any, any], x: number, y: number) => [
    latitude + y / 111111,
    longitude + x / 111111 / Math.cos((latitude * Math.PI) / 180),
];

/** given two geographical coordinates compute the distance between them,
 *  returned as x, y offsets in meters
 *  This is an approximation, good for small distances and places far from the earth poles
 */
const latitudeLongitudeDeltaToMeters = (
    [latitude1, longitude1]: [any, any],
    [latitude2, longitude2]: [any, any],
) => [
    (longitude2 - longitude1) * 111111 * Math.cos((latitude1 * Math.PI) / 180),
    (latitude2 - latitude1) * 111111,
];

/**
 * calculate the coordinates of an ellipse centered in origin, given the small and large radius,
 * the angle in radians and the number of points
 */
const ellipse = (rx: number, ry: number, a: number, steps: number): Array<[number, number]> => {
    const angles = Array(steps)
        .fill(0)
        .map((_, i) => (i * 2 * Math.PI) / steps);

    angles.push(0); // we want a closed ellipse, with he last point equal to the first one

    return angles.map((u: number): [number, number] => [
        rx * Math.cos(u) * Math.cos(a) - ry * Math.sin(u) * Math.sin(a),
        rx * Math.cos(u) * Math.sin(a) + ry * Math.sin(u) * Math.cos(a),
    ]);
};

/** compute a geografical polygon in the shape of an ellipse slice given
 * the coordinates of the focal points, the starting width of the ellipse
 * and the slice width
 */
const polygon = (
    f1: [number, number],
    f2: [number, number],
    sliceStart: number,
    sliceWidth: number,
) => {
    const [deltaX, deltaY] = latitudeLongitudeDeltaToMeters(f1, f2);
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY); // distance between work and school
    const c = distance / 2; // focal point coordinate
    const rx = c + sliceStart; // the big radius
    const ry = Math.sqrt(rx * rx - c * c); // the small radius
    const rx2 = c + sliceStart + sliceWidth;
    const ry2 = Math.sqrt(rx2 * rx2 - c * c);

    const alpha = Math.atan2(deltaY, deltaX); // the ellipse angle
    const center = addMetersToLatLong(f1, deltaX / 2, deltaY / 2); // the center of the ellipse

    if (sliceStart === 0) {
        // @ts-expect-error - TS2345 - Argument of type '([x, y]: [any, any]) => any[]' is not assignable to parameter of type '(value: [number, number], index: number, array: [number, number][]) => [number, number]'.
        return ellipse(rx2, ry2, alpha, 32).map<[number, number]>(([x, y]: [any, any]) =>
            // @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type '[any, any]'.
            addMetersToLatLong(center, x, y),
        );
    }
    return [...ellipse(rx, ry, alpha, 32), ...ellipse(rx2, ry2, alpha, 32).reverse()].map<
        [number, number]
        // @ts-expect-error - TS2345 - Argument of type '([x, y]: [any, any]) => any[]' is not assignable to parameter of type '(value: [number, number], index: number, array: [number, number][]) => [number, number]'. | TS2345 - Argument of type 'any[]' is not assignable to parameter of type '[any, any]'.
    >(([x, y]: [any, any]) => addMetersToLatLong(center, x, y));
};

export { addMetersToLatLong, latitudeLongitudeDeltaToMeters, ellipse };

export default polygon;
