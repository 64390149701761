import * as React from 'react';

import usePromotedSearchSections, { PromotedSearchSection } from './usePromotedSearchesWithHits';
import CategoryWithHitsSection from './categoryWithHitsSection';

const PromotedSearchesSection = () => {
    const promotedSearchSections = usePromotedSearchSections();

    return (
        <>
            {promotedSearchSections.map(
                ({ category, location, title, hits }: PromotedSearchSection, index: number) => (
                    <CategoryWithHitsSection
                        key={index}
                        category={category}
                        location={location}
                        title={title}
                        ads={hits}
                    />
                ),
            )}
        </>
    );
};

export default PromotedSearchesSection;
