import * as React from 'react';

import { ResponsiveBottomSheet } from 'strat/modal/compact/bottomSheet';

import styles from './styles/propertyInformationBottomSheet.cssm';
import BottomSheetCloseButton from './bottomSheetCloseButton';

type Props = {
    name: string;
    renderContent: (props: {
        onClick: (event: React.SyntheticEvent<any>) => void;
    }) => React.ReactElement;
    renderBottomSheetContent: () => React.ReactElement;
};

type ContentProps = {
    title: React.ReactNode;
    text: React.ReactNode;
};

export const PropertyInformationBottomSheetContent = ({ title, text }: ContentProps) => (
    <>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
    </>
);

const PropertyInformationBottomSheet = ({
    renderContent,
    renderBottomSheetContent,
    name,
}: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            {renderContent({ onClick: () => setIsOpen(true) })}
            <ResponsiveBottomSheet name={name} onClose={() => setIsOpen(false)} isOpen={isOpen}>
                <div className={styles.contentContainer}>
                    {renderBottomSheetContent()}
                    <BottomSheetCloseButton onClick={() => setIsOpen(false)} />
                </div>
            </ResponsiveBottomSheet>
        </>
    );
};

export default PropertyInformationBottomSheet;
