import * as React from 'react';

import { LiteAd } from 'horizontal/types';
import VerifiedProfileIcon from 'horizontal/profile/verifiedProfileIcon';

import AgencySummary from './agencySummary';
import styles from './styles/sellerDetails.cssm';

type Props = {
    readonly ad: LiteAd;
    readonly showAgency?: boolean;
    readonly showVerifiedBadge: boolean;
};

const SellerDetailsCompact = ({ ad, showAgency, showVerifiedBadge }: Props) => {
    if (!showVerifiedBadge) {
        return showAgency ? <AgencySummary ad={ad} hideDescription hideVerifiedBadge /> : null;
    }
    return (
        <div className={styles.verifiedProfileBadgeWrapper}>
            <VerifiedProfileIcon large />
        </div>
    );
};

export default SellerDetailsCompact;
