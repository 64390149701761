import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { useSelector } from 'react-redux';
import { selectIsLanguageRTL } from 'strat/i18n/language';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import IconArrowLeft from '@app/assets/icons/iconArrowLeft.svg';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';

import { trackBusinessPackagesClick } from 'horizontal/gtm';
import { useNavigateToSelectMultiplePackages } from 'horizontal/businessPackages/hooks';
import businessMultiWEBP from 'horizontal/assets/images/businessMulti.webp';
import businessMultiPNG from 'horizontal/assets/images/businessMulti.png';
import { ProductPackageOffer, ProductPurchase, ProductType } from 'horizontal/types';
import type { Ad } from 'horizontal/types';

import { getProductsTypes } from '../utils';

import styles from './styles/packagesOffersPromo.cssm';

type Props = {
    readonly text: string;
    ad: Ad | null | undefined;
    availableAdPackages:
        | Record<Values<typeof ProductType>, ProductPurchase[]>
        | Array<ProductPackageOffer>
        | Array<ProductPurchase>;
};

const TextLink = ({
    text,
    onClick,
    className,
}: {
    text: string;
    onClick: () => void;
    className?: string;
}) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);
    return (
        <div className={className || styles.link} onClick={onClick}>
            <Text.Regular bold>{text}</Text.Regular>
            {isLanguageRTL ? (
                <IconArrowLeft className={styles.arrowIcon} />
            ) : (
                <IconArrowRight className={styles.arrowIcon} />
            )}
        </div>
    );
};

const PackagesOffersPromo = ({ text, ad, availableAdPackages }: Props) => {
    const i18n = useI18n();
    const navigateToSelectPackages = useNavigateToSelectMultiplePackages();
    const productsTypes = getProductsTypes(availableAdPackages);
    const navigateToBusinessPackages = React.useCallback(() => {
        if (!ad) {
            return;
        }
        trackBusinessPackagesClick();
        const categoryExternalID = ad.category[ad.category.length - 1]?.externalID;
        const locationExternalID = ad.location[ad.location.length - 1]?.externalID;
        navigateToSelectPackages({
            locationExternalID,
            categoryExternalID,
            adExternalID: ad.externalID,
            productTypes: ad.externalID ? productsTypes : [],
        });
    }, [ad, navigateToSelectPackages, productsTypes]);

    if (!settings.enableBusinessPackages) {
        return null;
    }

    return (
        <Flex className={styles.container} alignCenter justifySpaceBetween>
            <Flex column>
                <Text.Large>{text}</Text.Large>
                <TextLink onClick={navigateToBusinessPackages} text={t(i18n)`View Offers`} />
            </Flex>

            <picture>
                <source srcSet={businessMultiWEBP} type="image/webp" />
                <img
                    src={businessMultiPNG}
                    className={styles.image}
                    alt={'Business Packages Discount'}
                />
            </picture>
        </Flex>
    );
};

export { TextLink };
export default PackagesOffersPromo;
