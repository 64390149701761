import * as React from 'react';
import { Formik } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { useActiveUser } from 'strat/user/session';

import { AddressFormActions } from '../types';
import type { AddressInfo } from '../types';

import Form from './addressForm';
import { addUserAddress, changeAddressInfo } from './AddressBookActions';
import styles from './styles/addressBook.cssm';
import { validationSchema } from './addressBookValidation';

const renderForm = () => (props: React.ComponentProps<typeof Form>) => <Form {...props} />;

type Props = {
    readonly closeDialog: () => void;
    editAddressValues?: AddressInfo;
    getAddressList?: () => void;
    submitForm?: () => void;
};

const AddressBook = ({ closeDialog, editAddressValues, getAddressList }: Props) => {
    const user = useActiveUser();
    const formRef = React.useRef();
    const i18n = useI18n();
    const userExternalID = user?.externalID || '';

    const initialValues = React.useMemo(() => {
        return {
            addressLineOne: editAddressValues?.addressLineOne || '',
            addressLineTwo: editAddressValues?.addressLineTwo || '',
            locationPath: editAddressValues?.locationPath || '',
            notes: editAddressValues?.notes || '',
            name: editAddressValues?.name || '',
        };
    }, [editAddressValues]);

    const onSubmit = React.useCallback(
        (values: AddressInfo) => {
            if (editAddressValues?.id) {
                changeAddressInfo(
                    {
                        id: editAddressValues?.id,
                        isDefault: editAddressValues?.isDefault,
                        ...values,
                    },
                    userExternalID,
                    AddressFormActions.EDIT,
                    getAddressList,
                );

                closeDialog();
            } else {
                addUserAddress(values, userExternalID, closeDialog, getAddressList);
            }
        },
        [closeDialog, editAddressValues, userExternalID, getAddressList],
    );

    return (
        <div>
            <div className={styles.form}>
                <Formik
                    initialValues={initialValues}
                    innerRef={formRef}
                    validationSchema={validationSchema(i18n)}
                    onSubmit={onSubmit}
                >
                    {renderForm()}
                </Formik>
            </div>
        </div>
    );
};

export default AddressBook;
