import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectProperty } from 'strat/property/selectors';

import DescriptionText, { Props } from './descriptionText';

const PropertyDescriptionText = (props: Props) => {
    const property = useSelector(selectProperty);

    return <DescriptionText {...props} property={property} />;
};
export default PropertyDescriptionText;
