import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';

import Card from './card';
import {
    RecommendationsNotificationsControl,
    SafetyTipsControl,
    OffersNotificationsControl,
} from './controls';

const NotificationsSheet = () => {
    const i18n = useI18n();

    return (
        <Flex stretchWidth column>
            <Card title={t(i18n)`Notifications`}>
                <OffersNotificationsControl />
                <RecommendationsNotificationsControl />
                <SafetyTipsControl />
            </Card>
        </Flex>
    );
};

export default NotificationsSheet;
