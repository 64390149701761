import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectMetrics } from './selectors';
import type { AdMetric, AdMetrics } from './types';

const useAdsMetrics = () => useSelector(selectMetrics);

export const useAdMetrics = (adExternalID: string): AdMetric | null => {
    const metrics: AdMetrics = useAdsMetrics();

    return React.useMemo(() => metrics[adExternalID], [metrics, adExternalID]);
};

export const useAdViews = (adExternalID: string) => {
    const adMetric = useAdMetrics(adExternalID);
    if (!adMetric) {
        return null;
    }

    return adMetric.viewCount;
};

export const useAdPhoneViews = (adExternalID: string) => {
    const adMetric = useAdMetrics(adExternalID);
    if (!adMetric) {
        return null;
    }

    return adMetric.phoneViewCount;
};

export const useAdChatLeadsCount = (adExternalID: string) => {
    const adMetric = useAdMetrics(adExternalID);
    if (!adMetric) {
        return null;
    }

    return adMetric.chatLeadsCount;
};

export const useAdFavorites = (adExternalID: string) => {
    const adMetric = useAdMetrics(adExternalID);
    if (!adMetric) {
        return null;
    }

    return adMetric.favoriteCount;
};

export const useAdWhatsAppLeadsCount = (adExternalID: string) => {
    const adMetric = useAdMetrics(adExternalID);
    if (!adMetric) {
        return null;
    }

    return adMetric.whatsAppLeadsCount;
};
