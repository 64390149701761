import { RouteNames } from 'strat/routes';

import { ChatRoomMemberRole } from 'horizontal/chat/constants';
import type { ChatRoom } from 'horizontal/chat/types';

import useChatSDK from './useChatSDK';

const useProfileDetails = (conversation: ChatRoom) => {
    const sdk = useChatSDK();
    const { ad } = sdk.useRoomContactInfo(conversation);
    const { contactXID } = conversation;
    const { agency } = ad || {};
    const { name, slug } = agency || {};
    const { myRole } = conversation;
    const isSeller = myRole === ChatRoomMemberRole.SELLER;

    if (name && !isSeller) {
        return {
            routeName: RouteNames.AGENCY_PROFILE,
            routeParams: { agencySlug: slug },
            contactXID,
            agencyName: name,
        };
    }
    return {
        routeName: RouteNames.SELLER_PROFILE,
        routeParams: { externalID: contactXID },
        contactXID,
    };
};

export default useProfileDetails;
