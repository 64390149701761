import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import { LiteAd } from 'horizontal/types';

import BuyWithDeliveryButton from './buyWithDeliveryButton';
import styles from './styles/contactButtons.cssm';

type Props = {
    readonly ad: LiteAd;
    readonly fullWidth?: boolean;
};
const BuyWithDeliveryCTA = ({ ad, fullWidth }: Props) => {
    return (
        <Flex className={styles.container}>
            <div
                className={classNames(styles.deliveryButtonWrapper, {
                    [styles.fullWidth]: fullWidth,
                })}
                aria-label="Buy with delivery"
            >
                <BuyWithDeliveryButton ad={ad} />
            </div>
        </Flex>
    );
};

export default BuyWithDeliveryCTA;
