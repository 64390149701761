import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Category from '@app/branding/category';
import { checkCarCategories, checkPropertyCategories } from '@app/branding/checkCategories';
import { EmptyDivBanner } from 'strat/search';
import { selectRouteParams } from 'strat/routes/selectors';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';

import type { LiteAd, MixedAd } from 'horizontal/types';
import {
    SellNowListing,
    makeLoadingAdCards,
    useShowHeroCategoryAdCardDesign,
} from 'horizontal/adCard';
import AdCard from '@app/adCard/adCard';

import styles from './styles/hitList.cssm';
import useInsertDfpInHitList from './useInsertDfpInHitList';

type Props = {
    readonly viewType: Values<typeof SearchHitsLayout>;
    readonly hits: Array<LiteAd>;
    readonly emptyDivPosition?: number;
    readonly CTAPosition?: number;
    readonly loading?: boolean;
    readonly loadingHitsCount?: number;
    readonly maxHitsPerLine?: number;
    readonly viewSection: Values<typeof ViewSections>;
    readonly highlighted?: boolean;
    readonly startingHitsIndex?: number;
};

const customHitClasses = (hit: MixedAd) => {
    // Verify if hit has Video, Badges or both.
    const hasVerifiedByOlxBadge = !!hit.documentCount;
    const hasVideoBadge = !!hit.videoCount;

    // Verify if hit has a subtitle. Only hits from Cars and Properties
    // categories may have subtitles.
    const hitCategory = hit?.category?.[1];
    const hasSubtitle = checkCarCategories(hitCategory) || checkPropertyCategories(hitCategory);

    return {
        [styles.hasBadge]: hasVideoBadge || hasVerifiedByOlxBadge,
        [styles.hasSubtitle]: hasSubtitle,
    };
};

const HitList = ({
    viewType,
    hits,
    emptyDivPosition,
    CTAPosition,
    loading,
    loadingHitsCount = 0,
    maxHitsPerLine = 3,
    viewSection,
    highlighted,
    startingHitsIndex = 0,
}: Props) => {
    const containerStyle = classNames({
        [styles.grid]: viewType === SearchHitsLayout.GRID,
        [styles.list]: viewType === SearchHitsLayout.LIST,
        [styles.threePerLine]: maxHitsPerLine === 3,
        [styles.fourPerLine]: maxHitsPerLine === 4,
        [styles.extendedGridHeight]:
            viewType === SearchHitsLayout.GRID && settings.extendedAdCardHeight,
    });

    // Subtitles won't be shown when a general category (e.g. Vehicles) is chosen
    // and the viewType is grid.
    const { categorySlug, parentCategorySlug } = useSelector(selectRouteParams);
    const generalCategory =
        (Category.isOfVehiclesType(categorySlug) || Category.isOfPropertiesType(categorySlug)) &&
        viewType === SearchHitsLayout.GRID;

    const listings = hits.map((hit, index) => {
        return (
            <li
                key={hit.id}
                className={classNames(
                    { [styles.generalCategory]: generalCategory },
                    customHitClasses(hit),
                )}
                aria-label="Listing"
            >
                <AdCard
                    ad={hit}
                    index={startingHitsIndex + index}
                    viewType={viewType}
                    viewSection={viewSection}
                    highlighted={highlighted}
                />
            </li>
        );
    });

    const dfpInserted = useInsertDfpInHitList(
        listings,
        viewType,
        parentCategorySlug || categorySlug,
    );

    if (CTAPosition) {
        // If DFP ads are inserted, we have to increase the CTA position
        // by 1 to properly insert it after the 9th Ad
        listings.splice(
            Math.min(listings.length, CTAPosition + (dfpInserted ? 1 : 0)),
            0,
            <li className={styles.sellNowListing} key={'sell-now-listing'}>
                <SellNowListing viewType={viewType} />
            </li>,
        );
    }

    if (
        emptyDivPosition &&
        !CONFIG.build.DISABLE_EMPTY_DIV_BANNERS &&
        viewType === SearchHitsLayout.LIST
    ) {
        listings.splice(
            Math.min(listings.length, emptyDivPosition),
            0,
            <EmptyDivBanner key={'empty-div-banner'} />,
        );
    }

    const showHeroCategoryAdCardDesign = useShowHeroCategoryAdCardDesign(viewType);

    if (loading) {
        listings.push(
            // @ts-expect-error - TS2345 - Argument of type 'ReactElement<any, string | JSXElementConstructor<any>>[]' is not assignable to parameter of type 'Element'.
            makeLoadingAdCards(
                loadingHitsCount,
                viewType,
                (children: React.ReactNode, key: number) => (
                    <li
                        key={key}
                        className={classNames({
                            [styles.loadingCard]: !showHeroCategoryAdCardDesign,
                        })}
                    >
                        {children}
                    </li>
                ),
                showHeroCategoryAdCardDesign,
            ),
        );
    }

    return <ul className={containerStyle}>{listings}</ul>;
};

export default HitList;
