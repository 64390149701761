import React from 'react';
import { Trans } from '@lingui/macro';

import { Flex } from 'strat/components';
import { PropertyFloorPlanImageData, PropertyPhotoData } from 'strat/property/types';
import { PhotoGalleryDialog } from 'strat/property/gallery';
import {
    ImageSlideshowBullets,
    ImageSlideShowItemType,
    SwipeableImageSlideshow,
    ThumbnailImage,
    ThumbnailSizes,
    ImageGalleryDialog,
} from 'strat/image';
import BackButton from 'strat/property/compact/gallery/backButton';

import { masterPlanSectionElementID } from './projectIdentifiers';
import SectionTitle from './sectionTitle';
import styles from './styles/masterPlanSection.cssm';

type Props = {
    masterPlans: readonly PropertyFloorPlanImageData[];
    compact?: boolean;
};

const renderPhoto = (photo: PropertyPhotoData) => (
    <ThumbnailImage
        imageID={photo.id}
        size={ThumbnailSizes.LARGE}
        alt={photo.title}
        title={photo.title}
        label="Master plan"
        className={styles.image}
        lazyLoad
    />
);

const renderBullets = (currentIndex: number, itemsCount: number) => (
    <div className={styles.bullets}>
        <ImageSlideshowBullets
            currentIndex={currentIndex}
            numberOfItems={itemsCount}
            bulletContainerClassName={styles.bulletContainer}
        />
    </div>
);

const MasterPlanSection = ({ masterPlans, compact }: Props) => {
    const [dialogVisible, setDialogVisible] = React.useState(false);

    const [swipeableGalleryIndex, setSwipeableGalleryIndex] = React.useState(0);
    const [galleryDialogIndex, setGalleryDialogIndex] = React.useState(0);

    const slides = React.useMemo(() => {
        return masterPlans.map((item) => ({
            ...item,
            type: ImageSlideShowItemType.PHOTO,
        }));
    }, [masterPlans]);

    const openFullScreenDialog = () => {
        // `SwipeableImageSlideshow` is not controllable, so we cannot update it
        // when swiping the fullscreen dialog. As a compromise we just initialize
        // the full screen dialog to the swipeable gallery index every time
        // the use opens it.
        setGalleryDialogIndex(swipeableGalleryIndex);
        setDialogVisible(true);
    };

    const renderCloseButton = () => (
        <BackButton onClick={() => setDialogVisible(false)} className={styles.backButton} />
    );

    const Gallery = compact ? ImageGalleryDialog : PhotoGalleryDialog;
    const galleryProps = compact
        ? {
              swiping: true,
              renderCloseButton,
          }
        : {
              withOverlay: true,
              closeButtonLabel: <Trans>Go back</Trans>,
          };

    return (
        <>
            <Flex column>
                <SectionTitle id={masterPlanSectionElementID}>
                    <Trans>Master Plan</Trans>
                </SectionTitle>
                <Flex className={styles.imageContainer}>
                    <SwipeableImageSlideshow
                        // @ts-expect-error connectors are not properly typed
                        items={masterPlans}
                        itemCount={masterPlans.length}
                        startIndex={swipeableGalleryIndex}
                        renderItem={renderPhoto}
                        autoHideNavArrows={false}
                        lazyLoad
                        onImageNavigation={setSwipeableGalleryIndex}
                        className={styles.gallery}
                        renderBullets={renderBullets}
                        onClick={openFullScreenDialog}
                    />
                </Flex>
            </Flex>
            <Gallery
                photos={slides}
                slideIndex={galleryDialogIndex}
                startIndex={galleryDialogIndex}
                visible={dialogVisible}
                onCloseButtonClick={() => setDialogVisible(false)}
                onSlide={setGalleryDialogIndex}
                {...galleryProps}
            />
        </>
    );
};

export default MasterPlanSection;
