import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { SavedSearchData } from './types';
import {
    selectSavedSearches,
    selectShouldFetchSavedSearches,
    selectCurrentSearchAsSaved,
} from './selectors';
import { fetchSavedSearches } from './state';

/**
 * Exposes saved searches to the component that uses it.
 *
 * If Saved Searches are not loaded, it dispatches an action to fetch them from Django API.
 */
const useSavedSearches = () => {
    const dispatch = useDispatch();

    const shouldFetch = useSelector(selectShouldFetchSavedSearches);
    const savedSearches = useSelector(selectSavedSearches);

    React.useEffect(() => {
        if (shouldFetch) {
            // @ts-expect-error - TS2345 - Argument of type '() => Promise<{ data: any; status: number; }>' is not assignable to parameter of type 'Action'.
            dispatch(fetchSavedSearches());
        }
    }, [shouldFetch, dispatch]);

    return savedSearches;
};

/**
 * Exposes the saved search corresponding to the current search URL
 * (in case the current search matches one of the saved searches).
 *
 * It needs saved searches data, so call the `useSavedSearches`
 * hook to make sure saved searches are available.
 */
const useCurrentSearchAsSaved = (): SavedSearchData | null | undefined => {
    useSavedSearches();
    return useSelector(selectCurrentSearchAsSaved);
};

export { useSavedSearches, useCurrentSearchAsSaved };
