import FetcherFactory from 'strat/fetcher';
import { selectActiveUser } from '@app/user/session';

import { StratAPI } from 'horizontal/api';

import { DeliveryRequestStatus } from '../types';

const factory = new FetcherFactory(
    'buyingOrder',
    (
        {
            offersStatus = DeliveryRequestStatus.ACTIVE,
        }: {
            offersStatus?: string;
        },
        state: any,
    ) => {
        const profile = selectActiveUser(state);
        const userExternalID = profile?.externalID;
        if (!userExternalID) {
            return Promise.resolve({ status: 200, data: [] });
        }

        return new StratAPI(state.i18n.language).userBuyingOrdersList(userExternalID, offersStatus);
    },
    {
        catchServerErrors: true,
        skipParamsCheck: true,
    },
);

const fetchUserBuyingList = factory.creator();
const buyingOrderReducer = factory.reducer();

export { fetchUserBuyingList };

export default buyingOrderReducer;
