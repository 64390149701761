import * as React from 'react';
import isNil from 'lodash/isNil';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'react-copy-to-clipboard'. 'node_modules/react-copy-to-clipboard/lib/index.js' implicitly has an 'any' type.
import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans } from '@lingui/macro';

import { CloseDialogButton, Dialog as GenericDialog, ErrorDialog, Toast } from 'strat/modal';
import { LoadingSpinner } from 'strat/loadable';
import type { CancelablePromise } from 'strat/util';
import LinkCopied from 'strat/misc/linkCopied';
import CopyIcon from '@app/assets/icons/iconCopyToClipboard.svg';
import { GTMLeadTrackingProps, withGTMLeadTracking, ShareMediums, PageTypes } from 'strat/gtm';
import type { PropertyData } from 'strat/property/types';
import Triggers from 'strat/gtm/triggers';
import { Flex } from 'strat/components';
import EMPTY_FUNCTION from 'strat/empty/function';

import type { ShareLinkState } from '../types';
import { ShareLinkStates } from '../types';

import createSocialShareLinks from './createSocialShareLinks';
import styles from './styles/dialog.cssm';

type Props = GTMLeadTrackingProps & {
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
    linkData: string;
    activeState: ShareLinkState;
    title: string;
    triggerName: string;
    property?: PropertyData;
    pageType: Values<typeof PageTypes>;
    cancelablePromise?: CancelablePromise;
    copyPadText: string | null;
    shareMethods?: typeof ShareMediums;
    onClick?: (shareMethod?: Values<typeof ShareMediums>) => void;
    onWhatsAppClick?: () => void;
    renderCopyPad?: (props: CopyPadProps) => React.ReactNode;
    renderCopyLinkText?: (className: string) => React.ReactNode;
    renderSubtitle?: (className: string) => React.ReactNode;
    containerClassName?: string;
};

export type CopyPadProps = {
    toastVisible: boolean;
    onHideToast: () => void;
    onCopy: () => void;
    linkData: string;
    copyPadText: string | null;
};

const CopyPad = ({ toastVisible, onCopy, onHideToast, linkData, copyPadText }: CopyPadProps) => {
    const hasCopyPadText = !isNil(copyPadText);
    const copyText = hasCopyPadText ? `${copyPadText}\n${linkData}` : linkData;
    return (
        <Flex alignCenter justifyCenter className={styles.copyPad}>
            <input className={styles.link} type="text" value={linkData} disabled />
            <CopyToClipboard
                key="shareDialogLink"
                onCopy={onCopy}
                text={copyText}
                aria-label={'Copy link'}
            >
                <div className={styles.copyAndToastContainer}>
                    <div className={styles.copyButton}>
                        <CopyIcon className={styles.copyIcon} />
                        <Trans>Copy</Trans>
                    </div>
                    {toastVisible && (
                        <Toast key="toast" onHide={onHideToast} timeout={1000}>
                            <LinkCopied
                                toastStyle={styles.toast}
                                message={
                                    hasCopyPadText ? (
                                        <Trans>Copied</Trans>
                                    ) : (
                                        <Trans>Link copied</Trans>
                                    )
                                }
                            />
                        </Toast>
                    )}
                </div>
            </CopyToClipboard>
        </Flex>
    );
};

const defaultRenderCopyPad = (props: CopyPadProps) => <CopyPad {...props} />;

const defaultRenderSubtitle = (className: string) => (
    <h3 className={className}>
        <Trans>Social media and e-mail:</Trans>
    </h3>
);

const defaultRenderCopyLinkText = (className: string) => (
    <h3 className={className}>
        <Trans>Or copy link:</Trans>
    </h3>
);

const Dialog = ({
    visible,
    onVisibilityChanged,
    activeState,
    linkData,
    title,
    triggerName,
    trackShareAgentProfileLink,
    trackSharePropertyLink,
    property,
    pageType,
    cancelablePromise,
    copyPadText,
    shareMethods,
    onClick = EMPTY_FUNCTION,
    onWhatsAppClick = EMPTY_FUNCTION,
    renderSubtitle = defaultRenderSubtitle,
    renderCopyLinkText = defaultRenderCopyLinkText,
    renderCopyPad = defaultRenderCopyPad,
    containerClassName = styles.container,
}: Props) => {
    const [toastVisible, setToastVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        return () => {
            if (cancelablePromise) {
                cancelablePromise.cancel();
            }
        };
    }, [cancelablePromise]);

    const changeVisibility = React.useCallback(
        (visibility) => {
            onVisibilityChanged(visibility);
            if (toastVisible) {
                setToastVisible(false);
            }
        },
        [onVisibilityChanged, toastVisible, setToastVisible],
    );

    const onHideToast = React.useCallback(() => {
        setToastVisible(false);
    }, [setToastVisible]);

    const onCopy = React.useCallback(() => {
        setToastVisible(true);
        if (triggerName === Triggers.SHARE_PROFILE_LINK) {
            trackShareAgentProfileLink(ShareMediums.COPY);
        }
        if (triggerName === Triggers.SHARE_PROPERTY) {
            trackSharePropertyLink(property, ShareMediums.COPY, pageType);
        }
    }, [
        setToastVisible,
        triggerName,
        property,
        pageType,
        trackShareAgentProfileLink,
        trackSharePropertyLink,
    ]);

    if (activeState === ShareLinkStates.ERROR) {
        return (
            <ErrorDialog visible={visible} onVisibilityChanged={onVisibilityChanged}>
                <Trans>An unknown internal error occurred.</Trans>
            </ErrorDialog>
        );
    }

    return (
        <GenericDialog
            dismissible
            visible={visible}
            onVisibilityChanged={changeVisibility}
            className={containerClassName}
        >
            {/* @ts-expect-error - TS2322 - Type '{ onClick: () => void; className: any; }' is not assignable to type 'IntrinsicAttributes & Diff<Props, OutProps> & RefAttributes<any> & { children?: ReactNode; }'. */}
            <CloseDialogButton onClick={() => changeVisibility(false)} className={styles.close} />
            {activeState === ShareLinkStates.LOADING && (
                <LoadingSpinner className={styles.spinner} />
            )}
            {activeState === ShareLinkStates.SUCCESS && (
                <>
                    <div className={styles.content}>
                        <h1 className={styles.title}>{title}</h1>
                        {renderSubtitle(styles.subtitle)}
                        <div className={styles.socialContainer}>
                            {createSocialShareLinks(
                                linkData,
                                'Social media and e-mail:',
                                triggerName,
                                property,
                                pageType,
                                shareMethods,
                                onClick,
                                copyPadText,
                                onWhatsAppClick,
                            )}
                        </div>
                        {renderCopyLinkText(styles.copyLinkText)}
                        {renderCopyPad({
                            toastVisible,
                            onHideToast,
                            onCopy,
                            linkData,
                            copyPadText,
                        })}
                    </div>
                </>
            )}
        </GenericDialog>
    );
};

export default withGTMLeadTracking(Dialog);
