import * as React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import ArrowDownIcon from '@app/assets/icons/iconArrowDown.svg';
import ArrowUpIcon from '@app/assets/icons/iconArrowUp.svg';
import styles from '@app/generic/styles/accordion.cssm';

type Props = {
    children: React.ReactNode;
    initialExpanded?: boolean;
};

export type ItemProps = {
    title?: string;
    children?: React.ReactNode;
    isExpanded?: boolean;
    onClick?: (event: React.SyntheticEvent<any>) => void;
    icon?: string | undefined;
    iconClassName?: string;
    itemClassName?: string;
    itemHeaderClassName?: string;
    itemContentClassName?: string;
    arrowClassName?: string;
    expandedClassName?: string;
};

const AccordionItem = ({
    title,
    children,
    isExpanded,
    onClick,
    icon,
    iconClassName,
    itemClassName = styles.item,
    itemHeaderClassName = styles.itemHeader,
    itemContentClassName = styles.itemContent,
    arrowClassName = styles.icon,
    expandedClassName = styles.isExpanded,
}: ItemProps) => (
    <div className={itemClassName}>
        <button
            className={classNames(itemHeaderClassName, { [expandedClassName]: isExpanded })}
            onClick={onClick}
        >
            <Flex stretchWidth>
                {icon && <img src={icon} className={iconClassName} />}
                {title}
            </Flex>
            {isExpanded ? (
                <ArrowUpIcon className={arrowClassName} />
            ) : (
                <ArrowDownIcon className={arrowClassName} />
            )}
        </button>
        <div className={classNames(itemContentClassName, { [expandedClassName]: isExpanded })}>
            {children}
        </div>
    </div>
);

const Accordion = ({ children, initialExpanded = true }: Props) => {
    const [expansionStates, setExpansionStates] = React.useState(
        React.Children.map(children, (_, index) => initialExpanded && index === 0),
    );

    const onItemClicked = (index: number) => {
        // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
        const newExpansionStates = expansionStates.map((_) => false);
        // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
        newExpansionStates[index] = !expansionStates[index];

        // @ts-expect-error - TS2345 - Argument of type 'boolean[]' is not assignable to parameter of type 'SetStateAction<never[] | null | undefined>'.
        setExpansionStates(newExpansionStates);
    };

    return (
        <>
            {React.Children.map(children, (child, index) =>
                // @ts-expect-error - TS2769 - No overload matches this call.
                React.cloneElement(child, {
                    onClick: () => onItemClicked(index),
                    // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
                    isExpanded: expansionStates[index],
                }),
            )}
        </>
    );
};

export { Accordion, AccordionItem };
