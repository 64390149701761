import { t } from '@lingui/macro';
import React from 'react';
import { useI18n } from 'strat/i18n/language';

type Params = {
    readonly setFormErrors: (formError: string) => void;
};

export type InputData = {
    name: string;
    value: string;
};
type GenericConfirmFormData = {
    inputsData: Array<InputData> | null | undefined;
    formAction: string;
};

const useCreateConfirmForm = ({ setFormErrors }: Params) => {
    const i18n = useI18n();
    const [renderConfirmForm, setRenderConfirmForm] = React.useState<boolean>(false);

    const [genericConfirmData, setGenericCofirmFormData] = React.useState<GenericConfirmFormData>({
        inputsData: null,
        formAction: '',
    });

    /* The improved '3 Domain Secure' differs in that the form is no longer FIXED.
     *  The payment provider has many scenrios due to different card comapnies
     *  this means that each time the Access Control Server (if am not wrong ACS is
     *  handling this) will respond with DIFFERENT Html Form for each case, this is
     *  why we need a generic way to create the confirm form. In general the HTML
     *  is made of <form> element which has <input> elements inside it.
     */

    const createGenericConfirmForm = React.useCallback(
        (stringHTML: string) => {
            const el = document.createElement('html');
            el.innerHTML = stringHTML;

            const postingForm = el.querySelector('form');
            const inputs = el.querySelectorAll('input');

            const formAction = (postingForm && postingForm.getAttribute('action')) || '';
            const inputsData = Array.from(inputs).map((input) => ({
                name: input.name,
                value: input.value,
            }));

            setGenericCofirmFormData({
                formAction,
                inputsData,
            });

            setRenderConfirmForm(true);

            if (
                inputsData.length < 1 ||
                Array.from(inputs).some((input) => !input || !(input instanceof HTMLInputElement))
            ) {
                setFormErrors(t(i18n)`Error processing payment form`);
            }
        },
        [i18n, setFormErrors],
    );

    return {
        renderConfirmForm,
        genericConfirmData,
        createGenericConfirmForm,
    };
};

export default useCreateConfirmForm;
