import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectDebugMode } from 'strat/user/selectors';
import PageWidthWrapper from 'strat/generic/pageWidthWrapper';

import styles from './styles/searchDebugConsole.cssm';
import DebugConsoleContent from './debugConsoleContent';

const SearchDebugConsole = () => {
    const visible = useSelector(selectDebugMode);
    const [expanded, setExpanded] = React.useState(false);
    if (!visible) {
        return null;
    }

    return (
        <PageWidthWrapper className={styles.container}>
            <DebugConsoleContent hidden={!expanded} />
            <div onClick={() => setExpanded((exp) => !exp)} className={styles.debugText}>
                You are in debug mode. Click to {expanded ? 'collapse' : 'expand'}
            </div>
        </PageWidthWrapper>
    );
};

export default SearchDebugConsole;
