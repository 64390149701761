import * as React from 'react';
import { useSelector } from 'react-redux';
import { GTMDataLayerVariables } from 'strat/gtm';

import {
    selectBusinessPackageCartOffers,
    selectBusinessPackageCartTotalPrice,
} from 'horizontal/businessPackages/selectors';

import useGlobalGTMVars from '../useGlobalGTMVars';

import PageType from './pageType';

const GTMPackageCartDataLayer = () => {
    const globalVars = useGlobalGTMVars();
    const packages = useSelector(selectBusinessPackageCartOffers);
    const totalPrice = useSelector(selectBusinessPackageCartTotalPrice);

    const variables = {
        ...globalVars,
        product_id: packages.map((pack) => pack.packageOfferID),
        payment_amount: totalPrice,
        page_type: PageType.MULTIPLE_PACKAGES,
    } as const;
    return <GTMDataLayerVariables variables={variables} />;
};

export default GTMPackageCartDataLayer;
