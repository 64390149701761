const encodeFreeTextQuery = (query: string): string => {
    return query
        .replace(/\s/g, '-')
        .replace(/&/g, '%26')
        .replace(/[,]/g, '%2C')
        .replace(/[(]/g, '%28')
        .replace(/[)]/g, '%29')
        .replace(/\+/g, '%2B')
        .replace(/"/g, '%22')
        .toLowerCase();
};

export default encodeFreeTextQuery;
