import { t } from '@lingui/macro';
import * as React from 'react';
import { Button, Flex } from 'strat/components';
import { LoadingSpinner } from 'strat/loadable';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import { useNavigateToAdDetailsWithProductPurchaseID } from 'horizontal/adDetails';
import type { FullAd, ProductPurchase } from 'horizontal/types';
import { ErrorBanner } from 'horizontal/packages/individual';
import UpSellingAdSuccessBanner from '@app/adCreation/compact/upSellingAdSuccessBanner';
import { PackagesFlow, PackagesFlowTypeValues } from 'horizontal/packages/types';
import useConfigureProductPurchasesSections from 'horizontal/packages/useConfigureProductPurchasesSections';

import useBoughtPackages from './individual/useBoughtPackages';
import ProductPurchaseOptionsContainer from './productPurchaseOptionsContainer';
import AdSummary from './adSummary';
import PackagesOffersPromo from './components/packagesOffersPromo';
import styles from './styles/applyBoughtProductPurchases.cssm';

type Props = {
    readonly purchasePackages: Array<ProductPurchase> | null | undefined;
    readonly ad: FullAd | null | undefined;
    readonly postAdSuccessPage?: boolean;
    readonly flow: PackagesFlowTypeValues;
    readonly onViewAdButtonClick?: () => void;
    readonly hidePromos?: boolean;
};

const ApplyBoughtProductPurchases = ({
    purchasePackages,
    ad,
    postAdSuccessPage,
    flow,
    onViewAdButtonClick,
    hidePromos = false,
}: Props) => {
    const navigateToAdDetails = useNavigateToAdDetailsWithProductPurchaseID();
    const { i18n, onChoiceClick, consumeProduct, selectedPackage, loading, errorMessage } =
        useBoughtPackages(purchasePackages, ad, navigateToAdDetails);
    const purchasePackagesSections = useConfigureProductPurchasesSections(purchasePackages, flow);
    const mainCTAAction = purchasePackages && !!purchasePackages.length ? consumeProduct : null;

    if (loading) {
        return (
            <Flex justifyCenter alignCenter fillContainer>
                <LoadingSpinner />
            </Flex>
        );
    }

    return (
        <Flex column fillContainer justifySpaceBetween className={styles.container}>
            {flow === PackagesFlow.UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY ? (
                <UpSellingAdSuccessBanner
                    packageSuccess={!postAdSuccessPage}
                    packagesCount={purchasePackagesSections?.length || 0}
                />
            ) : null}
            {errorMessage && <ErrorBanner error={errorMessage} />}

            <Flex className={styles.innerContainer}>
                <Flex
                    className={classNames(styles.packagesSectionContainer, {
                        [styles.border]: !!ad,
                    })}
                    column
                >
                    {ad && (
                        <Flex className={styles.upSellStatementContainer}>
                            <Trans>
                                <span className={styles.upSellStatment}>
                                    Apply upsell option on your ad:{' '}
                                    <span className={styles.upSelledAdTitle}>{ad.title}</span>
                                </span>
                            </Trans>
                        </Flex>
                    )}
                    <Flex column className={styles.content}>
                        {purchasePackagesSections?.map((section) => (
                            <ProductPurchaseOptionsContainer
                                ad={ad}
                                title={section.title}
                                onOptionClicked={onChoiceClick}
                                choices={section.items}
                                packageType={section.packageType}
                                selectedChoice={selectedPackage}
                                key={section.packageType}
                            />
                        ))}
                        {!hidePromos && (
                            <PackagesOffersPromo
                                text={t(i18n)`Buy in bundles and pay less!`}
                                ad={ad}
                                availableAdPackages={purchasePackages || []}
                            />
                        )}
                        <Flex justifyEnd className="">
                            {mainCTAAction ? (
                                <Button onClick={mainCTAAction} className={styles.mainCTAAction}>
                                    {t(i18n)`Apply`}
                                </Button>
                            ) : null}
                        </Flex>
                    </Flex>
                </Flex>
                <AdSummary ad={ad} onViewAdButtonClick={onViewAdButtonClick} />
            </Flex>
        </Flex>
    );
};

export default ApplyBoughtProductPurchases;
