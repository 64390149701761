import * as React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Text } from 'strat/components';

import { DatePicker } from './datePicker';
import styles from './styles/editProfileBirthday.cssm';

type Props = {
    readonly title: string;
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly setFieldTouched: (field: keyof FormikValues) => void;
    readonly fieldKey?: string;
};

const EditProfileBirthday = ({
    title,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    fieldKey = 'birthday',
}: Props) => {
    const onDatePicked = React.useCallback(
        (date) => {
            setFieldValue(fieldKey, date);
            // in case the user fills the birth date for first time ever
            // we want till all fields are defined to consider the field
            // to be touched, which means then we will check the validity
            if (date.year && date.month && date.year) {
                setFieldTouched(fieldKey);
            }
        },
        [setFieldValue, fieldKey, setFieldTouched],
    );

    return (
        <div>
            <DatePicker title={title} initialDate={values[fieldKey]} onDatePicked={onDatePicked} />
            <div className={styles.errorMessage}>
                {touched[fieldKey] && errors[fieldKey] && (
                    <Text.Regular error>{errors[fieldKey]}</Text.Regular>
                )}
            </div>
        </div>
    );
};

export default EditProfileBirthday;
