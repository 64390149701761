import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { Flex } from 'strat/components';

import PhoneNumberGuardedButton from './phoneNumberGuardedButton';
import styles from './styles/contactInfo.cssm';

type Props = {
    readonly adExternalID?: string | null | undefined;
    readonly shortNumber?: string;
    readonly className?: string;
};

const ContactInfo = ({ adExternalID, shortNumber, className }: Props) => {
    return (
        <Flex className={styles.container} fillContainer justifyCenter alignCenter>
            <PhoneNumberGuardedButton
                adExternalID={adExternalID}
                viewSection={ViewSections.SELLER_PROFILE}
                shortNumber={shortNumber}
                className={className}
            />
        </Flex>
    );
};

export default ContactInfo;
