import { useStore } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { buildAllCanonicalURLs } from 'strat/routing';
import type { AllCanonicalURLs } from 'strat/routing';
import createSearchURL from '@app/routes/createSearchURL';
import encodeFreeTextQuery from '@app/search/seo/encodeFreeTextQuery';
import { useSelector } from 'react-redux';

import type { SearchRouteParams } from 'horizontal/routes';
import { selectCanonicalOverride } from 'horizontal/urlSpecificSEO/selectors';

import useReducedExtraFields from './useReducedExtraFields';

const useCanonicalURL = (params: SearchRouteParams): AllCanonicalURLs => {
    const i18n = useI18n();
    const state = useStore().getState();
    const canonicalOverride = useSelector(selectCanonicalOverride);

    const reducedExtraFields = useReducedExtraFields(params.category?.id, params.extraFields);
    const reducedParams = {
        ...params,
        minPhotoCount: undefined,
        pagetype: undefined,
        remarketingAdID: undefined,
        extraFields: reducedExtraFields,
    } as const;
    const enhancedLocation = createSearchURL(reducedParams, state);

    // @ts-expect-error - TS2345 - Argument of type '{ pathname: string; search: SearchRouteQueryParams; }' is not assignable to parameter of type 'string'.
    const { url, alternateURLs } = buildAllCanonicalURLs(enhancedLocation, i18n.locale);

    if (canonicalOverride) {
        return { url: canonicalOverride, alternateURLs };
    }

    // Perform special URL encoding for the free text query. We do this here
    // manually because our router always deals with URL-decoded stuff,
    // so encoding it before creating the canonical would undo it.
    //
    // It's a hack, but it keeps Googlebot happy. It thinks '%28' and '('
    // are two different things :|
    if (reducedParams.freeTextQuery) {
        const encodedFreeTextQuery = encodeFreeTextQuery(reducedParams.freeTextQuery);
        const encodedURL = url.replace(reducedParams.freeTextQuery, encodedFreeTextQuery);
        return { url: encodedURL, alternateURLs };
    }

    return { url, alternateURLs };
};

export default useCanonicalURL;
