import React, { Component } from 'react';
import autoBind from 'react-autobind';
import isNil from 'lodash/isNil';
import isFastEqual from 'fast-deep-equal/es6/react';

import styles from './styles/countBasedExpandable.cssm';

type Props = {
    /**
     * The number of elements to display by default, regarding the state of the component.
     */
    readonly visibleCount: number;
    /**
     * Function used to render the trigger. Receives the current state of the component
     * (expanded or not), and a callback which can be used to toggle this state.
     */
    readonly renderTrigger: (expanded: boolean, toggleExpanded: () => void) => React.ReactNode;
    /**
     * The className of the children's container.
     */
    readonly className?: string;
    /**
     * The aria label of the children's container.
     */
    readonly ariaLabel?: string;
    /**
     * The children of the component.
     */
    readonly children: React.ReactNode;
    /**
     * The state of the component if it's controlled by another component
     */
    readonly expanded?: boolean;
    /**
     * Function to be called when the component is changing its state
     */
    readonly onExpandedChanged?: (expanded: boolean) => void;
    /**
     * The initial value for expanded state
     */
    readonly displayExpanded?: boolean;
};

type State = {
    /**
     * Whether the component is currently rendering all the children or not.
     */
    expanded: boolean;
};

/**
 * A component with two states:
 *
 *  not expanded:
 *      Renders a number of Props.visibleCount elements. If there are more elements available,
 *      renders a trigger, using a provided function, which can trigger the revealing of
 *      the other elements.
 *  expanded:
 *      Renders all available elements, alongside a trigger, using a provided function,
 *      which can trigger the hiding all the elements _except_ the first Props.visibleCount
 *      elements.
 */
class CountBasedExpandable extends Component<Props, State> {
    state: State = {
        expanded: false,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = { expanded: props.displayExpanded || false };
    }

    toggleExpanded() {
        this.setState((prevState) => ({
            expanded: !prevState.expanded,
        }));
    }

    isExpanded() {
        if (!isNil(this.props.expanded)) {
            return this.props.expanded;
        }

        return this.state.expanded;
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isFastEqual(nextProps.children, this.props.children)) {
            this.setState({ expanded: nextProps.displayExpanded || false });

            if (this.props.onExpandedChanged && this.props.expanded) {
                this.props.onExpandedChanged(false);
            }
        }
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        if (!children || children.length === 0) {
            return null;
        }

        const visibleElements = this.isExpanded()
            ? children
            : children.slice(0, this.props.visibleCount);
        const hiddenElements = this.isExpanded() ? [] : children.slice(this.props.visibleCount);

        return (
            <>
                <div className={this.props.className} aria-label={this.props.ariaLabel}>
                    {visibleElements}
                    <div className={styles.hidden}>{hiddenElements}</div>
                </div>
                {children.length > this.props.visibleCount &&
                    this.props.renderTrigger(this.isExpanded(), this.toggleExpanded)}
            </>
        );
    }
}

export default CountBasedExpandable;
