import React, { Component } from 'react';
import autoBind from 'react-autobind';

/**
 * Properties for {@see SwipeableArea}.
 */
type Props = {
    readonly enabled: boolean;
    readonly children: Node | React.ReactNode;
    readonly className?: string;
};

/**
 * {@see SwipeableArea} is a utility that detects
 * horizontal swipes and prevents accidential scrolling.
 *
 * This effect is common on mobile devices where the user
 * wants to swipe left/right but also makes a upward/downward
 * motion, causing the page to scroll.
 *
 * When a touch move is detected, an attempt is made to figure
 * out in what direction the move is by comparing it with the
 * coordinates at which the previous move occurred. If there
 * was more vertical movement than horizontal movement, then
 * vertical scrolling is disabled.
 */
class SwipeableArea extends Component<Props> {
    /**
     * Last position the user touched with his little finger.
     *
     * This is used to compare to on the next touchMove event,
     * so we can figure out in what direction the user is moving.
     */
    touchMove: null | {
        x: number;
        y: number;
    };

    /**
     * Default values for optional props.
     */
    static defaultProps = {
        enabled: true,
    };

    /**
     * Initializes a new instance of {@see SwipeableArea}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.touchMove = null;
    }

    /**
     * Swallows a scrolling (mobile) event and attempts
     * to detect whether you were swiping up/down or
     * left/right.
     *
     * If you're swiping up/down, then the event should
     * not be blocked and you should scroll. If you're
     * swiping left/right, then scrolling should be
     * blocked so the page doesn't scroll while you're
     * swiping.
     */
    swallowScroll(event: TouchEvent): void {
        const currentY = event.touches[0].clientY;
        const currentX = event.touches[0].clientX;

        if (!this.touchMove) {
            this.touchMove = { x: currentX, y: currentY };
            return;
        }

        const diffX = Math.abs(this.touchMove.x - currentX);
        // not sure what's happening here, flow complains that
        // `y` cannot be accessed because it might be null,
        // but there's a check a couple of lines up
        const diffY = Math.abs(this.touchMove.y - currentY);

        // user is moving more vertically than horizontally,
        // allow the movement
        if (diffY > diffX) {
            return;
        }

        // user is move more horizontally, block the move
        this.touchMove = { x: currentX, y: currentY };
        event.preventDefault();
    }

    /**
     * User is touching, start scroll detection.
     */
    onTouchStart(): void {
        if (!this.props.enabled) {
            return;
        }

        window.addEventListener('touchmove', this.swallowScroll, { passive: false });
    }

    /**
     * User stopped touching, stop scroll detection.
     */
    onTouchEnd(): void {
        if (!this.props.enabled) {
            return;
        }

        // @ts-expect-error - TS2769 - No overload matches this call.
        window.removeEventListener('touchmove', this.swallowScroll, { passive: false });

        this.touchMove = null;
    }

    /**
     * Renders the children in a container which captures
     * touch events to detect the user swiping.
     */
    render() {
        return (
            <div
                onTouchStart={this.onTouchStart}
                onTouchEnd={this.onTouchEnd}
                className={this.props.className ? this.props.className : ''}
            >
                {this.props.children}
            </div>
        );
    }
}

export default SwipeableArea;
