export enum LoginType {
    PHONE_CODE = 'phone_code',
    PHONE_PASSWORD = 'phone_password',
    EMAIL_IMPLICIT = 'email_implicit',
    EMAIL_CODE = 'email_code',
    EMAIL_LINK = 'email_link',
    EMAIL_PASSWORD = 'email_password',
    EMAIL_PASSWORD_CODE = 'email_password_code',
    GOOGLE_REDIRECT = 'google_redirect',
    GOOGLE_POPUP = 'google_popup',
    GOOGLE_ONETAP = 'google_onetap',
    FACEBOOK_REDIRECT = 'facebook_redirect',
    FACEBOOK_POPUP = 'facebook_popup',
    IMPERSONATION_REDIRECT = 'impersonation_redirect',
    UNKNOWN_REDIRECT = 'unknown_redirect',
    UNKNOWN_POPUP = 'unknown_popup',
}

export enum UserIdentifier {
    EMAIL = 'email',
    PHONE_NUMBER = 'phone',
}

export enum AuthFlow {
    LOGIN = 'login',
    REGISTER = 'register',
}

export interface EnsuredUserData {
    externalID: string;
    email: string;
    isNewlyRegistered: boolean;
}

export interface EnsureUserWithEmailOptions {
    silentlyFail?: boolean;
    shouldLogin?: boolean;
}
