import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import { withI18n } from 'strat/i18n/language/withI18n';
import { Flex, Text } from 'strat/components';

import EmptyChatWEBP from '@app/assets/images/emptyChat.webp';
import EmptyChatPNG from '@app/assets/images/emptyChat.png';

import styles from './styles/noSelectedConversation.cssm';

type Props = {
    i18n: I18n;
    text?: string;
};

const NoSelectedConversation = ({
    i18n,
    text = t(i18n)`Select a chat to view conversation`,
}: Props) => (
    <Flex column justifyCenter alignCenter className={styles.container}>
        <picture>
            <source srcSet={EmptyChatWEBP} type="image/webp" />
            <img className={styles.chatImage} src={EmptyChatPNG} alt="Chat" />
        </picture>
        <Text.Large>{text}</Text.Large>
    </Flex>
);

export default withI18n()(NoSelectedConversation);
