import * as React from 'react';
import Category from '@app/branding/category';

import InputInfoTooltip from 'horizontal/fields/inputInfoTooltip';
import { AdCommonExtraField } from 'horizontal/branding/adExtraFields';
import type { FlatCategoryField, Category as CategoryType } from 'horizontal/types';

const getInputFieldTitle = (
    field: FlatCategoryField,
    category: CategoryType | null | undefined,
) => {
    let title = field.name || field.attribute;
    title = field.isMandatory ? title.concat('*') : title;

    if (Category.isOfCarsType(category) && field.attribute === AdCommonExtraField.VEHICLE_MILEAGE) {
        return <InputInfoTooltip title={title} />;
    }
    return title;
};

export default getInputFieldTitle;
