import React from 'react';

import type { PaymentOrder } from 'horizontal/payment';
import PaymentPaymobMWallet from 'horizontal/payment/paymobWallet';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly order: PaymentOrder;
    readonly paymentSource: string;
};

const PaymentPaymobMWalletPage = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ order, paymentSource }: Props) => (
        <PaymentPaymobMWallet order={order} paymentSource={paymentSource} />
    ),
} as const;

export default PaymentPaymobMWalletPage;
