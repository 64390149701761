import * as React from 'react';
import { useWindowSize } from 'strat/modal';
import settings from '@app/branding/settings';

import { getNegotiableLabel, useGetDownPaymentLabel } from 'horizontal/ad/price';
import { LiteAd } from 'horizontal/types';

import useRentalPeriod from './useRentalPeriod';
import styles from './styles/adAdditionalPriceInfoUpdatedDesign.cssm';

const TINY_SCREEN_WIDTH = 375;

type Props = {
    ad: LiteAd;
};

const AdAdditionalPriceInfo = ({ ad }: Props) => {
    const { width } = useWindowSize();

    const negotiableLabel = getNegotiableLabel(ad);
    const rentalPeriod = useRentalPeriod(ad);

    const downPayment = useGetDownPaymentLabel(ad, false, true);
    const downPaymentCompact = useGetDownPaymentLabel(ad, true, true);

    let downPaymentLabel = null;
    if (width && width < TINY_SCREEN_WIDTH) {
        downPaymentLabel = <>{downPaymentCompact}</>;
    } else if (width) {
        downPaymentLabel = <>{downPayment}</>;
    }

    const label =
        (!settings.hideRentalPeriodInAdCard && rentalPeriod) ||
        (downPayment ? downPaymentLabel : negotiableLabel);

    if (!label) {
        return null;
    }

    return (
        <span className={label === downPaymentLabel ? styles.downPaymentLabel : styles.label}>
            {label}
        </span>
    );
};

export default AdAdditionalPriceInfo;
