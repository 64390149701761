import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { trigger, Triggers } from 'strat/gtm';
import { Text, ListEntry } from 'strat/components';

import iconHeart from 'horizontal/assets/icons/iconHeart_noinline.svg';

const renderLeft = () => <img alt={'Favourite'} src={iconHeart} />;

const FavouritesAndSavedSearchesLink = ({
    onClick,
}: {
    onClick: (arg1: React.MouseEvent<any>) => void;
}) => {
    const i18n = useI18n();
    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trigger(Triggers.VIEW_FAVOURITES_SAVED_SEARCHES);
            onClick(event);
        },
        [onClick],
    );

    return (
        <Link
            route={RouteNames.FAVORITES}
            title={t(i18n)`Favourites & Saved searches`}
            onClick={onTrackedClick}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>{t(i18n)`Favourites & Saved searches`}</Text.Large>
            </ListEntry>
        </Link>
    );
};

export default FavouritesAndSavedSearchesLink;
