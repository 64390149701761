import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Button, Flex, Text } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';
import Layout from 'strat/layout';

import styles from './styles/sectionRedesign.cssm';

type Props = {
    noPackages?: boolean;
};

const ContactSalesTeamSection = ({ noPackages = false }: Props) => {
    const i18n = useI18n();
    const content = [
        noPackages
            ? t(i18n)`There are no packages for this category.`
            : t(i18n)`Can't find the right package above?`,
        t(i18n)`Contact us for more customized packages.`,
    ];
    const layout = useSelector(selectLayout);
    const isMobile = layout === Layout.MOBILE;

    const constructLayout = (content: string[]) => {
        if (!isMobile) {
            return content.join(' ');
        }

        return content.map((sentence) => (
            <span key={sentence} className={styles.contentItem}>
                {sentence}
            </span>
        ));
    };

    return (
        <Flex column className={styles.contactSalesTeamContainer}>
            <Text.Large bold>
                <Trans>Customized Packages</Trans>
            </Text.Large>
            <Flex className={styles.cutomizedPackagesText}>{constructLayout(content)}</Flex>
            <Flex className={styles.buttonContainer}>
                <Button secondary>
                    <a href={t(i18n)`businesses_url`} target="_blank noopener noreferrer">
                        <Trans>Contact sales team</Trans>
                    </a>
                </Button>
            </Flex>
        </Flex>
    );
};

export default ContactSalesTeamSection;
