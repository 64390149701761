import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { Flex, Input, Select } from 'strat/components';

import { CustomerType } from '../types';

import styles from './styles/fields.cssm';

const customerTypes = (i18n: I18n) => [
    { id: 1, label: t(i18n)`Business`, value: CustomerType.BUSINESS },
    { id: 2, label: t(i18n)`Residential`, value: CustomerType.RESIDENTIAL },
];

type Props = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly businessValidationSchema: boolean;
    readonly setBusinessValidationSchema: (businessValidationSchema: boolean) => void;
};

const CustomerInfoFields = ({
    setFieldValue,
    touched,
    errors,
    values,
    onBlur,
    businessValidationSchema,
    setBusinessValidationSchema,
}: Props) => {
    const i18n = useI18n();

    const customerNameTitle = businessValidationSchema
        ? t(i18n)`* Customer Name`
        : t(i18n)`Customer Name`;
    const businessNameTitle = businessValidationSchema
        ? t(i18n)`* Business Name`
        : t(i18n)`Business Name`;
    const taxIDTitle =
        businessValidationSchema && settings.vatNumberRequired
            ? t(i18n)`* Tax Id`
            : t(i18n)`Tax Id`;
    const commercialNumberTitle =
        businessValidationSchema && settings.commercialNumberRequired
            ? t(i18n)`* Commercial Registration Number`
            : t(i18n)`Commercial Registration Number`;
    const onPhoneNumberChanged = React.useCallback(
        (value: string) => {
            let parsedText = value.replace(/[^0-9+() -]/g, '');
            if (parsedText.endsWith('+') && parsedText.length > 1) {
                parsedText = parsedText.slice(0, -1);
            }
            setFieldValue('phoneNumber', parsedText);
        },
        [setFieldValue],
    );

    return (
        <>
            <Flex className={styles.inputSection}>
                <div className={styles.inputContainer}>
                    <Select
                        name={'customerType'}
                        title={t(i18n)`Customer Type`}
                        placeholder={t(i18n)`Customer Type`}
                        choices={customerTypes(i18n)}
                        value={values.customerType}
                        // @ts-expect-error - TS2322 - Type '{ name: string; title: string; placeholder: string; choices: { id: number; label: string; value: string; }[]; value: any; onClick: () => void; onChange: (value: any) => void; onBlur: (arg1: SyntheticEvent<any, Event>) => void; errorMessage: any; accepted: any; }' is not assignable to type 'IntrinsicAttributes & Props<any>'.
                        onClick={() =>
                            trigger(Triggers.CLICK_FILTER, {
                                view_section: ViewSections.BILLING_FORM,
                            })
                        }
                        onChange={(value) => {
                            if (value === CustomerType.BUSINESS) {
                                setBusinessValidationSchema(true);
                            } else {
                                // non-business clients don't need to have a vatNumber
                                setFieldValue('vatNumber', '');
                                setFieldValue('commercialNumber', '');
                                setBusinessValidationSchema(false);
                            }
                            setFieldValue('customerType', value);
                        }}
                        onBlur={onBlur}
                        errorMessage={touched.customerType && errors.customerType}
                        accepted={touched.customerType && !errors.customerType}
                    />
                </div>
            </Flex>
            <Flex className={styles.inputSection}>
                <div className={styles.inputContainer}>
                    <Input
                        name={'email'}
                        title={t(i18n)`Email`}
                        value={values.email}
                        onChange={(value) => setFieldValue('email', value)}
                        onBlur={onBlur}
                        errorMessage={touched.email && errors.email}
                        accepted={touched.email && !errors.email}
                    />
                </div>
                <div className={styles.inputContainer}>
                    <Input
                        name={'customerName'}
                        title={customerNameTitle}
                        value={values.customerName}
                        onChange={(value) => setFieldValue('customerName', value)}
                        onBlur={onBlur}
                        errorMessage={touched.customerName && errors.customerName}
                        accepted={touched.customerName && !errors.customerName}
                    />
                </div>
            </Flex>
            <Flex className={styles.inputSection}>
                <div className={styles.inputContainer}>
                    <Input
                        name={'businessName'}
                        title={businessNameTitle}
                        value={values.businessName}
                        onChange={(value) => setFieldValue('businessName', value)}
                        onBlur={onBlur}
                        errorMessage={touched.businessName && errors.businessName}
                        accepted={touched.businessName && !errors.businessName}
                    />
                </div>
                {values.customerType === CustomerType.BUSINESS && (
                    <>
                        <div className={styles.inputContainer}>
                            <Input
                                name={'vatNumber'}
                                title={taxIDTitle}
                                value={values.vatNumber}
                                onChange={(value) => setFieldValue('vatNumber', value)}
                                onBlur={onBlur}
                                errorMessage={touched.vatNumber && errors.vatNumber}
                                accepted={touched.vatNumber && !errors.vatNumber}
                            />
                        </div>
                        {!settings.disableCommercialNumber && (
                            <div className={styles.inputContainer}>
                                <Input
                                    name={'commercialNumber'}
                                    title={commercialNumberTitle}
                                    value={values.commercialNumber}
                                    onChange={(value) => setFieldValue('commercialNumber', value)}
                                    onBlur={onBlur}
                                    errorMessage={
                                        touched.commercialNumber && errors.commercialNumber
                                    }
                                    accepted={touched.commercialNumber && !errors.commercialNumber}
                                />
                            </div>
                        )}
                    </>
                )}
                <div className={styles.inputContainer}>
                    <Input
                        name={'phoneNumber'}
                        title={t(i18n)`Phone Number`}
                        value={values.phoneNumber}
                        type={'tel'}
                        onChange={onPhoneNumberChanged}
                        onBlur={onBlur}
                        errorMessage={touched.phoneNumber && errors.phoneNumber}
                        accepted={touched.phoneNumber && !errors.phoneNumber}
                        noArrows
                    />
                </div>
            </Flex>
        </>
    );
};

export default CustomerInfoFields;
