import { useI18n } from '../language';

import humanizePrice from './humanizePrice';
import useLocalPrice from './useLocalPrice';

/**
 * Properties for {@see LocalPrice}.
 */
type Props = {
    price: number | null | undefined;
    roundFunction?: ((arg1: number) => number) | null;
    showPriceInMillions?: boolean;
    fixRoundTripping?: boolean;
    options?: Record<any, any>;
};

/**
 * Displays the specified price humanized in the local currency.
 */
const LocalPrice = ({ roundFunction = null, showPriceInMillions = false, ...props }: Props) => {
    const i18n = useI18n();
    const { currency, toLocalPrice, formatLocalPriceValue } = useLocalPrice();
    const convertedPrice = toLocalPrice(props.price, undefined, undefined, props.fixRoundTripping);

    if (CONFIG.build.WITH_PRICE_HUMANIZING) {
        return humanizePrice(
            convertedPrice,
            i18n,
            currency,
            formatLocalPriceValue,
            roundFunction,
            null,
            showPriceInMillions,
        );
    }

    return formatLocalPriceValue(convertedPrice, props.options);
};

export default LocalPrice;
