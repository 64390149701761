import { t } from '@lingui/macro';
import * as React from 'react';
import { Text, Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import IconFilters from 'horizontal/assets/icons/iconProfileFilters_noinline.svg';

import styles from './styles/filtersButton.cssm';

type Props = {
    readonly onClick: () => void;
};

const FiltersButton = ({ onClick }: Props) => {
    const i18n = useI18n();

    return (
        <Flex justifyCenter alignCenter className={styles.container} onClick={onClick}>
            <img className={styles.icon} src={IconFilters} alt={'Filters Icon'} />
            <Text.Small bold>{t(i18n)`Filters`}</Text.Small>
        </Flex>
    );
};

export default FiltersButton;
