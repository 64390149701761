import { createSelector } from 'reselect';

import Page from 'strat/pages/page';
import { GlobalState } from 'strat/state';

const selectPage = (state: GlobalState) => state.rendering?.page;

const selectIsSearchPage = createSelector(selectPage, (page) => page === Page.SEARCH);

const selectIsHomePage = createSelector(selectPage, (page) => page === Page.HOME);

const selectPageIsLoading = (state: GlobalState) => state.rendering?.loading;

export { selectIsSearchPage, selectPageIsLoading, selectIsHomePage };
