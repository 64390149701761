import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectProperty } from 'strat/property/selectors';
import { fetchPriceTrends } from 'strat/property/state';

import {
    selectPriceTrendsData,
    selectPriceTrendsLoading,
    selectPriceTrendsError,
    selectHasMonthlyAveragePrice,
} from './selectors';

const useFetchAveragePrice = (externalID: string) => {
    const dispatch = useDispatch();
    const property = useSelector(selectProperty);
    const data = useSelector(selectPriceTrendsData);
    const error = useSelector(selectPriceTrendsError);
    const hasAveragePrice = useSelector(selectHasMonthlyAveragePrice);
    const loading = useSelector(selectPriceTrendsLoading);
    const { prev_avg_psf_aed: averagePriceAED } = data || {};

    React.useEffect(() => {
        if (property?.externalID === externalID) {
            // @ts-expect-error - TS2345 - Argument of type '() => Promise<{ data: any; status: number; }>' is not assignable to parameter of type 'Action'.
            dispatch(fetchPriceTrends());
        }
    }, [externalID, dispatch, property]);

    return [averagePriceAED, loading || (!data && !error), hasAveragePrice];
};

export default useFetchAveragePrice;
