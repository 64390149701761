import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';
import { ThirdPartyProvider } from 'strat/auth/keycloak/types';

import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';

export type LinkIdentityProviderRouteParams = {
    readonly provider?: ThirdPartyProvider;
};

class LinkIdentityProviderRoute extends Route {
    constructor() {
        super(RouteNames.LINK_IDENTITY_PROVIDER, [
            [
                '^/linkIdentityProvider/',
                { name: 'provider', pattern: `(${Object.values(ThirdPartyProvider).join('|')})` },
            ],
        ]);
    }

    createURL({ provider }: LinkIdentityProviderRouteParams): EnhancedLocation {
        if (provider) {
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'EnhancedLocation'.
            return `/linkIdentityProvider/${provider}`;
        }
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'EnhancedLocation'.
        return '/linkIdentityProvider';
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        const {
            match: {
                params: { provider, externalRedirectPath },
            },
        } = context;

        if (!provider || !externalRedirectPath) {
            context.rendering.renderPage(Page.NOT_FOUND);
            return;
        }
        context.rendering.renderPage(Page.LINK_IDENTITY_PROVIDER, {
            provider,
            externalRedirectPath,
        });
    }
}

export default new LinkIdentityProviderRoute();
