import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import Category from '@app/branding/category';

import { LiteAd } from 'horizontal/types';
import useFormatAdExtraFieldsToObject from 'horizontal/ad/useFormatAdExtraFieldsToObject';
import { AdCommonExtraField } from 'horizontal/branding/adExtraFields';
import useGetCarMileage from 'horizontal/ad/useGetCarMileage';
import { areaUnitAbbreviations } from 'horizontal/ad/constants';

import { CarAdFeatures, HeroCategoryAdFeatures, PropertyAdFeatures } from './types';

type Props = {
    ad: LiteAd;
};

const useCarAdFeatures = ({ ad }: Props): CarAdFeatures => {
    const extraFields = useFormatAdExtraFieldsToObject(ad);

    const year = extraFields[AdCommonExtraField.YEAR];
    const fuelType = extraFields[AdCommonExtraField.VEHICLE_FUEL_TYPE];
    const mileage = useGetCarMileage(
        ad.extraFields?.[AdCommonExtraField.USAGE_CONDITION],
        extraFields[AdCommonExtraField.VEHICLE_MILEAGE],
    );

    return {
        year,
        fuelType,
        mileage,
    };
};

const usePropertyAdFeatures = ({ ad }: Props): PropertyAdFeatures => {
    const i18n = useI18n();

    const extraFields = useFormatAdExtraFieldsToObject(ad);

    const beds = extraFields[AdCommonExtraField.BEDROOMS];
    const bathrooms = extraFields[AdCommonExtraField.BATHROOMS];

    const areaUnitField = extraFields[AdCommonExtraField.AREA_UNIT] || settings.defaultAreaUnit;
    const areaUnit =
        areaUnitField in areaUnitAbbreviations
            ? areaUnitAbbreviations[areaUnitField]
            : areaUnitField;
    const area = extraFields[AdCommonExtraField.AREA]
        ? t(i18n)`${extraFields[AdCommonExtraField.AREA]} ${areaUnit}`
        : null;

    return {
        beds,
        bathrooms,
        area,
    };
};

const useAdFeaturesForHeroCategory = ({ ad }: Props): HeroCategoryAdFeatures | null => {
    const adCategory = ad.category[ad.category.length - 1];
    const isCarsAd = Category.isOfCarsType(adCategory);
    const isPropertiesAd = Category.isOfPropertySubtype(adCategory);
    const carFeatures = useCarAdFeatures({ ad });
    const propertyFeatures = usePropertyAdFeatures({ ad });

    if (isCarsAd) {
        return {
            carFeatures: carFeatures,
            propertyFeatures: null,
        };
    } else if (isPropertiesAd) {
        return {
            carFeatures: null,
            propertyFeatures: propertyFeatures,
        };
    }

    return null;
};

export default useAdFeaturesForHeroCategory;
