// @ts-expect-error - TS7016 - Could not find a declaration file for module 'msgpack-lite'. 'node_modules/msgpack-lite/index.js' implicitly has an 'any' type.
import msgpack from 'msgpack-lite';
import { createSelector, defaultMemoize, createSelectorCreator } from 'reselect';
import isEqual from 'lodash/isEqual';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import { GlobalState } from 'strat/state';
import CountryBounds, { FocusedBounds } from '@app/branding/countryBounds';
import { FilterValues } from 'strat/search';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { selectEnabledListingType, selectIsCommuteActive } from 'strat/search/selectors';
import { PropertyType, type PropertyData, PropertyCompletionStatus } from 'strat/property/types';
import { MapStyleTypes } from 'strat/map';
import { MapViewName } from 'strat/map/mapStyles';

import * as PackedListing from './packedListing';
import { PackedPropertyType } from './packedListing';
import { convertPackedListingsToFeatureCollection } from './convertPackedListingsToFeatureCollection';
import { computeCommuteTimes } from './commute/computeCommuteTimes';
import type { PackedListingType } from './packedListing';
import type {
    MapBasedSearchListing,
    Isochrones,
    // @ts-expect-error - TS2305 - Module '"./types"' has no exported member 'CommuteTimes'.
    CommuteTimes,
    AttributeFetchedFromAlgolia,
    MapBasedSearchProperty,
} from './types';
import type { CommuteLocations } from './commute';
import listingTransformerByType from './listingTransformerByType';
import { unpackProject } from './project/packedProject';
import { PackedProjectType } from './project/types';
import { filterProjectListings } from './project/filterProjectListings';
import {
    isContained,
    isInBounds,
    isInRange,
    isInSelectedLocation,
    isRangeFilterMatched,
    isStudio,
} from './filterConditions';

export const unpackProperty = (packedListing: PackedPropertyType): MapBasedSearchProperty => ({
    type: PropertyType.PROPERTY,
    id: PackedListing.id(packedListing),
    price: PackedListing.price(packedListing),
    rooms: PackedListing.bedroomCount(packedListing),
    baths: PackedListing.bathroomCount(packedListing),
    area: PackedListing.area(packedListing),
    // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'string'.
    rentFrequency: PackedListing.rentFrequency(packedListing),
    locationHierarchy: PackedListing.locationHierarchy(packedListing),
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    categoryExternalID: PackedListing.categoryExternalID(packedListing),
    score: PackedListing.score(packedListing),
    scoreL1: PackedListing.scoreL1(packedListing),
    productScore: PackedListing.productScore(packedListing),
    productLabelScore: PackedListing.productLabelScore(packedListing),
    verifiedScore: PackedListing.verifiedScore(packedListing),
    createdAt: PackedListing.createdAt(packedListing),
    completionStatus: PackedListing.completionStatus(packedListing),
    residenceType: PackedListing.residenceType(packedListing),
    occupancyStatus: PackedListing.occupancyStatus(packedListing),
    furnishingStatus: PackedListing.furnishingStatus(packedListing),
    geography: {
        lat: PackedListing.latitude(packedListing),
        lng: PackedListing.longitude(packedListing),
    },
});

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const filterPropertyListings = (
    packedListings: Array<PackedPropertyType>,
    filters: FilterCollection,
): Array<PackedPropertyType> => {
    if (!packedListings) {
        // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'PackedPropertyType[]'.
        return EMPTY_ARRAY;
    }

    const areaFilter = filters.getFilterValue(FilterValues.area.attribute, {
        min: null,
        max: null,
    });
    const priceFilter = filters.getFilterValue(FilterValues.price.attribute, {
        min: null,
        max: null,
    });
    const bedFilter = filters.getFilterValue(FilterValues.beds.attribute);
    const bathsFilter = filters.getFilterValue(FilterValues.baths.attribute);
    const locationFilter = filters.getFilterValue(FilterValues.location.attribute);
    const rentFrequencyFilter = filters.getFilterValue(FilterValues.rentFrequency.attribute);
    const completionStatusFilter = filters.getFilter(FilterValues.completionStatus.attribute);
    const residenceTypeFilter = filters.getFilter(FilterValues.residenceType.attribute);
    const occupancyStatusFilter = filters.getFilter(FilterValues.occupancyStatus.attribute);
    const furnishingStatusFilter = filters.getFilter(FilterValues.furnishingStatus.attribute);
    const completionDateFilter = filters.getFilter(FilterValues.handoverDate.attribute);
    const completionPercentageFilter = filters.getFilter(
        FilterValues.completionPercentage.attribute,
    );
    const preHandoverPaymentFilter = filters.getFilter(FilterValues.preHandoverPayment.attribute);

    return packedListings.filter((entry) => {
        if (
            !isInBounds(
                CountryBounds,
                PackedListing.longitude(entry),
                PackedListing.latitude(entry),
            )
        ) {
            return false;
        }

        if (!isInRange(priceFilter, PackedListing.price(entry))) {
            return false;
        }

        if (!isInRange(areaFilter, PackedListing.area(entry))) {
            return false;
        }

        const bedroomCount = PackedListing.bedroomCount(entry);
        if (
            !isContained(bedFilter, bedroomCount) ||
            (bedFilter && bedroomCount === 0 && !isStudio(PackedListing.categoryExternalID(entry)))
        ) {
            return false;
        }

        if (!isContained(bathsFilter, PackedListing.bathroomCount(entry))) {
            return false;
        }

        const rentFrequency = PackedListing.rentFrequency(entry);
        if (
            rentFrequency &&
            rentFrequencyFilter !== rentFrequency &&
            rentFrequencyFilter !== FilterValues.rentFrequency.disablingValue
        ) {
            return false;
        }

        if (!isInSelectedLocation(locationFilter, PackedListing.locationHierarchy(entry))) {
            return false;
        }

        if (
            completionStatusFilter &&
            completionStatusFilter.active &&
            completionStatusFilter.value !== FilterValues.completionStatus.disablingValue &&
            PackedListing.completionStatus(entry) !== completionStatusFilter.value
        ) {
            return false;
        }

        if (
            residenceTypeFilter &&
            residenceTypeFilter.active &&
            residenceTypeFilter.value !== null &&
            PackedListing.residenceType(entry) !== residenceTypeFilter.value
        ) {
            return false;
        }

        if (
            occupancyStatusFilter &&
            occupancyStatusFilter.active &&
            occupancyStatusFilter.value !== null &&
            PackedListing.occupancyStatus(entry) !== occupancyStatusFilter.value
        ) {
            return false;
        }

        if (
            furnishingStatusFilter &&
            furnishingStatusFilter.active &&
            furnishingStatusFilter.value !== null &&
            PackedListing.furnishingStatus(entry) !== furnishingStatusFilter.value
        ) {
            return false;
        }

        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
        if ((priceFilter.min || priceFilter.max) && PackedListing.hidePrice(entry) === true) {
            return false;
        }

        if (
            CONFIG.runtime.STRAT_ENABLE_PROJECT_MAP_SEARCH &&
            completionStatusFilter?.value === PropertyCompletionStatus.OFF_PLAN
        ) {
            if (!isRangeFilterMatched(completionDateFilter, PackedListing.completionDate(entry))) {
                return false;
            }

            if (
                !isRangeFilterMatched(
                    completionPercentageFilter,
                    PackedListing.completionPercentage(entry),
                )
            ) {
                return false;
            }

            if (
                !isRangeFilterMatched(
                    preHandoverPaymentFilter,
                    PackedListing.preHandoverPayment(entry),
                )
            ) {
                return false;
            }
        }

        return true;
    });
};

export const filterListings = (
    listingType: Values<typeof PropertyType>,
    packedListings: Array<PackedListingType>,
    filters: FilterCollection,
): Array<PackedListingType> => {
    switch (listingType) {
        case PropertyType.PROJECT:
            return filterProjectListings(packedListings as PackedProjectType[], filters);
        case PropertyType.PROPERTY:
        default:
            return filterPropertyListings(packedListings as PackedPropertyType[], filters);
    }
};

export const unpackListing = (
    listingType: Values<typeof PropertyType>,
    packedListing: PackedListingType,
): MapBasedSearchListing => {
    switch (listingType) {
        case PropertyType.PROJECT:
            return unpackProject(packedListing as PackedProjectType);
        case PropertyType.PROPERTY:
        default:
            return unpackProperty(packedListing as PackedPropertyType);
    }
};

export const unpackListings = (
    listingType: Values<typeof PropertyType>,
    eligibleListings: Array<PackedListingType>,
): Array<MapBasedSearchListing> =>
    eligibleListings.map((packedListing: PackedListingType) =>
        unpackListing(listingType, packedListing),
    );

export const selectDecodedListingsForClustering = createSelector(
    (state: GlobalState) => state.map.data,
    (data) => {
        if (!data || data.byteLength === 0) {
            return EMPTY_ARRAY;
        }

        return msgpack.decode(new Uint8Array(data));
    },
);

export const filterReachableListings = (
    listings: Array<PackedListingType>,
    commuteTimes: CommuteTimes,
    isCommuteActive: boolean,
    maxTime: number,
): Array<PackedListingType> => {
    if (!isCommuteActive) {
        return listings;
    }
    if (!commuteTimes) {
        // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'PackedListingType[]'.
        return EMPTY_ARRAY;
    }

    const { A, B } = commuteTimes;

    return listings.filter((listing) => {
        const id = PackedListing.id(listing);

        if (A && !(PackedListing.id(listing) in A)) {
            return false;
        }
        if (B && !(PackedListing.id(listing) in B)) {
            return false;
        }
        if (A && B && A[id] + B[id] > maxTime) {
            return false;
        }
        if (A && A[id] > maxTime) {
            return false;
        }
        if (B && B[id] > maxTime) {
            return false;
        }

        return true;
    });
};

export const selectIsochrones = (state: GlobalState): Isochrones =>
    state.isochrone?.data || EMPTY_OBJECT;

export const selectIsochronesNoRouteFound = createSelector(
    selectIsochrones,
    (isochrones) => !!Object.values(isochrones).find((x) => x.error?.status_code === 400),
);

export const selectEligibleListingsForClustering = createSelector(
    selectDecodedListingsForClustering,
    getFilterCollection,
    selectIsCommuteActive,
    selectIsochrones,
    (state: GlobalState) => state.map.commuteMaxTime,
    selectEnabledListingType,
    (packedListings, filterCollection, isCommuteActive, isochrones, maxTime, listingType) => {
        const filters = filterCollection.copy();
        filters.remove(FilterValues.location.attribute);
        const commuteTimes = computeCommuteTimes(isochrones, packedListings);
        const reachableListings = filterReachableListings(
            packedListings,
            commuteTimes,
            isCommuteActive,
            maxTime ?? 0,
        );
        return filterListings(listingType, reachableListings, filters);
    },
);

export const selectClustersFeatureCollection = createDeepEqualSelector(
    selectEnabledListingType,
    selectEligibleListingsForClustering,
    (listingType, eligibleListings) =>
        convertPackedListingsToFeatureCollection(listingType, eligibleListings),
);

export const selectPinnedListing = (state: GlobalState): PropertyData | null =>
    state.map.pinnedListing;

export const selectPinnedListingId = (state: GlobalState): number | null =>
    state.map.pinnedListingId;

export const selectActiveCluster = createDeepEqualSelector(
    (state: GlobalState) => state.map.activeCluster,
    (activeCluster) => {
        if (!activeCluster) {
            return EMPTY_ARRAY;
        }
        return activeCluster;
    },
);

export const selectActiveClusterLoading = (state: GlobalState): boolean =>
    state.map.activeClusterLoading;

export const selectActiveListings = createSelector(
    selectActiveCluster,
    (state: GlobalState) => state.map.page,
    (activeCollection, page) => {
        return activeCollection.slice(page * 24, (page + 1) * 24);
    },
);

/**
 * Selects the offset of a listing based on the page number and the number of listings per page.
 */
export const selectListingOffset = createSelector(
    (state: GlobalState) => state.map.page,
    (page) => page * 24,
);

export const selectMapPosition = createSelector(
    (state: GlobalState) => state.map.mapPosition,
    (currentMapPosition) => {
        const defaultBounds = FocusedBounds;
        const defaultCenter = currentMapPosition && {
            lng: currentMapPosition.lng,
            lat: currentMapPosition.lat,
        };
        return currentMapPosition ? { defaultCenter } : { defaultBounds };
    },
);

export const selectMapView = createSelector(
    (state: GlobalState) => state.map.mapView,
    (mapView?: Values<typeof MapViewName> | null) => mapView ?? MapViewName.DEFAULT,
);

export const selectMapStyle = createSelector(selectMapView, (mapView) =>
    mapView === MapViewName.SATELLITE ? MapStyleTypes.SATELLITE : MapStyleTypes.STRAT,
);

export const selectAreExtraFiltersSet = createSelector(
    (state: GlobalState) => getFilterCollection(state),
    (filters) =>
        filters
            .copy([
                FilterValues.purpose.attribute,
                FilterValues.location.attribute,
                FilterValues.category.attribute,
            ])
            .filter(
                (filter) =>
                    filter.active &&
                    !!filter.key &&
                    filter.key !== FilterValues.page.attribute &&
                    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ page: { attribute: string; default: null; }; totalProductCount: string; studioFilter: any; floorPlanID: { attribute: string; default: null; displayName: (i18n: any) => any; compactDisplayName: (i18n: any) => any; }; ... 38 more ...; contractRenewalStatus: { ...; }; }'.
                    filter.value !== (FilterValues[filter.key] || {}).disablingValue &&
                    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ page: { attribute: string; default: null; }; totalProductCount: string; studioFilter: any; floorPlanID: { attribute: string; default: null; displayName: (i18n: any) => any; compactDisplayName: (i18n: any) => any; }; ... 38 more ...; contractRenewalStatus: { ...; }; }'.
                    !isEqual(filter.value, (FilterValues[filter.key] || {}).default),
            ).length > 0,
);

export const selectListingsInViewport = (
    map: MapType,
    packedListings: Array<PackedListingType>,
): Array<PackedListingType> => {
    const mapBounds = map.getBounds();
    const bounds = [mapBounds.getSouthWest(), mapBounds.getNorthEast()];
    return packedListings.filter((packedListing) =>
        isInBounds(
            bounds,
            PackedListing.longitude(packedListing),
            PackedListing.latitude(packedListing),
        ),
    );
};

export const selectMapLoading = createSelector(
    (state: GlobalState) => state.map.loading,
    (state: GlobalState) => state.map.loaded,
    (state: GlobalState) => state.map.activeClusterLoading,
    (mapLoading, mapLoaded, activeClusterLoading) =>
        activeClusterLoading || mapLoading || !mapLoaded,
);

export const selectIsClusterInViewport = (
    map: MapType,
    activeClusterPosition?: {
        lng: number;
        lat: number;
    } | null,
): boolean => {
    const mapBounds = map.getBounds();
    const bounds = [mapBounds.getSouthWest(), mapBounds.getNorthEast()];
    return (
        !!activeClusterPosition &&
        isInBounds(bounds, activeClusterPosition.lng, activeClusterPosition.lat)
    );
};

export const selectCommuteLocations = (state: GlobalState): CommuteLocations | null =>
    getFilterCollection(state).getFilterValue('latLong');

export const selectAlgoliaHits = createSelector(
    (state: GlobalState) => state.map.activeCluster ?? EMPTY_ARRAY,
    (state: GlobalState) => state.map.page,
    selectLanguage,
    (state: GlobalState) => state.map.algoliaHits,
    selectEnabledListingType,
    (listings, page, language, algoliaHits, listingType) =>
        listings
            .slice(page * 24, (page + 1) * 24)
            .map((listing) => algoliaHits[listing.id])
            .filter(Boolean)
            .map(
                (listing) =>
                    listingTransformerByType(listingType, listing, language) as PropertyData,
            ),
);

export const selectAttributesFetchedFromAlgolia =
    (listing: MapBasedSearchListing) =>
    (state: GlobalState): AttributeFetchedFromAlgolia =>
        state.map.algoliaHits[listing.id];

export const selectCollectionType = (state: GlobalState) => state.map.collectionType || null;
