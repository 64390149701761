import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';

import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ScheduleScope } from 'strat/scheduler';
import {
    selectCompletionStatus,
    selectSearchView,
    selectIsTransactionsPage,
    selectProject,
    selectIsProjectSearchEnabled,
    selectPurpose,
} from 'strat/search/selectors';
import { GlobalState } from 'strat/state';
import { PageTypes } from 'strat/gtm';
import { BayutCompat } from 'strat/util';
import { ProjectSearchType } from 'strat/search/types';
import Purpose from 'strat/purpose';

import type { GTMProps } from './withGTM';
import withGTM from './withGTM';
import Triggers from './triggers';
import ViewSections from './viewSections';
import ViewElements from './viewElements';
import type { GTMLegacyStatsProps } from './withGTMLegacyStats';
import getViewSectionVariables from './getViewSectionVariables';

/**
 * Properties for {@see GTMSearchTracking}.
 */
type Props = GTMProps &
    GTMLegacyStatsProps & {
        searchView: string;
        completionStatus: string;
        isDubaiTransactionsPage: boolean;
        isProjectPage?: boolean;
        isProjectView?: boolean;
        purpose: Values<typeof Purpose>;
    };

/**
 * Properties passed to the wrapped component.
 */
export type GTMSearchTrackingProps = {
    trackSearchFilterChange: (
        filterName: string,
        newValue?: unknown,
        otherVariables?: {
            view_section?: Values<typeof ViewSections>;
            listing_pagetype?: Values<typeof PageTypes>;
        },
    ) => void;
    trackSetupAlert: () => void;
    trackSaveSearch: () => void;
    trackSearchHistory: () => void;
    trackListViewToggle: (layout: Values<typeof SearchHitsLayout>) => void;
    trackSortingChange: (viewSection: Values<typeof ViewSections>, sortValue?: any) => void;
    trackCompletionStatusChange: (value?: any) => void;
    trackSearchProjectChangeView: (value: string) => void;
    trackProjectClick: () => void;
    trackProjectPageView: (searchType: Values<typeof ProjectSearchType>) => void;
    trackProjectPageViewDetails: (value: string) => void;
};

/**
 * Names for search filters fields to be sent on change
 */
export const GTMSearchTrackingFilters = Object.freeze({
    PURPOSE: 'purpose_filter',
    LOCATION: 'loc_name',
    PAYMENT_TYPE: 'payment_type_filter',
    PROPERTY_TYPE: 'property-type_filter',
    PRICE: 'price_filter',
    PRICE_MAX: 'price_max_filter',
    BEDS: 'beds_filter',
    AREA: 'area_filter',
    AREA_MAX: 'area_max_filter',
    BATHS: 'baths_filter',
    VERIFIED_ADS: 'verified_ads_filter',
    HAS_VIDEOS: 'has_videos_filter',
    HAS_IMAGES: 'has_images_filter',
    KEYWORD: 'keywords_field',
    AGENCY: 'agency_filter',
    TRUCHECK: 'trucheck_enabled',
    TRANSACTION_HISTORY: 'transaction_history',
    MEDIA: 'advanced_filter',
    RENT_FREQUENCY: 'rent_frequency',
    COMPLETION_STATUS: 'completion_status_filter',
    FURNISHING_STATUS: 'furnishing_status_filter',
    SALE_TYPE: 'sale_type',
    HANDOVER_DATE: 'handover_date',
    PAYMENT_PLAN: 'payment_plan',
    COMPLETION_PERCENTAGE: 'completion_percentage',
    POI_PICK: 'nbs_picked_poi',
    MAX_TRAVEL_TIME: 'max_travel_time',
    LANGUAGE: 'language_filter',
    AGENT: 'agent_filter',
    SPECIALITIES: 'specialities_filter',
    TIME_SINCE_CREATION: 'time_since_creation',
    CONTRACT_RENEWAL_STATUS: 'contract_renewal_status',
    DEVELOPER: 'developer',
    DEVELOPER_FILTER: 'developer_filter',
});

/**
 * Provides convience functions for search tracking.
 */
export default <T extends any>(
    component: Flow.AbstractComponent<T>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'GTMSearchTrackingProps'.
): Flow.AbstractComponent<Flow.Diff<T, GTMSearchTrackingProps>> => {
    class GTMSearchTracking extends React.Component<T & Props> {
        constructor(props: T & Props) {
            super(props);
            autoBind(this);
        }

        /**
         * Gets a set of variables that are send in all events.
         */
        commonVariables(): any {
            let pageGroup = this.props.isProjectView ? ProjectSearchType.PROJECT_VIEW : null;
            if (this.props.isProjectPage) {
                pageGroup = ProjectSearchType.PROJECT_PAGE;
            }
            return {
                ...getViewSectionVariables(this.props.searchView),
                completion_status: this.props.completionStatus,
                page_group: pageGroup,
                ...(this.props.isDubaiTransactionsPage && {
                    page_type: PageTypes.DUBAI_TRANSACTIONS,
                }),
            };
        }

        trackSearchFilterChange(
            filterName: string,
            newValue: string,
            otherVariables?: {
                view_section?: Values<typeof ViewSections>;
                listing_pagetype?: Values<typeof PageTypes>;
            },
        ): void {
            const variables = {
                ...this.commonVariables(),
                name: filterName,
                value: newValue,
                ...otherVariables,
            } as const;
            this.props.trigger(Triggers.FILTER_CHANGE, variables, ScheduleScope.AFTER_RENDER);
        }

        /**
         * Tracks the user setting up a search alert.
         */
        trackSetupAlert(): void {
            this.props.trigger(Triggers.PROPERTY_SETUP_ALERT, {
                ...this.commonVariables(),
                view_element: ViewElements.SETUP_ALERT,
            });
        }

        /**
         * Tracks the user saving a search.
         */
        trackSaveSearch(): void {
            this.props.trigger(Triggers.PROPERTY_SAVE_SEARCH, {
                ...this.commonVariables(),
                view_element: ViewElements.SAVE_SEARCH,
            });
        }

        trackSearchHistory(): void {
            this.props.trigger(Triggers.SEARCH_HISTORY_CLICKED, {});
        }

        /**
         * Tracks the user changing the hits layout to List or Grid
         */
        trackListViewToggle(layout: Values<typeof SearchHitsLayout>): void {
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<{ LIST: string; GRID: string; }>'.
            const hitsLayout = SearchHitsLayout[layout].toLowerCase();
            this.props.trigger(Triggers.CARD_LIST_VIEW_TOGGLE, {
                layout_preference: hitsLayout,
                listing_arrangement: hitsLayout,
                ...this.commonVariables(),
            });
        }

        /**
         * Tracks the user changing the sorting
         */
        trackSortingChange(viewSection: Values<typeof ViewSections>, sortValue: any): void {
            this.props.trigger(Triggers.SORTING_CHANGE, {
                ...this.commonVariables(),
                view_section: viewSection,
                sort_preference: sortValue || 'default',
                website_section: BayutCompat.Purpose.textAsVerb(this.props.purpose),
            });
        }

        /**
         * Tracks the user changing the completion status filter
         */
        trackCompletionStatusChange(value: any): void {
            this.props.trigger(Triggers.COMPLETION_STATUS_CHANGE, {
                ...this.commonVariables(),
                completion_status: value || 'any',
            });
        }

        /**
         * Tracks the user changing the completion status filter
         */
        trackSaleTypeChange(value: any): void {
            this.props.trigger(Triggers.SALE_TYPE_CHANGE, {
                ...this.commonVariables(),
                sale_type: value || 'any',
            });
        }

        /**
         * Tracks the user changing the furnishing status filter
         */
        trackFurnishingStatusChange(value: any): void {
            this.props.trigger(Triggers.FURNISHING_STATUS_CHANGE, {
                ...this.commonVariables(),
                furnishing_status: value || 'any',
            });
        }

        trackSearchProjectChangeView(value: string): void {
            this.props.trigger(Triggers.CHANGE_VIEW, {
                ...this.commonVariables(),
                name: 'view_type',
                value,
            });
        }

        trackProjectClick(): void {
            this.props.trigger(Triggers.CLICK_PROJECT, {
                ...this.commonVariables(),
                page_group: ProjectSearchType.PROJECT_VIEW,
            });
        }

        trackProjectPageView(searchType: Values<typeof ProjectSearchType>): void {
            this.props.trigger(Triggers.PROJECT_PAGE_VIEW, {
                ...this.commonVariables(),
                page_group: searchType,
            });
        }

        trackProjectPageViewDetails(value: string): void {
            this.props.trigger(Triggers.PROJECT_PAGE_VIEW_DETAILS, {
                ...this.commonVariables(),
                page_group: ProjectSearchType.PROJECT_PAGE,
                name: 'detail_type',
                value,
            });
        }

        /**
         * Renders the wrapped component and exposes convenience
         * functions to the wrapped component through props.
         */
        render(): React.ReactElement {
            // @ts-ignore
            return React.createElement(component, {
                ...this.props,
                trackSearchFilterChange: this.trackSearchFilterChange,
                trackSetupAlert: this.trackSetupAlert,
                trackSaveSearch: this.trackSaveSearch,
                trackSearchHistory: this.trackSearchHistory,
                trackListViewToggle: this.trackListViewToggle,
                trackSortingChange: this.trackSortingChange,
                trackCompletionStatusChange: this.trackCompletionStatusChange,
                trackFurnishingStatusChange: this.trackFurnishingStatusChange,
                trackSearchProjectChangeView: this.trackSearchProjectChangeView,
                trackProjectClick: this.trackProjectClick,
                trackProjectPageView: this.trackProjectPageView,
                trackProjectPageViewDetails: this.trackProjectPageViewDetails,
            });
        }
    }

    // @ts-expect-error - TS2322 - Type 'AbstractComponent<Diff<T, GTMProps>, any>' is not assignable to type 'AbstractComponent<Diff<T, GTMSearchTrackingProps>, any>'.
    return withGTM(
        // @ts-expect-error - TS2345 - Argument of type 'ConnectedComponent<ComponentType<Matching<{ searchView: any; } & DispatchProp<AnyAction>, ClassAttributes<GTMSearchTracking> & T & GTMProps & Props & { ...; } & { ...; }>>, { [k in keyof (DistributiveOmit<...> & ... 1 more ... & ConnectPropsMaybeWithoutContext<...>)]: (DistributiveOmit<...> & ... 1 more ... & Connec...' is not assignable to parameter of type 'AbstractComponent<any, any>'.
        connect((state: GlobalState) => ({
            searchView: selectSearchView(state),
            completionStatus: selectCompletionStatus(state),
            isDubaiTransactionsPage: selectIsTransactionsPage(state),
            isProjectPage: CONFIG.runtime.STRAT_ENABLE_PROJECT_PAGE && !!selectProject(state),
            isProjectView: selectIsProjectSearchEnabled(state),
            purpose: selectPurpose(state),
            // @ts-expect-error - TS2345 - Argument of type 'typeof GTMSearchTracking' is not assignable to parameter of type 'ComponentType<Matching<{ searchView: any; } & DispatchProp<AnyAction>, ClassAttributes<GTMSearchTracking> & T & GTMProps & Props & { ...; } & { ...; }>>'.
        }))(GTMSearchTracking),
    );
};
