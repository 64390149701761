import * as React from 'react';
import { Flex } from 'strat/components';
import { useTotalSearchHitCount } from '@sector-labs/fe-search-redux/state';

import { BreadcrumbsWithSlashes } from 'horizontal/breadcrumbs';
import { SaveSearchButton } from 'horizontal/savedSearches';

import HitCountLabel from './results/hitCountLabel';
import { useHeadingTitle, useBreadcrumbs } from './seo';
import { HeadingTitle } from './heading';
import { useCurrentSearchRouteParams } from './routing';
import styles from './styles/searchHeading.cssm';

const SearchHeading = () => {
    const params = useCurrentSearchRouteParams();
    const breadcrumbs = useBreadcrumbs(params);
    const [hitCount, isExhaustiveHitCount] = useTotalSearchHitCount();
    const headingTitle = useHeadingTitle();

    return (
        <Flex column className={styles.container}>
            <div className={styles.breadcrumbs}>
                <BreadcrumbsWithSlashes breadcrumbs={breadcrumbs} withShortTitles />
            </div>
            <Flex justifySpaceBetween>
                <Flex alignCenter className={styles.titleContainer}>
                    <HeadingTitle>{headingTitle}</HeadingTitle>
                    {CONFIG.runtime.STRAT_ENABLE_OLX_SHOW_AD_COUNT && (
                        <HitCountLabel count={hitCount} isExhaustive={isExhaustiveHitCount} />
                    )}
                </Flex>
                <SaveSearchButton />
            </Flex>
        </Flex>
    );
};

export default SearchHeading;
