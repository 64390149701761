const rootLocationNode = (language: string) => {
    const baseLocation = {
        slug: '/dubai',
        slug_l1: '/dubai',
        externalID: '5002',
    } as const;
    switch (language) {
        case 'en':
            return {
                ...baseLocation,
                name: 'Dubai',
                name_l1: 'دبي',
                name_l2: '迪拜',
            };
        case 'ar':
            return {
                ...baseLocation,
                name_l2: 'Dubai',
                name: 'دبي',
                name_l1: '迪拜',
            };
        case 'zh':
            return {
                ...baseLocation,
                name_l1: 'Dubai',
                name_l2: 'دبي',
                name: '迪拜',
            };
        default:
            return {
                ...baseLocation,
                name: 'Dubai',
                name_l1: 'دبي',
                name_l2: '迪拜',
            };
    }
};

export const RootLocationNodeExternalID = '5002';

export default rootLocationNode;
