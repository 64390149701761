/**
 * Placeholder for redacted e-mail addresses.
 */
const EMAIL_PLACEHOLDER = '[redacted email address]';

/**
 * Placeholder for redacted phone numbers.
 */
const PHONE_PLACEHOLDER = '[redacted phone number]';

/**
 * Regex for redacted phone numbers with extra info
 */
const PHONE_PLACEHOLDER_NUMBER = /\[redacted phone number\](#{0,1}\(([()_\-+\d ]*)\)){0,1}/;

export { EMAIL_PLACEHOLDER, PHONE_PLACEHOLDER, PHONE_PLACEHOLDER_NUMBER };
