import React from 'react';
// @ts-expect-error - TS2724 - '"react"' has no exported member named 'Element'. Did you mean 'CElement'?
import type { Element } from 'react';

import Loadable, { LoadingSpinner } from 'strat/loadable';
import { Input } from 'strat/forms';

import Dialog from './dialog';
import defaultStyles from './styles/saveSearchDialog.cssm';

/**
 * Load the actual pop up contents component async.
 */
const SaveSearchDialogContents = Loadable({
    loader: () => import(/* webpackChunkName: 'saveSearchDialog' */ './saveSearchDialogContents'),
    loading: () => (
        <span className={defaultStyles.loadingSpinner}>
            <LoadingSpinner />
        </span>
    ),
});

/**
 * Properties for {@see SaveSearchDialog}.
 */
type Props = {
    /**
     * Callback for when the visibility of the dialog changes.
     */
    onVisibilityChanged: (arg1: boolean) => void;
    /**
     * Whether the dialog should be visible or not.
     */
    visible: boolean;
    /**
     * The styles to use for rendering the dialog.
     */
    styles?: {
        [key: string]: string;
    };
    /**
     * Function called to render the input.
     */
    renderInput?: (field: any) => Element<any>;
    readonly renderSubmitButtons?: (
        disableSubmit: boolean,
        styles: { [key: string]: string },
    ) => React.ReactElement;
    readonly setShowSuccessMessage?: (arg1: boolean) => void;
    readonly initialValue?: string;
};

/**
 * A dialog that allows the user to save the current search
 * under a specific name.
 */
const SaveSearchDialog = ({
    styles = defaultStyles,
    renderInput = (field) => <Input {...field} autoFocus className={defaultStyles.input} />,
    ...props
}: Props) => (
    <Dialog
        dismissible
        visible={props.visible}
        className={styles.saveSearchDialog}
        onVisibilityChanged={props.onVisibilityChanged}
    >
        <SaveSearchDialogContents
            renderInput={renderInput}
            styles={styles}
            onVisibilityChanged={props.onVisibilityChanged}
            renderSubmitButtons={props.renderSubmitButtons}
            setShowSuccessMessage={props.setShowSuccessMessage}
            initialValue={props?.initialValue}
        />
    </Dialog>
);

export default SaveSearchDialog;
