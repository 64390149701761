import React from 'react';

import Loadable, { LoadingSpinner } from 'strat/loadable';
import { Scrollable } from 'strat/modal';
import IconBack from '@app/assets/icons/iconBack.svg';

import UserPopUp from './userPopUp';
import styles from './styles/popUp.cssm';

/**
 * Load the actual pop up contents component async.
 */
const RegisterContents = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ '@app/auth/express/registerContents'),
    loading: LoadingSpinner,
});

/**
 * Properties for {@see RegisterPopUp}.
 */
type Props = {
    /**
     * Indicates whether the pop up should be visible.
     */
    visible: boolean;
    /**
     * Method to call when the visibility of the pop up should change.
     */
    onVisibilityChanged: (visible: boolean) => void;
    /**
     * Method to call when the "Login" button is clicked.
     */
    onLoginClick: (event: React.SyntheticEvent<any>) => void;
    onBackClick?: (event: React.SyntheticEvent<any>) => void;
    renderTitle?: () => React.ReactElement;
    stackGroup?: string;
};

/**
 * Shows a registration form in a pop up.
 */
const RegisterPopUp = (props: Props) => {
    const [top, setTop] = React.useState<any>(undefined);
    const renderBackButton = () => (
        <IconBack
            onClick={props.onBackClick}
            className={styles.backButton}
            aria-label={'Back to login'}
        />
    );
    const popUpProps = {
        withoutCloseButton: !!props.onBackClick,
        renderHeaderBackButton: props.onBackClick ? renderBackButton : null,
        stackGroup: props.stackGroup || 'register',
        containerClassName: styles.registerPopUpContainer,
    };

    React.useEffect(() => {
        const target = document.getElementsByClassName(styles.registerPopUpContainer).item(0);
        if (!target) {
            return undefined;
        }

        const config = { childList: true, subtree: true, attributes: true } as const;

        const callback = () => {
            const popUpTop = target.getBoundingClientRect().top;
            const telephoneInput = document.querySelector('.react-tel-input input');

            if (popUpTop && telephoneInput) {
                setTop(
                    `${(telephoneInput.getBoundingClientRect().bottom - popUpTop).toString()}px`,
                );
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(target, config);

        return () => observer.disconnect();
    });

    return (
        // @ts-expect-error - TS2322 - Type '{ children: Element; withoutCloseButton: boolean; renderHeaderBackButton: () => Element; stackGroup: string; containerClassName: any; visible: boolean; onVisibilityChanged: (visible: boolean) => void; } | { ...; }' is not assignable to type 'IntrinsicAttributes & Props'.
        <UserPopUp
            visible={props.visible}
            onVisibilityChanged={props.onVisibilityChanged}
            {...popUpProps}
        >
            <Scrollable className={styles.scrollable}>
                {props.renderTitle && props.renderTitle()}
                {/* @ts-expect-error connectors are not properly typed */}
                <RegisterContents
                    close={() => props.onVisibilityChanged(false)}
                    onLoginSuccess={() => props.onVisibilityChanged(false)}
                    top={top}
                />
            </Scrollable>
        </UserPopUp>
    );
};

export default RegisterPopUp;
