import { t } from '@lingui/macro';
import * as React from 'react';
import { Formik } from 'formik';
import type { I18n } from '@lingui/core';
import { useChangePassword } from '@sector-labs/fe-auth-redux/thunk';
import { SessionErrorKey, useSessionError, SessionError } from '@sector-labs/fe-auth-redux';

import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';
import { LoadingSpinnerOverlay } from 'strat/components';
import { InternalErrorDialog } from 'strat/error';
import { useIsPasswordlessLogin } from 'strat/user/session';

import { useKeycloakSiteConfigWithCallbacks } from '../hooks/useKeycloakSiteConfig';
import {
    generatePasswordValidationSchema,
    PasswordValidationErrors,
} from '../generatePasswordValidationSchema';
import useKeycloakErrorData from '../hooks/useKeycloakErrorData';

import ChangePasswordForm, {
    ChangePasswordFormFields,
    type ChangePasswordFormFormikProps,
    type ChangePasswordFormConfigProps,
} from './changePasswordForm';

type Props = {
    readonly className?: string;
    readonly onDone?: (message: string) => void;
    readonly hasErrorPopup?: boolean;
    readonly stretch?: boolean;
    readonly username?: string | null | undefined;
    readonly withInitialDescription?: boolean;
    readonly formClassName?: string;
};

const State = Object.freeze({
    LOADING: 'loading',
    READY: 'ready',
    ERROR: 'error',
});

const renderForm =
    (props: ChangePasswordFormConfigProps) => (formikProps: ChangePasswordFormFormikProps) => (
        <ChangePasswordForm {...formikProps} {...props} />
    );

const mapSessionErrorToFormErrors = (i18n: I18n, error: Error | SessionError) => {
    if (!(error instanceof SessionError)) {
        return null;
    }

    switch (error.name) {
        // Password policy failures which we validate client-side
        // so we have our own messaging for it.
        case SessionErrorKey.PASSWORD_POLICY_REGEX_FAILED:
        case SessionErrorKey.PASSWORD_POLICY_MIN_LENGTH_FAILED:
        case SessionErrorKey.INPUT:
        case SessionErrorKey.VALIDATION_ERROR: {
            const invalidFields = error.extra.invalidFields;
            return {
                ...(invalidFields.oldPassword && {
                    [ChangePasswordFormFields.OLD_PASSWORD]:
                        PasswordValidationErrors.required(i18n),
                }),
                ...(invalidFields.newPassword && {
                    [ChangePasswordFormFields.PASSWORD]: PasswordValidationErrors.invalid(i18n),
                }),
            };
        }

        // Password policy failures for which Keycloak tells us what error
        // message to show the user.
        case SessionErrorKey.PASSWORD_POLICY_FAILED:
        case SessionErrorKey.PASSWORD_POLICY_BLACKLIST_FAILED:
        case SessionErrorKey.PASSWORD_POLICY_HISTORY_FAILED:
        case SessionErrorKey.PASSWORD_POLICY_NOT_EMAIL_FAILED: {
            const invalidFields = error.extra.invalidFields;
            return {
                ...(invalidFields.newPassword && {
                    [ChangePasswordFormFields.PASSWORD]: error.message,
                }),
            };
        }

        case SessionErrorKey.INVALID_OLD_PASSWORD:
            return {
                [ChangePasswordFormFields.OLD_PASSWORD]:
                    PasswordValidationErrors.unauthorized(i18n),
            };
        default:
            return null;
    }
};

const ChangePassword = ({
    className,
    onDone,
    stretch,
    hasErrorPopup,
    username,
    withInitialDescription = true,
    formClassName,
}: Props) => {
    const i18n = useI18n();
    const isPasswordlessLogin = useIsPasswordlessLogin();
    const changePassword = useChangePassword();
    const siteConfig = useKeycloakSiteConfigWithCallbacks();
    const sessionError = useSessionError();
    const [errorData] = useKeycloakErrorData();
    const formikRef = React.useRef<Formik>(null);

    const validationSchema = React.useMemo(
        () => generatePasswordValidationSchema(i18n, { includeOldPassword: !isPasswordlessLogin }),
        [i18n, isPasswordlessLogin],
    );
    const initialValues = {
        [ChangePasswordFormFields.OLD_PASSWORD]: '',
        [ChangePasswordFormFields.PASSWORD]: '',
        [ChangePasswordFormFields.PASSWORD_CONFIRM]: '',
    } as const;

    const [state, setState] = React.useState(State.READY);

    React.useEffect(() => {
        if (!sessionError) {
            return;
        }

        const formErrors = mapSessionErrorToFormErrors(i18n, sessionError);

        if (formErrors) {
            // If form errors show them on the form and let user edit the form
            formikRef.current?.setErrors(formErrors);
            setState(State.READY);
        } else if (!hasErrorPopup) {
            // If generic error popup is disabled show any unknown error on
            // the last form field
            formikRef.current?.setErrors({
                [ChangePasswordFormFields.PASSWORD_CONFIRM]: errorData?.message,
            });
            setState(State.READY);
        } else {
            // Otherwise show generic popup
            setState(State.ERROR);
        }
    }, [i18n, sessionError, errorData, setState, hasErrorPopup]);

    const trackPasswordChange = (isSuccess: boolean) => {
        trigger(Triggers.PASSWORD_CHANGE, {
            viewSection: ViewSections.BODY,
            filter_name: 'pass-change',
            filter_value: isSuccess ? 'success' : 'failed',
        });
    };

    const onSubmit = React.useCallback(
        (values, { setSubmitting, resetForm }) => {
            setState(State.LOADING);

            changePassword(siteConfig, {
                newPassword: values.password,
                ...(values.oldPassword && { oldPassword: values.oldPassword }),
            }).then((sessionData) => {
                setSubmitting(false);
                if (!sessionData) {
                    // If sessionData is missing the request failed.
                    // Return from this function and let the error hook
                    // fetch the errors from Redux.
                    trackPasswordChange(false);
                    return;
                }
                trackPasswordChange(true);
                resetForm();
                setState(State.READY);

                if (onDone) {
                    onDone(t(i18n)`You have successfully changed your password`);
                }
            });
        },
        [siteConfig, changePassword, onDone, setState, i18n],
    );

    return (
        <div className={className}>
            <LoadingSpinnerOverlay visible={state === State.LOADING} />
            <Formik
                ref={formikRef}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {renderForm({
                    includeOldPassword: !isPasswordlessLogin,
                    stretch,
                    username,
                    withInitialDescription,
                    formClassName,
                })}
            </Formik>
            <InternalErrorDialog
                visible={state === State.ERROR}
                onVisibilityChanged={() => setState(State.READY)}
                message={errorData?.message}
            />
        </div>
    );
};

export default ChangePassword;
