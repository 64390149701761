import { useDispatch, useSelector } from 'react-redux';
import settings from '@app/branding/settings';

import { useI18n } from 'strat/i18n/language';
import { Choice, ChoiceValue } from 'strat/components/types';

import { setBaseCurrency } from './state';
import selectCurrency from './selectors';
import Currency from './currency';

const useDefaultCurrencySelectionConfiguration = () => {
    const i18n = useI18n();
    const dispatch = useDispatch();
    const { baseCurrency } = useSelector(selectCurrency);

    const primaryCurrency = settings.baseCurrencyName;
    const secondaryCurrency = settings.secondaryCurrency;

    if (!secondaryCurrency) {
        return null;
    }

    const currencies: Array<Choice<string>> = [
        {
            value: primaryCurrency,
            label: Currency.symbol(i18n, primaryCurrency),
        },
        {
            value: secondaryCurrency,
            label: Currency.symbol(i18n, secondaryCurrency),
        },
    ];

    const currencyChanged = (value: ChoiceValue) => {
        dispatch(setBaseCurrency(value as string));
    };

    return {
        onSelectCurrency: currencyChanged,
        currencies: currencies,
        selectedCurrency: baseCurrency,
    };
};

export default useDefaultCurrencySelectionConfiguration;
