import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { PaymentPaymobMWalletRouteParams } from 'horizontal/routes';

type UseNavigateToPaymobMWalletPayment = (
    arg1?: PaymentPaymobMWalletRouteParams | null | undefined,
) => void;

const useNavigateToPaymobMWalletPayment = (): UseNavigateToPaymobMWalletPayment => {
    const router = useRouter();

    return React.useCallback(
        (params?: PaymentPaymobMWalletRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_PAYMOB_MWALLET, params);
        },
        [router],
    );
};

export default useNavigateToPaymobMWalletPayment;
