import { createSelector } from 'reselect';
import { KeycloakUserState, type KeycloakUser } from 'strat/auth/keycloak/user';
import { selectRouteParams } from 'strat/routes/selectors';
import { selectActiveUser } from 'strat/user/session';

import { ProToolChatRoomRouteParams } from 'horizontal/agencyPortal/routes/agencyPortalAdsRoute';
import { ChatFeatureStatus } from 'horizontal/chat/constants';
import type { StratChatRoomRouteParams } from 'horizontal/routes/chatRoute';
import { ChatWidgetRouteParams } from 'horizontal/routes/chatWidgetRoute';

export const selectChatRouteParams = createSelector(
    selectRouteParams,
    (params: StratChatRoomRouteParams | ProToolChatRoomRouteParams | ChatWidgetRouteParams) => {
        const { subjectXID, adExternalID } = params as Partial<
            StratChatRoomRouteParams & ProToolChatRoomRouteParams & ChatWidgetRouteParams
        >;

        return {
            ...params,
            subjectXID: subjectXID ?? adExternalID,
        };
    },
);

const CHAT_BANNED_STATES: KeycloakUserState[] = [
    KeycloakUserState.CHAT_DISABLED,
    KeycloakUserState.LIGHT_BANNED,
    KeycloakUserState.BANNED,
];

export const selectChatFeatureAvailability = createSelector(
    selectActiveUser,
    (user?: KeycloakUser | null) => {
        if (CONFIG.runtime.ENABLE_CHAT_MAINTENANCE) {
            return ChatFeatureStatus.MAINTENANCE;
        } else if (!user) {
            return ChatFeatureStatus.UNAUTHENTICATED;
        } else if (CHAT_BANNED_STATES.includes(user.state)) {
            return ChatFeatureStatus.BANNED;
        }

        return ChatFeatureStatus.AVAILABLE;
    },
);
