import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import { UserRoleTypes } from 'horizontal/types';
import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureContextUserHasRole from './ensureContextUserHasRole';

class TeleSalesToolRoute extends Route {
    constructor() {
        super(RouteNames.TELE_SALES, [['^/telesales']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/telesales' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        const promise = ensureContextUserHasRole(
            context,
            UserRoleTypes.TELE_SALES,
            Page.TELE_SALES,
        );
        context.promise.wait(promise);
    }
}

export default new TeleSalesToolRoute();
