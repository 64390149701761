import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';

export const selectDriftCorrectedClock = createSelector(
    (state: GlobalState) => state.clock?.drift,
    (drift: number | null) => ({
        unixEpochNow: () => Math.trunc(((drift || 0) + Date.now()) / 1000),
    }),
);
