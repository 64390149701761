import * as React from 'react';
import { LoadingSpinnerOverlay } from 'strat/components';

import PrivateNonIndexableHead from './privateNonIndexableHead';

const LoadingTransitionPage = {
    head: () => <PrivateNonIndexableHead />,
    body: () => <LoadingSpinnerOverlay visible={true} />,
};

export default LoadingTransitionPage;
