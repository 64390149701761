import animateScroll from 'react-scroll/modules/mixins/animate-scroll';

const scrollToElement = (element?: HTMLElement | null, offset = 0) => {
    if (!element) {
        return;
    }

    animateScroll.scrollTo(element.getBoundingClientRect().top + offset, {
        smooth: true,
        duration: 400,
    });
};

export default scrollToElement;
