import computeVerificationBadge from 'strat/property/badges/computeVerificationBadge';
import type { PropertyData } from 'strat/property/types';

const getPropertyBadges = (property: PropertyData) => {
    const propertyBadges: Array<string> = [];

    const verificationBadge = computeVerificationBadge(property);
    if (verificationBadge) {
        propertyBadges.push(verificationBadge);
    }

    return propertyBadges.join(', ');
};

export default getPropertyBadges;
