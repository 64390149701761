import * as React from 'react';
import { isNonNullable } from 'strat/util';

import type { CategoryFieldNoChoices } from 'horizontal/types';

const useCategoryFieldsByAttributes = <T extends CategoryFieldNoChoices>(
    categoryFields: Array<T>,
    attributes: readonly string[],
): Array<T> =>
    React.useMemo(
        () =>
            attributes
                .map((attribute) => categoryFields.find((field) => field.attribute === attribute))
                .filter(isNonNullable),
        [categoryFields, attributes],
    );

export default useCategoryFieldsByAttributes;
