import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import Link from 'strat/navigation/link';
import RouteNames from 'strat/routes/routeNames';

import type { SearchRouteParams } from 'horizontal/routes';

import useUpdateFiltersPreemptively from './useUpdateFiltersPreemptively';

type Props = Flow.Diff<
    React.ComponentProps<typeof Link>,
    {
        route: string;
    }
> & {
    readonly params?: SearchRouteParams;
    readonly onClickParams?: SearchRouteParams;
    readonly rel?: string;
};

const SearchLink = ({ params, onClickParams, hard, onClick, children, ...props }: Props) => {
    const updateFiltersPreemptively = useUpdateFiltersPreemptively();

    const onLinkClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            if (!hard) {
                // @ts-expect-error - TS2345 - Argument of type '({ [key: string]: any; } & SearchRouteParams) | undefined' is not assignable to parameter of type 'SearchRouteParams'.
                updateFiltersPreemptively(onClickParams || params);
            }

            if (onClick) {
                onClick(event);
            }
        },
        [hard, updateFiltersPreemptively, params, onClick, onClickParams],
    );

    return (
        <Link
            route={RouteNames.SEARCH}
            params={params}
            onClickParams={onClickParams}
            hard={hard}
            onClick={onLinkClick}
            {...props}
        >
            {children}
        </Link>
    );
};

export default SearchLink;
