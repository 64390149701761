import shuffle from 'lodash/shuffle';
import type { AnyAction, Dispatch } from 'redux';

import { selectLanguage } from 'strat/i18n/language/selectors';
import { RouteNames } from 'strat/routes';
import type { GlobalState } from 'strat/state';
import type { PropertyData } from 'strat/property';
import { selectSortValue } from 'strat/search/state/selectors';
import { SortByValues } from 'strat/search/sortValues';
import { PropertyProduct } from 'strat/property/types';
import computeVerificationBadge from 'strat/property/badges/computeVerificationBadge';
import SearchView from 'strat/search/searchView';
import { selectIsProjectSearchEnabled, selectIsRemarketingPage } from 'strat/search/selectors';
import { determineAdsIndexName } from 'strat/search/indexNames';

const cityLevelScoreTopProducts = [PropertyProduct.SIGNATURE, PropertyProduct.HOT];

/**
 * Shuffles the hits within product & verification buckets
 * If the index from which the data is fetched is city-level-score, treat signature and hot as the same priority
 * Keep listings with hidden price at the end of their respective buckets
 * @param properties
 * @param isCityLevelScoreIndex
 */
export const shufflePropertiesWithinVerificationProductBuckets = (
    properties: PropertyData[],
    isCityLevelScoreIndex: boolean,
) => {
    if (properties.length === 0) {
        return properties;
    }

    let index = 0;
    let product;
    let verificationBadge;
    let bucket;
    let propertiesWithHiddenPrice;
    const shuffledProperties = [];

    while (index < properties.length) {
        product = properties[index].product;
        verificationBadge = computeVerificationBadge(properties[index]);
        bucket = [];
        propertiesWithHiddenPrice = [];

        while (
            index < properties.length &&
            (product === properties[index].product ||
                (isCityLevelScoreIndex &&
                    cityLevelScoreTopProducts.includes(product || '') &&
                    cityLevelScoreTopProducts.includes(properties[index].product || ''))) &&
            verificationBadge === computeVerificationBadge(properties[index])
        ) {
            if (properties[index].hidePrice) {
                propertiesWithHiddenPrice.push(properties[index]);
            } else {
                bucket.push(properties[index]);
            }
            index += 1;
        }

        shuffledProperties.push(...shuffle(bucket));
        shuffledProperties.push(...propertiesWithHiddenPrice);
    }

    return shuffledProperties;
};

const searchVerifiedShuffleMiddleware = (
    action: AnyAction,
    dispatch: Dispatch<GlobalState>,
    getState: () => GlobalState,
) => {
    if (!action.content || action.content.nbHits === 0) {
        return;
    }

    const state = getState();
    const isProjectSearchEnabled = selectIsProjectSearchEnabled(state);
    const isRemarketingPage = selectIsRemarketingPage(state);
    const isMapView =
        action.view === SearchView.MAP_BASED_SEARCH || action.view === SearchView.COMMUTE_SEARCH;
    const route = state.router.routeName;

    if (route !== RouteNames.SEARCH || isMapView || isProjectSearchEnabled || isRemarketingPage) {
        return;
    }

    const sortBy = selectSortValue(state);
    const isCityLevelScoreIndex =
        action.content.index ===
        determineAdsIndexName({
            sortBy: SortByValues.CITY_LEVEL_SCORE,
            language: selectLanguage(state),
        });

    if (
        !sortBy ||
        sortBy === SortByValues.VERIFIED_SCORE ||
        sortBy === SortByValues.POPULAR ||
        sortBy === SortByValues.CITY_LEVEL_SCORE
    ) {
        action.content.hits = shufflePropertiesWithinVerificationProductBuckets(
            action.content.hits,
            isCityLevelScoreIndex,
        );
    }
};

export default searchVerifiedShuffleMiddleware;
