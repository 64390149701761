import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

import { buildCanonicalURL } from 'strat/routing';
import { createTrackableAdURL } from 'strat/property/url';
import type { PropertyData } from 'strat/property/types';
import { ViewSections } from 'strat/gtm';

export type GenerateSMSURLParams = {
    property: PropertyData;
    i18n: I18n;
    language: string;
    traceID: string;
    viewSection: Values<typeof ViewSections>;
};

/**
 * Sends a text message to the specified phone number about
 * the specified property.
 * @param property The property for which to sent the SMS.
 * @param i18n i18n instance.
 * @param language Current language.
 * @param traceID Trace id of the view to be confirmed
 * @param viewSection current viewSection
 */
const generateSMSURL = ({
    property,
    i18n,
    language,
    traceID,
    viewSection,
}: GenerateSMSURLParams): string => {
    const propertyURL = buildCanonicalURL(
        createTrackableAdURL({ externalID: property.externalID, traceID }),
        language,
    );
    const brandName = settings.getBrandName(i18n);
    const { mobilePhoneNumber } = property;

    const smsBody =
        viewSection === ViewSections.REQUEST_VIDEO
            ? t(
                  i18n,
              )`I am interested in this property and would like to request for a video before booking a viewing. Please get in touch with me as soon as possible, thank you. Link: ${propertyURL}`
            : t(i18n)`Hi, I am interested in your property on ${brandName}. Link: ${propertyURL}`;

    return `sms:${mobilePhoneNumber as any}?&body=${encodeURI(smsBody)}`;
};

export default generateSMSURL;
