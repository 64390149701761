import type { AdCompletionDetails } from 'strat/property/types';

const useProjectCompletionPercentage = (
    completionDetails?: AdCompletionDetails | null,
): number | null => {
    if (!completionDetails || !completionDetails.completionPercentage) {
        return null;
    }

    const completionPercentageValue = completionDetails.completionPercentage;

    if (!completionPercentageValue || completionPercentageValue <= 0) {
        return null;
    }

    if (completionPercentageValue === 100) {
        // While the completion percentage seems to be 100, the developer
        // might not consider this project ready. It might be confusing for the
        // user to see `100% completed` for an `off-plan` property/project, so
        // we treat 100 as "undefined".
        return null;
    }

    return completionPercentageValue;
};

export default useProjectCompletionPercentage;
