import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

const templatesFetcherFactory = new FetcherFactory(
    'templates',

    (params: any, state: any) => new StratAPI(state.i18n.language).templates(),
    {
        catchServerErrors: true,
    },
);

/**
 * Action creator for fetching the templates from the backend.
 */
const fetchTemplates = templatesFetcherFactory.creator();

/**
 * Reducers for the search links.
 */
const templatesReducer = templatesFetcherFactory.reducer();

export { fetchTemplates };

export default templatesReducer;
