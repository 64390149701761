import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import { UserDeliveryOrderStatus } from '../types';

const useAddressDialogDetails = (action: string, i18n: I18n) => {
    switch (action) {
        case UserDeliveryOrderStatus.ACCEPTED:
            return {
                title: t(i18n)`You are about to confirm this order.`,
                description: t(i18n)`Our team will inform you about the delivery time.`,
                confirm: t(i18n)`Confirm`,
                cancel: t(i18n)`Cancel`,
            };

        case UserDeliveryOrderStatus.REJECTED:
            return {
                title: t(i18n)`You are about to decline this order.`,
                description: '',
                confirm: t(i18n)`Confirm`,
                cancel: t(i18n)`Cancel`,
            };
        case UserDeliveryOrderStatus.OUT_FOR_DELIVERY:
            return {
                title: t(i18n)`You are about to mark this order as out for delivery.`,
                description: '',
                confirm: t(i18n)`Confirm`,
                cancel: t(i18n)`Cancel`,
            };

        default:
            return {
                title: t(i18n)`You are about to cancel this order.`,
                description: '',
                confirm: t(i18n)`Confirm`,
                cancel: t(i18n)`Cancel`,
            };
    }
};

export default useAddressDialogDetails;
