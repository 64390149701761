import * as React from 'react';
import { trigger, Triggers } from 'strat/gtm';

import type { ChatRoomXID } from 'horizontal/chat/types';

import useChatSDK from './useChatSDK';
import useIsPageFocused from './useIsPageFocused';
import useIsPageVisible from './useIsPageVisible';

const useAutoSendReadReceipt = ({
    roomXID,
    unreadCount,
}: {
    roomXID: ChatRoomXID | null | undefined;
    unreadCount: number | null | undefined;
}) => {
    const sdk = useChatSDK();
    const sendReadReceipt = sdk.useSendReadReceipt(roomXID);

    const isFocused = useIsPageFocused();
    const isVisible = useIsPageVisible();

    const count = unreadCount ?? 0;
    const isFollowingConversation = !!roomXID && isVisible && isFocused;

    React.useEffect(() => {
        if (!isFollowingConversation || !count) {
            return;
        }

        sendReadReceipt();

        trigger(Triggers.CHAT_UNREAD_MESSAGES, {
            filter_name: 'unread-message',
            filter_value: count,
        });
    }, [sendReadReceipt, isFollowingConversation, count]);
};

export default useAutoSendReadReceipt;
