import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import { formatDate } from 'strat/i18n/language/dateTimeFormatter';

const defaultFormattedDateOptions = { weekday: 'long', day: 'numeric', month: 'long' } as const;

export const getTimeAsHour = (timestamp: number, language: any) => {
    return formatDate(new Date(timestamp), language, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    });
};

export const getTimeWithMeridiem = (timestamp: number, language: any) => {
    return formatDate(new Date(timestamp), language, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
};

/**
 * Pretty formats the number of seconds in mm:ss or in hh:mm:ss
 */
export const getDuration = (totalNoSeconds: number) => {
    const seconds = totalNoSeconds % 60;
    const minutes = Math.floor(totalNoSeconds / 60) % 60;
    const hours = Math.floor(totalNoSeconds / 3600);
    const toTwoDigitsString = (number: number) => {
        const first = Math.floor(number / 10);
        const second = number % 10;
        return `${first}${second}`;
    };

    if (hours) {
        return `${hours}:${toTwoDigitsString(minutes)}:${toTwoDigitsString(seconds)}`;
    }
    return `${toTwoDigitsString(minutes)}:${toTwoDigitsString(seconds)}`;
};

export const getTimeAsDate = (timestamp: number, language: any) => {
    return formatDate(new Date(timestamp), language, {
        month: 'numeric',
        year: 'numeric',
        day: 'numeric',
    });
};

export const sameDay = (timestampDate1: number, timestampDate2: number) => {
    const date1 = new Date(timestampDate1);
    const date2 = new Date(timestampDate2);
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );
};

export const isToday = (timestamp: number) => sameDay(Date.now(), timestamp);

const isYesterday = (timestamp: number) => {
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 1);
    return isToday(date.getTime());
};

/**
 * Warning: Adding for ex: 30 minutes to some date 23:30 is not always the next date
 * Due to vanilla JS Date can have days longer/shorter than 24 hours
 */
export const addMinutes = (date: Date, minutes: number) =>
    new Date(date.getTime() + minutes * 60000);

export const getTimeAsFormattedDate = (
    timestamp: number,
    i18n: I18n,
    options: Intl.DateTimeFormatOptions = defaultFormattedDateOptions,
) => {
    if (isToday(timestamp)) {
        return t(i18n)`Today`;
    }
    if (isYesterday(timestamp)) {
        return t(i18n)`Yesterday`;
    }
    return formatDate(new Date(timestamp), i18n.locale, options);
};

export const secondsUntilNextMidnight = (): number => {
    const nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    return Math.floor((nextMidnight.getTime() - Date.now()) / 1000);
};
