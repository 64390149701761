import * as React from 'react';
import classNames from 'classnames';
import { useDialog } from 'strat/dialogs/dialogContext';
import { withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';

import IconAttachment from 'horizontal/assets/icons/iconAttachment.svg';
import { ChatMessageType } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type {
    ChatAd,
    ChatMessageFileUploadData,
    ChatMessageLocationContent,
    ChatSendMessageResult,
} from 'horizontal/chat/types';
import { DialogNames } from 'horizontal/dialogs';
import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';

import buttonStyles from '../styles/button.cssm';

import styles from './styles/openAttachmentDialogButton.cssm';

type OwnProps = {
    readonly ad: ChatAd | null | undefined; // required for lead tracking,
    readonly sendImages: (
        files: Array<ChatMessageFileUploadData>,
    ) => Promise<ChatSendMessageResult | undefined>;
    readonly sendAttachments: (
        files: Array<ChatMessageFileUploadData>,
    ) => Promise<ChatSendMessageResult | undefined>;
};
type Props = GTMLeadTrackingProps & OwnProps;

const OpenAttachmentDialogButton = ({ sendImages, sendAttachments, trackChatLead }: Props) => {
    const [, setVisible] = useDialog(DialogNames.CHAT_ATTACHMENT_DIALOG);
    const sdk = useChatSDK();
    const sendLocationMessage = sdk.useActiveRoomSendLocationMessage();
    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);

    const onSendImages = React.useCallback(
        (files: Array<ChatMessageFileUploadData>) => {
            sendImages(files).then((messageSentResponse) => {
                if (messageSentResponse) {
                    trackSentChatMessageEvents(messageSentResponse, ChatMessageType.IMAGES);
                }
            });
        },
        [sendImages, trackSentChatMessageEvents],
    );

    const onSendAttachments = React.useCallback(
        (files: Array<ChatMessageFileUploadData>) => {
            sendAttachments(files).then((messageSentResponse) => {
                if (messageSentResponse) {
                    trackSentChatMessageEvents(messageSentResponse, ChatMessageType.FILES);
                }
            });
        },
        [sendAttachments, trackSentChatMessageEvents],
    );

    const onSendLocation = React.useCallback(
        (location: ChatMessageLocationContent) => {
            sendLocationMessage({ location }).then((messageSentResponse) => {
                if (messageSentResponse) {
                    trackSentChatMessageEvents(messageSentResponse, ChatMessageType.LOCATION);
                }
            });
        },
        [sendLocationMessage, trackSentChatMessageEvents],
    );

    const openDialog = React.useCallback(() => {
        setVisible(true, {
            sendImages: onSendImages,
            sendAttachments: onSendAttachments,
            sendLocation: onSendLocation,
        });
    }, [setVisible, onSendImages, onSendAttachments, onSendLocation]);

    return (
        <button onClick={openDialog} className={classNames(buttonStyles.button, styles.button)}>
            <IconAttachment />
        </button>
    );
};

export default withGTMLeadTracking<Props, ChatAd | null | undefined>(OpenAttachmentDialogButton);
