import * as React from 'react';
import { RouteNames } from 'strat/routes';

import BreadcrumbHomeLink from './breadcrumbHomeLink';
import BreadcrumbSearchLink from './breadcrumbSearchLink';
import type { BreadcrumbLink as BreadcrumbLinkType } from './types';

type Props = {
    readonly breadcrumb: BreadcrumbLinkType;
    readonly className?: string;
    readonly withShortTitle?: boolean;
};

const BreadcrumbLink = ({ breadcrumb, className, withShortTitle }: Props) => {
    switch (breadcrumb.route) {
        case RouteNames.SEARCH:
            return (
                <BreadcrumbSearchLink
                    params={(breadcrumb as any).params}
                    withShortTitle={withShortTitle}
                    className={className}
                />
            );

        case RouteNames.HOME:
            return <BreadcrumbHomeLink className={className} />;

        default:
            return null;
    }
};

export default BreadcrumbLink;
