import { ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchRequestFacetType } from '@sector-labs/fe-search-redux/backend';
import type { SearchRequestFacetsOption } from '@sector-labs/fe-search-redux/backend';
import { FilterValues } from 'strat/search';

import type { LiteCategory, LiteHierarchicalLocation } from 'horizontal/types';

import commonExcludedFiltersFromFaceting from './commonExcludedFiltersFromFaceting';

type HierarchicalFacetParams = {
    readonly level: number;
    readonly parentLevel: number;
    readonly parentExternalID: string;
};

type CategoriesFacetParams = HierarchicalFacetParams & {
    readonly locationExternalID: string;
};

const createCategoriesFacet = ({
    level,
    parentLevel,
    parentExternalID,
    locationExternalID,
}: CategoriesFacetParams): SearchRequestFacetsOption => ({
    // @ts-expect-error - TS2322 - Type '{ type: SearchRequestFacetType; attribute: string; filters: (SerializedFilter<string> | null)[]; includedFilterAttributes: { attribute: string; }[]; }' is not assignable to type 'SearchRequestFacetsOption'.
    type: SearchRequestFacetType.SIMPLE,
    attribute: `category.lvl${level}.externalID`,
    filters: [
        new ExactFilter({
            attribute: `category.lvl${parentLevel}.externalID`,
            value: parentExternalID,
        }).serialize(),
        new ExactFilter({
            attribute: FilterValues.location.attribute,
            value: locationExternalID,
        }).serialize(),
    ],
    // Ignore all other filters, except the location filter and freeTextQuery.
    includedFilterAttributes: [
        {
            attribute: 'query.value',
        },
    ],
});

type LocationsFacetParams = HierarchicalFacetParams & {
    readonly size?: number;
};

const createLocationsFacet = ({
    level,
    size,
    parentLevel,
    parentExternalID,
}: LocationsFacetParams): SearchRequestFacetsOption => ({
    // @ts-expect-error - TS2322 - Type '{ type: SearchRequestFacetType; attribute: string; uniqueAttribute: string; size: number | undefined; filters: (SerializedFilter<string> | null)[]; excludedFilterAttributes: { ...; }[]; }' is not assignable to type 'SearchRequestFacetsOption'.
    type: SearchRequestFacetType.COMPLEX,
    attribute: `location.lvl${level}`,
    uniqueAttribute: `location.lvl${level}.externalID`,
    size,
    filters: [
        new ExactFilter({
            attribute: `location.lvl${parentLevel}.externalID`,
            value: parentExternalID,
        }).serialize(),
    ],
    // Exclude location filters when computing facets.
    excludedFilterAttributes: [
        ...commonExcludedFiltersFromFaceting(),
        {
            attribute: 'location',
            exact: false,
        },
    ],
});

const createCategoryHierarchyFacetingConfig = (
    categoryHierarchy: Array<LiteCategory>,
    locationExternalID: string,
): SearchRequestFacetsOption => {
    const facets: SearchRequestFacetsOption = [];
    const category = categoryHierarchy[categoryHierarchy.length - 1] || null;
    if (category) {
        const parentCategory = categoryHierarchy[categoryHierarchy.length - 2] || null;

        if (parentCategory) {
            // @ts-expect-error - TS2339 - Property 'push' does not exist on type 'SearchRequestFacetsOption'.
            facets.push(
                createCategoriesFacet({
                    level: category.level,
                    parentLevel: category.level - 1,
                    parentExternalID: parentCategory.externalID,
                    locationExternalID,
                }),
            );
        }

        // @ts-expect-error - TS2339 - Property 'push' does not exist on type 'SearchRequestFacetsOption'.
        facets.push(
            createCategoriesFacet({
                level: category.level + 1,
                parentLevel: category.level,
                parentExternalID: category.externalID,
                locationExternalID,
            }),
        );
    }
    return facets;
};

const createLocationHierarchyFacetingConfig = (
    locationHierarchy: Array<LiteHierarchicalLocation>,
): SearchRequestFacetsOption => {
    const facets: SearchRequestFacetsOption = [];
    const location = locationHierarchy[locationHierarchy.length - 1] || null;
    if (location) {
        const size = location.level > 0 ? 40 : 20;
        const parentLocation = locationHierarchy[locationHierarchy.length - 2] || null;

        if (parentLocation) {
            // @ts-expect-error - TS2339 - Property 'push' does not exist on type 'SearchRequestFacetsOption'.
            facets.push(
                createLocationsFacet({
                    level: location.level,
                    size,
                    parentLevel: parentLocation.level,
                    parentExternalID: parentLocation.externalID,
                }),
            );
        }
        // @ts-expect-error - TS2339 - Property 'push' does not exist on type 'SearchRequestFacetsOption'.
        facets.push(
            createLocationsFacet({
                level: location.level + 1,
                size,
                parentLevel: location.level,
                parentExternalID: location.externalID,
            }),
        );
    }
    return facets;
};

const createCommonFieldsFacetingConfig = (
    locationHierarchy: Array<LiteHierarchicalLocation>,
    categoryHierarchy?: Array<LiteCategory>,
): SearchRequestFacetsOption => {
    const categoryFacets = categoryHierarchy
        ? createCategoryHierarchyFacetingConfig(
              categoryHierarchy,
              locationHierarchy[locationHierarchy.length - 1].externalID,
          )
        : [];
    const locationFacets = locationHierarchy
        ? createLocationHierarchyFacetingConfig(locationHierarchy)
        : [];
    return categoryFacets.concat(locationFacets);
};

export default createCommonFieldsFacetingConfig;
