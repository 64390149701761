import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import { UserOrdersSection } from '../types';

const useOrdersSectionParam = (): Values<typeof UserOrdersSection> | null | undefined => {
    const section = useSelector(selectRouteParams).section;

    if (!Object.values(UserOrdersSection).includes(section)) {
        return null;
    }
    return section;
};

export default useOrdersSectionParam;
