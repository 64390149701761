import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import IconCreditCard from 'horizontal/assets/icons/iconCreditCard.svg';
import IconJazzMobile from 'horizontal/assets/icons/iconJazzCashMobileApp.svg';
import { PaymentSource } from 'horizontal/payment/types';
import type { PaymentMethodDetails } from 'horizontal/payment/types';

const useDefaultPaymentMethods = (i18n: I18n): Array<PaymentMethodDetails> => {
    return React.useMemo(
        () => [
            {
                name: t(i18n)`Card Payment`,
                description: t(i18n)`Pay with Visa, Mastercard, or JCB`,
                renderIcon: (className: string) => <IconCreditCard className={className} />,
                source: PaymentSource.CHECKOUT,
            },
            {
                name: t(i18n)`JazzCash Mobile Account`,
                description: t(i18n)`Pay via your JazzCash app`,
                renderIcon: (className: string) => <IconJazzMobile className={className} />,
                source: PaymentSource.JAZZ_CASH_MOBILE_WALLET,
            },
        ],
        [i18n],
    );
};

export default useDefaultPaymentMethods;
