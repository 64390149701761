import type { KeycloakConfig } from '@sector-labs/fe-auth-redux/keycloak-client';
import type { Clock } from '@sector-labs/fe-auth-redux/clock';
import settings from '@app/branding/settings';

export interface KeycloakConfigOptions {
    languageCode: string;
    clock: Clock;
}

export const createKeycloakConfig = (options: KeycloakConfigOptions): KeycloakConfig =>
    Object.freeze({
        realm: CONFIG.runtime.KEYCLOAK_REALM_NAME,
        url: CONFIG.runtime.KEYCLOAK_SERVER_URL,
        clientID: CONFIG.runtime.KEYCLOAK_PUBLIC_CLIENT_ID,
        timeout: CONFIG.runtime.KEYCLOAK_TIMEOUT_MS,
        publicKey: CONFIG.runtime.KEYCLOAK_PUBLIC_KEY,
        iso639S1LanguageCode: options.languageCode,
        defaultRequestCredentials: 'include',
        defaultRequestHeaders: {
            ...(CONFIG.runtime.KEYCLOAK_EMAIL_THEME_HINT && {
                'X-KC-Email-Theme-Hint': CONFIG.runtime.KEYCLOAK_EMAIL_THEME_HINT,
            }),
        },
        enableIssuerClaimValidation: CONFIG.runtime.KEYCLOAK_ENABLE_ISSUER_CLAIM_VALIDATION,
        clock: options.clock,
    });

export const createKeycloakServerConfig = (options: KeycloakConfigOptions): KeycloakConfig =>
    Object.freeze({
        ...createKeycloakConfig(options),
        timeout: CONFIG.runtime.KEYCLOAK_SERVER_TIMEOUT_MS,
    });

export const isKeycloakEnabled = (): boolean =>
    !!CONFIG.runtime.KEYCLOAK_REALM_NAME &&
    !!CONFIG.runtime.KEYCLOAK_SERVER_URL &&
    !!CONFIG.runtime.KEYCLOAK_PUBLIC_CLIENT_ID &&
    !!CONFIG.runtime.KEYCLOAK_PUBLIC_KEY;

export const isRoutePathWithExternallyManagedSession = (routeURL: string): boolean => {
    const routes = settings.routePathPrefixesWithExternallyManagedSession ?? [];
    return routes.some((routePath) => routeURL.startsWith(routePath));
};
