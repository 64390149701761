import { t } from '@lingui/macro';
import React from 'react';
import useI18n from 'strat/i18n/language/useI18n';
import type { FormikValues } from 'formik';

import { useMyProfileDetails } from 'horizontal/profile/state';
import { useIsAnyTypeOfAgent } from 'horizontal/agent/state';
import { LocationRole } from 'horizontal/types';

import { AdDeliveryFields } from './commonPostingFields';

type Props = {
    setFieldValue: (field: string | number, value: any) => void;
    values: FormikValues;
};

const useMultiDeliveryFieldErrors = ({ setFieldValue, values }: Props) => {
    const i18n = useI18n();

    const [errors, setErrors] = React.useState({
        serviceDeliveryError: '',
        selfDeliveryError: '',
    });

    const profile = useMyProfileDetails();
    const isAgentProfile = useIsAnyTypeOfAgent();
    const isSelfDeliveryDisabled = !profile?.isVerified && !isAgentProfile;
    const [isServiceDeliveryDisabled, setIsServiceDeliveryDisabled] = React.useState(false);

    const handleSelfDeliveryError = React.useCallback(() => {
        if (isSelfDeliveryDisabled) {
            setErrors((prev) => ({
                ...prev,
                selfDeliveryError: t(i18n)`Available only for Verified Users.`,
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                selfDeliveryError: '',
            }));
        }
    }, [i18n, isSelfDeliveryDisabled]);

    const handleServiceDeliveryError = React.useCallback(() => {
        let error = '';
        if (
            values.price &&
            parseInt(values.price, 10) > CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE
        ) {
            error = t(
                i18n,
            )`Delivery service is only provided for products with maximum price of ${CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE}.`;
            setIsServiceDeliveryDisabled(true);
        } else if (values.location_delivery_role !== LocationRole.ALLOWS_DELIVERY) {
            error = t(i18n)`Can't provide delivery service for the selected location.`;
            setIsServiceDeliveryDisabled(true);
        } else {
            setIsServiceDeliveryDisabled(false);
        }
        setErrors((prev) => ({
            ...prev,
            serviceDeliveryError: error,
        }));
    }, [values.price, values.location_delivery_role, i18n]);

    React.useEffect(() => {
        handleSelfDeliveryError();
    }, [handleSelfDeliveryError]);

    React.useEffect(() => {
        handleServiceDeliveryError();
    }, [handleServiceDeliveryError]);

    React.useEffect(() => {
        if (errors.serviceDeliveryError && errors.selfDeliveryError) {
            setFieldValue(AdDeliveryFields.delivery_type.attribute, '');
        }
    }, [errors, setFieldValue]);

    return {
        ...errors,
        isServiceDeliveryDisabled,
        isSelfDeliveryDisabled,
    };
};

export default useMultiDeliveryFieldErrors;
