import * as React from 'react';

import { useDialog } from 'strat/dialogs/dialogContext';
import DialogNames from 'strat/dialogs/dialogNames';
import type { Triggers } from 'strat/gtm';

interface LoginProps {
    onDone?: () => void;
    email?: string;
    userPath?: Values<typeof Triggers>;
    stackGroup?: string;
    disableRegister?: boolean;
    disableEmailLink?: boolean;
    disableFacebook?: boolean;
}

type UseLoginDialog = [boolean, (visible: boolean, props?: LoginProps) => void];

const useLoginDialog = (): UseLoginDialog => {
    const [visible, setVisible] = useDialog(DialogNames.LOGIN);
    return React.useMemo(() => [visible, setVisible], [visible, setVisible]);
};

export default useLoginDialog;
