import * as React from 'react';
import { useSearchPagination, useTotalSearchPageCount } from '@sector-labs/fe-search-redux/state';
import { RouteNames } from 'strat/routes';
import settings from '@app/branding/settings';

import { Pagination as BasePagination } from 'horizontal/pagination';

import { useCurrentSearchRouteParams } from './routing';

const Pagination = (): React.ReactElement => {
    const [pageNumber] = useSearchPagination();
    const [totalPageCount] = useTotalSearchPageCount();
    const params = useCurrentSearchRouteParams();

    return (
        <BasePagination
            // @ts-expect-error - TS2322 - Type 'number | null' is not assignable to type 'number'.
            activePage={pageNumber}
            maxVisiblePages={4}
            totalPages={Math.min(totalPageCount, settings.maxSearchPages)}
            routeName={RouteNames.SEARCH}
            routeParams={params}
        />
    );
};

export default Pagination;
