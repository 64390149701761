import React from 'react';

import IconNoImage from '@app/assets/icons/iconNoImage.svg';

import styles from './styles/noImage.cssm';

type Props = {
    className?: string;
};

/**
 * Displays a placeholder for when there is no image/photo available.
 */
const NoImage = ({ className }: Props) => (
    <div className={[styles.noImageContainer, className].join(' ')}>
        <IconNoImage className={styles.noImage} />
    </div>
);

export default NoImage;
