import * as React from 'react';

import VerifiedProfileBadge from 'horizontal/profile/verifiedProfileBadge';
import { LiteAd } from 'horizontal/types';
import useAdAgency from 'horizontal/ad/useAdAgency';

import styles from './styles/sellerDetails.cssm';
import AgencySummary from './agencySummary';

type Props = {
    readonly ad: LiteAd;
    readonly showAgency?: boolean;
    readonly showVerifiedBadge?: boolean;
    readonly showElite?: boolean;
    readonly isGridLayout: boolean;
};

const SellerDetails = ({ ad, showAgency, showVerifiedBadge, showElite, isGridLayout }: Props) => {
    const agency = useAdAgency(ad);

    return (
        <>
            {!showAgency && showVerifiedBadge && (
                <div className={styles.verifiedProfileBadgeWrapper}>
                    <VerifiedProfileBadge
                        shortText={isGridLayout}
                        large={!isGridLayout}
                        isAgency={!!agency}
                    />
                </div>
            )}
            {showAgency && (
                <AgencySummary
                    ad={ad}
                    hideDescription={!showElite || isGridLayout}
                    isGridLayout={isGridLayout}
                    hideVerifiedBadge={!showVerifiedBadge}
                />
            )}
        </>
    );
};

export default SellerDetails;
