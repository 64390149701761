import * as React from 'react';

type Props = {
    readonly index: number;
    readonly children: React.ReactNode;
    readonly className?: string;
};

const BreadcrumbListItem = ({ index, children, className }: Props) => (
    <li
        itemScope
        itemProp="itemListElement"
        itemType="https://schema.org/ListItem"
        className={className}
    >
        {children}
        {/* @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'. */}
        <meta itemProp="position" content={index + 1} />
    </li>
);

export default BreadcrumbListItem;
