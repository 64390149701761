import type { Dispatch } from 'redux';
import { clearRemarketedListings } from 'strat/search/state';
import { MultiJobSearchHandler } from 'strat/routes';

import { SearchJobName } from 'horizontal/search/hits/types';
import { setRemarketedListings } from 'horizontal/search/state';
import { LiteAd } from 'horizontal/types';
import { hitsTransformer } from 'horizontal/search/hits';

const handleRemarketingAd = (
    dispatch: Dispatch<any>,
    searchHandler: MultiJobSearchHandler,
    language: string,
) => {
    const remarketingAdResponse = searchHandler.getResponseByJobName(SearchJobName.REMARKETING);

    if (remarketingAdResponse?.hits?.[0]) {
        const hits = hitsTransformer<LiteAd>(remarketingAdResponse.hits as LiteAd[], language);
        dispatch(setRemarketedListings(hits));
    } else {
        dispatch(clearRemarketedListings());
    }
};

export default handleRemarketingAd;
