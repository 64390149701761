import React from 'react';
import { Trans } from '@lingui/macro';

import styles from './styles/bankDetails.cssm';

const IBAN = 'PK88MEZN0002540105130117';
const ACCOUNT_NUMBER = '02540105130117';
const BANK_NAME = 'Meezan Bank';

const BankDetails = () => (
    <div>
        <div className={styles.bankDetails}>
            <div>
                <Trans>
                    Account No:<b>{ACCOUNT_NUMBER}</b>
                </Trans>
            </div>
            <div>
                <Trans>
                    IBAN:<b> {IBAN}</b>
                </Trans>
            </div>
            <div>
                <Trans>
                    Bank Name:<b> {BANK_NAME}</b>
                </Trans>
            </div>
            <div>
                <Trans>
                    Account Title:<b>ONLINE CLASSIFIEDS PAKISTAN (SMC-PRIVATE) LIMITED</b>
                </Trans>
            </div>
        </div>
    </div>
);

export default BankDetails;
