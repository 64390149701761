import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import { selectUserExternalID } from 'strat/user/session/selectors';

import { ProductPurchaseStatus } from './types';

type Params = {
    readonly status?: Values<typeof ProductPurchaseStatus>;
    readonly IDs?: number[];
    readonly adExternalID?: string;
    readonly bundleProductPurchases?: boolean;
};

const factory = new FetcherFactory(
    'productPurchases',
    (params: Params | null | undefined, state: any) => {
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Cannot fetch user product purchases due to missing user ID.');
        }

        return new StratAPI(state.i18n.language).productPurchases({
            userExternalID,
            adExternalID: params?.adExternalID,
            bundleProductPurchases: params?.bundleProductPurchases,
            status: params?.status,
            // @ts-expect-error - TS2322 - Type 'number[] | undefined' is not assignable to type 'string[]'.
            IDs: params?.IDs,
        });
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const fetchProductPurchases = factory.creator();
const productPurchasesReducer = factory.reducer();

export { fetchProductPurchases };

export default productPurchasesReducer;
