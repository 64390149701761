import * as React from 'react';
import { Trans } from '@lingui/macro';

import { ImageSlideShowItemType } from 'strat/image';
import { PhotoGalleryDialog } from 'strat/property/gallery';
import { PropertyPhotoData } from 'strat/property';

type Props = {
    photos: PropertyPhotoData[];
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
};

const UnitFloorPlanGalleryDialog = ({ photos, visible, onVisibilityChanged }: Props) => {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const slides = React.useMemo(() => {
        return photos.map((photo) => ({
            ...photo,
            type: ImageSlideShowItemType.PHOTO,
        }));
    }, [photos]);

    return (
        <PhotoGalleryDialog
            photos={slides}
            slideIndex={activeIndex}
            visible={visible}
            onCloseButtonClick={() => onVisibilityChanged(false)}
            onSlide={setActiveIndex}
            closeButtonLabel={<Trans>Go back</Trans>}
            withOverlay
        />
    );
};

export default UnitFloorPlanGalleryDialog;
