import React from 'react';
import { Trans } from '@lingui/macro';

import { Flex } from 'strat/components';

import styles from './styles/completionPercentageBadge.cssm';

type Props = {
    percentage: number;
};

const CompletionPercentageBadge = ({ percentage }: Props) => (
    <Flex alignCenter className={styles.badge}>
        <Trans>{Math.trunc(percentage)}% Completed</Trans>
    </Flex>
);

export default CompletionPercentageBadge;
