import { PropertyType } from 'strat/property/types';

import * as PackedListing from './packedListing';
import { unpackListing } from './selectors';

const getFeaturesGroupedByCoordinates = (
    listingType: Values<typeof PropertyType>,
    packedListings: Array<PackedListing.PackedListingType>,
) => {
    const groupedPackedListings = (packedListings || []).reduce<Record<string, any>>(
        (acc, packedListing) => {
            const key = `${PackedListing.longitude(packedListing)},${PackedListing.latitude(
                packedListing,
            )}`;
            if (key in acc) {
                acc[key].push(packedListing);
            } else {
                acc[key] = [packedListing];
            }

            return acc;
        },
        {},
    );

    return Object.keys(groupedPackedListings).reduce<Array<any>>((acc, key) => {
        const coordinates = PackedListing.coordinates(groupedPackedListings[key][0]);
        // @ts-expect-error - TS7006 - Parameter 'packedListing' implicitly has an 'any' type.
        const listings = groupedPackedListings[key].map((packedListing) =>
            unpackListing(listingType, packedListing),
        );
        const id = listings.reduce(
            // @ts-expect-error - TS7006 - Parameter 'maxId' implicitly has an 'any' type. | TS7006 - Parameter 'listing' implicitly has an 'any' type.
            (maxId, listing) => (maxId < listing.id ? listing.id : maxId),
            0,
        );

        acc.push({
            type: 'Feature',
            id,
            properties: {
                listing_count: listings.length,
                listings: JSON.stringify(listings),
                lng: coordinates[0],
                lat: coordinates[1],
            },
            geometry: {
                type: 'Point',
                coordinates,
            },
        });
        return acc;
    }, []);
};

export const convertPackedListingsToFeatureCollection = (
    listingType: Values<typeof PropertyType>,
    packedListings: Array<PackedListing.PackedListingType>,
) => ({
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: getFeaturesGroupedByCoordinates(listingType, packedListings),
    },
});
