import * as React from 'react';
import type { FormikState } from 'formik';
import { makeCancelable } from 'strat/util';
import { useActiveUser } from 'strat/user/session';

import { StratAPI } from 'horizontal/api';

import type { BillingInfo } from '../types';
import { CustomerType } from '../types';

type OnSubmitProps = {
    setSubmitting: (arg1: boolean) => void;
    // @ts-expect-error - TS2314 - Generic type 'FormikState<Values>' requires 1 type argument(s).
    resetForm: (arg1: FormikState) => void;
};

const useBillingInfo = (
    setBusinessValidationSchema: (businessValidationSchema: boolean) => void,
) => {
    const user = useActiveUser();
    const userExternalID = user?.externalID;

    const [loading, setIsLoading] = React.useState(true);
    const [billingInfo, setBillingInfo] = React.useState({
        customerType: '',
        email: '',
        customerName: '',
        businessName: '',
        phoneNumber: '',
        addressLineOne: '',
        addressLineTwo: '',
        state: '',
        city: '',
        vat_number: '',
    });

    const getBillingInfo = React.useCallback(() => {
        setIsLoading(true);
        // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        const cancelablePromise = makeCancelable(new StratAPI().billingInfo(userExternalID));

        cancelablePromise.then(({ data, status }) => {
            setIsLoading(false);
            if (status === 404) {
                return;
            }
            if (data.customerType === CustomerType.BUSINESS) {
                setBusinessValidationSchema(true);
            }
            setBillingInfo(data);
        });
    }, [setIsLoading, setBusinessValidationSchema, setBillingInfo, userExternalID]);

    const onSubmit = React.useCallback(
        (values: BillingInfo, { setSubmitting, resetForm }: OnSubmitProps) => {
            if (!user) {
                setSubmitting(false);
                return;
            }

            const billingInfoBody = {
                customer_type: values.customerType,
                email: values.email,
                customer_name: values.customerName,
                business_name: values.businessName,
                phone_number: values.phoneNumber,
                address_line_one: values.addressLineOne,
                address_line_two: values.addressLineTwo,
                state: values.state,
                city: values.city,
                vat_number: values.vatNumber,
                commercial_number: values.commercialNumber,
            } as const;

            const cancelablePromise = makeCancelable(
                // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                new StratAPI().saveBillingInfo(userExternalID, billingInfoBody),
            );
            cancelablePromise
                .then((response) => {
                    if (response?.status === 200) {
                        resetForm(response.data);
                    }
                    setSubmitting(false);
                })
                .catch(() => {
                    resetForm(billingInfo);
                    setSubmitting(false);
                });
        },
        [user, userExternalID, billingInfo],
    );

    return {
        getBillingInfo,
        billingInfo,
        loading,
        onSubmit,
    };
};

export default useBillingInfo;
