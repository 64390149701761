import * as React from 'react';
import classNames from 'classnames';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Text } from 'strat/components';

import type { Agency } from 'horizontal/types';
import { NoImage } from 'horizontal/image';

import styles from './styles/featuredAgenciesCard.cssm';

type Props = {
    reversed: boolean;
    agency: Agency;
};

const FeaturedAgenciesCard = ({ reversed, agency }: Props) => {
    return (
        <Link
            key={agency.externalID}
            route={RouteNames.AGENCY_PROFILE}
            params={{ agencySlug: agency.slug }}
            className={classNames(styles.agencyCard, {
                [styles.reverse]: reversed,
            })}
        >
            {agency.logo?.id ? (
                <ThumbnailImage
                    imageID={agency.logo.id}
                    size={ThumbnailSizes.LARGE}
                    alt={agency.logo.name}
                    title={agency.logo.name}
                    label="Agency logo"
                    className={classNames(styles.image)}
                    pictureClassName={styles.picture}
                    lazyLoad
                />
            ) : (
                <NoImage className={styles.image} />
            )}

            <Text.Base bold className={styles.agencyName}>
                {agency.name}
            </Text.Base>
        </Link>
    );
};

export default FeaturedAgenciesCard;
