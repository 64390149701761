import type { Location } from 'horizontal/types';

import useFetchLocationFacets from './useFetchLocationFacets';
import { LOCATION_DROPDOWN_POPULAR_LOCATIONS_COUNT } from './constants';

const usePopularLocations = (
    activeLocation: Location,
    locations: Array<Location>,
): Array<Location> => {
    const locationFacets = useFetchLocationFacets(activeLocation) || [];
    // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'never'.
    const popularLocationFacetsIds = locationFacets.map((facet) => facet?.value.id);
    const popularLocationsWithCount = locations
        .filter((loc) => popularLocationFacetsIds.includes(loc.id))
        .map((loc) => ({
            location: loc,
            // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'never'. | TS2339 - Property 'count' does not exist on type 'never'.
            count: locationFacets.find((facet) => facet?.value.id === loc.id)?.count || 0,
        }));

    popularLocationsWithCount.sort((a, b) => b.count - a.count);

    return popularLocationsWithCount
        .map((locationWithCount) => locationWithCount.location)
        .slice(0, LOCATION_DROPDOWN_POPULAR_LOCATIONS_COUNT);
};

export default usePopularLocations;
