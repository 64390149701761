import { matchChoiceValue } from 'strat/util';

import type { CategoryFieldChoice, FlatCategoryField } from 'horizontal/types';

const hasPriceTypeFieldValue = (
    priceTypeField: FlatCategoryField | null | undefined,
    value: string,
) =>
    !!priceTypeField?.choices?.find((choice: CategoryFieldChoice) =>
        matchChoiceValue(choice, value),
    );

export default hasPriceTypeFieldValue;
