import { t } from '@lingui/macro';
import * as React from 'react';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { getTimeAsDate, getTimeAsHour, isToday } from 'strat/util';

type Props = {
    timestamp: number;
};

const Timestamp = ({ timestamp }: Props) => {
    const i18n = useI18n();

    const displayableTime = isToday(timestamp)
        ? `${t(i18n)`Today`} ${getTimeAsHour(timestamp, i18n.locale)}`
        : getTimeAsDate(timestamp, i18n.locale);

    return <Text.Small>{displayableTime}</Text.Small>;
};

export default Timestamp;
