import * as React from 'react';

import type { AdPhoto, MixedAd } from 'horizontal/types';
import { AdFormat } from 'horizontal/types';

const useAdPhotos = (
    ad?: MixedAd | null,
): [AdPhoto | null | undefined, Array<AdPhoto> | null | undefined, number] => {
    const photoTitle = React.useCallback(
        (index) => {
            if (!ad) {
                return undefined;
            }

            return `${ad.title} ${index}`;
        },
        [ad],
    );

    if (!ad) {
        return [null, null, 0];
    }

    if (ad.format === AdFormat.LITE) {
        // @ts-expect-error - TS2339 - Property 'coverPhoto' does not exist on type 'MixedAd'. | TS2339 - Property 'coverPhoto' does not exist on type 'FullAd'.
        return [ad.coverPhoto, [], ad.photoCount];
    }

    const photos = (ad.photos || []).map((photo, index) => ({
        ...photo,
        title: photoTitle(index),
    }));

    const coverPhoto = photos.find((photo) => photo.main) || photos[0] || null;

    return [coverPhoto, photos, photos.length];
};

export default useAdPhotos;
