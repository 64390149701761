import * as React from 'react';
import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { useRouter } from 'react-true-router';

import CommonHead from 'horizontal/pages/commonHead';

const VerticalPropertiesHead = () => {
    const i18n = useI18n();
    const router = useRouter();
    const domainName = settings.getDomainName(i18n);
    const countryName = settings.countryName(i18n);
    return (
        <>
            <title>{t(i18n)`Properties for Sale and to Rent | ${domainName}`}</title>
            <meta
                name="description"
                content={t(
                    i18n,
                )`Dubizzle ${countryName} (formerly OLX) offers a variety of real estate listings all over ${countryName}. Apartments, villas, houses, offices & more. Buy or rent your next property!`}
            />
            <CommonHead url={router?.context?.match?.url} />
        </>
    );
};

export default VerticalPropertiesHead;
