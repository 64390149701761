import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { Choice } from 'strat/components/types';

import { FilterableDropdown } from 'horizontal/components';
import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';

import styles from '../styles/searchForm.cssm';

type Props = {
    readonly value: string;
    readonly setValue: (categoryExternalID: string) => void;
    readonly choices: Array<Choice<string>>;
    readonly titleCustomClass?: string;
    readonly disabled?: boolean;
};

const PropertyTypeFilter = ({
    value,
    setValue,
    choices,
    titleCustomClass = styles.filterTitle,
    disabled,
}: Props) => {
    const i18n = useI18n();

    const renderSelectionIcon = (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => (
        <IconArrowDown
            onClick={() => setIsOpen(!isOpen)}
            className={isOpen ? styles.iconArrowRotated : styles.iconArrow}
        />
    );

    return (
        <FilterableDropdown
            title={t(i18n)`Property type`}
            placeholder={value && !disabled ? '' : t(i18n)`All`}
            titleCustomClass={titleCustomClass}
            dropdownTriggerIcon={renderSelectionIcon}
            name={'selectedCategory'}
            dropdownTriggerCustomClass={classNames(styles.input, { [styles.disabled]: disabled })}
            choicesContainerCustomClass={styles.filterableDropdownContent}
            onChange={setValue}
            value={value}
            choices={choices}
        />
    );
};
export default PropertyTypeFilter;
