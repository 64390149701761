import { createSelector } from 'reselect';

import { selectDecodedListingsForClustering, selectIsochrones } from '../selectors';

import { computeCommuteTimes } from './computeCommuteTimes';
import { DEFAULT_COMMUTE_TIME } from './defaultCommuteTime';

export const selectCommuteTimes = createSelector(
    selectIsochrones,
    selectDecodedListingsForClustering,
    computeCommuteTimes,
);

export const selectCommuteSortBy = (state: any) => state.map.commuteSortBy;
export const selectCommuteMaxTime = (state: any) =>
    state.map.commuteMaxTime || DEFAULT_COMMUTE_TIME;
