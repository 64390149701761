/**
 * Manually set body height for active popups for ios15
 * In order to keep the popup listener logic valid you have to:
 * 1. toggle listeners to TRUE on component mount, ONLY if it is VISIBLE/ACTIVE
 * 2. toggle listeners on VISIBILITY change
 * 3. toggle listeners to FALSE on component unmount, ONLY if it is VISIBLE/ACTIVE
 */

/**
 * How many popups are currently active
 */
let popupCount = 0;

/**
 * Use percent of window height as viewport unit for the body
 */
const freezeBodySize = () => {
    const vh = window.innerHeight * 0.01;
    const body = document.querySelector('body');
    if (body) {
        body.style.setProperty('--vh', `${vh}px`);
    }
};

/**
 * Scroll when closing the on screen keyboard to realign input tap area
 */
const onBlur = () => {
    document.activeElement?.scrollIntoView();
};

/**
 * Scroll when closing the on screen keyboard to realign input tap area, after current call stack finished executing
 */
const iOsBlurHack = () => {
    setTimeout(onBlur, 0);
};

/**
 * Toggle listener
 * @param listenerEnabled whether to listen(popup is active) or not(popup is inactive)
 */
const toggleViewportHeightFreeze = (listenerEnabled: boolean) => {
    if (listenerEnabled) {
        popupCount += 1;
        freezeBodySize();

        // Fix tap areas after opening on screen keyboard on iOS 15
        // Do it for every toggle, because the DOM might change
        document.querySelectorAll('input, textarea').forEach((item) => {
            if (item.className.includes('applyIosBlurHack')) {
                // Scroll after the current call stack is finished executing.
                // On safari iOS there is some race condition that makes AutoCompleteItem miss selection
                // (onClick is never triggered) on the bottom part of the screen when scrollIntoView() also happens
                // Agency filter is affected because it is one of the last filters
                // in the filter screen that has autocomplete hits
                item.addEventListener('blur', iOsBlurHack);
            } else {
                item.addEventListener('blur', onBlur);
            }
        });
    } else {
        // Keep counter positive
        // If a component is not visible during mount it will not get incremented
        // But it can get decremented at unmount, causing a net negative count
        popupCount = Math.max(0, popupCount - 1);

        // Do not reset height if there still is a popup open
        if (popupCount >= 1) {
            return;
        }

        const body = document.querySelector('body');
        if (body) {
            body.style.removeProperty('--vh');
        }

        document.querySelectorAll('input, textarea').forEach((item) => {
            if (item.className.includes('applyIosBlurHack')) {
                item.removeEventListener('blur', iOsBlurHack);
            } else {
                item.removeEventListener('blur', onBlur);
            }
        });
    }
};

export default toggleViewportHeightFreeze;
