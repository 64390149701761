import { t } from '@lingui/macro';
import React from 'react';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import useI18n from 'strat/i18n/language/useI18n';

import styles from './styles/deliveryCheckboxLabel.cssm';

const DeliveryCheckboxLabel = () => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <span>
            <Trans>
                {`I have read and agree to the `}
                <a
                    href={t(i18n)`delivery_url`}
                    target="_blank noopener noreferrer"
                    className={styles.link}
                >
                    {`Terms & Conditions `}
                </a>
                {`of ${brandName} delivery`}
            </Trans>
        </span>
    );
};

export default DeliveryCheckboxLabel;
