//@ts-nocheck
import isNil from 'lodash/isNil';

const priceScore = (price: any, unitPrice: any) => {
    if (!isNil(price.min) && !isNil(price.max)) {
        return unitPrice.min >= price.min && unitPrice.max <= price.max ? 1 : 0;
    }

    if (!isNil(price.max)) {
        return unitPrice.max <= price.max ? 1 : 0;
    }

    if (!isNil(price.min)) {
        return unitPrice.min >= price.min ? 1 : 0;
    }

    return 0;
};

const areaScore = (area: any, unitArea: any) => {
    if (!isNil(area.min) && !isNil(area.max)) {
        if (
            Math.floor(area.min) === Math.floor(unitArea.min) &&
            Math.floor(area.max) === Math.floor(unitArea.max)
        ) {
            return 3;
        }

        if (unitArea.min > area.min && unitArea.max < area.max) {
            return 2;
        }

        return 0;
    }

    if (!isNil(area.max)) {
        return unitArea.max <= area.max ? 1 : 0;
    }

    if (!isNil(area.min)) {
        return unitArea.min >= area.min ? 1 : 0;
    }

    return 0;
};

const bedsScore = (beds: any, unitBeds: any) => {
    if (!unitBeds) {
        return 0;
    }

    if (!beds) {
        return 0;
    }

    const minBeds = Math.min(...beds.map((bed) => parseInt(bed, 10)));
    const maxBeds = Math.max(...beds.map((bed) => parseInt(bed, 10)));

    return unitBeds >= minBeds && unitBeds <= maxBeds ? 1 : 0;
};

const scoreUnit = (unit: any, filters: any) => {
    const { priceFilter, areaFilter, bedsFilter } = filters;

    let score = 0;

    const minPrice = unit.priceMin || unit.priceMax;
    const maxPrice = unit.priceMax || unit.priceMin;
    score += priceScore(priceFilter, { min: minPrice, max: maxPrice });

    const minArea = unit.areaMin || unit.areaMax;
    const maxArea = unit.areaMax || unit.areaMin;

    score += areaScore(areaFilter, { min: minArea, max: maxArea });
    score += bedsScore(bedsFilter, unit.rooms);

    return score;
};

const scoreUnits = (units: any, filters: any) =>
    units.map((unit) => ({ score: scoreUnit(unit, filters), unit }));

export default scoreUnits;
