import brandingSettings from '@app/branding/settings';

import { SearchPageSearchType } from '../types';

const getHitsPerPage = (searchPageType: Values<typeof SearchPageSearchType>) => {
    switch (searchPageType) {
        case SearchPageSearchType.PAID_FIRST:
        case SearchPageSearchType.PAID_FIRST_SLOTTED:
        case SearchPageSearchType.CIRCLE_BASED_SLOTTING:
            return 20 + Math.max(brandingSettings.eliteAdsPerPageCount, 0);
        default:
            return 45 + Math.max(brandingSettings.eliteAdsPerPageCount, 0);
    }
};

export default getHitsPerPage;
