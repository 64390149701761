import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import Header from './header';

const PaymentSelectionFreeAdUndertakingHeader = () => {
    const router = useRouter();

    const onBack = React.useCallback(() => {
        router.pushRoute(RouteNames.AD_MANAGEMENT);
    }, [router]);

    return <Header onBack={onBack} />;
};

export default PaymentSelectionFreeAdUndertakingHeader;
