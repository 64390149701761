import { languages } from '@app/branding/languages';

const translationURL = (language: string) => {
    return (
        __webpack_public_path__ +
        `locale/${language}/main.messages.${process.env.BUILD_COMMIT_HASH}.js`
    );
};

export const translationURLs = (language: string) => {
    if (!CONFIG.build.LOCALIZED_ROUTES) {
        return [translationURL(language)];
    }

    return languages.map((language) => translationURL(language.lang));
};
