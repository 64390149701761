import { BayutCompat, asNumberOrString } from 'strat/util';
import { Unit, Area } from 'strat/i18n';
import Purpose from 'strat/purpose';
import type { PropertyData } from 'strat/property/types';
import { PropertyProduct, PropertyType, PropertyState } from 'strat/property/types';
import { propertyAdditionalGTMVars } from '@app/branding/additionalGTMVars';
import getCategoryVariables from '@app/gtm/getCategoryVariables';

import joinList from './joinList';
import getProjectOrPropertyID from './getProjectOrPropertyID';
import getPropertyBadges from './getPropertyBadges';
import getSellerType from './getSellerType';
import getLocationsVariables from './getLocationsVariables';
import getTrackingLocationID from './getTrackingLocationID';
import getConditionFromCompletionStatus from './getConditionFromCompletionStatus';
import getPropertyType from './getPropertyType';
import getSecondaryLocationsVariables from './getSecondaryLocationsVariables';
import getContentPVTF from './getContentPVTF';

/**
 * Creates all the possible GTM variables you could ever need
 * for the specified property.
 */
export default (property: PropertyData, _: string | null = null): any => {
    const CITY_LEVEL = CONFIG.build.CITY_LEVEL;

    const locationHierarchy =
        (property.locations?.length
            ? property.locations.filter((loc) => loc.isMain)
            : property.location) || [];
    const location = locationHierarchy.slice(-1)[0] || null;
    const category = property.category[property.category.length - 1] || null;

    const city = property.location[CITY_LEVEL] || null;
    const locality = property.location[CITY_LEVEL + 1] || null;
    const propertyID = getProjectOrPropertyID(property);
    const agencyID = property.agency ? asNumberOrString(property.agency.externalID) : null;
    const locationID = location ? getTrackingLocationID(location) : null;
    const categoryID = category ? asNumberOrString(category.externalID) : null;
    const localityID = locality ? getTrackingLocationID(locality) : null;
    const locationBreadcrumb = locationHierarchy.map(getTrackingLocationID);
    const area = property.area ? new Unit(property.area, Area, Area.SQM).convert(Area.SQFT) : null;
    const rentFrequency = property.rentFrequency;
    const propertyBadges = getPropertyBadges(property);

    const variables: {
        [key: string]: any;
    } = {
        // PROPERTY ID
        ad_id: propertyID,
        object_id: propertyID,
        listingid: propertyID,
        listing_id: propertyID,

        // PROPERTY BADGES
        property_badge: propertyBadges,

        // PROPERTY TYPE
        listing_state: property.state || PropertyState.ACTIVE,
        listing_type_name: property.type || PropertyType.PROPERTY,

        // PURPOSE
        purpose: BayutCompat.Purpose.textAsVerb(property.purpose),
        purpose_id: BayutCompat.Purpose.toID(property.purpose),
        ...(property.purpose === Purpose.FOR_RENT
            ? { furnishing_status: property.furnishingStatus }
            : { completion_status: property.completionStatus }),

        // RENT FREQUENCY
        rent_frequency: rentFrequency
            ? rentFrequency[0].toUpperCase() + rentFrequency.slice(1)
            : null,

        // AGENCY
        ag: agencyID,
        agent_id: agencyID,
        agent_ids: agencyID,
        agencyid: agencyID,
        seller_type: getSellerType(property),
        company_ids: agencyID,
        seller_ids: asNumberOrString(property.ownerID),

        // USER
        owner_user_id: asNumberOrString(property.ownerID),

        // LOCATION
        cat_id: locationID,
        locality: localityID,
        loc_breadcrumb: joinList(locationBreadcrumb),
        loc_city_id: joinList(city ? [getTrackingLocationID(city)] : []),
        loc_city_name: joinList(city ? [city.name] : []),
        loc_id: joinList(location ? [locationID] : []),
        loc_name: joinList(location ? [location.name] : []),
        loc_neighbourhood_id: joinList(locality ? [getTrackingLocationID(locality)] : []),
        loc_neighbourhood_name: joinList(locality ? [locality.name] : []),
        city_id: localityID || locationID,
        city_name: (locality && locality.name) || (city && city.name) || null,
        // @ts-expect-error - TS2740 - Type '{ hierarchy: LocationNodeData[]; }' is missing the following properties from type 'LocationNodeData': id, externalID, name, slug, and 6 more.
        ...getLocationsVariables([{ hierarchy: locationHierarchy }]),
        ...(property.locations?.length ? getSecondaryLocationsVariables(property.locations) : {}),

        // CATEGORY
        listing_type: categoryID,
        listing_type_id: categoryID,
        property_type: getPropertyType(property),
        ...getCategoryVariables(property.category),

        // BEDS, BATHS, AREA
        property_beds: property.rooms,
        property_land_area: area,
        property_price: property.price,
        property_baths_list: [property.baths],
        property_beds_list: [property.rooms],
        property_area: property.area,

        // GEOGRAPHY
        latitude: property.geography ? property.geography.lat : null,
        longitude: property.geography ? property.geography.lng : null,

        // REFERENCE
        reference_id: property.referenceNumber || null,
        referrer_listing_id: null,

        // PACKAGE / PRODUCT
        // do not change to booleans, makes bayut's legacy stats
        // recording crash
        package_type: property.product,
        package_hot: property.product === PropertyProduct.HOT ? 1 : 0,
        package_free: property.product === PropertyProduct.FREE ? 1 : 0,
        package_paid: property.product === PropertyProduct.PAID ? 1 : 0,
        package_superhot: property.product === PropertyProduct.SIGNATURE ? 1 : 0,

        // PHOTOS
        is_active: '1',
        has_image: property.photoCount ? '1' : '0',
        number_of_photos: property.photoCount,

        // VIDEOS
        number_of_videos: property.videoCount,

        // TITLE
        listing_title: property.title,

        condition: getConditionFromCompletionStatus(property.completionStatus),

        // CONTENT: PHOTOS, VIDEOS, TOURS, FLOOR PLANS
        content_pvtf: getContentPVTF(property),
    };

    if (property.purpose !== Purpose.FOR_RENT) {
        delete variables.rent_frequency;
    }

    if (CITY_LEVEL > 1) {
        const region = locationHierarchy[CITY_LEVEL - 1] || null;
        variables.loc_region_id = joinList(region ? [region.externalID] : []);
        variables.loc_region_name = joinList(region ? [region.name] : []);
    }

    if (property.price) {
        variables.price = property.price;
    }

    return {
        ...variables,
        ...propertyAdditionalGTMVars(property),
    };
};
