import * as React from 'react';

import { ChatNegotiationState } from 'horizontal/chat/constants';
import type { ActionButton } from 'horizontal/chat/types';

import Accepted from '../accepted';

import BuyerMadeOffer from './buyerMadeOffer';
import Initiated from './initiated';
import Rejected from './rejected';
import SellerMadeOffer from './sellerMadeOffer';

type Props = {
    readonly negotiationState: ChatNegotiationState;
    readonly actionButtons: Array<ActionButton>;
    readonly adPrice: number;
    readonly lastOfferPrice: number;
    readonly lastRejectedPrice: number | null | undefined;
};

const MakeOffer = ({
    negotiationState,
    actionButtons,
    adPrice,
    lastOfferPrice,
    lastRejectedPrice,
}: Props) => {
    switch (negotiationState) {
        case ChatNegotiationState.BUYER_MADE_OFFER:
            return (
                <BuyerMadeOffer
                    actionButtons={actionButtons}
                    adPrice={adPrice}
                    lastOfferPrice={lastOfferPrice}
                />
            );
        case ChatNegotiationState.SELLER_MADE_OFFER:
            return lastRejectedPrice ? (
                <SellerMadeOffer
                    lastOfferPrice={lastOfferPrice}
                    lastRejectedPrice={lastRejectedPrice}
                />
            ) : null;
        case ChatNegotiationState.OFFER_REJECTED:
            return lastRejectedPrice ? <Rejected lastRejectedPrice={lastRejectedPrice} /> : null;
        case ChatNegotiationState.ACCEPTED:
            return <Accepted actionButtons={actionButtons} lastOfferPrice={lastOfferPrice} />;
        case ChatNegotiationState.INITIATED:
        default:
            return <Initiated actionButtons={actionButtons} />;
    }
};

export default MakeOffer;
