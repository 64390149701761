import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { DialogWithHeader, Text } from 'strat/components';

import type { AddressInfo } from 'horizontal/userOrders/types';
import IconPlusCircle from 'horizontal/assets/icons/iconPlusCircle.svg';
import AddAddressForm from 'horizontal/userOrders/addresses/addressBook';

import styles from './styles/chooseAddress.cssm';
import FormSection from './formSection';
import ChooseAddressDropdown from './chooseAddressDropdown';

type Props = {
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly addressBook: AddressInfo[];
    readonly defaultAddress: AddressInfo | null;
    readonly onNewAddressAdded?: () => void;
    readonly fieldName?: string;
};

type AddAddressLinkProps = {
    readonly onClick: () => void;
};

const AddAddressLink = ({ onClick }: AddAddressLinkProps) => {
    const i18n = useI18n();

    return (
        <div className={styles.addAddress} onClick={onClick}>
            <IconPlusCircle />
            {t(i18n)`Add Address`}
        </div>
    );
};

type AddressDialogProps = {
    readonly visible: boolean;
    readonly setVisible: (arg1: boolean) => void;
    readonly onNewAddressAdded?: () => void;
};

const AddAddressDialog = ({ visible, setVisible, onNewAddressAdded }: AddressDialogProps) => {
    const i18n = useI18n();
    const close = () => setVisible(false);

    const dialog = (
        <DialogWithHeader
            visible={visible}
            onVisibilityChanged={setVisible}
            title={t(i18n)`Add a New Address`}
            onBack={close}
            className={styles.dialogContainer}
        >
            <AddAddressForm closeDialog={close} getAddressList={onNewAddressAdded} />
        </DialogWithHeader>
    );
    return dialog;
};

const ChooseAddressSection = (props: Props) => {
    const [visible, setVisible] = React.useState(false);
    const {
        values,
        errors,
        touched,
        setFieldValue,
        addressBook,
        defaultAddress,
        onNewAddressAdded,
    } = props;
    const openAddAddressDialog = () => setVisible(true);

    return (
        <FormSection title={<Trans>Delivery address</Trans>}>
            <>
                <ChooseAddressDropdown
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    addressBook={addressBook}
                    defaultAddress={defaultAddress}
                />
                <Text.Regular>
                    <Trans>* Only the listed addresses support delivery</Trans>
                </Text.Regular>
                <AddAddressLink onClick={openAddAddressDialog} />
                <AddAddressDialog
                    visible={visible}
                    setVisible={setVisible}
                    onNewAddressAdded={onNewAddressAdded}
                />
            </>
        </FormSection>
    );
};

export default ChooseAddressSection;
