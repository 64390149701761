const computeTexthash = async (text: string): Promise<string> => {
    const subtle = globalThis?.crypto?.subtle || window.crypto.subtle;
    const encoder = new TextEncoder();

    const hashBuffer = await subtle.digest('SHA-256', encoder.encode(text));
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
};

export default computeTexthash;
