import * as React from 'react';
import type { OptionalSessionTokenData, SessionData } from '@sector-labs/fe-auth-redux';
import { useStoreSession } from '@sector-labs/fe-auth-redux/thunk';

import { useKeycloakSiteConfigWithCallbacks } from './useKeycloakSiteConfig';

type OptionalSessionData = OptionalSessionTokenData & {
    userProfile?: SessionData['userProfile'];
};

const useKeycloakStoreSession = () => {
    const siteConfig = useKeycloakSiteConfigWithCallbacks();
    const storeSession = useStoreSession();

    return React.useCallback(
        (tokenData: OptionalSessionData | undefined) => {
            const { accessToken, refreshToken, idToken } = tokenData ?? {};

            if (!accessToken || !refreshToken || !idToken) {
                return;
            }

            storeSession(siteConfig, tokenData as SessionData);
        },
        [storeSession, siteConfig],
    );
};

export default useKeycloakStoreSession;
