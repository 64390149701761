import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';

import { selectPageType } from 'horizontal/sitemap/state/selectors';
import { SitemapPageTypeName } from 'horizontal/sitemap';

const SitemapHead = () => {
    const i18n = useI18n();
    const selectedSitemapCategory = useSelector(selectPageType);
    const categoryName = SitemapPageTypeName.choices(i18n)[selectedSitemapCategory] || '';

    return <title>{t(i18n)`Sitemap ${categoryName}`}</title>;
};

export default SitemapHead;
