import type { Language } from 'strat/branding/types';

export const languages: Language[] = [
    {
        lang: 'ar',
        name: 'العربية',
        rtl: true,
        locales: 'ar-AE',
        dateLocales: 'ar-AE',
        numberFormattingLocale: 'en-UK',
    },
    {
        lang: 'en',
        name: 'English',
        locales: 'en-UK',
    },
];
