import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import EMPTY_ARRAY from 'strat/empty/array';
import { selectGeoLocationData } from 'strat/user/selectors';
import { Choice } from 'strat/components/types';
import useLocationSuggestions from 'strat/search/useLocationSuggestions';

import {
    CurrentLocationSelector,
    useDisabledLocations,
    filterDisabledLocations,
} from 'horizontal/search/location';
import { getCategoryIDsDescendants } from 'horizontal/search/location/disabledLocations';
import { selectCategories } from 'horizontal/categories';
import { selectLocationNameWithParent } from 'horizontal/location';
import AdCreationContext from 'horizontal/adCreation/context';
import type { AppDispatch } from 'horizontal/state';
import type { FilterableDropdownProps } from 'horizontal/components';

import type { Location } from '../types';
import { DropdownChoice, FilterableDropdownBase } from '../components/dropdown';

import styles from './styles/locationRegionDropdown.cssm';
import useRecentLocations from './useRecentLocations';
import addNewRecentLocation from './addNewRecentLocation';

interface Props extends Omit<FilterableDropdownProps<Location['id']>, 'onChange' | 'value'> {
    onLocationChoiceSelected: (locationID: Location['id']) => void;
    onFullLocationSelected: (Location: Location) => void;
    value: Location['id'] | string;
    formatPlaceholder?: (title?: string) => string;
}

const LocationRegionDropdown = ({
    title,
    description,
    name,
    accepted,
    onFullLocationSelected,
    onLocationChoiceSelected,
    value,
    choices,
    stretch,
    readOnly,
    formatPlaceholder,
    variant,
    lightPlaceholder,
    boldLabel,
    errorMessage,
    isMandatory,
}: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState<string | null>(null);
    const [loading, setIsLoading] = React.useState(false);

    const i18n = useI18n();
    const [selectedValueLabel, setSelectedValueLabel] = React.useState(value);
    const [isCurrentLocation, setIsCurrentLocation] = React.useState(false);
    const { closestLocation, error: geoLocationError } = useSelector(selectGeoLocationData);
    const [showGeolocationError, setShowGeolocationError] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const categories = useSelector(selectCategories);

    const maximumAllowedLocations = 3;
    const recentLocations = useRecentLocations(maximumAllowedLocations, i18n);
    const [activeCategory] = React.useContext(AdCreationContext);
    const locationDepthLimits = activeCategory?.locationDepthLimits;

    React.useEffect(() => {
        if (value) {
            setSelectedValueLabel(value);
        }
    }, [value]);

    const recentChoicesValues = React.useMemo(() => {
        return filterDisabledLocations(recentLocations, categories, activeCategory)
            ?.map((location: Location) => {
                return {
                    hasChildren: location.hasChildren,
                    level: location.level,
                    id: location?.id,
                    label: selectLocationNameWithParent(i18n, location.hierarchy),
                    value: location?.id,
                };
            })
            .filter((location) => location.level <= (locationDepthLimits?.max || Infinity));
    }, [recentLocations, i18n, locationDepthLimits, activeCategory, categories]);

    const suggestedLocations = useLocationSuggestions(searchQuery ?? '') as Array<Location>;
    const filteredLocationSuggestions = useDisabledLocations(
        suggestedLocations,
        categories,
        activeCategory,
    );

    const querySuggestionChoices = React.useMemo((): Array<Choice<Location['id']>> => {
        return (
            filteredLocationSuggestions.map((location) => ({
                id: location.id,
                hasChildren: location.hasChildren,
                level: location.level,
                label: selectLocationNameWithParent(i18n, location.hierarchy),
                value: location.id,
            })) || EMPTY_ARRAY
        ).filter(
            (location) =>
                location.level <= (locationDepthLimits?.max || Infinity) &&
                location.level >= (locationDepthLimits?.min || 0),
        );
    }, [filteredLocationSuggestions, i18n, locationDepthLimits]);

    const filteredChoices = searchQuery ? querySuggestionChoices : choices;

    const selectedValue = React.useMemo(
        () =>
            value
                ? filteredChoices.find((location: Choice<Location['id']>) => location.id === value)
                : null,
        [filteredChoices, value],
    );

    const onCurrentLocationSelected = () => {
        setIsOpen(false);
        setIsLoading(true);
    };

    const onGeoPositionSelected = () => {
        setIsCurrentLocation(true);
    };

    React.useEffect(() => {
        if (isCurrentLocation && closestLocation) {
            const restrictedClosedLocationHiearchy = activeCategory
                ? // @ts-expect-error
                  closestLocation.hierarchy?.filter((item: Location) => {
                      if (!item.disabledCategoryIDs) {
                          return true;
                      }
                      const disabledCategoryIDs = getCategoryIDsDescendants(
                          item.disabledCategoryIDs,
                          categories,
                          true,
                      );
                      return !disabledCategoryIDs.includes(activeCategory.id);
                  })
                : closestLocation.hierarchy;
            const finalCurrentLocation =
                restrictedClosedLocationHiearchy[restrictedClosedLocationHiearchy.length - 1];
            addNewRecentLocation(closestLocation, dispatch);
            setIsCurrentLocation(false);
            setIsLoading(false);
            setShowGeolocationError(false);
            onFullLocationSelected({
                geography: null,
                ...finalCurrentLocation,
                hierarchy: restrictedClosedLocationHiearchy,
            });
        } else if (geoLocationError && loading) {
            setIsLoading(false);
            setShowGeolocationError(true);
        }
    }, [
        closestLocation,
        isCurrentLocation,
        dispatch,
        geoLocationError,
        loading,
        i18n,
        setIsLoading,
        onFullLocationSelected,
        activeCategory,
        categories,
    ]);

    const onOptionSelected = (locationID: Location['id']) => {
        setIsOpen(false);
        const selectedLocation = [...recentLocations, ...filteredLocationSuggestions].find(
            (location) => location.id === locationID,
        );
        setShowGeolocationError(false);

        if (!selectedLocation) {
            onLocationChoiceSelected(locationID);
            const regionSelectedValue = choices.find((location) => location.id === locationID);

            setSelectedValueLabel(regionSelectedValue?.label);
            return;
        }

        setSelectedValueLabel(selectLocationNameWithParent(i18n, selectedLocation.hierarchy));
        onFullLocationSelected(selectedLocation);
    };

    const renderSuggestions = () => {
        if (searchQuery) {
            return null;
        }

        return (
            <>
                <div className={styles.currentLocationSelector} onClick={onCurrentLocationSelected}>
                    <CurrentLocationSelector onGeoPositionSelected={onGeoPositionSelected} />
                </div>
                {recentChoicesValues.length > 0 ? (
                    <div className={styles.subtitle}>{t(i18n)`Recent`}</div>
                ) : null}
                {recentChoicesValues.map((choice) => (
                    <DropdownChoice
                        choice={choice}
                        onClick={onOptionSelected}
                        selected={choice.value === value}
                        key={choice.value}
                    />
                ))}
                <div className={styles.subtitle}>{t(i18n)`Choose region`}</div>
            </>
        );
    };

    return (
        <FilterableDropdownBase
            onChange={onOptionSelected}
            choices={filteredChoices}
            name={name}
            description={description}
            title={isMandatory ? title?.concat('*') : title}
            errorMessage={errorMessage ?? (showGeolocationError ? geoLocationError : null)}
            accepted={accepted}
            stretch={stretch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedValueLabel={selectedValueLabel || selectedValue?.label || ('' as any)}
            value={value as any}
            placeholder={formatPlaceholder?.(title)}
            readOnly={readOnly}
            renderSuggestions={renderSuggestions}
            variant={variant}
            lightPlaceholder={lightPlaceholder}
            boldLabel={boldLabel}
        />
    );
};

export default LocationRegionDropdown;
