import type { LocationNodeData } from 'strat/property/types';

/**
 * Basic metadata type.
 */
export type MetaData = {
    [key: string]: any;
};

/**
 * Available schema types
 */

type SchemaTypeT = {
    ITEM_PAGE: string;
    ITEM_LIST: string;
    LIST_ITEM: string;
    PRODUCT: string;
    GEO_COORDINATES: string;
    QUANTITATIVE_VALUE: string;
    POSTAL_ADDRESS: string;
    LOCATION_FEATURE_SPECIFICATION: string;
    OFFER: string;
    UNIT_PRICE_SPECIFICATION: string;
    REAL_ESTATE_AGENT: string;
    ORGANIZATION: string;
    PROFILE_PAGE: string;
    BREADCRUMB_LIST: string;
    CATEGORY_BASED: string;
    HOUSE: string;
    RESIDENCE: string;
    APARTMENT: string;
    PLACE: string;
    isCategoryBased: (arg1: string) => boolean;
};

const SchemaType: SchemaTypeT = Object.freeze({
    /**
     * Structured Data specific schema types
     */
    ITEM_PAGE: 'ItemPage',
    ITEM_LIST: 'ItemList',
    LIST_ITEM: 'ListItem',

    PRODUCT: 'Product',

    GEO_COORDINATES: 'GeoCoordinates',
    QUANTITATIVE_VALUE: 'QuantitativeValue',
    POSTAL_ADDRESS: 'PostalAddress',

    LOCATION_FEATURE_SPECIFICATION: 'LocationFeatureSpecification',

    OFFER: 'Offer',
    UNIT_PRICE_SPECIFICATION: 'UnitPriceSpecification',
    REAL_ESTATE_AGENT: 'RealEstateAgent',
    ORGANIZATION: 'Organization',

    PROFILE_PAGE: 'ProfilePage',
    BREADCRUMB_LIST: 'BreadcrumbList',

    /**
     * Category based schema types
     */
    CATEGORY_BASED: 'CategoryBased',
    HOUSE: 'House',
    RESIDENCE: 'Residence',
    APARTMENT: 'Apartment',
    PLACE: 'Place',

    isCategoryBased: (type: string): boolean => {
        return SchemaType.CATEGORY_BASED === type;
    },
});

type PostalAddressTagT = {
    ADDRESS_COUNTRY: string;
    ADDRESS_REGION: string;
    ADDRESS_LOCALITY: string;
    ADDRESS_NAME: string;
    getLocationAddressForTag: (arg1: string, arg2: Array<LocationNodeData>) => string;
    computeAddress: (arg1: Array<LocationNodeData>, arg2: number) => string | null;
    computeName: (arg1: Array<LocationNodeData>) => string;
};

const PostalAddressTag: PostalAddressTagT = Object.freeze({
    ADDRESS_COUNTRY: 'addressCountry',
    ADDRESS_REGION: 'addressRegion',
    ADDRESS_LOCALITY: 'addressLocality',
    ADDRESS_NAME: 'name',

    getLocationAddressForTag: (tag: string, locations: Array<LocationNodeData>): string => {
        // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'string'.
        return {
            [PostalAddressTag.ADDRESS_COUNTRY]: PostalAddressTag.computeAddress(locations, 0),
            [PostalAddressTag.ADDRESS_REGION]: PostalAddressTag.computeAddress(locations, 1),
            [PostalAddressTag.ADDRESS_LOCALITY]: PostalAddressTag.computeAddress(locations, 2),
            [PostalAddressTag.ADDRESS_NAME]: PostalAddressTag.computeName(locations),
        }[tag];
    },

    computeAddress: (locations: Array<LocationNodeData>, level: number): string | null => {
        if (locations.length <= level) {
            return null;
        }

        return locations[level].name;
    },

    computeName: (locations: Array<LocationNodeData>): string => {
        // contains a string with all addresses (except the country), separated with a space
        return locations
            .slice(1)
            .reverse()
            .map((location) => location.name)
            .join(' ');
    },
});

export { SchemaType, PostalAddressTag };
