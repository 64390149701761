import * as React from 'react';
import { useSearchFacetValues } from '@sector-labs/fe-search-redux/state';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { locationTransformer } from 'strat/mergedIndex';

import type { Location, LiteHierarchicalLocation } from 'horizontal/types';

type LocationLevelFacets = {
    count: number;
    location: Location;
};

const useLocationFacets = (
    level = 0,
    hierarchy: ReadonlyArray<Location | LiteHierarchicalLocation> = settings.topLevelLocation
        .hierarchy as ReadonlyArray<LiteHierarchicalLocation>,
    activeLocation?: LiteHierarchicalLocation | null,
): Array<LocationLevelFacets> => {
    const facets = useSearchFacetValues(`location.lvl${level}`);
    const language = useI18n().locale;

    return React.useMemo(
        () =>
            facets
                .map(({ count, value }) => ({
                    location: {
                        // @ts-expect-error - TS2698 - Spread types may only be created from object types.
                        ...value,
                        hierarchy: [...hierarchy.slice(0, level + 1), value],
                    },
                    count,
                }))
                .map(({ location, count }) => ({
                    count,
                    location: locationTransformer<Location>(
                        location,
                        language,
                        CONFIG.build.ENABLE_MERGED_INDEX,
                    ),
                }))
                .sort((a, b) => {
                    if (a.location.externalID === activeLocation?.externalID) {
                        return -1;
                    }

                    if (b.location.externalID === activeLocation?.externalID) {
                        return 1;
                    }

                    return 0;
                }),
        [hierarchy, level, facets, language, activeLocation],
    );
};

export default useLocationFacets;
