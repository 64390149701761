import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Input } from 'strat/components';

import CommonPostingFields from './commonPostingFields';

type Props = {
    setFieldValue: (field: keyof FormikValues, value?: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
    onBlur: (arg1: React.SyntheticEvent<any>) => void;
};

const DescriptionField = ({ setFieldValue, touched, errors, values, onBlur }: Props) => {
    const i18n = useI18n();

    return (
        <Input
            name={'description'}
            title={t(i18n)`Description`.concat('*')}
            description={t(i18n)`Include condition, features and reason for selling`}
            maxLength={4096}
            multiLine
            onChange={(value) => setFieldValue(CommonPostingFields.description.attribute, value)}
            onBlur={(event: React.ChangeEvent<any>) => {
                setFieldValue(
                    CommonPostingFields.description.attribute,
                    (event.target.value || '').trim(),
                );
                onBlur?.(event);
            }}
            value={values.description}
            errorMessage={touched.description && errors.description}
            accepted={touched.description && !errors.description}
            leftSideLabel
            placeholder={t(i18n)`Describe the item you’re selling`}
            lightPlaceholder
            boldLabel
        />
    );
};

export default DescriptionField;
