import * as React from 'react';

import { DialogScreen } from '../types';

import KeycloakAuthDialog from './keycloakAuthDialog';

type Props = React.ComponentProps<typeof KeycloakAuthDialog>;

const KeycloakRegisterDialog = ({ name, ...props }: Props) => (
    <KeycloakAuthDialog name={name || DialogScreen.REGISTER} {...props} />
);

export default KeycloakRegisterDialog;
