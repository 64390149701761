import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import { getSlotRadii } from '@sector-labs/strat-horizontal-core';
import Category from '@app/branding/category';

import { AdProduct } from 'horizontal/types';
import type { LiteAd, LiteHierarchicalLocation } from 'horizontal/types';

import type { UpdatedAdResponseProps } from '../types';
import updateResponseIndexToSlotIndex from '../common/updateResponseIndexToSlotIndex';
import { SearchJobName } from '../types';

const isHitPromoted = (hit: LiteAd): boolean =>
    hit.product === AdProduct.FEATURED || hit.product === AdProduct.ELITE;

const isHitInExactLocation = (hit: LiteAd, locationExternalID: string): boolean =>
    (hit.location || []).some((loc) => loc.externalID === locationExternalID);

const groupPageHits = (
    hits: any,
    location: LiteHierarchicalLocation,
    fallbackSlotThresholdIndex: number,
) => {
    // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
    const regularHits = hits.filter((hit) => !isHitPromoted(hit));
    // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
    const promotedHits = hits.filter((hit) => isHitPromoted(hit));

    // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
    let exactHitList = regularHits.filter((hit) => isHitInExactLocation(hit, location.externalID));
    const fallbackHitList = regularHits.filter(
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        (hit) =>
            !isHitInExactLocation(hit, location.externalID) &&
            hit.requestIndex < fallbackSlotThresholdIndex,
    );
    const additionalFallbackHitList = regularHits.filter(
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        (hit) =>
            !isHitInExactLocation(hit, location.externalID) &&
            hit.requestIndex >= fallbackSlotThresholdIndex,
    );

    // Always add promoted ads on top of page
    exactHitList = [...promotedHits, ...exactHitList];

    return { exactHitList, fallbackHitList, additionalFallbackHitList };
};

const updatedAdsResponse = ({
    searchHandler,
    location,
    category,
}: UpdatedAdResponseProps): SearchResponse | null => {
    const job = searchHandler.getJobByJobName(SearchJobName.REGULAR);
    const response = searchHandler.getResponseByJobName(SearchJobName.REGULAR);
    if (!job || !response) {
        return null;
    }
    const updatedResponse = updateResponseIndexToSlotIndex(job, response);
    // @ts-expect-error - TS2341 - Property 'options' is private and only accessible within class 'SearchJob'.
    const sortOption = job.options.sort;

    // we have one featured from location slot, one non-featured from location slot and
    // two times the number of the circle slots(featured and non-featured)
    // if Category isOfPropertySubtype then we do not fetch nearby FeaturedAds
    const fallbackSlotThresholdIndex = Category?.isOfPropertySubtype(category)
        ? 2 + getSlotRadii(sortOption).length
        : 2 + 2 * getSlotRadii(sortOption).length;

    const { exactHitList, fallbackHitList, additionalFallbackHitList } = groupPageHits(
        updatedResponse.hits,
        location,
        fallbackSlotThresholdIndex,
    );

    const hits = [
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        ...exactHitList.map((hit) => ({ ...hit, requestIndex: 0 })),
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        ...fallbackHitList.map((hit) => ({ ...hit, requestIndex: 1 })),
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        ...additionalFallbackHitList.map((hit) => ({ ...hit, requestIndex: 2 })),
    ];

    return { ...updatedResponse, hits };
};

export default updatedAdsResponse;
