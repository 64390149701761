import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

import type { GlobalState } from 'horizontal/state';

const selectUserAgencies = createSelector(
    (state: GlobalState) => state.userAgencies,
    (userAgencies) => userAgencies.data || EMPTY_ARRAY,
);

const selectUserAgenciesLoaded = createSelector(
    (state: GlobalState) => state.userAgencies,
    (userAgencies) => userAgencies.loaded,
);

export { selectUserAgencies, selectUserAgenciesLoaded };
