import * as React from 'react';
import { Trans } from '@lingui/macro';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { Button, Text } from 'strat/components';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import RejectPaymentBody from './rejectPaymentBody';
import styles from './styles/paymentPreValidateErrorPage.cssm';

const ApplyAdSinglePackageErrorPage = {
    head: () => <PrivateNonIndexableHead />,
    body: () => (
        <>
            <RejectPaymentBody>
                <Text.Base bold className={styles.description}>
                    <Trans>Your Ad was rejected! You can&apos;t apply products on it.</Trans>
                </Text.Base>
                <Link route={RouteNames.AD_MANAGEMENT}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: Element; primary: true; stretch: true; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button primary stretch>
                        <Trans>Go to My Ads</Trans>
                    </Button>
                </Link>
            </RejectPaymentBody>
        </>
    ),
} as const;

export default ApplyAdSinglePackageErrorPage;
