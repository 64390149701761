import shuffle from 'lodash/shuffle';
import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import settings from '@app/branding/settings';

const factory = new FetcherFactory(
    'featuredAgencies',
    ({ categoryExternalID }, state: any) => {
        if (!categoryExternalID || settings.disableAgency) {
            return Promise.resolve({ status: 200, data: [] });
        }

        return new StratAPI(state.i18n.language)
            .featuredAgencies(categoryExternalID)
            .then((response) => {
                const { data, status } = response;
                // shuffling the Agencies had to be done on the response handling
                // It is not a big issue because we only use this API in one place
                // for now and we need it shuffled in the place we are using it
                const shuffledData = shuffle(data);
                return {
                    data: shuffledData,
                    status,
                };
            });
    },
    {
        catchServerErrors: true,
    },
);

const fetchFeaturedAgencies = factory.creator();
const featuredAgenciesReducer = factory.reducer();

export { fetchFeaturedAgencies };

export default featuredAgenciesReducer;
