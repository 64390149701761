// @ts-nocheck
import FetcherFactory from 'strat/fetcher';
import { InternalAPI } from 'strat/api';
import { NotificationType } from 'strat/alerts/types';

import { Actions } from './actions';

export const alertsFactory = new FetcherFactory('alerts', () =>
    new InternalAPI().getSubscriptions(),
);

const alertsFactoryReducer = alertsFactory.reducer();
const getSubscriptions = alertsFactory.creator();

const defaultState = {
    loading: false,
    loaded: false,
    preloaded: false,
    enabled: true,
    params: null,
    data: null,
    error: null,
} as const;

const alertsReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.UPDATE_ALERT:
            if (action.notificationType === NotificationType.PUSH_NOTIFICATION) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        pushNotificationsSubscription: action.alert,
                    },
                };
            } else if (action.notificationType === NotificationType.RECOMMENDED_EMAIL) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        recommendedEmailAlert: action.alert,
                    },
                };
            } else if (action.notificationType === NotificationType.RECENTLY_VIEWED_EMAIL) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        recentlyViewedEmailAlert: action.alert,
                    },
                };
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    emailAlerts: state.data.emailAlerts.map((alert) =>
                        alert.id !== action.alert.id ? alert : action.alert,
                    ),
                },
            };

        case Actions.ADD_ALERT:
            return {
                ...state,
                data: {
                    ...state.data,
                    emailAlerts: action.data,
                },
            };

        default:
            return alertsFactoryReducer(state, action);
    }
};

export default alertsReducer;

export const clearAlerts = (): any => ({ type: alertsFactory.actions.clear });

export { getSubscriptions };
