import * as React from 'react';
import { Flex } from 'strat/components';

import {
    PopularAreas,
    SearchBox,
    FeaturedAgencies,
    PromotedAds,
} from 'horizontal/verticals/properties';

const Properties = () => {
    return (
        <Flex column>
            <SearchBox />
            <PromotedAds />
            <FeaturedAgencies />
            <PopularAreas />
        </Flex>
    );
};

export default Properties;
