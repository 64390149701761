import { useIsMobileLayout } from 'strat/layout';
import { useIsStratCreditUser } from 'strat/user/hooks';
import { useDialog } from 'strat/dialogs/dialogContext';

import { DialogNames } from 'horizontal/dialogs';

const useCreditSystemGuard = (message: string) => {
    const isMobileLayout = useIsMobileLayout();
    const isStratCreditUser = useIsStratCreditUser();
    const [, setToastDialogVisible] = useDialog(DialogNames.TOAST);

    return (callBack?: () => void) => {
        if (isMobileLayout && isStratCreditUser) {
            setToastDialogVisible(true, {
                message,
                disclaimer: true,
                timeoutMS: 5000,
            });
            return;
        }
        callBack?.();
    };
};

export default useCreditSystemGuard;
