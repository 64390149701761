import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n, selectLanguage } from 'strat/i18n/language';
import { trigger, Triggers } from 'strat/gtm';
import InternalAPI from 'strat/api/internal';
import { useRouter } from 'react-true-router';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';
import settings from '@app/branding/settings';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { RouteNames } from 'strat/routes';

import { useNavigateToAdUpgrade } from 'horizontal/packages/hooks';
import { AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE } from 'horizontal/payment/types';
import { useFlatCategoryFields } from 'horizontal/categoryFields';
import type { AppDispatch } from 'horizontal/state';
import type { FullAd } from 'horizontal/types';

const getCustomDeclarationTranslation = (language: string, fullAppName: string) => {
    if (language === 'en') {
        return `I promise that if I succeed in making a sale through ${fullAppName}, I will pay 1% of the sale value to ${fullAppName} within 10 days of receiving my full price.`;
    } else if (language === 'ur') {
        return `میں وعدہ کرتا ہوں کہ اگرمیں OLX پاکستان کے ذریعے سے اپنے مال کو بیچنے میں کامیاب ہوں گا تو میں اس کی قیمتِ فروخت کا ۱ فیصد OLX پاکستان کو پوری قیمت موصول ہونے کے 10 دنوں کے اندر ادا کرنے کا پابند ہوں گا۔`;
    }
    return ` أتعهد بدفع 1٪ من قيمة البيع إلى ${fullAppName} في غضون 10 أيام من استلام سعري الكامل، إذا نجحت في إجراء عملية البيع من خلال ${fullAppName}.`;
};

type Props = {
    ad: FullAd;
};

const useTerms = ({ ad }: Props) => {
    const navigateToAdUpgrade = useNavigateToAdUpgrade(ad, true);
    const dispatch = useDispatch<AppDispatch>();
    const i18n = useI18n();
    const router = useRouter();
    const language = useSelector(selectLanguage);
    const [selectedTranslation, setSelectedTranslation] = React.useState<string>('en');

    const amountOfTranslatedLanguages = Object.keys(settings.languages).length;
    const containsCustomTranslation = amountOfTranslatedLanguages < 2;

    const isRTLGlobal = useSelector(selectIsLanguageRTL);
    const isRTL = !isRTLGlobal && selectedTranslation === 'ur';

    const declaration = getCustomDeclarationTranslation(
        containsCustomTranslation ? selectedTranslation : language,
        `${settings.getBrandName(i18n)} ${settings.countryName(i18n)}`,
    );

    const extraFields = ad?.extraFields || {};
    const flatCategoryFields = useFlatCategoryFields(ad.category.slice(-1)?.[0]?.id, extraFields);
    const { externalID } = ad;

    const translateText = React.useCallback(() => {
        if (selectedTranslation === 'en') {
            setSelectedTranslation('ur');
        } else {
            setSelectedTranslation('en');
        }
    }, [selectedTranslation]);

    const navigateToAdDetails = React.useCallback(() => {
        if (ad) {
            router.pushRoute(RouteNames.AD_DETAILS, { slug: ad.slug, externalID: ad.externalID });
        }
    }, [router, ad]);

    React.useEffect(() => {
        if (ad?.category?.length) {
            dispatch(
                fetchCategoryFields({
                    categoryExternalIDs: [ad.category.slice(-1)[0].externalID],
                    includeWithoutCategory: !settings.disableCategoryFieldsWithoutCategory,
                }),
            );
        }
    }, [ad, dispatch]);

    React.useEffect(() => {
        const adUndertakenCategoryField = flatCategoryFields.find(
            (field) => field.attribute === AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE,
        );
        if (adUndertakenCategoryField && externalID) {
            const payload = {
                terms: [AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE],
            } as const;
            new InternalAPI().updateAdTerms(externalID, payload);
        }
    }, [flatCategoryFields, externalID]);

    const onAgree = React.useCallback(() => {
        trigger(Triggers.FREE_AD_CONFIRM);
        navigateToAdDetails();
    }, [navigateToAdDetails]);

    return {
        currentLanguage: containsCustomTranslation ? selectedTranslation : language,
        translateText,
        featureTheAd: navigateToAdUpgrade,
        isRTL,
        containsCustomTranslation,
        declaration,
        onAgree,
    };
};

export default useTerms;
