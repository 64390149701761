import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';

import { SearchPageSearchType } from '../types';

import eachPageUpdatedAdResponse from './eachPageType/updatedAdsResponse';
import groupedUpdatedAdResponse from './groupedType/updatedAdsResponse';
import circleUpdatedAdResponse from './withCircleSlottingType/updatedAdsResponse';
import promotedOnlyUpdatedAdResponse from './promotedType/updatedAdsResponse';
import paidFirstUpdatedAdResponse from './paidFirstType/updatedAdsResponse';
import type { UpdatedAdResponseProps } from './types';

const updatedAdsResponse = (
    params: UpdatedAdResponseProps,
    searchPageType: Values<typeof SearchPageSearchType>,
): SearchResponse | null => {
    switch (searchPageType) {
        case SearchPageSearchType.PROMOTED_ONLY:
            return promotedOnlyUpdatedAdResponse(params);
        case SearchPageSearchType.PAID_FIRST:
            return paidFirstUpdatedAdResponse(params);
        case SearchPageSearchType.PAID_FIRST_SLOTTED:
        case SearchPageSearchType.CIRCLE_BASED_SLOTTING:
            return circleUpdatedAdResponse(params);
        case SearchPageSearchType.FEATURED_REGULAR_GROUPED:
        case SearchPageSearchType.EXPANDED_RADIUS_PRODUCT_GROUPED:
            return groupedUpdatedAdResponse(params);
        case SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE:
        default:
            return eachPageUpdatedAdResponse(params);
    }
};

export default updatedAdsResponse;
