import * as React from 'react';
import MotorsIcon from '@app/assets/icons/iconMotors.svg';
import PropertyIcon from '@app/assets/icons/iconProperty.svg';
import { VerticalSelection } from 'horizontal/types';

import GenericHeaderLink from './headerLink';

type HeaderLink = {
    readonly link: string;
    readonly renderIcon?: (isHighlighted: boolean) => React.ReactElement;
    readonly name: string;
};

const useHeaderLinks = (): ReadonlyArray<HeaderLink> => {
    const [origin, setOrigin] = React.useState('');
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        if (!loaded) {
            setOrigin(window?.location?.origin);
            setLoaded(true);
        }
    }, [loaded]);

    return React.useMemo(
        () => [
            {
                link: `${origin || 'https://www.olx.com.pk'}/motors/`,
                renderIcon: (isHighlighted) => (
                    <GenericHeaderLink
                        isHighlighted={isHighlighted}
                        Icon={MotorsIcon}
                        name={VerticalSelection.MOTORS}
                        alt={'OLX Motors'}
                    />
                ),
                name: VerticalSelection.MOTORS,
                isMotorsLink: true,
            },
            {
                link: `${origin || 'https://www.olx.com.pk'}/properties/`,
                renderIcon: (isHighlighted) => (
                    <GenericHeaderLink
                        isHighlighted={isHighlighted}
                        Icon={PropertyIcon}
                        name={VerticalSelection.PROPERTY}
                        alt={'OLX Property'}
                    />
                ),
                name: VerticalSelection.PROPERTY,
                isPropertyLink: true,
            },
        ],
        [origin],
    );
};

export default useHeaderLinks;
