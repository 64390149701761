// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';
import ReactDOM from 'react-dom';
import animateScroll from 'react-scroll/modules/mixins/animate-scroll';
import { Events } from 'react-scroll';

import { computeAbsoluteTopOffset } from 'strat/util';

const Actions = Object.freeze({
    SET_SECTIONS: 'SECTIONS/SET',
    SCROLL_TO_SECTION: 'SECTION/SCROLL',
});

type SetSectionsAction = {
    type: 'SECTIONS/SET';
    sections: any;
};

type ScrollToSectionAction = {
    type: 'SECTION/SCROLL';
    sectionTitle: string;
};

const setSections = (sections: any): SetSectionsAction => ({
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"SECTIONS/SET"'.
    type: Actions.SET_SECTIONS,
    sections,
});

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const scrollToSection = (sectionTitle: string) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const section = state.scrollSections[sectionTitle];
    if (section === null) {
        return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(section.node) as HTMLElement | null | undefined;
    if (node) {
        let toScrollPixels = computeAbsoluteTopOffset(node) - section.compensation;
        if (!section.index && section.topCompensationDelta) {
            toScrollPixels -= section.topCompensationDelta;
        }

        Events.scrollEvent.register('end', () => {
            Events.scrollEvent.remove('end');
            toScrollPixels = computeAbsoluteTopOffset(node) - section.compensation;
            if (!section.index && section.topCompensationDelta) {
                toScrollPixels -= section.topCompensationDelta;
            }
            animateScroll.scrollTo(toScrollPixels, {
                smooth: true,
                duration: 200,
            });
        });
        animateScroll.scrollTo(toScrollPixels, {
            smooth: true,
            duration: section.scrollDuration ?? 500,
        });
    }

    dispatch({
        type: Actions.SCROLL_TO_SECTION,
        sectionTitle,
    });
};

export type SectionActions = SetSectionsAction | ScrollToSectionAction;

const defaultState = '';

const scrollSectionsReducer = (
    state: any | null | undefined = defaultState,
    action: SectionActions,
) => {
    switch (action.type) {
        case Actions.SET_SECTIONS:
            // @ts-expect-error - TS2339 - Property 'sections' does not exist on type 'SectionActions'.
            return action.sections;
        case Actions.SCROLL_TO_SECTION:
        default:
            return state;
    }
};

export { setSections, scrollToSection };

export default scrollSectionsReducer;
