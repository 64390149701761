import React, { Component } from 'react';
import type { ElementRef } from 'react';
import autoBind from 'react-autobind';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'react-simple-dropdown'. 'node_modules/react-simple-dropdown/lib/components/Dropdown.js' implicitly has an 'any' type.
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import omit from 'lodash/omit';

import './styles/dropDownMenu.cssm';

const DropdownArrow = ({ style }: { style: string }) => <span className={style} />;

// The className cannot be imported since it's defined in a global scope in the .cssm file.
// This is needed so we can change the arrow icon based on other global classNames (dropdown
// and dropdown--active)
DropdownArrow.STYLES = {
    LIGHT: 'dropdownArrow',
    DARK: 'dropdownArrow dark',
    HOVERABLE: 'dropdownArrow hoverable',
};

DropdownArrow.defaultProps = {
    style: DropdownArrow.STYLES.LIGHT,
};

export type DropDownMenuProps = {
    readonly keepOpen?: boolean;
    readonly showOnHover?: boolean;
    readonly title?: React.ReactElement<any>;
    readonly trigger?: React.ReactElement<any>;
    readonly className?: string;
    readonly contentClassName?: string;
    readonly containerClassName?: string;
    readonly showDropdownArrow?: boolean;
    readonly spacing?: string;
    readonly children: Node | React.ReactNode;
    readonly linkTitle?: string;
};

class DropDownMenu extends Component<DropDownMenuProps> {
    dropdown: ElementRef<typeof Dropdown> | null | undefined;

    componentDidMount() {
        if (window) {
            window.addEventListener('popstate', this.hide, { passive: false });
        }
    }

    constructor(props: DropDownMenuProps) {
        super(props);
        autoBind(this);

        this.dropdown = null;
    }

    hide() {
        if (this.props.keepOpen) {
            return;
        }
        if (this.dropdown) {
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            this.dropdown.hide();
        }
    }

    onMouseEnter() {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        if (this.props.showOnHover && this.dropdown && this.dropdown.show) {
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            this.dropdown.show();
        }
    }

    onMouseLeave() {
        if (!this.props.showOnHover) {
            return;
        }

        this.hide();
    }

    render() {
        const props = omit(this.props, [
            'title',
            'linkTitle',
            'showDropdownArrow',
            'containerClassName',
            'contentClassName',
            'keepOpen',
            'trigger',
            'spacing',
            'showOnHover',
        ]);

        const dropDownTrigger =
            this.props.title != null ? (
                <>
                    {this.props.title}
                    <span style={{ marginRight: this.props.spacing }} />
                    {this.props.trigger}
                </>
            ) : (
                this.props.trigger
            );

        return (
            <Dropdown
                {...props}
                // @ts-expect-error - TS7006 - Parameter 'ref' implicitly has an 'any' type.
                ref={(ref) => {
                    this.dropdown = ref;
                }}
                onMouseLeave={this.onMouseLeave}
            >
                <DropdownTrigger
                    title={this.props.linkTitle}
                    className={this.props.containerClassName}
                    onMouseEnter={this.onMouseEnter}
                >
                    {dropDownTrigger}
                </DropdownTrigger>
                <DropdownContent
                    className={this.props.contentClassName}
                    onClick={this.hide}
                    aria-haspopup="true"
                >
                    {this.props.children}
                </DropdownContent>
            </Dropdown>
        );
    }
}

export default DropDownMenu;
export { DropdownArrow };
