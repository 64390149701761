import isNil from 'lodash/isNil';
import settings from '@app/branding/settings';
import Purpose from 'strat/purpose';

import type { MixedAd } from 'horizontal/types';

import useCanonicalURL from '../useCanonicalURL';

import type { OfferSchema } from './types';
import useAdExtraFieldLabel from './useAdExtraFieldLabel';
import selectAdExpiresAtFormatted from './selectAdExpiresAtFormatted';

const useOfferSchema = (ad?: MixedAd | null): OfferSchema | null | undefined => {
    const canonicalURL = useCanonicalURL(ad);
    const condition = useAdExtraFieldLabel(ad, 'new_used') || 'Used';

    if (!ad) {
        return null;
    }

    const businessFunction =
        ad.purpose === Purpose.FOR_RENT
            ? 'http://purl.org/goodrelations/v1#LeaseOut'
            : 'http://purl.org/goodrelations/v1#Sell';

    const schema: OfferSchema = {
        '@type': 'Offer',
        priceCurrency: settings.baseCurrencyName,
        itemCondition: `https://schema.org/${condition}Condition`,
        seller: 'OLX user',
        businessFunction,
    };

    if (canonicalURL && canonicalURL.url) {
        schema.url = canonicalURL.url;
    }

    if (!isNil(ad.price)) {
        schema.price = ad.price;
    }

    const priceValidUntil = selectAdExpiresAtFormatted(ad);
    if (priceValidUntil) {
        schema.priceValidUntil = priceValidUntil;
    }

    return schema;
};

export default useOfferSchema;
