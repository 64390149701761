import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const Distance = Object.freeze({
    FT: 'ft',
    YD: 'yd',
    M: 'm',

    text(i18n: I18n, distance: any) {
        switch (distance) {
            case this.FT:
                return t(i18n)`feet`;
            case this.YD:
                return t(i18n)`yards`;
            case this.M:
                return t(i18n)`meters`;
            default:
                return t(i18n)`feet`;
        }
    },

    abbreviation(i18n: I18n, distance: any) {
        switch (distance) {
            case this.FT:
                return t(i18n)`ft`;
            case this.YD:
                return t(i18n)`yd`;
            case this.M:
                return t(i18n)`m`;
            default:
                return t(i18n)`ft`;
        }
    },

    roundingFunction(_: any) {
        return (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;
    },

    formattingSettings(_: any) {
        return {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        };
    },
});

export default Distance;
