import * as React from 'react';
import { Flex, LoadingAnimation } from 'strat/components';

import { useFormattedPrice } from 'horizontal/util';

import styles from './styles/orderSummary.cssm';

type Props = {
    readonly productPackageNames: string | null | undefined;
    readonly productsPrice: number | null | undefined;
    readonly loading: boolean;
};

const OrderSummary = ({ productPackageNames, productsPrice, loading }: Props) => {
    const totalPrice = useFormattedPrice(productsPrice || 0);
    return (
        <Flex column className={styles.products}>
            <Flex justifySpaceBetween alignCenter>
                {loading ? (
                    <>
                        <LoadingAnimation width={'60%'} height={'3rem'} />
                        <LoadingAnimation width={'10%'} height={'3rem'} />
                    </>
                ) : (
                    <>
                        <span>{productPackageNames}</span>
                        <span className={styles.price} aria-label="Total price">
                            {totalPrice}
                        </span>
                    </>
                )}
            </Flex>
        </Flex>
    );
};

export default OrderSummary;
