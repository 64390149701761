import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import type { Dispatch } from 'redux';
import { determineLocationsIndexName } from 'strat/search/indexNames';
import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import brandingSettings from '@app/branding/settings';
import { fetchCategoryFields } from 'strat/categoryFields/state';

import Page from 'horizontal/pages/page';
import { SitemapPageType } from 'horizontal/sitemap';
import {
    setPageType,
    setPopularSearches,
    setSitemapCategories,
} from 'horizontal/sitemap/state/state';
import { findCategoryByExternalID, selectCategories } from 'horizontal/categories';
import { fetchCategories } from 'horizontal/categories/state';
import type { LiteCategory, Location, LiteHierarchicalLocation, Category } from 'horizontal/types';
import { StratAPI } from 'horizontal/api';
import fetchLocationsByParent from 'horizontal/search/location/fetchLocationsByParent';

type RouteParameters = {
    sitemapViewSlug: Values<typeof SitemapPageType>;
    category?: LiteCategory | null | undefined;
    location?: Location | null | undefined;
};

class SitemapRoute extends Route {
    constructor() {
        super(RouteNames.SITEMAP, [
            // /sitemap/popular-searches/{location}/{category}
            [
                '^/sitemap/',
                { name: 'sitemapViewSlug', pattern: '([A-z-]+)?' },
                '/',
                '(?:[A-z0-9-]*)',
                '_g',
                { name: 'locationExternalID', pattern: '([0-9]*)' },
                '/',
                { name: 'categorySlug', pattern: '([A-z-]*)' },
                '_c',
                { name: 'categoryExternalID', pattern: '([0-9]*)' },
                '(?:\\?)?',
            ],
            // sitemap/popular-searches/{category}
            [
                '^/sitemap/',
                { name: 'sitemapViewSlug', pattern: '([A-z-]+)?' },
                '/',
                { name: 'categorySlug', pattern: '([A-z-]*)' },
                '_c',
                { name: 'categoryExternalID', pattern: '([0-9]*)' },
                '(?:\\?)?',
            ],
            // sitemap/most-popular/{location}
            [
                '^/sitemap/',
                { name: 'sitemapViewSlug', pattern: '([A-z-]+)?' },
                '/',
                '(?:[A-z0-9-]*)',
                '_g',
                { name: 'locationExternalID', pattern: '([0-9]*)' },
                '(?:\\?)?',
            ],
            // /sitemap/{sitemapViewSlug}
            ['^/sitemap', '(?:/)?', { name: 'sitemapViewSlug', pattern: '([A-z-]+)?' }, '(?:\\?)?'],
        ]);
    }

    createURL({
        sitemapViewSlug = SitemapPageType.POPULAR_SEARCHES,
        category,
        location,
    }: RouteParameters): EnhancedLocation {
        let pathname = sitemapViewSlug ? `/sitemap/${sitemapViewSlug}` : '/sitemap';

        if (location && location.slug !== brandingSettings.topLevelLocation.slug) {
            pathname += `/${location.slug}`;
        }
        if (category) {
            pathname += `/${category.slug}_c${category.externalID}`;
        }

        return { pathname };
    }

    createLocationFilters(locationExternalID: string): FilterCollection | null | undefined {
        const filters = new FilterCollection();

        filters.refine(
            new ExactFilter({
                attribute: 'externalID',
                value: locationExternalID || brandingSettings.topLevelLocation.externalID,
            }),
        );

        return filters;
    }

    createLocationSearchJob(context: RoutingContextWithMiddlewares): SearchJob | null | undefined {
        const {
            match: { params },
            i18n: { locale: language },
        } = context;

        const indexName = determineLocationsIndexName({ language });
        const filters = this.createLocationFilters(params.locationExternalID);
        if (!filters) {
            return null;
        }

        return new SearchJob(indexName, filters, {
            hitsPerPage: 1,
            exhaustiveNbHits: false,
        });
    }

    search(backend: SearchBackend, jobs: SearchJob): Promise<SearchResponse[]> {
        const service = new SearchService({ backend });
        // @ts-expect-error - TS2345 - Argument of type 'SearchJob' is not assignable to parameter of type 'SearchJob[]'.
        return service.fetchJobs(jobs);
    }

    getPopularSearchesEntries(
        category?: LiteCategory | null,
        location?: LiteHierarchicalLocation | null,
    ) {
        let popularSearches: Array<any> = [];
        const currentLocation = location || brandingSettings.topLevelLocation;
        const stratAPI = new StratAPI();

        return (
            stratAPI
                // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
                .popularSearchesList(currentLocation.id, category?.id)
                .then((locationSearches) => {
                    if (locationSearches.data && locationSearches.data.length) {
                        const locationSearchesEntries = locationSearches.data[0];
                        const locationPopularSearches = locationSearchesEntries.popularSearchEntry;
                        locationPopularSearches.sort(() => Math.random() - 0.5);
                        locationSearchesEntries.popularSearchEntry = locationPopularSearches;
                        popularSearches = [locationSearchesEntries];
                    }
                    return stratAPI.popularSearchesList().then((generalSearches) => {
                        if (generalSearches.data && generalSearches.data.length > 0) {
                            popularSearches = popularSearches.concat(generalSearches.data);
                        }
                        return popularSearches;
                    });
                })
        );
    }

    fetchPopularSearchesData(context: RoutingContextWithMiddlewares) {
        const {
            match: {
                params: { categoryExternalID, locationExternalID },
            },
            redux: {
                store: { dispatch, getState },
            },
            i18n: { locale: language },
        } = context;
        const state = getState();
        const backend = selectActiveSearchBackend(state);
        let childLocations = [];
        // @ts-expect-error - TS7034 - Variable 'location' implicitly has type 'any' in some locations where its type cannot be determined.
        let location = null;

        const categoriesPromise = dispatch(fetchCategories());
        const locationJob = this.createLocationSearchJob(context);

        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        const searchPromise = this.search(selectActiveSearchBackend(state), [locationJob]);
        const promise = Promise.all([searchPromise, categoriesPromise]);

        return promise.then(([[locationResponse]]: [any, any]) => {
            let category: Category | null | undefined = null;

            if (locationExternalID && locationResponse?.hits?.length) {
                location = locationResponse.hits[0];
            }

            if (categoryExternalID) {
                const categories = selectCategories(getState());
                category = findCategoryByExternalID(categories, categoryExternalID);
            }

            // @ts-expect-error - TS7005 - Variable 'location' implicitly has an 'any' type.
            return this.getPopularSearchesEntries(category, location).then((popularSearches) => {
                // @ts-expect-error - TS7005 - Variable 'location' implicitly has an 'any' type. | TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
                return fetchLocationsByParent(location, language, backend).then((data) => {
                    childLocations = data;
                    dispatch(
                        setPopularSearches({
                            category,
                            // @ts-expect-error - TS7005 - Variable 'location' implicitly has an 'any' type.
                            location,
                            popularSearches,
                            childLocations,
                        }),
                    );
                });
            });
        });
    }

    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    fetchSitemapCategories(dispatch: Dispatch, language: string, locationLevel: number) {
        dispatch(setSitemapCategories([]));

        // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
        return new StratAPI(language).sitemapCategories(locationLevel).then((result) => {
            const { data, status } = result;
            if (status !== 200) {
                return;
            }

            if (data.length !== 0) {
                dispatch(setSitemapCategories(data));
                dispatch(fetchCategoryFields({}));
            }
        });
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            match: {
                params: { sitemapViewSlug },
            },
            redux: {
                store: { dispatch },
            },
            i18n: { locale: language },
        } = context;

        if (!sitemapViewSlug) {
            context.http.redirect(`/sitemap/${SitemapPageType.MOST_POPULAR}`);
        }

        let fetchPromise = Promise.resolve();

        switch (sitemapViewSlug) {
            case SitemapPageType.MOST_POPULAR:
                fetchPromise = this.fetchSitemapCategories(dispatch, language, 1);
                break;
            case SitemapPageType.CATEGORIES:
                fetchPromise = this.fetchSitemapCategories(dispatch, language, 0);
                break;
            case SitemapPageType.PROVINCES:
                fetchPromise = this.fetchSitemapCategories(dispatch, language, 1);
                break;
            case SitemapPageType.CITIES:
                fetchPromise = this.fetchSitemapCategories(dispatch, language, 2);
                break;
            case SitemapPageType.POPULAR_SEARCHES:
                fetchPromise = this.fetchPopularSearchesData(context);
                break;
            case undefined:
                break;
            default:
                context.rendering.renderPage(Page.NOT_FOUND);
                return;
        }

        context.promise.wait(
            Promise.all([fetchPromise, dispatch(fetchCategoryFields({}))]).then(() => {
                dispatch(setPageType(sitemapViewSlug));
                context.rendering.renderPage(Page.SITEMAP);
            }),
        );
    }
}

export default new SitemapRoute();
