import React from 'react';
import classNames from 'classnames';

import { stopEventPropagation } from 'strat/util';

import FilterConfirmationButtons from './filterConfirmationButtons';
import styles from './styles/dropDownBox.cssm';

/**
 * Properties for {@see DropDownBox}.
 */
type Props = React.ComponentProps<typeof FilterConfirmationButtons> & {
    /**
     * CSS class name for additional styling
     */
    className?: string;

    /**
     * Contents of the box.
     */
    children?: React.ReactNode;
    /**
     * CSS class name for children additional styling
     */
    childrenClassName?: string;
    /**
     * Current active design
     */
    design?: string;
};

/**
 * Renders a box that is used for search filter drop downs.
 */
export default ({
    className,
    design,
    onCloseClick,
    renderCloseButton,
    onResetClick,
    renderResetButton,
    children,
    childrenClassName,
}: Props) => {
    return (
        <div
            className={classNames(styles.container, design, className)}
            onClick={(event) => {
                stopEventPropagation(event);
            }}
            role="listbox"
        >
            <div className={childrenClassName}>{children}</div>
            <FilterConfirmationButtons
                design={design}
                onCloseClick={onCloseClick}
                onResetClick={onResetClick}
                renderCloseButton={renderCloseButton}
                renderResetButton={renderResetButton}
            />
        </div>
    );
};
