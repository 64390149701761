import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';

import { ChatRoomMemberRole } from 'horizontal/chat/constants';
import { useProfileDetails } from 'horizontal/chat/hooks';
import type { ChatRoom } from 'horizontal/chat/types';

import styles from './styles/agencyName.cssm';

type Props = {
    conversation: ChatRoom;
    compact?: boolean;
    small?: boolean;
};

const AgencyName = ({ conversation, compact = false, small = false }: Props) => {
    const { agencyName } = useProfileDetails(conversation);
    const { myRole } = conversation;
    const isSeller = myRole === ChatRoomMemberRole.SELLER;

    if (isSeller) {
        return null;
    }

    return agencyName ? (
        <>
            {!compact && (
                <Text.Base
                    bold
                    nowrap
                    className={classNames(styles.text, styles.largeText, {
                        [styles.small]: small,
                    })}
                >
                    ·
                </Text.Base>
            )}
            <Text.Base
                bold
                nowrap
                className={classNames(styles.text, styles.largeText, styles.agencyName, {
                    [styles.small]: small,
                })}
                aria-label="Agency name"
            >
                {agencyName}
            </Text.Base>
        </>
    ) : null;
};

export default AgencyName;
