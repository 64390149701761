import { t } from '@lingui/macro';
import * as React from 'react';
import { Text, InputMessage } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import ErrorMessage from '../errorMessage';

interface Props {
    readonly anyImageTooLarge?: boolean;
    readonly anyImageResolutionTooHigh?: boolean;
    readonly anyUploadFailed?: boolean;
    readonly isTouched?: boolean;
    readonly errorMessage?: string;
}

const ImageInputFooter = ({
    anyImageTooLarge,
    anyImageResolutionTooHigh,
    anyUploadFailed,
    isTouched,
    errorMessage,
}: Props) => {
    const i18n = useI18n();

    return (
        <>
            {isTouched && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {anyImageTooLarge ? (
                <Text.Regular error>{t(
                    i18n,
                )`The maximum file size is ${CONFIG.build.TEMP_IMAGE_MAX_SIZE_MB}MB`}</Text.Regular>
            ) : null}
            {anyImageResolutionTooHigh ? (
                <Text.Regular error>{t(
                    i18n,
                )`The maximum image resolution is ${CONFIG.build.TEMP_IMAGE_MAX_RESOLUTION} pixels`}</Text.Regular>
            ) : null}
            {anyUploadFailed ? (
                <Text.Regular error aria-label="Upload failed">{t(
                    i18n,
                )`We failed to upload some images. Please retry.`}</Text.Regular>
            ) : null}
            {!anyImageTooLarge && !anyUploadFailed && !isTouched ? (
                <InputMessage>{t(
                    i18n,
                )`For the cover picture we recommend using the landscape mode.`}</InputMessage>
            ) : null}
        </>
    );
};

export default ImageInputFooter;
