import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex, LoadingSpinnerOverlay, Button } from 'strat/components';
import { Trans } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';

import PostFeaturedIcon from 'horizontal/assets/icons/postFeaturedIcon.svg';
import type { FullAd, ProductPackageOffer, ProductPurchase } from 'horizontal/types';
import {
    useSinglePackages,
    ChoicesSection,
    PromoStrip,
    useBoughtPackages,
    ErrorBanner,
} from 'horizontal/packages/individual';
import { PackagesExamples } from 'horizontal/packages/components';
import { ChoicesSection as ChoicesSectionBoughtPackage, PackagesFlow } from 'horizontal/packages';
import { CTASection } from 'horizontal/generic/choicePage';
import { useNavigateToAdDetails, useNavigateToAdDetailsFromPortal } from 'horizontal/adDetails';
import { useIsAdUndertaking, useNavigateToOathPage } from 'horizontal/payment/hooks';
import { trackClickPreviewAd } from 'horizontal/gtm';
import getTaxInfoFromOffers from 'horizontal/util/tax';
import useIsInAgencyPortal from 'horizontal/user/useIsInAgencyPortal';
import useEnabledChoicesIds from 'horizontal/packages/useEnabledChoicesIds';
import useConfigureOfferPackagesSections from 'horizontal/packages/useConfigureOfferPackagesSections';
import useDefaultPackageSelection from 'horizontal/packages/useDefaultPackageSelection';
import { hasExample } from 'horizontal/packages/utils';
import { useIsProAgent } from 'horizontal/agent/state';

import PostAdSuccessTitle from './postAdSuccessTitle';
import FeaturedAdAdvantages from './featuredAdAdvantages';
import BoostToTopAdvantages from './boostToTopAdvatages';
import useTriggerAdSuccess from './useTriggerAdSuccess';
import styles from './styles/successMessage.cssm';

type PostAdSuccessMessageProps = {
    readonly ad?: FullAd | null;
    readonly productPurchases: Array<ProductPurchase> | null | undefined;
    readonly productOffers: Array<ProductPackageOffer> | null | undefined;
    readonly suppressTrigger?: boolean;
};

/**
 * component that either renders products the user can buy (productOffers)
 * or the products that user can use on the current ad (productPurchases)
 * if the user is of type agent we will not show anything related to
 * buying.
 */
const PostAdSuccessMessage = ({
    ad,
    productPurchases,
    productOffers,
    suppressTrigger,
}: PostAdSuccessMessageProps) => {
    const i18n = useI18n();
    const isInAgencyPortal = useIsInAgencyPortal();
    const isProAgent = useIsProAgent();
    const hasProducts = !!(productOffers?.length || productPurchases?.length);
    const showProductsForAgency = !(isInAgencyPortal && !productPurchases?.length);
    const isLoading = !ad;
    const isProAgencyPortal = isProAgent && isInAgencyPortal;

    const offersSections = useConfigureOfferPackagesSections(
        productOffers,
        PackagesFlow.UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY,
    );
    const defaultSelectedOffer = useDefaultPackageSelection(offersSections);

    const {
        selectedValue,
        handleClick,
        activeExample,
        openExample,
        closeExample,
        onCheckoutSinglePackages,
        errorMessage: singlePackagesErrorMessage,
    } = useSinglePackages(productOffers, ad, defaultSelectedOffer);

    const navigateToAdDetailsFromPortal = useNavigateToAdDetailsFromPortal(ad);
    const navigateToAdDetails = useNavigateToAdDetails(ad);
    const navigateToOathPage = useNavigateToOathPage(ad);
    const isAdUndertaking = useIsAdUndertaking(ad);

    const onViewAdButtonClick = React.useCallback(() => {
        trackClickPreviewAd(ad);
        if (isAdUndertaking) {
            navigateToOathPage();
            return;
        }
        if (isInAgencyPortal) {
            navigateToAdDetailsFromPortal();
            return;
        }
        navigateToAdDetails();
    }, [
        navigateToAdDetailsFromPortal,
        navigateToAdDetails,
        navigateToOathPage,
        isAdUndertaking,
        ad,
        isInAgencyPortal,
    ]);

    const {
        onChoiceClick,
        applyBoughtPackage,
        selectedPackage,
        errorMessage: boughtPackagesErrorMessage,
    } = useBoughtPackages(productPurchases, ad);
    const enabledIds = useEnabledChoicesIds(productPurchases);

    const onCTAClick = productOffers ? onCheckoutSinglePackages : applyBoughtPackage;

    const hasUserSelectedAnyPackage = !!selectedPackage || !!selectedValue;
    const viewAdButtonText =
        hasProducts || showProductsForAgency ? t(i18n)`Skip, View Your Ad` : t(i18n)`View Your Ad`;
    const [taxActive, taxPercentage] = getTaxInfoFromOffers(productOffers);

    useTriggerAdSuccess({ ad, suppressTrigger });

    const errorMessage = singlePackagesErrorMessage || boughtPackagesErrorMessage;

    const shouldDisplayUpgradeCTA =
        hasProducts && showProductsForAgency && hasUserSelectedAnyPackage;

    return (
        <>
            <PackagesExamples activeExample={activeExample} closeExample={closeExample} />
            <LoadingSpinnerOverlay visible={isLoading} />
            {!isLoading && (
                <Flex column>
                    {errorMessage && <ErrorBanner error={errorMessage} />}
                    <PostAdSuccessTitle />
                    {hasProducts && showProductsForAgency && (
                        <Flex column className={styles.productsContainer}>
                            <Flex column className={styles.sellFasterContainer}>
                                <Flex>
                                    <Flex column className={styles.infoContainer}>
                                        <Text.Base bold className={styles.sellFasterTitle}>
                                            {t(
                                                i18n,
                                            )`Reach More Buyers and Sell Faster and Upgrade your Ad to a top position`}
                                        </Text.Base>
                                        {productOffers && <FeaturedAdAdvantages />}
                                        {productPurchases && <BoostToTopAdvantages />}
                                    </Flex>
                                    {productOffers && (
                                        <PostFeaturedIcon className={styles.postFeaturedIcon} />
                                    )}
                                </Flex>
                            </Flex>
                            {productPurchases && (
                                <ChoicesSectionBoughtPackage
                                    ad={ad}
                                    onClick={onChoiceClick}
                                    choices={productPurchases}
                                    selectedValue={selectedPackage}
                                    enabledIds={enabledIds}
                                />
                            )}
                            {offersSections && (
                                <>
                                    {offersSections.map((section) => (
                                        <ChoicesSection
                                            ad={ad}
                                            onClick={handleClick}
                                            packages={section.items}
                                            selectedValue={selectedValue}
                                            title={section.title}
                                            valuePropositions={
                                                section.valuePropositions ?? [section.description]
                                            }
                                            onClickSeeExample={
                                                hasExample(section.packageType)
                                                    ? () => openExample(section.packageType)
                                                    : null
                                            }
                                        />
                                    ))}
                                    ;
                                </>
                            )}
                            {!isInAgencyPortal && (
                                <PromoStrip
                                    text={t(i18n)`Heavy discount on Business Packages`}
                                    ad={ad}
                                    availableAdPackages={productPurchases || productOffers}
                                />
                            )}
                        </Flex>
                    )}
                    <Flex column className={styles.bottomContainer}>
                        {taxActive && (
                            <Text.XSmall>{t(
                                i18n,
                            )`These prices are exclusive of tax, ${taxPercentage}% tax will be charged on checkout`}</Text.XSmall>
                        )}
                        <Flex className={styles.buttonsContainer}>
                            {isProAgencyPortal && (
                                <Link route={RouteNames.AGENCY_PORTAL_ADS}>
                                    <Button tertiary>
                                        <Trans>Go to My Ads</Trans>
                                    </Button>
                                </Link>
                            )}
                            {ad && (
                                <Flex justifyCenter className={styles.viewAdButtonContainer}>
                                    <Button
                                        small={isProAgencyPortal && !shouldDisplayUpgradeCTA}
                                        tertiary={!isProAgencyPortal || shouldDisplayUpgradeCTA}
                                        onClick={onViewAdButtonClick}
                                    >
                                        {viewAdButtonText}
                                    </Button>
                                </Flex>
                            )}
                            {shouldDisplayUpgradeCTA && (
                                <CTASection
                                    buttonText={t(i18n)`Upgrade Your Ad`}
                                    onClick={onCTAClick}
                                />
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default PostAdSuccessMessage;
