import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import RouteNames from 'strat/routes/routeNames';
import { makeCancelable, isCanceled } from 'strat/util';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { selectActiveUser, selectUserRoles } from 'strat/user/session';
import settings from '@app/branding/settings';
import { isProfolioUser } from 'strat/user/roles';

import type { Ad } from 'horizontal/types';
import { isAdEditor } from 'horizontal/util';
import { fetchAd, clearAd } from 'horizontal/ad/state';
import Page from 'horizontal/pages/page';
import { UpdateAdAction } from 'horizontal/adManagement/types';
import AgencyPortalUpdateAdRoute from 'horizontal/agencyPortal/routes/agencyPortalUpdateAdRoute';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';
import AdManagementRoute from './adManagementRoute';

export type UpdateAdRouteParams = {
    readonly externalID: string;
    readonly action?: Values<typeof UpdateAdAction>;
};

class UpdateAdRoute extends Route {
    constructor() {
        super(RouteNames.UPDATE_AD, [
            [
                '^/edit/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({ externalID, action }: UpdateAdRouteParams): EnhancedLocation {
        return { pathname: `/edit/${externalID}`, search: { action } };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const userRoles = selectUserRoles(context.redux.store.getState());

        // Profolio users will be redirected to MyAds page and credit system users to Agency Portal
        const redirectURL = isProfolioUser(userRoles)
            ? AdManagementRoute.createURL({})
            : AgencyPortalUpdateAdRoute.createURL({ externalID: context.match.params.externalID });

        if (
            !ensureHasActiveUser(context) ||
            !ensureActiveUserIsAllowedAccessAndRedirect(context, redirectURL)
        ) {
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { externalID, action },
            },
        } = context;

        const getAdData = () => getState().ad.data;
        const getCategoryFields = () => getState().categoryFields.data;
        const user = selectActiveUser(context.redux.store.getState());

        context.promise.wait(dispatch(clearAd()));

        const dataPromise = dispatch(fetchAd({ externalID }));

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'UpdateAdRoute'.
        this.cancelablePromise = makeCancelable(dataPromise);

        context.rendering.renderPage(Page.TRANSITION);

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'UpdateAdRoute'.
        const promise = this.cancelablePromise.then(
            () => {
                const ad: Ad = getAdData();
                // @ts-expect-error - TS2339 - Property 'externalID' does not exist on type 'boolean'.
                if (!ad || !isAdEditor(ad, user.externalID)) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.NOT_FOUND);
                    return Promise.resolve();
                }

                return dispatch(
                    fetchCategoryFields({
                        categoryExternalIDs: [ad.category.slice(-1)[0].externalID],
                        includeWithoutCategory: !settings.disableCategoryFieldsWithoutCategory,
                    }),
                ).then(() => {
                    const categoryFields = getCategoryFields();

                    if (!categoryFields) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return Promise.resolve();
                    }

                    context.rendering.renderPage(Page.UPDATE_AD, { action });

                    return Promise.resolve();
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );

        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'UpdateAdRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'UpdateAdRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new UpdateAdRoute();
