import * as React from 'react';

import type { LiteAd } from 'horizontal/types';

type Hits = {
    hits: Array<LiteAd>;
};

const useEmptyDivPosition = <T extends Hits>(sectionHits: Array<T>, emptyDivOffset = 3) => {
    return React.useMemo(() => {
        // If not enough hits then don't render the empty div
        const noRenderPosition = [-1, -1];
        const totalHits = sectionHits.reduce((count, section) => section.hits.length + count, 0);
        if (totalHits < emptyDivOffset) {
            return noRenderPosition;
        }

        // Insert empty div after exactly emptyDivOffset hits
        let position = emptyDivOffset;
        for (let i = 0; i < sectionHits.length; i++) {
            if (sectionHits[i].hits.length >= position) {
                return [position, i];
            }
            position -= sectionHits[i].hits.length;
        }

        // If less than emptyDivOffset hits in total then find the last sectionHits with at least one hit
        for (let i = sectionHits.length - 1; i >= 0; i--) {
            if (sectionHits[i].hits.length > 0) {
                return [sectionHits[i].hits.length, i];
            }
        }

        return noRenderPosition;
    }, [sectionHits, emptyDivOffset]);
};

export default useEmptyDivPosition;
