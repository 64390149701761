import * as React from 'react';

const useAnimationFrame = () => {
    const animationFrameHandle = React.useRef(null);

    return React.useCallback((callback: () => void) => {
        if (animationFrameHandle.current) {
            cancelAnimationFrame(animationFrameHandle.current);
        }

        // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
        animationFrameHandle.current = requestAnimationFrame(callback);
    }, []);
};

export default useAnimationFrame;
