import { LibPhoneNumberMetadata } from './types';

/**
 * Loads the lib phone number library asynchronously.
 *
 * This might seem like a pointless wrapper function, but having
 * a single function ensures that the library always ends up
 * in the same webpack chunk.
 */
const loadLibPhoneNumber = (
    metadata: LibPhoneNumberMetadata = LibPhoneNumberMetadata.MAX,
): Promise<any> => {
    switch (metadata) {
        case LibPhoneNumberMetadata.MOBILE:
            return import(/* webpackChunkName: 'phoneNumber' */ 'libphonenumber-js/mobile');
        case LibPhoneNumberMetadata.MAX:
        default:
            return import(/* webpackChunkName: 'phoneNumber' */ 'libphonenumber-js/max');
    }
};
export default loadLibPhoneNumber;
