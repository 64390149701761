import * as React from 'react';
import { useSelector } from 'react-redux';

import type { SearchHitsBanner } from 'strat/search/types';
import EMPTY_ARRAY from 'strat/empty/array';
import { selectRouteName, selectRouteParams } from 'strat/routes/selectors';

const EmptyDivBanner = () => {
    const routeName = useSelector(selectRouteName);
    const routeParams = useSelector(selectRouteParams);

    const emptyDivRef = React.createRef();
    const [inView, setInView] = React.useState(false);

    const handleScroll = React.useCallback(() => {
        if (!emptyDivRef.current) {
            return;
        }
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const rect = emptyDivRef.current.getBoundingClientRect();
        const rectInView =
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement?.clientHeight);
        if (inView !== rectInView) {
            window.dispatchEvent(new CustomEvent('scrolledInView', { detail: rectInView }));
            setInView(rectInView);
        }
    }, [emptyDivRef, inView]);

    React.useEffect(() => {
        window.dispatchEvent(new Event('routeChanged'));
    }, [routeName, routeParams]);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    React.useEffect(() => {
        window.dispatchEvent(new CustomEvent('mounted', { detail: true }));
        return () => {
            window.dispatchEvent(new CustomEvent('mounted', { detail: false }));
        };
    }, []);

    // @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
    return <div ref={emptyDivRef} className={'emptyDivBannerClassName'} id={'emptyDivBannerID'} />;
};

const banners: Array<SearchHitsBanner> = [
    {
        index: 4,
        renderBanner: () => <EmptyDivBanner />,
        key: 'empty-div-4',
    },
];

export { EmptyDivBanner };

// @ts-expect-error - TS2322 - Type 'readonly never[] | SearchHitsBanner[]' is not assignable to type 'SearchHitsBanner[]'.
const searchEmptyDivBanners: Array<SearchHitsBanner> = CONFIG.build.DISABLE_EMPTY_DIV_BANNERS
    ? EMPTY_ARRAY
    : banners;
export default searchEmptyDivBanners;
