import * as React from 'react';

import Dialog from './dialog';
import Dismissible from './dismissible';
import CloseButton from './closeButton';
import styles from './styles/galleryDialog.cssm';

type Props = React.ComponentProps<typeof Dialog> & {
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
    children?: React.ReactNode;
    renderCloseButton?: (onClick: () => void) => React.ReactElement;
};

const GalleryDialog = ({
    visible,
    onVisibilityChanged,
    children,
    renderCloseButton,
    className,
    dismissible = true,
    ...props
}: Props) => (
    <Dialog
        visible={visible}
        onVisibilityChanged={onVisibilityChanged}
        className={className || styles.dialog}
        label="Gallery Dialog"
        {...props}
    >
        <Dismissible
            enabled={dismissible && visible}
            onDismissed={() => onVisibilityChanged(false)}
            className={styles.dismissible}
        >
            <>
                {children}
                {renderCloseButton ? (
                    renderCloseButton(() => onVisibilityChanged(false))
                ) : (
                    <CloseButton
                        className={styles.closeButton}
                        onClick={() => onVisibilityChanged(false)}
                    />
                )}
            </>
        </Dismissible>
    </Dialog>
);

export default GalleryDialog;
