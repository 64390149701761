import * as React from 'react';
import PageWidthWrapper from 'strat/generic/pageWidthWrapper';
import Logo from '@app/branding/logo';
import { Flex, Header } from 'strat/components';

import type { FullAd, ProductPurchase } from 'horizontal/types';

import SelectPackageSuccessMessage from '../packages/selectPackageSuccessMessage';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/selectPackageSuccessPage.cssm';

type Props = {
    ad?: FullAd;
    productPurchase?: ProductPurchase;
};

const SelectPackagesSuccessPage = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ ad, productPurchase }: Props) => (
        <Flex column stretchHeight>
            <Header className={styles.header}>
                <div className={styles.logo}>
                    <Logo />
                </div>
            </Header>
            <PageWidthWrapper className={styles.wrapper}>
                <SelectPackageSuccessMessage ad={ad} productPurchase={productPurchase} />
            </PageWidthWrapper>
        </Flex>
    ),
} as const;

export default SelectPackagesSuccessPage;
