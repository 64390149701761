import { CancelableRoute } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { catchCanceled } from 'strat/util';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import EMPTY_ARRAY from 'strat/empty/array';
import { selectUserExternalID } from 'strat/user/session';

import type { FullAd } from 'horizontal/types';
import Page from 'horizontal/agencyPortal/pages/page';
import { getStratAPI } from 'horizontal/api';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import { filterEligibleProductPurchases, ProductPurchaseStatus } from 'horizontal/packages';
import { AdState } from 'horizontal/types';
import { selectProductPurchases } from 'horizontal/packages/selectors';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

export type PostAdSuccessRouteParams = {
    readonly adExternalID: string;
    readonly hidePurchaseSuggestions: boolean;
};

class AgencyPortalPostAdSuccessRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_POST_AD_SUCCESS, [
            [
                '^/agencyPortal/packages/consume/limits/',
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '/success',
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({
        adExternalID,
        hidePurchaseSuggestions,
    }: PostAdSuccessRouteParams): EnhancedLocation {
        return {
            pathname: `/agencyPortal/packages/consume/limits/${adExternalID}/success`,
            search: { hidePurchaseSuggestions },
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context)) {
            return;
        }

        context.rendering.renderPage(Page.AGENCY_PORTAL_POST_AD_SUCCESS, { ad: null });

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { adExternalID, hidePurchaseSuggestions },
            },
        } = context;

        const dataPromise = Promise.all([
            this.fetchAd(context),
            this.cancelable(
                dispatch(
                    fetchProductPurchases({ status: ProductPurchaseStatus.ACTIVE, adExternalID }),
                ),
            ),
            this.cancelable(dispatch(fetchFreeAdLimitUsage())),
        ]);

        context.promise.wait(
            dataPromise
                .then(([ad]: [FullAd | null | undefined, unknown, unknown]) => {
                    if (
                        ad &&
                        [AdState.REJECTED, AdState.SOFT_REJECTED].some(
                            (adState) => adState === ad.state,
                        )
                    ) {
                        context.rendering.renderPage(Page.AGENCY_PORTAL_REJECTED_AD);
                        return;
                    }

                    if (
                        !ad ||
                        ![AdState.ACTIVE, AdState.PENDING].some((adState) => adState === ad.state)
                    ) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    const productPurchases = selectProductPurchases(getState());
                    const eligibleProductPurchases = hidePurchaseSuggestions
                        ? EMPTY_ARRAY
                        : filterEligibleProductPurchases(ad, productPurchases);

                    context.rendering.renderPage(Page.AGENCY_PORTAL_POST_AD_SUCCESS, {
                        ad,
                        productPurchases: eligibleProductPurchases,
                    });
                })
                .catch(catchCanceled),
        );
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<FullAd | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { adExternalID },
            },
        } = context;

        const state = getState();
        const userExternalID = selectUserExternalID(state);
        if (!userExternalID) {
            return Promise.resolve(null);
        }

        return this.cancelable(getStratAPI(state).userAd(userExternalID, adExternalID)).then(
            (response) => (response.status === 200 ? response.data : null),
        );
    }
}

export default new AgencyPortalPostAdSuccessRoute();
