import * as React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { useDialog } from 'strat/dialogs/dialogContext';
import { DialogNames } from 'strat/dialogs';

import { GlobalState } from 'horizontal/state';
import useIsAgencyExternallyManaged from 'horizontal/adDetails/useIsAgencyExternallyManaged';
import useOnWhatsAppButtonClick from 'horizontal/adDetails/useOnWhatsAppButtonClick';
import { useEnsureHasActiveUser } from 'horizontal/user';
import { selectShouldShowPhoneNumberByCategory } from 'horizontal/categories/selectors';
import { MixedAd } from 'horizontal/types';
import { useFetchAdContactInfo } from 'horizontal/ad';

type Props = {
    readonly ad: MixedAd | null | undefined;
    readonly trackWhatsAppLead: (viewSection: Values<typeof ViewSections>) => Promise<string>;
    readonly viewSection: Values<typeof ViewSections>;
    readonly shouldOpenCTADialog?: boolean;
};
const useOnWhatsappCTACLick = ({
    ad,
    trackWhatsAppLead,
    viewSection = ViewSections.FOOTER,
    shouldOpenCTADialog,
}: Props) => {
    const [, setInternalErrorDialogVisible] = useDialog(DialogNames.INTERNAL_ERROR_DIALOG);
    const categoryExternalID = ad?.category[ad?.category.length - 1]?.externalID;
    const isPhoneNumberPublic: boolean = useSelector((state: GlobalState) =>
        selectShouldShowPhoneNumberByCategory(state, categoryExternalID),
    );
    const contactSeller = () => {
        fetchContactInfo();
    };

    const guardContactSeller = useEnsureHasActiveUser({
        onDone: contactSeller,
    });

    const { contactInfo, loading, fetchContactInfo, isError } = useFetchAdContactInfo(
        ad?.externalID,
    );

    const whatsAppPhoneNumber = contactInfo?.whatsapp;

    const isWhatsAppPublic = useIsAgencyExternallyManaged(ad?.agency) || isPhoneNumberPublic;

    const onWhatsAppButtonClick = useOnWhatsAppButtonClick({
        ad,
        trackWhatsAppLead,
        viewSection,
        shouldOpenCTADialog,
        whatsAppPhoneNumber,
    });

    React.useEffect(() => {
        if (isError) {
            setInternalErrorDialogVisible(true, {
                hideGoToHomeButton: shouldOpenCTADialog,
            });
            return;
        }

        if (!loading && whatsAppPhoneNumber) {
            onWhatsAppButtonClick();
        }
    }, [
        ad,
        isError,
        loading,
        onWhatsAppButtonClick,
        setInternalErrorDialogVisible,
        whatsAppPhoneNumber,
        shouldOpenCTADialog,
    ]);
    return isWhatsAppPublic ? contactSeller : guardContactSeller;
};

export default useOnWhatsappCTACLick;
