//@ts-nocheck
import { t } from '@lingui/macro';
import omit from 'lodash/omit';
import memoize from 'fast-memoize';
import type { I18n } from '@lingui/core';
import filterValues from '@app/branding/filterValues';
import { panoramaFilterName, panoramaCompactFilterName } from '@app/branding/property360ViewNames';

import Category from 'strat/branding/category';
import {
    priceChoiceBucketsValues,
    areaChoiceBucketsValues,
} from '@app/branding/filterBucketsValues';
import {
    PropertyType,
    PropertyCompletionStatus,
    PropertyResidenceType,
    PropertyfurnishingStatus,
    PropertyProductLabel,
    OffPlanPropertySaleType,
    LocationNodeData,
} from 'strat/property/types';
import Purpose from 'strat/purpose';
import { makeNumberFormatterFactory } from 'strat/i18n/language/numberFormatter';
import type { Range } from 'strat/types';
import { LocationCompletionStatus } from 'strat/locations/types';
import { AgentCategory } from 'strat/agency/agent/types';
import { numberFormatterLocaleFromLanguage } from 'strat/i18n/language/locales';

import computeYearlyQuarters from './computeYearlyQuarters';

export const FilterPrecedence = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
});

export default {
    page: {
        attribute: 'page',
        default: null,
    },
    externalID: {
        attribute: 'externalID',
        default: null,
    },
    totalProductCount: 'totalProductCount',
    studioFilter: filterValues.studioFilter || {
        key: 'studioFilter',
        attribute: 'category.slug',
        value: [Category.APARTMENTS, Category.HOTEL_APARTMENTS],
    },
    floorPlanID: {
        attribute: 'floorPlanID',
        default: null,
        displayName: (i18n) => t(i18n)`Floor plans`,
        compactDisplayName: (i18n) => t(i18n)`Must have Floor Plan`,
        precedence: FilterPrecedence.SECONDARY,
    },
    hasTransactionHistory: {
        attribute: 'hasTransactionHistory',
        default: null,
    },
    hasDldBuildingNk: {
        attribute: 'hasDldBuildingNk',
        default: null,
    },
    purpose: {
        attribute: 'purpose',
        truBrokerAttribute: 'proboard_purpose',
        default: (filterValues.purpose || {}).default || Purpose.FOR_SALE,
        defaultDT: Purpose.FOR_SALE,
        choices: (i18n) => [
            { name: t(i18n)`Buy`, value: Purpose.FOR_SALE },
            { name: t(i18n)`Rent_filterValue`, value: Purpose.FOR_RENT },
        ],
        agentChoices: (i18n) => [
            { name: t(i18n)`All`, value: Purpose.ANY },
            { name: t(i18n)`Buy`, value: Purpose.FOR_SALE },
            { name: t(i18n)`Rent_filterValue`, value: Purpose.FOR_RENT },
        ],
        precedence: FilterPrecedence.PRIMARY,
    },
    price: {
        attribute: 'price',
        default: {
            min: 0,
            max: null,
        },
        choices: (purpose, category) => {
            if (filterValues.price) {
                return filterValues.price.choices(purpose, category);
            }
            if (priceChoiceBucketsValues[purpose][category]) {
                return priceChoiceBucketsValues[purpose][category];
            }
            return priceChoiceBucketsValues[purpose].null;
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    area: {
        attribute: 'area',
        default: {
            min: 0,
            max: null,
        },
        choices: (unit, category) => {
            if (filterValues.area) {
                return filterValues.area.choices(unit, category);
            }
            if (areaChoiceBucketsValues[unit][category]) {
                return areaChoiceBucketsValues[unit][category];
            }
            return areaChoiceBucketsValues[unit].null;
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    landArea: {
        default: {
            min: 0,
            max: null,
        },
    },
    rentFrequency: {
        attribute: 'rentFrequency',
        default: (filterValues.rentFrequency || {}).default || 'yearly',
        disablingValue: 'any',
        // The values for the divider where selected to be consistend with the
        // dividers in choices.
        divider: (value) => {
            switch (value) {
                case 'yearly':
                    return 1;
                case 'monthly':
                    return 10;
                case 'weekly':
                    return 50;
                case 'daily':
                    return 360;
                case 'any':
                    return 1;
                default:
                    return null;
            }
        },
        // The values of the dividers were selected to allow for nice round
        // numbers when going from 'yearly' to 'monthly' and so on
        // See also {@see filterBucketsValues}
        choice: (i18n, value) => {
            switch (value) {
                case 'yearly':
                    return { name: t(i18n)`Yearly`, value: 'yearly', divider: 1 };
                case 'monthly':
                    return { name: t(i18n)`Monthly`, value: 'monthly', divider: 10 };
                case 'weekly':
                    return { name: t(i18n)`Weekly`, value: 'weekly', divider: 50 };
                case 'daily':
                    return { name: t(i18n)`Daily`, value: 'daily', divider: 360 };
                case 'any':
                    return { name: t(i18n)`Any`, value: 'any', divider: 1 };
                default:
                    return null;
            }
        },
        choices(i18n: any, params = { anyFirst: false }) {
            const choices = [
                this.choice(i18n, 'yearly'),
                this.choice(i18n, 'monthly'),
                this.choice(i18n, 'weekly'),
                this.choice(i18n, 'daily'),
            ];
            if (params.anyFirst) {
                choices.unshift(this.choice(i18n, 'any'));
            } else {
                choices.push(this.choice(i18n, 'any'));
            }
            return choices;
        },
        proboardType: (value) => {
            switch (value) {
                case 'yearly':
                    return AgentCategory.LONG_TERM_RESIDENTIAL;
                case 'monthly':
                case 'weekly':
                case 'daily':
                    return AgentCategory.SHORT_TERM_RESIDENTIAL;
                case 'any':
                    return AgentCategory.LONG_TERM_RESIDENTIAL;
                default:
                    return null;
            }
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    beds: {
        attribute: 'rooms',
        default: null,
        choices: (i18n) =>
            filterValues.beds
                ? filterValues.beds.choices(i18n)
                : [
                      { name: t(i18n)`Studio`, value: 0, expression: ': 0' },
                      ...[1, 2, 3, 4, 5, 6, 7].map((i) => ({
                          name: `${makeNumberFormatterFactory(numberFormatterLocaleFromLanguage(i18n.locale))().format(i)}`,
                          value: i,
                          expression: `: ${i}`,
                      })),
                      { name: t(i18n)`8+`, value: '8+', expression: ' >= 8' },
                  ],
        precedence: FilterPrecedence.PRIMARY,
    },
    baths: {
        attribute: 'baths',
        default: null,
        choices: (i18n) =>
            filterValues.baths?.choices
                ? filterValues.baths.choices(i18n)
                : [
                      ...[1, 2, 3, 4, 5].map((i) => ({
                          name: `${makeNumberFormatterFactory(numberFormatterLocaleFromLanguage(i18n.locale))().format(i)}`,
                          value: i,
                          expression: `: ${i}`,
                      })),
                      { name: t(i18n)`6+`, value: '6+', expression: ' >= 6' },
                  ],
        precedence: FilterPrecedence.SECONDARY,
    },
    location: {
        attribute:
            filterValues?.location?.attribute ||
            (CONFIG.build.USE_MULTIPLE_LOCATIONS ? 'locations.slug' : 'location.slug'),
        default: null,
        placeholder: filterValues.location.placeholder,
        placeholderName: filterValues.location.placeholderName,
        placeholders: filterValues.location.placeholders || {},
        placeholderExternalID: filterValues.location.placeholderExternalID,
        precedence: FilterPrecedence.PRIMARY,
    },
    locationCoordinates: {
        attribute: 'geo_point',
        default: null,
    },
    locationRadius: {
        attribute: 'locationRadius',
        default: null,
    },
    location_l1: {
        attribute: CONFIG.build.USE_MULTIPLE_LOCATIONS ? 'locations.slug_l1' : 'location.slug_l1',
        default: null,
        placeholder: filterValues.location.placeholder,
        placeholderName: filterValues.location.placeholderName,
        placeholders: filterValues.location.placeholders || {},
    },
    location_l2: {
        attribute: CONFIG.build.USE_MULTIPLE_LOCATIONS ? 'locations.slug_l2' : 'location.slug_l2',
        default: null,
        placeholder: filterValues.location.placeholder,
        placeholderName: filterValues.location.placeholderName,
        placeholders: filterValues.location.placeholders || {},
    },
    agency: {
        attribute: 'agency.externalID',
        default: null,
        placeholder: (i18n) => t(i18n)`Agency name`,
        precedence: FilterPrecedence.SECONDARY,
    },
    excludeAgencies: {
        attribute: 'ipckg',
        default: false,
    },
    agencySlug: {
        attribute: 'agency.slug',
        default: null,
    },
    agentSlug: {
        attribute: 'agent.slug',
        default: null,
        precedence: FilterPrecedence.SECONDARY,
    },
    agentOwner: {
        attribute: 'ownerID',
        default: null,
    },
    agentLocation: {
        attribute: 'stats.locationsWithAds',
        truBrokerAttribute: 'proboard_location',
        default: null,
    },
    category: {
        attribute: filterValues?.category?.attribute || 'category.slug',
        default: filterValues.category?.default || null,
        placeholder: filterValues.category?.placeholder || 'property',
        placeholderName: (i18n) => t(i18n)`Properties`,
        placeholderNameSingular: (i18n) => t(i18n)`Property`,
        canBeTruChecked: filterValues.category.canBeTruChecked || (() => true),
        tree: memoize(filterValues.category.tree, {
            serializer: (i18n) =>
                JSON.stringify({ ...i18n, _catalogs: undefined, _activeMessages: undefined }),
        }),
        dtTree: memoize(filterValues.category.dtTree || {}, {
            serializer: (i18n) =>
                JSON.stringify({ ...i18n, _catalogs: undefined, _activeMessages: undefined }),
        }),
        choices(i18n: any, options = {}): Array<CategoryNodeData> {
            const { skipRoot = false } = options;

            const flatten = (choices: any, parent: any) =>
                Object.keys(choices).map((slug) => ({
                    slug,
                    parent: !parent ? null : omit(parent, ['children']),
                    ...omit(choices[slug], ['children']),
                }));

            return [].concat(
                skipRoot ? [] : flatten(this.tree(i18n)),
                ...Object.keys(this.tree(i18n)).map((mainCategory) =>
                    flatten(this.tree(i18n)[mainCategory].children, this.tree(i18n)[mainCategory]),
                ),
            );
        },
        find(i18n: any, slug: any) {
            const tree = this.tree(i18n);

            return (
                tree[slug] ||
                Object.values(tree)
                    .map(({ children }) => children[slug])
                    .filter((x) => x)[0]
            );
        },
        render(i18n: any, categorySlug: any, plural = false) {
            if (!categorySlug) {
                return null;
            }

            const category = this.find(i18n, categorySlug);

            return category
                ? (plural ? category.name : category.nameSingular) || category.name
                : null;
        },
        precedence: FilterPrecedence.PRIMARY,
    },
    keywords: {
        attribute: 'keywords',
        default: null,
        precedence: FilterPrecedence.SECONDARY,
    },
    amenities: {
        attribute: 'amenities',
        default: null,
        precedence: FilterPrecedence.SECONDARY,
    },
    city: {
        attribute: 'city.slug',
        placeholder: (i18n) => t(i18n)`Select City`,
        default: (filterValues.city || {}).default || null,
        choices: (filterValues.city || {}).choices || null,
    },
    product: {
        attribute: 'product',
        default: null,
        choices: (filterValues.product || {}).choices || null,
    },
    agencyType: {
        attribute: 'agency.product',
        default: null,
        choices: (filterValues.agencyType || {}).choices || null,
        titaniumChoices: (filterValues.agencyType || {}).titaniumChoices || [],
    },
    photoCount: {
        attribute: 'photoCount',
        default: {
            min: 0,
            max: null,
        },
        displayName: (i18n) =>
            (filterValues.photoCount?.displayName && filterValues.photoCount.displayName(i18n)) ||
            t(i18n)`Photos`,
    },
    videoCount: {
        attribute: 'videoCount',
        default: {
            min: 0,
            max: null,
        },
        displayName: (i18n) =>
            (filterValues.videoCount?.displayName && filterValues.videoCount.displayName(i18n)) ||
            t(i18n)`Video tours`,
        compactDisplayName: (i18n) =>
            (filterValues.videoCount?.displayName && filterValues.videoCount.displayName(i18n)) ||
            t(i18n)`Must have Video`,
        compactDisplayNameShort: (i18n) =>
            (filterValues.videoCount?.displayName && filterValues.videoCount.displayName(i18n)) ||
            t(i18n)`Video`,
        precedence: FilterPrecedence.SECONDARY,
    },
    panoramaCount: {
        attribute: 'panoramaCount',
        default: {
            min: 0,
            max: null,
        },
        displayName: (i18n) => panoramaFilterName(i18n),
        compactDisplayName: (i18n) => panoramaCompactFilterName(i18n),
        precedence: FilterPrecedence.SECONDARY,
    },
    occupancyStatus: {
        attribute: 'occupancyStatus',
        default: (filterValues.occupancyStatus || {}).default || null,
        choice: (filterValues.occupancyStatus || {}).choice || null,
        choices: (filterValues.occupancyStatus || {}).choices || null,
    },
    ownershipStatus: {
        attribute: 'ownershipStatus',
        default: (filterValues.ownershipStatus || {}).default || null,
        choice: (filterValues.ownershipStatus || {}).choice || null,
        choices: (filterValues.ownershipStatus || {}).choices || null,
    },
    paymentType: {
        attribute: 'installments.remainingInstallments',
        default: (filterValues.paymentType || {}).default || null,
        choice: (filterValues.paymentType || {}).choice || null,
        choices: (filterValues.paymentType || {}).choices || null,
        range: (filterValues.paymentType || {}).range || ((_: any) => null),
        value: (filterValues.paymentType || {}).value || null,
    },
    isVerified: {
        attribute: 'isVerified',
        default: null,
    },
    propertyTour: {
        attribute: 'propertyTour',
        default: null,
        precedence: FilterPrecedence.SECONDARY,
    },
    timeSinceCreation: {
        attribute: 'createdAt',
        choice: (filterValues.timeSinceCreation || {}).choice || null,
        choices: (filterValues.timeSinceCreation || {}).choices || null,
        mapChoices: (filterValues.timeSinceCreation || {}).mapChoices || null,
        mapChoicesMobileRent: (filterValues.timeSinceCreation || {}).mapChoicesMobileRent || null,
        default: (filterValues.timeSinceCreation || {}).default || null,
        defaultMap: (filterValues.timeSinceCreation || {}).defaultMap || null,
        defaultMapMobileRent: (filterValues.timeSinceCreation || {}).defaultMapMobileRent || null,
        getDefaultValue: (isMapActive: boolean) => {
            const filter = filterValues.timeSinceCreation || {};
            return isMapActive ? filter.defaultMap : filter.default;
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    agencyTier: {
        attribute: 'agency.tier',
        default: null,
    },
    residenceType: {
        attribute: 'residenceType',
        default: null,
        choices: (i18n) => [
            { name: t(i18n)`Any-Residence Type`, value: null },
            { name: t(i18n)`Family`, value: PropertyResidenceType.FAMILY },
            { name: t(i18n)`Singles`, value: PropertyResidenceType.SINGLES },
        ],
    },
    completionStatus: {
        displayName: (i18n) =>
            (filterValues.completionStatus?.displayName &&
                filterValues.completionStatus?.displayName(i18n)) ||
            t(i18n)`Completion Status`,
        attribute: 'completionStatus',
        default: PropertyCompletionStatus.ANY,
        disablingValue: PropertyCompletionStatus.ANY,
        choice: (filterValues.completionStatus || {}).choice || null,
        choices: (filterValues.completionStatus || {}).choices || null,
        queryParamValues: (filterValues.completionStatus || {}).queryParamValues || [],
        render: (i18n, value) =>
            filterValues.completionStatus?.render &&
            filterValues.completionStatus.render(i18n, value),
        precedence: FilterPrecedence.PRIMARY,
        isDefault(value: string) {
            return value === this.default;
        },
        isDisabled(
            choice: Values<typeof PropertyCompletionStatus>,
            locations: LocationNodeData[] | string[],
        ) {
            if (choice === PropertyCompletionStatus.ANY) {
                return false;
            }

            if (!locations || !locations.length) {
                return false;
            }

            return locations.every((location: LocationNodeData | string) => {
                if (typeof location === 'string') {
                    return false;
                }

                if (location.hasChildren) {
                    return false;
                }

                if (choice === PropertyCompletionStatus.COMPLETED) {
                    return location.completionStatus === LocationCompletionStatus.OFF_PLAN;
                }

                return location.completionStatus === LocationCompletionStatus.READY;
            });
        },
    },
    furnishingStatus: {
        attribute: 'furnishingStatus',
        default: PropertyfurnishingStatus.ANY,
        choice: (i18n, value) => {
            switch (value) {
                case PropertyfurnishingStatus.ANY:
                    return {
                        name: t(i18n)`Any-Furnishing Status`,
                        value: PropertyfurnishingStatus.ANY,
                    };
                case PropertyfurnishingStatus.FURNISHED:
                    return { name: t(i18n)`Furnished`, value: PropertyfurnishingStatus.FURNISHED };
                case PropertyfurnishingStatus.UNFURNISHED:
                    return {
                        name: t(i18n)`Unfurnished`,
                        value: PropertyfurnishingStatus.UNFURNISHED,
                    };
                default:
                    return { name: t(i18n)`Any`, value: PropertyfurnishingStatus.ANY };
            }
        },
        choices(i18n: any) {
            return [
                this.choice(i18n, PropertyfurnishingStatus.ANY),
                this.choice(i18n, PropertyfurnishingStatus.FURNISHED),
                this.choice(i18n, PropertyfurnishingStatus.UNFURNISHED),
            ];
        },
        render: (i18n, value) => {
            switch (value) {
                case PropertyfurnishingStatus.FURNISHED:
                    return t(i18n)`Furnished`;
                case PropertyfurnishingStatus.UNFURNISHED:
                    return t(i18n)`Unfurnished`;
                case PropertyfurnishingStatus.ANY:
                default:
                    return null;
            }
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    hidePrice: {
        attribute: 'hidePrice',
        default: null,
    },
    productLabel: {
        attribute: 'productLabel',
        default: PropertyProductLabel.DEFAULT,
        choices: PropertyProductLabel,
    },
    showCurrentMonth: {
        attribute: 'showCurrentMonth',
        default: 'true',
        precedence: FilterPrecedence.SECONDARY,
    },
    languages: {
        attribute: 'language_codes',
        default: [],
    },
    contractRenewalStatus: {
        attribute: 'contractRenewalStatus',
        default: 'All',
        choice: (i18n: I18n, value: string) => {
            switch (value) {
                case 'New':
                    return { name: t(i18n)`New`, value };
                case 'Renewal':
                    return { name: t(i18n)`Renewal`, value };
                case 'All':
                default:
                    return { name: t(i18n)`All`, value: 'All' };
            }
        },
        choices(i18n: I18n) {
            return [
                this.choice(i18n, 'All'),
                this.choice(i18n, 'New'),
                this.choice(i18n, 'Renewal'),
            ];
        },
        findChoice(i18n: I18n, value: string) {
            return this.choices(i18n).find((crtChoice) => crtChoice.value === value);
        },
        isDefault(value: string) {
            return value === this.default;
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    completionPercentage: {
        attribute: 'completionDetails.completionPercentage',
        default: {
            min: null,
            max: null,
        },
        isDefault(value: Range | null) {
            if (!value) {
                return true;
            }

            return value.min === null && value.max === null;
        },
        choice(i18n: I18n, value: string) {
            switch (value) {
                case '0-25%':
                    return {
                        name: t(i18n)`0-25%`,
                        value: { min: null, max: 25 },
                        trackingValue: '0-25',
                    };
                case '25-50%':
                    return {
                        name: t(i18n)`25-50%`,
                        value: { min: 25, max: 50 },
                        trackingValue: '25-50',
                    };
                case '50-75%':
                    return {
                        name: t(i18n)`50-75%`,
                        value: { min: 50, max: 75 },
                        trackingValue: '50-75',
                    };
                case '75-100%':
                    return {
                        name: t(i18n)`75-100%`,
                        value: { min: 75, max: null },
                        trackingValue: '75-100',
                    };
                default:
                    return {
                        name: t(i18n)`Any`,
                        value: { min: null, max: null },
                        trackingValue: null,
                    };
            }
        },
        findChoice(i18n: I18n, value: Range | null) {
            return this.choices(i18n).find(
                (choice) => choice.value.min === value.min && choice.value.max === value.max,
            );
        },
        choices(i18n: I18n) {
            return [
                this.choice(i18n, '0-25%'),
                this.choice(i18n, '25-50%'),
                this.choice(i18n, '50-75%'),
                this.choice(i18n, '75-100%'),
            ];
        },
        render(i18n: I18n, value: Range | null) {
            if (!value) {
                return this.placeholder(i18n);
            }

            const choice = this.findChoice(i18n, value);

            if (!choice) {
                return null;
            }

            if (choice.value.min || choice.value.max) {
                return t(i18n)`${choice.name} Completed`;
            }

            return choice.name;
        },
        placeholder(i18n) {
            return t(i18n)`% Completion`;
        },
    },
    handoverDate: {
        attribute: 'completionDetails.completionDate',
        default: {
            min: null,
            max: null,
        },
        choice(i18n, { quarter, year }: { year: number; quarter?: number }) {
            if (!quarter) {
                return {
                    name: year,
                    value: { min: null, max: Date.UTC(year + 1) / 1000 },
                    trackingValue: year.toString(),
                    htmlValue: year.toString(),
                };
            }

            // We need to include the selected quarter/year, so we need
            // to increment everything by 1 quarter.
            const nextQuarter = quarter === 4 ? 1 : quarter + 1;
            const nextYear = quarter === 4 ? year + 1 : year;

            return {
                name: t(i18n)`Q${quarter} ${year}`,
                value: { min: null, max: Date.UTC(nextYear, (nextQuarter - 1) * 3) / 1000 },
                trackingValue: `Q${quarter} ${year}`,
                htmlValue: `${year.toString()}Q${quarter}`,
            };
        },
        choices(i18n: I18n) {
            const now = new Date();
            const quarters = computeYearlyQuarters(now, {
                quarteredYears: 2,
                totalYears: 7,
                includeCurrentQuarter: true,
            });
            return quarters.map((choice) => this.choice(i18n, choice));
        },
        isDefault(value: Range | null) {
            if (!value) {
                return true;
            }

            return (value?.min ?? 0) === 0 && (value?.max ?? 100) === 100;
        },
        placeholder(i18n) {
            return t(i18n)`Handover By`;
        },
        findChoice(i18n: I18n, value?: Range | null) {
            if (!value) {
                return null;
            }
            return this.choices(i18n).find((candidate) => candidate.value.max === value?.max);
        },
        render(i18n: I18n, value) {
            if (this.isDefault(value)) {
                return this.placeholder(i18n);
            }

            const choice = this.findChoice(i18n, value);

            if (!choice) {
                return this.placeholder(i18n);
            }

            return t(i18n)`By ${choice.name}`;
        },
    },
    preHandoverPayment: {
        attribute: 'paymentPlanSummaries.preHandoverPercentageSum',
        default: {
            min: null,
            max: null,
        },
        placeholder(i18n) {
            return t(i18n)`Payment Plan`;
        },
        isDefault(value: Range | null) {
            if (!value) {
                return true;
            }

            return (value?.min ?? 0) === 0 && (value?.max ?? 100) === 100;
        },
        render(i18n, value: Range | null) {
            if (this.isDefault(value)) {
                return this.placeholder(i18n);
            }

            const max = value?.max ?? 100;
            if (max === 0) {
                return t(i18n)`${max}% Pre-handover`;
            }

            return t(i18n)`Up to ${max}% Pre-handover`;
        },
    },
    saleType: {
        attribute: 'offplanDetails.saleType',
        default: OffPlanPropertySaleType.ANY,
        choice: (i18n, value) => {
            switch (value) {
                case OffPlanPropertySaleType.ANY:
                    return { name: t(i18n)`Any`, value: OffPlanPropertySaleType.ANY };
                case OffPlanPropertySaleType.INITIAL:
                    return {
                        name: t(i18n)`Off-Plan Initial Sale`,
                        value: OffPlanPropertySaleType.INITIAL,
                    };
                case OffPlanPropertySaleType.RESALE:
                    return {
                        name: t(i18n)`Off-Plan Resale`,
                        value: OffPlanPropertySaleType.RESALE,
                    };
                default:
                    return { name: t(i18n)`Any`, value: OffPlanPropertySaleType.ANY };
            }
        },
        choices(i18n: any) {
            return [
                this.choice(i18n, OffPlanPropertySaleType.ANY),
                this.choice(i18n, OffPlanPropertySaleType.INITIAL),
                this.choice(i18n, OffPlanPropertySaleType.RESALE),
            ];
        },
        render: (i18n, value) => {
            switch (value) {
                case OffPlanPropertySaleType.INITIAL:
                    return t(i18n)`Off-Plan Initial Sale`;
                case OffPlanPropertySaleType.RESALE:
                    return t(i18n)`Off-Plan Resale`;
                case OffPlanPropertySaleType.ANY:
                default:
                    return t(i18n)`Any`;
            }
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    type: {
        attribute: 'type',
        default: PropertyType.PROPERTY,
        choices(i18n: any) {
            const choices = [{ name: t(i18n)`Properties`, value: PropertyType.PROPERTY }];

            if (CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH) {
                choices.push({ name: t(i18n)`Projects`, value: PropertyType.PROJECT });
            }

            return choices;
        },
    },
    unitCategories: {
        attribute: 'unitCategories.slug',
        default: filterValues.category?.default || null,
    },
    agentSaleCategory: {
        attribute: 'agentSaleCategory',
        default: AgentCategory.ANY,
        choice: (i18n: I18n, value: string) => {
            switch (value) {
                case AgentCategory.RESIDENTIAL:
                    return {
                        name: t(i18n)`Residential`,
                        value: AgentCategory.RESIDENTIAL,
                        attribute: 'residential',
                    };
                case AgentCategory.COMMERCIAL:
                    return {
                        name: t(i18n)`Commercial`,
                        value: AgentCategory.COMMERCIAL,
                        attribute: 'commercial',
                    };
                case 'All':
                default:
                    return { name: t(i18n)`All`, value: AgentCategory.ANY, attribute: 'total' };
            }
        },
        choices(i18n: I18n) {
            return [
                this.choice(i18n, AgentCategory.ANY),
                this.choice(i18n, AgentCategory.RESIDENTIAL),
                this.choice(i18n, AgentCategory.COMMERCIAL),
            ];
        },
        findChoice(i18n: I18n, value: string) {
            return this.choices(i18n).find((crtChoice) => crtChoice.value === value);
        },
        isDefault(value: string) {
            return value === this.default;
        },
        findSearchIndexAttribute(value: string | null, i18n: I18n) {
            return this.findChoice(i18n, value).attribute;
        },
        findNameByValue(i18n: I18n, value: string | null) {
            return this.findChoice(i18n, value).name;
        },
        leaderboardTitleText(i18n: I18n, value: string | null) {
            switch (value) {
                case AgentCategory.RESIDENTIAL:
                    return t(i18n)`Residential`;
                case AgentCategory.COMMERCIAL:
                    return t(i18n)`Commercial`;
                case 'All':
                default:
                    return '';
            }
        },
        getValidValues(i18n: I18n): Array<string> {
            return this.choices(i18n).map((choice) => choice.value);
        },
        precedence: FilterPrecedence.SECONDARY,
    },
    agentRentalCategory: {
        attribute: 'agentRentalCategory',
        default: AgentCategory.ANY,
        choice: (i18n: I18n, value: string) => {
            switch (value) {
                case AgentCategory.LONG_TERM_RESIDENTIAL:
                    return {
                        name: t(i18n)`Residential Long-Term`,
                        value: AgentCategory.LONG_TERM_RESIDENTIAL,
                        attribute: 'residential|long_term_residential',
                    };
                case AgentCategory.SHORT_TERM_RESIDENTIAL:
                    return {
                        name: t(i18n)`Residential Short-Term`,
                        value: AgentCategory.SHORT_TERM_RESIDENTIAL,
                        attribute: 'residential|short_term_residential',
                    };
                case AgentCategory.COMMERCIAL:
                    return {
                        name: t(i18n)`Commercial`,
                        value: AgentCategory.COMMERCIAL,
                        attribute: 'commercial',
                    };
                case AgentCategory.ANY:
                default:
                    return { name: t(i18n)`All`, value: AgentCategory.ANY, attribute: 'total' };
            }
        },
        choices(i18n: I18n) {
            return [
                this.choice(i18n, AgentCategory.ANY),
                this.choice(i18n, AgentCategory.LONG_TERM_RESIDENTIAL),
                this.choice(i18n, AgentCategory.SHORT_TERM_RESIDENTIAL),
                this.choice(i18n, AgentCategory.COMMERCIAL),
            ];
        },
        findChoice(i18n: I18n, value: string) {
            return this.choices(i18n).find((crtChoice) => crtChoice.value === value);
        },
        isDefault(value: string) {
            return value === this.default;
        },
        findSearchIndexAttribute(value: string | null, i18n: I18n) {
            return this.findChoice(i18n, value).attribute;
        },
        findNameByValue(i18n: I18n, value: string | null) {
            return this.findChoice(i18n, value).name;
        },
        leaderboardTitleText(i18n: I18n, value: string | null) {
            switch (value) {
                case AgentCategory.RESIDENTIAL:
                    return t(i18n)`Residential`;
                case AgentCategory.SHORT_TERM_RESIDENTIAL:
                    return t(i18n)`Short-Term Residential`;
                case AgentCategory.LONG_TERM_RESIDENTIAL:
                    return t(i18n)`Long-Term Residential`;
                case 'All':
                default:
                    return '';
            }
        },
        getValidValues(i18n: I18n): Array<string> {
            return this.choices(i18n).map((choice) => choice.value);
        },
        precedence: FilterPrecedence.SECONDARY,
    },
};
