import { setGeoLocation } from 'strat/user/state';
import { buildCanonicalURL } from 'strat/routing';
import { serializeLocation } from 'react-true-router/location';
import { createSearchURL } from 'strat/search/url';
import { selectLanguage } from 'strat/i18n/language';
import { RoutingContextWithMiddlewares } from 'strat/app';

import { setInitialLocationState } from 'horizontal/location';
import { fetchURLSpecificSEOs } from 'horizontal/urlSpecificSEO/state';
import { fetchAdsAndFrequentSearches } from 'horizontal/search/hits';
import { SearchRouteParams } from 'horizontal/routes/createSearchURL';

import prefetchChildLocations from './prefetchChildLocations';

const postFetchDataForSearchPageRendering = (
    context: RoutingContextWithMiddlewares,
    searchRouteParams: SearchRouteParams,
): Promise<void> => {
    const {
        redux: {
            store: { getState, dispatch },
        },
    } = context;
    const { location } = searchRouteParams;
    const state = getState();
    const language = selectLanguage(state);

    if (location && state.user.geoLocation.closestLocation) {
        dispatch(setGeoLocation(null, null));
    }
    if (location) {
        setInitialLocationState(dispatch, location);
    }

    const { page, ...paramsWithoutPage } = searchRouteParams;
    if (page === 0 || page === 1) {
        const url = buildCanonicalURL(createSearchURL(paramsWithoutPage, state).pathname, language);
        context.http.redirect(url, { isAbsolute: true });
        return Promise.resolve();
    }

    return Promise.all([
        prefetchChildLocations(location, getState, dispatch),
        dispatch(
            fetchURLSpecificSEOs({
                language,
                url: buildCanonicalURL(
                    serializeLocation(createSearchURL(searchRouteParams, state)),
                    language,
                ),
            }),
        ),
        fetchAdsAndFrequentSearches(context, searchRouteParams),
    ]).then(() => {});
};

export default postFetchDataForSearchPageRendering;
