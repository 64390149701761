import createDefaultStore from './createDefaultStore';
import defaultReducers from './defaultReducers';
import createStore from './store';
import reducers from './reducers';

export { createDefaultStore, createStore, reducers };

export { type GlobalState } from './globalState';
export { type AppDispatch } from './appDispatch';

export default defaultReducers;
