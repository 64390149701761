import Mobiles from 'horizontal/assets/categoryImages/mobiles.png';
import Vehicles from 'horizontal/assets/categoryImages/vehicles.png';
import PropertyForRent from 'horizontal/assets/categoryImages/property-for-rent.png';
import PropertyForSale from 'horizontal/assets/categoryImages/property-for-sale.png';
import ElectronicsHomeAppliances from 'horizontal/assets/categoryImages/electronics-home-appliances.png';
import Bikes from 'horizontal/assets/categoryImages/bikes.png';
import BusinessIndustrialAgriculture from 'horizontal/assets/categoryImages/business-industrial-agriculture.png';
import Services from 'horizontal/assets/categoryImages/services.png';
import Jobs from 'horizontal/assets/categoryImages/jobs.png';
import Animals from 'horizontal/assets/categoryImages/animals.png';
import FurnitureHomeDecor from 'horizontal/assets/categoryImages/furniture-home-decor.png';
import FashionBeauty from 'horizontal/assets/categoryImages/fashion-beauty.png';
import BooksSportsHobbies from 'horizontal/assets/categoryImages/books-sports-hobbies.png';
import Kids from 'horizontal/assets/categoryImages/kids.png';
import Pets from 'horizontal/assets/categoryImages/pets.png';
import SportingGoodsBikes from 'horizontal/assets/categoryImages/sporting-goods-bikes.png';
import SportsEquipment from 'horizontal/assets/categoryImages/sports-equipment.png';
import HobbiesMusicArtBooks from 'horizontal/assets/categoryImages/hobbies-music-art-books.png';
import BusinessIndustrial from 'horizontal/assets/categoryImages/business-industrial.png';
import HomeFurnitureDecor from 'horizontal/assets/categoryImages/home-furniture-decor.png';
import HomeGarden from 'horizontal/assets/categoryImages/home-garden.png';
import JobsServices from 'horizontal/assets/categoryImages/jobs-services.png';
import KidsBabies from 'horizontal/assets/categoryImages/kids-babies.png';
import MobilePhonesTabletsAccessoriesNumbers from 'horizontal/assets/categoryImages/mobile-phones-tablets-accessories-numbers.png';
import MobilePhonesAccessories from 'horizontal/assets/categoryImages/mobile-phones-accessories.png';
import Properties from 'horizontal/assets/categoryImages/property.png';

const CategoryImageMapping = {
    mobiles: Mobiles,
    vehicles: Vehicles,
    'property-for-rent': PropertyForRent,
    'property-for-sale': PropertyForSale,
    'electronics-home-appliances': ElectronicsHomeAppliances,
    bikes: Bikes,
    'business-industrial-agriculture': BusinessIndustrialAgriculture,
    services: Services,
    jobs: Jobs,
    animals: Animals,
    'furniture-home-decor': FurnitureHomeDecor,
    'fashion-beauty': FashionBeauty,
    'books-sports-hobbies': BooksSportsHobbies,
    kids: Kids,
    pets: Pets,
    'sporting-goods-bikes': SportingGoodsBikes,
    'sports-equipments': SportsEquipment,
    'sports-equipment': SportsEquipment,
    'hobbies-music-art-books': HobbiesMusicArtBooks,
    'business-industrial': BusinessIndustrial,
    'home-garden': HomeGarden,
    'home-furniture-decor': HomeFurnitureDecor,
    'jobs-services': JobsServices,
    'kids-babies': KidsBabies,
    'mobile-phones-tablets-accessories-numbers': MobilePhonesTabletsAccessoriesNumbers,
    'mobiles-tablets': Mobiles,
    'mobile-phones-accessories': MobilePhonesAccessories,
    properties: Properties,
} as const;

export default CategoryImageMapping;
