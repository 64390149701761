import { sanitizeDescription } from 'strat/seo';

import type { MixedAd } from 'horizontal/types';

const useCleanedDescription = (ad?: MixedAd | null): string | null | undefined => {
    if (!ad) {
        return null;
    }

    return sanitizeDescription(ad.description, 215);
};

export default useCleanedDescription;
