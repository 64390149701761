import * as React from 'react';
import { Trans } from '@lingui/macro';

import { Flex } from 'strat/components';

import styles from './styles/dialogCounter.cssm';

type Props = {
    readonly slideIndex: number;
    readonly photoCount: number;
};

const DialogCounter = ({ slideIndex, photoCount }: Props) => (
    <Flex alignCenter className={styles.container}>
        <Trans>
            {slideIndex}/{photoCount}
        </Trans>
    </Flex>
);

export default DialogCounter;
