import * as React from 'react';
import classNames from 'classnames';

import type { ActionButton, ChatAd, ChatRoom } from 'horizontal/chat/types';

import { MakeOffer } from '../negotiation';

import Composer from './composer';
import QuestionsTab from './questionsTab';
import styles from './styles/chatAssistantBody.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly expanded: boolean;
    readonly setExpanded: (arg1: boolean) => void;
    readonly selectedTab: number;
    readonly conversation: ChatRoom;
    readonly actionButtons: Array<ActionButton>;
    readonly priceFormVisible: boolean;
    readonly setPriceFormVisible: (arg1: boolean) => void;
};

const ChatAssistantBody = ({
    ad,
    expanded,
    setExpanded,
    selectedTab,
    conversation,
    actionButtons,
    priceFormVisible,
    setPriceFormVisible,
}: Props) => {
    const isQuestionsTab = selectedTab === 0;

    return (
        <div className={classNames(styles.container, { [styles.expanded]: expanded })}>
            <div className={styles.content}>
                {isQuestionsTab ? (
                    <QuestionsTab ad={ad} conversation={conversation} />
                ) : (
                    <MakeOffer
                        conversation={conversation}
                        actionButtons={actionButtons}
                        priceFormVisible={priceFormVisible}
                        setPriceFormVisible={setPriceFormVisible}
                    />
                )}
            </div>
            <Composer
                chatRoom={conversation}
                ad={ad}
                submitOnEnter
                hidden={!isQuestionsTab && expanded}
                onClick={() => setExpanded(false)}
            />
        </div>
    );
};

export default ChatAssistantBody;
