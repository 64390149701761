import brandingSettings from '@app/branding/settings';

import type { SearchRouteParams } from 'horizontal/routes';
import type { LiteCategory, Category, Location } from 'horizontal/types';
import { restrictLocation } from 'horizontal/search/location/disabledLocations';

import { getCategoryIDsDescendants } from './getCategoryIDsDescendants';

const findActiveLocation = (
    locations: Array<Location>,
    categories: Array<Category>,
    activeCategory: LiteCategory,
): Location => {
    const disabledLocationIds = locations
        .map((location) =>
            getCategoryIDsDescendants(location.disabledCategoryIDs, categories, true),
        )
        .reverse();
    const activeLocation = locations
        .reverse()
        .find((_, index) => !disabledLocationIds[index].includes(activeCategory.id));
    return activeLocation || locations[locations.length - 1];
};

export const checkForDisabledLocationInParams = (
    params: SearchRouteParams | null | undefined,
    categories: Array<Category>,
    fetchLocations: any,
): Promise<SearchRouteParams | null | undefined> => {
    return new Promise((resolve, reject: (error?: any) => void) => {
        if (
            params &&
            params.category &&
            params.location &&
            brandingSettings.headerMaxLocationLevel &&
            params.location.level >= brandingSettings.headerMaxLocationLevel
        ) {
            const location = {
                ...params.location,
                disabledCategoryIDs: getCategoryIDsDescendants(
                    params.location.disabledCategoryIDs,
                    categories,
                    true,
                ),
            } as const;

            if (
                location.disabledCategoryIDs.length &&
                location.disabledCategoryIDs.indexOf(params?.category?.id) > -1
            ) {
                const restrictedLocation = restrictLocation(location);

                const externalIDs = (restrictedLocation?.hierarchy || []).map(
                    // @ts-expect-error - TS7006 - Parameter 'currentLocation' implicitly has an 'any' type.
                    (currentLocation) => currentLocation.externalID,
                );
                fetchLocations(externalIDs)
                    // @ts-expect-error - TS7006 - Parameter 'locationData' implicitly has an 'any' type.
                    .then((locationData) => {
                        const updatedLocation = params.category
                            ? findActiveLocation(locationData, categories, params.category)
                            : null;
                        resolve({ ...params, location: updatedLocation });
                    })
                    // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
                    .catch((e) => reject(e));
            } else {
                resolve(params);
            }
        } else {
            resolve(params);
        }
    });
};
