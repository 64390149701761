import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    selectActiveSearchBackend,
    selectSearchLoadingState,
} from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language';
import { trigger, Triggers } from 'strat/gtm';

import { hitsTransformer } from 'horizontal/search/hits';
import type { LiteAd } from 'horizontal/types';

import fetchAdsByFilterCollection from './fetchAdsByFilterCollection';

const useSearchHistoryRecommendations = () => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const searchHistory = useSelector((state) => state.user.searchHistory);
    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);
    const [lastSearchListings, setLastSearchListings] = React.useState([]);
    const { loading } = useSelector(selectSearchLoadingState);

    React.useEffect(() => {
        if (!searchHistory?.length) {
            return;
        }

        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        fetchAdsByFilterCollection(searchHistory[0], language, backend).then((data) => {
            // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
            const newHits = hitsTransformer<LiteAd>(data, language);
            if (!data.length) {
                return;
            }

            // @ts-expect-error - TS2345 - Argument of type 'LiteAd[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setLastSearchListings(newHits);
            if (!loading) {
                trigger(Triggers.SUGGESTED_HISTORY, {
                    suggested_history: newHits.map((history) => history.id),
                });
            }
        });
    }, [searchHistory, setLastSearchListings, language, backend, loading]);

    return { searchHistory, lastSearchListings };
};

export default useSearchHistoryRecommendations;
