import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { Flex } from 'strat/components';

import { LiteAd } from 'horizontal/types';

import useAdAvailableCTAButtons from './useAdAvailableCTAButtons';
import styles from './styles/adCardCTAs.cssm';

type Props = {
    ad: LiteAd;
    viewSection: Values<typeof ViewSections>;
};

const CardCTAs = (props: Props) => {
    const buttons = useAdAvailableCTAButtons(props);

    return <Flex className={styles.container}>{buttons}</Flex>;
};

export default CardCTAs;
