import * as React from 'react';
import { t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { CheckBox } from 'strat/forms';

import styles from './styles/marketingEmailsOptInCheckBox.cssm';

type Props = {
    checked: boolean;
    onChange: () => void;
};

const MarketingEmailsOptInCheckBox = ({ checked, onChange }: Props) => {
    const i18n = useI18n();

    return (
        <div className={styles.marketingCheckbox}>
            <CheckBox
                checked={checked}
                // @ts-expect-error TS2322
                onChange={onChange}
                label={t(
                    i18n,
                )`Yes, I'd like to receive product updates and property recommendations by email. I can unsubscribe anytime.`}
                labelClassName={styles.checkboxLabel}
                inputClassName={styles.checkbox}
                className={styles.checkboxContainer}
            />
        </div>
    );
};

export default MarketingEmailsOptInCheckBox;
