import * as React from 'react';
import type { Choice, ChoiceValue } from 'strat/components/types';
import { Options, RoundedButtonChoice } from 'strat/components';
import { RenderChoiceProps } from '@app/components/options';

type Props<T extends ChoiceValue> = {
    readonly title?: string;
    readonly choices: Array<Choice<T>>;
    readonly selectedValues: Set<T>;
    readonly onChange: (arg1: Set<T>) => void;
    readonly errorMessage?: string;
    readonly leftSideLabel?: boolean;
    readonly boldLabel?: boolean;
    readonly autoFilledValues?: Array<T>;
    readonly choicesClassName?: string;
    readonly renderChoice?: (arg1: RenderChoiceProps<T>) => React.ReactElement;
    readonly renderExpandOptions?: () => React.ReactNode;
};

const MultiSelect = <T extends ChoiceValue>({
    title,
    choices,
    selectedValues,
    onChange,
    errorMessage,
    leftSideLabel,
    boldLabel,
    autoFilledValues,
    choicesClassName,
    renderChoice,
    renderExpandOptions,
}: Props<T>) => {
    const onClick = React.useCallback(
        (option) => {
            const newValues = new Set(selectedValues);
            if (selectedValues.has(option)) {
                newValues.delete(option);
            } else {
                newValues.add(option);
            }

            onChange?.(newValues);
        },
        [selectedValues, onChange],
    );

    const isSelected = (value: T) => selectedValues.has(value);

    return (
        <Options
            title={title}
            autoFilledValues={autoFilledValues}
            choices={choices}
            onChange={(option) => onClick(option)}
            isSelected={isSelected}
            errorMessage={errorMessage}
            renderChoice={
                renderChoice ??
                (CONFIG.runtime.ENABLE_RADIO_BUTTONS ? RoundedButtonChoice : undefined)
            }
            leftSideLabel={leftSideLabel}
            boldLabel={boldLabel}
            choicesClassNames={choicesClassName}
            renderExpandOptions={renderExpandOptions}
        />
    );
};

export default MultiSelect;
