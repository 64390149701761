import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { useDialog } from 'strat/dialogs/dialogContext';
import settings from '@app/branding/settings';
import { selectGeoLocationData } from 'strat/user/selectors';
import { selectRouteName } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';
import { useNavigateToHome } from 'strat/home/routing';
import { Text, Flex } from 'strat/components';

import DialogNames from 'horizontal/dialogs/dialogNames';
import { selectLocationNameWithParent } from 'horizontal/location';
import type { Location } from 'horizontal/types';
import LocationIcon from '@app/search/location/locationIcon';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import useRefineLocationFilter from '../useRefineLocationFilter';
import useLocationFilterValue from '../useLocationFilterValue';

import styles from './styles/activeLocationDialogTrigger.cssm';

const ActiveLocationDialogTrigger = ({ reversed }: { reversed?: boolean }) => {
    const i18n = useI18n();
    const refine = useRefineLocationFilter();
    const [, setVisible] = useDialog(DialogNames.LOCATIONS_DIALOG);
    const userLocation = useSelector(selectGeoLocationData);
    const routeName = useSelector(selectRouteName);
    const navigateToHome = useNavigateToHome();

    const activeLocation =
        useLocationFilterValue() || userLocation.closestLocation || settings.topLevelLocation;

    const onLocationChanged = React.useCallback(
        (location?: Location | null) => {
            refine(location);

            if (routeName === RouteNames.HOME) {
                navigateToHome();
            }
        },
        [refine, routeName, navigateToHome],
    );

    const openDialog = React.useCallback(
        () =>
            setVisible(true, {
                onActiveLocationChanged: onLocationChanged,
                onGeoPositionSelected: onLocationChanged,
            }),
        [setVisible, onLocationChanged],
    );

    return (
        <Flex className={styles.container} alignCenter onClick={openDialog} rowReverse={reversed}>
            <img
                src={iconArrowDown}
                alt={'Select toggle'}
                className={classNames(styles.arrowDown, { [styles.hidden]: !reversed })}
            />
            <span className={styles.location}>
                <Text.Regular bold>
                    {selectLocationNameWithParent(i18n, activeLocation.hierarchy)}
                </Text.Regular>
            </span>
            <LocationIcon className={classNames(styles.icon, { [styles.reversed]: reversed })} />
        </Flex>
    );
};

export default ActiveLocationDialogTrigger;
