import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { Input } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { useFlatCategoryFields } from 'horizontal/categoryFields';
import type { Category } from 'horizontal/types';

import { AgentCodeField } from './commonPostingFields';

type Props = {
    setFieldValue: (field: keyof FormikValues, value?: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
    onBlur: (arg1: React.SyntheticEvent<any>) => void;

    activeCategory?: Category;
};

const AgentCodeFormField = ({
    setFieldValue,
    values,
    onBlur,
    touched,
    errors,
    activeCategory,
}: Props) => {
    const i18n = useI18n();
    const categoryFields = useFlatCategoryFields(activeCategory?.id);
    const agentCodeField = categoryFields.find(
        (field) => field.attribute === AgentCodeField.attribute,
    );
    if (!agentCodeField) {
        return null;
    }

    return (
        <Input
            name={agentCodeField.attribute}
            title={agentCodeField.name}
            placeholder={t(i18n)`Enter ${agentCodeField.name}`}
            onChange={(value) => setFieldValue(AgentCodeField.attribute, value)}
            onBlur={(event: React.ChangeEvent<any>) => {
                setFieldValue(agentCodeField.attribute, (event.target.value || '').trim());
                onBlur?.(event);
            }}
            value={values[agentCodeField.attribute]}
            errorMessage={touched[agentCodeField.attribute] && errors[agentCodeField.attribute]}
            accepted={touched[agentCodeField.attribute] && !errors[agentCodeField.attribute]}
            leftSideLabel
            lightPlaceholder
            boldLabel
        />
    );
};

export default AgentCodeFormField;
