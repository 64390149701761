import { createSelector } from 'reselect';
import { selectSessionUserProfile } from '@sector-labs/fe-auth-redux';

import { type KeycloakUser } from 'strat/auth/keycloak/user';
import { UserRole } from 'strat/user/roles';
import EMPTY_ARRAY from 'strat/empty/array';

export const selectActiveUser = createSelector(selectSessionUserProfile, (userProfile: any) =>
    userProfile
        ? ({
              id: userProfile.sub,
              externalID: userProfile.external_id,
              sessionID: userProfile.session_state,
              name: userProfile.name,
              email: userProfile.email,
              description: userProfile.description,
              phoneNumber: userProfile.phone_number,
              isPasswordlessLogin: userProfile.is_passwordless_login,
              identityProviders: userProfile.identity_providers,
              state: userProfile.state,
              createdAt: new Date(),
              updatedAt: new Date(),
              additionalPhoneNumbers: userProfile.additional_phone_number,
              profileImageID: userProfile.image_id,
              birthday: userProfile.birthday,
              gender: userProfile.gender,
              realmRoles: userProfile.realm_roles,
          } as KeycloakUser)
        : null,
);

export const selectUserExternalID = createSelector(
    selectActiveUser,
    (activeUser) => activeUser?.externalID,
);

export const selectIsUserLoggedIn = createSelector(selectActiveUser, (activeUser) => !!activeUser);

export const selectIsPasswordlessLogin = createSelector(
    selectActiveUser,
    (userProfile) => userProfile?.isPasswordlessLogin ?? false,
);

export const selectUserEmail = createSelector(selectActiveUser, (activeUser) => activeUser?.email);

export const selectUserPhoneNumber = createSelector(
    selectActiveUser,
    (activeUser) => activeUser?.phoneNumber,
);

export const selectUserIdentityProviders = createSelector(
    selectActiveUser,
    (activeUser) => (activeUser?.identityProviders || EMPTY_ARRAY) as string[],
);

export const selectUserRoles = createSelector(
    selectActiveUser,
    (activeUser) => (activeUser?.realmRoles || EMPTY_ARRAY) as UserRole[],
);
