import { t } from '@lingui/macro';

import Route from 'react-true-router/route';
import OvationAPI from 'strat/api/ovationAPI';
import { selectOvationAppType, selectOvationUser } from 'strat/ovation/selectors';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { makeCancelable } from 'strat/util';
import type { CancelablePromise } from 'strat/util';
import SmartShareAPI from 'strat/api/smartShareAPI';
import { SmartShareLeadType, SmartShareLinkType } from 'strat/smartShare/types';
import { renderNotFoundPage } from 'strat/routes/search';
import PhoneMessageResponse from 'strat/gtm/phoneMessageResponses';
import {
    fetchAdDataByExternalID,
    createAdDetailsURLFromState,
} from '@app/routes/phoneMessageConfirmation';

import RouteNames from './routeNames';

class PhoneMessageConfirmationRoute extends Route {
    // @ts-expect-error - TS2564 - Property 'api' has no initializer and is not definitely assigned in the constructor.
    api: OvationAPI;
    cancelablePromise: CancelablePromise | null | undefined;

    constructor() {
        super(RouteNames.PHONE_MESSAGE_CONFIRMATION, [
            [
                '^/pm/',
                { name: 'externalID', pattern: '([0-9]+)' },
                '/',
                { name: 'traceID', pattern: '([-0-9a-zA-Z]+)' },
                '(?:\\?)?',
            ],
        ]);
        this.cancelablePromise = null;
    }

    /**
     * Ingests phone message confirmations for the specified ad.
     */
    ingestAdPhoneMessageConfirmations(state: any, traceIDs: Array<string>): void {
        const confirmations = traceIDs.map((traceID) => ({
            ...selectOvationUser(state),
            app_type: selectOvationAppType(state),
            linked_trace_id: traceID,
        }));

        const api = new OvationAPI({
            userAgent: state.user.userAgent,
            clientIP: state.user.userIP,
        });
        // @ts-expect-error - TS2345 - 'null' is not assignable to type 'string'.
        api.ingestAdPhoneMessageConfirmations(confirmations);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const { params } = context.match;
        const { traceID, externalID } = params;
        const { dispatch, getState } = context.redux.store;
        const language =
            (params && params.language) || (context && context.i18n && context.i18n.locale) || 'en';
        const getVisitorID = () => getState().user.visitorID;

        if (getState().user.userAgent && getState().user.userAgent.startsWith('WhatsApp')) {
            // Whatsapp wants to render a preview so we should not allow him
            context.http.status(400);
            return;
        }

        const isErrorLead = Object.values(PhoneMessageResponse).includes(traceID);
        if (!isErrorLead) {
            this.ingestAdPhoneMessageConfirmations(getState(), [traceID]);
        }

        let promise;
        if (isErrorLead || !CONFIG.build.STRAT_ENABLE_SMART_SHARE) {
            this.cancelablePromise = makeCancelable(fetchAdDataByExternalID(externalID, dispatch));
            promise = this.cancelablePromise.then(() =>
                context.http.redirect(createAdDetailsURLFromState(getState)),
            );
        } else {
            this.cancelablePromise = makeCancelable(
                new SmartShareAPI().createSmartShare(
                    traceID,
                    SmartShareLeadType.SMS,
                    getVisitorID(),
                    externalID,
                    language,
                ),
            );
            // @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
            promise = this.cancelablePromise.then((result) => {
                // 201 == new smart share created
                // 409 == existing smart share
                if (result?.status !== 201 && result?.status !== 409) {
                    // TODO: This log message is temporary as there is a chance we don't fully
                    //       understand what is going on. This should be replaced by proper error
                    //       handling and logging on the back-end.
                    console.error(
                        'The server responded with the following while creating the smart share',
                        JSON.stringify(result),
                    );

                    const { i18n } = context;
                    renderNotFoundPage(
                        context,
                        404,
                        t(
                            i18n,
                        )`Sorry, we're not quite ready to let you see this. Please try again later.`,
                    );
                    return result;
                }

                context.http.redirect(
                    `/smart-share/${traceID}?linkType=${SmartShareLinkType.USER}`,
                );
                return result;
            });
        }

        context.promise.wait(promise);
    }

    onLeave(): void {
        if (this.cancelablePromise) {
            this.cancelablePromise.cancel();
        }
    }
}

const PhoneMessageConfirmationRouteClass = PhoneMessageConfirmationRoute;

export { PhoneMessageConfirmationRouteClass };

export default new PhoneMessageConfirmationRoute();
