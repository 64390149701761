import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { Input } from 'strat/components';
import useI18n from 'strat/i18n/language/useI18n';

import PhoneNumberField from 'horizontal/fields/phoneNumberField';

import styles from './styles/requestDeliveryForm.cssm';
import FormSection from './formSection';

type Props = {
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
};

const PersonalDetails = ({ values, errors, touched, handleBlur, setFieldValue }: Props) => {
    const i18n = useI18n();
    return (
        <FormSection title={<Trans>Personal Details</Trans>}>
            <>
                <div className={styles.fields}>
                    <Input
                        name={'name'}
                        title={<Trans>Full name *</Trans>}
                        onChange={(value) => setFieldValue('name', value)}
                        onBlur={handleBlur}
                        value={values.name}
                        errorMessage={touched.name && errors.name}
                        accepted={touched.name && !errors.name}
                    />
                    <PhoneNumberField
                        title={t(i18n)`Phone number *`}
                        name={'phone_number'}
                        values={values}
                        handleBlur={handleBlur}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        touched={touched}
                    />
                    <PhoneNumberField
                        title={t(i18n)`Alternative number`}
                        name={'alternative_phone'}
                        values={values}
                        handleBlur={handleBlur}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        optional
                    />
                    <Input
                        name={'notes'}
                        title={<Trans>Delivery Notes</Trans>}
                        multiLine
                        maxLength={4096}
                        onChange={(value) => setFieldValue('notes', value)}
                        onBlur={handleBlur}
                        value={values.notes}
                        errorMessage={touched.notes && errors.notes}
                        accepted={touched.notes && !errors.notes}
                    />
                </div>
            </>
        </FormSection>
    );
};

export default PersonalDetails;
