// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { FetchAction } from 'strat/fetcher';

import { LocationNodeData } from 'horizontal/property';
import { selectLocations, setInitialLocationState } from 'horizontal/location';
import { fetchMultipleLocationsByExternalID } from 'horizontal/search/location';

const prefetchChildLocations = (
    location: LocationNodeData | null | undefined,
    getState: GetState,
    dispatch: Dispatch<FetchAction>,
): Promise<void> => {
    const locations = location?.hierarchy || [];
    const state = getState();
    const fetchedLocations = selectLocations(state);

    const externalIDs = locations
        .map((item) => item.externalID)
        .filter((item) => !fetchedLocations[item]);
    if (externalIDs.length === 0) {
        return Promise.resolve();
    }

    return fetchMultipleLocationsByExternalID(
        externalIDs,
        state.i18n.language,
        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        selectActiveSearchBackend(state),
        externalIDs.length,
    ).then((hits) => {
        hits.forEach((item) => setInitialLocationState(dispatch, item));
    });
};

export default prefetchChildLocations;
