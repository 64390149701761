import * as React from 'react';
import { Trans } from '@lingui/macro';

import { PropertyData } from 'strat/property';

import PaymentPlanBreakdown from '../paymentPlanBreakdown';

import Badge from './badge';
import { ProjectBadgeStyle } from './types';

type Props = {
    property: PropertyData;
    style: ProjectBadgeStyle;
};

const PercentagePaidBadge = ({ property, style }: Props) => {
    if (!property.offplanDetails?.paidPrice || !property.offplanDetails?.originalPrice) {
        return null;
    }

    const renderValue = () => (
        <div>
            {property.offplanDetails?.paidPrice && property.offplanDetails?.originalPrice
                ? Math.ceil(
                      (property.offplanDetails.paidPrice / property.offplanDetails.originalPrice) *
                          100,
                  )
                : 0}
            %
        </div>
    );

    return (
        <Badge
            style={style}
            renderTitle={() => <Trans>Amount paid</Trans>}
            renderValue={renderValue}
            renderInformation={() => <PaymentPlanBreakdown property={property} />}
        />
    );
};

export default PercentagePaidBadge;
