const PermissionTypes = Object.freeze({
    GRANTED: 'granted',
    DEFAULT: 'default',
    DECLINED: 'declined',
});

const OneSignalEvents = Object.freeze({
    PERMISSION_CHANGE: 'notificationPermissionChange',
    SUBSCRIPTION_CHANGE: 'subscriptionChange',
});

export type OnPermissionChangeParams = {
    to: Values<typeof PermissionTypes>;
};

export const NotificationPromptType = Object.freeze({
    BOTTOM_NOTIFICATIONS_PROMPT: 'bottomNotificationsPrompt',
    PROPERTY_NOTIFICATIONS_PROMPT: 'propertyNotificationsPrompt',
    STICKY_NOTIFICATIONS_PROMPT: 'stickyNotificationsPrompt',
    COMPACT_NOTIFICATIONS_PROMPT: 'compactNotificationsPrompt',
    CHAT_NOTIFICATIONS_PROMPT: 'chatNotificationsPrompt',
});

export { PermissionTypes, OneSignalEvents };
