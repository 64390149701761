import { t } from '@lingui/macro';
import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID } from 'strat/user/session';
import { catchCanceled } from 'strat/util';

import { AdState } from 'horizontal/types';
import type { Ad, ProductPackageOffer } from 'horizontal/types';
import Page from 'horizontal/pages/page';
import { getStratAPI, StratAPI } from 'horizontal/api';

import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';
import ensureHasActiveUser from './ensureHasActiveUser';

export type SelectSinglePackageRouteParams = {
    externalID: string;
    withoutSkipButton?: boolean;
};

class SelectSinglePackageRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.SELECT_SINGLE_PACKAGE, [
            [
                '^/payments/choosePackage/single/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(params: SelectSinglePackageRouteParams): EnhancedLocation {
        const { externalID, withoutSkipButton } = params;
        const queryParams = {
            withoutSkipButton: withoutSkipButton ? 'true' : undefined,
        } as const;
        return {
            pathname: `/payments/choosePackage/single/${externalID}`,
            search: queryParams,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        // render the page so we can display the loading spinner
        context.rendering.renderPage(Page.SELECT_SINGLE_PACKAGE, { offerPackages: null });

        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { withoutSkipButton },
            },
            i18n,
        } = context;

        const userExternalID = selectUserExternalID(getState());

        const dataPromise = Promise.all([this.fetchAd(context), this.fetchOffers(context)]);

        context.promise.wait(
            dataPromise
                .then(([ad, packageOffers]): [Ad, ProductPackageOffer[]] | undefined => {
                    if (!ad || ad?.userExternalID !== userExternalID) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    if ([AdState.REJECTED, AdState.SOFT_REJECTED].includes(ad.state)) {
                        context.rendering.renderPage(Page.APPLY_AD_SINGLE_PACKAGE_ERROR);
                        return;
                    }

                    if (![AdState.ACTIVE, AdState.PENDING].includes(ad.state)) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    if (!packageOffers?.length) {
                        context.rendering.renderPage(Page.PACKAGES_NOT_FOUND, {
                            message: t(i18n)`Packages not found`,
                        });
                        return;
                    }

                    context.rendering.renderPage(Page.SELECT_SINGLE_PACKAGE, {
                        offerPackages: packageOffers,
                        ad,
                        withoutSkipButton: withoutSkipButton === 'true',
                    });
                })
                .catch(catchCanceled),
        );
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<Ad | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!externalID || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) => api.userAd(userExternalID, externalID));
    }

    fetchOffers(
        context: RoutingContextWithMiddlewares,
    ): Promise<Array<ProductPackageOffer> | null | undefined> {
        const {
            match: {
                params: { externalID },
            },
        } = context;

        if (!externalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) =>
            api.getAdProductPackageOffers(externalID, { sortBy: '-price' }),
        );
    }

    private makeStratAPIRequest<T>(
        context: RoutingContextWithMiddlewares,
        callback: (api: StratAPI) => Promise<{ status: number; data: T }>,
    ) {
        const {
            redux: {
                store: { getState },
            },
        } = context;

        const api = getStratAPI(getState());
        const request = this.cancelable(callback(api));

        return request.then((response) => (response.status < 300 ? response.data : null));
    }
}

export default new SelectSinglePackageRoute();
