import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import { AgentRolesIDs } from 'horizontal/types';

export type FetchAgentsParams = {
    externalID: string;
    countAdsByState?: boolean;
    getCreditBalance?: boolean;
};

const factory = new FetcherFactory(
    ['agencyPortal', 'agents'],
    ({ externalID, countAdsByState, getCreditBalance }: FetchAgentsParams, state: any) => {
        return new StratAPI(state.i18n.language).agents(
            externalID,
            false,
            countAdsByState,
            null,
            getCreditBalance,
        );
    },
    {
        skipParamsCheck: true,
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

const ownerAgentFactory = new FetcherFactory(
    ['agencyPortal', 'ownerAgent'],
    ({ externalID }: FetchAgentsParams, state: any) => {
        return new StratAPI(state.i18n.language).agents(externalID, false, true, [
            AgentRolesIDs.OWNER,
        ]);
    },
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

const fetchAgents = factory.creator();

const agentsReducer = factory.reducer();

const fetchOwnerAgent = ownerAgentFactory.creator();

const ownerAgentReducer = ownerAgentFactory.reducer();

export { fetchAgents, fetchOwnerAgent, ownerAgentReducer };

export default agentsReducer;
