import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import { withErrorBoundary } from 'strat/util';
import { CloseButton } from 'strat/modal';
import Designs from 'strat/branding/designs';

import styles from './styles/contactFormHeader.cssm';

/**
 * Properties for {@see ContactFormHeader}.
 */
type Props = {
    title?: string;
    design?: Values<typeof Designs>;
    onCloseClick: (event: React.SyntheticEvent<any>) => void;
};

/**
 * Renders the header for a contact form.
 *
 * This is used in the {@see ContactFormDialog} and
 * {@see ContactFormSlideIn}.
 */
const ContactFormHeader = ({ title, design, onCloseClick }: Props) => {
    const defaultTitle =
        design === Designs.FORM_DESIGN_2022 ? (
            <Trans>Contact Agent</Trans>
        ) : (
            <Trans>Email agent for more information</Trans>
        );

    return (
        <div
            className={classNames(styles.heading, {
                [styles.updatedHeading]: design !== Designs.FORM_DESIGN_2022,
            })}
        >
            <h2 className={styles.title}>{title || defaultTitle}</h2>
            <CloseButton className={styles.closeButton} onClick={onCloseClick} />
        </div>
    );
};

// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
export default withErrorBoundary(ContactFormHeader);
