import type { EnhancedLocation } from 'react-true-router/location';
import type { SearchParams as SearchParamsBase } from 'strat/search';
import { createSearchQueryParams } from 'strat/agency/propertySection/url';
import KnownURLParams from 'strat/branding/knownParameters';

export type SearchParams = SearchParamsBase & {
    agencySlug?: string;
    agentSlug?: string;
    activeTab?: string;
};

const createAgentDetailsURL = (params: SearchParams, unknownParams?: any): EnhancedLocation => {
    const pathname = `/brokers/${params.agentSlug}.html`;
    const search = createSearchQueryParams(params, unknownParams);

    return {
        pathname,
        search: {
            ...search,
            ...(CONFIG.build.STRAT_ENABLE_LEADERBOARD && {
                [KnownURLParams.ACTIVE_TAB]: params.activeTab,
            }),
        },
    };
};

export { createAgentDetailsURL };
