import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import { SearchJob } from '@sector-labs/fe-search-redux';

/**
 * Replace the response index with the slot index in each hit from a response.
 */
const updateResponseIndexToSlotIndex = (
    job: SearchJob,
    response: SearchResponse,
): SearchResponse => {
    // @ts-expect-error - TS2341 - Property 'strategy' is private and only accessible within class 'SearchJob'.
    const requestMapping = job.strategy.requestMapping;

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const requestToGroupMapping = new Map(Array.from(requestMapping, (a) => a.reverse()));

    return {
        ...response,
        hits: response.hits.map((hit) => ({
            ...hit,
            requestIndex: requestToGroupMapping.get(hit.requestIndex) ?? hit.requestIndex,
        })),
    };
};

export default updateResponseIndexToSlotIndex;
