import { combineReducers } from 'redux';
import FetcherFactory from 'strat/fetcher';
import { selectUserExternalID } from 'strat/user/session';

import { getStratAPI } from 'horizontal/api';
import { GlobalState } from 'horizontal/state';

const freeUsageFactory = new FetcherFactory(
    ['adLimits', 'freeUsage'],
    (params: any, state: GlobalState) => {
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Missing user ID when request my limit usage.');
        }

        return getStratAPI(state).userAdLimits(userExternalID);
    },
    { successCodes: [200], skipParamsCheck: true },
);

const fetchFreeAdLimitUsage = freeUsageFactory.creator();

const adLimitsReducer = combineReducers({
    freeUsage: freeUsageFactory.reducer(),
});

export { fetchFreeAdLimitUsage };

export default adLimitsReducer;
