import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { useActiveUser } from 'strat/user/session';

import ProBadge from 'horizontal/branding/proBadge';
import { useIsProAgent } from 'horizontal/agent/state';
import iconProfilePicture from 'horizontal/assets/icons/iconProfilePicture.png';
import iconProfilePictureLoggedOut from 'horizontal/assets/icons/iconProfilePictureLoggedOut.png';

import styles from './styles/userProfilePicture.cssm';
import getImageSource from './getImageSource';

type Props = {
    readonly className?: string;
    readonly alt?: string;
    readonly shouldDisplayInitial?: boolean;
    readonly showProBadge?: boolean;
    readonly boldProBadge?: boolean;
    readonly proBadgeClassName?: string;
};

const UserProfilePicture = ({
    className,
    alt,
    showProBadge,
    boldProBadge,
    proBadgeClassName,
}: Props) => {
    const imgClassName = className || classNames(styles.image, styles.roundedImage);
    const user = useActiveUser();
    const imageSource = getImageSource(user);
    const defaultImageSource = user ? iconProfilePicture : iconProfilePictureLoggedOut;
    const source = imageSource || defaultImageSource;

    const isProAgent = useIsProAgent();

    return (
        <Flex className={styles.container}>
            <img
                className={imgClassName}
                src={source}
                alt={alt || 'User profile'}
                aria-label="User profile picture"
            />
            {showProBadge && isProAgent && (
                <ProBadge className={proBadgeClassName} bold={boldProBadge} />
            )}
        </Flex>
    );
};

export default UserProfilePicture;
