import * as React from 'react';
import { Flex, Text } from 'strat/components';
import Category from '@app/branding/category';

import AdCarSubtitle from 'horizontal/ad//adCarSubtitleUpdatedDesign';
import AdPropertySubtitle from 'horizontal/ad/adPropertySubtitleUpdatedDesign';
import { useCreationDate } from 'horizontal/generic';
import { useAgencyLogo } from 'horizontal/ad';
import { LiteAd } from 'horizontal/types';
import useGetDownPaymentLabel from 'horizontal/ad/price/useGetDownPaymentLabel';
import useRentalPeriod from 'horizontal/adCard/useRentalPeriod';

import AgencyLogo from './agencyLogo';
import AdAdditionalPriceInfo from './adAdditionalPriceInfoForHeroCategory';
import styles from './styles/adDetails.cssm';

type Props = {
    ad: LiteAd;
    link: React.ReactElement;
    priceLabel: string | null | undefined;
    title: string;
    location: string;
};

const AdDetails = ({ ad, link, priceLabel, title, location }: Props) => {
    const logo = useAgencyLogo(ad);

    const adCategory = ad.category[ad.category.length - 1];
    const Subtitle = Category.isOfCarsType(adCategory) ? AdCarSubtitle : AdPropertySubtitle;
    const downPaymentLabel = useGetDownPaymentLabel(ad);
    const rentalPeriod = useRentalPeriod(ad);
    const creationDateString = useCreationDate(ad.timestamp);

    return (
        <Flex column className={styles.detailsContainer}>
            {link}
            <div className={styles.priceContainer}>
                <h5
                    className={
                        downPaymentLabel != null && !rentalPeriod
                            ? styles.downPaymentPriceLabel
                            : styles.priceLabel
                    }
                    aria-label="Price"
                >
                    {priceLabel}
                </h5>
                <AdAdditionalPriceInfo ad={ad} />
            </div>
            <div className={styles.title} aria-label="Title">
                <Text.Large>{title}</Text.Large>
            </div>
            <div className={styles.subtitle} aria-label="Subtitle">
                <Subtitle ad={ad} />
            </div>
            <Flex wrap justifySpaceBetween alignEnd className={styles.locationContainer}>
                <Flex column>
                    <span aria-label="Location">{location}</span>
                    <span aria-label="Creation date">{creationDateString}</span>
                </Flex>
                {ad.agency && <AgencyLogo logo={logo} />}
            </Flex>
            <div className={styles.separator} />
        </Flex>
    );
};

export default AdDetails;
