import type { CategoryField, FlatCategoryField } from 'strat/types';
import { dictionaryToFlatArray } from 'strat/util';

/**
 * Tries to find the parent field from a list of fields for the field provided.
 * Iterates through choices of the fields.
 * - If choices is a dictionary the keys indicate the parent's choice id.
 * - If choices is an array each choice will contain a parentId field.
 * @param field: the field to find parent for
 * @param fields: all the fields available
 * @returns CategoryField | null: the parent category field or null if it doesnt exist
 */
const getParentField = <T extends CategoryField | FlatCategoryField>(
    field: T,
    fields: T[],
): T | null => {
    if (!field.choices) {
        return null;
    }

    const choiceParentIds = Array.isArray(field.choices)
        ? field.choices.reduce<Record<string, any>>((parentIds, choice) => {
              if (!choice.parentID) {
                  return parentIds;
              }

              parentIds[choice.parentID] = true;
              return parentIds;
          }, {})
        : Object.keys(field.choices).reduce<Record<string, any>>((parentIds, parentId) => {
              parentIds[parentId] = true;
              return parentIds;
          }, {});

    return (
        fields.find((parentField) =>
            parentField === field
                ? false
                : // @ts-expect-error - TS2345 - Argument of type 'CategoryFieldChoice[] | Partial<Record<Primitive, CategoryFieldChoice[]>>' is not assignable to parameter of type 'Partial<Record<any, CategoryFieldChoice[]>> | CategoryFieldChoice[][]'.
                  dictionaryToFlatArray(parentField.choices).some(
                      // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'CategoryFieldChoice[]'.
                      (choice) => choiceParentIds[choice.id],
                  ),
        ) || null
    );
};

export default getParentField;
