import * as React from 'react';
import iconArrowDown from '@app/assets/icons/iconArrowDown_noinline.svg';

import InputContainer from './inputContainer';
import type { Choice, ChoiceValue } from './types';
import CheckedIcon from './checkedIcon';
import styles from './styles/select.cssm';

const renderIcon = () => (
    <div className={styles.checkedIcon}>
        <CheckedIcon />
    </div>
);

type Props<T extends ChoiceValue> = {
    readonly title?: string;
    readonly description?: string;
    readonly name: string;
    readonly errorMessage?: string;
    readonly accepted?: boolean;
    readonly renderCheckIcon?: () => React.ReactElement;
    readonly onChange: (arg1: T) => void;
    readonly value?: T;
    readonly choices: Array<Choice<T>>;
    readonly placeholder?: string;
    readonly resettable?: boolean;
    readonly stretch?: boolean;
    readonly selectClassname?: string;
};

const Select = <T extends ChoiceValue, OtherProps extends any>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    renderCheckIcon = renderIcon,
    onChange,
    value = '',
    choices,
    placeholder,
    resettable,
    stretch,
    selectClassname = styles.select,
    // @ts-expect-error - TS2339 - Property 'containerClassname' does not exist on type 'Props<T>'.
    containerClassname = '',
    ...otherProps
}: OtherProps & Props<T>) => (
    <InputContainer
        title={title}
        description={description}
        name={name}
        errorMessage={errorMessage}
        accepted={accepted}
        stretch={stretch}
        className={containerClassname}
    >
        <select
            id={name}
            name={name}
            className={selectClassname}
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'T'.
            onChange={(event) => onChange(event.target.value)}
            // @ts-expect-error - TS2322 - Type 'T' is not assignable to type 'string | number | readonly string[] | undefined'.
            value={value}
            {...otherProps}
        >
            {(placeholder || !value) && (
                <option value="" disabled={!resettable}>
                    {placeholder}
                </option>
            )}
            {choices.map((choice) => (
                // @ts-expect-error - TS2322 - Type 'ChoiceValue' is not assignable to type 'string | number | readonly string[] | undefined'.
                <option value={choice.value} key={choice.value}>
                    {choice.label}
                </option>
            ))}
        </select>
        {accepted && renderCheckIcon && renderCheckIcon()}
        <div className={styles.iconArrowDown}>
            <img src={iconArrowDown} alt={'Select toggle'} />
        </div>
    </InputContainer>
);

export default Select;
