import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

const factory = new FetcherFactory(
    ['categoryFields'],
    (params: any, state: any) =>
        new StratAPI(state.i18n.language).categoryFields({
            ...params,
            splitByCategoryIDs: true,
            flatChoices: true,
        }),
    {
        catchServerErrors: true,
    },
);

const fetchCategoryFields = factory.creator();

const categoryFieldsReducer = factory.reducer();

export { fetchCategoryFields };

export default categoryFieldsReducer;
