import settings from '@app/branding/settings';
import { OvationAdData } from 'strat/api/ovationAPI';
import { adData as adStratData } from 'strat/ovation/extractAdData';
import { ThumbnailSizeValues, ThumbnailSizes, thumbnailURL } from 'strat/image';

import type { AdFormattedExtraField } from 'horizontal/ad';

export const adData = (
    ad: any,
    potwExternalID?: string | null,
    experiments?: string[],
): OvationAdData => {
    const adData = adStratData(ad, potwExternalID, experiments);

    const coverPhotoId = ad.coverPhoto?.id || ad.photos?.[0]?.id;
    const coverPhotoUrl = coverPhotoId
        ? thumbnailURL(coverPhotoId, 'jpeg', ThumbnailSizeValues[ThumbnailSizes.SMALL])
        : null;
    const formattedExtraFields: Array<AdFormattedExtraField> = ad.formattedExtraFields;
    const extraFields = formattedExtraFields.reduce<Record<string, any>>(
        (acc, field) => ({
            ...acc,
            [field.attribute]: field.formattedValue,
        }),
        {},
    );
    const { price, bathrooms, rooms, ft, rental_period } = extraFields || {};
    const adExtraData = {
        ad_image_url: coverPhotoUrl,
        ad_location_l3_external_id: ad.location?.[2]?.externalID,
        ad_location_l3_name_en: ad.locationTranslations?.[2]?.en.name,
        ad_location_external_id: ad.location?.slice(-1)[0].externalID,
        ad_location_name_en: ad.locationTranslations?.slice(-1)[0].en.name,
        ad_agent_external_id: ad.agents?.[0]?.user_external_id || ad.userExternalID,
        ad_agent_name: ad.contactInfo?.name,
        ad_agency_external_id: ad.agency?.externalID,
        ad_category_external_id: ad.category?.slice(-1)[0].externalID,
        ad_product: ad.product,
        ad_rental_period: rental_period,
        ad_area: ft,
        ad_price: price,
        ad_bathroom_count: bathrooms,
        ad_bedroom_count: rooms,
        ad_title: ad.title,
        ad_extra_fields: { mileage: extraFields.mileage },
    };

    const alternateLanguageCode = settings.languages.filter((l) => l.lang !== 'en')[0]?.lang;
    return {
        ...adData,
        ...adExtraData,
        ...(alternateLanguageCode && {
            ad_location_l3_name_lc: ad.locationTranslations?.[2]?.[alternateLanguageCode]?.name,
            ad_location_name_lc: ad.locationTranslations?.slice(-1)[0][alternateLanguageCode]?.name,
        }),
    };
};
