import { serializeLocation } from 'react-true-router/location';
import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import createSearchURL from '@app/routes/createSearchURL';
import { redirectToURISafely } from 'strat/routing';

const patterns = {
    optionalSlashAndParams: '/?(?:(?:\\?|#).*)?$',
} as const;

class SavedSearchNotificationRoute extends Route {
    constructor() {
        super(RouteNames.SAVED_SEARCHES, [['^/saved_search', patterns.optionalSlashAndParams]]);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            match,
            redux: {
                store: { dispatch, getState },
            },
        } = context;

        const categoryFieldFetchPromise = dispatch(
            fetchCategoryFields({
                categoryExternalIDs: null,
                includeChildCategories: true,
            }),
        );
        const promise = categoryFieldFetchPromise.then(() => {
            const filtersJSON = JSON.parse(match.params.search_params);
            const newAdTimestamp = match.params.new_ad_timestamp;
            const { pathname, search } = createSearchURL(
                { ...filtersJSON, newAdTimestamp },
                getState(),
            );
            const url = serializeLocation({ pathname, search });
            redirectToURISafely(context, url);
        });
        context.promise.wait(promise);
    }
}

export default new SavedSearchNotificationRoute();
