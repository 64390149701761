import * as React from 'react';
import settings from '@app/branding/settings';
import { useActiveUser } from 'strat/user/session';
import classNames from 'classnames';

import isAdInactive from '@app/adDetails/isAdInactive';
import { useAdPhotos } from 'horizontal/ad';
import { useAd, useAdIsLoading } from 'horizontal/ad/state';
import { AdDetailsBanner, Position, Sizes } from 'horizontal/tagmanager';
import { AdProduct, AdVirtualState } from 'horizontal/types';
import { isAdEditor } from 'horizontal/util';
import AdUtils from 'horizontal/ad/utils';

import Gallery from './gallery';
import useTrackGallerySwipeCounter from './gallery/useTrackGallerySwipeCounter';
import { Section } from './section';
import styles from './styles/ad.cssm';
import RedesignedGallery from './gallery/redesignedGallery';
import AdInfo from './adInfo';
import AdSidebar from './adSidebar';

const Ad = () => {
    const ad = useAd();
    const loading = useAdIsLoading();
    const user = useActiveUser();
    const showDeliveryTag = AdUtils.isDeliverable(ad);
    const adTags = ad?.adTags || [];
    const [coverPhoto, photos, photoCount] = useAdPhotos(ad);
    const isOwnAd = ad && user && isAdEditor(ad, user.externalID);
    const isFeatured = isOwnAd
        ? ad?.virtualState === AdVirtualState.FEATURED
        : ad?.product === AdProduct.FEATURED;

    const isElite = isOwnAd
        ? ad?.virtualState === AdVirtualState.ELITE
        : ad?.product === AdProduct.ELITE;
    const isInactiveAd = isAdInactive(ad);

    const { incrementSwipeCounter } = useTrackGallerySwipeCounter(ad);
    if (!ad) {
        return null;
    }

    const renderLayout = () => (
        <>
            <div className={styles.main}>
                <Section noPadding label="Gallery">
                    <div className={styles.gallery}>
                        <Gallery
                            coverPhoto={coverPhoto}
                            photos={isInactiveAd ? [] : photos}
                            photoCount={photoCount}
                            featured={isFeatured && settings.enableFeaturedBadge}
                            loading={loading}
                            tags={adTags}
                            elite={isElite}
                            withArrows
                            onGalleryImageChange={incrementSwipeCounter}
                            showDeliveryTag={showDeliveryTag}
                        />
                    </div>
                </Section>
                <AdInfo />
            </div>
            <AdSidebar />
        </>
    );

    const renderRedesignedLayout = () => (
        <>
            <RedesignedGallery
                featured={isFeatured && settings.enableFeaturedBadge}
                tags={adTags}
                elite={isElite}
                isAdActive={!isInactiveAd}
                onGalleryImageChange={incrementSwipeCounter}
                showDeliveryTag={showDeliveryTag}
            />
            <div className={styles.main}>
                <AdInfo />
            </div>
            <AdSidebar />
        </>
    );

    return (
        <>
            <AdDetailsBanner
                ad={ad}
                sizes={Sizes.desktop.details.wallpaper}
                position={Position.TOP}
                disableStyle
            />
            <AdDetailsBanner ad={ad} sizes={Sizes.desktop.details.top} position={Position.TOP} />
            <div
                className={classNames({
                    [styles.mainContainer]: !CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN,
                    [styles.redesignedMainContainer]: CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN,
                })}
            >
                {CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN
                    ? renderRedesignedLayout()
                    : renderLayout()}
            </div>
        </>
    );
};

export default Ad;
