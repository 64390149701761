import type { RoutingMiddleware } from '../routingMiddleware';

/**
 * Allows routes to instruct the caller to
 * wait for something.
 */
class RoutingContextPromise {
    /**
     * Promises the caller should wait for.
     */
    promise: Promise<any>;

    /**
     * Initializes a new instance of the {@see RoutingContextPromise} class.
     * @param promises Initial promises to wait for.
     */
    constructor(promises: Array<Promise<any>> = []) {
        this.promise = promises ? Promise.all(promises) : Promise.resolve();
    }

    /**
     * Instructs the caller to wait for the specified promise.
     * @param promise The promise the caller should wait for.
     * @returns A promise that waits for the speciifed promise
     * and for all previously registered promises.
     */
    wait(promise: null | Promise<any> = null): Promise<any> {
        if (promise) {
            this.promise = Promise.all([this.promise, promise]);
        }

        return this.promise;
    }
}

export { RoutingContextPromise };

/**
 * Creates a new routing middleware that allows routes
 * to instruct the caller to wait for something.
 * @returns A new routing middleware.
 */
export default (promises: Array<Promise<any>> = []): RoutingMiddleware =>
    (): RoutingContextPromise =>
        new RoutingContextPromise(promises);
