import * as React from 'react';
import IconArea from '@app/assets/icons/iconArea.svg';
import { Flex } from 'strat/components';

import { LiteAd } from 'horizontal/types';
import IconBeds from 'horizontal/assets/icons/iconBeds.svg';
import IconBaths from 'horizontal/assets/icons/iconBaths.svg';
import IconCalendar from 'horizontal/assets/icons/iconCalendar.svg';
import IconRoad from 'horizontal/assets/icons/iconRoad.svg';
import IconFuel from 'horizontal/assets/icons/iconFuel.svg';
import capitalizeWords from 'horizontal/search/seo/capitalizeWords';

import { BasicTag } from '../tags';

import useAdFeaturesForHeroCategory from './useAdFeaturesForHeroCategory';
import { CAR_AD_FEATURES_KEYS, PROPERTY_AD_FEATURES_KEYS } from './types';
import styles from './styles/adFeatures.cssm';

type Props = {
    ad: LiteAd;
};

const mapAdFeatureIcon = (feature: string): React.ReactElement | null => {
    switch (feature) {
        case CAR_AD_FEATURES_KEYS.YEAR:
            return <IconCalendar className={styles.icon} />;
        case CAR_AD_FEATURES_KEYS.MILEAGE:
            return <IconRoad className={styles.icon} />;
        case CAR_AD_FEATURES_KEYS.FUEL_TYPE:
            return <IconFuel className={styles.icon} />;
        case PROPERTY_AD_FEATURES_KEYS.BEDS:
            return <IconBeds className={styles.icon} />;
        case PROPERTY_AD_FEATURES_KEYS.BATHROOMS:
            return <IconBaths className={styles.icon} />;
        case PROPERTY_AD_FEATURES_KEYS.AREA:
            return <IconArea className={styles.icon} />;
        default:
            return null;
    }
};

const adFeatureDisplayOrder = (feature: string): number => {
    switch (feature) {
        case CAR_AD_FEATURES_KEYS.YEAR:
            return 1;
        case CAR_AD_FEATURES_KEYS.MILEAGE:
            return 2;
        case CAR_AD_FEATURES_KEYS.FUEL_TYPE:
            return 3;
        case PROPERTY_AD_FEATURES_KEYS.BEDS:
            return 4;
        case PROPERTY_AD_FEATURES_KEYS.BATHROOMS:
            return 5;
        case PROPERTY_AD_FEATURES_KEYS.AREA:
            return 6;

        default:
            return 100;
    }
};

const AdFeatures = ({ ad }: Props) => {
    const adFeatures = useAdFeaturesForHeroCategory({ ad });

    if (!adFeatures || (!adFeatures.carFeatures && !adFeatures.propertyFeatures)) {
        return null;
    }

    const currentFeatures = adFeatures.carFeatures || adFeatures.propertyFeatures;

    const adFeaturesTags = Object.entries(currentFeatures || {})
        .filter(([_, value]) => !!value)
        .sort(
            ([featureA], [featureB]) =>
                adFeatureDisplayOrder(featureA) - adFeatureDisplayOrder(featureB),
        )
        .map(([key, value]) => {
            const icon = mapAdFeatureIcon(key);

            return (
                <BasicTag
                    key={key}
                    text={value ?? ''}
                    renderIcon={icon ? () => icon : undefined}
                    ariaLabel={capitalizeWords(key)}
                    disableTextTransformation
                />
            );
        });

    return <Flex className={styles.featuresContainer}>{adFeaturesTags}</Flex>;
};

export default React.memo(AdFeatures);
