import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';
import { selectRouteReferrer } from 'strat/routes/selectors';

import { ApplyAdLimitPackageRoute, PostAdSuccessRoute } from 'horizontal/routes';
import { useNavigateToApplyAdLimit } from 'horizontal/packages/navigation';

import type { PaymentOrder } from './types';
import Header from './header';

type Props = {
    readonly order: PaymentOrder | null | undefined;
};

const PaymentSelectionHeader = ({ order }: Props) => {
    const referrer = useSelector(selectRouteReferrer);
    const router = useRouter();
    const navigateToApplyAdLimit = useNavigateToApplyAdLimit();
    const onBack = React.useCallback(() => {
        const adExternalID = order?.adExternalID;
        if (adExternalID) {
            const postAdSuccessPathname = PostAdSuccessRoute.createURL({
                adExternalID,
                hidePurchaseSuggestions: false,
            }).pathname;
            if (referrer?.indexOf(postAdSuccessPathname) !== -1) {
                router.pushRoute(RouteNames.POST_AD_SUCCESS, {
                    adExternalID,
                });
                return;
            }
            const applyAdLimitPathname = ApplyAdLimitPackageRoute.createURL({
                externalID: adExternalID,
            }).pathname;
            if (referrer?.indexOf(applyAdLimitPathname) !== -1) {
                navigateToApplyAdLimit({ externalID: adExternalID });
                return;
            }

            router.pop();
            return;
        }

        router.pop();
    }, [order, navigateToApplyAdLimit, referrer, router]);

    return <Header onBack={onBack} />;
};

export default PaymentSelectionHeader;
