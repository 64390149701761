import type { AdVirtualStateValues } from 'horizontal/types';

import type { AdManagementState, AdStates } from './state';

const filterAdManagementState = (
    response: {
        status: number;
        data: AdManagementState | null;
    } | null,
    removedAdsIds: Array<number>,
) => {
    if (!response || !removedAdsIds || !response.data || !response.data?.results) {
        return response;
    }

    const { data } = response;

    const ads = data.results.filter(({ id }) => !removedAdsIds.includes(id));

    if (data.results.length === ads.length) {
        return response;
    }

    return {
        ...response,
        data: {
            ...data,
            count: ads.length,
            countByState: Object.keys(data.countByState).reduce<Partial<AdStates>>(
                (newCountByState, virtualState) => ({
                    ...newCountByState,
                    [virtualState]: (() => {
                        const removedAdsCountByState = data.results.filter(
                            (ad) =>
                                ad.virtualState === virtualState && removedAdsIds.includes(ad.id),
                        ).length;
                        return (
                            data.countByState[virtualState as AdVirtualStateValues] -
                            removedAdsCountByState
                        );
                    })(),
                }),
                {},
            ),
            results: ads,
        },
    };
};

export default filterAdManagementState;
