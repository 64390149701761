import { createSelector } from 'reselect';
import type { Campaign } from 'strat/campaign/types';

import { MarketingCampaigns } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

// Define if we should display the campaign Dialog or not
// we should display the campaign dialog ONLY if the user activated the campaign link
const selectIsWalletTopUpCampaignActive = createSelector(
    (state: GlobalState) => state.campaign,
    (campaign: Campaign) => {
        if (!campaign) {
            return false;
        }
        const isEligibleCampaignType = campaign.name === MarketingCampaigns.WALLET_TOP_UP;
        const isCampaignActive =
            CONFIG.runtime.STRAT_MARKETING_CAMPAIGN === MarketingCampaigns.WALLET_TOP_UP;
        return isEligibleCampaignType && isCampaignActive;
    },
);

export { selectIsWalletTopUpCampaignActive };
