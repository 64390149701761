import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Text, Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { Ad, ProductPackageOffer } from 'horizontal/types';
import { useShouldShowBTTForFeaturedAd } from 'horizontal/packages';
import useFormattedPackagesPrice from 'horizontal/businessPackages/useFormattedPackagesPrice';

import styles from './styles/packageChoiceBox.cssm';

type Props = {
    ad?: Ad | null;
    choice: ProductPackageOffer;
    selected: boolean;
    onClick: () => void;
};

const BTTForFeaturedAd = ({ ad, choice }: Pick<Props, 'ad' | 'choice'>) => {
    const shouldShowBTTForFeaturedAd = useShouldShowBTTForFeaturedAd(choice.productPackage, ad);
    const i18n = useI18n();
    return shouldShowBTTForFeaturedAd ? (
        <Text.Regular bold className={styles.consumptionCreditsProposition}>
            {t(
                i18n,
            )`${plural(choice.productConsumptionWeight || 1, { one: '# credit', other: '# credits' })}`}{' '}
            {t(i18n)`to be used to boost the ad`}
        </Text.Regular>
    ) : null;
};

const ValueProposition = ({ choice }: Pick<Props, 'choice'>) => {
    const valuePropositionText = choice.productPackage.valueProposition;

    return valuePropositionText ? (
        <Text.Regular className={styles.valueProposition}>{valuePropositionText}</Text.Regular>
    ) : null;
};

const Label = ({ choice }: Pick<Props, 'choice'>) => {
    const label = choice.label;

    return label ? (
        <div>
            <Text.Regular className={styles.label}>{label}</Text.Regular>
        </div>
    ) : null;
};

const OfferPackageChoiceBox = ({ ad, choice, selected, onClick }: Props) => {
    const totalPrice = useFormattedPackagesPrice(choice.discountPrice || choice.price);
    const price = useFormattedPackagesPrice(choice.price);
    const discountPercentage = choice.discountPrice
        ? Math.ceil((1 - choice.discountPrice / choice.price) * 100)
        : null;

    return (
        <Flex
            justifySpaceBetween
            fillContainer
            alignCenter
            className={classNames(styles.container, { [styles.selected]: selected })}
            onClick={onClick}
        >
            <Flex>
                <div
                    className={classNames(styles.radioCheck, {
                        [styles.radioCheckSelected]: selected,
                    })}
                />
                <Flex column>
                    <Text.Large bold>{choice.productPackage.name}</Text.Large>
                    <Label choice={choice} />
                    <ValueProposition choice={choice} />
                    <BTTForFeaturedAd ad={ad} choice={choice} />
                </Flex>
            </Flex>
            <Flex column justifyCenter alignCenter className={styles.priceContainer}>
                <Text.Large className={styles.price} bold>
                    {totalPrice}
                </Text.Large>
                {choice.discountPrice && (
                    <Text.Regular strikethrough gray>
                        {price}
                    </Text.Regular>
                )}
            </Flex>
            {!!discountPercentage && (
                <Text.Small bold className={styles.discountAmount}>
                    {discountPercentage}%
                </Text.Small>
            )}
        </Flex>
    );
};

export default OfferPackageChoiceBox;
