import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Button, Flex, Text } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';
import Layout from 'strat/layout';

import { CheckedTextRedesign } from 'horizontal/packages/components';
import IconInfoCircle from 'horizontal/assets/icons/iconInfoCircle.svg';
import type { BusinessPackageSubsection } from 'horizontal/packages/types';

import Subsection from './subsectionRedesign';
import styles from './styles/sectionRedesign.cssm';

type Props = {
    readonly title: string;
    readonly details: Array<string>;
    readonly showExampleButton?: boolean;
    readonly exampleText?: string;
    readonly onClickSeeExample?: () => void;
    readonly subsections: Array<BusinessPackageSubsection>;
    readonly renderIcon?: () => React.ReactElement | null;
    readonly disclaimer?: string;
};

const Section = ({
    title,
    details,
    showExampleButton,
    exampleText,
    onClickSeeExample,
    subsections,
    renderIcon,
    disclaimer,
}: Props) => {
    const layout = useSelector(selectLayout);
    const isMobile = layout === Layout.MOBILE;

    return (
        <Flex column className={styles.container}>
            <Flex alignStart className={styles.sectionHeader}>
                {renderIcon ? renderIcon() : null}
                <Flex stretchWidth justifySpaceBetween>
                    <Flex column className={styles.textContainer}>
                        <Flex justifySpaceBetween alignCenter>
                            <Text.Large bold>{title}</Text.Large>
                        </Flex>
                        <Flex column className={styles.detailContainer}>
                            {details.map((detailItem) => (
                                <CheckedTextRedesign key={detailItem} text={detailItem} />
                            ))}
                        </Flex>
                        {showExampleButton && isMobile && (
                            <Flex className={styles.buttonContainer}>
                                <Button onClick={onClickSeeExample} tertiary>
                                    <Trans>See example</Trans>
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                    {showExampleButton && !isMobile && (
                        <Flex className={styles.buttonContainer}>
                            <Button onClick={onClickSeeExample} tertiary>
                                <Trans>See example for {exampleText}</Trans>
                            </Button>
                        </Flex>
                    )}
                </Flex>
            </Flex>
            {disclaimer && (
                <Flex className={styles.disclaimer} alignCenter>
                    <IconInfoCircle className={styles.iconInfo} />
                    <Text.Regular bold className={styles.disclaimerText}>
                        {disclaimer}
                    </Text.Regular>
                </Flex>
            )}
            <Flex column className={styles.subsectionContainer}>
                {subsections &&
                    subsections.map((subsection, index) => (
                        <Subsection subsection={subsection} key={index} />
                    ))}
            </Flex>
        </Flex>
    );
};

export default Section;
