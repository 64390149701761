import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { ViewSections, trigger, Triggers } from 'strat/gtm';
import { useRouter } from 'react-true-router';
import settings from '@app/branding/settings';

import { useTrackCategoryClick } from 'horizontal/gtm';
import { getDeepCategoryVars } from 'horizontal/gtm/utils';
import { selectCategories } from 'horizontal/categories';
import { AdCreationSourceRoutes } from 'horizontal/constants';
import { AppBundles } from 'horizontal/types';
import type { AppDispatch } from 'horizontal/state';
import type { Category } from 'horizontal/types';

import { fetchCategoryFieldCombinations } from '../categoryFieldCombinations';

const findCategory = (id: number | null | undefined, categories: Category[]) => {
    let foundCategory = categories?.find((category: Category) => category.id === id);
    if (!foundCategory) {
        categories.some((category) => {
            foundCategory = findCategory(id, category.children);
            return foundCategory;
        });
    }
    return foundCategory;
};

const useAdCreationForm = (source: Values<typeof AppBundles> = AppBundles.MAIN_BUNDLE_NAME) => {
    const router = useRouter();
    const dispatch: AppDispatch = useDispatch();

    const [isFillingForm, setIsFillingForm] = React.useState(false);
    const [activeCategory, setActiveCategory] = React.useState<Category | null | undefined>();
    const categories = useSelector(selectCategories);
    const trackCategoryClick = useTrackCategoryClick();

    React.useEffect(() => {
        if (!activeCategory || activeCategory.children?.length) {
            return;
        }

        dispatch(
            fetchCategoryFields({
                categoryExternalIDs: [activeCategory.externalID],
                includeWithoutCategory: !settings.disableCategoryFieldsWithoutCategory,
                groupChoicesBySection: true,
            }),
        );

        dispatch(
            fetchCategoryFieldCombinations({
                categoryID: activeCategory.id,
            }),
        );
    }, [dispatch, activeCategory]);

    React.useEffect(() => {
        if (!activeCategory || activeCategory.children?.length) {
            return;
        }
        trackCategoryClick(activeCategory, ViewSections.BODY);
        setIsFillingForm(true);
    }, [activeCategory, trackCategoryClick]);

    React.useEffect(() => {
        if (!isFillingForm || !activeCategory || activeCategory.children?.length) {
            return;
        }

        trigger(Triggers.AD_CREATION_FILL_FORM, {
            ...getDeepCategoryVars(categories, activeCategory?.externalID),
        });

        router.replaceRoute(AdCreationSourceRoutes[source].CREATE_AD_ROUTE, { attributes: true });
    }, [router, isFillingForm, activeCategory, categories, source]);

    const reset = React.useCallback(() => {
        setActiveCategory(null);
        setIsFillingForm(false);
        router.replaceRoute(AdCreationSourceRoutes[source].CREATE_AD_ROUTE, {});
    }, [setActiveCategory, setIsFillingForm, router, source]);

    const onBack = React.useCallback(() => {
        if (isFillingForm) {
            reset();
            return;
        }

        if (activeCategory) {
            const parentCategory = findCategory(activeCategory.parentID, categories);
            setActiveCategory(parentCategory);
            setIsFillingForm(false);
            return;
        }

        router.pop();
    }, [activeCategory, setActiveCategory, router, isFillingForm, reset, categories]);

    return {
        isFillingForm,
        activeCategory,
        setActiveCategory,
        onBack,
    };
};

export default useAdCreationForm;
