//@ts-nocheck
import ReactDOM from 'react-dom';
import { compose, applyMiddleware, createStore as createReduxStore } from 'redux';
import {
    reducer as idleReducer,
    createMiddleware as createPromiseMiddleware,
} from 'redux-promises';
import thunkMiddleware from 'redux-thunk';
import { batchedSubscribe } from '@sector-labs/redux-batched-subscribe';
import debounce from 'lodash/debounce';
import { searchReducer } from '@sector-labs/fe-search-redux';

import { cookieMiddleware } from 'strat/cookies';
import { routerReducer } from 'react-true-router/middlewares/redux';
import { TaskScheduler, ScheduleScope, taskSchedulerMiddleware } from 'strat/scheduler';

import createReducerManager from './createReducerManager';

/**
 * Creates and initializes a new Redux store.
 * @param initialState The initial state of the store.
 * @param reducers An external list of reducers.
 * @param middlewares An external list of middlewares.
 */
const createStore = (initialState = {}, reducers = {}, middlewares = []) => {
    const baseMiddlewares = [
        thunkMiddleware,
        createPromiseMiddleware(),
        cookieMiddleware,
        taskSchedulerMiddleware,
    ];

    const baseReducers = {
        idle: idleReducer,
        algolia: searchReducer,
        router: routerReducer,
    } as const;

    // enable support for Redux chrome extension in development.
    let composeEnhancers = compose;
    if (CONFIG.build.NODE_ENV !== 'production') {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
    }

    /**
     * When a Redux action is dispatched, tell React to batch
     * setState calls to avoid cascading renders.
     *
     * This improves performance because we don't have pointless
     * intermediate rendering.
     *
     * We also debounce the redux notifications
     *
     * Use TaskScheduler to execute tasks after page has rendered
     * Flush tasks before render in case render was triggered again and TaskScheduler.run didn't get to execute tasks
     */
    const throttleNotify = debounce((callback) => {
        TaskScheduler.flush(ScheduleScope.AFTER_RENDER);
        ReactDOM.unstable_batchedUpdates(callback);
        TaskScheduler.run(ScheduleScope.AFTER_RENDER);
    }, 0);

    // build up a final list of redux middlewares
    const finalMiddlewares = [...baseMiddlewares, ...middlewares];

    if (
        typeof jest === 'undefined' &&
        CONFIG.build.NODE_ENV !== 'production' &&
        process.env.IS_BROWSER
    ) {
        // eslint-disable-next-line global-require
        const createLogger = require('redux-logger');
        finalMiddlewares.push(createLogger({ collapsed: () => true }));
    }

    const reducerManager = createReducerManager({ ...baseReducers, ...reducers });
    const store = createReduxStore(
        reducerManager.reduce,
        initialState,
        composeEnhancers(applyMiddleware(...finalMiddlewares), batchedSubscribe(throttleNotify)),
    );

    // You can add async reducers using: store.reducerManager.add('key', reducer).
    store.reducerManager = reducerManager;

    return store;
};

export default createStore;
