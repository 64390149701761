import { CancelableRoute, RoutingContext } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import { selectUserExternalID } from 'strat/user/session';

import { FullAd } from 'horizontal/types';
import { getStratAPI, StratAPI } from 'horizontal/api';
import Page from 'horizontal/pages/page';
import { PaymentOrder } from 'horizontal/payment';
import { preloadAd } from 'horizontal/ad/state';

import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';
import ensureHasActiveUser from './ensureHasActiveUser';

export type PaymentProcessingRouteParams = {
    readonly orderExternalID: string;
    readonly paymentID: string;
    readonly instrumentID?: string;
    readonly status?: string;
};

type QueryParams = {
    status?: string;
    instrumentID?: string;
};

class PaymentProcessRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.PAYMENT_PROCESSING, [
            [
                '^/payments/packages/consume/ALL/processing/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '/payment/',
                {
                    name: 'paymentID',
                    pattern: '([A-Za-z_0-9-]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { orderExternalID, paymentID, instrumentID, status }: PaymentProcessingRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams: QueryParams = {};
        if (status) {
            queryParams['status'] = status;
        }
        if (instrumentID) {
            queryParams['instrumentID'] = instrumentID;
        }
        const pathname = `/payments/packages/consume/ALL/processing/${orderExternalID}/payment/${paymentID}`;
        return { pathname, search: queryParams };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const dataPromise = this.fetchOrder(context).then((order) => {
            if (!order) {
                return null;
            }
            return this.fetchAd(context, order.adExternalID).then((ad) => {
                return { order, ad };
            });
        });

        context.promise.wait(
            dataPromise.then((result) => {
                if (!result) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.NOT_FOUND);
                    return;
                }
                const { order, ad } = result;

                const paymentID = context.match.params.paymentID;

                if (ad) {
                    preloadAd(ad);
                }

                context.rendering.renderPage(Page.PAYMENT_PROCESSING, {
                    order,
                    paymentID,
                });
            }),
        );
    }

    fetchOrder(context: RoutingContextWithMiddlewares): Promise<PaymentOrder | null | undefined> {
        const orderExternalID = context.match.params.orderExternalID;

        if (!orderExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) => api.getOrder(orderExternalID));
    }

    fetchAd(
        context: RoutingContextWithMiddlewares,
        adExternalID?: string | null,
    ): Promise<FullAd | null | undefined> {
        const userExternalID = selectUserExternalID(context.redux.store.getState());
        if (!adExternalID || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) => api.userAd(userExternalID, adExternalID));
    }

    private makeStratAPIRequest<T>(
        context: RoutingContextWithMiddlewares,
        callback: (api: StratAPI) => Promise<{ status: number; data: T }>,
    ) {
        const {
            redux: {
                store: { getState },
            },
        } = context;

        const api = getStratAPI(getState());
        const request = this.cancelable(callback(api));

        return request.then((response) => (response.status < 300 ? response.data : null));
    }

    onLeave(
        _oldContext?: RoutingContext | RoutingContextWithMiddlewares | undefined,
        _newContext?: RoutingContext | RoutingContextWithMiddlewares | undefined,
    ): void {}
}

export default new PaymentProcessRoute();
