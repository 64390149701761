import type { Dispatch } from 'redux';
import {
    setContent,
    setFilters,
    setSettings,
    selectActiveSearchBackend,
    selectHits,
} from '@sector-labs/fe-search-redux/state';
import brandingSettings from '@app/branding/settings';
import { resetScrollYPosition } from 'strat/search/state';
import { selectLanguage } from 'strat/i18n/language';
import { fetchPageRule } from 'strat/search/state/pageRule';
import Purpose from 'strat/purpose';
import { selectPageRule } from 'strat/search/state/selectors';
import PageRuleType from 'strat/pages/pageRule';
import { RoutingContextWithMiddlewares } from 'strat/app';

import type { SearchRouteParams } from 'horizontal/routes';
import type { LiteAd, LiteHierarchicalLocation, LiteCategory } from 'horizontal/types';
import {
    selectCategoryHierarchyByExternalID,
    selectCategoryConfigurations,
} from 'horizontal/categories';
import Page from 'horizontal/pages/page';
import mergeNewSearchHits from 'horizontal/routes/mergeNewSearchHits';
import { PaginationType } from 'horizontal/types';
import { fetchFrequentSearches } from 'horizontal/search/frequentSearchQueries';

import updatedAdsResponse from './updatedAdsResponse';
import adSearchJobs from './adSearchJobs';
import createAdsSearchSettings from './createAdsSearchSettings';
import getAdsSearchIndex from './getAdsSearchIndex';
import hitsTransformer from './hitsTransformer';
import determineSearchPageType from './determineSearchPageType';
import { SearchJobName } from './types';
import handleRemarketingAd from './handleRemarketingAd';
import createAdsSearchFilters from './createAdsSearchFilters';

const getFetchPageRulePromise = (
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch,
    locationHierarchy: Array<LiteHierarchicalLocation>,
    categoryHierarchy: Array<LiteCategory>,
): Promise<void> => {
    if (!CONFIG.runtime.ENABLE_SEARCH_PAGE_SEARCH_RULES) {
        return Promise.resolve();
    }
    return dispatch(
        fetchPageRule({
            purpose: Purpose.FOR_SALE,
            // @ts-expect-error - TS2322 - Type 'LiteHierarchicalLocation[]' is not assignable to type 'LocationNodeData[]'.
            locationHierarchy,
            // @ts-expect-error - TS2322 - Type 'LiteCategory[]' is not assignable to type 'CategoryNodeData[]'.
            categoryHierarchy,
            beds: null,
            isCustomPage: false,
            exactCategoryMatch: true,
        }),
    );
};

const fetchAdsAndFrequentSearches = (
    context: RoutingContextWithMiddlewares,
    searchRouteParams: SearchRouteParams,
): Promise<any> => {
    const {
        redux: {
            store: { getState, dispatch },
        },
    } = context;
    const state = getState();
    // @ts-expect-error - TS4104 - The type 'readonly SearchResponseHit[]' is 'readonly' and cannot be assigned to the mutable type 'LiteAd[]'.
    const existingHits: LiteAd[] = selectHits(state);
    const language = selectLanguage(state);

    const isUsingInfiniteScrolling =
        brandingSettings.paginationType === PaginationType.INFINITE_SCROLLING;

    const { category, sortOption, remarketingAdID } = searchRouteParams;
    const searchPageType = determineSearchPageType(category, sortOption?.key, searchRouteParams);

    const userLocation = state.user.geoLocation;
    const location =
        searchRouteParams.location ||
        userLocation.closestLocation ||
        brandingSettings.topLevelLocation;

    const categoryConfigurations = selectCategoryConfigurations(
        state,
        searchRouteParams?.category?.externalID,
    );

    const { filters, processedFilters } = createAdsSearchFilters(context, searchRouteParams);

    const indexName = getAdsSearchIndex(language);

    const settings = createAdsSearchSettings(
        { getState, searchRouteParams, categoryConfigurations },
        searchPageType,
    );
    fetchFrequentSearches(dispatch, filters);

    const searchHandler = adSearchJobs({
        indexName,
        processedFilters,
        settings,
        searchPageType,
        remarketingAdID,
        categoryConfigurations,
    });

    const categoryHierarchy = selectCategoryHierarchyByExternalID(
        getState(),
        searchRouteParams.category?.externalID,
    );
    const fetchPageRulePromise = getFetchPageRulePromise(
        dispatch,
        location.hierarchy,
        categoryHierarchy,
    );

    const searchPromises = searchHandler.search({
        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        backend: selectActiveSearchBackend(state),
        labelRequests: true,
    });

    return Promise.all([searchPromises, fetchPageRulePromise]).then(() => {
        const adsResponse = updatedAdsResponse(
            { searchHandler, location, category },
            searchPageType,
        );
        const mainJob = searchHandler.getJobByJobName(SearchJobName.REGULAR);
        if (!adsResponse || !mainJob) {
            context.http.status(404);
            context.rendering.renderPage(Page.NOT_FOUND);
            return;
        }

        const pageRule = selectPageRule(getState());

        if (!adsResponse.hits.length) {
            // if we have no exact hits and no fallback hits, we return 404 regardless of the page rule
            context.http.status(404);
        } else if (!adsResponse.nbHits && pageRule?.statusCode) {
            // page rules status only applies when we have fallback hits
            context.http.status(pageRule?.statusCode);
        }

        if (!adsResponse.nbHits) {
            if (pageRule?.page === PageRuleType.NOT_FOUND) {
                context.rendering.renderPage(Page.NOT_FOUND);
                return;
            }
            if (pageRule?.page === PageRuleType.SEARCH_PAGE && !pageRule?.showRecommendations) {
                adsResponse.hits = [];
                adsResponse.nbPages = 0;
            }
        }

        // Faceting can be disabled in emergency situations to reduce
        // load on the search cluster. We serve 503 to prevent search
        // engine bots from indexing the page. This is because we might
        // end up linking to 404 pages because we have no idea whether
        // a choice in the filters will lead to zero results or not.
        //
        // The page will be remain accessible to users. The 503 just
        // signals bot to come back later when we're fully available.
        if (CONFIG.runtime.DISABLE_SEARCH_FACETING) {
            context.http.status(503);
        }

        // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
        adsResponse.hits = hitsTransformer<LiteAd>(adsResponse.hits, language);

        if (isUsingInfiniteScrolling && searchRouteParams.page) {
            // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
            adsResponse.hits = mergeNewSearchHits(existingHits, adsResponse.hits);
        }

        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '0' can't be used to index type 'SearchResponse<SearchResponseHit>'.
        dispatch(setSettings(mainJob.options));
        dispatch(setFilters(filters));
        dispatch(setContent(adsResponse));
        if (isUsingInfiniteScrolling) {
            dispatch(resetScrollYPosition());
        }
        handleRemarketingAd(dispatch, searchHandler, language);
    });
};

export default fetchAdsAndFrequentSearches;
