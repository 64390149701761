import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

const getDefaultPlaceholderText = (i18n: I18n) => {
    const locationName = settings.getTopLevelLocation(i18n).name;
    return t(i18n)`All ads in ${locationName}`;
};

export default getDefaultPlaceholderText;
