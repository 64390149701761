import * as React from 'react';
import type { RouteParameters } from 'react-true-router/route';
import { LoadingSpinner } from 'strat/loadable';

import { useChatProps, useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoom, ChatRoomsQuery } from 'horizontal/chat/types';

import ConversationsList from './list';
import QuickFilters from './quickFilters';

type Props = {
    query: ChatRoomsQuery;
    setQuery: (prevState: ChatRoomsQuery | ((prevState: ChatRoomsQuery) => ChatRoomsQuery)) => void;
    pageSize: number;
    renderListItem?: (
        route: string,
        params: RouteParameters,
        conversation: ChatRoom,
    ) => React.ReactNode;
};

const Content = ({ pageSize, query, setQuery, renderListItem }: Props) => {
    const sdk = useChatSDK();
    const isChatLoggedIn = sdk.useIsLoggedIn();
    const { hideQuickFilters } = useChatProps();

    if (!isChatLoggedIn) {
        return <LoadingSpinner />;
    }

    return (
        <>
            {!hideQuickFilters ? <QuickFilters query={query} setQuery={setQuery} /> : null}
            <ConversationsList query={query} pageSize={pageSize} renderListItem={renderListItem} />
        </>
    );
};

export default Content;
