import * as React from 'react';
import { useDialog } from 'strat/dialogs/dialogContext';

import FlagIcon from 'horizontal/assets/icons/flag_noinline.svg';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatAd, ChatRoom } from 'horizontal/chat/types';
import { DialogNames } from 'horizontal/dialogs';
import { useDefaultUserName } from 'horizontal/user';

type Props = {
    readonly className?: string;
    readonly ad?: ChatAd;
    readonly conversation?: ChatRoom;
};

const ReportUserButton = ({ className, ad, conversation }: Props) => {
    const [, setReportDialogVisible] = useDialog(DialogNames.REPORT_USER_DIALOG);
    const sdk = useChatSDK();
    const { contactName, isLoading: isUserNameLoading } = sdk.useRoomContactInfo(conversation);
    const defaultContactName = useDefaultUserName();

    if (isUserNameLoading) {
        return null;
    }

    const name = contactName || defaultContactName;

    return (
        <img
            src={FlagIcon}
            role="button"
            onClick={() =>
                setReportDialogVisible(true, {
                    reportedUserExternalID: conversation?.contactXID,
                    reportedUserName: name,
                    ad,
                })
            }
            alt={'Report user'}
            className={className}
        />
    );
};

export default ReportUserButton;
