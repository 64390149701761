import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import chatAssistantPNG from '@app/assets/images/chatAssistant.png';
import chatAssistantWEBP from '@app/assets/images/chatAssistant.webp';
import type { ChatAd, ChatRoom } from 'horizontal/chat/types';

import styles from './styles/questionsTab.cssm';
import SuggestedMessages from './suggestedMessages';

type Props = {
    readonly conversation: ChatRoom;
    readonly ad: ChatAd | null | undefined;
};

const QuestionsTab = ({ ad, conversation }: Props) => {
    const i18n = useI18n();
    return (
        <div className={styles.container}>
            <Flex className={styles.infoBanner}>
                <div className={styles.text}>
                    <Text.Large bold>{t(i18n)`Chat to know more!`}</Text.Large>
                    <Text.Regular>{t(
                        i18n,
                    )`Close the deal faster by asking more about the product or person.`}</Text.Regular>
                </div>
                <picture>
                    <source srcSet={chatAssistantWEBP} type="image/webp" />
                    <img className={styles.chatImage} src={chatAssistantPNG} alt="Chat assistant" />
                </picture>
            </Flex>
            <SuggestedMessages ad={ad} conversation={conversation} />
        </div>
    );
};

export default QuestionsTab;
