import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';

import CallIcon from 'horizontal/assets/icons/callIcon.svg';
import ClockIcon from 'horizontal/assets/icons/clockIcon.svg';

import styles from './styles/callForInspection.cssm';

const CallForInspection = () => (
    <Flex column alignCenter justifyCenter className={styles.inspectionContainer}>
        <Flex column alignCenter justifyCenter>
            <CallIcon />
            <Text.Regular>
                <Trans>Call for Inspection</Trans>
            </Text.Regular>
            <Text.Regular>
                <a href={'tel:76888364'}>76 888 364</a>
            </Text.Regular>
        </Flex>
        <Flex column alignCenter justifyCenter>
            <ClockIcon />
            <Text.Regular>
                <Trans>Availability</Trans>
            </Text.Regular>
            <Text.Regular>
                <Trans>9:00 AM to 6:00 PM</Trans>
            </Text.Regular>
        </Flex>
    </Flex>
);

export default CallForInspection;
