import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSearchHits, selectSearchLoadingState } from '@sector-labs/fe-search-redux/state';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';
import { GTMDataLayerVariables } from 'strat/gtm';
import brandingSettings from '@app/branding/settings';

import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import { usePageViewTrigger } from 'horizontal/gtm';
import useTrackHomeImpressions from '@app/gtm/useTrackHomeImpressions';

import useGlobalGTMVars from '../useGlobalGTMVars';

import PageType from './pageType';

type Props = {
    ovation: OvationCallbackProps;
};

const GTMHomeDataLayer = (props: Props) => {
    const globalGTMVars = useGlobalGTMVars();
    const hits = useSearchHits();
    const { loading } = useSelector(selectSearchLoadingState);
    const { page } = useCurrentSearchRouteParams();

    useTrackHomeImpressions(props.ovation);

    const variables = {
        ...globalGTMVars,
        page_type: PageType.HOME,
        page_number: page,
        fresh_recommendations: hits.map((hit) => hit.externalID),
        popular_categories: brandingSettings.topCategories,
    } as const;

    usePageViewTrigger(`${PageType.HOME}_view`, variables, loading);

    return <GTMDataLayerVariables variables={variables} />;
};

export default withOvation(GTMHomeDataLayer);
