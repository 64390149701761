import type { RoutingContextWithMiddlewares } from 'strat/app';
import { selectActiveUser } from 'strat/user/session';

import { StratAPI } from 'horizontal/api';
import Page from 'horizontal/pages/page';
import { UserRoleTypes } from 'horizontal/types';
import type { FullUserDTO } from 'horizontal/dtos';

const ensureContextUserHasRole = (
    context: RoutingContextWithMiddlewares,
    userRole: Values<typeof UserRoleTypes>,
    targetedPage: Values<typeof Page>,
): Promise<void> => {
    const user = selectActiveUser(context.redux.store.getState());

    if (!user?.externalID) {
        context.rendering.renderPage(Page.NOT_FOUND);
        return Promise.resolve();
    }

    return new StratAPI()
        .fetchRelatedUsers('externalIDs', [user.externalID])
        .then(({ data }) => {
            if (data.length > 0) {
                const selectedUser = data[0];
                if ((selectedUser as FullUserDTO).roles?.includes(userRole)) {
                    context.rendering.renderPage(targetedPage);
                } else {
                    context.rendering.renderPage(Page.NOT_FOUND);
                }
            }
        })
        .catch(() => {
            context.rendering.renderPage(Page.NOT_FOUND);
        });
};

export default ensureContextUserHasRole;
