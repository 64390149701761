import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { UserOrdersRouteParams } from 'horizontal/routes';

const useNavigateToUserOrders = () => {
    const router = useRouter();

    return React.useCallback(
        (params?: UserOrdersRouteParams) => {
            router.pushRoute(RouteNames.USER_ORDERS, params);
        },
        [router],
    );
};

export default useNavigateToUserOrders;
