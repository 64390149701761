import type { CartPackage } from 'horizontal/packages/types';
import type { PaymentProductPackageOffer } from 'horizontal/payment';
import type { AdPhotoDTO } from 'horizontal/types';

const setBusinessPackageCartInLocalStorage = (
    businessPackageCartOffers: PaymentProductPackageOffer[] | CartPackage[],
): void => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        localStorage.setItem(
            'activity',
            JSON.stringify({
                ...activity,
                business_package_cart_offers: businessPackageCartOffers,
            }),
        );
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

const setAdOrderDetailsInLocalStorage = (adExternalID: string): void => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        localStorage.setItem(
            'activity',
            JSON.stringify({
                ...activity,
                adExternalID,
            }),
        );
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

const setRecentlyViewedInLocalStorage = (recentlyViewedAds: Array<any>): void => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        localStorage.setItem(
            'activity',
            JSON.stringify({
                ...activity,
                recentlyViewed: {
                    ads: recentlyViewedAds,
                },
            }),
        );
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

const setAdImageDataToLocalStorage = (photos: Array<AdPhotoDTO>, externalID: string): void => {
    localStorage.setItem(
        'lastUpsertedAd',
        JSON.stringify({
            photos,
            externalID,
        }),
    );
};

export {
    setBusinessPackageCartInLocalStorage,
    setAdOrderDetailsInLocalStorage,
    setRecentlyViewedInLocalStorage,
    setAdImageDataToLocalStorage,
};
