import * as React from 'react';
import { useSelector } from 'react-redux';
import brandingSettings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { SearchLink } from 'horizontal/search/routing';

import styles from './styles/wordCloud.cssm';
import { selectPopularSearches } from './state/selectors';

const BASE_FONT_SIZE = 14;
const MAX_FONT_SIZE = 52;

const WordCloud = () => {
    const { location, category, popularSearches } = useSelector(selectPopularSearches);
    const currentLocation = location || brandingSettings.topLevelLocation;
    const categoryId = category?.id || category;
    const popularSearch = popularSearches.find(
        // @ts-expect-error - TS7006 - Parameter 'popularSearchItem' implicitly has an 'any' type.
        (popularSearchItem) => popularSearchItem.categoryId === categoryId,
    );

    if (!popularSearch) {
        return null;
    }

    const popularWords = popularSearch.popularSearchEntry;
    // @ts-expect-error - TS7006 - Parameter 'accumulator' implicitly has an 'any' type. | TS7006 - Parameter 'word' implicitly has an 'any' type.
    const sum = popularWords.reduce((accumulator, word) => accumulator + word.weight, 0);

    return (
        <Flex className={styles.container}>
            {/* @ts-expect-error - TS7006 - Parameter 'word' implicitly has an 'any' type. */}
            {popularWords.map((word) => {
                const percentageOfTotal = Math.floor((100 * word.weight) / sum);
                const fontSize = Math.min(BASE_FONT_SIZE + percentageOfTotal, MAX_FONT_SIZE);
                const fontSizeWithUnit = `${fontSize}px`;

                return (
                    <SearchLink
                        params={{ freeTextQuery: word.value, location: currentLocation, category }}
                        className={styles.wordLink}
                        key={word.value}
                    >
                        <span style={{ fontSize: fontSizeWithUnit }}>{word.value}</span>
                    </SearchLink>
                );
            })}
        </Flex>
    );
};

export default WordCloud;
