import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';

import { BusinessPackagesLinkSource } from 'horizontal/businessPackages';
import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureProAgentHasAccess from './ensureProAgentHasAccess';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type BusinessPackagesRouteParams = {
    readonly source?: Values<typeof BusinessPackagesLinkSource>;
};

class BusinessPackagesRoute extends Route {
    constructor() {
        super(RouteNames.BUSINESS_PACKAGES, [
            ['^/payments/businesspackages', '(?:\\?)?'],
            [
                '^/payments/businesspackages/',
                {
                    name: 'source',
                    pattern: `(${Object.values(BusinessPackagesLinkSource).join('|')})`,
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({ source }: BusinessPackagesRouteParams): EnhancedLocation {
        if (!source) {
            return { pathname: `/payments/businesspackages` };
        }

        return { pathname: `/payments/businesspackages/${source}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (
            !ensureHasActiveUser(context) ||
            !ensureProAgentHasAccess(context) ||
            !ensureActiveUserIsAllowedAccessAndRedirect(context)
        ) {
            return;
        }

        const { source } = context.match.params;
        context.rendering.renderPage(Page.BUSINESS_PACKAGE, { source });
    }
}

export default new BusinessPackagesRoute();
