import { t } from '@lingui/macro';
import * as React from 'react';
import { RangeFilter } from '@sector-labs/fe-search-redux/filters';
import { isEqual, isNil } from 'lodash';
import EMPTY_OBJECT from 'strat/empty/object';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import { Input } from 'strat/components';
import { useSelector } from 'react-redux';

import type { FlatCategoryField } from 'horizontal/types';

import styles from './styles/range.cssm';
import useFilter from './useFilter';
import useRefineFilter from './useRefineFilter';
import RangeSlider from './rangeSlider';
import showRangeFilterSlider from './showRangeFilterSlider';

type Props = {
    readonly field: FlatCategoryField;
    readonly formatInputValue?: boolean;
};

// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
const noNaN = (value) => (isNaN(value) || value == null ? '' : value.toString());

const Range = ({ field, formatInputValue }: Props) => {
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    const filter = useFilter(field, EMPTY_OBJECT);
    const i18n = useI18n();

    const defaultMin = field.minValue || 0;
    const defaultMax = field.maxValue;

    const [min, setMin] = React.useState(filter.value?.min || defaultMin || 0);
    const [max, setMax] = React.useState(filter.value?.max || defaultMax);
    const refine = useRefineFilter(field.attribute);
    const shouldRefineOnUpdate = React.useRef(false);

    const handleBlur = React.useCallback(() => {
        const minComparedToDefaultMax = defaultMax ? Math.min(min, defaultMax) : min;
        const newMin = Math.max(minComparedToDefaultMax, defaultMin);
        const maxComparedToMin = max != null ? Math.max(max, newMin) : max;
        const newMax =
            defaultMax && maxComparedToMin
                ? Math.min(maxComparedToMin, defaultMax)
                : maxComparedToMin;
        const newValue = RangeFilter.safeGuard({ min: newMin, max: newMax });
        if (!isEqual(newValue, filter.value)) {
            // @ts-expect-error - TS2345 - Argument of type 'RangeFilterValue | null' is not assignable to parameter of type 'CategoryFieldFilterValue'.
            refine(newValue);
        }
        if (newMin !== min) {
            setMin(newMin);
        }
        if (max != null && newMax !== max) {
            setMax(newMax);
        }
    }, [min, max, defaultMax, defaultMin, filter, refine]);

    const setMinValue = React.useCallback((value) => {
        const cleanedValue = value.replace(/[^0-9]/g, '');
        const newMin = parseInt(cleanedValue, 10);
        if (!isNaN(newMin)) {
            setMin(newMin);
        } else if (cleanedValue === '') {
            setMin(0);
        }
    }, []);
    const setMaxValue = React.useCallback((value) => {
        const cleanedValue = value.replace(/[^0-9]/g, '');
        const newMax = parseInt(cleanedValue, 10);
        if (!isNaN(newMax)) {
            setMax(newMax);
        } else if (cleanedValue === '') {
            setMax(0);
        }
    }, []);

    const onChange = React.useCallback(
        ({ values: newValues }) => {
            let [newMin, newMax] = newValues;

            if (!isNil(defaultMin)) {
                newMin = Math.max(newMin, defaultMin);
                newMax = newMax != null ? Math.max(newMax, newMin) : newMax;
            }
            if (!isNil(defaultMax)) {
                newMax = defaultMax && newMax != null ? Math.min(newMax, defaultMax) : newMax;
                newMin = defaultMax ? Math.min(newMin, defaultMax) : newMin;
            }

            newMin = newMax ? Math.min(newMin, newMax) : newMin;

            setMin(newMin);
            setMax(newMax);
            if (shouldRefineOnUpdate.current) {
                const newValue = RangeFilter.safeGuard({ min: newMin, max: newMax });
                // @ts-expect-error - TS2345 - Argument of type 'RangeFilterValue | null' is not assignable to parameter of type 'CategoryFieldFilterValue'.
                refine(newValue);
                shouldRefineOnUpdate.current = false;
            }
        },
        [defaultMin, defaultMax, refine, shouldRefineOnUpdate],
    );

    const showSlider = showRangeFilterSlider(field);

    const numberFormatter = numberFormatterFactory({ maximumFractionDigits: 20 });
    const inputType = formatInputValue ? 'text' : 'number';

    return (
        <div>
            <div className={styles.container} onBlur={handleBlur}>
                <div className={styles.input}>
                    <Input
                        secondary
                        value={formatInputValue ? numberFormatter.format(min) : noNaN(min)}
                        type={inputType}
                        onChange={setMinValue}
                        placeholder={t(i18n)`Min`}
                    />
                </div>
                <div className={styles.input}>
                    <Input
                        secondary
                        value={formatInputValue ? numberFormatter.format(max) : noNaN(max)}
                        type={inputType}
                        onChange={setMaxValue}
                        placeholder={t(i18n)`Max`}
                    />
                </div>
            </div>
            {showSlider && (
                <RangeSlider
                    min={min}
                    max={max}
                    field={field}
                    onChange={onChange}
                    defaultMin={defaultMin}
                    defaultMax={defaultMax}
                    shouldRefineOnUpdate={shouldRefineOnUpdate}
                />
            )}
        </div>
    );
};

export default Range;
