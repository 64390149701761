import type { SearchRequestSortOption } from '@sector-labs/fe-search-redux/backend';
import type { FetchState } from 'strat/fetcher';
import Purpose from 'strat/purpose';
import type { Primitive, LocalizedString, LocationDepthLimits } from 'strat/types';
import type { Geoloc } from 'strat/map/types';
import { KeycloakUserState, KeycloakUserGender } from 'strat/auth/keycloak/user';

import type { FullUserDTO } from 'horizontal/dtos';
import type { AddressInfo } from 'horizontal/userOrders';

export {
    CategoryFieldFilterType,
    CategoryFieldValueType,
    CategoryFieldRole,
    CategoryFieldChoicesKeys,
} from 'strat/types';
export type {
    CategoryFieldChoice,
    CategoryFieldNoChoices,
    CategoryField,
    FlatCategoryField,
    FlatCategoryFieldHierarchy,
    CategoryFieldFilterValue,
    CategoryFieldsFilters,
} from 'strat/types';

// #region Named aliases for readability

export type Timestamp = number; // JS Date timestamp

// #endregion

export type SearchSortOption = SearchRequestSortOption & {
    label: string;
};

export const CustomTagsValues = Object.freeze({
    YES: 'yes',
    NO: 'no',
});

export const ProductType = Object.freeze({
    FEATURED: 'featured_ad',
    ELITE: 'elite_ad',
    LIMIT_BUMP: 'ad_limit_bump',
    REFRESH: 'refresh_ad',
    AUTO_REFRESH_AD: 'auto_refresh_ad',
    AUTO_REFRESH_CATEGORY: 'auto_refresh_category',
    FREE_AD_WITH_COMMISION: 'free_ad_with_commission',
});

export const PackageType = Object.freeze({
    ELITE: 'elite',
    FEATURED: 'featured',
    AUTO_REFRESH: 'auto_refresh',
    REFRESH: 'refresh',
    AUTO_REFRESH_CATEGORY: 'auto_refresh_category',
    FREE_AD_WITH_COMMISSION: 'free_ad_with_commission',
    LIMIT: 'ad_limit_bump',
    LIMIT_AND_FEATURED: 'limit_and_featured',
    LIMIT_AND_ELITE: 'limit_and_elite',
    LIMIT_AND_AUTO_REFRESH: 'limit_and_auto_refresh',
});

export type PackageTypeValues = Values<typeof PackageType>;

export type AdProductTypeValues = Values<typeof ProductType>;

export enum AdProduct {
    FEATURED = 'featured',
    ELITE = 'elite',
    ALL = 'all',
}

export const ProductSection = Object.freeze({
    postMoreAndFeature: 'POST_MORE_AND_FEATURE',
    postMoreAndBoost: 'POST_MORE_AND_BOOST',
});

export const LocationRole = Object.freeze({
    ALLOWS_DELIVERY: 'allows_delivery',
    HAS_SPECIAL_INDEXATION_RULES: 'has_special_indexation_rules',
});

export type LocationHighlight = {
    readonly name: Array<{
        value: string;
    }>;
};

export type LiteHierarchicalLocation = {
    readonly id?: number;
    readonly externalID: string;
    readonly level: number;
    readonly name: string;
    readonly slug: string;
};

export type Location = LiteHierarchicalLocation & {
    readonly geography: Geoloc | null | undefined;
    readonly hasChildren?: boolean;
    readonly _highlightResult?: LocationHighlight;
    readonly roles?: Array<Values<typeof LocationRole>>;
    readonly disabledCategoryIDs?: Array<number>;
    readonly hierarchy: Array<LiteHierarchicalLocation>;
};

export type LocationChoices = Array<Array<Location>>;

export type LocationTranslation = {
    [key: string]: LiteHierarchicalLocation;
};

export const CategoryRole = Object.freeze({
    INCLUDE_PURPOSE_IN_TITLE: 'include_purpose_in_title',
    INCLUDE_PURPOSE_IN_HEADING: 'include_purpose_in_heading',
    INCLUDE_PURPOSE_IN_DESCRIPTION: 'include_purpose_in_description',
    USE_LONG_NAME_IN_HEADING: 'use_long_name_in_heading',
    USE_LONG_NAME_IN_DESCRIPTION: 'use_long_name_in_description',
    SHOW_PHONE_NUMBER: 'show_phone_number',
    ALLOW_AD_WITHOUT_PHOTO: 'allow_ad_without_photo',
    SEARCH_TYPE_CIRCLE_BASED_SLOTTING: 'search_type_circle_based_slotting',
    SEARCH_TYPE_FEATURED_GROUPED: 'search_type_featured_regular_grouped',
    SEARCH_TYPE_FEATURED_EACH_PAGE: 'search_type_featured_regular_each_page',
    SEARCH_TYPE_PAID_FIRST: 'search_type_paid_first',
    SEARCH_TYPE_PAID_FIRST_SLOTTED: 'search_type_paid_first_slotted',
    ALLOWS_DELIVERY: 'allows_delivery',
    NON_EDITABLE_LOCATION: 'non_editable_location',
    ALLOWS_FREE_AD_COMMISSION_FEE: 'allows_free_ad_commission_fee',
    ALLOWS_BTT: 'allows_btt',

    searchPageTypes: () => [
        CategoryRole.SEARCH_TYPE_CIRCLE_BASED_SLOTTING,
        CategoryRole.SEARCH_TYPE_FEATURED_GROUPED,
        CategoryRole.SEARCH_TYPE_FEATURED_EACH_PAGE,
        CategoryRole.SEARCH_TYPE_PAID_FIRST,
        CategoryRole.SEARCH_TYPE_PAID_FIRST_SLOTTED,
    ],

    isPayedSearchType: (categoryRoles: string[]) =>
        categoryRoles.find((role) =>
            [
                CategoryRole.SEARCH_TYPE_PAID_FIRST,
                CategoryRole.SEARCH_TYPE_PAID_FIRST_SLOTTED,
            ].includes(role),
        ),
});

export type CategoryStatistics = {
    readonly activeCount: number;
};

export type CategoryConfigurations = {
    readonly featuredAdsGroupSize: number | null;
    readonly eliteAdsGroupSize: number | null;
};

export type PartialCategory = {
    readonly name: string;
    readonly name_l1?: string;
    readonly nameShort?: string;
    readonly slug: string;
};
export type LiteCategory = PartialCategory & {
    readonly id: number;
    readonly level: number;
    readonly externalID: string;
    readonly locationDepthLimits?: LocationDepthLimits | null | undefined;
};

export type Category = LiteCategory & {
    readonly parentID: number | null | undefined;
    readonly displayPriority: number;
    readonly children: Array<Category>;
    readonly purpose: Values<typeof Purpose>;
    readonly roles: Array<Values<typeof CategoryRole>>;
    readonly statistics: CategoryStatistics;
    readonly configurations: CategoryConfigurations;
};

export type FlatCategoryHierarchy = Array<Omit<Category, 'children'>>;

export type CategoryFieldCombination = {
    readonly id: number;
    readonly external_id: number;
    readonly attributes: Set<number>;
};

export type CategoryFieldCombinationCollection = {
    // @ts-expect-error - TS2411 - Property 'enabled' of type 'boolean' is not assignable to 'string' index type 'FetchState'.
    enabled: boolean;
    [categoryID: string]: FetchState;
};

export const AdStateFilter = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    PENDING: 'pending',
    MODERATED: 'moderated',
});
export type AdStateFilterValues = Values<typeof AdStateFilter>;

export const AdState = Object.freeze({
    ACTIVE: 'active',
    EXPIRED: 'inactive',
    SOLD: 'sold',
    DELETED: 'deleted',
    PENDING: 'pending',
    REJECTED: 'rejected',
    ARCHIVED: 'archived',
    LIMITED: 'limited',
    SOFT_REJECTED: 'soft_rejected',
});
export type AdStateValues = Values<typeof AdState>;

export const ProductStateFilter = Object.freeze({
    ELITE: 'elite',
    FEATURED: 'featured',
    BUMP_UP: 'bump_up',
});

export type ProductStateFilterValues = Values<typeof ProductStateFilter>;

export const AdVirtualState = Object.freeze({
    FEATURED: 'featured',
    ELITE: 'elite',
    FEATURED_PENDING: 'featured_pending',
    ELITE_PENDING: 'elite_pending',
    ACTIVE: 'active',
    EXPIRED: 'expired',
    OUTDATED: 'outdated',
    DISABLED: 'disabled',
    SOLD: 'sold',
    LIMITED: 'limited',
    MODERATION_PENDING: 'moderation_pending',
    MODERATION_HARD_REJECT: 'moderation_hard_reject',
    MODERATION_SOFT_REJECT: 'moderation_soft_reject',
});
export type AdVirtualStateValues = Values<typeof AdVirtualState>;

export const UserState = KeycloakUserState;

export const Gender = KeycloakUserGender;

export const UserRole = Object.freeze({
    ALLOWS_DELIVERY: 'allows_delivery',
});

export type Image = {
    id: number;
    url: string;
};

export type Notification = {
    readonly id: number;
    readonly text: string;
    readonly image: Image;
    readonly createdAt: string;
    readonly viewedAt: string | null | undefined;
    readonly readAt: string | null | undefined;
    readonly url: string;
};

export type FreeTextSuggestionSearchParams = {
    freeTextQuery: string;
    category?: Category;
};

export type FreeTextSuggestion = {
    searchParams: FreeTextSuggestionSearchParams;
    highlightedQuery: string;
};

export type AdPhoto = {
    id: number;
    externalID?: string;
    orderIndex?: number;
    url?: string;
    title?: string;
    alt?: string | null | undefined;
    main?: boolean;
    host?: string;
};
export type AdPhotoDTO = {
    external_id: string | null;
    url: string | undefined | null; // if nothing should change on the photos we send null for the url
    is_main: boolean;
    order_index: number;
};

export type AdVideo = {
    readonly externalID: string;
    readonly title: string;
    readonly host: string;
    readonly url: string;
    readonly orderIndex: number;
};

export type AdPanorama = AdVideo;

export const AdDocumentType = Object.freeze({
    VERIFIED: 'verified',
    MANAGED: 'managed',
    DOCUMENT: 'document',

    CAR_DOCUMENT: 'car_document',
    INSPECTION_SHEET: 'inspection_sheet',
    AUCTION_SHEET: 'auction_sheet',
});

export type AdDocument = {
    readonly title: LocalizedString;
    readonly fileSource: string;
    readonly isActive: boolean;
    readonly externalID: string;
    readonly tag: Values<typeof AdDocumentType>;
};

export type FileUploadProgress = {
    readonly id: string;
    readonly totalBytes: number;
    readonly transferredBytes: number;
    readonly isFinished: boolean;
    readonly error: Error | null;
};

export type UploadedPhoto = {
    id?: number | null;
    externalID: string;
    url: string | undefined | null;
    width: number;
    height: number;
};
export type AdExtraFieldValue = Primitive | Array<Primitive>;

export const PriceType = Object.freeze({
    EXCHANGE: 'exchange',
    FREE: 'free',
    FIXED_PRICE: 'price',
    NEGOTIABLE: 'arranged',
    REQUESTABLE: 'requestable',
});

export const PropertiesPaymentOptionType = Object.freeze({
    CASH: '1',
    INSTALLMENT: '2',
    CASH_OR_INSTALLMENT: '3',
});

export const VehiclesPaymentOptionType = Object.freeze({
    CASH: '1',
    EXCHANGE: '2',
    INSTALLMENT: '3',
});

export type AdKnownExtraFields = {
    new_used?: string;
    make?: string;
    model?: string;
    down_payment?: number;
    installment_plan?: string;
    year?: number;
    mileage?: number;
    rooms?: number;
    bathrooms?: number;
    petrol?: string;
    ft?: number;
    ft_unit?: string;
    location?: string;
    price?: number;
    price_type?: Values<typeof PriceType>;
    salary_period?: string;
    salary_from?: number;
    salary_to?: number;
    job_type?: string;
    deliverable?: string;
    readonly panorama?: string;
    readonly video?: string;
    readonly delivery?: string;
    readonly document?: string;
    readonly managed?: string;
    readonly new?: string;
    readonly hot?: string;
    readonly zero_km?: string;
    readonly verified?: string;
    readonly discounted?: string;
    readonly save_ten_percent?: string;
    readonly save_twenty_percent?: string;
    readonly save_thirty_percent?: string;
    readonly save_forty_percent?: string;
    readonly save_fifty_percent?: string;
    readonly save_sixty_percent?: string;
    readonly save_seventy_percent?: string;
    readonly eid_collection?: string;
    readonly new_collection?: string;
    readonly rental_period?: string;
};

export type AdExtraFields = AdKnownExtraFields & {
    [key: string]: AdExtraFieldValue;
};

export type AdStatistics = {
    readonly viewCount?: number;
    readonly phoneViewCount?: number;
};

export const AdFormat = Object.freeze({
    LITE: 'lite',
    FULL: 'full',
});

export type AdProductInfo = {
    readonly expiresAt: number;
    readonly name: string;
};

export const AgencyRoles = Object.freeze({
    SYNDICATE_CERTIFIED: 'syndicate_certification',
    DISABLE_AD_CARD_LOGO: 'disable_ad_card_logo',
    MANAGED_BY_EXTERNAL_SOURCE: 'managed_by_external_source',
    ALLOW_AGENTS_WHATSAPP_NUMBER: 'allow_agents_whatsapp_number',
    ENABLE_PRO_AGENCY_MODEL: 'enable_pro_agency_model',
});

export type AgencyLogo = {
    readonly id: number;
    readonly name?: string;
};

export const AgencyType = Object.freeze({
    REAL_ESTATE: 'real-estate',
    UNKNOWN: 'unknown',
});

export type Agency = {
    readonly logo: AgencyLogo;
    readonly name: string;
    readonly externalID: string;
    readonly agentExternalID?: string;
    readonly id: number;
    readonly roles: Array<Values<typeof AgencyRoles>> | null | undefined;
    readonly commercialNumber?: string | null;
    readonly slug: string;
    readonly active: boolean;
    readonly shortNumber?: string;
    readonly createdAt?: string;
    readonly description?: string | null;
};

export type AdAgent = {
    readonly email?: string;
    readonly user_external_id: string;
    readonly external_id: string;
    readonly name?: string;
    readonly state?: string;
    readonly phoneNumber: string | null | undefined;
};
export type AgentCreditBalance = {
    readonly availableCredit?: number;
    readonly totalCredit?: number;
    readonly creditExpiresAt?: Date;
};
export type Agent = {
    readonly email?: string;
    readonly user_external_id: string;
    readonly external_id: string;
    readonly name: string;
    readonly state?: string;
    readonly phoneNumber: string;
    readonly whatsappNumber?: string;
    readonly adsStatus?: Partial<Record<Values<typeof AdVirtualState>, number>>;
    readonly creditBalance?: AgentCreditBalance;
    readonly roles?: Array<string>;
    readonly agency?: Agency;
};

// It must be kept in Sync with backend/strat/strat/classifieds/models/contact_person.py
export enum ContactPersonRole {
    SHOW_PHONE_NUMBER = 'show_phone_number',
    ALLOW_CHAT_COMMUNICATION = 'allow_chat_communication',
    SHOW_SHORT_NUMBER = 'show_short_number',
    SHOW_BUY_WITH_DELIVERY_ON_SEARCH_PAGE = 'show_buy_with_delivery_on_search_page',
    SHOW_WHATSAPP_PHONE_BUTTON = 'show_whatsapp_phone_number',
}

export type AdPublicContactInfo = {
    readonly name: string | null | undefined;
    readonly roles: Array<ContactPersonRole> | null | undefined;
};

export type AdContactInfo = AdPublicContactInfo & {
    readonly mobile: string;
    readonly proxyMobile: string;
    readonly whatsapp?: string;
};

export type AdDeliveryInformation = {
    readonly weight: string;
    readonly pickupAddressID: number;
    readonly length: string;
    readonly height: string;
    readonly width: string;
    readonly deliveryType: DeliveryType;
};

export type AdActiveProductEntity = {
    appliedAt: number;
    expiresAt: number;
    productPurchaseId: number;
};

export type AdTag = {
    name: string;
    name_l1: string;
    order: number;
};

export type AdActiveProducts = Partial<Record<Values<typeof ProductType>, AdActiveProductEntity>>;

export type Ad = {
    readonly id: number;
    readonly externalID: string;
    readonly sourceID: number;
    readonly purpose: Values<typeof Purpose>;
    readonly price: number | null | undefined;
    readonly slug: string;
    readonly title: string;
    readonly title_l1: string;
    readonly description: string;
    readonly description_l1: string;
    readonly category: Array<LiteCategory>;
    readonly location: Array<LiteHierarchicalLocation>;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly product?: AdProduct;
    readonly productInfo?: AdProductInfo | null | undefined;
    readonly productRefreshedAt?: number;
    readonly refreshedAt?: number;
    readonly state: AdStateValues;
    readonly stateMessage: string | null;
    readonly virtualState: AdVirtualStateValues;
    readonly extraFields?: AdExtraFields;
    readonly geography: Geoloc | null | undefined;
    readonly userExternalID: string | null | undefined;
    readonly type?: string;
    readonly timestamp: number;
    readonly agency?: Agency;
    readonly postingID: number;
    readonly productConsumptionIDs: Array<number>;
    readonly videoCount?: number;
    readonly panoramaCount?: number;
    readonly documentCount?: number;
    readonly videos?: Array<AdVideo>;
    readonly panoramas?: Array<AdPanorama>;
    readonly documents?: Array<AdDocument>;
    readonly documentsTags?: Array<Values<typeof AdDocumentType>>;
    readonly contactInfo?: AdPublicContactInfo;
    readonly deliveryInformation?: AdDeliveryInformation;
    readonly activeProducts?: AdActiveProducts;
    readonly adTags?: Array<AdTag>;
    readonly adTagsCount?: number;
    readonly agents?: Array<AdAgent>;
    readonly allProductConsumptions?: AdActiveProducts;
    readonly isSellerVerified?: boolean;
    readonly singleBoostAppliedAt?: number | null;
    readonly favoriteCount?: number;
    readonly photos: Array<AdPhoto> | null | undefined;
    readonly isDeliverable?: boolean;
};

export type LiteAd = Ad & {
    readonly format: 'lite'; // AdFormat.LITE,
    readonly coverPhoto: AdPhoto | null | undefined;
    readonly photoCount: number;
    readonly requestIndex?: number | null | undefined;
};

export type FullAd = Ad &
    AdStatistics & {
        readonly format: 'full'; // AdFormat.FULL,
        readonly description: string;
        readonly description_l1: string;
        readonly expiresAt: number | null | undefined;
        readonly deliveryInformation?: AdDeliveryInformation;
        readonly locationTranslations?: Array<LocationTranslation>;
    };

export type MixedAd = LiteAd | FullAd;

type AdWithExpiresAt = MixedAd & {
    readonly expiresAt: number;
};

export const hasExpiresAt = (ad: MixedAd): ad is AdWithExpiresAt =>
    'expiresAt' in ad && !!ad.expiresAt;

export const PackageRoles = Object.freeze({
    PUBLIC: 'public',
    SINGLE: 'single',
    MULTIPLE: 'multiple',
    OFFLINE: 'offline',
    TELE_SALES: 'telesales',
});

export const UserRoleTypes = Object.freeze({
    TELE_SALES: 'telesales_user',
});

export type AdPattern = {
    readonly externalID: string;
};

export type Product = {
    readonly id: number;
    readonly type: Values<typeof ProductType>;
    readonly frequency: number | null | undefined;
    readonly duration: number | null | undefined;
    readonly frequencyTimeUnit: string | null | undefined;
};

export type ProductPackage = {
    readonly packageID: string;
    readonly productItems: Array<Product>;
    readonly name: string;
    readonly description: string;
    readonly valueProposition: string | null | undefined;
    readonly validFor: number;
    readonly productsQuantity: number;
};

export type ProductPackageOffer = {
    readonly packageOfferID: string;
    readonly productPackage: ProductPackage;
    readonly price: number;
    readonly discountPrice: number | null | undefined;
    readonly grossPrice: number;
    readonly categoryID: string | null | undefined;
    readonly categoryLabel: string | null | undefined;
    readonly locationID: string | null | undefined;
    readonly locationLabel: string | null | undefined;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly label?: string | null | undefined;
    readonly labelColor?: string | null | undefined;
    readonly productConsumptionWeight?: number | null | undefined;
    readonly adPattern?: AdPattern;
};

export type ProductConsumption = {
    readonly expiresAt: number;
    readonly appliedAt: number;
    readonly productPurchaseId?: number | null | undefined;
};

export type ConsumedProductAd = {
    readonly id: number;
    readonly state: AdStateValues;
    readonly title: string;
    readonly title_l1: string;
    readonly externalID: string;
    readonly slug: string;
    readonly slug_l1: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly virtualState: AdVirtualStateValues;
    readonly allProductConsumptions?: AdActiveProducts;
    readonly agents?: Array<AdAgent>;
    readonly activeProducts: AdActiveProducts | null;
};

export type ProductPurchaseAgent = {
    readonly externalId: string;
    readonly name: string;
    readonly assignedQuotaCount: number;
    readonly usedQuotaCount: number;
};

export type ProductPurchaseAgents = {
    readonly count: number;
    readonly results: Array<ProductPurchaseAgent>;
};

export const AdLimitConditionOperator = Object.freeze({
    MISSING: 'miss',
    ONE_OF: 'in',
    LESS_THAN: 'lt',
    LESS_THAN_OR_EQUAL: 'lte',
    GREATER_THAN: 'gt',
    GREATER_THAN_OR_EQUAL: 'gte',
});

export type AdLimitConditionOperatorValues = Values<typeof AdLimitConditionOperator>;
export type AdLimitConditionValue = boolean | string | number | Array<string> | Array<number>;
export type AdLimitConditionMatchOperators = Partial<
    Record<AdLimitConditionOperatorValues, AdLimitConditionValue>
>;

// The key is the extra field name
export type AdLimitExtraFieldCondition = {
    [key: string]: AdLimitConditionMatchOperators;
};

export type FreeAdLimitUsage = {
    readonly id: number;
    readonly category: Category;
    readonly quota: number;
    readonly durationInDays: number;
    readonly usedQuota: number;
    readonly firstUsedAt: string | null | undefined; // ISO date,
    readonly conditions?: {
        readonly category: AdLimitConditionMatchOperators;
        readonly extraFields: AdLimitExtraFieldCondition;
    };
};

export type ProductPurchase = {
    readonly id: number;
    readonly externalID: string;
    readonly productPackageOffer: ProductPackageOffer;
    readonly startDate: string;
    readonly expiresAt: string;
    readonly purchasedAt: string;
    readonly orderID: string;
    readonly usageLimit: number;
    readonly usages: number;
    readonly status: string;
    readonly available: number;
    readonly agencyPackageUsageLimit?: number;
    readonly agencyPackageUsages?: number;
    readonly productConsumptionWeight?: number;
    readonly totalQuotaAssignedToAgents?: number;
};

export type CarouselBanner = {
    identifier: string;
    title: string;
    action?: string;
    url: string;
    BannerOrder: number;
    image: {
        sourceWEBP_3x?: string;
        sourceWEBP_2x?: string;
        sourceWEBP?: string;
        source: string;
    };
};

export const PaginationType = Object.freeze({
    INFINITE_SCROLLING: 'infinite_scrolling',
    PAGINATION: 'pagination',
});

export const VerticalSelection = Object.freeze({
    MALL: 'Mall',
    MOTORS: 'Motors',
    PROPERTY: 'Property',
    CARCASH: 'Cash-Car',
});

export const AdBadgeType = Object.freeze({
    // The badge is applied if the ad has a panorama.
    // The panorama can be added as a link from the admin panel for each ad.
    PANORAMA: 'panorama',
    // The badge is applied if the ad has a video.
    // The video can be added as a link from the admin panel for each ad.
    VIDEO: 'video',
    // The badge is applied if the ad has delivery option available
    // This option can be chosen at ad posting time. It is only available on certain categories and locations.
    DELIVERY: 'delivery',

    // <document badges>
    // These badges appear if the ad has an attached document.
    // The document tag which appears for the document can be configured from the admin panel for each ad.
    DOCUMENT: 'document',
    VERIFIED: 'verified',
    MANAGED: 'managed',
    // </document badges>
    DOCS_ATTACHED: 'docs_attached',
    DELIVERY_VERIFIED: 'delivery_verified',

    SELLER_VERIFIED: 'seller_verified',
    AGENCY_VERIFIED: 'agency_verified',
});

export const ConditionType = Object.freeze({
    NEW: 'new',
    USED: 'used',
});

export type AdBadge = {
    readonly type: Values<typeof AdBadgeType>;
    readonly order: number;
};

export const AdBadges = Object.freeze({
    [AdBadgeType.PANORAMA]: {
        type: AdBadgeType.PANORAMA,
        order: 1,
    },
    [AdBadgeType.VIDEO]: {
        type: AdBadgeType.VIDEO,
        order: 2,
    },
    [AdBadgeType.DELIVERY]: {
        type: AdBadgeType.DELIVERY,
        order: 3,
    },
    [AdBadgeType.DOCUMENT]: {
        type: AdBadgeType.DOCUMENT,
        order: 4,
    },
    [AdBadgeType.MANAGED]: {
        type: AdBadgeType.MANAGED,
        order: 4,
    },
    [AdBadgeType.VERIFIED]: {
        type: AdBadgeType.VERIFIED,
        order: 5,
    },
    [AdBadgeType.DELIVERY_VERIFIED]: {
        type: AdBadgeType.DELIVERY_VERIFIED,
        order: 5,
    },
    [AdBadgeType.SELLER_VERIFIED]: {
        type: AdBadgeType.SELLER_VERIFIED,
        order: 0,
    },
    [AdBadgeType.AGENCY_VERIFIED]: {
        type: AdBadgeType.AGENCY_VERIFIED,
        order: 0,
    },
    [AdBadgeType.DOCS_ATTACHED]: {
        type: AdBadgeType.DOCS_ATTACHED,
        order: 0,
    },
});

export type AdDetailsBadges = {
    type: Values<typeof AdBadgeType>;
    readonly url?: string;
};

export const ChoiceSortByField = Object.freeze({
    VALUE: 'value',
    DISPLAY_PRIORITY: 'displayPriority',
});

export const verticalPages = Object.freeze({
    PROPERTIES: 'properties',
    CARS: 'cars',
    CARS_FOR_SALE: 'cars-for-sale',
});

export const AgentRoles = Object.freeze({
    OWNER: 'Owner',
});

export const AgentRolesIDs = Object.freeze({
    OWNER: 2,
});

export const AgentSortTypes = Object.freeze({
    NAME: 'name',
    TOTAL_ADS: 'total_ads',
    ACTIVE_ADS: 'active_ads',
});

export const AppBundles = Object.freeze({
    AGENCY_PORTAL_BUNDLE_NAME: 'agencyPortal',
    MAIN_BUNDLE_NAME: 'main',
});

export type URLSpecificSEO = {
    id: number;
    objectID: number;
    url: string;
    languageCode: string;
    h1: string;
    metaTitle: string;
    metaDescription: string;
    content: string;
    canonicalOverride: string;
    robotsMetaContent: string;
};

export const TransactionPurpose = Object.freeze({
    ALL: 'all',
    FOR_RENT: 'for-rent',
    FOR_SALE: 'for-sale',
});

export const SearchPageType = Object.freeze({
    REMARKETING: 'alt_offerdetail',
});

export const AdDetailPageType = Object.freeze({
    REMARKETING: 'alt_offerdetail',
});

export type DeliveryOrderAd = {
    id: number;
    externalID: string;
    extraFields: AdExtraFields;
    title_l1: string;
    title: string;
    coverPhoto?: AdPhoto;
    slug_l1: string;
    slug: string;
    createdAt: number;
};

export type DeliveryBuyingOrder = {
    id: number;
    ad: DeliveryOrderAd;
    buyer: FullUserDTO;
    buyerAlternativeName: string;
    buyerPhoneNumber: string;
    buyerAlternativePhone: string | null;
    status: string;
    cancellable: boolean;
    createdAt: number;
    updatedAt: number;
    deliveryAddress: AddressInfo;
    price: number | null;
    deliveryType: DeliveryType;
};

export type DeliverySellingOrder = {
    id: number;
    externalID: string;
    extraFields: AdExtraFields;
    title_l1: string;
    title: string;
    coverPhoto: AdPhoto;
    deliveryOffersCount: number;
    slug_l1: string;
    slug: string;
    createdAt: number;
};

export enum CTA {
    WHATSAPP = 'WHATSAPP',
    CHAT = 'CHAT',
    SMS = 'SMS',
    CALL = 'CALL',
    BUY = 'BUY',
}

export enum MarketingCampaigns {
    WALLET_TOP_UP = 'walletTopUp',
}

export type BundledProduct = {
    readonly type: Values<typeof ProductType>;
    readonly durationDays?: number;
    readonly frequencyHours?: number;
};

export type ProductBundle = {
    externalID: string;
    products: BundledProduct[];
};

export type AdProductCreditCost = {
    readonly externalID: string;
    readonly credits: number;
    readonly productBundle: ProductBundle;
};

export type AgentProductCreditSummary = {
    readonly total: number;
    readonly available: number;
    readonly consumed: number;
    readonly expiresAt: number;
};

export type AgencyProductCreditSummary = {
    readonly totalCredits: number;
    readonly availableCredits: number;
    readonly ownerConsumptions: number;
    readonly assignedToAgents: number;
    readonly agentsConsumptions: number;
    readonly expiresAt: number;
};

export const AdExcludedExtraFieldsFromAdDetails: Record<string, string> = {
    deliverable: 'deliverable',
    document: 'document',
    managed: 'managed',
    self_deliverable: 'self_deliverable',
    new: 'new',
    hot: 'hot',
    verified: 'verified',
    zero_km: 'zero_km',
    discounted: 'discounted',
    save_ten_percent: 'save_ten_percent',
    save_twenty_percent: 'save_twenty_percent',
    save_thirty_percent: 'save_thirty_percent',
    save_forty_percent: 'save_forty_percent',
    save_fifty_percent: 'save_fifty_percent',
    save_sixty_percent: 'save_sixty_percent',
    save_seventy_percent: 'save_seventy_percent',
    eid_collection: 'eid_collection',
    new_collection: 'new_collection',
    ramadan: 'ramadan',
    holidays: 'holidays',
    highlights: 'highlights',
    weekly_finds: 'weekly_finds',
    black_friday: 'black_friday',
    summer: 'summer',
};

export enum DeliveryType {
    SELF_DELIVERY = 'SELF_DELIVERY',
    SERVICE_DELIVERY = 'SERVICE_DELIVERY',
}
