import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';
import { ReviewDate } from 'strat/agency/agent/review';
import { CollapsibleText } from 'strat/generic';
import scrollToElementTop from 'strat/navigation/scroll/scrollToElementTop';

import type { ShortReviewData } from './types';
import styles from './styles/collapsibleReviewHistory.cssm';

type Props = {
    reply: ShortReviewData;
    agentName: string;
    className: string;
    commentClassName?: string | null | undefined;
    height?: number | null | undefined;
};

const firstName = (agentName: string) => agentName.split(' ')[0];

const AgentReply = ({
    reply,
    agentName,
    className,
    height = 80,
    commentClassName = classNames(styles.comment, styles.reply),
}: Props) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);
    return (
        <div dir={isLanguageRTL ? 'rtl' : 'ltr'} className={className}>
            <span className={styles.anchor} />
            <div className={styles.replyContainer}>
                <div className={styles.lightText}>
                    <Trans>
                        {firstName(agentName)} replied on{' '}
                        <ReviewDate timestampMillis={reply.createdAt} className={styles.inline} />
                    </Trans>
                </div>
                <CollapsibleText
                    // @ts-expect-error - TS2769 - No overload matches this call.
                    height={height}
                    buttonClassName={styles.moreButton}
                    scrollToElementTop={(node) => scrollToElementTop(node)}
                >
                    {/* @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'string | undefined'. */}
                    <div className={commentClassName} dir="auto">
                        {reply.comment}
                    </div>
                </CollapsibleText>
            </div>
        </div>
    );
};

export default AgentReply;
