import Purpose from 'strat/purpose';

import type { BreadcrumbsElement } from './types';

const computeParameters = (
    element: BreadcrumbsElement,
    categorySlug: string | null,
    purpose: string,
    rentFrequency?: string | null,
    beds?: number | null,
) => {
    const parameters = element.params || {};

    if (element.slug) {
        parameters.locations = [{ ...element }];
    }

    if (purpose) {
        parameters.purpose = purpose;
    }

    if (rentFrequency && purpose === Purpose.FOR_RENT) {
        parameters.rentFrequency = rentFrequency;
    }

    if (element.beds !== undefined && Number.isInteger(element.beds)) {
        parameters.beds = element.beds;
    } else if (beds) {
        parameters.beds = beds;
    }

    if (element.category) {
        parameters.category = element.category.slug;
    } else if (categorySlug) {
        parameters.category = categorySlug;
    }

    if (element.area) {
        parameters.area = element.area.slug;
    }

    if (element.completionStatus) {
        parameters.completionStatus = element.completionStatus;
    }

    if (element.listingType) {
        parameters.listingType = element.listingType;
    }

    return parameters;
};

export default computeParameters;
