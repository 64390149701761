import { type FetchData, FetcherFactoryWithContext } from 'strat/fetcher';
import { InternalAPI } from 'strat/api';
import { AdType } from 'strat/api/types';
import EMPTY_OBJECT from 'strat/empty/object';

export type FetchMetricsParams = {
    adExternalIDs: Array<string>;
};

/**
 * Fetcher factory for fetching ad metrics.
 */
const factory = new FetcherFactoryWithContext<FetchMetricsParams>(
    'adMetrics',
    ({ params }) => {
        const paramsWithAdSourceAndType = {
            ...params,
            adSource: 'imported',
            adType: AdType.GENERAL,
        } as const;

        return new InternalAPI().adsMetrics(paramsWithAdSourceAndType);
    },
    { skipParamsCheck: true },
);

const stateProcessor = (data: null | FetchData, previousData: null | FetchData) => {
    const currentStateItems = previousData || EMPTY_OBJECT;
    const newIncomingItemsFromServer = data || EMPTY_OBJECT;
    return { ...currentStateItems, ...newIncomingItemsFromServer };
};

/**
 * Action creator for fetching ad metrics from Ovation
 */
const fetchAdMetrics = factory.creator();

const adMetricsReducer = factory.reducer(stateProcessor);

export { fetchAdMetrics };

export default adMetricsReducer;
