import * as React from 'react';
import Logo from '@app/branding/logo';
import { BackButton, Header } from 'strat/components';

import { SelectPackagesForm } from 'horizontal/packages';
import type { FullAd, ProductPurchase } from 'horizontal/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/selectSinglePackagePageDesktop.cssm';

type Props = {
    readonly purchasePackages: Array<ProductPurchase> | null | undefined;
    readonly ad: FullAd | null | undefined;
    readonly withoutSkipButton: boolean;
};

const SelectMultiplePackagesPage = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ purchasePackages, ad, withoutSkipButton }: Props) => (
        <>
            <Header className={styles.header}>
                <BackButton popHistory />
                <Logo className={styles.logo} />
            </Header>
            <div className={styles.container}>
                <SelectPackagesForm
                    packages={purchasePackages}
                    ad={ad}
                    withoutSkipButton={withoutSkipButton}
                />
            </div>
        </>
    ),
} as const;

export default SelectMultiplePackagesPage;
