import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setExchangeRates } from 'strat/i18n/money';
import settings from '@app/branding/settings';

import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';

const useFetchPackagesExchangeRates = () => {
    const dispatch = useDispatch();
    const makeCancelable = useMakeCancelable();
    React.useEffect(() => {
        if (!settings.multipleCurrencyPackages) {
            return;
        }
        makeCancelable(
            new StratAPI().getProductPackageOffersExchangeRate().then((result) => {
                const { data, status } = result;
                if (status !== 200) {
                    return;
                }
                dispatch(setExchangeRates(data));
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useFetchPackagesExchangeRates;
