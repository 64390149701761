import * as React from 'react';

import type { BusinessPackageExampleTypeValues } from './types';

const Context = React.createContext<
    [
        BusinessPackageExampleTypeValues | null | undefined,
        (arg1: BusinessPackageExampleTypeValues | null) => void,
    ]
>([null, () => {}]);

Context.displayName = 'BusinessPackageExampleContext';

export default Context;
