import React from 'react';
import { Flex } from 'strat/components';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';

import ProfileHeader from 'horizontal/profileHeader';
import { Header } from 'horizontal/header';
import Footer from 'horizontal/footer';
import UserWallet from 'horizontal/userWallet/userWallet';

import WalletPageHead from './walletPageHead';
import styles from './styles/walletPageDesktop.cssm';

type Props = {
    readonly section: string;
};

const WalletPageDesktop = {
    head: () => <WalletPageHead />,
    body: ({ section }: Props) => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <PageWidthWrapper className={styles.pageWidthWrapper}>
                <Flex fillContainer stretchWidth column className={styles.container}>
                    <ProfileHeader header={<Trans>dubizzle Wallet</Trans>} />
                    <UserWallet section={section} />
                </Flex>
            </PageWidthWrapper>
            <Footer />
        </Flex>
    ),
} as const;

export default WalletPageDesktop;
