import brandingSettings from '@app/branding/settings';

import { SearchPageSearchType } from 'horizontal/search/types';

import getHitsPerPage from '../getHitsPerPage';

const featuredAdsCount = (searchPageType: Values<typeof SearchPageSearchType>) =>
    brandingSettings.featuredAdsGroupSizes(getHitsPerPage(searchPageType)).default;

export default featuredAdsCount;
