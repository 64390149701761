import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Image } from 'strat/util';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import EmptyOrdersPNG from '@app/assets/images/emptyOrders.png';
import EmptyOrdersWEBP from '@app/assets/images/emptyOrders.webp';

import { ProductPurchaseVirtualStatus } from '../types';

import styles from './styles/emptyProductPurchases.cssm';

const useTranslatedStatus = (status?: Values<typeof ProductPurchaseVirtualStatus> | null) => {
    const i18n = useI18n();
    switch (status) {
        case ProductPurchaseVirtualStatus.ACTIVE:
            return t(i18n)`active`;
        case ProductPurchaseVirtualStatus.SCHEDULED:
            return t(i18n)`scheduled`;
        case ProductPurchaseVirtualStatus.EXPIRED:
            return t(i18n)`expired`;
        default:
            return null;
    }
};

const EmptyProductPurchases = ({
    status,
}: {
    readonly status: Values<typeof ProductPurchaseVirtualStatus> | null | undefined;
}) => {
    const translatedStatus = useTranslatedStatus(status);
    return (
        <Flex fillContainer alignCenter column>
            <Image
                className={styles.image}
                image={{
                    source: EmptyOrdersPNG,
                    sourceWEBP: EmptyOrdersWEBP,
                }}
            />
            <Text.Base bold className={styles.title}>
                <Trans>Nothing here...</Trans>
            </Text.Base>
            <Text.Base className={styles.description}>
                <Trans>You don&apos;t have any {translatedStatus} packages</Trans>
            </Text.Base>
        </Flex>
    );
};

export default EmptyProductPurchases;
