import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

// eslint-disable-next-line no-restricted-imports
import BuyerOptions from '@app/chat/widgets/components/buyerOptions';
import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import { useChatSDK, useFetchUsers } from 'horizontal/chat/hooks';
import type { ChatAdXID } from 'horizontal/chat/types';
import { AdSoldMedium } from 'horizontal/constants';

import styles from './styles/buyersList.cssm';
import { BuyerInfo } from './types';

type SelectBuyerProps = WithChatStateProps & {
    readonly users?: Array<BuyerInfo>;
    readonly onBuyerSelected: (externalID: string) => void;
    readonly selectedBuyerExternalID?: string | null;
};

const SelectBuyer = (props: SelectBuyerProps) => {
    const { onBuyerSelected, selectedBuyerExternalID } = props;
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    const users = [
        { externalID: AdSoldMedium.OUTSIDE_STRAT, name: t(i18n)`Sold outside ${brandName}` },
        { externalID: AdSoldMedium.INSIDE_STRAT, name: t(i18n)`Sold on ${brandName}` },
        ...(props.users ?? []),
    ];
    return (
        <>
            <div className={styles.title}>
                <Text.XXLarge bold>
                    <Trans>Mark as sold</Trans>
                </Text.XXLarge>
            </div>
            <div className={styles.subtitle}>
                <Text.Large>
                    <Trans>Who bought your Ad?</Trans>
                </Text.Large>
            </div>
            <div className={styles.listContainer}>
                <BuyerOptions
                    users={users}
                    selectedBuyerExternalID={selectedBuyerExternalID}
                    onBuyerSelected={onBuyerSelected}
                />
            </div>
        </>
    );
};

type SelectBuyerFromChatProps = Omit<SelectBuyerProps, 'users'> & {
    readonly adXID: string;
};

const SelectBuyerFromChat = ({ adXID, ...props }: SelectBuyerFromChatProps) => {
    const sdk = useChatSDK();
    const {
        userXIDs,
        contacts,
        isLoading: isLoadingBuyers,
    } = sdk.useBuyersForAd({
        adXID: adXID as ChatAdXID,
        includeContacts: true,
    });
    const { fetchUserProfiles, loading: isLoadingProfiles, users: profiles } = useFetchUsers();
    const isLoading = isLoadingBuyers || isLoadingProfiles;

    React.useEffect(() => {
        // When using firebase chat, contacts is always undefined,
        // so we need to fetch them ourselves.
        if (contacts == null && !isLoadingBuyers) {
            fetchUserProfiles(userXIDs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingBuyers, contacts, userXIDs]);

    if (isLoading) {
        return (
            <Flex justifyCenter className={styles.element}>
                <Text.Regular bold>Loading users...</Text.Regular>
            </Flex>
        );
    }

    const users: Array<BuyerInfo> = contacts ?? profiles;

    return <SelectBuyer {...props} users={users} />;
};

export default withChatState<SelectBuyerFromChatProps>(SelectBuyerFromChat, SelectBuyer);
