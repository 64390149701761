import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';
import { RouteNames } from 'strat/routes';

/**
 * Selects the `router` part of the state.
 */
const selectRouter = createSelector(
    (state: GlobalState) => state.router,
    (router) => router,
);

/**
 * Selects the name of the active route.
 */
const selectRouteName = createSelector(
    selectRouter,
    (router) => router.routeName as Values<typeof RouteNames>,
);
const selectRouteURL = createSelector(selectRouter, (router) => router.url);
const selectRouteOriginalURL = createSelector(selectRouter, (router) => router.originalURL);
const selectRouteParams = createSelector(selectRouter, (router) => router.routeParams);
const selectRouteReferrer = createSelector(selectRouter, (router) => router.referrer);
const selectLandingURL = createSelector(selectRouter, (router) => router.landingURL);
const selectOriginalReferrer = createSelector(selectRouter, (router) => router.originalReferrer);

export {
    selectRouter,
    selectRouteName,
    selectRouteURL,
    selectRouteOriginalURL,
    selectRouteParams,
    selectRouteReferrer,
    selectLandingURL,
    selectOriginalReferrer,
};
