import { t } from '@lingui/macro';
import * as React from 'react';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { useSelector } from 'react-redux';
import { Button, Flex, LoadingSpinner } from 'strat/components';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import Mapbox from 'strat/map/mapbox';

import useGetGeoLocation from 'horizontal/search/location/useGetGeoLocation';
import type { ChatMessageLocationContent } from 'horizontal/chat/types';
import IconLocation from 'horizontal/assets/icons/iconMapPin.svg';
import IconCurrentLocation from 'horizontal/assets/icons/iconCurrentLocation.svg';

import fetchRootLocation from './fetchRootLocation';
import MapboxPanController from './mapboxPanController';
import styles from './styles/map.cssm';

type Props = {
    readonly onSubmit: (
        location: ChatMessageLocationContent,
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void;
};

const ShareLocationMap = ({ onSubmit }: Props) => {
    const i18n = useI18n();
    const { getGeoLocation, coordinates, loading } = useGetGeoLocation();

    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);

    const [defaultCenter, setDefaultCenter] = React.useState<any>(null);

    React.useEffect(() => {
        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        fetchRootLocation(language, backend).then((loc) => setDefaultCenter(loc.geography));
    }, [language, backend]);

    const [{ pinLocation, isMapMoving }, setMapState] = React.useState<{
        pinLocation: ChatMessageLocationContent | null | undefined;
        isMapMoving: boolean;
    }>({ pinLocation: undefined, isMapMoving: false });

    const onLocationChange = React.useCallback((center: ChatMessageLocationContent) => {
        setMapState({ pinLocation: center, isMapMoving: false });
    }, []);

    const onMoveStart = React.useCallback(() => {
        setMapState(({ pinLocation: previousPinLocation }) => ({
            pinLocation: previousPinLocation,
            isMapMoving: true,
        }));
    }, []);

    const onSubmitLocation = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (pinLocation) {
                onSubmit(pinLocation, event);
            }
        },
        [pinLocation, onSubmit],
    );

    const onGetLocation = React.useCallback(() => {
        getGeoLocation();
    }, [getGeoLocation]);

    if (!defaultCenter) {
        return <LoadingSpinner dark className={styles.loadingSpinner} />;
    }

    return (
        <Flex fillContainer className={styles.container}>
            <div className={styles.mapContainer}>
                <Mapbox defaultZoom={5} maxZoom={22} defaultCenter={defaultCenter}>
                    <Flex alignCenter justifyCenter className={styles.staticMarkerContainer}>
                        <IconLocation className={styles.staticMarker} />
                    </Flex>
                    <MapboxPanController
                        onLocationChange={onLocationChange}
                        onMoveStart={onMoveStart}
                        currentLocation={coordinates}
                        currentLocationLoading={loading}
                    />
                </Mapbox>
            </div>
            <Flex alignCenter justifyCenter className={styles.bottomContainer}>
                <div className={styles.sendButtonWrapper}>
                    <Button disabled={isMapMoving || !pinLocation} onClick={onSubmitLocation}>
                        {t(i18n)`Send location`}
                    </Button>
                </div>
            </Flex>
            <div className={styles.getLocationContainer}>
                <button
                    disabled={loading}
                    className={styles.locationButton}
                    onClick={onGetLocation}
                    aria-label={'Current location button'}
                >
                    <IconCurrentLocation className={styles.currentLocationIcon} />
                </button>
            </div>
        </Flex>
    );
};

export default ShareLocationMap;
