import React from 'react';

import { buildCanonicalURL } from 'strat/routing';

/**
 * Properties for {@see SocialImageTags}.
 */
type Props = {
    image: string;
};

const hasLinkFormat = (image: string) =>
    image.startsWith('https://') || image.startsWith('http://');

const SocialImageTags = ({ image }: Props) => {
    // the image tag content should be a link in order that
    // the metadata (open Graph protocol) to be valid
    // and the picture to be shown in sharing preview
    const imageUrl = hasLinkFormat(image)
        ? image
        : buildCanonicalURL(image, CONFIG.build.LANGUAGE_CODE);

    return (
        <>
            <meta key="og" property="og:image" content={imageUrl} />
            <meta key="twitter" name="twitter:image" content={imageUrl} />
            <meta key="schema.org" itemProp="image" content={imageUrl} />
        </>
    );
};

export default SocialImageTags;
