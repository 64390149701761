import { t, plural } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex } from 'strat/components';

import YellowWarningIcon from 'horizontal/assets/icons/yellowWarningIcon.svg';
import { useFreeLimitUsageByAd } from 'horizontal/adLimits/hooks';
import { useHumanizedPeriod } from 'horizontal/util';
import type { Ad } from 'horizontal/types';

import styles from './styles/applyLimitPackage.cssm';

type Props = {
    readonly ad: Ad | null | undefined;
};

const LimitedQuotaMessage = ({ ad }: Props) => {
    const i18n = useI18n();

    const freeLimitUsage = useFreeLimitUsageByAd(ad);

    const categoryName = freeLimitUsage?.category.name ?? '';
    const usedFreeCount = freeLimitUsage?.usedQuota;
    const freeLimitDurationDays = freeLimitUsage?.durationInDays;

    const period = useHumanizedPeriod(
        freeLimitDurationDays && freeLimitDurationDays * 24 * 60 * 60 * 1000,
    );

    const subtitle = React.useMemo(() => {
        if (!usedFreeCount || !period) {
            return null;
        }

        return t(i18n)`${plural(usedFreeCount, {
            zero: '',
            one: `You have already posted ${usedFreeCount} free ad in last ${period} for ${categoryName}`,
            two: `You have already posted ${usedFreeCount} free ads in last ${period} for ${categoryName}`,
            few: `You have already posted ${usedFreeCount} free ads in last ${period} for ${categoryName}`,
            many: `You have already posted ${usedFreeCount} free ads in last ${period} for ${categoryName}`,
            other: `You have already posted ${usedFreeCount} free ads in last ${period} for ${categoryName}`,
        })}`;
    }, [i18n, categoryName, usedFreeCount, period]);

    return (
        <Flex className={styles.titleContainer} aria-label="Limited quota message">
            <YellowWarningIcon className={styles.icon} />
            <Flex column justifyCenter className={styles.textContainer}>
                <Text.XLarge bold>{t(i18n)`Your ad was not posted`}</Text.XLarge>
                <Text.Base className={styles.infoText}>{subtitle}</Text.Base>
            </Flex>
        </Flex>
    );
};

export default LimitedQuotaMessage;
