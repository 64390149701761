import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';
import EMPTY_ARRAY from 'strat/empty/array';
import { useI18n } from 'strat/i18n/language';
import { getTimeAsFormattedDate, sameDay } from 'strat/util';

import { DialogNames } from 'horizontal/dialogs';
import type { ActionButton, ChatMessage, ChatRoom } from 'horizontal/chat/types';
import { useChatSDK, useIsActiveUserMessageSender } from 'horizontal/chat/hooks';
import { ChatMessageType, ChatRoomMemberRole } from 'horizontal/chat/constants';

import Message from './message';
import styles from './styles/messages.cssm';

type Props = {
    readonly messages: Array<ChatMessage>;
    readonly loadMore: () => Promise<void>;
    readonly isLoading: boolean;
    readonly isEndReached: boolean;
    readonly conversation: ChatRoom;
    readonly actionButtons?: Array<ActionButton>;
};

const Messages = ({ messages, conversation, actionButtons }: Props) => {
    const [, setVisible] = useDialog(DialogNames.CHAT_GALLERY_DIALOG);
    const { myRole } = conversation;
    const isLoggedInUserSeller = myRole !== ChatRoomMemberRole.BUYER;
    const sdk = useChatSDK();
    const contactReceipt = sdk.useRoomContactReceipt(conversation.xid);
    const isMessageAuthor = useIsActiveUserMessageSender({ chatRoom: conversation });

    const lastNegotiationMessageID = React.useMemo(
        () =>
            messages
                .filter((message) => !!message.content[ChatMessageType.NEGOTIATION])
                .slice(-1)[0]?.xid,
        [messages],
    );

    const openGallery = React.useCallback(
        (messageXID: string, index: number) => {
            setVisible(true, { messages, openAt: { messageXID, index } });
        },
        [messages, setVisible],
    );
    const i18n = useI18n();

    const messagesByDay = React.useMemo(
        () =>
            messages.reduce<Array<any>>(
                (sections: Array<Array<ChatMessage>>, message: ChatMessage) => {
                    if (sections.length === 0) {
                        sections.push([message]);
                        return sections;
                    }
                    const lastSection = sections[sections.length - 1];
                    const previousMessage = lastSection[lastSection.length - 1];

                    if (sameDay(message.createdAt, previousMessage.createdAt)) {
                        sections[sections.length - 1].push(message);
                        return sections;
                    }
                    sections.push([message]);
                    return sections;
                },
                [],
            ),
        [messages],
    );

    const renderMessage = React.useCallback(
        (message: ChatMessage, index: number, sectionMessages: Array<ChatMessage>) => {
            const previousMessage = index > 0 ? sectionMessages[index - 1] : null;
            const nextMessage =
                index < sectionMessages.length - 1 ? sectionMessages[index + 1] : null;
            const isMessageSender = isMessageAuthor({ message });
            const isPreviousMessageFromOtherAuthor = isMessageAuthor({ message: previousMessage });
            const isNextMessageFromOtherAuthor = isMessageAuthor({ message: nextMessage });

            return (
                <Message
                    key={message.xid}
                    message={message}
                    isSeen={message.createdAt <= contactReceipt.lastReadAt}
                    isReceived={message.createdAt <= contactReceipt.lastReceivedAt}
                    isLoggedInUserSeller={isLoggedInUserSeller}
                    isLoggedInUserAuthor={isMessageSender}
                    isPreviousMessageFromOtherAuthor={isPreviousMessageFromOtherAuthor}
                    isNextMessageFromOtherAuthor={isNextMessageFromOtherAuthor}
                    onImageClick={openGallery}
                    actionButtons={
                        lastNegotiationMessageID === message.xid
                            ? actionButtons ?? (EMPTY_ARRAY as ActionButton[])
                            : (EMPTY_ARRAY as ActionButton[])
                    }
                />
            );
        },
        [
            openGallery,
            contactReceipt,
            isLoggedInUserSeller,
            lastNegotiationMessageID,
            actionButtons,
            isMessageAuthor,
        ],
    );

    const renderMessageSection = React.useCallback(
        (sectionMessages: Array<ChatMessage>) => {
            if (!sectionMessages.length) {
                return null;
            }
            const firstMessage = sectionMessages[0];
            return (
                <Flex
                    column
                    stretchWidth
                    noShrink
                    key={firstMessage.xid}
                    className={styles.daySection}
                >
                    <Text.Base bold uppercase className={styles.date}>
                        {getTimeAsFormattedDate(firstMessage.createdAt, i18n)}
                    </Text.Base>
                    {sectionMessages.map(renderMessage)}
                </Flex>
            );
        },
        [renderMessage, i18n],
    );

    return (
        <>
            {messagesByDay.map(renderMessageSection)}

            <div>
                &nbsp;
                {/* This fixes safari's unusual collapsing margins approach.
                By default safari ignores last message bottom margin and sticks
                its container to the input area.
                By adding this div with a space safari is forced to honour it's
                "content" (empty div doesn't work ofc) and leave some room at the bottom.
              */}
            </div>
        </>
    );
};

export default React.memo<Props>(Messages);
