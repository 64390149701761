import * as React from 'react';
import settings from '@app/branding/settings';

import type { FlatCategoryFieldHierarchy } from 'horizontal/types';
import { CategoryFieldFilterType, CategoryFieldRole } from 'horizontal/types';
import {
    CategoryPriceField,
    PriceTypeField,
    VideoField,
    VirtualTourField,
    MileageField,
} from 'horizontal/fields/commonPostingFields';

import MultipleChoice from './multipleChoice';
import SingleChoice from './singleChoice';
import Range from './range';
import FilterContainer from './filterContainer';
import Hierarchical from './hierarchical';
import useFilter from './useFilter';
import useDisplayPriorityFilteredFacetedChoices from './useDisplayPriorityFilteredFacetedChoices';
import PriceFilter from './priceFilter';
import PriceTypeFilter from './priceTypeFilter';
import styles from './styles/filter.cssm';

const Filter = ({ field }: { field: FlatCategoryFieldHierarchy }) => {
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    const filter = useFilter(field);
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Primitive'.
    const choices = useDisplayPriorityFilteredFacetedChoices(field, filter.value);
    const title = field.name || field.attribute;
    if (
        (field.attribute === VirtualTourField.attribute ||
            field.attribute === VideoField.attribute) &&
        choices?.length === 1 &&
        choices[0].value == 'no'
    ) {
        return null;
    }
    if (CategoryPriceField.attribute === field.attribute) {
        return (
            <FilterContainer title={title} className={styles.container} uppercaseTitle={false}>
                <PriceFilter field={field} />
            </FilterContainer>
        );
    }
    if (PriceTypeField.attribute === field.attribute) {
        return (
            <PriceTypeFilter field={field} className={styles.container} uppercaseTitle={false} />
        );
    }

    if (field.roles.includes(CategoryFieldRole.SHOW_IN_SEARCH_HEADER)) {
        return null;
    }

    switch (field.filterType) {
        case CategoryFieldFilterType.MULTIPLE_CHOICES:
            if (!choices.length) {
                return null;
            }

            return (
                <FilterContainer title={title} className={styles.container} uppercaseTitle={false}>
                    <MultipleChoice
                        field={field}
                        visibleCount={settings.searchFiltersMaxVisibileCount}
                    />
                </FilterContainer>
            );
        case CategoryFieldFilterType.RANGE:
            return (
                <FilterContainer title={title} className={styles.container} uppercaseTitle={false}>
                    <Range
                        field={field}
                        formatInputValue={field.attribute === MileageField.attribute}
                    />
                </FilterContainer>
            );
        case CategoryFieldFilterType.SINGLE_CHOICE:
            if (!choices.length) {
                return null;
            }

            if (field.children && field.children.length) {
                return (
                    <FilterContainer
                        title={title}
                        className={styles.container}
                        uppercaseTitle={false}
                    >
                        <Hierarchical
                            field={field}
                            visibleCount={settings.searchFiltersMaxVisibileCount}
                        />
                    </FilterContainer>
                );
            }

            return (
                <FilterContainer title={title} className={styles.container} uppercaseTitle={false}>
                    <SingleChoice field={field} />
                </FilterContainer>
            );

        default:
            return null;
    }
};

export default Filter;
