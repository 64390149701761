import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/tab.cssm';

type TabProps = {
    readonly lazy?: boolean;
    readonly active?: boolean;
    readonly children: React.ReactNode;
    readonly className?: string;
    readonly fullHeight?: boolean;
};

type TabState = {
    render: boolean | null | undefined;
};

/**
 * An entry in the {@link TabView}.
 * @param children What should be rendered inside this tab.
 */
class Tab extends React.Component<TabProps, TabState> {
    constructor(props: TabProps) {
        super(props);

        this.state = {
            render: props.active,
        };
    }

    UNSAFE_componentWillReceiveProps(props: TabProps) {
        if (this.props.lazy && props.active && !this.state.render) {
            this.setState({ render: true });
        }
    }

    render() {
        if (this.props.lazy && !this.state.render) {
            return null;
        }

        return (
            <div
                className={classNames(this.props.className, {
                    [styles.fullHeight]: this.props.fullHeight,
                })}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Tab;
