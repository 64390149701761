import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { fetchCategoryFields } from 'strat/categoryFields/state';

import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';

class FavoriteAdsRoute extends Route {
    constructor() {
        super(RouteNames.FAVORITES, [['^/myfavorites']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/myfavorites' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        const {
            redux: {
                store: { dispatch },
            },
        } = context;

        context.rendering.renderPage(Page.FAVORITE_ADS, { activeTab: 0 });
        context.promise.wait(
            dispatch(
                fetchCategoryFields({
                    categoryExternalIDs: null,
                    includeChildCategories: true,
                    lite: true,
                }),
            ),
        );
    }
}

export default new FavoriteAdsRoute();
