import {
    ExactFilter,
    ExclusionFilter,
    SerializedFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SlotBorrowStrategy, Slot, Slots } from '@sector-labs/fe-search-redux/slots';
import {
    createRandomEliteSort,
    createProductFilter,
    createRandomFeaturedSort,
} from '@sector-labs/strat-horizontal-core/dist/search';
// eslint-disable-next-line no-restricted-imports
import {
    ProductType,
    Geography,
} from '@sector-labs/strat-horizontal-core/dist/search/slotting/types';
// eslint-disable-next-line no-restricted-imports
import {
    createCircleFilters,
    getCoordinatesFromFilters,
} from '@sector-labs/strat-horizontal-core/dist/search/slotting/slottingFilters';
// eslint-disable-next-line no-restricted-imports
import { SearchParams } from '@sector-labs/strat-horizontal-core/dist/search/slotting/types';
import brandingSettings from '@app/branding/settings';
import { FilterValues } from 'strat/search';

import { AdProduct, CategoryConfigurations, LiteHierarchicalLocation } from 'horizontal/types';
import getHitsPerPage from '@app/search/hits/getHitsPerPage';
import { SearchPageSearchType } from 'horizontal/search/types';

type LocalSearchParams = SearchParams & { locationRadius: string | null };

const SLOT_RADIUSES = [5, 10, 15, 30, 50, 75, 100, 250];

const makeLocationFilter = (
    location?: LiteHierarchicalLocation | null,
): SerializedFilter | null | undefined => {
    if (!location) {
        return null;
    }

    return new ExactFilter({
        attribute: 'location.externalID',
        value: location.externalID,
    }).serialize();
};

type ExpandedRadiusSlottingOptions = {
    indexName: string;
    searchParams: {
        location?: LiteHierarchicalLocation | null;
        product?: AdProduct;
    };
    categoryConfigurations: CategoryConfigurations;
};

const createAutomaticSlots = (searchParams: LocalSearchParams) => {
    const coordinates = getCoordinatesFromFilters(searchParams);
    let previousRadius = parseInt(searchParams.locationRadius || '0', 10);
    const locationExternalID = searchParams.location?.externalID;

    const excludedLocationFilter = locationExternalID
        ? (new ExclusionFilter({
              attribute: 'location.externalID',
              value: searchParams.location?.externalID,
          }).serialize() as SerializedFilter)
        : null;

    if (!coordinates) {
        return [] as Slot[];
    }

    const slots: Slot[] = [];

    SLOT_RADIUSES.filter((radius) => radius > previousRadius).forEach((radius) => {
        const circles = createCircleFilters(coordinates as Geography, radius, previousRadius);

        slots.push({
            filters: [...circles, excludedLocationFilter].filter(Boolean) as SerializedFilter[],
            counted: false,
        });

        previousRadius = radius;
    });

    return slots;
};

const createSlottingConfig = ({
    indexName,
    categoryConfigurations,
    searchParams,
}: ExpandedRadiusSlottingOptions) => {
    const product = searchParams?.product;
    const fallbackSlots = createAutomaticSlots(searchParams as LocalSearchParams);

    const eliteAdsSlot = {
        filters: [
            createProductFilter(ProductType.ELITE),
            makeLocationFilter(searchParams?.location),
        ].filter(Boolean) as SerializedFilter[],
        counted: true,
        sort: createRandomEliteSort(),
    };

    const featuredAdsSlot = {
        filters: [
            createProductFilter(ProductType.FEATURED),
            makeLocationFilter(searchParams?.location),
        ].filter(Boolean) as SerializedFilter[],
        counted: true,
        sort: createRandomFeaturedSort(),
    };

    const normalAdsSlot = {
        filters: [
            new ExclusionFilter({
                attribute: FilterValues.product.attribute,
                value: ProductType.ELITE,
                key: 'product-elite',
            }).serialize(),
            makeLocationFilter(searchParams?.location),
        ].filter(Boolean) as SerializedFilter[],
        counted: true,
    };

    const { eliteAdsGroupSize } = categoryConfigurations;

    const eliteAdsPerPageCount =
        product === AdProduct.ELITE
            ? getHitsPerPage(SearchPageSearchType.FEATURED_REGULAR_GROUPED) -
              Math.max(brandingSettings.eliteAdsPerPageCount, 0)
            : eliteAdsGroupSize ?? Math.max(brandingSettings.eliteAdsPerPageCount, 0);

    return {
        activeFor: [indexName],
        default: {
            hitsPerSlot: (page: number) =>
                page === 1
                    ? [
                          eliteAdsPerPageCount,
                          getHitsPerPage(SearchPageSearchType.FEATURED_REGULAR_GROUPED) -
                              eliteAdsPerPageCount,
                          0,
                          ...fallbackSlots.map((_) => 0),
                      ]
                    : [
                          eliteAdsPerPageCount,
                          Slots.DISABLED,
                          getHitsPerPage(SearchPageSearchType.FEATURED_REGULAR_GROUPED) -
                              eliteAdsPerPageCount,
                          ...fallbackSlots.map((_) => 0),
                      ],
            slots: [eliteAdsSlot, featuredAdsSlot, normalAdsSlot, ...fallbackSlots],
            borrowStrategy: SlotBorrowStrategy.FROM_FOLLOWING,
        },
    };
};

export default createSlottingConfig;
