import * as React from 'react';
import { useSelector } from 'react-redux';
import { PageWidthWrapper } from 'strat/generic';
import settings from '@app/branding/settings';

import { selectCategories } from 'horizontal/categories';
import { sortByDisplayPriority } from 'horizontal/util';

import styles from './styles/categoryDropdownSwitcher.cssm';
import TopSubcategories from './topSubcategories';
import SplitCategoriesDropdown from './splitCategoriesDropdown/splitCategoriesDropdown';
import AllCategoriesDropdown from './allCategoriesDropdown';

const CategoryDropdownSwitcher = () => {
    const categories = useSelector(selectCategories);

    const orderedCategories = React.useMemo(() => {
        return categories.sort(sortByDisplayPriority);
    }, [categories]);

    return (
        <div className={styles.categorySwitcher}>
            <PageWidthWrapper className={styles.pageWidthWrapper}>
                {settings.splitCategoriesDropdown ? (
                    <SplitCategoriesDropdown categories={orderedCategories} />
                ) : (
                    <AllCategoriesDropdown categories={orderedCategories} />
                )}
                <TopSubcategories categories={categories} />
            </PageWidthWrapper>
        </div>
    );
};

export default CategoryDropdownSwitcher;
