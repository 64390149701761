import React from 'react';
import type { FormikValues } from 'formik';

import { DeliveryType } from 'horizontal/types';

import { AdDeliveryFields } from './commonPostingFields';

type Props = {
    setFieldValue: (field: string | number, value: any) => void;
    readonly isEdit?: boolean;
    values: FormikValues & { delivery_type?: string };
    readonly serviceDeliveryError: string | null;
    readonly selfDeliveryError: string | null;
};

const useUpdateMultiDeliveryFields = ({
    setFieldValue,
    isEdit,
    values,
    serviceDeliveryError,
    selfDeliveryError,
}: Props) => {
    const [serviceDeliverySelected, setServiceDeliverySelected] = React.useState(false);
    const [selfDeliverySelected, setSelfDeliverySelected] = React.useState(false);

    const updateSelections = React.useCallback(
        (value: string) => {
            const isServiceDeliverySelected =
                value === DeliveryType.SERVICE_DELIVERY && !serviceDeliveryError;
            const isSelfDeliverySelected =
                value === DeliveryType.SELF_DELIVERY && !selfDeliveryError;

            setServiceDeliverySelected(isServiceDeliverySelected);
            setSelfDeliverySelected(isSelfDeliverySelected);
        },
        [serviceDeliveryError, selfDeliveryError],
    );

    const handleSelection = (value: string) => {
        setFieldValue(AdDeliveryFields.delivery_type.attribute, value);
        updateSelections(value);
    };

    React.useEffect(() => {
        if (isEdit && values.delivery_type) {
            updateSelections(values.delivery_type);
        }
    }, [isEdit, values.delivery_type, updateSelections]);

    React.useEffect(() => {
        if (values.delivery_type) {
            updateSelections(values.delivery_type);
        }
    }, [serviceDeliveryError, selfDeliveryError, values.delivery_type, updateSelections]);

    return {
        serviceDeliverySelected,
        selfDeliverySelected,
        handleSelection,
    };
};

export default useUpdateMultiDeliveryFields;
