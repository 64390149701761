import qs from 'qs';
import transform from 'lodash/transform';

import matchPatterns from './regex';
import Route from './route';
import RouteMatch from './routeMatch';
import { parseLocation } from './location';
import type { CapturedGroups } from './regex';

type RouteMatchArray = Array<RouteMatch>;

/**
 * A routing configuration.
 */
class RouteConfig {
    /**
     * List of available routes, ordering matters.
     */
    routes: Array<Route>;

    /**
     * Initializes a new instance of {@see RouteConfig}.
     * @param routes List of available routes, ordering matters.
     */
    constructor(routes: Array<Route>) {
        // filter out null/undefined routes, so we can pass routes conditionally
        this.routes = routes.filter((route) => route);
    }

    /**
     * Find a route by the specified name.
     * @param name The name of the route to get.
     * @returns The route with the specified name or null if no
     * route with the specified name was found.
     */
    getRoute(name: string): null | Route {
        const foundRoute: Route | null | undefined = this.routes.find(
            (route: Route): boolean => route.name === name,
        );

        return foundRoute || null;
    }

    /**
     * Attempts to match the specified URL against one of the
     * configured routes.
     * @param url The URL to match against one of the configured routes.
     * @returns A {@see RouteMatch} object describing how the specified
     * URL was matched against a route, or null if the URL matched no routes.
     */
    match(url: string): null | RouteMatch {
        const decodedURL = decodeURI(url);
        const matchedRoutes: RouteMatchArray = transform(
            this.routes,
            (result: RouteMatchArray, route: Route) => {
                const match = RouteConfig.doesRouteMatch(decodedURL, route);
                if (match) {
                    result.push(match);
                    return false; // found, please stop
                }
                return true; // not found, continue
            },
        );

        return matchedRoutes[0] || null;
    }

    /**
     * Attempts to match the specified URL against the specified route.
     * @param url The URL to match against the specified route.
     * @param route The route to match against the specified URL.
     * @returns A {@see RouteMatch} object describing how the specified
     * URL was matched against the specified route or null if the URL
     * did not match the specified route.
     */
    static doesRouteMatch(url: string, route: Route): null | RouteMatch {
        const groups: null | CapturedGroups = matchPatterns(url, route.patterns);
        if (!groups) {
            return null;
        }

        let queryParams: Record<string, any> = {};
        const queryString = parseLocation(url).search;
        if (queryString) {
            queryParams = qs.parse(queryString);
        }

        return new RouteMatch(route, url, { ...groups, ...queryParams });
    }
}

export default RouteConfig;
