// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import { selectSearchSettings } from '@sector-labs/fe-search-redux/state';
import { createSearchSlottingConfig } from '@sector-labs/strat-horizontal-core';
import brandingSettings from '@app/branding/settings';
import Category from '@app/branding/category';
import { selectLanguage } from 'strat/i18n/language';

import { createRandomSeed, locationByPriority } from 'horizontal/search/execution';
import { SearchPageSearchType } from 'horizontal/search/types';
import { selectIsBTTFeaturedEnabledForCategory } from 'horizontal/categories';

import getAdsSearchIndex from '../getAdsSearchIndex';
import type { SearchProps } from '../types';
import getHitsPerPage from '../getHitsPerPage';
import createAdsSearchFacets from '../common/createAdsSearchFacets';

const createAdsSearchSettings = ({
    getState,
    searchRouteParams,
    // @ts-expect-error - TS2339 - Property 'featuredSort' does not exist on type 'HorizontalSettings'.
    featuredSort = brandingSettings.featuredSort,
    categoryConfigurations,
}: SearchProps): SearchSettings => {
    const state = getState();
    const language = selectLanguage(state);
    const facets = createAdsSearchFacets({ state, searchRouteParams, withProductFacet: true });
    const indexName = getAdsSearchIndex(language);

    const location = searchRouteParams.location || brandingSettings.topLevelLocation;
    const userLocation = state.user.geoLocation;
    // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData | Location' is not assignable to parameter of type 'Location'.
    const [priorityLocation, coordinates] = locationByPriority(userLocation, location);

    const fetchNearbyFeaturedAds = !Category.isOfPropertySubtype(searchRouteParams.category);
    const oldSettings = selectSearchSettings(state);

    const isEnabledBTTForFeaturedAd = selectIsBTTFeaturedEnabledForCategory(
        state,
        searchRouteParams?.category?.externalID,
    );
    const featuredAdsSorting = isEnabledBTTForFeaturedAd ? featuredSort : undefined;

    const { featuredAdsGroupSize, eliteAdsGroupSize } = categoryConfigurations;

    return {
        hitsPerPage: getHitsPerPage(SearchPageSearchType.CIRCLE_BASED_SLOTTING),
        maxValuesPerFacet: 20,
        activeSlotRule: 'default',
        sort: searchRouteParams.sortOption,
        // Reset the random seed when on the first page. Like this,
        // the seed stays stable when navigating between pages.
        randomSeed: !searchRouteParams.page ? createRandomSeed() : oldSettings.randomSeed,
        slotRules: createSearchSlottingConfig({
            indexName,
            hitsPerSlot: [
                eliteAdsGroupSize ?? brandingSettings.eliteAdsPerPageCount,
                featuredAdsGroupSize ??
                    brandingSettings.featuredAdsGroupSizes(
                        getHitsPerPage(SearchPageSearchType.CIRCLE_BASED_SLOTTING),
                    ).default,
                14,
            ],
            cityLevel: CONFIG.build.CITY_LEVEL,
            searchParams: {
                // @ts-expect-error - TS2322 - Type 'import("horizontal/horizontal/types").Location | null | undefined' is not assignable to type 'import("packages/strat-horizontal-core/dist/search/slotting/types").Location | null | undefined'.
                location: priorityLocation,
                closestLocationCoordinates: coordinates,
                sortOption: searchRouteParams.sortOption,
            },
            // @ts-expect-error - TS2322 - Type 'SearchRequestSortOption | null | undefined' is not assignable to type 'SearchRequestSortOption | undefined'.
            featuredSort: featuredAdsSorting,
            fetchNearbyFeaturedAds,
        }),
        facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING ? facets : [],
        // Do not count more than 200k hits. If there are more,
        // we'll display '200k+ hits'. Setting an upper limit
        // prevents ES from endlessly iterating the hits just
        // to get an exhaustive count.
        exhaustiveNbHits: CONFIG.runtime.ADS_SEARCH_EXHAUSTIVE_NB_HITS || 200000,
        maxPageAllowed: 50,
    };
};

export default createAdsSearchSettings;
