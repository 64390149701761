import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { Ad } from 'horizontal/types';

const useNavigateToAdDetails = (ad?: Ad | null, trackAction?: (ad?: Ad | null) => void) => {
    const router = useRouter();

    return React.useCallback(() => {
        if (!ad) {
            return;
        }
        if (trackAction) {
            trackAction(ad);
        }
        router.pushRoute(RouteNames.AD_DETAILS, { slug: ad.slug, externalID: ad.externalID });
    }, [router, ad, trackAction]);
};

export default useNavigateToAdDetails;
