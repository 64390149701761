import { useSuggestedCompletions } from '@sector-labs/fe-search-redux/suggestion';
import { RangeFilter } from '@sector-labs/fe-search-redux/filters';
import brandingSettings from '@app/branding/settings';
import isNil from 'lodash/isNil';
import { SearchResponseHit } from '@sector-labs/fe-search-redux/backend/searchResponse';
import { Filter } from '@sector-labs/fe-search-redux/filters';

import { determineLocationsIndexName } from 'strat/search/indexNames';
import { useI18n } from 'strat/i18n/language';

const createCompletionOptions = (
    indexName: string,
    maxLevel?: number,
    minimumQueryLength?: number,
    attributesToHighlight?: string[],
    filters: Array<Filter> = [],
) => {
    const searchFilters = [...filters];

    if (maxLevel) {
        searchFilters.push(
            new RangeFilter({
                attribute: 'level',
                value: { max: maxLevel },
            }),
        );
    }

    return {
        indexName,
        fuzzy: brandingSettings.locationSearchFuzzySettings || false,
        searchOptions: {
            hitsPerPage: 20,
            attributesToHighlight: attributesToHighlight || ['name', 'hierarchy.name'],
        },
        filters: searchFilters.length ? searchFilters : undefined,
        ...(!isNil(minimumQueryLength) ? { minimumQueryLength } : {}),
    };
};

const useLocationSuggestions = (
    query: string,
    {
        maxLevel,
        minimumQueryLength,
        attributesToHighlight,
        filters,
    }: {
        maxLevel?: number;
        minimumQueryLength?: number;
        attributesToHighlight?: string[];
        filters?: Array<Filter>;
    } = {},
) => {
    const { locale: language } = useI18n();
    const indexName = determineLocationsIndexName({ language });

    return useSuggestedCompletions(
        query,
        createCompletionOptions(
            indexName,
            maxLevel,
            minimumQueryLength,
            attributesToHighlight,
            filters,
        ),
    ) as SearchResponseHit[];
};

export default useLocationSuggestions;
