import * as React from 'react';
import { RouteNames } from 'strat/routes';

import { useSellerProfile } from 'horizontal/seller/hooks';
import { useAdIsLoading, useAd } from 'horizontal/ad/state';
import { useAdAgency } from 'horizontal/ad';
import { Seller } from 'horizontal/adDetails/components';

// Business, Companies & Agency terms are same thing, they are used interchangeably
const SellerCardSwitcher = () => {
    const seller = useSellerProfile();
    const adAgency = useAdAgency(useAd());
    const loading = useAdIsLoading();

    if (!seller) {
        return null;
    }

    if (adAgency) {
        const {
            description,
            createdAt,
            updatedAt,
            allowChatCommunicationInAds,
            isPhoneNumberVisible,
            phoneNumber,
            externalID,
        } = seller;
        const {
            id,
            externalID: agencyExternalID,
            name,
            logo,
            commercialNumber,
            slug,
            shortNumber,
        } = adAgency;
        // agency has a combination of data that comes from Seller API & Agency API
        const agency = {
            createdAt,
            updatedAt,
            description,
            allowChatCommunicationInAds,
            isPhoneNumberVisible,
            phoneNumber,
            image: logo,
            name,
            externalID: agencyExternalID,
            agentExternalID: externalID,
            id,
            commercialNumber,
            slug,
            shortNumber,
        } as const;

        return <Seller loading={loading} route={RouteNames.AGENCY_PROFILE} seller={agency} />;
    }

    return <Seller loading={loading} route={RouteNames.SELLER_PROFILE} seller={seller} />;
};

export default SellerCardSwitcher;
