import { PermissionTypes } from '../types';

/**
 * Available notifications actions.
 */
const Actions = Object.freeze({
    POSTPONE_PROMPT: 'NOTIFICATIONS/POSTPONE_PROMPT',
    BLOCK_NOTIFICATIONS_SCRIPT: 'NOTIFICATIONS/BLOCK_NOTIFICATIONS_SCRIPT',
    CHANGE_PERMISSION: 'NOTIFICATIONS/CHANGE_PERMISSION',
    SET_SUPPORTED: 'NOTIFICATIONS/SET_SUPPORTED',
    SET_IS_USING_FALLBACK_NOTIFICATIONS: 'NOTIFICATIONS/SET_IS_USING_FALLBACK_NOTIFICATIONS',
    CLOSE_SLIDE_IN: 'NOTIFICATIONS/CLOSE_SLIDE_IN',
    LOAD_NOTIFICATIONS_SCRIPT: 'NOTIFICATIONS/LOAD_NOTIFICATIONS_SCRIPT',
    SET_PROMPT_VISIBLE: 'NOTIFICATIONS/SET_PROMPT_VISIBLE',
    SET_NOTIFICATIONS: 'NOTIFICATIONS/SET_NOTIFICATIONS',
    SET_NOTIFICATIONS_BACKOFF: 'NOTIFICATIONS/SET_NOTIFICATIONS_BACKOFF',
});

export type PostponePromptAction = {
    type: 'NOTIFICATIONS/POSTPONE_PROMPT';
    postponed: boolean;
};

export type BlockNotificationsScriptAction = {
    type: 'NOTIFICATIONS/BLOCK_NOTIFICATIONS_SCRIPT';
};

export type LoadNotificationsScriptAction = {
    type: 'NOTIFICATIONS/LOAD_NOTIFICATIONS_SCRIPT';
};

export type ChangePermissionAction = {
    type: 'NOTIFICATIONS/CHANGE_PERMISSION';
    permission: Values<typeof PermissionTypes>;
};

export type SetSupportedAction = {
    type: 'NOTIFICATIONS/SET_SUPPORTED';
    supported: boolean;
};

export type SetIsUsingFallbackNotifications = {
    type: 'NOTIFICATIONS/SET_IS_USING_FALLBACK_NOTIFICATIONS';
    isUsingFallbackNotifications: boolean;
};

export type CloseNotificationsSlideInAction = {
    type: 'NOTIFICATIONS/CLOSE_SLIDE_IN';
};

export type SetPromptVisible = {
    type: 'NOTIFICATIONS/SET_PROMPT_VISIBLE';
    prompt: string;
    visible: boolean;
};

export type SetNotificationsBackoff = {
    type: 'NOTIFICATIONS/SET_NOTIFICATIONS_BACKOFF';
    backOffUntil: Date;
};

export type NotificationsAction =
    | PostponePromptAction
    | BlockNotificationsScriptAction
    | ChangePermissionAction
    | SetSupportedAction
    | SetIsUsingFallbackNotifications
    | CloseNotificationsSlideInAction
    | LoadNotificationsScriptAction
    | SetPromptVisible
    | SetNotificationsBackoff;

export default Actions;
