import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text, Checkbox } from 'strat/components';
import type { I18n } from '@lingui/core';
import classNames from 'classnames';

import type { AppDispatch } from 'horizontal/state';
import { useCategoryVars } from 'horizontal/gtm';
import { toggleBusinessPackageOffer } from 'horizontal/businessPackages/state';
import { selectBusinessPackageCartOfferIDs } from 'horizontal/businessPackages/selectors';
import { trackSelectPackage } from 'horizontal/gtm';
import { ProductType } from 'horizontal/types';
import type { AdProductTypeValues, ProductPackageOffer } from 'horizontal/types';

import useFormattedPackagesPrice from '../useFormattedPackagesPrice';

import styles from './styles/packageOfferCard.cssm';

const round = (discount: number): number => {
    if (discount < 1) {
        return Math.ceil(discount);
    }
    if (discount > 99) {
        return Math.floor(discount);
    }
    return Math.round(discount);
};

const getCardTitle = (i18n: I18n, type: AdProductTypeValues) => {
    if (CONFIG.build.STRAT_ENABLE_FEATURED_BTT && type === ProductType.REFRESH) {
        return t(i18n)`Credits`;
    } else if (type === ProductType.FEATURED) {
        return t(i18n)`Featured`;
    } else if (type === ProductType.AUTO_REFRESH_AD) {
        return t(i18n)`Pack of`;
    } else if (type === ProductType.ELITE) {
        return t(i18n)`Elite`;
    } else {
        return t(i18n)`Ads`;
    }
};

const PackageOfferCard = ({ offer }: { offer: ProductPackageOffer }) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    const { categoryID } = offer;
    const categoryVars = useCategoryVars(categoryID);
    const discountPercentage = round(
        ((offer.price - (offer.discountPrice || 0)) * 100) / offer.price,
    ).toString();
    const businessPackageCartOfferIDs = useSelector(selectBusinessPackageCartOfferIDs);

    const [checked, setChecked] = React.useState(
        businessPackageCartOfferIDs.includes(offer.packageOfferID),
    );

    React.useEffect(() => {
        if (businessPackageCartOfferIDs.includes(offer.packageOfferID)) {
            setChecked(true);
        }
    }, [businessPackageCartOfferIDs, offer.packageOfferID]);

    const toggleSelected = React.useCallback(() => {
        if (!checked) {
            trackSelectPackage({ selectedPackage: offer, categoryVars });
        }
        setChecked(!checked);
        dispatch(toggleBusinessPackageOffer(offer));
    }, [checked, setChecked, dispatch, offer, categoryVars]);
    const formattedPrice = useFormattedPackagesPrice(offer.price);
    const formattedDiscountPrice = useFormattedPackagesPrice(offer.discountPrice || 0);
    const productType =
        offer.productPackage.productItems.length > 1
            ? ProductType.LIMIT_BUMP
            : offer.productPackage.productItems[0].type;
    const cardTitle = getCardTitle(i18n, productType);

    const quantity = React.useMemo(
        () =>
            offer.productPackage.productItems.some(
                (item) => item.type === ProductType.AUTO_REFRESH_CATEGORY,
            )
                ? t(i18n)`All Ads`
                : offer.productPackage.productItems[0].type === ProductType.AUTO_REFRESH_AD
                  ? t(i18n)`${cardTitle} ${offer.productPackage.productsQuantity || 0}`
                  : t(i18n)`${offer.productPackage.productsQuantity || 0} ${cardTitle}`,
        [offer, i18n, cardTitle],
    );
    return (
        <Flex
            className={classNames(styles.container, {
                [styles.checked]: checked,
            })}
            column
            onClick={toggleSelected}
            aria-label="Package offer"
        >
            <Flex className={styles.header} justifySpaceBetween>
                <Flex>
                    <Checkbox checked={checked} checkboxClassname={styles.checkbox} />
                </Flex>
                {offer.discountPrice && (
                    <Text.Base bold className={styles.discountPercentage}>
                        {`-${discountPercentage}%`}
                    </Text.Base>
                )}
            </Flex>
            <Flex justifyCenter alignCenter column fillContainer className={styles.priceContainer}>
                <Flex alignCenter justifyCenter className={styles.quantityTextContainer}>
                    <Text.Regular bold>{quantity}</Text.Regular>
                </Flex>
                <Text.Regular bold aria-label="Discount price" className={styles.discountPrice}>
                    {offer.discountPrice ? formattedDiscountPrice : formattedPrice}
                </Text.Regular>
                <Text.Small
                    aria-label="Actual price"
                    className={styles.strikeoffPrice}
                    strikethrough
                >
                    {offer.discountPrice ? formattedPrice : null}
                </Text.Small>
            </Flex>
        </Flex>
    );
};

export default PackageOfferCard;
