import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import IconNoAddressImage from 'horizontal/assets/icons/iconNoAddress.svg';

import styles from './styles/emptyAddressBook.cssm';

const EmptyAddressBook = () => {
    return (
        <Flex column justifyCenter alignCenter className={styles.noAddress}>
            <IconNoAddressImage />
            <Text.Base bold className={styles.noAddressTitle}>
                <Trans>You did not add any address yet</Trans>
            </Text.Base>
        </Flex>
    );
};

export default EmptyAddressBook;
