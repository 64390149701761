import * as React from 'react';

import fallbackProfilePicture from 'horizontal/assets/icons/iconProfilePicture.png';

type Props = {
    className: string;
    userDisplayName?: string;
    label?: string;
    fixedSize?: boolean;
};

const DefaultProfilePicture = ({ className, userDisplayName, label }: Props) => {
    return (
        <img
            src={fallbackProfilePicture}
            alt={userDisplayName}
            className={className}
            aria-label={label}
        />
    );
};

export default DefaultProfilePicture;
