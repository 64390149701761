import * as React from 'react';

import { useNavigateToRefinedSearch } from 'horizontal/search/routing';

type UseRefineLocationFilter = (arg1: number) => void;

const useRefineLocationRadiusFilter = (): UseRefineLocationFilter => {
    const navigateToSearch = useNavigateToRefinedSearch();

    return React.useCallback(
        (radius: number) => navigateToSearch({ locationRadius: radius }),
        [navigateToSearch],
    );
};

export default useRefineLocationRadiusFilter;
