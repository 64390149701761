import { ContextfulError } from 'strat/error/context';

class Stylesheet {
    /**
     * Starts the loading of a given css file.
     *
     * @param {*} url The URL to load the CSS file from.
     */
    static initiateStyleLoading(url: string): Promise<any> {
        const promise = new Promise(
            (
                resolve: (result: Promise<undefined> | undefined) => void,
                reject: (error?: any) => void,
            ) => {
                const sheet = document.createElement('link');
                sheet.rel = 'stylesheet';
                if (CONFIG.build.DANGEROUSLY_ENABLE_BROWSER_MEMORY_DEBUGGING) {
                    sheet.crossOrigin = 'anonymous';
                }
                sheet.href = url;
                sheet.type = 'text/css';
                sheet.onerror = (event) => {
                    reject(
                        new ContextfulError(
                            `Failed to load stylesheet from JS using Strat's Stylesheet.load`,
                            { url, message: (event as ErrorEvent)?.message, cause: event },
                        ),
                    );
                };

                window.sheetStatus[url] = {
                    loaded: false,
                };

                sheet.onload = () => {
                    window.sheetStatus[url].loaded = true;
                    // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
                    resolve();
                };

                if (document.head) {
                    document.head.appendChild(sheet);
                }
            },
        );

        window.sheetStatus[url].promise = promise;
        return promise;
    }

    static load(url: string): Promise<any> {
        if (!window.sheetStatus) {
            window.sheetStatus = {};
        }

        const sheetStatus = window.sheetStatus[url];
        if (!sheetStatus) {
            return Stylesheet.initiateStyleLoading(url);
        }

        if (sheetStatus.loaded) {
            return Promise.resolve();
        }

        // @ts-expect-error - TS2322 - Type 'Promise<unknown> | undefined' is not assignable to type 'Promise<any>'.
        return sheetStatus.promise;
    }

    static remove(url: string): void {
        const oldStyling = document.querySelector(`link[href="${url}"]`);
        oldStyling?.remove();
        delete window.sheetStatus?.[url];
    }
}

export default Stylesheet;
