import { createSelector } from 'reselect';

import {
    selectVisitorID,
    selectDeviceID,
    selectUserEmail,
    selectUserHash,
} from 'strat/user/selectors';
import EMPTY_OBJECT from 'strat/empty/object';
import Category from 'strat/branding/category';
import { GlobalState } from 'strat/state';
import { RootLocationNodeExternalID } from '@app/branding/dldRootLocationNode';

import { DLD_CONFIDENCE_LEVEL_THRESHOLD, PropertyCompletionStatus } from './types';
import type { PropertyData } from './types';

const selectProperty = (state: GlobalState) => (state.property || EMPTY_OBJECT).data;

const selectPropertyLoaded = (state: GlobalState) => (state.property || EMPTY_OBJECT).loaded;

const selectPropertyPreloaded = (state: GlobalState) => (state.property || EMPTY_OBJECT).preloaded;

const getRelevantPropertyAttributes = (property: PropertyData) => ({
    externalID: property.externalID,
    location: property.location,
    category: property.category,
    id: property.id,
    area: property.area,
    price: property.price,
    rooms: property.rooms,
    baths: property.baths,
    rentFrequency: property.rentFrequency,
    geography: property.geography,
    purpose: property.purpose,
});

const selectPropertyInteractionParams = createSelector(
    [selectVisitorID, selectDeviceID, selectUserEmail, selectProperty, selectUserHash],
    (visitorID, deviceID, userEmail, property, userHash) => ({
        clientID: visitorID,
        userEmail,
        sessionID: deviceID,
        userHash,
        ...getRelevantPropertyAttributes(property),
    }),
);

const selectPropertyPrice = createSelector(selectProperty, (property) => property.price);

const selectPropertyCategory = createSelector(selectProperty, (property) => property.category);

const selectExternalID = createSelector(selectProperty, (property) => property.externalID);

const selectPropertyCoverPhoto = createSelector(
    selectProperty,
    (property) => (property || EMPTY_OBJECT).coverPhoto,
);

const selectPropertyLinks = createSelector(
    selectProperty,
    (property) => (property || EMPTY_OBJECT).links,
);

const selectPropertyLocation = createSelector(
    selectProperty,
    (property) => (property || EMPTY_OBJECT).location,
);

const selectPropertyPurpose = createSelector(
    selectProperty,
    (property) => (property || EMPTY_OBJECT).purpose,
);

const selectPropertyDescription = createSelector(
    selectProperty,
    (property: PropertyData) => property.description,
);

const selectIsPropertyDescriptionTranslated = createSelector(
    selectProperty,
    (property: PropertyData) => property.descriptionTranslated,
);

const selectPropertyMainDescription = createSelector(
    selectProperty,
    (property: PropertyData) => property.mainDescription,
);

/**
    PopularityTrends section
 */
const selectPopularityTrends = (state: GlobalState) => state.popularityTrends || EMPTY_OBJECT;

const selectPopularityTrendsData = createSelector(
    selectPopularityTrends,
    (popularityTrends) => popularityTrends.data || EMPTY_OBJECT,
);

const selectTrendsLoading = createSelector(
    selectPopularityTrends,
    (popularityTrends) => popularityTrends.loading,
);

const selectPopularityTrendsTitle = createSelector(
    selectPopularityTrendsData,
    (popularityTrends) => popularityTrends.title,
);

const selectPopularityTrendsHistory = createSelector(
    selectPopularityTrendsData,
    (popularityTrends) => popularityTrends.history || EMPTY_OBJECT,
);

const selectPopularityTrendsTrends = createSelector(
    selectPopularityTrendsData,
    (popularityTrends) => popularityTrends.trends || EMPTY_OBJECT,
);

/**
 * Bayut DLD Property Information Section
 */

const selectPropertyInformationData = (state: any) => (state.propertyInformation || {}).data;

const selectPropertyInformationLoaded = (state: any) => (state.propertyInformation || {}).loaded;

const selectDLDConfidenceLevel = createSelector(
    selectPropertyInformationData,
    (data) => data?.weightedConfidenceLevel || 0,
);

const selectDLDIsMappedNova = createSelector(
    selectPropertyInformationData,
    (data) => data?.isMappedNova || 0,
);

const selectShouldShowDLDData = createSelector(
    selectDLDConfidenceLevel,
    selectDLDIsMappedNova,
    (confidenceLevel, isMappedNova) =>
        confidenceLevel >= DLD_CONFIDENCE_LEVEL_THRESHOLD || isMappedNova === 1,
);

const selectDLDBuildingNk = createSelector(
    selectProperty,
    (property) => property?.extraFields?.dldBuildingNK,
);

/**
 * We currently don't want to show any building info for
 * `villas`, `townhouses` and `villa-compound`.
 */
const CATEGORIES_WITHOUT_BUILDING_INFORMATION = [
    Category.VILLAS,
    Category.TOWNHOUSES,
    Category.VILLA_COMPOUND,
];
const selectShouldShowBuildingInformation = createSelector(
    selectProperty,
    (property: PropertyData) => {
        const propertyCategory = property?.category?.slice(-1)[0];
        const shouldHide = CATEGORIES_WITHOUT_BUILDING_INFORMATION.some((category) =>
            Category.isOfType(propertyCategory, category),
        );

        return !shouldHide;
    },
);

/**
 * Transaction information is shown only if Property Information is shown, there is transaction history
 * and DLD data should be shown
 */
const selectIsLocatedInDubai = createSelector(
    selectProperty,
    (property) =>
        property.location.length >= 2 &&
        property.location[1].externalID === RootLocationNodeExternalID,
);

const selectShouldShowSimilarTransactions = createSelector(
    selectIsLocatedInDubai,
    (isLocatedInDubai: boolean) =>
        CONFIG.runtime.BAYUT_ENABLE_SIMILAR_TRANSACTIONS_WIDGET_DPV && isLocatedInDubai,
);

const selectShouldShowProjectInformation = createSelector(
    selectProperty,
    (property) => property.completionStatus === PropertyCompletionStatus.OFF_PLAN,
);

export {
    selectProperty,
    selectPropertyLoaded,
    selectPropertyPreloaded,
    selectPropertyInteractionParams,
    selectPropertyDescription,
    selectIsPropertyDescriptionTranslated,
    selectPropertyMainDescription,
    getRelevantPropertyAttributes,
    selectPropertyPrice,
    selectPropertyCategory,
    selectExternalID,
    selectPropertyCoverPhoto,
    selectPropertyLinks,
    selectPropertyLocation,
    selectPropertyPurpose,
    selectPopularityTrends,
    selectTrendsLoading,
    selectPopularityTrendsData,
    selectPopularityTrendsHistory,
    selectPopularityTrendsTrends,
    selectPopularityTrendsTitle,
    selectPropertyInformationData,
    selectPropertyInformationLoaded,
    selectDLDConfidenceLevel,
    selectShouldShowSimilarTransactions,
    selectShouldShowDLDData,
    CATEGORIES_WITHOUT_BUILDING_INFORMATION,
    selectShouldShowBuildingInformation,
    selectDLDBuildingNk,
    selectShouldShowProjectInformation,
};
