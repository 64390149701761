import { connect } from 'react-redux';
import settings from '@app/branding/settings';
import { statelessUrlProcessor } from '@app/branding/urlProcessor';

import canonicalUrlLanguagePrefix from '@app/branding/canonicalUrlLanguagePrefix';
import type { PageInfo } from 'strat/seo/pageInfo/state';

import canonicalDomain from './canonicalDomain';

/**
 * Properties for a component wrapped by {@see withCanonicalURL}.
 */
export type CanonicalURLProps = {
    canonicalURL: (path: string) => string;
};

export type CanonicalURL = {
    alternateURL: string;
    language: string;
};

/**
 * Type returned by {@see buildAllCanonicalURLs}.
 */
export type AllCanonicalURLs = {
    url: string;
    alternateURLs: CanonicalURL[];
};

/**
 * Builds a canonical URL with the specified path.
 * @param path The path (+ query string parameters) to include into the resulting URL.
 * @param language The current active language.
 * @returns A fully qualified canonical URL.
 */
const buildCanonicalURL = (path: string, language: string): string => {
    const { domain, protocol, port } = canonicalDomain();
    const languagePrefix = canonicalUrlLanguagePrefix(language, path);
    const processedPath = statelessUrlProcessor.outbound(path || '');
    return `${protocol}://${domain}${port}${languagePrefix}${processedPath}`;
};

/**
 * Builds a canonical URL with the specified path and a list of canonical URLs for all languages.
 * @param path The path (+ query string parameters) to include into the resulting URL.
 * @param language The current active language.
 * @param pageInfo Optional overrides for page SEO-related information.
 * @returns A fully qualified canonical URL and a list of other languages canonical URLs.
 */
const buildAllCanonicalURLs = (
    path: string,
    language: string,
    pageInfo?: PageInfo,
): AllCanonicalURLs => {
    const url = (pageInfo || {}).canonicalURL || buildCanonicalURL(path, language);

    const languages = settings.languages.map(({ lang }) => lang);
    const alternateURLs = languages.map((lang) => ({
        alternateURL: buildCanonicalURL(path, lang),
        language: lang,
    }));

    return { url, alternateURLs };
};

/**
 * Builds a canonical URL with the specified path and a list of canonical URLs for all languages.
 * @param path The path (+ query string parameters) to include into the resulting URL.
 * @param language The current active language.
 * @returns A fully qualified canonical URL and a list of other languages canonical URLs.
 */
const localizedAllCanonicalURL = (
    createURL: (arg1: Record<any, any>) => string,
    params: Record<any, any>,
    language: string,
): AllCanonicalURLs => {
    const url = buildCanonicalURL(createURL({ ...params, language }), language);

    const languages = settings.languages.map(({ lang }) => lang);
    const alternateURLs = languages.map((lang) => ({
        alternateURL: buildCanonicalURL(createURL({ ...params, language: lang }), lang),
        language: lang,
    }));

    return { url, alternateURLs };
};

/**
 * Provides the specified component with the canonicalURL, a wrapper over
 * buildCanonicalURL with the language parameter passed in from the state store.
 * @param component The component to provide the canonicalURL function to
 * as a prop.
 * @returns A wrapper component that passes buildCanonicalURL as a prop.
 */
const withCanonicalURL = connect((state) => ({
    // @ts-expect-error - TS7006 - Parameter 'path' implicitly has an 'any' type. | TS2571 - Object is of type 'unknown'.
    canonicalURL: (path) => buildCanonicalURL(path, state.i18n.language),
}));

/**
 * Builds a canonical URL without language with the specified path.
 * This can be used either for APIs or for paths which already have the language
 * @param path The path (+ query string parameters) to include into the resulting URL.
 * @returns A fully qualified canonical URL.
 */
const buildCanonicalURLWithoutLanguage = (path: string): string => {
    const { domain, protocol, port } = canonicalDomain();
    return `${protocol}://${domain}${port}${path}`;
};

export {
    canonicalDomain,
    buildCanonicalURL,
    buildAllCanonicalURLs,
    localizedAllCanonicalURL,
    withCanonicalURL,
    buildCanonicalURLWithoutLanguage,
};
