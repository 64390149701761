import { I18n } from '@lingui/core';
import settings from '@app/branding/settings';
import { formatDate } from 'strat/i18n/language/dateTimeFormatter';

import type { ProductPurchase } from 'horizontal/types';

import { ProductPurchaseVirtualStatus } from '../types';

import getProductPurchaseVirtualStatus from './getProductPurchaseVirtualStatus';

export type UseProductPurchaseDetails = {
    readonly productPurchaseID: number;
    readonly name: string;
    readonly valueProposition: string | null | undefined;
    readonly category: string;
    readonly location: string;
    readonly startDate: string;
    readonly expiryDate: string;
    readonly orderID: string;
    readonly purchased: number;
    readonly available: number;
    readonly used: number;
    readonly status: Values<typeof ProductPurchaseVirtualStatus>;
    readonly agencyPackageUsageLimit?: number;
    readonly agencyPackageUsages?: number;
};

const parseDate = (date: string, language: string): string =>
    formatDate(new Date(Date.parse(date)), language, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

const getProductPurchaseDetails = (
    productPurchase: ProductPurchase,
    i18n: I18n,
    language: string,
): UseProductPurchaseDetails => ({
    productPurchaseID: productPurchase.id,
    name: productPurchase.productPackageOffer.productPackage.name,
    valueProposition: productPurchase.productPackageOffer.productPackage.valueProposition || '',
    category: productPurchase.productPackageOffer.categoryLabel || '',
    location:
        productPurchase.productPackageOffer.locationLabel ||
        settings.getTopLevelLocation(i18n).name,
    startDate: parseDate(productPurchase.startDate, language),
    expiryDate: parseDate(productPurchase.expiresAt, language),
    orderID: productPurchase.orderID,
    purchased: productPurchase.usageLimit,
    available: productPurchase.available,
    used: productPurchase.usages,
    status: getProductPurchaseVirtualStatus(productPurchase),
    agencyPackageUsageLimit: productPurchase.agencyPackageUsageLimit,
    agencyPackageUsages: productPurchase.agencyPackageUsages,
});

export default getProductPurchaseDetails;
