import React from 'react';
import type { ComponentType } from 'react';
import type { Store } from 'redux';
import { Provider as StoreProvider } from 'react-redux';
import type { I18n } from '@lingui/core';
import createUrlProcessor from '@app/branding/urlProcessor';
import { I18nProvider } from '@lingui/react';

import type { DialogsCollection } from 'strat/dialogs/types';
import { HistoryRouter, RouteConfig, RouterProvider } from 'react-true-router';
import RoutingContextClass from 'react-true-router/routingContext';
import createReduxMiddleware, { RoutingContextRedux } from 'react-true-router/middlewares/redux';
import createHttpMiddleware, { RoutingContextHttp } from 'react-true-router/middlewares/http';
import createPromiseMiddleware, {
    RoutingContextPromise,
} from 'react-true-router/middlewares/promise';
import type { RouteParameters } from 'react-true-router/route';
import type { PageCollection } from 'strat/rendering/types';
import { createRenderingContext, RoutingContextRendering } from 'strat/rendering';
import { setLoading } from 'strat/rendering/state';
import { getCatalogs, setupI18n } from 'strat/i18n/language';
import { selectI18n } from 'strat/i18n/language/selectors';
import { DialogContext } from 'strat/dialogs/dialogContext';
import { HCaptchaContextProvider } from 'strat/captcha';
import { logError } from 'strat/error/log';
import { RouteNames } from 'strat/routes';
import { DismissibleStacksProvider } from 'strat/modal/dismissible';

export type Params = {
    [key: string]: string;
};

export type Match = {
    params: Params;
    url: string;
};

/**
 * Object that is added on top of RoutingContextClass
 * and is provided to routes.
 */
export type RoutingContext = {
    redux: RoutingContextRedux;
    http: RoutingContextHttp;
    promise: RoutingContextPromise;
    rendering: RoutingContextRendering;
    match: Match;
    i18n: I18n;
};

/**
 * Object that is returned from 'createContext'
 * method of the {@see router} class
 */
export type RoutingContextWithMiddlewares = RoutingContextClass & RoutingContext;

/**
 * Options that can be passed to {@see App}.
 */
type AppOptions = {
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    store: Store;
    routes: RouteConfig;
    pages: PageCollection;
    dialogs: DialogsCollection;
    renderer: ComponentType<any>;
    promises?: Array<Promise<any>>;
};

/**
 * Router abstraction on top of {@see react-true-router}.
 */
class App extends HistoryRouter {
    /**
     * Redux store to use.
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    store: Store;

    /**
     * Pages available for rendering.
     */
    pages: PageCollection;

    /**
     * Component to use for rendering pages.
     */
    renderer: ComponentType<any>;

    pendingRoute: string | undefined;

    i18n: I18n;

    /**
     * Initializes a new instance of the {@see Router} class.
     */
    constructor({ store, routes, pages, renderer, promises, dialogs }: AppOptions) {
        super(routes, {}, createUrlProcessor(store));

        this.store = store;
        this.pages = pages;
        this.renderer = renderer;
        // @ts-expect-error - TS2339 - Property 'dialogs' does not exist on type 'App'.
        this.dialogs = dialogs;

        this.i18n = setupI18n(this.getLanguage(), getCatalogs());

        this.middlewares = {
            http: createHttpMiddleware(),
            redux: createReduxMiddleware(this.store),
            promise: createPromiseMiddleware(promises || []),
            rendering: createRenderingContext(this.store),
            i18n: () => selectI18n(store.getState()),
        };
    }

    isRouteInBundle(routeName: string): boolean {
        return routeName in (this.pages || {});
    }

    getLanguage() {
        return this.store.getState().i18n.language;
    }

    /**
     * Preload async pages before routing.
     */
    pushRoute(name: string, params: null | RouteParameters = null, invoke = true): void {
        // guess the pageName from the route name - by convention we give them the same names
        const pageName = name;
        const page = this.pages[pageName];
        const pageLoaded = page && page.state && page.state.loaded === true;

        if (page?.preload && !pageLoaded) {
            this.pendingRoute = name;

            page.preload()
                .then(() => {
                    if (this.pendingRoute === name) {
                        // the action is async; the user has the chance to click on another link
                        // while the page is loading and we need to honour the most recent "click"
                        super.pushRoute(name, params, invoke);
                    }
                })
                .catch((e) => {
                    if (this.pendingRoute === name) {
                        logError({
                            e,
                            msg: 'Page preload failed',
                            context: {
                                name,
                            },
                        });

                        super.pushRoute(RouteNames.INTERNAL_ERROR, { origin: 'pagePreloadFail' });
                    }
                });

            this.store.dispatch(setLoading(true));
            return;
        }

        super.pushRoute(name, params, invoke);
    }

    /**
     * Renders the app.
     */
    render() {
        return (
            <StoreProvider store={this.store}>
                <RouterProvider router={this}>
                    <I18nProvider i18n={this.i18n}>
                        <HCaptchaContextProvider>
                            <DismissibleStacksProvider>
                                {/* @ts-expect-error - TS2322 - Type '{ children: ReactElement<any, string | JSXElementConstructor<any>>; registeredDialogs: any; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, WithRouterProps> & RefAttributes<any> & { children?: ReactNode; }'. | TS2339 - Property 'dialogs' does not exist on type 'App'. */}
                                <DialogContext registeredDialogs={this.dialogs}>
                                    {React.createElement(this.renderer, {
                                        state: this.store.getState(),
                                        pages: this.pages,
                                    })}
                                </DialogContext>
                            </DismissibleStacksProvider>
                        </HCaptchaContextProvider>
                    </I18nProvider>
                </RouterProvider>
            </StoreProvider>
        );
    }
}

export default App;
