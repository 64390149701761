import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { Dropdown } from 'strat/modal';
import { Flex } from 'strat/components';

import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import styles from './styles/locationRadiusFilter.cssm';
import useLocationRadiusOptions, { getLocationRadiusOptionName } from './useLocationRadiusOptions';
import useRefineLocationRadiusFilter from './useRefineLocationRadiusFilter';
import useLocationRadiusFilterValue from './useLocationRadiusFilterValue';

type Props = {
    readonly onClick: () => void;
    readonly className?: string;
};

const LocationRadiusFilter = ({ onClick, className }: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const i18n = useI18n();

    const filterOptions = useLocationRadiusOptions();
    const refineLocationRadius = useRefineLocationRadiusFilter();

    const filterValue = useLocationRadiusFilterValue();
    const filterName = getLocationRadiusOptionName(i18n, filterValue);
    const renderTrigger = () => (
        <Flex
            alignCenter
            className={classNames(styles.rangeFilterTrigger, className)}
            onClick={() => {
                onClick();
                setIsOpen((x) => !x);
            }}
        >
            <div className={styles.triggerLabel}>{filterName}</div>
            <img
                src={iconArrowDown}
                alt={'Dropdown trigger'}
                className={classNames(styles.downArrowIcon, { [styles.rotated]: isOpen })}
            />
        </Flex>
    );

    return (
        <Dropdown
            triggerIsDismissble
            open={isOpen}
            renderTrigger={renderTrigger}
            onDismissed={() => setIsOpen(false)}
        >
            <div className={styles.dropDownContainer}>
                {filterOptions.map((option) => (
                    <div
                        key={option}
                        onClick={() => {
                            refineLocationRadius(option);
                            setIsOpen(false);
                        }}
                        className={styles.option}
                    >
                        {getLocationRadiusOptionName(i18n, option)}
                    </div>
                ))}
            </div>
        </Dropdown>
    );
};

export default LocationRadiusFilter;
