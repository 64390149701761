import { StratAPI } from 'strat/api';
import { FetcherFactoryWithContext } from 'strat/fetcher';

type Params = {
    readonly scriptExternalID: string;
};

/**
 * Factory for creating action creators and reducers
 * for fetching the ad search configuration from the back-end.
 */
const adSearchConfigurationFactory = new FetcherFactoryWithContext<Params>(
    'adSearchConfiguration',
    ({ params, state }) =>
        new StratAPI(state.i18n.language).adSearchConfiguration(params.scriptExternalID),
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

/**
 * Reducer for ad search configuration
 */
const adSearchConfigurationReducer = adSearchConfigurationFactory.reducer();

/**
 * Fetches an ad search configuration by external id
 */
const fetchAdSearchConfiguration = (scriptExternalID: string) => {
    const creator = adSearchConfigurationFactory.creator();
    return creator({ scriptExternalID });
};

const clearAdSearchConfiguration = () => ({
    type: adSearchConfigurationFactory.actions.clear,
});

export { fetchAdSearchConfiguration, clearAdSearchConfiguration };

export default adSearchConfigurationReducer;
