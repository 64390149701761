import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

const useCashPayment = () => {
    const i18n = useI18n();
    return React.useCallback(() => {
        const url = t(i18n)`cash_payment_url`;
        window.location.href = url;

        return url;
    }, [i18n]);
};

export default useCashPayment;
