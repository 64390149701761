import { isCanceled } from 'strat/util/cancelable';

import { obtainSentry, obtainLogger } from './logDestinations';
import type { ErrorContext } from './context';

interface LogErrorOptions {
    e?: unknown;
    msg: string;
    context?: ErrorContext;
}

interface ErrorWithArbitraryAttributes extends Error {
    [key: string]: any;
}

const wrapError = (error: unknown): Error | null => {
    if (!error) {
        return null;
    }

    if (error instanceof Error) {
        return error;
    }

    // Not great, but at least it gives us a stack trace
    if (typeof error === 'string') {
        return new Error(error);
    }

    // Sometimes we receive an object that looks like a `Error`
    // object. We'll try to reconstruct it in that case.
    const errorLikeObject = error as Record<string, any>;

    const wrappedError = new Error();
    wrappedError.name = errorLikeObject.name || errorLikeObject.type || errorLikeObject.code;
    wrappedError.message = errorLikeObject.message || errorLikeObject.msg;
    if (!wrappedError.message) {
        try {
            wrappedError.message = JSON.stringify(errorLikeObject);
        } catch {
            wrappedError.message = errorLikeObject.toString();
        }
    }
    wrappedError.stack =
        errorLikeObject.stack || errorLikeObject.trace || errorLikeObject.traceback;
    wrappedError.stack = wrappedError.stack?.toString() || '';
    wrappedError.cause = errorLikeObject.cause;

    return wrappedError;
};

/**
 * Extracts additional context from any `Error` object
 * that exposes a `toJSON` function.
 *
 * This is based on a pseudo standard popularized by
 * Axios and supported by Sentry's `ExtraErrorData`
 * integration.
 */
const collectContextFromError = (error: ErrorWithArbitraryAttributes | null): ErrorContext => {
    try {
        if (error?.toJSON && typeof error.toJSON === 'function') {
            return error?.toJSON() || {};
        }
    } catch (e) {
        return { collectContextFromError: `Unable to extract context from error: ${e}` };
    }

    return {};
};

/**
 * Stringifies all stacktraces of a chain of error causes
 *
 * For example, if a retrieval error is caused by a connection error,
 * both should be logged with their corresponding stacktraces
 */
const collectCauseChainStacktraces = (errorCause: unknown): string[] => {
    const result: string[] = [];
    let level = 1;
    let cause = wrapError(errorCause);

    while (cause) {
        const stackLines = getStackLines(cause);
        if (stackLines.length) {
            stackLines[0] = 'Caused by: ' + stackLines[0];
        }
        stackLines.filter(Boolean).forEach((line) => result.push('    '.repeat(level) + line));
        cause = wrapError(cause.cause);
        level += 1;
    }
    return result;
};

/**
 * Split an error stack line by line and filter out the empty ones
 */
const getStackLines = (error: Error | null): string[] => {
    const errorStack = error ? error.stack || String(error) : '';
    return errorStack.split('\n').filter(Boolean);
};

/**
 * Logs an error to the best of its abilities with as much
 * context as possible.
 *
 * The origins of this function are in Sentry. This started
 * out as a simple wrapper over Sentry's `captureException`,
 * but we quickly added plain logging to ensure that errors
 * are _somewhere_ in case they don't reach Sentry.
 *
 * The goal is simple: Take an error that occurred somewhere
 * and log it with as much info as possible in a place that
 * we can find it back.
 */
export const logError = (options: LogErrorOptions): Promise<void> => {
    const error = wrapError(options.e);

    if (error && isCanceled(error)) {
        return Promise.resolve();
    }

    const context = options.context || ({} as ErrorContext);

    return Promise.all([
        // Always log error to the logger
        obtainLogger().then((logger) => {
            const contextFromError = collectContextFromError(error);

            // Log stack trace.
            //
            // We use the name `exception` because `structlog` in
            // our Python back-end uses this for stack traces as well.
            const stackLines = getStackLines(error);
            stackLines.push(...collectCauseChainStacktraces(wrapError(error?.cause)));
            if (stackLines.length) {
                contextFromError['exception'] = stackLines.join('\n');
            }

            logger.error({ ...contextFromError, ...context }, options.msg);
        }),

        // Log error to Sentry if available.
        //
        // We do not have to take care of extracting context
        // from the error because Sentry's `ExtraErrorData`
        // integration takes care of that.
        obtainSentry().then((Sentry) => {
            if (Sentry) {
                const name = error?.name || error?.constructor?.name || 'Error';

                if (error) {
                    Sentry.captureException(error, {
                        contexts: { [name]: { message: options.msg, ...context } },
                    });
                } else {
                    // DO NOT replace this with `Sentry.captureMessage`
                    // Wrapping it in an error _might_ give us a meaningful
                    // stack trace.
                    Sentry.captureException(wrapError(options.msg), {
                        contexts: { [name]: context },
                    });
                }
            }
        }),
    ]).then(() => {});
};
