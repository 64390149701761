import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import useI18n from 'strat/i18n/language/useI18n';

import { FullAd, ProductPackageOffer } from 'horizontal/types';

import styles from './styles/telesalesTool.cssm';
import TeleSalesTable from './teleSalesTable';
import useAvailableAdPackageOffers from './hooks/useAvailableAdPackageOffers';

type Props = Readonly<{
    ad: FullAd;
    selectedPackageOfferID: string | undefined;
    onPackageOfferSelectionChange: (packageOffer: ProductPackageOffer | undefined) => unknown;
    onError: (error: string | undefined) => unknown;
}>;

const AdPackageOffersCard = ({
    ad,
    selectedPackageOfferID,
    onPackageOfferSelectionChange,
    onError,
}: Props) => {
    const i18n = useI18n();
    const [loading, setLoading] = React.useState(false);

    const { packageOffers, fetchPackageOffers } = useAvailableAdPackageOffers(setLoading, onError);

    const handlePackageOfferSelectionChange = React.useCallback(
        (packageOfferID: string | undefined) => {
            let selectedPackageOffer: ProductPackageOffer | undefined;

            if (packageOfferID) {
                selectedPackageOffer = packageOffers.find(
                    (packageOffer) => packageOffer.packageOfferID == packageOfferID,
                );
            }

            onPackageOfferSelectionChange(selectedPackageOffer);
        },
        [packageOffers, onPackageOfferSelectionChange],
    );

    React.useEffect(() => {
        fetchPackageOffers(ad);
    }, [ad, fetchPackageOffers]);

    React.useEffect(() => {
        handlePackageOfferSelectionChange(undefined);
    }, [packageOffers, handlePackageOfferSelectionChange]);

    return (
        <Flex column className={styles.cardElement}>
            <TeleSalesTable
                loading={loading}
                setSelectedItem={handlePackageOfferSelectionChange}
                items={packageOffers}
                headers={[
                    { text: t(i18n)`Discount price`, value: 'discountPrice' },
                    {
                        text: t(i18n)`Package Label`,
                        value: (item: ProductPackageOffer) =>
                            item.label || item.productPackage.name,
                    },
                    { text: t(i18n)`Category`, value: 'categoryLabel' },
                    { text: t(i18n)`Regular price`, value: 'price' },
                    {
                        text: t(i18n)`Pricing code`,
                        value: (offer: ProductPackageOffer) => offer?.adPattern?.externalID || '',
                    },
                ]}
                itemKey="packageOfferID"
                radioInputName="package"
                tableTitle={t(i18n)`Choose the correct package`}
                emptyTableMessage={t(i18n)`There are no applicable packages`}
                selectedItem={selectedPackageOfferID}
            />
        </Flex>
    );
};

export default React.memo(AdPackageOffersCard);
