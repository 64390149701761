import * as React from 'react';

import { NoImage } from 'horizontal/image';

import styles from './styles/item.cssm';

type Props = {
    readonly url: string;
};

const Item = ({ url }: Props) => {
    const [isError, setIsError] = React.useState(false);
    const onError = React.useCallback(() => setIsError(true), []);
    if (isError) {
        return <NoImage className={styles.noImage} />;
    }
    return (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
            <img className={styles.image} src={url} alt={''} onError={onError} />
        </div>
    );
};

export default Item;
