import styles from './styles/scroll.cssm';

const BODY_WRAPPER = 'body-wrapper';

export default class Scroll {
    /**
     * Stack for callers that disabled the scroll, the stack
     * must be emptied before the scroll is actually enabled again.
     */
    static callers: string[] = [];

    static shouldScrollUpOnEnable = false;

    /**
     * Scrolls up to the top of the page if the scroll is enabled. If not, the page will
     * scroll up when the Scroll.enable is called.
     */
    static up() {
        if (Scroll.isEnabled()) {
            if (window) {
                window.scrollTo(0, 0);
            }
        } else {
            Scroll.shouldScrollUpOnEnable = true;
        }
    }

    static vertically(position: number) {
        if (window) {
            window.scrollTo(0, position);
        }
    }

    /**
     * Gets whether scrolling is enabled on the entire page.
     * @param element The element to get off whether scrolling
     * is enabled. If not specified, the status will be returned
     * for the entire page.
     */
    static isEnabled(node: HTMLElement | null = null): boolean {
        const element = node || (document.getElementById && document.getElementById(BODY_WRAPPER));
        if (!element) {
            return true;
        }

        return !element.classList.contains(styles.noScroll);
    }

    /**
     * Disables scrolling on the entire page.
     *
     * WARNING: use this with caution, this breaks CSS overflow properties.
     *
     * @param key Unique string under which to register the call, you'll
     * need this to re-enable the scroll again.
     * @param element The element to disable scrolling on, if not specified
     * scrolling will be disabled on the entire page.
     */
    static disable(key: string, node: HTMLElement | null = null) {
        if (!Scroll.callers.includes(key)) {
            Scroll.callers.push(key);
        }

        if (!Scroll.isEnabled(node)) {
            return;
        }

        const element = node || document.getElementById(BODY_WRAPPER);
        if (element) {
            if (window.scrollY) {
                element.style.top = `-${window.scrollY}px`;
            }
            element.classList.add(styles.noScroll);
        }
    }

    /**
     * Enables scrolling on the entire page after having
     * disabled it with {@see disableScrolling}.
     * @param key The unique string you used to disable the scroll.
     * @param element The element to re-enable scrolling on, if not
     * specified scrolling will ne re-enabled scrolling on the entire page.
     */
    static enable(key: string, node: HTMLElement | null = null) {
        const callIndex = Scroll.callers.lastIndexOf(key);
        if (callIndex >= 0) {
            Scroll.callers.splice(callIndex, 1);
        }

        if (Scroll.callers.length !== 0) {
            return;
        }

        if (Scroll.isEnabled(node)) {
            return;
        }

        const element = node || document.getElementById(BODY_WRAPPER);
        if (element) {
            const top = parseInt(element.style.top, 10);
            element.style.top = 'unset';

            element.classList.remove(styles.noScroll);

            if (window.scrollTo) {
                window.scrollTo(0, -top);
            }

            // When the browser window is resized while the scroll is disabled, the components that listen
            // for resize events and measure page dimensions misbehave because Scroll messes with the page
            // dimensions. When the page dimensions are restored by the Scroll.enable we trigger a window
            // resize event so the "sanity" is restored for those components.
            if (window.dispatchEvent) {
                window.dispatchEvent(new Event('resize'));
            }

            if (Scroll.shouldScrollUpOnEnable) {
                Scroll.up();
                Scroll.shouldScrollUpOnEnable = false;
            }
        }
    }
}
