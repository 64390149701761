//@ts-nocheck
import Actions from './actions';

type State = {
    [key: string]: number;
};

const defaultState: State = {};

export default (state: State | null | undefined = defaultState, action: any): State => {
    switch (action.type) {
        case Actions.SET_BANNERS:
            return {
                ...state,
                ...action.banners,
            };
        case Actions.INCREMENT_VIEW_COUNT: {
            const newData = state[action.name] || {};
            return {
                ...state,
                [action.name]: {
                    ...newData,
                    viewCount: (newData.viewCount || 0) + 1,
                },
            };
        }
        default:
            return state;
    }
};
