import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

import { AdVirtualState } from 'horizontal/types';
import type { FullAd } from 'horizontal/types';
import { formatDate } from 'horizontal/util';

const FORMATTER_OPTIONS = {
    day: 'numeric',
    month: 'short',
} as const;

const DatesOfInterest = ({ ad }: { ad: FullAd }) => {
    const i18n = useI18n();

    const formatAdDate = (date: number) => formatDate(i18n, date * 1000, FORMATTER_OPTIONS);

    const createdAt = formatAdDate(ad.createdAt);
    const updatedAt = formatAdDate(ad.updatedAt);
    const expiresAt = formatAdDate(ad.expiresAt ?? 0);

    switch (ad.virtualState) {
        case AdVirtualState.FEATURED:
        case AdVirtualState.ACTIVE:
        case AdVirtualState.ELITE:
            return (
                <>
                    <Text.Base>{`${t(i18n)`Active from`} ${createdAt}`}</Text.Base>
                    {ad.expiresAt ? (
                        <Text.Base>&nbsp;{`${t(i18n)`to`} ${expiresAt}`}</Text.Base>
                    ) : null}
                </>
            );

        case AdVirtualState.EXPIRED:
        case AdVirtualState.OUTDATED:
            return ad.expiresAt ? (
                <Text.Base>{`${t(i18n)`Expired on`} ${expiresAt}`}</Text.Base>
            ) : null;

        case AdVirtualState.DISABLED:
            return <Text.Base>{`${t(i18n)`Disabled on`} ${updatedAt}`}</Text.Base>;

        case AdVirtualState.SOLD:
            return <Text.Base>{`${t(i18n)`Marked as sold on`} ${updatedAt}`}</Text.Base>;

        case AdVirtualState.LIMITED:
        case AdVirtualState.MODERATION_PENDING:
        case AdVirtualState.FEATURED_PENDING:
        case AdVirtualState.ELITE_PENDING:
        case AdVirtualState.MODERATION_SOFT_REJECT:
        case AdVirtualState.MODERATION_HARD_REJECT:
            return <Text.Base>{`${t(i18n)`Created on`} ${createdAt}`}</Text.Base>;

        default:
            return null;
    }
};

export default DatesOfInterest;
