import * as React from 'react';
import { Dialog } from 'strat/modal';
import { Flex, Text, Button } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconClose.svg';

import styles from './styles/actionConfirmDialog.cssm';

type Props = {
    readonly dialogDetails?: {
        [key: string]: string;
    };
    cancelAction: () => void;
    confirmAction: () => void;
};

function AddressConfirmationDialog({ dialogDetails, cancelAction, confirmAction }: Props) {
    return (
        <Dialog dismissible visible onVisibilityChanged={cancelAction}>
            <Flex column alignCenter className={styles.confirmDialog}>
                <Flex justifySpaceBetween className={styles.confirmDialogTitle}>
                    <Text.XLarge>{dialogDetails?.title}</Text.XLarge>
                    <IconClose onClick={cancelAction} />
                </Flex>
                <Text.Base className={styles.confirmDialogDescription}>
                    {dialogDetails?.description}
                </Text.Base>
                <Flex alignCenter>
                    <Flex justifyCenter className={styles.confirmDialogCancel}>
                        <Button
                            small
                            secondary
                            onClick={() => {
                                cancelAction();
                            }}
                        >
                            {dialogDetails?.cancel}
                        </Button>
                    </Flex>
                    <Button
                        small
                        onClick={() => {
                            confirmAction();
                        }}
                    >
                        {dialogDetails?.confirm}
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
}

export default AddressConfirmationDialog;
