import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppType } from 'strat/alerts/types';
import { selectRouteParams } from 'strat/routes/selectors';
import RouteNames from 'strat/routes/routeNames';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { Pagination, usePagination } from 'horizontal/pagination';

import LoadingCard from './loadingCard';
import ListEntry from './listEntry';
import ListEntryCompact from './compact/listEntryCompact';
import { ORDERS_PAGE_SIZE } from './constants';
import { selectConsumedProductAds } from './selectors';
import styles from './styles/productConsumption.cssm';

const ProductConsumption = ({
    productPurchaseID,
    platform = AppType.WEB_APP,
}: {
    productPurchaseID: number;
    platform: string;
}) => {
    const {
        items: consumedProductAds,
        isLoading,
        activePage,
        totalPages,
    } = usePagination(selectConsumedProductAds, ORDERS_PAGE_SIZE);
    const i18n = useI18n();
    const language = useSelector(selectLanguage);
    const consumedProductAdsObject = useSelector(selectConsumedProductAds);
    const routeParams = useSelector(selectRouteParams);
    let productName = '';
    let subtitle = '';

    const consumedProductAdEntries = isLoading
        ? Array.from(Array(ORDERS_PAGE_SIZE).keys(), (index: number) => (
              <LoadingCard key={`${index}`} />
          ))
        : consumedProductAds.map(
              // @ts-expect-error - TS7006 - Parameter 'ad' implicitly has an 'any' type.
              (ad) =>
                  ad !== null &&
                  (platform === AppType.WEB_APP ? (
                      <ListEntry productPurchaseID={productPurchaseID} ad={ad} key={ad.id} />
                  ) : (
                      <ListEntryCompact productPurchaseID={productPurchaseID} ad={ad} key={ad.id} />
                  )),
          );

    if (!isLoading) {
        productName = consumedProductAdsObject?.data?.name[language] || '';
        const category = consumedProductAdsObject?.data?.category;
        const deletedAdsCount = consumedProductAdsObject?.data?.deletedAdsCount
            ? consumedProductAdsObject?.data?.deletedAdsCount
            : 0;
        const categoryName = category?.[language];
        const count = (consumedProductAdsObject?.data?.count || 0) + deletedAdsCount;
        subtitle = t(
            i18n,
        )`${categoryName}, ${deletedAdsCount} out of ${count} ads have been deleted`;
    }

    return (
        <Flex column className={styles.container} fillContainer>
            <div className={styles.header}>
                <Text.XLarge bold>{productName}</Text.XLarge>
            </div>
            <div className={styles.header}>
                <Text.Regular>{subtitle}</Text.Regular>
            </div>
            {consumedProductAdEntries}
            <Pagination
                activePage={activePage}
                totalPages={totalPages}
                routeName={RouteNames.USER_ORDERS}
                routeParams={routeParams}
            />
        </Flex>
    );
};

export default ProductConsumption;
