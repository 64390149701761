import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import Purpose, { PurposeTextDisplay } from 'strat/purpose';

import computeBedsText from './computeBedsText';
import shouldIncludeBeds from './shouldIncludeBeds';
import type { BreadcrumbsElement } from './types';

const computeElementTitle = (
    element: BreadcrumbsElement,
    categoryName: string | null,
    purpose: string,
    i18n: I18n,
) => {
    if (element.title) {
        return element.title;
    }
    if (
        element.beds !== undefined &&
        shouldIncludeBeds(element.beds) &&
        element.category &&
        element.category.name
    ) {
        /* $FlowFixMe */ return t(i18n)`${computeBedsText(element.beds, i18n)} ${
            element.category.name
        } ${Purpose.text(i18n, purpose, PurposeTextDisplay.FOR_TEMPLATES)} in ${element.name}`;
    }

    if (element.beds !== undefined && Number.isInteger(element.beds) && purpose) {
        return t(i18n)`${element.name} ${computeBedsText(element.beds, i18n)} ${Purpose.text(
            i18n,
            purpose,
            PurposeTextDisplay.FOR_TEMPLATES,
        )}`;
    }

    if (element.category) {
        return t(i18n)`${element.category.name} ${Purpose.text(
            i18n,
            purpose,
            PurposeTextDisplay.FOR_TEMPLATES,
        )} in ${element.name}`;
    }

    if (element.beds !== undefined && Number.isInteger(element.beds)) {
        return t(i18n)`${element.name} ${computeBedsText(element.beds, i18n)}`;
    }

    if (purpose && categoryName) {
        return t(i18n)`${categoryName} ${Purpose.text(
            i18n,
            purpose,
            PurposeTextDisplay.FOR_TEMPLATES,
        )} in ${element.name}`;
    }

    return categoryName ? `${element.name} ${categoryName}` : element.name;
};

export default computeElementTitle;
