import { useIsProOwnerAgent } from 'horizontal/agent/state';
import type { ProductPurchase } from 'horizontal/types';

const useFilterFullyAssignedProductPurchasesForProAgencyOwner = (
    productPurchases: Array<ProductPurchase> | undefined | null,
) => {
    const isProAgencyOwner = useIsProOwnerAgent();

    if (!productPurchases) {
        return [];
    }

    if (!isProAgencyOwner) {
        return productPurchases;
    }

    return productPurchases.filter((productPurchase) => productPurchase.available !== 0);
};

export default useFilterFullyAssignedProductPurchasesForProAgencyOwner;
