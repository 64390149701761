import * as React from 'react';

import { ChatNegotiationState } from 'horizontal/chat/constants';
import type { ActionButton, ChatAd } from 'horizontal/chat/types';

import Accepted from '../accepted';

import BuyerMadeOffer from './buyerMadeOffer';
import Initiated from './initiated';
import Rejected from './rejected';
import SellerMadeOffer from './sellerMadeOffer';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly negotiationState: ChatNegotiationState;
    readonly actionButtons: Array<ActionButton>;
    readonly adPrice: number;
    readonly lastOfferPrice: number;
    readonly lastRejectedPrice: number | null | undefined;
};

const MakeOffer = ({
    ad,
    negotiationState,
    actionButtons,
    adPrice,
    lastOfferPrice,
    lastRejectedPrice,
}: Props) => {
    switch (negotiationState) {
        case ChatNegotiationState.BUYER_MADE_OFFER:
            return <BuyerMadeOffer actionButtons={actionButtons} lastOfferPrice={lastOfferPrice} />;
        case ChatNegotiationState.SELLER_MADE_OFFER:
            return (
                <SellerMadeOffer actionButtons={actionButtons} lastOfferPrice={lastOfferPrice} />
            );
        case ChatNegotiationState.OFFER_REJECTED:
            return lastRejectedPrice ? (
                <Rejected actionButtons={actionButtons} lastRejectedPrice={lastRejectedPrice} />
            ) : null;
        case ChatNegotiationState.ACCEPTED:
            return <Accepted actionButtons={actionButtons} lastOfferPrice={lastOfferPrice} />;
        case ChatNegotiationState.INITIATED:
        default:
            return <Initiated ad={ad} adPrice={adPrice} />;
    }
};

export default MakeOffer;
