import { ChatPhoneViewType } from 'horizontal/chat/constants';
import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatAdXID } from 'horizontal/chat/types';

type Props = WithChatStateProps & {
    readonly adXID: string;
    readonly linkType: ChatPhoneViewType;
    readonly counter: number;
};

const PhoneViewChatNotifier = ({ adXID, counter, linkType }: Props) => {
    const sdk = useChatSDK();
    sdk.useAutoSendPhoneViewMessage({ adXID: adXID as ChatAdXID, linkType, counter });

    return null;
};

export default withChatState<Props>(PhoneViewChatNotifier);
