import React, { Component, CSSProperties } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames';

import { computeAbsoluteTopOffset, Scroll } from 'strat/util';

import styles from './styles/sticky.cssm';

/**
 * Properties for {@see Sticky}.
 */
type Props = {
    /**
     * CSS class for additional styling.
     */
    readonly className?: string;
    /**
     * CSS class for styling the element when it is hidden.
     */
    readonly invisibleClassName?: string;
    /**
     * Elements to make sticky.
     */
    readonly children?: Node | React.ReactNode;
    /**
     * Event handler for when the element becomes sticky.
     */
    readonly onStuck?: () => void;
    /**
     * Event handler for when the element unsticks.
     */
    readonly onUnstuck?: () => void;
    /**
     *  Stick element after scroll passed the element. "scrollTop < element.scrollTop + element.height"
     *  If neither stickBefore, nor stickAfter is specified the element will stick when "scrollTop > element.scrollTop + element.height"
     */
    readonly stickAfter?: boolean;
    /**
     *  Stick element before scroll reaches the element. "scrollTop > element.scrollTop"
     *  If neither stickBefore, nor stickAfter is specified the element will stick when "scrollTop > element.scrollTop + element.height"
     */
    readonly stickBefore?: boolean;
    /**
     * Delay the stick of the element by a number of pixels.
     */
    readonly verticalOffset?: number;
    /**
     *  aria label for the element.
     */
    readonly label?: string;
    /**
     * Make the element invisible if it's not sticky.
     */
    readonly invisibleIfNotDetached?: boolean;
    /**
     * HTML ID of the container.
     */
    readonly containerID?: string;
    /**
     * Specify CSS top attribute value for detached style programatically.
     * This is useful when the element must stick below another fixed element.
     */
    readonly detachedTopPosition?: number;
};

/**
 * State for {@see Sticky}.
 */
type State = {
    detached: boolean;
};

/**
 * Makes the children sticky, aka they stay in place when scrolling.
 *
 * Does all the fancy magic you'd expect off a <Sticky /> element:
 *
 * - Places a spacer in the spot where the element used to be so
 *   that the page content doesn't jump.
 * - Provides events for when the element becomes sticky and unsticky.
 * - Applies a special CSS class to the sticky element so that
 *   conditional styling can be done.
 */
class Sticky extends Component<Props, State> {
    /**
     * Offset the element has to the top (relative to the view port)
     * at the moment of mounting.
     */
    // @ts-expect-error - TS2564 - Property 'offsetTop' has no initializer and is not definitely assigned in the constructor.
    offsetTop: null | number;

    /**
     * Height of the element
     */
    offsetHeight: number;

    /**
     * Element that is being made sticky.
     */
    // @ts-expect-error - TS2564 - Property 'element' has no initializer and is not definitely assigned in the constructor.
    element: null | HTMLElement;

    /**
     * Initializes a new instance of {@see Sticky}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = { detached: false };
        this.offsetHeight = 0;
    }

    /**
     * Invokes the onStuck and OnUnstuck event handlers.
     */
    invokeEventHandlers() {
        const eventHandler = this.state.detached ? this.props.onStuck : this.props.onUnstuck;

        if (eventHandler) {
            eventHandler();
        }
    }

    /**
     * Handler for when the user scrolls.
     */
    onScroll(): void {
        if (!this.element) {
            return;
        }
        // Assume that if the clientHeight is 0 then the element is hidden.
        // Ignore this check if we want the element to be hidden.
        if (this.element.clientHeight === 0 && !this.props.invisibleIfNotDetached) {
            return;
        }

        if (!Scroll.isEnabled()) {
            if (this.state.detached) {
                this.setState(() => ({ detached: false }), this.invokeEventHandlers);
            }
            return;
        }

        if (!this.state.detached) {
            // Store the offset as long as the element is not detached since
            // its position might change
            this.offsetTop = computeAbsoluteTopOffset(this.element);
            if (this.props.verticalOffset) {
                this.offsetTop += this.props.verticalOffset;
            }
            this.offsetHeight = this.element.offsetHeight;

            // prevent jumpy behaviour with invisible elements
            if (this.props.invisibleIfNotDetached) {
                this.offsetTop = Math.abs(this.offsetTop);
                this.offsetHeight = Math.abs(this.offsetHeight);
            }
        }

        if (this.offsetTop === null) {
            return;
        }

        const offsetHeight = this.props.stickAfter ? this.offsetHeight : 0;
        const detach = this.props.stickBefore
            ? window.scrollY < this.offsetTop - window.innerHeight + this.offsetHeight
            : window.scrollY > this.offsetTop + offsetHeight;

        if (detach !== this.state.detached) {
            this.setState(
                (prevState) => ({ detached: !prevState.detached }),
                this.invokeEventHandlers,
            );
        }
    }

    /**
     * Component mounts, hooks up scroll event and computes
     * the element's current offset to the top.
     */
    componentDidMount(): void {
        if (!this.element) {
            return;
        }

        window.addEventListener('scroll', this.onScroll, { passive: true });
        this.onScroll();
    }

    /**
     * Component unmounts, remove scroll event listener.
     */
    componentWillUnmount(): void {
        // @ts-expect-error - TS2769 - No overload matches this call.
        window.removeEventListener('scroll', this.onScroll, { passive: true });
    }

    /**
     * Renders the children in a special container
     * that is made sticky when needed.
     */
    render() {
        let className = classNames(this.props.className, {
            [styles.detached]: this.state.detached,
            sticky: this.state.detached,
        });

        const isInvisible = this.props.invisibleIfNotDetached ? !this.state.detached : false;

        if (isInvisible) {
            className = this.props.invisibleClassName || styles.invisible;
        }

        let inlineStyle: CSSProperties = {};
        if (this.state.detached && this.props.detachedTopPosition) {
            inlineStyle = { top: `${this.props.detachedTopPosition}px` };
        }

        const elements = [
            <div
                key="container"
                className={className}
                ref={(element) => {
                    this.element = element;
                }}
                aria-label={this.props.label}
                id={this.props.containerID}
                style={inlineStyle}
            >
                {this.props.children}
            </div>,
        ];

        // insert a spacer so that the content below
        // the sticky container stays in the same place
        if (this.state.detached && this.element && !this.props.invisibleIfNotDetached) {
            elements.push(<div key="spacer" style={{ height: this.element.clientHeight }} />);
        }

        return elements;
    }
}

export default Sticky;
