import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import type { ActionButton } from 'horizontal/chat/types';

import ActionButtons from '../actionButtons';
import PriceLabel from '../priceLabel';
import RejectedLabel from '../rejectedLabel';

import styles from './styles/rejectedBuyer.cssm';

type Props = {
    readonly actionButtons: Array<ActionButton>;
    readonly lastRejectedPrice: number;
};

const Rejected = ({ actionButtons, lastRejectedPrice }: Props) => (
    <Flex column className={styles.container}>
        <Flex column fillContainer alignCenter justifyCenter>
            <Text.Large bold uppercase>
                <Trans>Your offer:</Trans>
            </Text.Large>
            <Flex alignCenter>
                <PriceLabel price={lastRejectedPrice} gray />
                <RejectedLabel />
            </Flex>
            <Text.Regular>
                <Trans>Seller waiting for your new offer</Trans>
            </Text.Regular>
        </Flex>
        <ActionButtons actionButtons={actionButtons} />
    </Flex>
);
export default Rejected;
