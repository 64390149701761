import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import Billing from './billing/billing';
import styles from './styles/billingSheet.cssm';

const BillingSheet = () => (
    <Flex stretchWidth column className={styles.container}>
        <Flex stretchWidth className={styles.title}>
            <Text.XLarge bold>
                <Trans>Billing information</Trans>
            </Text.XLarge>
        </Flex>
        <Flex stretchWidth>
            <Billing />
        </Flex>
    </Flex>
);

export default BillingSheet;
