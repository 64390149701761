import * as React from 'react';
import { useSelector } from 'react-redux';
import Layout from 'strat/layout';
import { useI18n } from 'strat/i18n/language';
import { locationHierarchyToString } from 'strat/misc';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ViewSections } from 'strat/gtm';
import { selectUserExternalID } from 'strat/user/session';
import settings from '@app/branding/settings';
import { selectLayout } from 'strat/layout/selectors';

import { isAdEditor } from 'horizontal/util';
import { AdProduct } from 'horizontal/types';
import { selectLocationNameWithParent } from 'horizontal/location';
import type { LiteAd } from 'horizontal/types';
import { useAdPriceLabel, useLocalizedAdTitle } from 'horizontal/ad';

import AdCardForNormalCategory from './adCardForNormalCategory';
import DesktopAdCardForHeroCategory from './adCardForHeroCategory';
import CompactAdCardForHeroCategory from './compact/adCardForHeroCategory';
import gridViewStyles from './styles/gridViewStyles.cssm';
import listViewStyles from './styles/listViewStyles.cssm';
import AdLink from './adLink';
import { useShowHeroCategoryAdCardDesign } from './useShowHeroCategoryAdCardDesign';

type Props = {
    ad: LiteAd;
    index?: number;
    viewType?: Values<typeof SearchHitsLayout>;
    hideLocation?: boolean;
    viewSection: Values<typeof ViewSections>;
    hideAgency?: boolean;
    hideFeatured?: boolean;
    hideElite?: boolean;
    highlighted?: boolean;
};

const AdCard = ({
    ad,
    index,
    viewType,
    hideLocation,
    viewSection,
    hideAgency,
    hideFeatured,
    hideElite,
    highlighted,
}: Props) => {
    const layout = useSelector(selectLayout);
    const i18n = useI18n();
    const loggedUserExternalId = useSelector(selectUserExternalID);
    const isOwnAd = isAdEditor(ad, loggedUserExternalId);

    const showFeatured =
        settings.enableFeaturedBadge && ad.product === AdProduct.FEATURED && !hideFeatured;
    const showElite = ad.product === AdProduct.ELITE && !hideElite;
    const isMobile = layout === Layout.MOBILE;
    const lazyLoadLimit =
        layout === Layout.DESKTOP
            ? CONFIG.build.LAZY_LOAD_FROM_DESKTOP
            : CONFIG.build.LAZY_LOAD_FROM_MOBILE;
    // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
    const lazyLoadPhoto = index === undefined || index + 1 >= parseInt(lazyLoadLimit, 10);

    const locationHierarchy = settings.parseLocationNameWithCity
        ? selectLocationNameWithParent(i18n, ad.location)
        : locationHierarchyToString(ad.location, 2, i18n, ad.location.length - 2);
    const priceLabel = useAdPriceLabel(ad);
    const adLocalizedTitle = useLocalizedAdTitle(ad);

    const isListLayout = viewType === SearchHitsLayout.LIST;

    const styles = isListLayout ? listViewStyles : gridViewStyles;

    const coverPhoto = React.useMemo(() => {
        if (!ad.coverPhoto) {
            return null;
        }

        return {
            ...ad.coverPhoto,
            title: adLocalizedTitle,
        };
    }, [ad, adLocalizedTitle]);

    const link = (
        <AdLink ad={ad} isMobile={isMobile} viewSection={viewSection}>
            <div className={styles.linkDiv} />
        </AdLink>
    );

    const showHeroCategoryAdCardDesign = useShowHeroCategoryAdCardDesign(viewType);

    const AdCardForHeroCategory = isMobile
        ? CompactAdCardForHeroCategory
        : DesktopAdCardForHeroCategory;

    return showHeroCategoryAdCardDesign ? (
        <AdCardForHeroCategory
            ad={ad}
            link={link}
            coverPhoto={coverPhoto}
            coverPhotoSize={CONFIG.runtime.STRAT_LPV_DEFAULT_COVER_PHOTO_SIZE}
            lazyLoadPhoto={lazyLoadPhoto}
            isOwnAd={isOwnAd}
            priceLabel={priceLabel}
            title={adLocalizedTitle}
            location={locationHierarchy}
            viewSection={viewSection}
            highlighted={highlighted}
            showElite={showElite}
            showFeatured={showFeatured}
        />
    ) : (
        <AdCardForNormalCategory
            ad={ad}
            index={index}
            viewType={viewType}
            hideLocation={hideLocation}
            viewSection={viewSection}
            hideAgency={hideAgency}
            hideFeatured={hideFeatured}
            hideElite={hideElite}
            highlighted={highlighted}
            coverPhotoSize={CONFIG.runtime.STRAT_LPV_DEFAULT_COVER_PHOTO_SIZE}
        />
    );
};

export default React.memo(AdCard);
