import { t } from '@lingui/macro';
import * as React from 'react';
import { useRouter } from 'react-true-router';
import { useDialog } from 'strat/dialogs/dialogContext';
import { useI18n } from 'strat/i18n/language';

import { useChatLink } from '@app/chat/hooks/useChatLink';
import type { ChatRoom } from 'horizontal/chat/types';
import { DialogNames } from 'horizontal/dialogs';
import { useDefaultUserName } from 'horizontal/user';

import useChatSDK from './useChatSDK';

interface Props {
    chatRoom: ChatRoom;
    onSafetyTips: () => void;
}

const useChatRoomOptions = ({ chatRoom, onSafetyTips }: Props) => {
    const router = useRouter();
    const i18n = useI18n();
    const sdk = useChatSDK();

    const { xid, contactXID, adXID } = chatRoom;

    const isDraftRoom = !xid;

    const deleteRoom = sdk.useDeleteChatRoom(xid);

    const [, setBlockUserDialogVisible] = useDialog(DialogNames.CHAT_BLOCK_USER_DIALOG);
    const [, setReportDialogVisible] = useDialog(DialogNames.REPORT_USER_DIALOG);
    const blockUser = sdk.useUpdateUserBlocking(contactXID);
    const { isBlocked: isUserBlocked } = sdk.useIsUserBlockedByMe(contactXID);
    const { route, params } = useChatLink({ adXID });

    const {
        contactName: loadedContactName,
        ad,
        isLoading: isUserNameLoading,
    } = sdk.useRoomContactInfo({ contactXID, adXID });
    const defaultContactName = useDefaultUserName();

    return React.useMemo(() => {
        if (isDraftRoom) {
            return null;
        }

        const options = [
            {
                id: 1,
                label: t(i18n)`Safety tips`,
                onClick: () => {
                    onSafetyTips();
                },
            },
            {
                id: 2,
                label: t(i18n)`Delete chat`,
                onClick: () => {
                    deleteRoom();
                    router.replaceRoute(route, params);
                },
            },
            {
                id: 3,
                label: isUserBlocked ? t(i18n)`Unblock user` : t(i18n)`Block user`,
                onClick: () =>
                    setBlockUserDialogVisible(true, {
                        userName: loadedContactName,
                        shouldBlock: !isUserBlocked,
                        onClick: () => blockUser(!isUserBlocked),
                        ad,
                    }),
            },
        ];

        if (!isUserNameLoading) {
            options.push({
                id: 4,
                label: t(i18n)`Report user`,
                onClick: () =>
                    setReportDialogVisible(true, {
                        reportedUserExternalID: contactXID,
                        reportedUserName: loadedContactName ?? defaultContactName,
                        ad,
                    }),
            });
        }

        return options;
    }, [
        i18n,
        deleteRoom,
        router,
        ad,
        blockUser,
        contactXID,
        defaultContactName,
        isDraftRoom,
        isUserBlocked,
        isUserNameLoading,
        loadedContactName,
        onSafetyTips,
        setBlockUserDialogVisible,
        setReportDialogVisible,
        route,
        params,
    ]);
};

export default useChatRoomOptions;
