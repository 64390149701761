// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import {
    FilterCollection,
    RangeFilter,
    ExactFilter,
    ObjectExactFilter,
    QueryFilter,
    PageFilter,
    InsideCircleFilter,
    RefinementFilter,
    ExclusionFilter,
    ExclusionRefinementFilter,
    InsidePolygonFilter,
} from '@sector-labs/fe-search-redux/filters';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import settings from '@app/branding/settings';
import { FilterValues } from 'strat/search';

import { selectFlatCategoryFields } from 'horizontal/categoryFields';
import type { SearchRouteParams } from 'horizontal/routes';
import { searchParamsToFilters } from 'horizontal/search/routing';
import { AdProduct, PriceType } from 'horizontal/types';
import { SortOptionKey } from 'horizontal/search/sorting';

const createAdsFilters = (
    getState: GetState,
    {
        category,
        location,
        locationRadius,
        freeTextQuery,
        minPhotoCount,
        page,
        extraFields,
        product,
        marketingParams,
        remarketingAdID,
        sortOption,
        geoPoint,
    }: SearchRouteParams,
): FilterCollection => {
    const state = getState();
    const categoryFields = selectFlatCategoryFields(state, category?.id);
    const filterCollection = new FilterCollection();

    if (category) {
        filterCollection.refine(
            new ObjectExactFilter({
                attribute: FilterValues.category.attribute,
                value: category,
            }),
        );
    }

    if (location) {
        filterCollection.refine(
            new ObjectExactFilter({
                attribute: FilterValues.location.attribute,
                value: location,
                operator: Operator.OR,
            }),
        );
    }

    if (locationRadius && location?.geography) {
        filterCollection.refine(
            new InsideCircleFilter({
                attribute: FilterValues.locationCoordinates.attribute,
                value: {
                    areaUnit: 'km',
                    radius: locationRadius,
                    coordinates: location.geography,
                },
                key: FilterValues.locationRadius.attribute,
                operator: Operator.OR,
            }),
        );
    }

    if (geoPoint) {
        filterCollection.refine(
            new InsidePolygonFilter({
                attribute: FilterValues.locationCoordinates.attribute,
                value: geoPoint,
            }),
        );
    }

    if (freeTextQuery) {
        const fieldsArray = ['query'];
        if (settings.allowSearchByAdExternalID) {
            fieldsArray.push('externalID');
        }
        filterCollection.refine(
            new QueryFilter({
                value: freeTextQuery,
                fuzzy: settings.searchFuzzySettings,
                fields: fieldsArray,
            }),
        );
    }

    if (minPhotoCount && minPhotoCount > 0) {
        filterCollection.refine(
            new RangeFilter({
                attribute: FilterValues.photoCount.attribute,
                value: { min: minPhotoCount, max: null },
            }),
        );
    }

    if (product !== AdProduct.ALL) {
        filterCollection.refine(
            new ExactFilter({
                attribute: FilterValues.product.attribute,
                value: product,
            }),
        );
    }

    if (product === AdProduct.ALL) {
        filterCollection.refine(
            new RefinementFilter({
                attribute: FilterValues.product.attribute,
                value: [AdProduct.ELITE, AdProduct.FEATURED],
            }),
        );
    }

    if (page) {
        filterCollection.refine(new PageFilter({ value: page }));
    }

    if (extraFields && Object.keys(extraFields).length > 0) {
        const convertedFilters = searchParamsToFilters(categoryFields, extraFields);
        filterCollection.refineMultiple(convertedFilters);
    }

    const agencyTier = marketingParams?.agencyTier;
    if (agencyTier && Array.isArray(agencyTier) && agencyTier.length > 0) {
        filterCollection.refine(
            new RefinementFilter({
                attribute: FilterValues.agencyTier.attribute,
                value: agencyTier,
            }),
        );
    }

    if (remarketingAdID) {
        filterCollection.refine(
            new ExclusionFilter({
                attribute: FilterValues.externalID.attribute,
                value: remarketingAdID,
            }),
        );
    }

    if ([SortOptionKey.LOWEST_PRICE, SortOptionKey.HIGHEST_PRICE].includes(sortOption?.key || '')) {
        filterCollection.remove('extraFields.price_type');
        filterCollection.replace(
            new ExclusionRefinementFilter({
                attribute: 'extraFields.price_type',
                value: [PriceType.REQUESTABLE],
            }),
        );
    }

    return filterCollection;
};

export default createAdsFilters;
