import React from 'react';
import { useSelector } from 'react-redux';

import { decodeStateFilter, pickFilters } from 'horizontal/adManagement/stateFilterEncoding';
import type { GlobalState } from 'horizontal/state';

const useRouteParams = () => {
    const params = useSelector((state: GlobalState) => state.router.routeParams);
    const { filter } = params;

    const createDateFilter = pickFilters(filter, ['createDate']);
    const createDate = React.useMemo(() => {
        const { createDate } = decodeStateFilter(createDateFilter);
        return createDate;
    }, [createDateFilter]);

    const updateDateFilter = pickFilters(filter, ['updateDate']);
    const updateDate = React.useMemo(() => {
        const { updateDate } = decodeStateFilter(updateDateFilter);
        return updateDate;
    }, [updateDateFilter]);

    const productFilterValue = pickFilters(filter, ['productFilter']);
    const productFilter = React.useMemo(() => {
        const { productFilter } = decodeStateFilter(productFilterValue);
        return productFilter;
    }, [productFilterValue]);

    return React.useMemo(() => {
        const { page, adExternalID, tabName, tabChange } = params;
        const { query, category, agent, stateFilter, agentCode, phoneNumber, location } =
            decodeStateFilter(filter);
        return {
            adExternalID,
            tabName,
            tabChange,
            filter,
            location,
            query,
            category,
            agent,
            stateFilter,
            productFilter,
            createDate,
            updateDate,
            page,
            agentCode,
            phoneNumber,
        };
    }, [params, filter, createDate, updateDate, productFilter]);
};

export default useRouteParams;
