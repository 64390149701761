import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import settings from '@app/branding/settings';

import type { AppDispatch } from 'horizontal/state';
import type { MixedAd } from 'horizontal/types';

const useAdCategoryFields = (ad?: MixedAd | null): void => {
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        if (ad?.category?.length) {
            dispatch(
                fetchCategoryFields({
                    categoryExternalIDs: [ad.category.slice(-1)[0].externalID],
                    includeWithoutCategory: !settings.disableCategoryFieldsWithoutCategory,
                }),
            );
        }
    }, [ad, dispatch]);
};

export default useAdCategoryFields;
