import Loadable, { LoadingSpinner } from 'strat/loadable';

import OneSignalScript from './oneSignalScript';
import useNotificationsApprovalSlidein from './useNotificationsApprovalSlideIn';
import useOnPushNotificationsPermissionChanged from './useOnPushNotificationsPermissionChanged';

export const BottomNotificationsPrompt = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'notifications' */
            './bottomNotificationsPrompt'
        ),
    loading: () => null,
});

export const PropertyNotificationsPrompt = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'notifications' */
            './propertyNotificationsPrompt'
        ),
    loading: () => null,
});

export const CallDialogNotificationsFooter = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'notifications' */
            './callDialogNotificationsFooter'
        ),
    loading: LoadingSpinner,
});

export const SuccessDialogNotificationsFooter = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'notifications' */
            './successDialogNotificationsFooter'
        ),
    loading: LoadingSpinner,
});

export {
    OneSignalScript,
    useNotificationsApprovalSlidein,
    useOnPushNotificationsPermissionChanged,
};
