import { serializeLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { buildCanonicalURL } from 'strat/routing';
import createSearchURL from '@app/routes/createSearchURL';

import type { Ad } from 'horizontal/types';

const redirectToParentCategory = (ad: Ad, context: RoutingContextWithMiddlewares) => {
    const {
        redux: {
            store: { getState },
        },
        i18n,
    } = context;
    const { category, location } = ad;
    const params = {
        category: category?.slice(-1)[0],
        location: location?.slice(-1)[0],
    } as const;
    const url = buildCanonicalURL(
        // @ts-expect-error - TS2345 - Argument of type '{ readonly category: LiteCategory; readonly location: LiteHierarchicalLocation; }' is not assignable to parameter of type 'SearchRouteParams'.
        serializeLocation(createSearchURL(params, getState())),
        i18n.locale,
    );
    const status = CONFIG.runtime.ENABLE_TEMPORARY_REDIRECTS ? 302 : 301;
    context.http.redirect(url, { status, isAbsolute: true });
};

export default redirectToParentCategory;
