import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

const selectFavoritesState = (state: any) => state.favorites;

export const selectFavorites = createSelector(
    selectFavoritesState,
    (state) => state.data || EMPTY_ARRAY,
);
export const selectIsLoading = createSelector(selectFavoritesState, (state) => state.loading);
export const selectCreationState = createSelector(selectFavoritesState, (state) => state.creation);
