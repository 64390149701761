import { createSelector } from 'reselect';

import EMPTY_ARRAY from 'strat/empty/array';
import type { CategoryFieldsFilters } from 'strat/types';
import EMPTY_OBJECT from 'strat/empty/object';
import type { GlobalState } from 'strat/state';

import flattenCategoryFields from './flattenCategoryFields';
import addCommonCategoryFields, { COMMON_CATEGORY_FIELDS } from './addCommonCategoryFields';
import type { CategoryFields } from './types';

const EMPTY_CATEGORY_FIELDS_STATE = Object.freeze({
    flatFields: EMPTY_ARRAY,
    childrenFields: EMPTY_ARRAY,
    parentFieldLookup: EMPTY_OBJECT,
}) as CategoryFields;

const selectCategoryFields = createSelector(
    (state: GlobalState) => state.categoryFields.data,
    (_: GlobalState, categoryID?: number | null) => categoryID || null,
    (categoryFields, categoryID) => {
        if (categoryFields) {
            if (categoryID && categoryFields[categoryID]) {
                return addCommonCategoryFields(categoryFields, categoryFields[categoryID]);
            }
            return categoryFields[COMMON_CATEGORY_FIELDS] || EMPTY_CATEGORY_FIELDS_STATE;
        }
        return EMPTY_CATEGORY_FIELDS_STATE;
    },
    { memoizeOptions: { maxSize: 10 } },
);

const selectFlatCategoryFields = createSelector(
    selectCategoryFields,
    (_: GlobalState, __?: number | null, categoryFieldsFilters?: CategoryFieldsFilters) =>
        categoryFieldsFilters ?? EMPTY_OBJECT,
    flattenCategoryFields,
    { memoizeOptions: { maxSize: 10 } },
);

export { selectCategoryFields, selectFlatCategoryFields };
