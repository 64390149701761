import { CategoryFieldFilterType, FlatCategoryField } from 'horizontal/types';
import type { CategoryFieldsFilters } from 'horizontal/types';

const RangeOperator = {
    MIN: 'min',
    MAX: 'max',
    BETWEEN: 'between',
} as const;

const RANGE_SEPARATOR = '_to_';
const SINGLE_CHOICE_OPERATOR = 'eq';
const MULTIPLE_CHOICE_OPERATOR = 'eq';
const MULTIPLE_CHOICE_SEPARATOR = '_and_';

const FILTER_COMPONENTS_REGEX = /(.*)_(eq|min|between|max)_(.*)/;

const parseRangeFilter = (attribute: string, operator: string, value: string) => {
    switch (operator) {
        case RangeOperator.MIN:
        case RangeOperator.MAX:
            return { [operator]: Number(value) || null };
        case RangeOperator.BETWEEN: {
            const values = value
                .split(RANGE_SEPARATOR)
                .map(Number)
                .filter((number) => !isNaN(number));
            return { min: values[0], max: values[1] };
        }
        default:
            return null;
    }
};

const parseSingleChoice = (attribute: string, operator: string, value: string) => {
    if (operator !== SINGLE_CHOICE_OPERATOR) {
        return null;
    }

    return value;
};

const parseMultipleChoice = (attribute: string, operator: string, value: string) => {
    if (operator !== MULTIPLE_CHOICE_OPERATOR) {
        return null;
    }

    return value.split(MULTIPLE_CHOICE_SEPARATOR);
};

export const decodeFilter = (
    filterType: Values<typeof CategoryFieldFilterType>,
    attribute: string,
    operator: string,
    filterValue: string,
) => {
    switch (filterType) {
        case CategoryFieldFilterType.RANGE:
            return parseRangeFilter(attribute, operator, filterValue);
        case CategoryFieldFilterType.SINGLE_CHOICE:
            return parseSingleChoice(attribute, operator, filterValue);
        case CategoryFieldFilterType.MULTIPLE_CHOICES:
            return parseMultipleChoice(attribute, operator, filterValue);
        default:
            return null;
    }
};

export const parseFilterComponents = (filterAsString: string) => {
    const parsedComponents = FILTER_COMPONENTS_REGEX.exec(filterAsString);
    if (!parsedComponents) {
        return {};
    }

    return {
        attribute: parsedComponents[1],
        operator: parsedComponents[2],
        value: parsedComponents[3],
    };
};

const decodeFilters = (
    categoryFields: Array<FlatCategoryField>,
    filters: string,
): CategoryFieldsFilters => {
    if (!categoryFields?.length) {
        return {};
    }

    const filterStrings = filters.split(',');
    return filterStrings.reduce<Record<string, any>>((acc, filterString) => {
        const { attribute, operator, value } = parseFilterComponents(filterString);
        if (!attribute) {
            return acc;
        }

        const filter = categoryFields.find((field) => field.attribute === attribute);
        if (!filter) {
            return acc;
        }

        const decodedFilter = decodeFilter(filter.filterType, attribute, operator, value);
        if (!decodedFilter) {
            return acc;
        }

        acc[attribute] = decodedFilter;
        return acc;
    }, {});
};

export default decodeFilters;
