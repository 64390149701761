import React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { Formik } from 'formik';
import { useI18n } from 'strat/i18n/language';
import type { FormikProps } from 'formik';
import { useRouter } from 'react-true-router';

import useOnSellButtonClick from '@app/navigation/useOnSellButtonClick';

import useSellCarFormValidationSchema from './useSellCarFormValidationSchema';
import useSellCarFormSubmit from './useSellCarFormSubmit';
import useSellCarFormInitialValues from './useSellCarFormInitialValues';
import SellCarForm from './sellCarForm';
import styles from './styles/sellCarFormContainer.cssm';

const SellCarFormContainer = () => {
    const [showCashMyCarForm, setShowCashMyCarForm] = React.useState(true);
    const i18n = useI18n();
    const onClick = useOnSellButtonClick();
    const router = useRouter();
    const onSubmit = useSellCarFormSubmit(showCashMyCarForm, router);
    const brandName = 'Dubizzle'; // we want this always in english

    return (
        <Flex column alignCenter className={styles.flexGap}>
            <h2 className={styles.header}>
                <Trans>Selling your car has never been easier</Trans>
            </h2>
            <Text.XLarge>
                <Trans>Explore new ways to sell your car with one of our 3 services</Trans>
            </Text.XLarge>
            <Flex column>
                <Flex className={styles.topBar} alignCenter>
                    <Flex
                        justifyCenter
                        alignCenter
                        className={classNames(
                            { [styles.active]: showCashMyCarForm },
                            styles.topBarButton,
                        )}
                        onClick={() => setShowCashMyCarForm(true)}
                    >
                        <Text.XLarge>
                            <Trans>Cash My Car</Trans>
                        </Text.XLarge>
                    </Flex>
                    <Flex
                        justifyCenter
                        alignCenter
                        className={classNames(
                            { [styles.active]: !showCashMyCarForm },
                            styles.topBarButton,
                        )}
                        onClick={() => setShowCashMyCarForm(false)}
                    >
                        <Text.XLarge>
                            <Trans>Sell My Car</Trans>
                        </Text.XLarge>
                    </Flex>
                    <div className={styles.divider} />
                    <Flex
                        justifyCenter
                        alignCenter
                        className={classNames(styles.link, styles.topBarButton)}
                        onClick={onClick}
                    >
                        <Text.XLarge>
                            <Trans>Do It Myself</Trans>
                        </Text.XLarge>
                    </Flex>
                </Flex>
                <Flex className={styles.formContainer}>
                    <Flex column className={styles.flexGap}>
                        {showCashMyCarForm ? (
                            <>
                                <h2 className={styles.header}>
                                    <Trans>How Cash My Car Works?</Trans>
                                </h2>
                                <Text.Large>
                                    <Trans>
                                        If you wish to benefit from the Cash My Car service, all you
                                        have to do is book a free car inspection by filling a form
                                        with your car's details or simply by calling us. Your car
                                        will then be inspected for FREE and within the next 48 hours
                                        you will receive an offer from our experts. If you decide to
                                        sell your car to us, you will get cash payment in return
                                        instantly & all the paperwork will be done by our
                                        professional and trustworthy team. That's the easy process
                                        to sell your car quickly with a hassle-free service!
                                    </Trans>
                                </Text.Large>
                            </>
                        ) : (
                            <>
                                <h2 className={styles.header}>
                                    <Trans>How Sell My Car Works?</Trans>
                                </h2>
                                <Text.Large>
                                    <Trans>
                                        To benefit from the Sell My Car service, fix the price you
                                        want and let our experts do the rest.
                                        <br />
                                        You'll keep your car at {brandName} premises and will
                                        receive payment in cash as soon as your car is sold. The{' '}
                                        {brandName} Autos team will manage everything from A to Z,
                                        from the free inspection to the paperwork and insurance. The
                                        process is completely safe and hassle free. The experts will
                                        take care of all the showings of your car and guarantee to
                                        sell it at the best price for you.
                                    </Trans>
                                </Text.Large>
                            </>
                        )}
                    </Flex>
                    <Flex column>
                        <Formik
                            initialValues={useSellCarFormInitialValues(
                                showCashMyCarForm ? 'cash_my_car' : 'sell_my_car',
                            )}
                            onSubmit={onSubmit}
                            validationSchema={useSellCarFormValidationSchema(
                                i18n,
                                !showCashMyCarForm,
                            )}
                        >
                            {(props: FormikProps<any>) => (
                                <SellCarForm {...props} sellMyCar={!showCashMyCarForm} />
                            )}
                        </Formik>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SellCarFormContainer;
