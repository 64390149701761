import * as React from 'react';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { getGlobalGTMVars as selectStratGlobalGTMVars } from 'strat/gtm';
import { selectGeoLocationData } from 'strat/user/selectors';
import { UserRole } from 'strat/user/roles';
import { useActiveUser } from 'strat/user/session';

import { useLocationFilterValue } from 'horizontal/search/location';

import { getLocationVars } from './utils';

const useGlobalGTMVars = () => {
    const activeUser = useActiveUser();
    const userLocation = useSelector(selectGeoLocationData);
    const activeLocation =
        useLocationFilterValue() || userLocation.closestLocation || settings.topLevelLocation;
    const stratGlobalGTMVars = useSelector(selectStratGlobalGTMVars);
    const isAgent = activeUser?.realmRoles?.find((role: UserRole) =>
        Object.values(UserRole).includes(role),
    )
        ? 'yes'
        : 'no';

    return React.useMemo(
        () => ({
            ...stratGlobalGTMVars,
            // @ts-expect-error
            ...getLocationVars(activeLocation.hierarchy),
            website_section: 'main_site',
            user_id: activeUser?.externalID,
            is_agent: isAgent,
        }),
        [stratGlobalGTMVars, activeUser, activeLocation, isAgent],
    );
};

export default useGlobalGTMVars;
