import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import type { FileUploadProgress } from 'horizontal/types';

import styles from './styles/progressBar.cssm';

type Props = {
    readonly uploadingFiles: {
        [key: string]: FileUploadProgress;
    };
};

const ProgressBar = ({ uploadingFiles }: Props) => {
    // The prop uploadingFiles is the source of truth info about the ongoing upload.
    // We keep this separate state to provide a better ux while uploading.
    const [uploadingProgress, setUploadingProgress] = React.useState([]);

    React.useEffect(() => {
        const allFinished = Object.keys(uploadingFiles).every(
            (id) => uploadingFiles[id].isFinished,
        );
        // @ts-expect-error - TS7034 - Variable 'timer' implicitly has type 'any' in some locations where its type cannot be determined.
        let timer = null;

        if (allFinished) {
            timer = setTimeout(() => {
                setUploadingProgress([]);
            }, 1000);
        } else {
            setUploadingProgress(
                // @ts-expect-error - TS2345 - Argument of type '{ id: string; progressPercentage: number; isFinished: boolean; }[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
                Object.keys(uploadingFiles).map((id) => ({
                    id,
                    progressPercentage:
                        (uploadingFiles[id].transferredBytes / uploadingFiles[id].totalBytes) * 100,
                    isFinished: uploadingFiles[id].isFinished,
                })),
            );
        }

        return () => {
            // @ts-expect-error - TS7005 - Variable 'timer' implicitly has an 'any' type.
            if (timer) {
                // @ts-expect-error - TS7005 - Variable 'timer' implicitly has an 'any' type.
                clearTimeout(timer);
            }
        };
    }, [uploadingFiles]);

    return (
        <Flex
            stretchWidth
            className={classNames(styles.progressBar, {
                [styles.hide]: !uploadingProgress?.length,
            })}
        >
            {uploadingProgress.map(({ id, progressPercentage, isFinished }) => {
                return (
                    <div key={id} className={styles.itemProgressContainer}>
                        <div
                            style={{ width: `${progressPercentage}%` }}
                            className={classNames(styles.itemProgress, {
                                [styles.itemCompleted]: isFinished,
                            })}
                        />
                    </div>
                );
            })}
        </Flex>
    );
};

export default React.memo<Props>(ProgressBar);
