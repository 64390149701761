import React from 'react';

import { AdPhoto } from 'horizontal/types';

type PhotoRatiosDictionary = { [key: number]: 'landscape' | 'portrait' };

export const usePhotoRatios = (photos: AdPhoto[]) => {
    const [photoRatios, setPhotoRatios] = React.useState<PhotoRatiosDictionary>({});
    const photoRatiosTemp = React.useRef<PhotoRatiosDictionary>({});

    // This is a function that changes the display type of each image depending on the ratio of the following images
    // The exact process is defined at https://docs.google.com/document/d/1s-5dWsO6RlRN5Bfgih2n5rB5Ch9wN8tpDgIHtVzVUqM/edit
    const organisePhotoRatios = (photoRatios: PhotoRatiosDictionary) => {
        let i = 0;
        const photoIds: number[] = Object.keys(photoRatios).map((key) => Number(key));
        while (i < photoIds.length) {
            const ratio1 = photoRatios[photoIds[i]];
            const ratio2 = photoRatios[photoIds[i + 1]];
            if (ratio2 == undefined) {
                i += 1;
                continue;
            }
            if (ratio1 == 'landscape' && ratio2 == 'landscape') {
                i += 2;
                continue;
            }
            if (ratio1 == 'landscape' && ratio2 == 'portrait') {
                photoRatios[photoIds[i + 2]] = 'portrait';
                i += 3;
                continue;
            }
            if (ratio1 == 'portrait' && ratio2 == 'landscape') {
                photoRatios[photoIds[i + 2]] = 'portrait';
                i += 3;
                continue;
            }
            if (ratio1 == 'portrait' && ratio2 == 'portrait') {
                photoRatios[photoIds[i + 2]] = 'landscape';
                i += 3;
            }
        }
        return photoRatios;
    };

    const isLandscapePhoto = (width: number, height: number) => width < height;

    //This function will get called each time an image has its dimensions available. At the last one, all ratios are recomputed.
    const buildPhotoRatios = (photoId: number, width: number, height: number) => {
        if (photoId in photoRatiosTemp) {
            return;
        }
        if (!photoRatiosTemp.current[photoId]) {
            photoRatiosTemp.current[photoId] = isLandscapePhoto(width, height)
                ? 'landscape'
                : 'portrait';
        }
        if (photoId == photos[photos.length - 1].id) {
            setPhotoRatios(organisePhotoRatios(photoRatiosTemp.current));
        }
    };

    const isLandscapeDisplay = (index: number) => photoRatios[index] == 'landscape';
    const isPortraitDisplay = (index: number) => photoRatios[index] == 'portrait';

    return {
        buildPhotoRatios,
        isLandscapeDisplay,
        isPortraitDisplay,
    };
};
