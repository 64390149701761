import React from 'react';
import { useSelector } from 'react-redux';
import { RouteNames } from 'strat/routes';
import { selectRouteName } from 'strat/routes/selectors';
import { useActiveUserExternalID } from 'strat/user/session';

import {
    selectBusinessPackageCartOffers,
    selectBusinessPackageCartTotalPrice,
} from 'horizontal/businessPackages/selectors';

import { trackAbortPackageSelect, trackAbortCart } from './checkoutTracking';

const useTrackCheckoutAbortEvent = () => {
    const routeName = useSelector(selectRouteName);
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const orderExternalID = useSelector((state) => state.businessPackageCart.orderExternalID);
    const packages = useSelector(selectBusinessPackageCartOffers);
    const totalPrice = useSelector(selectBusinessPackageCartTotalPrice);
    const userExternalID = useActiveUserExternalID();

    return React.useCallback(() => {
        // @ts-expect-error - TS7006 - Parameter 'pack' implicitly has an 'any' type.
        const productIDs = packages ? packages.map((pack) => pack.productPackage.packageID) : [];

        if (routeName === RouteNames.SELECT_MULTIPLE_PACKAGES) {
            trackAbortPackageSelect(productIDs, totalPrice, orderExternalID, userExternalID);
        } else if (routeName === RouteNames.VIEW_BUSINESS_PACKAGE_CART_ROUTE) {
            trackAbortCart(productIDs, totalPrice, orderExternalID, userExternalID);
        }
    }, [packages, routeName, totalPrice, orderExternalID, userExternalID]);
};

export default useTrackCheckoutAbortEvent;
