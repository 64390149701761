import isNil from 'lodash/isNil';
import type { Range } from 'strat/types';
import { dictionaryToFlatArray } from 'strat/util';
import { CategoryFieldRole } from 'strat/types';

import { CategoryFieldFilterType } from 'horizontal/types';
import type {
    CategoryFieldsFilters,
    CategoryFieldFilterValue,
    LiteCategory,
    FlatCategoryField,
} from 'horizontal/types';
import { ExtraFieldEncoderType } from 'horizontal/agency/routing/types';

export const encodeFilter = (
    { attribute, filterType, choices }: FlatCategoryField | ExtraFieldEncoderType,
    value: CategoryFieldFilterValue,
) => {
    if (isNil(value)) {
        return '';
    }

    if (filterType === CategoryFieldFilterType.SINGLE_CHOICE) {
        if (!choices || Array.isArray(value)) {
            return '';
        }

        const choice = dictionaryToFlatArray(choices).find(
            (innerChoice) => innerChoice.value === `${value}`,
        );
        if (!choice) {
            return '';
        }

        return `${attribute}_eq_${choice.value}`;
    }

    if (filterType === CategoryFieldFilterType.MULTIPLE_CHOICES) {
        // @ts-expect-error - TS2339 - Property 'length' does not exist on type 'string | number | true | Range | Primitive[]'.
        if (!choices || !value || !value.length) {
            return '';
        }
        // Order the values by the order in which the choices were
        // configured so that they get encoded in a consistent order.
        const canonicalValues = dictionaryToFlatArray(choices)
            // @ts-expect-error - TS2339 - Property 'includes' does not exist on type 'string | number | true | Range | Primitive[]'.
            .filter((choice) => value.includes(choice.value))
            .map((choice) => choice.value)
            .join('_and_');

        if (!canonicalValues.length) {
            return '';
        }

        return `${attribute}_eq_${canonicalValues}`;
    }

    if (filterType === CategoryFieldFilterType.RANGE) {
        const v = value as Range;

        if ((v.min || v.min === 0) && v.max) {
            return `${attribute}_between_${String(v.min)}_to_${String(v.max)}`;
        }

        if (v.min) {
            return `${attribute}_min_${String(v.min)}`;
        }

        if (v.max) {
            return `${attribute}_max_${String(v.max)}`;
        }
    }

    return '';
};

const isMultipleChoicesPathField = (field: FlatCategoryField) =>
    field.roles.includes(CategoryFieldRole.INCLUDED_IN_PATHNAME) &&
    field.filterType === CategoryFieldFilterType.MULTIPLE_CHOICES;

const encodeFilters = (
    categoryFields: Array<FlatCategoryField>,
    filters: CategoryFieldsFilters,
    category?: LiteCategory | null,
): string =>
    categoryFields
        .map((field) => {
            const fieldValue = filters[field.attribute];
            if (!fieldValue) {
                return null;
            }
            if (category && field.categoryID !== category.id) {
                return null;
            }
            if (isMultipleChoicesPathField(field) && Array.isArray(fieldValue)) {
                // a multiple choice field included in path will have the first value in path, and the rest in query params
                return encodeFilter(field, fieldValue.slice(1));
            }
            return encodeFilter(field, fieldValue);
        })
        .filter((filter) => !!filter)
        .sort()
        .join(',');

export default encodeFilters;
