import isNil from 'lodash/isNil';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import {
    FilterCollection,
    RefinementFilter,
    ExactFilter,
} from '@sector-labs/fe-search-redux/filters';

import fetchListingsFromFilters from './fetchListingsFromFilters';

/**
 * Fetches listings from configured backend.
 */
const fetchListingsFromSearchIndex = (
    configuredBackend: SearchBackend,
    indexName: string,
    attribute: string,
    uniqueAttributes: Array<string>,
) => {
    if (!uniqueAttributes || uniqueAttributes.length === 0) {
        return Promise.resolve([]);
    }

    const filters = new FilterCollection();
    filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
    filters.refine(new RefinementFilter({ attribute, value: uniqueAttributes }));

    return fetchListingsFromFilters(
        configuredBackend,
        filters,
        indexName,
        uniqueAttributes.length,
    ).then((fetchResult) => {
        const result: Array<any> = [];

        uniqueAttributes.forEach((uniqueAttribute) => {
            if (isNil(uniqueAttribute)) {
                return;
            }

            // @ts-expect-error - TS2339 - Property 'hits' does not exist on type 'void | SearchResponse<SearchResponseHit>'. | TS7006 - Parameter 'hit' implicitly has an 'any' type.
            fetchResult.hits.forEach((hit) => {
                if (uniqueAttribute.toString() === hit[attribute]) {
                    result.push(hit);
                }
            });
        });

        return result;
    });
};

export default fetchListingsFromSearchIndex;
