import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import FetcherFactory from 'strat/fetcher';
import { determineFrequentSearchQueriesIndexName } from 'strat/search/indexNames';

type Params = {
    readonly language: string;
    readonly categoryExternalID: string | null | undefined;
    readonly categorySlug: string | null | undefined;
};

const factory = new FetcherFactory(
    ['frequentSearchQueries'],
    ({ language, categoryExternalID, categorySlug }: Params, state: any) => {
        const filters = new FilterCollection();
        filters.refine(
            new ExactFilter({
                attribute: 'language',
                value: language,
            }),
        );

        if (categoryExternalID) {
            filters.refine(
                new ExactFilter({
                    attribute: 'category.externalID',
                    value: categoryExternalID,
                }),
            );
        }

        if (categorySlug) {
            filters.refine(
                new ExactFilter({
                    attribute: 'category.slug',
                    value: categorySlug,
                }),
            );
        }

        const indexName = determineFrequentSearchQueriesIndexName({ language });

        const job = new SearchJob(indexName, filters, {
            exhaustiveNbHits: false,
            hitsPerPage: 10,
        });

        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend: selectActiveSearchBackend(state) });
        return service.fetchJob(job).then((response) => ({
            status: 200,
            data: response.hits.map((hit) => ({
                // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                freeTextQuery: hit.query.input,
                extraFields: hit.extraFields || {},
            })),
        }));
    },
    {
        catchServerErrors: true,
    },
);

const fetchFrequentSearchQueries = factory.creator();

const frequentSearchQueriesReducer = factory.reducer();

export { fetchFrequentSearchQueries };

export default frequentSearchQueriesReducer;
