import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import styles from './styles/flashMessage.cssm';

type Props = Readonly<{
    message: string | undefined;
    isError?: boolean;
}>;

const FlashMessage = ({ message, isError }: Props) => {
    if (!message) {
        return null;
    }

    return (
        <Flex className={classNames(styles.root, isError ? styles.errorMsg : styles.successMsg)}>
            <Text.Regular>{message}</Text.Regular>
        </Flex>
    );
};

export default FlashMessage;
