import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

const useLearnMoreDialogReasons = () => {
    const i18n = useI18n();

    return React.useMemo(
        () => [
            t(i18n)`The content you are selling is prohibited`,
            t(i18n)`Title or description is inappropriate`,
            t(i18n)`Photos are unclear or objectionable`,
            t(i18n)`Price set it too high or too low`,
            t(i18n)`This ad is considered a duplicate`,
        ],
        [i18n],
    );
};

export default useLearnMoreDialogReasons;
