import { ProductPurchase } from 'horizontal/types';
import useConfigurePackagesSections from 'horizontal/packages/useConfigurePackagesSections';

import { PackagesSection, PackagesFlowTypeValues } from './types';

const removeFullyUsedProductPurchaseFromPackagesSectionItems = (
    section: PackagesSection<ProductPurchase>,
): PackagesSection<ProductPurchase> | null => {
    const productPurchasesWithAvailableQuota = section.items.filter((item) => item.available);
    if (productPurchasesWithAvailableQuota.length) {
        return {
            ...section,
            items: productPurchasesWithAvailableQuota,
        };
    } else {
        return null;
    }
};

const useConfigureProductPurchasesSections = (
    productPurchases: Array<ProductPurchase> | null | undefined,
    flow: PackagesFlowTypeValues,
): Array<PackagesSection<ProductPurchase>> | null => {
    const sections = useConfigurePackagesSections(
        productPurchases,
        flow,
        (productPurchase) => productPurchase.productPackageOffer.productPackage,
    );

    return (
        sections
            ?.map(removeFullyUsedProductPurchaseFromPackagesSectionItems)
            ?.filter((section): section is PackagesSection<ProductPurchase> => Boolean(section)) ??
        null
    );
};

export default useConfigureProductPurchasesSections;
