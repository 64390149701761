import * as React from 'react';

import { HTTPApi } from 'strat/util';
import type { CategoryNodeData, LocationData } from 'strat/property';
import type { Geoloc } from 'strat/map/types';
import Purpose from 'strat/purpose';

type RecommendParams = {
    readonly clientID: string | null | undefined;
    readonly userEmail: string | null | undefined;
    readonly sessionID: string;
};

type BaseInteractionParams = {
    readonly clientID: string | null | undefined;
    readonly userEmail: string | null | undefined;
    readonly sessionID: string | null | undefined;
    readonly userHash?: string | null | undefined;
};

type PropertyInteractionParams = BaseInteractionParams & {
    readonly id: number;
    readonly externalID: string;
    readonly location: LocationData;
    readonly category: Array<CategoryNodeData>;
    readonly area: number;
    readonly price: number;
    readonly rooms: number;
    readonly baths: number;
    readonly rentFrequency: string | null | undefined;
    readonly geography: Geoloc | null | undefined;
    readonly purpose: Values<typeof Purpose>;
    readonly count?: number | null | undefined;
    readonly isLead?: boolean;
};

type SearchInteractionParams = BaseInteractionParams & {
    readonly purpose: Values<typeof Purpose>;
    readonly area?: {
        min?: number | null;
        max?: number | null;
    };
    readonly price?: {
        min?: number | null;
        max?: number | null;
    };
    readonly beds?: Array<number>;
    readonly baths?: Array<number>;
    readonly rentFrequency?: string | null;
    readonly locationID?: string | null;
    readonly categoryID?: string | null;
};

/**
 * Provides access to the Machine Learning API
 */
class RecommenderBackEndAPI extends HTTPApi {
    /**
     * Turns the specified relative path into a absolute URL.
     * @param {string} path The path to turn into an absolute URl.
     * @returns {string} An absoluate URL to the specified relative path.
     */
    static buildAbsoluteUrl(path: string) {
        if (process.env.IS_SERVER) {
            const baseURL = CONFIG.build.RECOMMENDER_BACKEND_URL;
            return `${baseURL}${path}`;
        }

        return path;
    }

    /**
     * Makes a request to the recommender at the specified URL.
     */
    post(path: string, body: Record<any, any>): Promise<any> {
        // @ts-expect-error - TS2339 - Property 'buildAbsoluteUrl' does not exist on type 'Function'.
        const url = this.constructor.buildAbsoluteUrl(path);
        return super.request(url, null, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        });
    }

    get(path: string, parameters: Record<any, any>): Promise<any> {
        // @ts-expect-error - TS2339 - Property 'buildAbsoluteUrl' does not exist on type 'Function'.
        const url = this.constructor.buildAbsoluteUrl(path);
        return super.request(url, parameters);
    }

    trackSearch(params: SearchInteractionParams) {
        return this.post('/api/tracker/search_interaction/', params);
    }

    trackPropertyInteraction(params: PropertyInteractionParams) {
        return this.post('/api/tracker/property_interaction/', params);
    }

    trackVisitAndFetchRecommendations(params: PropertyInteractionParams) {
        return this.post('/api/v1/recommender/store_and_fetch/', params);
    }

    temporalRecommenderFallback(params: RecommendParams) {
        return this.get('/api/v1/recommender/temporal_recommender_fallback/', params);
    }

    fuzzyRecommendations(params: RecommendParams) {
        return this.get('/api/v1/recommender/fuzzy_recommender/', {
            ...params,
            count: CONFIG.build.FUZZY_RECOMMENDATIONS_COUNT,
        });
    }

    leadInsights(params: RecommendParams) {
        return this.post('/api/v1/recommender/lead_insights/', params);
    }
}

const ifRecommenderEnabled = (recommenderComponent: React.Component<any>) =>
    CONFIG.build.ENABLE_RECOMMENDER ? recommenderComponent : () => null;

export default RecommenderBackEndAPI;
export { ifRecommenderEnabled };
