import EMPTY_ARRAY from 'strat/empty/array';

import type { Agency } from 'horizontal/types';

type GlobalState = {
    featuredAgencies: {
        loading: boolean;
        data: Array<Agency> | null | undefined;
    };
};

const selectFeaturedAgencies = (state: GlobalState) => state.featuredAgencies.data || EMPTY_ARRAY;

export { selectFeaturedAgencies };
