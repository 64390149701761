import invariant from 'invariant';

import type { RoutingContextWithMiddlewares } from 'strat/app';

import Route from './route';
import RouteMatch from './routeMatch';
import RouteConfig from './routeConfig';
import RoutingContext from './routingContext';
import type { RoutingMiddleware } from './routingMiddleware';
import type { RouteParameters } from './route';
import type { URLProcessor } from './urlProcessor';

/**
 * Mapping middlewares to names.
 */
export type RoutingMiddlewareList = {
    [key: string]: RoutingMiddleware;
};

/**
 * Dumb router.
 */
class Router {
    /**
     * List of routes to match incoming requests against.
     */
    routes: RouteConfig;

    /**
     * Middlewares to call during routing.
     */
    middlewares: RoutingMiddlewareList;

    /**
     * Context passed to routes and describing
     * the current state of the router.
     */
    context: null | RoutingContext | RoutingContextWithMiddlewares;

    /**
     * URL processor
     */
    urlProcessor: null | URLProcessor;

    /**
     * Initializes a new instance of {@see Router}.
     * @param routes List of routes to match incoming requests against.
     * @param middlewares Middlewares to call during routing.
     * get context to pass to the route handlers.
     */
    constructor(
        routes: RouteConfig,
        middlewares: RoutingMiddlewareList,
        urlProcessor: null | URLProcessor = null,
    ) {
        this.routes = routes;
        this.middlewares = middlewares;
        this.urlProcessor = urlProcessor;

        this.context = null;
    }

    /**
     * Compute the URL for a route, including post processing.
     * Useful because the router can provide generic URL pre/post processing
     * which is called through this method, see the urlProcessor parameter
     * @param routeName the name of the route
     * @param params the parameters to pass to the route
     */
    getRouteURL(routeName: string, params: null | RouteParameters = null): string {
        const route: null | Route = this.routes.getRoute(routeName);
        invariant(route, `${routeName} is not a configured route`);
        // @ts-expect-error - TS2322 - Type 'EnhancedLocation' is not assignable to type 'string'.
        let url: string = route.createURL(params || {}, this.context);
        if (this.urlProcessor) {
            url = this.urlProcessor.outbound(url);
        }
        return url;
    }

    /**
     * Creates a new routing context by matching the specified URL to
     * one of the available routes.
     * @param url The URL to perform routing/matching for.
     * @returns {@see RoutingContext} describing the current routing context.
     */
    createContext(url: string): RoutingContext | RoutingContextWithMiddlewares {
        let processedURL = url;
        if (this.urlProcessor) {
            processedURL = this.urlProcessor.inbound(url);
        }

        const match: null | RouteMatch = this.routes.match(processedURL);
        invariant(
            match,
            `Could not find a route that matches.
             Define a route that always matches and
             put it at the bottom of the route config.`,
        );

        if (match) {
            match.originalURL = url;
        }

        const context = new RoutingContext(this, match, this.urlProcessor);

        if (process.env.IS_BROWSER && match && match.route.processClientParams) {
            match.route.processClientParams(match.params, context);
        }

        Object.keys(this.middlewares).forEach((name: string) => {
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RoutingContext'.
            context[name] = this.middlewares[name](context);
        });

        if (match && match.route.processParams) {
            match.route.processParams(match.params, context);
        }

        return context;
    }

    /**
     * Hydrates the routing context from the current URL.
     *
     * This is to be used when using SSR. The router runs on the server and the
     * routing context can be hydrated without re-running the router.
     */
    hydrateContext(): RoutingContext | RoutingContextWithMiddlewares {
        const url = window.location.pathname + window.location.search;
        this.context = this.createContext(url);

        return this.context;
    }

    /**
     * Hydrates the route state for the current URL by running
     * any client-side handlers.
     *
     * This is to be used when using SSR. The router runs on the server
     * and the route might have client-side only handlers that need to
     * run after the app was rendered.
     */
    hydrateRoute(): void {
        if (!this.context) {
            return;
        }

        if (!this.context.match?.route?.onClientEnter) {
            return;
        }

        this.context.match.route.onClientEnter(this.context);
    }

    /**
     * Routes the specified URL to a configured route.
     * @param url The URL to perform the routing for.
     * @returns {@see RoutingContext | RoutingContextWithMiddlewares }
     * the current routing context.
     */
    route(url: string): RoutingContext | RoutingContextWithMiddlewares {
        const newContext = this.createContext(url);

        if (this.context && this.context.match.route.onLeave) {
            this.context.match.route.onLeave(this.context, newContext);
        }

        this.context = newContext;

        if (this.context.match.route.onEnter) {
            this.context.match.route.onEnter(this.context);
        }

        if (!process.env.IS_SERVER && this.context.match.route.onClientEnter) {
            this.context.match.route.onClientEnter(this.context);
        }

        return this.context;
    }
}

export default Router;
