import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text, Toggle, LoadingSpinner } from 'strat/components';

import styles from './styles/userSettingsToggle.cssm';

type Props = {
    readonly title: string;
    readonly subtitle?: string;
    readonly showErrorMessage?: boolean | null | undefined;
    readonly isLoading?: boolean;
    readonly value?: any;
    readonly onClick: () => void;
};

const SettingsToggle = ({
    showErrorMessage,
    isLoading,
    value,
    onClick,
    title,
    subtitle,
}: Props) => (
    <Flex stretchWidth className={styles.container}>
        <Flex column stretchWidth>
            <Text.Base className={styles.title}>{title}</Text.Base>
            <Text.Base className={styles.subtitle}>{subtitle}</Text.Base>
            {showErrorMessage && (
                <Text.Small error>
                    <Trans>Something went wrong. Please try again.</Trans>
                </Text.Small>
            )}
        </Flex>
        <div className={styles.toggleWrapper}>
            {isLoading ? <LoadingSpinner dark /> : <Toggle checked={!!value} onClick={onClick} />}
        </div>
    </Flex>
);

export default SettingsToggle;
