import { t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { PercentageProjectPaymentPlan } from 'strat/project/types';

import ordinalNumber from './ordinalNumber';

export type PercentagePaymentPlanMilestone = {
    title: string;
    valuePercentage: number;
    projectCompletion?: number | null;
};

const usePercentagePaymentTimeline = (
    paymentPlan: PercentageProjectPaymentPlan,
): Array<PercentagePaymentPlanMilestone> => {
    const i18n = useI18n();
    const timeline = [];

    const downPayment = paymentPlan.downPayment;
    if (downPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`Down Payment`,
            valuePercentage: downPayment.valuePercentage,
            projectCompletion: downPayment.completionPercentage,
        });
    }

    paymentPlan.installments?.forEach((installment, index) => {
        if (installment.valuePercentage) {
            timeline.push({
                title: t(i18n)`${ordinalNumber(index + 1)} Installment`,
                valuePercentage: installment.valuePercentage,
                projectCompletion: installment.completionPercentage,
            });
        }
    });

    const handoverPayment = paymentPlan.handoverPayment;
    if (handoverPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`On Handover`,
            valuePercentage: handoverPayment.valuePercentage,
            projectCompletion: handoverPayment.completionPercentage,
        });
    }

    const postHandoverPayment = paymentPlan.postHandoverPayment;
    if (postHandoverPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`Post Handover`,
            valuePercentage: postHandoverPayment.valuePercentage,
            projectCompletion: postHandoverPayment.completionPercentage,
        });
    }

    return timeline;
};

export default usePercentagePaymentTimeline;
