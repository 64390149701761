import Actions from './actions';
import type { SetLanguageAction } from './actions';

/**
 * Active language state.
 */
export type LanguageState = null | string;

const defaultLanguageState: LanguageState = CONFIG.build.LANGUAGE_CODE || null;

const languageReducer = (
    state: LanguageState | null = defaultLanguageState,
    action: SetLanguageAction,
): LanguageState => {
    switch (action.type) {
        case Actions.SET_LANGUAGE:
            return action.language ? action.language : state;

        default:
            return state;
    }
};

/**
 * Sets the specified language as the active language.
 */
const setLanguage = (language: string) => ({
    type: Actions.SET_LANGUAGE,
    language,
});

export { setLanguage };

export default languageReducer;
