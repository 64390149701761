import EMPTY_ARRAY from 'strat/empty/array';

import { isDefined } from 'horizontal/util';
import type { MixedAd, AdBadge, Ad, AdAgent } from 'horizontal/types';
import { AdBadges, AdDocumentType, ContactPersonRole, CustomTagsValues } from 'horizontal/types';

const isEligibleForDelivery = (ad?: MixedAd | null): boolean => {
    if (ad?.format === 'lite') {
        return ad?.extraFields?.deliverable?.toLowerCase() === CustomTagsValues.YES;
    } else if (ad?.format === 'full') {
        return !!ad.deliveryInformation;
    }
    return false;
};

const selectDocumentBadges = (ad?: MixedAd | null) => {
    const documentsInfo = ad?.documents || ad?.documentsTags;
    // old ads which had documentCount & a documents object without tag property will be all
    // considered as of verified type. `ad?.documentsTags` is for ads that are from ElasticSearch
    // in the Listings Page. `ad?.documents` is for Ad Details page that is coming from our backend
    // database directly
    if (documentsInfo) {
        if ((CONFIG.runtime.STRAT_ENABLE_AD_DOCUMENT_BUTTONS && ad?.documentCount) || 0 > 1) {
            return [AdBadges.docs_attached];
        }
        return documentsInfo.map((document) => {
            // @ts-expect-error - TS2339 - Property 'tag' does not exist on type 'string | AdDocument'.
            const tag = document?.tag || document;
            switch (tag) {
                case AdDocumentType.VERIFIED:
                    return AdBadges.verified;
                case AdDocumentType.MANAGED:
                    return AdBadges.managed;
                case AdDocumentType.DOCUMENT:
                    return AdBadges.document;
                default:
                    return AdBadges.verified;
            }
        });
    }
    return [AdBadges.verified];
};

const selectAdBadges = (
    ad?: MixedAd | null,
    limit?: number | null,
    isAgency?: boolean | null,
    showVerifiedProfileBadge?: boolean | null,
): AdBadge[] => {
    if (!ad) {
        return EMPTY_ARRAY as AdBadge[];
    }

    const showDeliveryVerified = ad.contactInfo?.roles?.includes(
        ContactPersonRole.SHOW_BUY_WITH_DELIVERY_ON_SEARCH_PAGE,
    );
    const showVerifiedProfile = showVerifiedProfileBadge && ad?.isSellerVerified;

    const badges: AdBadge[] = [
        showVerifiedProfile && !isAgency ? AdBadges.seller_verified : null,
        showVerifiedProfile && isAgency ? AdBadges.agency_verified : null,
        ad?.panoramaCount ? AdBadges.panorama : null,
        ad?.videoCount ? AdBadges.video : null,
        showDeliveryVerified ? AdBadges.delivery_verified : null,
        !showDeliveryVerified && isEligibleForDelivery(ad) ? AdBadges.delivery : null,
        ...(ad?.documentCount ? selectDocumentBadges(ad) : []),
    ]
        .filter(isDefined)
        .sort((badge) => badge.order);

    return limit ? badges.slice(0, limit) : badges;
};

const isAdAgent = (ad?: Ad | null, userExternalID?: string | null): boolean => {
    if (ad?.agents) {
        return (
            // eslint-disable-next-line camelcase
            ad.agents.find((adAgent: AdAgent) => adAgent.user_external_id === userExternalID) !==
            undefined
        );
    }
    return false;
};

const isAdEditor = (ad?: Ad | null, userExternalID?: string | null): boolean => {
    return (
        (ad?.userExternalID && ad?.userExternalID === userExternalID) ||
        isAdAgent(ad, userExternalID)
    );
};

const doesAdContainVideoBadge = (ad?: MixedAd | null): boolean => {
    return !!ad?.videoCount;
};

export {
    selectAdBadges,
    isEligibleForDelivery,
    isAdEditor,
    isAdAgent,
    isDefined,
    doesAdContainVideoBadge,
};
