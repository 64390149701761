import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';
import Category from '@app/branding/category';

const useIsOfCarsForSaleType = (): boolean => {
    const { categorySlugWithOptionalPrefix } = useSelector(selectRouteParams);

    // categorySlugWithOptionalPrefix in pk may have these values: 'cars', 'carBrand-cars'
    return (
        !!Category.isOfType(categorySlugWithOptionalPrefix, Category.CARS) ||
        categorySlugWithOptionalPrefix?.split('-').pop() === Category.CARS
    );
};

export default useIsOfCarsForSaleType;
