import { FilterCollection, RefinementFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import {
    SearchBackend,
    SearchRequestSortType,
    SearchRequestOrder,
} from '@sector-labs/fe-search-redux/backend';
import { SearchJobOptions } from '@sector-labs/fe-search-redux/searchJobOptions';
import { determineLocationsIndexName } from 'strat/search/indexNames';

import type { Location } from 'horizontal/types';

const fetchMultipleLocationsByExternalID = (
    value: Array<string>,
    language: string,
    backend: SearchBackend,
    hitsPerPage: number,
): Promise<Array<Location>> => {
    const filters = new FilterCollection();
    const sortRequest: SearchJobOptions['sort'] = {
        type: SearchRequestSortType.ATTRIBUTES,
        key: 'alphabetical',
        attributes: [{ name: 'level', order: SearchRequestOrder.ASC }],
    };

    filters.refine(
        new RefinementFilter({
            attribute: 'externalID',
            value,
        }),
    );

    const locations = determineLocationsIndexName({ language });
    const searchJob = new SearchJob(locations, filters, {
        exhaustiveNbHits: false,
        hitsPerPage,
        sort: sortRequest,
    });

    const service = new SearchService({ backend });
    return service
        .fetchJob(searchJob)
        .then((response) => response.hits as unknown as Array<Location>);
};

export default fetchMultipleLocationsByExternalID;
