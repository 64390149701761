//@ts-nocheck
import { clear, setContent } from '@sector-labs/fe-search-redux/state';
import settings from '@app/branding/settings';

import processHomePageParams from '@app/routes/processHomePageParams';
import Page from 'strat/pages/page';
import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RouteParameters } from 'react-true-router/route';
import { clearInternalLinks } from 'strat/search/internalLinks/state';
import { clearPopularSearches } from 'strat/seo/popularSearches/state';
import { resetPageInfo } from 'strat/seo/pageInfo/state';
import { resetPageParameters } from 'strat/search/state';
import { setActiveClusterId, setMapPosition, setMapView } from 'strat/search/state/mapBasedSearch';
import { fetchRecentBlogPosts } from 'strat/home/recentBlogs/state';
import { fetchTruBrokersHomePage } from 'strat/leaderboard/state/truBrokersHomepage';
import { fetchWallpaper } from 'strat/wallpaper';

import RouteNames from './routeNames';

class HomeRoute extends Route {
    constructor() {
        super(RouteNames.HOME, [['^/(?:\\?.*)?$'], ['^/(?:(#.*))?$']]);
    }

    createURL(_params: RouteParameters): EnhancedLocation {
        return { pathname: '/', hash: {} };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const { dispatch, getState } = context.redux.store;

        processHomePageParams(context);

        dispatch(clear());
        dispatch(setContent(null));
        dispatch(clearInternalLinks());
        dispatch(clearPopularSearches());
        dispatch(resetPageInfo());
        dispatch(resetPageParameters());
        dispatch(setActiveClusterId(null, null));
        dispatch(setMapPosition(null));
        dispatch(setMapView(null));

        if (CONFIG.build.STRAT_ENABLE_LEADERBOARD) {
            // Dispatch trubroker preview to state for server side rendering
            const truBrokersPromise = dispatch(fetchTruBrokersHomePage());
            context.promise.wait(truBrokersPromise);
        }

        if (settings.enableRecentBlogPosts) {
            const recentBlogPostsPromise = dispatch(fetchRecentBlogPosts());
            context.promise.wait(recentBlogPostsPromise);
        }

        context.promise.wait(fetchWallpaper(getState(), dispatch));
        context.rendering.renderPage(Page.HOME);
    }
}

export default new HomeRoute();
