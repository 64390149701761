import { t } from '@lingui/macro';
import * as React from 'react';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';

import { UserSettingsKey } from '../types';

import UserSettingsToggle from './userSettingsToggle';

const RecommendationsNotificationsControl = () => {
    const i18n = useI18n();

    const trackRecommendationsNotificationsToggle = (isSelected: boolean) => {
        trigger(Triggers.STOP_RECOMMEND, {
            filter_name: 'hint-toggle',
            filter_value: isSelected ? 'on' : 'off',
            view_section: ViewSections.SETTINGS_NOTIFICATIONS,
        });
    };

    return (
        <UserSettingsToggle
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'keyof UserSettings'.
            settingsKey={UserSettingsKey.RECOMMENDATIONS_NOTIFICATIONS_ACTIVE}
            title={t(i18n)`Recommendations`}
            subtitle={t(i18n)`Receive recommendations based on your activity`}
            trackToggle={trackRecommendationsNotificationsToggle}
        />
    );
};

export default RecommendationsNotificationsControl;
