import { createSelector } from 'reselect';
import EMPTY_OBJECT from 'strat/empty/object';
import EMPTY_ARRAY from 'strat/empty/array';

import type { GlobalState } from 'horizontal/state';
import type { CategoryFieldCombination } from 'horizontal/types';

import type { CategoryFieldCombinationsState } from './state';

const selectCategoryFieldCombinationCollection = createSelector(
    (state: GlobalState) => state.categoryFieldCombinations,
    (categoryFieldCombinations) =>
        categoryFieldCombinations ?? (EMPTY_OBJECT as Partial<CategoryFieldCombinationsState>),
);

const selectCategoryFieldCombinations = createSelector(
    selectCategoryFieldCombinationCollection,
    (_: CategoryFieldCombinationsState, categoryID?: number | null) => categoryID,
    (categoryFieldCombinations: CategoryFieldCombinationsState, categoryID) =>
        categoryID && categoryFieldCombinations?.[categoryID]?.data
            ? categoryFieldCombinations?.[categoryID]?.data
            : (EMPTY_ARRAY as CategoryFieldCombination[]),
);

export { selectCategoryFieldCombinationCollection, selectCategoryFieldCombinations };
