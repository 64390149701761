import * as React from 'react';

type Props<T> = T & {
    readonly onDragStart?: (event: React.DragEvent<any>, ref: React.ElementRef<any>) => void;
    readonly onDragLeave?: (event: React.DragEvent<any>, ref: React.ElementRef<any>) => void;
    readonly onDragOver?: (event: React.DragEvent<any>, ref: React.ElementRef<any>) => void;
    readonly onDrop?: (event: React.DragEvent<any>, ref: React.ElementRef<any>) => void;
};

const Draggable = <T extends any>({
    onDragStart,
    onDragLeave,
    onDragOver,
    onDrop,
    // @ts-expect-error - TS2339 - Property 'children' does not exist on type '{ readonly onDragStart?: ((event: DragEvent<any>, ref: unknown) => void) | undefined; readonly onDragLeave?: ((event: DragEvent<any>, ref: unknown) => void) | undefined; readonly onDragOver?: ((event: DragEvent<...>, ref: unknown) => void) | undefined; readonly onDrop?: ((event: DragEvent<...>, ref: unknown) => void...'.
    children,
    ...otherProps
}: Props<T>) => {
    const ref = React.useRef(null);

    const handleDragStart = React.useCallback(
        (event) => {
            if (!onDragStart) {
                return;
            }

            onDragStart(event, ref.current);
        },
        [onDragStart],
    );

    const handleDragLeave = React.useCallback(
        (event) => {
            if (!onDragLeave) {
                return;
            }

            onDragLeave(event, ref.current);
        },
        [onDragLeave],
    );

    const handleDragOver = React.useCallback(
        (event) => {
            if (!onDragOver) {
                return;
            }

            onDragOver(event, ref.current);
        },
        [onDragOver],
    );

    const handleDrop = React.useCallback(
        (event) => {
            if (!onDrop) {
                return;
            }

            onDrop(event, ref.current);
        },
        [onDrop],
    );
    return (
        <div
            {...otherProps}
            ref={ref}
            onDrop={handleDrop}
            onDragStart={handleDragStart}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
        >
            {children}
        </div>
    );
};

export default Draggable;
