import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRouter } from 'react-true-router';
import useHeaderLinks from '@app/header/compact/useHeaderLinks';
import { selectRouteName } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';

import HeaderIconLink from 'horizontal/header/headerIconLink';
import { useTrackClickVertical } from 'horizontal/gtm';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { useAd } from 'horizontal/ad/state';

import shouldHighlightVertical from '../shouldHighlightVertical';

import styles from './styles/headerLinks.cssm';

const HeaderLinks = ({ withHighlightedVertical }: { withHighlightedVertical?: boolean }) => {
    const headerLinks = useHeaderLinks();
    const trackClickVertical = useTrackClickVertical();
    const categoryFilterValue = useCategoryFilterValue();
    const ad = useAd();
    const router = useRouter();
    const routeName = useSelector(selectRouteName);
    const category =
        routeName === RouteNames.SEARCH ? categoryFilterValue : ad?.category?.slice(-1)[0];

    return (
        <>
            {headerLinks.map((headerLink, index) => {
                const isHighlighted = shouldHighlightVertical(
                    headerLink,
                    withHighlightedVertical,
                    category,
                    // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2345 - Argument of type '[string]' is not assignable to parameter of type 'string'.
                    router.context.match.params.vertical,
                );
                return (
                    headerLink.renderIcon && (
                        <HeaderIconLink
                            renderIcon={headerLink.renderIcon}
                            // @ts-expect-error - TS2322 - Type 'string | boolean | null | undefined' is not assignable to type 'boolean'.
                            isHighlighted={isHighlighted}
                            link={headerLink.link}
                            key={`headerlink-${headerLink.name || index}`}
                            linkClassName={classNames(styles.link, {
                                [styles.highlight]: isHighlighted,
                            })}
                            onClick={() => trackClickVertical(headerLink.name)}
                        />
                    )
                );
            })}
        </>
    );
};

export default HeaderLinks;
