import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';
import { selectLayout } from 'strat/layout/selectors';
import Layout from 'strat/layout';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';

import getFeaturesTitle from '@app/adDetails/getFeaturesTitle';
import { DialogNames } from 'horizontal/dialogs';
import { AdFormattedExtraField } from 'horizontal/ad';

import { SectionTitle } from '../section';

import styles from './styles/features.cssm';

type FeatureProp = {
    feature: string;
    isHidden?: boolean;
};

const Feature = ({ feature, isHidden = false }: FeatureProp) => {
    return (
        <span
            className={classNames({
                [styles.listItemValue]: !isHidden,
                [styles.hiddenListItemValue]: isHidden,
            })}
        >
            {feature}
        </span>
    );
};

type Props = {
    readonly formattedExtraField: AdFormattedExtraField;
    readonly isProperty: boolean;
};

const Features = ({ formattedExtraField, isProperty }: Props) => {
    const i18n = useI18n();
    const title = isProperty ? getFeaturesTitle(i18n) : t(i18n)`Features`;
    const layout = useSelector(selectLayout);
    const isMobile = layout === Layout.MOBILE;
    const maxDisplayedItemsCount = isMobile ? 5 : 6;

    const [, setExtraFeaturesDialogVisible] = useDialog(DialogNames.AD_DETAILS_FEATURES_DIALOG);
    const onExtraFeaturesClick = React.useCallback(() => {
        setExtraFeaturesDialogVisible(true, { formattedExtraField, title });
    }, [setExtraFeaturesDialogVisible, formattedExtraField, title]);

    const entries = React.useMemo(() => {
        let featuresCount = 0;
        const mainFeatures: Array<React.ReactElement> = [];
        /**
         * This is needed because we don't want to affect our SEO, so
         * what we will do is put the rest of the features hidden in the
         * HTML of the page.
         */
        const hiddenFeatures: Array<React.ReactElement> = [];

        if (Array.isArray(formattedExtraField.formattedValue)) {
            const formattedValueClone = (
                formattedExtraField.formattedValue as (string | CategoryFieldGroupedChoices)[]
            ).filter((feature) => !!feature);
            const firstItem = formattedValueClone.shift();
            /**
             * we have two type of `Features`, either ones that are grouped
             * under one type OR the string features that are simply strings
             * that are not under any grouping.
             */
            if (typeof firstItem === 'object') {
                formattedExtraField.formattedValue.forEach((groupedFeatures: any) => {
                    groupedFeatures?.choices?.all?.forEach((choice: any) => {
                        if (featuresCount > maxDisplayedItemsCount - 1) {
                            hiddenFeatures.push(
                                <Feature feature={choice?.label} isHidden key={choice?.value} />,
                            );
                            return;
                        }
                        featuresCount = featuresCount + 1;
                        mainFeatures.push(<Feature feature={choice?.label} key={choice?.value} />);
                    });
                });
            } else if (typeof firstItem === 'string') {
                formattedExtraField.formattedValue.forEach((feature: any) => {
                    if (featuresCount > maxDisplayedItemsCount - 1) {
                        hiddenFeatures.push(<Feature feature={feature} isHidden key={feature} />);
                        return;
                    }
                    featuresCount = featuresCount + 1;
                    mainFeatures.push(<Feature feature={feature} key={feature} />);
                });
            }
        }

        return (
            <div key={formattedExtraField.attribute} className={styles.listItemsContainer}>
                {Array.isArray(formattedExtraField.formattedValue) ? (
                    <>
                        {mainFeatures}
                        {hiddenFeatures}
                        {featuresCount > maxDisplayedItemsCount - 1 ? (
                            <Flex
                                alignCenter
                                className={styles.buttonContainer}
                                onClick={onExtraFeaturesClick}
                            >
                                <Text.Large bold>{t(i18n)`See All`}</Text.Large>
                                <IconArrowRight className={styles.buttonIcon} />
                            </Flex>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }, [formattedExtraField, maxDisplayedItemsCount, i18n, onExtraFeaturesClick]);

    if (!formattedExtraField.formattedValue?.length) {
        return null;
    }

    return (
        <>
            <SectionTitle title={formattedExtraField.name} />
            <div className={styles.list}>{entries}</div>
        </>
    );
};

export default Features;
