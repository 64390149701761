import * as React from 'react';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import { SitemapNavbar, SitemapContent } from 'horizontal/sitemap';
import Footer from 'horizontal/footer';

import SitemapHead from './sitemapHead';

const SitemapPageDesktop = {
    head: () => <SitemapHead />,
    body: () => (
        <>
            {/* @ts-expect-error - TS2322 - Type '{ shouldSubmitOnLocationSelected: true; }' is not assignable to type 'IntrinsicAttributes & { withHighlightedVertical?: boolean | undefined; }'. */}
            <Header shouldSubmitOnLocationSelected />
            <CategoryDropdownSwitcher />
            <SitemapNavbar />
            <SitemapContent />
            <Footer />
        </>
    ),
} as const;

export default SitemapPageDesktop;
