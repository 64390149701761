import { SearchJob } from '@sector-labs/fe-search-redux';
// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import {
    ExclusionFilter,
    RangeFilter,
    QueryFilter,
    ObjectExactFilter,
    InsidePolygonFilter,
    FilterCollection,
    RefinementFilter,
} from '@sector-labs/fe-search-redux/filters';
import { determineAdsIndexName } from 'strat/search/indexNames';
import { FilterValues } from 'strat/search';
import polygon from 'strat/misc/polygon';
import Category from '@app/branding/category';

import { createEmptySlottingConfig } from 'horizontal/search/execution';
import AdUtils from 'horizontal/ad/utils';
import type { Ad, SearchSortOption } from 'horizontal/types';
import { AdExtraFieldsKeys } from 'horizontal/ad';

import createRelatedCarsSlottingConfig from './createRelatedCarsSlottingConfig';

const createRelatedAdsSearchSettings = (
    indexName: string,
    sortOption: SearchSortOption,
): SearchSettings | null | undefined => ({
    timeoutSeconds: CONFIG.runtime.STRAT_ES_REQUESTS_TIMEOUT_SECONDS,
    hitsPerPage: 20,
    activeSlotRule: 'default',
    sort: sortOption,
    exhaustiveNbHits: false,
    slotRules: createEmptySlottingConfig({ indexName, hitsPerSlot: [20] }),
});

const createRelatedCarsSearchSettings = (
    indexName: string,
    sortOption: SearchSortOption,
    carModel: string,
): SearchSettings | null | undefined => ({
    hitsPerPage: 20,
    activeSlotRule: 'default',
    sort: sortOption,
    exhaustiveNbHits: false,
    slotRules: createRelatedCarsSlottingConfig({ indexName, hitsPerSlot: [12, 8], carModel }),
});

const createRelatedAdsSearchJob = (
    ad: Ad,
    sortOption: SearchSortOption,
    language: string,
): [SearchJob[], FilterCollection[]] | [] => {
    const filters = new FilterCollection();
    const jobsFilters: FilterCollection[] = [filters];
    const price = ad.extraFields?.price;
    const adGeography = ad.geography ? [ad.geography.lat, ad.geography.lng] : null;
    if (!price || !adGeography) {
        return [];
    }

    const priceRange = { min: 0.7 * price, max: 1.3 * price } as const;
    // search range is 10km
    // @ts-expect-error - TS2345 - Argument of type 'number[]' is not assignable to parameter of type '[number, number]'.
    const rangePolygon = polygon(adGeography, adGeography, 0, 10 * 1000).flat();

    filters.refine(new ExclusionFilter({ attribute: 'externalID', value: ad.externalID }));
    filters.refine(new RangeFilter({ attribute: 'extraFields.price', value: priceRange }));
    filters.refine(
        new ObjectExactFilter({
            attribute: FilterValues.category.attribute,
            value: ad.category.slice(-1)[0],
        }),
    );
    filters.refine(
        new InsidePolygonFilter({
            attribute: FilterValues.locationCoordinates.attribute,
            value: rangePolygon,
        }),
    );

    const indexName = determineAdsIndexName({ language });
    const carModel = ad.extraFields?.model;
    if (Category.isOfCarsType(ad.category.slice(-1)[0]) && carModel) {
        const settings = createRelatedCarsSearchSettings(indexName, sortOption, carModel);
        const searchJobs = [new SearchJob(indexName, filters, settings)];
        if (!AdUtils.isAdInNewCondition(ad)) {
            const newCarsFilters = new FilterCollection(filters.serialize());
            newCarsFilters.refine(
                new RefinementFilter({
                    attribute: `extraFields.${AdExtraFieldsKeys.CONDITION}`,
                    value: ['1', 'new'],
                }),
            );

            jobsFilters.push(newCarsFilters);
            searchJobs.push(
                new SearchJob(
                    indexName,
                    newCarsFilters,
                    createRelatedAdsSearchSettings(indexName, sortOption),
                ),
            );
        }
        return [searchJobs, jobsFilters];
    }

    filters.refine(
        // @ts-expect-error - TS2322 - Type '"or"' is not assignable to type 'Operator | undefined'.
        new QueryFilter({ value: ad.title, fuzzy: false, fields: ['query'], operator: 'or' }),
    );

    const settings = createRelatedAdsSearchSettings(indexName, sortOption);
    return [[new SearchJob(indexName, filters, settings)], jobsFilters];
};

export default createRelatedAdsSearchJob;
