import React from 'react';
import type { I18n } from '@lingui/core';
import { useSelector } from 'react-redux';
import { selectUserExternalID } from 'strat/user/session';

import { PaymentSource } from '@app/payment/types';
import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';
import type { PaymentOrder } from 'horizontal/payment/types';
import useNavigateAfterPayment from 'horizontal/payment/hooks/useNavigateAfterPayment';

const useWalletPayment = (i18n: I18n, order: PaymentOrder, partialPaymentFlag: boolean) => {
    const makeCancelable = useMakeCancelable();
    const navigateAfterPayment = useNavigateAfterPayment();
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorStatus, setErrorStatus] = React.useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const alreadyPaidAmount = order.payments?.find(
        (payment) => payment.source == PaymentSource.WALLET,
    )?.total;
    const userExternalId = useSelector(selectUserExternalID);

    const handleSubmissionError = (status: Array<string>) => {
        setErrorStatus(status[0]);
        setIsSubmitting(false);
        setError(true);
        setDialogVisible(false);
        setLoading(false);
    };

    const pay = (onPartialPaymentSuccess?: () => void) => {
        if (userExternalId) {
            setLoading(true);
            setIsSubmitting(true);
            const cancelablePromise = makeCancelable(
                new StratAPI(i18n.locale).walletPay(userExternalId, {
                    orderExternalID: order?.externalID,
                }),
            );

            cancelablePromise.then(({ status, data }: { status: number; data: Array<string> }) => {
                if (status === 201) {
                    if (partialPaymentFlag && onPartialPaymentSuccess) {
                        onPartialPaymentSuccess();
                    } else {
                        navigateAfterPayment({ order });
                    }
                    setLoading(false);
                } else {
                    handleSubmissionError(data);
                }
            });
        } else {
            setErrorStatus('');
            setError(true);
            setDialogVisible(false);
        }
    };

    return {
        pay,
        dialogVisible,
        setDialogVisible,
        error,
        loading,
        errorStatus,
        isSubmitting,
        alreadyPaidAmount,
    };
};

export default useWalletPayment;
