import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';
import { ProductPackageOffer, PackageTypeValues, Ad } from 'horizontal/types';

import OfferPackageChoiceBox from './components/offerPackageChoiceBox';
import styles from './styles/packageOptionsContainer.cssm';
import { TextLink } from './components/packagesOffersPromo';

type Props = {
    ad?: Ad | null;
    choices: Array<ProductPackageOffer>;
    onOptionClicked: (choice: ProductPackageOffer) => void;
    selectedChoice: string | null | undefined;
    title: string;
    description: string;
    packageType: PackageTypeValues;
    openExample?: () => void;
};

const OfferPackageOptionsContainer = ({
    ad,
    onOptionClicked,
    choices,
    selectedChoice,
    title,
    packageType,
    description,
    openExample,
}: Props) => {
    const i18n = useI18n();
    const icon = usePackagesIcons({ type: packageType });

    if (!choices.length) {
        return null;
    }

    return (
        <Flex column className={styles.container}>
            <Flex className={styles.header}>
                <img src={icon} className={styles.icon} />
                <Flex column className={styles.text}>
                    <Text.Large bold>{title}</Text.Large>
                    <Text.Regular>{description}</Text.Regular>
                    {openExample && (
                        <TextLink
                            text={t(i18n)`See example`}
                            className={styles.link}
                            onClick={openExample}
                        />
                    )}
                </Flex>
            </Flex>

            <>
                {choices.map((choice) => (
                    <OfferPackageChoiceBox
                        ad={ad}
                        onClick={() => onOptionClicked(choice)}
                        key={choice.packageOfferID}
                        choice={choice}
                        selected={selectedChoice === choice.packageOfferID}
                    />
                ))}
            </>
        </Flex>
    );
};

export default OfferPackageOptionsContainer;
