import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const PanoramaTabName = () => <Trans>360° View</Trans>;

const panoramaFilterName = (i18n: I18n) => t(i18n)`360° tours`;

const panoramaCompactFilterName = (i18n: I18n) => t(i18n)`Must have 360° Tour`;

const PanoramaOverlayText = () => null;

const PanoramaHomePageCardTitle = () => <Trans>Discover immersive 360° property tours</Trans>;

export {
    PanoramaTabName,
    PanoramaOverlayText,
    panoramaFilterName,
    panoramaCompactFilterName,
    PanoramaHomePageCardTitle,
};
