import type { Primitive } from 'strat/types';

const PageType = Object.freeze({
    HOME: 'home',
    SEARCH: 'lpv',
    DETAIL: 'dpv',
});

const Position = Object.freeze({
    TOP: 'top',
    BOTTOM: 'bottom',
    MIDDLE: 'middle',
    MIDDLE_BOTTOM: 'middle-bottom',
});

const Sizes = Object.freeze({
    desktop: {
        home: {
            top: [
                { width: 970, height: 90 },
                { width: 728, height: 90 },
            ],
            bottom: [
                { width: 970, height: 250 },
                { width: 970, height: 90 },
                { width: 728, height: 90 },
            ],
        },
        search: {
            top: [
                { width: 970, height: 250 },
                { width: 970, height: 90 },
                { width: 728, height: 90 },
            ],
            middle: [
                'fluid',
                { width: 970, height: 250 },
                { width: 970, height: 90 },
                { width: 728, height: 90 },
            ],
            bottom: [{ width: 728, height: 90 }],
            wallpaper: [{ width: 1, height: 1 }],
        },
        details: {
            top: [
                { width: 970, height: 250 },
                { width: 970, height: 90 },
                { width: 728, height: 90 },
            ],
            bottom: [{ width: 300, height: 250 }],
            wallpaper: [{ width: 1, height: 1 }],
        },
    },
    mobile: {
        home: {
            top: [
                { width: 320, height: 100 },
                { width: 320, height: 50 },
            ],
            bottom: [{ width: 300, height: 250 }],
            wallpaper: [{ width: 1, height: 1 }],
        },
        search: {
            bottom: [
                { width: 300, height: 250 },
                { width: 320, height: 100 },
                { width: 320, height: 50 },
            ],
            middle: [
                'fluid',
                { width: 300, height: 250 },
                { width: 320, height: 100 },
                { width: 320, height: 50 },
            ],
            wallpaper: [{ width: 1, height: 1 }],
        },
        details: {
            top: [
                { width: 320, height: 100 },
                { width: 320, height: 50 },
            ],
            bottom: [
                { width: 300, height: 250 },
                { width: 320, height: 100 },
                { width: 320, height: 50 },
            ],
            wallpaper: [{ width: 1, height: 1 }],
        },
    },
});

type TargetingParamValue = Primitive | Array<Primitive>;

type CategoryTargetingParams = {
    readonly ad_unit: TargetingParamValue;
};

type LocationTargetingParams = {
    readonly state: TargetingParamValue;
    readonly city: TargetingParamValue;
    readonly neighbourhood: TargetingParamValue;
};

type CarCategoryTargetingParams = {
    readonly make?: TargetingParamValue;
    readonly model?: TargetingParamValue;
    readonly year?: TargetingParamValue;
    readonly mileage?: TargetingParamValue;
    readonly fuel?: TargetingParamValue;
    readonly condition?: TargetingParamValue;
};

type PropertyCategoryTargetingParams = {
    readonly beds?: TargetingParamValue;
    readonly baths?: TargetingParamValue;
    readonly area?: TargetingParamValue;
    readonly area_unit?: TargetingParamValue;
    readonly furnished?: TargetingParamValue;
};

type MobileCategoryTargetingParams = {
    readonly make?: TargetingParamValue;
};

type ExtraFieldsTargetingParams = CarCategoryTargetingParams &
    PropertyCategoryTargetingParams &
    MobileCategoryTargetingParams;

export { PageType, Position, Sizes };

export type {
    CategoryTargetingParams,
    ExtraFieldsTargetingParams,
    LocationTargetingParams,
    TargetingParamValue,
};
