import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';

import styles from './styles/checkedText.cssm';

type Props = {
    readonly text: string;
    readonly light?: boolean;
};

const CheckedText = ({ text, light }: Props) => (
    <Text.Base
        className={classNames(styles.text, {
            [styles.light]: light,
        })}
    >
        {text}
    </Text.Base>
);

export default CheckedText;
