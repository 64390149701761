import * as React from 'react';

import { Dialog } from 'strat/components';
import { DialogComponentProps } from 'strat/dialogs/types';

import InternalError from './internalError';
import styles from './styles/internalErrorDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly title?: string;
    readonly message?: string | null;
    readonly hideGoToHomeButton?: boolean;
};

const InternalErrorDialog = ({
    visible,
    onVisibilityChanged,
    title,
    message,
    hideGoToHomeButton,
}: DialogComponentProps<Props>) => (
    <Dialog visible={visible} onVisibilityChanged={onVisibilityChanged} dismissible withCloseButton>
        <div className={styles.container}>
            <InternalError
                title={title}
                message={message}
                hideGoToHomeButton={hideGoToHomeButton}
            />
        </div>
    </Dialog>
);

export default InternalErrorDialog;
