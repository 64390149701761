import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import { useWarningText } from '../intelliChat';

import styles from './styles/textMessage.cssm';
import WarningBanner from './warningBanner';

type Props = {
    readonly text: string;
    readonly className: string;
    readonly wrapperClassName: string;
    readonly renderFooter: () => React.ReactElement;
    readonly isFromOwner: boolean;
    readonly moderated?: boolean;
};

const TextMessage = ({
    text,
    className,
    wrapperClassName,
    moderated,
    renderFooter,
    isFromOwner,
}: Props) => {
    const [expandedWarningBanner, setExpandedWarningBanner] = React.useState(true);
    const warningText = useWarningText(text);
    return (
        <Flex column={expandedWarningBanner} alignCenter noShrink className={wrapperClassName}>
            <div className={classNames(styles.container, className)} aria-label="Text message">
                <pre className={classNames(styles.content, { [styles.moderatedText]: moderated })}>
                    {text}
                </pre>
                {renderFooter()}
            </div>
            {!isFromOwner && !!warningText && (
                <WarningBanner
                    warningText={warningText}
                    expanded={expandedWarningBanner}
                    setExpanded={setExpandedWarningBanner}
                />
            )}
        </Flex>
    );
};

export default React.memo<Props>(TextMessage);
