import * as React from 'react';
import omit from 'lodash/omit';

import { getItem, setItem } from 'strat/misc/localStorage';

const KEY = 'property-commute';

export const savedPropertyCommuteLocations = (externalID: string) => {
    // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
    const data = (getItem(KEY) || []).find((item) => item.externalID === externalID);
    if (!data) {
        return data;
    }

    return omit(data, 'externalID');
};

const useSavedPropertyCommuteLocations = (externalID: string) => {
    const [data, setData] = React.useState<any>(null);

    React.useEffect(() => {
        setData(savedPropertyCommuteLocations(externalID));
    }, [externalID]);

    return data;
};

export const savePropertyCommuteLocations = (externalID: string, data: any) => {
    const storedData = getItem(KEY) ?? [];

    // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
    const index = storedData.findIndex((item) => item.externalID === externalID);

    if (index !== -1) {
        storedData[index] = { ...data, externalID };
    } else {
        storedData.push({ ...data, externalID });
    }

    while (storedData.length > 20) {
        storedData.shift();
    }

    setItem(KEY, storedData);
};

export default useSavedPropertyCommuteLocations;
