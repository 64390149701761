import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Input } from 'strat/components';
import { useActiveUserEmail } from 'strat/user/session';
import { useIsProfolioUser } from 'strat/user';

import { EditEmailDialog, EmailConfirmationDialog } from 'horizontal/fields';

import styles from './styles/editProfileForm.cssm';

type Props = {
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly setSubmitting: (arg1: boolean) => void;
    readonly isSubmitting: boolean;
};

const EditProfileEmail = ({ errors, touched, handleBlur, values, setFieldValue }: Props) => {
    const i18n = useI18n();
    const userEmail = useActiveUserEmail();
    const [showEmailDialog, setShowEmailDialog] = React.useState(false);
    const [showEmailConfirmation, setShowEmailConfirmation] = React.useState(false);
    const [userConfirmedEmail, setUserConfirmedEmail] = React.useState(userEmail);
    const isReadOnly = useIsProfolioUser();

    React.useEffect(() => {
        if (userEmail !== userConfirmedEmail) {
            setUserConfirmedEmail(userEmail || '');
        }
    }, [userEmail, userConfirmedEmail]);

    React.useEffect(() => {
        if (!showEmailDialog && !showEmailConfirmation && values.email !== userConfirmedEmail) {
            setFieldValue('email', userConfirmedEmail);
        }
    }, [showEmailDialog, showEmailConfirmation, userConfirmedEmail, values.email, setFieldValue]);

    const onChange = isReadOnly ? undefined : (value: string) => setFieldValue('email', value);
    const onBlur = isReadOnly ? undefined : handleBlur;
    const onClick = isReadOnly ? undefined : () => setShowEmailDialog(true);

    return (
        <>
            <div className={styles.inputContainer}>
                <Input
                    type="text"
                    name={'email'}
                    placeholder={t(i18n)`Email`}
                    value={userConfirmedEmail || undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    readOnly={isReadOnly}
                    disabled={isReadOnly}
                    lightPlaceholder
                />
            </div>
            {!isReadOnly && (
                <>
                    <EditEmailDialog
                        touched={touched}
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                        showEmailDialog={showEmailDialog}
                        setShowEmailDialog={setShowEmailDialog}
                        userEmail={userConfirmedEmail}
                        setShowEmailConfirmation={setShowEmailConfirmation}
                    />
                    <EmailConfirmationDialog
                        showEmailConfirmation={showEmailConfirmation}
                        setShowEmailDialog={setShowEmailDialog}
                        setShowEmailConfirmation={setShowEmailConfirmation}
                        email={values.email}
                        setUserConfirmedEmail={setUserConfirmedEmail}
                    />
                </>
            )}
        </>
    );
};

export default EditProfileEmail;
