import type { RoutingContextWithMiddlewares } from 'strat/app';
import { selectUserRoles } from 'strat/user/session';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';

import Page from 'horizontal/pages/page';
import ensureHasActiveUser from 'horizontal/routes/ensureHasActiveUser';

const ensureCanAccessAgencyPortal = (
    context: RoutingContextWithMiddlewares,
    routeParams?: any,
): boolean => {
    if (!ensureHasActiveUser(context, routeParams)) {
        return false;
    }

    const userRoles = selectUserRoles(context.redux.store.getState());
    const canAccessAgencyPortal = CONFIG.build.ENABLE_STRAT_AGENCY_PORTAL_FOR_AGENT
        ? isProAgent(userRoles)
        : isProAgencyOwner(userRoles);
    if (!canAccessAgencyPortal) {
        context.rendering.renderPage(Page.NOT_FOUND);
        return false;
    }
    return true;
};

export const ensureCanAccessAgencyPortalAsOwner = (
    context: RoutingContextWithMiddlewares,
    routeParams?: any,
): boolean => {
    const userRoles = selectUserRoles(context.redux.store.getState());
    return ensureCanAccessAgencyPortal(context, routeParams) && isProAgencyOwner(userRoles);
};
export default ensureCanAccessAgencyPortal;
