import type { Dispatch } from 'redux';

import { enableInternalLinks as enableLinks } from 'strat/search/internalLinks/state';

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const enableInternalLinks = () => (dispatch: Dispatch) => {
    dispatch(enableLinks());
};

export default enableInternalLinks;
