import * as React from 'react';
import classNames from 'classnames';
import { LoadingSpinner } from 'strat/loadable';
import iconArrowDown from '@app/assets/icons/iconArrowDown_noinline.svg';
import type { ChoiceValue } from 'strat/components/types';
import { Flex, InputContainer, CheckedIcon } from 'strat/components';

import styles from './styles/dropdownTrigger.cssm';

const renderIcon = () => (
    <div className={styles.checkedIcon}>
        <CheckedIcon />
    </div>
);

type Props<T extends ChoiceValue> = {
    readonly title?: string;
    readonly description?: string;
    readonly name: string;
    readonly errorMessage?: string | null;
    readonly accepted?: boolean;
    readonly stretch?: boolean;
    readonly setIsOpen: (arg1: boolean) => void;
    readonly value?: T;
    readonly placeholder?: string;
    readonly isOpen: boolean;
    readonly selectedValueLabel: number | string | null | undefined;
    readonly loading?: boolean;
    readonly className?: string;
    readonly selectClassName?: string;
    readonly titleCustomClass?: string;
    readonly noOverflow?: boolean;
    readonly boldLabel?: boolean;
    readonly renderSelectionIcon?: (
        isOpen: boolean,
        setIsOpen: (isOpen: boolean) => void,
    ) => React.ReactElement;
};

const DropdownTrigger = <T extends ChoiceValue>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    stretch,
    setIsOpen,
    className,
    selectClassName,
    placeholder,
    isOpen,
    selectedValueLabel,
    loading,
    noOverflow,
    renderSelectionIcon,
    titleCustomClass,
    boldLabel,
}: Props<T>) => (
    <InputContainer
        className={className}
        title={title}
        description={description}
        name={name}
        errorMessage={errorMessage}
        accepted={accepted}
        stretch={stretch}
        onFocusChanged={() => {
            setIsOpen(!isOpen);
        }}
        titleClassname={titleCustomClass}
        boldLabel={boldLabel}
    >
        {loading && <LoadingSpinner className={styles.loading} />}
        <Flex
            id={name}
            alignCenter
            onClick={() => setIsOpen(!isOpen)}
            className={classNames(selectClassName ? selectClassName : styles.select, {
                [styles.noOverflow]: noOverflow,
            })}
        >
            {selectedValueLabel && (
                <span
                    className={classNames({
                        [styles.noOverflow]: noOverflow,
                    })}
                >
                    {selectedValueLabel}
                </span>
            )}
            {(placeholder || !selectedValueLabel) && <span>{placeholder}</span>}
        </Flex>

        {renderSelectionIcon ? (
            renderSelectionIcon(isOpen, setIsOpen)
        ) : (
            <div
                className={classNames(styles.iconArrowDown, { [styles.focused]: isOpen })}
                onClick={() => setIsOpen(!isOpen)}
            >
                <img src={iconArrowDown} alt={'Select toggle'} />
            </div>
        )}
        {accepted && renderIcon()}
    </InputContainer>
);

export default DropdownTrigger;
