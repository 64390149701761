import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';

import Page from 'horizontal/pages/page';

import SelectPackageSuccessCommonRoute from './selectPackageSuccessCommonRoute';
import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type SelectPackageSuccessRouteParams = {
    adExternalID: string;
    packageID: string;
};

class SelectPackageSuccessRoute extends SelectPackageSuccessCommonRoute {
    constructor() {
        super(
            RouteNames.SELECT_PACKAGE_SUCCESS,
            '/payments/packages/consume/boost/',
            Page.SELECT_PACKAGE_SUCCESS,
            Page.APPLY_AD_SINGLE_PACKAGE_ERROR,
            Page.PACKAGES_NOT_FOUND,
        );
    }

    hasAccess(context: RoutingContextWithMiddlewares): boolean {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return false;
        }
        return true;
    }
}

export default new SelectPackageSuccessRoute();
