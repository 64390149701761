import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikTouched, FormikErrors, FormikValues } from 'formik';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Input, Text, Flex } from 'strat/components';

import { ContactInfoFields } from './commonPostingFields';
import styles from './styles/phoneNumberField.cssm';

type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly title?: string | null | undefined;
    readonly onClick?: () => void | null | undefined;
    readonly onFocus?: () => void | null | undefined;
    readonly onMouseDown?: (arg1: React.MouseEvent<any>) => void | null | undefined;
    readonly disabled?: boolean | null | undefined;
    readonly readOnly?: boolean;
    readonly showShortNumber?: boolean | null | undefined;
    readonly name?: string | null;
    readonly optional?: boolean;
    readonly placeholder?: string;
    readonly leftSideLabel?: boolean;
    readonly lightPlaceholder?: boolean;
    readonly boldLabel?: boolean;
    readonly isMandatory?: boolean;
};

const PhoneNumberField = ({
    setFieldValue,
    values,
    handleBlur,
    touched,
    errors,
    title,
    onClick,
    onFocus,
    onMouseDown,
    disabled,
    name,
    readOnly = false,
    showShortNumber = false,
    optional = false,
    placeholder = '',
    leftSideLabel,
    lightPlaceholder,
    boldLabel,
    isMandatory,
}: Props) => {
    const i18n = useI18n();
    const phoneNumberFieldName = name ? name : ContactInfoFields.phone_number.attribute;

    const phoneNumber = values[phoneNumberFieldName];
    const isInternational =
        phoneNumber?.startsWith('+') &&
        !phoneNumber?.startsWith(settings.defaultPhoneNumber.prefix);
    const countryPrefix = isInternational ? '' : settings.defaultPhoneNumber.prefix;
    const displayPhoneNumber = isInternational
        ? phoneNumber
        : phoneNumber?.replace(settings.defaultPhoneNumber.prefix, '');

    const onChange = (value: string) => {
        const isInternationalValue =
            value.startsWith('+') && !value.startsWith(settings.defaultPhoneNumber.prefix);
        if (isInternationalValue && !CONFIG.runtime.ENABLE_INTERNATIONAL_NUMBER_LOGIN) {
            return;
        }
        if (optional && !value) {
            setFieldValue(phoneNumberFieldName, value);
            return;
        }
        const newPhoneNumber = value.startsWith('+') ? value : `${countryPrefix}${value}`;
        setFieldValue(phoneNumberFieldName, newPhoneNumber);
    };

    if (disabled) {
        return (
            <Flex alignCenter justifySpaceBetween>
                <Text.Large>{t(i18n)`Your phone number`}</Text.Large>
                <Text.Large>{phoneNumber}</Text.Large>
            </Flex>
        );
    }
    return (
        <Input
            type="text"
            name={phoneNumberFieldName}
            title={isMandatory ? title?.concat('*') : title || ''}
            placeholder={placeholder || t(i18n)`Phone number`}
            value={displayPhoneNumber}
            unit={countryPrefix}
            onBlur={handleBlur}
            errorMessage={touched[phoneNumberFieldName] && errors[phoneNumberFieldName]}
            accepted={touched[phoneNumberFieldName] && !errors[phoneNumberFieldName]}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onMouseDown={onMouseDown}
            fixUnitPosition
            readOnly={showShortNumber || readOnly}
            disabled={showShortNumber || false}
            className={classNames({
                [styles.disabled]: showShortNumber,
            })}
            leftSideLabel={leftSideLabel}
            lightPlaceholder={lightPlaceholder}
            boldLabel={boldLabel}
        />
    );
};

export default PhoneNumberField;
