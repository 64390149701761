import { CancelableRoute } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import { isCanceled } from 'strat/util';

import Page from 'horizontal/pages/page';
import { PaymentOrderStatus } from 'horizontal/payment';
import type { PaymentOrder } from 'horizontal/payment';
import { setPaymentOrderExternalID } from 'horizontal/businessPackages/state';
import { getStratAPI } from 'horizontal/api';
import { SupportedCurrencies } from 'horizontal/payment/areeba';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type PaymentAreebaRouteParams = {
    readonly orderExternalID: string;
    readonly sessionID: number;
    readonly currency: Values<typeof SupportedCurrencies>;
};

class PaymentAreebaRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.PAYMENT_AREEBA, [
            [
                '^/areeba/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        params: PaymentAreebaRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/areeba/${params?.orderExternalID}?sessionID=${params?.sessionID}&currency=${params?.currency}`,
        };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const {
            match: {
                params: { orderExternalID, sessionID, currency },
            },
            redux: {
                store: { getState, dispatch },
            },
        } = context;

        if (!orderExternalID || !sessionID || !(currency in SupportedCurrencies)) {
            this.renderNotFoundPage(context);
            return;
        }

        const state = getState();
        const cancelablePromise = this.cancelable(getStratAPI(state).getOrder(orderExternalID));
        const promise = cancelablePromise.then(
            ({ data: order, status }: { data: PaymentOrder; status: number }) => {
                if (status !== 200 || !order || order.status === PaymentOrderStatus.ACTIVE) {
                    this.renderNotFoundPage(context);
                    return;
                }
                dispatch(setPaymentOrderExternalID(orderExternalID));
                context.rendering.renderPage(Page.PAYMENT_AREEBA, {
                    order,
                    sessionID,
                });
            },
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }
}

export default new PaymentAreebaRoute();
