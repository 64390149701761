import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import brandingSettings from '@app/branding/settings';

import { LocationNodeData } from 'horizontal/property';

import { MatchParams, MatchParamsWithDisambiguatedData } from '../types';

import searchLocationFromMatchParams from './searchLocationFromMatchParams';

const disambiguateMatchAndData = (
    params: MatchParams,
    locationsResponses: SearchResponse<LocationNodeData>[],
): MatchParamsWithDisambiguatedData => {
    const [jobForRealLocationsResponse, jobNeededToDisambiguateMakeVsLocation] = locationsResponses;
    if (!params.categoryFieldsWithLocationSlug) {
        return {
            pathCategoryFieldsSlugs: null,
            locationSlug: params.locationSlug,
            location: jobForRealLocationsResponse?.hits?.[0],
        };
    }

    const categoryFieldsWithLocation = params.categoryFieldsWithLocationSlug.split('/');
    const locationExistsInURL = jobNeededToDisambiguateMakeVsLocation?.hits?.length;
    if (locationExistsInURL) {
        const locationSlug = categoryFieldsWithLocation.pop();
        return {
            pathCategoryFieldsSlugs: categoryFieldsWithLocation,
            locationSlug,
            location: jobNeededToDisambiguateMakeVsLocation?.hits?.[0],
        };
    }

    return {
        pathCategoryFieldsSlugs: categoryFieldsWithLocation,
        locationSlug: null,
        location: jobForRealLocationsResponse?.hits?.[0],
    };
};

const getUpdatedMatchParams = (
    params: MatchParams,
    language: string,
    activeSearchBackend: SearchBackend,
): Promise<MatchParamsWithDisambiguatedData> => {
    /**
     * We can't always differentiate between a path category fields and a location.
     * So we assume it's a location and try to fetch the location.
     * If it's not a location it's a category field. That happens because the fields and locations can be in the same place
     * /vehicles/cars-for-sale/alexandria
     * /vehicles/cars-for-sale/mercedes-benz
     */
    return searchLocationFromMatchParams(params, language, activeSearchBackend).then(
        (locationsResponses) => {
            const updatedParams = {
                ...params,
                ...disambiguateMatchAndData(params, locationsResponses),
            };
            const isTopLevelLocation =
                updatedParams.location?.externalID == brandingSettings.topLevelLocation.externalID;
            return {
                ...updatedParams,
                location: isTopLevelLocation ? null : updatedParams.location,
            };
        },
    );
};

export default getUpdatedMatchParams;
