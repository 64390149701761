import React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';
import brandingSettings from '@app/branding/settings';

import type { Location, Category, LiteHierarchicalLocation } from 'horizontal/types';
import useLocationWithHierarchy from 'horizontal/search/location/useLocationWithHierarchy';
import { LocationField } from 'horizontal/fields';
import { useFetchFacetsForAdCreation, useSetAdDeliveryField } from 'horizontal/ad';
import { DropdownVariants } from 'horizontal/components';

type Props = {
    readonly attribute: string;
    readonly setFieldValue: (arg1: string, arg2?: string | null | undefined) => void;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly readOnly?: boolean;
    readonly activeCategory?: Category | null | undefined;
    readonly initialLocation?: LiteHierarchicalLocation | null;
    readonly variant?: DropdownVariants;
    readonly formatPlaceholder?: (title?: string) => string;
    readonly lightPlaceholder?: boolean;
    readonly isMandatory?: boolean;
};

const LocationFieldForm = ({
    setFieldValue,
    attribute,
    activeCategory,
    errors,
    initialLocation,
    touched,
    readOnly,
    variant,
    formatPlaceholder,
    lightPlaceholder,
    isMandatory,
}: Props) => {
    const setLocationFieldValue = React.useCallback(
        (locationObject?: Location) => {
            const externalID = locationObject?.externalID || '';
            setFieldValue(attribute, externalID);
        },
        [setFieldValue, attribute],
    );

    const { locationsHierarchy, locationChoicesList, onLocationSelect, selectedLocation } =
        useLocationWithHierarchy(
            initialLocation || brandingSettings.topLevelLocation,
            activeCategory,
            setLocationFieldValue,
        );

    useFetchFacetsForAdCreation(activeCategory, selectedLocation);
    useSetAdDeliveryField(selectedLocation, locationChoicesList, setFieldValue);

    return (
        <LocationField
            locationHierarchy={locationsHierarchy}
            errors={errors}
            touched={touched}
            choices={locationChoicesList}
            setSelectedLocation={onLocationSelect}
            readOnly={readOnly}
            variant={variant}
            formatPlaceholder={formatPlaceholder}
            lightPlaceholder={lightPlaceholder}
            boldLabel
            isMandatory={isMandatory}
        />
    );
};

export default LocationFieldForm;
