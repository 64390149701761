import { t } from '@lingui/macro';
import * as React from 'react';
import { useLinkIdentityProvider } from '@sector-labs/fe-auth-redux/thunk';
import {
    useKeycloakSiteConfigWithCallbacks,
    determineKeycloakThirdPartyProviderID,
} from 'strat/auth/keycloak';
import { useI18n } from 'strat/i18n/language';
import { Flex, LoadingSpinnerOverlay } from 'strat/components';
import { InternalError } from 'strat/error';
import { ThirdPartyProvider } from 'strat/auth/keycloak/types';

import { useMakeCancelable } from 'horizontal/util';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly provider: ThirdPartyProvider;
    readonly externalRedirectPath: string;
};

const LinkIdentityProvider = ({ provider, externalRedirectPath }: Props) => {
    const i18n = useI18n();
    const [error, setError] = React.useState(false);
    const linkIdentityProvider = useLinkIdentityProvider();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const makeCancelable = useMakeCancelable();

    React.useEffect(() => {
        makeCancelable(
            linkIdentityProvider(keycloakSiteConfig, {
                provider: determineKeycloakThirdPartyProviderID(provider),
                redirectURI: externalRedirectPath,
            }),
            // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
        ).then((data) => {
            if (!data) {
                setError(true);
            }
            window.location.href = data;
        });
    }, [linkIdentityProvider, keycloakSiteConfig, provider, externalRedirectPath, makeCancelable]);

    if (error) {
        return <InternalError />;
    }

    return <LoadingSpinnerOverlay visible text={t(i18n)`Logging in...`} />;
};

const LinkIdentityProviderPage = {
    head: () => <PrivateNonIndexableHead />,
    body: (props: Props) => (
        <Flex alignCenter justifyCenter stretchWidth stretchHeight column>
            <LinkIdentityProvider {...props} />
        </Flex>
    ),
} as const;

export default LinkIdentityProviderPage;
