import Currency from './currency';

/**
 * The shape of the exchangeRates.
 */
export type ExchangeRates = {
    base: string;
    rates: {
        [key: string]: number;
    };
};

/**
 * The shape of the JSON we receive from CurrencyLayer.
 */
export type CurrencyLayerResponse = {
    source: string;
    quotes: {
        [key: string]: number;
    };
    success: boolean;
};

// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'ExchangeRates': base, rates
let cachedExchangeRates: ExchangeRates = {};

const defaultExchangeRates: ExchangeRates = {
    base: 'USD',
    rates: {
        AED: 3.672601,
        EUR: 0.813101,
        GBP: 0.72256,
        INR: 65.060303,
        LBP: 17000,
        PKR: 134.12,
        RUB: 56.811966,
        SAR: 3.749997,
        CAD: 1.32,
        USD: 1,
        JOD: 0.71,
        XPF: 108.16939,
        ANG: 1.755611,
        NPR: 113.93335,
        QAR: 3.64,
        OMR: 0.38,
        BHD: 0.38,
        KWD: 0.3,
        CNY: 6.95,
        PHP: 54,
        EGP: 30.9,
    },
};

/**
 * Converts the CurrencyLayerResponse to an ExchangeRates object to be used with MoneyJS.
 */
const parseExchangeRates = (json: CurrencyLayerResponse): ExchangeRates => {
    const base = json.source;
    const rates: Record<string, any> = {};

    Object.keys(json.quotes).forEach((key) => {
        const currency = key.slice(base.length);
        rates[currency] = json.quotes[key];
    });

    return {
        base,
        rates,
    };
};

/**
 * Fetches the exchange rates from CurrencyLayer.
 */
const fetchExchangeRates = (): Promise<any> => {
    const currencies = Currency.all();

    const url: string =
        // $FlowFixMe
        `${CONFIG.build.CURRENCYLAYER_API}?access_key=${CONFIG.build.CURRENCYLAYER_API_KEY}` +
        `&currencies=${currencies.join(',')}`;

    return Promise.race([
        fetch(url)
            .then((response: any): CurrencyLayerResponse => response.json())
            .then((json: CurrencyLayerResponse) => {
                if (!json.success) {
                    throw new Error(
                        `Invalid currencyLayerResponse received: ${JSON.stringify(json)}`,
                    );
                }
                cachedExchangeRates = parseExchangeRates(json);
            })
            .catch((error) => {
                console.error('Failed to fetch the exchange rates.'); // eslint-disable-line
                console.error(error.message); // eslint-disable-line
            }),
        new Promise((_: (result: Promise<never>) => void, reject: (error?: any) => void) =>
            setTimeout(() => reject(new Error('timeout')), 10000),
        ),
    ]).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch the exchangeRates.');
    });
};

const obtainDefaultExchangeRates = (): ExchangeRates => defaultExchangeRates;

const obtainExchangeRates = (): ExchangeRates =>
    cachedExchangeRates.rates ? cachedExchangeRates : defaultExchangeRates;

export { fetchExchangeRates, obtainDefaultExchangeRates, obtainExchangeRates };
