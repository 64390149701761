import {
    selectActiveSearchBackend,
    setFilters,
    getFilterCollection,
} from '@sector-labs/fe-search-redux/state';
import { ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { Route } from 'react-true-router';
import FilterValues from 'strat/search/filterValues';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import brandingSettings from '@app/branding/settings';
import type { PatternsCollection } from 'react-true-router';
import { disableURLUpdater, resetPageParameters, saveScrollYPosition } from 'strat/search/state';
import createSearchURL from '@app/routes/createSearchURL';
import { getCatalogs, setupI18n } from 'strat/i18n/language';
import defaultRoutePatterns from '@app/routes/search/defaultRoutePatterns';

import type { LiteHierarchicalLocation } from 'horizontal/types';

import {
    decodeMatchParams,
    getUpdatedMatchParams,
    isValidSearchPath,
} from './search/paramsParsing';
import {
    prepareSearchPageRendering,
    preFetchDataForSearchPageRendering,
    postFetchDataForSearchPageRendering,
    renderNotFoundPage,
} from './search';
import type { SearchRouteParams } from './createSearchURL';

class SearchRoute extends Route {
    constructor(
        routeName: string = RouteNames.SEARCH,
        searchRouteParams: PatternsCollection = defaultRoutePatterns,
    ) {
        super(routeName, searchRouteParams);
    }

    createURL(
        searchRouteParams: SearchRouteParams,
        context: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const state = context.redux.store.getState();
        return createSearchURL(searchRouteParams, state);
    }

    postFetchData(context: RoutingContextWithMiddlewares, searchRouteParams: SearchRouteParams) {
        return postFetchDataForSearchPageRendering(context, searchRouteParams);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            match,
            redux: {
                store: { getState, dispatch },
            },
            i18n: { locale: language },
        } = context;

        prepareSearchPageRendering(context, match.params);

        const updatedParamsPromise = getUpdatedMatchParams(
            match.params,
            language,
            selectActiveSearchBackend(getState()) as SearchBackend,
        );

        const promise = Promise.all([
            updatedParamsPromise,
            preFetchDataForSearchPageRendering(dispatch, getState, match.params),
        ]).then(([{ location, pathCategoryFieldsSlugs, ...updatedMatchParams }]): Promise<any> => {
            if (!isValidSearchPath(updatedMatchParams, location)) {
                return renderNotFoundPage(context);
            }
            const searchRouteParams = decodeMatchParams(
                updatedMatchParams,
                getState,
                location,
                pathCategoryFieldsSlugs,
            );
            if (!searchRouteParams) {
                return renderNotFoundPage(context);
            }
            return this.postFetchData(context, searchRouteParams);
        });

        context.promise.wait(promise);
    }

    onLeave(
        oldContext: RoutingContextWithMiddlewares,
        newContext: RoutingContextWithMiddlewares,
    ): void {
        const {
            i18n: { locale: language },
            redux: {
                store: { getState, dispatch },
            },
        } = oldContext;
        dispatch(resetPageParameters());

        const newRouteName = newContext.match.route.name;
        const state = getState();

        // Custom requirements for the location filter to be reset after navigating to home page, for some locations
        const filterCollection = getFilterCollection(state);
        const location: LiteHierarchicalLocation | null = filterCollection.getFilterValue(
            FilterValues.location.attribute,
        );

        if (
            newRouteName === 'home' &&
            location &&
            brandingSettings.homePageLimitedLocations.includes(location.slug)
        ) {
            filterCollection.refine(
                new ExactFilter({
                    attribute: FilterValues.location.attribute,
                    value: brandingSettings.getTopLevelLocation(setupI18n(language, getCatalogs())),
                }),
            );
            dispatch(setFilters(filterCollection));
        }

        dispatch(saveScrollYPosition(window.pageYOffset));
        dispatch(disableURLUpdater());
    }
}

export { SearchRoute as SearchRouteClass };
export default new SearchRoute();
