import * as React from 'react';

import { SearchLink } from 'horizontal/search/routing';
import type { SearchRouteParams } from 'horizontal/routes';
import { useBreadcrumbTitle } from 'horizontal/search/seo';

type Props = {
    readonly params: SearchRouteParams;
    readonly className?: string;
    readonly withShortTitle?: boolean;
};

const BreadcrumbSearchLink = ({ params, className, withShortTitle }: Props) => {
    const title = useBreadcrumbTitle(params, { short: withShortTitle });

    return (
        <SearchLink
            // @ts-expect-error - TS2322 - Type '{ children: (string | Element)[]; itemProp: string; "data-testid": string; params: SearchRouteParams; title: string; className: string | undefined; }' is not assignable to type 'IntrinsicAttributes & Diff<Props, { route: string; }> & { readonly params?: SearchRouteParams | undefined; readonly onClickParams?: SearchRouteParams | undefined; }'.
            itemProp="item"
            data-testid="breadcrumbSearchLink"
            params={params}
            title={title}
            className={className}
        >
            {title}
            <meta itemProp="name" content={title} />
        </SearchLink>
    );
};

export default BreadcrumbSearchLink;
