import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { RouteNames } from 'strat/routes';
import { selectGeoLocationData } from 'strat/user/selectors';
import { selectUserExternalID } from 'strat/user/session';

import { clearAd, fetchAd, selectAdExternalID } from 'horizontal/ad/state';
import { encodeStateFilter } from 'horizontal/adManagement/stateFilterEncoding';
import Page from 'horizontal/agencyPortal/pages/page';
import type { DateRange } from 'horizontal/agencyPortal/types';
import { fetchUserAgencies } from 'horizontal/agent/state';
import { fetchCategories } from 'horizontal/categories/state';
import type { ChatAdXID, ChatUserXID } from 'horizontal/chat/types';
import type { AdStateFilterValues, ProductStateFilterValues } from 'horizontal/types';

import ensureCanAccessAgencyPortal, {
    ensureCanAccessAgencyPortalAsOwner,
} from './ensureCanAccessAgencyPortal';
import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';

export type AdsRouteParams = {
    readonly adExternalID?: string;
    readonly tabName?: string;
    /**
     * helps in determining if route change is purely
     * made for UI change purposes
     */
    readonly tabChange?: boolean;
    readonly query?: string | null;
    readonly category?: string;
    readonly agent?: string;
    readonly stateFilter?: AdStateFilterValues | null;
    readonly productFilter?: ProductStateFilterValues[] | null;
    readonly createDate?: DateRange;
    readonly updateDate?: DateRange;
    readonly page?: number;
    readonly agentCode?: string;
    readonly phoneNumber?: string;
    readonly location?: string;
    readonly contactXID?: string;
};

const patterns = {
    optionalSlashAndParams: '/?(?:(?:\\?|#).*)?$',
} as const;

const BASE_ROUTE = '/agencyPortal/ads';

export const AdSubRoutes = Object.freeze({
    OVERVIEW: 'overview',
    AGENT: 'agent',
    INFO: 'info',
    CHATS: 'chats',
});

export type ProToolChatRoomRouteParams = Partial<{
    readonly adExternalID: ChatAdXID;
    readonly contactXID: ChatUserXID;
}>;

class AgencyPortalAdsRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_ADS, [
            /**
             * presence of adExternalId means the dialog of
             * ad details will be open. tabName states which
             * section from the dialog is in view. contactXID
             * will determine which user chat is open in the
             * chats component
             */
            [
                '^',
                BASE_ROUTE,
                '/extraDetails/',
                { name: 'adExternalID', pattern: '([A-z0-9-]+)' },
                '/',
                { name: 'tabName', pattern: `(${AdSubRoutes.CHATS})` },
                '/',
                { name: 'contactXID', pattern: '([A-z0-9-]+)' },
                patterns.optionalSlashAndParams,
                '$',
            ],
            [
                '^',
                BASE_ROUTE,
                '/extraDetails/',
                { name: 'adExternalID', pattern: '([A-z0-9-]+)' },
                '/',
                { name: 'tabName', pattern: `(${Object.values(AdSubRoutes).join('|')})` },
                patterns.optionalSlashAndParams,
                '$',
            ],
            ['^', BASE_ROUTE, patterns.optionalSlashAndParams],
        ]);
    }

    createURL(
        {
            adExternalID,
            tabName,
            query,
            category,
            contactXID,
            agent,
            stateFilter,
            productFilter,
            createDate,
            updateDate,
            page,
            agentCode,
            phoneNumber,
            location,
            tabChange,
        }: AdsRouteParams,
        context: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const {
            redux: {
                store: { getState },
            },
        } = context;
        const { closestLocation } = selectGeoLocationData(getState());
        const filter = encodeStateFilter({
            query,
            category,
            agent,
            stateFilter,
            productFilter,
            createDate,
            updateDate,
            agentCode,
            phoneNumber,
            location: location || closestLocation?.externalID,
        });
        const search: Record<string, any> = {};

        if (filter) {
            search.filter = filter;
        }

        if (page && page.toString() !== '1') {
            search.page = page;
        }

        if (tabChange) {
            search.tabChange = tabChange;
        }

        let pathname = BASE_ROUTE;

        if (adExternalID && tabName) {
            pathname = `${BASE_ROUTE}/extraDetails/${adExternalID}/${tabName}`;
            if (tabName === AdSubRoutes.CHATS && contactXID) {
                pathname += `/${contactXID}`;
            }
        }

        if (Object.keys(search).length > 0) {
            return { pathname, search };
        }
        return { pathname };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context) && !ensureHasAccessToStratCredits(context)) {
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { adExternalID, tabChange, tabName },
            },
        } = context;

        if (tabName === AdSubRoutes.AGENT && !ensureCanAccessAgencyPortalAsOwner(context)) {
            context.rendering.renderPage(Page.NOT_FOUND);
            return;
        }

        const userExternalID = selectUserExternalID(getState());
        const currentAdExternalID = selectAdExternalID(getState());
        context.rendering.renderPage(Page.AGENCY_PORTAL_ADS);
        if (currentAdExternalID !== adExternalID) {
            dispatch(clearAd());
        }
        context.promise.wait(
            Promise.all([
                dispatch(fetchCategoryFields()),
                dispatch(fetchUserAgencies({ userExternalID })),
                tabChange || !adExternalID
                    ? Promise.resolve()
                    : dispatch(fetchAd({ externalID: adExternalID })),
                dispatch(fetchCategories()),
            ]),
        );
    }
}

export default new AgencyPortalAdsRoute();
