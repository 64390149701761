import type { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import type { PropertyData } from 'strat/property/types';
import { selectUserID } from 'strat/user/selectors';
import FavoritesAPI from 'strat/api/favoritesAPI';
import { selectLanguage } from 'strat/i18n/language';
import { GlobalState } from 'strat/state';
import { PropertyState } from 'strat/property/types';
import { ContextfulError } from 'strat/error/context';

import { selectInactiveFavoritesIDs, selectFavoritesIDs } from '../selectors';

import Actions from './actions';
import type { SetFavoritesAction } from './actions';

const preemptivelyToggleFavorite =
    (property: PropertyData): ThunkAction<void, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const state = getState();

        const favoritesIDs = selectFavoritesIDs(state);
        const inactiveFavoritesIDs = selectInactiveFavoritesIDs(state);
        const activeFavoritesIDs = favoritesIDs.filter(
            (favoriteID: string) => !inactiveFavoritesIDs.includes(favoriteID),
        );
        const isSelected = favoritesIDs.some(
            (favoriteID: string) => favoriteID === property.externalID,
        );

        if (isSelected) {
            // Some portals (eg. Zameen) index the inactive favorites in Algolia and do not have
            // a separate flow for them. For these portals, STRAT_DISPLAY_INACTIVE_FAVORITES
            // is not set and the original code flow should be executed
            if (
                !CONFIG.build.STRAT_DISPLAY_INACTIVE_FAVORITES ||
                activeFavoritesIDs.includes(property.externalID)
            ) {
                dispatch({
                    type: Actions.REMOVE_FAVORITE,
                    externalID: property.externalID,
                });
            }

            if (
                CONFIG.build.STRAT_DISPLAY_INACTIVE_FAVORITES &&
                inactiveFavoritesIDs.includes(property.externalID)
            ) {
                dispatch({
                    type: Actions.REMOVE_INACTIVE_FAVORITE,
                    externalID: property.externalID,
                });
            }
        } else {
            // Some portals (eg. Zameen) index the inactive favorites in Algolia and do not have
            // a separate flow for them. For these portals, STRAT_DISPLAY_INACTIVE_FAVORITES
            // is not set and the original code flow should be executed
            if (
                !CONFIG.build.STRAT_DISPLAY_INACTIVE_FAVORITES ||
                property.state === PropertyState.ACTIVE
            ) {
                dispatch({
                    type: Actions.ADD_FAVORITE,
                    property,
                });
            }

            if (
                CONFIG.build.STRAT_DISPLAY_INACTIVE_FAVORITES &&
                property.state !== PropertyState.ACTIVE
            ) {
                dispatch({
                    type: Actions.ADD_INACTIVE_FAVORITE,
                    externalID: property.externalID,
                });
            }
        }
    };

export const setFavorites = (favorites: Array<PropertyData>): SetFavoritesAction => ({
    type: Actions.SET_FAVORITES,
    favorites,
});

export const toggleFavorite =
    (property: PropertyData): ThunkAction<Promise<unknown>, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const state = getState();

        const userExternalID = selectUserID(state);
        const language = selectLanguage(state);

        if (!userExternalID) {
            return Promise.resolve();
        }

        // Update the state to give the user immediate feedback
        // without waiting for the server to commit the toggle.
        dispatch(preemptivelyToggleFavorite(property));

        return new FavoritesAPI(language)
            .toggleFavorite(userExternalID, property.externalID)
            .then(({ status, data }) => {
                if (status !== 200) {
                    throw new ContextfulError(
                        'Unexpected response status while toggling favorites',
                        { status, data },
                    );
                }
            })
            .catch((e) => {
                // Undo the toggle so the user has some feedback
                // that the toggle wasn't properly committed
                // on the server.
                dispatch(preemptivelyToggleFavorite(property));
                throw e;
            });
    };
