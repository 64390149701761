import { useSelector } from 'react-redux';
import { selectRouteParams } from '@app/routes/selectors';
import { selectPageParameters } from '@app/search/state/selectors';

import { RemarketingRouteParams } from './types';

export const useRemarketingParams = (): RemarketingRouteParams => {
    const pagetype = useSelector(selectPageParameters)?.pagetype;
    const remarketingAdID = useSelector(selectRouteParams)?.remarketingAdID;

    return {
        ...(pagetype && { pagetype }),
        ...(remarketingAdID && { remarketingAdID }),
    };
};
