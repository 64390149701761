import * as React from 'react';

import { NavButtonLeft, NavButtonRight } from './navButton';

const useRenderNavButtons = (withArrows = true) => {
    const renderLeftNav = React.useCallback(
        (onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void, disabled: boolean) => {
            return (
                !disabled &&
                withArrows && (
                    <NavButtonLeft
                        onClick={(e) => {
                            onClick(e);
                            e.stopPropagation();
                        }}
                    />
                )
            );
        },
        [withArrows],
    );

    const renderRightNav = React.useCallback(
        (onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void, disabled: boolean) => {
            return (
                !disabled &&
                withArrows && (
                    <NavButtonRight
                        onClick={(e) => {
                            onClick(e);
                            e.stopPropagation();
                        }}
                    />
                )
            );
        },
        [withArrows],
    );

    return [renderLeftNav, renderRightNav];
};

export default useRenderNavButtons;
