import Loadable from 'strat/loadable';
import EMPTY_FUNCTION from 'strat/empty/function';

/**
 * Loads the image slide show component async.
 */
export default Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'imageSlideShow' */
            './imageSlideshow'
        ),
    loading: EMPTY_FUNCTION,
});
