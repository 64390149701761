import type { Dispatch } from 'redux';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import type { FetchState } from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import { setItem, getItem } from 'strat/misc/sessionStorage';
import { selectUserExternalID } from 'strat/user/session/selectors';

import type { FullAd, AdVirtualStateValues } from 'horizontal/types';
import { TimeOfDay, isoDate } from 'horizontal/adManagement';
import type { DateRange } from 'horizontal/agencyPortal/types';
import type { GlobalState } from 'horizontal/state';

import filterAdManagementState from './filterAdManagementState';
import { DEFAULT_PAGE_SIZE } from './usePagination';

export type FetchMyAdsParams = {
    query?: string | null | undefined;
    agentCode?: string | null | undefined;
    states?: Array<AdVirtualStateValues> | null | undefined;
    category?: string;
    agent?: string;
    createDate?: DateRange;
    updateDate?: DateRange;
    page?: number;
    pageSize?: number;
    location?: string;
    includeCategoryDescendants?: boolean;
};

export type AdStates = Record<AdVirtualStateValues, number>;

export type AdManagementState = {
    count: number;
    next: string;
    previous: string;
    results: FullAd[];
    countByState: AdStates;
};

/**
 * Fetcher factory for fetching my ads.
 */
const factory = new FetcherFactory(
    'adManagement',
    // @ts-expect-error - TS2345 - Argument of type '(params: FetchMyAdsParams, state: any) => Promise<{ data: any; status: number; } | { status: number; data: AdManagementState | null; } | null>' is not assignable to parameter of type 'FetchMethod'.
    (params: FetchMyAdsParams, state: GlobalState) => {
        const removedAdsIDs = getItem('removed-ads') || [];
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Missing user ID when request my ads.');
        }

        return new StratAPI(state.i18n.language)
            .userAds(userExternalID, {
                location: params.location,
                countByState: true,
                // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
                query: params.query,
                agent_code: params.agentCode,
                category_external_id: params.category,
                agent_external_id: params.agent,
                include_category_descendants: true,
                // @ts-expect-error - TS2322 - Type 'string[] | null | undefined' is not assignable to type 'string[] | undefined'.
                states: params.states,
                dateFilters: {
                    created_at_gte:
                        params.createDate && isoDate(params.createDate?.from, TimeOfDay.START),
                    created_at_lte:
                        params.createDate && isoDate(params.createDate?.to, TimeOfDay.END),
                    updated_at_gte:
                        params.updateDate && isoDate(params.updateDate?.from, TimeOfDay.START),
                    updated_at_lte:
                        params.updateDate && isoDate(params.updateDate?.to, TimeOfDay.END),
                },
                page: params.page,
                page_size: params.pageSize || DEFAULT_PAGE_SIZE,
            })
            .then((response) => filterAdManagementState(response, removedAdsIDs));
    },
    { successCodes: [200], skipParamsCheck: true },
);

const Actions = Object.freeze({
    REMOVE_AD_BY_ID: 'AD_MANAGEMENT/REMOVE_AD_BY_ID',
});

type State = FetchState<AdManagementState> & {
    status: number;
};

type RemoveAdByID = {
    type: 'AD_MANAGEMENT/REMOVE_AD_BY_ID';
    adID: number;
};

const defaultState: State = {
    ...buildDefaultState(),
    status: 0,
};

/**
 * Action creator for fetching my ads from
 * the back-end.
 */
const fetchMyAds = factory.creator();

const clearMyAds = () => ({
    type: factory.actions.clear,
});

/**
 * Reducers for ad management.
 */
const adManagementFactoryReducer = factory.reducer();

const adManagementReducer = (
    state: State | null | undefined = defaultState,
    action: RemoveAdByID,
) => {
    switch (action.type) {
        case Actions.REMOVE_AD_BY_ID:
            return {
                ...filterAdManagementState(state, [action.adID]),
            };
        default:
            return adManagementFactoryReducer(state, action);
    }
};

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const removeAdByID = (adID: number) => (dispatch: Dispatch) => {
    const removedAdsIDs = getItem('removed-ads') || [];
    setItem('removed-ads', [...removedAdsIDs, adID]);
    return dispatch({ type: Actions.REMOVE_AD_BY_ID, adID });
};

export { clearMyAds, fetchMyAds, removeAdByID };

export default adManagementReducer;
