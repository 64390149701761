import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex, LoadingSpinnerOverlay, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import { useRenderErrorIfAccountSuspended } from 'horizontal/user';
import getTaxInfoFromOffers from 'horizontal/util/tax';
import IconInfoCircle from 'horizontal/assets/icons/iconInfoCircle.svg';
import type { ProductPackageOffer } from 'horizontal/types';
import { selectCategoryByExternalID } from 'horizontal/categories';
import { GlobalState } from 'horizontal/state';

import HeavyDiscountBannerRedesign from './heavyDiscountBannerRedesign';
import ChangeCurrencySection from './changeCurrencySection';
import {
    PostMoreAndBoostSectionRedesign,
    PostMoreAdsSectionRedesign,
    FeatureAdSectionRedesign,
    BoostToTopSectionRedesign,
    AutoBoostToTopSectionRedesign,
    ContactSalesTeamSectionRedesign,
    PostMoreAndFeatureSectionRedesign,
    EliteAdSectionRedesign,
} from './selectMultiplePackagesSection';
import ViewBusinessPackageCartSection from './viewBusinessPackageCartSection';
import { useBusinessPackages, useLocalizedDays } from './hooks';
import useHashBusinessPackageExample from './useHashBusinessPackageExample';
import BusinessPackageExampleContext from './businessPackageExampleContext';
import useFetchPackagesExchangeRates from './useFetchPackagesExchangeRates';
import PackagesPills from './packagesPills';
import NoPackages from './noPackages';
import styles from './styles/selectMultiplePackagesForm.cssm';

type Props = {
    productOffers?: ProductPackageOffer[];
};

const SelectMultiplePackagesForm = ({ productOffers }: Props) => {
    const {
        postMoreAndFeature,
        postMoreAndFeatureEqualDuration,
        postMoreAndBoost,
        postMoreAndBoostEqualDuration,
        postMoreAndBoostNonDaily,
        postMoreAndBoostNonDailyEqualDuration,
        postMoreAds,
        featureAds,
        eliteAds,
        boostToTop,
        autoBoostToTopDaily,
        autoBoostToTopNonDaily,
        loading,
    } = useBusinessPackages(productOffers);
    const i18n = useI18n();
    const localizedDays = useLocalizedDays();
    const [taxActive, taxPercentage] = getTaxInfoFromOffers(productOffers);
    useFetchPackagesExchangeRates();
    const { activeExample, setActiveExample } = useHashBusinessPackageExample();

    const { category: categoryExternalID } = useSelector(selectRouteParams);
    const category = useSelector((state: GlobalState) =>
        selectCategoryByExternalID(state, categoryExternalID),
    );

    const renderErrorIfAccountSuspended = useRenderErrorIfAccountSuspended(
        t(i18n)`Your account has been suspended from buying packages.`,
    );
    if (renderErrorIfAccountSuspended) {
        return renderErrorIfAccountSuspended();
    }

    if (productOffers && !productOffers.length) {
        return <NoPackages categoryID={category?.externalID} />;
    }

    return (
        <BusinessPackageExampleContext.Provider value={[activeExample, setActiveExample]}>
            <LoadingSpinnerOverlay visible={loading} />
            {!loading && (
                <Flex column className={styles.container}>
                    <Flex className={styles.banner}>
                        <HeavyDiscountBannerRedesign />
                    </Flex>
                    {settings.multipleCurrencyPackages && (
                        <Flex className={styles.banner}>
                            <ChangeCurrencySection />
                        </Flex>
                    )}
                    <Flex className={styles.packagePillsContainer}>
                        <PackagesPills productOffers={productOffers} />
                    </Flex>
                    <Flex className={styles.packagesUsageDurationContainer} alignCenter>
                        <IconInfoCircle className={styles.iconInfo} />
                        <Text.Regular bold className={styles.packagesUsageDurationText}>{t(
                            i18n,
                        )`All bundles can be used within ${settings.packagesUsageDuration} days from purchase date.`}</Text.Regular>
                    </Flex>
                    <Flex column className={styles.sectionContainer}>
                        {!!postMoreAndFeature.length && (
                            <Flex className={styles.section}>
                                <PostMoreAndFeatureSectionRedesign
                                    getTitle={(postMoreDuration, featuredDuration) => (
                                        <Trans>
                                            Post Ads valid for{' '}
                                            {<b>{localizedDays(postMoreDuration)}</b>}, Feature
                                            Service valid for{' '}
                                            {<b>{localizedDays(featuredDuration)}</b>}
                                        </Trans>
                                    )}
                                    packageOffers={postMoreAndFeature}
                                />
                            </Flex>
                        )}
                        {!!postMoreAndFeatureEqualDuration.length && (
                            <Flex className={styles.section}>
                                <PostMoreAndFeatureSectionRedesign
                                    getTitle={(postMoreDuration) => (
                                        <Trans>
                                            Post featured ads valid for{' '}
                                            {<b>{localizedDays(postMoreDuration)}</b>}
                                        </Trans>
                                    )}
                                    packageOffers={postMoreAndFeatureEqualDuration}
                                />
                            </Flex>
                        )}
                        {!!postMoreAndBoost.length && (
                            <Flex className={styles.section}>
                                <PostMoreAndBoostSectionRedesign
                                    details={t(i18n)`Post ads and ads get boosted to the top daily`}
                                    getTitle={(limitDuration, boostDuration) => (
                                        <Trans>
                                            Post Ads valid for <b>{localizedDays(limitDuration)}</b>
                                            , Auto Boost service valid for{' '}
                                            <b>{localizedDays(boostDuration)}</b>
                                        </Trans>
                                    )}
                                    packageOffers={postMoreAndBoost}
                                />
                            </Flex>
                        )}
                        {!!postMoreAndBoostEqualDuration.length && (
                            <Flex className={styles.section}>
                                <PostMoreAndBoostSectionRedesign
                                    details={t(i18n)`Post ads and ads get boosted to the top daily`}
                                    getTitle={(equalDuration) => (
                                        <Trans>
                                            Post ads valid for <b>{localizedDays(equalDuration)}</b>{' '}
                                            and refreshed daily
                                        </Trans>
                                    )}
                                    packageOffers={postMoreAndBoostEqualDuration}
                                />
                            </Flex>
                        )}
                        {!!postMoreAndBoostNonDaily.length && (
                            <Flex className={styles.section}>
                                <PostMoreAndBoostSectionRedesign
                                    details={t(
                                        i18n,
                                    )`Post ads and ads get boosted to the top every few days`}
                                    getTitle={(limitDuration, boostDuration, boostFrequency) => (
                                        <Trans>
                                            Post Ads valid for <b>{localizedDays(limitDuration)}</b>
                                            , Auto Boost service valid for{' '}
                                            <b>{localizedDays(boostDuration)}</b>, Ad will be
                                            auto-refreshed every{' '}
                                            <b>{localizedDays(boostFrequency)}</b>
                                        </Trans>
                                    )}
                                    packageOffers={postMoreAndBoostNonDaily}
                                />
                            </Flex>
                        )}

                        {!!postMoreAndBoostNonDailyEqualDuration.length && (
                            <Flex className={styles.section}>
                                <PostMoreAndBoostSectionRedesign
                                    details={t(
                                        i18n,
                                    )`Post ads and ads get boosted to the top every few days`}
                                    getTitle={(equalDuration, _, boostFrequency) => (
                                        <Trans>
                                            Post ads valid for <b>{localizedDays(equalDuration)}</b>{' '}
                                            and refreshed every{' '}
                                            <b>{localizedDays(boostFrequency)}</b>
                                        </Trans>
                                    )}
                                    packageOffers={postMoreAndBoostNonDailyEqualDuration}
                                />
                            </Flex>
                        )}
                        {!!postMoreAds.length && (
                            <Flex className={styles.section}>
                                <PostMoreAdsSectionRedesign packageOffers={postMoreAds} />
                            </Flex>
                        )}
                        {!!eliteAds.length && (
                            <Flex className={styles.section}>
                                <EliteAdSectionRedesign packageOffers={eliteAds} />
                            </Flex>
                        )}
                        {!!featureAds.length && (
                            <Flex className={styles.section}>
                                <FeatureAdSectionRedesign packageOffers={featureAds} />
                            </Flex>
                        )}
                        {!!boostToTop.length && (
                            <Flex className={styles.section}>
                                <BoostToTopSectionRedesign packageOffers={boostToTop} />
                            </Flex>
                        )}
                        {!!autoBoostToTopDaily.length && (
                            <Flex className={styles.section}>
                                <AutoBoostToTopSectionRedesign
                                    details={t(i18n)`Your ad will be auto-refreshed daily`}
                                    packageOffers={autoBoostToTopDaily}
                                />
                            </Flex>
                        )}
                        {!!autoBoostToTopNonDaily.length && (
                            <Flex className={styles.section}>
                                <AutoBoostToTopSectionRedesign
                                    details={t(i18n)`Your ad will be auto-refreshed periodically`}
                                    packageOffers={autoBoostToTopNonDaily}
                                />
                            </Flex>
                        )}
                        {settings.enableCustomizedPackagesOptions && (
                            <Flex className={styles.customizedPackageContainer}>
                                <ContactSalesTeamSectionRedesign />
                            </Flex>
                        )}
                    </Flex>
                    <Flex
                        className={classNames(styles.viewCartSectionContainer, {
                            [styles.taxText]: taxActive,
                        })}
                    >
                        {taxActive && (
                            <Text.XSmall>{t(
                                i18n,
                            )`These prices are exclusive of tax, ${taxPercentage}% tax will be charged on checkout`}</Text.XSmall>
                        )}
                        <ViewBusinessPackageCartSection />
                    </Flex>
                </Flex>
            )}
        </BusinessPackageExampleContext.Provider>
    );
};

export default SelectMultiplePackagesForm;
