import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { SearchJob } from '@sector-labs/fe-search-redux';
import { FilterValues } from 'strat/search';
import { determineAdsIndexName } from 'strat/search/indexNames';

import getSelectedLocationFilter from './getSelectedLocationFilter';
import createCategoryWithHitsSearchSettings from './createCategoryWithHitsSearchSettings';

const createCategoryWithHitsSearchJob = (
    getState: GetState,
    categoryIdentifier: string,
    language: string,
    locationExternalID?: string,
): SearchJob => {
    const filters = new FilterCollection();
    const locationFilter = locationExternalID
        ? new ExactFilter({
              attribute: FilterValues.location.attribute,
              value: locationExternalID,
          })
        : getSelectedLocationFilter(getState);
    if (locationFilter) {
        filters.refine(locationFilter);
    }
    filters.refine(
        new ExactFilter({
            attribute: FilterValues.category.attribute,
            value: categoryIdentifier,
        }),
    );

    const indexName = determineAdsIndexName({ language });
    const settings = createCategoryWithHitsSearchSettings(indexName, getState);
    return new SearchJob(indexName, filters, settings);
};

export default createCategoryWithHitsSearchJob;
