const getLocalizedFieldSuffix = (language: string, mergedIndex: boolean) => {
    if (mergedIndex) {
        return CONFIG.build.LANGUAGE_CODE === 'en' ? '' : '_l1';
    }

    return language === 'en' ? '' : '_l1';
};

/**
 * This function makes the assumptions that we
 * only have two languages and one of them is
 * english
 */
const transformLocalizedFieldToEnglish = (
    data: any,
    baseKey: string,
    language: string,
    mergedIndex: boolean,
) => {
    const key = `${baseKey}${getLocalizedFieldSuffix(language, mergedIndex)}`;
    return data[key];
};

export default transformLocalizedFieldToEnglish;
