import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import type { ActionButton } from 'horizontal/chat/types';

import ActionButtons from '../actionButtons';
import PriceLabel from '../priceLabel';

import styles from './styles/sellerMadeOfferBuyer.cssm';

type Props = {
    readonly actionButtons: Array<ActionButton>;
    readonly lastOfferPrice: number;
};

const SellerMadeOffer = ({ actionButtons, lastOfferPrice }: Props) => (
    <Flex column className={styles.container}>
        <Flex column fillContainer alignCenter justifyCenter>
            <Text.Large bold uppercase>
                <Trans>{"Seller's counter offer:"}</Trans>
            </Text.Large>
            <PriceLabel price={lastOfferPrice} />
            <Text.Regular>
                <Trans>Seller waiting for your response</Trans>
            </Text.Regular>
        </Flex>
        <ActionButtons actionButtons={actionButtons} />
    </Flex>
);

export default SellerMadeOffer;
