import { ContactPersonRole } from 'horizontal/types';
import type { MixedAd } from 'horizontal/types';

const useAdCommunicationMethods = (ad: MixedAd) => {
    /*
    This hook can be used for all the ads which have the roles stored in the ad.
    Currently this is valid for all portals except olx-pk. There we still have active ads which
    don't have contact info. Probably due to some old app versions.
    */
    const roles = ad.contactInfo?.roles || [];

    return {
        allowChatCommunication: roles.includes(ContactPersonRole.ALLOW_CHAT_COMMUNICATION),
        allowPhoneCommunication: roles.includes(ContactPersonRole.SHOW_PHONE_NUMBER),
        shouldShowBuyWithDelivery: roles.includes(
            ContactPersonRole.SHOW_BUY_WITH_DELIVERY_ON_SEARCH_PAGE,
        ),
        showWhatsappPhoneNumber: roles.includes(ContactPersonRole.SHOW_WHATSAPP_PHONE_BUTTON),
    };
};

export default useAdCommunicationMethods;
