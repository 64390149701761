import * as React from 'react';
import { Flex, RadioButtonChoice, RadioGroup, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { useDefaultCurrencySelectionConfiguration } from 'strat/i18n/money';

import styles from './styles/changeCurrencySection.cssm';

const ChangeCurrencySection = () => {
    const defaultCurrencyConfiguration = useDefaultCurrencySelectionConfiguration();

    if (!defaultCurrencyConfiguration) {
        return null;
    }

    const { onSelectCurrency, currencies, selectedCurrency } = defaultCurrencyConfiguration;

    return (
        <Flex column alignCenter stretchWidth>
            <Flex className={styles.changeCurrencyContainer}>
                <Text.Base bold className={styles.currencyChangeText}>
                    <Trans>Change currency:</Trans>
                </Text.Base>
                <RadioGroup<string>
                    onChange={onSelectCurrency}
                    choices={currencies}
                    renderChoice={RadioButtonChoice}
                    selectedValue={selectedCurrency}
                />
            </Flex>
        </Flex>
    );
};

export default ChangeCurrencySection;
