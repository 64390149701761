import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

export const joinStringList = (list: string[], i18n?: I18n): string => {
    // some calling functions do not pass second parameter; english comma is default in this case
    const joinSeparator = i18n ? `${t(i18n)`,`} ` : ', ';

    return list.join(joinSeparator);
};

export const asNumberOrString = <T extends string | number | undefined | null>(
    value: T,
): T | number => {
    if (value === undefined || value === null) {
        return value;
    }

    const valueAsNumber = Number(value);
    return !isNaN(valueAsNumber) ? valueAsNumber : value;
};
