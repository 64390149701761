import * as React from 'react';

import { ChatFeatureStatus } from 'horizontal/chat/constants';

import chatModuleInitializer from './initializer';

const useChatSDKModule = () => {
    const [chatStatus, setChatStatus] = React.useState(
        chatModuleInitializer.loadStatus ?? ChatFeatureStatus.LOADING,
    );

    React.useEffect(() => {
        const unsubscribe = chatModuleInitializer.onStatusChange((status) => setChatStatus(status));
        return unsubscribe;
    }, []);

    const chatModule = chatModuleInitializer.sdkModule;

    if (!chatModule || chatStatus !== ChatFeatureStatus.LOADED) {
        return { chatStatus, chatSDK: null, ChatProvider: null };
    }

    return {
        chatStatus,
        chatSDK: chatModule.chatSDK,
        ChatProvider: chatModule.ChatProvider,
    };
};

export default useChatSDKModule;
