import React from 'react';

import { TPayPayment } from 'horizontal/payment/tpay';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly orderExternalID: string;
    readonly sessionID: string;
};

const PaymentTPayPage = {
    head: () => <PrivateNonIndexableHead />,
    body: (props: Props) => {
        return <TPayPayment {...props} />;
    },
} as const;

export default PaymentTPayPage;
