import React from 'react';

import styles from './styles/loadingThreeDots.cssm';

/**
 * Properties for {@see LoadingThreeDots}.
 */
type Props = {
    className?: string;
    delay?: boolean;
};

/**
 * Three dots to indicate loading
 */
const LoadingThreeDots = (props: Props) => {
    const classNames = [styles.threeDots];
    if (props.className) {
        classNames.push(props.className);
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="10"
            viewBox="0 0 38 10"
            fill="none"
            className={classNames.join(' ')}
        >
            <circle cx="5" cy="5" r="4" transform="rotate(180 5 5)" className={styles.dot} />
            <circle cx="19" cy="5" r="4" transform="rotate(180 19 5)" className={styles.dot} />
            <circle cx="33" cy="5" r="4" transform="rotate(180 33 5)" className={styles.dot} />
        </svg>
    );
};

export default LoadingThreeDots;
