import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'strat/i18n/language/selectors';

import type { ChatUser } from 'horizontal/chat/types';
import { StratAPI } from 'horizontal/api';

/**
 * Fetches users from backend by external ids.
 */
const useFetchUsers = () => {
    const language = useSelector(selectLanguage);
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = React.useState<ChatUser[]>([]);

    const fetchUserProfiles = React.useCallback(
        (externalIDs: string[]) => {
            if (loading) {
                return;
            }
            setLoading(true);
            new StratAPI(language)
                .users(externalIDs)
                .then(({ data, status }) => {
                    setLoading(false);
                    if (status !== 200) {
                        return;
                    }
                    setUsers(data);
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [loading, language],
    );

    return { fetchUserProfiles, loading, users };
};

export default useFetchUsers;
