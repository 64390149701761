import * as React from 'react';

import { PropertiesPaymentOptionType, VehiclesPaymentOptionType } from 'horizontal/types';

import useFieldIndex from '../useFieldIndex';
import { DownPayment, ExtraFields, PaymentOption } from '../commonPostingFields';
import useCategoryFieldsByAttributes from '../useCategoryFieldsByAttributes';
import { FIELD_ATTRIBUTES_AFTER_PRICE_TYPE } from '../postingConstants';

import renderFields from './renderFields';
import { PriceRelatedFieldProps } from './types';

const useFieldsAfterPriceType = ({
    categoryFields,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
}: PriceRelatedFieldProps): React.ReactElement[] => {
    const [showDownPayment, setShowDownPayment] = React.useState<boolean>(
        !!values[PaymentOption.attribute],
    );

    const fieldsAfterPriceType = useCategoryFieldsByAttributes(
        categoryFields,
        FIELD_ATTRIBUTES_AFTER_PRICE_TYPE,
    );

    const paymentOptionFieldIndex = useFieldIndex(PaymentOption.attribute, categoryFields);
    const makeFieldIndex = useFieldIndex(ExtraFields.make.attribute, categoryFields);

    React.useEffect(() => {
        const isPropertyInstallmentOption =
            values[PaymentOption.attribute] === PropertiesPaymentOptionType.INSTALLMENT ||
            values[PaymentOption.attribute] === PropertiesPaymentOptionType.CASH_OR_INSTALLMENT;

        setShowDownPayment(
            !categoryFields[paymentOptionFieldIndex] ||
                (categoryFields[makeFieldIndex]
                    ? values[PaymentOption.attribute] === VehiclesPaymentOptionType.INSTALLMENT
                    : isPropertyInstallmentOption),
        );
    }, [values, categoryFields, paymentOptionFieldIndex, makeFieldIndex]);

    return React.useMemo(() => {
        const commonProps = {
            values,
            setFieldValue,
            errors,
            touched,
            onBlur: handleBlur,
        };
        const fieldsToRender = fieldsAfterPriceType.filter(
            (field) => !(field.attribute === DownPayment.attribute && !showDownPayment),
        );

        return renderFields(fieldsToRender, commonProps);
    }, [values, errors, touched, handleBlur, setFieldValue, showDownPayment, fieldsAfterPriceType]);
};

export default useFieldsAfterPriceType;
