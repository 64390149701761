import { useSelector } from 'react-redux';
import { useActiveUser } from 'strat/user/session';
import { makeCancelable } from 'strat/util';
import { useLanguage } from 'strat/i18n/language';
import ViewSections from 'strat/gtm/viewSections';

import { MixedAd } from 'horizontal/types';
import { trackBuyWithDelivery, trackCheckDelivery } from 'horizontal/gtm';
import { useNavigateToUserOrders } from 'horizontal/userOrders/hooks';
import { DeliveryRequestStatus, UserOrdersSection } from 'horizontal/userOrders/types';
import { useEnsureHasActiveUser } from 'horizontal/user';
import { selectBuyerList } from 'horizontal/userOrders/buyingOrders';
import { StratAPI } from 'horizontal/api';
import { isAdEditor } from 'horizontal/util';
import type { GlobalState } from 'horizontal/state';

import useNavigateToRequestDelivery from './useNavigateToRequestDelivery';

export type RequestDeliveryType = {
    isAdOrdered: boolean;
    redirect: () => void;
};

const useRequestDelivery = (
    ad: MixedAd,
    viewSection: Values<typeof ViewSections> = ViewSections.BODY,
): RequestDeliveryType => {
    const navigateToRequestDelivery = useNavigateToRequestDelivery({
        adExternalID: ad.externalID,
    });
    const navigateToUserOrders = useNavigateToUserOrders();
    const buyingOrderedAdExternalIDs = useSelector((state: GlobalState) =>
        selectBuyerList(state),
    ).map((deliveryOrder: any) => deliveryOrder.ad.externalID);
    const isAdOrdered = buyingOrderedAdExternalIDs.includes(ad.externalID);

    const activeUser = useActiveUser();
    const language = useLanguage();

    const redirect = useEnsureHasActiveUser({
        onDone: (user) => {
            if (!activeUser) {
                const cancelablePromise = makeCancelable(
                    new StratAPI(language).userBuyingOrdersList(
                        user.externalID,
                        DeliveryRequestStatus.ACTIVE,
                        ad.externalID,
                    ),
                );
                cancelablePromise.then((response) => {
                    if (response.data.length > 0) {
                        trackCheckDelivery(ad, viewSection);
                        navigateToUserOrders({ section: UserOrdersSection.BUYING });
                    } else {
                        trackBuyWithDelivery(ad, viewSection);
                        navigateToRequestDelivery();
                    }
                });
                return;
            }
            if (isAdEditor(ad, user.externalID)) {
                return;
            }
            if (isAdOrdered) {
                trackCheckDelivery(ad, viewSection);
                navigateToUserOrders({ section: UserOrdersSection.BUYING });
            } else {
                trackBuyWithDelivery(ad, viewSection);
                navigateToRequestDelivery();
            }
        },
    });

    return { isAdOrdered, redirect };
};

export default useRequestDelivery;
