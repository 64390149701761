import * as React from 'react';
import { Trans } from '@lingui/macro';
import className from 'classnames';
import { Flex } from 'strat/components';

import { ChatFeatureStatus } from 'horizontal/chat/constants';
import { ConversationGuard } from 'horizontal/chat/conversation';
import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import { useChatProps, useChatSDK, useIsPageVisible } from 'horizontal/chat/hooks';
import { Inbox } from 'horizontal/chat/inbox';
import type { ChatAdXID } from 'horizontal/chat/types';

import styles from './styles/chatCenter.cssm';

export type ChatCenterProps = WithChatStateProps & {
    adXID?: ChatAdXID;
    renderListItem?: any;
};

const ChatCenter = ({ inboxPageSize, adXID, renderListItem }: ChatCenterProps) => {
    const sdk = useChatSDK();
    const isVisible = useIsPageVisible();
    const { agencyMode } = useChatProps();
    sdk.useAutoSetChatMode({ isHidden: !isVisible });
    sdk.useAutoClearInboxUnreadCount(isVisible && !agencyMode);

    return (
        <Flex className={className(styles.container, styles.borders)}>
            <Inbox
                adXID={adXID}
                agencyMode={agencyMode}
                pageSize={inboxPageSize ?? 20}
                renderListItem={renderListItem}
            />
            <ConversationGuard />
        </Flex>
    );
};

type ChatCenterUnavailableProps = WithChatStateProps & {
    readonly chatStatus: Values<typeof ChatFeatureStatus>;
};

const ChatCenterUnavailable = ({ chatStatus }: ChatCenterUnavailableProps) => {
    let text;

    if (chatStatus === ChatFeatureStatus.BANNED) {
        text = <Trans>Your access to chat has been disabled</Trans>;
    } else if (chatStatus === ChatFeatureStatus.MAINTENANCE) {
        text = <Trans>Chat is currently under maintenance. Please check back soon.</Trans>;
    } else if (chatStatus === ChatFeatureStatus.ERROR) {
        text = <Trans>Something went wrong</Trans>;
    }

    if (text) {
        return <div className={className(styles.container, styles.unavailableText)}>{text}</div>;
    }

    return null;
};

export default withChatState<ChatCenterProps>(ChatCenter, ChatCenterUnavailable);
