import { RouteNames } from 'strat/routes';

import { AppBundles } from 'horizontal/types';

const AllowedZendeskPages: Partial<Record<Values<typeof RouteNames>, Values<typeof RouteNames>>> = {
    [RouteNames.HOME]: RouteNames.HOME,
    [RouteNames.SEARCH]: RouteNames.SEARCH,
};

export { AppBundles, AllowedZendeskPages };
