import * as React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import ThumbnailImage from 'strat/image/thumbnailImage';
import { ViewSections } from 'strat/gtm';
import { ThumbnailSizes } from 'strat/image';
import { ListingPhoto, PropsPhoto } from 'strat/listing';

import { LiteAd } from 'horizontal/types';

import GallerySeeMoreOverlay from './gallerySeeMoreOverlay';
import styles from './styles/galleryListingPhoto.cssm';

type Props = PropsPhoto & {
    readonly ad: LiteAd;
    readonly isElite: boolean;
    readonly viewSection: Values<typeof ViewSections>;
    readonly showSeeMoreOverlay: boolean;
};

const GalleryListingPhoto = ({
    ad,
    isElite,
    imageID,
    viewSection,
    showSeeMoreOverlay,
    ...rest
}: Props) => {
    return (
        <ListingPhoto className={styles.container}>
            {!isNil(imageID) && (
                <>
                    <ThumbnailImage
                        {...rest}
                        imageID={imageID}
                        size={ThumbnailSizes.NORMAL}
                        alt={ad.title}
                        title={ad.title}
                        pictureClassName={styles.picture}
                        className={classNames(styles.thumbnailImage, {
                            [styles.coverRoundedCorners]: !isElite,
                        })}
                    />
                    {showSeeMoreOverlay && (
                        <GallerySeeMoreOverlay
                            ad={ad}
                            viewSection={viewSection}
                            coverRoundedCorners={!isElite}
                        />
                    )}
                </>
            )}
        </ListingPhoto>
    );
};

export default GalleryListingPhoto;
