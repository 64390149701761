import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import iconSMS from '@app/assets/icons/iconSMS.svg';
import generateSMSURL from '@app/branding/generateSMSURL';
import settings from '@app/branding/settings';

import type { PropertyData } from 'strat/property/types';
import withGTMLeadTracking from 'strat/gtm/withGTMLeadTracking';
import ViewSections from 'strat/gtm/viewSections';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import { useLoginRestrictedCallback } from 'strat/misc';
import type { NumberFormatterFactory } from 'strat/i18n/language/connectLanguage';
import { UnitType } from 'strat/i18n';
import { Currency, selectCurrency } from 'strat/i18n/money';
import type { ExchangeRates } from 'strat/i18n/money';
import {
    selectI18n,
    selectLanguage,
    selectNumberFormatterFactory,
} from 'strat/i18n/language/selectors';
import { selectVisitorID } from 'strat/user/selectors';
import SmartShareAPI from 'strat/api/smartShareAPI';
import { SmartShareLeadType } from 'strat/smartShare/types';
import { Triggers } from 'strat/gtm';
import { ButtonVariant } from 'strat/components';

import styles from './styles/button.cssm';

/**
 * Properties for {@see ListingSMSButtonCompact}.
 */
type Props = GTMLeadTrackingProps & {
    /**
     * The property for which the SMS button is rendered.
     */
    property: PropertyData;
    /**
     * Optional CSS class names for styling.
     */
    className?: string;
    buttonClassName?: string;
    /**
     * Method to call when the button is clicked.
     */
    onClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Whether it should have an icon or not
     */
    withIcon?: boolean;
    /**
     * Render icon
     */
    renderIcon?: () => React.ReactElement;
    /**
     * The view section, needed for lead tracking
     */
    viewSection?: Values<typeof ViewSections>;
    /**
     * SMS URL promise, contains phone number and message string to be displayed on native phone app when SMS button is clicked.
     */
    smsURLPromise?: (props: any) => Promise<string>;
    /**
     * Whether log in is necessary to display phone numbers
     */
    requiresLoginForContact?: boolean;
    /**
     * Whether the button is actionable
     */
    disabled?: boolean;
    /**
     * Toggles the old button design, which is still needed for Zameen
     */
    sharp?: boolean;
    variant?: Values<typeof ButtonVariant>;
};

type SMSUrlProps = Pick<Props, 'trackSMSLead' | 'property'> & {
    i18n: I18n;
    language: string;
    intlNumberFormat: NumberFormatterFactory;
    currentAreaUnit: Values<(typeof UnitType)['Area']>;
    currency: Values<typeof Currency>;
    baseCurrency: Values<typeof Currency>;
    exchangeRates: ExchangeRates;
    visitorID: string | null;
    viewSection: Values<typeof ViewSections>;
};

/**
 * Renders a button that when clicked, forms a text
 * message for the user to send to the agency.
 */
const smsURLDefaultPromise = (props: SMSUrlProps): Promise<string> =>
    props.trackSMSLead(props.viewSection).then((traceID) => {
        if (CONFIG.build.STRAT_ENABLE_SMART_SHARE) {
            new SmartShareAPI().createSmartShare(
                traceID,
                SmartShareLeadType.SMS,
                props.visitorID,
                props.property.externalID,
                props.language,
            );
        }

        return generateSMSURL({
            ...props,
            traceID,
        });
    });

const SmsButton = (props: Props) => {
    const i18n = useSelector(selectI18n);
    const language = useSelector(selectLanguage);
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const { areaUnit } = useSelector(settings.selectAreaUnit);
    const { currency, baseCurrency, exchangeRates } = useSelector(selectCurrency);
    const visitorID = useSelector(selectVisitorID);

    const {
        className,
        sharp,
        buttonClassName,
        requiresLoginForContact,
        onClick,
        trackSMSLead,
        property,
        withIcon,
        disabled = false,
        viewSection = ViewSections.SEARCH_RESULTS,
        renderIcon = () =>
            React.createElement(iconSMS, {
                className: styles.icon,
            }),
    } = props;
    const smsPromise = props.smsURLPromise || smsURLDefaultPromise;

    const onClickButton = React.useCallback(
        (event: React.SyntheticEvent<any>): void => {
            if (onClick) {
                onClick(event);
            }
            smsPromise({
                trackSMSLead,
                viewSection,
                property,
                i18n,
                language,
                intlNumberFormat: numberFormatterFactory,
                currentAreaUnit: areaUnit,
                currency,
                baseCurrency,
                exchangeRates,
                visitorID,
            }).then((url) => {
                window.location.href = url;
            });
        },
        [
            smsPromise,
            onClick,
            trackSMSLead,
            viewSection,
            property,
            i18n,
            language,
            numberFormatterFactory,
            areaUnit,
            currency,
            baseCurrency,
            exchangeRates,
            visitorID,
        ],
    );

    const onClickLogInRestricted = useLoginRestrictedCallback({
        onClick: onClickButton,
        userPath: Triggers.SMS_LEAD,
    });

    return (
        <button
            className={classNames(buttonClassName || styles.button, className, {
                [styles.disabled]: disabled,
                [styles.updatedButton]: !sharp,
                [styles.light]: !disabled && props.variant === ButtonVariant.LIGHT,
            })}
            aria-label="SMS"
            onClick={requiresLoginForContact ? onClickLogInRestricted : onClickButton}
            disabled={disabled}
        >
            {withIcon && renderIcon()}
            <Trans>SMS</Trans>
        </button>
    );
};

export { smsURLDefaultPromise };

export default withGTMLeadTracking(SmsButton);
