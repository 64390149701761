import * as React from 'react';
import { Trans } from '@lingui/macro';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { Input, Button, LoadingSpinner, Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';

import { LocationFieldForm } from 'horizontal/fields';
import { AddressFormFields } from 'horizontal/fields/commonPostingFields';

import styles from './styles/addressForm.cssm';

type Props = {
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly isSubmitting: boolean;
    readonly handleSubmit: (arg1: FormikValues) => void;
};

const AddressForm = ({
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
}: Props) => {
    const initialValue = values[AddressFormFields.locationPath.attribute]?.at(-1);
    const i18n = useI18n();
    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.container}>
                <div className={styles.fields}>
                    <Input
                        name={'name'}
                        title={<Trans>Address name</Trans>}
                        onChange={(value) => setFieldValue('name', value)}
                        onBlur={handleBlur}
                        value={values.name}
                        errorMessage={touched.name && errors.name}
                        accepted={touched.name && !errors.name}
                    />
                    <LocationFieldForm
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        attribute={AddressFormFields.location.attribute}
                        initialLocation={initialValue}
                    />
                    <Input
                        name={'addressLineOne'}
                        title={<Trans>Address line 1</Trans>}
                        onChange={(value) => setFieldValue('addressLineOne', value)}
                        onBlur={handleBlur}
                        value={values.addressLineOne}
                        errorMessage={touched.addressLineOne && errors.addressLineOne}
                        accepted={touched.addressLineOne && !errors.addressLineOne}
                        placeholder={brandingSettings.addressPlaceholderText(i18n).addressLineOne}
                        lightPlaceholder={true}
                    />
                    <Input
                        name={'addressLineTwo'}
                        title={<Trans>Address line 2</Trans>}
                        onChange={(value) => setFieldValue('addressLineTwo', value)}
                        onBlur={handleBlur}
                        value={values.addressLineTwo}
                        errorMessage={touched.addressLineTwo && errors.addressLineTwo}
                        accepted={touched.addressLineTwo && !errors.addressLineTwo}
                        placeholder={brandingSettings.addressPlaceholderText(i18n).addressLineTwo}
                        lightPlaceholder={true}
                    />
                    <Input
                        name={'notes'}
                        title={<Trans>Notes</Trans>}
                        multiLine
                        maxLength={4096}
                        onChange={(value) => setFieldValue('notes', value)}
                        onBlur={handleBlur}
                        value={values.notes}
                        errorMessage={touched.notes && errors.notes}
                        accepted={touched.notes && !errors.notes}
                    />
                </div>
                <Flex className={styles.submitButton}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: (false | Element)[]; disabled: boolean; primary: true; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button disabled={isSubmitting} primary>
                        {!isSubmitting && <Trans>Save Address</Trans>}
                        {isSubmitting && <LoadingSpinner />}
                    </Button>
                </Flex>
            </div>
        </form>
    );
};

export default AddressForm;
