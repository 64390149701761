import * as React from 'react';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import { Flex, Text } from 'strat/components';

import { VerticalsSearchFields } from './searchBox';
import styles from './styles/propertiesSearch.cssm';

const PropertiesSearchBox = () => {
    return (
        <Flex justifyCenter alignCenter className={styles.container}>
            <PageWidthWrapper>
                <Flex alignCenter column>
                    <h1 className={styles.title}>
                        <Trans>Your next property is here.</Trans>
                    </h1>
                    <Text.Base className={styles.subTitle}>
                        <Trans>Let's find a home that's perfect for you</Trans>
                    </Text.Base>
                    <VerticalsSearchFields />
                </Flex>
            </PageWidthWrapper>
        </Flex>
    );
};

export default PropertiesSearchBox;
