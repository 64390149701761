import * as React from 'react';
import classNames from 'classnames';
import { AdBanner } from 'strat/tagmanager';
import type { Size } from 'strat/tagmanager';

import type { MixedAd } from 'horizontal/types';
import { AdFormat } from 'horizontal/types';

import useAdUnitName from './useAdUnitName';
import { PageType, Position } from './types';
import useDetailTargetingParams from './useAdDetailTargetingParams';
import styles from './styles/banner.cssm';

type Props = {
    readonly ad: MixedAd;
    readonly sizes?: Array<Size>;
    readonly position: Values<typeof Position>;
    readonly slotIndex?: number;
    readonly disableStyle?: boolean;
};

const AdDetailBanner = ({ ad, sizes, position, slotIndex, disableStyle = false }: Props) => {
    const name = useAdUnitName(PageType.DETAIL, ad.category);
    const targetingParams = useDetailTargetingParams(position, slotIndex, ad);

    if (ad.format === AdFormat.LITE) {
        return null;
    }

    return (
        <AdBanner
            name={name}
            sizes={sizes}
            parameters={targetingParams}
            index={slotIndex}
            className={classNames({ [styles.adBanner]: !disableStyle })}
            collapsible
        />
    );
};

export default AdDetailBanner;
