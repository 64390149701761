import { defaultMemoize } from 'reselect';
import { polygon } from '@turf/helpers';
import pointInPolygon from 'point-in-polygon-hao';
import bbox from '@turf/bbox';

import EMPTY_OBJECT from 'strat/empty/object';

import type { Isochrone, Isochrones, MapBasedSearchListing } from '../types';
import * as PackedListing from '../packedListing';

import type { CommuteTimes } from './types';

const pointInBBox = (longitude: number, latitude: number, bounds: Array<number>) =>
    bounds[0] <= longitude &&
    bounds[1] <= latitude &&
    bounds[2] >= longitude &&
    bounds[3] >= latitude;

// this is not fast enough. Maybe try https://github.com/mikolalysenko/point-in-big-polygon ?
const computeCommuteTime = (listings: Array<MapBasedSearchListing>, isochrone: Isochrone) => {
    const cache = new Map();
    const isochronePolygons = isochrone.features
        .map((feature) =>
            // @ts-expect-error - TS2345 - Argument of type 'Geoloc | PolygonCoordinates | MultiPolygonCoordinates' is not assignable to parameter of type 'Position[][]'.
            polygon(feature.geometry.coordinates, feature.properties, {
                bbox: bbox(feature),
            }),
        )
        .sort((a, b) => a.properties.contour - b.properties.contour);

    const commuteTimes: {
        [key: number]: number;
    } = {};

    for (let j = 0; j < listings.length; j++) {
        const listing = listings[j];
        // @ts-expect-error - TS2345 - Argument of type 'MapBasedSearchListing' is not assignable to parameter of type 'PackedListingType'.
        const longitude = PackedListing.longitude(listing);
        // @ts-expect-error - TS2345 - Argument of type 'MapBasedSearchListing' is not assignable to parameter of type 'PackedListingType'.
        const latitude = PackedListing.latitude(listing);
        // @ts-expect-error - TS2345 - Argument of type 'MapBasedSearchListing' is not assignable to parameter of type 'PackedListingType'.
        const id = PackedListing.id(listing);
        const key = `${longitude},${latitude}`;

        if (cache.has(key)) {
            const time = cache.get(key);
            if (time && time > 0) {
                commuteTimes[id] = time;
            }
        } else {
            let time = -1;
            for (let i = 0; i < isochronePolygons.length; i++) {
                const isochronePolygon = isochronePolygons[i];

                if (
                    // @ts-expect-error - TS2345 - Argument of type 'BBox | undefined' is not assignable to parameter of type 'number[]'.
                    pointInBBox(longitude, latitude, isochronePolygon.bbox) &&
                    pointInPolygon([longitude, latitude], isochronePolygon.geometry.coordinates)
                ) {
                    time = isochronePolygon.properties.contour;
                    break;
                }
            }

            cache.set(key, time);
            if (time > 0) {
                commuteTimes[id] = time;
            }
        }
    }

    return commuteTimes;
};

export const computeCommuteTimes = defaultMemoize(
    (isochrones: Isochrones, listings: Array<MapBasedSearchListing>): CommuteTimes => {
        if (!isochrones || !listings) {
            return EMPTY_OBJECT;
        }

        const commuteTimesObject = Object.entries(isochrones).reduce<Record<string, any>>(
            (result, [key, isochrone]: [any, any]) => {
                result[key] = computeCommuteTime(listings, isochrone);

                return result;
            },
            {},
        );

        // @ts-expect-error - TS2322 - Type 'Isochrones' is not assignable to type 'CommuteTimes'.
        return commuteTimesObject as Isochrones;
    },
);
