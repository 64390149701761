import React from 'react';
import { Trans } from '@lingui/macro';

import { formatDate, useI18n } from 'strat/i18n/language';
import { MonthlyProjectPaymentPlan } from 'strat/project/types';

import { CommonHeader, MilestoneAxisSegment } from './paymentPlanTable';
import styles from './styles/paymentPlanTable.cssm';
import useMonthlyPaymentTimeline, {
    MonthlyPaymentPlanMilestone,
} from './useMonthlyPaymentTimeline';

export const FORMATTER_OPTIONS = {
    year: 'numeric',
    month: 'long',
} as const;

const Header = () => (
    <tr className={styles.threeColumns}>
        <CommonHeader />
        <th>
            <Trans>Payment Date</Trans>
        </th>
    </tr>
);

type RowProps = {
    milestone: MonthlyPaymentPlanMilestone;
};

const Row = ({ milestone }: RowProps) => {
    const i18n = useI18n();
    const dueDate = milestone.dueDate
        ? formatDate(new Date(milestone.dueDate), i18n.locale, FORMATTER_OPTIONS)
        : '-';

    return (
        <tr>
            <MilestoneAxisSegment />
            <td>{milestone.title}</td>
            <td className={styles.boldText}>{milestone.valuePercentage}%</td>
            <td>{dueDate}</td>
        </tr>
    );
};

type Props = {
    paymentPlan: MonthlyProjectPaymentPlan;
};

const MonthlyPaymentPlan = ({ paymentPlan }: Props) => {
    const timeline = useMonthlyPaymentTimeline(paymentPlan);

    return (
        <>
            <Header />
            {timeline.map((milestone, index) => (
                <Row key={index} milestone={milestone} />
            ))}
        </>
    );
};

export default MonthlyPaymentPlan;
