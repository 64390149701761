import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import Category from '@app/branding/category';
import createPropertyURL from '@app/routes/createPropertyURL';

import type { CategoryNodeData, PropertyData } from 'strat/property/types';
import Purpose, { PurposeTextDisplay } from 'strat/purpose';
import { ThumbnailSizes, ThumbnailSizeValues, thumbnailURL } from 'strat/image';
import { SchemaType } from 'strat/schema/types';
import type { PropertyRouteParams } from 'strat/property/url';
import { buildCanonicalURL } from 'strat/routing';
import type { SearchParams as AgentSearchParams } from 'strat/agency/agent/propertySection/url';
import { createAgentDetailsURL } from 'strat/agency/agent/propertySection/url';
import type { SearchParams as AgencySearchParams } from 'strat/agency/propertySection/url';
import { createAgencyDetailsURL } from 'strat/agency/propertySection/url';

const getResidenceType = (categories: Array<CategoryNodeData> | null) => {
    if (!categories) {
        return SchemaType.RESIDENCE;
    }

    // @ts-ignore
    if (Category.isOfCommercialType(categories[0])) {
        return SchemaType.PLACE;
    }
    if (categories.length < 2) {
        return SchemaType.RESIDENCE;
    }
    // @ts-ignore
    if (Category.isOfApartmentType(categories[1])) {
        return SchemaType.APARTMENT;
    }
    // @ts-ignore
    if (Category.isOfVillasType(categories[1])) {
        return SchemaType.HOUSE;
    }
    return SchemaType.RESIDENCE;
};

const getAlternateTitle = (property: PropertyData, i18n: I18n) => {
    const bedroomCount = property.rooms;
    const categoryToken =
        property.category && property.category.length > 1
            ? property.category[1].nameSingular
            : t(i18n)`Property`;

    // take the location with the greatest location level
    const location = property.location.slice(-1)[0];

    let alternateTitle = t(i18n)`${categoryToken} ${Purpose.text(
        i18n,
        property.purpose,
        PurposeTextDisplay.CONSISTENT_PREPOSITION,
    )} in ${location.name}`;
    if (bedroomCount) {
        // bed count is specified and not zero, then add the bed text
        const bedText = t(i18n)`${plural(bedroomCount, {
            one: '# Bedroom',
            two: '# Bedroom',
            few: '# Bedroom',
            many: '# Bedroom',
            other: '# Bedroom',
        })}`;

        alternateTitle = t(i18n)`${bedText} ${alternateTitle}`;
    } else if (property.isStudio) {
        alternateTitle = t(i18n)`Studio ${alternateTitle}`;
    }

    return alternateTitle;
};

const getHitsWithPriceExist = (hits: Array<PropertyData>) => {
    if (!hits) {
        return false;
    }
    return hits.filter((hit) => hit.price).length > 0;
};

const getAgencyLogoThumbnail = (agency: any) => {
    if (agency && agency.logo && agency.logo.id) {
        return thumbnailURL(agency.logo.id, 'jpeg', ThumbnailSizeValues[ThumbnailSizes.SMALL]);
    }
    return null;
};

const computePropertyDetailsURL = (params: PropertyRouteParams, language: string) => {
    // @ts-ignore
    const path = createPropertyURL(params).pathname;
    return buildCanonicalURL(path, language);
};

const computeAgentDetailsURL = (params: AgentSearchParams, language: string) => {
    const path = createAgentDetailsURL(params).pathname;
    return buildCanonicalURL(path, language);
};

const computeAgencyDetailsURL = (params: AgencySearchParams, language: string) => {
    const path = createAgencyDetailsURL(params).pathname;
    return buildCanonicalURL(path, language);
};

export {
    getResidenceType,
    getAlternateTitle,
    getHitsWithPriceExist,
    getAgencyLogoThumbnail,
    computePropertyDetailsURL,
    computeAgentDetailsURL,
    computeAgencyDetailsURL,
};
