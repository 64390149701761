export type Geoloc = {
    lat: number;
    lng: number;
};

export type LinearRing = Array<Geoloc>;

export type LineString = Array<Geoloc>;

export type PolygonCoordinates = Array<LinearRing>;

export type MultiPolygonCoordinates = Array<PolygonCoordinates>;

export type MultiPolygon = {
    type: 'MultiPolygon';
    coordinates: MultiPolygonCoordinates;
};

export type Polygon = {
    type: 'Polygon';
    coordinates: PolygonCoordinates;
};

export type Point = {
    type: 'Point';
    coordinates: Geoloc;
};

export type Geometry = Point | Polygon | MultiPolygon;

const ControlsPosition = Object.freeze({
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right',
});

const PlacesType = Object.freeze({
    EDUCATION: 'EDUCATION',
    RESTAURANTS: 'FOOD_BEVERAGE',
    HOSPITALS: 'MEDICAL_HEALTH',
    RECREATION: 'FITNESS_RECREATION',
});

const GeoapifyPlacesType = Object.freeze({
    [PlacesType.EDUCATION]: 'education',
    [PlacesType.RESTAURANTS]: 'catering',
    [PlacesType.HOSPITALS]: 'healthcare',
    [PlacesType.RECREATION]: 'leisure',
});

export type Place = {
    name: string;
    rating?: number;
    center: Geoloc;
    category: Values<typeof PlacesType>;
    id: string;
};

export type MarkerProps = {
    key: string | null;
    position: LatLng;
    autoFit: boolean;
};

export type Places = Array<Place>;

export type SimpleAddress = {
    text: string;
    type?: string;
    filter?: string;
    bias?: string;
    format?: string;
    limit?: number;
};

export { ControlsPosition, PlacesType, GeoapifyPlacesType };
