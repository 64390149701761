import * as React from 'react';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';
import classNames from 'classnames';

import type { AdPhoto } from 'horizontal/types';
import DefaultThumbnailImage from 'horizontal/adDetails/gallery/defaultThumbnailImage';
import type { LiteCategory } from 'horizontal/types';

import styles from './styles/coverPhoto.cssm';

type Props = {
    cover: AdPhoto | null | undefined;
    size?: Values<typeof ThumbnailSizes>;
    lazyLoad?: boolean;
    category?: Array<LiteCategory>;
    roundedCorners?: boolean;
    className?: string;
};

const CoverPhoto = ({
    cover,
    size = ThumbnailSizes.SMALL,
    lazyLoad = true,
    category,
    roundedCorners,
    className = styles.image,
}: Props) => {
    if (!cover) {
        return <DefaultThumbnailImage category={category} />;
    }

    return (
        <ThumbnailImage
            className={classNames(className, {
                [styles.roundedCorners]: roundedCorners,
            })}
            imageID={cover.id}
            size={size}
            alt={cover.title}
            title={cover.title}
            label="Cover photo"
            lazyLoad={lazyLoad}
        />
    );
};

export default CoverPhoto;
