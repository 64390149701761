import * as React from 'react';

import type { AdContactInfo } from 'horizontal/types';
import { useFetchAdContactInfo } from 'horizontal/ad';
import { useAd } from 'horizontal/ad/state';
import { ContactInfoFields } from 'horizontal/fields/commonPostingFields';

const useInitialSellerFieldsValues = (): AdContactInfo => {
    const ad = useAd();

    const { fetchContactInfo, contactInfo } = useFetchAdContactInfo(ad?.externalID);

    React.useEffect(() => fetchContactInfo(), [fetchContactInfo]);

    // @ts-expect-error - TS2322 - Type '{ name: Primitive; mobile: Primitive; roles: Primitive | ("show_phone_number" | "allow_chat_communication" | "show_short_number")[]; }' is not assignable to type 'AdContactInfo'.
    return React.useMemo(() => {
        return {
            name: contactInfo?.name || ContactInfoFields.name.defaultValue,
            mobile: contactInfo?.mobile || ContactInfoFields.phone_number.defaultValue,
            roles: contactInfo?.roles || ContactInfoFields.roles.defaultValue,
        };
    }, [contactInfo]);
};

export default useInitialSellerFieldsValues;
