import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { PaymentJazzCashMWalletRouteParams } from 'horizontal/routes';

type UseNavigateToJazzCashMWalletPayment = (
    arg1?: PaymentJazzCashMWalletRouteParams | null | undefined,
) => void;

const useNavigateToJazzCashMWalletPayment = (): UseNavigateToJazzCashMWalletPayment => {
    const router = useRouter();

    return React.useCallback(
        (params?: PaymentJazzCashMWalletRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_JAZZ_CASH_MWALLET, params);
        },
        [router],
    );
};

export default useNavigateToJazzCashMWalletPayment;
