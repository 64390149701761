import * as React from 'react';
import { useDispatch } from 'react-redux';
import ContactFormDialog from '@app/contact/contactFormDialog';

import { CallButton } from 'strat/contact/buttons';
import { ListingEmailButton } from 'strat/listing';
import { ContactFormModal } from 'strat/contact';
import { ViewSections } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm';
import type { ScrollNavProps } from 'strat/navigation/scroll';
import { addSilentAlert } from 'strat/alerts/state/creators';
import { InternalAPI } from 'strat/api';
import { WhatsAppButton } from 'strat/listing';
import { ButtonVariant } from 'strat/components';

import type { PropertyData } from './types';
import styles from './styles/propertyNavBar.cssm';

/**
 * Properties for {@see PropertyNavBarSideButtons}.
 */
export type Props = GTMLeadTrackingProps &
    ScrollNavProps & {
        property: PropertyData;
        onCallClick?: () => void;
        renderContactForm?: () => React.ReactElement;
        withIcon?: boolean;
        variant?: Values<typeof ButtonVariant>;
    };

const PropertyNavBarSideButtons = ({ variant = ButtonVariant.LIGHT, ...props }: Props) => {
    const dispatch = useDispatch();
    const {
        phoneNumber,
        permitNumber,
        referenceNumber,
        externalID,
        contactName,
        agency,
        id,
        primaryPhoneNumber,
        active,
        requiresLoginForContact,
        hasEmail,
    } = props.property;

    if (!active) {
        return null;
    }

    return (
        <>
            <CallButton
                phoneNumberData={phoneNumber}
                referenceNumber={referenceNumber}
                agencyName={agency?.name}
                contactName={contactName}
                externalID={externalID}
                className={styles.callButton}
                onClick={() =>
                    props.onCallClick
                        ? props.onCallClick()
                        : props.trackCallView(ViewSections.PROPERTY_DETAIL)
                }
                onPhoneNumberClick={() => props.trackCallLead(ViewSections.PROPERTY_DETAIL)}
                withIcon={props.withIcon}
                requiresLoginForContact={requiresLoginForContact}
                variant={variant}
            />
            {hasEmail && (
                <ListingEmailButton
                    // @ts-expect-error - TS7006 - Parameter 'dialogProps' implicitly has an 'any' type.
                    renderContactFormModal={(dialogProps) => (
                        <ContactFormDialog
                            {...dialogProps}
                            // @ts-expect-error - TS7006 - Parameter 'modalProps' implicitly has an 'any' type.
                            renderModalContents={(modalProps) => (
                                <ContactFormModal
                                    {...modalProps}
                                    renderContactForm={props.renderContactForm}
                                />
                            )}
                        />
                    )}
                    contactName={contactName}
                    agency={agency}
                    propertyID={id}
                    externalID={externalID}
                    permitNumber={permitNumber}
                    referenceNumber={referenceNumber}
                    phoneNumber={primaryPhoneNumber}
                    className={styles.emailButton}
                    onClick={() => props.trackEmailView(ViewSections.PROPERTY_DETAIL)}
                    onCallClick={() => props.trackCallView(ViewSections.EMAIL_POPUP)}
                    onPhoneNumberClick={() => props.trackCallLead(ViewSections.EMAIL_POPUP)}
                    // @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
                    onSendEmailClick={(...args) => {
                        // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                        props.trackEmailLead(ViewSections.EMAIL_POPUP, ...args);
                        if (CONFIG.build.ENABLE_STRAT_EMAILS) {
                            // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                            new InternalAPI().sendEmail(externalID, ...args);
                        }
                        if (CONFIG.build.SHOW_AGENT_REVIEWS) {
                            new InternalAPI().scheduleReviewRequestReminder(
                                externalID,
                                // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                                ...args,
                            );
                        }
                    }}
                    // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
                    onKeepMeInformed={(values) => {
                        // @ts-expect-error - TS2339 - Property 'addSilentAlert' does not exist on type 'Props'.
                        dispatch(addSilentAlert(values, props.property));
                    }}
                    withIcon={props.withIcon}
                    hasEmail
                    variant={variant}
                />
            )}
            <WhatsAppButton
                property={props.property}
                className={styles.whatsAppButton}
                variant={variant}
            />
        </>
    );
};

export default React.memo(PropertyNavBarSideButtons);
