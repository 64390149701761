import React from 'react';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import StarsIcon from 'horizontal/assets/icons/geminiStarsIcon.svg';

import styles from './styles/autofillNotice.cssm';

const AutofillNotice = () => {
    const [isDismissed, setIsDismissed] = React.useState(false);
    if (isDismissed) {
        return null;
    }
    return (
        <Flex className={styles.root}>
            <StarsIcon />
            <div className={styles.text}>
                <Text.Large>
                    <Trans>
                        By entering the <b>Version</b> and <b>Generation</b> of the car you want to
                        sell, you will unlock the <b>Auto-fill</b> feature
                    </Trans>
                </Text.Large>
            </div>
            <IconClose className={styles.closeIcon} onClick={() => setIsDismissed(true)} />
        </Flex>
    );
};

export default AutofillNotice;
