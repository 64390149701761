import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';

import type { UpdatedAdResponseProps } from '../types';
import updateResponseIndexToSlotIndex from '../common/updateResponseIndexToSlotIndex';
import { SearchJobName } from '../types';

const updatedAdsResponse = ({ searchHandler }: UpdatedAdResponseProps): SearchResponse | null => {
    const job = searchHandler.getJobByJobName(SearchJobName.REGULAR);
    const response = searchHandler.getResponseByJobName(SearchJobName.REGULAR);
    if (!job || !response) {
        return null;
    }
    return updateResponseIndexToSlotIndex(job, response);
};

export default updatedAdsResponse;
