import * as React from 'react';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import settings from '@app/branding/settings';
import { Dialog, Flex } from 'strat/components';
import { DialogComponentProps } from 'strat/dialogs/types';
import WhatsappIcon from 'strat/assets/icons/iconWhatsapp.svg';
import { useI18n } from 'strat/i18n/language';
import { stableFormatPhoneNumber } from 'strat/i18n/phone/formatPhoneNumber';

import IconSMS from 'horizontal/assets/icons/iconEnvelope.svg';
import IconCall from 'horizontal/assets/icons/iconCall.svg';
import { CTA, LiteAd } from 'horizontal/types';
import CloseIcon from 'horizontal/assets/icons/iconClose.svg';
import { shouldDisplayProAgencyDataRegulationsDisclaimer } from 'horizontal/agency/roles';

import styles from './styles/ctaDialog.cssm';

type Props = {
    readonly ad: LiteAd;
    readonly phoneNumber: string;
    readonly ctaType: CTA;
    readonly onClick: () => void;
};

const CTADialog = ({
    visible,
    onVisibilityChanged,
    ad,
    phoneNumber,
    ctaType,
    onClick,
}: DialogComponentProps<Props>) => {
    const i18n = useI18n();

    if (!phoneNumber || !ad) {
        return null;
    }

    const renderPhoneIcon = () => {
        switch (ctaType) {
            case CTA.WHATSAPP:
                return <WhatsappIcon className={classNames(styles.phoneIcon, styles.whatsApp)} />;
            case CTA.SMS:
                return <IconSMS className={classNames(styles.phoneIcon, styles.call)} />;
            case CTA.CALL:
            default:
                return <IconCall className={classNames(styles.phoneIcon, styles.call)} />;
        }
    };

    return (
        <Dialog
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.dialogContainer}
            dismissible
        >
            <Flex className={styles.dialogContent}>
                <CloseIcon
                    className={styles.closeIcon}
                    onClick={() => onVisibilityChanged(false)}
                />
                <span className={styles.title}>{t(i18n)`Contact Us`}</span>
                <span className={styles.subtitle}>{ad?.contactInfo?.name}</span>
                <div
                    onClick={onClick}
                    className={classNames(styles.phoneContainer, {
                        [styles.whatsApp]: ctaType === CTA.WHATSAPP,
                        [styles.call]: [CTA.CALL, CTA.SMS].includes(ctaType),
                    })}
                >
                    {renderPhoneIcon()}
                    <span className={styles.phone} aria-label="Listing phone number">
                        +
                        {stableFormatPhoneNumber(
                            phoneNumber,
                            settings.phoneInputFormat ??
                                CONFIG.build.COUNTRY_PHONE_INPUT_FORMAT_DEFAULT,
                        )}
                    </span>
                </div>
                {shouldDisplayProAgencyDataRegulationsDisclaimer(ad.agency) && (
                    <span className={styles.disclaimer}>
                        {t(
                            i18n,
                        )`By contacting the Seller, you understand that your contact details will be
                        shared with the Seller, and that the Seller may contact you`}
                    </span>
                )}
            </Flex>
        </Dialog>
    );
};

export default CTADialog;
