import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { useActiveUserExternalID } from 'strat/user/session';
import { selectRouteParams } from 'strat/routes/selectors';
import type { AppDispatch } from 'strat/state';

import { useCategoryVars } from 'horizontal/gtm';
import { trackViewCart } from 'horizontal/gtm';
import { setAdOrderDetails } from 'horizontal/businessPackages/state';

type UseNavigateToViewCart = () => void;

const useNavigateToViewCart = (): UseNavigateToViewCart => {
    const router = useRouter();
    const { category, adExternalID } = useSelector(selectRouteParams);
    const categoryVars = useCategoryVars(category);
    const userExternalId = useActiveUserExternalID();
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback(() => {
        trackViewCart({ userExternalId, categoryVars });
        let params = {};
        if (CONFIG.runtime.STRAT_ENABLE_AFTER_PAYMENT_PROCESSING_PAGE_REDIRECTION) {
            dispatch(setAdOrderDetails(adExternalID));
            params = { adExternalID };
        }
        router.pushRoute(RouteNames.VIEW_BUSINESS_PACKAGE_CART_ROUTE, { ...params });
    }, [router, userExternalId, categoryVars, dispatch, adExternalID]);
};

export default useNavigateToViewCart;
