const TriggerName = Object.freeze({
    VERTICAL_SELECTED: 'vertical-selected',
    UNLINK_SOCIAL: 'unlink-social',
    LOGIN_FAILED: 'login-error',
    SIGNUP_FAILED: 'signup-failed',
    SIGNUP_SUCCESS: 'signup-success',
    LOGIN_SUCCESS: 'login-ok-channel',
    OTP_SENT: 'otp-sent',
    OTP_RESENT: 'otp-resent',
    EMAIL_LINK_SENT: 'email-link-sent',
    EMAIL_LINK_RESENT: 'email-link-resent',
    REGISTER_SUCCESS: 'register-ok-with',
    REGISTER_FAILED: 'register-fail-with',
    PAYMENT_SUCCESS: 'pay-ok-with',
    PAYMENT_FAILED: 'pay-fail-reason',
    PAYMENT_INITIATED: 'pay-with',
    CLICK_SIGNUP: 'click-signup',
    SET_NEW_PASSWORD: 'set-new-password',
});

export default TriggerName;
