import * as React from 'react';
import type { ChoiceValue } from 'strat/components/types';

import { DropdownVariants, FilterableDropdownProps } from './types';
import { RegularDropdownVariant, TextInputDropdownVariant } from './variants';

interface Props<T extends ChoiceValue> extends FilterableDropdownProps<T> {
    shouldHideInput?: boolean;
    searchQuery: string | null;
    setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedValueLabel?: string;
    variant?: DropdownVariants;
    boldLabel?: boolean;
}

const FilterableDropdownBase = <T extends ChoiceValue>({
    variant = DropdownVariants.REGULAR,
    isOpen,
    setSearchQuery,
    boldLabel,
    ...props
}: Props<T>) => {
    React.useEffect(() => {
        if (!isOpen) {
            setSearchQuery(null);
        }
    }, [isOpen, setSearchQuery]);

    if (variant === DropdownVariants.TEXT_INPUT) {
        return (
            <TextInputDropdownVariant
                isOpen={isOpen}
                setSearchQuery={setSearchQuery}
                {...props}
                boldLabel={boldLabel}
            />
        );
    }

    return (
        <RegularDropdownVariant
            isOpen={isOpen}
            setSearchQuery={setSearchQuery}
            {...props}
            boldLabel={boldLabel}
        />
    );
};

export default FilterableDropdownBase;
