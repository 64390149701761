import * as React from 'react';

import styles from './styles/sitemapList.cssm';

type Props = {
    readonly renderTitle: () => React.ReactElement;
    readonly renderListItems: () => React.ReactElement;
    readonly containerClass?: string;
};

const SitemapList = ({ renderTitle, renderListItems, containerClass }: Props) => {
    return (
        <div className={containerClass}>
            <div className={styles.title}>{renderTitle()}</div>
            <div className={styles.listItems}>{renderListItems()}</div>
        </div>
    );
};

export default SitemapList;
