import * as React from 'react';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';

import type { PropertyPhotoData } from 'strat/property/types';
import { NoImage, ThumbnailImage, ThumbnailSizes, SwipeableImageSlideshow } from 'strat/image';
import { ScrollContainerContext } from 'strat/util';

import styles from './styles/listingCardMinimalistGallery.cssm';

export type PropsPhoto = {
    imageID?: number;
    lazyLoad?: boolean;
    onLoad?: () => void;
    className?: string;
};

type Props = {
    coverPhotoID: number | null | undefined;
    lazyLoadCoverPhoto: boolean;
    photos: Array<PropertyPhotoData>;
    renderPhoto: (extraProps: PropsPhoto) => React.ReactElement;
    renderPhotoOverlay: () => React.ReactElement | null;
    renderListingLink: () => React.ReactElement;
    withNavArrows?: boolean;
    autoHideNavArrows?: boolean;
    onClick?: () => void;
    onImageNavigation?: (onImageNavigation: number) => void;
    className?: string;
    setCurrentSlideIndex?: React.Dispatch<React.SetStateAction<number>>;
    showBullets?: boolean;
};

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function usePrevious(value: Array<any>) {
    const ref = React.useRef();
    React.useEffect(() => {
        // @ts-expect-error - TS2322 - Type 'any[]' is not assignable to type 'undefined'.
        ref.current = value;
    });
    return ref.current;
}

/**
 * Renders a swipeable/arrow navigation photo gallery for a listing card.
 * Displays the cover photo until ImageGallery component is loaded async.
 * It does not support gallery dialog or fullscreen mode.
 * Except the first photo (coverPhoto), photo items are lazy loaded.
 *
 * It lazy loads the SwipableImageSlideshow to avoid loading the first
 * image for every listing on the page.
 */
const ListingCardSwipeableGallery = ({
    coverPhotoID,
    lazyLoadCoverPhoto,
    photos,
    renderPhoto,
    renderPhotoOverlay,
    onClick,
    onImageNavigation,
    withNavArrows,
    autoHideNavArrows,
    renderListingLink,
    className = styles.galleryContainer,
    setCurrentSlideIndex,
    showBullets = true,
}: Props) => {
    const [galleryLoaded, setGalleryLoaded] = React.useState(false);
    const [firstImageLoaded, setFirstImageLoaded] = React.useState(false);
    const [galleryReady, setGalleryReady] = React.useState(false);
    const [lazyLoad, setLazyLoad] = React.useState(false);

    const scrollContainer = React.useContext(ScrollContainerContext);
    const scrollContainerSelector = scrollContainer ? `.${scrollContainer.className}` : undefined;

    const prevPhotos = usePrevious(photos);

    React.useEffect(() => {
        // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
        const firstPhotoChanged = photos?.[0]?.id !== prevPhotos?.[0]?.id;
        if (firstPhotoChanged && firstImageLoaded) {
            setFirstImageLoaded(false);
            setLazyLoad(false);
        }
    }, [photos, prevPhotos, setFirstImageLoaded, firstImageLoaded]);

    const onImageLoaded = React.useCallback(
        (itemID: number) => {
            if (coverPhotoID === itemID && !firstImageLoaded) {
                setFirstImageLoaded(true);
            }
        },
        [coverPhotoID, firstImageLoaded, setFirstImageLoaded],
    );

    React.useEffect(() => {
        setGalleryReady(galleryLoaded && firstImageLoaded);
    }, [galleryLoaded, firstImageLoaded, setGalleryReady]);

    const renderGalleryItem = React.useCallback(
        (photo: PropertyPhotoData) => {
            // lazy load all items, except the first one in the image gallery
            // in order to avoid loading lots of images on the Search Page
            const lazyLoadGalleryItem = photo.id !== coverPhotoID;

            const extraProps: PropsPhoto = {
                imageID: photo.id,
                onLoad: () => onImageLoaded(photo.id),
                lazyLoad: lazyLoadGalleryItem,
                className: styles.galleryImage,
            };

            if (renderPhoto) {
                return (
                    <>
                        {renderListingLink()}
                        {renderPhoto(extraProps)}
                    </>
                );
            }

            return (
                <>
                    {renderListingLink()}
                    {/* @ts-expect-error - TS2322 - Type '{ imageID?: number | undefined; lazyLoad?: boolean | undefined; onLoad?: (() => void) | undefined; className?: string | undefined; title: string; alt: string; size: number; }' is not assignable to type 'Pick<Readonly<ThumbnailImageProps> & Readonly<{ children?: ReactNode; }>, "className" | "children" | "alt" | "label" | "title" | ... 8 more ... | "lazyLoad">'. */}
                    <ThumbnailImage
                        title={photo.title || ''}
                        alt={photo.title || ''}
                        size={ThumbnailSizes.NORMAL}
                        {...extraProps}
                    />
                </>
            );
        },
        [onImageLoaded, renderPhoto, renderListingLink, coverPhotoID],
    );

    const coverPhotoClass = classNames(styles.coverPhoto, {
        [styles.hidden]: galleryReady,
    });

    return (
        <div className={className} aria-label="Gallery Container">
            <div className={coverPhotoClass} aria-label="Cover Photo">
                {coverPhotoID ? (
                    renderPhoto({
                        lazyLoad: lazyLoadCoverPhoto,
                        className: styles.galleryImage,
                    })
                ) : (
                    <NoImage />
                )}
                {renderListingLink()}
            </div>
            {photos && photos.length > 0 && (
                <LazyLoad
                    scrollContainer={scrollContainerSelector}
                    className={styles.lazyLoadWrapper}
                >
                    <SwipeableImageSlideshow
                        // @ts-expect-error connectors are not properly typed
                        items={photos}
                        itemCount={photos.length}
                        renderItem={renderGalleryItem}
                        onImageGalleryReady={() => setGalleryLoaded(true)}
                        onClick={onClick}
                        onImageNavigation={onImageNavigation}
                        withNavArrows={withNavArrows}
                        autoHideNavArrows={autoHideNavArrows}
                        onFirstSlide={() => setLazyLoad(true)}
                        lazyLoad={lazyLoad}
                        hide={!galleryReady}
                        className={styles.swipeableArea}
                        setCurrentSlideIndex={setCurrentSlideIndex}
                        showBullets={showBullets}
                    />
                </LazyLoad>
            )}
            {renderPhotoOverlay()}
        </div>
    );
};

export default ListingCardSwipeableGallery;
