//@ts-nocheck
import pick from 'lodash/pick';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, RefinementFilter } from '@sector-labs/fe-search-redux/filters';
import { AlgoliaSearchBackend } from '@sector-labs/fe-search-redux/backend/algolia';
import { SearchResponseHit } from '@sector-labs/fe-search-redux/backend';

import { determineLocationsIndexName } from 'strat/search/indexNames';
import { logError } from 'strat/error/log';
import { localizedFieldKey, locationTransformer } from 'strat/mergedIndex';

const LOCALIZED_ATTRIBUTES = ['name', 'slug'];

const localizeAttributes = (attributesToRetrieve: string[], language: string): string[] => {
    return attributesToRetrieve.map((attribute: string) => {
        if (LOCALIZED_ATTRIBUTES.includes(attribute)) {
            return localizedFieldKey(
                attribute,
                language,
                CONFIG.build.ENABLE_LOCATIONS_MERGED_INDEX,
            );
        }
        return attribute;
    });
};

const fetchLocationsAttributes = (
    locationIDs: Set<any>,
    attributesToRetrieve: string[] = ['slug'],
    language = CONFIG.build.LANGUAGE_CODE,
    attribute = 'externalID',
    hitsPerPage?: number,
): Promise<Map<string, SearchResponseHit>> => {
    const algoliaBackend = new AlgoliaSearchBackend({
        appId: CONFIG.build.ALGOLIA_APP_ID,
        apiKey: CONFIG.build.ALGOLIA_SEARCH_API_KEY,
    });

    const localizedAttributes = localizeAttributes(attributesToRetrieve, language);
    const filters = new FilterCollection();
    filters.refine(
        new RefinementFilter({
            attribute,
            value: Array.from(locationIDs),
        }),
    );

    const job = new SearchJob(determineLocationsIndexName({ language }), filters, {
        attributesToRetrieve: [attribute, ...localizedAttributes],
        hitsPerPage: hitsPerPage ?? locationIDs.size,
    });

    return new SearchService({ backend: algoliaBackend })
        .fetchJob(job)
        .then((response) => {
            const locationIdSlugMap = new Map();
            const retrieveAllAttributes = attributesToRetrieve.includes('*');
            response.hits.forEach((hit) => {
                const locationAttributes = retrieveAllAttributes
                    ? hit
                    : pick(hit, localizedAttributes);
                locationIdSlugMap.set(
                    hit[attribute],
                    locationTransformer(
                        locationAttributes,
                        language,
                        CONFIG.build.ENABLE_LOCATIONS_MERGED_INDEX,
                        true,
                    ),
                );
            });
            return locationIdSlugMap;
        })
        .catch((e) => {
            logError({
                e,
                msg: 'Failed to fetch location attributes',
            });

            if (CONFIG.build.DEBUG) {
                throw e;
            }
        });
};

export default fetchLocationsAttributes;
