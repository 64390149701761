import * as React from 'react';

type ChoiceWithPriority = {
    readonly displayPriority: number;
};

type ChoiceWithLabel = {
    readonly label: string;
};

export enum SortingOptions {
    DEFAULT_SORTING = 'defaultSorting',
    SORT_ALPHABETICALLY = 'sortAlphabetically',
    // This option was added so we can use the hook and only sort conditionally
    // There are cases when a sort is needed only if some condition is fulfilled
    // and conditional hooks are not accepted. Hence, this approach.
    SORT_NONE = 'sortNone',
}

const sortingFunctions = {
    defaultSorting: (a: ChoiceWithPriority, b: ChoiceWithPriority) =>
        a.displayPriority - b.displayPriority,
    sortAlphabetically: (a: ChoiceWithLabel, b: ChoiceWithLabel) => a.label.localeCompare(b.label),
} as const;

const useSortedChoices = <
    T extends {
        readonly displayPriority: number;
        readonly label: string;
    },
>(
    choices?: Array<T> | null,
    sortType?: SortingOptions | null,
): Array<T> => {
    return React.useMemo(() => {
        const sortOption = sortType || SortingOptions.DEFAULT_SORTING;

        if (sortOption === SortingOptions.SORT_NONE) {
            return choices || [];
        }

        const sortFunction =
            sortingFunctions[sortOption] || sortingFunctions[SortingOptions.DEFAULT_SORTING];

        return (choices || []).sort((a, b) => {
            return sortFunction(a, b);
        });
    }, [choices, sortType]);
};

export default useSortedChoices;
