import React from 'react';

import IconBack from 'strat/assets/icons/iconArrowLeft.svg';

import styles from './styles/backButton.cssm';

type Props = {
    onClick?: () => void;
    className: string;
};

const BackButton = ({ onClick, className }: Props) => {
    return (
        <div onClick={onClick} className={className} aria-label="Back button">
            <IconBack className={styles.backIcon} />
        </div>
    );
};

export default BackButton;
