import type { AppDispatch, GlobalState } from 'strat/state';
import { selectLanguage } from 'strat/i18n/language';
import { selectIsMobile } from 'strat/platform/selectors';

import { setMainWallpaper } from './state';
import { selectWallpaper } from './selectors';

/**
 *  Returns the wallpaper image url based on the language.
 *  The format for the wallpaper images is:
 *  - for mobile: imageBackground<SHORT_LANGUAGE_CODE>.jpg
 *  - for desktop: imageBackgroundLarge<SHORT_LANGUAGE_CODE>.jpg
 *  In case we don't find an image for the language we fallback to the image without it.
 */
const fetchWallpaperImageURL = (language: string, isMobile: boolean, extension: 'jpg' | 'webp') => {
    const imageName = isMobile ? 'imageBackground' : 'imageBackgroundLarge';
    const fallbackImageFile = `${imageName}.${extension}`;
    const imageFile = `${imageName}${language.toUpperCase()}.${extension}`;

    // Disable lint rule for webpackChunkName since we use webpackMode eager which generates no extra chunk
    return (
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(/* webpackMode: "eager" */ `@app/assets/icons/${imageFile}`)
            .catch(
                // eslint-disable-next-line import/dynamic-import-chunkname
                () => import(/* webpackMode: "eager" */ `@app/assets/icons/${fallbackImageFile}`),
            )
            .then((module) => module.default)
    );
};

const fetchWallpaper = (state: GlobalState, dispatch: AppDispatch, useWebp = false) => {
    const language = selectLanguage(state);
    const isMobile = selectIsMobile(state);
    const { imageURL } = selectWallpaper(state);

    if (imageURL) {
        return Promise.resolve();
    }

    const promises = [fetchWallpaperImageURL(language, isMobile, 'jpg')];
    if (useWebp) {
        promises.push(fetchWallpaperImageURL(language, isMobile, 'webp'));
    }
    return Promise.all(promises).then(([imageURL, webpImageURL]) => {
        dispatch(setMainWallpaper(imageURL, webpImageURL));
    });
};

export default fetchWallpaper;
