import { RangeFilterValue } from '@sector-labs/fe-search-redux/filters';

import type { AgentData } from 'strat/agency/agent/types';
import { AgentSortByValues } from 'strat/searchComponents';

import type { AgentBadgeData } from './badges/types';
import { AgentActivityData } from './activity/types';

export type TruBrokerLocation = {
    name: string;
    externalID: string;
    slug: string;
};

export const TruBrokerLocations: { [key: string]: TruBrokerLocation } = {
    DUBAI: {
        name: 'Dubai',
        externalID: '5002',
        slug: '/dubai',
    },
    ABU_DHABI: {
        name: 'Abu Dhabi',
        externalID: '6020',
        slug: '/abu-dhabi',
    },
};

export type LeaderboardAgentData = AgentData & {
    nameEn: string;
    user_image: string | null | undefined;
    user_image_id: string | null | undefined;
    agency: {
        external_id: string;
        name: string | null | undefined;
    };
    loc_2_external_id?: Array<string>;
    experience: string | null | undefined;
    hasBRN: boolean;
    comboXP: number;
    experiencePoints: number;
    experiencePointsLast3Months: number;
    globalRankLast3Months: number;
    truBrokerRanking: number;
    truBrokerRankingLast3Months: number;
    listingsInDubaiCurrentMonth: number;
    listingsInAbuDhabiCurrentMonth: number;
    cubeLocationTruBrokerRanking: number;
    cubeLocationCategoryPurposeTruBrokerRanking: number;
    cubeCategoryPurposeTruBrokerRanking: number;
    cubeLocationPurposeTruBrokerRanking: number;
    cubeCategoryTruBrokerRanking: number;
    cubePurposeTruBrokerRanking: number;
    cubeLocationCategoryTruBrokerRanking: number;
    activeInEmirate: string;
    hasWhatsappTrackingEnabled: boolean;
    hasCallTrackingEnabled: boolean;
    activeInEmirateCurrent: string;
    badgeProgress?: AgentBadgeData | null;
    activities?: AgentActivityData | null;
};

export type AgentForSearchCard = AgentData & {
    truBrokerRanking?: number;
    comboXP: number;
    cubeLocationTruBrokerRanking: number;
    cubeLocationCategoryPurposeTruBrokerRanking: number;
    cubeCategoryPurposeTruBrokerRanking: number;
    cubeLocationPurposeTruBrokerRanking: number;
    cubeCategoryTruBrokerRanking: number;
    cubePurposeTruBrokerRanking: number;
    cubeLocationCategoryTruBrokerRanking: number;
};

export type RawLeaderboardEntry = {
    agent_external_id: string;
    agency_id: string;
    agent_name_en: string;
    agent_name_ar: string;
    about_user_en: string;
    about_user_ar: string;
    agency_name_en: string;
    agency_name_ar: string;
    user_langs_en: Array<string>;
    user_langs_ar: Array<string>;
    specialities_en: string;
    specialities_ar: string;
    service_areas_en: string;
    service_areas_ar: string;
    sale_count: string;
    rent_count: string;
    user_image: string;
    user_image_id: string;
    review_count: string;
    avgstarrating: string;
    agent_slug: string;
    agency_slug: string;
    loc_2_external_id: Array<string>;
    most_active_emirate_prev_month: string;
    most_active_emirate_curr_month: string;
    email: string;
    experience: string;
    phone_numbers: string;
    proxy_mobile: string;
    proxy_phone: string;
    cell_numbers: string;
    social_media: string;
    location_ranking_past_3_months?: string;
    is_trubroker_badge_prev_month?: string;
    location_ranking_trubroker_past_3_months?: string;
    has_brn: string;
    combo_xp?: string;
};

export type Emirate = {
    name: string;
    emirate: string;
    slug: string;
    externalID: string;
    image: {
        sourceJPEG: string;
        sourceWEBP: string;
    };
    nbAgencies: number;
};

export type FullLeaderboardEntry = AgentData &
    RawLeaderboardEntry & {
        xp_earned_overall: number;
        xp_earned_past_3_months: number;
        global_ranking_past_3_months: number;
        trubroker_first_ranking_overall: number;
        trubroker_ranking_past_3_months: number;
        num_live_listings_in_dubai_curr_month: number;
        num_live_listings_in_abu_dhabi_curr_month: number;
        top_location_trubroker_first_ranking?: string;
        top_location_category_purpose_trubroker_first_ranking?: string;
        top_category_purpose_trubroker_first_ranking?: string;
        top_location_purpose_trubroker_first_ranking?: string;
        top_category_trubroker_first_ranking?: string;
        top_purpose_trubroker_first_ranking?: string;
        top_location_category_trubroker_first_ranking?: string;
        most_active_emirate_curr_month: string;
        is_whatsapp_tracking_enabled: string;
        is_call_tracking_enabled: string;
        is_quality_lister_badge_prev_month: string;
        is_responsive_agent_badge_prev_month: string;
    };

export type RawLocationRankingData = {
    agent_external_id: string;
    location_ranking_past_3_months: string;
};

export type TruBrokerAgentsParams = {
    language: string;
    locationsWithAds: Array<string>;
    category: string | null;
    purpose?: string | null;
    sortBy: Values<typeof AgentSortByValues> | null;
    hitsPerPage: number;
    page?: number;
    rankingField?: string;
    rankingInterval?: RangeFilterValue | null;
    userLanguages?: Array<string> | null;
    isTruBroker?: string | null;
    xpField?: string | null;
    xpRange?: RangeFilterValue | null;
    activeInEmirate?: string | null;
    agentExternalID?: string | number | null;
};
