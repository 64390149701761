import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/text.cssm';

type Props = React.ComponentProps<'span'> & {
    readonly center?: boolean;
    readonly bold?: boolean;
    readonly italic?: boolean;
    readonly underlined?: boolean;
    readonly strikethrough?: boolean;
    readonly uppercase?: boolean;
    readonly capitalized?: boolean;
    readonly block?: boolean;
    readonly nowrap?: boolean;
    readonly error?: boolean;
    readonly gray?: boolean;
    readonly className?: string;
    readonly children?: React.ReactNode | React.ReactText;
    readonly isDangerousHTML?: boolean;
};

const Text = ({
    center,
    bold,
    italic,
    underlined,
    strikethrough,
    uppercase,
    capitalized,
    block,
    nowrap,
    error,
    gray,
    className,
    children,
    isDangerousHTML,
    ...props
}: Props) => {
    const finalClassName = classNames(className, {
        [styles.center]: center,
        [styles.bold]: bold,
        [styles.italic]: italic,
        [styles.underlined]: underlined,
        [styles.uppercase]: uppercase,
        [styles.capitalized]: capitalized,
        [styles.block]: block,
        [styles.nowrap]: nowrap,
        [styles.strikethrough]: strikethrough,
        [styles.error]: error,
        [styles.gray]: gray,
    });

    if (isDangerousHTML) {
        return (
            <span
                {...props}
                className={finalClassName}
                dangerouslySetInnerHTML={{ __html: children as string }}
            />
        );
    }

    return (
        <span {...props} className={finalClassName}>
            {children}
        </span>
    );
};

const XSmall = ({ children, className = styles.extraSmall, ...props }: Props) => (
    <Text {...props} className={className}>
        {children}
    </Text>
);
const Small = ({ children, className = styles.small, ...props }: Props) => (
    <Text {...props} className={className}>
        {children}
    </Text>
);
const Regular = ({ children, className = styles.regular, ...props }: Props) => (
    <Text {...props} className={className}>
        {children}
    </Text>
);
const Large = ({ children, className = styles.large, ...props }: Props) => (
    <Text {...props} className={className}>
        {children}
    </Text>
);
const XLarge = ({ children, className = styles.extraLarge, ...props }: Props) => (
    <Text {...props} className={className}>
        {children}
    </Text>
);
const XXLarge = ({ children, className = styles.extraExtraLarge, ...props }: Props) => (
    <Text {...props} className={className}>
        {children}
    </Text>
);

export { Text as Base, XSmall, Small, Regular, Large, XLarge, XXLarge };
