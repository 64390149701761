import * as React from 'react';
import { SearchService, SearchJob } from '@sector-labs/fe-search-redux';
import { getFilterCollection, selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import differenceBy from 'lodash/differenceBy';
import { AlgoliaSearchBackend } from '@sector-labs/fe-search-redux/backend/algolia';
import {
    FilterCollection,
    ExactFilter,
    RefinementFilter,
    Operator,
} from '@sector-labs/fe-search-redux/filters';
import { useSelector } from 'react-redux';

import { selectLanguage } from 'strat/i18n/language';
import { selectPropertyHits } from 'strat/search/selectors';
import { FilterValues } from 'strat/search';
import { projectTransformer } from 'strat/project/projectTransformer';
import { CommonAdData, LocationNodeData, PropertyType } from 'strat/property/types';
import { ProjectData } from 'strat/project/types';
import { determineAdsIndexName } from 'strat/search/indexNames';

const RECOMMENDATIONS_COUNT = 5;

const fetchFromAlgolia = (
    filters: FilterCollection,
    language: string,
    backend: AlgoliaSearchBackend,
) => {
    const service = new SearchService({ backend });

    const job = new SearchJob(
        determineAdsIndexName({ language, sortBy: null, adType: PropertyType.PROJECT }),
        filters,
        {
            hitsPerPage: 10,
        },
    );

    return service
        .fetchJob(job)
        .then((response) => ({
            data: {
                hits: response.hits,
            },
            status: 200,
        }))
        .catch(() => ({
            data: {
                hits: [],
            },
            status: 404,
        }));
};

const getFallbackFilters = (currentFilters: FilterCollection) => {
    const locationFilter = currentFilters.getFilter(FilterValues.location.attribute);
    const pageFilter = new ExactFilter({ attribute: 'page', value: 1 });

    const parentLocationsSlugs = currentFilters
        .getFilterValue<LocationNodeData[]>(FilterValues.location.attribute)
        ?.map<string | null>((location: LocationNodeData) =>
            location.hierarchy && location.hierarchy.length >= 2
                ? location.hierarchy[location.hierarchy.length - 2].slug
                : null,
        )
        .filter((location) => location !== null) as string[];

    const parentLocationFilter = new RefinementFilter({
        attribute: FilterValues.location.attribute,
        value: parentLocationsSlugs,
        operator: Operator.OR,
    });

    const fallbackSearchFilters1 = new FilterCollection();
    fallbackSearchFilters1.refineMultiple([pageFilter, locationFilter]);

    const fallbackSearchFilters2 = new FilterCollection();
    fallbackSearchFilters2.refineMultiple([pageFilter, parentLocationFilter]);

    return [fallbackSearchFilters1, fallbackSearchFilters2];
};

const processFallbackFilters = (
    searchFiltersQueue: FilterCollection[],
    language: string,
    backend: AlgoliaSearchBackend,
    listings: CommonAdData[],
    recommendedListings: ProjectData[],
    setRecommendedListings: React.Dispatch<React.SetStateAction<ProjectData[]>>,
) => {
    if (searchFiltersQueue.length === 0 || recommendedListings.length > RECOMMENDATIONS_COUNT) {
        return;
    }
    fetchFromAlgolia(searchFiltersQueue[0], language, backend).then(({ data }) => {
        let hits = (data.hits || []) as ProjectData[];
        hits = differenceBy(hits, listings, 'id') as ProjectData[];
        hits = differenceBy(hits, recommendedListings, 'id');

        const finalHits = hits
            .slice(0, RECOMMENDATIONS_COUNT - recommendedListings.length)
            .map((hit: ProjectData) => projectTransformer(hit, language));
        if (recommendedListings.length + finalHits.length < RECOMMENDATIONS_COUNT) {
            processFallbackFilters(
                searchFiltersQueue.slice(1),
                language,
                backend,
                listings,
                [...recommendedListings, ...finalHits],
                setRecommendedListings,
            );
        }
        if (finalHits.length > 0) {
            setRecommendedListings([...recommendedListings, ...finalHits]);
        }
    });
};

const useProjectRecommendations = () => {
    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend) as AlgoliaSearchBackend;
    const filters = useSelector(getFilterCollection);
    const listings = useSelector(selectPropertyHits) as ProjectData[];

    const [recommendedListings, setRecommendedListings] = React.useState<ProjectData[]>([]);

    React.useEffect(() => {
        processFallbackFilters(
            getFallbackFilters(filters),
            language,
            backend,
            listings,
            [],
            setRecommendedListings,
        );
    }, [filters, backend, language, listings]);

    return recommendedListings;
};

export default useProjectRecommendations;
