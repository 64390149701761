import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { PaymentSuccessRouteParams } from 'horizontal/routes';

const useNavigateToSuccessPage = () => {
    const router = useRouter();

    return React.useCallback(
        (params?: PaymentSuccessRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_SUCCESS, params);
        },
        [router],
    );
};

export default useNavigateToSuccessPage;
