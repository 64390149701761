import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import type { Choice, ChoiceValue } from 'strat/components/types';

const maxLabelDisplayCount = 2;

const useMultiSelectSlideInSelectedText = (
    choices: Array<Choice<ChoiceValue>>,
    selectedValues: Set<ChoiceValue>,
) => {
    const i18n = useI18n();
    return React.useMemo((): string => {
        if (selectedValues.size) {
            const labelsText = choices
                .filter((choice) => selectedValues.has(choice.value))
                .slice(0, maxLabelDisplayCount)
                .map((choice) => choice.label)
                .join(',');

            if (selectedValues.size > maxLabelDisplayCount) {
                const remainingCount = selectedValues.size - maxLabelDisplayCount;
                return t(i18n)`${labelsText} +${remainingCount} more`;
            }

            return labelsText;
        }

        return t(i18n)`Choose...`;
    }, [i18n, choices, selectedValues]);
};

export default useMultiSelectSlideInSelectedText;
