import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

import { Category } from 'horizontal/types';
import { GlobalState } from 'horizontal/agencyPortal/state';

/**
 * The state and selectors should be lifted up and be part of the
 * main APP bundle. Otherwise any logic that relies on this will
 * always find `agencyCategoryFilters` to be undefined.
 * For example in Posting an Ad in the main site, the app will
 * always have `agencyCategoryFilters` as undefined.
 */

const selectAgencyCategoryFiltersIsLoaded = createSelector(
    (state: GlobalState) => state.agencyPortal?.agencyCategoryFilters,
    (categories) => categories?.loaded,
);

const selectAgencyCategoryFilters = createSelector(
    (state: GlobalState) => state.agencyPortal?.agencyCategoryFilters,
    (categories) => categories?.data || (EMPTY_ARRAY as Category[]),
);

const selectAgencyCategoriesFilterOrFullCategoriesList = createSelector(
    (state: GlobalState) => state.agencyPortal?.agencyCategoryFilters,
    (state: GlobalState) => state.categories,
    (categoriesFilter, fullCategoriesList) => {
        if (categoriesFilter?.loaded && categoriesFilter?.data?.length == 0) {
            return fullCategoriesList.data || EMPTY_ARRAY;
        }
        return categoriesFilter?.data || EMPTY_ARRAY;
    },
);

export {
    selectAgencyCategoryFilters,
    selectAgencyCategoriesFilterOrFullCategoriesList,
    selectAgencyCategoryFiltersIsLoaded,
};
