const DialogNames = Object.freeze({
    ALERTS_MANAGER: 'alertsManager',
    NOTIFICATIONS_APPROVAL: 'notificationsApproval',
    LISTINGS_CLUSTER_BOTTOM_SHEET: 'listingsClusterBottomSheet',
    AGENT_REVIEWS_MANAGER: 'agentReviewsManager',
    ERROR: 'error',
    NO_QUOTA: 'noQuota',
    SHARE_LINK: 'shareLink',
    AGENT_REVIEWS: 'agentReviews',
    NBS_SURVEY: 'nbsSurvey',
    TOP_LOADING_BAR: 'fullScreenSpinner',
    MULTI_SELECT_COMPACT_DIALOG: 'multiSelectCompactDialog',
    TRANSACTION_LIST_BOTTOM_SHEET: 'transactionListBottomSheet',
    INTERNAL_ERROR_DIALOG: 'internalErrorDialog',

    LOGIN: 'login',
    REGISTER: 'register',
});

export default DialogNames;
