import {
    ReplaceParameters,
    renderTemplate as renderTemplateBase,
    renderAllTemplates as renderAllTemplatesBase,
} from '@sector-labs/templates-renderer';

import { createLoggers } from './logging';

type RenderTemplateOptions = {
    template: string;
    replaceParameters: ReplaceParameters;
};

type RenderAllTemplatesOptions = {
    templates: Array<string>;
    replaceParameters: ReplaceParameters;
};

export const renderTemplate = ({ template, replaceParameters }: RenderTemplateOptions) =>
    renderTemplateBase(template, {
        replaceParameters,
        loggers: createLoggers({ includeMessages: true }),
    });

/**
 * Renders the specified list of templates with the specified
 * replace parameters.
 */
export const renderAllTemplates = ({
    templates,
    replaceParameters,
}: RenderAllTemplatesOptions): string | null | undefined =>
    renderAllTemplatesBase(templates, {
        replaceParameters,
        loggers: createLoggers({ includeMessages: false }),
    });
