import * as React from 'react';

import type { FullAd, ProductPackageOffer, ProductPurchase } from 'horizontal/types';
import { useNavigateToAdDetails } from 'horizontal/adDetails';
import { useIsAdUndertaking, useNavigateToOathPage } from 'horizontal/payment/hooks';
import { trackClickPreviewAd } from 'horizontal/gtm';
import ApplyBoughtProductPurchases from 'horizontal/packages/applyBoughtProductPurchases';
import SelectSingleVASOfferPackage from 'horizontal/packages/individual/selectSingleVASOfferPackage';
import { PackagesFlow, PackagesFlowTypeValues } from 'horizontal/packages/types';

import styles from './styles/upSelling.cssm';

type Props = {
    readonly ad?: FullAd | null | undefined;
    readonly productPurchases: Array<ProductPurchase> | null | undefined;
    readonly productOffers: Array<ProductPackageOffer> | null | undefined;
    readonly hidePromos?: boolean;
    readonly purchasePackageSuccess?: boolean;
    readonly flow?: PackagesFlowTypeValues;
};

const UpSelling = ({
    ad,
    productPurchases,
    productOffers,
    hidePromos = false,
    purchasePackageSuccess = false,
    flow = PackagesFlow.UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY,
}: Props) => {
    const navigateToAdDetails = useNavigateToAdDetails(ad);
    const navigateToOathPage = useNavigateToOathPage(ad);
    const isAdUndertaking = useIsAdUndertaking(ad);

    const onViewAdButtonClick = React.useCallback(() => {
        trackClickPreviewAd(ad);
        if (isAdUndertaking) {
            navigateToOathPage();
        } else {
            navigateToAdDetails();
        }
    }, [navigateToAdDetails, navigateToOathPage, isAdUndertaking, ad]);

    return (
        <div className={styles.container}>
            {!!productPurchases && (
                <ApplyBoughtProductPurchases
                    onViewAdButtonClick={onViewAdButtonClick}
                    postAdSuccessPage={!purchasePackageSuccess}
                    purchasePackages={productPurchases}
                    ad={ad}
                    hidePromos={hidePromos}
                    flow={flow}
                />
            )}

            {!!productOffers && (
                <SelectSingleVASOfferPackage
                    onViewAdButtonClick={onViewAdButtonClick}
                    flow={flow}
                    offerPackages={productOffers}
                    ad={ad}
                />
            )}
        </div>
    );
};

export default UpSelling;
