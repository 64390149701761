import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { useSelector } from 'react-redux';
import * as React from 'react';
import { determineAdsIndexName } from 'strat/search/indexNames';
import { fetchListingsFromSearchIndex } from 'strat/search/algolia';
import { selectLanguage } from 'strat/i18n/language/selectors';
import EMPTY_ARRAY from 'strat/empty/array';
import { makeCancelable } from 'strat/util';

import type { LiteAd } from 'horizontal/types';
import { hitsTransformer } from 'horizontal/search/hits';

import { selectFavorites } from './selectors';

const useFetchFavoritesFromSearchIndex = () => {
    const configuredBackend = useSelector(selectActiveSearchBackend);
    const language = useSelector(selectLanguage);
    const indexName = determineAdsIndexName({ language });
    const [favorites, setFavorites] = React.useState(EMPTY_ARRAY);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const favoritesExternalIDs = useSelector(selectFavorites);

    React.useEffect(() => {
        if (favoritesExternalIDs.length === 0) {
            setLoaded(true);
            setFavorites([]);
            return undefined;
        }

        setLoading(true);
        setLoaded(false);

        const cancelablePromise = makeCancelable(
            fetchListingsFromSearchIndex(
                // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
                configuredBackend,
                indexName,
                'externalID',
                favoritesExternalIDs,
            ),
        );

        cancelablePromise
            .then((data) => {
                setFavorites(hitsTransformer<LiteAd>(data, language));
            })
            .finally(() => {
                setLoaded(true);
                setLoading(false);
            });

        return () => {
            cancelablePromise.cancel();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favoritesExternalIDs, configuredBackend, indexName]);

    return { favorites, loading, loaded };
};

export default useFetchFavoritesFromSearchIndex;
