import * as React from 'react';
import { isCanceled } from 'strat/util';
import { useI18n } from 'strat/i18n/language';
import { Flex, LoadingSpinnerOverlay } from 'strat/components';

import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';
import getPageTitle from '@app/payment/paymobWallet/paymobWalletPageTitles';

import { useNavigateToPaymentSelection, usePaymentMethods } from '../hooks';
import PaymentProducts from '../paymentProducts';
import type { PaymentOrder } from '../types';
import PaymentTitle from '../paymentTitle';
import { PaymentOrderStatus, PaymentSource, PaymentStatus } from '../types';
import Header from '../header';
import useTrackPaymentInitiated from '../hooks/useTrackPaymentInitiated';
import useNavigateToPaymentFailed from '../hooks/useNavigateToPaymentFailed';
import useShouldHidePaymentHeader from '../hooks/useShouldHidePaymentHeader';

import styles from './styles/paymentPaymobMWallet.cssm';
import PaymobMobileWalletPaymentForm from './paymobMobileWalletPaymentForm';

type Props = {
    readonly order: PaymentOrder;
    readonly paymentSource: string;
};

const PaymentPaymobMWallet = ({ order, paymentSource }: Props) => {
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const navigateToPaymentSelection = useNavigateToPaymentSelection();
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();
    const { paymentMethods } = usePaymentMethods();
    const shouldHidePaymentHeader = useShouldHidePaymentHeader();
    const paymobDetails = paymentMethods.find((element) => element.source === paymentSource);
    const i18n = useI18n();

    const pageTitle = getPageTitle(i18n, paymentSource);

    const [loading, setLoading] = React.useState(false);

    const onSubmit = (paymentDetails: any) => {
        setLoading(true);

        makeCancelable(
            new StratAPI().paymobWalletCheckout(
                // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
                order.externalID,
                paymentDetails.phoneNumber,
                i18n.locale,
            ),
        )
            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            .then(({ data, status }) => {
                if (status === 422) {
                    setLoading(false);
                    navigateToPaymentFailed(PaymentStatus.MISSING_INFO);
                    return;
                }

                const redirect = data.iframe_url;

                if (
                    status !== 200 ||
                    redirect === undefined ||
                    data.status.toLowerCase() !== PaymentOrderStatus.SUCCESS
                ) {
                    setLoading(false);
                    navigateToPaymentFailed();
                    return;
                }

                trackPaymentInitiated(PaymentSource.PAYMOB_WALLET);
                window.location.href = redirect;
            })
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            .catch((error) => {
                setLoading(false);
                if (isCanceled(error)) {
                    return;
                }
                navigateToPaymentFailed();
            });
    };

    return (
        <Flex stretchHeight column>
            <LoadingSpinnerOverlay visible={loading} />
            {!shouldHidePaymentHeader && (
                <Header
                    popHistory={!order}
                    onBack={() => navigateToPaymentSelection({ orderExternalID: order.externalID })}
                />
            )}

            <Flex column className={styles.content}>
                <PaymentTitle>{pageTitle}</PaymentTitle>
                <PaymentProducts
                    productPackageNames={order.productPackageNames}
                    productsPrice={order.total}
                    loading={!order}
                    discount={paymobDetails?.discount}
                />
                <PaymobMobileWalletPaymentForm onSubmit={onSubmit} />
            </Flex>
        </Flex>
    );
};

export default PaymentPaymobMWallet;
