import type { AdStateFilterValues, ProductStateFilterValues } from 'horizontal/types';
import type { DateRange } from 'horizontal/agencyPortal/types';

import { extractEncodedDate, constructDateRangeEncoding } from './utils';

export type StateFilterParams = Partial<{
    query?: string | null;
    agentCode?: string | null;
    phoneNumber?: string | null;
    category?: string | null;
    agent?: string | null;
    stateFilter?: AdStateFilterValues | null;
    productFilter?: Array<ProductStateFilterValues> | null;
    location?: string;
    createDate?: DateRange | null;
    updateDate?: DateRange | null;
}>;

const pickFilters = (filter: string | null, keys: string[]): string | null => {
    if (!filter) {
        return null;
    }

    return decodeURIComponent(filter)
        .split(',')
        .filter((item) => {
            const [key] = item.split('_eq_');
            return keys.includes(key);
        })
        .join(',');
};

const decodeStateFilter = (filter?: string | null): StateFilterParams => {
    if (!filter) {
        return {};
    }

    return decodeURIComponent(filter)
        .split(',')
        .map((item) => item.split('_eq_'))
        .reduce<StateFilterParams>((acc, [key, value]) => {
            if (key === 'q') {
                acc.query = value;
            } else if (key === 'status') {
                acc.stateFilter = (value ?? '').toLowerCase();
            } else if (key === 'productFilter' && value) {
                acc.productFilter = (value ?? '')
                    .toLowerCase()
                    .split('+')
                    .filter((x) => x);
            } else if (key === 'agentCode') {
                acc.agentCode = value;
            } else if (key === 'phoneNumber') {
                acc.phoneNumber = value;
            } else if (key === 'category') {
                acc.category = value;
            } else if (key === 'agent') {
                acc.agent = value;
            } else if (key === 'location') {
                acc.location = value;
            } else if (key.includes('createDate') && value) {
                const [from, to] = extractEncodedDate(value);
                acc.createDate = {
                    from,
                    to,
                };
            } else if (key === 'updateDate' && value) {
                const [from, to] = extractEncodedDate(value);
                acc.updateDate = {
                    from,
                    to,
                };
            }

            return acc;
        }, {} as StateFilterParams);
};

const encodeStateFilter = (filter: StateFilterParams): string | null | undefined => {
    const items: Array<[string, string]> = [];

    if (filter.query) {
        items.push([`q`, filter.query]);
    }

    if (filter.agentCode) {
        items.push([`agentCode`, filter.agentCode]);
    }
    if (filter.phoneNumber) {
        items.push([`phoneNumber`, filter.phoneNumber]);
    }
    if (filter.category) {
        items.push([`category`, filter.category]);
    }
    if (filter.location) {
        items.push([`location`, filter.location]);
    }
    if (filter.agent) {
        items.push([`agent`, filter.agent]);
    }

    if (filter.stateFilter) {
        // Although the field is called state, we use status in the url to keep it backwards
        // compatible with the old OLX
        items.push(['status', filter.stateFilter.toUpperCase()]);
    }

    if (filter.productFilter && filter.productFilter.length) {
        items.push([`productFilter`, filter.productFilter.join('+')]);
    }

    if (filter.createDate) {
        items.push([`createDate`, constructDateRangeEncoding(filter.createDate)]);
    }
    if (filter.updateDate) {
        items.push([`updateDate`, constructDateRangeEncoding(filter.updateDate)]);
    }

    const result = items.map((item) => item.join('_eq_')).join(',');
    return result ? encodeURIComponent(result) : undefined;
};

export { pickFilters, decodeStateFilter, encodeStateFilter };
