import * as React from 'react';
import classNames from 'classnames';

import { LocalCurrency, LocalPrice } from 'strat/i18n/money';

import { PROJECT_PRICE_FORMATTING_OPTIONS } from '../projectPriceFormattingOptions';

import { ProjectBadgeStyle } from './types';
import styles from './styles/priceValue.cssm';

type Props = {
    price: number;
    style: ProjectBadgeStyle;
};

const PriceValue = ({ price, style }: Props) => (
    <div
        className={classNames(styles.container, {
            [styles.tinyCurrency]: style === ProjectBadgeStyle.SMALL_CARD,
            [styles.smallCurrency]: style === ProjectBadgeStyle.SMALL_SEGMENTED_BAR,
        })}
    >
        <div className={styles.currency}>
            <LocalCurrency />
        </div>
        <LocalPrice price={price} options={PROJECT_PRICE_FORMATTING_OPTIONS} />
    </div>
);

export default PriceValue;
