import React from 'react';
import { LDJSONScript } from 'strat/seo';
import logo from '@app/assets/icons/logo_noinline.svg';

const ORGANIZATION_METADATA = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'OLX',
    description: 'OLX - Buy and Sell for free anywhere in Pakistan with OLX online classifieds',
    url: 'https://olx.com.pk',
    sameAs: [
        'https://en.wikipedia.org/wiki/OLX',
        'https://www.linkedin.com/company/olx-group/',
        'https://www.facebook.com/olxpakistan/',
        'https://www.instagram.com/olx.pakistan/',
    ],
    telephone: '+92 800 101 01',
    foundingDate: '2006',
    founder: [
        {
            '@type': 'Person',
            name: 'Fabrice Grinda',
        },
        {
            '@type': 'Person',
            name: 'Alec Oxenford',
        },
    ],
    numberOfEmployees: {
        '@type': 'QuantitativeValue',
        minValue: '4001',
        maxValue: '5000',
    },
    image: {
        '@type': 'ImageObject',
        url: logo,
    },
    logo: {
        '@type': 'ImageObject',
        url: logo,
    },
    address: {
        '@type': 'PostalAddress',
        streetAddress: '3rd Floor, Central Tower, Hali Rd, Block B Gulberg 2',
        addressLocality: 'Lahore',
        addressCountry: {
            '@type': 'Country',
            name: 'Pakistan',
        },
    },
} as const;

const OLXPKOrganizationMetadata = () => <LDJSONScript content={ORGANIZATION_METADATA} />;

export default OLXPKOrganizationMetadata;
