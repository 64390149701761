import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';

import { trackBusinessPackagesClick } from 'horizontal/gtm';
import { useNavigateToSelectMultiplePackages } from 'horizontal/businessPackages/hooks';
import { ProductType } from 'horizontal/types';
import type { Ad } from 'horizontal/types';

import { getProductsTypes } from '../utils';

import styles from './styles/promoStrip.cssm';

type Props = {
    readonly text: string;
    ad: Ad | null | undefined;
    availableAdPackages?: any;
};

const PromoStrip = ({ text, ad, availableAdPackages }: Props) => {
    const i18n = useI18n();
    const navigateToSelectPackages = useNavigateToSelectMultiplePackages();

    const navigateToBusinessPackages = React.useCallback(() => {
        if (!ad) {
            return;
        }

        const productsTypes = availableAdPackages
            ? getProductsTypes(availableAdPackages)
            : [ProductType.LIMIT_BUMP];

        trackBusinessPackagesClick();
        const categoryExternalID = ad.category[ad.category.length - 1]?.externalID;
        const locationExternalID = ad.location[ad.location.length - 1]?.externalID;
        navigateToSelectPackages({
            locationExternalID,
            categoryExternalID,
            adExternalID: ad.externalID,
            productTypes: ad.externalID ? productsTypes : [],
        });
    }, [ad, navigateToSelectPackages, availableAdPackages]);

    return (
        <div className={styles.wrapper}>
            {settings.enableBusinessPackages && (
                <Flex className={styles.container} alignCenter>
                    <Text.Regular>{text}</Text.Regular>
                    <button className={styles.button} onClick={navigateToBusinessPackages}>
                        <Text.Regular>{t(i18n)`View Packages`}</Text.Regular>
                    </button>
                </Flex>
            )}
        </div>
    );
};

export default PromoStrip;
