import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useSavedSearches, selectSavedSearchesLoaded } from 'strat/search/savedSearches';
import { removeSavedSearch } from 'strat/search/savedSearches/state';
import { Flex, Text } from 'strat/components';
import type { AppDispatch } from 'strat/state';

import SavedSearchesCard from './savedSearchesCard';
import styles from './styles/savedSearchesTab.cssm';

const SavedSearchesTab = () => {
    const savedSearches = useSavedSearches();
    const savedSearchesCount = savedSearches.length;
    const savedSearchesLoaded = useSelector(selectSavedSearchesLoaded);
    const [removing, setRemoving] = React.useState([]);
    const dispatch = useDispatch<AppDispatch>();

    const removeSearch = React.useCallback(
        (id: number) => {
            // @ts-expect-error - TS2345 - Argument of type '(prevRemoving: never[]) => number[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setRemoving((prevRemoving) => [...prevRemoving, id]);
            dispatch(removeSavedSearch(id));
        },
        [dispatch],
    );

    if (savedSearchesCount === 0 && savedSearchesLoaded) {
        return (
            <Flex column justifyCenter alignCenter>
                <Text.Large bold>
                    <Trans>No saved searches yet.</Trans>
                </Text.Large>
            </Flex>
        );
    }

    return (
        <Flex fillContainer wrap className={styles.container}>
            {/* @ts-expect-error - TS7006 - Parameter 'savedSearch' implicitly has an 'any' type. */}
            {savedSearches.map((savedSearch) => (
                <SavedSearchesCard
                    key={savedSearch.id}
                    savedSearch={savedSearch}
                    removeSearch={removeSearch}
                    removing={removing}
                />
            ))}
        </Flex>
    );
};

export default SavedSearchesTab;
