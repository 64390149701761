import * as React from 'react';

import DefaultRejectionContent from './defaultRejectionContent';
import styles from './styles/rejectionReason.cssm';

const RejectionReason = ({ rejectionReason }: { rejectionReason: string | null | undefined }) =>
    rejectionReason ? (
        <div
            className={styles.rejectionTextContainer}
            dangerouslySetInnerHTML={{ __html: rejectionReason }}
        />
    ) : (
        <DefaultRejectionContent />
    );

export default RejectionReason;
