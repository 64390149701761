import * as React from 'react';
import { Trans } from '@lingui/macro';

import type { AnyAdData } from 'strat/types';

import Badge from './badge';
import PriceValue from './priceValue';
import { ProjectBadgeStyle } from './types';

type Props = {
    ad: AnyAdData;
    style: ProjectBadgeStyle;
};

const StartingFromBadge = ({ ad, style }: Props) => {
    if (!ad.price) {
        return null;
    }

    return (
        <Badge
            style={style}
            renderTitle={() => <Trans>Starting from</Trans>}
            renderValue={() => <PriceValue price={ad.price} style={style} />}
        />
    );
};

export default StartingFromBadge;
