import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { ThirdPartyProvider } from 'strat/auth/keycloak/types';

import type { AvailableSocialLoginProvider } from './types';

type UseAvailableSocialLoginProviders = AvailableSocialLoginProvider[];

const useAvailableSocialLoginProviders = (): UseAvailableSocialLoginProviders => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return React.useMemo(
        () => [
            {
                type: ThirdPartyProvider.FACEBOOK,
                title: t(i18n)`Facebook`,
                description: t(
                    i18n,
                )`Sign in with Facebook and discover your trusted connections to buyers`,
            },
            {
                type: ThirdPartyProvider.GOOGLE,
                title: t(i18n)`Google`,
                description: t(
                    i18n,
                )`Connect your ${brandName} account to your Google account for simplicity and ease`,
            },
        ],
        [i18n, brandName],
    );
};

export default useAvailableSocialLoginProviders;
