import * as React from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import settings from '@app/branding/settings';
import styles from '@app/property/styles/propertyNavBar.cssm';

import { Sticky } from 'strat/modal';
import { PageWidthWrapper } from 'strat/generic';
import { withGTMLeadTracking, withGTMPropertyNavigationTracking } from 'strat/gtm';
import { connectScrollNav } from 'strat/navigation/scroll';
import PropertyNavBarSideButtons from '@app/property/propertyNavBarSideButtons';
import type { Props as PropertyNavBarSideButtonsProps } from '@app/property/propertyNavBarSideButtons';
import type { GTMPropertyNavigationTrackingProps } from 'strat/gtm';

/**
 * Properties for {@see PropertyNavBar}.
 */
type Props = React.ComponentProps<typeof PropertyNavBarSideButtons> &
    GTMPropertyNavigationTrackingProps & {
        verticalOffset?: number;
        containerClassName?: string;
        renderSideButtons?: (props: PropertyNavBarSideButtonsProps) => React.ReactElement;
    };

/**
 * State for {@see PropertyNavBar}.
 */
type State = {
    stuck: boolean;
};

/**
 * Navigation bar for the property page.
 */
class PropertyNavBar extends React.Component<Props, State> {
    static defaultProps = {
        renderSideButtons: (props: PropertyNavBarSideButtonsProps) => (
            <PropertyNavBarSideButtons withIcon {...props} />
        ),
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = {
            stuck: false,
        };
    }

    render() {
        let contents = (
            <div className={styles.contents} aria-label="Nav bar">
                {this.props.sections.map((section, index) => {
                    const className = classNames(styles.button, {
                        [styles.active]: index === this.props.index,
                        [styles.buttonWithIcon]: !!section.icon,
                    });
                    return (
                        <button
                            key={section.title}
                            className={className}
                            onClick={() => {
                                this.props.scrollToSection(section.title);
                                // @ts-expect-error - TS2339 - Property 'trackPropertyNavigation' does not exist on type 'never'.
                                this.props.trackPropertyNavigation(section.shortTitle);
                            }}
                        >
                            {section.icon}
                            {section.shortTitle || section.title}
                        </button>
                    );
                })}
                <div
                    className={classNames(styles.contactButtons, styles.updatedButtons)}
                    style={{ display: !this.state.stuck ? 'none' : '' }}
                >
                    {this.props.renderSideButtons && this.props.renderSideButtons(this.props)}
                </div>
            </div>
        );

        contents = <PageWidthWrapper enabled={this.state.stuck}>{contents}</PageWidthWrapper>;

        return (
            <>
                <Sticky
                    invisibleIfNotDetached={!settings.propertyNavbarVisibleIfNotDetached}
                    key="sticky"
                    onStuck={() => this.setState({ stuck: true })}
                    onUnstuck={() => this.setState({ stuck: false })}
                    className={styles.container}
                    verticalOffset={
                        !isNil(this.props.verticalOffset) ? this.props.verticalOffset : 150
                    }
                >
                    {contents}
                </Sticky>
                <div className={this.props.containerClassName}>{this.props.children}</div>
            </>
        );
    }
}

export default withGTMPropertyNavigationTracking(
    // @ts-expect-error - TS2345 - Argument of type 'ConnectedComponent<typeof ScrollNav, { children: ChildrenArray<ScrollNavSection>; ref?: LegacyRef<ScrollNav> | undefined; key?: Key | null | undefined; compensation: number; topCompensationDelta: number; context?: Context<...> | undefined; store?: any; }>' is not assignable to parameter of type 'AbstractComponent<any, any>'. | TS2345 - Argument of type 'typeof PropertyNavBar' is not assignable to parameter of type 'AbstractComponent<any, any>'.
    connectScrollNav(withGTMLeadTracking(PropertyNavBar)),
);
