import * as React from 'react';

export type ScrollNavSectionContextValue = {
    title: string;
    shortTitle?: string;
    icon?: any;
};

const ScrollNavSectionContext = React.createContext<ScrollNavSectionContextValue | null>(null);

export default ScrollNavSectionContext;
