import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';

import { useDialog } from './dialogContext';

export const BottomSheetState = Object.freeze({
    DOCKED: 'docked',
    MINIMIZED: 'minimized',
    FULL_SCREEN: 'fullScreen',
});

type ProvidedProps = {
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
    activeState: Values<typeof BottomSheetState>;
    setActiveState: (arg1: Values<typeof BottomSheetState>) => void;
};

const registerBottomSheet =
    <T extends ProvidedProps>(
        name: string,
        component: Flow.AbstractComponent<any>,
    ): Flow.AbstractComponent<Flow.Diff<T, ProvidedProps>> =>
    (props) => {
        const [visible, setVisible] = useDialog(name);
        const [activeState, setActiveState] = React.useState(BottomSheetState.DOCKED);

        return React.createElement(component, {
            visible,
            onVisibilityChanged: setVisible,
            activeState,
            setActiveState,
            ...props,
        });
    };

export default registerBottomSheet;
