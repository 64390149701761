import * as React from 'react';
import { Route } from 'react-true-router';
import { serializeLocation } from 'react-true-router/location';
import { buildCanonicalURLWithoutLanguage, buildCanonicalURL } from 'strat/routing';
import { isCanceled } from 'strat/util';
import { useI18n } from 'strat/i18n/language';

import { PaymentSelectionRoute, PaymentSelectionFreeAdUndertakingRoute } from 'horizontal/routes';
import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';

import { PaymentSource, PaymentStatus, PaymentOrder, CheckoutCreditCard } from '../types';
import type { CheckoutHostedPaymentsPayload, PaymentHookParams } from '../types';

import useCheckoutSavedPayment from './useCheckoutSavedPayment';
import useNavigateToPaymentSelectionFreeAd from './useNavigateToPaymentSelectionFreeAd';
import useNavigateToPaymentFailed from './useNavigateToPaymentFailed';
import useTrackPaymentInitiated from './useTrackPaymentInitiated';

export const CHECKOUT_PAYMENT_FINISH_API_URL = '/api/payment/checkout/finish';

type PaymentFinishAPIParams = {
    orderExternalID: string;
    language: string;
};

export const buildPaymentFinishURL = (path: string, params?: PaymentFinishAPIParams) =>
    buildCanonicalURLWithoutLanguage(
        serializeLocation({
            pathname: path,
            search: params,
        }),
    );

const buildHostedPaymentPayload = (
    orderExternalID: string,
    language: string,
    cancelURL: string,
): CheckoutHostedPaymentsPayload => {
    const finishPaymentURL = buildPaymentFinishURL(CHECKOUT_PAYMENT_FINISH_API_URL, {
        language,
        orderExternalID,
    });
    return {
        order_external_id: orderExternalID,
        success_url: finishPaymentURL,
        cancel_url: buildCanonicalURL(
            serializeLocation({
                pathname: cancelURL,
            }),
            language,
        ),
        failure_url: finishPaymentURL,
    };
};

const useCheckoutPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const navigateToPaymentSelectionFreeAd = useNavigateToPaymentSelectionFreeAd();
    const savedCardPayment = useCheckoutSavedPayment({ setPaymentPromise, setIsLoading });
    const navigateToPaymentFailedFreeAd = React.useCallback(
        (adExternalID) => {
            navigateToPaymentSelectionFreeAd({
                adExternalID,
            });
        },
        [navigateToPaymentSelectionFreeAd],
    );

    const makeCancelable = useMakeCancelable();
    const language = useI18n().locale;

    return React.useCallback(
        (order?: PaymentOrder, route?: Route, card?: CheckoutCreditCard) => {
            if (!order || !order.externalID) {
                return;
            }

            if (card && card.id) {
                savedCardPayment(card, order);
                return;
            }

            let cancelURL = '';
            let failedCallback: (status?: Values<typeof PaymentStatus>) => void = () => {};
            if (route === PaymentSelectionFreeAdUndertakingRoute) {
                // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
                cancelURL = PaymentSelectionFreeAdUndertakingRoute.createURL({
                    adExternalID: order.adExternalID,
                }).pathname;
                failedCallback = () => navigateToPaymentFailedFreeAd(order.adExternalID);
            } else if (route === PaymentSelectionRoute) {
                // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
                cancelURL = PaymentSelectionRoute.createURL({
                    orderExternalID: order.externalID,
                }).pathname;
                failedCallback = () => navigateToPaymentFailed();
            }
            setIsLoading(true);

            const cancelablePromise = makeCancelable(
                new StratAPI().checkoutHostedPayment(
                    buildHostedPaymentPayload(order.externalID, language, cancelURL),
                ),
            );
            cancelablePromise
                // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
                .then(({ data, status }) => {
                    if (status === 400 && data?.paymentStatus === PaymentStatus.AD_REJECTED) {
                        setIsLoading(false);
                        failedCallback(PaymentStatus.AD_REJECTED);
                        return;
                    }

                    if (status !== 201) {
                        setIsLoading(false);
                        failedCallback();
                        return;
                    }

                    // eslint-disable-next-line no-underscore-dangle
                    const redirect = data._links?.redirect?.href;

                    if (redirect) {
                        trackPaymentInitiated(PaymentSource.CHECKOUT);

                        window.location.href = redirect;
                    } else {
                        setIsLoading(false);
                        failedCallback();
                    }
                })
                // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
                .catch((error) => {
                    if (isCanceled(error)) {
                        return;
                    }
                    setIsLoading(false);
                });

            setPaymentPromise(cancelablePromise);
        },
        [
            setIsLoading,
            setPaymentPromise,
            makeCancelable,
            language,
            navigateToPaymentFailed,
            navigateToPaymentFailedFreeAd,
            trackPaymentInitiated,
            savedCardPayment,
        ],
    );
};

export default useCheckoutPayment;
