import * as React from 'react';
import { trigger, Triggers, PageTypes } from 'strat/gtm';

import type { FullAd } from 'horizontal/types';
import { useCategoryVars } from 'horizontal/gtm';

const useTrackFreeAdUndertaking = (ad: FullAd) => {
    const categoryVars = useCategoryVars(ad.category.slice(-1)[0].externalID);
    const [tracked, setIsTracked] = React.useState(false);

    React.useEffect(() => {
        if (Object.keys(categoryVars).length === 0 || tracked) {
            return;
        }
        trigger(Triggers.FREE_AD_PAYMENT, {
            page_type: PageTypes.FREE_AD_PAYMENT_SELECTION,
            ...categoryVars,
        });
        setIsTracked(true);
    }, [categoryVars, tracked]);
};

export default useTrackFreeAdUndertaking;
