import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StratAPI } from 'strat/api';
import type { PushNotificationsSubscriptionType } from 'strat/alerts/types';
import { selectDeviceID } from 'strat/user/selectors';
import type { AppDispatch } from 'strat/state';

import { setPushNotificationAlert } from '../alerts/state/creators';

const useOnPushNotificationsPermissionChanged = (onSuccessfulSubscribe?: () => void) => {
    const dispatch = useDispatch<AppDispatch>();
    const userDeviceID = useSelector(selectDeviceID);

    const setPushNotificationAlertData = React.useCallback(
        (data: PushNotificationsSubscriptionType) => dispatch(setPushNotificationAlert(data)),
        [dispatch],
    );
    const onUnsubscribe = React.useCallback(
        () =>
            new StratAPI().unsubscribeFromNotifications(userDeviceID).then(({ data, status }) => {
                if (status !== 200) {
                    return;
                }
                setPushNotificationAlertData(data);
            }),
        [userDeviceID, setPushNotificationAlertData],
    );
    const onSubscribe = React.useCallback(
        () =>
            new StratAPI().subscribeForNotifications(userDeviceID).then(({ data, status }) => {
                if (status !== 200) {
                    return;
                }
                setPushNotificationAlertData(data);
                onSuccessfulSubscribe?.();
                if (CONFIG.build.ENABLE_PUSH_NOTIFICATIONS) {
                    OneSignal.push(() =>
                        OneSignal.getUserId().then((_) => OneSignal.setExternalUserId(data.id)),
                    );
                }

                // also call moengage web push in order to generate push token after permission is granted
                // it will not open a new promp as permission is already granted
                if (CONFIG.build.ENABLE_MO_ENGAGE_PUSH_NOTIFICATIONS) {
                    // @ts-expect-error - TS2339 - Property 'Moengage' does not exist on type 'Window & typeof globalThis'.
                    window.Moengage?.call_web_push?.();
                }
            }),
        [userDeviceID, setPushNotificationAlertData, onSuccessfulSubscribe],
    );

    return { onSubscribe, onUnsubscribe };
};

export default useOnPushNotificationsPermissionChanged;
