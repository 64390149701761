import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { trackPopularSearches } from 'horizontal/gtm';

import type { FooterLink } from './types';

const usePopularCategoriesLinks = (): ReadonlyArray<FooterLink> => {
    const i18n = useI18n();

    return React.useMemo(
        () => [
            {
                name: t(i18n)`Cars`,
                url: '/cars_c84',
                event: () => trackPopularSearches('Cars'),
            },
            {
                name: t(i18n)`Flats for rent`,
                url: '/apartments-flats_c1723',
                event: () => trackPopularSearches('Flats for rent'),
            },
            {
                name: t(i18n)`Mobile Phones`,
                url: '/mobile-phones_c1453',
                event: () => trackPopularSearches('Mobile Phones'),
            },
            {
                name: t(i18n)`Jobs`,
                url: '/jobs_c4',
                event: () => trackPopularSearches('Jobs'),
            },
        ],
        [i18n],
    );
};

export default usePopularCategoriesLinks;
