import * as React from 'react';

import { PaymentStatus } from '../types';
import type { PaymentOrder } from '../types';

import useNavigateToPaymentSelection from './useNavigateToPaymentSelection';

const useNavigateToPaymentFailed = (order?: PaymentOrder) => {
    const navigateToPaymentSelection = useNavigateToPaymentSelection();

    return React.useCallback(
        (status: Values<typeof PaymentStatus> | null = null) => {
            if (!order || !order.externalID) {
                return;
            }

            navigateToPaymentSelection({
                orderExternalID: order.externalID,
                status: status || PaymentStatus.FAILURE,
            });
        },
        [navigateToPaymentSelection, order],
    );
};

export default useNavigateToPaymentFailed;
