import * as React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import Layout from 'strat/layout';
import { Button, Flex, LoadingSpinnerOverlay, Text } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';

import CustomerInfoFields from './customerInfoFields';
import AddressFields from './addressFields';
import styles from './styles/billingForm.cssm';

type Props = {
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly isSubmitting: boolean;
    readonly businessValidationSchema: boolean;
    readonly setBusinessValidationSchema: (businessValidationSchema: boolean) => void;
};

const BillingForm = ({
    errors,
    touched,
    handleBlur,
    handleSubmit,
    values,
    setFieldValue,
    isSubmitting,
    businessValidationSchema,
    setBusinessValidationSchema,
}: Props) => {
    const layout = useSelector(selectLayout);

    return (
        <>
            {isSubmitting && <LoadingSpinnerOverlay visible={isSubmitting} />}
            <form className={styles.container}>
                <Flex column className={styles.mainSection}>
                    {layout === Layout.DESKTOP && (
                        <Text.Base bold block className={styles.sectionTitle}>
                            <Trans>Customer Information</Trans>
                        </Text.Base>
                    )}
                    <Flex column>
                        <CustomerInfoFields
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                            values={values}
                            onBlur={handleBlur}
                            businessValidationSchema={businessValidationSchema}
                            setBusinessValidationSchema={setBusinessValidationSchema}
                        />
                    </Flex>
                </Flex>
                <Flex column className={styles.mainSection}>
                    <Text.Base bold block className={styles.sectionTitle}>
                        <Trans>Customer Address</Trans>
                    </Text.Base>
                    <Flex column>
                        <AddressFields
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                            values={values}
                            onBlur={handleBlur}
                            businessValidationSchema={businessValidationSchema}
                        />
                    </Flex>
                </Flex>
                <Flex className={styles.saveSection}>
                    <Button stretch={layout === Layout.MOBILE} onClick={handleSubmit} type="submit">
                        <Trans>Save changes</Trans>
                    </Button>
                </Flex>
            </form>
        </>
    );
};

export default BillingForm;
