//@ts-nocheck
/**
 * Available map actions.
 */
import FetcherFactory from 'strat/fetcher';
import { FrontEndAPI } from 'strat/api';

const factory = new FetcherFactory(
    'places',
    ({ geoloc, type, distance }, state: any) =>
        new FrontEndAPI().places(geoloc, type, distance, state.i18n.language),
    { successCodes: [403] },
);

const fetchPlaces = factory.creator();
const placesReducer = factory.reducer();

export { fetchPlaces };

export default placesReducer;
