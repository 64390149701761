import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectRouteParams } from 'strat/routes/selectors';
import Layout from 'strat/layout';
import { useI18n } from 'strat/i18n/language';
import Category from '@app/branding/category';
import { locationHierarchyToString } from 'strat/misc';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ViewSections } from 'strat/gtm';
import { selectUserExternalID } from 'strat/user/session';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';
import getVerifiedProfileBadgeConfig from '@app/adCard/getVerifiedProfileBadgeConfig';

import { AdProduct } from 'horizontal/types';
import { selectLocationNameWithParent } from 'horizontal/location';
import type { LiteAd } from 'horizontal/types';
import {
    useAdPriceLabel,
    useAdSubtitle,
    useLocalizedAdTitle,
    useAgencyLogo,
    useAdCommunicationMethods,
    useAdAgency,
} from 'horizontal/ad';
import { selectAdBadges, isAdEditor, isEligibleForDelivery } from 'horizontal/util';
import { FeaturedTag, AdTag } from 'horizontal/adCard/tags';
import CreationDate from 'horizontal/adCard/creationDate';
import FavoriteButton from 'horizontal/adCard/favoriteButton';
import CoverPhoto from 'horizontal/adCard/coverPhoto';
import useGetDownPaymentLabel from 'horizontal/ad/price/useGetDownPaymentLabel';
import EliteStrip from '@app/branding/eliteStrip';
import EliteTag from '@app/branding/eliteTag';
import useCleanedDescription from 'horizontal/adDetails/seo/useCleanedDescription';
import getRequestablePriceLabel from 'horizontal/ad/price/getRequestablePriceLabel';
import useRentalPeriod from 'horizontal/adCard/useRentalPeriod';

import gridViewStyles from './styles/gridViewStyles.cssm';
import listViewStyles from './styles/listViewStyles.cssm';
import ContactButtons from './contactButtons';
import SellerDetailsCompact from './sellerDetailsCompact';
import SellerDetails from './sellerDetails';
import Title from './title';
import AdAdditionalPriceInfo from './adAdditionalPriceInfo';
import AdLink from './adLink';

type Props = {
    ad: LiteAd;
    index?: number;
    viewType?: Values<typeof SearchHitsLayout>;
    hideLocation?: boolean;
    viewSection: Values<typeof ViewSections>;
    hideAgency?: boolean;
    hideFeatured?: boolean;
    hideElite?: boolean;
    highlighted?: boolean;
    coverPhotoSize?: number;
};

const AdCardForNormalCategory = ({
    ad,
    index,
    viewType,
    hideLocation,
    viewSection,
    hideAgency,
    hideFeatured,
    hideElite,
    highlighted,
    coverPhotoSize,
}: Props) => {
    const layout = useSelector(selectLayout);
    const i18n = useI18n();
    const loggedUserExternalId = useSelector(selectUserExternalID);
    const isOwnAd = isAdEditor(ad, loggedUserExternalId);

    const showFeatured =
        settings.enableFeaturedBadge && ad.product === AdProduct.FEATURED && !hideFeatured;
    const showElite = ad.product === AdProduct.ELITE && !hideElite;
    const maxNumberOfBadges = settings.maxNumberOfBadgesForAdListItem;
    const isMobile = layout === Layout.MOBILE;
    const lazyLoadLimit =
        layout === Layout.DESKTOP
            ? CONFIG.build.LAZY_LOAD_FROM_DESKTOP
            : CONFIG.build.LAZY_LOAD_FROM_MOBILE;
    // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
    const lazyLoadPhoto = index === undefined || index + 1 >= parseInt(lazyLoadLimit, 10);

    const locationHierarchy = settings.parseLocationNameWithCity
        ? selectLocationNameWithParent(i18n, ad.location)
        : locationHierarchyToString(ad.location, 2, i18n, ad.location.length - 2);
    const priceLabel = useAdPriceLabel(ad);
    const requestableText = getRequestablePriceLabel(ad);
    const subtitle = useAdSubtitle(ad);
    const adLocalizedTitle = useLocalizedAdTitle(ad);
    const adLocalizedDescription = useCleanedDescription(ad);
    const agencyLogo = useAgencyLogo(ad);
    const agency = useAdAgency(ad);
    const { shouldShowBuyWithDelivery } = useAdCommunicationMethods(ad);
    const shouldShowDeliveryCTA = isEligibleForDelivery(ad) && shouldShowBuyWithDelivery;
    const showAgency = !!agencyLogo && !hideAgency;
    const downPaymentLabel = useGetDownPaymentLabel(ad);
    const rentalPeriod = useRentalPeriod(ad);
    const priceInfoLabel = CONFIG.build.STRAT_ENABLE_DOWN_PAYMENT_REDESIGN
        ? rentalPeriod || downPaymentLabel
        : null;

    const isListLayout = viewType === SearchHitsLayout.LIST;
    const isGridLayout = !isListLayout;

    const styles = isListLayout ? listViewStyles : gridViewStyles;

    const shouldShowDescription = showElite && showAgency && isListLayout && !isMobile;

    const coverPhoto = React.useMemo(() => {
        if (!ad.coverPhoto) {
            return null;
        }

        return {
            ...ad.coverPhoto,
            title: adLocalizedTitle,
        };
    }, [ad, adLocalizedTitle]);

    const { categorySlug } = useSelector(selectRouteParams);
    const isGridGeneralCategory =
        (Category.isOfVehiclesType(categorySlug) || Category.isOfPropertiesType(categorySlug)) &&
        isGridLayout;

    const verifiedProfileBadgeConfig = getVerifiedProfileBadgeConfig(
        ad,
        isMobile,
        isListLayout,
        viewSection,
    );

    let badges = selectAdBadges(
        ad,
        maxNumberOfBadges,
        !!agency,
        verifiedProfileBadgeConfig.show && verifiedProfileBadgeConfig.includeInBadgesList,
    )
        .map((badge) => <AdTag badgeType={badge.type} key={badge.type} />)
        .filter(Boolean);

    const customBadges = ad.adTags?.map((adTag) => <AdTag name={adTag.name} />) || [];

    badges = badges.concat(customBadges);

    badges = maxNumberOfBadges ? badges.slice(0, maxNumberOfBadges) : badges;

    /**
     * if the user is not verified or if the badge is shown as an ad baged (like other
     * badges: video, deliveriable...etc), then don't show it on the seller details component
     */
    const showVerifiedStatusInSellerDetails = !(
        !verifiedProfileBadgeConfig.show || verifiedProfileBadgeConfig.includeInBadgesList
    );

    const renderBadges = (isPhotoDetail = false): React.ReactNode | false =>
        !!badges.length && (
            <span
                className={classNames(styles.badgesWrapper, {
                    [styles.photoButtonWrapper]: isPhotoDetail,
                })}
            >
                {badges}
            </span>
        );

    const renderLocation = (withDot: boolean) =>
        !hideLocation && (
            <span className={styles.location} aria-label="Location">
                {locationHierarchy}
                {withDot && <span className={classNames(styles.separator)}>&#8226;</span>}
            </span>
        );

    const link = (
        <AdLink ad={ad} isMobile={isMobile} viewSection={viewSection}>
            <div className={styles.linkDiv} />
        </AdLink>
    );

    const EliteComponent = isListLayout ? EliteStrip : EliteTag;
    return (
        <article
            className={classNames(styles.container, {
                [styles.elite]: showElite,
                [styles.highlighted]: highlighted,
            })}
        >
            <div className={styles.photoContainer}>
                {link}
                <CoverPhoto
                    size={coverPhotoSize}
                    cover={coverPhoto}
                    lazyLoad={lazyLoadPhoto}
                    category={ad.category}
                />
                {showFeatured && (
                    <div className={classNames(styles.featuredTag)}>
                        <FeaturedTag />
                    </div>
                )}
                {showElite && (
                    <div>
                        <EliteComponent />
                    </div>
                )}
            </div>
            <div className={styles.detailsWrapper}>
                {link}
                <div className={styles.details}>
                    {link}
                    {!!priceLabel && (
                        <Flex alignCenter className={styles.price} aria-label="Price">
                            <span
                                className={
                                    priceInfoLabel &&
                                    priceInfoLabel === downPaymentLabel &&
                                    !isGridLayout
                                        ? styles.downPaymentPriceLabel
                                        : styles.priceLabel
                                }
                            >
                                {priceLabel}
                            </span>
                            <AdAdditionalPriceInfo
                                ad={ad}
                                isGridLayout={isGridLayout}
                                isMobile={isMobile}
                            />
                        </Flex>
                    )}
                    {!!requestableText && (
                        <Flex alignCenter className={styles.price} aria-label="Price">
                            <span
                                className={
                                    priceInfoLabel && priceInfoLabel === downPaymentLabel
                                        ? styles.downPaymentPriceLabel
                                        : styles.priceLabel
                                }
                            >
                                {requestableText}
                            </span>
                        </Flex>
                    )}
                    <div
                        className={classNames(styles.titleWrapper, {
                            [styles.titleWithPrice]: !!priceLabel || !!requestableText,
                            [styles.reducedTitle]: shouldShowDescription,
                        })}
                        aria-label="Title"
                    >
                        <Title title={adLocalizedTitle} className={styles.title} />
                    </div>
                    {shouldShowDescription && (
                        <div className={styles.description} aria-label="Description">
                            <Text.Regular>{adLocalizedDescription}</Text.Regular>
                        </div>
                    )}
                    {!isOwnAd && (
                        <div className={styles.favoriteButton}>
                            {/* @ts-expect-error - TS2322 - Type '{ ad: LiteAd; viewSection: string; }' is not assignable to type 'IntrinsicAttributes & { ref?: Ref<unknown> | undefined; key?: Key | null | undefined; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } & { ...; }'. */}
                            <FavoriteButton ad={ad} viewSection={ViewSections.BODY} />
                        </div>
                    )}
                    {isMobile && isListLayout && renderLocation(false)}
                    <Flex
                        className={classNames(styles.subtitle, {
                            [styles.small]: !priceLabel && !badges.length,
                            [styles.hide]: isGridLayout && !subtitle,
                        })}
                        aria-label="Subtitle"
                    >
                        {!!subtitle && !isGridGeneralCategory && (
                            <Flex alignCenter className={styles.subtitleWrapper}>
                                {subtitle}
                            </Flex>
                        )}
                        {isListLayout && renderBadges()}
                    </Flex>
                    <div className={styles.expandableDiv} />
                    {isGridLayout && !subtitle && (
                        <div
                            className={classNames(styles.whiteSpace, {
                                [styles.largeSpace]: !priceLabel,
                            })}
                        />
                    )}
                    <div
                        className={classNames(styles.locationContainer, {
                            /**
                             * When showing agency logo we want smaller width for the location/ad time
                             * container because the logo takes a big chunk of the size.
                             * Verified Icon takes less size so we allow bigger width for such container.
                             */
                            [styles.smallContainer]:
                                showAgency && !showVerifiedStatusInSellerDetails,
                            [styles.mediumContainer]: showVerifiedStatusInSellerDetails,
                        })}
                    >
                        {(!isMobile || isGridLayout) && renderLocation(isListLayout)}
                        <span
                            className={classNames(styles.creationDate, {
                                [styles.bottom]: !shouldShowDeliveryCTA,
                            })}
                        >
                            <CreationDate createdAt={ad.timestamp} />
                        </span>
                    </div>
                    {isListLayout && (
                        <div
                            className={classNames(styles.whiteSpace, {
                                [styles.largeSpace]: !priceLabel && !badges.length,
                                [styles.smallSpace]: shouldShowDeliveryCTA && isMobile,
                            })}
                        />
                    )}
                    {!isOwnAd && isListLayout && (
                        <ContactButtons ad={ad} viewSection={viewSection} />
                    )}
                    {isMobile ? (
                        <SellerDetailsCompact
                            ad={ad}
                            showAgency={showAgency}
                            showVerifiedBadge={showVerifiedStatusInSellerDetails}
                        />
                    ) : (
                        <SellerDetails
                            ad={ad}
                            showAgency={showAgency}
                            showVerifiedBadge={verifiedProfileBadgeConfig.show}
                            showElite={showElite}
                            isGridLayout={isGridLayout}
                        />
                    )}
                </div>
            </div>
        </article>
    );
};

export default React.memo(AdCardForNormalCategory);
