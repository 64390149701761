import {
    Filter,
    ExactFilter,
    RefinementFilter,
    RangeFilter,
} from '@sector-labs/fe-search-redux/filters';

import type {
    CategoryFieldsFilters,
    CategoryFieldFilterValue,
    FlatCategoryField,
} from 'horizontal/types';
import { CategoryFieldFilterType } from 'horizontal/types';

export const FilterTypeToFeSearchReduxFilter = {
    [CategoryFieldFilterType.SINGLE_CHOICE]: ExactFilter,
    [CategoryFieldFilterType.MULTIPLE_CHOICES]: RefinementFilter,
    [CategoryFieldFilterType.RANGE]: RangeFilter,
} as const;

const updatedValue = (
    filterType: Values<typeof CategoryFieldFilterType>,
    value: CategoryFieldFilterValue,
) => {
    if (!value) {
        return value;
    }
    if (filterType === CategoryFieldFilterType.MULTIPLE_CHOICES && !Array.isArray(value)) {
        return [value];
    }
    return value;
};

export const searchParamToFilter = (
    filterType: Values<typeof CategoryFieldFilterType>,
    attribute: string,
    value: CategoryFieldFilterValue,
) => {
    const FilterType = FilterTypeToFeSearchReduxFilter[filterType];
    if (!FilterType) {
        return null;
    }

    return new FilterType({
        attribute: `extraFields.${attribute}`,
        // @ts-expect-error - TS2322 - Type 'CategoryFieldFilterValue' is not assignable to type 'CategoryFieldFilterValue & RefinementFilterValue<CategoryFieldFilterValue> & (RangeFilterValue | null)'.
        value: updatedValue(filterType, value),
    });
};

const searchParamsToFilters = (
    categoryFields: Array<FlatCategoryField>,
    filters: CategoryFieldsFilters,
): Array<Filter> => {
    // @ts-expect-error - TS2322 - Type '(RefinementFilter<T> | RangeFilter | ExactFilter<CategoryFieldFilterValue> | null)[]' is not assignable to type 'Filter<unknown>[]'.
    return Object.entries(filters)
        .map(([key, value]: [any, any]) => {
            const filter = categoryFields.find((field) => field.attribute === key);
            if (!filter) {
                return null;
            }

            return searchParamToFilter(filter.filterType, key, value);
        })
        .filter((filter) => !!filter);
};

export default searchParamsToFilters;
