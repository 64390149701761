import * as React from 'react';
import IconCheck from '@app/assets/icons/iconCheck.svg';

import styles from './styles/checkedIcon.cssm';
import Flex from './flex';

const CheckedIcon = () => (
    <Flex alignCenter justifyCenter className={styles.checkContainer}>
        <IconCheck className={styles.iconCheck} />
    </Flex>
);

export default CheckedIcon;
