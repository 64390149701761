import type { MixedAd, ProductPurchase } from '../types';
import { ProductType } from '../types';

const filterEligibleProductPurchases = <T extends ProductPurchase>(
    ad: MixedAd | null | undefined,
    productPurchases: Array<T>,
): Array<T> => {
    if (!productPurchases || !ad) {
        return [];
    }

    const category = ad.category;
    const location = ad.location;

    const activeProducts = Object.keys(ad.activeProducts || []);
    const productsToFilterBy = new Set([
        ...activeProducts,
        ...(activeProducts.includes(ProductType.FEATURED) ? [ProductType.ELITE] : []),
        ...(activeProducts.includes(ProductType.ELITE)
            ? [ProductType.FEATURED, ProductType.REFRESH, ProductType.AUTO_REFRESH_AD]
            : []),
        ...(activeProducts.includes(ProductType.AUTO_REFRESH_AD)
            ? [ProductType.ELITE, ProductType.REFRESH]
            : []),
        ProductType.LIMIT_BUMP,
    ]);

    return productPurchases.filter((userPackage) => {
        const productItems = userPackage.productPackageOffer.productPackage.productItems;
        if (productItems.some((item) => productsToFilterBy.has(item.type))) {
            return false;
        }
        const packageLocation = userPackage.productPackageOffer.locationID;
        const packageCategory = userPackage.productPackageOffer.categoryID;

        if (!packageLocation && !packageCategory) {
            return false;
        }

        if (packageLocation) {
            const locationMatches = !!location.find(
                (locationNode) => locationNode.externalID === packageLocation,
            );
            if (!locationMatches) {
                return false;
            }
        }

        return !!category.find((categoryNode) => categoryNode.externalID === packageCategory);
    });
};

export default filterEligibleProductPurchases;
