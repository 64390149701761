import * as React from 'react';
import { Flex } from 'strat/components';

import VideoIcon from 'horizontal/assets/icons/iconAdVideo_noinline.svg';
import InspectIcon from 'horizontal/assets/icons/iconInspect_noinline.svg';
import { selectAdBadges } from 'horizontal/util';
import { LiteAd } from 'horizontal/types';

import styles from './styles/adTags.cssm';

type Props = {
    ad: LiteAd;
};

const AdTags = ({ ad }: Props) => {
    const tags = selectAdBadges(ad);
    const showInspectIcon = (!ad.videoCount && tags.length) || (ad.videoCount && tags.length > 1);

    if (!tags.length) {
        return null;
    }

    return (
        <Flex justifyCenter className={styles.tagsContainer}>
            {!!ad.videoCount && <img src={VideoIcon} alt="Video Icon" />}
            {!!showInspectIcon && <img src={InspectIcon} alt="Inspect Icon" />}
        </Flex>
    );
};

export default AdTags;
