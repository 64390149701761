import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import iconNotFoundPNG from '@app/assets/icons/iconNotFound.png';
import iconNotFoundWEBP from '@app/assets/icons/iconNotFound.webp';

import styles from './styles/noHitsImage.cssm';

const NoHitsImage = () => {
    const i18n = useI18n();

    return (
        <picture>
            <source srcSet={iconNotFoundWEBP} type="image/webp" />
            <source srcSet={iconNotFoundPNG} type="image/png" />
            <img src={iconNotFoundPNG} alt={t(i18n)`Not found`} className={styles.icon} />
        </picture>
    );
};

export default NoHitsImage;
