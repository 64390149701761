import * as React from 'react';

import styles from './styles/telesalesTool.cssm';
import TeleSalesTableCellValue from './teleSalesTableCellValue';

type TableHeader = {
    text: string;
    value: string | ((item: unknown) => string | React.ReactNode);
};

type Props = {
    headers: TableHeader[];
    item: Record<string, unknown>;
    itemKey: string;
    radioInputName: string;
    selectedItem: string | undefined;
    setSelectedItem: (value: string | undefined) => void;
};

const TeleSalesTableRow = ({
    headers,
    item,
    itemKey,
    radioInputName,
    selectedItem,
    setSelectedItem,
}: Props) => {
    const value = item[itemKey] as string;

    return (
        <div className={styles.tableRow}>
            <input
                type="radio"
                onChange={() => setSelectedItem(value)}
                value={value}
                name={radioInputName}
                checked={value === selectedItem}
            />

            {headers.map((header) => (
                <TeleSalesTableCellValue
                    key={header.text}
                    item={item}
                    valueProvider={header.value}
                />
            ))}
        </div>
    );
};

export default TeleSalesTableRow;
