import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';

import { useI18n } from 'strat/i18n/language';
import { PhoneNumberLink } from 'strat/contact';
import type { PhoneNumberType } from 'strat/property/types';

import styles from './styles/phoneNumber.cssm';

/**
 * Properties for {@see ContactFormSlideIn}.
 */
export const Phone = (i18n: I18n, name: string): string => {
    if (name === 'mobile' || name === 'mobileNumbers') {
        return t(i18n)`mobile`;
    }
    if (name === 'phone' || name === 'phoneNumbers') {
        return t(i18n)`phone`;
    }
    return '';
};

type Props = {
    /**
     * Method to call when a phone number is clicked.
     */
    onPhoneNumberClick?: (event: React.SyntheticEvent<any>) => void;
    phoneNumbers: Array<PhoneNumberType>;
};

const ContactNumbersDisplay = ({ phoneNumbers, onPhoneNumberClick }: Props) => {
    const i18n = useI18n();
    const filteredPhoneNumbers: Array<PhoneNumberType> = phoneNumbers.filter(
        ({ type, numbers }) =>
            !['proxyMobile', 'proxyPhone', 'fax', 'whatsapp'].includes(type) &&
            numbers.length !== 0,
    );

    return (
        <>
            {filteredPhoneNumbers.map(({ type, numbers }) => (
                <div className={styles.row} key={type}>
                    <span className={styles.key}>{Phone(i18n, type)}</span>
                    <span className={styles.value}>
                        <PhoneNumberLink
                            // @ts-expect-error - TS2322 - Type '{ numbers: string[]; className: any; onClick: (event: SyntheticEvent<any, Event>) => void; "aria-label": string; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, WithCleanPhoneNumberProps> & RefAttributes<any> & { children?: ReactNode; }'.
                            numbers={numbers}
                            className={styles.phoneNumber}
                            onClick={(event: React.SyntheticEvent<any>): void => {
                                if (onPhoneNumberClick) {
                                    onPhoneNumberClick(event);
                                }
                            }}
                            aria-label="Listing phone number"
                        />
                    </span>
                </div>
            ))}
        </>
    );
};

export default ContactNumbersDisplay;
