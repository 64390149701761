import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import { AgentState } from 'strat/agency/agent/types';

export type FetchAgentDetailsParams = {
    readonly agentExternalID: string;
    readonly state?: Values<typeof AgentState>;
    readonly countAdsByState?: boolean;
};

const factory = new FetcherFactory(
    ['agencyPortal', 'agentDetails'],
    (params: FetchAgentDetailsParams | null | undefined, state: any) => {
        if (!params?.agentExternalID) {
            throw new Error('Missing agent external id ID.');
        }
        return new StratAPI(state.i18n.language).agent(
            params?.agentExternalID,
            params?.state,
            params?.countAdsByState,
        );
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const fetchAgentDetails = factory.creator();
const agentDetailsReducer = factory.reducer();

export { fetchAgentDetails };

export default agentDetailsReducer;
