//@ts-nocheck
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import omit from 'lodash/omit';
import 'whatwg-fetch';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay, virtualize } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';

const VirtualizeSwipeableViews = autoPlay(virtualize(SwipeableViews));

const VirtualizeSwitchTypes = Object.freeze({
    MOVE: 'move',
    END: 'end',
});

class AutoPlayCarousel extends Component {
    static defaultProps = {
        columnsCount: 3,
        paused: false,
    };

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.state = {
            index: 0,
        };
    }

    getStyle(customStyle: Array) {
        const { columnsCount, useCustomPadding } = this.props;

        if (useCustomPadding) {
            return customStyle;
        }

        const padding =
            Math.floor((100 / columnsCount) * Math.floor(columnsCount / 2) * 1000) / 1000;

        return {
            padding: `0 calc(${padding}%)`,
            ...customStyle,
        };
    }

    indexChanged(index: number): void {
        this.setState(
            {
                index,
            },
            this.notifyIndexChanged,
        );
    }

    next(count: any): void {
        // Manually checking & setting count since we can receive garbage instead
        let slidesToMove = Number.isInteger(count) ? count : 1;
        if (!slidesToMove) {
            return;
        }
        this.setState((prevState) => {
            if (this.props.slideCount) {
                slidesToMove = Math.min(this.props.slideCount - 1 - prevState.index, slidesToMove);
            }
            return {
                index: prevState.index + slidesToMove,
            };
        }, this.notifyIndexChanged);
    }

    prev(count: any): void {
        // Manually checking & setting count since we can receive garbage instead
        let slidesToMove = Number.isInteger(count) ? count : 1;
        if (!slidesToMove) {
            return;
        }
        this.setState((prevState) => {
            if (this.props.slideCount) {
                slidesToMove = Math.min(prevState.index, slidesToMove);
            }
            return {
                index: prevState.index - slidesToMove,
            };
        }, this.notifyIndexChanged);
    }

    notifyIndexChanged() {
        if (!this.props.onIndexChanged) {
            return;
        }
        const { index } = this.state;
        const position = mod(index, this.props.items.length);
        this.props.onIndexChanged({ index, position });
    }

    slideRenderer({ index }: { index: number }): Node {
        const position = mod(index, this.props.items.length);
        return this.props.renderSlide({ index, position });
    }

    render() {
        const passedProps = omit(this.props, [
            'items',
            'renderPrevButton',
            'renderNextButton',
            'renderDotSlider',
            'style',
            'renderSlide',
            'onIndexChanged',
            'columnsCount',
            'paused',
            'carouselClassName',
            'useCustomPadding',
            'followSwipes',
        ]);

        const enableAutoPlay = !this.props.paused && !CONFIG.runtime.DISABLE_AUTO_PLAY_CAROUSELS;
        const style = {
            ...this.getStyle(this.props.carouselClassName),
            ...(this.props.style || {}),
        } as const;

        const onSwipe = (index: any, switchType: any) => {
            if (switchType !== VirtualizeSwitchTypes.END) {
                return;
            }
            const newIndex = this.state.index + (index - this.props.overscanSlideBefore);
            this.indexChanged(newIndex);
        };

        return (
            this.props.items && [
                this.props.renderPrevButton(this.prev),
                <VirtualizeSwipeableViews
                    enableMouseEvents
                    index={this.state.index}
                    onChangeIndex={this.indexChanged}
                    slideRenderer={this.slideRenderer}
                    onSwitching={this.props.followSwipes ? onSwipe : null}
                    style={style}
                    key="carousel"
                    autoplay={enableAutoPlay}
                    {...passedProps}
                />,
                this.props.renderNextButton(this.next),
                this.props.renderDotSlider?.(this.indexChanged, this.state.index),
            ]
        );
    }
}

export default AutoPlayCarousel;
