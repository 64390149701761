import React from 'react';
import { connect, useSelector } from 'react-redux';
import type { I18n } from '@lingui/core';
import staticPages from '@app/branding/staticPages';

import { useI18n } from 'strat/i18n/language';
import { SocialTitleTags, SocialDescriptionTags } from 'strat/social/tags';
import { selectLayout } from 'strat/layout/selectors';

/**
 * Renders a Static page (a html template read from the store) into a div.
 */
// @ts-expect-error - TS7031 - Binding element 'pageData' implicitly has an 'any' type.
const Page = ({ pageData }) => {
    const layout = useSelector(selectLayout);

    if (!pageData) {
        return null;
    }

    const page = pageData.content;

    return <div className={layout} dangerouslySetInnerHTML={{ __html: page }} />;
};

export default connect((state) => ({
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    pageData: state.staticPage.data,
}))(Page);

/**
 * Renders the tags of the static page
 */
// @ts-expect-error - TS7031 - Binding element 'pageData' implicitly has an 'any' type.
const Tags = ({ pageData }) => {
    const i18n = useI18n();

    if (!pageData) {
        return null;
    }

    const pageEntry = staticPages.find((entry) => entry.route === pageData.route) || {};
    // @ts-expect-error - TS2339 - Property 'title' does not exist on type '{}'.
    const title = pageEntry.title || ((_: I18n) => '');
    // @ts-expect-error - TS2339 - Property 'description' does not exist on type '{}'.
    const description = pageEntry.description || ((_: I18n) => '');
    // @ts-expect-error - TS2339 - Property 'script' does not exist on type '{}'.
    const script = pageEntry.script;

    return (
        <>
            <title key="title">{title(i18n)}</title>
            <SocialTitleTags key="socialMediaTitle" title={title(i18n)} />
            <meta key="metaDescription" name="description" content={description(i18n)} />
            <SocialDescriptionTags key="socialMediaDescription" description={description(i18n)} />
            {script && <script key="embeddedScript" type="text/javascript" src={script} />}
        </>
    );
};

const StaticPageTags = connect((state) => ({
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    pageData: state.staticPage.data,
}))(Tags);

export { StaticPageTags };
