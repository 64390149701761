import * as React from 'react';
import classNames from 'classnames';

import IconBackThin from '@app/assets/icons/iconBackThin.svg';
import { Flex, Text, BackButton } from 'strat/components';

import styles from './styles/formContainer.cssm';

type Props = {
    readonly title?: React.ReactNode;
    readonly privacyNoticeLabel?: string;
    readonly onBack?: () => void;
    readonly children: React.ReactNode;
    readonly renderFooter?: () => React.ReactElement;
    readonly contentClassName?: string;
};

const FormContainer = ({
    title,
    privacyNoticeLabel,
    onBack,
    children,
    renderFooter,
    contentClassName = styles.content,
}: Props) => (
    <Flex alignCenter column>
        {!!onBack && (
            <BackButton
                onBack={onBack}
                className={styles.backButton}
                renderIcon={() => (
                    <IconBackThin
                        className={classNames(styles.backButton, styles.thinBackButton)}
                    />
                )}
            />
        )}
        {title && (
            <Text.Base bold className={styles.title}>
                {title}
            </Text.Base>
        )}
        <form className={contentClassName}>{children}</form>
        {!!privacyNoticeLabel && (
            <Text.Base className={styles.privacyNoticeLabel}>{privacyNoticeLabel}</Text.Base>
        )}
        {renderFooter && renderFooter()}
    </Flex>
);

export default FormContainer;
