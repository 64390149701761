import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import settings from '@app/branding/settings';

import { selectUserProfile } from 'strat/user/selectors';
import { InternalAPI } from 'strat/api';
import type { APIResponse, LoginResponse, ImplicitRegisterResponse } from 'strat/api/types';
import type { GlobalState, AppDispatch } from 'strat/state';
import type { UserProfileData, UserRegistrationData } from 'strat/user/types';
import { setUser, nukeUserAndClearEverything, setLoading } from 'strat/user/state';
import { setSettings } from 'strat/settings/state';
import type { ExpressSessionData } from 'strat/server/expressSession';
import { setSavedSearches } from 'strat/search/savedSearches/state';
import { addAuthenticationContext, setAuthenticationLoginType } from 'strat/auth/state';
import type { SavedSearchData } from 'strat/search/savedSearches/types';
import { LoginType } from 'strat/auth/types';

import type { EnsuredUserData, EnsureUserWithEmailOptions } from '../types';

/**
 * Process login promises by handling login results
 */
const handleLogin = (
    loginType: LoginType,
    promise: Promise<APIResponse<ExpressSessionData>>,
    dispatch: AppDispatch,
): Promise<unknown> => {
    dispatch(setLoading(true));
    return promise
        .then(({ status, data }) => {
            dispatch(setUser(data.profile));

            if (!CONFIG.runtime.ENABLE_STRAT_SAVED_SEARCHES) {
                dispatch(setSavedSearches(data.savedSearches || ([] as SavedSearchData[])));
            }

            if (!settings.disableBackOfficeSettingsPersistence && data.settings) {
                dispatch(setSettings(data.settings));
            }

            dispatch(addAuthenticationContext(data.authenticationContext));
            dispatch(setAuthenticationLoginType(loginType));

            return { status, data };
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
};

/**
 * Attempts to log in the user by verifying the specified credentials
 * and retrieving the user's profile and personal settings.
 */
export const login = (email: string, password: string, remember: boolean) =>
    handleLogin.bind(
        null,
        LoginType.EMAIL_PASSWORD,
        new InternalAPI().login(email, password, remember),
    ) as ThunkAction<Promise<LoginResponse>, GlobalState, unknown, AnyAction>;

/**
 * Attempts to log in the user with the specified Facebook auth token.
 */
export const loginWithFacebook = (profileData: any) =>
    handleLogin.bind(
        null,
        LoginType.FACEBOOK_POPUP,
        new InternalAPI().loginWithFacebook(profileData),
    ) as ThunkAction<Promise<LoginResponse>, GlobalState, unknown, AnyAction>;

/**
 * Attempts to log in the user with the specified Google auth token.
 */
export const loginWithGoogle = (profileData: any) =>
    handleLogin.bind(
        null,
        LoginType.GOOGLE_POPUP,
        new InternalAPI().loginWithGoogle(profileData),
    ) as ThunkAction<Promise<LoginResponse>, GlobalState, unknown, AnyAction>;

/**
 * Logs the user out.
 */
export const logout =
    (): ThunkAction<Promise<APIResponse<unknown>>, GlobalState, unknown, AnyAction> =>
    (dispatch, _getState) =>
        new InternalAPI().logout().then((response) => {
            dispatch(nukeUserAndClearEverything());
            return response;
        });

/**
 * Attempts to register a new user with the specified data.
 */
export const register = (registrationData: UserRegistrationData) =>
    new InternalAPI().register({
        ...registrationData,
        phoneNumber: registrationData.phoneNumber.replace(/-/g, ''),
    });

/**
 * Attempts to register a user given only an email address
 * for use cases such as 'lead generation' or 'alerts subscription'
 */
export const implicitRegister =
    (
        email: string,
        shouldLogin = true,
    ): ThunkAction<Promise<ImplicitRegisterResponse>, GlobalState, unknown, AnyAction> =>
    (dispatch) =>
        new InternalAPI().implicitRegister(email).then(({ status, data }) => {
            if (data.registration.isNewlyRegistered && data.session && shouldLogin) {
                return handleLogin(
                    LoginType.EMAIL_IMPLICIT,
                    Promise.resolve({ status, data: data.session }),
                    dispatch,
                ).then(() => ({ status, data }) as ImplicitRegisterResponse);
            }

            return { status, data } as ImplicitRegisterResponse;
        });

/**
 * Attempts to reset the specified user's password by sending
 * them a link to reset it via email.
 */
export const resetPassword =
    (email: string): ThunkAction<Promise<APIResponse<unknown>>, GlobalState, unknown, AnyAction> =>
    () =>
        new InternalAPI().resetPassword(email);

/**
 * Confirms a user password reset request by verifying the token
 * and storing the new password.
 */
export const resetPasswordConfirm =
    (
        token: string,
        password: string,
    ): ThunkAction<Promise<APIResponse<unknown>>, GlobalState, unknown, AnyAction> =>
    () =>
        new InternalAPI().resetPasswordConfirm(token, password);

/**
 * Adds an email address to an already existing user
 */
export const addEmailToUserProfile =
    (email: string): ThunkAction<Promise<APIResponse<unknown>>, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) =>
        new InternalAPI().addEmailToUserProfile(email).then((response) => {
            const state = getState();
            const profile = { ...state.user.profile, email } as UserProfileData;
            dispatch(setUser(profile));
            return response;
        });

export const ensureUserWithEmail =
    (
        email: string,
        options: EnsureUserWithEmailOptions = {},
    ): ThunkAction<Promise<EnsuredUserData>, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const loggedInUserProfile = selectUserProfile(getState());

        if (loggedInUserProfile) {
            const ensuredUserData = {
                externalID: loggedInUserProfile.id,
                email,
                isNewlyRegistered: false,
            };

            if (!loggedInUserProfile.email) {
                return dispatch(addEmailToUserProfile(email))
                    .catch((e) => {
                        if (!options.silentlyFail) {
                            throw e;
                        }

                        return ensuredUserData;
                    })
                    .then(() => ensuredUserData);
            }

            return Promise.resolve(ensuredUserData);
        }

        return dispatch(implicitRegister(email, options.shouldLogin))
            .then(({ data }) => ({
                externalID: data.registration.externalID,
                email,
                isNewlyRegistered: data.registration.isNewlyRegistered,
            }))
            .catch((e) => {
                if (!options.silentlyFail) {
                    throw e;
                }

                return {
                    externalID: '',
                    email,
                    isNewlyRegistered: false,
                };
            });
    };
