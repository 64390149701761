export const PriceOfferType = Object.freeze({
    NULL: 'null',
    LOW: 'low',
    UNDER_MINIMUM: 'under_minimum',
    GOOD: 'good',
    VERY_GOOD: 'very_good',
    HIGH: 'high',
});

export const getPriceOfferType = (
    adPrice: number,
    offeredPrice?: number | null,
    minimumPrice?: number | null,
): Values<typeof PriceOfferType> => {
    if (offeredPrice === null || offeredPrice === undefined) {
        return PriceOfferType.NULL;
    }
    if (minimumPrice && offeredPrice < minimumPrice) {
        return PriceOfferType.UNDER_MINIMUM;
    }
    const priceRatio = offeredPrice / adPrice;
    if (priceRatio > 1.2) {
        return PriceOfferType.HIGH;
    }
    if (priceRatio >= 0.8) {
        return PriceOfferType.VERY_GOOD;
    }
    if (priceRatio >= 0.7) {
        return PriceOfferType.GOOD;
    }
    return PriceOfferType.LOW;
};
