import url from 'url';

import { buildCanonicalURL } from './canonicalDomainTools';

/**
 * Returns the given path if the path is an absolute URL, otherwise the path is considered relative
 * and the function returns its canonical version (which is absolute).
 */
const ensureAbsoluteURL = (path: string | null, language: string): string | null => {
    if (!path) {
        return path;
    }

    const parsedURL = url.parse(path);
    return parsedURL.hostname ? path : buildCanonicalURL(path, language);
};

export { ensureAbsoluteURL };
