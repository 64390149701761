import * as React from 'react';
import { Trans } from '@lingui/macro';
import {
    useSearchFacetValues,
    useSearchPagination,
    useTotalSearchPageCount,
} from '@sector-labs/fe-search-redux/state';
import { ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';
import { FilterValues } from 'strat/search';
import { Flex, Text } from 'strat/components';

import { AdProduct } from 'horizontal/types';
import useEmptyDivPosition from 'horizontal/search/results/useEmptyDivPosition';
import useCTAPosition from 'horizontal/search/results/useCTAPosition';
import { useHitsPerPage } from 'horizontal/search/hooks';
import { useSearchHitsLayout } from 'horizontal/layout';
import { ExactHitList } from 'horizontal/search/results';
import ExpandLocationRadius from 'horizontal/search/results/expandLocationRadius';

import ViewAllAdsOfProduct from './viewAllAdsOfProduct';
import useSectionHits from './useSectionHits';
import styles from './styles/sectionHits.cssm';

type Props = {
    readonly loading?: boolean;
};

const SectionHits = ({ loading }: Props): React.ReactElement => {
    const sectionHits = useSectionHits();
    const selectedLayout = useSearchHitsLayout();
    const [pageNumber] = useSearchPagination();
    const [totalPageCount] = useTotalSearchPageCount();
    const hitsPerPage = useHitsPerPage();
    const [emptyDivPosition, emptyDivIndex] = useEmptyDivPosition([
        { hits: sectionHits.eliteHitList },
        { hits: sectionHits.featuredHitList },
        { hits: sectionHits.exactHitList },
    ]);
    const [CTAPosition, CTAIndex] = useCTAPosition([
        { hits: sectionHits.eliteHitList },
        { hits: sectionHits.featuredHitList },
        { hits: sectionHits.exactHitList },
    ]);

    const productFacets = useSearchFacetValues(FilterValues.product.attribute);
    const featuredAdsCount =
        productFacets.find((facet) => facet.value === AdProduct.FEATURED)?.count || 0;

    const featuredAdsCountLimit = settings.featuredAdsGroupSizes(hitsPerPage).default;

    const commonParams = {
        viewType: selectedLayout,
        loading,
        viewSection: ViewSections.SEARCH_RESULTS,
    } as const;

    const skipExactHits =
        sectionHits.exactHitList.length === 0 &&
        (sectionHits.featuredHitList.length > 0 || sectionHits.eliteHitList.length > 0);

    return (
        <div className={styles.container}>
            {sectionHits.eliteHitList.length > 0 && (
                <ExactHitList
                    {...commonParams}
                    hits={sectionHits.eliteHitList}
                    title={
                        <Flex justifySpaceBetween stretchWidth className={styles.sectionTitle}>
                            <Text.Base bold>
                                <Trans>Elite ads</Trans>
                            </Text.Base>
                            <ViewAllAdsOfProduct product={AdProduct.ELITE} />
                        </Flex>
                    }
                    emptyDivPosition={emptyDivIndex === 0 ? emptyDivPosition : undefined}
                    CTAPosition={CTAIndex === 0 ? CTAPosition : undefined}
                />
            )}
            {sectionHits.featuredHitList.length > 0 && (
                <ExactHitList
                    {...commonParams}
                    hits={sectionHits.featuredHitList}
                    title={
                        <Flex justifySpaceBetween stretchWidth className={styles.sectionTitle}>
                            <Text.Base bold>
                                <Trans>Featured ads</Trans>
                            </Text.Base>
                            {featuredAdsCount > featuredAdsCountLimit && (
                                <ViewAllAdsOfProduct product={AdProduct.FEATURED} />
                            )}
                        </Flex>
                    }
                    emptyDivPosition={emptyDivIndex === 1 ? emptyDivPosition : undefined}
                    CTAPosition={CTAIndex === 1 ? CTAPosition : undefined}
                    startingHitsIndex={sectionHits.eliteHitList.length}
                />
            )}
            {!skipExactHits && (
                <ExactHitList
                    {...commonParams}
                    hits={sectionHits.exactHitList}
                    title={
                        <Flex stretchWidth className={styles.sectionTitle}>
                            <Text.Base bold>
                                <Trans>Ads</Trans>
                            </Text.Base>
                        </Flex>
                    }
                    emptyDivPosition={emptyDivIndex === 2 ? emptyDivPosition : undefined}
                    CTAPosition={CTAIndex === 2 ? CTAPosition : undefined}
                    loadingHitsCount={hitsPerPage}
                    startingHitsIndex={
                        sectionHits.eliteHitList.length + sectionHits.featuredHitList.length
                    }
                />
            )}
            {sectionHits.fallbackHitList.length > 0 && (
                <ExactHitList
                    {...commonParams}
                    hits={sectionHits.fallbackHitList}
                    title={
                        <Flex stretchWidth className={styles.sectionTitle}>
                            <Text.Base bold>
                                <Trans>Results from other locations</Trans>
                            </Text.Base>
                        </Flex>
                    }
                    emptyDivPosition={emptyDivIndex === 2 ? emptyDivPosition : undefined}
                    CTAPosition={CTAIndex === 2 ? CTAPosition : undefined}
                    loadingHitsCount={hitsPerPage}
                    startingHitsIndex={
                        sectionHits.eliteHitList.length +
                        sectionHits.featuredHitList.length +
                        sectionHits.exactHitList.length
                    }
                />
            )}
            {totalPageCount === pageNumber && <ExpandLocationRadius />}
        </div>
    );
};

export default SectionHits;
