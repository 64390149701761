import { statelessUrlProcessor } from '@app/branding/urlProcessor';

import { serializeLocation } from 'react-true-router/location';
import createLoginCallbackURL, {
    type LoginCallbackParams,
} from 'strat/routes/createLoginCallbackURL';
import { decodeURL } from 'react-true-router/location';
import { buildCanonicalURL } from 'strat/routing';

import type { LoginType, AuthFlow } from '../types';

type Options = {
    languageCode: string;
    redirectPath: string | null;
    loginType: LoginType | null;
    authFlow: AuthFlow | null;
    subscribeToMarketingEmails?: boolean;
};

/**
 * Used to compute the callback url that keycloak will redirect when the third party login is successful.
 *
 * Used twice, in this order:
 * 1. For computing the redirect uri passed to keycloak. Keycloak will redirect the user back to the LOGIN_CALLBACK with a
 * code as the hash parameter.
 *
 * Example: keycloak.com/auth?redirect_uri=strat.com/account/callback?redirectPath=/listing/1234
 * After the login is successful, keycloak will redirect the user back to
 * strat.com/account/callback?redirectPath=/listing/1234#code=1234
 *
 * 2. Exchanging the code obtained after keycloak redirected the user to the LOGIN_CALLBACK route. This must be exactly
 * the same as the redirect uri passed at 1.
 *
 * Example: POST keycloak.com/token
 * code=1234
 * redirect_uri=strat.com/account/callback?redirectPath=/listing/1234
 *
 * After the token is obtained, we redirect the user back to the original listing page (/listing/1234)
 */
export const createKeycloakThirdPartyLoginRedirectURI = ({
    languageCode,
    redirectPath,
    loginType,
    authFlow,
    subscribeToMarketingEmails,
}: Options): string => {
    const params: LoginCallbackParams = {
        redirectPath: decodeURL(
            statelessUrlProcessor && redirectPath
                ? statelessUrlProcessor.outbound(redirectPath)
                : redirectPath,
        ),
    };

    if (loginType) {
        params['loginType'] = loginType;
    }

    if (authFlow) {
        params['authFlow'] = authFlow;
    }

    if (subscribeToMarketingEmails) {
        params['subscribeToMarketingEmails'] = subscribeToMarketingEmails;
    }

    return buildCanonicalURL(serializeLocation(createLoginCallbackURL(params)), languageCode);
};
