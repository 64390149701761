import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

import type { AnyAdData } from 'strat/types';
import { buildCanonicalURL } from 'strat/routing';
import { createTrackableAdURL } from 'strat/property/url';
import { ViewSections } from 'strat/gtm';

type Props = {
    property: AnyAdData;
    i18n: I18n;
    language: string;
    traceID: string;
    /**
     * View section this component is placed on.
     */
    viewSection?: Values<typeof ViewSections>;
};

/**
 * Sends a WhatsApp message to the specified phone number about
 * the specified property.
 * @param property The property for which to send the message.
 * @param i18n i18n instance.
 * @param language Current language.
 * @param traceID Trace id of the view to be confirmed
 * @param viewSection current viewSection
 */
const generateWhatsAppURL = ({ property, i18n, language, traceID, viewSection }: Props): string => {
    const propertyURL = buildCanonicalURL(
        createTrackableAdURL({ externalID: property.externalID, traceID }),
        language,
    );

    const brandName = settings.getBrandName(i18n);
    const phoneNumber = (property.phoneNumber || {}).whatsapp;

    const message =
        viewSection === ViewSections.REQUEST_VIDEO
            ? t(
                  i18n,
              )`I am interested in this property and would like to request for a video before booking a viewing. Please get in touch with me as soon as possible, thank you. Link: ${propertyURL}`
            : t(i18n)`Hi, I am interested in your property on ${brandName}. Link: ${propertyURL}`;

    return `https://wa.me/${phoneNumber as any}?text=${encodeURI(message)}`;
};

export default generateWhatsAppURL;
