import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRouteURL } from 'strat/routes/selectors';
import useGooglePublisherTag from 'strat/tagmanager/useGooglePublisherTag';

// This component has the role of resetting the GPT token
// on URL change. More information in this issue description:
// https://www.pivotaltracker.com/story/show/182081907
const GptTokenResetter = () => {
    const route = useSelector(selectRouteURL);
    const tagManager = useGooglePublisherTag();

    React.useEffect(() => {
        if (tagManager) {
            tagManager.cmd.push(() => {
                tagManager.destroySlots();
            });
        }

        return undefined;
    }, [route, tagManager]);

    return null;
};

export default GptTokenResetter;
