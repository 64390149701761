import { useSelector } from 'react-redux';
import { selectSearchSettings, selectSearchLoadingState } from '@sector-labs/fe-search-redux/state';

import { useCategoryFieldsAreLoading } from 'horizontal/categoryFields';

export const useHitsPerPage = (): number =>
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GlobalState'.
    useSelector((state) => selectSearchSettings(state)?.hitsPerPage || 0);

export const useIsSearchLoading = (): boolean => {
    const categoryFields = useCategoryFieldsAreLoading();
    const { loading: search } = useSelector(selectSearchLoadingState);
    return categoryFields || search;
};

export const useSearchSlots = () => {
    const searchSettings = useSelector(selectSearchSettings);
    const activeSlotRule = searchSettings.activeSlotRule || 'default';
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'slots' does not exist on type 'string[] | SlotRulesConfig'.
    return searchSettings.slotRules[activeSlotRule]?.slots || [];
};
