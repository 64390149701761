import { useSelector } from 'react-redux';
import { selectGeoLocationData } from 'strat/user/selectors';
import brandingSettings from '@app/branding/settings';

import { Location } from 'horizontal/types';
import { useLocationFilterValue } from 'horizontal/search/location';

const useCurrentLocation = () => {
    const userLocation = useSelector(selectGeoLocationData);
    return (useLocationFilterValue() ||
        userLocation.closestLocation ||
        brandingSettings.topLevelLocation) as unknown as Location;
};

export default useCurrentLocation;
