import * as React from 'react';
import { Text } from 'strat/components';

import styles from './styles/filterContainer.cssm';

type Props = {
    readonly title?: React.ReactNode;
    readonly children?: React.ReactNode;
    readonly label?: string;
    readonly className?: string;
    readonly titleClassName?: string;
    readonly uppercaseTitle?: boolean;
};

const FilterContainer = ({
    title,
    children,
    label,
    className = styles.container,
    titleClassName = styles.title,
    uppercaseTitle = true,
}: Props) => (
    <div className={className} aria-label={label}>
        {title && (
            <div className={titleClassName}>
                <Text.Large bold uppercase={uppercaseTitle}>
                    {title}
                </Text.Large>
            </div>
        )}
        <div>{children}</div>
    </div>
);

export default FilterContainer;
