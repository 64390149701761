import { t } from '@lingui/macro';
import * as React from 'react';
import { withI18n } from 'strat/i18n/language/withI18n';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { stopSyntheticEventPropagation } from 'strat/util';
import { useDialog } from 'strat/dialogs/dialogContext';
import { selectGeoLocationData } from 'strat/user/selectors';

import DialogNames from 'horizontal/dialogs/dialogNames';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { useLocationFilterValue } from 'horizontal/search/location';
import getDefaultPlaceholderText from '@app/search/freeText/getDefaultPlaceholderText';

import useFreeTextQuery from '../useFreeTextQuery';

import FreeTextInput from './freeTextInput';

const FreeTextSearchDialogTrigger = ({ className }: { className?: string }) => {
    const i18n = useI18n();
    const freeTextQuery = useFreeTextQuery();
    const category = useCategoryFilterValue();
    const userLocation = useSelector(selectGeoLocationData);
    const location = useLocationFilterValue() || userLocation.closestLocation;
    const [, setDialogVisible] = useDialog(DialogNames.FREE_TEXT_SEARCH_DIALOG);

    const placeholder = React.useMemo(() => {
        if (!freeTextQuery && !category && !location) {
            return getDefaultPlaceholderText(i18n);
        }

        const categoryName =
            freeTextQuery || category?.nameShort || category?.name || t(i18n)`All ads`;
        const locationName = location?.name || settings.getTopLevelLocation(i18n).name;

        return t(i18n)`${categoryName} in ${locationName}`;
    }, [i18n, freeTextQuery, category, location]);

    return (
        <div
            onClick={(event) => {
                stopSyntheticEventPropagation(event);
                setDialogVisible(true);
            }}
            className={className}
        >
            <FreeTextInput placeholder={placeholder} />
        </div>
    );
};

export default withI18n()(FreeTextSearchDialogTrigger);
