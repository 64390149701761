import { t } from '@lingui/macro';
import React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Text } from 'strat/components';

import type { LiteAd } from 'horizontal/types';
import AdCard from '@app/adCard/adCard';

import styles from './styles/recentlyViewedHits.cssm';

const RecentlyViewedHits = () => {
    const getLastViewedAds = (ads: Array<LiteAd>, maxLenghtToView: number): Array<LiteAd> => {
        return ads.slice(-maxLenghtToView);
    };

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const all_ads = useSelector((state) => state.recentlyViewed.ads);
    const ads = getLastViewedAds(all_ads, CONFIG.build.RECENTLY_VIEWED_ADS_MAX_VIEW_LENGTH);
    const i18n = useI18n();

    const entries = React.useMemo(
        () =>
            ads.map((ad) => (
                <div key={ad.id} className={styles.item}>
                    <AdCard
                        ad={ad}
                        viewType={SearchHitsLayout.GRID}
                        viewSection={ViewSections.SEARCH_RESULTS}
                    />
                </div>
            )),
        [ads],
    );

    if (ads.length === 0) {
        return null;
    }

    return (
        <>
            <Text.Base className={styles.title}>{t(i18n)`Recently viewed`}</Text.Base>
            <div className={styles.itemsContainer}>{entries}</div>
        </>
    );
};

export default RecentlyViewedHits;
