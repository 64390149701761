import * as React from 'react';
import { useSelector } from 'react-redux';
import { trigger, Triggers, ViewSections } from 'strat/gtm';

import type { Category } from 'horizontal/types';
import { selectCategories } from 'horizontal/categories';

import { getDeepCategoryVars } from './utils';

const useTrackCategoryDropdownClick = () => {
    return React.useCallback(() => {
        trigger(Triggers.CLICK_CATEGORY_TREE, {
            view_section: ViewSections.HEADER,
        });
    }, []);
};

const useTrackCategoryClick = () => {
    const categories = useSelector(selectCategories);

    return React.useCallback(
        (category: Category, viewSection: Values<typeof ViewSections> = ViewSections.HEADER) => {
            const categoryVars = getDeepCategoryVars(categories, category.externalID);
            trigger(Triggers.CLICK_CATEGORY, {
                ...categoryVars,
                view_section: viewSection,
            });
        },
        [categories],
    );
};

const useTrackCategoryFilterClick = () => {
    const categories = useSelector(selectCategories);

    return React.useCallback(
        (category: Category, viewSection: Values<typeof ViewSections> = ViewSections.HEADER) => {
            const categoryVars = getDeepCategoryVars(categories, category.externalID);
            trigger(Triggers.FILTER_CHANGE, {
                ...categoryVars,
                filter_name: 'category-select',
                filter_value: category.externalID,
                view_section: viewSection,
            });
        },
        [categories],
    );
};

export { useTrackCategoryDropdownClick, useTrackCategoryClick, useTrackCategoryFilterClick };
