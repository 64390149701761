import * as React from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';

import EMPTY_OBJECT from 'strat/empty/object';
import {
    findTemplate,
    renderTemplate,
    selectTemplates,
    TemplatePage,
    TemplateType,
} from 'strat/templates';

type UseHomeTemplate = {
    readonly text: React.ReactNode;
    readonly isDefault: boolean;
};

const useHomeTemplate = (type: Values<typeof TemplateType>): UseHomeTemplate => {
    const templates = useSelector(selectTemplates);

    const template = findTemplate({
        templates,
        type,
        page: TemplatePage.HOME,
    });
    const renderedTemplate = isNil(template)
        ? null
        : renderTemplate({ template, replaceParameters: EMPTY_OBJECT });

    return { text: renderedTemplate, isDefault: !!renderedTemplate };
};

export default useHomeTemplate;
