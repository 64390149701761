import * as React from 'react';

import './styles/button.css';

type Props = {
    label?: string;
    isSecondary?: boolean;
    onClick?: (event: React.SyntheticEvent<any>) => void;
    className?: string;
    children?: React.ReactNode;
    ariaLabel?: string;
};

/**
 * A simple button rendered in the current site style.
 */
const Button = ({ label, isSecondary, onClick, className, children, ariaLabel }: Props) => {
    const classes = className || (isSecondary ? 'button secondary' : 'button');
    return (
        <button className={classes} onClick={onClick} aria-label={ariaLabel}>
            {children || label}
        </button>
    );
};

export default Button;
