import type { Dispatch } from 'redux';
import EMPTY_ARRAY from 'strat/empty/array';

import { PromotedSearch } from './types';

const Actions = Object.freeze({
    SET_PROMOTED_SEARCHES: 'SET_PROMOTED_SEARCHES',
});

type PromotedSearchAction = {
    type: 'SET_PROMOTED_SEARCHES';
    data: PromotedSearch[];
};

const defaultState = {
    data: EMPTY_ARRAY,
} as const;

export type State = {
    readonly data: ReadonlyArray<PromotedSearch>;
};

const promotedSearchesReducer = (
    state: State | null | undefined = defaultState,
    action: PromotedSearchAction,
) => {
    switch (action.type) {
        case Actions.SET_PROMOTED_SEARCHES:
            return { ...state, data: action.data };
        default:
            return state;
    }
};

const setPromotedSearches =
    (data: Array<PromotedSearch>) => (dispatch: Dispatch<PromotedSearchAction>) => {
        return dispatch({
            type: Actions.SET_PROMOTED_SEARCHES,
            data,
        });
    };

export { setPromotedSearches };

export default promotedSearchesReducer;
