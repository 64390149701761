import React from 'react';
import { Trans } from '@lingui/macro';

import { LocalCurrency } from 'strat/i18n/money';
import { useI18n } from 'strat/i18n/language';
import { Area, useLocalUnits } from 'strat/i18n';
import type { ProjectUnit } from 'strat/project/types';

import UnitFloorPlanGalleryDialog from './unitFloorPlanGalleryDialog';
import { CompactUnitFloorPlanGalleryDialog } from './compact';
import UnitFloorPlansButton from './unitFloorPlansButton';
import { createUnitRangeValue, UnitAreaRange, UnitPriceRange } from './unitRangeValue';
import styles from './styles/unitTable.cssm';

enum Column {
    AREA = 'area',
    PRICE = 'price',
    FLOOR_PLAN = 'floorPlan',
}

type HeaderProps = {
    compact?: boolean;
    columnVisibility: Record<Column, boolean>;
};

export const columnVisibilityFromUnits = (units: ProjectUnit[]) => {
    return {
        [Column.AREA]: units.some((unit) => unit.areaMin || unit.areaMax),
        [Column.PRICE]: units.some((unit) => unit.priceMin || unit.priceMax),
        [Column.FLOOR_PLAN]: units.some((unit) => unit.photos?.length),
    };
};

const Header = ({ compact, columnVisibility }: HeaderProps) => {
    const i18n = useI18n();
    const { areaUnit } = useLocalUnits();

    return (
        <tr>
            <th className={styles.typeElement}>
                <Trans>Type</Trans>
            </th>
            {columnVisibility[Column.AREA] && (
                <th className={styles.areaElement}>
                    <Trans>Area ({Area.abbreviation(i18n, areaUnit)})</Trans>
                </th>
            )}
            {columnVisibility[Column.PRICE] && (
                <th className={styles.priceElement}>
                    <Trans>
                        Price (<LocalCurrency />)
                    </Trans>
                </th>
            )}
            {columnVisibility[Column.FLOOR_PLAN] && (
                <th className={styles.floorPlanElement}>
                    {compact ? null : <Trans>Floor Plan</Trans>}
                </th>
            )}
        </tr>
    );
};

type RowProps = HeaderProps & {
    unit: ProjectUnit;
};

const RowPhotos = ({ unit, compact }: Omit<RowProps, 'columnVisibility'>) => {
    const [isGalleryVisible, setIsGalleryVisible] = React.useState(false);

    if (!unit.photos?.length) {
        return <>{'-'}</>;
    }

    const Gallery = compact ? CompactUnitFloorPlanGalleryDialog : UnitFloorPlanGalleryDialog;

    return (
        <>
            <UnitFloorPlansButton onClick={() => setIsGalleryVisible(true)} />
            <Gallery
                photos={unit.photos}
                visible={isGalleryVisible}
                onVisibilityChanged={setIsGalleryVisible}
            />
        </>
    );
};

const Row = ({ unit, compact, columnVisibility }: RowProps) => {
    const areaRange = createUnitRangeValue(unit.areaMin, unit.areaMax);
    const priceRange = createUnitRangeValue(unit.priceMin, unit.priceMax);

    return (
        <tr>
            <td className={styles.typeElement}>{unit.name || '-'}</td>
            {columnVisibility[Column.AREA] && (
                <td className={styles.areaElement}>
                    <UnitAreaRange range={areaRange} />
                </td>
            )}
            {columnVisibility[Column.PRICE] && (
                <td className={styles.priceElement}>
                    <UnitPriceRange range={priceRange} />
                </td>
            )}
            {columnVisibility[Column.FLOOR_PLAN] && (
                <td className={styles.floorPlanElement}>
                    <RowPhotos unit={unit} compact={compact} />
                </td>
            )}
        </tr>
    );
};

type Props = {
    units: ProjectUnit[];
    compact?: boolean;
    hideHeader?: boolean;
    columnVisibility?: Record<string, boolean>;
};

const UnitTable = ({
    units,
    compact,
    hideHeader,
    columnVisibility: defaultColumnVisibility,
}: Props) => {
    const columnVisibility = React.useMemo(() => {
        if (defaultColumnVisibility) {
            return defaultColumnVisibility;
        }

        return columnVisibilityFromUnits(units);
    }, [defaultColumnVisibility, units]);

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <tbody>
                    {!hideHeader ? (
                        <Header columnVisibility={columnVisibility} compact={compact} />
                    ) : (
                        <tr></tr>
                    )}
                    {units.map((unit) => (
                        <Row
                            key={unit.id}
                            unit={unit}
                            columnVisibility={columnVisibility}
                            compact={compact}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UnitTable;
