import * as React from 'react';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';

import { useGenericRelatedAds, useRelatedAdsAreLoading } from 'horizontal/adDetails/relatedAds';

/**
 * Tracks impressions for ads displayed in the related ads section.
 */
const useTrackRelatedAds = (ovation: OvationCallbackProps) => {
    const isLoading = useRelatedAdsAreLoading();
    const genericRelatedAds = useGenericRelatedAds();

    React.useEffect(() => {
        if (isLoading) {
            return;
        }

        ovation.ingestAdImpressions(genericRelatedAds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, genericRelatedAds]);
};

export default useTrackRelatedAds;
