import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import InternalAPI from 'strat/api/internal';
import { useRouter } from 'react-true-router';
import { useI18n } from 'strat/i18n/language';
import { generateValidationSchema } from '@app/adUpdating/validation';
import { useIsAdDeliverable } from '@app/userOrders/hooks';
import { Flex, Text } from 'strat/components';
import { useCombinedCategoryFields } from 'strat/categoryFields';
import { useIsStratCreditUser } from 'strat/user';
import { Trans } from '@lingui/macro';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import settings from '@app/branding/settings';

import { useSubmitAd } from '@app/adCreation/submitAd';
import type { AppDispatch, GlobalState } from 'horizontal/state';
import { selectCategoryByExternalID } from 'horizontal/categories/selectors';
import { useFlatCategoryFields } from 'horizontal/categoryFields';
import { AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE } from 'horizontal/payment/types';
import type {
    FullAd,
    LiteHierarchicalLocation,
    AdPhoto,
    CategoryField,
    LiteCategory,
} from 'horizontal/types';
import { AdProduct, AdState, AppBundles } from 'horizontal/types';
import { useAd } from 'horizontal/ad/state';
import { generateInitialValues, generateInitialValuesWithDelivery } from 'horizontal/fields';
import { ReadonlyCategoryFormField } from '@app/fields/categoryFormField';
import { selectBundle, useOnSubmitAdError } from 'horizontal/adCreation';
import { AdCreationSourceRoutes as AdUpdateSourceRoutes } from 'horizontal/constants';
import { UpdateAdAction } from 'horizontal/adManagement/types';
import { useNavigateToApplyAdLimit } from 'horizontal/packages/navigation';
import { useFetchUserSettings } from 'horizontal/userSettings';
import AdCreationTips from 'horizontal/adCreation/adCreationTips';
import { useNavigateToApplyAdProductCreditCosts } from 'horizontal/adProductCreditCost';
import { fetchCategoryFieldCombinations } from '@app/categoryFieldCombinations';

import Form from './form';
import styles from './styles/adDetailsForm.cssm';

const renderForm =
    (
        liteCategory: LiteCategory,
        categoryFields: Array<CategoryField>,
        initialLocation: Array<LiteHierarchicalLocation>,
        initialPhotos: Array<AdPhoto>,
    ) =>
    (props: React.ComponentProps<typeof Form>) => (
        <Form
            {...props}
            categoryFields={categoryFields}
            initialLocation={initialLocation}
            initialPhotos={initialPhotos}
            liteCategory={liteCategory}
        />
    );

type Props = {
    readonly action?: Values<typeof UpdateAdAction>;
};

const AdDetailsForm = ({ action }: Props) => {
    const i18n = useI18n();
    // @ts-expect-error - TS2322 - Type 'MixedAd | null | undefined' is not assignable to type 'FullAd'.
    const ad: FullAd = useAd();
    const dispatch: AppDispatch = useDispatch();
    const fetchUserSettings = useFetchUserSettings();
    const isStratCreditUser = useIsStratCreditUser();
    const submitAd = useSubmitAd(ad?.externalID);
    const onError = useOnSubmitAdError({ isUpdate: true });
    const adUpdateSource = useSelector(selectBundle);

    const liteCategory = ad?.category.slice(-1)[0];
    const categoryFields = useCombinedCategoryFields(liteCategory?.id);
    const flatCategoryFields = useFlatCategoryFields(liteCategory?.id);
    const router = useRouter();
    const category = useSelector((state: GlobalState) =>
        selectCategoryByExternalID(state, liteCategory?.externalID),
    );

    const isDeliverable = useIsAdDeliverable(category);
    const adUndertakenCategoryField = flatCategoryFields.find(
        (field) => field.attribute === AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE,
    );
    const externalID = ad?.externalID;

    const initialValues = React.useMemo(() => {
        const values = isDeliverable
            ? generateInitialValuesWithDelivery(flatCategoryFields, ad)
            : generateInitialValues(flatCategoryFields, ad);
        values.description = values.description
            ?.replace(/&amp;/g, '&')
            .replace(/&lt/g, '<')
            .replace(/&gt/g, '>');
        return values;
    }, [isDeliverable, flatCategoryFields, ad]);

    const validationSchema = React.useMemo(
        () => generateValidationSchema(i18n, flatCategoryFields, category?.roles, category),
        [i18n, flatCategoryFields, category],
    );

    const navigateToApplyAdLimit = useNavigateToApplyAdLimit();
    const navigateToApplyAdProductCreditCosts = useNavigateToApplyAdProductCreditCosts();

    React.useEffect(() => {
        if (!category || category.children?.length) {
            return;
        }

        dispatch(
            fetchCategoryFields({
                categoryExternalIDs: [category.externalID],
                includeWithoutCategory: !settings.disableCategoryFieldsWithoutCategory,
                groupChoicesBySection: true,
            }),
        );

        dispatch(
            fetchCategoryFieldCombinations({
                categoryID: category.id,
            }),
        );
    }, [dispatch, category]);

    const onSuccess = React.useCallback(
        ({ data: { external_id: adExternalID, state, product } }) => {
            if (isStratCreditUser) {
                if (
                    adUpdateSource === AppBundles.AGENCY_PORTAL_BUNDLE_NAME &&
                    state !== AdState.LIMITED &&
                    [AdProduct.FEATURED, AdProduct.ELITE].includes(product)
                ) {
                    router.pushRoute(AdUpdateSourceRoutes[adUpdateSource].AD_MANAGEMENT);
                    return;
                }

                navigateToApplyAdProductCreditCosts({ adExternalID });
                return;
            }

            if (state === AdState.LIMITED) {
                return navigateToApplyAdLimit({ externalID: adExternalID });
            }

            if (
                adUpdateSource === AppBundles.AGENCY_PORTAL_BUNDLE_NAME ||
                [AdProduct.FEATURED, AdProduct.ELITE].includes(product)
            ) {
                router.pushRoute(AdUpdateSourceRoutes[adUpdateSource].AD_MANAGEMENT);
                return;
            }

            router.pushRoute(AdUpdateSourceRoutes[adUpdateSource].POST_AD_SUCCESS, {
                adExternalID,
                suppressTrigger: action === UpdateAdAction.EDIT,
            });
        },
        [
            router,
            adUpdateSource,
            action,
            navigateToApplyAdLimit,
            isStratCreditUser,
            navigateToApplyAdProductCreditCosts,
        ],
    );

    const updateTerms = React.useCallback(() => {
        if (adUndertakenCategoryField && externalID) {
            const payload = {
                terms: [AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE],
            } as const;
            return new InternalAPI().updateAdTerms(externalID, payload);
        }
        return new Promise((resolve: (result: Promise<undefined> | undefined) => void) =>
            // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
            resolve(),
        );
    }, [externalID, adUndertakenCategoryField]);

    const onSubmit = React.useCallback(
        (values, { setSubmitting, setFieldError }) => {
            updateTerms()
                .then(() => submitAd(flatCategoryFields, values))
                .then(onSuccess)
                .catch((error) => onError(error, setFieldError))
                .finally(() => setSubmitting(false));
        },
        [flatCategoryFields, submitAd, onError, onSuccess, updateTerms],
    );

    React.useEffect(() => {
        fetchUserSettings();
    }, [fetchUserSettings]);

    return (
        <Flex fillContainer>
            <Flex column className={styles.section}>
                <Flex justifySpaceBetween className={styles.fieldWrapper}>
                    <Text.Regular bold>
                        <Trans>Category</Trans>
                    </Text.Regular>
                    <Flex className={styles.fieldInput}>
                        <ReadonlyCategoryFormField activeCategory={liteCategory} />
                    </Flex>
                </Flex>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {renderForm(liteCategory, categoryFields, ad.location, ad.photos || [])}
                </Formik>
            </Flex>
            <AdCreationTips />
        </Flex>
    );
};

export default AdDetailsForm;
