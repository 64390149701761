import type { LiteCategory } from 'horizontal/types';

import Category from './category';

const checkCarCategories = (category?: LiteCategory | null) =>
    Category.isOfCarsType(category) ||
    Category.isOfBusesType(category) ||
    Category.isOfRickshawType(category) ||
    Category.isOfScootersType(category) ||
    Category.isOfTractorsType(category);

const checkPropertyCategories = (category?: LiteCategory | null) =>
    Category.isOfHousesType(category) ||
    Category.isOfApartmentsFlatsType(category) ||
    Category.isOfPortionsFloorsType(category);

export { checkCarCategories, checkPropertyCategories };
