import * as React from 'react';

import Gesture from './gesture';

/**
 * Figures out if a certain 2D movement, from {@param firstTouch} to {@param lastTouch}
 * should be treated as a drag or scroll.
 *
 * If the movement on both the X and Y axes is lower than {@param threshold},
 * the function will return Gesture.NONE.
 */
const determineScrollAxis = (firstTouch: Touch, lastTouch: Touch, threshold = 10) => {
    if (!firstTouch || !lastTouch) {
        return Gesture.NONE;
    }

    const xDelta = Math.abs(firstTouch.clientX - lastTouch.clientX);
    const yDelta = Math.abs(firstTouch.clientY - lastTouch.clientY);

    if (yDelta < threshold && xDelta < threshold) {
        return Gesture.NONE;
    }

    return xDelta >= yDelta ? Gesture.SCROLL : Gesture.DRAG;
};

const useHorizontalGesture = () => {
    const [gesture, setActiveGesture] = React.useState(Gesture.NONE);

    const computeActiveGesture = React.useCallback((initialTouch: Touch, currentTouch: Touch) => {
        // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'SetStateAction<null>'.
        setActiveGesture(determineScrollAxis(initialTouch, currentTouch));
    }, []);

    const clearGesture = React.useCallback(() => {
        setActiveGesture(Gesture.NONE);
    }, []);

    return [gesture, clearGesture, computeActiveGesture];
};

export default useHorizontalGesture;
