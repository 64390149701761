import * as React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';

import styles from './styles/input.cssm';
import InputContainer from './inputContainer';
import * as Text from './text';
import CheckedIcon from './checkedIcon';

interface RenderIconProps {
    accepted: boolean;
    focused: boolean;
    multiLine: boolean;
}

type InputContainerProps = Omit<React.ComponentProps<typeof InputContainer>, 'characterCount'>;

type InputElementProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'title' | 'name' | 'maxLength' | 'children' | 'onChange' | 'capture'
>;

interface Props extends InputContainerProps, Omit<InputElementProps, 'value'> {
    readonly renderLeft?: (() => React.ReactNode | null | undefined | null | undefined) | null;
    readonly renderRight?:
        | ((props: RenderIconProps) => React.ReactElement | null | undefined)
        | null;
    readonly unit?: string;
    readonly onChange?: (arg1: string) => void;
    readonly onSubmit?: () => void;
    readonly onKeyDown?: (arg1: React.KeyboardEvent<any>) => void;
    readonly multiLine?: boolean;
    readonly autoComplete?: string;
    readonly className?: string;
    readonly fixUnitPosition?: boolean;
    readonly readOnly?: boolean;
    readonly disabled?: boolean;
    readonly titleCustomClass?: string;
    readonly lightPlaceholder?: boolean;
    readonly lightErrorBorder?: boolean;
    readonly shouldDisplayErrorMessage?: boolean;
    readonly boldLabel?: boolean;
    readonly renderRightIcon?: () => React.ReactNode;
    readonly noArrows?: boolean;
    readonly value?: string | ReadonlyArray<string> | number | null;
}

const renderIcon = ({ accepted, multiLine }: RenderIconProps): React.ReactElement | null =>
    accepted ? (
        <div
            className={classNames(styles.checkedIcon, {
                [styles.checkedIconForMultiline]: multiLine,
            })}
        >
            <CheckedIcon />
        </div>
    ) : null;

const Input = ({
    title,
    description,
    name,
    maxLength,
    errorMessage,
    shouldDisplayErrorMessage = true,
    erroring,
    accepted,
    renderLeft,
    renderRight = renderIcon,
    unit,
    onChange,
    onFocusChanged,
    onKeyDown,
    onSubmit,
    secondary,
    ternary,
    borderless,
    multiLine,
    noArrows,
    // Chrome ignores autocomplete="off"
    autoComplete = 'nope',
    className,
    disabled = false,
    fixUnitPosition,
    readOnly,
    titleCustomClass,
    lightPlaceholder = false,
    lightErrorBorder = false,
    renderRightIcon,
    leftSideLabel,
    boldLabel,
    value,
    warningMessage,
    ...otherProps
}: Props) => {
    const [characterCount, setCharacterCount] = React.useState(0);
    const [focused, setIsFocused] = React.useState(false);

    const onFocusChange = React.useCallback(
        (isFocused) => {
            setIsFocused(isFocused);
            if (onFocusChanged) {
                onFocusChanged(isFocused);
            }
        },
        [setIsFocused, onFocusChanged],
    );

    const onInputKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.keyCode === 13 /* ENTER */ && !multiLine) {
                if (onSubmit) {
                    onSubmit();
                }

                event.currentTarget.blur();
            }

            if (onKeyDown) {
                onKeyDown(event);
            }
        },
        [onSubmit, onKeyDown, multiLine],
    );

    const handleChange = React.useCallback(
        (event) => {
            setCharacterCount(event.target.value.length);
            if (onChange) {
                onChange(event.target.value);
            }
        },
        [onChange],
    );

    React.useEffect(() => {
        if (value) {
            setCharacterCount(value.toString().length);
        }
    }, [value]);

    const input = multiLine ? (
        <textarea
            id={name}
            name={name}
            spellCheck={false}
            className={classNames(styles.input, {
                [styles.lightPlaceholder]: lightPlaceholder,
            })}
            maxLength={maxLength}
            autoComplete={autoComplete}
            onKeyDown={onInputKeyDown}
            onChange={handleChange}
            readOnly={readOnly}
            {...(!isNil(value) ? { value: value } : {})}
            {...(otherProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
        />
    ) : (
        <input
            id={name}
            name={name}
            spellCheck={false}
            className={classNames(styles.input, {
                [styles.arrowlessInput]: noArrows,
                [styles.fixedInput]: fixUnitPosition,
                [styles.disabled]: disabled,
                [styles.lightPlaceholder]: lightPlaceholder,
                [styles.paddedRightInput]: !!renderRightIcon,
            })}
            maxLength={maxLength}
            autoComplete={autoComplete}
            onKeyDown={onInputKeyDown}
            onChange={handleChange}
            readOnly={readOnly}
            {...(!isNil(value) ? { value: value } : {})}
            {...otherProps}
        />
    );

    return (
        <InputContainer
            title={title}
            description={description}
            name={name}
            maxLength={maxLength}
            errorMessage={errorMessage}
            shouldDisplayErrorMessage={shouldDisplayErrorMessage}
            erroring={erroring}
            lightErrorBorder={lightErrorBorder}
            accepted={accepted}
            characterCount={characterCount}
            onFocusChanged={onFocusChange}
            secondary={secondary}
            ternary={ternary}
            borderless={borderless}
            large={multiLine}
            className={classNames(className, { [styles.fixedInputContainer]: fixUnitPosition })}
            titleClassname={titleCustomClass}
            leftSideLabel={leftSideLabel}
            boldLabel={boldLabel}
            warningMessage={warningMessage}
        >
            {renderLeft && renderLeft()}

            {!renderLeft && unit && (
                <span className={classNames(styles.unit, { [styles.fixedUnit]: fixUnitPosition })}>
                    <Text.Small>{unit}</Text.Small>
                </span>
            )}

            {input}

            {renderRight && renderRight({ accepted: !!accepted, focused, multiLine: !!multiLine })}
            {renderRightIcon && (
                <div className={styles.rightIconContainer}>{renderRightIcon()}</div>
            )}
        </InputContainer>
    );
};

export default Input;
