import React from 'react';

type Props = {
    image: {
        sourceWEBP_3x?: string;
        sourceWEBP_2x?: string;
        sourceWEBP?: string;
        source: string;
    };
    alt?: string;
    className?: string;
    imgClassName?: string;
    loading?: string;
};

const Image = ({
    image = {
        source: '',
        sourceWEBP: '',
        sourceWEBP_2x: '',
        sourceWEBP_3x: '',
    },
    alt = '',
    className,
    imgClassName,
    loading = 'auto',
}: Props) => {
    const srcSet = [
        ...(image.sourceWEBP ? [[image.sourceWEBP, '1x'].join(' ')] : []),
        ...(image.sourceWEBP_2x ? [[image.sourceWEBP_2x, '2x'].join(' ')] : []),
        ...(image.sourceWEBP_3x ? [[image.sourceWEBP_3x, '3x'].join(' ')] : []),
    ];
    return (
        <picture className={className}>
            <source type="image/webp" srcSet={srcSet.join(', ')} />
            {/* @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"lazy" | "eager" | undefined'. */}
            <img src={image.source} alt={alt} loading={loading} className={imgClassName} />
        </picture>
    );
};

export default Image;
