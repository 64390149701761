import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text, Flex } from 'strat/components';

import IconCheck from 'horizontal/assets/icons/iconCheckThick.svg';

import styles from './styles/packagesAdvantages.cssm';

const FeaturedAdAdvantages = () => (
    <Flex column className={styles.container}>
        <Text.Large bold>
            <Trans>What is Featured Ad ?</Trans>
        </Text.Large>
        <Flex alignCenter className={styles.featureItems}>
            <IconCheck className={styles.iconCheckMark} />
            <Text.Base className={styles.sellFasterText}>
                <Trans>Get noticed with</Trans>
                <span className={styles.bold}>
                    <Trans>“FEATURED”</Trans>
                </span>
                <Trans>tag in a top position</Trans>
            </Text.Base>
        </Flex>
        <Flex alignCenter className={styles.featureItems}>
            <IconCheck className={styles.iconCheckMark} />
            <Text.Base className={styles.sellFasterText}>
                <Trans>Ad will be highlighted to top position</Trans>
            </Text.Base>
        </Flex>
    </Flex>
);

export default FeaturedAdAdvantages;
