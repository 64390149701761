import * as React from 'react';
import { Flex } from 'strat/components';
import classNames from 'classnames';

import styles from './styles/verticalSeparator.cssm';

type Props = {
    medium?: boolean;
    small?: boolean;
};

const VerticalSeparator = ({ medium, small }: Props) => {
    return (
        <Flex
            className={classNames(styles.container, {
                [styles.medium]: medium,
                [styles.small]: small,
            })}
        >
            <div className={styles.left}></div>
            <div className={styles.right}></div>
        </Flex>
    );
};

export default VerticalSeparator;
