import Category from '@app/branding/category';

import settings from '../branding/settings';

const useHasDFPCategory = (category?: string | null) => {
    const enableDfp = settings.enableNativeDfp;

    if (!category) {
        return enableDfp.categories.includes(Category.OTHERS);
    }

    if (
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'LiteCategory'.
        (Category.isOfVehiclesType(category) ||
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'LiteCategory'.
            Category.isOfJobsType(category) ||
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'LiteCategory'.
            Category.isOfPropertiesType(category)) &&
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Values<{ readonly PROPERTY_FOR_SALE: "property-for-sale"; readonly PROPERTY_FOR_RENT: "property-for-rent"; readonly PROPERTIES: "properties"; readonly HOUSE_FOR_SALE: "houses-sale"; ... 58 more ...; readonly isOfVacationHomeType: (category?: LiteCategory | ... 1 more ... | undefined) => boolean | ... 2 more ... | u...'.
        enableDfp.categories.includes(category)
    ) {
        return true;
    }

    return enableDfp.categories.includes(Category.OTHERS);
};

export default useHasDFPCategory;
