import * as React from 'react';
import { FloatingDialog } from 'strat/components';

import type { FullAd } from 'horizontal/types';

import { MarkAsSoldContent } from './removeAd';

type Props = {
    ad: FullAd;
    onVisibilityChanged: (arg: boolean) => void;
    visible: boolean;
    onSuccess: () => void;
};

const MarkAsSoldDialog = (props: Props) => {
    const { ad, onSuccess, ...rest } = props;

    if (!ad) {
        return null;
    }

    return (
        <FloatingDialog {...rest} withCloseButton dismissible>
            <MarkAsSoldContent ad={ad} onSuccess={onSuccess} />
        </FloatingDialog>
    );
};

export default MarkAsSoldDialog;
