import type { Primitive } from 'strat/types';

import type { FlatCategoryField } from 'horizontal/types';

import { formattedFieldParam } from './utils';

const formatSearchExtraField = (
    language: string,
    field: Array<Primitive> | null | undefined,
    attribute: string,
    categoryFields: Array<FlatCategoryField>,
    rangeBucket?: (arg1: Primitive) => Array<string>,
    formatFieldValue?: (arg1: Primitive) => Primitive,
): Primitive | null | undefined => {
    if (!field) {
        return null;
    }

    if (field.length) {
        const formattedFields = formattedFieldParam(language, field, attribute, categoryFields);
        // @ts-expect-error - TS2322 - Type 'Primitive[]' is not assignable to type 'Primitive | null | undefined'.
        return formatFieldValue ? formattedFields.map(formatFieldValue) : formattedFields;
    }

    if (rangeBucket) {
        // @ts-expect-error - TS2322 - Type 'string[]' is not assignable to type 'Primitive | null | undefined'. | TS2345 - Argument of type 'Primitive[]' is not assignable to parameter of type 'Primitive'.
        return rangeBucket(field);
    }

    // @ts-expect-error - TS2322 - Type 'Primitive[]' is not assignable to type 'Primitive | null | undefined'.
    return field;
};

export default formatSearchExtraField;
