import * as React from 'react';

import IconChat from 'horizontal/assets/icons/iconChat.svg';

type Props = {
    readonly className: string;
};

const ChatIcon = ({ className }: Props): React.ReactElement => <IconChat className={className} />;

export default ChatIcon;
