import * as React from 'react';
import classNames from 'classnames';
import brandingSettings from '@app/branding/settings';

import { ButtonVariant } from 'strat/components';
import { ViewSections } from 'strat/gtm';
import type { PropertyData } from 'strat/property';
import BaseWhatsappButton from 'strat/contact/buttons/whatsappButton';
import { withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm';
import { stopEventPropagation } from 'strat/util';

import styles from './styles/whatsAppButton.cssm';

/**
 * Properties for {@see WhatsAppButton}.
 */
export type WhatsAppButtonProps = GTMLeadTrackingProps & {
    property: PropertyData;
    index?: number;
    /**
     * View section this component is placed on.
     */
    viewSection?: Values<typeof ViewSections>;
    variant?: Values<typeof ButtonVariant>;
    className?: string;
    disabled?: boolean;
};

/**
 * Renders whatsapp button for a listing.
 */
const WhatsAppButton = ({
    property,
    index,
    viewSection,
    className,
    disabled = false,
    variant = ButtonVariant.LIGHT,
}: WhatsAppButtonProps) => {
    const phoneNumber = property.phoneNumber || {};

    if (!brandingSettings.enableDesktopWhatsappButton) {
        return null;
    }
    // @ts-expect-error - TS2339 - Property 'whatsapp' does not exist on type '{}'.
    if (!phoneNumber.whatsapp) {
        return null;
    }

    return (
        <BaseWhatsappButton
            className={classNames(className, styles.whatsAppButton, {
                [styles.disabled]: disabled,
                [styles.light]: !disabled && variant === ButtonVariant.LIGHT,
            })}
            index={index}
            property={property}
            onClick={(event: React.SyntheticEvent<any>) => {
                stopEventPropagation(event);
            }}
            viewSection={viewSection}
            variant={variant}
        />
    );
};

export default withGTMLeadTracking(WhatsAppButton);
