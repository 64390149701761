import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectLocations } from 'strat/search/selectors';

import Chip from './chip';
import type { Props as ChipProps } from './chip';
import styles from './styles/chipList.cssm';

export type ChipData<T> = {
    value: T;
    name: string | number;
};

type Props<T> = {
    selectedChips: T[];
    chipsData: ChipData<T>[];
    onChipClick: (value: T) => void;
    selectedChipIcon?: React.ReactNode;
    ariaLabel?: string;
    checkIfChipIsSelected?: (chipValue: T, selectedChips: T[]) => boolean;
    containerClassName?: string;
    chipClassName?: string;
    selectedChipClassName?: string;
    renderSingleChip?: (props: ChipProps<T>) => React.ReactNode;
    filter?: any;
    disabledChipClassName?: string;
};

const defaultCheckIfChipIsSelected = <T,>(chipValue: T, selectedChips: T[]) => {
    return selectedChips.includes(chipValue);
};

export const defaultRenderSingleChip = <T,>(chipProps: ChipProps<T>) => Chip(chipProps);

const ChipList = <T,>({
    chipsData,
    ariaLabel,
    selectedChipIcon,
    onChipClick,
    selectedChips,
    checkIfChipIsSelected = defaultCheckIfChipIsSelected,
    containerClassName = styles.container,
    chipClassName = styles.chip,
    selectedChipClassName = styles.selected,
    renderSingleChip = defaultRenderSingleChip,
    filter,
    disabledChipClassName = styles.disabled,
}: Props<T>) => {
    const locations = useSelector(selectLocations);

    const renderChip = (choice: ChipData<T>) => {
        const isSelected = checkIfChipIsSelected(choice.value, selectedChips);
        const disabled = filter?.isDisabled?.(choice.value, locations);

        return renderSingleChip({
            chipData: choice,
            isSelected,
            onChipClick,
            chipClassName,
            selectedChipClassName,
            selectedChipIcon,
            disabledChipClassName,
            isDisabled: disabled,
        });
    };

    return (
        <ul className={containerClassName} aria-label={ariaLabel}>
            {chipsData.map(renderChip)}
        </ul>
    );
};

export default ChipList;
