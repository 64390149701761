import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import SuspiciousAvatarWEBP from '@app/assets/images/suspiciousAvatar.webp';
import SuspiciousAvatarPNG from '@app/assets/images/suspiciousAvatar.png';

import styles from './styles/fraudulentMessage.cssm';

type Props = {
    readonly isSuspicious?: boolean;
};

const FraudulentMessage = ({ isSuspicious }: Props) => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    const title = isSuspicious
        ? t(i18n)`Suspicious user detected!`
        : t(i18n)`We have been too strict`;

    const description = isSuspicious
        ? t(
              i18n,
          )`This person has been removed from ${brandName}. Please don't communicate with the user or exchange any money or items. Contact us for more support.`
        : t(
              i18n,
          )`We have incorrectly removed a user you were chatting to. Sorry about this, please continue to chat. Contact us if you have any questions.`;

    return (
        <Flex alignCenter className={styles.container}>
            <picture>
                <source srcSet={SuspiciousAvatarWEBP} type="image/webp" />
                <source srcSet={SuspiciousAvatarPNG} type="image/png" />
                <img
                    className={styles.suspiciousAvatar}
                    src={SuspiciousAvatarPNG}
                    alt={t(i18n)`Suspicious user`}
                />
            </picture>
            <Flex column>
                <Text.Large bold>{title}</Text.Large>
                <Text.Regular>{description}</Text.Regular>
            </Flex>
        </Flex>
    );
};

export default FraudulentMessage;
