import type { Store } from 'redux';
import settings from '@app/branding/settings';

import { parseLocation, serializeLocation } from 'react-true-router/location';
import type { URLProcessor } from 'react-true-router/urlProcessor';

import setLanguage from './creators';

type URLProcessResult = {
    newUrl: string;
    language: string;
};

const processLanguageInUrl = (url: string): URLProcessResult => {
    const urlObj = parseLocation(url);
    const languages = settings.languages.map(({ lang }) => lang);
    const result = {
        newUrl: url,
        language: CONFIG.build.LANGUAGE_CODE,
    } as const;
    languages.forEach((lang) => {
        // skip the first language, which is primary
        const langComponent = `/${lang}`;
        if (urlObj.pathname === langComponent) {
            urlObj.pathname = '/';
            // @ts-expect-error - TS2540 - Cannot assign to 'language' because it is a read-only property.
            result.language = lang;
            // @ts-expect-error - TS2540 - Cannot assign to 'newUrl' because it is a read-only property.
            result.newUrl = serializeLocation(urlObj);
        } else if (urlObj.pathname.startsWith(`${langComponent}/`)) {
            urlObj.pathname = urlObj.pathname.slice(langComponent.length);
            // @ts-expect-error - TS2540 - Cannot assign to 'language' because it is a read-only property.
            result.language = lang;
            // @ts-expect-error - TS2540 - Cannot assign to 'newUrl' because it is a read-only property.
            result.newUrl = serializeLocation(urlObj);
        }
    });
    return result;
};

/**
 * Creates a new routing url processor that syncs the active language, as
 * specified from the language path component (e.g. /en/)
 * with the Redux store
 * @param store The Redux store to use.
 * @returns A new routing middleware.
 */
// @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
export default (store: Store): URLProcessor => ({
    inbound: (url: string): string => {
        const { newUrl, language } = processLanguageInUrl(url);
        if (store.getState().i18n.language !== language) {
            store.dispatch(setLanguage(language));
        }
        return newUrl;
    },
    outbound: (url: string): string => {
        const { language } = store.getState().i18n;
        if (language && language !== CONFIG.build.LANGUAGE_CODE) {
            const location = parseLocation(url);
            location.pathname = `/${language}${location.pathname}`;
            return serializeLocation(location);
        }
        return url;
    },
});

export { processLanguageInUrl };
