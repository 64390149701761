import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLanguage } from 'strat/i18n/language';
import { selectDriftCorrectedClock } from 'strat/clock/selectors';

import {
    type KeycloakSiteConfig,
    type KeycloakSiteConfigWithCallbacks,
    createKeycloakSiteConfig,
    createKeycloakSiteConfigWithCallbacks,
} from '../keycloakSiteConfig';

export const useKeycloakSiteConfig = (): KeycloakSiteConfig => {
    const languageCode = useLanguage();
    const clock = useSelector(selectDriftCorrectedClock);
    return React.useMemo(
        () => createKeycloakSiteConfig({ languageCode, clock }),
        [languageCode, clock],
    );
};

export const useKeycloakSiteConfigWithCallbacks = (): KeycloakSiteConfigWithCallbacks => {
    const dispatch = useDispatch();
    const languageCode = useLanguage();
    const clock = useSelector(selectDriftCorrectedClock);

    return React.useMemo(
        () => createKeycloakSiteConfigWithCallbacks({ languageCode, clock }, dispatch),
        [dispatch, languageCode, clock],
    );
};
