import { Store } from 'redux';
import {
    SearchActions,
    setIndex,
    getFilterCollection,
    search,
    setContent,
} from '@sector-labs/fe-search-redux/state';
import type {
    SearchAction,
    StoreResultsAction,
} from '@sector-labs/fe-search-redux/state/searchStateTypes';
import type { Action } from 'redux';
import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import {
    ExactFilter,
    FilterCollection,
    ExclusionFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';

import { selectSortValue } from 'strat/search/state/selectors';
import type { AppDispatch, GlobalState } from 'strat/state';
import { FilterValues } from 'strat/search';
import { PropertyCompletionStatus, PropertyType } from 'strat/property/types';
import Purpose from 'strat/purpose';
import { SortByValues } from 'strat/search/sortValues';
import { clearProjects, setChildProjectCount } from 'strat/project/state';
import type { ProjectData } from 'strat/project/types';
import { selectProjects, selectChildProjectCount } from 'strat/project/selectors';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { clearMapData } from 'strat/search/state/mapBasedSearch';
import {
    selectIsMapBasedSearchActive,
    selectIsProjectMapSearchEnabled,
} from 'strat/search/selectors';
import { determineAdsIndexName } from 'strat/search/indexNames';

import extractProjectPageLocationSlugs from './extractProjectPageLocationSlugs';
import {
    isProjectChildCountFetchingRequired,
    isProjectPageFetchingRequired,
} from './isProjectFetchingRequired';
import detectProjectSearchRedirectRequired from './detectProjectSearchRedirectRequired';
import fetchProjectPageProjects from './fetchProjectPageProjects';
import fetchChildProjectCount from './fetchChildProjectCount';
import { ProjectAdHit, ProjectHit } from './types';
import { getProjectsJob } from './getProjectsJob';
import { hasProjectAdsFiltersActive } from './hasProjectAdsFiltersActive';

type NextSearchActionData = {
    nextFilters: FilterCollection;
    nextIndexName: string;
};

const SEARCH_PROJECT_IDS_JOBS_EXCLUDED_FILTERS = [
    FilterValues.saleType.attribute,
    FilterValues.type.attribute,
    FilterValues.page.attribute,
    FilterValues.rentFrequency.attribute,
    FilterValues.agency.attribute,
    FilterValues.agentOwner.attribute,
    FilterValues.keywords.attribute,
];

const switchToProjectsView = (
    filters: FilterCollection,
    state: GlobalState,
    wasProjectSearchActive: boolean,
): NextSearchActionData => {
    const nextFilters = filters.copy();
    nextFilters.refine(
        new ExactFilter({
            attribute: FilterValues.type.attribute,
            value: PropertyType.PROJECT,
            active: false,
        }),
    );

    if (!wasProjectSearchActive) {
        nextFilters.remove(FilterValues.page.attribute);
        nextFilters.remove(FilterValues.rentFrequency.attribute);
        nextFilters.remove(FilterValues.agency.attribute);
        nextFilters.remove(FilterValues.agentOwner.attribute);
        nextFilters.remove(FilterValues.keywords.attribute);
    }

    const language = selectLanguage(state);
    const nextIndexName = determineAdsIndexName({
        language,
        sortBy: null,
        adType: PropertyType.PROJECT,
    });

    return { nextFilters, nextIndexName };
};

const switchToPropertiesView = (
    filters: FilterCollection,
    state: GlobalState,
): NextSearchActionData => {
    const nextFilters = filters.copy();
    nextFilters.remove(FilterValues.type.attribute);
    nextFilters.remove(FilterValues.page.attribute);

    const language = selectLanguage(state);
    const sortValue = selectSortValue(state);

    const nextIndexName = determineAdsIndexName({
        language,
        sortBy: sortValue || SortByValues.DEFAULT,
    });

    return { nextFilters, nextIndexName };
};

const determineWasProjectSearchActive = (filters: FilterCollection): boolean =>
    filters.getFilterValue(FilterValues.type.attribute) === PropertyType.PROJECT;

export const determineIsProjectSearchActive = (filters: FilterCollection): boolean =>
    filters.getFilterValue(FilterValues.type.attribute) === PropertyType.PROJECT &&
    filters.getFilterValue(FilterValues.purpose.attribute) === Purpose.FOR_SALE &&
    filters.getFilterValue(FilterValues.completionStatus.attribute) ===
        PropertyCompletionStatus.OFF_PLAN;

/**
 * Runs each time an ad search is executed and the results are known.
 *
 * We fetch projects based on the location filter
 */
export const projectPageMiddleware = (
    filters: FilterCollection | null,
    dispatch: AppDispatch,
    getState: () => GlobalState,
): FilterCollection => {
    if (!filters) {
        return new FilterCollection();
    }

    if (process.env.IS_SERVER) {
        return filters;
    }

    const state = getState();

    if (isProjectPageFetchingRequired(filters)) {
        const locationSlugs = extractProjectPageLocationSlugs(filters);
        dispatch(fetchProjectPageProjects(locationSlugs));
    } else {
        const projects = selectProjects(state);
        if (projects?.length) {
            dispatch(clearProjects());
        }
    }

    if (isProjectChildCountFetchingRequired(filters)) {
        dispatch(fetchChildProjectCount(filters));
    } else {
        const projectChildCount = selectChildProjectCount(state);
        if (projectChildCount) {
            dispatch(setChildProjectCount(0));
        }
    }

    return filters;
};

const noContentResult = (indexName: string) => ({
    hits: [],
    nbHits: 0,
    nbPages: 0,
    hitsPerPage: 0,
    exhaustiveNbHits: true,
    processingTimeMS: 0,
    index: indexName,
});

const fetchProjects = (
    newFilters: FilterCollection,
    nextFilters: FilterCollection,
    nextIndexName: string,
    state: GlobalState,
) => {
    const adsFilters = newFilters.copy(SEARCH_PROJECT_IDS_JOBS_EXCLUDED_FILTERS);
    adsFilters.refine(new ExclusionFilter({ attribute: 'project', value: null }));

    const sortValue = selectSortValue(state);
    const adIndex = determineAdsIndexName({
        sortBy: sortValue,
        language: state.i18n.language,
        adType: PropertyType.PROPERTY,
    });
    const algoliaSettings = state.algolia.settings;
    const backend = selectActiveSearchBackend(state);
    // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
    const service = new SearchService({ backend });
    const searchProjectIdsJobsSettings = {
        ...algoliaSettings,
        distinct: true,
        attributesToRetrieve: ['project', 'externalID'],
        hitsPerPage: 10000,
    };
    const searchJobs = [new SearchJob(adIndex, adsFilters, searchProjectIdsJobsSettings)];

    // If no active filters except location, show all projects that don't have any listings
    if (!hasProjectAdsFiltersActive(newFilters)) {
        const projectFilters = newFilters.copy(SEARCH_PROJECT_IDS_JOBS_EXCLUDED_FILTERS);
        projectFilters.refine(
            new ExclusionFilter({
                attribute: 'hasPropertyAds',
                value: true,
            }),
        );
        searchJobs.push(
            new SearchJob(nextIndexName, projectFilters, {
                ...searchProjectIdsJobsSettings,
                attributesToRetrieve: ['externalID'],
            }),
        );
    }

    return service.fetchJobs(searchJobs).then((responses) => {
        const projectAdData = responses[0]?.hits as ProjectAdHit[];

        const projectIds = (projectAdData || [])
            .map((hit: ProjectAdHit) => hit.project?.externalID)
            .filter((externalID): externalID is string => Boolean(externalID));

        if (responses.length > 1) {
            const projectsWithoutAdsIds = ((responses[1]?.hits as ProjectHit[]) || []).map(
                (hit: ProjectHit) => hit.externalID as string,
            );
            projectIds.push(...projectsWithoutAdsIds);
        }

        if (projectIds.length === 0) {
            return noContentResult(nextIndexName);
        }

        const projectsJob = getProjectsJob(projectIds, nextFilters, nextIndexName, algoliaSettings);
        return service.fetchJob(projectsJob);
    });
};

/**
 * Off-plan project specific middleware that:
 * - switches the algolia index when the type of search changes
 * - removes the listing type search filter when the purpose changes to `for-rent`
 *
 * When the filter collection has a filter of `type=project`, we should search in the
 * `projects` index, and in any other cases, we should search in the `ads` index.
 *
 * When the purpose changes to `for-rent`, the listing type purpose no longer makes
 * sense, as off-plan properties are never available for-rent, so we just switch to
 * regular search and pop the type filter.
 */
export const projectSearchMiddleware =
    ({ dispatch, getState }: Store<GlobalState>) =>
    (next: (action: Action) => void) =>
    (action: Action) => {
        const searchAction = action as SearchAction;
        if (searchAction.type !== SearchActions.SEARCH) {
            return next(action);
        }

        const state = getState();

        // WARNING: Do not change to `getFilterCollection` or some
        // other kind of memoizing selector.
        //
        // There are bugs lurking in the code that mutate the
        // memoized `FilterCollection` which breaks the
        // old vs new comparison here.
        const oldFilters = new FilterCollection(state.algolia.filters);
        const newFilters = searchAction.filters;
        const wasProjectSearchActive = determineWasProjectSearchActive(oldFilters);
        const isProjectSearchActive = determineIsProjectSearchActive(newFilters);

        if (!wasProjectSearchActive && !isProjectSearchActive) {
            return next(searchAction);
        }

        const listingTypeChanged = wasProjectSearchActive !== isProjectSearchActive;
        const isMapBasedSearchActive = selectIsMapBasedSearchActive(state);
        const isProjectMapSearchEnabled = selectIsProjectMapSearchEnabled(state);

        // Clear map data when listing type changed, to avoid unpacking the listings
        // collection of the old type while the new collection is fetching.
        // This is because format is different between projects and property collections.
        if (listingTypeChanged && isProjectMapSearchEnabled && isMapBasedSearchActive) {
            dispatch(clearMapData());
        }

        if (isProjectSearchActive) {
            const { nextFilters, nextIndexName } = switchToProjectsView(
                newFilters,
                state,
                wasProjectSearchActive,
            );

            if (!wasProjectSearchActive) {
                dispatch(setIndex(nextIndexName));
            }

            return fetchProjects(newFilters, nextFilters, nextIndexName, state).then((content) => {
                dispatch(setContent(content));
                return next({
                    ...searchAction,
                    filters: nextFilters,
                    indexName: nextIndexName,
                } as SearchAction);
            });
        }

        const { nextFilters, nextIndexName } = switchToPropertiesView(newFilters, state);
        dispatch(setIndex(nextIndexName));

        return next({
            ...searchAction,
            filters: nextFilters,
            indexName: nextIndexName,
        } as SearchAction);
    };

/**
 * Runs after the search results have been fetched but BEFORE they
 * are being stored in the store.
 *
 * This detects if the user searched in a location that yields
 * only project(s) that match the location exactly. In that
 * case staying in the projects view doesn't make sense since
 * all they would see is 1 result with the exact project they
 * searched for.
 *
 * We switch the user to the properties view which will display
 * the project page with the project details.
 */
export const projectSearchResultsMiddleware =
    ({ dispatch, getState }: Store<GlobalState>) =>
    (next: (action: Action) => void) =>
    (action: Action): void => {
        const storeResultsAction = action as StoreResultsAction;
        if (storeResultsAction.type !== SearchActions.STORE_RESULTS) {
            return next(action);
        }

        // Content == null means a reset is being performed. We
        // don't have to check for a redirect.
        if (storeResultsAction.content === null) {
            return next(action);
        }

        const state = getState();
        const filters = getFilterCollection(state);

        const isProjectSearchActive = determineIsProjectSearchActive(filters);
        if (!isProjectSearchActive) {
            return next(action);
        }

        const hits = (storeResultsAction.content as SearchResponse<ProjectData>)?.hits;

        const appDispatch = dispatch as AppDispatch;

        appDispatch(detectProjectSearchRedirectRequired(filters, hits)).then((shouldRedirect) => {
            if (!shouldRedirect) {
                next(action);
                return;
            }

            const { nextFilters, nextIndexName } = switchToPropertiesView(filters, state);
            appDispatch(setIndex(nextIndexName));
            appDispatch(search(nextFilters, nextIndexName));

            // Note: No next(...) call here. We abort our current search
            // attempt and have restarted it in the properties view.
        });

        return undefined;
    };
