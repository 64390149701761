import * as React from 'react';
import { GTMLeadTrackingProps, withGTMLeadTracking } from 'strat/gtm';

import { useAutoSendReadReceipt, useChatSDK } from '../hooks';
import { useActionButtons } from '../negotiation';
import type { ChatRoom, ChatRoomDisabledInfo } from '../types';

import ChatAssistant from './chatAssistant';
import { ReadonlyConversationContent } from './readonlyConversationContent';

type OwnProps = {
    readonly chatRoom: ChatRoom;
    readonly disabledInfo: ChatRoomDisabledInfo;
};

type Props = GTMLeadTrackingProps & OwnProps;

const ConversationContent = ({ chatRoom, disabledInfo, trackChatLead }: Props) => {
    const sdk = useChatSDK();
    const unreadCount = sdk.useRoomUnreadCount(chatRoom?.xid);
    const [priceFormVisible, setPriceFormVisible] = React.useState(false);

    const actionButtons = useActionButtons(chatRoom, setPriceFormVisible, trackChatLead);

    useAutoSendReadReceipt({ roomXID: chatRoom?.xid, unreadCount });

    return (
        <>
            <ReadonlyConversationContent
                disabledInfo={disabledInfo}
                chatRoom={chatRoom}
                actionButtons={actionButtons}
            />
            {!disabledInfo?.disabled ? (
                <ChatAssistant
                    conversation={chatRoom}
                    actionButtons={actionButtons}
                    priceFormVisible={priceFormVisible}
                    setPriceFormVisible={setPriceFormVisible}
                />
            ) : null}
        </>
    );
};

export default withGTMLeadTracking(React.memo<Props>(ConversationContent));
