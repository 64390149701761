export const UserSettingsKey = Object.freeze({
    SHOW_PHONE_NUMBER_IN_ADS: 'showPhoneNumberInAds',
    ALLOW_CHAT_COMMUNICATION_IN_ADS: 'allowChatCommunicationInAds',
    CHAT_SAFETY_TIPS_ACTIVE: 'chatSafetyTipsActive',
    OFFERS_NOTIFICATIONS_ACTIVE: 'offersNotificationsActive',
    RECOMMENDATIONS_NOTIFICATIONS_ACTIVE: 'recommendationsNotificationsActive',
    ALLOW_CALL_FOR_PRICE_ADS: 'allowCallForPriceAds',
});

export type UserSettings = {
    showPhoneNumberInAds: boolean;
    allowChatCommunicationInAds: boolean;
    chatSafetyTipsActive: boolean;
    offersNotificationsActive: boolean;
    recommendationsNotificationsActive: boolean;
    allowCallForPriceAds: boolean;
};

export const UserSettingsOperation = Object.freeze({
    FETCH: 'fetch',
    SAVE: 'save',
});
export type UserSettingsOperationParams =
    | {
          operation: 'fetch';
          userExternalID?: string;
      }
    | {
          operation: 'save';
          updatedFields: keyof UserSettings;
      };

export const UserSettingsSection = Object.freeze({
    PRIVACY: 'privacy',
    NOTIFICATIONS: 'notifications',
    CHAT: 'chat',
});

export const UserSettingsPrivacySubsection = Object.freeze({
    PASSWORD: 'password',
});

export const UserSettingsSubsection = Object.freeze({ ...UserSettingsPrivacySubsection });
