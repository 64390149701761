import { Dispatch } from 'react';

import { fetchAd } from 'horizontal/ad/state';

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const fetchAdDataByExternalID = (externalID: string, dispatch: Dispatch): Promise<void> => {
    return dispatch(fetchAd({ externalID }));
};

export default fetchAdDataByExternalID;
