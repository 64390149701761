import * as React from 'react';
import type { ChoiceValue } from 'strat/components/types';

import type { FacetedChoice } from './types';
import sortFacetedChoices from './sortFacetedChoices';

const useSortedFacetedChoices = <T extends ChoiceValue>(
    choices: Array<FacetedChoice<T>>,
    values?: T | Array<T>,
): Array<FacetedChoice<T>> =>
    // eslint-disable-next-line
    React.useMemo(() => sortFacetedChoices<T>(choices, values || []), [choices, values]);

export default useSortedFacetedChoices;
