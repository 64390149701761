import * as React from 'react';
import { useSelector } from 'react-redux';
import { isCanceled } from 'strat/util';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';

import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';
import type { Ad, FreeAdLimitUsage } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

import {
    selectCanPostAdForFree,
    selectDurationTillNextFreePostByAd,
    selectFreeLimitUsageByAd,
} from './selectors';

const useFreeLimitUsageByAd = (ad?: Ad | null): FreeAdLimitUsage | null | undefined =>
    useSelector(
        React.useCallback((state: GlobalState) => selectFreeLimitUsageByAd(state, { ad }), [ad]),
    );

const useDurationTillNextFreePost = (ad?: Ad | null): string | null | undefined =>
    useSelector(
        React.useCallback(
            (state: GlobalState) => selectDurationTillNextFreePostByAd(state, { ad }),
            [ad],
        ),
    );

const useCanPostAdForFree = (ad?: Ad | null): boolean | null | undefined =>
    useSelector(
        React.useCallback((state: GlobalState) => selectCanPostAdForFree(state, { ad }), [ad]),
    );

const useActivateAd = () => {
    const [loading, setLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const language = useSelector(selectLanguage);
    const makeCancelable = useMakeCancelable();

    const activateAd = React.useCallback(
        ({
            adExternalID,
            productPurchaseID,
            onSuccess,
        }: {
            adExternalID: string;
            productPurchaseID?: string | null | undefined;
            onSuccess: () => void;
        }) => {
            if (loading) {
                return;
            }

            setLoading(true);
            setIsError(false);

            makeCancelable(
                new StratAPI(language).activateAd({
                    adExternalID,
                    productPurchaseID,
                }),
            )
                // @ts-expect-error - TS7031 - Binding element 'status' implicitly has an 'any' type.
                .then(({ status, data }) => {
                    if (status >= 300) {
                        throw new ContextfulError(`Unexpect status while requesting limits`, {
                            status,
                            data,
                        });
                    }

                    onSuccess();
                    setLoading(false);
                })
                .catch((e: unknown) => {
                    logError({
                        e,
                        msg: 'Unable to fetch ad limits',
                        context: {
                            adExternalID,
                        },
                    });

                    if (isCanceled(e)) {
                        // Don't change any state because we're unmounting.
                        return;
                    }

                    setIsError(true);
                    setLoading(false);
                });
        },
        [makeCancelable, language, loading],
    );

    return { loading, isError, activateAd };
};

export { useActivateAd, useCanPostAdForFree, useDurationTillNextFreePost, useFreeLimitUsageByAd };
