import * as React from 'react';
import { Formik } from 'formik';
import { Trans } from '@lingui/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { selectActiveUser } from 'strat/user/session';
import { Flex, Text } from 'strat/components';
import ViewSections from 'strat/gtm/viewSections';

import { DeliveryType } from 'horizontal/types';
import { useAd } from 'horizontal/ad/state';
import { trackPlaceOrder } from 'horizontal/gtm';
import type { AddressInfo } from 'horizontal/userOrders/types';
import {
    selectDeliveryAddresses,
    selectDefaultDeliveryAddress,
} from 'horizontal/userOrders/addresses/selectors';
import { fetchAddressBook } from 'horizontal/userOrders/addresses/state';
import type { AppDispatch, GlobalState } from 'horizontal/state';

import RequestDeliveryForm from './requestDeliveryForm';
import { validationSchema } from './requestDeliveryValidationSchema';
import type { RequestDeliveryData } from './types';
import usePostDeliveryRequest from './usePostDeliveryRequest';
import AdPreview from './adCardPreview';
import { ReviewRequestDelivery } from './reviewRequestDelivery';
import RequestDeliverySuccess from './requestDeliverySuccess';
import styles from './styles/requestDelivery.cssm';

const FlowStep = Object.freeze({
    FORM: 'form_step',
    REVIEW: 'review_step',
    SUCCESS: 'success_step',
});

const renderForm =
    (
        defaultAddress: AddressInfo,
        addressBook: AddressInfo[],
        onNewAddressAdded: () => void,
        deliveryType: DeliveryType,
    ) =>
    (props: React.ComponentProps<typeof RequestDeliveryForm>) => (
        <RequestDeliveryForm
            {...props}
            defaultAddress={defaultAddress}
            addressBook={addressBook}
            onNewAddressAdded={onNewAddressAdded}
            deliveryType={deliveryType}
        />
    );

type FormProps = {
    readonly handleNext: (arg1: RequestDeliveryData, arg2: AddressInfo) => void;
    readonly values: RequestDeliveryData | null | undefined;
    readonly selectedAddress: AddressInfo | null | undefined;
    readonly deliveryType?: DeliveryType;
};

const RequestForm = ({
    handleNext,
    values,
    selectedAddress,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: FormProps) => {
    const formRef = React.useRef();
    const i18n = useI18n();
    const deliveryAddresses = useSelector((state: GlobalState) =>
        selectDeliveryAddresses(state, deliveryType),
    );
    const defaultDeliveryAddress = useSelector(selectDefaultDeliveryAddress);
    // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Boolean'. | TS2339 - Property 'phoneNumber' does not exist on type 'Boolean'. | TS2339 - Property 'externalID' does not exist on type 'Boolean'.
    const { name, phoneNumber, externalID: userExternalID } = useSelector(selectActiveUser);
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const loadAddresses = React.useCallback(() => {
        dispatch(fetchAddressBook({ userExternalID }));
    }, [dispatch, userExternalID]);

    const initialValues = React.useMemo(() => {
        return {
            name: name || '',
            phone_number: phoneNumber || '',
            alternative_phone: '',
            notes: '',
            delivery_address: defaultDeliveryAddress?.id || '',
        };
    }, [name, phoneNumber, defaultDeliveryAddress]);

    const onNext = React.useCallback(
        (request: RequestDeliveryData) => {
            const requestAddress = deliveryAddresses.find(
                (address) => address.id === request.delivery_address,
            );
            if (requestAddress) {
                handleNext(request, requestAddress);
            }
        },
        [handleNext, deliveryAddresses],
    );

    return (
        <div className={styles.form}>
            <Formik
                initialValues={values ?? initialValues}
                innerRef={formRef}
                validationSchema={() => validationSchema(i18n)}
                // @ts-expect-error - TS2322 - Type '(request: RequestDeliveryData) => void' is not assignable to type '(values: object, formikActions: FormikActions<object>) => void'.
                onSubmit={onNext}
            >
                {renderForm(
                    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'AddressInfo'.
                    selectedAddress ?? defaultDeliveryAddress,
                    deliveryAddresses,
                    loadAddresses,
                    deliveryType,
                )}
            </Formik>
        </div>
    );
};

const RequestDelivery = () => {
    const ad = useAd();
    const [step, setStep] = React.useState<Values<typeof FlowStep>>(FlowStep.FORM);
    const [selectedAddress, setSelectedAddress] = React.useState<AddressInfo | null | undefined>(
        null,
    );
    const { onPostRequestDelivery, loading: submitting } = usePostDeliveryRequest();
    const [request, setRequest] = React.useState<RequestDeliveryData | null | undefined>(null);

    const onNext = React.useCallback(
        (values: RequestDeliveryData, address: AddressInfo) => {
            setSelectedAddress(address);

            const updatedValues = {
                ...values,
                delivery_type:
                    ad?.deliveryInformation?.deliveryType === DeliveryType.SELF_DELIVERY
                        ? DeliveryType.SELF_DELIVERY
                        : DeliveryType.SERVICE_DELIVERY,
            };

            setRequest(updatedValues);
            setStep(FlowStep.REVIEW);
        },
        [setStep, setRequest, setSelectedAddress, ad],
    );

    const onEdit = React.useCallback(() => {
        if (submitting) {
            return;
        }
        setStep(FlowStep.FORM);
    }, [setStep, submitting]);

    const onOrderPlaced = React.useCallback(() => {
        setStep(FlowStep.SUCCESS);
    }, [setStep]);

    const onPlaceOrder = React.useCallback(() => {
        if (request && ad && !submitting) {
            trackPlaceOrder(ad, ViewSections.BODY);
            onPostRequestDelivery(request, ad.externalID, onOrderPlaced);
        }
    }, [onPostRequestDelivery, onOrderPlaced, submitting, request, ad]);

    return (
        <Flex fillContainer column className={styles.requestDeliveryContainer}>
            <Flex stretchWidth className={styles.title}>
                <Text.XLarge bold>
                    <Trans>Buy with delivery</Trans>
                </Text.XLarge>
            </Flex>
            <Flex fillContainer className={styles.requestDeliveryContainer}>
                {step === FlowStep.FORM && ad && (
                    <>
                        <RequestForm
                            handleNext={onNext}
                            values={request}
                            selectedAddress={selectedAddress}
                            deliveryType={ad?.deliveryInformation?.deliveryType}
                        />
                        <AdPreview
                            ad={ad}
                            showAdPrice={false}
                            deliveryType={ad?.deliveryInformation?.deliveryType}
                        />
                    </>
                )}
                {step === FlowStep.REVIEW && request && selectedAddress && ad && (
                    <>
                        <ReviewRequestDelivery
                            deliveryInfo={request}
                            addressInfo={selectedAddress}
                            onEdit={onEdit}
                            onSubmit={onPlaceOrder}
                        />
                        <AdPreview
                            ad={ad}
                            showAdPrice
                            deliveryType={ad?.deliveryInformation?.deliveryType}
                        />
                    </>
                )}
                {step === FlowStep.SUCCESS && <RequestDeliverySuccess />}
            </Flex>
        </Flex>
    );
};

export default RequestDelivery;
