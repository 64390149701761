import * as React from 'react';
import isNil from 'lodash/isNil';
import { prefixTargetingParams } from 'strat/tagmanager';
import settings from '@app/branding/settings';

import { AdProduct } from 'horizontal/types';
import type { MixedAd } from 'horizontal/types';
import { AdFormat } from 'horizontal/types';

import useAdExtraFieldsParams from './useAdExtraFieldsParams';
import { categoryParams, currencyMapping, locationParams } from './utils';
import { Position } from './types';
import { getActiveRangesPrice } from './valueRanges';
import useCommonTargetingParams from './useCommonTargetingParams';

const useAdDetailTargetingParams = (
    position: Values<typeof Position>,
    slotIndex: number | null | undefined,
    ad: MixedAd,
) => {
    const { product, userExternalID, title, category, extraFields, location } = ad;
    // We hardcode english because we want the locations in english for targeting
    const translatedLocations =
        ad.format === AdFormat.FULL &&
        // @ts-expect-error - TS2339 - Property 'locationTranslations' does not exist on type 'MixedAd'.
        ad.locationTranslations &&
        // @ts-expect-error - TS2339 - Property 'locationTranslations' does not exist on type 'MixedAd'.
        ad.locationTranslations.map(
            // @ts-expect-error - TS7006 - Parameter 'locationTranslation' implicitly has an 'any' type.
            (locationTranslation) =>
                locationTranslation.en || locationTranslation[CONFIG.build.LANGUAGE_CODE],
        );
    const commonParams = useCommonTargetingParams();
    const extraFieldsParams = useAdExtraFieldsParams(category, extraFields);
    const price = extraFields?.price;
    const currency = currencyMapping(settings.baseCurrencyName);

    return React.useMemo(
        () => ({
            page_type: 'details',
            pos: position,
            slot_index: isNil(slotIndex) || slotIndex < 0 ? null : `${slotIndex}`,
            is_featured: product === AdProduct.FEATURED,
            ...prefixTargetingParams({ seller_id: userExternalID }),
            listing_title: title,
            price: price || 0,
            [`price_band_${currency}`]: price
                ? getActiveRangesPrice({ min: price, max: price })
                : [],
            ...prefixTargetingParams(locationParams('en', translatedLocations || location)),
            ...prefixTargetingParams(categoryParams(category, extraFieldsParams?.make)),
            ...extraFieldsParams,
            ...commonParams,
        }),
        [
            product,
            userExternalID,
            title,
            category,
            price,
            position,
            slotIndex,
            extraFieldsParams,
            currency,
            location,
            translatedLocations,
            commonParams,
        ],
    );
};

export default useAdDetailTargetingParams;
