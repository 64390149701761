import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

import styles from './styles/remarketingSectionDelimiter.cssm';

const RemarketingSectionDelimiter = () => {
    return (
        <Text.Regular className={styles.title}>
            <Trans>Check out similar results</Trans>
        </Text.Regular>
    );
};

export default RemarketingSectionDelimiter;
