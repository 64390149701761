import type { I18n } from '@lingui/core';
import {
    SearchBackend,
    type SearchResponse,
    SearchResponseHit,
} from '@sector-labs/fe-search-redux/backend';
import { FilterCollection, RangeFilterValue } from '@sector-labs/fe-search-redux/filters';

import { InternalAPI } from 'strat/api';
import { FilterValues } from 'strat/search';
import TruBrokerAPI from 'strat/server/trubrokerAPI';
import { AgentSortByValues } from 'strat/searchComponents';
import { TruBrokerAgentsParams } from 'strat/leaderboard/types';
import { ServerAuthContext } from 'strat/server/serverAuthContext';
import { LeaderboardRankingTypes } from 'strat/leaderboard/miniLeaderboard/types';
import { getCubeRankingField } from 'strat/agency/search/form/fetchDataFromProBoard';
import { mergeBIDataWithAlgoliaData } from '@app/agency/search/form/fetchDataFromProBoard';
import { getRankKeyByTab, getXPEarnedKeyByTab } from 'strat/leaderboard/miniLeaderboard/utils';

import { getAgentLocationFilterValues } from './filters';
import { getPurposeCategoryFilterValues } from './computeFilters';

const getAgentSearchPromise = (searchParams: TruBrokerAgentsParams) => {
    if (process.env.IS_BROWSER) {
        return new InternalAPI().searchAgents(searchParams);
    }

    const userExternalID = ServerAuthContext.userExternalID();
    return new TruBrokerAPI().searchProboardAgents(searchParams, userExternalID);
};

export const searchAgents = (
    locationsWithAds: Array<any>,
    sortBy: Values<typeof AgentSortByValues> | null,
    i18n: I18n,
    backend: SearchBackend | null,
    filters: FilterCollection,
    hitsPerPage: number,
    useLast3MonthData = false,
    mergeWithAlgoliaData = true,
): Promise<SearchResponse<SearchResponseHit>[] | null> => {
    const emirate: string = filters.getFilterValue('activeInEmirate', '');
    const page: number = filters.getFilterValue(FilterValues.page.attribute, 1);
    const agentExternalID: string | number | null = filters.getFilterValue(
        'agent_external_id',
        null,
    );
    const isTruBroker: string | null = filters.getFilterValue('isTruBroker', null);
    const userLanguages: Array<any> | null = filters.getFilterValue(
        FilterValues.languages.attribute,
        null,
    );

    const agentLocations = getAgentLocationFilterValues(filters);
    const locations = (locationsWithAds || agentLocations).filter(
        (location) => location.hierarchy?.length >= 2,
    );

    const [purposeValue, categoryValue] = getPurposeCategoryFilterValues(filters, i18n, false);

    const cubeRankingField = getCubeRankingField(filters, locationsWithAds);
    const cubeRankingFilterValue: RangeFilterValue = filters.getFilterValue(cubeRankingField, {
        min: 1,
        max: null,
    });

    const rankingType = useLast3MonthData
        ? LeaderboardRankingTypes.LAST_3_MONTHS
        : LeaderboardRankingTypes.ALL_TIME;

    const rankingKey = getRankKeyByTab(rankingType, filters);
    const xpField = getXPEarnedKeyByTab(rankingType, rankingKey);
    const xpRange: RangeFilterValue | null = filters.getFilterValue(xpField, null);

    const searchParams: TruBrokerAgentsParams = {
        page,
        sortBy,
        language: i18n.locale,
        hitsPerPage,
        isTruBroker,
        userLanguages,
        agentExternalID,
        category: categoryValue,
        purpose: purposeValue,
        locationsWithAds: locations,
        rankingField: cubeRankingField,
        rankingInterval: cubeRankingFilterValue,
        xpField,
        xpRange,
        activeInEmirate: emirate,
    };
    return getAgentSearchPromise(searchParams).then((response) => {
        if (mergeWithAlgoliaData && response?.data) {
            return mergeBIDataWithAlgoliaData(response?.data, i18n.locale, backend);
        }
        return [response?.data];
    });
};
