import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch } from 'strat/state';

import { UserRole } from 'horizontal/types';

import { selectUserRoles } from './selectors';
import { fetchUserRoles } from './state';

export const useUserRoles = () => useSelector(selectUserRoles);

export const useFetchUserRoles = () => {
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback(() => dispatch(fetchUserRoles), [dispatch]);
};

export const useIsUserWithDeliveryRole = () => {
    const fetchUserRoles = useFetchUserRoles();
    const userRoles = useUserRoles();

    React.useEffect(() => fetchUserRoles(), [fetchUserRoles]);

    return (
        CONFIG.runtime.STRAT_ENABLE_DELIVERY_SERVICE_FOR_VERIFIED_USERS_ONLY ||
        (userRoles && userRoles.includes(UserRole.ALLOWS_DELIVERY))
    );
};
