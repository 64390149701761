//@ts-nocheck
import Area from './area';
import Distance from './distance';

const sqftInOneSqm = 10.763910416709722;
const sqftInOneSqyd = 9;
const marlasInOneKanal = 20;
const sqftInOneMarla = 225;
const sqftInOneKanal = marlasInOneKanal * sqftInOneMarla;
const sqftInOneSqwa = 43.05;
const sqftInOneRai = 17222.256666736;
const sqftInOneNgan = 4305;
const sqftInOneKatha = 720;

const sqftTable = new Map([
    [Area.SQM, (value: any) => value * (1 / sqftInOneSqm)],
    [Area.SQYD, (value: any) => value * (1 / sqftInOneSqyd)],
    [Area.MARLA, (value: any) => value * (1 / sqftInOneMarla)],
    [Area.KANAL, (value: any) => value * (1 / sqftInOneKanal)],
    [Area.SQWA, (value: any) => value * (1 / sqftInOneSqwa)],
    [Area.RAI, (value: any) => value * (1 / sqftInOneRai)],
    [Area.NGAN, (value: any) => value * (1 / sqftInOneNgan)],
    [Area.KATHA, (value: any) => value * (1 / sqftInOneKatha)],
]);

const sqmTable = new Map([
    [Area.SQFT, (value: any) => value * sqftInOneSqm],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneSqm)],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneSqm)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneSqm)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneSqm)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneSqm)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneSqm)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneSqm)],
]);

const sqydTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneSqyd)],
    [Area.SQFT, (value: any) => value * sqftInOneSqyd],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneSqyd)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneSqyd)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneSqyd)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneSqyd)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneSqyd)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneSqyd)],
]);

const marlaTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneMarla)],
    [Area.SQFT, (value: any) => value * sqftInOneMarla],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneMarla)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneMarla)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneMarla)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneMarla)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneMarla)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneMarla)],
]);

const kanalTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneKanal)],
    [Area.SQFT, (value: any) => value * sqftInOneKanal],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneKanal)],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneKanal)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneKanal)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneKanal)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneKanal)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneKanal)],
]);

const sqwaTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneSqwa)],
    [Area.SQFT, (value: any) => value * sqftInOneSqwa],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneSqwa)],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneSqwa)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneSqwa)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneSqwa)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneSqwa)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneSqwa)],
]);

const raiTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneRai)],
    [Area.SQFT, (value: any) => value * sqftInOneRai],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneRai)],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneRai)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneRai)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneRai)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneRai)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneRai)],
]);

const nganTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneNgan)],
    [Area.SQFT, (value: any) => value * sqftInOneNgan],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneNgan)],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneNgan)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneNgan)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneNgan)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneNgan)],
    [Area.KATHA, (value: any) => sqftTable.get(Area.KATHA)(value * sqftInOneNgan)],
]);

const kathaTable = new Map([
    [Area.SQM, (value: any) => sqftTable.get(Area.SQM)(value * sqftInOneKatha)],
    [Area.SQFT, (value: any) => value * sqftInOneKatha],
    [Area.SQYD, (value: any) => sqftTable.get(Area.SQYD)(value * sqftInOneKatha)],
    [Area.MARLA, (value: any) => sqftTable.get(Area.MARLA)(value * sqftInOneKatha)],
    [Area.KANAL, (value: any) => sqftTable.get(Area.KANAL)(value * sqftInOneKatha)],
    [Area.SQWA, (value: any) => sqftTable.get(Area.SQWA)(value * sqftInOneKatha)],
    [Area.RAI, (value: any) => sqftTable.get(Area.RAI)(value * sqftInOneKatha)],
    [Area.NGAN, (value: any) => sqftTable.get(Area.NGAN)(value * sqftInOneKatha)],
]);

const feetTable = new Map([
    [Distance.M, (value: number) => value / 3.28084],
    [Distance.YD, (value: number) => value / 3],
]);

const yardsTable = new Map([
    [Distance.FT, (value: number) => value * 3],
    [Distance.M, (value: number) => value / 1.0936132983],
]);

const metersTable = new Map([
    [Distance.FT, (value: number) => value * 3.28084],
    [Distance.YD, (value: number) => value * 1.0936132983],
]);

/**
 * Lists how values in unit X can be converted to unit Y.
 */
const ConversionTable = new Map([
    [Area.SQM, sqmTable],
    [Area.SQFT, sqftTable],
    [Area.SQYD, sqydTable],
    [Area.MARLA, marlaTable],
    [Area.KANAL, kanalTable],
    [Area.SQWA, sqwaTable],
    [Area.RAI, raiTable],
    [Area.NGAN, nganTable],
    [Area.KATHA, kathaTable],
    [Distance.FT, feetTable],
    [Distance.YD, yardsTable],
    [Distance.M, metersTable],
]);

export default ConversionTable;
