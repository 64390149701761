import { useI18n } from 'strat/i18n/language';

import defaultUserName from './defaultUserName';

const useDefaultUserName = (): string => {
    const i18n = useI18n();
    return defaultUserName(i18n);
};

export default useDefaultUserName;
