import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, LoadingAnimation, Text } from 'strat/components';

import styles from './styles/loadingDescription.cssm';

const LoadingDescription = () => (
    <Flex column>
        <Text.XLarge bold>
            <Trans>Description</Trans>
        </Text.XLarge>
        <LoadingAnimation width={'80%'} height={'1.6rem'} className={styles.text} />
        <LoadingAnimation width={'50%'} height={'1.6rem'} className={styles.text} />
        <LoadingAnimation width={'20%'} height={'1.6rem'} className={styles.text} />
        <LoadingAnimation width={'60%'} height={'1.6rem'} className={styles.text} />
    </Flex>
);

export default LoadingDescription;
