import React from 'react';

import { ContactMethodControl } from '@app/userSettings/controls';
import { useSaveUserSettings, useUserSetting } from 'horizontal/userSettings/hooks';
import { ContactPersonRole } from 'horizontal/types';

import { UserSettingsKey } from '../types';

const UserContactMethodControl = () => {
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'keyof UserSettings'.
    const showPhoneNumber = useUserSetting(UserSettingsKey.SHOW_PHONE_NUMBER_IN_ADS);
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'keyof UserSettings'.
    const allowChatCommunication = useUserSetting(UserSettingsKey.ALLOW_CHAT_COMMUNICATION_IN_ADS);
    const saveUserSettings = useSaveUserSettings();

    const onSettingsChanged = React.useCallback(
        (settings) => {
            const userSettings = {
                [UserSettingsKey.ALLOW_CHAT_COMMUNICATION_IN_ADS]:
                    settings[ContactPersonRole.ALLOW_CHAT_COMMUNICATION],
                [UserSettingsKey.SHOW_PHONE_NUMBER_IN_ADS]:
                    settings[ContactPersonRole.SHOW_PHONE_NUMBER],
            } as const;
            saveUserSettings(userSettings);
        },
        [saveUserSettings],
    );

    return (
        <ContactMethodControl
            showPhoneNumber={showPhoneNumber}
            allowChatCommunication={allowChatCommunication}
            onSettingsChanged={onSettingsChanged}
        />
    );
};

export default UserContactMethodControl;
