import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';
import { isValidPhoneNumber } from 'strat/i18n/phone';

export enum FieldAttribute {
    PHONE_NUMBER = 'phoneNumber',
    CNIC = 'cnic',
}

export type UserDetails = {
    [FieldAttribute.PHONE_NUMBER]: string;
    [FieldAttribute.CNIC]: string;
};

export type Field = {
    readonly attribute: string;
    readonly title: (arg1: I18n) => string;
    readonly unit?: string;
    readonly initial: string;
};

export const MobileWalletPaymentFields: Array<Field> = [
    {
        attribute: FieldAttribute.PHONE_NUMBER,
        title: (i18n: I18n) => t(i18n)`JazzCash Account Phone Number`,
        unit: settings.defaultPhoneNumber.prefix,
        initial: '',
    },
    {
        attribute: FieldAttribute.CNIC,
        title: (i18n: I18n) => t(i18n)`CNIC`,
        initial: '',
    },
];

const Errors = {
    invalidCNIC: (i18n: I18n) => t(i18n)`Please enter your CNIC`,
    invalidPhone: (i18n: I18n) => t(i18n)`Please enter a valid phone number`,
} as const;

export const makeValidationSchema = (i18n: I18n) =>
    Yup.object().shape({
        phoneNumber: Yup.string()
            .required(Errors.invalidPhone(i18n))
            // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
            .test('validPhone', Errors.invalidPhone(i18n), (value) =>
                isValidPhoneNumber(
                    (value || '').startsWith('+')
                        ? value
                        : `${settings.defaultPhoneNumber.prefix}${value}`,
                ),
            ),
        cnic: Yup.string()
            .required(Errors.invalidCNIC(i18n))
            .min(13, Errors.invalidCNIC(i18n))
            .max(13, Errors.invalidCNIC(i18n)),
    });

export const generateInitialValues = () =>
    MobileWalletPaymentFields.reduce<Record<string, any>>(
        (acc, field) => ({ ...acc, [field.attribute]: field.initial }),
        {},
    );
