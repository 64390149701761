import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import useMostPopularCategories from '@app/branding/useMostPopularCategories';
import { Text } from 'strat/components';

import { selectSitemapCategories } from './state/selectors';
import CategoryIconList from './categoryIconList';
import MostPopularLocations from './mostPopularLocations';
import styles from './styles/sitemapMostPopular.cssm';

const SitemapMostPopular = () => {
    const categories = useMostPopularCategories();
    // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
    const locations = useSelector(selectSitemapCategories).map((loc) => ({
        ...loc,
        categories: undefined,
        hierarchy: [],
    }));

    return (
        <div className={styles.container}>
            <div className={styles.categoriesContainer}>
                <div className={styles.title}>
                    <Text.XXLarge bold>
                        <Trans>Categories</Trans>
                    </Text.XXLarge>
                </div>
                <div className={styles.description}>
                    <Text.Small>
                        <Trans>Browse through some of our most popular categories.</Trans>
                    </Text.Small>
                </div>
                {/* @ts-ignore */}
                <CategoryIconList categories={categories} />
            </div>
            <div className={styles.locationsContainer}>
                <div className={styles.title}>
                    <Text.XXLarge bold>
                        <Trans>Locations</Trans>
                    </Text.XXLarge>
                </div>
                <div className={styles.description}>
                    <Text.Small>
                        <Trans>Browse through some of our most popular locations.</Trans>
                    </Text.Small>
                </div>
                <MostPopularLocations locations={locations} />
            </div>
        </div>
    );
};

export default SitemapMostPopular;
