import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import { selectActiveUser } from 'strat/user/session';
import { makeCancelable, isCanceled } from 'strat/util';

import Page from 'horizontal/pages/page';
import { AdVirtualState, AdState } from 'horizontal/types';
import { fetchAd } from 'horizontal/ad/state';
import { fetchAddressBook } from 'horizontal/userOrders/addresses/state';

import ensureHasActiveUser from './ensureHasActiveUser';

export type RequestDeliveryRouteParams = {
    readonly adExternalID: string;
};

class RequestDeliverRoute extends Route {
    constructor() {
        super(RouteNames.REQUEST_DELIVERY, [
            [
                '^/requestDelivery/',
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { adExternalID }: RequestDeliveryRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const pathname = `/requestDelivery/${adExternalID}`;
        return { pathname };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }
        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { adExternalID },
            },
        } = context;

        const getAdData = () => getState().ad.data;
        const userExternalID = selectActiveUser(getState())?.externalID;
        const fetchAddressesPromise = dispatch(fetchAddressBook({ userExternalID }));
        const accessibleVirtualStates = [
            AdVirtualState.ACTIVE,
            AdVirtualState.FEATURED_PENDING,
            AdVirtualState.ELITE,
            AdVirtualState.ELITE_PENDING,
            AdVirtualState.FEATURED,
        ];

        const shouldShowDeliveryForm = () => {
            const {
                userExternalID: adUserExternalID,
                state,
                virtualState,
                deliveryInformation,
            } = getAdData();
            const isOwnAd = adUserExternalID === userExternalID;
            const isAdAccessible =
                state === AdState.ACTIVE && accessibleVirtualStates.includes(virtualState);

            return deliveryInformation && !isOwnAd && isAdAccessible;
        };

        const dataPromises = [fetchAddressesPromise];

        if (!getAdData() || getAdData().externalID !== adExternalID) {
            const fetchAdPromise = dispatch(fetchAd({ externalID: adExternalID }));
            dataPromises.push(fetchAdPromise);
        }
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'RequestDeliverRoute'.
        this.cancelablePromise = makeCancelable(Promise.all(dataPromises));
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'RequestDeliverRoute'.
        const promise = this.cancelablePromise.then(
            () => {
                if (shouldShowDeliveryForm()) {
                    context.rendering.renderPage(Page.REQUEST_DELIVERY, { adExternalID });
                } else {
                    // @ts-expect-error - TS2554 - Expected 0-1 arguments, but got 2.
                    context.http.status(404, `This ad seems not valid for delivery`);
                    context.rendering.renderPage(Page.NOT_FOUND);
                }
                return Promise.resolve();
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'RequestDeliverRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'RequestDeliverRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new RequestDeliverRoute();
