import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/positionedOverlay.cssm';

/**
 * Properties for {@see PositionedOverlay}.
 */
export type Props = {
    /**
     * Method to call to render content at the top
     * of the container.
     */
    renderTop?: () => React.ReactElement;
    /**
     * Method to call to render content at the bottom
     * of the container.
     */
    renderBottom?: () => React.ReactElement;
    /**
     * Method to call to render content in the top
     * left corner.
     */
    renderLeftTop?: () => React.ReactNode;
    /**
     * Method to call to render content in the top
     * right corner.
     */
    renderRightTop?: () => React.ReactNode;
    /**
     * Method to call to render content in the bottom
     * left corner.
     */
    renderLeftBottom?: () => React.ReactNode;
    /**
     * Method to call to render content in the bottom
     * right corner.
     */
    renderRightBottom?: () => React.ReactNode;
    /**
     * Method to call to render content in the middle/center.
     */
    renderMiddle?: () => React.ReactElement;
    /**
     * Optionally, a CSS class name for the absolutely
     * positioned container.
     */
    className?: string;
    /**
     * Optional class name for the bottom positioned container.
     */
    bottomClassName?: string;
};

/**
 * Single block in a positioned overlay.
 */
// @ts-expect-error - TS7031 - Binding element 'className' implicitly has an 'any' type. | TS7031 - Binding element 'render' implicitly has an 'any' type.
const PositionedOverlayBlock = ({ className, render }) => {
    const items = render ? React.Children.toArray(render()) : [];

    if (items.length === 0) {
        return null;
    }

    return (
        <div className={className}>
            {/* @ts-expect-error - TS2769 - No overload matches this call. */}
            {items.map((child, index) => React.cloneElement(child, { key: index }))}
        </div>
    );
};

/**
 * Helper component that assists in rendering other
 * components on top of each other.
 */
const PositionedOverlay = (props: Props): React.ReactElement => {
    const className = props.className ? `${styles.container} ${props.className}` : styles.container;

    return (
        <div className={className}>
            <PositionedOverlayBlock className={styles.topContainer} render={props.renderTop} />
            <PositionedOverlayBlock
                className={classNames(styles.bottomContainer, props.bottomClassName)}
                render={props.renderBottom}
            />
            <PositionedOverlayBlock
                className={styles.leftTopContainer}
                render={props.renderLeftTop}
            />
            <PositionedOverlayBlock
                className={styles.rightTopContainer}
                render={props.renderRightTop}
            />
            <PositionedOverlayBlock
                className={styles.leftBottomContainer}
                render={props.renderLeftBottom}
            />
            <PositionedOverlayBlock
                className={styles.rightBottomContainer}
                render={props.renderRightBottom}
            />
            <PositionedOverlayBlock
                className={styles.middleContainer}
                render={props.renderMiddle}
            />
        </div>
    );
};

export default PositionedOverlay;
