import * as React from 'react';
import classNames from 'classnames';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { LoadingAnimation } from 'strat/components';

import styles from './styles/loadingAdCard.cssm';

type Props = {
    readonly viewType: Values<typeof SearchHitsLayout>;
};

const LoadingAdCard = ({ viewType }: Props) => (
    <div className={classNames(styles.container, styles[viewType.toString().toLowerCase()])}>
        <LoadingAnimation className={styles.photoContainer} />
        <div className={styles.details}>
            <LoadingAnimation />
            <LoadingAnimation />
        </div>
    </div>
);

export default LoadingAdCard;
