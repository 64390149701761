import * as React from 'react';
import type { Choice, ChoiceValue } from 'strat/components/types';

import type { CategoryFieldChoice, FlatCategoryField } from 'horizontal/types';
import {
    FilterableDropdown,
    DropdownPopularSuggestions,
    CategoryFieldChoiceIcon,
    DropdownVariants,
} from 'horizontal/components';
import usePopularChoices from 'horizontal/search/filters/usePopularChoices';

type Props<T extends ChoiceValue> = {
    readonly title?: string;
    readonly description?: string;
    readonly name: string;
    readonly errorMessage?: string;
    readonly accepted?: boolean;
    readonly onChange: (arg1: T) => void;
    readonly value?: T;
    readonly choices: Array<Choice<T>>;
    readonly placeholder?: string;
    readonly stretch?: boolean;
    readonly field: FlatCategoryField;
    readonly readOnly?: boolean;
    readonly boldLabel?: boolean;
    readonly onBlur?: (e: React.SyntheticEvent) => void;
    readonly isAutofilled?: boolean;
};

const FilterableDropdownWithSuggestions = <T extends NonNullable<ChoiceValue>>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    onChange,
    value = '' as T,
    choices,
    placeholder,
    stretch,
    field,
    readOnly,
    boldLabel,
    isAutofilled,
}: Props<T>) => {
    const popularChoices = usePopularChoices(field) as Choice<T>[];

    const renderIcon = React.useCallback(
        (choice) => (
            <CategoryFieldChoiceIcon
                choice={choice as CategoryFieldChoice}
                field={field.attribute}
            />
        ),
        [field],
    );

    const renderSuggestions = React.useCallback(
        (onClick) => {
            if (!popularChoices.length || choices.length < 10) {
                return;
            }

            return (
                <DropdownPopularSuggestions
                    fieldName={field.name}
                    popularChoices={popularChoices}
                    value={value}
                    onClick={onClick}
                    renderIcon={renderIcon}
                />
            );
        },
        [popularChoices, choices, field, value, renderIcon],
    );

    return (
        <FilterableDropdown
            isAutofilled={isAutofilled}
            title={title}
            description={description}
            name={name}
            errorMessage={errorMessage}
            accepted={accepted}
            onChange={onChange}
            choices={choices}
            placeholder={placeholder}
            stretch={stretch}
            renderSuggestions={renderSuggestions}
            value={value}
            readOnly={readOnly}
            renderIcon={renderIcon}
            variant={DropdownVariants.TEXT_INPUT}
            lightPlaceholder
            boldLabel={boldLabel}
        />
    );
};

export default FilterableDropdownWithSuggestions;
