import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';

/**
 * Selects the entire 'propertyOfTheWeek' part of the state.
 */
const selectPropertyOfTheWeek = createSelector(
    (state: GlobalState) => state.propertyOfTheWeek?.data,
    (potw) => potw,
);

/**
 * Selects the property that is the property of the week.
 */
const selectPropertyOfTheWeekProperty = createSelector(
    selectPropertyOfTheWeek,
    (potw) => potw?.property,
);

/**
 * Selects the externalID of the property that is being displayed
 * as the property of the week (if any).
 */
const selectPropertyOfTheWeekExternalID = createSelector(
    selectPropertyOfTheWeekProperty,
    (property) => property?.externalID,
);

export { selectPropertyOfTheWeekExternalID };
