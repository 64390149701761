import propertyTransformer from 'strat/property/propertyTransformer';
import { PropertyType, type PropertyData } from 'strat/property/types';
import { projectTransformer } from 'strat/project/projectTransformer';
import { ProjectData } from 'strat/project/types';
import type { AnyAdData } from 'strat/types';

import { AttributeFetchedFromAlgolia } from './types';

const listingTransformerByType = (
    listingType: Values<typeof PropertyType>,
    listing: AnyAdData | AttributeFetchedFromAlgolia,
    language: string,
) =>
    listingType === PropertyType.PROJECT
        ? projectTransformer(listing as ProjectData, language)
        : propertyTransformer<PropertyData>(
              listing as PropertyData,
              language,
              CONFIG.build.ENABLE_MERGED_INDEX,
          );

export default listingTransformerByType;
