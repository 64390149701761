import React from 'react';

type Props = {
    content: any;
};

/**
 * Renders a script tag with type application/ld+json
 * @param content ld+json format content
 */
const LDJSONScript = ({ content }: Props) => (
    <script
        dangerouslySetInnerHTML={{
            __html: JSON.stringify(content),
        }}
        type="application/ld+json"
    />
);

export default LDJSONScript;
