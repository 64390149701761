import * as React from 'react';
import Scroll from 'react-scroll';
import autoBind from 'react-autobind';

import styles from './styles/scroller.cssm';

type Props = {
    /**
     * Additional classname for styling the content
     */
    className?: string;
    /**
     * Number of skipped elements in a scroll event.
     */
    offset: number;
    /**
     * Renders the trigger for scrolling up event.
     */
    renderUpTrigger: (active: boolean) => React.ReactElement;
    /**
     * Render the trigger for scrolling down event.
     */
    renderDownTrigger: (active: boolean) => React.ReactElement;
    /**
     * Elements rendered in the scrollable container.
     */
    children: Array<React.ReactNode>;
};

type State = {
    /**
     * Index of the first element in the list visible at a certain moment.
     */
    currentIndex: number;
    /**
     * Index of the first element which will be visible after scrolling up.
     */
    previousIndex: number;
    /**
     * Index of the first element which will be visible after scrolling down.
     */
    nextIndex: number;
};

/**
 *  Renders a vertical carousel like component.
 */
class Scroller extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentIndex: 0,
            previousIndex: 0,
            nextIndex: this.props.offset,
        };

        autoBind(this);
    }

    goUp() {
        const previousIndex = this.state.previousIndex - this.props.offset;

        this.setState((prevState) => ({
            previousIndex: previousIndex > 0 ? previousIndex : 0,
            currentIndex: prevState.previousIndex,
            nextIndex:
                prevState.previousIndex !== prevState.currentIndex
                    ? prevState.currentIndex
                    : this.props.offset,
        }));
    }

    goDown() {
        if (this.state.nextIndex === this.state.currentIndex) {
            return;
        }

        const childrenCount = React.Children.count(this.props.children);
        const remainingChildrenCount = childrenCount - this.state.nextIndex - this.props.offset;

        const offset =
            remainingChildrenCount < this.props.offset ? remainingChildrenCount : this.props.offset;

        this.setState((prevState) => ({
            previousIndex: prevState.currentIndex,
            currentIndex: prevState.nextIndex,
            nextIndex:
                this.state.nextIndex === childrenCount - this.props.offset
                    ? prevState.nextIndex
                    : prevState.nextIndex + offset,
        }));
    }

    render() {
        const className = this.props.className
            ? `${styles.elements} ${this.props.className}`
            : styles.elements;

        return (
            <>
                <Scroll.Link
                    onClick={this.goUp}
                    activeClass="active"
                    to={this.state.previousIndex.toString()}
                    smooth
                    duration={250}
                    containerId="containerElement"
                >
                    {this.props.renderUpTrigger(this.state.currentIndex !== 0)}
                </Scroll.Link>
                <div className={className} id="containerElement">
                    {React.Children.map(this.props.children, (child, index) => (
                        <Scroll.Element name={index.toString()}>
                            {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                            {React.cloneElement(child)}
                        </Scroll.Element>
                    ))}
                </div>
                <Scroll.Link
                    onClick={this.goDown}
                    activeClass="active"
                    to={this.state.nextIndex.toString()}
                    smooth
                    duration={250}
                    containerId="containerElement"
                >
                    {this.props.renderDownTrigger(this.state.nextIndex !== this.state.currentIndex)}
                </Scroll.Link>
            </>
        );
    }
}

export default Scroller;
