import * as React from 'react';
import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { useIsStratCreditUser } from 'strat/user';
import settings from '@app/branding/settings';

import useCreditSystemGuard from 'horizontal/authorization/useCreditSystemGuard';
import type { UpdateAdRouteParams } from 'horizontal/routes';

type UseNavigateToUpdateAd = (arg1: Partial<UpdateAdRouteParams>) => void;

const useNavigateToUpdateAd = (): UseNavigateToUpdateAd => {
    const i18n = useI18n();
    const router = useRouter();
    const isStratCreditUser = useIsStratCreditUser();
    const updateRoute = isStratCreditUser
        ? RouteNames.AGENCY_PORTAL_UPDATE_AD
        : RouteNames.UPDATE_AD;
    const brandName = settings.getAgencyPortalBrandName(i18n);
    const withCreditSystemGuard = useCreditSystemGuard(
        t(i18n)`You can only post ads through ${brandName} Pro.`,
    );

    return React.useCallback(
        (params: Partial<UpdateAdRouteParams>) => {
            withCreditSystemGuard(() => router.pushRoute(updateRoute, params));
        },
        [withCreditSystemGuard, router, updateRoute],
    );
};

export default useNavigateToUpdateAd;
