import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

import type { FullAd } from 'horizontal/types';

import PaymentProcessingContent from './paymentProcessingContent';
import PaymentProcessingCTAs from './paymentProcessingCTAs';
import styles from './styles/paymentSuccessPending.cssm';

type Props = {
    readonly ad?: FullAd | null;
};

const PaymentSuccessPending = ({ ad }: Props) => (
    <PaymentProcessingContent>
        <Text.Regular className={styles.processingText}>
            <Trans>
                This is taking longer than expected. Please try to apply the service you purchased
                later.
            </Trans>
        </Text.Regular>
        <PaymentProcessingCTAs ad={ad} />
    </PaymentProcessingContent>
);

export default PaymentSuccessPending;
