import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { ViewSections } from 'strat/gtm';

import type { Category } from 'horizontal/types';

import CategoryLink from '../categoryLink';

import TopCategoryGroup from './topCategoriesGroup';
import styles from './styles/moreCategoriesChildren.cssm';

type Props = {
    readonly activeCategory: Category;
    readonly onClick: (arg1: React.SyntheticEvent<any>) => void;
    readonly display?: boolean;
};

export const MoreCategoriesChildren = ({ activeCategory, onClick, display }: Props) => {
    return (
        <div
            className={classNames(styles.categoryChildrenContainer, {
                [styles.displayNone]: !display,
            })}
        >
            <CategoryLink
                category={activeCategory}
                viewSection={ViewSections.CATEGORY_DROPDOWN}
                className={styles.categoryChildrenTopTitle}
            >
                <Trans>{`All in ${activeCategory.name}`}</Trans>
            </CategoryLink>

            <TopCategoryGroup
                noGrid
                onClick={onClick}
                key={activeCategory.id}
                category={activeCategory}
            />
        </div>
    );
};
