import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useDialog } from 'strat/dialogs/dialogContext';
import { Flex, Text } from 'strat/components';

import { DialogNames } from 'horizontal/dialogs';
import { useEnsureHasActiveUser } from 'horizontal/user';
import IconReportFlag from 'horizontal/assets/icons/iconReportFlag.svg';

import styles from './styles/reportAd.cssm';

const ReportAd = () => {
    const [, setVisible] = useDialog(DialogNames.REPORT_AD_DIALOG);

    const onClick = useEnsureHasActiveUser({ onDone: () => setVisible(true) });

    return (
        <Flex alignCenter className={styles.container} onClick={onClick}>
            <IconReportFlag className={styles.icon} />
            <Text.Regular bold className={styles.text} aria-label="Report this ad">
                <Trans>Report this ad</Trans>
            </Text.Regular>
        </Flex>
    );
};

export default ReportAd;
