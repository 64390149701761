import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';

import { EventType } from 'strat/user/types';
import { RouteNames } from 'strat/routes';
import { selectRouteName } from 'strat/routes/selectors';
import { selectIsMobileMapBasedSearch } from 'strat/search/selectors';

import { PermissionTypes } from './types';

type NotificationApprovalData = {
    promptPostponed: boolean;
    scriptLoaded: boolean;
    supported: boolean;
    permission: boolean;
};

const selectEvents = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.user,
    // @ts-expect-error - TS7006 - Parameter 'user' implicitly has an 'any' type.
    (user) => user.eventsHistory,
);

const selectNotificationsData = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.notifications,
    // @ts-expect-error - TS7006 - Parameter 'notifications' implicitly has an 'any' type.
    (notifications) => notifications,
);

const selectNotificationsBackOffUntil = createSelector(
    selectNotificationsData,
    (notifications) => notifications.backOffUntil,
);

const selectIsNotificationsPreApprovalAllowed = createSelector(
    selectNotificationsData,
    selectIsMobileMapBasedSearch,
    ({ scriptLoaded, supported, permission }: NotificationApprovalData, isMobileMapBasedSearch) =>
        [
            scriptLoaded,
            supported,
            // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'boolean' and 'string' have no overlap.
            permission === PermissionTypes.DEFAULT,
            !isMobileMapBasedSearch,
        ].every((isTrue) => isTrue),
);

const selectIsNotificationsPreApprovalDisplayable = createSelector(
    selectNotificationsData,
    selectIsNotificationsPreApprovalAllowed,
    ({ promptPostponed }: NotificationApprovalData, isNotificationsPreApprovalAllowed) =>
        !promptPostponed && isNotificationsPreApprovalAllowed,
);

const selectIsNotificationsFooterVisible = createSelector(
    selectIsNotificationsPreApprovalDisplayable,
    (isNotificationsPreApprovalDisplayable) =>
        isNotificationsPreApprovalDisplayable && CONFIG.build.ENABLE_PUSH_NOTIFICATIONS,
);

const selectNumberOfSearchPageViews = createSelector(
    selectEvents,
    (eventsHistory) =>
        // @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
        eventsHistory.filter((event) => event.eventType === EventType.SEARCH_PAGE_VIEW).length,
);

const selectShowFallbackRecommendationsInfoBox = createSelector(
    [selectNotificationsData, selectRouteName],
    (notifications, routeName) =>
        notifications.isUsingFallbackNotifications &&
        routeName === RouteNames.RECOMMENDED_PROPERTIES,
);

const selectIsNotificationsPromptVisible = (promptName: string) =>
    createSelector(
        selectNotificationsData,
        selectIsNotificationsPreApprovalDisplayable,
        (notifications, promptDisplayable) => {
            if (!promptDisplayable) {
                return false;
            }

            const promptVisible = notifications.promptsVisibility[promptName];
            if (isNil(promptVisible)) {
                return true;
            }

            return promptVisible;
        },
    );

const selectNotifications = createSelector(
    selectNotificationsData,
    (notifications) => notifications.notifications,
);

export {
    selectIsNotificationsPreApprovalAllowed,
    selectIsNotificationsPreApprovalDisplayable,
    selectIsNotificationsFooterVisible,
    selectShowFallbackRecommendationsInfoBox,
    selectNotificationsBackOffUntil,
    selectNumberOfSearchPageViews,
    selectIsNotificationsPromptVisible,
    selectNotifications,
    selectEvents,
};
