import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import IconArrowLeft from '@app/assets/icons/iconArrowLeft.svg';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';

import styles from './styles/carouselNavigationButton.cssm';

export const NavButtonType = Object.freeze({
    LEFT: 'left',
    RIGHT: 'right',
});

type Props = {
    readonly onClick: () => void;
    readonly disabled: boolean;
    readonly type: Values<typeof NavButtonType>;
};

const CarouselNavigationButton = ({ onClick, disabled, type }: Props) => {
    const isRTL = useSelector(selectIsLanguageRTL);

    return (
        <div className={styles.container}>
            <button
                onClick={disabled ? () => {} : onClick}
                className={classNames(styles.button, {
                    [styles.disabled]: disabled,
                    [styles.leftOffset]:
                        (isRTL && type !== NavButtonType.LEFT) ||
                        (!isRTL && type === NavButtonType.LEFT),
                    [styles.rightOffset]:
                        (isRTL && type !== NavButtonType.RIGHT) ||
                        (!isRTL && type === NavButtonType.RIGHT),
                })}
                aria-hidden={disabled}
            >
                {type === NavButtonType.LEFT ? <IconArrowLeft /> : <IconArrowRight />}
            </button>
        </div>
    );
};

export default CarouselNavigationButton;
