import settings from '@app/branding/settings';

import type { PropertyData } from '../types';
import { PropertyAvailability, PropertyVerificationStatus } from '../types';

import { PropertyVerificationBadges } from './propertyVerificationBadges';
import type { PropertyVerificationBadge } from './propertyVerificationBadges';

/**
 * Return the highest ranking badge supported for a given property.
 * If property is sold/rented, we should not render verification badge.
 * @param property
 */
const computeVerificationBadge = (
    property: PropertyData,
): PropertyVerificationBadge | null | undefined => {
    const { verification, availabilityStatus } = property;

    if (
        availabilityStatus &&
        [PropertyAvailability.SOLD, PropertyAvailability.RENTED].includes(availabilityStatus)
    ) {
        return null;
    }

    const enabledVerificationBadges = settings?.enabledVerificationBadges ?? [];
    const eligible = verification?.eligible ?? false;
    const status = verification?.status ?? PropertyVerificationStatus.UNVERIFIED;

    if (
        eligible &&
        status === PropertyVerificationStatus.VERIFIED &&
        enabledVerificationBadges.includes(PropertyVerificationBadges.TRUCHECKED)
    ) {
        return PropertyVerificationBadges.TRUCHECKED;
    }

    if (eligible && enabledVerificationBadges.includes(PropertyVerificationBadges.CHECKED)) {
        return PropertyVerificationBadges.CHECKED;
    }

    if (
        status === PropertyVerificationStatus.VERIFIED &&
        enabledVerificationBadges.includes(PropertyVerificationBadges.VERIFIED)
    ) {
        return PropertyVerificationBadges.VERIFIED;
    }

    return null;
};

export default computeVerificationBadge;
