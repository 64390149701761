import * as React from 'react';
import { Trans } from '@lingui/macro';

import styles from './styles/featuredTag.cssm';

const FeaturedTag = () => {
    return (
        <span className={styles.container} aria-label="Featured">
            <Trans>Featured</Trans>
        </span>
    );
};

export default FeaturedTag;
