import * as React from 'react';
import autoBind from 'react-autobind';

import { Dialog, CloseButton } from 'strat/modal';
import { stopEventPropagation } from 'strat/util';

import styles from './styles/dialogWithCloseButton.cssm';

/**
 * Props passed to the trigger element.
 */
type TriggerProps = {
    open: () => void;
    close: () => void;
};

/**
 * Properties for {@see DialogWithCloseButton}.
 */
type Props = {
    /**
     * Whether the dialog should be dismissible by clicking
     * outside it.
     */
    readonly dismissible?: boolean;
    /**
     * Additional CSS class to apply on the dialog.
     */
    readonly className?: string;
    /**
     * Method to call to render the element which opens/closes the dialog.
     */
    readonly renderTrigger: (props: TriggerProps) => React.ReactElement<any>;
    /**
     * Contents of the dialog.
     */
    readonly children: React.ReactNode;
};

/**
 * State for {@see DialogWithCloseButton}.
 */
type State = {
    visible: boolean;
};

/**
 * Renders a managed dialog with a close button in the top-right corner.
 *
 * This dialog is managed because it maintains its own state. The caller
 * is only responsible for rendering the trigger, which is the element
 * which is supposed to open or close the dialog.
 */
class DialogWithCloseButton extends React.Component<Props, State> {
    /**
     * Default values for optional properties.
     */
    static defaultProps = {
        dismissible: true,
    };

    /**
     * Initializes a new instance of {@see DialogWithCloseButton}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = {
            visible: false,
        };
    }

    /**
     * Opens the dialog.
     */
    open(): void {
        this.setState({ visible: true });
    }

    /**
     * Closes the dialog.
     */
    close(): void {
        this.setState({ visible: false });
    }

    /**
     * Renders the dialog with a close button.
     */
    render(): React.ReactElement {
        const { dismissible, className, renderTrigger } = this.props;

        const triggerProps = {
            open: this.open,
            close: this.close,
        } as const;

        const contentClassNames = [styles.content];
        if (className) {
            contentClassNames.push(className);
        }

        return (
            <>
                {renderTrigger(triggerProps)}
                <Dialog
                    className={className}
                    visible={this.state.visible}
                    onVisibilityChanged={(visible) => {
                        this.setState({ visible });
                    }}
                    dismissible={dismissible}
                >
                    <div className={contentClassNames.join(' ')}>
                        <CloseButton
                            className={styles.closeButton}
                            onClick={(event) => {
                                stopEventPropagation(event);
                                this.close();
                            }}
                        />
                        {this.props.children}
                    </div>
                </Dialog>
            </>
        );
    }
}

export default DialogWithCloseButton;
