import * as React from 'react';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { Choice } from 'strat/components/types';
import classNames from 'classnames';
import { useDefaultCurrencySelectionConfiguration } from 'strat/i18n/money';

import styles from './styles/upsellingCurrencySelection.cssm';

type CurrencyChoiceProps = {
    option: Choice<string>;
    isSelected: boolean;
    onChange: (newCurrency: string) => void;
};

const CurrencyChoice = ({ option, isSelected, onChange }: CurrencyChoiceProps) => {
    return (
        <div
            key={option.id ?? option.value}
            onClick={() => onChange(option.value)}
            className={classNames(styles.currencyChoice, {
                [styles.isSelected]: isSelected,
            })}
        >
            <Text.Regular>{option.value}</Text.Regular>
        </div>
    );
};

const UpsellingCurrencySelection = () => {
    const defaultCurrencyConfiguration = useDefaultCurrencySelectionConfiguration();

    if (!defaultCurrencyConfiguration) {
        return null;
    }

    const { onSelectCurrency, currencies, selectedCurrency } = defaultCurrencyConfiguration;

    return settings.multipleCurrencyPackages ? (
        <Flex column alignCenter stretchWidth className={styles.currencySelection}>
            <Text.XLarge bold className={styles.currencySelectionTitle}>
                <Trans>Preferred currency</Trans>
            </Text.XLarge>
            <Flex stretchWidth>
                {currencies.map((currency) => {
                    return (
                        <CurrencyChoice
                            option={currency}
                            isSelected={selectedCurrency == currency.value}
                            onChange={onSelectCurrency}
                        />
                    );
                })}
            </Flex>
        </Flex>
    ) : null;
};

export default UpsellingCurrencySelection;
