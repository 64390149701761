import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { Image } from 'strat/util';

import dubizzleCarsLogoPNG from 'horizontal/assets/images/dubizzleCarsLogo.png';
import dubizzleCarsLogoWEBP from 'horizontal/assets/images/dubizzleCarsLogo.webp';
import cadillacImagePNG from 'horizontal/assets/images/cadillacImage.png';
import MobileCarIcon from 'horizontal/assets/icons/mobileCarIcon.svg';
import KeyCarIcon from 'horizontal/assets/icons/keyCarIcon.svg';
import LaptopCarIcon from 'horizontal/assets/icons/laptopCarIcon.svg';

import styles from './styles/sellYourCarHero.cssm';

const dubizzleLogo = {
    source: dubizzleCarsLogoPNG,
    sourceWEBP: dubizzleCarsLogoWEBP,
};

const cadillacImage = {
    source: cadillacImagePNG,
};

const SellYourCarHero = () => (
    <Flex alignCenter stretchWidth className={styles.heroContainer}>
        <Flex column className={styles.heroLeftContainer}>
            <h1 className={styles.header}>
                <Trans>Sell your car hassle free!</Trans>
            </h1>
            <Image image={dubizzleLogo} />
            <Text.XXLarge>
                <Trans>Share a few details to sell your car from the comfort of your home!</Trans>
            </Text.XXLarge>
            <Flex stretchWidth className={styles.heroIconContainer}>
                <MobileCarIcon />
                <KeyCarIcon />
                <LaptopCarIcon />
            </Flex>
        </Flex>
        <Image imgClassName={styles.image} image={cadillacImage} />
    </Flex>
);

export default SellYourCarHero;
