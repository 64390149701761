import upperFirst from 'lodash/upperFirst';

// Suffixes for which we assume that the entire domain is private
// and the domain _is_ the root domain.
//
// These are mostly used by our development/staging/ra/test
// environments and we want to avoid having overlap with
// the other environments hosted on the same apex.
const knownPrivateSuffixies = [
    '.ra.dubizzle.dev',
    '.dubizzle.dev',
    '.sector.sh',
    '.sector.run',
    '.sectorlabs.ro',
    '.herokuapp.com',
    `.${CONFIG.build.STRAT_BRAND_NAME}.run`,
];

// TODO: figure out a way to use the well updated list from
// https://publicsuffix.org/ without bloating our bundle size
//
// This is just a list of suffixes on which we currently host
// domains. Expand as needed.
const knownPublicSuffixes = [
    '.sa.com',
    '.com.bh',
    '.com.eg',
    '.com.kw',
    '.com.lb',
    '.com.om',
    '.com.pk',
    '.com.ph',
    '.co.id',
    '.com',
    '.sa',
    '.jo',
    '.om',
    '.qa',
    '.kw',
    '.bh',
    '.run',
    '.net',
    '.ph',
];

/**
 * Gets the root domain of the specified domain (www.bayut.com > bayut.com)
 * and removes the port number from the domain.
 *
 * Just splitting by dots doesn't work because some countries use two
 * parts for their suffix. Like 'co.uk' or 'com.pk'.
 *
 * This is still not fully reliable, but it works for our current
 * situations. For development/staging environments, the root is
 * often not the same as the apex since we don't want to share
 * resources between different environments.
 *
 * Do not use this to get the apex of arbitrary domains. This is
 * meant to find the root domain for our own application.
 */
const getRootDomain = (domain: string): string => {
    if (knownPrivateSuffixies.some((s) => domain.endsWith(s))) {
        return domain;
    }

    const suffix = knownPublicSuffixes.find((s) => domain.endsWith(s));
    if (!suffix) {
        return domain;
    }

    return domain.replace(suffix, '').split('.').slice(-1).join('.') + suffix;
};

const isLocalDomain = (domain?: string | null): boolean =>
    !domain ||
    domain.startsWith('localhost') ||
    domain.startsWith('127.0.0.1') ||
    domain.startsWith('192.');

const getCanonicalDomain = (): [string, number | string] => {
    // The default 3000 port is used by the unit tests only.
    // For manual testing the apps define their own port.
    if (!CONFIG.runtime.CANONICAL_DOMAIN) {
        return ['localhost', CONFIG.runtime.PORT || '3000'];
    }

    const parts = CONFIG.runtime.CANONICAL_DOMAIN.split(':') as [string, number | string];

    // no explicit port number, it's implied: port 80 or 443
    if (!parts[1]) {
        return [parts[0], ''];
    }

    return parts;
};

/**
 * Canonical domain parts.
 */
export default () => {
    const [domain, port] = getCanonicalDomain();
    const rootDomain = getRootDomain(domain);

    return {
        domain,
        rootDomain,
        rootDomainTitle: upperFirst(rootDomain),
        protocol: isLocalDomain(domain) ? 'http' : 'https',
        port: port ? `:${port}` : '',
    };
};
