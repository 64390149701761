import { setContent } from '@sector-labs/fe-search-redux/state';
import { MultiJobSearchHandler } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import settings from '@app/branding/settings';

import { hitsTransformer } from 'horizontal/search/hits';
import type { LiteAd } from 'horizontal/types';

import { SearchJobName } from './constants';

const processCategoriesWithHitsResponse = (
    context: RoutingContextWithMiddlewares,
    jobSearchHandler: MultiJobSearchHandler,
) => {
    const responses = settings.homePageCategoriesWithHitsIdentifiers
        .map((categoryIdentifier: string) =>
            jobSearchHandler.getResponseByJobName(
                SearchJobName.categoryWithHits(categoryIdentifier),
            ),
        )
        .filter((response) => !!response);

    if (!responses.length) {
        return;
    }

    // @ts-expect-error - TS2769 - No overload matches this call. | TS2533 - Object is possibly 'null' or 'undefined'.
    const mergedHits = [].concat(...responses.map((response) => response.hits || []));

    const {
        redux: {
            store: { dispatch },
        },
        i18n: { locale: language },
    } = context;

    dispatch(
        // @ts-expect-error - TS2345 - Argument of type '{ hits: LiteAd[]; exhaustiveNbHits?: boolean | undefined; hitsPerPage?: number | undefined; index?: string | undefined; nbHits?: number | undefined; nbPages?: number | undefined; processingTimeMS?: number | undefined; facets?: SearchResponseFacets | undefined; facets_stats?: SearchResponseFacetsStats | undefined; }' is not assignable to parameter of type 'SearchResponse<SearchResponseHit>'.
        setContent({
            ...responses[0],
            hits: hitsTransformer<LiteAd>(mergedHits, language),
        }),
    );
};

export default processCategoriesWithHitsResponse;
