import type { MixedAd, AgencyLogo } from 'horizontal/types';
import { AgencyRoles } from 'horizontal/types';

import useAdAgency from './useAdAgency';

const useAgencyLogo = (ad: MixedAd): AgencyLogo | null | undefined => {
    const agency = useAdAgency(ad);
    if (agency && !agency?.roles?.includes(AgencyRoles.DISABLE_AD_CARD_LOGO)) {
        return { id: agency.logo?.id, name: agency.name };
    }
    return null;
};

export default useAgencyLogo;
