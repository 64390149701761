import { t } from '@lingui/macro';
import * as React from 'react';
import { LoadingAnimation, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import type { ChatAd } from 'horizontal/chat/types';

import styles from './styles/adTitle.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly isLoading: boolean;
};

const AdTitle = ({ ad, isLoading }: Props) => {
    const i18n = useI18n();

    if (isLoading && !ad) {
        return <LoadingAnimation className={styles.loading} />;
    }

    if (ad?.title) {
        return (
            <Text.Base bold className={styles.text} aria-label="Ad title">
                {ad.title}
            </Text.Base>
        );
    }

    return <Text.Base className={styles.text}>{t(i18n)`Couldn't load title`}</Text.Base>;
};

export default AdTitle;
