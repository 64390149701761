import { createSelector } from 'reselect';
import { FetchData, FetcherFactoryWithContext } from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import EMPTY_OBJECT from 'strat/empty/object';
import EMPTY_ARRAY from 'strat/empty/array';

import { GlobalState } from 'horizontal/agencyPortal/state';

import type { LogItem } from './types';

export type FetchAgencyActivityLogParams = {
    externalID: string;
    limit?: number | null | undefined;
    before?: number | null | undefined;
};

export type ActivityLogState = {
    items: Array<LogItem>;
    complete: boolean;
};

const agencyActivityLogFactory = new FetcherFactoryWithContext<FetchAgencyActivityLogParams>(
    ['agencyPortal', 'agencyActivityLog'],
    ({ params, state }) =>
        new StratAPI(state.i18n.language).agencyActivityLog(
            params.externalID,
            params.limit,
            // @ts-expect-error - TS2345 - Argument of type 'number | null | undefined' is not assignable to parameter of type 'Date | null | undefined'.
            params.before,
        ),
    {
        successCodes: [200],
        catchServerErrors: true,
    },
);

const stateProcessor = (data: null | FetchData, previousData: null | FetchData) => {
    const currentStateItems = previousData?.items || EMPTY_ARRAY;
    const newIncomingItemsFromServer = data?.items || EMPTY_ARRAY;
    const items = currentStateItems.concat(newIncomingItemsFromServer);
    return { ...(previousData || EMPTY_OBJECT), ...(data || EMPTY_OBJECT), items };
};

const agencyActivityLogCreator = agencyActivityLogFactory.creator();
const agencyActivityLogReducer = agencyActivityLogFactory.reducer(stateProcessor);

const fetchAgencyActivityLog = (
    externalID: string,
    limit: number | null = null,
    before: number | null = null,
) => agencyActivityLogCreator({ externalID, limit, before });

const selectAgencyActivityLogData = (state: GlobalState) => {
    return (
        state.agencyPortal.agencyActivityLog?.data || (EMPTY_OBJECT as Partial<ActivityLogState>)
    );
};

const selectAgencyActivityLogItems = createSelector(
    selectAgencyActivityLogData,
    (data) => data.items || (EMPTY_ARRAY as LogItem[]),
);

const selectAgencyActivityLogLoading = (state: GlobalState) =>
    !state.agencyPortal?.agencyActivityLog?.loaded;

const selectAgencyActivityLogOldestItemTimestamp = createSelector(
    selectAgencyActivityLogItems,
    (items) => {
        if (!items || items.length === 0) {
            return null;
        }
        return items[items.length - 1].timestamp;
    },
);

const selectAgencyActivityLogComplete = createSelector(
    selectAgencyActivityLogData,
    (data) => data.complete === true,
);

export {
    fetchAgencyActivityLog,
    agencyActivityLogReducer,
    selectAgencyActivityLogItems,
    selectAgencyActivityLogLoading,
    selectAgencyActivityLogOldestItemTimestamp,
    selectAgencyActivityLogComplete,
};
