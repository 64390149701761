import * as React from 'react';
import { useSelector } from 'react-redux';

import { useFreeLimitUsageByAd } from 'horizontal/adLimits/hooks';
import { selectCategories } from 'horizontal/categories';
import { CategoryRole } from 'horizontal/types';
import { findCategoryByExternalID } from 'horizontal/categories/findCategoriesBy';
import { AdState, type Ad } from 'horizontal/types';

const useIsAdUndertaking = (ad?: Ad | null) => {
    const freeLimitUsage = useFreeLimitUsageByAd(ad);
    // An equal is needed because we are actually fetching freeLimitUsage (onRouteEnter ) after a successful Post Ad
    // meaning that the quota has been consumed but not surpassed
    const canPostAdForFree = freeLimitUsage && freeLimitUsage.usedQuota <= freeLimitUsage.quota;
    const activeProducts = ad?.activeProducts;
    const adCategory = ad?.category;
    const adCategoryExternalID = adCategory && adCategory[adCategory.length - 1].externalID;
    const isLimitedAd = ad?.state === AdState.LIMITED;

    const categories = useSelector(selectCategories);
    const activeCategory = React.useMemo(() => {
        if (adCategoryExternalID) {
            return findCategoryByExternalID(categories, adCategoryExternalID);
        }
        return null;
    }, [categories, adCategoryExternalID]);

    const isAdUndertaking =
        !isLimitedAd &&
        !activeProducts &&
        canPostAdForFree &&
        activeCategory?.roles &&
        activeCategory.roles.includes(CategoryRole.ALLOWS_FREE_AD_COMMISSION_FEE);

    return isAdUndertaking;
};

export default useIsAdUndertaking;
