import * as React from 'react';

/**
 * Docs: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
const useIsPageVisible = () => {
    const [isVisible, setVisible] = React.useState(document.visibilityState === 'visible');

    React.useEffect(() => {
        const handleVisibilityChange = () => {
            setVisible(document.visibilityState === 'visible');
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        handleVisibilityChange();

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            handleVisibilityChange();
        };
    }, []);

    return isVisible;
};

export default useIsPageVisible;
