import { RouteNames } from 'strat/routes';

import type { LiteUserDTO, AgencyUserDTO } from 'horizontal/dtos';
import { useIsAnyTypeOfAgent } from 'horizontal/agent/state';

import { useMyProfileDetails, useMyProfileIsLoaded } from '../state';

type ProfileType = {
    profile: LiteUserDTO | AgencyUserDTO | null;
    isAgency: boolean;
    routeName: string | null;
};

const useMyProfile = (): ProfileType => {
    const isAgent = useIsAnyTypeOfAgent();
    const isLoaded = useMyProfileIsLoaded();
    const profile = useMyProfileDetails();

    if (isLoaded && profile) {
        return {
            profile,
            isAgency: isAgent,
            routeName: isAgent ? RouteNames.AGENCY_PROFILE : RouteNames.SELLER_PROFILE,
        };
    }

    return {
        profile: null,
        isAgency: isAgent,
        routeName: null,
    };
};

export default useMyProfile;
