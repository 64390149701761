import * as React from 'react';
import { Trans } from '@lingui/macro';
import { PopUp } from 'strat/modal';
import IconAlert from '@app/assets/icons/iconAlert.svg';
import { Text } from 'strat/components';

import styles from './styles/adExpiredPopup.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (visible: boolean) => void;
};

const AdExpiredPopup = ({ visible, onVisibilityChanged }: Props) => (
    <PopUp
        className={styles.container}
        visible={visible}
        onVisibilityChanged={onVisibilityChanged}
        withoutCloseButton
    >
        <IconAlert className={styles.alertIcon} />
        <Text.Regular>
            <Trans>This ad is no longer available</Trans>
        </Text.Regular>
    </PopUp>
);

export default AdExpiredPopup;
