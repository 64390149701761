import * as React from 'react';
import { useDispatch } from 'react-redux';

import { withGTMCommuteSurvey } from 'strat/gtm';
import type { GTMCommuteSurveyTrackingProps } from 'strat/gtm';
import { Dialog } from 'strat/modal';
import {
    getItem as getItemFromLocalStorage,
    setItem as setItemInLocalStorage,
} from 'strat/misc/localStorage';
import { setCommuteActive } from 'strat/search/state/mapBasedSearch';
import { showSearchResults } from 'strat/search/state';

import CommuteSurveySuccessDialog from './commuteSurveySuccessDialog';
import CommuteSurveyContent from './commuteSurveyContent';

type Props = GTMCommuteSurveyTrackingProps & {
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
    commuteSurveyKey: string;
};

const CommuteSurveyDialog = ({
    visible,
    onVisibilityChanged,
    commuteSurveyKey,
    trackCommuteSurveyResponse,
    trackCommuteSurveyImpression,
}: Props) => {
    const dispatch = useDispatch();
    const [completed, setCompleted] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    React.useEffect(() => {
        const completedCommuteSurvey = getItemFromLocalStorage(commuteSurveyKey);
        if (completedCommuteSurvey) {
            setCompleted(true);
            setSubmitted(true);
        } else {
            setCompleted(false);
        }
    }, [commuteSurveyKey]);

    const onSurveyVisibilityChanged = () => {
        dispatch(setCommuteActive(false));
        dispatch(showSearchResults());
        onVisibilityChanged(false);
        trackCommuteSurveyImpression();
        setItemInLocalStorage(commuteSurveyKey, true);
    };

    const onSubmit = (value: string, freeTextInput: string) => {
        trackCommuteSurveyResponse(value, freeTextInput);
        setSubmitted(true);
    };

    const showSurvey = CONFIG.build.STRAT_ENABLE_COMMUTE_EXIT_SURVEY && visible && !completed;

    return (
        <Dialog
            dismissible
            visible={showSurvey || false}
            onVisibilityChanged={onSurveyVisibilityChanged}
        >
            {submitted ? (
                <CommuteSurveySuccessDialog onVisibilityChanged={onSurveyVisibilityChanged} />
            ) : (
                <CommuteSurveyContent
                    onSubmit={onSubmit}
                    commuteSurveyKey={commuteSurveyKey}
                    onVisibilityChanged={onSurveyVisibilityChanged}
                />
            )}
        </Dialog>
    );
};

export default withGTMCommuteSurvey(CommuteSurveyDialog);
