import { Filter } from '@sector-labs/fe-search-redux/filters';

import fetchFromElasticSearch from './fetchFromElasticSearch';

type PropertyData = {
    purposeID: number;
    propertyTypeID: number;
    bedrooms: number;
    locationID?: number;
    areaID?: number;
    yearQuarter?: number;
};

const propertyToAttributeMap = Object.freeze({
    purposeID: 'purpose_id',
    propertyTypeID: 'property_type_id',
    bedrooms: 'bedrooms',
    locationID: 'location_id',
    areaID: 'area_id',
    yearQuarter: 'quarter',
});

const fetchFromPopularityTrends = (
    indexName: string,
    propertyData: PropertyData,
    fetchingOptions: any = {},
    extraFilters: Array<Filter> = [],
): Promise<Array<any>> =>
    fetchFromElasticSearch(
        indexName,
        propertyData,
        propertyToAttributeMap,
        fetchingOptions,
        extraFilters,
    ).then((response) => response.hits);
export default fetchFromPopularityTrends;
