import * as React from 'react';
import { Flex } from 'strat/components';

import { AGENCY_CHATS_ROLE_FILTER, ALL_CHATS_ROLE_FILTER } from 'horizontal/chat/constants';
import type { ChatAdXID, ChatRoomsQuery } from 'horizontal/chat/types';

import Content from './content';
import Header from './header';
import styles from './styles/inbox.cssm';

type Props = {
    readonly pageSize: number;
    readonly agencyMode?: boolean;
    readonly adXID?: ChatAdXID;
    renderListItem?: any;
};

const Inbox = ({ pageSize, agencyMode, adXID, renderListItem }: Props) => {
    const roleFilter = agencyMode ? AGENCY_CHATS_ROLE_FILTER : ALL_CHATS_ROLE_FILTER;
    const [query, setQuery] = React.useState<ChatRoomsQuery>({ roles: roleFilter, adXID });

    return (
        <Flex column className={styles.container}>
            {!agencyMode ? <Header /> : null}
            <Content
                query={query}
                setQuery={setQuery}
                pageSize={pageSize}
                renderListItem={renderListItem}
            />
        </Flex>
    );
};

export default Inbox;
