import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { PostAdSuccessMessage } from 'horizontal/adCreation';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';
import type { FullAd, ProductPackageOffer, ProductPurchase } from 'horizontal/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly ad?: FullAd | null | undefined;
    readonly productPurchases: Array<ProductPurchase> | null | undefined;
    readonly productOffers: Array<ProductPackageOffer> | null | undefined;
    readonly suppressTrigger?: boolean;
};

const PostAdSuccessPageDesktop = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
            <GTMDataLayer pageType={PageType.ADD_LISTING_SUCCESS} />
        </>
    ),
    body: ({ ad, productPurchases, productOffers, suppressTrigger }: Props) => (
        <Flex column stretchHeight>
            <Header />
            <PageWidthWrapper>
                <PostAdSuccessMessage
                    ad={ad}
                    productPurchases={productPurchases}
                    productOffers={productOffers}
                    suppressTrigger={suppressTrigger}
                />
            </PageWidthWrapper>
        </Flex>
    ),
} as const;

export default PostAdSuccessPageDesktop;
