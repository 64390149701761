import * as React from 'react';
import { Flex, LoadingSpinner } from 'strat/components';

import { ChatRoomReadOnlyReason } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';

import ConversationContent from './conversationContent';
import { ReadonlyConversationContent } from './readonlyConversationContent';

const Conversation = () => {
    const sdk = useChatSDK();
    const chatRoom = sdk.useActiveChatRoom();
    const disabledInfo = sdk.useRoomDisabledInfo(chatRoom);

    const readonly = chatRoom?.readOnly === ChatRoomReadOnlyReason.READONLY_ROLE;

    if (!chatRoom) {
        return (
            <Flex justifyCenter fillContainer alignCenter>
                <LoadingSpinner dark />
            </Flex>
        );
    }

    return (
        <>
            {readonly ? (
                <ReadonlyConversationContent disabledInfo={disabledInfo} chatRoom={chatRoom} />
            ) : (
                <ConversationContent disabledInfo={disabledInfo} chatRoom={chatRoom} />
            )}
        </>
    );
};

export default Conversation;
