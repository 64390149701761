import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { ScheduleScope } from 'strat/scheduler';

const trackFreeTextSearch = (value: string, categoryVars = {}) =>
    trigger(
        Triggers.FREE_TEXT_SEARCH,
        {
            search_keyword: value,
            view_section: ViewSections.HEADER,
            ...categoryVars,
        },
        ScheduleScope.AFTER_RENDER,
    );

const trackFreeTextSearchHitClick = (value: string, categoryVars = {}) =>
    trigger(
        Triggers.FREE_TEXT_SEARCH_HIT_CLICK,
        {
            search_keyword: value,
            view_section: ViewSections.HEADER,
            ...categoryVars,
        },
        ScheduleScope.AFTER_RENDER,
    );

const trackSortChange = (sortOption: string) =>
    trigger(
        Triggers.SORTING_CHANGE,
        {
            filter_name: 'sort-type',
            filter_value: sortOption,
            view_section: ViewSections.BODY,
        },
        ScheduleScope.AFTER_RENDER,
    );

const trackShowVerifiedAds = () => trigger(Triggers.VERIFIED_ADS_SORT);

const trackFilterChange = (name: string, value: any) =>
    trigger(
        Triggers.FILTER_CHANGE,
        {
            filter_name: name,
            filter_value: value,
            view_section: ViewSections.FILTER,
        },
        ScheduleScope.AFTER_RENDER,
    );

const trackLoadMore = (
    pageNumber: number,
    totalPageCount: number,
    viewSection: Values<typeof ViewSections>,
    featuredAdsCount: string,
) =>
    trigger(Triggers.CLICK_LOAD_MORE, {
        page_number: pageNumber,
        page_count: totalPageCount,
        view_section: viewSection,
        total_featured: featuredAdsCount,
    });

const trackTrendingSearches = (category: string) =>
    trigger(Triggers.CLICK_TRENDING_SEARCHES, { value: category });

const trackPopularSearches = (category: string) =>
    trigger(Triggers.CLICK_POPULAR_SEARCHES, { value: category });

export {
    trackFreeTextSearch,
    trackFreeTextSearchHitClick,
    trackSortChange,
    trackShowVerifiedAds,
    trackFilterChange,
    trackLoadMore,
    trackTrendingSearches,
    trackPopularSearches,
};
