//@ts-nocheck
/**
 * Action to take when clicking in the middle of the slideshow.
 */
enum ClickBehavior {
    TOGGLE_ARROWS = 'TOGGLE_ARROWS',
    FULL_SCREEN = 'FULL_SCREEN',
    NEXT = 'NEXT',
}

export default ClickBehavior;
