import { t } from '@lingui/macro';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { useIsPasswordlessLogin } from 'strat/user/session';
import type { AppDispatch } from 'strat/state';

import {
    selectUserSettings,
    selectUserSettingsError,
    selectUserSettingsFetchError,
    selectUserSettingsLoaded,
    selectUserSettingsLoading,
    selectUserSettingsOperationParams,
    selectUserSettingsSaveError,
} from './selectors';
import { fetchUserSettings, saveUserSettings } from './state';
import type { UserSettings, UserSettingsOperationParams } from './types';

export const useUserSetting = (key: keyof UserSettings): boolean | null | undefined => {
    const userSettings = useSelector(selectUserSettings);

    return userSettings ? userSettings[key] : null;
};

export const useFetchUserSettings = () => {
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback(() => dispatch(fetchUserSettings), [dispatch]);
};

export const useSaveUserSettings = () => {
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback(
        (userSettings: Partial<UserSettings>) => dispatch(saveUserSettings(userSettings)),
        [dispatch],
    );
};

export const useUserSettingsLoading = (): boolean => useSelector(selectUserSettingsLoading);

export const useUserSettingsLoaded = (): boolean => useSelector(selectUserSettingsLoaded);

// @ts-expect-error - TS2322 - Type 'FetchError | null' is not assignable to type 'Error | null'.
export const useUserSettingsError = (): Error | null => useSelector(selectUserSettingsError);

export const useUserSettingsFetchError = (): Error | null =>
    // @ts-expect-error - TS2322 - Type 'FetchError | null' is not assignable to type 'Error | null'.
    useSelector(selectUserSettingsFetchError);

export const useUserSettingsSaveError = (): Error | null =>
    // @ts-expect-error - TS2322 - Type 'FetchError | null' is not assignable to type 'Error | null'.
    useSelector(selectUserSettingsSaveError);

export const useUserSettingsOperationParams = (): UserSettingsOperationParams =>
    useSelector(selectUserSettingsOperationParams);

export const useChangePasswordTitle = (): string => {
    const i18n = useI18n();
    const isPasswordlessLogin = useIsPasswordlessLogin();

    const changePasswordTitle = React.useMemo(
        () => (isPasswordlessLogin ? t(i18n)`Create password` : t(i18n)`Change password`),
        [i18n, isPasswordlessLogin],
    );

    return changePasswordTitle;
};
