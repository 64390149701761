import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import type { ProductPackageOffer } from 'horizontal/types';
import { ProductType, ProductSection } from 'horizontal/types';

import { getProductSection } from '../utils';

type GroupedPackages = {
    postMoreAndFeature: ProductPackageOffer[];
    postMoreAndFeatureEqualDuration: ProductPackageOffer[];
    postMoreAndBoost: ProductPackageOffer[];
    postMoreAndBoostNonDaily: ProductPackageOffer[];
    postMoreAndBoostEqualDuration: ProductPackageOffer[];
    postMoreAndBoostNonDailyEqualDuration: ProductPackageOffer[];
    postMoreAds: ProductPackageOffer[];
    eliteAds: ProductPackageOffer[];
    featureAds: ProductPackageOffer[];
    boostToTop: ProductPackageOffer[];
    autoBoostToTopDaily: ProductPackageOffer[];
    autoBoostToTopNonDaily: ProductPackageOffer[];
};

type UseBusinessPackages = GroupedPackages & {
    loading: boolean;
};

const useBusinessPackages = (productOffers: ProductPackageOffer[] = []): UseBusinessPackages => {
    const { products } = useSelector(selectRouteParams);
    const productsTypes =
        products?.length > 0 ? (products.split('_and_') as Values<typeof ProductType>[]) : null;
    const showVasAndLimitProduct = !(
        productsTypes?.length && !productsTypes.includes(ProductType.LIMIT_BUMP)
    );
    const offers: GroupedPackages = {
        postMoreAndFeature: [],
        /**
         * if the Limit product duration and the Feature duration are equal
         * then these will be grouped together in their own section also.
         */
        postMoreAndFeatureEqualDuration: [],
        /**
         * postMoreAndBoost will hold packages that has AutoBoost with
         * a frequency of daily. While postMoreAndBoostNonDaily will be
         * for the case the AutoBoost is not on daily basis (2,3,5...etc)
         */
        postMoreAndBoost: [],
        postMoreAndBoostNonDaily: [],
        /**
         * if the Limit product duration and the Boost duration are equal
         * then these will be grouped together in their own section also.
         */
        postMoreAndBoostEqualDuration: [],
        postMoreAndBoostNonDailyEqualDuration: [],
        postMoreAds: [],
        eliteAds: [],
        featureAds: [],
        boostToTop: [],
        autoBoostToTopDaily: [],
        autoBoostToTopNonDaily: [],
    };

    productOffers.forEach((offer) => {
        const { productItems } = offer.productPackage;
        /**
         * Limit product that is mixed with a VAS (like boost or featured)
         * is not considered a VAS, this is why we shouldn't show them in
         * the Select VAS Multiple packages.
         * For context, the Select Multiple packages can show 1 of those 3:
         * 1- Show all packages (VAS + Limits)
         * 2- Show only VAS <-- this is where we don't want to show Limits
         * 3- Show only Limits
         */
        if (productItems.length === 2 && showVasAndLimitProduct) {
            switch (getProductSection(productItems)) {
                case ProductSection.postMoreAndFeature: {
                    const featuringDuration = productItems.find(
                        (product) => product.type === ProductType.FEATURED,
                    )?.duration;
                    const limitDuration = productItems.find(
                        (product) => product.type === ProductType.LIMIT_BUMP,
                    )?.duration;

                    if (featuringDuration === limitDuration) {
                        offers.postMoreAndFeatureEqualDuration.push(offer);
                        break;
                    }
                    offers.postMoreAndFeature.push(offer);
                    break;
                }
                case ProductSection.postMoreAndBoost: {
                    const frequency = productItems.find(
                        (product) => product.type === ProductType.AUTO_REFRESH_AD,
                    )?.frequency;
                    const boostDuration = productItems.find(
                        (product) => product.type === ProductType.AUTO_REFRESH_AD,
                    )?.duration;
                    const limitDuration = productItems.find(
                        (product) => product.type === ProductType.LIMIT_BUMP,
                    )?.duration;
                    if (frequency !== 1) {
                        if (boostDuration === limitDuration) {
                            offers.postMoreAndBoostNonDailyEqualDuration.push(offer);
                            break;
                        }
                        offers.postMoreAndBoostNonDaily.push(offer);
                        break;
                    }
                    if (boostDuration === limitDuration) {
                        offers.postMoreAndBoostEqualDuration.push(offer);
                        break;
                    }
                    offers.postMoreAndBoost.push(offer);
                    break;
                }
                default:
                    break;
            }
        }
        if (productItems.length === 1) {
            const productType = productItems[0]?.type;
            if (productType === ProductType.LIMIT_BUMP) {
                offers.postMoreAds.push(offer);
            } else if (productType === ProductType.FEATURED) {
                offers.featureAds.push(offer);
            } else if (productType === ProductType.REFRESH) {
                offers.boostToTop.push(offer);
            } else if (
                productType === ProductType.AUTO_REFRESH_CATEGORY ||
                productType === ProductType.AUTO_REFRESH_AD
            ) {
                if (productItems[0].frequency !== 1) {
                    offers.autoBoostToTopNonDaily.push(offer);
                } else {
                    offers.autoBoostToTopDaily.push(offer);
                }
            } else if (productType === ProductType.ELITE) {
                offers.eliteAds.push(offer);
            }
        }
    });

    return {
        ...offers,
        loading: !productOffers.length,
    };
};

export default useBusinessPackages;
