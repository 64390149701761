import * as React from 'react';
import { useFlatCategoryFields } from '@app/categoryFields';
import { sortByGroupIndex } from 'strat/util';
import { CategoryFieldFilterValue } from 'strat/types';

import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import { useCategoryFilterValue } from 'horizontal/search/category';
import formatEnumArrayValue from 'horizontal/ad/formatEnumArrayValue';
import formatEnumValue from 'horizontal/ad/formatEnumValue';

const useFormattedSearchExtraFields = (): { [key: string]: CategoryFieldFilterValue } => {
    /**
     * Returns an object of selected search extra fields where the choices are replaced with the label of the choice
     * Example. A selection of:
     * {
     *     make: "45",
     *     model: ["286"],
     *     year: { min: 1901, max: 2025 },
     * }
     * will become:
     * {
     *     make: "Hyundai",
     *     model: ["Elantra"],
     *     year: { min: 1901, max: 2025 },
     * }
     */
    const activeCategory = useCategoryFilterValue();
    const extraFields = useCurrentSearchRouteParams().extraFields;
    const categoryFields = useFlatCategoryFields(activeCategory?.id, extraFields);

    return React.useMemo(
        () =>
            categoryFields.sort(sortByGroupIndex).reduce((acc, field) => {
                if (!extraFields) {
                    return acc;
                }

                const value = extraFields[field.attribute];
                if (!value) {
                    return acc;
                }

                if (Array.isArray(value)) {
                    const choice = formatEnumArrayValue(value, field);
                    return choice ? { ...acc, [field.attribute]: choice } : acc;
                } else if (typeof value === 'string') {
                    const choice = formatEnumValue(value, field, extraFields, categoryFields);
                    return choice ? { ...acc, [field.attribute]: choice } : acc;
                } else {
                    return { ...acc, [field.attribute]: value };
                }
            }, {}),
        [extraFields, categoryFields],
    );
};

export default useFormattedSearchExtraFields;
