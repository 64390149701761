// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { ParamParser } from 'strat/routes';
import brandingSettings from '@app/branding/settings';

import { MatchParams } from 'horizontal/routes/search/types';
import { LocationNodeData } from 'horizontal/property';
import { SearchRouteParams } from 'horizontal/routes/createSearchURL';
import { selectCategories } from 'horizontal/categories';
import selectMatchedCategory from 'horizontal/routes/search/selectMatchedCategory';
import { AdProduct, SearchPageType } from 'horizontal/types';
import { getPageFromQueryParam } from 'horizontal/routes/pagination';
import { selectDefaultSortOption, selectKeyedSortOptions } from 'horizontal/search/sorting';
import { flattenCategoryFields, selectCategoryFields } from 'horizontal/categoryFields';
import decodeFilters from 'horizontal/routes/decodeURLFilters';

import combinePathAndFilterExtraFields from './combinePathAndFilterExtraFields';
import isCategorySpecifiedInURL from './isCategorySpecifiedInURL';
import getExtraFieldsFromPath from './getExtraFieldsFromPath';

const decodeMatchParams = (
    matchParams: MatchParams,
    getState: GetState,
    location: LocationNodeData | null | undefined,
    pathCategoryFieldsSlugs: string[] | null | undefined,
): SearchRouteParams | null | undefined => {
    const state = getState();
    const categories = selectCategories(state);

    const category = selectMatchedCategory(categories, matchParams);
    if (isCategorySpecifiedInURL(matchParams) && !category) {
        return null;
    }

    // @ts-expect-error - TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const locationRadius = parseInt(matchParams.locationRadius, 10);

    let freeTextQuery = matchParams.freeTextQuery;
    if (freeTextQuery) {
        freeTextQuery = freeTextQuery.replace(/-/g, ' ');
    }

    // @ts-expect-error - TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const minPhotoCountAsNumber = parseInt(matchParams.minPhotoCount, 10);
    if (matchParams.minPhotoCount && (isNaN(minPhotoCountAsNumber) || minPhotoCountAsNumber < 0)) {
        return null;
    }

    const product = matchParams.product as AdProduct;
    if (product && !Object.values(AdProduct).includes(product)) {
        return null;
    }

    // @ts-expect-error - TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string | number'.
    const pageAsNumber = getPageFromQueryParam(matchParams.page);
    const minPageLimit = brandingSettings.startPaginationFromPageZero ? 1 : 0;
    if (matchParams.page && (isNaN(pageAsNumber) || pageAsNumber < minPageLimit)) {
        return null;
    }

    const sortOptions = selectKeyedSortOptions(state);
    const sortOption =
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'I18n'. | TS2554 - Expected 1 arguments, but got 2.
        sortOptions[matchParams.sorting || ''] ||
        selectDefaultSortOption(state, category, !!freeTextQuery);

    const pagetype = matchParams.pagetype as Values<typeof SearchPageType>;
    let remarketingAdID = undefined;
    if (pagetype == SearchPageType.REMARKETING) {
        remarketingAdID = matchParams.remarketingAdID;
    }

    const categoryFields = selectCategoryFields(state, category?.id);
    const flatCategoryFields = flattenCategoryFields(categoryFields, {});
    const extraFieldsFromFilter = matchParams.filter
        ? decodeFilters(flatCategoryFields, matchParams.filter)
        : {};
    const extraFieldsFromPath = getExtraFieldsFromPath(pathCategoryFieldsSlugs, categoryFields);
    if (extraFieldsFromPath === null) {
        return null;
    }
    const extraFields = combinePathAndFilterExtraFields(extraFieldsFromFilter, extraFieldsFromPath);

    const agencyTier = matchParams.tier ? ParamParser.numberList(matchParams.tier) : [];
    const geoPoint = matchParams.geoPoint ? ParamParser.numberList(matchParams.geoPoint) : [];

    return {
        category,
        location,
        locationRadius,
        freeTextQuery,
        minPhotoCount: minPhotoCountAsNumber,
        product,
        page: pageAsNumber,
        sortOption,
        pagetype,
        remarketingAdID,
        extraFields,
        marketingParams: {
            agencyTier: agencyTier,
        },
        geoPoint,
    };
};

export default decodeMatchParams;
