import * as React from 'react';
import { Trans } from '@lingui/macro';
import Recaptcha from 'react-google-recaptcha';

import usePreviousState from 'strat/util/usePreviousState';
import { Text } from 'strat/components';

import styles from './styles/useRecaptcha.cssm';

interface UseRecaptcha {
    token?: string | null;
    isRecaptchaValid: boolean;
    executeRecaptcha: () => void;
    isRecaptchaActive: boolean;
    isRecaptchaCheckbox: boolean;
    renderRecaptcha: () => React.ReactNode;
    resetRecaptcha: () => void;
    onSubmitRecaptchaForm: (isFormValid: boolean) => void;
    isRecaptchaFormValid: (isFormValid: boolean) => boolean;
}

const useRecaptcha = (
    callback: (arg1?: string | null | undefined) => void,
    submitOnCheckbox?: boolean | null,
): UseRecaptcha => {
    const [token, setToken] = React.useState<string | null | undefined>(null);
    const previousToken = usePreviousState(token);
    const ref = React.useRef<Recaptcha>(null);

    const isRecaptchaActive =
        CONFIG.runtime.STRAT_WITH_CAPTCHA && !!CONFIG.runtime.STRAT_RECAPTCHA_KEY;

    const isRecaptchaCheckbox = React.useMemo(
        () => CONFIG.runtime.STRAT_CHECKBOX_CAPTCHA && isRecaptchaActive,
        [isRecaptchaActive],
    );

    const executeRecaptcha = React.useCallback(() => {
        ref.current?.execute();
    }, [ref]);

    const resetRecaptcha = React.useCallback(() => {
        setToken(null);
        ref.current?.reset();
    }, [setToken, ref]);

    React.useEffect(() => {
        if ((!isRecaptchaCheckbox || submitOnCheckbox) && token && token !== previousToken) {
            callback(token);
            resetRecaptcha();
        }
    }, [token, isRecaptchaCheckbox, callback, submitOnCheckbox, previousToken, resetRecaptcha]);

    const onCaptchaComplete = React.useCallback(
        (captchaToken) => {
            setToken(captchaToken);
        },
        [setToken],
    );

    const isRecaptchaValid = isRecaptchaActive && isRecaptchaCheckbox ? !!token : true;

    const onSubmitRecaptchaForm = React.useCallback(
        (isFormValid: boolean) => {
            if (!isFormValid) {
                return;
            }

            if (!isRecaptchaActive) {
                callback();
                return;
            }

            if (isRecaptchaCheckbox && isRecaptchaValid) {
                callback(token);
                resetRecaptcha();
                return;
            }
            executeRecaptcha();
        },
        [
            isRecaptchaActive,
            isRecaptchaCheckbox,
            isRecaptchaValid,
            callback,
            resetRecaptcha,
            executeRecaptcha,
            token,
        ],
    );

    const isRecaptchaFormValid = React.useCallback(
        (isFormValid: boolean) => {
            if (isRecaptchaCheckbox) {
                return isFormValid && isRecaptchaValid;
            }

            return isFormValid;
        },
        [isRecaptchaValid, isRecaptchaCheckbox],
    );

    const renderRecaptcha = React.useCallback(
        () =>
            isRecaptchaActive ? (
                <div className={styles.captchaContainer}>
                    {isRecaptchaCheckbox && submitOnCheckbox && (
                        <Text.Regular bold>
                            <Trans>Please check this box:</Trans>
                        </Text.Regular>
                    )}
                    <Recaptcha
                        ref={ref}
                        size={isRecaptchaCheckbox ? 'normal' : 'invisible'}
                        sitekey={CONFIG.runtime.STRAT_RECAPTCHA_KEY}
                        onChange={onCaptchaComplete}
                    />
                </div>
            ) : null,
        [isRecaptchaActive, ref, isRecaptchaCheckbox, onCaptchaComplete, submitOnCheckbox],
    );

    return {
        token,
        isRecaptchaValid,
        executeRecaptcha,
        isRecaptchaActive,
        isRecaptchaCheckbox,
        renderRecaptcha,
        resetRecaptcha,
        onSubmitRecaptchaForm,
        isRecaptchaFormValid,
    };
};

export default useRecaptcha;
