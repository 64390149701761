import * as React from 'react';
import { Trans } from '@lingui/macro';
import { BackButton, Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Flex, Text } from 'strat/components';

import FawryPaymentDeviceIcon from 'horizontal/assets/icons/fawryPaymentDevice_noinline.svg';

import styles from './styles/fawryPayment.cssm';

type Props = {
    readonly referenceNumber: string;
};

const FawryPayment = ({ referenceNumber }: Props) => (
    <section className={styles.wrapper}>
        <Flex column className={styles.content}>
            <Flex alignCenter>
                <BackButton className={styles.backButton} />
                <Text.XXLarge bold>
                    <Trans>Fawry</Trans>
                </Text.XXLarge>
            </Flex>
            <Flex column alignCenter className={styles.fawryPaymentSection}>
                <img
                    src={FawryPaymentDeviceIcon}
                    alt={'Fawry Payment Device'}
                    className={styles.fawryPaymentDeviceIcon}
                />
                <Text.Large bold>
                    <Trans>Pay using Fawry</Trans>
                </Text.Large>
                <Text.Small>
                    <Trans>
                        Use the code below while paying at any Fawry&apos;s Point of sale across
                        Egypt
                    </Trans>
                </Text.Small>
                <Text.Base bold className={styles.referenceNumberWrapper}>
                    {referenceNumber}
                </Text.Base>
                <Text.Base bold underlined className={styles.myAdsLink}>
                    <Link route={RouteNames.AD_MANAGEMENT}>
                        <Trans>Go to My Ads</Trans>
                    </Link>
                </Text.Base>
                <Text.Small>
                    <Trans>Need help? Call Fawry&apos;s support on 16421</Trans>
                </Text.Small>
            </Flex>
        </Flex>
    </section>
);

export default FawryPayment;
