import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Text, Flex } from 'strat/components';

import type { LiteCategory, LiteHierarchicalLocation } from 'horizontal/types';

import SitemapList from './sitemapList';
import styles from './styles/popularSearchesRegion.cssm';
import { selectPopularSearches } from './state/selectors';

const renderTitle = () => (
    <Text.Large bold>
        <Trans>Regions</Trans>
    </Text.Large>
);

const renderListItems = (locations: Array<LiteHierarchicalLocation>, category: LiteCategory) => {
    return (
        <Flex className={styles.linkContainer}>
            {locations.map((location) => (
                <Link
                    route={RouteNames.SITEMAP}
                    params={{ location, category }}
                    key={location.externalID}
                >
                    {location.name}
                </Link>
            ))}
        </Flex>
    );
};

const PopularSearchesRegions = () => {
    const { category, childLocations } = useSelector(selectPopularSearches);

    if (!childLocations || childLocations.length === 0) {
        return null;
    }

    return (
        <SitemapList
            renderTitle={renderTitle}
            renderListItems={() => renderListItems(childLocations, category)}
        />
    );
};

export default PopularSearchesRegions;
