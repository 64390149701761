import * as React from 'react';

const useSelectedBuyer = () => {
    const [selectedBuyerExternalID, setSelectedBuyerExternalID] = React.useState<any>(undefined);

    const onBuyerSelected = React.useCallback(
        (externalID: string | null) => {
            if (selectedBuyerExternalID === externalID) {
                setSelectedBuyerExternalID(undefined);
                return;
            }

            setSelectedBuyerExternalID(externalID);
        },
        [selectedBuyerExternalID],
    );

    return {
        selectedBuyerExternalID,
        onBuyerSelected,
        noUserSelected: selectedBuyerExternalID === undefined,
    };
};

export default useSelectedBuyer;
