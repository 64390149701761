import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import createSearchURL from '@app/routes/createSearchURL';
import settings from '@app/branding/settings';

import Purpose from 'strat/purpose';
import { ThumbnailSizes, ThumbnailSizeValues, thumbnailURL } from 'strat/image';
import { buildCanonicalURL } from 'strat/routing';
import { serializeLocation } from 'react-true-router/location';
import { Area } from 'strat/i18n';
import { propertyTransformer } from 'strat/property';
import type { PropertyRouteParams } from 'strat/property/url';
import type { PropertyData } from 'strat/property/types';
import { SchemaType, PostalAddressTag } from 'strat/schema/types';
import { getHitWithPrice } from 'strat/searchComponents/seo/pricesRange';
import { Currency } from 'strat/i18n/money';
import type { MetaData } from 'strat/schema/types';

import { computePropertyDetailsURL, getAgencyLogoThumbnail, getAlternateTitle } from './utility';
import { addAgencyURLTag } from './tagsAgency';
import {
    addNameTag,
    addImageTag,
    addPostalAddressTags,
    addTypeTag,
    addDescriptionTag,
    addURLTag,
} from './tagsGeneral';

/**
 * These tags are specific to PROPERTY. This means that they take a property/ad as a parameter to take metadata from it.
 */

const addPropertyURLTag = (metadata: MetaData, property: PropertyData, language: string) => {
    const parameters: PropertyRouteParams = {
        externalID: property.externalID,
        language,
    };
    const propertyUrl = computePropertyDetailsURL(parameters, language);
    addURLTag(metadata, propertyUrl);
};

const addAlternateNameTag = (metadata: MetaData, property: PropertyData, i18n: I18n) => {
    const location = property.location || [];
    if (location.length < 1) {
        return;
    }

    metadata.alternateName = getAlternateTitle(property, i18n);
};

const addPropertyDescriptionTag = (metadata: MetaData, property: PropertyData) => {
    if (!property.description || property.description === '') {
        return;
    }

    const stripHtmlRegex = /<[^>]*>?/gm;

    const description = property.description.replace(stripHtmlRegex, '').replace('\n', '');
    addDescriptionTag(metadata, description);
};

const addPropertyImageTag = (
    metadata: MetaData,
    property: PropertyData,
    size: {
        width: number;
        height: number;
    } = ThumbnailSizeValues[ThumbnailSizes.NORMAL],
) => {
    if (!property.coverPhotoID) {
        return;
    }

    const thumbnailImage = thumbnailURL(property.coverPhotoID, 'jpeg', size);
    addImageTag(metadata, thumbnailImage);
};

const addFloorSizeTag = (metadata: MetaData, property: PropertyData, props: any) => {
    if (!property.area) {
        return;
    }
    const floorSize: Record<string, any> = {};
    addTypeTag(floorSize, [SchemaType.QUANTITATIVE_VALUE]);

    floorSize.value = props.formatLocalUnitsValue(
        props.areaInLocalUnits(property.area, Area.SQM, settings.defaultAreaUnit),
    );
    floorSize.unitText = settings.defaultAreaUnit;

    metadata.floorSize = floorSize;
};

const addRoomTags = (metadata: MetaData, property: PropertyData, i18n: I18n) => {
    if (Number.isInteger(property.rooms)) {
        const rooms: Record<string, any> = {};
        addTypeTag(rooms, [SchemaType.QUANTITATIVE_VALUE]);
        const name = t(i18n)`Bedroom(s)`;
        addNameTag(rooms, name);
        rooms.value = property.rooms.toString(10);
        metadata.numberOfRooms = rooms;
    }
    if (Number.isInteger(property.baths)) {
        metadata.numberOfBathroomsTotal = property.baths.toString(10);
    }
};

const addPriceRangeTag = (metadata: MetaData, hits: Array<PropertyData>, i18n: I18n) => {
    const lowPrice = getHitWithPrice(hits, Math.min);
    const highPrice = getHitWithPrice(hits, Math.max);

    if (lowPrice === null || highPrice === null) {
        return;
    }

    const currency = settings.defaultCurrencyName || settings.baseCurrencyName;
    const currencyAcronym = Currency.acronym(i18n, currency);

    metadata.priceRange = t(i18n)`${lowPrice} ${currencyAcronym} - ${highPrice} ${currencyAcronym}`;
};

const addPriceSpecificationTags = (metadata: MetaData, property: PropertyData) => {
    const priceSpecification: Record<string, any> = {};
    addTypeTag(priceSpecification, [SchemaType.UNIT_PRICE_SPECIFICATION]);

    priceSpecification.price = property.price;
    priceSpecification.priceCurrency = settings.defaultCurrencyName || settings.baseCurrencyName;

    if (Purpose.isForRent(property.purpose)) {
        priceSpecification.unitText = property.rentFrequency;
    }

    metadata.priceSpecification = priceSpecification;
};

const addPropertyParentOrganizationTag = (
    metadata: MetaData,
    property: PropertyData,
    language: string,
) => {
    const agency = property.agency;
    if (!CONFIG.build.ENABLE_AGENCY || !agency) {
        // check for the env var is necessary here because some
        // sites do not have agency enabled
        return;
    }

    const parentOrganization: Record<string, any> = {};
    addTypeTag(parentOrganization, [SchemaType.ORGANIZATION]);
    addNameTag(parentOrganization, agency.name);
    addAgencyURLTag(parentOrganization, agency, language);

    metadata.parentOrganization = parentOrganization;
};

const addOfferedByTags = (metadata: MetaData, property: PropertyData) => {
    if (!CONFIG.build.ENABLE_AGENCY) {
        // check for the env var is necessary here, because some
        // brands do not have agency and agent sections enabled
        return;
    }

    const agency = property.agency;
    const agencyLogoThumbnail = getAgencyLogoThumbnail(agency);
    const agentName = property.contactName;

    if (!agentName || !agencyLogoThumbnail) {
        // both name and image are required attributes for this tag
        return;
    }

    const offeredBy: Record<string, any> = {};
    addTypeTag(offeredBy, [SchemaType.REAL_ESTATE_AGENT]);
    addNameTag(offeredBy, agentName);

    // add agency thumbnail as we do not have access to agent image
    // (agent image is fetched, and it's not present in SSR)
    addImageTag(offeredBy, agencyLogoThumbnail);

    metadata.offeredBy = offeredBy;
};

const addOfferTags = (metadata: MetaData, property: PropertyData, language: string) => {
    const offerTags: Record<string, any> = {};
    addTypeTag(offerTags, [SchemaType.OFFER]);
    offerTags.priceCurrency = settings.defaultCurrencyName || settings.baseCurrencyName;
    addPropertyURLTag(offerTags, property, language);

    if (Purpose.isForRent(property.purpose)) {
        // only set this for renting purpose; default is for sale
        offerTags.businessFunction = 'http://purl.org/goodrelations/v1#LeaseOut';
    }

    // array is required
    metadata.offers = [offerTags];
};

const addContainedInPlaceTag = (
    metadata: MetaData,
    property: PropertyData,
    locationPlaceLevel: number,
    language: string,
) => {
    if (!property.location || property.location.length <= locationPlaceLevel) {
        return;
    }

    const containedInPlaceTag: Record<string, any> = {};
    addTypeTag(containedInPlaceTag, [SchemaType.PLACE]);
    addNameTag(containedInPlaceTag, property.location[locationPlaceLevel].name);

    // build contained in place url
    const params = {
        purpose: property.purpose,
        category: property.category.slice(-1)[0].slug,
        locations: [property.location[locationPlaceLevel]],
    } as const;
    // @ts-ignore
    const url = buildCanonicalURL(serializeLocation(createSearchURL(params)), language);
    addURLTag(containedInPlaceTag, url);

    metadata.containedInPlace = containedInPlaceTag;
};

const addMakesOfferTag = (
    metadata: MetaData,
    hits: Array<PropertyData>,
    language: string,
    otherProps: any,
) => {
    // for each 'hit' (property) from the search results, create an ItemOffered
    const makeOfferList = hits.map((hit) => {
        const property = propertyTransformer(hit, language, CONFIG.build.ENABLE_MERGED_INDEX);

        // we do not want to generate offer for ads that do not have a price
        if (!property.price) {
            return null;
        }

        const makesOffer: any = {};
        addTypeTag(makesOffer, [SchemaType.OFFER]);

        const itemOffered: Record<string, any> = {};
        addTypeTag(itemOffered, [SchemaType.CATEGORY_BASED, SchemaType.PRODUCT], property);
        addNameTag(itemOffered, property.title);
        addPropertyImageTag(itemOffered, property, ThumbnailSizeValues[ThumbnailSizes.NORMAL]);
        addPropertyURLTag(itemOffered, property, language);
        addPropertyDescriptionTag(itemOffered, property);
        addPostalAddressTags(itemOffered, property.location, [PostalAddressTag.ADDRESS_NAME]);
        addFloorSizeTag(itemOffered, property, otherProps);
        addOfferTags(itemOffered, property, language);
        addPriceSpecificationTags(itemOffered.offers[0], property);

        makesOffer.itemOffered = itemOffered;
        return makesOffer;
    });

    // filter only offers which have price
    const makesOffer = makeOfferList.filter((offer) => offer != null);
    if (makesOffer.length === 0) {
        return;
    }
    metadata.makesOffer = makesOffer;
};

export {
    addPropertyURLTag,
    addAlternateNameTag,
    addPropertyDescriptionTag,
    addPropertyImageTag,
    addFloorSizeTag,
    addRoomTags,
    addPriceRangeTag,
    addOfferTags,
    addPropertyParentOrganizationTag,
    addPriceSpecificationTags,
    addOfferedByTags,
    addMakesOfferTag,
    addContainedInPlaceTag,
};
