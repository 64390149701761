import * as React from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import type { Choice, ChoiceValue } from 'strat/components/types';

import styles from './styles/selectedOptions.cssm';

type Props = {
    readonly selectedValues: Set<ChoiceValue>;
    readonly sortedChoices: Array<Choice<ChoiceValue>>;
    readonly optionsCounts?: number;
};

const SelectedOptions = ({ selectedValues, sortedChoices, optionsCounts = 4 }: Props) => {
    const i18n = useI18n();

    const spreadSelectedValued = React.useMemo(() => {
        return Array.from(selectedValues)
            .slice(0, optionsCounts)
            .map((selectedValue) => {
                const selectedChoices = sortedChoices.filter(
                    (choice) => choice.value === selectedValue,
                );
                return selectedChoices?.map((selectedChoice) => {
                    return (
                        <React.Fragment key={selectedChoice.id}>
                            <span>{selectedChoice.label}</span>
                            <span className={styles.choicesSeparator}>{', '}</span>
                        </React.Fragment>
                    );
                });
            });
    }, [selectedValues, optionsCounts, sortedChoices]);

    return (
        <div className={styles.infoTextContainer}>
            <span className={styles.infoText}>
                <b>
                    <Trans>Selected:</Trans>
                </b>{' '}
                {spreadSelectedValued}
                {selectedValues.size > optionsCounts && (
                    <b>
                        + {selectedValues.size - optionsCounts} {t(i18n)`more`}
                    </b>
                )}
            </span>
        </div>
    );
};

export default SelectedOptions;
