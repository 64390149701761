import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import {
    FloatingDialog,
    Flex,
    FloatingDialogActionButtons,
    LoadingSpinnerOverlay,
    Text,
} from 'strat/components';
import { selectRouteName } from 'strat/routes/selectors';

import { trackCustomerSupport } from 'horizontal/gtm';
import { AdState, AdVirtualState } from 'horizontal/types';
import type { FullAd } from 'horizontal/types';

import useRejectionReason from './useRejectionReason';
import { useSubmitDisableAd } from './removeAd/useDisableAd';
import styles from './styles/learnMoreDialog.cssm';
import RejectionReason from './rejectionReason';
import { getLangaugeSupportLink } from './utils';

type Props = {
    ad?: FullAd;
    onVisibilityChanged: (arg: boolean) => void;
    visible: boolean;
    onSuccess: () => void;
    onCancel: () => void;
};

const LearnMoreDialog = ({ ad, onSuccess, onCancel, ...rest }: Props) => {
    const i18n = useI18n();
    const [rejectionReason, rejectionReasonLoading] = useRejectionReason(ad?.externalID || '');
    const route = useSelector(selectRouteName);
    const language = useSelector(selectLanguage);
    const supportLink = getLangaugeSupportLink(language);

    const { loading, isError, submitAd } = useSubmitDisableAd({
        adExternalID: ad?.externalID || '',
        reason: AdState.DELETED,
        onSuccess,
    });

    if (!ad) {
        return null;
    }

    const textErrorChecker = (text: string) => (isError ? t(i18n)`RETRY` : text);
    const isSoftRejectedAd = ad.virtualState === AdVirtualState.MODERATION_SOFT_REJECT;

    const dialogText = isSoftRejectedAd
        ? textErrorChecker(t(i18n)`EDIT AD`)
        : textErrorChecker(t(i18n)`REMOVE AD`);

    const onClickCustomerSupport = () => {
        trackCustomerSupport(route);
    };

    return (
        <FloatingDialog {...rest} dismissible>
            {loading ? (
                <Flex className={styles.content}>
                    <LoadingSpinnerOverlay visible />;
                </Flex>
            ) : (
                <Flex column className={styles.content}>
                    <Flex justifyCenter>
                        <Text.XLarge bold>
                            <Trans>This ad was not published</Trans>
                        </Text.XLarge>
                    </Flex>
                    <Flex column alignCenter justifyCenter className={styles.reasonContainer}>
                        {rejectionReasonLoading ? (
                            <Flex className={styles.messageContent}>
                                <LoadingSpinnerOverlay visible />
                            </Flex>
                        ) : (
                            <RejectionReason rejectionReason={rejectionReason} />
                        )}
                    </Flex>
                    <Flex>
                        <Text.Regular className={styles.line}>
                            <Trans>Need help? Please contact</Trans>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={supportLink}
                                className={styles.support}
                                onClick={onClickCustomerSupport}
                            >
                                <Trans>customer support</Trans>
                            </a>
                        </Text.Regular>
                    </Flex>
                    {isError ? (
                        <Text.Regular error bold>
                            <Trans>There was a problem. Please retry</Trans>
                        </Text.Regular>
                    ) : null}
                    <FloatingDialogActionButtons
                        primaryButtonAction={isSoftRejectedAd ? onSuccess : submitAd}
                        primaryButtonText={dialogText}
                        secondaryButtonAction={onCancel}
                        secondaryButtonText={t(i18n)`CANCEL`}
                    />
                </Flex>
            )}
        </FloatingDialog>
    );
};

export default LearnMoreDialog;
