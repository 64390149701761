import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { ViewSections } from 'strat/gtm';
import { selectUserRoles } from 'strat/user/session';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';

import { trackBusinessPackagesClick } from 'horizontal/gtm';

export const BusinessPackagesLinkSource = Object.freeze({
    MY_ACCOUNT: 'my-account',
    MY_ADS_BANNER: 'myads-banner',
});

type Props = {
    readonly onClick?: (arg1: React.MouseEvent<any>) => void;
    readonly viewSection?: Values<typeof ViewSections>;
    readonly source?: Values<typeof BusinessPackagesLinkSource>;
    readonly children: React.ReactNode;
};

const BusinessPackagesLink = ({ onClick, viewSection, source, children }: Props) => {
    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trackBusinessPackagesClick(viewSection);
            if (onClick) {
                onClick(event);
            }
        },
        [onClick, viewSection],
    );
    const userRoles = useSelector(selectUserRoles);
    if (isProAgent(userRoles) && !isProAgencyOwner(userRoles)) {
        return null;
    }

    return (
        <Link route={RouteNames.BUSINESS_PACKAGES} params={{ source }} onClick={onTrackedClick}>
            {children}
        </Link>
    );
};

export default BusinessPackagesLink;
