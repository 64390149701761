import * as React from 'react';

const numberOfDisplayedItems = Object.freeze({
    SMALL_TABLET: 3,
    DESKTOP: 6,
    TABLET: 4,
});

// having the default value as 3 doesn't make sense because most Web
// users are in general more than 960px in width, so having 4 is more
// feasible. Because this means the shift that will happen between the
// server render (if the screen was different than TABLET) and the client
// render (if it is DESKTOP or SMALL_TABLET) will be minimal
export const computeDisplayedCategories = (windowWidth: number) => {
    if (windowWidth >= CONFIG.build.DEVICE_WIDTH_DESKTOP) {
        return numberOfDisplayedItems.DESKTOP;
    } else if (windowWidth >= CONFIG.build.DEVICE_WIDTH_TABLET) {
        return numberOfDisplayedItems.TABLET;
    } else if (windowWidth < CONFIG.build.DEVICE_WIDTH_TABLET) {
        return numberOfDisplayedItems.SMALL_TABLET;
    }
    return numberOfDisplayedItems.TABLET;
};

/*
    categories are split into 2 groups:
    1- categories that are shown independently since there
    is space in the horizontal nav bar
    2- categories that are grouped together in one dropdown
    since there is no space left.
*/
const useDisplayedCategories = (windowWidth: number) => {
    const [numberOfDisplayedNavigationItems, setNumberOfDisplayedNavigationItems] =
        React.useState<any>(undefined);

    React.useEffect(() => {
        setNumberOfDisplayedNavigationItems(computeDisplayedCategories(windowWidth));
    }, [windowWidth]);

    return numberOfDisplayedNavigationItems;
};

export default useDisplayedCategories;
