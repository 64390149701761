import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { PaymentAreebaRouteParams } from 'horizontal/routes';

type UseNavigateToPaymentAreeba = (arg1?: PaymentAreebaRouteParams | null | undefined) => void;

const useNavigateToAreebaPayment = (): UseNavigateToPaymentAreeba => {
    const router = useRouter();

    return React.useCallback(
        (params?: PaymentAreebaRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_AREEBA, params);
        },
        [router],
    );
};

export default useNavigateToAreebaPayment;
