import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Input } from 'strat/components';

import CommonPostingFields from './commonPostingFields';

type Props = {
    setFieldValue: (field: keyof FormikValues, value?: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
    onBlur: (arg1: React.SyntheticEvent<any>) => void;
    titleChangedRef?: React.MutableRefObject<boolean>;
};

export const TITLE_MAX_CHARACTERS = 70;

const TitleField = ({ setFieldValue, touched, errors, values, onBlur, titleChangedRef }: Props) => {
    const i18n = useI18n();

    return (
        <Input
            name={'title'}
            title={t(i18n)`Ad title`.concat('*')}
            description={t(
                i18n,
            )`Mention the key features of your item (e.g. brand, model, age, type)`}
            maxLength={TITLE_MAX_CHARACTERS}
            onChange={(value) => {
                setFieldValue(CommonPostingFields.title.attribute, value);

                if (titleChangedRef) {
                    titleChangedRef.current = true;
                }
            }}
            onBlur={(event: React.ChangeEvent<any>) => {
                setFieldValue(
                    CommonPostingFields.title.attribute,
                    (event.target.value || '').trim(),
                );
                onBlur?.(event);
            }}
            value={values.title}
            errorMessage={touched.title && errors.title}
            accepted={touched.title && !errors.title}
            leftSideLabel
            placeholder={t(i18n)`Enter title`}
            lightPlaceholder
            boldLabel
        />
    );
};

export default TitleField;
