import * as React from 'react';
import { Text } from 'strat/components';

import type { ChatRoomDisabledInfo } from 'horizontal/chat/types';

import useDisabledChatMessage from '../useDisabledChatMessage';

import styles from './styles/disabledChatMessage.cssm';

type Props = {
    disabledInfo: ChatRoomDisabledInfo;
};

const DisabledChatMessage = ({ disabledInfo }: Props) => {
    const disabledMessage = useDisabledChatMessage(disabledInfo);
    return <Text.Base className={styles.text}>{disabledMessage}</Text.Base>;
};

export default DisabledChatMessage;
