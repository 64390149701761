import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { useI18n } from 'strat/i18n/language';
import MoreLessButton from 'strat/generic/moreLessButton';
import { selectIsMobile } from 'strat/platform/selectors';
import { SearchElementIdentifiers } from 'strat/search';
import { scrollToElement } from 'strat/navigation/scroll';

import styles from './styles/expandUnitsButton.cssm';

const getHeaderHeight = (isMobile: boolean) => {
    const filtersHeight =
        document.getElementById(
            isMobile
                ? SearchElementIdentifiers.compactSearchPageHeaderId
                : SearchElementIdentifiers.searchPageHeaderId,
        )?.offsetHeight || 0;

    const navBarHeight =
        document.getElementById(SearchElementIdentifiers.projectPageNavBar)?.offsetHeight || 0;

    return filtersHeight + navBarHeight;
};

type Props = {
    isExpanded: boolean;
    setIsExpanded: (expanded: boolean) => void;
    containerElement?: React.RefObject<null | HTMLDivElement>;
};

const ExpandUnitsButton = ({ isExpanded, setIsExpanded, containerElement }: Props) => {
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobile);

    const scrollToElementTop = () => {
        if (!containerElement) {
            return;
        }

        const offset = isMobile ? 84 : 72;
        const headerHeight = getHeaderHeight(isMobile);

        scrollToElement(
            containerElement.current,
            document.documentElement.scrollTop - (headerHeight + offset),
        );
    };

    return (
        <MoreLessButton
            expanded={isExpanded}
            onClick={() => {
                if (isExpanded) {
                    scrollToElementTop();
                }

                setIsExpanded(!isExpanded);
            }}
            className={styles.collapsibleActionButton}
            textMore={t(i18n)`View more`}
            textLess={t(i18n)`View less`}
        />
    );
};

export default ExpandUnitsButton;
