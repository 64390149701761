import * as React from 'react';

/**
 * Properties for {@see DNSPreFetch}.
 */
type Props = {
    urls: Array<string>;
};

/**
 * Renders "dns-prefetch" link tags, which instructs the browser
 * to pre-fetch the DNS records for the specified domains.
 */
const DNSPreFetch = (props: Props): React.ReactElement =>
    // @ts-expect-error - TS2322 - Type 'Element[]' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
    props.urls.map((url) => <link rel="dns-prefetch" href={url} key={url} />);

export default DNSPreFetch;
