import { t } from '@lingui/macro';
import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';

import AdExpiredPopup from './adExpiredPopup';
import PhoneNumberGuardedLink from './phoneNumberGuardedLink';
import useAvailableCTAButtons from './useAvailableCTAButtons';
import DescriptionChatWithSellerButton from './descriptionChatWithSellerButton';

type Props = {
    readonly isAdExpired: boolean;
    readonly adExternalID?: string | null | undefined;
    readonly phoneNumber?: string | null;
};

const DescriptionContactInfo = ({ isAdExpired, adExternalID, phoneNumber }: Props) => {
    const [popupVisible, setPopupVisibility] = React.useState(false);

    const i18n = useI18n();
    const viewPhoneNumberText: string = t(i18n)`View phone number`;
    const { showPhoneNumber } = useAvailableCTAButtons();

    const onViewPhoneNumberClick = () => {
        if (isAdExpired) {
            setPopupVisibility(true);
        }

        return !isAdExpired;
    };

    return (
        <>
            <AdExpiredPopup
                key="adExpiredPopup"
                visible={popupVisible}
                onVisibilityChanged={(showPopup) => setPopupVisibility(showPopup)}
            />
            {phoneNumber || showPhoneNumber ? (
                <PhoneNumberGuardedLink
                    adExternalID={adExternalID}
                    viewSection={ViewSections.SELLER_PROFILE}
                    showButtonText={`(${viewPhoneNumberText})`}
                    onShowButtonClick={onViewPhoneNumberClick}
                    phoneNumber={phoneNumber}
                />
            ) : (
                <DescriptionChatWithSellerButton />
            )}
        </>
    );
};

export default DescriptionContactInfo;
