import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveUser } from 'strat/user/session';
import { useI18n } from 'strat/i18n/language';
import { LoadingSpinner } from 'strat/loadable';
import EMPTY_ARRAY from 'strat/empty/array';

import {
    fetchUserSellingList,
    selectSellingList,
    selectSellingListLoaded,
} from 'horizontal/userOrders/sellingOrders';
import type { AppDispatch } from 'horizontal/state';

import { useDeliveryOrdersNavStatus } from '../hooks';
import { EmptyDeliveryOrders } from '../deliveryOrders';
import { DeliveryRequestStatus } from '../types';

import SellerOrderCard from './sellingOrderCard';

function SellingOrdersList() {
    const i18n = useI18n();
    const activeUser = useSelector(selectActiveUser);
    const userExternalID = activeUser?.externalID;
    const dispatch = useDispatch<AppDispatch>();
    const status = useDeliveryOrdersNavStatus();
    const emptyPageMsg =
        status === DeliveryRequestStatus.ACTIVE
            ? t(
                  i18n,
              )`You don’t have any active orders. Sell now any item that is eligible for delivery & receive your money to your doorstep!`
            : t(
                  i18n,
              )`You don’t have any archived orders. Sell now any item that is eligible for delivery & receive your money to your doorstep!`;
    const sellingList = useSelector(selectSellingList);
    const isSellingListLoaded = useSelector(selectSellingListLoaded);
    const renderSellingOrders = React.useMemo(() => {
        if (!userExternalID) {
            return EMPTY_ARRAY;
        }

        // @ts-expect-error - TS7006 - Parameter 'order' implicitly has an 'any' type.
        return sellingList.map((order) => (
            <SellerOrderCard
                key={order.id}
                adExternalID={order.externalID}
                title={order.title}
                adCreatedAt={order.createdAt}
                sellingOrderPrice={order.extraFields.price}
                coverPhoto={order.coverPhoto}
                userExternalID={userExternalID}
                currentTab={status}
                adSlug={order.slug}
            />
        ));
    }, [sellingList, status, userExternalID]);

    React.useEffect(() => {
        if (userExternalID) {
            dispatch(fetchUserSellingList({ userExternalID, offersStatus: status }));
        }
    }, [dispatch, userExternalID, status]);

    if (!isSellingListLoaded) {
        return <LoadingSpinner />;
    }

    if (!userExternalID || sellingList.length === 0) {
        return <EmptyDeliveryOrders msg={emptyPageMsg} />;
    }

    return renderSellingOrders;
}

export default SellingOrdersList;
