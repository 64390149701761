import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/featuredAgencies.cssm';
import FeaturedAgenciesCore from './featuredAgenciesCore';

const AGENCY_BLOCK_SIZE = 200; // 200px

const GROUP_SIZE = {
    [CONFIG.build.DEVICE_WIDTH_TABLET]: 3,
    [CONFIG.build.DEVICE_WIDTH_DESKTOP]: 4,
    [CONFIG.build.DEVICE_WIDTH_DESKTOP + AGENCY_BLOCK_SIZE]: 5,
    [CONFIG.build.DEVICE_WIDTH_DESKTOP + AGENCY_BLOCK_SIZE]: 6,
} as const;

const computeGroupSize = (windowWidth: number | null | undefined) => {
    if (Number(windowWidth) <= CONFIG.build.DEVICE_WIDTH_TABLET) {
        return 3;
    }

    if (Number(windowWidth) <= CONFIG.build.DEVICE_WIDTH_DESKTOP) {
        return 4;
    }

    if (Number(windowWidth) <= CONFIG.build.DEVICE_WIDTH_DESKTOP + AGENCY_BLOCK_SIZE) {
        return 5;
    }

    if (Number(windowWidth) <= CONFIG.build.DEVICE_WIDTH_DESKTOP + AGENCY_BLOCK_SIZE * 2) {
        return 6;
    }

    return 7;
};

const FeaturedAgencies = () => {
    return (
        <FeaturedAgenciesCore
            groupSize={GROUP_SIZE}
            computeGroupSize={computeGroupSize}
            renderPreviousButton={(previousFunction, canGoPrevious) => {
                const arrowLeftClasses = classNames('arrow left', styles.arrowLeft);
                return (
                    <button
                        onClick={previousFunction}
                        className={styles.button}
                        disabled={!canGoPrevious}
                    >
                        <i className={arrowLeftClasses} />
                    </button>
                );
            }}
            renderNextButton={(nextFunction, canGoNext) => {
                const arrowRightClasses = classNames('arrow right', styles.arrowRight);
                return (
                    <button onClick={nextFunction} className={styles.button} disabled={!canGoNext}>
                        <i className={arrowRightClasses} />
                    </button>
                );
            }}
            compact={false}
        />
    );
};

export default FeaturedAgencies;
