import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { TemplateType } from 'strat/templates';
import { locationHierarchyToString } from 'strat/misc';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { selectUserExternalID } from 'strat/user/session';
import { Flex, Text } from 'strat/components';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import iconEdit from '@app/assets/icons/iconModify_noinline.svg';
import { selectIsMobileLayout } from 'strat/layout/selectors';
import { useIsProfolioUser, useIsStratCreditUser } from 'strat/user';
import { useIsMobileLayout } from 'strat/layout';

import { getRequestablePriceLabel } from 'horizontal/ad/price';
import {
    getMenuCTATypes,
    getMainCTAType,
    getSecondaryCTAType,
} from 'horizontal/adManagement/getAdCTAs';
import { CTAType } from 'horizontal/adManagement/types';
import { selectLocationNameWithParent } from 'horizontal/location';
import { CreationDate } from 'horizontal/adCard';
import { selectCustomH1 } from 'horizontal/urlSpecificSEO/selectors';
import { FavoriteButton } from 'horizontal/favorites';
import { AdVirtualState, AdFormat, MixedAd, FullAd } from 'horizontal/types';
import SocialShareDropdown from 'horizontal/social';
import useAdDetailTemplate from 'horizontal/adDetails/seo/useAdDetailTemplate';
import {
    useAdPriceLabel,
    useAdSecondaryPriceLabel,
    getNegotiableLabel,
    useLocalizedAdTitle,
    useGetDownPaymentLabel,
} from 'horizontal/ad';
import { isAdEditor } from 'horizontal/util';
import IconLocation from 'horizontal/assets/icons/iconLocation.svg';
import { UpdateAdAction } from 'horizontal/adManagement/types';
import AdUtils from 'horizontal/ad/utils';
import useCreditSystemGuard from 'horizontal/authorization/useCreditSystemGuard';
import useRentalPeriod from 'horizontal/adCard/useRentalPeriod';

import ExpiryDate from './expiryDate';
import RequestablePriceTooltip from './requestablePriceTooltip';
import AdAttachmentButtons from './adAttachmentButton';
import PurposeBadge from './purposeBadge';
import styles from './styles/overview.cssm';

type Props = {
    readonly ad: MixedAd;
};

const Overview = ({ ad }: Props) => {
    const i18n = useI18n();
    const adTags = ad?.adTags || [];
    const showDeliveryTag = AdUtils.isDeliverable(ad);
    const isMobile = useSelector(selectIsMobileLayout);
    const { slug, location, createdAt, productRefreshedAt, externalID, timestamp } = ad;
    const customTitle = useSelector(selectCustomH1);
    const renderedTemplate = useAdDetailTemplate(TemplateType.H1, ad);
    const localizedAdTitle = useLocalizedAdTitle(ad);
    const adTitle = customTitle || renderedTemplate || localizedAdTitle;
    const isMobileLayout = useIsMobileLayout();
    const brandName = settings.getAgencyPortalBrandName(i18n);
    const withCreditSystemGuard = useCreditSystemGuard(
        t(i18n)`You can only upgrade ads through ${brandName} Pro.`,
    );

    const loggedUserExternalId = useSelector(selectUserExternalID);
    const isOwnAd = isAdEditor(ad, loggedUserExternalId);

    const fixedPriceLabel = useAdPriceLabel(ad);
    const requestablePriceLabel = getRequestablePriceLabel(ad);
    const priceLabel = fixedPriceLabel || requestablePriceLabel;
    const secondaryPriceLabel = useAdSecondaryPriceLabel(ad);
    const negotiableLabel = getNegotiableLabel(ad);
    const rentalFrequencyLabel = useRentalPeriod(ad);
    const downPaymentLabel = useGetDownPaymentLabel(ad);
    const priceInfoLabel = CONFIG.build.STRAT_ENABLE_DOWN_PAYMENT_REDESIGN
        ? rentalFrequencyLabel || downPaymentLabel
        : null;
    const isProfolioUser = useIsProfolioUser();
    const isStratCreditUser = useIsStratCreditUser();
    const showEditIcon =
        !isProfolioUser &&
        isOwnAd &&
        (getMenuCTATypes(ad).includes(CTAType.EDIT) ||
            getMainCTAType(ad) === CTAType.EDIT ||
            getSecondaryCTAType(ad) === CTAType.EDIT);
    const editRoute = isStratCreditUser ? RouteNames.AGENCY_PORTAL_UPDATE_AD : RouteNames.UPDATE_AD;

    const locationHierarchy = settings.parseLocationNameWithCity
        ? selectLocationNameWithParent(i18n, ad.location)
        : locationHierarchyToString(location, 2, i18n, location.length - 2);
    const shouldShowExpiryDate =
        ad.format === AdFormat.FULL &&
        (ad as FullAd).expiresAt &&
        ad.virtualState &&
        (ad.virtualState === AdVirtualState.FEATURED || ad.virtualState === AdVirtualState.ACTIVE);

    const renderSocials = () => {
        return (
            <>
                <SocialShareDropdown title={adTitle} slug={slug} externalID={externalID} />
                {!isOwnAd && (
                    <div className={styles.iconContainer}>
                        {/* @ts-expect-error - TS2322 - Type '{ ad: MixedAd; viewSection: string; }' is not assignable to type 'IntrinsicAttributes & { ref?: Ref<unknown> | undefined; key?: Key | null | undefined; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } & { ...; }'. */}
                        <FavoriteButton ad={ad} viewSection={ViewSections.BODY} />
                    </div>
                )}
            </>
        );
    };

    const renderEdit = () => {
        if (!showEditIcon) {
            return null;
        }

        return (
            <div onClick={() => withCreditSystemGuard()}>
                <Link
                    data-testid="edit-ad-icon-link"
                    className={styles.editContainer}
                    route={editRoute}
                    params={{
                        externalID: ad.externalID,
                        action: UpdateAdAction.EDIT,
                    }}
                    enabled={!(isMobileLayout && isStratCreditUser)}
                >
                    <img src={iconEdit} alt={'Edit ad'} className={styles.editIcon} />
                </Link>
            </div>
        );
    };

    return (
        <Flex stretchWidth column>
            <Flex justifySpaceBetween wrap className={styles.priceContainer}>
                <Flex>
                    <Flex column>
                        <span
                            className={
                                priceInfoLabel && priceInfoLabel === downPaymentLabel
                                    ? styles.downPaymentPriceLabel
                                    : styles.priceLabel
                            }
                            aria-label="Price"
                        >
                            {priceLabel}
                        </span>
                        {isMobile && priceInfoLabel && (
                            <Flex>
                                <span
                                    className={
                                        priceInfoLabel === downPaymentLabel
                                            ? styles.downPaymentLabel
                                            : styles.rentalFrequencyLabel
                                    }
                                    aria-label="Price Info"
                                >
                                    {priceInfoLabel}
                                </span>
                            </Flex>
                        )}
                    </Flex>
                    {requestablePriceLabel && (
                        <Flex alignEnd>
                            <RequestablePriceTooltip />
                        </Flex>
                    )}
                    {priceInfoLabel && !isMobile && (
                        <Flex alignCenter>
                            <span
                                className={
                                    priceInfoLabel === downPaymentLabel
                                        ? styles.downPaymentLabel
                                        : styles.rentalFrequencyLabel
                                }
                                aria-label="Price Info"
                            >
                                {priceInfoLabel}
                            </span>
                        </Flex>
                    )}
                    {negotiableLabel && !priceInfoLabel && (
                        <span className={styles.negotiableLabel}>{negotiableLabel}</span>
                    )}
                    {!!secondaryPriceLabel && isMobile && (
                        <>
                            <span className={styles.priceSeparator}> / </span>
                            <span className={styles.secondaryPrice}>{secondaryPriceLabel}</span>
                        </>
                    )}
                </Flex>
                <Flex>
                    {renderEdit()}
                    {renderSocials()}
                </Flex>
            </Flex>
            {!isMobile && secondaryPriceLabel && (
                <span className={styles.secondaryPrice}>{secondaryPriceLabel}</span>
            )}
            <h1 className={styles.title}>{adTitle}</h1>
            {!isMobile && CONFIG.runtime.STRAT_ENABLE_AD_DOCUMENT_BUTTONS && (
                <AdAttachmentButtons ad={ad} />
            )}
            {isOwnAd && shouldShowExpiryDate && <ExpiryDate date={(ad as FullAd).expiresAt} />}
            {isMobile && (
                <Flex className={styles.tagsContainer}>
                    <PurposeBadge ad={ad} />
                    {showDeliveryTag && <div className={styles.tag}>{t(i18n)`Delivery`}</div>}
                    {!!adTags.length &&
                        adTags.map((tag) => (
                            <div className={styles.tag} key={tag.name}>
                                {tag.name}
                            </div>
                        ))}
                </Flex>
            )}
            <Flex justifySpaceBetween className={styles.bottomDetails}>
                <Flex>
                    <IconLocation className={styles.locationIcon} />
                    <Text.Regular aria-label="Location">{locationHierarchy}</Text.Regular>
                </Flex>
                <Text.Regular>
                    {/* we should only use timestamp, and remove Math.max part after a while.*/}
                    {/*we just need it now to avoid re-publishing all the ads again.*/}
                    <CreationDate
                        createdAt={timestamp || Math.max(productRefreshedAt || 0, createdAt)}
                    />
                </Text.Regular>
            </Flex>
        </Flex>
    );
};

export default Overview;
