import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import { Category } from 'horizontal/types';
import { sortByDisplayPriority } from 'horizontal/util';

export type AgencyCategoriesFilterParams = {
    agencyExternalID: string;
};

const agencyCategoryFiltersFactory = new FetcherFactory(
    ['agencyPortal', 'agencyCategoryFilters'],
    (params: AgencyCategoriesFilterParams, state: any) => {
        return new StratAPI(state.i18n.language).categories(params).then((data: any) => {
            const sortedCategories = data.data
                .sort(sortByDisplayPriority)
                .map((category: Category) => ({
                    ...category,
                    children: category.children.sort(sortByDisplayPriority),
                }));
            return {
                data: sortedCategories,
                status: data.status,
            };
        });
    },
);

const fetchAgencyCategoryFilters = agencyCategoryFiltersFactory.creator();
const agencyCategoryFiltersReducer = agencyCategoryFiltersFactory.reducer();

export { fetchAgencyCategoryFilters };

export default agencyCategoryFiltersReducer;
