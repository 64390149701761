import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { ViewSections } from 'strat/gtm';

import IconArrowRight from 'horizontal/assets/icons/iconArrowRight.svg';

import CategoryLink from '../categoryLink';

import type { CategoryLinkProps } from './types';
import styles from './styles/nestedCategory.cssm';

type Props = CategoryLinkProps & {
    readonly noWrapText?: boolean;
    readonly isMoreCategoriesItem?: boolean;
};

/*
The purpose is to navigate inside the categories while making sure
that inner categories are hidden by CSS not by JSX conditional
rendering. This means HTML markup will have the anchor tags of
the categories at all times.
*/
const NestedCategory = ({ category, onClick, noWrapText, isMoreCategoriesItem }: Props) => {
    const [showThirdLevel, setShowThirdLevel] = React.useState(false);

    return (
        <>
            {/* The L2 cateogry */}
            <div
                key={category.id}
                className={classNames(styles.category, {
                    [styles.noWrapText]: noWrapText,
                    [styles.relativePosition]: !isMoreCategoriesItem,
                })}
                onMouseEnter={() => setShowThirdLevel(true)}
                onMouseLeave={() => setShowThirdLevel(false)}
            >
                <Flex justifySpaceBetween stretchWidth>
                    <CategoryLink
                        category={category}
                        key={category.id}
                        onClick={onClick}
                        className={classNames(styles.categoryLink, styles.disabled)}
                        viewSection={ViewSections.CATEGORY_DROPDOWN}
                    />
                    <span className={styles.moreCategories}>
                        <IconArrowRight className={classNames(styles.arrow, styles.rightArrow)} />
                    </span>
                </Flex>
                {/* The L3 category */}
                <Flex
                    column
                    className={classNames({
                        [styles.childrenCategoriesOuterContainer]: !isMoreCategoriesItem,
                        [styles.noWrapText]: noWrapText,
                        [styles.displayNone]: !showThirdLevel,
                        [styles.display]: showThirdLevel,
                    })}
                >
                    <div
                        className={classNames({
                            [styles.childrenCategoriesInnerContainer]: !isMoreCategoriesItem,
                        })}
                    >
                        <div
                            key={category.id}
                            className={classNames(styles.childCategory, {
                                [styles.withCustomDimensions]: isMoreCategoriesItem,
                                [styles.noWrapText]: noWrapText,
                            })}
                        >
                            <CategoryLink
                                category={category}
                                key={category.id}
                                onClick={onClick}
                                className={classNames(
                                    styles.categoryLink,
                                    styles.categoryChildrenTopTitle,
                                )}
                                viewSection={ViewSections.CATEGORY_DROPDOWN}
                            >
                                <Trans>{`All in ${category.name}`}</Trans>
                            </CategoryLink>
                        </div>
                        {category.children.map((subcategory) => {
                            return (
                                <div
                                    key={subcategory.id}
                                    className={classNames(
                                        styles.childCategory,
                                        styles.extraHorizontalPadding,
                                        {
                                            [styles.noWrapText]: noWrapText,
                                        },
                                    )}
                                >
                                    <CategoryLink
                                        category={subcategory}
                                        key={subcategory.id}
                                        onClick={onClick}
                                        className={styles.categoryLink}
                                        viewSection={ViewSections.CATEGORY_DROPDOWN}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Flex>
            </div>
        </>
    );
};

export default NestedCategory;
