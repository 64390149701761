import * as React from 'react';

import IconCreditCard from 'horizontal/assets/icons/iconCreditCard.svg';
import IconJazzMobile from 'horizontal/assets/icons/iconJazzCashMobileApp.svg';
import { PaymentSource } from 'horizontal/payment/types';
import IconCashPayment from '@app/assets/icons/iconCashPayment.svg';
import IconWhishPayment from '@app/assets/icons/iconWhishPayment.svg';
import IconOMTPayment from '@app/assets/icons/iconOMTPayment.svg';
import IconEasyPaisaPayment from '@app/assets/icons/iconEasyPaisaPayment.svg';
import IconCashUnitedPayment from '@app/assets/icons/iconCashUnitedPayment.svg';

const useGetPaymentMethodsIcon = (_: boolean) => {
    return React.useCallback(
        ({
            paymentSource,
            className,
        }: {
            paymentSource: Values<typeof PaymentSource>;
            className: string;
        }): React.ReactElement => {
            switch (paymentSource) {
                case PaymentSource.CHECKOUT:
                case PaymentSource.AREEBA:
                case PaymentSource.AREEBA_CARD_USD:
                    return <IconCreditCard className={className} />;
                case PaymentSource.JAZZ_CASH_MOBILE_WALLET:
                    return <IconJazzMobile className={className} />;
                case PaymentSource.CASH:
                    return <IconCashPayment className={className} />;
                case PaymentSource.WHISH:
                    return <IconWhishPayment className={className} />;
                case PaymentSource.OMT:
                    return <IconOMTPayment className={className} />;
                case PaymentSource.CASH_UNITED:
                    return <IconCashUnitedPayment className={className} />;
                case PaymentSource.EASYPAISA:
                    return <IconEasyPaisaPayment className={className} />;
                default:
                    return <IconCreditCard className={className} />;
            }
        },
        [],
    );
};

export default useGetPaymentMethodsIcon;
