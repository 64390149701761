import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { selectUserExternalID } from '@app/user/session';

import Page from 'horizontal/agencyPortal/pages/page';
import { ProductPurchaseVirtualStatus } from 'horizontal/userOrders';
import { fetchAgencyProductPurchases } from 'horizontal/agencyPortal/packages/state';
import { ProductPurchaseStatus } from 'horizontal/packages';
import { fetchUserAgencies, selectUserAgencies } from 'horizontal/agent/state';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';
import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';

type AgencyPortalPackagesRouteParams = {
    readonly purchaseStatus: string;
};

class AgencyPortalPackagesRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_PACKAGES, [
            [
                '^/agencyPortal/packages',
                {
                    name: 'purchaseStatus',
                    pattern: `(?:/(${[
                        ProductPurchaseVirtualStatus.EXPIRED,
                        ProductPurchaseVirtualStatus.SCHEDULED,
                    ].join('|')}))?`,
                },
                '(?:[?#].*)?$',
            ],
        ]);
    }

    createURL(
        { purchaseStatus }: AgencyPortalPackagesRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        let purchaseStatusSlug = '';
        if (
            [ProductPurchaseVirtualStatus.EXPIRED, ProductPurchaseVirtualStatus.SCHEDULED].includes(
                purchaseStatus,
            )
        ) {
            purchaseStatusSlug = `/${purchaseStatus}`;
        }
        return { pathname: `/agencyPortal/packages${purchaseStatusSlug}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context) || ensureHasAccessToStratCredits(context)) {
            return;
        }
        const {
            redux: {
                store: { dispatch, getState },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());

        context.rendering.renderPage(Page.AGENCY_PORTAL_PACKAGES);

        const fetchAgencyPackagesPromise = dispatch(
            fetchUserAgencies({
                userExternalID,
            }),
        )
            .then(() => {
                const userAgencies = selectUserAgencies(getState());
                const agencyExternalID = userAgencies[0]?.externalID;
                const status = context.match.params?.purchaseStatus || ProductPurchaseStatus.ACTIVE;

                if (!agencyExternalID) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
                } else {
                    return dispatch(fetchAgencyProductPurchases({ agencyExternalID, status }));
                }
            })
            .catch(() => {
                context.http.status(404);
                context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
            });

        context.promise.wait(fetchAgencyPackagesPromise);
    }
}

const AgencyPortalPackagesRouteClass = AgencyPortalPackagesRoute;

export { AgencyPortalPackagesRouteClass };

export default new AgencyPortalPackagesRoute();
