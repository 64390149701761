import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

export type FetchAgencyParams = {
    externalID?: string | null | undefined;
};

const factory = new FetcherFactory(
    'agencyProfile',
    ({ externalID }: FetchAgencyParams, state: any) => {
        // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
        return new StratAPI(state.i18n.language).agency(externalID);
    },
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

const fetchAgency = factory.creator();

const agencyReducer = factory.reducer();

const clearAgency = () => ({
    type: factory.actions.clear,
});

const startFetchingAgency = () => ({
    type: factory.actions.start,
});

/**
 * Action creator for updating agency profile
 * information.
 */
const updateAgencyStats = (updatedAgency: any) => ({
    type: factory.actions.success,
    data: updatedAgency,
});

export { fetchAgency, clearAgency, startFetchingAgency, updateAgencyStats };

export default agencyReducer;
