import React from 'react';
import classNames from 'classnames';
import popularLinks from '@app/branding/popularLinks';
import { useI18n } from 'strat/i18n/language';
import { PageWidthWrapper } from 'strat/generic';
import { PopularPropertySection, Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import type { PopularPropertySubsection } from 'strat/home/popularProperty/popularPropertyLinksType';

import styles from './styles/popularProperty.cssm';
import { PopularPropertyWrapper } from './popularPropertyDesktop';

// @ts-expect-error - TS2749 - 'PopularPropertySection' refers to a value, but is being used as a type here. Did you mean 'typeof PopularPropertySection'?
const sectionHasMore = (section: PopularPropertySection, maxItemCount = 5): boolean => {
    const maxSubsectionItemCount = Math.max(
        // @ts-expect-error - TS7006 - Parameter 'subSection' implicitly has an 'any' type.
        ...section.content.map((subSection) => subSection.links.length),
    );
    return maxSubsectionItemCount > maxItemCount;
};

const PopularPropertySectionWrapper = ({
    subSectionTitle,
    subSection,
    className,
    sectionTitleStyle,
}: {
    // @ts-expect-error - TS2749 - 'Link' refers to a value, but is being used as a type here. Did you mean 'typeof Link'?
    subSectionTitle: Link;
    subSection: PopularPropertySubsection;
    className?: string;
    sectionTitleStyle?: string;
}) => {
    const i18n = useI18n();

    return (
        <PopularPropertySection
            title={subSectionTitle}
            titleStyle={classNames(sectionTitleStyle, styles.sectionTitle)}
            elementStyle={styles.element}
            containerStyle={styles.popularPropertySectionContainer}
            // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
            className={className}
            ignoreDefaultTextStyles
        >
            {subSection.links
                .filter((link) => link?.hideForLanguage !== i18n.locale)
                .map(({ category, location, title: linkTitle, url }) => (
                    <Link
                        key={linkTitle}
                        scrollUp
                        route={RouteNames.SEARCH}
                        className={[styles.link, className].join(' ')}
                        params={
                            url
                                ? { basePath: url }
                                : {
                                      category: {
                                          slug: category,
                                      },
                                      location: { slug: location },
                                  }
                        }
                        title={linkTitle}
                    >
                        <span>{linkTitle}</span>
                    </Link>
                ))}
        </PopularPropertySection>
    );
};

const PopularProperty = () => {
    const i18n = useI18n();
    const popularPropertyLinks = popularLinks(i18n);
    const popularLinksContent = popularPropertyLinks.content[0];
    const title = popularPropertyLinks.title;

    return (
        <PageWidthWrapper className={styles.container} label="Popular properties">
            {title && <div className={styles.title}>{title}</div>}
            <div>
                {popularLinksContent.container.map((section, index) => (
                    <PopularPropertyWrapper
                        key={section.title}
                        section={section}
                        containerIndex={index}
                        hardLinks={false}
                    />
                ))}
            </div>
        </PageWidthWrapper>
    );
};

export { PopularPropertySectionWrapper, sectionHasMore };

export default PopularProperty;
