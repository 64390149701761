import * as React from 'react';
import { Text } from 'strat/components';

import styles from './styles/section.cssm';

type Props = {
    readonly title: string;
    readonly children: React.ReactNode;
};

const Section = ({ title, children }: Props) => (
    <section className={styles.section}>
        <Text.Regular bold uppercase>
            {title}
        </Text.Regular>
        {children}
    </section>
);

export default Section;
