import React from 'react';

import noImageIcon from '@app/assets/icons/iconNoImage_noinline.svg';

import styles from './styles/noImage.cssm';

type Props = {
    className?: string;
};

const NoImage = ({ className = styles.image }: Props) => (
    <img src={noImageIcon} alt={'Default image'} className={className} />
);

export default NoImage;
