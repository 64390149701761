import { t } from '@lingui/macro';
import * as React from 'react';
import { ThumbnailSizes } from 'strat/image';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { CoverPhoto, Link } from 'horizontal/adCard';
import type { FullAd } from 'horizontal/types';
import { useAdPhotos, useLocalizedAdTitle } from 'horizontal/ad';
import { formatDate } from 'horizontal/util';
import AdStatePill from 'horizontal/adManagement/statePill';

import extractProductInfo from './extractProductInfo';
import styles from './styles/listEntry.cssm';

const ListEntry = ({ ad, productPurchaseID }: { ad: FullAd; productPurchaseID: number }) => {
    const i18n = useI18n();
    const [coverPhoto] = useAdPhotos(ad);
    const adLocalizedTitle = useLocalizedAdTitle(ad);
    const { allProductConsumptions } = ad;
    let productInfo;
    if (allProductConsumptions) {
        productInfo = extractProductInfo(allProductConsumptions, productPurchaseID);
    }
    if (!productInfo) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Flex className={styles.ad} fillContainer>
                <Flex column className={styles.date} justifyCenter>
                    <Text.Regular>{t(i18n)`Created On`}: </Text.Regular>
                    <Text.Regular bold aria-label="Ad creation date">
                        {formatDate(i18n, ad.createdAt * 1000)}
                    </Text.Regular>
                </Flex>
                <Flex column fillContainer>
                    <Flex className={styles.details} fillContainer alignCenter>
                        <Flex className={styles.image}>
                            <CoverPhoto
                                cover={coverPhoto}
                                size={ThumbnailSizes.TINY}
                                lazyLoad
                                category={ad.category}
                            />
                        </Flex>
                        <Flex className={styles.title} aria-label="Ad title">
                            <Text.Regular bold>{adLocalizedTitle}</Text.Regular>
                        </Flex>
                        <Flex className={styles.field} aria-label="Ad ID">
                            <Text.Regular>{t(i18n)`Ad ID`}: </Text.Regular>
                            <Text.Regular bold>{ad.externalID}</Text.Regular>
                        </Flex>
                        <Flex className={styles.field} aria-label="Applied At">
                            <Text.Regular>{t(i18n)`Applied At`}: </Text.Regular>
                            <Text.Regular bold aria-label="Applied At">
                                {formatDate(i18n, productInfo?.appliedAt * 1000)}
                            </Text.Regular>
                        </Flex>
                        <Flex className={styles.field} aria-label="Expired At">
                            <Text.Regular>{t(i18n)`Expired At`}: </Text.Regular>
                            <Text.Regular bold aria-label="Expired At">
                                {formatDate(i18n, productInfo?.expiresAt * 1000)}
                            </Text.Regular>
                        </Flex>
                        <Flex fillContainer alignCenter>
                            <AdStatePill ad={ad} />
                        </Flex>
                        <Flex fillContainer alignCenter aria-label="View AD">
                            {/* @ts-expect-error - TS2741 - Property 'preloadedAd' is missing in type '{ children: Element; openInNewTab: true; params: { slug: string; externalID: string; }; }' but required in type '{ readonly preloadedAd: MixedAd | null | undefined; }'. */}
                            <Link
                                openInNewTab
                                params={{ slug: ad.slug, externalID: ad.externalID }}
                            >
                                <Flex>
                                    <Text.Regular className={styles.navigator} bold>
                                        {t(i18n)`View`}
                                    </Text.Regular>
                                    <div className={styles.arrow} />
                                </Flex>
                            </Link>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
};

export default ListEntry;
