import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Text } from 'strat/components';

import { UserOrdersSection } from './types';
import styles from './styles/menu.cssm';
import useSectionLinks from './useSectionLinks';

type Props = {
    readonly section: Values<typeof UserOrdersSection> | null | undefined;
};

const Menu = ({ section }: Props) => {
    const sectionLinks = useSectionLinks();

    return (
        <ul className={styles.list}>
            {sectionLinks.map((link) => (
                <li className={styles.listItem} key={link.section}>
                    <Link
                        route={RouteNames.USER_ORDERS}
                        params={{ section: link.section }}
                        onClick={link.event}
                        title={link.name}
                        className={classNames(styles.link, {
                            [styles.selected]: section === link.section,
                        })}
                    >
                        <Text.Large>{link.name}</Text.Large>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default Menu;
