import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { Flex, Text } from 'strat/components';

import DeleteIcon from 'horizontal/assets/icons/iconAddressTrash.svg';
import IconEdit from 'horizontal/assets/icons/iconEditAddress.svg';

import { AddressFormActions } from '../types';
import type { AddressInfo } from '../types';

import styles from './styles/addressItem.cssm';

type Props = {
    readonly setSelectedAddress: (address: AddressInfo) => void;
    address: AddressInfo;
    selectedAddress?: AddressInfo;
    readonly setAddressAction: (action: string) => void;
};

function AddressItem({ setSelectedAddress, setAddressAction, address, selectedAddress }: Props) {
    const i18n = useI18n();
    const language = useSelector(selectLanguage);
    const resolveLocationString = (location: Array<any>) =>
        `${location[2]?.name[language]} ${location[1]?.name[language]}`;

    return (
        <div className={styles.addressItem}>
            <Text.Regular bold block>
                {address.name || t(i18n)`untitled address`}
            </Text.Regular>
            <Flex style={{ justifyContent: 'space-between' }}>
                <Flex justifyCenter className={styles.addressBookDetail}>
                    <div>
                        <input
                            disabled={address.isDefault}
                            type="radio"
                            value={address.id}
                            name="address"
                            checked={address.id === selectedAddress?.id}
                            onChange={() => {
                                setSelectedAddress(address);
                                setAddressAction(AddressFormActions.DEFAULT);
                            }}
                        />
                    </div>
                    <div>
                        <Text.Regular>
                            {[address.addressLineOne, address.addressLineTwo].join(', ')}
                            <br />
                            {resolveLocationString(address.locationPath || [])}
                        </Text.Regular>
                    </div>
                </Flex>
                <Flex className={styles.addressBookActions}>
                    {address.isDefault && <Trans>Default</Trans>}
                    <IconEdit
                        onClick={() => {
                            setSelectedAddress(address);
                            setAddressAction(AddressFormActions.EDIT);
                        }}
                        className={styles.editIcon}
                    />
                    <DeleteIcon
                        onClick={() => {
                            if (!address?.isDefault) {
                                setSelectedAddress(address);
                                setAddressAction(AddressFormActions.DELETE);
                            }
                        }}
                        className={classNames(styles.deleteIcon, {
                            [styles.disabledIcon]: address.isDefault,
                        })}
                    />
                </Flex>
            </Flex>
        </div>
    );
}

export default AddressItem;
