import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useActiveUser } from 'strat/user/session';
import { fetchUserSellingList, selectSellingList } from 'horizontal/userOrders/sellingOrders';
import { fetchUserBuyingList, selectBuyerList } from 'horizontal/userOrders/buyingOrders';
import { DeliveryRequestStatus } from 'horizontal/userOrders/types';
import settings from '@app/branding/settings';

const useEnableDeliveryOrdersMenu = () => {
    const user = useActiveUser();
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (settings.deliverySettings && user) {
            dispatch(
                // @ts-expect-error
                fetchUserBuyingList({
                    offersStatus: DeliveryRequestStatus.ACTIVE,
                }),
            );
            dispatch(
                // @ts-expect-error
                fetchUserSellingList({
                    userExternalID: user.externalID,
                    offersStatus: DeliveryRequestStatus.ACTIVE,
                }),
            );
        }
    }, [dispatch, user]);
    const sellingOrders = useSelector(selectSellingList);
    const buyingOrders = useSelector(selectBuyerList);
    return settings.deliverySettings && (sellingOrders?.length > 0 || buyingOrders?.length > 0);
};

export default useEnableDeliveryOrdersMenu;
