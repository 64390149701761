import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Input } from 'strat/components';

import { ContactInfoFields } from './commonPostingFields';

type Props = {
    setFieldValue: (field: keyof FormikValues, value?: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
    handleBlur: (arg1: React.SyntheticEvent<any>) => void;
};

const ContactInfoNameField = ({ values, setFieldValue, handleBlur, touched, errors }: Props) => {
    const i18n = useI18n();

    return (
        <Input
            type="text"
            name={ContactInfoFields.name.attribute}
            title={t(i18n)`Name`.concat('*')}
            placeholder={t(i18n)`Enter name`}
            onChange={(value) => setFieldValue(ContactInfoFields.name.attribute, value)}
            onBlur={handleBlur}
            value={values[ContactInfoFields.name.attribute]}
            errorMessage={
                touched[ContactInfoFields.name.attribute] &&
                errors[ContactInfoFields.name.attribute]
            }
            accepted={
                touched[ContactInfoFields.name.attribute] &&
                !errors[ContactInfoFields.name.attribute]
            }
            leftSideLabel
            lightPlaceholder
            boldLabel
        />
    );
};

export default ContactInfoNameField;
