import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { selectUserRoles } from 'strat/user/session';
import settings from '@app/branding/settings';
import { ListEntry, LoadingDots } from 'strat/components';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';
import { Link } from 'strat/navigation';
import { trigger, Triggers } from 'strat/gtm';
import RouteNames from 'strat/routes/routeNames';

import { UserWalletsSection } from 'horizontal/userWallet/userWallet';
import iconWallet from '@app/assets/icons/iconWallet_noinline.svg';
import {
    fetchWalletBalance,
    selectWalletBalanceLoading,
    selectWalletBalance,
} from 'horizontal/payment/wallet';
import { useFormattedPrice } from 'horizontal/util';
import type { AppDispatch } from 'horizontal/state';

import styles from './styles/walletLink.cssm';

const renderLeft = () => <img alt={'My Wallet'} src={iconWallet} />;

const WalletLink = ({ onClick }: { onClick: (arg1: React.MouseEvent<any>) => void }) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        dispatch(fetchWalletBalance());
    }, [dispatch]);
    const isBalanceLoading = useSelector(selectWalletBalanceLoading);
    const balance = useSelector(selectWalletBalance);
    const userRoles = useSelector(selectUserRoles);
    const balanceDisplayText = useFormattedPrice(balance);

    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trigger(Triggers.VIEW_WALLET);
            onClick(event);
        },
        [onClick],
    );

    if (isProAgent(userRoles) && !isProAgencyOwner(userRoles)) {
        return null;
    }

    return (
        <Link
            route={RouteNames.WALLET}
            params={{ section: UserWalletsSection.OVERVIEW }}
            title={t(i18n)`${settings.getBrandName(i18n)} Wallet`}
            onClick={onTrackedClick}
            className={styles.link}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <div>
                    <div className={styles.title}>
                        {t(i18n)`${settings.getBrandName(i18n)} Wallet`}
                    </div>
                    <p className={styles.balance}>
                        {t(i18n)`Balance`}:{' '}
                        {isBalanceLoading ? <LoadingDots /> : balanceDisplayText}
                    </p>
                </div>
            </ListEntry>
        </Link>
    );
};

export default WalletLink;
