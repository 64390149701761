import settings from '@app/branding/settings';

/**
 * Computes the field key for a merged index. Under a merged index the field keys are defined
 * as <base_key>_l<_current_language_index>.
 */
const localizedFieldKey = (field: string, language: string, mergedIndex: boolean): string => {
    if (!mergedIndex) {
        return field;
    }

    const langIndex = settings.languages.findIndex((obj) => obj.lang === language);
    return langIndex > 0 ? `${field}_l${langIndex}` : field;
};

export default localizedFieldKey;
