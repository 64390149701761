import * as React from 'react';

import { PropertyPhotoData } from 'strat/property';
import { Flex } from 'strat/components';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';

import styles from './styles/projectTopSlideshow.cssm';

type Props = { photos: Array<PropertyPhotoData> };

type PhotoProps = {
    photo: PropertyPhotoData;
    size: Values<typeof ThumbnailSizes>;
};

const ProjectTopSlideshowPhoto = ({ photo, size }: PhotoProps) => (
    <ThumbnailImage
        pictureClassName={styles.imageContainer}
        className={styles.image}
        imageID={photo.id}
        title={photo.title}
        alt={photo.title}
        size={size}
    />
);

const ProjectTopSlideshow = ({ photos }: Props) => {
    return (
        <div className={styles.container}>
            <Flex className={styles.images}>
                {photos.length > 0 && (
                    <ProjectTopSlideshowPhoto size={ThumbnailSizes.LARGE} photo={photos[0]} />
                )}
                <Flex column className={styles.rightContainer}>
                    {photos.length > 1 && (
                        <ProjectTopSlideshowPhoto
                            size={
                                photos.length === 2 ? ThumbnailSizes.LARGE : ThumbnailSizes.NORMAL
                            }
                            photo={photos[1]}
                        />
                    )}
                    {photos.length > 2 && (
                        <ProjectTopSlideshowPhoto size={ThumbnailSizes.NORMAL} photo={photos[2]} />
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

export default ProjectTopSlideshow;
