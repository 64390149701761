import { useSelector } from 'react-redux';
import { selectRouteName } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';

import { selectIsRemarketingPage } from 'horizontal/search/selectors';

import useLocationFilterValue from './useLocationFilterValue';

const useWithLocationRadiusFilter = (locationLevel = 2): boolean => {
    const routeName = useSelector(selectRouteName);
    const location = useLocationFilterValue();
    const isRemarketingPage = useSelector(selectIsRemarketingPage);

    return (
        CONFIG.build.ENABLE_LOCATION_RADIUS_FILTER &&
        !!location &&
        !!location.geography &&
        location.level >= locationLevel &&
        (routeName === RouteNames.SEARCH || isRemarketingPage)
    );
};

export default useWithLocationRadiusFilter;
