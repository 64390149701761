import url from 'url';

import React, { Component } from 'react';

import { Script } from 'strat/util';

/**
 * Properties for {@see GTMScript}.
 */
type Props = Record<any, any>;

/**
 * Renders a <script> tag which loads the Google Tag Manager (GTM).
 */
class GTMScript extends Component<Props> {
    /**
     * Name of the GTM container to use.
     */
    containerID: string | null | undefined;

    /**
     * Name of the GTM data layer to use.
     */
    dataLayerName: string | null | undefined;

    /**
     * Initializes a new instance of {@see GTMScript}.
     */
    constructor(props: Props) {
        super(props);

        this.containerID = CONFIG.build.GTM_CONTAINER_ID;
        this.dataLayerName = CONFIG.build.GTM_DATA_LAYER_NAME;
    }

    /**
     * Loads the GTM script async.
     */
    componentDidMount(): void {
        if (!this.dataLayerName || !this.containerID) {
            return;
        }

        const scriptURL = {
            protocol: 'https:',
            host: 'www.googletagmanager.com',
            pathname: '/gtm.js',
            query: {
                id: this.containerID,
                l: this.dataLayerName,
            },
        } as const;

        Script.load(url.format(scriptURL));
    }

    /**
     * Renders the initial version of the GTM data layer.
     */
    render() {
        // don't use this if GTM is disabled
        if (!this.dataLayerName || !this.containerID) {
            console.warn('Google Tag Manager disabled, GTM settings not set.');
            return null;
        }

        // this is a simplified version of the snippet on:
        // https://developers.google.com/tag-manager/quickstart
        return (
            <script
                key="gtm-init"
                dangerouslySetInnerHTML={{
                    __html: `
                        window['${this.dataLayerName}'] = window['${this.dataLayerName}'] || [];
                        window['${this.dataLayerName}'].push({
                            'gtm.start': new Date().getTime(),
                            event: 'gtm.js',
                        });
                    `,
                }}
            />
        );
    }
}

export default GTMScript;
