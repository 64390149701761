import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import { Flex, Text } from 'strat/components';
import {
    isProjectOverdue,
    useProjectCategoryNames,
    useProjectCompletionPercentage,
} from 'strat/project';
import { PropertyCompletionStatus } from 'strat/property/types';
import type { ProjectData } from 'strat/project/types';

import CompletionStatusBadge from './completionStatusBadge';
import CompletionPercentageBadge from './completionPercentageBadge';
import styles from './styles/projectTopSectionHeader.cssm';

type Props = {
    isExpanded?: boolean;
    project: ProjectData;
};

const ProjectTopSectionHeader = ({ project, isExpanded = true }: Props) => {
    const categoryNames = useProjectCategoryNames(project.unitCategories);
    const completionPercentage = useProjectCompletionPercentage(project.completionDetails);
    const isOverdue = isProjectOverdue(project);

    return (
        <Flex column className={classNames(styles.header, { [styles.expanded]: isExpanded })}>
            <h1 className={styles.title}>{project.title}</h1>
            {categoryNames && <div className={styles.subtitle}>{categoryNames}</div>}
            <Flex className={styles.status}>
                <Flex className={styles.labels}>
                    {!isOverdue && (
                        <div className={styles.badge}>
                            <CompletionStatusBadge completionStatus={project.completionStatus} />
                        </div>
                    )}
                    {project.completionStatus === PropertyCompletionStatus.OFF_PLAN &&
                        !!completionPercentage &&
                        !isOverdue && (
                            <div className={styles.badge}>
                                <CompletionPercentageBadge percentage={completionPercentage} />
                            </div>
                        )}
                </Flex>
                {isExpanded && (
                    <div className={styles.developerName}>
                        <Trans>
                            {!isOverdue ? <span className={styles.bullet}>&bull;</span> : null} By{' '}
                            <Text.Base bold aria-label="Developer">
                                {project.agency?.name}
                            </Text.Base>
                        </Trans>
                    </div>
                )}
            </Flex>
        </Flex>
    );
};

export default ProjectTopSectionHeader;
