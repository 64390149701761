import * as React from 'react';
import { Image } from 'strat/util';
import { Flex, Text } from 'strat/components';

import styles from './styles/choicePageHeading.cssm';
import type { HeadingImage } from './types';

export type Props = {
    readonly image: HeadingImage;
    readonly title: string;
    readonly subtitle?: string | null | undefined;
    readonly infoStrip?: string | null | undefined;
};

const ChoicePageHeading = ({ image, title, subtitle, infoStrip }: Props) => {
    return (
        <Flex column alignCenter>
            <Image
                className={styles.headingImage}
                alt={image.name}
                image={{
                    source: image.source,
                    sourceWEBP: image.sourceWEBP,
                }}
            />
            <Text.Base className={styles.title} uppercase bold>
                {title}
            </Text.Base>
            {subtitle && <Text.Base className={styles.subtitle}>{subtitle}</Text.Base>}
            {infoStrip && (
                <Text.Base className={styles.infoStrip} uppercase bold>
                    {infoStrip}
                </Text.Base>
            )}
        </Flex>
    );
};

export default ChoicePageHeading;
