import type { FetchState } from 'strat/fetcher';

import type { FavoritesAction } from './actions';
import Actions from './actions';
import inactiveFavoritesFetcherReducer, { defaultState } from './inactiveFavoritesFactory';

/**
 * Reducer for favorites related actions.
 */
const inactiveFavoritesReducer = (
    state: FetchState = defaultState,
    action: FavoritesAction,
): FetchState => {
    switch (action.type) {
        case Actions.SET_INACTIVE_FAVORITES:
            return {
                ...state,
                data: action.inactiveFavorites,
                loaded: true,
            };
        case Actions.ADD_INACTIVE_FAVORITE:
            return {
                ...state,
                data: [action.externalID, ...(state.data || [])],
            };
        case Actions.REMOVE_INACTIVE_FAVORITE:
            return {
                ...state,
                data: [
                    ...(state.data || []).filter(
                        (inactiveFavoriteID: string) => inactiveFavoriteID !== action.externalID,
                    ),
                ],
            };
        default:
            return inactiveFavoritesFetcherReducer(state, action);
    }
};

export default inactiveFavoritesReducer;
