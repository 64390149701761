import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import humanizeDuration from '@sector-labs/humanize-duration';
import { Trans } from '@lingui/macro';
import notificationLogo from '@app/assets/images/brandLogoWithBackground.png';
import { ThumbnailImageWithFallback, ThumbnailSizes } from 'strat/image';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { ListEntry, Text, Flex } from 'strat/components';

import type { Notification as NotificationType } from 'horizontal/types';

import styles from './styles/notification.cssm';

type Props = {
    notification: NotificationType;
    onClick: (event: React.SyntheticEvent<any>) => void;
};

const Notification = ({ notification, onClick }: Props) => {
    const language = useSelector(selectLanguage);

    return (
        <a
            href={notification.url}
            onClick={onClick}
            className={classNames(styles.container, { [styles.unread]: !notification.viewedAt })}
        >
            <ListEntry
                borderless
                renderRight={null}
                renderLeft={() => (
                    <ThumbnailImageWithFallback
                        imageID={notification.image?.id}
                        fallbackUrl={notification.image?.url || notificationLogo}
                        title={notification.text}
                        alt={notification.text}
                        className={styles.thumbnail}
                        size={ThumbnailSizes.TINY}
                    />
                )}
            >
                <Flex column>
                    <Text.Small bold={!notification.readAt}>{notification.text}</Text.Small>

                    <span className={styles.creationDate}>
                        <Text.XSmall>
                            <Trans>
                                {humanizeDuration(
                                    Date.now() - Number(notification.createdAt) * 1000,
                                    {
                                        largest: 1,
                                        language,
                                        round: true,
                                    },
                                )}{' '}
                                ago
                            </Trans>
                        </Text.XSmall>
                    </span>
                </Flex>
            </ListEntry>
        </a>
    );
};

export default Notification;
