// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';

import { SearchPageSearchType } from '../types';

import circleSlottingCreateAdsSearchSettings from './withCircleSlottingType/createAdsSearchSettings';
import groupedCreateAdsSearchSettings from './groupedType/createAdsSearchSettings';
import eachPageCreateAdsSearchSettings from './eachPageType/createAdsSearchSettings';
import paidFirstCreateAdsSearchSettings from './paidFirstType';
import paidFirstSlottedCreateAdsSearchSettings from './paidFirstSlottedType';
import promotedCreateAdsSearchSettings from './promotedType';
import expandedRadiusAdsSearchSettings from './expandedRadius/createAdsSearchSettings';
import type { SearchProps } from './types';

const createAdsSearchSettings = (
    params: SearchProps,
    searchPageType: Values<typeof SearchPageSearchType>,
): SearchSettings => {
    switch (searchPageType) {
        case SearchPageSearchType.CIRCLE_BASED_SLOTTING:
            return circleSlottingCreateAdsSearchSettings(params);
        case SearchPageSearchType.FEATURED_REGULAR_GROUPED:
            return groupedCreateAdsSearchSettings(params);
        case SearchPageSearchType.PAID_FIRST:
            return paidFirstCreateAdsSearchSettings(params);
        case SearchPageSearchType.PROMOTED_ONLY:
            return promotedCreateAdsSearchSettings(params);
        case SearchPageSearchType.PAID_FIRST_SLOTTED:
            return paidFirstSlottedCreateAdsSearchSettings(params);
        case SearchPageSearchType.EXPANDED_RADIUS_PRODUCT_GROUPED:
            return expandedRadiusAdsSearchSettings(params);
        case SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE:
        default:
            return eachPageCreateAdsSearchSettings(params);
    }
};

export default createAdsSearchSettings;
