import type { Store } from 'redux';
import { type SessionData } from '@sector-labs/fe-auth-redux';
import { storeSession } from '@sector-labs/fe-auth-redux/thunk';
import {
    selectSessionStatus,
    selectSessionActiveOtpOperation,
    SessionStatus,
    OtpOperationKey,
} from '@sector-labs/fe-auth-redux/state';

import { logError } from 'strat/error/log';
import type { GlobalState, AppDispatch } from 'strat/state';

import type { KeycloakSiteConfigWithCallbacks } from './keycloakSiteConfig';
import { isKeycloakEnabled } from './config';

export enum KeycloakCrossWindowNotificationKey {
    // User clicked the magic link from the email and completed
    // the login in another tab. We're receiving the session
    // data so that we can let the user continue browsing from
    // this tab as well.
    LINK_LOGIN_COMPLETE = 'strat:keycloak:notification:linkLoginComplete',
    // User logged in with a third party identity provider such
    // as Google/Facebook in a pop-up.  We're receiving the session
    // data so we can finalize the login and persist the session
    // data.
    POPUP_LOGIN_COMPLETE = 'strat:keycloak:notification:popupLoginComplete',
}

interface KeycloakCrossWindowEvent<TData> {
    moment: string;
    data: TData;
}

const handleKeycloakCrossWindowEvent = (
    store: Store<GlobalState>,
    siteConfig: KeycloakSiteConfigWithCallbacks,
    e: StorageEvent,
) => {
    switch (e.key) {
        case KeycloakCrossWindowNotificationKey.LINK_LOGIN_COMPLETE:
        case KeycloakCrossWindowNotificationKey.POPUP_LOGIN_COMPLETE:
            {
                const state = store.getState();

                const status = selectSessionStatus(state);
                const isReadyToLogin = status === SessionStatus.READY_TO_LOGIN;

                if (!isReadyToLogin) {
                    return;
                }

                const otpOperation = selectSessionActiveOtpOperation(state);
                const wasMagicLinkSent = otpOperation?.key === OtpOperationKey.LINK_LOGIN;
                const isMagicLinkLoginComplete =
                    e.key === KeycloakCrossWindowNotificationKey.LINK_LOGIN_COMPLETE;

                if (isMagicLinkLoginComplete && !wasMagicLinkSent) {
                    return;
                }

                try {
                    if (!e.newValue) {
                        throw new Error('Value is empty');
                    }

                    const event = JSON.parse(e.newValue) as KeycloakCrossWindowEvent<SessionData>;
                    (store.dispatch as AppDispatch)(storeSession(siteConfig, event.data));
                } catch (e) {
                    logError({
                        e,
                        msg: 'Failed to parse event data for cross-window Keycloak login complete notification',
                        context: {
                            data: event,
                        },
                    });
                }
            }
            break;

        default:
            return;
    }
};

export const createKeycloakCrossWindowListener = (
    store: Store<GlobalState>,
    siteConfig: KeycloakSiteConfigWithCallbacks,
): void => {
    // Cross-window events are used for social login
    // and email link (magic link) login. Since all
    // of those are enabled by default, enabling
    // Keycloak support also means we need the
    // event listener.
    if (isKeycloakEnabled()) {
        window.addEventListener('storage', (e) => {
            handleKeycloakCrossWindowEvent(store, siteConfig, e);
        });
    }
};

export const sendKeycloakCrossWindowLoginCompleteNotification = (
    key: KeycloakCrossWindowNotificationKey,
    sessionData: SessionData,
): void => {
    if (!window.localStorage) {
        return;
    }

    const event: KeycloakCrossWindowEvent<SessionData> = {
        moment: new Date().toISOString(),
        data: sessionData,
    };

    try {
        localStorage.setItem(key, JSON.stringify(event));
    } catch (e) {
        console.warn('Failed to send cross-window Keycloak login complete notification', e);
    }
};
