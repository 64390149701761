//@ts-nocheck
import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import serialize from 'serialize-javascript';

import { translationURLs } from 'strat/i18n/language/translations';
import { withI18n } from 'strat/i18n/language/withI18n';
import EMPTY_OBJECT from 'strat/empty/object';
import Layout from 'strat/layout';
import LoadingPage from 'strat/pages/loadingPage';
import { connectLanguage } from 'strat/i18n/language';
import { selectExperiments } from 'strat/experiments';
import { selectLayout } from 'strat/layout/selectors';

/**
 * Renders the current page according to the Redux store.
 */
export default (component: ComponentType<any>) => {
    class AppRenderer extends Component {
        constructor(props: any) {
            super(props);

            // we don't have a tablet bundle, fall back to configured layout
            const layout =
                this.props.layout === Layout.TABLET && CONFIG.build.STRAT_TABLET_FALLBACK_LAYOUT
                    ? CONFIG.build.STRAT_TABLET_FALLBACK_LAYOUT
                    : this.props.layout;

            const dir = this.props.rtl ? 'rtl' : 'ltr';

            const originalBundles = global.webpackBundles || window.webpackBundles;

            const entry = `${this.props.bundle}.${layout.toLowerCase()}`;

            if (process.env.IS_SERVER) {
                this.bundles = {
                    'browser.css': originalBundles[entry].css[dir],
                    'browser.js': originalBundles[entry].js,
                    'fonts.js': originalBundles.fonts.js,
                    'lazyLoad.js': originalBundles.lazyLoad.js,
                };

                if (originalBundles[entry].preload) {
                    this.bundles.preloadedStyles = originalBundles[entry].preload.css[dir];
                    this.bundles.preloadedScripts = originalBundles[entry].preload.js;
                }
            } else {
                this.bundles = window.webpackBundles;
            }

            const assignments = {
                'window.state': this.props.state,
                'window.webpackBundles': {
                    'browser.css': {
                        ...this.bundles['browser.css'],
                        contents: null,
                    },
                    'browser.js': {
                        ...this.bundles['browser.js'],
                        contents: null,
                    },
                    'fonts.js': {
                        ...this.bundles['fonts.js'],
                        contents: null,
                    },
                    'lazyLoad.js': {
                        ...this.bundles['lazyLoad.js'],
                        contents: null,
                    },
                },
            } as const;

            this.js = Object.keys(assignments)
                .map((name) => `${name} = ${serialize(assignments[name], { isJSON: true })};\n`)
                .join('\n\n\n');

            this.js +=
                'window.webpackBundles["browser.css"].contents = ' +
                'document.getElementById("browserStyles").textContent.toString();';

            this.js +=
                'window.webpackBundles["fonts.js"].contents = ' +
                'document.getElementById("fontsScript").textContent.toString();';

            this.js +=
                'window.webpackBundles["preloadedStyles"] = ' +
                'Array.from(document.querySelectorAll("[data-preload-type=style]")).map(' +
                'function (el) {' +
                '  return {' +
                '    publicPath: el.dataset.href,' +
                '    contents: el.innerHTML' +
                '  };' +
                '});';

            this.js +=
                'window.webpackBundles["preloadedScripts"] = ' +
                'Array.from(document.querySelectorAll("[data-preload-type=script]")).map(' +
                'function (el) {' +
                '  return {' +
                '    publicPath: el.attributes.href.value,' +
                '    contents: null' +
                '  };' +
                '});';
        }

        render() {
            // not all routes render a page, some routes are
            // just redirecting, and then we don't render anything
            const { pageName, renderingEnabled } = this.props;
            let page;
            if (!renderingEnabled) {
                page = LoadingPage;
            } else if (!pageName) {
                return null;
            } else {
                // try to find the page class in the look up table
                page = this.props.pages[pageName];
            }

            // make sure to let the developer know that we could
            // not find the page, maybe we should render a 404?
            if (!page) {
                console.warn(`Could not find page with name '${pageName}'

                                Did you forget to register '${pageName}'
                                in the PageCollection?`);
                return null;
            }

            const props = {
                ...this.props.pageProps,
                bundles: this.bundles,
                js: this.js,
                i18n: this.props.i18n,
                language: this.props.language,
                catalogs: this.catalogs,
                experiments: this.props.experiments,
                layout: this.props.layout,
                dialogs: this.props.dialogs,
                translationURLs: translationURLs(this.props.language),
            } as const;

            return React.createElement(component, {
                ...props,
                head: page.head(props),
                body: page.body(props),
            });
        }
    }

    return withI18n()(
        connect((state) => ({
            pageName: state.rendering.page,
            pageProps: state.rendering.props || EMPTY_OBJECT,
            layout: selectLayout(state),
            bundle: state.bundle,
            experiments: selectExperiments(state),
            renderingEnabled: state.rendering.enabled,
        }))(connectLanguage(AppRenderer)),
    );
};
