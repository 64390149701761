export default Object.freeze({
    HOME: 'home',
    SEARCH: 'search',
    NOT_FOUND: 'notFound',
    PROPERTY: 'property',
    PROPERTY_INACTIVE: 'propertyInactive',
    PROPERTY_REMARKETING: 'propertyRemarketing',
    REGISTRATION: 'registration',
    INTERNAL_ERROR: 'internalError',
    UNSUBSCRIBE_ALERT: 'unsubscribeAlert',
    STATIC_PAGE: 'staticPage',
    RESET_PASSWORD: 'resetPassword',

    AGENT_DETAIL: 'agentDetail',
    AGENT_REVIEW: 'agentReview',
    AGENT_REVIEW_EMAIL_CONFIRMATION: 'agentReviewEmailConfirmation',

    AGENCY_DETAIL: 'agencyDetail',
    AGENCY_SEARCH: 'agencySearch',
    AGENCY_HOME: 'agencyHome',

    FAVORITES: 'adFavorites',
    TRU_VALUE: 'truValue',
    TRU_VALUE_HOME: 'truValueHome',

    MOBILE_APP_REDIRECT: 'mobileAppRedirect',

    CAPTCHA_CHALLENGE: 'captchaChallenge',

    LOGIN: 'login',
    LOGIN_CALLBACK: 'loginCallback',
    EXPRESS_LOGIN: 'expressLogin',
    LINK_LOGIN_CALLBACK: 'linkLoginCallback',
});
