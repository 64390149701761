import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';

import Expandable from './expandable';

type Props = {
    readonly level: number;
    readonly children: React.ReactNode;
    readonly expandable?: boolean;
};

const TreeNodeList = ({ level, children, expandable }: Props) => {
    const list = expandable ? <Expandable>{children}</Expandable> : children;
    const isRTL = useSelector(selectIsLanguageRTL);
    return (
        <div style={isRTL ? { marginRight: level * 16 } : { marginLeft: level * 16 }}>{list}</div>
    );
};

export default TreeNodeList;
