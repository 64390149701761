import type { I18n } from '@lingui/core';

import type { ProjectData } from './types';
import { projectTransformer } from './projectTransformer';

export interface ProjectsState {
    data: readonly ProjectData[];
    locationSlugFilter: string | null;
    descriptionExpanded: boolean;
    childProjectCount: number | null;
}

enum ProjectActionType {
    STORE = 'PROJECTS/STORE',
    CLEAR = 'PROJECTS/CLEAR',
    EXPAND_DESCRIPTION = 'PROJECTS/EXPAND_DESCRIPTION',
    SET_LOCATION_SLUG_FILTER = 'PROJECTS/SET_LOCATION_SLUG_FILTER',
    SET_CHILD_PROJECT_COUNT = 'PROJECTS/SET_CHILD_PROJECT_COUNT',
}

interface StoreProjectsAction {
    type: ProjectActionType.STORE;
    data: readonly ProjectData[];
    locationSlugFilter: string;
}

interface ClearProjectsAction {
    type: ProjectActionType.CLEAR;
}

interface ExpandDescriptionAction {
    type: ProjectActionType.EXPAND_DESCRIPTION;
    value: boolean;
}

interface SetLocationSlugFilterAction {
    type: ProjectActionType.SET_LOCATION_SLUG_FILTER;
    locationSlugFilter: string | null;
}

interface SetChildProjectCountAction {
    type: ProjectActionType.SET_CHILD_PROJECT_COUNT;
    childProjectCount: number | null;
}

type ProjectAction =
    | StoreProjectsAction
    | ClearProjectsAction
    | SetLocationSlugFilterAction
    | ExpandDescriptionAction
    | SetChildProjectCountAction;

const defaultState: ProjectsState = {
    data: [],
    locationSlugFilter: null,
    descriptionExpanded: false,
    childProjectCount: 0,
};

export const projectsReducer = (
    state: ProjectsState = defaultState,
    action: ProjectAction,
): ProjectsState => {
    switch (action.type) {
        case ProjectActionType.STORE:
            return {
                ...state,
                data: action.data,
                locationSlugFilter: action.locationSlugFilter,
            };

        case ProjectActionType.CLEAR:
            return {
                ...state,
                data: [],
                locationSlugFilter: null,
            };

        case ProjectActionType.EXPAND_DESCRIPTION:
            return {
                ...state,
                descriptionExpanded: action.value,
            };

        case ProjectActionType.SET_LOCATION_SLUG_FILTER:
            return {
                ...state,
                locationSlugFilter: action.locationSlugFilter,
            };

        case ProjectActionType.SET_CHILD_PROJECT_COUNT:
            return {
                ...state,
                childProjectCount: action.childProjectCount,
            };

        default:
            return state;
    }
};

export const storeProjects = (
    data: readonly ProjectData[],
    locationSlugFilter: string,
): StoreProjectsAction => ({
    type: ProjectActionType.STORE,
    data,
    locationSlugFilter,
});

export const storeTransformedProjects = (
    i18n: I18n,
    data: readonly ProjectData[],
    locationSlugFilter: string,
): StoreProjectsAction =>
    storeProjects(
        data.map((project) => projectTransformer(project, i18n.locale)),
        locationSlugFilter,
    );

export const clearProjects = (): ClearProjectsAction => ({
    type: ProjectActionType.CLEAR,
});

export const setProjectDescriptionExpanded = (value: boolean): ExpandDescriptionAction => ({
    type: ProjectActionType.EXPAND_DESCRIPTION,
    value,
});

export const setChildProjectCount = (value: number): SetChildProjectCountAction => ({
    type: ProjectActionType.SET_CHILD_PROJECT_COUNT,
    childProjectCount: value,
});

export const setLocationSlugFilter = (
    locationSlugFilter: string | null,
): SetLocationSlugFilterAction => ({
    type: ProjectActionType.SET_LOCATION_SLUG_FILTER,
    locationSlugFilter,
});
