// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import type { FlatCategoryField } from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';
import { generateValidationSchema as generateBaseValidationSchema } from 'horizontal/fields';

export const generateValidationSchema = (
    i18n: I18n,
    fields: Array<FlatCategoryField>,
    roles?: Array<Values<typeof CategoryRole>>,
) => {
    const baseSchema = generateBaseValidationSchema(i18n, fields, roles);

    // TODO: Replace with object.omit once we update Yup
    return baseSchema.shape({
        name: Yup.string().nullable(),
        phone_number: Yup.string().nullable(),
    });
};
