import { ProductPackageOffer } from 'horizontal/types';
import useConfigurePackagesSections from 'horizontal/packages/useConfigurePackagesSections';

import { PackagesSection, PackagesFlowTypeValues } from './types';

const useConfigureOfferPackagesSections = (
    offerPackages: Array<ProductPackageOffer> | null | undefined,
    flow: PackagesFlowTypeValues,
): Array<PackagesSection<ProductPackageOffer>> | null => {
    return useConfigurePackagesSections(offerPackages, flow, (offer) => offer.productPackage);
};

export default useConfigureOfferPackagesSections;
