export const LimitPackageChoiceType = Object.freeze({
    WAIT: 'wait',
    OFFER: 'offer',
    PURCHASED: 'purchased',
});

export type LimitPackageChoice = {
    id: number;
    name: string;
    type: Values<typeof LimitPackageChoiceType>;
    price?: number | null | undefined;
    discountPrice?: number | null | undefined;
    expiresAt?: string | null | undefined;
    availableQuota?: number | null | undefined;
    description?: string | null | undefined;
    valueProposition?: string | null | undefined;
    label?: string | null | undefined;
    labelColor?: string | null | undefined;
    hasFeaturedProduct?: boolean;
    totalQuotaAssignedToAgents?: number | null;
};
