import * as React from 'react';

export type ChoiceValue = string | number | null | undefined;

export type Choice<T extends ChoiceValue> = {
    readonly id?: number;
    readonly label: string;
    readonly ariaLabel?: string;
    readonly value: T;
    readonly slug?: T;
    readonly displayPriority?: number;
    readonly renderBody?: (arg1: { isSelected: boolean }) => React.ReactElement;
};

export type CategoryChoice<T extends ChoiceValue> = {
    readonly id?: number;
    readonly label: string;
    readonly value: T;
    readonly slug?: T;
    readonly children?: Array<CategoryChoice<T>>;
    readonly renderBody?: (arg1: { isSelected: boolean }) => React.ReactElement;
};

export const ButtonVariant = {
    FILLED: 'filled',
    LIGHT: 'light',
} as const;
