import { CategoryFieldsFilters, StringMap } from 'strat/types';

/**
 * Combines the values of the extra fields taken from path and query params.
 * We can have an extra field in path and as well in query params, if that field has
 * the role INCLUDED_IN_PATHNAME and has a MULTIPLE_CHOICES filter type. We will include
 * only the first value in path, and next ones will be in query params.
 */
const combinePathAndFilterExtraFields = (
    extraFieldsFromFilter: CategoryFieldsFilters,
    extraFieldsFromPath: StringMap<string | string[]>,
) => {
    const commonFieldsAttribute = Object.keys(extraFieldsFromPath).filter(
        (attribute) => !!extraFieldsFromFilter[attribute],
    );
    const commonFields = commonFieldsAttribute.reduce((acc, attribute) => {
        const fieldFromFilterValue = extraFieldsFromFilter[attribute];
        const fieldFromPathValue = extraFieldsFromPath[attribute];
        // multiple choice field values should be always arrays
        if (!Array.isArray(fieldFromFilterValue) || !Array.isArray(fieldFromPathValue)) {
            return acc;
        }
        return {
            ...acc,
            [attribute]: [...fieldFromPathValue, ...fieldFromFilterValue],
        };
    }, {});

    return Object.assign({}, extraFieldsFromFilter, extraFieldsFromPath, commonFields);
};

export default combinePathAndFilterExtraFields;
