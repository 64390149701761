import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Text } from 'strat/components';

import type { Location } from 'horizontal/types';
import { useCurrentLocation } from 'horizontal/location';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { selectCategories } from 'horizontal/categories';

import LocationsListing from './locationsListing';
import usePopularLocations from './usePopularLocations';
import { useDisabledLocations } from './disabledLocations';
import styles from './styles/locationList.cssm';

const PopularLocationsSection = ({
    locations,
    onLocationClicked,
    withArrows,
    selectedIndex,
}: {
    locations: Location[];
    onLocationClicked: (arg1: Location) => void;
    withArrows?: boolean;
    selectedIndex?: number;
}) => {
    const activeLocation = useCurrentLocation();
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);
    const filteredLocations = useDisabledLocations(locations, categories, activeCategory);
    const popularLocations = usePopularLocations(activeLocation, filteredLocations);

    if (
        !CONFIG.runtime.LOCATION_DROPDOWN_DISPLAY_POPULAR_LOCATIONS ||
        popularLocations.length <= 0 ||
        activeLocation.level === 0
    ) {
        return null;
    }

    return (
        <>
            <div className={styles.title}>
                <Text.Small uppercase>
                    <Trans>popular locations</Trans>
                </Text.Small>
            </div>
            <LocationsListing
                locations={popularLocations}
                onLocationClicked={onLocationClicked}
                withArrows={withArrows}
                selectedIndex={selectedIndex}
            />
        </>
    );
};

export default PopularLocationsSection;
