import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { StylelessButton } from 'strat/generic';
import { Flex, Text } from 'strat/components';

import iconList from 'horizontal/assets/icons/iconList_noinline.svg';
import iconGrid from 'horizontal/assets/icons/iconGrid_noinline.svg';

import styles from './styles/layoutSwitcher.cssm';
import useLayout from './useLayout';

const LayoutSwitcher = () => {
    const [selectedLayout, setLayout] = useLayout();

    const className = React.useCallback(
        (layout) =>
            classNames(styles.button, {
                [styles.selected]: layout === selectedLayout,
            }),
        [selectedLayout],
    );

    return (
        <Flex alignCenter className={styles.container}>
            <Text.Regular uppercase bold>
                <Trans>View</Trans>
            </Text.Regular>
            <StylelessButton
                className={className(SearchHitsLayout.LIST)}
                // @ts-expect-error - TS2349 - This expression is not callable.
                onClick={setLayout(SearchHitsLayout.LIST)}
            >
                <img src={iconList} alt={'List layout'} />
            </StylelessButton>
            <StylelessButton
                className={className(SearchHitsLayout.GRID)}
                // @ts-expect-error - TS2349 - This expression is not callable.
                onClick={setLayout(SearchHitsLayout.GRID)}
            >
                <img src={iconGrid} alt={'Grid layout'} />
            </StylelessButton>
        </Flex>
    );
};

export default LayoutSwitcher;
