import React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { CanonicalURL } from 'strat/routing';

import { selectRobotsMetaContent } from 'horizontal/urlSpecificSEO/selectors';

export type Props = {
    url?: string | null;
    alternateURLs?: CanonicalURL[] | null;
};

const CommonHead = ({ url, alternateURLs }: Props) => {
    const i18n = useI18n();
    const robotsMetaContent = useSelector(selectRobotsMetaContent);

    const alternateLinks =
        alternateURLs?.length && alternateURLs.length > 1 ? (
            <>
                {alternateURLs.map(({ alternateURL, language }) => (
                    <link
                        key={`${alternateURL}-${language}`}
                        rel="alternate"
                        href={alternateURL}
                        hrefLang={language}
                    />
                ))}
            </>
        ) : null;

    return (
        <>
            {url ? <link key="canonicalURL" rel="canonical" href={url} /> : null}
            <meta key="Content-Language" httpEquiv="Content-Language" content={i18n.locale} />
            <meta key="X-UA-Compatible" httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            {robotsMetaContent ? (
                <meta key="robots" name="robots" content={robotsMetaContent} />
            ) : null}
            <meta key="cleartype" httpEquiv="cleartype" content="on" />
            <meta key="HandheldFriendly" name="HandheldFriendly" content="True" />
            <meta key="MobileOptimized" name="MobileOptimized" content="320" />
            {alternateLinks}
        </>
    );
};

export default CommonHead;
