import { useSearchSortOption } from '@sector-labs/fe-search-redux/state';

import type { FlatCategoryFieldHierarchy } from 'horizontal/types';
import { PriceType } from 'horizontal/types';
import useFilter from '@app/search/filters/useFilter';
import { SortOptionKey } from '@app/search/sorting';

import useDisplayPriorityFilteredFacetedChoices from './useDisplayPriorityFilteredFacetedChoices';

/**
 * The price type filter is only displayed when the sort option is not by price
 * and there are choices for the price type(s) other than fixed price, and the
 * fixed price choice is analogously removed from the displayed choices.
 * @param field
 */
const useDisplayedPriceTypeFilter = (field: FlatCategoryFieldHierarchy) => {
    const filter = useFilter(field, undefined);
    const choices = useDisplayPriorityFilteredFacetedChoices(field, filter.value || '');
    const sortOption = useSearchSortOption();
    if ([SortOptionKey.LOWEST_PRICE, SortOptionKey.HIGHEST_PRICE].includes(sortOption?.key || '')) {
        return null;
    }

    if (choices.filter((choice) => choice.value !== PriceType.FIXED_PRICE).length === 0) {
        return null;
    }
    return {
        ...field,
        // artificially remove fixed price from the choices since it is not an actual option
        choices: field.choices.filter((choice) => choice.value !== PriceType.FIXED_PRICE),
    };
};

export default useDisplayedPriceTypeFilter;
