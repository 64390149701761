import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Text, ListEntry } from 'strat/components';

import iconBlog from 'horizontal/assets/icons/iconBlog_noinline.svg';

const renderLeft = () => <img alt={'dubizzle Blog'} src={iconBlog} />;

const BlogLink = () => {
    const i18n = useI18n();
    return (
        <a href={t(i18n)`dubizzle_blog_link`} target="_blank noopener noreferrer">
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>
                    <Trans>Blog</Trans>
                </Text.Large>
            </ListEntry>
        </a>
    );
};

export default BlogLink;
