import { useSelector } from 'react-redux';
import { selectSearchLoadingState } from '@sector-labs/fe-search-redux/state';

import { useHasSectionHits } from 'horizontal/search/hits';
import { useShowRemarketedListing } from 'horizontal/search/state';

const useShouldShowHitsAndNoHits = () => {
    const { loading } = useSelector(selectSearchLoadingState);
    const hasHits = useHasSectionHits();
    const showRemarketedListing = useShowRemarketedListing();
    const shouldShowHitsSection = hasHits || loading;
    const shouldShowNoHitsSection = !shouldShowHitsSection && !showRemarketedListing;

    return { shouldShowHitsSection, shouldShowNoHitsSection };
};

export default useShouldShowHitsAndNoHits;
