//@ts-nocheck
import Loadable, { LoadingSpinner } from 'strat/loadable';

/**
 * Loads the ContactFormDialog component async.
 */
const ContactFormDialog = Loadable({
    loader: () => import(/* webpackChunkName: 'contactForm' */ './contactFormDialog'),
    loading: LoadingSpinner,
});

export default ContactFormDialog;
