import * as React from 'react';
import { Flex } from 'strat/components';

import { BusinessPackagesHeader } from 'horizontal/businessPackages';
import { PackagePurchaseError } from 'horizontal/packages';

import NotFoundHead from './notFoundHead';

const PackagePurchaseErrorPage = {
    head: () => <NotFoundHead />,
    body: ({ errorStatus }: { errorStatus: string }) => (
        <>
            <BusinessPackagesHeader secondary />
            <Flex justifyCenter alignCenter fillContainer>
                <PackagePurchaseError errorStatus={errorStatus} />
            </Flex>
        </>
    ),
} as const;

export default PackagePurchaseErrorPage;
