import animateScroll from 'react-scroll/modules/mixins/animate-scroll';

// The function scrolls to the top of element provided
const scrollToElementTop = (element: HTMLDivElement, offset = 0) => {
    const parent = element.parentNode;
    if (
        element.getBoundingClientRect().top < 0 &&
        parent &&
        parent.parentNode instanceof HTMLDivElement
    ) {
        animateScroll.scrollTo(parent.parentNode.offsetTop - offset, {
            smooth: true,
            duration: 400,
        });
    }
};

export default scrollToElementTop;
