import type { Campaign } from './types';

/**
 * Available actions for campaigns.
 */
const Actions = Object.freeze({
    SET_CAMPAIGN: 'CAMPAIGN/SET',
} as const);

/**
 * An action that sets a campaign.
 */
type SetCampaignAction = {
    type: 'CAMPAIGN/SET';
    campaign: Campaign;
};

/**
 * Default campaign state.
 */
const defaultState: Campaign | null | undefined = {
    // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string'.
    source: null,
    medium: null,
    name: null,
    term: null,
    content: null,
};

/**
 * Campaign reducer.
 */
const campaignReducer = (
    state: Campaign | null | undefined = defaultState,
    action: SetCampaignAction,
) => {
    switch (action.type) {
        case Actions.SET_CAMPAIGN:
            return action.campaign;
        default:
            return state;
    }
};

/**
 * Sets the active campaign.
 */
const setCampaign = (campaign?: Campaign | null): SetCampaignAction => ({
    type: Actions.SET_CAMPAIGN,
    // @ts-expect-error - TS2322 - Type 'Campaign | null | undefined' is not assignable to type 'Campaign'.
    campaign,
});

export { setCampaign };

export default campaignReducer;
