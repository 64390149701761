import alertsReducer, { getSubscriptions, clearAlerts } from './alertManager';
import {
    addAlert,
    addAlertByEmail,
    addRecentlyViewedEmailAlert,
    addSilentAlert,
    addRecommendedEmailAlert,
    setAlertFrequency,
} from './creators';

export {
    addAlert,
    addRecommendedEmailAlert,
    addRecentlyViewedEmailAlert,
    addAlertByEmail,
    addSilentAlert,
    getSubscriptions,
    setAlertFrequency,
    clearAlerts,
};

export default alertsReducer;
