import * as React from 'react';
import { useRouteName } from 'strat/routes/hooks';
import { RouteNames } from 'strat/routes';

type Props = {
    readonly title: string;
    readonly className: string;
};

const Title = ({ title, className }: Props) => {
    const routeName = useRouteName();
    if (routeName == RouteNames.SEARCH) {
        return <h2 className={className}>{title}</h2>;
    }
    return <p className={className}>{title}</p>;
};

export default Title;
