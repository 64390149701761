import { isCanceled, makeCancelable } from 'strat/util';
import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';
import { fetchAd } from 'horizontal/ad/state';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type PaymentSelectionFreeAdUndertakingRouteParams = {
    readonly adExternalID: string;
    readonly hideHeader?: string;
};

class PaymentSelectionFreeAdUndertakingRoute extends Route {
    constructor() {
        super(RouteNames.PAYMENT_SELECTION_FREE_AD_UNDERTAKING, [
            [
                '^/payment-selection/free-ad-undertaking/',
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        params: PaymentSelectionFreeAdUndertakingRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams: Record<string, any> = {};
        if (params.hideHeader === 'true') {
            queryParams.hideHeader = params.hideHeader;
        }
        return {
            pathname: `/payment-selection/free-ad-undertaking/${params?.adExternalID}`,
            search: queryParams,
        };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const {
            match: {
                params: { adExternalID },
            },
            redux: {
                store: { dispatch },
            },
        } = context;

        if (!adExternalID) {
            this.renderNotFoundPage(context);
            return;
        }

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionFreeAdUndertakingRoute'.
        this.cancelablePromise = makeCancelable(
            Promise.all([dispatch(fetchAd({ externalID: adExternalID }))]),
        );
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionFreeAdUndertakingRoute'.
        const promise = this.cancelablePromise.then(
            ([{ data: ad, status }]: [any]) => {
                if (status !== 200) {
                    this.renderNotFoundPage(context);
                    return;
                }
                context.rendering.renderPage(Page.PAYMENT_SELECTION_FREE_AD_UNDERTAKING, {
                    ad,
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionFreeAdUndertakingRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionFreeAdUndertakingRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new PaymentSelectionFreeAdUndertakingRoute();
