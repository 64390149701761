import React from 'react';
import type { FormikValues } from 'formik';
import { matchValueInChoices } from 'strat/util';
import type { FlatCategoryField } from 'strat/types';

const useClearHiddenFields = (
    allFields: Array<FlatCategoryField>,
    renderedFields: Array<FlatCategoryField>,
    values: FormikValues,
    setFieldValue: (field: keyof FormikValues, value?: any) => void,
): void => {
    const hiddenFields = React.useMemo(
        () =>
            allFields.filter(
                (field) => !renderedFields.some((rf) => rf.attribute === field.attribute),
            ),
        [allFields, renderedFields],
    );

    React.useEffect(() => {
        hiddenFields.forEach((field) => {
            if (values[field.attribute] && field.valueType === 'enum') {
                if (
                    !field.choices ||
                    !matchValueInChoices(values[field.attribute], field.choices)
                ) {
                    setFieldValue(field.attribute, undefined);
                }
            }
        });
    }, [hiddenFields, values, setFieldValue]);
};

export default useClearHiddenFields;
