import type { FetchState } from 'strat/fetcher';
import { InternalAPI } from 'strat/api';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';

/**
 * Fetcher factory for fetching inactive favorite ads external ids.
 * The content for ads will be fetched from the backend.
 */
const factory = new FetcherFactory(['inactiveFavorites'], (_params: any, _state: any) => {
    return new InternalAPI().getFavorites(true).then(({ data, status }) => {
        if (status === 200) {
            return {
                status: 200,
                data: data,
            };
        }

        return {
            status,
            data: null,
        };
    });
});

const fetchInactiveFavorites = factory.creator();

const clearInactiveFavorites = () => ({
    type: factory.actions.clear,
});

const defaultState: FetchState = buildDefaultState();

const inactiveFavoritesFetcherReducer = factory.reducer();

export default inactiveFavoritesFetcherReducer;
export { clearInactiveFavorites, fetchInactiveFavorites, defaultState };
