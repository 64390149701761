const sortImages = (
    a: { src: string | null | undefined } | null | undefined,
    b: { src: string | null | undefined } | null | undefined,
) => {
    if (!a?.src && !b?.src) {
        return 0;
    }

    if (a?.src && b?.src) {
        return 0;
    }

    if (a?.src) {
        return -1;
    }

    if (b?.src) {
        return 1;
    }

    return 0;
};

export default sortImages;
