import type { LocationNodeData } from 'strat/property';

const getTrackingLocationID = (location?: LocationNodeData | null): string | null => {
    if (!location) {
        return null;
    }

    return location.trackID || location.externalID;
};

export default getTrackingLocationID;
