import type { ProductPurchase } from 'horizontal/types';
import { useFilterFullyAssignedProductPurchasesForProAgencyOwner } from 'horizontal/packages';

const useEnabledChoicesIds = (productPurchases: Array<ProductPurchase> | undefined | null) => {
    return useFilterFullyAssignedProductPurchasesForProAgencyOwner(productPurchases).map(
        (value) => value.id,
    );
};

export default useEnabledChoicesIds;
