import * as React from 'react';
import { Button, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import settings from '@app/branding/settings';

const VerifiedCarContent = () => (
    <>
        <Text.Large>
            <Trans>A full inspection report is available for every car.</Trans>
        </Text.Large>
        <Link
            route={RouteNames.AGENCY_PROFILE}
            params={{ agencySlug: settings.cashMyCar.verifiedCarAgencySlug }}
        >
            <Button secondary small>
                <Text.Regular>
                    <Trans>Click here</Trans>
                </Text.Regular>
            </Button>
        </Link>
    </>
);

export default VerifiedCarContent;
