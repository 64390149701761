import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { useRouter } from 'react-true-router';

import CommonHead from 'horizontal/pages/commonHead';

const VerticalCarsHead = () => {
    const i18n = useI18n();
    const router = useRouter();
    const countryName = settings.countryName(i18n);
    return (
        <>
            <title>{t(i18n)`Sell your car hassle free!`}</title>
            <meta
                name="description"
                content={t(
                    i18n,
                )`Want to sell your car in ${countryName}? Get the best deals, tips for a quick sale, and access to a wide network of buyers - car sales, hassle free!`}
            />
            <CommonHead url={router?.context?.match?.url} />
        </>
    );
};

export default VerticalCarsHead;
