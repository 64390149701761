import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import { useDialog } from 'strat/dialogs/dialogContext';
import settings from '@app/branding/settings';

import DialogNames from 'horizontal/dialogs/dialogNames';
import type { Location } from 'horizontal/types';

import fetchLocationsByParent from '../fetchLocationsByParent';
import LocationList from '../locationList';

type Props = {
    readonly selectedIndex?: number;
    readonly onLocationClicked: (arg1: Location) => void;
};

const RegionLocationsList = ({ selectedIndex, onLocationClicked }: Props) => {
    const i18n = useI18n();

    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);
    const [locationTreeList, setLocationTreeList] = React.useState([]);

    const [, setLocationsDialogVisible] = useDialog(
        DialogNames.HIERARCHICAL_LOCATION_SELECT_DIALOG,
    );

    const topLocation = React.useMemo(() => settings.getTopLevelLocation(i18n), [i18n]);

    React.useEffect(() => {
        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        fetchLocationsByParent(topLocation, language, backend).then((data) => {
            if (!data.length) {
                return;
            }
            data.sort((a, b) => a.name.localeCompare(b.name));
            // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setLocationTreeList(data);
        });
    }, [topLocation, backend, language]);

    return (
        <LocationList
            title={t(i18n)`Choose region`}
            locations={locationTreeList}
            onLocationClicked={(location) =>
                setLocationsDialogVisible(true, {
                    location,
                    onLocationSelected: onLocationClicked,
                })
            }
            selectedIndex={selectedIndex}
            withArrows
        />
    );
};

export default RegionLocationsList;
