import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import CategoryTypeChecker from '@app/branding/category';
import { Text } from 'strat/components';

import { Location, verticalPages, VerticalSelection } from 'horizontal/types';
import type { Category } from 'horizontal/types';
import { CategoryIcon } from 'horizontal/categories';
import IconArrowRight from 'horizontal/assets/icons/iconArrowRight.svg';
import { SearchLink } from 'horizontal/search/routing';
import { useCurrentLocation } from 'horizontal/location';
import VerticalLink from 'horizontal/verticals/verticalLink';

import styles from './styles/popularCategorySection.cssm';

type Props = {
    readonly rootCategory: Category;
};

const AllInCategoryButton = ({ rootCategory }: Props) => {
    const i18n = useI18n();

    return (
        <>
            <div className={styles.seeAllText}>
                {t(i18n)`All in ${rootCategory.nameShort || rootCategory.name}`}
            </div>
            <IconArrowRight className={styles.rightArrow} />
        </>
    );
};

const PopularCategorySection = ({ rootCategory }: Props) => {
    const currentLocation = useCurrentLocation();

    return (
        <Flex column className={styles.container}>
            <Flex alignCenter>
                <CategoryIcon
                    category={rootCategory}
                    iconClassName={styles.icon}
                    containerClassName={styles.iconContainer}
                />
                <Text.Large bold>{rootCategory.nameShort || rootCategory.name}</Text.Large>
            </Flex>
            {rootCategory.children.slice(0, 2).map((childCategory) => (
                <SearchLink
                    key={childCategory.slug}
                    params={{ category: childCategory, location: currentLocation as Location }}
                    className={styles.childCategory}
                >
                    {childCategory.nameShort || childCategory.name}
                </SearchLink>
            ))}
            {CONFIG.runtime.ENABLE_OLX_VERTICALS &&
            CONFIG.runtime.OLX_HORIZONTAL_VERTICALS_PAGES?.includes(verticalPages.PROPERTIES) &&
            CategoryTypeChecker.isOfPropertiesType(rootCategory) ? (
                <VerticalLink
                    verticalSelector={VerticalSelection.PROPERTY}
                    className={styles.seeAllContainer}
                >
                    <AllInCategoryButton rootCategory={rootCategory} />
                </VerticalLink>
            ) : (
                <SearchLink
                    params={{ category: rootCategory, location: currentLocation as Location }}
                    className={styles.seeAllContainer}
                >
                    <AllInCategoryButton rootCategory={rootCategory} />
                </SearchLink>
            )}
        </Flex>
    );
};

export default PopularCategorySection;
