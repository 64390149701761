import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/flex.cssm';

type Props = {
    readonly children?: React.ReactNode;
    readonly className?: string;
    readonly justifyCenter?: boolean;
    readonly justifyRight?: boolean;
    readonly justifyEnd?: boolean;
    readonly justifySpaceAround?: boolean;
    readonly justifySpaceBetween?: boolean;
    readonly alignCenter?: boolean;
    readonly alignStart?: boolean;
    readonly alignEnd?: boolean;
    readonly alignBaseline?: boolean;
    readonly column?: boolean;
    readonly columnReverse?: boolean;
    readonly rowReverse?: boolean;
    readonly stretchWidth?: boolean;
    readonly stretchHeight?: boolean;
    readonly fillContainer?: boolean;
    readonly noShrink?: boolean;
    readonly wrap?: boolean;
    readonly id?: string;
    readonly onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    readonly role?: string;
    readonly style?: any;
    readonly onMouseEnter?: () => void;
    readonly onMouseLeave?: () => void;
    readonly title?: string;
};

const Flex = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            children,
            column,
            columnReverse,
            rowReverse,
            className,
            justifyCenter,
            justifyRight,
            justifyEnd,
            justifySpaceAround,
            justifySpaceBetween,
            alignCenter,
            alignStart,
            alignEnd,
            alignBaseline,
            stretchWidth,
            stretchHeight,
            fillContainer,
            noShrink,
            wrap,
            ...otherProps
        },
        ref,
    ) => (
        <div
            {...otherProps}
            ref={ref}
            className={classNames(styles.container, className, {
                [styles.justifyCenter]: justifyCenter,
                [styles.justifyRight]: justifyRight,
                [styles.justifyEnd]: justifyEnd,
                [styles.justifySpaceAround]: justifySpaceAround,
                [styles.justifySpaceBetween]: justifySpaceBetween,
                [styles.alignCenter]: alignCenter,
                [styles.alignStart]: alignStart,
                [styles.alignEnd]: alignEnd,
                [styles.alignBaseline]: alignBaseline,
                [styles.column]: column,
                [styles.columnReverse]: columnReverse,
                [styles.rowReverse]: rowReverse,
                [styles.stretchWidth]: stretchWidth,
                [styles.stretchHeight]: stretchHeight,
                [styles.fillContainer]: fillContainer,
                [styles.noShrink]: noShrink,
                [styles.wrap]: wrap,
            })}
        >
            {children}
        </div>
    ),
);

Flex.displayName = 'Flex';

export default Flex;
