import * as React from 'react';
import { trigger, ViewSections, Triggers } from 'strat/gtm';

import { getAdVars } from 'horizontal/gtm/utils';
import type { FullAd } from 'horizontal/types';

type Props = {
    readonly ad?: FullAd | null;
    readonly suppressTrigger?: boolean;
};

const useTriggerAdSuccess = ({ ad, suppressTrigger }: Props) => {
    React.useEffect(() => {
        if (suppressTrigger || !ad) {
            return;
        }
        trigger(Triggers.AD_SUCCESS, {
            view_section: ViewSections.BODY,
            ...getAdVars(ad),
        });
    }, [ad, suppressTrigger]);
};

export default useTriggerAdSuccess;
