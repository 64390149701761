import React from 'react';

import type { Category, Location } from 'horizontal/types';

import { filterDisabledLocations } from './filterDisabledLocations';

export const useDisabledLocations = (
    locations: Array<Location> | undefined,
    categories: Array<Category>,
    activeCategory?: Category | null,
): Array<Location> => {
    return React.useMemo(() => {
        return filterDisabledLocations(locations, categories, activeCategory);
    }, [locations, activeCategory, categories]);
};
