import { createSelector } from 'reselect';

import { Location } from 'horizontal/types';

type GlobalState = {
    locations: Record<string, { data: Location }>;
};

const selectAllLocations = (state: GlobalState) => state.locations;

const selectLocations = createSelector(selectAllLocations, (locations) =>
    Object.keys(locations || [])
        .map((key, _) => locations?.[key]?.data)
        .filter(Boolean)
        .reduce<Record<string, any>>((acc, item) => {
            acc[item.externalID] = item;
            return acc;
        }, {}),
);

const selectLocationBySlug = createSelector(
    selectAllLocations,
    (_: any, locationSlug: string) => locationSlug,
    (locations: Record<string, { data: Location }>, locationSlug) => {
        const matchedLocationKey = Object.keys(locations).find(
            (locationKey) =>
                locations[locationKey].data && locations[locationKey].data?.slug === locationSlug,
        );
        if (matchedLocationKey) {
            return locations[matchedLocationKey].data;
        }
        return null;
    },
);

export { selectLocations, selectLocationBySlug };
