import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';
import { isValidPhoneNumber } from '@app/i18n/phone';
// @ts-ignore
import * as Yup from 'yup';

const Errors = {
    required: (i18n: I18n) => t(i18n)`This field is required`,
    invalidPhone: (i18n: I18n) => t(i18n)`Please enter a valid phone number`,
    acceptedTc: (i18n: I18n) => t(i18n)`Please agree to our terms and conditions`,
    type: (i18n: I18n) => t(i18n)`Please input a valid number`,
} as const;

const useSellCarFormValidationSchema = (i18n: I18n, sellMyCar: boolean) =>
    Yup.object().shape({
        makeName: Yup.string().required(Errors.required(i18n)),
        modelName: Yup.string().required(Errors.required(i18n)),
        year: Yup.number().required(Errors.required(i18n)),
        isRegistered: Yup.string().oneOf(['yes', 'no']).required(Errors.required(i18n)),
        governateName: Yup.string().required(Errors.required(i18n)),
        regionName: Yup.string().required(Errors.required(i18n)),
        cityName: Yup.string().required(Errors.required(i18n)),
        userName: Yup.string().required(Errors.required(i18n)),
        askingPrice: sellMyCar
            ? Yup.string().test(
                  'decimalsOnly',
                  Errors.type(i18n),
                  (value: string) => value && value.match(/^\d+(\.\d+)?$/),
              )
            : Yup.string(),
        phoneNumber: Yup.string()
            .required(Errors.required(i18n))
            .test('validPhone', Errors.invalidPhone(i18n), (value: string) =>
                isValidPhoneNumber(value),
            ),
        notes: Yup.string(),
        acceptedTc: Yup.boolean().test(
            'acceptedTc',
            Errors.acceptedTc(i18n),
            (value: boolean) => value === true,
        ),
    });

export default useSellCarFormValidationSchema;
