import type { LocationNodeData } from 'strat/property';
import { UserRole } from 'strat/user/roles';

/**
 * Data included in a user's profile.
 */
export type UserProfileData = {
    id: string;
    name: string;
    email: string;
    phoneNumber: string | null;
    roles: readonly UserRole[];
};

/**
 * Data a user must enter to register.
 */
export type UserRegistrationData = {
    readonly email: string;
    readonly password: string;
    readonly name: string;
    readonly phoneNumber: string;
    readonly userRoles: Array<number> | null | undefined;
    readonly withNotificationsSubscription: boolean | null | undefined;
};

/**
 * User's physical location on the globe (or maybe outside!! aliens!).
 */
export type UserLocationData = {
    readonly countryCode: string | null | undefined;
    readonly countryName: string | null | undefined;
    readonly cityName: string | null | undefined;
};

type Longitude = number;
type Latitude = number;

export type Coordinates = [Latitude, Longitude];
export type GeoLocationData = {
    readonly coordinates: Coordinates | null | undefined;
    readonly closestLocation: LocationNodeData | null | undefined;
    readonly loading: boolean;
    readonly error: string | null | undefined;
};

export const EventType = Object.freeze({
    SAVE_ON_FACEBOOK: 'save_on_facebook',
    SIMILAR_PROPERTIES_CLICK: 'similar_properties_click',
    PROPERTY_PAGE_VIEW: 'property_page_view',
    SEARCH_PAGE_VIEW: 'search_page_view',
});

export type UserEvent = {
    readonly eventType: Values<typeof EventType>;
    readonly timestamp: number;
};
