import { t } from '@lingui/macro';
import * as React from 'react';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import { useI18n } from 'strat/i18n/language';
import { ToolTipPosition } from 'strat/forms';
import Designs from 'strat/branding/designs';
import { numberFormatterLocaleFromLanguage } from 'strat/i18n/language/locales';

import DroppableFilter from './droppableFilter';
import DropDownBox from './dropDownBox';
import MultiLanguageNumericInput from './multiLanguageNumericInput';
import FilterValueOptions from './filterValueOptions';
import styles from './styles/numberSelector.cssm';

/**
 * A value that can be displayed by {@see NumberSelector}.
 */
type Value = null | number | string;

/**
 * Properties for {@see NumberSelector}.
 */
type Props = {
    /**
     * Label to display at the top.
     */
    label: string;
    /**
     * Current value to display.
     */
    value: Value;
    /**
     * Pre-defined values to display.
     */
    options: Array<number>;
    /**
     * Callback for when the current value changes.
     */
    onChange: (value: Value) => void;
    /**
     * Whether to display a 'Any' button to clear
     * the value.
     */
    showAny?: boolean;
    /**
     * Method that can be called for each option.
     */
    renderOption?: (value: number) => string | React.ReactNode;
    /**
     * Optional errorMessage to be displayed in an error tooltip.
     */
    errorMessage?: null | string;
    /**
     * Callback for when the user dissmisses the error tooltip.
     */
    onDismissError: () => void;
    /**
     * Placeholder to be displayed in the input
     */
    placeholder?: string;
    /**
     * Current active design
     */
    design?: string;
    /**
     * Optional class name for the value label
     */
    valueClassName?: string;
    /**
     * Optional position to render the tooltip
     */
    toolTipPosition?: string;
    /**
     * Optional class for the tooltip
     */
    toolTipClassName?: string;
};

/**
 * Allows inputting a number or selecting a pre-determined
 * value from a list.
 */
const NumberSelector = ({
    label,
    value,
    options,
    onChange,
    showAny = false,
    renderOption,
    errorMessage,
    onDismissError,
    placeholder,
    design,
    valueClassName,
    toolTipPosition = ToolTipPosition.ABOVE,
    toolTipClassName,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const locale = numberFormatterLocaleFromLanguage(i18n.locale);

    const currentValue = !isNil(value) ? value : '';
    const placeholderText = placeholder || t(i18n)`Any`;
    const [open, setOpen] = React.useState(false);

    const toggleFilter = React.useCallback((newState?: boolean | null) => {
        setOpen((openState) => (!isNil(newState) ? newState : !openState));
    }, []);

    /**
     * Sets the specified value as the current value.
     */
    const setValue = (newValue: Value): void => {
        onChange(newValue);

        if (design === Designs.FILTER_DESIGN_2022) {
            toggleFilter(false);
        }
    };

    /**
     * Handler for when the user manually changes
     * the current value by entering an arbitrary value.
     */
    const onTextChange = (numericValue: number): void => {
        setValue(numericValue);
    };

    const filterValueOptions = (
        <FilterValueOptions
            showAny={showAny}
            placeholderText={placeholderText}
            label={label}
            value={value}
            options={options}
            setValue={setValue}
            renderOption={renderOption}
            design={design}
        />
    );

    const renderNumericInput = (inputClassName?: string) => {
        const numericInputId = isNil(value) ? 'inactiveNumericInput' : 'activeNumericInput';
        return (
            <MultiLanguageNumericInput
                key="input"
                id={numericInputId}
                value={`${currentValue}`}
                placeholder={placeholderText}
                onChange={onTextChange}
                className={classNames(styles.inputContainer, design)}
                inputClassName={inputClassName}
                errorMessage={errorMessage || ''}
                toolTipPosition={toolTipPosition}
                toolTipClassName={toolTipClassName}
                onDismissError={onDismissError}
                locales={locale}
            />
        );
    };

    if (design === Designs.FILTER_DESIGN_2022) {
        const inputId = isNil(value) ? 'inactiveInputContainer' : 'activeInputContainer';
        return (
            <>
                <span className={classNames(styles.label, design)}>{`${label}`}</span>
                <div
                    id={inputId}
                    className={classNames(styles.dropDownContainer, { [styles.active]: open })}
                    onClick={() => toggleFilter()}
                >
                    <DroppableFilter
                        value={null}
                        open={open}
                        onOpenChanged={toggleFilter}
                        design={design}
                        renderInput={() => renderNumericInput(styles.input)}
                        valueClassName={valueClassName}
                    >
                        <DropDownBox className={styles.options} design={design}>
                            {filterValueOptions}
                        </DropDownBox>
                    </DroppableFilter>
                </div>
            </>
        );
    }

    return (
        <>
            <span key="label" className={styles.label}>
                {`${label}:`}
            </span>
            {renderNumericInput()}
            <div className={styles.options} key="options">
                {filterValueOptions}
            </div>
        </>
    );
};

export default NumberSelector;
