import React from 'react';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import { Button, Text, Flex } from 'strat/components';

import { BankDetails, WhatsappShareButton } from 'horizontal/adUndertakingTerms';
import { useNavigateToAdDetails } from 'horizontal/adDetails';
import type { FullAd } from 'horizontal/types';
import AdCard from 'horizontal/payment/adCard';

import PaymentBankTrasferHeader from './paymentBankTransferHeader';
import styles from './styles/paymentBankTransfer.cssm';

export const WHATS_APP_NUMBER = '923158662610';

type Props = {
    readonly ad: FullAd;
    readonly commission: string;
};

const PaymentBankTransfer = ({ ad, commission }: Props) => {
    const navigateToAdDetails = useNavigateToAdDetails(ad);

    return (
        <>
            <PaymentBankTrasferHeader />
            <PageWidthWrapper className={styles.pageWrapper}>
                <Flex column className={styles.container}>
                    <Flex className={styles.iconContainer}>
                        <div className={styles.bankImage} />
                    </Flex>
                    <div className={styles.title}>
                        <Text.XLarge bold>
                            <Trans>Pay directly to the following bank account</Trans>
                        </Text.XLarge>
                    </div>
                    <div className={styles.adCardWrapper}>
                        <AdCard ad={ad} commission={commission} />
                    </div>
                    <div className={styles.textBankDetails}>
                        <Text.Regular bold>
                            <Trans>Bank Account Details</Trans>
                        </Text.Regular>
                    </div>
                    <BankDetails />
                    <div className={styles.shareButtonSeparator} />
                    <WhatsappShareButton url={WHATS_APP_NUMBER} />
                    <div className={styles.separator} />
                    <div className={styles.actionButton}>
                        <Button onClick={navigateToAdDetails}>
                            <Text.Regular className={styles.text}>
                                <Trans>Done</Trans>
                            </Text.Regular>
                        </Button>
                    </div>
                </Flex>
            </PageWidthWrapper>
        </>
    );
};

export default PaymentBankTransfer;
