import * as React from 'react';
import { Flex, Text } from 'strat/components';

import type { CartPackage } from 'horizontal/packages/types';
import { ProductType } from 'horizontal/types';
import { PaymentProductPackageOffer } from 'horizontal/payment';

import CartEntryCounter from './cartEntryCounter';
import useFormattedPackagesPrice from './useFormattedPackagesPrice';
import styles from './styles/cartEntryRedesign.cssm';
import useGetCartEntryDescription from './useGetCartEntryDescription';

const CartEntry = ({
    packageOffer,
}: {
    packageOffer: PaymentProductPackageOffer | CartPackage;
}) => {
    const discountPercentage = Math.floor(
        ((packageOffer.price - (packageOffer.discountPrice || 0)) * 100) / packageOffer.price,
    ).toString();
    const formattedPrice = useFormattedPackagesPrice(packageOffer.price);
    const formattedDiscountPrice = useFormattedPackagesPrice(packageOffer.discountPrice || 0);
    const hideCounter = packageOffer.productPackage.productItems.some(
        (item) => item.type === ProductType.AUTO_REFRESH_CATEGORY,
    );

    return (
        <div className={styles.wrapper} aria-label="Cart entry">
            {packageOffer.discountPrice && (
                <Text.Base bold className={styles.discountPercentage}>
                    {`-${discountPercentage}%`}
                </Text.Base>
            )}
            <Flex column className={styles.container}>
                <Flex column>
                    <Text.Large>{useGetCartEntryDescription(packageOffer)}</Text.Large>
                </Flex>
                <Flex justifySpaceBetween className={styles.bottom}>
                    <Flex alignCenter className={styles.pricingDetails}>
                        <Text.Regular bold>
                            {packageOffer.discountPrice ? formattedDiscountPrice : formattedPrice}
                        </Text.Regular>
                        {packageOffer.discountPrice && (
                            <Text.Base
                                aria-label="Actual price"
                                strikethrough
                                className={styles.strikeOffPrice}
                            >
                                {formattedPrice}
                            </Text.Base>
                        )}
                    </Flex>
                    <Flex>{!hideCounter && <CartEntryCounter packageOffer={packageOffer} />}</Flex>
                </Flex>
            </Flex>
        </div>
    );
};

export default CartEntry;
