import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import { numberOfHits } from '@app/branding/search';
import settings from '@app/branding/settings';
import filterValues from '@app/branding/filterValues';

import { useLocalUnits, Area, ConversionTable } from 'strat/i18n';
import { useI18n } from 'strat/i18n/language';
import { useLocalPrice, Currency, selectCurrency } from 'strat/i18n/money';
import { selectNumberFormatterFactory } from 'strat/i18n/language/selectors';
import Purpose, { PurposeTextDisplay } from 'strat/purpose';
import { FilterValues } from 'strat/search';
import EMPTY_ARRAY from 'strat/empty/array';

const cityLevel = CONFIG.build.CITY_LEVEL;
const localityLevel = cityLevel + 1;

const EMPTY_RANGE_OBJECT = Object.freeze({
    min: null,
    max: null,
});

const getTopLevelLocation = (language: string) => ({
    ...settings.topLevelLocation,
    hierarchy: [
        {
            ...(settings.topLevelLocation.hierarchy?.[0] || {}),
            // @ts-ignore
            slug: filterValues.location.placeholders[language],
        },
    ],
});

const useListingTemplateReplaceParameters = () => {
    const i18n = useI18n();
    const filters = useSelector(getFilterCollection);
    const { formatLocalPriceValue } = useLocalPrice();
    const { areaUnit, formatLocalUnitsValue, areaInLocalUnits } = useLocalUnits();
    const { isAreaPage, areaPageUnit, areaPageValue } = useSelector(
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        (state) => state.search.pageParameters,
    );
    const nbHits = useSelector(numberOfHits);
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const { currency } = useSelector(selectCurrency);

    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);

    const priceRange = filters.getFilterValue(FilterValues.price.attribute, EMPTY_RANGE_OBJECT);

    const areaRange = filters.getFilterValue(FilterValues.area.attribute, EMPTY_RANGE_OBJECT);

    let convertedAreaPageValue = null;
    if (isAreaPage && areaPageUnit && areaPageValue) {
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'. | TS2722 - Cannot invoke an object which is possibly 'undefined'.
        convertedAreaPageValue = ConversionTable.get(Area.SQM).get(areaPageUnit)(areaPageValue);
    }

    const areaPageValueSqYd =
        convertedAreaPageValue && areaUnit
            ? // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2722 - Cannot invoke an object which is possibly 'undefined'. | TS2345 - Argument of type 'string | ((i18n: any, area: any) => any) | ((i18n: any, area: any) => any) | ((area: any) => { maximumFractionDigits: number; minimumFractionDigits: number; } | { maximumFractionDigits?: undefined; minimumFractionDigits?: undefined; }) | ((area: any) => (x: number) => number) | ((value: string) => boolean) | (() => ...' is not assignable to parameter of type 'string'.
              ConversionTable.get(areaUnit)
                  .get(Area.SQYD)(Number(convertedAreaPageValue))
                  .toFixed(2)
            : null;

    let cityFilter = filters.getFilterValue(FilterValues.city.attribute, { name: '' });
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    if (isNil(cityFilter.hierarchy)) {
        // Sometimes, the cityFilter doesn't have a hierarchy since we don't always
        // fetch it from algolia. If we have a cityFilter, assign itself to the hierarchy
        // so templates for it can be found.
        cityFilter = {
            // @ts-expect-error - TS2698 - Spread types may only be created from object types.
            ...cityFilter,
            hierarchy: [
                {
                    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                    name: cityFilter.name,
                    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                    slug: cityFilter.slug,
                },
            ],
        };
    }

    const locationFilter = filters.getFirstFilterValue(
        FilterValues.location.attribute,
        getTopLevelLocation(i18n.locale),
    );
    // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
    const locationType = locationFilter?.type ? locationFilter.type : null;

    let location;
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    if (locationFilter.level === 0) {
        // A bit of a hack which is specific to mustang but doesn't influence explorer.
        // If a city is available, we do not use the fallback (Pakistan) for the location.
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        location = cityFilter.name ? '' : FilterValues.location.placeholderName(i18n);
    } else {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        location = locationFilter.name;
    }

    // Remove city name if it also appears in the location name
    // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type. | TS2571 - Object is of type 'unknown'.
    const removeCity = (loc) => loc.replace(cityFilter.name, '');
    const locationWithoutCity = removeCity(location);
    const locationWithParent =
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationFilter.hierarchy.length > 4
            ? t(i18n)`${locationWithoutCity} ${removeCity(
                  // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                  locationFilter.hierarchy.slice(-2)[0].name,
              )}`
            : // @ts-expect-error - TS2571 - Object is of type 'unknown'.
              `${locationWithoutCity} ${cityFilter.name}`;

    let locationWithParents = location;
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    if (locationFilter.hierarchy.length > 1) {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationWithParents = locationFilter.hierarchy
            // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
            .filter((loc) => loc.level > 0)
            // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
            .map((loc) => loc.name)
            .reverse()
            .join(', ');
    }

    let locationWithLoc2 = location;
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    if (location && locationFilter.hierarchy.length > cityLevel + 1) {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationWithLoc2 = `${location}, ${locationFilter.hierarchy[cityLevel].name}`;
    }

    let locationWithLoc3 = location;
    let locationWithLoc3and2 = location;
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    if (location && locationFilter.hierarchy.length > localityLevel + 1) {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationWithLoc3 = `${location}, ${locationFilter.hierarchy[localityLevel].name}`;
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
        locationWithLoc3and2 = `${location}, ${locationFilter.hierarchy[localityLevel].name}, ${locationFilter.hierarchy[cityLevel].name}`;
    }

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const locationAliases = locationFilter.aliases || EMPTY_ARRAY;
    const locationAlias1 = locationAliases[0] || locationWithoutCity;
    const locationAlias2 = locationAliases[1] || locationAlias1;
    const locationAlias3 = locationAliases[2] || locationAlias2;
    const locationAlias4 = locationAliases[3] || locationAlias3;
    const locationAlias5 = locationAliases[4] || locationAlias4;

    const categoryFilter = filters.getFilterValue(FilterValues.category.attribute, {
        slug: null,
        name: null,
    });

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const category = categoryFilter.name || FilterValues.category.placeholderName(i18n);

    const rentFrequencyFilter = filters.getFilterValue(FilterValues.rentFrequency.attribute);
    let rentFrequency = Purpose.isForRent(purpose) ? rentFrequencyFilter : undefined;
    if (rentFrequency) {
        rentFrequency =
            (
                FilterValues.rentFrequency.choice(i18n, rentFrequencyFilter) || {}
            ).name.toLowerCase() || undefined;
    }

    const page: number = filters.getFilterValue(FilterValues.page.attribute, 1);
    const roomsFilter = filters.getFilterValue(FilterValues.beds.attribute, EMPTY_ARRAY);

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    let rooms = roomsFilter?.[0];
    if (rooms) {
        rooms = typeof rooms === 'number' ? numberFormatterFactory().format(rooms) : rooms;
    }

    return {
        purpose: Purpose.text(i18n, purpose, PurposeTextDisplay.FOR_TEMPLATES),
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
        priceMin: priceRange.min ? formatLocalPriceValue(priceRange.min) : priceRange.min,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
        priceMax: priceRange.max ? formatLocalPriceValue(priceRange.max) : priceRange.max,
        currency: Currency.acronym(i18n, currency),
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        areaMin: areaRange.min
            ? // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1. | TS2571 - Object is of type 'unknown'. | TS2345 - Argument of type 'string | ((i18n: any, area: any) => any) | ((i18n: any, area: any) => any) | ((area: any) => { maximumFractionDigits: number; minimumFractionDigits: number; } | { maximumFractionDigits?: undefined; minimumFractionDigits?: undefined; }) | ((area: any) => (x: number) => number) | ((value: string) => boolean) | (() => ...' is not assignable to parameter of type 'Unit'.
              formatLocalUnitsValue(areaInLocalUnits(areaRange.min), Area, areaUnit)
            : // @ts-expect-error - TS2571 - Object is of type 'unknown'.
              areaRange.min,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        areaMax: areaRange.max
            ? // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1. | TS2571 - Object is of type 'unknown'. | TS2345 - Argument of type 'string | ((i18n: any, area: any) => any) | ((i18n: any, area: any) => any) | ((area: any) => { maximumFractionDigits: number; minimumFractionDigits: number; } | { maximumFractionDigits?: undefined; minimumFractionDigits?: undefined; }) | ((area: any) => (x: number) => number) | ((value: string) => boolean) | (() => ...' is not assignable to parameter of type 'Unit'.
              formatLocalUnitsValue(areaInLocalUnits(areaRange.max), Area, areaUnit)
            : // @ts-expect-error - TS2571 - Object is of type 'unknown'.
              areaRange.max,
        areaUnit: Area.abbreviation(i18n, areaUnit),
        areaPageValueSqYd,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        city: cityFilter.name,
        location: locationWithoutCity,
        locationType,
        locationWithParent,
        locationWithParents,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationOrCity: location || cityFilter.name,
        locationWithLoc2,
        locationWithLoc3,
        locationWithLoc3and2,
        locationAlias: locationAlias1,
        locationAlias1,
        locationAlias2,
        locationAlias3,
        locationAlias4,
        locationAlias5,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel1: (locationFilter.hierarchy[0] || {}).name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel2: (locationFilter.hierarchy[1] || {}).name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel3: (locationFilter.hierarchy[2] || {}).name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel4: (locationFilter.hierarchy[3] || {}).name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel5: (locationFilter.hierarchy[4] || {}).name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel6: (locationFilter.hierarchy[5] || {}).name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationLevel7: (locationFilter.hierarchy[6] || {}).name,
        category,
        rentFrequency,

        numberOfHits: numberFormatterFactory().format(nbHits),
        pageNumber: page,

        localizedPageNumber: numberFormatterFactory().format(page),
        rooms,
        areaPageValue: convertedAreaPageValue ? Math.round(convertedAreaPageValue) : null,
    };
};

export default useListingTemplateReplaceParameters;
