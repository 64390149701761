import * as React from 'react';

import { Flex } from 'strat/components';
import IconInfo from '@app/assets/icons/iconInfo_noinline.svg';

import styles from './styles/infoIcon.cssm';

export type Props = {
    onClick?: (event: React.SyntheticEvent<any>) => void;
};

const InfoIcon = ({ onClick }: Props) => (
    <Flex onClick={onClick}>
        <svg className={styles.infoIcon}>
            <use xlinkHref={`${IconInfo}#info`} />
        </svg>
    </Flex>
);

export default InfoIcon;
