import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Tooltip, { HorizontalPosition, VerticalPosition } from 'strat/generic/tooltip';
import { selectIsLanguageRTL } from 'strat/i18n/language';

import styles from './styles/paymentPlanMoreDetailsTooltip.cssm';

const tooltipDisplayDelay = 200;

type Props = {
    renderTrigger: () => React.ReactElement;
    renderContent: () => React.ReactElement;
    displayTooltipOnTop?: boolean;
};

const PaymentPlanMoreDetailsTooltip = ({
    renderTrigger,
    renderContent,
    displayTooltipOnTop = false,
}: Props) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);

    const attachment = isLanguageRTL
        ? {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.RIGHT,
          }
        : {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.LEFT,
          };

    const topAttachment = {
        vertical: VerticalPosition.BOTTOM,
        horizontal: HorizontalPosition.CENTER,
    };

    const targetAttachment = isLanguageRTL
        ? {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.LEFT,
          }
        : {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.RIGHT,
          };

    const targetTopAttachment = {
        vertical: VerticalPosition.TOP,
        horizontal: isLanguageRTL ? HorizontalPosition.LEFT : HorizontalPosition.RIGHT,
    };

    return (
        <Tooltip
            timeToRender={tooltipDisplayDelay}
            triggerContainerClassName={styles.trigger}
            anchorClassName={classNames(styles.anchor, { [styles.onTop]: displayTooltipOnTop })}
            renderTrigger={renderTrigger}
            renderContent={() => (
                <div
                    className={classNames(styles.content, { [styles.onTop]: displayTooltipOnTop })}
                >
                    {renderContent()}
                </div>
            )}
            attachment={displayTooltipOnTop ? topAttachment : attachment}
            targetAttachment={displayTooltipOnTop ? targetTopAttachment : targetAttachment}
        />
    );
};

export default PaymentPlanMoreDetailsTooltip;
