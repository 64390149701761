import * as React from 'react';

import { withRouter, HistoryRouter } from 'react-true-router';
import { SchemaType } from 'strat/schema/types';
import { buildCanonicalURL } from 'strat/routing';
import { RouteNames } from 'strat/routes';
import type { BreadcrumbsElement } from 'strat/navigation/breadcrumbs';

import MetaData from '../metaData';
import { addContextTag, addTypeTag, addNameTag } from '../tagsGeneral';

type Props = {
    breadcrumbs: BreadcrumbsElement[];
    router: HistoryRouter;
};
/*
 * Component that renders a JSON-LD script with breadcrumbs metadata
 */
const BreadcrumbsMetaData = ({ breadcrumbs, router }: Props): React.ReactElement => {
    const metadata: Record<string, any> = {};

    addContextTag(metadata);
    addTypeTag(metadata, [SchemaType.BREADCRUMB_LIST]);

    metadata.itemListElement = breadcrumbs.map((breadcrumb, index) => {
        const element: Record<string, any> = {};
        addTypeTag(element, [SchemaType.LIST_ITEM]);
        addNameTag(element, breadcrumb.name);
        element.position = index + 1;

        // Build the url using router such that the metadata links match the actual links
        if (!breadcrumb.disabled) {
            const url = router.getRouteURL(
                breadcrumb.route || RouteNames.SEARCH,
                breadcrumb.params,
            );
            const canonicalURL = buildCanonicalURL(url, CONFIG.build.LANGUAGE_CODE);

            element.item = canonicalURL;
        }
        return element;
    });

    // @ts-expect-error - TS2786 - 'MetaData' cannot be used as a JSX component.
    return <MetaData data={metadata} />;
};

export default withRouter(BreadcrumbsMetaData);
