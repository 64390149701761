import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { Button, Dialog, Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { trackBlockUser } from 'horizontal/gtm/userTracking';
import { selectDialogStack } from 'horizontal/dialogs/selectors';
import type { ChatAd } from 'horizontal/chat/types';

import styles from './styles/blockUserDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly onClick: () => void;
    readonly userName: string | null | undefined;
    readonly shouldBlock: boolean | null | undefined;
    readonly ad?: ChatAd;
};

const BlockUserDialog = ({
    visible,
    onVisibilityChanged,
    onClick,
    userName,
    shouldBlock,
    ad,
}: Props) => {
    const i18n = useI18n();
    const { stacked, stackGroup } = useSelector(selectDialogStack);

    const onBlockClicked = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onClick();
            if (ad && !isNil(shouldBlock)) {
                trackBlockUser(ad, shouldBlock);
            }
            /**
             * This is needed when you are supporting stacking of
             * Dialogs which internally are using Dissmissible.
             * Dissmissible uses event listeners on the document:
             * document.addEventListener('click')
             * such events are `document` based, not React based.
             * So they can't be handled with our stopEventPropagation
             * function, which is meant for React synthetic events.
             */
            event.nativeEvent.stopImmediatePropagation();
            onVisibilityChanged(false);
        },
        [onClick, ad, onVisibilityChanged, shouldBlock],
    );

    if (!onClick || !userName || shouldBlock === undefined || shouldBlock === null) {
        return null;
    }

    const blockMessage = shouldBlock ? t(i18n)`Block` : t(i18n)`Unblock`;
    const title = shouldBlock ? (
        <Trans>Block {userName}?</Trans>
    ) : (
        <Trans>Unblock {userName}?</Trans>
    );

    const description = shouldBlock ? (
        <Trans>Blocked contacts will no longer be able to send you messages.</Trans>
    ) : (
        <Trans>
            Unblock <strong>{userName}</strong> to send messages.
        </Trans>
    );

    return (
        <Dialog
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            stacked={stacked}
            stackGroup={stackGroup}
        >
            <Flex column className={styles.container}>
                <Text.Base bold className={styles.title}>
                    {title}
                </Text.Base>
                <Text.Base className={styles.message}>{description}</Text.Base>
                <Flex className={styles.buttonsContainer}>
                    <Button
                        stretch
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                            onBlockClicked(event)
                        }
                        className={styles.blockButton}
                    >
                        <Text.Large uppercase>{blockMessage}</Text.Large>
                    </Button>
                    <Button secondary stretch onClick={() => onVisibilityChanged(false)}>
                        <Text.Large uppercase>{t(i18n)`Cancel`}</Text.Large>
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default BlockUserDialog;
