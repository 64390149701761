import * as React from 'react';
import { Trans } from '@lingui/macro';
import styles from '@app/modal/styles/messageDialog.cssm';

import PopUp from './popUp';

type FooterProps = {
    onClick: () => void;
};

export const MessageDialogFooter = ({ onClick }: FooterProps) => (
    <button className={styles.button} onClick={onClick}>
        <Trans>OK</Trans>
    </button>
);

/**
 * Properties for {@see MessageDialog}.
 */
type Props = {
    className?: string;
    title: React.ReactNode | string;
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
    renderFooter?: (props: FooterProps) => React.ReactElement | null | undefined;
    children?: React.ReactNode;
    icon: any;
    withoutCloseButton?: boolean;
};

/**
 * Displays a dialog informing the user that the e-mail
 * was succesfully send.
 */
const MessageDialog = ({
    renderFooter = ({ onClick }) => <MessageDialogFooter onClick={onClick} />,
    ...props
}: Props) => {
    const className = props.className ? `${styles.container} ${props.className}` : styles.container;

    return (
        <PopUp
            visible={props.visible}
            onVisibilityChanged={props.onVisibilityChanged}
            className={className}
            withoutCloseButton={props.withoutCloseButton}
        >
            {React.cloneElement(props.icon, {
                className: `${props.icon.props.className} ${styles.icon}`,
            })}
            <div className={styles.title}>{props.title}</div>
            <div className={styles.message}>{props.children}</div>
            {renderFooter && renderFooter({ onClick: () => props.onVisibilityChanged(false) })}
        </PopUp>
    );
};

export default MessageDialog;
