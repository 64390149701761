import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { SearchRouteParams } from 'horizontal/routes';

import refineSearchRouteParams from './refineSearchRouteParams';
import useUpdateFiltersPreemptively from './useUpdateFiltersPreemptively';
import useCurrentSearchRouteParams from './useCurrentSearchRouteParams';

type UseNavigateToRefinedSearch = (arg1: Partial<SearchRouteParams>) => void;

const useNavigateToRefinedSearch = (): UseNavigateToRefinedSearch => {
    const router = useRouter();
    const currentParams = useCurrentSearchRouteParams();
    const updateFiltersPreemptively = useUpdateFiltersPreemptively();

    return React.useCallback(
        (params: Partial<SearchRouteParams>) => {
            const refinedParams = refineSearchRouteParams(currentParams, params);
            updateFiltersPreemptively(refinedParams);
            router.pushRoute(RouteNames.SEARCH, refinedParams);
        },
        [router, currentParams, updateFiltersPreemptively],
    );
};

export default useNavigateToRefinedSearch;
