import * as React from 'react';

import { BottomSheetState } from 'strat/dialogs/dialogContext';

import Gesture from './gesture';
import isElementInEventPath from './isElementInEventPath';

/**
 * Keeps track of the gestures the user is applying on the bottom sheet.
 *
 * @param activeState - The active state of the Bottom Sheet
 * @param header - The header of the bottom sheet - not scrollable - any event happening on this
 *     element is interpreted as a drag.
 * @param scrollable - The scrollable part of the bottom sheet.
 */
const useVerticalGesture = (
    activeState: Values<typeof BottomSheetState>,
    header: {
        current: HTMLElement | null;
    },
    scrollable: {
        current: HTMLElement | null;
    },
) => {
    const [activeGesture, setActiveGesture] = React.useState(Gesture.NONE);

    const computeActiveGesture = React.useCallback(
        (velocityY: number, event: React.TouchEvent<HTMLElement>) => {
            if (activeState !== BottomSheetState.FULL_SCREEN || activeGesture !== Gesture.NONE) {
                return;
            }

            // If the tap started from the header, always move the drawer around.
            if (isElementInEventPath(event, header.current)) {
                // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
                setActiveGesture(Gesture.DRAG);
                return;
            }

            // If the scrollable is at the start, and the user is pulling down,
            // drag the drawer down.
            if (scrollable.current && scrollable.current.scrollTop === 0 && velocityY > 0) {
                // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
                setActiveGesture(Gesture.DRAG);
                return;
            }

            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
            setActiveGesture(Gesture.SCROLL);
        },
        [activeState, activeGesture, header, scrollable],
    );

    const clearGesture = React.useCallback(() => {
        setActiveGesture(Gesture.NONE);
    }, []);

    return [activeGesture, clearGesture, computeActiveGesture];
};

export default useVerticalGesture;
