const PageType = Object.freeze({
    HOME: 'home',
    SEARCH: 'searchresult',
    AD_DETAIL: 'offerdetail',
    AD_CREATION: 'add-listing',
    ADD_LISTING_SUCCESS: 'add-listing-success',
    AD_MANAGEMENT: 'own-ads-listing',
    PROFILE: 'profile',
    SINGLE_PACKAGE: 'product-sell-faster',
    MULTIPLE_PACKAGES: 'product-sell-faster-hdp',
    PAYMENT: 'CO_payment',
    SETTINGS: 'settings',
    CHAT: 'chat',
    SELLER: 'seller',
    AGENCY: 'agency',
    FAVOURITES_SAVED_SEARCHES_MANAGEMENT: 'own-favourites-saved-searches-listing',
    WALLET: 'wallet',
    PROPERTIES_VERTICAL: 'properties_vertical',
    CARS_VERTICAL: 'cars_vertical',
});

export default PageType;
