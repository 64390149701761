import { createSelector } from 'reselect';
import EMPTY_OBJECT from 'strat/empty/object';

import type { GlobalState } from 'horizontal/state';

import { AdMetrics } from './types';

type FetchError = {
    status: number;
    data: {
        message: string;
    };
};

export type AdMetricsWithError = AdMetrics & { error: FetchError | null; loaded: boolean };

const selectMetricsData = (state: GlobalState) => state.adMetrics || EMPTY_OBJECT;

export const selectMetrics = createSelector(
    selectMetricsData,
    (metrics) => metrics.data || EMPTY_OBJECT,
);

export const selectLoadedMetrics = createSelector(selectMetricsData, (metrics) => metrics.loaded);

export const selectMetricsError = createSelector(selectMetricsData, (metrics) => metrics.error);
