import * as React from 'react';

import { withErrorBoundary } from 'strat/util';
import { Flex } from 'strat/components';
import { AgencyType } from 'strat/property/types';

import AgencyInfoDefault, { Props as AgencyInfoDefaultProps } from './agencyInfoDefault';
import ViewAllProperties from './viewAllProperties';
import styles from './styles/agencyInfo.cssm';

type Props = AgencyInfoDefaultProps & {
    hideViewButton: boolean;
};

/**
 * Renders the agency info for a contact form.
 */
const AgencyInfo: React.FC<Props> = ({ hideViewButton = false, ...props }) => {
    if (props?.agency?.type === AgencyType.REAL_ESTATE_INDIVIDUAL_AGENCY) {
        return null;
    }

    return (
        <Flex column className={styles.agencyContainer}>
            <AgencyInfoDefault {...props} />
            {/* @ts-expect-error - TS2322 - Type '{ className: any; children?: ReactNode; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, GTMLeadTrackingProps> & RefAttributes<any> & { children?: ReactNode; }'. */}
            {!hideViewButton && <ViewAllProperties {...props} className={styles.viewAllButton} />}
        </Flex>
    );
};

// @ts-expect-error - TS2345 - Argument of type 'typeof (Anonymous class)' is not assignable to parameter of type 'ComponentType<withI18nProps>'. | TS2554 - Expected 2 arguments, but got 1.
export default CONFIG.build.ENABLE_AGENCY ? withErrorBoundary(AgencyInfo) : () => null;
