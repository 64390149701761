import * as React from 'react';
import { Flex } from 'strat/components';

import { AdImage, AdPrice, AdTitle } from 'horizontal/chat/components';
import { ChatRoomMemberRole } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoom } from 'horizontal/chat/types';

import styles from './styles/subheader.cssm';
import ViewAdButton from './viewAdButton';

type Props = {
    readonly buttonWithText: boolean;
    readonly chatRoom: ChatRoom;
};

const Subheader = ({ buttonWithText, chatRoom }: Props) => {
    const sdk = useChatSDK();
    const { ad, isLoading } = sdk.useRoomContactInfo(chatRoom);
    const isSeller = chatRoom.myRole !== ChatRoomMemberRole.BUYER;

    return (
        <Flex alignCenter className={styles.container}>
            <div className={styles.adImage}>
                <AdImage ad={ad} isLoading={isLoading} />
            </div>
            <Flex column>
                <AdTitle ad={ad} isLoading={isLoading} />
                <AdPrice ad={ad} isLoading={isLoading} />
            </Flex>
            {!isLoading && ad && (
                <ViewAdButton buttonWithText={buttonWithText} ad={ad} isSeller={isSeller} />
            )}
        </Flex>
    );
};

export default Subheader;
