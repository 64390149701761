import { CategoryFieldFilterType } from 'strat/types';

// eslint-disable-next-line no-restricted-imports
import {
    bathsFilterValues,
    bedsFilterValues,
} from 'horizontal/verticals/properties/searchBox/constants';

import { ExtraFieldsValuesType } from './types';

export const extraFieldsValues = {
    price: {
        type: CategoryFieldFilterType.RANGE,
        choices: [],
    },
    ft: {
        type: CategoryFieldFilterType.RANGE,
        choices: [],
    },
    rooms: {
        type: CategoryFieldFilterType.MULTIPLE_CHOICES,
        choices: bedsFilterValues.choices(),
    },
    bathrooms: {
        type: CategoryFieldFilterType.MULTIPLE_CHOICES,
        choices: bathsFilterValues.choices(),
    },
} as ExtraFieldsValuesType;

export const defaultProfileFilterValues = Object.freeze({
    price: { min: null, max: null },
    area: { min: null, max: null },
    beds: [],
    baths: [],
});
