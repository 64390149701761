import React from 'react';

import { StratAPI } from 'horizontal/api';

import type { CheckoutCreditCard } from '../types';

const useUserCreditCards = () => {
    const [creditCards, setCreditCards] = React.useState<Array<CheckoutCreditCard>>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (creditCards.length) {
            setLoading(false);
        }
    }, [creditCards]);

    React.useEffect(() => {
        if (CONFIG.runtime.STRAT_ENABLE_SAVED_CARD_PAYMENTS) {
            setLoading(true);
            new StratAPI()
                .getCustomerInstruments()
                .then((response) => {
                    setLoading(false);
                    if (response.status === 404) {
                        setLoading(false);
                        setCreditCards([]);
                    }
                    if (response.status === 200) {
                        setCreditCards(response?.data);
                    }
                })
                .catch(() => {
                    setCreditCards([]);
                    setLoading(false);
                });
            return () => {
                setCreditCards([]);
                setLoading(false);
            };
        }
    }, []);

    return {
        creditCards,
        cardsLoading: loading,
    };
};

export default useUserCreditCards;
