import * as React from 'react';
import Loadable, { type LoadableOptions } from '@sector-labs/react-loadable-revised';

import { PageState } from 'strat/rendering/types';

import styles from './styles/topLoadingBar.cssm';

type LoadablePageOptions<T, P> = Omit<LoadableOptions<T, P>, 'loading' | 'render'>;

/**
 * Loads a page async.
 */
const Page = <T, P>(loadableConfig: LoadablePageOptions<T, P>) => {
    const Head = Loadable({
        ...loadableConfig,
        render: (loaded: any, props: any) => loaded.default.head(props),
        loading: () => null,
    });

    const Body = Loadable({
        ...loadableConfig,
        render: (loaded: any, props: any) => loaded.default.body(props),
        loading: () => (
            <div className={styles.container}>
                <div className={styles.bar} />
            </div>
        ),
    });

    const state: PageState = {
        loaded: false,
        promise: null,
    };

    const preload = () => {
        if (state.promise) {
            return state.promise;
        }

        state.promise = Promise.all([Head.preload(true), Body.preload(true)])
            .then((values) => {
                state.loaded = true;
                return values;
            })
            .catch((error) => {
                state.promise = null;
                throw error;
            });

        return state.promise;
    };

    return Object.freeze({
        head: (props: {}) => <Head {...props} />,
        body: (props: {}) => <Body {...props} />,
        state,
        preload,
    });
};

export default Page;
