import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import { SearchServiceOptions } from '@sector-labs/fe-search-redux/searchService';

const SearchState = Object.freeze({
    NOT_STARTED: 'not_started',
    STARTED: 'started',
    FINISHED: 'finished',
    FAILED: 'failed',
});

/**
 * The MultiJobSearchHandler is responsible for simplifying the way we do search for multiple jobs at once.
 * When we want to fetch multiple jobs at once, we usually send an array of jobs and receive an array of responses.
 *
 * The problem with this approach is that we may conditionally add some jobs in the search process.
 * This means that all the indexes of the responses will/will not be shifted based on some condition.
 * If we have this case for multiple jobs, the code turns into a mess.
 *
 * The MultiJobSearchHandler allows us to identify the jobs based on a name.
 * We don't have to worry about the index at which a job was added nor about the index of the request.
 * We can just add a job by a name and request the response based on the same name.
 */
class MultiJobSearchHandler {
    _jobIndexByName: {
        [key: string]: number;
    };
    _jobs: SearchJob[];
    _responses: SearchResponse[];
    _state: Values<typeof SearchState>;

    constructor() {
        this._jobIndexByName = {};
        this._jobs = [];
        this._responses = [];
        this._state = SearchState.NOT_STARTED;
    }

    getJobs() {
        return this._jobs;
    }

    addNamedJob(jobName: string, job: SearchJob) {
        if (this._state !== SearchState.NOT_STARTED) {
            throw Error(
                "Search has already been executed, it's not possible to add more named jobs",
            );
        }
        const existentJobIndex = this._jobIndexByName[jobName];
        if (existentJobIndex != null) {
            // The named job already exists, we update the job
            this._jobs[existentJobIndex] = job;
            return;
        }
        this._jobs.push(job);
        this._jobIndexByName[jobName] = this._jobs.length - 1;
    }

    getIndexByJobName(jobName: string): number | null | undefined {
        return this._jobIndexByName[jobName];
    }

    getJobByJobName(jobName: string): SearchJob | null | undefined {
        const index = this.getIndexByJobName(jobName);
        if (index == null) {
            return undefined;
        }
        return this._jobs[index] || undefined;
    }

    getResponseByJobName(jobName: string): SearchResponse | undefined {
        if (this._state !== SearchState.FINISHED) {
            throw Error('Search has not finished, responses are not yet available');
        }
        const index = this.getIndexByJobName(jobName);
        if (index == null) {
            return undefined;
        }
        return this._responses[index] || undefined;
    }

    getSearchState(): Values<typeof SearchState> {
        return this._state;
    }

    search(searchServiceOptions: SearchServiceOptions): Promise<SearchResponse[]> {
        this._state = SearchState.STARTED;
        if (this._jobs.length === 0) {
            this._responses = [];
            this._state = SearchState.FINISHED;
            return Promise.resolve([]);
        }
        const service = new SearchService(searchServiceOptions);
        return service
            .fetchJobs(this._jobs)
            .then((responses) => {
                this._responses = responses;
                this._state = SearchState.FINISHED;
                return responses;
            })
            .catch((error) => {
                this._state = SearchState.FAILED;
                return Promise.reject(error);
            });
    }
}

export default MultiJobSearchHandler;
