import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID } from 'strat/user/session';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import { fetchUserAgencies } from 'horizontal/agent/state';
import Page from 'horizontal/agencyPortal/pages/page';

import { ensureCanAccessAgencyPortalAsOwner } from './ensureCanAccessAgencyPortal';

export type AgentsRouteParams = {
    readonly page?: number;
};

class AgencyPortalAgentsRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_AGENTS, [['^/agencyPortal/agents', '(?:\\?.*)?', '$']]);
    }

    createURL(params: AgentsRouteParams): EnhancedLocation {
        const { page } = params;

        return { pathname: `/agencyPortal/agents`, search: { page } };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortalAsOwner(context)) {
            return;
        }
        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { page },
            },
        } = context;

        context.rendering.renderPage(Page.AGENCY_PORTAL_AGENTS, {
            page,
        });

        const userExternalID = selectUserExternalID(getState());
        context.promise.wait(dispatch(fetchUserAgencies({ userExternalID })));
    }
}

export default new AgencyPortalAgentsRoute();
