import * as React from 'react';
import autoBind from 'react-autobind';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import { Dialog } from 'strat/modal';
import { stopEventPropagation, withErrorBoundary } from 'strat/util';
import { LoadingSpinner } from 'strat/loadable';
import type { PropertyPhotoData } from 'strat/property/types';

import ThumbnailImage from './thumbnailImage';
import ClickBehavior from './clickBehavior';
import ImageSlideshow from './imageSlideshowAsync';
import VideoPlayer from './videoPlayer';
import styles from './styles/imageGalleryDialog.cssm';
import ImageSlideShowItemType from './imageSlideShowItemType';
import DialogCounter from './dialogCounter';

export type SlideShowItemType = PropertyPhotoData & {
    render?: (fullScreen: boolean) => React.ReactElement;
    coverPhotoID?: string;
    type: Values<typeof ImageSlideShowItemType>;
};

/**
 * Properties for {@see ImageGalleryDialog}.
 */
type Props = {
    photos: Array<SlideShowItemType>;
    visible: boolean;
    persistent: boolean;
    onVisibilityChanged: (visible: boolean) => void;
    startIndex?: number;
    slideIndex?: number;
    swiping?: boolean;
    showCaption?: boolean;
    onSlide?: (index: number) => void;
    onNavigate?: () => void;
    ariaLabel?: string;
    clickBehavior?: keyof typeof ClickBehavior;
    containerClassName?: string;
    dismissibleClassName?: string;
    overlayClassName?: string;
    rightArrowStyle?: string;
    leftArrowStyle?: string;
    arrowIconStyle?: string;
    arrowContainerStyle?: string;
    slideDuration?: number;
    renderTracker?: () => Node;
    renderFullscreenContactButtons?: () => React.ReactElement;
    renderSharePropertyButton?: () => React.ReactElement;
    renderCloseButton: (onVisibilityChange: (visible: boolean) => void) => React.ReactElement;
    renderItem?: (item: SlideShowItemType) => React.ReactElement;
    dismissible?: boolean;
    renderCaption?: (photo: PropertyPhotoData) => React.ReactNode;
    withCounter?: boolean;
};

type State = {
    slidesCount: number;
};

const defaultRenderCloseButton = (onVisibilityChanged: any) => (
    <div
        role="button"
        className={styles.closeButton}
        onClick={(event) => {
            onVisibilityChanged(false);
            stopEventPropagation(event);
        }}
        aria-label="Close gallery button"
    >
        <span className={styles.closeIcon} />
    </div>
);

/**
 * Renders a dialog with a video and image gallery/slideshow.
 */
class ImageGalleryDialog extends React.Component<Props, State> {
    static defaultProps = {
        clickBehavior: ClickBehavior.TOGGLE_ARROWS,
        renderCloseButton: defaultRenderCloseButton,
        containerClassName: styles.popup,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = {
            slidesCount: 0,
        };
    }

    onSlide(index: number): void {
        this.setState((prevState) => ({ slidesCount: prevState.slidesCount + 1 }));
        if (this.props.onSlide) {
            this.props.onSlide(index);
        }
    }

    renderItem(slideShowItem: SlideShowItemType) {
        const { type } = slideShowItem;

        if (this.props.renderItem) {
            return this.props.renderItem(slideShowItem);
        }

        if (type === ImageSlideShowItemType.WHATSAPP_CONTACT_FORM) {
            return (
                <div>
                    <ThumbnailImage
                        imageID={this.props.photos[0].id}
                        loadingIndicator={<LoadingSpinner />}
                        title={slideShowItem.title}
                        showCaption={this.props.showCaption}
                        className={styles.darkenedImage}
                        pictureClassName={styles.darkenedImage}
                    ></ThumbnailImage>
                    {slideShowItem.render && slideShowItem.render(true)}
                </div>
            );
        }

        if (slideShowItem.render) {
            return slideShowItem.render(true);
        }

        if (type === ImageSlideShowItemType.VIDEO || type === ImageSlideShowItemType.PANORAMA) {
            const autoPlay =
                !isNil(this.props.startIndex) &&
                this.props.photos &&
                (this.props.photos[this.props.startIndex] || {}).type ===
                    ImageSlideShowItemType.VIDEO;

            return (
                <VideoPlayer
                    autoPlay={autoPlay}
                    coverPhotoID={slideShowItem.coverPhotoID}
                    // @ts-expect-error - TS2322 - Type 'SlideShowItemType' is not assignable to type 'VideoData'.
                    video={slideShowItem}
                    slidesCount={this.state.slidesCount}
                />
            );
        }

        const renderCaption = this.props.renderCaption
            ? () => this.props.renderCaption?.(slideShowItem)
            : undefined;

        return (
            <ThumbnailImage
                imageID={slideShowItem.id}
                loadingIndicator={<LoadingSpinner />}
                title={slideShowItem.title}
                showCaption={this.props.showCaption}
                className={styles.image}
                renderCaption={renderCaption}
            />
        );
    }

    shouldRenderContactButtons(): boolean {
        return this.props.visible && !!this.props.renderFullscreenContactButtons;
    }

    shouldHideContactButtons(): boolean {
        const currentShowItem = this.props.photos[this.props.startIndex || 0];

        if (!currentShowItem) {
            return false;
        }

        return (
            currentShowItem.type === ImageSlideShowItemType.CONTACT_FORM ||
            currentShowItem.type === ImageSlideShowItemType.WHATSAPP_CONTACT_FORM
        );
    }

    render() {
        return (
            <Dialog
                visible={this.props.visible}
                dismissible={this.props.dismissible}
                className={this.props.containerClassName}
                dismissibleClassName={this.props.dismissibleClassName}
                onVisibilityChanged={this.props.onVisibilityChanged}
                overlayClassName={this.props.overlayClassName}
                persistent={this.props.persistent}
                label="Gallery Dialog"
            >
                {this.props.renderCloseButton(this.props.onVisibilityChanged)}

                {this.props.renderSharePropertyButton && this.props.renderSharePropertyButton()}

                <ImageSlideshow
                    // @ts-expect-error connectors are not properly typed
                    ariaLabel={this.props.ariaLabel ? this.props.ariaLabel : null}
                    withVideos
                    startIndex={this.props.startIndex}
                    slideIndex={this.props.slideIndex}
                    photos={this.props.photos}
                    showFullscreenButton={false}
                    clickBehavior={this.props.clickBehavior}
                    renderImage={this.renderItem}
                    swiping={this.props.swiping}
                    onSlide={this.onSlide}
                    onNavigate={this.props.onNavigate}
                    fullscreenGallery
                    renderTracker={this.props.renderTracker}
                    renderFullscreenContactButtons={this.props.renderFullscreenContactButtons}
                    rightArrowStyle={this.props.rightArrowStyle}
                    leftArrowStyle={this.props.leftArrowStyle}
                    arrowIconStyle={this.props.arrowIconStyle}
                    arrowContainerStyle={this.props.arrowContainerStyle}
                    slideDuration={this.props.slideDuration}
                />

                {this.shouldRenderContactButtons() && (
                    <div className={classNames(this.shouldHideContactButtons() && styles.hidden)}>
                        {/* $FlowFixMe - we check if this is null in shouldRenderContactButtons() */}
                        {/* @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'. */}
                        {this.props.renderFullscreenContactButtons()}
                    </div>
                )}
                {!!this.props.withCounter && (
                    <div className={styles.counterContainer}>
                        <DialogCounter
                            slideIndex={(this.props.slideIndex || 0) + 1}
                            photoCount={this.props.photos.length}
                        />
                    </div>
                )}
            </Dialog>
        );
    }
}

// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
export default withErrorBoundary(ImageGalleryDialog);
