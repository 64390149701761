import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import { Flex, Text } from 'strat/components';

import styles from './styles/promotedAds.cssm';

const ExploreMoreButton = () => {
    const i18n = useI18n();

    return (
        <Flex alignCenter className={styles.buttonContainer}>
            <Text.Large bold>{t(i18n)`Explore More`}</Text.Large>
            <IconArrowRight className={styles.buttonIcon} />
        </Flex>
    );
};

export default ExploreMoreButton;
