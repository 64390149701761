import * as React from 'react';

import HistoryRouter from './historyRouter';

export type RouterContextValue = {
    readonly router: HistoryRouter;
};

const RouterContext = React.createContext<RouterContextValue | null | undefined>(null);

export default RouterContext;
