import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { buildCanonicalURLWithoutLanguage } from 'strat/routing';

import { fetchWalletBalance } from 'horizontal/payment/wallet';
import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';

const WalletSubRoutes = Object.freeze({
    OVERVIEW: 'overview',
    INFO: 'info',
    TOP_UP: 'top-up',
});

const defaultWalletSubRoute = WalletSubRoutes.OVERVIEW;

export type WalletRouteParams = {
    readonly section: Values<typeof WalletSubRoutes>;
};

class WalletRoute extends Route {
    constructor() {
        super(RouteNames.WALLET, [
            [
                '^/wallet',
                { name: 'section', pattern: `(?:/(${Object.values(WalletSubRoutes).join('|')}))?` },
                '$',
            ],
        ]);
    }

    createURL(params: WalletRouteParams): EnhancedLocation {
        const { section } = params;
        const sectionSlug = section === defaultWalletSubRoute ? '' : `/${section}`;
        return { pathname: `/wallet${sectionSlug}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            redux: {
                store: { dispatch },
            },
            match: {
                params: { section },
            },
        } = context;
        if (section == WalletSubRoutes.TOP_UP) {
            // This is used to match the url used in the mobile app for the wallet top up marketing campaign
            const url = buildCanonicalURLWithoutLanguage('?utm_campaign=walletTopUp');
            context.http.redirect(url);
            return;
        } else {
            if (!ensureHasActiveUser(context)) {
                return;
            }
            context.rendering.renderPage(Page.WALLET, {
                section: section || defaultWalletSubRoute,
            });
            context.promise.wait(dispatch(fetchWalletBalance()));
        }
    }
}

export default new WalletRoute();
