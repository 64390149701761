import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

const DeliveryCostNote = () => (
    <Flex>
        *
        <Text.Regular>
            <Trans>Our operation team will contact you about the delivery cost</Trans>
        </Text.Regular>
    </Flex>
);

export default DeliveryCostNote;
