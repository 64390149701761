import React from 'react';
import { Text, Flex } from 'strat/components';

import styles from './styles/informationBox.cssm';

type Props = {
    readonly renderIcon: () => Node;
    readonly title: Node;
    readonly content: Node;
};

const InformationBox = ({ renderIcon, title, content }: Props) => {
    return (
        <Flex column className={styles.container}>
            {renderIcon()}
            <Text.Base bold aria-label="title" className={styles.title}>
                {title}
            </Text.Base>
            <p aria-label="Description">{content}</p>
        </Flex>
    );
};

export default InformationBox;
