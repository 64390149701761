import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';
import FilterValues from 'strat/search/filterValues';
import type { LocationData } from 'strat/property';
import { SearchHistory as SearchHistoryBase } from 'strat/search';

import type { Category } from 'horizontal/types';

// @ts-expect-error - TS2417 - Class static side 'typeof import("horizontal/horizontal/search/searchHistory").default' incorrectly extends base class static side 'typeof import("strat/strat/search/searchHistory").default'.
class SearchHistory extends SearchHistoryBase {
    static shouldPushForCategory(oldCategory: Category, newCategory: Category) {
        if (!oldCategory && !newCategory) {
            return false;
        }

        if (oldCategory && !newCategory) {
            return true;
        }

        if (!oldCategory && newCategory) {
            return true;
        }

        const moreGenericCategory = oldCategory.parentID && !newCategory.parentID;

        const changedSubcategory =
            oldCategory.parentID &&
            newCategory.parentID &&
            oldCategory.externalID !== newCategory.externalID;

        const changedMainCategory =
            !oldCategory.parentID &&
            !newCategory.parentID &&
            oldCategory.externalID !== newCategory.externalID;

        return moreGenericCategory || changedSubcategory || changedMainCategory;
    }

    static shouldPushForQuery(oldQuery: string, newQuery: string) {
        if (!oldQuery && !newQuery) {
            return false;
        }

        if (oldQuery && !newQuery) {
            return true;
        }

        if (!oldQuery && newQuery) {
            return true;
        }

        return true;
    }

    static shouldPushForLocation(oldLocation: LocationData, newLocation: LocationData): boolean {
        // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type 'LocationData'.
        if ((!oldLocation && !newLocation) || !oldLocation?.hierarchy) {
            return false;
        }

        return true;
    }

    static push(
        size: number,
        state: Array<SerializedFilterCollection>,
        newSearchFilters: FilterCollection,
    ): Array<FilterCollection> {
        const searchFilters = newSearchFilters.copy([FilterValues.page.attribute]);
        const lastState = state[0] ? new FilterCollection(state[0]) : new FilterCollection();

        const oldLocation = lastState.getFilterValue(FilterValues.location.attribute);
        const newLocation = searchFilters.getFilterValue(FilterValues.location.attribute);
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'LocationData'.
        const shouldPushForLocation = SearchHistory.shouldPushForLocation(oldLocation, newLocation);

        const oldCategory = lastState.getFilterValue(FilterValues.category.attribute);
        const newCategory = searchFilters.getFilterValue(FilterValues.category.attribute);
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Category'.
        const shouldPushForCategory = SearchHistory.shouldPushForCategory(oldCategory, newCategory);

        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const oldMinPhotoCountFilter = lastState.getFilterValue(
            FilterValues.photoCount.attribute,
        )?.min;
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const newMinPhotoCountFilter = searchFilters.getFilterValue(
            FilterValues.photoCount.attribute,
        )?.min;
        const shouldPushForMinPhotoCountFilter = newMinPhotoCountFilter !== oldMinPhotoCountFilter;

        const oldQuery = lastState.filters.query?.value;
        const newQuery = searchFilters.filters.query?.value;
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
        const shouldPushForQuery = SearchHistory.shouldPushForQuery(oldQuery, newQuery);

        const shouldPushNewSearch =
            shouldPushForLocation ||
            shouldPushForCategory ||
            shouldPushForQuery ||
            shouldPushForMinPhotoCountFilter;

        const serializedFilters = searchFilters.serialize();

        // @ts-expect-error - TS2322 - Type 'SerializedFilterCollection[]' is not assignable to type 'FilterCollection[]'.
        return (
            shouldPushNewSearch
                ? [serializedFilters, ...SearchHistory.filterEqualStates(state, searchFilters)]
                : [
                      serializedFilters,
                      ...SearchHistory.filterEqualStates(state.slice(1), searchFilters),
                  ]
        ).slice(0, size);
    }
}

export default SearchHistory;
