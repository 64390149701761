import type { SearchRequestFacetsOption } from '@sector-labs/fe-search-redux/backend';
import { SearchRequestFacetType } from '@sector-labs/fe-search-redux/backend';
import { ExactFilter, SerializedFilter } from '@sector-labs/fe-search-redux/filters';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import FilterValues from 'strat/search/filterValues';

import { AdProduct } from 'horizontal/types';

import commonExcludedFiltersFromFaceting from './commonExcludedFiltersFromFaceting';

const createProductFacetingConfig = (filters?: SerializedFilter[]): SearchRequestFacetsOption => [
    {
        type: SearchRequestFacetType.COMPLEX,
        attribute: FilterValues.product.attribute,
        uniqueAttribute: FilterValues.product.attribute,
        filters: [
            new ExactFilter({
                attribute: FilterValues.product.attribute,
                value: AdProduct.FEATURED,
                key: `product-featured`,
                operator: Operator.OR,
            }).serialize(),
            new ExactFilter({
                attribute: FilterValues.product.attribute,
                value: AdProduct.ELITE,
                key: 'product-elite',
                operator: Operator.OR,
            }).serialize(),
            ...(filters || []).filter(
                (filter) => filter.attribute !== FilterValues.product.attribute,
            ),
        ],
    },
    {
        type: SearchRequestFacetType.COMPLEX,
        attribute: FilterValues.totalProductCount,
        uniqueAttribute: FilterValues.product.attribute,
        filters: [
            new ExactFilter({
                attribute: FilterValues.product.attribute,
                value: AdProduct.FEATURED,
                key: 'product-featured',
                operator: Operator.OR,
            }).serialize(),
            new ExactFilter({
                attribute: FilterValues.product.attribute,
                value: AdProduct.ELITE,
                key: 'product-elite',
            }).serialize(),
        ],
        excludedFilterAttributes: [
            ...commonExcludedFiltersFromFaceting(),
            { attribute: FilterValues.location.attribute, exact: false },
        ],
    },
];

export default createProductFacetingConfig;
