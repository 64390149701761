//@ts-nocheck
import { Component } from 'react';

// eslint-disable-next-line
class ErrorRedirect extends Component {
    render() {
        window.location.href = '/error.html?origin=serverSideRenderingFail';
        return window.location.href;
    }
}

export default ErrorRedirect;
