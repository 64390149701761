import * as React from 'react';
import { isCanceled } from 'strat/util';

import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';

import { PaymentSource, FawryPaymentMethod, PaymentStatus } from '../types';
import type { PaymentHookParams } from '../types';

import useNavigateToFawryPayment from './useNavigateToFawryPayment';
import useNavigateToPaymentFailed from './useNavigateToPaymentFailed';
import useTrackPaymentInitiated from './useTrackPaymentInitiated';

const useFawryPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const navigateToFawryPayment = useNavigateToFawryPayment();
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();

    return React.useCallback(() => {
        if (!order || !order.externalID) {
            return;
        }
        setIsLoading(true);

        const cancelablePromise = makeCancelable(
            // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
            new StratAPI().fawryPayment({
                orderExternalId: order.externalID,
                paymentMethod: FawryPaymentMethod.REFERENCE_NUMBER,
            }),
        );

        cancelablePromise
            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            .then(({ data, status }) => {
                if (status !== 200) {
                    setIsLoading(false);
                    navigateToPaymentFailed(PaymentStatus.FAILURE);
                    return;
                }
                if (data?.referenceNumber) {
                    trackPaymentInitiated(PaymentSource.FAWRY);
                    navigateToFawryPayment({ referenceNumber: data.referenceNumber });
                }
            })
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            .catch((error) => {
                if (isCanceled(error)) {
                    return;
                }
                setIsLoading(false);
            });

        setPaymentPromise(cancelablePromise);
    }, [
        order,
        setIsLoading,
        setPaymentPromise,
        navigateToFawryPayment,
        navigateToPaymentFailed,
        trackPaymentInitiated,
        makeCancelable,
    ]);
};

export default useFawryPayment;
