import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { I18n } from '@lingui/core';
import Category from '@app/branding/category';

import FilterValues from 'strat/search/filterValues';
import { CategoryNodeData } from 'strat/property/types';

const selectCategoryFromFilters = (filters: FilterCollection) =>
    filters.getFilterValue(FilterValues.category.attribute, null) as CategoryNodeData | null;

const selectRootCategoryFromFilters = (
    i18n: I18n,
    filters: FilterCollection,
): any | null | undefined => {
    const category = selectCategoryFromFilters(filters);
    if (!category) {
        return null;
    }

    const filterValuesCategory = FilterValues.category
        .choices(i18n)
        .find((choice) => choice.slug === category.slug);

    return filterValuesCategory.parent || filterValuesCategory;
};

const selectIsRootCategoryResidentialFromFilters = (
    i18n: I18n,
    filters: FilterCollection,
): boolean => {
    const rootCategory = selectRootCategoryFromFilters(i18n, filters);
    if (!rootCategory) {
        return false;
    }

    // @ts-ignore
    const residentialCategory = FilterValues.category.tree(i18n)[Category.RESIDENTIAL];
    if (!residentialCategory) {
        return false;
    }

    return rootCategory && rootCategory.externalID === residentialCategory.externalID;
};

export {
    selectCategoryFromFilters,
    selectRootCategoryFromFilters,
    selectIsRootCategoryResidentialFromFilters,
};
