import React from 'react';
import ErrorIcon from 'strat/assets/icons/iconError.svg';
import { Dialog, Text } from 'strat/components';
import Flex from 'strat/components/flex';

import CheckMarkIcon from 'horizontal/assets/icons/iconCheckMarkBlue_noinline.svg';

import styles from './styles/reportStatusDialog.cssm';

type Props = {
    readonly success: boolean;
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly onCancel: (arg1?: any) => void;
    readonly successMessage: string;
    readonly errorMessage: string;
};

const ReportStatusDialog = ({
    success,
    visible,
    onVisibilityChanged,
    onCancel,
    successMessage,
    errorMessage,
}: Props) => (
    <Dialog
        className={styles.dialogSize}
        onCancel={onCancel}
        visible={visible}
        withCloseButton
        onVisibilityChanged={onVisibilityChanged}
    >
        <Flex column alignCenter className={styles.center}>
            {success ? (
                <>
                    <img
                        className={styles.iconCheckMark}
                        src={CheckMarkIcon}
                        alt={'CheckMarkIcon'}
                    />
                    <Text.Base className={styles.dialogText}>{successMessage}</Text.Base>
                </>
            ) : (
                <>
                    <ErrorIcon className={styles.iconError} />
                    <Text.Base className={styles.dialogText}>{errorMessage}</Text.Base>
                </>
            )}
        </Flex>
    </Dialog>
);

export default ReportStatusDialog;
