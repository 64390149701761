import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useRouter } from 'react-true-router';
import { PageWidthWrapper } from 'strat/generic';
import Logo from '@app/branding/logo';
import { Flex, Text, BackButton, Header } from 'strat/components';

import type { Category } from 'horizontal/types';
import AdCreationContext from 'horizontal/adCreation/context';
import useIsInAgencyPortal from 'horizontal/user/useIsInAgencyPortal';
import { UpdateAdAction } from 'horizontal/adManagement/types';

import AdDetailsForm from './adDetailsForm';
import styles from './styles/adUpdatingForm.cssm';

type Props = {
    readonly action?: Values<typeof UpdateAdAction>;
};

const AdUpdatingForm = ({ action }: Props) => {
    const [activeCategory, setActiveCategory] = React.useState<Category | null | undefined>();
    const router = useRouter();
    const isInAgencyPortal = useIsInAgencyPortal();

    const onBack = React.useCallback(() => {
        router.pop();
    }, [router]);

    return (
        <AdCreationContext.Provider value={[activeCategory, setActiveCategory]}>
            {!isInAgencyPortal ? (
                <Header className={styles.header}>
                    <BackButton onBack={onBack} />
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                </Header>
            ) : null}
            <Flex justifyCenter className={styles.title}>
                <Text.XLarge uppercase bold>
                    <Trans>Edit ad</Trans>
                </Text.XLarge>
            </Flex>
            <PageWidthWrapper>
                <div className={styles.content}>
                    <AdDetailsForm action={action} />
                </div>
            </PageWidthWrapper>
        </AdCreationContext.Provider>
    );
};

export default AdUpdatingForm;
