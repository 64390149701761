import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { Text, ListEntry } from 'strat/components';

import iconDelivery from 'horizontal/assets/icons/iconDeliveryTruck_noinline.svg';
import { UserOrdersSection } from 'horizontal/userOrders/types';

const renderLeft = () => <img alt={'Delivery orders'} src={iconDelivery} />;

const DeliveryOrdersLink = ({ onClick }: { onClick: (arg1: React.MouseEvent<any>) => void }) => {
    const i18n = useI18n();
    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            // TODO: Add view_section to GTM
            onClick(event);
        },
        [onClick],
    );

    return (
        <Link
            route={RouteNames.USER_ORDERS}
            params={{ section: UserOrdersSection.BUYING }}
            title={t(i18n)`Delivery orders`}
            onClick={onTrackedClick}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>{t(i18n)`Delivery orders`}</Text.Large>
            </ListEntry>
        </Link>
    );
};

export default DeliveryOrdersLink;
