import * as React from 'react';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';
import classNames from 'classnames';

import type { AdPhoto } from 'horizontal/types';
import DefaultThumbnailImage from 'horizontal/adDetails/gallery/defaultThumbnailImage';
import { useAd } from 'horizontal/ad/state';
import EliteTag from 'horizontal/branding/eliteTag';

import styles from './styles/coverPhoto.cssm';
import PhotoCountBadge from './photoCountBadge';
import FeaturedBadge from './featuredBadge';

type Props = {
    cover: AdPhoto | null | undefined;
    photoCount?: number | null;
    featured?: boolean;
    elite?: boolean;
    withPhotoCountBadge?: boolean;
    renderTags?: () => JSX.Element | false | undefined;
    renderSocial?: () => JSX.Element | false | undefined;
};

const CoverPhoto = ({
    cover,
    photoCount = null,
    featured,
    elite,
    withPhotoCountBadge,
    renderTags,
    renderSocial,
}: Props) => {
    const ad = useAd();

    const renderAllBadges = () => (
        <>
            {featured && <FeaturedBadge />}
            {elite && <EliteTag elevated />}
            {renderSocial && renderSocial()}
            {renderTags && renderTags()}
            {withPhotoCountBadge && (
                <div className={styles.photoCountContainer}>
                    <PhotoCountBadge photoCount={photoCount} />
                </div>
            )}
        </>
    );

    return (
        <div
            className={classNames({
                [styles.container]: !CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN,
                [styles.redesignedContainer]: CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN,
            })}
        >
            {cover ? (
                <ThumbnailImage
                    className={classNames({
                        [styles.image]: !CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN,
                        [styles.redesignedImage]: CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN,
                    })}
                    imageID={cover.id}
                    size={
                        CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN
                            ? ThumbnailSizes.LARGE
                            : ThumbnailSizes.SMALL
                    }
                    alt={cover.title}
                    title={cover.title}
                    label="Cover photo"
                />
            ) : (
                <DefaultThumbnailImage category={ad?.category} />
            )}
            {CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN && renderAllBadges()}
        </div>
    );
};

export default CoverPhoto;
