import { useEffect } from 'react';

import { Scroll } from 'strat/util';

const useVerticalScroller = (position: number, contentLoaded = true) => {
    useEffect(() => {
        if (position && contentLoaded) {
            Scroll.vertically(position);
        }
    });
};

export default useVerticalScroller;
