import React from 'react';
import { Text, Flex } from 'strat/components';

import type { BusinessPackageSubsection } from 'horizontal/packages/types';

import PackageOffers from './packageOffers';
import styles from './styles/subsectionRedesign.cssm';

type Props = {
    readonly subsection: BusinessPackageSubsection;
};

const Subsection = ({ subsection }: Props) => {
    return (
        <Flex column className={styles.container}>
            <Flex column className={styles.header}>
                <Text.Regular>{subsection.title}</Text.Regular>
            </Flex>
            <Flex className={styles.packageOffersContainer}>
                <PackageOffers offers={subsection.packageOffers} />
            </Flex>
        </Flex>
    );
};

export default Subsection;
