import React from 'react';
import classNames from 'classnames';

import styles from './styles/filterValueButton.cssm';

/**
 * Properties for {@see FilterValueButton}.
 */
type Props = {
    /**
     * CSS class for additional styling.
     */
    className?: string;
    /**
     * Indicates whether this button is currently active.
     */
    selected?: boolean;
    /**
     * CSS class for selected element
     */

    selectedClassName?: string;
    /**
     * Contents of the button to display.
     */
    children?: Node | React.ReactNode;
    /**
     * Handler for onClick event
     */
    onClick: () => void;
    /**
     * Current active design
     */
    design?: string;
};

/**
 * Button that selects a filter value.
 */
const FilterValueButton = ({
    className,
    selected,
    selectedClassName,
    children,
    onClick,
    design,
}: Props): React.ReactElement => {
    const element = React.useRef<HTMLButtonElement | null | undefined>(null);

    const buttonClassName = classNames(styles.button, design, className, {
        [selectedClassName || styles.selected]: selected,
    });

    React.useEffect(() => {
        if (element && element.current && selected) {
            element.current.scrollIntoView({ block: 'nearest' });
        }
    }, [selected]);

    return (
        <span aria-selected={selected} className={classNames(styles.container, design)}>
            <button
                // @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLButtonElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
                ref={element}
                aria-label={children}
                className={buttonClassName}
                onClick={onClick}
            >
                {children}
            </button>
        </span>
    );
};

export default FilterValueButton;
