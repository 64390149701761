import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

import { formatDate } from 'horizontal/util';

import styles from './styles/expiryDate.cssm';
import dateOptions from './dateOptions';

type Props = {
    readonly date: number | null | undefined;
};

const ExpiryDate = ({ date }: Props) => {
    const i18n = useI18n();

    if (!date) {
        return null;
    }

    const formattedDate = formatDate(i18n, date * 1000, dateOptions);

    return (
        <div className={styles.container}>
            <Trans>Your ad expires on {formattedDate}.</Trans>
        </div>
    );
};

export default ExpiryDate;
