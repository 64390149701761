import React from 'react';
import classNames from 'classnames';
import { ViewSections } from 'strat/gtm';

import CategoryLink from '../categoryLink';

import type { CategoryLinkProps } from './types';
import styles from './styles/leafCategory.cssm';

type Props = CategoryLinkProps & {
    readonly noWrapText?: boolean;
};

// Leaf cateogry here means a category which doesn't have any children/categories nested inside it
const LeafCategory = ({ category, onClick, noWrapText }: Props) => {
    return (
        <div
            key={category.id}
            className={classNames(styles.childCategory, {
                [styles.noWrapText]: noWrapText,
            })}
        >
            <CategoryLink
                category={category}
                key={category.id}
                onClick={onClick}
                className={styles.categoryLink}
                viewSection={ViewSections.CATEGORY_DROPDOWN}
            />
        </div>
    );
};

export default LeafCategory;
