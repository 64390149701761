import { createSelector } from 'reselect';
import { selectLanguage } from 'strat/i18n/language/selectors';

import { humanizePeriod } from 'horizontal/util';
import type { Ad, FreeAdLimitUsage } from 'horizontal/types';
import { GlobalState } from 'horizontal/state';

import { findFreeLimitUsageByAd } from './findLimitUsage';

const selectAdFromProps = (
    _: any,
    {
        ad,
    }: {
        ad: Ad | null | undefined;
    },
) => ad;

const selectFreeLimitUsageData = (state: GlobalState): Array<FreeAdLimitUsage> | null | undefined =>
    state.adLimits.freeUsage?.data;

const selectFreeLimitUsageByAd = createSelector(
    selectFreeLimitUsageData,
    selectAdFromProps,
    findFreeLimitUsageByAd,
);

const selectDurationTillNextFreePostByAd = createSelector(
    selectFreeLimitUsageByAd,
    selectLanguage,
    (
        freeUsage: FreeAdLimitUsage | null | undefined,
        language: string,
    ): string | null | undefined => {
        if (!freeUsage || freeUsage.usedQuota < freeUsage.quota || !freeUsage.firstUsedAt) {
            return null;
        }

        const nextFreeAvailableAt = new Date(freeUsage.firstUsedAt);
        nextFreeAvailableAt.setDate(nextFreeAvailableAt.getDate() + freeUsage.durationInDays);

        return humanizePeriod(nextFreeAvailableAt.getTime() - Date.now(), language);
    },
);

const selectCanPostAdForFree = createSelector(
    selectFreeLimitUsageByAd,
    (freeUsage?: FreeAdLimitUsage | null) => freeUsage && freeUsage.usedQuota < freeUsage.quota,
);

export { selectCanPostAdForFree, selectDurationTillNextFreePostByAd, selectFreeLimitUsageByAd };
