import EMPTY_ARRAY from 'strat/empty/array';

import { ProductPurchase, ProductType } from 'horizontal/types';
import type { Product, ProductPackageOffer } from 'horizontal/types';

import { LimitPackageChoiceType } from './types';
import type { LimitPackageChoice } from './types';

const useChoices = (
    productPurchases?: Array<ProductPurchase> | null,
    offers?: Array<ProductPackageOffer> | null,
) => {
    const noChoices = EMPTY_ARRAY as Array<LimitPackageChoice>;

    if (productPurchases && productPurchases.length > 0) {
        return productPurchases.map((p) => ({
            id: p.id,
            type: LimitPackageChoiceType.PURCHASED,
            name: p.productPackageOffer?.productPackage?.name ?? '',
            expiresAt: p.expiresAt,
            availableQuota: p.available,
            hasFeaturedProduct: !!p.productPackageOffer?.productPackage?.productItems?.find(
                (product: Product) => product.type === ProductType.FEATURED,
            ),
            totalQuotaAssignedToAgents: p.totalQuotaAssignedToAgents,
        }));
    }

    if (offers && offers.length > 0) {
        return offers.map((offer) => ({
            id: +offer.packageOfferID,
            type: LimitPackageChoiceType.OFFER,
            price: offer.price,
            discountPrice: offer.discountPrice,
            name: offer.productPackage.name,
            valueProposition: offer?.productPackage.valueProposition,
            label: offer?.label,
            labelColor: offer?.labelColor,
            hasFeaturedProduct: !!offer?.productPackage?.productItems?.find(
                (product: Product) => product.type === ProductType.FEATURED,
            ),
        }));
    }

    return noChoices;
};

export { useChoices };
