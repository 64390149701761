import { useActiveUserExternalID } from 'strat/user/session';

import { ChatRoomMemberRole } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatGTMVarsProps, ChatUserXID } from 'horizontal/chat/types';

const useChatGTMVarsProps = (): ChatGTMVarsProps => {
    const sdk = useChatSDK();
    const chatRoom = sdk.useActiveChatRoom();
    const { ad, isLoading } = sdk.useRoomContactInfo(chatRoom);
    const myUserXID = useActiveUserExternalID() as ChatUserXID;

    const { adXID, myRole, contactXID } = chatRoom ?? {};
    const sellerXID = myRole === ChatRoomMemberRole.BUYER ? contactXID : myUserXID;
    const buyerXID = myRole === ChatRoomMemberRole.BUYER ? myUserXID : contactXID;

    return {
        chatRoom: { adId: adXID, sellerId: sellerXID, buyerId: buyerXID },
        ad: isLoading ? undefined : ad,
    };
};

export default useChatGTMVarsProps;
