import cookie from 'cookie';

import { addMinutes } from 'strat/util/time';
import { CookieNames } from 'strat/cookies';

import { PermissionTypes } from '../types';

import Actions from './actions';
import type {
    BlockNotificationsScriptAction,
    LoadNotificationsScriptAction,
    PostponePromptAction,
    SetSupportedAction,
} from './actions';

const postponePrompt = (postponed: boolean): PostponePromptAction => ({
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"NOTIFICATIONS/POSTPONE_PROMPT"'.
    type: Actions.POSTPONE_PROMPT,
    postponed,
});

const blockNotificationsScript = (): BlockNotificationsScriptAction => ({
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"NOTIFICATIONS/BLOCK_NOTIFICATIONS_SCRIPT"'.
    type: Actions.BLOCK_NOTIFICATIONS_SCRIPT,
});

const loadNotificationsScript = (): LoadNotificationsScriptAction => ({
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"NOTIFICATIONS/LOAD_NOTIFICATIONS_SCRIPT"'.
    type: Actions.LOAD_NOTIFICATIONS_SCRIPT,
});

const setSupported = (supported: boolean): SetSupportedAction => ({
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"NOTIFICATIONS/SET_SUPPORTED"'.
    type: Actions.SET_SUPPORTED,
    supported,
});

const changePermission = (permission: Values<typeof PermissionTypes>) => ({
    type: Actions.CHANGE_PERMISSION,
    permission,
});

const setIsUsingFallbackNotifications = (isUsingFallbackNotifications: boolean) => ({
    type: Actions.SET_IS_USING_FALLBACK_NOTIFICATIONS,
    isUsingFallbackNotifications,
});

/**
 * Compute new date limit for notification backoff
 * and store it in a cookie for cross-app validity
 */
const updateNotificationsBackOff = (promptRetries: number) => {
    const deltaMinutes = ((promptRetries || 0) + 1) * 10;
    const backOffUntil = addMinutes(new Date(), deltaMinutes);
    if (process.env.IS_BROWSER) {
        document.cookie = cookie.serialize(
            CookieNames.NOTIFICATIONS_BACKOFF_UNTIL,
            backOffUntil.toISOString(),
            {
                path: '/',
                maxAge: deltaMinutes * 60,
                secure: window.location.protocol === 'https',
                sameSite: true,
            },
        );
    }
    return backOffUntil;
};

const setNotificationsBackoff = (value: string | Date) => ({
    type: Actions.SET_NOTIFICATIONS_BACKOFF,
    // @ts-expect-error - TS2339 - Property 'getTime' does not exist on type 'string | Date'.
    backOffUntil: value && value.getTime ? value : new Date(value || 0),
});

const triggerNotificationsSlideIn = () => (dispatch: any, getState: any) => {
    const newBackoffDate = updateNotificationsBackOff(getState().promptRetries || 0);
    dispatch(setNotificationsBackoff(newBackoffDate));
};

const closeNotificationsSlideIn = () => ({
    type: Actions.CLOSE_SLIDE_IN,
});

const setPromptVisible = (prompt: string, visible: boolean) => ({
    type: Actions.SET_PROMPT_VISIBLE,
    prompt,
    visible,
});

export {
    postponePrompt,
    blockNotificationsScript,
    setSupported,
    changePermission,
    setIsUsingFallbackNotifications,
    triggerNotificationsSlideIn,
    closeNotificationsSlideIn,
    loadNotificationsScript,
    setPromptVisible,
    setNotificationsBackoff,
};
