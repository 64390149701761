import { StratAPI } from 'strat/api';
import SegmentFetcherFactory from 'strat/segmentFetcher';
import type { FetchState } from 'strat/fetcher';

import type { CategoryFieldCombination } from 'horizontal/types';

export type CategoryFieldCombinationsState = {
    readonly enabled?: boolean;
    readonly loading?: boolean;
} & Partial<Record<number, FetchState<CategoryFieldCombination[]>>>;

export type CategoryFieldCombinationParams = {
    categoryID: number;
};

const factory = new SegmentFetcherFactory<CategoryFieldCombinationParams>(
    ['categoryFieldCombinations'],
    'categoryID',
    ({ params, state }) =>
        new StratAPI(state.i18n.language).categoryFieldCombinations(
            params as CategoryFieldCombinationParams,
        ),
    {
        catchServerErrors: true,
    },
);

const fetchCategoryFieldCombinations = factory.creator();

const categoryFieldCombinationsReducer = factory.reducer();

export { fetchCategoryFieldCombinations };

export default categoryFieldCombinationsReducer;
