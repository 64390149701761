import * as React from 'react';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { useActiveUserExternalID } from 'strat/user/session';

import MakeOfferIcon from 'horizontal/assets/icons/iconMakeOffer.svg';
import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';
import type { ChatMessage } from 'horizontal/chat/types';
import { useFormattedPrice } from 'horizontal/util';

import { getLastMessageText } from './replies';
import styles from './styles/lastMessage.cssm';

type Props = {
    readonly message: ChatMessage;
};

const LastMessage = ({ message }: Props) => {
    const i18n = useI18n();
    const content = message.content[ChatMessageType.NEGOTIATION];

    const userXID = useActiveUserExternalID();
    const formattedPrice = useFormattedPrice(content?.value ?? 0);

    if (!content) {
        return null;
    }

    const isMessageFromUser = message.senderXID === userXID;

    switch (content.state) {
        case ChatNegotiationState.INITIATED:
        case ChatNegotiationState.OFFER_REJECTED:
        case ChatNegotiationState.ACCEPTED:
            return <>{getLastMessageText(content.state, isMessageFromUser, i18n)}</>;
        case ChatNegotiationState.BUYER_MADE_OFFER:
        case ChatNegotiationState.SELLER_MADE_OFFER:
        default:
            return (
                <>
                    <MakeOfferIcon className={styles.icon} />
                    {getLastMessageText(content.state, isMessageFromUser, i18n)}
                    :&nbsp;
                    <Text.Small>{formattedPrice}</Text.Small>
                </>
            );
    }
};

export default LastMessage;
