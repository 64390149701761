import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Trans } from '@lingui/macro';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import Footer from 'horizontal/footer';
import FavouritesAndSavedSearches from 'horizontal/favouritesAndSavedSearches';
import ProfileHeader from 'horizontal/profileHeader';

import FavouritesAndSavedSearchesHead from './favouritesAndSavedSearchesHead';
import styles from './styles/favouritesAndSavedSearchesPageDesktop.cssm';

type Props = {
    activeTab: number;
};

const FavouritesAndSavedSearchesPageDesktop = {
    head: () => <FavouritesAndSavedSearchesHead />,
    body: ({ activeTab }: Props) => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <PageWidthWrapper className={styles.container}>
                <ProfileHeader header={<Trans>Favourites & Saved searches</Trans>} />
                <FavouritesAndSavedSearches activeTab={activeTab} />
            </PageWidthWrapper>
            <Footer />
        </Flex>
    ),
} as const;

export default FavouritesAndSavedSearchesPageDesktop;
