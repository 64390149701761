import * as React from 'react';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Flex } from 'strat/components';
import { flattenCategoryFieldsWithHierarchy } from 'strat/categoryFields/flattenCategoryFields';

import { SellNowListing } from 'horizontal/adCard';

import { Filter, LoadingFilters } from './filters';
import { useFilterableCategoryFields } from './categoryFields';
import { CategoryFilterTree } from './category';
import { LocationFilterTree } from './location';
import { useIsSearchLoading } from './hooks';
import styles from './styles/filtersList.cssm';
import { useCurrentSearchRouteParams } from './routing';

const FiltersList = () => {
    const { extraFields } = useCurrentSearchRouteParams();
    const categoryFields = useFilterableCategoryFields();
    const categoryFieldsHierarchy = flattenCategoryFieldsWithHierarchy(categoryFields, extraFields);
    const loading = useIsSearchLoading();

    return (
        <Flex column className={styles.container} aria-label="Filters list">
            {loading ? (
                <LoadingFilters />
            ) : (
                <>
                    <CategoryFilterTree />
                    <LocationFilterTree />
                    {categoryFieldsHierarchy.map((field) => (
                        <Filter key={field.attribute} field={field} />
                    ))}
                    <SellNowListing viewType={SearchHitsLayout.GRID} onFilterList />
                </>
            )}
        </Flex>
    );
};

export default FiltersList;
