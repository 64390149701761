import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import EMPTY_OBJECT from 'strat/empty/object';
import EMPTY_ARRAY from 'strat/empty/array';
import type { Choice, ChoiceValue } from 'strat/components/types';

import type { Location } from 'horizontal/types';
import { DropdownPopularSuggestions, FilterableDropdown } from 'horizontal/components';
import type { FilterableDropdownProps } from 'horizontal/components';
import { useLocationFacets } from 'horizontal/search/location';

interface Props extends FilterableDropdownProps<Location['id']> {
    level: number;
    hierarchy: Array<Location>;
    formatPlaceholder?: (title?: string) => string;
}

const MINIMUM_CHOICES_NUMBER = 5;

const LocationDropdownWithSuggestions = <T extends Location['id']>({
    title,
    name,
    errorMessage,
    accepted,
    onChange,
    value,
    choices,
    level,
    hierarchy,
    readOnly,
    formatPlaceholder,
    variant,
    lightPlaceholder,
    boldLabel,
    isMandatory,
}: Props) => {
    const i18n = useI18n();
    const popularLocationLabel = level === 1 ? t(i18n)`Cities` : t(i18n)`Neighbourhoods`;

    const currentLocation = hierarchy[level - 1] || EMPTY_OBJECT;
    const facetedLocations = useLocationFacets(level + 1, currentLocation.hierarchy).slice(0, 5);
    const facetedChoices = React.useMemo(
        () =>
            facetedLocations?.map(({ location }) => ({
                id: location.id,
                label: location.name,
                value: location.id,
            })),
        [facetedLocations],
    );
    const popularChoices = choices.length > MINIMUM_CHOICES_NUMBER ? facetedChoices : EMPTY_ARRAY;
    const nonPopularChoices =
        popularChoices.length > 0
            ? choices.filter((location) =>
                  popularChoices.every((popularLocation) => popularLocation.id !== location.id),
              )
            : choices;

    const renderSuggestions = React.useCallback(
        (onClick) => {
            if (!popularChoices.length) {
                return;
            }

            return (
                <DropdownPopularSuggestions
                    fieldName={popularLocationLabel}
                    onClick={onClick}
                    popularChoices={popularChoices as Choice<ChoiceValue>[]}
                    value={value}
                />
            );
        },
        [popularChoices, value, popularLocationLabel],
    );

    return (
        <FilterableDropdown
            title={isMandatory ? title?.concat('*') : title}
            name={name}
            errorMessage={errorMessage}
            accepted={accepted}
            onChange={onChange}
            choices={nonPopularChoices}
            renderSuggestions={renderSuggestions}
            suggestedChoices={popularChoices as Array<Choice<T>>}
            value={value}
            readOnly={readOnly}
            variant={variant}
            placeholder={formatPlaceholder?.(title)}
            lightPlaceholder={lightPlaceholder}
            boldLabel={boldLabel}
        />
    );
};

export default LocationDropdownWithSuggestions;
