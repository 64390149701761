import * as React from 'react';
import { Flex } from 'strat/components';
import { Trans } from '@lingui/macro';
import { Image } from 'strat/util';

import verifiedCarImagePNG from '@app/assets/images/buyVerifiedCar.png';

import VerifiedCarContent from './verifiedCarContent';
import styles from './styles/buyVerifiedCarBanner.cssm';

const verifiedCarImage = {
    source: verifiedCarImagePNG,
};

const BuyVerifiedCarBanner = () => (
    <Flex stretchWidth alignCenter className={styles.verifiedCarContainer} justifySpaceBetween>
        <Flex column className={styles.verifiedCarLeftContainer}>
            <h2 className={styles.header}>
                <Trans>Do you want to buy a verified car by dubizzle?</Trans>
            </h2>
            <VerifiedCarContent />
        </Flex>
        <Image imgClassName={styles.image} image={verifiedCarImage} />
    </Flex>
);

export default BuyVerifiedCarBanner;
