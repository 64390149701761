import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { useSelector, useDispatch } from 'react-redux';
import Category from '@app/branding/category';
import { selectGeoLocationData } from 'strat/user/selectors';
import { fetchGeoLocation } from 'strat/user/state/creators';
import { Text, Flex } from 'strat/components';
import type { AppDispatch } from 'strat/state';

import { findDeepList } from 'horizontal/util/findDeep';
import iconCurrentLocation from 'horizontal/assets/icons/iconCurrentLocation_noinline.svg';
import { useCategoryFilterValue } from 'horizontal/search/category';
import AdCreationContext from 'horizontal/adCreation/context';
import { selectCategories } from 'horizontal/categories';

import styles from './styles/currentLocationSelector.cssm';

const CurrentLocationSelector = ({
    onGeoPositionSelected,
    className,
    renderIcon,
}: {
    onGeoPositionSelected: () => void;
    readonly className?: string;
    readonly renderIcon?: () => React.ReactElement;
}) => {
    const { closestLocation, error } = useSelector(selectGeoLocationData);
    const dispatch = useDispatch<AppDispatch>();
    const [activeCategory] = React.useContext(AdCreationContext);
    const activeFilterCategory = useCategoryFilterValue();
    const actualCategory = activeCategory || activeFilterCategory;
    const categories = useSelector(selectCategories);

    const activeCategoryHierarchy = React.useMemo(
        () => findDeepList(categories, (category) => category.id === actualCategory?.id, true),
        [categories, actualCategory],
    );

    const isProperty = activeCategoryHierarchy.find((cat) => Category?.isOfPropertyType(cat));

    const refetchGeoLocation =
        closestLocation &&
        actualCategory &&
        actualCategory.locationDepthLimits &&
        // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
        ((isProperty && closestLocation.level < actualCategory.locationDepthLimits.max) ||
            // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'.
            (!isProperty && closestLocation.level > actualCategory.locationDepthLimits.max));

    const handleClick = React.useCallback(() => {
        if (!closestLocation || refetchGeoLocation) {
            dispatch(
                fetchGeoLocation(onGeoPositionSelected, null, actualCategory?.locationDepthLimits),
            );
            return;
        }
        onGeoPositionSelected();
    }, [dispatch, onGeoPositionSelected, closestLocation, actualCategory, refetchGeoLocation]);

    return (
        <Flex
            alignCenter
            className={classNames(styles.container, className, { [styles.error]: !!error })}
            onClick={handleClick}
        >
            {renderIcon ? (
                renderIcon()
            ) : (
                <img src={iconCurrentLocation} alt={iconCurrentLocation} />
            )}
            <div className={styles.location}>
                <Text.Large bold>
                    <Trans>Use current location</Trans>
                </Text.Large>
                <div className={classNames(styles.currentLocation, { [styles.error]: !!error })}>
                    <Text.Regular>{closestLocation?.name || error}</Text.Regular>
                </div>
            </div>
        </Flex>
    );
};

export default CurrentLocationSelector;
