import * as React from 'react';
import classNames from 'classnames';

import iconPlus from '@app/assets/icons/iconPlusSell_noinline.svg';
import iconMinus from '@app/assets/icons/iconMinus_noinline.svg';

import Flex from './flex';
import * as Text from './text';
import styles from './styles/counter.cssm';

type Props = {
    readonly onAdd: () => void;
    readonly onSubtract: () => void;
    readonly displayValue: number;
    readonly addDisabled: boolean;
    readonly subtractIcon?: string;
    readonly addIcon?: string;
};

const Counter = ({
    onAdd,
    onSubtract,
    displayValue,
    addDisabled,
    subtractIcon,
    addIcon,
}: Props) => (
    <Flex alignCenter justifySpaceBetween className={styles.container}>
        <Flex onClick={onSubtract} className={styles.signContainer}>
            <img src={subtractIcon ?? iconMinus} alt={'minus'} />
        </Flex>
        <Text.Large bold>{displayValue}</Text.Large>
        <Flex
            onClick={onAdd}
            className={classNames(styles.signContainer, {
                [styles.disabled]: addDisabled,
            })}
        >
            <img src={addIcon ?? iconPlus} alt={'plus'} />
        </Flex>
    </Flex>
);

export default Counter;
