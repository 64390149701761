import * as React from 'react';
import { Flex, Text } from 'strat/components';

import styles from './styles/requestDeliveryForm.cssm';

type FormProps = {
    readonly title: React.ReactNode;
    readonly children: React.ReactNode | Array<React.ReactNode>;
};

const FormSection = ({ title, children }: FormProps) => {
    return (
        <Flex column className={styles.section}>
            <Text.Base bold block className={styles.sectionTitle}>
                {title}
            </Text.Base>
            <Flex column>{children}</Flex>
        </Flex>
    );
};

export default FormSection;
