import * as React from 'react';

import { Link } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { BreadcrumbsMetaData } from 'strat/schema';
import type { CategoryNodeData } from 'strat/property';
import { useI18n } from 'strat/i18n/language';
import { useTrackBreadcrumbClick, PageTypes, withGTM, GTMProps } from 'strat/gtm';
import Purpose, { PurposeTextDisplay } from 'strat/purpose';

import type { BreadcrumbsElement, NameFunction, ParameterFunction, TitleFunction } from './types';
import computeBreadcrumbs from './computeBreadcrumbs';
import { DisplayCategory } from './displayCategory';
import defaultOptions from './options';
import styles from './styles/breadcrumbs.cssm';

/**
 * Props for {@see Breadcrumbs}.
 */
type Props = GTMProps & {
    className?: string;
    elements: BreadcrumbsElement[];
    lastElement?: BreadcrumbsElement | null | undefined;
    purpose: string;
    rentFrequency?: string | null;
    category: CategoryNodeData;
    beds?: number | null | undefined;
    leaf?: boolean;
    /**
     * The role of this parameter is to override the default behaviour for
     * either the last or the second last element and display only the
     * name of the element and the category.
     */
    displayCategory?: Values<typeof DisplayCategory>;
    withHomepage?: boolean;
    withPurposeHeading?: boolean;
    computeElementTitle?: TitleFunction;
    computeElementName?: NameFunction;
    computeParameters?: ParameterFunction;
    // @ts-expect-error - TS2749 - 'PageTypes' refers to a value, but is being used as a type here. Did you mean 'typeof PageTypes'?
    pageType?: PageTypes;
    renderPurposeHeading?: (purpose: string, className?: string) => React.ReactElement;
};

/**
 * The Breadcrumbs component renders each item in the list of received elements
 * into a Breadcrumb.
 */
const Breadcrumbs = ({
    className,
    elements,
    lastElement,
    purpose,
    rentFrequency,
    category,
    beds = null,
    leaf = defaultOptions.leaf,
    displayCategory = defaultOptions.displayCategory,
    withHomepage = defaultOptions.withHomepage,
    withPurposeHeading = defaultOptions.withPurposeHeading,
    computeElementTitle = defaultOptions.computeElementTitle,
    computeElementName = defaultOptions.computeElementName,
    computeParameters = defaultOptions.computeParameters,
    pageType = PageTypes.SEARCH,
    renderPurposeHeading,
}: Props) => {
    const i18n = useI18n();
    const computedBreadcrumbs = computeBreadcrumbs(
        i18n,
        {
            elements,
            lastElement,
            purpose,
            rentFrequency,
            category,
            beds,
        },
        {
            leaf,
            displayCategory,
            withHomepage,
            computeElementName,
            computeElementTitle,
            computeParameters,
        },
    );
    const trackBreadcrumbClick = useTrackBreadcrumbClick(pageType);

    return (
        <div className={className} aria-label="Breadcrumb">
            <BreadcrumbsMetaData breadcrumbs={computedBreadcrumbs} />
            {withPurposeHeading &&
                (renderPurposeHeading ? (
                    renderPurposeHeading(purpose, styles.purpose)
                ) : (
                    <div className={styles.purpose}>
                        {Purpose.text(i18n, purpose, PurposeTextDisplay.CONSISTENT_PREPOSITION)}:
                    </div>
                ))}
            {computedBreadcrumbs.map((element: BreadcrumbsElement, index) => (
                <React.Fragment key={index}>
                    {element.disabled ? (
                        <span className={styles.item} aria-label="Link name">
                            {element.name}
                        </span>
                    ) : (
                        <Link
                            onClick={() => trackBreadcrumbClick(element)}
                            route={element.route || RouteNames.SEARCH}
                            params={element.params}
                            className={styles.link}
                            scrollUp
                            title={element.title}
                            hard={element.hard ? !!element.hard : false}
                        >
                            <span className={styles.item} aria-label="Link name">
                                {element.name}
                            </span>
                        </Link>
                    )}
                    {index !== computedBreadcrumbs.length - 1 && (
                        <div className={styles.delimiter} aria-label="Link delimiter" />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default withGTM(Breadcrumbs);
