import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';

import DialogContext from './dialogContext';

type OutProps = {
    onClick: (event?: React.SyntheticEvent<any>) => void;
};

/**
 * Wraps the specified component by injecting a `onClick`
 * prop that closes the dialog the component is placed in.
 */
const connectCloseDialog = <T extends any>(
    component: Flow.AbstractComponent<T>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'OutProps'.
): Flow.AbstractComponent<Flow.Diff<T, OutProps>> => {
    const Wrapper = (props: T) => {
        const context = React.useContext(DialogContext);

        const onClick = (event: any) => {
            if (context?.dialog) {
                context.dialog.close();
            }
            // @ts-expect-error - TS2339 - Property 'onClick' does not exist on type 'T'.
            if (props.onClick) {
                // @ts-expect-error - TS2339 - Property 'onClick' does not exist on type 'T'.
                props.onClick(event);
            }
        };

        // @ts-expect-error - TS2698 - Spread types may only be created from object types.
        return React.createElement(component, { ...props, onClick });
    };

    // @ts-expect-error - TS2322 - Type '{ (props: T, context: any): React.ReactElement<React.PropsWithoutRef<T> & React.RefAttributes<any>, string | React.JSXElementConstructor<any>>; contextTypes: { ...; }; }' is not assignable to type 'AbstractComponent<Diff<T, OutProps>, any>'.
    return Wrapper;
};

export default connectCloseDialog;
