import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { AdBadgeType } from 'horizontal/types';
import VerifiedProfileBadge from 'horizontal/profile/verifiedProfileBadge';

import styles from './styles/adTag.cssm';

type Props = {
    badgeType?: Values<typeof AdBadgeType>;
    name?: string;
};

const renderBadge = (text: string) => {
    return (
        <button className={styles.cardButton} aria-label={`${text}`}>
            {text}
        </button>
    );
};

const renderVerifiedProfileBadge = (isAgency: boolean) => (
    <VerifiedProfileBadge isAgency={isAgency} />
);

const AdTag = ({ badgeType, name }: Props) => {
    const i18n = useI18n();

    if (name) {
        return renderBadge(name);
    }
    switch (badgeType) {
        case AdBadgeType.DELIVERY:
            return renderBadge(t(i18n)`Delivery`);
        case AdBadgeType.VERIFIED:
        case AdBadgeType.DELIVERY_VERIFIED:
            return renderBadge(t(i18n)`Verified`);
        case AdBadgeType.DOCS_ATTACHED:
            return renderBadge(t(i18n)`Docs Attached`);
        case AdBadgeType.SELLER_VERIFIED:
            return renderVerifiedProfileBadge(false);
        case AdBadgeType.AGENCY_VERIFIED:
            return renderVerifiedProfileBadge(true);
        default:
            return null;
    }
};

export default AdTag;
