import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Flex, Text } from 'strat/components';

import { useNavigateToUserOrders, UserOrdersSection } from 'horizontal/userOrders';
import iconWallet from '@app/assets/icons/iconDeliveryRequestSuccess_noinline.svg';

import styles from './styles/requestDeliverySuccess.cssm';

const RequestDeliverySuccess = () => {
    const navigateToUserOrders = useNavigateToUserOrders();
    const navigateToMyDeliveryOrders = React.useCallback(() => {
        navigateToUserOrders({ section: UserOrdersSection.BUYING });
    }, [navigateToUserOrders]);

    return (
        <Flex className={styles.successPageLayout} alignCenter justifyCenter>
            <Flex column className={styles.successPageContent} alignCenter>
                <img src={iconWallet} className={styles.walletIcon} alt="request successfully" />
                <Text.Base bold className={styles.successPageTitle}>
                    <Trans>Your order has been placed successfully</Trans>
                </Text.Base>
                <Text.Base className={styles.successPageSubtitle}>
                    <Trans>You can track your order status from your account</Trans>
                </Text.Base>
                <Button onClick={navigateToMyDeliveryOrders}>
                    <Trans>View my orders</Trans>
                </Button>
            </Flex>
        </Flex>
    );
};

export default RequestDeliverySuccess;
