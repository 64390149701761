import PCancelable from 'p-cancelable';

export type TypedCancelablePromise<T = any> = PCancelable<T>;

// @deprecated - Use TypedCancelablePromise instead
// @ts-expect-error - TS2314 - Generic type 'PCancelable<ValueType>' requires 1 type argument(s).
export type CancelablePromise = PCancelable;

function makeCancelable<T = any>(promise: Promise<T>): PCancelable<T> {
    return PCancelable.fn((onCancel) => {
        onCancel(() => {});
        return promise;
    })();
}

function isCanceled(error: any): boolean {
    return error && error.name === 'CancelError';
}

function catchCanceled(error: Error) {
    if (isCanceled(error)) {
        return;
    }

    throw error;
}

export { makeCancelable, catchCanceled, isCanceled };
