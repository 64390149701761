import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { t } from '@lingui/macro';

import { ChatRoomLink } from 'horizontal/chat/widgets/large';
import { useAd } from 'horizontal/ad/state';
import { useTrackChatInit } from 'horizontal/gtm';

import DescriptionLink from './descriptionLink';

const DescriptionChatWithSellerButton = () => {
    const i18n = useI18n();
    const chatWithSellerText: string = t(i18n)`Chat with seller`;
    const { externalID: adExternalID, userExternalID: sellerID } = useAd() || {};
    const trackChatInit = useTrackChatInit();

    if (!adExternalID || !sellerID) {
        return null;
    }

    return (
        <ChatRoomLink adXID={adExternalID} sellerXID={sellerID}>
            <DescriptionLink text={`(${chatWithSellerText})`} onClick={trackChatInit} />
        </ChatRoomLink>
    );
};

export default DescriptionChatWithSellerButton;
