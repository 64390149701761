import * as React from 'react';
import { useRouter } from 'react-true-router';
import { ViewSections } from 'strat/gtm';
import withGTMLeadTracking, { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import type { PropertyData } from 'strat/property/types';
import { RouteNames } from 'strat/routes';

import { useEnsureHasActiveUser } from 'horizontal/user';
import type { StratChatRoomRouteParams } from 'horizontal/routes/chatRoute';
import { ChatNavAction } from 'horizontal/chat/constants';

type Props = GTMLeadTrackingProps & {
    readonly adXID: string;
    readonly sellerXID: string;
    readonly makeOffer?: boolean;
    readonly className?: string;
    readonly ariaLabel?: string;
    readonly children: React.ReactNode;
    readonly closeDialog?: () => void;
    readonly viewSection?: Values<typeof ViewSections>;
    readonly property?: PropertyData | null;
};

const ChatRoomLink = ({
    adXID,
    sellerXID,
    makeOffer,
    children,
    className,
    ariaLabel,
    trackOfferView,
    trackChatAttempt,
    viewSection,
    closeDialog,
    property,
}: Props) => {
    const router = useRouter();

    const goToChat = React.useCallback(() => {
        if (makeOffer) {
            trackOfferView(ViewSections.PROPERTY_DETAIL);
        }

        router.pushRoute(RouteNames.CHAT, {
            subjectXID: adXID,
            contactXID: sellerXID,
            navAction: ChatNavAction.CREATE,
            makeOffer,
        } as StratChatRoomRouteParams);
        closeDialog?.();
    }, [trackOfferView, makeOffer, router, adXID, sellerXID, closeDialog]);

    const onClick = useEnsureHasActiveUser({
        onDone: goToChat,
        onLoginDialogOpen: () => {
            trackChatAttempt(viewSection || ViewSections.PROPERTY_DETAIL, property);
        },
    });

    return (
        <span onClick={onClick} className={className} aria-label={ariaLabel}>
            {children}
        </span>
    );
};

export default withGTMLeadTracking<Props>(ChatRoomLink);
