import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { Text, ListEntry } from 'strat/components';

import NotificationIcon from './notificationIcon';
import styles from './styles/notificationsTrigger.cssm';

const NotificationsTrigger = ({
    onClick,
    newNotifications,
    hiddenTitle,
}: {
    onClick?: () => void;
    newNotifications: boolean;
    hiddenTitle?: boolean;
}) => {
    const renderLeft = React.useCallback(
        () => (
            <div className={classNames({ [styles.newNotifications]: newNotifications })}>
                <NotificationIcon />
            </div>
        ),
        [newNotifications],
    );

    return (
        <ListEntry
            borderless
            hoverable
            renderLeft={renderLeft}
            renderRight={null}
            onClick={onClick}
            smallContainer={hiddenTitle}
        >
            {!hiddenTitle && (
                <Text.Large>
                    <Trans>Notifications</Trans>
                </Text.Large>
            )}
        </ListEntry>
    );
};

export default NotificationsTrigger;
