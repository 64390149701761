import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectNumberFormatterFactory } from '../language/selectors';

import selectCurrency from './selectors';
import {
    formatPrice as formatPriceBase,
    formatLocalPriceValue as formatLocalPriceValueBase,
    toLocalPrice as toLocalPriceBase,
} from './localPriceFormatting';
import convertPrice from './convertPrice';

const useLocalPrice = () => {
    const numbetFormatterFactory = useSelector(selectNumberFormatterFactory);
    const { currency, baseCurrency, exchangeRates } = useSelector(selectCurrency);

    /**
     * Formats the specified price, observing the
     * base currency's rules for formatting.
     */
    const formatPrice = React.useCallback(
        (value: number, priceCurrency: string = baseCurrency, options: any = {}): string => {
            return formatPriceBase(value, priceCurrency, options, numbetFormatterFactory);
        },
        [baseCurrency, numbetFormatterFactory],
    );

    /**
     * Formats the specified price, but without the currency name
     * or currency symbol, observing the base currency's rules for formatting.
     */
    const formatPriceValue = React.useCallback(
        (value: number, priceCurrency: string = baseCurrency): string => {
            return formatPrice(value, priceCurrency, { style: 'decimal' });
        },
        [formatPrice, baseCurrency],
    );

    /**
     * Formats the specified price, but without the currency
     * name or currency symbol, observing the current currency's
     * rules for formatting.
     */
    const formatLocalPriceValue = React.useCallback(
        (value: number, options?: any): string => {
            return formatLocalPriceValueBase(value, currency, numbetFormatterFactory, options);
        },
        [currency, numbetFormatterFactory],
    );

    /**
     * Converts the specified price, noted in the current
     * currency into a valid price in the base currency with no more than 2 decimals.
     */
    const fromLocalPrice = React.useCallback(
        (value: number): number => {
            return (
                Math.round(
                    convertPrice({
                        price: value,
                        from: currency,
                        to: baseCurrency,
                        exchangeRates,
                    }) * 100,
                ) / 100
            );
        },
        [currency, baseCurrency, exchangeRates],
    );

    /**
     * Converts the specified price, noted in the base
     * currency into a valid price in the local currency.
     *
     * If fixRoundTripping, round away single-digit rounding errors due to conversions
     * like 1000000 => base currency => 1000001
     */
    const toLocalPrice = React.useCallback(
        (
            value: number | null | undefined,
            from: string = baseCurrency,
            to: string = currency,
            fixRoundTripping = false,
        ): number => {
            const convertedValue = toLocalPriceBase(
                value,
                from || baseCurrency,
                to || currency,
                exchangeRates,
            );

            if (fixRoundTripping && from !== to && convertedValue >= 9999) {
                const roundedValue = Math.round(convertedValue * 0.1) * 10;
                if (Math.abs(roundedValue - convertedValue) <= 1) {
                    return roundedValue;
                }
            }

            return convertedValue;
        },
        [baseCurrency, currency, exchangeRates],
    );

    return {
        currency,
        formatPrice,
        formatPriceValue,
        formatLocalPriceValue,
        fromLocalPrice,
        toLocalPrice,
    };
};

export default useLocalPrice;
