import * as React from 'react';
import { makeCancelable } from 'strat/util';
import { useI18n } from 'strat/i18n/language';
import { useActiveUser } from 'strat/user/session';

import { StratAPI } from 'horizontal/api';

const useRejectionReason = (adExternalID: string) => {
    const user = useActiveUser();
    const userExternalID = user?.externalID;
    const { locale: language } = useI18n();

    const [rejectionReason, setRejectionReason] = React.useState<any>(null);
    const [rejectionReasonLoading, setRejectionReasonLoading] = React.useState(true);

    React.useEffect(() => {
        if (userExternalID && adExternalID) {
            setRejectionReasonLoading(true);
            const cancelablePromise = makeCancelable(
                new StratAPI().adStateDetails(userExternalID, adExternalID),
            );
            cancelablePromise.then(({ data, status }) => {
                setRejectionReasonLoading(false);
                if (status === 404) {
                    setRejectionReason(null);
                } else {
                    const messageFieldName =
                        language === CONFIG.build.LANGUAGE_CODE ? 'longMessage' : 'longMessage_l1';
                    setRejectionReason(data[messageFieldName]);
                }
            });
        } else {
            setRejectionReasonLoading(false);
            setRejectionReason(null);
        }
    }, [userExternalID, adExternalID, setRejectionReason, setRejectionReasonLoading, language]);

    return [rejectionReason, rejectionReasonLoading];
};

export default useRejectionReason;
