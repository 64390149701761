import { StratAPI } from 'strat/api';
import type { AppDispatch, GlobalState } from 'strat/state';
import { removeItem, setItem } from 'strat/misc/localStorage';
import { selectLanguage } from 'strat/i18n/language';
import { AgentData } from 'strat/agency/agent/types';
import type { FetchState, FetchAction } from 'strat/fetcher';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';

import { LOGGED_IN_AGENT_KEY_LOCAL_STORAGE } from '../shouldFetchAgentData';

type FetchAgentParams = {
    userExternalID?: string;
};

const factory = new FetcherFactory(
    'loggedInAgentData',
    (params: FetchAgentParams, state: GlobalState) => {
        const language = selectLanguage(state);
        if (!params.userExternalID) {
            return Promise.resolve({
                data: null,
                status: 200,
            });
        }

        return new StratAPI(language)
            .agentCompleteProfile(params.userExternalID)
            .then((response) => {
                if (response.status === 200 || response.status === 304) {
                    const loggedInAgentData = response.data;
                    if (window.localStorage) {
                        setItem(LOGGED_IN_AGENT_KEY_LOCAL_STORAGE, loggedInAgentData, {
                            daysAvailable: 7,
                        });
                    }

                    return {
                        data: loggedInAgentData,
                        status: 200,
                    };
                }
                return {
                    data: null,
                    status: 200,
                };
            });
    },
);

const fetchLoggedInAgentData = factory.creator();
const deleteLoggedInAgentData = () => ({
    type: factory.actions.clear,
});

const loggedInAgentDataFetcherReducer = factory.reducer();

enum Actions {
    SET_LOGGED_IN_AGENT = 'AGENT_DATA/SET_LOGGED_IN_AGENT',
}

type SetLoggedInAgentData = {
    type: Actions.SET_LOGGED_IN_AGENT;
    agentData: AgentData;
};

type AgentDataAction = SetLoggedInAgentData;

const loggedInAgentDataReducer = (
    state: FetchState | null | undefined = buildDefaultState(),
    action: AgentDataAction | FetchAction,
) => {
    switch (action.type) {
        case Actions.SET_LOGGED_IN_AGENT:
            return {
                ...state,
                data: (action as SetLoggedInAgentData).agentData,
            };

        default:
            return loggedInAgentDataFetcherReducer(state, action);
    }
};

const clearLoggedInAgentData = () => (dispatch: AppDispatch) => {
    removeItem(LOGGED_IN_AGENT_KEY_LOCAL_STORAGE);
    dispatch(deleteLoggedInAgentData());
};

const setLoggedInAgentData = (agentData: AgentData) => ({
    type: Actions.SET_LOGGED_IN_AGENT,
    agentData,
});

export default loggedInAgentDataReducer;
export { clearLoggedInAgentData, fetchLoggedInAgentData, setLoggedInAgentData };
export type { FetchState, FetchAction };
