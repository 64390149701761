import * as React from 'react';
import { Flex } from 'strat/components';

import AdCardHeroCategoryVideoBadgeIcon from 'horizontal/assets/icons/iconAdVideo_noinline.svg';

import styles from './styles/videoIcon.cssm';

const VideoIcon = () => {
    return (
        <Flex className={styles.background}>
            <img src={AdCardHeroCategoryVideoBadgeIcon} alt="Video Icon" className={styles.icon} />
        </Flex>
    );
};

export default VideoIcon;
