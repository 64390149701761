import type { NumberFormatterFactory } from 'strat/i18n/language/connectLanguage';
import type { ExchangeRates } from 'strat/i18n/money';
import { Currency } from 'strat/i18n/money';

import convertPrice from './convertPrice';

/**
 * Formats the specified price, observing the
 * base currency's rules for formatting.
 */
export const formatPrice = (
    value: number,
    currency: string,
    options: any | null | undefined = {},
    numberFormatterFactory: NumberFormatterFactory,
): string => {
    const formattingSettings = {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        ...options,
    } as const;

    return numberFormatterFactory(formattingSettings).format(value);
};

/**
 * Formats the specified price, but without the currency
 * name or currency symbol, observing the current currency's
 * rules for formatting.
 */
export const formatLocalPriceValue = (
    value: number,
    currency: Values<typeof Currency>,
    intlNumberFormat: NumberFormatterFactory,
    options: any = {},
): string => formatPrice(value, currency, { style: 'decimal', ...options }, intlNumberFormat);

/**
 * Converts the specified price, noted in the base
 * currency into a valid price in the local currency.
 */
export const toLocalPrice = (
    value: number | null | undefined,
    fromCurrency: Values<typeof Currency> | null | undefined = null,
    toCurrency: Values<typeof Currency> | null | undefined = null,
    exchangeRates: ExchangeRates,
): number =>
    Math.round(
        convertPrice({
            price: value,
            from: fromCurrency,
            to: toCurrency,
            exchangeRates,
        }),
    );
