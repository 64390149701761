import * as React from 'react';

import styles from './styles/dialog.cssm';
import Dialog from './dialog';
import type { Props as DialogProps } from './dialog';

/**
 * A dialog which is shown as a popup / floating window both on mobile and desktop
 * Normal dialog show floating window only on desktop.
 */
const FloatingDialog = (props: DialogProps) => {
    return <Dialog {...props} className={styles.floatingContainer} />;
};

export default FloatingDialog;
