import * as React from 'react';
import { Flex, Text, Button } from 'strat/components';
import { Link } from 'strat/navigation';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import { RouteNames } from 'strat/routes';

import GreenCheckIcon from 'horizontal/assets/icons/greenCheckIcon.svg';
import { VerticalsRouteClass } from 'horizontal/routes';

import styles from './styles/sellCarSuccessMessage.cssm';

const SellCarSuccessMessage = ({ requestID }: { requestID: string }) => {
    return (
        <PageWidthWrapper>
            <Flex column alignCenter justifyCenter className={styles.container}>
                <GreenCheckIcon />
                <h1 className={styles.header}>
                    <Trans>Request sent successfully</Trans>
                </h1>
                <Text.XLarge bold>
                    <Trans>Request no. {requestID}</Trans>
                </Text.XLarge>
                <Text.Large>
                    <Trans>
                        Cash my car request has been sent successfully, dubizzle team will contact
                        you soon
                    </Trans>
                </Text.Large>
                <Link
                    className={styles.stretch}
                    route={RouteNames.HORIZONTAL_VERTICALS}
                    params={{ vertical: VerticalsRouteClass.verticals.CARS }}
                >
                    <Button stretch>
                        <Trans>Sell another car</Trans>
                    </Button>
                </Link>
                <Link className={styles.stretch} route={RouteNames.HOME}>
                    <Button stretch secondary>
                        <Trans>dubizzle home page</Trans>
                    </Button>
                </Link>
            </Flex>
        </PageWidthWrapper>
    );
};

export default SellCarSuccessMessage;
