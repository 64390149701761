import Loadable from 'strat/loadable';

import Description from './description';
import Details from './details';
import Features from './features';
import MapDialog from './mapDialog';
import ReportAd from './reportAd';
import Overview from './overview';
import LoadingDescription from './loadingDescription';
import AdExternalID from './adExternalID';
import SafetyRules from './safetyRules';
import RequestDeliverySection from './requestDeliverySection';
import LocationAndId from './locationAndId';
import HighlightedDetails from './highlightedDetails';

const Seller = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'seller' */
            './seller'
        ),
    loading: () => null,
});

const OwnAdSection = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'ownAdsSection' */
            './ownAdSection'
        ),
    loading: () => null,
});

export {
    Description,
    Details,
    MapDialog,
    ReportAd,
    Overview,
    LoadingDescription,
    Seller,
    OwnAdSection,
    AdExternalID,
    Features,
    SafetyRules,
    RequestDeliverySection,
    LocationAndId,
    HighlightedDetails,
};
