import * as React from 'react';
import isEqual from 'lodash/isEqual';

import { usePreviousState } from 'strat/util';

import type { Size, TargetingParameters, Slot } from './types';
import useGooglePublisherTag from './useGooglePublisherTag';

const PIXEL_SIZE = [{ width: 1, height: 1 }];

const computeContainerID = (
    index: number | null | undefined,
    pos: string | null | undefined,
    pixel: boolean,
): string => {
    if (pixel) {
        return 'ad-placement-pixel';
    }

    const position = pos ? `-${pos}` : '';
    const idx = index || index === 0 ? `-${index.toString()}` : '';

    return `ad-placement${position}${idx}`;
};

const applySlotTargeting = (slot?: Slot | null, parameters?: TargetingParameters | null): void => {
    if (!slot || !parameters) {
        return;
    }

    slot.clearTargeting();

    Object.entries(parameters).forEach((param) => {
        if (param[1] === null || param[1] === undefined || param[1]?.length === 0) {
            return;
        }
        slot.setTargeting(param[0], param[1]);
    });
};

type Props = {
    readonly name: string;
    readonly parameters?: TargetingParameters | null | undefined;
    readonly index?: number;
    readonly sizes?: Array<Size>;
    readonly collapsible?: boolean;
    readonly className?: string;
    readonly singleRequest?: boolean;
    readonly adjustScroll?: boolean;
};

const AdBanner = ({
    name,
    index,
    sizes = [],
    parameters,
    collapsible,
    className,
    singleRequest,
    adjustScroll = false,
}: Props) => {
    const tagManager = useGooglePublisherTag();
    const slot = React.useRef<Slot | null | undefined>(null);
    const containerRef = React.useRef<HTMLElement | null | undefined>(null);
    const prevElement = React.useRef<HTMLElement | null | undefined>(null);
    const resizeObserver = React.useRef<ResizeObserver | null>(null);
    const previousParameters = usePreviousState(parameters);
    const previousName = usePreviousState(name);

    const containerID = computeContainerID(
        index,
        parameters?.pos,
        JSON.stringify(sizes) === JSON.stringify(PIXEL_SIZE),
    );

    React.useEffect(() => {
        if (!tagManager) {
            return undefined;
        }
        tagManager.cmd.push(() => {
            tagManager.pubads().enableLazyLoad({
                // Fetch slots within 0.5 viewports.
                fetchMarginPercent: 50,
                // Render as soon as the banner is fetched.
                renderMarginPercent: -1,
                // Double the above values on mobile
                mobileScaling: 2.0,
            });

            if (singleRequest) {
                tagManager.pubads().enableSingleRequest();
            }

            if (collapsible) {
                tagManager.pubads().collapseEmptyDivs();
            }
        });

        return () => {
            tagManager.cmd.push(() => {
                if (slot.current) {
                    tagManager.destroySlots([slot.current]);
                }
            });
        };
    }, [containerID, tagManager, singleRequest, collapsible]);

    React.useEffect(() => {
        if (!tagManager || !containerRef.current) {
            return;
        }
        tagManager.cmd.push(() => {
            if (!containerRef.current) {
                return;
            }

            const slots = tagManager.pubads().getSlots();

            containerRef.current.id = containerID;
            slot.current = slots.find((s) => s.getSlotElementId() === containerRef.current?.id);

            // Destroy previous banner for a new ad unit id, happening on purpose change
            if (slot.current && previousName && previousName !== name) {
                tagManager.destroySlots([slot.current]);
                slot.current = null;
            }

            if (!slot.current) {
                const isFluid = !sizes.length;
                slot.current = tagManager.defineSlot(
                    name,
                    isFluid
                        ? 'fluid'
                        : sizes.map((size) =>
                              // @ts-expect-error - TS2367 - This condition will always return 'true' since the types 'Size' and 'string' have no overlap.
                              size !== 'fluid' ? [size.width, size.height] : size,
                          ),
                    containerRef.current.id,
                );

                slot.current.addService(tagManager.pubads());

                applySlotTargeting(slot.current, parameters);
                tagManager.enableServices();

                tagManager.display(containerRef.current.id);

                return;
            }

            if (!isEqual(parameters, previousParameters)) {
                applySlotTargeting(slot.current, parameters);
                tagManager.pubads().refresh([slot.current]);
            }
        });
    }, [
        tagManager,
        containerRef,
        name,
        sizes,
        parameters,
        slot,
        previousParameters,
        previousName,
        containerID,
    ]);

    React.useEffect(() => {
        if (prevElement.current === containerRef.current) {
            return;
        }
        resizeObserver.current?.disconnect();
        const element = containerRef?.current;

        if (
            adjustScroll &&
            document.documentElement.style['overflowAnchor'] === undefined &&
            element
        ) {
            prevElement.current = element;

            let loadedHeight = element.clientHeight;
            const observer = new ResizeObserver(() => {
                if (element.getBoundingClientRect().top < 0) {
                    window.scrollTo(
                        window.scrollX,
                        window.scrollY + element.clientHeight - loadedHeight,
                    );
                    loadedHeight = element.clientHeight;
                }
            });
            observer.observe(element);

            resizeObserver.current = observer;
        }
    });

    // @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
    return <div ref={containerRef} className={className} />;
};

export default CONFIG.build.DISABLE_BANNERS ? () => null : AdBanner;
