//@ts-nocheck
import settings from '@app/branding/settings';

/**
 * Compute localized attribute name
 * If we're on a merged index, a localized attribute has its simple name if its the main language, otherwise
 * it's suffixed with the index of the language, form the order they are defined by `settings`
 * If we're not on a merged index, the current language is considered to be the first in the list of languages,
 * and suffixing with `_l1`, `_l2` is based on this order
 * @param attributeName
 * @param currentLanguage
 * @param targetLanguage
 * @param mergedIndex
 * @returns {string}
 */
const getLocalizedAttribute = (
    attributeName: string,
    currentLanguage: string,
    targetLanguage: string,
    mergedIndex?: boolean,
) => {
    if (currentLanguage === targetLanguage) {
        return attributeName;
    }

    if (mergedIndex) {
        const languageIndex = settings.languages.findIndex((l) => l.lang === targetLanguage);
        return `${attributeName}_l${languageIndex}`;
    }

    const languageIndex =
        settings.languages
            .filter((l) => l.lang !== currentLanguage)
            .findIndex((l) => l.lang === targetLanguage) + 1;

    return `${attributeName}_l${languageIndex}`;
};

export default getLocalizedAttribute;
