import * as React from 'react';

import type { CategoryFieldsFilters, FlatCategoryField } from 'horizontal/types';
import { CategoryFieldRole, CategoryFieldFilterType } from 'horizontal/types';
import { useFlatCategoryFields } from 'horizontal/categoryFields';

const concatExtraFieldsLabels = (
    categoryFields: Array<FlatCategoryField>,
    extraFields: CategoryFieldsFilters | null | undefined,
    role: Values<typeof CategoryFieldRole>,
): [Array<string>, Set<Values<typeof CategoryFieldRole>>] => {
    if (!extraFields) {
        return [[], new Set()];
    }

    const concattedFields = categoryFields
        .map((field) => {
            const value = extraFields[field.attribute];
            if (!value) {
                return { field, text: '' };
            }

            if (!field.roles || !field.roles.includes(role)) {
                return { field, text: '' };
            }

            switch (field.filterType) {
                case CategoryFieldFilterType.SINGLE_CHOICE: {
                    const selectedChoice = field.choices.find((choice) => value === choice.value);
                    if (!selectedChoice) {
                        return { field, text: '' };
                    }

                    return { field, text: selectedChoice.label };
                }

                case CategoryFieldFilterType.MULTIPLE_CHOICES: {
                    const selectedChoices = field.choices.filter((choice) =>
                        // @ts-expect-error - TS2339 - Property 'includes' does not exist on type 'string | number | true | Range | Primitive[]'.
                        value.includes(choice.value),
                    );

                    // fields with more than one choice active are excluded
                    // TODO: research if it would be better to do `choice[0]/choice[1]` for example
                    if (!selectedChoices.length || selectedChoices.length > 1) {
                        return { field, text: '' };
                    }

                    return {
                        field,
                        text: selectedChoices.map((choice) => choice.label).join(' '),
                    };
                }

                default:
                    return { field, text: '' };
            }
        })
        .filter(({ text }) => !!text)
        .sort(({ field: fieldA }, { field: fieldB }) => {
            if (fieldA.titlePriority > fieldB.titlePriority) {
                return 1;
            }

            if (fieldA.titlePriority < fieldB.titlePriority) {
                return -1;
            }

            return 0;
        });

    const labels = concattedFields.map(({ text }) => text);
    const roles = new Set(concattedFields.flatMap(({ field }) => field.roles || []));

    return [labels, roles];
};

const useExtraFieldsLabels = (
    categoryID: number | null | undefined,
    extraFields: CategoryFieldsFilters | null | undefined,
    role: Values<typeof CategoryFieldRole>,
): [Array<string>, Set<Values<typeof CategoryFieldRole>>] => {
    // @ts-expect-error - TS2345 - Argument of type 'Partial<{ [key: string]: CategoryFieldFilterValue; }> | null | undefined' is not assignable to parameter of type '{ [key: string]: Primitive | Primitive[]; } | null | undefined'.
    const flatCategoryFields = useFlatCategoryFields(categoryID, extraFields);

    return React.useMemo(
        () => concatExtraFieldsLabels(flatCategoryFields, extraFields, role),
        [flatCategoryFields, extraFields, role],
    );
};

export default useExtraFieldsLabels;
