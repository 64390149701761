import {
    CategoryFieldChoice,
    CategoryFieldsFilters,
    FlatCategoryField,
} from 'strat/types/categoryFields';
import { getParentField } from '@app/categoryFields';
import { dictionaryToFlatArray, matchChoiceValue } from 'strat/util';
import EMPTY_ARRAY from 'strat/empty/array';

import { AdExtraFields } from 'horizontal/types';

const findCategoryFieldChoice = (
    categoryField: FlatCategoryField,
    extraFields: AdExtraFields | CategoryFieldsFilters,
    flatCategoryFields: Array<FlatCategoryField>,
    value: string,
): CategoryFieldChoice | null | undefined => {
    const parentField = getParentField(categoryField, flatCategoryFields);
    if (parentField) {
        const parentFieldChoice = dictionaryToFlatArray(parentField.choices).find((choice) =>
            matchChoiceValue(choice, extraFields[parentField.attribute]),
        );

        if (!parentFieldChoice) {
            return null;
        }

        return (categoryField.choices ?? EMPTY_ARRAY).find(
            (choice) => matchChoiceValue(choice, value) && choice.parentID === parentFieldChoice.id,
        );
    }
    return (categoryField.choices ?? EMPTY_ARRAY).find((innerChoice) =>
        matchChoiceValue(innerChoice, value),
    );
};

const formatEnumValue = (
    value: string,
    field: FlatCategoryField,
    extraFields: AdExtraFields | CategoryFieldsFilters,
    flatCategoryFields: Array<FlatCategoryField>,
): string | null => {
    const choice = findCategoryFieldChoice(field, extraFields, flatCategoryFields, value);
    if (!choice) {
        return null;
    }

    return choice.label;
};

export default formatEnumValue;
