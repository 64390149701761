import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

import { AdVirtualState, AdStateFilter } from 'horizontal/types';
import type { AdVirtualStateValues, AdStateFilterValues } from 'horizontal/types';

import { selectMyAdsCountByState } from './selectors';

const stateToFilterMapping = Object.freeze({
    [AdVirtualState.ACTIVE]: AdStateFilter.ACTIVE,
    [AdVirtualState.FEATURED]: AdStateFilter.ACTIVE,
    [AdVirtualState.ELITE]: AdStateFilter.ACTIVE,
    [AdVirtualState.FEATURED_PENDING]: AdStateFilter.PENDING,
    [AdVirtualState.ELITE_PENDING]: AdStateFilter.PENDING,
    [AdVirtualState.MODERATION_HARD_REJECT]: AdStateFilter.MODERATED,
    [AdVirtualState.MODERATION_SOFT_REJECT]: AdStateFilter.MODERATED,
    [AdVirtualState.MODERATION_PENDING]: AdStateFilter.PENDING,
    [AdVirtualState.LIMITED]: AdStateFilter.PENDING,
    [AdVirtualState.EXPIRED]: AdStateFilter.INACTIVE,
    [AdVirtualState.OUTDATED]: AdStateFilter.INACTIVE,
    [AdVirtualState.DISABLED]: AdStateFilter.INACTIVE,
    [AdVirtualState.SOLD]: AdStateFilter.INACTIVE,
});

export const getFilterByState = (state: AdVirtualStateValues): AdStateFilterValues => {
    return stateToFilterMapping[state];
};

export const getStatesByFilter = (
    filter?: AdStateFilterValues | null,
): Array<AdVirtualStateValues> | null | undefined => {
    const result = Object.entries(stateToFilterMapping).reduce<Array<any>>(
        (acc, [key, value]: [any, any]) => {
            if (value === filter) {
                acc.push(key);
            }
            return acc;
        },
        [],
    );

    return result.length > 0 ? result : undefined;
};

const getFilterText = (i18n: I18n, filter: AdStateFilterValues): string => {
    switch (filter) {
        case AdStateFilter.ACTIVE:
            return t(i18n)`Active Ads`;
        case AdStateFilter.INACTIVE:
            return t(i18n)`Inactive Ads`;
        case AdStateFilter.PENDING:
            return t(i18n)`Pending Ads`;
        case AdStateFilter.MODERATED:
            return t(i18n)`Moderated Ads`;
        default:
            return '';
    }
};

const useStateFilter = (selectCountByState = selectMyAdsCountByState) => {
    const i18n = useI18n();
    const countByState = useSelector(selectCountByState);
    const totalCount = Object.values(countByState).reduce((acc, value: any) => acc + value, 0);

    const facets = React.useMemo(() => {
        const values: Record<string, any> = {};

        Object.values(AdStateFilter).forEach((filter: any) => {
            values[filter] = 0;
        }, {});

        Object.entries(countByState).forEach(([state, count]: [any, any]) => {
            const filter = getFilterByState(state);

            if (filter) {
                values[filter] += count;
            }
        }, {});

        return values;
    }, [countByState]);

    return React.useMemo(() => {
        if (Object.keys(facets).length === 0) {
            return [];
        }

        const viewAll = {
            id: -1,
            value: null,
            label: t(i18n)`View all (${totalCount})`,
        } as const;

        const facetsAsChoices = Object.entries(facets).map(
            ([filter, values]: [any, any], index) => ({
                id: index,
                value: filter,
                label: t(i18n)`${getFilterText(i18n, filter)} (${values})`,
            }),
        );

        return [viewAll, ...facetsAsChoices];
    }, [i18n, facets, totalCount]);
};

export default useStateFilter;
