import { t } from '@lingui/macro';
import type { AnyAction } from 'redux';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { ThunkAction } from 'redux-thunk';
import type { SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';
import { search, setContent, getFilterCollection } from '@sector-labs/fe-search-redux/state';
import settings from '@app/branding/settings';
import SearchHistory from '@app/search/searchHistory';
import enableInternalLinks from '@app/search/internalLinks/enableInternalLinks';
import type { SessionData } from '@sector-labs/fe-auth-redux/state';

import { clearMyAds } from '@app/adManagement/state';
import { clearMyProfile } from '@app/profile/state';
import type { GlobalState } from 'strat/state';
import { clearMessageHistory } from '@app/bayutGPT/state';
import type { LocationDepthLimits } from 'strat/types';
import { clearSavedSearches } from 'strat/search/savedSearches/state';
import { clearFavorites } from 'strat/favorites/state';
import { clearInactiveFavorites } from 'strat/favorites/state';
import { saveSearchHistory, saveRecentlyViewedProperties } from 'strat/app/saveInLocalStorage';
import { selectIsFavoritesView } from 'strat/search/selectors';
import { clearAlerts } from 'strat/alerts/state/alertManager';
import { selectI18n } from 'strat/i18n/language';
import type { LocationNodeData } from 'strat/property';
import { showSearchResults } from 'strat/search/state';
import type { PropertyData } from 'strat/property/types';
import { clearAuthenticationContext, clearAuthenticationLoginType } from 'strat/auth/state';
import { resetLoginWelcomeDisplayed } from 'strat/auth';

import type { UserProfileData, UserLocationData, Coordinates } from '../types';
import { EventType } from '../types';
import fetchLocationByCoordinates from '../fetchLocationByCoordinates';
import createUserProfileFromKeycloakSessionData from '../createUserProfileFromKeycloakSessionData';

import Actions from './actions';
import type {
    SetProfileAction,
    NukeProfileAction,
    SetCityAction,
    SetLocationAction,
    SetSearchHistoryAction,
    SetRecentlyViewedPropertiesAction,
    SetVisitorIDAction,
    SetClientIDAction,
    SetDeviceIDAction,
    SetAnonymousSessionIDAction,
    SetFBPIDAction,
    SetFBCIDAction,
} from './actions';
import { clearLoggedInAgentData } from './loggedInAgentData';

export const setLoading = (loading: boolean) => ({
    type: Actions.SET_LOADING,
    loading,
});

/**
 * Sets the specified user profile as the currently active profile.
 */
export const setUser = (profile: UserProfileData): SetProfileAction => ({
    type: Actions.SET_PROFILE,
    profile,
});

/**
 * Sets the specified user profile as the currently active profile,
 * deriving it from Keycloak session data.
 */
export const setUserFromKeycloakSessionData = (sessionData: SessionData): SetProfileAction => ({
    type: Actions.SET_PROFILE,
    profile: createUserProfileFromKeycloakSessionData(sessionData),
});

/**
 * Nukes the current user's profile.
 */
export const nukeUser = (): NukeProfileAction => ({
    type: Actions.NUKE_PROFILE,
});

/**
 * Nukes the current user's profile and all related state.
 */
export const nukeUserAndClearEverything =
    (): ThunkAction<void, GlobalState, unknown, AnyAction> => (dispatch, getState) => {
        dispatch(nukeUser());
        dispatch(clearAuthenticationContext());
        dispatch(clearAuthenticationLoginType());
        dispatch(clearAlerts());
        dispatch(clearFavorites());
        dispatch(clearInactiveFavorites());
        dispatch(clearSavedSearches());
        dispatch(clearLoggedInAgentData());

        resetLoginWelcomeDisplayed();

        const clearMyAdsAction = clearMyAds();
        if (clearMyAdsAction) {
            dispatch(clearMyAdsAction);
        }
        const clearMessageHistoryAction = clearMessageHistory();
        if (clearMessageHistoryAction) {
            dispatch(clearMessageHistoryAction);
        }

        const state = getState();
        // if the user is on favorites properties page, redirect it to the search results page
        if (selectIsFavoritesView(state)) {
            // reset the search results because we need to fetch
            dispatch(setContent(null));
            dispatch(showSearchResults());
            const currentFilters = getFilterCollection(state as {});
            dispatch(search(currentFilters));
            dispatch(enableInternalLinks());
        }

        const clearMyProfileAction = clearMyProfile();
        if (clearMyProfileAction) {
            dispatch(clearMyProfileAction);
        }
    };

/**
 * Sets the user current city.
 */
export const setCity = (city: any): SetCityAction => ({
    type: Actions.SET_CITY,
    city,
});

/**
 * Sets the user's location (usually derived from the IP address).
 */
export const setLocation = (location?: UserLocationData | null): SetLocationAction => ({
    type: Actions.SET_LOCATION,
    location,
});

/**
 * Updates the search history by adding the specified search.
 */
export const updateSearchHistory =
    (currentSearch: FilterCollection): ThunkAction<void, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const newSearchHistory = SearchHistory.push(
            12,
            getState().user.searchHistory,
            currentSearch,
        );
        saveSearchHistory(newSearchHistory);
        dispatch({
            type: Actions.SET_SEARCH_HISTORY,
            searchHistory: newSearchHistory,
        });
    };

/**
 * Sets the search history to the specified list of searches.
 */
export const setSearchHistory = (
    searchHistory: Array<SerializedFilterCollection>,
): SetSearchHistoryAction => ({
    type: Actions.SET_SEARCH_HISTORY,
    searchHistory,
});

/**
 * Updates the recently viewed properties by adding the specified property.
 */
export const updateRecentlyViewedProperties =
    (recentlyViewedProperty: PropertyData): ThunkAction<void, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const recentlyViewedProperties = getState().user.recentlyViewedProperties ?? [];
        if (
            recentlyViewedProperties.some((property) => property.id === recentlyViewedProperty.id)
        ) {
            const propertyIndex = recentlyViewedProperties
                .map((property) => property.id)
                .indexOf(recentlyViewedProperty.id);
            recentlyViewedProperties.splice(propertyIndex, 1);
        }

        if (recentlyViewedProperties.length === 10) {
            recentlyViewedProperties.pop();
        }

        recentlyViewedProperties.unshift(recentlyViewedProperty);
        saveRecentlyViewedProperties(recentlyViewedProperties);

        return dispatch({
            type: Actions.SET_RECENTLY_VIEWED_PROPERTIES,
            recentlyViewedProperties,
        });
    };

/**
 * Sets the recently viewed properties to the specified list of properties.
 */
export const setRecentlyViewedProperties = (
    recentlyViewedProperties: Array<PropertyData>,
): SetRecentlyViewedPropertiesAction => ({
    type: Actions.SET_RECENTLY_VIEWED_PROPERTIES,
    recentlyViewedProperties,
});

/**
 * Sets the ID that uniquely identifies the current user.
 */
export const setVisitorID = (visitorID: string): SetVisitorIDAction => ({
    type: Actions.SET_VISITOR_ID,
    visitorID,
});

export const setClientID = (clientID: string): SetClientIDAction => ({
    type: Actions.SET_CLIENT_ID,
    clientID,
});

export const setDeviceID = (deviceID: string): SetDeviceIDAction => ({
    type: Actions.SET_DEVICE_ID,
    deviceID,
});

export const setAnonymousSessionID = (anonymousSessionID: string): SetAnonymousSessionIDAction => ({
    type: Actions.SET_ANONYMOUS_SESSION_ID,
    anonymousSessionID,
});

export const trackEvent = (eventType: Values<typeof EventType>, timestamp: number) => ({
    type: Actions.TRACK_EVENT,
    eventType,
    timestamp,
});

export const setUserAgent = (userAgent: string) => ({
    type: Actions.SET_USER_AGENT,
    userAgent,
});

export const setUserIP = (userIP: string) => ({
    type: Actions.SET_USER_IP,
    userIP,
});

export const setDebugMode = (debugMode: boolean) => ({
    type: Actions.SET_DEBUG_MODE,
    debugMode,
});

export const setFBPID = (fbpID: string): SetFBPIDAction => ({
    type: Actions.SET_FBP_ID,
    fbpID,
});

export const setFBCID = (fbcID: string): SetFBCIDAction => ({
    type: Actions.SET_FBC_ID,
    fbcID,
});

export const setGeoLocation = (
    coordinates?: Coordinates | null,
    closestLocation?: LocationNodeData | null,
) => ({
    type: Actions.SET_GEOLOCATION,
    coordinates,
    closestLocation,
});

export const setGeoLocationError = (error?: string) => ({
    type: Actions.SET_GEOLOCATION_ERROR,
    error,
});

export const fetchGeoLocation =
    (
        onSuccess?: (() => void) | null,
        onError?: (() => void) | null,
        locationDepthLimits?: LocationDepthLimits | null,
    ): ThunkAction<void, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const state = getState();
        const i18n = selectI18n(state);

        const genericErrorMessage = t(i18n)`Location fetching failed. Please try again.`;
        const handleSuccess = (
            position: GeolocationPosition,
            maxLocationDepth: any | null | undefined | number,
        ) => {
            const coordinates: Coordinates = [position.coords.latitude, position.coords.longitude];

            fetchLocationByCoordinates(i18n, coordinates, maxLocationDepth)
                .then((location) => {
                    dispatch(setGeoLocation(coordinates, location));
                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch(() => {
                    dispatch(setGeoLocationError(genericErrorMessage));
                    if (onError) {
                        onError();
                    }
                });
        };

        const handleError = (error: GeolocationPositionError) => {
            // Timeout
            if (error.code === 3) {
                return dispatch(setGeoLocationError(genericErrorMessage));
            }

            return dispatch(setGeoLocationError(error.message));
        };

        if (!navigator.geolocation) {
            const error = t(i18n)`Location blocked. Check browser/phone settings`;
            return dispatch(setGeoLocationError(error));
        }

        const maxLocationDepth = locationDepthLimits
            ? locationDepthLimits.max
            : settings.headerMaxLocationLevel;
        navigator.geolocation.getCurrentPosition(
            (position) => handleSuccess(position, maxLocationDepth),
            handleError,
            {
                timeout: 10000,
            },
        );

        return dispatch({
            type: Actions.FETCH_GEOLOCATION,
        });
    };
