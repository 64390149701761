import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Carousel, { CarouselContentStyles } from 'strat/generic/carousel';
import { ViewSections } from 'strat/gtm';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';
import SearchHitsLayout from '@app/branding/searchHitsLayout';

import makeLoadingAdCards from 'horizontal/adCard/makeLoadingAdCards';
import AdCard from '@app/adCard/adCard';
import type { LiteAd } from 'horizontal/types';
import { trackSearchHistoryScroll } from 'horizontal/gtm/adTracking';
import { CarouselNavigationButton, NavButtonType } from 'horizontal/components';

import { AdsSeeMoreButton } from './adsSeeMoreButton';
import computeAdsCarouselGroupSize from './computeAdsCarouselGroupSize';
import styles from './styles/adsCarousel.cssm';
import './styles/carousel.css';

const loadingCards = makeLoadingAdCards(
    4,
    SearchHitsLayout.GRID,
    (children: React.ReactNode, key: number) => (
        <div key={key} className={styles.listingContainer}>
            {children}
        </div>
    ),
);

const renderNextButton =
    (onClickSeeMore?: () => void) => (nextFunction: () => void, canGoNext: boolean) => {
        if (onClickSeeMore && !canGoNext) {
            return null;
        }
        return (
            <CarouselNavigationButton
                onClick={nextFunction}
                disabled={!canGoNext}
                type={NavButtonType.RIGHT}
            />
        );
    };

const renderSeeMore = (onClickSeeMore: () => void) => {
    return (
        <div className={styles.seeMoreContainer} key="see-more">
            <AdsSeeMoreButton onClick={onClickSeeMore} />
        </div>
    );
};

type Props = {
    ads: ReadonlyArray<LiteAd>;
    loading?: boolean;
    hideAgency?: boolean;
    hideIncompletePages?: boolean;
    computeGroupSize?: (windowWidth: number) => number;
    seeMoreLimit: number;
    handleSeeMore?: () => void;
};

const AdsCarousel = ({
    ads,
    loading,
    hideAgency,
    hideIncompletePages = false,
    computeGroupSize = computeAdsCarouselGroupSize,
    seeMoreLimit = 0,
    handleSeeMore,
}: Props) => {
    const reversed = useSelector(selectIsLanguageRTL);
    const entries = React.useMemo(
        () =>
            ads
                .slice(...(handleSeeMore && seeMoreLimit ? [0, seeMoreLimit] : []))
                .map((ad) => (
                    <div
                        key={ad.id}
                        className={classNames(styles.listingContainer, {
                            [styles.reverse]: reversed,
                        })}
                        aria-label="Listing"
                    >
                        <AdCard
                            ad={ad}
                            viewType={SearchHitsLayout.GRID}
                            viewSection={ViewSections.BODY}
                            hideAgency={hideAgency}
                        />
                    </div>
                ))
                .concat(
                    handleSeeMore && ads.length >= seeMoreLimit
                        ? [renderSeeMore(handleSeeMore)]
                        : [],
                ),
        [ads, reversed, hideAgency, handleSeeMore, seeMoreLimit],
    );

    const onClickSeeMore = React.useCallback(() => {
        if (handleSeeMore && ads.length >= seeMoreLimit) {
            handleSeeMore();
        }
    }, [handleSeeMore, seeMoreLimit, ads.length]);

    return (
        <Carousel
            // @ts-expect-error - TS2322 - Type '{ children: Element[]; computeGroupSize: (windowWidth: number) => 2 | 3 | 4; hideIncompletePages: boolean; groupItemsAlignmentStyle: "carouselGroupContentStart"; onArrowClick: () => void; reversed: any; renderPreviousButton: (previousFunction: any, canGoPrevious: any) => Element; renderNextButton: (nextFunction: any...' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Carousel> & Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
            computeGroupSize={computeGroupSize}
            hideIncompletePages={hideIncompletePages}
            groupItemsAlignmentStyle={CarouselContentStyles.FLEX_START}
            onArrowClick={trackSearchHistoryScroll}
            reversed={reversed}
            renderPreviousButton={(previousFunction: () => void, canGoPrevious: boolean) => (
                <CarouselNavigationButton
                    onClick={previousFunction}
                    disabled={!canGoPrevious}
                    type={NavButtonType.LEFT}
                />
            )}
            renderNextButton={renderNextButton(handleSeeMore && onClickSeeMore)}
        >
            {loading ? loadingCards : entries}
        </Carousel>
    );
};

export default AdsCarousel;
