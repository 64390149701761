import React from 'react';
import { Flex } from 'strat/components';

import Footer from 'horizontal/footer';
import Properties from 'horizontal/verticals/properties';
import AppInstallBanner from '@app/home/appInstallBanner';
import VerticalHeader from 'horizontal/verticals/verticalHeader';
import { HeaderWithoutFilters } from 'horizontal/header';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';
import VerticalPropertiesHead from 'horizontal/pages/verticalPropertiesHead';

const VerticalPropertiesPageDesktop = {
    head: () => (
        <>
            <VerticalPropertiesHead />
            <GTMDataLayer pageType={PageType.PROPERTIES_VERTICAL} />
        </>
    ),
    body: () => (
        <Flex column stretchHeight fillContainer>
            <VerticalHeader renderHeader={() => <HeaderWithoutFilters />} />
            <Properties />
            <AppInstallBanner />
            <Footer />
        </Flex>
    ),
} as const;

export default VerticalPropertiesPageDesktop;
