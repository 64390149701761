import { sortByDisplayPriority } from 'strat/util';
import {
    CategoryFieldChoice,
    CategoryFieldChoicesKeysType,
    CategoryFieldsFilters,
    FlatCategoryField,
} from 'strat/types';

import type { CategoryFields } from './types';

export const flattenCategoryFieldChildrenChoices = (
    choices?: Partial<Record<CategoryFieldChoicesKeysType, Array<CategoryFieldChoice>>>,
    parentValue?: string | number,
): Array<CategoryFieldChoice> => {
    if (!choices) {
        return [];
    }

    if (Array.isArray(choices)) {
        return choices;
    }

    if (!parentValue) {
        return [];
    }

    return choices?.[parentValue] ?? [];
};

const flattenCategoryFieldsChildren = (
    categoryFields: CategoryFields | null,
    parentValues?: CategoryFieldsFilters | null,
): Array<FlatCategoryField> => {
    if (!categoryFields) {
        return [];
    }

    const flatFields: Array<FlatCategoryField> = categoryFields.flatFields.map((field) => ({
        ...field,
        choices: field.choices || [],
        combinationChoices: field.combinationChoices || [],
    }));

    if (!categoryFields.childrenFields.length) {
        return flatFields;
    }

    categoryFields.childrenFields.forEach((childCategoryField) => {
        const parentAttribute = categoryFields.parentFieldLookup[childCategoryField.attribute];
        const parentValue = (parentValues || {})[parentAttribute];
        if (typeof parentValue !== 'string' && typeof parentValue !== 'number') {
            flatFields.push({
                ...childCategoryField,
                choices: [],
                combinationChoices: [],
            });
            return;
        }
        flatFields.push({
            ...childCategoryField,
            choices: flattenCategoryFieldChildrenChoices(childCategoryField.choices, parentValue),
            combinationChoices: flattenCategoryFieldChildrenChoices(
                childCategoryField.combinationChoices,
                parentValue,
            ),
        });
    });

    return flatFields.sort(sortByDisplayPriority);
};

export default flattenCategoryFieldsChildren;
