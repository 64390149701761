import { PackedProjectType } from './project/types';

export type PackedPropertyType = [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    Array<number>,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    boolean,
    number,
    number,
    number,
    number,
];

const toRentFrequency = (rentFrequencyID: number) => {
    switch (rentFrequencyID) {
        case 0:
            return 'yearly';
        case 1:
            return 'monthly';
        case 2:
            return 'weekly';
        case 3:
            return 'daily';
        default:
            return null;
    }
};

const toCompletionStatus = (completionStatusID: number) => {
    switch (completionStatusID) {
        case 0:
            return 'completed';
        case 1:
            return 'under-construction';
        default:
            return 'completed';
    }
};

const toResidenceType = (residenceType: number) => {
    switch (residenceType) {
        case 0:
            return 'singles';
        case 1:
            return 'family';
        default:
            return null;
    }
};

const toOccupancyStatus = (occupancyStatus: number) => {
    switch (occupancyStatus) {
        case 0:
            return 'vacant';
        case 1:
            return 'occupied';
        default:
            return null;
    }
};

const toFurnishingStatus = (occupancyStatus: number) => {
    switch (occupancyStatus) {
        case 0:
            return 'furnished';
        case 1:
            return 'unfurnished';
        default:
            return null;
    }
};

export type PackedListingType = PackedPropertyType | PackedProjectType;

export const id = (dataArray: PackedListingType) => dataArray[0];
export const coordinates = (dataArray: PackedListingType) => [dataArray[1], dataArray[2]];
export const latitude = (dataArray: PackedListingType) => dataArray[2];
export const longitude = (dataArray: PackedListingType) => dataArray[1];
export const price = (dataArray: PackedPropertyType) => dataArray[3];
export const rentFrequency = (dataArray: PackedPropertyType) => toRentFrequency(dataArray[4]);
export const bedroomCount = (dataArray: PackedPropertyType) => dataArray[5];
export const bathroomCount = (dataArray: PackedPropertyType) => dataArray[6];
export const area = (dataArray: PackedPropertyType) => dataArray[7];
export const locationHierarchy = (dataArray: PackedPropertyType) => dataArray[8];
export const categoryExternalID = (dataArray: PackedPropertyType) => dataArray[9].toString();
export const score = (dataArray: PackedPropertyType) => dataArray[10];
export const scoreL1 = (dataArray: PackedPropertyType) => dataArray[11];
export const productScore = (dataArray: PackedPropertyType) => dataArray[12];
export const verifiedScore = (dataArray: PackedPropertyType) => dataArray[13];
export const createdAt = (dataArray: PackedPropertyType) => dataArray[14];
export const completionStatus = (dataArray: PackedPropertyType) =>
    toCompletionStatus(dataArray[15]);
export const residenceType = (dataArray: PackedPropertyType) => toResidenceType(dataArray[16]);
export const occupancyStatus = (dataArray: PackedPropertyType) => toOccupancyStatus(dataArray[17]);
export const furnishingStatus = (dataArray: PackedPropertyType) =>
    toFurnishingStatus(dataArray[18]);
export const hidePrice = (dataArray: PackedPropertyType) =>
    dataArray.length > 19 ? dataArray[19] : false;
export const productLabelScore = (dataArray: PackedPropertyType) => dataArray[20];
export const completionDate = (dataArray: PackedPropertyType) => dataArray[21];
export const completionPercentage = (dataArray: PackedPropertyType) => dataArray[22];
export const preHandoverPayment = (dataArray: PackedPropertyType) => dataArray[23];
