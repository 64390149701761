import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';

import Loadable, { LoadingSpinner } from 'strat/loadable';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import styles from './styles/captchaPage.cssm';

type Props = {
    readonly externalRedirectPath?: string;
};

// @ts-expect-error - TS2322 - Type '{ visible: true; }' is not assignable to type 'IntrinsicAttributes & Props'.
const LoadingCaptchaChallenge = () => <LoadingSpinner visible />;

const CaptchaChallengeErrorFallback = () => (
    <h2>
        <Trans>Something went wrong. Please try again.</Trans>
    </h2>
);

const CaptchaChallenge = Loadable({
    loader: () =>
        import(/* webpackChunkName: 'captchaChallenge' */ 'strat/humbucker/captchaChallenge'),
    loading: LoadingCaptchaChallenge,
    fallback: CaptchaChallengeErrorFallback,
    loadingFallback: CaptchaChallengeErrorFallback,
});

const CaptchaPageHead = () => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <>
            <title>{t(i18n)`Captcha | ${brandName}`}</title>
            <meta name="robots" content="noindex, nofollow" />
        </>
    );
};

const CaptchaPage = {
    head: () => <CaptchaPageHead />,
    body: ({ externalRedirectPath }: Props) => (
        <Flex justifyCenter alignCenter className={styles.container}>
            <CaptchaChallenge externalRedirectPath={externalRedirectPath} />
        </Flex>
    ),
} as const;

export default CaptchaPage;
