import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { StylelessButton } from 'strat/generic';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import settings from '@app/branding/settings';
import { Text, Flex, Input, Button, LoadingSpinnerOverlay } from 'strat/components';
import { setCampaign } from 'strat/campaign/state';

import IconBulb from 'horizontal/assets/icons/iconBulb.svg';
import { trackProfileChanges } from 'horizontal/gtm/userTracking';
import { UserPhoneNumberField } from 'horizontal/fields';
import UserProfilePicture from '@app/user/userProfilePicture';
import { selectIsWalletTopUpCampaignActive } from 'horizontal/marketingCampaigns';

import WhatsappPhoneNumberField from './whatsappPhoneNumberField';
import GenderDropdown from './genderDropdown';
import SocialLogin from './socialLogin';
import EditProfileEmail from './editProfileEmail';
import EditProfileBirthday from './editProfileBirthday';
import UserImageUpload from './userImageUpload';
import styles from './styles/editProfileForm.cssm';

type Props = {
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly setFieldTouched: (field: keyof FormikValues) => void;
    readonly setSubmitting: (arg1: boolean) => void;
    readonly isSubmitting: boolean;
    readonly shouldDisplayWhatsappInput: boolean;
    readonly isWhatsappLoading: boolean;
};

const EditProfileForm = (props: Props) => {
    const {
        errors,
        touched,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        setSubmitting,
        setFieldTouched,
        shouldDisplayWhatsappInput,
    } = props;
    const isWalletTopUpCampaignActive = useSelector(selectIsWalletTopUpCampaignActive);
    const dispatch = useDispatch();

    const onPhoneChanges = () => {
        if (isWalletTopUpCampaignActive) {
            // reset campaign after changing the phone number if the user activated the campaign using the campaign link.
            dispatch(setCampaign(null));
        }
    };
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    return (
        <form className={styles.container}>
            <div className={styles.formTitle}>
                <Text.XLarge bold>{t(i18n)`Edit profile`}</Text.XLarge>
            </div>
            {CONFIG.runtime.OLX_ENABLE_USER_IMAGE_EDIT && (
                <>
                    <div className={styles.subsectionTitle}>
                        <Text.Large bold>{t(i18n)`Profile Photo`}</Text.Large>
                    </div>
                    <Flex>
                        <Flex column>
                            <UserProfilePicture alt="Avatar" className={styles.avatar} />
                        </Flex>

                        <UserImageUpload
                            setSubmitting={setSubmitting}
                            userImageSizes={{ min: 400, max: 1024 }}
                        />
                    </Flex>
                    <div className={styles.separator} />
                </>
            )}
            <div className={styles.subsectionTitle}>
                <Text.Regular bold>{t(i18n)`Name`}</Text.Regular>
            </div>
            <Flex>
                <Flex column className={styles.inputContainer}>
                    <div className={styles.nameContainer}>
                        <Input
                            type="text"
                            name={'name'}
                            placeholder={t(i18n)`Name`}
                            value={values.name}
                            errorMessage={touched.name && errors.name}
                            accepted={touched.name && !errors.name}
                            onChange={(value) => setFieldValue('name', value)}
                            onBlur={(event) => {
                                setFieldValue('name', event.target.value.trim());
                                handleBlur(event);
                            }}
                            lightPlaceholder
                        />
                    </div>
                    <div>
                        <EditProfileBirthday
                            title={t(i18n)`Date of birth`}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            fieldKey={'birthday'}
                        />
                    </div>
                    <div>
                        <GenderDropdown
                            title={t(i18n)`Gender`}
                            value={values.gender}
                            placeholder={t(i18n)`Select your gender`}
                            setFieldValue={setFieldValue}
                            fieldKey={'gender'}
                        />
                    </div>
                    <div>
                        <Input
                            type="text"
                            name={'description'}
                            placeholder={t(i18n)`About me (optional)`}
                            errorMessage={touched.description && errors.description}
                            accepted={touched.description && !errors.description}
                            maxLength={200}
                            value={values.description}
                            onChange={(value) => setFieldValue('description', value)}
                            onBlur={handleBlur}
                            multiLine
                            lightPlaceholder
                        />
                    </div>
                </Flex>
                <Flex column className={styles.infoContainer}>
                    <Flex alignCenter>
                        <IconBulb />
                        <Text.Regular bold>{t(i18n)`Why is it important?`}</Text.Regular>
                    </Flex>
                    <Text.Base className={styles.infoContainerContent}>
                        {t(
                            i18n,
                        )`${brandName} is built on trust. Help other people get to know you. Tell them about the things you like. Share your favorite brands, books, movies, shows, music, food. And you will see the results…`}{' '}
                    </Text.Base>
                </Flex>
            </Flex>
            <div className={styles.separator} />
            <div className={styles.subsectionTitle}>
                <Text.Large bold>{t(i18n)`Contact information`}</Text.Large>
            </div>
            <Flex>
                <div className={styles.inputContainer}>
                    <UserPhoneNumberField {...props} onDone={onPhoneChanges} />
                </div>
                <Text.Base className={styles.description}>
                    {t(
                        i18n,
                    )`This is the number for buyers contacts, reminders, and other notifications.`}
                </Text.Base>
            </Flex>
            {shouldDisplayWhatsappInput && (
                <Flex>
                    <div className={styles.inputContainer}>
                        <WhatsappPhoneNumberField {...props} />
                    </div>
                    <Text.Base className={styles.description}>{t(
                        i18n,
                    )`This phone number will be used for WhatsApp communication.`}</Text.Base>
                </Flex>
            )}
            <Flex>
                <EditProfileEmail {...props} />
                <Text.Base className={styles.description}>
                    {t(i18n)`We won't reveal your email to anyone else nor use it to send you spam`}
                </Text.Base>
            </Flex>
            <div className={styles.separator} />
            <div className={styles.subsectionTitle}>
                <Text.Large bold>{t(i18n)`Optional information`}</Text.Large>
            </div>
            <SocialLogin />
            <div className={styles.separator} />
            <Flex justifySpaceBetween className={styles.buttons}>
                <Link
                    route={RouteNames.HOME}
                    title={t(i18n)`Discard changes`}
                    onClick={() => trackProfileChanges('discard')}
                >
                    <StylelessButton>
                        <Text.Base className={styles.discardButton} bold>
                            {t(i18n)`Discard`}
                        </Text.Base>
                    </StylelessButton>
                </Link>
                <div>
                    {/* @ts-expect-error - TS2322 - Type '{ children: string; stretch: true; primary: true; type: string; onClick: (arg1?: any) => void; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button stretch primary type="submit" onClick={handleSubmit}>
                        {t(i18n)`Save changes`}
                    </Button>
                </div>
            </Flex>
            <LoadingSpinnerOverlay visible={isSubmitting} />
        </form>
    );
};

export default EditProfileForm;
