import * as React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { useDialog } from 'strat/dialogs/dialogContext';
import { selectIsMobile } from 'strat/platform/selectors';

import { PhoneNumberLinkType, redirectToPhoneURL } from 'horizontal/contact';
import { DialogNames } from 'horizontal/dialogs';
import { LiteAd, CTA } from 'horizontal/types';
import { shouldDisplayProAgencyDataRegulationsDisclaimer } from 'horizontal/agency/roles';

type Params = {
    readonly ad?: LiteAd;
    readonly viewSection: Values<typeof ViewSections>;
    readonly trackCallLead: (viewSection: Values<typeof ViewSections>) => void;
    /* we must provide this argument if we want to register a call view as well as a call lead */
    readonly trackCallView?: (viewSection: Values<typeof ViewSections>) => void;
    readonly shouldOpenCTADialog?: boolean;
};

const useOnCallButtonClick = ({
    ad,
    viewSection,
    trackCallLead,
    trackCallView,
    shouldOpenCTADialog,
}: Params) => {
    const isMobile = useSelector(selectIsMobile);
    const [, setCTADialogVisible] = useDialog(DialogNames.CTA_DIALOG);
    const trackCallLeadAndRedirectToPhoneURL = React.useCallback(
        (phoneNumber: string) => {
            trackCallLead(viewSection);
            redirectToPhoneURL(phoneNumber, PhoneNumberLinkType.CALL);
        },
        [trackCallLead, viewSection],
    );

    return React.useCallback(
        (phoneNumber: string) => {
            trackCallView?.(viewSection);
            if (isMobile && !shouldDisplayProAgencyDataRegulationsDisclaimer(ad?.agency)) {
                trackCallLeadAndRedirectToPhoneURL(phoneNumber);
            } else {
                shouldOpenCTADialog
                    ? setCTADialogVisible(true, {
                          ad,
                          phoneNumber: phoneNumber,
                          ctaType: CTA.CALL,
                          onClick: () => {
                              trackCallLeadAndRedirectToPhoneURL(phoneNumber);
                          },
                      })
                    : trackCallLeadAndRedirectToPhoneURL(phoneNumber);
            }
        },
        [
            ad,
            shouldOpenCTADialog,
            setCTADialogVisible,
            viewSection,
            trackCallView,
            isMobile,
            trackCallLeadAndRedirectToPhoneURL,
        ],
    );
};

export default useOnCallButtonClick;
