import type { Ad } from 'horizontal/types';
import adTransformer from 'horizontal/ad/adTransformer';

export default <T extends Ad>(hits: T[], language: string): T[] => {
    if (!hits?.length) {
        return [];
    }

    return hits.map<T>((hit) => adTransformer<T>(hit, language, CONFIG.build.ENABLE_MERGED_INDEX));
};
