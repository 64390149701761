import * as React from 'react';
import { Trans } from '@lingui/macro';

type Props = {
    sendEmail: () => void;
    renderIcon: () => React.ReactElement;
    className: string;
    emailButtonTitle: string;
};

const ContactFormEmailButton = (props: Props) => (
    <button
        onClick={props.sendEmail}
        /*
        * When an input field is in focus, then the click on submit button will not actually trigger the submit,
        * but the blur from leaving the field. This causes the error message to appear, which pushes down the button,
        * so onClick event will not be triggered, hence submit not called, and eventually not all fields will be validated.
        Here are more details about the issue and the fix I used can also be found: jaredpalmer/formik#1796
        * Adding this prop fixes this (will subvert the blur behaviour).
        */
        onMouseDown={(event) => {
            event.preventDefault();
            event.stopPropagation();
        }}
        className={props.className}
        type="button"
        aria-label="Send email"
    >
        {props.renderIcon && props.renderIcon()}
        {props.emailButtonTitle ? props.emailButtonTitle : <Trans>Send email</Trans>}
    </button>
);

export default ContactFormEmailButton;
