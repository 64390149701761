import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { Button, Flex, Text } from 'strat/components';

import IconCheck from 'horizontal/assets/icons/iconCheck.svg';
import type { FullAd, Product, ProductPurchase } from 'horizontal/types';
import { ProductType } from 'horizontal/types';
import { useNavigateToAdDetails, useNavigateToAgencyAds } from 'horizontal/adDetails';
import { trackClickPreviewAd } from 'horizontal/gtm';
import { useExternalWindowNavigation } from 'horizontal/util';

import styles from './styles/selectPackageSuccessMessage.cssm';
import ProductPurchaseSummary from './productPurchaseSummary';

type Props = {
    ad?: FullAd;
    productPurchase?: ProductPurchase;
    isInAgencyPortal?: boolean;
};

const getSuccessMessage = (productPurchase: ProductPurchase | null | undefined, i18n: I18n) => {
    if (
        productPurchase?.productPackageOffer.productPackage.productItems.filter((item: Product) =>
            [ProductType.FEATURED, ProductType.LIMIT_BUMP].includes(item.type),
        ).length === 2
    ) {
        return t(i18n)`Your featured ad will be posted shortly...`;
    }

    return t(i18n)`Your ad will be boosted shortly...`;
};

const SelectPackageSuccessMessage = ({ ad, productPurchase, isInAgencyPortal = false }: Props) => {
    const i18n = useI18n();
    const message = getSuccessMessage(productPurchase, i18n);
    const navigateToAdDetails = useNavigateToAdDetails(ad);
    const navigateToAgencyAds = useNavigateToAgencyAds();
    const externalWindowNavigation = useExternalWindowNavigation();
    const onViewAdButtonClick = () => {
        if (isInAgencyPortal && ad) {
            trackClickPreviewAd(ad);
            externalWindowNavigation(RouteNames.AD_DETAILS, {
                slug: ad.slug,
                externalID: ad.externalID,
            });
            return;
        }
        navigateToAdDetails();
    };

    return (
        <Flex className={styles.container} column>
            <Flex justifyCenter>
                <IconCheck className={styles.icon} />
            </Flex>
            <Text.XLarge bold>{t(i18n)`Congratulations!`}</Text.XLarge>
            <Text.Large bold>{message}</Text.Large>
            {productPurchase && (
                <span className={styles.productPurchaseMessage}>
                    <ProductPurchaseSummary productPurchase={productPurchase} />
                </span>
            )}
            <Button onClick={onViewAdButtonClick} className={styles.button}>
                {t(i18n)`Preview ad`}
            </Button>
            {isInAgencyPortal ? (
                <Button secondary onClick={navigateToAgencyAds} className={styles.secondaryButton}>
                    {t(i18n)`Go to Ads`}
                </Button>
            ) : null}
        </Flex>
    );
};

export default SelectPackageSuccessMessage;
