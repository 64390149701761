import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ViewSections } from 'strat/gtm';
import { selectUserExternalID } from 'strat/user/session';
import { Flex, Text } from 'strat/components';
import getVerifiedProfileBadgeConfig from '@app/adCard/getVerifiedProfileBadgeConfig';
import settings from '@app/branding/settings';
import { locationHierarchyToString } from 'strat/misc';
import { useI18n } from 'strat/i18n/language';

import { AdPhoto, AdProduct } from 'horizontal/types';
import type { LiteAd } from 'horizontal/types';
import { useAdPriceLabel, useLocalizedAdTitle, useAgencyLogo } from 'horizontal/ad';
import { isAdEditor } from 'horizontal/util';
import CreationDate from 'horizontal/adCard/creationDate';
import FavoriteButton from 'horizontal/adCard/favoriteButton';
import useCleanedDescription from 'horizontal/adDetails/seo/useCleanedDescription';
import getRequestablePriceLabel from 'horizontal/ad/price/getRequestablePriceLabel';
import useGetDownPaymentLabel from 'horizontal/ad/price/useGetDownPaymentLabel';
import { selectLocationNameWithParent } from 'horizontal/location';
import useRentalPeriod from 'horizontal/adCard/useRentalPeriod';

import { ImageContainer, AdFeatures, AdBadges } from './adCardForHeroCategoryComponents';
import styles from './styles/adCardForHeroCategory.cssm';
import ContactButtons from './contactButtons';
import SellerDetails from './sellerDetails';
import Title from './title';
import AdAdditionalPriceInfo from './adAdditionalPriceInfoForHeroCategory';
import AdLink from './adLink';

type Props = {
    ad: LiteAd;
    link: React.ReactElement;
    index?: number;
    viewType?: Values<typeof SearchHitsLayout>;
    hideLocation?: boolean;
    viewSection: Values<typeof ViewSections>;
    hideAgency?: boolean;
    hideFeatured?: boolean;
    hideElite?: boolean;
    highlighted?: boolean;
    coverPhoto: AdPhoto | null;
    lazyLoadPhoto: boolean;
    isOwnAd: boolean;
    priceLabel: string | null | undefined;
    title: string;
    location: string;
    showElite?: boolean;
    showFeatured?: boolean;
    coverPhotoSize?: number;
};

const AdCard = ({
    ad,
    viewSection,
    showFeatured,
    hideAgency,
    hideLocation,
    hideElite,
    highlighted,
    index,
    coverPhotoSize,
}: Props) => {
    const i18n = useI18n();
    const loggedUserExternalId = useSelector(selectUserExternalID);
    const isOwnAd = isAdEditor(ad, loggedUserExternalId);
    const downPaymentLabel = useGetDownPaymentLabel(ad);
    const rentalPeriod = useRentalPeriod(ad);
    const showElite = ad.product === AdProduct.ELITE && !hideElite;

    const priceLabel = useAdPriceLabel(ad);
    const requestableText = getRequestablePriceLabel(ad);
    const adLocalizedTitle = useLocalizedAdTitle(ad);
    const adLocalizedDescription = useCleanedDescription(ad);
    const agencyLogo = useAgencyLogo(ad);
    const showAgency = !!agencyLogo && !hideAgency;

    const shouldShowDescription = showElite && showAgency;

    const verifiedProfileBadgeConfig = getVerifiedProfileBadgeConfig(ad, false, true, viewSection);

    /**
     * if the user is not verified or if the badge is shown as an ad baged (like other
     * badges: video, deliveriable...etc), then don't show it on the seller details component
     */
    const showVerifiedStatusInSellerDetails = !(
        !verifiedProfileBadgeConfig.show || verifiedProfileBadgeConfig.includeInBadgesList
    );

    const locationHierarchy = settings.parseLocationNameWithCity
        ? selectLocationNameWithParent(i18n, ad.location)
        : locationHierarchyToString(ad.location, 2, i18n, ad.location.length - 2);
    const renderLocation = (withDot: boolean) =>
        !hideLocation && (
            <span className={styles.location} aria-label="Location">
                {locationHierarchy}
                {withDot && <span className={classNames(styles.separator)}>&#8226;</span>}
            </span>
        );

    const link = (
        <AdLink ad={ad} isMobile={false} viewSection={viewSection}>
            <div className={styles.linkDiv} />
        </AdLink>
    );

    return (
        <article
            className={classNames(styles.container, {
                [styles.elite]: showElite,
                [styles.highlighted]: highlighted,
            })}
        >
            <ImageContainer
                ad={ad}
                showElite={showElite}
                showFeatured={showFeatured}
                adLink={link}
                index={index}
                coverPhotoSize={coverPhotoSize}
            />

            <div className={styles.detailsWrapper}>
                {link}
                <div className={styles.details}>
                    {link}
                    {!!priceLabel && (
                        <Flex alignCenter className={styles.price} aria-label="Price">
                            <span
                                className={
                                    downPaymentLabel != null && !rentalPeriod
                                        ? styles.downPaymentPriceLabel
                                        : styles.priceLabel
                                }
                            >
                                {priceLabel}
                            </span>
                            <AdAdditionalPriceInfo ad={ad} />
                        </Flex>
                    )}
                    {!!requestableText && (
                        <Flex alignCenter className={styles.price} aria-label="Price">
                            <span
                                className={
                                    downPaymentLabel != null
                                        ? styles.downPaymentPriceLabel
                                        : styles.priceLabel
                                }
                            >
                                {requestableText}
                            </span>
                        </Flex>
                    )}
                    <div
                        className={classNames(styles.titleWrapper, {
                            [styles.titleWithPrice]: !!priceLabel,
                            [styles.reducedTitle]: shouldShowDescription,
                        })}
                        aria-label="Title"
                    >
                        <Title title={`${adLocalizedTitle}`} className={styles.title} />
                    </div>
                    {shouldShowDescription && (
                        <div className={styles.description} aria-label="Description">
                            <Text.Regular>{adLocalizedDescription}</Text.Regular>
                        </div>
                    )}
                    {!isOwnAd && (
                        <div className={styles.favoriteButton}>
                            {/* @ts-expect-error - TS2322 - Type '{ ad: LiteAd; viewSection: string; }' is not assignable to type 'IntrinsicAttributes & { ref?: Ref<unknown> | undefined; key?: Key | null | undefined; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } & { ...; }'. */}
                            <FavoriteButton ad={ad} viewSection={ViewSections.BODY} />
                        </div>
                    )}
                    <Flex className={classNames(styles.subtitle)} aria-label="Subtitle">
                        <AdFeatures ad={ad} />
                        <AdBadges ad={ad} viewSection={viewSection} />
                    </Flex>
                    <div
                        className={classNames(styles.locationContainer, {
                            /**
                             * When showing agency logo we want smaller width for the location/ad time
                             * container because the logo takes a big chunk of the size.
                             * Verified Icon takes less size so we allow bigger width for such container.
                             */
                            [styles.smallContainer]:
                                showAgency && !showVerifiedStatusInSellerDetails,
                            [styles.mediumContainer]: showVerifiedStatusInSellerDetails,
                        })}
                    >
                        {renderLocation(true)}
                        <span
                            className={classNames(styles.creationDate, {
                                [styles.bottom]: true,
                            })}
                        >
                            <CreationDate createdAt={ad.timestamp} />
                        </span>
                    </div>
                    {!isOwnAd && (
                        <>
                            <div className={styles.whiteSpace} />
                            <ContactButtons ad={ad} viewSection={viewSection} />
                        </>
                    )}

                    <SellerDetails
                        ad={ad}
                        showAgency={showAgency}
                        showVerifiedBadge={verifiedProfileBadgeConfig.show}
                        showElite={showElite}
                        isGridLayout={false}
                    />
                </div>
            </div>
        </article>
    );
};

export default React.memo(AdCard);
