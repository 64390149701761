import * as React from 'react';
import InternalAPI from 'strat/api/internal';
import { ThumbnailSizes, thumbnailURL } from 'strat/image';
import { trigger, Triggers } from 'strat/gtm';

import type { AdPhoto } from 'horizontal/types';

import { getImageSize, isImageResolutionTooHigh, isImageTooLarge } from './imageUploadUtils';

export type UploadImageState = {
    // Function to upload the image to the cloud
    readonly uploadImage: (imageName: string, file: File) => Promise<string> | null | undefined;
    // Whether the image is currently uploading
    readonly loading: boolean;
    // The id from the backend of the image if it was already uploaded
    readonly imageID: number | null | undefined;
    // The cloud URL of the image
    readonly fileURL: string | null | undefined;
    // The external_id from the backend of the photo if it was already uploaded
    readonly photoExternalID: string | null | undefined;
    // The local blob URL of the image
    readonly src: string | null | undefined;
    // Function to reset the state of the hook.
    // The image will not really be removed from the cloud,
    // but it will seem like it to the users of the hook.
    readonly remove: () => void;
    // Whether the file that was tried to be uploaded
    // surpasses the provided `MAX_SIZE_MB`
    readonly isFileTooLarge: boolean;
    // Whether the file that was tried to be uploaded
    // surpasses the provided `MAX_RESOLUTION`
    readonly isResolutionTooHigh: boolean;
    // Whether the upload attempt failed
    readonly uploadFailed: boolean;
    // Retry uploading the previous image
    readonly retry: () => void;
    // The image resolution
    readonly width: number | null | undefined;
    readonly height: number | null | undefined;
};

const useUploadImage = (initialImage?: AdPhoto): UploadImageState => {
    const [loading, setIsLoading] = React.useState(false);
    const [uploadFailed, setUploadFailed] = React.useState(false);
    const [imageID, setImageID] = React.useState<number | null | undefined>(initialImage?.id);
    const [photoExternalID, setPhotoExternalID] = React.useState<string | null | undefined>(
        initialImage?.externalID,
    );

    const imageUrl =
        initialImage?.url ||
        (initialImage?.id
            ? // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type '{ width: number; height: number; }'.
              thumbnailURL(initialImage?.id, 'jpeg', ThumbnailSizes.SMALL)
            : undefined);
    const [fileURL, setFileURL] = React.useState<string | null | undefined>(imageUrl);
    const [src, setSrc] = React.useState<string | null | undefined>(imageUrl);

    const [retryParams, setRetryParams] = React.useState<Parameters<
        UploadImageState['uploadImage']
    > | null>(null);

    const [isFileTooLarge, setIsFileTooLarge] = React.useState(false);
    const [isResolutionTooHigh, setIsResolutionTooHigh] = React.useState(false);

    const [width, setWidth] = React.useState<number | null>(0);
    const [height, setHeight] = React.useState<number | null>(0);

    const uploadImage = React.useCallback(
        (imageName: string, file: File): Promise<string> | null | undefined => {
            setIsResolutionTooHigh(false);

            if (!file) {
                return null;
            }

            const url = URL.createObjectURL(file);
            setSrc(url);

            if (isImageTooLarge(file.size)) {
                setIsFileTooLarge(true);
                return null;
            }

            setIsFileTooLarge(false);
            setIsLoading(true);
            trigger(Triggers.CLICK_UPLOAD_PHOTO);

            return getImageSize(url)
                .then(({ width, height }) => {
                    if (isImageResolutionTooHigh({ width, height })) {
                        setIsResolutionTooHigh(true);
                        setIsLoading(false);
                        return null;
                    }

                    return new InternalAPI()
                        .uploadImage(imageName, 'temp', file)
                        .then(({ data: url }) => {
                            setIsLoading(false);
                            setFileURL(url);
                            setImageID(null);
                            setPhotoExternalID(imageName);

                            return url;
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            setUploadFailed(true);
                            setRetryParams([imageName, file]);

                            return err.toString();
                        });
                })
                .catch((err) => {
                    setIsLoading(false);
                    setUploadFailed(true);
                    setRetryParams([imageName, file]);

                    return err.toString();
                });
        },
        [],
    );

    const remove = React.useCallback(() => {
        setIsLoading(false);
        setFileURL(null);
        setImageID(null);
        setSrc(null);
        setIsFileTooLarge(false);
        setIsResolutionTooHigh(false);
        setUploadFailed(false);
        setRetryParams(null);
        setPhotoExternalID(null);
        setHeight(null);
        setWidth(null);
    }, []);

    const retry = React.useCallback(() => {
        if (!uploadFailed || !retryParams) {
            return;
        }

        setUploadFailed(false);
        uploadImage(...retryParams);
    }, [uploadImage, retryParams, uploadFailed]);

    return {
        uploadImage,
        loading,
        fileURL,
        photoExternalID,
        imageID,
        src,
        remove,
        isFileTooLarge,
        isResolutionTooHigh,
        uploadFailed,
        retry,
        width,
        height,
    };
};

export default useUploadImage;
