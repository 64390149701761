import type { LocationData } from 'strat/property';
import GTM_SECONDARY_LOCATION_TYPES from '@app/gtm/secondaryLocationTypes';

import groupNodesByType from './groupNodesByType';

const getSecondaryLocationsVariables = (locations?: LocationData | null) => {
    const secondaryLocations = (locations || []).filter((node) => !node.isMain);
    const nodesByType = groupNodesByType(secondaryLocations);

    return GTM_SECONDARY_LOCATION_TYPES.reduce<Record<string, any>>((accumulator, type, index) => {
        const nodes = nodesByType[type]?.length ? nodesByType[type] : [];
        accumulator[`loc_secondary_${index + 1}_ids`] = nodes
            // @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type.
            .map((node) => node.externalID)
            .join(',');
        // @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type.
        accumulator[`loc_secondary_${index + 1}_names`] = nodes.map((node) => node.name).join(',');
        return accumulator;
    }, {});
};

export default getSecondaryLocationsVariables;
