import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTotalSearchPageCount } from '@sector-labs/fe-search-redux/state';

import { useSearchHitsLayout } from 'horizontal/layout';
import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { selectCategories } from 'horizontal/categories';
import useFormattedSearchExtraFields from 'horizontal/search/useFormattedSeachExtraFields';

import { getDeepCategoryVars } from './utils';

const useSearchGTMVars = () => {
    const searchParams = useCurrentSearchRouteParams();
    const selectedLayout = useSearchHitsLayout();
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);
    const [totalPageCount] = useTotalSearchPageCount();
    const formattedSearchExtraFields = useFormattedSearchExtraFields();

    return React.useMemo(
        () => ({
            ...getDeepCategoryVars(categories, activeCategory?.externalID),
            listing_arrangement: selectedLayout,
            page_number: searchParams.page,
            page_count: totalPageCount,
            sort_by: searchParams.sortOption?.key,
            search_keyword: searchParams.freeTextQuery,
            ...formattedSearchExtraFields,
        }),
        [
            searchParams,
            selectedLayout,
            activeCategory,
            categories,
            totalPageCount,
            formattedSearchExtraFields,
        ],
    );
};

export default useSearchGTMVars;
