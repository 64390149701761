import { createSelector } from 'reselect';

import Platform from 'strat/platform';
import { selectUserAgent } from 'strat/user/selectors';
import { GlobalState } from 'strat/state';
import { selectLayout } from 'strat/layout/selectors';

import Layout from '../layout/layout';

/**
 * Detect whether the user is using an iPhone or not.
 */
const selectIsIphone = (state: GlobalState) =>
    state.platform?.platform === Platform.platform.IPHONE;

/**
 * Detect whether the user is using an Android or not.
 */
const selectIsAndroid = (state: GlobalState) =>
    state.platform?.platform === Platform.platform.ANDROID;

/**
 * Selects the user's current platform and checks if it is Mobile or Desktop
 */
const selectIsMobile = (state: GlobalState) => selectIsAndroid(state) || selectIsIphone(state);

/**
 * Selects if the user uses a Huawei device based on the user agent. This isn't
 * a conclusive method since it can result in some false negatives, thus missing
 * some Huawei devices
 */
const selectIsHuaweiDevice = createSelector([selectUserAgent], (userAgent) =>
    userAgent ? /huawei|HMS/i.test(userAgent) : false,
);

/**
 * Selects if the user uses a device that uses Google Mobile Services (GMS) based
 * on the user agent. This isn't a conclusive method since it can result in some
 * false negatives, thus missing some devices
 */
// @ts-expect-error - TS2769 - No overload matches this call. | TS7006 - Parameter 'userAgent' implicitly has an 'any' type.
const selectDeviceHasGMS = createSelector([selectUserAgent], (userAgent) => /GMS/i.test(userAgent));

/**
 * Selects if we should target Huawei Mobile Services (HMS) instead of Google Mobile Services (GMS).
 * This is true only if the user has a Huawei device which doesn't have access to GMS, since GMS is
 * the preffered option.
 */
const selectShouldTargetHMS = createSelector(
    [selectIsHuaweiDevice, selectDeviceHasGMS],
    (isHuaweiDevice, deviceHasGMS) => isHuaweiDevice && !deviceHasGMS,
);

/**
 * Selects the user's current platform.
 */
const selectPlatform = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.platform,
    // @ts-expect-error - TS7006 - Parameter 'platform' implicitly has an 'any' type.
    (platform) => platform.platform,
);

/**
 * Detect whether the user is using a Tablet or not.
 */
const selectIsTablet = createSelector(selectLayout, (layout) => layout === Layout.TABLET);

const selectSupportsWebp = (state: GlobalState) => state.platform?.supportsWebp;

export {
    selectPlatform,
    selectIsIphone,
    selectIsAndroid,
    selectIsTablet,
    selectIsMobile,
    selectIsHuaweiDevice,
    selectDeviceHasGMS,
    selectShouldTargetHMS,
    selectSupportsWebp,
};
