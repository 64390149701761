import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useSearchHits,
    useSearchPagination,
    useTotalSearchPageCount,
    selectSearchLoadingState,
} from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { RouteNames } from 'strat/routes';
import { Flex } from 'strat/components';

import { HitList } from 'horizontal/search/results';
import type { HitsProps } from 'horizontal/profile';
import { Pagination } from 'horizontal/pagination';

import { hitsTransformer } from '../search/hits';
import type { LiteAd, Location } from '../types';

import NoHits from './noHits';
import Filters from './filters';
import useProfilePaginationMessage from './hooks/useProfilePaginationMessage';
import useProfileParams from './hooks/useProfileParams';
import styles from './styles/ads.cssm';

const Hits = ({
    location,
    navigatePage,
    viewSection,
    isAgency,
    agencyType,
    viewType,
    loading,
}: HitsProps) => {
    const hits = useSearchHits();
    const language = useSelector(selectLanguage);
    // @ts-expect-error - TS2345 - Argument of type 'SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
    const transformedHits = hitsTransformer<LiteAd>(hits, language);
    const hasHits = !!hits.length;
    const [pageNumber] = useSearchPagination();
    const [totalPageCount] = useTotalSearchPageCount();
    const { loading: searchIsLoading } = useSelector(selectSearchLoadingState);
    const paginationMessage = useProfilePaginationMessage();
    const params = useProfileParams();
    const onLocationSelected = (selectedLocation: Location | null) => {
        const updatedParams = {
            ...params,
            page: 1,
            locationSlug: selectedLocation?.slug,
        };
        navigatePage(updatedParams);
    };

    return (
        <div>
            <Filters
                isAgency={isAgency}
                agencyType={agencyType}
                location={location}
                onLocationSelected={onLocationSelected}
            />
            <div>{paginationMessage}</div>
            <div>
                {hasHits || loading || searchIsLoading ? (
                    <HitList
                        hits={loading ? [] : transformedHits}
                        viewType={viewType}
                        loading={loading || searchIsLoading}
                        loadingHitsCount={12}
                        viewSection={viewSection}
                    />
                ) : (
                    <NoHits />
                )}
                {totalPageCount && pageNumber ? (
                    <Flex justifyCenter className={styles.paginationComponent}>
                        <Pagination
                            activePage={pageNumber}
                            maxVisiblePages={4}
                            totalPages={totalPageCount}
                            routeName={
                                isAgency ? RouteNames.AGENCY_PROFILE : RouteNames.SELLER_PROFILE
                            }
                            routeParams={params}
                        />
                    </Flex>
                ) : null}
            </div>
        </div>
    );
};

export default Hits;
