import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';

export const PasswordValidationErrors = {
    invalid: (i18n: I18n) =>
        t(i18n)`Password must include at least 1 number, 1 special character, 1 letter`,
    required: (i18n: I18n) => t(i18n)`This field is mandatory`,
    matchPassword: (i18n: I18n) => t(i18n)`Passwords don't match`,
    unauthorized: (i18n: I18n) => t(i18n)`Invalid Password`,
    tooShort: (i18n: I18n) =>
        t(i18n)`Password is too short. It should be at least 8 characters long`,
} as const;

type PasswordValidationSchemaConfigs = {
    readonly includeOldPassword?: boolean;
};

export const generatePasswordValidationSchema = (
    i18n: I18n,
    { includeOldPassword = true }: PasswordValidationSchemaConfigs = {},
) => {
    const schema: Record<string, unknown> = {
        password: Yup.string()
            .required(PasswordValidationErrors.required(i18n))
            .min(8, PasswordValidationErrors.tooShort(i18n))
            .matches(
                /^(?=.*[A-Za-z])(?=.*?(?:[^\w\s]|[_]))(?=.*\d).{8,}$/,
                PasswordValidationErrors.invalid(i18n),
            ),
        passwordConfirm: Yup.string()
            .required(PasswordValidationErrors.required(i18n))
            .test(
                'sameAs',
                PasswordValidationErrors.matchPassword(i18n),
                function sameAs(value: string) {
                    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
                    const other = this.resolve(Yup.ref('password'));
                    return !other || !value || value === other;
                },
            ),
    };

    if (includeOldPassword) {
        schema.oldPassword = Yup.string().required(PasswordValidationErrors.required(i18n));
    }

    return Yup.object(schema);
};
