import { t } from '@lingui/macro';
import { search, setIndex, setSettings } from '@sector-labs/fe-search-redux/state';
import settings from '@app/branding/settings';

import type { RoutingContextWithMiddlewares } from 'strat/app';
import Page from 'strat/pages/page';
import { enableURLUpdater, setPageParameters, updateURL } from 'strat/search/state';
import { createSearchRouteParams, createSearchURL, selectRouteParamsInput } from 'strat/search/url';
import { setPageInfo } from 'strat/seo/pageInfo/state';
import { logError } from 'strat/error/log';
import { StratAPI } from 'strat/api';
import { fetchAreaGuide } from 'strat/areaGuide/state';
import { buildCanonicalURL } from 'strat/routing';
import { serializeLocation, EnhancedLocation } from 'react-true-router/location';
import { setLanguage } from 'strat/i18n/language/state';
import { insertPopularSearches } from 'strat/seo/popularSearches/state';
import type { RouteParameters } from 'react-true-router/route';
import isTestLocation from 'strat/locations/isTestLocation';
import { SortByValues } from 'strat/search/sortValues';
import { fetchInternalLinks } from 'strat/search/internalLinks';
import fetchSearchGuideLinks from 'strat/routes/search/fetchSearchGuideLinks';
import { determineAdsIndexName } from 'strat/search/indexNames';

import { handleResult } from './handlers';
import computeAndTranslateSearchFilters from './computeAndTranslateSearchFilters';
import createLongTailSearchFilters from './createLongTailSearchFilters';
/**
 * Configures the state to render a normal search page
 */
const renderSearchPage = (
    context: RoutingContextWithMiddlewares,
    statusCode = 404,
    extraQueryParams: Object = {},
): void => {
    const {
        redux: {
            store: { dispatch },
        },
    } = context;

    // do not index 404 pages
    if (statusCode === 404) {
        // @ts-expect-error - TS2345 - Argument of type '{ indexable: false; }' is not assignable to parameter of type 'PageInfo'.
        dispatch(setPageInfo({ indexable: false }));
    }

    context.http.status(statusCode);
    context.rendering.renderPage(Page.SEARCH);

    const state = context.redux.store.getState();
    const routeParamsInput = selectRouteParamsInput(state);

    const routeParams = createSearchRouteParams(routeParamsInput.filters, {
        ...routeParamsInput.extraParams,
        ...extraQueryParams,
    });

    const unknownParams = state.unknownParams || {};
    // @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'.
    context.redux.store.dispatch(updateURL({ ...routeParams, unknownParams }, { replace: true }));
    context.redux.store.dispatch(enableURLUpdater());
};

/**
 * Configures the state to render a not-found page with
 * the 404 status code.
 */
const renderNotFoundPage = (
    context: RoutingContextWithMiddlewares,
    statusCode = 404,
    inputMessage: string | null = null,
): void => {
    const { i18n } = context;
    const message =
        inputMessage ||
        t(i18n)`Sorry, we couldn't find the page you're
                            trying to view. You can just specify the new
                            search criteria.`;
    // Render the correct index for searching ads before redirecting to a 404
    context.redux.store.dispatch(setIndex(determineAdsIndexName({ language: i18n.locale })));
    context.http.status(statusCode);
    context.rendering.renderPage(Page.NOT_FOUND, { message });
};

/**
 * Renders a long-tail search page by looking up
 * a pre-defined search page in the back-end that
 * matches the current URL.
 *
 * If there is, we build up the filter state
 * and render the search page.
 */
const renderLongTailPage = (
    context: RoutingContextWithMiddlewares,
    createURL: (params: RouteParameters) => EnhancedLocation,
    urlParams?: any,
) => {
    const {
        i18n,
        redux: {
            store: { dispatch },
        },
    } = context;
    const { basePath, page } = context.match.params;

    const promise = new StratAPI(i18n.locale)
        .searchPage(basePath)
        .then(({ data, status, headers }) => {
            // if the page doesn't exist in the requested language, but it does exist in another one,
            // either create search URL for base filters, or redirect to search default
            if (status === 300) {
                if (CONFIG.build.STRAT_ENABLE_REDIRECT_SEARCH_PAGE_TO_ROOT) {
                    const params = computeAndTranslateSearchFilters(
                        data.filters,
                        headers?.get('Content-Language') ?? 'en',
                        i18n.locale,
                    );
                    // @ts-ignore
                    const redirectURL = createSearchURL(params).pathname;
                    context.http.redirect(redirectURL, { status: 302 });
                } else {
                    // @ts-ignore
                    const redirectURL = createSearchURL({}).pathname;
                    context.http.redirect(redirectURL, { status: 302 });
                }
                return null;
            }

            if (status !== 200 && status !== 404) {
                throw Error(`Server returned ${status} for popular custom search page`);
            }

            if (!data) {
                return null;
            }

            // if the page is disabled, redirect to the configured URL
            if (!data.enabled) {
                context.http.redirect(data.redirectURL, { status: 301 });
                return null;
            }

            // @ts-ignore
            const url = createSearchURL({ basePath: data.url, page });
            // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
            const canonicalURL = buildCanonicalURL(url, data.language);
            const languageSwitchURLs = data.languageSwitchURLs
                ? Object.entries(data.languageSwitchURLs).map(
                      ([lang, languageSwitchURL]: [any, any]) =>
                          buildCanonicalURL(languageSwitchURL, lang),
                  )
                : null;

            const indexable = !isTestLocation(data.filters.location?.hierarchy?.[1]?.slug);

            const pageInfo = {
                pageTitle: data.pageTitle,
                description: data.description,
                h1Title: data.h1Title,
                indexable,
                canonicalURL,
                localizedURLs: {
                    [data.language]: canonicalURL,
                },
                languageSwitchURLs:
                    languageSwitchURLs ||
                    settings.languages
                        .map((l) => l.lang)
                        .filter((lang) => lang !== urlParams?.language)
                        .map((language) =>
                            serializeLocation(
                                createURL({
                                    purpose: data.filters.purpose,
                                    category: data.filters.category?.slug || null,
                                    locations: [data.filters.location || null].filter(Boolean),
                                    ...{
                                        urlParams,
                                        language,
                                    },
                                    alternativeLocationLanguage: true,
                                }),
                            ),
                        ),
            } as const;

            dispatch(setLanguage(data.language));
            // @ts-expect-error - TS2345 - Argument of type '{ readonly pageTitle: any; readonly description: any; readonly h1Title: any; readonly indexable: boolean; readonly canonicalURL: string; readonly localizedURLs: { readonly [x: number]: string; }; readonly languageSwitchURLs: string[]; }' is not assignable to parameter of type 'PageInfo'.
            dispatch(setPageInfo(pageInfo));
            dispatch(
                setPageParameters({
                    isAreaPage: false,
                    isBedroomPage: false,
                    isCustomPage: true,
                    areaPageUnit: data.filters.areaUnit
                        ? data.filters.areaUnit.toUpperCase()
                        : null,
                    areaPageValue: null,
                    basePath,
                    breadcrumbLeafText: data.breadcrumbText || null,
                }),
            );
            dispatch(
                insertPopularSearches(
                    {
                        // @ts-expect-error - TS2345 - Argument of type '{ isCustomPage: boolean; purpose: any; categorySlug: any; locationSlug: any; }' is not assignable to parameter of type 'Params'.
                        isCustomPage: true,
                        purpose: data.filters.purpose,
                        categorySlug: (data.filters.category || {}).slug || null,
                        locationSlug: (data.filters.location || {}).slug || null,
                    },
                    data.popularSearches,
                ),
            );

            context.rendering.renderPage(Page.SEARCH);

            const sortOptions = SortByValues.getSortOptionsByValue(data.sortBy);
            // @ts-expect-error - TS2322 - Type '{ value: "price_asc"; type: SearchRequestSortType; attributes: { name: string; order: SearchRequestOrder; }[]; } | { value: "price_desc"; type: SearchRequestSortType; attributes: { ...; }[]; } | ... 4 more ... | undefined' is not assignable to type 'SearchRequestSortOption | null | undefined'.
            dispatch(setSettings({ sort: sortOptions }));
            const index = determineAdsIndexName({ sortBy: data.sortBy, language: data.language });
            dispatch(setIndex(index));

            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
            const filters = createLongTailSearchFilters(i18n, data.filters, page);

            if (process.env.IS_SERVER) {
                fetchSearchGuideLinks(context, filters);
            }

            return Promise.all([
                ...(process.env.IS_SERVER ? [dispatch(fetchInternalLinks(filters))] : []),
                dispatch(search(filters)),
                dispatch(
                    fetchAreaGuide({
                        purpose: data.filters.purpose,
                        category: (data.filters.category || {}).slug || null,
                        location: (data.filters.location || {}).slug || null,
                        customSearchPageSlug: basePath,
                        beds: data.beds,
                        completionStatus: data.filters.completionStatus || null,
                        page: 1,
                    }),
                ).catch(() => {}),
            ]);
        })
        .then(() => handleResult(context))
        .catch((e) => {
            logError({
                e,
                msg: 'Unable to render long tail search page',
                context: {
                    url: context.match.url,
                },
            });

            context.http.status(500);
            context.rendering.renderPage(Page.INTERNAL_ERROR);
        });

    context.promise.wait(promise);
};

export { renderSearchPage, renderNotFoundPage, renderLongTailPage };
