import { FilterCollection, ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { type SearchResponse } from '@sector-labs/fe-search-redux/backend';

import { logError } from 'strat/error/log';
import FetcherFactory from 'strat/fetcher';
import { agentTransformer } from 'strat/mergedIndex';
import { AgentSortByValues } from 'strat/searchComponents';
import type { RawLeaderboardEntry } from 'strat/leaderboard/types';
import { searchAgents } from 'strat/agency/search/form/searchAgents';
import { selectI18n, selectLanguage } from 'strat/i18n/language/selectors';
import { mergeBIDataWithAlgoliaData } from '@app/agency/search/form/fetchDataFromProBoard';

export const NO_OF_TRUBROKERS_ON_HOMEPAGE = 4;

const factory = new FetcherFactory(
    'truBrokersHomepage',
    (_params: {}, state: any) => {
        const language = selectLanguage(state);
        const i18n = selectI18n(state);
        const backend = selectActiveSearchBackend(state);

        if (!backend) {
            return Promise.resolve({ data: null, status: 200 });
        }

        const filters = new FilterCollection();
        filters.refine(
            new ObjectExactFilter({
                attribute: 'isTruBroker',
                value: '1',
            }),
        );

        return searchAgents(
            [],
            AgentSortByValues.RANDOM,
            i18n,
            backend,
            filters,
            NO_OF_TRUBROKERS_ON_HOMEPAGE,
            false,
            false,
        )
            .then((response) => {
                if (!response?.[0]) {
                    throw new Error('Failed to fetch agent data for homepage.');
                }
                return mergeBIDataWithAlgoliaData(
                    response[0] as SearchResponse<RawLeaderboardEntry>,
                    language,
                    backend,
                );
            })
            .then((agentResults) => {
                if (agentResults == null) {
                    throw new Error('Failed to merge agent data.');
                }
                return {
                    data: agentResults[0].hits
                        ?.map((agent) =>
                            agentTransformer(
                                agent,
                                language,
                                CONFIG.build.ENABLE_AGENTS_MERGED_INDEX,
                            ),
                        )
                        .filter((agent) => agent.isTruBroker),
                    status: 200,
                };
            })
            .catch((e) => {
                logError({
                    e,
                    msg: 'Failed to fetch and process agents for homepage ',
                });

                return {
                    data: null,
                    status: 404,
                };
            });
    },
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

const fetchTruBrokersHomePage = factory.creator();

const truBrokersHomePageReducer = factory.reducer();

export { fetchTruBrokersHomePage };

export default truBrokersHomePageReducer;
