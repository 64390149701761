import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectNumberFormatterFactory } from 'strat/i18n/language';
import EMPTY_OBJECT from 'strat/empty/object';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';

import type { MixedAd, FlatCategoryField } from 'horizontal/types';
import { CategoryFieldRole, CategoryFieldValueType } from 'horizontal/types';
import { AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE } from 'horizontal/payment/types';
import { useFlatCategoryFields } from 'horizontal/categoryFields';
import { sortByGroupIndex } from 'horizontal/util';
import formatEnumArrayValue from 'horizontal/ad/formatEnumArrayValue';
import formatEnumValue from 'horizontal/ad/formatEnumValue';

export type AdFormattedExtraField = {
    readonly name: string;
    readonly attribute: string;
    readonly formattedValue: string | string[] | CategoryFieldGroupedChoices[];
};

const createFormattedField = (
    field: FlatCategoryField,
    formattedValue: string | string[] | CategoryFieldGroupedChoices[],
): AdFormattedExtraField => ({
    name: field.name,
    attribute: field.attribute,
    formattedValue,
});

const useAdFormattedExtraFields = (ad?: MixedAd | null): Array<AdFormattedExtraField> => {
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const extraFields = ad?.extraFields || EMPTY_OBJECT;

    const categoryFields = useFlatCategoryFields(ad?.category?.slice(-1)?.[0]?.id, extraFields);

    const formatNumber = React.useCallback(
        (value: number) => numberFormatterFactory().format(value),

        [numberFormatterFactory],
    );

    return React.useMemo(
        () =>
            categoryFields.sort(sortByGroupIndex).reduce<Array<any>>((acc, field) => {
                const value = extraFields[field.attribute];
                if (!value) {
                    return acc;
                }

                // free-ad-commission categoryField is not relevant to the user
                if (field.attribute === AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE) {
                    return acc;
                }

                if (field.valueType === CategoryFieldValueType.ENUM_MULTIPLE) {
                    const choice = formatEnumArrayValue(value, field);
                    return choice ? [...acc, createFormattedField(field, choice)] : acc;
                } else if (field.choices && field.choices.length > 0) {
                    const choice = formatEnumValue(value, field, extraFields, categoryFields);
                    if (!choice) {
                        if (typeof value === 'number') {
                            const formattedValue = formatNumber(value);
                            return [...acc, createFormattedField(field, formattedValue)];
                        }

                        return acc;
                    }

                    return [...acc, createFormattedField(field, choice)];
                } else if (
                    field.valueType === CategoryFieldValueType.FLOAT ||
                    field.valueType === CategoryFieldValueType.INTEGER
                ) {
                    const isFormattable = field.roles.includes(CategoryFieldRole.FORMATTABLE);
                    const formattedValue = isFormattable ? formatNumber(value) : value.toString();

                    return [...acc, createFormattedField(field, formattedValue)];
                }
                return [...acc, createFormattedField(field, value.toString())];
            }, []),
        [categoryFields, formatNumber, extraFields],
    );
};

export default useAdFormattedExtraFields;
