import * as React from 'react';
import classNames from 'classnames';
import { LoadingAnimation } from 'strat/components';
import { Link } from 'strat/navigation';

import { ChatRoomReadOnlyReason } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import { useChatLink } from 'horizontal/chat/hooks/useChatLink';
import { useIsChatRoomRoute } from 'horizontal/chat/state';
import type { ChatRoom, ChatSubjectXID } from 'horizontal/chat/types';

import Details from './details';
import Head from './head';
import Options from './options';
import styles from './styles/listItem.cssm';

type Props = {
    conversation: ChatRoom;
    renderListItem?: any;
};

const ListItem = ({ conversation, renderListItem }: Props) => {
    const sdk = useChatSDK();
    const { adXID, contactXID } = conversation;
    const { ad, contact, isLoading } = sdk.useRoomContactInfo({ contactXID, adXID });
    const isInitialDataLoaded = ad && contact;
    const isConversationSelected = useIsChatRoomRoute(contactXID, adXID as ChatSubjectXID);
    const { disabled } = sdk.useRoomDisabledInfo(conversation);
    const { route, params } = useChatLink({ adXID, contactXID });

    if (isLoading && !isInitialDataLoaded) {
        return <LoadingAnimation className={styles.loading} />;
    }

    const readonly = conversation?.readOnly === ChatRoomReadOnlyReason.READONLY_ROLE;

    if (renderListItem) {
        return renderListItem(route, params, conversation);
    }

    return (
        <Link
            route={route}
            params={params}
            className={classNames(styles.container, {
                [styles.selected]: isConversationSelected,
                [styles.disabled]: disabled,
            })}
            aria-label={'Conversation list item'}
        >
            <Head contactXID={contactXID} contactUser={contact} />
            <Details conversation={conversation} />
            {!readonly && <Options conversation={conversation} />}
        </Link>
    );
};

export default React.memo<Props>(ListItem);
