import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import isNil from 'lodash/isNil';

import { BedsGroupedUnits } from './unitListTransformers';

export const titleForBeds = (i18n: I18n, unit: BedsGroupedUnits, isCommercial: boolean) => {
    if (isNil(unit.bedsCount) && !isCommercial) {
        return t(i18n)`Other`;
    }

    if ((isNil(unit.bedsCount) || unit.bedsCount === '0') && isCommercial) {
        return t(i18n)`Unit types`;
    }

    if (unit.bedsCount === '0') {
        return t(i18n)`Studio`;
    }

    return t(i18n)`${unit.bedsCount} Bed`;
};
