import { PropertyCompletionStatus } from 'strat/property/types';
import type { PropertyData, CategoryNodeData } from 'strat/property/types';

import joinList from './joinList';

const getPropertyTypeFromProperty = (property: PropertyData): string | null | undefined => {
    const category = property.category[property.category.length - 1];
    if (!category) {
        return null;
    }

    if (property.completionStatus === PropertyCompletionStatus.OFF_PLAN) {
        return `offplan-${category.slug}`;
    }
    return category.slug;
};

const getPropertyTypeFromFilters = (
    completionStatus?: string | null,
    category?: CategoryNodeData | null,
) => {
    let propertyType = category?.name ? category.name.toLowerCase() : 'all';
    if (completionStatus === PropertyCompletionStatus.OFF_PLAN) {
        propertyType = `offplan-${propertyType}`;
    }
    return joinList([propertyType]);
};

/**
 * Determines the property type to be sent to GTM
 * The way this value is computed is different for property detail page and search page.
 * The function will know how to compute the property type based on its available parameters.
 */
const getPropertyType = (
    property?: PropertyData | null,
    completionStatus?: string | null,
    category?: CategoryNodeData | null,
): string | null | undefined => {
    if (property) {
        return getPropertyTypeFromProperty(property);
    }

    return getPropertyTypeFromFilters(completionStatus, category);
};

export default getPropertyType;
