import Route from './route';

/**
 * Parameters parsed from a URL that matched a route.
 */
type RouteParams = {
    [key: string]: string;
};

/**
 * Result of a route that matched an incoming URL.
 */
class RouteMatch {
    /**
     * Route that was matched.
     */
    route: Route;

    /**
     * URL that routing was performed at.
     */
    url: string;

    /**
     * Original URL that came in, before it was passed
     * through the URL processor.
     */
    originalURL: string;

    /**
     * Parameters parsed from the URL according to the route's patterns.
     */
    params: RouteParams;

    /**
     * Initializes a new instance of {@see RouteMatch}.
     * @param route Route that was matched.
     * @param url URL that routing was performed at.
     * @param params Parameters that were parsed from the URL according to
     * the route's patterns.
     */
    constructor(route: Route, url: string, params: RouteParams) {
        this.route = route;
        this.url = url;
        this.params = params;
        this.originalURL = this.url;
    }
}

export default RouteMatch;
