import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import debounce from 'just-debounce';

import { useI18n } from 'strat/i18n/language';
import { StylelessButton } from 'strat/generic';
import IconArrowUp from '@app/assets/icons/backToTopIcon.svg';
import styles from '@app/components/styles/backToTopButton.cssm';

const BackToTopButton = () => {
    const i18n = useI18n();
    const [hidden, setHidden] = React.useState(true);

    const onClick = React.useCallback(() => {
        window.scrollTo({ top: 0 });
    }, []);

    React.useEffect(() => {
        const doubleViewportHeight = 2 * window.innerHeight;
        const onScroll = debounce(() => {
            // @ts-expect-error - TS2345 - Argument of type 'true | 0' is not assignable to parameter of type 'SetStateAction<boolean>'.
            setHidden(window.scrollY < doubleViewportHeight || 0);
        }, 50);

        window.addEventListener('scroll', onScroll, {
            passive: true,
        });

        setHidden(true);
        // @ts-expect-error - TS2769 - No overload matches this call.
        return () => window.removeEventListener('scroll', onScroll, { passive: true });
    }, []);

    return (
        <StylelessButton
            onClick={onClick}
            className={classNames(styles.button, {
                [styles.hidden]: hidden,
                [styles.visible]: !hidden,
            })}
        >
            <IconArrowUp className={styles.icon} aria-label={'Icon arrow back to top'} />
            <span className={styles.text}>{t(i18n)`Back to top`}</span>
        </StylelessButton>
    );
};

export default BackToTopButton;
