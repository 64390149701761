import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useRouter } from 'react-true-router';
import { StylelessButton } from 'strat/generic';
import RouteNames from 'strat/routes/routeNames';
import { Button, Flex, LoadingAnimation, Text } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { useLoginDialog } from 'strat/auth';

import UserProfilePicture from '@app/user/userProfilePicture';
import VerifiedProfileIcon from 'horizontal/profile/verifiedProfileIcon';
import useMyProfile from 'horizontal/profile/hooks/useMyProfile';
import { useMyProfileIsLoading } from 'horizontal/profile/state';

import styles from './styles/userProfileBriefing.cssm';
import EditProfileSection from './editProfileSection';

const UserProfileBriefing = ({ onClick }: { onClick?: () => void }) => {
    const router = useRouter();
    const user = useActiveUser();
    const [, setLoginDialogVisibility] = useLoginDialog();
    const isLoading = useMyProfileIsLoading();
    const { profile, isAgency } = useMyProfile();

    const onEditProfileClick = React.useCallback(() => {
        router.pushRoute(RouteNames.EDIT_PROFILE, { section: EditProfileSection.INFO });
    }, [router]);

    const info = React.useMemo(() => {
        if (!user) {
            return (
                <>
                    <div>
                        <Text.Regular>
                            <Trans>Enter to your account</Trans>
                        </Text.Regular>
                    </div>
                    <StylelessButton
                        onClick={() => {
                            setLoginDialogVisibility(true);
                        }}
                    >
                        <Text.Regular underlined>
                            <Trans>Log in to your account</Trans>
                        </Text.Regular>
                    </StylelessButton>
                </>
            );
        }

        return (
            <Flex className={styles.infoWrapper} column>
                <div className={styles.hello}>
                    <Text.Large>
                        <Trans>Hello,</Trans>
                    </Text.Large>
                </div>
                <Flex className={styles.name} alignCenter>
                    <Text.XLarge bold aria-label="Username">
                        {user.name}
                    </Text.XLarge>
                    {!isAgency && profile?.isVerified && <VerifiedProfileIcon leftMargin large />}
                </Flex>
            </Flex>
        );
    }, [user, setLoginDialogVisibility, profile, isAgency]);

    const renderAgencyBriefing = React.useMemo(() => {
        if (isLoading) {
            return <LoadingAnimation height="2.4rem" width="80%" />;
        }

        return isAgency && profile ? (
            <Flex className={styles.agencyName} alignCenter>
                <Text.Large>{profile.name}</Text.Large>
                {profile.isVerified && <VerifiedProfileIcon leftMargin large />}
            </Flex>
        ) : null;
    }, [isLoading, isAgency, profile]);

    return (
        <Flex className={styles.container} onClick={onClick} column>
            <Flex>
                <div className={styles.image}>
                    <UserProfilePicture
                        showProBadge
                        proBadgeClassName={styles.proBadgePosition}
                        boldProBadge
                    />
                </div>
                <Flex justifyCenter column className={styles.info}>
                    {info}
                </Flex>
            </Flex>
            {isAgency && renderAgencyBriefing}
            {user && (
                <div className={styles.viewProfileButton}>
                    <Button secondary small stretch onClick={onEditProfileClick}>
                        <Text.Regular>
                            <Trans>View and edit your profile</Trans>
                        </Text.Regular>
                    </Button>
                </div>
            )}
        </Flex>
    );
};

export default UserProfileBriefing;
