import type { RoutingContextWithMiddlewares } from 'strat/app';
import { selectUserRoles } from 'strat/user/session';
import { isStratCreditUser } from 'strat/user/roles';

import Page from 'horizontal/pages/page';

const ensureHasAccessToStratCredits = (context: RoutingContextWithMiddlewares): boolean => {
    const userRoles = selectUserRoles(context.redux.store.getState());
    if (isStratCreditUser(userRoles)) {
        return true;
    }
    context.http.status(404);
    context.rendering.renderPage(Page.NOT_FOUND);
    return false;
};

export default ensureHasAccessToStratCredits;
