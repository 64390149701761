import * as React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';
import { Flex } from 'strat/components';
import { Trans } from '@lingui/macro';

import {
    fetchWalletBalance,
    selectWalletBalance,
    selectFirstAmountToExpire,
} from 'horizontal/payment/wallet';
import { formatDate, useFormattedPrice } from 'horizontal/util';
import IconWallet from 'horizontal/assets/icons/iconUserWallet_noinline.svg';
import type { AppDispatch } from 'horizontal/state';

import styles from './styles/walletBalance.cssm';

type Props = {
    readonly isCompact?: boolean;
};

const WalletBalance = ({ isCompact }: Props) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        dispatch(fetchWalletBalance());
    }, [dispatch]);
    const balance = useSelector(selectWalletBalance);
    const firstAmountToExpire = useSelector(selectFirstAmountToExpire);
    const balanceDisplayText = useFormattedPrice(balance);
    const firstAmountToExpireDisplayText = useFormattedPrice(firstAmountToExpire?.value);
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const formattedDate = formatDate(i18n, firstAmountToExpire?.expiresAt * 1000, dateOptions);
    const expirationText = (): React.ReactElement =>
        firstAmountToExpire?.value == balance ? (
            <Text.Large>
                <Trans>
                    Your balance will expire on
                    <b> {formattedDate} </b>
                </Trans>
            </Text.Large>
        ) : (
            <Text.Large>
                <Trans>
                    {firstAmountToExpireDisplayText} credit will expire on
                    <b> {formattedDate} </b>
                </Trans>
            </Text.Large>
        );

    return (
        <>
            <Flex column>
                <Flex>
                    <Flex column className={styles.description}>
                        <span
                            className={classNames(styles.yourBalance, {
                                [styles.compact]: isCompact,
                            })}
                        >
                            <Trans>Your Balance</Trans>
                        </span>
                        <span
                            className={classNames(styles.balance, { [styles.compact]: isCompact })}
                        >
                            {balanceDisplayText}
                        </span>
                    </Flex>
                    <img
                        src={IconWallet}
                        className={classNames(styles.icon, { [styles.compact]: isCompact })}
                    />
                </Flex>
            </Flex>
            <Flex className={classNames(styles.expirationMassage, { [styles.compact]: isCompact })}>
                {balance != 0 && expirationText()}
            </Flex>
        </>
    );
};

export default WalletBalance;
