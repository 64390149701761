import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';

import { useI18n } from 'strat/i18n/language';
import { useKeycloakErrorData } from 'strat/auth/keycloak';
import { Flex, LoadingSpinnerOverlay } from 'strat/components';
import { InternalError } from 'strat/error';
import { trackLoginFail, trackSignUpFail } from 'strat/gtm';
import { useRouter } from 'react-true-router';
import { AuthFlow, LoginType } from 'strat/auth/types';

const Head = () => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <>
            <title>{t(i18n)`Login | ${brandName}`}</title>
            <meta name="robots" content="noindex, nofollow" />
        </>
    );
};

const ThirdPartyLogin = () => {
    const i18n = useI18n();
    const [errorData] = useKeycloakErrorData();
    const router = useRouter();
    const loginType = (router.context?.match.params.loginType || null) as LoginType;
    const authFlow = (router.context?.match.params.authFlow || null) as AuthFlow;

    React.useEffect(() => {
        if (errorData) {
            switch (authFlow) {
                case AuthFlow.REGISTER:
                    trackSignUpFail(errorData.description, loginType);
                    break;
                case AuthFlow.LOGIN:
                    trackLoginFail(errorData.description, loginType);
            }
        }
    }, [errorData, loginType, authFlow]);

    if (errorData) {
        return <InternalError message={errorData.message} isLikelyToBeRenderedInPopup />;
    }

    return <LoadingSpinnerOverlay visible text={t(i18n)`Logging in...`} />;
};

const LoginCallbackPage = {
    head: () => <Head />,
    body: () => (
        <Flex alignCenter justifyCenter stretchWidth stretchHeight column>
            <ThirdPartyLogin />
        </Flex>
    ),
} as const;

export default LoginCallbackPage;
