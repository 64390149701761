import type { FormikValues } from 'formik';

import type { CategoryField, FlatCategoryField } from 'horizontal/types';
import { CategoryFieldChoicesKeys } from 'horizontal/types';

import type { AttributeChoices, ParentFields } from './useEnforceCategoryFieldCombination';

function flattenFieldsWithCombinations(
    fields: Array<CategoryField>,
    values: FormikValues,
    attributeChoicesLookup: AttributeChoices,
    parentFieldLookup: ParentFields,
): Array<FlatCategoryField> {
    return fields.reduce<Array<any>>((fieldsAccumulator, field) => {
        if (field.choices && !Array.isArray(field.choices)) {
            const parentField = parentFieldLookup[field.id];
            const parentValue = parentField ? values[parentField.attribute] : null;
            const parentChoiceID = parentValue
                ? attributeChoicesLookup[parentField.attribute][parentValue]?.id
                : null;
            const flatField = {
                ...field,
                choices: field.choices?.[parentChoiceID ?? CategoryFieldChoicesKeys.ALL],
                combinationChoices:
                    field.combinationChoices?.[parentChoiceID ?? CategoryFieldChoicesKeys.ALL],
            } as const;

            return [...fieldsAccumulator, flatField];
        }
        // @ts-expect-error - TS2352 - Conversion of type 'CategoryField' to type 'FlatCategoryField' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first. | TS2352 - Conversion of type 'CategoryField' to type 'FlatCategoryField' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        return [...fieldsAccumulator, field as FlatCategoryField];
    }, []);
}

export default flattenFieldsWithCombinations;
