import { useSelector } from 'react-redux';

import { selectRemarketedListing, selectShowRemarketedListing } from 'strat/search/selectors';
import { PropertyData } from 'strat/property';

import { selectPageRule } from './selectors';

const usePageRule = () => useSelector(selectPageRule);

const useRemarketedListing = (): PropertyData | null => useSelector(selectRemarketedListing);

const useShowRemarketedListing = (): boolean => !!useSelector(selectShowRemarketedListing);

export { usePageRule, useRemarketedListing, useShowRemarketedListing };
