import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { PageWidthWrapper } from 'strat/generic';
import selectWallpaperTakeover from 'strat/wallpaper/selectors';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import Footer from 'horizontal/footer';
import Ad, { AdHeading } from 'horizontal/adDetails';
import Wallpaper from 'horizontal/wallpaper/wallpaper';
import { useGetWallpaperForSize } from 'horizontal/util';
import AppInstallBanner from '@app/home/appInstallBanner';

import GptTokenResetter from './gptTokenResetter';
import styles from './styles/adDetailsPageDesktop.cssm';

const AdDetailsBodyDesktop = () => {
    const wallpaper = useSelector(selectWallpaperTakeover);
    const isWallpaperActive = useGetWallpaperForSize(wallpaper);

    return (
        <Flex column stretchHeight fillContainer>
            <GptTokenResetter />
            <Header withHighlightedVertical />
            <CategoryDropdownSwitcher />
            <Wallpaper>
                <Flex fillContainer>
                    <PageWidthWrapper
                        className={classNames(styles.container, {
                            [styles.wallpaperBannerAd]: isWallpaperActive,
                            margin: wallpaper.targetURL === null,
                        })}
                    >
                        <div className={styles.heading}>
                            <AdHeading />
                        </div>
                        <Ad />
                    </PageWidthWrapper>
                </Flex>
                {settings.enableAdDetailsAppInstallBanner && <AppInstallBanner />}
                <Footer
                    className={classNames({
                        [styles.wallpaperActive]: isWallpaperActive,
                    })}
                />
            </Wallpaper>
        </Flex>
    );
};

export default AdDetailsBodyDesktop;
