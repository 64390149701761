import { LocalizedString, Range } from 'strat/types';

import { AdFormattedExtraField } from 'horizontal/ad';
import {
    AdExtraFields,
    AdPhoto,
    AdStateFilter,
    ProductStateFilter,
    ProductType,
} from 'horizontal/types';
import type { AdAgent, ProductPurchase } from 'horizontal/types';

export type DateRange = {
    from?: Date | null | undefined;
    to?: Date | null | undefined;
};

export enum DateFilterTypes {
    CREATE_DATE = 'createDate',
    UPDATE_DATE = 'updateDate',
}

export type Filters = {
    category?: string | null;
    agent?: string | null;
    stateFilter?: Values<typeof AdStateFilter> | null;
    productFilter?: Values<typeof ProductStateFilter> | null;
    createDate?: DateRange | null;
    updateDate?: DateRange | null;
    agentCode?: string | null;
    phoneNumber?: string | null;
    location?: string;
};

export type HandleAgentDialogResponseProps = {
    readonly status: number;
    readonly errorMessage: string;
};

export type HandleAgentDialogErrorProps = {
    readonly error: number;
    readonly errorMessage: string;
};

export type OnSubmitAgentDialogProps = {
    readonly existingAgentExternalID?: string;
    readonly selectedAgent: AdAgent;
    readonly agencyExternalID: string;
    readonly handleResponse: (params: HandleAgentDialogResponseProps) => void;
    readonly handleError: (params: HandleAgentDialogErrorProps) => void;
};

export type ActivateAdBulkDataResponse =
    | string
    | {
          readonly adsExternalIDs: Array<string>;
      };

export type ActivateSingleAdDataResponse = {
    message: string;
};

export type HandleActivateAdDialogResponseProps = {
    readonly status: number;
    readonly data?: ActivateSingleAdDataResponse | ActivateAdBulkDataResponse;
    readonly setError: (errorMessage: string) => void;
};

export type HandleActivateAdDialogErrorProps = {
    readonly error: Error;
    readonly setError: (errorMessage: string) => void;
};

export type OnSubmitUnassignAgentProps = {
    readonly agencyExternalID: string;
    readonly agencyOwnerExternalID: string;
};

export type AgencyProductPurchase = {
    totalLimit: number;
    totalConsumption: number;
    ownerConsumption: number;
    assigned: number;
    availableQuotaToAssign: number;
    unUsed: number;
    agencyTotalConsumption?: number;
    agencyTotalLimit?: number;
} & ProductPurchase;

export type UnifiedProductPurchase = {
    readonly id: number;
    readonly name: string;
    readonly agentAssignedQuota: number;
    readonly agentTotalConsumption: number;
    readonly agentRemainingQuota: number;
    readonly ownerPackageConsumption: number;
    readonly agencyPackageLimit: number;
    readonly agencyAssignedQuota: number;
    readonly agencyAvailableQuotaToAssign: number;
};

export type InviteAgentData = {
    email: string;
    name: string | undefined;
};

export type ProtoolAgencyLeadsFilterParams = {
    readonly freeTextSearch: string | null;
    readonly leadType: LeadType | null;
    readonly dateInterval: DateRange | null;
    readonly agentExternalIDs: string[] | null;
    readonly adExternalID: string | null;
    readonly page: number | null;
    readonly timestamp: Range;
    readonly pageSize?: number | null;
    readonly excludeAnonymousLead?: boolean | null;
};

export enum LeadType {
    WHATSAPP = 'whatsapp',
    PHONE = 'phone',
    SMS = 'sms',
    CHAT = 'chat',
    EMAIL = 'email',
}

export type Lead = {
    readonly adExternalID: string;
    readonly type: Values<typeof LeadType> | null;
    readonly buyerName: string;
    readonly buyerPhone: string;
    readonly coverURL: string;
    readonly price: string;
    readonly createdAt: Date;
    readonly adTitle: string;
    readonly extraFields: AdExtraFields;
    readonly category: LocalizedString;
    readonly agentName: string;
    readonly userExternalId: string;
    readonly buyerExternalId: string;
};

export type ConsumptionBreakdown = {
    consumption: number;
    products: Array<Values<typeof ProductType>>;
    adsCount: number;
    name: string;
    name_l1: string;
};

export type ConsumptionLogAd = {
    readonly externalID: string;
    readonly photos: Array<AdPhoto>;
    readonly title: string;
    readonly title_l1: string;
    readonly formattedExtraFields: Array<AdFormattedExtraField>;
};

export type ConsumptionLog = ConsumptionBreakdown & {
    readonly ad: ConsumptionLogAd;
    readonly createdAt: number;
    readonly agent: AdAgent;
};

export enum CreditsViewScope {
    ALL = 'all',
    SELF = 'self',
    AGENTS = 'agents',
}

export enum TransactionType {
    ADD = 'add',
    REMOVE = 'remove',
}

export type CreditsSuccessToastProps = {
    creditsAmount: number;
    agentName: string;
    remainingAgencyCredits: number;
    transactionType: TransactionType;
};
