import * as React from 'react';
import { StylelessButton } from 'strat/generic';

import IconSearch from 'horizontal/assets/icons/iconSearch.svg';

import styles from './styles/freeTextSearchButton.cssm';

type Props = {
    readonly onClick?: () => void;
};

const FreeTextSearchButton = ({ onClick }: Props) => (
    <StylelessButton aria-label="Search" onClick={onClick} className={styles.button}>
        <IconSearch className={styles.icon} />
    </StylelessButton>
);

export default FreeTextSearchButton;
