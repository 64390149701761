import * as React from 'react';

import ScrollNavSectionContext from './scrollNavSectionContext';

/**
 * Hooks a component into the parent {@see ScrollNavSection}.
 *
 * This allows you to display the title of the section you're
 * currently rendering in. For example:
 *
 * <ScrollNavSection title="Beer">
 *      {connectScrollNavSection({ title } =>
 *          <strong>{title}</strong>
 *      )}
 *  </ScrollNavSection>
 */
export default (component: any) => {
    const ScrollNavSectionConnector = (props: any) => {
        const context = React.useContext(ScrollNavSectionContext);

        return React.createElement(component, {
            ...props,
            title: context?.title,
            shortTitle: context?.shortTitle,
        });
    };

    return ScrollNavSectionConnector;
};
