import {
    FilterCollection,
    ExactFilter,
    RangeFilter,
    ObjectExactFilter,
    RefinementFilter,
    OneToKOrMoreFilter,
} from '@sector-labs/fe-search-redux/filters';
import type { RangeFilterValue, PageFilter, Filter } from '@sector-labs/fe-search-redux/filters';
import Category from '@app/branding/category';
import settings from '@app/branding/settings';

import type { AppDispatch, GlobalState } from 'strat/state';
import Purpose from 'strat/purpose';
import { FilterValues } from 'strat/search';
import { selectI18n } from 'strat/i18n/language/selectors';
import { selectIsRootCategoryResidentialFromFilters } from 'strat/search/filterSelectors';
import { showSearchResults } from 'strat/search/state';
import rootLocationNode from '@app/branding/dldRootLocationNode';
import {
    AgencyData,
    AgencyType,
    PropertyCompletionStatus,
    PropertyType,
} from 'strat/property/types';
import type { LocationNodeData, CategoryNodeData } from 'strat/property/types';
import type { Range } from 'strat/types';
import type { AgentData } from 'strat/agency/agent/types';

import { determineIsProjectSearchActive } from './project';

/**
 * Returns a filter collection corresponding to the given state
 *
 * @param getState function to retrive state
 */
const filtersFromState = (getState: () => GlobalState) => {
    return new FilterCollection(getState().algolia.filters);
};

/**
 * Compares two filter collections, ignoring the filters with the name in the
 * ignore list, and returns true if they contain the same filters.
 *
 * @param firstFilters collection of filters to be compared
 * @param secondFilters collection of filters to be compared
 * @param ignoreList list of filter names for the filters that will
 * be ignored during the check.
 */
const areFiltersEqual = (
    firstFilters: any,
    secondFilters: any,
    ignoreList: Array<any | string>,
) => {
    const firstCopy = firstFilters.copy(ignoreList);
    const secondCopy = secondFilters.copy(ignoreList);

    return firstCopy.equals(secondCopy);
};

/**
 * Returns true if the filter has a different value in the current
 * filter collection compared to the old one.
 *
 * @param newFilters the current filter collection
 * @param oldFilters the old filter collection
 * @param filterName the filter for which the check is done
 */
const hasFilterChanged = (
    newFilters: FilterCollection,
    oldFilters: FilterCollection,
    filterName: string,
    isEqual: (v1?: any, v2?: any) => boolean = (v1: any, v2: any) => v1 === v2,
) => {
    const newFilterValue = newFilters.getFilterValue(filterName);
    const oldFilterValue = oldFilters.getFilterValue(filterName);

    return !isEqual(newFilterValue, oldFilterValue);
};

/**
 * Verify if only the filter with the given name was modified
 * ignoring the modifications for the filters in the ignore list.
 *
 * @param newFilters the current filter collection
 * @param oldFilters the old filter collection
 * @param filterName name of the filter for which the check is done.
 * @param ignoreList list of filter names for the filters that will
 * be ignored during the check.
 */
const hasOnlyOneFilterChanged = (
    newFilters: FilterCollection,
    oldFilters: FilterCollection,
    filterName: string,
    ignoreList = [],
) => {
    // Add filter to ignore list
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
    ignoreList.push(filterName);

    return (
        areFiltersEqual(newFilters, oldFilters, ignoreList) &&
        hasFilterChanged(newFilters, oldFilters, filterName)
    );
};

/**
 * Reset purpose filter to default value if its value is not one
 * of the expected values (i.e. FOR_RENT/FOR_SALE).
 * Under the leaderboard feature, if we are in agent search,
 * we will accept the value 'ANY' for purpose.
 */
const processPurposeFilter = (filters: FilterCollection) => {
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    const agentRentalFilter = filters.getFilterValue(FilterValues.agentRentalCategory.attribute);
    const agentSaleFilter = filters.getFilterValue(FilterValues.agentSaleCategory.attribute);
    const isAgentSearch = agentRentalFilter || agentSaleFilter;
    if (CONFIG.build.STRAT_ENABLE_LEADERBOARD && isAgentSearch && purpose === Purpose.ANY) {
        return;
    }
    if (purpose !== Purpose.FOR_SALE && purpose !== Purpose.FOR_RENT) {
        filters.refine(
            new ExactFilter({
                attribute: FilterValues.purpose.attribute,
                value: FilterValues.purpose.default,
            }),
        );
    }
};

/**
 * Process rent frequency filter as follows:
 * - Add rent frequency filter if none is set.
 * - Disables rent frequency filter when purpose is for sale.
 * - Disables rent frequency filter when its value is equal with disabling value.
 */
const processRentFrequencyFilter = (filters: FilterCollection) => {
    let rentFrequencyFilter = filters.getFilter(FilterValues.rentFrequency.attribute);

    // Add filter with default value if none exists
    if (!rentFrequencyFilter) {
        rentFrequencyFilter = new ExactFilter({
            attribute: FilterValues.rentFrequency.attribute,
            value: FilterValues.rentFrequency.default,
        });

        filters.refine(rentFrequencyFilter);
    }

    // Enable/Disable filter
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    if (
        rentFrequencyFilter.value === FilterValues.rentFrequency.disablingValue ||
        purpose === Purpose.FOR_SALE
    ) {
        rentFrequencyFilter.disable();
    } else if (purpose === Purpose.FOR_RENT) {
        rentFrequencyFilter.enable();
    }
};

const processCompletionPercentageFilter = (filters: FilterCollection) => {
    const completionPercentageFilter = filters.getFilter<Filter<Range>>(
        FilterValues.completionPercentage.attribute,
    );
    const completionStatus = filters.getFilter(FilterValues.completionStatus.attribute);
    if (completionStatus?.value !== PropertyCompletionStatus.OFF_PLAN || !completionStatus.active) {
        completionPercentageFilter?.disable();
    } else {
        completionPercentageFilter?.enable();
    }
};

const processPreHandoverPaymentFilter = (filters: FilterCollection) => {
    const preHandoverPaymentFilter = filters.getFilter<Filter<Range>>(
        FilterValues.preHandoverPayment.attribute,
    );
    const completionStatus = filters.getFilter(FilterValues.completionStatus.attribute);
    if (completionStatus?.value !== PropertyCompletionStatus.OFF_PLAN || !completionStatus.active) {
        preHandoverPaymentFilter?.disable();
    } else {
        preHandoverPaymentFilter?.enable();
    }
};

const processHandoverDateFilter = (filters: FilterCollection) => {
    const handoverDate = filters.getFilter<Filter<Range>>(FilterValues.handoverDate.attribute);
    const completionStatus = filters.getFilter(FilterValues.completionStatus.attribute);
    if (completionStatus?.value !== PropertyCompletionStatus.OFF_PLAN || !completionStatus.active) {
        handoverDate?.disable();
    } else {
        handoverDate?.enable();
    }
};

/**
 * Process completion status filter:
 * - Add completion status filter if none is set.
 * - Disables completion status filter when purpose is for sale.
 * - Disables completion status filter when its value is equal with disabling value.
 */
const processCompletionStatusFilter = (filters: FilterCollection) => {
    // apply the default completion status filter if the feature is enabled
    let completionStatusFilter = filters.getFilter(FilterValues.completionStatus.attribute);
    if (!completionStatusFilter) {
        completionStatusFilter = new ExactFilter({
            attribute: FilterValues.completionStatus.attribute,
            value: FilterValues.completionStatus.default,
        });
        if (!settings.hideSaleCompletionStatus || !settings.hideRentCompletionStatus) {
            filters.refine(completionStatusFilter);
        }
    }

    // Enable/Disable filter
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    if (
        completionStatusFilter.value === FilterValues.completionStatus.disablingValue ||
        (purpose === Purpose.FOR_RENT && settings.hideRentCompletionStatus) ||
        (purpose === Purpose.FOR_SALE && settings.hideSaleCompletionStatus)
    ) {
        completionStatusFilter.disable();
    } else {
        completionStatusFilter.enable();
    }
};

/**
 * Process residence type filter:
 * - Add completion status filter if none is set.
 * - Enable residence type filter if the root category is residential
 */
const processResidenceTypeFilter = (filters: FilterCollection, getState: () => GlobalState) => {
    let residenceTypeFilter = filters.getFilter(FilterValues.residenceType.attribute);
    if (!residenceTypeFilter) {
        residenceTypeFilter = new ExactFilter({
            attribute: FilterValues.residenceType.attribute,
            value: FilterValues.residenceType.default,
        });

        filters.refine(residenceTypeFilter);
    }

    // Enable/Disable filter
    const i18n = selectI18n(getState());
    if (
        residenceTypeFilter &&
        settings.enableResidenceTypeFilter &&
        selectIsRootCategoryResidentialFromFilters(i18n, filters)
    ) {
        residenceTypeFilter.enable();
    } else {
        residenceTypeFilter.disable();
    }
};

/**
 * Process occupancy status filter:
 * - Add occupancy status filter if none is set.
 * - Disables occupancy status filter when purpose is for sale.
 */
const processOccupancyStatusFilter = (filters: FilterCollection) => {
    let occupancyStatusFilter = filters.getFilter(FilterValues.occupancyStatus.attribute);
    if (!occupancyStatusFilter) {
        occupancyStatusFilter = new ExactFilter({
            attribute: FilterValues.occupancyStatus.attribute,
            value: FilterValues.occupancyStatus.default,
        });
        filters.refine(occupancyStatusFilter);
    }

    // Enable/Disable filter
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    if (
        occupancyStatusFilter &&
        settings.enableOccupancyStatusFilter &&
        purpose === Purpose.FOR_RENT
    ) {
        occupancyStatusFilter.enable();
    } else {
        occupancyStatusFilter.disable();
    }
};

/**
 * Process furnishing status filter:
 * - Add furnishing status filter if none is set.
 * - Disables furnishing status filter when purpose is for sale.
 */
const processFurnishingStatusFilter = (filters: FilterCollection) => {
    let furnishingStatusFilter = filters.getFilter(FilterValues.furnishingStatus.attribute);
    if (!furnishingStatusFilter) {
        furnishingStatusFilter = new ExactFilter({
            attribute: FilterValues.furnishingStatus.attribute,
            value: FilterValues.furnishingStatus.default,
        });
        filters.refine(furnishingStatusFilter);
    }

    // Enable/Disable filter
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    if (
        furnishingStatusFilter &&
        ((settings.enableFurnishingStatusForRent && purpose === Purpose.FOR_RENT) ||
            (settings.enableFurnishingStatusForSale && purpose === Purpose.FOR_SALE)) &&
        furnishingStatusFilter.value !== FilterValues.furnishingStatus.default
    ) {
        furnishingStatusFilter.enable();
    } else {
        furnishingStatusFilter.disable();
    }
};

/**
 * Add a custom filter which makes sure that when you filter
 * on "studio", we only show apartments and hotel apartments.
 */
const processBedsFilter = (filters: FilterCollection) => {
    let studioFilter = filters.getFilter(FilterValues.studioFilter.key);
    if (!studioFilter) {
        studioFilter = new RefinementFilter(FilterValues.studioFilter);
    }

    const bedsFilter = filters.getFilter<OneToKOrMoreFilter>(FilterValues.beds.attribute);
    const beds = bedsFilter?.value;

    if (beds && beds.includes(0) && beds?.length === 1) {
        filters.replace(studioFilter);
    } else {
        filters.remove(FilterValues.studioFilter.key);
    }
};

/**
 * Update the price filter.
 * - Reset the price filter if only the purpose changes.
 * - Update the priice filter if the rent frequency changes.
 */
const processPriceFilter = (filters: FilterCollection, getState: () => GlobalState) => {
    const priceValue = filters.getFilterValue<RangeFilterValue>(FilterValues.price.attribute);
    if (!priceValue) {
        return;
    }

    const oldFilters = filtersFromState(getState);

    if (
        hasOnlyOneFilterChanged(filters, oldFilters, FilterValues.purpose.attribute, [
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            FilterValues.rentFrequency.attribute,
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            FilterValues.completionStatus.attribute,
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            FilterValues.furnishingStatus.attribute,
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            FilterValues.page.attribute,
        ])
    ) {
        filters.remove(FilterValues.price.attribute);
        return;
    }

    if (
        hasOnlyOneFilterChanged(filters, oldFilters, FilterValues.rentFrequency.attribute, [
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            FilterValues.page.attribute,
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            FilterValues.completionStatus.attribute,
        ])
    ) {
        const oldRentFrequencyValue = oldFilters.getFilterValue(
            FilterValues.rentFrequency.attribute,
        );
        const rentFrequencyValue = filters.getFilterValue(FilterValues.rentFrequency.attribute);

        const oldDivider = FilterValues.rentFrequency.divider(oldRentFrequencyValue);
        const newDivider = FilterValues.rentFrequency.divider(rentFrequencyValue);

        const value = {
            min:
                priceValue.min !== null
                    ? // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2531 - Object is possibly 'null'. | TS2531 - Object is possibly 'null'.
                      Math.round((priceValue.min * oldDivider) / newDivider)
                    : null,
            max:
                priceValue.max !== null
                    ? // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2531 - Object is possibly 'null'. | TS2531 - Object is possibly 'null'.
                      Math.round((priceValue.max * oldDivider) / newDivider)
                    : null,
        } as const;

        filters.replace(new RangeFilter({ attribute: FilterValues.price.attribute, value }));
    }
};

/**
 * Add category filter if none is set.
 */
const processCategoryFilter = (
    filters: FilterCollection,
    getState: () => GlobalState,
    defaultCategory: string,
) => {
    let categoryFilter = filters.getFilter<ObjectExactFilter<CategoryNodeData>>(
        FilterValues.category.attribute,
    );
    if (!categoryFilter?.value) {
        const i18n = selectI18n(getState());

        categoryFilter = new ObjectExactFilter({
            attribute: FilterValues.category.attribute,
            value: FilterValues.category.choices(i18n).find((cat) => cat.slug === defaultCategory),
        });
    }

    filters.replace(categoryFilter);
};

const processSaleTypeFilter = (filters: FilterCollection) => {
    const saleTypeFilter = filters.getFilter(FilterValues.saleType.attribute);

    const completionStatus = filters.getFilterValue(FilterValues.completionStatus.attribute);
    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);
    const listingType = filters.getFilterValue(FilterValues.type.attribute);

    if (
        completionStatus !== PropertyCompletionStatus.OFF_PLAN ||
        listingType === PropertyType.PROJECT
    ) {
        saleTypeFilter?.disable();
    } else {
        saleTypeFilter?.enable();
    }

    if (saleTypeFilter?.value === FilterValues.saleType.default || purpose !== Purpose.FOR_SALE) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.saleType.attribute,
                value: null,
            }),
        );
    }
};

/*
 * Add hidePrice filter if priceFilter is set
 */
const processHidePriceFilter = (filters: FilterCollection) => {
    const priceFilter = filters.getFilterValue<RangeFilterValue>(FilterValues.price.attribute);
    if (priceFilter?.min || priceFilter?.max) {
        filters.replace(
            new ExactFilter({ attribute: FilterValues.hidePrice.attribute, value: 'false' }),
        );
        return;
    }

    filters.remove(FilterValues.hidePrice.attribute);
};

/**
 * Remove hasTransactionHistory filter if the location is not the root location
 */

const processHasTransactionHistoryFilter = (filters: FilterCollection) => {
    const location = filters.getFilterValue<LocationNodeData>(FilterValues.location.attribute);
    const hasTransactionHistoryFilter = filters.getFilterValue(
        FilterValues.hasTransactionHistory.attribute,
    );
    const rootLocation = rootLocationNode('en');

    // location is null when first accessing the search page
    if (!location) {
        return;
    }

    const removeHasTransactionHistoryFilter =
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        location?.length !== 0 &&
        hasTransactionHistoryFilter &&
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        location[0]?.externalID !== rootLocation.externalID &&
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        location[0]?.hierarchy[1]?.externalID !== rootLocation.externalID;

    if (removeHasTransactionHistoryFilter) {
        filters.remove(FilterValues.hasTransactionHistory.attribute);
    }
};

const clearRealEstateAgenciesAndAgentsFilters = (
    filter: Filter,
    filterCollection: FilterCollection,
) => {
    const filterValues = filter.value as [AgencyData & AgentData];
    if (filterValues.some((filter) => filter?.type !== AgencyType.REAL_ESTATE_DEVELOPER)) {
        filterCollection.remove(filter.key);
    } else {
        filter.enable();
    }
};

const clearDeveloperAgenciesFilters = (filter: Filter, filterCollection: FilterCollection) => {
    const filterValues = filter.value as [AgencyData];
    if (filterValues.some((filter) => filter?.type === AgencyType.REAL_ESTATE_DEVELOPER)) {
        filterCollection.remove(filter.key);
    } else {
        filter.enable();
    }
};

const processAgencyAndAgentFilter = (filters: FilterCollection) => {
    const agencyOrAgentFilters = [
        filters.getFilter(FilterValues.agency.attribute),
        filters.getFilter(FilterValues.agencySlug.attribute),
        filters.getFilter(FilterValues.agentSlug.attribute),
        filters.getFilter(FilterValues.agentOwner.attribute),
    ];

    const isProjectSearchPage = determineIsProjectSearchActive(filters);

    agencyOrAgentFilters.forEach((filter) => {
        if (!filter) {
            return;
        }

        // this condition ensures that filters are not deleted before the IDs are replaced with their actual values.
        if (
            (filter.value as []).every((value) => typeof value === 'string') &&
            CONFIG.build.STRAT_ENABLE_PROJECTS_DEVELOPER_FILTER
        ) {
            return;
        }

        if (isProjectSearchPage) {
            CONFIG.build.STRAT_ENABLE_PROJECTS_DEVELOPER_FILTER
                ? clearRealEstateAgenciesAndAgentsFilters(filter, filters)
                : filter.disable();
        } else {
            CONFIG.build.STRAT_ENABLE_PROJECTS_DEVELOPER_FILTER
                ? clearDeveloperAgenciesFilters(filter, filters)
                : filter.enable();
        }
    });
};

const processAgencyExclusionFilter = (filters: FilterCollection) => {
    const agencyOrAgentFilterValues = [
        filters.getFilterValue(FilterValues.agency.attribute),
        filters.getFilterValue(FilterValues.agencySlug.attribute),
        filters.getFilterValue(FilterValues.agentSlug.attribute),
        filters.getFilterValue(FilterValues.agentOwner.attribute),
    ];

    const agencyExclusionFilter = filters.getFilter(FilterValues.excludeAgencies.attribute);
    if (!agencyExclusionFilter) {
        return;
    }

    if (
        agencyOrAgentFilterValues.some(
            (filterValue) =>
                (filterValue as Array<Partial<AgentData> | Partial<AgencyData> | string> | null)
                    ?.length,
        )
    ) {
        agencyExclusionFilter.disable();
    } else {
        agencyExclusionFilter.enable();
    }
};

const processKeywordFilter = (filters: FilterCollection) => {
    const keywordFilter = filters.getFilter(FilterValues.keywords.attribute);

    const listingType = filters.getFilterValue(FilterValues.type.attribute);

    if (listingType === PropertyType.PROJECT) {
        keywordFilter?.disable();
    } else {
        keywordFilter?.enable();
    }
};

const processAmenitiesFilter = (filters: FilterCollection) => {
    const amenitiesFilter = filters.getFilter(FilterValues.amenities.attribute);

    const listingType = filters.getFilterValue(FilterValues.type.attribute);

    if (listingType === PropertyType.PROJECT) {
        amenitiesFilter?.disable();
    } else {
        amenitiesFilter?.enable();
    }
};

const processPageFilter = (filters: FilterCollection) => {
    const pageFilter = filters.getFilter<PageFilter>(FilterValues.page.attribute);

    // We don't need a filter for the first page. It's implied. We are
    // removing it so that filter collections created in different places
    // that might add a implied page filter are still considered equal.
    if (!pageFilter?.value || (pageFilter?.value && pageFilter?.value <= 1)) {
        filters.remove(FilterValues.page.attribute);
    }
};

/**
 * Filter processor, processes new filters as they are being applied.
 *
 * - Reset purpose filter to default value if it has invalid value
 * - Set default rent frequency if purpose is for rent.
 * - Disables rent frequency filter when purpose is for sale.
 * - Disables completion status filter when purpose is for rent.
 * - Disables residence type filter when category is not residential.
 * - Set default completion status if purpose is for sale.
 * - Set default purpose if none is set.
 * - Reset price filter if the purpose changes
 * - Update price if rent frequency changes
 * - Handle "0 beds" to "Studio" conversion
 * - Remove product and agency tier filters if any filter except page changes
 * - Add category filter if none is set.
 */
const filterProcessor = (
    filters: FilterCollection,
    dispatch?: AppDispatch | null,
    getState?: (() => GlobalState) | null,
): FilterCollection => {
    // Each step in the following set of steps directly modify the filter collection
    // and hence their order is important. Any change in this order should be done with care.

    if (filters) {
        processPurposeFilter(filters);
        processRentFrequencyFilter(filters);
        processCompletionStatusFilter(filters);
        processBedsFilter(filters);
        processOccupancyStatusFilter(filters);
        processFurnishingStatusFilter(filters);
        processSaleTypeFilter(filters);
        processPreHandoverPaymentFilter(filters);
        processCompletionPercentageFilter(filters);
        processHandoverDateFilter(filters);
        processKeywordFilter(filters);
        processAmenitiesFilter(filters);
        processPageFilter(filters);
        processAgencyExclusionFilter(filters);
        processAgencyAndAgentFilter(filters);
    }

    if (filters && getState) {
        processPriceFilter(filters, getState);
        // @ts-ignore
        processCategoryFilter(filters, getState, Category.RESIDENTIAL);
        processResidenceTypeFilter(filters, getState);
        processHidePriceFilter(filters);
        if (settings.enableQuickFilters) {
            processHasTransactionHistoryFilter(filters);
        }
    }

    if (dispatch && getState && getState().algolia.settings.enabled) {
        dispatch(showSearchResults());
    }

    return filters;
};

export { processCategoryFilter };
export default filterProcessor;
