import * as React from 'react';

import styles from './styles/priceContainer.cssm';

type Props = {
    currency: React.ReactNode;
    price: React.ReactNode;
    separator?: React.ReactNode;
    showCurrencyLast?: boolean;
    priceContainerClassName?: string;
};

/**
 * Displays price and currency based on the language
 * en -> USD 100.000
 * bn/ar -> 100.000 USD
 */
const PriceContainer = (props: Props) => (
    <div className={props.priceContainerClassName || styles.priceContainer}>
        {!props.showCurrencyLast && (
            <>
                {props.currency}
                <span className={styles.separator} />
            </>
        )}
        {props.price}
        {props.showCurrencyLast && (
            <>
                <span className={styles.separator} />
                {props.currency}
            </>
        )}
    </div>
);

export default PriceContainer;
