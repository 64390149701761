import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';

import { PaymentSource } from '../payment/types';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type ProviderCashRouteParams = {
    readonly referenceNumber: string;
    readonly cashPaymentSource: Values<typeof PaymentSource>;
};

class ProviderCashPaymentRoute extends Route {
    constructor() {
        super(RouteNames.PROVIDER_CASH_PAYMENT, [
            [
                '^/cashPayments/',
                {
                    name: 'cashPaymentSource',
                    pattern: '([a-zA-z]+)',
                },
                '/referenceNumber/',
                {
                    name: 'referenceNumber',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { referenceNumber, cashPaymentSource }: ProviderCashRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/cashPayments/${cashPaymentSource}/referenceNumber/${referenceNumber}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { cashPaymentSource, referenceNumber },
            },
        } = context;

        context.rendering.renderPage(Page.PROVIDER_CASH_PAYMENT, {
            referenceNumber,
            cashPaymentSource,
        });
    }
}

export default new ProviderCashPaymentRoute();
