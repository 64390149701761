import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';

import { useFormattedPrice } from 'horizontal/util';

import styles from './styles/priceLabel.cssm';

type Props = {
    readonly price: number;
    readonly gray?: boolean;
};

const PriceLabel = ({ price, gray }: Props) => {
    const formattedPrice = useFormattedPrice(price);
    const className = classNames(styles.price, { [styles.gray]: gray });
    return (
        <Text.Base bold className={className}>
            {formattedPrice}
        </Text.Base>
    );
};

export default PriceLabel;
