import * as React from 'react';

import { type ProjectData } from 'strat/project/types';
import { FloorPlanType, PropertyFloorPlanImageData } from 'strat/property/types';
import EMPTY_ARRAY from 'strat/empty/array';

const useProjectMasterPlans = (
    project: ProjectData | null,
): readonly PropertyFloorPlanImageData[] =>
    React.useMemo(() => {
        if (!project || !project.floorPlans || !project.floorPlans.length) {
            return EMPTY_ARRAY;
        }

        return project?.floorPlans.filter(
            (floorPlan) => floorPlan.type === FloorPlanType.MASTER_PLAN,
        );
    }, [project]);

export default useProjectMasterPlans;
