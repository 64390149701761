// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { selectLanguage } from 'strat/i18n/language';

import { fetchCategories } from 'horizontal/categories/state';

import { MatchParams } from './types';
import fetchAndStoreFeaturedAgencies from './fetchAndStoreFeaturedAgencies';
import fetchAndStoreCategoryFields from './fetchAndStoreCategoryFields';
import fetchAndStoreFrequentSearchQueries from './fetchAndStoreFrequentSearchQueries';

const preFetchDataForSearchPageRendering = (
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch,
    getState: GetState,
    { categorySlug, categoryExternalID }: MatchParams,
): Promise<[void, void, void]> => {
    const matchParams = { categorySlug, categoryExternalID };
    const language = selectLanguage(getState());
    return Promise.all([
        dispatch(fetchCategories()).then(() =>
            fetchAndStoreFeaturedAgencies(dispatch, getState, matchParams),
        ),
        fetchAndStoreCategoryFields(dispatch, matchParams),
        fetchAndStoreFrequentSearchQueries(dispatch, matchParams, language),
    ]);
};

export default preFetchDataForSearchPageRendering;
