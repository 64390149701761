import React from 'react';
import { useSelector } from 'react-redux';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { selectCategories } from 'horizontal/categories/selectors';
import { findDeepList } from 'horizontal/util/findDeep';

import brandingSettings from 'olx-pk/branding/settings';

import CategoryTypeChecker from '../../branding/category';

const useRestrictLocationLevel = (hierarchySize: number) => {
    const activeCategory = useCategoryFilterValue();

    const categories = useSelector(selectCategories);
    const categoryHierarchy = React.useMemo(
        () => findDeepList(categories, (cat) => cat.id === activeCategory?.id, true),
        [categories, activeCategory],
    );

    return React.useMemo(() => {
        const topLevelIncomingCategory = categoryHierarchy[0];
        const isProperty =
            // @ts-ignore
            activeCategory && CategoryTypeChecker.isOfPropertyType(topLevelIncomingCategory);
        if (
            !isProperty &&
            brandingSettings.headerMaxLocationLevel &&
            hierarchySize > brandingSettings.headerMaxLocationLevel
        ) {
            return brandingSettings.headerMaxLocationLevel + 1;
        }
        return hierarchySize;
    }, [activeCategory, hierarchySize, categoryHierarchy]);
};

export default useRestrictLocationLevel;
