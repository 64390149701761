import * as React from 'react';
import { Flex } from 'strat/components';

import ProductPurchasesStatusNavBar from './productPurchasesStatusNavBar';
import ProductPurchasesList from './productPurchasesList';
import styles from './styles/productPurchasesSheet.cssm';

const ProductPurchasesSheet = () => (
    <Flex column stretchWidth>
        <ProductPurchasesStatusNavBar className={styles.menu} />
        <Flex className={styles.ordersList}>
            {/* @ts-expect-error - TS2786 - 'ProductPurchasesList' cannot be used as a JSX component. */}
            <ProductPurchasesList />
        </Flex>
    </Flex>
);

export default ProductPurchasesSheet;
