import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { FilterChipsComponent } from 'strat/searchComponents/compact';
import IconChecked from '@app/assets/icons/iconCheck.svg';
import { Text } from 'strat/components';

import { bedsFilterValues, bathsFilterValues } from '../constants';

import styles from './styles/verticalRangeFilter.cssm';
import DropdownWithButtons from './dropdrownWithButtons';
import formatOptionsDisplayValue from './formatOptionsDisplayValue';

type Props = {
    readonly title: string;
    readonly titleCustomClass?: string;
    readonly beds: Array<number | string>;
    readonly baths: Array<number | string>;
    readonly setFieldValue: (field: string, value?: any, shouldValidate?: boolean) => void;
    readonly onReset: () => void;
};

const BedsAndBathsFilter = ({
    title,
    setFieldValue,
    beds,
    baths,
    onReset,
    titleCustomClass = styles.label,
}: Props) => {
    const i18n = useI18n();
    const valueText = `${formatOptionsDisplayValue(
        beds,
    )} ${t(i18n)`Beds`} / ${formatOptionsDisplayValue(baths)} ${t(i18n)`Baths`} `;
    const placeholder = (
        <Text.Base
            className={styles.placeholder}
        >{`${t(i18n)`Beds`} / ${t(i18n)`Bathrooms`}`}</Text.Base>
    );

    const renderValue =
        beds.length + baths.length !== 0
            ? () => <Text.Base className={styles.valueText}>{valueText}</Text.Base>
            : undefined;

    const refine = (
        refineValue: any,
        currentRefinement: Array<number | string>,
        attribute: string,
    ) => {
        return new Promise<void>((resolve) => {
            const [newValue] = refineValue;
            if (currentRefinement.includes(newValue)) {
                setFieldValue(
                    attribute,
                    currentRefinement.filter((element) => element !== newValue),
                );
            } else {
                setFieldValue(attribute, [...currentRefinement, newValue]);
            }
            resolve();
        });
    };

    return (
        <DropdownWithButtons
            title={title}
            titleClassname={titleCustomClass}
            placeholder={placeholder}
            renderValue={renderValue}
            onResetClick={onReset}
        >
            <>
                <div className={styles.title}>{t(i18n)`Beds`}</div>
                <FilterChipsComponent
                    filter={bedsFilterValues}
                    currentRefinement={beds}
                    refine={(value) => refine(value, beds, bedsFilterValues.attribute)}
                    filterClassName={styles.filterContainer}
                    chipClassName={styles.chipItem}
                    selectedChipClassName={styles.selected}
                    icon={<IconChecked className={styles.checked} />}
                    clearOnRefine={false}
                    ariaLabel="Beds filter"
                />
                <div className={styles.title}>{t(i18n)`Baths`}</div>
                <FilterChipsComponent
                    currentRefinement={baths}
                    refine={(value) => refine(value, baths, bathsFilterValues.attribute)}
                    filter={bathsFilterValues}
                    filterClassName={styles.filterContainer}
                    chipClassName={styles.chipItem}
                    selectedChipClassName={styles.selected}
                    icon={<IconChecked className={styles.checked} />}
                    clearOnRefine={false}
                    ariaLabel="Baths filter"
                />
            </>
        </DropdownWithButtons>
    );
};
export default BedsAndBathsFilter;
