import { useSelector } from 'react-redux';
import { selectLanguage, useI18n } from 'strat/i18n/language';

import type { ProductPurchase } from 'horizontal/types';

import getProductPurchaseDetails from './getProductPurchaseDetails';
import type { UseProductPurchaseDetails } from './getProductPurchaseDetails';

const useProductPurchaseDetails = (productPurchase: ProductPurchase): UseProductPurchaseDetails => {
    const language = useSelector(selectLanguage);
    const i18n = useI18n();
    return getProductPurchaseDetails(productPurchase, i18n, language);
};

export default useProductPurchaseDetails;
