import React from 'react';
import { ReactNode } from 'react';

import styles from './styles/tabSwitchButton.cssm';

type Props = {
    onClick: () => void;
    title?: ReactNode;
    Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    className?: string;
};

const TabSwitchButton = ({ onClick, title, Icon, className }: Props) => (
    <div onClick={onClick} className={className}>
        {Icon && <Icon className={styles.tabIcon} />}
        {title}
    </div>
);

export default TabSwitchButton;
