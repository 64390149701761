import * as React from 'react';
import { useSelector } from 'react-redux';
import CategoryTypeChecker from '@app/branding/category';
import { useI18n } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';
import { Text } from 'strat/components';

import type { Location } from 'horizontal/types';
import { useCategoryFilterValue } from 'horizontal/search/category';
import useRecentLocations from 'horizontal/fields/useRecentLocations';
import { selectCategories } from 'horizontal/categories';
import { filterDisabledLocations, useDisabledLocations } from 'horizontal/search/location';

import styles from './styles/locationList.cssm';
import LocationsListing from './locationsListing';

type Props = {
    title?: string;
    locations: Location[];
    onLocationClicked: (arg1: Location) => void;
    selectedIndex?: number;
    withArrows?: boolean;
};

const RecentLocationsSection = ({
    onLocationClicked,
    withArrows,
    selectedIndex,
}: {
    onLocationClicked: (arg1: Location) => void;
    withArrows?: boolean;
    selectedIndex?: number;
}) => {
    const i18n = useI18n();
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);
    const isProperty = CategoryTypeChecker.isOfPropertyType(activeCategory);
    const recentLocations = useRecentLocations(Infinity, i18n)
        // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type. | TS2339 - Property 'headerMaxLocationLevel' does not exist on type 'HorizontalSettings'.
        .filter((loc) => isProperty || loc.level <= brandingSettings.headerMaxLocationLevel)
        .slice(0, 5);

    const filteredRecentLocations = recentLocations.length
        ? filterDisabledLocations(recentLocations, categories, activeCategory)
        : [];

    if (!filteredRecentLocations.length) {
        return null;
    }

    return (
        <>
            <div className={styles.title}>
                <Text.Small uppercase>recent locations</Text.Small>
            </div>
            <LocationsListing
                locations={filteredRecentLocations}
                onLocationClicked={onLocationClicked}
                withArrows={withArrows}
                selectedIndex={selectedIndex}
            />
        </>
    );
};

const LocationList = ({
    title,
    locations,
    onLocationClicked,
    selectedIndex,
    withArrows,
}: Props) => {
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);
    const filteredLocations = useDisabledLocations(locations, categories, activeCategory);

    if (!locations || locations.length === 0) {
        return null;
    }

    const parentLevelLocation = locations ? locations[0].level - 1 : 0;

    return (
        <>
            {parentLevelLocation === 0 && !!title && (
                <RecentLocationsSection
                    onLocationClicked={onLocationClicked}
                    withArrows={withArrows}
                    selectedIndex={selectedIndex}
                />
            )}
            <div className={styles.title}>
                <Text.Small uppercase>{title || 'all locations'}</Text.Small>
            </div>
            <LocationsListing
                locations={filteredLocations}
                onLocationClicked={onLocationClicked}
                withArrows={withArrows}
                selectedIndex={selectedIndex}
            />
        </>
    );
};

export default LocationList;
