import isEqual from 'lodash/isEqual';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { FilterValues } from 'strat/search';
import Category from 'strat/branding/category';

export const hasProjectAdsFiltersActive = (filters: FilterCollection): boolean =>
    !isEqual(
        filters.getFilterValue(FilterValues.category.attribute, FilterValues.category.default)
            ?.slug,
        FilterValues.category.default?.slug || Category.RESIDENTIAL,
    ) ||
    filters.getFilterValue(FilterValues.beds.attribute) ||
    filters.getFilterValue(FilterValues.baths.attribute) ||
    !isEqual(
        filters.getFilterValue(FilterValues.area.attribute, FilterValues.area.default),
        FilterValues.area.default,
    ) ||
    !isEqual(
        filters.getFilterValue(FilterValues.price.attribute, FilterValues.price.default),
        FilterValues.price.default,
    ) ||
    !isEqual(
        filters.getFilterValue(
            FilterValues.handoverDate.attribute,
            FilterValues.handoverDate.default,
        ),
        FilterValues.handoverDate.default,
    ) ||
    !isEqual(
        filters.getFilterValue(
            FilterValues.completionPercentage.attribute,
            FilterValues.completionPercentage.default,
        ),
        FilterValues.completionPercentage.default,
    ) ||
    !isEqual(
        filters.getFilterValue(
            FilterValues.preHandoverPayment.attribute,
            FilterValues.preHandoverPayment.default,
        ),
        FilterValues.preHandoverPayment.default,
    );
