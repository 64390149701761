import * as React from 'react';
import classNames from 'classnames';
import { StylelessButton } from 'strat/generic';
import { Text } from 'strat/components';

import type { FreeTextSuggestion } from 'horizontal/types';

import styles from './styles/freeTextSuggestionList.cssm';

type Props = {
    readonly suggestions: Array<FreeTextSuggestion>;
    readonly selectedIndex?: number | null | undefined;
    readonly onSuggestionClicked?: (arg1: FreeTextSuggestion) => void;
};

const FreeTextSuggestionList = ({
    suggestions,
    selectedIndex,
    onSuggestionClicked,
}: Props): React.ReactElement =>
    // @ts-expect-error - TS2739 - Type 'Element[]' is missing the following properties from type 'ReactElement<any, string | JSXElementConstructor<any>>': type, props, key
    suggestions.map((suggestion, index) => (
        <StylelessButton
            aria-label={`Suggestion: ${suggestion.searchParams.freeTextQuery}`}
            className={classNames(styles.suggestion, {
                [styles.selected]: index === selectedIndex,
            })}
            key={suggestion.searchParams.freeTextQuery + index.toString()}
            onClick={() => {
                if (onSuggestionClicked) {
                    onSuggestionClicked(suggestion);
                }
            }}
        >
            <Text.Base className={styles.text} isDangerousHTML>
                {suggestion.highlightedQuery}
            </Text.Base>
        </StylelessButton>
    ));

export default FreeTextSuggestionList;
