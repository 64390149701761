import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { stopEventPropagation } from 'strat/util';
import { Button, Flex, Text, LoadingSpinner } from 'strat/components';
import type { ThirdPartyProvider } from 'strat/auth/keycloak/types';

import type { AvailableSocialLoginProvider } from './types';
import styles from './styles/socialLoginItem.cssm';

type Props = {
    readonly provider: AvailableSocialLoginProvider;
    readonly connected: boolean;
    readonly loading: boolean;
    readonly onDisconnect: (arg1: ThirdPartyProvider) => void;
    readonly onConnect: (arg1: ThirdPartyProvider) => void;
};

const SocialLoginItem = ({ provider, connected, loading, onConnect, onDisconnect }: Props) => {
    const i18n = useI18n();

    const renderButtonContent = () => {
        if (loading) {
            return <LoadingSpinner dark />;
        }
        if (connected) {
            return t(i18n)`Disconnect`;
        }
        return t(i18n)`Connect`;
    };

    const onClick = (event: React.SyntheticEvent<any>) => {
        stopEventPropagation(event);
        if (loading) {
            return;
        }
        if (connected) {
            onDisconnect(provider.type);
            return;
        }
        onConnect(provider.type);
    };

    return (
        <Flex className={styles.socialLoginItem}>
            <Flex column className={styles.container}>
                <Text.Regular>{provider.title}</Text.Regular>
                <Text.Base className={styles.description}>{provider.description}</Text.Base>
            </Flex>
            <div className={styles.buttonContainer}>
                <Button
                    secondary
                    stretch
                    onClick={onClick}
                    className={classNames(styles.button, {
                        [styles.disabled]: loading,
                    })}
                >
                    {renderButtonContent()}
                </Button>
            </div>
        </Flex>
    );
};

export default SocialLoginItem;
