import * as React from 'react';
import Layout from 'strat/layout';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';

import AdUtils from 'horizontal/ad/utils';
import { MixedAd } from 'horizontal/types';
import type { AdFormattedExtraField } from 'horizontal/ad';

import styles from './styles/highlightedDetails.cssm';

type HighlightItemProps = {
    label: string;
    value: string;
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | null;
};

const HighlightItem = ({ label, value, icon: Icon }: HighlightItemProps) => {
    const layout = useSelector(selectLayout);
    const isDesktop = layout === Layout.DESKTOP;

    return (
        <Flex column={isDesktop} alignCenter={isDesktop} className={styles.container}>
            {Icon && <Icon className={styles.icon} />}
            <Flex column alignCenter={isDesktop} className={styles.details}>
                <Text.Regular className={styles.label}>{label}</Text.Regular>
                <Text.Regular bold>{value}</Text.Regular>
            </Flex>
        </Flex>
    );
};

type Props = {
    readonly fields: Array<AdFormattedExtraField>;
    readonly ad: MixedAd | null | undefined;
};

const HighlightedDetails = ({ fields, ad }: Props) => {
    if (!ad) {
        return null;
    }

    return (
        <>
            {fields.map(({ name, formattedValue, attribute }: AdFormattedExtraField) => (
                <HighlightItem
                    key={attribute}
                    label={name}
                    value={typeof formattedValue === 'string' ? formattedValue : ''}
                    icon={AdUtils.getHighlightedFieldIcon(attribute, ad)}
                />
            ))}
        </>
    );
};

export default HighlightedDetails;
