import {
    type FilterCollection,
    RefinementFilter,
    ObjectRefinementFilter,
    Filter,
} from '@sector-labs/fe-search-redux/filters';

import FilterValues from 'strat/search/filterValues';
import type { LocationNodeData } from 'strat/property/types';

const extractProjectPageLocationSlugs = (
    adFilters?: FilterCollection | null,
    locationFilters?: FilterCollection | null,
): string[] => {
    if (locationFilters) {
        const locationSlugFilter = locationFilters.getFilter<RefinementFilter<string>>('slug');
        if (locationSlugFilter && locationSlugFilter.value?.length) {
            return locationSlugFilter.value || [];
        }
    }

    if (!adFilters) {
        return [];
    }

    const adLocationFilter = adFilters.getFilter(FilterValues.location.attribute);
    if (!adLocationFilter) {
        return [];
    }

    const adLocationFilterTypeName = (adLocationFilter.constructor as typeof Filter).TYPE_NAME;

    switch (adLocationFilterTypeName) {
        case ObjectRefinementFilter.TYPE_NAME:
            return (adLocationFilter as ObjectRefinementFilter<LocationNodeData>).value?.map(
                (location) => location.slug,
            );

        default:
        case RefinementFilter.TYPE_NAME:
            return (adLocationFilter as RefinementFilter<string>).value || [];
    }

    return [];
};

export default extractProjectPageLocationSlugs;
