import { FetcherFactoryWithContext } from 'strat/fetcher';
import { InternalAPI } from 'strat/api';

export type FetchMetricsParams = {
    readonly agencyExternalIDs: Array<string>;
    readonly startTimestamp: number;
    readonly endTimestamp: number;
};

/**
 * Fetcher factory for fetching agency metrics.
 */
const factory = new FetcherFactoryWithContext<FetchMetricsParams>(
    'agencyMetrics',
    ({ params }) => new InternalAPI().agenciesMetrics(params),
    { skipParamsCheck: true },
);

/**
 * Action creator for fetching ad metrics from Ovation
 */
const fetchAgencyMetrics = factory.creator();

const agencyMetricsReducer = factory.reducer();

export { fetchAgencyMetrics };

export default agencyMetricsReducer;
