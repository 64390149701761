import thumbnailURL from 'strat/image/thumbnailURL';
import { tempUserImageURL, ThumbnailSizes, ThumbnailSizeValues } from 'strat/image';
import type { KeycloakUser } from 'strat/auth/keycloak/user';

const getImageSource = (user?: KeycloakUser | null) => {
    if (!CONFIG.runtime.OLX_ENABLE_USER_IMAGE_EDIT || !user || !user?.profileImageID) {
        return user?.image || null;
    }

    const tempURL = tempUserImageURL(user.profileImageID);
    if (!tempURL) {
        return thumbnailURL(user.profileImageID, 'jpeg', ThumbnailSizeValues[ThumbnailSizes.SMALL]);
    }
    return tempURL;
};

export default getImageSource;
