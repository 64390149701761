import * as React from 'react';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';

import type { ProductPackageOffer, FullAd } from 'horizontal/types';
import { getAdVars, getLocationVars } from 'horizontal/gtm/utils';
import { useLocationFilterValue } from 'horizontal/search/location';

const trackBusinessPackagesClick = (viewSection: Values<typeof ViewSections> = ViewSections.BODY) =>
    trigger(Triggers.CLICK_BUY_PACKAGES, {
        view_section: viewSection,
    });

const trackViewExampleClick = () =>
    trigger(Triggers.CLICK_EXAMPLE, {
        view_section: ViewSections.BODY,
    });

const trackSelectPackage = ({
    selectedPackage,
    categoryVars = {},
}: {
    selectedPackage: ProductPackageOffer;
    categoryVars?: { [key: string]: string };
}) =>
    trigger(Triggers.SELECT_PACKAGE, {
        product_id: selectedPackage.packageOfferID,
        payment_amount: selectedPackage.price,
        view_section: ViewSections.BODY,
        ...categoryVars,
    });

const trackAbortPackageSelect = (
    productIDs?: string[],
    price?: number | null,
    orderExternalID?: string | null,
    userExternalID?: string | null,
) => {
    trigger(Triggers.ABORT_PACKAGE_SELECT, {
        product_id: productIDs,
        payment_amount: price,
        order_id: orderExternalID,
        user_id: userExternalID,
    });
};

const trackAbortCart = (
    productIDs?: string[],
    price?: number | null,
    orderExternalID?: string | null,
    userExternalID?: string | null,
) => {
    trigger(Triggers.ABORT_CART, {
        product_id: productIDs,
        payment_amount: price,
        user_id: userExternalID,
        order_id: orderExternalID,
    });
};

const useTrackPaymentAbort = () => {
    const location = useLocationFilterValue() || settings.topLevelLocation;

    return React.useCallback(
        (triggerData: any) => {
            trigger(Triggers.PAYMENT_ABORT, {
                ...triggerData,
                // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData[] | LiteHierarchicalLocation[] | undefined' is not assignable to parameter of type 'LiteHierarchicalLocation[]'.
                ...getLocationVars(location.hierarchy),
            });
        },
        [location],
    );
};

type TrackPayPackageParams = {
    readonly productIDs?: string[];
    readonly price?: number | null;
    readonly ad?: FullAd | null;
    readonly userExternalId?: string | null;
    readonly categoryVars?: { [key: string]: string };
};

const trackPayPackage = ({
    productIDs,
    price,
    ad,
    userExternalId,
    categoryVars = {},
}: TrackPayPackageParams) => {
    const adVars = ad
        ? { ...getAdVars(ad), number_of_photos: ad?.photos?.length, user_id: userExternalId }
        : {};
    trigger(Triggers.PAY_PACKAGE, {
        product_id: productIDs,
        payment_amount: price,
        view_section: ViewSections.BODY,
        ...categoryVars,
        ...adVars,
    });
};

const trackViewCart = ({
    userExternalId,
    categoryVars = {},
}: {
    userExternalId?: string | null;
    categoryVars?: { [key: string]: string };
}) => trigger(Triggers.VIEW_CART, { user_id: userExternalId, ...categoryVars });

export {
    trackBusinessPackagesClick,
    trackViewExampleClick,
    trackSelectPackage,
    trackPayPackage,
    trackViewCart,
    trackAbortPackageSelect,
    trackAbortCart,
    useTrackPaymentAbort,
};
