import * as React from 'react';
import classNames from 'classnames';
import languageSwitcherStyles from 'strat/settings/styles/languageSwitcher.cssm';
import { PageWidthWrapper } from 'strat/generic';
import { LanguageSwitcher } from 'strat/settings';
import settings from '@app/branding/settings';
import Logo from '@app/branding/logo';
import { Flex } from 'strat/components';

import SellButton from '@app/navigation/sellButton';
import { UserCenter } from 'horizontal/user';
import HeaderLinks from 'horizontal/header/headerLinks';

import styles from './styles/headerWithoutFilters.cssm';

const HeaderWithoutFilters = () => (
    <div className={styles.container}>
        <PageWidthWrapper className={styles.content}>
            <Flex alignCenter>
                <HeaderLinks
                    withHighlightedVertical
                    renderLogo={(defaultClassname: string) => (
                        <Logo
                            className={defaultClassname}
                            small={settings.headerWithoutFiltersSmallLogo}
                        />
                    )}
                />
            </Flex>

            <Flex>
                <LanguageSwitcher
                    svgClassName={classNames(
                        languageSwitcherStyles.darkFill,
                        styles.languageSwitcher,
                    )}
                />
                <Flex alignCenter className={styles.userCenter}>
                    <UserCenter />
                </Flex>
                <div className={styles.sellButton}>
                    <SellButton />
                </div>
            </Flex>
        </PageWidthWrapper>
    </div>
);

export default HeaderWithoutFilters;
