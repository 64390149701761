import * as React from 'react';
import classNames from 'classnames';

import Flex from './flex';
import styles from './styles/messageBox.cssm';

export const MessageBoxType = Object.freeze({
    ACCEPTED: 'accepted',
    NEUTRAL: 'neutral',
    ERROR: 'error',
});

export const ArrowDirection = Object.freeze({
    UP: 'up',
    DOWN: 'down',
});

export const ArrowPosition = Object.freeze({
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
});

type Props = {
    readonly renderTitle: () => string | React.ReactNode;
    readonly renderContent: () => string | React.ReactNode;
    readonly renderIcon?: () => React.ReactElement;
    readonly type: Values<typeof MessageBoxType>;
    readonly arrowDirection?: Values<typeof ArrowDirection>;
    readonly arrowPosition?: Values<typeof ArrowPosition>;
    readonly centeredText?: boolean;
};

const MessageBox = ({
    renderTitle,
    renderContent,
    renderIcon,
    type,
    centeredText,
    arrowDirection = ArrowDirection.UP,
    arrowPosition = ArrowPosition.LEFT,
}: Props) => {
    const className = {
        [styles.accepted]: type === MessageBoxType.ACCEPTED,
        [styles.neutral]: type === MessageBoxType.NEUTRAL,
        [styles.error]: type === MessageBoxType.ERROR,
    } as const;
    const arrowClassName = classNames(
        {
            [styles.upArrow]: arrowDirection === ArrowDirection.UP,
            [styles.downArrow]: arrowDirection === ArrowDirection.DOWN,
            [styles.left20]: arrowPosition === ArrowPosition.LEFT,
            [styles.left50]: arrowPosition === ArrowPosition.CENTER,
            [styles.left80]: arrowPosition === ArrowPosition.RIGHT,
        },
        className,
    );
    return (
        <Flex alignCenter className={classNames(styles.container, className)}>
            <div className={arrowClassName} />
            {renderIcon ? renderIcon() : null}
            <Flex column fillContainer alignCenter={centeredText}>
                <div className={styles.title}>{renderTitle()}</div>
                <div className={styles.content}>{renderContent()}</div>
            </Flex>
        </Flex>
    );
};

export default MessageBox;
