import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Button } from 'strat/components';

import { DeliveryType } from 'horizontal/types';

import { UserDeliveryOrderStatus } from '../types';
import {
    useDeliveryOrderConfirmDialogDetails,
    actionConfirmDialog as ConfirmActionDialog,
} from '../deliveryOrders';

import styles from './styles/sellingOrderCardExpansion.cssm';

type Props = {
    readonly changeOrderStatus: (offerId: number, offerStatus: string) => void;
    readonly orderStatus: string;
    readonly offerID: number;
    readonly deliveryType?: DeliveryType;
};

const SellingCardActionButtons = ({
    orderStatus,
    changeOrderStatus,
    offerID,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: Props) => {
    const i18n = useI18n();
    const [confirmActionDialog, setConfirmActionDialog] = React.useState(false);
    const [buttonAction, setButtonAction] = React.useState('');
    const dialogDetails = useDeliveryOrderConfirmDialogDetails(buttonAction, i18n);
    return (
        <Flex justifyCenter className={styles.sellingCardActions}>
            {confirmActionDialog && (
                <ConfirmActionDialog
                    dialogDetails={dialogDetails}
                    confirmAction={() => changeOrderStatus(offerID, buttonAction)}
                    cancelAction={() => {
                        setConfirmActionDialog(false);
                    }}
                />
            )}
            {orderStatus !== UserDeliveryOrderStatus.NEW && (
                <Button
                    onClick={() => {
                        setButtonAction(UserDeliveryOrderStatus.CANCELED_BY_SELLER);
                        setConfirmActionDialog(true);
                    }}
                    secondary
                >
                    {t(i18n)`Cancel order`}
                </Button>
            )}

            {CONFIG.build.ENABLE_SELF_DELIVERY &&
                orderStatus !== UserDeliveryOrderStatus.NEW &&
                deliveryType === DeliveryType.SELF_DELIVERY && (
                    <Button
                        onClick={() => {
                            setButtonAction(UserDeliveryOrderStatus.OUT_FOR_DELIVERY);
                            setConfirmActionDialog(true);
                        }}
                    >
                        {t(i18n)`Out for delivery`}
                    </Button>
                )}

            {orderStatus === UserDeliveryOrderStatus.NEW && (
                <Flex className={styles.mainActions}>
                    <Button
                        onClick={() => {
                            setButtonAction(UserDeliveryOrderStatus.REJECTED);
                            setConfirmActionDialog(true);
                        }}
                        secondary
                    >
                        {t(i18n)`Decline Order`}
                    </Button>
                    <Button
                        onClick={() => {
                            setButtonAction(UserDeliveryOrderStatus.ACCEPTED);
                            setConfirmActionDialog(true);
                        }}
                    >
                        {t(i18n)`Accept Order`}
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export default SellingCardActionButtons;
