import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import AdCardSubtitleCategories from '@app/branding/adCardSubtitleCategories';

import IconMileage from 'horizontal/assets/icons/iconMileage.svg';
import type { MixedAd } from 'horizontal/types';
import { BasicTag } from 'horizontal/adCard/tags';

import useFormatAdExtraFieldsToObject from './useFormatAdExtraFieldsToObject';
import styles from './styles/adSubtitle.cssm';

type Props = {
    readonly ad: MixedAd;
};

const AdCarSubtitle = ({ ad }: Props) => {
    const extraFields = useFormatAdExtraFieldsToObject(ad);

    // Field keys so we can fetch the proper value from the extraFields
    const fieldNames = AdCardSubtitleCategories?.VEHICLES.fields;
    // @ts-ignore
    const newUsedFieldName = AdCardSubtitleCategories?.VEHICLES?.optionalFields?.newUsed;
    const i18n = useI18n();

    if (!fieldNames) {
        return null;
    }

    const mileage =
        newUsedFieldName && extraFields[newUsedFieldName] === 'New'
            ? t(i18n)`NEW`
            : t(i18n)`${extraFields[fieldNames.mileage]} km`;
    const year = extraFields[fieldNames.year];

    return (
        <span className={styles.container}>
            <BasicTag
                text={mileage}
                renderIcon={(className) => <IconMileage className={className} />}
                ariaLabel={'Mileage'}
            />
            <span className={styles.separator}>&#8226;</span>
            <BasicTag text={year} ariaLabel={'Year'} withEllipsis />
        </span>
    );
};

export default AdCarSubtitle;
