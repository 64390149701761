import * as React from 'react';
import settings from '@app/branding/settings';
import { useSelector } from 'react-redux';

import EMPTY_ARRAY from 'strat/empty/array';
import { useI18n } from 'strat/i18n/language';
import { selectLoginType } from 'strat/auth/selectors';
import { LoginType } from 'strat/auth/types';
import Layout from 'strat/layout';
import { selectLayout } from 'strat/layout/selectors';
import * as jsonifiedLocalStorage from 'strat/misc/localStorage';

type UseLoginWelcome = {
    visible: boolean;
    message: readonly string[];
    dismiss: () => void;
};

const visibilityTimeoutSeconds = 8;
const storageKey = 'strat:auth:tracking:loginWelcomeDisplayed';

export const resetLoginWelcomeDisplayed = (): void => jsonifiedLocalStorage.removeItem(storageKey);

export const markLoginWelcomeAsDisplayed = (): void =>
    jsonifiedLocalStorage.setItem(storageKey, true);

const wasLoginWelcomeDisplayed = (): boolean => {
    const value = jsonifiedLocalStorage.getItem(storageKey);
    if (typeof value !== 'boolean') {
        return false;
    }

    return value;
};

const shouldDisplayLoginWelcome = ({
    layout,
    loginType,
}: {
    layout: Values<typeof Layout>;
    loginType: LoginType | null;
}): boolean => {
    if (wasLoginWelcomeDisplayed()) {
        return false;
    }

    // If you logged in outside of Strat, using the API or
    // through Laravel/Legacy/Profolio, you won't have a login
    // type and we don't have to notify you because you had
    // an entirely different login experience than the one
    // we use the welcome notification for.
    if (!loginType) {
        return false;
    }

    // On mobile we always display the notification because it isn't
    // immediately obvious to the user that the login was a success
    // because we hide the user profile etc in the side bar.
    if (layout === Layout.MOBILE) {
        return true;
    }

    // Add login methods for which users might not notice that they
    // have been logged in because of redirects, implicit flows etc.
    return [
        LoginType.EMAIL_IMPLICIT,
        LoginType.EMAIL_LINK,
        LoginType.GOOGLE_REDIRECT,
        LoginType.GOOGLE_POPUP,
        LoginType.FACEBOOK_REDIRECT,
        LoginType.FACEBOOK_POPUP,
        LoginType.UNKNOWN_POPUP,
        LoginType.UNKNOWN_REDIRECT,
    ].includes(loginType);
};

const useLoginWelcome = (): UseLoginWelcome => {
    const i18n = useI18n();

    const layout = useSelector(selectLayout);
    const loginType = useSelector(selectLoginType);

    const [visible, setVisible] = React.useState(false);

    const dismiss = React.useCallback(() => {
        setVisible(false);
        markLoginWelcomeAsDisplayed();
    }, []);

    React.useEffect(() => {
        const shouldBeVisible = shouldDisplayLoginWelcome({ layout, loginType });
        setVisible(shouldBeVisible);

        if (shouldBeVisible) {
            const timeoutID = setTimeout(dismiss, visibilityTimeoutSeconds * 1000);
            return () => clearTimeout(timeoutID);
        }
    }, [dismiss, layout, loginType]);

    const message = settings.postLoginNotificationMessage?.(i18n);
    if (!message || !message.length) {
        return { visible: false, message: EMPTY_ARRAY, dismiss };
    }

    return { visible, message, dismiss };
};

export default useLoginWelcome;
