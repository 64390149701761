import { useSelector } from 'react-redux';
import { selectLanguage } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { useLocationFilterValue } from 'horizontal/search/location';

import { locationParams } from './utils';

const useLocationParams = () => {
    const activeLocation = useLocationFilterValue() || settings.topLevelLocation;
    const language = useSelector(selectLanguage);
    // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData[] | LiteHierarchicalLocation[] | undefined' is not assignable to parameter of type 'LiteHierarchicalLocation[]'.
    return locationParams(language, activeLocation.hierarchy);
};

export default useLocationParams;
