import * as React from 'react';

import { useAdFormattedExtraFields } from 'horizontal/ad';
import type { MixedAd } from 'horizontal/types';
import type { AdFormattedExtraField } from 'horizontal/ad';

const useAdFeatures = (ad?: MixedAd | null): Array<AdFormattedExtraField> => {
    const formattedExtraFields = useAdFormattedExtraFields(ad);
    return React.useMemo(
        () =>
            formattedExtraFields.filter(
                (field) => !!field.name && Array.isArray(field.formattedValue),
            ),
        [formattedExtraFields],
    );
};

export default useAdFeatures;
