import * as React from 'react';
import { trigger } from 'strat/gtm';

const usePageViewTrigger = (event: string, variables: any, shouldNotTrigger = false) => {
    const [pageViewTriggered, setPageViewTriggered] = React.useState(false);

    React.useEffect(() => {
        setPageViewTriggered(false);
    }, [shouldNotTrigger]);

    React.useEffect(() => {
        if (shouldNotTrigger || pageViewTriggered) {
            return;
        }

        trigger(event, variables);
        setPageViewTriggered(true);
    }, [event, shouldNotTrigger, pageViewTriggered, variables]);
};

export { usePageViewTrigger };
