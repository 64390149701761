//@ts-nocheck
/**
 * Get the brand name for the current website instance
 *
 * As a general rule, the brand should match the domain
 * which means we can infer the brand from the domain name
 */
const getBrandName = () => (CONFIG.runtime.CANONICAL_DOMAIN || 'localhost').split('.').slice(-2)[0];

export default getBrandName;
