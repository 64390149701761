import { languages } from '@app/branding/languages';

/**
 * Returns true or false depending on whether the language is
 * written right-to-left or not.
 * @param lang the language code
 */
export default (lang: string): boolean =>
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    languages.find((configLang) => configLang.lang === lang).rtl === true;
