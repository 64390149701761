import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Flex, Input } from 'strat/components';
import useI18n from 'strat/i18n/language/useI18n';

import type { UserDTO } from 'horizontal/dtos';

import useUserSearch from './hooks/useUserSearch';
import styles from './styles/telesalesTool.cssm';
import TeleSalesTable from './teleSalesTable';

type Props = Readonly<{
    selectedUserExternalID: string | undefined;
    onUserSelectionChange: (user: UserDTO | undefined) => unknown;
    onError: (error: string | undefined) => unknown;
}>;

const UserSearchCard = ({ selectedUserExternalID, onUserSelectionChange, onError }: Props) => {
    const i18n = useI18n();
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const { users, fetchUsers } = useUserSearch(setLoading, onError);

    const handleUserSearchClick = () => {
        handleUserSelectionChange(undefined);
        fetchUsers(searchTerm);
    };

    const handleUserSelectionChange = React.useCallback(
        (userExternalID: string | undefined) => {
            let selectedUser: UserDTO | undefined;

            if (userExternalID) {
                selectedUser = users.find((user) => user.externalID == userExternalID);
            }

            onUserSelectionChange(selectedUser);
        },
        [users, onUserSelectionChange],
    );

    React.useEffect(() => {
        handleUserSelectionChange(undefined);
    }, [users, handleUserSelectionChange]);

    return (
        <Flex className={styles.cardElement} column>
            <Flex className={styles.searchForUser}>
                <Input
                    className={styles.searchInput}
                    title={<Trans>Search for user</Trans>}
                    onChange={setSearchTerm}
                    onSubmit={handleUserSearchClick}
                    value={searchTerm}
                />
                <Button onClick={handleUserSearchClick}>
                    <Trans>Search</Trans>
                </Button>
            </Flex>

            <TeleSalesTable
                loading={loading}
                setSelectedItem={handleUserSelectionChange}
                headers={[
                    { text: t(i18n)`User ID`, value: 'id' },
                    { text: t(i18n)`Name`, value: 'name' },
                    { text: t(i18n)`Phone No.`, value: 'phoneNumber' },
                    { text: t(i18n)`Email`, value: 'email' },
                ]}
                itemKey="externalID"
                radioInputName="user"
                items={users}
                selectedItem={selectedUserExternalID}
            />
        </Flex>
    );
};

export default React.memo(UserSearchCard);
