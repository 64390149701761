import * as React from 'react';
import { Trans } from '@lingui/macro';

import type { ProductPurchase } from '../types';
import { useProductPurchaseDetails } from '../userOrders/orders';

type Props = {
    productPurchase: ProductPurchase;
};

const ProductPurchaseSummary = ({ productPurchase }: Props) => {
    const productPurchaseDetails = useProductPurchaseDetails(productPurchase);

    return (
        <Trans>
            {productPurchaseDetails.available} Ads are left in <b>{productPurchaseDetails.name}</b>.
            Please use the package before {productPurchaseDetails.expiryDate}
        </Trans>
    );
};

export default ProductPurchaseSummary;
