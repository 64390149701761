import * as React from 'react';

import HeaderWithVerticals from './headerWithVerticals';
import SimpleHeader from './simpleHeader';

type Props = {
    readonly withLocationsDialog?: boolean;
    readonly withFreeTextSearchDialog?: boolean;
    readonly className?: string;
    readonly withHighlightedVertical?: boolean;
};

const Header = ({
    withLocationsDialog,
    withFreeTextSearchDialog,
    className,
    withHighlightedVertical,
}: Props) => {
    return CONFIG.runtime.ENABLE_OLX_VERTICALS ? (
        <HeaderWithVerticals
            withLocationsDialog={withLocationsDialog}
            withFreeTextSearchDialog={withFreeTextSearchDialog}
            className={className}
            withHighlightedVertical={withHighlightedVertical}
        />
    ) : (
        <SimpleHeader
            withLocationsDialog={withLocationsDialog}
            withFreeTextSearchDialog={withFreeTextSearchDialog}
        />
    );
};

export default Header;
