//@ts-nocheck
import { dtResidentialCategoriesIds, dtCommercialCategoriesIds } from './filterValues';
import { PropertyTypes } from './propertyTypes';

/**
 * Category types mapped to their corresponding slugs.
 * Has functions to check category type.
 */
const Category = {
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',

    ...PropertyTypes,

    isOfType: (category, type) =>
        category && (category === type || (category.slug && category.slug === type)),

    isOfLandType: () => false,

    isOfResidentialType: (category) => Category.isOfType(category, Category.RESIDENTIAL),

    isOfApartmentType: (category) => Category.isOfType(category, Category.APARTMENTS),

    isOfHotelApartmentType: (category) => Category.isOfType(category, Category.HOTEL_APARTMENTS),

    isOfVillasType: (category) => Category.isOfType(category, Category.VILLAS),

    isOfTownhousesType: (category) => Category.isOfType(category, Category.TOWNHOUSES),

    isOfVillaCompoundType: (category) => Category.isOfType(category, Category.VILLA_COMPOUND),

    isOfCommercialType: (category) => Category.isOfType(category, Category.COMMERCIAL),

    ifOfCommercialVillaType: (category) => Category.isOfType(category, Category.COMMERCIAL_VILLAS),

    isOfCommercialPropertyType: (category) =>
        Category.isOfType(category, Category.COMMERCIAL_PROPERTIES),

    isOfPlotType: () => false,

    isDTCategory: (category) => {
        if (Category.isOfType(category, Category.ANY)) {
            return true;
        }
        const isDTResidential = dtResidentialCategoriesIds.includes(category.externalID);
        const isDTCommercial = dtCommercialCategoriesIds.includes(category.externalID);
        return isDTResidential || isDTCommercial;
    },
} as const;

export default Category;
