import React from 'react';
import { Trans } from '@lingui/macro';

import { Flex } from 'strat/components';
import { formatDate, useI18n } from 'strat/i18n/language';
import { MonthlyProjectPaymentPlan } from 'strat/project/types';

import useMonthlyPaymentTimeline, {
    MonthlyPaymentPlanMilestone,
} from '../useMonthlyPaymentTimeline';

import { MilestoneAxisSegment } from './paymentPlanTable';
import styles from './styles/paymentPlanTable.cssm';

export const FORMATTER_OPTIONS = {
    year: 'numeric',
    month: 'short',
} as const;

type MilestoneContentProps = {
    milestone: MonthlyPaymentPlanMilestone;
};

const MilestoneContent = ({ milestone }: MilestoneContentProps) => {
    const i18n = useI18n();
    const dueDate = milestone.dueDate
        ? formatDate(new Date(milestone.dueDate), i18n.locale, FORMATTER_OPTIONS)
        : null;

    return (
        <Flex className={styles.equalColumns}>
            <div className={styles.cell}>{milestone.title}</div>
            <Flex className={styles.cell}>
                <div className={styles.boldText}>{milestone.valuePercentage}% </div>
                {dueDate && <Trans>on {dueDate}</Trans>}
            </Flex>
        </Flex>
    );
};

type Props = {
    paymentPlan: MonthlyProjectPaymentPlan;
};

const PaymentPlanTable = ({ paymentPlan }: Props) => {
    const timeline = useMonthlyPaymentTimeline(paymentPlan);

    return (
        <Flex column>
            <div className={styles.title}>
                <Trans>% of property value to be paid by date</Trans>
            </div>
            <div>
                {timeline.map((milestone, index) => (
                    <Flex key={index} className={styles.element}>
                        <MilestoneAxisSegment />
                        <Flex column className={styles.content}>
                            <MilestoneContent milestone={milestone} />
                        </Flex>
                    </Flex>
                ))}
            </div>
        </Flex>
    );
};

export default PaymentPlanTable;
