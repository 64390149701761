import { createSelector } from 'reselect';

const Actions = Object.freeze({
    DISMISS_PROPERTY_DEEP_LINK: 'PROPERTY_DEEP_LINK/DISMISS',
});

const defaultState = {
    deepLinkDismissed: false,
} as const;

const propertyDeepLinkReducer = (state: any | null | undefined = defaultState, action: any) => {
    switch (action.type) {
        case Actions.DISMISS_PROPERTY_DEEP_LINK:
            return {
                ...state,
                deepLinkDismissed: true,
            };
        default:
            return state;
    }
};

const dismissPropertyDeepLink = () => ({
    type: Actions.DISMISS_PROPERTY_DEEP_LINK,
});

const selectIsPropertyDeepLinkDismissed = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.propertyDeepLinks?.deepLinkDismissed,
    // @ts-expect-error - TS7006 - Parameter 'deepLinkDismissed' implicitly has an 'any' type.
    (deepLinkDismissed) => deepLinkDismissed,
);

export { dismissPropertyDeepLink, selectIsPropertyDeepLinkDismissed };

export default propertyDeepLinkReducer;
