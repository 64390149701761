import React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from 'strat/components';

import { MilestoneAxisSegment } from './paymentPlanTable';
import styles from './styles/paymentPlanTable.cssm';

type Props = {
    preHandoverPercentage: number | null;
    postHandoverPercentage: number | null;
    hasOtherPaymentPlans?: boolean;
};

export const PaymentPlanTooltipTable = ({
    preHandoverPercentage,
    postHandoverPercentage,
    hasOtherPaymentPlans = false,
}: Props) => {
    return (
        <div className={styles.tooltipTable}>
            <div className={styles.title}>
                <Trans>% of Property Value to be Paid</Trans>
            </div>
            <table className={styles.table}>
                <tbody>
                    <tr className={styles.emptyRow} />
                    {!!preHandoverPercentage && (
                        <tr>
                            <MilestoneAxisSegment />
                            <td>
                                <Trans>
                                    <Text.Large bold>{preHandoverPercentage}%</Text.Large> Pre
                                    Handover
                                </Trans>
                            </td>
                        </tr>
                    )}
                    {!!postHandoverPercentage && (
                        <tr>
                            <MilestoneAxisSegment />
                            <td>
                                <Trans>
                                    <Text.Large bold>{postHandoverPercentage}%</Text.Large> Post
                                    Handover
                                </Trans>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {hasOtherPaymentPlans && (
                <Text.Small gray>
                    <Trans>Note: Other payment plans are also available</Trans>
                </Text.Small>
            )}
        </div>
    );
};

export default PaymentPlanTooltipTable;
