import { makeCancelable } from 'strat/util/cancelable';
import type { TypedCancelablePromise } from 'strat/util/cancelable';
import { RoutingContextWithMiddlewares } from 'strat/app';

import Route from './route';
import type { PatternsCollection } from './regex';
import RoutingContext from './routingContext';

class CancelableRoute extends Route {
    cancelables: Array<TypedCancelablePromise>;

    constructor(name: string, patterns: PatternsCollection) {
        super(name, patterns);

        this.cancelables = [];
    }

    onLeave(
        _oldContext?: RoutingContext | RoutingContextWithMiddlewares,
        _newContext?: RoutingContext | RoutingContextWithMiddlewares,
    ): void {
        this.cancelables
            .filter((cancelable) => !!cancelable)
            .forEach((cancelable) => cancelable.cancel());
    }

    cancelable<T>(promise: Promise<T>): TypedCancelablePromise<T> {
        const result = makeCancelable(promise);
        this.cancelables.push(result);
        return result;
    }
}

export default CancelableRoute;
