import { t, plural } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { Button, Flex, Text } from 'strat/components';

import {
    selectIsBusinessPackageCartEmpty,
    selectBusinessPackageCartTotalPrice,
    selectBusinessPackageCartOffers,
} from './selectors';
import { useNavigateToViewCart } from './hooks';
import useFormattedPackagesPrice from './useFormattedPackagesPrice';
import styles from './styles/viewBusinessPackageCartSection.cssm';

const ViewBusinessPackageCartSection = () => {
    const i18n = useI18n();
    const totalPrice = useSelector(selectBusinessPackageCartTotalPrice);
    const isBusinessPackageCartEmpt = useSelector(selectIsBusinessPackageCartEmpty);
    const numberOfItems = useSelector(selectBusinessPackageCartOffers).length;
    const numberOfItemsLabel = t(i18n)`${plural(numberOfItems, {
        one: `${numberOfItems} item`,
        other: `${numberOfItems} items`,
    })}`;
    const navigateToViewCart = useNavigateToViewCart();
    const formattedTotalPrice = useFormattedPackagesPrice(totalPrice);

    return (
        <Flex justifySpaceBetween stretchWidth className={styles.container}>
            <Flex column justifySpaceAround className={styles.cartDetails}>
                <Flex className={styles.total}>
                    <Text.Regular>{formattedTotalPrice}</Text.Regular>
                </Flex>
                <Flex className={styles.numberOfItems}>
                    <Text.Regular>{numberOfItemsLabel}</Text.Regular>
                </Flex>
            </Flex>
            <Button onClick={navigateToViewCart} disabled={isBusinessPackageCartEmpt}>
                <Flex alignCenter>
                    <Flex>
                        <Flex>
                            <Text.Large bold>{t(i18n)`View Cart`}</Text.Large>
                        </Flex>
                    </Flex>
                </Flex>
            </Button>
        </Flex>
    );
};

export default ViewBusinessPackageCartSection;
