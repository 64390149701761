import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { RadioInput } from 'strat/generic';
import { Flex } from 'strat/components';
import { CloseButton } from 'strat/modal';
import { setItem as setItemInLocalStorage } from 'strat/misc/localStorage';

import { NBS_SURVEY_OPTIONS } from './constants';
import styles from './styles/commuteSurvey.cssm';

type Props = {
    commuteSurveyKey: string;
    onVisibilityChanged: (arg1: boolean) => void;
    onSubmit: (arg1: string, arg2: string) => void;
};

const CommuteSurveyContent = ({ commuteSurveyKey, onVisibilityChanged, onSubmit }: Props) => {
    const i18n = useI18n();
    const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(-1);
    const [otherValue, setOtherValue] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const responseOptions = NBS_SURVEY_OPTIONS;

    const messages = {
        property_found: t(i18n)`I found a property close to both my points of interest`,
        property_not_found: t(i18n)`I didn’t find the right property for me`,
        inaccurate_data: t(i18n)`I found the drive times to be inaccurate`,
        difficult_use: t(i18n)`I did not find it easy to use this search`,
        not_in_budget: t(i18n)`I did not find something within my budget`,
        other: t(i18n)`Other`,
    } as const;
    const placeHolder = t(i18n)`Type your answer here*`;

    const onValueChanged = (event: any) => {
        const value = parseInt(event.target.value, 10);
        if (value !== responseOptions.length - 1 && otherValue.length) {
            setOtherValue('');
        }
        setSelectedOptionIndex(value);
        if (errorMessage.length) {
            setErrorMessage('');
        }
    };

    const submitSurvey = (event: any) => {
        if (selectedOptionIndex === -1) {
            event.preventDefault();
            setErrorMessage(t(i18n)`Please select an option before submitting.`);
            return;
        }
        if (selectedOptionIndex === responseOptions.length - 1 && otherValue.length === 0) {
            event.preventDefault();
            setErrorMessage(t(i18n)`Please type an answer before submitting.`);
            return;
        }
        setItemInLocalStorage(commuteSurveyKey, true);
        onSubmit(responseOptions[selectedOptionIndex], otherValue);
    };

    const getSurveyContent = () => {
        return responseOptions.map((option, index) => {
            const isOptionSelected =
                responseOptions[index] === responseOptions[selectedOptionIndex];
            const isOptionOther = index === responseOptions.length - 1;
            return (
                <Flex column stretchWidth className={styles.option} key={index}>
                    <RadioInput
                        index={index}
                        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly property_found: string; readonly property_not_found: string; readonly inaccurate_data: string; readonly difficult_use: string; readonly not_in_budget: string; readonly other: string; }'.
                        value={messages[option]}
                        // @ts-expect-error - TS2322 - Type '(event: any) => void' is not assignable to type '() => void'.
                        onValueChanged={onValueChanged}
                        isChecked={isOptionSelected}
                    />
                    {isOptionOther && isOptionSelected && (
                        <input
                            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
                            maxLength={'50'}
                            placeholder={placeHolder}
                            type={'text'}
                            value={otherValue}
                            onChange={(e) => setOtherValue(e.target.value)}
                        />
                    )}
                </Flex>
            );
        });
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.title}>
                <Trans>
                    We hope you found what you were looking for! We&apos;d love to hear your
                    feedback
                </Trans>
            </div>
            <div className={styles.subtitle}>
                <Trans>Tell us more about your experience with Search 2.0!</Trans>
            </div>
            <CloseButton
                onClick={() => onVisibilityChanged(false)}
                className={styles.closeButton}
            />
            {getSurveyContent()}
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
            <button type={'submit'} className={styles.submitButton} onClick={submitSurvey}>
                {t(i18n)`Submit`}
            </button>
        </div>
    );
};

export default CommuteSurveyContent;
