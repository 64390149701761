import IconAnimals from '@app/assets/icons/animals_noinline.svg';
import IconBikes from '@app/assets/icons/bikes_noinline.svg';
import IconBooks from '@app/assets/icons/books_noinline.svg';
import IconBusiness from '@app/assets/icons/business_noinline.svg';
import IconFashion from '@app/assets/icons/fashion_noinline.svg';
import IconFurniture from '@app/assets/icons/furniture_noinline.svg';
import IconJobs from '@app/assets/icons/jobs_noinline.svg';
import IconKids from '@app/assets/icons/kids_noinline.svg';
import IconMobiles from '@app/assets/icons/mobiles_noinline.svg';
import IconPropertyForRent from '@app/assets/icons/property-for-rent_noinline.svg';
import IconPropertyForSale from '@app/assets/icons/property-for-sale_noinline.svg';
import IconServices from '@app/assets/icons/services_noinline.svg';
import IconVehicles from '@app/assets/icons/vehicles_noinline.svg';

const CategoryIconMapping = {
    animals: IconAnimals,
    pets: IconAnimals,
    bikes: IconBikes,
    'sporting-goods-bikes': IconBikes,
    'books-sports-hobbies': IconBooks,
    'sports-equipments': IconBikes,
    'sports-equipment': IconBikes,
    'hobbies-music-art-books': IconBooks,
    'business-industrial-agriculture': IconBusiness,
    'electronics-home-appliances': IconBusiness,
    'business-industrial': IconBusiness,
    'furniture-home-decor': IconFurniture,
    'home-furniture-decor': IconFurniture,
    'home-garden': IconFurniture,
    'fashion-beauty': IconFashion,
    jobs: IconJobs,
    'jobs-services': IconJobs,
    kids: IconKids,
    'kids-babies': IconKids,
    mobiles: IconMobiles,
    'mobile-phones-tablets-accessories-numbers': IconMobiles,
    'mobiles-tablets': IconMobiles,
    'mobile-phones-accessories': IconMobiles,
    properties: IconPropertyForRent,
    'property-for-rent': IconPropertyForRent,
    'property-for-sale': IconPropertyForSale,
    services: IconServices,
    vehicles: IconVehicles,
} as const;

export default CategoryIconMapping;
