import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

import { ChatRoomDisabledReason } from 'horizontal/chat/constants';
import type { ChatRoomDisabledInfo } from 'horizontal/chat/types';

const useDisabledChatMessage = ({ reason }: ChatRoomDisabledInfo): string => {
    const i18n = useI18n();

    switch (reason) {
        case ChatRoomDisabledReason.INACTIVE_AD:
            return t(i18n)`Ad inactive.`;

        case ChatRoomDisabledReason.DELETED_AD:
            return t(i18n)`Ad deleted.`;

        case ChatRoomDisabledReason.SOLD_AD:
            return t(i18n)`Ad sold.`;

        case ChatRoomDisabledReason.REJECTED_AD:
        case ChatRoomDisabledReason.SOFT_REJECTED_AD:
            return t(i18n)`Ad rejected`;

        case ChatRoomDisabledReason.BLOCKED_BY_CURRENT_USER:
        case ChatRoomDisabledReason.BLOCKED_BY_OTHER_USER:
        default:
            return t(i18n)`Conversation with this user is blocked.`;
    }
};

export default useDisabledChatMessage;
