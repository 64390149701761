import * as React from 'react';
import { useSelector } from 'react-redux';
import type { ChoiceValue } from 'strat/components/types';
import { RadioGroup, RadioButtonChoice, Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { selectIsMobileLayout } from 'strat/layout/selectors';
import Category from '@app/branding/category';
import { useDialog } from 'strat/dialogs/dialogContext';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';

import { MultiSelect, MultiSelectSlideIn } from 'horizontal/components';
import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldValueType } from 'horizontal/types';
import FilterableDropdownWithSuggestions from 'horizontal/adCreation/filterableDropdownWithSuggestions';
import Context from 'horizontal/adCreation/context';
import { DialogNames } from 'horizontal/dialogs';

import CheckboxOptionsChoice from './checkboxOptionsChoice';
import SelectedOptions from './selectedOptions';
import useSortedChoices, { SortingOptions } from './useSortedChoices';
import { ExtraFeatures, ExtraFields, Features } from './commonPostingFields';
import styles from './styles/adCreationGroupedChoicesDialog.cssm';

const COLLAPSED_CHOICES_COUNT = 4;

type Props = {
    readonly field: FlatCategoryField;
    readonly value: string;
    readonly onChange: (arg1: ChoiceValue | ChoiceValue[]) => void;
    readonly errorMessage?: string;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly accepted: boolean;
    readonly readOnly?: boolean;
    readonly placeholder?: string;
    readonly boldLabel?: boolean;
    readonly autoFilledValues?: ChoiceValue[];
    readonly setAutoFilledValues?: (arg1: ChoiceValue[]) => void;
    readonly radioThreshold?: number;
};

const EnumInput = ({
    field,
    value,
    onChange,
    errorMessage,
    accepted,
    readOnly,
    placeholder,
    boldLabel,
    autoFilledValues,
    setAutoFilledValues,
    radioThreshold = 3,
}: Props) => {
    const activeCategoryContext = React.useContext(Context);
    const [activeCategory] = activeCategoryContext;
    const [, setVisible] = useDialog(DialogNames.AD_CREATION_GROUPED_CHOICES_DIALOG);

    const setGroupedChoicesDialogVisible = (
        groupedChoices: Array<CategoryFieldGroupedChoices>,
        selectedValues: Set<ChoiceValue>,
    ) => {
        setVisible(true, {
            choices: field.choices,
            activeCategoryContext,
            selectedValues,
            groupedChoices,
            onChange,
            value,
            errorMessage,
            autoFilledValues,
            setAutoFilledValues,
        });
    };

    const sortingOption = React.useMemo(() => {
        if (
            Category.isOfCarsSubType(activeCategory) &&
            field.attribute === ExtraFields.make.attribute
        ) {
            return SortingOptions.SORT_ALPHABETICALLY;
        }

        return SortingOptions.DEFAULT_SORTING;
    }, [activeCategory, field]);

    const sortedChoices = useSortedChoices(field.choices, sortingOption);

    const isMobile = useSelector(selectIsMobileLayout);
    const title = field.name || field.attribute;
    if (field.valueType === CategoryFieldValueType.ENUM_MULTIPLE) {
        const onMultiSelectChange = (newValues: Set<ChoiceValue>) => {
            onChange(Array.from(newValues));
            const newAutofilledValues = Array.from(newValues).filter((value) =>
                autoFilledValues?.includes(value),
            );
            setAutoFilledValues?.(newAutofilledValues);
        };
        const selectedValues = new Set(value);

        if (isMobile) {
            return (
                <MultiSelectSlideIn
                    onChange={(newValues) => onMultiSelectChange(newValues)}
                    selectedValues={selectedValues}
                    choices={sortedChoices}
                    title={field.isMandatory ? title.concat('*') : title}
                    readOnly={readOnly}
                    boldLabel
                />
            );
        }
        if (
            !!Object.values(field?.choiceGroups ?? {}).length &&
            ([ExtraFeatures.attribute, Features.attribute] as Array<string>).includes(
                field.attribute,
            )
        ) {
            return (
                <div>
                    <MultiSelect
                        onChange={(newValues) => onMultiSelectChange(newValues)}
                        selectedValues={selectedValues}
                        autoFilledValues={autoFilledValues}
                        choices={sortedChoices?.slice(0, COLLAPSED_CHOICES_COUNT)}
                        title={field.isMandatory ? title.concat('*') : title}
                        errorMessage={errorMessage}
                        renderChoice={CheckboxOptionsChoice}
                        choicesClassName={styles.inlineChoices}
                        leftSideLabel
                        boldLabel
                        renderExpandOptions={() => (
                            <Flex
                                alignCenter
                                className={styles.selectMoreButtonContainer}
                                onClick={() => {
                                    setGroupedChoicesDialogVisible(
                                        Object.values(field.choiceGroups || {}),
                                        selectedValues,
                                    );
                                }}
                            >
                                <Text.Large bold aria-label="Select more button">
                                    <Trans>Select more</Trans>
                                </Text.Large>
                                <Text.XXLarge bold>{'+'}</Text.XXLarge>
                            </Flex>
                        )}
                    />
                    {!!selectedValues.size && (
                        <SelectedOptions
                            selectedValues={selectedValues}
                            sortedChoices={sortedChoices}
                        />
                    )}
                </div>
            );
        }

        return (
            <MultiSelect
                onChange={(newValues) => onMultiSelectChange(newValues)}
                selectedValues={selectedValues}
                choices={sortedChoices}
                autoFilledValues={autoFilledValues}
                title={field.isMandatory ? title.concat('*') : title}
                errorMessage={errorMessage}
                leftSideLabel
                boldLabel
            />
        );
    }

    if (isNaN(radioThreshold) || sortedChoices.length <= radioThreshold) {
        return (
            <RadioGroup
                title={field.isMandatory ? title.concat('*') : title}
                choices={sortedChoices}
                selectedValue={value}
                autoFilledValues={autoFilledValues}
                onChange={onChange}
                errorMessage={errorMessage}
                renderChoice={CONFIG.runtime.ENABLE_RADIO_BUTTONS ? RadioButtonChoice : undefined}
                compact
                leftSideLabel
                boldLabel={boldLabel}
                readOnly={readOnly}
            />
        );
    }

    return (
        <FilterableDropdownWithSuggestions
            isAutofilled={(autoFilledValues?.length || 0) > 0}
            title={field.isMandatory ? title.concat('*') : title}
            name={field.attribute}
            choices={sortedChoices}
            value={value}
            onChange={onChange}
            errorMessage={errorMessage}
            accepted={accepted}
            field={field}
            readOnly={readOnly}
            placeholder={placeholder}
            boldLabel={boldLabel}
        />
    );
};

export default EnumInput;
