import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';

import type { ProductPurchase } from 'horizontal/types';

import { UserOrdersSection, ProductPurchaseVirtualStatus, UserOrdersSubSection } from '../types';

import useProductPurchaseDetails from './useProductPurchaseDetails';
import styles from './styles/productPurchaseCard.cssm';

const ProductPurchaseQuantity = ({
    text,
    quantity,
}: {
    readonly text: string;
    readonly quantity: number;
}) => (
    <Flex fillContainer alignCenter column>
        <Text.XLarge bold>{quantity}</Text.XLarge>
        <Text.Small>{text}</Text.Small>
    </Flex>
);

type Props = {
    readonly productPurchase: ProductPurchase;
};

const ProductPurchaseCard = ({ productPurchase }: Props) => {
    const i18n = useI18n();
    const {
        productPurchaseID,
        name,
        valueProposition,
        category,
        location,
        startDate,
        expiryDate,
        orderID,
        purchased,
        available,
        used,
        status,
    } = useProductPurchaseDetails(productPurchase);

    const strippedValueProposition = valueProposition?.replace(/(<([^>]+)>)/gi, '');

    return (
        <Flex column className={styles.container}>
            <Flex column className={styles.info}>
                <Flex justifySpaceBetween>
                    <Text.Large className={styles.name} bold>
                        {t(i18n)`Package: ${name}`}
                    </Text.Large>
                </Flex>
                <Text.Base className={styles.description}>{strippedValueProposition}</Text.Base>

                <Text.Small>{t(i18n)`Category: ${category}`}</Text.Small>
                <Text.Small>{t(i18n)`Location: ${location}`}</Text.Small>
                <Text.Small aria-label="Activation date">{t(
                    i18n,
                )`Date of Activation: ${startDate}`}</Text.Small>
                <Text.Small aria-label="Expiry date">{t(
                    i18n,
                )`Date of Expiry: ${expiryDate}`}</Text.Small>
                <Text.Small>{t(i18n)`Order ID: ${orderID}`}</Text.Small>
                {status === ProductPurchaseVirtualStatus.EXPIRED && (
                    <Text.Small>{t(i18n)`Used: ${used} of ${purchased} ads`}</Text.Small>
                )}
            </Flex>
            {status !== ProductPurchaseVirtualStatus.EXPIRED && (
                <Flex className={styles.quantities}>
                    <ProductPurchaseQuantity text={t(i18n)`Purchased`} quantity={purchased} />
                    <ProductPurchaseQuantity text={t(i18n)`Available`} quantity={available} />
                    <ProductPurchaseQuantity text={t(i18n)`Used`} quantity={used} />
                </Flex>
            )}
            {/* @ts-expect-error - TS2339 - Property 'showViewConsumptionLink' does not exist on type 'HorizontalSettings'. */}
            {settings.showViewConsumptionLink && (
                <Link
                    route={RouteNames.USER_ORDERS}
                    title={t(i18n)`View Consumption`}
                    params={{
                        productPurchaseID,
                        section: UserOrdersSection.ORDERS,
                        ordersStatus: status,
                        ordersStatusSubSection: UserOrdersSubSection.CONSUMPTION,
                    }}
                >
                    <Flex className={styles.footer} justifySpaceBetween alignCenter>
                        <Flex className={styles.viewConsumption}>
                            <Text.Regular>{t(i18n)`View Consumption`}</Text.Regular>
                        </Flex>
                        <Flex className={styles.icon}>
                            <div className={styles.arrowIcon} />
                        </Flex>
                    </Flex>
                </Link>
            )}
        </Flex>
    );
};

export default ProductPurchaseCard;
