import * as React from 'react';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import {
    FilterCollection,
    ObjectExactFilter,
    QueryFilter,
} from '@sector-labs/fe-search-redux/filters';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import type { SearchRequestFacetsOption } from '@sector-labs/fe-search-redux/backend';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import { FilterValues } from 'strat/search';
import { selectLanguage } from 'strat/i18n/language';
import { determineAdsIndexName } from 'strat/search/indexNames';

import type { CategoryFieldsFilters, Location, FlatCategoryField } from 'horizontal/types';
import { createCommonFieldsFacetingConfig } from 'horizontal/search/execution';
import type { SearchRouteParams } from 'horizontal/routes';
import { useCurrentSearchRouteParams, searchParamsToFilters } from 'horizontal/search/routing';

import { useFlatFilterableCategoryFields } from '../categoryFields';

const createSearchSettings = (facets: Array<SearchRequestFacetsOption>) => {
    return {
        hitsPerPage: 0,
        maxValuesPerFacet: 1000,
        facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING ? facets : [],
    };
};

const createSearchFilters = (
    location: Location,
    categoryFields: Array<FlatCategoryField>,
    params?: SearchRouteParams,
) => {
    const filters = new FilterCollection();

    if (params && params.category) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.category.attribute,
                value: params.category,
            }),
        );
    }

    if (location) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.location.attribute,
                value: location,
                operator: Operator.OR,
            }),
        );
    }

    if (params && params.freeTextQuery) {
        filters.refine(
            new QueryFilter({
                value: params.freeTextQuery,
                fuzzy: false,
                fields: ['query'],
            }),
        );
    }

    if (params && params.extraFields) {
        const paramsToFilters = searchParamsToFilters(
            categoryFields,
            params.extraFields as CategoryFieldsFilters,
        );
        filters.refineMultiple(paramsToFilters);
    }

    return filters;
};

const useFetchLocationFacets = (activeLocation: Location) => {
    const backend = useSelector(selectActiveSearchBackend);
    const language = useSelector(selectLanguage);
    const [locationFacets, setLocationFacets] = React.useState([]);
    const params = useCurrentSearchRouteParams();
    const flatFilterableCategoryFields = useFlatFilterableCategoryFields();

    React.useEffect(() => {
        const searchFacets = createCommonFieldsFacetingConfig(activeLocation.hierarchy);
        // @ts-expect-error - TS2345 - Argument of type 'SearchRequestFacetsOption' is not assignable to parameter of type 'SearchRequestFacetsOption[]'.
        const settings = createSearchSettings(searchFacets);
        const filters = createSearchFilters(activeLocation, flatFilterableCategoryFields, params);
        const indexName = determineAdsIndexName({ language });
        // @ts-expect-error - TS2345 - Argument of type '{ hitsPerPage: number; maxValuesPerFacet: number; facets: SearchRequestFacetsOption[]; }' is not assignable to parameter of type 'Partial<SearchJobOptions>'.
        const job = new SearchJob(indexName, filters, settings);
        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend });

        service.fetchJob(job).then((response) => {
            const facets = response?.facets || {};
            // @ts-expect-error - TS2345 - Argument of type 'SearchResponseFacetValue<unknown, unknown>[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setLocationFacets(facets[`location.lvl${activeLocation.level + 1}`]);
        });
    }, [backend, language, activeLocation, flatFilterableCategoryFields, params]);

    return locationFacets;
};

export default useFetchLocationFacets;
