import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { selectLanguage } from 'strat/i18n/language';
import { selectRouteName } from 'strat/routes/selectors';

import IconPaymentFailed from '@app/assets/icons/iconPaymentFailed_noinline.svg';
import { trackCustomerSupport } from 'horizontal/gtm';

import styles from './styles/paymentFailed.cssm';

type Props = {
    readonly errorMessage?: React.ReactNode | null | undefined;
    readonly errorTitle?: React.ReactNode | null | undefined;
};

const PaymentFailed = ({ errorMessage, errorTitle }: Props) => {
    const language = useSelector(selectLanguage);
    const route = useSelector(selectRouteName);

    const onClickCustomerSupport = () => {
        trackCustomerSupport(route);
    };

    return (
        <Flex className={styles.container}>
            <img src={IconPaymentFailed} alt={'Icon payment failed'} className={styles.icon} />
            <Flex column justifyCenter>
                <Text.Regular>{errorTitle || <Trans>Please try again</Trans>}</Text.Regular>
                <Text.Base className={styles.description}>
                    {errorMessage ? (
                        <Trans>Previous payment failed! Error message: {errorMessage}</Trans>
                    ) : (
                        <Trans>Something went wrong processing your payment.</Trans>
                    )}
                </Text.Base>
                <Text.Base className={styles.description}>
                    <Trans>Need help? Please contact</Trans>{' '}
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={settings.support_request[language as 'en' | 'ar']}
                        className={styles.support}
                        onClick={onClickCustomerSupport}
                    >
                        <Trans>customer support</Trans>
                    </a>
                </Text.Base>
            </Flex>
        </Flex>
    );
};

export default PaymentFailed;
