import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { formatDate } from 'strat/i18n/language/dateTimeFormatter';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { Flex, Text } from 'strat/components';
import CallIcon from 'strat/assets/icons/iconCall.svg';
import { selectIsMobile } from 'strat/platform/selectors';

import { DeliveryType } from 'horizontal/types';
import type { AddressInfo } from 'horizontal/userOrders/types';
import { fetchUserSellingList } from 'horizontal/userOrders/sellingOrders';
import { useFormattedPrice } from 'horizontal/util';
import type { AppDispatch } from 'horizontal/state';

import { useDeliveryOrdersNavStatus, useDeliveryOrderStatusDetails } from '../hooks';
import { DeliveryRequestStatus } from '../types';
import { updateDeliveryOrderStatus, DeliveryOrderStatusPill } from '../deliveryOrders';

import SellingCardActionButtons from './sellingCardActionButtons';
import styles from './styles/sellingOrderCardExpansion.cssm';

type Props = {
    readonly buyerName: string;
    readonly buyerPhoneNumber: string;
    readonly orderStatus: string;
    readonly createdAt: number;
    readonly deliveryAddress: AddressInfo;
    readonly userExternalID: string;
    readonly offerID: number;
    readonly price: number;
    readonly deliveryType?: DeliveryType;
};

const FORMATTER_OPTIONS = {
    year: '2-digit',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
} as const;

const RequestOrderDetails = ({
    buyerName,
    buyerPhoneNumber,
    orderStatus,
    createdAt,
    deliveryAddress,
    offerID,
    userExternalID,
    price,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: Props) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    const language = useSelector(selectLanguage);
    const displayedPrice = useFormattedPrice(price || 0);
    const formattedDate = createdAt
        ? formatDate(new Date(createdAt * 1000), i18n.locale, FORMATTER_OPTIONS)
        : '';
    const deliveryOrdersNavStatus = useDeliveryOrdersNavStatus();
    const statusDetails = useDeliveryOrderStatusDetails({
        status: orderStatus,
        isSeller: true,
        deliveryType: deliveryType,
        i18n: i18n,
    });

    const isMobile = useSelector(selectIsMobile);

    const resolveLocationString = (location: Array<any>) =>
        `${location[2]?.name[language]}, ${location[1]?.name[language]}`;

    const getNewestSellingList = React.useCallback(() => {
        dispatch(
            fetchUserSellingList({ userExternalID, offersStatus: DeliveryRequestStatus.ACTIVE }),
        );
    }, [dispatch, userExternalID]);

    const changeOrderStatus = (offerId: number, offerStatus: string) => {
        updateDeliveryOrderStatus(offerId, offerStatus, userExternalID, getNewestSellingList);
    };
    const actionButtonsWrapper = () => {
        return (
            statusDetails.enableCancelOrder &&
            deliveryOrdersNavStatus !== DeliveryRequestStatus.ARCHIVED && (
                <SellingCardActionButtons
                    orderStatus={orderStatus}
                    changeOrderStatus={changeOrderStatus}
                    offerID={offerID}
                    deliveryType={deliveryType}
                />
            )
        );
    };

    return (
        <Flex className={styles.orderDetailItem} alignCenter>
            <Flex fillContainer column>
                <Flex fillContainer className={styles.orderInformation} justifySpaceBetween>
                    <Flex column className={styles.orderInformationItem}>
                        <Text.Regular>
                            <Trans>Order time</Trans>
                        </Text.Regular>
                        <Text.Regular aria-label="Order date">{formattedDate} </Text.Regular>
                    </Flex>
                    <Flex column className={styles.orderInformationItem}>
                        <Text.Regular>
                            <Trans>Buyer Contact</Trans>
                        </Text.Regular>
                        <Flex column className={styles.buyerInfo}>
                            <Text.Regular>{buyerName}</Text.Regular>
                            <Flex className={styles.buyerPhoneNumber}>
                                <CallIcon />
                                <Text.Regular>{buyerPhoneNumber}</Text.Regular>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex column className={styles.orderInformationItem}>
                        <Text.Regular>
                            <Trans>Buyer Address</Trans>
                        </Text.Regular>
                        <Text.Regular>
                            {resolveLocationString(deliveryAddress.locationPath || [])}
                        </Text.Regular>
                        {deliveryType === DeliveryType.SELF_DELIVERY && (
                            <Text.Regular>{`${deliveryAddress.addressLineOne}, ${deliveryAddress.addressLineTwo}`}</Text.Regular>
                        )}
                    </Flex>
                    <Flex column className={styles.orderInformationItem}>
                        <Text.Regular>
                            <Trans>Price</Trans>
                        </Text.Regular>
                        <Flex column>
                            <Text.Regular>{displayedPrice}</Text.Regular>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex fillContainer justifySpaceBetween>
                    <Flex className={[styles.status, styles.orderInformationItem].join(' ')}>
                        <Text.Regular>
                            <Trans>Status</Trans>
                        </Text.Regular>
                        <Flex aria-label="order state" className={styles.statusDescription}>
                            <div>
                                <DeliveryOrderStatusPill
                                    orderStatus={orderStatus}
                                    title={statusDetails.title}
                                    deliveryType={deliveryType}
                                />
                            </div>
                            <Text.Regular>{statusDetails.description}</Text.Regular>
                        </Flex>
                    </Flex>
                    {!isMobile && actionButtonsWrapper()}
                </Flex>
            </Flex>
            {isMobile && actionButtonsWrapper()}
        </Flex>
    );
};

export default RequestOrderDetails;
