import React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { useSelector } from 'react-redux';

import { PaymentOrder, PaymentOrderStatus, PaymentStatus } from 'horizontal/payment';
import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';
import { useNavigateToProcessingPage, useNavigateAfterPayment } from 'horizontal/payment/hooks';
import { selectBusinessPackageCartOfferAdID } from 'horizontal/businessPackages/selectors';

import { UserDetails } from './mobileWalletFields';

const MAX_RETRIES = 6;
const RETRIES_INTERVAL_SECONDS = 20;

/**
 *  Starts a payment for jazz cash mobile wallet. The initial request to StratAPI().jazzCashMobileWalletPayment will
 *  hang until the user accepts the payment on its phone. Because it can hang for a long time the request will be timeout.
 *  We can't use the response from it to know if the payment is done or not. If the request timeouts jazz cash will update
 *  the payment using the webhook. We try for 2 minutes to check if the payment is captured or declined. If in 3 minutes
 *  the payment is not updated we redirect the user to payment success with the message that the payment is still
 *  processing at the provider.
 */
const useMakeMobileWalletPayment = (
    order: PaymentOrder,
    navigateBack: (status: Values<typeof PaymentStatus>) => void,
) => {
    const makeCancelable = useMakeCancelable();
    const navigateAfterPayment = useNavigateAfterPayment();
    const navigateToProcessingPage = useNavigateToProcessingPage();
    const orderAdID = useSelector(selectBusinessPackageCartOfferAdID);
    const router = useRouter();
    const [loading, setLoading] = React.useState(false);
    const retries = React.useRef(0);
    const orderFetchInterval = React.useRef<ReturnType<typeof setInterval>>();

    React.useEffect(
        () => () => {
            if (orderFetchInterval.current) {
                clearInterval(orderFetchInterval.current);
            }
        },
        [],
    );

    const fetchPaymentOrder = () => {
        retries.current += 1;
        makeCancelable(
            new StratAPI()
                .getOrder(order.externalID)
                .then(({ status, data: paymentOrder }) => {
                    if (status !== 200) {
                        return;
                    }

                    if (paymentOrder.status == PaymentOrderStatus.ACTIVE) {
                        navigateAfterPayment({
                            order: order,
                            status: PaymentOrderStatus.ACTIVE,
                        });
                        return;
                    }

                    if (
                        paymentOrder.status == PaymentStatus.PENDING &&
                        paymentOrder.latestPaymentResponseCode
                    ) {
                        navigateBack(PaymentStatus.FAILURE);
                        return;
                    }

                    if (retries.current >= MAX_RETRIES) {
                        navigateAfterPayment({
                            order: order,
                            status: paymentOrder?.status,
                        });
                    }
                })
                .finally(() => {
                    if (retries.current > MAX_RETRIES) {
                        router.pushRoute(RouteNames.INTERNAL_ERROR);
                    }
                }),
        );
    };

    const onSubmit = (userDetails: UserDetails) => {
        if (!order) {
            return;
        }

        setLoading(true);
        makeCancelable(
            new StratAPI()
                .jazzCashMobileWalletPayment({
                    orderExternalID: order.externalID,
                    ...userDetails,
                })
                .then(({ status, data }) => {
                    if (status !== 200) {
                        return;
                    }
                    switch (data?.status) {
                        case PaymentStatus.CAPTURED:
                        case PaymentStatus.PENDING:
                            if (
                                CONFIG.runtime
                                    .STRAT_ENABLE_AFTER_PAYMENT_PROCESSING_PAGE_REDIRECTION &&
                                orderAdID
                            ) {
                                const { status, paymentID } = data;
                                navigateToProcessingPage({
                                    orderExternalID: order.externalID,
                                    paymentID,
                                    status,
                                });
                            } else {
                                navigateAfterPayment({
                                    order: order,
                                    status: data?.status,
                                });
                            }
                            break;
                        default:
                            navigateBack(PaymentStatus.FAILURE);
                    }
                }),
        );
        orderFetchInterval.current = setInterval(
            fetchPaymentOrder,
            RETRIES_INTERVAL_SECONDS * 1000,
        );
    };

    return { onSubmit, loading };
};

export default useMakeMobileWalletPayment;
