import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

import { UserSettingsSection } from './types';
import styles from './styles/menu.cssm';

type Props = {
    readonly section?: Values<typeof UserSettingsSection>;
};

const Menu = ({ section }: Props) => {
    const i18n = useI18n();

    return (
        <ul className={styles.list}>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_SETTINGS}
                    params={{ section: UserSettingsSection.PRIVACY }}
                    title={t(i18n)`Privacy`}
                    className={classNames(styles.link, {
                        [styles.selected]: section === UserSettingsSection.PRIVACY,
                    })}
                >
                    <Text.Large>{t(i18n)`Privacy`}</Text.Large>
                </Link>
            </li>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_SETTINGS}
                    params={{ section: UserSettingsSection.NOTIFICATIONS }}
                    title={t(i18n)`Notifications`}
                    className={classNames(styles.link, {
                        [styles.selected]: section === UserSettingsSection.NOTIFICATIONS,
                    })}
                >
                    <Text.Large>{t(i18n)`Notifications`}</Text.Large>
                </Link>
            </li>
        </ul>
    );
};

export default Menu;
