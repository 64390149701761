// @flow strict
import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import type { AdVirtualStateValues, FullAd } from 'horizontal/types';
import { TimeOfDay, isoDate } from 'horizontal/adManagement';
import type { DateRange } from 'horizontal/agencyPortal/types';

export type FetchAgencyAdsParams = {
    agencyExternalID: string;
    query?: string;
    agentCode?: string;
    phoneNumber?: string;
    location?: string;
    states?: Array<AdVirtualStateValues>;
    productFilter?: Array<AdVirtualStateValues>;
    category?: string;
    agent?: string;
    createDate?: DateRange;
    updateDate?: DateRange;
    page?: number;
    pageSize?: number;
    includeCategoryDescendants?: boolean;
};

export type AgencyManagementState = {
    count: number;
    countByState: Object;
    results: Array<FullAd>;
};

const DEFAULT_PAGE_SIZE = 10;

/**
 * Fetcher factory for fetching agency ads.
 */
const factory = new FetcherFactory(
    ['agencyPortal', 'agencyAdsManagement'],
    (params: FetchAgencyAdsParams, state) => {
        if (!params?.agencyExternalID) {
            throw new Error('Missing agency external id.');
        }
        const states = params.productFilter ?? params.states;
        return new StratAPI(state.i18n.language).agencyAds(params.agencyExternalID, {
            count_by_state: true,
            query_text: params.query,
            agent_code: params.agentCode,
            phone_number: params.phoneNumber,
            location_external_id: params.location,
            category_external_id: params.category,
            agent_external_id: params.agent,
            include_category_descendants: true,
            states: states,
            created_at_gte: params.createDate && isoDate(params.createDate?.from, TimeOfDay.START),
            created_at_lte: params.createDate && isoDate(params.createDate?.to, TimeOfDay.END),
            updated_at_gte: params.updateDate && isoDate(params.updateDate?.from, TimeOfDay.START),
            updated_at_lte: params.updateDate && isoDate(params.updateDate?.to, TimeOfDay.END),
            page: params.page,
            page_size: params.pageSize || DEFAULT_PAGE_SIZE,
        });
    },
    { successCodes: [200], skipParamsCheck: true },
);

const fetchAgencyAds = factory.creator();

const agencyAdsManagementReducer = factory.reducer();

export { fetchAgencyAds, agencyAdsManagementReducer, DEFAULT_PAGE_SIZE };
