export const AgentState = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
});

export type AgencyForAgentData = {
    id: string;
    external_id: string;
    name: string;
    slug: string;
    logo: {
        id: number;
        url: string;
    };
    licenses: Array<{
        authority: string;
        number: string;
    }>;
    type: string;
};

export type AgentData = {
    external_id: string;
    externalID?: string;
    name: string;
    about_user: string;
    user_image: string;
    user_image_id: number;
    state: Values<typeof AgentState>;
    experience?: number;
    slug: string;
    email: string;
    cell: string;
    cell_numbers: Array<string>;
    phone: string;
    phone_numbers: Array<string>;
    proxy_phone: string;
    proxy_mobile: string;
    user_langs: Array<string>;
    agency: AgencyForAgentData;
    social_media: {
        Facebook?: string;
        Youtube?: string;
        ['Linked In']?: string;
        Twitter?: string;
        Instagram?: string;
    } | null;
    sale_count: string;
    rent_count: string;
    detailedAdCountStats: {
        adCountVerificationStatsKeys: Array<string>;
        [location: number]: {
            [purpose: string]: {
                [category: string]: Array<number>;
            };
        };
    };
    bestDefaultFiltersForLeaderboard: {
        locationSlug: { [language: string]: string | null };
        category: string;
        purpose: string;
    } | null;
    specialities: Array<string>;
    service_areas: Array<string>;
    reviewCount: number;
    topAgent: boolean;
    brnNumber?: string;
    blnNumber?: string;
    isComplete: boolean;
    avgStarRating?: number;
    speciality_codes: Array<string>;
    language_codes: Array<string>;
    isTruBroker?: boolean;
    hasBadges?: boolean;
    isHighlyResponsive?: boolean;
    isQualityLister?: boolean;
    isRecommendedAgent?: boolean;
    globalRank?: number;
    globalRankLast3Months?: number;
    experiencePoints?: number;
    experiencePointsLast3Months?: number;
    totalXP?: number;
    stats: {
        adsCount: number;
        trucheckCount: number;
        checkCount: number;
    };
    activeInEmirate?: string;
};

export const AgentCategory = Object.freeze({
    ANY: 'any',
    COMMERCIAL: 'commercial',
    RESIDENTIAL: 'residential',
    SHORT_TERM_RESIDENTIAL: 'short_term_residential',
    LONG_TERM_RESIDENTIAL: 'long_term_residential',
});
