import React from 'react';
import autoBind from 'react-autobind';

import { connectLanguage } from 'strat/i18n/language';
import type { InlineStyle } from 'strat/types';

import { SlideInDirection } from './slideIn';
import Dismissible from './dismissible';

const BODY_WRAPPER = 'body-wrapper';

/**
 * Properties for {@see SideBar}.
 */
type Props = {
    /**
     * Width of the side bar itself.
     */
    readonly width: number;
    /**
     * Indicates whether the sidebar should be visible.
     */
    readonly visible: boolean;
    /**
     * Callback for when the visibility needs to change.
     */
    readonly onVisibilityChanged: (visible: boolean) => void;
    /**
     * Amount of time the transition to open/close the
     * side bar takes.
     */
    readonly transitionDuration?: string;
    /**
     * Additional styles for the side bar.
     */
    readonly className?: string;
    /**
     * Items to be displayed in the side bar.
     */
    readonly children?: React.ReactNode;
    /**
     * Indicates from which side of the screen the sidebar should slide in
     * when right-to-left script is inactive (english).
     */
    readonly slideFrom: Values<typeof SlideInDirection>;
    /**
     * Indicates whether right-to-left script is currently active.
     * (default is false, and left-to-right is used).
     */
    readonly rtl?: boolean;
};

/**
 * State for {@see SideBar}.
 */
type State = {
    /**
     * Indicates whether the side bar can be dismissed
     * by clicking outside the side bar.
     */
    dismissible: boolean;
};

/**
 * Renders a side bar on the left side of the screen
 * that pushes the content to the right when expanded.
 */
class SideBar extends React.Component<Props, State> {
    /**
     * Default values for optional props.
     */
    static defaultProps = {
        transitionDuration: '0.3s',
        rtl: false,
        slideFrom: SlideInDirection.LEFT,
    };

    /**
     * Initializes a new instance of {@see SideBar}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = { dismissible: false };
    }

    /**
     * Opens the side bar.
     */
    open(): void {
        this.props.onVisibilityChanged(true);
    }

    /**
     * Closes the side bar.
     */
    close(): void {
        this.props.onVisibilityChanged(false);
    }

    /**
     * Gets the inline style to give to the side bar.
     */
    sideBarStyle(): InlineStyle {
        const transitionDuration =
            this.props.transitionDuration || SideBar.defaultProps.transitionDuration;
        const slideFromLeft = this.props.rtl === (this.props.slideFrom === SlideInDirection.RIGHT);

        const offset = slideFromLeft ? -Math.abs(this.props.width) : Math.abs(this.props.width);

        const style: InlineStyle = {
            position: 'fixed',
            top: 0,
            margin: 0,
            height: '100%',
            width: `${this.props.width}px`,
            transition: `transform ${transitionDuration}`,
            transform: `translateX(${offset}px)`,
        };

        if (slideFromLeft) {
            style.left = 0;
        } else {
            style.right = 0;
        }

        return style;
    }

    /**
     * Updates the style of the content (body).
     */
    updateContentStyle(): void {
        const transitionDuration =
            this.props.transitionDuration || SideBar.defaultProps.transitionDuration;
        const slideFromLeft = this.props.rtl === (this.props.slideFrom === SlideInDirection.RIGHT);

        const offset = slideFromLeft ? Math.abs(this.props.width) : -Math.abs(this.props.width);
        const transform = this.props.visible ? `translateX(${offset}px)` : 'translateX(0)';

        // trick to make the content move to the right to make
        // space for the side bar without having to have
        // the content as the children of this component
        const element = document.getElementById(BODY_WRAPPER);
        if (element) {
            if (this.props.visible) {
                element.style.transition = `transform ${transitionDuration}`;
                element.style.transform = transform;
            } else {
                element.style.transform = '';
            }
        }
    }

    /**
     * Enables dismissing after the side bar was expanded.
     */
    componentDidUpdate(prevProps: Props): void {
        if (prevProps.visible !== this.props.visible) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ dismissible: !this.state.dismissible });
            if (process.env.IS_BROWSER) {
                this.updateContentStyle();
            }
        }
    }

    /**
     * Renders the side bar and the content.
     */
    render(): any {
        return (
            <Dismissible
                key="sideBar"
                enabled={this.state.dismissible}
                onDismissed={this.close}
                style={this.sideBarStyle()}
                className={this.props.className}
            >
                {this.props.children}
            </Dismissible>
        );
    }
}

export default connectLanguage(SideBar);
