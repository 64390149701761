import * as React from 'react';
import { useSelector } from 'react-redux';
import { GTMDataLayerVariables, Triggers } from 'strat/gtm';

import type { ProductPackageOffer } from 'horizontal/types';
import { selectCategories } from 'horizontal/categories';
import { usePageViewTrigger } from 'horizontal/gtm';

import useGlobalGTMVars from '../useGlobalGTMVars';
import { getDeepCategoryVars } from '../utils';

import PageType from './pageType';

type Props = {
    readonly productOffers?: Array<ProductPackageOffer>;
    readonly categoryID?: string;
    readonly locationID?: string;
};

const GTMSelectMultiplePackagesDataLayer = ({ productOffers, categoryID, locationID }: Props) => {
    const globalVars = useGlobalGTMVars();
    const categories = useSelector(selectCategories);

    const variables = {
        ...globalVars,
        ...getDeepCategoryVars(categories, categoryID),
        product_id: productOffers ? productOffers.map((offer) => offer.packageOfferID) : [],
        loc_id: locationID,
        page_type: PageType.MULTIPLE_PACKAGES,
    } as const;

    usePageViewTrigger(Triggers.SELECT_MULTIPLE_PACKAGES_VIEW, variables, !productOffers);

    return <GTMDataLayerVariables variables={variables} />;
};

export default GTMSelectMultiplePackagesDataLayer;
