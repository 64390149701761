import * as React from 'react';

import { trackPaymentFailed } from 'horizontal/gtm';
import { useCategoryVars } from 'horizontal/gtm';

import type { PaymentOrder } from '../types';

const useTrackPaymentFailed = (
    order: PaymentOrder | null | undefined,
    paymentFailed: boolean,
    failedPaymentMessage?: string,
) => {
    const [triggerPaymentFailed, setTriggerPaymentFailed] = React.useState(true);
    const categoryVars = useCategoryVars(
        order?.productCategoryExternalID || order?.categoryExternalID,
    );

    React.useEffect(() => {
        if (order && paymentFailed && triggerPaymentFailed) {
            trackPaymentFailed(order, categoryVars, failedPaymentMessage);
            setTriggerPaymentFailed(false);
        }
    }, [order, paymentFailed, triggerPaymentFailed, categoryVars, failedPaymentMessage]);
};

export default useTrackPaymentFailed;
