import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

type FawryRoutePayload = {
    readonly referenceNumber: string;
};

type UseNavigateToFawryPayment = (arg1?: FawryRoutePayload | null | undefined) => void;

const useNavigateToFawryPayment = (): UseNavigateToFawryPayment => {
    const router = useRouter();

    return React.useCallback(
        (params?: FawryRoutePayload | null) => {
            router.pushRoute(RouteNames.PAYMENT_FAWRY, params);
        },
        [router],
    );
};

export default useNavigateToFawryPayment;
