import { combineReducers } from 'redux';

type Reducer<TState, TActions> = (state: TState, action: TActions) => TState;
type ReducersMap<TState, TActions> = {
    [key: string]: Reducer<TState, TActions>;
};

/**
 * Create a reducer manager which supports code splitting.
 *
 * @see: https://redux.js.org/usage/code-splitting#using-a-reducer-manager
 */
function createReducerManager<
    TState extends {
        [key: string]: any;
    },
    TActions,
>(initialReducers: ReducersMap<TState, TActions>) {
    // Create an object which maps keys to reducers
    const reducers = { ...initialReducers } as const;

    // Create the initial combinedReducer
    // @ts-expect-error - TS2322 - Type 'Reducer<TState>' is not assignable to type 'Reducer<TState, TActions>'. | TS2345 - Argument of type '{ readonly [x: string]: Reducer<TState, TActions>; }' is not assignable to parameter of type 'ReducersMapObject'.
    let topReducer: Reducer<TState, TActions> = combineReducers(reducers);

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove: Array<string> = [];

    return {
        getReducerMap: () => reducers,

        // The root reducer function exposed by this object
        // This will be passed to the store
        reduce: ((state, action) => {
            // If any reducers have been removed, clean up their state first
            let newState = state;

            if (keysToRemove.length > 0) {
                newState = { ...state };
                keysToRemove.forEach((key) => {
                    delete newState[key];
                });

                keysToRemove = [];
            }

            // Delegate to the combined reducer
            return topReducer(state, action);
        }) as Reducer<TState, TActions>,

        // Adds a new reducer with the specified key
        add: (key: string, reducer: Reducer<TState, TActions>) => {
            if (!key || reducers[key]) {
                return;
            }

            // Add the reducer to the reducer mapping
            // @ts-expect-error - TS2542 - Index signature in type '{ readonly [x: string]: Reducer<TState, TActions>; }' only permits reading.
            reducers[key] = reducer;

            // Generate a new combined reducer
            // @ts-expect-error - TS2322 - Type 'Reducer<TState>' is not assignable to type 'Reducer<TState, TActions>'. | TS2345 - Argument of type '{ readonly [x: string]: Reducer<TState, TActions>; }' is not assignable to parameter of type 'ReducersMapObject'.
            topReducer = combineReducers(reducers);
        },

        // Removes a reducer with the specified key
        remove: (key: string) => {
            if (!key || !reducers[key]) {
                return;
            }

            // Remove it from the reducer mapping
            // @ts-expect-error - TS2542 - Index signature in type '{ readonly [x: string]: Reducer<TState, TActions>; }' only permits reading.
            delete reducers[key];

            // Add the key to the list of keys to clean up
            keysToRemove.push(key);

            // Generate a new combined reducer
            // @ts-expect-error - TS2322 - Type 'Reducer<TState>' is not assignable to type 'Reducer<TState, TActions>'. | TS2345 - Argument of type '{ readonly [x: string]: Reducer<TState, TActions>; }' is not assignable to parameter of type 'ReducersMapObject'.
            topReducer = combineReducers(reducers);
        },
    };
}

export default createReducerManager;
