import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import type { ActionButton } from 'horizontal/chat/types';

import styles from './styles/quickActionButtons.cssm';

type Props = {
    actionButtons: Array<ActionButton>;
    isMessageFromUser: boolean;
};

const QuickActionButtons = ({ actionButtons, isMessageFromUser }: Props) => (
    <Flex className={classNames(styles.container, { [styles.right]: isMessageFromUser })}>
        {actionButtons.map(({ label, onClick }) => (
            <div key={label} onClick={onClick} className={styles.actionButton}>
                {label}
            </div>
        ))}
    </Flex>
);
export default QuickActionButtons;
