import { ThirdPartyProvider } from './types';

const determineKeycloakThirdPartyProviderID = (provider: ThirdPartyProvider): string => {
    switch (provider) {
        case ThirdPartyProvider.FACEBOOK:
            return CONFIG.runtime.KEYCLOAK_FACEBOOK_PROVIDER_NAME;

        case ThirdPartyProvider.GOOGLE:
            return 'google';

        default:
            return provider as string;
    }
};

export default determineKeycloakThirdPartyProviderID;
