import * as React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';

import type { Range } from 'strat/types';

import { selectNumberFormatterFactory } from './language/selectors';
import { Unit, UnitType } from './units';
import Area from './area';
import convertRange from './convertRange';
import {
    toLocalUnits as toLocalUnitsBase,
    roundLocalUnitsValue as roundLocalUnitsValueBase,
    areaInLocalUnits as areaInLocalUnitsBase,
    formatLocalUnitsValue as formatLocalUnitsValueBase,
} from './localUnitsFormatting';

type UseLocalUnits = {
    areaUnit: Values<typeof Area>;
    isDefaultAreaUnit: boolean;
    // @ts-expect-error - TS2749 - 'UnitType' refers to a value, but is being used as a type here. Did you mean 'typeof UnitType'?
    currentUnits: Partial<Record<UnitType, typeof UnitType>>;
    convertRange: (
        value: Range | null,
        fromUnit: string,
        toUnit: string,
    ) => Range | null | undefined;
    areaInLocalUnits: (
        value: number,
        unit?: Values<typeof Area>,
        currentUnit?: string | null,
    ) => number;
    areaInBaseUnits: (
        value: number,
        currentUnit?: string | null,
        unit?: Values<typeof Area>,
    ) => number;
    toLocalUnits: (
        value: number,
        unitType: Values<typeof UnitType>,
        baseUnit: Values<typeof Unit>,
        currentUnit?: string | null,
    ) => number;
    formatLocalUnitsValue: (
        value: number,
        unitType: Values<typeof UnitType> | null,
        unit: Values<(typeof UnitType)['Area']> | null | undefined,
    ) => string;
    roundLocalUnitsValue: (
        value: number,
        unitType?: ExtractValues<typeof UnitType>,
        unit?: Values<typeof Area>,
    ) => number;
};

const selectCurrentUnits = createSelector(settings.selectAreaUnit, ({ areaUnit }) => ({
    // @ts-expect-error - TS2464 - A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
    [Area]: areaUnit,
}));

const useLocalUnits = (): UseLocalUnits => {
    const { areaUnit, isDefaultAreaUnit } = useSelector(settings.selectAreaUnit);
    const currentUnits = useSelector(selectCurrentUnits);
    const numbetFormatterFactory = useSelector(selectNumberFormatterFactory);

    const areaInLocalUnits = React.useCallback(
        (value: number, unit = Area.SQM, currentUnit = null) =>
            areaInLocalUnitsBase(value, unit, currentUnit || areaUnit),
        [areaUnit],
    );

    const areaInBaseUnits = React.useCallback(
        (value: number, currentUnit = areaUnit, unit = Area.SQM) =>
            areaInLocalUnitsBase(value, currentUnit, unit),
        [areaUnit],
    );

    const toLocalUnits = React.useCallback(
        (value: number, unitType, baseUnit, convertedUnit = null): number =>
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
            toLocalUnitsBase(value, unitType, baseUnit, convertedUnit || currentUnits[unitType]),
        [currentUnits],
    );

    const formatLocalUnitsValue = React.useCallback(
        (value: number, unitType = null, unit = null): string =>
            formatLocalUnitsValueBase(value, unitType, unit, numbetFormatterFactory),
        [numbetFormatterFactory],
    );

    const roundLocalUnitsValue = React.useCallback(
        (value: number, unitType = null, unit = null): number =>
            roundLocalUnitsValueBase(value, unitType, unit),
        [],
    );

    return React.useMemo(
        () => ({
            areaUnit,
            isDefaultAreaUnit,
            currentUnits,
            convertRange,
            areaInBaseUnits,
            areaInLocalUnits,
            toLocalUnits,
            formatLocalUnitsValue,
            roundLocalUnitsValue,
        }),
        [
            areaUnit,
            isDefaultAreaUnit,
            currentUnits,
            areaInBaseUnits,
            areaInLocalUnits,
            toLocalUnits,
            formatLocalUnitsValue,
            roundLocalUnitsValue,
        ],
    );
};

export default useLocalUnits;
