import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

import {
    BirthDatePart,
    PartClickedActionType,
    PartSelectedActionType,
    DateUpdatedActionType,
} from './types';
import type { DatePickerState } from './types';
import DatePartPicker from './datePartPicker';
import { DatePickerInitialState, DatePickerReducer } from './datePickerReducer';
import styles from './styles/datePicker.cssm';

type BirthDateType = Partial<Record<Values<typeof BirthDatePart>, number | null>>;

type Props = {
    readonly title: string;
    readonly initialDate?: BirthDateType;
    readonly onDatePicked: (date: BirthDateType) => void;
};

const genenrateInitialState = (initialDate?: BirthDateType | null): DatePickerState => {
    if (!initialDate) {
        return DatePickerInitialState;
    }

    return {
        day: {
            // @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'number | null'.
            value: initialDate.day,
            isOpened: false,
        },
        month: {
            // @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'number | null'.
            value: initialDate.month,
            isOpened: false,
        },
        year: {
            // @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'number | null'.
            value: initialDate.year,
            isOpened: false,
        },
    };
};

const DatePicker = ({ title, initialDate, onDatePicked }: Props) => {
    const i18n = useI18n();
    const [state, dispatch] = React.useReducer(
        DatePickerReducer,
        genenrateInitialState(initialDate),
    );

    React.useEffect(() => {
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
        if (state.year.value || state.month.value || state.day.value) {
            onDatePicked({
                // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                day: state.day.value,
                // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                month: state.month.value,
                // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                year: state.year.value,
            });
        }
    }, [state, onDatePicked]);

    React.useEffect(() => {
        if (
            initialDate &&
            initialDate.year &&
            initialDate.month &&
            initialDate.day &&
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            initialDate.year !== state.year.value &&
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            initialDate.month !== state.month.value &&
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            initialDate.day !== state.day.value
        ) {
            dispatch({
                type: DateUpdatedActionType,
                payload: {
                    year: initialDate.year,
                    month: initialDate.month,
                    day: initialDate.day,
                },
            });
        }
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
    }, [initialDate, state.day.value, state.month.value, state.year.value]);

    return (
        <div className={styles.datePickerRoot}>
            <div className={styles.subsectionTitle}>
                <Text.Regular bold>{title}</Text.Regular>
            </div>
            <div className={styles.datePickerPartsContainer}>
                <DatePartPicker
                    part={BirthDatePart.day}
                    placeholder={t(i18n)`DD`}
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    isOpened={state.day.isOpened}
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    value={state.day.value}
                    onSelect={(part, value) => {
                        const action: {
                            readonly type: typeof PartSelectedActionType;
                            readonly payload: {
                                part: Values<typeof BirthDatePart>;
                                value: number;
                            };
                        } = {
                            type: PartSelectedActionType,
                            payload: { part, value },
                        };
                        dispatch(action);
                    }}
                    onClick={(part, isOpened) => {
                        const action: {
                            readonly type: typeof PartClickedActionType;
                            readonly payload: {
                                part: Values<typeof BirthDatePart>;
                                isOpened: boolean;
                            };
                        } = {
                            type: PartClickedActionType,
                            payload: { part, isOpened },
                        };
                        dispatch(action);
                    }}
                />
                <DatePartPicker
                    part={BirthDatePart.month}
                    placeholder={t(i18n)`MM`}
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    isOpened={state.month.isOpened}
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    value={state.month.value}
                    onSelect={(part, value) => {
                        const action: {
                            readonly type: typeof PartSelectedActionType;
                            readonly payload: {
                                part: Values<typeof BirthDatePart>;
                                value: number;
                            };
                        } = {
                            type: PartSelectedActionType,
                            payload: { part, value },
                        };
                        dispatch(action);
                    }}
                    onClick={(part, isOpened) => {
                        const action: {
                            readonly type: typeof PartClickedActionType;
                            readonly payload: {
                                part: Values<typeof BirthDatePart>;
                                isOpened: boolean;
                            };
                        } = {
                            type: PartClickedActionType,
                            payload: { part, isOpened },
                        };
                        dispatch(action);
                    }}
                />
                <DatePartPicker
                    part={BirthDatePart.year}
                    placeholder={t(i18n)`YYYY`}
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    isOpened={state.year.isOpened}
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    value={state.year.value}
                    onSelect={(part, value) => {
                        const action: {
                            readonly type: typeof PartSelectedActionType;
                            readonly payload: {
                                part: Values<typeof BirthDatePart>;
                                value: number;
                            };
                        } = {
                            type: PartSelectedActionType,
                            payload: { part, value },
                        };
                        dispatch(action);
                    }}
                    onClick={(part, isOpened) => {
                        const action: {
                            readonly type: typeof PartClickedActionType;
                            readonly payload: {
                                part: Values<typeof BirthDatePart>;
                                isOpened: boolean;
                            };
                        } = {
                            type: PartClickedActionType,
                            payload: { part, isOpened },
                        };
                        dispatch(action);
                    }}
                />
            </div>
        </div>
    );
};

export default DatePicker;
