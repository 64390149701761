import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import iconPriceTagPNG from 'horizontal/assets/icons/iconPriceTag.png';
import iconPriceTagWEBP from 'horizontal/assets/icons/iconPriceTag.webp';
import { ChoicePageLayout, ChoicePageHeading } from 'horizontal/generic/choicePage';
import ChangeCurrencySection from 'horizontal/businessPackages/changeCurrencySection';
import type { FullAd, ProductPackageOffer } from 'horizontal/types';
import { useRenderErrorIfAccountSuspended } from 'horizontal/user';
import useFormattedPackagesPrice from 'horizontal/businessPackages/useFormattedPackagesPrice';
import useFetchPackagesExchangeRates from 'horizontal/businessPackages/useFetchPackagesExchangeRates';
import getTaxInfoFromOffers from 'horizontal/util/tax';
import useConfigureOfferPackagesSections from 'horizontal/packages/useConfigureOfferPackagesSections';
import { PackagesFlow } from 'horizontal/packages';
import useDefaultPackageSelection from 'horizontal/packages/useDefaultPackageSelection';
import { hasExample } from 'horizontal/packages/utils';

import { PackagesExamples } from '../components';

import ChoicesSection from './choicesSection';
import PromoStrip from './promoStrip';
import useSinglePackages from './useSinglePackages';
import styles from './styles/singlePackages.cssm';
import ErrorBanner from './errorBanner';

type Props = {
    readonly packages: Array<ProductPackageOffer> | null | undefined;
    readonly ad: FullAd | null | undefined;
    readonly withoutSkipButton: boolean;
};

const SinglePackages = ({ packages, ad, withoutSkipButton }: Props) => {
    const offersSections = useConfigureOfferPackagesSections(
        packages,
        PackagesFlow.UPSELLING_AD_FROM_USER_ADS,
    );
    const defaultSelectedOffer = useDefaultPackageSelection(offersSections);
    const {
        i18n,
        category,
        price,
        selectedValue,
        handleClick,
        activeExample,
        openExample,
        closeExample,
        onCheckoutSinglePackages,
        loading,
        checkoutLoading,
        errorMessage,
    } = useSinglePackages(packages, ad, defaultSelectedOffer);
    const country = settings.countryName(i18n);
    const renderErrorIfAccountSuspended = useRenderErrorIfAccountSuspended(
        t(i18n)`Your account has been suspended from buying packages.`,
    );
    const [taxActive, taxPercentage] = getTaxInfoFromOffers(packages);

    useFetchPackagesExchangeRates();

    const formattedTotalPrice = useFormattedPackagesPrice(price || 0);
    if (renderErrorIfAccountSuspended) {
        return renderErrorIfAccountSuspended();
    }
    const categoryName = category?.name || '';

    return (
        <>
            <PackagesExamples activeExample={activeExample} closeExample={closeExample} />
            <ChoicePageLayout
                isLoading={loading || checkoutLoading}
                showCTA={!!selectedValue}
                ctaText={t(i18n)`Pay ${formattedTotalPrice}`}
                onCTAClick={onCheckoutSinglePackages}
                ad={withoutSkipButton ? null : ad}
                taxActive={taxActive}
                taxPercentage={taxPercentage}
            >
                {errorMessage && <ErrorBanner error={errorMessage} />}
                <Flex column className={styles.container} aria-label="Payment dialog">
                    <ChoicePageHeading
                        image={{
                            name: t(i18n)`${categoryName} packages`,
                            source: iconPriceTagPNG,
                            sourceWEBP: iconPriceTagWEBP,
                        }}
                        title={t(i18n)`Reach more buyers and sell faster`}
                        subtitle={t(i18n)`Upgrade your Ad to a top position`}
                        infoStrip={t(
                            i18n,
                        )`Applicable for ${categoryName} category in all ${country}`}
                    />
                    {settings.multipleCurrencyPackages && !!packages && !!packages.length ? (
                        <Flex className={styles.container}>
                            <ChangeCurrencySection />
                        </Flex>
                    ) : null}
                    {offersSections && (
                        <>
                            {offersSections.map((section) => (
                                <ChoicesSection
                                    ad={ad}
                                    onClick={handleClick}
                                    packages={section.items}
                                    selectedValue={selectedValue}
                                    title={section.title}
                                    valuePropositions={
                                        section.valuePropositions ?? [section.description]
                                    }
                                    onClickSeeExample={
                                        hasExample(section.packageType)
                                            ? () => openExample(section.packageType)
                                            : null
                                    }
                                />
                            ))}
                        </>
                    )}
                    <PromoStrip
                        text={t(i18n)`Heavy discount on Business Packages`}
                        ad={ad}
                        availableAdPackages={packages}
                    />
                </Flex>
            </ChoicePageLayout>
        </>
    );
};

export default SinglePackages;
