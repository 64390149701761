import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';

import { ChatNavAction, ChatSubjectType } from 'horizontal/chat/constants';
import type { ChatSubjectXID, ChatUserXID } from 'horizontal/chat/types';
import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';

const BaseChatRoute = '/chat';

export type StratChatRoomRouteParams = Partial<{
    readonly contactXID: ChatUserXID;
    readonly subjectXID: ChatSubjectXID;
    readonly navAction: Values<typeof ChatNavAction>;
    readonly makeOffer?: boolean | null | undefined;
}>;

class ChatRoute extends Route {
    constructor() {
        super(RouteNames.CHAT, [
            ['^', BaseChatRoute, '$'],
            [
                '^',
                BaseChatRoute,
                '/',
                { name: 'navAction', pattern: `(${Object.values(ChatNavAction).join('|')})` },
                '/',
                { name: 'contactXID', pattern: '([A-Za-z0-9-]+)' },
                '/',
                { name: 'subjectXID', pattern: '([A-z0-9-]+)' },
            ],
            [
                '^',
                BaseChatRoute,
                '/',
                { name: 'subjectXID', pattern: '([A-z0-9-]+)' },
                '/',
                { name: 'subjectType', pattern: `(${Object.values(ChatSubjectType).join('|')})` },
                '/',
                { name: 'contactXID', pattern: '([A-Za-z0-9-]+)' },
            ],
        ]);
    }

    createURL(params: StratChatRoomRouteParams): EnhancedLocation {
        const search: Record<string, any> = {};
        if (params.makeOffer) {
            search.makeOffer = true;
        }

        if (params.subjectXID && params.navAction && params.contactXID) {
            return {
                pathname: `${BaseChatRoute}/${params.navAction}/${params.contactXID}/${params.subjectXID}`,
                search,
            };
        }

        return { pathname: BaseChatRoute, search, hash: {} };
    }

    onEnter(context: any): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        context.rendering.renderPage(Page.CHAT);
    }
}

export default new ChatRoute();
