import * as React from 'react';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import { UserSettings, UserSettingsSection } from 'horizontal/userSettings';
import Footer from 'horizontal/footer';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

import styles from './styles/userSettingsPageDesktop.cssm';
import UserSettingsHead from './userSettingsHead';

type Props = {
    readonly section?: Values<typeof UserSettingsSection>;
};

const UserSettingsPageDesktop = {
    head: () => (
        <>
            <UserSettingsHead />
            <GTMDataLayer pageType={PageType.SETTINGS} />
        </>
    ),
    body: ({ section }: Props) => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <Flex fillContainer stretchWidth column className={styles.container}>
                <UserSettings section={section} />
            </Flex>
            <Footer />
        </Flex>
    ),
} as const;

export default UserSettingsPageDesktop;
