import * as React from 'react';

const useIsScrollingUp = (): boolean => {
    let oldScrollY = 0;

    const [isScrollUp, setIsScrollUp] = React.useState(false);

    const controlDirection = () => {
        if (window.scrollY > oldScrollY) {
            setIsScrollUp(false);
        } else {
            setIsScrollUp(true);
        }
        oldScrollY = window.scrollY;
    };

    React.useEffect(() => {
        window.addEventListener('scroll', controlDirection, { passive: true });
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isScrollUp;
};

export default useIsScrollingUp;
