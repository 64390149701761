import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { TemplateType } from 'strat/property';

import type { MixedAd } from 'horizontal/types';
import { selectCustomMetaTitle } from 'horizontal/urlSpecificSEO/selectors';

import useAdDetailTemplate from './useAdDetailTemplate';

const useTitle = (ad?: MixedAd | null): string => {
    const i18n = useI18n();
    const domainName = settings.getDomainName(i18n);
    const customTitle = useSelector(selectCustomMetaTitle);
    const renderedTemplate = useAdDetailTemplate(TemplateType.TITLE, ad);

    if (!ad) {
        return t(i18n)`Item | ${domainName}`;
    }
    // a custom title takes precedence over a renderedTemplate, which in turn takes precedence over the default naming scheme
    if (customTitle) {
        return customTitle;
    }
    if (renderedTemplate) {
        return renderedTemplate;
    }

    const categoryName = ad.category.slice(-1)[0]?.name;

    if (categoryName) {
        return t(i18n)`${ad.title} - ${categoryName} - ${ad.externalID}`;
    }

    return t(i18n)`${ad.title} - ${ad.externalID}`;
};

export default useTitle;
