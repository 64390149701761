import * as React from 'react';
import { useSelector } from 'react-redux';
import selectWallpaperTakeover from 'strat/wallpaper/selectors';

import { useGetWallpaperForSize } from 'horizontal/util';

import useWallpaperRemover from './useWallpaperRemover';
import styles from './styles/wallpaper.cssm';

type Props = {
    children: React.ReactNode;
};

const Wallpaper = (props: Props) => {
    const wallpaper = useSelector(selectWallpaperTakeover);
    const imageUrl = useGetWallpaperForSize(wallpaper);
    const backgroundStyle = { backgroundImage: `url("${imageUrl}")` } as const;

    useWallpaperRemover();

    return (
        <header>
            <div
                title={wallpaper.title || ''}
                className={styles.wallpaper}
                aria-label="Wallpaper Ad Body"
            >
                {imageUrl ? (
                    <>
                        <a
                            className={styles.wallpaperImg}
                            style={backgroundStyle}
                            href={wallpaper.targetURL || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Wallpaper Ad Link Image"
                        />
                        <div
                            className={styles.wallpaperTopSpace}
                            aria-label="Wallpaper Ad Top Space"
                        />{' '}
                    </>
                ) : null}
                {props.children}
            </div>
        </header>
    );
};

export default Wallpaper;
