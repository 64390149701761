import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';

import { NoImage } from 'horizontal/image';

import styles from './styles/imagePreview.cssm';

type Props = {
    readonly imageUrl: string | null;
    readonly onClick?: (arg1: React.SyntheticEvent<HTMLDivElement>) => void;
    readonly moreImageCount?: number;
};

const ImagePreview = ({ imageUrl, moreImageCount, onClick }: Props) => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const onLoad = React.useCallback(() => {
        setIsLoaded(true);
    }, []);

    const onError = React.useCallback(() => {
        setIsError(true);
    }, []);

    if (isError || !imageUrl) {
        return (
            <div className={styles.container}>
                <div className={styles.placeholder}>
                    <NoImage />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {imageUrl && moreImageCount && isLoaded ? (
                <div className={styles.moreOverlay} onClick={onClick}>
                    <Text.Large bold>+{moreImageCount}</Text.Large>
                </div>
            ) : null}
            <img
                onClick={onClick}
                className={classNames(styles.image, { [styles.forceImageVisible]: isLoaded })}
                src={imageUrl}
                alt={''}
                onLoad={onLoad}
                onError={onError}
            />
        </div>
    );
};

export default React.memo<Props>(ImagePreview);
