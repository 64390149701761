import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import useI18n from 'strat/i18n/language/useI18n';
import { Flex, LoadingSpinnerOverlay, Text, Button } from 'strat/components';

import type { Ad } from 'horizontal/types';
import { trackClickPreviewAd } from 'horizontal/gtm';
import { useNavigateToAdDetails, useNavigateToAdDetailsFromPortal } from 'horizontal/adDetails';
import useIsInAgencyPortal from 'horizontal/user/useIsInAgencyPortal';

import CTASection from './ctaSection';
import styles from './styles/choicePageLayout.cssm';

type Props = {
    isLoading: boolean;
    showCTA?: boolean;
    ctaText?: string;
    onCTAClick?: () => void;
    children?: Array<any> | any | React.ReactNode;
    readonly ad: Ad | null | undefined;
    taxActive?: boolean;
    taxPercentage?: number;
};

const ChoicePageLayout = ({
    isLoading,
    showCTA,
    onCTAClick,
    ctaText,
    children,
    ad,
    taxActive = false,
    taxPercentage = 0,
}: Props) => {
    const i18n = useI18n();
    const isInAgencyPortal = useIsInAgencyPortal();
    const navigateToAdDetails = useNavigateToAdDetails(ad);
    const navigateToAdDetailsFromPortal = useNavigateToAdDetailsFromPortal(ad);
    const onViewAdButtonClick = () => {
        trackClickPreviewAd(ad);
        if (isInAgencyPortal) {
            navigateToAdDetailsFromPortal();
            return;
        }
        navigateToAdDetails();
    };
    const CTAClassName = !ad ? styles.singleChoice : '';
    return (
        <>
            <LoadingSpinnerOverlay visible={isLoading} />
            {!isLoading && (
                <Flex column className={styles.content}>
                    {children}
                    {showCTA && (
                        <>
                            <Flex
                                column
                                className={classNames(styles.bottomContainer, {
                                    [styles.taxText]: taxActive,
                                })}
                            >
                                {taxActive && (
                                    <Text.XSmall>{t(
                                        i18n,
                                    )`These prices are exclusive of tax, ${taxPercentage}% tax will be charged on checkout`}</Text.XSmall>
                                )}
                                <Flex className={styles.buttonsContainer}>
                                    {ad && (
                                        <Flex justifyCenter className={styles.viewAdButton}>
                                            <Button tertiary onClick={onViewAdButtonClick}>
                                                {t(i18n)`Skip, View Your Ad`}
                                            </Button>
                                        </Flex>
                                    )}
                                    <CTASection
                                        className={CTAClassName}
                                        buttonText={ctaText}
                                        onClick={onCTAClick}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Flex>
            )}
        </>
    );
};

export default ChoicePageLayout;
