import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

import Page from 'strat/pages/page';

import { DisplayCategory } from './displayCategory';
import type { BreadcrumbsOptions, BreadcrumbsElement, BreadcrumbsData } from './types';

const computeBreadcrumbs = (
    i18n: I18n,
    data: BreadcrumbsData,
    options: BreadcrumbsOptions,
): BreadcrumbsElement[] => {
    const { elements, lastElement, purpose, rentFrequency, category, beds } = data;
    const {
        leaf,
        withHomepage,
        displayCategory,
        computeElementTitle,
        computeElementName,
        computeParameters,
    } = options;

    const slicedElements = elements.slice(0, CONFIG.build.AD_LOCATION_BREADCRUMB_URL_MAX_LEVEL);
    const categoryName = category?.name;
    const categorySlug = category?.slug;

    // Deep copy the elements because the locations are used also in other parts of the application
    const breadcrumbsElements: Array<BreadcrumbsElement> = slicedElements.map((element) =>
        Object.assign({}, element),
    );

    // Include the homepage in the list of elements
    if (withHomepage) {
        const brandName = settings.getBrandName(i18n);
        breadcrumbsElements.unshift({
            route: Page.HOME,
            name: brandName,
            title: brandName,
            slug: '/',
            hard: false,
        });
    }

    /**
     * Append the `lastElement` to the list of breadcrumbsElements
     *
     * Since the `lastElement`` can be a string, it needs to be "transformed"
     * into an object according to BreadcrumbElement definition.
     *
     * The `disabled` property is true because the lastElement represents
     * the current location in the breadcrumbs and it doesn't redirect anywhere.
     */
    if (lastElement) {
        breadcrumbsElements.push(
            // @ts-expect-error - TS2345 - Argument of type '(BreadcrumbsElement | { name: never; }) & { disabled: boolean; }' is not assignable to parameter of type 'BreadcrumbsElement'.
            Object.assign(
                {},
                typeof lastElement === 'object' ? lastElement : { name: lastElement },
                {
                    disabled: true,
                },
            ),
        );
    }

    /**
     *  Override the data in the element based on DisplayCategory value
     */
    if (displayCategory !== DisplayCategory.NONE) {
        if (!category) {
            throw new Error(
                "Cannot use displayCategory option because category parameter doesn't have a value",
            );
        }

        const noElements = breadcrumbsElements.length - 1;
        let index = -1;
        if (displayCategory === DisplayCategory.LAST) {
            index = noElements;
        }
        if (displayCategory === DisplayCategory.BEFORE_LAST) {
            index = noElements - 1;
        }

        breadcrumbsElements[index] = Object.assign({}, breadcrumbsElements[index], {
            category: !leaf ? category : undefined,
        });
    }

    return breadcrumbsElements.map<BreadcrumbsElement>((element: BreadcrumbsElement) => ({
        ...element,
        title: computeElementTitle(element, categoryName, purpose, i18n),
        name: computeElementName(element, categoryName, purpose, withHomepage, i18n),
        params:
            element.params ||
            computeParameters(element, categorySlug, purpose, rentFrequency, beds),
    }));
};

export default computeBreadcrumbs;
