import * as React from 'react';
import FormikProps from 'formik';
import BaseSellerFields from 'horizontal/adCreation/sellerFields';
import { useActiveUser } from 'strat/user/session';

// @ts-expect-error - TS2709 - Cannot use namespace 'FormikProps' as a type.
type Props = FormikProps & {
    readonly isPhoneEditDisabled: boolean;
};

const SellerFields = (props: Props) => {
    const user = useActiveUser();

    return <BaseSellerFields {...props} isPhoneEditDisabled={!!user?.phoneNumber} />;
};

export default SellerFields;
