import * as React from 'react';
import { Trans } from '@lingui/macro';
import useSafetyRules from '@app/ad/useSafteyRules';
import { Flex } from 'strat/components';

import { Section } from '../section';

import styles from './styles/safetyRules.cssm';

const SafetyRules = () => {
    const safetyRulesList = useSafetyRules();
    if (!safetyRulesList) {
        return null;
    }

    return (
        <Section>
            <Flex column justifyCenter>
                <div className={styles.title}>
                    <Trans>Your safety matters to us!</Trans>
                </div>
                <div className={styles.sentenceContainer}>
                    <ul className={styles.sentences}>
                        {/*@ts-ignore*/}
                        {safetyRulesList.map((sentence) => (
                            <li key={sentence}>{sentence}</li>
                        ))}
                    </ul>
                </div>
            </Flex>
        </Section>
    );
};

export default SafetyRules;
