import { createSelector } from 'reselect';
import settings from '@app/branding/settings';
import EMPTY_ARRAY from 'strat/empty/array';

import type { GlobalState } from 'horizontal/state';

const selectBusinessPackageCartOffers = (state: GlobalState) =>
    state.businessPackageCart?.businessPackageCartOffers?.sort(
        (a, b) => parseInt(a.packageOfferID, 10) - parseInt(b.packageOfferID, 10),
    ) || EMPTY_ARRAY;

const selectIsBusinessPackageCartEmpty = createSelector(
    selectBusinessPackageCartOffers,
    (offers) => !offers.length,
);

const selectBusinessPackageCartOfferIDs = createSelector(
    selectBusinessPackageCartOffers,
    (offers) => offers.map((businessPackageOffer) => businessPackageOffer?.packageOfferID),
);

const selectBusinessPackageCartTotalPriceWithoutDiscount = createSelector(
    selectBusinessPackageCartOffers,
    (offers: any[]): number =>
        offers.reduce(
            (acc, currentOffer) => acc + currentOffer.price * (currentOffer.cartQuantity || 1),
            0,
        ),
);

const selectBusinessPackageCartTotalDiscountPrice = createSelector(
    selectBusinessPackageCartOffers,
    (offers: any[]): number =>
        offers.reduce(
            (acc, currentOffer) =>
                acc +
                (currentOffer.price - (currentOffer.discountPrice || currentOffer.price)) *
                    (currentOffer.cartQuantity ?? 1),
            0,
        ),
);

const selectBusinessPackageCartTotalPrice = createSelector(
    selectBusinessPackageCartOffers,
    (offers: any[]): number =>
        offers.reduce(
            (acc, currentOffer) =>
                acc +
                (currentOffer.discountPrice || currentOffer.price) *
                    (currentOffer.cartQuantity ?? 1),
            0,
        ),
);

const selectBusinessPackageCartTotalPriceWithTax = createSelector(
    selectBusinessPackageCartOffers,
    (offers: any[]): number =>
        offers.reduce(
            (acc, currentOffer) => acc + currentOffer.grossPrice * (currentOffer.cartQuantity ?? 1),
            0,
        ),
);

const selectBusinessPackageCartParams = createSelector(
    selectBusinessPackageCartOffers,
    (offers) => {
        if (!offers.length) {
            return null;
        }
        const firstOffer = offers[0];
        const firstOfferCategory = 'categoryID' in firstOffer ? firstOffer.categoryID : null;
        const firstOfferLocationID = 'locationID' in firstOffer ? firstOffer.categoryID : null;
        return {
            categoryExternalID: firstOfferCategory,
            locationExternalID: firstOfferLocationID || settings.topLevelLocation.externalID,
        };
    },
);

const selectBusinessPackageCartOfferAdID = (state: GlobalState) =>
    state.businessPackageCart.adExternalID;

export {
    selectBusinessPackageCartOffers,
    selectIsBusinessPackageCartEmpty,
    selectBusinessPackageCartOfferIDs,
    selectBusinessPackageCartTotalPriceWithoutDiscount,
    selectBusinessPackageCartTotalDiscountPrice,
    selectBusinessPackageCartTotalPrice,
    selectBusinessPackageCartTotalPriceWithTax,
    selectBusinessPackageCartParams,
    selectBusinessPackageCartOfferAdID,
};
