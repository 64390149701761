import * as React from 'react';
import { Flex } from 'strat/components';

import IconCalendar from 'horizontal/assets/icons/iconCalendar.svg';
import IconRoad from 'horizontal/assets/icons/iconRoad.svg';
import IconFuel from 'horizontal/assets/icons/iconFuel.svg';
import { BasicTag } from 'horizontal/adCard/tags';
import { AdCommonExtraField } from 'horizontal/branding/adExtraFields';
import type { LiteAd } from 'horizontal/types';

import useFormatAdExtraFieldsToObject from './useFormatAdExtraFieldsToObject';
import useGetCarMileage from './useGetCarMileage';
import styles from './styles/adSubtitleUpdatedDesign.cssm';

type Props = {
    readonly ad: LiteAd;
};

const AdCarSubtitle = ({ ad }: Props) => {
    const extraFields = useFormatAdExtraFieldsToObject(ad);

    const year = extraFields[AdCommonExtraField.YEAR];
    const fuelType = extraFields[AdCommonExtraField.VEHICLE_FUEL_TYPE];
    const mileage = useGetCarMileage(
        ad.extraFields?.[AdCommonExtraField.USAGE_CONDITION],
        extraFields[AdCommonExtraField.VEHICLE_MILEAGE],
    );

    return (
        <Flex className={styles.container}>
            {!!year && (
                <BasicTag
                    text={year}
                    renderIcon={() => <IconCalendar className={styles.icon} />}
                    ariaLabel={'Year'}
                />
            )}
            {!!mileage && (
                <BasicTag
                    text={mileage}
                    renderIcon={() => <IconRoad className={styles.icon} />}
                    ariaLabel={'Mileage'}
                    withEllipsis
                />
            )}
            {!!fuelType && (
                <BasicTag
                    text={fuelType}
                    renderIcon={() => <IconFuel className={styles.icon} />}
                    ariaLabel={'Fuel Type'}
                    withEllipsis
                    disableTextTransformation
                />
            )}
        </Flex>
    );
};

export default AdCarSubtitle;
