import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { AdBanner } from 'strat/tagmanager';
import type { Size } from 'strat/tagmanager';

import { useCategoryFilterValue } from 'horizontal/search/category';
import { findDeepList } from 'horizontal/util/findDeep';
import { selectCategories } from 'horizontal/categories';
import { useIsSearchLoading } from 'horizontal/search/hooks';

import useAdUnitName from './useAdUnitName';
import { PageType, Position } from './types';
import useSearchTargetingParams from './useSearchTargetingParams';
import styles from './styles/searchBanner.cssm';

type Props = {
    readonly sizes?: Array<Size>;
    readonly position: Values<typeof Position>;
    readonly slotIndex?: number;
    readonly nativeAd?: boolean;
    readonly disableStyle?: boolean;
};

const SearchBanner = ({ sizes, position, slotIndex, nativeAd, disableStyle = false }: Props) => {
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);
    const categoryHierarchy = React.useMemo(
        () => findDeepList(categories, (category) => category.id === activeCategory?.id, true),
        [categories, activeCategory],
    );

    const name = useAdUnitName(PageType.SEARCH, categoryHierarchy);
    const targetingParams = useSearchTargetingParams(position, slotIndex, categoryHierarchy);

    const isSearchLoading = useIsSearchLoading();
    if (isSearchLoading) {
        return null;
    }

    return (
        <AdBanner
            name={name}
            sizes={sizes}
            parameters={targetingParams}
            index={slotIndex}
            className={
                !disableStyle
                    ? classNames(styles.banner, { [styles.nativeAd]: nativeAd })
                    : styles.noLineHeight
            }
            collapsible
        />
    );
};

export default SearchBanner;
