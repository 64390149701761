import { ExactFilter } from '@sector-labs/fe-search-redux/filters';

import { StratAPI } from 'strat/api';
import type { AreaGuide } from 'strat/areaGuide/types';
import type { AreaGuideParams } from 'strat/areaGuide/state';
import Purpose from 'strat/purpose';
import fetchFromElasticSearch from 'strat/search/elasticSearch/fetchFromElasticSearch';
import { isOfCommercialPropertyType } from 'strat/branding/propertyTypes';
import { APIResponse } from 'strat/api/types';
import { PropertyCompletionStatus } from 'strat/property/types';
import { LocationCompletionStatus } from 'strat/locations/types';

import { AreaGuideContentMapping } from './types';
import { NUMBER_OF_HITS_PLACEHOLDER } from './constants';
import Category from './category';

const NUMBER_OF_HITS_KEY = 'ActiveListings';
const AGGREGATE_PLACEHOLDER = 'total';
const NOT_AVAILABLE_PLACEHOLDER = 'na';
const UPPERCASE_ENDING = '_up';
const LOWERCASE_ENDING = '_lo';
const UNIT_OPEN_TAG = '{unit}';
const UNIT_CLOSE_TAG = '{/unit}';
const reReplaceParameter = /{{([\w\\.]*)}}/;
const reAreaGuideUnit = new RegExp(`(${UNIT_OPEN_TAG}[\\S\\s]*?${UNIT_CLOSE_TAG})`);
const completionStatusMapping = Object.freeze({
    [PropertyCompletionStatus.ANY]: LocationCompletionStatus.MIXED,
    [PropertyCompletionStatus.COMPLETED]: LocationCompletionStatus.READY,
    [PropertyCompletionStatus.OFF_PLAN]: LocationCompletionStatus.OFF_PLAN,
});

const containsPlaceholders = (data: AreaGuide) => {
    if (!data) {
        return false;
    }
    if (!data.body) {
        return false;
    }
    return data.body.includes('{{') && data.body.includes('}}');
};

const getParamValue = (paramName: string, params: Record<string, any>) => {
    if (!(paramName.endsWith(UPPERCASE_ENDING) || paramName.endsWith(LOWERCASE_ENDING))) {
        return params[paramName];
    }
    const name = paramName.replace(UPPERCASE_ENDING, '').replace(LOWERCASE_ENDING, '');
    if (!params[name]) {
        return '';
    }
    const words = params[name].split(' ');

    return words
        .map((word: string) => {
            let firstLetter = word[0];
            if (paramName.endsWith(UPPERCASE_ENDING)) {
                firstLetter = word[0].toUpperCase();
            }
            if (paramName.endsWith(LOWERCASE_ENDING)) {
                firstLetter = word[0].toLowerCase();
            }
            return firstLetter + word.substring(1);
        })
        .join(' ');
};

const replaceParameters = (text: string, params: Record<string, any>, isUnit: boolean): string => {
    let result = text;
    let match = result.match(reReplaceParameter);
    while (match) {
        const [paramRule, paramName] = match;
        const paramValue = getParamValue(paramName, params);

        if (isUnit && !paramValue) {
            return '';
        }

        result = result.replaceAll(paramRule, paramValue);
        match = result.match(reReplaceParameter);
    }
    return result;
};

const processTemplate = (text: string, params: Record<string, any>): string => {
    const sections = text.split(reAreaGuideUnit).map((section: string) => {
        if (section.startsWith(UNIT_OPEN_TAG)) {
            return replaceParameters(section, params, true)
                .replaceAll(UNIT_OPEN_TAG, '')
                .replaceAll(UNIT_CLOSE_TAG, '');
        }
        return replaceParameters(section, params, false);
    });
    return sections.join('');
};

const replacePlaceholdersWithAreaGuideContent = (
    response: APIResponse<Array<AreaGuide>>,
    purpose: string,
    category: string,
    location: string,
    bedType: string,
    propertyUsage: string,
    language: string,
) => {
    const purposeID = Purpose.id(purpose);
    return fetchFromElasticSearch(CONFIG.runtime.STRAT_INDEX_AREAGUIDE_CONTENT, {}, {}, {}, [
        new ExactFilter({
            attribute: 'bayut_purpose_id',
            value: purposeID,
        }),
        new ExactFilter({
            attribute: 'property_type_url_slug',
            value: category,
        }),
        new ExactFilter({
            attribute: 'location_url_slug',
            value: location,
        }),
        new ExactFilter({
            attribute: 'bed_type_id',
            value: bedType,
        }),
        new ExactFilter({
            attribute: 'bayut_property_usage_id',
            value: propertyUsage,
        }),
    ])
        .then((data) => {
            const content = data?.hits?.[0];
            const replaceParameters: Record<string, any> = {};
            for (const [key, value] of Object.entries(AreaGuideContentMapping)) {
                const langKey = key + '_' + language;
                replaceParameters[value] = content[langKey] || content[key];
            }
            replaceParameters[NUMBER_OF_HITS_KEY] = NUMBER_OF_HITS_PLACEHOLDER;
            const areaGuideBody = processTemplate(response.data[0].body, replaceParameters) || '';
            if (!areaGuideBody || areaGuideBody === '<p></p>') {
                return {
                    data: null,
                    status: 204,
                };
            }
            response.data[0].body = areaGuideBody;
            response.data[0].title =
                processTemplate(response.data[0].title, replaceParameters) || '';
            return response;
        })
        .catch(() => ({
            data: null,
            status: 404,
        }));
};

const areaGuideFetcher = (params: AreaGuideParams, state: any) => {
    if (params.isLongtailPage || params.page !== 1) {
        return Promise.resolve({ data: null, status: 200 });
    }

    if (params.customSearchPageSlug) {
        return new StratAPI(state.i18n.language).areaGuideBySlug(params.customSearchPageSlug);
    }

    return new StratAPI(state.i18n.language)
        .areaGuide(
            params.purpose,
            params.location,
            params.category,
            params.beds.length > 0 ? parseInt(params.beds[0]) : null,
            completionStatusMapping[params.completionStatus],
        )
        .then((response) => {
            let propertyUsageId: string = Category.RESIDENTIAL;
            let bedType =
                params.beds.length > 0 ? params.beds[0].toString() : AGGREGATE_PLACEHOLDER;
            if (isOfCommercialPropertyType(params.category)) {
                propertyUsageId = Category.COMMERCIAL;
                bedType = NOT_AVAILABLE_PLACEHOLDER;
            }
            if (
                params.category === Category.RESIDENTIAL ||
                params.category === Category.COMMERCIAL
            ) {
                propertyUsageId = params.category;
                bedType = AGGREGATE_PLACEHOLDER;
            }
            if (containsPlaceholders(response.data?.[0])) {
                return replacePlaceholdersWithAreaGuideContent(
                    response,
                    params.purpose,
                    params.category,
                    params.location,
                    bedType,
                    propertyUsageId,
                    state.i18n.language,
                );
            }
            return response;
        });
};

export default areaGuideFetcher;
