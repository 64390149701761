import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';

import styles from './styles/headerLinksWrapper.cssm';
import HeaderLinks from './headerLinks';

const HeaderLinksWrapper = ({ withHighlightedVertical }: { withHighlightedVertical?: boolean }) => {
    return (
        <PageWidthWrapper className={styles.content}>
            <HeaderLinks withHighlightedVertical={withHighlightedVertical} />
        </PageWidthWrapper>
    );
};

export default HeaderLinksWrapper;
