import { FlatCategoryField } from 'strat/types';

import { AdCommonExtraField } from 'horizontal/branding/adExtraFields';

const fieldsRequiringSlider: string[] = [AdCommonExtraField.VEHICLE_MILEAGE];

const showRangeFilterSlider = (field: FlatCategoryField) =>
    fieldsRequiringSlider.includes(field.attribute);

export default showRangeFilterSlider;
