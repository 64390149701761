import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';

const minLengthText = (i18n: I18n, _minLength: number) =>
    t(i18n)`A minimum length of ${_minLength} characters is required. Please edit the field.`;
const maxLengthText = (i18n: I18n, _maxLength: number) =>
    t(i18n)`A maximum length of ${_maxLength} characters is allowed. Please edit the field.`;
const requiredError = (i18n: I18n) => t(i18n)`This field is required`;

function validationSchema(i18n: I18n) {
    return Yup.object().shape({
        name: Yup.string()
            .min(2, minLengthText(i18n, 2))
            .max(50, maxLengthText(i18n, 50))
            .required(requiredError(i18n)),
        location: Yup.string()
            .min(3, minLengthText(i18n, 3))
            .max(50, maxLengthText(i18n, 50))
            .required(requiredError(i18n)),
        addressLineOne: Yup.string()
            .min(2, minLengthText(i18n, 2))
            .max(50, maxLengthText(i18n, 50))
            .required(requiredError(i18n)),
        addressLineTwo: Yup.string()
            .min(2, minLengthText(i18n, 2))
            .max(50, maxLengthText(i18n, 50)),
        notes: Yup.string().max(4096, maxLengthText(i18n, 4096)),
    });
}

export { validationSchema };
