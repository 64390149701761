import type { LiteAd } from 'horizontal/types';

/**
 *  The shuffle algorithm is:
 *  Take 3 from beginning
 *  Take 2 from end
 *  Take next 3 from beginning
 *  Take next 2 from end
 *  And so on
 */
const getShuffledIndices = (length: number): Array<number> =>
    [...Array(length).keys()].map((idx) => {
        const currentBatch = Math.floor(idx / 5);
        const remainder = idx % 5;
        if (remainder < 3) {
            // remainder = 0, 1 or 2 -> take index from the beginning of the array
            return currentBatch * 3 + remainder;
        }
        // remainder = 3 or 4 -> take index from the end of the array
        return length - 2 * currentBatch - (remainder - 2);
    });

const shuffleRelatedAds = (ads: Array<LiteAd>): Array<LiteAd> =>
    getShuffledIndices(ads.length).map((idx) => ads[idx]);

export { getShuffledIndices };
export default shuffleRelatedAds;
