// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';

import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import type { FetchState } from 'strat/fetcher';
import { PropertyData } from 'strat/property';
import type { GlobalState } from 'strat/state';
import { PropertyType } from 'strat/property/types';
import { MapViewName } from 'strat/map/mapStyles';

import { fetchAttributesFromAlgolia } from '../mapBasedSearch/fetchAttributesFromAlgolia';
import type { AttributesFetchedFromAlgolia, MapBasedSearchListing } from '../mapBasedSearch/types';
import { sortListings } from '../mapBasedSearch/sortListings';
import { CommuteSortByValues } from '../mapBasedSearch/commute/sortByOptions';
import { DEFAULT_COMMUTE_TIME } from '../mapBasedSearch/commute/defaultCommuteTime';
import { selectEnabledListingType, selectIsProjectMapSearchActive } from '../selectors';
import {
    PropertyMapCollectionParams,
    fetchPropertyMapCollections,
    getPropertyMapCollectionParams,
} from '../mapBasedSearch/fetchPropertyMapCollections';
import {
    ProjectMapCollectionParams,
    fetchProjectMapCollections,
    getProjectMapCollectionParams,
} from '../mapBasedSearch/project';

const Actions = Object.freeze({
    START_COMPUTING_CLUSTER: 'MAP/START_COMPUTING_CLUSTER',
    SET_ACTIVE_CLUSTER: 'MAP/SET_ACTIVE_CLUSTER',
    SET_ALGOLIA_HITS: 'MAP/SET_ALGOLIA_HITS',
    SET_PAGE: 'MAP/SET_PAGE',
    SET_MAP_POSITION: 'MAP/SET_MAP_POSITION',
    SET_ACTIVE_CLUSTER_ID: 'MAP/SET_ACTIVE_CLUSTER_ID',
    SET_COMMUTE_ACTIVE: 'MAP/SET_COMMUTE_ACTIVE',
    SET_ALGOLIA_HITS_LOADING: 'MAP/SET_ALGOLIA_HITS_LOADING',
    SET_COMMUTE_SORT_BY: 'MAP/SET_COMMUTE_SORT_BY',
    SET_COMMUTE_MAX_TIME: 'MAP/SET_COMMUTE_MAX_TIME',
    SET_PINNED_LISTING: 'MAP/SET_PINNED_LISTING',
    SET_PINNED_LISTING_ID: 'MAP/SET_PINNED_LISTING_ID',
    CLEAR_MAP_DATA: 'MAP/CLEAR_MAP_DATA',
    SET_COLLECTION_TYPE: 'MAP/SET_COLLECTION_TYPE',
    SET_MAP_VIEW: 'MAP/SET_MAP_VIEW',
} as const);

const defaultState = {
    ...buildDefaultState(),
    activeCluster: null,
    clusterId: null,
    activeClusterPosition: null,
    activeClusterLoading: false,
    page: 0,
    algoliaHits: {},
    algoliaHitsLoading: false,
    algoliaHitsLoaded: false,
    mapPosition: null,
    mapView: null,
    commuteActive: false,
    commuteSortBy: CommuteSortByValues.BALANCED,
    commuteMaxTime: DEFAULT_COMMUTE_TIME,
    pinnedListing: null,
    pinnedListingId: null,
    collectionType: null,
} as const;

type Params = PropertyMapCollectionParams | ProjectMapCollectionParams;

export type MapPosition = {
    zoom: number;
    lat: number;
    lng: number;
};

const mapBasedSearchFactory = new FetcherFactory(
    'map',
    (params: Params, state: GlobalState) => {
        const isProjectMapSearchActive = selectIsProjectMapSearchActive(state);
        if (isProjectMapSearchActive) {
            return fetchProjectMapCollections(params as ProjectMapCollectionParams);
        }

        return fetchPropertyMapCollections(params as PropertyMapCollectionParams);
    },
    {
        getParams: (params: Params, getState: GetState) => {
            const isProjectMapSearchActive = selectIsProjectMapSearchActive(getState());
            if (isProjectMapSearchActive) {
                return getProjectMapCollectionParams(getState);
            }

            return getPropertyMapCollectionParams(getState);
        },
    },
);

const fetchMapBasedSearchClusters = mapBasedSearchFactory.creator();
const mapBasedSearchFetcherReducer = mapBasedSearchFactory.reducer();

export const setAlgoliaHitsLoading = (algoliaHitsLoading: boolean) => ({
    type: Actions.SET_ALGOLIA_HITS_LOADING,
    algoliaHitsLoading,
});

export const setMapPosition = (mapPosition?: MapPosition | null) => ({
    type: Actions.SET_MAP_POSITION,
    mapPosition,
});

export const setPinnedListingId = (pinnedListingId: string | null) => ({
    type: Actions.SET_PINNED_LISTING_ID,
    pinnedListingId,
});

export const setPinnedListing = (pinnedListing: PropertyData | null) => ({
    type: Actions.SET_PINNED_LISTING,
    pinnedListing,
});

export const setMapView = (mapView?: Values<typeof MapViewName> | null) => ({
    type: Actions.SET_MAP_VIEW,
    mapView,
});

export const setMapBasedSearchActiveCluster =
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    (activeCluster: Array<MapBasedSearchListing>) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();

        const listingType = selectEnabledListingType(state);

        const sortedListings = sortListings(activeCluster, state);
        const listingIDs = sortedListings.slice(0, 24).map((entry) => entry.id);

        dispatch(setAlgoliaHitsLoading(true));
        fetchAttributesFromAlgolia(listingIDs, getState().i18n.language, listingType)
            .then((result) =>
                dispatch({
                    type: Actions.SET_ALGOLIA_HITS,
                    algoliaHits: result,
                }),
            )
            .catch(() => {
                dispatch(setAlgoliaHitsLoading(false));
            });

        return dispatch({ type: Actions.SET_ACTIVE_CLUSTER, cluster: sortedListings });
    };

export const setActiveClusterId = (
    clusterId: number | null | undefined | string,
    activeClusterPosition?: MapPosition | null,
) => ({
    type: Actions.SET_ACTIVE_CLUSTER_ID,
    clusterId,
    activeClusterPosition,
});

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
export const setPage = (page: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const activeCluster = state.map.activeCluster;
    if (!activeCluster) {
        return dispatch({
            type: Actions.SET_PAGE,
            page: 0,
        });
    }

    const listingIDs = activeCluster
        .slice(page * 24, (page + 1) * 24)
        .map((entry: MapBasedSearchListing) => entry.id);

    const listingType = selectEnabledListingType(state);

    dispatch(setAlgoliaHitsLoading(true));
    fetchAttributesFromAlgolia(listingIDs, state.i18n.language, listingType)
        .then((result) =>
            dispatch({
                type: Actions.SET_ALGOLIA_HITS,
                algoliaHits: result,
            }),
        )
        .catch(() => {
            dispatch(setAlgoliaHitsLoading(false));
        });

    return dispatch({
        type: Actions.SET_PAGE,
        page,
    });
};

export const startComputingCluster = () => ({
    type: Actions.START_COMPUTING_CLUSTER,
});

export const setCommuteActive = (commuteActive: boolean) => ({
    type: Actions.SET_COMMUTE_ACTIVE,
    commuteActive,
});

export const setCommuteSortBy = (commuteSortBy: string) => ({
    type: Actions.SET_COMMUTE_SORT_BY,
    commuteSortBy,
});

export const setCommuteMaxTime = (commuteMaxTime: number | null | undefined) => ({
    type: Actions.SET_COMMUTE_MAX_TIME,
    commuteMaxTime,
});

export const clearMapData = () => ({
    type: Actions.CLEAR_MAP_DATA,
});

export const setCollectionType = (collectionType: Values<PropertyType> | null) => ({
    type: Actions.SET_COLLECTION_TYPE,
    collectionType,
});

type SetActiveClusterAction = {
    type: 'MAP/SET_ACTIVE_CLUSTER';
    cluster: Array<MapBasedSearchListing>;
};

type SetMapPosition = {
    type: 'MAP/SET_MAP_POSITION';
    mapPosition: MapPosition;
};

type SetAlgoliaHitsAction = {
    type: 'MAP/SET_ALGOLIA_HITS';
    algoliaHits: AttributesFetchedFromAlgolia;
};

type SetPageAction = {
    type: 'MAP/SET_PAGE';
    page: number;
};

type StartComputingCluster = {
    type: 'MAP/START_COMPUTING_CLUSTER';
};

type SetActiveClusterId = {
    type: 'MAP/SET_ACTIVE_CLUSTER_ID';
    clusterId: number | null | undefined | string;
    activeClusterPosition: MapPosition | null | undefined;
};

type SetCommuteActive = {
    type: 'MAP/SET_COMMUTE_ACTIVE';
    commuteActive: boolean;
};

type SetAlgoliaHitsLoading = {
    type: 'MAP/SET_ALGOLIA_HITS_LOADING';
    algoliaHitsLoading: boolean;
};

type SetCommuteSortBy = {
    type: 'MAP/SET_COMMUTE_SORT_BY';
    commuteSortBy: string;
};

type SetCommuteMaxTime = {
    type: 'MAP/SET_COMMUTE_MAX_TIME';
    commuteMaxTime: number | null;
};

type SetPinnedListing = {
    type: 'MAP/SET_PINNED_LISTING';
    pinnedListing: PropertyData | null;
};

type SetPinnedListingId = {
    type: 'MAP/SET_PINNED_LISTING_ID';
    pinnedListingId: string | null;
};

type SetCollectionType = {
    type: 'MAP/SET_COLLECTION_TYPE';
    collectionType: Values<PropertyType> | null;
};

type ClearMapData = {
    type: 'MAP/CLEAR_MAP_DATA';
};

type SetMapView = {
    type: 'MAP/SET_MAP_VIEW';
    mapView?: Values<typeof MapViewName>;
};

type Action =
    | SetActiveClusterAction
    | SetAlgoliaHitsAction
    | SetPageAction
    | StartComputingCluster
    | SetMapPosition
    | SetActiveClusterId
    | SetCommuteActive
    | SetAlgoliaHitsLoading
    | SetCommuteSortBy
    | SetCommuteMaxTime
    | SetPinnedListing
    | SetPinnedListingId
    | ClearMapData
    | SetCollectionType
    | SetMapView;

export type MapState = FetchState & {
    algoliaHits: AttributesFetchedFromAlgolia;
    algoliaHitsLoading: boolean;
    activeCluster: Array<MapBasedSearchListing> | null | undefined;
    activeClusterLoading: boolean;
    clusterId: number | null | undefined | string;
    activeClusterPosition: MapPosition | null | undefined;
    page: number;
    mapPosition: MapPosition | null | undefined;
    commuteActive: boolean;
    commuteSortBy: string;
    commuteMaxTime: number | null;
    pinnedListing: PropertyData | null;
    pinnedListingId: number | null;
    collectionType: Values<typeof PropertyType> | null;
    mapView?: Values<typeof MapViewName> | null;
};

const mapBasedSearchReducer = (
    state: MapState | null | undefined = defaultState,
    action: Action,
) => {
    switch (action.type) {
        case Actions.SET_ACTIVE_CLUSTER:
            return {
                ...state,
                activeCluster: action.cluster,
                activeClusterLoading: false,
                page: 0,
            };

        case Actions.SET_MAP_POSITION:
            return {
                ...state,
                mapPosition: action.mapPosition,
            };

        case Actions.SET_ACTIVE_CLUSTER_ID:
            return {
                ...state,
                clusterId: action.clusterId,
                activeClusterPosition: action.activeClusterPosition,
            };

        case Actions.SET_ALGOLIA_HITS:
            return {
                ...state,
                algoliaHits: action.algoliaHits,
                algoliaHitsLoading: false,
                algoliaHitsLoaded: true,
            };

        case Actions.SET_ALGOLIA_HITS_LOADING:
            return {
                ...state,
                algoliaHitsLoading: action.algoliaHitsLoading,
            };

        case Actions.SET_PAGE:
            return {
                ...state,
                page: action.page,
            };

        case Actions.START_COMPUTING_CLUSTER:
            return {
                ...state,
                activeClusterLoading: true,
            };

        case Actions.SET_COMMUTE_ACTIVE:
            return {
                ...state,
                commuteActive: action.commuteActive,
            };

        case Actions.SET_COMMUTE_SORT_BY:
            return {
                ...state,
                commuteSortBy: action.commuteSortBy,
            };

        case Actions.SET_COMMUTE_MAX_TIME:
            return {
                ...state,
                commuteMaxTime: action.commuteMaxTime,
            };

        case Actions.SET_PINNED_LISTING_ID:
            return {
                ...state,
                pinnedListingId: action.pinnedListingId,
            };

        case Actions.SET_PINNED_LISTING:
            return {
                ...state,
                pinnedListing: action.pinnedListing,
                pinnedListingId: action.pinnedListing?.id ?? null,
            };
        case Actions.SET_MAP_VIEW:
            return {
                ...state,
                mapView: action.mapView,
            };
        case Actions.CLEAR_MAP_DATA:
            return {
                ...state,
                data: null,
                activeCluster: null,
                collectionType: null,
            };

        case Actions.SET_COLLECTION_TYPE:
            return {
                ...state,
                collectionType: action.collectionType,
            };
        default:
            return mapBasedSearchFetcherReducer(state, action);
    }
};

export { fetchMapBasedSearchClusters };
export default mapBasedSearchReducer;
