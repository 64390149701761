import defaultReducers from 'strat/state';

import sellerProfileReducer from 'horizontal/seller/state';
import agencyProfileReducer from 'horizontal/agency/state';
import categoriesReducerer from 'horizontal/categories/state';
import featuredAgenciesReducer from 'horizontal/featuredAgencies/state';
import adReducer from 'horizontal/ad/state';
import adLimitsReducer from 'horizontal/adLimits/state';
import adManagementReducer from 'horizontal/adManagement/state';
import sitemapReducer from 'horizontal/sitemap/state/state';
import userNotificationsReducer from 'horizontal/notifications/state';
import relatedAdsReducer from 'horizontal/adDetails/relatedAds/state';
import promotedSearchesReducer from 'horizontal/home/promotedSearches/state';
import frequentSearchQueriesReducer from 'horizontal/search/frequentSearchQueries/state';
import businessPackageCartReducer from 'horizontal/businessPackages/state';
import favoritesReducer from 'horizontal/favorites/state';
import recentlyViewedReducer from 'horizontal/search/recentlyViewed/state';
import userSettingsReducer from 'horizontal/userSettings/state';
import productPurchasesReducer from 'horizontal/packages/state';
import paymentsReducer, { userPaymentOrdersReducer } from 'horizontal/payment/state';
import adMetricsReducer from 'horizontal/adMetrics/state';
import agencyMetricsReducer from 'horizontal/agencyMetrics/state';
import walletBalanceReducer from 'horizontal/payment/wallet/state';
import addressBookReducer from 'horizontal/userOrders/addresses/state';
import areebaSessionReducer from 'horizontal/payment/areeba/state';
import categoryFieldCombinationsReducer from 'horizontal/categoryFieldCombinations/state';
import locationsReducer from 'horizontal/location/state';
import buyingOrderReducer from 'horizontal/userOrders/buyingOrders/state';
import sellingOrdersReducer from 'horizontal/userOrders/sellingOrders/state';
import userAgenciesReducer from 'horizontal/agent/state';
import userRolesReducer from 'horizontal/userRoles/state';
import urlSpecificSEOsReducer from 'horizontal/urlSpecificSEO/state';
import { verticalsPromotedAdsReducer } from 'horizontal/verticals/properties/promotedAds';
import { topSearchesReducer } from 'horizontal/verticals/properties/suggestedSearch';
import consumedProductAdsReducer from 'horizontal/userOrders/orders/state';
import dialogStackReducer from 'horizontal/dialogs/state';
import myProfileReducer from 'horizontal/profile/state';

export default {
    ...defaultReducers,
    categories: categoriesReducerer,
    categoryFieldCombinations: categoryFieldCombinationsReducer,
    locations: locationsReducer,
    featuredAgencies: featuredAgenciesReducer,
    favorites: favoritesReducer,
    recentlyViewed: recentlyViewedReducer,
    ad: adReducer,
    adLimits: adLimitsReducer,
    adManagement: adManagementReducer,
    sellerProfile: sellerProfileReducer,
    agencyProfile: agencyProfileReducer,
    sitemap: sitemapReducer,
    userNotifications: userNotificationsReducer,
    relatedAds: relatedAdsReducer,
    promotedSearches: promotedSearchesReducer,
    frequentSearchQueries: frequentSearchQueriesReducer,
    businessPackageCart: businessPackageCartReducer,
    userSettings: userSettingsReducer,
    productPurchases: productPurchasesReducer,
    payments: paymentsReducer,
    paymentOrders: userPaymentOrdersReducer,
    adMetrics: adMetricsReducer,
    agencyMetrics: agencyMetricsReducer,
    walletBalance: walletBalanceReducer,
    addressBook: addressBookReducer,
    areebaSession: areebaSessionReducer,
    buyingOrder: buyingOrderReducer,
    sellingOrders: sellingOrdersReducer,
    userAgencies: userAgenciesReducer,
    userRoles: userRolesReducer,
    urlSpecificSEOs: urlSpecificSEOsReducer,
    verticalsPromotedAds: verticalsPromotedAdsReducer,
    topSearches: topSearchesReducer,
    consumedProductAds: consumedProductAdsReducer,
    dialogStack: dialogStackReducer,
    myProfile: myProfileReducer,
};
