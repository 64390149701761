//@ts-nocheck
export default {
    'completion-year': 'building-icon',
    view: 'view-icon',
    'balcony-or-terrace': 'balcony-or-terrace',
    'barbeque-area': 'barbeque-area',
    'lobby-in-building': 'lobby-in-building',
    'double-glazed-windows': 'double-glazed-windows',
    'centrally-air-conditioned': 'central-air-con',
    'central-heating': 'central-heating',
    'day-care-center': 'day-care-center',
    'electricity-backup': 'electricity-backup',
    'waste-disposal': 'waste-disposal',
    'first-aid-medical-center': 'first-aid-medical-center',
    floor: 'floor',
    flooring: 'floor',
    'elevators-in-building': 'service-elevators',
    'service-elevators': 'service-elevators',
    'other-main-features': '',
    freehold: 'freehold',
    furnished: 'furnished',
    'gym-or-health-club': 'shared-gym',
    'prayer-room': 'prayer-room',
    'laundry-room': 'laundry-room',
    'other-rooms': '',
    'other-facilities': '',
    'broadband-internet': 'broadband-internet',
    'satellitecable-tv': 'satellite-cableTV',
    'business-center': 'business-center',
    'conference-room': 'conference-room',
    intercom: 'intercom',
    'atm-facility': 'atm-facility',
    jacuzzi: 'jacuzzi',
    'kids-play-area': 'kids-play-area',
    'land-area': '',
    'lawn-or-garden': 'lawn-or-garden',
    'maids-room': 'maids-room',
    'number-of-bathrooms': '',
    'number-of-bedrooms': '',
    'receptionwaiting-room': 'reception-waiting-room',
    sauna: 'sauna',
    'steam-room': 'steam-room',
    'swimming-pool': 'swimming-pool',
    'nearby-schools': '',
    'nearby-hospitals': '',
    'nearby-shopping-malls': 'nearby-shopping-malls',
    'distance-from-airport-kms': '',
    'nearby-public-transport': '',
    'other-nearby-places': '',
    'maintenance-staff': 'maintenance-staff',
    'security-staff': 'security-staff',
    'cctv-security': 'cctv-security',
    'cafeteria-or-canteen': 'cafeteria-or-canteen',
    'laundry-facility': 'laundry-facility',
    'shared-kitchen': 'shared-kitchen',
    'facilities-for-disabled': 'facilities-for-disabled',
    'parking-spaces': 'parking-spaces',
    'storage-areas': 'storage-areas',
    'study-room': 'study-room',
    '24-hours-concierge': 'conceirge-service',
    'cleaning-services': 'cleaning-services',
    'total-floors': 'floor',
    'covered-parking': 'covered-garage',
    'close-to-metro-station': 'close-to-metro',
    'basement-parking': 'basement-parking',
    'close-to-main-roads': 'close-to-main-road',
    'public-parking': 'public-parking',
    'private-garage': 'private-garage',
    'nearby-mosque': 'nearby-mosque',
    'valet-service': 'valet-parking',
    'pet-policy': 'pets-allowed',
    'pets-allowed': 'pets-allowed',
    'pets-not-allowed': 'pets-not-allowed',
    'built-in-kitchen-appliances': 'built-in-kitchen-appliances',
    'natural-gas': 'natural-gas',
    'electricity-meter': 'electricity-meter',
    'water-meter': 'water-meter',
    landline: 'landline',
    'sea-view': 'sea-view',
    'central-air-con-and-heating': 'central-air-con-and-heating',
};
