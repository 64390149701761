import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useI18n } from 'strat/i18n/language';

import {
    TitleField,
    DescriptionField,
    LocationFieldForm,
    CommonPostingFields,
} from 'horizontal/fields';
import { DropdownVariants } from 'horizontal/components';
import type { Category, LiteHierarchicalLocation } from 'horizontal/types';

type Props = {
    activeCategory?: Category | null | undefined;
    setFieldValue: (field: keyof FormikValues, value?: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
    onBlur: (arg1: React.SyntheticEvent<any>) => void;
    titleChangedRef?: React.MutableRefObject<boolean>;
    readonlyLocation?: boolean;
    initialLocation?: LiteHierarchicalLocation | null;
};

const CommonFields = ({
    activeCategory,
    setFieldValue,
    touched,
    errors,
    values,
    onBlur,
    titleChangedRef,
    readonlyLocation,
    initialLocation,
}: Props) => {
    const i18n = useI18n();

    return (
        <>
            <TitleField
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                values={values}
                onBlur={onBlur}
                titleChangedRef={titleChangedRef}
            />
            <DescriptionField
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                values={values}
                onBlur={onBlur}
            />
            <LocationFieldForm
                activeCategory={activeCategory}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                formatPlaceholder={(title?: string) => (title ? t(i18n)`Select ${title}` : '')}
                attribute={CommonPostingFields.location_id.attribute}
                variant={DropdownVariants.TEXT_INPUT}
                lightPlaceholder
                readOnly={readonlyLocation}
                initialLocation={initialLocation}
                isMandatory
            />
        </>
    );
};

export default CommonFields;
