import type { FetchState } from 'strat/fetcher';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { selectUserID } from 'strat/user/selectors';
import FavoritesAPI from 'strat/api/favoritesAPI';

import fetchFavoritesFromIndex from './fetchFavoritesFromIndex';

/**
 * Fetcher factory for fetching favorite ads external ids.
 * The content for ads will be fetched using the Algolia index.
 */
const factory = new FetcherFactory(['favorites'], (params: any, state: any) => {
    const language = selectLanguage(state);

    const userExternalID = selectUserID(state);
    if (!userExternalID) {
        return Promise.resolve({ status: 200, data: [] });
    }

    return new FavoritesAPI(language).favorites(userExternalID).then(({ status, data }) => {
        if (status !== 200 || !Array.isArray(data)) {
            return { status, data: null };
        }

        const externalIDs = (data || []).map((favorite) => favorite.adExternalID);
        return fetchFavoritesFromIndex(externalIDs, language).then((favorites) => ({
            status: 200,
            data: favorites,
        }));
    });
});

export const fetchFavorites = factory.creator();

export const clearFavorites = () => ({
    type: factory.actions.clear,
});

export const defaultState: FetchState = buildDefaultState();

const favoritesFetcherReducer = factory.reducer();

export default favoritesFetcherReducer;
