import { t } from '@lingui/macro';
import * as React from 'react';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatUserXID } from 'horizontal/chat/types';
import { useCreationDate } from 'horizontal/generic';

import styles from './styles/onlineStatus.cssm';

type Props = {
    readonly userId: ChatUserXID;
};

const OnlineStatus = ({ userId }: Props) => {
    const i18n = useI18n();
    const sdk = useChatSDK();
    const { isOnline, lastSeenAt } = sdk.usePresenceByUser(userId);
    const lastOnlineAgo = useCreationDate((lastSeenAt ?? 0) / 1000);

    if (isOnline) {
        return <Text.Base nowrap className={styles.text}>{t(i18n)`online`}</Text.Base>;
    }

    if (lastSeenAt) {
        return (
            <Text.Base nowrap className={styles.text}>
                {t(i18n)`Last active ${lastOnlineAgo}`}
            </Text.Base>
        );
    }

    return null;
};

export default React.memo<Props>(OnlineStatus);
