import trigger from 'strat/gtm/trigger';
import Triggers from 'strat/gtm/triggers';
import ViewSections from 'strat/gtm/viewSections';

const trackAboutEMPG = () => trigger(Triggers.CLICK_ABOUT_EMPG);

const trackOLXBlog = () => trigger(Triggers.CLICK_OLX_BLOG);

const trackContactUs = () => trigger(Triggers.CLICK_CONTACT_US);

const trackOLXBusiness = () => trigger(Triggers.CLICK_OLX_BUSINESS);

const trackContactOurSales = () => trigger(Triggers.CLICK_CONTACT_OUR_SALES);

const trackSitemap = () => trigger(Triggers.CLICK_SITEMAP);

const trackTermOfUse = () => trigger(Triggers.CLICK_TERMOFUSE);

const trackPrivacyPolicy = () => trigger(Triggers.CLICK_PRIVACYPOLICY);

const trackClickHelp = (viewSection: Values<typeof ViewSections>) => {
    trigger(Triggers.CLICK_HELP, { view_section: viewSection });
};

export {
    trackAboutEMPG,
    trackOLXBlog,
    trackContactUs,
    trackOLXBusiness,
    trackSitemap,
    trackTermOfUse,
    trackPrivacyPolicy,
    trackClickHelp,
    trackContactOurSales,
};
