import { ContextfulError, type ErrorContext } from 'strat/error/context';

import type { APIResponse } from './types';

export enum APIErrorReason {
    ACCESS_DENIED = 'ACCESS_DENIED',
    UNAUTHORIZED = 'UNAUTHORIZED',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INVALID_REQUEST = 'INVALID_REQUEST',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    ALREADY_REGISTERED = 'ALREADY_REGISTERED',
    CELL_DUPLICATED = 'CELL_DUPLICATED',
    UNKNOWN_EMAIL = 'UNKNOWN_EMAIL',
    EMPTY_EMAIL = 'EMPTY_EMAIL',
    ALREADY_EXISTS = 'ALREADY_EXISTS',
    INVALID_PASSWORD_RESET_TOKEN = 'INVALID_PASSWORD_RESET_TOKEN',
    NOT_FOUND = 'NOT_FOUND',
    UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
    TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
    PRECONDITION_FAILED = 'PRECONDITION_FAILED',
}

export type APIErrorContext = ErrorContext & {
    reason: APIErrorReason;
    response?: APIResponse<unknown>;
    requestURL?: string;
    requestMethod?: string;
    requestBody?: unknown;
    [key: string]: any;
};

export class APIError extends ContextfulError {
    public reason!: APIErrorReason | null;

    public status!: number | null;

    public data!: unknown;

    constructor(msg: string, context?: APIErrorContext) {
        const { response, requestURL, requestMethod, requestBody, ...remainingContext } =
            context || {};
        const forwardedContext = { ...remainingContext };

        if (requestURL) {
            forwardedContext.url = requestURL;
        }

        if (requestMethod) {
            forwardedContext.method = requestMethod;
        }

        if (requestBody) {
            forwardedContext.params = APIError.stringify(requestBody);
        }

        if (response?.status) {
            forwardedContext.status = response.status;
        }

        if (response?.data) {
            forwardedContext.data = APIError.stringify(response.data);
        }

        super(msg, forwardedContext);

        Object.setPrototypeOf(this, APIError.prototype);

        // Set this as a non-enumerable property so that
        // Sentry doesn't extract them twice, once as
        // part of `.toJSON()` and once by enumerating
        // the properties using `Object.keys()`.
        Object.defineProperty(this, 'reason', {
            enumerable: false,
            writable: false,
            value: context?.reason || null,
        });

        Object.defineProperty(this, 'status', {
            enumerable: false,
            writable: false,
            value: context?.response?.status || null,
        });

        Object.defineProperty(this, 'data', {
            enumerable: false,
            writable: false,
            value: context?.response?.data || null,
        });
    }

    statusCode(): number {
        switch (this.reason) {
            case APIErrorReason.UNKNOWN_EMAIL:
            case APIErrorReason.EMPTY_EMAIL:
                return 404;
            case APIErrorReason.UNAUTHORIZED:
                return 401;
            case APIErrorReason.ACCESS_DENIED:
            case APIErrorReason.INVALID_CREDENTIALS:
                return 403;
            case APIErrorReason.NOT_FOUND:
                return 404;
            case APIErrorReason.ALREADY_REGISTERED:
            case APIErrorReason.CELL_DUPLICATED:
            case APIErrorReason.ALREADY_EXISTS:
                return 409;
            case APIErrorReason.PRECONDITION_FAILED:
            case APIErrorReason.INVALID_PASSWORD_RESET_TOKEN:
                return 412;
            case APIErrorReason.UNPROCESSABLE_ENTITY:
                return 422;
            case APIErrorReason.TOO_MANY_REQUESTS:
                return 429;
            default:
                return 500;
        }
    }

    public static reasonFromStatusCode(status: number): APIErrorReason {
        switch (status) {
            case 400:
                return APIErrorReason.INVALID_REQUEST;

            case 403:
                return APIErrorReason.ACCESS_DENIED;

            case 401:
                return APIErrorReason.UNAUTHORIZED;

            case 404:
                return APIErrorReason.NOT_FOUND;

            case 409:
                return APIErrorReason.ALREADY_EXISTS;

            case 412:
                return APIErrorReason.PRECONDITION_FAILED;

            case 422:
                return APIErrorReason.UNPROCESSABLE_ENTITY;

            case 429:
                return APIErrorReason.TOO_MANY_REQUESTS;

            default:
                return APIErrorReason.UNKNOWN_ERROR;
        }
    }

    public static stringify(data: unknown): unknown {
        if (!data) {
            return data;
        }

        if (typeof data === 'string') {
            return data;
        }

        try {
            return '#' + JSON.stringify(data);
        } catch (_e) {
            return String(data);
        }
    }
}

export const isAPIError = (error: unknown, reason?: APIErrorReason): error is APIError => {
    if (!(error instanceof APIError)) {
        return false;
    }

    if (!reason) {
        return true;
    }

    return error.reason === reason;
};

export const asAPIErrorReason = (error: unknown): APIErrorReason | null => {
    if (!(error instanceof APIError)) {
        return null;
    }

    return error.reason;
};
