import { useSearchHits, useSearchFilterValueOfType } from '@sector-labs/fe-search-redux/state';
import { ExactFilter } from '@sector-labs/fe-search-redux/filters';
import FilterValues from 'strat/search/filterValues';
import uniq from 'lodash/uniq';

import { AdProduct } from 'horizontal/types';
import type { LiteAd } from 'horizontal/types';

import type { HitsSections } from './types';

const getSectionHits = (hits: Array<LiteAd>, product: AdProduct) => {
    if (product === AdProduct.ELITE) {
        return { exactHitList: hits, featuredHitList: [], eliteHitList: [], fallbackHitList: [] };
    }
    if (product === AdProduct.FEATURED) {
        return { exactHitList: hits, featuredHitList: [], eliteHitList: [], fallbackHitList: [] };
    }

    const eliteHitList = hits.filter((hit) => hit.requestIndex === 0);
    const featuredHitList = hits.filter((hit) => hit.requestIndex === 1);
    const exactHitList = hits.filter((hit) => hit.requestIndex === 2);
    const fallbackHitList = uniq(
        hits.filter((hit) => (typeof hit.requestIndex == 'number' ? hit.requestIndex > 2 : false)),
    );

    if (product === AdProduct.ALL) {
        return { exactHitList, featuredHitList: [], eliteHitList: [], fallbackHitList: [] };
    }

    return { exactHitList, featuredHitList, eliteHitList, fallbackHitList };
};

const useSectionHits = (): HitsSections => {
    // @ts-expect-error - TS2769 - No overload matches this call.
    const product = useSearchFilterValueOfType(ExactFilter, FilterValues.product.attribute);
    const hits = useSearchHits();

    // @ts-expect-error - TS2345 - Argument of type 'SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
    return getSectionHits(hits, product);
};

export { getSectionHits };

export default useSectionHits;
