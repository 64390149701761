import * as React from 'react';
import classNames from 'classnames';

import CategoryDropdownEntry from './categoryDropdownEntry';
import type { CategoryLinkProps } from './types';
import styles from './styles/topCategoriesGroup.cssm';

type Props = CategoryLinkProps & {
    readonly noGrid?: boolean;
    readonly noWrapText?: boolean;
};

const TopCategoryGroup = ({ category, onClick, noGrid, noWrapText }: Props) => (
    <div className={classNames(styles.category, { [styles.noGrid]: noGrid })}>
        {category.children.map((subCategory) => (
            <CategoryDropdownEntry
                category={subCategory}
                key={subCategory.id}
                onClick={onClick}
                noGrid={noGrid}
                noWrapText={noWrapText}
            />
        ))}
    </div>
);

export default TopCategoryGroup;
