import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { LoadingSpinner } from 'strat/loadable';
import { CircleRadioButtonsGroup, Button, Input } from 'strat/components';

import type { ReportFormProps } from './types';
import styles from './styles/reportForm.cssm';
import ReportBlockingCheckbox from './reportBlockingCheckbox';

const MAX_COMMENT_LENGTH = 500;

const commentAttribute = 'comment';
const reasonAttribute = 'reason';

const ReportForm = ({
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    onBlur,
    isSubmitting,
    reportReasons,
    title,
    isBlockingEnabled,
    isUserBlocked,
    isUserBlockedLoading,
    shouldBlockUser,
    setShouldBlockUser,
}: ReportFormProps) => {
    const i18n = useI18n();

    const onCommentChange = React.useCallback(
        (value) => {
            setFieldValue(commentAttribute, value);
        },
        [setFieldValue],
    );

    const onReasonChange = React.useCallback(
        (value) => {
            setFieldValue(reasonAttribute, value);
        },
        [setFieldValue],
    );

    return (
        <>
            {isSubmitting ? (
                // @ts-expect-error - TS2322 - Type '{ visible: true; }' is not assignable to type 'IntrinsicAttributes & Props'.
                <LoadingSpinner visible={isSubmitting} />
            ) : (
                <form>
                    <h2 className={styles.title}>{title}</h2>
                    <div className={styles.listContainer}>
                        <CircleRadioButtonsGroup
                            // $FlowFixMe
                            choices={reportReasons}
                            selectedValue={values.reason}
                            onChange={onReasonChange}
                            errorMessage={touched[reasonAttribute] && errors[reasonAttribute]}
                        />
                    </div>
                    <Input
                        multiLine
                        name={commentAttribute}
                        placeholder={t(i18n)`Comment`}
                        maxLength={MAX_COMMENT_LENGTH}
                        onBlur={onBlur}
                        value={values[commentAttribute]}
                        errorMessage={touched[commentAttribute] && errors[commentAttribute]}
                        className={styles.comment}
                        onChange={onCommentChange}
                    />
                    {isBlockingEnabled && (
                        <ReportBlockingCheckbox
                            shouldBlockUser={shouldBlockUser}
                            setShouldBlockUser={setShouldBlockUser}
                            isUserBlocked={isUserBlocked}
                            isUserBlockedLoading={isUserBlockedLoading}
                        />
                    )}
                    <Button onClick={handleSubmit} stretch disabled={isUserBlockedLoading}>
                        <Trans>Send complaint</Trans>
                    </Button>
                </form>
            )}
        </>
    );
};

export default ReportForm;
