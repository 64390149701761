import * as React from 'react';

import { GTMSearchDataLayer } from 'horizontal/gtm/components';

import SearchHead from './searchHead';
import SearchBodyDesktop from './searchBodyDesktop';

const SearchPageDesktop = {
    head: () => (
        <>
            <SearchHead />
            <GTMSearchDataLayer />
        </>
    ),
    body: () => <SearchBodyDesktop />,
} as const;

export default SearchPageDesktop;
