import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import businessMultiWEBP from 'horizontal/assets/images/businessMulti.webp';
import businessMultiPNG from 'horizontal/assets/images/businessMulti.png';

import styles from './styles/heavyDiscountBannerRedesign.cssm';

const HeavyDiscountBanner = () => (
    <Flex className={styles.container} alignCenter stretchWidth>
        <Flex className={styles.innerContainer}>
            <Flex className={styles.textContainer} column alignCenter>
                <Text.XLarge bold>
                    <Trans>Discounts on Bundles</Trans>
                </Text.XLarge>
                <Text.Regular>
                    <Trans>Buy in bundles and pay less</Trans>
                </Text.Regular>
            </Flex>
            <Flex className={styles.imageContainer}>
                <picture>
                    <source srcSet={businessMultiWEBP} type="image/webp" />
                    <img
                        src={businessMultiPNG}
                        className={styles.image}
                        alt={'Business Packages Discount'}
                    />
                </picture>
            </Flex>
        </Flex>
    </Flex>
);

export default HeavyDiscountBanner;
