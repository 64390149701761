import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID } from 'strat/user/session';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import { fetchUserAgencies } from 'horizontal/agent/state';
import Page from 'horizontal/agencyPortal/pages/page';

import { ensureCanAccessAgencyPortalAsOwner } from './ensureCanAccessAgencyPortal';

class AgencyPortalHomeRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_HOME, [['^/agencyPortal$']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/agencyPortal' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortalAsOwner(context)) {
            return;
        }
        const {
            redux: {
                store: { dispatch, getState },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        context.rendering.renderPage(Page.AGENCY_PORTAL_HOME);

        context.promise.wait(dispatch(fetchUserAgencies({ userExternalID })));
    }
}

export default new AgencyPortalHomeRoute();
