import * as React from 'react';

import { Flex } from 'strat/components';
import StarIcon from 'strat/agency/branding/assets/icons/starIcon.svg';
import HalfStarIcon from 'strat/agency/branding/assets/icons/halfStarIcon.svg';

import styles from './styles/completedStarRating.cssm';

type Props = {
    isCompact?: boolean;
    starRating: number | null;
};

const MAX_NUMBER_OF_STARS = 5;
const UPPER_THRESHOLD = 0.75;
const LOWER_THRESHOLD = 0.3;

const CompletedStarRating = ({ starRating, isCompact = false }: Props) => {
    if (!starRating) {
        return null;
    }

    const getStars = () => {
        return [...Array(MAX_NUMBER_OF_STARS)].map((element, index) => {
            if (starRating - index >= UPPER_THRESHOLD) {
                return <StarIcon key={index} className={styles.starIcon} />;
            }
            if (starRating - index >= LOWER_THRESHOLD) {
                return <HalfStarIcon key={index} className={styles.starIcon} />;
            }
            return <StarIcon key={index} className={styles.emptyStar} />;
        });
    };

    return (
        <Flex alignCenter className={styles.rating}>
            <Flex alignCenter className={styles.stars}>
                {isCompact ? <StarIcon className={styles.iconCompact} /> : getStars()}
            </Flex>
            {starRating.toFixed(1)}
        </Flex>
    );
};

export default CompletedStarRating;
