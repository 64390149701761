import { t } from '@lingui/macro';
import * as React from 'react';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { useTotalSearchPageCount } from '@sector-labs/fe-search-redux/state';
import { FilterValues } from 'strat/search';
import { useI18n } from 'strat/i18n/language';

import { useTrackLoadMoreSearchRecommendations } from 'horizontal/gtm';
import { SectionTitle } from 'horizontal/home';

type Props = {
    readonly lastSearchFilters: FilterCollection;
};

const SearchHistoryRecommendationsTitle = ({ lastSearchFilters }: Props) => {
    const i18n = useI18n();
    const trackViewMoreClick = useTrackLoadMoreSearchRecommendations();
    const [totalPageCount] = useTotalSearchPageCount();

    const category = lastSearchFilters.getFilterValue(
        FilterValues.category.attribute,
        FilterValues.category.default,
    );
    const location = lastSearchFilters.getFilterValue(
        FilterValues.location.attribute,
        FilterValues.location.default,
    );

    const freeTextQuery = lastSearchFilters.filters.query?.value;
    const searchName = freeTextQuery || category?.name || t(i18n)`recent ads`;
    const title = freeTextQuery ? t(i18n)`More on ${searchName}` : t(i18n)`More in ${searchName}`;

    return (
        <SectionTitle
            title={title}
            onViewMoreClick={() => trackViewMoreClick(category, totalPageCount)}
            // @ts-expect-error
            routeParams={{ category, freeTextQuery, location }}
        />
    );
};

export default SearchHistoryRecommendationsTitle;
