import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import type { ProductPackageOffer } from 'horizontal/types';
import { BoostToTopAdExample } from 'horizontal/packages/components';
import { trackViewExampleClick } from 'horizontal/gtm';
import AutoBoostIcon from 'horizontal/assets/icons/iconAutoBoostPackage_noinline.svg';

import { BusinessPackageExampleType } from '../types';
import BusinessPackageExampleContext from '../businessPackageExampleContext';

import Section from './sectionRedesign';

const renderIcon = () => <img src={AutoBoostIcon} alt="Boost to Top" />;

const BoostToTopSection = ({ packageOffers }: { packageOffers: Array<ProductPackageOffer> }) => {
    const i18n = useI18n();
    const details = [t(i18n)`Back on top like a new ad`];

    const [activeExample, setActiveExample] = React.useContext(BusinessPackageExampleContext);
    const closeExample = React.useCallback(() => setActiveExample(null), [setActiveExample]);
    const openExample = React.useCallback(() => {
        trackViewExampleClick();
        setActiveExample(BusinessPackageExampleType.BOOST_TO_TOP);
    }, [setActiveExample]);

    return (
        <>
            {activeExample === BusinessPackageExampleType.BOOST_TO_TOP && (
                <BoostToTopAdExample onClick={closeExample} />
            )}
            <Section
                title={t(i18n)`Boost to top`}
                details={details}
                showExampleButton
                exampleText={t(i18n)`Boosted Ad`}
                onClickSeeExample={openExample}
                subsections={[
                    {
                        title: t(i18n)`Boost to Top`,
                        packageOffers,
                    },
                ]}
                renderIcon={renderIcon}
                disclaimer={t(i18n)`Can only be used on active ad`}
            />
        </>
    );
};

export default BoostToTopSection;
