import React from 'react';
import { useHomeTemplate } from 'strat/home';
import { withHomeCanonicalURL } from 'strat/home/seo';
import { TemplateType } from 'strat/templates';
import { useI18n } from 'strat/i18n/language';
import type { AllCanonicalURLs } from 'strat/routing';
import OrganizationMetadata from '@app/branding/organizationMetadata';
import WebsiteSchemaMetadata from '@app/branding/websiteSchemaMetadata';

import { getHomePageDescription, getHomePageTitle } from 'horizontal/home/seo';

import CommonHead from './commonHead';

const HomeHead = ({ url, alternateURLs }: AllCanonicalURLs) => {
    const i18n = useI18n();
    const title = getHomePageTitle(i18n);
    const description = getHomePageDescription(i18n);

    const { text: titleFromTemplate, isDefault: isTemplatedTitleDefault } = useHomeTemplate(
        TemplateType.TITLE,
    );
    const { text: descriptionFromTemplate, isDefault: isTemplatedDescriptionDefault } =
        useHomeTemplate(TemplateType.META);

    return (
        <>
            <title>{(isTemplatedTitleDefault && titleFromTemplate) || title}</title>
            <meta
                name="description"
                // @ts-expect-error - TS2322 - Type '{}' is not assignable to type 'string'.
                content={(isTemplatedDescriptionDefault && descriptionFromTemplate) || description}
            />
            <CommonHead url={url} alternateURLs={alternateURLs} />
            <WebsiteSchemaMetadata />
            <OrganizationMetadata />
        </>
    );
};

export default withHomeCanonicalURL(HomeHead);
