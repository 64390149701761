const createLegacyStatsAPI = (_?: string | null) => null;

const selectLegacyStatsResources = (_: any) => null;

type LegacyStatsAPI = null;

type LegacyStatsResources = null;

export { createLegacyStatsAPI, selectLegacyStatsResources };

export type { LegacyStatsAPI, LegacyStatsResources };
