import * as React from 'react';
import isNil from 'lodash/isNil';
import brandingSettings from '@app/branding/settings';
import { usePageRule } from 'strat/search/state/hooks';

import type { Category, CategoryFieldsFilters } from 'horizontal/types';
import { useActiveCategoryHierarchy } from 'horizontal/categories';
import { useCurrentLocation } from 'horizontal/location';
import { LocationRole } from 'horizontal/types';

type Props = {
    readonly extraFields?: CategoryFieldsFilters;
    readonly freeTextQuery?: string | null | undefined;
    readonly marketingParams?: { [key: string]: any };
};

const isLocationLevelIndexable = (locationLevel: number, categoryHierarchy: Category[]) => {
    const maxLocationLevelToIndex =
        categoryHierarchy
            .map(
                (category) =>
                    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
                    brandingSettings.maxLocationLevelIndexationByCategory[category.externalID],
            )
            .find((level) => !!level) ||
        // @ts-expect-error - TS2339 - Property 'default' does not exist on type '{}'.
        brandingSettings.maxLocationLevelIndexationByCategory.default;

    if (maxLocationLevelToIndex) {
        return locationLevel <= maxLocationLevelToIndex;
    }
    return true;
};

export const RobotsIndexReason = Object.freeze({
    MULTIPLE_CHOICES_SELECTED: 'multiple_choices_selected',
    ALWAYS_INDEXABLE_LOCATION: 'always_indexable_location',
    NON_INDEXABLE_QUERY_PAGE: 'non_indexable_query_page',
    LOCATION_LEVEL_NON_INDEXABLE: 'location_level_non_indexable',
    PAGE_RULE_DIRECTED: 'page_rule_directed',
    DEFAULT: 'default',
});

/**
 * We have multiple rules for deciding if a page is indexable or not. Order matters here.
 * See #182370626 and #182582124 for more info
 * - multiple choice selected for a category field -> noindex
 * - location without HAS_SPECIAL_INDEXATION_RULES role(non-imported location) -> index
 * - q-page -> noindex
 * - location level is grater that a given value(dependent on the category) -> noindex, nofollow
 * - we have some value in the robots field for this page rule -> do what the robots says
 * - we have less than 10 inactive ads for the [category, location] pair(robots is null) -> noindex
 *
 * Shortly:
 * multiple choice -> noindex
 * old location -> index
 * q-page -> noindex
 * loc level > 3 -> noindex
 * has >= 10 inactive ads -> index
 * else -> noindex
 * */
const useMetaRobotsDirective = ({
    extraFields,
    freeTextQuery,
    marketingParams,
}: Props): {
    result: string;
    reason: Values<typeof RobotsIndexReason>;
} => {
    const categoryHierarchy = useActiveCategoryHierarchy();
    const location = useCurrentLocation();
    const pageRule = usePageRule();
    return React.useMemo(() => {
        if (marketingParams && Object.values(marketingParams).some((value) => !isNil(value))) {
            return { result: 'noindex', reason: RobotsIndexReason.NON_INDEXABLE_QUERY_PAGE };
        }

        const hasMultipleChoicesSelected = Object.values(extraFields || {}).some((value) =>
            Array.isArray(value) ? value.length > 1 : false,
        );
        if (hasMultipleChoicesSelected) {
            return { result: 'noindex', reason: RobotsIndexReason.MULTIPLE_CHOICES_SELECTED };
        }

        const hasSpecialIndexationRules = (location.roles || []).includes(
            LocationRole.HAS_SPECIAL_INDEXATION_RULES,
        );

        if (!hasSpecialIndexationRules) {
            return {
                result: '',
                reason: RobotsIndexReason.ALWAYS_INDEXABLE_LOCATION,
            };
        }

        if (freeTextQuery) {
            // Query search pages with new locations imported from zameen should not be indexable.
            return {
                result: 'noindex, nofollow',
                reason: RobotsIndexReason.NON_INDEXABLE_QUERY_PAGE,
            };
        }

        // @ts-expect-error - TS2345 - Argument of type 'Omit<never, "children">[]' is not assignable to parameter of type 'Category[]'.
        const locationLevelIndexable = isLocationLevelIndexable(location.level, categoryHierarchy);
        if (!locationLevelIndexable) {
            return {
                result: 'noindex, nofollow',
                reason: RobotsIndexReason.LOCATION_LEVEL_NON_INDEXABLE,
            };
        }

        // @ts-expect-error - TS2339 - Property 'robots' does not exist on type 'PageRule'.
        if (pageRule?.robots) {
            return {
                // @ts-expect-error - TS2339 - Property 'robots' does not exist on type 'PageRule'.
                result: pageRule.robots.join(','),
                reason: RobotsIndexReason.PAGE_RULE_DIRECTED,
            };
        }

        return {
            result: 'noindex',
            reason: RobotsIndexReason.DEFAULT,
        };
    }, [extraFields, location, categoryHierarchy, pageRule, freeTextQuery, marketingParams]);
};

export default useMetaRobotsDirective;
