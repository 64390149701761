import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { parseLocation } from 'react-true-router/location';
import type { RouteParameters } from 'react-true-router/route';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';

import Page from 'horizontal/pages/page';

class NotFoundRoute extends Route {
    constructor() {
        super(RouteNames.NOT_FOUND, [['^/'], ['^/notfound(?:(?:\\?.*)?(?:#.*)?)?$']]);
    }

    createURL(_params?: RouteParameters): EnhancedLocation {
        return { pathname: '/notfound' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const canonicalURL = this.createURL({});
        const originalURL = parseLocation(context.match.originalURL);

        // don't ask why olx.com.pk does this, turning a 404 into a 301
        // to a page that returns 404 does not seem like a good idea, but ok
        if (originalURL.pathname !== canonicalURL.pathname) {
            // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
            context.http.redirect(canonicalURL);
            return;
        }

        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
    }
}

export default new NotFoundRoute();
