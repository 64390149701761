/**
 * This component allows switching between the first two languages defined in the LANGUAGE settings.
 */

import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import brandingSettings from '@app/branding/settings';
import { languageSwitcher as ImagesAndStyles } from '@app/branding/languageSwitcher';

import { selectLocations } from 'strat/search/selectors';
import { selectLanguage } from 'strat/i18n/language/selectors';
import type { CanonicalURL } from 'strat/routing';

import findSwitchLanguageTarget from './findSwitchLanguageTarget';
import useLanguageSwitcherURL from './useLanguageSwitcherURL';
import styles from './styles/languageSwitcher.cssm';

/**
 * Props for {@see LanguageSwitcher}.
 */
type Props = {
    targetLanguage?: string;
    svgClassName: string;
    alternateURLs?: CanonicalURL[] | null;
    onClick?: () => void;
};

/**
 * Images and styes used to display a language in the switcher.
 *
 * Maybe these should be in LANGUAGE settings, but this is an implementation detail
 * so it stays here for now.
 */
const targetLanguageName = (targetLang: string) =>
    brandingSettings.languages.find((l) => l.lang === targetLang)?.name;

const LanguageSwitcher = ({
    svgClassName,
    alternateURLs,
    onClick,
    targetLanguage,
}: Props): React.ReactElement | null => {
    const locations = useSelector(selectLocations);
    const currentLanguage = useSelector(selectLanguage);
    const targetLang = targetLanguage ?? findSwitchLanguageTarget(currentLanguage);
    const href = useLanguageSwitcherURL(targetLang, alternateURLs, locations);

    const [hovered, setHovered] = React.useState(false);

    const onMouseEnter = React.useCallback(() => {
        setHovered(true);
    }, [setHovered]);

    const onMouseLeave = React.useCallback(() => {
        setHovered(false);
    }, [setHovered]);

    const altTag = React.useMemo(() => targetLanguageName(targetLang), [targetLang]);
    const linkStyle = React.useMemo(() => ImagesAndStyles[targetLang]?.style, [targetLang]);
    const imageSource = React.useMemo(() => {
        const src = ImagesAndStyles[targetLang]?.image;
        return `${src}#regular`;
    }, [targetLang]);

    if (brandingSettings.languages.length < 2) {
        return null;
    }

    return (
        <a
            href={href}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={linkStyle}
            title={altTag}
            className={styles.content}
            onClick={onClick}
            aria-label={'language switch button'}
        >
            <svg
                style={styles}
                className={classNames(svgClassName, {
                    [styles.hovered]: hovered,
                })}
            >
                <use xlinkHref={imageSource} />
            </svg>
        </a>
    );
};

export default LanguageSwitcher;
