import { createSelector } from 'reselect';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import settings from '@app/branding/settings';
import createSearchURL from '@app/routes/createSearchURL';

import { getLocalizedAttribute } from 'strat/i18n';
import getLocalizedAttributeNames from 'strat/util/getLocalizedAttributeNames';
import { GlobalState } from 'strat/state';
import { selectRouteParams } from 'strat/routes/selectors';

import { selectSortValue } from './state/selectors';
import FilterValues from './filterValues';
import type { SearchParams } from './types';
import getCampaignParams from './getCampaignParams';
import SearchView from './searchView';

/**
 * Redux selectors to grab input for {@see createSearchRouteParams}.
 */
const selectExtraParams = createSelector(
    (state: GlobalState) => state.search.pageParameters,
    selectRouteParams,
    selectSortValue,
    (state: GlobalState) => state.searchMode,
    (state: GlobalState) => state.algolia.settings.fallbackIndexActive,
    (state: GlobalState) => state.campaign,
    (state: GlobalState) => state.agent,
    (state: GlobalState) => (state.search.hits || {}).view,
    (state: GlobalState) => state.map.clusterId,
    (state: GlobalState) => state.map.mapPosition,
    (state: GlobalState) => state.map.mapView,
    (state: GlobalState) => state.map.commuteActive,
    (state: GlobalState) => state.map.commuteMaxTime,
    (state: GlobalState) => state.map.commuteSortBy,
    (state: GlobalState) => state.map.pinnedListingId,
    (state: GlobalState) => state.smartShare?.information.data?.leadID,
    (state: GlobalState) => state.agent?.activeTab,
    (
        pageParameters,
        routeParams,
        sortBy,
        searchMode,
        fallbackIndexActive,
        campaign,
        agent,
        view,
        clusterId,
        mapPosition,
        mapView,
        commuteActive,
        commuteMaxTime,
        commuteSortBy,
        pinnedListingId,
        leadID,
        activeTab,
    ) => ({
        ...pageParameters,
        remarketingPropertyID: routeParams?.remarketingPropertyID,
        sortBy,
        searchMode,
        fallbackIndexActive,
        ...getCampaignParams(campaign),
        agentSlug: (agent && agent.data && agent.data.slug) || null,
        activeTab: activeTab,
        mapActive: view === SearchView.MAP_BASED_SEARCH || view === SearchView.COMMUTE_SEARCH,
        clusterId: clusterId || null,
        mapZoom: (mapPosition && mapPosition.zoom) || null,
        mapCenterLat: (mapPosition && mapPosition.lat) || null,
        mapCenterLong: (mapPosition && mapPosition.lng) || null,
        mapView: mapView || null,
        commuteActive,
        commuteMaxTime,
        commuteSortBy,
        pinnedListingId,
        leadID,
    }),
);

const selectRouteParamsInput = createSelector(
    getFilterCollection,
    selectExtraParams,
    (state: GlobalState) => state.i18n.language,
    (filters: any, extraParams, currentLanguage) => ({
        filters,
        extraParams,
        currentLanguage,
    }),
);

const selectSearchLink = createSelector(
    selectRouteParamsInput,
    ({ filters, extraParams, currentLanguage }) => ({
        params: createSearchRouteParams(filters, extraParams, currentLanguage),
    }),
);

/**
 * Extract a multi-language slug from location filters.
 * The information we need is present in the location.hierarchy, in the slug and slug_l1 keys.
 * slug is the slug of the currentLanguage and slug_l1 is the slug for the "opposite language"
 *
 * We return the information in an object with the language codes as the keys.
 */
const filtersToLocationsByLanguage = (filters: any, currentLanguage: string) => {
    const filterLocations = filters.getFilterValue(FilterValues.location.attribute, []);

    return settings.languages.reduce<Record<string, any>>((prev, { lang }) => {
        prev[lang] = filterLocations
            // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
            .map((loc) =>
                typeof loc === 'object'
                    ? loc.hierarchy?.[loc.hierarchy.length - 1]?.[
                          getLocalizedAttribute(
                              'slug',
                              currentLanguage,
                              lang,
                              CONFIG.build.ENABLE_MERGED_INDEX,
                          )
                      ]
                    : loc,
            )
            // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
            .map((loc) => ({ slug: loc }));

        return prev;
    }, {});
};

/**
 * Creates an object of parameters to be passed to the search
 * route from the specified filters and extra parameters that
 * are not filters.
 *
 * @param filters Algolia search filters to use to compute
 * search route parameters.
 * @param extraParams If any non-filter specific are wished
 * to be included in the route parametrs, they can be specified
 * through this argument.
 *
 * @returns A set of search route parameters that can be serialized
 * into a URL.
 */
const createSearchRouteParams = (
    filters: FilterCollection,
    extraParams?: any,
    currentLanguage?: string,
): SearchParams => {
    let locationsByLanguage: Record<string, any> = {};

    if (CONFIG.build.LOCALIZED_ROUTES && currentLanguage) {
        locationsByLanguage = {
            locationsByLanguage: filtersToLocationsByLanguage(filters, currentLanguage),
        };
    }

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const locations = filters
        .getFilterValue(FilterValues.location.attribute, [])
        // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
        .map((loc) => (typeof loc === 'object' ? loc.slug : loc));

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const agencies = filters
        .getFilterValue(FilterValues.agency.attribute, [])
        // @ts-expect-error - TS7006 - Parameter 'agency' implicitly has an 'any' type.
        .map((agency) => (typeof agency === 'object' ? agency.externalID : agency));

    // from the search page we will have an ObjectRefinementFilter for the ownerID, whereas in the
    // agency detail page we will have a NumericExactFilter for it
    const ownerFilterValue = filters.getFilterValue(FilterValues.agentOwner.attribute, []);
    let agents = [];
    if (typeof ownerFilterValue === 'object') {
        // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2339 - Property 'map' does not exist on type 'object'. | TS7006 - Parameter 'agent' implicitly has an 'any' type.
        agents = ownerFilterValue.map((agent) =>
            typeof agent === 'object' ? agent.externalID : agent,
        );
    }

    const residenceTypeFilter = filters.getFilter(FilterValues.residenceType.attribute);

    return {
        purpose: filters.getFilterValue(FilterValues.purpose.attribute),
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        category: filters.getFilterValue(FilterValues.category.attribute, {}).slug || null,
        // @ts-expect-error - TS7006 - Parameter 'locationSlug' implicitly has an 'any' type.
        locations: locations.map((locationSlug) => ({ slug: locationSlug })),
        ...locationsByLanguage,
        agencies,
        agents,
        excludeAgencies: filters.getFilterValue(FilterValues.excludeAgencies.attribute),
        page: filters.getFilterValue(FilterValues.page.attribute, 1),
        rentFrequency: filters.getFilterValue(
            FilterValues.rentFrequency.attribute,
            FilterValues.rentFrequency.disablingValue,
        ),
        price: filters.getFilterValue(FilterValues.price.attribute),
        area: filters.getFilterValue(FilterValues.area.attribute),
        beds: filters.getFilterValue(FilterValues.beds.attribute, []),
        baths: filters.getFilterValue(FilterValues.baths.attribute, []),
        keywords: filters.getFilterValue(FilterValues.keywords.attribute, []),
        amenities: CONFIG.build.ENABLE_MERGED_INDEX
            ? getLocalizedAttributeNames(FilterValues.amenities.attribute)
                  .map((localizedAttribute) => filters.getFilterValue(localizedAttribute, []))
                  .flat()
            : filters.getFilterValue(FilterValues.amenities.attribute, []),
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        city: filters.getFilterValue(FilterValues.city.attribute, {}).slug || null,
        videoCount: filters.getFilterValue(FilterValues.videoCount.attribute),
        photoCount: filters.getFilterValue(FilterValues.photoCount.attribute),
        occupancyStatus: filters.getFilterValue(FilterValues.occupancyStatus.attribute),
        ownershipStatus: filters.getFilterValue(FilterValues.ownershipStatus.attribute),
        isVerified: filters.getFilterValue(FilterValues.isVerified.attribute),
        propertyTour: filters.getFilterValue(FilterValues.propertyTour.attribute),
        paymentType: filters.getFilterValue(FilterValues.paymentType.attribute),
        timeSinceCreation: filters.getFilterValue(FilterValues.timeSinceCreation.attribute),
        agencySlug: filters.getFilterValue(FilterValues.agencySlug.attribute),
        agencyTier: filters.getFilterValue(FilterValues.agencyTier.attribute),
        productType: filters.getFilterValue(FilterValues.product.attribute, []),
        completionStatus: filters.getFilterValue(
            FilterValues.completionStatus.attribute,
            FilterValues.residenceType.default,
        ),
        furnishingStatus: filters.getFilterValue(FilterValues.furnishingStatus.attribute),
        saleType: filters.getFilterValue(FilterValues.saleType.attribute),
        residenceType:
            residenceTypeFilter && residenceTypeFilter.active ? residenceTypeFilter.value : null,
        commute: filters.getFilterValue('latLong'),
        panoramaCount: filters.getFilterValue(FilterValues.panoramaCount.attribute),
        hasFloorPlan: filters.getFilterValue(FilterValues.floorPlanID.attribute),
        hasTransactionHistory: filters.getFilterValue(FilterValues.hasTransactionHistory.attribute),
        listingType: filters.getFilterValue(FilterValues.type.attribute),
        completionPercentage: filters.getFilterValue(FilterValues.completionPercentage.attribute),
        preHandoverPayment: filters.getFilterValue(FilterValues.preHandoverPayment.attribute),
        handoverDate: filters.getFilterValue(FilterValues.handoverDate.attribute),
        ...extraParams,
    };
};

export { selectRouteParamsInput, createSearchRouteParams, createSearchURL, selectSearchLink };
