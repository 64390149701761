import React from 'react';

import { SaveCard } from 'horizontal/payment';
import { GTMPaymentSuccessDataLayer } from 'horizontal/gtm/components';
import type { PaymentOrder } from 'horizontal/payment';
import type { CheckoutCreditCard } from 'horizontal/payment/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

const SaveCardPage = {
    head: (paymentOrder: PaymentOrder) => (
        <>
            <PrivateNonIndexableHead />
            <GTMPaymentSuccessDataLayer paymentOrder={paymentOrder} />
        </>
    ),
    body: ({
        productPackageNames,
        status,
        instrument,
        orderExternalID,
    }: {
        productPackageNames: string;
        status: string;
        orderExternalID: string;
        instrument: CheckoutCreditCard;
    }) => (
        <SaveCard
            productPackageNames={productPackageNames}
            status={status}
            instrument={instrument}
            orderExternalID={orderExternalID}
        />
    ),
} as const;

export default SaveCardPage;
