import React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { AdManagementRouteParams } from 'horizontal/routes/adManagementRoute';

import useAdManagementFilters from './useAdManagementFilters';

const useSetAdManagementFilters = () => {
    const router = useRouter();
    const filters = useAdManagementFilters();

    return React.useCallback(
        (params: Partial<AdManagementRouteParams>) => {
            router.pushRoute(RouteNames.AD_MANAGEMENT, {
                ...filters,
                ...params,
            });
        },
        [router, filters],
    );
};

export default useSetAdManagementFilters;
