import * as React from 'react';
import { Trans } from '@lingui/macro';

import styles from './styles/bottomSheetCloseButton.cssm';

type Props = {
    onClick: () => void;
};

const BottomSheetCloseButton = ({ onClick }: Props) => (
    <button onClick={onClick} className={styles.closeButton} aria-label="Close button">
        <Trans>Close</Trans>
    </button>
);

export default BottomSheetCloseButton;
