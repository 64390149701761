import type { Primitive, Range } from './range';

export const CategoryFieldFilterType = Object.freeze({
    SINGLE_CHOICE: 'single_choice',
    MULTIPLE_CHOICES: 'multiple_choice',
    RANGE: 'range',
});

export enum CategoryFieldValueType {
    INTEGER = 'integer',
    STRING = 'string',
    FLOAT = 'float',
    BOOLEAN = 'boolean',
    ENUM = 'enum',
    ENUM_MULTIPLE = 'enum_multiple',
    DATETIME = 'datetime',
    GEOGRAPHY = 'geography',
}

export const CategoryFieldRole = Object.freeze({
    FILTERABLE: 'filterable',
    FORMATTABLE: 'formattable',
    INCLUDED_IN_PATHNAME: 'included_in_pathname',
    INCLUDED_IN_TITLE: 'included_in_title',
    INCLUDED_IN_BREADCRUMBS: 'included_in_breadcrumbs',
    INCLUDED_IN_SITEMAP: 'included_in_sitemap',
    OVERRIDES_CATEGORY_NAME: 'overrides_category_name',
    NON_FACETED: 'non_faceted',
    EXCLUDE_FROM_POST_AN_AD: 'exclude_from_post_an_ad',
    NON_EDITABLE: 'non_editable',
    SHOW_IN_SEARCH_HEADER: 'show_in_search_header',
    HIDE_IF_NO_RESULTS: 'hide_if_no_results',
});

export const CategoryFieldChoicesKeys = Object.freeze({
    ALL: 'all',
});

export type CategoryFieldChoicesKeysType = string | number | 'all';

export type CategoryFieldChoice = {
    readonly value: string;
    readonly slug?: string; // optional, use value if not available,
    readonly label: string;
    readonly id: number;
    readonly parentID?: number | null;
    readonly displayPriority: number;
};

export type CategoryFieldGroupedChoices = {
    readonly id: number;
    readonly choices: {
        readonly all: Array<CategoryFieldChoice>;
    };
    readonly label: string;
    readonly label_l1: string;
    readonly displayPriority: number;
};

export type CategoryFieldNoChoices = {
    readonly id: number;
    readonly categoryID: number | null;
    readonly name: string;
    readonly valueType: CategoryFieldValueType;
    readonly filterType: Values<typeof CategoryFieldFilterType>;
    readonly isMandatory: boolean;
    readonly parentID?: number | null | undefined;
    readonly displayPriority: number;
    readonly titlePriority: number;
    readonly attribute: string;
    readonly minValue?: number | null;
    readonly maxValue?: number | null;
    readonly minLength?: number | null;
    readonly maxLength?: number | null;
    readonly roles: Array<Values<typeof CategoryFieldRole>>;
    readonly groupIndex?: number;
    readonly maxFieldFacetSize?: number | null;
};

export type CategoryField = CategoryFieldNoChoices & {
    readonly choices?: Partial<Record<CategoryFieldChoicesKeysType, Array<CategoryFieldChoice>>>;
    readonly choiceGroups?: Array<CategoryFieldGroupedChoices>;
    readonly combinationChoices?: Partial<
        Record<CategoryFieldChoicesKeysType, Array<CategoryFieldChoice>>
    >;
};

export type FlatCategoryField = CategoryFieldNoChoices & {
    readonly choiceGroups?: Array<CategoryFieldGroupedChoices>;
    readonly choices: Array<CategoryFieldChoice>;
    readonly combinationChoices: Array<CategoryFieldChoice>;
    readonly children?: Array<FlatCategoryField>;
};

export type FlatCategoryFieldHierarchy = FlatCategoryField & {
    children: Array<FlatCategoryFieldHierarchy>;
};

export type CategoryFieldFilterValue = Range | Array<Primitive> | Primitive;

export type CategoryFieldsFilters = Partial<{
    [key: string]: CategoryFieldFilterValue;
}>;
