import * as React from 'react';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';
import { useSelector } from 'react-redux';
import { selectRouteName, selectRouteParams } from 'strat/routes/selectors';

import { selectBusinessPackageCartOfferAdID } from 'horizontal/businessPackages/selectors';
import type { ProductPurchase } from 'horizontal/types';

type Props = {
    readonly packageOffers?: Array<ProductPurchase> | null;
};

const useHandlePaymentSuccessRedirection = ({ packageOffers }: Props) => {
    const router = useRouter();
    const { orderExternalID, adExternalID, status } = useSelector(selectRouteParams);
    const routeName = useSelector(selectRouteName);
    const orderAdID = useSelector(selectBusinessPackageCartOfferAdID);

    React.useEffect(() => {
        /**
         * The below if statement is needed because there is a critical
         * issue in most of strat/horizontal project. The issue is that
         * when we enter a new route, we should in general render a new
         * page, but in a lot of cases, what we do is wait using
         * 'context.promise.wait' for some promises to resolve and then
         * we decide to render. This creates issues if re-rendering happens
         * before we render a new page, because this means some logic is
         * going to be executed just like this useEffect that we are in!
         */
        if (routeName !== RouteNames.PAYMENT_SUCCESS) {
            return;
        }
        /**
         * When we have an orderAdID defined then it means this was
         * on order that was made using `Select Multiple Packages` flow.
         * We replace the current route that doesn't have adExternalID
         * with a route that has that param.
         */
        if (packageOffers && !adExternalID && orderAdID) {
            router.replaceRoute(RouteNames.PAYMENT_SUCCESS, {
                adExternalID: orderAdID,
                orderExternalID,
                status,
            });
        }
    }, [packageOffers, adExternalID, orderAdID, orderExternalID, status, router, routeName]);
};

export default useHandlePaymentSuccessRedirection;
