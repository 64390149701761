import * as React from 'react';
import { useSearchSortOption } from '@sector-labs/fe-search-redux/state';
import { Dropdown } from 'strat/modal';
import { Flex } from 'strat/components';

import type { SearchSortOption } from 'horizontal/types';
import { trackSortChange } from 'horizontal/gtm';

import { useNavigateToRefinedSearch } from '../routing';

import { useSortOptions } from './sortOptions';
import SortOptionsDropdownItem from './sortOptionsDropdownItem';
import SortOptionsDropdownTrigger from './sortOptionsDropdownTrigger';
import styles from './styles/sortOptionsDropdown.cssm';

const SortOptionsDropdown = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const options = useSortOptions();
    const currentOption = useSearchSortOption();
    const navigateToSearch = useNavigateToRefinedSearch();

    const renderTrigger = React.useCallback(
        () => (
            <SortOptionsDropdownTrigger
                // @ts-expect-error - TS2339 - Property 'label' does not exist on type 'SearchRequestSortOption'.
                label={currentOption?.label || ''}
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
            />
        ),
        [currentOption, setIsOpen, isOpen],
    );

    const selectOption = React.useCallback(
        (option: SearchSortOption) => {
            trackSortChange(option.key);
            navigateToSearch({ sortOption: option });
            setIsOpen(false);
        },
        [navigateToSearch],
    );

    return (
        <Flex alignCenter className={styles.container}>
            <Dropdown
                open={isOpen}
                renderTrigger={renderTrigger}
                onDismissed={() => setIsOpen(false)}
            >
                <ul role="listbox" className={styles.content}>
                    {options.map((option: SearchSortOption) => (
                        <SortOptionsDropdownItem
                            key={option.key}
                            option={option}
                            isSelected={currentOption?.key === option.key}
                            onClick={() => selectOption(option)}
                        />
                    ))}
                </ul>
            </Dropdown>
        </Flex>
    );
};

export default SortOptionsDropdown;
