import getDataLayerVariables from 'strat/gtm/getDataLayerVariables';

const getDefaultDataLayerVariables = () => {
    const dataLayerName = CONFIG.build.GTM_DATA_LAYER_NAME || null;

    // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
    if (dataLayerName === null || window[dataLayerName] === undefined) {
        return;
    }
    return getDataLayerVariables(dataLayerName);
};

export default getDefaultDataLayerVariables;
