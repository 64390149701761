import * as React from 'react';
import className from 'classnames';
import { Text } from 'strat/components';

import type { FlatCategoryField } from 'horizontal/types';
import { trackFilterChange } from 'horizontal/gtm';

import { RefinedSearchLink } from '../routing';

import useFilter from './useFilter';
import Expandable from './expandable';
import styles from './styles/singleChoice.cssm';
import ChoiceCountLabel from './choiceCountLabel';
import useDisplayPriorityFilteredFacetedChoices from './useDisplayPriorityFilteredFacetedChoices';

type Props = {
    readonly field: FlatCategoryField;
};

const SingleChoice = ({ field }: Props) => {
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    const filter = useFilter(field);
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Primitive'.
    const choices = useDisplayPriorityFilteredFacetedChoices(field, filter.value);

    const newParams = React.useCallback(
        (value) => ({
            extraFields: { [field.attribute]: filter.value !== value ? value : null },
        }),
        [filter.value, field.attribute],
    );

    return (
        <Expandable>
            {choices.map((choice) => (
                <RefinedSearchLink
                    params={newParams(choice.value)}
                    key={choice.value}
                    onClick={() => trackFilterChange(field.attribute, choice.value)}
                >
                    <div
                        key={choice.value}
                        className={className(styles.entry, {
                            [styles.selected]: filter.value === choice.value,
                        })}
                    >
                        <Text.Base className={styles.label}>{choice.label}</Text.Base>
                        {CONFIG.runtime.STRAT_ENABLE_OLX_SHOW_AD_COUNT && !!choice.count && (
                            <ChoiceCountLabel count={choice.count} />
                        )}
                    </div>
                </RefinedSearchLink>
            ))}
        </Expandable>
    );
};

export default SingleChoice;
