import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import pickBy from 'lodash/pickBy';
import TimeDelta from '@sector-labs/fe-search-redux/timeDelta';
import Category from '@app/branding/category';

import type { FilterValues } from 'strat/branding/types';
import { PropertyCompletionStatus } from 'strat/property/types';

// TODO: This should not be in strat.
const categoryTree = (i18n: I18n) => ({
    // @ts-ignore
    [Category.RESIDENTIAL]: {
        name: t(i18n)`Properties`,
        nameSingular: t(i18n)`Residential`,
        nameCompact: t(i18n)`Residential`,
        externalID: '1',
        children: {
            apartments: {
                localizedSlug: t(i18n)`apartments`,
                name: t(i18n)`Apartments`,
                nameSingular: t(i18n)`Apartment`,
                externalID: '4',
                popularity: 0,
            },
            townhouses: {
                name: t(i18n)`Townhouses`,
                nameSingular: t(i18n)`Townhouse`,
                externalID: '16',
                popularity: 2,
            },
            'villa-compound': {
                name: t(i18n)`Villa Compounds`,
                nameSingular: t(i18n)`Villa Compound`,
                externalID: '19',
                popularity: 4,
            },
            'residential-plots': {
                name: t(i18n)`Residential Plots`,
                nameSingular: t(i18n)`Residential Plot`,
                externalID: '14',
                popularity: 6,
            },
            'residential-building': {
                name: t(i18n)`Residential Buildings`,
                nameSingular: t(i18n)`Residential Building`,
                externalID: '17',
                popularity: 8,
            },
            villas: {
                localizedSlug: t(i18n)`villas`,
                name: t(i18n)`Villas`,
                nameSingular: t(i18n)`Villa`,
                externalID: '3',
                popularity: 1,
            },
            penthouse: {
                name: t(i18n)`Penthouses`,
                nameSingular: t(i18n)`Penthouse`,
                externalID: '18',
                popularity: 3,
            },
            'hotel-apartments': {
                name: t(i18n)`Hotel Apartments`,
                nameSingular: t(i18n)`Hotel Apartment`,
                externalID: '21',
                popularity: 5,
            },
            'residential-floors': {
                name: t(i18n)`Residential Floors`,
                nameSingular: t(i18n)`Residential Floor`,
                externalID: '12',
                popularity: 7,
            },
        },
    },
    // @ts-ignore
    [Category.COMMERCIAL]: {
        name: t(i18n)`Commercial Properties`,
        nameSingular: t(i18n)`Commercial`,
        nameCompact: t(i18n)`Commercial`,
        externalID: '2',
        children: {
            offices: {
                name: t(i18n)`Offices`,
                nameSingular: t(i18n)`Office`,
                externalID: '5',
                popularity: 0,
            },
            warehouses: {
                name: t(i18n)`Warehouses`,
                nameSingular: t(i18n)`Warehouse`,
                externalID: '7',
                popularity: 2,
            },
            'commercial-villas': {
                name: t(i18n)`Commercial Villas`,
                nameSingular: t(i18n)`Commercial Villa`,
                externalID: '25',
                popularity: 4,
            },
            'commercial-plots': {
                name: t(i18n)`Commercial Plots`,
                nameSingular: t(i18n)`Commercial Plot`,
                externalID: '15',
                popularity: 6,
            },
            'commercial-buildings': {
                name: t(i18n)`Commercial Buildings`,
                nameSingular: t(i18n)`Commercial Building`,
                externalID: '10',
                popularity: 8,
            },
            'industrial-land': {
                name: t(i18n)`Industrial Land`,
                nameSingular: t(i18n)`Industrial Land`,
                externalID: '22',
                popularity: 10,
            },
            showrooms: {
                name: t(i18n)`Showrooms`,
                nameSingular: t(i18n)`Showroom`,
                externalID: '24',
                popularity: 12,
            },
            shops: {
                name: t(i18n)`Shops`,
                nameSingular: t(i18n)`Shop`,
                externalID: '6',
                popularity: 1,
            },
            'labour-camps': {
                name: t(i18n)`Labour Camps`,
                nameSingular: t(i18n)`Labour Camp`,
                externalID: '9',
                popularity: 3,
            },
            'bulk-units': {
                name: t(i18n)`Bulk Units`,
                nameSingular: t(i18n)`Bulk Unit`,
                externalID: '20',
                popularity: 5,
            },
            'commercial-floors': {
                name: t(i18n)`Commercial Floors`,
                nameSingular: t(i18n)`Commercial Floor`,
                externalID: '13',
                popularity: 7,
            },
            factories: {
                name: t(i18n)`Factories`,
                nameSingular: t(i18n)`Factory`,
                externalID: '8',
                popularity: 9,
            },
            'mixed-use-land': {
                name: t(i18n)`Mixed Use Land`,
                nameSingular: t(i18n)`Mixed Use Land`,
                externalID: '23',
                popularity: 11,
            },
            'commerical-properties': {
                localizedSlug: t(i18n)`commercial-properties`,
                name: t(i18n)`Other Commercial`,
                nameSingular: t(i18n)`Other Commercial`,
                externalID: '11',
                popularity: 13,
            },
        },
    },
});

const dtResidentialCategoriesIds = ['3', '4', '14', '16', '17', '18', '21'];
const dtCommercialCategoriesIds = [
    '5',
    '6',
    '7',
    '8',
    '9',
    '25',
    '15',
    '10',
    '22',
    '23',
    '24',
    '11',
];
const truCheckCategoriesExternalIds = [
    '1' /* Residential */,
    '2' /* Commercial */,
    '3' /* Villas */,
    '4' /* Apartments */,
    '5' /* Offices */,
    '6' /* Shops */,
    '18' /* Penthouse */,
    '24' /* Showrooms */,
    '10' /* Commercial-Buildings */,
    '25' /* Commercial-Villas */,
    '17' /* Residential-Building */,
    '21' /* Hotel-Apartments */,
    '16' /* Townhouses */,
];

const ContractRenewalValue = Object.freeze({
    ALL: 'All',
    NEW: 'New',
    RENEWAL: 'Renewal',
});

export type ContractRenewal = {
    name: string;
    value: string;
};

const filterValues: FilterValues = {
    location: {
        placeholder: '/uae',
        placeholderName: (i18n: I18n): string => t(i18n)`UAE`,
        placeholders: { en: '/uae', ar: '/uae', zh: '/uae' },
    },
    category: {
        // @ts-ignore
        canBeTruChecked: (externalID: string) =>
            !externalID || truCheckCategoriesExternalIds.includes(externalID),
        // @ts-ignore
        tree: (i18n: I18n) => categoryTree(i18n),
        // @ts-ignore
        dtTree: (i18n: I18n) => ({
            // @ts-ignore
            [Category.RESIDENTIAL]: {
                // @ts-ignore
                ...categoryTree(i18n)[Category.RESIDENTIAL],
                // @ts-ignore
                children: pickBy(categoryTree(i18n)[Category.RESIDENTIAL].children, (value) =>
                    // @ts-ignore
                    dtResidentialCategoriesIds.includes(value.externalID),
                ),
            },
            // @ts-ignore
            [Category.COMMERCIAL]: {
                // @ts-ignore
                ...categoryTree(i18n)[Category.COMMERCIAL],
                // @ts-ignore
                children: pickBy(categoryTree(i18n)[Category.COMMERCIAL].children, (value) =>
                    // @ts-ignore
                    dtCommercialCategoriesIds.includes(value.externalID),
                ),
            },
        }),
    },
    completionStatus: {
        // @ts-expect-error - TS7006 - Parameter 'i18n' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
        choice: (i18n: I18n, value) => {
            switch (value) {
                case PropertyCompletionStatus.ANY:
                    return {
                        name: t(i18n)`Any-Completion Status`,
                        value: PropertyCompletionStatus.ANY,
                    };
                case PropertyCompletionStatus.COMPLETED:
                    return { name: t(i18n)`Ready`, value: PropertyCompletionStatus.COMPLETED };
                case PropertyCompletionStatus.OFF_PLAN:
                    return { name: t(i18n)`Off-Plan`, value: PropertyCompletionStatus.OFF_PLAN };
                default:
                    return { name: t(i18n)`Any`, value: PropertyCompletionStatus.ANY };
            }
        },
        choices(i18n: any) {
            return [
                this.choice(i18n, PropertyCompletionStatus.ANY),
                this.choice(i18n, PropertyCompletionStatus.COMPLETED),
                this.choice(i18n, PropertyCompletionStatus.OFF_PLAN),
            ];
        },
        queryParamValues: [
            { filterValue: PropertyCompletionStatus.COMPLETED, queryParamValue: 'ready' },
            { filterValue: PropertyCompletionStatus.OFF_PLAN, queryParamValue: 'off-plan' },
            { filterValue: PropertyCompletionStatus.ANY, queryParamValue: 'any' },
        ],
        // @ts-expect-error - TS7006 - Parameter 'i18n' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
        render: (i18n: I18n, value) => {
            switch (value) {
                case PropertyCompletionStatus.COMPLETED:
                    return t(i18n)`Ready`;
                case PropertyCompletionStatus.OFF_PLAN:
                    return t(i18n)`Off-Plan`;
                case PropertyCompletionStatus.ANY:
                default:
                    return null;
            }
        },
    },
    timeSinceCreation: {
        choice: (i18n: I18n, value: string) => {
            switch (value) {
                case String(new TimeDelta({ months: 1 })):
                    return { name: t(i18n)`Last month`, value };
                case String(new TimeDelta({ months: 3 })):
                    return { name: t(i18n)`Last 3 months`, value };
                case String(new TimeDelta({ months: 6 })):
                    return { name: t(i18n)`Last 6 months`, value };
                case String(new TimeDelta({ months: 12 })):
                    return { name: t(i18n)`Last 12 months`, value };
                case String(new TimeDelta({ months: 36 })):
                    return { name: t(i18n)`Last 3 years`, value };
                default:
                    return {
                        name: t(i18n)`Last 12 months`,
                        value: String(new TimeDelta({ months: 12 })),
                    };
            }
        },
        choices(i18n: I18n) {
            return [
                this.choice(i18n, String(new TimeDelta({ months: 1 }))),
                this.choice(i18n, String(new TimeDelta({ months: 3 }))),
                this.choice(i18n, String(new TimeDelta({ months: 6 }))),
                this.choice(i18n, String(new TimeDelta({ months: 12 }))),
                this.choice(i18n, String(new TimeDelta({ months: 36 }))),
            ];
        },
        mapChoices(i18n: I18n) {
            return [
                this.choice(i18n, String(new TimeDelta({ months: 1 }))),
                this.choice(i18n, String(new TimeDelta({ months: 3 }))),
                this.choice(i18n, String(new TimeDelta({ months: 6 }))),
            ];
        },
        mapChoicesMobileRent(i18n: I18n) {
            return [
                this.choice(i18n, String(new TimeDelta({ months: 1 }))),
                this.choice(i18n, String(new TimeDelta({ months: 3 }))),
            ];
        },
        default: { value: String(new TimeDelta({ months: 12 })) },
        defaultMap: { value: String(new TimeDelta({ months: 6 })) },
        defaultMapMobileRent: { value: String(new TimeDelta({ months: 3 })) },
    },
};

export default filterValues;
export { dtResidentialCategoriesIds, dtCommercialCategoriesIds, ContractRenewalValue };
