import { useSelector } from 'react-redux';
import { Actions, PageParameters } from 'strat/search/state';

import { LiteAd } from 'horizontal/types';

import { selectRemarketedListings, selectShowRemarketedListing } from './selectors';

const setRemarketedListing = (remarketedListing: LiteAd) => ({
    type: Actions.SET_REMARKETED_LISTINGS,
    remarketedListings: [remarketedListing],
});

const setRemarketedListings = (remarketedListings: LiteAd[]) => ({
    type: Actions.SET_REMARKETED_LISTINGS,
    remarketedListings,
});

/**
 * Sets search page parameters.
 */
const setPageParameters = (pageParameters: Partial<PageParameters>) => (dispatch: any) => {
    dispatch({
        type: Actions.SET_PAGE_PARAMETERS,
        pageParameters,
    });
};

const useRemarketedListings = (): LiteAd[] => useSelector(selectRemarketedListings);

const useShowRemarketedListing = (): boolean => !!useSelector(selectShowRemarketedListing);

export {
    setRemarketedListing,
    setRemarketedListings,
    useRemarketedListings,
    useShowRemarketedListing,
    setPageParameters,
};
