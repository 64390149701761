const topAmenities = [
    {
        slug: 'chiller-free',
        rank: 1,
    },
    {
        slug: 'furnished',
        rank: 2,
    },
    {
        slug: 'kitchen-appliances',
        rank: 3,
    },
    {
        slug: 'built-in-wardrobes',
        rank: 4,
    },
    {
        slug: 'maids-room',
        rank: 5,
    },
    {
        slug: 'balcony-or-terrace',
        rank: 6,
    },
    {
        slug: 'centrally-air-conditioned',
        rank: 7,
    },
    {
        slug: 'covered-parking',
        rank: 8,
    },
    {
        slug: 'floor',
        rank: 9,
    },
    {
        slug: 'shared-kitchen',
        rank: 10,
    },
    {
        slug: 'parking-spaces',
        rank: 11,
    },
    {
        slug: 'swimming-pool',
        rank: 12,
    },
    {
        slug: 'view-icon',
        rank: 13,
    },
    {
        slug: 'jacuzzi',
        rank: 14,
    },
    {
        slug: 'sauna',
        rank: 15,
    },
    {
        slug: 'steam-room',
        rank: 16,
    },
];

const getAmenities = (): Set<any> => {
    const slugsSet = new Set();
    topAmenities.forEach((amenity) => slugsSet.add(amenity.slug));

    return slugsSet;
};
const slugsSet = getAmenities();

export default slugsSet;
