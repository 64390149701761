import Category from '@app/branding/category';

import type { MixedAd } from 'horizontal/types';
import { AdCommonExtraField } from '@app/branding/adExtraFields';

import type { PropertyProductSchema } from './types';
import useProductSchema from './useProductSchema';
import useAdExtraFieldLabel from './useAdExtraFieldLabel';

const determineSchemaType = (ad?: MixedAd | null): string | null | undefined => {
    const isHouse = ad && ad.category.some((category) => Category.isOfHouseType(category));
    if (isHouse) {
        return 'House';
    }

    const isApartment = ad && ad.category.some((category) => Category.isOfApartmentType(category));
    if (isApartment) {
        return 'Apartment';
    }

    return null;
};

const usePropertyProductSchema = (
    ad?: MixedAd | null,
): PropertyProductSchema | null | undefined => {
    const productSchema = useProductSchema(ad);
    const schemaType = determineSchemaType(ad);

    const bedroomCount = useAdExtraFieldLabel(ad, AdCommonExtraField.BEDROOMS);
    const bathroomCount = useAdExtraFieldLabel(ad, AdCommonExtraField.BATHROOMS);
    const area = useAdExtraFieldLabel(ad, AdCommonExtraField.AREA);
    const areaUnit = useAdExtraFieldLabel(ad, AdCommonExtraField.AREA_UNIT);

    if (!ad || !productSchema || !schemaType) {
        return null;
    }

    const schema: PropertyProductSchema = {
        ...productSchema,
        '@type': ['Product', schemaType],
        address: ad.location
            .map(({ name }) => name)
            .reverse()
            .join(', '),
    };

    if (bedroomCount) {
        schema.numberOfRooms = bedroomCount;
    }

    if (bathroomCount) {
        schema.numberOfBathroomsTotal = bathroomCount;
    }

    if (area && areaUnit) {
        schema.floorSize = {
            '@type': 'QuantitativeValue',
            unitText: areaUnit,
            value: area,
        };
    }

    return schema;
};

export default usePropertyProductSchema;
