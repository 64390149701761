import * as React from 'react';

/**
 * Custom hook that shows dismissible dialogs and/or pop-ups when a
 * certain target element is focused.
 *
 * This target element can be something like an input or a textarea,
 * and the dismissible dialogs/pop-ups are components that under the hood
 * make use of {@see Dismissible} to close themselves when a click is made
 * somewhere else on the page.
 *
 * This workaround is needed because simply using `onFocus` on the target
 * element to display the dialog is not going to work for the scenario where
 * the user focuses on the target element by left clicking it.
 *
 * The order in which events are triggered on an element for a left mouse
 * click is: `mousedown`, `focus`, `mouseup`, `click`.
 *
 * If we display the dialog on the `focus` event, then {@see Dismissible} will
 * immediately close the dialog when the `click` event occurs (when the mouse
 * is released).
 *
 * To avoid this, we need to make sure that:
 * - we display the dialog/pop-up on the `focus` event only if it is not
 *   followed up by a `click` event (e.g. we navigated to the target element
 *   using TAB, made a right click on it, etc.)
 * - otherwise, we wait for the `click` event to finsih, which can happen
 *   anywhere on the page, and only then we display the dialog/pop-up
 *
 * Usage:
 * const [
 *     isDialogVisible,
 *     setIsDialogVisible,
 *     onMouseDown,
 *     onFocus,
 *   ] = useShowDismissibleOnTargetFocus();
 * ...
 * <TargetElement onMouseDown={onMouseDown} onFocus={onFocus} />
 * <DismissibleDialog visible={isDialogVisible} onVisibilityChanged={setIsDialogVisible} />
 */
const useShowDismissibleOnTargetFocus = (): [
    boolean,
    (arg1: ((arg1: boolean) => boolean) | boolean) => void,
    (event: React.MouseEvent<any>) => void,
    () => void,
] => {
    const [isDismissibleVisible, setIsDismissibleVisible] = React.useState(false);
    const [isTargetInitiatedClickEvent, setIsTargetInitiatedClickEvent] = React.useState(false);

    const handleTargetInitiatedClickEvent = () => {
        setIsDismissibleVisible(true);
        setIsTargetInitiatedClickEvent(false);
    };

    React.useEffect(() => {
        if (isTargetInitiatedClickEvent) {
            document.addEventListener('click', handleTargetInitiatedClickEvent);
        }
        return () => document.removeEventListener('click', handleTargetInitiatedClickEvent);
    }, [isTargetInitiatedClickEvent]);

    const onMouseDown = (event: React.MouseEvent<any>) => {
        const isLeftClick = event.button === 0 && !event.ctrlKey;
        if (isLeftClick) {
            setIsTargetInitiatedClickEvent(true);
        }
    };

    const onFocus = () => {
        if (!isTargetInitiatedClickEvent) {
            setIsDismissibleVisible(true);
        }
    };

    return [isDismissibleVisible, setIsDismissibleVisible, onMouseDown, onFocus];
};

export default useShowDismissibleOnTargetFocus;
