import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { VerticalScroller } from 'strat/navigation';
import { PageWidthWrapper } from 'strat/generic';
import selectWallpaperTakeover from 'strat/wallpaper/selectors';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import Footer from 'horizontal/footer';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import { FiltersList, SearchHeading, MainSearchPageContent } from 'horizontal/search';
import { SearchBanner, Position, Sizes } from 'horizontal/tagmanager';
import { CustomURLSpecificContent } from 'horizontal/urlSpecificSEO';
import Wallpaper from 'horizontal/wallpaper/wallpaper';
import { SearchDebugConsole } from 'horizontal/search/debug';
import { useGetWallpaperForSize } from 'horizontal/util';
import {
    FrequentSearchQueries,
    useFrequentSearchQueries,
} from 'horizontal/search/frequentSearchQueries';
import { SearchUrlUpdater } from 'horizontal/search';

import GptTokenResetter from './gptTokenResetter';
import styles from './styles/searchPageDesktop.cssm';

const SearchBodyDesktop = () => {
    const wallpaper = useSelector(selectWallpaperTakeover);
    const isWallpaperActive = useGetWallpaperForSize(wallpaper);
    const frequentSearchQueries = useFrequentSearchQueries();

    return (
        <Flex column stretchHeight fillContainer>
            <SearchUrlUpdater />
            <SearchDebugConsole />
            <GptTokenResetter />
            <VerticalScroller />
            <Header withHighlightedVertical />
            <CategoryDropdownSwitcher />
            <SearchBanner
                position={Position.TOP}
                sizes={Sizes.desktop.search.wallpaper}
                disableStyle
            />
            <Wallpaper>
                <Flex fillContainer>
                    <PageWidthWrapper
                        className={classNames(styles.containerPageWidth, {
                            [styles.wallpaperBannerAd]: isWallpaperActive,
                            margin: wallpaper.targetURL === null,
                        })}
                    >
                        <SearchBanner sizes={Sizes.desktop.search.top} position={Position.TOP} />
                        <Flex column stretchWidth fillContainer className={styles.container}>
                            <SearchHeading />
                            <Flex stretchWidth className={styles.content}>
                                <FiltersList />
                                <MainSearchPageContent />
                            </Flex>
                            {!!frequentSearchQueries.length && (
                                <div className={styles.frequentSearchQueries}>
                                    <FrequentSearchQueries queries={frequentSearchQueries} />
                                </div>
                            )}
                        </Flex>
                        <SearchBanner
                            sizes={Sizes.desktop.search.bottom}
                            position={Position.BOTTOM}
                        />
                        <CustomURLSpecificContent />
                    </PageWidthWrapper>
                </Flex>
                <Footer
                    className={classNames({
                        [styles.wallpaperActive]: isWallpaperActive,
                    })}
                />
            </Wallpaper>
        </Flex>
    );
};

export default SearchBodyDesktop;
