import * as React from 'react';
import { useSelector } from 'react-redux';

import styles from 'strat/listing/styles/listingInfoOnSearchPage.cssm';
import type { PropertyData } from 'strat/property';
import { selectIsPurposeForRent } from 'strat/search/selectors';

import connectPropertyHits from '../connectPropertyHits';

const getHitWithPrice = (
    hits: Array<PropertyData>,
    fun: (arg1: number, arg2: number) => number,
): number | null => {
    const hitsWithPrice = hits.filter((hit) => hit.price);
    if (hitsWithPrice.length === 0) {
        return null;
    }

    return hitsWithPrice.reduce(
        (price: number, property: PropertyData) => fun(price, property.price),
        hitsWithPrice[0].price,
    );
};

type Props = {
    hits: Array<PropertyData>;
};

const PriceRange = ({ hits }: Props) => {
    const className = styles.priceRange;
    const isPurposeForRent = useSelector(selectIsPurposeForRent);

    const lowPrice = getHitWithPrice(hits, Math.min);
    const highPrice = getHitWithPrice(hits, Math.max);

    if (lowPrice === null || highPrice === null) {
        return false;
    }

    // the element is not visible
    // it is there only for SEO purposes
    return (
        <span
            key="price"
            itemScope
            itemType="http://schema.org/AggregateOffer"
            className={className}
        >
            <span itemProp="lowPrice">
                <b>{lowPrice}</b>
            </span>{' '}
            to{' '}
            <span itemProp="highPrice">
                <b>{highPrice}</b>
            </span>
            <span itemProp="priceCurrency"> AED</span> {isPurposeForRent ? 'yearly' : ''}
        </span>
    );
};

// @ts-expect-error - TS2345 - Argument of type '({ hits }: Props) => false | JSX.Element' is not assignable to parameter of type 'ComponentType<never>'.
export default connectPropertyHits(PriceRange);
export { getHitWithPrice };
