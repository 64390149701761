import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import AdCardSubtitleCategories from '@app/branding/adCardSubtitleCategories';
import IconArea from '@app/assets/icons/iconArea.svg';
import settings from '@app/branding/settings';

import type { MixedAd } from 'horizontal/types';
import IconBeds from 'horizontal/assets/icons/iconBeds.svg';
import IconBaths from 'horizontal/assets/icons/iconBaths.svg';
import { BasicTag } from 'horizontal/adCard/tags';

import useFormatAdExtraFieldsToObject from './useFormatAdExtraFieldsToObject';
import { areaUnitAbbreviations } from './constants';
import styles from './styles/adSubtitle.cssm';

type Props = {
    ad: MixedAd;
};

const AdPropertySubtitle = ({ ad }: Props) => {
    const extraFields = useFormatAdExtraFieldsToObject(ad);

    // Field keys so we can fetch the proper value from the extraFields
    const fieldNames = AdCardSubtitleCategories?.PROPERTIES.fields;
    const i18n = useI18n();

    if (!fieldNames) {
        return null;
    }

    const beds = extraFields[fieldNames.bedrooms];
    const bathrooms = extraFields[fieldNames.bathrooms];
    // @ts-ignore
    const areaUnitField = extraFields?.[fieldNames.area_unit] || settings.defaultAreaUnit;
    const areaUnit =
        areaUnitField in areaUnitAbbreviations
            ? areaUnitAbbreviations[areaUnitField]
            : areaUnitField;
    const area = t(i18n)`${extraFields[fieldNames.area]} ${areaUnit}`;

    return (
        <span className={styles.container}>
            <BasicTag
                text={beds}
                renderIcon={(className) => <IconBeds className={className} />}
                ariaLabel={'Bedrooms'}
            />
            <span className={styles.separator} />
            <BasicTag
                text={bathrooms}
                renderIcon={(className) => <IconBaths className={className} />}
                ariaLabel={'Bathrooms'}
            />
            <span className={styles.separator} />
            <BasicTag
                text={area}
                renderIcon={(className) => <IconArea className={className} />}
                ariaLabel={'Area'}
                withEllipsis
            />
        </span>
    );
};

export default AdPropertySubtitle;
