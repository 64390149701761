import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import NoHitsImage from 'horizontal/search/results/noHitsImage';

const NoHits = () => {
    const i18n = useI18n();

    return (
        <Flex column alignCenter>
            <NoHitsImage />
            <Text.Regular bold>{t(i18n)`There are no ads`}</Text.Regular>
            <Text.Regular>{t(i18n)`When users post ads, they will appear here`}</Text.Regular>
        </Flex>
    );
};

export default NoHits;
