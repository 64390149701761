import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';
import { selectGeoLocationData } from 'strat/user/selectors';
import useRestrictLocationLevel from '@app/search/location/useRestrictLocationLevel';
import LocationDropdown from '@app/search/location/locationDropdown';
import CategoryTypeChecker from '@app/branding/category';
import { setGeoLocation } from 'strat/user/state';
import { Flex, Text } from 'strat/components';

import { useFullLocationsHierarchy } from 'horizontal/location';
import {
    useCurrentSearchRouteParams,
    useNavigateToSearch,
    RefinedSearchLink,
} from 'horizontal/search/routing';
import { useActiveCategoryHierarchy } from 'horizontal/categories';
import type { Location } from 'horizontal/types';

import { FilterContainer, TreeNode, TreeNodeList } from '../filters';

import useLocationFacets from './useLocationFacets';
import useLocationFilterValue from './useLocationFilterValue';
import styles from './styles/locationFilterTree.cssm';

const LocationTreeNode = ({
    location,
    count,
    selected,
    onClick,
}: {
    location: Location;
    count?: number;
    selected: boolean;
    onClick?: () => void;
}) => (
    <RefinedSearchLink params={{ location }} onClick={onClick}>
        <TreeNode label={location.name} count={count} selected={selected} />
    </RefinedSearchLink>
);

const LocationTreeLevel = ({
    level,
    activeHierarchy,
}: {
    level: number;
    activeHierarchy: Array<Location>;
}) => {
    const activeNode = activeHierarchy[level];
    const activeLeafNode = activeHierarchy[activeHierarchy.length - 1];

    const facets = useLocationFacets(level, activeHierarchy, activeLeafNode);
    const childFacets = useLocationFacets(level + 1, activeHierarchy, activeLeafNode);

    const dispatch = useDispatch();
    const resetGeoLocation = React.useCallback(
        () => dispatch(setGeoLocation(null, null)),
        [dispatch],
    );
    // If we have no facets at this level, or the next level
    // _does_ have facets, then just render the active node
    // without any count.
    if (activeNode && (!facets.length || childFacets.length)) {
        return (
            <TreeNodeList level={level}>
                <LocationTreeNode
                    location={activeNode}
                    selected={activeNode.externalID === activeLeafNode.externalID}
                    onClick={resetGeoLocation}
                />
            </TreeNodeList>
        );
    }

    return (
        <TreeNodeList level={level}>
            {facets.map(({ location, count }) => (
                <LocationTreeNode
                    key={location.id}
                    location={location}
                    count={count}
                    selected={location.externalID === activeLeafNode.externalID}
                />
            ))}
        </TreeNodeList>
    );
};

const LocationFilterTree = () => {
    const i18n = useI18n();
    const userLocation = useSelector(selectGeoLocationData);
    const location =
        useLocationFilterValue() ||
        userLocation.closestLocation ||
        brandingSettings.getTopLevelLocation(i18n);
    const [searchLocation, setSearchLocation] = React.useState<any>(null);

    const hierarchy = useFullLocationsHierarchy(location?.hierarchy || []);

    const navigateToSearch = useNavigateToSearch();
    const searchParams = useCurrentSearchRouteParams();

    const categories = useActiveCategoryHierarchy();

    const isProperty =
        CategoryTypeChecker &&
        categories.find((category) => CategoryTypeChecker.isOfPropertyType(category));

    const maxLocationLevel = isProperty ? undefined : brandingSettings.headerMaxLocationLevel;

    const restrictedHierarchySize = useRestrictLocationLevel(hierarchy.length + 1);
    const levels = React.useMemo(
        () =>
            // Render one level beyond the current location hierarchy
            // because the facets might provide children that we need
            // to render.
            Array(restrictedHierarchySize)
                .fill(null)
                .map((_, level) => (
                    <LocationTreeLevel key={level} level={level} activeHierarchy={hierarchy} />
                )),
        [hierarchy, restrictedHierarchySize],
    );

    const onLocationClicked = React.useCallback(
        (selectedLocation) => {
            navigateToSearch({ ...searchParams, location: selectedLocation });
            setSearchLocation(null);
        },
        [searchParams, navigateToSearch],
    );

    return (
        <Flex column className={styles.container}>
            <div className={styles.item}>
                <Text.Large bold>
                    <Trans>Location</Trans>
                </Text.Large>
            </div>
            <div className={styles.item}>
                <LocationDropdown
                    onLocationSelected={onLocationClicked}
                    renderSearchIcon={false}
                    location={searchLocation}
                    showCurrentLocationSelector={false}
                    maxLevel={maxLocationLevel}
                />
            </div>
            <FilterContainer className={styles.filterContainer}>{levels}</FilterContainer>
        </Flex>
    );
};

export default LocationFilterTree;
