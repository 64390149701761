import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useSearchPagination, useTotalSearchHitCount } from '@sector-labs/fe-search-redux/state';
import { selectRouteParams } from 'strat/routes/selectors';
import { useI18n } from 'strat/i18n/language';

import { GlobalState } from 'horizontal/state';
import { selectCategoryBySlug } from 'horizontal/categories';

const useProfilePaginationMessage = () => {
    const i18n = useI18n();
    const { category: categorySlug } = useSelector(selectRouteParams);
    const category = useSelector((state: GlobalState) => selectCategoryBySlug(state, categorySlug));
    const { name: categoryName } = category || {};
    const [pageNumber] = useSearchPagination();
    const [totalCounts] = useTotalSearchHitCount();
    const lowerBoundCount = totalCounts ? (pageNumber || 1) * 12 - 11 : 0;
    const upperBoundCount =
        (pageNumber || 1) * 12 >= totalCounts ? totalCounts : (pageNumber || 1) * 12;
    const adTranslation = totalCounts === 1 ? t(i18n)`Ad` : t(i18n)`Ads`;

    return t(i18n)`Showing ${lowerBoundCount} - ${upperBoundCount} out of ${totalCounts}${
        categoryName ? ` ${categoryName}` : ` ${adTranslation}`
    }`;
};

export default useProfilePaginationMessage;
