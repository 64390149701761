import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';

import type { UpdatedAdResponseProps } from '../types';
import { SearchJobName } from '../types';

const updatedAdsResponse = ({ searchHandler }: UpdatedAdResponseProps): SearchResponse | null => {
    const adsResponse = searchHandler.getResponseByJobName(SearchJobName.REGULAR);
    if (!adsResponse) {
        return null;
    }

    if (!adsResponse.hits.length) {
        return adsResponse;
    }

    return {
        ...adsResponse,
        hits: [...adsResponse.hits.map((hit) => ({ ...hit, requestIndex: 2 }))],
    };
};

export default updatedAdsResponse;
