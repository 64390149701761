import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import { selectCurrency, Currency } from 'strat/i18n/money';
import settings from '@app/branding/settings';

import { useFormattedPrice, useConvertedPrice } from 'horizontal/util';

const useFormattedPackagesPrice = (price: number) => {
    const i18n = useI18n();
    const { baseCurrency } = useSelector(selectCurrency);
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const convertedPrice = useConvertedPrice(price, baseCurrency);

    const defaultPrice = useFormattedPrice(price);

    const exchangedPrice = numberFormatterFactory().format(convertedPrice);

    return baseCurrency === settings.baseCurrencyName
        ? defaultPrice
        : t(i18n)`${Currency.symbol(i18n, baseCurrency)} ${exchangedPrice}`;
};

export default useFormattedPackagesPrice;
