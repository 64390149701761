import * as React from 'react';

import { Dialog, CloseDialogButton } from 'strat/modal';

import AlertsManager from './alertsManager';
import styles from './styles/alertsManagerDialog.cssm';

type Props = {
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
};

/*
 * Display the Alert Manager component in a dialog.
 */
const AlertsManagerDialog = ({ visible, onVisibilityChanged }: Props) => (
    <Dialog
        dismissible
        visible={visible}
        onVisibilityChanged={onVisibilityChanged}
        className={styles.dialog}
    >
        <CloseDialogButton className={styles.closeButton} />
        <AlertsManager onVisibilityChanged={onVisibilityChanged} />
    </Dialog>
);

export default AlertsManagerDialog;
