import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, PageFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { determineAdsIndexName } from 'strat/search/indexNames';

const fetchAdsByFilterCollection = (
    searchHistoryFilter: FilterCollection,
    language: string,
    backend: SearchBackend,
) => {
    // @ts-expect-error - TS2345 - Argument of type 'FilterCollection' is not assignable to parameter of type 'SerializedFilterCollection'.
    const filters = new FilterCollection(searchHistoryFilter);
    filters.refine(new PageFilter({ value: 1 }));

    const job = new SearchJob(determineAdsIndexName({ language }), filters, {
        hitsPerPage: 12,
    });

    const service = new SearchService({ backend });
    return service.fetchJob(job).then((response) => response.hits);
};

export default fetchAdsByFilterCollection;
