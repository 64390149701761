import type { LiteCategory } from 'horizontal/types';

const Category = {
    PROPERTY_FOR_SALE: 'property-for-sale',
    PROPERTY_FOR_RENT: 'property-for-rent',
    PROPERTIES: 'properties',
    HOUSE_FOR_SALE: 'houses-sale',
    HOUSE_FOR_RENT: 'houses-rent',
    APARTMENT_FOR_SALE: 'apartments-flats-sale',
    APARTMENT_FOR_RENT: 'apartments-flats-rent',
    VEHICLES: 'vehicles',
    CARS: 'cars',
    CARS_FOR_SALE: 'cars-for-sale',
    CARS_FOR_RENT: 'cars-for-rent',
    CARS_ON_INSTALLMENTS: 'cars-on-installments',
    MOTORCYCLES: 'motorcycles',
    MOTORCYCLES_ACCESSORIES: 'motorcycles-accessories',
    JOBS: 'jobs',
    JOBS_SERVICES: 'jobs-services',
    MOBILE_PHONES: 'mobile-phones',
    VILLAS_FOR_SALE: 'villas-for-sale',
    VILLAS_FOR_RENT: 'villas-for-rent',
    APARTMENTS_DUPLEX_FOR_SALE: 'apartments-duplex-for-sale',
    APARTMENTS_DUPLEX_FOR_RENT: 'apartments-duplex-for-rent',
    VACATION_HOMES_FOR_SALE: 'vacation-homes-for-sale',
    VACATION_HOMES_FOR_RENT: 'vacation-homes-for-rent',
    HOUSES: 'houses',
    APARTMENT_FLATS: 'apartments-flats',
    LANDS_AND_PLOTS: 'land-plots',
    SHOP_OFFICES_COMMERCIAL: 'shops-offices-commercial-space',
    PORTIONS_AND_FLOORS: 'portions-floors',
    ROOMMATES_PAYING_GUESTS: 'roommates-paying-guests',
    ROOMS: 'rooms',
    VACATION_RENTALS: 'vacation-rentals-guest-houses',
    SERVICES: 'services',
    COMMERCIAL_FOR_SALE: 'commercial-for-sale',
    COMMERCIAL_FOR_RENT: 'commercial-for-rent',
    LAND_FOR_SALE: 'land-for-sale',
    LAND_FOR_RENT: 'land-for-rent',
    BUILDINGS_LANDS_OTHER: 'buildings-lands-other',
    OTHERS: 'others',
    CAR_SPARE_PARTS: 'car-spare-parts',

    // Cars for Rent subcategories
    SMALL_CARS: 'small-cars',
    MEDIUM_CARS: 'medium-cars',
    SUV: 'suv',
    LUXURY_CARS: 'luxury-cars',
    WEDDING_CARS: 'wedding-cars',
    SEVEN_SEATERS: '7-seaters',
    MINIBUS: 'minibus',

    // GCC properties
    PROPERTIES_FOR_RENT: 'properties-for-rent',
    PROPERTIES_FOR_SALE: 'properties-for-sale',
    APARTMENTS_FOR_RENT: 'apartments-for-rent',
    CHALET_FOR_RENT: 'chalet-for-rent',
    BUILDINGS_FOR_RENT: 'buildings-for-rent',
    RENTALS_WANTED: 'rentals-wanted',
    ROOMS_FOR_RENT: 'rooms-for-rent',
    GARAGE_FOR_RENT: 'garage-for-rent',
    MULTIPLE_UNITS_FOR_RENT: 'multiple-units-for-rent',

    APARTMENTS_FOR_SALE: 'apartments-for-sale',
    CHALET_FOR_SALE: 'chalet-for-sale',
    BUILDINGS_FOR_SALE: 'buildings-for-sale',
    GARAGE_FOR_SALE: 'garage-for-sale',
    MULTIPLE_UNITS_FOR_SALE: 'multiple-units-for-sale',

    isOfType: (category: LiteCategory | string | null | undefined, type: string) =>
        category &&
        (category === type ||
            ((category as LiteCategory).slug && (category as LiteCategory).slug === type)),

    isOfPropertyForSaleType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.PROPERTY_FOR_SALE),

    isOfPropertyForRentType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.PROPERTY_FOR_RENT),

    isOfPropertiesType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.PROPERTIES),

    isOfPropertyType: (category?: LiteCategory | null) =>
        Category.isOfPropertyForSaleType(category) ||
        Category.isOfPropertyForRentType(category) ||
        Category.isOfPropertiesType(category),

    isOfHouseForSaleType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.HOUSE_FOR_SALE),

    isOfHouseForRentType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.HOUSE_FOR_RENT),

    isOfHouseType: (category?: LiteCategory | null) =>
        Category.isOfHouseForSaleType(category) || Category.isOfHouseForRentType(category),

    isOfApartmentForSaleType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.APARTMENT_FOR_SALE),

    isOfApartmentForRentType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.APARTMENT_FOR_RENT),

    isOfApartmentType: (category?: LiteCategory | null) =>
        Category.isOfApartmentForSaleType(category) || Category.isOfApartmentForRentType(category),

    isOfPropertyForSaleSubtype: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.LANDS_AND_PLOTS) ||
        Category.isOfType(category, Category.HOUSES) ||
        Category.isOfType(category, Category.APARTMENT_FLATS) ||
        Category.isOfType(category, Category.SHOP_OFFICES_COMMERCIAL) ||
        Category.isOfType(category, Category.PORTIONS_AND_FLOORS),

    isOfPropertyForRentSubtype: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.HOUSES) ||
        Category.isOfType(category, Category.APARTMENT_FLATS) ||
        Category.isOfType(category, Category.SHOP_OFFICES_COMMERCIAL) ||
        Category.isOfType(category, Category.PORTIONS_AND_FLOORS) ||
        Category.isOfType(category, Category.ROOMMATES_PAYING_GUESTS) ||
        Category.isOfType(category, Category.ROOMS) ||
        Category.isOfType(category, Category.VACATION_RENTALS) ||
        Category.isOfType(category, Category.LANDS_AND_PLOTS),

    isOfPropertySubtype: (category?: LiteCategory | null) =>
        Category.isOfPropertyType(category) ||
        Category.isOfPropertyForSaleSubtype(category) ||
        Category.isOfPropertyForRentSubtype(category),

    isOfCommercialForSaleType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.COMMERCIAL_FOR_SALE),

    isOfCommercialForRentType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.COMMERCIAL_FOR_RENT),

    isOfCommercialSubtype: (category?: LiteCategory | null) =>
        Category.isOfCommercialForSaleType(category) ||
        Category.isOfCommercialForRentType(category),

    isOfLandForSaleType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.LAND_FOR_SALE),

    isOfLandForRentType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.LAND_FOR_RENT),

    isOfBuildingsLandOtherType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.BUILDINGS_LANDS_OTHER),

    isOfLandSubtype: (category?: LiteCategory | null) =>
        Category.isOfLandForSaleType(category) ||
        Category.isOfLandForRentType(category) ||
        Category.isOfBuildingsLandOtherType(category),

    isOfVehiclesType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.VEHICLES),

    isOfCarsType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.CARS) ||
        Category.isOfType(category, Category.CARS_FOR_SALE),

    isOfCarsForRentType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.CARS_FOR_RENT),

    isOfCarsForRentSubType: (category?: LiteCategory | null) =>
        Category.isOfCarsForRentType(category) ||
        Category.isOfType(category, Category.SMALL_CARS) ||
        Category.isOfType(category, Category.MEDIUM_CARS) ||
        Category.isOfType(category, Category.SUV) ||
        Category.isOfType(category, Category.LUXURY_CARS) ||
        Category.isOfType(category, Category.WEDDING_CARS) ||
        Category.isOfType(category, Category.SEVEN_SEATERS) ||
        Category.isOfType(category, Category.MINIBUS),

    isOfCarsOnInstallmentsType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.CARS_ON_INSTALLMENTS),

    isOfCarsOrCarsOnInstallmentsType: (category?: LiteCategory | null) =>
        Category.isOfCarsType(category) || Category.isOfCarsOnInstallmentsType(category),

    isOfCarsSubType: (category?: LiteCategory | null) =>
        Category.isOfCarsType(category) ||
        Category.isOfCarsForRentSubType(category) ||
        Category.isOfCarsOnInstallmentsType(category),

    isOfCarSparePartsType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.CAR_SPARE_PARTS),

    isOfMotorcyclesType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.MOTORCYCLES) ||
        Category.isOfType(category, Category.MOTORCYCLES_ACCESSORIES),

    isOfJobsType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.JOBS) ||
        Category.isOfType(category, Category.JOBS_SERVICES),

    isOfMobilePhonesType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.MOBILE_PHONES),

    isOfVillasType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.VILLAS_FOR_RENT) ||
        Category.isOfType(category, Category.VILLAS_FOR_SALE),

    isOfApartmentsDuplexType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.APARTMENTS_DUPLEX_FOR_RENT) ||
        Category.isOfType(category, Category.APARTMENTS_DUPLEX_FOR_SALE),

    isOfVacationHomeType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.VACATION_HOMES_FOR_SALE) ||
        Category.isOfType(category, Category.VACATION_HOMES_FOR_RENT),

    isOfServicesType: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.SERVICES),

    isOfPropertyForRentInGCCSubtype: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.APARTMENTS_FOR_RENT) ||
        Category.isOfType(category, Category.COMMERCIAL_FOR_RENT) ||
        Category.isOfType(category, Category.CHALET_FOR_RENT) ||
        Category.isOfType(category, Category.BUILDINGS_FOR_RENT) ||
        Category.isOfType(category, Category.RENTALS_WANTED) ||
        Category.isOfType(category, Category.ROOMS_FOR_RENT) ||
        Category.isOfType(category, Category.VILLAS_FOR_RENT) ||
        Category.isOfType(category, Category.GARAGE_FOR_RENT) ||
        Category.isOfType(category, Category.LAND_FOR_RENT) ||
        Category.isOfType(category, Category.MULTIPLE_UNITS_FOR_RENT) ||
        Category.isOfType(category, Category.PROPERTIES_FOR_RENT),

    isOfPropertyForSaleInGCCSubtype: (category?: LiteCategory | null) =>
        Category.isOfType(category, Category.APARTMENTS_FOR_SALE) ||
        Category.isOfType(category, Category.CHALET_FOR_SALE) ||
        Category.isOfType(category, Category.COMMERCIAL_FOR_SALE) ||
        Category.isOfType(category, Category.LAND_FOR_SALE) ||
        Category.isOfType(category, Category.BUILDINGS_FOR_SALE) ||
        Category.isOfType(category, Category.VILLAS_FOR_SALE) ||
        Category.isOfType(category, Category.GARAGE_FOR_SALE) ||
        Category.isOfType(category, Category.MULTIPLE_UNITS_FOR_SALE) ||
        Category.isOfType(category, Category.PROPERTIES_FOR_SALE),

    isOfResidentialType: (_: unknown) => false,

    isOfCommercialType: (_: unknown) => false,
} as const;

export default Category;
