import * as React from 'react';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';

const SellFasterHeader = (): React.ReactElement => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    return <Trans>Sell your products faster with {brandName} delivery</Trans>;
};

const FirstSectionTitle = (): React.ReactElement => <Trans>Sell your product faster</Trans>;

const FirstSectionContent = (): React.ReactElement => (
    <Trans>
        The courier makes the way between you and the buyer, so you don&apos;t have to meet him face
        to face.
    </Trans>
);

const SecondSectionTitle = (): React.ReactElement => <Trans>Track your order status</Trans>;

const SecondSectionContent = (): React.ReactElement => (
    <Trans>
        Deliver your product right from your doorstep, and keep tracking the order status from your
        account.
    </Trans>
);

const LearnMoreAboutDelivery = (): React.ReactElement => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    return <Trans>Learn more about {brandName} delivery</Trans>;
};

export {
    SellFasterHeader,
    FirstSectionTitle,
    FirstSectionContent,
    SecondSectionTitle,
    SecondSectionContent,
    LearnMoreAboutDelivery,
};
