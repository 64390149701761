import * as React from 'react';
import { Dropdown } from 'strat/modal';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { Flex } from 'strat/components';
import classNames from 'classnames';

import IconMore from 'horizontal/assets/icons/adCardMoreIcon.svg';
import type { FullAd } from 'horizontal/types';
import { getAdVars } from 'horizontal/gtm/utils';

import { useMenuCTAInfo } from './useCTA';
import styles from './styles/actions.cssm';
import type { CTAInfo } from './useCTA';

type Props = {
    action: CTAInfo;
    onActionClick?: () => void;
};

const Action = (props: Props) => {
    const { action, onActionClick } = props;
    const onClick = React.useCallback(() => {
        onActionClick?.();
        action.onClick?.();
    }, [onActionClick, action]);

    return (
        <Flex alignCenter className={styles.entry} onClick={onClick}>
            {action.title}
        </Flex>
    );
};

const Actions = ({ ad, verticalIcon }: { ad: FullAd; verticalIcon?: boolean }) => {
    const actions = useMenuCTAInfo(ad);
    const [open, setIsOpen] = React.useState(false);

    const renderTrigger = React.useCallback(
        () => (
            <IconMore
                className={classNames(styles.trigger, {
                    [styles.vertical]: verticalIcon,
                })}
                onClick={() => {
                    setIsOpen(!open);
                }}
            />
        ),
        [open, verticalIcon],
    );

    if (actions.length === 0) {
        return null;
    }

    const trackActionClick = (actionTitle: string) => {
        trigger(Triggers.MANAGE_AD, {
            ...getAdVars(ad),
            filter_name: 'manage-ad',
            filter_value: actionTitle,
            view_section: ViewSections.BODY,
        });
    };

    return (
        <Dropdown open={open} renderTrigger={renderTrigger} onDismissed={() => setIsOpen(false)}>
            <div className={styles.content} aria-label="Ad options">
                {actions.map((action) => (
                    <Action
                        action={action}
                        key={action.title}
                        onActionClick={() => trackActionClick(action.title)}
                    />
                ))}
            </div>
        </Dropdown>
    );
};

export default Actions;
