import * as React from 'react';

import { Dialog as StratDialog } from 'strat/modal';
import { StylelessButton } from 'strat/generic';
import IconClose from '@app/assets/icons/iconClose.svg';

import styles from './styles/dialog.cssm';

export type Props = React.ComponentProps<typeof StratDialog> & {
    onCancel?: () => void;
    withCloseButton?: boolean;
    className?: string;
    headerClassName?: string;
    readonly zIndex?: number;
};

const Dialog = ({
    onCancel,
    withCloseButton,
    children,
    onVisibilityChanged,
    className,
    headerClassName,
    ...otherProps
}: Props) => {
    const close = (event: React.MouseEvent) => {
        if (onCancel) {
            onCancel();
        }
        /**
         * This is needed when you are supporting stacking of
         * Dialogs which are internally using Dissmissible.
         * Dissmissible uses event listeners on the document:
         * document.addEventListener('click')
         * such events are `document` based, not React based.
         * So they can't be handled with our stopEventPropagation
         * function, which is meant for React synthetic events.
         */
        event.nativeEvent.stopImmediatePropagation();
        onVisibilityChanged(false);
    };

    return (
        <StratDialog
            className={className || styles.container}
            onVisibilityChanged={onVisibilityChanged}
            {...otherProps}
        >
            {!!withCloseButton && (
                <div className={headerClassName || styles.header}>
                    <StylelessButton
                        onClick={(event: React.MouseEvent) => close(event)}
                        className={styles.closeButton}
                        aria-label={'Close button'}
                    >
                        <IconClose className={styles.closeIcon} />
                    </StylelessButton>
                </div>
            )}
            {children}
        </StratDialog>
    );
};

export default Dialog;
