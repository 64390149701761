import React from 'react';
import { isCanceled } from 'strat/util';
import { useRouter } from 'react-true-router';
import { useSelector } from 'react-redux';

import { selectBusinessPackageCartOfferAdID } from 'horizontal/businessPackages/selectors';
import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';
import { PaymentSuccessRoute, PaymentProcessingRoute } from 'horizontal/routes';

import type { PaymentHookParams } from '../types';
import { PaymentStatus } from '../types';

import useNavigateToPaymentSelection from './useNavigateToPaymentSelection';

type Response = {
    status: number;
    data: {
        paymentID: string;
        redirectURL: string | null;
        paymentStatus: Values<typeof PaymentStatus>;
    };
};

const ACCEPTED_STATUSES = [
    PaymentStatus.PENDING,
    PaymentStatus.CAPTURED,
    PaymentStatus.PENDING_AT_PROVIDER,
];
const ACCEPTED_HTTP_STATUSES = [
    200,
    //payment created
    201,
    //redirect required but otherwise ok
    202,
];
const useCheckoutSavedPayment = ({ setPaymentPromise, setIsLoading }: PaymentHookParams) => {
    const orderAdID = useSelector(selectBusinessPackageCartOfferAdID);
    const navigateToPaymentSelection = useNavigateToPaymentSelection();
    const makeCancelable = useMakeCancelable();
    const router = useRouter();

    const savedCardPayment = React.useCallback(
        (card, order) => {
            setIsLoading(true);
            const instrumentID = card.id;
            const requestBody = {
                instrument_id: instrumentID,
                order_external_id: order.externalID,
            };

            const cancelablePromise = makeCancelable(new StratAPI().checkoutPayment(requestBody));
            cancelablePromise
                .then(({ status, data }: Response) => {
                    if (
                        !ACCEPTED_HTTP_STATUSES.includes(status) ||
                        !ACCEPTED_STATUSES.includes(data.paymentStatus)
                    ) {
                        setIsLoading(false);
                        navigateToPaymentSelection({
                            orderExternalID: order.externalID,
                            status: PaymentStatus.FAILURE,
                        });
                        return;
                    }
                    if (
                        CONFIG.runtime.STRAT_ENABLE_AFTER_PAYMENT_PROCESSING_PAGE_REDIRECTION &&
                        orderAdID
                    ) {
                        const { paymentID } = data;
                        router.pushRoute(PaymentProcessingRoute.name, {
                            orderExternalID: order.externalID,
                            paymentID,
                            instrumentID,
                        });
                        return;
                    }
                    if (status === 201) {
                        router.pushRoute(PaymentSuccessRoute.name, {
                            orderExternalID: order.externalID,
                        });
                    }

                    if (status === 202 && data.redirectURL) {
                        window.open(data.redirectURL, '_blank');

                        router.pushRoute(PaymentSuccessRoute.name, {
                            orderExternalID: order.externalID,
                        });
                    }
                })
                .catch((error: any) => {
                    if (isCanceled(error)) {
                        return;
                    }
                    setIsLoading(false);
                });
            setPaymentPromise(cancelablePromise);
        },
        [
            setPaymentPromise,
            router,
            makeCancelable,
            setIsLoading,
            orderAdID,
            navigateToPaymentSelection,
        ],
    );
    return savedCardPayment;
};

export default useCheckoutSavedPayment;
