import * as React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import type { UserProfileData } from 'strat/user/types';
import type { PropertyData } from 'strat/property/types';
import { selectLanguage } from 'strat/i18n/language';
import getAdGTMVars from '@app/gtm/getAdGTMVars';

import getPropertyListVariables from './getPropertyListVariables';

/**
 * Properties for {@see GTMLegacyStats}.
 */
type ConnectedProps = {
    userProfile: UserProfileData | null | undefined;
    language: string;
};

/**
 * Properties passed to the wrapped component.
 */
export type GTMLegacyStatsProps = ConnectedProps & {
    propertyImpressionVars: (property: PropertyData) => any;
    callLeadVars: (property: PropertyData) => any;
    smsLeadVars: (property: PropertyData) => any;
    emailLeadVars: (property: PropertyData) => any;
    whatsappLeadVars: (property: PropertyData) => any;
};

/**
 * Provides convenience functions for interacting with the
 * legacy stats API.
 */
const withGTMLegacyStats = <T extends GTMLegacyStatsProps>(component: React.ComponentType<T>) => {
    class GTMLegacyStats extends React.Component<ConnectedProps> {
        constructor(props: ConnectedProps) {
            super(props);
            autoBind(this);
        }

        /**
         * Variables related to the actor/user who initiated
         * a specific action.
         */
        getUserVars(): any {
            const { userProfile } = this.props;

            return {
                actor_detail: {
                    actor_name: userProfile ? userProfile.name : '',
                    cell: userProfile ? userProfile.phoneNumber : '',
                    email: userProfile ? userProfile.email : '',
                },
            };
        }

        /**
         * Legacy stats variables to send with property impressions.
         */
        propertyImpressionVars(property: PropertyData): any {
            return {
                listings: [
                    getPropertyListVariables(
                        property,
                        'view',
                        this.props.language,
                        this.props.userProfile,
                    ),
                ],
            };
        }

        /**
         * Legacy stats variables to send with leads.
         */
        leadVars(property: PropertyData): any {
            return {
                ...this.getUserVars(),
                // @ts-ignore
                ...getAdGTMVars(property, this.props.language),
            };
        }

        /**
         * Renders the wrapped component and exposes convenience
         * functions to the wrapped component through props.
         */
        render() {
            const props = {
                ...this.props,
                propertyImpressionVars: this.propertyImpressionVars,
                callLeadVars: this.leadVars,
                smsLeadVars: this.leadVars,
                emailLeadVars: this.leadVars,
                whatsappLeadVars: this.leadVars,
            } as T;

            return React.createElement(component, props);
        }
    }

    return connect((state) => ({
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        userProfile: state.user.profile || null,
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '{}'.
        language: selectLanguage(state),
    }))(GTMLegacyStats);
};

export default withGTMLegacyStats;
