import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';

import { useI18n } from 'strat/i18n/language';
import { Input, Button } from 'strat/components';

import styles from './styles/changePasswordForm.cssm';

export type ChangePasswordFormFormikProps = {
    readonly isValid: boolean;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly setFieldTouched: (field: keyof FormikValues, touched: boolean) => void;
};

export type ChangePasswordFormConfigProps = {
    readonly hasForgotPassword?: boolean;
    readonly includeOldPassword: boolean;
    readonly stretch?: boolean;
    readonly isCaptchaValid?: boolean;
    readonly username?: string | null | undefined;
    readonly withInitialDescription?: boolean;
    readonly formClassName?: string;
};

type Props = ChangePasswordFormFormikProps & ChangePasswordFormConfigProps;

export const ChangePasswordFormFields = Object.freeze({
    OLD_PASSWORD: 'oldPassword',
    PASSWORD: 'password',
    PASSWORD_CONFIRM: 'passwordConfirm',
});

const ChangePasswordForm = ({
    errors,
    touched,
    handleBlur,
    handleSubmit,
    values,
    setFieldValue,
    setFieldTouched,
    includeOldPassword,
    stretch = false,
    isValid = false,
    isCaptchaValid = true,
    username,
    withInitialDescription = true,
    formClassName,
}: Props) => {
    const i18n = useI18n();
    const submitButtonText = includeOldPassword
        ? t(i18n)`Change password`
        : t(i18n)`Create password`;

    return (
        <form className={formClassName || styles.content} onSubmit={handleSubmit}>
            {includeOldPassword && (
                <Input
                    type="password"
                    name={ChangePasswordFormFields.OLD_PASSWORD}
                    placeholder={t(i18n)`Current password`}
                    value={values.oldPassword}
                    errorMessage={touched.oldPassword && errors.oldPassword}
                    onChange={(value) => setFieldValue('oldPassword', value)}
                    onBlur={handleBlur}
                />
            )}
            {/* hidden input needed for the browser to save the username password combo */}
            {username && <input name="email" value={username} readOnly hidden />}
            <Input
                type="password"
                name={ChangePasswordFormFields.PASSWORD}
                placeholder={t(i18n)`New password`}
                value={values.password}
                errorMessage={touched.password && errors.password}
                onChange={(value) => setFieldValue('password', value)}
                onBlur={handleBlur}
                description={
                    withInitialDescription
                        ? t(
                              i18n,
                          )`Use a minimum of 8 characters with at least 1 number, 1 special character, 1 letter`
                        : undefined
                }
            />
            <Input
                type="password"
                name={ChangePasswordFormFields.PASSWORD_CONFIRM}
                placeholder={t(i18n)`Confirm new password`}
                value={values.passwordConfirm}
                errorMessage={touched.passwordConfirm && errors.passwordConfirm}
                onChange={(value) => {
                    setFieldValue('passwordConfirm', value);
                    setFieldTouched('passwordConfirm', true);
                }}
                onBlur={handleBlur}
            />
            <Button stretch={stretch} disabled={!isValid || !isCaptchaValid}>
                {submitButtonText}
            </Button>
        </form>
    );
};

export default ChangePasswordForm;
