import { RouteNames } from 'strat/routes';

import useRouteParams from 'horizontal/agencyPortal/agencyAds/useRouteParams';
import { AdSubRoutes } from 'horizontal/agencyPortal/routes/agencyPortalAdsRoute';
import { ChatNavAction, ChatSubjectType } from 'horizontal/chat/constants';
import type { ChatAdXID, ChatUserXID } from 'horizontal/chat/types';

import useChatProps from './useChatProps';

type Props = {
    readonly adXID?: ChatAdXID;
    readonly contactXID?: ChatUserXID;
};

export const useChatLink = ({ adXID, contactXID }: Props) => {
    const { externalMode, agencyMode } = useChatProps();
    const params = useRouteParams();

    const shouldBuildChatRoomLink = adXID && contactXID;

    if (externalMode) {
        // TODO sellerCenter route based on chatRoom subjectType
        return {
            route: RouteNames.CHAT_WIDGET,
            params: {
                contactXID,
                subjectXID: adXID,
                subjectType: ChatSubjectType.AD,
            },
        };
    }

    if (agencyMode) {
        if (shouldBuildChatRoomLink) {
            return {
                route: RouteNames.AGENCY_PORTAL_ADS,
                params: {
                    ...params,
                    adExternalID: adXID,
                    tabName: AdSubRoutes.CHATS,
                    tabChange: true,
                    contactXID,
                },
            };
        }

        return {
            route: RouteNames.AGENCY_PORTAL_ADS,
            params: {
                adExternalID: adXID,
                tabName: AdSubRoutes.CHATS,
                tabChange: true,
            },
        };
    }

    if (shouldBuildChatRoomLink) {
        return {
            route: RouteNames.CHAT,
            params: { subjectXID: adXID, contactXID, navAction: ChatNavAction.READ },
        };
    }

    return {
        route: RouteNames.CHAT,
    };
};
