import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import styles from './styles/header.cssm';

const Header = () => {
    const i18n = useI18n();

    return (
        <Flex alignCenter className={styles.container}>
            <Text.XLarge bold uppercase>
                {t(i18n)`inbox`}
            </Text.XLarge>
        </Flex>
    );
};

export default Header;
