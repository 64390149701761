import { FetcherFactoryWithContext, buildDefaultState } from 'strat/fetcher';
import type { FetchAction, FetchState } from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import type { RawUnitPlan, RoomSectionSelection } from '../types';

type FetchUnitPlanParams = {
    adExternalID: string;
};

type UnitPlanState = FetchState<RawUnitPlan> & {
    selectedSection: RoomSectionSelection | null;
    wallMeasurementsVisible: boolean;
    imageSidebarVisible: boolean;
};

const factory = new FetcherFactoryWithContext<FetchUnitPlanParams>(
    'unitPlan',
    ({ params }) => {
        const { adExternalID } = params;
        return new StratAPI().unitPlan(adExternalID).then(({ data, status }) => {
            if (status === 404) {
                return {
                    data: null,
                    status: 404,
                };
            }

            return { data, status };
        });
    },
    {
        successCodes: [200, 404],
    },
);

const fetchUnitPlan = factory.creator();
const baseUnitPlanReducer = factory.reducer();

const Actions = Object.freeze({
    SET_SELECTED_SECTION: 'UNIT_PLAN/SET_SELECTED_SECTION',
    SET_WALL_MEASUREMENTS_VISIBLE: 'UNIT_PLAN/SET_WALL_MEASUREMENTS_VISIBLE',
    SET_IMAGE_SIDEBAR_VISIBLE: 'UNIT_PLAN/SET_IMAGE_SIDEBAR_VISIBLE',
    CLEAR_SCENE_STATE: 'UNIT_PLAN/CLEAR_SCENE_STATE',
});

type SetSelectedSectionAction = {
    type: 'UNIT_PLAN/SET_SELECTED_SECTION';
    selectedSection: RoomSectionSelection | null;
};

type SetWallMeasurementsVisible = {
    type: 'UNIT_PLAN/SET_WALL_MEASUREMENTS_VISIBLE';
    visible: boolean;
};

type SetImageSidebarVisible = {
    type: 'UNIT_PLAN/SET_IMAGE_SIDEBAR_VISIBLE';
    visible: boolean;
};

type ClearSceneState = {
    type: 'UNIT_PLAN/CLEAR_SCENE_STATE';
};

const clearUnitPlan = () => ({
    type: factory.actions.clear,
});

const setSelectedSection = (selectedSection: RoomSectionSelection | null) => ({
    type: Actions.SET_SELECTED_SECTION,
    selectedSection,
});

const setWallMeasurementsVisible = (visible: boolean) => ({
    type: Actions.SET_WALL_MEASUREMENTS_VISIBLE,
    visible,
});

const setImageSidebarVisible = (visible: boolean) => ({
    type: Actions.SET_IMAGE_SIDEBAR_VISIBLE,
    visible,
});

const clearSceneState = () => ({
    type: Actions.CLEAR_SCENE_STATE,
});

const sceneDefaultState = {
    selectedSection: null,
    wallMeasurementsVisible: false,
    imageSidebarVisible: true,
};

const defaultState: UnitPlanState = {
    ...buildDefaultState(),
    ...sceneDefaultState,
};

type UnitPlanAction =
    | FetchAction
    | SetSelectedSectionAction
    | SetWallMeasurementsVisible
    | SetImageSidebarVisible
    | ClearSceneState;

const unitPlanReducer = (state: UnitPlanState = defaultState, action: UnitPlanAction) => {
    switch (action.type) {
        case Actions.SET_SELECTED_SECTION:
            return {
                ...state,
                selectedSection: (action as SetSelectedSectionAction).selectedSection,
            };
        case Actions.SET_WALL_MEASUREMENTS_VISIBLE:
            return {
                ...state,
                wallMeasurementsVisible: (action as SetWallMeasurementsVisible).visible,
            };
        case Actions.SET_IMAGE_SIDEBAR_VISIBLE:
            return {
                ...state,
                imageSidebarVisible: (action as SetImageSidebarVisible).visible,
            };
        case Actions.CLEAR_SCENE_STATE:
            return {
                ...state,
                ...sceneDefaultState,
            };
        default: {
            return {
                ...defaultState,
                ...baseUnitPlanReducer(state, action as FetchAction),
            };
        }
    }
};

export {
    fetchUnitPlan,
    clearUnitPlan,
    setSelectedSection,
    setWallMeasurementsVisible,
    setImageSidebarVisible,
    clearSceneState,
};
export type { UnitPlanState };
export default unitPlanReducer;
