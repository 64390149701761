import * as React from 'react';
import { useSelector } from 'react-redux';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';

/**
 * Tracks impressions for ads displayed in the recently viewed ads section.
 */
const useTrackRecentlyViewedAds = (ovation: OvationCallbackProps) => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const recentlyViewedAds = useSelector((state) => state.recentlyViewed.ads);
    React.useEffect(() => {
        ovation.ingestAdImpressions(
            recentlyViewedAds.slice(-CONFIG.build.RECENTLY_VIEWED_ADS_MAX_VIEW_LENGTH),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentlyViewedAds]);
};

export default useTrackRecentlyViewedAds;
