import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import type { Location } from 'horizontal/types';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { selectCategories } from 'horizontal/categories';
import { filterDisabledLocations } from 'horizontal/search/location';

import fetchLocationsByParent from './fetchLocationsByParent';
import LocationList from './locationList';
import useLocationFilterValue from './useLocationFilterValue';
import PopularLocationsSection from './popularLocationsSection';

type Props = {
    readonly maxLevel?: number;
    readonly selectedIndex?: number;
    readonly onLocationClicked: (arg1: Location) => void;
};

const SubLocationList = ({ maxLevel, selectedIndex, onLocationClicked }: Props) => {
    const i18n = useI18n();

    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);
    const [locationTreeList, setLocationTreeList] = React.useState([]);

    const locationTreeTitles = [
        t(i18n)`Choose region`,
        t(i18n)`Choose cities`,
        t(i18n)`Choose area`,
    ];

    const activeLocation = useLocationFilterValue() || settings.topLevelLocation;
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);

    React.useEffect(() => {
        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        fetchLocationsByParent(activeLocation, language, backend).then((data) => {
            if (!data.length) {
                return;
            }

            data.sort((a, b) => a.name.localeCompare(b.name));
            let restrictedLocations = maxLevel
                ? data.filter((location) => location.level <= maxLevel)
                : data;
            restrictedLocations = filterDisabledLocations(
                restrictedLocations,
                categories,
                activeCategory,
            );
            // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setLocationTreeList(restrictedLocations);
        });
    }, [activeLocation, categories, activeCategory, backend, language, maxLevel]);

    return (
        <>
            <PopularLocationsSection
                locations={locationTreeList}
                onLocationClicked={onLocationClicked}
            />
            <LocationList
                title={locationTreeTitles[activeLocation.level]}
                locations={locationTreeList}
                onLocationClicked={onLocationClicked}
                selectedIndex={selectedIndex}
            />
        </>
    );
};

export default SubLocationList;
