import * as React from 'react';
import classNames from 'classnames';

import IconCheck from '@app/assets/icons/iconCheckBox.svg';

import Flex from './flex';
import useDOMEvents from './useDOMEvents';
import styles from './styles/checkbox.cssm';

type Props = {
    readonly name?: string;
    readonly checked?: boolean;
    readonly onChange?: () => void;
    readonly label?: React.ReactNode;
    readonly withRoundedCorners?: boolean;
    readonly withHighlightLabel?: boolean;
    readonly renderIcon?: (className: string) => React.ReactElement;
    readonly disabled?: boolean;
    readonly containerClassname?: string;
    readonly checkboxClassname?: string;
    readonly iconClassname?: string;
    readonly labelClassname?: string;
    readonly topLevelDOMEvent?: boolean;
};

const Checkbox = ({
    name,
    checked = false,
    onChange,
    label,
    withRoundedCorners = true,
    withHighlightLabel = true,
    disabled = false,
    renderIcon = (className: string) => <IconCheck className={className} />,
    containerClassname = styles.container,
    checkboxClassname = styles.checkBox,
    iconClassname = styles.icon,
    labelClassname = styles.label,
    topLevelDOMEvent,
}: Props) => {
    const checkboxRef = useDOMEvents({
        eventType: 'mousedown',
        eventHandler: onChange,
        topLevelDOMEvent,
    });

    // when a checkbox input has 'checked' attribute it must also have
    // onChange function with it. In the case we have topLevelDOMEvent
    // as true then we are going to pass a dummy function just to prevent
    // the warning/error from showing.
    const onChangeHandler = topLevelDOMEvent ? () => {} : onChange;

    return (
        <Flex
            className={classNames(containerClassname, {
                [styles.checked]: checked && withHighlightLabel,
            })}
        >
            <input
                type="checkbox"
                id={name}
                name={name}
                className={classNames(checkboxClassname, {
                    [styles.roundedCorners]: withRoundedCorners,
                    [styles.disabledCheckbox]: disabled,
                })}
                checked={checked}
                onChange={onChangeHandler}
                disabled={disabled}
                readOnly
                // @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLInputElement> | undefined'.
                ref={checkboxRef}
            />
            {checked &&
                renderIcon(
                    classNames(iconClassname, {
                        [styles.disabledIcon]: disabled,
                    }),
                )}
            {label && (
                <label
                    className={classNames(labelClassname, {
                        [styles.disabledLabel]: disabled,
                    })}
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
        </Flex>
    );
};

export default Checkbox;
