import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { Text, ListEntry } from 'strat/components';

import iconMyAds from 'horizontal/assets/icons/iconMyAds_noinline.svg';

const renderLeft = () => <img alt={'My Ads'} src={iconMyAds} />;

const AdManagementLink = ({ onClick }: { onClick: (arg1: React.MouseEvent<any>) => void }) => {
    const i18n = useI18n();
    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trigger(Triggers.VIEW_MY_ADS, { view_section: ViewSections.USER_DETAIL });
            onClick(event);
        },
        [onClick],
    );

    return (
        <Link route={RouteNames.AD_MANAGEMENT} title={t(i18n)`My ads`} onClick={onTrackedClick}>
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>{t(i18n)`My ads`}</Text.Large>
            </ListEntry>
        </Link>
    );
};

export default AdManagementLink;
