import RouteMatch from './routeMatch';
import type { URLProcessor } from './urlProcessor';
import type Router from './router';

/**
 * Context provided to route handlers.
 */
class RoutingContext {
    /**
     * Router that created this context.
     */
    router: Router;

    /**
     * Result of matching the incoming URL to a route.
     */
    match: RouteMatch;

    /**
     * An optional urlProcessor that is used for URL
     * inbound / outbound processing, e.g.
     * adding or stripping language prefixes (/ar)
     */
    urlProcessor: URLProcessor | null | undefined;

    /**
     * Initializes a new instance of {@see RoutingContext}.
     * @param match Result of matching the incoming URL to a route.
     */
    constructor(router: Router, match: RouteMatch, urlProcessor: URLProcessor | null = null) {
        this.router = router;
        this.match = match;
        this.urlProcessor = urlProcessor;
    }
}

export default RoutingContext;
