import type { AdPhotoDTO, UploadedPhoto } from '../types';

const transformAdPhotosToAdPhotoDTOs = (photos: Array<UploadedPhoto>): Array<AdPhotoDTO> => {
    return photos.map((photo, index) => ({
        id: photo.id,
        external_id: photo.externalID,
        order_index: index,
        is_main: index === 0,
        url: photo.url,
        width: photo.width,
        height: photo.height,
    }));
};

export default transformAdPhotosToAdPhotoDTOs;
