import * as React from 'react';
import classNames from 'classnames';

import { connectScrollNavSection } from 'strat/navigation/scroll';

import SectionHeader from './sectionHeader';
import styles from './styles/section.cssm';

/**
 * Properties for {@see Section}.
 */
type Props = {
    className?: string;
    renderHeader?: (arg1?: string | null) => React.ReactElement | null;
    children: React.ReactNode;
    label?: string;
    title?: string;
};

/**
 * Renders a titled? content section.
 */
const Section = ({
    renderHeader = (title) => (title ? <SectionHeader title={title} /> : null),
    ...props
}: Props) => {
    const className = classNames(styles.sectionBase, props.className, {
        [styles.section]: !!props.title,
    });

    return (
        <div aria-label={props.label} className={className}>
            {renderHeader(props.title)}
            {props.children}
        </div>
    );
};

export default connectScrollNavSection(Section);
