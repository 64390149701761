import { GlobalState } from 'horizontal/state';

import { SupportedCurrencies } from './types';

export type AreebaState = {
    areebaMerchantID: string;
    extraCharge: string;
    currency: Values<typeof SupportedCurrencies>;
};

const selectMerchantID = (state: GlobalState) => state.areebaSession?.data?.areebaMerchantID;

const selectExtraCharges = (state: GlobalState) => state.areebaSession?.data?.extraCharge;

const selectAreebaCurrency = (state: GlobalState): Values<typeof SupportedCurrencies> => {
    const currency = state.areebaSession?.data?.currency;

    return (
        SupportedCurrencies[currency as keyof typeof SupportedCurrencies] || SupportedCurrencies.LBP
    );
};

export { selectMerchantID, selectExtraCharges, selectAreebaCurrency };
