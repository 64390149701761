import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import type { StaticPage } from 'strat/branding/types';
import Layout from 'strat/layout';

const staticFilesPath = 'uae';
const staticFilesLanguageMapping = Object.freeze({
    en: 'en_mobile_terms.html',
    ar: 'ar_mobile_terms.html',
    zh: 'en_mobile_terms.html',
    ru: 'en_mobile_terms.html',
});
const staticPages: Array<StaticPage> = [
    {
        route: 'mobile/terms',
        title: (i18n: I18n) => t(i18n)`Terms of Use for mobile apps`,
        description: (i18n: I18n) =>
            t(
                i18n,
            )`Terms and condition to use this property and real estate website. Agree to the terms and conditions to use mobile
            apps provided by Bayut`,
        path: (language: string, _layout: string) =>
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<{ en: string; ar: string; zh: string; }>'.
            `${staticFilesPath}/${staticFilesLanguageMapping[language]}`,
    },
    {
        route: 'bayutProChat',
        title: (i18n: I18n) => t(i18n)`Bayut chat reply in Bayut Pro`,
        description: (i18n: I18n) =>
            t(i18n)`Bayut.com offers chat interaction between leads and agents`,
        path: (_language: string, _layout: string) => {
            if (_layout === Layout.MOBILE) {
                return `${staticFilesPath}/mobileBayutProChat.html`;
            }
            return `${staticFilesPath}/bayutProChat.html`;
        },
    },
    {
        route: 'bayutProEdit',
        title: () => '',
        description: () => '',
        path: (_language: string, _layout: string) => {
            return `${staticFilesPath}/bayutProEdit.html`;
        },
    },
    {
        route: 'bayutPro',
        title: () => '',
        description: () => '',
        path: (_language: string, _layout: string) => {
            return `${staticFilesPath}/bayutPro.html`;
        },
    },
];

export default staticPages;
