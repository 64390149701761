import * as React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import { ListingMoreInfoTooltip } from 'strat/listing';
import IconInfo from 'strat/assets/icons/iconInfo_noinline.svg';

import type { BadgeProps } from './types';
import styles from './styles/cardBadge.cssm';

const CardBadge = ({ renderTitle, renderValue, renderInformation, large, label }: BadgeProps) => {
    const shouldRenderMoreInfo = !!renderInformation;
    const ariaLabel =
        `${label || ''}${shouldRenderMoreInfo ? ' Hoverable ' : ' '}Card Badge`.trim();

    const renderContent = () => (
        <Flex
            column
            className={classNames(styles.container, {
                [styles.hoverable]: shouldRenderMoreInfo,
                [styles.large]: large,
            })}
            aria-label={ariaLabel}
        >
            <div
                className={classNames(styles.title, {
                    [styles.large]: large,
                })}
            >
                {renderTitle()}
            </div>
            <Flex
                alignCenter
                className={classNames(styles.value, {
                    [styles.valueWithInfo]: shouldRenderMoreInfo,
                    [styles.large]: large,
                })}
            >
                {renderValue()}
                {shouldRenderMoreInfo && (
                    <svg
                        className={classNames(styles.infoIcon, {
                            [styles.large]: large,
                        })}
                    >
                        <use xlinkHref={`${IconInfo}#info`} />
                    </svg>
                )}
            </Flex>
        </Flex>
    );

    return shouldRenderMoreInfo ? (
        <ListingMoreInfoTooltip
            renderTrigger={renderContent}
            renderInformation={renderInformation}
        />
    ) : (
        renderContent()
    );
};

export default CardBadge;
