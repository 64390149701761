import * as React from 'react';

import HistoryRouter from './historyRouter';
import useRouter from './useRouter';

export type WithRouterProps = {
    router?: HistoryRouter;
};

/**
 * Injects the current router into the specified component.
 *
 * (<RouterProvider /> must be used in order for this to work).
 */
const withRouter = <T extends WithRouterProps>(component: React.ComponentType<T>) => {
    const WithRouter = (props: Omit<T, keyof WithRouterProps>) => {
        const router = useRouter();

        return React.createElement(component, {
            router: router,
            ...props,
        } as T);
    };

    return WithRouter;
};

export default withRouter;
