import * as React from 'react';

import styles from './styles/agencyInfoDefault.cssm';

type Props = {
    name: string;
};

const AgencyName = ({ name }: Props) => (
    <span aria-label={'Agency name'} className={styles.agencyName}>
        {name}
    </span>
);

export default AgencyName;
