import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { CloseDialogButton, Dialog as GenericDialog, ErrorDialog, Scrollable } from 'strat/modal';
import { LoadingSpinner } from 'strat/loadable';

import ReviewInfo from '../components/reviewInfo';

import type { DialogState } from './dialogStates';
import { DialogStates } from './dialogStates';
import styles from './styles/agentReviewsDialog.cssm';
import ReviewListItem from './reviewListItem';
import { selectAcceptedReviews } from './selectors';

type Props = {
    onVisibilityChanged: (arg1: boolean) => void;
    activeState: DialogState;
    visible: boolean;
    title: string;
    reviewCount: number;
    avgStarRating: number;
};

const AgentReviewsDialog = ({
    onVisibilityChanged,
    activeState,
    visible,
    title,
    reviewCount,
    avgStarRating,
}: Props) => {
    const reviewList = useSelector(selectAcceptedReviews);

    const close = React.useCallback(() => {
        onVisibilityChanged(false);
    }, [onVisibilityChanged]);

    if (activeState === DialogStates.ERROR) {
        return (
            <ErrorDialog visible={visible} onVisibilityChanged={onVisibilityChanged}>
                <Trans>An unknown internal error occurred.</Trans>
            </ErrorDialog>
        );
    }

    return (
        <GenericDialog
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.container}
        >
            {/* @ts-expect-error - TS2322 - Type '{ onClick: () => void; className: any; }' is not assignable to type 'IntrinsicAttributes & Diff<Props, OutProps> & RefAttributes<any> & { children?: ReactNode; }'. */}
            <CloseDialogButton onClick={close} className={styles.close} />
            {activeState === DialogStates.LOADING && <LoadingSpinner className={styles.spinner} />}
            {activeState === DialogStates.SUCCESS && (
                <>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <h1 className={styles.title}>{title}</h1>
                            <ReviewInfo reviewCount={reviewCount} avgStarRating={avgStarRating} />
                        </div>
                        <Scrollable className={styles.scrollable}>
                            {/* @ts-expect-error - TS7006 - Parameter 'review' implicitly has an 'any' type. */}
                            {reviewList.map((review) => {
                                return (
                                    <ReviewListItem
                                        key={review.externalID}
                                        review={review}
                                        className={styles.reviewContainer}
                                    />
                                );
                            })}
                        </Scrollable>
                    </div>
                </>
            )}
        </GenericDialog>
    );
};

export default AgentReviewsDialog;
