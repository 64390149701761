import * as React from 'react';
import { Trans } from '@lingui/macro';

import IconBack from '@app/assets/icons/iconBack.svg';

import styles from './styles/closeGalleryButton.cssm';

type Props = {
    onClick?: () => void;
    label?: React.ReactNode;
    className?: string;
};

const CloseGalleryButton = ({ onClick, label, className = styles.button }: Props) => {
    return (
        <button onClick={onClick} className={className} aria-label="Back button">
            <IconBack className={styles.backIcon} />
            {label ? label : <Trans>Back to gallery</Trans>}
        </button>
    );
};

export default CloseGalleryButton;
