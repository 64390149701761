import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'react-true-router';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { logError } from 'strat/error/log';

import StratAPI from 'horizontal/api/strat';

import type { PaymentProducts } from '../types';
import { PaymentOrderPlatform } from '../types';

import useNavigateToPaymentSelection from './useNavigateToPaymentSelection';

type UsePayProductPackages = {
    readonly onPayProductPackages: (arg1: PaymentProducts) => void;
    readonly loading: boolean;
    readonly errorMessage: string;
};

const usePayProductPackages = (): UsePayProductPackages => {
    const i18n = useI18n();
    const router = useRouter();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const navigateToPaymentSelection = useNavigateToPaymentSelection();
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const orderExternalID = useSelector((state) => state.businessPackageCart.orderExternalID);

    const onPayProductPackages = React.useCallback(
        (productPackageOffers: PaymentProducts) => {
            setLoading(true);
            new StratAPI()
                // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
                .createOrder({
                    product_package_offers: productPackageOffers,
                    platform: PaymentOrderPlatform.WEB,
                    order_external_id: orderExternalID,
                })
                .then(
                    ({ data, status }) => {
                        if (
                            status === 400 &&
                            data?.[0] ===
                                'Ad can not have more than one auto_refresh package at same time'
                        ) {
                            setLoading(false);
                            setErrorMessage(
                                t(i18n)`You already have this service running on your ad.`,
                            );
                            return;
                        }
                        if (status === 403) {
                            setLoading(false);
                            router.pushRoute(RouteNames.PACKAGE_PURCHASE_ERROR, {
                                errorType: data.detail,
                            });
                            return;
                        }
                        if (status === 412) {
                            setLoading(false);
                            setErrorMessage(t(i18n)`You've reached your order limit for today`);
                            return;
                        }
                        if (status !== 200) {
                            router.pushRoute(RouteNames.INTERNAL_ERROR);
                            return;
                        }
                        navigateToPaymentSelection({ orderExternalID: data?.orderExternalID });
                        setLoading(false);
                    },
                    (e) => {
                        setLoading(false);
                        logError({
                            e,
                            msg: 'Error creating products order',
                        });
                    },
                );
        },
        [router, navigateToPaymentSelection, orderExternalID, i18n],
    );

    return {
        onPayProductPackages,
        loading,
        errorMessage,
    };
};

export default usePayProductPackages;
