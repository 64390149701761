import type { MixedAd } from 'horizontal/types';

import type { CarProductSchema, ProductSchema } from './types';
import useProductSchema from './useProductSchema';
import useAdExtraFieldLabel from './useAdExtraFieldLabel';

const useCarProductSchema = (ad?: MixedAd | null): CarProductSchema | null | undefined => {
    const productSchema: ProductSchema | null | undefined = useProductSchema(ad);
    const brand = useAdExtraFieldLabel(ad, 'make');
    const model = useAdExtraFieldLabel(ad, 'model');
    const year = useAdExtraFieldLabel(ad, 'year');
    const mileage = useAdExtraFieldLabel(ad, 'mileage');
    const fuel = useAdExtraFieldLabel(ad, 'petrol');

    if (!ad || !productSchema) {
        return null;
    }

    const schema: CarProductSchema = {
        ...productSchema,
        '@type': ['Product', 'Car'],
    };

    if (brand) {
        schema.brand = brand;
    }

    if (model) {
        schema.model = model;
    }

    if (year) {
        schema.modelDate = year.toString();
    }

    if (mileage) {
        schema.mileageFromOdometer = {
            '@type': 'QuantitativeValue',
            value: mileage,
            unitCode: 'KMT',
        };
    }

    if (fuel) {
        schema.fuelType = fuel;
    }

    return schema;
};

export default useCarProductSchema;
