import Loadable from 'strat/loadable';
import EMPTY_FUNCTION from 'strat/empty/function';

/**
 * Loads the contactForm component async.
 */
const ContactForm = Loadable({
    loader: () => import(/* webpackChunkName: 'contactForm' */ './contactFormSelector'),
    loading: EMPTY_FUNCTION,
});

export default ContactForm;
