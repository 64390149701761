export const getItem = (storageKey: string) => {
    if (process.env.IS_SERVER) {
        return null;
    }

    try {
        const storageData = localStorage.getItem(storageKey);
        if (storageData) {
            const data = JSON.parse(storageData);
            const expiry_date = localStorage.getItem(`${storageKey}_expires_at`);
            if (expiry_date && Number.parseInt(expiry_date) < Date.now()) {
                localStorage.removeItem(storageKey);
                localStorage.removeItem(`${storageKey}_expires_at`);
                return null;
            }

            return data;
        }
    } catch (e) {
        console.warn(`Tried to get localStorage key "${storageKey}" from session storage`, e);
    }

    return null;
};

export const setItem = (
    storageKey: string,
    data: any,
    exp?: { daysAvailable?: number; expirationDate?: Date },
) => {
    if (process.env.IS_SERVER) {
        return;
    }

    try {
        localStorage.setItem(storageKey, JSON.stringify(data));
        if (exp?.expirationDate) {
            localStorage.setItem(
                `${storageKey}_expires_at`,
                exp.expirationDate.getTime().toString(),
            );
        } else if (exp?.daysAvailable) {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + exp.daysAvailable);
            localStorage.setItem(`${storageKey}_expires_at`, expiryDate.getTime().toString());
        }
    } catch (e) {
        console.warn(`Tried to set localStorage key "${storageKey}"`, e);
    }
};

export const removeItem = (storageKey: string): void => {
    if (process.env.IS_SERVER) {
        return;
    }

    try {
        localStorage.removeItem(storageKey);
    } catch (e) {
        console.warn(`Tried to remove localStorage key "${storageKey}"`, e);
    }
};
