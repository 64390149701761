import React from 'react';
import { Trans } from '@lingui/macro';

import Loadable, { LoadingSpinner } from 'strat/loadable';
import IconBack from '@app/assets/icons/iconBack.svg';

import UserPopUp from './userPopUp';
import styles from './styles/popUp.cssm';

const ForgotPassContents = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ './forgotPassContents'),
    loading: LoadingSpinner,
});

/**
 * Properties for {@see ForgotPassPopUp}.
 */
type Props = {
    /**
     * Indicates whether the dialog should be visible.
     */
    visible: boolean;
    /**
     * Method to call when the visibility of the dialog changes.
     */
    onVisibilityChanged: (visible: boolean) => void;
    /**
     * Method to call when the "Back To Login" button is clicked.
     */
    onBackClick?: (event: React.SyntheticEvent<any>) => void;
    onSuccess?: () => void;
    initialValues?: {
        email?: string;
    };
    stackGroup?: string;
};

const ForgotPassPopUp = (props: Props) => {
    const renderBackButton = () => (
        <IconBack
            onClick={props.onBackClick}
            className={styles.backButton}
            aria-label={'Back to login'}
        />
    );
    const renderTitle = () => (
        <div className={styles.title}>
            <Trans>Forgot password</Trans>
        </div>
    );
    const popUpProps = {
        withoutCloseButton: true,
        renderHeaderBackButton: renderBackButton,
        renderHeaderTitle: renderTitle,
        stackGroup: props.stackGroup || 'forgotPassword',
    };

    return (
        // @ts-expect-error - TS2322 - Type '{ children: Element; withoutCloseButton: boolean; renderHeaderBackButton: () => Element; renderHeaderTitle: () => Element; stackGroup: string; visible: boolean; onVisibilityChanged: (visible: boolean) => void; } | { ...; }' is not assignable to type 'IntrinsicAttributes & Props'.
        <UserPopUp
            visible={props.visible}
            onVisibilityChanged={props.onVisibilityChanged}
            {...popUpProps}
        >
            <ForgotPassContents
                onBackClick={props.onBackClick}
                initialValues={props.initialValues}
                onSuccess={props.onSuccess}
            />
        </UserPopUp>
    );
};

export default ForgotPassPopUp;
