import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Button } from 'strat/components';

type Props = {
    readonly onClick?: () => void;
    readonly disabled?: boolean;
};

const LoadMoreHitsButton = ({ onClick, disabled = false }: Props) => (
    <Button
        secondary
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.currentTarget.blur();
            if (onClick) {
                onClick();
            }
        }}
        disabled={disabled}
    >
        <Trans>Load more</Trans>
    </Button>
);

export default LoadMoreHitsButton;
