import * as React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'strat/modal';
import { useLogout } from 'strat/auth';
import settings from '@app/branding/settings';
import useEnableDeliveryOrdersMenu from '@app/userOrders/hooks/useEnableDeliveryOrdersMenu';
import { Flex } from 'strat/components';
import { useIsProfolioUser } from 'strat/user';
import { useIsStratCreditUser } from 'strat/user';

import { UserSettingsLink, HelpLink } from 'horizontal/navigation';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';
import { trackOpenAccount } from 'horizontal/gtm/userTracking';
import UserProfilePicture from '@app/user/userProfilePicture';
import useFetchMyProfile from 'horizontal/profile/hooks/useFetchMyProfile';

import LogoutLink from './logoutLink';
import SwitchPortalLink from './switchPortalLink';
import AdManagementLink from './adManagementLink';
import WalletLink from './walletLink';
import BusinessPackagesLink from './businessPackagesLink';
import BoughtPackagesLink from './boughtPackagesLink';
import DeliveryOrdersLink from './deliveryOrdersLink';
import UserProfileBriefing from './userProfileBriefing';
import BlogLink from './blogLink';
import FavouritesAndSavedSearchesLink from './favouritesAndSavedSearchesLink';
import PublicProfileLink from './publicProfileLink';
import styles from './styles/userDropdown.cssm';

const UserDropdown = () => {
    const logout = useLogout();
    const [open, setOpen] = React.useState(false);
    const close = React.useCallback(() => setOpen(false), []);
    const enableDeliveryOrdersMenu = useEnableDeliveryOrdersMenu();
    const isProfolioUser = useIsProfolioUser();
    const isStratCreditUser = useIsStratCreditUser();

    useFetchMyProfile();

    const renderTrigger = React.useCallback(
        (openDropdown, isOpen) => (
            <Flex
                onClick={() => {
                    trackOpenAccount();
                    setOpen(!isOpen);
                    openDropdown();
                }}
                className={styles.trigger}
                alignCenter
            >
                <div className={styles.profilePicture}>
                    <UserProfilePicture showProBadge proBadgeClassName={styles.proBadgePosition} />
                </div>
                <img
                    src={iconArrowDown}
                    className={classNames(styles.dropdownArrow, { [styles.open]: isOpen })}
                    alt={'User profile dropdown arrow'}
                />
            </Flex>
        ),
        [setOpen],
    );

    const shouldShowWallet = !settings.disableWallet && !isProfolioUser && !isStratCreditUser;
    const shouldShowBusinessPacakges =
        settings.enableBusinessPackages && !isProfolioUser && !isStratCreditUser;
    const shouldShowBoughtPackages = !isProfolioUser && !isStratCreditUser;

    return (
        <Flex className={styles.container} alignCenter>
            <Dropdown renderTrigger={renderTrigger} open={open} onDismissed={close}>
                <div className={styles.dropdown} aria-label="My account">
                    <div className={styles.profile}>
                        <UserProfileBriefing onClick={close} />
                    </div>
                    <div className={styles.linkGroup}>
                        <SwitchPortalLink onClick={close} />
                        {shouldShowWallet && <WalletLink onClick={close} />}
                        {!isStratCreditUser && <AdManagementLink onClick={close} />}
                        <FavouritesAndSavedSearchesLink onClick={close} />
                        <PublicProfileLink onClick={close} />
                        {!CONFIG.build.HIDE_DELIVERY_ORDERS_OPTION && enableDeliveryOrdersMenu && (
                            <DeliveryOrdersLink onClick={close} />
                        )}
                        {shouldShowBusinessPacakges && <BusinessPackagesLink />}
                        {shouldShowBoughtPackages && <BoughtPackagesLink onClick={close} />}
                    </div>
                    <div className={styles.linkGroup}>
                        {settings.enableBlog && <BlogLink />}
                        <HelpLink />
                        <UserSettingsLink onClick={close} />
                    </div>
                    <LogoutLink onClick={logout} ariaLabel="Logout" />
                </div>
            </Dropdown>
        </Flex>
    );
};

export default UserDropdown;
