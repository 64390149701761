import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

import { useHumanizedPeriod } from 'horizontal/util';

const useCreationDate = (createdAt: number): string => {
    const i18n = useI18n();
    const period = createdAt && Date.now() - createdAt * 1000;
    const humanizedCreationDate = useHumanizedPeriod(period);

    return t(i18n)`${humanizedCreationDate} ago`;
};

export default useCreationDate;
