import React from 'react';

/**
 * Properties for {@see SocialDescriptionTags}.
 */
type Props = {
    description?: string;
};

const SocialDescriptionTags = (props: Props) => (
    <>
        <meta key="og" property="og:description" content={props.description} />
        <meta key="twitter" name="twitter:description" content={props.description} />
        <meta key="schema.org" itemProp="description" content={props.description} />
    </>
);

export default SocialDescriptionTags;
