const useExtraChargesPrice = (price?: number | null, extraCharge?: number | null) => {
    if (!price) {
        return 0;
    }
    if (!extraCharge) {
        return price;
    }
    return price + price * (extraCharge / 100);
};

export default useExtraChargesPrice;
