import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Input, Flex } from 'strat/components';

import type { Category } from 'horizontal/types';

import ActiveCategoryCheckbox from './activeCategoryCheckbox';
import FreeTextSearchButton from './freeTextSearchButton';
import styles from './styles/freeTextInput.cssm';

type Props = Readonly<
    React.ComponentProps<typeof Input> & {
        query: string;
        activeCategory: Category | null | undefined;
        onCategoryChanged: (arg1?: Category | null | undefined) => void;
        readonly searchInputClassName?: string;
    }
>;

const FreeTextInput = ({
    query,
    onSubmit,
    onChange,
    onFocusChanged,
    activeCategory,
    onCategoryChanged,
    onKeyDown,
    searchInputClassName,
}: Props) => {
    const i18n = useI18n();

    const placeholder = t(i18n)`Find Cars, Mobile Phones and more...`;
    const [checked, setChecked] = React.useState(true);

    const onActiveCategoryChanged = React.useCallback(
        (isChecked: boolean) => {
            setChecked(isChecked);
            onCategoryChanged(isChecked ? activeCategory : null);
        },
        [onCategoryChanged, activeCategory],
    );

    React.useEffect(() => {
        onActiveCategoryChanged(true);
    }, [activeCategory, onActiveCategoryChanged]);

    const renderRight = React.useCallback(
        () => (
            <ActiveCategoryCheckbox
                checked={checked}
                setChecked={onActiveCategoryChanged}
                activeCategory={activeCategory}
            />
        ),
        [checked, activeCategory, onActiveCategoryChanged],
    );

    return (
        <Flex className={styles.container} aria-label="Search input">
            <Input
                value={query}
                onChange={onChange}
                renderLeft={null}
                renderRight={renderRight}
                placeholder={placeholder}
                onFocusChanged={onFocusChanged}
                onKeyDown={onKeyDown}
                onSubmit={onSubmit}
                type="search"
                autoComplete="free-text-search"
                className={searchInputClassName}
            />
            <FreeTextSearchButton onClick={onSubmit} />
        </Flex>
    );
};

export default FreeTextInput;
