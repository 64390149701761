import { RoutingContextWithMiddlewares } from 'strat/app';

import Page from 'horizontal/pages/page';

const renderNotFoundPage = (context: RoutingContextWithMiddlewares) => {
    context.http.status(404);
    context.rendering.renderPage(Page.NOT_FOUND);
    return Promise.resolve();
};

export default renderNotFoundPage;
