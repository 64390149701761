import { createSelector } from 'reselect';

import { selectProperty } from 'strat/property/selectors';
import Purpose from 'strat/purpose';

const selectShowMortgageCalculator = createSelector(
    selectProperty,
    (property) => Purpose.isForSale(property.purpose) && property.price > 0,
);

export { selectShowMortgageCalculator };
