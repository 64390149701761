import * as React from 'react';
import { Trans } from '@lingui/macro';

import { connectLanguage } from 'strat/i18n/language';

import styles from './styles/moreAmenitiesButton.cssm';

type ButtonProps = {
    amenitiesCount: number;
    className?: string;
    onClick: (event: React.SyntheticEvent<any>) => void;
    formatNumber: (arg1: number) => string;
};

const MoreAmenitiesButton = ({ className = styles.moreButton, ...props }: ButtonProps) => {
    const amenitiesCount = props.formatNumber(props.amenitiesCount);

    return (
        <div className={className} onClick={props.onClick} aria-label="More amenities">
            <Trans>+ {amenitiesCount} more amenities</Trans>
        </div>
    );
};

export default connectLanguage(MoreAmenitiesButton);
