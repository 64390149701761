import { PermissionTypes } from '../types';

import Actions from './actions';
import type { NotificationsAction } from './actions';

type State = {
    readonly promptPostponed: boolean;
    readonly scriptLoaded: boolean;
    readonly permission: Values<typeof PermissionTypes>;
    readonly supported: boolean;
    readonly isUsingFallbackNotifications: boolean;
    readonly promptRetries: number;
    readonly backOffUntil: Date;
    readonly promptsVisibility: {
        [key: string]: boolean;
    };
};

/**
 * Default notifications state.
 */
const defaultState: State = {
    promptPostponed: false,
    scriptLoaded: false,
    supported: false,
    permission: PermissionTypes.DEFAULT,
    isUsingFallbackNotifications: false,
    promptRetries: 0,
    backOffUntil: new Date(),
    promptsVisibility: {},
};

/**
 * Reducer for notifications related actions.
 */
const notificationReducer = (
    state: State | null | undefined = defaultState,
    action: NotificationsAction,
) => {
    switch (action.type) {
        case Actions.SET_SUPPORTED:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'supported' does not exist on type 'NotificationsAction'.
                supported: action.supported,
            };
        case Actions.CHANGE_PERMISSION:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'permission' does not exist on type 'NotificationsAction'.
                permission: action.permission,
            };
        case Actions.POSTPONE_PROMPT:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'postponed' does not exist on type 'NotificationsAction'.
                promptPostponed: action.postponed,
            };
        case Actions.LOAD_NOTIFICATIONS_SCRIPT:
            return {
                ...state,
                scriptLoaded: true,
            };
        case Actions.BLOCK_NOTIFICATIONS_SCRIPT:
            return {
                ...state,
                scriptLoaded: false,
            };
        case Actions.SET_IS_USING_FALLBACK_NOTIFICATIONS:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'isUsingFallbackNotifications' does not exist on type 'NotificationsAction'.
                isUsingFallbackNotifications: action.isUsingFallbackNotifications,
            };
        case Actions.SET_NOTIFICATIONS_BACKOFF:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'backOffUntil' does not exist on type 'NotificationsAction'.
                backOffUntil: action.backOffUntil,
            };
        case Actions.CLOSE_SLIDE_IN:
            return {
                ...state,
                // @ts-expect-error - TS2531 - Object is possibly 'null'.
                promptRetries: state.promptRetries + 1,
            };
        case Actions.SET_PROMPT_VISIBLE:
            return {
                ...state,
                promptsVisibility: {
                    // @ts-expect-error - TS2531 - Object is possibly 'null'.
                    ...state.promptsVisibility,
                    // @ts-expect-error - TS2339 - Property 'prompt' does not exist on type 'NotificationsAction'. | TS2339 - Property 'visible' does not exist on type 'NotificationsAction'.
                    [action.prompt]: action.visible,
                },
            };
        default:
            return state;
    }
};

export default notificationReducer;
