import React from 'react';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import classNames from 'classnames';
import { Text } from 'strat/components';
import { useActiveUser } from 'strat/user/session';

import isAdInactive from '@app/adDetails/isAdInactive';
import { SellerCardSwitcher } from 'horizontal/adDetails';
import { AdDetailsBanner, Position, Sizes } from 'horizontal/tagmanager';
import {
    LocationAndId,
    OwnAdSection,
    RequestDeliverySection,
    SafetyRules,
} from 'horizontal/adDetails/components';
import { Section } from 'horizontal/adDetails/section';
import { useAd } from 'horizontal/ad/state';
import useShouldShowOwnAdSection from 'horizontal/adDetails/useShouldShowOwnAdSection';
import { isAdEditor, isEligibleForDelivery } from 'horizontal/util';
import { useFullLocationsHierarchy } from 'horizontal/location';

import styles from './styles/adSidebar.cssm';

const AdSidebar = () => {
    const ad = useAd();

    const user = useActiveUser();
    const isOwnAd = ad && user && isAdEditor(ad, user.externalID);
    const isInactiveAd = isAdInactive(ad);
    const isAvailableForDelivery = !isInactiveAd && isEligibleForDelivery(ad);

    const { shouldShowOwnAdSection, shouldShowOwnAdAnalytics, shouldShowOwnAdBanner } =
        useShouldShowOwnAdSection(!!isOwnAd, ad);
    const photoCount = ad?.photos?.length || 0;
    const fullLocations = useFullLocationsHierarchy(ad?.location || []);

    let marginSidebarClassName = null;

    if (CONFIG.build.DUBIZZLE_ENABLE_GALLERY_REDESIGN) {
        if (photoCount == 2) {
            marginSidebarClassName = styles.twoPhotosSidebar;
        }
        if (photoCount < 2) {
            marginSidebarClassName = styles.onePhotoSidebar;
        }
    }

    if (!ad) {
        return null;
    }

    return (
        <div className={classNames(styles.sidebar, marginSidebarClassName)}>
            {shouldShowOwnAdSection && (
                <OwnAdSection
                    ad={ad}
                    withAnalytics={shouldShowOwnAdAnalytics}
                    withAdBanner={shouldShowOwnAdBanner}
                />
            )}
            {!isOwnAd && !isInactiveAd && <SellerCardSwitcher />}
            {!isOwnAd && isAvailableForDelivery && <RequestDeliverySection ad={ad} />}
            {!isOwnAd && isInactiveAd && (
                <Section>
                    <div>
                        <Text.XLarge>
                            <Trans>This ad is no longer available</Trans>
                        </Text.XLarge>
                    </div>
                </Section>
            )}
            <SafetyRules />
            <AdDetailsBanner ad={ad} position={Position.MIDDLE} />
            {!settings.enableAdDetailsMap && (
                <LocationAndId ad={ad} isOwnAd={isOwnAd} location={fullLocations} />
            )}
            <AdDetailsBanner ad={ad} position={Position.MIDDLE_BOTTOM} />
            <AdDetailsBanner
                ad={ad}
                sizes={Sizes.desktop.details.bottom}
                position={Position.BOTTOM}
            />
        </div>
    );
};
export default AdSidebar;
