import { MultiJobSearchHandler } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import settings from '@app/branding/settings';

import { hitsTransformer } from 'horizontal/search/hits';
import type { LiteAd, Location } from 'horizontal/types';
import { setPromotedSearches } from 'horizontal/home/promotedSearches';

import { SearchJobName } from './constants';

const processPromotedSearchesResponse = (
    context: RoutingContextWithMiddlewares,
    jobSearchHandler: MultiJobSearchHandler,
) => {
    const {
        redux: {
            store: { dispatch },
        },
        i18n: { locale: language },
    } = context;

    const locations = jobSearchHandler.getResponseByJobName(
        SearchJobName.promotedSearchesLocations(),
    )?.hits;
    const promotedSearches = settings.homePagePromotedSearches.map(
        ({ displayCategorySlug, searchCategorySlug, locationExternalID }) => {
            const hits = jobSearchHandler.getResponseByJobName(
                SearchJobName.promotedSearchHits(displayCategorySlug, locationExternalID),
            )?.hits as LiteAd[];
            const location = locations?.find(
                (location) => location.externalID === locationExternalID,
            ) as Location;
            return {
                displayCategorySlug,
                searchCategorySlug,
                location,
                hits: hitsTransformer<LiteAd>(hits, language),
            };
        },
    );

    dispatch(setPromotedSearches(promotedSearches));
};

export default processPromotedSearchesResponse;
