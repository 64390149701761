import { ContextfulError } from 'strat/error/context';

const byteToMegabyte = (size: number) => size / (1024 * 1024);

const MAX_SIZE_MB = CONFIG.build.TEMP_IMAGE_MAX_SIZE_MB;

const MAX_RESOLUTION = CONFIG.build.TEMP_IMAGE_MAX_RESOLUTION;

type Size = {
    width: number;
    height: number;
};

/**
 * Load the image in memory and returns it's size based on an image url.
 *
 * @param {string} url - The url of the image for which to get the size.
 * @returns {{ width: number, height: number }} Object containing the width and height of the image.
 * @throws {ContextFulError} If there is an error when loading the image.
 */
export const getImageSize = (url: string): Promise<Size> => {
    const img = new Image();
    const promise = new Promise<Size>((resolve, reject) => {
        img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            resolve({ width, height });
        };
        img.onerror = (event) => {
            reject(
                new ContextfulError(
                    `Failed to detect image size using Horizontal's useUploadImage`,
                    { url, message: (event as ErrorEvent)?.message, cause: event },
                ),
            );
        };
    });
    img.src = url;
    return promise;
};

/**
 * Checks if an image meets the upload sizing constraints for the horizontal backend.
 *
 * @param {number} size - The size in bytes to be converted.
 * @returns true if the file is too large.
 */
export const isImageTooLarge = (size: number) => byteToMegabyte(size) > MAX_SIZE_MB;

/**
 * Checks if an image has an accepted resolution by the horizontal backend.
 *
 * @param {number} size - The size in bytes to be converted.
 * @returns true if the file is too large.
 */
export const isImageResolutionTooHigh = ({ width, height }: Size) =>
    width * height > MAX_RESOLUTION;
