import React from 'react';
import { Checkbox } from 'strat/components';

import DeliveryCheckboxLabel from '@app/fields/deliveryCheckboxLabel';
import { DeliveryType } from 'horizontal/types';

import SelfDeliveryCheckboxLabel from './selfDeliveryCheckboxLabel';
import { AdDeliveryFields } from './commonPostingFields';
import ErrorMessage from './errorMessage';

type Props = {
    readonly checked: boolean;
    readonly onChange: () => void;
    readonly errorMessage: string;
    readonly deliveryType?: DeliveryType;
};

const DeliveryCheckbox = ({
    checked,
    onChange,
    errorMessage,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: Props) => (
    <div>
        <Checkbox
            // @ts-expect-error - TS2322 - Type '{ id: string; name: string; label: Element; checked: boolean; onChange: () => void; }' is not assignable to type 'IntrinsicAttributes & Props'.
            id={AdDeliveryFields.delivery_terms.attribute}
            name={AdDeliveryFields.delivery_terms.attribute}
            label={
                CONFIG.build.ENABLE_SELF_DELIVERY && deliveryType === DeliveryType.SELF_DELIVERY ? (
                    <SelfDeliveryCheckboxLabel />
                ) : (
                    <DeliveryCheckboxLabel />
                )
            }
            checked={checked}
            onChange={onChange}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
);

export default DeliveryCheckbox;
