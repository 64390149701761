import * as React from 'react';

function useResizeObserver<T extends HTMLElement | null>(
    onResize: (entries: ResizeObserverEntry[]) => void,
): (element: T) => void {
    const observerRef = React.useRef<ResizeObserver | null>(null);

    const observe = (element: T) => {
        if (observerRef.current) {
            observerRef.current.disconnect();
            observerRef.current = null;
        }

        if (!element) {
            return;
        }

        const observer = new ResizeObserver(onResize);
        observer.observe(element);
        observerRef.current = observer;
    };

    return observe;
}

export default useResizeObserver;
