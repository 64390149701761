//@ts-nocheck
import type { Dispatch, GetState } from 'redux';
import {
    getFilterCollection,
    selectActiveSearchBackend,
    setFilters,
} from '@sector-labs/fe-search-redux/state';
import {
    ExactFilter,
    FilterCollection,
    RefinementFilter,
} from '@sector-labs/fe-search-redux/filters';
import settings from '@app/branding/settings';

import { AppDispatch } from 'strat/state';
import FetcherFactory, { buildDefaultState, FetchState } from 'strat/fetcher';
import type { AgentData } from 'strat/agency/agent/types';
import { AgentState } from 'strat/agency/agent/types';
import { StratAPI } from 'strat/api';
import { HitValue } from 'strat/search/searchBox';
import { selectI18n } from 'strat/i18n/language';
import fetchFromProboardAndUpdateState from '@app/agency/search/form/fetchDataFromProBoard';
import { getCubeRankingField } from 'strat/agency/search/form/fetchDataFromProBoard';
import { STRAT_RANKING_FIELDS_MAPPING } from 'strat/agency/agent/listing/useAgentRank';
import { FilterValues } from 'strat/search';
import { searchFiltersToRouteParams } from 'strat/agency/propertySection/url';
import { selectRouteParamsInput } from 'strat/search/url';
import { updateURL } from 'strat/search/state';
import {
    selectCurrentAgent,
    selectHasAnyFilterAddedOnAgentSearch,
    TabNames,
} from 'strat/agency/agent/state/selectors';
import Purpose from 'strat/purpose';
import shouldFetchFromCube from 'strat/agency/agent/state/shouldFetchFromCube';

import fetchCurrentAgentLeaderboardInfo from './fetchCurrentAgentLeaderboardInfo';

type FetchAgentParams = {
    adExternalID?: string;
    externalID?: string;
    slug?: string;
};

/**
 * Fetcher factory for fetching agencies.
 */
const factory = new FetcherFactory('agent', (params: FetchAgentParams, state: any) => {
    let agentPromise = null;
    const stratAPI = new StratAPI(state.i18n.language);

    if (params.adExternalID) {
        agentPromise = stratAPI.agentByAd(params.adExternalID, AgentState.ACTIVE);
    } else if (params.externalID) {
        agentPromise = stratAPI.agent(params.externalID, AgentState.ACTIVE);
    } else {
        agentPromise = stratAPI.agentBySlug(params.slug, AgentState.ACTIVE);
    }

    return agentPromise.then((response) => {
        if (response.status === 200 || response.status === 304) {
            return {
                data: response.data,
                status: 200,
            };
        }
        return {
            data: null,
            status: 200,
        };
    });
});

/**
 * Action creator for fetching a agent from
 * the back-end.
 */
const fetchAgent = factory.creator();

const clearAgent = () => ({
    type: factory.actions.clear,
});

/**
 * Action creator for pre-loading an agent
 */
const preloadAgent = (agent: AgentData) => ({
    type: factory.actions.preload,
    params: { slug: agent.slug },
    data: agent,
});

/**
 * Reducers for agent.
 */
const agentFetcherReducer = factory.reducer();

const Actions = Object.freeze({
    SET_CURRENT_AGENT: 'AGENTS/SET_CURRENT_AGENT',
    CLEAR_CURRENT_AGENT: 'AGENTS/CLEAR_CURRENT_AGENT',
    SET_ACTIVE_TAB: 'AGENTS/SET_ACTIVE_TAB',
});

export type AgentSearchType = FetchState & {
    currentAgent: HitValue | null;
    activeTab: string | null;
};

export type AgentSearchState = {
    agent: AgentSearchType;
};

const defaultState = {
    ...buildDefaultState(),
    agentIDs: null,
    currentAgent: null,
    activeTab: null,
};

type SetAgentIDs = {
    type: Actions.SET_AGENT_IDS;
    agentIDs: Array<string>;
};

type SetCurrentAgent = {
    type: Actions.SET_CURRENT_AGENT;
    currentAgent: HitValue;
};

type ClearAgent = {
    type: Actions.CLEAR_CURRENT_AGENT;
};

type SetActiveTab = {
    type: Actions.SET_ACTIVE_TAB;
    activeTab: string;
};

type Action = SetAgentIDs | SetCurrentAgent | ClearAgent | SetActiveTab;

const agentReducer = (state = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.SET_CURRENT_AGENT:
            return {
                ...state,
                currentAgent: action.currentAgent,
            };
        case Actions.CLEAR_CURRENT_AGENT:
            return {
                ...state,
                currentAgent: null,
            };
        case Actions.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTab,
            };
        default:
            return agentFetcherReducer(state, action);
    }
};

export const setCurrentAgent =
    (
        currentAgent: HitValue | null,
        updateAgentPage?: (agent: HitValue | null) => void,
        filters?: FilterCollection,
    ) =>
    (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
        /**
         * When setting the current agent, we need to merge it with the agent from the BI loc_lvl
         * index so that we obtain the leaderboard data and location ranking for that agent.
         * INPUT: currentAgent = the agent hit from the algolia agent index
         *        updateAgentPage = optional, a function that updates the search page number
         *        filters = optionally, you can pass custom filters that need to be used. Use case: in agentSearchRoute
         **/
        const i18n = selectI18n(getState());
        const allFilters = filters || getFilterCollection(getState());
        const backend = selectActiveSearchBackend(getState());

        if (!currentAgent) {
            dispatch({
                type: Actions.SET_CURRENT_AGENT,
                currentAgent,
            });
        }

        fetchCurrentAgentLeaderboardInfo(i18n, allFilters, currentAgent, backend).then(
            (fullAgent) => {
                dispatch({
                    type: Actions.SET_CURRENT_AGENT,
                    currentAgent: fullAgent,
                });
                updateAgentPage?.(fullAgent);
            },
        );
    };
export const setCurrentPrefetchedAgent =
    (currentAgent: HitValue | null, updateAgentPage?: (agent: HitValue | null) => void) =>
    (dispatch: Dispatch<AppDispatch>, _getState: GetState) => {
        /**
         * This sets currentAgent with a prefetched agent, i.e. with an agent that has all the leaderboard information.
         * INPUT: currentAgent = the agent hit from the algolia agent index
         *        updateAgentPage = optional, a function that updates the search page number
         **/
        if (!currentAgent) {
            dispatch({
                type: Actions.SET_CURRENT_AGENT,
                currentAgent,
            });
        }
        dispatch({
            type: Actions.SET_CURRENT_AGENT,
            currentAgent: currentAgent,
        });
        updateAgentPage?.(currentAgent);
    };

export const clearCurrentAgent = () => ({
    type: Actions.CLEAR_CURRENT_AGENT,
});

const setNewActiveTab = (activeTab: string | null) => ({
    type: Actions.SET_ACTIVE_TAB,
    activeTab,
});

export const setAgentActiveTab =
    (activeTab: string | null) => (dispatch: Dispatch<AppDispatch>, _getState: GetState) => {
        if (Object.values(TabNames).includes(activeTab)) {
            dispatch(setNewActiveTab(activeTab));
        } else {
            dispatch(setNewActiveTab(null));
        }
    };

export const computeNewAgentPage = (
    filters: FilterCollection,
    agent: HitValue,
    useCubeRanking: boolean,
    shouldSetToFirstPage = false,
): number => {
    if (shouldSetToFirstPage) {
        return 1;
    }
    const cubeRankingField = getCubeRankingField(filters);
    const stratRankingField = STRAT_RANKING_FIELDS_MAPPING[cubeRankingField];
    if (!agent || (useCubeRanking && !agent[stratRankingField])) {
        return -1;
    }
    return Math.ceil(
        (useCubeRanking ? agent[stratRankingField] : agent.truBrokerRanking) /
            (settings.leaderboardAgentPageSize ?? 1),
    );
};

const resetAgentPageFilter = (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
    const filters = getFilterCollection(getState());
    filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
    filters.refine(new ExactFilter({ attribute: FilterValues.agentOwner.attribute, value: [] }));
    dispatch(setFilters(filters));
};

const recomputeAgentPageFilter = (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
    const filters = getFilterCollection(getState());
    const locationsWithAds: Array<any> = filters.getFilterValue(
        FilterValues.agentLocation.attribute,
        [],
    );
    const currentAgent = selectCurrentAgent(getState());
    const hasAnyFiltersApplied = selectHasAnyFilterAddedOnAgentSearch(getState());
    if (currentAgent && !hasAnyFiltersApplied) {
        const useCubeRanking = shouldFetchFromCube(filters, locationsWithAds);
        const newPage = computeNewAgentPage(filters, currentAgent, useCubeRanking);
        const currentPage: number = filters.getFilterValue(FilterValues.page.attribute, 0);
        if (currentPage !== newPage) {
            filters.refine(new ExactFilter({ attribute: 'page', value: newPage || -1 }));
            dispatch(setFilters(filters));
        }
    } else {
        filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
        dispatch(setFilters(filters));
    }
};

const resetCurrentAgentAndFetch = (dispatch: Dispatch<AppDispatch>) => {
    dispatch(clearCurrentAgent());
    dispatch(resetAgentPageFilter);
    dispatch(fetchFromProboardAndUpdateState(settings.leaderboardAgentPageSize));
};

const setCurrentAgentAndUpdateData =
    (newAgent: HitValue, newAgentID: any) =>
    (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
        const { filters } = selectRouteParamsInput(getState());
        const hasAnyFiltersApplied = selectHasAnyFilterAddedOnAgentSearch(getState());
        const locationsWithAds: Array<any> = filters.getFilterValue(
            FilterValues.agentLocation.attribute,
            [],
        );

        filters.refine(
            new ExactFilter({
                attribute: FilterValues.agentOwner.attribute,
                value: [newAgentID],
            }),
        );

        const updatePageAndURL = (agent: HitValue) => {
            const useCubeRanking = shouldFetchFromCube(filters, locationsWithAds);
            const newPage = computeNewAgentPage(filters, agent, useCubeRanking);
            const currentPage: number = filters.getFilterValue(FilterValues.page.attribute, 0);
            if (currentPage !== newPage && !hasAnyFiltersApplied) {
                filters.refine(new ExactFilter({ attribute: 'page', value: newPage || -1 }));
                dispatch(setFilters(filters));
                dispatch(fetchFromProboardAndUpdateState(settings.leaderboardAgentPageSize));
            } else {
                dispatch(setFilters(filters));
                const routeParams = searchFiltersToRouteParams(filters);
                // @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'.
                dispatch(updateURL(routeParams, { replace: true }));
            }
        };

        dispatch(setCurrentAgent(newAgent, updatePageAndURL));
    };

export const updateAgentPageAndURL =
    (dispatch: Dispatch<AppDispatch>) => (agent: HitValue | null) => {
        if (agent) {
            dispatch(recomputeAgentPageFilter);
        }
        dispatch(fetchFromProboardAndUpdateState(settings.leaderboardAgentPageSize));
    };

export const setNewLocationAndUpdateData =
    (newLocation: HitValue) => (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
        const filters = getFilterCollection(getState());
        const currentAgent = selectCurrentAgent(getState());
        filters.refine(
            new RefinementFilter({
                attribute: FilterValues.agentLocation.attribute,
                value: newLocation,
            }),
        );
        filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
        dispatch(setFilters(filters));

        dispatch(setCurrentAgent(currentAgent, updateAgentPageAndURL(dispatch)));
    };

export const updateCurrentPageAndFetch = (dispatch: Dispatch<AppDispatch>) => {
    dispatch(recomputeAgentPageFilter);
    dispatch(fetchFromProboardAndUpdateState(settings.leaderboardAgentPageSize));
};

export const refineAgentPurposeFilter =
    (newPurpose: string) => (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
        const filters = getFilterCollection(getState());
        const currentAgent = selectCurrentAgent(getState());
        if (Purpose.isAny(newPurpose)) {
            filters.remove(FilterValues.purpose.truBrokerAttribute);
        } else {
            filters.refine(
                new ExactFilter({
                    attribute: FilterValues.purpose.truBrokerAttribute,
                    value: newPurpose,
                }),
            );
        }
        filters.refine(
            new ExactFilter({
                attribute: FilterValues.page.attribute,
                value: 1,
            }),
        );
        dispatch(setFilters(filters));
        dispatch(setCurrentAgent(currentAgent, updateAgentPageAndURL(dispatch)));
    };

export const refineAgentCategoryFilter =
    (filterAttribute: string, chipValue: string) =>
    (dispatch: Dispatch<AppDispatch>, getState: GetState) => {
        const filters = getFilterCollection(getState());
        const currentAgent = selectCurrentAgent(getState());
        filters.refine(
            new ExactFilter({
                attribute: filterAttribute,
                value: chipValue,
            }),
        );
        filters.refine(
            new ExactFilter({
                attribute: FilterValues.page.attribute,
                value: 1,
            }),
        );

        dispatch(setFilters(filters));
        dispatch(setCurrentAgent(currentAgent, updateAgentPageAndURL(dispatch)));
    };

export const resetAgentPurposeAndCategoryFilter = (
    dispatch: Dispatch<AppDispatch>,
    getState: GetState,
) => {
    const filters = getFilterCollection(getState());
    const currentAgent = selectCurrentAgent(getState());
    filters.remove(FilterValues.agentSaleCategory.attribute);
    filters.remove(FilterValues.agentRentalCategory.attribute);
    filters.remove(FilterValues.purpose.truBrokerAttribute);
    dispatch(setFilters(filters));

    dispatch(setCurrentAgent(currentAgent, updateAgentPageAndURL(dispatch)));
};

export {
    clearAgent,
    fetchAgent,
    preloadAgent,
    agentReducer,
    resetAgentPageFilter,
    resetCurrentAgentAndFetch,
    setCurrentAgentAndUpdateData,
    recomputeAgentPageFilter,
};
