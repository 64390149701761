import * as React from 'react';

type UseLayoutEffect = typeof React.useLayoutEffect;

type Effect = Parameters<UseLayoutEffect>[0];
type Deps = Parameters<UseLayoutEffect>[1];

const noopUseLayoutEffect = (_effect: Effect, _deps?: Deps) => {};

// React currently throws a warning when using useLayoutEffect on the server.
//
// To silence the warning on the server, we use a noop function
// when we're rendering on the server.
const useIsomorphicLayoutEffect: UseLayoutEffect = process.env.IS_SERVER
    ? noopUseLayoutEffect
    : React.useLayoutEffect;

export default useIsomorphicLayoutEffect;
