import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectActiveSearchBackend,
    setFilters,
    setSettings,
} from '@sector-labs/fe-search-redux/state';
import type { SearchStateSettings } from '@sector-labs/fe-search-redux/state';
import {
    ExistFilter,
    FilterCollection,
    ObjectExactFilter,
    QueryFilter,
    PageFilter,
    RangeFilter,
    ExactFilter,
    InsideCircleFilter,
} from '@sector-labs/fe-search-redux/filters';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';
import FilterValues from 'strat/search/filterValues';
import { updateSearchHistory } from 'strat/user/state';
import brandingSettings from '@app/branding/settings';
import type { AppDispatch } from 'strat/state';
import settings from '@app/branding/settings';

import type { SearchRouteParams } from 'horizontal/routes';
import { checkForDisabledLocationInParams } from 'horizontal/search/location/disabledLocations';
import type { CategoryFieldsFilters, FlatCategoryField } from 'horizontal/types';
import { fetchMultipleLocationsByExternalID } from 'horizontal/search/location';
import { selectCategories } from 'horizontal/categories';

import { useFlatFilterableCategoryFields } from '../categoryFields';

import searchParamsToFilters from './searchParamsToFilters';

const createFiltersFromSearchRouteParams = (
    categoryFields: Array<FlatCategoryField>,
    params?: SearchRouteParams,
): FilterCollection => {
    const filters = new FilterCollection();

    if (params && params.category) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.category.attribute,
                value: params.category,
            }),
        );
    }

    if (
        params &&
        params.location &&
        params.location.externalID !== brandingSettings.topLevelLocation.externalID
    ) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.location.attribute,
                value: params.location,
                operator: Operator.OR,
            }),
        );
    }

    if (params?.locationRadius && params?.location?.geography) {
        filters.refine(
            new InsideCircleFilter({
                attribute: FilterValues.locationCoordinates.attribute,
                value: {
                    areaUnit: 'km',
                    radius: params.locationRadius,
                    coordinates: params.location.geography,
                },
                key: FilterValues.locationRadius.attribute,
                operator: Operator.OR,
            }),
        );
    }

    if (params && params.freeTextQuery) {
        filters.refine(
            new QueryFilter({
                value: params.freeTextQuery,
                fuzzy: settings.searchFuzzySettings,
                fields: ['query'],
            }),
        );
    }

    if (params?.minPhotoCount && params.minPhotoCount > 0) {
        filters.refine(
            new RangeFilter({
                attribute: FilterValues.photoCount.attribute,
                value: { min: params.minPhotoCount, max: null },
            }),
        );
    }

    if (params?.product) {
        filters.refine(
            new ExactFilter({
                attribute: FilterValues.product.attribute,
                value: params.product,
            }),
        );
    }

    if (params && params.page && params.page > 1) {
        filters.refine(
            new PageFilter({
                value: params.page,
            }),
        );
    }

    if (params && params.extraFields) {
        const paramsToFilters = searchParamsToFilters(
            categoryFields,
            params.extraFields as CategoryFieldsFilters,
        );
        filters.refineMultiple(paramsToFilters);
    }

    if (params?.withAgency) {
        filters.refine(
            new ExistFilter({
                attribute: 'agency',
            }),
        );
    }

    return filters;
};

const createSettingsFromSearchRouteParams = (
    params?: SearchRouteParams,
): Partial<SearchStateSettings> => {
    const settings: Record<string, any> = {};

    if (params && params.sortOption) {
        settings.sort = params.sortOption;
    }

    return settings;
};

type UseUpdateFiltersPreemptively = (arg1: SearchRouteParams) => void;

const useUpdateFiltersPreemptively = (): UseUpdateFiltersPreemptively => {
    const dispatch = useDispatch<AppDispatch>();
    const categoryFields = useFlatFilterableCategoryFields();
    const categories = useSelector(selectCategories);
    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);
    const router = useRouter();

    const fetchLocations = React.useCallback(
        (externalIDs) => {
            // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
            return fetchMultipleLocationsByExternalID(externalIDs, language, backend, 10).then(
                (locationData): Array<Location> => {
                    if (!locationData.length) {
                        return [];
                    }
                    // @ts-expect-error - TS4104 - The type 'readonly SearchResponseHit[]' is 'readonly' and cannot be assigned to the mutable type 'Location[]'.
                    return locationData;
                },
            );
        },
        [backend, language],
    );

    return React.useCallback(
        (params?: SearchRouteParams) => {
            let finalParams = { ...params };
            checkForDisabledLocationInParams(params, categories, fetchLocations).then(
                (newParams) => {
                    if (
                        params &&
                        newParams &&
                        newParams.location &&
                        params.location &&
                        newParams.location.externalID !== params.location.externalID
                    ) {
                        finalParams = { ...params, location: newParams.location };
                    }

                    const filters = createFiltersFromSearchRouteParams(categoryFields, finalParams);
                    const settings = createSettingsFromSearchRouteParams(finalParams);
                    dispatch(updateSearchHistory(filters));
                    if (
                        finalParams &&
                        finalParams.location &&
                        params &&
                        params.location &&
                        finalParams.location.externalID !== params.location.externalID
                    ) {
                        router.replaceRoute(RouteNames.SEARCH, finalParams);
                    } else {
                        dispatch(setFilters(filters));
                        dispatch(setSettings(settings));
                    }
                },
            );
        },
        [dispatch, categoryFields, categories, router, fetchLocations],
    );
};

export default useUpdateFiltersPreemptively;
export { createFiltersFromSearchRouteParams };
