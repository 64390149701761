import * as React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import { useDialog } from 'strat/dialogs/dialogContext';

import { CTA, MixedAd } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';
import { PhoneNumberLinkType, redirectToPhoneURL } from 'horizontal/contact';
import { DialogNames } from 'horizontal/dialogs';
import { shouldDisplayProAgencyDataRegulationsDisclaimer } from 'horizontal/agency/roles';

import getPhoneMessageContent from './getPhoneMessageContent';

type Params = {
    readonly ad?: MixedAd | null;
    readonly viewSection: Values<typeof ViewSections>;
    readonly trackSMSLead: (viewSection: Values<typeof ViewSections>) => Promise<string>;
    readonly shouldOpenCTADialog?: boolean;
};

const useOnSMSButtonClick = ({ ad, viewSection, trackSMSLead, shouldOpenCTADialog }: Params) => {
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();
    const language = useSelector(selectLanguage);
    const [, setCTADialogVisible] = useDialog(DialogNames.CTA_DIALOG);

    const trackSMSLeadAndRedirectToPhoneURL = React.useCallback(
        (phoneNumber: string, ad: MixedAd) => {
            makeCancelable(
                trackSMSLead(viewSection).then((traceID) => {
                    const message = getPhoneMessageContent(ad.externalID, traceID, i18n, language);
                    redirectToPhoneURL(phoneNumber, PhoneNumberLinkType.SMS, message);
                }),
            );
        },
        [i18n, language, trackSMSLead, viewSection, makeCancelable],
    );

    return React.useCallback(
        (phoneNumber: string) => {
            if (!ad?.externalID) {
                return null;
            }
            shouldOpenCTADialog && shouldDisplayProAgencyDataRegulationsDisclaimer(ad.agency)
                ? setCTADialogVisible(true, {
                      ad,
                      phoneNumber: phoneNumber,
                      ctaType: CTA.SMS,
                      onClick: () => trackSMSLeadAndRedirectToPhoneURL(phoneNumber, ad),
                  })
                : trackSMSLeadAndRedirectToPhoneURL(phoneNumber, ad);
        },
        [ad, setCTADialogVisible, shouldOpenCTADialog, trackSMSLeadAndRedirectToPhoneURL],
    );
};

export default useOnSMSButtonClick;
