import { t } from '@lingui/macro';
import React from 'react';

import { useI18n } from 'strat/i18n/language';

import styles from './styles/propertyNotAvailable.cssm';

/**
 * Props for {@see PropertyNotAvailable}.
 */
type Props = {
    className?: string;
};

/**
 * Renders a message about an unavailable property.
 */
const PropertyNotAvailable = ({ className }: Props): React.ReactElement => {
    const i18n = useI18n();

    return (
        <span className={className || styles.notAvailable} aria-label="Inactive property banner">
            {t(i18n)`This property is no longer available`}
        </span>
    );
};

export default PropertyNotAvailable;
