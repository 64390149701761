//@ts-nocheck
import 'whatwg-fetch';
import staticPages from '@app/branding/staticPages';

import FetcherFactory from 'strat/fetcher';
import { logError } from 'strat/error/log';

let readFile = () => '';

if (process.env.IS_SERVER) {
    // eslint-disable-next-line
    const util = require('util');
    // eslint-disable-next-line
    const fs = require('fs');

    readFile = util.promisify(fs.readFile);
}

/**
 * Fetcher factory for static pages.
 */
const factory = new FetcherFactory('staticPage', (params: any, state: any) => {
    const pageEntry = staticPages.find((entry) => entry.route === params.staticPage);

    if (!pageEntry) {
        return { status: 404, data: null };
    }

    const fileName = pageEntry.path(state.i18n.language, state.layout);

    const url = `static/${fileName}`;

    return readFile(url)
        .then((text) => ({
            status: 200,
            data: {
                content: text.toString(),
                route: pageEntry.route,
            },
        }))
        .catch((e) => {
            logError({
                e,
                msg: `Failed to fetch file in static data`,
                context: {
                    url,
                },
            });
            return { status: 404, data: null };
        });
});

/**
 * Action creator for fetching a static page
 */
const fetchStaticPage = factory.creator();

/**
 * Reducers for static pages.
 */
const staticPageReducer = factory.reducer();

export { fetchStaticPage };

export default staticPageReducer;
