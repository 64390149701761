import * as React from 'react';

import PhoneIcon from 'horizontal/assets/icons/phone.svg';
import type { ChatAd } from 'horizontal/chat/types';
import { PhoneNumberLinkType } from 'horizontal/contact';

import PhoneContactButton from './phoneContactButton';

type Props = {
    readonly className: string;
    readonly ad: ChatAd | null | undefined;
    readonly ariaLabel?: string;
};

const PhoneNumberButton = ({ className, ad }: Props) => (
    <PhoneContactButton
        icon={<PhoneIcon className={className} />}
        linkType={PhoneNumberLinkType.CALL}
        ad={ad}
        ariaLabel={'Phone number button'}
    />
);

export default PhoneNumberButton;
