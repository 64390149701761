import React from 'react';

import PaymentSelectionFreeAdUndertaking from 'horizontal/payment/paymentSelectionFreeAdUndertaking';
import type { FullAd } from 'horizontal/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly ad: FullAd;
};

const PaymentSelectionFreeAdUndertakingDesktop = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
        </>
    ),
    body: ({ ad }: Props) => <PaymentSelectionFreeAdUndertaking ad={ad} />,
} as const;

export default PaymentSelectionFreeAdUndertakingDesktop;
