import { t } from '@lingui/macro';
import React from 'react';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { Trans } from '@lingui/macro';
import { Input, Checkbox } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Button, Flex, LoadingSpinner, Text } from 'strat/components';

import ErrorMessage from '@app/fields/errorMessage';
import { FilterableDropdown } from 'horizontal/components';

import useSellCarFormChoices from './useSellCarFormChoices';
import useFormattedPhoneNumber from './useFormattedPhoneNumber';
import styles from './styles/sellCarForm.cssm';

const TermsConditionsLabel = () => (
    <Text.Large>
        <Trans>By checking, you accept our</Trans>{' '}
        <a href={settings.cashMyCar.termsConditionsLink} className={styles.termsLink}>
            <Trans>Terms & Conditions</Trans>
        </a>
    </Text.Large>
);

const ConditionalFlexWrapper = ({ showFlex, children }: { showFlex: boolean; children: any }) =>
    showFlex ? <Flex className={styles.formInputContainer}>{children}</Flex> : <>{children}</>;

type Props = {
    readonly values: FormikValues;
    readonly errors: FormikErrors<any>;
    readonly touched: FormikTouched<any>;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly isSubmitting: boolean;
    readonly handleSubmit: (arg1?: any) => void;
    readonly sellMyCar: boolean;
    readonly mobileView?: boolean;
};

const SellCarForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    sellMyCar = false,
    mobileView = false,
}: Props) => {
    const i18n = useI18n();
    const { phoneNumberValue, countryPrefix } = useFormattedPhoneNumber(values);
    const {
        makeChoices,
        modelChoices,
        yearChoices,
        isRegisteredChoices,
        governateChoices,
        regionsChoices,
        cityChoices,
    } = useSellCarFormChoices(values);

    return (
        <form onSubmit={handleSubmit}>
            <Flex column className={styles.formInputContainer}>
                <ConditionalFlexWrapper showFlex={!mobileView}>
                    <FilterableDropdown
                        name={'makeName'}
                        placeholder={values['makeName'] ? undefined : t(i18n)`Car Brand`}
                        choices={makeChoices}
                        onChange={(value) => {
                            setFieldValue('makeName', value);
                            setFieldValue('modelName', undefined);
                        }}
                        errorMessage={touched['makeName'] && errors['makeName']}
                        value={values['makeName']}
                    />
                    <FilterableDropdown
                        name={'modelName'}
                        placeholder={values['modelName'] ? undefined : t(i18n)`Car Model`}
                        choices={modelChoices}
                        onChange={(value) => setFieldValue('modelName', value)}
                        errorMessage={touched['modelName'] && errors['modelName']}
                        value={values['modelName']}
                    />
                </ConditionalFlexWrapper>
                <ConditionalFlexWrapper showFlex={!mobileView}>
                    <FilterableDropdown
                        name={'year'}
                        placeholder={values['year'] ? undefined : t(i18n)`Year`}
                        choices={yearChoices}
                        onChange={(value) => setFieldValue('year', value)}
                        errorMessage={touched['year'] && errors['year']}
                        value={values['year']}
                    />
                    <FilterableDropdown
                        name={'isRegistered'}
                        placeholder={
                            values['isRegistered'] != null ? undefined : t(i18n)`Is registered`
                        }
                        choices={isRegisteredChoices}
                        onChange={(value) => setFieldValue('isRegistered', value)}
                        errorMessage={touched['isRegistered'] && errors['isRegistered']}
                        value={values['isRegistered']}
                    />
                </ConditionalFlexWrapper>
                <FilterableDropdown
                    name={'governateName'}
                    placeholder={values['governateName'] ? undefined : t(i18n)`Governate`}
                    choices={governateChoices}
                    onChange={(value) => {
                        setFieldValue('governateName', value);
                        setFieldValue('regionName', undefined);
                        setFieldValue('cityName', undefined);
                    }}
                    errorMessage={touched['governateName'] && errors['governateName']}
                    value={values['governateName']}
                />
                <ConditionalFlexWrapper showFlex={!mobileView}>
                    <FilterableDropdown
                        name={'regionName'}
                        placeholder={values['regionName'] ? undefined : t(i18n)`Region`}
                        choices={regionsChoices}
                        onChange={(value) => {
                            setFieldValue('regionName', value);
                            setFieldValue('cityName', undefined);
                        }}
                        errorMessage={touched['regionName'] && errors['regionName']}
                        value={values['regionName']}
                    />
                    <FilterableDropdown
                        name={'cityName'}
                        placeholder={values['cityName'] ? undefined : t(i18n)`City`}
                        choices={cityChoices}
                        onChange={(value) => setFieldValue('cityName', value)}
                        errorMessage={touched['cityName'] && errors['cityName']}
                        value={values['cityName']}
                    />
                </ConditionalFlexWrapper>
                <Input
                    name={'phoneNumber'}
                    type="tel"
                    autoComplete="tel"
                    unit={countryPrefix}
                    onChange={(value) => setFieldValue('phoneNumber', countryPrefix + value)}
                    errorMessage={touched['phoneNumber'] && errors['phoneNumber']}
                    value={phoneNumberValue}
                    placeholder={phoneNumberValue ? undefined : t(i18n)`Phone Number`}
                />
                <Input
                    name={'userName'}
                    onChange={(value) => setFieldValue('userName', value)}
                    errorMessage={touched['userName'] && errors['userName']}
                    placeholder={values['userName'] ? undefined : t(i18n)`Name`}
                    value={values['userName']}
                />
                {sellMyCar && (
                    <Input
                        name={'askingPrice'}
                        onChange={(value) => setFieldValue('askingPrice', value)}
                        errorMessage={touched['askingPrice'] && errors['askingPrice']}
                        value={values['askingPrice']}
                        placeholder={values['askingPrice'] ? undefined : t(i18n)`Asking Price`}
                    />
                )}
                <Input
                    name={'notes'}
                    multiLine
                    className={styles.noteInput}
                    onChange={(value) => setFieldValue('notes', value)}
                    errorMessage={touched['notes'] && errors['notes']}
                    value={values['notes']}
                    placeholder={values['notes'] ? undefined : t(i18n)`Note`}
                />
                <Checkbox
                    checked={values['acceptedTc']}
                    name={'acceptedTc'}
                    label={<TermsConditionsLabel />}
                    onChange={() => setFieldValue('acceptedTc', !values['acceptedTc'])}
                />
                {touched['acceptedTc'] && errors['acceptedTc'] && (
                    <ErrorMessage>{errors['acceptedTc']}</ErrorMessage>
                )}
                <Button stretch disabled={isSubmitting}>
                    {!isSubmitting && <Trans>Submit your form</Trans>}
                    {isSubmitting && <LoadingSpinner />}
                </Button>
            </Flex>
        </form>
    );
};

export default SellCarForm;
