import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';
import { useSearchPagination } from '@sector-labs/fe-search-redux/state';

import { useAgencyProfile } from 'horizontal/agency/state';
import { decodeExtraFields } from 'horizontal/agency/routing/extraFieldsConverters';
import { TransactionPurpose } from 'horizontal/types';
import { AgencyExtraFields } from 'horizontal/agency/routing/types';

type Params = {
    readonly agencySlug?: string;
    readonly externalID?: string;
    readonly page?: number | null;
    readonly categorySlug?: string;
    readonly extraFields: AgencyExtraFields;
    readonly locationSlug?: string;
    readonly purpose?: Values<typeof TransactionPurpose>;
};
const useProfileParams = (): Params => {
    const { slug } = useAgencyProfile() || {};
    const { externalID, filter, category, ...otherParams } = useSelector(selectRouteParams);
    const [, nextPageNumber] = useSearchPagination();
    const memoizedExtraFields = React.useMemo(() => {
        const decodedExtraFields = { ...decodeExtraFields(filter) };
        return {
            ...decodedExtraFields,
            price: {
                min: null,
                max: null,
                ...(decodedExtraFields.price || {}),
            },
            ft: {
                min: null,
                max: null,
                ...(decodedExtraFields.ft || {}),
            },
        };
    }, [filter]);

    const params = {
        [slug ? 'agencySlug' : 'externalID']: slug || externalID,
        page: nextPageNumber || 1,
        categorySlug: category,
        extraFields: memoizedExtraFields,
        ...otherParams,
    };

    return params;
};

export default useProfileParams;
