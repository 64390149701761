import { TemplateType } from 'strat/templates';

import type { MixedAd } from 'horizontal/types';

import useLiteAdTemplate from '../useLiteAdTemplate';

const useAdPriceLabel = (ad: MixedAd): string | null | undefined =>
    useLiteAdTemplate(TemplateType.PRICE_LABEL, ad);

export default useAdPriceLabel;
