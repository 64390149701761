import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { useSelector } from 'react-redux';
import { selectGeoLocationData } from 'strat/user/selectors';
import { Text } from 'strat/components';

import LocationDropdown from 'horizontal/search/location/locationDropdown';
import { Location } from 'horizontal/types';
import { useLocationFilterValue } from 'horizontal/search/location';

import styles from './styles/locationFilter.cssm';

type Props = {
    value: Location | null;
    onLocationSelected: (selectedLocation: Location | null) => void;
};

const LocationFilter = ({ value, onLocationSelected }: Props) => {
    const i18n = useI18n();
    const { loading, closestLocation } = useSelector(selectGeoLocationData);
    const [selectedCurrentLocation, setSelectedCurrentLocation] = React.useState(false);
    const locationFilterValue = useLocationFilterValue();

    React.useEffect(() => {
        if (selectedCurrentLocation && closestLocation) {
            setSelectedCurrentLocation(false);
            onLocationSelected(closestLocation);
        }
    }, [selectedCurrentLocation, closestLocation, onLocationSelected]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Text.Regular>{t(i18n)`Location`}</Text.Regular>
            </div>
            <LocationDropdown
                loading={loading}
                renderSearchIcon
                onLocationSelected={(location) => {
                    if (location) {
                        onLocationSelected(location);
                        setSelectedCurrentLocation(false);
                    }
                }}
                onCurrentLocationSelected={() => setSelectedCurrentLocation(true)}
                location={value || closestLocation || locationFilterValue}
                shouldRenderTriggerRight={false}
                dropdownContainerClassname={styles.dropdownContainer}
            />
        </div>
    );
};

export default LocationFilter;
