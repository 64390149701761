import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { selectCurrency } from 'strat/i18n/money';
import Currency from 'strat/i18n/money/currency';

import styles from './styles/priceInput.cssm';

type Props = {
    readonly price: number | null | undefined;
    readonly setPrice: (arg1?: number | null | undefined) => void;
    readonly className?: string;
};

const PriceInput = ({ price, setPrice, className }: Props) => {
    const i18n = useI18n();
    const { baseCurrency } = useSelector(selectCurrency);

    return (
        <Flex className={className}>
            <span className={styles.rsLabel}>{Currency.symbol(i18n, baseCurrency)}</span>
            <input
                id="price"
                name="price"
                pattern="(\d+,)*\d*"
                autoComplete="off"
                spellCheck={false}
                className={styles.priceInput}
                onChange={(event) => {
                    const value = parseInt(event.target.value.replace(/,/g, ''), 10) || null;
                    setPrice(value);
                }}
                value={price ? price.toLocaleString() : ''}
            />
        </Flex>
    );
};

export default PriceInput;
