import * as React from 'react';
import classNames from 'classnames';
import { ListEntry } from 'strat/components';

import { CategoryIcon } from 'horizontal/categories';
import type { Category } from 'horizontal/types';
import IconArrowRight from 'horizontal/assets/icons/iconArrowRight.svg';

import styles from './styles/subCategoryList.cssm';

const renderImage = (category: Category) => () => (
    <div className={styles.iconContainer}>
        {category.level === 0 ? (
            <CategoryIcon iconClassName={styles.icon} category={category} />
        ) : null}
    </div>
);

const renderArrow = () => () => <IconArrowRight className={styles.arrowRight} />;

type Props = {
    categories: readonly Category[];
    onClick: (arg1: Category) => void;
    isSelected?: (arg1: Category) => boolean;
    renderLeft?: (arg1: Category) => () => React.ReactElement | null | undefined;
    renderRight?: (arg1: Category) => () => React.ReactElement | null | undefined;
    itemBorderClass?: string;
    borderless?: boolean;
};

const SubcategoryList = ({
    categories,
    onClick,
    isSelected,
    renderLeft = renderImage,
    renderRight = renderArrow,
    itemBorderClass,
    borderless,
}: Props) => {
    if (!categories) {
        return null;
    }

    return (
        <>
            {categories.map((category) => (
                <div
                    key={category.id}
                    className={classNames(styles.category, {
                        [styles.selected]: isSelected && isSelected(category),
                    })}
                    aria-label={`Category level ${category.level}`}
                >
                    <ListEntry
                        renderLeft={renderLeft?.(category)}
                        renderRight={
                            category.children?.length > 0 && renderRight
                                ? renderRight(category)
                                : null
                        }
                        onClick={() => onClick(category)}
                        borderClass={itemBorderClass}
                        hoverable
                        borderless={borderless}
                    >
                        {category.nameShort || category.name}
                    </ListEntry>
                </div>
            ))}
        </>
    );
};

export default SubcategoryList;
