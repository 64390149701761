import * as React from 'react';

export type DialogComponentProps<T = any> = {
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
} & DialogProps<T>;

export type DialogProps<T = any> = T;

export enum Actions {
    SET_VISIBLE = 'SET_VISIBLE',
    SET_DEFAULT_VISIBLE_ALERT = 'SET_DEFAULT_VISIBLE_ALERT',
    CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS',
}

type SetVisibleAction<TProps = DialogProps> = {
    readonly type: Actions.SET_VISIBLE;
    readonly visible: boolean;
    readonly name: string;
    readonly props: TProps;
};

type SetDefaultVisibleAlert = {
    readonly type: Actions.SET_DEFAULT_VISIBLE_ALERT;
    readonly name: string;
};

type CloseAllDialogs = {
    readonly type: Actions.CLOSE_ALL_DIALOGS;
};

export type Action = SetVisibleAction | SetDefaultVisibleAlert | CloseAllDialogs;

export type DialogState = {
    readonly visible: boolean;
    readonly props: DialogProps;
};

export type State = Record<string, DialogState> & {
    readonly defaultVisibleAlert?: string;
};

export type DialogsCollection = Readonly<Record<string, React.ComponentType<any>>>;
