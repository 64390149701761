//@ts-nocheck
import Loadable, { LoadingSpinner } from 'strat/loadable';

/*
 * Loads the alerts manager async
 */
export default Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'alertsManager' */
            './alertsManagerContents'
        ),
    loading: LoadingSpinner,
});
