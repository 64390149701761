import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import styles from './styles/headerLinks.cssm';

type Props = {
    readonly name: string;
    readonly Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    readonly isHighlighted: boolean;
    readonly alt: string;
};

const HeaderLink = ({ name, Icon, isHighlighted, alt }: Props) => {
    return (
        <Flex
            alignCenter
            className={classNames(styles.headerLinkContainer, {
                [styles.highlightedHeaderLinkContainer]: isHighlighted,
            })}
        >
            <Flex alignCenter justifyCenter className={styles.iconWrapper}>
                {/* @ts-expect-error - TS2322 - Type '{ className: any; alt: string; }' is not assignable to type 'IntrinsicAttributes & SVGAttributes<SVGElement> & { children?: ReactNode; }'. */}
                <Icon className={styles.icon} alt={alt} />
            </Flex>
            <Text.Large bold className={styles.verticalLogoText}>
                {name}
            </Text.Large>
        </Flex>
    );
};

export default HeaderLink;
