import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { isCanceled, makeCancelable } from 'strat/util';
import { RouteNames } from 'strat/routes';
import { AdPhotoDTO } from '@app/types';

import { drainLastUpsertedAdFromLocalStorage } from 'horizontal/app';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { fetchAd } from 'horizontal/ad/state';
import { ProductPurchaseStatus } from 'horizontal/packages';
import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type AdUndertakingTermsRouteParams = {
    readonly externalID: string;
};

class AdUndertakingTermsRoute extends Route {
    constructor() {
        super(RouteNames.AD_UNDERTAKING_TERMS, [
            [
                '^/payments/packages/free/terms/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(params: AdUndertakingTermsRouteParams): EnhancedLocation {
        return {
            pathname: `/payments/packages/free/terms/${params.externalID}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { externalID },
            },
            redux: {
                store: { getState, dispatch },
            },
        } = context;

        if (!externalID) {
            // @ts-expect-error - TS2339 - Property 'renderNotFoundPage' does not exist on type 'AdUndertakingTermsRoute'.
            this.renderNotFoundPage(context);
            return;
        }
        const getAdData = () => getState().ad.data;

        const lastUpsertedAd = drainLastUpsertedAdFromLocalStorage();

        const adPromise = Promise.all([
            dispatch(fetchAd({ externalID })),
            dispatch(fetchProductPurchases({ status: ProductPurchaseStatus.ACTIVE })),
        ]);
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AdUndertakingTermsRoute'.
        this.cancelablePromise = makeCancelable(adPromise);
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AdUndertakingTermsRoute'.
        const promise = this.cancelablePromise.then(
            () => {
                const ad = getAdData();
                let photos: Array<AdPhotoDTO> | Array<never> = [];
                if (ad.photos.length === 0 && lastUpsertedAd) {
                    if (ad.externalID === lastUpsertedAd.externalID) {
                        photos = lastUpsertedAd.photos;
                    } else {
                        // race condition if the user does this process in 2 tabs
                        context.rendering.renderPage(Page.INTERNAL_ERROR);
                    }
                }
                context.rendering.renderPage(Page.AD_UNDERTAKING_TERMS, {
                    ad: { ...ad, photos },
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }
                throw error;
            },
        );
        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AdUndertakingTermsRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AdUndertakingTermsRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new AdUndertakingTermsRoute();
