import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';
import { buildCanonicalURL } from 'strat/routing';
import { createTrackableAdURL } from 'strat/property/url';

const getPhoneMessageContent = (
    externalID: string,
    traceID: string,
    i18n: I18n,
    language: string,
): string => {
    const adURL = buildCanonicalURL(createTrackableAdURL({ externalID, traceID }), language);
    return t(i18n)`Hi, I am interested in ${adURL}`;
};

export default getPhoneMessageContent;
