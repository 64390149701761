import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';

import generateUniqueEventID from './generateUniqueEventID';
import withGTM from './withGTM';
import type { GTMProps } from './withGTM';
import Triggers from './triggers';

export type GTMPropertyNavigationTrackingProps = GTMProps & {
    trackPropertyNavigation: (detailPageSection: string) => void;
};

const withGTMPropertyNavigationTracking = <T extends any>(
    component: Flow.AbstractComponent<any>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'GTMPropertyNavigationTrackingProps'.
): Flow.AbstractComponent<Flow.Diff<T, GTMPropertyNavigationTrackingProps>> => {
    // @ts-expect-error - TS2339 - Property 'trigger' does not exist on type 'unknown'. | TS2700 - Rest types may only be created from object types.
    const GTMPropertyNavigationTracking = ({ trigger, ...props }: T) =>
        React.createElement(component, {
            ...props,
            trackPropertyNavigation: (detailPageSection: string) =>
                trigger(Triggers.PROPERTY_NAVIGATION, {
                    page_section: 'header',
                    name: 'text_clicked',
                    value: detailPageSection,
                    event_unique_id: generateUniqueEventID(),
                }),
        });

    // @ts-expect-error - TS2322 - Type 'AbstractComponent<Diff<T, GTMProps>, any>' is not assignable to type 'AbstractComponent<Diff<T, GTMPropertyNavigationTrackingProps>, any>'.
    return withGTM(GTMPropertyNavigationTracking);
};

export default withGTMPropertyNavigationTracking;
