import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';

import SitemapList from './sitemapList';
import styles from './styles/sitemapProvinces.cssm';
import { renderTitle, renderListItems } from './renderList';
import { selectSitemapCategories } from './state/selectors';

const SitemapProvinces = () => {
    const provinces = useSelector(selectSitemapCategories);

    if (!provinces || provinces.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div>
                <Text.XXLarge bold>
                    <Trans>Provinces</Trans>
                </Text.XXLarge>
            </div>
            <Flex className={styles.listItemsContainer}>
                {/* @ts-expect-error - TS7006 - Parameter 'province' implicitly has an 'any' type. */}
                {provinces.map((province) => (
                    <SitemapList
                        renderTitle={() => renderTitle(province)}
                        renderListItems={() => renderListItems(province)}
                        containerClass={styles.listContainer}
                        key={`${province.externalID}`}
                    />
                ))}
            </Flex>
        </div>
    );
};

export default SitemapProvinces;
