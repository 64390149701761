import * as React from 'react';
import { Flex } from 'strat/components';

import styles from './styles/savedSearchesCardFiltersRow.cssm';

const SavedSearchesCardFiltersRow = ({ label, value }: { label: React.ReactNode; value: any }) => (
    <Flex justifySpaceBetween className={styles.description}>
        {label}
        <div className={styles.value}>{value}</div>
    </Flex>
);

export default SavedSearchesCardFiltersRow;
