import { t } from '@lingui/macro';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import type { I18n } from '@lingui/core';

import { useI18n } from 'strat/i18n/language';
import { addSilentAlert } from 'strat/alerts/state/creators';
import { ViewSections, withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm';
import PropertyNotAvailablePopup from 'strat/property/propertyNotAvailablePopup';
import { OffScreen } from 'strat/modal';
import { InternalAPI } from 'strat/api';
import { stopEventPropagation } from 'strat/util';
import type { AppDispatch } from 'strat/state';

import { EMAIL_PLACEHOLDER, PHONE_PLACEHOLDER, PHONE_PLACEHOLDER_NUMBER } from './constants';
import { FIELD_IDS } from './contactFormConstants';
import ContactFormSlideIn from './contactFormSlideInAsync';
import ContactFormDialog from './contactFormDialogAsync';
import ContactDetailsDialog from './contactDetailsDialog';
import styles from './styles/descriptionText.cssm';

/**
 * Props for {@see ContactFormSlideIn}.
 */
type ContactFormSlideInProps = React.ComponentProps<typeof ContactFormSlideIn>;

/**
 * Props for {@see ContactFormDialog}.
 */
type ContactFormDialogProps = React.ComponentProps<typeof ContactFormDialog>;

/**
 * Properties for {@see DescriptionText}.
 */
export type Props = GTMLeadTrackingProps &
    ContactFormSlideInProps &
    ContactFormDialogProps & {
        children: string;
        withEmailSlideIn: boolean;
        withEmailDialog: boolean;
    };

/**
 * Escapes a string for use in a regular expression.
 */

const escape = (text: string): string => {
    return text.replace('[', '\\[').replace(']', '\\]');
};

/**
 * Takes all registered placeholders and creates a regular
 * expression to split the description with.
 *
 * Each placeholder is generated as a look-ahead regular
 * expression so that they are kept as part of the
 * splitted description.
 */
const expression = (): RegExp => {
    const patterns = [PHONE_PLACEHOLDER, EMAIL_PLACEHOLDER]
        .map((placeholder) => escape(placeholder))
        .map((placeholder) => `(?=${placeholder})`)
        .join('|');

    return new RegExp(`(${patterns})`);
};

/**
 * Bring focus to the e-mail input element on the page
 */
const focusEmailInput = () => {
    const element = document.getElementsByClassName(FIELD_IDS.name)[1];
    if (element) {
        // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
        element.focus();
    }
};

/**
 * Creates a link to be rendered in the phone number placeholder.
 */
const callLink = (i18n: I18n): string => {
    return `<button class="${styles.link} phoneCTA">${t(i18n)`View Contact Detail`}</button>`;
};

/**
 * Creates a link to be rendered in the email placeholder.
 */
const emailLink = (i18n: I18n): string => {
    return `<button class="${styles.link} emailCTA">${t(i18n)`Send e-mail`}</button>`;
};

/**
 * Renders a description text with placeholders in it
 * for phone numbers and e-mail addresses.
 *
 * The placeholders are replaced with links. Phone number
 * placeholders get a link that opens a dialog with
 * the phone number in it and the e-mail addresses
 * get replaced with a link that brings the contact
 * form into focus.
 */
const DescriptionText = (props: Props): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const i18n = useI18n();
    const descriptionRef = React.useRef<HTMLSpanElement>(null);
    const {
        children,
        property,
        withEmailSlideIn = true,
        withEmailDialog = false,
        trackEmailView,
        trackCallView,
        trackCallLead,
        trackEmailLead,
    } = props;
    const [state, setState] = React.useState({
        hasCustomNumber: false,
        phoneNumber: null,
        contactDialog: false,
        emailSlideIn: false,
        emailDialog: false,
        showPropertyNotAvailable: false,
    });

    const description = (): string => {
        // we have to remove paragraphs because we might
        // have break it into multiple spans and insert
        // a link in-between.. this is due to react..
        // if we could avoid wrapping everything in <span>
        // then this wouldn't be needed
        return children.replace(/<p>/g, '<br>').replace(/<\/p>/g, '<br>');
    };

    /**
     * Opens a popup dialog alerting that the property is no longer available.
     */
    const openPropertyNotAvailable = () => {
        setState({ ...state, showPropertyNotAvailable: true });
    };

    /**
     * Opens the contact details dialog.
     */
    const openContactDialog = (phoneNumber?: string | null) => {
        setState({
            ...state,
            hasCustomNumber: !!phoneNumber,
            // @ts-expect-error - TS2322
            phoneNumber: {
                mobileNumbers: [],
                phoneNumbers: phoneNumber ? [phoneNumber] : [],
                proxyMobile: null,
                proxyPhone: null,
                whatsapp: null,
            },
            contactDialog: true,
        });
    };

    /**
     * Opens the e-mail slideIn.
     */
    const openEmailSlideIn = () => {
        setState({ ...state, emailSlideIn: true });
    };

    /**
     *  Open the e-mail dialog.
     */
    const openEmailDialog = () => {
        setState({ ...state, emailDialog: true });
    };

    const getPhoneNumbers = () => {
        return description()
            .split(expression())
            .filter((text) => text.startsWith(PHONE_PLACEHOLDER))
            .map((text) => {
                const matches = text.match(PHONE_PLACEHOLDER_NUMBER);
                return matches && matches[2];
            });
    };

    /**
     * Renders the description with the phone number and e-mail
     * address placeholders replaced with links.
     */
    const renderDescription = (): string => {
        return description()
            .split(expression())
            .map((text) => {
                if (text.startsWith(PHONE_PLACEHOLDER)) {
                    return `${callLink(i18n)}${text.replace(PHONE_PLACEHOLDER_NUMBER, '')}`;
                } else if (text.startsWith(EMAIL_PLACEHOLDER)) {
                    return `${emailLink(i18n)}${text.replace(EMAIL_PLACEHOLDER, '')}`;
                } else {
                    return text;
                }
            })
            .join('');
    };

    React.useEffect(() => {
        const onEmailClick = (event: MouseEvent | TouchEvent) => {
            if (property.active) {
                if (withEmailSlideIn) {
                    openEmailSlideIn();
                } else if (withEmailDialog) {
                    openEmailDialog();
                } else {
                    focusEmailInput();
                }
            } else {
                openPropertyNotAvailable();
            }
            trackEmailView(ViewSections.PROPERTY_DETAIL);
            stopEventPropagation(event);
        };
        const phoneCTAs = descriptionRef.current?.getElementsByClassName('phoneCTA') ?? [];
        const emailCTAs = descriptionRef.current?.getElementsByClassName('emailCTA') ?? [];
        const phoneNumbers = getPhoneNumbers();
        for (let i = 0; i < phoneCTAs.length; i++) {
            const onPhoneClick = (event: MouseEvent | TouchEvent) => {
                if (property.active) {
                    openContactDialog(phoneNumbers[i]);
                } else {
                    openPropertyNotAvailable();
                }
                trackCallView(ViewSections.PROPERTY_DETAIL);
                stopEventPropagation(event);
            };
            const phoneCTA = phoneCTAs[i] as HTMLElement;
            phoneCTA.onclick = onPhoneClick;
        }
        for (let i = 0; i < emailCTAs.length; i++) {
            const emailCTA = emailCTAs[i] as HTMLElement;
            emailCTA.onclick = onEmailClick;
        }
    });

    const elements = [
        <span
            key="description"
            ref={descriptionRef}
            className={styles.text}
            dangerouslySetInnerHTML={{
                __html: renderDescription(),
            }}
        />,
        <PropertyNotAvailablePopup
            key="propertyNotAvailablePopup"
            visible={state.showPropertyNotAvailable}
            onVisibilityChanged={(showPropertyNotAvailable) =>
                setState({ ...state, showPropertyNotAvailable })
            }
        />,
        <ContactDetailsDialog
            key="contactDialog"
            phoneNumberData={state.hasCustomNumber ? state.phoneNumber : property.phoneNumber}
            agencyName={(property.agency || {}).name}
            contactName={property.contactName}
            externalID={property.externalID}
            referenceNumber={property.referenceNumber}
            visible={state.contactDialog}
            onVisibilityChanged={(contactDialog) => setState({ ...state, contactDialog })}
            onPhoneNumberClick={() => trackCallLead(ViewSections.PROPERTY_DETAIL)}
            name={'description-text'}
        />,
    ];

    const formProps = {
        agency: property.agency,
        contactName: property.contactName,
        referenceNumber: property.referenceNumber,
        phoneNumber: property.primaryPhoneNumber,
        onCallClick: () => trackCallView(ViewSections.EMAIL_POPUP),
        onPhoneNumberClick: () => trackCallLead(ViewSections.EMAIL_POPUP),
        // @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
        onSendEmailClick: (...args) => {
            trackEmailLead(ViewSections.EMAIL_POPUP, ...args);
            if (CONFIG.build.ENABLE_STRAT_EMAILS) {
                // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                new InternalAPI().sendEmail(property.externalID, ...args);
            }
            if (CONFIG.build.SHOW_AGENT_REVIEWS) {
                new InternalAPI().scheduleReviewRequestReminder(
                    property.externalID,
                    // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                    ...args,
                );
            }
        },
    } as const;

    if (withEmailSlideIn) {
        elements.push(
            <OffScreen key="emailSlideIn-offscreen" name="email-slide-in">
                <ContactFormSlideIn
                    {...formProps}
                    key="emailSlideIn"
                    visible={state.emailSlideIn}
                    // @ts-expect-error - TS7006 - Parameter 'emailSlideIn' implicitly has an 'any' type.
                    onVisibilityChanged={(emailSlideIn) => setState({ ...state, emailSlideIn })}
                    slideInClassName={styles.slideIn}
                    // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
                    onKeepMeInformed={(values) => {
                        dispatch(addSilentAlert(values, property));
                    }}
                />
            </OffScreen>,
        );
    } else if (withEmailDialog) {
        elements.push(
            <OffScreen key="emailDialog-offscreen" name="email-dialog">
                <ContactFormDialog
                    {...formProps}
                    key="emailDialog"
                    visible={state.emailDialog}
                    // @ts-expect-error - TS7006 - Parameter 'emailDialog' implicitly has an 'any' type.
                    onVisibilityChanged={(emailDialog) => setState({ ...state, emailDialog })}
                    renderAgencyInfo={() => null}
                    // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
                    onKeepMeInformed={(values) => {
                        dispatch(addSilentAlert(values, property));
                    }}
                />
            </OffScreen>,
        );
    }

    // @ts-expect-error - TS2322 - Type '(Element | Node)[]' is not assignable to type 'Node[]'.
    return elements;
};

export default withGTMLeadTracking(DescriptionText);
