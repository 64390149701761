import * as React from 'react';
import omit from 'lodash/omit';

import { ScheduleScope, TaskScheduler } from 'strat/scheduler';

import useRouter from './useRouter';
import type { RouteParameters } from './route';
import { serializeLocation } from './location';

type Props = {
    /**
     * Name of a configured route to link to.
     */
    readonly route: string;
    /**
     * Indicates whether the link should be click-able.
     */
    readonly enabled?: boolean;
    /**
     * Indicates whether the link should be rendered as <a> or <div>.
     */
    readonly soft?: boolean;
    /**
     * Indicates whether the page should be harded
     * from the back-end when clicking the link.
     */
    readonly hard?: boolean;
    /**
     * Indiciates whether the window should be scrolled
     * up to the top when this link is clicked.
     */
    readonly scrollUp?: boolean;
    /**
     * Parameters to pass to the route.
     */
    readonly params?: RouteParameters;
    /**
     * Parameters to actually use when the user clicks on the link.
     *
     * This is useful if you want the link to look like it will go to a certain
     * route, but it will actually go to another one. Note: this only works
     * if JS is enabled.
     */
    readonly onClickParams?: RouteParameters;
    /**
     * Elements to render as children of <a>.
     */
    readonly children?: React.ReactNode;
    /**
     * If true, `rel='noopenner'` and `target='_blank'` is added
     * to the anchor tag.
     */
    readonly openInNewTab?: boolean;
    /**
     * Do or do not invoke the router.
     * If false, make the necessary actions to reach the url in the onClick handler.
     * Defaults to true.
     */
    readonly invoke?: boolean;
    /**
     * Called when this link is clicked.
     *
     * This is called _before_ the actual routing happens.
     */
    readonly onClick?: (arg1: React.MouseEvent<any>) => void;
    // Props forwarded to the underlying <a>
    readonly title?: string;
    readonly className?: string;
    ['aria-label']?: string;
    readonly encodePathname?: boolean;
    setLinkRef?: (arg1?: HTMLElement | null | undefined) => void;
    readonly role?: string;
};

const Link = (props: Props) => {
    const {
        route,
        enabled = true,
        soft = true,
        hard = false,
        scrollUp = false,
        params,
        onClickParams,
        children,
        openInNewTab,
        onClick,
        encodePathname = false,
        setLinkRef,
        invoke = true,
        ...restProps
    } = props;

    const router = useRouter();

    const url = serializeLocation(router.getRouteURL(route, params), {
        encodePathname,
    });
    const isEnabled = enabled !== null && enabled !== undefined ? enabled : true;

    const onLinkClick = React.useCallback(
        (event: React.MouseEvent<any>): boolean => {
            if (onClick) {
                onClick(event);
            }

            // Detect if the user is trying to open the page in a new tab
            const isBeingOpenedInNewTab = isEnabled && event && (event.ctrlKey || event.metaKey);

            if (hard || openInNewTab || isBeingOpenedInNewTab) {
                return true;
            }

            if (isEnabled) {
                if (scrollUp && window.scrollTo) {
                    TaskScheduler.schedule(
                        () => window.scrollTo(0, 0),
                        ScheduleScope.AFTER_CHANGE_ROUTE,
                    );
                }

                router.pushRoute(route, onClickParams || params, invoke);
            }

            event.preventDefault();
            return false;
        },
        [
            onClick,
            isEnabled,
            hard,
            openInNewTab,
            router,
            route,
            params,
            onClickParams,
            scrollUp,
            invoke,
        ],
    );

    const forwardedProps = omit(restProps, ['to', 'href']);

    if (!isEnabled || !soft) {
        return (
            <div ref={setLinkRef} {...forwardedProps}>
                {children}
            </div>
        );
    }

    if (openInNewTab) {
        // @ts-expect-error - TS2339 - Property 'rel' does not exist on type 'Partial<{ title?: string | undefined; className?: string | undefined; "aria-label"?: string | undefined; }>'.
        forwardedProps.rel = 'noopener';
        // @ts-expect-error - TS2339 - Property 'target' does not exist on type 'Partial<{ title?: string | undefined; className?: string | undefined; "aria-label"?: string | undefined; }>'.
        forwardedProps.target = '_blank';
    }

    return (
        <a ref={setLinkRef} href={url} onClick={onLinkClick} {...forwardedProps}>
            {children}
        </a>
    );
};

export default Link;
