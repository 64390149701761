import type { Dispatch } from 'redux';
import { FilterCollection, ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import { updateSearchHistory } from 'strat/user/state';
import FilterValues from 'strat/search/filterValues';

import type { Location } from 'horizontal/types';

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const addNewRecentLocation = (location: Location, dispatch: Dispatch) => {
    const filters = new FilterCollection();
    filters.refine(
        new ObjectExactFilter({
            attribute: FilterValues.location.attribute,
            value: location,
            operator: Operator.OR,
        }),
    );
    dispatch(updateSearchHistory(filters));
};

export default addNewRecentLocation;
