import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/toolTip.cssm';

const ToolTipPosition = Object.freeze({
    ABOVE: styles.above,
    LEFT: styles.left,
    RIGHT: styles.right,
    BELOW: styles.below,
});

/**
 * Properties for {@see ToolTip}.
 */
type Props = {
    text?: string;
    onCloseClick?: (event: React.SyntheticEvent<any>) => void;
    position?: Values<typeof ToolTipPosition>;
    className?: string;
    closeable?: boolean;
    children?: React.ReactNode;
};

const ToolTip = ({
    text,
    onCloseClick,
    position = ToolTipPosition.LEFT,
    className,
    closeable = true,
    children,
}: Props) => {
    const content = (
        <div
            className={classNames(styles.container, position, styles.width, className, {
                [styles.hidden]: !text && !children,
            })}
        >
            <div className={styles.content} aria-label="Error message">
                <span>{text}</span>
                {children}
                {closeable && (
                    <button className={styles.closeButton} onClick={onCloseClick}>
                        x
                    </button>
                )}
            </div>
            <span className={classNames(styles.anchor, position)} />
        </div>
    );

    switch (position) {
        case ToolTipPosition.ABOVE:
            return <div className={styles.alignmentHelper}>{content}</div>;
        case ToolTipPosition.BELOW:
            return (
                <div className={classNames(styles.alignmentHelper, styles.alignmentHelperBelow)}>
                    {content}
                </div>
            );
        default:
            return content;
    }
};

export default ToolTip;
export { ToolTipPosition };
