import FetcherFactory from 'strat/fetcher';
import settings from '@app/branding/settings';
import { selectUserExternalID } from 'strat/user/session/selectors';
import type { GlobalState } from 'strat/state';

import { StratAPI } from 'horizontal/api';

const factory = new FetcherFactory(
    'walletBalance',
    (_: any, state: GlobalState) => {
        if (settings.disableWallet) {
            return Promise.resolve({ status: 200, data: null });
        }

        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Cannot fetch wallet balance due to missing user ID.');
        }

        return new StratAPI(state.i18n.language).walletBalance(userExternalID);
    },
    { skipParamsCheck: true },
);

const fetchWalletBalance = factory.creator();
const walletBalanceReducer = factory.reducer();

export { fetchWalletBalance };

export default walletBalanceReducer;
