import Purpose from 'strat/purpose';
import type { Primitive } from 'strat/types';
import { ExpressSessionData } from 'strat/server/expressSession';
import type { UserProfileData } from 'strat/user/types';

export type APIResponse<T> = {
    readonly data: T;
    readonly status: number;
    readonly headers?: Headers;
};

export const AdType = Object.freeze({
    PROPERTY: 'property',
    PROJECT: 'project',
    GENERAL: 'general',
});

type Photo = {
    readonly url: string;
    readonly is_main?: boolean;
};

export type UpsertAdPayload = {
    type: Values<typeof AdType>;
    user_external_id: string;
    source: string;
    state: string;
    purpose?: Values<typeof Purpose>;
    price: number;
    title: string;
    description: string;
    photos: Array<Photo>;
    extra_fields?: {
        [key: string]: Primitive;
    };
    area: number;
    category_id: string;
    location_id: string;
    contact_info: {
        name: string;
        mobile: string;
        roles: Array<string>;
    };
    external_id?: string | null | undefined;
    updated_at?: number | null | undefined;
    delivery_information?: {
        [key: string]: Primitive;
    };
};

export type ApplyProductPayload = {
    productPurchaseID: string;
};

export type CashMyCarFormPayload = {
    readonly type: string;
    readonly makeName: string;
    readonly modelName: string;
    readonly year: string;
    readonly isRegistered: string;
    readonly regionName: string;
    readonly governateName: string;
    readonly cityName: string;
    readonly phoneNumber: string;
    readonly userName: string;
    readonly askingPrice: number | null;
    readonly notes: string | null;
};

export type LoginResponseData = Omit<ExpressSessionData, 'profile'> & {
    profile: Omit<UserProfileData, 'id' | 'phoneNumber'> & {
        id: number;
        phoneNumber: { phone: string | null | undefined; mobile: string | null | undefined };
    };
};

export type LoginResponse = APIResponse<LoginResponseData>;

export type AlreadyRegisteredResponse = {
    readonly status: number;
    readonly data: null;
};

export type ImplicitRegisterCreateResponseData = {
    registration: {
        externalID: string;
        email: string | null;
        isNewlyRegistered: true;
    };
    session: ExpressSessionData;
};

export type ImplicitRegisterExistsResponseData = {
    registration: {
        externalID: string;
        email: string | null;
        isNewlyRegistered: false;
    };
    session: null;
};

export type ImplicitRegisterResponse =
    | {
          readonly status: 200;
          readonly data: ImplicitRegisterCreateResponseData;
      }
    | {
          readonly status: 409;
          readonly data: ImplicitRegisterExistsResponseData;
      };

export type PaginatedAPIResponse<T> = APIResponse<{
    readonly count: number;
    readonly next: string | null;
    readonly previous: string | null;
    readonly results: T[];
}>;

export enum FrontEndEnvironment {
    DEV = 'dev',
    STAGE = 'stage',
    PROD = 'prod',
}

export enum PlatformName {
    MOBILE_WEB = 'web-mobile',
    DESKTOP = 'web-desktop',
    APP_ANDORID = 'app-android',
    APP_IOS = 'app-ios',
}
