import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import Yup from 'yup';
import type { I18n } from '@lingui/core';

import { isValidPhoneNumber } from 'strat/i18n/phone';

export const PasswordStrength = {
    WEAK: 'weak',
    STRONG: 'strong',
    VERY_STRONG: 'veryStrong',
} as const;

export const PasswordStrengthMinLength = {
    WEAK: 8,
    STRONG: 11,
    VERY_STRONG: 15,
} as const;

export const hasMinimumPasswordLength = (password = '') =>
    password.length >= PasswordStrengthMinLength.WEAK;

export const containsLetter = (password = '') => /[A-z]/.test(password);

export const containsSpecialCharacter = (password = '') => {
    const specialCharacterRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

    return specialCharacterRegex.test(password);
};

export const containsNumber = (password = '') => /[0-9]/.test(password);

export const containsOnlyAlphanumericOrSpecialCharacters = (password = '') => {
    const alphanumericOrSpecialCharacterRegex = /^[a-zA-Z0-9`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]*$/;

    return alphanumericOrSpecialCharacterRegex.test(password);
};

export const containsOnlyAlphanumericOrSpecialCharactersErrorMessage = (i18n: I18n) =>
    t(i18n)`The password can only contain alphanumeric or special characters`;

const nameValidation = (i18n: I18n) =>
    Yup.string()
        .trim()
        .min(3, t(i18n)`The name should be at least 3 characters long`)
        .max(30, t(i18n)`The name should not be longer than 30 characters.`)
        .containsNoPhone()
        .containsNoSpecialCharacters();

/**
 * Schemas for commonly used fields using {@see Yup}.
 */
export const ValidationSchema = (i18n: I18n) => {
    // @ts-expect-error - TS7006 - Parameter 'message' implicitly has an 'any' type.
    Yup.addMethod(Yup.string, 'phone', function method(message) {
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        return this.test(
            'phone',
            message || t(i18n)`The phone number must be valid.`,
            isValidPhoneNumber,
        );
    });

    // @ts-expect-error - TS7006 - Parameter 'ref' implicitly has an 'any' type. | TS7006 - Parameter 'message' implicitly has an 'any' type.
    Yup.addMethod(Yup.mixed, 'sameAs', function method(ref, message) {
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation. | TS7006 - Parameter 'value' implicitly has an 'any' type.
        return this.test('sameAs', message, function sameAs(value) {
            // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
            const other = this.resolve(ref);
            return !other || !value || value === other;
        });
    });

    Yup.addMethod(Yup.mixed, 'containsNoPhone', function method() {
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        return this.matches(
            /^(?![^]*((\d|[⁰¹²³⁴⁵⁶⁷⁸⁹₀₁₂₃₄₅₆₇₈₉]){1}[^]*){8})[^]*/,
            t(i18n)`Phone numbers are not allowed.`,
        );
    });

    Yup.addMethod(Yup.mixed, 'containsNoSpecialCharacters', function method() {
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        return this.matches(
            // eslint-disable-next-line
            /^(?![^]*[\u2300-\u{1FFFF}\u20E3])[^]*/u,
            t(i18n)`Special characters are not allowed.`,
        );
    });

    return {
        email: Yup.string()
            .trim()
            .email(t(i18n)`The e-mail address must be in the format of name@domain.com`)
            .required(t(i18n)`Please enter your e-mail address.`),

        // Used for the login form, having a more relaxed validation
        password: Yup.string()
            .required(t(i18n)`Password is required.`)
            .min(3, t(i18n)`The password should be at least 3 characters long.`),

        // Used for the register and reset password forms, having a stricter validation
        newPassword: Yup.string()
            .required(t(i18n)`Password is required.`)
            .test(
                'Alphanumeric or symbol',
                containsOnlyAlphanumericOrSpecialCharactersErrorMessage(i18n),
                containsOnlyAlphanumericOrSpecialCharacters,
            )
            .test(
                'Minimum length',
                t(
                    i18n,
                )`The password should be at least ${PasswordStrengthMinLength.WEAK} characters long`,
                hasMinimumPasswordLength,
            )
            .test(
                'Contains letter',
                t(i18n)`The password should contain at least a letter`,
                containsLetter,
            )
            .test(
                'Contains number',
                t(i18n)`The password should contain at least a number`,
                containsNumber,
            )
            .test(
                'Contains special character',
                t(i18n)`The password should contain at least a special character`,
                containsSpecialCharacter,
            ),

        repeatPassword: Yup.string()
            .required(t(i18n)`Confirm Password is required.`)
            .sameAs(Yup.ref('password'), t(i18n)`Password should match the above password`),

        name: nameValidation(i18n).required(t(i18n)`Name is required.`),

        optionalName: nameValidation(i18n),

        description: Yup.string().containsNoPhone().containsNoSpecialCharacters(),

        phoneNumber: Yup.string()
            .trim()
            .required(t(i18n)`Phone number is required.`)
            .min(7, t(i18n)`The phone number should be at least 7 characters long.`)
            .phone(t(i18n)`The phone number must be a valid international phone number.`),

        verificationCode: Yup.string().matches(
            /^\d{6}$/,
            t(i18n)`Verification code should have 6 digits`,
        ),

        termsAgreed: Yup.boolean().oneOf([true], t(i18n)`Please agree to our terms and conditions`),
    };
};
