import React from 'react';
import { Trans } from '@lingui/macro';

import CreditCardsContainer from './creditCardsContainer';
import PaymentTitle from './paymentTitle';
import type { PaymentMethodDetails, PaymentMethodSelectedType, CheckoutCreditCard } from './types';
import { PaymentSource, PaymentMethodRoles } from './types';
import PaymentMethod from './paymentMethod';
import styles from './styles/paymentMethodsContainer.cssm';

export type PaymentMethodsContainerProps = {
    paymentMethods: Array<PaymentMethodDetails>;
    onPaymentMethodSelected: ({ source, card }: PaymentMethodSelectedType) => void;
    creditCards: Array<CheckoutCreditCard>;
    partialPayment?: boolean;
    isPartialPaymentOrder?: boolean;
};

const PaymentMethodsContainer = ({
    paymentMethods,
    onPaymentMethodSelected,
    creditCards,
    partialPayment,
    isPartialPaymentOrder,
}: PaymentMethodsContainerProps) => {
    const checkoutPaymentMethod = paymentMethods.find(
        (paymentMethod: PaymentMethodDetails) => paymentMethod.source === PaymentSource.CHECKOUT,
    );

    return (
        <div aria-label="Payment" className={styles.paymentMethodsContainer}>
            {checkoutPaymentMethod && (
                <div className={styles.checkoutPaymentMethodContainer}>
                    <PaymentTitle>
                        <Trans>Pay by Credit / Debit Card</Trans>
                    </PaymentTitle>
                    <div className={styles.checkoutPaymentMethodContentWrapper}>
                        <div className={styles.checkoutPaymentMethod}>
                            {creditCards.length ? (
                                <CreditCardsContainer
                                    onSavedCardPaymentSelected={(card: CheckoutCreditCard) =>
                                        onPaymentMethodSelected({
                                            source: checkoutPaymentMethod.source,
                                            card,
                                            name: checkoutPaymentMethod.name,
                                        })
                                    }
                                    onAddNewCard={() =>
                                        onPaymentMethodSelected({
                                            source: checkoutPaymentMethod.source,
                                            name: checkoutPaymentMethod.name,
                                        })
                                    }
                                    cards={creditCards}
                                />
                            ) : (
                                <PaymentMethod
                                    paymentMethod={checkoutPaymentMethod}
                                    onClick={() =>
                                        onPaymentMethodSelected({
                                            source: checkoutPaymentMethod.source,
                                            name: checkoutPaymentMethod.name,
                                        })
                                    }
                                />
                            )}
                        </div>
                        <div className={styles.dividerContainer}>
                            <div className={styles.divider} />
                        </div>
                    </div>
                </div>
            )}

            <div
                className={
                    checkoutPaymentMethod
                        ? styles.paymentListWithCheckout
                        : styles.paymentListNormal
                }
            >
                {checkoutPaymentMethod && paymentMethods.length > 1 && (
                    <PaymentTitle>
                        <Trans>Pay using other options</Trans>
                    </PaymentTitle>
                )}
                <ul className={styles.paymentListWrapper}>
                    {paymentMethods.map((paymentMethod: PaymentMethodDetails) => {
                        if (paymentMethod.source === PaymentSource.CHECKOUT) {
                            return null;
                        }

                        const allowPartialPayment = paymentMethod.roles?.includes(
                            PaymentMethodRoles.ALLOW_PARTIAL_PAYMENT,
                        );

                        return (
                            <li key={paymentMethod.name}>
                                <PaymentMethod
                                    disabled={
                                        (partialPayment || isPartialPaymentOrder) &&
                                        !allowPartialPayment
                                    }
                                    paymentMethod={paymentMethod}
                                    onClick={() => {
                                        onPaymentMethodSelected({
                                            source: paymentMethod.source,
                                            name: paymentMethod.name,
                                        });
                                    }}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default PaymentMethodsContainer;
