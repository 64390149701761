import * as React from 'react';
import { Flex } from 'strat/components';
import { NotFoundError } from 'strat/error';

import { Header } from 'horizontal/header';
import Footer from 'horizontal/footer';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';

import NotFoundHead from './notFoundHead';
import styles from './styles/notFoundPageDesktop.cssm';

const NotFoundPageDesktop = {
    head: () => <NotFoundHead />,
    body: () => (
        <Flex column fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <Flex justifyCenter fillContainer className={styles.content}>
                <NotFoundError />
            </Flex>
            <Footer />
        </Flex>
    ),
} as const;

export default NotFoundPageDesktop;
