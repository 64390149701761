import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import type { FetchAction, FetchState } from 'strat/fetcher';
import StratAPI from 'strat/api/strat';
import { selectActiveUser } from 'strat/user/session';

import type { Notification } from 'horizontal/types';

export type NotificationsPage = {
    readonly count: number;
    readonly next: string | null | undefined;
    readonly prev: string | null | undefined;
    readonly results: Notification[];
};

export type NotificationsPageMap = {
    [key: number]: NotificationsPage;
};

export type PaginatedNotificationsState = FetchState & {
    pages: NotificationsPageMap;
};

export type NotificationsState = FetchState<NotificationsPage> & { pages: NotificationsPageMap };

export const defaultPaginationParams = {
    page: 1,
    pageSize: 50,
} as const;

const factory = new FetcherFactory('userNotifications', (_params: any, state: any) => {
    const activeUser = selectActiveUser(state);
    if (!activeUser) {
        return Promise.resolve({ data: {}, status: 200 });
    }
    return new StratAPI(state.i18n.language).userNotifications(activeUser.externalID, {
        ...defaultPaginationParams,
        ..._params,
    });
});

/**
 * Action creator for fetching user notifications from
 * the back-end.
 */
const fetchUserNotifications = factory.creator();

/**
 * Clears the user notifications from the store
 */
const clearUserNotifications = () => ({
    type: factory.actions.clear,
});

/**
 * Reducers for user notifications.
 */
const userNotificationsBaseReducer = factory.reducer();

const defaultState: PaginatedNotificationsState = {
    ...buildDefaultState(),
    pages: {},
};

const userNotificationsReducer = (
    state: PaginatedNotificationsState | null | undefined = defaultState,
    action: FetchAction,
) => {
    const page = action.params?.page || 1;
    switch (action.type) {
        case factory.actions.success:
            return {
                ...userNotificationsBaseReducer(state, action),
                pages: {
                    // @ts-expect-error - TS2531 - Object is possibly 'null'.
                    ...state.pages,
                    [page]: action.data,
                },
            };
        case factory.actions.clear:
            return defaultState;
        default:
            return userNotificationsBaseReducer(state, action);
    }
};

export { fetchUserNotifications, clearUserNotifications };

export default userNotificationsReducer;
