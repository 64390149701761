type OMEType = {
    AD: string;
    PHONE: string;
    SMS: string;
    WHATSAPP: string;
    EMAIL: string;
};

type OMSType = {
    DETAILS: string;
    SEARCH: string;
    SEARCH_AGENTS: string;
    EMAIL_POPUP_DETAILS: string;
    EMAIL_POPUP_SEARCH: string;
    EMAIL_POPUP_SEARCH_AGENTS: string;
};

type OMAType = {
    VIEW: string;
    LEAD: string;
};

const OvationAppType = Object.freeze({
    WEB_DESKTOP: 'web_desktop',
    WEB_TABLET: 'web_tablet',
    WEB_MOBILE: 'web_mobile',
    MOBILE: 'mobile',
});

const OvationMetricEntity: OMEType = Object.freeze({
    AD: 'ad',
    PHONE: 'phone',
    SMS: 'sms',
    WHATSAPP: 'whatsapp',
    EMAIL: 'email',
});

const OvationMetricSource: OMSType = Object.freeze({
    DETAILS: 'details',
    SEARCH: 'search',
    SEARCH_AGENTS: 'search_agents',
    EMAIL_POPUP_DETAILS: 'email_popup_details',
    EMAIL_POPUP_SEARCH: 'email_popup_search',
    EMAIL_POPUP_SEARCH_AGENTS: 'email_popup_search_agents',
});

const OvationMetricAction: OMAType = Object.freeze({
    VIEW: 'view',
    LEAD: 'lead',
});

const OvationAttribution = Object.freeze({
    POTW: 'potw',
});

const FavoriteAction = Object.freeze({
    LIKE: 'like',
    UNLIKE: 'unlike',
});

export type AdPartialData = {
    readonly type?: string;
    readonly externalID: string;
    readonly sourceID: number;
    readonly postingID: number;
    readonly productConsumptionIDs: Array<number>;
};

export {
    OvationAppType,
    OvationMetricEntity,
    OvationMetricSource,
    OvationMetricAction,
    OvationAttribution,
    FavoriteAction,
};
