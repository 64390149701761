import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import type { ActionButton } from 'horizontal/chat/types';
import handshake from '@app/assets/images/handshake.png';

import ActionButtons from './actionButtons';
import PriceLabel from './priceLabel';
import styles from './styles/accepted.cssm';

type Props = {
    readonly actionButtons: Array<ActionButton>;
    readonly lastOfferPrice: number;
};

const Accepted = ({ actionButtons, lastOfferPrice }: Props) => (
    <Flex column className={styles.container}>
        <Flex column fillContainer alignCenter justifyCenter>
            <Text.Large bold uppercase>
                <Trans>Offer accepted</Trans>
            </Text.Large>
            <Flex>
                <PriceLabel price={lastOfferPrice} />
                <img src={handshake} alt="handshake" className={styles.handshakeImage} />
            </Flex>
            <Text.Regular>
                <Trans>{"Now you're one step closer to the final deal"}</Trans>
            </Text.Regular>
        </Flex>
        <ActionButtons actionButtons={actionButtons} />
    </Flex>
);
export default React.memo<Props>(Accepted);
