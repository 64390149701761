import type { AdCompletionDetails } from 'strat/property/types';

type ProjectHandoverQuarter = {
    quarter: number;
    year: number;
};

const computeProjectHandoverQuarter = (
    completionDetails?: AdCompletionDetails | null,
): ProjectHandoverQuarter | null => {
    if (!completionDetails?.completionDate) {
        return null;
    }

    const date = new Date(completionDetails.completionDate * 1000);
    const quarter = Math.floor(date.getMonth() / 3) + 1;
    return {
        quarter,
        year: date.getFullYear(),
    };
};

export default computeProjectHandoverQuarter;
