import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
// @ts-ignore
import type { Dispatch, GetState } from 'redux';

import {
    buildDefaultState,
    FetchAction,
    FetchState as SegmentFetchState,
    FetchError as SegmentFetchError,
    FetchProcessor,
    FetcherFactoryWithContext,
    FetchMethodWithContext,
    FetchOptions,
    FetchData,
    FetcherActions,
} from './fetcher';
import type { FetchActionContext, FetchContext, FetchParameters, FetchReducer } from './fetcher';

interface FetchState {
    [key: string]: SegmentFetchState | SegmentFetchError | boolean | undefined | null;
}

/**
 * Default state.
 */
const defaultState = {
    enabled: true,
    loading: false,
} as const;

/**
 * Default state for each segment.
 */
const segmentDefaultState = buildDefaultState();

type SegmentKey = string;

interface SegmentBatchTask {
    resolve: (data?: any) => void;
    reject: (err?: any) => void;
    params: FetchParameters;
    state: FetchState;
    dispatch: Dispatch<FetchAction>;
}

type SegmentFetcherOptions = Partial<
    {
        batched?: boolean;
        cached?: boolean;
    } & FetchOptions
>;

/**
 * SegmentFetcherFactory can be used in cases where we want to gradually load data from an API on demand,
 * accumulating them in redux-state.
 *
 * Similar to FetcherFactory but:
 * - you need to pass `segmentKey` which is a string.
 *  `segmentKey` is a key of params passed to the fetcher.
 *  subsequent queries will accumulate on state based on that key's value.
 *  If the key exist already its value will be overwritten. (for more see field's docs)
 *
 *  Usage:
 *  const factory = new SegmentFetcherFactory(
 *                      'dummyFactory',
 *                      'segmentKey',
 *                      (params, state) => StratAPI.fetchSomething(params),
 *                      options,
 *                  )
 *  const factoryCreator = factory.creator();
 *  const factoryReducer = factory.reducer();
 */
class SegmentFetcherFactory<TParams, TData = any> extends FetcherFactoryWithContext<
    TParams | Array<TParams>,
    TData | Array<TData>,
    TParams | Array<TParams>,
    FetchState
> {
    /**
     * The `segmentKey` will be used to accumulate the `data` and `params` objects when a new request is triggered
     * In redux state both `data` and `params` are objects with keys specified by `segmentKey`
     *
     * e.g. fetchParams are { category: 'dummy', page: '1' }
     *  segmentKey can be 'category'
     *  then state = {
     *      ...
     *      'dummy': {
     *          params: { category: 'dummy', page: '1' },
     *          data: {
     *              'dummy': results of request,
     *              ...
     *          },
     *          loading: true/false,
     *          error: null/error,
     *          ...
     *       },
     *       loading: true/false,
     *  }
     */
    segmentKey: SegmentKey;

    /**
     * Set `options.cached` to true if you want to avoid fetching again a segment once it is successfully fetched.
     */
    cached: boolean;

    /**
     * Set `options.batched` to true if you want this fetcher to perform batched requests.
     * If this is set to true, the fetcher provided is expected to handle an array of `params` and return an array of
     * `data`. The response data are expected to include the segmentKey so that this fetcher can properly assign
     * response items to their segmentName.
     */
    batched: boolean;

    /**
     * Internal variable that holds the currently scheduled tasks
     */
    currentBatch: Record<SegmentKey, SegmentBatchTask>;

    declare actions: FetcherActions & {
        batchStart: string;
        batchSuccess: string;
    };

    private debouncedCallFetchMethod: () => void;

    constructor(
        name: string | Array<string>,
        segmentKey: SegmentKey,
        fetcher: FetchMethodWithContext<TParams | Array<TParams>, TData | Array<TData>>,
        options: SegmentFetcherOptions = {},
    ) {
        super(name, fetcher, options);

        this.segmentKey = segmentKey;
        this.batched = options.batched ?? false;
        this.cached = options.cached ?? false;
        this.currentBatch = {};

        const actionName = Array.isArray(this.name)
            ? this.name.join('/').toUpperCase()
            : this.name.toUpperCase();
        this.actions = {
            ...this.actions,
            batchStart: `FETCH/${actionName}/BATCH_START`,
            batchSuccess: `FETCH/${actionName}/BATCH_SUCCESS`,
        };

        if (process.env.IS_SERVER) {
            this.debouncedCallFetchMethod = debounce(this._debouncedCallFetchMethod.bind(this), 0);
        } else {
            this.debouncedCallFetchMethod = debounce(
                this._debouncedCallFetchMethod.bind(this),
                100,
            );
        }
    }

    /**
     * Gets a Redux reducer to storing fetched data.
     */
    // eslint-disable-next-line
    reducer(processor: null | FetchProcessor = null): FetchReducer<FetchState, FetchAction> {
        const segmentReducer = super.reducer(processor);
        return (state: FetchState = defaultState, action: FetchAction): FetchState => {
            switch (action.type) {
                case this.actions.batchStart:
                    action.params.forEach((params: any) => {
                        const segmentName = this.getSegmentName(params);
                        if (segmentName) {
                            if (!state[segmentName]) {
                                state[segmentName] = segmentDefaultState;
                            }
                            const subState = state[segmentName] as SegmentFetchState;
                            subState.loading = true;
                            subState.loaded = false;
                            subState.params = params;
                        }
                    });
                    return { ...state };

                case this.actions.batchSuccess:
                    action.params.forEach((params: any, index: number) => {
                        const segmentName = this.getSegmentName(params);
                        if (segmentName) {
                            if (!state[segmentName]) {
                                state[segmentName] = { ...segmentDefaultState };
                            }
                            const subState = state[segmentName] as SegmentFetchState;
                            subState.loading = false;
                            subState.loaded = true;
                            subState.preloaded = false;
                            subState.params = params;
                            subState.data = processor
                                ? processor(action.data[index], subState.data)
                                : action.data[index];
                            subState.error = null;
                        }
                    });
                    return { ...state };

                default: {
                    const actionSegmentName = this.getSegmentName(action.params ?? {});
                    if (!actionSegmentName) {
                        return {
                            ...defaultState,
                            ...state,
                        };
                    }

                    const updatedState = {
                        ...state,
                        [actionSegmentName]: segmentReducer(
                            (state[actionSegmentName] ?? segmentDefaultState) as SegmentFetchState,
                            action,
                        ),
                    };

                    return {
                        ...updatedState,
                        // @ts-ignore
                        loading: Object.values(updatedState).some((subState) => subState?.loading),
                    };
                }
            }
        };
    }

    getSegmentName(params: FetchParameters) {
        return params[this.segmentKey];
    }

    checkParamsEquality(allParams: any, state: any) {
        const segmentName = this.getSegmentName(allParams);
        return (
            isEqual(allParams, state[segmentName]?.params) &&
            !state.preloaded &&
            !this.options.skipParamsCheck
        );
    }

    _thunk(
        params: FetchParameters,
        actionContext: FetchActionContext,
        dispatch: Dispatch<FetchAction>,
        getState: GetState,
    ): Promise<any> {
        if (this.cached) {
            const cachedEntry = getState()[this.name]?.[this.getSegmentName(params)];
            if (cachedEntry && !cachedEntry.error && !cachedEntry.loading) {
                return Promise.resolve({ data: cachedEntry.data, status: 200 });
            }
        }
        return super.thunk(params, actionContext, dispatch, getState);
    }

    thunk(
        params: FetchParameters,
        actionContext: FetchActionContext,
        dispatch: Dispatch<FetchAction>,
        getState: GetState,
    ): Promise<any> {
        if (Array.isArray(params)) {
            return Promise.all(
                params.map((param) => this._thunk(param, actionContext, dispatch, getState)),
            ).then((data) => {
                return data;
            });
        }
        return this._thunk(params, actionContext, dispatch, getState);
    }

    dispatchFetchStart(dispatch: Dispatch<FetchAction>, params: FetchParameters) {
        if (!this.batched) {
            super.dispatchFetchStart(dispatch, params);
        }
    }

    dispatchFetchSuccess(
        dispatch: Dispatch<FetchAction>,
        params: FetchParameters,
        data: FetchData,
    ) {
        if (!this.batched) {
            super.dispatchFetchSuccess(dispatch, params, data);
        }
    }

    callFetchMethod({ params, state, dispatch, ...actionContext }: FetchContext<any>): Promise<{
        data: any;
        status: number;
    }> {
        if (!this.batched) {
            super.dispatchFetchStart(dispatch, params);
            return super.callFetchMethod({ params, state, dispatch });
        }
        const segmentName = this.getSegmentName(params);

        this.debouncedCallFetchMethod();

        // eslint-disable-next-line
        const self = this;
        return new Promise((resolve: any, reject: any) => {
            self.currentBatch[segmentName] = {
                ...actionContext,
                params,
                state,
                dispatch,
                resolve,
                reject,
            };
        });
    }

    _debouncedCallFetchMethod() {
        const batchArray = Object.values(this.currentBatch);
        if (!batchArray.length) {
            return;
        }

        const paramsArray = batchArray.map((task) => task.params) as FetchParameters[];
        const state = batchArray[0].state;
        const dispatch = batchArray[0].dispatch;
        // eslint-disable-next-line
        const self = this;
        super
            .callFetchMethod({
                params: paramsArray,
                state,
                dispatch,
            })
            // @ts-ignore
            .then(({ data: batchResults, status }: { data: TData[]; status: number }) => {
                dispatch({
                    type: self.actions.batchSuccess,
                    params: paramsArray,
                    data: batchResults,
                });

                batchResults.forEach((taskResult: TData) => {
                    const segmentName = self.getSegmentName(taskResult);
                    self.currentBatch[segmentName].resolve({
                        data: taskResult,
                        status,
                    });
                    delete self.currentBatch[segmentName];
                });
            })
            .catch((error) => {
                Object.values(self.currentBatch).forEach((task) => task.reject(error));
            });
    }
}

export default SegmentFetcherFactory;
