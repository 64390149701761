import * as React from 'react';
import autoBind from 'react-autobind';

import { ScheduleScope } from 'strat/scheduler';
import { PropertyState } from 'strat/property/types';
import type { PropertyData } from 'strat/property/types';

import type { GTMVariables } from './trigger';
import trigger from './trigger';

/**
 * GTM data layer.
 */
type GTMDataLayer = {
    get: (key: string) => any;
    set: (key: string, value?: any) => void;
    reset: () => void;
};

/**
 * Properties passed by {@see withGTM} to the wrapped component.
 */
export type GTMProps = {
    /**
     * GTM data layer object.
     */
    gtm: Array<any> | null;
    /**
     * Sets a set of variables to the GTM data layer.
     */
    set: (variables: GTMVariables) => void;
    /**
     * Gets the value of a single variables on the GTM data layer.
     */
    get: (key: string) => any;
    /**
     * Resets the values of all variables currenlty set.
     */
    reset: () => void;
    /**
     * Emits a trigger.
     */
    trigger: (
        triggerName: string,
        variables?: GTMVariables | null | undefined,
        scheduleScope?: Values<typeof ScheduleScope>,
    ) => void;
    /**
     * Name of the data layer.
     */
    dataLayerName: string | null;
    /**
     * Unique GTM container ID.
     */
    containerID: string | null;
    /**
     * Whether the ad can be ingested
     */
    canIngestAd: (property: PropertyData) => boolean;
};

/**
 * Allows access to the Google Tag Manager.
 */
const withGTM = <T extends GTMProps>(component: React.ComponentType<T>) => {
    class WithGTMHOC extends React.Component<Omit<T, keyof GTMProps>> {
        /**
         * Indicates whether GTM is enabled.
         */
        enabled: boolean;

        /**
         * Name of the data layer to use.
         */
        dataLayerName: null | string;

        /**
         * Unique GTM container ID.
         */
        containerID: null | string;

        /**
         * Initializes a new instance of {@see WithGTMHoc}.
         */
        constructor(props: Omit<T, keyof GTMProps>) {
            super(props);
            autoBind(this);

            this.containerID = CONFIG.build.GTM_CONTAINER_ID || null;
            this.dataLayerName = CONFIG.build.GTM_DATA_LAYER_NAME || null;
            this.enabled = !!this.containerID && !!this.dataLayerName;

            if (!this.enabled) {
                console.warn('Google Tag Manager disabled, GTM setings not set.');
            }
        }

        /**
         * Gets access to the data layer.
         */
        dataLayer(): null | GTMDataLayer {
            // @ts-expect-error - TS2339 - Property 'google_tag_manager' does not exist on type 'Window & typeof globalThis'.
            const tagManager = window.google_tag_manager;
            if (!tagManager) {
                return null;
            }

            const container = tagManager[this.containerID];
            if (!container) {
                return null;
            }

            const dataLayer = container[this.dataLayerName];
            if (!dataLayer) {
                return null;
            }

            return dataLayer;
        }

        /**
         * Sets the specified variables in the GTM data layer.
         * @param newVariables A map/dict with the variables to set.
         */
        set(newVariables: GTMVariables): void {
            // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
            window[this.dataLayerName].push(newVariables);
        }

        /**
         * Gets the value of the specified variable from the GTM data layer.
         * @param key The name of the variable to get the value of.
         * @returns The value of the variable with thespecified name.
         */
        get(key: string): any {
            const dataLayer = this.dataLayer();
            if (!dataLayer) {
                return null;
            }

            return dataLayer.get(key);
        }

        /**
         * Resets the values of the specified variables to undefined.
         */
        reset(): void {
            const dataLayer = this.dataLayer();
            if (!dataLayer) {
                return;
            }

            dataLayer.reset();
            // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
            window[this.dataLayerName].push({});
        }

        canIngestAd(property: PropertyData) {
            return property.state ? property.state === PropertyState.ACTIVE : true;
        }

        /**
         * Renders the wrapped component and passes the GTM
         * data layer as a prop.
         */
        render() {
            if (!this.enabled) {
                return null;
            }

            const props = {
                // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
                gtm: window[this.dataLayerName] || null,
                set: this.set,
                get: this.get,
                reset: this.reset,
                trigger,
                canIngestAd: this.canIngestAd,
                dataLayerName: this.dataLayerName,
                containerID: this.containerID,
                ...this.props,
            } as T;

            return React.createElement(component, props);
        }
    }

    return WithGTMHOC;
};

export default withGTM;
