import React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import Footer from 'horizontal/footer';
import { RequestDelivery } from 'horizontal/adDetails/requestDelivery';

import RequestDeliveryHead from './requestDeliveryHead';
import styles from './styles/requestDeliveryDesktop.cssm';

const RequestDeliveryPage = {
    head: () => <RequestDeliveryHead />,
    body: () => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <Flex fillContainer>
                <PageWidthWrapper className={styles.container}>
                    <RequestDelivery />
                </PageWidthWrapper>
            </Flex>
            <Footer />
        </Flex>
    ),
} as const;

export default RequestDeliveryPage;
