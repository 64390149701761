import { FilterCollection, RangeFilter } from '@sector-labs/fe-search-redux/filters';
import { Area, convertRange } from 'strat/i18n';
import brandingSettings from '@app/branding/settings';
import { FilterValues } from 'strat/search';

const processAreaFilter = (filterCollection: FilterCollection): FilterCollection => {
    /**
     * We do not perform a search by `ft` and `ft_unit`. Instead we convert these
     * values to a `area` and we search by that
     */
    const processedFilterCollection = filterCollection.copy([
        'extraFields.ft',
        'extraFields.ft_unit',
    ]);
    const areaValue = filterCollection.getFilterValue('extraFields.ft');
    if (!areaValue) {
        return processedFilterCollection;
    }

    const areaUnit = filterCollection.getFilterValue('extraFields.ft_unit');
    const areaUnitMapping = {
        kanal: Area.KANAL,
        marla: Area.MARLA,
        sqfeet: Area.SQFT,
        sqmeter: Area.SQM,
        sqyards: Area.SQYD,
    } as const;

    const value = convertRange(
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Range | null | undefined'.
        areaValue,
        // @ts-expect-error - TS2538 - Type 'unknown' cannot be used as an index type.
        areaUnitMapping[areaUnit] || brandingSettings.defaultAreaUnit,
        Area.SQM,
    );

    processedFilterCollection.refine(
        new RangeFilter({
            // @ts-ignore
            attribute: brandingSettings.areaFilterAttributeKey || FilterValues.area.attribute,
            value,
        }),
    );
    return processedFilterCollection;
};

export default processAreaFilter;
