import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'strat/i18n/language/selectors';

import { StratAPI } from 'horizontal/api';
import { AdSoldMedium } from 'horizontal/constants';
import { AdState } from 'horizontal/types';

type Params = {
    adExternalID: string;
    buyerExternalID?: string | null;
    onSuccess: (soldType?: Values<typeof AdSoldMedium>) => void;
    soldInfo?: string | null;
    soldType?: Values<typeof AdSoldMedium>;
};
type Props = Params & {
    reason: string;
};

const useSubmitDisableAd = ({
    adExternalID,
    buyerExternalID,
    onSuccess,
    reason,
    soldInfo,
    soldType,
}: Props) => {
    const [loading, setLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const language = useSelector(selectLanguage);
    const submitAd = React.useCallback(() => {
        if (loading) {
            return;
        }

        setLoading(true);
        setIsError(false);

        new StratAPI(language)
            .closeAd({
                adExternalID,
                buyerUserExternalID: buyerExternalID,
                reason,
                soldType,
                soldInfo,
            })
            .then(({ status }) => {
                if (status !== 200) {
                    setIsError(true);
                    return;
                }

                onSuccess(soldType);
            })
            .catch(() => setIsError(true))
            .then(() => setLoading(false));
    }, [adExternalID, buyerExternalID, onSuccess, language, loading, reason, soldInfo, soldType]);

    return { loading, isError, submitAd };
};

const useSubmitSoldAd = (params: Params) => {
    return useSubmitDisableAd({ ...params, reason: AdState.SOLD });
};

export { useSubmitSoldAd, useSubmitDisableAd };
