import * as React from 'react';
import classNames from 'classnames';

import { Dialog } from 'strat/modal';
import { withErrorBoundary } from 'strat/util';
import Designs from 'strat/branding/designs';

import ContactFormModal from './contactFormModal';
import styles from './styles/contactFormDialog.cssm';

/**
 * Properties for {@see ContactFormModal}.
 */
type ContactFormModalProps = React.ComponentProps<typeof ContactFormModal>;

/**
 * Properties for {@see ContactFormDialog}.
 */
type Props = ContactFormModalProps & {
    renderModalContents?: (props: ContactFormModalProps) => React.ReactElement;
    dismissibleClassName?: string;
    dialogClassName?: string;
    design?: Values<typeof Designs>;
};

/**
 * Renders a contact form in a dialog.
 */
const ContactFormDialog = ({
    renderModalContents = (props: ContactFormModalProps) => <ContactFormModal {...props} />,
    ...props
}: Props) => (
    <Dialog
        dismissible
        visible={props.visible}
        onVisibilityChanged={props.onVisibilityChanged}
        className={classNames(props.dialogClassName || props.className, styles.dialog, {
            [styles.size]: !props.dialogClassName,
            [styles.updatedSize]: props.design !== Designs.FORM_DESIGN_2022,
        })}
        dismissibleClassName={props.dismissibleClassName}
    >
        {React.createElement(renderModalContents, props)}
    </Dialog>
);

// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
export default withErrorBoundary(ContactFormDialog);
