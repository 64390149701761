import type { I18n } from '@lingui/core';

import { StratAPI } from 'strat/api';

import type { Coordinates } from './types';

const fetchLocationByCoordinates = (
    i18n: I18n,
    coordinates: Coordinates,
    maxLocationDepth?: number | null,
) =>
    new StratAPI(i18n.locale)
        .locationByCoordinates(coordinates[1], coordinates[0], {
            max_location_depth: maxLocationDepth,
        })
        .then((response) => response.data);

export default fetchLocationByCoordinates;
