import type { PackageTypeValues, ProductPackageOffer, ProductPurchase } from 'horizontal/types';

export type CartPackage = ProductPackageOffer & {
    readonly cartQuantity?: number;
};

export type BusinessPackageSubsection = {
    readonly title: string | React.ReactElement;
    readonly details?: Array<string | null | undefined>;
    readonly packageOffers: Array<ProductPackageOffer>;
};

export const ProductPurchaseStatus = Object.freeze({
    ACTIVE: 'active',
    EXPIRED: 'expired',
    SCHEDULED: 'scheduled',
});

export const PackagesFlow = Object.freeze({
    UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY: 'upselling_ad_after_posting_ad_successfully',
    UPSELLING_AD_FROM_USER_ADS: 'upselling_ad_from_user_ads',
    DISCOUNTED_PACKAGES_PURCHASE_FROM_AN_AD: 'discounted_packages_purchase_from_an_ad',
    LIMITED_AD: 'limited_ad',
});

export type PackagesFlowTypeValues = Values<typeof PackagesFlow>;

export type OfferPackagesSectionsConfiguration = {
    [key in PackageTypeValues]: {
        order: number;
        title: string;
        description: string;
        show: boolean;
        valuePropositions?: Array<string>;
    };
};

export type PackagesSection<T extends ProductPackageOffer | ProductPurchase> = {
    packageType: PackageTypeValues;
    title: string;
    description: string;
    items: Array<T>;
    order: number;
    valuePropositions?: Array<string>;
};
