import { thumbnailURL, ThumbnailSizes, ThumbnailSizeValues } from 'strat/image';

import type { MixedAd } from 'horizontal/types';
import { AdFormat } from 'horizontal/types';

const selectCoverPhotoURL = (ad?: MixedAd | null): string | null | undefined => {
    if (!ad) {
        return null;
    }

    switch (ad.format) {
        case AdFormat.FULL: {
            if (!ad.photos) {
                return null;
            }

            const coverPhoto = ad.photos.find((photo) => photo.main) || ad.photos?.[0];
            if (!coverPhoto) {
                return null;
            }

            return thumbnailURL(coverPhoto.id, 'jpeg', ThumbnailSizeValues[ThumbnailSizes.MEDIUM]);
        }

        default: {
            // @ts-expect-error - TS2339 - Property 'coverPhoto' does not exist on type 'MixedAd'.
            if (!ad.coverPhoto) {
                return null;
            }

            return thumbnailURL(
                // @ts-expect-error - TS2339 - Property 'coverPhoto' does not exist on type 'MixedAd'.
                ad.coverPhoto.id,
                'jpeg',
                ThumbnailSizeValues[ThumbnailSizes.MEDIUM],
            );
        }
    }
};

export default selectCoverPhotoURL;
