import urlParser, { type UrlWithStringQuery } from 'url';

import type { Response } from 'express';
import * as React from 'react';

import { ContextfulError } from 'strat/error/context';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { HistoryRouter, useRouter } from 'react-true-router';
import RouteNames from 'strat/routes/routeNames';

type Options = {
    fallbackURI?: string | null;
    status?: number | null;
};

const defaultFallbackURI = '/';
const defaultStatusCode = 302;

const isWhitelistedRedirectHostname = (hosts: string[]): boolean => {
    const whitelistedHostnames: string[] = [...CONFIG.build.STRAT_REDIRECT_HOSTNAME_WHITELIST];

    const parsedProfolioURI = urlParser.parse(CONFIG.build.PROFOLIO_URL);
    if (parsedProfolioURI?.host && parsedProfolioURI?.hostname) {
        whitelistedHostnames.push(parsedProfolioURI.host);
        whitelistedHostnames.push(parsedProfolioURI.hostname);
    }

    if (CONFIG.runtime.CANONICAL_DOMAIN) {
        whitelistedHostnames.push(CONFIG.runtime.CANONICAL_DOMAIN);
    }

    (CONFIG.runtime.CANONICAL_DOMAIN_EXCEPTION || []).forEach((domain) => {
        if (domain) {
            whitelistedHostnames.push(domain);
        }
    });

    return whitelistedHostnames.some((whitelistedHostname) => hosts.includes(whitelistedHostname));
};

/**
 * Redirects without validating the input.
 *
 * Whatever reason you think you have, do NOT export this.
 * Do NOT remove the `_dangerously` prefix. Redirecting
 * to an unsanitized URI is DANGEROUS as it would allow
 * an attacker to craft URLs that redirect to shady
 * websites that we don't trust.
 */
const dangerouslyRedirectToURI = (
    routerOrContext: HistoryRouter | RoutingContextWithMiddlewares | Response,
    redirectURI: UrlWithStringQuery,
    options?: Exclude<Options, 'fallbackURI'>,
): void => {
    let redirectURIHref = redirectURI.href || defaultFallbackURI;

    const isAbsoluteURI = !!redirectURI.hostname;
    if (!isAbsoluteURI && !redirectURIHref.startsWith('/')) {
        redirectURIHref = `/${redirectURIHref}`;
    }

    if (routerOrContext instanceof HistoryRouter) {
        if (process.env.IS_SERVER) {
            throw new ContextfulError(
                `Cannot execute redirect to ${redirectURIHref} server-side without routing context`,
                {
                    url: redirectURIHref,
                },
            );
        }

        const router = routerOrContext as HistoryRouter;

        if (isAbsoluteURI) {
            window.location.href = redirectURIHref;
            return;
        }

        const routeMatch = router.routes.match(redirectURIHref);
        if (!routeMatch || routeMatch?.route.name === RouteNames.NOT_FOUND) {
            window.location.href = redirectURIHref;
            return;
        }

        router.pushURL(redirectURIHref, true, false);
        return;
    } else if ((routerOrContext as Response)?.status) {
        const response = routerOrContext as Response;

        response
            .status(options?.status || defaultStatusCode)
            .set('Location', redirectURIHref)
            .end();
        return;
    }

    const context = routerOrContext as RoutingContextWithMiddlewares;

    if (process.env.IS_SERVER) {
        context.http.redirect(redirectURIHref, {
            status: options?.status || defaultStatusCode,
            isAbsolute: isAbsoluteURI,
        });
    } else {
        window.location.href = redirectURIHref;
    }
};

/**
 * Redirects to a URI (absolute or relative) safely by taking
 * into account whitelists and other security concerns.
 *
 * This also properly detects if a hard redirect is needed
 * or whether we can push the URL onto the router's
 * navigation stack.
 *
 * Use this function to redirect to untrusted user inputted
 * URIs.
 */
export const redirectToURISafely = (
    routerOrContext: HistoryRouter | RoutingContextWithMiddlewares | Response,
    redirectURI?: string | null,
    options?: Options,
): void => {
    const parsedFallbackURI = urlParser.parse(options?.fallbackURI || defaultFallbackURI);

    if (!redirectURI) {
        dangerouslyRedirectToURI(routerOrContext, parsedFallbackURI, options);
        return;
    }

    const parsedRedirectURI = urlParser.parse(redirectURI);

    // For absolute URLs, verify that the host or hostname is whitelisted.
    // host == with port number
    // hostname == without port number
    const isAbsoluteRedirectURI = parsedRedirectURI.host && parsedRedirectURI.hostname;
    const isWhitelistedHost = isAbsoluteRedirectURI
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          isWhitelistedRedirectHostname([parsedRedirectURI.host!, parsedRedirectURI.hostname!])
        : true;

    if (!isWhitelistedHost) {
        dangerouslyRedirectToURI(routerOrContext, parsedFallbackURI, options);
        return;
    }

    dangerouslyRedirectToURI(routerOrContext, parsedRedirectURI, options);
};

export const useRedirectToURISafely = () => {
    const router = useRouter();

    return React.useCallback(
        (redirectURI?: string | null, options?: Options): void =>
            redirectToURISafely(router, redirectURI, options),
        [router],
    );
};
