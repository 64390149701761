import { ScheduleScope, TaskScheduler } from 'strat/scheduler';

import generateUniqueEventID from './generateUniqueEventID';
import getDataLayerVariables from './getDataLayerVariables';

/**
 * Collection of GTM variables.
 */
export type GTMVariables = {
    [key: string]: any;
};

/**
 * Emits the trigger with the specified name.
 * @param triggerName The name of the trigger.
 * @param variables Additional GTM data layer variables.
 * @param scheduleScope to determine when to call the trigger.
 */
const trigger = (
    triggerName: string,
    variables: GTMVariables | null = {},
    scheduleScope: Values<typeof ScheduleScope> = ScheduleScope.SYNC,
): void => {
    const dataLayerName = CONFIG.build.GTM_DATA_LAYER_NAME || null;

    // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
    if (dataLayerName === null || window[dataLayerName] === undefined) {
        return;
    }
    const dataLayerVariables = getDataLayerVariables(dataLayerName);
    TaskScheduler.schedule(
        () =>
            // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
            window[dataLayerName].push({
                ...dataLayerVariables,
                event_unique_id: undefined,
                ...variables,
                event_id: generateUniqueEventID(),
                event: triggerName,
            }),
        scheduleScope,
    );
};

export default trigger;
