import React from 'react';

import { type ProjectPaymentPlan, ProjectPaymentPlanType } from 'strat/project/types';

import MilestonesPaymentPlan from './milestonesPaymentPlan';
import MonthlyPaymentPlan from './monthlyPaymentPlan';
import PercentagePaymentPlan from './percentagePaymentPlan';
import styles from './styles/paymentPlanTable.cssm';

export const MilestoneAxisSegment = () => (
    <div className={styles.milestoneAxis}>
        <div className={styles.topLine}></div>
        <div className={styles.point}></div>
        <div className={styles.bottomLine}></div>
    </div>
);

type Props = {
    paymentPlan: ProjectPaymentPlan;
};

const PaymentPlanTable = ({ paymentPlan }: Props) => {
    switch (paymentPlan.type) {
        case ProjectPaymentPlanType.MILESTONES:
            return <MilestonesPaymentPlan paymentPlan={paymentPlan} />;
        case ProjectPaymentPlanType.MONTHLY:
            return <MonthlyPaymentPlan paymentPlan={paymentPlan} />;
        case ProjectPaymentPlanType.PERCENTAGE:
            return <PercentagePaymentPlan paymentPlan={paymentPlan} />;
        default:
            return null;
    }
};

export default PaymentPlanTable;
