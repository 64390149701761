import type { PropertyData } from 'strat/property/types';
import { PropertyState } from 'strat/property/types';
import { selectFloorPlanResources } from 'strat/floorPlans/selectors';

import { selectFloorPlan2DImage, selectFloorPlan3DImage, selectFloorPlanModel } from './selectors';
import type { FloorPlan, FloorPlanListResourceType } from './types';

const hasFloorPlans = (property: PropertyData) => {
    /**
     * currently property can have floorplans in 2 ways:
     *  - floorPlan represents a linked floorplan model (to be valid, it must have resources)
     *  - floorPlans represents multiple floorplan images linked to the property (this will be removed in the future)
     */
    const floorPlan = property.floorPlan;
    const floorPlans = property.floorPlans;

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const validFloorPlan = floorPlan && selectFloorPlanResources(floorPlan).length > 0;
    const validFloorPlans = floorPlans && floorPlans.length > 0;
    return validFloorPlan || validFloorPlans;
};

const shouldFetchMatchingFloorPlans = (property: PropertyData) => {
    return CONFIG.build.ENABLE_REQUEST_FLOORPLAN_SECTION && !hasFloorPlans(property);
};

const shouldRenderFloorPlanSection = (property: PropertyData, hasMatchingFloorPlans: boolean) => {
    return (
        (hasFloorPlans(property) || hasMatchingFloorPlans) &&
        property.state !== PropertyState.INACTIVE
    );
};

const getFetchParams = (property: PropertyData) => {
    const bedsCount = property.rooms;
    const bathsCount = property.baths;
    const categorySlug = (property.category.slice(-1)[0] || {}).slug;
    const locationSlug = (property.location.slice(-1)[0] || {}).slug;
    return { bedsCount, bathsCount, categorySlug, locationSlug };
};

const getFloorPlanItems = (floorPlans: Array<FloorPlan>): Array<FloorPlanListResourceType> => {
    return floorPlans
        .map((floorPlan) => {
            // @ts-expect-error - TS2345 - Argument of type 'FloorPlan' is not assignable to parameter of type 'never'.
            const models = selectFloorPlanModel(floorPlan);
            if (CONFIG.build.ENABLE_3D_FLOOR_PLANS && models?.length) {
                return models;
            }
            // @ts-expect-error - TS2345 - Argument of type 'FloorPlan' is not assignable to parameter of type 'never'.
            const image3D = selectFloorPlan3DImage(floorPlan);
            if (image3D) {
                return image3D;
            }
            // @ts-expect-error - TS2345 - Argument of type 'FloorPlan' is not assignable to parameter of type 'never'.
            const image2D = selectFloorPlan2DImage(floorPlan);
            if (image2D) {
                return image2D;
            }
            return null;
        })
        .filter((floorplan) => !!floorplan)
        .slice(0, 12);
};

export {
    hasFloorPlans,
    getFetchParams,
    getFloorPlanItems,
    shouldFetchMatchingFloorPlans,
    shouldRenderFloorPlanSection,
};
