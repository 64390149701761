import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { isCanceled } from 'strat/util';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';

import Page from 'horizontal/pages/page';
import { getStratAPI } from 'horizontal/api';
import type { PaymentOrder } from 'horizontal/payment';
import { PaymentOrderStatus } from 'horizontal/payment';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type PaymentJazzCashMWalletRouteParams = {
    readonly orderExternalID: string;
    readonly fromFreeAdUndertaking?: string;
};

class PaymentJazzCashMWalletRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.PAYMENT_JAZZ_CASH_MWALLET, [
            [
                '^/payment/jazzCash/mobileWallet/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        params: PaymentJazzCashMWalletRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams: Record<string, any> = {};
        if (params.fromFreeAdUndertaking === 'true') {
            queryParams.fromFreeAdUndertaking = params.fromFreeAdUndertaking;
        }
        return {
            pathname: `/payment/jazzCash/mobileWallet/${params?.orderExternalID}`,
            search: queryParams,
        };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const {
            match: {
                params: { orderExternalID, fromFreeAdUndertaking },
            },
            redux: {
                store: { getState },
            },
        } = context;

        if (!orderExternalID) {
            this.renderNotFoundPage(context);
            return;
        }

        const promise = this.cancelable(getStratAPI(getState()).getOrder(orderExternalID)).then(
            ({ data: order, status }: { data: PaymentOrder; status: number }) => {
                if (status !== 200 || order.status === PaymentOrderStatus.ACTIVE) {
                    this.renderNotFoundPage(context);
                    return;
                }

                context.rendering.renderPage(Page.PAYMENT_JAZZ_CASH_MWALLET, {
                    order,
                    fromFreeAdUndertaking: fromFreeAdUndertaking === 'true',
                });
            },
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }
}

export default new PaymentJazzCashMWalletRoute();
