import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { useUserSetting } from 'horizontal/userSettings';
import { UserSettings, UserSettingsKey } from 'horizontal/userSettings/types';
import { PriceType } from 'horizontal/types';

import { PriceTypeField } from '../commonPostingFields';
import NegotiableField from '../negotiableField';

import hasPriceTypeFieldValue from './hasPriceTypeFieldValue';
import PriceFieldCheckBox from './priceFieldCheckBox';
import { PriceRelatedFieldProps } from './types';

const usePriceTypeFields = (props: PriceRelatedFieldProps): React.ReactElement[] => {
    const { categoryFields, values, setFieldValue } = props;
    const i18n = useI18n();

    const allowsCallForPriceAds = useUserSetting(
        UserSettingsKey.ALLOW_CALL_FOR_PRICE_ADS as keyof UserSettings,
    );

    return React.useMemo(() => {
        const priceTypeField = categoryFields.find(
            (field) => field.attribute === PriceTypeField.attribute,
        );

        const isExchangeable = hasPriceTypeFieldValue(priceTypeField, PriceType.EXCHANGE);
        const isNegotiable = hasPriceTypeFieldValue(priceTypeField, PriceType.NEGOTIABLE);
        const isForFree = hasPriceTypeFieldValue(priceTypeField, PriceType.FREE);
        const isRequestable = hasPriceTypeFieldValue(priceTypeField, PriceType.REQUESTABLE);
        const shouldShowRequestableOption = isRequestable && allowsCallForPriceAds;
        const priceType = values[PriceTypeField.attribute];

        const result = [];
        if (isNegotiable) {
            result.push(<NegotiableField values={values} setFieldValue={setFieldValue} />);
        }
        if (isExchangeable) {
            result.push(
                <PriceFieldCheckBox
                    label={t(i18n)`Exchange`}
                    currentPriceType={priceType}
                    priceType={PriceType.EXCHANGE}
                    setFieldValue={setFieldValue}
                />,
            );
        }
        if (isForFree) {
            result.push(
                <PriceFieldCheckBox
                    label={t(i18n)`Free`}
                    currentPriceType={priceType}
                    priceType={PriceType.FREE}
                    setFieldValue={setFieldValue}
                />,
            );
        }
        if (shouldShowRequestableOption) {
            result.push(
                <PriceFieldCheckBox
                    label={t(i18n)`Call for price`}
                    currentPriceType={priceType}
                    priceType={PriceType.REQUESTABLE}
                    setFieldValue={setFieldValue}
                />,
            );
        }

        return result;
    }, [i18n, values, setFieldValue, categoryFields, allowsCallForPriceAds]);
};

export default usePriceTypeFields;
