import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import type { Location } from 'horizontal/types';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import styles from './styles/nestedLocationDropdown.cssm';

export type Props = {
    readonly activeLocation: Location | null;

    readonly setActiveLocation: (location: Location) => void;
    readonly fullLocations: Array<Location>;
};

const LocationDropdownBackButton = ({
    activeLocation,
    setActiveLocation,
    fullLocations,
}: Props) => {
    const i18n = useI18n();

    if (!(activeLocation && activeLocation?.hierarchy?.length > 1)) {
        return null;
    }

    return (
        <Flex
            className={styles.locationElement}
            onClick={() => {
                if (activeLocation.hierarchy) {
                    setActiveLocation(fullLocations[fullLocations.length - 2]);
                }
            }}
            alignCenter
        >
            <img src={iconArrowDown} alt={'Select location'} className={styles.arrowLeft} />

            <Text.Regular>
                {t(i18n)`Back to ${
                    activeLocation?.hierarchy[activeLocation.hierarchy.length - 2]?.name
                }`}
            </Text.Regular>
        </Flex>
    );
};

export default LocationDropdownBackButton;
