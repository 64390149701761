import {
    FilterCollection,
    ExactFilter,
    PageFilter,
    ObjectExactFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { SearchRequestFacetType } from '@sector-labs/fe-search-redux/backend';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import {
    SearchActions,
    selectActiveSearchBackend,
    setContent,
    setSettings,
    setFilters,
} from '@sector-labs/fe-search-redux/state';
import { FilterValues } from 'strat/search';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { isCanceled, makeCancelable } from 'strat/util';
import { determineAdsIndexName, determineLocationsIndexName } from 'strat/search/indexNames';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { CancelableRoute } from 'react-true-router';
import brandingSettings from '@app/branding/settings';
import type { PatternsCollection } from 'react-true-router';
import { selectUserExternalID } from 'strat/user/session';

import { selectDefaultSortOption } from 'horizontal/search/sorting';
import Page from 'horizontal/pages/page';
import { setInitialLocationState, selectLocationBySlug } from 'horizontal/location';
import { Location } from 'horizontal/types';

import { fetchFavoriteAds } from '../favorites';

import { getPageForQueryParams, getPageFromQueryParam } from './pagination';
import ensureHasActiveUser from './ensureHasActiveUser';

export type ProfileRouteParams = {
    readonly externalID: string;
    readonly locationSlug?: string;
    readonly agencySlug?: string;
    readonly page?: number;
};

type SearchJobParams = {
    readonly externalID: string;
    readonly location?: Location;
    readonly page?: number;
};

const HITS_PER_PAGE = 12;

class ProfileRoute extends CancelableRoute {
    searchAttribute: string;
    type: string;
    profilePage: string;
    matchedLocation: Location | null;

    constructor(
        name: string | null | undefined,
        patterns: PatternsCollection | null | undefined,
        searchAttribute: string,
        type: string,
        profilePage: string,
    ) {
        // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
        super(name, patterns || [['$^']]);
        this.searchAttribute = searchAttribute;
        this.type = type;
        this.profilePage = profilePage;
        this.matchedLocation = null;
    }

    createURL(
        { externalID, locationSlug, page }: ProfileRouteParams,
        _context: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const search: Record<string, any> = {};
        if (page) {
            search.page = getPageForQueryParams(page);
        }

        if (locationSlug) {
            return { pathname: `/${this.type}/${externalID}/${locationSlug}`, search };
        }

        return { pathname: `/${this.type}/${externalID}`, search };
    }

    createSearchJob(_: RoutingContextWithMiddlewares, __: string) {}

    createFilteredSearchJob(
        context: RoutingContextWithMiddlewares,
        { externalID, page, location }: SearchJobParams,
    ) {
        const {
            redux: {
                store: { getState },
            },
            i18n: { locale: language },
        } = context;

        const state = getState();

        const filterCollection = new FilterCollection();
        filterCollection.refine(
            new ExactFilter({
                attribute: this.searchAttribute,
                value: externalID,
            }),
        );

        if (location) {
            filterCollection.refine(
                new ObjectExactFilter({
                    attribute: FilterValues.location.attribute,
                    value: location,
                    operator: Operator.OR,
                }),
            );
        }

        if (page) {
            const pageAsNumber = getPageFromQueryParam(page);
            if (isNaN(pageAsNumber) || pageAsNumber <= 0) {
                return null;
            }

            filterCollection.refine(new PageFilter({ value: pageAsNumber }));
        }

        this.refineExtraSearchFields(context, filterCollection);

        const indexName = determineAdsIndexName({ language });

        return new SearchJob(indexName, filterCollection, {
            hitsPerPage: HITS_PER_PAGE,
            sort: selectDefaultSortOption(state),
            facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING
                ? [
                      {
                          type: SearchRequestFacetType.SIMPLE,
                          attribute: `category.lvl${1}.externalID`,
                          filters: [
                              new ExactFilter({
                                  attribute: `category.lvl${0}.externalID`,
                                  value: brandingSettings.verticalProperties.externalID,
                              }).serialize(),
                              new ExactFilter({
                                  attribute: FilterValues.location.attribute,
                                  value: brandingSettings.topLevelLocation.externalID,
                              }).serialize(),
                              new ExactFilter({
                                  attribute: this.searchAttribute,
                                  value: externalID,
                              }).serialize(),
                          ],
                      },
                  ]
                : [],
        });
    }

    searchLocation(context: RoutingContextWithMiddlewares) {
        const {
            match: {
                params: { locationSlug },
            },
            i18n: { locale: language },
            redux: {
                store: { getState },
            },
        } = context;

        if (!locationSlug) {
            return Promise.resolve(null);
        }

        const state = getState();
        const jobs: Array<SearchJob> = [];

        const jobForRealLocation = new SearchJob(
            determineLocationsIndexName({ language }),
            new FilterCollection().refine(
                new ExactFilter({
                    attribute: 'slug',
                    value: locationSlug || brandingSettings.topLevelLocation.slug,
                }),
            ),
            {
                hitsPerPage: 1,
                exhaustiveNbHits: false,
            },
        );
        jobs.push(jobForRealLocation);

        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        const service = new SearchService({ backend: selectActiveSearchBackend(state) });
        return service.fetchJobs(jobs);
    }

    search(context: RoutingContextWithMiddlewares, searchJob: SearchJob) {
        const {
            redux: {
                store: { dispatch, getState },
            },
        } = context;

        const backend = selectActiveSearchBackend(getState());

        // @ts-expect-error - TS2341 - Property 'options' is private and only accessible within class 'SearchJob'.
        dispatch(setSettings(searchJob.options));

        // @ts-expect-error - TS2341 - Property 'filters' is private and only accessible within class 'SearchJob'.
        dispatch(setFilters(searchJob.filters));

        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend });
        return service.fetchJob(searchJob);
    }

    searchByLocation(context: RoutingContextWithMiddlewares, externalID: string) {
        const {
            redux: {
                store: { dispatch },
            },
            match: {
                params: { page },
            },
        } = context;
        return this.searchLocation(context).then((locationResponse) => {
            const locationBasedSearchJob = this.createFilteredSearchJob(context, {
                externalID,
                // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
                page,
                location: locationResponse?.[0].hits[0] as Location,
            });
            if (!locationBasedSearchJob) {
                context.http.status(404);
                context.rendering.renderPage(Page.NOT_FOUND);
                return;
            }
            const matchedLocation = locationResponse?.[0].hits[0] as Location;
            if (matchedLocation) {
                setInitialLocationState(dispatch, matchedLocation);
            }

            return this.search(context, locationBasedSearchJob);
        });
    }

    searchAds(context: RoutingContextWithMiddlewares, searchJob: SearchJob, externalID: string) {
        const {
            match: {
                params: { locationSlug },
            },
        } = context;

        return locationSlug
            ? this.searchByLocation(context, externalID)
            : this.search(context, searchJob);
    }

    getProfileExternalID(_: RoutingContextWithMiddlewares) {
        return '';
    }

    fetchProfile(_: RoutingContextWithMiddlewares) {
        return Promise.resolve({ status: 200, data: null });
    }

    refineExtraSearchFields(_: RoutingContextWithMiddlewares, __: FilterCollection) {}

    fetchAllProfileData(context: RoutingContextWithMiddlewares) {
        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { page, locationSlug },
            },
        } = context;

        const externalID = this.getProfileExternalID(context);

        if (!externalID) {
            // @ts-expect-error - TS2339 - Property 'handleNoExternalID' does not exist on type 'ProfileRoute'.
            this.handleNoExternalID(context);
            return;
        }

        if (!page) {
            dispatch(setContent(null));
            dispatch({ type: SearchActions.FETCH });
        }

        dispatch(fetchFavoriteAds());
        const state = getState();
        const userLocation = state.user.geoLocation;

        const searchJob = this.createFilteredSearchJob(context, {
            externalID,
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
            page,
            location: userLocation.closestLocation,
        });
        if (!searchJob) {
            context.http.status(404);
            context.rendering.renderPage(Page.NOT_FOUND);
            return;
        }

        const dataPromise = Promise.all([
            this.fetchProfile(context),
            this.searchAds(context, searchJob, externalID),
            dispatch(fetchCategoryFields({ lite: true })),
        ]);

        // @ts-expect-error - TS2345 - Argument of type '([{ status }, searchResponse]: [any, any]) => Promise<any>' is not assignable to parameter of type '(value: [{ status: number; data: null; }, SearchResponse<SearchResponseHit>, any]) => any'.
        const fetchData = dataPromise.then(([[{ status }], searchResponse]: [any[], any]) => {
            if (status !== 200) {
                context.http.status(404);
                context.rendering.renderPage(Page.NOT_FOUND);
                return Promise.resolve(null);
            }
            return Promise.resolve(searchResponse);
        });

        const promise = makeCancelable(
            fetchData.then((searchResponse) => {
                if (!searchResponse) {
                    return;
                }

                dispatch(setContent(searchResponse));
                const globalState = getState();
                const matchedLocation = selectLocationBySlug(globalState, locationSlug);
                context.rendering.renderPage(this.profilePage, {
                    location: matchedLocation,
                    loading: false,
                });
            }),
        ).catch((error) => {
            if (isCanceled(error)) {
                return;
            }

            throw error;
        });

        return promise;
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        context.rendering.renderPage(this.profilePage, { location: null, loading: true });
        const {
            redux: {
                store: { getState },
            },
        } = context;
        const state = getState();

        if (state.router.url.includes('myaccount')) {
            if (!ensureHasActiveUser(context)) {
                return;
            }
            const userExternalID = selectUserExternalID(state);
            // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
            context.http.redirect(this.createURL({ externalID: userExternalID }));
            return;
        }

        const promise = this.fetchAllProfileData(context);
        context.promise.wait(promise);
    }
}

export default ProfileRoute;
