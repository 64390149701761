import type { EnhancedLocation } from 'react-true-router/location';
import { parseLocation, serializeLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';

import type { AdDetailsRouteParams } from '../createAdDetailsURL';

import getAdData from './getAdData';

const redirectToCanonical = (
    context: RoutingContextWithMiddlewares,
    createURL: (params: AdDetailsRouteParams) => EnhancedLocation,
): boolean => {
    const ad = getAdData(context);
    const currentLocation = parseLocation(context.match.url);
    const canonicalLocation = parseLocation(
        createURL({ slug: ad.slug, externalID: ad.externalID }),
    );

    if (currentLocation.pathname !== canonicalLocation.pathname) {
        const newURL = serializeLocation({
            ...currentLocation,
            pathname: canonicalLocation.pathname,
        });

        const status = CONFIG.runtime.ENABLE_TEMPORARY_REDIRECTS ? 302 : 301;
        context.http.redirect(newURL, {
            status,
        });

        return true;
    }

    return false;
};

export default redirectToCanonical;
