import * as React from 'react';
import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import { withI18n } from 'strat/i18n/language/withI18n';

/**
 * Properties for {@see BedRoomsLabel}.
 */
type Props = {
    children: React.ReactChild;
    isStudio?: boolean | undefined;
    i18n: I18n;
};

const BedRoomsLabel = (props: Props) => {
    const { i18n } = props;
    const number = props.children as number;

    if (props.isStudio) {
        return <>{t(i18n)`Studio`}</>;
    }

    return (
        <>
            {t(i18n)`${plural(number, {
                '=0': '0',
                one: '# Bed',
                two: '# Beds',
                few: '# Beds',
                many: '# Beds',
                other: '# Beds',
            })}`}
        </>
    );
};

/**
 * Renders the amount of bed rooms as a label.
 */
export default withI18n()(BedRoomsLabel);
