import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { ExactFilter, FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { determineLocationsIndexName } from 'strat/search/indexNames';

const fetchRootLocation = (language: string, backend: SearchBackend) => {
    const filters = new FilterCollection();

    filters.refine(
        new ExactFilter({
            attribute: 'level',
            value: 0,
        }),
    );

    const locations = determineLocationsIndexName({ language });
    const searchJob = new SearchJob(locations, filters, {
        exhaustiveNbHits: false,
        hitsPerPage: 1,
    });

    const service = new SearchService({ backend });
    return service.fetchJob(searchJob).then((response) => response.hits[0]);
};

export default fetchRootLocation;
