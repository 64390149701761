const uniquePrefix = Object.freeze('strat');

/**
 * Returns an ID prefixed with a "unique" string.
 * Changing the map style removes all existing layers and sources, and adds new ones.
 * The best solution for making our custom layers persistent is to "save"
 * them before removal and add them to the new style.
 * Layers and sources having these unique IDs are easily identified as
 * custom and will be added to the new styling.
 * @param {string} name The given string.
 * @returns {string} The prefixed string.
 */
export const createCustomID = (name: string) => `${uniquePrefix}-${name}`;

/**
 * @param {*} name ID to verify.
 * @returns {boolean} If the ID is prefixed with our own unique prefix.
 */
const isCustomID = (name: string) => name.startsWith(uniquePrefix);

export default isCustomID;
