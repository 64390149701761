import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';

import Layout from './layout';

const selectLayout = (state: GlobalState) => state.layout;

const selectIsMobileLayout = createSelector(selectLayout, (layout) => layout === Layout.MOBILE);

export { selectLayout, selectIsMobileLayout };
