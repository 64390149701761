import FetcherFactory from 'strat/fetcher';

import { StratAPI } from 'horizontal/api';
import { ProtoolAgencyLeadsFilterParams } from 'horizontal/agencyPortal/types';
import { ITEMS_PER_PAGE } from 'horizontal/agencyPortal/utils';

const factory = new FetcherFactory(
    ['agencyPortal', 'leads'],
    (filters: ProtoolAgencyLeadsFilterParams) => {
        return new StratAPI().agencyLeads({ ...filters, pageSize: ITEMS_PER_PAGE });
    },
    {
        skipParamsCheck: true,
        catchServerErrors: true,
    },
);

const fetchLeads = factory.creator();

const leadsReducer = factory.reducer();

export { fetchLeads };

export default leadsReducer;
