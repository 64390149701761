import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useSearchHits,
    selectSearchLoadingState,
    selectSearchSettings,
} from '@sector-labs/fe-search-redux/state';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';

/**
 * Tracks search impressions for SEARCH and HOME page.
 * hits.slice(-hitsPerPage) is needed because we have an infinite scroll
 * and we concatenate the results in redux so for first page we'll have [ad1, ..., ad20]
 * when load more is pressed => [ad1,ad2,...ad40] and so on. In order to correctly track we need to get only
 * the last `hitsPerPage` from array
 */
const useTrackSearchImpressions = (ovation: OvationCallbackProps) => {
    const hits = useSearchHits();
    const { loading } = useSelector(selectSearchLoadingState);
    const { hitsPerPage } = useSelector(selectSearchSettings);

    React.useEffect(() => {
        if (loading) {
            return;
        }

        // @ts-expect-error - TS2345 - Argument of type 'SearchResponseHit[]' is not assignable to parameter of type 'readonly AdPartialData[]'. | TS2532 - Object is possibly 'undefined'.
        ovation.ingestAdImpressions(hits.slice(-hitsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hits, loading]);
};

export default useTrackSearchImpressions;
