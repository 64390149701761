import type { EnhancedLocation } from 'react-true-router/location';

import type { PropertyData } from './types';

/**
 * Parameters that can be passed to the property route.
 */
export type PropertyRouteParams = {
    externalID: string;
    slug?: string;
    pagetype?: string | null | undefined;
    language?: string;
};

/**
 * Takes data for a property and encodes it into parameters that
 * can be used to generate a URL that leads to the property page.
 */
const createPropertyURLParams = (
    property: PropertyData,
    language?: string | null,
    pagetype?: string | null,
): PropertyRouteParams => {
    const { externalID, slug } = property;

    return {
        slug,
        externalID,
        pagetype,
        ...(language ? { language } : {}),
    };
};

/**
 * Generate a URL that leads to the property page.
 */
const createPropertyURL = (params: PropertyRouteParams): EnhancedLocation => {
    const { externalID, pagetype, language } = params;
    const baseURL = {
        en: `/property/details-${externalID}.html`,
        ar: CONFIG.build.ENABLE_FLAT_SEARCH_ROUTE
            ? `/تفاصيل-${externalID}/العقار.html`
            : `/العقار/تفاصيل-${externalID}.html`,
        bn: `/প্রপার্টি/বিস্তারিত-${externalID}.html`,
    } as const;

    return {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly en: `/property/details-${string}.html`; readonly ar: string; readonly bn: `/\u09AA\u09CD\u09B0\u09AA\u09BE\u09B0\u09CD\u099F\u09BF/\u09AC\u09BF\u09B8\u09CD\u09A4\u09BE\u09B0\u09BF\u09A4-${string}.html`; }'.
        pathname: CONFIG.build.LOCALIZED_ROUTES ? baseURL[language || 'en'] : baseURL.en,
        search: { pagetype },
    };
};

export type TrackablePropertyRouteParams = {
    externalID: string;
    traceID: string;
};

/**
 * Generate a URL that leads to the property page.
 * This is a trackable url, a unique id is generated for each url, such that we can track who generated this id
 * generated this url.
 */
const createTrackableAdURL = (params: TrackablePropertyRouteParams): string => {
    const { externalID, traceID } = params;

    return `/pm/${externalID}/${traceID}`;
};

export { createPropertyURLParams, createPropertyURL, createTrackableAdURL };
