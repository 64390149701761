import * as React from 'react';
import settings from '@app/branding/settings';
import { isValidPhoneNumber } from 'strat/i18n/phone';
import { useActiveUser } from 'strat/user/session';

import { TPayPaymentField } from './tPayForm';

const useValidPhone = () => {
    const user = useActiveUser();
    // the TPay input wants an Egyptian number without the prefix
    const [phone, setPhone] = React.useState(TPayPaymentField.initial);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        if (!user) {
            setLoading(false);
        } else {
            setLoading(true);
            const phoneNumber = user?.phoneNumber
                ? user?.phoneNumber.replace(settings.defaultPhoneNumber.prefix, '')
                : '';
            // isValidPhoneNumber validates a number with the prefix of the country
            // if the number is valid then we will use 'phone' as initial value for
            // input, otherwise we are going to keep it as empty string
            isValidPhoneNumber(`${settings.defaultPhoneNumber.prefix}${phoneNumber}`)
                .then((valid) => {
                    if (valid) {
                        setPhone(phoneNumber);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [user]);
    return { phone, loading };
};

export default useValidPhone;
