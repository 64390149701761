import React from 'react';
import { isCanceled } from 'strat/util';

import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';

import type { PaymentHookParams } from '../types';
import { PaymentSource, PaymentStatus } from '../types';
import useNavigateToPaymentFailed from '../hooks/useNavigateToPaymentFailed';
import useTrackPaymentInitiated from '../hooks/useTrackPaymentInitiated';

import useNavigateToTPayPayment from './useNavigateToTPayPayment';

const useTPayPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const navigateToTPayPayment = useNavigateToTPayPayment();
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();

    return React.useCallback(() => {
        if (!order || !order.externalID) {
            return;
        }
        setIsLoading(true);
        const cancelablePromise = makeCancelable(new StratAPI().tpaySession(order.externalID));
        cancelablePromise
            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            .then(({ data, status }) => {
                setIsLoading(false);
                if (status === 400 && data.paymentStatus === PaymentStatus.AD_REJECTED) {
                    navigateToPaymentFailed(PaymentStatus.AD_REJECTED);
                    return;
                }
                if (status !== 200 || !data?.sessionID) {
                    navigateToPaymentFailed();
                    return;
                }

                trackPaymentInitiated(PaymentSource.TPAY);

                navigateToTPayPayment({
                    orderExternalID: order.externalID,
                    sessionID: data.sessionID,
                });
            })
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            .catch((error) => {
                if (isCanceled(error)) {
                    return;
                }
                setIsLoading(false);
            });

        setPaymentPromise(cancelablePromise);
    }, [
        order,
        setIsLoading,
        setPaymentPromise,
        navigateToTPayPayment,
        navigateToPaymentFailed,
        trackPaymentInitiated,
        makeCancelable,
    ]);
};

export default useTPayPayment;
