import * as React from 'react';
import {
    useAddAdditionalPhone,
    useEditPhone,
    useResendAdditionalPhoneCode,
    useResendEditPhoneCode,
    useVerifyAdditionalPhoneCode,
    useVerifyEditPhoneCode,
} from '@sector-labs/fe-auth-redux/thunk';
import {
    KeycloakChannel,
    SessionOtpData,
    SessionTokenData,
    useResetSessionError,
} from '@sector-labs/fe-auth-redux';
import { getDefaultOTPChannel, useKeycloakSiteConfigWithCallbacks } from 'strat/auth/keycloak';
import { parsePhoneNumberList } from 'strat/i18n/phone';
import { Dialog } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { trackOTPResent, trackOTPSent } from 'strat/gtm';
import { OTPTriggerFlow } from 'strat/auth/keycloak/types';

import { useMakeCancelable } from 'horizontal/util';
import PhoneNumberDialogContent from '@app/fields/userPhoneNumberField/phoneNumberDialogContent';

import PhoneConfirmationDialogContent from './phoneConfirmationDialogContent';
import styles from './styles/phoneNumberDialog.cssm';

export const DialogStep = Object.freeze({
    PHONE: 'phone',
    OTP: 'otp',
});

type Props = {
    readonly phoneNumber: string | null | undefined;
    readonly visible: boolean;
    readonly onVisibilityChanged: (visible: boolean) => void;
    readonly onDone: (phoneNumber?: string | null | undefined) => void;
    readonly isAdditionalPhoneNumber?: boolean;
    readonly enablePhoneNumberChangeWarningBanner?: boolean;
};

const PhoneNumberDialog = ({
    phoneNumber,
    visible,
    onVisibilityChanged,
    onDone,
    isAdditionalPhoneNumber = false,
    enablePhoneNumberChangeWarningBanner,
}: Props) => {
    const editPhone = useEditPhone();
    const addAdditionalPhone = useAddAdditionalPhone();
    const resendEditPhoneCode = useResendEditPhoneCode();
    const resendAdditionalPhoneCode = useResendAdditionalPhoneCode();
    const verifyEditPhoneCode = useVerifyEditPhoneCode();
    const verifyAdditionalPhoneCode = useVerifyAdditionalPhoneCode();
    const resetSessionError = useResetSessionError();

    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const makeCancelable = useMakeCancelable();

    const [phone, setPhone] = React.useState(phoneNumber);
    const [dialogStep, setDialogStep] = React.useState(DialogStep.PHONE);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOTPLoaded, setIsOTPLoaded] = React.useState(false);
    const user = useActiveUser();

    const defaultOTPChannel = getDefaultOTPChannel();
    const onPhoneNumberSubmit = React.useCallback(
        (newPhone?: string | null, captcha?: string | null) => {
            setIsLoading(true);
            const params = {
                phone: newPhone,
                channel: defaultOTPChannel,
                captcha,
            } as const;
            const keycloakAction = isAdditionalPhoneNumber ? addAdditionalPhone : editPhone;

            makeCancelable(parsePhoneNumberList([user?.phoneNumber, newPhone]))
                .then(([currentPhoneNumber, formattedNewPhone]: [any, any]) => {
                    if (currentPhoneNumber === formattedNewPhone) {
                        setPhone(newPhone);
                        onDone(newPhone);
                        return Promise.resolve();
                    }
                    // @ts-expect-error - TS2345 - Argument of type '(dispatch: Dispatch<any>, getState: () => GlobalState) => Promise<SessionTokenData | SessionOtpData | null>' is not assignable to parameter of type 'Promise<any>'. | TS2345 - Argument of type '{ readonly phone: string | null | undefined; readonly channel: KeycloakChannel.SMS | KeycloakChannel.CALL; readonly captcha: string | null | undefined; }' is not assignable to parameter of type 'SendPhoneCodeParameters'.
                    return makeCancelable(keycloakAction(keycloakSiteConfig, params));
                })
                // @ts-expect-error - TS2345 - Argument of type '(dispatch: Dispatch<any>, getState: () => GlobalState) => Promise<SessionTokenData | SessionOtpData | null>' is not assignable to parameter of type 'Promise<any>'. | TS2345 - Argument of type '{ readonly phone: string | null | undefined; readonly channel: KeycloakChannel.SMS | KeycloakChannel.CALL; readonly captcha: string | null | undefined; }' is not assignable to parameter of type 'SendPhoneCodeParameters'.
                .catch(() => makeCancelable(keycloakAction(keycloakSiteConfig, params)))
                .then(
                    (
                        data:
                            | SessionOtpData
                            | null
                            | undefined
                            | SessionTokenData
                            | null
                            | undefined,
                    ) => {
                        setIsOTPLoaded(true);
                        if (!data) {
                            // The error is read from the Redux state in PhoneNumberDialogContent
                            return;
                        }

                        setPhone(newPhone);

                        // @ts-expect-error - TS2339 - Property 'nextAction' does not exist on type 'SessionTokenData | SessionOtpData'.
                        if (data?.nextAction) {
                            trackOTPSent(defaultOTPChannel, OTPTriggerFlow.EDIT_PROFILE);
                            setDialogStep(DialogStep.OTP);
                        } else {
                            onDone(newPhone);
                        }
                    },
                )
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [
            isAdditionalPhoneNumber,
            editPhone,
            addAdditionalPhone,
            keycloakSiteConfig,
            defaultOTPChannel,
            setPhone,
            setDialogStep,
            makeCancelable,
            onDone,
            user,
        ],
    );

    const onOTPResend = React.useCallback(
        (captcha?: string | null, keycloakChannel?: KeycloakChannel | null) => {
            setIsLoading(true);
            const channel = keycloakChannel || defaultOTPChannel;
            trackOTPResent(channel, OTPTriggerFlow.EDIT_PROFILE);

            makeCancelable(
                isAdditionalPhoneNumber
                    ? // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'. | TS2322 - Type 'KeycloakChannel' is not assignable to type 'KeycloakChannel.SMS | KeycloakChannel.CALL'. | TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
                      resendAdditionalPhoneCode(keycloakSiteConfig, { phone, channel, captcha })
                    : // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'. | TS2322 - Type 'KeycloakChannel' is not assignable to type 'KeycloakChannel.SMS | KeycloakChannel.CALL'. | TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
                      resendEditPhoneCode(keycloakSiteConfig, { phone, channel, captcha }),
                // @ts-expect-error - TS7006 - Parameter 'codeData' implicitly has an 'any' type.
            ).then((codeData) => {
                setIsLoading(false);

                if (!codeData) {
                    setDialogStep(DialogStep.PHONE);
                }
            });
        },
        [
            keycloakSiteConfig,
            phone,
            defaultOTPChannel,
            isAdditionalPhoneNumber,
            resendEditPhoneCode,
            resendAdditionalPhoneCode,
            setDialogStep,
            makeCancelable,
        ],
    );

    const onOTPVerify = React.useCallback(
        (otp: string) => {
            setIsLoading(true);

            makeCancelable(
                isAdditionalPhoneNumber
                    ? // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
                      verifyAdditionalPhoneCode(keycloakSiteConfig, { phone, code: otp })
                    : // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
                      verifyEditPhoneCode(keycloakSiteConfig, { phone, code: otp }),
                // @ts-expect-error - TS7006 - Parameter 'sessionData' implicitly has an 'any' type.
            ).then((sessionData) => {
                setIsLoading(false);

                if (!sessionData) {
                    // The error is read from the Redux state in PhoneConfirmationDialogContent
                    return;
                }

                setDialogStep(DialogStep.PHONE);
                onDone(phone);
            });
        },
        [
            keycloakSiteConfig,
            isAdditionalPhoneNumber,
            verifyEditPhoneCode,
            verifyAdditionalPhoneCode,
            phone,
            setDialogStep,
            onDone,
            makeCancelable,
        ],
    );

    const onDialogDismiss = React.useCallback(
        (isVisibile: boolean) => {
            if (!isVisibile) {
                // Reset to the initial phone number
                setPhone(phoneNumber);
                resetSessionError();
                setDialogStep(DialogStep.PHONE);
            }
            onVisibilityChanged(isVisibile);
        },
        [phoneNumber, setDialogStep, onVisibilityChanged, resetSessionError],
    );

    React.useEffect(() => {
        setPhone(phoneNumber);
        setDialogStep(DialogStep.PHONE);
    }, [phoneNumber, setPhone, setDialogStep]);

    return (
        <Dialog
            dismissible
            withCloseButton
            visible={visible}
            onVisibilityChanged={onDialogDismiss}
            className={styles.container}
        >
            {dialogStep === DialogStep.PHONE && (
                <PhoneNumberDialogContent
                    phoneNumber={phone}
                    onSubmit={onPhoneNumberSubmit}
                    isLoading={isLoading}
                    enablePhoneNumberChangeWarningBanner={enablePhoneNumberChangeWarningBanner}
                />
            )}
            {dialogStep === DialogStep.OTP && (
                <PhoneConfirmationDialogContent
                    phoneNumber={phone as string}
                    onBack={() => setDialogStep(DialogStep.PHONE)}
                    onSubmit={onOTPVerify}
                    onOTPResend={onOTPResend}
                    isOTPLoaded={isOTPLoaded}
                    isLoading={isLoading}
                    onDialogDismiss={onDialogDismiss}
                />
            )}
        </Dialog>
    );
};

export default PhoneNumberDialog;
