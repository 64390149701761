//@ts-nocheck
/**
 * Stops the propagation of an SyntheticEvent wraooer that
 * forms part of React's Event System.
 *
 * React uses event delegation with a single event listener on document for events
 * that bubble, like 'click' for example, which means stopping propagation is
 * not possible; the real event has already propagated by the time you interact with it in React.
 *
 * @param e The event whose propagation should be stopped.
 */
const stopSyntheticEventPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
};

export default stopSyntheticEventPropagation;
