import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { CategoryFieldRole } from 'horizontal/types';
import type {
    LiteCategory,
    LiteHierarchicalLocation,
    CategoryFieldsFilters,
} from 'horizontal/types';

import useExtraFieldsLabels from './useExtraFieldsLabels';

type Props = {
    readonly category?: LiteCategory | null | undefined;
    readonly location?: LiteHierarchicalLocation | null | undefined;
    readonly extraFields?: CategoryFieldsFilters;
};

type Options = {
    readonly short?: boolean;
};

const useBreadcrumbTitle = (
    { category, location, extraFields }: Props,
    { short }: Options = {},
): string => {
    const i18n = useI18n();

    const [labels] = useExtraFieldsLabels(
        category?.id,
        extraFields,
        CategoryFieldRole.INCLUDED_IN_BREADCRUMBS,
    );

    const firstLabel =
        category &&
        settings.combineMakeAndModelOnBreadcrumbs &&
        settings.categoriesWithCustomBreadcrumbs?.includes(category?.slug)
            ? labels.join(' ')
            : labels.slice(-1)[0];

    if (firstLabel) {
        if (short) {
            return firstLabel;
        }

        if (location) {
            return t(i18n)`${firstLabel} in ${location.name}`;
        }

        return firstLabel;
    }

    if (category && location) {
        return t(i18n)`${category.name} in ${location.name}`;
    }

    if (category) {
        return category.name;
    }

    return location?.name || '';
};

export default useBreadcrumbTitle;
