import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikTouched, FormikErrors, FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';

import { ContactInfoFields } from 'horizontal/fields/commonPostingFields';

import UserPhoneNumberField from './userPhoneNumberField';
import PhoneNumberField from './phoneNumberField';

type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly title?: string | null | undefined;
    readonly disabled?: boolean | null | undefined;
    readonly disableOtpVerification?: boolean;
    readonly enablePhoneNumberChangeWarningBanner?: boolean;
    readonly onDone?: (newPhoneNumber?: string | null, oldPhoneNumber?: string | null) => void;
    readonly isMandatory?: boolean;
};

const ContactInfoPhoneNumberField = ({ enablePhoneNumberChangeWarningBanner, ...props }: Props) => {
    const i18n = useI18n();
    const { disableOtpVerification, setFieldValue, onDone } = props;
    const onChangePhoneNumber = React.useCallback(
        (newPhoneNumber?: string | null) => {
            if (!disableOtpVerification) {
                setFieldValue(ContactInfoFields.is_otp_verified.attribute, !!newPhoneNumber);
            }
            if (onDone) {
                // allow the parent to add more handlers
                onDone();
            }
        },
        [disableOtpVerification, setFieldValue, onDone],
    );

    return disableOtpVerification ? (
        <PhoneNumberField leftSideLabel lightPlaceholder boldLabel {...props} />
    ) : (
        <UserPhoneNumberField
            {...props}
            placeholder={t(i18n)`Enter phone number`}
            enablePhoneNumberChangeWarningBanner={enablePhoneNumberChangeWarningBanner}
            onDone={onChangePhoneNumber}
            leftSideLabel
            boldLabel
        />
    );
};

export default ContactInfoPhoneNumberField;
