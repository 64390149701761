import React from 'react';
import { Text } from 'strat/components';

import styles from './styles/infoBanner.cssm';

type Props = {
    readonly text: string;
};

const InfoBanner = ({ text, ...props }: Props) => {
    return (
        <div {...props} className={styles.infoBanner}>
            <Text.Large>{text}</Text.Large>
        </div>
    );
};

export default InfoBanner;
