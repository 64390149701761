import React from 'react';
import IconRetry from '@app/assets/icons/iconRetry.svg';

import { Button } from 'strat/generic';

import styles from './styles/errorFallback.cssm';

type Props = {
    error: null | Error;
    onRetryClick?: (event: React.SyntheticEvent<any>) => void;
    styles?: {
        [key: string]: any;
    };
};

/*
 * Fallback for when a component fails.
 */
export default (props: Props) => {
    const style = props.styles ? props.styles : styles;

    return (
        <div className={style.container}>
            <IconRetry className={style.retryIcon} />
            <Button label={'Retry'} className={style.retryButton} onClick={props.onRetryClick} />
        </div>
    );
};
