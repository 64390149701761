import React from 'react';
import { t, plural } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

const useLocalizedAds = () => {
    const i18n = useI18n();

    return React.useCallback(
        (count?: number | null) => {
            const adsCount = count || 0;
            return t(i18n)`${plural(adsCount || 0, {
                zero: ``,
                one: `${adsCount} Ad`,
                two: `${adsCount} Ads`,
                few: `${adsCount} Ads`,
                many: `${adsCount} Ads`,
                other: `${adsCount} Ads`,
            })}`;
        },
        [i18n],
    );
};

export default useLocalizedAds;
