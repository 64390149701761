import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import iconAgent from 'horizontal/assets/icons/iconAgent_noinline.svg';
import { AdExtraFieldValue } from 'horizontal/types';

import AnalyticsTile from './analyticsTile';
import styles from './styles/analytics.cssm';

type Props = {
    readonly agentCode: AdExtraFieldValue;
    readonly compact?: boolean;
};

const AgentCode = ({ agentCode, compact }: Props) => {
    const i18n = useI18n();

    return compact ? (
        <Flex alignCenter className={styles.agentCode} aria-label="Agent code">
            <Text.Small className={styles.label}>{t(i18n)`Agent Code:`}</Text.Small>
            <Text.Regular bold className={styles.value}>
                {agentCode}
            </Text.Regular>
        </Flex>
    ) : (
        <AnalyticsTile value={`${agentCode}`} text={t(i18n)`Agent Code`} iconSrc={iconAgent} />
    );
};
export default AgentCode;
