import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

const getHomePageDescription = (i18n: I18n) => {
    const countryName = settings.countryName(i18n);
    const brandName = settings.getBrandName(i18n);
    return t(
        i18n,
    )`${brandName} has 1000's ads available in ${countryName} of goods for sale from cars, furniture, electronics to jobs and services listings. Buy or sell something today!`;
};

export default getHomePageDescription;
