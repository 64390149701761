import * as React from 'react';

import type { Geoloc } from './types';
import MapStyleTypes, { DEFAULT_MAPBOX_OWNER } from './mapStyles';

type StaticMapOptions = {
    type: Values<typeof MapStyleTypes>;
    zoom: number;
    width: number;
    height: number;
};

type Props = {
    geolocation: Geoloc;
    options: StaticMapOptions;
    className?: string;
    alt?: string;
    marker?: Geoloc;
    /**
     * Adds a @2x optional parameter, for a higher quality image.
     */
    highDensity?: boolean;
    owner?: string;
    logo?: boolean;
    attribution?: boolean;
};

const StaticMap = ({
    geolocation,
    options,
    className,
    alt,
    marker,
    highDensity,
    owner = DEFAULT_MAPBOX_OWNER,
    logo = true,
    attribution = true,
}: Props) => {
    if (!CONFIG.build.MAPBOX_API_KEY || CONFIG.runtime.DISABLE_MAPBOX_API) {
        return null;
    }

    const overlay = marker ? `pin-l+f74e4e(${marker.lng},${marker.lat})/` : '';
    const highDensityString = highDensity ? '@2x' : '';
    const logoString = logo ? '' : '&logo=false';
    const attributionString = attribution ? '' : '&attribution=false';

    return (
        <img
            className={className || ''}
            src={`https://api.mapbox.com/styles/v1/${owner}/${options.type}/static/${overlay}${geolocation.lng},${geolocation.lat},${options.zoom}/${options.width}x${options.height}${highDensityString}?access_token=${CONFIG.build.MAPBOX_API_KEY}${attributionString}${logoString}`}
            alt={alt || ''}
        />
    );
};

export default StaticMap;
