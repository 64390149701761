import type { FormikErrors, FormikValues, FormikTouched } from 'formik';

export type AuthFormikProps = {
    readonly isValid: boolean;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly touched: FormikTouched<FormikValues>;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly setFieldTouched: (field: keyof FormikValues, touched: boolean) => void;
};

export enum Flow {
    PHONE_LOGIN = 'phoneLogin',
    PHONE_REGISTER = 'phoneRegister',
    EMAIL_LOGIN = 'emailLogin',
    EMAIL_REGISTER = 'emailRegister',
    EMAIL_LINK_LOGIN = 'emailLinkLogin',
    EMAIL_LINK_REGISTER = 'emailLinkRegister',
    FACEBOOK_REGISTER = 'facebookRegister',
    GOOGLE_REGISTER = 'googleRegister',
    FACEBOOK_LOGIN = 'facebookLogin',
    GOOGLE_LOGIN = 'googleLogin',
}

export enum DialogScreen {
    REGISTER = 'register',
    PHONE_LOGIN = 'phoneLogin',
    PHONE_REGISTER = 'phoneRegister',
    EMAIL_LOGIN = 'emailLogin',
    EMAIL_REGISTER = 'emailRegister',
    EMAIL_LINK_LOGIN = 'emailLinkLogin',
    EMAIL_LINK_REGISTER = 'emailLinkRegister',
    LOGIN_SUCCESS = 'login_success',
}

/**
 * Enum for third party providers.
 *
 * Note: The values are not the final Identity Provider IDs used by Keycloak.
 * To obtain the final ID, use the `determineKeycloakThirdPartyProviderID` function.
 */
export enum ThirdPartyProvider {
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
}

export enum FormFields {
    EMAIL = 'email',
    PASSWORD = 'password',
}

export enum OTPTriggerFlow {
    FORGOT_PASSWORD = 'forgotPassword',
    SIGNUP = 'signup',
    EDIT_PROFILE = 'editProfile',
    LOGIN = 'login',
}
