import * as React from 'react';
import { Flex } from 'strat/components';

import type { MixedAd, Location } from 'horizontal/types';

import { Section } from '../section';

import MapDialog from './mapDialog';
import ReportAd from './reportAd';
import AdExternalID from './adExternalID';
import styles from './styles/locationAndId.cssm';

type Props = {
    ad: MixedAd;
    location: Array<Location>;
    isOwnAd?: boolean | null | undefined;
};

const LocationAndId = ({ ad, location, isOwnAd }: Props) => (
    <>
        <Section label="Location">
            <MapDialog location={location} geography={ad.geography} />
        </Section>
        <Flex justifySpaceBetween alignCenter className={styles.extraDetails}>
            <AdExternalID externalID={ad.externalID} />
            {!isOwnAd && <ReportAd />}
        </Flex>
    </>
);

export default LocationAndId;
