import { t } from '@lingui/macro';
import * as React from 'react';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';
import { useI18n } from 'strat/i18n/language';

// because of https://github.com/import-js/eslint-plugin-import/issues/1062
// eslint-disable-next-line import/order
import type { AdPhoto, AdDetailsBadges } from 'horizontal/types';
import { AdTag } from 'horizontal/types';
import EliteTag from '@app/branding/eliteTag';

import 'strat/image/styles/imageSlideshow.css';

import ImageSlideshow from './imageSlideshowAsync';
import FeaturedBadge from './featuredBadge';
import CoverPhoto from './coverPhoto';
import Badge from './badge';
import styles from './styles/gallery.cssm';

type Props = {
    readonly coverPhoto: AdPhoto | null | undefined;
    readonly photos: Array<AdPhoto> | null | undefined;
    readonly photoCount: number;
    readonly featured?: boolean;
    readonly elite?: boolean;
    readonly withArrows?: boolean;
    readonly withSwiping?: boolean;
    readonly withNavigation?: boolean;
    readonly badges?: AdDetailsBadges[];
    readonly tags?: AdTag[];
    readonly loading?: boolean;
    readonly renderGalleryFooter?: () => React.ReactElement;
    readonly onClick?: () => void;
    readonly onGalleryImageChange?: () => void;
    readonly renderSocial?: () => React.ReactElement;
    readonly showDeliveryTag?: boolean;
};

const Gallery = ({
    photos,
    photoCount,
    coverPhoto,
    featured,
    elite,
    withArrows,
    withNavigation,
    badges,
    tags,
    loading,
    renderGalleryFooter,
    onClick,
    onGalleryImageChange,
    renderSocial,
    showDeliveryTag,
}: Props) => {
    const i18n = useI18n();
    const [galleryLoaded, setGalleryLoaded] = React.useState(false);
    const [firstImageLoaded, setFirstImageLoaded] = React.useState(false);
    const onImageLoaded = React.useCallback(
        (itemID) => {
            if (coverPhoto?.id === itemID) {
                setFirstImageLoaded(true);
            }
        },
        [coverPhoto],
    );

    const galleryReady = galleryLoaded && firstImageLoaded;

    const renderItem = React.useCallback(
        (item) => (
            <ThumbnailImage
                imageID={item.id}
                title={item.title}
                alt={item.title}
                className={styles.thumbnail}
                size={ThumbnailSizes.LARGE}
                onLoad={() => onImageLoaded(item.id)}
            />
        ),
        [onImageLoaded],
    );

    const renderBadges = React.useCallback(
        () => (
            <div className={styles.leftBottomOverlay}>
                {badges?.map((badge) => (
                    <Badge badge={badge} key={badge.type} />
                ))}
            </div>
        ),
        [badges],
    );

    const renderTags = React.useCallback(
        () =>
            (!!tags?.length || showDeliveryTag) && (
                <div className={styles.leftBottomOverlay}>
                    {showDeliveryTag && <div className={styles.tag}>{t(i18n)`Delivery`}</div>}
                    {tags?.map((tag) => (
                        <div className={styles.tag}>{tag.name}</div>
                    ))}{' '}
                </div>
            ),
        [tags, i18n, showDeliveryTag],
    );

    return (
        <div className={styles.galleryContainer}>
            {featured && <FeaturedBadge />}
            {elite && <EliteTag elevated />}
            {!galleryReady && <CoverPhoto cover={coverPhoto} />}
            {photos && photoCount > 0 && (
                <ImageSlideshow
                    photos={photos}
                    photoCount={photoCount}
                    onImageGalleryReady={() => setGalleryLoaded(true)}
                    withArrows={withArrows}
                    withNavigation={withNavigation}
                    renderItem={renderItem}
                    loading={loading}
                    renderGalleryFooter={renderGalleryFooter}
                    // @ts-expect-error `renderTags` should be changed to not return a boolean
                    renderBadges={badges ? renderBadges : renderTags}
                    onClick={onClick}
                    onGalleryImageChange={onGalleryImageChange}
                    renderSocial={renderSocial}
                />
            )}
        </div>
    );
};

export default Gallery;
