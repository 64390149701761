import { useSelector } from 'react-redux';

import type { GlobalState } from 'horizontal/state';

import { selectIsMyProfileLoaded, selectIsMyProfileLoading, selectMyProfile } from './selectors';

const useMyProfileDetails = () => useSelector((state: GlobalState) => selectMyProfile(state));

const useMyProfileIsLoaded = () =>
    useSelector((state: GlobalState) => selectIsMyProfileLoaded(state));

const useMyProfileIsLoading = () =>
    useSelector((state: GlobalState) => selectIsMyProfileLoading(state));

export { useMyProfileDetails, useMyProfileIsLoaded, useMyProfileIsLoading };
