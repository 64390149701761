import type { SearchRouteParams } from 'horizontal/routes';
import type { CategoryFieldsFilters } from 'horizontal/types';
import { SortOptionKey } from 'horizontal/search/sorting';
import { PriceType } from 'horizontal/types';

const shouldExcludePriceType = (priceType: string): boolean => {
    return (
        !!priceType &&
        [PriceType.REQUESTABLE, PriceType.EXCHANGE, PriceType.FREE].includes(priceType as string)
    );
};

const refineSearchRouteParams = (
    currentParams: SearchRouteParams,
    newParams: Partial<SearchRouteParams>,
): SearchRouteParams => {
    const oldExtraFields: CategoryFieldsFilters = currentParams?.extraFields || {};
    const newExtraFields: CategoryFieldsFilters = newParams?.extraFields || {};
    const extraFields: CategoryFieldsFilters = {
        ...oldExtraFields,
        ...newExtraFields,
    };
    if (oldExtraFields.price && shouldExcludePriceType(newExtraFields.price_type as string)) {
        delete extraFields.price;
    }
    if (shouldExcludePriceType(oldExtraFields.price_type as string) && newExtraFields.price) {
        delete extraFields.price_type;
    }

    const shouldExcludeFinalPriceType = shouldExcludePriceType(extraFields.price_type as string);
    const sortOption = newParams?.sortOption || currentParams?.sortOption;
    if (
        [SortOptionKey.LOWEST_PRICE, SortOptionKey.HIGHEST_PRICE].includes(sortOption?.key || '') &&
        shouldExcludeFinalPriceType
    ) {
        delete extraFields.price_type;
    }

    return {
        ...currentParams,
        ...newParams,
        page: newParams.page || 1,
        extraFields,
    };
};

export default refineSearchRouteParams;
