import type { Ad } from 'horizontal/types';
import { AdState } from 'horizontal/types';

const shouldRedirectToParentCategory = (ad?: Ad | null): boolean => {
    return (
        !!ad?.category && !!ad?.location && [AdState.REJECTED, AdState.DELETED].includes(ad?.state)
    );
};

export default shouldRedirectToParentCategory;
