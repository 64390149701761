import { useSelector } from 'react-redux';
import { RouteNames } from 'strat/routes';
import { selectRouteName } from '@app/routes/selectors';
import { isSavedSearchesEnabled } from 'strat/search/savedSearches';

import { selectIsRemarketingPage } from 'horizontal/search/selectors';

const useShowSaveSearchButton = () => {
    const routeName = useSelector(selectRouteName);
    const isRemarketingPage = useSelector(selectIsRemarketingPage);
    return isSavedSearchesEnabled() && routeName === RouteNames.SEARCH && !isRemarketingPage;
};

export default useShowSaveSearchButton;
