import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import type { PopularPropertyLinks } from 'strat/home/popularProperty/popularPropertyLinksType';

export default (i18n: I18n): PopularPropertyLinks => ({
    title: t(i18n)`Popular areas`,
    content: [
        {
            title: t(i18n)`For Sale`,
            container: [],
        },
        {
            title: t(i18n)`To Rent`,
            container: [],
        },
    ],
});
