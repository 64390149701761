import * as React from 'react';
import { Flex, LoadingSpinner } from 'strat/components';

import { PaymentOrder } from 'horizontal/payment/types';

import PaymentSuccessHeader from './paymentSuccessHeader';
import PaymentProcessingContent from './paymentProcessingContent';
import useWaitForPaymentToComplete from './hooks/useWaitForPaymentToComplete';
import styles from './styles/paymentProcessing.cssm';

type Props = {
    order: PaymentOrder;
    paymentID: string;
};

const PaymentProcessing = ({ order, paymentID }: Props) => {
    useWaitForPaymentToComplete({ order, paymentID });

    return (
        <>
            <PaymentSuccessHeader />
            <PaymentProcessingContent>
                <Flex className={styles.loadingContainer}>
                    <LoadingSpinner dark className={styles.loading} />
                </Flex>
            </PaymentProcessingContent>
        </>
    );
};

export default PaymentProcessing;
