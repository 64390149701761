import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';

import Page from 'horizontal/agencyPortal/pages/page';

import { ensureCanAccessAgencyPortalAsOwner } from './ensureCanAccessAgencyPortal';

class AgencyPortalAcionCenterRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_ACTION_CENTER, [['^/agencyPortal/actionCenter$']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/agencyPortal/actionCenter' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortalAsOwner(context)) {
            return;
        }
        context.rendering.renderPage(Page.AGENCY_PORTAL_ACTION_CENTER);
    }
}

export default new AgencyPortalAcionCenterRoute();
