import * as React from 'react';
import { useDispatch } from 'react-redux';
import { logout as keycloakLogout } from '@sector-labs/fe-auth-redux/thunk';

import { useRouter } from 'react-true-router';
import { useNavigateToHome } from 'strat/home/routing';
import { restrictedRouteNames } from 'strat/routes/routeNames';
import type { AppDispatch } from 'strat/state';

import { isKeycloakEnabled } from './keycloak/config';
import { logout as expressLogout } from './express/thunks';
import { useKeycloakSiteConfigWithCallbacks } from './keycloak';

type Props = {
    // true             = navigate to home
    // false            = do not navigate to home
    // undefined | null = navigate to home if currently
    //                    on a restricted page
    shouldNavigateToHome?: boolean | null;
};

const useLogout = (): ((options?: Props) => Promise<void>) => {
    const dispatch = useDispatch<AppDispatch>();

    const router = useRouter();
    const navigateToHome = useNavigateToHome();
    const siteConfig = useKeycloakSiteConfigWithCallbacks();

    const currentRouteName = router?.context?.match?.route?.name;

    const navigateToHomeIfNeeded = React.useCallback(
        (shouldNavigateToHome: boolean | null | undefined) => {
            if (shouldNavigateToHome === false) {
                return;
            }

            if (shouldNavigateToHome === true) {
                navigateToHome();
                return;
            }

            if (currentRouteName && restrictedRouteNames.includes(currentRouteName)) {
                navigateToHome();
            }
        },
        [navigateToHome, currentRouteName],
    );

    return React.useCallback(
        (options?: Props): Promise<void> => {
            const shouldNavigateToHome = options?.shouldNavigateToHome;

            if (isKeycloakEnabled()) {
                return dispatch(keycloakLogout(siteConfig)).then(() =>
                    navigateToHomeIfNeeded(shouldNavigateToHome),
                );
            } else {
                return dispatch(expressLogout()).then(() =>
                    navigateToHomeIfNeeded(shouldNavigateToHome),
                );
            }
        },
        [dispatch, navigateToHomeIfNeeded, siteConfig],
    );
};

export default useLogout;
