import * as React from 'react';

import {
    useUserSettingsLoading,
    useUserSettingsSaveError,
    useUserSettingsOperationParams,
    useUserSetting,
    useSaveUserSettings,
} from '../hooks';
import type { UserSettings } from '../types';
import { UserSettingsOperation } from '../types';

import SettingsToggle from './settingsToggle';

export type Props = {
    readonly settingsKey: keyof UserSettings;
    readonly title: string;
    readonly subtitle?: string;
    readonly trackToggle?: (arg1: boolean) => void;
};

const UserSettingsToggle = ({ settingsKey, title, subtitle, trackToggle }: Props) => {
    const [showErrorMessage, setShowErrorMessage] = React.useState<any>(null);

    const value = useUserSetting(settingsKey);
    const isWaitingForData = useUserSettingsLoading();
    const error = useUserSettingsSaveError();
    const operationParams = useUserSettingsOperationParams();
    const saveUserSettings = useSaveUserSettings();

    const isLoading = React.useMemo(() => {
        if (!isWaitingForData) {
            return false;
        }

        if (operationParams.operation === UserSettingsOperation.FETCH) {
            return true;
        }

        // @ts-expect-error - TS2339 - Property 'updatedFields' does not exist on type 'UserSettingsOperationParams'.
        return operationParams.updatedFields.includes(settingsKey);
    }, [operationParams, isWaitingForData, settingsKey]);

    const saveSettings = React.useCallback(() => {
        saveUserSettings({ [settingsKey as string]: !value });
        trackToggle?.(!value);
    }, [saveUserSettings, value, settingsKey, trackToggle]);

    React.useEffect(() => {
        if (
            error &&
            operationParams.operation === UserSettingsOperation.SAVE &&
            // @ts-expect-error - TS2339 - Property 'updatedFields' does not exist on type 'UserSettingsOperationParams'.
            operationParams.updatedFields.includes(settingsKey)
        ) {
            setShowErrorMessage(true);
        } else {
            setShowErrorMessage(false);
        }
    }, [error, operationParams, settingsKey, setShowErrorMessage]);

    return (
        <SettingsToggle
            showErrorMessage={showErrorMessage}
            isLoading={isLoading}
            title={title}
            subtitle={subtitle}
            onClick={saveSettings}
            value={value}
        />
    );
};

export default UserSettingsToggle;
