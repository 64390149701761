//@ts-nocheck
import { combineReducers } from 'redux';

import { InternalAPI } from 'strat/api';
import { FetchState, FetcherFactoryWithContext } from 'strat/fetcher';
import { formatDescription } from 'strat/property/propertyTransformer';

import { setAutoTranslateDescriptionOption } from './autoTranslateFeature';
import { AutoTranslateDescriptionOption } from './constants';

type AutoTranslateState = {
    isAutoTranslateDescriptionEnabled: boolean | null;
    descriptionTranslatedOnDemand: FetchState;
};

const isAutoTranslateDescriptionEnabledState: boolean | null = null;

const Actions = Object.freeze({
    SET_DESCRIPTION_AUTO_TRANSLATE: 'SET_DESCRIPTION_AUTO_TRANSLATE',
});

const isAutoTranslateDescriptionEnabledReducer = (
    state: boolean | null | undefined = isAutoTranslateDescriptionEnabledState,
    action: any,
) => {
    switch (action.type) {
        case Actions.SET_DESCRIPTION_AUTO_TRANSLATE:
            setAutoTranslateDescriptionOption(action.isAutoTranslateDescriptionEnabled);
            return action.isAutoTranslateDescriptionEnabled;
        default:
            return state;
    }
};

const setDescriptionAutoTranslate = (isAutoTranslateDescriptionEnabled: boolean | null) => {
    const value = CONFIG.build.STRAT_ENABLE_AUTO_TRANSLATE_DESCRIPTION_TESTING_PHASE
        ? AutoTranslateDescriptionOption.ENABLED
        : isAutoTranslateDescriptionEnabled;

    return {
        type: Actions.SET_DESCRIPTION_AUTO_TRANSLATE,
        isAutoTranslateDescriptionEnabled: value,
    };
};

type DescriptionTranslatedOnDemandParams = {
    propertyExternalID: string;
    language: string;
    descriptionHash: string;
};

const descriptionTranslatedOnDemandFactory =
    new FetcherFactoryWithContext<DescriptionTranslatedOnDemandParams>(
        ['autoTranslate', 'descriptionTranslatedOnDemand'],
        ({ params }) => {
            return new InternalAPI()
                .translateAdDescription(
                    params.propertyExternalID,
                    params.language,
                    params.descriptionHash,
                )
                .then(({ status, data }) => ({
                    status,
                    data: formatDescription(data || ''),
                }));
        },
        {
            catchServerErrors: true,
            successCodes: [200],
        },
    );

const fetchDescriptiontranslatedOnDemand = descriptionTranslatedOnDemandFactory.creator();

const descriptionTranslatedOnDemandReducer = descriptionTranslatedOnDemandFactory.reducer();

const autoTranslateReducer = combineReducers({
    isAutoTranslateDescriptionEnabled: isAutoTranslateDescriptionEnabledReducer,
    descriptionTranslatedOnDemand: descriptionTranslatedOnDemandReducer,
});

export type { AutoTranslateState };

export { setDescriptionAutoTranslate, fetchDescriptiontranslatedOnDemand };

export default autoTranslateReducer;
