import React from 'react';

import type { CategoryFieldFilterValue } from 'horizontal/types';
import { useNavigateToRefinedSearch } from 'horizontal/search/routing';
import { trackFilterChange } from 'horizontal/gtm';

const useRefineFilter = (attribute: string) => {
    const navigateToRefinedSearch = useNavigateToRefinedSearch();

    return React.useCallback(
        (value: CategoryFieldFilterValue) => {
            trackFilterChange(attribute, value);
            navigateToRefinedSearch({
                extraFields: {
                    [attribute]: value,
                },
            });
        },
        [attribute, navigateToRefinedSearch],
    );
};

export default useRefineFilter;
