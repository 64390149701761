import * as React from 'react';

import type { Category } from 'horizontal/types';
import { CategoryIcon } from 'horizontal/categories';
import { SearchLink } from 'horizontal/search/routing';

import styles from './styles/categoryIconList.cssm';

type Props = {
    readonly categories: Array<Category>;
};

const CategoryIconList = ({ categories }: Props) => (
    <div className={styles.container}>
        {categories.map((category) => (
            <SearchLink params={{ category }} key={category.externalID}>
                <div className={styles.itemContainer}>
                    <div className={styles.iconContainer}>
                        <CategoryIcon
                            category={category}
                            iconClassName={styles.icon}
                            withBackgroundColor
                            containerBackgroundColor={styles.iconBackgroundColor}
                        />
                    </div>
                    <span className={styles.iconText}>{category.name}</span>
                </div>
            </SearchLink>
        ))}
    </div>
);

export default CategoryIconList;
