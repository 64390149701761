import * as React from 'react';
import omit from 'lodash/omit';

import styles from './styles/minimizeMaximizeButton.cssm';

type Props = {
    minimized: boolean;
    onClick: () => void;
    className?: string;
};

const MinimizeMaximizeButton = (props: Props) => {
    // if minimized, show the maximize icon
    const iconClassName = props.minimized ? styles.maximizeIcon : styles.minimizeIcon;
    const label = props.minimized ? 'Maximize' : 'Minimize';

    return (
        <button
            className={[styles.minimizeMaximizeButton, props.className].join(' ')}
            {...omit(props, ['className', 'children', 'minimized'])}
        >
            <span className={iconClassName} aria-label={`${label} window button`} />
        </button>
    );
};

export default MinimizeMaximizeButton;
