import { RouteNames } from 'strat/routes';

import { AppBundles } from 'horizontal/types';

export const AdSoldMedium = Object.freeze({
    OUTSIDE_STRAT: 'outside',
    INSIDE_STRAT: 'inside',
});

export const AdCreationSourceRoutes = Object.freeze({
    [AppBundles.MAIN_BUNDLE_NAME]: {
        AD_MANAGEMENT: RouteNames.AD_MANAGEMENT,
        APPLY_AD_LIMIT: RouteNames.APPLY_AD_LIMIT_PACKAGE,
        SELECT_PACKAGE_SUCCESS: RouteNames.SELECT_PACKAGE_SUCCESS,
        POST_AD_SUCCESS: RouteNames.POST_AD_SUCCESS,
        CREATE_AD_ROUTE: RouteNames.CREATE_AD,
    },
    [AppBundles.AGENCY_PORTAL_BUNDLE_NAME]: {
        AD_MANAGEMENT: RouteNames.AGENCY_PORTAL_ADS,
        APPLY_AD_LIMIT: RouteNames.AGENCY_PORTAL_APPLY_AD_LIMIT,
        SELECT_PACKAGE_SUCCESS: RouteNames.AGENCY_PORTAL_SELECT_PACKAGE_SUCCESS,
        POST_AD_SUCCESS: RouteNames.AGENCY_PORTAL_POST_AD_SUCCESS,
        CREATE_AD_ROUTE: RouteNames.AGENCY_PORTAL_CREATE_AD,
        APPLY_AD_PRODUCTS_CREDIT_COST: RouteNames.AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST,
    },
});
