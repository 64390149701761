import brandingSettings from '@app/branding/settings';

/**
 *
 * From business Perspective there are 4 cases for restricting locations
 *
 * 1. location.level > 4 and disabledCategoryIds exists -> fallback to level 4
 * 2. location.level = 4 and disabledCategoryIds exists -> fallback to level -> 3
 *
 ** On this state, we make use of disabledCategoryIds as a flag, while in a later state after
    the refactor finish and we will have the full locations objects it will be used together with category && categories
    in order to not be used as a flag but as a per category disabling rule
 */

// TODO Types are being fixed on parallels PR with [#182869933]
export const restrictLocation = (location: any): any => {
    if (
        location?.disabledCategoryIDs &&
        // @ts-expect-error - TS2339 - Property 'headerMaxLocationLevel' does not exist on type 'HorizontalSettings'.
        location?.level >= brandingSettings?.headerMaxLocationLevel
    ) {
        const parentLocationIndex =
            location.level === brandingSettings.headerMaxLocationLevel
                ? // @ts-expect-error - TS2339 - Property 'headerMaxLocationLevel' does not exist on type 'HorizontalSettings'.
                  brandingSettings.headerMaxLocationLevel - 1
                : brandingSettings.headerMaxLocationLevel;
        const hierarchySliceIndex =
            location.level === brandingSettings.headerMaxLocationLevel
                ? brandingSettings.headerMaxLocationLevel
                : // @ts-expect-error - TS2339 - Property 'headerMaxLocationLevel' does not exist on type 'HorizontalSettings'.
                  brandingSettings.headerMaxLocationLevel + 1;

        // @ts-ignore
        const parentLocation = location.hierarchy[parentLocationIndex];
        const newHieararchy = location.hierarchy.slice(0, hierarchySliceIndex);

        return {
            ...parentLocation,
            hierarchy: [...newHieararchy],
        };
    }

    return location;
};
