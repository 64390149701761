import { SearchRequestFacetType } from '@sector-labs/fe-search-redux/backend';
import type { SearchRequestFacetsOption } from '@sector-labs/fe-search-redux/backend';
import { ExactFilter } from '@sector-labs/fe-search-redux/filters';
import settings from '@app/branding/settings';
import { FilterValues } from 'strat/search';

import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldFilterType, CategoryFieldRole } from 'horizontal/types';

import commonExcludedFiltersFromFaceting from './commonExcludedFiltersFromFaceting';

const createCategoryFieldsFacetingConfig = (
    categoryFields: Array<FlatCategoryField>,
    locationExternalID?: string,
): SearchRequestFacetsOption =>
    categoryFields
        .filter((field) => {
            const isFilterable = field.roles?.includes(CategoryFieldRole.FILTERABLE);
            const isFacetable = [
                CategoryFieldFilterType.SINGLE_CHOICE,
                CategoryFieldFilterType.MULTIPLE_CHOICES,
            ].includes(field.filterType);

            return isFacetable && isFilterable;
        })
        .map(({ attribute, maxFieldFacetSize }) => ({
            type: SearchRequestFacetType.SIMPLE,
            // @ts-expect-error - TS2339 - Property 'maxFieldFacetSize' does not exist on type 'HorizontalSettings'.
            size: maxFieldFacetSize || settings.maxFieldFacetSize,
            attribute: `extraFields.${attribute}`,
            filters: locationExternalID
                ? [
                      new ExactFilter({
                          attribute: FilterValues.location.attribute,
                          value: locationExternalID,
                      }).serialize(),
                  ]
                : [],
            excludedFilterAttributes: [
                ...commonExcludedFiltersFromFaceting(),
                {
                    attribute: `extraFields.${attribute}`,
                    exact: true,
                },
            ],
        }));

export default createCategoryFieldsFacetingConfig;
