import React from 'react';
import { Link } from 'strat/navigation';
import { Text } from 'strat/components';

import Section from './section';
import type { FooterLink } from './types';
import styles from './styles/linkListSection.cssm';

type Props = {
    readonly title: string;
    readonly links: ReadonlyArray<FooterLink>;
};

const LinkListSection = ({ title, links }: Props) => {
    const listItems = React.useMemo(
        () =>
            links.map(({ name, url, route, routeParams, event }) => (
                <li key={name}>
                    {url ? (
                        <a href={url} className={styles.link} onClick={event}>
                            <Text.Small>{name}</Text.Small>
                        </a>
                    ) : (
                        <Link
                            // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
                            route={route}
                            className={styles.link}
                            params={routeParams}
                            onClick={event}
                        >
                            <Text.Small>{name}</Text.Small>
                        </Link>
                    )}
                </li>
            )),
        [links],
    );

    return (
        <Section title={title}>
            <ul className={styles.list}>{listItems}</ul>
        </Section>
    );
};

export default LinkListSection;
