import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { LoadingSpinner, Text } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';
import { ViewSections, withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import Layout from 'strat/layout';
import { selectLayout } from 'strat/layout/selectors';

import { useFetchAdContactInfo } from 'horizontal/ad';
import { useOnCallButtonClick, useOnSMSButtonClick } from 'horizontal/adDetails';
import { ChatPhoneViewType } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatAd, ChatAdXID } from 'horizontal/chat/types';
import { PhoneNumberLinkType } from 'horizontal/contact';
import { DialogNames } from 'horizontal/dialogs';
import { ContactPersonRole } from 'horizontal/types';

import styles from './styles/phoneContactButton.cssm';

type OwnProps = {
    readonly icon: React.ReactNode;
    readonly linkType: Values<typeof PhoneNumberLinkType>;
    readonly ad: ChatAd | null | undefined; // required for lead tracking,
    readonly ariaLabel: string;
};
type Props = GTMLeadTrackingProps & OwnProps;

const PhoneContactButton = ({
    icon,
    linkType,
    trackCallLead,
    trackSMSLead,
    trackCallView,
    ad,
    ariaLabel,
}: Props) => {
    const layout = useSelector(selectLayout);
    const sdk = useChatSDK();
    const adXID = ad?.externalID as ChatAdXID | undefined;
    const sendPhoneViewMessage = sdk.useSendPhoneViewMessage();
    const { contactInfo, loading, isError, fetchContactInfo } = useFetchAdContactInfo(adXID);
    const [shouldShowPhoneNumber, setShouldShowPhoneNumber] = React.useState(false);
    const isCallButton = linkType === PhoneNumberLinkType.CALL;
    const viewSection = ViewSections.HEADER;

    const onSMSButtonClick = useOnSMSButtonClick({ ad, viewSection, trackSMSLead });
    const onCallButtonClick = useOnCallButtonClick({ viewSection, trackCallLead });

    const [, setPhoneNumberDialogVisible] = useDialog(DialogNames.PHONE_NUMBER_DIALOG);

    const isShortNumber = ad?.contactInfo?.roles?.includes(ContactPersonRole.SHOW_SHORT_NUMBER);
    const callPhoneNumber = isShortNumber
        ? ad?.agency?.shortNumber
        : contactInfo?.proxyMobile || contactInfo?.mobile;
    const smsPhoneNumber = isShortNumber ? ad?.agency?.shortNumber : contactInfo?.mobile;
    const phoneNumber = isCallButton ? callPhoneNumber : smsPhoneNumber;

    React.useEffect(() => {
        if (loading || !phoneNumber || !shouldShowPhoneNumber || !adXID) {
            return;
        }
        setShouldShowPhoneNumber(false);

        sendPhoneViewMessage({
            adXID,
            linkType: isCallButton ? ChatPhoneViewType.CALL : ChatPhoneViewType.SMS,
        });

        if (isCallButton || layout === Layout.DESKTOP) {
            trackCallView(ViewSections.HEADER);
        }

        if (layout === Layout.DESKTOP) {
            setPhoneNumberDialogVisible(true, {
                ad,
                phoneNumber,
                linkType,
                trackCallLead,
                trackSMSLead,
            });
        } else {
            if (isCallButton) {
                onCallButtonClick(phoneNumber);
            } else {
                onSMSButtonClick(phoneNumber);
            }
        }
    }, [
        ad,
        adXID,
        setPhoneNumberDialogVisible,
        layout,
        linkType,
        isCallButton,
        sendPhoneViewMessage,
        phoneNumber,
        loading,
        shouldShowPhoneNumber,
        trackCallLead,
        trackSMSLead,
        trackCallView,
        onCallButtonClick,
        onSMSButtonClick,
    ]);

    if (loading) {
        return (
            <div className={styles.spinnerContainer}>
                <LoadingSpinner dark className={styles.loadingSpinner} />
            </div>
        );
    }
    if (isError) {
        return (
            <Text.Regular error>
                <Trans>Something went wrong!</Trans>
            </Text.Regular>
        );
    }

    return (
        <div
            role="button"
            className={styles.buttonContainer}
            aria-label={ariaLabel || 'Phone contact button'}
            onClick={() => {
                fetchContactInfo();
                setShouldShowPhoneNumber(true);
            }}
        >
            {icon}
        </div>
    );
};

export default withGTMLeadTracking<Props, ChatAd | null | undefined>(PhoneContactButton);
