import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';

import { PaymentSource } from '../types';

const getPageTitle = (i18n: I18n, paymentSource: string) => {
    switch (paymentSource) {
        case PaymentSource.VODAFONE_CASH:
            return t(i18n)`Pay via Vodafone Cash Wallet`;
        case PaymentSource.ORANGE_CASH:
            return t(i18n)`Pay via Orange Cash Wallet`;
        case PaymentSource.ETISALAT_CASH:
            return t(i18n)`Pay via Etisalat Cash Wallet`;
        case PaymentSource.BANK_WALLET:
            return t(i18n)`Pay via Bank wallet Wallet`;
        default:
            return t(i18n)`Pay via Paymob Wallet`;
    }
};

export default getPageTitle;
