import * as React from 'react';

import useNavigateToBankTransfer from './useNavigateToBankTransfer';

const useBankTransferPayment = () => {
    const navigateToBankTransfer = useNavigateToBankTransfer();

    const bankTransferPayment = React.useCallback(
        (adExternalId: string, commission: number) => {
            const params = {
                adExternalID: adExternalId,
                commission,
            } as const;
            navigateToBankTransfer(params);
        },
        [navigateToBankTransfer],
    );

    return bankTransferPayment;
};

export default useBankTransferPayment;
