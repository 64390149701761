import * as React from 'react';
import { Text, Pill } from 'strat/components';

import { DeliveryType } from 'horizontal/types';

import { UserDeliveryOrderStatus } from '../types';

const getPillComponent = (orderStatus: string, deliveryType: DeliveryType) => {
    const pillComponents: Record<string, React.ElementType> = {
        [UserDeliveryOrderStatus.CANCELED_BY_BUYER]: Pill.Regular,
        [UserDeliveryOrderStatus.CANCELED_BY_SELLER]: Pill.Regular,
        [UserDeliveryOrderStatus.REJECTED]: Pill.Regular,
        [UserDeliveryOrderStatus.EXPIRED]: Pill.Regular,
        [UserDeliveryOrderStatus.PAYMENT_DELIVERED]: Pill.Accentuated,
        [UserDeliveryOrderStatus.RETURNED]: Pill.Accentuated,
        [UserDeliveryOrderStatus.DELIVERED]: Pill.Finished,
        [UserDeliveryOrderStatus.NEW]: Pill.Recent,
        [UserDeliveryOrderStatus.ACCEPTED]:
            deliveryType === DeliveryType.SELF_DELIVERY ? Pill.Accepted : Pill.Featured,
        [UserDeliveryOrderStatus.VERIFIED]: Pill.Featured,
        [UserDeliveryOrderStatus.OUT_FOR_DELIVERY]: Pill.Featured,
        [UserDeliveryOrderStatus.PAYMENT_EN_ROUTE]: Pill.Featured,
        [UserDeliveryOrderStatus.RETURN_EN_ROUTE]: Pill.Featured,
        [UserDeliveryOrderStatus.NOT_VERIFIED]: Pill.Featured,
        [UserDeliveryOrderStatus.CLOSED_BY_ADMIN]: Pill.Featured,
        [UserDeliveryOrderStatus.AWAIT_PICKUP]: Pill.Featured,
    };

    return pillComponents[orderStatus] || Pill.Featured;
};

const deliveryOrderStatusPill = ({
    title,
    orderStatus,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: {
    orderStatus: string;
    title: string;
    deliveryType?: DeliveryType;
}): React.ReactElement => {
    const PillComponent = getPillComponent(orderStatus, deliveryType);

    return (
        <PillComponent>
            <Text.Regular>{title}</Text.Regular>
        </PillComponent>
    );
};

export default deliveryOrderStatusPill;
