import * as React from 'react';
import { useRefineSearchFilter } from '@sector-labs/fe-search-redux/state';
import { ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import FilterValues from 'strat/search/filterValues';

import type { Location } from 'horizontal/types';

type UseRefineLocationFilter = (arg1?: Location | null | undefined) => void;

const useRefineLocationFilter = (): UseRefineLocationFilter => {
    const refine = useRefineSearchFilter();

    return React.useCallback(
        (location?: Location | null) =>
            refine(
                new ObjectExactFilter({
                    attribute: FilterValues.location.attribute,
                    value: location,
                }),
            ),
        [refine],
    );
};

export default useRefineLocationFilter;
