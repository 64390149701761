import * as React from 'react';
import { Flex } from 'strat/components';

import { NoPackages, BusinessPackagesHeader } from 'horizontal/businessPackages';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/packagesNotFound.cssm';

type Props = {
    readonly message?: string;
    readonly categoryID: string;
};

const PackagesNotFoundPage = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ message, categoryID }: Props) => (
        <>
            <BusinessPackagesHeader withCategory />
            <Flex column className={styles.noPackagesContainer}>
                <NoPackages categoryID={categoryID} message={message} />
            </Flex>
        </>
    ),
} as const;

export default PackagesNotFoundPage;
