// Note!
//
// This is far from perfect.
// We should maybe upload these icons to S3 and provide a path
// so you can dynamically allocate icons to categories.
//
// For the sake of the deadline, I will not spend days on this.

import * as React from 'react';
import { Flex } from 'strat/components';

import type { LiteCategory } from 'horizontal/types';
import CategoryIconMapping from '@app/branding/categoryIconMapping';

import styles from './styles/categoryIcon.cssm';

const CategoryColorMapping = {
    animals: '#8df1ee',
    pets: '#8df1ee',
    bikes: '#ff645c',
    'sporting-goods-bikes': '#ff645c',
    'books-sports-hobbies': '#f8dd3c',
    'hobbies-music-art-books': '#f8dd3c',
    'business-industrial-agriculture': '#ffe894',
    'electronics-home-appliances': '#9bb9ff',
    'business-industrial': '#9bb9ff',
    'furniture-home-decor': '#d2b982',
    'home-furniture-decor': '#d2b982',
    'home-garden': '#d2b982',
    'fashion-beauty': '#23e5d8',
    jobs: '#cddcff',
    'jobs-services': '#cddcff',
    kids: '#9bb9ff',
    'kids-babies': '#9bb9ff',
    mobiles: '#8df1ee',
    'mobile-phones-tablets-accessories-numbers': '#8df1ee',
    'mobiles-tablets': '#8df1ee',
    'mobile-phones-accessories': '#8df1ee',
    properties: '#f8dd3c',
    'property-for-rent': '#f8dd3c',
    'property-for-sale': '#23e5d8',
    services: '#ff645c',
    vehicles: '#d2b982',
} as const;

type Props = {
    readonly category: LiteCategory;
    readonly onClick?: () => void;
    readonly withBackgroundColor?: boolean;
    readonly containerBackgroundColor?: string;
    readonly iconClassName?: string;
    readonly containerClassName?: string;
};

const CategoryIcon = ({
    category,
    onClick,
    withBackgroundColor,
    containerBackgroundColor,
    iconClassName,
    containerClassName,
}: Props) => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly animals: any; readonly pets: any; readonly bikes: any; readonly 'sporting-goods-bikes': any; readonly 'books-sports-hobbies': any; readonly 'sports-equipments': any; readonly 'sports-equipment': any; ... 20 more ...; readonly vehicles: any; }'.
    if (!CategoryIconMapping[category.slug]) {
        return null;
    }

    return (
        <Flex
            justifyCenter
            alignCenter
            onClick={onClick}
            className={containerClassName || styles.imageContainer}
            style={
                withBackgroundColor
                    ? {
                          backgroundColor:
                              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly animals: "#8df1ee"; readonly pets: "#8df1ee"; readonly bikes: "#ff645c"; readonly 'sporting-goods-bikes': "#ff645c"; readonly 'books-sports-hobbies': "#f8dd3c"; readonly 'hobbies-music-art-books': "#f8dd3c"; ... 19 more ...; readonly vehicles: "#d2b982"; }'.
                              containerBackgroundColor || CategoryColorMapping[category.slug],
                      }
                    : {}
            }
        >
            <img
                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly animals: any; readonly pets: any; readonly bikes: any; readonly 'sporting-goods-bikes': any; readonly 'books-sports-hobbies': any; readonly 'sports-equipments': any; readonly 'sports-equipment': any; ... 20 more ...; readonly vehicles: any; }'.
                src={CategoryIconMapping[category.slug]}
                className={iconClassName || styles.icon}
                alt={category.name}
            />
        </Flex>
    );
};

export default CategoryIcon;
