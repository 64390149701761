import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';

import SitemapList from './sitemapList';
import styles from './styles/sitemapCities.cssm';
import { renderTitle, renderListItems } from './renderList';
import { selectSitemapCategories } from './state/selectors';

const SitemapCities = () => {
    const cities = useSelector(selectSitemapCategories);

    if (!cities || cities.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div>
                <Text.XXLarge bold>
                    <Trans>Cities</Trans>
                </Text.XXLarge>
            </div>
            <Flex className={styles.listItemsContainer}>
                {/* @ts-expect-error - TS7006 - Parameter 'city' implicitly has an 'any' type. */}
                {cities.map((city) => (
                    <SitemapList
                        renderTitle={() => renderTitle(city)}
                        renderListItems={() => renderListItems(city)}
                        containerClass={styles.listContainer}
                        key={`${city.externalID}`}
                    />
                ))}
            </Flex>
        </div>
    );
};

export default SitemapCities;
