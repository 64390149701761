//@ts-nocheck
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectProperty } from 'strat/property/selectors';
import IconBack from '@app/assets/icons/iconBack.svg';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import styles from './styles/backButton.cssm';

type Props = {
    onClick?: () => void;
    className?: string;
    ArrowIcon?: React.FunctionComponent<SVGAttributes<SVGElement>>;
    ariaLabel?: string;
};

/**
 * Temporary function until we have the proper data in place.
 */
const useGoBack = () => {
    const property = useSelector(selectProperty);
    const router = useRouter();

    return () => {
        if (window.history.length > 1) {
            window.history.back();
        } else {
            router.pushRoute(RouteNames.SEARCH, {
                purpose: property.purpose,
                locations: property.locations.slice(-1),
                category: property.category[property.category.length - 1].slug,
            });
        }
    };
};

const BackButton = ({
    onClick,
    className = styles.backButton,
    ArrowIcon = IconBack,
    ariaLabel,
}: Props) => {
    const goBack = useGoBack();

    const onClickCallback = React.useCallback(() => {
        if (onClick) {
            onClick();
        } else {
            goBack();
        }
    }, [onClick, goBack]);

    return (
        <ArrowIcon
            onClick={onClickCallback}
            className={className}
            aria-label={ariaLabel || 'Back to search'}
        />
    );
};

export default BackButton;
