import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';
import type { AdDetailsRouteParams } from 'horizontal/routes/createAdDetailsURL';
import { AdDetailsRouteClass as HorizontalAdDetailsRouteClass } from 'horizontal/routes/adDetailsRoute';

import createAdDetailsURL from './createAdDetailsURL';

class AdDetailsRoute extends HorizontalAdDetailsRouteClass {
    constructor() {
        super(RouteNames.AD_DETAILS, [
            [
                '^/item/',
                {
                    name: 'slug',
                    pattern: '([0-9A-z\xA8-\xFE\u0600-\u06FF-]*)',
                },
                '-iid-',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
            [
                '^/item/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
            // legacy route
            [
                '^/ad/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
            [
                '^/m/item/',
                {
                    name: 'slug',
                    pattern: '([0-9A-z\xA8-\xFE-]+)',
                },
                '-iid-',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
            [
                '^/m/item/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(params: AdDetailsRouteParams): EnhancedLocation {
        return createAdDetailsURL(params);
    }

    decryptExternalID(hash: string): string {
        return hash;
    }
}

export default new AdDetailsRoute();
