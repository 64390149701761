import * as React from 'react';
import { KeycloakChannel, SessionErrorKey } from '@sector-labs/fe-auth-redux';
import { LoadingSpinner, Flex } from 'strat/components';
import { OTPForm, OTPError } from 'strat/auth/keycloak';
import { secondsTillResend } from 'strat/auth/keycloak';

import useProfileEditErrorMessage, { ProfileField } from '../useProfileEditErrorMessage';

import styles from './styles/phoneConfirmationDialogContent.cssm';

type Props = {
    readonly phoneNumber: string;
    readonly onBack: () => void;
    readonly onSubmit: (otp: string) => void;
    readonly onOTPResend: (
        captcha?: string | null | undefined,
        channel?: KeycloakChannel | null | undefined,
    ) => void;
    readonly isLoading: boolean;
    readonly onDialogDismiss: (arg1: boolean) => void;
    readonly isOTPLoaded?: boolean;
};

const PhoneConfirmationDialogContent = ({
    phoneNumber,
    onBack,
    onSubmit,
    onOTPResend,
    isLoading,
    onDialogDismiss,
    isOTPLoaded = true,
}: Props) => {
    const [errorMessage, resetErrorMessage, error] = useProfileEditErrorMessage(ProfileField.PHONE);

    const [otp, setOTP] = React.useState('');
    const [enableResendCode, setEnableResendCode] = React.useState(false);
    const [resendTimer, setResendTimer] = React.useState<any>();

    const clearInput = React.useCallback(() => {
        setOTP('');
        resetErrorMessage();
    }, [setOTP, resetErrorMessage]);

    const disableResendCode = React.useCallback(() => {
        setEnableResendCode(false);
        const timer = setTimeout(() => {
            setEnableResendCode(true);
        }, secondsTillResend * 1000);
        setResendTimer(timer);
    }, [setEnableResendCode]);

    const submitOTP = React.useCallback(() => {
        clearInput();
        onSubmit(otp);
    }, [clearInput, otp, onSubmit]);

    const onBackClicked = React.useCallback(() => {
        clearInput();
        onBack();
    }, [onBack, clearInput]);

    const resendCode = React.useCallback(
        ({
            captcha,
            channel,
        }: {
            captcha: string | null | undefined;
            channel: KeycloakChannel | null | undefined;
        }) => {
            clearInput();
            disableResendCode();
            onOTPResend(captcha, channel);
        },
        [clearInput, onOTPResend, disableResendCode],
    );

    const requestPhoneCall = React.useCallback(
        (captcha?: string | null) => {
            resendCode({ captcha, channel: KeycloakChannel.CALL });
        },
        [resendCode],
    );

    React.useEffect(() => {
        disableResendCode();
    }, [phoneNumber, disableResendCode]);

    React.useEffect(() => {
        // in otp error dialog we should not have any timers running in the background
        // it should be cleared out, otherwise the OTP form dialog will POP up IF the
        // user previously had opened the OTP form when he filled a valid number
        if (error?.name === SessionErrorKey.ALREADY_IN_USE && resendTimer) {
            clearTimeout(resendTimer);
        }
        return resendTimer ? () => clearTimeout(resendTimer) : undefined;
    }, [resendTimer, error]);

    if (!isOTPLoaded) {
        return (
            <div className={styles.container}>
                <Flex justifyCenter fillContainer alignCenter>
                    <LoadingSpinner dark />
                </Flex>
            </div>
        );
    }

    if (error?.name === SessionErrorKey.ALREADY_IN_USE) {
        return (
            <div className={styles.container}>
                <OTPError phoneNumber={phoneNumber} onDialogDismiss={onDialogDismiss} />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <OTPForm
                value={otp}
                onChange={setOTP}
                onSubmit={submitOTP}
                onBack={onBackClicked}
                // @ts-expect-error - TS2322 - Type '({ captcha, channel, }: { captcha: string | null | undefined; channel: KeycloakChannel | null | undefined; }) => void' is not assignable to type '(arg1: { captcha: string | null | undefined; channel: string | null | undefined; }) => void'.
                onRequestNewCode={resendCode}
                onRequestPhoneCall={requestPhoneCall}
                userDescriptor={phoneNumber}
                errorMessage={errorMessage}
                enableResendCode={enableResendCode}
                isLoading={isLoading}
                isPhone
            />
        </div>
    );
};

export default PhoneConfirmationDialogContent;
