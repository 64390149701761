import React from 'react';

import { PopUp } from 'strat/modal';
import IconAlert from '@app/assets/icons/iconAlert.svg';

import styles from './styles/propertyNotAvailablePopup.cssm';

import { PropertyNotAvailable } from '.';

/**
 * Properties for {@see PropertyNotAvailablePopup}.
 */
type Props = {
    /**
     * Indicates whether the dialog should be visible.
     */
    visible: boolean;
    /**
     * Method to call when the visibility of the dialog changes.
     */
    onVisibilityChanged: (visible: boolean) => void;
};

/**
 * Displays a popup saying that property is not available
 */
const PropertyNotAvailablePopup = ({ visible, onVisibilityChanged }: Props): React.ReactElement => (
    <PopUp className={styles.container} visible={visible} onVisibilityChanged={onVisibilityChanged}>
        <IconAlert className={styles.alertIcon} />
        <PropertyNotAvailable className={styles.notAvailable} />
    </PopUp>
);

export default PropertyNotAvailablePopup;
