import { createSelector } from 'reselect';
import { selectCategory, selectLocations } from 'strat/search/selectors';

import { selectCurrentSearchRouteParams } from 'horizontal/search/routing/useCurrentSearchRouteParams';

const selectSavedSearchParams = createSelector(
    selectCurrentSearchRouteParams,
    selectLocations,
    selectCategory,
    (routeSearchParams, filterLocationsData, filterCategoryData) => {
        const locationExternalIDs = filterLocationsData.map((location) => location.externalID);
        const categoryExternalID = filterCategoryData?.externalID;

        return {
            ...routeSearchParams,
            // Beside the search route params, API also needs some external IDs
            // These are useful for DB linking a saved search to the locations and category.
            locationExternalIDs,
            categoryExternalID,
        };
    },
);

export { selectSavedSearchParams };
