import * as React from 'react';
import { Select } from 'strat/components';

import type { Category } from 'horizontal/types';

type Props = {
    readonly name: string;
    readonly categories: Array<Category>;
    readonly onChange: (arg1: string) => void;
    readonly value?: string;
    readonly defaultChoiceLabel: string;
};

const buildChoices = (categories: Array<Category>) =>
    categories.map((category) => {
        return {
            id: parseInt(category.externalID, 10),
            value: category.externalID,
            label: category.name,
        };
    });

const CategorySelector = ({ name, categories, defaultChoiceLabel, value, onChange }: Props) => (
    <Select
        name={name}
        onChange={onChange}
        maxLength={70}
        choices={[...buildChoices(categories)]}
        value={value}
        placeholder={defaultChoiceLabel}
        stretch
    />
);

export default CategorySelector;
