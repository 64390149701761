import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import AttachmentImage from 'horizontal/assets/icons/iconAttachment.svg';
import type { ChatMessageFilesContent } from 'horizontal/chat/types';

import styles from './styles/attachmentMessage.cssm';

type Props = {
    readonly attachments: ChatMessageFilesContent;
    readonly className: string;
    readonly wrapperClassName: string;
    readonly renderFooter: () => React.ReactElement;
};

const AttachmentMessage = ({ attachments, className, wrapperClassName, renderFooter }: Props) => {
    return (
        <Flex column alignCenter noShrink className={wrapperClassName}>
            <div
                className={classNames(styles.container, className)}
                aria-label="Attachment message"
            >
                <Flex column>
                    {attachments.map(({ url, name }) => (
                        <pre className={styles.content}>
                            <a key={url} href={url}>
                                <Flex alignCenter>
                                    <AttachmentImage className={styles.icon} />
                                    <Text.Regular underlined>{name}</Text.Regular>
                                </Flex>
                            </a>
                        </pre>
                    ))}
                </Flex>
                {renderFooter()}
            </div>
        </Flex>
    );
};

export default React.memo<Props>(AttachmentMessage);
