import * as React from 'react';

import Header from './header';
import BackButton from './backButton';
import BaseDialog from './dialog';
import styles from './styles/dialogWithHeader.cssm';

type Props = React.ComponentProps<typeof BaseDialog> & {
    readonly title?: React.ReactNode;
    readonly onBack: () => void;
    readonly renderRight?: () => React.ReactElement;
    readonly contentClassName?: string;
};

const DialogWithHeader = ({
    visible,
    onVisibilityChanged,
    title,
    onBack,
    renderRight,
    children,
    contentClassName,
    ...otherProps
}: Props) => (
    <BaseDialog visible={visible} onVisibilityChanged={onVisibilityChanged} {...otherProps}>
        <Header compact>
            <BackButton onBack={onBack} />
            {title && <span className={styles.title}>{title}</span>}
            {renderRight && <div className={styles.headerRight}>{renderRight()}</div>}
        </Header>
        <div className={contentClassName || styles.content}>{children}</div>
    </BaseDialog>
);

export default DialogWithHeader;
