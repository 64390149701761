const crypto = process.env.IS_BROWSER ? null : require('crypto');

export const convertInt = (int: number): string | null | undefined => {
    if (process.env.IS_BROWSER || crypto === null) {
        console.error('Illegal browser operation');
        return null;
    }

    const uuid = crypto.createHash('sha256').update(int.toString()).digest('hex').substring(0, 36);

    const s = (start: number, end: number) => uuid.slice(start, end);

    return `${s(0, 8)}-${s(8, 12)}-4${s(13, 16)}-8${s(17, 20)}-${s(20, 32)}`;
};
