// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import type { FetchAction, FetchState } from 'strat/fetcher';
import FavoritesAPI from 'strat/api/favoritesAPI';
import { selectActiveUser } from 'strat/user/session';
import { logError } from 'strat/error/log';

import { selectCreationState } from './selectors';

export type FetchFavoritesParams = Record<any, any>;
export type CreationStateType = {
    loading: boolean;
    adExternalID?: string;
    errorCode?: number;
};
type FavoritesState = FetchState & {
    creation: CreationStateType;
};

const Actions = Object.freeze({
    CREATION_LOADING: 'FAVORITES/CREATION/LOADING',
    CREATION_SUCCESS: 'FAVORITES/CREATION/SUCCESS',
    CREATION_ERROR: 'FAVORITES/CREATION/ERROR',
    CLEAR_ERROR: 'FAVORITES/CREATION/CLEAR_ERROR',
});

type FavoritesAction =
    | {
          type: 'FAVORITES/CREATION/ERROR';
          errorCode: number;
      }
    | {
          type: 'FAVORITES/CREATION/LOADING';
          adExternalID: string;
      }
    | {
          type: 'FAVORITES/CREATION/SUCCESS';
          data: string[];
      }
    | {
          type: 'FAVORITES/CREATION/CLEAR_ERROR';
      }
    | FetchAction;

/**
 * Fetcher factory for fetching favorite ads external ids.
 * The content for ads will is fetched using search indexes.
 */
const factory = new FetcherFactory(
    'favorites',
    (params: FetchFavoritesParams, state: any) => {
        const profile = selectActiveUser(state);
        const userExternalID = profile?.externalID;

        if (!userExternalID) {
            return Promise.resolve({ status: 200, data: [] });
        }

        return new FavoritesAPI(state.i18n.language).favorites(userExternalID).then((response) => {
            if (response.status !== 200) {
                return response;
            }

            // @ts-expect-error - TS7006 - Parameter 'favorite' implicitly has an 'any' type.
            return { ...response, data: response.data.map((favorite) => favorite.adExternalID) };
        });
    },
    { successCodes: [200], skipParamsCheck: true },
);

/**
 * Action creator for fetching favoriteAdsExternalIDs from
 * the back-end.
 */
const fetchFavoriteAds = factory.creator();
const favoritesBaseReducer = factory.reducer();
const defaultState = {
    ...buildDefaultState(),
    creation: { loading: false },
} as const;
/**
 * Reducer for storing and handling favorites.
 */
const favoritesReducer = (
    state: FavoritesState | null | undefined = defaultState,
    action: FavoritesAction,
) => {
    switch (action.type) {
        case Actions.CREATION_LOADING:
            // $FlowIgnore[prop-missing]
            // @ts-expect-error - TS2339 - Property 'adExternalID' does not exist on type 'FavoritesAction'.
            return { ...state, creation: { loading: true, adExternalID: action.adExternalID } };
        case Actions.CREATION_SUCCESS:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'data' does not exist on type 'FavoritesAction'.
                data: action.data,
                creation: { loading: false },
            };
        case Actions.CREATION_ERROR:
            return {
                ...state,
                creation: {
                    // @ts-expect-error - TS2531 - Object is possibly 'null'.
                    ...state.creation,
                    loading: false,
                    // $FlowIgnore[prop-missing]
                    // @ts-expect-error - TS2339 - Property 'errorCode' does not exist on type 'FavoritesAction'.
                    errorCode: action.errorCode,
                },
            };
        case Actions.CLEAR_ERROR:
            return { ...state, creation: { loading: false } };

        default:
            return {
                creation: state?.creation || defaultState.creation,
                ...favoritesBaseReducer(state, action),
            };
    }
};

const toggleFavoriteAd =
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    (adExternalID: string, onSuccess: () => void) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const { loading } = selectCreationState(state);
        // avoid toggle multiple times very fast
        if (loading) {
            return;
        }

        dispatch({ type: Actions.CREATION_LOADING, adExternalID });

        const profile = selectActiveUser(state);
        const userExternalID = profile?.externalID;

        if (!userExternalID) {
            return;
        }

        new FavoritesAPI(state.i18n.language)
            .toggleFavorite(userExternalID, adExternalID)
            .then(({ data, status }) => {
                if (status === 200) {
                    onSuccess();
                    dispatch({
                        type: Actions.CREATION_SUCCESS,
                        // @ts-expect-error - TS7006 - Parameter 'ad' implicitly has an 'any' type.
                        data: data.map((ad) => ad.adExternalID),
                    });
                } else {
                    dispatch({ type: Actions.CREATION_ERROR, errorCode: status });
                }
            })
            .catch((e) => {
                logError({
                    e,
                    msg: 'Failed to toggle favorite ad',
                    context: {
                        userExternalID,
                        adExternalID,
                    },
                });

                dispatch({ type: Actions.CLEAR_ERROR });
            });
    };

export { Actions, fetchFavoriteAds, toggleFavoriteAd };
export default favoritesReducer;
