//@ts-nocheck
/**
 * Available platform actions.
 */
const Actions = Object.freeze({
    SET_PLATFORM: 'PLATFORM/SET',
});

/**
 * Default state for the platform.
 */
const defaultState = null;

/**
 * Reducer for the platform.
 */
const platformReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_PLATFORM:
            return {
                platform: action.platform,
                os: action.os,
                browser: action.browser,
                supportsWebp: action.supportsWebp,
            };

        default:
            return state;
    }
};

/**
 * Sets the active platform.
 */
const setPlatform = (os: any, platform: any, browser: any, supportsWebp: any) => ({
    type: Actions.SET_PLATFORM,
    os,
    platform,
    browser,
    supportsWebp,
});

export { setPlatform };

export default platformReducer;
