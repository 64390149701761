import * as React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import EMPTY_ARRAY from 'strat/empty/array';
import { selectRouteParams } from 'strat/routes/selectors';
import { FetchState } from 'strat/fetcher';

/**
 * Provides some useful data for paginating a list of objects.
 * */
const usePagination = (objectSelector: (arg1: any) => FetchState, pageSize: number) => {
    const itemsSelector = React.useMemo(
        () =>
            createSelector(
                objectSelector,
                (fetchedData) => fetchedData.data?.results || EMPTY_ARRAY,
            ),
        [objectSelector],
    );
    const itemsCountSelector = React.useMemo(
        () => createSelector(objectSelector, (fetchedData) => fetchedData.data?.count || 0),
        [objectSelector],
    );

    const areItemsLoadingSelector = React.useMemo(
        () => createSelector(objectSelector, (fetchedData) => fetchedData.loading),
        [objectSelector],
    );

    const areItemsLoadedSelector = React.useMemo(
        () => createSelector(objectSelector, (fetchedData) => fetchedData.loaded),
        [objectSelector],
    );

    const errorSelector = React.useMemo(
        () => createSelector(objectSelector, (fetchedData) => fetchedData.error),
        [objectSelector],
    );

    const items = useSelector(itemsSelector);
    const count = useSelector(itemsCountSelector);
    const isLoading = useSelector(areItemsLoadingSelector);
    const loaded = useSelector(areItemsLoadedSelector);
    const error = useSelector(errorSelector);

    const params = useSelector(selectRouteParams);
    const activePage = parseInt(params.page, 10) || 1;
    const totalPages = Math.ceil(count / pageSize);

    return { items, count, isLoading, loaded, error, activePage, totalPages };
};

export default usePagination;
