import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

import { AgencyRoles, LiteCategory } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

const selectAd = (state: GlobalState) => state.ad?.data;

const selectAdID = createSelector(selectAd, (ad) => ad?.id);

const selectAdExternalID = createSelector(selectAd, (ad) => ad?.externalID);

const selectAdAgencyRoles = createSelector(
    selectAd,
    (ad) => ad?.agency?.roles || (EMPTY_ARRAY as Values<Readonly<typeof AgencyRoles>>[]),
);

const selectAdCategories = createSelector(
    selectAd,
    (ad) => ad?.category || (EMPTY_ARRAY as LiteCategory[]),
);

const selectAdCategoryExternalID = createSelector(
    selectAdCategories,
    (categories) => categories[categories.length - 1]?.externalID,
);

const selectAdContactInfoRoles = createSelector(selectAd, (ad) => ad?.contactInfo?.roles || null);

const selectAdPublicContactInfo = createSelector(selectAd, (ad) => ad?.contactInfo || null);

const selectAdAgents = createSelector(selectAd, (ad) => ad?.agents || null);

export {
    selectAd,
    selectAdID,
    selectAdExternalID,
    selectAdCategories,
    selectAdCategoryExternalID,
    selectAdAgencyRoles,
    selectAdContactInfoRoles,
    selectAdPublicContactInfo,
    selectAdAgents,
};
