import * as React from 'react';
import type { FormikTouched, FormikValues } from 'formik';

type Field = {
    readonly attribute: string;
};

const useTouchedFields = <T extends Field>(
    fields: Array<T>,
    touched: FormikTouched<FormikValues>,
    isSubmitting: boolean,
) => {
    const [didSubmit, setDidSubmit] = React.useState(false);

    React.useEffect(() => {
        if (!isSubmitting) {
            return;
        }

        setDidSubmit(true);
    }, [isSubmitting]);

    return React.useMemo(() => {
        if (didSubmit) {
            return fields.reduce<Record<string, any>>((acc, field) => {
                acc[field.attribute] = true;
                return acc;
            }, {});
        }

        return touched;
    }, [fields, touched, didSubmit]);
};

export default useTouchedFields;
