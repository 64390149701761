import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import IconArrowDown from '@app/assets/icons/iconArrowDown.svg';
import { Flex } from 'strat/components';
import { LocalCurrency, LocalPrice } from 'strat/i18n/money';
import { PROJECT_PRICE_FORMATTING_OPTIONS } from 'strat/project';

import styles from './styles/collapsibleUnitGroup.cssm';

type Props = {
    isDisabled?: boolean;
    isExpanded?: boolean;
    onClick?: () => void;
    renderTitle: () => React.ReactElement;
    startingPrice?: number | null;
    children: React.ReactElement;
};

const CollapsibleUnitGroup = ({
    isDisabled,
    isExpanded,
    onClick,
    renderTitle,
    children,
    startingPrice,
}: Props) => (
    <Flex column className={styles.container}>
        <Flex
            alignCenter
            justifySpaceBetween
            className={classNames(styles.header, {
                [styles.disabled]: isDisabled,
            })}
            onClick={!isDisabled ? onClick : undefined}
        >
            <Flex alignCenter className={styles.titleContainer}>
                {renderTitle && renderTitle()}
            </Flex>
            <Flex alignCenter className={styles.arrowContainer}>
                {startingPrice && (
                    <Flex className={styles.priceContainer}>
                        <div className={styles.startingText}>
                            <Trans>From</Trans>
                        </div>
                        <LocalCurrency />{' '}
                        <LocalPrice
                            price={startingPrice}
                            options={PROJECT_PRICE_FORMATTING_OPTIONS}
                        />
                    </Flex>
                )}
                {!isDisabled && (
                    <IconArrowDown
                        className={classNames(styles.arrow, {
                            [styles.expanded]: isExpanded,
                        })}
                    />
                )}
            </Flex>
        </Flex>
        <div className={classNames(styles.content, { [styles.expandedContent]: isExpanded })}>
            {children}
        </div>
    </Flex>
);

export default CollapsibleUnitGroup;
