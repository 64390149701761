/**
 * Function used to get the path of an event.
 * Returns an array with all DOM elements affected by an event.
 * The function serves as a polyfill for
 * [`Event.composedPath()`](https://dom.spec.whatwg.org/#dom-event-composedpath).
 */
export default (event: any): Array<any> => {
    let path = (event.composedPath && event.composedPath()) || event.path;
    const target = event.target;

    if (path != null) {
        // Safari doesn't include Window, and it should.
        path = path.indexOf(window) < 0 ? path.concat([window]) : path;
        return path;
    }

    if (target === window) {
        return [window];
    }

    // @ts-expect-error - TS7023 - 'getParents' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
    function getParents(node: any, memo: undefined | Array<any>) {
        const newMemo = memo || [];
        const parentNode = node.parentNode;

        if (!parentNode) {
            return newMemo;
        }
        return getParents(parentNode, newMemo.concat([parentNode]));
    }

    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    return [target].concat(getParents(target)).concat([window]);
};
