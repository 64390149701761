import * as React from 'react';
import { useSelector } from 'react-redux';
import { DropDownMenu } from 'strat/modal';
import {
    WhatsAppShareLink,
    FacebookShareLink,
    FBMessengerShareLink,
    TwitterShareLink,
} from 'strat/social/links';
import { Flex } from 'strat/components';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import FacebookIcon from 'horizontal/assets/icons/iconFacebookShare_noinline.svg';
import TwitterIcon from 'horizontal/assets/icons/iconTwitterShare_noinline.svg';
import MessengerIcon from 'horizontal/assets/icons/iconMessengerShare_noinline.svg';
import WhatsappIcon from 'horizontal/assets/icons/iconWhatsappShare_noinline.svg';
import { useCanonicalURL } from 'horizontal/adDetails/seo';

import ShareButton from './shareButton';
import styles from './styles/socialShareDropdown.cssm';

type Props = {
    readonly slug: string;
    readonly title: string;
    readonly externalID: string;
    readonly trigger?: React.ReactElement;
    readonly className?: string;
};

const SocialShareDropdown = ({
    slug,
    title,
    externalID,
    trigger = <ShareButton />,
    className,
}: Props) => {
    const isMobile = useSelector(selectIsMobileLayout);
    const allCanonicalURLs = useCanonicalURL({ slug, externalID });

    const url = allCanonicalURLs?.url;

    const socialLinks = React.useMemo(() => {
        const links: Array<React.ReactNode> = [
            // @ts-expect-error - TS2769 - No overload matches this call.
            <FacebookShareLink url={url} key="facebook-share-link">
                <img src={FacebookIcon} className={styles.icon} alt="Share on Facebook" />
            </FacebookShareLink>,
            // @ts-expect-error - TS2769 - No overload matches this call.
            <TwitterShareLink url={url} text={title} key="twitter-share-link">
                <img src={TwitterIcon} className={styles.icon} alt="Share on Twitter" />
            </TwitterShareLink>,
        ];

        if (isMobile) {
            links.push(
                // @ts-expect-error - TS2769 - No overload matches this call.
                <WhatsAppShareLink url={url} text={title} key="whatsapp-share-link">
                    <img src={WhatsappIcon} className={styles.icon} alt="Share on Whatsapp" />
                </WhatsAppShareLink>,
                // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
                <FBMessengerShareLink url={url} key="messenger-share-link">
                    <img src={MessengerIcon} className={styles.icon} alt="Share on Messenger" />
                </FBMessengerShareLink>,
            );
        }
        return links;
    }, [isMobile, url, title]);

    return (
        <DropDownMenu
            contentClassName={styles.dropdownContent}
            trigger={trigger}
            className={className}
        >
            <span className={styles.tooltipArrow} />
            <Flex className={styles.iconContainer}>{socialLinks}</Flex>
        </DropDownMenu>
    );
};

export default SocialShareDropdown;
