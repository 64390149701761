import { createPushRouteHook } from 'react-true-router';
import { useSelector } from 'react-redux';
import { RouteNames } from 'strat/routes';

import type {
    ApplyAdLimitPackageRouteParams,
    SelectPackageRouteParams,
    SelectPackageSuccessRouteParams,
    SelectSinglePackageRouteParams,
} from 'horizontal/routes';
import { selectBundle } from 'horizontal/adCreation/selectors';
import { AdCreationSourceRoutes } from 'horizontal/constants';

const useBaseNavigateToApplyAdLimit = createPushRouteHook<ApplyAdLimitPackageRouteParams>(
    RouteNames.APPLY_AD_LIMIT_PACKAGE,
);

export const useNavigateToApplyAdLimit = () => {
    const appBundle = useSelector(selectBundle);
    const routeName = AdCreationSourceRoutes[appBundle].APPLY_AD_LIMIT;
    return useBaseNavigateToApplyAdLimit(routeName);
};

export const useNavigateToSinglePackage = createPushRouteHook<SelectSinglePackageRouteParams>(
    RouteNames.SELECT_SINGLE_PACKAGE,
);

export const useNavigateToPackageSelection = createPushRouteHook<SelectPackageRouteParams>(
    RouteNames.SELECT_PACKAGE,
);

const useBaseNavigateToPackageSelectionSuccess =
    createPushRouteHook<SelectPackageSuccessRouteParams>(RouteNames.SELECT_PACKAGE_SUCCESS);

export const useNavigateToPackageSelectionSuccess = () => {
    const appBundle = useSelector(selectBundle);
    const routeName = AdCreationSourceRoutes[appBundle].SELECT_PACKAGE_SUCCESS;
    return useBaseNavigateToPackageSelectionSuccess(routeName);
};

export const useNavigateToAdsManagement = createPushRouteHook(RouteNames.AD_MANAGEMENT);
