import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { FilterValues } from 'strat/search';
import Purpose from 'strat/purpose';
import Category from 'strat/branding/category';
import { AgentCategory } from 'strat/agency/agent/types';

export const getAgentLocationFilterValues = (filters: FilterCollection) => {
    const locationsWithAds: Array<any> | null = filters.getFilterValue(
        FilterValues.agentLocation.attribute,
        null,
    );
    const leaderboardLocations: Array<any> | null = filters.getFilterValue(
        FilterValues.agentLocation.truBrokerAttribute,
        null,
    );
    return locationsWithAds || leaderboardLocations;
};

export const checkHasAgentCategory = (filters: FilterCollection) => {
    const purposeAgent = filters.getFilterValue(FilterValues.purpose.truBrokerAttribute, null);
    const purposeLPV = filters.getFilterValue(FilterValues.purpose.attribute, Purpose.ANY);

    const categoryLPV = filters.getFilterValue(FilterValues.category.attribute, null);
    const rentalCategory: string = filters.getFilterValue(
        FilterValues.agentRentalCategory.attribute,
        FilterValues.agentRentalCategory.default,
    );
    const saleCategory: string = filters.getFilterValue(
        FilterValues.agentSaleCategory.attribute,
        FilterValues.agentSaleCategory.default,
    );
    return (
        !(Purpose.isAny(purposeLPV) && categoryLPV !== Category.ANY) ||
        (Purpose.isForRent(purposeAgent) && rentalCategory !== AgentCategory.ANY) ||
        (Purpose.isForSale(purposeAgent) && saleCategory !== AgentCategory.ANY)
    );
};

export const checkHasAgentPurpose = (filters: FilterCollection) => {
    const purposeAgent = filters.getFilterValue(FilterValues.purpose.truBrokerAttribute, null);
    const purposeLPV = filters.getFilterValue(FilterValues.purpose.attribute, Purpose.ANY);
    return !Purpose.isAny(purposeAgent || purposeLPV);
};

export const checkHasAgentLocation = (
    filters: FilterCollection,
    locationsWithAds: Array<any> | null,
) => {
    const activeInEmirate: string | null = filters.getFilterValue('activeInEmirate', null);
    if (activeInEmirate) {
        return true;
    }

    const agentLocations: Array<any> | null = getAgentLocationFilterValues(filters);
    const lpvLocations: Array<any> | null = filters.getFilterValue(
        FilterValues.location.attribute,
        locationsWithAds,
    );
    const locations = agentLocations || lpvLocations;

    return locations !== null && locations.length >= 1;
};
