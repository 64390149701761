import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import IconTrends from '@app/assets/icons/iconTrends.svg';
import settings from '@app/branding/settings';

import type { PropertyData } from 'strat/property';
import { Section, SubSection } from 'strat/property/layout';
import Loadable, { LoadingSpinner } from 'strat/loadable';
import { renderNavSection } from 'strat/property/propertyContent';
import styles from 'strat/property/styles/propertyContent.cssm';

import isPropertyForSaleOrYearlyRent from './isPropertyForSaleOrYearlyRent';
import PropertyMostPopularCommunitiesSection from './propertyMostPopularCommunitiesSection';

const PropertyAreaTrendsSection = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'trends' */
            'strat/property/propertyAreaTrendsSection'
        ),
    loading: LoadingSpinner,
});

const PropertyPriceComparisonSection = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'trends' */
            'strat/property/propertyPriceComparisonSection'
        ),
    loading: LoadingSpinner,
});

const propertyTrendsSection = (i18n: I18n, property: PropertyData, _: any) => {
    const isPropertyForSaleOrRentedYearly = isPropertyForSaleOrYearlyRent(property);

    return renderNavSection(
        t(i18n)`Trends`,
        <IconTrends className={styles.icon} />,
        <Section className={styles.trendsSection}>
            {isPropertyForSaleOrRentedYearly && (
                <SubSection className={styles.propertyTrendsSubSection}>
                    <PropertyAreaTrendsSection
                        // @ts-expect-error connectors are not properly typed
                        property={property}
                        shiftLastXLabel
                    />
                </SubSection>
            )}
            <SubSection className={styles.propertyTrendsSubSection}>
                <div className={styles.trends}>
                    {isPropertyForSaleOrRentedYearly && (
                        <div className={styles.priceComparison}>
                            <PropertyPriceComparisonSection property={property} />
                        </div>
                    )}
                    <div className={styles.mostPopularCommunities}>
                        <div className={styles.flexContainer}>
                            <PropertyMostPopularCommunitiesSection property={property} />
                        </div>
                    </div>
                </div>
                {isPropertyForSaleOrRentedYearly && (
                    <span className={styles.note}>
                        {t(
                            i18n,
                        )`*These trends are calculated using a proprietary algorithm based on prices advertised on ${settings.getBrandName(
                            i18n,
                        )}.`}
                    </span>
                )}
                <span className={styles.note}>
                    {t(
                        i18n,
                    )`**Popularity is based on searches conducted by users on ${settings.getBrandName(
                        i18n,
                    )} over the last
                            quarter.`}
                </span>
            </SubSection>
        </Section>,
    );
};

export default propertyTrendsSection;
