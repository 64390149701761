import EMPTY_ARRAY from 'strat/empty/array';

import type { GlobalState } from 'horizontal/state';

import { PaymentOrder } from './types';

export type PaymentState = {
    count: number;
    next: string | null;
    previous: string | null;
    results: PaymentOrder[];
};

const selectUserPayments = (state: GlobalState) => state.payments || EMPTY_ARRAY;
const selectUserPaymentOrders = (state: GlobalState) => state.paymentOrders || EMPTY_ARRAY;

export { selectUserPayments, selectUserPaymentOrders };
