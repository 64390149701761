// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';

import type { Ad, LiteAd } from 'horizontal/types';
import { setRecentlyViewedInLocalStorage } from 'horizontal/app';

const RECENTLY_VIEWED_ADS_MAX_STORING_LENGTH = 10;

const Actions = Object.freeze({
    UPDATE_RECENTLY_VIEWED_ADS: 'UPDATE_RECENTLY_VIEWED_ADS',
});

type RecentlyViewedAction = {
    type: 'UPDATE_RECENTLY_VIEWED_ADS';
    ads: any[];
};

const defaultState = {
    ads: [],
} as const;

type State = {
    readonly ads: Array<any>;
};

const recentlyViewedReducer = (
    // @ts-expect-error - TS2322 - Type '{ readonly ads: readonly []; }' is not assignable to type 'State'.
    state: State | null | undefined = defaultState,
    action: RecentlyViewedAction,
) => {
    switch (action.type) {
        case Actions.UPDATE_RECENTLY_VIEWED_ADS:
            return { ...state, ads: action.ads };
        default:
            return state;
    }
};

const listContainsElement = (array: any, item: LiteAd | Ad) =>
    // @ts-expect-error - TS7006 - Parameter 'el' implicitly has an 'any' type.
    array.some((el) => el.id === item.id);

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const setRecentlyViewedAds = (ads: Array<LiteAd | Ad>) => (dispatch: Dispatch) => {
    return dispatch({
        type: Actions.UPDATE_RECENTLY_VIEWED_ADS,
        ads,
    });
};

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const updateRecentlyViewedAds = (ad: LiteAd) => (dispatch: Dispatch, getState: GetState) => {
    const ads = getState().recentlyViewed.ads;
    if (listContainsElement(ads, ad)) {
        return () => null;
    }

    if (ads.length === RECENTLY_VIEWED_ADS_MAX_STORING_LENGTH) {
        ads.shift();
    }
    ads.push(ad);

    setRecentlyViewedInLocalStorage(ads);
    return dispatch({
        type: Actions.UPDATE_RECENTLY_VIEWED_ADS,
        ads: [...ads],
    });
};

const removeUnreachableAdFromRecentlyViewedAds =
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    (unreachableAd: LiteAd | Ad) => (dispatch: Dispatch, getState: GetState) => {
        const ads = getState().recentlyViewed.ads;

        const updatedAds = ads.filter((item: LiteAd | Ad) => item.id !== unreachableAd.id);
        setRecentlyViewedInLocalStorage(updatedAds);
        return dispatch({
            type: Actions.UPDATE_RECENTLY_VIEWED_ADS,
            ads: updatedAds,
        });
    };

export { updateRecentlyViewedAds, setRecentlyViewedAds, removeUnreachableAdFromRecentlyViewedAds };

export default recentlyViewedReducer;
