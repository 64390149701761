/**
 * Detects if the browser supports WEBP images.
 * returns a Promise that resolves to true if there's support for WEBP
 *
 * See: https://stackoverflow.com/questions/5573096/detecting-webp-support
 *      https://github.com/fregante/supports-webp
 */

// @ts-expect-error - TS7034 - Variable 'supportsWebPromise' implicitly has type 'any' in some locations where its type cannot be determined.
let supportsWebPromise = null;

const supportsWebP: () => Promise<boolean> = () => {
    // @ts-expect-error - TS7005 - Variable 'supportsWebPromise' implicitly has an 'any' type.
    if (!supportsWebPromise) {
        supportsWebPromise = new Promise(
            (resolve: (result: Promise<boolean> | boolean) => void) => {
                const image = new Image();
                image.onerror = () => resolve(false);
                image.onload = () => resolve(image.width === 1);
                image.src =
                    'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
            },
        ).catch(() => false);
    }

    // @ts-expect-error - TS7005 - Variable 'supportsWebPromise' implicitly has an 'any' type.
    return supportsWebPromise;
};

export default supportsWebP;
