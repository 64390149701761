import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { SearchRequestOrder, SearchRequestSortType } from '@sector-labs/fe-search-redux/backend';

import { AgentSortByValues } from 'strat/searchComponents';
import { getCubeRankingField } from 'strat/agency/search/form/fetchDataFromProBoard';
import shouldFetchFromCube from 'strat/agency/agent/state/shouldFetchFromCube';

const getSortOptions = (field: string) => ({
    attributes: [{ name: field, order: SearchRequestOrder.ASC }],
    type: SearchRequestSortType.ATTRIBUTES,
    value: field,
});

export const getFetchingOptions = (
    pageSize: number,
    sortBy?: Values<typeof AgentSortByValues> | null,
) => {
    const sortOptions = sortBy
        ? AgentSortByValues.getSortOptionsByValue(sortBy)
        : AgentSortByValues.defaultSortOption;

    return {
        attributesToRetrieve: ['*'],
        hitsPerPage: pageSize,
        exhaustiveNbHits: true,
        sort: sortOptions,
    };
};

const getCubeSortingOptions = (filters: FilterCollection, locationsWithAds: Array<any>) => {
    const rankingField = getCubeRankingField(filters, locationsWithAds);
    return getSortOptions(rankingField);
};

export const getLocationIndexFetchingOptions = (
    pageSize: number,
    sortBy: Values<typeof AgentSortByValues> | null,
    locationsWithAds: Array<any>,
    filters: FilterCollection,
) => {
    const shouldUseCubeSorting = shouldFetchFromCube(filters, locationsWithAds);
    const sortOptions = sortBy ? AgentSortByValues.getSortOptionsByValue(sortBy) : {};
    const sort =
        shouldUseCubeSorting && sortBy !== AgentSortByValues.RANDOM
            ? getCubeSortingOptions(filters, locationsWithAds)
            : sortOptions;

    return {
        attributesToRetrieve: ['*'],
        hitsPerPage: pageSize,
        exhaustiveNbHits: true,
        collapseOptions: {
            fieldName: 'agent_external_id',
        },
        ...(sort && Object.keys(sort).length && { sort }),
    };
};
