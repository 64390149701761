import type { DialogsCollection } from 'strat/dialogs/types';
import BrowserApp from 'strat/app/browser';
import Renderer from 'strat/app/renderer';
import { isRoutePathWithExternallyManagedSession } from 'strat/auth/keycloak/config';
import type { PageCollection } from 'strat/rendering/types';
import { createDefaultStore } from 'strat/state';
import routes from '@app/routes';

import reducers from 'horizontal/state/reducers';
import agencyPortalReducers from 'horizontal/agencyPortal/state';
import { drainStateFromLocalStorage } from 'horizontal/app';
import chatSDKModuleInitializer from 'horizontal/chat/sdk';
import { chatMiddlewares } from 'horizontal/chat/state';

class HorizontalBrowserApp extends BrowserApp {
    createStore(initialState: any) {
        // @ts-expect-error - TS2345 - Argument of type '((_: any) => (next: (arg1: Action) => unknown) => (action: Action) => unknown)[]' is not assignable to parameter of type 'never[]'.
        return createDefaultStore(initialState, reducers, [...chatMiddlewares]);
    }

    onHydrationFinished() {
        this.store.dispatch(drainStateFromLocalStorage());

        const isExternalSession = isRoutePathWithExternallyManagedSession(window.location.pathname);

        chatSDKModuleInitializer.configure({
            getStore: () => this.store,
            client: {
                disableAuthRefreshFetch: isExternalSession,
            },
        });

        // Since using the browser back (or history.back) might restore from
        // bfcache, it also undoes any state changes done on the page.
        // When loading from bfcache, the onload JS event is not triggered.
        // By restoring the state from localStorage, we can work around the issue
        // presisting the data there and reloading on bfcache loads.
        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                if (
                    window.location.pathname === '/payments/viewcart/ALL' ||
                    window.location.pathname.match(new RegExp('payment-selection/[0-9]+$'))
                ) {
                    window.location.reload();
                }
                this.store.dispatch(drainStateFromLocalStorage());
            }
        });

        super.onHydrationFinished();

        chatSDKModuleInitializer.refresh();
    }
}

class HorizontalBrowserAgencyPortalApp extends HorizontalBrowserApp {
    createStore(initialState: any) {
        // @ts-expect-error - TS2345 - Argument of type '((_: any) => (next: (arg1: Action) => unknown) => (action: Action) => unknown)[]' is not assignable to parameter of type 'never[]'.
        return createDefaultStore(initialState, agencyPortalReducers, [...chatMiddlewares]);
    }
}

const createBrowserApp = (pages: PageCollection, dialogs: DialogsCollection = {}): BrowserApp => {
    const browserApp = new HorizontalBrowserApp(routes, pages, dialogs, Renderer);
    browserApp.setupWallpaperTakeover();

    return browserApp;
};

const createBrowserAgencySubApp = (
    pages: PageCollection,
    dialogs: DialogsCollection = {},
): BrowserApp => {
    const browserApp = new HorizontalBrowserAgencyPortalApp(routes, pages, dialogs, Renderer);

    return browserApp;
};

export { createBrowserAgencySubApp };

export default createBrowserApp;
