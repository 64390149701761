import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import Designs from 'strat/branding/designs';
import DroppableFilter from 'strat/search/droppableFilter';
import DropDownBox from 'strat/search/dropDownBox';
import { Button, Text } from 'strat/components';

import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';

import styles from './styles/dropdownWithButtons.cssm';

type Props = {
    readonly title: string;
    readonly titleClassname?: string;
    readonly children: React.ReactElement;
    readonly placeholder: React.ReactElement;
    readonly renderValue?: () => React.ReactElement;
    readonly onResetClick: () => void;
    readonly floatLeft?: boolean;
};

const DropdownWithButtons = ({
    title,
    titleClassname,
    children,
    placeholder,
    renderValue,
    onResetClick,
    floatLeft = false,
}: Props) => {
    const i18n = useI18n();
    const [open, setOpen] = React.useState(false);

    const toggleFilter = (): void => {
        setOpen(!open);
    };

    const closeButton = () => {
        return (
            <div className={styles.buttonWrapper}>
                <Button small stretch onClick={() => setOpen(false)}>
                    <Text.Regular>{t(i18n)`Apply`}</Text.Regular>
                </Button>
            </div>
        );
    };

    const resetButton = () => {
        return (
            <div className={styles.buttonWrapper}>
                <Button secondary small stretch onClick={onResetClick}>
                    <Text.Regular>{t(i18n)`Reset`}</Text.Regular>
                </Button>
            </div>
        );
    };

    return (
        <div onClick={toggleFilter} aria-label={title}>
            <div className={styles.container}>
                {title && (
                    <label className={titleClassname} htmlFor="filter-title">
                        {title}
                    </label>
                )}
                <DroppableFilter
                    renderValue={renderValue}
                    open={open}
                    onOpenChanged={toggleFilter}
                    label={title}
                    placeholder={placeholder}
                    className={styles.input}
                    dropDownInputClassName={styles.textContainer}
                    icon={
                        <IconArrowDown
                            className={classNames(styles.iconArrow, {
                                [styles.iconArrowRotated]: open,
                            })}
                        />
                    }
                >
                    <DropDownBox
                        className={classNames(styles.dropdownContent, {
                            [styles.floatLeft]: floatLeft,
                        })}
                        renderCloseButton={closeButton}
                        renderResetButton={resetButton}
                        design={Designs.FILTER_DESIGN_2022}
                    >
                        {children}
                    </DropDownBox>
                </DroppableFilter>
            </div>
        </div>
    );
};
export default DropdownWithButtons;
