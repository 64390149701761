import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import type { OutputSelector } from 'reselect';
import { FilterKeys, FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { getFilterCollection, selectSearchSortOption } from '@sector-labs/fe-search-redux/state';
import type { GlobalState } from '@sector-labs/fe-search-redux/state';
import FilterValues from 'strat/search/filterValues';
import { selectPageParameters } from 'strat/search/state/selectors';
import type { PageParameters } from 'strat/search/state';
import { selectRouteParams } from 'strat/routes/selectors';

import type { SearchSortOption } from 'horizontal/types';
import type { SearchRouteParams } from 'horizontal/routes';
import { AdProduct } from 'horizontal/types';

// @ts-expect-error - TS2322 - Type '((state: ((...args: unknown[]) => {}) | { [x: string]: any; }, ...params: undefined[]) => { category: unknown; location: unknown; locationRadius: any; freeTextQuery: unknown; minPhotoCount: any; product: unknown; page: unknown; sortOption: SearchSortOption | null | undefined; extraFields: unknown[]; }) & OutputSelec...' is not assignable to type 'OutputSelector<GlobalState, FilterCollection, SearchRouteParams, never>'.
const selectCurrentSearchRouteParams: OutputSelector<
    // @ts-expect-error - TS2344 - Type 'GlobalState' does not satisfy the constraint 'SelectorArray'.
    GlobalState,
    FilterCollection,
    SearchRouteParams
    // @ts-expect-error - TS2769 - No overload matches this call.
> = createSelector(
    [getFilterCollection, selectSearchSortOption, selectPageParameters, selectRouteParams],
    (
        filters: FilterCollection,
        sortOption: SearchSortOption | null,
        pageParameters: PageParameters,
        routeParams,
    ) => ({
        ...pageParameters,
        category: filters.getFilterValue(FilterValues.category.attribute),
        location: filters.getFilterValue(FilterValues.location.attribute),
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        locationRadius: filters.getFilterValue(FilterValues.locationRadius.attribute)?.radius,
        freeTextQuery: filters.getFilterValue(FilterKeys.QUERY),
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        minPhotoCount: filters.getFilterValue(FilterValues.photoCount.attribute)?.min || 0,
        product: Array.isArray(filters.getFilterValue(FilterValues.product.attribute))
            ? AdProduct.ALL
            : filters.getFilterValue(FilterValues.product.attribute),
        page: filters.getFilterValue(FilterKeys.PAGE, 1),
        sortOption,
        remarketingAdID: routeParams?.remarketingAdID,
        extraFields: filters
            .copy([
                FilterValues.category.attribute,
                FilterValues.location.attribute,
                FilterValues.locationRadius.attribute,
                FilterKeys.QUERY,
                FilterKeys.PAGE,
            ])
            .map((filter) => [filter.key, filter.value])
            // @ts-expect-error - TS2345 - Argument of type '(acc: Record<string, any>, [key, value]: [any, any]) => Record<string, any>' is not assignable to parameter of type '(previousValue: Record<string, any>, currentValue: unknown[], currentIndex: number, array: unknown[][]) => Record<string, any>'.
            .reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
                acc[key.replace('extraFields.', '')] = value;
                return acc;
            }, {}),
        marketingParams: { agencyTier: filters.getFilterValue(FilterValues.agencyTier.attribute) },
    }),
);

const useCurrentSearchRouteParams: () => SearchRouteParams = () =>
    // @ts-expect-error - TS2559 - Type 'FilterCollection' has no properties in common with type 'SearchRouteParams'.
    useSelector(selectCurrentSearchRouteParams);

export { selectCurrentSearchRouteParams };
export default useCurrentSearchRouteParams;
