import * as React from 'react';
import classNames from 'classnames';
import { ViewSections } from 'strat/gtm';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import { LiteAd } from 'horizontal/types';

import AdLink from './adLink';
import styles from './styles/gallerySeeMoreOverlay.cssm';

type Props = {
    readonly ad: LiteAd;
    readonly viewSection: Values<typeof ViewSections>;
    readonly coverRoundedCorners: boolean;
};

const GallerySeeMoreOverlay = ({ ad, viewSection, coverRoundedCorners }: Props) => {
    return (
        <Flex
            alignCenter
            justifyCenter
            className={classNames(styles.seeMoreOverlay, {
                [styles.coverRoundedCorners]: coverRoundedCorners,
            })}
        >
            <AdLink ad={ad} isMobile viewSection={viewSection}>
                <Text.Large bold underlined>
                    <Trans>See more</Trans>
                </Text.Large>
            </AdLink>
        </Flex>
    );
};

export default GallerySeeMoreOverlay;
