import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Logo from '@app/branding/logo';
import brandingSettings from '@app/branding/settings';
import { PageWidthWrapper } from 'strat/generic';
import { useNavigateToHome } from 'strat/home/routing';
import CategoryTypeChecker from '@app/branding/category';
import { selectRouteName } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';
import { selectGeoLocationData } from 'strat/user/selectors';
import { LanguageSwitcher } from 'strat/settings';
import { useI18n } from 'strat/i18n/language';
import languageSwitcherStyles from 'strat/settings/styles/languageSwitcher.cssm';
import LocationFilter from '@app/search/location/locationDropdown';
import settings from '@app/branding/settings';
import { Header as BaseHeader } from 'strat/components';
import { Flex } from 'strat/components';
import { KeycloakGoogleOneTap } from 'strat/auth/keycloak';

import HeaderLinksWrapper from '@app/header/headerLinksWrapper';
import type { Location, Category, FreeTextSuggestionSearchParams } from 'horizontal/types';
import SellButton from '@app/navigation/sellButton';
import { useNavigateToSearch, useNavigateToRefinedSearch } from 'horizontal/search/routing';
import {
    useRefineLocationFilter,
    useLocationFilterValue,
    useLocationRadiusFilterValue,
    useWithLocationRadiusFilter,
} from 'horizontal/search/location';
import { selectCategories, useActiveCategoryHierarchy } from 'horizontal/categories';
import { useCategoryFilterValue } from 'horizontal/search/category';
import FreeTextSearchFilter, { useFreeTextQuery } from 'horizontal/search/freeText';
import { UserCenter } from 'horizontal/user';
import { trackFreeTextSearch, trackFreeTextSearchHitClick } from 'horizontal/gtm';
import { getDeepCategoryVars } from 'horizontal/gtm/utils';
import { useFullLocationsHierarchy } from 'horizontal/location';
import NestedLocationDropdown from 'horizontal/search/location/nestedLocationDropdown';

import ActivityIndicator from './activityIndicator';
import styles from './styles/header.cssm';

const Header = ({ withHighlightedVertical }: { withHighlightedVertical?: boolean }) => {
    const i18n = useI18n();
    const navigateToSearch = useNavigateToSearch();
    const navigateToHome = useNavigateToHome();
    const navigateToRefinedSearch = useNavigateToRefinedSearch();

    const routeName = useSelector(selectRouteName);

    const category = useCategoryFilterValue();

    const location = useLocationFilterValue();
    const locationRadius = useLocationRadiusFilterValue();

    const fullLocations = useFullLocationsHierarchy(location?.hierarchy || []);

    const { loading, closestLocation } = useSelector(selectGeoLocationData);

    const refineLocation = useRefineLocationFilter();

    const filteredFreeTextQuery = useFreeTextQuery();
    const [freeTextQuery, setFreeTextQuery] = React.useState<string>(filteredFreeTextQuery);
    const [activeCategory, setActiveCategory] = React.useState<Category | null | undefined>(
        category,
    );
    const withLocationRadiusFilter = useWithLocationRadiusFilter();

    const categories = useSelector(selectCategories);
    const activeCategoryHierarchy = useActiveCategoryHierarchy();
    const isProperty =
        CategoryTypeChecker &&
        activeCategoryHierarchy.find((cat) => CategoryTypeChecker.isOfPropertyType(cat));

    const maxLocationLevel = isProperty ? undefined : brandingSettings.headerMaxLocationLevel;

    React.useEffect(() => {
        setFreeTextQuery(filteredFreeTextQuery);
    }, [filteredFreeTextQuery, setFreeTextQuery]);

    const restrictLocationBasedOnCategory = React.useCallback(() => {
        if (!location) {
            return null;
        }
        const locationDepthLimits = activeCategory?.locationDepthLimits || {};
        // @ts-expect-error - TS2339 - Property 'max' does not exist on type '{}'.
        const maxAllowedLevel = locationDepthLimits.max
            ? // @ts-expect-error - TS2339 - Property 'max' does not exist on type '{}'.
              locationDepthLimits.max
            : brandingSettings.headerMaxLocationLevel;

        if (location.level > maxAllowedLevel) {
            return fullLocations.find((loc) => loc.level === maxAllowedLevel);
        }
        return location;
    }, [activeCategory, location, fullLocations]);

    const search = React.useCallback(() => {
        const restrictedLocation = restrictLocationBasedOnCategory();
        const categoryVars = getDeepCategoryVars(categories, activeCategory?.externalID);
        trackFreeTextSearch(freeTextQuery, categoryVars);
        navigateToSearch({
            location: restrictedLocation,
            locationRadius,
            category: activeCategory,
            freeTextQuery,
        });
    }, [
        navigateToSearch,
        freeTextQuery,
        locationRadius,
        activeCategory,
        restrictLocationBasedOnCategory,
        categories,
    ]);

    const searchWithSearchRouteParams = React.useCallback(
        (searchParams: FreeTextSuggestionSearchParams) => {
            const restrictedLocation = restrictLocationBasedOnCategory();
            let categoryVars = getDeepCategoryVars(categories, searchParams.category?.externalID);
            if (!searchParams.category) {
                categoryVars = getDeepCategoryVars(categories, activeCategory?.externalID);
            }
            trackFreeTextSearchHitClick(searchParams.freeTextQuery, categoryVars);
            navigateToSearch({
                location: restrictedLocation,
                locationRadius,
                category: searchParams.category || activeCategory,
                freeTextQuery: searchParams.freeTextQuery,
            });
        },
        [
            navigateToSearch,
            restrictLocationBasedOnCategory,
            locationRadius,
            activeCategory,
            categories,
        ],
    );

    const searchWithLocation = React.useCallback(
        (selectedLocation?: Location | null) => {
            refineLocation(selectedLocation);

            if (routeName === RouteNames.HOME) {
                navigateToHome();
            } else {
                navigateToRefinedSearch({ location: selectedLocation });
            }
        },
        [refineLocation, navigateToRefinedSearch, navigateToHome, routeName],
    );

    const searchWithoutLocation = React.useCallback(() => {
        refineLocation();

        if (routeName === RouteNames.HOME) {
            navigateToHome();
        } else {
            navigateToRefinedSearch({ location: null });
        }
    }, [refineLocation, navigateToRefinedSearch, navigateToHome, routeName]);

    return (
        <BaseHeader>
            {CONFIG.runtime.ENABLE_OLX_VERTICALS && (
                <HeaderLinksWrapper withHighlightedVertical={withHighlightedVertical} />
            )}
            <PageWidthWrapper className={styles.content}>
                {!CONFIG.runtime.ENABLE_OLX_VERTICALS && <Logo className={styles.logo} />}

                {CONFIG.runtime.NEW_DROPDOWN_LOCATION ? (
                    <div className={styles.locationFilter}>
                        <NestedLocationDropdown
                            location={
                                location || closestLocation || settings.getTopLevelLocation(i18n)
                            }
                            withLocationRadiusFilter={withLocationRadiusFilter}
                            onLocationSelected={searchWithLocation}
                            onLocationRemoved={searchWithoutLocation}
                            onCurrentLocationSelected={searchWithoutLocation}
                            maxLevel={maxLocationLevel}
                            loading={loading}
                        />
                    </div>
                ) : (
                    <div
                        className={classNames(styles.locationFilter, {
                            [styles.extendedLocationFilter]: withLocationRadiusFilter,
                        })}
                    >
                        <LocationFilter
                            // @ts-expect-error - TS2322: Type 'LiteHierarchicalLocation | Location' is not assignable to type 'Location | null | undefined'.
                            location={
                                location || closestLocation || brandingSettings.topLevelLocation
                            }
                            onLocationSelected={searchWithLocation}
                            onCurrentLocationSelected={searchWithoutLocation}
                            loading={loading}
                            withLocationRadiusFilter={withLocationRadiusFilter}
                            searchInputClassName={styles.searchInputContainerBorder}
                            searchIconClassName={styles.searchIconSize}
                            maxLevel={maxLocationLevel}
                        />
                    </div>
                )}
                <div className={styles.freeTextSearch}>
                    <FreeTextSearchFilter
                        query={freeTextQuery}
                        activeCategory={category}
                        onCategoryChanged={setActiveCategory}
                        onQueryChange={setFreeTextQuery}
                        onQuerySelected={searchWithSearchRouteParams}
                        onSubmit={search}
                        searchInputClassName={styles.searchInputContainerBorder}
                    />
                </div>
                <LanguageSwitcher
                    // @ts-expect-error - TS2322 - Type '{ languages: { lang: string; name: string; locales: string; rtl?: boolean | undefined; isDisabled?: boolean | undefined; getLanguagePrefix?: ((requestTranslatedPage: boolean) => string) | undefined; }[]; svgClassName: string; }' is not assignable to type 'IntrinsicAttributes & Props'.
                    languages={brandingSettings.languages}
                    svgClassName={[languageSwitcherStyles.darkFill, styles.languageSwitcher].join(
                        ' ',
                    )}
                />
                <Flex alignCenter className={styles.userCenter}>
                    <UserCenter />
                    <ActivityIndicator className={styles.activityIndicator} />
                    <KeycloakGoogleOneTap />
                </Flex>
                <div className={styles.sellButton}>
                    <SellButton />
                </div>
            </PageWidthWrapper>
        </BaseHeader>
    );
};

export default Header;
