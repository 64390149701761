import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import useI18n from 'strat/i18n/language/useI18n';
import { isCanceled } from 'strat/util';
import { StratAPI } from 'strat/api';
import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';

import { AdState, FullAd, ProductPurchase } from 'horizontal/types';
import { getAdVars } from 'horizontal/gtm/utils';
import useGlobalGTMVars from 'horizontal/gtm/useGlobalGTMVars';
import { useMakeCancelable } from 'horizontal/util';
import { StratAPI as HorizontalAPI } from 'horizontal/api';

import { selectProductPurchasesLoading } from '../selectors';
import { useNavigateToPackageSelectionSuccess } from '../navigation';

const selectFirstAvailablePackageID = (
    packages?: Array<ProductPurchase> | null,
): number | undefined => {
    return packages?.find((item) => item.available)?.id;
};

export const getDefaultSelection = (
    defaultPackageSelection?: ProductPurchase | null,
    packages?: Array<ProductPurchase> | null,
): number | undefined => {
    return defaultPackageSelection?.id ?? selectFirstAvailablePackageID(packages);
};

const useOnSuccessNavigation = () => {
    const navigateToSelectPackageSuccess = useNavigateToPackageSelectionSuccess();

    return React.useCallback(
        (
            ad?: FullAd | null,
            productPackageId?: number,
            navigateOnApplyPurchasedProductSuccess?:
                | ((ad?: FullAd | null, productPurchaseID?: number) => void)
                | null,
        ) => {
            if (navigateOnApplyPurchasedProductSuccess) {
                navigateOnApplyPurchasedProductSuccess(ad, productPackageId);
                return;
            }

            navigateToSelectPackageSuccess({
                adExternalID: (ad as FullAd).externalID,
                packageID: String(productPackageId),
            });
        },
        [navigateToSelectPackageSuccess],
    );
};

const useBoughtPackages = (
    packages?: Array<ProductPurchase> | null,
    ad?: FullAd | null,
    navigateOnApplyPurchasedProductSuccess?:
        | ((ad?: FullAd | null, productPurchaseID?: number) => void)
        | null,
    defaultPackageSelection?: ProductPurchase | null,
) => {
    const i18n = useI18n();
    const onSuccessNavigation = useOnSuccessNavigation();
    const packagesLoading = useSelector(selectProductPurchasesLoading) || !packages;
    const [userSelectedPackage, setSelectedPackage] = React.useState<number | null | undefined>(
        getDefaultSelection(defaultPackageSelection, packages),
    );
    const [errorMessage, setErrorMessage] = React.useState('');
    // Make sure we preselect the first option after packages are loaded.
    const selectedPackage =
        userSelectedPackage || getDefaultSelection(defaultPackageSelection, packages);
    const [ctaLoading, setCTALoading] = React.useState(false);
    const makeCancelable = useMakeCancelable();
    const globalVars = useGlobalGTMVars();

    const trackApplyPackages = React.useCallback(() => {
        trigger(Triggers.APPLY_PACKAGES, {
            ...globalVars,
            ...getAdVars(ad),
            product_id: selectedPackage,
            view_section: ViewSections.BODY,
        });
    }, [ad, selectedPackage, globalVars]);

    const consumeAdLimit = React.useCallback(() => {
        if (!ad || !selectedPackage) {
            return;
        }
        setCTALoading(true);
        makeCancelable(
            new HorizontalAPI().activateAd({
                adExternalID: ad?.externalID,
                productPurchaseID: selectedPackage?.toString(),
            }),
        )
            .then(({ status, data }: { status: number; data: any }) => {
                if (status >= 300) {
                    throw new ContextfulError(`Unexpect status while requesting limits`, {
                        status,
                        data,
                    });
                }

                trackApplyPackages();
                onSuccessNavigation(ad, selectedPackage, navigateOnApplyPurchasedProductSuccess);
                setCTALoading(false);
            })
            .catch((e: unknown) => {
                setCTALoading(false);
                setErrorMessage(t(i18n)`Unexpected error occurred. Please try again later.`);
                logError({
                    e,
                    msg: 'Unable to fetch ad limits',
                    context: {
                        adExternalID: ad?.externalID,
                    },
                });

                if (isCanceled(e)) {
                    // Don't change any state because we're unmounting.
                    return;
                }
            });
    }, [
        makeCancelable,
        ad,
        selectedPackage,
        trackApplyPackages,
        navigateOnApplyPurchasedProductSuccess,
        onSuccessNavigation,
        i18n,
    ]);

    const applyBoughtPackage = React.useCallback(() => {
        setCTALoading(true);
        makeCancelable(
            new StratAPI()
                .applyProduct((ad as FullAd).externalID, {
                    // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string'.
                    productPurchaseID: selectedPackage,
                })
                .then(({ status, data }) => {
                    if (status === 409 && data.message === 'Ad already has product applied') {
                        setErrorMessage(t(i18n)`You already have this service running on your ad.`);
                        return;
                    }
                    if (status === 403) {
                        setErrorMessage(
                            t(
                                i18n,
                            )`The usages of this product type have already been exhausted or you don't have enough credits left`,
                        );
                        return;
                    }
                    if (status === 412) {
                        setErrorMessage(
                            t(i18n)`This product has already been applied less than 5 minutes ago`,
                        );
                        return;
                    }
                    trackApplyPackages();
                    onSuccessNavigation(
                        ad,
                        selectedPackage,
                        navigateOnApplyPurchasedProductSuccess,
                    );
                })
                .finally(() => {
                    setCTALoading(false);
                }),
        );
    }, [
        ad,
        selectedPackage,
        onSuccessNavigation,
        navigateOnApplyPurchasedProductSuccess,
        trackApplyPackages,
        makeCancelable,
        i18n,
    ]);

    const onChoiceClick = React.useCallback(
        (choice: ProductPurchase) => {
            if (choice.available) {
                setSelectedPackage(choice.id);
            }
        },
        [setSelectedPackage],
    );

    const consumeProduct = ad?.state === AdState.LIMITED ? consumeAdLimit : applyBoughtPackage;

    return {
        i18n,
        onChoiceClick,
        consumeProduct,
        applyBoughtPackage,
        selectedPackage,
        loading: packagesLoading || ctaLoading,
        errorMessage,
    };
};

export default useBoughtPackages;
