import { RouteNames } from 'strat/routes';
import { ViewSections } from 'strat/gtm';

import { useSellerProfile, useSellerIsLoading } from 'horizontal/seller/hooks';
import type { UserDTO, AgencyUserDTO } from 'horizontal/dtos';
import { useAgencyProfile, useAgencyIsLoading } from 'horizontal/agency/state';
import { useNavigateToSellerPage } from 'horizontal/seller/routing';
import { useNavigateToAgencyPage } from 'horizontal/agency/routing';
import { SellerProfileRoute, AgencyProfileRoute } from 'horizontal/routes';
import { AgencyType } from 'horizontal/types';

import type { NavigationProps } from '../types';

export type AgencyProfile = {
    user: AgencyUserDTO | null;
    isAgency: true;
};

export type UserProfile = {
    user: UserDTO | null;
    isAgency: false;
};

type ProfileType = {
    viewSection: Values<typeof ViewSections> | null | undefined;
    canonicalURL: string | null | undefined;
    routeName: string | null | undefined;
    navigatePage: (params: NavigationProps) => void | null | undefined;
    agencyType?: Values<typeof AgencyType>;
} & (AgencyProfile | UserProfile);

const useProfile = (): ProfileType => {
    const isLoadingAgency = useAgencyIsLoading();
    const isLoadingSeller = useSellerIsLoading();
    const agencyProfile = useAgencyProfile();
    const agencyLogo = agencyProfile?.logo;
    const seller = useSellerProfile();
    const navigateToAgencyPage = useNavigateToAgencyPage();
    const navigateToSellerPage = useNavigateToSellerPage();
    if (!isLoadingAgency && agencyProfile) {
        return {
            user: {
                ...agencyProfile,
                logo: agencyLogo,
                image: agencyLogo?.id
                    ? {
                          ...agencyLogo,
                      }
                    : null,
            },
            viewSection: ViewSections.AGENCY_PROFILE,
            // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
            canonicalURL: AgencyProfileRoute.createURL({ agencySlug: agencyProfile.slug }).pathname,
            routeName: RouteNames.AGENCY_PROFILE,
            navigatePage: navigateToAgencyPage,
            isAgency: true,
            agencyType: agencyProfile.type,
        };
    } else if (!isLoadingSeller && seller?.externalID) {
        return {
            user: seller,
            viewSection: ViewSections.SELLER_PROFILE,
            // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
            canonicalURL: SellerProfileRoute.createURL({ externalID: seller.externalID }).pathname,
            routeName: RouteNames.SELLER_PROFILE,
            // @ts-expect-error - TS2322 - Type '(params: SellerProfileRouteParams) => void' is not assignable to type '(params: Partial<AgencyNavigationProps & SellerNavigationProps>) => void | null | undefined'.
            navigatePage: navigateToSellerPage,
        };
    }
    return {
        user: null,
        viewSection: null,
        canonicalURL: null,
        routeName: null,
        // @ts-expect-error - TS2322 - Type 'null' is not assignable to type '(params: Partial<AgencyNavigationProps & SellerNavigationProps>) => void | null | undefined'.
        navigatePage: null,
        isAgency: false,
    };
};

export default useProfile;
