import * as React from 'react';
import classNames from 'classnames';
import { MapStyleTypes, StaticMap } from 'strat/map';

import type { ChatMessageLocationContent } from 'horizontal/chat/types';

import styles from './styles/locationMessage.cssm';

type Props = {
    readonly location: ChatMessageLocationContent;
    readonly className: string;
    readonly renderFooter: () => React.ReactElement;
};

const STATIC_MAP_DIMENSION = 200;
const STATIC_MAP_ZOOM = 14;

const LocationMessage = ({ location, className, renderFooter }: Props) => {
    const { lat, lng } = location;
    return (
        <div className={classNames(styles.container, className)} aria-label="Location message">
            <a
                href={`https://www.google.com/maps/place/${lat},${lng}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className={styles.content}>
                    <StaticMap
                        geolocation={{ lat, lng }}
                        options={{
                            type: MapStyleTypes.STREETS,
                            zoom: STATIC_MAP_ZOOM,
                            width: STATIC_MAP_DIMENSION,
                            height: STATIC_MAP_DIMENSION,
                        }}
                        className={styles.staticMap}
                        marker={{ lat, lng }}
                        highDensity
                    />
                </div>
            </a>
            <div className={styles.footer}>{renderFooter()}</div>
        </div>
    );
};

export default React.memo<Props>(LocationMessage);
