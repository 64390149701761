/**
 * Available actions for the URL updater.
 */
const Actions = Object.freeze({
    UPDATE_URL: 'SEARCH/UPDATE_URL',
    TOGGLE_URL_UPDATER: 'SEARCH/TOGGLE_URL_UPDATER',
});

/**
 * Default options for search state
 * Replace - should or should not replace the last entry on the history stack
 */
const defaultOptions = {
    replace: false,
} as const;

/**
 * Default state for the URL updater.
 */
const defaultState = {
    id: 0,
    enabled: false,
    params: null,
    options: defaultOptions,
} as const;

export type URLState = {
    id: number;
    enabled: boolean;
    params: Record<string, any> | null;
    options: {
        replace: boolean;
    };
};

/**
 * Reducer for the URL updater.
 */
const urlUpdaterReducer = (state: URLState = defaultState, action: any) => {
    switch (action.type) {
        case Actions.UPDATE_URL:
            return {
                ...state,
                id: state.id + 1,
                params: action.params,
                options: action.options,
            };

        case Actions.TOGGLE_URL_UPDATER:
            return {
                ...state,
                enabled: action.enabled,
            };

        default:
            return state;
    }
};

/**
 * Updates the current search URL.
 */
const updateURL = (params: any, options = defaultOptions) => ({
    type: Actions.UPDATE_URL,
    params,
    options,
});

/**
 * Enables the URL updater.
 */
const enableURLUpdater = () => ({
    type: Actions.TOGGLE_URL_UPDATER,
    enabled: true,
});

/**
 * Disable the URL updater.
 */
const disableURLUpdater = () => ({
    type: Actions.TOGGLE_URL_UPDATER,
    enabled: false,
});

export { updateURL, enableURLUpdater, disableURLUpdater };

export default urlUpdaterReducer;
