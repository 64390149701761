import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRouter } from 'react-true-router';
import Logo from '@app/branding/logo';
import useHeaderLinks from '@app/header/useHeaderLinks';
import Category from '@app/branding/category';
import { selectRouteName } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';

import { useCategoryFilterValue } from 'horizontal/search/category';
import { useTrackClickVertical } from 'horizontal/gtm';
import { useAd } from 'horizontal/ad/state';

import shouldHighlightVertical from './shouldHighlightVertical';
import HeaderIconLink from './headerIconLink';
import styles from './styles/headerLinks.cssm';

const defaultRenderLogo = (defaultClassname: string) => <Logo className={defaultClassname} small />;

type Props = {
    withHighlightedVertical?: boolean;
    renderLogo?: (defaultClassname: string) => void;
};

const HeaderLinks = ({ withHighlightedVertical, renderLogo = defaultRenderLogo }: Props) => {
    const headerLinks = useHeaderLinks();
    const trackClickVertical = useTrackClickVertical();
    const categoryFilterValue = useCategoryFilterValue();
    const ad = useAd();
    const routeName = useSelector(selectRouteName);
    const router = useRouter();
    const category =
        routeName === RouteNames.SEARCH ? categoryFilterValue : ad?.category?.slice(-1)[0];

    const isHighlightedVertical =
        withHighlightedVertical &&
        (router.context?.match.params.vertical ||
            Category.isOfCarsOrCarsOnInstallmentsType(category) ||
            Category.isOfPropertySubtype(category));

    return (
        <>
            {renderLogo(classNames(styles.link, { [styles.noHighlight]: isHighlightedVertical }))}
            {headerLinks.map((headerLink, index) => {
                const isHighlighted = shouldHighlightVertical(
                    headerLink,
                    withHighlightedVertical,
                    category,
                    // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2345 - Argument of type '[string]' is not assignable to parameter of type 'string'.
                    router.context.match.params.vertical,
                );
                return (
                    headerLink.renderIcon && (
                        <HeaderIconLink
                            renderIcon={headerLink.renderIcon}
                            link={headerLink.link}
                            // @ts-expect-error - TS2322 - Type 'string | boolean | null | undefined' is not assignable to type 'boolean'.
                            isHighlighted={isHighlighted}
                            linkClassName={classNames(styles.link, {
                                [styles.highlight]: isHighlighted,
                            })}
                            key={`headerlink-${index}`}
                            onClick={() => trackClickVertical(headerLink.name)}
                        />
                    )
                );
            })}
        </>
    );
};

export default HeaderLinks;
