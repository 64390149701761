import isEqual from 'lodash/isEqual';
// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';

import { APIError, APIErrorReason, isAPIError } from 'strat/api/error';

export type FetchActionContext = Record<string, string>;

export type FetcherActions = {
    preload: string;
    start: string;
    success: string;
    enable: string;
    disable: string;
    error: string;
    clear: string;
};

export type FetchContext<TParams> = {
    params: TParams;
    state: GetState;
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch;
    ssr?:
        | {
              readonly cookies?:
                  | {
                        [key: string]: string;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

/**
 * Method that can be invoked to fetch data.
 */
export type FetchMethod = (
    params: any,
    state: any,
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch,
) => Promise<{
    data: any;
    status: number;
}>;

/**
 * Parameters that can be passed when fetching.
 */
export type FetchParameters = any;

/**
 * Data that is fetched.
 */
export type FetchData = any;

/**
 * Error that could occur on fetching.
 */
export type FetchError = {
    name: string;
    status: number;
};

/**
 * Redux actions.
 */
export type FetchAction =
    | {
          type: 'error';
          params: FetchParameters;
          data: FetchData;
          error: FetchError;
      }
    | {
          type: string;
          params: FetchParameters;
          data: FetchData;
          error: null;
      };

/**
 * Signature for the action creator.
 */
export type FetchActionCreator<TParams, TData = any> = (
    params?: TParams,
    context?: FetchActionContext,
) => () => Promise<{
    data: TData;
    status: number;
}>;

/**
 * Signature for the Redux state.
 */
export type FetchState<FD = FetchData, FP = FetchParameters, FE = FetchError> = {
    loading: boolean;
    loaded: boolean;
    preloaded: boolean;
    params: null | FP;
    data: null | FD;
    error: null | FE;
    enabled: boolean;
};

/**
 * Reducer signature.
 */
export type FetchReducer<FS, FA> = (state: FS, action: FA) => FS;

/**
 * Post-processor for fetched data.
 */
export type FetchProcessor = (
    data: null | FetchData,
    previousData: null | FetchData,
) => null | FetchData;

/**
 * Additional options that can be specified.
 */
export type FetchOptions = {
    /**
     * HTTP Status codes that indicate success.
     */
    successCodes: Array<number>;
    /**
     * Extracting parameters from the state.
     */
    getParams: (params: FetchParameters, getState: GetState) => FetchParameters;
    /**
     * Whether server errors (more specifically 500s) should be caught.
     */
    catchServerErrors?: boolean;
    /**
     * Specifies if we can refetch even though params are not changed. Useful for refreshing backend data.
     * @type {boolean}
     */
    skipParamsCheck?: boolean;
};

const buildDefaultState = (): FetchState => ({
    loading: false,
    loaded: false,
    preloaded: false,
    enabled: true,
    params: null,
    data: null,
    error: null,
});

const pendingPromises: Record<string, any> = {};

const removePendingPromise = (key: string) => delete pendingPromises[key];

const getPendingPromise = (keyElements: Array<any>): Promise<any> => {
    const key = JSON.stringify(keyElements);
    return pendingPromises[key];
};

const addPendingPromise = (pendingPromise: Promise<any>, keyElements: Array<any>): void => {
    const key = JSON.stringify(keyElements);
    pendingPromises[key] = pendingPromise;
    pendingPromise.then(() => removePendingPromise(key)).catch(() => removePendingPromise(key));
};

/**
 * Default state.
 */
const defaultState = buildDefaultState();

/**
 * Small framework for creating Redux action creators
 * and reducers for fetching data from a Rest API.
 *
 * Takes care of creating three actions (start, success, error)
 * , fetching itself. It also prevents re-fetching and instead
 * serves back previously fetched data.
 *
 * @deprecated Use FetcherFactoryWithContext instead.
 */
class FetcherFactory {
    /**
     * Name of this fetcher factory.
     */
    name: string | Array<string>;

    /**
     * Method to invoke to fetch data.
     */
    fetcher: FetchMethod;

    /**
     * Names for actions that can be dispatched.
     */
    actions: FetcherActions;

    /**
     * Additional (optional) options the user can specify.
     */
    options: FetchOptions;

    /**
     * Initializes a new instance of the {@see FetcherFactory}.
     * @param name The name of this fetcher factory. Used to determine where in the store
     * this reducer is attached.
     *
     * If you're working with `combineReducers`, make sure to specify the full path to the reducer,
     * using an array of keys (e.g. if your store looks like this:
     *
     * - root
     *   - module
     *     - fetcher
     *
     * the name provided to the FetcherFactory constructor should be `['module', 'fetcher']`).
     *
     * @param fetcher Method to invoke to fetch data.
     * @param options Additional (optional) options.
     */
    constructor(name: string | Array<string>, fetcher: FetchMethod, options: any = {}) {
        this.name = name;
        this.fetcher = fetcher;
        const successCodes = options.successCodes || [];

        this.options = {
            getParams: null,
            ...options,
            // default success codes cannot be overriden
            successCodes: [...successCodes, 200, 304],
        };

        const actionName = Array.isArray(this.name)
            ? this.name.join('/').toUpperCase()
            : this.name.toUpperCase();
        this.actions = {
            preload: `FETCH/${actionName}/PRELOAD`,
            start: `FETCH/${actionName}/START`,
            success: `FETCH/${actionName}/SUCCESS`,
            enable: `FETCH/${actionName}/ENABLE`,
            disable: `FETCH/${actionName}/DISABLE`,
            error: `FETCH/${actionName}/ERROR`,
            clear: `FETCH/${actionName}/CLEAR`,
        };
    }

    /**
     * Gets a Redux action creator that fetches data.
     */
    creator(): FetchActionCreator<any> {
        return (params: FetchParameters = {}, context: Partial<FetchActionContext> = {}) =>
            this.thunk.bind(this, params, context);
    }

    /**
     * Gets a Redux reducer to storing fetched data.
     */
    // eslint-disable-next-line
    reducer(processor: null | FetchProcessor = null): FetchReducer<any, any> {
        return (state: FetchState = defaultState, action: FetchAction): FetchState => {
            switch (action.type) {
                case this.actions.preload:
                    return {
                        ...defaultState,
                        preloaded: true,
                        loaded: true,
                        params: action.params,
                        data: processor ? processor(action.data, defaultState.data) : action.data,
                    };

                case this.actions.start:
                    return {
                        ...state,
                        loading: true,
                        loaded: false,
                        params: action.params,
                    };

                case this.actions.success:
                    return {
                        ...state,
                        loading: false,
                        loaded: true,
                        preloaded: false,
                        params: action.params,
                        data: processor ? processor(action.data, state.data) : action.data,
                        error: null,
                    };

                case this.actions.enable:
                    return {
                        ...state,
                        enabled: true,
                    };

                case this.actions.disable:
                    return {
                        ...defaultState,
                        enabled: false,
                    };

                case this.actions.error:
                    return {
                        ...state,
                        loaded: false,
                        loading: false,
                        error: action.error,
                    };

                case this.actions.clear:
                    return defaultState;

                default:
                    return state;
            }
        };
    }

    /**
     * Action creator thunk.
     */
    thunk(
        params: FetchParameters,
        actionContext: FetchActionContext,
        dispatch: Dispatch<FetchAction>,
        getState: GetState,
    ): Promise<any> {
        let state = getState();

        if (Array.isArray(this.name)) {
            this.name.forEach((part) => {
                state = state[part];
            });
        } else {
            state = state[this.name];
        }

        if (Object.keys(state).length === 0) {
            state = defaultState;
        }

        let allParams = { ...params };
        if (this.options.getParams) {
            allParams = {
                ...allParams,
                ...this.options.getParams(params, getState),
            };
        }

        // when this fetcher is disabled, we just act like
        // we're fetching nothing
        if (!state.enabled) {
            return Promise.resolve({ data: null, status: 200 });
        }

        // if the specified parameters are the same
        // as the ones in the current state then we
        // don't have to re-fetch
        if (!state.error?.authSchemes && this.checkParamsEquality(allParams, state)) {
            const pendingPromise = getPendingPromise([this.name, allParams]);
            if (state.loading && pendingPromise) {
                return pendingPromise;
            }

            return new Promise((resolve, reject: (error?: any) => void) => {
                if (!state.error) {
                    resolve({ data: state.data, status: 200 });
                } else {
                    reject(state.error);
                }
            });
        }

        this.dispatchFetchStart(dispatch, allParams);

        const pendingPromise = this.callFetchMethod({
            ...actionContext,
            params: allParams,
            state: getState(),
            dispatch,
        })
            .then(({ data, status }) => {
                if (!this.options.successCodes.includes(status)) {
                    const name = this.name.toString();

                    throw new APIError(`Fetcher ${name} returned a non-success status code`, {
                        name,
                        reason: APIError.reasonFromStatusCode(status),
                        params: APIError.stringify(allParams),
                        response: {
                            status,
                            data,
                        },
                    });
                }

                this.dispatchFetchSuccess(dispatch, allParams, data);

                return { data, status };
            })
            .catch((error) => {
                let wrappedError = error;

                // Wrap error if it was unexpected. If it's an API error
                // or contextful error, we assume that it was thrown
                // explicitly by application code and no wrapping is
                // needed or desired.
                if (!isAPIError(error)) {
                    const name = this.name.toString();

                    wrappedError = new APIError(
                        `Fetcher ${name} threw an unexpected error: ${error}`,
                        {
                            reason: APIErrorReason.UNKNOWN_ERROR,
                            cause: error,
                            name,
                            params: allParams,
                        },
                    );
                }

                if (
                    isAPIError(wrappedError) &&
                    wrappedError.status &&
                    this.options.successCodes.includes(wrappedError.status)
                ) {
                    this.dispatchFetchSuccess(dispatch, allParams, null);
                    return;
                }

                dispatch({
                    type: this.actions.error,
                    params: allParams,
                    error: wrappedError,
                });

                // You read that right. Unlike what the name suggests,
                // `catchServerErrors: true` actually catches ALL
                // errors.
                //
                // Changing the parameter name now is a bit too
                // impactful, but when callers set this flag
                // the intention is to hide/swallow/swoop
                // any error so that the caller can presume
                // that the server simply didn't return
                // an error.
                //
                // Often used for non-critical functionality.
                if (this.options.catchServerErrors) {
                    return;
                }

                throw wrappedError;
            });

        addPendingPromise(pendingPromise, [this.name, allParams]);

        return pendingPromise;
    }

    checkParamsEquality(allParams: FetchParameters, state: FetchState) {
        return (
            isEqual(allParams, state.params) && !state.preloaded && !this.options.skipParamsCheck
        );
    }

    callFetchMethod({ params, state, dispatch }: FetchContext<any>): Promise<{
        data: any;
        status: number;
    }> {
        return this.fetcher(params, state, dispatch);
    }

    dispatchFetchStart(dispatch: Dispatch<FetchAction>, params: FetchParameters) {
        // indicate we've started fetching
        dispatch({
            type: this.actions.start,
            params,
        });
    }

    dispatchFetchSuccess(
        dispatch: Dispatch<FetchAction>,
        params: FetchParameters,
        data: FetchData,
    ) {
        dispatch({
            type: this.actions.success,
            params,
            data,
        });
    }
}

export type FetchMethodWithContext<TParams, TData = any> = (
    context: FetchContext<TParams>,
) => Promise<{
    data: TData;
    status: number;
}>;

/**
 * New style of FetcherFactory that supports passing the context from the action.
 * Used as a new class to provide better typechecking without having to change the
 * FetcherFactory code used everywhere in the codebase.
 */
export class FetcherFactoryWithContext<
    TParams,
    TData = any,
    TCreatorParams = TParams,
    TState = FetchState,
    TAction = FetchAction,
> extends FetcherFactory {
    declare fetcher: FetchMethodWithContext<TParams, TData>;

    // Override for type annotations
    constructor(
        name: string | Array<string>,
        fetcher: FetchMethodWithContext<TParams, TData>,
        options: Partial<FetchOptions> = {},
    ) {
        super(name, fetcher, options);
    }

    // Override for type annotations
    creator(): FetchActionCreator<TCreatorParams> {
        return super.creator() as FetchActionCreator<any>;
    }

    // Override for type annotations
    reducer(processor: null | FetchProcessor = null): FetchReducer<TState, TAction> {
        return super.reducer(processor) as FetchReducer<any, any>;
    }

    callFetchMethod({ params, state, dispatch, ...actionContext }: FetchContext<TParams>): Promise<{
        data: TData;
        status: number;
    }> {
        return this.fetcher({ ...actionContext, params, state, dispatch });
    }
}

export { buildDefaultState };
export default FetcherFactory;
