import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

const WalletPageHead = () => {
    const i18n = useI18n();

    return (
        <>
            <title>{t(i18n)`Dubizzle Wallet`}</title>
            <GTMDataLayer pageType={PageType.WALLET} />
        </>
    );
};

export default WalletPageHead;
