import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

const JazzCashExplanatoryNotes = () => {
    return (
        <>
            <Text.Regular block bold gray>
                <Trans>
                    After clicking Pay you will have to enter your MPIN (Mobile Banking PIN) on your
                    phone to confirm the transaction.
                </Trans>
            </Text.Regular>
            <Text.Regular block bold gray>
                <Trans>
                    If you don&apos;t fill it in time, please try to make the payment again.
                </Trans>
            </Text.Regular>
        </>
    );
};

export default JazzCashExplanatoryNotes;
