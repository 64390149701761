import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { I18n } from '@lingui/core';
import useI18n from 'strat/i18n/language/useI18n';
import { StratAPI } from 'strat/api';
import { makeCancelable } from 'strat/util';
import useDefaultPaymentMethods from '@app/payment/hooks/useDefaultPaymentMethods';
import { selectIsLanguageRTL } from 'strat/i18n/language';

import useGetPaymentMethodsIcons from '@app/payment/hooks/useGetPaymentMethodsIcons';
import IconBank from 'horizontal/assets/icons/iconBank.svg';

import { PaymentSource } from '../types';
import type { PaymentMethodDetails, LitePaymentMethodDetails } from '../types';
import styles from '../styles/paymentMethod.cssm';

type UsePaymentMethods = {
    readonly paymentMethods: Array<PaymentMethodDetails>;
    readonly paymentMethodsLoading: boolean;
    readonly wallet: LitePaymentMethodDetails | null | undefined;
    readonly loadingError: string;
};

type Props = {
    readonly withBankTransferPaymentMethod?: boolean;
};

const bankTransferPayment = (i18n: I18n) => ({
    name: t(i18n)`Bank Transfer`,
    description: t(i18n)`Pay Directly into our Bank Account`,
    renderIcon: () => <IconBank className={styles.bankIcon} />,
    source: PaymentSource.BANK_TRANSFER,
});

const usePaymentMethods = ({ withBankTransferPaymentMethod }: Props = {}): UsePaymentMethods => {
    const i18n = useI18n();
    const isRTL = useSelector(selectIsLanguageRTL);

    const [isLoading, setIsLoading] = React.useState(true);
    const [loadingError, setLoadingError] = React.useState<string>('');

    const defaultPaymentMethods = useDefaultPaymentMethods(i18n);
    const getPaymentMethodIcon = useGetPaymentMethodsIcons(isRTL);

    const [paymentMethods, setPaymentMethods] = React.useState<Array<PaymentMethodDetails>>([]);
    const [wallet, setWallet] = React.useState<LitePaymentMethodDetails | null | undefined>(null);

    React.useEffect(() => {
        setIsLoading(true);
        const promise = makeCancelable(new StratAPI(i18n.locale).paymentMethods());
        promise
            .then(({ data, status }: { data: Array<LitePaymentMethodDetails>; status: number }) => {
                if (status === 200 && data.length > 0) {
                    const paymentsWithoutWallet = data.filter(
                        (rawMethod: LitePaymentMethodDetails) => rawMethod.source !== 'wallet',
                    );
                    const walletPayment = data.find(
                        (rawMethod: LitePaymentMethodDetails) => rawMethod.source === 'wallet',
                    );

                    const methods = paymentsWithoutWallet.map((rawMethod) => {
                        return {
                            name: rawMethod.name,
                            description: rawMethod.description,
                            renderIcon: (className: string) =>
                                getPaymentMethodIcon({
                                    paymentSource: rawMethod.source,
                                    className,
                                }),
                            roles: rawMethod.roles,
                            source: rawMethod.source,
                            discount: rawMethod.discount
                                ? {
                                      name: rawMethod.discount.name,
                                      discountPercent: rawMethod.discount.discountPercent,
                                      description: rawMethod.discount.description,
                                      discountType: rawMethod.discount.discountType,
                                  }
                                : null,
                        };
                    });

                    if (withBankTransferPaymentMethod) {
                        // @ts-expect-error - TS2345 - Argument of type '{ name: string; description: string; renderIcon: () => JSX.Element; source: string; }' is not assignable to parameter of type '{ name: string; description: string; renderIcon: (className: string) => JSX.Element; source: string; discount: { name: string; discountPercent: number; description: string; } | null; }'.
                        methods.push(bankTransferPayment(i18n));
                    }
                    setPaymentMethods(methods);

                    if (walletPayment) {
                        setWallet({
                            name: walletPayment.name,
                            description: walletPayment.description,
                            source: walletPayment.source,
                        });
                    }
                } else {
                    if (defaultPaymentMethods.length) {
                        setPaymentMethods(defaultPaymentMethods);
                    } else {
                        setLoadingError(
                            t(
                                i18n,
                            )`Please note that payment methods did not load correctly, try to refresh or try again later`,
                        );
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {
            if (promise && !promise.isCanceled) {
                promise.cancel();
                setIsLoading(false);
            }
        };
    }, [
        i18n,
        defaultPaymentMethods,
        setIsLoading,
        withBankTransferPaymentMethod,
        getPaymentMethodIcon,
    ]);

    return {
        paymentMethods,
        paymentMethodsLoading: isLoading,
        wallet,
        loadingError,
    };
};

export default usePaymentMethods;
