import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import type { ActionButton } from 'horizontal/chat/types';

import ActionButtons from '../actionButtons';

import styles from './styles/initiatedSeller.cssm';

type Props = {
    readonly actionButtons: Array<ActionButton>;
};

const Initiated = ({ actionButtons }: Props) => (
    <Flex column className={styles.container}>
        <Flex column fillContainer justifyCenter alignCenter>
            <Text.Large bold uppercase>
                <Trans>No offer from the buyer yet</Trans>
            </Text.Large>
            <Text.Regular>
                <Trans>Request offer to close the deal fast!</Trans>
            </Text.Regular>
        </Flex>
        <ActionButtons actionButtons={actionButtons} />
    </Flex>
);
export default React.memo<Props>(Initiated);
