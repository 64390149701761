import * as React from 'react';
import classNames from 'classnames';

import * as Text from './text';
import type { RenderChoiceProps } from './options';
import type { ChoiceValue } from './types';
import styles from './styles/radioButtonChoice.cssm';

const RadioButtonChoice = <T extends ChoiceValue>({
    option,
    isSelected,
    onChange,
}: RenderChoiceProps<T>) => (
    <div
        key={option.id ?? option.value}
        className={classNames(styles.choice, {
            [styles.selected]: isSelected(option.value),
        })}
        onClick={() => onChange(option.value)}
    >
        <div
            className={classNames(styles.circle, {
                [styles.selected]: isSelected(option.value),
            })}
        />
        <Text.Regular>{option.label}</Text.Regular>
    </div>
);

export default RadioButtonChoice;
