import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { Flex, LoadingSpinnerOverlay, Text } from 'strat/components';

import type { AppDispatch } from 'horizontal/state';
import {
    useDidPaymentFail,
    usePaymentMethods,
    useTrackPaymentFailed,
    useGetFailedPaymentMessage,
} from 'horizontal/payment/hooks';
import useFetchPackagesExchangeRates from 'horizontal/businessPackages/useFetchPackagesExchangeRates';
import {
    clearBusinessPackageCart,
    setBusinessPackageCartOffers,
} from 'horizontal/businessPackages/state';
import { useUserCreditCards } from 'horizontal/payment/hooks';

import CartPriceDetails from '../businessPackages/cartPriceDetails';

import PaymentMethodsContainer from './paymentMethodsContainer';
import processPaymentFailedError from './processPaymentFailedError';
import PaymentSelectionHeader from './paymentSelectionHeader';
import PaymentTitle from './paymentTitle';
import WalletPayment from './wallet/walletPayment';
import WalletPaymentWithPartial from './wallet/walletPaymentWithPartial';
import styles from './styles/paymentSelection.cssm';
import PaymentFailed from './paymentFailed';
import { PaymentOrderRoles } from './types';
import type { PaymentOrder, PaymentMethodSelectedType } from './types';
import useMakePayment from './hooks/useMakePayment';
import useTrackPaymentAbort from './hooks/useTrackPaymentAbort';
import PaymentProducts from './paymentProducts';
import renderPriceDetails from './paymentSelectionPriceDetails';

type Props = {
    readonly order?: PaymentOrder;
};

const PaymentSelection = ({ order }: Props) => {
    const paymentFailed = useDidPaymentFail();
    const failedPaymentMessage = useGetFailedPaymentMessage();
    const { paymentMethods, paymentMethodsLoading, wallet, loadingError } = usePaymentMethods();
    const { onPaymentSelected, paymentLoading } = useMakePayment({ order });
    const [partialPayment, setPartialPayment] = React.useState(false);
    const [onWalletPaymentSuccess, setOnWalletPaymentSuccess] = React.useState<
        (() => void) | undefined
    >(undefined);
    const [selectPaymentMethodName, setSelectedPaymentMethodName] = React.useState<
        string | undefined
    >(undefined);
    const isPaymentMethodSelected = React.useRef(false);
    const isPartialPaymentOrder =
        order?.roles?.includes(PaymentOrderRoles.PARTIAL_PAYMENT) || false;

    const { creditCards, cardsLoading } = useUserCreditCards();
    const useTax = !!CONFIG.runtime.SALES_TAX_PERCENTAGE;
    const dispatch = useDispatch<AppDispatch>();

    useTrackPaymentAbort({ isPaymentMethodSelected, order });

    if (order && order.offers) {
        dispatch(setBusinessPackageCartOffers(order.offers));
    } else {
        clearBusinessPackageCart();
    }
    useTrackPaymentFailed(order, paymentFailed, failedPaymentMessage);
    useFetchPackagesExchangeRates();

    const onWalletSelect = React.useCallback(() => {
        if (wallet) {
            isPaymentMethodSelected.current = true;
            onPaymentSelected(wallet.source);
        }
    }, [onPaymentSelected, wallet]);

    const onWalletDismiss = () => {
        isPaymentMethodSelected.current = false;
    };

    const handlePartialPayment = ({ source, card, name }: PaymentMethodSelectedType) => {
        setSelectedPaymentMethodName(name);
        isPaymentMethodSelected.current = true;
        if (partialPayment) {
            setOnWalletPaymentSuccess(() => () => {
                onPaymentSelected(source, {
                    card,
                });
            });
        } else {
            onPaymentSelected(source, {
                card,
            });
        }
    };

    // resetting the second payment triggering function each time user enables partial payment
    React.useEffect(() => {
        if (!partialPayment) {
            setOnWalletPaymentSuccess(undefined);
        }
    }, [partialPayment, setOnWalletPaymentSuccess]);

    return (
        <Flex stretchHeight column>
            <LoadingSpinnerOverlay
                visible={(paymentLoading || cardsLoading || paymentMethodsLoading) && !loadingError}
            />
            <PaymentSelectionHeader order={order} />
            <Flex column className={styles.content}>
                <PaymentTitle>
                    <Trans>Payment Methods</Trans>
                </PaymentTitle>
                <PaymentProducts
                    productPackageNames={order?.productPackageNames}
                    productsPrice={useTax ? order?.grossTotal : order?.total}
                    loading={!order}
                />
                {useTax && (
                    <span className={styles.taxMessage}>
                        <Trans>* Total price includes 5% sales tax</Trans>
                    </span>
                )}
                {paymentFailed && (
                    <PaymentFailed
                        {...processPaymentFailedError(
                            order?.latestPaymentMethod,
                            order?.latestPaymentResponseCode,
                            order?.latestPaymentResponseSummary,
                        )}
                    />
                )}
                {useTax && <CartPriceDetails showTaxDetails render={renderPriceDetails} />}
                {wallet &&
                    order &&
                    (CONFIG.build.ENABLE_PARTIAL_PAYMENT ? (
                        <WalletPaymentWithPartial
                            isPartialPaymentOrder={isPartialPaymentOrder}
                            partialPayment={partialPayment}
                            setPartialPayment={setPartialPayment}
                            order={order}
                            onWalletSelect={onWalletSelect}
                            onWalletPaymentSuccess={onWalletPaymentSuccess}
                            selectPaymentMethodName={selectPaymentMethodName}
                            onWalletDismiss={onWalletDismiss}
                        />
                    ) : (
                        <WalletPayment
                            order={order}
                            onWalletSelect={onWalletSelect}
                            onWalletDismiss={onWalletDismiss}
                        />
                    ))}

                {loadingError && (
                    <Text.Regular error className={styles.errorMessage}>
                        {loadingError}
                    </Text.Regular>
                )}
                <PaymentMethodsContainer
                    partialPayment={partialPayment}
                    isPartialPaymentOrder={isPartialPaymentOrder}
                    creditCards={creditCards}
                    paymentMethods={paymentMethods}
                    onPaymentMethodSelected={handlePartialPayment}
                />
            </Flex>
        </Flex>
    );
};

export default PaymentSelection;
