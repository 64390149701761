import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import {
    checkHasAgentCategory,
    checkHasAgentLocation,
    checkHasAgentPurpose,
} from 'strat/agency/search/form/filters';

const shouldFetchFromCube = (filters: FilterCollection, locationsWithAds: Array<any> = []) => {
    const hasLocationFilter = checkHasAgentLocation(filters, locationsWithAds);
    const hasPurposeFilter = checkHasAgentPurpose(filters);
    const hasCategoryFilter = checkHasAgentCategory(filters);
    return hasLocationFilter || hasPurposeFilter || hasCategoryFilter;
};

export default shouldFetchFromCube;
