import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

type ViewCartRouteParams = {
    readonly adExternalID?: string;
};
class ViewBusinessPackageRoute extends Route {
    constructor() {
        super(RouteNames.VIEW_BUSINESS_PACKAGE_CART_ROUTE, [
            ['^/payments/viewcart/ALL', '(?:\\?)?'],
        ]);
    }

    createURL({ adExternalID }: ViewCartRouteParams): EnhancedLocation {
        return {
            pathname: '/payments/viewcart/ALL',
            search: {
                adExternalID: adExternalID,
            },
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        context.rendering.renderPage(Page.VIEW_BUSINESS_PACKAGE_CART);
    }
}

export default new ViewBusinessPackageRoute();
