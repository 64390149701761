//@ts-nocheck
export default Object.freeze({
    COPY: 'copy',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    WHATSAPP: 'whatsapp',
    MESSENGER: 'messenger',
    GMAIL: 'gmail',
    EMAIL: 'email',
});
