import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { SearchPageSearchType } from '../types';

import processAreaFilter from './processAreaFilter';
import processLocationFilter from './processLocationFilter';

const createProcessedSearchFilters = (
    filters: FilterCollection,
    searchPageType: Values<typeof SearchPageSearchType>,
): FilterCollection => {
    let processedFilters = processAreaFilter(filters);
    processedFilters = processLocationFilter(processedFilters, searchPageType);

    return processedFilters;
};

export default createProcessedSearchFilters;
