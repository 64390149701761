//@ts-nocheck
import { combineReducers } from 'redux';

import pageInfoReducer from './pageInfo/state';
import popularSearchesReducer from './popularSearches/state';

export default combineReducers({
    pageInfo: pageInfoReducer,
    popularSearches: popularSearchesReducer,
});
