import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import Page from 'horizontal/pages/page';
import {
    UserSettingsSection,
    UserSettingsPrivacySubsection,
    UserSettingsSubsection,
} from 'horizontal/userSettings';

import ensureHasActiveUser from './ensureHasActiveUser';

export type UserSettingsRouteParams = {
    readonly section?: Values<typeof UserSettingsSection>;
    readonly subsection?: Values<typeof UserSettingsSubsection>;
};

class UserSettingsRoute extends Route {
    constructor() {
        super(RouteNames.USER_SETTINGS, [
            [
                '^/settings/',
                { name: 'section', pattern: `(${UserSettingsSection.PRIVACY})` },
                '/',
                {
                    name: 'subsection',
                    pattern: `(${Object.values(UserSettingsPrivacySubsection).join('|')})`,
                },
                '(?:(?:\\?.*)?(?:#.*)?)?$',
            ],
            [
                '^/settings/',
                { name: 'section', pattern: `(${Object.values(UserSettingsSection).join('|')})` },
                '(?:(?:\\?.*)?(?:#.*)?)?$',
            ],
            ['^/settings', '(?:(?:\\?.*)?(?:#.*)?)?$'],
        ]);
    }

    createURL({ section, subsection }: UserSettingsRouteParams): EnhancedLocation {
        if (!section) {
            return { pathname: '/settings' };
        }

        if (!subsection) {
            return { pathname: `/settings/${section}` };
        }

        return { pathname: `/settings/${section}/${subsection}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        const { section, subsection } = context.match.params;

        context.rendering.renderPage(Page.USER_SETTINGS, { section, subsection });
    }
}

export default new UserSettingsRoute();
