import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import styles from './styles/analyticsTile.cssm';

type Props = {
    readonly text: string;
    readonly value: number | string;
    readonly iconSrc: string;
    readonly className?: string;
    readonly compact?: boolean;
};

const AnalyticsTile = ({ text, value, iconSrc, className, compact }: Props) => {
    return (
        <Flex className={classNames(styles.container, className)} aria-label={text}>
            <div className={styles.iconWrapper}>
                <svg className={styles.icon}>
                    <use xlinkHref={`${iconSrc}#icon`} />
                </svg>
            </div>
            <Flex
                column={!compact}
                justifySpaceAround
                className={compact ? styles.detailsCompact : styles.details}
            >
                <Text.Regular bold className={styles.value}>
                    {value}
                </Text.Regular>
                <Text.Regular className={styles.label}>{text}</Text.Regular>
            </Flex>
        </Flex>
    );
};
export default AnalyticsTile;
