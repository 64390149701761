//@ts-nocheck
// flow

import type { Campaign } from 'strat/campaign/types';

/**
 * In order to make sure that
 * the campaign params are passed even on refresh
 * and redirect we added this function.
 *
 * This issue has been faced in 2018 september #2032
 * and we don't want it to happen again.
 */
const getCampaignParams = (campaign: Campaign | null) => {
    return {
        utm_source: campaign ? campaign.source : null,
        utm_medium: campaign ? campaign.medium : null,
        utm_campaign: campaign ? campaign.name : null,
        utm_term: campaign ? campaign.term : null,
        utm_content: campaign ? campaign.content : null,
        gclid: campaign ? campaign.gclid : null,
        wbraid: campaign ? campaign.wbraid : null,
        custom_traffic_source: campaign ? campaign.customTrafficSource : '',
    };
};

export default getCampaignParams;
