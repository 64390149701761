import React from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from 'horizontal/state';

import { decodeStateFilter } from './stateFilterEncoding';
import { getStatesByFilter } from './useStateFilter';

const useRouteParams = () => {
    const params = useSelector((state: GlobalState) => state.router.routeParams);

    return React.useMemo(() => {
        const { filter, page } = params;
        const { query, agentCode, category, stateFilter } = decodeStateFilter(filter);
        const states = getStatesByFilter(stateFilter);
        return { query, agentCode, category, states, page };
    }, [params]);
};

export default useRouteParams;
