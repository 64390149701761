import * as React from 'react';

import { Flex } from 'strat/components';

import styles from './styles/projectExperienceBackground.cssm';

const ProjectExperienceBackground = () => (
    <Flex justifyCenter className={styles.background}>
        <div />
    </Flex>
);

export default ProjectExperienceBackground;
