import { t } from '@lingui/macro';
import * as React from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import Yup from 'yup';
import { Formik } from 'formik';
import type { I18n } from '@lingui/core';
import useI18n from 'strat/i18n/language/useI18n';
import { ValidationSchema } from 'strat/form/validation';
import { LoadingSpinner } from 'strat/loadable';
import settings from '@app/branding/settings';

import BillingForm from './billingForm';
import useBillingInfo from './useBillingInfo';

// either 9 numbers OR 9 numbers with a '-' in between OR 9 numbers with 2 separate '-' in between
export const commercialNumberRegex =
    /^[0-9]{9}$|(^(?=[0-9-]{10}$)([0-9]+(-){1}[0-9]+))|(^(?=[0-9-]{11}$)([0-9]+(-){1}[0-9]+(-){1}[0-9]+))/;

export const makeValidationSchema = (businessValidationSchema: boolean, i18n: I18n) => {
    const residentialValidation = {
        email: ValidationSchema(i18n).email,
        customerType: Yup.string().required(t(i18n)`Customer Type field is mandatory`),
        customerName: Yup.string().max(
            150,
            t(i18n)`Customer Name field should have 150 characters maximum`,
        ),
        businessName: Yup.string().max(
            200,
            t(i18n)`Business Name field should have 200 characters maximum`,
        ),
        phoneNumber: ValidationSchema(i18n).phoneNumber,
        addressLineOne: Yup.string().max(
            250,
            t(i18n)`Address line 1 field should have 250 characters maximum`,
        ),
        addressLineTwo: Yup.string().max(
            250,
            t(i18n)`Address line 2 field should have 250 characters maximum`,
        ),
        city: Yup.string().max(250, t(i18n)`City field should have 250 characters maximum`),
    } as const;

    if (businessValidationSchema) {
        // the business validation schema extends the residential one
        let businessValidation = {
            email: residentialValidation.email,
            customerType: residentialValidation.customerType,
            customerName: residentialValidation.customerName.required(
                t(i18n)`Customer Name field is mandatory`,
            ),
            businessName: residentialValidation.businessName.required(
                t(i18n)`Business Name field is mandatory`,
            ),
            vatNumber: settings.vatNumberRequired
                ? Yup.string().required(t(i18n)`Tax Id field is mandatory`)
                : Yup.string().nullable(),
            phoneNumber: residentialValidation.phoneNumber,
            addressLineOne: residentialValidation.addressLineOne.required(
                t(i18n)`Address line 1 field is mandatory`,
            ),
            addressLineTwo: residentialValidation.addressLineTwo,
            state: Yup.string().required(t(i18n)`State field is mandatory`),
            city: residentialValidation.city.required(t(i18n)`City field is mandatory`),
        };

        if (!settings.disableCommercialNumber) {
            businessValidation = {
                ...businessValidation,
                // @ts-expect-error - TS2322 - Type '{ commercialNumber: any; email: any; customerType: any; customerName: any; businessName: any; vatNumber: any; phoneNumber: any; addressLineOne: any; addressLineTwo: any; state: any; city: any; }' is not assignable to type '{ email: any; customerType: any; customerName: any; businessName: any; vatNumber: any; phoneNumber: any; addressLineOne: any; addressLineTwo: any; state: any; city: any; }'. | TS2339 - Property 'commercialNumberRequired' does not exist on type 'HorizontalSettings'.
                commercialNumber: settings.commercialNumberRequired
                    ? Yup.string()
                          .required(t(i18n)`Commercial Registration Number field is mandatory`)
                          .matches(
                              commercialNumberRegex,
                              t(
                                  i18n,
                              )`Commercial Registration Number field should be ${9} digits long`,
                          )
                          .nullable()
                    : Yup.string()
                          .matches(
                              commercialNumberRegex,
                              t(
                                  i18n,
                              )`Commercial Registration Number field should be ${9} digits long`,
                          )
                          .nullable(),
            };
        }
        return Yup.object().shape(businessValidation);
    }
    return Yup.object().shape(residentialValidation);
};

const renderForm =
    (
        businessValidationSchema: boolean,
        setBusinessValidationSchema: (businessValidationSchema: boolean) => void,
    ) =>
    (props: React.ComponentProps<typeof BillingForm>) => (
        <BillingForm
            {...props}
            businessValidationSchema={businessValidationSchema}
            setBusinessValidationSchema={setBusinessValidationSchema}
        />
    );

const Billing = () => {
    const i18n = useI18n();
    const [businessValidationSchema, setBusinessValidationSchema] = React.useState(false);
    const validationSchema = React.useMemo(
        () => makeValidationSchema(businessValidationSchema, i18n),
        [businessValidationSchema, i18n],
    );
    const { getBillingInfo, billingInfo, loading, onSubmit } = useBillingInfo(
        setBusinessValidationSchema,
    );

    React.useEffect(() => getBillingInfo(), [getBillingInfo]);

    return loading ? (
        <LoadingSpinner />
    ) : (
        <Formik
            validationSchema={validationSchema}
            initialValues={billingInfo}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {renderForm(businessValidationSchema, setBusinessValidationSchema)}
        </Formik>
    );
};

export default Billing;
