import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { LoadingSpinner } from 'strat/loadable';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';
import { Input, Flex } from 'strat/components';

import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';
import IconClose from 'horizontal/assets/icons/iconClose.svg';
import type { Location } from 'horizontal/types';
import { selectLocationNameWithParent } from 'horizontal/location';
import LocationSearchIcon from 'horizontal/search/location/locationSearchIcon';

import styles from './styles/locationInput.cssm';
import LocationRadiusFilter from './locationRadiusFilter';

const renderLeft = ({
    loading,
    renderSearchIcon,
    searchIcon,
}: {
    readonly loading?: boolean;
    readonly renderSearchIcon?: boolean;
    readonly searchIcon?: string;
}) =>
    loading ? (
        <LoadingSpinner className={styles.loading} />
    ) : (
        renderSearchIcon && (
            <Flex alignCenter className={styles.searchIconContainer}>
                <LocationSearchIcon className={classNames(searchIcon, styles.locationIcon)} />
            </Flex>
        )
    );

type LocationInputRightSideProps = {
    focused?: boolean;
    toggleOpen: () => void;
    clearSelectedLocation: () => void;
    withLocationRadiusFilter: boolean;
    onLocationRadiusFilterClick: () => void;
};

const LocationInputRightSide = ({
    focused,
    toggleOpen,
    clearSelectedLocation,
    withLocationRadiusFilter,
    onLocationRadiusFilterClick,
}: LocationInputRightSideProps) => {
    if (withLocationRadiusFilter) {
        return (
            <>
                <IconClose className={styles.closeIcon} onClick={clearSelectedLocation} />
                <LocationRadiusFilter onClick={onLocationRadiusFilterClick} />
            </>
        );
    }
    return (
        <Flex alignCenter>
            <IconArrowDown
                className={classNames(styles.dropdownIcon, { [styles.focused]: focused })}
                onClick={toggleOpen}
                // @ts-expect-error - TS2322 - Type '{ className: string; onClick: () => void; alt: string; }' is not assignable to type 'IntrinsicAttributes & SVGAttributes<SVGElement> & { children?: ReactNode; }'.
                alt={'Dropdown trigger'}
            />
        </Flex>
    );
};

type Props = {
    readonly location?: Location | null | undefined;
    readonly onChange: (arg1: string) => void;
    readonly loading?: boolean;
    readonly onInputActiveChanged?: (arg1: boolean) => void;
    readonly inputActive?: boolean;
    readonly renderSearchIcon?: boolean;
    readonly searchIcon?: string;
    readonly onLocationSelected?: (location?: Location | null | undefined) => void;
    readonly withLocationRadiusFilter?: boolean;
    readonly className?: string;
    readonly shouldRenderRight?: boolean;
} & React.ComponentProps<typeof Input>;

const LocationInput = ({
    location,
    onChange,
    onInputActiveChanged,
    inputActive,
    onKeyDown,
    loading,
    renderSearchIcon = true,
    searchIcon,
    onLocationSelected,
    withLocationRadiusFilter = false,
    className,
    shouldRenderRight = true,
}: Props) => {
    const i18n = useI18n();
    const [input, setInput] = React.useState('');

    React.useEffect(() => {
        setInput(
            location
                ? selectLocationNameWithParent(i18n, location.hierarchy)
                : selectLocationNameWithParent(i18n, [settings.topLevelLocation]),
        );
    }, [i18n, location, setInput, onChange]);

    React.useEffect(() => {
        onChange(input);
    }, [input, onChange]);

    const onInputActiveChange = React.useCallback(
        (isActive) => {
            if (!onInputActiveChanged) {
                return;
            }

            onInputActiveChanged(isActive);
        },
        [onInputActiveChanged],
    );

    const onDropdownTrigger = React.useCallback(
        () => onInputActiveChange(!inputActive),
        [inputActive, onInputActiveChange],
    );

    return (
        <Input
            value={input}
            onChange={setInput}
            renderLeft={() => renderLeft({ loading, renderSearchIcon, searchIcon })}
            renderRight={() =>
                shouldRenderRight ? (
                    <LocationInputRightSide
                        focused={inputActive}
                        toggleOpen={onDropdownTrigger}
                        clearSelectedLocation={() => onLocationSelected?.(null)}
                        withLocationRadiusFilter={!!withLocationRadiusFilter}
                        onLocationRadiusFilterClick={() => onInputActiveChange(false)}
                    />
                ) : null
            }
            placeholder={t(i18n)`Location or Compound`}
            onKeyDown={onKeyDown}
            onFocusChanged={(focus) => {
                if (!focus) {
                    trigger(Triggers.TYPE_LOCATION_BOX, { view_section: ViewSections.HEADER });
                    return;
                }
                onInputActiveChange(true);
            }}
            autoComplete="location-search"
            className={className}
        />
    );
};

export default LocationInput;
