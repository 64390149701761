import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import EMPTY_ARRAY from 'strat/empty/array';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID } from 'strat/user/session';
import { catchCanceled } from 'strat/util';

import { preloadAd } from 'horizontal/ad/state';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import { getStratAPI, StratAPI } from 'horizontal/api';
import { filterEligibleProductPurchases, ProductPurchaseStatus } from 'horizontal/packages';
import { selectProductPurchases } from 'horizontal/packages/selectors';
import { fetchProductPurchases } from 'horizontal/packages/state';
import Page from 'horizontal/pages/page';
import type { FullAd, ProductPackageOffer } from 'horizontal/types';
import { AdState } from 'horizontal/types';
import { isAdAgent, isAdEditor } from 'horizontal/util';

import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';
import ensureHasActiveUser from './ensureHasActiveUser';

export type PostAdSuccessRouteParams = {
    readonly adExternalID: string;
    readonly hidePurchaseSuggestions: boolean;
    readonly suppressTrigger?: boolean;
};

class PostAdSuccessRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.POST_AD_SUCCESS, [
            [
                '^/payments/packages/consume/limits/',
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '/success',
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({
        adExternalID,
        hidePurchaseSuggestions,
        suppressTrigger,
    }: PostAdSuccessRouteParams): EnhancedLocation {
        return {
            pathname: `/payments/packages/consume/limits/${adExternalID}/success`,
            search: { hidePurchaseSuggestions, suppressTrigger },
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        context.rendering.renderPage(Page.POST_AD_SUCCESS, { ad: null });

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { adExternalID, hidePurchaseSuggestions, suppressTrigger },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        const dataPromise = Promise.all([
            this.fetchAd(context),
            this.cancelable(
                dispatch(
                    fetchProductPurchases({
                        status: ProductPurchaseStatus.ACTIVE,
                        bundleProductPurchases: true,
                        adExternalID,
                    }),
                ),
            ),
            this.cancelable(dispatch(fetchFreeAdLimitUsage())),
        ]);

        context.promise.wait(
            dataPromise
                .then(([ad]) => {
                    if (!ad || !isAdEditor(ad, userExternalID)) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    if ([AdState.REJECTED, AdState.SOFT_REJECTED].includes(ad.state)) {
                        context.rendering.renderPage(Page.APPLY_AD_SINGLE_PACKAGE_ERROR);
                        return;
                    }

                    if (![AdState.ACTIVE, AdState.PENDING].includes(ad.state)) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }

                    const productPurchases = selectProductPurchases(getState());
                    const eligibleProductPurchases = hidePurchaseSuggestions
                        ? EMPTY_ARRAY
                        : filterEligibleProductPurchases(ad, productPurchases);

                    if (
                        eligibleProductPurchases.length === 0 &&
                        !hidePurchaseSuggestions &&
                        !isAdAgent(ad, userExternalID)
                    ) {
                        return Promise.all([Promise.resolve(ad), this.fetchOffers(context)]).then(
                            (params) => {
                                if (!params) {
                                    return;
                                }

                                const productOffers = params[1];

                                dispatch(preloadAd(ad));
                                context.rendering.renderPage(Page.POST_AD_SUCCESS, {
                                    ad,
                                    productOffers,
                                    suppressTrigger,
                                });
                            },
                        );
                    }

                    context.rendering.renderPage(Page.POST_AD_SUCCESS, {
                        ad,
                        productPurchases: eligibleProductPurchases,
                        suppressTrigger,
                    });
                })
                .catch(catchCanceled),
        );
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<FullAd | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { adExternalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!adExternalID || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) => api.userAd(userExternalID, adExternalID));
    }

    fetchOffers(
        context: RoutingContextWithMiddlewares,
    ): Promise<Array<ProductPackageOffer> | null | undefined> {
        const {
            match: {
                params: { adExternalID },
            },
        } = context;

        if (!adExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) =>
            api.getAdProductPackageOffers(adExternalID),
        );
    }

    private makeStratAPIRequest<T>(
        context: RoutingContextWithMiddlewares,
        callback: (api: StratAPI) => Promise<{ status: number; data: T }>,
    ) {
        const {
            redux: {
                store: { getState },
            },
        } = context;

        const api = getStratAPI(getState());
        const request = this.cancelable(callback(api));

        return request.then((response) => (response.status < 300 ? response.data : null));
    }
}

export default new PostAdSuccessRoute();
