import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { SellerProfileRouteParams } from 'horizontal/routes';

const useNavigateToSellerPage = () => {
    const router = useRouter();

    return React.useCallback(
        (params: SellerProfileRouteParams) => {
            router.pushRoute(RouteNames.SELLER_PROFILE, params);
        },
        [router],
    );
};

export default useNavigateToSellerPage;
