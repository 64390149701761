//@ts-nocheck
import type { CancelablePromise } from 'strat/util';
import { makeCancelable } from 'strat/util';
/**
 * Function which return an empty promise so that the contact details pane will use the phone number
 * sent via props.
 */
const phoneNumberFetcher: CancelablePromise = () => makeCancelable(Promise.resolve());

export default phoneNumberFetcher;
