import * as React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';

import * as Text from './text';
import InputMessage from './inputMessage';
import styles from './styles/inputContainer.cssm';

type Props = {
    readonly title?: React.ReactNode;
    readonly description?: string;
    readonly name?: string;
    readonly maxLength?: number;
    readonly errorMessage?: string | null;
    readonly shouldDisplayErrorMessage?: boolean;
    readonly erroring?: boolean;
    readonly accepted?: boolean;
    readonly characterCount?: number;
    readonly children?: React.ReactNode;
    readonly onFocusChanged?: (arg1: boolean) => void;
    readonly secondary?: boolean;
    readonly ternary?: boolean;
    readonly borderless?: boolean;
    readonly large?: boolean;
    readonly stretch?: boolean;
    readonly className?: string;
    readonly titleClassname?: string;
    readonly readOnly?: boolean;
    readonly lightErrorBorder?: boolean;
    readonly leftSideLabel?: boolean;
    readonly boldLabel?: boolean;
    readonly warningMessage?: string;
};

const InputContainer = ({
    title,
    description,
    name,
    maxLength,
    errorMessage,
    shouldDisplayErrorMessage = true,
    erroring,
    accepted,
    characterCount,
    children,
    onFocusChanged,
    secondary,
    ternary,
    borderless,
    large,
    stretch,
    className,
    readOnly,
    titleClassname,
    lightErrorBorder,
    leftSideLabel,
    boldLabel,
    warningMessage,
}: Props) => {
    const onFocus = React.useCallback(() => {
        if (!onFocusChanged) {
            return;
        }

        onFocusChanged(true);
    }, [onFocusChanged]);

    const onBlur = React.useCallback(() => {
        if (!onFocusChanged) {
            return;
        }

        onFocusChanged(false);
    }, [onFocusChanged]);

    const inputWithInfo = (
        <>
            <div
                className={classNames(styles.inputContainer, className, { [styles.large]: large })}
            >
                {children}
            </div>
            <div className={styles.info}>
                {(!!errorMessage || !!warningMessage || !!description) &&
                    shouldDisplayErrorMessage && (
                        <InputMessage isError={!!errorMessage} isWarning={!!warningMessage}>
                            {errorMessage || warningMessage || description || null}
                        </InputMessage>
                    )}
                {maxLength && !isNil(characterCount) && (
                    <span className={styles.counter}>
                        <Text.Small>{`${characterCount}/${maxLength}`}</Text.Small>
                    </span>
                )}
            </div>
        </>
    );

    return (
        <div
            className={classNames(styles.container, {
                [styles.secondary]: secondary,
                [styles.ternary]: ternary,
                [styles.error]: !!errorMessage || !!erroring,
                [styles.lightErrorBorder]: (!!errorMessage || !!erroring) && lightErrorBorder,
                [styles.warning]: !errorMessage && !erroring && !!warningMessage,
                [styles.accepted]: accepted,
                [styles.stretch]: stretch,
                [styles.borderless]: borderless,
                [styles.readOnly]: readOnly,
                [styles.leftSideLabel]: leftSideLabel,
            })}
            onBlur={onBlur}
            onFocus={onFocus}
        >
            {title && (
                <label
                    htmlFor={name}
                    className={
                        titleClassname ??
                        classNames(styles.title, {
                            [styles.leftSideLabel]: leftSideLabel,
                            [styles.boldLabel]: boldLabel,
                        })
                    }
                >
                    {/* @ts-expect-error - TS2322 - Type '{ children: true | ReactChild | ReactFragment | ReactPortal; selected: boolean | undefined; bold: boolean; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Text.Regular selected={accepted} bold={!!errorMessage}>
                        {title}
                    </Text.Regular>
                </label>
            )}
            {leftSideLabel ? (
                <div className={styles.inputInfoContainer}>{inputWithInfo}</div>
            ) : (
                inputWithInfo
            )}
        </div>
    );
};

export default InputContainer;
