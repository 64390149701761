import * as React from 'react';
import { t } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex, ListEntry } from 'strat/components';

import { useIsProOwnerAgent } from 'horizontal/agent/state';

import styles from './styles/agencyPortalLink.cssm';

type Props = {
    onClick: (arg1: React.MouseEvent<any>) => void;
    renderLeft: () => JSX.Element;
};

const AgencyPortalLink = ({ onClick, renderLeft }: Props) => {
    const i18n = useI18n();

    const isProAgencyOwner = useIsProOwnerAgent();
    const brandName = settings.getAgencyPortalBrandName(i18n);

    const routerLink = isProAgencyOwner
        ? RouteNames.AGENCY_PORTAL_HOME
        : RouteNames.AGENCY_PORTAL_ADS;

    return (
        <Link
            route={routerLink}
            title={t(i18n)`Switch to ${brandName} Pro`}
            onClick={onClick}
            openInNewTab
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Flex column justifyCenter className={styles.container}>
                    <Text.Large>
                        {t(i18n)`Switch to`} <span className={styles.brand}>{brandName}</span>{' '}
                        <span className={styles.pro}>{t(i18n)`Pro`}</span>
                    </Text.Large>
                    {!isProAgencyOwner && (
                        <Text.Small className={styles.adsManagement}>
                            {t(i18n)`Ads management`}
                        </Text.Small>
                    )}
                </Flex>
            </ListEntry>
        </Link>
    );
};

export default AgencyPortalLink;
