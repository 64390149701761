/**
 * Available actions for rendering.
 */
export enum Actions {
    RENDER_PAGE = 'PAGE/RENDER',
    SWITCH = 'RENDERING/SWITCH',
    SET_LOADING = 'RENDERING/LOADING',
}

export type RenderingState = {
    page: string | null;
    enabled: boolean;
    loading: boolean;
    props: Record<string, unknown>;
};

export type RenderPageAction = {
    type: Actions.RENDER_PAGE;
    page: string;
    props: Object;
};

type SwitchAction = {
    type: Actions.SWITCH;
    enabled: boolean;
};

type SetLoadingAction = {
    type: Actions.SET_LOADING;
    loading: boolean;
};

type Action = RenderPageAction | SwitchAction | SetLoadingAction;
/**
 * Default state for rendering.
 */
const defaultState: RenderingState = {
    page: null,
    enabled: true,
    loading: false,
    props: {},
} as const;

/**
 * Reducer for pages.
 */
const renderingReducer = (state: Object = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.RENDER_PAGE:
            return {
                ...state,
                page: action.page,
                props: action.props,
                loading: false, // resets the loading indicator
            };
        case Actions.SWITCH:
            return {
                ...state,
                enabled: action.enabled,
            };
        case Actions.SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        default:
            return state;
    }
};

/*
 * Set the loading flag so a loading indicator component can be implemented
 */
const setLoading = (loading: boolean) => ({
    type: Actions.SET_LOADING,
    loading,
});

/**
 * Renders the specified page.
 */
const renderPage = (page: string, props: Object = {}) => ({
    type: Actions.RENDER_PAGE,
    page,
    props,
});

/*
 * Switch rendering - used for disabling SSR in some cases
 */
const switchRendering = (enabled: boolean) => ({
    type: Actions.SWITCH,
    enabled,
});

export { renderPage, switchRendering, setLoading };

export default renderingReducer;
