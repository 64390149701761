// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import FilterValues from 'strat/search/filterValues';

import { restrictLocation } from 'horizontal/search/location/disabledLocations';
import { Location } from 'horizontal/types';

const getSelectedLocationFilter = (getState: GetState): ObjectExactFilter<Location> | null => {
    const state = getState();
    const location = getFilterCollection(state).getFilterValue(FilterValues.location.attribute);
    const userLocation = state.user.geoLocation;
    if (location) {
        return new ObjectExactFilter({
            attribute: FilterValues.location.attribute,
            value: restrictLocation(location),
        });
    } else if (userLocation.closestLocation) {
        return new ObjectExactFilter({
            attribute: FilterValues.location.attribute,
            value: restrictLocation(userLocation.closestLocation),
        });
    }
    return null;
};

export default getSelectedLocationFilter;
