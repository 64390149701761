import * as React from 'react';
import classNames from 'classnames';

import Loadable from 'strat/loadable';
import { Dismissible } from 'strat/modal';

import styles from './styles/tooltip.cssm';

export const VerticalPosition = Object.freeze({
    TOP: 'top',
    BOTTOM: 'bottom',
    MIDDLE: 'middle',
});

export const HorizontalPosition = Object.freeze({
    CENTER: 'center',
    LEFT: 'left',
    RIGHT: 'right',
});

const Constraints = Object.freeze({
    SCROLL_PARENT: 'scrollParent',
    TOGETHER: 'together',
});

export type Attachment = {
    vertical: Values<typeof VerticalPosition>;
    horizontal: Values<typeof HorizontalPosition>;
};

export type ContentProps = {
    className?: string;
};

export type Props = {
    /**
     * Renders the  content inside the tooltip.
     */
    renderContent?: (props: ContentProps) => React.ReactElement;
    /**
     * Renders the trigger.
     */
    renderTrigger: (props?: { isTooltipVisible?: boolean }) => React.ReactElement;
    /**
     * The attachment/anchor point from the tooltip.
     */
    attachment: Attachment;
    /**
     * The atachment/anchor point from the target element.
     */
    targetAttachment: Attachment;
    /**
     * Additional styling for the trigger and tooltip container.
     */
    className?: string;
    /**
     * Additional styling for the content container.
     */
    contentContainerClassName?: string;
    /**
     * Additional styling for the tooltip container.
     */
    tooltipClassName?: string;
    /**
     * Additional styling for the anchor(that little arrow.).
     */
    anchorClassName?: string;
    /**
     * Additional styling for the trigger container.
     */
    triggerContainerClassName?: string;
    /**
     * A time that needs to elapse before displaying the tooltip
     */
    timeToRender?: number;
    /**
     * A time that needs to elapse before hiding the tooltip
     */
    timeToHide?: number;
    /**
     * Whether this tooltip can also be activated by click.
     * This means that it will be dismissed when clicked somewhere else
     */
    clickable?: boolean;
    anchorID?: string;
    /**
     * Whether we should dismiss the content when clicking on the tooltip 'popup'
     */
    shouldDismissOnContentClick?: boolean;
    /**
     * Whether the tooltip will ignore mouse events.
     */
    tooltipShouldIgnoreMouseEvents?: boolean;
    /**
     * Whether the tooltip should not dismiss if the cursor is over the content.
     */
    shouldNotDismissIfCursorIsOverContent?: boolean;
    disabled?: boolean;
    /**
     * Whether the tooltip should be pinned to the window to avoid horizontal scrolling.
     */
    pin?: boolean;
};

type State = {
    visible: boolean;
    showTether: boolean;
    isMouseOverContent: boolean;
};

const TetherComponent = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'tooltip' */
            'react-tether'
        ),
    loading: () => <div />,
});

/**
 * A generic tooltip component that will adjust its position.
 *
 * The 2 most important  props that this  component  receives are:
 *  -   renderTrigger: this will render the component to which the tooltip will be attached.
 *  -   renderContent: this will render the content inside the tooltip.
 *
 *
 * Should be imported  async because it uses a pretty big  library -> react-tether.
 */
class Tooltip extends React.Component<Props, State> {
    // @ts-expect-error - TS2564 - Property 'displayTimeoutID' has no initializer and is not definitely assigned in the constructor.
    displayTimeoutID: number;
    // @ts-expect-error - TS2564 - Property 'hideTimeoutID' has no initializer and is not definitely assigned in the constructor.
    hideTimeoutID: number;
    static defaultProps = {
        attachment: {
            vertical: VerticalPosition.BOTTOM,
            horizontal: HorizontalPosition.CENTER,
        },
        targetAttachment: {
            vertical: VerticalPosition.TOP,
            horizontal: HorizontalPosition.CENTER,
        },
        tooltipClassName: styles.tether,
    };

    state = {
        visible: false,
        showTether: false,
        isMouseOverContent: false,
    };

    showTooltip() {
        if (this.props.timeToRender) {
            // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
            this.displayTimeoutID = setTimeout(
                () => this.setState({ visible: true, showTether: true }),
                this.props.timeToRender,
            );
        } else {
            this.setState({ visible: true, showTether: true });
        }
    }

    shouldNotDismiss() {
        return this.props.shouldNotDismissIfCursorIsOverContent && this.state.isMouseOverContent;
    }

    hideTooltip() {
        if (this.props.timeToHide) {
            // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
            this.hideTimeoutID = setTimeout(
                () => !this.shouldNotDismiss() && this.setState({ visible: false }),
                this.props.timeToHide,
            );
        } else if (!this.shouldNotDismiss()) {
            this.setState({ visible: false });
        }
        if (this.props.timeToRender && this.displayTimeoutID) {
            clearTimeout(this.displayTimeoutID);
        }
    }

    onClick() {
        if (this.props.clickable) {
            this.showTooltip();
            this.hideTooltip();
        }
    }

    onDismissed() {
        this.setState({ visible: false });
        if (this.props.timeToRender && this.displayTimeoutID) {
            clearTimeout(this.displayTimeoutID);
            clearTimeout(this.hideTimeoutID);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.disabled !== this.props.disabled && this.props.disabled) {
            this.hideTooltip();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.displayTimeoutID);
        clearTimeout(this.hideTimeoutID);
    }

    render() {
        const {
            attachment,
            disabled,
            targetAttachment,
            className,
            contentContainerClassName = styles.contentContainer,
            anchorClassName,
            clickable,
            shouldDismissOnContentClick,
            tooltipClassName,
            triggerContainerClassName,
            pin,
        } = this.props;

        // We don't add the event handlers when the tooltip is disabled because
        // they can stop the propagation of other events to the trigger child
        const triggerContainerProps = !disabled && {
            onMouseEnter: () => !clickable && this.showTooltip(),
            onMouseLeave: () => !clickable && this.hideTooltip(),
            onClick: () => this.onClick(),
        };

        return (
            <div className={className ?? styles.container}>
                <div {...triggerContainerProps} className={triggerContainerClassName}>
                    {this.props.renderTrigger &&
                        this.props.renderTrigger({
                            isTooltipVisible: this.state.showTether && this.state.visible,
                        })}
                </div>
                {this.state.showTether && (
                    <TetherComponent
                        // @ts-expect-error react-loadable typings seem to have problems with external lib d.ts files
                        className={classNames(tooltipClassName, {
                            [styles.noPointerEvents]: this.props.tooltipShouldIgnoreMouseEvents,
                        })}
                        attachment={`${attachment.vertical} ${attachment.horizontal}`}
                        targetAttachment={`${targetAttachment.vertical} ${targetAttachment.horizontal}`}
                        constraints={[
                            {
                                to: 'window',
                                attachment: Constraints.TOGETHER,
                                ...(pin ? { pin: ['left', 'right'] } : {}),
                            },
                        ]}
                        // @ts-expect-error - TS7006 - Parameter 'ref' implicitly has an 'any' type.
                        renderTarget={(ref) => <div ref={ref} className={styles.trigger} />}
                        // @ts-expect-error - TS7006 - Parameter 'ref' implicitly has an 'any' type.
                        renderElement={(ref) =>
                            this.state.visible && (
                                <div
                                    ref={ref}
                                    className={contentContainerClassName}
                                    onClick={() =>
                                        shouldDismissOnContentClick && this.onDismissed()
                                    }
                                    onTouchStart={() =>
                                        shouldDismissOnContentClick && this.onDismissed()
                                    }
                                    onMouseEnter={() => this.setState({ isMouseOverContent: true })}
                                    onMouseLeave={() => {
                                        this.setState({ isMouseOverContent: false });
                                        if (this.props.shouldNotDismissIfCursorIsOverContent) {
                                            this.hideTooltip();
                                        }
                                    }}
                                >
                                    {this.props.renderContent &&
                                        (clickable ? (
                                            <Dismissible
                                                onDismissed={() => this.onDismissed()}
                                                stacked={false}
                                            >
                                                {this.props.renderContent({
                                                    className: styles.content,
                                                })}
                                            </Dismissible>
                                        ) : (
                                            this.props.renderContent({
                                                className: styles.content,
                                            })
                                        ))}
                                    {this.props.renderContent && (
                                        <span
                                            className={[styles.anchor, anchorClassName].join(' ')}
                                            id={this.props.anchorID}
                                        />
                                    )}
                                </div>
                            )
                        }
                    />
                )}
            </div>
        );
    }
}

export default Tooltip;
