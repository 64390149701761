import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteNames } from 'strat/routes';
import EMPTY_ARRAY from 'strat/empty/array';

import { BreadcrumbType } from 'horizontal/breadcrumbs';
import { useFullLocationsHierarchy } from 'horizontal/location';
import type { SearchRouteParams } from 'horizontal/routes';
import { findDeepList } from 'horizontal/util/findDeep';
import { selectCategories } from 'horizontal/categories';
import { CategoryFieldRole, FlatCategoryField } from 'horizontal/types';
import type { Breadcrumb } from 'horizontal/breadcrumbs';
import type { Category, CategoryFieldsFilters, Location } from 'horizontal/types';
import { useFlatCategoryFields } from 'horizontal/categoryFields';

/**
 * Selects the the specified extra fields in the order in
 * which they appear in the registered category fields.
 *
 * Input: { make: 'apple', condition: 'new', model: 'iphone' },
 * Output: [{ make: 'apple' }, { model: 'iphone' }]
 *
 * In the example above, `model` is placed after `make` because it
 * is a child of `make`. `condition` is excluded because it does
 * not have the INCLUDED_IN_TITLE role.
 *
 * From each level, we only pick the first field that has a value,
 * because the rest will not be included in the breadcrumb.
 */
const selectExtraFieldsHierarchy = (
    categoryFields: Array<FlatCategoryField>,
    extraFields?: CategoryFieldsFilters | null,
): Array<CategoryFieldsFilters> => {
    if (!extraFields) {
        // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'Partial<{ [key: string]: CategoryFieldFilterValue; }>[]'.
        return EMPTY_ARRAY;
    }
    const fields = categoryFields.filter(
        (innerField) =>
            extraFields[innerField.attribute] &&
            innerField.roles.includes(CategoryFieldRole.FILTERABLE) &&
            innerField.roles.includes(CategoryFieldRole.INCLUDED_IN_BREADCRUMBS),
    );

    if (!fields.length) {
        // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'Partial<{ [key: string]: CategoryFieldFilterValue; }>[]'.
        return EMPTY_ARRAY;
    }

    return fields.reduce<Array<any>>(
        (accumulator, field) => [
            ...accumulator,
            {
                [field.attribute]: extraFields[field.attribute],
            },
        ],
        [],
    );
};

const mergeExtraFieldsHierarchy = (extraFieldsHierarchy: Array<CategoryFieldsFilters>) =>
    extraFieldsHierarchy.reduce<CategoryFieldsFilters>((acc, field) => ({ ...acc, ...field }), {});

const computeBreadcrumbs = (
    categories: Array<Category>,
    categoryFields: Array<FlatCategoryField>,
    locationHierarchy: Array<Location>,
    { category, freeTextQuery, extraFields }: SearchRouteParams,
): Array<Breadcrumb> => {
    const items: Array<Breadcrumb> = [
        {
            type: BreadcrumbType.LINK,
            route: RouteNames.HOME,
        },
    ];

    const location =
        locationHierarchy.length > 0 ? locationHierarchy[locationHierarchy.length - 1] : undefined;
    const categoryHierarchy = category
        ? findDeepList(categories, (cat) => cat.id === category.id, true)
        : [];

    const extraFieldsHierarchy = selectExtraFieldsHierarchy(categoryFields, extraFields);

    categoryHierarchy.slice(0, -1).forEach((categoryNode) => {
        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            // @ts-expect-error - TS2322 - Type 'Omit<never, "children">' is not assignable to type 'Category'.
            params: { category: categoryNode },
        });
    });

    if (!categoryHierarchy.length) {
        return items;
    }

    locationHierarchy.slice(1).forEach((locationNode) => {
        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            params: {
                category,
                location: locationNode.level !== 0 ? locationNode : null,
            },
        });
    });

    extraFieldsHierarchy.forEach((extraFieldsNode, index) => {
        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            params: {
                category,
                location,
                extraFields: mergeExtraFieldsHierarchy(extraFieldsHierarchy.slice(0, index)),
            },
        });
    });

    if (freeTextQuery) {
        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            params: {
                category,
                location,
                extraFields: mergeExtraFieldsHierarchy(extraFieldsHierarchy),
            },
        });
    }

    return items;
};

const useBreadcrumbs = ({
    category,
    location,
    freeTextQuery,
    extraFields,
}: SearchRouteParams): Array<Breadcrumb> => {
    const locationHierarchy = useFullLocationsHierarchy(location?.hierarchy || []);

    const categories = useSelector(selectCategories);
    const categoryFields = useFlatCategoryFields(category?.id);

    return React.useMemo(
        () =>
            // Pass params separately so that React.useMemo() properly
            // memoizes. If we'd pass the object, it would re-compute
            // every time because it does weak comparison and we get
            // passed a new object every time.
            computeBreadcrumbs(categories, categoryFields, locationHierarchy, {
                category,
                freeTextQuery,
                extraFields,
            }),
        [categories, categoryFields, category, freeTextQuery, extraFields, locationHierarchy],
    );
};

export default useBreadcrumbs;
