import Router from './router';
import RouteConfig from './routeConfig';
import RoutingContext from './routingContext';
import createHistory from './history';
import type { RoutingMiddlewareList } from './router';
import type { URLProcessor } from './urlProcessor';
import type { Location, EnhancedLocation } from './location';
import type { RouteParameters } from './route';
import { serializeLocation, parseLocation } from './location';

/**
 * Signature for the {@see HistoryRouter} callback.
 */
type Callback = (context: RoutingContext, action: string) => void;

/**
 * Hooks into the browser/memory history and performs routing
 * when the history changes.
 */
class HistoryRouter extends Router {
    /**
     * Methods to call when the router is invoked.
     */
    callbacks: Array<Callback>;

    /**
     * History object that, in a browser is tied
     * to the HTML5 history API and anywhere else
     * keeps a stack in memory.
     */
    history: {
        push: (location: Location) => void;
        replace: (location: Location) => void;
        goBack: () => void;
    };

    /**
     * Indicates if we need to invoke the router.
     */
    invoke: boolean;

    /**
     * Initializes a new instance of {@see HistoryRouter}.
     * @param routes List of routes to match incoming requests against.
     * @param middlewares Middlewares to call during routing.
     * get context to pass to the route handlers.
     */
    constructor(
        routes: RouteConfig,
        middlewares: RoutingMiddlewareList,
        urlProcessor: URLProcessor,
    ) {
        super(routes, middlewares, urlProcessor);

        this.history = createHistory();
        // @ts-expect-error - TS2339 - Property 'listen' does not exist on type '{ push: (location: Location) => void; replace: (location: Location) => void; goBack: () => void; }'.
        this.history.listen(this.onHistoryChanges.bind(this));

        this.callbacks = [];

        this.invoke = true;
    }

    /**
     * Registers a callback to be called when the router is invoked.
     * @param callback The method to be called when the router is invoked.
     */
    listen(callback: Callback): number {
        this.callbacks.push(callback);
        return this.callbacks.length - 1;
    }

    /**
     * Unregisters the callback at the specified index.
     * @param callbackIndex The number returned by {@see subscribe}.
     */
    unsubscribe(callbackIndex: number): void {
        if (callbackIndex < 0 || callbackIndex >= this.callbacks.length) {
            return;
        }

        this.callbacks.splice(callbackIndex, 1);
    }

    /**
     * Handler for when the history changes and the router needs
     * to be invoked.
     * @param location The {@see Location} object describing the
     * new location/URL.
     * @param action 'PUSH' or 'POP'
     */
    onHistoryChanges(location: Location, action: string): void {
        if (!this.invoke) {
            this.invoke = true;
            return;
        }

        const url = location.pathname + location.search + location.hash;
        const context = this.route(url);

        this.callbacks.forEach((callback: Callback) => callback(context, action));
    }

    /**
     * Pushes the specified route with the specified routes onto
     * the history stack.
     * @param name The name of the route.
     * @param params The parameters to pass to the route.
     * @param invoke Whether to invoke the router.
     */
    pushRoute(name: string, params: null | RouteParameters = null, invoke = true): void {
        const url: string = this.getRouteURL(name, params);

        if (this.isRouteInBundle(name)) {
            this.pushURL(url, invoke);
        } else {
            window.location.href = url;
        }
    }

    /**
     * Checks if the specified route is in the current bundle.
     * @param _routeName: the name of the route
     * @returns {boolean}
     */
    isRouteInBundle(_routeName: string): boolean {
        return false;
    }

    /**
     * Replaces the last entry on the history stack with the specified
     * route.
     * @param name The name of the route.
     * @param params The parameters to pass to the route.
     */
    replaceRoute(name: string, params: null | RouteParameters = null, invoke = true): void {
        const url: string = this.getRouteURL(name, params);
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'EnhancedLocation'.
        this.replaceURL(url, invoke);
    }

    /**
     * Pushes a URL onto the history stack.
     * @param url A string with the URL to route to.
     * @param invoke Whether to invoke the router.
     * @param forceUseInitialURL If true, the provided URL will not be processed.
     */
    pushURL(url: string, invoke = true, forceUseInitialURL = false): void {
        const destinationUrl = forceUseInitialURL ? url : serializeLocation(parseLocation(url));

        // by pushing directly into the window.history object,
        // we avoid invoking the router, since it cannot listen
        // to history pushes unless pushed into the history
        // wrapped that is this.history
        if (invoke) {
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Location'.
            this.history.push(destinationUrl);
        } else {
            this.context = this.createContext(destinationUrl);
            // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.
            window.history.pushState(null, null, destinationUrl);
        }
    }

    /**
     * Replaces the last entry on the history stack.
     * @param location A {@see Location} object describing the location to route to.
     */
    replaceURL(location: EnhancedLocation, invoke = true): void {
        const url = serializeLocation(parseLocation(location));
        if (invoke) {
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Location'.
            this.history.replace(url);
        } else {
            this.context = this.createContext(url);
            // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.
            window.history.replaceState(null, null, url);
        }
    }

    /**
     * Pops the last entry from the history stack.
     */
    pop(invoke = true): void {
        this.invoke = invoke;
        this.history.goBack();
    }
}

export default HistoryRouter;
