import * as React from 'react';

import { SocialDescriptionTags } from 'strat/social/tags';

type Props = {
    children: string;
};

const ListingMetaDescription = ({ children }: Props) => (
    <>
        <meta name="description" content={children} />
        <SocialDescriptionTags description={children} />
    </>
);

export default ListingMetaDescription;
