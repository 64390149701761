// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import { SearchRequestOrder, SearchRequestSortType } from '@sector-labs/fe-search-redux/backend';
import { AdProduct } from '@app/types';

import { selectKeyedSortOptions, SortOptionKey } from 'horizontal/search/sorting';

import type { SearchProps } from '../types';
import withCircleSlottingTypeSearchSettings from '../withCircleSlottingType/createAdsSearchSettings';

const createSortOption = (product: AdProduct) => {
    return {
        type: SearchRequestSortType.ATTRIBUTES,
        key: `newest-${product}-ads`,
        name: `Newest ${product} ads`,
        attributes: [
            { name: 'timestamp', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    } as const;
};
const createAdsSearchSettings = ({ getState, searchRouteParams }: SearchProps): SearchSettings => {
    const state = getState();
    const sortOptions = selectKeyedSortOptions(state);
    const mostRelevantSortOption = sortOptions[SortOptionKey.MOST_RELEVANT];
    const sortOption = {
        ...mostRelevantSortOption,
        attributes: [
            { name: 'productScore', order: SearchRequestOrder.DESC },
            { name: 'timestamp', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    };

    const featuredSort = createSortOption(AdProduct.FEATURED);
    const eliteSort = createSortOption(AdProduct.ELITE);

    return withCircleSlottingTypeSearchSettings({
        getState,
        searchRouteParams: { ...searchRouteParams, sortOption },
        // @ts-expect-error - TS2322 - Type '{ readonly type: SearchRequestSortType.ATTRIBUTES; readonly key: "newest-featured-ads"; readonly name: "Newest featured ads"; readonly attributes: readonly [{ readonly name: "timestamp"; readonly order: SearchRequestOrder.DESC; }, { ...; }]; }' is not assignable to type 'SearchRequestSortOption | null | undefined'.
        featuredSort,
        eliteSort,
    });
};

export default createAdsSearchSettings;
