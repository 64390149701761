import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { FloatingDialog } from 'strat/components';

import type { FullAd } from 'horizontal/types';
import { AdState } from 'horizontal/types';

import { RemoveAdContent } from './removeAd';

type Props = {
    ad: FullAd;
    onCancel: () => void;
    onSuccess: () => void;
    onVisibilityChanged: (arg: boolean) => void;
    visible: boolean;
};

const RemoveAdDialog = ({ ad, onCancel, onSuccess, ...rest }: Props) => {
    const i18n = useI18n();

    return (
        <FloatingDialog {...rest} dismissible>
            {ad ? (
                <RemoveAdContent
                    ad={ad}
                    onCancelPress={onCancel}
                    onSuccess={onSuccess}
                    title={t(i18n)`Confirm`}
                    description={t(
                        i18n,
                    )`You are about to delete your Ad. You won't be able to undo this.`}
                    reason={AdState.DELETED}
                    disableButtonText={t(i18n)`DELETE`}
                />
            ) : null}
        </FloatingDialog>
    );
};

export default RemoveAdDialog;
