import { connect } from 'react-redux';

import { buildAllCanonicalURLs } from 'strat/routing';
import { HomeRoute } from 'strat/routes';

/**
 * Connector that provides the canonical URL for the home page as a prop.
 */
export default connect((state, ownProps) => {
    const path = HomeRoute.createURL(null).pathname;
    // @ts-expect-error - TS2339 - Property 'language' does not exist on type '{}'. | TS2339 - Property 'language' does not exist on type '{}'. | TS2571 - Object is of type 'unknown'.
    return buildAllCanonicalURLs(path, ownProps.language ? ownProps.language : state.i18n.language);
});
