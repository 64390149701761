import React from 'react';
import { connect } from 'react-redux';
import settings from '@app/branding/settings';
import { ReplaceParameters } from '@sector-labs/templates-renderer';

import Purpose from 'strat/purpose';
import Area from 'strat/i18n/area';
import { FilterValues } from 'strat/search';
import {
    TemplateType,
    TemplatePage,
    findAllTemplates,
    renderTemplate,
    renderAllTemplates,
    selectTemplates,
} from 'strat/templates';
import { SocialTitleTags, SocialDescriptionTags } from 'strat/social/tags';
import type { TemplateCollection } from 'strat/templates';
import connectLocalPrice from 'strat/i18n/money/connectLocalPrice';
import connectLocalUnits from 'strat/i18n/connectLocalUnits';
import { connectLanguage, useI18n } from 'strat/i18n/language';
import { canonicalDomain } from 'strat/routing';
import { sanitizeDescription } from 'strat/seo';
import { GlobalState } from 'strat/state';
import { selectSettings } from 'strat/settings/selectors';

import { propertyDefaultDescription } from './seo';
import type { PropertyData } from './types';
import { selectProperty } from './selectors';

type Props = {
    type: Values<typeof TemplateType>;
    className?: any;
    toLocalPrice: (value: number) => number;
    formatLocalPriceValue: (value: number) => string;
    areaInLocalUnits: (value: number) => number;
    formatLocalUnitsValue: (value: number) => string;
    property: PropertyData;
    templates: TemplateCollection;
    language: string;
    settings: {
        currency: string;
        area: string;
    };
    formatNumber: (arg1: number) => string;
};

const render = (templates: Array<string>, replaceParameters: ReplaceParameters) => {
    if (settings.templates && settings.templates.tryAllMatchesForProperty) {
        return renderAllTemplates({ templates, replaceParameters });
    }
    return renderTemplate({ template: templates[0], replaceParameters });
};

const PropertyTemplate = (props: Props) => {
    const i18n = useI18n();
    let renderedTemplate = null;

    const templates = findAllTemplates({
        type: props.type,
        page: TemplatePage.PROPERTY,
        categoryExternalID: props.property.category[props.property.category.length - 1].externalID,
        locationSlugs: props.property.location.map((location) => location.slug).reverse(),
        purpose: props.property.purpose,
        templates: props.templates,
    });

    if (templates.length) {
        let rentFrequency = Purpose.isForRent(props.property.purpose)
            ? props.property.rentFrequency
            : undefined;

        if (rentFrequency) {
            rentFrequency =
                (FilterValues.rentFrequency.choice(i18n, rentFrequency) || {}).name.toLowerCase() ||
                undefined;
        }

        const locationHierarchy = props.property.location;
        const length = locationHierarchy.length;

        // The location hierarchy, excluding the country and the region.
        const cityBoundLocationHierarchy = locationHierarchy
            .filter((loc) => loc.level >= CONFIG.build.CITY_LEVEL)
            .map((loc) => loc.name)
            .reverse()
            .join(', ');

        let location = null;
        if (length > 2) {
            location = `${locationHierarchy[length - 1].name}, ${
                locationHierarchy[length - 2].name
            }`;
        } else {
            location = `${locationHierarchy[length - 1].name}`;
        }

        const description = sanitizeDescription(props.property.description, 215);
        const shortDescription = description.split(' ').slice(0, 8).join(' ');

        const categorySlug = (props.property.category[1] || {}).slug;
        const category = FilterValues.category.render(i18n, categorySlug);

        const categoryParentSlug = (props.property.category[0] || {}).slug;
        const categoryParent = FilterValues.category.render(i18n, categoryParentSlug);

        const replaceParameters = {
            rentFrequency,
            externalID: props.property.externalID,
            title: props.property.title,
            price: props.formatLocalPriceValue(props.toLocalPrice(props.property.price)),
            photoCount: props.property.photoCount,
            currency: props.settings.currency,
            area: props.formatLocalUnitsValue(props.areaInLocalUnits(props.property.area)),
            areaUnit: Area.abbreviation(i18n, props.settings.area),
            // @ts-expect-error - String not assignable to number
            rooms: props.formatNumber(props.property.rooms || ''),
            baths: props.property.baths,
            referenceNumber: props.property.referenceNumber || '-',
            description,
            shortDescription,
            slug: props.property.slug,
            category: category ? category.toLowerCase() : category,
            categoryParent: categoryParent ? categoryParent.toLowerCase() : categoryParent,
            uppercaseCategoryPlural: FilterValues.category.render(i18n, categorySlug, true),
            locationHierarchy: props.property.labels.locationHierarchy,
            cityBoundLocationHierarchy,
            location,
            locationLevel2: (locationHierarchy[1] || {}).name,
            locationLevel3: (locationHierarchy[2] || {}).name,
            locationLevel4: (locationHierarchy[3] || {}).name,
            primaryPhoneNumber: props.property.primaryPhoneNumber || '-',
            purpose: Purpose.textAsVerb(i18n, props.property.purpose),
            agency:
                props.property.agency && props.property.agency.name
                    ? props.property.agency.name
                    : '-',
        } as const;

        renderedTemplate = render(templates, replaceParameters);
    }

    const domainName = canonicalDomain().rootDomainTitle;
    switch (props.type) {
        case TemplateType.TITLE: {
            const result = renderedTemplate || `${props.property.title} | ${domainName}`;
            return (
                <>
                    <title key="title" className={props.className}>
                        {result}
                    </title>
                    <SocialTitleTags key="socialMediaTitle" title={result} />
                </>
            );
        }
        case TemplateType.META: {
            const result = renderedTemplate || propertyDefaultDescription(i18n, props.property);
            return (
                <>
                    <meta key="description" name="description" content={result} />
                    <SocialDescriptionTags key="socialMediaDescription" description={result} />
                </>
            );
        }
        case TemplateType.H1:
            return <h1 className={props.className}>{renderedTemplate || props.property.title}</h1>;
        default:
            return null;
    }
};

export { TemplateType };

export default connectLocalUnits(
    // @ts-expect-error - TS2345 - Argument of type 'ConnectedComponent<ComponentType<Omit<Props, "i18n">>, { context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } | { ...; }>' is not assignable to parameter of type 'AbstractComponent<{ context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; }, any>'.
    connectLocalPrice(
        // @ts-expect-error - TS2345 - Argument of type 'ConnectedComponent<ComponentType<Omit<Diff<Props, OutProps> & RefAttributes<any>, "i18n">>, { className?: any; ref?: Ref<any> | undefined; ... 7 more ...; store?: any; } | { ...; }>' is not assignable to parameter of type 'Component<any, {}, any>'.
        connect((state: GlobalState) => ({
            property: selectProperty(state),
            templates: selectTemplates(state),
            settings: selectSettings(state),
        }))(connectLanguage(PropertyTemplate)),
    ),
);
