import { AdFormat } from 'horizontal/types';
import type { MixedAd } from 'horizontal/types';

const selectAdExpiresAtFormatted = (ad: MixedAd): string | null | undefined => {
    switch (ad.format) {
        case AdFormat.FULL:
            // @ts-expect-error - TS2339 - Property 'expiresAt' does not exist on type 'MixedAd'.
            if (!ad.expiresAt) {
                return null;
            }

            // @ts-expect-error - TS2339 - Property 'expiresAt' does not exist on type 'MixedAd'.
            return new Date(ad.expiresAt * 1000).toISOString();

        default:
            return null;
    }
};

export default selectAdExpiresAtFormatted;
