import * as React from 'react';

type Props = {
    readonly renderIcon: (isHighlighted: boolean) => React.ReactElement;
    readonly link: string;
    readonly linkClassName?: string;
    readonly onClick?: () => void;
    readonly isHighlighted: boolean;
};

const HeaderIconLink = ({
    renderIcon,
    link,
    linkClassName,
    onClick,
    isHighlighted = false,
}: Props) => {
    return (
        <a href={link} className={linkClassName} onClick={onClick}>
            {renderIcon(isHighlighted)}
        </a>
    );
};

export default HeaderIconLink;
