import { t } from '@lingui/macro';
import * as React from 'react';

import { SearchElementIdentifiers } from 'strat/search';
import { Flex } from 'strat/components';
import IconRightArrow from 'strat/assets/icons/iconRightArrowSquare.svg';
import { LocalCurrency, LocalPrice } from 'strat/i18n/money';
import { scrollToElement } from 'strat/navigation/scroll';
import { useI18n } from 'strat/i18n/language';
import { PROJECT_PRICE_FORMATTING_OPTIONS } from 'strat/project';

import ProjectTopDetailsItem from './projectTopDetailsItem';
import styles from './styles/projectPriceInformationSection.cssm';
import { availableUnitsSectionElementID } from './projectIdentifiers';

type Props = {
    price: number;
};

const PriceInfo = ({ price }: Props) => (
    <>
        <Flex alignEnd className={styles.currency}>
            <LocalCurrency />
        </Flex>{' '}
        <LocalPrice price={price} options={PROJECT_PRICE_FORMATTING_OPTIONS} />
        <IconRightArrow
            className={styles.arrow}
            onClick={() => {
                const headerHeight =
                    document.getElementById(SearchElementIdentifiers.compactSearchPageHeaderId)
                        ?.offsetHeight || 0;
                scrollToElement(
                    document.getElementById(availableUnitsSectionElementID),
                    -(headerHeight + 30),
                );
            }}
        />
    </>
);

const ProjectPriceInformationSection = ({ price }: Props) => {
    const i18n = useI18n();

    return (
        <ProjectTopDetailsItem
            renderLabel={() => t(i18n)`Starting From`}
            renderInfo={() => <PriceInfo price={price} />}
            linkOnClick={() => {
                const headerHeight =
                    document.getElementById(SearchElementIdentifiers.searchPageHeaderId)
                        ?.offsetHeight || 0;
                scrollToElement(
                    document.getElementById(availableUnitsSectionElementID),
                    -(headerHeight + 30),
                );
            }}
            linkText={t(i18n)`View unit types`}
        />
    );
};

export default ProjectPriceInformationSection;
