import { t } from '@lingui/macro';
import React from 'react';
import { useI18n } from 'strat/i18n/language';
import { useSelector } from 'react-redux';

import { selectCategoryByExternalID } from 'horizontal/categories/selectors';
import AdUtils from 'horizontal/ad/utils';
import { TransactionPurpose } from 'horizontal/types';
import type { MixedAd } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

import styles from './styles/purposeBadge.cssm';

interface Props {
    ad: MixedAd;
}

const PurposeBadge = ({ ad }: Props) => {
    const i18n = useI18n();

    const label = useSelector((state: GlobalState) => {
        const category = selectCategoryByExternalID(state, AdUtils.getLeafCategory(ad)?.externalID);

        if (!category || !AdUtils.shouldShowPurposeType(ad)) {
            return null;
        }

        switch (category.purpose) {
            case TransactionPurpose.FOR_RENT:
                return t(i18n)`For Rent`;
            case TransactionPurpose.FOR_SALE:
                return t(i18n)`For Sale`;
            default:
                return null;
        }
    });

    if (!label) {
        return null;
    }

    return (
        <div className={styles.tag} aria-label={`${label}`}>
            {label}
        </div>
    );
};

export default PurposeBadge;
