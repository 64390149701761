import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import { SavedSearchParameters } from '@app/search/savedSearches/constants';
import PlatformCompat from '@app/compat/platformCompat';

import Purpose from 'strat/purpose';
import { PropertyCompletionStatus } from 'strat/property/types';

import type { SavedSearchParams } from './types';

const requiresOrderingParams = [
    'locations',
    'locationExternalIDs',
    'beds',
    'baths',
    'agencies',
    'keywords',
];

const isEmptyArray = (value: any): boolean => Array.isArray(value) && value?.length === 0;

/**
 * Fixes data discrepancies between Strat and APIv6 & compatibility modules.
 */
// @ts-expect-error - TS7006 - Parameter 'paramName' implicitly has an 'any' type. | TS7006 - Parameter 'paramValue' implicitly has an 'any' type. | TS7006 - Parameter 'savedSearchParams' implicitly has an 'any' type.
const fixDataInconsistency = (paramName, paramValue, savedSearchParams): any => {
    if (paramName === 'searchMode') {
        // do not save default search mode
        return paramValue === 'default' ? null : paramValue;
    }
    // TODO: below hacks can be removed after enabling saved searches on Strat
    if (paramName === 'hasFloorPlan') {
        /**
         * APIv6 and Bayut compatibility module returns boolean value for this parameter, but
         * StratAPI should return the search route parameter format {@see createSearchRouteParams}
         */
        if (paramValue === false) {
            return null;
        } else if (paramValue === true) {
            return { min: 1, max: null };
        }
        return paramValue;
    }
    if (paramName === 'rentFrequency') {
        // Ignore rentFrequency parameter in case purpose is NOT 'for-rent'
        return savedSearchParams.purpose === Purpose.FOR_RENT ? paramValue : null;
    }
    if (paramName === 'completionStatus') {
        /**
         * Ignore completionStatus parameter in case is has `any` value or
         * purpose is 'for-rent', as for these cases the filter is not active.
         */
        const ignoreCompletionStatus =
            savedSearchParams.purpose === Purpose.FOR_RENT ||
            paramValue === PropertyCompletionStatus.ANY;
        return ignoreCompletionStatus ? null : paramValue;
    }
    if (['price', 'area'].includes(paramName) && isObject(paramValue)) {
        /**
         * This fixes/addresses more problems:
         * 1. a bug with price and area search filters/route params
         *    - 'resetting' the filter values through selection => `{min:0, max:null}`
         *    - 'resetting' the filter values through the 'reset' button => `null`
         * 2. APIv6 returns strings instead of numbers ("0" as default/null)
         * 3. Compatibility module is different for explorer and mustang
         *    - mustang filters out null and "0" values
         *    - explorer does not filter out null and "0" values
         * 4. Search route url ignores `0` and `null` values for price,
         * so there is no point in passing those values to it.
         */
        const range: Record<string, any> = {};
        // @ts-expect-error - TS2339 - Property 'min' does not exist on type 'object'. | TS2339 - Property 'min' does not exist on type 'object'.
        if (paramValue?.min && parseFloat(paramValue.min) !== 0) {
            // @ts-expect-error - TS2339 - Property 'min' does not exist on type 'object'.
            range.min = parseFloat(paramValue.min);
        }
        // @ts-expect-error - TS2339 - Property 'max' does not exist on type 'object'.
        if (paramValue?.max) {
            // @ts-expect-error - TS2339 - Property 'max' does not exist on type 'object'.
            range.max = parseFloat(paramValue.max);
        }
        if (!Object.keys(range).length) {
            return null;
        }
        return range;
    }
    return paramValue;
};

/**
 * Cleans the saved search params: removes undefined/null/empty list/default
 * values in order not to store them in the database.
 */
const cleanSavedSearchParams = (savedSearchParams: SavedSearchParams): any => {
    return Object.keys(savedSearchParams).reduce<Record<string, any>>(
        (cleanedParams, paramName) => {
            const finalParamValue = fixDataInconsistency(
                paramName,
                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Partial<Partial<{ purpose: string; locations: SearchLocationNode[]; locationsByLanguage: { [key: string]: SearchLocationNode[]; }; locationPrefix: string | null | undefined; ... 56 more ...; showListingScore: boolean | ... 1 more ... | undefined; }> & { ...; }> & { ...; }>'.
                savedSearchParams[paramName],
                savedSearchParams,
            );
            if (isNil(finalParamValue)) {
                return cleanedParams;
            }
            if (isEmptyArray(finalParamValue)) {
                return cleanedParams;
            }
            cleanedParams[paramName] = finalParamValue;
            return cleanedParams;
        },
        {},
    );
};

/**
 * Filters & cleans saved search params.
 *
 * Sorts the parameters that are of array type, to make
 * sure they are consistent and won't affect the comparison
 * (as search url does not guarantee ordering of those).
 * This will help to avoid saving searches with parameters
 * that are already saved (but have different ordering).
 */
const filterSavedSearchParams = (
    savedSearchParams: SavedSearchParams,
    paramsToFilter: readonly string[],
): any => {
    const filteredSavedSearchParams = pick(savedSearchParams, paramsToFilter);
    const cleanedSavedSearchParams = cleanSavedSearchParams(filteredSavedSearchParams);

    requiresOrderingParams.forEach((parameter) =>
        (cleanedSavedSearchParams[parameter] || []).sort(),
    );

    return cleanedSavedSearchParams;
};

/**
 * Used when comparing saved search params with search route params
 * to determine if the current search is a saved search.
 *
 * Makes sure to compare just the filtered params,
 * as they represent the params stored in the database.
 */
const compareSavedSearchParams = (
    savedSearchParams: SavedSearchParams,
    currentRouteParams: SavedSearchParams,
): boolean => {
    const filteredSavedParams = filterSavedSearchParams(savedSearchParams, SavedSearchParameters);
    const filteredRouteParams = filterSavedSearchParams(currentRouteParams, SavedSearchParameters);
    if (CONFIG.runtime.ENABLE_STRAT_SAVED_SEARCHES) {
        return isEqual(filteredSavedParams, filteredRouteParams);
    }
    return PlatformCompat.SearchParams.compare(filteredSavedParams, filteredRouteParams);
};

export { compareSavedSearchParams, filterSavedSearchParams, cleanSavedSearchParams };
