import * as React from 'react';

import IconMicrophone from 'horizontal/assets/icons/iconMicrophone.svg';

type Props = {
    readonly className?: string;
};

const RecordButton = ({ className }: Props) => {
    return (
        <button className={className}>
            <IconMicrophone />
        </button>
    );
};

export default RecordButton;
