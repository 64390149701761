import * as React from 'react';
import { Flex } from 'strat/components';

import CategoryImageMapping from '@app/branding/categoryImageMapping';

import type { PartialCategory } from '../types';

type Props = {
    readonly category: PartialCategory;
    readonly onClick?: () => void;
    readonly imageClassName?: string;
    readonly containerClassName?: string;
};

export const categoryHasImage = (category: PartialCategory): boolean => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly mobiles: any; readonly vehicles: any; readonly 'property-for-rent': any; readonly 'property-for-sale': any; readonly 'electronics-home-appliances': any; readonly bikes: any; readonly 'business-industrial-agriculture': any; ... 20 more ...; readonly properties: any; }'.
    return CategoryImageMapping[category.slug] != null;
};

const CategoryImage = ({ category, onClick, imageClassName, containerClassName }: Props) => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly mobiles: any; readonly vehicles: any; readonly 'property-for-rent': any; readonly 'property-for-sale': any; readonly 'electronics-home-appliances': any; readonly bikes: any; readonly 'business-industrial-agriculture': any; ... 20 more ...; readonly properties: any; }'.
    if (!CategoryImageMapping[category.slug]) {
        return null;
    }

    return (
        <Flex justifyCenter alignCenter onClick={onClick} className={containerClassName}>
            <img
                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly mobiles: any; readonly vehicles: any; readonly 'property-for-rent': any; readonly 'property-for-sale': any; readonly 'electronics-home-appliances': any; readonly bikes: any; readonly 'business-industrial-agriculture': any; ... 20 more ...; readonly properties: any; }'.
                src={CategoryImageMapping[category.slug]}
                className={imageClassName}
                alt={category.name}
            />
        </Flex>
    );
};

export default CategoryImage;
