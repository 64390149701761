import * as React from 'react';

import styles from './styles/infoBox.cssm';

type Props = {
    readonly className?: string;
    readonly children: React.ReactNode;
};

const InfoBox = ({ children, className }: Props) => {
    return <div className={className || styles.infoBox}>{children}</div>;
};

export default React.memo<Props>(InfoBox);
