import * as React from 'react';

import { ViewSections } from 'strat/gtm';
import { AgencyInfo, ContactFormButtons, ContactForm } from 'strat/contact';
import { InternalAPI } from 'strat/api';
import type { GTMMapTrackingProps, GTMLeadTrackingProps } from 'strat/gtm';
import type { PropertyData } from 'strat/property';
import Designs from 'strat/branding/designs';

import styles from './styles/mapContactForm.cssm';

type MapContactFormProps = GTMMapTrackingProps &
    GTMLeadTrackingProps & {
        contactButtons: React.ReactNode;
        agencyInfo: React.ReactNode;
        property: PropertyData;
        hideEmailForm: boolean;
        buttonsClassName?: string;
        containerClassName?: string;
        contactFormDesign?: Values<typeof Designs>;
    };

const MapContactForm = ({
    property,
    trackEmailLead,
    trackCallView,
    trackCallLead,
    // @ts-expect-error - TS2339 - Property 'addSilentAlert' does not exist on type 'MapContactFormProps'.
    addSilentAlert,
    hideEmailForm = false,
    contactButtons = ContactFormButtons,
    agencyInfo,
    buttonsClassName,
    containerClassName,
    contactFormDesign,
}: MapContactFormProps) => {
    const {
        externalID,
        agency,
        contactName,
        permitNumber,
        referenceNumber,
        active,
        primaryPhoneNumber,
        hasEmail,
        requiresLoginForContact,
    } = property;

    const onSendEmailClick = React.useCallback(
        (...args) => {
            // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
            trackEmailLead(ViewSections.MAP_POPUP, ...args);

            if (CONFIG.build.ENABLE_STRAT_EMAILS) {
                // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                new InternalAPI().sendEmail(externalID, ...args);
            }

            if (CONFIG.build.SHOW_AGENT_REVIEWS) {
                // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
                new InternalAPI().scheduleReviewRequestReminder(externalID, ...args);
            }
        },
        [externalID, trackEmailLead],
    );
    const onCallClick = React.useCallback(
        () => trackCallView(ViewSections.MAP_POPUP),
        [trackCallView],
    );
    const onPhoneNumberClick = React.useCallback(
        () => trackCallLead(ViewSections.MAP_POPUP),
        [trackCallLead],
    );
    const onKeepMeInformed = React.useCallback(
        (values) => addSilentAlert(values, property),
        [addSilentAlert, property],
    );

    return (
        <div className={containerClassName || styles.contactAgency} aria-label="Contact agency">
            {agencyInfo || (
                <AgencyInfo
                    // @ts-expect-error - TS2322 - Type '{ viewSection: string; agency: AgencyData | null; contactName: string | null; permitNumber: string | null; agencyLogoClassName: any; referenceNumber: string; hideViewButton: true; }' is not assignable to type 'IntrinsicAttributes & Omit<withI18nProps, "i18n"> & { children?: ReactNode; }'.
                    viewSection={ViewSections.MAP_POPUP}
                    agency={agency}
                    contactName={contactName}
                    permitNumber={permitNumber}
                    agencyLogoClassName={styles.agencyLogo}
                    referenceNumber={referenceNumber}
                    hideViewButton
                />
            )}
            {active && (
                <div className={styles.contact}>
                    <ContactForm
                        contactButtons={contactButtons}
                        buttonsClassName={buttonsClassName || styles.contactFormButtons}
                        externalID={externalID}
                        phoneNumber={primaryPhoneNumber}
                        referenceNumber={referenceNumber}
                        hasEmail={hasEmail}
                        requiresLoginForContact={requiresLoginForContact}
                        onCallClick={onCallClick}
                        onPhoneNumberClick={onPhoneNumberClick}
                        onSendEmailClick={onSendEmailClick}
                        onKeepMeInformed={onKeepMeInformed}
                        viewSection={ViewSections.MAP_POPUP}
                        hideEmailForm={hideEmailForm}
                        hideCallButton={false}
                        withIcon={contactFormDesign !== Designs.FORM_DESIGN_2022}
                        design={contactFormDesign}
                    />
                </div>
            )}
        </div>
    );
};

export type { MapContactFormProps };
export default MapContactForm;
