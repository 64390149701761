import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useRouter } from 'react-true-router';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex, Text, OTPInput, Button } from 'strat/components';
import { useResendCountDown, secondsTillResend } from 'strat/auth/keycloak';

import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';
import MobilePaymentOTPIcon from '@app/assets/icons/mobile_payment_otp_noinline.svg';
import XLoaderIcon from 'horizontal/assets/icons/x_loader.svg';
import CheckIcon from 'horizontal/assets/icons/iconCheckThick.svg';
import { useNavigateToSuccessPage } from 'horizontal/payment/hooks';

import Header from '../header';

import styles from './styles/tPayPaymentOTP.cssm';

type Props = {
    readonly sessionID: string;
    readonly phoneNumber: string;
    readonly orderExternalID: string;
};

const otpLength = 6;

const MobilePaymentOTP = ({ phoneNumber, sessionID, orderExternalID }: Props) => {
    const router = useRouter();
    const i18n = useI18n();
    const [otp, setOTP] = React.useState('');
    const [otpError, setOTPError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isResent, setIsResent] = React.useState(false);
    const makeCancelable = useMakeCancelable();
    const [resetTimer, setResetTimer] = React.useState(false);
    const timeLeft = useResendCountDown(secondsTillResend, resetTimer);
    const navigateToSuccessPage = useNavigateToSuccessPage();

    const handleResend = () => {
        const cancelablePromise = makeCancelable(new StratAPI().tpayResend(sessionID));
        setIsLoading(true);
        // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
        cancelablePromise.then(({ data, status }) => {
            setIsLoading(false);
            setTimeout(() => {
                setResetTimer(false);
            }, secondsTillResend * 1000);
            setResetTimer(true);
            if (status === 200 && data.status === 'SUCCESS') {
                setIsResent(true);
            }
        });
    };

    const handleSubmit = React.useCallback(() => {
        const cancelablePromise = makeCancelable(
            new StratAPI().tpayConfirm({
                sessionID,
                code: otp,
                language: i18n.locale,
            }),
        );

        setOTPError('');
        setIsLoading(true);
        // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
        cancelablePromise.then(({ data, status }) => {
            setIsLoading(false);
            setTimeout(() => {
                setResetTimer(false);
            }, secondsTillResend * 1000);
            setResetTimer(true);
            if (status !== 200) {
                setOTPError(t(i18n)`Unexpected error occurred. Please try again later.`);
            }

            if (data.status === 'ERROR') {
                setOTPError(data.message);
            }

            if (data.status === 'SUCCESS') {
                navigateToSuccessPage({
                    orderExternalID,
                });
            }
        });
    }, [sessionID, otp, orderExternalID, navigateToSuccessPage, makeCancelable, i18n]);

    React.useEffect(() => {
        if (otp.length === otpLength) {
            handleSubmit();
        }
    }, [otp, handleSubmit]);

    return (
        <section>
            <Header onBack={() => router.pop()} />
            {isLoading && (
                <div className={styles.loaderDim}>
                    <XLoaderIcon className={styles.loaderIcon} />
                </div>
            )}
            {isResent && (
                <Flex justifyCenter className={styles.resendSuccessContainer}>
                    <p className={styles.resendSuccess}>
                        <CheckIcon className={styles.checkIcon} />
                        <Trans>A new code has been sent to your phone</Trans>
                    </p>
                </Flex>
            )}
            <Flex column className={styles.pageWrapper}>
                <Flex column alignCenter>
                    <img
                        src={MobilePaymentOTPIcon}
                        alt={'Mobile payment one time passowrd'}
                        className={styles.mobileIcon}
                    />
                    <Text.Base className={styles.mainText}>
                        <Trans>
                            Enter the 6 digit code that we sent to{' '}
                            <strong>
                                {settings.defaultPhoneNumber.prefix}
                                {phoneNumber}
                            </strong>
                        </Trans>
                    </Text.Base>
                </Flex>

                <Flex justifyCenter>
                    <OTPInput
                        length={otpLength}
                        value={otp}
                        errorMessage={otpError}
                        onChange={setOTP}
                    />
                </Flex>

                <Flex column className={styles.footer}>
                    <div className={styles.resendSuccessContainer}>
                        {timeLeft > 0 && (
                            <span className={styles.resendLabel}>
                                {t(i18n)`After ${timeLeft} seconds you can `}
                            </span>
                        )}
                    </div>
                    <Button
                        action
                        small
                        stretch
                        disabled={isLoading || timeLeft > 0}
                        onClick={handleResend}
                    >
                        {t(i18n)`Resend code`}
                    </Button>
                </Flex>
            </Flex>
        </section>
    );
};

export default MobilePaymentOTP;
