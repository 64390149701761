/**
 * Construct the url for a custom style create with mapbox studio.
 *
 * @param owner - Mapbox account
 * @param id - the style identifier
 */
export const styleURL = (id: string, owner = 'mapbox') => `mapbox://styles/${owner}/${id}`;

export const jsonURL = (id: string, owner = 'mapbox') =>
    `https://api.mapbox.com/styles/v1/${owner}/${id}?access_token=${CONFIG.build.MAPBOX_API_KEY}`;

export const rasterTileURL = (id: string, owner = 'mapbox') =>
    `https://api.mapbox.com/styles/v1/${owner}/${id}/tiles/{z}/{x}/{y}?access_token=${CONFIG.build.MAPBOX_API_KEY}`;

export const MAPBOX_OWNER = 'devzameen';
export const DEFAULT_MAPBOX_OWNER = 'mapbox';

/**
 * Mapbox let us create a custom styles using mapbox studio.
 * In order to import them we need to provide an url with a specific structure. See @style.
 *
 * STRAT -  Mapbox Studio Custom style for our portals
 */
const MapStyleTypes: {
    [key: string]: string;
} = Object.freeze({
    STREETS: 'streets-v10',
    LIGHT: 'light-v9',
    DARK: 'dark-v9',
    OUTDOORS: 'outdoors-v10',
    SATELLITE: 'satellite-v9',
    SATELLITE_STREETS: 'satellite-streets-v10',
    STRAT: 'ckpgvk1cu023g17pdhm0ndhcg',
});

/**
 * Map View type used in the route params.
 */
export const MapViewName = Object.freeze({
    DEFAULT: 'default',
    SATELLITE: 'satellite',
});

export default MapStyleTypes;
