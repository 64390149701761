import isNil from 'lodash/isNil';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import settings from '@app/branding/settings';

import translationCatalog from '@app/branding/translations/agencyRoutes';
import type { EnhancedLocation } from 'react-true-router/location';
import Purpose from 'strat/purpose';
import type { SearchParams as SearchParamsBase } from 'strat/search';
import { createSearchRouteParams as stratSearchFiltersToRouteParams } from 'strat/search/url';
import FilterValues from 'strat/search/filterValues';
import { SortByValues } from 'strat/search/sortValues';
import translate from 'strat/i18n/translate';
import { PropertyfurnishingStatus } from 'strat/property/types';
import { getLocalizedAttribute } from 'strat/i18n';
import KnownURLParams from 'strat/branding/knownParameters';
import { SearchLocationNode } from 'strat/search/types';

export type SearchParams = SearchParamsBase & {
    agencySlug?: string;
    agencySlug_l1?: string;
    currentLanguage?: string;
    activeTab?: string;
    proboardLocations?: Array<SearchLocationNode>;
    proboardCategory?: string;
    proboardPurpose?: string;
};

/**
 * Extract a multi-language slug from location filters.
 * The information we need is present in the location.hierarchy, in the slug and slug_l1 keys.
 * slug is the slug of the currentLanguage and slug_l1 is the slug for the "opposite language"
 *
 * We return the information in an object with the language codes as the keys.
 */
const filtersToLocationsByLanguage = (filterLocations: any, currentLanguage: string) =>
    settings.languages.reduce<Record<string, any>>((prev, { lang }) => {
        prev[lang] = filterLocations
            // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
            .map((loc) =>
                typeof loc === 'object'
                    ? loc.hierarchy?.[loc.hierarchy.length - 1]?.[
                          getLocalizedAttribute(
                              'slug',
                              currentLanguage,
                              lang,
                              CONFIG.build.ENABLE_MERGED_INDEX,
                          )
                      ]
                    : loc,
            )
            // @ts-expect-error - TS7006 - Parameter 'loc' implicitly has an 'any' type.
            .map((loc) => ({ slug: loc }));

        return prev;
    }, {});

const searchFiltersToRouteParams = (
    filters: FilterCollection,
    extraParams?: any,
    currentLanguage?: string,
) => {
    const searchLocations = filters.getFilterValue(FilterValues.agentLocation.attribute, []);
    const proboardLocations = filters.getFilterValue(
        FilterValues.agentLocation.truBrokerAttribute,
        [],
    );
    const detailsLocations = filters.getFilterValue(FilterValues.location.attribute, []);
    // @ts-expect-error - TS2488 - Type 'unknown' must have a '[Symbol.iterator]()' method that returns an iterator. | TS2488 - Type 'unknown' must have a '[Symbol.iterator]()' method that returns an iterator.
    const locations = [...searchLocations, ...detailsLocations];
    let locationsByLanguage: Record<string, any> = {};
    let localizedAgencySlug = null;

    const filteredAgencySlug = filters.getFilterValue(FilterValues.agencySlug.attribute);
    if (CONFIG.build.LOCALIZED_ROUTES) {
        if (Array.isArray(filteredAgencySlug) && filteredAgencySlug.length > 1) {
            localizedAgencySlug = {
                agencySlug: filteredAgencySlug[0],
                agencySlug_l1: filteredAgencySlug[1],
            };
        }
        if (currentLanguage) {
            locationsByLanguage = {
                locationsByLanguage: filtersToLocationsByLanguage(
                    // @ts-expect-error - TS2488 - Type 'unknown' must have a '[Symbol.iterator]()' method that returns an iterator. | TS2488 - Type 'unknown' must have a '[Symbol.iterator]()' method that returns an iterator.
                    [...searchLocations, ...detailsLocations],
                    currentLanguage,
                ),
            };
        }
    }

    const filterAgencies: Array<{ externalID: string }> | string = filters.getFilterValue(
        'externalID',
        [],
    );
    const agencies = Array.isArray(filterAgencies)
        ? (filterAgencies as Array<{ externalID: string }>).map(
              (agency: { externalID: string }) => agency.externalID,
          )
        : [];
    // @ts-expect-error - TS2322 - Type '{ agencySlug: any; agencySlug_l1: any; } | { agencySlug: unknown; }' is not assignable to type '{ agencySlug?: string | undefined; agencySlug_l1?: string | undefined; }'.
    const agencySlug: {
        agencySlug?: string;
        agencySlug_l1?: string;
    } = localizedAgencySlug ? { ...localizedAgencySlug } : { agencySlug: filteredAgencySlug };

    const extraRouteParams: any = {
        ...agencySlug,
        locations,
        ...locationsByLanguage,
        agencies,
        ...extraParams,
    };

    if (CONFIG.build.STRAT_ENABLE_AGENTS_SEARCH) {
        extraRouteParams.specialities = filters.getFilterValue('speciality_codes', []);
        extraRouteParams.languages = filters.getFilterValue('language_codes', []);
        if (!CONFIG.build.STRAT_ENABLE_LEADERBOARD) {
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            extraRouteParams.agents = filters
                .getFilterValue('externalID', [])
                // @ts-expect-error - TS7006 - Parameter 'agent' implicitly has an 'any' type.
                .map((agent) => agent.externalID);
        }
    }
    const purpose = filters.getFilterValue(FilterValues.purpose.truBrokerAttribute, null);
    const rentalCategory: string = filters.getFilterValue(
        FilterValues.agentRentalCategory.attribute,
        FilterValues.agentRentalCategory.default,
    );
    const saleCategory: string = filters.getFilterValue(
        FilterValues.agentSaleCategory.attribute,
        FilterValues.agentSaleCategory.default,
    );

    if (Purpose.isForSale(purpose) && !FilterValues.agentSaleCategory.isDefault(saleCategory)) {
        extraRouteParams.proboardCategory = saleCategory;
    }

    if (Purpose.isForRent(purpose) && !FilterValues.agentRentalCategory.isDefault(rentalCategory)) {
        extraRouteParams.proboardCategory = rentalCategory;
    }

    return stratSearchFiltersToRouteParams(filters, {
        ...extraRouteParams,
        ...(purpose ? { proboardPurpose: purpose } : {}),
        proboardLocations,
    });
};

const createSearchQueryParams = (params: SearchParams, unknownParams?: any) => {
    const purpose = !params.purpose ? undefined : Purpose.value(params.purpose);
    const locations: Array<any> = [];
    const proboardLocations: Array<any> = [];
    if (params.locations) {
        locations.push(...params.locations.map((location) => location.slug));
    }
    if (params.proboardLocations) {
        proboardLocations.push(...params.proboardLocations.map((location) => location.slug));
    }

    const price = params.price;
    const area = params.area;
    const baths = params.baths;
    const rentFrequency = Purpose.isForRent(params.purpose) ? params.rentFrequency : null;
    const sortBy = params.sortBy;
    const page = params.page;

    let beds = params.beds;
    if (!Array.isArray(beds)) {
        // @ts-expect-error - TS2322 - Type 'null | undefined' is not assignable to type 'string | number'.
        beds = [beds];
    }
    // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
    beds = beds.filter((bed) => !isNaN(parseInt(bed, 10))).sort();

    const search: Record<string, any> = {};

    // encode page number into the url
    if (page && page > 1) {
        search[KnownURLParams.PAGE] = page;
    }

    if (locations.length > 0) {
        search[KnownURLParams.LOCATIONS] = locations.join(',');
    }
    if (proboardLocations.length > 0) {
        search[KnownURLParams.PROBOARD_LOCATIONS] = proboardLocations.join(',');
    }

    search[KnownURLParams.CATEGORY] = params.category;
    search[KnownURLParams.PROBOARD_CATEGORY] = params.proboardCategory;
    search[KnownURLParams.PROBOARD_PURPOSE] = params.proboardPurpose;
    search[KnownURLParams.PURPOSE] = purpose;

    if (beds && beds.length > 0) {
        search[KnownURLParams.BEDS] = beds.join(',');
    }

    if (sortBy && sortBy !== SortByValues.CITY_LEVEL_SCORE) {
        search[KnownURLParams.SORT] = sortBy;
    }

    // encode price into the url
    if (price) {
        if (!isNil(price.min)) {
            search[KnownURLParams.PRICE_MIN] = price.min;
        }

        if (!isNil(price.max)) {
            search[KnownURLParams.PRICE_MAX] = price.max;
        }
    }

    // encode area into the url
    if (area) {
        if (!isNil(area.min)) {
            search[KnownURLParams.AREA_MIN] = area.min;
        }

        if (!isNil(area.max)) {
            search[KnownURLParams.AREA_MAX] = area.max;
        }
    }

    // encode bathrooms into the url
    if (baths && baths.length > 0) {
        search[KnownURLParams.BATHS_IN] = Array.isArray(baths) ? baths.join(',') : baths;
    }

    const keywords = params.keywords;
    if (keywords && keywords.length > 0) {
        search[KnownURLParams.KEYWORDS] = Array.isArray(keywords) ? keywords.join(',') : keywords;
    }

    if (params.furnishingStatus && params.furnishingStatus !== PropertyfurnishingStatus.ANY) {
        search[KnownURLParams.FURNISHING_STATUS] = params.furnishingStatus;
    }

    if (rentFrequency) {
        search[KnownURLParams.RENT_FREQUENCY] = rentFrequency;
    }

    if (
        (Purpose.isForSale(params.purpose) && !settings.hideSaleCompletionStatus) ||
        (Purpose.isForRent(params.purpose) && !settings.hideRentCompletionStatus)
    ) {
        const completionStatus = FilterValues.completionStatus.queryParamValues.find(
            (status: { filterValue: string }) => status.filterValue === params.completionStatus,
        );
        if (
            completionStatus &&
            completionStatus.filterValue !== FilterValues.completionStatus.default
        ) {
            search[KnownURLParams.COMPLETION_STATUS] = completionStatus.queryParamValue;
        }
    }

    if (params.residenceType) {
        search[KnownURLParams.RESIDENCE_TYPE] = params.residenceType;
    }

    // add the unknown params to the search  like the campaign params: utm, gclid or so on.
    if (unknownParams) {
        Object.assign(search, unknownParams);
    }

    // re-encode campaign parameters into the url
    search.utm_source = params.utm_source;
    search.utm_medium = params.utm_medium;
    search.utm_content = params.utm_content;
    search.utm_campaign = params.utm_campaign;
    search.utm_term = params.utm_term;
    search.gclid = params.gclid;
    search.wbraid = params.wbraid;
    return search;
};

const createAgencyDetailsURL = (params: SearchParams, unknownParams?: any): EnhancedLocation => {
    const language = params && params.language;
    const companies = CONFIG.build.LOCALIZED_ROUTES
        ? translate(translationCatalog(), 'companies', 'en', language || 'en')
        : 'companies';

    const activeLanguage = params.currentLanguage
        ? params.currentLanguage === params.language
        : true;

    let agencySlug = activeLanguage ? params.agencySlug : params.agencySlug_l1 || params.agencySlug;
    // params.agencySlug can be either a string or an array of strings containing slugs for two languages
    if (Array.isArray(agencySlug) && agencySlug.length > 1) {
        agencySlug = activeLanguage ? agencySlug[0] : agencySlug[1] || agencySlug[0];
    }

    const pathname = `/${companies}/${agencySlug}/`;

    const search = createSearchQueryParams(params, unknownParams);

    return {
        pathname,
        search: {
            ...search,
            ...(CONFIG.build.STRAT_ENABLE_LEADERBOARD && {
                [KnownURLParams.ACTIVE_TAB]: params.activeTab,
            }),
        },
    };
};

export { searchFiltersToRouteParams, createAgencyDetailsURL, createSearchQueryParams };
