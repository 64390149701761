//@ts-nocheck
import { SearchStateConstants } from '@sector-labs/fe-search-redux/state';

import FetcherFactory from 'strat/fetcher';
import { FrontEndAPI } from 'strat/api';

import fetchFromAlgolia from './fetchFromAlgolia';

/**
 * Factory for fetching similar properties.
 */
const factory = new FetcherFactory(
    'similarProperties',
    (params: any, state: any) =>
        new FrontEndAPI(state.i18n.language)
            .similarProperties(params.externalID)
            .then(({ data, status }) => {
                const slicedData = data.list_item
                    .slice(0, params.count)
                    .map((entry) => entry.property_id);
                return fetchFromAlgolia(
                    { slicedData, status },
                    params.appId,
                    params.apiKey,
                    params.indexName,
                    params.count,
                    'externalID',
                );
            }),
    {
        getParams: (params, getState) => {
            const state = getState();

            const property = state.property.data;
            return {
                externalID: property.externalID,
                appId: state[SearchStateConstants.REDUCER_NAME].appId,
                apiKey: state[SearchStateConstants.REDUCER_NAME].apiKey,
                indexName: state[SearchStateConstants.REDUCER_NAME].indexName,
                count: params,
            };
        },
    },
);

/**
 * Fetches similar properties.
 */
const fetchSimilarProperties = factory.creator();

/**
 * Reducer for similar properties.
 */
const similarPropertiesReducer = factory.reducer();

export { fetchSimilarProperties };

export default similarPropertiesReducer;
