import * as React from 'react';
import { Slider as BaseSlider } from 'strat/generic';

import './styles/slider.css';

type Props = {
    readonly min?: number;
    readonly max?: number;
    readonly values?: Array<number | null | undefined>;
    readonly disabled?: boolean;
    readonly onValuesUpdated: (arg1: any) => void;
    readonly onSliderDragEnd: () => void;
    readonly onClick: () => void;
};

const Slider = (props: Props) => {
    return <BaseSlider {...props} />;
};

export default Slider;
