//@ts-nocheck
import Loadable, { LoadingSpinner } from 'strat/loadable';

/**
 * Loads the ContactFormSlideIn component async.
 */
const ContactFormSlideIn = Loadable({
    loader: () => import(/* webpackChunkName: 'contactForm' */ './contactFormSlideIn'),
    loading: LoadingSpinner,
});

export default ContactFormSlideIn;
