import type { Dispatch } from 'redux';

import BannerTypes from 'strat/property/interactiveBanners/bannerTypes';

const ActionTypes = Object.freeze({
    SET_LAST_DISPLAYED: 'NUDGES/SET_LAST_DISPLAYED',
});

type SetLastDisplayedAtAction = {
    type: 'NUDGES/SET_LAST_DISPLAYED';
    lastDisplayedAt: number;
    lastDisplayedBannerType: Values<typeof BannerTypes>;
    lastDisplayedPropertyExternalID: string;
};

export type NudgeManagerState = {
    readonly lastDisplayedAt: number;
    readonly lastDisplayedBannerType: Values<typeof BannerTypes>;
    readonly lastDisplayedPropertyExternalID: string | null | undefined;
};

const defaultState: NudgeManagerState = {
    lastDisplayedAt: 0,
    // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string'.
    lastDisplayedBannerType: null,
    lastDisplayedPropertyExternalID: null,
};

const nudgeReducer = (
    state: NudgeManagerState | null | undefined = defaultState,
    action: SetLastDisplayedAtAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_LAST_DISPLAYED:
            return {
                ...state,
                lastDisplayedAt: action.lastDisplayedAt,
                lastDisplayedBannerType: action.lastDisplayedBannerType,
                lastDisplayedPropertyExternalID: action.lastDisplayedPropertyExternalID,
            };

        default:
            return state;
    }
};

const setNudgeLastDisplayed =
    (bannerType: Values<typeof BannerTypes>, propertyExternalId: string) =>
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    (dispatch: Dispatch) =>
        dispatch({
            type: ActionTypes.SET_LAST_DISPLAYED,
            lastDisplayedAt: Date.now(),
            lastDisplayedBannerType: bannerType,
            lastDisplayedPropertyExternalID: propertyExternalId,
        });

export { setNudgeLastDisplayed };

export default nudgeReducer;
