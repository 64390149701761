import * as React from 'react';
import { Trans } from '@lingui/macro';
import { StylelessButton } from 'strat/generic';
import { Text } from 'strat/components';

import styles from './styles/loginButton.cssm';

type Props = {
    onClick: () => void;
};

const LoginButton = ({ onClick }: Props) => (
    <StylelessButton onClick={onClick} className={styles.button} aria-label="Login">
        <Text.Large bold>
            <Trans>Login</Trans>
        </Text.Large>
    </StylelessButton>
);

export default LoginButton;
