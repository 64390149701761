import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useSearchHits,
    selectSearchLoadingState,
    useTotalSearchHitCount,
} from '@sector-labs/fe-search-redux/state';
import { GTMDataLayerVariables, Triggers } from 'strat/gtm';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';
import brandingSettings from '@app/branding/settings';

import { usePageViewTrigger } from 'horizontal/gtm';
import { useFeaturedAdsCount } from 'horizontal/search';
import { LiteAd } from 'horizontal/types';

import useGlobalGTMVars from '../useGlobalGTMVars';
import useSearchGTMVars from '../useSearchGTMVars';
import useTrackSearchImpressions from '../useTrackSearchImpressions';
import useTrackRecentlyViewedAds from '../useTrackRecentlyViewedAds';

import PageType from './pageType';

type Props = {
    ovation: OvationCallbackProps;
};

const GTMSearchDataLayer = (props: Props) => {
    const globalGTMVars = useGlobalGTMVars();
    const searchGTMVars = useSearchGTMVars();
    const hits = useSearchHits<LiteAd>();
    const { featuredCount, totalFeaturedCount } = useFeaturedAdsCount();
    const { loading } = useSelector(selectSearchLoadingState);
    const [hitCount] = useTotalSearchHitCount();
    const numberOfSets = React.useRef<number>(0);

    const splitAdIds = React.useMemo(() => {
        const MAX_IDS_PER_SET = 8;
        const newNumberOfSets = Math.ceil(hits.length / MAX_IDS_PER_SET);
        const splitIds: Record<string, string[]> = {};
        const adIds = hits.map((hit) => hit.externalID);

        // Clearing previous ad IDs sets when transitioning from a category with many ads to one with fewer.
        if (newNumberOfSets < numberOfSets.current) {
            for (let i = 0; i < numberOfSets.current; i++) {
                const setKey = i === 0 ? 'ad_ids' : `ad_ids_set_${i + 1}`;
                splitIds[setKey] = [];
            }
        }

        for (let i = 0; i < newNumberOfSets; i++) {
            const startIdx = i * MAX_IDS_PER_SET;
            const endIdx = startIdx + MAX_IDS_PER_SET;
            const idsSubset = adIds.slice(startIdx, endIdx);

            if (idsSubset.length > 0) {
                const setKey = i === 0 ? 'ad_ids' : `ad_ids_set_${i + 1}`;
                splitIds[setKey] = idsSubset;
            }
        }
        // Track total number of sets to be cleared in the next search
        numberOfSets.current = newNumberOfSets;

        return splitIds;
    }, [hits]);

    // as requested by the marketing team, send the hit count as if it were a filter
    const hitCountVars = {
        filter_name: 'Search Hits Count',
        filter_value: hitCount,
    } as const;

    useTrackSearchImpressions(props.ovation);
    useTrackRecentlyViewedAds(props.ovation);

    const variables = {
        ...globalGTMVars,
        ...searchGTMVars,
        ...hitCountVars,
        ...splitAdIds,
        popular_categories: brandingSettings.topCategories,
        page_type: PageType.SEARCH,
    } as const;

    usePageViewTrigger(
        Triggers.PROPERTY_SEARCH,
        { ...variables, total_featured: `${featuredCount}, ${totalFeaturedCount}` },
        loading,
    );

    return <GTMDataLayerVariables variables={variables} />;
};

export default withOvation(GTMSearchDataLayer);
