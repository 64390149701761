import type { NumberFormatterFactory } from 'strat/i18n/language/connectLanguage';

import Area from './area';
import { Unit, UnitType } from './units';

/**
 * Converts the specified value, noted in the base
 * units into a valid value in the local current units.
 */
export const toLocalUnits = (
    value: number,
    // @ts-expect-error - TS2749 - 'UnitType' refers to a value, but is being used as a type here. Did you mean 'typeof UnitType'?
    unitType: UnitType,
    baseUnit: Values<typeof UnitType>,
    convertedUnit: Values<typeof UnitType> | null | undefined = null,
): number => {
    return new Unit(value, unitType, baseUnit).convert(convertedUnit);
};

/**
 * Rounds the specified value using the current locales.
 */
export const roundLocalUnitsValue = (
    value: number,
    // @ts-expect-error - TS2749 - 'UnitType' refers to a value, but is being used as a type here. Did you mean 'typeof UnitType'?
    unitType: UnitType = null,
    // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
    unit: Values<typeof UnitType> = null,
): number => {
    const round =
        unitType && unit && unitType.roundingFunction
            ? unitType.roundingFunction(unit)
            : Math.round;

    return round(value);
};

/**
 * Converts the specified area, noted in the base
 * area units into a valid area in the local current area units.
 */
export const areaInLocalUnits = (
    value: number,
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
    unit: Values<typeof UnitType> = Area.SQM,
    // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
    currentUnit: Values<typeof UnitType> = null,
): number => {
    return toLocalUnits(value, Area, unit, currentUnit);
};

/**
 * Formats the specified value using the current locales.
 */
export const formatLocalUnitsValue = (
    value: number,
    // @ts-expect-error - TS2749 - 'UnitType' refers to a value, but is being used as a type here. Did you mean 'typeof UnitType'?
    unitType: UnitType | null | undefined = null,
    unit: Values<(typeof UnitType)['Area']> | null | undefined = null,
    intlNumberFormat: NumberFormatterFactory,
): string => {
    const formattingSettings =
        unitType && unit && unitType.formattingSettings ? unitType.formattingSettings(unit) : {};

    // @ts-expect-error - TS2345 - Argument of type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...' is not assignable to parameter of type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
    const roundedNumber = roundLocalUnitsValue(value, unitType, unit);
    return intlNumberFormat(formattingSettings).format(roundedNumber);
};
