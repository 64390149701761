import settings from '@app/branding/settings';
import * as React from 'react';
import useI18n from 'strat/i18n/language/useI18n';
import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';

import { StratAPI } from 'horizontal/api';
import { PackageRoles, ProductPackageOffer } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';

const useAvailableBusinessPackageOffers = (
    setLoading: (loading: boolean) => unknown,
    setError: (error: string | undefined) => unknown,
) => {
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();
    const topLocationID = settings.getTopLevelLocation(i18n)?.externalID;

    const [packageOffers, setPackageOffers] = React.useState<ProductPackageOffer[]>([]);

    const fetchPackageOffers = React.useCallback(
        (categoryID: string | null | undefined) => {
            setPackageOffers([]);

            if (!categoryID) {
                return;
            }

            setLoading(true);
            setError(undefined);

            makeCancelable(
                new StratAPI(i18n.locale)
                    .getProductPackageOffers(categoryID, topLocationID, {
                        roles: [PackageRoles.OFFLINE, PackageRoles.TELE_SALES],
                        priceOrderDesc: false,
                        show_all_bundles: true,
                    })
                    .then((response) => {
                        if (response.status >= 400) {
                            throw new ContextfulError(
                                `Unexpected response status while fetching business package offers for TeleSales`,
                                {
                                    status: response.status,
                                    data: response.data,
                                },
                            );
                        }

                        setPackageOffers(response.data);
                    })
                    .catch((e) => {
                        logError({
                            e,
                            msg: 'Unable to fetch business package offers',
                            context: {
                                categoryID,
                            },
                        });
                        setError("Couldn't fetch packages");
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            );
        },
        [setLoading, setError, makeCancelable, i18n.locale, topLocationID],
    );

    return { fetchPackageOffers, packageOffers };
};

export default useAvailableBusinessPackageOffers;
