import brandingSettings from '@app/branding/settings';
import { setContent, SearchActions } from '@sector-labs/fe-search-redux/state';
import { RoutingContextWithMiddlewares } from 'strat/app';

import { setPageParameters } from 'horizontal/search/state';
import { PaginationType } from 'horizontal/types';
import { fetchFavoriteAds } from 'horizontal/favorites';
import Page from 'horizontal/pages/page';

import { MatchParams } from './types';

const prepareSearchPageRendering = (
    context: RoutingContextWithMiddlewares,
    { page, pagetype }: MatchParams,
) => {
    const { dispatch } = context.redux.store;
    const isUsingInfiniteScrolling =
        brandingSettings.paginationType === PaginationType.INFINITE_SCROLLING;

    dispatch(fetchFavoriteAds());

    if (!page || !isUsingInfiniteScrolling) {
        dispatch(setContent(null));
    }

    dispatch({ type: SearchActions.FETCH });
    context.rendering.renderPage(Page.SEARCH);

    dispatch(setPageParameters({ pagetype }));
};

export default prepareSearchPageRendering;
