import React from 'react';
import { Dialog } from 'strat/modal';
import { Flex, Button, Text } from 'strat/components';
import { Trans } from '@lingui/macro';

import styles from './styles/deleteSavedCardConfirmationDialog.cssm';

type Props = {
    onConfirm: () => void;
    onCancel: () => void;
    visible: boolean;
};
const DeleteSavedCardConfirmationDialog = ({ onConfirm, onCancel, visible }: Props) => {
    return (
        <Dialog visible={visible} onVisibilityChanged={onCancel} dismissible>
            <Flex column className={styles.content}>
                <div className={styles.title}>
                    <Text.XLarge>
                        <Trans>Are you sure you want to delete this card?</Trans>
                    </Text.XLarge>
                </div>
                <Flex alignCenter justifySpaceAround>
                    <Button small secondary onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button small onClick={onConfirm}>
                        <Trans>Delete</Trans>
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default DeleteSavedCardConfirmationDialog;
