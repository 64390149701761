import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { useSelector } from 'react-redux';
import Layout from 'strat/layout';
import { selectLayout } from 'strat/layout/selectors';
import classNames from 'classnames';

import type { AdFormattedExtraField } from 'horizontal/ad';
import {
    ConditionField,
    MileageField,
    VideoField,
    VirtualTourField,
} from 'horizontal/fields/commonPostingFields';

import { SectionTitle } from '../section';

import styles from './styles/details.cssm';

type Props = {
    readonly formattedExtraFields: Array<AdFormattedExtraField>;
};

const CONDITION_FORMATTED_NEW = 'New';

const Details = ({ formattedExtraFields }: Props) => {
    const i18n = useI18n();
    const layout = useSelector(selectLayout);

    const entries = React.useMemo(() => {
        const conditionNew = formattedExtraFields.find(
            (field) =>
                field.attribute === ConditionField.attribute &&
                field.formattedValue === CONDITION_FORMATTED_NEW,
        );
        return formattedExtraFields
            .filter(
                (field) =>
                    (field.attribute !== MileageField.attribute || !conditionNew) &&
                    field.attribute !== VirtualTourField.attribute &&
                    field.attribute !== VideoField.attribute,
            )
            .map(({ attribute, name, formattedValue }) => (
                <div key={attribute} className={styles.detailElementWrapper}>
                    <div className={styles.detailElement}>
                        <span>{name}</span>
                        <span>{formattedValue}</span>
                    </div>
                </div>
            ));
    }, [formattedExtraFields]);

    return (
        <>
            <SectionTitle title={t(i18n)`Details`} />
            <div
                className={classNames(styles.detailsContent, {
                    [styles.multiColumns]: layout === Layout.DESKTOP,
                })}
            >
                {entries}
            </div>
        </>
    );
};

export default Details;
