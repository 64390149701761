import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import IconCommuteSurveySuccess from 'strat/assets/icons/houseIcon_noinline.svg';
import { CloseButton } from 'strat/modal';

import styles from './styles/commuteSurvey.cssm';

type Props = {
    onVisibilityChanged: (arg1: boolean) => void;
};

const CommuteSurveySuccessDialog = ({ onVisibilityChanged }: Props) => (
    <div className={styles.formContainer}>
        <CloseButton onClick={() => onVisibilityChanged(false)} className={styles.closeButton} />
        <img src={IconCommuteSurveySuccess} className={styles.successIcon} alt="house-icon" />
        <div className={classNames(styles.title, styles.successDialogTitle)}>
            <Trans>Thank you for sharing your thoughts!</Trans>
        </div>
        <div className={styles.successContent}>
            <Trans>
                Thank you for sharing your feedback! We will be using your inputs to create a new
                and improved version of Search 2.0!
            </Trans>
        </div>
    </div>
);

export default CommuteSurveySuccessDialog;
