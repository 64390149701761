import type { Dispatch } from 'redux';

import { setBusinessPackageCartOffers, setAdOrderDetails } from 'horizontal/businessPackages/state';
import { setRecentlyViewedAds } from 'horizontal/search/recentlyViewed/state';

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const drainStateFromLocalStorage = () => (dispatch: Dispatch) => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        if (activity.business_package_cart_offers) {
            dispatch(setBusinessPackageCartOffers(activity.business_package_cart_offers));
        }

        if (
            CONFIG.runtime.STRAT_ENABLE_AFTER_PAYMENT_PROCESSING_PAGE_REDIRECTION &&
            activity.adExternalID
        ) {
            dispatch(setAdOrderDetails(activity.adExternalID));
        }

        if (activity?.recentlyViewed?.ads) {
            dispatch(setRecentlyViewedAds(activity.recentlyViewed.ads));
        }
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

export default drainStateFromLocalStorage;
