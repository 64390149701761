import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';
import settings from '@app/branding/settings';

import { AddressRole } from 'horizontal/userOrders';
import type { GlobalState } from 'horizontal/state';
import { DeliveryType } from 'horizontal/types';

const selectAddressBook = createSelector(
    (state: GlobalState) => state?.addressBook?.data || EMPTY_ARRAY,
    (addressBook) =>
        addressBook.map((address) => ({
            id: address?.id,
            isDefault: address?.isDefault,
            locationPath: address?.locationPath,
            name: address?.name,
            addressLineOne: address?.addressLineOne,
            addressLineTwo: address?.addressLineTwo,
            notes: address?.notes,
            location: address?.location,
            roles: address?.roles,
        })),
);

const selectDeliveryAddresses = createSelector(
    [
        selectAddressBook,
        (state: GlobalState, deliveryType: DeliveryType = DeliveryType.SERVICE_DELIVERY) =>
            deliveryType,
    ],
    (addresses, deliveryType) =>
        settings.deliverySettings?.allowDeliveryFromAnyLocation ||
        (CONFIG.build.ENABLE_SELF_DELIVERY && deliveryType === DeliveryType.SELF_DELIVERY)
            ? addresses
            : addresses.filter((address) => address.roles?.includes(AddressRole.ALLOWS_DELIVERY)),
);

const selectDefaultDeliveryAddress = createSelector(selectDeliveryAddresses, (addresses) => {
    return addresses?.find((address: any) => address.isDefault);
});

const selectAddressBookLoading = createSelector(
    (state: GlobalState) => state?.addressBook || EMPTY_OBJECT,
    (addressBook) => !addressBook?.loaded || false,
);

export {
    selectAddressBook,
    selectAddressBookLoading,
    selectDeliveryAddresses,
    selectDefaultDeliveryAddress,
};
