import * as React from 'react';

import styles from './styles/paginationContainer.cssm';

type Props = {
    readonly children: React.ReactNode | Array<any> | any;
    readonly className?: string;
};

const PaginationContainer = ({ children, className }: Props) => (
    <div className={className || styles.pagination} role={'navigation'}>
        {children}
    </div>
);

export default PaginationContainer;
