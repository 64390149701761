import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';

import { ChatSubjectType } from 'horizontal/chat/constants';
import type { ChatSubjectXID, ChatUserXID } from 'horizontal/chat/types';
import Page from 'horizontal/pages/page';

const BaseChatRoute = '/widgets/chat';

export type ChatWidgetRouteParams = {
    readonly contactXID: ChatUserXID;
    readonly subjectXID: ChatSubjectXID;
    readonly subjectType: ChatSubjectType;
};

/*
 * This route is used in order to provide a chat interface through an iframe for external websites.
 * */

class ChatWidgetRoute extends Route {
    constructor() {
        super(RouteNames.CHAT_WIDGET, [
            ['^', BaseChatRoute, '$'],
            [
                '^',
                BaseChatRoute,
                '/',
                { name: 'subjectType', pattern: `(${Object.values(ChatSubjectType).join('|')})` },
                '/',
                { name: 'subjectXID', pattern: '([A-z0-9-]+)' },
                '/',
                { name: 'contactXID', pattern: '([A-z0-9-]+)' },
            ],
        ]);
    }

    createURL(params: ChatWidgetRouteParams): EnhancedLocation {
        if (params.subjectType && params.subjectXID && params.contactXID) {
            return {
                pathname: `${BaseChatRoute}/${params.subjectType}/${params.subjectXID}/${params.contactXID}`,
            };
        }

        return { pathname: BaseChatRoute, hash: {} };
    }

    onEnter(context: any): void {
        context.rendering.renderPage(Page.CHAT_WIDGET);
    }
}

export default new ChatWidgetRoute();
