import * as React from 'react';
import Logo from '@app/branding/logo';
import settings from '@app/branding/settings';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Button, Flex, FloatingDialog, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import IconChat from 'horizontal/assets/icons/iconChat.svg';
import IconFlag from 'horizontal/assets/icons/iconFlag.svg';
import IconPay from 'horizontal/assets/icons/iconPay.svg';
import { selectDialogStack } from 'horizontal/dialogs/selectors';

import styles from './styles/safetyTips.cssm';

type Props = {
    readonly isVisible: boolean;
    readonly setIsVisible: (arg1: boolean) => void;
};

const SafetyTips = ({ isVisible, setIsVisible }: Props) => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    const { stacked, stackGroup } = useSelector(selectDialogStack);

    return (
        <FloatingDialog
            dismissible
            visible={isVisible}
            onVisibilityChanged={setIsVisible}
            stacked={stacked}
            stackGroup={stackGroup}
        >
            <Flex justifyCenter className={styles.header}>
                <Logo />
            </Flex>
            <Flex alignCenter justifyCenter column className={styles.dialogContent}>
                <Text.XLarge bold>
                    <Trans>Tips for a safe deal</Trans>
                </Text.XLarge>
                <Flex alignCenter className={styles.tipsContainer}>
                    <IconFlag className={styles.image} />
                    <Text.Base className={styles.text}>
                        <Trans>
                            We strongly urge you not to meet amid the lockdown. Close the
                            negotiations on chat and complete the final transaction once lockdown
                            ends
                        </Trans>
                    </Text.Base>
                </Flex>
                <Flex alignCenter className={styles.tipsContainer}>
                    <IconPay className={styles.image} />
                    <Text.Base className={styles.text}>
                        <Trans>Never give money or product in advance</Trans>
                    </Text.Base>
                </Flex>
                <Flex alignCenter className={styles.tipsContainer}>
                    <IconChat className={styles.image} />
                    <Text.Base className={styles.text}>
                        <Trans>Do not reply to messages from International Numbers</Trans>
                    </Text.Base>
                </Flex>
                <Flex alignCenter className={styles.tipsContainer}>
                    <IconFlag className={styles.image} />
                    <Text.Base className={styles.text}>
                        <Trans>Report suspicious users to {brandName}</Trans>
                    </Text.Base>
                </Flex>
                <Button stretch secondary onClick={() => setIsVisible(false)}>
                    <Trans>Continue to chat</Trans>
                </Button>
            </Flex>
        </FloatingDialog>
    );
};

export default SafetyTips;
