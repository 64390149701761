import * as React from 'react';
import { InternalAPI } from 'strat/api';
import { Triggers, trigger } from 'strat/gtm';
import thumbnailURL from 'strat/image/thumbnailURL';

import { AdPhoto } from 'horizontal/types';
import { getImageSize, isImageResolutionTooHigh, isImageTooLarge } from 'horizontal/util';

import sortImages from './sortImage';

export type UploadingImage = {
    readonly loading: boolean;
    readonly src: string | null | undefined;
    readonly imageID: number | null | undefined;
    readonly isCoverImage: boolean;
    readonly isFileTooLarge: boolean;
    readonly isResolutionTooHigh: boolean;
    readonly uploadFailed: boolean;
    readonly file: File | null | undefined;
    readonly externalID: string | null | undefined;
    readonly width: number;
    readonly height: number;
};

type ImagePlaceholder = undefined;

const defaultUploadingImage: UploadingImage = {
    loading: false,
    src: undefined,
    // uploaded images have an imageID
    imageID: undefined,
    isCoverImage: false,
    isFileTooLarge: false,
    isResolutionTooHigh: false,
    uploadFailed: false,
    file: undefined,
    externalID: undefined,
    width: 0,
    height: 0,
};

interface Props {
    initialImagesCount: number;
    maxImagesCount: number;
    generateImageID: (file: File) => string;
    initialValue: AdPhoto[];
}

export type ImageType = UploadingImage | ImagePlaceholder;

const stateInitializer = (
    adPhotos: AdPhoto[] | undefined,
    initialImagesCount: number,
    maxImagesCount: number,
) => {
    const arraySize =
        !!adPhotos?.length && adPhotos.length >= initialImagesCount
            ? maxImagesCount
            : initialImagesCount;

    const mappedImages = (adPhotos ?? []).map((adPhoto) => ({
        ...defaultUploadingImage,
        src: thumbnailURL(adPhoto.id, 'webp'),
        externalID: adPhoto.externalID,
        imageID: adPhoto.id,
    }));

    const placeholders = arraySize - (mappedImages?.length ?? 0);

    return [...mappedImages, ...Array.from(Array(placeholders), () => undefined)];
};

export const useUploadImages = ({
    initialImagesCount = 13,
    maxImagesCount = 20,
    initialValue,
    generateImageID,
}: Props) => {
    const [images, setImages] = React.useState<Array<ImageType>>(
        stateInitializer(initialValue, initialImagesCount, maxImagesCount),
    );

    const anyImageTooLarge = React.useMemo(
        () => images.some((image) => image?.isFileTooLarge),
        [images],
    );

    const anyImageResolutionTooHigh = React.useMemo(
        () => images.some((image) => image?.isResolutionTooHigh),
        [images],
    );

    const anyUploadFailed = React.useMemo(
        () => images.some((image) => image?.uploadFailed),
        [images],
    );

    const anyImageLoading = React.useMemo(() => images.some((image) => image?.loading), [images]);

    const updateUploadingImageState = (
        externalID: string,
        imageUpdater: (previous: UploadingImage) => UploadingImage | undefined,
    ) => {
        setImages((prev) => {
            const imageIndexToUpdate = prev.findIndex((image) => image?.externalID === externalID);

            if (imageIndexToUpdate === -1) {
                return prev;
            }

            const image = imageUpdater(prev[imageIndexToUpdate] as UploadingImage);

            prev[imageIndexToUpdate] = image;

            return [...prev];
        });
    };

    React.useEffect(() => {
        setImages((prev) => prev.sort(sortImages));
    }, [images]);

    const uploadImage = React.useCallback(
        (uploadingImage: NonNullable<UploadingImage>): Promise<string> | null | undefined => {
            trigger(Triggers.CLICK_UPLOAD_PHOTO);

            return getImageSize(uploadingImage.src as string)
                .then(({ width, height }) => {
                    if (isImageResolutionTooHigh({ width, height })) {
                        updateUploadingImageState(
                            uploadingImage.externalID as string,
                            (image: UploadingImage) => ({
                                ...image,
                                isResolutionTooHigh: true,
                                loading: false,
                            }),
                        );

                        return null;
                    }

                    return new InternalAPI()
                        .uploadImage(
                            uploadingImage.externalID as string,
                            'temp',
                            uploadingImage.file as File,
                        )
                        .then(({ data: url }) => {
                            updateUploadingImageState(
                                uploadingImage.externalID as string,
                                (image) => ({
                                    ...(image as UploadingImage),
                                    src: url,
                                    loading: false,
                                    imageID: null,
                                    width,
                                    height,
                                }),
                            );
                        })
                        .catch((err) => {
                            updateUploadingImageState(
                                uploadingImage.externalID as string,
                                (image) => ({
                                    ...image,
                                    loading: false,
                                    uploadFailed: true,
                                }),
                            );

                            return err.toString();
                        });
                })
                .catch((err) => {
                    updateUploadingImageState(uploadingImage.externalID as string, (image) => ({
                        ...image,
                        loading: false,
                        uploadFailed: true,
                    }));

                    return err.toString();
                });
        },
        [],
    );

    const uploadImages = React.useCallback(
        (files: Array<File>) => {
            const nonPlaceholderImages = images.filter(Boolean);

            const filesToUpload = files
                .filter(Boolean)
                .slice(0, maxImagesCount - nonPlaceholderImages.length);

            if (!filesToUpload.length) {
                return;
            }

            const uploadingImages: Array<UploadingImage> = filesToUpload.map((file) => {
                const id = generateImageID(file);
                const isFileTooLarge = isImageTooLarge(file.size);

                return {
                    ...defaultUploadingImage,
                    externalID: id,
                    src: URL.createObjectURL(file),
                    file,
                    loading: isFileTooLarge ? false : true,
                    isFileTooLarge,
                };
            });

            setImages(() => {
                const maxImagePlaceholders =
                    nonPlaceholderImages.length + uploadingImages.length >= initialImagesCount
                        ? maxImagesCount
                        : initialImagesCount;
                const imagePlaceholders =
                    maxImagePlaceholders - nonPlaceholderImages.length - uploadingImages.length;

                return [
                    ...nonPlaceholderImages,
                    ...uploadingImages,
                    ...Array(Math.max(0, imagePlaceholders)),
                ];
            });

            uploadingImages.forEach((uploadingImage) => {
                const uploadingImageLoading = uploadingImage.loading;

                // image not set for loading, it should not be uploaded, probably too large
                if (!uploadingImageLoading) {
                    return;
                }

                uploadImage(uploadingImage);
            });
        },
        [images, maxImagesCount, initialImagesCount, generateImageID, uploadImage],
    );

    const remove = React.useCallback((uploadingImage: UploadingImage) => {
        if (!uploadingImage?.externalID) {
            return;
        }

        updateUploadingImageState(uploadingImage.externalID as string, () => undefined);
    }, []);

    const retry = React.useCallback(
        (uploadingImage: UploadingImage) => {
            if (
                !uploadingImage ||
                !uploadingImage.externalID ||
                !uploadingImage.file ||
                !uploadingImage.uploadFailed
            ) {
                return;
            }

            updateUploadingImageState(uploadingImage.externalID, (image) => ({
                ...image,
                uploadFailed: false,
                loading: true,
            }));

            const uploadingImageIndex = images.findIndex(
                (image) => image?.externalID === uploadingImage.externalID,
            );

            if (uploadingImageIndex === -1) {
                return;
            }

            uploadImage(images[uploadingImageIndex] as UploadingImage);
        },
        [uploadImage, images],
    );

    return {
        images,
        setImages,
        remove,
        retry,
        uploadImages,
        anyImageTooLarge,
        anyImageResolutionTooHigh,
        anyImageLoading,
        anyUploadFailed,
    };
};
