import { useDispatch } from 'react-redux';
import * as React from 'react';
import { setSettings } from 'strat/settings/state';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ViewSections, trigger, Triggers } from 'strat/gtm';
import type { AppDispatch } from 'strat/state';

import { useSearchHitsLayout } from 'horizontal/layout';

const useLayout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedLayout = useSearchHitsLayout();

    const setLayout = React.useCallback(
        (layout: Values<typeof SearchHitsLayout>) => () => {
            trigger(Triggers.CHANGE_LAYOUT, {
                filter_name: 'view-type',
                filter_value: layout,
                view_section: ViewSections.BODY,
            });
            dispatch(setSettings({ searchHitsLayout: layout }));
        },
        [dispatch],
    );

    return [selectedLayout, setLayout];
};

export default useLayout;
