import { createSelector } from 'reselect';

import {
    selectSearchView,
    selectPropertyHits,
    selectMapPropertyHits,
} from 'strat/search/selectors';
import { PropertyState } from 'strat/property/types';
import { selectLanguage } from 'strat/i18n/language';
import { selectUserProfile } from 'strat/user/selectors';
import {
    selectRouteReferrer,
    selectOriginalReferrer,
    selectLandingURL,
} from 'strat/routes/selectors';
import { ensureAbsoluteURL } from 'strat/routing';

import getViewSectionVariables from './getViewSectionVariables';
import getPropertyListVariables from './getPropertyListVariables';

const selectViewSectionVariables = createSelector(selectSearchView, (searchView) =>
    getViewSectionVariables(searchView),
);

const selectSearchImpressionData = createSelector(
    [selectPropertyHits, selectLanguage, selectUserProfile],
    (propertyHits, language, userProfile) => {
        return {
            listings: propertyHits
                .filter(
                    (property) =>
                        property.active === true || property.state === PropertyState.ACTIVE,
                )
                .map((property) =>
                    getPropertyListVariables(property, 'impression', language, userProfile),
                ),
        };
    },
);

const selectMapBasedSearchImpressionData = createSelector(
    [selectMapPropertyHits, selectLanguage, selectUserProfile],
    (mapHits, language, userProfile) => {
        return {
            listings: mapHits.map((property) =>
                getPropertyListVariables(property, 'impression', language, userProfile),
            ),
        };
    },
);

const selectFormattedReferrer = createSelector(
    [selectRouteReferrer, selectOriginalReferrer, selectLanguage],
    (currentReferrer, originalReferrer, language) =>
        // Fallback onto the original referrer and ensure that the referrer is an absolute URL for consistency.
        ensureAbsoluteURL(currentReferrer?.length ? currentReferrer : originalReferrer, language),
);

const selectFormattedOriginalReferrer = createSelector(
    [selectOriginalReferrer, selectLanguage],
    ensureAbsoluteURL,
);

const selectFormattedLandingURL = createSelector(
    [selectLandingURL, selectLanguage],
    ensureAbsoluteURL,
);

export {
    selectViewSectionVariables,
    selectSearchImpressionData,
    selectMapBasedSearchImpressionData,
    selectFormattedReferrer,
    selectFormattedOriginalReferrer,
    selectFormattedLandingURL,
};
