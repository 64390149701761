import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { PageWidthWrapper } from 'strat/generic';
import { ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';

import { SearchLink } from 'horizontal/search/routing';
import { selectCategories } from 'horizontal/categories';
import AdCard from 'horizontal/adCard/adCard';
import { AdProduct, LiteAd } from 'horizontal/types';

import styles from './styles/promotedAds.cssm';
import { selectVerticalsPromotedAds } from './selectors';
import ExploreMoreButton from './exploreMoreButton';

const DISPLAYED_ADS_COUNT = 4;

const VerticalsPromotedAds = () => {
    const i18n = useI18n();
    const categories = useSelector(selectCategories);
    const verticalsCategory = categories.find(
        (category) => category.id === settings.verticalProperties.categoryID,
    );
    const verticalsPromotedAds = useSelector(selectVerticalsPromotedAds);

    if (!verticalsCategory || verticalsPromotedAds.length < DISPLAYED_ADS_COUNT) {
        return null;
    }

    return (
        <PageWidthWrapper className={styles.promotedAdsContainer}>
            <Flex justifySpaceBetween className={styles.promotedAdsTitle}>
                <Text.XXLarge bold>{t(i18n)`Check our Premium Listings`}</Text.XXLarge>
                <SearchLink
                    params={{
                        product: AdProduct.ALL,
                        category: verticalsCategory,
                    }}
                    className={styles.exploreMoreButton}
                >
                    <ExploreMoreButton />
                </SearchLink>
            </Flex>
            <Flex className={styles.adsContainer}>
                {verticalsPromotedAds.slice(0, DISPLAYED_ADS_COUNT).map((ad: LiteAd) => (
                    <div key={ad.externalID} className={styles.adCardWrapper} aria-label="Listing">
                        <AdCard ad={ad} viewSection={ViewSections.BODY} />
                    </div>
                ))}
            </Flex>
        </PageWidthWrapper>
    );
};

export default VerticalsPromotedAds;
