import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { Location } from 'horizontal/types';
import type { AppDispatch } from 'horizontal/state';

import { fetchLocations } from './state';
import { selectLocations } from './selectors';

type Props = {
    [key: string]: Location;
};

const useFullLocations = (externalIDs: Array<string>): Props => {
    const dispatch = useDispatch<AppDispatch>();
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '{}'.
    const allLocationsDict = useSelector((state) => selectLocations(state));

    React.useEffect(() => {
        externalIDs.forEach((externalID) => {
            dispatch(fetchLocations({ externalID }));
        });
    }, [externalIDs, dispatch]);

    const desiredLocationsDict = React.useMemo(() => {
        return (
            allLocationsDict &&
            externalIDs.reduce<Record<string, any>>((acc, externalID) => {
                if (allLocationsDict[externalID]) {
                    acc[externalID] = allLocationsDict[externalID];
                    return acc;
                }
                return acc;
            }, {})
        );
    }, [allLocationsDict, externalIDs]);

    return desiredLocationsDict;
};

export default useFullLocations;
