import { t } from '@lingui/macro';
import React from 'react';
import isNil from 'lodash/isNil';

import { useI18n } from 'strat/i18n/language';
import { MilestonesPaymentPlanInstallment } from 'strat/project/types';
import IconInfo from 'strat/assets/icons/iconInfo_noinline.svg';
import { ListingMoreInfoTooltip } from 'strat/listing';
import { Flex } from 'strat/components';
import { isNonEmptyArray } from 'strat/types/conditions';

import { PaymentStep } from './types';
import PaymentPercentagesTimeline from './paymentPercentagesTimeline';
import styles from './styles/paymentInstallmentsTooltip.cssm';

export const mapInstallmentsToPaymentSteps = (
    installments: MilestonesPaymentPlanInstallment[] | undefined,
): PaymentStep[] =>
    installments
        ? installments
              .filter(
                  (installment) =>
                      !isNil(installment.description) && !isNil(installment.valuePercentage),
              )
              .map((installment) => ({
                  label: installment.description || '',
                  value: installment.valuePercentage || 0,
              }))
        : [];

type PaymentInstallmentsTooltipProps = {
    installments?: MilestonesPaymentPlanInstallment[];
};

const PaymentInstallmentsTooltip = ({ installments }: PaymentInstallmentsTooltipProps) => {
    const i18n = useI18n();
    const displaySteps = mapInstallmentsToPaymentSteps(installments);

    if (!isNonEmptyArray(displaySteps)) {
        return null;
    }

    return (
        <Flex className={styles.container}>
            <ListingMoreInfoTooltip
                renderTrigger={() => (
                    <svg className={styles.infoIcon}>
                        <use xlinkHref={`${IconInfo}#info`} />
                    </svg>
                )}
                renderInformation={() => (
                    <Flex column className={styles.detailsContainer}>
                        <PaymentPercentagesTimeline
                            title={t(i18n)`Payment details`}
                            steps={displaySteps}
                        />
                    </Flex>
                )}
            />
        </Flex>
    );
};

export default PaymentInstallmentsTooltip;
