import * as React from 'react';

import { useChatProps } from 'horizontal/chat/hooks';

import type { ActionButton, ChatRoom, ChatRoomDisabledInfo } from '../types';

import DisabledChat from './disabledChat';
import Header from './header';
import MessagesContainer from './messagesContainer';
import Subheader from './subheader';

type Props = {
    readonly chatRoom: ChatRoom;
    readonly actionButtons?: Array<ActionButton>;
    readonly disabledInfo: ChatRoomDisabledInfo;
};

export const ReadonlyConversationContent = ({ chatRoom, actionButtons, disabledInfo }: Props) => {
    const { agencyMode } = useChatProps();
    return (
        <>
            <Header conversation={chatRoom} />
            {!agencyMode ? <Subheader buttonWithText chatRoom={chatRoom} /> : null}
            <MessagesContainer conversation={chatRoom} actionButtons={actionButtons} />
            {disabledInfo.disabled ? (
                <DisabledChat conversation={chatRoom} disabledInfo={disabledInfo} />
            ) : null}
        </>
    );
};
