import * as React from 'react';
import LogoIcon from '@app/assets/icons/logo_noinline.svg';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import SafetyTipsIcon from 'horizontal/assets/icons/safetyTips_noinline.svg';
import Warning from 'horizontal/assets/icons/warning.svg';

import styles from './styles/warningBanner.cssm';

type Props = {
    readonly warningText: string;
    readonly expanded: boolean;
    readonly setExpanded: (arg1: boolean) => void;
};

const WarningBanner = ({ warningText, expanded, setExpanded }: Props) => {
    if (!expanded) {
        return <Warning className={styles.warningIcon} onClick={() => setExpanded(true)} />;
    }
    return (
        <Flex noShrink className={styles.container}>
            <Flex column justifyCenter>
                <img src={SafetyTipsIcon} alt={'Safety tips'} className={styles.safetyTipsIcon} />
            </Flex>
            <Flex column fillContainer justifyCenter className={styles.textContainer}>
                <Flex>
                    <Trans>
                        <Flex alignCenter fillContainer className={styles.titleContainer}>
                            <img src={LogoIcon} alt="Logo" className={styles.logoIcon} />
                            <Text.Small bold>wants you to pay attention!</Text.Small>
                        </Flex>
                    </Trans>
                    <IconClose onClick={() => setExpanded(false)} className={styles.closeIcon} />
                </Flex>
                <Text.Base className={styles.warningText}>{warningText}</Text.Base>
            </Flex>
            <div className={styles.arrowBorder} />
            <div className={styles.arrowContent} />
        </Flex>
    );
};

export default WarningBanner;
