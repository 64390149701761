import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Toast } from 'strat/modal';
import { Flex } from 'strat/components';
import { ChangePassword } from 'strat/auth/keycloak';

import { UserContactMethodControl } from '@app/userSettings/controls';
import { useChangePasswordTitle } from 'horizontal/userSettings/hooks';

import Card from './card';
import InfoBanner from './infoBanner';
import styles from './styles/sheet.cssm';

const PrivacySheet = () => {
    const i18n = useI18n();
    const changePasswordTitle = useChangePasswordTitle();

    const [toastText, setToastText] = React.useState<string | null>(null);

    return (
        <Flex stretchWidth column className={styles.content}>
            <Card title={t(i18n)`My ads settings`}>
                <UserContactMethodControl />
            </Card>
            <Card title={changePasswordTitle}>
                <ChangePassword hasErrorPopup onDone={setToastText} />
            </Card>
            {toastText && (
                <Toast onHide={() => setToastText(null)}>
                    <InfoBanner text={toastText} />
                </Toast>
            )}
        </Flex>
    );
};

export default PrivacySheet;
