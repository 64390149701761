import { createSelector } from 'reselect';
import { selectSearchPageParameters } from 'strat/search/selectors';
import { GlobalState } from 'strat/state';
import EMPTY_ARRAY from 'strat/empty/array';

import { LiteAd, SearchPageType } from 'horizontal/types';

const selectIsRemarketingPage = createSelector(
    selectSearchPageParameters,
    (pageParams) => pageParams.pagetype === SearchPageType.REMARKETING,
);

const selectRemarketedListings = createSelector(
    (state: GlobalState) => state.search.remarketedListings as LiteAd[],
    (remarketedListings) => remarketedListings || EMPTY_ARRAY,
);

const selectRemarketedListing = createSelector(
    selectRemarketedListings,
    (remarketedListings) => remarketedListings?.[0],
);

const selectShowRemarketedListing = createSelector(
    selectRemarketedListing,
    selectIsRemarketingPage,
    (remarketedListing: LiteAd, isRemarketingPage): boolean => {
        return remarketedListing && isRemarketingPage;
    },
);

export {
    selectIsRemarketingPage,
    selectShowRemarketedListing,
    selectRemarketedListing,
    selectRemarketedListings,
};
