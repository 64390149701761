import type { Range } from 'strat/types';

type Breakpoint = {
    readonly value: number;
    readonly step: number;
};

const priceBreakpoints: Array<Breakpoint> = [
    { value: 200, step: 50 },
    { value: 500, step: 100 },
    { value: 1000, step: 500 },
    { value: 2000, step: 500 },
    { value: 5000, step: 3000 },
    { value: 10000, step: 5000 },
    { value: 100000, step: 10000 },
    { value: 1000000, step: 100000 },
    { value: 5000000, step: 500000 },
    { value: 10000000, step: 1000000 },
    { value: 20000000, step: 5000000 },
    { value: 50000000, step: 10000000 },
    { value: 100000000, step: 50000000 },
    { value: 1000000000, step: 900000000 },
    { value: 2000000000, step: 1000000000 },
];

const otherBreakpoints: Array<Breakpoint> = [
    { value: 100, step: 50 },
    { value: 300, step: 100 },
    { value: 500, step: 200 },
    { value: 2000, step: 500 },
    { value: 3000, step: 1000 },
    { value: 5000, step: 2000 },
    { value: 10000, step: 5000 },
    { value: 20000, step: 10000 },
    { value: 50000, step: 30000 },
    { value: 100000, step: 50000 },
    { value: 500000, step: 100000 },
    { value: 1000000, step: 500000 },
    { value: 10000000, step: 9000000 },
    { value: 100000000, step: 90000000 },
    { value: 1000000000, step: 900000000 },
    { value: 2000000000, step: 1000000000 },
];

const computeRanges = (breakpoints: Array<Breakpoint>): Array<Range> => {
    const ranges: Array<any> = [];

    let i = breakpoints[0].step;
    ranges.push({ min: 0, max: i });

    breakpoints.forEach((breakpoint) => {
        while (i < breakpoint.value) {
            ranges.push({ min: i + 1, max: i + breakpoint.step });
            i += breakpoint.step;
        }
    });

    return ranges;
};

/**
 * Checks if two ranges overlap
 */
const overlap = (first: Range, second: Range): boolean =>
    // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2533 - Object is possibly 'null' or 'undefined'.
    (!first.min || first.min <= second.max) && (!first.max || first.max >= second.min);

const valueRanges = {
    price: computeRanges(priceBreakpoints),
    other: computeRanges(otherBreakpoints),
} as const;

const getActiveRanges = (
    valueRange: Range | null | undefined,
    buckets: Array<Range>,
): Array<string> => {
    if (!valueRange) {
        return [];
    }

    const activeBuckets: Array<any> = [];
    buckets.forEach((bucket) => {
        if (overlap(valueRange, bucket)) {
            activeBuckets.push(bucket);
        }
    });

    return activeBuckets.map((bucket) => `${bucket.min}-${bucket.max}`);
};

const getActiveRangesPrice = (value: Range) => getActiveRanges(value, valueRanges.price);
const getActiveRangesOther = (value: Range) => getActiveRanges(value, valueRanges.other);

const getOneStepRanges = (value: Range, min: number, max: number) => {
    const start = value.min ?? min;
    const end = value.max ?? max;
    const ranges: Array<any> = [];

    for (let i = start; i <= end; i += 1) {
        ranges.push(i);
    }
    return ranges;
};

export { getActiveRangesPrice, getActiveRangesOther, getOneStepRanges };
