import * as React from 'react';
import { BottomSheetState } from 'strat/dialogs/dialogContext/bottomSheets';
import { BottomSheet } from 'strat/modal/compact';

import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import type { ChatNegotiationActionsMap } from 'horizontal/chat/types';
import { DialogNames } from 'horizontal/dialogs';

import AskForMore from './askForMore';
import styles from './styles/askForMoreBottomSheet.cssm';

type Props = WithChatStateProps & {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly activeState: Values<typeof BottomSheetState>;
    readonly setActiveState: (arg1: Values<typeof BottomSheetState>) => void;
    readonly adPrice?: number;
    readonly lastOfferPrice?: number;
    readonly actions?: ChatNegotiationActionsMap;
};

const AskForMoreBottomSheet = ({
    visible,
    onVisibilityChanged,
    adPrice,
    lastOfferPrice,
}: Props) => {
    const thresholds = React.useMemo(
        () => ({
            [BottomSheetState.DOCKED]: 0,
            [BottomSheetState.MINIMIZED]: 300,
        }),
        [],
    );

    if (!adPrice || !lastOfferPrice) {
        return null;
    }

    return (
        <BottomSheet
            activeState={visible ? BottomSheetState.MINIMIZED : BottomSheetState.DOCKED}
            setActiveState={(newState) => {
                if (newState === BottomSheetState.DOCKED) {
                    onVisibilityChanged(false);
                }
            }}
            visible
            // @ts-expect-error - TS2322 - Type '{ children: Element; activeState: string; setActiveState: (newState: string) => void; visible: true; onVisibilityChanged: (arg1: boolean) => void; name: string; thresholds: { [x: string]: number; }; className: any; }' is not assignable to type 'IntrinsicAttributes & Props'.
            onVisibilityChanged={onVisibilityChanged}
            name={DialogNames.CHAT_ASK_FOR_MORE_DIALOG}
            thresholds={thresholds}
            className={styles.container}
        >
            <div className={styles.askForMoreWrapper}>
                <AskForMore
                    adPrice={adPrice}
                    lastOfferPrice={lastOfferPrice}
                    onVisibilityChanged={onVisibilityChanged}
                />
            </div>
        </BottomSheet>
    );
};

export default withChatState<Props>(AskForMoreBottomSheet);
