import { t } from '@lingui/macro';
import React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import useI18n from 'strat/i18n/language/useI18n';
import settings from '@app/branding/settings';
import { Text, Flex, Toggle } from 'strat/components';
import { StylelessButton } from 'strat/generic';

import { DeliveryFields } from 'horizontal/fields';
import { LocationRole, DeliveryType } from 'horizontal/types';
import IconInfoCircle from 'horizontal/assets/icons/iconInfoCircle.svg';
import { DeliveryInformationDialog } from 'horizontal/userOrders/addresses';
import { useMyProfileDetails } from 'horizontal/profile/state';
import { useIsAnyTypeOfAgent } from 'horizontal/agent/state';

import { AdDeliveryFields } from './commonPostingFields';
import ErrorMessage from './errorMessage';
import styles from './styles/deliverySection.cssm';

type Props = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly isEdit?: boolean;
};

const TOGGLE_ERROR_TYPES = {
    NO_PRICE: 'no_price',
    PRICE_EXCEEDED: 'price_exceeded',
    EMPTY_LOCATION: 'empty_location',
    NON_DELIVERABLE_LOCATION: 'non_deliverable_location',
    UNVERIFIED_USER: 'unverified_user',
} as const;

type Payload = {
    readonly type: string;
    readonly errorMessage: string;
};

const initialState = { errorMessage: '', type: '' } as const;

const toggleErrorsReducer = (
    _: {
        errorMessage: string;
        type: string;
    },
    { type, errorMessage }: Payload,
) => {
    const validTypes: string[] = Object.values(TOGGLE_ERROR_TYPES);
    if (validTypes.includes(type)) {
        return { errorMessage, type };
    } else {
        return initialState;
    }
};

const DeliverySection = ({ setFieldValue, touched, errors, values, onBlur, isEdit }: Props) => {
    const i18n = useI18n();
    const [dialogVisible, setDialogVisibility] = React.useState(false);
    // if is_deliverable is true, then this means this AD had delivery service enabled & is being edited
    const [toggled, setToggled] = React.useState(!!values.is_deliverable);
    const [error, dispatch] = React.useReducer(toggleErrorsReducer, initialState);
    const profile = useMyProfileDetails();
    const isAgentProfile = useIsAnyTypeOfAgent();

    const isToggleDisabled = () => {
        const priceCheck =
            values.price &&
            parseInt(values.price, 10) <= CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE;
        const locationCheck =
            values.location_id && values.location_delivery_role == LocationRole.ALLOWS_DELIVERY;
        const verifiedCheck =
            !CONFIG.runtime.STRAT_ENABLE_DELIVERY_SERVICE_FOR_VERIFIED_USERS_ONLY ||
            (profile?.isVerified && isAgentProfile);
        return !(priceCheck && locationCheck && verifiedCheck);
    };

    const isTogglable = React.useCallback(
        () =>
            (values.price && error.type === TOGGLE_ERROR_TYPES.NO_PRICE) ||
            (values.price &&
                parseInt(values.price, 10) <= CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE &&
                error.type === TOGGLE_ERROR_TYPES.PRICE_EXCEEDED) ||
            (values.location_id && error.type === TOGGLE_ERROR_TYPES.EMPTY_LOCATION) ||
            (values.location_delivery_role === LocationRole.ALLOWS_DELIVERY &&
                error.type === TOGGLE_ERROR_TYPES.NON_DELIVERABLE_LOCATION),
        [values.price, values.location_id, values.location_delivery_role, error.type],
    );

    React.useEffect(() => {
        if (!toggled) {
            setFieldValue(AdDeliveryFields.is_deliverable.attribute, false);
            setFieldValue(AdDeliveryFields.delivery_type.attribute, '');
        }
    }, [toggled, setFieldValue]);

    React.useEffect(() => {
        if (isTogglable()) {
            // @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'Payload'.
            dispatch({});
            return;
        }
        // in case of Edit we can have the scenario where we don't have location_id YET but
        // we have location_delivery_role, this should be considered valid location.
        // This happens because on couple of first renders location_id isn't filled YET
        const isLocationAvailable =
            (!values.location_id && isEdit && values.location_delivery_role) ||
            (values.location_id && values.location_delivery_role);
        if (
            (!values.price ||
                parseInt(values.price, 10) > CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE ||
                !isLocationAvailable) &&
            toggled
        ) {
            setToggled(!toggled);
            setFieldValue(AdDeliveryFields.is_deliverable.attribute, !toggled);
            if (AdDeliveryFields.is_deliverable.attribute) {
                setFieldValue(
                    AdDeliveryFields.delivery_type.attribute,
                    DeliveryType.SERVICE_DELIVERY,
                );
            } else {
                setFieldValue(AdDeliveryFields.delivery_type.attribute, '');
            }
        }
    }, [
        isTogglable,
        values.price,
        values.location_id,
        values.location_delivery_role,
        toggled,
        error.type,
        isEdit,
        setFieldValue,
    ]);

    const validateDeliveryRequirements = () => {
        if (!(values.price && parseInt(values.price, 10) > 0)) {
            dispatch({
                type: TOGGLE_ERROR_TYPES.NO_PRICE,
                errorMessage: t(
                    i18n,
                )`The product must have a price, free & exchange products are not eligible for delivery.`,
            });
        } else if (
            values.price &&
            parseInt(values.price, 10) > CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE
        ) {
            dispatch({
                type: TOGGLE_ERROR_TYPES.PRICE_EXCEEDED,
                errorMessage: t(
                    i18n,
                )`Delivery service is only provided for products with maximum price of ${CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_PRICE}.`,
            });
        } else if (!values.location_id) {
            dispatch({
                type: TOGGLE_ERROR_TYPES.EMPTY_LOCATION,
                errorMessage: t(i18n)`Must provide a location for your ad.`,
            });
        } else if (values.location_delivery_role !== LocationRole.ALLOWS_DELIVERY) {
            dispatch({
                type: TOGGLE_ERROR_TYPES.NON_DELIVERABLE_LOCATION,
                errorMessage: t(i18n)`Can't provide delivery service for the selected location.`,
            });
        } else if (
            !profile?.isVerified &&
            !isAgentProfile &&
            CONFIG.runtime.STRAT_ENABLE_DELIVERY_SERVICE_FOR_VERIFIED_USERS_ONLY
        ) {
            dispatch({
                type: TOGGLE_ERROR_TYPES.UNVERIFIED_USER,
                errorMessage: t(i18n)`Delivery services are available for verified users only.`,
            });
        } else {
            // @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'Payload'.
            dispatch({});
            setToggled(!toggled);
            setFieldValue(AdDeliveryFields.is_deliverable.attribute, !toggled);
            if (AdDeliveryFields.is_deliverable.attribute) {
                setFieldValue(
                    AdDeliveryFields.delivery_type.attribute,
                    DeliveryType.SERVICE_DELIVERY,
                );
            } else {
                setFieldValue(AdDeliveryFields.delivery_type.attribute, '');
            }
        }
    };

    const brandName = settings.getBrandName(i18n);

    return (
        <>
            <DeliveryInformationDialog
                visible={dialogVisible}
                onVisibilityChanged={(showDialog) => setDialogVisibility(showDialog)}
            />
            <Flex alignCenter className={styles.container}>
                <Text.Regular bold className={styles.title}>
                    {t(i18n)`${brandName} Delivery`}
                </Text.Regular>
                <Flex
                    fillContainer
                    justifySpaceBetween
                    alignCenter
                    className={styles.toggleContainer}
                >
                    <Toggle
                        checked={toggled}
                        onClick={validateDeliveryRequirements}
                        disabled={isToggleDisabled()}
                        name={AdDeliveryFields.is_deliverable.attribute}
                        // @ts-expect-error - TS2322 - Type '{ checked: boolean; onClick: () => void; name: string; errors: any; touched: any; values: FormikValues; }' is not assignable to type 'IntrinsicAttributes & Props'.
                        errors={errors}
                        touched={touched}
                        values={values}
                    />
                    <Flex alignCenter className={styles.aboutDelivery}>
                        <Text.Small>{t(i18n)`Sell faster using ${brandName} delivery`}</Text.Small>
                        <IconInfoCircle
                            onClick={() => setDialogVisibility(true)}
                            className={styles.icon}
                        />
                    </Flex>
                    {settings.deliverySettings?.displayDeliveryPricingButton && (
                        <a
                            className={styles.button}
                            href={t(i18n)`delivery_fees_table_url`}
                            target="_blank noopener noreferrer"
                        >
                            <StylelessButton type="button">
                                <Text.Regular bold className={styles.buttonText}>
                                    {t(i18n)`See Pricing`}
                                </Text.Regular>
                            </StylelessButton>
                        </a>
                    )}
                </Flex>
            </Flex>
            {error?.errorMessage ? <ErrorMessage>{error.errorMessage}</ErrorMessage> : null}
            {toggled ? (
                <DeliveryFields
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    values={values}
                    onBlur={onBlur}
                />
            ) : null}
        </>
    );
};

export default DeliverySection;
