import type { Category } from 'horizontal/types';

/**
 This function returns all the children ids for a given caregory node.
 It is not pure and does modifies the input array in place, specifically childrenIDs.
 */

export const getAllDescendantsIDs = (
    category: Category,
    childrenIDs: Array<number> = [],
    includeSelf = false,
) => {
    if (includeSelf) {
        childrenIDs.push(category.id);
    }

    for (let i = 0; i < category.children.length; i++) {
        getAllDescendantsIDs(category.children[i], childrenIDs, true);
    }
    return childrenIDs;
};
