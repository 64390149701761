import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';

import { GlobalState } from 'horizontal/state';
import { DeliveryBuyingOrder } from 'horizontal/types';

const selectBuyerList = createSelector(
    (state: GlobalState) => state.buyingOrder?.data || (EMPTY_ARRAY as DeliveryBuyingOrder[]),
    (data) => data,
);

const selectBuyerListLoaded = createSelector(
    (state: GlobalState) => state.buyingOrder || EMPTY_OBJECT,
    (buyingOrder) => !buyingOrder?.loaded || false,
);
export { selectBuyerList, selectBuyerListLoaded };
