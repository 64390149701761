import * as React from 'react';

import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatUserXID } from 'horizontal/chat/types';
import { ReportUserDialog } from 'horizontal/report';
import type { ReportUserDialogProps } from 'horizontal/report/reportUserDialog';

type ReportAndBlockUserDialogProps = WithChatStateProps & ReportUserDialogProps;

const ReportAndBlockUserDialog = (props: ReportAndBlockUserDialogProps) => {
    const sdk = useChatSDK();

    const userXID = (props.reportedUserExternalID ?? undefined) as ChatUserXID | undefined;
    const { isBlocked } = sdk.useIsUserBlockedByMe(userXID);
    const [isBlocking, setIsBlocking] = React.useState(false);
    const blockUserFunction = sdk.useUpdateUserBlocking(userXID);
    const blockUser = React.useCallback(
        (shouldBlock: boolean) => {
            setIsBlocking(true);
            blockUserFunction(shouldBlock).finally(() => setIsBlocking(false));
        },
        [blockUserFunction],
    );

    return (
        <ReportUserDialog
            {...props}
            isUserBlocked={isBlocked}
            isBlockingLoading={isBlocking}
            blockUser={blockUser}
        />
    );
};

export default withChatState<ReportAndBlockUserDialogProps>(
    ReportAndBlockUserDialog,
    ReportUserDialog,
);
