import * as React from 'react';

import { ChatPropsContext } from 'horizontal/chat/state';
import type { ChatPropsContextType } from 'horizontal/chat/types';

const useChatProps = (): ChatPropsContextType => {
    const props = React.useContext(ChatPropsContext);
    if (props == null) {
        throw new Error('Component tree must be wrapped with ChatPropsContext.Provider');
    }

    return props;
};

export default useChatProps;
