import * as React from 'react';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';

import type { AgencyUserDTO, UserDTO } from 'horizontal/dtos';
import DefaultProfilePicture from '@app/profile/defaultProfilePicture';
import { Agency } from 'horizontal/types';

import styles from './styles/profilePicture.cssm';

type Props = {
    user?: UserDTO | AgencyUserDTO | Agency | null;
    userName?: string;
    userImageID?: number;
    thumbnailSize?: Values<typeof ThumbnailSizes>;
    fixedSize?: boolean;
    className?: string;
};

const ProfilePicture = ({
    user,
    userName,
    userImageID,
    thumbnailSize,
    fixedSize,
    className,
}: Props) => {
    const [isError, setIsError] = React.useState<any>(null);
    const userDisplayName = userName || user?.name;
    let imageID;
    if (user && 'image' in user) {
        imageID = userImageID || user?.image?.id;
    } else if (user && 'logo' in user) {
        imageID = user?.logo?.id;
    }

    React.useEffect(() => {
        setIsError(false);
    }, [imageID]);

    if (!imageID || isError) {
        return (
            <DefaultProfilePicture
                userDisplayName={userDisplayName}
                className={className || styles.image}
                label="User photo"
                fixedSize={fixedSize}
            />
        );
    }

    return (
        <ThumbnailImage
            imageID={imageID}
            size={thumbnailSize || ThumbnailSizes.NORMAL}
            alt={userDisplayName}
            title={userDisplayName}
            label="User photo"
            className={className || styles.image}
            onError={() => setIsError(true)}
            lazyLoad
        />
    );
};

export default ProfilePicture;
