import * as React from 'react';

const Key = {
    ARROW_DOWN: 40,
    ARROW_UP: 38,
    ENTER: 13,
    BACKSPACE: 8,
    ESCAPE: 27,
} as const;

const useKeyNavigation = (
    onNext?: () => void,
    onPrevious?: () => void,
    onSubmit?: () => void,
    onDelete?: () => void,
    onClear?: () => void,
) => {
    return React.useCallback(
        (event: React.KeyboardEvent<any>) => {
            switch (event.keyCode) {
                case Key.BACKSPACE:
                    if (onDelete) {
                        onDelete();
                    }
                    break;

                case Key.ENTER:
                    if (onSubmit) {
                        onSubmit();
                    }
                    break;

                case Key.ARROW_UP:
                    if (onPrevious) {
                        onPrevious();
                    }
                    break;

                case Key.ARROW_DOWN:
                    if (onNext) {
                        onNext();
                    }
                    break;

                case Key.ESCAPE:
                    if (onClear) {
                        onClear();
                    }
                    break;

                default:
                    break;
            }
        },
        [onNext, onPrevious, onSubmit, onDelete, onClear],
    );
};

export default useKeyNavigation;
