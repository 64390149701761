import { ChatRoomMemberRole } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoom } from 'horizontal/chat/types';

const useIsVerified = (conversation: ChatRoom) => {
    const sdk = useChatSDK();
    const { contact, ad } = sdk.useRoomContactInfo(conversation);
    const { myRole } = conversation;
    const isBuyer = myRole === ChatRoomMemberRole.BUYER;
    const isVerified = contact?.isVerified || (isBuyer && ad?.isSellerVerified);

    return !!isVerified;
};

export default useIsVerified;
