import { useSelector } from 'react-redux';

import type { GlobalState } from 'horizontal/state';
import { selectAdCategoryExternalID } from 'horizontal/ad/state/selectors';
import { selectShouldShowPhoneNumberByCategory } from 'horizontal/categories/selectors';

const useIsPhoneNumberPublicForCategory = () => {
    const categoryExternalID = useSelector(selectAdCategoryExternalID);
    const isPhoneNumberPublicForCategory = useSelector((state: GlobalState) =>
        selectShouldShowPhoneNumberByCategory(state, categoryExternalID),
    );

    return isPhoneNumberPublicForCategory;
};

export default useIsPhoneNumberPublicForCategory;
