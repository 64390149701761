import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import AttachmentImage from 'horizontal/assets/icons/iconAttachment.svg';
import CameraIcon from 'horizontal/assets/icons/iconCamera.svg';
import IconLocation from 'horizontal/assets/icons/iconLocation.svg';
import MicrophoneIcon from 'horizontal/assets/icons/microphone.svg';
import PhoneInCircleIcon from 'horizontal/assets/icons/phoneInCircle.svg';
import SMSIcon from 'horizontal/assets/icons/sms.svg';
import {
    ChatMessageType,
    ChatPhoneViewType,
    ChatSystemMessageType,
} from 'horizontal/chat/constants';
import type { ChatMessage } from 'horizontal/chat/types';

import { NegotiationLastMessage } from '../negotiation';

import styles from './styles/lastMessage.cssm';

type Props = {
    readonly message: ChatMessage;
    readonly isConversationUnread: boolean;
    readonly className?: string;
};

const renderContent = (message: ChatMessage, i18n: I18n) => {
    const { content } = message;

    if (content[ChatMessageType.NEGOTIATION]) {
        return <NegotiationLastMessage message={message} />;
    } else if (content[ChatMessageType.IMAGES]) {
        if (content[ChatMessageType.IMAGES]?.length === 0) {
            return <Trans>Broken Image</Trans>;
        }

        return (
            <Flex className={styles.capitalize}>
                <CameraIcon className={styles.icon} />
                {t(i18n)`${plural(content[ChatMessageType.IMAGES]?.length ?? 0, {
                    one: `image`,
                    other: `images`,
                })}`}
            </Flex>
        );
    } else if (content[ChatMessageType.FILES]) {
        return (
            <Flex className={styles.capitalize}>
                <AttachmentImage className={styles.icon} />
                <Trans>Attachment</Trans>
            </Flex>
        );
    } else if (content[ChatMessageType.LOCATION]) {
        return (
            <Flex className={styles.capitalize}>
                <IconLocation className={styles.icon} />
                {t(i18n)`location`}
            </Flex>
        );
    } else if (content[ChatMessageType.PHONE_VIEW]) {
        if (content[ChatMessageType.PHONE_VIEW]?.type === ChatPhoneViewType.SMS) {
            return (
                <Flex>
                    <SMSIcon className={classNames(styles.icon, styles.smsIcon)} />
                    <Trans>SMS</Trans>
                </Flex>
            );
        }
        return (
            <Flex>
                <PhoneInCircleIcon className={classNames(styles.icon, styles.phoneIcon)} />
                <Trans>Number viewed</Trans>
            </Flex>
        );
    } else if (content[ChatMessageType.TEXT]) {
        return content[ChatMessageType.TEXT];
    } else if (content[ChatMessageType.AUDIO]) {
        return (
            <Flex>
                <MicrophoneIcon className={styles.icon} />
                <Trans>Voice message</Trans>
            </Flex>
        );
    } else if (content[ChatMessageType.SYSTEM]) {
        const { type } = content[ChatMessageType.SYSTEM] ?? {};

        switch (type) {
            case ChatSystemMessageType.SOLD:
                return t(i18n)`This ad has been sold!`;
            case ChatSystemMessageType.BANNED:
                return t(i18n)`Suspicious user detected!`;
            case ChatSystemMessageType.UNBANNED:
                return t(i18n)`We have been too strict`;
            default:
                return null;
        }
    }

    return null;
};

const LastMessage = (props: Props) => {
    const { message, isConversationUnread, className } = props;
    const i18n = useI18n();

    if (message.isModerationRejected) {
        return (
            <Text.Base bold={isConversationUnread} italic className={className}>
                {t(i18n)`Hidden due to inappropriate content`}
            </Text.Base>
        );
    }

    return (
        <Text.Base
            bold={isConversationUnread}
            className={className}
            aria-label="Conversation last message"
        >
            {renderContent(message, i18n)}
        </Text.Base>
    );
};

export default LastMessage;
