import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import autoBind from 'react-autobind';
import humanizeDuration from '@sector-labs/humanize-duration';
import type { I18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import Category from '@app/branding/category';
import settings from '@app/branding/settings';
import IconCreationDate from '@app/assets/icons/iconCreationDate.svg';
import shouldDisplayArea from '@app/branding/shouldDisplayArea';
import PropertyMetadata from '@app/schema/property/propertyMetadata';
import VerifiedText from '@app/branding/verifiedText';

import { NumberFormat } from 'strat/i18n/language/numberFormat';
import { withI18n } from 'strat/i18n/language/withI18n';
import { computeProjectHandoverQuarter, PaymentPlanBreakdown } from 'strat/project';
import ServiceChargesField from '@app/dld/serviceCharges/serviceChargesField';
import { LocalCurrency, LocalPrice } from 'strat/i18n/money';
import { Area, UnitType, LocalUnits, UnitSystemDisplay } from 'strat/i18n';
import Purpose, { PurposeTextDisplay } from 'strat/purpose';
import { FilterValues } from 'strat/search';
import { locationHierarchyToString, PriceContainer } from 'strat/misc';
import type { PropertyData } from 'strat/property/types';
import {
    PropertyResidenceType,
    PropertyVerificationStatus,
    PropertyCompletionStatus,
    PropertyOwnership,
} from 'strat/property/types';
import Tooltip from 'strat/generic/tooltip';
import { formatDate } from 'strat/i18n/language/dateTimeFormatter';
import { BathRoomsLabel } from 'strat/formatting';
import BedRoomsLabel from '@app/formatting/bedRoomsLabel';
import ListingMoreInfoTooltip from 'strat/listing/listingMoreInfoTooltip';
import TruCheckText from 'strat/property/truCheckText';
import PropertyDetailsBottomSheet from 'strat/property/compact/propertyDetailsBottomSheet';
import InfoIcon from 'strat/property/infoIcon';
import type { Props as InfoIconProps } from 'strat/property/infoIcon';

import { hasInstallments } from '../installments';

import PropertyHandoverBottomSheet from './propertyHandoverBottomSheet';
import { AverageRentText, AverageRentValue } from './averageRent';
import styles from './styles/propertyDetails.cssm';

const Key = ({
    children,
    className = styles.key,
}: {
    children: React.ReactNode;
    className?: string;
}) => <span className={className}>{children}</span>;

const Value = ({
    children,
    label,
    className = styles.value,
}: {
    children: React.ReactNode;
    label: string;
    className?: string;
}) => (
    <span className={className} aria-label={label}>
        {children}
    </span>
);

// @ts-expect-error - TS7031 - Binding element 'className' implicitly has an 'any' type.
const Icon = ({ className }) => <span className={`${styles.icon} ${className}`} />;

const renderBottomSheetInfoIcon = ({ onClick }: InfoIconProps) => <InfoIcon onClick={onClick} />;
const renderTooltipInfoIcon = () => <InfoIcon />;

/**
 * Properties for {@see PropertyDetails}.
 */
type Props = {
    /**
     * The property for which to show the details section.
     */
    property: PropertyData;
    /**
     * How many columns should the details section have.
     */
    columns?: number;
    /**
     * i18n instance
     */
    i18n: I18n;
    /**
     * Whether icons should be shown for each entry in the details section.
     */
    withIcons?: boolean;
    /**
     * Whether extra information should be displayed for each entry in the details section.
     */
    withDescription?: boolean;
    /**
     * Renders the property title shown in the details section.
     */
    renderPropertyTitle?: (title: string) => React.ReactElement;
    /**
     * Whether the missing information should be hidden or simply rendered with placeholder values.
     */
    hideMissingInformation?: boolean;
    /* eslint-disable no-use-before-define */
    details: ReadonlyArray<ExtractValues<typeof PropertyDetailsSections>>;
    /* eslint-enable no-use-before-define */

    /**
     * The active current area unit used.
     */
    currentAreaUnit?: Values<typeof UnitType>;
    /**
     * Show a header above the list of details.
     */
    header?: string;
    /**
     * The type of device that this is being rendered
     */
    isMobile?: boolean;
    /**
     * Custom styles for icons
     */
    iconClassName?: string;
};

/**
 * Renders the Overview/Details section of a property.
 */
class PropertyDetails extends React.Component<Props> {
    /**
     * Whether the bathroom section should be shown or not.
     * Don't show the bathroom section if the bathroom count
     * is not available or < 0.
     */
    static shouldShowBathrooms(props: Props): boolean {
        if (!props.hideMissingInformation) {
            return true;
        }

        return !!props.property.baths;
    }

    /**
     * Whether the bedroom section should be shown or not.
     * Don't show the bedroom section if the bedroom section
     * is not available.
     */
    static shouldShowBedrooms(props: Props): boolean {
        if (!props.hideMissingInformation) {
            return true;
        }

        return props.property.isStudio || !!props.property.rooms;
    }

    static shouldShowResidenceType(props: Props): boolean {
        if (!settings.enableResidenceTypeDetail) {
            return false;
        }

        const { property } = props;
        const rootCategorySlug = property.category[0].slug;

        // @ts-ignore
        if (rootCategorySlug !== Category.RESIDENTIAL) {
            return false;
        }

        return !!property.residenceType;
    }

    static shouldShowCompletionStatus({ property }: Props): boolean {
        return property.purpose === Purpose.FOR_SALE;
    }

    static shouldShowAverageRent({ property }: Props): boolean {
        return (
            Purpose.isForSale(property.purpose) &&
            property.completionStatus === PropertyCompletionStatus.COMPLETED
        );
    }

    static shouldShowServiceCharges({ property }: Props): boolean {
        return Purpose.isForSale(property.purpose);
    }

    /**
     * Displays the bedroom count in a human readable form.
     */
    static formatBedrooms(props: Props): React.ReactNode {
        const { i18n } = props;
        if (props.property.isStudio) {
            return t(i18n)`Studio`;
        }

        const { rooms } = props.property;
        if (rooms) {
            return (
                <>
                    {props.withDescription ? (
                        <BedRoomsLabel>{rooms}</BedRoomsLabel>
                    ) : (
                        <NumberFormat value={rooms} />
                    )}
                </>
            );
        }

        return '-';
    }

    /**
     * Displays the bathroom count in a human readable form.
     */
    static formatBathrooms(props: Props): React.ReactNode {
        const { baths } = props.property;

        if (baths) {
            return (
                <>
                    {props.withDescription ? (
                        <BathRoomsLabel>{baths}</BathRoomsLabel>
                    ) : (
                        <NumberFormat value={baths} />
                    )}
                </>
            );
        }

        return '-';
    }

    static formatArea(props: Props): React.ReactNode {
        const { area } = props.property;
        if (shouldDisplayArea(area)) {
            return (
                <LocalUnits
                    // @ts-expect-error - TS2322 - Type '{ value: number; unitType: Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any...' is not assignable to type 'IntrinsicAttributes & Omit<withI18nProps, "i18n"> & { children?: ReactNode; }'.
                    value={area}
                    unitType={Area}
                    baseUnit={Area.SQM}
                    currentUnit={props.currentAreaUnit}
                    unitSystemDisplay={UnitSystemDisplay.ABBREVIATION}
                />
            );
        }
        return '-';
    }

    static renderType(props: Props): React.ReactNode {
        const { i18n } = props;
        const className = props.iconClassName
            ? `${styles.iconType} ${props.iconClassName}`
            : styles.iconType;

        return (
            <li key={`type-${props.property.externalID}`}>
                {props.withIcons && <Icon className={className} />}
                <Key>
                    <Trans>Type</Trans>
                </Key>
                <Value label="Type">
                    {FilterValues.category.render(i18n, props.property.category.slice(-1)[0].slug)}
                </Value>
            </li>
        );
    }

    static renderPrice(props: Props): React.ReactNode {
        const className = props.iconClassName
            ? `${styles.iconPrice} ${props.iconClassName}`
            : styles.iconPrice;

        return (
            <li key={`price-${props.property.externalID}`}>
                {props.withIcons && <Icon className={className} />}
                <Key>
                    <Trans>Price</Trans>
                </Key>
                <Value label="Price">
                    {!props.property.price ||
                    props.property.price === 0 ||
                    props.property.hidePrice ? (
                        <Trans>Contact for price</Trans>
                    ) : (
                        <PriceContainer
                            currency={<LocalCurrency />}
                            price={<LocalPrice price={props.property.price} />}
                        />
                    )}
                </Value>
            </li>
        );
    }

    static renderInstallmentPrice(props: Props): React.ReactNode {
        const { i18n, isMobile } = props;

        if (!hasInstallments(props.property)) {
            return null;
        }

        if (isMobile) {
            return (
                <>
                    <li key={`installments-initial-${props.property.externalID}`}>
                        <div className={styles.subKey}>
                            <Trans>Initial Amount</Trans>
                        </div>
                        <div className={styles.subValue} aria-label="Advance">
                            <PriceContainer
                                currency={<LocalCurrency />}
                                price={
                                    <LocalPrice
                                        price={props.property.installments?.advanceAmount}
                                    />
                                }
                            />
                        </div>
                    </li>
                    <li key={`installments-monthly-${props.property.externalID}`}>
                        <div className={styles.subKey}>
                            <Trans>Monthly Installment</Trans>
                        </div>
                        <div className={styles.subValue} aria-label="Monthly installment">
                            <PriceContainer
                                currency={<LocalCurrency />}
                                price={
                                    <LocalPrice
                                        price={props.property.installments?.monthlyAmount}
                                    />
                                }
                            />
                        </div>
                    </li>
                    <li key={`installments-remaining-${props.property.externalID}`}>
                        <div className={styles.subKey}>
                            <Trans>Remaining Installments</Trans>
                        </div>
                        <div className={styles.subValue} aria-label="Remaining installments">
                            {t(i18n)`${props.property.installments?.remainingInstallments} Months`}
                        </div>
                    </li>
                </>
            );
        }
        return (
            <>
                <li key={`installments-initial-${props.property.externalID}`}>
                    <Key>
                        <Trans>Initial Amount</Trans>
                    </Key>
                    <Value label="advance">
                        <PriceContainer
                            currency={<LocalCurrency />}
                            price={
                                <LocalPrice price={props.property.installments?.advanceAmount} />
                            }
                        />
                    </Value>
                </li>
                <li key={`installments-monthly-${props.property.externalID}`}>
                    <Key>
                        <Trans>Monthly Installment</Trans>
                    </Key>
                    <Value label="Monthly installment">
                        <PriceContainer
                            currency={<LocalCurrency />}
                            price={
                                <LocalPrice price={props.property.installments?.monthlyAmount} />
                            }
                        />
                    </Value>
                </li>
                <li key={`installments-remaining-${props.property.externalID}`}>
                    <Key>
                        <Trans>Remaining Installments</Trans>
                    </Key>
                    <Value label="Remaining installments">
                        {t(i18n)`${props.property.installments?.remainingInstallments} Months`}
                    </Value>
                </li>
            </>
        );
    }

    static renderBedroomCount(props: Props): React.ReactNode {
        const className = props.iconClassName
            ? `${styles.iconBeds} ${props.iconClassName}`
            : styles.iconBeds;

        return (
            PropertyDetails.shouldShowBedrooms(props) && (
                <li key={`beds-${props.property.externalID}`}>
                    {props.withIcons && <Icon className={className} />}
                    <Key>
                        <Trans>Bedroom(s)</Trans>
                    </Key>
                    <Value label="Beds">{PropertyDetails.formatBedrooms(props)}</Value>
                </li>
            )
        );
    }

    static renderBathroomCount(props: Props): React.ReactNode {
        const className = props.iconClassName
            ? `${styles.iconBaths} ${props.iconClassName}`
            : styles.iconBaths;

        return (
            PropertyDetails.shouldShowBathrooms(props) && (
                <li key={`baths-${props.property.externalID}`}>
                    {props.withIcons && <Icon className={className} />}
                    <Key>
                        <Trans>Bath(s)</Trans>
                    </Key>
                    <Value label="Baths">{PropertyDetails.formatBathrooms(props)}</Value>
                </li>
            )
        );
    }

    static renderArea(props: Props): React.ReactNode {
        const className = props.iconClassName
            ? `${styles.iconArea} ${props.iconClassName}`
            : styles.iconArea;

        return (
            <li key={`area-${props.property.externalID}`}>
                {props.withIcons && <Icon className={className} />}
                <Key>
                    <Trans>Area</Trans>
                </Key>
                <Value label="Area">{PropertyDetails.formatArea(props)}</Value>
            </li>
        );
    }

    static renderPurpose(props: Props): React.ReactNode {
        const className = props.iconClassName
            ? `${styles.iconPurpose} ${props.iconClassName}`
            : styles.iconPurpose;

        return (
            <li key={`purpose-${props.property.externalID}`}>
                {props.withIcons && <Icon className={className} />}
                <Key>
                    <Trans>Purpose</Trans>
                </Key>
                <Value label="Purpose">
                    {Purpose.text(
                        props.i18n,
                        props.property.purpose,
                        PurposeTextDisplay.CONSISTENT_PREPOSITION,
                    )}
                </Value>
            </li>
        );
    }

    static renderLocation(props: Props): React.ReactNode {
        const className = props.iconClassName
            ? `${styles.iconLocation} ${props.iconClassName}`
            : styles.iconLocation;

        return (
            <li key={`location-${props.property.externalID}`}>
                {props.withIcons && <Icon className={className} />}
                <Key>
                    <Trans>Location</Trans>
                </Key>
                <Value label="Location">
                    {locationHierarchyToString(
                        props.property.location,
                        CONFIG.build.AD_LOCATION_MAX_LEVEL_PROPERTY_CARD,
                        props.i18n,
                    )}
                </Value>
            </li>
        );
    }

    static renderReferenceNumber(props: Props): React.ReactNode {
        const { i18n } = props;
        const brandName = settings.getBrandName(i18n);

        return (
            <li key={`reference_number-${props.property.externalID}`}>
                {props.withIcons && <Icon className={styles.iconReference} />}
                <Key>
                    <Trans>Reference no.</Trans>
                </Key>
                <Value label="Reference">
                    <Trans>
                        {brandName} - {props.property.referenceNumber}
                    </Trans>
                </Value>
            </li>
        );
    }

    static renderCompletionStatus(props: Props): React.ReactNode {
        const { i18n, property } = props;
        return (
            PropertyDetails.shouldShowCompletionStatus(props) && (
                <li
                    aria-label="Property completion status"
                    key={`completion-${property.externalID}`}
                >
                    <Key>
                        <Trans>Completion</Trans>
                    </Key>
                    <Value label="Completion status">
                        {FilterValues.completionStatus.render(i18n, property.completionStatus)}
                    </Value>
                </li>
            )
        );
    }

    static renderOriginalPrice(props: Props): React.ReactNode {
        const { property } = props;

        if (!property.offplanDetails || !property.offplanDetails.originalPrice) {
            return null;
        }

        return (
            <li aria-label="Original price" key={`original-price-${property.externalID}`}>
                <Key>
                    <Trans>Original price</Trans>
                </Key>
                <Value label="Price">
                    <LocalCurrency /> <LocalPrice price={property.offplanDetails.originalPrice} />
                </Value>
            </li>
        );
    }

    static renderPaidPrice(props: Props): React.ReactNode {
        const { property, isMobile } = props;

        if (!property.offplanDetails?.paidPrice) {
            return null;
        }
        const paidPrice = property.offplanDetails.paidPrice;

        const renderInformation = () => <PaymentPlanBreakdown property={property} />;
        const renderValue = () => (
            <Value label="Amount paid">
                <LocalCurrency /> <LocalPrice price={paidPrice} />
            </Value>
        );

        return (
            <li aria-label="Amount paid" key={`amount-paid-${property.externalID}`}>
                <Key>
                    <Trans>Amount paid</Trans>
                </Key>
                {!isMobile && (
                    <>
                        {renderValue()}
                        <ListingMoreInfoTooltip
                            renderTrigger={renderTooltipInfoIcon}
                            renderInformation={renderInformation}
                        />
                    </>
                )}
                {isMobile && (
                    <PropertyHandoverBottomSheet
                        property={property}
                        renderTrigger={renderValue}
                        renderInformation={renderInformation}
                    />
                )}
            </li>
        );
    }

    static renderHandoverDate(props: Props): React.ReactNode {
        const { i18n, property } = props;

        const handover = computeProjectHandoverQuarter({
            completionDate: property.completionDetails?.completionDate,
        });

        if (!handover || property.completionStatus === PropertyCompletionStatus.COMPLETED) {
            return null;
        }

        const { quarter, year } = handover;

        return (
            <li aria-label="Handover date" key={`handover-date-${property.externalID}`}>
                <Key>
                    <Trans>Handover date</Trans>
                </Key>
                <Value label="Handover date">{t(i18n)`Q${quarter} ${year}`}</Value>
            </li>
        );
    }

    static renderFurnishingStatus(props: Props): React.ReactNode {
        const { i18n, property } = props;
        return (
            props.property.furnishingStatus && (
                <li
                    aria-label="Property furnishing status"
                    key={`furnishing-${property.externalID}`}
                >
                    <Key>
                        <Trans>Furnishing</Trans>
                    </Key>
                    <Value label="Furnishing">
                        {FilterValues.furnishingStatus.render(i18n, property.furnishingStatus)}
                    </Value>
                </li>
            )
        );
    }

    static renderCreationDate(props: Props): React.ReactNode {
        const { i18n } = props;
        const { createdAt: creationDate } = props.property;
        const className = props.iconClassName
            ? `${styles.icon} ${styles.iconCreationDate} ${props.iconClassName}`
            : `${styles.icon} ${styles.iconCreationDate}`;

        if (!creationDate) {
            return null;
        }

        const humanizedCreationDate = humanizeDuration(Date.now() - creationDate * 1000, {
            largest: 1,
            units: ['y', 'mo', 'w', 'd', 'h', 'm'],
            round: true,
            language: i18n.locale,
        });

        if (!humanizedCreationDate) {
            return null;
        }

        return (
            <li key={`creation_date-${props.property.externalID}`}>
                {props.withIcons && <IconCreationDate className={className} />}
                <Key>
                    <Trans>Added</Trans>
                </Key>
                <Value label="Creation date">{t(i18n)`${humanizedCreationDate} ago`}</Value>
            </li>
        );
    }

    static renderAbsoluteCreationDate(props: Props): React.ReactNode {
        const { i18n } = props;
        const { createdAt: creationDate, reactivatedAt: reactivatedDate } = props.property;
        if (!creationDate) {
            return null;
        }

        const FORMATTER_OPTIONS = {
            year: 'numeric',
            day: 'numeric',
            month: 'long',
        } as const;
        const addedDate = reactivatedDate || creationDate;
        const formattedDate = formatDate(
            new Date(addedDate * 1000),
            i18n.locale,
            FORMATTER_OPTIONS,
        );

        return (
            <li key={`absolute_creation_date-${props.property.externalID}`}>
                {props.withIcons && (
                    <IconCreationDate className={`${styles.icon} ${styles.iconCreationDate}`} />
                )}
                <Key>
                    <Trans>Added on</Trans>
                </Key>
                <Value label="Reactivated date">{formattedDate}</Value>
            </li>
        );
    }

    static renderPropertyOwnership({ property, i18n }: Props): React.ReactNode {
        const { extraFields, externalID } = property;
        const { ownership } = extraFields || {};

        let ownershipLabel = null;
        if (ownership == PropertyOwnership.PRIMARY) {
            ownershipLabel = t(i18n)`Primary`;
        } else if (ownership == PropertyOwnership.RESALE) {
            ownershipLabel = t(i18n)`Resale`;
        }
        if (!ownershipLabel) {
            return null;
        }

        return (
            <li key={`property_ownership-${externalID}`}>
                <Key>
                    <Trans>Ownership</Trans>
                </Key>
                <Value label="Ownership">{ownershipLabel}</Value>
            </li>
        );
    }

    static renderLastUpdatedDate(props: Props): React.ReactNode {
        const { i18n } = props;
        const { updatedAt, reactivatedAt } = props.property;

        const updatedDate = (updatedAt || 0) > (reactivatedAt || 0) ? updatedAt : reactivatedAt;
        if (!updatedDate) {
            return null;
        }

        const FORMATTER_OPTIONS = {
            year: 'numeric',
            day: 'numeric',
            month: 'long',
        } as const;
        const formattedDate = formatDate(
            new Date(updatedDate * 1000),
            i18n.locale,
            FORMATTER_OPTIONS,
        );

        return (
            <li key={`updated_date-${props.property.externalID}`}>
                {props.withIcons && (
                    <IconCreationDate
                        className={classNames(styles.icon, styles.iconCreationDate)}
                    />
                )}
                <Key>
                    <Trans>Last Updated</Trans>
                </Key>
                <Value label="Updated date">{formattedDate}</Value>
            </li>
        );
    }

    static renderVerificationDate(props: Props): React.ReactNode {
        const { i18n, isMobile } = props;
        const { verification } = props.property;
        const isVerified = verification?.status === PropertyVerificationStatus.VERIFIED;
        const verificationDate = verification?.verifiedAt || verification?.updatedAt;

        if (!isVerified || !verificationDate) {
            return null;
        }

        const FORMATTER_OPTIONS = {
            year: 'numeric',
            day: 'numeric',
            month: 'long',
        } as const;
        const date = new Date(verificationDate * 1000);
        const formattedDate = formatDate(date, i18n.locale, FORMATTER_OPTIONS);

        return (
            <li
                aria-label="Property TruCheck verification date"
                key={`verification_date-${props.property.externalID}`}
            >
                <div className={classNames(styles.truCheckKey, styles.key)}>
                    <Trans>
                        <TruCheckText /> <span>on</span>
                    </Trans>
                </div>
                <Value label="Trucheck date">{formattedDate}</Value>
                {isMobile ? (
                    <PropertyDetailsBottomSheet
                        renderContent={renderBottomSheetInfoIcon}
                        name={`verification_date-${props.property.externalID}`}
                        title={
                            <Trans>
                                <TruCheckText /> <span>on</span>
                            </Trans>
                        }
                        description={<VerifiedText />}
                    />
                ) : (
                    <Tooltip
                        className={styles.tooltipContainer}
                        clickable={false}
                        timeToRender={400}
                        timeToHide={0}
                        renderTrigger={renderTooltipInfoIcon}
                        renderContent={({ className }) => (
                            <div className={classNames(className, styles.tooltipContent)}>
                                <VerifiedText />
                            </div>
                        )}
                        anchorClassName={styles.anchorClassName}
                    />
                )}
            </li>
        );
    }

    static renderResidenceType(props: Props): React.ReactNode {
        if (!PropertyDetails.shouldShowResidenceType(props)) {
            return null;
        }

        const { i18n, property } = props;

        let label = null;
        switch (property.residenceType) {
            case PropertyResidenceType.FAMILY:
                label = t(i18n)`Family`;
                break;

            case PropertyResidenceType.SINGLES:
                label = t(i18n)`Singles`;
                break;

            default:
                break;
        }

        return (
            <li key={`residence_type-${props.property.externalID}`}>
                <Key>
                    <Trans>Residence Type</Trans>
                </Key>
                <Value label="Residence type">{label || '-'}</Value>
            </li>
        );
    }

    static renderAverageRent(props: Props): React.ReactNode | null | undefined {
        if (!PropertyDetails.shouldShowAverageRent(props)) {
            return null;
        }

        const { property, isMobile } = props;

        return (
            <li key={`average_rent-${property.externalID}`}>
                <div className={classNames(styles.truCheckKey, styles.key)}>
                    <Trans>Average Rent</Trans>
                </div>
                <Value label="Average Rent">
                    <AverageRentValue property={property} />
                </Value>
                {isMobile ? (
                    <PropertyDetailsBottomSheet
                        renderContent={renderBottomSheetInfoIcon}
                        name={'average-rent'}
                        title={<Trans>Average Rent</Trans>}
                        description={<AverageRentText />}
                    />
                ) : (
                    <Tooltip
                        clickable={false}
                        timeToRender={400}
                        timeToHide={0}
                        renderTrigger={renderTooltipInfoIcon}
                        renderContent={({ className }) => (
                            <AverageRentText
                                // @ts-expect-error - TS2322 - Type '{ className: string; noValueClassName: any; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, GTMPropertyTrendsTrackingProps> & RefAttributes<any> & { children?: ReactNode; }'.
                                className={classNames(className, styles.tooltipContent)}
                                noValueClassName={styles.tooltipContentNoValue}
                            />
                        )}
                        anchorClassName={styles.anchorClassName}
                    />
                )}
            </li>
        );
    }

    static renderServiceCharges(props: Props) {
        if (!PropertyDetails.shouldShowServiceCharges(props)) {
            return null;
        }

        return <ServiceChargesField key={`serviceCharges-${props.property.externalID}`} />;
    }

    constructor(props: Props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { props } = this;

        const listClassName =
            props.columns === 1 ? styles.oneColumnDetailsList : styles.detailsList;

        return (
            <>
                {/*@ts-ignore*/}
                <PropertyMetadata property={props.property} />
                {props.renderPropertyTitle && props.renderPropertyTitle(props.property.title)}
                {props.header && <h2 className={styles.header}>{props.header}</h2>}
                <ul
                    className={listClassName}
                    style={{ columns: props.columns }}
                    aria-label="Property details"
                >
                    {props.details.map((section) => section(props))}
                </ul>
            </>
        );
    }
}

const PropertyDetailsSections = Object.freeze({
    TYPE: PropertyDetails.renderType,
    PRICE: PropertyDetails.renderPrice,
    INSTALLMENTS: PropertyDetails.renderInstallmentPrice,
    BEDROOMS: PropertyDetails.renderBedroomCount,
    BATHROOMS: PropertyDetails.renderBathroomCount,
    AREA: PropertyDetails.renderArea,
    PURPOSE: PropertyDetails.renderPurpose,
    LOCATION: PropertyDetails.renderLocation,
    REFERENCE_NUMBER: PropertyDetails.renderReferenceNumber,
    CREATION_DATE: PropertyDetails.renderCreationDate,
    ABSOLUTE_CREATION_DATE: PropertyDetails.renderAbsoluteCreationDate,
    UPDATED_DATE: PropertyDetails.renderLastUpdatedDate,
    RESIDENCE_TYPE: PropertyDetails.renderResidenceType,
    VERIFICATION_DATE: PropertyDetails.renderVerificationDate,
    COMPLETION_STATUS: PropertyDetails.renderCompletionStatus,
    FURNISHING_STATUS: PropertyDetails.renderFurnishingStatus,
    AVERAGE_RENT: PropertyDetails.renderAverageRent,
    SERVICE_CHARGES: PropertyDetails.renderServiceCharges,
    OWNERSHIP: PropertyDetails.renderPropertyOwnership,
    ORIGINAL_PRICE: PropertyDetails.renderOriginalPrice,
    PAID_PRICE: PropertyDetails.renderPaidPrice,
    HANDOVER_DATE: PropertyDetails.renderHandoverDate,
});

// @ts-expect-error - TS2339 - Property 'defaultProps' does not exist on type 'typeof PropertyDetails'.
PropertyDetails.defaultProps = {
    columns: 2,
    withIcons: false,
    hideMissingInformation: false,
    details: [
        PropertyDetailsSections.TYPE,
        PropertyDetailsSections.PRICE,
        PropertyDetailsSections.INSTALLMENTS,
        PropertyDetailsSections.BEDROOMS,
        PropertyDetailsSections.BATHROOMS,
        PropertyDetailsSections.AREA,
        PropertyDetailsSections.PURPOSE,
        PropertyDetailsSections.LOCATION,
        PropertyDetailsSections.REFERENCE_NUMBER,
        PropertyDetailsSections.RESIDENCE_TYPE,
        PropertyDetailsSections.CREATION_DATE,
    ],
};

export { PropertyDetailsSections, Key, Value };
export default withI18n()(PropertyDetails);
