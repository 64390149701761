import * as React from 'react';
import { GTMDataLayerVariables, TriggerName, Triggers, ViewSections } from 'strat/gtm';

import { usePageViewTrigger } from 'horizontal/gtm';
import { useCategoryVars } from 'horizontal/gtm';
import type { PaymentOrder } from 'horizontal/payment';

import useGlobalGTMVars from '../useGlobalGTMVars';

import PageType from './pageType';

const GTMPaymentSuccessDataLayer = ({ paymentOrder }: { paymentOrder: PaymentOrder }) => {
    const {
        productIDs,
        total,
        externalID,
        adExternalID,
        paymentMethods,
        categoryExternalID,
        productCategoryExternalID,
    } = paymentOrder;
    const globalVars = useGlobalGTMVars();
    const categoryVars = useCategoryVars(productCategoryExternalID || categoryExternalID);

    const variables = {
        ...globalVars,
        product_id: productIDs,
        order_id: externalID,
        ad_id: adExternalID,
        payment_amount: total,
        page_type: PageType.PAYMENT,
        view_section: ViewSections.BODY,
        name: TriggerName.PAYMENT_SUCCESS,
        value: paymentMethods?.join(' + '),
        ...categoryVars,
    } as const;

    usePageViewTrigger(Triggers.PAYMENT_SUCCESS, variables, !productIDs);

    return <GTMDataLayerVariables variables={variables} />;
};

export default GTMPaymentSuccessDataLayer;
