import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { Ad } from 'horizontal/types';

const useNavigateToOathPage = (ad?: Ad | null) => {
    const router = useRouter();
    const { externalID, slug } = ad || {};

    return React.useCallback(() => {
        if (!slug || !externalID) {
            return;
        }
        router.pushRoute(RouteNames.AD_UNDERTAKING_TERMS, {
            slug,
            externalID,
        });
    }, [router, slug, externalID]);
};

export default useNavigateToOathPage;
