import * as React from 'react';

import useCategoryFieldsByAttributes from '../useCategoryFieldsByAttributes';
import { FIELD_ATTRIBUTES_BEFORE_PRICE } from '../postingConstants';

import renderFields from './renderFields';
import { PriceRelatedFieldProps } from './types';

const useFieldsBeforePrice = ({
    categoryFields,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
}: PriceRelatedFieldProps): React.ReactElement[] => {
    const fieldsBeforePrice = useCategoryFieldsByAttributes(
        categoryFields,
        FIELD_ATTRIBUTES_BEFORE_PRICE,
    );

    return React.useMemo(() => {
        const commonProps = {
            values,
            setFieldValue,
            errors,
            touched,
            onBlur: handleBlur,
        };

        return renderFields(fieldsBeforePrice, commonProps);
    }, [values, errors, touched, handleBlur, setFieldValue, fieldsBeforePrice]);
};

export default useFieldsBeforePrice;
