import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';

import type { GlobalState } from 'horizontal/state';
import noPackagesAvailable from '@app/assets/icons/noPackagesAvailable_noinline.svg';
import { selectCategoryByExternalID } from 'horizontal/categories';

import { ContactSalesTeamSectionRedesign } from './selectMultiplePackagesSection';
import styles from './styles/noPackages.cssm';

type Props = {
    readonly categoryID?: string;
    readonly message?: string;
};

const NoPackages = ({ categoryID, message }: Props) => {
    const categoryName = useSelector((state: GlobalState) =>
        selectCategoryByExternalID(state, categoryID),
    )?.name;
    return (
        <Flex column justifySpaceAround className={styles.noPackagesContainer}>
            <Flex column alignCenter className={styles.noPackagesSection}>
                <img src={noPackagesAvailable} alt="No packages available" />
                <Text.Large center>
                    {message ? (
                        message
                    ) : (
                        <Trans>
                            No packages available for <b>{categoryName}</b>.
                        </Trans>
                    )}
                </Text.Large>
            </Flex>
            {settings.enableCustomizedPackagesOptions ? (
                <ContactSalesTeamSectionRedesign noPackages />
            ) : null}
        </Flex>
    );
};

export default NoPackages;
