import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { selectUserExternalID } from 'strat/user/session';

import Page from 'horizontal/agencyPortal/pages/page';
import { selectProductPurchases } from 'horizontal/packages/selectors';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { DateRange } from 'horizontal/agencyPortal/types';
import { encodeStateFilter } from 'horizontal/adManagement/stateFilterEncoding';
import { fetchUserAgencies, selectUserAgencies } from 'horizontal/agent/state';
import { fetchAgents, fetchOwnerAgent } from 'horizontal/agencyPortal/agents/state';
import { AdStateFilterValues, type ProductPurchase } from 'horizontal/types';

import ensureCanAccessAgencyPortal, {
    ensureCanAccessAgencyPortalAsOwner,
} from './ensureCanAccessAgencyPortal';
import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';

export const PackageDetailSubRoutes = Object.freeze({
    ADS: 'ads',
    AGENTS: 'agents',
});

type AgencyPortalPackageDetailRouteParams = {
    readonly packageID: string;
    readonly tab: Values<typeof PackageDetailSubRoutes>;
    readonly page?: string | number;
    readonly agent?: string;
    readonly stateFilter?: AdStateFilterValues;
    readonly createDate?: DateRange;
};

class AgencyPortalPackageDetailRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_PACKAGE_DETAIL, [
            [
                '^/agencyPortal/package/',
                {
                    name: 'packageID',
                    pattern: `([0-9]+)`,
                },
                {
                    name: 'tab',
                    pattern: `(?:/(${Object.values(PackageDetailSubRoutes).join('|')}))?`,
                },
                '(?:[?#].*)?$',
            ],
        ]);
    }

    createURL(
        {
            packageID,
            tab,
            page,
            agent,
            stateFilter,
            createDate,
        }: AgencyPortalPackageDetailRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        // @ts-expect-error - TS2345 - Argument of type 'string | number | undefined' is not assignable to parameter of type 'string'.
        const pageInt = parseInt(page, 10);
        const actualPage = pageInt && pageInt !== 1 ? pageInt : null;
        const filter = encodeStateFilter({
            agent,
            stateFilter,
            createDate,
        });
        const search: Record<string, any> = {};

        if (filter) {
            search.filter = filter;
        }

        if (actualPage) {
            search.page = actualPage;
        }

        const tabSlug = tab ?? PackageDetailSubRoutes.ADS;

        if (Object.keys(search).length > 0) {
            return { pathname: `/agencyPortal/package/${packageID}/${tabSlug}`, search };
        }
        return { pathname: `/agencyPortal/package/${packageID}/${tabSlug}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            redux: {
                store: { dispatch, getState },
            },
            match: { params },
        } = context;

        if (!ensureCanAccessAgencyPortal(context) || ensureHasAccessToStratCredits(context)) {
            return;
        }
        // Only agency owners are allowed to access the Agents tap
        if (
            params.tab === PackageDetailSubRoutes.AGENTS &&
            !ensureCanAccessAgencyPortalAsOwner(context)
        ) {
            return;
        }

        const userExternalID = selectUserExternalID(getState());

        context.rendering.renderPage(Page.AGENCY_PORTAL_PACKAGE_DETAIL, {
            packageID: params.packageID,
            tab: params.tab,
        });
        const userAgencies = selectUserAgencies(getState());
        const userAgenciesPromise = userAgencies[0]?.externalID
            ? Promise.resolve()
            : dispatch(
                  fetchUserAgencies({
                      userExternalID,
                  }),
              );
        const fetchProductPurchasesPromise = userAgenciesPromise
            .then(() => {
                const userAgencies = selectUserAgencies(getState());
                const agencyExternalID = userAgencies[0]?.externalID;

                if (!agencyExternalID) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
                } else {
                    const productPurchase: ProductPurchase | null | undefined =
                        selectProductPurchases(getState())[0];
                    return Promise.all([
                        productPurchase?.id === Number(params.packageID)
                            ? Promise.resolve()
                            : dispatch(fetchProductPurchases({ IDs: [params.packageID] })).then(
                                  () => {
                                      const packages = getState().productPurchases?.data;
                                      if (!packages || !packages.length) {
                                          context.rendering.renderPage(
                                              Page.AGENCY_PORTAL_NOT_FOUND,
                                          );
                                      }
                                  },
                              ),
                        dispatch(fetchAgents({ externalID: agencyExternalID })),
                        dispatch(fetchOwnerAgent({ externalID: agencyExternalID })),
                    ]);
                }
            })
            .catch(() => {
                context.http.status(404);
                context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
            });

        context.promise.wait(fetchProductPurchasesPromise);
    }
}
const AgencyPortalPackageDetailRouteClass = AgencyPortalPackageDetailRoute;

export { AgencyPortalPackageDetailRouteClass };

export default new AgencyPortalPackageDetailRoute();
