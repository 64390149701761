import React from 'react';
import omit from 'lodash/omit';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { Flex, InputMessage } from 'strat/components';

import IconEdit from 'horizontal/assets/icons/iconEditAddress.svg';
import { selectCategories } from 'horizontal/categories';
import { findDeepList } from 'horizontal/util/findDeep';

import { useCurrentSearchRouteParams } from '../search/routing';

import styles from './styles/saveSearchDialogContent.cssm';
import FiltersContentBullets from './filtersContentBullets';
import useExtraFieldsAndSuggestedTitle from './useExtraFieldsAndSuggestedTitle';

const SaveSearchDialogContent = ({ field }: { field: Field }) => {
    const params = useCurrentSearchRouteParams();
    const { suggestedTitle, formattedExtraFields } = useExtraFieldsAndSuggestedTitle();

    const categories = useSelector(selectCategories);
    const categoryHierarchy = params.category
        ? findDeepList(categories, (cat) => cat.id === params.category?.id, true)
        : [];

    return (
        <>
            <Flex className={styles.container}>
                <input
                    {...omit(field, ['hasError', 'errorMessage', 'onDismissError'])}
                    className={styles.input}
                    placeholder={suggestedTitle}
                />
                <IconEdit className={styles.icon} />
            </Flex>
            {/* @ts-expect-error - TS2339 - Property 'hasError' does not exist on type 'Field<any>'. | TS2339 - Property 'errorMessage' does not exist on type 'Field<any>'. */}
            {field.hasError && <InputMessage isError>{field.errorMessage}</InputMessage>}
            <Flex className={styles.filtersContent}>
                {categoryHierarchy.length > 0 && (
                    <FiltersContentBullets text={categoryHierarchy[0]?.name} />
                )}
                {formattedExtraFields.map((formattedExtraField, index) => (
                    <FiltersContentBullets text={formattedExtraField.formattedValue} key={index} />
                ))}
            </Flex>
        </>
    );
};

export default SaveSearchDialogContent;
