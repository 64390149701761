import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import { AdTitle, Username } from 'horizontal/chat/components';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoom } from 'horizontal/chat/types';

import MessageSeenStatus from '../messageSeenStatus';

import DisabledChatMessage from './disabledChatMessage';
import LastMessage from './lastMessage';
import styles from './styles/details.cssm';

type Props = {
    conversation: ChatRoom;
};

const Details = ({ conversation }: Props) => {
    const sdk = useChatSDK();
    const { ad, isLoading } = sdk.useRoomContactInfo(conversation);
    const disabledInfo = sdk.useRoomDisabledInfo(conversation);
    const lastMessage = sdk.useRoomLastMessage(conversation.xid);
    const { isLastMessageAuthor, isLastMessageRead, isLastMessageReceived } =
        sdk.useRoomReadIndicators(conversation.xid);
    const unreadCount = sdk.useRoomUnreadCount(conversation.xid);

    const isConversationImportant = !!conversation.userFields?.isImportant;

    return (
        <Flex column fillContainer className={styles.container}>
            {isConversationImportant && (
                <Text.Base bold uppercase className={styles.importantLabel}>
                    <Trans>Important</Trans>
                </Text.Base>
            )}
            <Username conversation={conversation} />
            <AdTitle ad={ad} isLoading={isLoading} />
            {lastMessage && !disabledInfo.disabled && (
                <Flex className={styles.withSpacing}>
                    {isLastMessageAuthor && (
                        <MessageSeenStatus
                            isReceived={isLastMessageReceived}
                            isSeen={isLastMessageRead}
                        />
                    )}
                    <LastMessage
                        message={lastMessage}
                        isConversationUnread={unreadCount > 0}
                        className={classNames(styles.text, styles.faded)}
                    />
                </Flex>
            )}
            {disabledInfo.disabled && <DisabledChatMessage disabledInfo={disabledInfo} />}
        </Flex>
    );
};

export default Details;
