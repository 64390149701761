import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import type { ChatMessage } from 'horizontal/chat/types';
import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';

import { getMessageText } from './replies';
import styles from './styles/messageText.cssm';

type Props = {
    readonly message: ChatMessage;
    readonly formattedPrice: string;
};

const MessageText = ({ message, formattedPrice }: Props) => {
    const i18n = useI18n();
    const content = message.content[ChatMessageType.NEGOTIATION];

    if (!content) {
        return null;
    }

    switch (content.state) {
        case ChatNegotiationState.BUYER_MADE_OFFER:
        case ChatNegotiationState.SELLER_MADE_OFFER:
            return (
                <Text.Base className={classNames(styles.content, styles.extraExtraLargeText)} bold>
                    {formattedPrice}
                </Text.Base>
            );
        case ChatNegotiationState.ACCEPTED:
        case ChatNegotiationState.OFFER_REJECTED:
        case ChatNegotiationState.INITIATED:
            return (
                <Text.Base className={classNames(styles.content, styles.regularText)}>
                    {getMessageText(content.state, i18n)}
                </Text.Base>
            );

        default:
            return null;
    }
};

export default React.memo<Props>(MessageText);
