import { t } from '@lingui/macro';
import * as React from 'react';

import { useI18n } from 'strat/i18n/language';
import type { ShortReviewData } from 'strat/reviewsManager/types';
import { AgentReply, ReviewState } from 'strat/reviewsManager';

import ReviewAgentInfo from '../../components/reviewAgentInfo';

import CompletedStarRating from './completedStarRating';
import ReviewDate from './reviewDate';
import ReviewerImage from './reviewerImage';
import styles from './styles/reviewListItem.cssm';

type Props = {
    review: {
        externalID: string;
        isVerified: boolean;
        comment: string;
        createdAt: number;
        reviewerName?: string | null | undefined;
        reply?: ShortReviewData | null | undefined;
        agentName: string;
        agentPhotoURL: string;
        agentPhotoID: string;
        rating: number;
    };
    className?: string;
    forAgency?: boolean;
};

const ReviewListItem = ({ review, className, forAgency = false }: Props) => {
    const i18n = useI18n();
    const { createdAt, reply, agentName, comment, rating } = review;
    const reviewerName = review.reviewerName || t(i18n)`Anonymous`;

    return (
        <div className={className || styles.reviewContainer}>
            <div className={styles.reviewHeader}>
                <ReviewerImage name={reviewerName} />
                <div className={styles.reviewTitle}>
                    <div className={styles.name}>{reviewerName}</div>
                    <ReviewDate timestampMillis={createdAt} className={styles.reviewDate} />
                </div>
            </div>

            <div className={styles.reviewContent}>
                <CompletedStarRating starRating={rating} />
                <div dir="auto">{comment}</div>
                {forAgency && <ReviewAgentInfo review={review} />}
                {reply && reply.state === ReviewState.ACCEPTED && (
                    <AgentReply
                        reply={reply}
                        agentName={agentName}
                        className={styles.replyContainer}
                        height={70}
                        commentClassName={styles.replyComment}
                    />
                )}
            </div>
        </div>
    );
};

const memoized = React.memo(
    ReviewListItem,
    (prevProps, nextProps) => prevProps.review.externalID === nextProps.review.externalID,
);
export default memoized;
