import * as React from 'react';
import { Trans } from '@lingui/macro';
import useI18n from 'strat/i18n/language/useI18n';
import IconInfo from '@app/assets/icons/iconInfo_noinline.svg';
import { Text, Flex, Button } from 'strat/components';

import PurchasePackageErrorIcon from 'horizontal/assets/icons/purchasePackageError_noinline.svg';

import styles from './styles/packagePurchaseError.cssm';
import usePackagePurchaseError from './usePackagePurchaseError';

const PackagePurchaseError = ({ errorStatus }: { errorStatus: string }) => {
    const i18n = useI18n();
    const { title, subTitle, callToActionText, buttonFn, description } = usePackagePurchaseError(
        errorStatus,
        i18n,
    );

    return (
        <Flex column className={styles.container} alignCenter>
            <Flex className={styles.errorMsg} alignCenter justifyCenter>
                <svg className={styles.icon}>
                    <use xlinkHref={`${IconInfo}#info`} />
                </svg>
                <Text.Large>{title}</Text.Large>
            </Flex>
            <Text.Large bold>
                <Trans>What to do next?</Trans>
            </Text.Large>
            <img alt="Map placeholder" src={PurchasePackageErrorIcon} className={styles.image} />
            <Flex column alignCenter>
                {subTitle && <Text.XLarge bold>{subTitle}</Text.XLarge>}
                {description && (
                    <Text.Large className={styles.errorDescription}>{description}</Text.Large>
                )}
            </Flex>
            {callToActionText && (
                <Button onClick={buttonFn} secondary stretch>
                    {callToActionText}
                </Button>
            )}
        </Flex>
    );
};

export default PackagePurchaseError;
