import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';
import Carousel, { CarouselContentStyles } from 'strat/generic/carousel';

import { selectFeaturedAgencies } from 'horizontal/featuredAgencies/selectors';
import { CarouselNavigationButton, NavButtonType } from 'horizontal/components';

import styles from './styles/featuredAgenciesCarousel.cssm';
import FeaturedAgenciesCard from './featuredAgenciesCard';

const computeGroupSize = (windowWidth: any) => {
    if (windowWidth <= CONFIG.build.DEVICE_WIDTH_TABLET) {
        return 1;
    }
    if (windowWidth <= CONFIG.build.DEVICE_WIDTH_DESKTOP) {
        return 3;
    }
    return 4;
};

const FeaturedAgenciesCarousel = () => {
    const featuredAgencies = useSelector(selectFeaturedAgencies);
    const isLanguageRTL = useSelector(selectIsLanguageRTL);

    return (
        <Carousel
            // @ts-expect-error - TS2322 - Type '{ children: Element[]; computeGroupSize: (windowWidth: any) => 1 | 3 | 4; groupItemsAlignmentStyle: "carouselGroupContentStart"; isLanguageRTL: any; renderPreviousButton: (previousFunction: any, canGoPrevious: any) => Element; renderNextButton: (nextFunction: any, canGoNext: any) => Element; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Carousel> & Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
            ariaLabel="Featured agency carousel"
            computeGroupSize={computeGroupSize}
            groupItemsAlignmentStyle={CarouselContentStyles.FLEX_START}
            reversed={isLanguageRTL}
            // @ts-expect-error - TS7006 - Parameter 'previousFunction' implicitly has an 'any' type. | TS7006 - Parameter 'canGoPrevious' implicitly has an 'any' type.
            renderPreviousButton={(previousFunction, canGoPrevious) => (
                <CarouselNavigationButton
                    onClick={previousFunction}
                    disabled={!canGoPrevious}
                    type={NavButtonType.LEFT}
                />
            )}
            navigationLeftClassName={styles.leftNavButton}
            // @ts-expect-error - TS7006 - Parameter 'nextFunction' implicitly has an 'any' type. | TS7006 - Parameter 'canGoNext' implicitly has an 'any' type.
            renderNextButton={(nextFunction, canGoNext) => (
                <CarouselNavigationButton
                    onClick={nextFunction}
                    disabled={!canGoNext}
                    type={NavButtonType.RIGHT}
                />
            )}
            navigationRightClassName={styles.rightNavButton}
            showNextGroupItem
        >
            {featuredAgencies.map((agency) => (
                <FeaturedAgenciesCard key={agency.id} agency={agency} reversed={isLanguageRTL} />
            ))}
        </Carousel>
    );
};

export default FeaturedAgenciesCarousel;
