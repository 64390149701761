const PageSections = Object.freeze({
    LISTING_LIST: 'listing-list',
    DETAIL: 'detail',
    RECOMMENDED_LISTING_LIST: 'recommended-listing-list',
    AGENT_TEAM_LIST: 'agent-team-list',
    BODY: 'body',
    MAIN_IMAGE: 'main_image',
    GALLERY: 'gallery',
    GALLERY_VIEW: 'gallery_view',
    SECONDARY_LOCATION_LABELS: 'secondary-location-labels',
    FEATURED_AGENCIES: 'featured-agencies',
    FOOTER_SECTION: 'footer-section',
    HISTORY_CHIP: 'history-chip',
    SEO_CROSSLINKS: 'seo-crosslinks',
    SALES_VOLUME: 'sales-volume',
    SALES_VALUE: 'sales-value',
    AVG_PRICE: 'avg-price',
    SALES_BREAKDOWN: 'sales_breakdown',
    RENTALS_VOLUME: 'rentals-volume',
    RENTALS_VALUE: 'rentals-value',
    AVG_RENT: 'avg-rent',
    QUICK_FILTERS: 'quick_filters',
    INLINE_FILTER: 'inline_filter',
});

export default PageSections;
