import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import autoBind from 'react-autobind';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';

import { makeCancelable, isCanceled } from 'strat/util';
import type { CancelablePromise } from 'strat/util';
import { loadLibPhoneNumber, formatPhoneNumber } from 'strat/i18n/phone';

type WithCleanPhoneNumberProps = {
    numbers: Array<string> | null | undefined;
};

type WithCleanPhoneNumberState = {
    libraryLoaded: boolean;
};

type PhoneNumberProps = WithCleanPhoneNumberProps & {
    callNumbers: Array<string>;
    cleanNumber: string | null | undefined;
};

const withCleanPhoneNumber = <T extends any>(
    component: Flow.AbstractComponent<any>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'WithCleanPhoneNumberProps'.
): Flow.AbstractComponent<Flow.Diff<T, WithCleanPhoneNumberProps>> => {
    class WithPhoneNumber extends React.Component<
        WithCleanPhoneNumberProps,
        WithCleanPhoneNumberState
    > {
        libPhone: any;
        cancelableLibPhoneNumber: null | CancelablePromise;

        constructor(props: WithCleanPhoneNumberProps) {
            super(props);
            this.cancelableLibPhoneNumber = null;

            autoBind(this);
            this.state = {
                libraryLoaded: false,
            };
        }

        componentDidMount() {
            this.cancelableLibPhoneNumber = makeCancelable(loadLibPhoneNumber());

            this.cancelableLibPhoneNumber.then(
                // @ts-expect-error - TS7006 - Parameter 'lib' implicitly has an 'any' type.
                (lib) => {
                    this.libPhone = lib;
                    if (!this.state.libraryLoaded) {
                        this.setState({ libraryLoaded: true });
                    }
                },
                // @ts-expect-error - TS7006 - Parameter 'err' implicitly has an 'any' type.
                (err) => {
                    if (isCanceled(err)) {
                        return;
                    }
                    throw err;
                },
            );
        }

        componentWillUnmount() {
            if (this.cancelableLibPhoneNumber && !this.cancelableLibPhoneNumber.isCanceled) {
                this.cancelableLibPhoneNumber.cancel();
            }
        }

        formatPhoneNumber(phoneNumber: string) {
            if (this.libPhone && this.state.libraryLoaded) {
                return formatPhoneNumber(phoneNumber, this.libPhone);
            }

            return phoneNumber;
        }

        getPhoneNumbers(): Array<string> {
            if (!this.props.numbers) {
                return [];
            }

            return this.props.numbers
                .map((number) => this.formatPhoneNumber(number))
                .filter((number) => !!number);
        }

        render() {
            const phoneNumbers = this.getPhoneNumbers();

            return React.createElement(component, {
                callNumbers: phoneNumbers,
                cleanNumber: phoneNumbers[0] || null,
                ...this.props,
            });
        }
    }

    // @ts-expect-error - TS2322 - Type 'typeof WithPhoneNumber' is not assignable to type 'AbstractComponent<Diff<T, WithCleanPhoneNumberProps>, any>'.
    return WithPhoneNumber;
};

const PHONE_NUMBER_SPLITTER = /,|\//;

export const getListWithFirstNumber = (phones: Array<string>): Array<string> => {
    return (phones || []).slice(0, 1);
};

export const splitPhoneNumbers = (
    phone?: string | null,
    defaultValue?: Array<string> | null,
): Array<string> => {
    const value = isNil(defaultValue) ? [] : defaultValue;
    return phone ? phone.split(PHONE_NUMBER_SPLITTER) : value;
};

// @ts-expect-error - TS2345 - Argument of type '(props: PhoneNumberProps) => JSX.Element[] | "-"' is not assignable to parameter of type 'AbstractComponent<any, any>'.
export const PhoneNumberLink = withCleanPhoneNumber((props: PhoneNumberProps) => {
    if (props.callNumbers.length > 0) {
        return props.callNumbers.map((phoneNumber, index) => (
            <a
                key={index}
                href={`tel:${phoneNumber || ''}`}
                {...omit(props, ['numbers', 'callNumbers', 'cleanNumber'])}
            >
                <span dir="ltr">{phoneNumber}</span>
            </a>
        ));
    }
    return '-';
});

export const PhoneNumberText = withCleanPhoneNumber((props) => {
    if (props.cleanNumber) {
        return <span dir="ltr">{props.cleanNumber}</span>;
    }
    return null;
});
