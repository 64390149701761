import { MixedAd } from 'horizontal/types';

import useAdFormattedExtraFields from './useAdFormattedExtraFields';

const useFormatAdExtraFieldsToObject = (ad: MixedAd): Record<string, string> =>
    useAdFormattedExtraFields(ad).reduce<Record<string, any>>(
        (acc, field) => ({
            ...acc,
            [field.attribute]: field.formattedValue,
        }),
        {},
    );

export default useFormatAdExtraFieldsToObject;
