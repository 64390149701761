import Footer from './footer';

export { default as usePopularCategoriesLinks } from './usePopularCategoriesLinks';
export { default as useAboutUsLinks } from './useAboutUsLinks';
export { default as useOtherLinks } from './useOtherLinks';
export { default as useTrendingSearchesLinks } from './useTrendingSearchesLinks';
export { default as useCountriesLinks } from './useCountriesLinks';

export type { FooterLink } from './types';

export default Footer;
