import * as React from 'react';
import { RouteNames } from 'strat/routes';
import settings from '@app/branding/settings';
import Category from '@app/branding/category';

import type {
    Ad,
    CategoryFieldNoChoices,
    LiteCategory,
    LiteHierarchicalLocation,
    Location,
} from 'horizontal/types';
import { CategoryFieldFilterType, CategoryFieldRole } from 'horizontal/types';
import type { Breadcrumb } from 'horizontal/breadcrumbs';
import { BreadcrumbType } from 'horizontal/breadcrumbs';
import { useFullLocations } from 'horizontal/location';
import { useFlatCategoryFields } from 'horizontal/categoryFields';

const BREADCRUMB_CITY_LEVEL = settings.breadcrumbsCityLevel;

const createCategoryFieldFilterValue = <T extends CategoryFieldNoChoices>(field: T, value: any) => {
    switch (field.filterType) {
        case CategoryFieldFilterType.MULTIPLE_CHOICES:
            return [value];

        case CategoryFieldFilterType.SINGLE_CHOICE:
            return value;

        default:
            return null;
    }
};

const computeBreadcrumbs = <T extends CategoryFieldNoChoices>(
    categoryFields: Array<T>,
    ad: Ad | null | undefined,
    fullAdLocationsDict: {
        [key: string]: Location;
    },
): Array<Breadcrumb> => {
    const items: Array<Breadcrumb> = [
        {
            type: BreadcrumbType.LINK,
            route: RouteNames.HOME,
        },
    ];

    if (!ad) {
        return items;
    }

    ad.category.forEach((categoryNode) => {
        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            // @ts-expect-error - TS2322 - Type 'LiteCategory' is not assignable to type 'Category | null | undefined'.
            params: { category: categoryNode },
        });
    });

    const category: LiteCategory = ad.category[ad.category.length - 1];

    // Skip the first item because it is the root node but add the entire location hierarchy
    ad.location.slice(1).forEach((locationNode) => {
        const location = fullAdLocationsDict[locationNode.externalID] || locationNode;

        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            params: {
                // @ts-expect-error - TS2322 - Type 'LiteCategory' is not assignable to type 'Category | null | undefined'.
                category,
                location,
            },
        });
    });

    // We do a reduce here so that we create a breadcrumb item for each
    // extra field, with the previous extra fields included.
    categoryFields.reduce<Record<string, any>>((acc, field) => {
        const value = ad.extraFields?.[field.attribute];
        if (!value || !field.roles.includes(CategoryFieldRole.INCLUDED_IN_BREADCRUMBS)) {
            return acc;
        }

        const filterValue = createCategoryFieldFilterValue(field, value);
        if (!filterValue) {
            return acc;
        }
        const last = ad.location.length - 1;
        const location = fullAdLocationsDict[ad.location[last].externalID] || ad.location[last];

        const extraFields = { ...acc, [field.attribute]: filterValue } as const;

        items.push({
            type: BreadcrumbType.LINK,
            route: RouteNames.SEARCH,
            params: {
                // @ts-expect-error - TS2322 - Type 'LiteCategory' is not assignable to type 'Category | null | undefined'.
                category,
                location,
                extraFields,
            },
        });

        return extraFields;
    }, {});

    // On ad details page, for some of the categories, the last breadcrumb should display the city and not the leaf location
    if (ad && Category.isOfCarsType(category)) {
        const lastBreadcrumbParams = items[items.length - 1].params;
        const city =
            fullAdLocationsDict[ad.location[BREADCRUMB_CITY_LEVEL]?.externalID] ||
            ad.location[BREADCRUMB_CITY_LEVEL];
        items[items.length - 1].params = {
            ...lastBreadcrumbParams,
            location: city,
        };

        // In the case of combining the make and model, the second-to-last breadcrumb's location is changed as well
        if (settings.combineMakeAndModelOnBreadcrumbs) {
            const secondToLastBreadcrumbParams = items[items.length - 2].params;
            items[items.length - 2].params = {
                ...secondToLastBreadcrumbParams,
                location: city,
            };
        }
    }
    return items;
};

const useBreadcrumbs = (ad?: Ad | null): Array<Breadcrumb> => {
    const externalIDs = ad?.location.map((item: LiteHierarchicalLocation) => item.externalID);

    const fullAdLocationsDict = useFullLocations(externalIDs || []);

    const categoryFields = useFlatCategoryFields(ad?.category?.slice(-1)?.[0]?.id, ad?.extraFields);
    return React.useMemo(
        () => computeBreadcrumbs(categoryFields, ad, fullAdLocationsDict),
        [ad, categoryFields, fullAdLocationsDict],
    );
};

export default useBreadcrumbs;
