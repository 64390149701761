import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectSearchLoadingState } from '@sector-labs/fe-search-redux/state';
import { selectIsMobile } from 'strat/platform/selectors';
import { ViewSections } from 'strat/gtm';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Flex } from 'strat/components';

import { makeLoadingAdCards } from 'horizontal/adCard';
import AdCard from '@app/adCard/adCard';
import type { Category, LiteAd, Location } from 'horizontal/types';
import { useCurrentLocation } from 'horizontal/location';

import SectionTitle from './sectionTitle';
import { shouldRenderCategoryWithHits, HITS_PER_CATEGORY } from './renderedCategoriesWithHits';
import styles from './styles/categoryWithHitsSection.cssm';

type Props = {
    readonly category: Category;
    readonly ads: Array<LiteAd>;
    readonly title?: string;
    readonly location?: Location;
};

const loadingAdCards = makeLoadingAdCards(
    HITS_PER_CATEGORY,
    SearchHitsLayout.GRID,
    (children: React.ReactNode, key: number) => (
        <div key={key} className={classNames(styles.adCardWrapper, styles.loadingCard)}>
            {children}
        </div>
    ),
);

const CategoryWithHitsSection = ({ category, ads, title, location }: Props) => {
    const { loading } = useSelector(selectSearchLoadingState);
    const currentLocation = useCurrentLocation();
    const isMobile = useSelector(selectIsMobile);

    if (!loading && !shouldRenderCategoryWithHits(ads, isMobile)) {
        return null;
    }

    const adCards = ads.map((ad, index) => (
        <div key={index} className={styles.adCardWrapper} aria-label="Listing">
            <AdCard
                ad={ad}
                viewSection={ViewSections.HOME_PAGE}
                hideFeatured
                hideAgency
                hideElite
            />
        </div>
    ));

    return (
        <div className={styles.container} aria-label="Category with hits section">
            <SectionTitle
                title={title || category.name}
                routeParams={{ category, location: location || currentLocation }}
                loading={loading}
            />
            <Flex className={styles.adsContainer}>{loading ? loadingAdCards : adCards}</Flex>
        </div>
    );
};

export default CategoryWithHitsSection;
