import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language';

import { fetchMultipleLocationsByExternalID } from 'horizontal/search/location';

import type { SavedSearchData } from './types';

const useSavedSearchesLocationData = (savedSearch: SavedSearchData) => {
    const locationExternalId = savedSearch?.params?.location?.externalID;
    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);
    const [location, setLocation] = React.useState<any>(null);

    React.useEffect(() => {
        if (locationExternalId) {
            // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
            fetchMultipleLocationsByExternalID([locationExternalId], language, backend, 1).then(
                (locationData) => {
                    if (!locationData.length) {
                        return;
                    }

                    setLocation(locationData[0]);
                },
            );
        }
    }, [locationExternalId, language, backend]);

    return location;
};

export { useSavedSearchesLocationData };
