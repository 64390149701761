import { createSelector } from 'reselect';
import type { FetchState } from 'strat/fetcher';

import { UserSettingsOperation } from './types';

export const selectUserSettingsState = (state: { userSettings: FetchState }) => state.userSettings;

export const selectUserSettings = createSelector(selectUserSettingsState, (state) => state.data);

export const selectUserSettingsLoading = createSelector(
    selectUserSettingsState,
    (state) => state.loading,
);

export const selectUserSettingsLoaded = createSelector(
    selectUserSettingsState,
    (state) => state.loaded,
);

export const selectUserSettingsOperationParams = createSelector(
    selectUserSettingsState,
    (state) => state.params,
);

export const selectUserSettingsError = createSelector(
    selectUserSettingsState,
    (state) => state.error,
);

export const selectUserSettingsFetchError = createSelector(
    selectUserSettingsError,
    selectUserSettingsOperationParams,
    (error, operationParams) =>
        operationParams?.operation === UserSettingsOperation.FETCH ? error : null,
);

export const selectUserSettingsSaveError = createSelector(
    selectUserSettingsError,
    selectUserSettingsOperationParams,
    (error, operationParams) =>
        operationParams?.operation === UserSettingsOperation.SAVE ? error : null,
);
