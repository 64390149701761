import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import type { FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Dropdown } from 'strat/modal';
import { I18n } from '@lingui/core';
import { Text } from 'strat/components';

import { Gender as GenderType } from 'horizontal/types';
import { DropdownTrigger } from 'horizontal/components';

import styles from './styles/genderDropdown.cssm';

type Props = {
    readonly title: string;
    readonly value?: string | null;
    readonly placeholder?: string;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly fieldKey?: string;
};

const Gender = Object.freeze({
    label(i18n: I18n, gender: string) {
        switch (gender) {
            case GenderType.MALE:
                return t(i18n)`Male`;
            case GenderType.FEMALE:
                return t(i18n)`Female`;
            case GenderType.PREFER_NOT_TO_SAY:
                return t(i18n)`Prefer not to say`;
            default:
                return '';
        }
    },

    all() {
        return [GenderType.MALE, GenderType.FEMALE, GenderType.PREFER_NOT_TO_SAY];
    },
});

const GenderDropdown = ({
    title,
    value = '',
    placeholder = '',
    setFieldValue,
    fieldKey = 'gender',
}: Props) => {
    const [isOpened, setIsOpened] = React.useState(false);
    const i18n = useI18n();

    const renderDropDownTrigger = () => (
        <DropdownTrigger
            isOpen={isOpened}
            placeholder={value ? '' : placeholder} // when a value is selected we don't want to have a placeholder
            setIsOpen={(open: boolean) => setIsOpened(open)}
            value={value || GenderType.PREFER_NOT_TO_SAY} // default value if gender is not set
            name={fieldKey}
            selectedValueLabel={value ? Gender.label(i18n, value) : null}
        />
    );

    return (
        <div className={styles.genderContainer}>
            <div className={styles.subsectionTitle}>
                <Text.Regular bold>{title}</Text.Regular>
            </div>
            <Dropdown
                triggerIsDismissble
                open={isOpened}
                onDismissed={() => {
                    setIsOpened(false);
                }}
                renderTrigger={renderDropDownTrigger}
            >
                <div className={styles.datePartPickerValuesContainer}>
                    {Gender.all().map((gender) => {
                        return (
                            <div
                                key={gender}
                                onClick={() => {
                                    setFieldValue(fieldKey, gender);
                                    setIsOpened(false);
                                }}
                                className={classNames(styles.datePartPickerValueItem, {
                                    [styles.selectedValue]: gender === (value || ''),
                                })}
                            >
                                {Gender.label(i18n, gender)}
                            </div>
                        );
                    })}
                </div>
            </Dropdown>
        </div>
    );
};

export default GenderDropdown;
