import * as React from 'react';
import { CableUIHostDriver, useCableUIHost } from '@sector-labs/cable-widgets';
import { useSelector } from 'react-redux';
import { useKeycloakStoreSession } from 'strat/auth/keycloak';
import { LoadingSpinnerOverlay } from 'strat/components';
import { selectIsUserLoggedIn } from 'strat/user/session';
import useRouter from 'react-true-router/useRouter';
import { RouteNames } from 'strat/routes';

import { ChatSubjectType } from 'horizontal/chat/constants';

import ChatCenter, { ChatCenterProps } from './chatCenter';

const DRIVER = CableUIHostDriver.init(CONFIG.runtime.CABLE_CHAT_EXTERNAL_HOSTS ?? []);

const ChatCenterExternal = (props: ChatCenterProps) => {
    const keycloakStoreSession = useKeycloakStoreSession();
    const router = useRouter();

    const onChatRoomChangeRequest = React.useCallback(
        ({
            contactXID,
            subjectXID,
        }: {
            contactXID?: string | null;
            subjectXID?: string | null;
        }) => {
            if (!contactXID || !subjectXID) {
                return;
            }

            router.pushRoute(RouteNames.CHAT_WIDGET, {
                contactXID,
                subjectXID,
                subjectType: ChatSubjectType.AD,
            });
        },
        [router],
    );

    // Establish communication with parent window (TopHost).
    // In this case, strat acts as a Cable UI host.
    useCableUIHost({
        driver: DRIVER,
        onSessionReceived: keycloakStoreSession,
        onChatRoomChangeRequest: onChatRoomChangeRequest,
    });

    const isLoggedIn = useSelector(selectIsUserLoggedIn);

    if (!isLoggedIn) {
        return <LoadingSpinnerOverlay visible={true} />;
    }

    return <ChatCenter {...props} />;
};

export default ChatCenterExternal;
