import * as React from 'react';
import { Flex } from 'strat/components';

import type { Category } from 'horizontal/types';

import styles from './styles/moreCategoriesGroup.cssm';
import MoreCategoriesGroupEntry from './moreCategoriesGroupEntry';
import { MoreCategoriesChildren } from './moreCategoriesChildren';

type Props = {
    readonly categories: Category[];
    readonly onClick: (arg1: React.SyntheticEvent<any>) => void;
};

const MoreCategoriesGroup = ({ categories, onClick }: Props): React.ReactElement => {
    const [activeCategory, setActiveCategory]: [
        Category,
        (arg1: ((arg1: Category) => Category) | Category) => void,
    ] = React.useState(categories[0]);

    React.useEffect(() => {
        setActiveCategory(categories[0]);
    }, [categories]);

    return (
        <Flex>
            <div className={styles.mainCategoriesContainer}>
                {categories.map((category: Category) => (
                    <MoreCategoriesGroupEntry
                        onCategoryChange={setActiveCategory}
                        key={category.id}
                        category={category}
                        activeCategory={activeCategory}
                    />
                ))}
            </div>
            {categories.map((category) => (
                <MoreCategoriesChildren
                    key={category.id}
                    activeCategory={category}
                    onClick={onClick}
                    display={category.id === activeCategory.id}
                />
            ))}
        </Flex>
    );
};

export default MoreCategoriesGroup;
