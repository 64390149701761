import { t } from '@lingui/macro';
import * as React from 'react';
import className from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';
import Category from '@app/branding/category';

import type { FlatCategoryFieldHierarchy } from 'horizontal/types';
import { trackFilterChange } from 'horizontal/gtm';
import { RefinedSearchLink, useCurrentSearchRouteParams } from 'horizontal/search/routing';
import { CategoryFieldChoiceIcon } from 'horizontal/components';
import useSortedChoices, { SortingOptions } from 'horizontal/fields/useSortedChoices';
import { ExtraFields } from 'horizontal/fields/commonPostingFields';

import styles from './styles/singleChoice.cssm';
import MultipleChoice from './multipleChoice';
import useFilter from './useFilter';
import Expandable from './expandable';
import ChoiceCountLabel from './choiceCountLabel';
import useDisplayPriorityFilteredFacetedChoices from './useDisplayPriorityFilteredFacetedChoices';

type Props = {
    readonly field: FlatCategoryFieldHierarchy;
    readonly visibleCount?: number;
};

const Hierarchical = ({ field, visibleCount }: Props) => {
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    const filter = useFilter(field);
    const params = useCurrentSearchRouteParams();
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Primitive'.
    const choices = useDisplayPriorityFilteredFacetedChoices(field, filter.value);

    const isMakeField = React.useMemo(
        () =>
            Category.isOfCarsSubType(params.category) &&
            field.attribute === ExtraFields.make.attribute,
        [field, params],
    );

    const sortingOption = React.useMemo(() => {
        if (isMakeField && !CONFIG.runtime.STRAT_ENABLE_OLX_SHOW_AD_COUNT) {
            return SortingOptions.SORT_ALPHABETICALLY;
        }

        return SortingOptions.SORT_NONE;
    }, [isMakeField]);

    const sortedChoices = useSortedChoices(choices, sortingOption);
    const childField = field.children[0];
    const i18n = useI18n();

    const newParams = React.useCallback(
        (value, allValues = false) => {
            return {
                extraFields: {
                    [field.attribute]: allValues || filter.value !== value ? value : null,
                    [childField.attribute]: [],
                },
            };
        },
        [field, filter.value, childField.attribute],
    );

    return (
        <Expandable visibleCount={visibleCount}>
            {sortedChoices.map((choice) => (
                <Flex
                    key={choice.value}
                    className={className(styles.entry, {
                        [styles.selected]: filter.value === choice.value,
                    })}
                >
                    <CategoryFieldChoiceIcon
                        className={styles.brandLogo}
                        field={field.attribute}
                        choice={choice}
                        category={params.category}
                    />
                    <Flex column>
                        <div>
                            <RefinedSearchLink
                                params={newParams(choice.value)}
                                onClick={() => trackFilterChange(field.attribute, choice.value)}
                            >
                                <Text.Base className={styles.label}>{choice.label}</Text.Base>
                            </RefinedSearchLink>
                            {CONFIG.runtime.STRAT_ENABLE_OLX_SHOW_AD_COUNT && !!choice.count && (
                                <ChoiceCountLabel count={choice.count} />
                            )}
                        </div>
                        {choice.value === filter.value && childField && (
                            <div>
                                {isMakeField && (
                                    <RefinedSearchLink
                                        params={newParams(choice.value, true)}
                                        onClick={() =>
                                            trackFilterChange(field.attribute, choice.value)
                                        }
                                    >
                                        <Text.Base className={styles.allLabel}>
                                            {t(i18n)`All in ${choice.label}`}
                                        </Text.Base>
                                    </RefinedSearchLink>
                                )}
                                <MultipleChoice field={childField} />
                            </div>
                        )}
                    </Flex>
                </Flex>
            ))}
        </Expandable>
    );
};

export default Hierarchical;
