import settings from '@app/branding/settings';

export const getPageForQueryParams = (page: number) => {
    if (settings.startPaginationFromPageZero) {
        return page - 1;
    }
    return page;
};

export const getPageFromQueryParam = (pageQueryParam: string | number): number => {
    // @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
    const page = parseInt(pageQueryParam, 10);
    if (settings.startPaginationFromPageZero) {
        return page + 1;
    }
    return page;
};
