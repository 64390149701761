import * as React from 'react';
import { Flex } from 'strat/components';
import { LoadingAnimation } from 'strat/components';

import { SearchLink } from 'horizontal/search/routing';
import { SearchRouteParams } from 'horizontal/routes';

import SectionTitleButton from './sectionTitleButton';
import styles from './styles/sectionTitle.cssm';

type Props = {
    readonly title: string;
    readonly onViewMoreClick?: () => void;
    readonly routeParams?: SearchRouteParams;
    readonly loading?: boolean;
};

const SectionTitle = ({ title, onViewMoreClick, routeParams, loading = false }: Props) => {
    const hasViewMoreLink = onViewMoreClick || routeParams;
    if (loading) {
        return (
            <Flex justifySpaceBetween className={styles.container}>
                <LoadingAnimation className={styles.loadingTextLarge} />
                {hasViewMoreLink && <LoadingAnimation className={styles.loadingTextSmall} />}
            </Flex>
        );
    }

    const onClick = () => {
        onViewMoreClick?.();
        window.scrollTo({ top: 0 });
    };

    const viewMoreLink = routeParams ? (
        <SearchLink params={routeParams} onClick={onClick}>
            <SectionTitleButton />
        </SearchLink>
    ) : (
        <span onClick={onClick}>
            <SectionTitleButton />
        </span>
    );

    return (
        <Flex justifySpaceBetween alignEnd className={styles.container}>
            <span className={styles.title} aria-label="Section Title">
                {title}
            </span>
            {hasViewMoreLink && viewMoreLink}
        </Flex>
    );
};

export default SectionTitle;
