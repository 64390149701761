// Heavily inspired from https://stackoverflow.com/a/52187440
export const computeContainedImageSize = (
    img: HTMLImageElement,
    objectFit: 'contain' | 'scale-down',
) => {
    const ratio = img.naturalWidth / img.naturalHeight;

    let newWidth = img.height * ratio;
    let newHeight = img.height;

    if (newWidth > img.width) {
        newWidth = img.width;
        newHeight = img.width / ratio;
    }

    if (objectFit === 'scale-down' && img.naturalHeight < newHeight) {
        return {
            height: img.naturalHeight,
            width: img.naturalWidth,
        };
    }

    return { height: newHeight, width: newWidth };
};
