import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import Designs from 'strat/branding/designs';
import { useI18n, parseFormattedNumber, selectNumberFormatterFactory } from 'strat/i18n/language';
import type { RangeValue } from 'strat/search/types';
import { numberFormatterLocaleFromLanguage } from 'strat/i18n/language/locales';

import NumberSelector from './numberSelector';
import styles from './styles/rangeSelector.cssm';

type FilterFunction = (value: number) => boolean;

/**
 * Properties for {@see RangeSelector}.
 */
type Props = {
    /**
     * Current value to display.
     */
    value: RangeValue;
    /**
     * If true, maxChoices includes state.min
     */
    maxCanEqualMin?: boolean;
    /**
     * List of choices to display as minimum values.
     */
    minChoices: Array<number>;
    /**
     * List of choices to display as maximum values.
     */
    maxChoices: Array<number>;
    /**
     * Callbacks for when the current value changes.
     */
    onChange: (value: RangeValue) => void;
    onMinChange?: (value: number | null) => void;
    onMaxChange?: (value: number | null) => void;
    /**
     * The errors to be used if the range filter's values are invalid.
     */
    errors?: {
        min: string;
        max: string;
    };
    /**
     * Optionally, a function to format numbers.
     */
    formatNumber?: (value?: any) => string;
    /**
     * Placeholder for the max value
     */
    placeholderMax?: string;
    /**
     * Placeholder for the min value
     */
    placeholderMin?: string;
    /**
     * Current active design
     */
    design?: string;
    minimumLabel?: string;
    maximumLabel?: string;
    valueClassName?: string;
    minToolTipPosition?: string;
    maxToolTipPosition?: string;
};

/**
 * Drop down for selecting a range.
 */
const RangeSelector = (props: Props) => {
    const i18n = useI18n();
    const locale = numberFormatterLocaleFromLanguage(i18n.locale);

    const [minError, setMinError] = React.useState<any>(null);
    const [maxError, setMaxError] = React.useState<any>(null);

    const {
        value: propsValue,
        maxCanEqualMin,
        errors,
        onChange,
        onMinChange: propsOnMinChange,
        onMaxChange: propsOnMaxChange,
        formatNumber: propsFormatNumber,
        minChoices: propsMinChoices,
        maxChoices: propsMaxChoices,
        minimumLabel,
        maximumLabel,
        design,
        minToolTipPosition,
        maxToolTipPosition,
        placeholderMin,
        placeholderMax,
        valueClassName,
    } = props;

    const [value, setValue] = React.useState<RangeValue>({
        min: propsValue.min || null,
        max: propsValue.max || null,
    });
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    React.useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    const checkMinAndMaxValues = React.useCallback(
        (min: number, max: number) => min < max || (maxCanEqualMin && min === max),
        [maxCanEqualMin],
    );

    React.useEffect(() => {
        const minValue = value.min ?? 0,
            maxValue = value.max ?? Infinity;
        if (checkMinAndMaxValues(minValue, maxValue)) {
            setMinError(null);
            setMaxError(null);
        }
    }, [value, checkMinAndMaxValues]);

    /**
     * Handler for when the minimum part of the range changes.
     */
    const onMinChange = React.useCallback(
        (min: null | number | string): void => {
            const minValue =
                min && Math.round(parseFormattedNumber(min, locale)) > 0
                    ? Math.round(parseFormattedNumber(min, locale))
                    : null;

            if (
                errors &&
                !isNil(propsValue.max) &&
                !isNil(minValue) &&
                !checkMinAndMaxValues(minValue, propsValue.max)
            ) {
                setMinError(errors.min);
            }
            setMaxError(null);
            setValue((prev) => ({ ...prev, min: minValue }));

            onChange({
                min: minValue,
                max: propsValue.max,
            });

            propsOnMinChange?.(minValue);
        },
        [locale, propsValue, errors, onChange, propsOnMinChange, checkMinAndMaxValues],
    );

    /**
     * Handler for when the maximum part of the range changes.
     */
    const onMaxChange = React.useCallback(
        (max: null | number | string): void => {
            const maxValue =
                max && Math.round(parseFormattedNumber(max, locale)) > 0
                    ? Math.round(parseFormattedNumber(max, locale))
                    : null;

            if (
                errors &&
                !isNil(propsValue.min) &&
                !isNil(maxValue) &&
                !checkMinAndMaxValues(propsValue.min, maxValue)
            ) {
                setMaxError(errors.max);
            }
            setMinError(null);
            setValue((prev) => ({ ...prev, max: maxValue }));

            onChange({
                max: maxValue,
                min: propsValue.min,
            });

            propsOnMaxChange?.(maxValue);
        },
        [locale, propsValue, errors, onChange, propsOnMaxChange, checkMinAndMaxValues],
    );

    /**
     * Formats a number for displaying.
     */
    const formatNumber = React.useCallback(
        (number: null | number): null | string => {
            if (isNil(number)) {
                return number;
            }

            if (propsFormatNumber) {
                return propsFormatNumber(number);
            }

            return numberFormatterFactory().format(number);
        },
        [propsFormatNumber, numberFormatterFactory],
    );

    const minFilter = React.useCallback((): FilterFunction => {
        if (maxCanEqualMin) {
            return (choice: number) => choice <= Math.round(value.max || 0) || value.max === null;
        }
        return (choice: number) => choice < (value.max || 0) || value.max === null;
    }, [value, maxCanEqualMin]);

    const maxFilter = React.useCallback((): FilterFunction => {
        if (maxCanEqualMin) {
            return (choice: number) => choice >= Math.round(value.min || 0);
        }
        return (choice: number) => choice > (value.min || 0);
    }, [value, maxCanEqualMin]);

    const minChoices = propsMinChoices.filter(minFilter());
    const maxChoices = propsMaxChoices.filter(maxFilter());
    const isFilterDesign2022 = design === Designs.FILTER_DESIGN_2022;

    const minLabel = minimumLabel || (isFilterDesign2022 ? t(i18n)`Minimum` : t(i18n)`min`);
    const maxLabel = maximumLabel || (isFilterDesign2022 ? t(i18n)`Maximum` : t(i18n)`max`);

    return (
        <div className={classNames(styles.container, design)}>
            <div className={classNames(styles.column, design)}>
                <NumberSelector
                    label={minLabel}
                    onChange={onMinChange}
                    value={formatNumber(value.min)}
                    renderOption={(option) => formatNumber(option)}
                    options={minChoices}
                    errorMessage={minError}
                    toolTipPosition={minToolTipPosition}
                    onDismissError={() => setMinError(null)}
                    placeholder={placeholderMin}
                    design={design}
                    valueClassName={valueClassName}
                    toolTipClassName={classNames(styles.tooltip, styles.minimum)}
                />
            </div>
            <div className={classNames(styles.column, design)}>
                <NumberSelector
                    showAny
                    label={maxLabel}
                    onChange={onMaxChange}
                    value={formatNumber(value.max)}
                    renderOption={(option) => formatNumber(option)}
                    options={maxChoices}
                    errorMessage={maxError}
                    toolTipPosition={maxToolTipPosition}
                    onDismissError={() => setMaxError(null)}
                    placeholder={placeholderMax}
                    design={design}
                    valueClassName={valueClassName}
                    toolTipClassName={classNames(styles.tooltip, styles.maximum)}
                />
            </div>
        </div>
    );
};

export default RangeSelector;
