import * as React from 'react';
import { useIsMobileLayout } from 'strat/layout';

import { useAd } from 'horizontal/ad/state';
import { useNavigateToAdDetails } from 'horizontal/adDetails';
import type { PaymentSuccessRouteParams } from 'horizontal/routes';

import { PaymentOrder } from '../types';

import useNavigateToSuccessPage from './useNavigateToSuccessPage';

export type NavigateAfterPaymentParams = Omit<
    PaymentSuccessRouteParams,
    'orderExternalID' | 'adExternalID'
> & {
    order: PaymentOrder;
};

const isOrderForSinglePackages = (order: PaymentOrder) => {
    return Boolean(order.adExternalID);
};

const useNavigateAfterPayment = () => {
    const isMobile = useIsMobileLayout();
    const ad = useAd();

    const navigateToAdDetails = useNavigateToAdDetails(ad);
    const navigateToSuccessPage = useNavigateToSuccessPage();

    return React.useCallback(
        ({ order, ...rest }: NavigateAfterPaymentParams) => {
            const orderForSinglePackages = isOrderForSinglePackages(order);

            if (isMobile && orderForSinglePackages && ad?.externalID === order.adExternalID) {
                navigateToAdDetails();
                return;
            }

            navigateToSuccessPage({
                orderExternalID: order.externalID,
                ...rest,
            });
        },
        [isMobile, ad, navigateToAdDetails, navigateToSuccessPage],
    );
};

export default useNavigateAfterPayment;
