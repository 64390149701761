import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import styles from './styles/adCreationTips.cssm';

const useTipsLinks = () => {
    const i18n = useI18n();
    const language = useSelector(selectLanguage);

    return [
        {
            title: t(i18n)`Tips for improving your ads and your chances of selling`,
            url: settings.tipsForImprovingAdSellingChances[language as 'en' | 'ar'],
        },
        {
            title: t(i18n)`All you need to know about Posting Ads`,
            url: settings.allAboutPostingAds[language as 'en' | 'ar'],
        },
    ];
};

const AdCreationTips = () => {
    const tipsLinks = useTipsLinks();

    return (
        <Flex className={styles.container} column fillContainer>
            <Text.Large bold>
                <Trans>Need help getting started?</Trans>
            </Text.Large>
            <Text.Regular>
                <Trans>
                    Review these resource to learn how to create a great ad and increase your
                    selling chances
                </Trans>
            </Text.Regular>
            <ul className={styles.tips}>
                {tipsLinks.map((tip, index) => (
                    <li key={index}>
                        <a href={tip.url} target="_blank noopener noreferrer">
                            <Text.Regular bold>{tip.title}</Text.Regular>
                        </a>
                    </li>
                ))}
            </ul>
            <Text.Regular>
                <Trans>You can always come back to change your ad</Trans>
            </Text.Regular>
        </Flex>
    );
};

export default AdCreationTips;
