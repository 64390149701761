import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { selectActiveUser } from 'strat/user/session';
import { Dropdown } from 'strat/modal';
import settings from '@app/branding/settings';
import { Input, DialogWithHeader, Text, Flex } from 'strat/components';

import { DropdownTrigger } from 'horizontal/components';
import IconPlusCircle from 'horizontal/assets/icons/iconPlusCircle.svg';
import AdAddressForm from 'horizontal/userOrders/addresses/addressBook';
import {
    selectDeliveryAddresses,
    selectDefaultDeliveryAddress,
} from 'horizontal/userOrders/addresses/selectors';
import { fetchAddressBook } from 'horizontal/userOrders/addresses/state';
import type { AppDispatch } from 'horizontal/state';

import DeliveryCheckbox from './deliveryCheckbox';
import { AdDeliveryFields } from './commonPostingFields';
import styles from './styles/deliveryFields.cssm';

type Props = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
};

const DeliveryFields = ({ setFieldValue, touched, errors, values, onBlur }: Props) => {
    const i18n = useI18n();
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector(selectActiveUser);
    const deliveryAddresses = useSelector(selectDeliveryAddresses);
    const defaultDeliveryAddress = useSelector(selectDefaultDeliveryAddress);
    const [selectedAddress, setSelectedAddress] = React.useState({});
    const userExternalID = activeUser?.externalID;
    const [isDropdownOpen, setIsDropDownOpen] = React.useState(false);

    const open = React.useCallback(() => setVisible(true), []);
    const close = React.useCallback(() => {
        setVisible(false);
        dispatch(fetchAddressBook({ userExternalID }));
    }, [dispatch, userExternalID]);

    React.useEffect(() => {
        dispatch(fetchAddressBook({ userExternalID }));
    }, [dispatch, userExternalID]);

    React.useEffect(() => {
        // @ts-expect-error - TS2339 - Property 'id' does not exist on type '{}'.
        if (selectedAddress?.id) {
            return;
        }
        if (values[AdDeliveryFields.pickup_address_id.attribute]) {
            const initialAddress = deliveryAddresses.find(
                (address) => address.id === values[AdDeliveryFields.pickup_address_id.attribute],
            );
            /**
             * an address can be deleted from address book,
             * while the ad still have that address stored
             * in the ad object, we should remove it from ad
             * edit since it got removed from adresses book
             */
            if (!initialAddress) {
                setFieldValue(AdDeliveryFields.pickup_address_id.attribute, '');
                return;
            }
            setSelectedAddress(initialAddress);
        } else if (deliveryAddresses.length > 0) {
            if (defaultDeliveryAddress) {
                setFieldValue(
                    AdDeliveryFields.pickup_address_id.attribute,
                    defaultDeliveryAddress.id,
                );
                setSelectedAddress(defaultDeliveryAddress);
            }
        }
    }, [deliveryAddresses, setFieldValue, selectedAddress, values, defaultDeliveryAddress]);

    const setIsOpen = () => setIsDropDownOpen(!isDropdownOpen);

    const onAddressSelected = (value: any) => {
        const addressSelected = deliveryAddresses.find((address) => address.id === value);
        if (addressSelected) {
            setIsOpen();
            setSelectedAddress(addressSelected);
            setFieldValue(AdDeliveryFields.pickup_address_id.attribute, value);
        }
    };

    const renderTrigger = () => (
        <DropdownTrigger
            title={t(i18n)`Choose pickup address`}
            name={AdDeliveryFields.pickup_address_id.attribute}
            errorMessage={touched.pickup_address_id && errors.pickup_address_id}
            accepted={touched.pickup_address_id && !errors.pickup_address_id}
            setIsOpen={setIsOpen}
            // @ts-expect-error - TS2339 - Property 'name' does not exist on type '{}'.
            value={selectedAddress?.name}
            isOpen={isDropdownOpen}
            // @ts-expect-error - TS2339 - Property 'name' does not exist on type '{}'.
            selectedValueLabel={selectedAddress?.name}
            loading={false}
        />
    );

    return (
        <div>
            <div className={styles.weightContainer}>
                <Input
                    title={t(i18n)`Product weight (kg)`}
                    name={AdDeliveryFields.weight.attribute}
                    onChange={(value) => setFieldValue(AdDeliveryFields.weight.attribute, value)}
                    value={values[AdDeliveryFields.weight.attribute] || ''}
                    errorMessage={touched.weight && errors.weight}
                    accepted={touched.weight && !errors.weight}
                    onBlur={onBlur}
                    type="number"
                />
            </div>
            {CONFIG.runtime.STRAT_ENABLE_AD_PRODUCT_DIMENSIONS && (
                <Flex className={styles.dimensionsContainer}>
                    <Input
                        title={t(i18n)`Length (CM)`}
                        name={AdDeliveryFields.length.attribute}
                        onChange={(value) =>
                            setFieldValue(AdDeliveryFields.length.attribute, value)
                        }
                        value={values[AdDeliveryFields.length.attribute] || ''}
                        errorMessage={touched.length && errors.length}
                        accepted={touched.length && !errors.length}
                        onBlur={onBlur}
                        type="number"
                    />
                    <Input
                        title={t(i18n)`Height (CM)`}
                        name={AdDeliveryFields.height.attribute}
                        onChange={(value) =>
                            setFieldValue(AdDeliveryFields.height.attribute, value)
                        }
                        value={values[AdDeliveryFields.height.attribute] || ''}
                        errorMessage={touched.height && errors.height}
                        accepted={touched.height && !errors.height}
                        onBlur={onBlur}
                        type="number"
                    />
                    <Input
                        title={t(i18n)`Width (CM)`}
                        name={AdDeliveryFields.width.attribute}
                        onChange={(value) => setFieldValue(AdDeliveryFields.width.attribute, value)}
                        value={values[AdDeliveryFields.width.attribute] || ''}
                        errorMessage={touched.width && errors.width}
                        accepted={touched.width && !errors.width}
                        onBlur={onBlur}
                        type="number"
                    />
                </Flex>
            )}
            <div className={styles.addressContainer}>
                <div className={styles.dropDownContainer}>
                    <Dropdown
                        triggerIsDismissble
                        open={isDropdownOpen}
                        renderTrigger={renderTrigger}
                        onDismissed={setIsOpen}
                    >
                        <div className={styles.content} aria-label="Addresses">
                            {deliveryAddresses.map((address) => (
                                <div
                                    onClick={() => onAddressSelected(address.id)}
                                    className={classNames(styles.entry, {
                                        [styles.selected]: values.pickup_address_id === address.id,
                                    })}
                                    key={address.id}
                                >
                                    {address.name}
                                </div>
                            ))}
                        </div>
                    </Dropdown>
                    <Text.Regular>
                        <Trans>* Only the listed addresses support delivery</Trans>
                    </Text.Regular>
                </div>
                <div className={styles.addAddressContainer}>
                    <div className={styles.addAddress} onClick={open}>
                        <IconPlusCircle />
                        {t(i18n)`Add Address`}
                    </div>
                </div>
            </div>
            {!settings.deliverySettings?.disableTermsConditionsCheckbox && (
                <DeliveryCheckbox
                    checked={acceptedTerms}
                    onChange={() => {
                        setFieldValue(AdDeliveryFields.delivery_terms.attribute, !acceptedTerms);
                        setAcceptedTerms((accepted) => !accepted);
                    }}
                    errorMessage={
                        touched[AdDeliveryFields.delivery_terms.attribute] &&
                        errors[AdDeliveryFields.delivery_terms.attribute]
                    }
                />
            )}
            <DialogWithHeader
                visible={visible}
                onVisibilityChanged={setVisible}
                title={t(i18n)`Add a New Address`}
                onBack={close}
                className={styles.dialogContainer}
            >
                <AdAddressForm closeDialog={close} />
            </DialogWithHeader>
        </div>
    );
};

export default DeliveryFields;
