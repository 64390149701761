import settings from '@app/branding/settings';

import type { PhoneNumber, LibPhoneNumber } from './types';

export const stableFormatPhoneNumber = (number: string, countryPhoneFormat: string): string => {
    const digits = number.match(/\d/g);
    let digitItr = 0;

    let formattedString = countryPhoneFormat.replace(/[.]/g, () => {
        const bHasADigitToReplace = digits && digitItr < digits.length;
        const replaceStr = digits && bHasADigitToReplace ? digits[digitItr] : '';
        if (digits && bHasADigitToReplace) {
            digitItr += 1;
        }
        return replaceStr;
    });

    formattedString =
        digits && digitItr < digits.length
            ? `${formattedString}${digits.splice(digitItr).join('')}`
            : formattedString;

    return formattedString;
};

/*
 * Adds '+' in front of a phone number if one does not already exist
 */
export const fixPhoneNumberWithMissingPlus = (originalNumber: string): string => {
    let number = originalNumber.trim();

    if (!number.match(/^\+?[0-9]+$/)) {
        return originalNumber;
    }

    // Add + to the number if missing
    if (!number.startsWith('+')) {
        number = `+${number}`;
    }

    return number;
};

const tryParsePhoneNumber = (
    number: string,
    libPhone: LibPhoneNumber,
): PhoneNumber | null | undefined => {
    try {
        return libPhone.parsePhoneNumberWithError(number);
    } catch (e1: any) {
        try {
            return libPhone.parsePhoneNumberWithError(fixPhoneNumberWithMissingPlus(number));
        } catch (e2: any) {
            return null;
        }
    }
};

/*
 * Helper method to format phone numbers based on their
 * country code. If the number is not complete then it
 * will just return it.
 */
export const formatPhoneNumber = (originalNumber: string, libPhone: LibPhoneNumber): string => {
    let number = originalNumber.trim();
    // Replace phone trunk_prefix with international prefix.
    if (number.startsWith(settings.defaultPhoneNumber.trunk_prefix)) {
        const regex = new RegExp(`^${settings.defaultPhoneNumber.trunk_prefix}`);
        number = number.replace(regex, settings.defaultPhoneNumber.prefix);
    }

    const parsedNumber = tryParsePhoneNumber(number, libPhone);
    if (!parsedNumber || !parsedNumber.isValid()) {
        return originalNumber;
    }

    const countryISO = parsedNumber.country || '';

    // At this point we have a clean and correct version of the number,
    // but the structure of the number is not "stable" and
    // depends on whether the phone number belongs to a land line
    // or a mobile operator, location.
    //
    // So here the number will be converted to a
    // "stable" pre-defined structure.
    number = parsedNumber.number.replace(/[^\d+]/g, '');
    const phoneType = parsedNumber.nationalNumber.startsWith(
        // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        settings.defaultPhoneNumber.mobile_prefix,
    )
        ? 'mobile'
        : 'landline';
    const prefixFormat = CONFIG.build.COUNTRY_PHONE_INPUT_WITH_PREFIX_FORMAT[countryISO];
    const countryPhoneFormat =
        (prefixFormat ? prefixFormat[phoneType] : null) ||
        CONFIG.build.COUNTRY_PHONE_INPUT_FORMAT[countryISO] ||
        CONFIG.build.COUNTRY_PHONE_INPUT_FORMAT_DEFAULT;
    number = stableFormatPhoneNumber(number, countryPhoneFormat);

    return number;
};
