import type { Store } from 'redux';

import EMPTY_OBJECT from 'strat/empty/object';

import { renderPage } from './state';

/**
 * Allows access to the rendering context.
 */
class RoutingContextRendering {
    /**
     * Main Redux store.
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    _store: Store;

    /**
     * Initializes a new instance of {@see RoutingContextRendering}.
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    constructor(store: Store) {
        this._store = store;
    }

    /**
     * Attempts to render the page with the specified name by
     * dispatching an action that sets the current page name
     * in the Redux store.
     */
    renderPage(pageName: string, props: any = EMPTY_OBJECT): void {
        this._store.dispatch(renderPage(pageName, props));
    }
}

export { RoutingContextRendering };

// @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
export default (store: Store): any =>
    (): RoutingContextRendering =>
        new RoutingContextRendering(store);
