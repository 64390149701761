import React, { Component } from 'react';
import autoBind from 'react-autobind';

import { logError } from 'strat/error/log';

import ErrorFallback from './errorFallback';

type Props = {
    children?: React.ReactNode;
};

type State = {
    error: null | Error;
    showFallback: boolean;
};

/*
 *  Catch errors and try re-rendering. If the second
 *  render cycle fails then display the fallback.
 *
 *  If the component has children display the
 *  original component since the error can
 *  come from the children and there is no point
 *  in messing things up.
 */

export function withErrorBoundary<T extends Props>(
    WrappedComponent: React.ComponentType<T>,
    CustomFallback: null | any,
) {
    return class extends Component<T, State> {
        constructor(props: T) {
            super(props);
            autoBind(this);

            this.state = {
                error: null,
                showFallback: false,
            };
        }

        componentDidCatch(
            error: Error,
            errorInfo: {
                componentStack: string;
            },
        ) {
            let showFallback = false;

            /*
             * Log error and if the re-render try fails stop trying.
             */
            if (this.state.error) {
                logError({
                    e: error,
                    msg: errorInfo.componentStack,
                });
            } else {
                showFallback = true;
            }

            /*
             * You can't re-render the tree properly if you have children
             * because they're passed to the component and they won't be re-created.
             */
            if (this.props.children) {
                showFallback = true;
            }

            this.setState({
                error,
                showFallback,
            });
        }

        retryRender() {
            this.setState({
                error: null,
                showFallback: false,
            });
        }

        render() {
            if (this.state.showFallback) {
                if (CustomFallback) {
                    return <CustomFallback error={this.state.error} />;
                }

                return <ErrorFallback error={this.state.error} onRetryClick={this.retryRender} />;
            }

            return <WrappedComponent {...this.props} />;
        }
    };
}
