import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { TransactionPurpose } from 'horizontal/types';
import { FilterableDropdown } from 'horizontal/components';
import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';

import styles from '../styles/searchForm.cssm';

type Props = {
    readonly value: Values<typeof TransactionPurpose>;
    readonly setValue: (value: Values<typeof TransactionPurpose>) => void;
    readonly titleCustomClass?: string;
    readonly showAllPurpose?: boolean;
};

const PurposeFilter = ({
    value,
    setValue,
    titleCustomClass = styles.filterTitle,
    showAllPurpose = false,
}: Props) => {
    const i18n = useI18n();

    const renderSelectionIcon = (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => (
        <IconArrowDown
            onClick={() => setIsOpen(!isOpen)}
            className={isOpen ? styles.iconArrowRotated : styles.iconArrow}
        />
    );

    return (
        <FilterableDropdown
            title={t(i18n)`Purpose`}
            titleCustomClass={titleCustomClass}
            dropdownTriggerIcon={renderSelectionIcon}
            name={'purpose'}
            dropdownTriggerCustomClass={styles.input}
            choicesContainerCustomClass={styles.filterableDropdownContent}
            onChange={(value) => setValue(value)}
            value={value}
            choices={[
                ...(showAllPurpose ? [{ label: t(i18n)`All`, value: TransactionPurpose.ALL }] : []),
                { label: t(i18n)`Sale`, value: TransactionPurpose.FOR_SALE },
                { label: t(i18n)`Rent`, value: TransactionPurpose.FOR_RENT },
            ]}
        />
    );
};
export default PurposeFilter;
