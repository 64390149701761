import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const SitemapPageType = Object.freeze({
    MOST_POPULAR: 'most-popular',
    CATEGORIES: 'categories',
    PROVINCES: 'states',
    CITIES: 'cities',
    POPULAR_SEARCHES: 'popular-searches',
});

const SitemapPageTypeName = Object.freeze({
    choices: (i18n: I18n) => {
        return {
            [SitemapPageType.MOST_POPULAR]: t(i18n)`Most Popular`,
            [SitemapPageType.CATEGORIES]: t(i18n)`Categories`,
            [SitemapPageType.PROVINCES]: t(i18n)`Provinces`,
            [SitemapPageType.CITIES]: t(i18n)`Cities`,
            [SitemapPageType.POPULAR_SEARCHES]: t(i18n)`Popular Searches`,
        };
    },
});

export { SitemapPageTypeName };

export default SitemapPageType;
