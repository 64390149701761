import FetcherFactory from 'strat/fetcher';

import { StratAPI } from 'horizontal/api';

const factory = new FetcherFactory(
    'sellingOrders',
    (
        {
            userExternalID,
            offersStatus,
        }: {
            userExternalID: string;
            offersStatus: string;
        },
        state: any,
    ) => {
        if (!userExternalID) {
            return Promise.resolve({ status: 200, data: [] });
        }

        return new StratAPI(state.i18n.language).userSellingOrdersList(
            userExternalID,
            offersStatus,
        );
    },
    {
        catchServerErrors: true,
        skipParamsCheck: true,
    },
);

const fetchUserSellingList = factory.creator();
const sellingOrdersReducer = factory.reducer();

export { fetchUserSellingList };

export default sellingOrdersReducer;
