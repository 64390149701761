import { Trans } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { LoadingSpinner } from 'strat/loadable';

import styles from './styles/telesalesTool.cssm';
import TeleSalesTableRow from './teleSalesTableRow';

type Props = {
    readonly loading: boolean;
    readonly items: Array<any>;
    readonly headers: Array<{
        text: string;
        value: string | ((item?: any) => string | React.ReactNode);
    }>;
    readonly setSelectedItem: (id: string | undefined) => void;
    readonly itemKey: string;
    readonly radioInputName: string;
    readonly tableTitle?: string;
    readonly emptyTableMessage?: string;
    readonly selectedItem?: string;
};

const TeleSalesTable = ({
    loading,
    items,
    headers,
    setSelectedItem,
    itemKey,
    radioInputName,
    tableTitle,
    emptyTableMessage,
    selectedItem,
}: Props) => {
    React.useEffect(() => {
        if (loading) {
            return;
        }

        if (items.length === 0) {
            if (!selectedItem) {
                return;
            }

            return setSelectedItem(undefined);
        }

        if (selectedItem || items.length > 1) {
            return;
        }

        setSelectedItem(items[0][itemKey]);
    }, [loading, setSelectedItem, selectedItem, items, itemKey]);

    if (loading) {
        return <LoadingSpinner />;
    }
    if (items.length === 0) {
        return <Text.XLarge>{emptyTableMessage}</Text.XLarge>;
    }

    return (
        <Flex column>
            {tableTitle && (
                <Text.XLarge bold uppercase>
                    {tableTitle}
                </Text.XLarge>
            )}

            <div className={styles.tableRow}>
                <Text.Regular>
                    <Trans>Checked</Trans>
                </Text.Regular>
                {headers.map((header) => (
                    <Text.Regular key={radioInputName + header.text}>{header.text}</Text.Regular>
                ))}
            </div>

            {items.map((itemElement) => (
                <TeleSalesTableRow
                    key={itemElement[itemKey]}
                    headers={headers}
                    item={itemElement}
                    itemKey={itemKey}
                    radioInputName={radioInputName}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />
            ))}
        </Flex>
    );
};

export default TeleSalesTable;
