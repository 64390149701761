import React from 'react';
import Icons from '@app/assets/icons/iconAdCategories_noinline.svg';

import { Flex } from 'strat/components';
import { FilterValues } from 'strat/search';
import { useI18n } from 'strat/i18n/language';

import styles from './styles/categoryWithIcon.cssm';

type Props = {
    categorySlug: string;
};

const CategoryWithIcon = ({ categorySlug }: Props) => {
    const i18n = useI18n();
    const categoryName = FilterValues.category.render(i18n, categorySlug, true);

    if (!categoryName) {
        return null;
    }

    return (
        <Flex className={styles.container}>
            <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                    <use href={`${Icons}#${categorySlug}`} />
                </svg>
            </div>
            <div className={styles.name}>{categoryName}</div>
        </Flex>
    );
};

export default CategoryWithIcon;
