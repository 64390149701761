import * as React from 'react';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import EMPTY_ARRAY from 'strat/empty/array';

import { selectCategories, findCategoryBySlug } from 'horizontal/categories';
import type { Category, LiteAd, Location } from 'horizontal/types';

import { PromotedSearch } from './promotedSearches/types';

export type PromotedSearchSection = {
    readonly title: string;
    readonly category: Category;
    readonly location: Location;
    readonly hits: Array<LiteAd>;
};

const usePromotedSearchSections = (): ReadonlyArray<PromotedSearchSection> => {
    const i18n = useI18n();
    const categories = useSelector(selectCategories) as Category[];
    const promotedSearches = useSelector(
        (state: any) => state.promotedSearches.data || EMPTY_ARRAY,
    );

    return React.useMemo(
        () =>
            settings.homePagePromotedSearches
                .map(
                    ({
                        displayCategorySlug,
                        searchCategorySlug,
                        locationExternalID,
                        displayTitle,
                    }) => {
                        const searchCategory = findCategoryBySlug(categories, searchCategorySlug);
                        const searchSection = promotedSearches.find(
                            (search: PromotedSearch) =>
                                search.displayCategorySlug === displayCategorySlug &&
                                search.searchCategorySlug === searchCategorySlug &&
                                search.location.externalID === locationExternalID,
                        );

                        return {
                            category: searchCategory as Category,
                            hits: searchSection?.hits,
                            location: searchSection?.location,
                            title: displayTitle(i18n),
                        };
                    },
                )
                .filter((section) => !!section.hits && !!section.location),
        [i18n, categories, promotedSearches],
    );
};

export default usePromotedSearchSections;
