import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import { ChoiceButton } from 'horizontal/generic/choicePage';
import { useHumanizedPeriod } from 'horizontal/util';
import type { Ad } from 'horizontal/types';
import { PackagePriceTag } from 'horizontal/packages';

import styles from './styles/packageChoiceBox.cssm';
import type { LimitPackageChoice } from './types';

type Props = {
    readonly isSelected: boolean;
    readonly choice: LimitPackageChoice;
    readonly ad?: Ad | null | undefined;
    readonly isEnabled: boolean | undefined;
};

const PackageChoiceBox = ({ isSelected, choice, isEnabled }: Props) => {
    const period = choice.expiresAt ? new Date(choice.expiresAt).getTime() - Date.now() : null;
    const expiresPeriod = useHumanizedPeriod(period);

    return (
        <Flex alignCenter justifySpaceBetween>
            <Flex column fillContainer>
                <Flex>
                    <ChoiceButton isSelected={isSelected} isEnabled={isEnabled} />
                    <Text.Large bold>{choice.name}</Text.Large>
                    {choice.label && (
                        <div
                            className={styles.label}
                            // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'BackgroundColor | undefined'.
                            style={{ backgroundColor: choice.labelColor }}
                        >
                            <Text.Large bold>{choice.label}</Text.Large>
                        </div>
                    )}
                </Flex>
                {choice.valueProposition && (
                    <div
                        className={styles.description}
                        key="valueProposition-content"
                        dangerouslySetInnerHTML={{
                            __html: choice.valueProposition,
                        }}
                    />
                )}

                {choice.availableQuota !== null && choice.availableQuota !== undefined ? (
                    <div className={styles.valueProposition}>
                        <Text.Regular>
                            <Trans>Available: {choice.availableQuota}</Trans>
                        </Text.Regular>
                    </div>
                ) : null}

                {choice.totalQuotaAssignedToAgents !== null &&
                choice.totalQuotaAssignedToAgents !== undefined ? (
                    <div className={styles.valueProposition}>
                        <Text.Regular>
                            <Trans>Total Assigned: {choice.totalQuotaAssignedToAgents}</Trans>
                        </Text.Regular>
                    </div>
                ) : null}

                {expiresPeriod && (
                    <div className={styles.valueProposition}>
                        <Text.Regular>
                            <Trans>Package expires in {expiresPeriod}</Trans>
                        </Text.Regular>
                    </div>
                )}
            </Flex>

            {choice.price && (
                <PackagePriceTag fullPrice={choice.price} discountPrice={choice.discountPrice} />
            )}
        </Flex>
    );
};

export default PackageChoiceBox;
