import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectNumberFormatterFactory } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { matchChoiceValue } from 'strat/util';

import { sortByGroupIndex } from 'horizontal/util';
import { PriceTypeField } from 'horizontal/fields/commonPostingFields';
import { CategoryFieldFilterType } from 'horizontal/types';
import type { FlatCategoryField, CategoryFieldsFilters } from 'horizontal/types';

export type FormattedExtraField = {
    readonly name: string;
    readonly attribute: string;
    readonly formattedValue: string;
};

const createFormattedField = (
    field: FlatCategoryField,
    formattedValue: string,
): FormattedExtraField => ({
    name: field.name,
    attribute: field.attribute,
    formattedValue,
});

const useSearchFiltersFormattedExtraFields = (
    extraFields: CategoryFieldsFilters | null | undefined,
    categoryFields: Array<FlatCategoryField>,
): Array<FormattedExtraField> => {
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const baseCurrency = settings.baseCurrencyName;

    const formatNumber = React.useCallback(
        (value: number) => numberFormatterFactory().format(value),

        [numberFormatterFactory],
    );

    return React.useMemo(
        () =>
            categoryFields.sort(sortByGroupIndex).reduce<Array<any>>((acc, field) => {
                const value = extraFields?.[field.attribute];
                if (!value) {
                    return acc;
                }

                if (field.filterType === CategoryFieldFilterType.RANGE) {
                    const fieldDescription = PriceTypeField.attribute.startsWith(field.attribute)
                        ? baseCurrency
                        : field.name;
                    // @ts-expect-error - TS2339 - Property 'min' does not exist on type 'string | number | true | Range | Primitive[]'.
                    const formattedMin = `${fieldDescription} ${formatNumber(value.min)}`;
                    // @ts-expect-error - TS2339 - Property 'max' does not exist on type 'string | number | true | Range | Primitive[]'.
                    const formattedMax = `${fieldDescription} ${formatNumber(value.max)}`;
                    // @ts-expect-error - TS2339 - Property 'max' does not exist on type 'string | number | true | Range | Primitive[]'.
                    const formattedValue = value.max
                        ? `${formattedMin} - ${formattedMax}`
                        : `Min ${formattedMin}`;
                    return [...acc, createFormattedField(field, formattedValue)];
                }
                if (field.filterType === CategoryFieldFilterType.MULTIPLE_CHOICES) {
                    const choice = (field.choices || [])
                        // @ts-expect-error - TS2339 - Property 'includes' does not exist on type 'string | number | true | Range | Primitive[]'.
                        .filter((innerChoice) => value.includes(innerChoice.value))
                        .map((innerChoice) => innerChoice.label);

                    if (!choice) {
                        return acc;
                    }

                    return [...acc, createFormattedField(field, choice.join(', '))];
                }
                if (field.filterType === CategoryFieldFilterType.SINGLE_CHOICE) {
                    const fieldChoice = field.choices.find((choice) =>
                        matchChoiceValue(choice, value),
                    );
                    return [
                        ...acc,
                        createFormattedField(
                            field,
                            fieldChoice ? fieldChoice.label : value.toString(),
                        ),
                    ];
                }
                return [...acc, createFormattedField(field, value.toString())];
            }, []),
        [categoryFields, formatNumber, extraFields, baseCurrency],
    );
};

export default useSearchFiltersFormattedExtraFields;
