import * as React from 'react';
import { useSelector } from 'react-redux';
import brandingSettings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { selectCategories } from 'horizontal/categories';

import { selectPopularSearches } from './state/selectors';
import PopularSearchesItems from './popularSearchesItems';
import type { CategoryPopularSearch } from './types';
import styles from './styles/popularSearchesCategory.cssm';

const getPopularSearchForCategory = (
    categoryId: number,
    popularSearches: Array<CategoryPopularSearch>,
) => {
    const popularSearch = popularSearches.find(
        (popularSearchItem) => popularSearchItem.categoryId === categoryId,
    );
    if (popularSearch) {
        return popularSearch.popularSearchEntry;
    }
    return [];
};

const PopularSearchesCategory = () => {
    const { location, category, popularSearches } = useSelector(selectPopularSearches);
    const existentCategories = useSelector(selectCategories);
    const childrenCategories = category?.children || existentCategories;
    const currentLocation = location || brandingSettings.topLevelLocation;

    return (
        <Flex className={styles.container}>
            {/* @ts-expect-error - TS7006 - Parameter 'childCategory' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type. */}
            {childrenCategories.map((childCategory, index) => (
                <PopularSearchesItems
                    category={childCategory}
                    location={currentLocation}
                    key={index}
                    popularWords={getPopularSearchForCategory(childCategory.id, popularSearches)}
                />
            ))}
        </Flex>
    );
};

export default PopularSearchesCategory;
