import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Dialog } from 'strat/modal';
import { useI18n } from 'strat/i18n/language';
import { Button, Flex, Text } from 'strat/components';

import carIcon from '@app/assets/icons/iconCar_noinline.svg';

import styles from './styles/mileageInfoTooltipDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
};

const MileageInfoTooltipDialog = ({ visible, onVisibilityChanged }: Props) => {
    const i18n = useI18n();

    return (
        <Dialog
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            overlayClassName={styles.overlay}
        >
            <Flex alignStart className={styles.container}>
                <Flex column className={styles.details}>
                    <Text.Large>
                        <Trans>
                            You can <b>find the kilometres on your car's dashboard</b> (AKA car
                            mileage)
                        </Trans>
                    </Text.Large>
                    <div>
                        <Button small onClick={() => onVisibilityChanged(false)}>{t(
                            i18n,
                        )`Ok, got it`}</Button>
                    </div>
                </Flex>
                <img src={carIcon} className={styles.icon} />
            </Flex>
        </Dialog>
    );
};

export default MileageInfoTooltipDialog;
