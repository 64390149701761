import { t } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import type { LiteHierarchicalLocation, Location } from 'horizontal/types';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import styles from './styles/locationEntry.cssm';
import LocationIcon from './locationIcon';

export const useLocationHighlightedName = (
    i18n: I18n,
    location: Location | LiteHierarchicalLocation,
) => {
    if (!location) {
        return null;
    }
    let parentLocationName;
    if ('hierarchy' in location) {
        switch (location.hierarchy.length) {
            case 0:
            case 1:
                break;
            case 2:
                parentLocationName = location.hierarchy[0].name;
                break;
            case 3:
                parentLocationName = location.hierarchy[1].name;
                break;
            default:
                parentLocationName = location.hierarchy[CONFIG.build.CITY_LEVEL].name;
                break;
        }
    }
    let locationName = location.name;
    if (
        '_highlightResult' in location &&
        location._highlightResult &&
        location._highlightResult.name
    ) {
        locationName = location._highlightResult.name[0].value;
    }

    return parentLocationName ? t(i18n)`${locationName}, ${parentLocationName}` : locationName;
};

type Props = {
    readonly location: Location;
    readonly label?: string;
    readonly withArrow?: boolean;
    readonly className?: string;
};

const LocationEntry = ({ location, label, withArrow, className }: Props) => {
    const i18n = useI18n();

    const highlightedName = useLocationHighlightedName(i18n, location);

    return (
        <Flex alignCenter className={classNames(styles.container, className)}>
            <LocationIcon className={styles.icon} />
            <div className={styles.textContainer}>
                <Text.Regular isDangerousHTML>{label || highlightedName}</Text.Regular>
            </div>
            {withArrow && location.hasChildren && (
                <img src={iconArrowDown} alt={'Select location'} className={styles.arrow} />
            )}
        </Flex>
    );
};

export default LocationEntry;
