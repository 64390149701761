import { Product, ProductSection, ProductType } from 'horizontal/types';

const getProductSection = (productItems: Array<Product>) => {
    let productSection;

    const productTypes = productItems.map((productItem) => productItem.type);

    if (
        productTypes?.includes(ProductType.FEATURED) &&
        productTypes?.includes(ProductType.LIMIT_BUMP)
    ) {
        productSection = ProductSection.postMoreAndFeature;
    } else if (
        productTypes?.includes(ProductType.AUTO_REFRESH_AD) &&
        productTypes?.includes(ProductType.LIMIT_BUMP)
    ) {
        productSection = ProductSection.postMoreAndBoost;
    }

    return productSection;
};

export { getProductSection };
