import * as React from 'react';
import classNames from 'classnames';
import { Button, Text } from 'strat/components';

import styles from './styles/ctaSection.cssm';

type Props = {
    buttonText?: string;
    onClick?: () => void;
    className?: string;
};

const CTASection = ({ buttonText, onClick, className }: Props) => (
    <div className={classNames(styles.buttonContainer, className)}>
        <Button stretch onClick={onClick}>
            <Text.Large bold>{buttonText}</Text.Large>
        </Button>
    </div>
);

export default CTASection;
