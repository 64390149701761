import React from 'react';
import { Flex } from 'strat/components';

import { isBoostedWithSingleBoost } from 'horizontal/adManagement/utils';
import { adHasAutoBoost } from 'horizontal/adManagement/utils';
import { AdVirtualState, type FullAd } from 'horizontal/types';

import StateDescriptionText from './stateDescriptionText';
import styles from './styles/adState.cssm';
import AdStatePill from './statePill';

type Props = {
    ad: FullAd;
    compact?: boolean;
};

const StateSeparator = () => <div className={styles.separator} />;

const AdState = ({ ad, compact }: Props) => {
    const hasAutoBoost = adHasAutoBoost(ad);
    const hasSingleBoost = isBoostedWithSingleBoost(ad);
    const isFeaturedOrElite = [AdVirtualState.FEATURED, AdVirtualState.ELITE].includes(
        ad.virtualState,
    );

    return (
        <>
            <Flex className={styles.state}>
                <AdStatePill ad={ad} />
                <Flex column={compact} alignCenter={!compact} className={styles.gap}>
                    <StateDescriptionText ad={ad} />
                    {isFeaturedOrElite && hasSingleBoost && !hasAutoBoost && (
                        <>
                            {!compact && <StateSeparator />}
                            <StateDescriptionText ad={ad} showExtraDescriptionText />
                        </>
                    )}
                </Flex>
            </Flex>
            {isFeaturedOrElite && hasAutoBoost && (
                <>
                    {!compact && <StateSeparator />}
                    <Flex className={styles.state}>
                        <AdStatePill ad={ad} showBoostedPill />
                        <StateDescriptionText ad={ad} showExtraDescriptionText />
                    </Flex>
                </>
            )}
        </>
    );
};

export default AdState;
