import * as React from 'react';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import type { CancelablePromise } from 'strat/util';

import { useTPayPayment } from 'horizontal/payment/tpay';
import { PaymentSelectionRoute, PaymentSelectionFreeAdUndertakingRoute } from 'horizontal/routes';
import { useCategoryVars } from 'horizontal/gtm';
import type { FullAd } from 'horizontal/types';

import useAreebaPayment from '../areeba/useAreebaPayment';
import { SupportedCurrencies } from '../areeba/types';
import usePaymobWalletPayment from '../paymobWallet/usePaymobWalletPayment';
import type { PaymentOrder, CheckoutCreditCard } from '../types';
import { PaymentSource, ProductPackageOffer } from '../types';

import useBankTransferPayment from './useBankTransferPayment';
import useCheckoutPayWhatYouWant from './useCheckoutPayWhatYouWant';
import useJazzCashMobileWalletPayment from './useJazzCashMobileWalletPayment';
import useCheckoutPayment from './useCheckoutPayment';
import useCashPayment from './useCashPayment';
import useFawryPayment from './useFawryPayment';
import usePaymobCreditPayment from './usePaymobCreditPayment';
import useProviderCashPayment from './useProviderCashPayment';
import usePaymobBankInstallmentPayment from './usePaymobBankInstallmentPayment';
import useEasyPaisaPayment from './useEasyPaisaPayment';

type AdditionalPaymentData = {
    routeIdentifier?: string;
    page_type?: string;
    commissionAmount?: string;
    packages?: Array<ProductPackageOffer>;
    ad?: FullAd;
    card?: CheckoutCreditCard;
};

type Props = {
    readonly ad?: FullAd;
    readonly order?: PaymentOrder;
};

const useMakePayment = ({ order, ad }: Props) => {
    const [loading, setIsLoading] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState<string | null>(null);
    const [paymentPromise, setPaymentPromise] = React.useState<
        CancelablePromise | null | undefined
    >(null);

    const categoryID = ad
        ? ad.category.slice(-1)[0].externalID
        : order?.productCategoryExternalID || order?.categoryExternalID;
    const categoryVars = useCategoryVars(categoryID);

    React.useEffect(
        () => () => {
            if (paymentPromise) {
                setIsLoading(false);
            }
        },
        [paymentPromise],
    );

    const checkoutPayment = useCheckoutPayment({ order, setIsLoading, setPaymentPromise });

    const { checkoutPayWhatYouWant } = useCheckoutPayWhatYouWant({
        checkoutPayment,
        setPaymentError,
        setPaymentPromise,
        setIsLoading,
    });
    const tPayPayment = useTPayPayment({ order, setIsLoading, setPaymentPromise });
    const easyPaisaPayment = useEasyPaisaPayment({ order, setIsLoading, setPaymentPromise });
    const jazzCashMobileWalletPayment = useJazzCashMobileWalletPayment(order);
    const fawryPayment = useFawryPayment({ order, setIsLoading, setPaymentPromise });
    const areebaPayment = useAreebaPayment({ order, setIsLoading, setPaymentPromise });

    const paymobCreditPayment = usePaymobCreditPayment({ order, setIsLoading, setPaymentPromise });
    const bankTransferPayment = useBankTransferPayment();
    const paymobWalletPayment = usePaymobWalletPayment(order);

    const paymobBankInstallmentPayment = usePaymobBankInstallmentPayment({
        order,
        setIsLoading,
        setPaymentPromise,
    });

    const cashPayment = useCashPayment();

    const providerCashPayment = useProviderCashPayment({ order, setIsLoading, setPaymentPromise });

    React.useEffect(() => {
        return () => {
            setPaymentError(null);
            setPaymentPromise(null);
            setIsLoading(false);
        };
    }, []);

    const onPaymentSelected = React.useCallback(
        (source: Values<typeof PaymentSource>, additionalPaymentData?: AdditionalPaymentData) => {
            const commissionAmount = additionalPaymentData?.commissionAmount;
            const packages = additionalPaymentData?.packages;
            const adID = additionalPaymentData?.ad?.id;
            const adExternalID = additionalPaymentData?.ad?.externalID;
            const pageType = additionalPaymentData?.page_type;
            const routeIdentifier = additionalPaymentData?.routeIdentifier;
            const card = additionalPaymentData?.card;
            const isFreeAdUndertaking =
                routeIdentifier && routeIdentifier === PaymentSelectionFreeAdUndertakingRoute.name;

            trigger(Triggers.SELECT_PAYMENT_METHOD, {
                payment_method: source,
                view_section: ViewSections.BODY,
                page_type: pageType,
                ...categoryVars,
            });

            switch (source) {
                case PaymentSource.AREEBA:
                    areebaPayment(SupportedCurrencies.LBP);
                    break;
                case PaymentSource.AREEBA_CARD_USD:
                    areebaPayment(SupportedCurrencies.USD);
                    break;
                case PaymentSource.CHECKOUT:
                    isFreeAdUndertaking && packages && commissionAmount && adID
                        ? checkoutPayWhatYouWant(packages, commissionAmount, adID, card)
                        : checkoutPayment(order, PaymentSelectionRoute, card);
                    break;
                case PaymentSource.JAZZ_CASH_MOBILE_WALLET:
                    jazzCashMobileWalletPayment(packages, commissionAmount, adID);
                    break;
                case PaymentSource.FAWRY:
                    fawryPayment();
                    break;
                case PaymentSource.TPAY:
                    tPayPayment();
                    break;
                case PaymentSource.PAYMOB:
                    paymobCreditPayment();
                    break;
                case PaymentSource.PAYMOB_WALLET:
                case PaymentSource.VODAFONE_CASH:
                case PaymentSource.ORANGE_CASH:
                case PaymentSource.ETISALAT_CASH:
                case PaymentSource.BANK_WALLET:
                    paymobWalletPayment(source);
                    break;
                case PaymentSource.PAYMOB_BANK_INSTALLMENT:
                    paymobBankInstallmentPayment();
                    break;
                case PaymentSource.CASH:
                    cashPayment();
                    break;
                case PaymentSource.WHISH:
                    providerCashPayment(PaymentSource.WHISH);
                    break;
                case PaymentSource.OMT:
                    providerCashPayment(PaymentSource.OMT);
                    break;
                case PaymentSource.CASH_UNITED:
                    providerCashPayment(PaymentSource.CASH_UNITED);
                    break;
                case PaymentSource.EASYPAISA:
                    easyPaisaPayment();
                    break;
                case PaymentSource.BANK_TRANSFER:
                    if (adExternalID) {
                        bankTransferPayment(adExternalID, Number(commissionAmount));
                    } else {
                        setPaymentError('Ad external id is missing, please refresh your page');
                    }
                    break;
                default:
            }
        },
        [
            bankTransferPayment,
            checkoutPayWhatYouWant,
            checkoutPayment,
            jazzCashMobileWalletPayment,
            areebaPayment,
            fawryPayment,
            tPayPayment,
            paymobCreditPayment,
            paymobWalletPayment,
            paymobBankInstallmentPayment,
            cashPayment,
            providerCashPayment,
            easyPaisaPayment,
            order,
            categoryVars,
        ],
    );

    return {
        onPaymentSelected,
        paymentLoading: loading,
        paymentError,
    };
};

export default useMakePayment;
