import { useEffect } from 'react';
import type { FormikValues } from 'formik';
import { matchValueInChoices } from 'strat/util';

import { CategoryFieldValueType } from 'horizontal/types';
import type { FlatCategoryField } from 'horizontal/types';

const groupIndexThreshold = 4;

/**
 * Will check if a field has combination choices.
 * If it does it will auto-apply the combination choice once there is a single option for the field.
 * Also, if the combinations change it will unselect in case the current value doesn't belong to the new combination set
 *
 * @param field - a CategoryField
 * @param values - the current selected values
 * @param setFieldValue - the setter that will be called to apply the value
 * @param setAutofilledValues mark the values as autofilled
 */
const useApplyFieldCombinations = (
    field: FlatCategoryField,
    values: FormikValues,
    setFieldValue: (arg1: string, arg2?: any) => void,
    setAutofilledValues: (arg1: (number | string)[]) => void,
) => {
    useEffect(() => {
        if (!field.combinationChoices?.length || (field.groupIndex ?? 0) <= groupIndexThreshold) {
            return;
        }
        const currentValue = values[field.attribute];

        if (currentValue && !matchValueInChoices(currentValue, field.combinationChoices)) {
            setFieldValue(field.attribute, undefined);
            setAutofilledValues([]);
        }

        switch (field.valueType) {
            case CategoryFieldValueType.ENUM_MULTIPLE: {
                if (
                    currentValue?.[0] !== field.combinationChoices[0].value ||
                    currentValue.length > 1
                ) {
                    const autofillValues = field.combinationChoices.map((choice) => choice.value);
                    setFieldValue(field.attribute, autofillValues);
                    setAutofilledValues(autofillValues);
                }
                break;
            }
            default:
                {
                    const autofillValue = field.combinationChoices[0].value;

                    if (currentValue !== autofillValue) {
                        setFieldValue(field.attribute, autofillValue);
                        setAutofilledValues([autofillValue]);
                    }
                }
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue]);
};

export default useApplyFieldCombinations;
