import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Button, Text, Flex, LoadingSpinnerOverlay, Input } from 'strat/components';

import { ChatBuyersList } from 'horizontal/chat/widgets/large';
import { AdSoldMedium } from 'horizontal/constants';
import type { FullAd } from 'horizontal/types';

import styles from './styles/markAsSoldContent.cssm';
import { useSubmitSoldAd } from './useDisableAd';
import useSelectedBuyer from './useSelectedBuyer';
import AdContent from './adContent';

type Props = {
    ad: FullAd;
    onSuccess: () => void;
};

const MarkAsSoldContent = (props: Props) => {
    const i18n = useI18n();
    const [soldInfo, setSoldInfo] = React.useState('');
    const { ad, onSuccess } = props;
    const { selectedBuyerExternalID, onBuyerSelected, noUserSelected } = useSelectedBuyer();
    const soldType =
        selectedBuyerExternalID === AdSoldMedium.OUTSIDE_STRAT
            ? AdSoldMedium.OUTSIDE_STRAT
            : AdSoldMedium.INSIDE_STRAT;
    const realBuyerId =
        soldType === AdSoldMedium.OUTSIDE_STRAT ||
        selectedBuyerExternalID === AdSoldMedium.INSIDE_STRAT
            ? null
            : selectedBuyerExternalID;
    const realSoldInfo =
        soldInfo === '' || soldType === AdSoldMedium.INSIDE_STRAT ? null : soldInfo;

    const {
        loading: isSendingSoldAd,
        submitAd,
        isError,
    } = useSubmitSoldAd({
        adExternalID: ad.externalID,
        buyerExternalID: realBuyerId,
        onSuccess,
        soldInfo: realSoldInfo,
        soldType,
    });

    if (isSendingSoldAd) {
        return (
            <Flex className={styles.content}>
                <LoadingSpinnerOverlay visible />;
            </Flex>
        );
    }

    return (
        <div className={styles.content}>
            <AdContent ad={ad} />
            <ChatBuyersList
                adXID={ad.externalID}
                onBuyerSelected={onBuyerSelected}
                selectedBuyerExternalID={selectedBuyerExternalID}
            />
            {isError ? (
                <div className={styles.errorContainer}>
                    <Text.Regular error>
                        <Trans>There was a problem. Please retry later.</Trans>
                    </Text.Regular>
                </div>
            ) : null}
            <div className={styles.button}>
                {soldType === AdSoldMedium.OUTSIDE_STRAT && (
                    <Input
                        placeholder={t(i18n)`Platform name (optional)`}
                        value={soldInfo}
                        onChange={(value) => setSoldInfo(value)}
                    />
                )}
                <Button onClick={submitAd} disabled={noUserSelected}>
                    {isError ? <Trans>Retry</Trans> : <Trans>Mark as sold</Trans>}
                </Button>
            </div>
        </div>
    );
};

export default MarkAsSoldContent;
