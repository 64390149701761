import * as React from 'react';
import classNames from 'classnames';
import { Choice, ChoiceValue } from 'strat/components/types';

import styles from './styles/dropdownChoice.cssm';

interface Props<T extends ChoiceValue> {
    choice: Choice<T>;
    onClick: (value: T) => void;
    selected: boolean;
    renderIcon?: (value: Choice<T>) => React.ReactElement | null | undefined;
}

const DropdownChoice = <T extends ChoiceValue>({
    choice,
    onClick,
    selected,
    renderIcon,
}: Props<T>) => {
    return (
        <div
            onClick={() => onClick(choice.value)}
            className={classNames(styles.entry, {
                [styles.selected]: selected,
            })}
        >
            {renderIcon?.(choice)}
            {choice.label}
        </div>
    );
};

export default DropdownChoice;
