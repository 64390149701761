import * as React from 'react';
import classNames from 'classnames';

import { OffScreen, useWindowSizeWithoutSSR } from 'strat/modal';
import { BottomSheetState } from 'strat/dialogs/dialogContext';
import { stopSyntheticEventPropagation } from 'strat/util';

import { useVelocityTracking, getSingleTouchEvent } from './useVelocityTracking';
import Gesture from './gesture';
import useVerticalGesture from './useVerticalGesture';
import useHorizontalGesture from './useHorizontalGesture';
import useAnimationFrame from './useAnimationFrame';
import { computeProjection, findStateClosestToProjection } from './projections';
import isElementInEventPath from './isElementInEventPath';
import styles from './styles/bottomSheet.cssm';

type Props = {
    name: string;
    visible: boolean;
    children: React.ReactNode;
    renderHeader?: () => React.ReactElement;
    className?: string;
    allowDragAboveMaxThreshold?: boolean;
    activeState: Values<typeof BottomSheetState>;
    setActiveState: (arg1: Values<typeof BottomSheetState>) => void;
    thresholds: Partial<Record<Values<typeof BottomSheetState>, number>>;
};

export const nextStateOnTap = Object.freeze({
    [BottomSheetState.DOCKED]: BottomSheetState.MINIMIZED,
    [BottomSheetState.MINIMIZED]: BottomSheetState.FULL_SCREEN,
    [BottomSheetState.FULL_SCREEN]: BottomSheetState.MINIMIZED,
});

const BottomSheet = ({
    name,
    visible,
    children,
    renderHeader,
    className,
    activeState,
    setActiveState,
    thresholds,
    allowDragAboveMaxThreshold = true,
}: Props) => {
    const [offset, setOffset] = React.useState(0);
    const [isMoving, setIsMoving] = React.useState(false);
    const [clientY, setClientY] = React.useState(0);

    const { height: windowHeight } = useWindowSizeWithoutSSR();
    const [velocity, trackVelocity, resetVelocity] = useVelocityTracking();

    const [initialTouch, setInitialTouch] = React.useState<any>(null);

    const header = React.useRef(null);
    const scrollable = React.useRef(null);
    const [verticalGesture, clearVerticalGesture, computeActiveVerticalGesture] =
        useVerticalGesture(activeState, header, scrollable);

    const [horizontalGesture, clearHorizontalGesture, computeActiveHorizontalGesture] =
        useHorizontalGesture();

    const animate = useAnimationFrame();

    React.useEffect(() => {
        // This is here for iOS - preventing the elements to move around chaotically.
        const body = document.querySelector('body');
        if (!body) {
            return () => {};
        }

        body.classList.add(styles.noOverflow);
        return () => {
            body.classList.remove(styles.noOverflow);
        };
    }, []);

    React.useEffect(() => {
        if (visible) {
            return;
        }

        const body = document.querySelector('body');
        if (!body) {
            return;
        }

        if (!visible) {
            body.classList.remove(styles.noOverflow);
        }
    }, [visible]);

    const onTouchMove = React.useCallback(
        (event) => {
            event.persist();

            const currentTouch = getSingleTouchEvent(event);
            if (!currentTouch) {
                return;
            }

            animate(() => {
                // @ts-expect-error - TS2367 - This condition will always return 'false' since the types '((initialTouch: Touch, currentTouch: Touch) => void) | null' and 'string' have no overlap.
                if (horizontalGesture === Gesture.SCROLL) {
                    return;
                }

                const touchVelocity = trackVelocity(currentTouch);

                // First touch, nothing to do yet.
                if (touchVelocity === null) {
                    return;
                }

                // Never move the drawer if the user is scrolling it.
                // @ts-expect-error - TS2367 - This condition will always return 'false' since the types '((velocityY: number, event: TouchEvent<HTMLElement>) => void) | null' and 'string' have no overlap.
                if (verticalGesture === Gesture.SCROLL) {
                    return;
                }

                // Figure out if we should move the drawer around the screen
                // or scroll it's content.
                // @ts-expect-error - TS2721 - Cannot invoke an object which is possibly 'null'.
                computeActiveVerticalGesture(touchVelocity, event);

                // Figure out if the user is scrolling more vertically or horizontally.
                if (horizontalGesture === Gesture.NONE && isMoving && initialTouch) {
                    // @ts-expect-error - TS2721 - Cannot invoke an object which is possibly 'null'.
                    computeActiveHorizontalGesture(initialTouch, currentTouch);
                }

                if (!isMoving) {
                    setInitialTouch(currentTouch);
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    setOffset(currentTouch.clientY - (windowHeight - thresholds[activeState]));
                }

                setIsMoving(true);
                setClientY(currentTouch.clientY);
            });
        },
        [
            isMoving,
            activeState,
            initialTouch,
            trackVelocity,
            horizontalGesture,
            verticalGesture,
            computeActiveHorizontalGesture,
            computeActiveVerticalGesture,
            windowHeight,
            animate,
            thresholds,
        ],
    );

    const onTouchEnd = React.useCallback(
        (y, event, touchVelocity) => {
            if (!isMoving) {
                resetVelocity();
                // @ts-expect-error - TS2721 - Cannot invoke an object which is possibly 'null'. | TS2554 - Expected 2 arguments, but got 0.
                clearVerticalGesture();

                if (!isElementInEventPath(event, header.current)) {
                    return;
                }

                setActiveState(nextStateOnTap[activeState]);
                stopSyntheticEventPropagation(event);
                return;
            }

            animate(() => {
                // @ts-expect-error - TS2367 - This condition will always return 'false' since the types '((initialTouch: Touch, currentTouch: Touch) => void) | null' and 'string' have no overlap.
                if (horizontalGesture === Gesture.DRAG && touchVelocity) {
                    const projectedY = computeProjection(y, touchVelocity);
                    // @ts-expect-error - TS2345 - Argument of type 'Partial<Record<string, number>>' is not assignable to parameter of type '{ [key: string]: number; }'.
                    const targetState = findStateClosestToProjection(projectedY, thresholds);
                    setActiveState(targetState);
                }

                setIsMoving(false);
                resetVelocity();
                // @ts-expect-error - TS2721 - Cannot invoke an object which is possibly 'null'. | TS2554 - Expected 2 arguments, but got 0.
                clearVerticalGesture();
                // @ts-expect-error - TS2721 - Cannot invoke an object which is possibly 'null'. | TS2554 - Expected 2 arguments, but got 0.
                clearHorizontalGesture();
            });
        },

        [
            thresholds,
            activeState,
            isMoving,
            horizontalGesture,
            animate,
            clearHorizontalGesture,
            clearVerticalGesture,
            resetVelocity,
            setActiveState,
        ],
    );

    const computeStyle = React.useCallback(
        (y, touchOffset) => {
            if (!visible) {
                return {
                    transform: 'translateY(100vh)',
                    visibility: 'hidden',
                };
            }

            // @ts-expect-error - TS2367 - This condition will always return 'false' since the types '((initialTouch: Touch, currentTouch: Touch) => void) | null' and 'string' have no overlap.
            if (!isMoving || horizontalGesture === Gesture.SCROLL) {
                return {
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    transform: `translateY(${windowHeight - thresholds[activeState]}px)`,
                    transition: `transform 250ms ease-out`,
                };
            }

            if (!allowDragAboveMaxThreshold) {
                const maxThresholdHeight =
                    thresholds[BottomSheetState.FULL_SCREEN] ||
                    thresholds[BottomSheetState.MINIMIZED] ||
                    thresholds[BottomSheetState.DOCKED] ||
                    0;

                return {
                    transform: `translateY(${Math.max(
                        y - touchOffset,
                        windowHeight - maxThresholdHeight,
                    )}px`,
                };
            }

            return { transform: `translateY(${Math.max(0, y - touchOffset)}px` };
        },
        [
            isMoving,
            thresholds,
            activeState,
            visible,
            horizontalGesture,
            windowHeight,
            allowDragAboveMaxThreshold,
        ],
    );

    return (
        <OffScreen name={name}>
            <div
                className={classNames(
                    styles.container,
                    {
                        [styles.visible]: visible,
                    },
                    className,
                )}
                onTouchEnd={(event) => onTouchEnd(clientY - offset, event, velocity)}
                onTouchMove={onTouchMove}
                // @ts-expect-error - TS2322 - Type '{ transform: string; visibility: string; transition?: undefined; } | { transform: string; transition: string; visibility?: undefined; } | { transform: string; visibility?: undefined; transition?: undefined; }' is not assignable to type 'CSSProperties | undefined'.
                style={computeStyle(clientY, offset)}
            >
                <div ref={header} className={styles.header}>
                    {renderHeader && renderHeader()}
                </div>
                {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                {React.cloneElement(React.Children.only(children), {
                    // $FlowFixMe
                    // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2339 - Property 'props' does not exist on type 'boolean | ReactChild | ReactFragment | ReactPortal'.
                    className: classNames(children.props.className, {
                        // @ts-expect-error - TS2367 - This condition will always return 'false' since the types '((velocityY: number, event: TouchEvent<HTMLElement>) => void) | null' and 'string' have no overlap.
                        [styles.locked]: verticalGesture === Gesture.DRAG,
                    }),
                    // @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type.
                    ref: (node) => {
                        // @ts-expect-error - TS2339 - Property 'ref' does not exist on type 'true | ReactChild | ReactFragment | ReactPortal'.
                        if (children && children.ref) {
                            // @ts-expect-error - TS2339 - Property 'ref' does not exist on type 'true | ReactChild | ReactFragment | ReactPortal'.
                            children.ref.current = node;
                        }

                        scrollable.current = node;
                    },
                })}
            </div>
        </OffScreen>
    );
};

export default BottomSheet;
