import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikTouched, FormikErrors, FormikValues } from 'formik';
import { useSendEditEmailCode } from '@sector-labs/fe-auth-redux/thunk';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import Yup from 'yup';
import { useI18n } from 'strat/i18n/language';
import Logo from '@app/branding/logo';
import { RequestCodeType, useKeycloakSiteConfigWithCallbacks } from 'strat/auth/keycloak';
import { Input, Flex, Text, Button, Dialog } from 'strat/components';
import { trackOTPSent } from 'strat/gtm';
import { OTPTriggerFlow } from 'strat/auth/keycloak/types';

import UserProfilePicture from '@app/user/userProfilePicture';
import styles from '@app/fields/styles/editEmailDialog.cssm';

import useProfileEditErrorMessage, { ProfileField } from './useProfileEditErrorMessage';

type Props = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly showEmailDialog: boolean;
    readonly setShowEmailDialog: (arg1: boolean) => void;
    readonly setShowEmailConfirmation: (arg1: boolean) => void;
    readonly userEmail: string | null | undefined;
};

const EditEmailDialog = ({
    setFieldValue,
    touched,
    errors,
    values,
    showEmailDialog,
    setShowEmailDialog,
    setShowEmailConfirmation,
    userEmail,
}: Props) => {
    const i18n = useI18n();
    const sendEditEmailCode = useSendEditEmailCode();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const [isValid, setIsValid] = React.useState(false);
    const [authErrorMessage, resetErrorMessage] = useProfileEditErrorMessage(ProfileField.EMAIL);

    const onClick = React.useCallback(() => {
        sendEditEmailCode(keycloakSiteConfig, values.email).then((codeData) => {
            if (!codeData) {
                return;
            }
            setShowEmailConfirmation(true);
            trackOTPSent(RequestCodeType.EMAIL, OTPTriggerFlow.EDIT_PROFILE);
            setShowEmailDialog(false);
            resetErrorMessage();
        });
    }, [
        sendEditEmailCode,
        keycloakSiteConfig,
        values,
        setShowEmailDialog,
        setShowEmailConfirmation,
        resetErrorMessage,
    ]);

    React.useEffect(() => {
        const isValidEmail = Yup.string().email().isValidSync(values.email) && !!values.email;
        const isSameEmail = userEmail && userEmail === values.email;
        setIsValid(isValidEmail && !isSameEmail);
    }, [values, userEmail]);

    return (
        <Dialog
            dismissible
            visible={showEmailDialog}
            onVisibilityChanged={(emailDialogShouldBeVisible) => {
                setShowEmailDialog(emailDialogShouldBeVisible);
                resetErrorMessage();
            }}
            withCloseButton={!!setShowEmailDialog}
        >
            <Flex column justifyCenter alignCenter className={styles.container}>
                <Flex justifyCenter alignCenter>
                    <Logo />
                    <div className={styles.spacer} />
                    <div className={styles.image}>
                        <UserProfilePicture />
                    </div>
                </Flex>
                <div className={styles.title}>
                    <Text.XLarge bold>
                        {t(i18n)`Enter your email to verify your account`}
                    </Text.XLarge>
                </div>
                <div className={styles.sendConfirmationCodeText}>
                    <Text.Regular>{t(
                        i18n,
                    )`We will send a confirmation code to your email`}</Text.Regular>
                </div>
                <div className={styles.input}>
                    <Input
                        name={'Email'}
                        placeholder={t(i18n)`Email address`}
                        value={values.email}
                        accepted={touched.email && !errors.email}
                        onChange={(value) => setFieldValue('email', value)}
                        errorMessage={authErrorMessage}
                        autoFocus
                        lightPlaceholder
                    />
                </div>
                <div className={styles.emailProvidedMessage}>
                    <Text.Small>{t(
                        i18n,
                    )`The email you provide here is only used to verify your account. It will not be made public`}</Text.Small>
                </div>
                <div className={styles.submitButton}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: string; stretch: true; primary: true; disabled: boolean; onClick: () => void; type: string; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button stretch primary disabled={!isValid} onClick={onClick} type="submit">
                        {t(i18n)`Next`}
                    </Button>
                </div>
            </Flex>
        </Dialog>
    );
};

export default EditEmailDialog;
