import * as React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'strat/modal';

import IconClose from 'horizontal/assets/icons/iconClose.svg';
import type { ChatMessageFileUploadData, ChatMessageLocationContent } from 'horizontal/chat/types';
import { selectDialogStack } from 'horizontal/dialogs/selectors';

import DialogContent from './dialogContent';
import styles from './styles/dialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly sendImages: (files: Array<ChatMessageFileUploadData>) => void;
    readonly sendAttachments: (files: Array<ChatMessageFileUploadData>) => void;
    readonly sendLocation: (location: ChatMessageLocationContent) => void;
};

const AttachmentDialog = ({
    visible,
    sendImages,
    sendAttachments,
    onVisibilityChanged,
    sendLocation,
}: Props) => {
    const { stacked, stackGroup } = useSelector(selectDialogStack);
    const onCloseClick = React.useCallback(() => onVisibilityChanged(false), [onVisibilityChanged]);

    if (!visible || !sendImages) {
        return null;
    }

    return (
        <Dialog
            stacked={stacked}
            stackGroup={stackGroup}
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.container}
        >
            <IconClose onClick={onCloseClick} className={styles.closeButton} />
            <DialogContent
                sendImages={sendImages}
                sendAttachments={sendAttachments}
                sendLocation={sendLocation}
                onSubmit={onCloseClick}
            />
        </Dialog>
    );
};

export default AttachmentDialog;
