import * as React from 'react';

import Dialog from './dialog';
import CloseDialogButton from './closeDialogButton';
import styles from './styles/popUp.cssm';

type Props = {
    visible: boolean;
    className?: string;
    children: React.ReactNode;
    onVisibilityChanged: (visible: boolean) => void;
    withoutCloseButton?: boolean;
};

export default (props: Props) => {
    const className = props.className ? `${styles.container} ${props.className}` : styles.container;

    return (
        <Dialog
            dismissible
            visible={props.visible}
            className={className}
            onVisibilityChanged={() => props.onVisibilityChanged(false)}
            zIndex={110}
        >
            {!props.withoutCloseButton && <CloseDialogButton className={styles.closeButton} />}
            {props.children}
        </Dialog>
    );
};
