import React from 'react';
import { useSelector } from 'react-redux';
import Layout from 'strat/layout';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import settings from '@app/branding/settings';
import { selectLayout } from 'strat/layout/selectors';

import { Position, Sizes, SearchBanner, useHasDFPCategory } from 'horizontal/tagmanager';

const useInsertDfpInHitList = (
    listings: Array<any>,
    // @ts-expect-error - TS2749 - 'SearchHitsLayout' refers to a value, but is being used as a type here. Did you mean 'typeof SearchHitsLayout'?
    viewType: SearchHitsLayout,
    category: string,
): boolean => {
    const layout = useSelector(selectLayout);
    const hasDFPCategory = useHasDFPCategory(category);

    if (
        listings &&
        viewType !== SearchHitsLayout.GRID &&
        CONFIG.runtime.OLX_ENABLE_DFP_NATIVE_ADS &&
        settings.enableNativeDfp &&
        settings.enableNativeDfp.layouts.includes(layout) &&
        hasDFPCategory
    ) {
        let currentPosition = settings.nativeDfpCount.initial_step;
        let slotIndex = 0;
        for (
            currentPosition;
            currentPosition < listings.length;
            currentPosition += settings.nativeDfpCount.step + 1
        ) {
            listings.splice(
                currentPosition,
                0,
                <SearchBanner
                    key={`search-banner-ad-${slotIndex.toString()}`}
                    ad={listings[currentPosition - 1]}
                    position={Position.MIDDLE}
                    // @ts-expect-error - TS2322 - Type '(string | { width: number; height: number; })[]' is not assignable to type 'Size[]'.
                    sizes={
                        layout === Layout.DESKTOP
                            ? Sizes.desktop.search.middle
                            : Sizes.mobile.search.middle
                    }
                    slotIndex={slotIndex}
                    aria-label="Native Ad"
                    nativeAd
                />,
            );
            slotIndex += 1;
        }

        return true;
    }

    return false;
};

export default useInsertDfpInHitList;
