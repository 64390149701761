import type { Primitive } from 'strat/types';

import type { FlatCategoryField } from 'horizontal/types';

import { formattedFieldParam } from './utils';

const formatAdExtraField = (
    language: string,
    field: Primitive | null | undefined,
    attribute: string,
    categoryFields: Array<FlatCategoryField>,
    formatFieldValue?: (arg1: Primitive) => Primitive,
): Primitive | null | undefined => {
    if (!field) {
        return null;
    }

    const formattedFields = formattedFieldParam(language, [field], attribute, categoryFields);
    if (!formattedFields.length) {
        return field;
    }

    // @ts-expect-error - TS2322 - Type 'Primitive[]' is not assignable to type 'Primitive | null | undefined'.
    return formatFieldValue ? formattedFields.map(formatFieldValue) : formattedFields;
};

export default formatAdExtraField;
