import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Button, Flex, Text } from 'strat/components';

import SellNowText from '@app/adCard/sellNowText';
import { AppBundles } from 'horizontal/types';
import useNavigateToCreateAd from 'horizontal/navigation/useNavigateToCreateAd';

import styles from './styles/sellNowListing.cssm';

type Props = {
    readonly viewType: Values<typeof SearchHitsLayout>;
    readonly onFilterList?: boolean;
    readonly source?: Values<typeof AppBundles>;
};

const SellNowListing = ({
    viewType,
    onFilterList = false,
    source = AppBundles.MAIN_BUNDLE_NAME,
}: Props) => {
    const navigateToCreateAd = useNavigateToCreateAd(source);

    const sellNowButtonStyle =
        viewType === SearchHitsLayout.GRID
            ? styles.gridStartSellingButton
            : styles.listStartSellingButton;

    return (
        <Flex
            column
            alignCenter
            className={classNames(styles.container, styles[viewType.toLowerCase()], {
                [styles.filterList]: onFilterList,
            })}
            onClick={navigateToCreateAd}
        >
            <Flex fillContainer column alignCenter>
                <Text.Large bold>
                    <Trans>Want to see your stuff here?</Trans>
                </Text.Large>
                <SellNowText className={styles.description} />
            </Flex>
            <Button
                mask
                stretch
                className={classNames(sellNowButtonStyle, styles.startSellingButton)}
            >
                <Text.Regular bold>
                    <Trans>Start selling</Trans>
                </Text.Regular>
            </Button>
        </Flex>
    );
};

export default SellNowListing;
