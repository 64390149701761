import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import failureIcon from '@app/assets/icons/iconPaymentFailed_noinline.svg';

import styles from './styles/errorBanner.cssm';

const ErrorBanner = ({ error }: { error: string }) => {
    const i18n = useI18n();

    return (
        <Flex alignCenter className={styles.card}>
            <img src={failureIcon} className={styles.icon} alt="error" />
            <div className={styles.textContainer}>
                <Text.Large bold block>
                    {t(i18n)`Oops, we couldn't proceed with your action`}
                </Text.Large>
                <Text.Small block>{error}</Text.Small>
            </div>
        </Flex>
    );
};

export default ErrorBanner;
