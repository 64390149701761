import { useRouter } from 'react-true-router';
import { isProfolioUser, isStratCreditUser } from 'strat/user/roles';
import { RouteNames } from 'strat/routes';
import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { AdCreationSourceRoutes } from 'horizontal/constants';
import { AppBundles } from 'horizontal/types';
import useCreditSystemGuard from 'horizontal/authorization/useCreditSystemGuard';

import { useEnsureHasActiveUser } from '../user';

const useNavigateToCreateAd = (source: Values<typeof AppBundles> = AppBundles.MAIN_BUNDLE_NAME) => {
    const i18n = useI18n();
    const brandName = settings.getAgencyPortalBrandName(i18n);
    const withCreditSystemGuard = useCreditSystemGuard(
        t(i18n)`You can only post ads through ${brandName} Pro.`,
    );
    const router = useRouter();

    return useEnsureHasActiveUser({
        onDone: (user) =>
            withCreditSystemGuard(() => {
                const userRoles = user.realmRoles || [];

                if (isProfolioUser(userRoles)) {
                    window.location.href = CONFIG.build.PROFOLIO_URL;
                    return;
                }
                const createAdRoute = isStratCreditUser(userRoles)
                    ? RouteNames.AGENCY_PORTAL_CREATE_AD
                    : AdCreationSourceRoutes[source].CREATE_AD_ROUTE;

                router.pushRoute(createAdRoute);
            }),
    });
};

export default useNavigateToCreateAd;
