import {
    findAllFullTemplates as findAllFullTemplatesBase,
    findAllTemplates as findAllTemplatesBase,
    TemplatePage,
    TemplateType,
    Template,
} from '@sector-labs/templates-renderer';

import type { LocationTypes } from 'strat/locations/locationTypes';

import type { TemplateCollection } from './types';
import { createLoggers } from './logging';

type MatchOptions = {
    purpose?: string | null | undefined;
    categorySlug?: string | null | undefined;
    categoryExternalID?: string | null | undefined;
    locationSlugs?: Array<string> | null | undefined;
    bedroomPage?: boolean;
    areaPage?: boolean;
    locationType?: Values<typeof LocationTypes>;
    completionStatus?: string | undefined;
    templates: TemplateCollection;
};

type FindOptions = MatchOptions & {
    type: Values<typeof TemplateType>;
    page: Values<typeof TemplatePage>;
};

const findAllSortedTemplates = (options: FindOptions): Array<string> => {
    const { templates, ...otherOptions } = options;

    return findAllFullTemplatesBase([...templates], {
        ...otherOptions,
        loggers: createLoggers({ includeMessages: true }),
    })
        .sort((templateA: Template, templateB: Template): number => {
            if (templateA.bedroomPage && !templateB.bedroomPage) {
                return -1;
            } else if (!templateA.bedroomPage && templateB.bedroomPage) {
                return 1;
            }
            return 0;
        })
        .map((template: Template) => template.template);
};

export const findAllTemplates = (options: FindOptions): Array<string> => {
    const { templates, ...otherOptions } = options;

    if (CONFIG.build.STRAT_ENABLE_TEMPLATES_SORTING && options?.bedroomPage) {
        return findAllSortedTemplates(options);
    }

    // The first argument bust be a collection of templates
    return findAllTemplatesBase([...(templates || [])], {
        ...otherOptions,
        loggers: createLoggers({ includeMessages: true }),
    });
};

export const findTemplate = (options: FindOptions): string | null | undefined =>
    findAllTemplates(options)[0] || null;
