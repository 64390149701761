import * as React from 'react';
import { useSearchPagination } from '@sector-labs/fe-search-redux/state';
import { serializeLocation } from 'react-true-router/location';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';
import { buildCanonicalURLWithoutLanguage } from 'strat/routing';
import settings from '@app/branding/settings';

import { useCurrentSearchRouteParams, refineSearchRouteParams } from '../routing';

const LinkRel = Object.freeze({
    NEXT: 'next',
    PREV: 'prev',
});

const PaginationLink = ({ rel, page }: { rel: Values<typeof LinkRel>; page: number }) => {
    const router = useRouter();
    const currentParams = useCurrentSearchRouteParams();
    const refinedParams = refineSearchRouteParams(currentParams, { page });

    const url = buildCanonicalURLWithoutLanguage(
        serializeLocation(router.getRouteURL(RouteNames.SEARCH, refinedParams)),
    );
    return <link rel={rel} href={url} />;
};

const PaginationLinks = () => {
    const [, nextPageNumber, previousPageNumber] = useSearchPagination();

    return (
        <>
            {previousPageNumber && <PaginationLink rel={LinkRel.PREV} page={previousPageNumber} />}
            {nextPageNumber && nextPageNumber <= settings.maxSearchPages && (
                <PaginationLink rel={LinkRel.NEXT} page={nextPageNumber} />
            )}
        </>
    );
};

export default PaginationLinks;
