import { fetchListingsByExternalID } from 'strat/search/algolia';
import { determineAdsIndexName, determineInactiveAdsIndexNames } from 'strat/search/indexNames';

/**
 * Fetches favorite ads from Algolia by looking them up by external ID.
 */
const fetchFavoritesFromIndex = (
    externalIDs: Array<string>,
    language: string = CONFIG.build.LANGUAGE_CODE,
): Promise<any> => {
    if (!externalIDs || externalIDs.length === 0) {
        return Promise.resolve([]);
    }

    const activeIndexName = determineAdsIndexName({ language });
    const inactiveIndexName = determineInactiveAdsIndexNames({ language })[activeIndexName];

    const promises = [fetchListingsByExternalID(activeIndexName, externalIDs)];

    if (CONFIG.build.STRAT_SEARCH_INDEX_INACTIVE_ENABLED === true) {
        promises.push(fetchListingsByExternalID(inactiveIndexName, externalIDs));
    }

    return Promise.all(promises).then((results) => [].concat(...results));
};

export default fetchFavoritesFromIndex;
