import React from 'react';
import { LDJSONScript } from 'strat/seo';

import { useSchema } from 'horizontal/adDetails/seo/schema';
import {
    useTitle,
    useMetaDescription,
    useCanonicalURL,
    AdImageTags,
} from 'horizontal/adDetails/seo';
import { useCurrentAdRouteParams } from 'horizontal/adDetails/routing';
import { useAd } from 'horizontal/ad/state';

import CommonHead from './commonHead';

const AdDetailsHead = () => {
    const ad = useAd();
    const params = useCurrentAdRouteParams();
    const title = useTitle(ad);
    const metaDescription = useMetaDescription(ad);
    const canonicalURL = useCanonicalURL(params);
    const schema = useSchema(ad);

    return (
        <>
            <title>{title}</title>
            {!!metaDescription && <meta name="description" content={metaDescription} />}
            <CommonHead url={canonicalURL?.url} alternateURLs={canonicalURL?.alternateURLs} />
            {!!schema && <LDJSONScript content={schema} />}
            <AdImageTags />
        </>
    );
};

export default AdDetailsHead;
