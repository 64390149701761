import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { useActiveUserExternalID } from 'strat/user/session';

import { useCategoryVars, useTrackPaymentAbort as useTrackAbortPayment } from 'horizontal/gtm';

import type { PaymentOrder } from '../types';

type Props = {
    readonly isPaymentMethodSelected: React.MutableRefObject<boolean>;
    readonly order?: PaymentOrder;
};

const useTrackPaymentAbort = ({ isPaymentMethodSelected, order }: Props) => {
    const userExternalID = useActiveUserExternalID();
    const trackPaymentAbort = useTrackAbortPayment();
    const categoryVars = useCategoryVars(
        order?.productCategoryExternalID || order?.categoryExternalID,
    );

    React.useEffect(() => {
        return () => {
            // the warning below is due to the fact that the linter thinks
            // that the below `ref` might be related to a DOM node, but
            // in our case we are using a ref to store a boolean value
            // further details: https://github.com/facebook/react/issues/15841
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (!isPaymentMethodSelected.current && order && userExternalID) {
                trackPaymentAbort({
                    view_section: ViewSections.BODY,
                    order_id: order?.externalID,
                    product_id: order?.productIDs,
                    payment_amount: order?.total,
                    user_id: userExternalID,
                    ...categoryVars,
                });
            }
        };
    }, [order, categoryVars, userExternalID, trackPaymentAbort, isPaymentMethodSelected]);
};

export default useTrackPaymentAbort;
