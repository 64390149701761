//@ts-nocheck
import { t } from '@lingui/macro';
import brandingSettings from '@app/branding/settings';

import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Area } from 'strat/i18n';
import { Currency } from 'strat/i18n/money';

const Settings = Object.freeze({
    AREA: 'area',
    CURRENCY: 'currency',
    INSTALL_BANNER: 'installBanner',
    SEARCH_HITS_LAYOUT: 'searchHitsLayout',
    INSTALL_BANNER_VERSION: 'installBannerVersion',

    defaultValues() {
        return {
            [this.AREA]: null,
            [this.CURRENCY]: Currency[brandingSettings.defaultCurrencyName],
            [this.INSTALL_BANNER]: brandingSettings.defaultInstallBanner,
            [this.SEARCH_HITS_LAYOUT]: SearchHitsLayout.LIST,
        };
    },

    getOptions() {
        return {
            [this.AREA]: 'areaUnits',
            [this.CURRENCY]: 'currencies',
        };
    },

    type() {
        return {
            [this.AREA]: Area,
            [this.CURRENCY]: Currency,
            [this.INSTALL_BANNER]: Boolean,
            [this.SEARCH_HITS_LAYOUT]: SearchHitsLayout,
        };
    },

    /**
     * Gets localized names for settings.
     */
    text(i18n: any, key: string) {
        const texts = {
            [this.AREA]: t(i18n)`Area`,
            [this.CURRENCY]: t(i18n)`Currency`,
            [this.INSTALL_BANNER]: t(i18n)`Install banner`,
            [this.SEARCH_HITS_LAYOUT]: t(i18n)`Search hits layout`,
        } as const;

        return texts[key];
    },

    /**
     * Normalizes the specified settings object to only include
     * valid settings values and falling back to the default value
     * in case an invalid value was specified for a setting.
     */
    normalize(settings: any) {
        return {
            ...this.defaultValues(),
            ...this.clean(settings),
        };
    },

    /**
     * Cleans the specified settings object to only include
     * valid values.
     *
     * Values that are not valid are stripped. Default values
     * are not included. Use {@see normalize} for that.
     */
    clean(settings: any) {
        const cleanedSettings: Record<string, any> = {};

        const currency = settings[this.CURRENCY];
        if (currency && Currency.isValidValue(currency)) {
            cleanedSettings[this.CURRENCY] = currency;
        }

        const area = settings[this.AREA];
        if (area && Area.isValidValue(area)) {
            cleanedSettings[this.AREA] = area;
        }

        const installBanner = settings[this.INSTALL_BANNER];
        if (typeof installBanner === 'boolean') {
            cleanedSettings[this.INSTALL_BANNER] = installBanner;
        }

        const installBannerVersion = settings[this.INSTALL_BANNER_VERSION];
        if (!isNaN(installBannerVersion)) {
            cleanedSettings[this.INSTALL_BANNER_VERSION] = installBannerVersion;
        }

        const searchHitsLayout = settings[this.SEARCH_HITS_LAYOUT];
        if (searchHitsLayout && Object.values(SearchHitsLayout).includes(searchHitsLayout)) {
            cleanedSettings[this.SEARCH_HITS_LAYOUT] = searchHitsLayout;
        }

        return cleanedSettings;
    },

    cleanFromCookie(settings: any) {
        const cleanedSettings: Record<string, any> = {};
        const installBannerVersion = settings[this.INSTALL_BANNER_VERSION];
        if (!brandingSettings.installBannerVersion) {
            return settings;
        }

        if (installBannerVersion !== brandingSettings.installBannerVersion) {
            cleanedSettings[this.INSTALL_BANNER] = brandingSettings.defaultInstallBanner;
        }

        cleanedSettings[this.INSTALL_BANNER_VERSION] = brandingSettings.installBannerVersion;

        return {
            ...settings,
            ...cleanedSettings,
        };
    },

    all() {
        return [this.AREA, this.CURRENCY, this.INSTALL_BANNER, this.SEARCH_HITS_LAYOUT];
    },
});

export default Settings;
