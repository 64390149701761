import computeIndexNameLanguagePostFix from './computeIndexNameLanguagePostFix';

export const determineLocationsIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_LOCATIONS}-${computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_LOCATIONS_MERGED_INDEX)}`;
};

export const determineAgenciesIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_AGENCIES}-${computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_AGENCY_MERGED_INDEX)}`;
};

export const determineAgentsIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_AGENTS}-${computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_AGENTS_MERGED_INDEX)}`;
};

export const determineFrequentSearchQueriesIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_FREQUENT_SEARCH_QUERIES}-${computeIndexNameLanguagePostFix(language, false)}`;
};

export const determineStoriesIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_STORIES}-${computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX)}`;
};

export const determineUrlSpecificSEOIndexName = ({ language }: { language: string }) => {
    return `${CONFIG.build.STRAT_SEARCH_INDEX_NAME_URL_SPECIFIC_SEO}-${computeIndexNameLanguagePostFix(language, CONFIG.build.ENABLE_MERGED_INDEX)}`;
};
