import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

const warningWords = (i18n: I18n, brandName: string) => [
    {
        warningText: t(
            i18n,
        )`Stay on the safer ${brandName} chat and don't share your personal/whatsapp number with anyone`,
        words: [
            'email',
            'emailaddress',
            'email sale',
            'e-mail',
            'emails',
            'mail',
            'mails',
            'emailinfo',
            'yourmail',
            'e-pos',
            'epos',
            'scam',
            'marketer',
            'nommer',
            'nomer',
            'hangout',
            'viber',
            'sms',
            'data',
            'secure',
            'whatsapp',
            'watsapp',
            'watsup',
            'whats app',
            'whats ap',
            'wats ap',
            'wats app',
            'whattsapp',
            'whattsap',
            'whatsap',
            'number',
            'text',
            'message',
            'mesage',
            'call',
        ],
    },
    {
        warningText: t(
            i18n,
        )`Never make payments or send products in advance, to avoid the risk of fraud`,
        words: [
            'bank',
            'bnk',
            'bankrepo',
            'rek no',
            'rel no.',
            'rek',
            'bankrekening',
            'rekening',
            'deposit',
            'deposito',
            'transfer',
            'dealer',
            'dealership',
            'verified',
            'pay',
            'payment',
            'money',
            'stur geld',
            'auction',
            'advance',
            'moneygram',
            'money gram',
            'wallet',
            'walet',
            'ewallet',
            'e-wallet',
            'sale',
            'finance',
            'funds',
            'betaal oor',
            'oorbetal',
            'oor betal',
            'bankaccount',
            'acc',
            'account',
            'account no.',
            'fnb',
            'absa',
            'nedbank',
            'capitec',
            'std bank',
            'standard bank',
            'banking',
            'courier',
            'delivery',
            'parcel',
            'ship',
            'shipping',
            'shipment',
            'koerier',
            'korier',
            'aflewer',
            'aflewering',
            'aramex',
            'postnet',
            'post net',
            'fastway',
            'fast way',
            'mds collivery',
            'mdscollivery',
            'post',
            'off-shore',
            'offshore',
            'off shore',
            'tracking',
            'oorsee',
            'driver',
            'uber',
            'taxify',
            'tax',
            'dawnwing',
            'dawn wing',
            'googlepay',
            'upfront',
            'send',
            'cash',
            'cas',
            'csah',
            'doorstep',
            'on the spot',
            'dollar',
            'fedex',
            'tcs',
            'leopard',
            'mtcn',
            'payment before delivery',
            'half payment',
            'western union',
            'nigeria',
            'bank transfer',
            'paisay',
            'paise',
            'easy paisa',
            'easypaisa',
            'jazz cash',
            'morocco',
            'united kingdom',
            'usa',
            'california',
        ],
    },
    {
        warningText: t(i18n)`Identities can be fake. Be careful before making any payment`,
        words: [
            'army',
            'military',
            'fauji',
            'fauj',
            'defense',
            'defence',
            'jawaan',
            'subaidaar',
            'captain',
        ],
    },
];

const warningRules = (i18n: I18n, brandName: string) =>
    warningWords(i18n, brandName).map(({ words, warningText }) => [words.join('|'), warningText]);

const useWarningText = (text: string): string | null | undefined => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    return React.useMemo(() => {
        return (
            warningRules(i18n, brandName)
                // @ts-expect-error - TS2769 - No overload matches this call.
                .filter(([regularExpression, _]: [any, any]) =>
                    text.toLowerCase().match(new RegExp(regularExpression)),
                )
                // @ts-expect-error - TS2345 - Argument of type '([_, warningText]: [any, any]) => any' is not assignable to parameter of type '(value: string[], index: number, array: string[][]) => any'.
                .map(([_, warningText]: [any, any]) => warningText)[0]
        );
    }, [text, i18n, brandName]);
};

export default useWarningText;
