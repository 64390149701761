import * as React from 'react';
import autoBind from 'react-autobind';
import isNil from 'lodash/isNil';

type Props = {
    /**
     * The default tab to open. If not provided, the first one will be opened by default.
     */
    readonly defaultTab?: number;
    /**
     * Function used to render the tab switcher. Receives the current opened tab
     * (as an index), and a callback which can be used to toggle between tabs.
     */
    readonly renderSwitcher: (
        openedTab: number,
        switchToTab: (index: number) => void,
    ) => React.ReactNode;
    /**
     * The className of the children's container.
     */
    readonly className?: string;
    /**
     * The children of the component.
     */
    readonly children: React.ReactNode;
    /**
     *  The styling of tab.
     */
    readonly tabDisplay: (
        index: number,
        openedTab: number,
    ) => {
        style?: Record<any, any>;
        className?: string;
    };
    /**
     * Always render switcher (even if there is only one tab)
     */
    readonly alwaysRenderSwitcher?: any;
    /**
     * Places the tab switcher at the top
     */
    readonly renderSwitcherOnTop?: boolean;
};

type State = {
    /**
     * The index of the current opened tab.
     */
    openedTab: number;
};

/**
 * Renders a TabView.
 * The TabView will only render the currently active tab.
 * It provides a callback function which can be used to render a tab switcher.
 * If only one tab is provided, the tab switcher will _not_ be rendered.
 */
class TabView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = {
            openedTab: this.defaultTab(props),
        };
    }

    static defaultProps = {
        tabDisplay: (index: number, openedTab: number) => ({
            style: index === openedTab ? { display: 'block' } : { display: 'none' },
        }),
    };

    /**
     * Checks whether a given index is in the valid range.
     * @param index The index to be checked.
     * @param children The actual child list.
     */
    inRange(index: number, children: React.ReactNode): boolean {
        const childrenCount = React.Children.count(children);
        return index >= 0 && index < childrenCount;
    }

    /**
     * Computes and returns the default tab to be rendered (before user interaction).
     */
    defaultTab(props: Props): number {
        if (isNil(props.defaultTab)) {
            return 0;
        }

        const childrenCount = React.Children.count(props.children);
        if (!props.children || !childrenCount) {
            return 0;
        }

        if (!this.inRange(props.defaultTab, props.children)) {
            console.warn(`Default tab index ${props.defaultTab} is out of range.`);
            return 0;
        }

        return props.defaultTab;
    }

    /**
     * Tries to return the index of the last opened tab or the default otherwise.
     * Usefull after the user started to interact with the tabs.
     */
    defaultUserTab(props: Props): number {
        if (this.inRange(this.state.openedTab, props.children)) {
            return this.state.openedTab;
        }

        return this.defaultTab(props);
    }

    switchToTab(index: number): void {
        if (!this.inRange(index, this.props.children)) {
            console.warn(`Tab index ${index} is out of range.`);
            return;
        }

        this.setState({ openedTab: index });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            React.Children.toArray(nextProps.children).length !==
            React.Children.toArray(this.props.children).length
        ) {
            this.setState({ openedTab: this.defaultUserTab(nextProps) });
        }

        if (nextProps.defaultTab !== this.props.defaultTab) {
            this.setState({ openedTab: this.defaultTab(nextProps) });
        }
    }

    renderSwitcher(alwaysRenderSwitcher: any, childrenLength: number): boolean {
        return alwaysRenderSwitcher ? childrenLength >= 1 : childrenLength > 1;
    }

    render() {
        const children = React.Children.toArray(this.props.children).filter((child) => !!child);
        if (!children || children.length === 0) {
            return null;
        }

        const tabSwitcher = this.props.renderSwitcher &&
            this.renderSwitcher(this.props.alwaysRenderSwitcher, children.length) && (
                <div>{this.props.renderSwitcher(this.state.openedTab, this.switchToTab)}</div>
            );

        return (
            <>
                {this.props.renderSwitcherOnTop && tabSwitcher}
                <div className={this.props.className}>
                    {children.map((child, index) => (
                        <div key={index} {...this.props.tabDisplay(index, this.state.openedTab)}>
                            {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                            {React.cloneElement(child, { active: index === this.state.openedTab })}
                        </div>
                    ))}
                </div>
                {!this.props.renderSwitcherOnTop && tabSwitcher}
            </>
        );
    }
}

export default TabView;
