import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Flex } from 'strat/components';

import type { ChatAd } from 'horizontal/chat/types';

import { getPriceOfferType, PriceOfferType } from './price';
import PriceFeedbackMessageBox from './priceFeedbackMessageBox';
import styles from './styles/priceFeedback.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly price: number;
    readonly offeredPrice: number | null | undefined;
    readonly onSendClick: (value?: number | null | undefined) => unknown;
    readonly minPrice: number | null | undefined;
};

const PriceFeedback = ({ ad, price, offeredPrice, onSendClick, minPrice }: Props) => {
    const { category, location } = ad ?? {};

    const priceOfferType = getPriceOfferType(price, offeredPrice, minPrice);
    const sendButtonDisabled =
        priceOfferType !== PriceOfferType.VERY_GOOD && priceOfferType !== PriceOfferType.GOOD;

    if (!category || !location) {
        return null;
    }

    return (
        <Flex>
            <div className={styles.messageBoxContainer}>
                <PriceFeedbackMessageBox
                    category={category[category.length - 1]}
                    location={location[location.length - 1]}
                    priceOfferType={priceOfferType}
                    price={price}
                    minPrice={minPrice}
                />
            </div>
            <div className={styles.sendButton}>
                <Button
                    stretch
                    disabled={sendButtonDisabled}
                    onClick={() => {
                        if (offeredPrice) {
                            onSendClick(offeredPrice);
                        }
                    }}
                >
                    <Trans>Send</Trans>
                </Button>
            </div>
        </Flex>
    );
};

export default PriceFeedback;
