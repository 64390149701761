import React from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from 'strat/state';

import { removeAdByID } from './state';

const useRemoveAdByID = () => {
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback((adID: number) => dispatch(removeAdByID(adID)), [dispatch]);
};

export default useRemoveAdByID;
