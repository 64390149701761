import type { I18n } from '@lingui/core';
import { dateLocalesFromLanguage } from 'strat/i18n/language';

const numberMap = Object.freeze({
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0',
});

const replaceArabicNumbers = (text: string): string => {
    // server-side we get arabic numbers and client-side we get latin ones.
    // We always want latin ones.
    // source: https://github.com/moment/moment/issues/3685#issuecomment-275462809
    return (
        text
            .replace(/\u200f/g, '')
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<{ '\u0661': string; '\u0662': string; '\u0663': string; '\u0664': string; '\u0665': string; '\u0666': string; '\u0667': string; '\u0668': string; '\u0669': string; '\u0660': string; }>'.
            .replace(/[١٢٣٤٥٦٧٨٩٠]/g, (match) => numberMap[match])
            .replace(/،/g, ',')
    );
};

const defaultOptions = {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
} as const;

const formatDate = (i18n: I18n, date: number, options: any = defaultOptions) =>
    replaceArabicNumbers(
        new Date(date).toLocaleDateString(dateLocalesFromLanguage(i18n.locale), options),
    );

export default formatDate;
