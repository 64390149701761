import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { useActiveUserExternalID } from 'strat/user/session';

import type { ChatMessage } from 'horizontal/chat/types';

const useMessageReply = (messages: Array<ChatMessage>, onMessageReply: () => void) => {
    const userXID = useActiveUserExternalID();
    const lastMessageRef = React.useRef<ChatMessage | null>(null);

    React.useEffect(() => {
        if (!messages) {
            return;
        }
        const lastMessage = messages[messages.length - 1];
        if (!lastMessage) {
            return;
        }
        if (
            lastMessageRef.current &&
            !isEqual(lastMessageRef.current, lastMessage) &&
            lastMessageRef.current.roomXID === lastMessage.roomXID &&
            userXID !== lastMessage.senderXID
        ) {
            onMessageReply();
        }
        if (lastMessageRef.current !== lastMessage) {
            lastMessageRef.current = lastMessage;
        }
    }, [messages, lastMessageRef, userXID, onMessageReply]);
};

export default useMessageReply;
