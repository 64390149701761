import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { Flex } from 'strat/components';

import { useFetchUserSettings, useUserSettingsFetchError } from './hooks';
import Menu from './menu';
import PrivacySheet from './privacySheet';
import NotificationsSheet from './notificationsSheet';
import { UserSettingsSection } from './types';
import styles from './styles/userSettings.cssm';

type Props = {
    readonly section?: Values<typeof UserSettingsSection>;
};

const Sheet = ({ section }: Props) => {
    switch (section) {
        case UserSettingsSection.PRIVACY:
            return <PrivacySheet />;

        case UserSettingsSection.NOTIFICATIONS:
            return <NotificationsSheet />;

        default:
            return null;
    }
};

const UserSettings = ({ section }: Props) => {
    const router = useRouter();
    const fetchUserSettings = useFetchUserSettings();
    const error = useUserSettingsFetchError();

    React.useEffect(() => {
        fetchUserSettings();
    }, [fetchUserSettings]);

    React.useEffect(() => {
        if (!error) {
            return;
        }

        router.pushRoute(RouteNames.INTERNAL_ERROR);
    }, [router, error]);

    return (
        <Flex>
            <Menu section={section} />
            <div className={styles.sheet}>
                <Sheet section={section} />
            </div>
        </Flex>
    );
};

export default UserSettings;
