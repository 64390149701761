import * as React from 'react';
import { Trans } from '@lingui/macro';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import settings from '@app/branding/settings';
import { Button, Flex, Text } from 'strat/components';

import { DeliveryCheckbox } from 'horizontal/fields';
import type { AddressInfo } from 'horizontal/userOrders/types';
import { DeliveryType } from 'horizontal/types';

import styles from './styles/requestDeliveryForm.cssm';
import FormSection from './formSection';
import PersonalDetails from './personalDetailsForm';
import ChooseAddressSection from './chooseAddress';

type Props = {
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly isSubmitting: boolean;
    readonly handleSubmit: (arg1: FormikValues) => void;
    readonly defaultAddress: AddressInfo | null;
    readonly addressBook: AddressInfo[];
    readonly onNewAddressAdded?: () => void;
    readonly deliveryType: DeliveryType;
};

const PaymentMethods = () => {
    return (
        <FormSection title={<Trans>Payment method</Trans>}>
            <>
                <Flex className={styles.paymentMethodChoice}>
                    <input name={'payment_method'} type="radio" checked readOnly />
                    <Text.Regular>
                        <Trans>Cash on delivery</Trans>
                    </Text.Regular>
                </Flex>
                <Text.Small>
                    <Trans>Once you’ve placed your order, the seller should accept it.</Trans>
                    <br />
                    <Trans>This can take up to 2 days.</Trans>
                    <br />
                    <Trans>You only have to pay when the courier arrives at your place.</Trans>
                    <br />
                </Text.Small>
            </>
        </FormSection>
    );
};
type FormFooterProps = {
    readonly disableSubmission: boolean;
    readonly deliveryType: DeliveryType;
};

const FormFooter = ({ disableSubmission, deliveryType }: FormFooterProps) => {
    const [isTermsAccepted, setTermsAccepted] = React.useState(
        settings.deliverySettings?.disableTermsConditionsCheckbox,
    );

    return (
        <Flex column className={styles.footer}>
            <Flex justifySpaceBetween alignCenter>
                {!settings.deliverySettings?.disableTermsConditionsCheckbox && (
                    <DeliveryCheckbox
                        checked={!!isTermsAccepted}
                        onChange={() => setTermsAccepted(!isTermsAccepted)}
                        errorMessage={''}
                        deliveryType={deliveryType}
                    />
                )}
                <div className={styles.buttonContainer}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: Element; primary: true; stretch: true; disabled: boolean; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button primary stretch disabled={!isTermsAccepted || disableSubmission}>
                        <Trans>Next</Trans>
                    </Button>
                </div>
            </Flex>
        </Flex>
    );
};

const RequestDeliveryForm = (props: Props) => {
    const {
        isSubmitting,
        handleSubmit,
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        addressBook,
        onNewAddressAdded,
        defaultAddress,
        deliveryType = DeliveryType.SERVICE_DELIVERY,
    } = props;

    return (
        <Flex column>
            <form onSubmit={handleSubmit}>
                <ChooseAddressSection
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    addressBook={addressBook}
                    onNewAddressAdded={onNewAddressAdded}
                    defaultAddress={defaultAddress}
                />
                <PersonalDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
                <PaymentMethods />
                <FormFooter disableSubmission={isSubmitting} deliveryType={deliveryType} />
            </form>
        </Flex>
    );
};

export default RequestDeliveryForm;
