import { ExclusionFilter } from '@sector-labs/fe-search-redux/filters';

import FetcherFactory from 'strat/fetcher';
import { fetchFromPopularityTrends } from 'strat/search/elasticSearch';
import { fetchLocationsAttributes } from 'strat/search/algolia';
import { BayutCompat } from 'strat/util';

/**
 * Factory for fetching most popular communities.
 */
const factory = new FetcherFactory(
    'mostPopularCommunities',
    (params: any, state: any) => {
        if (!params.areaID) {
            return Promise.resolve({ data: [], status: 200 });
        }

        return (
            fetchFromPopularityTrends(
                CONFIG.runtime.STRAT_SEARCH_INDEX_NAME_MOST_POPULAR_COMMUNITIES,
                params,
                {
                    attributesToRetrieve: ['community_id', 'avg_psf', 'prev_avg_psf'],
                    sort: {
                        type: 'attributes',
                        key: 'impressionsSort',
                        name: 'Impressions sort',
                        attributes: [{ name: 'impressions', order: 'desc' }],
                    },
                },
                [new ExclusionFilter({ attribute: 'community_id', value: 'Unknown' })],
            )
                .then((content) => {
                    if (!content.length) {
                        return [[], {}];
                    }

                    const locationIDs = content.map((contentItem) => contentItem.community_id);

                    return Promise.all([
                        content,
                        // @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'Set<any>'.
                        fetchLocationsAttributes(locationIDs, ['name'], state.i18n.language),
                    ]);
                })
                // @ts-expect-error - TS2345 - Argument of type '([content, locations]: [any, any]) => { data: { communities: any; }; status: number; }' is not assignable to parameter of type '(value: {}[]) => { data: { communities: any; }; status: number; } | PromiseLike<{ data: { communities: any; }; status: number; }>'.
                .then(([content, locations]: [any, any]) => ({
                    data: {
                        // @ts-expect-error - TS7006 - Parameter 'contentItem' implicitly has an 'any' type.
                        communities: content.map((contentItem) => ({
                            community: locations.get(contentItem.community_id).name,
                            avgPricePerSqft: contentItem.avg_psf ? contentItem.avg_psf : null,
                            avgPricePerSqftChange:
                                contentItem.avg_psf && contentItem.prev_avg_psf
                                    ? ((contentItem.avg_psf - contentItem.prev_avg_psf) /
                                          contentItem.prev_avg_psf) *
                                      100
                                    : null,
                        })),
                    },
                    status: 200,
                }))
                .catch(() => ({
                    data: [],
                    status: 404,
                }))
        );
    },
    {
        // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
        getParams: (params, getState) => {
            const property = getState().property.data;
            return {
                purposeID: BayutCompat.Purpose.toID(property.purpose),
                propertyTypeID: property.category[property.category.length - 1].externalID,
                bedrooms: Math.min(property.rooms, 8),
                yearQuarter: BayutCompat.PopularityESIndexYearQuarter.convertTo(
                    params.year,
                    params.quarter,
                ),
                areaID: property.locations[2]?.externalID,
            };
        },
    },
);

/**
 * Fetches most popular communities.
 */
const fetchMostPopularCommunities = factory.creator();

/**
 * Reducer for most popular communities.
 */
const mostPopularCommunitiesReducer = factory.reducer();

export { fetchMostPopularCommunities };

export default mostPopularCommunitiesReducer;
