import React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { ProjectUnit } from 'strat/project/types';

import UnitTable, { columnVisibilityFromUnits } from './unitTable';
import { BedsGroupedUnits, groupUnitsByBeds } from './unitListTransformers';
import { titleForBeds } from './unitTitle';
import styles from './styles/unitBedsGroupList.cssm';
import ExpandUnitsButton from './expandUnitsButton';

export const MAX_MINIMIZED_UNITS_COUNT = 5;

const sliceBedGroupedUnits = (bedsWithUnitsList: BedsGroupedUnits[]) => {
    const slicedBedsWithUnits = [];
    let remainingUnitsCount = MAX_MINIMIZED_UNITS_COUNT;

    for (const bedsWithUnits of bedsWithUnitsList) {
        const slicedUnits = bedsWithUnits.units.slice(
            0,
            Math.min(bedsWithUnits.units.length, remainingUnitsCount),
        );
        slicedBedsWithUnits.push({ ...bedsWithUnits, units: slicedUnits });

        remainingUnitsCount -= slicedUnits.length;
        if (remainingUnitsCount <= 0) {
            break;
        }
    }

    return slicedBedsWithUnits;
};

type Props = {
    units: ProjectUnit[];
    isCommercial: boolean;
};

const UnitBedsGroupList = ({ units, isCommercial }: Props) => {
    const i18n = useI18n();

    const [isExpanded, setIsExpanded] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const bedsWithUnitsFullList = React.useMemo(() => groupUnitsByBeds(units), [units]);
    const totalUnitsCount = bedsWithUnitsFullList.reduce(
        (acc, value) => acc + value.units.length,
        0,
    );

    const showExpandButton = totalUnitsCount > MAX_MINIMIZED_UNITS_COUNT;
    const bedsWithUnitsDisplayedList =
        isExpanded || !showExpandButton
            ? bedsWithUnitsFullList
            : sliceBedGroupedUnits(bedsWithUnitsFullList);

    const columnVisibility = React.useMemo(() => columnVisibilityFromUnits(units), [units]);

    return (
        <Flex column className={styles.container} ref={containerRef}>
            {bedsWithUnitsDisplayedList.map((bedsWithUnits, index) => {
                const isFirst = index === 0;

                return (
                    <Flex key={bedsWithUnits.bedsCount} column>
                        {!isFirst && <div className={styles.divider}></div>}
                        <Flex>
                            {!isCommercial && (
                                <div
                                    className={classNames(styles.name, {
                                        [styles.nameWithHeader]: isFirst,
                                    })}
                                >
                                    {titleForBeds(i18n, bedsWithUnits, isCommercial)}
                                </div>
                            )}
                            <UnitTable
                                columnVisibility={columnVisibility}
                                hideHeader={!isFirst}
                                units={bedsWithUnits.units}
                            />
                        </Flex>
                    </Flex>
                );
            })}
            {showExpandButton && (
                <ExpandUnitsButton
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    containerElement={containerRef}
                />
            )}
        </Flex>
    );
};

export default UnitBedsGroupList;
