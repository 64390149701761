import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { Text } from 'strat/components';

import useLearnMoreDialogReasons from './useLearnMoreDialogReasons';
import styles from './styles/defaultRejectionContent.cssm';

const DefaultRejectionContent = () => {
    const reasons = useLearnMoreDialogReasons();
    const language = useSelector(selectLanguage);
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <Flex column>
            <Text.Base className={styles.line}>
                <Trans>Hello {brandName} user,</Trans>
            </Text.Base>
            <Text.Base className={styles.line}>
                <Trans>
                    We regret to inform you that your ad could have been rejected for one the
                    following reasons listed below:
                </Trans>
            </Text.Base>
            {reasons.map((reason, index) => (
                <Text.Base key={index} className={index === 0 ? styles.line : styles.listItem}>
                    - <Trans>{reason}</Trans>
                </Text.Base>
            ))}
            <Text.Base className={styles.line}>
                <Trans>
                    To know more about why your ad was rejected and post again, please go through
                    our rules under
                </Trans>{' '}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={settings.posting_guidelines[language as 'en' | 'ar']}
                >
                    <Trans>posting guidelines.</Trans>
                </a>
            </Text.Base>
            <Text.Base className={styles.line}>
                <Trans>Team {brandName}.</Trans>
            </Text.Base>
        </Flex>
    );
};

export default DefaultRejectionContent;
