import { ProjectSearchType } from 'strat/search/types';
import {
    getItem as getItemFromLocalStorage,
    setItem as setItemInLocalStorage,
} from 'strat/misc/localStorage';
import {
    getItem as getItemFromSessionStorage,
    setItem as setItemInSessionStorage,
} from 'strat/misc/sessionStorage';

import type { ItemReferral } from './itemReferral';
import ViewSections from './viewSections';

const DEFAULT_GTM_VARS = Object.freeze({
    flow_type: 'direct',
    listing_position: undefined,
});

const computeFlowType = ({
    viewSection,
    viewSort,
    viewLayout,
    projectExternalID,
}: ItemReferral): string | null | undefined => {
    if (projectExternalID) {
        return ProjectSearchType.PROJECT_PAGE;
    }

    if (viewSection === ViewSections.SEARCH_RESULTS) {
        if (!viewSort || !viewLayout) {
            return null;
        }

        return `search_${viewSort}_${viewLayout.toLowerCase()}`;
    }

    return viewSection;
};

const getPropertyReferralSessionStorageKey = (externalID: string) =>
    `property-${externalID}-referral`;

/**
 * Saves a property referral into the local storage.
 * At all times we try to keep at most 5 property referrals into the local storage.
 * If we have 5 or more saved property referrals, we remove the least recently added ones until we
 * have space to add the new one.
 */
const savePropertyReferralInLocalStorage = (
    externalID: string,
    propertyReferral: ItemReferral,
): void => {
    const oldPropertyReferrals = getItemFromLocalStorage('property-referrals') || [];
    const newPropertyReferrals = oldPropertyReferrals.filter(
        // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
        (element) => element.externalID !== externalID,
    );

    while (newPropertyReferrals.length >= 5) {
        newPropertyReferrals.shift();
    }

    newPropertyReferrals.push({ externalID, data: propertyReferral });
    setItemInLocalStorage('property-referrals', newPropertyReferrals);
};

/**
 * Moves a property referral from the local storage to the session storage, if it exists.
 * We need to run this when we land on the property page, so that the referral data will be
 * available in the session storage for the `getPropertyReferralGTMVars` function.
 */
const movePropertyReferralFromLocalToSessionStorage = (externalID: string) => {
    const localStoragePropertyReferrals = getItemFromLocalStorage('property-referrals') || [];
    const localStoragePropertyReferral = localStoragePropertyReferrals.find(
        // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
        (element) => element.externalID === externalID,
    )?.data;

    if (localStoragePropertyReferral) {
        setItemInSessionStorage(
            getPropertyReferralSessionStorageKey(externalID),
            localStoragePropertyReferral,
        );
        const newLocalStoragePropertyReferrals = localStoragePropertyReferrals.filter(
            // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
            (element) => element.externalID !== externalID,
        );
        setItemInLocalStorage('property-referrals', newLocalStoragePropertyReferrals);
    }
};

/**
 * Computes and returns the property referral GTM variables.
 * The data layer variables are computed using the 'propertyReferral' parameter, and if
 * this field is not provided, it uses the property referral data from the session storage.
 */
const getPropertyReferralGTMVars = (
    externalID: string,
    propertyReferral?: ItemReferral | null,
): any => {
    const storagePropertyReferral = getItemFromSessionStorage(
        getPropertyReferralSessionStorageKey(externalID),
    );
    const usedPropertyReferral = propertyReferral || storagePropertyReferral;

    if (!usedPropertyReferral) {
        return DEFAULT_GTM_VARS;
    }

    const { viewPosition, pageNumber } = usedPropertyReferral;
    const flowType = computeFlowType(usedPropertyReferral);

    if (flowType) {
        return {
            flow_type: flowType,
            listing_position: viewPosition,
            page_number: pageNumber,
        };
    }

    return DEFAULT_GTM_VARS;
};

export {
    savePropertyReferralInLocalStorage,
    movePropertyReferralFromLocalToSessionStorage,
    getPropertyReferralGTMVars,
};
