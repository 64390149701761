import { createSelector } from 'reselect';

const selectWalletBalance = (state: any) => state.walletBalance?.data?.balance;
const selectFirstAmountToExpire = (state: any) => state.walletBalance?.data?.firstAmountToExpire;

const selectWalletBalanceState = (state: any) => state.walletBalance;

const selectWalletBalanceLoading = createSelector(
    selectWalletBalanceState,
    (state) => state.loading,
);

export {
    selectWalletBalance,
    selectWalletBalanceState,
    selectWalletBalanceLoading,
    selectFirstAmountToExpire,
};
