import * as React from 'react';

import { ChatMessageType, ChatSystemMessageType } from 'horizontal/chat/constants';
import type { ChatMessage } from 'horizontal/chat/types';

import FraudulentMessage from './fraudulentMessage';
import SoldMessage from './soldMessage';

type Props = {
    readonly message: ChatMessage;
};

const SystemMessage = ({ message }: Props) => {
    const content = message.content[ChatMessageType.SYSTEM];
    if (!content) {
        return null;
    }

    switch (content.type) {
        case ChatSystemMessageType.SOLD:
            return <SoldMessage />;
        case ChatSystemMessageType.BANNED:
            return <FraudulentMessage isSuspicious />;
        case ChatSystemMessageType.UNBANNED:
            return <FraudulentMessage />;
        default:
            return null;
    }
};

export default SystemMessage;
