import { t } from '@lingui/macro';
import { connect } from 'react-redux';
import * as React from 'react';
import type { I18n } from '@lingui/core';
import BreadcrumbsSearch from '@app/navigation/breadcrumbsSearch';

import { withI18n } from 'strat/i18n/language/withI18n';
import { NumberFormat } from 'strat/i18n/language/numberFormat';
import IconTriangleLeft from '@app/assets/icons/iconTriangleLeft.svg';
import IconTriangleRight from '@app/assets/icons/iconTriangleRight.svg';
import IconDoubleLeft from '@app/assets/icons/iconDoubleArrowLeft.svg';
import IconDoubleRight from '@app/assets/icons/iconDoubleArrowRight.svg';
import { connectPaginationNavigation } from 'strat/generic';
import { isLanguageRTL } from 'strat/i18n/language';
import connectSummary from 'strat/searchComponents/connectSummary';

import type { Props as PaginationCellProps } from './paginationCell';
import styles from './styles/paginationRenderer.cssm';
import PaginationCell from './paginationCell';

type Props = {
    i18n: I18n;
    pageCount: number;
    activePage: number;
    maxVisible: number;
    canGoBack: boolean;
    canGoForward: boolean;
    previousPage: number;
    nextPage: number;
    computeVisiblePages: () => Array<number>;
    /**
     * Whether to always show a button to the first page.
     */
    withFirstPageButton?: boolean;
    /**
     * Whether to always show a button to the last page.
     */
    withLastPageButton?: boolean;
    /**
     * Whether to display search summary below pagination
     */
    withSummary?: boolean;
    summaryDescription?: () => string;
    renderPaginationCell: (props: PaginationCellProps) => React.ReactElement<any>;
    renderElement: (
        element: React.ReactElement<any>,
        page: number,
        isCurrentPage: boolean,
    ) => React.ReactElement;
    rtl: boolean;
    summary: string;
    /**
     * Whether or not to display the breadcrumbs below pagination
     */
    renderBreadcrumbs?: boolean;
    customFormatNumber?: (amount: number | null) => string;
};

/**
 * Renders page numbers with back/forward buttons.
 */
class PaginationRenderer extends React.Component<Props> {
    static defaultProps = {
        withFirstPageButton: true,
        withLastPageButton: false,
        renderPaginationCell: (props: PaginationCellProps) => <PaginationCell {...props} />,
    };

    getIconTriangle = (leftFirst: boolean) =>
        this.props.rtl !== leftFirst ? (
            <IconTriangleLeft className={styles.svgImage} />
        ) : (
            <IconTriangleRight className={styles.svgImage} />
        );

    getDoubleIconTriangle = (leftFirst: boolean) =>
        this.props.rtl !== leftFirst ? (
            <IconDoubleLeft className={styles.svgImage} />
        ) : (
            <IconDoubleRight className={styles.svgImage} />
        );

    renderArrow = (component: React.ReactElement<any>, title: string, page: number) =>
        this.props.renderElement(
            this.props.renderPaginationCell({
                title,
                content: component,
            }),
            page,
            false,
        );

    getPages() {
        const { i18n } = this.props;
        const visiblePages = this.props.computeVisiblePages();
        return visiblePages.map((page) => {
            const isCurrentPage = page === this.props.activePage;

            const title = isCurrentPage ? '' : t(i18n)`Page ${page}`;
            return (
                <li key={page}>
                    {this.props.renderElement(
                        this.props.renderPaginationCell({
                            title,
                            isCurrentPage,
                            content: <NumberFormat value={page} />,
                        }),
                        page,
                        isCurrentPage,
                    )}
                </li>
            );
        });
    }

    getNavigationLeft() {
        const { i18n } = this.props;
        const navigationLeft: Array<React.ReactElement<React.ComponentProps<'li'>> | any> = [];
        if (this.props.canGoBack) {
            if (this.props.withFirstPageButton) {
                navigationLeft.push(
                    <li key="first">
                        {this.renderArrow(this.getDoubleIconTriangle(true), t(i18n)`First Page`, 1)}
                    </li>,
                );
            }
            navigationLeft.push(
                <li key="prev">
                    {this.renderArrow(
                        this.getIconTriangle(true),
                        t(i18n)`Previous`,
                        this.props.previousPage,
                    )}
                </li>,
            );
        }
        return navigationLeft;
    }

    getNavigationRight() {
        const { i18n } = this.props;
        const navigationRight: Array<React.ReactElement<React.ComponentProps<'li'>> | any> = [];
        if (this.props.canGoForward) {
            navigationRight.push(
                <li key="next">
                    {this.renderArrow(
                        this.getIconTriangle(false),
                        t(i18n)`Next`,
                        this.props.nextPage,
                    )}
                </li>,
            );
            if (this.props.withLastPageButton) {
                navigationRight.push(
                    <li key="last">
                        {this.renderArrow(
                            this.getDoubleIconTriangle(false),
                            t(i18n)`Last Page`,
                            this.props.pageCount,
                        )}
                    </li>,
                );
            }
        }
        return navigationRight;
    }

    /**
     * Renders all available pages with back/forward buttons.
     */
    render() {
        return (
            <>
                {this.props.pageCount > 1 && (
                    <ul className={styles.pagination}>
                        {[
                            ...this.getNavigationLeft(),
                            ...this.getPages(),
                            ...this.getNavigationRight(),
                        ]}
                    </ul>
                )}
                {this.props.withSummary &&
                    CONFIG.runtime.STRAT_SHOW_TOTAL_AD_COUNT &&
                    this.props.pageCount > 0 && (
                        <span className={styles.summary} aria-label="Summary text">
                            {this.props.summary}
                        </span>
                    )}
                {this.props.renderBreadcrumbs && (
                    <div className={styles.searchBreadCrumbs}>
                        {/*@ts-ignore*/}
                        <BreadcrumbsSearch />
                    </div>
                )}
            </>
        );
    }
}

export default connect((state) => ({
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    rtl: isLanguageRTL(state.i18n.language),
    // @ts-expect-error - TS2345 - Argument of type 'ConnectedComponent<ComponentType<Omit<withI18nProps, "i18n">>, { context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } | { ...; }>' is not assignable to parameter of type 'ComponentType<withI18nProps>'. | TS2345 - Argument of type 'typeof PaginationRenderer' is not assignable to parameter of type 'Component<any, {}, any>'.
}))(withI18n()(connectSummary(connectPaginationNavigation(PaginationRenderer))));
