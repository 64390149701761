import { selectSearchSortOption } from '@sector-labs/fe-search-redux/state';
import { createSelector } from 'reselect';

import { type GlobalState } from 'strat/state';

import type { PageRule } from './pageRule';
import type { AdSearchConfiguration } from './adSearchConfiguration';

const selectPageParameters = (state: GlobalState) => state.search.pageParameters;

const selectAreaPageUnit = (state: GlobalState) => (selectPageParameters(state) || {}).areaPageUnit;

const selectPageRule = (state: GlobalState): PageRule | null | undefined =>
    // @ts-expect-error - TS2339: Property 'pageRule' does not exist on type 'SearchState'.
    state.search.pageRule.data;

// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'SearchRequestSortByAttributesOption | SearchRequestSortByRandomOption | SearchRequestSortByGeoDistanceOption | SearchRequestSortByCustomRules'.
const selectSortValue: (state: GlobalState) => any = createSelector(
    selectSearchSortOption,
    (sortOption: any) => sortOption?.value,
);

const selectAdSearchConfiguration = (
    state: GlobalState,
): AdSearchConfiguration | null | undefined => state.adSearchConfiguration?.data;

export {
    selectAreaPageUnit,
    selectPageParameters,
    selectPageRule,
    selectSortValue,
    selectAdSearchConfiguration,
};
