import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import type { Location } from 'horizontal/types';

import LocationList from './locationList';
import CurrentLocationSelector from './currentLocationSelector';
import LocationEntry from './locationEntry';
import RegionLocationsList from './compact/regionLocationsList';
import SubLocationList from './subLocationList';
import styles from './styles/defaultLocationList.cssm';

type Props = {
    maxLevel?: number;
    onLocationClicked: (arg1: Location) => void;
    recentLocations: Location[];
    selectedIndex?: number;
    onGeoPositionSelected: () => void;
    showCurrentLocationSelector?: boolean;
};

const DefaultLocationList = ({
    maxLevel,
    recentLocations,
    onLocationClicked,
    selectedIndex,
    onGeoPositionSelected,
    showCurrentLocationSelector = true,
}: Props) => {
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobileLayout);

    const topLocation = React.useMemo(() => settings.getTopLevelLocation(i18n), [i18n]);

    return (
        <>
            {showCurrentLocationSelector && (
                <div className={classNames(styles.section, styles.entry, styles.withExtraPadding)}>
                    <CurrentLocationSelector onGeoPositionSelected={onGeoPositionSelected} />
                </div>
            )}
            <LocationList
                title={t(i18n)`Recent location`}
                locations={recentLocations}
                onLocationClicked={onLocationClicked}
                selectedIndex={selectedIndex}
            />
            <div
                className={classNames(styles.section, styles.entry)}
                // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData' is not assignable to parameter of type 'Location'.
                onClick={() => onLocationClicked(topLocation)}
            >
                <LocationEntry
                    // @ts-expect-error - TS2322 - Type 'LocationNodeData' is not assignable to type 'Location'.
                    location={topLocation}
                    label={t(i18n)`See ads in all ${topLocation.name}`}
                />
            </div>
            {isMobile ? (
                <RegionLocationsList
                    selectedIndex={selectedIndex}
                    onLocationClicked={onLocationClicked}
                />
            ) : (
                <SubLocationList
                    maxLevel={maxLevel}
                    selectedIndex={selectedIndex}
                    onLocationClicked={onLocationClicked}
                />
            )}
        </>
    );
};

export default DefaultLocationList;
