import * as React from 'react';

import { GTMAdDetailDataLayer } from 'horizontal/gtm/components';

import AdDetailsHead from './adDetailsHead';
import AdDetailsBodyDesktop from './adDetailsBodyDesktop';

const AdDetailsPageDesktop = {
    head: () => (
        <>
            <AdDetailsHead />
            <GTMAdDetailDataLayer />
        </>
    ),
    body: () => <AdDetailsBodyDesktop />,
} as const;

export default AdDetailsPageDesktop;
