//@ts-nocheck
/**
 * Convenience method for parsing a number from the URL into
 * an actual number. Returns 0 if the value is negative.
 */
const number = (input: null | string, defaultValue: any = null): null | number => {
    const value = Number(input, 10);
    return !isNaN(value) ? Math.max(0, value) : defaultValue;
};

/**
 * Convenience method for parsing a float number from the URL into
 * an actual float number.
 */
const floatNumber = (input: null | string, defaultValue: any = null): null | number => {
    const value = parseFloat(input, 10);
    return !isNaN(value) ? value : defaultValue;
};

/**
 * Parses a list of strings (comma separated by default) from the URL.
 */
const stringList = (
    input: null | string,
    defaultValue: any = [],
    separateSymbol = ',',
): Array<string> => (input ? input.split(separateSymbol).filter((item) => !!item) : defaultValue);

/**
 * Parses a list of numbers that could include a 'N+' value.
 */
const oneToKOrMoreList = (input: null | string, defaultValue: any = []): Array<number | string> =>
    stringList(input, defaultValue)
        .map((item) => {
            // if it's a K-or-more value, make sure that it's actually a number
            // but don't put it in the list as a number
            if (item.includes('+')) {
                if (number(item.replace('+', ''), null) === null) {
                    return null;
                }

                return item;
            }

            return number(item, null);
        })
        .filter((item) => item !== null);

/**
 * Convenience method for parsing a list of numbers (comma separated)
 * from the URL.
 * @returns An array of numbers that were passed. Invalid numbers are left out.
 */
const numberList = (input: null | string, defaultValue: any = []): Array<number> =>
    stringList(input, defaultValue)
        .map((item) => number(item, null))
        .filter((item) => item !== null);

export default {
    number,
    floatNumber,
    stringList,
    oneToKOrMoreList,
    numberList,
};
