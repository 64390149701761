import {
    ExactFilter,
    FilterCollection,
    ObjectRefinementFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SearchJob } from '@sector-labs/fe-search-redux';
import { setFilters, getFilterCollection } from '@sector-labs/fe-search-redux/state';
import { SearchRequestFacetType } from '@sector-labs/fe-search-redux/backend';
import Operator from '@sector-labs/fe-search-redux/filters/operator';
import { FilterValues } from 'strat/search';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import { determineAdsIndexName } from 'strat/search/indexNames';
import settings from '@app/branding/settings';
import { StratAPI } from 'strat/api';

import { fetchCategories } from 'horizontal/categories/state';
import { clearSellerProfile } from 'horizontal/seller/state';
import Page from 'horizontal/pages/page';
import { AgencyType, TransactionPurpose } from 'horizontal/types';
import {
    startFetchingAgency,
    updateAgencyStats,
    selectAgencyProfile,
} from 'horizontal/agency/state';
import {
    decodeExtraFields,
    encodeExtraFields,
    paramsToFilters,
} from 'horizontal/agency/routing/extraFieldsConverters';
import { selectCategoryByExternalID } from 'horizontal/categories/selectors';
import { AgencyExtraFields } from 'horizontal/agency/routing';
import { selectDefaultSortOption } from 'horizontal/search/sorting';
import { calculatePurposesCount } from 'horizontal/profile';

import ProfileRoute from './profileRoute';
import { getPageForQueryParams } from './pagination';

const HITS_PER_PAGE = 12;

export type AgencyProfileRouteParams = {
    readonly agencySlug: string;
    readonly page?: number;
    readonly locationSlug?: string;
    readonly purpose?: Values<typeof TransactionPurpose>;
    readonly categorySlug?: string | null;
    readonly extraFields?: AgencyExtraFields;
};

class AgencyProfileRoute extends ProfileRoute {
    type = '';

    constructor() {
        super(
            RouteNames.AGENCY_PROFILE,
            [
                [
                    '^/companies/',
                    '(?:[A-z\u0621-\u064A0-9-]+-)?',
                    {
                        name: 'agencyExternalID',
                        pattern: '([0-9]+)',
                    },
                    '/',
                    { name: 'locationSlug', pattern: '((?!q-)[A-z0-9-_&]*)' },
                    '/?(?:(?:\\?|#).*)?$',
                ],
                [
                    '^/companies/',
                    '(?:[A-z\u0621-\u064A0-9-]+-)?',
                    {
                        name: 'agencyExternalID',
                        pattern: '([0-9]+)',
                    },
                    '(?:\\?.*)?$',
                ],
            ],
            'agency.externalID',
            'companies',
            Page.AGENCY_PROFILE,
        );
        this.type = 'companies';
    }

    // @ts-expect-error - TS2416 - Property 'createURL' in type 'AgencyProfileRoute' is not assignable to the same property in base type 'ProfileRoute'.
    createURL(
        params: AgencyProfileRouteParams,
        _context: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const { agencySlug, page, locationSlug, purpose, categorySlug, extraFields } = params;
        const search: Record<string, any> = {};
        if (purpose && purpose !== TransactionPurpose.ALL) {
            search.purpose = purpose;
        }
        if (page && page !== 1) {
            search.page = getPageForQueryParams(page);
        }

        if (
            categorySlug &&
            categorySlug !== settings.agencyProfileDefaultCategoryFilter[TransactionPurpose.ALL][0]
        ) {
            search.category = categorySlug;
        }

        const filter = encodeExtraFields(extraFields);
        if (filter) {
            search.filter = filter;
        }

        if (locationSlug) {
            return { pathname: `/${this.type}/${agencySlug}/${locationSlug}`, search };
        }
        return { pathname: `/${this.type}/${agencySlug}`, search };
    }

    handleNoExternalID(context: RoutingContextWithMiddlewares) {
        context.http.status(500);
        context.rendering.renderPage(Page.INTERNAL_ERROR);
    }

    getProfileExternalID(context: RoutingContextWithMiddlewares) {
        const {
            match: {
                params: { agencyExternalID },
            },
        } = context;
        return agencyExternalID;
    }

    createSearchJob(context: RoutingContextWithMiddlewares, externalID: string) {
        const {
            redux: {
                store: { getState },
            },
            i18n: { locale: language },
        } = context;

        const state = getState();

        const filterCollection = new FilterCollection();
        filterCollection.refine(
            new ExactFilter({
                attribute: this.searchAttribute,
                value: externalID,
            }),
        );

        const indexName = determineAdsIndexName({ language });

        return new SearchJob(indexName, filterCollection, {
            hitsPerPage: HITS_PER_PAGE,
            sort: selectDefaultSortOption(state),
            facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING
                ? [
                      {
                          type: SearchRequestFacetType.SIMPLE,
                          attribute: `category.lvl${1}.externalID`,
                          filters: [
                              new ExactFilter({
                                  attribute: `category.lvl${0}.externalID`,
                                  value: settings.verticalProperties.externalID,
                              }).serialize(),
                              new ExactFilter({
                                  attribute: FilterValues.location.attribute,
                                  value: settings.topLevelLocation.externalID,
                              }).serialize(),
                              new ExactFilter({
                                  attribute: this.searchAttribute,
                                  value: externalID,
                              }).serialize(),
                          ],
                          // Ignore all other filters, except the location filter and freeTextQuery.
                          includedFilterAttributes: [
                              {
                                  attribute: 'query.value',
                                  exact: true,
                              },
                          ],
                      },
                  ]
                : [],
        });
    }

    fetchProfile(context: RoutingContextWithMiddlewares): Promise<any> {
        const {
            redux: {
                store: { dispatch },
            },
            match: {
                params: { agencyExternalID },
            },
            i18n: { locale: language },
        } = context;
        const searchJob = this.createSearchJob(context, agencyExternalID);
        dispatch(clearSellerProfile());
        return Promise.all([
            new StratAPI(language).agency(agencyExternalID),
            searchJob ? this.search(context, searchJob) : Promise.resolve(),
            dispatch(fetchCategories()),
        ]);
    }

    refineExtraSearchFields(
        context: RoutingContextWithMiddlewares,
        filterCollection: FilterCollection,
    ) {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { category, filter, purpose },
            },
        } = context;

        const state = getState();
        const agency = selectAgencyProfile(state);
        if (agency?.type !== AgencyType.REAL_ESTATE) {
            return;
        }

        const extraFields = decodeExtraFields(filter);
        let categoriesRefinementValue;
        if (category) {
            categoriesRefinementValue = [category];
        } else {
            categoriesRefinementValue = purpose
                ? settings.agencyProfileDefaultCategoryFilter[purpose]
                : [];
        }

        if (categoriesRefinementValue.length > 0) {
            filterCollection.refine(
                new ObjectRefinementFilter({
                    attribute: FilterValues.category.attribute,
                    value: [...categoriesRefinementValue],
                    operator: Operator.AND,
                }),
            );
        }

        if (Object.keys(extraFields).length > 0) {
            const convertedFilters = paramsToFilters(extraFields);
            filterCollection.refineMultiple(convertedFilters);
        }
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            redux: {
                store: { getState, dispatch },
            },
            match: {
                params: { agencyExternalID },
            },
        } = context;

        const state = getState();

        // Shows loading animations for agency information (name, description, etc.) only if the profile was not fetched previously
        const agency = selectAgencyProfile(state);
        if (!agency?.externalID || agency.externalID != agencyExternalID) {
            dispatch(startFetchingAgency());
            context.rendering.renderPage(this.profilePage, { loading: true });
        } else {
            context.rendering.renderPage(this.profilePage, { loading: false });
        }

        const promise = this.fetchProfile(context).then(
            ([agencyProfileResponse, elasticSearchResponse]) => {
                const { data: agency, status } = agencyProfileResponse;
                const [, forSaleCount, forRentCount] = calculatePurposesCount(
                    elasticSearchResponse.facets['category.lvl1.externalID'],
                    selectCategoryByExternalID(getState(), settings.verticalProperties.externalID),
                );

                if (status !== 200) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.NOT_FOUND);
                    return;
                }

                const updatedAgency = {
                    ...agency,
                    stats: {
                        ...(agency.stats || {}),
                        adsCount: elasticSearchResponse.nbHits,
                        realEstateForSaleAdsCount: forSaleCount,
                        realEstateForRentAdsCount: forRentCount,
                    },
                };
                dispatch(updateAgencyStats(updatedAgency));
                return this.fetchAllProfileData(context);
            },
        );

        context.promise.wait(promise);
    }

    onLeave(
        oldContext: RoutingContextWithMiddlewares,
        newContext: RoutingContextWithMiddlewares,
    ): void {
        const {
            redux: {
                store: { getState, dispatch },
            },
        } = oldContext;

        const state = getState();
        const newRouteName = newContext.match.route.name;

        const filterCollection = getFilterCollection(state);
        const category = filterCollection.getFilterValue(FilterValues.category.attribute);

        if (newRouteName === RouteNames.AD_DETAILS && category) {
            filterCollection.remove(FilterValues.category.attribute);
            dispatch(setFilters(filterCollection));
        }

        super.onLeave();
    }
}

export default new AgencyProfileRoute();
