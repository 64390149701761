import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import type { I18n } from '@lingui/core';
import { selectLanguage } from 'strat/i18n/language';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { selectRouteName } from 'strat/routes/selectors';

import iconPaymentFailed from '@app/assets/icons/iconPaymentFailed_noinline.svg';
import { trackCustomerSupport } from 'horizontal/gtm';

import styles from './styles/walletPayment.cssm';

type Props = {
    error: boolean;
    errorStatus: string | null;
};

const WalletErrorStatus = Object.freeze({
    EMPTY_WALLET: 'No wallet balance available.',
    INCOMPLETE_PARTIAL_PAYMENT_ORDER:
        'Active wallet partial payment is already exists for this order',
});

const errorTitle = (i18n: I18n, status?: string | null) => {
    switch (status) {
        case WalletErrorStatus.EMPTY_WALLET:
            return t(i18n)`Insufficient credit.`;
        case WalletErrorStatus.INCOMPLETE_PARTIAL_PAYMENT_ORDER:
            return t(i18n)`Incomplete partial payment order.`;
        default:
            return t(i18n)`Oops, we couldn't proceed with your payment.`;
    }
};

const errorSubtitle = (i18n: I18n, status?: string | null) => {
    switch (status) {
        case WalletErrorStatus.EMPTY_WALLET:
            return t(i18n)`Fill your wallet balance and try again.`;
        case WalletErrorStatus.INCOMPLETE_PARTIAL_PAYMENT_ORDER:
            return t(
                i18n,
            )`Please complete the transaction in external page or wait until your balance is refunded`;
        default:
            return t(
                i18n,
            )`Please try a different payment method. If the problem occurs again, contact us.`;
    }
};

const ContactCustomerSupport = () => {
    const language = useSelector(selectLanguage);
    const route = useSelector(selectRouteName);

    const onClickCustomerSupport = () => {
        trackCustomerSupport(route);
    };

    return (
        <Text.Base className={styles.description}>
            <Trans>Need help? Please contact</Trans>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href={settings.support_request[language as 'en' | 'ar']}
                className={styles.support}
                onClick={onClickCustomerSupport}
            >
                <Trans>customer support</Trans>
            </a>
        </Text.Base>
    );
};

const ErrorMessage = ({ error, errorStatus }: Props) => {
    const i18n = useI18n();

    if (!error) {
        return null;
    }

    return (
        <Flex className={styles.walletError}>
            <Flex alignCenter>
                <img alt={'My Wallet'} src={iconPaymentFailed} />
            </Flex>
            <Flex column>
                <p className={styles.title}>{errorTitle(i18n, errorStatus)}</p>
                <p>{errorSubtitle(i18n, errorStatus)}</p>
                <ContactCustomerSupport />
            </Flex>
        </Flex>
    );
};

export default ErrorMessage;
