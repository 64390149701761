import { t } from '@lingui/macro';
import * as React from 'react';
import { LoadingAnimation } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';

import type { ChatAd } from 'horizontal/chat/types';
import { useAdPhotos } from 'horizontal/ad';
import DefaultThumbnailImage from 'horizontal/adDetails/gallery/defaultThumbnailImage';

import styles from './styles/adImage.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly isLoading: boolean;
};

const AdImage = ({ ad, isLoading }: Props) => {
    const i18n = useI18n();
    const [coverPhoto] = useAdPhotos(ad);

    if (isLoading) {
        return <LoadingAnimation className={styles.adImage} />;
    }

    if (coverPhoto) {
        return (
            <ThumbnailImage
                className={styles.adImage}
                imageID={coverPhoto.id}
                size={ThumbnailSizes.TINY}
                alt={t(i18n)`Ad cover`}
                title={coverPhoto.title}
                label="Cover photo"
                lazyLoad
            />
        );
    }

    return <DefaultThumbnailImage category={ad?.category} className={styles.adImage} />;
};

export default AdImage;
