import React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

type Props = {
    className: string;
};

export default function SellNowText({ className }: Props) {
    return (
        <Text.Base className={className}>
            <Trans>
                Make some extra cash by selling things in your community. Go on, it&apos;s quick and
                easy.
            </Trans>
        </Text.Base>
    );
}
