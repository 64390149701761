import * as React from 'react';

import * as Text from './text';
import styles from './styles/section.cssm';

type Props = {
    readonly title?: string;
    readonly renderTitle?: () => React.ReactElement;
    readonly children: React.ReactNode;
};

const Section = ({ title, children, renderTitle }: Props) => (
    <div className={styles.section}>
        {(renderTitle || title) && (
            <div className={styles.title}>
                {renderTitle && renderTitle()}
                {!renderTitle && title && <Text.Large bold>{title}</Text.Large>}
            </div>
        )}
        {children}
    </div>
);

export default Section;
