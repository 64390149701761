import type { Choice, ChoiceValue } from 'strat/components/types';

export interface FilterableDropdownProps<T extends ChoiceValue> {
    readonly title?: string;
    readonly description?: string;
    readonly name: string;
    readonly errorMessage?: string | null;
    readonly accepted?: boolean;
    readonly onChange: (arg1: T) => void;
    readonly value?: T;
    readonly choices: Array<Choice<T>>;
    readonly placeholder?: string;
    readonly stretch?: boolean;
    readonly renderSuggestions?: (
        onClick: (value: T) => void,
    ) => React.ReactElement | null | undefined;
    readonly suggestedChoices?: Array<Choice<T>>;
    readonly readOnly?: boolean;
    readonly showIcon?: boolean;
    readonly dropdownTriggerCustomClass?: string;
    readonly choicesContainerCustomClass?: string;
    readonly titleCustomClass?: string;
    readonly noOverflow?: boolean;
    readonly dropdownTriggerIcon?: (
        isOpen: boolean,
        setIsOpen: (isOpen: boolean) => void,
    ) => React.ReactElement;
    readonly renderIcon?: (value: Choice<T>) => React.ReactElement | null | undefined;
    readonly onBlur?: (e: React.SyntheticEvent) => void;
    readonly variant?: DropdownVariants;
    readonly lightPlaceholder?: boolean;
    readonly boldLabel?: boolean;
    readonly isMandatory?: boolean | null;
    readonly isAutofilled?: boolean;
}

export enum DropdownVariants {
    REGULAR = 'regular',
    TEXT_INPUT = 'text_input',
}
