import languageEN from '@app/assets/icons/iconLanguageEN_noinline.svg';
import languageAR from '@app/assets/icons/iconLanguageAR_noinline.svg';
import type { LanguageSwitcher } from 'strat/branding/types';

const languageSwitcher: LanguageSwitcher = {
    en: {
        image: languageEN,
        style: {
            minWidth: '4.5rem',
            maxWidth: '5.7rem',
            display: 'inline-block',
        },
    },
    ar: {
        image: languageAR,
        style: {
            minWidth: '4.1rem',
            maxWidth: '5.7rem',
            display: 'inline-block',
            position: 'relative',
            top: '0.1rem',
        },
    },
};

export { languageSwitcher };
