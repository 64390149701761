import { createSelector } from 'reselect';
import amenityIcons from '@app/branding/amenityIcons';

import topAmenities from '@app/branding/topAmenities';
import type { PropertyAmenityData, PropertyAmenityGroupData } from 'strat/property/types';

const computeDisplayAmenities = (
    amenities: Array<PropertyAmenityGroupData>,
    minNumberOfAmenities?: number,
): Array<PropertyAmenityData> => {
    const allAmenities = amenities.reduce<Array<PropertyAmenityData>>(
        (acc, amenityGroup) => [...acc, ...amenityGroup.amenities],
        [],
    );
    const priorityAmenities = allAmenities
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ 'completion-year': string; view: string; 'balcony-or-terrace': string; 'barbeque-area': string; 'lobby-in-building': string; 'double-glazed-windows': string; 'centrally-air-conditioned': string; ... 68 more ...; 'central-air-con-and-heating': string; }'.
        .filter((amenity) => topAmenities.has(amenityIcons[amenity.slug]))
        .sort((a, b) => a.rank - b.rank);

    // Compute a set with the amenities that are already displayed.
    const slugsSet = new Set();
    priorityAmenities.forEach((amenity) => slugsSet.add(amenity.slug));

    // Fill in empty space with amenities.
    const sortedAmenities = [
        ...priorityAmenities,
        ...allAmenities.filter((amenity) => !slugsSet.has(amenity.slug)),
    ];

    return minNumberOfAmenities ? sortedAmenities.slice(0, minNumberOfAmenities) : sortedAmenities;
};

const selectDisplayedAmenities = createSelector(
    (amenities: Array<PropertyAmenityGroupData>, minNumberOfAmenities?: number) =>
        computeDisplayAmenities(amenities, minNumberOfAmenities),
    (amenities: Array<PropertyAmenityData>) => amenities,
);

// Compute the list of amenities that will be
// displayed no matter what.
export default selectDisplayedAmenities;
