import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { trackTrendingSearches } from 'horizontal/gtm';

import type { FooterLink } from './types';

const useTrendingSearchesLinks = (): ReadonlyArray<FooterLink> => {
    const i18n = useI18n();

    return React.useMemo(
        () => [
            {
                name: t(i18n)`Bikes`,
                url: '/motorcycles_c81',
                event: () => {
                    trackTrendingSearches('Bikes');
                },
            },
            {
                name: t(i18n)`Watches`,
                url: '/watches_c1970',
                event: () => {
                    trackTrendingSearches('Watches');
                },
            },
            {
                name: t(i18n)`Books`,
                url: '/items/q-books',
                event: () => {
                    trackTrendingSearches('Books');
                },
            },
            {
                name: t(i18n)`Dogs`,
                url: '/items/q-dogs',
                event: () => {
                    trackTrendingSearches('Dogs');
                },
            },
        ],
        [i18n],
    );
};

export default useTrendingSearchesLinks;
