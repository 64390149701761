import settings from '@app/branding/settings';
import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import { isCanceled, makeCancelable } from 'strat/util';

import { getStratAPI } from 'horizontal/api';
import { fetchCategories } from 'horizontal/categories/state';
import Page from 'horizontal/pages/page';
import { PackageRoles, ProductType } from 'horizontal/types';

import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';
import ensureHasActiveUser from './ensureHasActiveUser';
import ensureProAgentHasAccess from './ensureProAgentHasAccess';

export type SelectBusinessPackagesRouteParams = {
    locationExternalID: string;
    categoryExternalID: string;
    adExternalID?: string;
    productTypes?: Values<typeof ProductType>[];
};

class SelectBusinessPackagesRoute extends Route {
    constructor() {
        super(RouteNames.SELECT_MULTIPLE_PACKAGES, [
            ['^/payments/choosePackageMulti/ALL', '(?:\\?)?'],
        ]);
    }

    createURL({
        locationExternalID,
        categoryExternalID,
        adExternalID,
        productTypes,
    }: SelectBusinessPackagesRouteParams): EnhancedLocation {
        return {
            pathname: '/payments/choosePackageMulti/ALL',
            search: {
                location: locationExternalID,
                category: categoryExternalID,
                adExternalID: adExternalID,
                products: (productTypes || []).join('_and_'),
            },
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (
            !ensureHasActiveUser(context) ||
            !ensureProAgentHasAccess(context) ||
            !ensureActiveUserIsAllowedAccessAndRedirect(context)
        ) {
            return;
        }

        const {
            match: {
                params: { category, location, products },
            },
            redux: {
                store: { getState, dispatch },
            },
        } = context;

        const productsTypes =
            products?.length > 0 ? (products.split('_and_') as Values<typeof ProductType>[]) : null;

        const productParams = {
            roles: PackageRoles.MULTIPLE,
            types: productsTypes || [
                ProductType.FEATURED,
                ProductType.AUTO_REFRESH_AD,
                ProductType.LIMIT_BUMP,
                ProductType.REFRESH,
                ProductType.AUTO_REFRESH_CATEGORY,
                ProductType.ELITE,
            ],
            priceOrderDesc: true,
            show_all_bundles: true,
        };

        // Render the page so we can display the loading indicator
        context.rendering.renderPage(Page.SELECT_MULTIPLE_PACKAGES);

        const state = getState();
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'SelectBusinessPackagesRoute'.
        this.cancelablePromise = makeCancelable(
            Promise.all([
                getStratAPI(state).getProductPackageOffers(category, location, productParams),
                dispatch(fetchCategories()),
            ]),
        );
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'SelectBusinessPackagesRoute'.
        const promise = this.cancelablePromise.then(
            ([{ data: productOffers, status }]: [any]) => {
                if (status !== 200) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.NOT_FOUND);
                    return;
                }
                if (
                    productOffers &&
                    !productOffers.length &&
                    !settings.enableCustomizedPackagesOptions
                ) {
                    context.rendering.renderPage(Page.PACKAGES_NOT_FOUND, {
                        categoryID: category,
                    });
                    return;
                }
                context.rendering.renderPage(Page.SELECT_MULTIPLE_PACKAGES, {
                    productOffers,
                    categoryID: category,
                    locationID: location,
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );

        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'SelectBusinessPackagesRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'SelectBusinessPackagesRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new SelectBusinessPackagesRoute();
