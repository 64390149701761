import * as React from 'react';
import type { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';

import type { AppDispatch, GlobalState } from 'strat/state';

import { isKeycloakEnabled } from './keycloak/config';
import { ensureUserWithEmail as ensureUserWithEmailExpress } from './express/thunks';
import { ensureUserWithEmail as ensureUserWithEmailKeycloak } from './keycloak/thunks';
import type { EnsuredUserData, EnsureUserWithEmailOptions } from './types';

export const ensureUserWithEmail = (
    email: string,
    options: EnsureUserWithEmailOptions,
): ThunkAction<Promise<EnsuredUserData>, GlobalState, unknown, AnyAction> =>
    isKeycloakEnabled()
        ? ensureUserWithEmailKeycloak(email, options)
        : ensureUserWithEmailExpress(email, options);

type UseEnsureUserWithEmail = (
    email: string,
    options?: EnsureUserWithEmailOptions,
) => Promise<EnsuredUserData>;

export const useEnsureUserWithEmail = (): UseEnsureUserWithEmail => {
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback(
        (email: string, options: EnsureUserWithEmailOptions = {}) =>
            dispatch(ensureUserWithEmail(email, options)),
        [dispatch],
    );
};
