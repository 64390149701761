import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import IconCall from '@app/assets/icons/iconCall.svg';
import ContactDetailsDialog from '@app/contact/contactDetailsDialog';

import type { PropertyPhoneNumberData } from 'strat/property/types';
import type { CancelablePromise } from 'strat/util';
import { PhoneNumberPriority } from 'strat/contact/mergePhoneNumbers';
import getPrimaryPhoneNumber from 'strat/contact/getPrimaryPhoneNumber';
import { useLoginRestrictedCallback } from 'strat/misc';
import { Triggers } from 'strat/gtm';
import { ButtonVariant } from 'strat/components';

import Button from './button';
import styles from './styles/callButton.cssm';

/**
 * Properties for {@see ListingCallButtonCompact}.
 */
type Props = {
    phoneNumberData: PropertyPhoneNumberData | null;
    agencyName?: string | null | undefined;
    propertyID?: any;
    externalID: string | null | undefined;
    contactName: string | null | undefined;
    referenceNumber?: string | null | undefined;
    className?: string;
    withIcon?: boolean;
    /**
     * Method to call when the button is clicked.
     */
    onClick?: (event?: React.SyntheticEvent<any>) => void;
    /**
     * Method to call when the visibility of the dialog changed
     */
    onVisibilityChanged?: (visibility: boolean) => void;
    /**
     * Method to call when the phone number is clicked.
     */
    onPhoneNumberClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Method to call when the call button is clicked to fetch phone numbers from strat api.
     */
    phoneFetcher?: (props: { externalID: string }) => CancelablePromise;
    phoneNumberPriority?: Values<typeof PhoneNumberPriority>;
    showAllNumbers?: boolean;
    /**
     * Initial state of the dialog
     */
    dialogVisible?: boolean;
    /**
     * Whether the button should be callable or not
     */
    isCallable?: boolean;
    /**
     * Whether or not the user is loggedIn.
     */
    loggedIn?: boolean;
    /**
     * Whether log in is necessary to display phone numbers
     */
    requiresLoginForContact?: boolean;
    /**
     * Whether the button is actionable
     */
    disabled?: boolean;
    /**
     * Render icon
     */
    renderIcon?: () => React.ReactElement;
    /**
     * If true, the button will include "Call" text
     */
    withText?: boolean;
    /**
     * Flag indicating whether we should show proxy phone
     * number if possible.
     */
    showProxy?: boolean;
    /**
     * Text to be shown in the button.
     */
    renderText?: () => React.ReactElement;
    /**
     * Toggles the old button design, which is still needed for Zameen
     */
    sharp?: boolean;
    variant?: Values<typeof ButtonVariant>;
    name?: string;
};

/**
 * Renders a call button in a listing.
 *
 * When clicked, shows a dialog displaying the available
 * phone numbers and the reference number.
 */
const CallButton = (props: Props) => {
    const [dialogVisible, setDialogVisible] = React.useState(props.dialogVisible || false);
    const propsDialogVisible = props.dialogVisible;
    const {
        onClick,
        onVisibilityChanged,
        isCallable,
        onPhoneNumberClick,
        className,
        phoneNumberData,
        externalID,
        referenceNumber,
        agencyName,
        contactName,
        phoneFetcher,
        phoneNumberPriority,
        showAllNumbers,
        showProxy,
        variant,
        sharp,
        withText = true,
        renderText = () => <Trans>Call</Trans>,
        renderIcon = () => <IconCall className={styles.icon} />,
        name,
    } = props;

    React.useEffect(() => {
        if (propsDialogVisible && onClick) {
            onClick();
        }
    }, [propsDialogVisible, onClick]);

    const changeVisibility = React.useCallback(
        (visibility) => {
            onVisibilityChanged?.(visibility);
            setDialogVisible(visibility);
        },
        [onVisibilityChanged],
    );

    const phoneNumber = getPrimaryPhoneNumber(phoneNumberData);
    const onClickButton = React.useCallback(
        (event: React.SyntheticEvent<any>): void => {
            if (isCallable && phoneNumber) {
                onPhoneNumberClick?.(event);
                location.href = `tel:${phoneNumber}`;
            }

            setDialogVisible(true);

            onClick?.(event);
        },
        [isCallable, phoneNumber, onPhoneNumberClick, onClick],
    );

    const onClickLogInRestricted = useLoginRestrictedCallback({
        onClick: onClickButton,
        userPath: Triggers.PHONE_LEAD,
    });

    if (!phoneNumber) {
        return null;
    }

    return (
        <>
            <Button
                disabled={props.disabled}
                key="button"
                type={props.isCallable ? 'submit' : 'button'}
                className={classNames(styles.button, className, {
                    [styles.updatedButton]: !sharp,
                    [styles.disabled]: props.disabled,
                    [styles.light]: !props.disabled && variant === ButtonVariant.LIGHT,
                })}
                sharp={sharp}
                aria-label="Call"
                // @ts-expect-error - TS2322 - Type '(() => ReactElement<any, string | JSXElementConstructor<any>>) | (() => null)' is not assignable to type '(() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined'.
                renderIcon={props.withIcon ? renderIcon : () => null}
                onClick={props.requiresLoginForContact ? onClickLogInRestricted : onClickButton}
            >
                {withText && renderText()}
            </Button>
            <ContactDetailsDialog
                key="dialog"
                visible={dialogVisible}
                onVisibilityChanged={changeVisibility}
                phoneNumberData={phoneNumberData}
                // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'number'.
                externalID={externalID}
                referenceNumber={referenceNumber}
                agencyName={agencyName}
                contactName={contactName}
                onPhoneNumberClick={onPhoneNumberClick}
                // @ts-expect-error - TS2322 - Type '((props: { externalID: string; }) => any) | undefined' is not assignable to type '(language: string, externalID: number) => any'.
                phoneFetcher={phoneFetcher}
                // @ts-ignore
                phoneNumberPriority={phoneNumberPriority}
                showAllNumbers={showAllNumbers}
                showProxy={showProxy}
                name={name}
            />
        </>
    );
};

export default CallButton;
