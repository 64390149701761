import * as React from 'react';
import { ThumbnailImageWithFallback, ThumbnailSizes } from 'strat/image';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';

import defaultAgencyLogo from 'horizontal/assets/icons/defaultAgencyLogo_noinline.svg';
import type { Agency } from 'horizontal/types';

import styles from './styles/featuredAgencyCard.cssm';

type Props = {
    readonly agency: Agency;
};
const FeaturedAgencyCard = ({ agency: { logo, name, slug } }: Props) => {
    return (
        <Link route={RouteNames.AGENCY_PROFILE} params={{ agencySlug: slug }}>
            <ThumbnailImageWithFallback
                className={styles.featuredAgencyImage}
                imageID={logo?.id}
                fallbackUrl={defaultAgencyLogo}
                size={ThumbnailSizes.TINY}
                alt={'logo of featured Business/Agency'}
                title={name}
                label="Agency photo"
                lazyLoad
            />
            <div aria-label="Title" className={styles.agencyName}>
                {name}
            </div>
        </Link>
    );
};

export default FeaturedAgencyCard;
