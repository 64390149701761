import React from 'react';
import { t, plural } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

const useLocalizedDays = () => {
    const i18n = useI18n();

    return React.useCallback(
        (count?: number | null) => {
            const daysCount = count || 0;
            return t(i18n)`${plural(daysCount || 0, {
                zero: ``,
                one: `${daysCount} day`,
                two: `${daysCount} days`,
                few: `${daysCount} days`,
                many: `${daysCount} days`,
                other: `${daysCount} days`,
            })}`;
        },
        [i18n],
    );
};

export default useLocalizedDays;
