import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { selectRouteParams } from 'strat/routes/selectors';
import { Flex, Text } from 'strat/components';

import SuccessPaymentIcon from 'horizontal/assets/icons/successPayementIcon_noinline.svg';

import styles from './styles/paymentSuccess.cssm';
import { PaymentStatus } from './types';

const PaymentSuccessContent = ({ productPackageNames }: { productPackageNames: string }) => {
    const paymentPending = useSelector(selectRouteParams).status === PaymentStatus.PENDING;

    return (
        <>
            <Flex alignCenter column className={styles.content}>
                <Flex alignCenter stretchWidth className={styles.packagesContainer}>
                    <Flex column alignBaseline justifyCenter className={styles.packages}>
                        <div>
                            <Text.XLarge bold className={styles.congratulations}>
                                <Trans>Congratulations!</Trans>
                            </Text.XLarge>
                        </div>
                        <div>
                            <Text.Base bold className={styles.textContent}>
                                {paymentPending ? (
                                    <Trans>
                                        The payment provider is processing your payment. Your
                                        packages {productPackageNames} will be sent to you after the
                                        payment is processed.
                                    </Trans>
                                ) : (
                                    <Trans>Packages purchased successfully</Trans>
                                )}
                            </Text.Base>
                        </div>
                    </Flex>
                    <div className={styles.checkMark}>
                        <img src={SuccessPaymentIcon} alt={'Check mark icon'} />
                    </div>
                </Flex>
            </Flex>
        </>
    );
};

export default PaymentSuccessContent;
