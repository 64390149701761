import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { selectRouteParams } from 'strat/routes/selectors';

import type { SearchSortOption } from 'horizontal/types';

const useSetSearchOptions = () => {
    const router = useRouter();
    const routeParams = useSelector(selectRouteParams);

    return React.useCallback(
        ({ sortOption }: { sortOption: SearchSortOption }) => {
            router.pushRoute(RouteNames.USER_ORDERS, {
                ...routeParams,
                sorting: sortOption.key,
                page: 1,
            });
        },
        [router, routeParams],
    );
};

export default useSetSearchOptions;
