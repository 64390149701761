import memoize from 'fast-memoize';

const formatNumber = (value: number, locales: string): string => {
    const intl = new Intl.NumberFormat(locales);
    return intl.format(value);
};

const getThousandSeparator = memoize((locales: string) => {
    const oneString = formatNumber(1, locales);
    return formatNumber(1111, locales).replace(new RegExp(oneString, 'g'), '');
});

const getDecimalSeparator = memoize((locales: string) => {
    const oneString = formatNumber(1, locales);
    return formatNumber(1.1, locales).replace(new RegExp(oneString, 'g'), '');
});

const arabicToWesternArabicNumeralsMap = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'].map(
    (character, index) => [new RegExp(character, 'g'), index.toString()],
);

const urduToWesternArabicNumeralsMap = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'].map(
    (character, index) => [new RegExp(character, 'g'), index.toString()],
);

const nonStandardToWesternArabicNumeralsMap = [].concat(
    // @ts-expect-error - TS2769 - No overload matches this call.
    arabicToWesternArabicNumeralsMap,
    urduToWesternArabicNumeralsMap,
);

/**
 * Parse a formatted number and returns a JS Number. For example these
 * values will be parsed as the same number:
 *
 *  * 1234
 *  * 1,234
 *  * ١٢٣٤
 *  * ١,٢٣٤
 *  * ١٬٢٣٤
 *
 *  The locales parameter is used to detect the current digit grouping and decimal
 *  separators. In case the detected separators can't be used to successfully parse
 *  the given value then the english , and . will be used as a fallback. The main
 *  reason for using the english fallback is that the arabic separators are not
 *  always used by the arabic users.
 */
const parseFormattedNumber = (
    value?: number | string | null,
    // @ts-expect-error - TS2724 - 'Intl' has no exported member named 'Locales'. Did you mean 'Locale'?
    locales?: Intl.Locales | null,
): number => {
    if (typeof value !== 'string') {
        return Number(value);
    }

    const localeSeparators = [getThousandSeparator(locales), getDecimalSeparator(locales)];
    const enFallbackSeparators = [',', '.'];
    const arFallbackSeparators = ['٬', '.'];
    const separators = [localeSeparators, enFallbackSeparators, arFallbackSeparators];
    const number = separators
        // @ts-expect-error - TS2345 - Argument of type '([thousandSeparator, decimalSeparator]: [any, any]) => number' is not assignable to parameter of type '(value: string[], index: number, array: string[][]) => number'.
        .map(([thousandSeparator, decimalSeparator]: [any, any]) => {
            const unformattedNumber = value
                .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
                .replace(new RegExp(`\\${decimalSeparator}`), '.');

            let englishUnformattedNumber = unformattedNumber;

            nonStandardToWesternArabicNumeralsMap.forEach(([a, b]: [any, any]) => {
                englishUnformattedNumber = englishUnformattedNumber.replace(a, b);
            });

            return Number(englishUnformattedNumber);
        })
        .find((result) => !Number.isNaN(result));

    return typeof number === 'number' ? number : NaN;
};

export default parseFormattedNumber;
