import { PropertyType } from 'strat/property/types';

import * as PackedListing from '../packedListing';

import { MapBasedSearchProject, PackedProjectType } from './types';

export const externalID = (fields: PackedProjectType): string => fields[3];
export const price = (fields: PackedProjectType): number => fields[4];
export const area = (fields: PackedProjectType): number => fields[5];
export const completionDate = (fields: PackedProjectType): number => fields[6];
export const completionPercentage = (fields: PackedProjectType): number => fields[7];
export const unitRooms = (fields: PackedProjectType): number[] => fields[8];
export const unitBaths = (fields: PackedProjectType): number[] => fields[9];
export const preHandoverPayment = (fields: PackedProjectType): number => fields[10];
export const locationHierarchy = (fields: PackedProjectType): number[] => fields[11];
export const hasPropertyAds = (fields: PackedProjectType): boolean => fields[12];

export const unpackProject = (packedProject: PackedProjectType): MapBasedSearchProject => ({
    type: PropertyType.PROJECT,
    id: PackedListing.id(packedProject),
    geography: {
        lat: PackedListing.latitude(packedProject),
        lng: PackedListing.longitude(packedProject),
    },
    externalID: externalID(packedProject),
    price: price(packedProject),
    area: area(packedProject),
    completionDate: completionDate(packedProject),
    completionPercentage: completionPercentage(packedProject),
    unitRooms: unitRooms(packedProject),
    unitBaths: unitBaths(packedProject),
    preHandoverPayment: preHandoverPayment(packedProject),
    locationHierarchy: locationHierarchy(packedProject),
    hasPropertyAds: hasPropertyAds(packedProject),
});
