import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import useI18n from 'strat/i18n/language/useI18n';
import SellerFields from '@app/adCreation/sellerFields';
import { useIsAdDeliverable } from '@app/userOrders/hooks';
import { Button, LoadingSpinnerOverlay, LoadingSpinner, Flex } from 'strat/components';
import { useIsStratCreditUser } from 'strat/user';
import Category from '@app/branding/category';

import type { Category as CategoryType } from 'horizontal/types';
import type { CategoryField, FlatCategoryField } from 'horizontal/types';
import { flattenCategoryFields, useCategoryFieldsAreLoading } from 'horizontal/categoryFields';
import {
    PriceTypeField,
    ContactInfoFields,
    MileageField,
    AgentCodeField,
    AdDeliveryFields,
    ExtraFields,
} from 'horizontal/fields/commonPostingFields';
import {
    CommonPostingFields,
    useTouchedFields,
    CommonFields,
    Field,
    ImageInput,
    DeliverySection,
    PriceRelatedFieldsSection,
    useGroupedRenderedFields,
    MultipleDeliveriesSection,
} from 'horizontal/fields';
import { PriceType } from 'horizontal/types';
import {
    useCategoryFieldCombinationsAreLoading,
    useEnforceCategoryFieldCombination,
} from 'horizontal/categoryFieldCombinations';
import AutofillNotice from 'horizontal/adCreation/autofillNotice';
import AutofillDialog from 'horizontal/adCreation/autofillDialog';

import Context from './context';
import styles from './styles/form.cssm';
import { useCarsSideEffects } from './useCarsSideEffects';
import useCarsFormWarnings from './useCarsFormWarnings';
import getInputFieldTitle from './getInputFieldTitle';
import getInputFieldPlaceholder from './getInputFieldPlaceholder';

type Props = {
    readonly categoryFields: Array<CategoryField>;
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly isSubmitting: boolean;
    readonly handleSubmit: (arg1?: any) => void;
};

const CARS_KEY_ATTRIBUTES = [
    ExtraFields.make.attribute,
    ExtraFields.model.attribute,
    ExtraFields.generation.attribute,
    ExtraFields.version.attribute,
];
const computeShouldShowAutofillDialog = (
    categoryFields: Array<FlatCategoryField>,
    values: FormikValues,
    category: CategoryType | null | undefined,
) => {
    const hasCombinations = categoryFields
        .filter((field) => CARS_KEY_ATTRIBUTES.includes(field.attribute))
        .some((field) => (field.combinationChoices?.length || 0) > 0);

    return hasCombinations && values.version && Category.isOfCarsType(category);
};

const Form = ({
    categoryFields,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
}: Props) => {
    const i18n = useI18n();
    const [activeCategory] = React.useContext(Context);
    const loadingFields = useCategoryFieldsAreLoading();
    const loadingCombinations = useCategoryFieldCombinationsAreLoading();
    const loading = loadingFields || loadingCombinations;
    const [imagesLoading, setImagesLoading] = React.useState(false);
    const isStratCreditUser = useIsStratCreditUser();

    const flatCategoryFields = flattenCategoryFields(categoryFields, values);
    const choiceReducedFields = useEnforceCategoryFieldCombination(
        activeCategory?.id,
        categoryFields,
        values,
    );

    const [loadingRenderedFields, renderedFields] = useGroupedRenderedFields(
        choiceReducedFields,
        values,
        setFieldValue,
    );

    const [mileageFieldHidden, userChangedTitle] = useCarsSideEffects({
        categoryFields,
        flatCategoryFields: renderedFields,
        values,
        adCategory: activeCategory,
        setFieldValue,
    });
    const warnings = useCarsFormWarnings(values, errors, activeCategory, categoryFields);

    const renderedFilteredFields = renderedFields.filter(
        (field) =>
            (field.attribute !== MileageField.attribute || !mileageFieldHidden) &&
            field.attribute !== AgentCodeField.attribute,
    );

    const priceTypeField = React.useMemo(
        () => categoryFields.find((field) => field.attribute === PriceTypeField.attribute),
        [categoryFields],
    );

    const isDeliverable = useIsAdDeliverable(activeCategory);

    const allFields = React.useMemo(() => {
        return [
            ...categoryFields,
            ...Object.values(CommonPostingFields),
            ...Object.values(ContactInfoFields),
            ...Object.values(isDeliverable ? AdDeliveryFields : {}),
        ];
    }, [categoryFields, isDeliverable]);

    const touchedFields = useTouchedFields(allFields, touched, isSubmitting);
    const shouldShowAutofillNotice = Category.isOfCarsType(activeCategory) && !values.version;

    const postButtonText = isStratCreditUser ? t(i18n)`Next` : t(i18n)`Post now`;

    React.useEffect(() => {
        setFieldValue(CommonPostingFields.category_id.attribute, activeCategory?.externalID);
        if (priceTypeField) {
            setFieldValue(PriceTypeField.attribute, PriceType.FIXED_PRICE);
        }
    }, [setFieldValue, activeCategory, priceTypeField]);
    const shouldShowAutofillDialog = computeShouldShowAutofillDialog(
        choiceReducedFields,
        values,
        activeCategory,
    );

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.firstSection}>
                <ImageInput
                    field={CommonPostingFields.photos}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    setLoading={setImagesLoading}
                />
                {renderedFilteredFields.length ? <div className={styles.separator} /> : null}
                <LoadingSpinnerOverlay visible={loading || loadingRenderedFields} />
                {shouldShowAutofillDialog && <AutofillDialog />}
                {!loading &&
                    renderedFilteredFields.map((field: FlatCategoryField) => (
                        <div key={`${field.id}-${field.combinationChoices?.length}`}>
                            <Field
                                key={`${field.id}-${field.combinationChoices?.length}`}
                                field={field}
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touchedFields}
                                onBlur={handleBlur}
                                warnings={warnings}
                                title={getInputFieldTitle(field, activeCategory)}
                                placeholder={getInputFieldPlaceholder(i18n, field, activeCategory)}
                                radioThreshold={
                                    Category.isOfCarsType(activeCategory) &&
                                    CARS_KEY_ATTRIBUTES.includes(field.attribute)
                                        ? 0
                                        : 3
                                }
                            />
                            {shouldShowAutofillNotice && field.attribute === 'generation' && (
                                <AutofillNotice />
                            )}
                        </div>
                    ))}
                <div className={styles.separator} />
                <CommonFields
                    activeCategory={activeCategory}
                    onBlur={handleBlur}
                    touched={touchedFields}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    titleChangedRef={userChangedTitle}
                />
            </div>
            <PriceRelatedFieldsSection
                categoryFields={flatCategoryFields}
                values={values}
                errors={errors}
                touched={touchedFields}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
            />
            {isDeliverable &&
                (CONFIG.build.ENABLE_SELF_DELIVERY ? (
                    <div className={styles.section}>
                        <MultipleDeliveriesSection
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touchedFields}
                            values={values}
                            onBlur={handleBlur}
                        />
                    </div>
                ) : (
                    <div className={styles.section}>
                        <DeliverySection
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touchedFields}
                            values={values}
                            onBlur={handleBlur}
                            isEdit
                        />
                    </div>
                ))}
            <div className={styles.section}>
                <SellerFields
                    handleBlur={handleBlur}
                    touched={touchedFields}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                />
                <div className={styles.separator} />
                <Flex justifyEnd stretchWidth className={styles.buttonWrapper}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: (string | false | Element)[]; disabled: boolean; stretch: true; primary: true; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button disabled={imagesLoading || isSubmitting} primary>
                        {!isSubmitting && postButtonText}
                        {isSubmitting && <LoadingSpinner />}
                    </Button>
                </Flex>
            </div>
        </form>
    );
};

export default Form;
