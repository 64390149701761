import { createSelector } from 'reselect';

import EMPTY_OBJECT from 'strat/empty/object';
import { GlobalState } from 'strat/state';

const selectAutoTranslate = (state: GlobalState) => state.autoTranslate || EMPTY_OBJECT;

const selectIsAutoTranslateDescriptionEnabled = createSelector(
    selectAutoTranslate,
    (autoTranslateState) => autoTranslateState.isAutoTranslateDescriptionEnabled,
);

const selectDescriptionTranslatedOnDemand = createSelector(
    selectAutoTranslate,
    (autoTranslate) => autoTranslate?.descriptionTranslatedOnDemand?.data,
);

const selectDescriptionTranslatedOnDemandLoading = createSelector(
    selectAutoTranslate,
    (autoTranslate) => autoTranslate?.descriptionTranslatedOnDemand?.loading,
);

const selectDescriptionTranslatedOnDemandError = createSelector(
    selectAutoTranslate,
    (autoTranslate) => autoTranslate?.descriptionTranslatedOnDemand?.error,
);

export {
    selectIsAutoTranslateDescriptionEnabled,
    selectDescriptionTranslatedOnDemand,
    selectDescriptionTranslatedOnDemandLoading,
    selectDescriptionTranslatedOnDemandError,
};
