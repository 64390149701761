import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StratAPI from 'strat/api/strat';
import { selectActiveUser } from 'strat/user/session';

import useURLChangeDetector from 'horizontal/util/useURLChangeDetector';
import type { Notification } from 'horizontal/types';
import type { AppDispatch } from 'horizontal/state';

import { clearUserNotifications, fetchUserNotifications } from './state';
import { selectNotifications, selectLoading, selectNotificationsCount } from './selectors';

type UseNotificationsResult = {
    notifications: Array<Notification>;
    fetchNotificationsIfViewed: () => void;
    markAsViewed: () => void;
    markAsRead: (notification: Notification) => void;
    fetchNextPage: () => void;
    newNotifications: boolean;
    loading: boolean;
    totalNotificationsCount: number;
};

const useNotifications = (): UseNotificationsResult => {
    const notifications = useSelector(selectNotifications);
    const dispatch = useDispatch<AppDispatch>();
    const route = useURLChangeDetector();
    const activeUser = useSelector(selectActiveUser);
    const totalNotificationsCount = useSelector(selectNotificationsCount);
    const page = React.useRef(1);
    const loading = useSelector(selectLoading);

    React.useEffect(() => {
        dispatch(fetchUserNotifications());
        return () => {
            page.current = 1;
            dispatch(clearUserNotifications());
        };
    }, [dispatch, route]);

    const notViewedNotificationIds = React.useMemo(() => {
        return notifications
            .filter((notification) => !notification.viewedAt)
            .map((notification) => notification.id);
    }, [notifications]);

    const fetchNotificationsIfViewed = React.useCallback(() => {
        if (notViewedNotificationIds.length) {
            page.current = 1;
            dispatch(clearUserNotifications());
            dispatch(fetchUserNotifications());
        }
    }, [dispatch, notViewedNotificationIds]);

    const markAsViewed = React.useCallback(() => {
        if (notViewedNotificationIds.length && activeUser) {
            new StratAPI().viewNotifications(notViewedNotificationIds, activeUser.externalID);
        }
    }, [notViewedNotificationIds, activeUser]);

    const markAsRead = React.useCallback(
        (notification) => {
            if (!notification.readAt && activeUser) {
                new StratAPI().readNotification(notification.id, activeUser.externalID);
            }
        },
        [activeUser],
    );

    const fetchNextPage = React.useCallback(() => {
        if (!loading && notifications.length < totalNotificationsCount) {
            page.current += 1;
            dispatch(fetchUserNotifications({ page: page.current }));
        }
    }, [dispatch, notifications, totalNotificationsCount, loading]);

    return {
        notifications,
        fetchNotificationsIfViewed,
        markAsViewed,
        markAsRead,
        fetchNextPage,
        newNotifications: !!notViewedNotificationIds.length,
        loading,
        totalNotificationsCount,
    };
};

export default useNotifications;
