import * as React from 'react';
import { Trans } from '@lingui/macro';
import { ViewSections } from 'strat/gtm';
import { Text, ListEntry } from 'strat/components';

import iconBusinessPackages from 'horizontal/assets/icons/iconBusinessPackages_noinline.svg';
import {
    BusinessPackagesLink as BaseBusinessPackagesLink,
    BusinessPackagesLinkSource,
} from 'horizontal/businessPackages';

const renderLeft = () => <img alt={'Buy business packages'} src={iconBusinessPackages} />;

const BusinessPackagesLink = ({ onClick }: { onClick?: (arg1: React.MouseEvent<any>) => void }) => {
    return (
        <BaseBusinessPackagesLink
            onClick={onClick}
            viewSection={ViewSections.USER_DETAIL}
            source={BusinessPackagesLinkSource.MY_ACCOUNT}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>
                    <Trans>Buy Discounted Packages</Trans>
                </Text.Large>
            </ListEntry>
        </BaseBusinessPackagesLink>
    );
};

export default BusinessPackagesLink;
