import * as React from 'react';

import styles from './styles/header.cssm';

type Props = {
    readonly className?: string;
    readonly compact?: boolean;
    readonly children: React.ReactNode;
};

const Header = ({ className, compact, children }: Props) => {
    return (
        <header className={className || (compact ? styles.compactHeader : styles.header)}>
            {children}
        </header>
    );
};

export default Header;
