import React from 'react';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import { Tab } from 'strat/modal';
import { GTMImageViewTrackingProps } from 'strat/gtm';
import { PropertyPhotoData } from 'strat/property/types';
import PhotoGalleryDialog from 'strat/property/gallery/photoGalleryDialog';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';
import { LoadingSpinner } from 'strat/loadable';
import { useI18n } from 'strat/i18n/language';

import { AdPhoto } from 'horizontal/types';

import { usePhotoRatios } from './usePhotoRatios';
import styles from './styles/adPhotosGalleryTab.cssm';

type Props = Partial<GTMImageViewTrackingProps> & {
    photos: AdPhoto[];
    photoViewVisible: boolean;
    onPhotoViewVisibilityChange: (visible: boolean) => void;
    onImageChange?: () => void;
};

const AdPhotosGalleryTab = ({
    photos,
    photoViewVisible,
    onPhotoViewVisibilityChange,
    onImageChange,
}: Props) => {
    const i18n = useI18n();
    const [currentSlideshowPhotoIndex, setCurrentSlideshowPhotoIndex] = React.useState(0);
    const { buildPhotoRatios, isLandscapeDisplay, isPortraitDisplay } = usePhotoRatios(photos);
    const onPhotoClick = (photo: PropertyPhotoData, index: number) => {
        setCurrentSlideshowPhotoIndex(index);
        onPhotoViewVisibilityChange(true);
    };

    const onCloseButtonClick = () => {
        onPhotoViewVisibilityChange(false);
    };

    const onSlide = (index: number) => {
        setCurrentSlideshowPhotoIndex(index);
        if (onImageChange) {
            onImageChange();
        }
    };
    const closeButtonLabel = t(i18n)`Back to gallery`;

    return (
        <Tab>
            <div className={styles.photosSection}>
                <div
                    aria-label="Gallery dialog photo grid"
                    className={classNames(styles.photoCollectionContainer, {
                        [styles.displayNone]: photoViewVisible,
                        [styles.flex]: photos.length > 1,
                    })}
                >
                    {photos.map((photo, index) => (
                        <div
                            className={classNames({
                                [styles.landscapeContainer]: isLandscapeDisplay(photo.id),
                                [styles.portraitContainer]: isPortraitDisplay(photo.id),
                            })}
                            key={photo.id}
                        >
                            <ThumbnailImage
                                alt={photo.title}
                                imageID={photo.id}
                                className={classNames(styles.photo, {
                                    [styles.multiplePhotosHeight]: photos.length > 1,
                                })}
                                loadingIndicator={<LoadingSpinner />}
                                onClick={() => onPhotoClick(photo, index)}
                                onSizeAvailable={(width, height) => {
                                    buildPhotoRatios(photo.id, width, height);
                                }}
                                size={ThumbnailSizes.MEDIUM}
                            />
                        </div>
                    ))}
                </div>
                <PhotoGalleryDialog
                    onCloseButtonClick={onCloseButtonClick}
                    slideIndex={currentSlideshowPhotoIndex}
                    startIndex={currentSlideshowPhotoIndex}
                    visible={photoViewVisible}
                    photos={photos}
                    onSlide={onSlide}
                    closeButtonLabel={closeButtonLabel}
                    withOverlay
                    withCounter
                />
            </div>
        </Tab>
    );
};

export default AdPhotosGalleryTab;
