import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import { useI18n } from 'strat/i18n/language';
import { stopEventPropagation } from 'strat/util';

import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';
import type { ChatAd, ChatRoom } from 'horizontal/chat/types';
import { useChatSDK } from 'horizontal/chat/hooks';
import { ChatMessageType } from 'horizontal/chat/constants';

import { OpenAttachmentDialogButton, ProgressBar } from './attachment';
import SendOrRecordButton from './sendOrRecordButton';
import styles from './styles/composer.cssm';

type OwnProps = {
    readonly chatRoom: ChatRoom;
    readonly ad: ChatAd | null | undefined; // required for lead tracking,
    /**
     * Mobile and tablets don't have a physical keyboard (usually), so sending
     * a message typically relies on tapping the "send button". However, on desktops
     * where we have a physical keyboard, enter key traditionally submits the message.
     * This is the case, when there's no shift held when pressing enter, as in that case
     * a new line is expected (on physical keyboards).
     * As there's no shift key on mobile/tablet (usually), the scenario when enter+HOLD
     * shift-key isn't possible so the enter should always add a new line, and submitting
     * should be done only by the send button.
     */
    readonly submitOnEnter?: boolean;
    readonly hidden: boolean;
    readonly onClick: () => void;
};
type Props = GTMLeadTrackingProps & OwnProps;

const Composer = ({ ad, chatRoom, submitOnEnter, hidden, onClick, trackChatLead }: Props) => {
    const sdk = useChatSDK();
    const sendTextMessage = sdk.useActiveRoomSendTextMessage();
    const { sendImages: sendImagesMessage, uploadingImages: imagesUploadProgress } =
        sdk.useActiveRoomSendImages();
    const { sendFiles: sendFilesMessage, uploadingFiles: filesUploadProgress } =
        sdk.useActiveRoomSendFiles();

    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [composedMessage, setComposedMessage] = React.useState<string>('');
    const i18n = useI18n();
    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);
    const sendMessage = React.useCallback(() => {
        if (!composedMessage.trim()) {
            return;
        }

        sendTextMessage({ message: composedMessage }).then((messageSentResponse) => {
            if (messageSentResponse) {
                trackSentChatMessageEvents(messageSentResponse, ChatMessageType.TEXT);
            }
        });

        setComposedMessage('');
        if (textAreaRef.current) {
            textAreaRef.current.value = '';
        }
    }, [composedMessage, sendTextMessage, trackSentChatMessageEvents]);

    const onChange = React.useCallback((event) => {
        setComposedMessage(event.target.value);
    }, []);

    const onInputKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (submitOnEnter && event.keyCode === 13 && !event.shiftKey) {
                sendMessage();
                stopEventPropagation(event);
            }
        },
        [sendMessage, submitOnEnter],
    );

    React.useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.value = '';
        }
    }, [chatRoom.xid]);

    return (
        <Flex
            alignCenter
            className={classNames(styles.container, { [styles.hidden]: hidden })}
            onClick={onClick}
        >
            <ProgressBar uploadingFiles={imagesUploadProgress ?? {}} />
            <ProgressBar uploadingFiles={filesUploadProgress ?? {}} />
            <OpenAttachmentDialogButton
                ad={ad}
                sendImages={sendImagesMessage}
                sendAttachments={sendFilesMessage}
            />
            <textarea
                ref={textAreaRef}
                placeholder={t(i18n)`Type a message`}
                spellCheck={false}
                className={styles.input}
                autoComplete="off"
                onChange={onChange}
                // @ts-expect-error - TS2322 - Type '(event: React.KeyboardEvent<HTMLInputElement>) => void' is not assignable to type 'KeyboardEventHandler<HTMLTextAreaElement>'.
                onKeyDown={onInputKeyDown}
            />
            <SendOrRecordButton
                /* we hide the record button until the functionality is implemented */
                shouldShowSendButton
                onSendMesssage={sendMessage}
            />
        </Flex>
    );
};

export default withGTMLeadTracking<Props, ChatAd | null | undefined>(React.memo<Props>(Composer));
