import { t } from '@lingui/macro';
import * as React from 'react';
import Logo from '@app/branding/logo';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { isValidPhoneNumber, LibPhoneNumberMetadata } from 'strat/i18n/phone';
import { Input, Flex, Text, Button } from 'strat/components';
import { RecaptchaUsageConditions, useRecaptcha } from 'strat/captcha';

import UserProfilePicture from '@app/user/userProfilePicture';

import useProfileEditErrorMessage, { ProfileField } from '../useProfileEditErrorMessage';

import styles from './styles/phoneNumberDialogContent.cssm';

type Props = {
    readonly phoneNumber: string | null | undefined;
    readonly onSubmit: (
        phoneNumber?: string | null | undefined,
        captcha?: string | null | undefined,
    ) => void;
    readonly isLoading: boolean;
    readonly enablePhoneNumberChangeWarningBanner?: boolean;
};

const cleanPhoneNumber = (phone: string, isInternational: boolean) => {
    let parsedText = phone.replace(/[^0-9+() ]/g, '');
    if (parsedText.endsWith('+') && (parsedText.length > 1 || !isInternational)) {
        parsedText = parsedText.slice(0, -1);
    }
    return parsedText;
};

const PhoneNumberDialogContent = ({ phoneNumber, onSubmit, isLoading }: Props) => {
    const i18n = useI18n();
    const [authErrorMessage, resetErrorMessage] = useProfileEditErrorMessage(ProfileField.PHONE);
    const [phone, setPhone] = React.useState(phoneNumber);
    const [isInternational, setIsInternational] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);

    const defaultErrorMessage = t(
        i18n,
    )`Looks like your phone number may be incorrect. Please try entering your full number.`;
    const errorMessage = phone && (isValid ? authErrorMessage : defaultErrorMessage);
    const countryPrefix = isInternational ? '' : settings.defaultPhoneNumber.prefix;
    const displayPhoneNumber = isInternational
        ? phone
        : phone?.replace(settings.defaultPhoneNumber.prefix, '');

    const onClick = React.useCallback(
        (captcha?: string | null) => {
            resetErrorMessage();
            onSubmit(phone, captcha);
        },
        [phone, onSubmit, resetErrorMessage],
    );

    const { renderRecaptcha, onSubmitRecaptchaForm, isRecaptchaFormValid } = useRecaptcha(onClick);

    const onPhoneNumberChanged = React.useCallback(
        (displayValue: string) => {
            if (authErrorMessage) {
                resetErrorMessage();
            }

            const value = !isInternational
                ? settings.defaultPhoneNumber.prefix + displayValue
                : displayValue;

            const newPhone = cleanPhoneNumber(value, isInternational);

            setPhone(newPhone);
            isValidPhoneNumber(newPhone, LibPhoneNumberMetadata.MOBILE).then(setIsValid);
        },
        [authErrorMessage, resetErrorMessage, isInternational, setPhone, setIsValid],
    );

    React.useEffect(() => {
        resetErrorMessage();

        setPhone(phoneNumber);

        const isInternationalNumber =
            phoneNumber?.startsWith('+') &&
            !phoneNumber?.startsWith(settings.defaultPhoneNumber.prefix);

        setIsInternational(!!isInternationalNumber);
    }, [phoneNumber, setPhone, setIsInternational, resetErrorMessage]);

    const showInternationalButton =
        !isInternational && CONFIG.runtime.ENABLE_INTERNATIONAL_NUMBER_LOGIN;

    return (
        <Flex column justifyCenter alignCenter className={styles.container}>
            <Flex justifyCenter alignCenter>
                <Logo />
                <div className={styles.spacer} />
                <div className={styles.image}>
                    <UserProfilePicture />
                </div>
            </Flex>
            <div className={styles.title}>
                <Text.XLarge bold>{t(i18n)`Enter your phone to verify your account`}</Text.XLarge>
            </div>
            <div className={styles.sendConfirmationCodeText}>
                <Text.Regular>{t(
                    i18n,
                )`We will send a confirmation code to your number`}</Text.Regular>
            </div>
            <div className={styles.input}>
                <Input
                    name={'Mobile Phone Number'}
                    value={displayPhoneNumber || ''}
                    placeholder={t(i18n)`Phone number`}
                    type={'tel'}
                    autoComplete={'tel'}
                    errorMessage={errorMessage}
                    onChange={onPhoneNumberChanged}
                    unit={countryPrefix}
                    noArrows
                    autoFocus
                    disabled={isLoading}
                />
            </div>
            <div className={styles.phoneNumberProvidedMessage}>
                <Text.Small>{t(
                    i18n,
                )`The phone number you provide here is only used to verify your account. It will not be made public.`}</Text.Small>
            </div>
            <div className={styles.submitButton}>
                <Button
                    stretch
                    // @ts-expect-error - TS2322 - Type '{ children: string; stretch: true; primary: true; disabled: boolean; onClick: () => void; type: string; }' is not assignable to type 'IntrinsicAttributes & Props'.
                    primary
                    disabled={isLoading || !isRecaptchaFormValid(isValid)}
                    onClick={() => onSubmitRecaptchaForm(true)}
                    type="submit"
                >
                    {t(i18n)`Next`}
                </Button>
            </div>
            {renderRecaptcha()}
            <RecaptchaUsageConditions />
            {showInternationalButton && (
                <Button action small stretch onClick={() => setIsInternational(true)}>
                    {t(i18n)`Use international number`}
                </Button>
            )}
        </Flex>
    );
};

export default PhoneNumberDialogContent;
