import { RoutingContextWithMiddlewares } from 'strat/app';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { enableURLUpdater, updateURL } from 'strat/search/state';

import { SearchParamsForPageRendering } from 'horizontal/routes/search/types';
import { selectCategoryByExternalID } from 'horizontal/categories';
import { SearchRouteParams } from 'horizontal/routes/createSearchURL';
import { selectDefaultSortOption } from 'horizontal/search/sorting/sortOptions';
import { SearchSortOption } from 'horizontal/types';

import preFetchDataForSearchPageRendering from './preFetchDataForSearchPageRendering';
import prepareSearchPageRendering from './prepareSearchPageRendering';
import postFetchDataForSearchPageRendering from './postFetchDataForSearchPageRendering';
import fetchLocationByExternalID from './fetchLocationByExternalID';

const getLocationPromise = (
    context: RoutingContextWithMiddlewares,
    params: SearchParamsForPageRendering,
) => {
    const {
        redux: {
            store: { getState },
        },
        i18n: { locale: language },
    } = context;
    const { location, locationExternalID } = params;
    const activeSearchBackend = selectActiveSearchBackend(getState()) as SearchBackend;

    if (location) {
        return Promise.resolve(location);
    }
    if (locationExternalID) {
        return fetchLocationByExternalID(locationExternalID, language, activeSearchBackend);
    }
    return Promise.resolve(null);
};

const prefetchDataAndGetUpdatedParams = (
    context: RoutingContextWithMiddlewares,
    params: SearchParamsForPageRendering,
): Promise<SearchRouteParams> => {
    const {
        redux: {
            store: { getState, dispatch },
        },
    } = context;

    const { categoryExternalID } = params;

    return Promise.all([
        getLocationPromise(context, params),
        preFetchDataForSearchPageRendering(dispatch, getState, {
            categoryExternalID: categoryExternalID || params.category?.externalID,
        }),
    ]).then(([location]) => {
        const category =
            params.category || selectCategoryByExternalID(getState(), categoryExternalID);

        const sortOption: SearchSortOption | undefined =
            params.sortOption || selectDefaultSortOption(getState(), category);

        return {
            ...params,
            category,
            location,
            sortOption,
        };
    });
};

const renderSearchPageWithParams = (
    context: RoutingContextWithMiddlewares,
    params: SearchParamsForPageRendering,
): Promise<void> => {
    const { page, pagetype } = params;

    prepareSearchPageRendering(context, {
        page: page?.toString(),
        pagetype: pagetype,
    });

    return prefetchDataAndGetUpdatedParams(context, params).then((updatedParams) =>
        postFetchDataForSearchPageRendering(context, updatedParams).then(() => {
            // @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'.
            context.redux.store.dispatch(updateURL(updatedParams, { replace: true }));
            context.redux.store.dispatch(enableURLUpdater());
        }),
    );
};

export default renderSearchPageWithParams;
