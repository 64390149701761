export const TEMP_USER_IMAGE_PREFIX = 'TEMP_';

export const tempUserImageURL = (tempImageID: string) => {
    if (!tempImageID.startsWith(TEMP_USER_IMAGE_PREFIX)) {
        return '';
    }

    let url: string = CONFIG.build.THUMBNAILS_URL || '';
    url += `/temp/${tempImageID.split(TEMP_USER_IMAGE_PREFIX)[1]}`;
    return url;
};
