import * as React from 'react';
import Carousel, { CarouselContentStyles } from 'strat/generic/carousel';
import { useWindowSize } from 'strat/modal';

import type { Agency } from 'horizontal/types';

import { FeaturedAgencyCard, FeaturedAgenciesCore } from '../featuredAgencies';

import styles from './styles/featuredAgenciesCarousel.cssm';

type Props = React.ComponentProps<typeof FeaturedAgenciesCore> & {
    readonly items: Agency[];
    readonly reversed: boolean;
};

const FeaturedAgenciesCarousel = ({
    items,
    groupSize,
    computeGroupSize,
    renderPreviousButton,
    renderNextButton,
    compact,
    reversed,
}: Props) => {
    const [layoutType, setLayoutType] = React.useState<number>(() => {
        if (compact) {
            return window.innerWidth <= CONFIG.build.DEVICE_WIDTH_MOBILE
                ? CONFIG.build.DEVICE_WIDTH_MOBILE
                : CONFIG.build.DEVICE_WIDTH_TABLET;
        }
        return window.innerWidth <= CONFIG.build.DEVICE_WIDTH_TABLET
            ? CONFIG.build.DEVICE_WIDTH_TABLET
            : CONFIG.build.DEVICE_WIDTH_DESKTOP;
    });

    const { width } = useWindowSize();

    React.useEffect(() => {
        let calculatedWidth;
        if (compact) {
            calculatedWidth =
                Number(width) <= CONFIG.build.DEVICE_WIDTH_MOBILE
                    ? CONFIG.build.DEVICE_WIDTH_MOBILE
                    : CONFIG.build.DEVICE_WIDTH_TABLET;
        } else {
            calculatedWidth =
                Number(width) <= CONFIG.build.DEVICE_WIDTH_TABLET
                    ? CONFIG.build.DEVICE_WIDTH_TABLET
                    : CONFIG.build.DEVICE_WIDTH_DESKTOP;
        }
        setLayoutType(calculatedWidth);
    }, [width, compact]);

    const entries = React.useMemo(
        () =>
            items.map((item) => (
                <div key={item.id} className={styles.featuredAgencyCardContainer}>
                    <FeaturedAgencyCard agency={item} />
                </div>
            )),
        [items],
    );

    const getGroupAlignmentStyle = React.useCallback(
        (groupPhotosCount: number) =>
            groupPhotosCount < groupSize[layoutType]
                ? CarouselContentStyles.FLEX_START
                : CarouselContentStyles.FLEX_SPACE_BETWEEN,
        [layoutType, groupSize],
    );

    return (
        <Carousel
            // @ts-expect-error - TS2322 - Type '{ children: Element[]; groupSize?: number | undefined; computeGroupSize: (windowWidth?: number | null | undefined) => number; groupItemsAlignmentStyle: "carouselGroupContentStart"; getGroupAlignmentStyle: (groupPhotosCount: number) => "carouselGroupContentStart" | "carouselGroupContentSpaceBetween"; renderPreviousBu...' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Carousel> & Readonly<{}> & Readonly<{ children?: ReactNode; }>'.
            computeGroupSize={computeGroupSize}
            groupItemsAlignmentStyle={CarouselContentStyles.FLEX_START}
            getGroupAlignmentStyle={getGroupAlignmentStyle}
            renderPreviousButton={renderPreviousButton}
            renderNextButton={renderNextButton}
            reversed={reversed}
            {...(compact ? { groupSize: groupSize[layoutType] } : {})}
        >
            {entries}
        </Carousel>
    );
};

export default FeaturedAgenciesCarousel;
