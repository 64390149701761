import type { PropertyData } from 'strat/property';

export const SellerType = Object.freeze({
    BUSINESS: 'business',
    INDIVIDUAL: 'individual',
});

const getSellerType = (property: PropertyData): Values<typeof SellerType> =>
    property.agency ? SellerType.BUSINESS : SellerType.INDIVIDUAL;

export default getSellerType;
