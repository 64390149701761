import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { Accordion, AccordionItem } from 'strat/generic/accordion';
import { Trans } from '@lingui/macro';

import WalletIcon from 'horizontal/assets/icons/iconDeliveryServiceWallet_noinline.svg';
import TimeIcon from 'horizontal/assets/icons/iconTime_noinline.svg';
import OfferIcon from 'horizontal/assets/icons/iconOffer_noinline.svg';
import UnsuccessfulPaymentIcon from 'horizontal/assets/icons/iconUnsuccessfulPayment_noinline.svg';
import Refund from 'horizontal/assets/images/refund.png';
import TopUpMethod from '@app/userWallet/topUpMethod';
import PartialPayment from '@app/userWallet/partialPayment';

import WalletCredit from './walletCredit';
import styles from './styles/accoridonContnet.cssm';

const AccoridonContnet = () => {
    const i18n = useI18n();

    return (
        <Accordion initialExpanded={false}>
            <AccordionItem
                icon={WalletIcon}
                iconClassName={styles.icon}
                itemHeaderClassName={styles.itemHeader}
                itemClassName={styles.item}
                arrowClassName={styles.arrowClassName}
                expandedClassName={styles.isExpanded}
                itemContentClassName={styles.itemContent}
                title={t(i18n)`Payment with dubizzle Wallet`}
            >
                <Flex column>
                    <Flex className={styles.section}>
                        <span>
                            <Trans>
                                In the payment selection, simply
                                <b>activate “Use wallet credit”</b>
                                to pay the amount.
                            </Trans>
                        </span>
                    </Flex>

                    <Flex className={styles.section}>
                        <PartialPayment />
                    </Flex>

                    <Flex alignCenter column stretchWidth className={styles.section}>
                        <WalletCredit />
                    </Flex>
                </Flex>
            </AccordionItem>
            <TopUpMethod />
            <AccordionItem
                icon={TimeIcon}
                iconClassName={styles.icon}
                itemHeaderClassName={styles.itemHeader}
                itemClassName={styles.item}
                arrowClassName={styles.arrowClassName}
                expandedClassName={styles.isExpanded}
                itemContentClassName={styles.itemContent}
                title={t(i18n)`Balance expiration date`}
            >
                <Flex column>
                    <Flex className={styles.section}>
                        <Trans>Each top-up will have its own expiration date.</Trans>
                    </Flex>
                    <Flex className={styles.section}>
                        <span>
                            <Trans>
                                Under
                                <b> dubizzle Wallet </b>
                                balance on the previous page, you will see the next amount that is
                                about to expire.
                            </Trans>
                        </span>
                    </Flex>
                </Flex>
            </AccordionItem>
            <AccordionItem
                icon={OfferIcon}
                iconClassName={styles.icon}
                itemHeaderClassName={styles.itemHeader}
                itemClassName={styles.item}
                arrowClassName={styles.arrowClassName}
                expandedClassName={styles.isExpanded}
                itemContentClassName={styles.itemContent}
                title={t(i18n)`Refunds`}
            >
                <Flex column>
                    <Flex className={styles.section}>
                        <span>
                            <Trans>
                                In case you need a refund for one of the dubizzle products you
                                bought, the money will be returned to your
                                <b> dubizzle Wallet </b>
                                and you will be able to reuse them for posting or upgrading your
                                ads.
                            </Trans>
                        </span>
                    </Flex>

                    <Flex alignCenter column stretchWidth className={styles.section}>
                        <img src={Refund} className={styles.img} />
                    </Flex>
                </Flex>
            </AccordionItem>
            <AccordionItem
                icon={UnsuccessfulPaymentIcon}
                iconClassName={styles.icon}
                itemHeaderClassName={styles.itemHeader}
                itemClassName={styles.item}
                arrowClassName={styles.arrowClassName}
                expandedClassName={styles.isExpanded}
                itemContentClassName={styles.itemContent}
                title={t(i18n)`Unsuccessful transaction`}
            >
                <Flex column>
                    <Flex className={styles.section}>
                        <span>
                            <Trans>
                                In case of an unsuccessful transaction, your credit will be restored
                                to your
                                <b> dubizzle Wallet </b>
                                within 24h.
                            </Trans>
                        </span>
                    </Flex>
                </Flex>
            </AccordionItem>
        </Accordion>
    );
};

export default AccoridonContnet;
