import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';
import MessageBox, { MessageBoxType } from 'strat/components/messageBox';
import { useI18n } from 'strat/i18n/language';

import DislikeIcon from 'horizontal/assets/icons/dislike.svg';
import LikeIcon from 'horizontal/assets/icons/like.svg';
import OkHandIcon from 'horizontal/assets/icons/okhand.svg';
import RightArrowInCircleIcon from 'horizontal/assets/icons/rightArrowInCircle.svg';
import WarningIcon from 'horizontal/assets/icons/warning.svg';
import SearchLink from 'horizontal/search/routing/searchLink';
import type { LiteCategory, LiteHierarchicalLocation } from 'horizontal/types';
import { useFormattedPrice } from 'horizontal/util';

import { PriceOfferType } from './price';
import styles from './styles/priceFeedbackMessageBox.cssm';

type Props = {
    readonly priceOfferType: Values<typeof PriceOfferType>;
    readonly category: LiteCategory;
    readonly location: LiteHierarchicalLocation;
    readonly price: number;
    readonly minPrice: number | null | undefined;
};

const PriceFeedbackMessageBox = ({
    priceOfferType,
    category,
    location,
    price,
    minPrice,
}: Props) => {
    const i18n = useI18n();
    const maxPriceFormatted = useFormattedPrice(price * 1.2 + 1);
    const minPriceFormatted = useFormattedPrice((minPrice || 1) - 1);

    const searchRouteParams = { category, location } as const;
    switch (priceOfferType) {
        case PriceOfferType.NULL:
            return (
                <MessageBox
                    renderTitle={() => t(i18n)`Please input offer value`}
                    renderContent={() => t(i18n)`Make offer to close the deal fast!`}
                    renderIcon={() => <WarningIcon className={styles.icon} />}
                    type={MessageBoxType.ERROR}
                />
            );
        case PriceOfferType.HIGH:
            return (
                <MessageBox
                    renderTitle={() => t(i18n)`Your offer is too high`}
                    renderContent={() =>
                        t(i18n)`Your offer should be less than ${maxPriceFormatted}`
                    }
                    renderIcon={() => <WarningIcon className={styles.icon} />}
                    type={MessageBoxType.ERROR}
                />
            );
        case PriceOfferType.VERY_GOOD:
            return (
                <MessageBox
                    renderTitle={() => t(i18n)`Very good offer!`}
                    renderContent={() => t(i18n)`High chances of seller’s reply.`}
                    renderIcon={() => <OkHandIcon className={styles.icon} />}
                    type={MessageBoxType.ACCEPTED}
                />
            );
        case PriceOfferType.GOOD:
            return (
                <MessageBox
                    renderTitle={() => t(i18n)`Good offer`}
                    renderContent={() => t(i18n)`More chances of seller’s response.`}
                    renderIcon={() => <LikeIcon className={styles.icon} />}
                    type={MessageBoxType.NEUTRAL}
                />
            );
        case PriceOfferType.UNDER_MINIMUM:
            return (
                <MessageBox
                    renderTitle={() => t(i18n)`Offer should be more than ${minPriceFormatted}.`}
                    renderContent={() => null}
                    renderIcon={() => <WarningIcon className={styles.icon} />}
                    type={MessageBoxType.ACCEPTED}
                />
            );
        case PriceOfferType.LOW:
        default:
            return (
                // @ts-expect-error - TS2322 - Type '{ readonly category: LiteCategory; readonly location: LiteHierarchicalLocation; }' is not assignable to type '{ [key: string]: any; } & SearchRouteParams'.
                <SearchLink hard params={searchRouteParams}>
                    <MessageBox
                        renderTitle={() => t(i18n)`Too low offer`}
                        renderContent={() => (
                            <Flex>
                                <Text.Base uppercase underlined>
                                    <Trans>Similar products in your budget.</Trans>
                                </Text.Base>
                                <RightArrowInCircleIcon
                                    className={classNames(styles.icon, styles.rightArrowIcon)}
                                />
                            </Flex>
                        )}
                        renderIcon={() => <DislikeIcon className={styles.icon} />}
                        type={MessageBoxType.ERROR}
                    />
                </SearchLink>
            );
    }
};

export default PriceFeedbackMessageBox;
