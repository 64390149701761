//@ts-nocheck
import { combineReducers } from 'redux';

import i18nMoneyReducer from './money/state';
import { languageReducer } from './language';

export default combineReducers({
    money: i18nMoneyReducer,
    language: languageReducer,
    country: languageReducer,
});
