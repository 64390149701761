import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import { ViewSections } from 'strat/gtm';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { useAdCommunicationMethods } from 'horizontal/ad';
import { isEligibleForDelivery } from 'horizontal/util';
import { PhoneNumberLinkType } from 'horizontal/contact';
import type { LiteAd } from 'horizontal/types';
import { AgencyRoles } from 'horizontal/types';
import IconCall from 'horizontal/assets/icons/iconCall.svg';
import { useShowWhatsAppButton } from 'horizontal/adDetails';

import BuyWithDeliveryCTA from './buyWithDeliveryCTA';
import PhoneCTA from './phoneCTA';
import ChatCTA from './chatCTA';
import WhatsAppCTA from './whatsAppCTA';
import styles from './styles/contactButtons.cssm';

const renderCallIcon = (className: string) => <IconCall className={className} />;

type Props = {
    readonly ad: LiteAd;
    readonly viewSection: Values<typeof ViewSections>;
};

const ContactButtons = ({ ad, viewSection }: Props) => {
    const i18n = useI18n();
    const isAgencyExternallyManaged = ad?.agency?.roles?.includes(
        AgencyRoles.MANAGED_BY_EXTERNAL_SOURCE,
    );

    const {
        allowChatCommunication,
        allowPhoneCommunication,
        shouldShowBuyWithDelivery,
        showWhatsappPhoneNumber,
    } = useAdCommunicationMethods(ad);

    const showWhatsappButton = useShowWhatsAppButton({
        isWhatsAppActive: showWhatsappPhoneNumber,
        isShowPhoneNumberActive: allowPhoneCommunication,
        agency: ad?.agency,
    });

    const isChatAvailable = allowChatCommunication && ad.userExternalID;
    const showChatButton = isAgencyExternallyManaged
        ? isChatAvailable && !showWhatsappButton
        : isChatAvailable;

    const shouldShowDeliveryCTA = isEligibleForDelivery(ad) && shouldShowBuyWithDelivery;

    if (shouldShowDeliveryCTA) {
        return <BuyWithDeliveryCTA ad={ad} />;
    }

    return (
        <Flex className={classNames(styles.container, styles.hideCTA)}>
            {allowPhoneCommunication && (
                <PhoneCTA
                    ad={ad}
                    viewSection={viewSection}
                    phoneNumberLinkType={PhoneNumberLinkType.CALL}
                    text={t(i18n)`Call`}
                    renderIcon={renderCallIcon}
                    secondary={settings.allowsPrimaryContactButton}
                    className={
                        !settings.allowsPrimaryContactButton ? styles.contactButton : undefined
                    }
                />
            )}
            {showChatButton && (
                <ChatCTA
                    ad={ad}
                    adExternalID={ad.externalID}
                    userExternalID={ad.userExternalID}
                    viewSection={viewSection}
                    className={
                        !settings.allowsPrimaryContactButton ? styles.contactButton : undefined
                    }
                />
            )}
            {showWhatsappButton && <WhatsAppCTA ad={ad} viewSection={viewSection} />}
        </Flex>
    );
};

export default ContactButtons;
