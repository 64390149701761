import * as React from 'react';
import autoBind from 'react-autobind';
import omit from 'lodash/omit';

import { stopEventPropagation, getEventPath } from 'strat/util';
import type { InlineStyle } from 'strat/types';

import withDismissibleStack, { DissmissibleStacksProps } from './withDismissibleStack';

type DivProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className' | 'style'>;

type Props = DivProps &
    DissmissibleStacksProps & {
        enabled?: boolean;
        stacked?: boolean;
        onDismissed: (event: Event) => void;
        children?: React.ReactNode;
        className?: string;
        style?: InlineStyle;
        propagate?: boolean;
        ignoreNode?: HTMLElement;
        dismissOnClick?: boolean;
        dismissOnKeyDown?: boolean;
        dismissOnScroll?: boolean;
    };

/**
 * Non-visible container that allows the contents to be
 * dismissed by clicking outside the container.
 *
 * This is useful for dialogs and/or pop-ups which
 * can be dismissed by clicking outside of the pop-up.
 *
 * Multiple Dismissible components can be rendered
 * at the same time. Dismissible makes the assumption
 * that they are rendered "on top" of each other. This
 * means that you have to dismiss them in order. You
 * first have to dismiss the last one you rendered
 * before the one before that can be dismissed.
 */
class Dismissible extends React.Component<Props> {
    /**
     * Default values for optional props.
     */
    static defaultProps = {
        enabled: true,
        stacked: true,
        propagate: false,
        dismissOnClick: true,
        dismissOnKeyDown: true,
        dismissOnScroll: false,
    };

    /**
     * Increased every time a new Dismissible is mounted.
     */
    static idCounter = 0;

    /**
     * Elements that is outside the dismissible area.
     */
    element: null | Node;

    id: number;

    constructor(props: Props) {
        super(props);
        autoBind(this);

        Dismissible.idCounter += 1;
        this.id = Dismissible.idCounter;

        this.element = null;
    }

    isTopOfStack() {
        const topId = this.props.dismissibleStack.top();
        return topId === this.id;
    }

    /**
     * Handler for when a click occurs anywhere on the page.
     */
    onClick(event: MouseEvent | TouchEvent): void {
        if (!this.element) {
            return;
        }

        if (!(event.target instanceof Node)) {
            // eslint-disable-next-line no-console
            console.warn('Dismissible: click from non-node object.');
            return;
        }

        // Don't dismiss if the click was inside the child
        const isInContainer = this.element === event.target || this.element.contains(event.target);

        if (isInContainer) {
            return;
        }

        // Don't dismiss if the click was inside the ignored subtree
        if (this.props.ignoreNode) {
            const isIgnored =
                this.props.ignoreNode === event.target ||
                this.props.ignoreNode.contains(event.target);

            if (isIgnored) {
                return;
            }
        }

        // It might happen that the element that was clicked is no longer in the DOM
        // Think of a button that gets removed when it's clicked.
        // The SyntheticMouseEvent contains a path attribute that includes every element
        // the event was propagated through. If this attribute contains the current dissmisible,
        // it means the element that was clicked is part of it. If so, don't dismiss.
        const isElementInTargetPath = getEventPath(event).includes(this.element);

        if (isElementInTargetPath) {
            return;
        }

        // Do not propagate any click/touch event if the event is outside of it
        if (!this.props.propagate) {
            stopEventPropagation(event);
        }

        this.props.onDismissed(event);
    }

    /**
     * Enables the dismissible by registering the click handler
     */
    enable() {
        if (!document) {
            return;
        }

        if (this.props.dismissOnClick) {
            document.addEventListener('click', this.onClick, { passive: false });
            document.addEventListener('touchstart', this.onClick, { passive: false });
        }

        if (this.props.dismissOnKeyDown) {
            document.addEventListener('keydown', this.onKeyDown, { passive: true });
        }

        if (this.props.dismissOnScroll) {
            document.addEventListener('scroll', this.onScroll, { passive: true });
        }
    }

    /**
     * Disables the dismissible by removing the click handler
     */
    disable() {
        if (!document) {
            return;
        }

        if (this.props.dismissOnClick) {
            document.removeEventListener('click', this.onClick);
            document.removeEventListener('touchstart', this.onClick);
        }

        if (this.props.dismissOnKeyDown) {
            document.removeEventListener('keydown', this.onKeyDown);
        }

        if (this.props.dismissOnScroll) {
            document.removeEventListener('scroll', this.onScroll);
        }
    }

    /**
     * Enables/disables the dismissible based on the
     * specified props.
     */
    applyState(enabled: boolean) {
        if (enabled) {
            this.enable();
        } else {
            this.disable();
        }
    }

    componentDidMount() {
        if (!this.props.enabled) {
            return;
        }

        if (this.props.stacked) {
            this.props.dismissibleStack.push(this.id);
        } else {
            this.enable();
        }
    }

    onKeyDown(event: KeyboardEvent) {
        // Close the dialog if escape was pressed.
        if (event?.which === 27) {
            this.props.onDismissed(event);
        }
    }

    onScroll(event: Event) {
        this.props.onDismissed(event);
    }

    componentWillUnmount() {
        this.disable();

        if (this.props.enabled && this.props.stacked) {
            this.props.dismissibleStack.remove(this.id);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (!this.props.stacked) {
            if (this.props.enabled !== prevProps.enabled) {
                this.applyState(!!this.props.enabled);
            }
            return;
        }

        if (this.props.enabled !== prevProps.enabled) {
            if (this.props.enabled) {
                this.props.dismissibleStack.push(this.id);
            } else {
                this.props.dismissibleStack.remove(this.id);
            }
        }

        const isTop = this.isTopOfStack();
        const wasTopOfStack = prevProps.dismissibleStack.top() === this.id;

        if (isTop !== wasTopOfStack) {
            this.applyState(isTop);
        }
    }

    render() {
        const props = omit(this.props, [
            'enabled',
            'stacked',
            'stackGroup',
            'onDismissed',
            'ignoreNode',
            'propagate',
            'dismissibleStack',
            'dismissOnClick',
            'dismissOnKeyDown',
            'dismissOnScroll',
        ]);

        return (
            // @ts-expect-error - TS2322 - Type '{ children: ReactNode; ref: (element: HTMLDivElement | null) => void; className?: string | undefined; style?: Partial<{ position?: string | undefined; top?: number | undefined; ... 7 more ...; opacity?: number | undefined; }> | undefined; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.
            <div
                {...props}
                ref={(element) => {
                    this.element = element;
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default withDismissibleStack(Dismissible);
