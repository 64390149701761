import { useAdPublicContactInfo } from 'horizontal/ad/state';
import { ContactPersonRole } from 'horizontal/types';
import { useSellerProfile } from 'horizontal/seller/hooks';

const useAvailableCTAButtons = () => {
    const adPublicContactInfo = useAdPublicContactInfo();
    const seller = useSellerProfile();
    // #180759880 remove fallback User Settings after we migrate contactpersons
    const [allowChatCommunication, showPhoneNumber, showWhatsappPhoneNumber] =
        !adPublicContactInfo?.roles
            ? [seller?.allowChatCommunicationInAds, seller?.isPhoneNumberVisible, false]
            : [
                  adPublicContactInfo.roles?.includes(ContactPersonRole.ALLOW_CHAT_COMMUNICATION),
                  adPublicContactInfo.roles?.includes(ContactPersonRole.SHOW_PHONE_NUMBER),
                  adPublicContactInfo.roles?.includes(ContactPersonRole.SHOW_WHATSAPP_PHONE_BUTTON),
              ];
    return {
        allowChatCommunication,
        showPhoneNumber,
        showWhatsappPhoneNumber,
    };
};

export default useAvailableCTAButtons;
