import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import settings from '@app/branding/settings';
import { I18n } from '@lingui/core';
import { getItem, setItem } from 'strat/misc/sessionStorage';
import { selectSettingsInstallBanner } from 'strat/settings/selectors';
import { canonicalDomain as getCanonicalDomain } from 'strat/routing';
import { dismissBottomBanner } from 'strat/appInstallBanners';

import { useGetStoreInfo } from './useGetStoreInfo';

const SHOW_INSTALL_BANNER = 'show-install-banner';
const PREVIOUS_FIRST_VISIT_BOTTOM_BANNER_DISMISSAL = 'previous-first-visit-bottom-banner-dismissal';

const useAppInstallBanner = (adExternalID?: string) => {
    const [linkStore] = useGetStoreInfo({ adExternalID });
    const dispatch = useDispatch();
    const bannerIsAllowed = useSelector(selectSettingsInstallBanner);
    const installBannerLocalStorage =
        bannerIsAllowed && process.env.IS_BROWSER ? getItem(SHOW_INSTALL_BANNER) : false;
    const [showBanner, setShowBanner] = React.useState(installBannerLocalStorage || false);

    React.useEffect(() => {
        if (installBannerLocalStorage === null) {
            setItem(SHOW_INSTALL_BANNER, true);
            setShowBanner(true);
        }
    }, [installBannerLocalStorage]);

    const getSiteName = () => {
        // siteName is ‘dubizzle.com.eg’ in ‘https://www.dubizzle.com.eg’
        return getCanonicalDomain().domain;
    };

    const getAppName = (i18n: I18n) => {
        const brandName = settings.getBrandName(i18n);
        const appName = brandName === 'Dubizzle' ? brandName.toLocaleLowerCase() : brandName;
        return t(i18n)`${appName} app`;
    };

    const wasBannerPreviouslyDismissed = () => {
        const currentDate: Date = new Date();

        const recordedDateStr = getItem(PREVIOUS_FIRST_VISIT_BOTTOM_BANNER_DISMISSAL);
        const recordedDate = recordedDateStr ? new Date(recordedDateStr) : null;

        const wasDismissed =
            recordedDate !== null &&
            currentDate.getFullYear() === recordedDate.getFullYear() &&
            currentDate.getMonth() === recordedDate.getMonth() &&
            currentDate.getDate() === recordedDate.getDate();

        return wasDismissed;
    };

    const updateLastDismissalDate = () => {
        // The date is set to today's date when the user dismisses the banner,
        // this way we won't show the banner again until a new  day (new Date) is reached
        setItem(PREVIOUS_FIRST_VISIT_BOTTOM_BANNER_DISMISSAL, new Date());
    };

    const onCloseTopBanner = React.useCallback(() => {
        setItem(SHOW_INSTALL_BANNER, false);
        setShowBanner(false);
    }, []);

    const onCloseBottomBanner = React.useCallback(() => {
        dispatch(dismissBottomBanner());
        updateLastDismissalDate();
    }, [dispatch]);

    return {
        linkStore,
        onCloseTopBanner,
        onCloseBottomBanner,
        showBanner,
        getSiteName,
        getAppName,
        wasBannerPreviouslyDismissed,
    };
};

export default useAppInstallBanner;
