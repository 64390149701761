import { matchChoiceValue } from 'strat/util';

import { CategoryFieldValueType } from 'horizontal/types';
import { useFlatCategoryFields } from 'horizontal/categoryFields';
import type { MixedAd, AdKnownExtraFields } from 'horizontal/types';

const useAdExtraFieldLabel = <TKey extends keyof AdKnownExtraFields>(
    ad: MixedAd | null | undefined,
    attribute: TKey,
): AdKnownExtraFields[TKey] | null | undefined => {
    const categoryFields = useFlatCategoryFields(ad?.category?.slice(-1)?.[0]?.id, ad?.extraFields);

    if (!ad) {
        return null;
    }

    const field = categoryFields.find((innerField) => innerField.attribute === attribute);
    if (!field) {
        return null;
    }

    // Flow hack needed here because it doesn't understand that
    // `ad.extraFields[attribute]` is the same as `$ElementType<AdKnownExtraFields, attribute>`
    const value = ad.extraFields?.[attribute];
    if (!value) {
        return null;
    }

    switch (field.valueType) {
        case CategoryFieldValueType.ENUM: {
            const choice = (field.choices || []).find((innerChoice) =>
                matchChoiceValue(innerChoice, value),
            );

            if (!choice) {
                return null;
            }

            // Flow hack needed here because we have no way of telling that
            // the type for all ENUM fields become string because of the label.
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'AdKnownExtraFields[TKey]'.
            return choice.label;
        }

        default:
            return value;
    }
};

export default useAdExtraFieldLabel;
