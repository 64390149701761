import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { Dialog } from 'strat/modal';

import { selectDialogStack } from 'horizontal/dialogs/selectors';
import type { ChatMessageLocationContent } from 'horizontal/chat/types';
import IconClose from 'horizontal/assets/icons/iconClose.svg';

import Map from './map';
import styles from './styles/dialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly sendLocation: (location: ChatMessageLocationContent) => void;
};

const ShareLocationDialog = ({ visible, onVisibilityChanged, sendLocation }: Props) => {
    const { stacked, stackGroup } = useSelector(selectDialogStack);
    const onCloseClick = React.useCallback(
        (event: React.MouseEvent<SVGElement | HTMLButtonElement>) => {
            /**
             * This is needed when you are supporting stacking of
             * Dialogs which internally are using Dissmissible.
             * Dissmissible uses event listeners on the document:
             * document.addEventListener('click')
             * such events are `document` based, not React based.
             * So they can't be handled with our stopEventPropagation
             * function, which is meant for React synthetic events.
             */
            event.nativeEvent.stopImmediatePropagation();
            onVisibilityChanged(false);
        },
        [onVisibilityChanged],
    );
    const i18n = useI18n();

    const onSubmit = React.useCallback(
        (
            location: ChatMessageLocationContent,
            event: React.MouseEvent<HTMLButtonElement | SVGElement>,
        ) => {
            if (sendLocation) {
                sendLocation(location);
                onCloseClick(event);
            }
        },
        [sendLocation, onCloseClick],
    );

    if (!visible || !sendLocation) {
        return null;
    }

    return (
        <Dialog
            dismissible
            visible={visible}
            stacked={stacked}
            stackGroup={stackGroup}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.container}
        >
            <Flex alignCenter column justifyCenter className={styles.header}>
                <IconClose onClick={onCloseClick} className={styles.iconClose} />
                <Text.XLarge bold>{t(i18n)`Send location`}</Text.XLarge>
            </Flex>
            <Map onSubmit={onSubmit} />
        </Dialog>
    );
};

export default ShareLocationDialog;
