import { KeycloakChannel } from '@sector-labs/fe-auth-redux';

import { RequestCodeType } from './hooks/useSupportedOTPChannels';

export const getSupportedOTPChannels = (): Array<string> =>
    (CONFIG.runtime.STRAT_OLX_OTP_CHANNELS || '')
        .split(',')
        .map((item) => item.trim().toLowerCase())
        .filter((item) => [RequestCodeType.SMS, RequestCodeType.VOICE].includes(item));

export const isOTPChannelEnabled = (channel: Values<typeof RequestCodeType>) => {
    const enabledOTPChannels = getSupportedOTPChannels();
    return enabledOTPChannels.includes(channel);
};

export const isVoiceOTPFirst = () => {
    const [firstChannel] = getSupportedOTPChannels();
    return firstChannel === RequestCodeType.VOICE;
};

export const getDefaultOTPChannel = () => {
    const isSMSEnabled = isOTPChannelEnabled(RequestCodeType.SMS);
    return !isSMSEnabled || isVoiceOTPFirst() ? KeycloakChannel.CALL : KeycloakChannel.SMS;
};
