import * as React from 'react';
import classNames from 'classnames';

import * as Text from './text';
import type { Choice, ChoiceValue } from './types';
import SquareishButtonChoice from './squareishButtonChoice';
import styles from './styles/options.cssm';

export type RenderChoiceProps<T extends ChoiceValue> = {
    option: Choice<T>;
    isSelected: (arg1: T) => boolean;
    onChange: (arg1: T) => void;
    ariaLabel?: string;
    secondary?: boolean;
    compact?: boolean;
    isAutofilled?: boolean;
};

type Props<T extends ChoiceValue> = {
    readonly title?: string;
    readonly choices: Array<Choice<T>>;
    readonly onChange: (arg1: T) => void;
    readonly isSelected: (arg1: T) => boolean;
    readonly compact?: boolean;
    readonly errorMessage?: string;
    readonly renderChoice?: (arg1: RenderChoiceProps<T>) => React.ReactElement;
    readonly choiceAriaLabel?: string;
    readonly leftSideLabel?: boolean;
    readonly boldLabel?: boolean;
    readonly choicesClassNames?: string;
    readonly renderExpandOptions?: () => React.ReactNode;
    readonly autoFilledValues?: Array<T>;
    readonly readOnly?: boolean;
};

const Options = <T extends ChoiceValue>({
    title,
    choices,
    onChange,
    isSelected,
    errorMessage,
    compact,
    renderChoice = SquareishButtonChoice,
    choiceAriaLabel,
    leftSideLabel,
    boldLabel,
    autoFilledValues,
    renderExpandOptions,
    readOnly,
    choicesClassNames = styles.choices,
}: Props<T>) => {
    const inputWithInfo = (
        <>
            <div className={choicesClassNames}>
                {choices.map((option) =>
                    renderChoice({
                        option,
                        isSelected,
                        compact,
                        onChange,
                        ariaLabel: choiceAriaLabel,
                        isAutofilled: autoFilledValues?.includes(option.value),
                    }),
                )}
            </div>
            {errorMessage && (
                <span className={styles.errorMessage}>
                    <Text.Small>{errorMessage}</Text.Small>
                </span>
            )}
        </>
    );

    return (
        <div
            className={classNames({
                [styles.error]: !!errorMessage,
                [styles.containerLeftSideLabel]: leftSideLabel,
                [styles.readOnly]: readOnly,
            })}
        >
            {title && (
                <span
                    className={classNames(styles.title, {
                        [styles.leftSideLabel]: leftSideLabel,
                        [styles.boldLabel]: boldLabel,
                    })}
                >
                    <Text.Regular>{title}</Text.Regular>
                </span>
            )}
            {leftSideLabel ? (
                <div
                    className={classNames(styles.inputInfoContainer, {
                        [styles.withExpandedOptions]: renderExpandOptions,
                    })}
                >
                    {inputWithInfo}
                    {renderExpandOptions && renderExpandOptions()}
                </div>
            ) : (
                inputWithInfo
            )}
        </div>
    );
};
export default Options;
