import * as React from 'react';
import { Flex } from 'strat/components';

import styles from './styles/paymentSelectionTitle.cssm';

const PaymentTitle = ({ children }: { children: React.ReactNode }) => (
    <Flex className={styles.container}>
        <span className={styles.title}>{children}</span>
    </Flex>
);

export default PaymentTitle;
