//@ts-nocheck
// TODO: clean up this file and remove hack-ish semantics, re-enable flowtype

import urlParser from 'url';

import qs from 'qs';
import decodeURL from 'safe-decode-uri-component';

/**
 * A serialized version of a URL.
 */
export type Location = {
    pathname: string;
    search: string;
    hash: string;
};

export type Options = {
    sort?: boolean;
    encodePathname?: boolean;
};

/**
 * Enhanced version of the {@see Location} object that can accept
 * dictionaries and arrays for the `search` and `hash` parameters.
 */
export type EnhancedLocation = {
    pathname: string;
    search?: string | any | Array<any>;
    hash?: string | any | Array<any>;
};

/**
 * Takes an object and removes the keys that have empty values.
 * @param obj The object to remove the empty keys from.
 * @returns The input object with keys with empty values removed.
 */
const filterEmpty = (obj: any): any => {
    const newObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key]) {
            newObj[key] = obj[key];
        }
    });

    return newObj;
};

const parseLocation = (url: string | EnhancedLocation): EnhancedLocation => {
    // parse the specified url

    let location = url;
    if (typeof url === 'string') {
        location = urlParser.parse(location);
    }

    // parse normal query parameters from the url
    let queryParams = location.search;
    if (location.search && typeof location.search === 'string') {
        queryParams = qs.parse(location.search.substr(1), {
            indices: true,
            decoder: decodeURL,
        });
    }

    // parse query parameters encoded in the hash
    let hashQueryParams = location.hash;
    const validHashQueryParams =
        location.hash &&
        typeof location.hash === 'string' &&
        location.hash.length >= 2 &&
        location.hash[1] === '?';
    if (validHashQueryParams) {
        hashQueryParams = qs.parse(decodeURL(location.hash.substr(2)), {
            indices: true,
            decoder: decodeURL,
        });
    }

    // url.parse returns `null` for `pathname` when an empty string is
    // passed or the specified URL has an unknown protocol
    //
    // url.parse('android-app://domain.com').pathname == null
    // url.parse('').pathname == null
    //
    // this kind of behaviour does not occur when the protocol is a known
    // and supported protocol:
    //
    // url.parse('/http://domain.com').pathame == '/'
    //
    // we fix this by making sure the behaviour is the same regardless
    // of the protocol.. no path implies '/'
    const pathname = location.pathname ? decodeURL(location.pathname) : '/';

    return {
        ...location,
        pathname,
        search: queryParams,
        hash: hashQueryParams,
    };
};

/**
 * Takes a typical {@see window.location} object and serializes
 * it into a full-blown url.
 * @param location The location object to serialize.
 * @param options Additional options for serialize.
 * @returns A serialized version of the specified location object.
 */
const serializeLocation = (
    location: string | EnhancedLocation,
    options: Options = { sort: false, encodePathname: false },
): string => {
    const parsedUrl = parseLocation(location);
    let url = encodeURI(parsedUrl.pathname);
    if (options.encodePathname) {
        url = url.replace(/&/g, '%26');
    }

    const serializeItem = (item: any) => {
        if (typeof item === 'string') {
            if (item.startsWith('?')) {
                return item.substr(1);
            }
            return item;
        } else if (Array.isArray(item)) {
            const serializedItems = item.map((innerItem) => serializeItem(innerItem));

            return serializedItems.join('&');
        }

        const filteredObj = filterEmpty(item);
        if (Object.keys(filteredObj).length === 0) {
            return '';
        }

        const stringifyOptions = {
            indices: true,
        } as const;

        if (options.sort) {
            stringifyOptions.sort = (a: any, b: any) => a.localeCompare(b);
        }

        return qs.stringify(filteredObj, stringifyOptions);
    };

    if (parsedUrl.search) {
        const serializedItem = serializeItem(parsedUrl.search);
        if (serializedItem.length > 0) {
            url += `?${serializedItem}`;
        }
    }

    if (parsedUrl.hash) {
        const serializedItem = serializeItem(parsedUrl.hash);
        if (serializedItem.length > 0) {
            url += `#?${serializedItem}`;
        }
    }

    return url;
};

/**
 * Turns an absolute location into a relative URL (this implies serializing
 * the specified location).
 */
const makeRelativeURL = (location: string | EnhancedLocation): string =>
    serializeLocation(parseLocation(location));

export { serializeLocation, parseLocation, makeRelativeURL, decodeURL };
