const patterns = {
    locationSlug: '([A-z0-9-_]*)',
    locationExternalID: '([0-9]+[-]?[0-9]?)',
    categorySlugWithOptionalPrefix: '([A-z-]*)',
    categoryExternalID: '([0-9]+)',
    freeTextQuery: `(?:/q-([A-z0-9-&.\\.,'"\\+\\^\\=\\+\\]\\*!;\\$\\(]+))?`,
    optionalSlashAndParams: '/?(?:(?:\\?|#).*)?$',
} as const;

const defaultRoutePatterns = [
    [
        '^/',
        { name: 'locationSlug', pattern: patterns.locationSlug },
        '_g',
        { name: 'locationExternalID', pattern: patterns.locationExternalID },
        '/',
        {
            name: 'categorySlugWithOptionalPrefix',
            pattern: patterns.categorySlugWithOptionalPrefix,
        },
        '_c',
        { name: 'categoryExternalID', pattern: patterns.categoryExternalID },
        { name: 'freeTextQuery', pattern: patterns.freeTextQuery },
        patterns.optionalSlashAndParams,
    ],
    [
        '^/',
        {
            name: 'categorySlugWithOptionalPrefix',
            pattern: patterns.categorySlugWithOptionalPrefix,
        },
        '_c',
        { name: 'categoryExternalID', pattern: patterns.categoryExternalID },
        { name: 'freeTextQuery', pattern: patterns.freeTextQuery },
        patterns.optionalSlashAndParams,
    ],
    [
        '^/',
        { name: 'locationSlug', pattern: patterns.locationSlug },
        '_g',
        { name: 'locationExternalID', pattern: patterns.locationExternalID },
        { name: 'freeTextQuery', pattern: patterns.freeTextQuery },
        patterns.optionalSlashAndParams,
    ],
    ['^/items/?(?:(?:\\?|#).*)?$'],
    [
        '^/items',
        { name: 'freeTextQuery', pattern: patterns.freeTextQuery },
        patterns.optionalSlashAndParams,
    ],
];

export default defaultRoutePatterns;
