import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
    reserveUserByEmail,
    addUnverifiedEmail,
    loginWithAuthorizationCode,
} from '@sector-labs/fe-auth-redux/thunk';

import type { GlobalState } from 'strat/state';
import { ContextfulError } from 'strat/error/context';
import { selectUserProfile } from 'strat/user/selectors';
import { selectLanguage } from 'strat/i18n/language';
import { selectDriftCorrectedClock } from 'strat/clock/selectors';

import type { EnsuredUserData, EnsureUserWithEmailOptions } from '../types';

import { createKeycloakSiteConfigWithCallbacks } from './keycloakSiteConfig';

export const ensureUserWithEmail =
    (
        email: string,
        options: EnsureUserWithEmailOptions = {},
    ): ThunkAction<Promise<EnsuredUserData>, GlobalState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const state = getState();

        const languageCode = selectLanguage(state);
        const clock = selectDriftCorrectedClock(state);
        const loggedInUserProfile = selectUserProfile(state);

        const siteConfig = createKeycloakSiteConfigWithCallbacks({ languageCode, clock }, dispatch);

        if (loggedInUserProfile) {
            const ensuredUserData = {
                externalID: loggedInUserProfile.id,
                email,
                isNewlyRegistered: false,
            };

            if (!loggedInUserProfile.email) {
                return dispatch(addUnverifiedEmail(siteConfig, { email }))
                    .catch((e) => {
                        if (!options.silentlyFail) {
                            throw e;
                        }

                        return ensuredUserData;
                    })
                    .then(() => ensuredUserData);
            }

            return Promise.resolve(ensuredUserData);
        }

        return dispatch(reserveUserByEmail(siteConfig, { email }))
            .then((userReservation) => {
                if (!userReservation) {
                    throw new ContextfulError('User reservation is unexpectely null', { email });
                }

                const ensuredUserData = {
                    externalID: userReservation.externalID,
                    email,
                    isNewlyRegistered: userReservation.isNewlyRegistered,
                };

                if (userReservation.isNewlyRegistered && userReservation.authorizationCode) {
                    return dispatch(
                        loginWithAuthorizationCode(siteConfig, {
                            authorizationCode: userReservation.authorizationCode,
                        }),
                    )
                        .then(() => ensuredUserData)
                        .catch((e) => {
                            if (!options.silentlyFail) {
                                throw e;
                            }

                            return ensuredUserData;
                        });
                }

                return ensuredUserData;
            })
            .catch((e) => {
                if (!options.silentlyFail) {
                    throw e;
                }

                return {
                    externalID: '',
                    email,
                    isNewlyRegistered: false,
                };
            });
    };
