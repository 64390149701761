import { t } from '@lingui/macro';
import * as React from 'react';

import { useNavigateToAdDetails } from 'horizontal/adDetails';
import { trackClickPreviewAd } from 'horizontal/gtm';
import type { FullAd, ProductPackageOffer } from 'horizontal/types';
import SelectSingleOfferPackage from '@app/packages/individual/selectSingleOfferPackage';
import UpSellingAdSuccessBanner from 'horizontal/adCreation/compact/upSellingAdSuccessBanner';

import { PackagesFlow, PackagesFlowTypeValues } from '../types';
import useConfigureOfferPackagesSections from '../useConfigureOfferPackagesSections';
import useDefaultPackageSelection from '../useDefaultPackageSelection';

import useSinglePackages from './useSinglePackages';

type Props = {
    readonly offerPackages?: Array<ProductPackageOffer> | null;
    readonly ad?: FullAd | null;
    readonly flow: PackagesFlowTypeValues;
    readonly onViewAdButtonClick?: () => void;
};

const SelectSingleVASOfferPackage = ({ offerPackages, flow, ad, onViewAdButtonClick }: Props) => {
    const offerPackagesSections = useConfigureOfferPackagesSections(offerPackages, flow);
    const defaultOfferSelection = useDefaultPackageSelection(offerPackagesSections);

    const {
        i18n,
        selectedValue,
        handleClick,
        activeExample,
        openExample,
        closeExample,
        onCheckoutSinglePackages,
        loading,
        checkoutLoading,
        errorMessage,
    } = useSinglePackages(offerPackages, ad, defaultOfferSelection);

    const navigateToAdDetails = useNavigateToAdDetails(ad, trackClickPreviewAd);
    const viewAd = onViewAdButtonClick || navigateToAdDetails;

    const renderBanner = () => {
        if (flow === PackagesFlow.UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY) {
            return <UpSellingAdSuccessBanner />;
        }

        return null;
    };

    const mainCTA = {
        text: t(i18n)`Proceed to pay`,
        action: onCheckoutSinglePackages,
    };

    return (
        <SelectSingleOfferPackage
            loading={loading || checkoutLoading}
            activeExample={activeExample}
            closeExample={closeExample}
            offerPackages={offerPackages}
            offerPackagesSections={offerPackagesSections}
            ad={ad}
            selectedValue={selectedValue}
            flow={flow}
            viewAd={viewAd}
            onItemClicked={handleClick}
            openExample={openExample}
            mainCTA={mainCTA}
            renderBanner={renderBanner}
            errorMessage={errorMessage}
        />
    );
};

export default SelectSingleVASOfferPackage;
