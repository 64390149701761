import * as React from 'react';
import { useRouter } from 'react-true-router';
import Logo from '@app/branding/logo';
import { RouteNames } from 'strat/routes';
import { BackButton, Header } from 'strat/components';

import SinglePackages from 'horizontal/packages/individual';
import type { FullAd, ProductPackageOffer } from 'horizontal/types';
import { GTMSelectSinglePackageDataLayer } from 'horizontal/gtm/components';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/selectSinglePackagePageDesktop.cssm';

const SelectSinglePackagePageHeader = () => {
    const router = useRouter();
    const onBack = React.useCallback(() => {
        router.pushRoute(RouteNames.AD_MANAGEMENT);
    }, [router]);

    return (
        <Header className={styles.header}>
            <BackButton onBack={onBack} />
            <Logo className={styles.logo} />
        </Header>
    );
};

type Props = {
    readonly offerPackages?: Array<ProductPackageOffer> | null;
    readonly ad?: FullAd | null;
    readonly withoutSkipButton: boolean;
};

const SelectSinglePackagePage = {
    head: ({ offerPackages, ad }: Props) => (
        <>
            <PrivateNonIndexableHead />
            <GTMSelectSinglePackageDataLayer packages={offerPackages} ad={ad} />
        </>
    ),
    body: ({ offerPackages, ad, withoutSkipButton }: Props) => (
        <>
            <SelectSinglePackagePageHeader />
            <SinglePackages
                packages={offerPackages}
                ad={ad}
                withoutSkipButton={withoutSkipButton}
            />
        </>
    ),
} as const;

export default SelectSinglePackagePage;
