import userAgenciesReducer, { fetchUserAgencies, type FetchUserAgencyParams } from './state';
import { selectUserAgencies, selectUserAgenciesLoaded } from './selectors';
import { useIsProOwnerAgent, useIsAnyTypeOfAgent, useIsProAgent } from './hooks';
import type { UserAgency } from './types';

export {
    fetchUserAgencies,
    selectUserAgencies,
    selectUserAgenciesLoaded,
    useIsProOwnerAgent,
    useIsProAgent,
    useIsAnyTypeOfAgent,
    type UserAgency,
    type FetchUserAgencyParams,
};

export default userAgenciesReducer;
