import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { RangeFilterType } from '../types';
import { areaFilterMaximumOptions, areaFilterMinimumOptions } from '../constants';

import RangeFilter from './verticalRangeFilter';

type Props = {
    readonly area: RangeFilterType;
    readonly setArea: (area: RangeFilterType) => void;
    readonly titleClassname?: string;
};

const AreaRangeFilter = ({ area, setArea, titleClassname }: Props) => {
    const i18n = useI18n();

    return (
        <RangeFilter
            maxChoices={areaFilterMaximumOptions}
            minChoices={areaFilterMinimumOptions}
            placeholderMax={t(i18n)`Any`}
            placeholderMin={'0'}
            value={area}
            filterUnit={t(i18n)`m²`}
            onValueSelected={(value) => setArea(value)}
            title={t(i18n)`Area`}
            titleClassname={titleClassname}
        />
    );
};

export default AreaRangeFilter;
