import type { I18n } from '@lingui/core';
import { setupI18n as linguiSetupI18n } from '@lingui/core';

import { localesFromLanguage } from './locales';

const setupI18n = (language: string, catalogs: Record<string, Record<string, string>>): I18n => {
    const locales = localesFromLanguage(language);

    const i18n = linguiSetupI18n({ locale: language, messages: catalogs, locales: [locales] });
    i18n.activate(language, locales);

    return i18n;
};

export default setupI18n;
