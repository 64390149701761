import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import NoHitsImage from './noHitsImage';
import ExpandLocationRadius from './expandLocationRadius';
import styles from './styles/noHits.cssm';

const NoHits = () => {
    const i18n = useI18n();
    return (
        <Flex justifyCenter column className={styles.container}>
            <h3 className={styles.title}>
                {t(i18n)`Oops... we didn't find anything that matches this search`}
            </h3>
            <Text.Large>{t(
                i18n,
            )`Try to search for something more general, change the filters or check for spelling mistakes`}</Text.Large>
            <NoHitsImage />
            <ExpandLocationRadius />
        </Flex>
    );
};

export default NoHits;
