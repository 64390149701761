import { isCanceled, makeCancelable } from 'strat/util';
import { CancelableRoute } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';
import { PaymentOrderStatus, PaymentStatus } from 'horizontal/payment';
import { fetchWalletBalance } from 'horizontal/payment/wallet/state';
import { getStratAPI } from 'horizontal/api';
import { selectBusinessPackageCartOfferAdID } from 'horizontal/businessPackages/selectors';
import { ProductPurchaseStatus } from 'horizontal/packages';
import { fetchProductPurchases } from 'horizontal/packages/state';
import { fetchAd } from 'horizontal/ad/state';
import { AdState } from 'horizontal/types';
import { fetchFreeAdLimitUsage } from 'horizontal/adLimits/state';
import type { PaymentOrder } from 'horizontal/payment/types';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type PaymentSuccessRouteParams = {
    readonly orderExternalID: string;
    readonly adExternalID?: string | null;
    readonly status?: Values<typeof PaymentStatus>;
};

type QueryParams = {
    adExternalID?: string;
    status?: string;
    instrumentID?: string;
};

type OrderResponseType = {
    data: {
        status: Values<typeof PaymentStatus>;
        data: Array<PaymentOrder>;
    };
    status: number;
};

class PaymentSuccessRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.PAYMENT_SUCCESS, [
            [
                '^/payments/packages/consume/ALL/success/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '/payment',
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { orderExternalID, adExternalID, status }: PaymentSuccessRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams: QueryParams = {};
        if (status) {
            queryParams['status'] = status;
        }
        if (adExternalID) {
            queryParams['adExternalID'] = adExternalID;
        }
        const pathname = `/payments/packages/consume/ALL/success/${orderExternalID}/payment`;
        return { pathname, search: queryParams };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const {
            match: {
                params: { adExternalID, orderExternalID },
            },
            redux: {
                store: { getState, dispatch },
            },
        } = context;

        let lastRegisteredAdExternalID;
        /**
         * ad ID can only be defined on the browser because it is stored
         * using localStorage before the cart order is started.
         */
        if (process.env.IS_BROWSER) {
            lastRegisteredAdExternalID = selectBusinessPackageCartOfferAdID(getState());
        }
        const computedAdID = adExternalID || lastRegisteredAdExternalID;
        const isAdOrderPurchase =
            CONFIG.runtime.STRAT_ENABLE_AFTER_PAYMENT_PROCESSING_PAGE_REDIRECTION && computedAdID;

        const promises = [
            getStratAPI(getState()).getOrder(orderExternalID),
            dispatch(fetchWalletBalance()),
        ];
        if (isAdOrderPurchase) {
            promises.push(
                // if the user refreshes the site, then this request should be done from a useEffect.
                this.cancelable(
                    dispatch(
                        fetchProductPurchases({
                            status: ProductPurchaseStatus.ACTIVE,
                            adExternalID: computedAdID,
                        }),
                    ),
                ),
                this.cancelable(dispatch(fetchAd({ externalID: computedAdID }))),
                this.cancelable(dispatch(fetchFreeAdLimitUsage())),
            );
        }

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSuccessRoute'.
        this.cancelablePromise = makeCancelable(Promise.all(promises));

        const getAdData = () => getState().ad.data;
        const getOffersData = () => getState().productPurchases.data;
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSuccessRoute'.
        const promise = this.cancelablePromise.then(
            ([orderResponse]: [OrderResponseType]) => {
                const status = orderResponse.status;
                const paymentStatus = orderResponse?.data.status;
                const orderData = orderResponse?.data;
                if (status !== 200 && paymentStatus !== PaymentOrderStatus.ACTIVE) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.NOT_FOUND);
                    return;
                }
                /**
                 * 1- we check that isAdOrderPurchase was true so that we know redux doesn't have
                 * cached content from previous API calls.
                 * 2- We check ad state because in PK you can pay a fee for an ad that eventually
                 * deactivates that ad, this is the Free Ad Comission Fee. Once that fee is paid
                 * the ad is set to inactive/deleted/sold.
                 */
                const ad = getAdData();
                let adProcessingData = {};
                if (
                    isAdOrderPurchase &&
                    [AdState.ACTIVE, AdState.PENDING, AdState.LIMITED].includes(ad.state)
                ) {
                    const packageOffers = getOffersData();
                    adProcessingData = { packageOffers, ad };
                }

                context.rendering.renderPage(Page.PAYMENT_SUCCESS, {
                    ...orderData,
                    ...adProcessingData,
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }
}

export default new PaymentSuccessRoute();
