import * as React from 'react';
import classNames from 'classnames';

import * as Text from './text';
import styles from './styles/button.cssm';

type Props = {
    readonly children: React.ReactNode;
    readonly secondary?: boolean;
    readonly tertiary?: boolean;
    readonly action?: boolean;
    readonly cta?: boolean;
    readonly danger?: boolean;
    readonly loading?: boolean;
    readonly small?: boolean;
    readonly smallIcon?: boolean;
    readonly largeIcon?: boolean;
    readonly mask?: boolean;
    readonly stretch?: boolean;
    readonly stretchContent?: boolean;
    readonly outstanding?: boolean;
    readonly renderIcon?: (className: string) => React.ReactNode;
    readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly disabled?: boolean;
    readonly borderless?: boolean;
    readonly ariaLabel?: string;
    readonly type?: 'button' | 'reset' | 'submit';
    readonly className?: string;
};

const Button = ({
    children,
    secondary,
    tertiary,
    action,
    cta,
    danger,
    loading,
    small,
    smallIcon,
    largeIcon,
    mask,
    stretch,
    stretchContent,
    outstanding,
    renderIcon,
    onClick,
    disabled,
    borderless,
    ariaLabel,
    type,
    className = styles.button,
}: Props) => {
    return (
        <button
            disabled={disabled}
            aria-label={ariaLabel}
            className={classNames(className, {
                [styles.secondary]: secondary,
                [styles.tertiary]: tertiary,
                [styles.action]: action,
                [styles.cta]: cta,
                [styles.danger]: danger,
                [styles.loading]: loading,
                [styles.mask]: mask,
                [styles.stretch]: stretch,
                [styles.borderless]: borderless,
                [styles.largeIcon]: largeIcon,
                [styles.outstanding]: outstanding,
                [styles.small]: small,
            })}
            onClick={onClick}
            type={type || 'submit'}
        >
            {renderIcon &&
                renderIcon(
                    classNames(styles.icon, {
                        [styles.cta]: cta,
                        [styles.secondary]: secondary,
                        [styles.mask]: mask,
                        [styles.largeIcon]: largeIcon,
                        [styles.smallIcon]: smallIcon,
                        [styles.disabled]: disabled,
                    }),
                )}
            <Text.Base
                bold
                className={classNames(styles.label, {
                    [styles.stretch]: stretchContent,
                    [styles.largeIcon]: largeIcon,
                    [styles.small]: small,
                    [styles.tertiary]: tertiary,
                })}
            >
                {children}
            </Text.Base>
        </button>
    );
};

export default Button;
