import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { useSelector } from 'react-redux';
import { selectGeoLocationData } from 'strat/user/selectors';
import brandingSettings from '@app/branding/settings';

import type { Category, Location } from 'horizontal/types';
import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';
import IconClose from 'horizontal/assets/icons/iconClose.svg';
import { useLocationFilterValue } from 'horizontal/search/location';
import { selectLocationNameWithParent } from 'horizontal/location';

import Chip from './chip';
import styles from './styles/filterChips.cssm';

type Props = {
    readonly category: Category | null | undefined;
    readonly onCategoryDismiss: () => void;
    readonly location: Location | null;
    readonly openFilters: () => void;
    readonly additionalFiltersCount: number;
};

const FilterChips = ({
    category,
    onCategoryDismiss,
    location: routeParamLocation,
    openFilters,
    additionalFiltersCount,
}: Props) => {
    const i18n = useI18n();
    const { closestLocation } = useSelector(selectGeoLocationData);
    const locationFilterValue = useLocationFilterValue();
    const location = routeParamLocation || closestLocation || locationFilterValue;

    return (
        <Flex className={styles.chipsContainer}>
            <Chip
                title={
                    location?.name ||
                    selectLocationNameWithParent(i18n, [brandingSettings.topLevelLocation])
                }
                renderIcon={() => (
                    <IconArrowDown className={styles.iconArrow} onClick={openFilters} />
                )}
            />
            {category && (
                <Chip
                    title={category.name}
                    renderIcon={() => (
                        <IconClose className={styles.iconClose} onClick={onCategoryDismiss} />
                    )}
                />
            )}
            {!!additionalFiltersCount && (
                <Chip title={t(i18n)`+${additionalFiltersCount} more`} onClick={openFilters} />
            )}
        </Flex>
    );
};

export default FilterChips;
