import { createSelector } from 'reselect';

import EMPTY_OBJECT from 'strat/empty/object';

const selectInternalLinks = (state: any) => state?.internalLinks ?? EMPTY_OBJECT;

const selectInternalLinksHaveLoaded = createSelector(
    selectInternalLinks,
    (internalLinks) => internalLinks?.loaded ?? false,
);

export { selectInternalLinks, selectInternalLinksHaveLoaded };
