import { useSelector } from 'react-redux';
import { createLoginURL } from '@sector-labs/fe-auth-redux/keycloak-client';

import { useLanguage } from 'strat/i18n/language';
import { selectDriftCorrectedClock } from 'strat/clock/selectors';

import { createKeycloakConfig } from '../config';
import { LoginType, type AuthFlow } from '../../types';
import type { ThirdPartyProvider } from '../types';
import determineKeycloakThirdPartyProviderID from '../determineKeycloakThirdPartyProviderID';

import useKeycloakThirdPartyLoginRedirectURI from './useKeycloakThirdPartyLoginRedirectURI';

const useKeycloakThirdPartyLoginURL = (
    provider: ThirdPartyProvider,
    loginType: LoginType,
    authFlow: AuthFlow,
    subscribeToMarketingEmails?: boolean,
): string => {
    const languageCode = useLanguage();
    const clock = useSelector(selectDriftCorrectedClock);

    const redirectURI = useKeycloakThirdPartyLoginRedirectURI(
        loginType,
        authFlow,
        subscribeToMarketingEmails,
    );
    const keycloakConfig = createKeycloakConfig({ languageCode, clock });
    const idpHint = determineKeycloakThirdPartyProviderID(provider);

    const additionalQueryParameters: Record<string, unknown> = {};
    if (loginType == LoginType.FACEBOOK_POPUP) {
        additionalQueryParameters['display'] = 'popup';
    }

    return createLoginURL(keycloakConfig, { redirectURI, idpHint, additionalQueryParameters });
};

export default useKeycloakThirdPartyLoginURL;
