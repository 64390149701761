import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';

import { ChatRoomDisabledReason, ChatRoomReadOnlyReason } from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoom, ChatRoomDisabledInfo } from 'horizontal/chat/types';
import { DialogNames } from 'horizontal/dialogs';
import { useDefaultUserName } from 'horizontal/user';

import useDisabledChatMessage from '../useDisabledChatMessage';

import styles from './styles/disabledChat.cssm';

type Props = {
    conversation: ChatRoom;
    disabledInfo: ChatRoomDisabledInfo;
};

const DisabledChat = ({ disabledInfo, conversation }: Props) => {
    const sdk = useChatSDK();
    const deleteRoom = sdk.useDeleteChatRoom(conversation.xid);
    const blockUser = sdk.useUpdateUserBlocking(conversation.contactXID);
    const { contactName: loadedContactName, ad } = sdk.useRoomContactInfo(conversation);
    const defaultContactName = useDefaultUserName();
    const contactName = loadedContactName || defaultContactName;

    const disabledMessage = useDisabledChatMessage(disabledInfo);
    const shouldShowUnblock =
        disabledInfo.reason === ChatRoomDisabledReason.BLOCKED_BY_CURRENT_USER;

    const isDraftRoom = !conversation.xid;

    const [, setBlockUserDialogVisible] = useDialog(DialogNames.CHAT_BLOCK_USER_DIALOG);

    const readonly = conversation?.readOnly === ChatRoomReadOnlyReason.READONLY_ROLE;

    const onUnblockClick = React.useCallback(() => {
        setBlockUserDialogVisible(true, {
            userName: contactName,
            shouldBlock: false,
            onClick: () => blockUser(false),
            ad,
        });
    }, [setBlockUserDialogVisible, contactName, blockUser, ad]);

    return (
        <Flex justifySpaceBetween className={styles.container}>
            <Text.Base className={styles.text}>{disabledMessage}</Text.Base>
            <Flex justifySpaceAround>
                {shouldShowUnblock && !readonly && (
                    <Text.Base uppercase bold className={styles.button} onClick={onUnblockClick}>
                        <Trans>Unblock</Trans>
                    </Text.Base>
                )}
                {!isDraftRoom && !readonly && (
                    <Text.Base uppercase bold className={styles.button} onClick={deleteRoom}>
                        <Trans>Delete</Trans>
                    </Text.Base>
                )}
            </Flex>
        </Flex>
    );
};

export default DisabledChat;
