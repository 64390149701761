import { createSelector } from 'reselect';

import EMPTY_OBJECT from 'strat/empty/object';
import { GlobalState } from 'strat/state';

const selectSettings = (state: GlobalState) => state.settings || EMPTY_OBJECT;

const selectSettingsArea = createSelector(selectSettings, (settings) => settings.area);

const selectSearchHitsLayout = createSelector(
    selectSettings,
    (settings) => settings.searchHitsLayout,
);

const selectSettingsInstallBanner = createSelector(
    selectSettings,
    (settings) => settings.installBanner,
);

export { selectSettings, selectSettingsArea, selectSearchHitsLayout, selectSettingsInstallBanner };
