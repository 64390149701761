import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { Text, Button } from 'strat/components';

import {
    useLocationRadiusOptions,
    useLocationRadiusFilterValue,
    getLocationRadiusOptionName,
    useWithLocationRadiusFilter,
    useRefineLocationRadiusFilter,
} from 'horizontal/search/location';

import styles from './styles/expandLocationRadius.cssm';

type Props = {
    locationLevel?: number;
};

const ExpandLocationRadius = ({ locationLevel = 1 }: Props) => {
    const i18n = useI18n();
    const options = useLocationRadiusOptions();
    const locationRadius = useLocationRadiusFilterValue();
    const withLocationRadiusFilter = useWithLocationRadiusFilter(locationLevel);
    const refineLocationRadius = useRefineLocationRadiusFilter();

    const expandOptions = options.filter((option) => option > locationRadius).slice(0, 3);

    if (
        CONFIG.runtime.STRAT_ENABLE_AUTOMATIC_EXPANDED_RADIUS ||
        !withLocationRadiusFilter ||
        !expandOptions.length
    ) {
        return null;
    }

    return (
        <Flex column alignCenter className={styles.container} aria-label="Radius filter">
            <Text.Base className={styles.text}>
                <Trans>Expand your search and browse in a different area</Trans>
            </Text.Base>
            <Flex justifyCenter className={styles.buttonsContainer}>
                {expandOptions.map((option) => (
                    <Button
                        key={option}
                        className={styles.button}
                        onClick={() => refineLocationRadius(option)}
                    >
                        <Text.Regular>{getLocationRadiusOptionName(i18n, option)}</Text.Regular>
                    </Button>
                ))}
            </Flex>
        </Flex>
    );
};

export default ExpandLocationRadius;
