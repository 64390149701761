import * as React from 'react';
import { useSearchHits } from '@sector-labs/fe-search-redux/state';
import { useSelector } from 'react-redux';
import { getSellerType, trigger, TriggerName, Triggers, ViewSections } from 'strat/gtm';
import { selectAnonymousSessionID } from 'strat/user/selectors';
import { useActiveUser, useActiveUserExternalID } from 'strat/user/session';
import { TrackChatLead } from 'strat/gtm/withGTMLeadTracking';

import { useSellerProfile } from 'horizontal/seller/hooks';
import { useAgencyProfile } from 'horizontal/agency/state';
import type { ChatGTMVarsProps } from 'horizontal/chat/types';
import type { PaymentOrder } from 'horizontal/payment';
import { AdFormat } from 'horizontal/types';
import { ChatMessageType } from 'horizontal/chat/constants';
import { ChatSendMessageResult } from 'horizontal/chat/types';
import useChatGTMVarsProps from 'horizontal/chat/useChatGTMVarsProps';

import { getAdVars } from './utils';

const useChatGTMVars = ({
    chatRoom,
    ad,
}: ChatGTMVarsProps):
    | {
          [key: string]: any;
      }
    | null
    | undefined => {
    const userExternalId = useActiveUserExternalID();
    const anonymousSessionID = useSelector(selectAnonymousSessionID);

    if (!chatRoom || !ad) {
        return undefined;
    }

    return {
        seller_id: chatRoom.sellerId,
        buyer_id: chatRoom.buyerId,
        ad_id: chatRoom.adId,
        view_section: ViewSections.BODY,
        ...getAdVars(ad),
        number_of_photos: ad.format === AdFormat.FULL ? ad?.photos?.length : 0,
        user_id: userExternalId || anonymousSessionID,
    };
};

const useSellerProfileGTMVars = () => {
    const seller = useSellerProfile();
    const agency = useAgencyProfile();
    const userId = useActiveUserExternalID();
    const hits = useSearchHits();
    const anonymousSessionID = useSelector(selectAnonymousSessionID);

    return {
        user_id: userId || anonymousSessionID,
        seller_id: seller?.externalID,
        // @ts-expect-error - TS2345 - Argument of type 'SearchResponseHit' is not assignable to parameter of type 'PropertyData'.
        seller_type: hits.length > 0 ? getSellerType(hits[0]) : null,
        company_ids: agency?.externalID,
    };
};

const useTrackOfferSent = (props: ChatGTMVarsProps) => {
    const chatVars = useChatGTMVars(props);

    return React.useCallback(
        (offerValue?: number | null) => {
            if (!chatVars) {
                return;
            }
            trigger(Triggers.MAKE_AN_OFFER_SENT, {
                ...chatVars,
                filter_name: 'offer-value-sent',
                filter_value: offerValue,
            });
        },
        [chatVars],
    );
};

const useTrackOfferOption = (props: ChatGTMVarsProps) => {
    const chatVars = useChatGTMVars(props);

    return React.useCallback(
        (offerValue?: number | null) => {
            if (!chatVars) {
                return;
            }
            trigger(Triggers.MAKE_AN_OFFER, {
                ...chatVars,
                filter_name: 'offer-value',
                filter_value: offerValue,
            });
        },
        [chatVars],
    );
};

const useTrackChatReply = (props: ChatGTMVarsProps) => {
    const chatVars = useChatGTMVars(props);

    return React.useCallback(() => {
        if (!chatVars) {
            return;
        }
        trigger(Triggers.CHAT_MESSAGE_RECEIVED, { ...chatVars });
    }, [chatVars]);
};

const useTrackMakeAnOffer = (props: ChatGTMVarsProps) => {
    const chatVars = useChatGTMVars(props);

    return React.useCallback(() => {
        if (!chatVars) {
            return;
        }
        trigger(Triggers.MAKE_AN_OFFER, { ...chatVars });
    }, [chatVars]);
};

const useTrackChatInit = () => {
    return React.useCallback(() => {
        trigger(Triggers.CHAT_INIT);
    }, []);
};

const useTrackSentChatMessageEvents = (trackChatLead: TrackChatLead) => {
    const gtmProps = useChatGTMVarsProps();
    const user = useActiveUser();
    const chatVars = useChatGTMVars(gtmProps);
    return React.useCallback(
        (
            { trackingActions: { isChatLead, hasSellerResponded } }: ChatSendMessageResult,
            chatMessageType: ChatMessageType,
        ) => {
            if (!chatVars) {
                return;
            }
            if (isChatLead) {
                // first non-system message sent by the buyer
                trigger(Triggers.CHAT_MESSAGE_SENT, chatVars);
                trackChatLead(
                    ViewSections.PROPERTY_DETAIL,
                    user?.name ?? '',
                    user?.email ?? '',
                    user?.phoneNumber,
                    null,
                    chatMessageType,
                );
            }
            if (hasSellerResponded) {
                // first non-system message sent by the seller
                trigger(Triggers.SELLER_FIRST_RESPONSE, chatVars);
            }
            if (chatVars.seller_id === chatVars.user_id) {
                trigger(Triggers.SELLER_SENT_MESSAGE, chatVars);
            } else {
                trigger(Triggers.BUYER_SENT_MESSAGE, chatVars);
            }
        },
        [chatVars, trackChatLead, user],
    );
};

const useTrackReportAbuse = () => {
    const sellerProfileVars = useSellerProfileGTMVars();

    return React.useCallback(() => {
        if (!sellerProfileVars) {
            return;
        }

        trigger(Triggers.REPORT_ABUSE, sellerProfileVars);
    }, [sellerProfileVars]);
};

const useTrackShareProfileLink = () => {
    const sellerProfileVars = useSellerProfileGTMVars();

    return React.useCallback(() => {
        if (!sellerProfileVars) {
            return;
        }

        trigger(Triggers.SHARE_PROFILE_LINK, sellerProfileVars);
    }, [sellerProfileVars]);
};

const useTrackClickVertical = () =>
    React.useCallback((verticalSelected: string) => {
        trigger(Triggers.CLICK_VERTICAL, {
            name: TriggerName.VERTICAL_SELECTED,
            value: verticalSelected,
        });
    }, []);

const trackPaymentFailed = (
    {
        externalID,
        productIDs,
        total,
        grossTotal,
        adExternalID,
        latestPaymentResponseSummary,
        paymentMethods,
    }: PaymentOrder,
    selectedCategories: any,
    failedPaymentMessage?: string,
) => {
    trigger(Triggers.PAYMENT_FAILED, {
        view_section: ViewSections.BODY,
        payment_method: paymentMethods?.join(' + '),
        name: TriggerName.PAYMENT_FAILED,
        value: latestPaymentResponseSummary || failedPaymentMessage,
        order_id: externalID,
        product_id: productIDs,
        payment_amount: grossTotal || total,
        ad_id: adExternalID,
        ...selectedCategories,
    });
};

const trackPaymentInitiated = (
    { productIDs, total, grossTotal, adExternalID }: PaymentOrder,
    selectedCategories: any,
    paymentMethod: string,
) => {
    trigger(Triggers.PAYMENT_INITIATED, {
        payment_method: paymentMethod,
        view_section: ViewSections.BODY,
        name: TriggerName.PAYMENT_INITIATED,
        value: paymentMethod,
        product_id: productIDs,
        payment_amount: grossTotal || total,
        ad_id: adExternalID,
        ...selectedCategories,
    });
};

const trackBannerClickAction = (triggerName: string, agencyExternalID: string | null = null) => {
    trigger(triggerName, {
        company_ids: agencyExternalID,
    });
};

export {
    useTrackOfferSent,
    useTrackChatReply,
    trackBannerClickAction,
    useTrackMakeAnOffer,
    useTrackOfferOption,
    useTrackChatInit,
    useTrackSentChatMessageEvents,
    useTrackReportAbuse,
    useTrackShareProfileLink,
    useTrackClickVertical,
    trackPaymentFailed,
    trackPaymentInitiated,
};
