import { useSelector } from 'react-redux';

import { AppBundles } from 'horizontal/types';

const useIsInAgencyPortal = (): boolean => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const bundle = useSelector((state) => state.bundle);
    return bundle === AppBundles.AGENCY_PORTAL_BUNDLE_NAME;
};

export default useIsInAgencyPortal;
