import * as React from 'react';
import { Trans } from '@lingui/macro';
import {
    useTotalSearchHitCount,
    selectSearchLoadingState,
} from '@sector-labs/fe-search-redux/state';
import { useSelector } from 'react-redux';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Flex, Text, LoadingAnimation } from 'strat/components';

import { BlockSeller } from 'horizontal/chat/widgets/large';
import { Location } from 'horizontal/types';

import useProfile from './hooks/useProfile';
import ProfilePicture from './profilePicture';
import ShareSellerLink from './shareSellerLink';
import ReportSeller from './reportSeller';
import Ads from './ads';
import VerifiedProfileBadge from './verifiedProfileBadge';
import styles from './styles/profile.cssm';

const Profile = ({ location, loading }: { location: Location | null; loading: boolean }) => {
    const { user, viewSection, canonicalURL, navigatePage, isAgency, agencyType } = useProfile();
    const [totalCounts] = useTotalSearchHitCount();
    const { loading: searchIsLoading } = useSelector(selectSearchLoadingState);

    if (!user || !viewSection || !canonicalURL || !navigatePage) {
        return null;
    }
    const profileID = isAgency ? { agencySlug: user.slug } : { externalID: user.externalID };

    return (
        <Flex stretchWidth className={styles.content}>
            <Flex column alignCenter className={styles.left}>
                <Flex>
                    {loading ? (
                        <LoadingAnimation
                            width={isAgency ? '27rem' : '18.4rem'}
                            height={isAgency ? '9rem' : '18.4rem'}
                            className={styles.text}
                        />
                    ) : (
                        <ProfilePicture
                            user={user}
                            className={
                                isAgency ? styles.agencyProfilePicture : styles.profilePicture
                            }
                        />
                    )}
                </Flex>
                {loading || (searchIsLoading && !isAgency) ? (
                    <LoadingAnimation width={'80%'} height={'2.4rem'} className={styles.text} />
                ) : (
                    <Flex
                        className={styles.adsCountContainer}
                        onClick={() => navigatePage({ ...profileID })}
                    >
                        <Text.Large bold>
                            <Trans>
                                {isAgency ? user?.stats?.adsCount : totalCounts} published ads
                            </Trans>
                        </Text.Large>
                    </Flex>
                )}
                <ShareSellerLink canonicalURL={canonicalURL} isAgency={isAgency} />
                {!isAgency && (
                    <Flex>
                        <ReportSeller user={user} textClassName={styles.actionText} />
                        <div className={styles.verticalDivider} />
                        <BlockSeller user={user} textClassName={styles.actionText} />
                    </Flex>
                )}
            </Flex>
            <Flex column stretchWidth fillContainer>
                <div className={styles.name} aria-label="Seller name">
                    {loading ? (
                        <LoadingAnimation width={'80%'} height={'6rem'} className={styles.text} />
                    ) : (
                        user.name
                    )}
                </div>
                {!loading && user.isVerified && (
                    <div className={styles.verifiedBadge}>
                        <VerifiedProfileBadge isAgency={isAgency} />
                    </div>
                )}
                {loading ? (
                    <LoadingAnimation width={'80%'} height={'2.4rem'} className={styles.text} />
                ) : (
                    <Text.Large aria-label="Seller description">{user.description}</Text.Large>
                )}
                <div className={styles.divider} />
                <Ads
                    location={location}
                    navigatePage={navigatePage}
                    viewSection={viewSection}
                    viewType={SearchHitsLayout.GRID}
                    isAgency={isAgency}
                    agencyType={agencyType}
                    loading={loading}
                />
            </Flex>
        </Flex>
    );
};

export default Profile;
