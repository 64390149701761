import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import { selectMyAdsCount } from './selectors';

const DEFAULT_PAGE_SIZE = 20;

const useActivePage = (): number => {
    const params = useSelector(selectRouteParams);
    return parseInt(params.page, 10) || 1;
};

const useTotalPages = (): number => {
    const adsCount = useSelector(selectMyAdsCount);

    return Math.ceil(adsCount / DEFAULT_PAGE_SIZE);
};

export { DEFAULT_PAGE_SIZE, useActivePage, useTotalPages };
