import React, { Component, FunctionComponent } from 'react';
import autoBind from 'react-autobind';

import { LoadingSpinner } from 'strat/loadable';
import type { PropertyPhotoData, PropertyVideoData } from 'strat/property/types';

import ThumbnailImage from './thumbnailImage';
import { ThumbnailSizes } from './thumbnailSizes';
import VideoPlayer from './videoPlayer';
import ImageSlideshow from './imageSlideshowAsync';
import ClickBehavior from './clickBehavior';
import VideoHostAdapter from './videoHostAdapter';
/**
 * Properties for {@see VideoSlideshow}.
 */

type Props = {
    videos: Array<PropertyVideoData>;
    coverPhotoID?: string;
    showThumbnails?: boolean;
    showFullscreenButton?: boolean;
    clickBehavior?: ClickBehavior;
    triggerName?: string;
    autoPlay?: boolean;
    customArrow?: string | FunctionComponent<any>;
    rightArrowStyle?: string;
    leftArrowStyle?: string;
    arrowIconStyle?: string;
    swiping?: boolean;
    className?: string;
    renderVideoThumbnail?: (video: PropertyVideoData, coverPhotoID?: string) => React.ReactElement;
    onError?: (errorMessage: string) => void;
    readonly isMobile?: boolean;
};

/**
 * State for {@see VideoSlideshow}.
 */
type State = {
    slidesCount: number;
};

/**
 * A video slide show used for the property page.
 */
class VideoSlideshow extends Component<Props, State> {
    /**
     * Initializes a new instance of {@see VideoSlideshow}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = {
            slidesCount: 0,
        };
    }

    /**
     * Render a single video thumbnail in the slide show.
     */
    static renderThumbInner(video: PropertyVideoData) {
        return (
            <ThumbnailImage
                imageID={video.id}
                thumbnailURL={(id, encoding, size) =>
                    VideoHostAdapter.videoThumbnailURL(video.host, video.url, encoding, size)
                }
                size={ThumbnailSizes.SMALL}
                loadingIndicator={<LoadingSpinner />}
            />
        );
    }

    /**
     * Close the video player for all opend videos in the slideshow.
     */
    onSlide() {
        this.setState((prevState) => ({ slidesCount: prevState.slidesCount + 1 }));
    }

    render() {
        return (
            <ImageSlideshow
                // @ts-expect-error connectors are not properly typed
                photos={this.props.videos as Array<PropertyPhotoData>}
                showThumbnails={this.props.showThumbnails}
                clickBehavior={ClickBehavior.NEXT}
                // @ts-expect-error - TS7006 - Parameter 'video' implicitly has an 'any' type.
                renderImage={(video) => (
                    <VideoPlayer
                        // @ts-expect-error - TS2322 - Type '{ autoPlay: boolean | undefined; video: any; coverPhotoID: string | undefined; slidesCount: number; triggerName: string | undefined; renderVideoThumbnail: ((coverPhotoID: string, video: VideoData) => Node) | undefined; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, GTMProps> & RefAttributes<any> & { children?: ReactNode; }'.
                        autoPlay={this.props.autoPlay}
                        video={video}
                        coverPhotoID={this.props.coverPhotoID}
                        slidesCount={this.state.slidesCount}
                        triggerName={this.props.triggerName}
                        // @ts-expect-error - TS2322 - Type '((video: VideoData, coverPhotoID: string | undefined) => ReactElement<any, string | JSXElementConstructor<any>>) | undefined' is not assignable to type '(video: VideoData, coverPhotoID: string | undefined) => ReactElement<any, string | JSXElementConstructor<any>>'
                        renderVideoThumbnail={this.props.renderVideoThumbnail}
                        onError={this.props.onError}
                    />
                )}
                renderThumbInner={
                    this.props.showThumbnails && (VideoSlideshow.renderThumbInner as any)
                }
                onSlide={this.onSlide}
                withVideos
                swiping={this.props.swiping}
                showFullscreenButton={this.props.showFullscreenButton}
                customArrow={this.props.customArrow}
                leftArrowStyle={this.props.leftArrowStyle}
                rightArrowStyle={this.props.rightArrowStyle}
                arrowIconStyle={this.props.arrowIconStyle}
                className={this.props.className}
                isMobile={this.props.isMobile}
            />
        );
    }
}

export default VideoSlideshow;
