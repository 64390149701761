export type ErrorContext = Record<string, unknown>;

export class ContextfulError extends Error {
    public context!: ErrorContext;

    constructor(msg: string, context?: ErrorContext) {
        super(msg, { cause: context?.cause as Error | undefined });

        Object.setPrototypeOf(this, ContextfulError.prototype);

        // Set this as a non-enumerable property so that
        // Sentry doesn't extract them twice, once as
        // part of `.toJSON()` and once by enumerating
        // the properties using `Object.keys()`.
        Object.defineProperty(this, 'context', {
            enumerable: false,
            writable: false,
            value: context,
        });
    }

    toJSON(): ErrorContext {
        return this.context;
    }
}
