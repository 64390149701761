import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { Flex, Text, Dialog, Button } from 'strat/components';

import styles from './styles/savedSearchesSuccessfullyDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
};

const SavedSearchSuccessfullyDialog = ({ visible, onVisibilityChanged }: Props) => (
    <Dialog
        dismissible
        visible={visible}
        onVisibilityChanged={onVisibilityChanged}
        withCloseButton
        className={styles.dialog}
    >
        <Flex column alignCenter justifyCenter className={styles.container}>
            <Text.XLarge bold>
                <Trans>Saved search successfully</Trans>
            </Text.XLarge>
            <Text.Base className={styles.description}>
                <Trans>
                    You can access your saved searches in the &#39;Favourites & Saved Searches&#39;
                    section from your profile
                </Trans>
            </Text.Base>
            <Button stretch onClick={() => onVisibilityChanged(false)} className={styles.button}>
                <Trans>Resume Search</Trans>
            </Button>
            <Link route={RouteNames.SAVED_SEARCHES} hard className={styles.viewSavedSearches}>
                <Button secondary stretch>
                    <Text.Large bold>
                        <Trans>View all saved searches</Trans>
                    </Text.Large>
                </Button>
            </Link>
        </Flex>
    </Dialog>
);

export default SavedSearchSuccessfullyDialog;
