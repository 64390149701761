import { OvationAdData } from 'strat/api/ovationAPI';
import { PropertyType } from 'strat/property/types';

import { OvationAttribution } from './types';

export const adData = (
    ad: any,
    potwExternalID?: string | null,
    experiments?: string[],
): OvationAdData => {
    const data: OvationAdData = {
        ad_source: 'imported',
        ad_source_id: ad.sourceID,
        ad_type: ad.type || PropertyType.PROPERTY,
        ad_external_id: ad.externalID,
        attributions: [],
        ad_posting_id: ad.postingID,
        ad_product: ad.product,
        ad_product_consumptions: ad.productConsumptionIDs,
    };

    if (potwExternalID === ad.externalID) {
        data.attributions.push(OvationAttribution.POTW);
    }
    if (experiments) {
        data.attributions.push(...experiments);
    }

    return data;
};
