import * as React from 'react';

import type { SearchRouteParams } from 'horizontal/routes';
import { useCurrentSearchRouteParams } from 'horizontal/search/routing';

import refineSearchRouteParams from './refineSearchRouteParams';
import SearchLink from './searchLink';

type Props = React.ComponentProps<typeof SearchLink> & {
    readonly params?: Partial<SearchRouteParams>;
};

const RefinedSearchLink = ({ params, children, ...props }: Props) => {
    const currentParams = useCurrentSearchRouteParams();
    // @ts-expect-error - TS2345 - Argument of type '({ [key: string]: any; } & SearchRouteParams & Partial<SearchRouteParams>) | undefined' is not assignable to parameter of type 'Partial<SearchRouteParams>'.
    const refinedParams = refineSearchRouteParams(currentParams, params);

    return (
        <SearchLink params={refinedParams} {...props}>
            {children}
        </SearchLink>
    );
};

export default RefinedSearchLink;
