import { buildCanonicalURLWithoutLanguage } from 'strat/routing';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';

import EditProfileSection from '../editProfileSection';

const useEditProfileAbsoluteURL = () => {
    const router = useRouter();

    return buildCanonicalURLWithoutLanguage(
        router.getRouteURL(RouteNames.EDIT_PROFILE, { section: EditProfileSection.INFO }),
    );
};

export default useEditProfileAbsoluteURL;
