import { RouteNames } from 'strat/routes';
import type { Patterns, PatternsCollection } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { EnhancedLocation } from 'react-true-router/location';
import defaultRoutePatterns from '@app/routes/search/defaultRoutePatterns';

import type { SearchRouteParams } from 'horizontal/routes';
import { createAdsFilters } from 'horizontal/search/hits';

import { SearchRouteClass } from './searchRoute';

// This path should not appear in urls which need to be indexed by google because it's disallowed in robots
const FILTERS_SUFFIX = '/raw-filters';

/**
 * Inserts the suffix /filters to all search route patterns after the freeTextQuery path.
 * It also excludes all the routePatterns without the /filters suffix, in case freeTextQuery path is missing.
 * @param routePatterns search route patterns
 */
function insertFiltersSuffixPathInRoutePatterns(routePatterns: PatternsCollection) {
    return routePatterns
        .map((patterns) =>
            patterns.reduce((agg: Patterns, pattern) => {
                if (
                    pattern !== null &&
                    typeof pattern === 'object' &&
                    pattern.name === 'freeTextQuery'
                ) {
                    return [...agg, pattern, FILTERS_SUFFIX];
                }
                return [...agg, pattern];
            }, []),
        )
        .filter((patterns) => !!patterns.find((pattern) => pattern === FILTERS_SUFFIX));
}

/**
 * Route which returns the ad search filters for a search url
 * It works by adding the suffix /filters to all search route patterns,
 * it uses the search route for parsing and computing the filters and
 * sets them in the context.http.body to be returned as json.
 */
class SearchRouteFiltersAPI extends SearchRouteClass {
    constructor() {
        super(
            RouteNames.SEARCH_FILTERS_API,
            insertFiltersSuffixPathInRoutePatterns(defaultRoutePatterns),
        );
    }

    createURL(_: SearchRouteParams, __: RoutingContextWithMiddlewares): EnhancedLocation {
        throw new Error('Not allowed');
    }

    postFetchData(context: RoutingContextWithMiddlewares, searchRouteParams: SearchRouteParams) {
        const filters = createAdsFilters(context.redux.store.getState, searchRouteParams);
        context.http.json({ filters: filters.serialize() });

        return Promise.resolve();
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        super.onEnter(context);
    }
}

export default new SearchRouteFiltersAPI();
