import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import classNames from 'classnames';
import { LoadingAnimation } from 'strat/components';

import type { Category } from 'horizontal/types';

import RootCategoryDropdown from './rootCategoryDropdown';
import TopCategoryGroup from './topCategoriesGroup';
import MoreCategoriesGroup from './moreCategoriesGroup';
import useDisplayedCategories from './useDisplayedCategories';
import styles from './styles/splitCategoriesDropdown.cssm';

const SplitCategoriesDropdown = ({ categories }: { categories: Array<Category> }) => {
    const i18n = useI18n();
    const numberOfDisplayedCategories = useDisplayedCategories(window.innerWidth);
    const displayedCategories = categories.slice(0, numberOfDisplayedCategories);
    const groupedCategories = categories.slice(numberOfDisplayedCategories, categories.length);

    const topCategoryGroupRenderer =
        (category: Category) =>
        // @ts-expect-error - TS7031 - Binding element 'onClick' implicitly has an 'any' type.
        ({ onClick }) => <TopCategoryGroup noWrapText onClick={onClick} category={category} />;

    // @ts-expect-error - TS7031 - Binding element 'onClick' implicitly has an 'any' type.
    const moreCategoriesGroupRenderer = ({ onClick }) => (
        <MoreCategoriesGroup categories={groupedCategories} onClick={onClick} />
    );

    return (
        <Flex justifySpaceBetween stretchWidth>
            {!numberOfDisplayedCategories ? (
                <Flex stretchWidth>
                    <LoadingAnimation width={'100%'} height={'2.25rem'} />
                </Flex>
            ) : null}
            <Flex
                justifySpaceBetween
                stretchWidth
                className={classNames({ [styles.hidden]: !numberOfDisplayedCategories })}
            >
                {displayedCategories.map((category: Category) => {
                    return (
                        <RootCategoryDropdown
                            category={category}
                            key={category.id}
                            groupRenderer={topCategoryGroupRenderer(category)}
                        />
                    );
                })}
                <div>
                    <RootCategoryDropdown
                        rightAlignGroup
                        title={t(i18n)`More Categories`}
                        groupRenderer={moreCategoriesGroupRenderer}
                        withIcon
                    />
                </div>
            </Flex>
        </Flex>
    );
};
export default SplitCategoriesDropdown;
