// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import { selectSearchSettings } from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';

import { createRandomSeed } from 'horizontal/search/execution';
import { SearchPageSearchType } from 'horizontal/search/types';

import getAdsSearchIndex from '../getAdsSearchIndex';
import type { SearchProps } from '../types';
import getHitsPerPage from '../getHitsPerPage';
import createAdsSearchFacets from '../common/createAdsSearchFacets';

import createSlottingConfig from './createSlottingConfig';

const createAdsSearchSettings = ({
    getState,
    searchRouteParams,
    categoryConfigurations,
}: SearchProps): SearchSettings => {
    const state = getState();
    const language = selectLanguage(state);
    const location = searchRouteParams.location || brandingSettings.topLevelLocation;
    const facets = createAdsSearchFacets({ state, searchRouteParams, withProductFacet: true });

    const hitsPerPage = getHitsPerPage(SearchPageSearchType.FEATURED_REGULAR_GROUPED);

    const oldSettings = selectSearchSettings(state);

    const indexName = getAdsSearchIndex(language);

    return {
        hitsPerPage,
        maxValuesPerFacet: 20,
        activeSlotRule: 'default',
        sort: searchRouteParams.sortOption,
        // Reset the random seed when on the first page. Like this,
        // the seed stays stable when navigating between pages.
        randomSeed: !searchRouteParams.page ? createRandomSeed() : oldSettings.randomSeed,
        slotRules: createSlottingConfig({
            categoryConfigurations,
            indexName,
            searchParams: {
                location,
                product: searchRouteParams.product,
            },
        }),
        facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING ? facets : [],
        // Do not count more than 200k hits. If there are more,
        // we'll display '200k+ hits'. Setting an upper limit
        // prevents ES from endlessly iterating the hits just
        // to get an exhaustive count.
        exhaustiveNbHits: CONFIG.runtime.ADS_SEARCH_EXHAUSTIVE_NB_HITS || 200000,
        maxPageAllowed: 50,
    };
};

export default createAdsSearchSettings;
