import * as React from 'react';

import { PackagesFlowTypeValues } from 'horizontal/packages';

import { UpsellingCTA } from '../components/types';

import UpsellingCTASection from './upsellingCTASection';
import useCreatePackagesSectionCTAs from './useCreatePackagesSectionCTAs';

type Props = {
    readonly flow: PackagesFlowTypeValues;
    readonly mainCTA: UpsellingCTA;
    readonly viewAd: () => void;
    readonly hasItems: boolean;
};

const PackagesCTASection = (props: Props) => {
    const sectionCTAs = useCreatePackagesSectionCTAs(props);

    if (!sectionCTAs) {
        return null;
    }

    return (
        <UpsellingCTASection
            mainCTA={sectionCTAs.mainCTA}
            secondaryCTA={sectionCTAs.secondaryCTA}
        />
    );
};

export default PackagesCTASection;
