import { ProductPackageOffer, ProductPurchase } from 'horizontal/types';

import { PackagesSection } from './types';

const useDefaultPackageSelection = <T extends ProductPackageOffer | ProductPurchase>(
    packageSections: Array<PackagesSection<T>> | null,
): T | null => {
    if (!packageSections) {
        return null;
    }

    return packageSections.at(0)?.items.at(0) ?? null;
};

export default useDefaultPackageSelection;
