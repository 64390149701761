import React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from 'strat/components';

const TruCheckText = () => (
    <span dir="ltr">
        <Trans>
            Tru<Text.Base bold>Check</Text.Base>&trade;
        </Trans>
    </span>
);
export default TruCheckText;
