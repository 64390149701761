import { useSelector } from 'react-redux';

import { isProfolioUser, isStratCreditUser } from './roles';
import { selectUserRoles } from './session';
import { selectClosestLocation } from './selectors';

export const useIsProfolioUser = () => isProfolioUser(useSelector(selectUserRoles));

export const useIsStratCreditUser = () => isStratCreditUser(useSelector(selectUserRoles));

export const useClosestLocation = () => useSelector(selectClosestLocation);
