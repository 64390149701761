import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import Page from 'horizontal/agencyPortal/pages/page';
import { ProtoolAgencyLeadsFilterParams } from 'horizontal/agencyPortal/types';
import { encodeLeadFilter } from 'horizontal/agencyPortal/leads/leadsFilterEncoding';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

class AgencyPortalLeadsRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_LEADS, [['^/agencyPortal/leads', '/?(?:(?:\\?|#).*)?$']]);
    }

    createURL({
        freeTextSearch,
        adExternalID,
        agentExternalIDs,
        leadType,
        timestamp,
        excludeAnonymousLead,
        page,
    }: ProtoolAgencyLeadsFilterParams): EnhancedLocation {
        const filter = encodeLeadFilter({
            freeTextSearch,
            adExternalID,
            agentExternalIDs,
            leadType,
            timestamp,
            excludeAnonymousLead,
            page,
        });
        const search: Record<string, any> = {};

        if (filter) {
            search.filter = filter;
        }

        if (page && Number(page) !== 1) {
            search.page = page;
        }
        const pathname = '/agencyPortal/leads';

        if (Object.keys(search).length > 0) {
            return { pathname, search };
        }
        return { pathname };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context)) {
            return;
        }
        context.rendering.renderPage(Page.AGENCY_PORTAL_LEADS);
    }
}

export default new AgencyPortalLeadsRoute();
