/**
 * DOM element field id's, gathered in a single file, for easy access
 *
 * This allows other components to easily focus certain fields.
 */
export const FIELD_IDS: {
    [key: string]: string;
} = {
    name: 'contactFormName',
    email: 'contactFormEmail',
    phone: 'contactFormPhone',
    message: 'contactFormMessage',
};

export const VIEWS: {
    [key: string]: string;
} = {
    form: 'form',
    success: 'success',
};

export const VALIDATION = {
    nameMinCharacters: 3,
    messageMinCharacters: 10,
    messageMaxCharacters: 200,
} as const;
