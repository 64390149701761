import * as React from 'react';
import { Flex } from 'strat/components';
import { useSelector } from 'react-redux';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';
import { selectUserRoles } from 'strat/user/session';

import Overview from './overview';
import LearnMore from './learnMore';
import Menu from './menu';
import styles from './styles/userWallet.cssm';

export const UserWalletsSection = Object.freeze({
    OVERVIEW: 'overview',
    INFO: 'info',
});

const Sheet = ({ section }: { section: Values<typeof UserWalletsSection> | null | undefined }) => {
    switch (section) {
        case UserWalletsSection.OVERVIEW:
            return <Overview />;
        case UserWalletsSection.INFO:
            return <LearnMore />;
        default:
            return null;
    }
};

type Props = {
    readonly section: string;
};

const UserWallet = ({ section }: Props) => {
    const userRoles = useSelector(selectUserRoles);
    if (isProAgent(userRoles) && !isProAgencyOwner(userRoles)) {
        return null;
    }

    return (
        <Flex fillContainer>
            <Menu section={section} />
            <Flex className={styles.container}>
                <Sheet section={section} />
            </Flex>
        </Flex>
    );
};

export default UserWallet;
