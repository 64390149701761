import * as React from 'react';

import { MarketingCampaigns } from 'horizontal/types';

import WalletTopUpCampaign from './walletTopUpCampaign/walletTopUpCampaignDialog';

const HomePageCampaign = () => {
    switch (CONFIG.runtime.STRAT_MARKETING_CAMPAIGN) {
        case MarketingCampaigns.WALLET_TOP_UP:
            return <WalletTopUpCampaign />;
        default:
            return null;
    }
};
export default HomePageCampaign;
