import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import { Flex, Input, Select } from 'strat/components';

import { fetchLocationsByParent } from 'horizontal/search/location';

import styles from './styles/fields.cssm';

type Props = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly businessValidationSchema: boolean;
};

const AddressFields = ({
    setFieldValue,
    touched,
    errors,
    values,
    onBlur,
    businessValidationSchema,
}: Props) => {
    const i18n = useI18n();

    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);
    const [states, setStates] = React.useState([]);
    const [currentLocations, setCurrentLocations] = React.useState([]);

    React.useEffect(() => {
        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
        fetchLocationsByParent(null, language, backend).then((locations) => {
            const choices = locations.map((loc, idx) => ({
                id: idx,
                label: loc.name,
                value: loc.name,
            }));
            // @ts-expect-error - TS2345 - Argument of type '{ id: number; label: unknown; value: unknown; }[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setStates(choices);
            // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
            setCurrentLocations(locations);
        });
    }, [language, backend]);

    React.useEffect(() => {
        const foundState = currentLocations.find(
            // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'never'. | TS2339 - Property 'name_l1' does not exist on type 'never'.
            (loc) => loc.name === values.state || loc.name_l1 === values.state,
        );
        // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'never'.
        const newValue = foundState ? foundState.name : values.state;
        if (newValue !== values.state) {
            setFieldValue('state', newValue);
        }
    }, [currentLocations, setFieldValue, values.state]);

    const addressLineOneTitle = businessValidationSchema
        ? t(i18n)`* Address line 1`
        : t(i18n)`Address line 1`;
    const stateTitle = businessValidationSchema ? t(i18n)`* State` : t(i18n)`State`;
    const cityTitle = businessValidationSchema ? t(i18n)`* City` : t(i18n)`City`;

    return (
        <>
            <Flex className={styles.inputSection}>
                <div className={styles.inputContainer}>
                    <Input
                        name={'addressLineOne'}
                        title={addressLineOneTitle}
                        value={values.addressLineOne}
                        onChange={(value) => setFieldValue('addressLineOne', value)}
                        onBlur={onBlur}
                        errorMessage={touched.addressLineOne && errors.addressLineOne}
                        accepted={touched.addressLineOne && !errors.addressLineOne}
                    />
                </div>
                <div className={styles.inputContainer}>
                    <Input
                        name={'addressLineTwo'}
                        title={t(i18n)`Address line 2`}
                        value={values.addressLineTwo}
                        onChange={(value) => setFieldValue('addressLineTwo', value)}
                        onBlur={onBlur}
                        errorMessage={touched.addressLineTwo && errors.addressLineTwo}
                        accepted={touched.addressLineTwo && !errors.addressLineTwo}
                    />
                </div>
            </Flex>
            <Flex className={styles.inputSection}>
                <div className={styles.inputContainer}>
                    <Select
                        name={'state'}
                        title={stateTitle}
                        placeholder={stateTitle}
                        choices={states}
                        value={values.state}
                        onChange={(value) => setFieldValue('state', value)}
                        // @ts-expect-error - TS2322 - Type '{ name: string; title: string; placeholder: string; choices: never[]; value: any; onChange: (value: any) => void; onBlur: (arg1: SyntheticEvent<any, Event>) => void; errorMessage: any; accepted: any; }' is not assignable to type 'IntrinsicAttributes & Props<any>'.
                        onBlur={onBlur}
                        errorMessage={touched.state && errors.state}
                        accepted={touched.state && !errors.state}
                    />
                </div>
            </Flex>
            <Flex className={styles.inputSection}>
                <div className={styles.inputContainer}>
                    <Input
                        name={'city'}
                        title={cityTitle}
                        value={values.city}
                        onChange={(value) => setFieldValue('city', value)}
                        onBlur={onBlur}
                        errorMessage={touched.city && errors.city}
                        accepted={touched.city && !errors.city}
                    />
                </div>
            </Flex>
        </>
    );
};

export default AddressFields;
