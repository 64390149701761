import { t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';

import { getSupportedOTPChannels } from '../utils';

export const RequestCodeType = Object.freeze({
    SMS: 'sms',
    VOICE: 'voice',
    EMAIL: 'email',
});

type SupportedOTPChannels = {
    defaultChannel: string;
    secondaryChannel?: string | null | undefined;
    defaultChannelActionStatement: string;
    secondaryChannelActionStatement?: string;
    secondaryChannelResendLabel?: string;
};

const useSupportedOTPChannels = (isPhone = true): SupportedOTPChannels => {
    const i18n = useI18n();

    const actionStatements = {
        [RequestCodeType.SMS]: t(i18n)`Resend Code by SMS`,
        [RequestCodeType.VOICE]: t(i18n)`Resend Code by Call`,
        [RequestCodeType.EMAIL]: t(i18n)`Resend Code by Email`,
    } as const;

    if (!isPhone) {
        return {
            defaultChannel: RequestCodeType.EMAIL,
            defaultChannelActionStatement: actionStatements[RequestCodeType.EMAIL],
        };
    }

    const [defaultChannel, secondaryChannel] = getSupportedOTPChannels();

    const defaultChannelActionStatement = actionStatements[defaultChannel];

    if (!secondaryChannel) {
        return {
            defaultChannel,
            defaultChannelActionStatement,
        };
    }

    const secondaryChannelActionStatement = actionStatements[secondaryChannel];

    const resendLabel = {
        [RequestCodeType.VOICE]: t(
            i18n,
        )`If you have not received the code by call, please request `,
        [RequestCodeType.SMS]: t(i18n)`If you have not received the code by SMS, please request `,
    } as const;

    const secondaryChannelResendLabel = secondaryChannel && resendLabel[defaultChannel];

    return {
        defaultChannel,
        secondaryChannel,
        defaultChannelActionStatement,
        secondaryChannelActionStatement,
        secondaryChannelResendLabel,
    };
};

export default useSupportedOTPChannels;
