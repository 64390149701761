import * as React from 'react';
import { useSelector } from 'react-redux';
import brandingSettings from '@app/branding/settings';
import { selectGeoLocationData } from 'strat/user/selectors';

import { AdDeliveryFields } from 'horizontal/fields/commonPostingFields';
import useIsLocationDeliverable from 'horizontal/location/useIsLocationDeliverable';
import { LocationRole } from 'horizontal/types';
import type { Location, LocationChoices } from 'horizontal/types';

const useSetAdDeliveryField = (
    selectedLocation: Location | null,
    choices: LocationChoices,
    setFieldValue: any,
) => {
    const isLocationDeliverable = useIsLocationDeliverable(choices);
    const { closestLocation } = useSelector(selectGeoLocationData);

    React.useEffect(() => {
        /**
         * we are considering the case where selectedLocation is computed/retrieved
         * when a user selected a location using `current location` feature. When
         * location is selected through that approach, the roles are missing. So here
         * we check if selectedLocation is same as the geoLocation in redux, if yes
         * we take the redux one since it has the roles.
         */
        const location =
            closestLocation?.id === selectedLocation?.id ? closestLocation : selectedLocation;
        if (!brandingSettings.deliverySettings || !location) {
            return;
        }
        if (isLocationDeliverable(location)) {
            setFieldValue(
                AdDeliveryFields.location_delivery_role.attribute,
                LocationRole.ALLOWS_DELIVERY,
            );
        } else {
            setFieldValue(AdDeliveryFields.location_delivery_role.attribute, '');
        }
    }, [closestLocation, selectedLocation, isLocationDeliverable, setFieldValue]);
};

export default useSetAdDeliveryField;
