import { SearchResponseHit } from '@sector-labs/fe-search-redux/backend';

export enum ProjectPageSections {
    DESCRIPTION = 'description',
    PAYMENT_PLANS = 'payment_plan',
    AVAILABLE_UNITS = 'available_units',
    AMENITIES = 'amenities',
    ABOUT = 'about',
    MASTER_PLAN = 'master_plan',
}

export type ProjectAdHit = SearchResponseHit & {
    project?: { externalID: string };
    externalID?: string;
};

export type ProjectHit = SearchResponseHit & {
    externalID: string;
};
