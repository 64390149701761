import * as React from 'react';

import { Flex } from 'strat/components';
import type { ProjectData } from 'strat/project/types';
import { selectProjectPaymentPlans } from 'strat/project/selectors';
import { isNonEmptyArray } from 'strat/types/conditions';

import { PaymentPlanInformationSection } from './paymentPlans';
import styles from './styles/projectTopDetails.cssm';
import ProjectPriceInformationSection from './projectPriceInformationSection';
import ProjectHandoverInformationSection from './projectHandoverInformationSection';

type Props = {
    project: ProjectData;
};

const ProjectTopDetails = ({ project }: Props) => {
    const paymentPlans = selectProjectPaymentPlans(project);

    return (
        <Flex className={styles.details}>
            {!!project.price && <ProjectPriceInformationSection price={project.price} />}
            {isNonEmptyArray(paymentPlans) && (
                <PaymentPlanInformationSection paymentPlans={paymentPlans} />
            )}
            {!!project.completionDetails?.completionDate && (
                <ProjectHandoverInformationSection
                    date={project.completionDetails.completionDate}
                />
            )}
        </Flex>
    );
};

export default ProjectTopDetails;
