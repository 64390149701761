import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { Button } from 'strat/components';
import { TemplateType } from '@sector-labs/templates-renderer';
import { useSelector } from 'react-redux';

import LockIcon from 'horizontal/assets/icons/payment_lock.svg';
import DisclaimerIcon from '@app/assets/icons/payment_disclaimer.svg';
import VisaIcon from 'horizontal/assets/icons/visa_icon.png';
import MastercardIcon from 'horizontal/assets/icons/mastercard_icon.png';
import { useFormattedPrice } from 'horizontal/util';

import styles from './styles/paymentAreebaForm.cssm';
import { selectAreebaCurrency } from './selectors';
import { SupportedCurrencies } from './types';

export const FormFields = Object.freeze({
    NAME_ON_CARD: 'nameOnCard',
    CARD_NUMBER: 'cardNumber',
    EXPIRATION_MONTH: 'expirationMonth',
    EXPIRATION_YEAR: 'expirationYear',
    CVV: 'cvv',
});

type FormFieldsType = Partial<Record<Values<typeof FormFields>, string>>;

type Props = {
    readonly initialValues: FormFieldsType;
    readonly totalPrice: number;
    readonly isDisabled: boolean;
    readonly onSubmit: (arg1?: any) => void;
    readonly extraCharges: string | undefined;
};

const AreebaForm = ({ initialValues, totalPrice, isDisabled, onSubmit, extraCharges }: Props) => {
    const [values, setValues] = React.useState(initialValues);
    const i18n = useI18n();
    const currency = useSelector(selectAreebaCurrency);
    const template =
        currency === SupportedCurrencies.LBP
            ? TemplateType.SECONDARY_PRICE_LABEL
            : TemplateType.PRICE_LABEL;
    const formattedTotalPrice = useFormattedPrice(totalPrice, template);
    const brandName = settings.getBrandName(i18n);

    return (
        <div className={styles.form}>
            <div className={styles.securePayment}>
                <p>{t(i18n)`Secure Credit/Debit Card Payment`}</p>
                <LockIcon />
            </div>
            {extraCharges && (
                <div className={styles.securePayment}>
                    <p>{t(
                        i18n,
                    )`Due to the current banking restrictions, an additional ${extraCharges}% charge will be applied to your purchase`}</p>
                    <DisclaimerIcon />
                </div>
            )}
            <label htmlFor="card-name">{t(i18n)`Name on Card`}</label>
            <input
                type="text"
                readOnly
                id="card-name"
                placeholder={t(i18n)`e.g. John Smith`}
                value={values.nameOnCard}
                onChange={(e) => setValues({ ...values, nameOnCard: e.target.value })}
                className={styles.paymentInput}
            />

            <label htmlFor="card-number">{t(i18n)`Card Number`}</label>
            <input
                type="text"
                readOnly
                id="card-number"
                value={values.cardNumber}
                onChange={(e) => setValues({ ...values, cardNumber: e.target.value })}
                className={classNames(styles.paymentInput, styles.cardNumber)}
            />
            <div>
                <div className={styles.column}>
                    <label htmlFor="expiry-month">{t(i18n)`Month`}</label>
                    <input
                        type="text"
                        readOnly
                        id="expiry-month"
                        value={values.expirationMonth}
                        onChange={(e) => setValues({ ...values, expirationMonth: e.target.value })}
                        className={styles.paymentInput}
                    />
                </div>
                <div className={styles.column}>
                    <label htmlFor="expiry-year">{t(i18n)`Year`}</label>
                    <input
                        type="text"
                        readOnly
                        id="expiry-year"
                        value={values.expirationYear}
                        onChange={(e) => setValues({ ...values, expirationYear: e.target.value })}
                        className={styles.paymentInput}
                    />
                </div>
                <div className={styles.column}>
                    <label htmlFor="security-code">{t(i18n)`CVV`}</label>
                    <input
                        type="text"
                        readOnly
                        id="security-code"
                        value={values.cvv}
                        onChange={(e) => setValues({ ...values, cvv: e.target.value })}
                        className={styles.paymentInput}
                    />
                </div>
            </div>
            <div className={styles.paymentAbout}>
                <a
                    href={t(i18n)`card_payments_url`}
                    target="_blank noopener noreferrer"
                    className={styles.aboutLink}
                >
                    {t(i18n)`About ${brandName} Payments`}
                </a>
                <div>
                    <img src={VisaIcon} alt="Visa logo" />
                    <img src={MastercardIcon} className={styles.spaced} alt="Mastercard logo" />
                </div>
            </div>
            <Button type="button" stretch disabled={isDisabled} onClick={onSubmit}>
                {t(i18n)`Pay ${formattedTotalPrice} `}
            </Button>
        </div>
    );
};

export default AreebaForm;
