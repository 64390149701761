const sanitizeDescription = (description?: string | null, maxLength = 150): string => {
    if (!description) {
        return '';
    }

    return description
        .replace(/<.+?(?=>)>/g, ' ')
        .replace(/(\r|\n)/g, ' ')
        .replace(/\s\s+/g, ' ')
        .trim()
        .slice(0, maxLength);
};

export default sanitizeDescription;
