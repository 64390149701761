import StratDialogNames from 'strat/dialogs/dialogNames';

const DialogNames = Object.freeze({
    ...StratDialogNames,
    CATEGORIES_DIALOG: 'categoriesDialog',
    LOCATIONS_DIALOG: 'locationsDialog',
    HIERARCHICAL_LOCATION_SELECT_DIALOG: 'hierarchicalLocationSelectDialog',
    LOCATION_RADIUS_DIALOG: 'locationRadiusDialog',
    FREE_TEXT_SEARCH_DIALOG: 'freeTextSearchDialog',
    FILTERS_DIALOG: 'filtersDialog',
    PROPERTY_FILTERS_DIALOG: 'propertyFiltersDialog',
    USER_CENTER_DIALOG: 'userCenterDialog',
    TOAST: 'toast',
    CTA_DIALOG: 'ctaDialog',

    // report dialogs
    REPORT_AD_DIALOG: 'reportAdDialog',
    REPORT_USER_DIALOG: 'reportUserDialog',

    // chat dialogs
    CHAT_ATTACHMENT_DIALOG: 'chatAttachmentDialog',
    CHAT_SHARE_LOCATION_DIALOG: 'chatShareLocationDialog',
    CHAT_GALLERY_DIALOG: 'chatGalleryDialog',
    CHAT_BLOCK_USER_DIALOG: 'chatBlockUserDialog',
    CHAT_ASK_FOR_MORE_DIALOG: 'chatAskForMoreDialog',

    // authentication related dialogs
    PHONE_NUMBER_DIALOG: 'phoneNumberDialog',

    // post an ad dialogs
    POST_AD_CATEGORY_PICKER_DIALOG: 'postAdCategoryPickerDialog',
    AD_CREATION_GROUPED_CHOICES_DIALOG: 'adCreationGroupedChoicesDialog',

    // ad functionalitites dialog
    MARK_AS_SOLD: 'markAsSold',
    REMOVE_AD_DIALOG: 'removeAdDialog',
    DISABLE_AD_DIALOG: 'disableAdDialog',
    LEARN_MORE_DIALOG: 'learnMoreDialog',

    UNLINK_SOCIAL_LOGIN_DIALOG: 'unlinkSocialLoginDialog',

    USER_IMAGE_DIALOG: 'userImageDialog',
    REMOVE_USER_IMAGE_DIALOG: 'removeUserImageDialog',
    USER_IMAGE_FAIL_DIALOG: 'userImageFailDialog',

    AD_GALLERY_DIALOG: 'adGalleryDialog',

    // ad details dialogs
    AD_DETAILS_FEATURES_DIALOG: 'adDetailsFeaturesDialog',

    POST_AD_MILEAGE_INFO_DIALOG: 'postAdMileageInfoDialog',
});

export default DialogNames;
