import * as React from 'react';
import classNames from 'classnames';

import type { Location } from 'horizontal/types';

import styles from './styles/locationList.cssm';
import LocationEntry from './locationEntry';

const LocationsListing = ({
    locations,
    onLocationClicked,
    withArrows,
    selectedIndex,
}: {
    locations: Location[];
    onLocationClicked: (arg1: Location) => void;
    withArrows?: boolean;
    selectedIndex?: number;
}) => (
    <>
        {locations.map((location, index) => (
            <div
                onClick={() => onLocationClicked(location)}
                className={classNames(styles.entry, {
                    [styles.selected]: index === selectedIndex,
                })}
                key={location.id}
            >
                <LocationEntry location={location} withArrow={withArrows} />
            </div>
        ))}
    </>
);

export default LocationsListing;
