import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import type { Category, Location, CategoryFieldsFilters } from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';
import { selectCustomMetaDescription } from 'horizontal/urlSpecificSEO/selectors';

import useTerm from './useTerm';
import usePurposeAdjective from './usePurposeAdjective';
import useLocationName from './useLocationName';

type Props = {
    readonly category?: Category | null | undefined;
    readonly location?: Location | null | undefined;
    readonly freeTextQuery?: string | null | undefined;
    readonly extraFields?: CategoryFieldsFilters;
};

const useMetaDescription = ({ category, location, freeTextQuery, extraFields }: Props): string => {
    const i18n = useI18n();
    const term = useTerm(
        { category, freeTextQuery, extraFields },
        { useShortNames: !category?.roles?.includes(CategoryRole.USE_LONG_NAME_IN_DESCRIPTION) },
    );
    const purposeAdjective = usePurposeAdjective(
        category,
        CategoryRole.INCLUDE_PURPOSE_IN_DESCRIPTION,
    );
    const locationName = useLocationName(location);

    const customMetaDescription = useSelector(selectCustomMetaDescription);
    if (customMetaDescription) {
        return customMetaDescription;
    }

    const domainName = settings.getDomainName(i18n);

    if (term) {
        const termWithPurpose = purposeAdjective ? `${term} ${purposeAdjective}` : term;
        return t(
            i18n,
        )`Find the best ${termWithPurpose} in ${locationName}. ${domainName} offers online local classified ads for ${term}. Post your classified ad for free in various categories like mobiles, tablets, cars, bikes, laptops, electronics, birds, houses, furniture, clothes, dresses for sale in ${locationName}.`;
    }

    return t(
        i18n,
    )`${domainName} offers local classified ads for jobs, for sale, real estate, services, community and events - Post your classified ad for free`;
};

export default useMetaDescription;
