import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames';

import { toggleViewportHeightFreeze } from 'strat/modal';
import { Scroll } from 'strat/util';
import { connectLanguage } from 'strat/i18n/language';

import styles from './styles/slideIn.cssm';

export const SlideInDirection = Object.freeze({
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom',
});

/**
 * Properties for {@see SlideIn}.
 */
type Props = {
    /**
     * Whether the {@see SlideIn} is visible or not.
     */
    readonly visible: boolean;
    /**
     * Callback for when the visibility changes.
     */
    readonly onVisibilityChanged?: (visible: boolean) => void;
    readonly handleAnimationStart?: () => void;
    readonly handleAnimationEnd?: () => void;
    /**
     * Additional styles for the {@see SlideIn}.
     */
    readonly className?: string;
    /**
     * The children of the {@see SlideIn}
     */
    readonly children?: Node | React.ReactNode;
    readonly setNotVisible?: () => void;
    readonly handleVisibilityChange?: (
        visible: boolean,
        arg2?: () => void | null | undefined,
    ) => void;
    /**
     * Whether to force pre-rendering the content.
     *
     * This can be helpful, for example, when the SlideIn contains
     * content that you want crawlers too see.
     */
    readonly preRender?: boolean;
    readonly slideFrom?: StringValues<typeof SlideInDirection>;
    /**
     * Please whenever possible, pass a name that is representative for the content that the SlideIn is related to.
     * Since this is a generic component and it's used in many places it is hard to test it especially when the
     * component is pre-rendered! Therefore, a specific area label should be passed to describe the context of the
     * SlideIn to be easier to test and follow.
     */
    readonly ariaLabel?: string;
    /**
     * Whether this modal should be rendered with right to left direction or not
     */
    readonly rtl: boolean;
    readonly height?: any;
};

/**
 * Renders a slideIn on top of the screen.
 */

class SlideIn extends Component<Props> {
    /**
     * Unique name for this slide in.
     */
    name: string;

    /**
     * Indicates whether this slide in was rendered
     * at least once after mounting.
     */
    wasRendered: boolean;

    /**
     * Used to store a reference to the slideIn DOM element.
     */
    slideInElement: any;

    /**
     * Default values for optional props.
     */
    static defaultProps = {
        visible: false,
        preRender: false,
        slideFrom: SlideInDirection.RIGHT,
    };

    /**
     * Initializes a new instance of {@see SlideIn}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.name = `strat-slide-in-${Math.floor(Math.random() * 9999)}`;
        this.wasRendered = false;

        if (this.props.visible) {
            Scroll.disable(this.name);
        }
    }

    // @ts-expect-error - TS2344 - Type 'string' does not satisfy the constraint 'keyof IntrinsicElements | ForwardRefExoticComponent<any> | (new (props: any) => Component<any, {}, any>) | ((props: any, context?: any) => ReactElement<...> | null)'.
    setSlideInElementRef(element: null | React.ElementRef<string>) {
        this.slideInElement = element;
        this.attachEventListeners();
    }

    componentDidMount() {
        if (this.props.visible) {
            toggleViewportHeightFreeze(true);
        }
    }

    attachEventListeners() {
        if (this.slideInElement) {
            this.slideInElement.addEventListener(
                'webkitAnimationStart',
                this.props.handleAnimationStart,
            );
            this.slideInElement.addEventListener(
                'webkitAnimationEnd',
                this.props.handleAnimationEnd,
            );
            this.slideInElement.addEventListener('animationStart', this.props.handleAnimationStart);
            this.slideInElement.addEventListener('animationEnd', this.props.handleAnimationEnd);
        }
    }

    detachEventListeners() {
        if (this.slideInElement) {
            this.slideInElement.removeEventListener(
                'webkitAnimationStart',
                this.props.handleAnimationStart,
            );
            this.slideInElement.removeEventListener(
                'webkitAnimationEnd',
                this.props.handleAnimationEnd,
            );
            this.slideInElement.removeEventListener(
                'animationStart',
                this.props.handleAnimationStart,
            );
            this.slideInElement.removeEventListener('animationEnd', this.props.handleAnimationEnd);
        }
    }

    /**
     * Re-enable scroll when unmounting.
     */
    componentWillUnmount() {
        if (this.props.visible) {
            toggleViewportHeightFreeze(false);
        }
        Scroll.enable(this.name);
        this.detachEventListeners();
    }

    /**
     * Disable scroll when the slide in was made visible.
     */
    componentDidUpdate(oldProps: Props) {
        if (oldProps.visible === this.props.visible) {
            return;
        }
        toggleViewportHeightFreeze(this.props.visible);

        if (this.props.visible) {
            Scroll.disable(this.name);
        } else {
            Scroll.enable(this.name);
        }

        if (this.props.handleVisibilityChange) {
            this.props.handleVisibilityChange(this.props.visible, this.props.setNotVisible);
        }
    }

    /**
     * Notifies the slideIn opened.
     */
    open(): void {
        if (this.props.onVisibilityChanged) {
            this.props.onVisibilityChanged(true);
        }
    }

    /**
     * Closes the slideIn closed
     */
    close(): void {
        if (this.props.onVisibilityChanged) {
            this.props.onVisibilityChanged(false);
        }
    }

    /**
     * Renders the slideIn and the content.
     */
    render() {
        if (!this.props.visible && !this.wasRendered && !this.props.preRender) {
            return null;
        }
        if (this.props.visible) {
            // if preRender, set wasRendered only when the slide becomes visible
            this.wasRendered = true;
        }

        const className = classNames(
            {
                [styles.slideIn]: this.props.visible,
                [styles.slideOut]: !this.props.visible,
                [styles.left]: this.props.slideFrom === SlideInDirection.LEFT,
                [styles.bottom]: this.props.slideFrom === SlideInDirection.BOTTOM,
                [styles.hidden]: this.props.preRender && !this.wasRendered,
                // add a dummy class so we can use it in selectors
                // if there are multiple slide-ins hidden on the page
                'slide-in-open': this.props.visible,
            },
            this.props.className,
        );

        const ariaLabel = this.props.ariaLabel
            ? `Slidein popup ${this.props.ariaLabel}`
            : 'Slidein popup';

        const style = {
            height: this.props.height || 'auto',
        } as const;

        return (
            <div
                style={style}
                className={className}
                id={this.name}
                ref={this.setSlideInElementRef}
                aria-label={ariaLabel}
                dir={this.props.rtl ? 'rtl' : 'ltr'}
            >
                {this.props.children}
            </div>
        );
    }
}

export default connectLanguage(SlideIn);
