import React, { Component } from 'react';
import isFastEqual from 'fast-deep-equal/es6/react';

import ScrollNavSectionContext from './scrollNavSectionContext';

/**
 * Properties for {@see NavSection}.
 */
type Props = {
    title: string;
    id?: string;
    shortTitle?: string;
    icon?: any;
    children: React.ReactNode;
};

export const ScrollNavSectionName = 'ScrollNavSection';

/**
 * A section that can appear as a child of {@see ScrollNav}.
 *
 * This _has_ to be a class. Otherwise, {@see ScrollNav} cannot
 * use refs. If this were a pure components, then refs wouldn't
 * work.
 * https://reactjs.org/docs/refs-and-the-dom.html#refs-and-functional-components
 */
class ScrollNavSection extends Component<Props> {
    static displayName = ScrollNavSectionName;

    shouldComponentUpdate(nextProps: Props) {
        return !isFastEqual(nextProps, this.props);
    }

    render() {
        return (
            <ScrollNavSectionContext.Provider
                value={{
                    title: this.props.title,
                    shortTitle: this.props.shortTitle,
                    icon: this.props.icon,
                }}
            >
                {<div>{this.props.children}</div>}
            </ScrollNavSectionContext.Provider>
        );
    }
}

export default ScrollNavSection;
