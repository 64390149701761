import * as React from 'react';
import { Flex } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { useLoginDialog } from 'strat/auth';

import { NotificationsDropdown } from 'horizontal/notifications';
import { ChatLink } from 'horizontal/chat/widgets/large';
import { trackLoginClick } from 'horizontal/gtm';

import LoginButton from './loginButton';
import UserDropdown from './userDropdown';
import styles from './styles/userCenter.cssm';

const UserCenter = () => {
    const user = useActiveUser();
    const [, setLoginDialogVisibility] = useLoginDialog();

    const onLoginClick = React.useCallback(() => {
        setLoginDialogVisibility(true);
        trackLoginClick();
    }, [setLoginDialogVisibility]);

    if (!user) {
        return (
            <Flex className={styles.container}>
                <LoginButton onClick={onLoginClick} />
            </Flex>
        );
    }

    return (
        <Flex className={styles.container}>
            <ChatLink />
            <NotificationsDropdown />
            <UserDropdown />
        </Flex>
    );
};

export default UserCenter;
