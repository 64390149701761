import React from 'react';

import { NoImage, ThumbnailSizes } from 'strat/image';
import type { PropertyData } from 'strat/property/types';

import styles from './styles/listingPhoto.cssm';

/**
 * Props for {@see ListingPhoto}.
 */
type Props = {
    children?: Node | React.ReactNode;
    renderPlaceholderImage?: () => Node;
    // These next few props are needed in order to accept the same
    // Props type as the ListingPhoto component from strat
    property?: PropertyData;
    className?: string;
    size?: Values<typeof ThumbnailSizes>;
};

const defaultRenderPlaceholderImage = () => <NoImage />;

const ListingPhoto = ({
    // @ts-expect-error - TS2322 - Type '() => JSX.Element' is not assignable to type '() => Node'.
    renderPlaceholderImage = defaultRenderPlaceholderImage,
    children,
}: Props) => <div className={styles.container}>{children || renderPlaceholderImage()}</div>;

export default ListingPhoto;
