import { type Vector3 } from 'three';

export const UnitPlanStatus = Object.freeze({
    SUBMITTED: 'submitted',
    PROCESSING: 'processing',
    READY: 'ready',
});

export type UnitPlanResource = {
    name: string;
    url: string;
};

export const SectionType = Object.freeze({
    UNKNOWN: 0,
    LIVING_ROOM: 1,
    BEDROOM: 2,
    BATHROOM: 3,
    KITCHEN: 4,
    DINING_ROOM: 5,
});

export type Point2D = [number, number];

export type UnitPlanRoomPolygon = {
    polygon: Point2D[];
    section_indices: number[];
};

export type UnitPlanRoomSection = {
    type: Values<typeof SectionType>;
    center: number[];
};

export type UnitPlanRoomImage = {
    image_id: number;
};

export type RawUnitPlanRoom = {
    id: number;
    sections: UnitPlanRoomSection[];
    image_mappings: UnitPlanRoomImage[];
    outline: UnitPlanRoomPolygon[];
};

export type UnitPlanRoom = RawUnitPlanRoom & {
    area: number;
};

export type RawUnitPlan = {
    created_at: string;
    status: string;
    resources: UnitPlanResource[];
    rooms: RawUnitPlanRoom[];
};

export type UnitPlan = Omit<RawUnitPlan, 'rooms'> & {
    rooms: UnitPlanRoom[];
};

export type UnitPlanResources = {
    objURL: string;
    mtlURL: string;
};

export type RoomSection = {
    roomID: number;
    sectionIndex: number;
};

export type RoomSectionSelection = {
    roomID: number;
    sectionIndex: number | null;
};

export type ImageLabel = {
    title: string;
    subtitle: string | null;
};

export type CameraRenderDetails = {
    center: Vector3;
    direction: Vector3;
    distance: number;
};
