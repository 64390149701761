import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';

import { selectCategories } from 'horizontal/categories';
import type { Category } from 'horizontal/types';
import {
    useCategoryChoicesTree,
    FilterContainer,
    TreeNode,
    TreeNodeList,
    CategoryFilterList,
} from 'horizontal/search/filters';
import { useTrackCategoryFilterClick } from 'horizontal/gtm';
import { sortByAdCount } from 'horizontal/util';
import { useRemarketingParams } from 'horizontal/search/remarketing';

import { RefinedSearchLink, SearchLink } from '../routing';
import { useLocationFilterValue } from '../location';
import { useFreeTextQuery } from '../freeText';

import useCategoryFilterValue from './useCategoryFilterValue';
import useCategoryFacetCount from './useCategoryFacetCount';
import styles from './styles/catgeoryFilterTree.cssm';

type CategoryTreeProps = {
    readonly category: Category;
    readonly isSelected: (arg1: Category) => boolean;
    readonly level: number;
    readonly followable: boolean;
    readonly isDefaultCategory?: boolean;
};

type CategoryTreeNodeProps = CategoryTreeProps & {
    readonly count?: number | null;
};

const CategoryTreeNode = ({ category, count, followable, isSelected }: CategoryTreeNodeProps) => {
    const location = useLocationFilterValue();
    const trackCategoryClick = useTrackCategoryFilterClick();
    const freeTextQuery = useFreeTextQuery();
    const remarketingParams = useRemarketingParams();
    const onClick = React.useCallback(() => {
        trackCategoryClick(category, ViewSections.FILTER);
    }, [category, trackCategoryClick]);

    return (
        <SearchLink
            params={{ location, category, freeTextQuery, ...remarketingParams }}
            onClick={onClick}
            rel={followable ? 'follow' : 'nofollow'}
        >
            <TreeNodeList level={category.level + 1}>
                <TreeNode
                    label={category.nameShort || category.name}
                    count={count}
                    selected={isSelected(category)}
                />
            </TreeNodeList>
        </SearchLink>
    );
};

const CategoryTree = ({
    category,
    level,
    isSelected,
    followable = true,
    isDefaultCategory = false,
}: CategoryTreeProps) => {
    const categories = useSelector(selectCategories);
    const categoryFacetCount = useCategoryFacetCount(category.externalID, level);
    const count = level === 0 ? 0 : categoryFacetCount;

    const childCategories = React.useMemo(
        () =>
            settings.enableL2CategoriesSort
                ? [...category.children].sort(sortByAdCount)
                : category.children,
        [category],
    );

    // Render the level 0 categories if no category is selected
    if (isDefaultCategory) {
        return (
            <>
                {categories.map((category) => (
                    <CategoryTreeNode
                        category={category}
                        count={count}
                        isSelected={isSelected}
                        followable={followable}
                        level={level}
                    />
                ))}
            </>
        );
    }

    return (
        <>
            <CategoryTreeNode
                category={category}
                count={count}
                isSelected={isSelected}
                followable={followable}
                level={level}
            />
            {childCategories.map((childCategory) => (
                <CategoryTree
                    key={childCategory.name}
                    category={childCategory}
                    level={level + 1}
                    isSelected={isSelected}
                    followable={followable}
                />
            ))}
        </>
    );
};

const CategoryFilterTree = () => {
    const i18n = useI18n();

    const category = useCategoryFilterValue();
    const categories = useSelector(selectCategories);
    const categoryChoicesTree = useCategoryChoicesTree();
    const hasFreeTextQuery = !!useFreeTextQuery();
    const isDefaultCategory = !categoryChoicesTree;

    const tree = React.useMemo(
        () => (
            <CategoryTree
                category={categoryChoicesTree ?? categories[0]}
                level={0}
                isSelected={(node) => node.externalID === category?.externalID}
                followable={!hasFreeTextQuery}
                isDefaultCategory={isDefaultCategory}
            />
        ),
        [categoryChoicesTree, category, hasFreeTextQuery, categories, isDefaultCategory],
    );

    return (
        <FilterContainer
            title={t(i18n)`Categories`}
            label={`Categories filter`}
            className={styles.filterContainer}
            titleClassName={styles.filterContainerTitle}
            uppercaseTitle={false}
        >
            <CategoryFilterList />
            <RefinedSearchLink
                params={{ category: null }}
                rel={hasFreeTextQuery ? 'nofollow' : 'follow'}
            >
                <TreeNode label={t(i18n)`All categories`} selected={isDefaultCategory} />
            </RefinedSearchLink>
            {tree}
        </FilterContainer>
    );
};

export default CategoryFilterTree;
