import { t } from '@lingui/macro';
import React from 'react';
import { Text } from 'strat/components';
import { StylelessButton } from 'strat/generic';
import settings from '@app/branding/settings';
import useI18n from 'strat/i18n/language/useI18n';

import styles from './styles/serviceDeliveryField.cssm';

const ServiceDeliveryField = () => {
    const i18n = useI18n();
    return (
        <div>
            <span>{t(i18n)`Service Delivery Provider`}</span>
            {settings.deliverySettings?.displayDeliveryPricingButton && (
                <>
                    <hr className={styles.separator}></hr>
                    <a href={t(i18n)`delivery_fees_table_url`} target="_blank noopener noreferrer">
                        <StylelessButton type="button">
                            <Text.Regular className={styles.buttonText}>
                                {t(i18n)`See Pricing`}
                            </Text.Regular>
                        </StylelessButton>
                    </a>
                </>
            )}
        </div>
    );
};

export default ServiceDeliveryField;
