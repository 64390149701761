import { StratAPI as BaseStratAPI } from 'strat/api';
import type { APIResponse } from 'strat/api/types';
import { HTTPApi } from 'strat/util';

import type { RequestDeliveryData } from 'horizontal/adDetails/requestDelivery';
import { InviteAgentData } from 'horizontal/agencyPortal/types';
import { AdSoldMedium } from 'horizontal/constants';
import type { UserDTO } from 'horizontal/dtos';
import type {
    AreebaPaymentPayload,
    CheckoutHostedPaymentsPayload,
    CheckoutPaymentsPayload,
    FawryPaymentPayload,
    JazzCashMobilePaymentPayload,
    JazzCashPayloadNotification,
    PaymentOrderPayload,
    PayWhatYouWantOrderPayload,
    ProviderCashPaymentPayload,
} from 'horizontal/payment';
import type { TPayConfirmPayload, TPaySessionPayload } from 'horizontal/payment/tpay';
import {
    ProductPurchaseAgents,
    PackageRoles,
    ProductPackageOffer,
    ProductType,
    AdProductCreditCost,
    AgentProductCreditSummary,
    AgencyProductCreditSummary,
} from 'horizontal/types';
import type { UserSettings } from 'horizontal/userSettings/types';
import { UserAgency } from 'horizontal/agent/state';
import type { ProtoolAgencyLeadsFilterParams } from 'horizontal/agencyPortal/types';

import type { EasyPaisaHostedPaymentsPayload } from '../payment/types';
import type { AddressInfo, BillingInfo } from '../userOrders/types';

class StratAPI extends BaseStratAPI {
    getOrder(externalID: string) {
        return this.uncachedRequest(`/api/payment/order/${externalID}`, null, {
            method: 'GET',
        });
    }

    getPaymentStatus(paymentID: string) {
        return this.uncachedRequest(`/api/payment/${paymentID}/status`, null, {
            method: 'GET',
        });
    }

    createOrder(
        orderPayload: PaymentOrderPayload,
        userExternalID: string,
    ): Promise<APIResponse<any>> {
        return this.post(
            `/api/payment/order?userExternalId=${userExternalID}`,
            orderPayload,
        ) as Promise<unknown> as Promise<APIResponse<any>>;
    }

    createPayWhatYouWantOrder(orderPayload: PayWhatYouWantOrderPayload) {
        return this.post('/api/payWhatYouWant/order', orderPayload);
    }

    easyPaisaHostedPayment(body: EasyPaisaHostedPaymentsPayload) {
        return this.post('/api/payment/easypaisa/hostedPayment', body);
    }

    checkoutHostedPayment(body: CheckoutHostedPaymentsPayload) {
        return this.post('/api/payment/checkout/hostedPayments', body);
    }

    checkoutPayment(body: CheckoutPaymentsPayload) {
        return this.post('/api/payment/checkout/payments', body);
    }

    getCustomerInstruments(instrumentID?: string) {
        const url = '/api/payment/checkout/customer/instruments';
        const parameters = instrumentID ? { instrument_id: instrumentID } : null;
        return this.request(url, parameters);
    }

    deleteCustomerInstrument(instrumentID: string) {
        return this.uncachedRequest(
            `/api/payment/checkout/customer/instruments?instrument_id=${instrumentID}`,
            null,
            {
                method: 'DELETE',
            },
        );
    }

    checkoutFinishPayment(checkoutPaymentID: string) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return this.post(`/api/payment/checkout/finish/${checkoutPaymentID}`);
    }

    easyPaisaFinishPayment(orderExternalID: string) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return this.post(`/api/payment/easypaisa/finish/${orderExternalID}`);
    }

    jazzCashPaymentNotification(body: JazzCashPayloadNotification) {
        return this.post('/api/payment/jazzCash/notification', body);
    }

    jazzCashMobileWalletPayment(body: JazzCashMobilePaymentPayload) {
        return this.post('/api/payment/jazzCash/mwallet', body);
    }

    fawryPayment(body: FawryPaymentPayload, userExternalID: string): Promise<APIResponse<any>> {
        return this.post(
            `/api/payment/fawry/?userExternalId=${userExternalID}`,
            body,
        ) as Promise<unknown> as Promise<APIResponse<any>>;
    }

    billingInfo(userExternalID: string) {
        return this.uncachedRequest(`/api/user/billingInfo/${userExternalID}`, null, {
            method: 'GET',
        });
    }

    saveBillingInfo(userExternalID: string, billingInfo: BillingInfo) {
        return this.post(`/api/user/billingInfo/${userExternalID}`, billingInfo);
    }

    /**
     * Gets the sitemap categories for a location level.
     */
    sitemapCategories(locationLevel: string) {
        return this.uncachedRequest(`/api/sitemap/categories/${locationLevel}`);
    }

    getProductPackageOffers(
        categoryID: string,
        locationID: string,
        params: {
            roles?: Values<typeof PackageRoles>[] | Values<typeof PackageRoles>;
            types?: Values<typeof ProductType>[] | Values<typeof ProductType>;
            priceOrderDesc?: boolean;
            show_all_bundles: boolean;
        },
    ): Promise<APIResponse<ProductPackageOffer[]>> {
        return this.request(
            `/api/catalog/packages/category/${categoryID}/location/${locationID}`,
            params,
        );
    }

    getAdProductPackageOffers(
        adExternalID: string,
        opts: { sortBy?: string } = {},
    ): Promise<APIResponse<ProductPackageOffer[]>> {
        const params: Record<string, string> = {};
        if (opts.sortBy) {
            params['sort'] = opts.sortBy;
        }

        return this.request(`/api/listing/${adExternalID}/packageOffers`, params);
    }

    getAdProductCreditCosts(adExternalID: string): Promise<APIResponse<AdProductCreditCost[]>> {
        return this.request(`/api/listing/${adExternalID}/adProductCreditCosts/`);
    }

    applyAdProductCreditCosts(adExternalID: string, adProductCreditCostExternalIDs: string[]) {
        return this.post(`/api/listing/${adExternalID}/adProductCreditCosts/apply/`, {
            adProductCreditCostExternalIDs,
        });
    }

    getProductPackageOffersExchangeRate() {
        return this.request('/api/catalog/packages/exchangeRate');
    }

    /**
     * Gets the popular searches list for a given location and category.
     */
    popularSearchesList(locationId?: string, categoryId?: string) {
        if (!locationId) {
            return this.uncachedRequest(`/api/popularSearchesList`);
        }
        if (categoryId) {
            return this.uncachedRequest(`/api/popularSearchesList/${locationId}/${categoryId}`);
        }
        return this.uncachedRequest(`/api/popularSearchesList/${locationId}`);
    }

    closeAd({
        adExternalID,
        buyerUserExternalID,
        reason,
        soldType,
        soldInfo,
    }: {
        adExternalID: string;
        buyerUserExternalID?: string | null;
        reason: string;
        soldType?: Values<typeof AdSoldMedium>;
        soldInfo?: string | null;
    }) {
        // we need post instead of GET because in case GET request we can take at most 50 users per request
        // this request is used also for getting chat users for an existing ad so we can not guarantee that
        // there are at most 50 conversations. In case they are more the request will fail.
        return this.post(`/api/listing/${adExternalID}/close/`, {
            buyerUserExternalID,
            reason,
            soldType,
            soldInfo,
        });
    }

    activateAd({
        adExternalID,
        productPurchaseID,
    }: {
        adExternalID: string;
        productPurchaseID?: string | null;
    }) {
        return this.post(`/api/listing/${adExternalID}/activate/`, {
            productPurchaseID,
        });
    }

    activateBulkAds({
        userExternalID,
        adsExternalIDs,
        productPurchaseID,
    }: {
        userExternalID: string;
        adsExternalIDs: string[];
        productPurchaseID?: string | null;
    }) {
        return this.post(`/api/user/${userExternalID}/ads/activate/`, {
            adsExternalIDs,
            productPurchaseID,
        });
    }

    reportUser(
        userExternalID: string,
        body: {
            readonly reason: string;
            readonly comment: string;
        },
    ) {
        return this.post(`/api/user/${userExternalID}/complaint`, body);
    }

    deactivateBulkAds({
        userExternalID,
        adsExternalID,
    }: {
        userExternalID: string;
        adsExternalID: string[];
    }) {
        return this.post(`/api/user/${userExternalID}/ads/close/`, {
            adsExternalID,
        });
    }

    userSettings(userExternalID: string) {
        return this.request(`/api/user/${userExternalID}/settings/`);
    }

    saveUserSettings(userExternalID: string, settings: Partial<UserSettings>) {
        return this.patch(`/api/user/${userExternalID}/settings/`, settings);
    }

    userRoles(userExternalID: string) {
        return this.request(`/api/user/${userExternalID}/roles/`);
    }

    getBanners(platform: string) {
        return this.request(`/api/banners?platform=${platform}`);
    }

    areebaSession(orderExternalID: number, currency: string) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return this.post(`/api/payment/areeba/session/${orderExternalID}?currency=${currency}`);
    }

    areebaPayment({ language, ...body }: AreebaPaymentPayload): Promise<any> {
        return this.post(`/api/payment/areeba/checkout`, body, {
            headers: { 'Accept-Language': language },
        });
    }

    walletPay(
        userExternalID: string,
        orderDetails: {
            orderExternalID: string;
        },
    ) {
        return this.post(`/api/user/${userExternalID}/wallet`, orderDetails);
    }

    walletBalance(userExternalID: string) {
        return this.request(`/api/user/${userExternalID}/wallet`);
    }

    userAgencies(userExternalID: string): Promise<APIResponse<UserAgency[]>> {
        return this.request(`/api/user/${userExternalID}/agencies`);
    }

    userAddressBook(userExternalID: string) {
        return this.request(`/api/user/${userExternalID}/addressBook`);
    }

    addUserAddress(userExternalID: string, address: AddressInfo) {
        return this.post(`/api/user/${userExternalID}/addressBook`, {
            ...address,
            address_line_one: address?.addressLineOne,
            address_line_two: address?.addressLineTwo,
            is_default: address?.isDefault,
            is_deleted: address?.isDeleted,
        });
    }

    updateUserAddress(userExternalID: string, address: AddressInfo, id: string) {
        return this.patch(`/api/user/${userExternalID}/addressBook/${id}`, {
            ...address,
            address_line_one: address?.addressLineOne,
            address_line_two: address?.addressLineTwo,
            is_default: address?.isDefault,
            is_deleted: address?.isDeleted,
        });
    }

    tpaySession(orderExternalID: string) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return this.post(`/api/payment/tpay/session/${orderExternalID}`);
    }

    tpayCheckout({ language, ...body }: TPaySessionPayload): Promise<any> {
        return this.post(`/api/payment/tpay/checkout`, body, {
            headers: { 'Accept-Language': language },
        });
    }

    tpayResend(sessionID: string) {
        return this.post(`/api/payment/tpay/resend_code`, { sessionID });
    }

    tpayConfirm({ language, ...body }: TPayConfirmPayload) {
        return this.post(`/api/payment/tpay/confirm`, body, {
            headers: { 'Accept-Language': language },
        });
    }

    userBuyingOrdersList(userExternalID: string, offersStatus: string, adExternalID?: string) {
        return this.request(
            `/api/delivery/user/${userExternalID}/offers?${HTTPApi.buildQueryString({
                status: offersStatus,
                adExternalId: adExternalID,
            })}`,
        );
    }

    userSellingOrdersList(userExternalID: string, offersStatus: string) {
        return this.request(`/api/delivery/user/${userExternalID}/listings?status=${offersStatus}`);
    }

    updateDeliveryOrderStatus(userExternalID: string, orderID: string, orderStatus: string) {
        return this.patch(`/api/delivery/user/${userExternalID}/offers/${orderID}`, {
            status: orderStatus,
        });
    }

    sellingOrderDetail(adExternalID: string, offersStatus: string) {
        return this.request(`/api/delivery/listing/${adExternalID}/offers?status=${offersStatus}`);
    }

    requestDelivery(adExternalID: string, requestDetails: RequestDeliveryData) {
        return this.post(`/api/delivery/listing/${adExternalID}/offers`, requestDetails);
    }

    paymobCheckout(orderExternalID: string, language: string) {
        return this.post(
            `/api/payment/paymob/prepareCheckout/${orderExternalID}`,
            {},
            {
                headers: { 'Accept-Language': language },
            },
        );
    }

    paymobBankInstallmentCheckout(orderExternalID: number, language: string) {
        return this.post(
            `/api/payment/paymob/prepareBankInstallmentCheckout/${orderExternalID}`,
            {},
            {
                headers: { 'Accept-Language': language },
            },
        );
    }

    paymobWalletCheckout(orderExternalID: number, phone: string, language: string) {
        return this.post(
            `/api/payment/paymob/prepareWalletCheckout/${orderExternalID}`,
            {
                phoneNumber: phone,
            },
            {
                headers: { 'Accept-Language': language },
            },
        );
    }

    providerCashPayment(orderExternalID: number, paymentSource: ProviderCashPaymentPayload) {
        return this.post(`/api/payment/cash/session/${orderExternalID}`, {
            paymentSource,
        });
    }

    fetchRelatedUsers(
        searchKey: 'externalIDs' | 'emailAddresses' | 'phoneNumbers' | 'adExternalIDs',
        searchValue: string[],
    ): Promise<APIResponse<UserDTO[]>> {
        return this.request(`/api/users/search/?${searchKey}=${searchValue}`);
    }

    inviteNewAgent(agencyExternalID: string, agentDetails: InviteAgentData) {
        return this.post(`/api/agency/${agencyExternalID}/agent/invitation`, {
            user_email: agentDetails.email,
            user_name: agentDetails.name,
        });
    }

    productPurchaseAgents(
        agencyExternalID: string,
        productPurchaseID: string,
        page: number,
        pageSize: number,
    ): Promise<APIResponse<ProductPurchaseAgents>> {
        return this.request(
            `/api/agency/${agencyExternalID}/productPurchases/${productPurchaseID}/agents`,
            {
                page,
                page_size: pageSize,
            },
        );
    }

    agencyLeads(filters?: ProtoolAgencyLeadsFilterParams): Promise<APIResponse<any>> {
        return this.post(`/api/protool/agent/leads`, { ...filters });
    }

    getAgentUserProductCreditSummary(
        agencyExternalID: string,
        agentUserExternalID: string,
    ): Promise<APIResponse<AgentProductCreditSummary>> {
        return this.request(
            `/api/agency/${agencyExternalID}/agentUser/${agentUserExternalID}/productCredit/`,
        );
    }

    getAgencyProductCreditSummary(
        agencyExternalID: string,
    ): Promise<APIResponse<AgencyProductCreditSummary>> {
        return this.request(`/api/agency/${agencyExternalID}/productCredit/`);
    }
}

export default StratAPI;
