import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import type { I18n } from '@lingui/core';
import WhatsappIcon from '@app/assets/icons/iconWhatsapp.svg';
import settings from '@app/branding/settings';
import generateWhatsAppURL from '@app/branding/generateWhatsAppURL';

import {
    selectI18n,
    selectLanguage,
    selectNumberFormatterFactory,
} from 'strat/i18n/language/selectors';
import { withGTMLeadTracking, ViewSections } from 'strat/gtm';
import type { PropertyData } from 'strat/property';
import { PropertyState } from 'strat/property/types';
import type { NumberFormatterFactory } from 'strat/i18n/language/connectLanguage';
import { Currency, selectCurrency } from 'strat/i18n/money';
import type { ExchangeRates } from 'strat/i18n/money';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import { UnitType } from 'strat/i18n';
import { selectVisitorID } from 'strat/user/selectors';
import SmartShareAPI from 'strat/api/smartShareAPI';
import { SmartShareLeadType } from 'strat/smartShare/types';
import { selectIsMobile } from 'strat/platform/selectors';
import { ButtonVariant } from 'strat/components';

import styles from './styles/whatsappButton.cssm';

/**
 * Properties for {@see WhatsappButton}.
 */
type Props = GTMLeadTrackingProps & {
    /**
     * The property for the whatsApp link
     */
    property: PropertyData;
    /**
     * Optional CSS class name for styling.
     */
    className?: string;
    /**
     * Position of the button inside a search list
     */
    index?: number;
    /**
     * Method to call when the button is clicked.
     */
    onClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     *
     */
    viewSection?: string;
    /**
     * WhatsApp URL promise, contains phone number and message string to be displayed on native phone app when WhatsApp button is clicked.
     */
    whatsAppURLPromise?: (props: WhatsappUrlProps) => Promise<string>;
    /**
     * Whether to display the "WhatsApp" text next to the icon
     */
    displayText?: boolean;
    /**
     * Text to display next to the icon
     */
    text?: string;
    /**
     * Whether to display the "WhatsApp" icon
     */
    withIcon?: boolean;
    /**
     * Optional CSS class name for styling the text.
     */
    textClassName?: string;
    variant?: Values<typeof ButtonVariant>;
    /**
     * Render icon
     */
    renderIcon?: () => React.ReactElement;
    /**
     * Toggles the old button design, which is still needed for Zameen
     */
    sharp?: boolean;
};

type WhatsappUrlProps = Props & {
    language: string;
    i18n: I18n;
    intlNumberFormat: NumberFormatterFactory;
    currentAreaUnit: Values<typeof UnitType>;
    currency: Values<typeof Currency>;
    baseCurrency: Values<typeof Currency>;
    exchangeRates: ExchangeRates;
    visitorID: string | null;
    isMobile: boolean;
    whatsAppURLPromise: (props: WhatsappUrlProps) => Promise<string>;
};

const whatsAppURLDefaultPromise = (props: WhatsappUrlProps) =>
    props.trackWhatsAppLead(props.viewSection).then((traceID) => {
        if (CONFIG.build.STRAT_ENABLE_SMART_SHARE) {
            new SmartShareAPI().createSmartShare(
                traceID,
                SmartShareLeadType.WHATSAPP,
                props.visitorID,
                props.property.externalID,
                props.language,
            );
        }

        return generateWhatsAppURL({
            ...props,
            traceID,
            viewSection: props.viewSection,
        });
    });

const onWhatsappButtonClick = (props: WhatsappUrlProps) => {
    return (event: React.SyntheticEvent<any>): void => {
        if (props.onClick) {
            props.onClick(event);
        }

        let newWindow: Window | null = null;
        if (!props.isMobile) {
            // Open the new window before calling the promise on desktop
            // because Safari will block it if inside async code
            newWindow = window.open('about:blank', '_blank');
        }

        props.whatsAppURLPromise(props).then((url) => {
            if (props.isMobile) {
                window.location.href = url;
            } else if (newWindow) {
                newWindow.location.href = url;
            }
        });
    };
};

const WhatsappButton = (props: Props) => {
    const i18n = useSelector(selectI18n);
    const language = useSelector(selectLanguage);
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const { areaUnit } = useSelector(settings.selectAreaUnit);
    const { currency, baseCurrency, exchangeRates } = useSelector(selectCurrency);
    const visitorID = useSelector(selectVisitorID);

    const {
        displayText = false,
        text = t(i18n)`WhatsApp`,
        withIcon = true,
        property: { state },
        textClassName = styles.text,
        viewSection = ViewSections.SEARCH_RESULTS,
        renderIcon = () => <WhatsappIcon className={styles.icon} />,
    } = props;
    const whatsAppPromise = props.whatsAppURLPromise || whatsAppURLDefaultPromise;
    const isMobile = useSelector(selectIsMobile);

    const buttonDisabled = React.useMemo(() => state === PropertyState.INACTIVE, [state]);

    const className = classNames(props.className, styles.button, {
        [styles.disabled]: buttonDisabled,
        [styles.updatedButton]: !props.sharp,
        [styles.light]: !buttonDisabled && props.variant === ButtonVariant.LIGHT,
    });

    return (
        <button
            className={className}
            aria-label="Whatsapp"
            onClick={onWhatsappButtonClick({
                ...props,
                i18n,
                language,
                intlNumberFormat: numberFormatterFactory,
                // @ts-expect-error - TS2322 - Type 'string | ((i18n: any, area: any) => any) | ((i18n: any, area: any) => any) | ((area: any) => { maximumFractionDigits: number; minimumFractionDigits: number; } | { maximumFractionDigits?: undefined; minimumFractionDigits?: undefined; }) | ((area: any) => (x: number) => number) | ((value: string) => boolean) | (() => ...' is not assignable to type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
                currentAreaUnit: areaUnit,
                currency,
                baseCurrency,
                exchangeRates,
                viewSection,
                whatsAppURLPromise: whatsAppPromise,
                visitorID,
                isMobile,
            })}
            disabled={buttonDisabled}
        >
            {withIcon && renderIcon()}
            {displayText && <span className={textClassName}>{text}</span>}
        </button>
    );
};

export { whatsAppURLDefaultPromise, onWhatsappButtonClick };

export default withGTMLeadTracking(WhatsappButton);
