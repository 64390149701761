/**
 * Constant values of the Known Search Parameters in the URL
 */

const KnownURLParams = Object.freeze({
    LOCATIONS: 'locations',
    PROBOARD_LOCATIONS: 'proboard_locations',
    LOCATION: 'location',
    AGENT_ID: 'agent_id',
    // this parameter intentionally has an obscure name (to be explained as 'impressions package' to end users)
    // so that they don't know it filters out ads from certain agencies (flagged from admin panel) for some campaigns
    EXCLUDE_AGENCIES: 'ipckg',
    OWNER_ID: 'owner_id',
    RENT_FREQUENCY: 'rent_frequency',
    SORT: 'sort',
    PRICE_MIN: 'price_min',
    PRICE_MAX: 'price_max',
    AREA_MIN: 'area_min',
    AREA_MAX: 'area_max',
    BATHS_IN: 'baths_in',
    KEYWORDS: 'keywords',
    FILTER_LIST_VISIBLE: 'filtersListVisible',
    PAGE: 'page',
    CATEGORY: 'category',
    PROBOARD_CATEGORY: 'proboard_category',
    PURPOSE: 'purpose',
    PROBOARD_PURPOSE: 'proboard_purpose',
    BEDS: 'beds',
    BEDS_IN: 'beds_in',
    AGENCIES: 'agencies',
    AGENTS: 'agents',
    PAGE_TYPE: 'pagetype',
    REMARKETING_PROPERTY_ID: 'remarketingPropertyID',
    COMPLETION_STATUS: 'completion_status',
    COMPLETION_PERCENTAGE_MIN: 'completion_percentage_min',
    COMPLETION_PERCENTAGE_MAX: 'completion_percentage_max',
    PRE_HANDOVER_PAYMENT_MIN: 'pre_handover_payment_min',
    PRE_HANDOVER_PAYMENT_MAX: 'pre_handover_payment_max',
    HANDOVER_DATE_MIN: 'handover_date_min',
    HANDOVER_DATE_MAX: 'handover_date_max',
    RESIDENCE_TYPE: 'residence_type',
    OCCUPANCY_STATUS: 'occupancy_status',
    FURNISHING_STATUS: 'furnishing_status',
    SALE_TYPE: 'sale_type',
    VIDEOS_MIN: 'videos_min',
    VIDEOS_MAX: 'videos_max',
    PANORAMAS_MIN: 'panoramas_min',
    PANORAMAS_MAX: 'panoramas_max',
    HAS_FLOORPLAN: 'has_floorplan',
    LOAD_ALL_PROP: 'load_all_prop',
    HAS_TRANSACTION_HISTORY: 'has_transaction_history',
    AMENITIES: 'amenities',
    LANGUAGES: 'languages',
    SPECIALITIES: 'specialities',
    TIME_SINCE_CREATION: 'time_since_creation',
    CONTRACT_RENEWAL_STATUS: 'contract_renewal_status',
    ACTIVE_TAB: 'activeTab',

    // The options below are intentionally following a different, more obscure naming pattern since they use private
    // information (e.g. agency tier) which should not be exposed to the public. The pattern also allows for the
    // addition of new tiers in the future (e.g. location tier). These are used by the marketing team to create
    // customized search pages for better lead distribution.
    AGENCY_TIER: 'a_t',
    PRODUCT_TYPE: 'p_t',

    MAP_ACTIVE: 'map_active',
    CLUSTER_ID: 'cluster_id',
    MAP_ZOOM: 'zoom',
    MAP_CENTER_LAT: 'center_lat',
    MAP_CENTER_LONG: 'center_long',
    MAP_VIEW: 'map_view',
    COMMUTE_ACTIVE: 'commute_active',
    COMMUTE: 'commute',
    COMMUTE_MAX_TIME: 'commute_max_time',
    COMMUTE_SORT_BY: 'commute_sort',
    PINNED_LISTING: 'pinned_listing',
    SCRIPT: 'script',
    SHOW_LISTING_SCORE: 'show_listing_score',
    SHOW_CURRENT_MONTH: 'show_current_month',
    SHOW_TIME_FRAME_INFO: 'show_time_frame_info',
    SHOW_CATEGORY_INFO: 'show_category_info',

    // project or property
    LISTING_TYPE: 'listing_type',
});

export default KnownURLParams;
