import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

import { PaymentSource } from 'horizontal/payment/types';

const formatPaymentSource = (i18n: I18n, source: Values<typeof PaymentSource>): string => {
    const brandName = settings.getBrandName(i18n);
    switch (source) {
        case PaymentSource.CHECKOUT:
            return t(i18n)`Checkout`;
        case PaymentSource.JAZZ_CASH_VOUCHER:
            return t(i18n)`Jazz cash voucher`;
        case PaymentSource.JAZZ_CASH_MOBILE_WALLET:
            return t(i18n)`Jazz cash mobile wallet`;
        case PaymentSource.PLUTUS:
            return t(i18n)`Plutus`;
        case PaymentSource.BEE:
            return t(i18n)`BEE`;
        case PaymentSource.FAWRY:
            return t(i18n)`FAWRY`;
        case PaymentSource.TPAY:
            return t(i18n)`TPAY`;
        case PaymentSource.WALLET:
            return t(i18n)`${brandName} Wallet`;
        case PaymentSource.ADMIN_TOOL:
            return t(i18n)`Admin tool`;
        default:
            return source;
    }
};

export default formatPaymentSource;
