import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { buildCanonicalURL } from 'strat/routing';
import { Button, Flex } from 'strat/components';

import { useTrackShareProfileLink } from 'horizontal/gtm';
import IconShare from 'horizontal/assets/icons/iconShare_noinline.svg';
import IconCheck from 'horizontal/assets/icons/iconCheck_noinline.svg';

import styles from './styles/shareSellerLink.cssm';

type Props = {
    readonly canonicalURL: string;
    readonly isAgency?: boolean;
    readonly compact?: boolean;
};

const ShareSellerLink = ({ canonicalURL, isAgency, compact }: Props) => {
    const i18n = useI18n();
    const initialMessage = isAgency ? t(i18n)`Share agency profile` : t(i18n)`Share user profile`;
    const [message, setMessage] = React.useState(initialMessage);
    const trackShareProfile = useTrackShareProfileLink();

    React.useEffect(() => {
        const messageRestorage = setTimeout(() => {
            if (initialMessage !== message) {
                setMessage(initialMessage);
            }
        }, 3000);

        return () => {
            clearTimeout(messageRestorage);
        };
    }, [message, initialMessage]);

    const copyToClipboard = React.useCallback(
        () =>
            navigator.clipboard.writeText(buildCanonicalURL(canonicalURL, i18n.locale)).then(() => {
                trackShareProfile();
                setMessage(t(i18n)`Copied to clipboard`);
            }),
        [i18n, trackShareProfile, canonicalURL],
    );

    const renderIcon = () => (
        <svg className={styles.icon}>
            <use xlinkHref={`${initialMessage === message ? IconShare : IconCheck}#icon`} />
        </svg>
    );

    return (
        <Flex
            alignCenter
            justifyCenter
            stretchWidth={compact}
            className={styles.content}
            onClick={copyToClipboard}
        >
            <Button small={compact} stretch={compact} secondary renderIcon={renderIcon}>
                <div className={styles.message}>{message}</div>
            </Button>
        </Flex>
    );
};

export default ShareSellerLink;
