import Category from '@app/branding/category';

import IconCalendar from 'horizontal/assets/icons/iconCalendar.svg';
import IconRoad from 'horizontal/assets/icons/iconRoad.svg';
import IconFuel from 'horizontal/assets/icons/iconFuel.svg';
import IconEngine from 'horizontal/assets/icons/iconEngine.svg';
import IconNewCar from 'horizontal/assets/icons/iconNewCar.svg';
import IconTransmission from 'horizontal/assets/icons/iconTransmission.svg';
import IconUsedCar from 'horizontal/assets/icons/iconUsedCar.svg';
import IconOwnership from 'horizontal/assets/icons/iconOwnership.svg';
import IconRentalFrequency from 'horizontal/assets/icons/iconRentalFrequency.svg';
import IconFurniture from 'horizontal/assets/icons/iconFurniture.svg';
import IconArea from 'horizontal/assets/icons/iconArea.svg';
import IconPropertyType from 'horizontal/assets/icons/iconPropertyType.svg';
import IconBaths from 'horizontal/assets/icons/iconBaths.svg';
import IconBeds from 'horizontal/assets/icons/iconBeds.svg';
import { MixedAd, LiteCategory, PriceType, Ad, AdFormat } from 'horizontal/types';

import { AdExtraFieldsKeys } from './types';

export default class AdUtils {
    static HIGHLIGHTED_FIELDS_CONFIG = {
        [AdExtraFieldsKeys.MILEAGE]: { icon: IconRoad },
        [AdExtraFieldsKeys.YEAR]: { icon: IconCalendar },
        [AdExtraFieldsKeys.PETROL]: { icon: IconFuel },
        [AdExtraFieldsKeys.TRANSMISSION]: { icon: IconTransmission },
        [AdExtraFieldsKeys.ENGINE_CAPACITY]: { icon: IconEngine },
        [AdExtraFieldsKeys.CONDITION]: { icon: null },
        /* Some countries have type like PK/EG others have property_type like LB */
        [AdExtraFieldsKeys.TYPE]: { icon: IconPropertyType },
        [AdExtraFieldsKeys.PROPERTY_TYPE]: { icon: IconPropertyType },
        [AdExtraFieldsKeys.AREA]: { icon: IconArea },
        [AdExtraFieldsKeys.BATHROOMS]: { icon: IconBaths },
        [AdExtraFieldsKeys.BEDROOMS]: { icon: IconBeds },
        [AdExtraFieldsKeys.FURNISHED]: { icon: IconFurniture },
        [AdExtraFieldsKeys.OWNERSHIP]: { icon: IconOwnership },
        [AdExtraFieldsKeys.RENTAL_PERIOD]: { icon: IconRentalFrequency },
    } as const;

    static getLeafCategory(ad: MixedAd | null | undefined): LiteCategory | null {
        return ad?.category?.reduce((a, b) => (a.level > b.level ? a : b)) ?? null;
    }

    static shouldShowPurposeType(ad: MixedAd) {
        return ad.category.some(Category.isOfPropertyType);
    }

    static isHighlightedField(attribute: string, ad: MixedAd) {
        if (
            Category.isOfCarsType(AdUtils.getLeafCategory(ad)) ||
            Category.isOfPropertyType(ad?.category[0])
        ) {
            return Object.keys(AdUtils.HIGHLIGHTED_FIELDS_CONFIG).includes(attribute);
        }

        return false;
    }

    static getHighlightedFieldIcon(attribute: string, ad: MixedAd) {
        //special use-case because if we can do it why shouldn't we do it
        if (
            attribute === AdExtraFieldsKeys.CONDITION &&
            Category.isOfCarsType(AdUtils.getLeafCategory(ad))
        ) {
            return ['new', '1'].includes(ad.extraFields?.[attribute] as string)
                ? IconNewCar
                : IconUsedCar;
        }

        return AdUtils.HIGHLIGHTED_FIELDS_CONFIG[
            attribute as keyof typeof AdUtils.HIGHLIGHTED_FIELDS_CONFIG
        ].icon;
    }

    static shouldShowPriceType(ad?: MixedAd | null): boolean {
        if (!ad || !ad.extraFields) {
            return false;
        }

        return ([PriceType.NEGOTIABLE, PriceType.FREE, PriceType.EXCHANGE] as string[]).includes(
            ad.extraFields[AdExtraFieldsKeys.PRICE_TYPE] as string,
        );
    }

    static isAdInNewCondition(ad?: Ad | null): boolean {
        if (!ad || !Category.isOfCarsType(ad.category[ad.category.length - 1])) {
            return false;
        }
        const { extraFields } = ad;
        return ['new', '1'].includes(extraFields?.new_used || '');
    }

    static isDeliverable(ad?: MixedAd | null): boolean {
        if (ad?.format === AdFormat.FULL) {
            return !!ad?.deliveryInformation;
        }
        if (ad?.format === AdFormat.LITE) {
            return !!ad?.isDeliverable;
        }

        return false;
    }
}
