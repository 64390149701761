import React from 'react';
import { Trans } from '@lingui/macro';
import { Button } from 'strat/components';

import IconAddCardButton from 'horizontal/assets/icons/addCardButton.svg';
import CreditCardItem from 'horizontal/payment/creditCardItem';

import type { CheckoutCreditCard } from './types';
import styles from './styles/creditCardsContainer.cssm';

const AddCreditCardButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <Button className={styles.button} onClick={onClick} ariaLabel="Add card button">
            <div className={styles.buttonContentWrapper}>
                <IconAddCardButton className={styles.icon} />
                <Trans>New Card</Trans>
            </div>
        </Button>
    );
};

type Props = {
    cards: Array<CheckoutCreditCard>;
    onSavedCardPaymentSelected: (paymentData: any) => void;
    onAddNewCard: () => void;
};

const CreditCardsContainer = ({ cards, onAddNewCard, onSavedCardPaymentSelected }: Props) => {
    if (!cards.length) {
        return null;
    }

    return (
        <div>
            {cards.map((item, index) => (
                <React.Fragment key={index}>
                    <CreditCardItem onClick={onSavedCardPaymentSelected} card={item} />
                </React.Fragment>
            ))}
            <div>
                <AddCreditCardButton onClick={onAddNewCard} />
            </div>
        </div>
    );
};

export default CreditCardsContainer;

export { CreditCardItem };
