import React from 'react';

import AdCreationForm from 'horizontal/adCreation';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

import PrivateNonIndexableHead from './privateNonIndexableHead';

const CreateAdPageDesktop = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
            <GTMDataLayer pageType={PageType.AD_CREATION} />
        </>
    ),
    body: () => <AdCreationForm />,
} as const;

export default CreateAdPageDesktop;
