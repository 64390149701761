import * as React from 'react';

const DOMEventTypes = Object.freeze({
    MOUSE_DOWN: 'mousedown',
});

type Props = {
    readonly eventType: Values<typeof DOMEventTypes>;
    readonly eventHandler?: () => void;
    readonly topLevelDOMEvent?: boolean;
};

const useDOMEvents = ({ eventType, eventHandler, topLevelDOMEvent }: Props) => {
    const ref = React.useRef<HTMLElement | null | undefined>(null);
    /**
     * In some rare occasions you are going to need to bypass the React
     * event system and go straight into the DOM event system.
     * For example when you are using our Dropdown component which has
     * dismissible functionality, then when you happen to open the dropdown
     * THEN you click on a checkbox, the checkbox onChange/onClick are
     * not going to be fired.
     * This is because Dismissible used the internal DOM events system,
     * and from what I understood, the event listeners that are declared
     * there are somehow prioritised over the React events (onBlur/onChange...etc).
     * So the events of the Dismissible are executed but the ones of
     * Checkbox are not. So what is the solution? simply also add DOM events
     * to the checkbox when needed, since the events will not be disregarded
     * like the React events.
     */
    React.useEffect(() => {
        const handleEventTrigger = (event: MouseEvent) => {
            // must check that event.target is instance of Node: https://github.com/facebook/flow/issues/4799
            if (
                ref.current &&
                event.target instanceof Node &&
                ref.current.contains(event.target) &&
                eventHandler
            ) {
                eventHandler();
            }
        };
        if (topLevelDOMEvent) {
            // @ts-expect-error - TS2769 - No overload matches this call.
            document.addEventListener(eventType, handleEventTrigger);
        }
        return () => {
            if (topLevelDOMEvent) {
                // @ts-expect-error - TS2769 - No overload matches this call.
                document.removeEventListener(eventType, handleEventTrigger);
            }
        };
    }, [ref, eventType, eventHandler, topLevelDOMEvent]);

    return ref;
};

export default useDOMEvents;
