import type { Store } from 'redux';
import { OptionalSessionTokenData } from '@sector-labs/fe-auth-redux';
import { extractJWTPayload } from '@sector-labs/fe-auth-redux/jwt';
import { refreshSession } from '@sector-labs/fe-auth-redux/thunk';

import type { GlobalState, AppDispatch } from 'strat/state';
import { selectDriftCorrectedClock } from 'strat/clock/selectors';

import type { KeycloakSiteConfigWithCallbacks } from './keycloakSiteConfig';

export const MAX_FAILED_ATTEMPTS = 5;

class KeycloakSessionRefreshTimer {
    store: Store<GlobalState>;
    siteConfig: KeycloakSiteConfigWithCallbacks;
    timeoutID: ReturnType<typeof setTimeout> | null;
    expiryTime: number | null;
    failedAttempts: number;
    isWaitingForResponse: boolean;

    constructor(store: Store<GlobalState>, siteConfig: KeycloakSiteConfigWithCallbacks) {
        this.store = store;
        this.siteConfig = siteConfig;
        this.timeoutID = null;
        this.expiryTime = null;
        this.failedAttempts = 0;
        this.isWaitingForResponse = false;
    }

    reset(sessionData: OptionalSessionTokenData | null) {
        this.failedAttempts = 0;
        this.isWaitingForResponse = false;
        this.expiryTime = this._extractExpiryTime(sessionData);

        this.refresh();
    }

    refresh() {
        this._clearTimeout();

        if (!this.expiryTime || this.isWaitingForResponse) {
            return;
        }

        const timeout = this._computeTimeout(this.expiryTime);
        this._scheduleRefreshSession(timeout);
    }

    scheduleRetry() {
        this.isWaitingForResponse = false;
        this.failedAttempts = Math.min(this.failedAttempts + 1, MAX_FAILED_ATTEMPTS);
        this._retry();
    }

    forceRetry() {
        if (this.isWaitingForResponse) {
            // We are already waiting for a request to return, there is no
            // point in making a new request since it won't return faster
            return;
        }

        this.failedAttempts = 0;
        this._retry();
    }

    _retry() {
        this._clearTimeout();

        const timeout = this._computeRetryTimeout(this.failedAttempts);
        this._scheduleRefreshSession(timeout);
    }

    _scheduleRefreshSession(timeout: number) {
        if (timeout <= 0) {
            this._refreshSession();
        } else {
            this.timeoutID = setTimeout(this._refreshSession.bind(this), timeout);
        }
    }

    _refreshSession() {
        this.expiryTime = null;
        this.isWaitingForResponse = true;
        (this.store.dispatch as AppDispatch)(refreshSession(this.siteConfig));
    }

    _computeTimeout(expiryTime: number): number {
        const clock = selectDriftCorrectedClock(this.store.getState());

        // to account for any timer inconsistencies keep a 1 minute safety margin
        // we need to multiply by 1000 because the clock uses seconds and the timeout is calculated in milliseconds
        return expiryTime - clock.unixEpochNow() * 1000 - 60000;
    }

    _extractExpiryTime(sessionData: OptionalSessionTokenData | null): number | null {
        if (!sessionData?.accessToken || !sessionData?.idToken) {
            return null;
        }

        const { accessToken, idToken } = sessionData;
        const accessTokenTimestamp = extractJWTPayload(accessToken)?.exp || null;
        const idTokenTimestamp = extractJWTPayload(idToken)?.exp || null;

        if (accessTokenTimestamp == null || idTokenTimestamp == null) {
            return null;
        }

        const expiryTime =
            idTokenTimestamp < accessTokenTimestamp ? idTokenTimestamp : accessTokenTimestamp;

        // token contains the expiry time in seconds
        return expiryTime * 1000;
    }

    _clearTimeout() {
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
            this.timeoutID = null;
        }
    }

    _computeRetryTimeout(failedAttempts: number): number {
        if (failedAttempts === 0) {
            return 0;
        }
        return 2 ** failedAttempts * 1000;
    }
}

let timer: KeycloakSessionRefreshTimer | null = null;

const handleVisibilityChange = () => {
    if (!timer || document.hidden) {
        return;
    }

    if (timer.failedAttempts) {
        timer.forceRetry();
    } else {
        timer.refresh();
    }
};

const initKeycloakSessionRefreshTimer = (
    store: Store<GlobalState>,
    siteConfig: KeycloakSiteConfigWithCallbacks,
): KeycloakSessionRefreshTimer => {
    if (timer) {
        timer.store = store;
        timer.siteConfig = siteConfig;
    } else {
        timer = new KeycloakSessionRefreshTimer(store, siteConfig);
        document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return timer;
};

const getKeycloakSessionRefreshTimer = (): KeycloakSessionRefreshTimer | null => {
    return timer;
};

export { initKeycloakSessionRefreshTimer, getKeycloakSessionRefreshTimer };
