import * as React from 'react';

import { Script } from 'strat/util';

import type { TagManager } from './types';

const GPT_SCRIPT_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

const useGooglePublisherTag = (): TagManager | null | undefined => {
    const tagManager = React.useRef(null);

    if (process.env.IS_BROWSER) {
        // @ts-expect-error - TS2339 - Property 'googletag' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'googletag' does not exist on type 'Window & typeof globalThis'.
        window.googletag = window.googletag || {};
        // @ts-expect-error - TS2339 - Property 'googletag' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'googletag' does not exist on type 'Window & typeof globalThis'.
        window.googletag.cmd = window.googletag.cmd || [];
        // @ts-expect-error - TS2339 - Property 'googletag' does not exist on type 'Window & typeof globalThis'.
        tagManager.current = window.googletag;
    }

    React.useEffect(() => {
        Script.load(GPT_SCRIPT_URL);
    }, []);

    return tagManager.current;
};

export default useGooglePublisherTag;
