import { ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import { useSearchFilterValueOfType } from '@sector-labs/fe-search-redux/state';
import FilterValues from 'strat/search/filterValues';

import type { Category } from 'horizontal/types';

const useCategoryFilterValue = (): Category | null | undefined =>
    // @ts-expect-error - TS2769 - No overload matches this call.
    useSearchFilterValueOfType(ObjectExactFilter, FilterValues.category.attribute);

export default useCategoryFilterValue;
