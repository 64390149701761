import * as React from 'react';

import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

export type HomeRouteParams = {
    page?: number;
};

const useNavigateToHome = (): any => {
    const router = useRouter();

    return React.useCallback(
        (params?: HomeRouteParams) => {
            router.pushRoute(RouteNames.HOME, params);
        },
        [router],
    );
};

export default useNavigateToHome;
