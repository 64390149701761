import * as React from 'react';
import { useSelector } from 'react-redux';

import withGTM from './withGTM';
import Triggers from './triggers';
import type { GTMProps } from './withGTM';

const trackCLS = (
    entries: undefined | PerformanceEntryList,
    urlRef: {
        current: any;
    },
    // @ts-expect-error - TS7006 - Parameter 'trigger' implicitly has an 'any' type.
    trigger,
) => {
    entries?.forEach((entry: PerformanceEntry) => {
        // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'PerformanceEntry'.
        const clsValue = entry.value;
        // @ts-expect-error - TS2339 - Property 'hadRecentInput' does not exist on type 'PerformanceEntry'.
        const hadRecentInput = entry.hadRecentInput;

        if (!hadRecentInput && clsValue > CONFIG.build.WEB_VITALS_CLS_THRESHOLD) {
            trigger(Triggers.CUMULATIVE_LAYOUT_SHIFT, {
                url: urlRef.current,
                value: clsValue,
            });
        }
    });
};

const initializeObserver = (
    urlRef: {
        current: any;
    },
    // @ts-expect-error - TS7006 - Parameter 'trigger' implicitly has an 'any' type.
    trigger,
) => {
    try {
        const observer = new PerformanceObserver((list: PerformanceObserverEntryList) =>
            trackCLS(list.getEntries(), urlRef, trigger),
        );
        observer.observe({ type: 'layout-shift', buffered: true });
        return observer;
    } catch (_: any) {
        return null;
    }
};

const GTMCumulativeLayoutShiftTracker = ({ trigger }: GTMProps) => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const url = useSelector((state) => state.router.originalURL);

    // use ref to pass the url to the PerformanceObserver, so that it's not reinitialized at every render
    const urlRef = React.useRef(url);
    urlRef.current = url;

    React.useEffect(() => {
        const observer = initializeObserver(urlRef, trigger);
        return () => {
            trackCLS(observer?.takeRecords(), urlRef, trigger);
            observer?.disconnect();
        };
    }, [urlRef, trigger]);

    return null;
};

export default withGTM(GTMCumulativeLayoutShiftTracker);
