import * as React from 'react';
import classNames from 'classnames';
import { Dropdown, HidingMethod } from 'strat/modal';
import { ViewSections } from 'strat/gtm';
import CategoryTypeChecker from '@app/branding/category';
import useHeaderLinks from '@app/header/compact/useHeaderLinks';

import MoreCategoriesIcon from '@app/assets/icons/more_categories.svg';
import type { Category } from 'horizontal/types';
import { verticalPages, VerticalSelection } from 'horizontal/types';
import { useTrackCategoryClick } from 'horizontal/gtm';

import CategoryLink from '../categoryLink';

import styles from './styles/rootCategoryDropdown.cssm';

const RootCategoryDropdown = ({
    title,
    withIcon,
    groupRenderer,
    rightAlignGroup,
    category,
}: {
    title?: string;
    withIcon?: boolean;
    groupRenderer: (arg1: { onClick: any }) => React.ReactElement;
    rightAlignGroup?: boolean;
    category?: Category;
}) => {
    const [open, setOpen] = React.useState(false);
    const trackCategoryClick = useTrackCategoryClick();
    const headerLinks = useHeaderLinks();
    const propertyVerticalLink = headerLinks.find(
        (link) => link.name === VerticalSelection.PROPERTY,
    )?.link;

    const isPropertyVerticalLink = (category: Category) => {
        return (
            CONFIG.runtime.ENABLE_OLX_VERTICALS &&
            CONFIG.runtime.OLX_HORIZONTAL_VERTICALS_PAGES?.includes(verticalPages.PROPERTIES) &&
            CategoryTypeChecker.isOfPropertiesType(category)
        );
    };

    const renderTrigger = React.useCallback((): React.ReactElement => {
        return (
            <span className={styles.trigger}>
                {withIcon && <MoreCategoriesIcon className={styles.icon} />}

                {!category && title}

                {category ? (
                    isPropertyVerticalLink(category) ? (
                        <div
                            className={styles.categoryLink}
                            onClick={() => {
                                trackCategoryClick(category, ViewSections.CATEGORY_DROPDOWN);
                                window.open(propertyVerticalLink, '_self');
                            }}
                        >
                            {category.nameShort || category.name}
                        </div>
                    ) : (
                        <CategoryLink
                            category={category}
                            onClick={() => setOpen(false)}
                            className={styles.categoryLink}
                            viewSection={ViewSections.CATEGORY_DROPDOWN}
                        />
                    )
                ) : null}
            </span>
        );
    }, [title, withIcon, category, propertyVerticalLink, trackCategoryClick]);

    return (
        <div
            className={classNames(styles.dropdownContainer, {
                [styles.relativePosition]: !rightAlignGroup,
            })}
            onMouseEnter={() => {
                setOpen(true);
            }}
            onMouseLeave={() => {
                setOpen(false);
            }}
        >
            <div
                className={classNames(styles.dropdown, {
                    [styles.relativePosition]: !rightAlignGroup,
                })}
                aria-label="Top category dropdown"
            >
                <Dropdown
                    hidingMethod={HidingMethod.DISPLAY_NONE}
                    renderTrigger={renderTrigger}
                    open={open}
                >
                    <div
                        className={classNames(styles.categories, {
                            [styles.rightAlignGroup]: rightAlignGroup,
                        })}
                        aria-label="Subcategories list"
                    >
                        {groupRenderer({ onClick: () => setOpen(false) })}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};
export default RootCategoryDropdown;
