import type { PropertyAmenityData } from './types';

const AMENITIY_SLUGS_WITH_YEAR_VALUES = ['built-in-year', 'completion-year'];

const formatAmenityValue = (
    amenity: PropertyAmenityData,
    formatNumber: (arg1: number) => string,
) => {
    let amenityValue = amenity.value;
    if (amenityValue !== null) {
        // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
        amenityValue = Number(amenityValue);
        if (!amenityValue) {
            return '';
        }
        if (!AMENITIY_SLUGS_WITH_YEAR_VALUES.includes(amenity.slug)) {
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
            return formatNumber(amenityValue);
        }
        return amenityValue;
    }
    return null;
};

export default formatAmenityValue;
