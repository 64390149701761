import { dictionaryToFlatArray, matchChoiceValue } from 'strat/util';
import brandingSettings from '@app/branding/settings';
import { CategoryFieldRole } from 'horizontal/types';
import type {
    Category,
    CategoryField,
    CategoryFieldsFilters,
    FlatCategoryField,
    SearchSortOption,
} from 'horizontal/types';
import type { CategoryFields } from 'strat/categoryFields';
import type { SearchRouteParams, SearchRouteQueryParams } from 'horizontal/routes/createSearchURL';
import type { EnhancedLocation } from 'react-true-router/location';
import { flattenCategoryFields, selectCategoryFields } from 'horizontal/categoryFields';
import { selectDefaultSortOption } from 'horizontal/search/sorting/sortOptions';
import { getPageForQueryParams } from 'horizontal/routes/pagination';
import { sortByDisplayPriority } from 'horizontal/util';
import type { GlobalState } from 'strat/state';

import encodeFilters from './encodeURLFilters';

const encodeCategory = (
    category: Category | null | undefined,
    categoryFields: FlatCategoryField[],
    extraFields?: CategoryFieldsFilters | null,
) => {
    if (!category) {
        return '';
    }
    const categoryIncludedInPathname = categoryFields.find((field) =>
        field.roles.includes(CategoryFieldRole.INCLUDED_IN_PATHNAME),
    );
    if (!categoryIncludedInPathname) {
        return `/${category.slug}_c${category.externalID}`;
    }

    const extraField = extraFields?.[categoryIncludedInPathname.attribute];
    if (!extraField) {
        return `/${category.slug}_c${category.externalID}`;
    }

    if (typeof extraField === 'string') {
        const extraFieldChoice = dictionaryToFlatArray(categoryIncludedInPathname.choices).find(
            (choice) => matchChoiceValue(choice, extraField),
        );
        if (!extraFieldChoice) {
            return `/${category.slug}_c${category.externalID}`;
        }

        return `/${extraFieldChoice.slug || extraFieldChoice.value}-${category.slug}_c${
            category.externalID
        }`;
    }

    if (!Array.isArray(extraField) || extraField.length === 0) {
        return `/${category.slug}_c${category.externalID}`;
    }

    const choices = extraField
        .map((value) =>
            dictionaryToFlatArray(categoryIncludedInPathname.choices).find((choice) =>
                matchChoiceValue(choice, value),
            ),
        )
        .filter(Boolean)
        // @ts-expect-error - TS2345 - Argument of type '<T extends { readonly displayPriority: number; }>(a: T, b: T) => number' is not assignable to parameter of type '(a: CategoryFieldChoice | undefined, b: CategoryFieldChoice | undefined) => number'.
        .sort(sortByDisplayPriority);

    if (!choices.length) {
        return `/${category.slug}_c${category.externalID}`;
    }

    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
    return `/${choices[0].slug || choices[0].value}-${category.slug}_c${category.externalID}`;
};

const createSearchURLCore = (
    {
        category,
        location,
        locationRadius,
        freeTextQuery,
        page,
        minPhotoCount,
        sortOption,
        extraFields,
        product,
        newAdTimestamp,
        pagetype,
        remarketingAdID,
    }: SearchRouteParams,
    categoryFields: CategoryFields | CategoryField[],
    defaultSortOption: SearchSortOption,
    _categoryHierarchy?: Category[] | null,
) => {
    let path = '';
    const queryParams: SearchRouteQueryParams = {} as any;
    const isDefaultLocation = location?.slug === brandingSettings.topLevelLocation.slug;
    const categoryID = category?.id || null;
    const flatCategoryFields = flattenCategoryFields(categoryFields, extraFields);
    const filteredCategoryFields = flatCategoryFields.filter(
        (field) => field.categoryID === categoryID,
    );

    if (location && !isDefaultLocation) {
        path += `/${location.slug}`;
    }

    path += encodeCategory(category, filteredCategoryFields, extraFields);

    if ((!location || isDefaultLocation) && !category) {
        path += '/items';
    }

    if (freeTextQuery) {
        const formattedQuery = freeTextQuery.replace(/\s/g, '-');
        path += `/q-${formattedQuery}`;
    }

    if (page && page > 1) {
        queryParams.page = getPageForQueryParams(page);
    }

    if (sortOption && sortOption.key !== defaultSortOption.key) {
        queryParams.sorting = sortOption.key;
    }

    if (extraFields) {
        queryParams.filter = encodeFilters(filteredCategoryFields, extraFields, category);
    }

    if (location && location.level >= 2 && locationRadius) {
        queryParams.locationRadius = locationRadius;
    }

    if (minPhotoCount && minPhotoCount > 0) {
        queryParams.minPhotoCount = minPhotoCount;
    }

    if (product) {
        queryParams.product = product;
    }

    if (newAdTimestamp) {
        queryParams.newAdTimestamp = newAdTimestamp;
    }

    if (pagetype) {
        queryParams.pagetype = pagetype;
    }

    if (remarketingAdID) {
        queryParams.remarketingAdID = remarketingAdID;
    }

    return {
        pathname: path,
        search: queryParams,
    };
};

const createSearchURL = (params: SearchRouteParams, state: GlobalState): EnhancedLocation => {
    const categoryFields = selectCategoryFields(state, params.category?.id);
    const category = params.category?.roles && params.category;
    const defaultSortOption = selectDefaultSortOption(state, category, !!params.freeTextQuery);

    return createSearchURLCore(params, categoryFields, defaultSortOption);
};

export { createSearchURLCore };
export default createSearchURL;
