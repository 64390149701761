import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';

import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import propertyIcon from '@app/assets/icons/iconProperty.svg';
import getPlaceTypeData from 'strat/map/placeTypeData';

import { PlacesType } from '../types';

import styles from './styles/placesSwitcherRedesign.cssm';

type Props = {
    /**
     * Callback to be called when you click one of the triggers.
     */
    onTypeChanged: (placeType?: Values<typeof PlacesType> | null | undefined) => void;
    /**
     * The current active places type.
     */
    activeType?: Values<typeof PlacesType>;
    /**
     * Available types.
     */
    types: Array<Values<typeof PlacesType>>;
};

type TabSwitchProps = {
    onClick: () => void;
    selected: boolean;
    title: string;
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
};

const TabSwitch = ({ onClick, selected, title, icon }: TabSwitchProps) => (
    <Flex className={classNames(styles.tabContainer, { [styles.activeTabContainer]: selected })}>
        <div className={styles.tabDelimiter} />
        <Flex justifyCenter alignCenter onClick={onClick} className={styles.tab}>
            {!!icon &&
                React.createElement(icon, {
                    className: styles.tabIcon,
                })}
            {title}
        </Flex>
    </Flex>
);

const PlacesSwitcherRedesign = ({
    onTypeChanged,
    activeType,
    types = Object.values(PlacesType) as Array<Values<typeof PlacesType>>,
}: Props) => {
    const i18n = useI18n();

    return (
        <Flex className={styles.tabsContainer}>
            <TabSwitch
                onClick={() => onTypeChanged(null)}
                selected={!activeType}
                title={t(i18n)`Property`}
                icon={propertyIcon}
                key={'PROPERTY'}
            />
            {types.map((type) => (
                <TabSwitch
                    onClick={() => onTypeChanged(type)}
                    selected={type === activeType}
                    title={getPlaceTypeData(i18n, type).title}
                    icon={getPlaceTypeData(i18n, type).iconInline}
                    key={type}
                />
            ))}
        </Flex>
    );
};

export default PlacesSwitcherRedesign;
