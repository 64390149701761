import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRouter } from 'react-true-router';
import { selectRouteName, selectRouteParams } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';
import { Flex, BackButton, Header, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import Logo from '@app/branding/logo';

import { useTrackCheckoutAbortEvent } from 'horizontal/gtm';
import { selectCategoryByExternalID } from 'horizontal/categories';
import IconArrowLeft from 'horizontal/assets/icons/iconArrowLeft.svg';
import { GlobalState } from 'horizontal/state';

import styles from './styles/businessPackagesHeader.cssm';

type Props = {
    readonly secondary?: boolean;
    readonly withCategory?: boolean;
    readonly label?: React.ReactNode;
};

const BusinessPackagesHeader = ({ secondary, withCategory, label }: Props) => {
    const routeName = useSelector(selectRouteName);
    const { category: categoryExternalID } = useSelector(selectRouteParams);
    const category = useSelector((state: GlobalState) =>
        selectCategoryByExternalID(state, categoryExternalID),
    );
    const categoryName = category?.name;
    const router = useRouter();

    const trackAbortEvent = useTrackCheckoutAbortEvent();

    const onBack = React.useCallback(() => {
        trackAbortEvent();

        switch (routeName) {
            case RouteNames.SELECT_MULTIPLE_PACKAGES:
            case RouteNames.VIEW_BUSINESS_PACKAGE_CART_ROUTE:
            case RouteNames.BUSINESS_PACKAGES:
                router.pop();
                break;
            default:
                router.pushRoute(RouteNames.HOME);
        }
    }, [routeName, router, trackAbortEvent]);

    const renderIcon = () => <IconArrowLeft className={styles.backButton} />;

    return (
        <Flex column>
            <Header className={classNames(styles.header, { [styles.secondary]: secondary })}>
                <BackButton onBack={onBack} renderIcon={secondary ? renderIcon : undefined} />
                {!withCategory && !label && (
                    <Logo onClick={trackAbortEvent} className={styles.logo} />
                )}
                {withCategory && categoryName && (
                    <Text.XLarge>
                        <Trans>Packages for {categoryName}</Trans>
                    </Text.XLarge>
                )}
                {label && <Text.XLarge>{label}</Text.XLarge>}
            </Header>
        </Flex>
    );
};

export default BusinessPackagesHeader;
