import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';

import { PriceType, CategoryFieldRole } from 'horizontal/types';

import { usePriceFieldIndex } from '../useFieldIndex';
import useSecondaryPriceFieldIndex from '../useSecondaryPriceFieldIndex';
import { CategoryPriceField, PriceTypeField } from '../commonPostingFields';
import PriceField from '../priceField';

import renderFields from './renderFields';
import hasPriceTypeFieldValue from './hasPriceTypeFieldValue';
import { PriceRelatedFieldProps } from './types';

const usePriceFields = (props: PriceRelatedFieldProps): React.ReactElement[] => {
    const { categoryFields, values, errors, touched, handleBlur, setFieldValue } = props;
    const i18n = useI18n();

    const priceFieldIndex = usePriceFieldIndex(categoryFields);
    const secondaryPriceFieldIndex = useSecondaryPriceFieldIndex(categoryFields);

    return React.useMemo(() => {
        const priceTypeField = categoryFields.find(
            (field) => field.attribute === PriceTypeField.attribute,
        );
        const priceField = categoryFields.filter(
            (field) =>
                field.attribute === CategoryPriceField.attribute &&
                !field.roles.includes(CategoryFieldRole.EXCLUDE_FROM_POST_AN_AD),
        );

        const isNegotiable = hasPriceTypeFieldValue(priceTypeField, PriceType.NEGOTIABLE);
        const isRequestable = hasPriceTypeFieldValue(priceTypeField, PriceType.REQUESTABLE);
        const priceType = values[PriceTypeField.attribute];

        const shouldShowPriceInput =
            (!isNegotiable && !isRequestable) ||
            [PriceType.FIXED_PRICE, PriceType.NEGOTIABLE].includes(priceType) ||
            !priceType;

        const commonProps = {
            values,
            setFieldValue,
            errors,
            touched,
            onBlur: handleBlur,
        };

        const result = [];
        if (shouldShowPriceInput && priceFieldIndex >= 0 && priceField.length !== 0) {
            result.push(...renderFields([categoryFields[priceFieldIndex]], commonProps));
        }
        if (shouldShowPriceInput && secondaryPriceFieldIndex >= 0) {
            result.push(
                <PriceField
                    {...commonProps}
                    key={categoryFields[secondaryPriceFieldIndex].id}
                    field={categoryFields[secondaryPriceFieldIndex]}
                    currency={settings.secondaryCurrency}
                    label={t(i18n)`Optional price`}
                />,
            );
        }
        return result;
    }, [
        i18n,
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        categoryFields,
        priceFieldIndex,
        secondaryPriceFieldIndex,
    ]);
};

export default usePriceFields;
