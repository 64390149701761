import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import Page from 'horizontal/agencyPortal/pages/page';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

class AgencyPortalCompanyProfileRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_COMPANY_PROFILE, [['^/agencyPortal/companyProfile$']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/agencyPortal/companyProfile' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context)) {
            return;
        }
        context.rendering.renderPage(Page.AGENCY_PORTAL_COMPANY_PROFILE);
    }
}

export default new AgencyPortalCompanyProfileRoute();
