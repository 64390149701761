import { createSelector } from 'reselect';

import { selectProperty } from 'strat/property/selectors';
import { GraphicMode } from 'strat/floorPlans/types';
import EMPTY_OBJECT from 'strat/empty/object';
import EMPTY_ARRAY from 'strat/empty/array';

type State = {
    [key: string]: any;
};

const selectFloorPlanDetails = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => floorPlan || EMPTY_OBJECT,
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => ({
        bedroomCount: floorPlan.bedroom_count,
        bathroomCount: floorPlan.bathroom_count,
        typeIdentifier: floorPlan.type_identifier,
        typeIdentifierValue: floorPlan.type_identifier_value,
    }),
);

const selectFloorPlanModel = createSelector(
    selectFloorPlanDetails,
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => (floorPlan || {}).models || EMPTY_ARRAY,
    // @ts-expect-error - TS7006 - Parameter 'details' implicitly has an 'any' type. | TS7006 - Parameter 'models' implicitly has an 'any' type.
    (details, models) =>
        models
            // @ts-expect-error - TS7006 - Parameter 'modelA' implicitly has an 'any' type. | TS7006 - Parameter 'modelB' implicitly has an 'any' type.
            .sort((modelA, modelB) => modelA.order_index - modelB.order_index)
            // @ts-expect-error - TS7006 - Parameter 'model' implicitly has an 'any' type.
            .map((model) => ({
                id: model.external_id,
                title: model.label.en,
                thumbnailID: model.placeholder_image_id,
                graphicMode: GraphicMode.INTERACTIVE_3D,
                ...details,
            })),
);

const selectFloorPlanImages = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'floorPlan' implicitly has an 'any' type.
    (floorPlan) => (floorPlan || {}).images || EMPTY_ARRAY,
    // @ts-expect-error - TS7006 - Parameter 'images' implicitly has an 'any' type. | TS7006 - Parameter 'imageA' implicitly has an 'any' type. | TS7006 - Parameter 'imageB' implicitly has an 'any' type.
    (images) => images.sort((imageA, imageB) => imageA.order_index - imageB.order_index),
);

const selectFloorPlan2DImage = createSelector(
    selectFloorPlanDetails,
    selectFloorPlanImages,
    (details, images) =>
        (images || [])
            // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
            .filter((image) => image.image_2d_id)
            // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
            .map((image) => ({
                id: image.image_2d_id,
                title: image.label.en,
                graphicMode: GraphicMode.IMAGE_2D,
                ...details,
            })),
);

const selectFloorPlan3DImage = createSelector(
    selectFloorPlanDetails,
    selectFloorPlanImages,
    (details, images) =>
        (images || [])
            // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
            .filter((image) => image.image_3d_id)
            // @ts-expect-error - TS7006 - Parameter 'image' implicitly has an 'any' type.
            .map((image) => ({
                id: image.image_3d_id,
                title: image.label.en,
                graphicMode: GraphicMode.IMAGE_3D,
                ...details,
            })),
);

const selectMatchingFloorPlans = (state: State) => (state.matchingFloorPlans.data || {}).hits;

const selectHasMatchingFloorPlans = createSelector(
    selectMatchingFloorPlans,
    selectProperty,
    (matchingFloorPlans, property) =>
        matchingFloorPlans?.length > 0 || property?.hasMatchingFloorPlans,
);

export {
    selectFloorPlan3DImage,
    selectFloorPlan2DImage,
    selectFloorPlanModel,
    selectMatchingFloorPlans,
    selectHasMatchingFloorPlans,
};
