import React from 'react';
import { Trans } from '@lingui/macro';

import type { MixedAd } from 'horizontal/types';
import { PriceType } from 'horizontal/types';

const getRequestablePriceLabel = (ad: MixedAd): React.ReactElement | null => {
    const priceType = ad.extraFields?.price_type;

    if (!priceType || priceType !== PriceType.REQUESTABLE) {
        return null;
    }

    return <Trans>Call for price</Trans>;
};

export default getRequestablePriceLabel;
