import cookie from 'cookie';
import pick from 'lodash/pick';

import { canonicalDomain } from 'strat/routing';

/**
 * Gets the domain to which cookies are bound, we use wildcard
 * here so that cookies set from subdomains can be read by other
 * subdomains and the root domain.
 */
const cookieDomain = (): string => CONFIG.runtime.COOKIE_DOMAIN || canonicalDomain().rootDomain;

/*
 *  Default options for cookies
 *  this is needed so the same options are used when setting and clearing cookies
 */
const cookieOptions = () => ({
    path: '/',
    domain: cookieDomain(),
    httpOnly: false,
    secure: CONFIG.runtime.ENABLE_SECURE_COOKIES && canonicalDomain().protocol === 'https',
});

/**
 * Collection of convenience functions for working with cookies.
 */
class Cookies {
    /**
     * @param cookiesSource - By default this is the `document`
     */
    static get<T extends Record<string, unknown>>(
        cookieNames: string[],
        cookiesSource: Record<string, string> = cookie.parse(document.cookie),
    ) {
        return Cookies.decodeValues<T>(pick(cookiesSource, cookieNames));
    }

    /**
     * @param cookieSource - By default this is the `document`
     */
    static getCookie<T = unknown>(cookieName: string, cookieSource?: Record<string, string>) {
        return Cookies.get<Record<string, T>>([cookieName], cookieSource)[cookieName];
    }

    /**
     * Decode cookies from an object.
     */
    static decodeValues<T extends Record<string, unknown>>(cookies: Record<string, string>) {
        const decodedCookies: T = {} as T;

        Object.keys(cookies).forEach((name: string) => {
            try {
                (decodedCookies as Record<string, unknown>)[name] = JSON.parse(cookies[name]);
            } catch (error) {
                (decodedCookies as Record<string, unknown>)[name] = cookies[name];
            }
        });

        return decodedCookies;
    }

    /**
     * Encodes an object of cookies ready to be set.
     */
    static encodeValues(cookies: Record<string, unknown>): Record<string, string> {
        const encodedCookies: Record<string, string> = {};
        Object.keys(cookies).forEach((name: string) => {
            const value = cookies[name];
            if (!value) {
                return;
            }

            if (typeof value !== 'string') {
                encodedCookies[name] = JSON.stringify(value);
            } else {
                encodedCookies[name] = value;
            }
        });

        return encodedCookies;
    }

    /**
     * Sets the values of all the specified cookies.
     */
    static set(cookies: Record<string, string>, maxAge: number | null = null) {
        Object.keys(cookies).forEach((key: string) => {
            // NOTE: don't invert this, like this, babel strips
            //       this code from the server bundle..
            if (process.env.IS_BROWSER) {
                document.cookie = cookie.serialize(key, cookies[key], {
                    path: '/',
                    maxAge: maxAge === null ? undefined : maxAge,
                    domain: cookieDomain(),
                    secure:
                        CONFIG.runtime.ENABLE_SECURE_COOKIES &&
                        canonicalDomain().protocol === 'https',
                });
            }
        });
    }

    /**
     * Clears the values of all cookies with the specified names.
     */
    static clear(cookieNames: Array<string>) {
        cookieNames.forEach((cookieName: string) => {
            if (process.env.IS_BROWSER) {
                document.cookie = cookie.serialize(cookieName, '', {
                    path: '/',
                    maxAge: 0,
                    domain: cookieDomain(),
                    secure:
                        CONFIG.runtime.ENABLE_SECURE_COOKIES &&
                        canonicalDomain().protocol === 'https',
                });
            }
        });
    }
}

export { cookieDomain, cookieOptions };

export default Cookies;
