import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import { ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import brandingSettings from '@app/branding/settings';
import FilterValues from 'strat/search/filterValues';

import { selectFlatCategoryFields } from 'horizontal/categoryFields';
import {
    createCategoryFieldsFacetingConfig,
    createCategoryFreeTextFacetingConfig,
    createCommonFieldsFacetingConfig,
    createProductFacetingConfig,
} from 'horizontal/search/execution';
import { selectCategoryHierarchyByExternalID } from 'horizontal/categories';
import type { SearchRouteParams } from 'horizontal/routes';

type Props = {
    readonly state: any;
    readonly searchRouteParams: SearchRouteParams;
    readonly withProductFacet?: boolean;
};

const createAdsSearchFacets = ({ state, searchRouteParams, withProductFacet }: Props) => {
    const categoryFields = selectFlatCategoryFields(state, searchRouteParams.category?.id);

    const categoryHierarchy = selectCategoryHierarchyByExternalID(
        state,
        searchRouteParams.category?.externalID,
    );
    const userLocation = state.user?.geoLocation?.closestLocation;

    const location =
        userLocation ||
        searchRouteParams?.location ||
        getFilterCollection(state).getFilterValue(FilterValues.location.attribute) ||
        brandingSettings.topLevelLocation;

    const locationHierarchy = location.hierarchy;

    const serializedFilters = [
        new ObjectExactFilter({
            attribute: FilterValues.location.attribute,
            value: location,
        }).serialize(),
    ];

    const freeTextQuery: string = searchRouteParams.freeTextQuery || '';

    return [
        ...createCommonFieldsFacetingConfig(locationHierarchy, categoryHierarchy),
        ...createCategoryFieldsFacetingConfig(categoryFields, location.externalID),
        ...createCategoryFreeTextFacetingConfig(freeTextQuery),
        // @ts-expect-error - TS2345 - Argument of type '(SerializedFilter<any> | null)[]' is not assignable to parameter of type 'SerializedFilter<unknown>[]'.
        ...(withProductFacet ? createProductFacetingConfig(serializedFilters) : []),
    ];
};

export default createAdsSearchFacets;
