import type { EnhancedLocation } from 'react-true-router/location';

export type AdDetailsRouteParams = {
    readonly slug: string;
    readonly externalID: string;
    readonly productPurchaseID?: number;
};

const createURL = ({
    slug,
    externalID,
    productPurchaseID,
}: AdDetailsRouteParams): EnhancedLocation => {
    // remove non ascii and - character situated between them when computing url
    /* eslint-disable no-control-regex */
    const strippedSlug = (slug || '')
        .replace(/[^\x00-\x7F\u0600-\u06FF]([-]+)[^\x00-\x7F\u0600-\u06FF]/g, '')
        .replace(/[^\x00-\x7F\u0600-\u06FF]/g, '');
    /* eslint-enable no-control-regex */
    const pathname = `/ad/${strippedSlug}-ID${externalID}.html`;
    return { pathname, search: { productPurchaseID: productPurchaseID } };
};

export default createURL;
