import * as React from 'react';
import { usePreviousState } from 'strat/util';

import { useSellerProfile } from 'horizontal/seller/hooks';
import type { LiteUserDTO } from 'horizontal/dtos';
import { useAdContactInfoRoles } from 'horizontal/ad/state';
import { useAdAgency, useFetchAdContactInfo } from 'horizontal/ad';
import { UserSettingsKey } from 'horizontal/userSettings/types';
import isAdInactive from '@app/adDetails/isAdInactive';
import { ContactPersonRole } from 'horizontal/types';
import { isEligibleForDelivery } from 'horizontal/util';
import useRequestDelivery from 'horizontal/adDetails/requestDelivery/useRequestDelivery';
import type { MixedAd } from 'horizontal/types';

export type CallToActionsStickyResult = {
    readonly canContactOnPhone: boolean;
    readonly canContactOnChat: boolean;
    readonly canBuyNow: boolean;
    readonly canCallOnShortNumber: boolean;
    readonly canContactOnWhatsApp: boolean;
    readonly showBuyWithDeliveryOnly: boolean;
    readonly seller: LiteUserDTO | null;
    readonly sellerWithPhone: LiteUserDTO | null;
    readonly loading: boolean;
};

export const PrimaryCTAs = Object.freeze({
    CALL: 'CALL',
    CHAT: 'CHAT',
});

export type CallToActionsStickyParams = {
    readonly ad: MixedAd;
};

const useCallToActionsSticky = ({ ad }: CallToActionsStickyParams): CallToActionsStickyResult => {
    const seller = useSellerProfile();
    const adContactInfoRoles = useAdContactInfoRoles();
    const adAgency = useAdAgency(ad);
    const { isAdOrdered } = useRequestDelivery(ad);
    const { contactInfo, fetchContactInfo, loading } = useFetchAdContactInfo(ad.externalID);
    const previousAdExternalID = usePreviousState(ad.externalID);

    const isInactiveAd = isAdInactive(ad);

    const canBuyNow = !isInactiveAd && !isAdOrdered && isEligibleForDelivery(ad);
    let canContactOnChat = false;
    let canContactOnPhone = false;
    let canContactOnWhatsApp = false;
    let canCallOnShortNumber = false;
    let showBuyWithDeliveryOnly =
        adContactInfoRoles?.includes(ContactPersonRole.SHOW_BUY_WITH_DELIVERY_ON_SEARCH_PAGE) ||
        false;
    if (contactInfo) {
        const roles: Array<Values<typeof ContactPersonRole>> = [
            ...(contactInfo?.roles || []),
            ...(adContactInfoRoles || []),
        ];

        // #180759880 remove fallback User Settings after we migrate contactpersons
        if (roles) {
            canContactOnChat = roles.includes(ContactPersonRole.ALLOW_CHAT_COMMUNICATION);
            canContactOnPhone = roles.includes(ContactPersonRole.SHOW_PHONE_NUMBER);
            canContactOnWhatsApp =
                adContactInfoRoles?.includes(ContactPersonRole.SHOW_WHATSAPP_PHONE_BUTTON) ?? false;
            canCallOnShortNumber = roles.includes(ContactPersonRole.SHOW_SHORT_NUMBER);
            showBuyWithDeliveryOnly = roles.includes(
                ContactPersonRole.SHOW_BUY_WITH_DELIVERY_ON_SEARCH_PAGE,
            );
        } else {
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'User'.
            canContactOnChat = seller[UserSettingsKey.ALLOW_CHAT_COMMUNICATION_IN_ADS];
            // @ts-expect-error - TS2339 - Property 'isPhoneNumberVisible' does not exist on type 'User'.
            canContactOnPhone = seller.isPhoneNumberVisible;
        }
    }

    React.useEffect(() => {
        // TODO #187790350, this should be refactored, previousAdExternalID was added to refresh contact info when the
        // ad is changed, but this is not a clean solution
        if (ad.externalID !== previousAdExternalID) {
            fetchContactInfo();
        }
    }, [fetchContactInfo, ad.externalID, previousAdExternalID]);

    const phoneNumber = contactInfo?.mobile || null;
    const proxyPhoneNumber = contactInfo?.proxyMobile || phoneNumber;
    const agencyShortNumber = adAgency?.shortNumber || null;
    const sellerWithPhone: LiteUserDTO | null = seller
        ? {
              ...(seller as LiteUserDTO),
              proxyPhoneNumber: canCallOnShortNumber ? agencyShortNumber : proxyPhoneNumber,
              phoneNumber: canCallOnShortNumber ? agencyShortNumber : phoneNumber,
          }
        : null;

    return {
        canContactOnPhone,
        canContactOnChat,
        canBuyNow,
        canCallOnShortNumber,
        canContactOnWhatsApp,
        showBuyWithDeliveryOnly,
        seller,
        sellerWithPhone,
        loading,
    };
};

export default useCallToActionsSticky;
