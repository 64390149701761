import { useSelector } from 'react-redux';

import { ProToolChatRoomRouteParams } from '@app/agencyPortal/routes/agencyPortalAdsRoute';
import { ChatWidgetRouteParams } from '@app/routes/chatWidgetRoute';
import { ChatFeatureStatus } from 'horizontal/chat/constants';
import type { ChatSubjectXID, ChatUserXID } from 'horizontal/chat/types';
import type { StratChatRoomRouteParams } from 'horizontal/routes/chatRoute';

import { selectChatFeatureAvailability, selectChatRouteParams } from './selectors';

export const useChatRouteParams = (): Partial<
    StratChatRoomRouteParams & ProToolChatRoomRouteParams & ChatWidgetRouteParams
> => useSelector(selectChatRouteParams) ?? {};

export const useIsChatRoomRoute = (
    contactXID?: ChatUserXID | null,
    subjectXID?: ChatSubjectXID | null,
): boolean => {
    const params = useChatRouteParams();
    if (!contactXID || !subjectXID) {
        return false;
    }

    return contactXID === params.contactXID && subjectXID === params.subjectXID;
};

export const useChatFeatureAccess = (): Values<typeof ChatFeatureStatus> =>
    useSelector(selectChatFeatureAvailability);
