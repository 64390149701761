import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from 'strat/state';

import { useNavigateToPostAdSuccess } from 'horizontal/adCreation/hooks';
import { useNavigateToAdDetails } from 'horizontal/adDetails';
import { useActivateAd } from 'horizontal/adLimits/hooks';
import { usePayProductPackages } from 'horizontal/payment';
import { setAdOrderDetails } from 'horizontal/businessPackages/state';
import { useFormattedPrice } from 'horizontal/util';
import type { Ad } from 'horizontal/types';

import { LimitPackageChoiceType } from './types';
import type { LimitPackageChoice } from './types';

const useCTAText = (selectedChoice?: LimitPackageChoice | null): string => {
    const i18n = useI18n();
    const price = selectedChoice?.discountPrice ?? selectedChoice?.price ?? 0;
    const formattedTotalPrice = useFormattedPrice(price);

    return React.useMemo(() => {
        switch (selectedChoice?.type) {
            case LimitPackageChoiceType.OFFER: {
                return t(i18n)`Pay ${formattedTotalPrice}`;
            }

            case LimitPackageChoiceType.PURCHASED:
                return t(i18n)`Post Ad`;

            case LimitPackageChoiceType.WAIT:
            default:
                return t(i18n)`Preview ad`;
        }
    }, [i18n, selectedChoice, formattedTotalPrice]);
};

const useCTA = (selectedChoice?: LimitPackageChoice | null, ad?: Ad | null) => {
    const navigateToAdDetails = useNavigateToAdDetails(ad);
    const navigateToPostAdSuccess = useNavigateToPostAdSuccess(ad);
    const { onPayProductPackages, loading: checkoutLoading } = usePayProductPackages();
    const { loading: consumptionLoading, isError, activateAd } = useActivateAd();
    const text = useCTAText(selectedChoice);
    const dispatch = useDispatch<AppDispatch>();

    const onClick = React.useCallback(() => {
        dispatch(setAdOrderDetails(''));
        switch (selectedChoice?.type) {
            case LimitPackageChoiceType.OFFER:
                if (selectedChoice?.id && ad) {
                    onPayProductPackages([
                        {
                            external_id: selectedChoice.id.toString(),
                            ad_id: ad.id,
                        },
                    ]);
                }

                break;
            case LimitPackageChoiceType.PURCHASED:
                if (ad?.externalID && selectedChoice?.id) {
                    activateAd({
                        adExternalID: ad?.externalID,
                        productPurchaseID: selectedChoice.id.toString(),
                        onSuccess() {
                            navigateToPostAdSuccess(selectedChoice?.hasFeaturedProduct);
                        },
                    });
                }

                break;
            case LimitPackageChoiceType.WAIT:
                navigateToAdDetails();
                break;
            default:
                break;
        }
    }, [
        dispatch,
        navigateToPostAdSuccess,
        navigateToAdDetails,
        ad,
        selectedChoice,
        onPayProductPackages,
        activateAd,
    ]);

    return { loading: consumptionLoading || checkoutLoading, isError, text, onClick };
};

export { useCTAText, useCTA };
