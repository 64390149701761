import { type AppDispatch } from 'strat/state';
import { InternalAPI, StratAPI } from 'strat/api';
import { APIError, APIErrorReason } from 'strat/api/error';
import { ensureUserWithEmail } from 'strat/auth/ensureUserWithEmail';

import { AlertType, PushNotificationsSubscriptionType, NotificationType } from '../types';

import { Actions } from './actions';
import { propertyAlertParams, getAlertParams } from './alertParams';

export const setPushNotificationAlert =
    (data: PushNotificationsSubscriptionType) => (dispatch: AppDispatch) =>
        dispatch({
            type: Actions.UPDATE_ALERT,
            alert: data,
            notificationType: NotificationType.PUSH_NOTIFICATION,
        });

export const setAlertActive =
    (alertID: any, isActive: any, notificationType: any) => (dispatch: AppDispatch) =>
        new InternalAPI()
            .updateSubscription(alertID, notificationType, { isActive })
            .then(({ data }) => {
                dispatch({
                    type: Actions.UPDATE_ALERT,
                    alert: data,
                    notificationType,
                });
            });

export const setAlertFrequency =
    (alertID: any, frequency: any, notificationType: any) => (dispatch: AppDispatch) =>
        new InternalAPI()
            .updateSubscription(alertID, notificationType, { frequency })
            .then(({ data }) => {
                dispatch({
                    type: Actions.UPDATE_ALERT,
                    alert: data,
                    notificationType,
                });
            });

export const addRecommendedEmailAlert = () => (dispatch: AppDispatch, getState: any) =>
    new InternalAPI()
        .addRecommendedEmailAlert(AlertType.EXPLICIT, getState().i18n.language)
        .then(({ data }) => {
            dispatch({
                type: Actions.UPDATE_ALERT,
                alert: data.length ? data[0] : null,
                notificationType: NotificationType.RECOMMENDED_EMAIL,
            });
        });

/*
 * If the alertID is null (the alert does not exist, create one).
 * This is here because the toggle for this type of alert is present all the time in the alerts management.
 */
export const createOrUpdateRecommendedAlert = (alertID: any, isActive: any) => {
    if (!alertID) {
        return addRecommendedEmailAlert();
    }
    return setAlertActive(alertID, isActive, NotificationType.RECOMMENDED_EMAIL);
};

export const addAlert = (property: any) => (dispatch: AppDispatch, getState: any) => {
    const params = getAlertParams(property, getState);
    const language = getState().i18n.language;

    return new InternalAPI().addAlert(params, AlertType.EXPLICIT, language).then((response) => {
        dispatch({
            type: Actions.ADD_ALERT,
            data: response.data,
        });

        return null;
    });
};

export const addAlertByEmail =
    (userExternalID: string, userEmail: string) => (dispatch: AppDispatch, getState: any) => {
        const params = getAlertParams(null, getState);
        const language = getState().i18n.language;

        return new StratAPI(language)
            .addAlertByEmail(userEmail, userExternalID, params)
            .then((response) => {
                const errorContext = {
                    response,
                    userExternalID,
                    email: userEmail,
                    params: JSON.stringify(params),
                };
                if (response.status === 422) {
                    throw new APIError('Adding an alert by email that already exists', {
                        reason: APIErrorReason.ALREADY_EXISTS,
                        ...errorContext,
                    });
                } else if (response.status < 200 || response.status >= 300) {
                    throw new APIError('Adding an alert by email non-2xx status code', {
                        reason: APIErrorReason.UNKNOWN_ERROR,
                        ...errorContext,
                    });
                }

                return response;
            });
    };

export const addRecentlyViewedEmailAlert = () => (dispatch: AppDispatch, getState: any) =>
    new InternalAPI()
        .addRecentlyViewedEmailAlert(getState().i18n.language)
        .then(({ status, data }) => {
            if (status === 200) {
                dispatch({
                    type: Actions.UPDATE_ALERT,
                    alert: data.length ? data[0] : null,
                    notificationType: NotificationType.RECENTLY_VIEWED_EMAIL,
                });
            }
        });

/* Add a new alert to the email account specified in the form values */
export const addSilentAlert =
    (values: any, property: any) => (dispatch: AppDispatch, getState: any) => {
        if (!CONFIG.build.WITH_NEW_ALERTS_SYSTEM) {
            return;
        }

        if (!values.keepMeInformed) {
            return;
        }

        const state = getState();
        const loggedIn = state.user.loggedIn;
        const params = propertyAlertParams(property);
        const alertParams = {
            ...params,
            locations: (params.locations || []).map((loc) => loc.slug),
        } as const;
        const language = state.i18n.language;

        if (loggedIn) {
            new InternalAPI().addAlert(alertParams, AlertType.SILENT, language).then((response) => {
                dispatch({
                    type: Actions.ADD_ALERT,
                    data: response.data,
                });
            });

            if (CONFIG.build.SEND_RECOMMENDATION_EMAIL_ALERTS) {
                dispatch(addRecommendedEmailAlert());
            }

            return;
        }

        return dispatch(
            ensureUserWithEmail(values.email, { silentlyFail: true, shouldLogin: false }),
        ).then((ensuredUserData) => {
            /*
             * Create an alert of each type if they are enabled on our end.
             *
             * Create a saved search one since these are typically enabled by default.
             * This is necessary to be done anything else because it upserts the user.
             *
             */
            const stratApi = new StratAPI(language);
            stratApi
                .addAlertByEmail(values.email, ensuredUserData.externalID, alertParams)
                .then(() => {
                    if (CONFIG.build.SEND_RECOMMENDATION_EMAIL_ALERTS) {
                        stratApi.addRecommendedEmailAlertByEmail(
                            values.email,
                            ensuredUserData.externalID,
                        );
                    }
                    if (CONFIG.build.SEND_RECENTLY_VIEWED_EMAIL_ALERTS) {
                        stratApi.addRecentlyViewedEmailAlertByEmail(
                            values.email,
                            ensuredUserData.externalID,
                        );
                    }
                });
        });
    };

/*
 * If the alertID is null (the alert does not exist, create one).
 * This is here because the toggle for this type of alert is present all the time in the alerts management.
 */
export const createOrUpdateRecentlyViewedAlert = (alertID: any, isActive: any) => {
    if (!alertID) {
        return addRecentlyViewedEmailAlert();
    }
    return setAlertActive(alertID, isActive, NotificationType.RECENTLY_VIEWED_EMAIL);
};
