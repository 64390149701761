type GlobalState = {
    dialogStack: {
        stacked: boolean;
        stackGroup: string;
    };
};

const selectDialogStack = (state: GlobalState) => state.dialogStack;

export { selectDialogStack };
