import React from 'react';
import CategoryUtils from '@app/branding/category';

import Context from 'horizontal/adCreation/context';
import { ExtraFields } from 'horizontal/fields/commonPostingFields';
import { Category, CategoryFieldChoice, FlatCategoryField } from 'horizontal/types';

type ChoiceIconMappingFn = (choiceSlug: string) => string;

const CarFieldChoiceIconMapping: Record<FlatCategoryField['attribute'], ChoiceIconMappingFn> = {
    [ExtraFields.make.attribute]: (slug: string) =>
        `${CONFIG.build.THUMBNAILS_URL || ''}/static/logos/${slug}.png`,
};

const useCategoryFieldChoiceIconURL = (
    field?: FlatCategoryField['attribute'] | null,
    category?: Category | null,
    choice?: CategoryFieldChoice | null | undefined,
): string | null | undefined => {
    const [contextCategory] = React.useContext(Context);
    const activeCategory = category || contextCategory;

    if (!activeCategory || !field || !choice) {
        return;
    }

    if (CategoryUtils.isOfCarsSubType(activeCategory) && choice.slug) {
        return CarFieldChoiceIconMapping[field]?.(choice.slug as string);
    }
};

export default useCategoryFieldChoiceIconURL;
