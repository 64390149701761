import React from 'react';

import FawryPayment from 'horizontal/payment/fawry';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly referenceNumber: string;
};

const PaymentFawryPage = {
    head: () => <PrivateNonIndexableHead />,
    body: (props: Props) => <FawryPayment {...props} />,
} as const;

export default PaymentFawryPage;
