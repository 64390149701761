//@ts-nocheck
import type { Dispatch } from 'redux';
import { setFilters } from '@sector-labs/fe-search-redux/state';
import { ExactFilter, FilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { I18n } from '@lingui/core';

import { FilterValues } from 'strat/search';

const resetFilters =
    (i18n: I18n, customDefaultValues: any = {}) =>
    (dispatch: Dispatch) => {
        const filters = new FilterCollection();
        filters.refine(
            new ExactFilter({
                attribute: FilterValues.purpose.attribute,
                value: customDefaultValues?.purpose || FilterValues.purpose.default,
            }),
        );
        const defaultCategorySlug = (FilterValues.category.default || {}).slug;
        filters.refine(
            new ExactFilter({
                attribute: FilterValues.category.attribute,
                value:
                    customDefaultValues?.category ||
                    FilterValues.category.tree(i18n)[defaultCategorySlug],
            }),
        );
        dispatch(setFilters(filters));
    };

export default resetFilters;
