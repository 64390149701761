import * as React from 'react';
import { Flex, Header, Text } from 'strat/components';
import BaseDialog from 'strat/components/dialog';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';

import CloseIcon from 'horizontal/assets/icons/iconClose.svg';

import styles from './styles/adExtraFeaturesDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly formattedExtraField: any;
    readonly title: string;
};

type TypeSeparatedFields = Record<string, string[]>;

const AdExtraFeaturesDialog = ({
    formattedExtraField,
    visible,
    onVisibilityChanged,
    title,
}: Props) => {
    const groupedFields =
        (formattedExtraField &&
            (
                formattedExtraField?.formattedValue as (string | CategoryFieldGroupedChoices)[]
            ).filter((feature) => !!feature)) ||
        [];
    const typeSeaparatedFields: TypeSeparatedFields = {};
    const regularFields = [] as string[];

    /**
     * we have two type of `Features`:
     * 1- ones that are grouped under one type
     * 2- string features that are not under any grouping.
     */
    if (typeof groupedFields[0] === 'object') {
        groupedFields.forEach((group: any) => {
            group.choices.all.forEach((field: any) => {
                typeSeaparatedFields[group.label] = typeSeaparatedFields[group.label] || [];
                typeSeaparatedFields[group.label].push(field.label);
            });
        });
    } else if (typeof groupedFields[0] === 'string') {
        groupedFields.forEach((fieldTitle: string) => {
            regularFields.push(fieldTitle);
        });
    }

    const renderTypeSeparatedFields = () => {
        return Object.keys(typeSeaparatedFields).map((key) => {
            return (
                <Flex column>
                    <div>
                        <Text.Regular bold>{key}</Text.Regular>
                    </div>
                    <Flex column className={styles.listItemsContainer}>
                        {typeSeaparatedFields[key].map((feature: any) => (
                            <span className={styles.listItemValue} key={feature}>
                                {feature}
                            </span>
                        ))}
                    </Flex>
                </Flex>
            );
        });
    };

    const renderRegularFields = () => {
        return (
            <Flex column>
                <Flex column className={styles.listItemsContainer}>
                    {regularFields.map((fieldTitle: string) => (
                        <span className={styles.listItemValue} key={fieldTitle}>
                            {fieldTitle}
                        </span>
                    ))}
                </Flex>
            </Flex>
        );
    };

    const renderFields = () => {
        if (Object.keys(typeSeaparatedFields).length) {
            return renderTypeSeparatedFields();
        }
        return renderRegularFields();
    };

    return (
        <BaseDialog
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.dialogContainer}
        >
            <Header className={styles.header}>
                <CloseIcon
                    className={styles.closeIcon}
                    onClick={() => onVisibilityChanged(false)}
                />
                <span className={styles.title}>{title}</span>
            </Header>
            <Flex column className={styles.content}>
                {renderFields()}
            </Flex>
        </BaseDialog>
    );
};

export default AdExtraFeaturesDialog;
