import { ClockState } from './types';

const defaultState: ClockState = Object.freeze({
    server: null,
    client: null,
    drift: null,
});

/**
 * Reducer for the state.
 */
const clockReducer = (state: ClockState = defaultState) => {
    /**
     * During store initiation on the express app we need to set only the server time
     * */
    if (process.env.IS_SERVER && !state?.server) {
        return {
            server: Date.now(),
            client: null,
            drift: null,
        };
    }

    /** During store retrival on the client browser we need to set the client time and calculate
     * the drift between server time and client time
     *which will be used to correct any inconsistencies between the server and client time */
    if (!process.env.IS_SERVER && !state?.client && !state?.drift && state?.server) {
        const { domContentLoadedEventEnd } = (
            window?.performance?.getEntriesByType
                ? window?.performance?.getEntriesByType('navigation')?.[0]
                : {}
        ) as PerformanceNavigationTiming;
        const pageStartLoadingTime = Date.now() - Math.ceil(domContentLoadedEventEnd || 0);
        return {
            ...state,
            client: pageStartLoadingTime,
            drift: state.server - pageStartLoadingTime,
        };
    }

    return state;
};

export default clockReducer;
