import React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { PageTypes } from 'strat/gtm';
import Currency from 'strat/i18n/money/currency';
import settings from '@app/branding/settings';
import { Text, Flex, LoadingSpinnerOverlay, Input } from 'strat/components';

import { useAdCategoryFields } from 'horizontal/ad';
import { usePaymentMethods, useTrackFreeAdUndertaking } from 'horizontal/payment/hooks';
import { PaymentSelectionFreeAdUndertakingRoute } from 'horizontal/routes';
import type { FullAd } from 'horizontal/types';
import useMakePayment from 'horizontal/payment/hooks/useMakePayment';
import { useUserCreditCards } from 'horizontal/payment/hooks';

import type { PaymentMethodSelectedType } from './types';
import PaymentMethodsContainer from './paymentMethodsContainer';
import PaymentSelectionFreeAdUndertakingHeader from './paymentSelectionFreeAdUndertakingHeader';
import AdCard from './adCard';
import useFreeAdCommission from './hooks/useFreeAdCommission';
import styles from './styles/paymentSelectionFreeAdUndertaking.cssm';

type Props = {
    readonly ad: FullAd;
};

const RenderRight = () => {
    const i18n = useI18n();
    const baseCurrency = settings.baseCurrencyName;
    return (
        <Flex justifyCenter alignCenter className={styles.renderRight}>
            <Text.Regular>{Currency.symbol(i18n, baseCurrency)}</Text.Regular>
        </Flex>
    );
};

const PaymentSelectionFreeAdUndertaking = ({ ad }: Props) => {
    useAdCategoryFields(ad);
    useTrackFreeAdUndertaking(ad);
    const { creditCards, cardsLoading } = useUserCreditCards();
    const { paymentMethods, paymentMethodsLoading } = usePaymentMethods({
        withBankTransferPaymentMethod: settings.hasBankPaymentMethodEnabled,
    });

    const { commissionAmount, packages, validateCommission, onCommissionChange, error } =
        useFreeAdCommission(ad);

    const { onPaymentSelected, paymentLoading, paymentError } = useMakePayment({ ad });

    return (
        <Flex stretchHeight column>
            <PaymentSelectionFreeAdUndertakingHeader />
            <LoadingSpinnerOverlay
                visible={paymentMethodsLoading || cardsLoading || paymentLoading}
            />
            <Flex column className={styles.content}>
                <Text.XLarge bold className={styles.titleWrapper}>
                    <Trans>Free Ad Fee</Trans>
                </Text.XLarge>
                <div className={styles.adCardWrapper}>
                    <AdCard ad={ad} />
                </div>

                <div className={styles.inputWrapper}>
                    <div className={styles.commissionTitle}>
                        <Text.Regular bold>
                            <Trans>Fee amount</Trans>
                        </Text.Regular>
                    </div>
                    <Input
                        name={'Fee'}
                        renderRight={RenderRight}
                        className={styles.inputContainer}
                        value={commissionAmount}
                        onChange={onCommissionChange}
                        onBlur={validateCommission}
                    />
                    {error && (
                        <div className={styles.errorMessage}>
                            <span>
                                <Trans>
                                    * The minimum amount is {settings.minFreeAdCommission}{' '}
                                    {settings.baseCurrencyName}
                                </Trans>
                            </span>
                        </div>
                    )}
                    {paymentError && (
                        <div className={styles.errorMessage}>
                            <span>{paymentError}</span>
                        </div>
                    )}
                </div>

                <PaymentMethodsContainer
                    creditCards={creditCards}
                    paymentMethods={paymentMethods}
                    onPaymentMethodSelected={({ source, card }: PaymentMethodSelectedType) =>
                        onPaymentSelected(source, {
                            page_type: PageTypes.FREE_AD,
                            routeIdentifier: PaymentSelectionFreeAdUndertakingRoute.name,
                            commissionAmount: commissionAmount,
                            packages: packages,
                            card,
                            ad,
                        })
                    }
                />
            </Flex>
        </Flex>
    );
};

export default PaymentSelectionFreeAdUndertaking;
