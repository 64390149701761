import EliteIcon from '@app/assets/icons/iconElitePackage_noinline.svg';

import PostMoreAdsAndFeaturedIcon from 'horizontal/assets/icons/iconPostMoreAdsAndFeatured_noinline.svg';
import PostMoreAdsAndBoostIcon from 'horizontal/assets/icons/iconPostMoreAdsAndBoost_noinline.svg';
import AutoBoostIcon from 'horizontal/assets/icons/iconAutoBoostPackage_noinline.svg';
import FeaturedIcon from 'horizontal/assets/icons/iconFeaturedAd_noinline.svg';
import PostMoreAdsIcon from 'horizontal/assets/icons/iconPostMoreAds_noinline.svg';
import { PackageType, PackageTypeValues } from 'horizontal/types';

const usePackagesIcons = ({ type }: { type: PackageTypeValues | null }) => {
    switch (type) {
        case PackageType.FEATURED:
            return FeaturedIcon;

        case PackageType.REFRESH:
        case PackageType.AUTO_REFRESH:
        case PackageType.AUTO_REFRESH_CATEGORY:
            return AutoBoostIcon;

        case PackageType.LIMIT:
            return PostMoreAdsIcon;

        case PackageType.LIMIT_AND_FEATURED:
            return PostMoreAdsAndFeaturedIcon;

        case PackageType.ELITE:
            return EliteIcon;

        case PackageType.LIMIT_AND_AUTO_REFRESH:
            return PostMoreAdsAndBoostIcon;

        default:
            return null;
    }
};

export default usePackagesIcons;
