import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';

class PackagePurchaseErrorRoute extends Route {
    constructor() {
        super(RouteNames.PACKAGE_PURCHASE_ERROR, [
            [
                '^/packagePurchaseError/',
                {
                    name: 'errorType',
                    pattern: '([0-9_a-z]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(params: { errorType: string }): EnhancedLocation {
        return { pathname: `/packagePurchaseError/${params.errorType}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            match: {
                params: { errorType },
            },
        } = context;

        context.rendering.renderPage(Page.PACKAGE_PURCHASE_ERROR, {
            errorStatus: errorType,
        });
    }
}

export default new PackagePurchaseErrorRoute();
