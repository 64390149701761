import { matchChoiceValue } from 'strat/util';

import { CategoryFieldValueType, LocationRole } from 'horizontal/types';
import type { Ad, FlatCategoryField } from 'horizontal/types';

import CommonPostingFields, { ContactInfoFields, AdDeliveryFields } from './commonPostingFields';

const defaultForType = (valueType: Values<typeof CategoryFieldValueType>) => {
    switch (valueType) {
        case CategoryFieldValueType.ENUM:
        case CategoryFieldValueType.STRING:
            return '';
        case CategoryFieldValueType.BOOLEAN:
            return false;
        default:
            return null;
    }
};

const generateAdExtraFieldsInitialValues = (fields: Array<FlatCategoryField>, ad: Ad) => {
    return fields.reduce<Record<string, any>>((acc, field) => {
        let fieldValue = ad.extraFields?.[field.attribute];
        if (field.choices && field.choices.length) {
            if (Array.isArray(fieldValue)) {
                // @ts-expect-error - TS2322 - Type '(Primitive | null)[]' is not assignable to type 'AdExtraFieldValue | undefined'.
                fieldValue = fieldValue
                    .map((v) =>
                        field.choices.some((choice) => matchChoiceValue(choice, v)) ? v : null,
                    )
                    .filter((v) => !!v);
            } else if (!field.choices.some((choice) => matchChoiceValue(choice, fieldValue))) {
                // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'AdExtraFieldValue | undefined'.
                fieldValue = null;
            }
        }
        // avoid override the extra fields with value 0
        acc[field.attribute] = Object.prototype.hasOwnProperty.call(ad.extraFields, field.attribute)
            ? fieldValue
            : defaultForType(field.valueType);
        return acc;
    }, {});
};

const generateContactInfoInitialValues = (ad?: Ad) => {
    return Object.values(ContactInfoFields).reduce<Record<string, any>>( // $FlowFixMe
        (acc, obj) => ({
            ...acc,
            // $FlowFixMe
            [obj.attribute]:
                // $FlowFixMe
                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'AdPublicContactInfo'.
                ad?.contactInfo?.[obj.attribute] || obj.defaultValue,
        }),
        {},
    );
};

const generateInitialValues = (fields: Array<FlatCategoryField>, ad?: Ad) => {
    if (ad) {
        const extraFields = generateAdExtraFieldsInitialValues(fields, ad);
        const contactInfoFields = generateContactInfoInitialValues(ad);

        const categoryField = {
            [CommonPostingFields.category_id.attribute]: ad.category.slice(-1)[0].externalID,
        } as const;

        return {
            ...Object.values(CommonPostingFields).reduce<Record<string, any>>( // $FlowFixMe
                (acc, obj) => ({
                    ...acc,
                    // $FlowFixMe
                    [obj.attribute]:
                        // $FlowFixMe
                        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Ad'.
                        ad[obj.attribute] || obj.defaultValue,
                }),
                {},
            ),
            ...extraFields,
            ...contactInfoFields,
            ...categoryField,
        };
    }

    const extraFields = fields.reduce<Record<string, any>>((acc, field) => {
        acc[field.attribute] = defaultForType(field.valueType);
        return acc;
    }, {});

    return {
        ...Object.values(CommonPostingFields).reduce<Record<string, any>>(
            (acc, obj) => ({
                ...acc,
                // $FlowFixMe
                [obj.attribute]: obj.defaultValue,
            }),
            {},
        ),
        ...generateContactInfoInitialValues(),
        ...extraFields,
    };
};

const generateDeliveryInfoInitialValues = (ad: Ad) => {
    return {
        [AdDeliveryFields.weight.attribute]: ad?.deliveryInformation?.weight
            ? parseFloat(ad.deliveryInformation.weight)
            : AdDeliveryFields.weight.defaultValue,
        [AdDeliveryFields.pickup_address_id.attribute]:
            ad?.deliveryInformation?.pickupAddressID ||
            AdDeliveryFields.pickup_address_id.defaultValue,
        [AdDeliveryFields.is_deliverable.attribute]: !!(
            ad?.deliveryInformation?.weight && ad?.deliveryInformation?.pickupAddressID
        ),
        [AdDeliveryFields.location_delivery_role.attribute]: ad?.deliveryInformation
            ?.pickupAddressID
            ? LocationRole.ALLOWS_DELIVERY
            : AdDeliveryFields.location_delivery_role.defaultValue,
        [AdDeliveryFields.delivery_terms.attribute]: AdDeliveryFields.delivery_terms.defaultValue,
        [AdDeliveryFields.length.attribute]: ad?.deliveryInformation?.length
            ? parseFloat(ad.deliveryInformation.length)
            : AdDeliveryFields.length.defaultValue,
        [AdDeliveryFields.height.attribute]: ad?.deliveryInformation?.height
            ? parseFloat(ad.deliveryInformation.height)
            : AdDeliveryFields.height.defaultValue,
        [AdDeliveryFields.width.attribute]: ad?.deliveryInformation?.width
            ? parseFloat(ad.deliveryInformation.width)
            : AdDeliveryFields.width.defaultValue,
        [AdDeliveryFields.delivery_type.attribute]: ad?.deliveryInformation?.deliveryType
            ? ad.deliveryInformation.deliveryType
            : AdDeliveryFields.delivery_type.defaultValue,
    };
};

const generateInitialValuesWithDelivery = (fields: Array<FlatCategoryField>, ad?: Ad) => {
    if (ad) {
        const deliveryFields = generateDeliveryInfoInitialValues(ad);
        return {
            ...deliveryFields,
            ...generateInitialValues(fields, ad),
        };
    }

    return {
        ...Object.values(AdDeliveryFields).reduce<Record<string, any>>(
            (acc, obj) => ({
                ...acc,
                // $FlowFixMe
                [obj.attribute]: obj.defaultValue,
            }),
            {},
        ),
        ...generateInitialValues(fields),
    };
};

export { generateInitialValues, generateInitialValuesWithDelivery };
