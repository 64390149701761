import isEqual from 'lodash/isEqual';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { FilterValues } from 'strat/search';
import { PropertyCompletionStatus, PropertyType } from 'strat/property/types';

import bedsOfInterestFor from './bedsOfInterestFor';

const ACCEPTED_FILTERS = Object.freeze([
    FilterValues.purpose.attribute,
    FilterValues.location.attribute,
    FilterValues.category.attribute,
    FilterValues.page.attribute,
    FilterValues.studioFilter.key,
]);

const shouldRenderChildLocations = (filters: FilterCollection): boolean => {
    let shouldRender = true;
    filters.forEach((filter) => {
        // If a filter doesn't have a valid key, no need to hide the panel.
        if (!filter.key) {
            return;
        }

        // If a filter carries its default value, no need to hide the panel.
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ page: { attribute: string; default: null; }; totalProductCount: string; studioFilter: any; floorPlanID: { attribute: string; default: null; displayName: (i18n: any) => any; compactDisplayName: (i18n: any) => any; }; ... 38 more ...; contractRenewalStatus: { ...; }; }'.
        if (isEqual(filter.value, FilterValues?.[filter.key]?.default)) {
            return;
        }

        // If this is a bedroom filter, and its value is accepted, no need to hide the panel.
        if (
            filter.key === FilterValues.beds.attribute &&
            (bedsOfInterestFor(
                // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                filters.getFilterValue(FilterValues.category.attribute, {}).slug,
                // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            ).includes(filter.value[0]) ||
                // @ts-expect-error - TS2571 - Object is of type 'unknown'.
                filter.value.length === 1)
        ) {
            return;
        }

        // If this is an accepted filter, no need to hide the panel.
        if (ACCEPTED_FILTERS.includes(filter.key)) {
            return;
        }

        if (
            CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
            filter.key === FilterValues.completionStatus.attribute &&
            (filter.value === PropertyCompletionStatus.OFF_PLAN ||
                filter.value === PropertyCompletionStatus.COMPLETED)
        ) {
            return;
        }

        if (
            CONFIG.build.STRAT_ENABLE_NEW_PROJECTS_LPV &&
            filter.key === FilterValues.type.attribute &&
            filter.value === PropertyType.PROJECT
        ) {
            return;
        }

        shouldRender = false;
    });

    return shouldRender;
};

export { shouldRenderChildLocations };
