import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import styles from './styles/profileHeader.cssm';

type Props = {
    header: React.ReactNode;
};

const ProfileHeader = ({ header }: Props) => (
    <Flex column className={styles.headerSection}>
        <Text.Regular className={styles.profile}>
            <Trans>Profile</Trans>
        </Text.Regular>
        <Text.XXLarge bold>{header}</Text.XXLarge>
    </Flex>
);

export default ProfileHeader;
