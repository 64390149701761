import React from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { isNonEmptyArray } from 'strat/types/conditions';

import { MilestonesPaymentPlanMilestone } from './useMilestonesPaymentTimeline';

type Props = {
    milestone: MilestonesPaymentPlanMilestone;
    renderInfo: (milestone: MilestonesPaymentPlanMilestone) => React.ReactElement;
};

const MilestoneInfo = ({ milestone, renderInfo }: Props) => {
    const i18n = useI18n();
    const hasScheduleInfo =
        i18n.locale === CONFIG.build.LANGUAGE_CODE &&
        milestone.installments &&
        isNonEmptyArray(milestone.installments);

    if (!hasScheduleInfo) {
        return null;
    }

    return (
        <>
            {` • `}
            <Trans>Payment Schedule</Trans>
            {renderInfo(milestone)}
        </>
    );
};

export default MilestoneInfo;
