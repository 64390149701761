import { SearchService, SearchJob } from '@sector-labs/fe-search-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { createRandomEliteAndFeaturedFirstSlottingConfig } from '@sector-labs/strat-horizontal-core';
import { Slots } from '@sector-labs/fe-search-redux/slots';
import {
    ObjectExactFilter,
    FilterCollection,
    ExistFilter,
    ExactFilter,
    RangeFilter,
} from '@sector-labs/fe-search-redux/filters';
import { ObjectRefinementFilter } from '@sector-labs/fe-search-redux/filters';
import { selectLanguage } from 'strat/i18n/language';
import { determineAdsIndexName } from 'strat/search/indexNames';
import FetcherFactory from 'strat/fetcher';
import settings from '@app/branding/settings';

import { hitsTransformer } from 'horizontal/search/hits';
import type { LiteAd } from 'horizontal/types';

const factory = new FetcherFactory('verticalsPromotedAds', (params: any, state: any) => {
    const backend = selectActiveSearchBackend(state);
    const language = selectLanguage(state);
    const indexName = determineAdsIndexName({ language });
    const filters = new FilterCollection();

    const { promotedAdsSearchParams } = settings.verticalProperties;

    filters.refine(
        new ExistFilter({
            attribute: 'agency',
        }),
    );
    filters.refine(
        new ObjectExactFilter({
            attribute: 'category.id',
            value: params.categoryId,
        }),
    );

    promotedAdsSearchParams.exactFilters.forEach(({ attribute, value }) => {
        filters.refine(
            new ExactFilter({
                attribute,
                value,
            }),
        );
    });

    promotedAdsSearchParams.refineFilters.forEach(({ attribute, value }) => {
        filters.refine(
            new ObjectRefinementFilter({
                attribute,
                value,
            }),
        );
    });

    promotedAdsSearchParams.rangeFilters.forEach(({ attribute, value }) => {
        filters.refine(
            new RangeFilter({
                attribute,
                value,
            }),
        );
    });

    const relatedAdsJob = new SearchJob(indexName, filters, {
        hitsPerPage: 4,
        activeSlotRule: 'default',
        slotRules: createRandomEliteAndFeaturedFirstSlottingConfig({
            indexName,
            hitsPerSlot: [4, 0, Slots.DISABLED],
        }),
    });

    // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
    const relatedAdsPromise = new SearchService({ backend }).fetchJob(relatedAdsJob);

    return relatedAdsPromise.then((response) => {
        // @ts-expect-error - TS2345 - Argument of type 'readonly SearchResponseHit[]' is not assignable to parameter of type 'LiteAd[]'.
        const transformedHits = hitsTransformer<LiteAd>(response.hits, language);
        return {
            status: 200,
            data: transformedHits,
        };
    });
});

const fetchVerticalsPromotedAds = factory.creator();

const verticalsPromotedAdsReducer = factory.reducer();

export { fetchVerticalsPromotedAds };

export default verticalsPromotedAdsReducer;
