import { CategoryFieldsFilters, FlatCategoryField, LiteCategory } from 'horizontal/types';
import { encodeFilter } from 'horizontal/routes/encodeURLFilters';

const encodeFilters = (
    categoryFields: Array<FlatCategoryField>,
    filters: CategoryFieldsFilters,
    category?: LiteCategory | null,
): string =>
    categoryFields
        .map((field) => {
            const fieldValue = filters[field.attribute];
            if (!fieldValue) {
                return null;
            }
            if (category && field.categoryID !== category.id) {
                return null;
            }
            return encodeFilter(field, fieldValue);
        })
        .filter((filter) => !!filter)
        .sort()
        .join(',');

export default encodeFilters;
