import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { PageWidthWrapper } from 'strat/generic';
import { Text } from 'strat/components';

import { selectFeaturedAgencies } from 'horizontal/featuredAgencies/selectors';

import FeaturedAgenciesCarousel from './featuredAgenciesCarousel';
import styles from './styles/featuredAgencies.cssm';

const FeaturedAgencies = () => {
    const i18n = useI18n();
    const featuredAgencies = useSelector(selectFeaturedAgencies);
    const link = t(i18n)`verticals_featured_agencies_contact_link`;

    if (!featuredAgencies.length) {
        return null;
    }

    return (
        <PageWidthWrapper className={styles.featuredAgenciesSection}>
            <Text.Base bold className={styles.sectionTitle}>
                <Trans>Explore Featured Agencies</Trans>
            </Text.Base>
            <div className={styles.section}>
                <Text.Large className={styles.sectionSubtitle}>
                    <Trans>Explore our trusted agencies</Trans>
                </Text.Large>
            </div>
            <div className={styles.section}>
                <FeaturedAgenciesCarousel />
            </div>
            <div className={styles.section}>
                <Text.XLarge bold>
                    <Trans>Become a trusted Agency ?</Trans>
                </Text.XLarge>
            </div>
            <Text.Large className={styles.joinUs}>
                <Trans>Join our ever growing group of brokers covering all of Egypt</Trans>
            </Text.Large>
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.contactUsButton}
            >
                <Text.Large bold>
                    <Trans>Contact us</Trans>
                </Text.Large>
            </a>
        </PageWidthWrapper>
    );
};

export default FeaturedAgencies;
