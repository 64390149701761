//@ts-nocheck
import { sessionReducer } from '@sector-labs/fe-auth-redux/state';

import authenticationReducer from 'strat/auth/state';
import bundleReducer from 'strat/bundle/state';
import layoutReducer from 'strat/layout/state';
import platformReducer from 'strat/platform/state';
import clockReducer from 'strat/clock/state';
import purposeReducer from 'strat/purpose/state';
import i18nReducer from 'strat/i18n/state';
import settingsReducer from 'strat/settings/state';
import autoTranslateReducer from 'strat/autoTranslate/state';
import templatesReducer from 'strat/templates/state';
import renderingReducer from 'strat/rendering/state';
import userReducer from 'strat/user/state';
import favoritesReducer from 'strat/favorites/state';
import { inactiveFavoritesReducer } from 'strat/favorites/state';
import campaignReducer from 'strat/campaign/state';
import searchReducer from 'strat/search/state';
import unknownParametersReducer from 'strat/unknownParameters/state';
import wallpaperReducer from 'strat/wallpaper/state';
import { experimentsReducer } from 'strat/experiments';
import propertyReducer, {
    popularityTrendsReducer,
    mostPopularCommunitiesReducer,
    areaTrendsReducer,
    similarPropertiesReducer,
    openDialogOnceReducer,
    recommenderPropertiesReducer,
    priceTrendsReducer,
} from 'strat/property/state';
import areaGuideReducer from 'strat/areaGuide/state';
import placesReducer from 'strat/map/state';
import alertsReducer from 'strat/alerts/state';
import propertyOfTheWeekReducer from 'strat/propertyOfTheWeek/state';
import staticPageReducer from 'strat/staticPage';
import bannersReducer from 'strat/banners/state';
import seoReducer from 'strat/seo/state';
import notificationsReducer from 'strat/notifications/state';
import floorPlanModelReducer from 'strat/floorPlans/floorPlanModelState';
import { areaGuideLinkReducer } from 'strat/search/areaGuideLink';
import mapBasedSearchReducer from 'strat/search/state/mapBasedSearch';
import {
    agentReviewReducer,
    acceptedReviewsReducer,
    agencyAgentsReviewsReducer,
} from 'strat/agency/agent/review/state';
import { managedAgentReviewsReducer } from 'strat/reviewsManager';
import newDevelopmentsReducer from 'strat/newDevelopments/state';
import fetchMatchingFloorPlansReducer from 'strat/property/floorPlans/state';
import { scrollSectionsReducer } from 'strat/navigation/scroll/state';
import siteUnderMaintenanceBannerReducer from 'strat/banners/siteUnderMaintenanceBannerState';
import recentBlogPostsReducer from 'strat/home/recentBlogs/state';
import nudgeReducer from 'strat/nudges/state';
import appInstallBannersReducer from 'strat/appInstallBanners';
import { reducers as dldReducers } from '@app/dld';
import unitPlanReducer from 'strat/unitPlan/state';
import { projectsReducer } from 'strat/project/state';
import loggedInAgentDataReducer from 'strat/user/state/loggedInAgentData';
import developerPropertiesReducer from 'strat/developerProperties/state';
import categoryFieldsReducer from 'strat/categoryFields/state';
import miniFormActiveTabReducer from 'strat/search/miniForm/state';

export default {
    commitHash: (state = '') => state,
    releaseVersion: (state = '') => state,
    bundle: bundleReducer,
    layout: layoutReducer,
    platform: platformReducer,
    purpose: purposeReducer,
    i18n: i18nReducer,
    settings: settingsReducer,
    templates: templatesReducer,
    rendering: renderingReducer,
    user: userReducer,
    loggedInAgentData: loggedInAgentDataReducer,
    favorites: favoritesReducer,
    inactiveFavorites: inactiveFavoritesReducer,
    campaign: campaignReducer,
    unknownParams: unknownParametersReducer,
    search: searchReducer,
    experiments: experimentsReducer,
    wallpaper: wallpaperReducer,
    property: propertyReducer,
    areaGuide: areaGuideReducer,
    popularityTrends: popularityTrendsReducer,
    mostPopularCommunities: mostPopularCommunitiesReducer,
    priceTrends: priceTrendsReducer,
    areaTrends: areaTrendsReducer,
    alerts: alertsReducer,
    places: placesReducer,
    similarProperties: similarPropertiesReducer,
    recommendedProperties: recommenderPropertiesReducer,
    propertyOfTheWeek: propertyOfTheWeekReducer,
    staticPage: staticPageReducer,
    openDialogOnce: openDialogOnceReducer,
    banners: bannersReducer,
    seo: seoReducer,
    notifications: notificationsReducer,
    areaGuideLink: areaGuideLinkReducer,
    map: mapBasedSearchReducer,
    floorPlanModel: floorPlanModelReducer,
    agentReview: agentReviewReducer,
    acceptedReviews: acceptedReviewsReducer,
    agencyAgentsReviews: agencyAgentsReviewsReducer,
    managedAgentReviews: managedAgentReviewsReducer,
    newDevelopments: newDevelopmentsReducer,
    matchingFloorPlans: fetchMatchingFloorPlansReducer,
    scrollSections: scrollSectionsReducer,
    siteUnderMaintenanceBanner: siteUnderMaintenanceBannerReducer,
    session: sessionReducer,
    recentBlogPosts: recentBlogPostsReducer,
    nudges: nudgeReducer,
    authentication: authenticationReducer,
    clock: clockReducer,
    unitPlan: unitPlanReducer,
    autoTranslate: autoTranslateReducer,
    appInstallBanners: appInstallBannersReducer,
    projects: projectsReducer,
    developerProperties: developerPropertiesReducer,
    categoryFields: categoryFieldsReducer,
    miniFormActiveTab: miniFormActiveTabReducer,
    ...dldReducers,
};
