import React from 'react';

import { ChatCenterExternal } from 'horizontal/chat/widgets/large';

const WidgetChatPage = {
    head: () => <></>,
    body: () => (
        <ChatCenterExternal externalMode={true} inboxPageSize={20} ensureFirebaseAuth={true} />
    ),
} as const;

export default WidgetChatPage;
