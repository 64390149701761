import type { CartPackage } from 'horizontal/packages/types';

const Actions = Object.freeze({
    SET_BUSINESS_PACKAGE_CART: 'SET_BUSINESS_PACKAGE_CART',
    ADD_BUSINESS_PACKAGE_OFFER: 'ADD_BUSINESS_PACKAGE_OFFER',
    REMOVE_BUSINESS_PACKAGE_OFFER: 'REMOVE_BUSINESS_PACKAGE_OFFER',
    CLEAR_BUSINESS_PACKAGE_CART: 'CLEAR_BUSINESS_PACKAGE_CART',
    ADD_TO_CART_COUNTER: 'ADD_TO_CART_COUNTER',
    SUBTRACT_FROM_CART_COUNTER: 'SUBTRACT_FROM_CART_COUNTER',
    SET_PAYMENT_ORDER_EXTERNAL_ID: 'SET_PAYMENT_ORDER_EXTERNAL_ID',
    SET_AD_ORDER_DETAILS: 'SET_AD_ORDER_DETAILS',
} as const);

export type SetBusinessPackageCartAction = {
    type: 'SET_BUSINESS_PACKAGE_CART';
    businessPackageOffers: Array<CartPackage>;
};

export type AddBusinessPackageOffer = {
    type: 'ADD_BUSINESS_PACKAGE_OFFER';
    businessPackageOffer: CartPackage;
};

export type RemoveBusinessPackageOffer = {
    type: 'REMOVE_BUSINESS_PACKAGE_OFFER';
    businessPackageOffer: CartPackage;
    packageOfferID: string;
};

export type ClearBusinessPackageCartAction = {
    type: 'CLEAR_BUSINESS_PACKAGE_CART';
};

export type AddToCartCounterAction = {
    type: 'ADD_TO_CART_COUNTER';
    businessPackageOffer: CartPackage;
    packageOfferID: string;
};

export type SubtractFromCartCounterAction = {
    type: 'SUBTRACT_FROM_CART_COUNTER';
    businessPackageOffer: CartPackage;
    packageOfferID: string;
};

export type SetPaymentOrderExternalID = {
    type: 'SET_PAYMENT_ORDER_EXTERNAL_ID';
    orderExternalID: string;
};

export type SetAdOrderDetails = {
    type: 'SET_AD_ORDER_DETAILS';
    adExternalID: string;
};

/**
 * Union type for all available business package cart actions.
 */
export type BusinessPackageCartActions =
    | SetBusinessPackageCartAction
    | AddBusinessPackageOffer
    | RemoveBusinessPackageOffer
    | ClearBusinessPackageCartAction
    | AddToCartCounterAction
    | SubtractFromCartCounterAction
    | SetPaymentOrderExternalID
    | SetAdOrderDetails;

export default Actions;
