import { Script } from 'strat/util';

class YoutubeIframeApi {
    /**
     * Loads YoutubeIframeApi and returns the YT object from window
     *
     * If the script was already loaded, it won't load it
     * again and the promise will be resolved immediately.
     *
     * The function onYouTubeIframeAPIReady will be called when the api has loaded
     * https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
     *
     * If the API is loading, we add the promise in the youtubePlayers window object, and
     * the promise will be solved when onYouTubeIframeAPIReady is called.
     *
     * @returns {Promise<*>}
     */
    static load(): Promise<any> {
        return new Promise((resolve: (result: Promise<never>) => void) => {
            // @ts-expect-error - TS2339 - Property 'YT' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'YT' does not exist on type 'Window & typeof globalThis'.
            if (window.YT && window.YT.loaded) {
                // @ts-expect-error - TS2339 - Property 'YT' does not exist on type 'Window & typeof globalThis'.
                resolve(window.YT);
            } else {
                Script.load('https://www.youtube.com/iframe_api');

                // @ts-expect-error - TS2339 - Property 'youtubePlayers' does not exist on type 'Window & typeof globalThis'.
                if (!window.youtubePlayers) {
                    // @ts-expect-error - TS2339 - Property 'youtubePlayers' does not exist on type 'Window & typeof globalThis'.
                    window.youtubePlayers = [];
                }
                // @ts-expect-error - TS2339 - Property 'youtubePlayers' does not exist on type 'Window & typeof globalThis'. | TS7006 - Parameter 'YT' implicitly has an 'any' type.
                window.youtubePlayers.push((YT) => resolve(YT));

                // @ts-expect-error - TS2339 - Property 'onYouTubeIframeAPIReady' does not exist on type 'Window & typeof globalThis'.
                if (!window.onYouTubeIframeAPIReady) {
                    // @ts-expect-error - TS2339 - Property 'onYouTubeIframeAPIReady' does not exist on type 'Window & typeof globalThis'.
                    window.onYouTubeIframeAPIReady = () =>
                        // @ts-expect-error - TS2339 - Property 'youtubePlayers' does not exist on type 'Window & typeof globalThis'.
                        window.youtubePlayers &&
                        // @ts-expect-error - TS2339 - Property 'youtubePlayers' does not exist on type 'Window & typeof globalThis'. | TS7006 - Parameter 'player' implicitly has an 'any' type. | TS2339 - Property 'YT' does not exist on type 'Window & typeof globalThis'.
                        window.youtubePlayers.forEach((player) => player(window.YT));
                }
            }
        });
    }
}

export default YoutubeIframeApi;
