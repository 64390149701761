import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { Text, ListEntry } from 'strat/components';

import { useSectionLinks } from 'horizontal/userOrders';
import iconBoughtPackages from 'horizontal/assets/icons/iconBoughtPackages_noinline.svg';

const renderLeft = () => <img alt={'Bought packages'} src={iconBoughtPackages} />;

type Props = {
    readonly onClick?: (arg1: React.MouseEvent<any>) => void;
    readonly compact?: boolean;
};

const BoughtPackagesLink = ({ onClick, compact }: Props) => {
    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trigger(Triggers.VIEW_BUY_HISTORY, { view_section: ViewSections.USER_DETAIL });
            if (onClick) {
                onClick(event);
            }
        },
        [onClick],
    );

    const { section } = useSectionLinks()[0];

    return (
        <Link
            route={RouteNames.USER_ORDERS}
            params={compact ? null : { section }}
            onClick={onTrackedClick}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>
                    <Trans>Bought Packages & Billing</Trans>
                </Text.Large>
            </ListEntry>
        </Link>
    );
};

export default BoughtPackagesLink;
