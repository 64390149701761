import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Checkbox, Flex, Text } from 'strat/components';
import { PageWidthWrapper } from 'strat/generic';

import { UserDTO } from 'horizontal/dtos';
import { FullAd, ProductPackageOffer } from 'horizontal/types';

import AdPackageOffersCard from './adPackageOffersCard';
import BusinessPackageOffersCard from './businessPackageOffersCard';
import createPaymentOrder from './createOrderAndPayment';
import FlashMessage from './flashMessage';
import styles from './styles/telesalesTool.cssm';
import UserAdsListCard from './userAdsListCard';
import UserSearchCard from './userSearchCard';

const TeleSalesToolPage = () => {
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>('');
    const [successMsg, setSuccessMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    const [selectedUser, setSelectedUser] = React.useState<UserDTO | undefined>();
    const [selectedAd, setSelectedAd] = React.useState<FullAd | undefined>();
    const [selectedPackageOffer, setSelectedPackageOffer] = React.useState<
        ProductPackageOffer | undefined
    >();

    React.useEffect(() => {
        setSelectedAd(undefined);
        setSelectedPackageOffer(undefined);
    }, [checked]);

    const handleUserSelectionChange = React.useCallback((user: UserDTO | undefined) => {
        setSelectedUser(user);
        setSelectedAd(undefined);
        setSelectedPackageOffer(undefined);
    }, []);

    const handleAdSelectionChange = React.useCallback((ad: FullAd | undefined) => {
        setSelectedAd(ad);
        setSelectedPackageOffer(undefined);
    }, []);

    const handlePackageOfferSelectionChange = React.useCallback(
        (packageOffer: ProductPackageOffer | undefined) => {
            setSelectedPackageOffer(packageOffer);
        },
        [],
    );

    return (
        <PageWidthWrapper className={styles.pageContainer}>
            <Text.XLarge>
                <Trans>Tele Sales Tool</Trans>
            </Text.XLarge>

            <FlashMessage message={successMsg} />
            <FlashMessage message={errorMsg} isError />

            <UserSearchCard
                selectedUserExternalID={selectedUser?.externalID}
                onUserSelectionChange={handleUserSelectionChange}
                onError={setErrorMsg}
            />

            <Flex>
                <Checkbox
                    name={'apply_service'}
                    label={<Trans>Apply service</Trans>}
                    checked={checked}
                    onChange={() => {
                        setChecked(!checked);
                    }}
                />
            </Flex>

            {checked && selectedUser && (
                <UserAdsListCard
                    userExternalID={selectedUser.externalID}
                    selectedAdExternalID={selectedAd?.externalID}
                    onAdSelectionChange={handleAdSelectionChange}
                    onError={setErrorMsg}
                />
            )}

            {!checked && selectedUser && (
                <BusinessPackageOffersCard
                    selectedPackageOfferID={selectedPackageOffer?.packageOfferID}
                    onPackageOfferSelectionChange={handlePackageOfferSelectionChange}
                    onError={setErrorMsg}
                />
            )}

            {checked && selectedAd && (
                <AdPackageOffersCard
                    ad={selectedAd}
                    selectedPackageOfferID={selectedPackageOffer?.packageOfferID}
                    onPackageOfferSelectionChange={handlePackageOfferSelectionChange}
                    onError={setErrorMsg}
                />
            )}

            <Flex>
                <Button
                    className={styles.submitButton}
                    disabled={!selectedPackageOffer || !selectedUser || loading}
                    onClick={() => {
                        if (selectedUser && selectedPackageOffer) {
                            createPaymentOrder(
                                selectedUser.externalID,
                                checked,
                                selectedAd?.id,
                                selectedPackageOffer.packageOfferID,
                                setSuccessMsg,
                                setErrorMsg,
                                setLoading,
                            );
                        }
                    }}
                >
                    <Trans>Submit</Trans>
                </Button>
            </Flex>
        </PageWidthWrapper>
    );
};

export default TeleSalesToolPage;
