import type { ChoiceValue } from 'strat/components/types';

import type { FacetedChoice } from './types';

const sortFacetedChoices = <T extends ChoiceValue>(
    choices: Array<FacetedChoice<T>>,
    activeValues?: T | Array<T>,
): Array<FacetedChoice<T>> => {
    const values = Array.isArray(activeValues) ? activeValues : [activeValues];

    return choices.sort((a, b) => {
        const aIsFiltered = values.includes(a.value);
        const bIsFiltered = values.includes(b.value);

        const areBothFiltered = aIsFiltered && bIsFiltered;
        const areBothNotFiltered = !aIsFiltered && !bIsFiltered;

        if (!areBothFiltered && !areBothNotFiltered) {
            if (aIsFiltered) {
                return -1;
            }

            if (bIsFiltered) {
                return 1;
            }
        }

        if (a.count === null || b.count === null) {
            return 0;
        }

        if (a.count < b.count) {
            return 1;
        }

        if (a.count > b.count) {
            return -1;
        }

        return 0;
    });
};

export default sortFacetedChoices;
