/**
 * Gets all variables currently set on the GTM data layer.
 */
const getDataLayerVariables = (dataLayerName: string) => {
    let variables: Record<string, any> = {};
    // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'. | TS7006 - Parameter 'dataLayerEvent' implicitly has an 'any' type.
    window[dataLayerName].forEach((dataLayerEvent) => {
        if (dataLayerEvent.event) {
            return;
        }

        if (Object.keys(dataLayerEvent).length > 0) {
            Object.assign(variables, dataLayerEvent);
        } else {
            // we use the empty object as an obsolency marker
            variables = {};
        }
    });

    return variables;
};

export default getDataLayerVariables;
