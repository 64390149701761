import type { RoutingContextWithMiddlewares } from 'strat/app';

import type { SearchRouteParams } from 'horizontal/routes';

import createAdsFilters from './createAdsFilters';
import createProcessedSearchFilters from './createProcessedSearchFilters';
import determineSearchPageType from './determineSearchPageType';

function createAdsSearchFilters(
    context: RoutingContextWithMiddlewares,
    searchRouteParams: SearchRouteParams,
) {
    const { category, sortOption } = searchRouteParams;

    const filters = createAdsFilters(context.redux.store.getState, searchRouteParams);
    const searchPageType = determineSearchPageType(category, sortOption?.key, searchRouteParams);

    const processedFilters = createProcessedSearchFilters(filters, searchPageType);
    return { filters, processedFilters };
}

export default createAdsSearchFilters;
