import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { useFormattedPrice } from 'horizontal/util';

import styles from './styles/walletPaymentWithPartial.cssm';

type Props = {
    partialPayment: boolean;
    balance: number;
};

const WalletBalanceText = ({ balance, partialPayment = false }: Props) => {
    const i18n = useI18n();
    const zeroBalanceText = useFormattedPrice(0);
    const balanceDisplayText = useFormattedPrice(balance);

    return (
        <Flex className={styles.balanceText}>
            <Text.Regular>{t(i18n)`Balance :`}</Text.Regular>
            <Text.Regular strikethrough={partialPayment}>{balanceDisplayText}</Text.Regular>
            {partialPayment && <Text.Regular>{zeroBalanceText}</Text.Regular>}
        </Flex>
    );
};

export default WalletBalanceText;
