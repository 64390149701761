import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { AgencyProfileRouteParams } from 'horizontal/routes';

const useNavigateToAgencyPage = () => {
    const router = useRouter();

    return React.useCallback(
        (params: Partial<AgencyProfileRouteParams>) => {
            router.pushRoute(RouteNames.AGENCY_PROFILE, params);
        },
        [router],
    );
};

export default useNavigateToAgencyPage;
