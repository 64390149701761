import * as React from 'react';
import { matchChoiceValue } from 'strat/util';
import type { Choice, ChoiceValue } from 'strat/components/types';

import type { FilterableDropdownProps } from './types';
import FilterableDropdownBase from './filterableDropdownBase';

const FilterableDropdown = <T extends ChoiceValue>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    onChange,
    value = '',
    choices,
    dropdownTriggerCustomClass,
    choicesContainerCustomClass,
    placeholder,
    stretch,
    renderSuggestions,
    suggestedChoices,
    readOnly,
    noOverflow,
    dropdownTriggerIcon,
    titleCustomClass,
    renderIcon,
    variant,
    lightPlaceholder,
    boldLabel,
    isAutofilled,
}: FilterableDropdownProps<T>) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState<string | null>(null);
    const allChoices = React.useMemo(
        () => (suggestedChoices ? [...choices, ...suggestedChoices] : choices),
        [suggestedChoices, choices],
    );

    const selectedValueLabel = React.useMemo(() => {
        if (!value) {
            return;
        }

        const selectedChoice = allChoices.find((choice) =>
            matchChoiceValue(choice as Choice<NonNullable<ChoiceValue>>, value),
        );

        return selectedChoice?.label;
    }, [value, allChoices]);

    const filteredChoices = React.useMemo(() => {
        if (!searchQuery) {
            return allChoices;
        }

        return allChoices.filter((choice) => {
            return choice.label.toLowerCase().startsWith(searchQuery.toLowerCase());
        });
    }, [allChoices, searchQuery]);

    const onOptionSelected = React.useCallback(
        (clickedOption) => {
            setIsOpen(false);
            onChange(clickedOption);
        },
        [setIsOpen, onChange],
    );

    return (
        <FilterableDropdownBase
            isAutofilled={isAutofilled}
            onChange={onOptionSelected}
            choices={filteredChoices}
            shouldHideInput={allChoices.length < 10}
            name={name}
            description={description}
            title={title}
            errorMessage={errorMessage}
            accepted={accepted}
            stretch={stretch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedValueLabel={selectedValueLabel}
            value={value as T}
            placeholder={placeholder}
            noOverflow={noOverflow}
            titleCustomClass={titleCustomClass}
            dropdownTriggerCustomClass={dropdownTriggerCustomClass}
            choicesContainerCustomClass={choicesContainerCustomClass}
            readOnly={readOnly}
            dropdownTriggerIcon={dropdownTriggerIcon}
            renderIcon={renderIcon}
            renderSuggestions={renderSuggestions}
            variant={variant}
            lightPlaceholder={lightPlaceholder}
            boldLabel={boldLabel}
        />
    );
};

export default FilterableDropdown;
