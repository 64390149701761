import { t } from '@lingui/macro';
import * as React from 'react';
import { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

import { useAdFormattedExtraFields } from 'horizontal/ad';
import { type MixedAd, AdExcludedExtraFieldsFromAdDetails } from 'horizontal/types';
import { PriceTypeField } from 'horizontal/fields/commonPostingFields';
import AdUtils from 'horizontal/ad/utils';
import { CarUsageConditions } from 'horizontal/ad/useGetCarMileage';
import { type AdFormattedExtraField, AdExtraFieldsKeys } from 'horizontal/ad';

const excludedFields: string[] = [
    AdExtraFieldsKeys.SECONDARY_PRICE,
    AdExtraFieldsKeys.FREE_AD_UNDERTAKING,
    AdExtraFieldsKeys.PRICE,
    AdExtraFieldsKeys.DOWN_PAYMENT,
    AdExtraFieldsKeys.VIDEO,
    AdExtraFieldsKeys.PANORAMA,
];

const displayConditions = (field: AdFormattedExtraField, ad: MixedAd) => {
    if (field.attribute === AdExtraFieldsKeys.PRICE_TYPE) {
        return AdUtils.shouldShowPriceType(ad);
    }

    if (field.attribute === AdExtraFieldsKeys.DOWN_PAYMENT) {
        const rentalPeriod = ad.extraFields?.rental_period;
        return !!rentalPeriod;
    }

    return (
        !excludedFields.includes(field.attribute) &&
        !AdUtils.isHighlightedField(field.attribute, ad)
    );
};

export const useAdDetails = (ad?: MixedAd | null): Array<AdFormattedExtraField> => {
    const formattedExtraFields = useAdFormattedExtraFields(ad);
    return React.useMemo(
        () =>
            formattedExtraFields.filter(
                (field) =>
                    !!field.name &&
                    !Array.isArray(field.formattedValue) &&
                    field.name !== PriceTypeField.attribute &&
                    !AdExcludedExtraFieldsFromAdDetails[field.attribute],
            ),
        [formattedExtraFields],
    );
};

const useSanitizeHighlightedDetails = (details: Array<AdFormattedExtraField>, i18n: I18n) => {
    return React.useMemo(() => {
        const isNewCondition = details.find(
            (detailsField) =>
                detailsField.attribute === AdExtraFieldsKeys.CONDITION &&
                CarUsageConditions.NEW.includes(detailsField.formattedValue as string),
        );
        const mileageFieldIndex = details.findIndex(
            (detailsField) => detailsField.attribute === AdExtraFieldsKeys.MILEAGE,
        );
        const updatedDetails = [...details];

        // if the field exists then we will modify it
        if (isNewCondition && details[mileageFieldIndex]) {
            updatedDetails[mileageFieldIndex] = {
                name: t(i18n)`Kilometers`,
                attribute: AdExtraFieldsKeys.MILEAGE,
                formattedValue: '-',
            };
            return updatedDetails;
        }
        // if the condition is new but field doesn't exist, then we will add it
        if (isNewCondition) {
            updatedDetails.unshift({
                name: t(i18n)`Kilometers`,
                attribute: AdExtraFieldsKeys.MILEAGE,
                formattedValue: '-',
            });
            return updatedDetails;
        }
        return updatedDetails;
    }, [details, i18n]);
};

const useFilteredAdDetails = (ad?: MixedAd | null): Array<AdFormattedExtraField> => {
    const details = useAdDetails(ad);
    if (!ad) {
        return [];
    }

    return details.filter((detailsField) => displayConditions(detailsField, ad));
};

export const useAdHighlightedDetails = (ad?: MixedAd | null): Array<AdFormattedExtraField> => {
    const i18n = useI18n();
    const details = useSanitizeHighlightedDetails(useAdDetails(ad), i18n);
    if (!ad) {
        return [];
    }

    return details.filter((detailsField) => AdUtils.isHighlightedField(detailsField.attribute, ad));
};

export default useFilteredAdDetails;
