import { useI18n } from 'strat/i18n/language';
import { useRouter } from 'react-true-router';

import type { LoginType, AuthFlow } from '../../types';
import { createKeycloakThirdPartyLoginRedirectURI } from '../keycloakThirdPartyLogin';

const useKeycloakThirdPartyLoginRedirectURI = (
    loginType: LoginType | null,
    authFlow: AuthFlow | null,
    subscribeToMarketingEmails?: boolean,
) => {
    const i18n = useI18n();
    const router = useRouter();

    return createKeycloakThirdPartyLoginRedirectURI({
        redirectPath: router.context?.match?.url || null,
        languageCode: i18n.locale,
        loginType,
        authFlow,
        subscribeToMarketingEmails,
    });
};

export default useKeycloakThirdPartyLoginRedirectURI;
