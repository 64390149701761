import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

type BankTransferPaymentRouteParams = {
    readonly adExternalID: string;
    readonly commission: number;
};

type UseNavigateToBankTransfer = (arg1?: BankTransferPaymentRouteParams | null | undefined) => void;

const useNavigateToBankTransfer = (): UseNavigateToBankTransfer => {
    const router = useRouter();

    return React.useCallback(
        (params?: BankTransferPaymentRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_BANK_TRANSFER, params);
        },
        [router],
    );
};

export default useNavigateToBankTransfer;
