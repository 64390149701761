import * as React from 'react';
import { useSelector } from 'react-redux';

const useURLChangeDetector = () => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const routeName = useSelector((state) => state.router.routeName);
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const routeParams = useSelector((state) => state.router.routeParams);
    return React.useMemo(() => ({ routeName, routeParams }), [routeName, routeParams]);
};

export default useURLChangeDetector;
