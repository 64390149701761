import * as React from 'react';

import HistoryRouter from './historyRouter';
import RouterContext from './routerContext';

type Props = {
    readonly router: HistoryRouter;
    readonly children?: React.ReactNode;
};

/**
 * Provides access to the router to child component through
 * the use of context.
 */
const RouterProvider = (props: Props) => (
    <RouterContext.Provider value={{ router: props.router }}>
        {React.Children.only(props.children)}
    </RouterContext.Provider>
);

export default RouterProvider;
