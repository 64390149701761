import { t } from '@lingui/macro';
import * as React from 'react';
import isNil from 'lodash/isNil';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Trans } from '@lingui/macro';

import { BusinessPackageSubsection } from 'horizontal/packages/types';
import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';
import { PackageType, type ProductPackageOffer } from 'horizontal/types';

import { useLocalizedDays } from '../hooks';

import Section from './sectionRedesign';

const PostMoreAdsSection = ({ packageOffers }: { packageOffers: Array<ProductPackageOffer> }) => {
    const i18n = useI18n();
    const iconSrc = usePackagesIcons({ type: PackageType.LIMIT });
    const localizedDays = useLocalizedDays();

    const getSubsectionsByDuration = React.useMemo(() => {
        packageOffers.sort(
            (a, b) =>
                (isNil(a.productPackage.productItems[0].duration)
                    ? settings.defaultDaysDuration
                    : a.productPackage.productItems[0].duration) -
                (isNil(b.productPackage.productItems[0].duration)
                    ? settings.defaultDaysDuration
                    : b.productPackage.productItems[0].duration),
        );

        let duration = packageOffers.length
            ? packageOffers[0].productPackage.productItems[0].duration
            : null;
        let currentSubsection: Array<ProductPackageOffer> = [];
        const subsections: Array<BusinessPackageSubsection> = [];

        duration = isNil(duration) ? settings.defaultDaysDuration : duration;

        packageOffers.forEach((offer) => {
            if (
                (isNil(offer.productPackage.productItems[0].duration)
                    ? settings.defaultDaysDuration
                    : offer.productPackage.productItems[0].duration) !== duration
            ) {
                subsections.push({
                    title: (
                        <Trans>
                            Post Ads valid for <b>{localizedDays(duration)}</b>
                        </Trans>
                    ),
                    packageOffers: currentSubsection,
                });
                currentSubsection = [];
                duration = isNil(offer.productPackage.productItems[0].duration)
                    ? settings.defaultDaysDuration
                    : offer.productPackage.productItems[0].duration;
            }
            currentSubsection.push(offer);
        });
        if (currentSubsection) {
            subsections.push({
                title: (
                    <Trans>
                        Post Ads valid for <b>{localizedDays(duration)}</b>
                    </Trans>
                ),
                packageOffers: currentSubsection,
            });
        }

        return subsections;
    }, [packageOffers, localizedDays]);

    const details = [t(i18n)`Post ads in the selected category`];

    return (
        <Section
            title={t(i18n)`Post more Ads`}
            details={details}
            subsections={getSubsectionsByDuration}
            renderIcon={() => iconSrc && <img src={iconSrc} alt="Post More Ads" />}
        />
    );
};

export default PostMoreAdsSection;
