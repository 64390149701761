import * as React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import styles from './styles/paymentMethod.cssm';
import DiscountFlag from './discountFlag';
import type { PaymentMethodDetails } from './types';

type Props = {
    readonly paymentMethod: PaymentMethodDetails;
    readonly onClick: any;
    readonly disabled?: boolean;
};

const PaymentMethod = ({ paymentMethod, onClick, disabled = false }: Props) => (
    <Flex
        alignCenter
        column
        className={classNames(styles.paymentMethod, { [styles.disabled]: disabled })}
        onClick={onClick}
    >
        {paymentMethod.renderIcon(styles.icon)}
        <Text.Base className={styles.name}>{paymentMethod.name}</Text.Base>
        <Text.Base className={styles.description}>{paymentMethod.description}</Text.Base>
        {paymentMethod.discount && <DiscountFlag amount={paymentMethod.discount.discountPercent} />}
    </Flex>
);

export default PaymentMethod;
