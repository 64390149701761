import type { APIResponse } from './types';
import { APIError, APIErrorReason } from './error';

const Errors = Object.freeze({
    CELL_TAKEN: 'The cell has already been taken.',
    USER_EXISTS: 'User already exists',
});

const handleRegisterResponse = (response: APIResponse<any>): APIResponse<any> => {
    if (response.status === 204) {
        // The API v6 returns 204 when an email already exists and allows email enumeration through register form
        // We can hide this by returning 200 anyway since the actual request to the APIv6 is performed during SSR
        return { status: 200, data: response.data };
    }

    if (response.status === 400) {
        // This response is not part of the original api v6
        // Portals such as bayut.sa use v6 as a proxy to v7 for backwards compatibility
        if (response.data.list_item?.length) {
            if (response.data.list_item.includes(Errors.USER_EXISTS)) {
                throw new APIError('User already exists', {
                    reason: APIErrorReason.ALREADY_REGISTERED,
                    response,
                });
            }

            if (response.data.list_item.includes(Errors.CELL_TAKEN)) {
                throw new APIError('User already exists with same phone number', {
                    reason: APIErrorReason.CELL_DUPLICATED,
                    response,
                });
            }
        }
    }

    if (response.status === 422) {
        throw new APIError('User registration did not contain valid data', {
            reason: APIErrorReason.INVALID_CREDENTIALS,
            response,
        });
    }

    return response;
};

export default handleRegisterResponse;
