import { t } from '@lingui/macro';
import * as React from 'react';
import { connect } from 'react-redux';
import type { ComponentType } from 'react';
import autoBind from 'react-autobind';
import isNil from 'lodash/isNil';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import type { I18n } from '@lingui/core';
import { numberOfHits, isNumberOfHitsLoading } from '@app/branding/search';
import Category from '@app/branding/category';
import { GlobalState } from '@app/state';

import { withI18n } from 'strat/i18n/language/withI18n';
import { connectLanguage } from 'strat/i18n/language';
import { selectIsMapBasedSearchActive } from 'strat/search/selectors';
import { FilterValues } from 'strat/search';
import { PropertyType } from 'strat/property/types';

type Props = {
    shortSummary: boolean | null;
    summaryDescription?: () => string;
    nbHits: number | null;
    page: number | null;
    activePage: number | null;
    hitsPerPage?: number | string;
    customFormatNumber?: (amount: number) => string;
};
/**
 * Connects search summary for the wrapped component.
 */
export default (component: ComponentType<any>) => {
    class SummaryConnector extends React.Component<any> {
        constructor(props: any) {
            super(props);
            autoBind(this);
        }

        activeCategory(i18n: I18n): string {
            const singular = t(i18n)`Properties_translatedToSingular`;
            const category = this.props.filters.getFilterValue('category.slug');
            const defaultTransactionCategory = category?.slug === 'any' && t(i18n)`Transactions`;
            const listingType = this.props.filters.getFilterValue(
                FilterValues.type.attribute,
                FilterValues.type.default,
            );

            // Use singular in Arabic only when category is set to 'residential'.
            const shouldUseSingular =
                // @ts-ignore
                i18n.locale === 'ar' && category && category.slug === Category.RESIDENTIAL;

            if (this.props.summaryDescription) {
                return this.props.summaryDescription();
            }

            if (listingType === PropertyType.PROJECT) {
                return t(i18n)`Projects`;
            }

            return !category || shouldUseSingular
                ? singular
                : defaultTransactionCategory || category.name;
        }

        computeSummary(i18n: I18n) {
            const categoryName = this.activeCategory(i18n);

            if (this.props.nbHits === 0) {
                return `0 ${categoryName}`;
            }

            const rangeStartOnPage = (this.props.page - 1) * this.props.hitsPerPage + 1;
            const rangeStart = this.props.formatNumber(
                this.props.nbHits > 0 ? rangeStartOnPage : 0,
            );
            const rangeEnd = this.props.formatNumber(
                Math.min(this.props.page * this.props.hitsPerPage, this.props.nbHits),
            );

            const nbHits = this.props.customFormatNumber
                ? this.props.customFormatNumber(this.props.nbHits)
                : this.props.formatNumber(this.props.nbHits);

            if (this.props.shortSummary) {
                return t(i18n)`Showing ${rangeStart} - ${rangeEnd} of ${nbHits} ${categoryName}`;
            }

            return this.props.longText
                ? t(
                      i18n,
                  )`Showing ${rangeStart} - ${rangeEnd} of ${nbHits} ${categoryName} sorted by`
                : t(i18n)`${rangeStart} to ${rangeEnd} of ${nbHits} ${categoryName}`;
        }

        computeLoadingSummary(i18n: I18n) {
            const activeCategory = this.activeCategory(i18n);
            return t(i18n)`Loading ${activeCategory}`;
        }

        /**
         * Renders the wrapped component.
         */
        render(): any {
            const { i18n } = this.props;
            const summary = this.props.loading
                ? this.computeLoadingSummary(i18n)
                : this.computeSummary(i18n);

            const props = {
                ...this.props,
                summary,
            } as const;

            return React.createElement(component, props);
        }
    }

    return connect((state: GlobalState, ownProps: Props) => {
        const filters = getFilterCollection(state);
        const isMapBasedSearch = selectIsMapBasedSearchActive(state);
        const providedPage = ownProps.page || ownProps.activePage;

        const page = !isNil(providedPage) ? providedPage : filters.getFilterValue('page', 1);
        const nbHits = ownProps.nbHits ? ownProps.nbHits : numberOfHits(state);

        return {
            loading: isNumberOfHitsLoading(state),
            filters,
            page,
            shortSummary: isMapBasedSearch || ownProps.shortSummary,
            hitsPerPage:
                ownProps.hitsPerPage ||
                (state.algolia.content || {}).hitsPerPage ||
                state.algolia.settings.hitsPerPage,
            nbHits,
        };
    }, null)(withI18n()(connectLanguage(SummaryConnector)));
};
