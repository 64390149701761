import * as React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from 'strat/components';

import styles from './styles/invisibleRecaptchaText.cssm';

const renderGooglePrivacyLink = () => (
    <a rel="noopener noreferrer" target="_blank" href="https://policies.google.com/privacy">
        <Trans>Privacy Policy</Trans>
    </a>
);

const renderGoogleTermsLink = () => (
    <a rel="noopener noreferrer" target="_blank" href="https://policies.google.com/terms">
        <Trans>Terms of Service</Trans>
    </a>
);

const RecaptchaUsageConditions = () => {
    if (
        !CONFIG.runtime.STRAT_WITH_CAPTCHA ||
        !CONFIG.runtime.STRAT_RECAPTCHA_KEY ||
        CONFIG.runtime.STRAT_CHECKBOX_CAPTCHA
    ) {
        return null;
    }

    return (
        <Text.Base className={styles.text}>
            <Trans>
                This site is protected by reCAPTCHA and the Google {renderGooglePrivacyLink()} and{' '}
                {renderGoogleTermsLink()} apply.
            </Trans>
        </Text.Base>
    );
};

export default RecaptchaUsageConditions;
