import { t } from '@lingui/macro';
import * as React from 'react';

import iconPriceTagPNG from 'horizontal/assets/icons/iconPriceTag.png';
import iconPriceTagWEBP from 'horizontal/assets/icons/iconPriceTag.webp';
import { ChoicePageLayout, ChoicePageHeading } from 'horizontal/generic/choicePage';
import type { FullAd, ProductPurchase } from 'horizontal/types';
import { PromoStrip, ErrorBanner } from 'horizontal/packages/individual';

import useBoughtPackages from './individual/useBoughtPackages';
import ChoicesSection from './choicesSection';
import useEnabledChoicesIds from './useEnabledChoicesIds';

type Props = {
    readonly packages: Array<ProductPurchase> | null | undefined;
    readonly ad: FullAd | null | undefined;
    readonly withoutSkipButton: boolean;
};

const SelectPackagesForm = ({ packages, ad, withoutSkipButton }: Props) => {
    const { i18n, onChoiceClick, applyBoughtPackage, selectedPackage, loading, errorMessage } =
        useBoughtPackages(packages, ad);
    const choices = packages || [];
    const enabledIds = useEnabledChoicesIds(choices);

    return (
        <ChoicePageLayout
            isLoading={loading}
            showCTA={!!selectedPackage}
            ctaText={t(i18n)`Upgrade Your Ad`}
            onCTAClick={applyBoughtPackage}
            ad={withoutSkipButton ? null : ad}
        >
            {errorMessage && <ErrorBanner error={errorMessage} />}
            <ChoicePageHeading
                image={{
                    name: 'Price tag',
                    source: iconPriceTagPNG,
                    sourceWEBP: iconPriceTagWEBP,
                }}
                title={t(i18n)`Reach more buyers and sell faster`}
                subtitle={t(i18n)`Select packages to sell faster`}
            />
            <ChoicesSection
                onClick={onChoiceClick}
                choices={choices}
                selectedValue={selectedPackage}
                enabledIds={enabledIds}
                ad={ad}
            />
            <PromoStrip
                text={t(i18n)`Heavy discount on Business Packages`}
                ad={ad}
                availableAdPackages={packages}
            />
        </ChoicePageLayout>
    );
};

export default SelectPackagesForm;
