import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { selectWalletBalance } from 'horizontal/payment/wallet/selectors';
import iconWallet from '@app/assets/icons/iconWallet_noinline.svg';
import { useFormattedPrice } from 'horizontal/util';

import useTrackPaymentFailed from '../hooks/useTrackPaymentFailed';
import type { PaymentOrder } from '../types';

import useWalletPayment from './hooks/useWalletPayment';
import ConfirmPaymentDialog from './confirmPaymentDialog';
import ErrorMessage from './errorMessage';
import styles from './styles/walletPayment.cssm';

type Props = {
    readonly onWalletSelect: () => void;
    readonly order: PaymentOrder;
    readonly onWalletDismiss: () => void;
};

const WalletPayment = ({ order, onWalletSelect, onWalletDismiss }: Props) => {
    const i18n = useI18n();
    const { pay, setDialogVisible, dialogVisible, error, errorStatus, isSubmitting } =
        useWalletPayment(i18n, order, false);

    const balance = useSelector(selectWalletBalance);
    const balanceDisplayText = useFormattedPrice(balance);
    useTrackPaymentFailed(order, error);

    return (
        <>
            <ErrorMessage error={error} errorStatus={errorStatus} />
            <Flex
                alignCenter
                className={styles.wallet}
                onClick={() => {
                    onWalletSelect();
                    setDialogVisible(!dialogVisible);
                }}
            >
                <img alt={'My Wallet'} src={iconWallet} />
                <div>
                    <p className={styles.title}>{t(i18n)`${settings.getBrandName(i18n)} Wallet`}</p>
                    <p className={styles.balance}>
                        {t(i18n)`Balance`}: {balanceDisplayText}
                    </p>
                </div>
                <ConfirmPaymentDialog
                    dialogVisible={dialogVisible}
                    setDialogVisible={setDialogVisible}
                    amount={order.total || 0}
                    onConfirm={pay}
                    disableActions={isSubmitting}
                    onDismiss={onWalletDismiss}
                />
            </Flex>
        </>
    );
};

export default WalletPayment;
