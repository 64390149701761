import { HTTPApi } from 'strat/util';
import { OvationAppType, OvationMetricEntity, OvationMetricSource } from 'strat/ovation';
import { APIResponse } from 'strat/api/types';

import { OvationMetricAction, FavoriteAction } from '../ovation/types';

export type ClientData = {
    readonly client_device_id: string;
    readonly client_user_external_id?: string | null | undefined;
    readonly client_session_id: string;
};

export type TrackingData = {
    readonly google_client_id?: string | null | undefined;
    readonly google_click_id?: string | null | undefined;
    readonly campaign_source?: string | null | undefined;
    readonly campaign_medium?: string | null | undefined;
};

export type OvationAdData = {
    readonly ad_source: string;
    readonly ad_source_id: number;
    readonly ad_type: string;
    readonly ad_external_id: string;
    readonly ad_posting_id: number;
    readonly ad_product: string;
    readonly ad_product_consumptions: Array<number>;
    readonly attributions: Array<string>;
};

export type OvationAdDataWithExtraFields = OvationAdData & {
    readonly ad_image_url?: string | null;
    readonly ad_location_l3_external_id?: string | null;
    readonly ad_location_l3_name_en?: string | null;
    readonly ad_location_l3_name_lc?: string | null;
    readonly ad_location_external_id?: string;
    readonly ad_location_name_en?: string;
    readonly ad_location_name_lc?: string;
    readonly ad_agent_external_id?: string | null;
    readonly ad_agent_name?: string | null;
    readonly ad_agent_email?: string | null;
    readonly ad_agent_phone?: string | null;
    readonly ad_agency_external_id?: string;
    readonly ad_category_external_id?: string;
    readonly ad_category_name_en?: string;
    readonly ad_category_name_lc?: string;
    readonly ad_product?: string | null;
    readonly ad_purpose?: string;
    readonly ad_area?: number;
    readonly ad_plot_area?: number;
    readonly ad_price?: number;
    readonly ad_bathroom_count?: number;
    readonly ad_bedroom_count?: number;
    readonly ad_reference_number?: string;
    readonly ad_rent_frequency?: string | null;
};

export type OvationUserData = {
    readonly user_external_id?: string | null;
    readonly user_name?: string | null;
    readonly user_email_address?: string | null;
    readonly user_phone_number?: string | null;
};

type MetricData = {
    readonly metric_entity: Values<typeof OvationMetricEntity>;
    readonly metric_source: Values<typeof OvationMetricSource>;
    readonly metric_action: Values<typeof OvationMetricAction>;
};

type AdMetricData = ClientData &
    TrackingData &
    OvationAdDataWithExtraFields &
    OvationUserData &
    MetricData & {
        readonly app_type: Values<typeof OvationAppType>;
        readonly unique_event_id?: string;
    };

type EmailLeadData = ClientData & {
    readonly metric_source: string;
    readonly user_name: string;
    readonly user_email_address: string;
    readonly user_phone_number: string;
    readonly email_subject?: string | null | undefined;
    readonly email_body: string;
    readonly app_type?: Values<typeof OvationAppType>;
    readonly attributions?: Array<string>;
    readonly unique_event_id: string;
};

type AdEmailLeadData = EmailLeadData & OvationAdDataWithExtraFields;

type AgencyEmailLeadData = EmailLeadData & {
    readonly agency_type: string;
    readonly agency_external_id: string;
};

type AgentEmailLeadData = EmailLeadData & {
    readonly agent_external_id: string;
};

type SellerLeadData = OvationAdDataWithExtraFields & {
    readonly user_name: string;
    readonly user_email_address: string;
    readonly user_phone_number: string;
    readonly message_body: string;
    readonly agent_external_id: string;
    readonly agency_external_id?: string;
    readonly commission: number;
    readonly truestimate: number;
    readonly app_type?: Values<typeof OvationAppType>;
    readonly unique_event_id: string;
    readonly report_external_id: string;
};

type ChatLeadData = ClientData &
    OvationAdDataWithExtraFields & {
        readonly app_type: Values<typeof OvationAppType>;
        readonly message: string;
        readonly metric_source: string;
        readonly user_name: string;
        readonly user_email_address: string;
        readonly user_phone_number: string;
        readonly unique_event_id: string;
    };

type AgentMetricData = ClientData &
    MetricData & {
        readonly app_type: Values<typeof OvationAppType>;
        readonly agent_external_id: string;
        readonly unique_event_id: string;
    };

type AgencyMetricData = ClientData &
    MetricData & {
        readonly app_type: Values<typeof OvationAppType>;
        readonly agency_external_id: string;
        readonly agency_type: string;
        readonly unique_event_id: string;
    };

type LeopardAppUsageData = {
    agent_external_id: string;
    app_version: string;
};

type LeopardAppLeadInteraction = {
    readonly agent_external_id: string;
    readonly lead_id: string;
    readonly lead_type: string;
    readonly lead_sync_timestamp: number;
};

type LeopardAppFeedInteraction = {
    readonly agent_external_id: string;
    readonly feed_id: number;
    readonly feed_type: string;
    readonly interaction_type: number;
};

type LeopardAppLeadNote = {
    readonly agent_external_id: string;
    readonly lead_id: string;
    readonly note_length: number;
    readonly lead_type: string;
};

type AdPhoneMessageConfirmationData = ClientData & {
    readonly app_type: Values<typeof OvationAppType>;
    readonly linked_trace_id: string;
};

type BannerMetricData = ClientData & {
    readonly banner_identifier: string;
    readonly metric_action: Values<typeof OvationMetricAction>;
    readonly app_type: Values<typeof OvationAppType>;
};

type FavoriteMetricData = ClientData &
    OvationAdData & {
        readonly favorite_action: Values<typeof FavoriteAction>;
    };

type OvationAPIOptions =
    | {
          readonly apiKey?: string | null | undefined;
          readonly userAgent?: string;
          readonly clientIP?: string | null | undefined;
      }
    | null
    | undefined;

/**
 * Provides access to the Ovation API.
 *
 * Ovation is the system where we record metrics and leads for
 * our customers.
 */
class OvationAPI extends HTTPApi {
    /**
     * Initializes a new instance of \see OvationAPI.
     */
    constructor(options: OvationAPIOptions = null) {
        super();

        // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
        this.options = options || {};
    }

    baseURL(): string | null | undefined {
        return process.env.IS_SERVER ? CONFIG.build.OVATION_PRIVATE_URL : CONFIG.build.OVATION_URL;
    }

    requestHeaders(): any {
        let headers: any = { 'Content-Type': 'application/json; charset=utf-8' };

        // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
        if (this.options.apiKey) {
            // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
            headers.Authorization = `Token ${this.options.apiKey}`;
        }
        // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
        if (this.options.userAgent) {
            // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
            headers = { ...headers, 'User-Agent': this.options.userAgent };
        }
        // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
        if (this.options.clientIP) {
            headers = {
                ...headers,
                'Access-Control-Expose-Headers': 'X-Forwarded-For',
                // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'OvationAPI'.
                'X-Forwarded-For': this.options.clientIP,
            };
        }

        return headers;
    }

    /**
     * Makes a POST request to Ovation at the specified path.
     */
    post(path: string, body: any): Promise<any> {
        let url = this.baseURL();

        // ovation support is disabled
        if (!url) {
            return Promise.resolve({ status: 501, data: 'Ovation not enabled' });
        }

        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }

        const headers = this.requestHeaders();

        return super.request(url + path, null, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        });
    }

    /**
     * Makes a GET request to Ovation at the specified path.
     */
    get(path: string, parameters: any): Promise<any> {
        let url = this.baseURL();

        // ovation support is disabled
        if (!url) {
            return Promise.resolve({ status: 501, data: 'Ovation not enabled' });
        }

        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }

        const headers = this.requestHeaders();

        return super.request(url + path, parameters, {
            method: 'GET',
            headers,
        });
    }

    /**
     * Ingests an ad metric and sends it to Ovation.
     */
    ingestAdMetrics(data: Array<AdMetricData>): Promise<any> {
        return this.post('/ingest/adMetric/', data);
    }

    /**
     * Ingest e-mail leads for ads and sends it to Ovation.
     */
    ingestAdEmailLeads(data: Array<AdEmailLeadData>): Promise<any> {
        return this.post('/ingest/adEmailLead/', data);
    }

    /**
     * Ingest e-mail leads for agencies and sends it to Ovation.
     */
    ingestAgencyEmailLeads(data: Array<AgencyEmailLeadData>): Promise<any> {
        return this.post('/ingest/agencyEmailLead/', data);
    }

    /**
     * Ingest an agency metric and send it to Ovation
     */
    ingestAgencyMetrics(data: Array<AgencyMetricData>): Promise<any> {
        return this.post('/ingest/agencyMetric/', data);
    }

    /**
     * Ingest e-mail leads for agents and sends it to Ovation.
     */
    ingestAgentEmailLeads(data: Array<AgentEmailLeadData>): Promise<any> {
        return this.post('/ingest/agentEmailLead/', data);
    }

    /**
     * Ingest seller leads and sends it to Ovation.
     */
    ingestSellerLeads(data: Array<SellerLeadData>): Promise<APIResponse<unknown>> {
        return this.post('/ingest/sellerLead/', data);
    }

    /**
     * Ingest an agent metric and send it to Ovation
     */
    ingestAgentMetrics(data: Array<AgentMetricData>): Promise<any> {
        return this.post('/ingest/agentMetric/', data);
    }

    /**
     * Ingest Leopard app usage.
     *
     * This will track the last usage of the app by an agent.
     */
    ingestLeopardAppUsage(data: LeopardAppUsageData): Promise<any> {
        return this.post('/ingest/leopardAppUsage/', data);
    }

    /**
     * Ingest chat leads for ads and sends it to Ovation.
     */
    ingestAdChatLeads(data: Array<ChatLeadData>): Promise<any> {
        return this.post('/ingest/adChatLead/', data);
    }

    /**
     * Ingest leopard app lead interaction and send it to Ovation
     */
    ingestLeopardAppLeadInteraction(data: LeopardAppLeadInteraction): Promise<any> {
        return this.post('/ingest/leopardAppLeadInteraction/', data);
    }

    ingestLeopardAppFeedInteraction(data: LeopardAppFeedInteraction): Promise<any> {
        return this.post('/ingest/leopardAppFeedInteraction/', data);
    }
    /**
     * Ingest leopard app lead note and send it to Ovation
     */
    ingestLeopardAppNote(data: LeopardAppLeadNote): Promise<any> {
        return this.post('/ingest/leopardAppLeadNote/', data);
    }

    /**
     * Ingest ad phone message confirmation and sends it to Ovation.
     */
    ingestAdPhoneMessageConfirmations(data: Array<AdPhoneMessageConfirmationData>): Promise<any> {
        return this.post('/ingest/adPhoneMessageConfirmation/', data);
    }

    /**
     * Ingests a banner metric and sends it to Ovation.
     */
    ingestBannerMetrics(data: Array<BannerMetricData>): Promise<any> {
        return this.post('/ingest/bannerMetric/', data);
    }

    /**
     * Increments or decrements the favorite count of an ad as tracked in Ovation.
     */
    ingestAdFavorite(data: FavoriteMetricData): Promise<any> {
        return this.post('/ingest/adFavorite/', data);
    }
}

export default OvationAPI;
