//@ts-nocheck
import Currency from './currency';
import LocalPrice from './localPrice';
import LocalCurrency from './localCurrency';
import connectLocalPrice, { type ConnectedLocalPriceProps } from './connectLocalPrice';
import useLocalPrice from './useLocalPrice';
import { setExchangeRates } from './state';
import { fetchExchangeRates } from './exchangeRates';
import { toLocalPrice, formatLocalPriceValue } from './localPriceFormatting';
import selectCurrency from './selectors';
import humanizePrice from './humanizePrice';
import convertPrice from './convertPrice';
import useComputeLocalisedPricePerArea from './useComputeLocalisedPricePerArea';
import useDefaultCurrencySelectionConfiguration from './useDefaultCurrencySelectionConfiguration';

export type { ExchangeRates } from './exchangeRates';
export type { ConnectedLocalPriceProps };

export {
    Currency,
    LocalPrice,
    LocalCurrency,
    connectLocalPrice,
    useLocalPrice,
    setExchangeRates,
    fetchExchangeRates,
    toLocalPrice,
    formatLocalPriceValue,
    selectCurrency,
    humanizePrice,
    convertPrice,
    useComputeLocalisedPricePerArea,
    useDefaultCurrencySelectionConfiguration,
};

export default Currency;
