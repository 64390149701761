import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectRouteURL } from 'strat/routes/selectors';
import { clearWallpaperTakeover } from 'strat/wallpaper/state';

const useWallpaperRemover = () => {
    const route = useSelector(selectRouteURL);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(clearWallpaperTakeover());
    }, [route, dispatch]);
};

export default useWallpaperRemover;
