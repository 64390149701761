import * as React from 'react';
import { Trans } from '@lingui/macro';
import isNil from 'lodash/isNil';
import settings from '@app/branding/settings';

import type { CartPackage } from 'horizontal/packages/types';
import { ProductType, ProductSection } from 'horizontal/types';
import { PaymentProductPackageOffer } from 'horizontal/payment';

import { getProductSection } from './utils';
import { FrequencyTimeUnitType } from './types';
import { useLocalizedDays, useLocalizedAds } from './hooks';

const useGetCartItemDescription = (packageOffer: PaymentProductPackageOffer | CartPackage) => {
    const localizedDays = useLocalizedDays();
    const localizedAds = useLocalizedAds();

    const { productItems, productsQuantity } = packageOffer.productPackage;
    if (productItems.length === 2) {
        switch (getProductSection(productItems)) {
            case ProductSection.postMoreAndFeature: {
                const featuredDuration = productItems.find(
                    (p) => p.type === ProductType.FEATURED,
                )?.duration;
                let postMoreDuration = productItems.find(
                    (p) => p.type === ProductType.LIMIT_BUMP,
                )?.duration;
                postMoreDuration = isNil(postMoreDuration)
                    ? settings.defaultDaysDuration
                    : postMoreDuration;
                return (
                    <Trans>
                        <b>Post {localizedAds(productsQuantity)}</b> valid for{' '}
                        <b>{localizedDays(postMoreDuration)}</b>, Feature Service valid for{' '}
                        <b>{localizedDays(featuredDuration)}</b>
                    </Trans>
                );
            }
            case ProductSection.postMoreAndBoost: {
                const autoRefreshProduct = productItems.find(
                    (product) => product.type === ProductType.AUTO_REFRESH_AD,
                );
                const boostFrequency = autoRefreshProduct?.frequency;
                const boostDuration = autoRefreshProduct?.duration;
                const limitDuration = productItems.find(
                    (product) => product.type === ProductType.LIMIT_BUMP,
                )?.duration;
                if (boostFrequency !== 1) {
                    if (boostDuration === limitDuration) {
                        return (
                            <Trans>
                                <b>Post {localizedAds(productsQuantity)}</b> valid for{' '}
                                <b>{localizedDays(limitDuration)}</b> and refreshed every{' '}
                                <b>{localizedDays(boostFrequency)}</b>
                            </Trans>
                        );
                    }
                    return (
                        <Trans>
                            <b>Post {localizedAds(productsQuantity)}</b> valid for{' '}
                            <b>{localizedDays(limitDuration)}</b>, Auto Boost service valid for{' '}
                            <b>{localizedDays(boostDuration)}</b>, Ad auto-refreshed every{' '}
                            <b>{localizedDays(boostFrequency)}</b>
                        </Trans>
                    );
                }
                if (boostDuration === limitDuration) {
                    return (
                        <Trans>
                            <b>Post {localizedAds(productsQuantity)}</b> valid for{' '}
                            <b>{localizedDays(limitDuration)}</b> and refreshed daily
                        </Trans>
                    );
                }
                return (
                    <Trans>
                        <b>Post {localizedAds(productsQuantity)}</b> valid for{' '}
                        <b>{localizedDays(limitDuration)}</b>, Auto Boost service valid for{' '}
                        <b>{localizedDays(boostDuration)}</b>
                    </Trans>
                );
            }
        }
    } else if (productItems.length === 1) {
        const productType = productItems[0]?.type;
        let duration = productItems[0].duration;

        switch (productType) {
            case ProductType.LIMIT_BUMP:
                duration = isNil(duration) ? settings.defaultDaysDuration : duration;
                return (
                    <Trans>
                        <b>Post {localizedAds(productsQuantity)}</b> valid for{' '}
                        <b>{localizedDays(duration)}</b>
                    </Trans>
                );
            case ProductType.FEATURED:
                return (
                    <Trans>
                        <b>{productsQuantity} Featured</b> Service valid for{' '}
                        <b>{localizedDays(duration)}</b>
                    </Trans>
                );
            case ProductType.REFRESH:
                return <Trans>Boost to Top</Trans>;
            case ProductType.AUTO_REFRESH_AD:
            case ProductType.AUTO_REFRESH_CATEGORY: {
                const frequency = productItems[0].frequency || 0;
                const frequencyTimeUnit =
                    productItems[0].frequencyTimeUnit === FrequencyTimeUnitType.DAYS ? (
                        <Trans>days</Trans>
                    ) : (
                        <Trans>hours</Trans>
                    );
                return frequency > 1 ? (
                    <Trans>
                        <b>Pack of {productsQuantity}</b> Auto Boost service valid for{' '}
                        <b>{localizedDays(duration)}</b>, ad will be auto-refreshed every{' '}
                        <b>
                            {frequency} {frequencyTimeUnit}
                        </b>
                    </Trans>
                ) : (
                    <Trans>
                        <b>Pack of {productsQuantity}</b> Auto Boost service valid for{' '}
                        <b>{localizedDays(duration)}</b>
                    </Trans>
                );
            }
            case ProductType.ELITE:
                return (
                    <Trans>
                        Elite ad valid for <b>{localizedDays(duration)}</b>
                    </Trans>
                );
        }
    }
};

export default useGetCartItemDescription;
