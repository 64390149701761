import { useSelector } from 'react-redux';
import humanizeDuration from '@sector-labs/humanize-duration';
import { selectLanguage } from 'strat/i18n/language/selectors';

const humanizePeriod = (milliseconds: number, language: string, options?: any): string =>
    humanizeDuration(milliseconds, {
        largest: 1,
        units: ['y', 'mo', 'w', 'd', 'h', 'm'],
        round: true,
        language,
        ...(options || {}),
    });

const useHumanizedPeriod = (
    milliseconds?: number | null,
    options?: any,
): string | null | undefined => {
    const language = useSelector(selectLanguage);

    if (milliseconds == null) {
        return undefined;
    }

    return humanizePeriod(milliseconds, language, options);
};

export { humanizePeriod, useHumanizedPeriod };
