import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

const factory = new FetcherFactory(
    ['matchingFloorPlans'],
    (params: any) =>
        new StratAPI().matchingFloorPlans(
            params.locationSlug,
            params.categorySlug,
            params.bedsCount,
            params.bathsCount,
        ),
    {
        catchServerErrors: true,
    },
);

const fetchMatchingFloorPlans = factory.creator();
const fetchMatchingFloorPlansReducer = factory.reducer();
const clearMatchingFloorPlans = () => ({
    type: factory.actions.clear,
});

export { fetchMatchingFloorPlans, clearMatchingFloorPlans };

export default fetchMatchingFloorPlansReducer;
