import { useFlatCategoryFields } from 'strat/categoryFields';

import { Category, CategoryFieldRole, FlatCategoryField } from 'horizontal/types';

const useHeaderCategoryField = (category?: Category | null): FlatCategoryField | null => {
    const categoryFields = useFlatCategoryFields(category?.id);
    return (
        categoryFields.find((categoryField) =>
            categoryField.roles.includes(CategoryFieldRole.SHOW_IN_SEARCH_HEADER),
        ) || null
    );
};

export default useHeaderCategoryField;
