import loadLibPhoneNumber from './loadLibPhoneNumber';
import { LibPhoneNumberMetadata } from './types';

const isValidPhoneNumber = (value: string, metadata?: LibPhoneNumberMetadata): Promise<boolean> =>
    loadLibPhoneNumber(metadata)
        .then((lib) => {
            if (
                CONFIG.build.STRAT_WHITELISTED_FAKE_PHONE_NUMBER_PREFIX &&
                value.startsWith(CONFIG.build.STRAT_WHITELISTED_FAKE_PHONE_NUMBER_PREFIX)
            ) {
                return true;
            }

            const phoneNumber = lib.parsePhoneNumberWithError(value);
            return phoneNumber ? phoneNumber.isValid() : false;
        })
        // TODO: bubble errors up
        // I cannot do this right now as existing calling code would
        // need to implement additional handling for this. This would
        // require changes in a areas of the code base that are not
        // well tested.
        .catch((_) => false);

export default isValidPhoneNumber;
