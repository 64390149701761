import { Area, areaInLocalUnits } from 'strat/i18n';

import useFetchAveragePrice from './useFetchAveragePrice';

const useAverageRentValue = (externalID: string, area?: number) => {
    const [averagePriceAED, loading, hasAveragePrice] = useFetchAveragePrice(externalID);

    if (loading) {
        return [averagePriceAED, loading];
    }

    const parsedAveragePrice = parseFloat(averagePriceAED);
    // In our DB area is SQM and price is AED
    // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
    const sqftArea = areaInLocalUnits(area, Area.SQM, Area.SQFT);

    if ((parsedAveragePrice && isNaN(parsedAveragePrice)) || !hasAveragePrice || !area) {
        return [null, loading];
    }

    const computedValue = Math.floor(parsedAveragePrice * sqftArea);
    return [computedValue, loading];
};

export default useAverageRentValue;
