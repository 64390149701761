import React from 'react';
import useRouter from 'react-true-router/useRouter';

type Props = {
    renderHeader: () => React.ReactElement;
};

const VerticalHeader = ({ renderHeader }: Props) => {
    const router = useRouter();

    return router.context?.match.params.utm_source?.toLowerCase() !== 'mobile'
        ? renderHeader()
        : null;
};

export default VerticalHeader;
