import * as React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import brandingSettings from '@app/branding/settings';
import { selectGeoLocationData } from 'strat/user/selectors';

import type { LiteAd } from 'horizontal/types';

import { useLocationFilterValue } from '../location';

import NoExactHits from './noExactHits';
import HitList from './hitList';

type Props = {
    readonly viewType: Values<typeof SearchHitsLayout>;
    readonly hits: Array<LiteAd>;
    readonly loading?: boolean;
    readonly loadingHitsCount?: number;
    readonly emptyDivPosition?: number;
    readonly CTAPosition?: number;
    readonly viewSection: Values<typeof ViewSections>;
    readonly title?: React.ReactNode;
    readonly startingHitsIndex?: number;
};

const ExactHitList = ({
    viewType,
    hits,
    loading,
    loadingHitsCount,
    emptyDivPosition,
    CTAPosition,
    viewSection,
    title,
    startingHitsIndex,
}: Props) => {
    const userLocation = useSelector(selectGeoLocationData);
    const location =
        useLocationFilterValue() ||
        userLocation?.closestLocation ||
        brandingSettings.topLevelLocation;
    if (!loading && hits.length === 0) {
        // @ts-expect-error
        return <NoExactHits location={location} />;
    }

    return (
        <>
            {!!hits.length && title}
            <HitList
                viewType={viewType}
                hits={hits}
                loading={loading}
                loadingHitsCount={loadingHitsCount}
                emptyDivPosition={emptyDivPosition}
                CTAPosition={CTAPosition}
                viewSection={viewSection}
                startingHitsIndex={startingHitsIndex}
            />
        </>
    );
};

export default ExactHitList;
