import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Trans } from '@lingui/macro';

import { type ProductPackageOffer, PackageType } from 'horizontal/types';
import { BusinessPackageSubsection } from 'horizontal/packages/types';
import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';

import { useLocalizedDays } from '../hooks';

import Section from './sectionRedesign';

const EliteAdSectionRedesign = ({
    packageOffers,
}: {
    packageOffers: Array<ProductPackageOffer>;
}) => {
    const i18n = useI18n();
    const iconSrc = usePackagesIcons({ type: PackageType.ELITE });
    const localizedDays = useLocalizedDays();

    const details = [t(i18n)`Place your ad above all other ads in the chosen category`];

    const getSubsectionsByDuration = React.useMemo(() => {
        packageOffers.sort(
            (a, b) =>
                (a.productPackage.productItems[0].duration || 0) -
                (b.productPackage.productItems[0].duration || 0),
        );

        let duration =
            packageOffers[0]?.productPackage.productItems[0] &&
            packageOffers[0]?.productPackage.productItems[0].duration;
        let currentSubsection: Array<ProductPackageOffer> = [];
        const subsections: Array<BusinessPackageSubsection> = [];

        packageOffers.forEach((offer) => {
            if (offer.productPackage.productItems[0].duration !== duration) {
                subsections.push({
                    title: (
                        <Trans>
                            Elite ad valid for <b>{localizedDays(duration)}</b>
                        </Trans>
                    ),
                    packageOffers: currentSubsection,
                });
                currentSubsection = [];
                duration = offer.productPackage.productItems[0].duration;
            }
            currentSubsection.push(offer);
        });
        if (currentSubsection) {
            subsections.push({
                title: (
                    <Trans>
                        Elite service valid for <b>{localizedDays(duration)}</b>
                    </Trans>
                ),
                packageOffers: currentSubsection,
            });
        }

        return subsections;
    }, [packageOffers, localizedDays]);

    return (
        <Section
            title={t(i18n)`Elite Service`}
            details={details}
            subsections={getSubsectionsByDuration}
            renderIcon={() => iconSrc && <img src={iconSrc} alt="Elite" />}
            disclaimer={t(i18n)`Can only be used on active ad`}
        />
    );
};

export default EliteAdSectionRedesign;
