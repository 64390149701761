import type { Primitive } from 'strat/types';

import { PriceType, ContactPersonRole, ConditionType } from 'horizontal/types';
import { AdCommonExtraField } from '@app/branding/adExtraFields';

export type Field = {
    readonly attribute: string;
    readonly defaultValue: Primitive;
};

// @ts-expect-error - TS2322 - Type 'Readonly<{ title: { attribute: string; defaultValue: string; }; description: { attribute: string; defaultValue: string; }; photos: { attribute: string; defaultValue: never[]; }; location_id: { attribute: string; defaultValue: string; }; category_id: { ...; }; }>' is not assignable to type '{ [key: string]: Field; }'.
const CommonPostingFields: {
    [key: string]: Field;
} = Object.freeze({
    title: {
        attribute: 'title',
        defaultValue: '',
    },
    description: {
        attribute: 'description',
        defaultValue: '',
    },
    photos: {
        attribute: 'photos',
        defaultValue: [],
    },
    location_id: {
        attribute: 'location_id',
        defaultValue: '',
    },
    category_id: {
        attribute: 'category_id',
        defaultValue: '',
    },
});

export const AddressFormFields: {
    [key: string]: Field;
} = Object.freeze({
    location: {
        attribute: 'location',
        defaultValue: '',
    },

    locationPath: {
        attribute: 'locationPath',
        defaultValue: '',
    },
});

// @ts-expect-error - TS2322 - Type 'Readonly<{ name: { attribute: string; defaultValue: string; }; phone_number: { attribute: string; defaultValue: string; }; roles: { attribute: string; defaultValue: ("show_phone_number" | "allow_chat_communication")[]; }; }>' is not assignable to type '{ [key: string]: Field; }'.
export const ContactInfoFields: {
    [key: string]: Field;
} = Object.freeze({
    name: {
        attribute: 'name',
        defaultValue: '',
    },
    phone_number: {
        attribute: 'phone_number',
        defaultValue: '',
    },
    whatsappContactPhoneNumber: {
        attribute: 'whatsappContactPhoneNumber',
        defaultValue: '',
    },
    is_otp_verified: {
        attribute: 'is_otp_verified',
        defaultValue: true,
    },
    roles: {
        attribute: 'roles',
        defaultValue: [
            ContactPersonRole.SHOW_PHONE_NUMBER,
            ContactPersonRole.ALLOW_CHAT_COMMUNICATION,
        ],
    },
});

export const AdDeliveryFields: {
    [key: string]: Field;
} = Object.freeze({
    is_deliverable: {
        attribute: 'is_deliverable',
        defaultValue: false,
    },
    delivery_type: {
        attribute: 'delivery_type',
        defaultValue: '',
    },
    weight: {
        attribute: 'weight',
        defaultValue: '',
    },
    pickup_address_id: {
        attribute: 'pickup_address_id',
        defaultValue: '',
    },
    delivery_terms: {
        attribute: 'delivery_terms',
        defaultValue: false,
    },
    location_delivery_role: {
        attribute: 'location_delivery_role',
        defaultValue: '',
    },
    length: {
        attribute: 'length',
        defaultValue: '',
    },
    height: {
        attribute: 'height',
        defaultValue: '',
    },
    width: {
        attribute: 'width',
        defaultValue: '',
    },
});

export const PaymentOptionField = {
    attribute: 'payment_option',
} as const;

export const PriceTypeField = {
    attribute: 'price_type',
    defaultValue: PriceType.FIXED_PRICE,
} as const;

export const MileageField = {
    attribute: AdCommonExtraField.VEHICLE_MILEAGE,
    defaultValue: 0,
} as const;

export const ConditionField = {
    attribute: 'new_used',
    defaultValue: ConditionType.USED,
} as const;

export const ExtraFeatures = {
    attribute: 'extra_features',
} as const;

export const Features = {
    attribute: 'features',
} as const;

export const CategoryPriceField = {
    attribute: 'price',
} as const;

export const SecondaryPriceField = {
    attribute: 'secondary_price',
} as const;

export const RentalPeriodField = {
    attribute: 'rental_period',
} as const;

export const DownPaymentField = {
    attribute: 'down_payment',
} as const;

export const InsuranceAmountField = {
    attribute: 'insurance_amount',
} as const;

export const AgentCodeField = {
    attribute: 'agent_code',
} as const;

export const ShortNumberField = {
    attribute: 'short_number',
} as const;

export const ReferenceIDField = {
    attribute: 'reference_id',
} as const;

export const TypeField = {
    attribute: 'type',
} as const;

export const DownPayment = {
    attribute: 'down_payment',
} as const;

export const PaymentOption = {
    attribute: 'payment_option',
} as const;

export const VirtualTourField = {
    attribute: 'panorama',
} as const;

export const VideoField = {
    attribute: 'video',
} as const;

export const ExtraFields: {
    [key: string]: Field;
} = Object.freeze({
    make: {
        attribute: 'make',
        defaultValue: '',
    },
    model: {
        attribute: 'model',
        defaultValue: '',
    },
    generation: {
        attribute: 'generation',
        defaultValue: '',
    },
    version: {
        attribute: 'version',
        defaultValue: '',
    },
    year: {
        attribute: 'year',
        defaultValue: '',
    },
});

export default CommonPostingFields;
