import * as React from 'react';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import { Flex, Text } from 'strat/components';

import IconPending from '@app/assets/icons/iconPending_noinline.svg';

import styles from './styles/paymentProcessingContent.cssm';

type Props = {
    readonly children: React.ReactNode;
};
const PaymentProcessingContent = ({ children }: Props) => {
    return (
        <PageWidthWrapper>
            <Flex column className={styles.wrapper} alignCenter>
                <Flex justifyCenter className={styles.iconContainer}>
                    <img src={IconPending} alt={'Icon Pending'} className={styles.icon} />
                </Flex>
                <Flex className={styles.textContainer}>
                    <Text.XLarge bold>
                        <Trans>Payment is being processed</Trans>
                    </Text.XLarge>
                </Flex>
                {children}
            </Flex>
        </PageWidthWrapper>
    );
};

export default PaymentProcessingContent;
