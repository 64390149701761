import { useSelector } from 'react-redux';
import { selectSearchSettings } from '@sector-labs/fe-search-redux/state';
import { useI18n, selectNumberFormatterFactory } from 'strat/i18n/language';
import { t, plural } from '@lingui/macro';

const useHitCountLabel = (count: number, isExhaustive = true): string => {
    const i18n = useI18n();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    const { exhaustiveNbHits } = useSelector(selectSearchSettings);

    const numberFormatter = numberFormatterFactory();

    const exhaustiveFormattedCount = numberFormatter.format(count);
    const exhaustiveLabel = t(i18n)`${plural(count, {
        one: `${exhaustiveFormattedCount} ad`,
        other: `${exhaustiveFormattedCount} ads`,
    })}`;

    const noneExhaustiveFormattedCount = numberFormatter.format(
        // @ts-expect-error - TS2769 - No overload matches this call. | TS2532 - Object is possibly 'undefined'.
        count > exhaustiveNbHits ? exhaustiveNbHits : count,
    );
    const noneExhaustiveLabel = t(i18n)`${plural(count, {
        one: `${noneExhaustiveFormattedCount} ad`,
        other: `${noneExhaustiveFormattedCount}+ ads`,
    })}`;

    // if the hit count is not exhaustive, we have more results than
    // ES/Algolia wanted to count, we'll simply display '10,000+ ads'
    return isExhaustive || count === 0 ? exhaustiveLabel : noneExhaustiveLabel;
};

export default useHitCountLabel;
