import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { FetcherFactoryWithContext } from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import { FilterValues } from 'strat/search';

type BrowsePropertiesLinksParams = {
    purpose: string;
    locationID: string;
};

const browsePropertiesLinksFactory = new FetcherFactoryWithContext<BrowsePropertiesLinksParams>(
    'browsePropertiesLinks',
    ({ params, state }) =>
        new StratAPI(state.i18n.language).browsePropertiesLinks(params.locationID, params.purpose),
    {
        catchServerErrors: true,
        successCodes: [200, 204, 404],
    },
);

const browsePropertiesLinksCreator = browsePropertiesLinksFactory.creator();
const browsePropertiesLinksReducer = browsePropertiesLinksFactory.reducer();

const fetchBrowsePropertiesLinks = (filters: FilterCollection) => {
    const defaultExternalID = FilterValues.location?.placeholderExternalID ?? 0;
    const defaultCityID = FilterValues.city?.default?.externalID ?? 0;

    const locationID = CONFIG.runtime.BROWSE_PROPERTIES_ALWAYS_USE_ROOT_LOCATION
        ? defaultExternalID
        : // @ts-expect-error - TS2571 - Object is of type 'unknown'.
          filters.getFilterValue(FilterValues.city.attribute, { externalID: defaultCityID })
              .externalID;

    const purpose = filters.getFilterValue(FilterValues.purpose.attribute);

    // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'string'.
    return browsePropertiesLinksCreator({ purpose, locationID });
};

export { fetchBrowsePropertiesLinks, browsePropertiesLinksReducer };
