import React from 'react';
import type { FormikValues } from 'formik';
import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import { useRouter } from 'react-true-router';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { APIError } from 'strat/api/error';

import { ContactInfoFields } from 'horizontal/fields/commonPostingFields';

type FieldError = {
    field: string;
    message: string;
};

type AdScrubberErrorData = {
    messages: FieldError[];
};

const isAdScrubberErrorData = (errorData: unknown): errorData is AdScrubberErrorData => {
    const data = errorData as AdScrubberErrorData;
    return !(!data?.messages || !data.messages.length);
};

const getFieldErrors = (error: APIError | unknown, i18n: I18n): FieldError[] | null => {
    if (!(error instanceof APIError) || !isAdScrubberErrorData(error.data)) {
        return null;
    }

    const fieldErrors: FieldError[] = [];
    error.data.messages.forEach(({ field, message }) => {
        if (field === 'phone_number' && message === 'Phone number is assigned to another user') {
            fieldErrors.push({
                field: ContactInfoFields.phone_number.attribute,
                message: t(i18n)`This phone number is already used for another account`,
            });
        }
    });

    return fieldErrors.length ? fieldErrors : null;
};

const useOnSubmitAdError = ({ isUpdate = false }: { isUpdate?: boolean } = {}) => {
    const router = useRouter();
    const i18n = useI18n();

    return React.useCallback(
        (
            error: APIError | unknown,
            setFieldError: (field: keyof FormikValues, value: string) => void,
        ) => {
            const fieldErrors = getFieldErrors(error, i18n);
            if (fieldErrors) {
                fieldErrors.forEach(({ field, message }) => {
                    setFieldError(field, message);
                });
                return;
            }

            if (!isUpdate) {
                trigger(Triggers.AD_CREATION_FAILED, {
                    view_section: ViewSections.BODY,
                });
            }
            router.pushRoute(RouteNames.INTERNAL_ERROR);
        },
        [router, i18n, isUpdate],
    );
};

export default useOnSubmitAdError;
