import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import { PropertyCompletionStatus } from 'strat/property/types';

import styles from './styles/completionStatusBadge.cssm';

type Props = {
    completionStatus?: Values<typeof PropertyCompletionStatus>;
    light?: boolean;
};

const CompletionStatusBadge = ({ completionStatus, light }: Props) => {
    switch (completionStatus) {
        case PropertyCompletionStatus.COMPLETED:
            return (
                <Flex alignCenter className={classNames(styles.badge, styles.greenBadge)}>
                    <Trans>Completed</Trans>
                </Flex>
            );
        case 'upcoming':
            return (
                <Flex alignCenter className={classNames(styles.badge, styles.blueBadge)}>
                    <Trans>Upcoming</Trans>
                </Flex>
            );
        default:
            return (
                <Flex
                    alignCenter
                    className={classNames(styles.badge, styles.purpleBadge, {
                        [styles.lightPurpleBadge]: light,
                    })}
                >
                    <Trans>Under Construction</Trans>
                </Flex>
            );
    }
};

export default CompletionStatusBadge;
