import { t } from '@lingui/macro';
import * as React from 'react';
import { GTMLeadTrackingProps, ViewSections, withGTMLeadTracking } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';
import { Button } from 'strat/components';

import IconChatWithDots from 'horizontal/assets/icons/iconChatWithDots.svg';
import { ChatRoomLink } from 'horizontal/chat/widgets/large';
import { LiteAd } from 'horizontal/types';

import styles from './styles/contactButtons.cssm';

type Props = GTMLeadTrackingProps & {
    readonly adExternalID: string;
    readonly userExternalID: string | null | undefined;
    readonly viewSection: Values<typeof ViewSections>;
    readonly className?: string;
    readonly secondary?: boolean;
};

const ChatCTA = ({
    adExternalID,
    userExternalID,
    viewSection,
    property,
    className,
    secondary,
}: Props) => {
    const i18n = useI18n();

    if (!userExternalID) {
        return null;
    }

    return (
        <ChatRoomLink
            adXID={adExternalID}
            sellerXID={userExternalID}
            className={styles.buttonWrapper}
            ariaLabel="Chat"
            viewSection={viewSection}
            property={property}
        >
            <Button
                stretch
                className={className}
                secondary={secondary}
                small
                smallIcon
                renderIcon={(className) => <IconChatWithDots className={className} />}
            >
                {t(i18n)`Chat`}
            </Button>
        </ChatRoomLink>
    );
};

export default withGTMLeadTracking<Props, LiteAd>(ChatCTA);
