import * as React from 'react';

import styles from './styles/scrollable.cssm';

type Props = {
    id?: string;
    children: React.ReactNode;
    className?: string;
    onScroll?: () => void;
};

/**
 * Renders all provided children into a simple div with _Momentum_ scrolling.
 *
 * Also hacks around iOS bugs and keeps a lower boundary of 1px on each side
 * of the scrollable to make sure the scrolling won't freeze.
 *
 * Do not use this if it's not necessary.
 */
const Scrollable = ({ className, children, ...props }: Props) => (
    <div className={[styles.scrollable, className].join(' ')} {...props}>
        {children}
    </div>
);

export default Scrollable;
