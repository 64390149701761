import { createSelector } from 'reselect';

import EMPTY_OBJECT from 'strat/empty/object';

const selectPriceTrendsData = (state: any) => (state.priceTrends || EMPTY_OBJECT).data;

const selectPriceTrendsLoading = (state: any) => (state.priceTrends || EMPTY_OBJECT).loading;

const selectPriceTrendsLoaded = (state: any) => (state.priceTrends || EMPTY_OBJECT).loaded;

const selectPriceTrendsError = (state: any) => (state.priceTrends || EMPTY_OBJECT).error;

const selectHasMonthlyAveragePrice = createSelector(
    selectPriceTrendsData,
    selectPriceTrendsLoaded,
    selectPriceTrendsError,
    (data, loaded, error) => {
        // eslint-disable-next-line camelcase
        const dataLoaded = loaded && data?.prev_avg_psf_aed;
        const hasError = !loaded && !!error;

        return dataLoaded && !hasError;
    },
);

export {
    selectPriceTrendsData,
    selectPriceTrendsLoading,
    selectHasMonthlyAveragePrice,
    selectPriceTrendsError,
};
