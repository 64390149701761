import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { logError } from 'strat/error/log';

import StratAPI from 'horizontal/api/strat';

import type { RequestDeliveryData } from './types';

type UsePostRequestDelivery = {
    readonly onPostRequestDelivery: (
        arg1: RequestDeliveryData,
        arg2: string,
        arg3: () => void,
    ) => void;
    readonly loading: boolean;
};

const usePostRequestDelivery = (): UsePostRequestDelivery => {
    const router = useRouter();
    const [loading, setLoading] = React.useState(false);
    const onPostRequestDelivery = React.useCallback(
        (request: RequestDeliveryData, adExternalID: string, onSuccess: () => void) => {
            setLoading(true);
            new StratAPI().requestDelivery(adExternalID, request).then(
                ({ status }) => {
                    if (status !== 200) {
                        router.pushRoute(RouteNames.INTERNAL_ERROR);
                        return;
                    }
                    setLoading(false);
                    onSuccess();
                },
                (e) => {
                    setLoading(false);
                    logError({
                        e,
                        msg: 'Error while requesting delivery',
                    });
                },
            );
        },
        [router],
    );

    return {
        onPostRequestDelivery,
        loading,
    };
};

export default usePostRequestDelivery;
