import {
    FilterCollection,
    RefinementFilter,
    ExactFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { AlgoliaSearchBackend } from '@sector-labs/fe-search-redux/backend/algolia';

import { SortByValues } from 'strat/search/sortValues';
import { PropertyType } from 'strat/property/types';
import { determineAdsIndexName } from 'strat/search/indexNames';

export const fetchAttributesFromAlgolia = (
    listingIDs: number[],
    language: string,
    listingType: Values<typeof PropertyType>,
) => {
    const indexName = determineAdsIndexName({
        sortBy: SortByValues.DATE_DESC,
        language,
        adType: listingType,
    });
    const filters = new FilterCollection();
    filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
    filters.refine(new RefinementFilter({ attribute: 'objectID', value: listingIDs }));

    const job = new SearchJob(indexName, filters, {
        facets: [],
        attributesToRetrieve: '*',
        hitsPerPage: listingIDs.length,
    });

    const service = new SearchService({
        backend: new AlgoliaSearchBackend({
            appId: CONFIG.build.ALGOLIA_APP_ID,
            apiKey: CONFIG.build.ALGOLIA_SEARCH_API_KEY,
        }),
    });

    return service.fetchJob(job).then((result) => {
        return result.hits.reduce<Record<string, any>>((acc, value) => {
            return {
                ...acc,
                // @ts-expect-error - TS2464 - A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
                [value.objectID]: value,
            };
        }, {});
    });
};
