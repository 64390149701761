import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

type Props = {
    commercialNumber: string;
};

const CommercialNumber = ({ commercialNumber }: Props) => {
    const i18n = useI18n();
    return <Text.Small>{t(i18n)`Comm. ID: ${commercialNumber}`}</Text.Small>;
};

export default CommercialNumber;
