import { t } from '@lingui/macro';
import React from 'react';
import classNames from 'classnames';
import Copyright from '@app/branding/copyright';
import useFooterLinks from '@app/footer/useFooterLinks';
import settings from '@app/branding/settings';
import MobileAppDownload from '@app/branding/mobileAppDownload';
import { PageWidthWrapper } from 'strat/generic';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

import SocialMedia from '@app/footer/socialMedia';

import LinkListSection from './linkListSection';
import styles from './styles/footer.cssm';

/**
 * Properties for {@see Footer}.
 */
type Props = {
    className?: string;
};

const Footer = (props: Props) => {
    const i18n = useI18n();
    const footerLinks = useFooterLinks();
    const footerLinksList = footerLinks.map((item, index) => {
        return (
            <LinkListSection key={`linkSection-${index}`} title={item.section} links={item.links} />
        );
    });

    return (
        <footer className={classNames(styles.container, props.className)}>
            <div className={styles.mainSection}>
                <PageWidthWrapper className={styles.spacedFlex}>
                    {footerLinksList}
                    <section className={styles.socialMedia}>
                        {settings.enableSocialMedia && (
                            <>
                                <Text.Regular bold uppercase>{t(i18n)`Follow us`}</Text.Regular>
                                <SocialMedia />
                            </>
                        )}
                        <MobileAppDownload />
                    </section>
                </PageWidthWrapper>
            </div>
            <div className={styles.bottom}>
                <PageWidthWrapper>
                    <Copyright />
                </PageWidthWrapper>
            </div>
        </footer>
    );
};

export default Footer;
