import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { trigger, Triggers, ViewSections } from 'strat/gtm';

import { UserSettingsKey } from '../types';

import UserSettingsToggle from './userSettingsToggle';

const SafetyTipsControl = () => {
    const i18n = useI18n();

    const trackSafetyNotificationsToggle = (isSelected: boolean) => {
        trigger(Triggers.STOP_SAFETY, {
            filter_name: 'safety-toggle',
            filter_value: isSelected ? 'on' : 'off',
            view_section: ViewSections.SETTINGS_NOTIFICATIONS,
        });
    };

    return (
        <UserSettingsToggle
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'keyof UserSettings'.
            settingsKey={UserSettingsKey.CHAT_SAFETY_TIPS_ACTIVE}
            title={t(i18n)`Chat Safety Tips`}
            subtitle={t(i18n)`Receive safety tips based on your chat activity`}
            trackToggle={trackSafetyNotificationsToggle}
        />
    );
};

export default SafetyTipsControl;
