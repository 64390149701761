import * as React from 'react';
import { GTMDataLayerVariables, Triggers } from 'strat/gtm';

import { usePageViewTrigger, useCategoryVars } from 'horizontal/gtm';
import type { PaymentOrder } from 'horizontal/payment';

import useGlobalGTMVars from '../useGlobalGTMVars';

import PageType from './pageType';

type Props = {
    readonly order?: PaymentOrder;
};

const GTMPaymentSelectionDataLayer = ({ order }: Props) => {
    const globalVars = useGlobalGTMVars();
    const { categoryExternalID, productCategoryExternalID } = order || {};
    const categoryVars = useCategoryVars(productCategoryExternalID || categoryExternalID);

    const variables = {
        ...globalVars,
        product_id: order?.productIDs,
        order_id: order?.externalID,
        ad_id: order?.adExternalID,
        payment_amount: order?.total,
        page_type: PageType.PAYMENT,
        ...categoryVars,
    } as const;

    usePageViewTrigger(Triggers.SELECT_PAYMENT_VIEW, variables, !order);

    return <GTMDataLayerVariables variables={variables} />;
};

export default GTMPaymentSelectionDataLayer;
