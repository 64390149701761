import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { AutoPlayCarousel } from 'strat/generic';
import { selectIsLanguageRTL, selectLanguage } from 'strat/i18n/language/selectors';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';
import { makeCancelable } from 'strat/util';
import { thumbnailURL, ThumbnailSizes, ThumbnailSizeValues } from 'strat/image';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import { StratAPI } from 'horizontal/api';

import Banner from './banner';
import styles from './styles/bannerAdsCarousel.cssm';

const BannerAdsCarousel = ({ ovation }: { ovation: OvationCallbackProps }) => {
    const language = useSelector(selectLanguage);
    const isRTL = useSelector(selectIsLanguageRTL);
    const isMobile = useSelector(selectIsMobileLayout);
    const platform = isMobile ? 'app' : 'web';
    const [bannerAds, setBannerAds] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    const numberOfBanners = bannerAds?.length;

    const trackBannerView = React.useCallback(
        ({ index, position }) => {
            if (Math.abs(index) < numberOfBanners) {
                // @ts-expect-error - TS2339 - Property 'identifier' does not exist on type 'never'.
                ovation.ingestBannerView(bannerAds[position].identifier);
            }
        },
        [ovation, bannerAds, numberOfBanners],
    );

    React.useEffect(() => {
        const fetchBannerAdsPromise = makeCancelable(new StratAPI(language).getBanners(platform));
        fetchBannerAdsPromise.then((response) => {
            const { data: banners } = response;
            // @ts-expect-error - TS7006 - Parameter 'banner' implicitly has an 'any' type.
            const bannersWithImageThumbnail = (banners || []).map((banner) => {
                return {
                    ...banner,
                    image: {
                        source: CONFIG.runtime.ENABLE_ORIGINAL_HOMEPAGE_BANNER
                            ? banner.image_source
                            : thumbnailURL(
                                  banner.image,
                                  'webp',
                                  ThumbnailSizeValues[ThumbnailSizes.LARGE],
                              ),
                    },
                };
            });
            const orderedBanners =
                isRTL && bannersWithImageThumbnail.length > 0
                    ? [
                          bannersWithImageThumbnail[0],
                          ...bannersWithImageThumbnail.slice(1).reverse(),
                      ]
                    : bannersWithImageThumbnail;
            setLoaded(true);
            setBannerAds(orderedBanners);
            if (orderedBanners.length > 0) {
                ovation.ingestBannerView(orderedBanners[0].identifier);
            }
        });
        return () => {
            // @ts-expect-error - TS2551 - Property 'canceled' does not exist on type 'PCancelable<any>'. Did you mean 'cancel'?
            if (fetchBannerAdsPromise && !fetchBannerAdsPromise.canceled) {
                fetchBannerAdsPromise.cancel();
            }
        };
        // on loaded status change, we don't want to re trigger the effect.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, isRTL, setBannerAds, platform]);

    const slideRenderer = React.useCallback(
        ({ position, index }) => {
            return (
                bannerAds[position] && (
                    <div className={styles.adBanner} key={index}>
                        {/* @ts-expect-error - TS2322 - Type '{ banner: never; }' is not assignable to type 'IntrinsicAttributes & { ref?: Ref<unknown> | undefined; key?: Key | null | undefined; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } & { ...; }'. */}
                        <Banner banner={bannerAds[position]} />
                    </div>
                )
            );
        },
        [bannerAds],
    );
    const renderDotSlider = React.useCallback(
        (onSelect, selected) => {
            const iterations = Math.floor(Math.abs(selected) / numberOfBanners);
            const dots = [...Array(numberOfBanners).keys()].map((index) => {
                const fullIndex = iterations * numberOfBanners + index;
                return (
                    <div
                        key={index}
                        onClick={() => onSelect(isRTL ? -fullIndex : fullIndex)}
                        className={styles.clickableArea}
                        aria-label="Slide button"
                    >
                        <div
                            className={classNames(styles.rectangle, {
                                [styles.active]: index === Math.abs(selected) % numberOfBanners,
                            })}
                        />
                    </div>
                );
            });
            return (
                <div key={'dots'} className={styles.dots}>
                    {dots}
                </div>
            );
        },
        [numberOfBanners, isRTL],
    );

    if (!loaded) {
        return (
            <div className={styles.container}>
                <div className={styles.adBanner} />
            </div>
        );
    }

    return bannerAds.length > 0 ? (
        <div className={styles.container}>
            <AutoPlayCarousel
                columnsCount={1}
                // @ts-expect-error - TS2322 - Type '{ columnsCount: number; renderSlide: ({ position, index }: any) => never; items: never[]; overscanSlideAfter: number; overscanSlideBefore: number; renderPrevButton: () => null; renderNextButton: () => null; interval: number; ... 4 more ...; onIndexChanged: ({ index, position }: any) => void; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<AutoPlayCarousel> & Pick<Readonly<{}> & Readonly<{ ...; }>, "children"> & Partial<...> & Partial<...>'.
                renderSlide={slideRenderer}
                items={bannerAds}
                overscanSlideAfter={1}
                overscanSlideBefore={1}
                renderPrevButton={() => null}
                renderNextButton={() => null}
                interval={8000}
                enableMouseEvents={false}
                direction={isRTL ? 'decremental' : 'incremental'}
                renderDotSlider={renderDotSlider}
                slideClassName={styles.bannerSlide}
                onIndexChanged={trackBannerView}
            />
        </div>
    ) : null;
};

export default withOvation(BannerAdsCarousel);
