import type { SerializedFilter } from '@sector-labs/fe-search-redux/filters';
import { ExactFilter, ExclusionFilter } from '@sector-labs/fe-search-redux/filters';
import { SlotBorrowStrategy } from '@sector-labs/fe-search-redux/slots';
import {
    createProductFilter,
    createRandomEliteSort,
    createRandomFeaturedSort,
    ProductType,
} from '@sector-labs/strat-horizontal-core/dist/search';
import { Options } from '@sector-labs/strat-horizontal-core';
import brandingSettings from '@app/branding/settings';
import { SearchPageSearchType } from '@app/search/types';
import { AdProduct, LiteHierarchicalLocation } from '@app/types';
import { FilterValues } from 'strat/search';

import { Location, CategoryConfigurations } from 'horizontal/types';

import getHitsPerPage from '../getHitsPerPage';

import featuredAdsCount from './featuredAdsCount';

const makeLocationFilter = (
    location?: LiteHierarchicalLocation | null,
): SerializedFilter | null => {
    if (!location) {
        return null;
    }

    return new ExactFilter({
        attribute: FilterValues.location.attribute,
        value: location.externalID,
    }).serialize();
};
type GroupedSlottingOptions = Partial<Options> & {
    searchParams: {
        location?: Location | null;
        product?: AdProduct;
    };
    categoryConfigurations: CategoryConfigurations;
};
const createSlottingConfig = (options: GroupedSlottingOptions) => {
    const { indexName, searchParams, categoryConfigurations } = options;
    const product = searchParams?.product;
    const eliteAdsSlot = {
        filters: [createProductFilter(ProductType.ELITE)],
        counted: false,
        sort: createRandomEliteSort(),
    };

    const featuredAdsSlot = {
        filters: [createProductFilter(ProductType.FEATURED)],
        counted: false,
        sort: createRandomFeaturedSort(),
    };

    const normalAdsSlot = {
        filters: [
            new ExclusionFilter({
                attribute: FilterValues.product.attribute,
                value: ProductType.ELITE,
                key: 'product-elite',
            }).serialize(),
            makeLocationFilter(searchParams?.location),
        ].filter(Boolean) as SerializedFilter[],
        counted: true,
    };

    const { eliteAdsGroupSize, featuredAdsGroupSize } = categoryConfigurations;

    const eliteAdsPerPageCount =
        product === AdProduct.ELITE
            ? getHitsPerPage(SearchPageSearchType.FEATURED_REGULAR_GROUPED) -
              Math.max(brandingSettings.eliteAdsPerPageCount, 0)
            : eliteAdsGroupSize ?? Math.max(brandingSettings.eliteAdsPerPageCount, 0);
    const featuredAdsPerPageCount =
        product === AdProduct.FEATURED
            ? featuredAdsCount(SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE) -
              Math.max(brandingSettings.eliteAdsPerPageCount, 0)
            : featuredAdsGroupSize ??
              featuredAdsCount(SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE);

    return {
        activeFor: [indexName],
        default: {
            hitsPerSlot: (_: number) => [
                eliteAdsPerPageCount,
                featuredAdsPerPageCount,
                getHitsPerPage(SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE) -
                    featuredAdsCount(SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE) -
                    eliteAdsPerPageCount,
            ],
            slots: [eliteAdsSlot, featuredAdsSlot, normalAdsSlot],
            borrowStrategy: SlotBorrowStrategy.FROM_FOLLOWING,
        },
    };
};

export default createSlottingConfig;
