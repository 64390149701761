import settings from '@app/branding/settings';
import { transformLocalizedFields } from 'strat/mergedIndex';

import type {
    Ad,
    LiteCategory,
    LiteHierarchicalLocation,
    AdProductInfo,
    LocationTranslation,
    AdTag,
} from 'horizontal/types';

/**
 * Returns an object with the language as key and the translation as value
 */
const translateFields = <T extends object>(data: T, mergedIndex: boolean) => {
    return settings.languages.reduce<Record<string, any>>((translations, { lang }) => {
        translations[lang] = transformLocalizedFields(data, lang, mergedIndex);
        return translations;
    }, {});
};

/**
 * This adTransformer function and idea was heavily inspired from
 * strat/property/propertyTransformer.jsx, look to that file for useful comments.
 */
const adTransformer = <T extends Ad>(data: T, lang: string, mergedIndex: boolean): T => {
    if (!mergedIndex || !data) {
        // At this moment, adTransformer is used only on platforms with mergedIndex = true,
        // so there is no point to go through this processing when mergedIndex != true.
        return data;
    }

    const category: Array<LiteCategory> = data.category.map((item) =>
        transformLocalizedFields(item, lang, mergedIndex),
    );

    const locationTranslations: Array<LocationTranslation> = data.location.map((loc) =>
        translateFields(loc, mergedIndex),
    );
    const location: Array<LiteHierarchicalLocation> = locationTranslations.map(
        (locationTranslation) => locationTranslation[lang],
    );

    const productInfo: AdProductInfo | null | undefined = data?.productInfo
        ? transformLocalizedFields(data.productInfo, lang, mergedIndex)
        : null;

    const localizedData = transformLocalizedFields(data, lang, mergedIndex);

    const adTags: Array<AdTag> =
        data.adTags?.map((item) => transformLocalizedFields(item, lang, mergedIndex)) || [];

    return {
        ...localizedData,
        category,
        locationTranslations,
        location,
        productInfo,
        adTags,
    };
};

export default adTransformer;
