import * as React from 'react';
import classNames from 'classnames';

import { connectScrollNav, ScrollNavProps } from 'strat/navigation/scroll';
import { Sticky } from 'strat/modal';
import { PageWidthWrapper, StylelessButton } from 'strat/generic';
import { SearchElementIdentifiers } from 'strat/search';
import { Flex } from 'strat/components';

import styles from './styles/projectNavBar.cssm';

type Props = ScrollNavProps & {
    setNavBarHeight?: (height: number) => void;
};

const ProjectNavBar = ({
    compensation,
    children,
    sections,
    index,
    scrollToSection,
    setNavBarHeight,
}: Props) => {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const filtersHeaderHeight =
        document.getElementById?.(SearchElementIdentifiers.searchPageHeaderId)?.offsetHeight || 0;

    React.useEffect(() => {
        if (ref.current && setNavBarHeight) {
            setNavBarHeight(ref.current.offsetHeight || 0);
        }
    }, [setNavBarHeight]);

    const contents = (
        <div className={styles.container} ref={ref}>
            <PageWidthWrapper>
                <Flex
                    alignCenter
                    justifyCenter
                    stretchWidth
                    className={styles.contents}
                    aria-label="Nav bar"
                >
                    {sections.map((section, sectionIndex) => (
                        <StylelessButton
                            key={section.title}
                            className={classNames(styles.button, {
                                [styles.active]: sectionIndex === index,
                            })}
                            onClick={() => {
                                scrollToSection(section.title);
                            }}
                        >
                            {section.icon}
                            {section.shortTitle || section.title}
                        </StylelessButton>
                    ))}
                </Flex>
            </PageWidthWrapper>
        </div>
    );

    return (
        <>
            <Sticky
                invisibleIfNotDetached
                key="sticky"
                verticalOffset={-compensation}
                containerID={SearchElementIdentifiers.projectPageNavBar}
                className={styles.stickyContainer}
                invisibleClassName={styles.invisibleStickyContainer}
                detachedTopPosition={filtersHeaderHeight}
            >
                {contents}
            </Sticky>
            {children}
        </>
    );
};

export default connectScrollNav(ProjectNavBar);
