import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import type { BoughtProductPackage } from 'horizontal/payment/types';
import { useFormattedPrice } from 'horizontal/util';

import styles from './styles/package.cssm';

type Props = {
    readonly boughtPackage: BoughtProductPackage;
};

const Package = ({ boughtPackage }: Props) => {
    const { id, name, currentPrice, quantity } = boughtPackage;
    return (
        <Flex justifySpaceBetween alignCenter key={id} className={styles.package}>
            <Text.Base className={styles.packageName}>
                {quantity > 1 ? (
                    <Trans>
                        <Text.Small bold>{quantity} &times; </Text.Small>
                        {name}
                    </Trans>
                ) : (
                    name
                )}
            </Text.Base>
            <Text.Small gray>{useFormattedPrice(currentPrice)}</Text.Small>
        </Flex>
    );
};

export default Package;
