import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { buildAllCanonicalURLs } from 'strat/routing';
import { selectRouteParams } from 'strat/routes/selectors';
import settings from '@app/branding/settings';

import { useAgencyProfile } from 'horizontal/agency/state';
import AgencyPageRoute from 'horizontal/routes/agencyProfileRoute';
import { getHomePageDescription } from 'horizontal/home/seo';

import CommonHead from './commonHead';

const AgencyPageHead = () => {
    const i18n = useI18n();
    const description = getHomePageDescription(i18n);
    const domainName = settings.getBrandName(i18n);
    const agency = useAgencyProfile();
    const { locationSlug } = useSelector(selectRouteParams);

    const { url, alternateURLs } = buildAllCanonicalURLs(
        // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
        AgencyPageRoute.createURL({ agencySlug: agency?.slug, locationSlug }).pathname,
        i18n.locale,
    );

    return (
        <>
            <title>{t(i18n)`${agency?.name} | ${domainName}`}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="noindex, nofollow" />
            <CommonHead url={url} alternateURLs={alternateURLs} />
        </>
    );
};

export default AgencyPageHead;
