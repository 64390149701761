import React, { Component } from 'react';
import autoBind from 'react-autobind';

import styles from './styles/hideable.cssm';

/**
 * Properties for {@see Hideable}.
 */
type Props = {
    /**
     * Elements to hide.
     */
    readonly children?: React.ReactNode;
    /**
     * Event handler for when the element becomes hidden.
     */
    readonly onHidden?: () => void;
    /**
     * Event handler for when the element becomes visible.
     */
    readonly onShown?: () => void;
    /**
     * The ID of the element used to decide when to hide
     * the children.
     */
    readonly hideAfterElementID: string;
    /**
     * Optional className to send to the container.
     */
    readonly className?: string;
};

/**
 * State for {@see Hideable}.
 */
type State = {
    hidden: boolean;
};

/**
 * Hide the element when the scroll of the document
 * surpasses a given element.
 *
 * Accepts callbacks for hidden/shown events.
 */
class Hideable extends Component<Props, State> {
    /**
     * The offset of the element received as a prop.
     *
     * If the scroll surpasses this offset, the children
     * become hidden.
     */
    offsetHidden: number | null | undefined;

    /**
     * Initializes a new instance of {@see Hideable}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = { hidden: false };
    }

    /**
     * Invokes the onHidden and onShown event handlers.
     */
    invokeEventHandlers() {
        const eventHandler = this.state.hidden ? this.props.onHidden : this.props.onShown;

        if (eventHandler) {
            eventHandler();
        }
    }

    /**
     * Handler for when the user scrolls.
     */
    onScroll(): void {
        if (!this.offsetHidden) {
            return;
        }
        const hidden = window.scrollY > this.offsetHidden;
        if (hidden !== this.state.hidden) {
            this.setState({ hidden }, this.invokeEventHandlers);
        }
    }

    componentDidMount() {
        const element = document.getElementById(this.props.hideAfterElementID);
        if (!element) {
            return;
        }
        this.offsetHidden = element.offsetTop;
        window.addEventListener('scroll', this.onScroll, { passive: true });
    }

    componentWillUnmount(): void {
        // @ts-expect-error - TS2769 - No overload matches this call.
        window.removeEventListener('scroll', this.onScroll, { passive: true });
    }

    /**
     * Renders the children in container
     * that is hidden when needed.
     */
    render() {
        const className = this.state.hidden ? styles.hidden : null;

        return (
            <span className={[className, this.props.className].join(' ')}>
                {this.props.children}
            </span>
        );
    }
}

export default Hideable;
