import * as React from 'react';
import { useDispatch } from 'react-redux';
import cookie from 'cookie';
import { setDebugMode } from 'strat/user/state';
import CookieNames from 'strat/cookies/cookieNames';
import { Button } from 'strat/components';

const ExitDebugModeButton = () => {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(setDebugMode(false));
        if (process.env.IS_BROWSER) {
            document.cookie = cookie.serialize(CookieNames.STRAT_DEBUG, '', {
                path: '/',
                maxAge: 0,
            });
        }
    };
    return (
        <Button stretchContent secondary onClick={onClick}>
            Exit debug mode
        </Button>
    );
};

export default ExitDebugModeButton;
