import Category from 'strat/branding/category';

// Settings for internal linking
// TODO: move this and the identical setting in the backend (strat/settings/common.py) into
// a common config file
const BEDROOM_PAGES = Object.freeze({
    [Category.APARTMENTS]: [0, 1, 2, 3, 4],
    [Category.HOTEL_APARTMENTS]: [0, 1, 2, 3, 4],
    [Category.TOWNHOUSES]: [1, 2, 3, 4, 5],
    [Category.HOUSES]: [1, 2, 3, 4, 5],
    [Category.VILLAS]: [2, 3, 4, 5, 6],
    [Category.PENTHOUSE]: [2, 3, 4, 5, 6],
});

export default (categorySlug: string): number[] => BEDROOM_PAGES[categorySlug] || [];
