// @ts-expect-error - TS2724 - '"react"' has no exported member named 'Element'. Did you mean 'CElement'?
import type { Element } from 'react';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

import { computeContainedImageSize } from 'strat/util';

import { ThumbnailSizes, ThumbnailSizeValues } from './thumbnailSizes';
import thumbnailURL from './thumbnailURL';
import styles from './styles/thumbnailImage.cssm';

/**
 * Properties for {@see ThumbnailImage}.
 */
export type ThumbnailImageProps = {
    /**
     * Unique ID of the thumbnail to display.
     */
    imageID: number | string;
    /**
     * Image alt tag.
     */
    alt?: string;
    /**
     * Image title tag.
     */
    title?: string;
    /**
     * Size at which to render the thumbnail.
     */
    size: number;
    /**
     * Optional CSS class name for styling the
     * img element.
     */
    className?: string;
    /**
     * Optional CSS class name for styling the
     * picture element.
     */
    pictureClassName?: string;
    /**
     * Event for when the image has finished loading.
     */
    onLoad?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Callback which is used to communicate the size
     * of the rendered image after it is done loading,
     * or after it is taken from the browser cache.
     */
    onSizeAvailable?: (height: number, width: number) => void;
    /**
     * Optionally, a function to compute the URL of the image
     */
    thumbnailURL?: (
        imageID: number | string,
        encoding: string,
        size: {
            width: number;
            height: number;
        },
    ) => string | null | undefined;
    /**
     * Optionally, a loading indicator to display
     * while the image is loading.
     */
    loadingIndicator?: Element<any>;
    /**
     * Accessibility label
     */
    label?: string;
    /**
     * Optionally, a boolean to indicate if there
     * is a need to show a caption for image
     */
    showCaption?: boolean;
    /**
     * Specify if image should be lazy loaded
     */
    lazyLoad?: boolean;
    /**
     * Error handler for the picture tag
     */
    onError?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Image click handler
     */
    onClick?: () => void;
    /**
     * How to render the image caption.
     */
    renderCaption?: (imageTitle: string | undefined | null) => React.ReactNode;
    /**
     * Whether to position the caption directly underneath the image (or _inside_ the image if it doesn't
     * fit underneath) by dynamically computing the size of the contained image.
     */
    dynamicCaptionPosition?: boolean;
    /**
     * The caption offset, in pixels, from the bottom of the rendered image (if dynamically positioned).
     */
    dynamicCaptionOffset?: number;
};

/**
 * State for {@see ThumbnailImage}.
 */
type State = {
    loaded: boolean;
    lazyLoaded: boolean;
};

const defaultRenderCaption = (imageTitle: string | undefined | null) => {
    if (!imageTitle) {
        return null;
    }

    return <span className={styles.caption}>{imageTitle}</span>;
};

/**
 * Displays a thumbnail.
 */
class ThumbnailImage extends Component<ThumbnailImageProps, State> {
    /**
     * Reference to the <img> tag.
     */
    element: HTMLImageElement | null | undefined;
    labelElement: HTMLDivElement | null | undefined;

    static defaultProps = {
        size: ThumbnailSizes.LARGE,
        renderCaption: defaultRenderCaption,
        dynamicCaptionOffset: 25,
    };

    /**
     * Initializes a new instance of {@see ThumbnailImage}.
     */
    constructor(props: ThumbnailImageProps) {
        super(props);
        autoBind(this);

        this.state = {
            loaded: false,
            lazyLoaded: false,
        };

        this.element = null;
        this.labelElement = null;
    }

    /**
     * Hides the loading indicator and shows the image after it was loaded.
     */
    onLoaded(event: React.SyntheticEvent<any>): void {
        this.setState({ loaded: true });

        if (this.props.onLoad) {
            this.props.onLoad(event);
        }

        if (this.props.onSizeAvailable) {
            const target = event.target as any;
            this.props.onSizeAvailable(target.height, target.width);
        }
    }

    /**
     * Sets the reference and immediately sets the loaded
     * state in case the image was already loaded from the cache.
     */
    setRef(element?: HTMLImageElement | null): void {
        this.element = element;

        // on some browsers, the onLoad event doesn't fire
        // for cached images.. however, the .complete property
        // should be set in that case
        if (element && element.complete) {
            this.setState({ loaded: true });

            if (this.props.onSizeAvailable) {
                this.props.onSizeAvailable(element.height, element.width);
            }
        }
    }

    setLabelElement(element?: HTMLDivElement | null): void {
        this.labelElement = element;
    }

    initLazyLoading() {
        if (!process.env.IS_BROWSER || !this.props.lazyLoad) {
            return;
        }

        // this is needed in case we're switching images, if
        // we do not remove this, the lazyLoadInstance won't
        // update the 'src' attribute from the update data-srcset
        // see: https://github.com/verlok/lazyload/issues/190
        if (this.element) {
            this.element.removeAttribute('data-was-processed');
        }

        // allow the lazy loader to load the image when the
        // image appears in the viewport, also needed in case
        // the image source changed
        // @ts-expect-error - TS2339 - Property 'lazyLoadInstance' does not exist on type 'Window & typeof globalThis'.
        if (window.lazyLoadInstance) {
            // @ts-expect-error - TS2339 - Property 'lazyLoadInstance' does not exist on type 'Window & typeof globalThis'.
            window.lazyLoadInstance.update();
        }

        // add this so we can style loaded lazyload images
        this.setState({ lazyLoaded: true });
    }

    /**
     * Initialize lazy loadeding and patch up the loaded state
     * if the image was loaded from the cache.
     */
    componentDidMount() {
        this.initLazyLoading();
    }

    /**
     * Reset internal state and initLazyLoading in case the imageID
     * or thumbnail URL changes.
     */
    componentDidUpdate(prevProps: ThumbnailImageProps) {
        const imageIDChanged = this.props.imageID !== prevProps.imageID;
        const urlChanged = this.props.thumbnailURL !== prevProps.thumbnailURL;

        if ((!prevProps.lazyLoad && this.props.lazyLoad) || imageIDChanged || urlChanged) {
            this.initLazyLoading();
        }
    }

    computeOffset() {
        if (!this.element || !this.element.complete || !this.labelElement) {
            return 0;
        }

        const image = this.element;
        const { height } = computeContainedImageSize(image, 'scale-down');

        const imageCenter = image.height / 2;
        const containedImageBottom = imageCenter + height / 2;
        const labelHeight = this.labelElement.clientHeight;
        const offset =
            this.props.dynamicCaptionOffset || ThumbnailImage.defaultProps.dynamicCaptionOffset;

        const wouldLabelOverflowContainer =
            labelHeight + containedImageBottom + offset > image.height;

        if (wouldLabelOverflowContainer) {
            return `${containedImageBottom - labelHeight - offset}px`;
        }

        return `${containedImageBottom + offset}px`;
    }

    renderCaption() {
        if (!this.props.showCaption) {
            return null;
        }

        if (!this.props.renderCaption) {
            return null;
        }

        if (!this.props.dynamicCaptionPosition) {
            return this.props.renderCaption(this.props.title);
        }

        return (
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: this.computeOffset(),
                }}
                ref={this.setLabelElement}
            >
                {this.props.renderCaption(this.props.title)}
            </div>
        );
    }
    /**
     * Renders the thumbnail.
     */
    render() {
        const getThumbnailURL = this.props.thumbnailURL || thumbnailURL;
        const thumbnailSize = ThumbnailSizeValues[this.props.size];

        const webpURL = getThumbnailURL(this.props.imageID, 'webp', thumbnailSize);
        const jpegURL = getThumbnailURL(this.props.imageID, 'jpeg', thumbnailSize);

        let extraProps = {
            src: jpegURL,
            className: this.props.className,
        };

        if (this.props.lazyLoad) {
            extraProps = {
                // @ts-expect-error - TS2322 - Type '{ 'data-src': string | null | undefined; className: string; }' is not assignable to type '{ src: string | null | undefined; className: string | undefined; }'.
                'data-src': jpegURL,
                className: `${this.props.className || ''} lazy`,
            };

            if (this.state.lazyLoaded) {
                extraProps.className += ' loaded';
            }
        }

        const image = (
            <picture
                key={`image-${this.props.imageID}`}
                className={this.props.pictureClassName || styles.picture}
                onError={this.props.onError}
                onClick={this.props.onClick}
            >
                {this.props.lazyLoad && (
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `
                            <source type="image/webp" srcSet=${webpURL} />
                            <img
                                role="presentation"
                                src=${jpegURL}
                                class=${this.props.className || ''}
                                alt=${this.props.alt || ''}
                                title=${this.props.title || ''}
                                aria-label="Fallback listing photo"
                            />
                        `,
                        }}
                    />
                )}
                {webpURL && !this.props.lazyLoad ? (
                    <source type="image/webp" srcSet={webpURL} />
                ) : (
                    <source type="image/webp" data-srcset={webpURL} />
                )}
                {/* @ts-expect-error - TS2322 - Type '{ src: string | null | undefined; className: string | undefined; role: string; onLoad: (event: SyntheticEvent<any, Event>) => void; ref: (element?: HTMLImageElement | null | undefined) => void; alt: string | undefined; title: string | undefined; "aria-label": string | undefined; }' is not assignable to type 'DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>'. */}
                <img
                    role="presentation"
                    onLoad={this.onLoaded}
                    ref={this.setRef}
                    alt={this.props.alt}
                    title={this.props.title}
                    aria-label={this.props.label}
                    {...extraProps}
                />
                {this.renderCaption()}
            </picture>
        );

        if (!this.props.loadingIndicator) {
            return image;
        }

        const loaderClassName = this.props.className
            ? `${this.props.className} ${styles.loader}`
            : styles.loader;

        return [
            <div
                key="loader"
                className={loaderClassName}
                style={{ display: this.state.loaded ? 'none' : '' }}
            >
                {this.props.loadingIndicator}
            </div>,
            React.cloneElement(image, { style: { display: this.state.loaded ? '' : 'none' } }),
        ];
    }
}

export default ThumbnailImage;
