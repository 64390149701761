import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'serialize-javascript'. '/frontend/strat/node_modules/serialize-javascript/index.js' implicitly has an 'any' type.
import serialize from 'serialize-javascript';

import withGTM from './withGTM';
import type { GTMProps } from './withGTM';

/**
 * Properties for {@see GTMDataLayerVariables}.
 */
type Props = GTMProps & {
    variables: any;
};

/**
 * Sets the GTM data layer variables according to the specified props.
 */
class GTMDataLayerVariables extends Component<Props> {
    /**
     * Set variables after mounting.
     *
     * This is in case the navigation happens in the browser
     * and the rendered <script> won't be executed.
     */
    componentDidMount() {
        this.props.set(this.props.variables);
    }

    /**
     * Reset all variables when unmounting.
     */
    componentWillUnmount() {
        this.props.reset();
    }

    /**
     * Update the data layer whenever the props change.
     */
    componentDidUpdate() {
        this.props.set(this.props.variables);
    }

    /**
     * Do not update the component if the variables didn't change.
     */
    shouldComponentUpdate(nextProps: Props) {
        return !isEqual(nextProps.variables, this.props.variables);
    }

    /**
     * Render the variables into a <script> tag that push
     * them onto the data layer.
     *
     * We have to render the <script> tag to make sure that
     * the data layer variables are set before GTM loads, in
     * other words, they'll be rendered on the server.
     *
     * Subsequent changes to the variables will be handled
     * in {@see componentDidUpdate}.
     */
    render() {
        const { dataLayerName } = this.props;
        let js = `window['${dataLayerName}'] = window['${dataLayerName}'] || [];`;
        js += `window['${dataLayerName}'].push(${serialize(this.props.variables, {
            isJSON: true,
        })});`;

        return (
            <script
                dangerouslySetInnerHTML={{
                    __html: js,
                }}
            />
        );
    }
}

export default withGTM(GTMDataLayerVariables);
