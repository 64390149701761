import omit from 'lodash/omit';

import {
    PackageType,
    PackageTypeValues,
    Product,
    ProductPackage,
    ProductPackageOffer,
    ProductPurchase,
    ProductType,
} from 'horizontal/types';

export const LIMIT_PACKAGE_TYPES: Array<PackageTypeValues> = Object.freeze([
    PackageType.LIMIT,
    PackageType.LIMIT_AND_FEATURED,
    PackageType.LIMIT_AND_AUTO_REFRESH,
]) as Array<PackageTypeValues>;

export const VAS_PACKAGE_TYPES: Array<PackageTypeValues> = Object.freeze([
    PackageType.ELITE,
    PackageType.FEATURED,
    PackageType.AUTO_REFRESH,
    PackageType.REFRESH,
    PackageType.AUTO_REFRESH_CATEGORY,
    PackageType.FREE_AD_WITH_COMMISSION,
]) as Array<PackageTypeValues>;

export const isElitePackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.ELITE);
};

export const isFeaturedPackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.FEATURED);
};

export const isAutoRefreshPackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.AUTO_REFRESH_AD);
};

export const isRefreshPackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.REFRESH);
};

export const isAutoRefreshCategoryPackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.AUTO_REFRESH_CATEGORY);
};

export const isFreeAdWithCommissionPackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.FREE_AD_WITH_COMMISION);
};

export const isLimitPackageOnly = (productItems: Array<Product>): boolean => {
    return productItems.every((product) => product.type === ProductType.LIMIT_BUMP);
};

export const isLimitAndFeaturedPackage = (productItems: Array<Product>): boolean => {
    return [ProductType.LIMIT_BUMP, ProductType.FEATURED].every((requiredProduct) =>
        productItems.some((product) => requiredProduct === product.type),
    );
};

export const isLimitAndElitePackage = (productItems: Array<Product>): boolean => {
    return [ProductType.LIMIT_BUMP, ProductType.ELITE].every((requiredProduct) =>
        productItems.some((product) => requiredProduct === product.type),
    );
};

export const isLimitAndAutoRefreshPackage = (productItems: Array<Product>): boolean => {
    return [ProductType.LIMIT_BUMP, ProductType.AUTO_REFRESH_AD].every((requiredProduct) =>
        productItems.some((product) => requiredProduct === product.type),
    );
};

export const getProductPackageType = (
    productPackage: ProductPackage | undefined,
): PackageTypeValues | null => {
    if (!productPackage) {
        return null;
    }

    const productItems = productPackage.productItems;

    if (productItems.length === 0) {
        return null;
    }

    if (isElitePackageOnly(productItems)) {
        return PackageType.ELITE;
    }

    if (isFeaturedPackageOnly(productItems)) {
        return PackageType.FEATURED;
    }

    if (isAutoRefreshPackageOnly(productItems)) {
        return PackageType.AUTO_REFRESH;
    }

    if (isRefreshPackageOnly(productItems)) {
        return PackageType.REFRESH;
    }

    if (isAutoRefreshCategoryPackageOnly(productItems)) {
        return PackageType.AUTO_REFRESH_CATEGORY;
    }

    if (isFreeAdWithCommissionPackageOnly(productItems)) {
        return PackageType.FREE_AD_WITH_COMMISSION;
    }

    if (isLimitPackageOnly(productItems)) {
        return PackageType.LIMIT;
    }

    if (isLimitAndFeaturedPackage(productItems)) {
        return PackageType.LIMIT_AND_FEATURED;
    }

    if (isLimitAndElitePackage(productItems)) {
        return PackageType.LIMIT_AND_ELITE;
    }

    if (isLimitAndAutoRefreshPackage(productItems)) {
        return PackageType.LIMIT_AND_AUTO_REFRESH;
    }

    return null;
};

export const getProductsTypes = (
    availableAdPackages?:
        | Record<Values<typeof ProductType>, ProductPurchase[]>
        | Array<ProductPackageOffer>
        | Array<ProductPurchase>,
) => {
    const products: Values<typeof ProductType>[] = [];
    const productsTypes = Object.values(ProductType);
    if (!availableAdPackages) {
        return products;
    }

    for (let i = 0; i < productsTypes.length; i++) {
        if (Array.isArray(availableAdPackages)) {
            for (let j = 0; j < availableAdPackages.length; j++) {
                let productItems;
                const productPackage = availableAdPackages[j];
                if ('productPackage' in productPackage) {
                    productItems = productPackage.productPackage.productItems;
                } else if ('productPackageOffer' in productPackage) {
                    productItems = productPackage.productPackageOffer.productPackage.productItems;
                } else {
                    return;
                }
                if (productItems?.length) {
                    for (let k = 0; k < productItems.length; k++) {
                        if (productItems[k].type == productsTypes[i]) {
                            products.push(productsTypes[i]);
                        }
                    }
                }
            }
        }
        if (
            !Array.isArray(availableAdPackages) &&
            availableAdPackages[productsTypes[i]]?.length > 0
        ) {
            products.push(productsTypes[i]);
        }
    }
    if ([...new Set(products)].some((product) => ProductType.LIMIT_BUMP === product)) {
        return [ProductType.LIMIT_BUMP];
    }
    const vasProducts = { ...omit(ProductType, ['LIMIT_BUMP']) };
    return [...Object.values(vasProducts)];
};

export const hasFeaturedExample = (
    type: PackageTypeValues | Values<typeof ProductType>,
): boolean => {
    return [PackageType.FEATURED, ProductType.FEATURED].includes(type);
};

export const hasRefreshExample = (
    type: PackageTypeValues | Values<typeof ProductType>,
): boolean => {
    return [
        PackageType.REFRESH,
        PackageType.AUTO_REFRESH,
        ProductType.REFRESH,
        ProductType.AUTO_REFRESH_AD,
    ].includes(type);
};

export const hasExample = (type: PackageTypeValues | Values<typeof ProductType>): boolean => {
    return hasFeaturedExample(type) || hasRefreshExample(type);
};
