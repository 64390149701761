import { SearchResponseHit } from '@sector-labs/fe-search-redux/backend';

import { AgentForSearchCard } from '@app/leaderboard/types';

import transformLocalizedFields from './transformLocalizedFields';

const agentTransformer = (
    agent: SearchResponseHit,
    language: string,
    mergedIndex: boolean,
): AgentForSearchCard => {
    // If we are not on a merged index, or there is no data, there is nothing to transform
    if (!mergedIndex || !agent) {
        return agent as AgentForSearchCard;
    }

    const transformedAgent = transformLocalizedFields(agent, language, mergedIndex);

    if (transformedAgent.agency) {
        transformedAgent.agency = transformLocalizedFields(
            transformedAgent.agency as object,
            language,
            mergedIndex,
        );
    }

    return transformedAgent as AgentForSearchCard;
};

export default agentTransformer;
