import * as React from 'react';

import styles from './styles/flexSizeItemWrapper.cssm';

type Props = {
    readonly wide?: boolean;
    readonly children?: React.ReactNode;
};

const FlexSizeItemWrapper = ({ wide, children }: Props) => (
    <div className={wide ? styles.wide : styles.normal}>{children}</div>
);

export default FlexSizeItemWrapper;
