import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsIphone, selectIsMobile } from 'strat/platform/selectors';
import ShareIconDesktop from '@app/assets/icons/iconShareCTA.svg';
import ShareIconAndroid from '@app/assets/icons/iconShareAndroid.svg';
import ShareIconIOS from '@app/assets/icons/iconShareIOS.svg';

const ShareIconAllDevices = (props: React.SVGProps<SVGElement>) => {
    const isMobile = useSelector(selectIsMobile);
    const isIphone = useSelector(selectIsIphone);
    if (isIphone) {
        return <ShareIconIOS {...props} />;
    }
    return isMobile ? <ShareIconAndroid {...props} /> : <ShareIconDesktop {...props} />;
};

export default ShareIconAllDevices;
