import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import type { ChatMessageFileUploadData, ChatMessageLocationContent } from 'horizontal/chat/types';

import { ShareLocationButton } from './shareLocationDialog';
import styles from './styles/dialogContent.cssm';
import UploadAttachment from './uploadAttachment';
import UploadImageButton from './uploadImageButton';

type Props = {
    readonly sendImages: (files: Array<ChatMessageFileUploadData>) => void;
    readonly sendAttachments: (files: Array<ChatMessageFileUploadData>) => void;
    readonly sendLocation: (location: ChatMessageLocationContent) => void;
    readonly onSubmit: () => void;
};

const DialogContent = ({ sendImages, sendAttachments, sendLocation, onSubmit }: Props) => {
    const i18n = useI18n();
    const [errorMessage, setErrorMessage] = React.useState<any>(null);

    const sendLocationAndCloseDialog = React.useCallback(
        (location: ChatMessageLocationContent) => {
            sendLocation(location);
            onSubmit();
        },
        [sendLocation, onSubmit],
    );

    const options = [
        ...(CONFIG.build.ENABLE_CHAT_ATTACHMENTS
            ? [
                  <UploadAttachment
                      sendAttachments={sendAttachments}
                      onSubmit={onSubmit}
                      setErrorMessage={setErrorMessage}
                  />,
              ]
            : []),
        <UploadImageButton
            sendImages={sendImages}
            onSubmit={onSubmit}
            setErrorMessage={setErrorMessage}
        />,
        <ShareLocationButton sendLocation={sendLocationAndCloseDialog} />,
    ];

    return (
        <Flex column>
            <Text.XLarge bold>{t(i18n)`Choose an option`}</Text.XLarge>
            <Flex justifySpaceAround wrap>
                {options.map((option, index) => (
                    <label className={styles.optionButton} key={index}>
                        {option}
                    </label>
                ))}
            </Flex>
            {errorMessage && <Text.Base className={styles.centeredText}>{errorMessage}</Text.Base>}
        </Flex>
    );
};

export default DialogContent;
