import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import {
    SearchBackend,
    SearchRequestSortType,
    SearchRequestOrder,
} from '@sector-labs/fe-search-redux/backend';
import { SearchJobOptions } from '@sector-labs/fe-search-redux/searchJobOptions';
import { determineLocationsIndexName } from 'strat/search/indexNames';

import type { Location, LiteHierarchicalLocation } from 'horizontal/types';

const fetchLocationsByParent = (
    parent: Location | null | undefined | LiteHierarchicalLocation | null | undefined,
    language: string,
    backend: SearchBackend,
): Promise<Array<Location>> => {
    const filters = new FilterCollection();
    const sortRequest: SearchJobOptions['sort'] = {
        type: SearchRequestSortType.ATTRIBUTES,
        key: 'alphabetical',
        attributes: [{ name: 'name', order: SearchRequestOrder.ASC }],
    };

    if (parent) {
        filters.refineMultiple([
            new ExactFilter({
                attribute: 'hierarchy.externalID',
                value: parent.externalID,
            }),
            new ExactFilter({
                attribute: 'level',
                value: parent.level + 1,
            }),
        ]);
    } else {
        filters.refine(
            new ExactFilter({
                attribute: 'level',
                value: 1,
            }),
        );
    }

    const locations = determineLocationsIndexName({ language });
    const searchJob = new SearchJob(locations, filters, {
        exhaustiveNbHits: false,
        hitsPerPage: 10000,
        sort: sortRequest,
    });

    const service = new SearchService({ backend });
    return service.fetchJob(searchJob).then((response) => response.hits as Array<Location>);
};

export default fetchLocationsByParent;
