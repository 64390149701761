import { createSelector } from 'reselect';

import { AgentData } from 'strat/agency/agent/types';
import { UserProfileData } from 'strat/user/types';
import { getItem } from 'strat/misc/localStorage';

import {
    selectAgentUserProfile,
    selectLoggedInAgentData,
    selectLoggedInAgentLoading,
} from './selectors';

export const LOGGED_IN_AGENT_KEY_LOCAL_STORAGE = 'loggedInAgentData';

export const doesAgentDataMatchProfile = (
    agentData: AgentData | null,
    profile: UserProfileData | null,
) => agentData != null && agentData.external_id === profile?.id;

const getLocalStorageAgentData = (): AgentData | null => {
    if (!window.localStorage) {
        return null;
    }

    return getItem(LOGGED_IN_AGENT_KEY_LOCAL_STORAGE);
};

const selectShouldFetchAgentData = createSelector(
    selectAgentUserProfile,
    selectLoggedInAgentData,
    selectLoggedInAgentLoading,
    (agentUserProfile, loggedInAgentData, loggedInAgentLoading) => {
        const isAgentLoggedIn = agentUserProfile != null;

        const agentDataSession = getLocalStorageAgentData();
        const shouldUseAgentData = !loggedInAgentData && isAgentLoggedIn;
        const doesSessionMatchProfile = doesAgentDataMatchProfile(
            agentDataSession,
            agentUserProfile,
        );

        const shouldFetch = shouldUseAgentData && !loggedInAgentLoading && !doesSessionMatchProfile;
        const shouldUseSessionStorage = shouldUseAgentData && doesSessionMatchProfile;

        return [shouldUseSessionStorage ? agentDataSession : null, shouldFetch] as [
            AgentData | null,
            boolean | null,
        ];
    },
);

export { selectShouldFetchAgentData };
