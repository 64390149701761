import * as React from 'react';
import SearchHitsLayout from '@app/branding/searchHitsLayout';

import LoadingAdCard from './loadingAdCard';
import LoadingAdCardUpdatedDesign from './loadingAdCardUpdatedDesign';

const makeLoadingAdCards = (
    count: number,
    viewType: Values<typeof SearchHitsLayout>,
    renderContainer: (children: React.ReactNode, key: number) => React.ReactElement,
    showHeroCategoryAdCardDesign?: boolean,
): Array<React.ReactElement> =>
    Array.from(Array(count).keys(), (index: number) =>
        renderContainer(
            showHeroCategoryAdCardDesign ? (
                <LoadingAdCardUpdatedDesign />
            ) : (
                <LoadingAdCard viewType={viewType} />
            ),
            index,
        ),
    );

export default makeLoadingAdCards;
