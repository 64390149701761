import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

import styles from './styles/rejectedLabel.cssm';

const RejectedLabel = () => {
    return (
        <Text.Base uppercase className={styles.rejectedLabel}>
            <Trans>Rejected</Trans>
        </Text.Base>
    );
};

export default RejectedLabel;
