import { createSelector } from 'reselect';

import { URLSpecificSEO } from 'horizontal/types';

const selectURLSpecificSEO = (state: any): URLSpecificSEO =>
    state.urlSpecificSEOs.data?.length ? state.urlSpecificSEOs.data[0] : null;

const selectCustomH1 = createSelector(selectURLSpecificSEO, (urlSpecificSEO) => urlSpecificSEO?.h1);

const selectCustomMetaTitle = createSelector(
    selectURLSpecificSEO,
    (urlSpecificSEO) => urlSpecificSEO?.metaTitle,
);

const selectCustomMetaDescription = createSelector(
    selectURLSpecificSEO,
    (urlSpecificSEO) => urlSpecificSEO?.metaDescription,
);

const selectCustomContent = createSelector(
    selectURLSpecificSEO,
    (urlSpecificSEO) => urlSpecificSEO?.content,
);

const selectCanonicalOverride = createSelector(
    selectURLSpecificSEO,
    (urlSpecificSEO) => urlSpecificSEO?.canonicalOverride,
);

const selectRobotsMetaContent = createSelector(
    selectURLSpecificSEO,
    (urlSpecificSEO) => urlSpecificSEO?.robotsMetaContent,
);

export {
    selectCustomH1,
    selectCustomMetaTitle,
    selectCustomMetaDescription,
    selectCustomContent,
    selectCanonicalOverride,
    selectRobotsMetaContent,
};
