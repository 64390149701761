import { t } from '@lingui/macro';
import * as React from 'react';

import { useI18n } from 'strat/i18n/language';

import styles from './styles/agencyInfoPermitNumber.cssm';

/**
 * Properties for {@see AgencyPermitList}.
 */
export type Props = {
    permitNumber: null | string;
};
const AgencyInfoPermitNumber = ({ permitNumber }: Props) => {
    const i18n = useI18n();

    return permitNumber ? (
        <div className={styles.permitList}>
            <span key="permitNumber" className={styles.permit}>
                {t(i18n)`Permit`}# {permitNumber}
            </span>
        </div>
    ) : null;
};

export default AgencyInfoPermitNumber;
