const ThumbnailSizes = Object.freeze({
    MICRO: 0,
    TINY: 1,
    SMALL: 2,
    NORMAL: 3,
    MEDIUM: 4,
    LARGE: 5,
    X_LARGE: 6,
});

const ThumbnailSizeValues = Object.freeze({
    [ThumbnailSizes.MICRO]: { width: 62, height: 57 },
    [ThumbnailSizes.TINY]: { width: 120, height: 90 },
    [ThumbnailSizes.SMALL]: { width: 240, height: 180 },
    [ThumbnailSizes.NORMAL]: { width: 400, height: 300 },
    [ThumbnailSizes.MEDIUM]: { width: 600, height: 450 },
    [ThumbnailSizes.LARGE]: { width: 800, height: 600 },
    [ThumbnailSizes.X_LARGE]: { width: 1066, height: 800 },
});

export { ThumbnailSizes, ThumbnailSizeValues };
