import * as React from 'react';

import { useNavigateToJazzCashMWalletPayment } from 'horizontal/payment/jazzCash';
import StratAPI from 'horizontal/api/strat';

import { PaymentSource, PaymentOrderPlatform } from '../types';
import type { PaymentOrder, ProductPackageOffer } from '../types';

import useTrackPaymentInitiated from './useTrackPaymentInitiated';

const useJazzCashMobileWalletPayment = (order?: PaymentOrder) => {
    const navigateToJazzCashMWallet = useNavigateToJazzCashMWalletPayment();
    const trackPaymentInitiated = useTrackPaymentInitiated(order);

    return React.useCallback(
        (packages?: Array<ProductPackageOffer>, commissionAmount?: string, adID?: number) => {
            if (!order || !order.externalID) {
                if (packages && commissionAmount && adID) {
                    // @ts-expect-error - TS2339 - Property 'packageOfferID' does not exist on type 'ProductPackageOffer'.
                    const packageOfferID = packages[0].packageOfferID;
                    new StratAPI()
                        .createPayWhatYouWantOrder({
                            product_package_offers: [{ external_id: packageOfferID, ad_id: adID }],
                            platform: PaymentOrderPlatform.WEB,
                            total: Number(commissionAmount),
                        })
                        .then(({ data }) => data.orderExternalID)
                        .then((orderExternalID: string) =>
                            navigateToJazzCashMWallet({ orderExternalID: orderExternalID }),
                        );
                }
                return;
            } else {
                trackPaymentInitiated(PaymentSource.JAZZ_CASH_MOBILE_WALLET);
                navigateToJazzCashMWallet({ orderExternalID: order.externalID });
            }
        },
        [order, navigateToJazzCashMWallet, trackPaymentInitiated],
    );
};

export default useJazzCashMobileWalletPayment;
