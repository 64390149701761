import SearchView from 'strat/search/searchView';

import ViewSections from './viewSections';

const SECTIONS = Object.freeze({
    [SearchView.MAP_BASED_SEARCH]: ViewSections.MAP_BASED_SEARCH,
    [SearchView.COMMUTE_SEARCH]: ViewSections.COMMUTE_SEARCH,
});

const getViewSection = (searchView: Values<typeof SearchView>) => {
    if (Object.keys(SECTIONS).includes(searchView)) {
        return SECTIONS[searchView];
    }

    return ViewSections.SEARCH_RESULTS;
};

export default (searchView: Values<typeof SearchView>) => ({
    view_section: getViewSection(searchView),
    view_type: 2,
});
