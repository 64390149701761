import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { FormikTouched, FormikErrors, FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';

import { filterDisabledLocations } from 'horizontal/search/location';
import { selectCategories } from 'horizontal/categories';
import type { Location, LocationChoices } from 'horizontal/types';
import AdCreationContext from 'horizontal/adCreation/context';
import { DropdownVariants } from 'horizontal/components';

import LocationFieldLevel from './locationFieldLevel';
import CommonPostingFields from './commonPostingFields';

type Props = {
    readonly locationHierarchy: Array<Location>;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly choices: LocationChoices;
    readonly setSelectedLocation?: (arg1: Location, arg2: number) => void;
    readonly attribute?: string;
    readonly readOnly?: boolean;
    readonly variant?: DropdownVariants;
    readonly formatPlaceholder?: (title?: string) => string;
    readonly lightPlaceholder?: boolean;
    readonly boldLabel?: boolean;
    readonly isMandatory?: boolean;
};

const LocationField = ({
    errors,
    locationHierarchy,
    touched,
    choices,
    setSelectedLocation,
    attribute = CommonPostingFields.location_id.attribute,
    readOnly,
    formatPlaceholder,
    variant,
    lightPlaceholder,
    boldLabel,
    isMandatory,
}: Props) => {
    const i18n = useI18n();

    const hierarchyLabels = React.useMemo(
        () => [t(i18n)`LocationState`, t(i18n)`City`, t(i18n)`Neighbourhood`],
        [i18n],
    );
    const [activeCategory] = React.useContext(AdCreationContext);
    const locationDepthLimits = activeCategory?.locationDepthLimits;
    const categories = useSelector(selectCategories);

    const onLocationClick = (value: Location, index: number) => {
        if (setSelectedLocation) {
            setSelectedLocation(value, index);
        }
    };

    const restrictedChoices = React.useMemo(
        () =>
            choices
                .filter((entry) => entry[0].level <= (locationDepthLimits?.max || Infinity))
                .reduce<Array<any>>((accumulator, locations) => {
                    if (categories.length) {
                        const filteredLocations = filterDisabledLocations(
                            locations,
                            categories,
                            activeCategory,
                        );
                        if (filteredLocations.length) {
                            accumulator.push(filteredLocations);
                        }
                        return accumulator;
                    }
                    accumulator.push(locations);
                    return accumulator;
                }, []),
        [choices, locationDepthLimits, activeCategory, categories],
    );

    const initalEmptyState =
        locationHierarchy.length === 1 &&
        restrictedChoices.length === 1 &&
        locationHierarchy[0].externalID === brandingSettings.topLevelLocation.externalID;

    return (
        <>
            {restrictedChoices.map((entry, index) => (
                <LocationFieldLevel
                    key={index}
                    value={initalEmptyState ? undefined : locationHierarchy[index]}
                    title={hierarchyLabels[entry[0].level - 1]}
                    choices={restrictedChoices[index]}
                    onChange={(value) => onLocationClick(value, index)}
                    level={entry[0].level - 1}
                    hierarchy={locationHierarchy}
                    readOnly={readOnly}
                    variant={variant}
                    formatPlaceholder={formatPlaceholder}
                    lightPlaceholder={lightPlaceholder}
                    boldLabel={boldLabel}
                    errorMessage={
                        touched[attribute] && index === restrictedChoices.length - 1
                            ? errors[attribute]
                            : undefined
                    }
                    isMandatory={
                        isMandatory &&
                        locationDepthLimits &&
                        entry[0].level - 1 <= locationDepthLimits.min
                    }
                />
            ))}
        </>
    );
};

export default LocationField;
