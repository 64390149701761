import * as React from 'react';

import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import { useFlatCategoryFields } from 'horizontal/categoryFields';
import { useDefaultHeadingTitle } from 'horizontal/search/seo';

import useSearchFiltersFormattedExtraFields from './useSearchFiltersFormattedExtraFields';

const useExtraFieldsAndSuggestedTitle = () => {
    const params = useCurrentSearchRouteParams();
    const categoryFields = useFlatCategoryFields(params.category?.id, params.extraFields);
    const formattedExtraFields = useSearchFiltersFormattedExtraFields(
        params.extraFields,
        categoryFields,
    );
    const headingTitle = useDefaultHeadingTitle(params);
    const extraFieldTitles = React.useMemo(
        () => formattedExtraFields.map((extraField) => extraField.formattedValue).join(' '),
        [formattedExtraFields],
    );
    const suggestedTitle = `${headingTitle} ${extraFieldTitles}`;

    return {
        suggestedTitle,
        formattedExtraFields,
    };
};

export default useExtraFieldsAndSuggestedTitle;
