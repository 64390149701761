import { useSelector } from 'react-redux';

import { useI18n } from '../language';

import { selectCurrentCurrency } from './selectors';
import Currency from './currency';

/**
 * Displays the current currency as the ISO code.
 */
const LocalCurrency = () => {
    const i18n = useI18n();
    const currency = useSelector(selectCurrentCurrency);

    return Currency.acronym(i18n, currency);
};

export default LocalCurrency;
