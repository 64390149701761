import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';
import { Dismissible } from 'strat/modal';

import { selectDialogStack } from 'horizontal/dialogs/selectors';
import IconMore from 'horizontal/assets/icons/moreIcon.svg';

import styles from './styles/moreButton.cssm';

export type Option = {
    readonly id: number;
    readonly label: string;
    readonly onClick: () => unknown;
};

type Props = {
    readonly options: Array<Option>;
};

const MoreButton = ({ options }: Props) => {
    const { stacked, stackGroup } = useSelector(selectDialogStack);
    const [areOptionsVisible, setAreOptionsVisible] = React.useState(false);

    React.useEffect(() => {
        setAreOptionsVisible(false);
    }, [options]);

    const toggleOptions = (event: React.MouseEvent<HTMLDivElement>) => {
        setAreOptionsVisible((visible) => !visible);
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div className={styles.container}>
            <Flex className={styles.button} onClick={toggleOptions}>
                <IconMore className={styles.icon} />
            </Flex>

            {areOptionsVisible && (
                <Dismissible
                    stacked={stacked}
                    stackGroup={stackGroup}
                    enabled={areOptionsVisible}
                    onDismissed={() => setAreOptionsVisible(false)}
                >
                    <Flex column className={styles.options}>
                        {options.map((option) => (
                            <Flex
                                alignCenter
                                key={option.id}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                    option.onClick();
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                className={styles.option}
                                role="button"
                            >
                                {option.label}
                            </Flex>
                        ))}
                    </Flex>
                </Dismissible>
            )}
        </div>
    );
};

export default React.memo<Props>(MoreButton);
