import React from 'react';

import { PaymentSelection } from 'horizontal/payment';
import type { PaymentOrder } from 'horizontal/payment';
import { GTMPaymentSelectionDataLayer } from 'horizontal/gtm/components';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly order: PaymentOrder;
};

const PaymentSelectionPageDesktop = {
    head: ({ order }: Props) => (
        <>
            <PrivateNonIndexableHead />
            <GTMPaymentSelectionDataLayer order={order} />
        </>
    ),
    body: ({ order }: Props) => <PaymentSelection order={order} />,
} as const;

export default PaymentSelectionPageDesktop;
