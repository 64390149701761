import { t } from '@lingui/macro';
import useI18n from 'strat/i18n/language/useI18n';

type MonthTranslator = (monthNumber: number) => string;

const useMonthTranslator = (): MonthTranslator => {
    const i18n = useI18n();

    const translateMonth = (monthNumber: number) => {
        const months = [
            t(i18n)`January`,
            t(i18n)`February`,
            t(i18n)`March`,
            t(i18n)`April`,
            t(i18n)`May`,
            t(i18n)`June`,
            t(i18n)`July`,
            t(i18n)`August`,
            t(i18n)`September`,
            t(i18n)`October`,
            t(i18n)`November`,
            t(i18n)`December`,
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
        } else {
            return t(i18n)`Invalid month number`;
        }
    };

    return translateMonth;
};

export default useMonthTranslator;
