import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex, LoadingSpinnerOverlay } from 'strat/components';

import { selectExtraCharges, selectMerchantID } from 'horizontal/payment/areeba';
import { InputData } from 'horizontal/payment/hooks/useCreateConfirmForm';

import {
    useCreateConfirmForm,
    useAreebaFormConfiguration,
    useNavigateToPaymentSelection,
    useNavigateAfterPayment,
} from '../hooks';
import Header from '../header';
import type { PaymentOrder } from '../types';
import { PaymentStatus } from '../types';

import AreebaForm, { FormFields } from './paymentAreebaForm';
import styles from './styles/paymentAreeba.cssm';
import useCalculatePrice from './useCalculatePrice';
import { selectAreebaCurrency } from './selectors';

type Props = {
    readonly sessionID: string;
    readonly order: PaymentOrder;
};

const initialValues = {
    [FormFields.NAME_ON_CARD]: '',
    [FormFields.CARD_NUMBER]: '',
    [FormFields.EXPIRATION_MONTH]: '',
    [FormFields.EXPIRATION_YEAR]: '',
    [FormFields.CVV]: '',
} as const;

const PaymentAreeba = ({ order, sessionID }: Props) => {
    const navigateAfterPayment = useNavigateAfterPayment();
    const navigateToPaymentSelection = useNavigateToPaymentSelection();
    const [formErrors, setFormErrors] = React.useState<string>('');
    const merchantID = useSelector(selectMerchantID);
    const extraCharges = useSelector(selectExtraCharges);
    const { renderConfirmForm, genericConfirmData, createGenericConfirmForm } =
        useCreateConfirmForm({
            setFormErrors,
        });

    const { loading, setLoading, formDisabled, setFormDisabled, paymentContainer } =
        useAreebaFormConfiguration({
            merchantID,
            order,
            sessionID,
            navigateAfterPayment,
            createGenericConfirmForm,
            setFormErrors,
        });

    const confirmFormRef = React.useRef<HTMLFormElement | null | undefined>(null);
    const currency = useSelector(selectAreebaCurrency);

    const alreadyPaidAmount =
        order.payments
            ?.filter((payment) => payment.status == PaymentStatus.CAPTURED)
            ?.reduce((sum, payment) => sum + payment.total, 0) || 0;

    const orderPrice = useCalculatePrice(
        order.total - alreadyPaidAmount,
        Number(extraCharges),
        currency,
    );
    const onSubmit = () => {
        setFormDisabled(true);
        setLoading(true);
        setFormErrors('');
        // @ts-expect-error - TS2339 - Property 'PaymentSession' does not exist on type 'Window & typeof globalThis'.
        window.PaymentSession.updateSessionFromForm('card');
    };

    React.useEffect(() => {
        if (renderConfirmForm && confirmFormRef?.current) {
            confirmFormRef.current.submit();
        }
    }, [renderConfirmForm]);

    return (
        <Flex stretchHeight column>
            <LoadingSpinnerOverlay visible={loading} />
            <Header
                popHistory={!order}
                onBack={
                    order
                        ? () => navigateToPaymentSelection({ orderExternalID: order.externalID })
                        : undefined
                }
            />
            {/* @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
            <div id="payment-areeba" className={styles.paymentContainer} ref={paymentContainer}>
                <div className={styles.card}>
                    <AreebaForm
                        isDisabled={formDisabled}
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        totalPrice={orderPrice}
                        extraCharges={extraCharges}
                    />
                    {!!formErrors && <div className={styles.formErrors}>{formErrors}</div>}
                </div>
            </div>
            {renderConfirmForm && (
                <form
                    // @ts-expect-error - TS2322 - Type 'MutableRefObject<HTMLFormElement | null | undefined>' is not assignable to type 'LegacyRef<HTMLFormElement> | undefined'.
                    ref={confirmFormRef}
                    id="confirmForm"
                    className="hidden"
                    action={genericConfirmData.formAction}
                    method="POST"
                    acceptCharset="UTF-8"
                >
                    {genericConfirmData?.inputsData?.map((input: InputData) => {
                        return (
                            <input
                                key={input.name}
                                type="hidden"
                                name={input.name}
                                value={input.value}
                            />
                        );
                    })}
                </form>
            )}
        </Flex>
    );
};

export default PaymentAreeba;
