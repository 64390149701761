import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import Currency from 'strat/i18n/money/currency';
import settings from '@app/branding/settings';
import { convertPriceToAlphabeticalForm } from '@app/fields/convertPriceToAlphabeticalForm';
import { Input } from 'strat/components';
import { useSelector } from 'react-redux';

import type { FlatCategoryField } from 'horizontal/types';
import { CategoryPriceField } from '@app/fields/commonPostingFields';

type Props = {
    readonly field: FlatCategoryField;
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly setFieldValue: (arg1: string, arg2: string) => void;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly label?: string;
    readonly currency?: string;
};

const PriceField = ({
    field,
    values,
    touched,
    errors,
    setFieldValue,
    onBlur,
    label,
    currency,
}: Props) => {
    const i18n = useI18n();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    const baseCurrency = settings.baseCurrencyName;
    const price = values[field.attribute];

    const formatter = numberFormatterFactory({ maximumFractionDigits: 20 });
    let priceFormatted = price || price === 0 ? formatter.format(price) : '';
    const priceInAlphabeticalForm = price ? convertPriceToAlphabeticalForm(price, i18n) : '';
    const description =
        !!priceInAlphabeticalForm && price !== priceInAlphabeticalForm
            ? priceInAlphabeticalForm
            : '';

    // the formatter will cleanup the dot if there is nothing after it
    if (price?.toString().endsWith('.')) {
        priceFormatted += '.';
    }

    const onChange = (value: string) => {
        const cleanedValue = value.replace(/[^0-9.]+/g, '');
        setFieldValue(field.attribute, cleanedValue);
    };

    const title = label || field.name || field.attribute;

    return (
        <Input
            title={
                field.isMandatory || field.attribute === CategoryPriceField.attribute
                    ? title.concat('*')
                    : title
            }
            placeholder={t(i18n)`Enter ${title}`}
            value={priceFormatted}
            name={field.attribute}
            type="text"
            errorMessage={touched[field.attribute] && errors[field.attribute]}
            accepted={touched[field.attribute] && !errors[field.attribute]}
            onChange={onChange}
            onBlur={onBlur}
            unit={Currency.symbol(i18n, currency || baseCurrency)}
            description={description}
            leftSideLabel
            lightPlaceholder
            boldLabel
        />
    );
};

export default PriceField;
