import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

import { PackageType, PackageTypeValues } from 'horizontal/types';

import { PackagesFlowTypeValues, OfferPackagesSectionsConfiguration, PackagesFlow } from './types';
import { LIMIT_PACKAGE_TYPES, VAS_PACKAGE_TYPES } from './utils';

export const excludePackageTypesFromConfigs = (
    configs: OfferPackagesSectionsConfiguration,
    packageTypes: Array<PackageTypeValues>,
) => {
    packageTypes.forEach((type) => {
        configs[type].show = false;
    });
    return configs;
};

const usePackagesSectionsConfiguration = (
    flow: PackagesFlowTypeValues,
): OfferPackagesSectionsConfiguration => {
    const i18n = useI18n();

    const configs = {
        [PackageType.ELITE]: {
            order: 1,
            title: t(i18n)`Elite Ad`,
            description: t(i18n)`Place your ad above all other ads in the chosen category`,
            show: true,
        },
        [PackageType.FEATURED]: {
            order: 2,
            title: t(i18n)`Feature your ad`,
            description: t(
                i18n,
            )`Reach more buyers and get noticed with “Featured” tag in a top position`,
            show: true,
            valuePropositions: [
                t(i18n)`Get noticed with 'FEATURED' tag in a top position`,
                t(i18n)`Ad will be highlighted to top positions`,
            ],
        },
        [PackageType.AUTO_REFRESH]: {
            order: 3,
            title: t(i18n)`Auto Boost to top`,
            description: t(
                i18n,
            )`Repost your ad everyday within the selected duration to increase visibility`,
            show: true,
            valuePropositions: [t(i18n)`Back on top like a new ad`],
        },
        [PackageType.REFRESH]: {
            order: 4,
            title: t(i18n)`Boost your Ad to top`,
            description: t(i18n)`Sell faster by boosting you ad on top like a new ad`,
            show: true,
            valuePropositions: [t(i18n)`Back on top like a new ad`],
        },
        [PackageType.AUTO_REFRESH_CATEGORY]: {
            order: 5,
            title: '',
            description: '',
            show: true,
        },
        [PackageType.FREE_AD_WITH_COMMISSION]: {
            order: 6,
            title: '',
            description: '',
            show: true,
        },
        [PackageType.LIMIT]: {
            order: 7,
            title: t(i18n)`Post your ad`,
            description: t(i18n)`Have your ad posted for number of days`,
            show: true,
        },
        [PackageType.LIMIT_AND_FEATURED]: {
            order: 8,
            title: t(i18n)`Post your ad and get featured`,
            description: t(
                i18n,
            )`Post your ad and get featured in searches belonging to the category of your ad`,
            show: true,
        },
        [PackageType.LIMIT_AND_AUTO_REFRESH]: {
            order: 9,
            title: t(i18n)`Post your ad and autoboost`,
            description: t(i18n)`Post your ad and get boosted to the top every few days`,
            show: true,
        },
    };

    switch (flow) {
        case PackagesFlow.LIMITED_AD: {
            return {
                ...excludePackageTypesFromConfigs(configs, VAS_PACKAGE_TYPES),
            };
        }

        case PackagesFlow.UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY:
        case PackagesFlow.UPSELLING_AD_FROM_USER_ADS: {
            return {
                ...excludePackageTypesFromConfigs(configs, LIMIT_PACKAGE_TYPES),
            };
        }

        default: {
            return configs;
        }
    }
};

export default usePackagesSectionsConfiguration;
