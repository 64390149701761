/**
 * A stubbed version of lingui's i18n object that forcefully
 * returns the original value when accessing localized content.
 */
import { setupI18n } from '@lingui/core';

import getCatalogs from './getCatalogs';

const mockedI18n = setupI18n({ locale: 'en', messages: getCatalogs() });

export default mockedI18n;
