import range from 'lodash/range';

import { BirthDatePart } from './types';

type DatePartValuesType = Partial<Record<Values<typeof BirthDatePart>, Array<number>>>;

const DatePartsValues: DatePartValuesType = {
    // lodash range doesn't include the end
    // in other words when we put 32, 13, 2023
    // it returns as last value 31, 12, 2022
    day: range(1, 32),
    month: range(1, 13),
    year: range(1950, new Date().getFullYear() + 1).reverse(),
};

const useDatPartValues = (datePart: Values<typeof BirthDatePart>) => {
    return DatePartsValues[datePart];
};

export default useDatPartValues;
