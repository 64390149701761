import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text, Button } from 'strat/components';

import { ChoiceBoxGroup } from 'horizontal/generic/choicePage';
import { ProductType } from 'horizontal/types';
import type { ProductPackageOffer, Ad } from 'horizontal/types';

import { CheckedText } from '../components';

import PackageChoiceBox from './packageChoiceBox';
import styles from './styles/choicesSection.cssm';

type Props = {
    readonly onClick: (arg1: ProductPackageOffer) => void;
    readonly packages: Array<ProductPackageOffer>;
    readonly selectedValue: string | null | undefined;
    readonly productType?: Values<typeof ProductType>;
    readonly title?: string;
    readonly valuePropositions?: Array<string>;
    readonly ad?: Ad | null | undefined;
    readonly onClickSeeExample?: (() => void) | null;
};

const ChoicesSection = ({
    onClickSeeExample,
    packages,
    onClick,
    productType,
    selectedValue,
    title,
    valuePropositions,
    ad,
}: Props) => {
    const i18n = useI18n();
    const selectedChoices = React.useMemo(
        () => (selectedValue ? [selectedValue] : []),
        [selectedValue],
    );

    const shownPackages = React.useMemo(() => {
        if (!productType) {
            return packages;
        }

        return packages.filter((p) =>
            p.productPackage.productItems.some((product) => product.type === productType),
        );
    }, [packages, productType]);

    if (!shownPackages?.length) {
        return null;
    }

    return (
        <Flex column className={styles.container}>
            {title && (
                <Flex justifySpaceBetween>
                    <Text.Large bold uppercase>
                        {title}
                    </Text.Large>
                    {onClickSeeExample ? (
                        <Button onClick={onClickSeeExample} secondary>
                            {t(i18n)`See example`}
                        </Button>
                    ) : null}
                </Flex>
            )}

            {valuePropositions?.map((text: string, index: number) => (
                <CheckedText key={index} text={text} />
            ))}

            <ChoiceBoxGroup
                className={styles.choiceBoxGroup}
                idField={'packageOfferID'}
                choices={shownPackages}
                choiceComponent={PackageChoiceBox}
                selectedChoiceIds={selectedChoices}
                onChoiceClick={onClick}
                ad={ad}
            />
        </Flex>
    );
};

export default ChoicesSection;
