import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import {
    selectBusinessPackageCartTotalDiscountPrice,
    selectBusinessPackageCartTotalPrice,
    selectBusinessPackageCartTotalPriceWithoutDiscount,
    selectBusinessPackageCartTotalPriceWithTax,
} from './selectors';
import styles from './styles/cartPriceDetails.cssm';
import useFormattedPackagesPrice from './useFormattedPackagesPrice';

const defaultRender = (
    formattedTotalPriceWithoutDiscount: string,
    formattedTotalDiscountPrice: string,
    formattedTax: string,
    formattedFinalPrice: string,
    taxPercentage: number,
    showTaxDetails: boolean,
) => (
    <Flex column className={styles.priceDetailsContainer}>
        <Flex>
            <Text.Large bold>
                <Trans>Price details</Trans>
            </Text.Large>
        </Flex>
        <Flex column>
            <Flex justifySpaceBetween className={styles.detailRow}>
                <Flex>
                    <Text.Regular>
                        <Trans>Price</Trans>
                    </Text.Regular>
                </Flex>
                <Flex>
                    <Text.Regular>{formattedTotalPriceWithoutDiscount}</Text.Regular>
                </Flex>
            </Flex>
            <Flex justifySpaceBetween className={styles.detailRow}>
                <Flex>
                    <Text.Regular>
                        <Trans>Discount</Trans>
                    </Text.Regular>
                </Flex>
                <Flex>
                    <Text.Regular>-{formattedTotalDiscountPrice}</Text.Regular>
                </Flex>
            </Flex>
            {showTaxDetails && (
                <Flex justifySpaceBetween className={styles.detailRow}>
                    <Flex>
                        <Text.Regular>
                            <Trans>Sales Tax ({Math.round((taxPercentage || 0) * 100)}%)</Trans>
                        </Text.Regular>
                    </Flex>
                    <Flex>
                        <Text.Regular>{formattedTax}</Text.Regular>
                    </Flex>
                </Flex>
            )}
            <Flex justifySpaceBetween className={styles.total}>
                <Flex>
                    <Text.Regular bold>
                        <Trans>Total</Trans>
                    </Text.Regular>
                </Flex>
                <Flex>
                    <Text.Regular bold className={styles.finalPrice}>
                        {formattedFinalPrice}
                    </Text.Regular>
                </Flex>
            </Flex>
        </Flex>
    </Flex>
);

type Props = {
    readonly showTaxDetails: boolean;
    readonly render?: (
        formattedTotalPriceWithoutDiscount: string,
        formattedTotalDiscountPrice: string,
        formattedTax: string,
        formattedFinalPrice: string,
        taxPercentage: number,
        showTaxDetails: boolean,
    ) => React.ReactElement;
};

const CartPriceDetails = ({ showTaxDetails = false, render = defaultRender }: Props) => {
    const totalPrice = useSelector(selectBusinessPackageCartTotalPrice);
    const totalPriceWithoutDiscount = useSelector(
        selectBusinessPackageCartTotalPriceWithoutDiscount,
    );
    const totalDiscountPrice = useSelector(selectBusinessPackageCartTotalDiscountPrice);

    const totalPriceWithTax = useSelector(selectBusinessPackageCartTotalPriceWithTax);
    const tax = totalPriceWithTax - totalPrice;
    const taxPercentage = totalPrice !== 0 ? totalPriceWithTax / totalPrice - 1 : 0;

    const formattedTotalPrice = useFormattedPackagesPrice(totalPrice);
    const formattedTotalPriceWithoutDiscount = useFormattedPackagesPrice(totalPriceWithoutDiscount);
    const formattedTotalDiscountPrice = useFormattedPackagesPrice(totalDiscountPrice);
    const formattedTax = useFormattedPackagesPrice(tax);
    const formattedTotalPriceWithTax = useFormattedPackagesPrice(totalPriceWithTax);

    const formattedFinalPrice = showTaxDetails ? formattedTotalPriceWithTax : formattedTotalPrice;

    return render(
        formattedTotalPriceWithoutDiscount,
        formattedTotalDiscountPrice,
        formattedTax,
        formattedFinalPrice,
        taxPercentage,
        showTaxDetails,
    );
};

export default CartPriceDetails;
