import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { CategoryFieldCombination } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

import {
    selectCategoryFieldCombinationCollection,
    selectCategoryFieldCombinations,
} from './selectors';

const useCategoryFieldCombinationCollection = () =>
    useSelector((state: GlobalState) => selectCategoryFieldCombinationCollection(state));

const useCategoryFieldCombinations = (
    categoryID?: number | null,
): Array<CategoryFieldCombination> => {
    const combinations = useSelector((state: GlobalState) =>
        selectCategoryFieldCombinations(state, categoryID),
    );
    return useMemo(
        () =>
            (combinations || []).map((combination) => ({
                ...combination,
                attributes: new Set(combination.attributes),
            })),
        [combinations],
    );
};

const useCategoryFieldCombinationsAreLoading = () =>
    useSelector((state: GlobalState) => state.categoryFieldCombinations.loading);

export {
    useCategoryFieldCombinationCollection,
    useCategoryFieldCombinations,
    useCategoryFieldCombinationsAreLoading,
};
