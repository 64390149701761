import { useSelector } from 'react-redux';
import { selectCurrency, Currency } from 'strat/i18n/money';

const useConvertedPrice = (price: number | null | undefined, currency: Values<typeof Currency>) => {
    const {
        exchangeRates: { base, rates },
    } = useSelector(selectCurrency);
    if (!price) {
        return 0;
    }
    if (!currency || currency === base) {
        return price;
    }
    return Math.ceil((rates[currency] * price) / 1000) * 1000;
};

export default useConvertedPrice;
