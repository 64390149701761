import * as React from 'react';
import { Trans } from '@lingui/macro';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { Button, Text } from 'strat/components';

import EditProfileSection from 'horizontal/user/editProfileSection';

import RejectPaymentBody from './rejectPaymentBody';
import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/paymentPreValidateErrorPage.cssm';

const MissingInfoErrorPage = {
    head: () => <PrivateNonIndexableHead />,
    body: () => (
        <>
            <RejectPaymentBody>
                <Text.Base bold className={styles.description}>
                    <Trans>Please add your phone number in your profile.</Trans>
                </Text.Base>
                <Link route={RouteNames.EDIT_PROFILE} params={{ section: EditProfileSection.INFO }}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: Element; primary: true; stretch: true; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button primary stretch>
                        <Trans>Go to profile</Trans>
                    </Button>
                </Link>
            </RejectPaymentBody>
        </>
    ),
} as const;

export default MissingInfoErrorPage;
