import * as React from 'react';
import { Button, Flex } from 'strat/components';

import type { ActionButton } from 'horizontal/chat/types';

import styles from './styles/actionButtons.cssm';

type Props = {
    readonly actionButtons: Array<ActionButton>;
};

const ActionButtons = ({ actionButtons }: Props) => (
    <div className={styles.container}>
        <Flex fillContainer className={styles.buttonsContainer}>
            {actionButtons.map(({ label, secondary, onClick }) => (
                <Button key={label} stretch secondary={secondary} onClick={onClick}>
                    {label}
                </Button>
            ))}
        </Flex>
    </div>
);
export default React.memo<Props>(ActionButtons);
