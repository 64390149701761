import type { PropertyData } from 'strat/property';
import { asNumberOrString } from 'strat/util';

import getPropertyGTMVars from './getPropertyGTMVars';

/**
 * Pick certain values from the property variables, to be used
 * when sending a list of properties.
 *
 * We could just send all common variables, but the Bayut
 * Stats API will crash if there are unknown properties.
 */
export default (
    property: PropertyData,
    statType: string,
    language: string,
    userProfile?: {
        [key: string]: any;
    } | null,
): {
    [key: string]: string;
} => {
    const propertyVars = getPropertyGTMVars(property, language);

    return {
        agencyid: propertyVars.agencyid,
        cat_id: propertyVars.cat_id,
        has_image: propertyVars.has_image,
        listing_type: propertyVars.listing_type,
        locality: propertyVars.locality,
        package_type: propertyVars.package_type,
        purpose: propertyVars.purpose_id,
        listingid: propertyVars.listingid,
        stat_type: statType,
        ...(userProfile?.id ? { userid: asNumberOrString(userProfile.id) } : {}),
    };
};
