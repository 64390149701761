import { t } from '@lingui/macro';
import * as React from 'react';
import ClassNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { ToggleButton } from 'strat/generic';
import { Flex, Text } from 'strat/components';

import iconWallet from '@app/assets/icons/iconWallet_noinline.svg';
import { WalletBalanceText } from 'horizontal/payment/wallet';

import styles from './styles/walletCredit.cssm';

const WalletCredit = () => {
    const i18n = useI18n();
    const [isUseWalletCredit, setIsUseWalletCredit] = React.useState(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setIsUseWalletCredit((prevValue) => !prevValue);
        }, 1200);

        return () => clearInterval(interval);
    }, []);

    return (
        <Flex column className={styles.container}>
            <Flex justifySpaceBetween>
                <Text.Large>{t(i18n)`Use wallet credit`}</Text.Large>
                <ToggleButton
                    styles={styles}
                    value={isUseWalletCredit}
                    checked={isUseWalletCredit}
                    onClick={() => {}}
                />
            </Flex>
            <Flex
                alignCenter
                className={ClassNames(styles.wallet, { [styles.checked]: isUseWalletCredit })}
            >
                <img alt={'My Wallet'} src={iconWallet} />
                <Flex column>
                    <Text.Large bold>{t(i18n)`dubizzle Wallet`}</Text.Large>
                    <WalletBalanceText balance={68} partialPayment={isUseWalletCredit} />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default WalletCredit;
