import { connect } from 'react-redux';
import { I18nProvider } from '@lingui/react';

import { localesFromLanguage } from './locales';
import getCatalogs from './getCatalogs';

export default connect((state) => ({
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    language: state.i18n.language,
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    locales: localesFromLanguage(state.i18n.language),
    catalogs: getCatalogs(),
}))(I18nProvider);
