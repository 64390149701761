import * as React from 'react';
import { ContextfulError } from 'strat/error/context';
import { logError } from 'strat/error/log';

import { StratAPI } from 'horizontal/api';
import type { UserDTO, FullUserDTO } from 'horizontal/dtos';
import { UserRoleTypes } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';

const useUserSearch = (
    setLoading: (loading: boolean) => unknown,
    setError: (error: string | undefined) => unknown,
) => {
    const makeCancelable = useMakeCancelable();

    const [users, setUsers] = React.useState<UserDTO[]>([]);

    const fetchUsers = React.useCallback(
        (value: string | null | undefined) => {
            if (!value?.trim()) {
                return;
            }

            setUsers([]);
            setLoading(true);
            setError(undefined);

            makeCancelable(
                new StratAPI()
                    .fetchRelatedUsers('phoneNumbers', [value.trim()])
                    .then((response) => {
                        if (response.status >= 400) {
                            throw new ContextfulError(
                                `Unexpected response status while searching for users for TeleSales`,
                                {
                                    status: response.status,
                                    data: response.data,
                                },
                            );
                        }

                        const users = response.data.filter((user) => {
                            const fullUser = user as FullUserDTO;
                            if (fullUser.roles?.length) {
                                return !fullUser.roles.includes(UserRoleTypes.TELE_SALES);
                            }
                            return true;
                        });
                        setUsers(users);
                    })
                    .catch((e) => {
                        logError({
                            e,
                            msg: 'Unable to search for users in TeleSales',
                            context: {
                                value,
                            },
                        });

                        setError("Couldn't fetch users");
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            );
        },
        [setLoading, setError, makeCancelable],
    );

    return { users, fetchUsers };
};

export default useUserSearch;
