import { type Loggers } from '@sector-labs/templates-renderer';

import { logError } from 'strat/error/log';

export const createLoggers = ({
    includeMessages = true,
}: {
    includeMessages: boolean;
}): Loggers => ({
    exceptionLog: (e: Error, msg?: string, context?: Object) =>
        logError({
            e,
            msg: msg || 'Unknown exception during template rendering',
            context: context as Record<string, any>,
        }),
    errorLog: includeMessages
        ? (msg: string, context?: Object) =>
              logError({
                  msg,
                  context: context as Record<string, any>,
              })
        : undefined,
});
