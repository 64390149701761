import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';
import { selectRouteParams } from 'strat/routes/selectors';

import { GlobalState } from 'horizontal/state';
import { TransactionPurpose, Location } from 'horizontal/types';
import { useNavigateToAgencyPage } from 'horizontal/agency/routing';
import { useAgencyProfile } from 'horizontal/agency/state';
import { propertyFiltersToExtraFields } from 'horizontal/agency/routing/mappers';
import { selectCategoryBySlug } from 'horizontal/categories';
import { DialogNames } from 'horizontal/dialogs';

import { AgencyExtraFilters } from '../types';

import FiltersButton from './filtersButton';
import FilterChips from './filterChips';
import styles from './styles/realEstateFilters.cssm';

export type ApplyFiltersParams = {
    readonly page: number;
    readonly extraFields: AgencyExtraFilters;
    readonly categorySlug?: string | null;
    readonly purpose: Values<typeof TransactionPurpose>;
    readonly locationSlug: string;
};

type Props = {
    readonly location: Location | null;
};

const getAdditionalFiltersCount = (filters?: string) => filters?.split(',').length || 0;

const RealEstateAgencyFilters = ({ location }: Props) => {
    const { slug: agencySlug } = useAgencyProfile() || {};
    const { category: categorySlug, filter } = useSelector(selectRouteParams);
    const category = useSelector((state: GlobalState) => selectCategoryBySlug(state, categorySlug));
    const navigatePage = useNavigateToAgencyPage();
    const additionalFiltersCount = getAdditionalFiltersCount(filter);
    const params = React.useMemo(
        () => ({
            agencySlug: agencySlug,
        }),
        [agencySlug],
    );

    const applyFilters = React.useCallback(
        (filterParams: ApplyFiltersParams) => {
            const extraFields = propertyFiltersToExtraFields(filterParams.extraFields);
            navigatePage({
                ...params,
                ...filterParams,
                extraFields,
                page: 1,
            });
        },
        [navigatePage, params],
    );

    const [, setDialogVisible] = useDialog(DialogNames.PROPERTY_FILTERS_DIALOG);

    const openFilters = React.useCallback(() => {
        setDialogVisible(true, {
            applyFilters,
        });
    }, [setDialogVisible, applyFilters]);

    return (
        <Flex column className={styles.filtersContainer}>
            <Flex className={styles.moreFilters}>
                <FiltersButton onClick={openFilters} />
                <FilterChips
                    location={location}
                    openFilters={openFilters}
                    category={category}
                    onCategoryDismiss={() =>
                        navigatePage({
                            ...params,
                            extraFields: {},
                            purpose: TransactionPurpose.ALL,
                        })
                    }
                    additionalFiltersCount={additionalFiltersCount}
                />
            </Flex>
        </Flex>
    );
};

export default RealEstateAgencyFilters;
