import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import boostToTopExample from '@app/assets/images/boostToTopExample.desktop.gif';
import { useI18n } from 'strat/i18n/language';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import boostToTopExampleMobile from '@app/assets/images/boostToTopExample.mobile.gif';

import styles from './styles/boostToTopAdExample.cssm';
import Example from './example';

type Props = {
    readonly onClick: () => void;
};

const BoostToTopAdExample = ({ onClick }: Props) => {
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobileLayout);

    return (
        <Example
            src={isMobile ? boostToTopExampleMobile : boostToTopExample}
            alt={t(i18n)`Package example`}
            onClick={onClick}
        >
            <span className={styles.description}>
                <Trans>Back on top like a new ad</Trans>
            </span>
        </Example>
    );
};

export default BoostToTopAdExample;
