import merge from 'lodash/merge';

import handleRegisterResponse from '@app/api/handleRegisterResponse';
import { HTTPApi } from 'strat/util';
import type { PropertyData } from 'strat/property/types';
import type { SettingsData } from 'strat/settings';
import type { SearchParams } from 'strat/search/types';
import type { SavedSearchParams } from 'strat/search/savedSearches/types';
import type {
    RequestTruEstimatePropertyData,
    TruEstimatePropertyData,
} from 'strat/truEstimate/types';
import type {
    AgentReviewFillData,
    UserInitiatedAgentReviewCreateData,
} from 'strat/agency/agent/review/types';
import type { UserRegistrationData } from 'strat/user/types';
import { AlertFrequency, AlertType, NotificationType } from 'strat/alerts/types';
import type { Report } from 'strat/report/types';
import { canonicalDomain } from 'strat/routing';
import { ServerAuthContext } from 'strat/server/serverAuthContext';
import { ExpressSessionData } from '@app/server/expressSession';
import { createForwardCompatibleExpressSessionData } from 'strat/user/createForwardCompatibleExpressSessionData';
import {
    GenerateReportResponse,
    SendReportParams,
    SaveReportResponse,
} from 'strat/truEstimate/types';
import { TruBrokerAgentsParams } from 'strat/leaderboard/types';
import { ReportDetailsAPIResponse, ReportPDFAPIResponse } from 'strat/truEstimate/apiResponseTypes';
import { TransactionsSearchQueryParams } from 'strat/propertyMarketAnalysis/state/constructTransactionSearchQueryParams';
import buildTransactionSearchQueryString from 'strat/propertyMarketAnalysis/state/buildTransactionSearchQueryString';

import { ACL } from './s3';
import { APIError, APIErrorReason } from './error';
import type {
    APIResponse,
    LoginResponse,
    UpsertAdPayload,
    ImplicitRegisterResponse,
} from './types';
import { FrontEndEnvironment, PlatformName } from './types';

/**
 * Provides access to the internal Node HTTP API.
 */
class InternalAPI extends HTTPApi {
    static buildAbsoluteURL(path: string): string {
        if (process.env.IS_SERVER) {
            const server = canonicalDomain();
            const baseURL = `${server.protocol}://${server.domain}${server.port}`;
            return `${baseURL}${path}`;
        }

        return path;
    }

    authHeader() {
        if (process.env.IS_SERVER) {
            return ServerAuthContext.asHeaders();
        }

        return super.authHeader();
    }

    /**
     * Makes a request to the Node HTTP API.
     */
    request(path: string, options: any) {
        const headers: Record<string, any> = {};

        const mergedOptions = {
            ...options,
            headers: {
                'Accept-Language': this.language,
                ...headers,
                ...this.authHeader(),
                ...(options.headers || {}),
            },
        } as const;

        // @ts-expect-error - TS2339 - Property 'buildAbsoluteURL' does not exist on type 'Function'.
        const url = this.constructor.buildAbsoluteURL(path);
        return super.request(url, null, mergedOptions);
    }

    /**
     * Makes a POST request to the Node HTTP API.
     */
    post(path: string, body?: any) {
        return this.request(path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...this.authHeader(),
            },
            body: JSON.stringify(body),
        });
    }

    /**
     * Makes a GET request to the Node HTTP API.
     */
    get(path: string, headers: Record<string, string> = {}) {
        return this.request(path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...this.authHeader(),
                ...headers,
            },
        });
    }

    /**
     * Makes a DELETE request to the Node HTTP API.
     */
    delete(path: string) {
        return this.request(path, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...this.authHeader(),
            },
        });
    }

    /**
     * Makes a PATCH request to the Node HTTP API.
     */
    patch(path: string, body: any) {
        return this.request(path, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                ...this.authHeader(),
            },
            body: JSON.stringify(body),
        });
    }

    /**
     * Handler for when an exception is thrown while making a request.
     */
    static handleException(error: Error): void {
        if (error instanceof APIError) {
            throw error;
        }

        throw new APIError('Unknown error while handling response to InternalAPI request', {
            reason: APIErrorReason.UNKNOWN_ERROR,
            cause: error,
        });
    }

    /**
     * Handles general response errors/success.
     */
    static handleResponse(response: any): any {
        if (response.status >= 200 && response.status <= 299) {
            return response;
        }

        switch (response.status) {
            case 400:
                throw new APIError('Invalid request', {
                    reason: APIErrorReason.INVALID_REQUEST,
                    response,
                });
            case 401:
                throw new APIError('Unauthorized', {
                    reason: APIErrorReason.UNAUTHORIZED,
                    response,
                });
            case 403:
                throw new APIError('Access denied', {
                    reason: APIErrorReason.ACCESS_DENIED,
                    response,
                });
            case 404:
                throw new APIError('Not found', {
                    reason: APIErrorReason.NOT_FOUND,
                    response,
                });
            case 412:
                throw new APIError('Precondition failed', {
                    reason: APIErrorReason.PRECONDITION_FAILED,
                    response,
                });
            case 422:
                throw new APIError('Unprocessable entity', {
                    reason: APIErrorReason.UNPROCESSABLE_ENTITY,
                    response,
                });
            case 429:
                throw new APIError('Too many requests (rate limited)', {
                    reason: APIErrorReason.TOO_MANY_REQUESTS,
                    response,
                });
            default:
                throw new APIError('Non-2xx status code that was not expected', {
                    reason: APIErrorReason.UNKNOWN_ERROR,
                    response,
                });
        }
    }

    /**
     * Handles the response to a log in request.
     */
    static handleLogin(response: LoginResponse): APIResponse<ExpressSessionData> {
        if (response.status === CONFIG.build.LOGIN_INVALID_CREDENTIALS_STATUS) {
            throw new APIError('Invalid credentials during login', {
                reason: APIErrorReason.INVALID_CREDENTIALS,
                response,
            });
        }

        return {
            status: response.status,
            data: createForwardCompatibleExpressSessionData(response.data),
        };
    }

    /**
     * Verifies the specified credentials and retrieves the user profile.
     */
    login(
        email: string,
        password: string,
        remember: boolean,
    ): Promise<APIResponse<ExpressSessionData>> {
        return (
            this.post('/api/login', { email, password, remember })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleLogin' does not exist on type 'Function'.
                .then(this.constructor.handleLogin)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    logout(): Promise<APIResponse<ExpressSessionData>> {
        return (
            this.post('/api/logout')
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Attempts to login in the user with the specified Facebook profile.
     */
    loginWithFacebook(facebookProfileData: any): Promise<APIResponse<ExpressSessionData>> {
        return (
            this.post('/api/loginWithFacebook', facebookProfileData)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleLogin' does not exist on type 'Function'.
                .then(this.constructor.handleLogin)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Attempts to login the user with the specified Google profile.
     */
    loginWithGoogle(googleProfileData: any): Promise<APIResponse<ExpressSessionData>> {
        return (
            this.post('/api/loginWithGoogle', googleProfileData)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleLogin' does not exist on type 'Function'.
                .then(this.constructor.handleLogin)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Gets all favorites for the current user.
     */
    getFavorites(getInactives: boolean | null | undefined = false): Promise<any> {
        const path = getInactives
            ? `/api/user/favorites?getInactives=${getInactives}`
            : '/api/user/favorites';
        return (
            this.get(path)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Toggles a favorite.
     */
    toggleFavorites(property: PropertyData): Promise<any> {
        const body = { property } as const;

        return (
            this.post('/api/user/favorites/toggle', body)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Saves the specified settings for the currently logged in user.
     */
    saveSettings(settingsData: SettingsData): Promise<any> {
        return (
            this.post('/api/saveSettings', settingsData)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Attempts to register a new user with the specified data.
     */
    register(registrationData: UserRegistrationData): Promise<any> {
        return (
            this.post('/api/register', registrationData)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                .then(handleRegisterResponse)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Attempts to register a user given only an email address
     * for use cases such as 'lead generation' or 'alerts subscription'
     */
    implicitRegister(email: string): Promise<ImplicitRegisterResponse> {
        return this.post('/api/implicitRegister', { email })
            .catch(InternalAPI.handleException)
            .then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                    return {
                        status: 200,
                        data: {
                            registration: {
                                externalID: response.data.profile.id.toString(),
                                email: response.data.profile.email || null,
                                isNewlyRegistered: true,
                            },
                            session: createForwardCompatibleExpressSessionData(response.data),
                        },
                    };
                }

                if (response?.status === 409) {
                    let profile = response.data.list_item;
                    if (Array.isArray(profile)) {
                        profile = profile.reduce((a, b) => merge(a, b));
                    }

                    // Ideally the response contains the ID + email of
                    // the user that already exists, if we don't we
                    // should throw to let the caller know.
                    if (!profile.id || !profile.email) {
                        throw new APIError("User already exists, but we don't know the ID", {
                            reason: APIErrorReason.ALREADY_REGISTERED,
                            email,
                            response,
                        });
                    }

                    return {
                        status: 409,
                        data: {
                            registration: {
                                externalID: profile.id.toString(),
                                email: profile.email,
                                isNewlyRegistered: false,
                            },
                            session: null,
                        },
                    };
                }

                throw new APIError('Implicit registration returned non-2xx status code', {
                    reason: APIErrorReason.UNKNOWN_ERROR,
                    email,
                    response: response || undefined,
                });
            });
    }

    /**
     * Attempts to reset the user's password.
     */
    resetPassword(email: string): Promise<APIResponse<unknown>> {
        return (
            this.post('/api/resetPassword', { email })
                .then((response) => {
                    if (CONFIG.build.WITH_NEW_PASSWORD_RESET_SYSTEM) {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        }

                        throw new APIError('Reset password returned non-2xx status code', {
                            reason:
                                response.status === 400
                                    ? APIErrorReason.INVALID_REQUEST
                                    : APIErrorReason.UNKNOWN_ERROR,
                            email,
                            response,
                        });
                    } else {
                        if (response.status === 500) {
                            throw new APIError('Reset password returned 500 status code', {
                                reason: APIErrorReason.UNKNOWN_ERROR,
                                email,
                                response,
                            });
                        }

                        return response;
                    }
                })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
        );
    }

    /**
     * Attempts to confirm user's reset password request.
     */
    resetPasswordConfirm(token: string, password: string): Promise<APIResponse<unknown>> {
        return (
            this.post('/api/resetPasswordConfirm', { token, password })
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }

                    if (response.status === 404) {
                        throw new APIError('Reset password confirmation invalid token', {
                            reason: APIErrorReason.INVALID_PASSWORD_RESET_TOKEN,
                            token,
                            response,
                        });
                    }

                    throw new APIError('Reset password non-2xx status code', {
                        reason: APIErrorReason.UNKNOWN_ERROR,
                        token,
                        response,
                    });
                })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
        );
    }

    /**
     * Gets all saved searches for the current user.
     */
    getSavedSearches(_?: string | null): Promise<any> {
        return (
            this.get(`/api/user/searches/saved`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Adds a "saved search" for the current user.
     */
    addSavedSearch(name: string, params: SavedSearchParams): Promise<any> {
        return (
            this.post('/api/user/searches/save', { name, params })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                .then((response) => {
                    if (response.status === 204) {
                        throw new APIError('Saved search already exists', {
                            reason: APIErrorReason.ALREADY_EXISTS,
                            name,
                            params: JSON.stringify(params),
                            response,
                        });
                    }

                    return response;
                })
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Removes a "saved search" for the current user.
     */
    removeSavedSearch(searchID: string): Promise<any> {
        return (
            this.delete(`/api/user/searches/saved/${searchID}`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Adds a "email alert" for the current user.
     */
    addAlert(
        params: SearchParams,
        type: string = AlertType.EXPLICIT,
        language: string = CONFIG.build.LANGUAGE_CODE,
    ): Promise<any> {
        return (
            this.post(`/api/emailalerts?language=${language}`, { params, type })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                .then((response: APIResponse<unknown>) => {
                    if (
                        response.status === 422 ||
                        (!CONFIG.build.WITH_NEW_ALERTS_SYSTEM && response.status === 204)
                    ) {
                        throw new APIError('Email alert already exists', {
                            reason: APIErrorReason.ALREADY_EXISTS,
                            type,
                            params: JSON.stringify(params),
                            response,
                        });
                    } else if (response.status !== 200) {
                        throw new APIError('Adding email alert non-2xx status code', {
                            reason: APIErrorReason.UNKNOWN_ERROR,
                            type,
                            params: JSON.stringify(params),
                            response,
                        });
                    }

                    return response;
                })
        );
    }

    /**
     * Adds a "recommended email alert" for the current user.
     */
    addRecommendedEmailAlert(
        type: string = AlertType.EXPLICIT,
        language: string = CONFIG.build.LANGUAGE_CODE,
    ): Promise<any> {
        return (
            this.post(
                `/api/emailalerts?notificationType=${NotificationType.RECOMMENDED_EMAIL}&language=${language}`,
                { type },
            )
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                .then((response: APIResponse<unknown>) => {
                    if (response.status === 422) {
                        throw new APIError('Recommended email alert already exists', {
                            reason: APIErrorReason.ALREADY_EXISTS,
                            type,
                            response,
                        });
                    } else if (response.status !== 200) {
                        throw new APIError('Adding recommended email alert non-2xx status code', {
                            reason: APIErrorReason.UNKNOWN_ERROR,
                            type,
                            response,
                        });
                    }

                    return response;
                })
        );
    }

    /**
     * Adds a "recently viewed email alert" for the current user.
     */
    addRecentlyViewedEmailAlert(language: string = CONFIG.build.LANGUAGE_CODE): Promise<any> {
        return this.post(
            `/api/emailalerts?notificationType=${NotificationType.RECENTLY_VIEWED_EMAIL}&language=${language}`,
        ).catch((response) => {
            return response;
        });
    }

    /**
     * Adds an email address to an already existing user
     */
    addEmailToUserProfile(email: string): Promise<APIResponse<unknown>> {
        return (
            this.post('/api/updateUserProfile', { email })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    commuteMatrix(sources: Array<any>, targets: Array<any>): Promise<any> {
        const sourcesLatLon = sources.map(({ lat, lon }) => `${lat},${lon}`).join(',');
        const targetsLatLon = targets.map(({ lat, lon }) => `${lat},${lon}`).join(',');

        return (
            this.get(`/api/commute/matrix?sources=${sourcesLatLon}&targets=${targetsLatLon}`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    isochrone(
        {
            latitude,
            longitude,
        }: {
            latitude: number;
            longitude: number;
        },
        contours: Array<number>,
    ): Promise<any> {
        return (
            this.get(
                `/api/commute/isochrone?source=${latitude},${longitude}&contours=${contours.join(
                    ',',
                )}`,
            )
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Fetch email alerts for the current user.
     */
    getAlerts(): Promise<any> {
        return (
            this.get('/api/emailalerts')
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Fetch recommended email alerts for the current user.
     */
    getRecommendedEmailAlerts(): Promise<any> {
        return (
            this.get(`/api/emailalerts?notificationType=${NotificationType.RECOMMENDED_EMAIL}`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Fetch recently viewed email alerts for the current user.
     */
    getRecentlyViewedEmailAlerts(): Promise<any> {
        return (
            this.get(`/api/emailalerts?notificationType=${NotificationType.RECENTLY_VIEWED_EMAIL}`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Remove an alert from the backend based on
     * the primary key alertHash.
     */
    removeAlert(alertHash: string): Promise<any> {
        return (
            this.delete(`/api/emailalerts/${alertHash}`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Remove a recommended email alert from the backend based on the primary key alertHash
     */
    removeRecommendedEmailAlert(alertHash: string): Promise<any> {
        return (
            this.delete(
                `/api/emailalerts/${alertHash}?notificationType=${NotificationType.RECOMMENDED_EMAIL}`,
            )
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Remove all the alerts from the current user.
     */
    removeAlerts(): Promise<any> {
        return (
            this.delete('/api/emailalerts')
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                .then((response) => {
                    if (response.status !== 200 && response.status !== 204) {
                        throw new APIError('Removing all email alerts non-2xx status code', {
                            reason: APIErrorReason.UNKNOWN_ERROR,
                            response,
                        });
                    }
                    return response;
                })
        );
    }

    getSubscriptions(): Promise<any> {
        return (
            this.get('/api/subscriptions')
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    updateSubscription(
        alertID: string,
        notificationType: Values<typeof NotificationType>,
        fieldsToUpdate: {
            isActive?: boolean;
            frequency?: Values<typeof AlertFrequency>;
        },
    ): Promise<any> {
        return (
            this.patch('/api/subscriptions', { alertID, notificationType, ...fieldsToUpdate })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Unsubscribes a particular alert and returns unsubscribed alert data
     */
    unsubscribeAlert(alertHash: string): Promise<any> {
        return (
            this.delete(`/api/emailalerts/${alertHash}/unsubscribe`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Unsubscribes a particular recommended email alert and returns unsubscribe email alert data
     */
    unsubscribeRecommendedEmailAlert(alertHash: string): Promise<any> {
        return (
            this.delete(`/api/emailalerts/recommendations/${alertHash}/unsubscribe`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /*
     * Unsubscribes a particular recently viewed email alert and returns unsubscribe email alert data
     */
    unsubscribeRecentlyViewedEmailAlert(alertHash: string): Promise<any> {
        return (
            this.delete(`/api/emailalerts/recentlyviewed/${alertHash}/unsubscribe`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    sendEmailAgent(
        agentExternalID: string,
        inquirerName: string,
        inquirerEmail: string,
        inquirerPhone: string,
        inquirerBody: string,
    ) {
        return (
            this.post('/api/sendEmailAgent', {
                agentExternalID,
                inquirerName,
                inquirerEmail,
                inquirerPhone,
                inquirerBody,
            })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    sendReport(propertyID: number, report: Report) {
        return this.post(`/api/listing/id/${propertyID}/complaints/`, report);
    }

    /**
     * Gets a report (in JSON form) of a listing's performance.
     */
    adPerformanceReport({ externalID }: { externalID: string }): Promise<any> {
        return (
            this.get(`/api/listing/${externalID}/reports/performance`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                .then((response) => {
                    if (response && response.status === 404) {
                        throw new APIError('Ad performance report 404', {
                            reason: APIErrorReason.NOT_FOUND,
                            response,
                        });
                    }

                    // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                    return this.constructor.handleResponse(response);
                })
        );
    }

    adsMetrics({
        adExternalIDs,
        adSource,
        adType,
    }: {
        adExternalIDs: Array<string>;
        adSource: string;
        adType: string;
    }): Promise<any> {
        const mappedExternalIDs = adExternalIDs.join(',');

        return this.get(
            `/api/listing/reports/metrics?externalIDs=${mappedExternalIDs}&adSource=${adSource}&adType=${adType}`,
        );
    }

    agenciesMetrics({
        agencyExternalIDs,
        startTimestamp,
        endTimestamp,
    }: {
        agencyExternalIDs: Array<string>;
        startTimestamp?: number;
        endTimestamp?: number;
    }): Promise<any> {
        const mappedExternalIDs = agencyExternalIDs.join(',');
        let url = `/api/agencies/metrics?externalIDs=${mappedExternalIDs}`;
        if (startTimestamp) {
            url += `&startTimestamp=${startTimestamp}`;
            if (endTimestamp) {
                url += `&endTimestamp=${endTimestamp}`;
            }
        }
        return this.get(url);
    }

    /**
     * Returns the accepted agent reviews for an agent(paginated request).
     */
    acceptedAgentReviews(agentExternalID: string, page: number, pageSize = 8) {
        return this.get(
            `/api/agent/review?agentExternalID=${agentExternalID}&page=${page}&pageSize=${pageSize}`,
        );
    }

    /**
     * Returns the accepted agents reviews for an agency
     */
    agencyAgentsReviews(agencyExternalID: string, page: number, pageSize = 8, state = '') {
        return this.get(
            `/api/agent/review?agencyExternalID=${agencyExternalID}&page=${page}&pageSize=${pageSize}&state=${state}`,
        );
    }

    /**
     * Returns all the agent reviews based on a reviewer email
     */
    agentReviewsByReviewerEmail(reviewerEmail: string) {
        return this.get(`/api/agent/review?reviewerEmail=${encodeURIComponent(reviewerEmail)}`);
    }

    /**
     * Creates a user initiated agent review.
     */
    createUserInitiatedAgentReview(reviewData: UserInitiatedAgentReviewCreateData): Promise<any> {
        return this.post(`/api/agent/review`, {
            comment: reviewData.comment,
            rating: reviewData.rating,
            leadPhoneNumber: reviewData.phoneNumber,
            reviewerName: reviewData.name,
            reviewerEmail: reviewData.email,
            adExternalID: reviewData.adExternalID,
            purposeOfService: reviewData.purpose,
            categoryExternalID: reviewData.category,
            locationExternalID: reviewData.location,
            dateOfService: reviewData.dateTimestamp,
            language: reviewData.language,
            agentExternalID: reviewData.agentExternalID,
            areaName: reviewData.areaName,
            userDeviceID: reviewData.deviceID,
            userSessionID: reviewData.sessionID,
            type: reviewData.type,
            parentReview: reviewData.parentReview,
            images: reviewData.images,
        });
    }

    /**
     * Fills a given agent review.
     */
    fillAgentReview(reviewData: AgentReviewFillData): Promise<any> {
        return this.patch(`/api/agent/review/${reviewData.reviewExternalID}`, {
            comment: reviewData.comment,
            rating: reviewData.rating,
            leadPhoneNumber: reviewData.phoneNumber,
            reviewerName: reviewData.name,
            reviewerEmail: reviewData.email,
            adExternalID: reviewData.adExternalID,
            purposeOfService: reviewData.purpose,
            categoryExternalID: reviewData.category,
            locationExternalID: reviewData.location,
            dateOfService: reviewData.dateTimestamp,
            language: reviewData.language,
            areaName: reviewData.areaName,
            userDeviceID: reviewData.deviceID,
            userSessionID: reviewData.sessionID,
        });
    }

    deleteAgentReview(externalID: string): Promise<any> {
        return this.delete(`/api/agent/review/${externalID}`);
    }

    /**
     * Gets a list of featured agents
     */
    getFeaturedAgents(): Promise<any> {
        return (
            this.get(`/api/agent/featured`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Sends an offer for a listing.
     */
    sendOffer(
        externalID: string,
        inquirerName: string,
        inquirerEmail: string,
        inquirerPhone: string,
        inquirerOffer: string,
    ) {
        return (
            this.post(`/api/listing/${externalID}/offer`, {
                inquirerName,
                inquirerEmail,
                inquirerPhone,
                inquirerOffer,
            })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    /**
     * Send email to agents
     */
    sendEmail(
        propertyId: number,
        name: string,
        email: string,
        phone: string,
        senderMessage: string,
    ) {
        const options = {
            sender_name: name,
            sender_email: email,
            sender_phone: phone,
            sender_message: senderMessage,
            property_id: propertyId,
        } as const;
        return this.post('/api/send-email/', options);
    }

    /**
     * Schedule an email asking for agent review by the user
     */
    scheduleReviewRequestReminder(propertyId: number, name: string, email: string, phone: string) {
        const options = {
            sender_name: name,
            sender_email: email,
            sender_phone: phone,
            property_id: propertyId,
        } as const;
        return this.post('/api/agent/reviews/schedule-reminder/', options);
    }

    uploadImage(
        imageName: string,
        folderName: string,
        file: File,
        acl: Values<typeof ACL> = ACL.PUBLIC_READ,
    ): Promise<any> {
        return (
            this.get(
                `/api/getUploadSignedURL/${folderName}?imageName=${imageName}&acl=${acl}&methodType=POST`,
            )
                .then((response) => {
                    // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                    this.constructor.handleResponse(response);

                    const presignedPostData = response.data;
                    const fields = presignedPostData.fields;
                    const formData = new FormData();
                    Object.keys(fields).forEach((key) => {
                        formData.append(key, fields[key]);
                    });
                    formData.append('file', file);

                    const url = presignedPostData.url.replace(/\/$/, '');
                    const imageUrl = `${url}/${fields.key}`;

                    return Promise.all([
                        imageUrl,
                        this.request(url, {
                            method: 'POST',
                            body: formData,
                        }),
                    ]);
                })
                .then(([imageUrl, response]: [any, any]) => {
                    // aws s3 success code for post call is 204
                    if (response.status === 204) {
                        return { ...response, data: imageUrl };
                    }
                    // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                    this.constructor.handleResponse(response);
                    return { ...response, data: imageUrl };
                })
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
        );
    }

    upsertAd(payload: UpsertAdPayload): Promise<any> {
        return (
            this.post(`/api/listing/upsert`, payload)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    updateAdTerms(externalID: string, payload: any): Promise<any> {
        return (
            this.put(`/api/listing/${externalID}/acceptTerms`, payload)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    saveKeycloakSession(payload: {
        accessToken: string;
        refreshToken: string;
        idToken: string;
    }): Promise<any> {
        return (
            this.post(`/api/keycloak/session`, payload)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    forgetKeycloakSession(): Promise<any> {
        return (
            this.delete(`/api/keycloak/session`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    getListingEvaluation(queryParams: string): Promise<any> {
        return (
            this.get(`/api/listing/evaluation?${queryParams}`)
                // @ts-expect-error - TS2339 - Property 'handleException' does not exist on type 'Function'.
                .catch(this.constructor.handleException)
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    getAdBannerData(adExternalID: string): Promise<any> {
        return this.get(`/api/adBannerData?adExternalID=${adExternalID}`);
    }

    toggleHiddenSmartShareRecommendation(recommendationID: number): Promise<any> {
        return this.post(`/api/user/smartShare/recommendations/toggleHidden`, { recommendationID });
    }

    addSmartShareRecommendations(leadID: string, adExternalIDs: Array<string>): Promise<any> {
        return this.post(`/api/user/smartShare/recommendations`, { leadID, adExternalIDs });
    }

    addSmartSharePhoneNumber(leadID: string, phoneNumber: string): Promise<any> {
        return this.post(`/api/user/smartShare/phoneNumber`, { leadID, phoneNumber });
    }

    addSmartShareComment(
        leadID: string,
        payload: {
            text?: string;
            addedRecommendationsCount?: string;
        },
    ): Promise<any> {
        return this.post(`/api/user/smartShare/comments`, { leadID, payload });
    }

    updateSmartShareComment(commentID: number, text: string): Promise<any> {
        return this.put(`/api/user/smartShare/comments/${commentID}`, { text });
    }

    deleteSmartShareComment(commentID: number): Promise<any> {
        return this.delete(`/api/user/smartShare/comments/${commentID}`);
    }

    generateShortLink(destinationURL: string): Promise<any> {
        return this.post(`/api/generateShortLink`, { destinationURL });
    }

    translateAdDescription(
        adExternalID: string,
        languageCode: string,
        descriptionHash: string,
    ): Promise<any> {
        return (
            this.get(
                `/api/listing/${adExternalID}/description/${languageCode}/translation?${HTTPApi.buildQueryString(
                    { h: descriptionHash },
                )}`,
            )
                // @ts-expect-error - TS2339 - Property 'handleResponse' does not exist on type 'Function'.
                .then(this.constructor.handleResponse)
        );
    }

    generateTruEstimateReport(
        preview: boolean,
        propertyData: TruEstimatePropertyData | RequestTruEstimatePropertyData,
        userID: string | null,
        platform: PlatformName,
        frontEndEnvironment: FrontEndEnvironment,
        searchBy: string,
        generatedFromProfolio?: boolean,
    ): Promise<GenerateReportResponse> {
        return this.post(`/api/truEstimate/generate`, {
            preview,
            propertyData,
            userID,
            platform,
            frontEndEnvironment,
            searchBy,
            ...(generatedFromProfolio ? { generatedFromProfolio } : {}),
        });
    }

    sendTruEstimateReport(request: SendReportParams): Promise<SaveReportResponse> {
        return this.post('/api/truEstimate/send', request)
            .catch(InternalAPI.handleException)
            .then(InternalAPI.handleResponse);
    }

    sendTruEstimateReportFeedback(
        externalID: string | null,
        userName: string | null,
        feedback: string,
        feedbackType: string,
    ): Promise<APIResponse<unknown>> {
        return this.post('/api/truEstimate/feedback', {
            externalID,
            userName,
            feedback,
            feedback_type: feedbackType,
        });
    }

    getTruEstimateReportDetails(
        externalID: string,
    ): Promise<APIResponse<ReportDetailsAPIResponse>> {
        return this.post(`/api/truEstimate/reportDetails`, { externalID });
    }

    getTruEstimateReportPDF(
        reportId: string,
        language: string,
        reportCreatedAt: number | undefined,
    ): Promise<APIResponse<ReportPDFAPIResponse>> {
        return this.post(`/api/truEstimate/getReportPDF`, { reportId, language, reportCreatedAt });
    }

    getTruBrokerData(): Promise<any> {
        // fetches the badges and activities of the loggedin agent
        return this.post(`/api/truBroker`);
    }

    getAgentCubeData(params: TruBrokerAgentsParams): Promise<any> {
        // fetches the cube data of the loggedin agent based on some filters (location, purpose, category)
        return this.post(`/api/truBroker/cube`, params);
    }

    searchAgents(params: TruBrokerAgentsParams): Promise<any> {
        return this.post(`/api/truBroker/search`, params);
    }

    searchTransactions(params: TransactionsSearchQueryParams): Promise<any> {
        return this.get(`/api/transactions?${buildTransactionSearchQueryString(params)}`);
    }
}

export default InternalAPI;
