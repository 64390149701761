import * as React from 'react';

import iconFloorPlans from '@app/assets/icons/iconProjectUnitFloorPlans_noinline.svg';
import { StylelessButton } from 'strat/generic';

import styles from './styles/unitFloorPlansButton.cssm';

type Props = {
    onClick: () => void;
};

const UnitFloorPlansButton = ({ onClick }: Props) => (
    <StylelessButton type="button" onClick={onClick}>
        <img src={iconFloorPlans} className={styles.icon} />
    </StylelessButton>
);

export default UnitFloorPlansButton;
