import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import { SearchRequestOrder, SearchRequestSortType } from '@sector-labs/fe-search-redux/backend';
import Area from 'strat/i18n/area';
import Currency from 'strat/i18n/money/currency';
import { PaginationType, VerticalSelection } from 'horizontal/types';
import {
    trackAboutEMPG,
    trackOLXBlog,
    trackOLXBusiness,
    trackContactUs,
} from 'horizontal/gtm/pageTracking';
import { SearchPageSearchType } from 'horizontal/search/types';
import { AdCommonExtraField } from '@app/branding/adExtraFields';
import horizontalSettings, { type HorizontalSettings } from 'horizontal/branding/settings';

import { languages } from './languages';

const topLevelLocationWithoutHierarchy = {
    id: 1,
    externalID: '1000001',
    level: 0,
    name: 'Pakistan',
    slug: 'pakistan_g1000001',
} as const;

// @ts-expect-error - TS2322 - Type '{ readonly getBrandName: (i18n: I18n) => string; readonly getDomainName: (i18n: I18n) => string; readonly countryName: (i18n: I18n) => string; readonly getTopLevelLocation: (i18n: I18n) => { name: string; hierarchy: { name: string; id: 1; externalID: "1000001"; level: 0; slug: "pakistan_g1000001"; }[]; id: 1; extern...' is not assignable to type 'HorizontalSettings'.
const settings: HorizontalSettings = {
    ...horizontalSettings,
    getBrandName: (i18n: I18n): string => t(i18n)`OLX`,
    getDomainName: (i18n: I18n): string => t(i18n)`OLX Pakistan`,
    countryName: (i18n: I18n): string => t(i18n)`Pakistan`,
    getTopLevelLocation: (i18n: I18n) => ({
        ...topLevelLocationWithoutHierarchy,
        name: t(i18n)`Pakistan`,
        hierarchy: [
            {
                ...topLevelLocationWithoutHierarchy,
                name: t(i18n)`Pakistan`,
            },
        ],
    }),
    defaultPhoneNumber: {
        country: 'pak',
        prefix: '+92',
        trunk_prefix: '0',
    },
    selectAreaUnit: () => ({
        areaUnit: Area.SQFT,
        isDefaultAreaUnit: true,
    }),
    popularCategories: ['mobiles', 'vehicles', 'property-for-sale', 'property-for-rent'],
    topLevelLocation: {
        ...topLevelLocationWithoutHierarchy,
        hierarchy: [topLevelLocationWithoutHierarchy],
    },
    social: { twitterSite: '@OLX_Pakistan' },
    themeColor: '#002f34',
    baseCurrencyName: Currency.PKR,
    defaultCurrencyName: Currency.PKR,
    linkAppStore: 'https://apps.apple.com/pk/app/olx-pakistan/id1551315538',
    linkGooglePlayMobile: 'market://details?id=com.olx.pk',
    linkGooglePlayDesktop: 'https://play.google.com/store/apps/details?id=com.olx.pk',
    linkAppGallery: 'https://appgallery.huawei.com/#/app/C104375435',
    appDeepLink: 'https://olxpk.page.link/home',
    targetingParamsPrefix: 'pk',
    enableSocialMedia: true,
    paginationType: PaginationType.INFINITE_SCROLLING,
    enableContactMethodControl: false,
    defaultAllowChatCommunication: true,
    priceSortFields: [
        `extraFields.${AdCommonExtraField.PRICE}`,
        `extraFields.${AdCommonExtraField.SALARY_FROM}`,
    ],
    languages,
    searchPageSearchType: SearchPageSearchType.CIRCLE_BASED_SLOTTING,
    eliteAdsPerPageCount: 3,
    disableWallet: true,
    disableSavedSearches: true,
    // <home page>
    enableHomePageWallpaper: false,
    // the category identifier on olx-pk is the external_id. Slugs are not unique
    homePageCategoriesWithHitsIdentifiers: [
        '1453', // mobile phones
        '84', // cars
        '81', // motorcycles
        '1721', // houses (for sale)
        '729', // tv-video-audio
        '1455', // tablets
        '40', // land & plots
        '4', // jobs
    ],
    // </home page>
    disableCommercialNumber: true,
    aboutUsLinks: (i18n: I18n) => {
        const brandName = settings.getBrandName(i18n);
        return [
            {
                name: t(i18n)`About Dubizzle Group`,
                url: 'https://dubizzlegroup.com/',
                event: trackAboutEMPG,
            },
            {
                name: t(i18n)`OLX Blog`,
                url: 'https://blog.olx.com.pk/',
                event: trackOLXBlog,
            },
            {
                name: t(i18n)`Contact Us`,
                url: t(i18n)`contact_url`,
                event: trackContactUs,
            },
            {
                name: t(i18n)`${brandName} for Businesses`,
                url: t(i18n)`businesses_url`,
                event: trackOLXBusiness,
            },
        ];
    },

    startPaginationFromPageZero: true,
    showWelcomeLocationSelectionDialog: true,
    hidePopularSearchesOnQueryFilter: true,
    disableCategoryFieldsWithoutCategory: true,
    disableFilterByCategory: true,
    disableFilterByAdId: true,
    vatNumberRequired: true,
    locationSearchFuzzySettings: {
        active: true,
        fuzziness: 2,
        transpositions: true,
    },
    deliverySettings: {
        allowDeliveryFromAnyLocation: true,
        disableTermsConditionsCheckbox: true,
        disableLinks: true,
    },
    posting_guidelines: {
        en: 'https://help.olx.com.pk/hc/en-us/sections/4403040419087-Posting-guidelines',
    },
    tipsForImprovingAdSellingChances: {
        en: 'https://help.olx.com.pk/hc/en-us/articles/6411709268111-How-to-post-a-good-ad',
    },
    allAboutPostingAds: {
        en: 'https://help.olx.com.pk/hc/en-us/categories/4403034834447-Posting-and-Managing-Ads',
    },
    support_request: {
        en: 'https://help.olx.com.pk/hc/en-us/requests/new',
    },
    appDescription: {
        iosAppName: 'OLX Pakistan',
        androidAppName: 'OLX Pakistan',
    },
    installAppBannerSubtitle: (i18n: I18n): string => t(i18n)`10 Million+ Installs`,
    maxLocationLevelIndexationByCategory: { default: 3 },
    enableL2CategoriesSort: true,
    maxSearchPages: 49,
    headerMaxLocationLevel: 4,
    parseLocationNameWithCity: true,
    enableMobileBannersCarousel: true,
    extraLocationsCheckRule: true,
    popularChoicesNumber: 5,
    useMostRelevantAsNewlyListed: false,
    enableAdDetailsMap: false,
    verticalsInCategoryWidget: [
        {
            name: VerticalSelection.MOTORS,
            slug: 'vehicles',
        },
        {
            name: VerticalSelection.PROPERTY,
            slug: 'properties',
        },
    ],
    categoriesWithCustomBreadcrumbs: ['cars'],
    hasBankPaymentMethodEnabled: true,
    freeAdTerms: {
        decleration: {
            en: 'I swear by God that if I succeed in making a sale through OLX Pakistan, I will pay 1% of the sale value to OLX Pakistan within 10 days of receiving my full price.',
            ur: 'OLX میں اللہ تعالی کے نام پرعہد کرتا ہوں کہ اگرمیں  پاکستان کے ذریعے سے اپنے مال کو بیچنے میں کامیاب ہوں OLX گا تو میں اس کی قیمتِ فروخت کا ۱ فیصد پاکستان کو پوری قیمت موصول ہونے کے 10 دنوں کے اندر ادا کرنے کا پابند ہوں گا۔',
        },
        pledge: {
            en: `"Honour Allah's covenant when you make a pledge, and do not break your oaths after confirming them, having made Allah your guarantor. Surely Allah knows all you do"`,
            ur: '"جب تم کوئی عہد کرو تو اللہ کے عہد کی تعظیم کرو اور اللہ کو اپنا ضامن بنا کر اپنی قسموں کو پختہ کرنے کے بعد مت توڑو۔ بے شک اللہ جانتا ہے جو تم کرتے ہو"',
        },
        author: {
            en: 'Surah An-Nahl - 91',
            ur: 'سورہ نحل - 91',
        },
        note: {
            en: 'Note: This pledge is only applicable to free ad posting. If you purchase a Featured Ad, this pledge will not apply to you.',
            ur: 'نوٹ: یہ عھد صرف مفت اشتہار لگانے پر لاگو ہوگا۔ فیچرڈ ایڈ خریدنے کی صورت میں یہ عھد لاگو نہیں ہو گا۔',
        },
    },
    minFreeAdCommission: 150,
    useOlxSitemap: false,
    featuredSort: CONFIG.build.STRAT_ENABLE_FEATURED_BTT
        ? {
              type: SearchRequestSortType.ATTRIBUTES,
              key: 'newest-featured-ads',
              name: 'Newest featured ads',
              attributes: [
                  { name: 'timestamp', order: SearchRequestOrder.DESC },
                  { name: 'id', order: SearchRequestOrder.DESC },
              ],
          }
        : undefined,
    combineMakeAndModelOnBreadcrumbs: true,
    breadcrumbsCityLevel: CONFIG.build.CITY_LEVEL,
    packagesUsageDuration: 90,
    enableOtpValidation: true,
    phoneInputFormat: '..-',
    getAgencyPortalBrandName: (i18n: I18n) => t(i18n)`OLX`,
} as const;

export default settings;
