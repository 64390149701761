import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex } from 'strat/components';

import AdPostedIcon from '@app/assets/icons/adPostedIcon.svg';

import styles from './styles/postAdSuccessTitle.cssm';

const PostAdSuccessTitle = () => {
    const i18n = useI18n();

    return (
        <Flex className={styles.container} aria-label="Success message">
            <AdPostedIcon />
            <Flex column justifyCenter alignCenter className={styles.textContainer}>
                <Text.XLarge bold>{t(i18n)`Your Ad has been uploaded successfully!`}</Text.XLarge>
                <Text.Base className={styles.description}>
                    <span> {t(i18n)`Your Ad will soon be reachable to `}</span>
                    <span className={styles.bold}> {t(i18n)`millions`}</span>
                    <span> {t(i18n)`of buyers`}</span>
                </Text.Base>
            </Flex>
        </Flex>
    );
};

export default PostAdSuccessTitle;
