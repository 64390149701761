import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import settings from '@app/branding/settings';

import Page from 'horizontal/pages/page';
import { verticalPages } from 'horizontal/types';
import { fetchFeaturedAgencies } from 'horizontal/featuredAgencies/state';
import { fetchVerticalsPromotedAds } from 'horizontal/verticals/properties/promotedAds';

export type VerticalCategoriesParams = {
    readonly vertical: Values<typeof VerticalsRoute.verticals>;
    readonly requestID?: string;
};

class VerticalsRoute extends Route {
    static verticals = Object.freeze({
        PROPERTIES: 'realestate',
        CARS: 'cars-for-sale',
    });

    constructor() {
        super(RouteNames.HORIZONTAL_VERTICALS, [
            [
                '^/',
                {
                    name: 'vertical',
                    pattern: `(${VerticalsRoute.verticals.CARS})`,
                },
                '/success/',
                {
                    name: 'requestID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
            [
                '^/',
                {
                    name: 'vertical',
                    pattern: `(${Object.values(VerticalsRoute.verticals).join('|')})`,
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { vertical, requestID }: VerticalCategoriesParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        if (requestID && vertical === VerticalsRoute.verticals.CARS) {
            const pathname = `/${VerticalsRoute.verticals.CARS}/success/${requestID}`;
            return { pathname };
        }
        const pathname = `/${vertical}/`;
        return { pathname };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            match: {
                params: { vertical, requestID },
            },
        } = context;

        if (
            CONFIG.runtime.OLX_HORIZONTAL_VERTICALS_PAGES?.includes(verticalPages.PROPERTIES) &&
            vertical === VerticalsRoute.verticals.PROPERTIES
        ) {
            context.promise.wait(
                Promise.all([
                    context.redux.store.dispatch(
                        fetchVerticalsPromotedAds({
                            categoryId: settings.verticalProperties.categoryID,
                        }),
                    ),
                    context.redux.store.dispatch(
                        fetchFeaturedAgencies({
                            categoryExternalID: settings.verticalProperties.externalID,
                        }),
                    ),
                    context.redux.store.dispatch(fetchCategoryFields()),
                ]),
            );
            context.rendering.renderPage(Page.VERTICAL_PROPERTIES);
        } else if (
            CONFIG.runtime.ENABLE_CASH_MY_CAR &&
            settings.cashMyCar.enabled &&
            vertical === VerticalsRoute.verticals.CARS
        ) {
            context.promise.wait(
                context.redux.store.dispatch(
                    fetchCategoryFields({ categorySlugs: ['cars-for-sale'] }),
                ),
            );
            if (requestID) {
                context.rendering.renderPage(Page.CASH_MY_CAR_SUCCESS, {
                    requestID,
                });
            } else {
                context.rendering.renderPage(Page.VERTICAL_CARS);
            }
        } else {
            context.rendering.renderPage(Page.NOT_FOUND);
        }
    }
}

export { VerticalsRoute as VerticalsRouteClass };

export default new VerticalsRoute();
