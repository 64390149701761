//@ts-nocheck
import { SearchView } from 'strat/search';
import { selectRouteParamsInput, createSearchRouteParams } from 'strat/search/url';
import { savedSearchesReducer } from 'strat/search/savedSearches/state';
import type { FetchState } from 'strat/fetcher';
import type { PropertyData } from 'strat/property';

import urlUpdaterReducer, { updateURL, type URLState } from './urlUpdater';
import recommendationsReducer from './recommendations';
import pageRuleReducer from './pageRule';
import type { PageRule } from './pageRule';

/**
 * Search page parameters.
 */
export type PageParameters = {
    isAreaPage: boolean;
    isBedroomPage: boolean;
    isCustomPage: boolean;
    areaPageUnit: string | null;
    areaPageValue: number | null;
    basePath: string | null;
    breadcrumbLeafText: string | null;
    pagetype: string | null;
    scriptExternalID: string | null;
};

/**
 * Available actions for search.
 */
const Actions = Object.freeze({
    SET_SEARCH_VIEW: 'SEARCH/SET_SEARCH_VIEW',
    UPDATE_FILTERS_VISIBILITY: 'SEARCH/UPDATE_FILTERS_VISIBILITY',
    SET_WPTO: 'SET_WPTO',
    SET_PAGE_PARAMETERS: 'SEARCH/SET_PAGE_PARAMETERS',
    SET_UNSUBSCRIBE_BANNER: 'SEARCH/SET_UNSUBSCRIBE_BANNER',
    SAVE_SCROLL_Y_POSITION: 'SEARCH/SAVE_SCROLL_Y_POSITION',
    SET_SCROLL_Y_POSITION: 'SEARCH/SET_SCROLL_Y_POSITION',
    SET_REMARKETED_LISTINGS: 'SEARCH/SET_REMARKETED_LISTINGS',
    SET_FILTERS_LIST_VISIBLE: 'SEARCH/SET_FILTERS_LIST_VISIBLE',
});

export type SearchState = {
    hits: {
        view: StringValues<typeof SearchView>;
    };
    advancedFiltersVisible: boolean;
    showUnsubscribe: boolean;
    withWPTO: boolean;
    pageParameters: PageParameters;
    filtersListVisible: boolean;
    savedScrollYPosition: number;
    scrollYPositionToRestore: number;
    // This is PropertyData[] for real-estate portals and LiteAd[] for horizontal. We perform the conversion in the main selector for each.
    // Having a custom type for the remarketing listing is not a good idea since some strat-only selectors rely on some fields which are not present in LiteAd.
    remarketedListings: Object[];
    url: URLState;
    saved?: FetchState;
    recommendations?: FetchState<{
        readonly recommenderHits: PropertyData[];
        readonly locationFallbackHits: PropertyData[];
        pageRule?: PageRule;
    }>;
};
/**
 * Default search state.
 */
const defaultState: SearchState = Object.freeze({
    hits: Object.freeze({
        view: SearchView.SEARCH_RESULTS,
    }),
    advancedFiltersVisible: false,
    showUnsubscribe: false,
    withWPTO: false,
    pageParameters: Object.freeze({
        isAreaPage: false,
        isBedroomPage: false,
        isCustomPage: false,
        areaPageUnit: null,
        areaPageValue: null,
        basePath: null,
        breadcrumbLeafText: null,
        pagetype: null,
        scriptExternalID: null,
    }),
    filtersListVisible: false,
    savedScrollYPosition: 0,
    scrollYPositionToRestore: 0,
    remarketedListings: [],
}) as const;

/**
 * Search reducer.
 */
const searchReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_SEARCH_VIEW:
            return {
                ...state,
                hits: {
                    ...state.hits,
                    view: action.view,
                },
            };

        case Actions.UPDATE_FILTERS_VISIBILITY:
            return {
                ...state,
                advancedFiltersVisible: action.advancedFiltersVisible,
            };

        case Actions.SET_UNSUBSCRIBE_BANNER:
            return {
                ...state,
                showUnsubscribe: action.visible,
            };

        case Actions.SET_WPTO:
            return {
                ...state,
                withWPTO: action.WPTO,
            };

        case Actions.SET_PAGE_PARAMETERS:
            return {
                ...state,
                pageParameters: {
                    ...state.pageParameters,
                    ...action.pageParameters,
                },
            };
        case Actions.SET_REMARKETED_LISTINGS:
            return {
                ...state,
                remarketedListings: action.remarketedListings,
            };
        case Actions.SAVE_SCROLL_Y_POSITION:
            return {
                ...state,
                savedScrollYPosition: action.scrollYPosition,
                scrollYPositionToRestore: 0,
            };
        case Actions.SET_SCROLL_Y_POSITION:
            return {
                ...state,
                scrollYPositionToRestore: state.savedScrollYPosition,
                savedScrollYPosition: 0,
            };
        case Actions.SET_FILTERS_LIST_VISIBLE:
            return {
                ...state,
                filtersListVisible: action.filtersListVisible,
            };
        default:
            return {
                ...state,
                url: urlUpdaterReducer(state.url, action),
                saved: savedSearchesReducer(state.saved, action),
                recommendations: recommendationsReducer(state.recommendations, action),
                pageRule: pageRuleReducer(state.pageRule, action),
            };
    }
};

/**
 * Set Wallpaper Takeover.
 */
const setWPTO = (visibility: boolean) => ({
    type: Actions.SET_WPTO,
    WPTO: visibility,
});

/**
 * Set Advanced Filters Visibility
 */
const setAdvancedFiltersVisibility = (visibility: boolean) => ({
    type: Actions.UPDATE_FILTERS_VISIBILITY,
    advancedFiltersVisible: visibility,
});

/**
 * Shows the favorites, discarding the search results.
 */
const showFavorites = () => ({
    type: Actions.SET_SEARCH_VIEW,
    view: SearchView.FAVORITES,
});

const showRecommendations = () => ({
    type: Actions.SET_SEARCH_VIEW,
    view: SearchView.RECOMMENDATIONS,
});

/**
 * Shows the search results, discarding the favorites.
 */
const showSearchResults = () => ({
    type: Actions.SET_SEARCH_VIEW,
    view: SearchView.SEARCH_RESULTS,
});

const showMap = () => ({
    type: Actions.SET_SEARCH_VIEW,
    view: SearchView.MAP_BASED_SEARCH,
});

const showCommute = () => ({
    type: Actions.SET_SEARCH_VIEW,
    view: SearchView.COMMUTE_SEARCH,
});

/**
 * Show/Hide the unsubscribe banner
 */
const showUnsubscribeBanner = (visible: boolean) => ({
    type: Actions.SET_UNSUBSCRIBE_BANNER,
    visible,
});

/**
 * Sets search page parameters.
 */
const setPageParameters =
    (pageParameters: Partial<PageParameters>) => (dispatch: any, getState: any) => {
        dispatch({
            type: Actions.SET_PAGE_PARAMETERS,
            pageParameters,
        });

        if (dispatch && getState && getState().search.url.enabled) {
            const routeParamsInput = selectRouteParamsInput(getState());
            const routeParams = createSearchRouteParams(
                routeParamsInput.filters,
                routeParamsInput.extraParams,
            );

            dispatch(updateURL(routeParams));
        }
    };

/**
 * Resets the page parameters to their initial state.
 */
const resetPageParameters = () => ({
    type: Actions.SET_PAGE_PARAMETERS,
    pageParameters: defaultState.pageParameters,
});

const setRemarketedListing = (remarketedListing: PropertyData) => ({
    type: Actions.SET_REMARKETED_LISTINGS,
    remarketedListings: [remarketedListing],
});

const setRemarketedListings = (remarketedListings: PropertyData[]) => ({
    type: Actions.SET_REMARKETED_LISTINGS,
    remarketedListings,
});

const clearRemarketedListings = () => ({
    type: Actions.SET_REMARKETED_LISTINGS,
    remarketedListings: defaultState.remarketedListings,
});

const setFiltersListVisible = (filtersListVisible: boolean) => ({
    type: Actions.SET_FILTERS_LIST_VISIBLE,
    filtersListVisible,
});

/**
 * Saves the scroll position in order to be used when going
 * back to the search page from details page.
 */
const saveScrollYPosition = (scrollY: number) => ({
    type: Actions.SAVE_SCROLL_Y_POSITION,
    scrollYPosition: scrollY,
});

const restoreScrollYPosition = () => ({
    type: Actions.SET_SCROLL_Y_POSITION,
});

const resetScrollYPosition = () => ({
    type: Actions.SET_SCROLL_Y_POSITION,
});

export {
    Actions,
    showFavorites,
    showRecommendations,
    showSearchResults,
    setAdvancedFiltersVisibility,
    setWPTO,
    showUnsubscribeBanner,
    setPageParameters,
    resetPageParameters,
    setRemarketedListing,
    clearRemarketedListings,
    saveScrollYPosition,
    restoreScrollYPosition,
    resetScrollYPosition,
    setRemarketedListings,
    showMap,
    setFiltersListVisible,
    showCommute,
};

export default searchReducer;
