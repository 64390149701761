import React from 'react';
import { Trans } from '@lingui/macro';
import EliteIcon from '@app/assets/icons/iconElite_noinline.svg';
import { Flex } from 'strat/components';

import styles from './styles/eliteStrip.cssm';

type Props = {
    className?: string;
};

const EliteStrip = ({ className }: Props) => {
    return (
        <Flex className={className ?? styles.container} alignCenter aria-label="Elite">
            <img src={EliteIcon} alt={'Elite Icon'} className={styles.logo} />
            <Trans>Elite</Trans>
        </Flex>
    );
};

export default EliteStrip;
