import { t } from '@lingui/macro';
import React from 'react';
import { useI18n } from 'strat/i18n/language';
import {
    usePopularCategoriesLinks,
    useAboutUsLinks,
    useOtherLinks,
    useTrendingSearchesLinks,
} from 'horizontal/footer';
import type { FooterLinkSection } from 'horizontal/footer/types';

const useFooterLinks = (): ReadonlyArray<FooterLinkSection> => {
    const i18n = useI18n();
    const popularCategoriesLinks = usePopularCategoriesLinks();
    const aboutUsLinks = useAboutUsLinks();
    const otherLinks = useOtherLinks();
    const trendingSearchesLinks = useTrendingSearchesLinks();

    return React.useMemo(
        () => [
            {
                section: t(i18n)`Popular Categories`,
                links: popularCategoriesLinks,
            },
            {
                section: t(i18n)`Trending Searches`,
                links: trendingSearchesLinks,
            },
            {
                section: t(i18n)`About Us`,
                links: aboutUsLinks,
            },
            {
                section: t(i18n)`OLX`,
                links: otherLinks,
            },
        ],
        [i18n, aboutUsLinks, popularCategoriesLinks, otherLinks, trendingSearchesLinks],
    );
};

export default useFooterLinks;
