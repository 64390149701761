import * as React from 'react';
import amenityIcon from '@app/assets/icons/iconAmenities_noinline.svg';

import type { PropertyAmenityData } from 'strat/property/types';
import { connectLanguage } from 'strat/i18n/language';

import formatAmenityValue from './formatAmenityValue';
import styles from './styles/propertyAmenity.cssm';

/**
 * Properties for {@see PropertyAmenity}.
 */
type Props = {
    amenity: PropertyAmenityData;
    formatNumber: (arg1: number) => string;
    className?: string;
};

/**
 * Renders a single amenity icon.
 */
const AmenityIcon = ({ icon }: { icon: string }) => (
    <svg className={styles.icon}>
        <use xlinkHref={`${amenityIcon}#${icon}`} />
    </svg>
);

/**
 * Renders a single amenity.
 */
const PropertyAmenity = ({ amenity, formatNumber, className = styles.closerAmenity }: Props) => {
    const amenityValue = formatAmenityValue(amenity, formatNumber);

    return (
        <div className={className}>
            <div className={styles.imageContainer}>
                <AmenityIcon icon={amenity.icon} />
            </div>
            <div className={styles.textContainer}>
                <span className={styles.text}>
                    {amenity.text}
                    {amenityValue ? `: ${amenityValue}` : ''}
                </span>
            </div>
        </div>
    );
};

export default connectLanguage(PropertyAmenity);
