import { groupBy, sortBy } from 'lodash';
import Category from '@app/branding/category';

import type { ProjectUnit } from 'strat/project/types';

import { createUnitRangeValue } from './unitRangeValue';

export type CategoryGroupedUnits = {
    categorySlug: string;
    isCategoryCommercial: boolean;
    startingPrice: number | null;
    units: ProjectUnit[];
};

const getUnitPrice = (unit: ProjectUnit): number | null => {
    return unit.priceMin || unit.priceMax || null;
};

const getUnitCategorySlug = (unit: ProjectUnit) => {
    return unit.category.length > 0 ? unit.category[unit.category.length - 1].slug : '';
};

const isCommercialUnit = (unit: ProjectUnit) => {
    return Category.isOfCommercialType(unit.category?.[0]);
};

export const groupUnitsByCategory = (units: ProjectUnit[]): CategoryGroupedUnits[] => {
    const groupedUnits: CategoryGroupedUnits[] = [];

    const unitsDict = groupBy(units, getUnitCategorySlug);
    for (const [categorySlug, categoryUnits] of Object.entries(unitsDict)) {
        const unitPrices = categoryUnits.map(getUnitPrice).filter(Boolean) as number[];

        groupedUnits.push({
            categorySlug,
            isCategoryCommercial: isCommercialUnit(categoryUnits[0]),
            startingPrice: unitPrices.length ? Math.min(...unitPrices) : null,
            units: categoryUnits,
        });
    }

    return sortBy(groupedUnits, ['startingPrice']);
};

export type BedsGroupedUnits = {
    bedsCount: string | null;
    startingPrice: number | null;
    units: ProjectUnit[];
};

const getUnitBedsCount = (unit: ProjectUnit) => {
    return unit.rooms;
};

const sortUnits = (units: ProjectUnit[]) =>
    sortBy(
        units,
        (unit) => unit.order,
        (unit) => createUnitRangeValue(unit.priceMin, unit.priceMax)[0] || 0,
        (unit) => createUnitRangeValue(unit.areaMin, unit.areaMax)[0] || 0,
    );

export const groupUnitsByBeds = (units: ProjectUnit[]): BedsGroupedUnits[] => {
    const groupedUnits: BedsGroupedUnits[] = [];

    const unitsDict = groupBy(units, getUnitBedsCount);
    for (const [bedsCount, bedsUnits] of Object.entries(unitsDict)) {
        const unitPrices = bedsUnits.map(getUnitPrice).filter(Boolean) as number[];

        groupedUnits.push({
            bedsCount: bedsCount === 'null' ? null : bedsCount,
            startingPrice: unitPrices.length ? Math.min(...unitPrices) : null,
            units: sortUnits(bedsUnits),
        });
    }

    return sortBy(groupedUnits, ['bedsCount']);
};
