import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import PriceLabel from '../priceLabel';
import RejectedLabel from '../rejectedLabel';

import styles from './styles/rejectedSeller.cssm';

type Props = {
    readonly lastRejectedPrice: number;
};

const Rejected = ({ lastRejectedPrice }: Props) => {
    return (
        <Flex column fillContainer alignCenter justifyCenter className={styles.container}>
            <Text.Large bold uppercase>
                <Trans>{"Buyer's offer"}</Trans>
            </Text.Large>
            <Flex alignCenter>
                <PriceLabel price={lastRejectedPrice} gray />
                <RejectedLabel />
            </Flex>
            <Text.Regular>
                <Trans>{"You asked for better offer, waiting for buyer's response"}</Trans>
            </Text.Regular>
        </Flex>
    );
};

export default React.memo<Props>(Rejected);
