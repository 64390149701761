import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Image } from 'strat/util';
import iconDiscountPNG from '@app/assets/icons/iconDiscount.png';
import iconDiscountWEBP from '@app/assets/icons/iconDiscount.webp';
import { Flex, Text } from 'strat/components';

import useFormattedPackagesPrice from 'horizontal/businessPackages/useFormattedPackagesPrice';

import styles from './styles/packagePriceTag.cssm';

type Props = {
    readonly fullPrice: number;
    readonly discountPrice?: number | null | undefined | null;
};

const PackagePriceTag = ({ fullPrice, discountPrice }: Props) => {
    const i18n = useI18n();
    const formattedFullPrice = useFormattedPackagesPrice(fullPrice);
    const formattedDiscountPrice = useFormattedPackagesPrice(discountPrice || 0);

    if (!discountPrice) {
        return <Text.Large bold>{formattedFullPrice}</Text.Large>;
    }

    const discountPercentage = fullPrice ? Math.ceil((1 - discountPrice / fullPrice) * 100) : 0;

    return (
        <>
            <div className={styles.discount}>
                <Image
                    alt={t(i18n)`Discount`}
                    image={{
                        source: iconDiscountPNG,
                        sourceWEBP: iconDiscountWEBP,
                    }}
                />
                <Text.Base className={styles.discountPercentage} bold>
                    {`-${discountPercentage}%`}
                </Text.Base>
            </div>

            <Flex column className={styles.discountPriceContainer}>
                <Text.Base className={styles.priceValue} bold>
                    {formattedFullPrice}
                </Text.Base>
                <Text.Large bold>{formattedDiscountPrice}</Text.Large>
            </Flex>
        </>
    );
};

export default PackagePriceTag;
