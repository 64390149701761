import type { Store } from 'redux';
import isEqual from 'lodash/isEqual';

import { RouteNames } from 'strat/routes';

import RoutingContext from '../routingContext';
import type { RoutingMiddleware } from '../routingMiddleware';

/**
 * Available actions for the router.
 */
const Actions = Object.freeze({
    CHANGE_ROUTE: 'ROUTER/CHANGE_ROUTE',
    SET_REFERRER: 'ROUTER/SET_REFERRER',
    SET_ORIGINAL_REFERRER: 'ROUTER/SET_ORIGINAL_REFERRER',
    SET_LANDING_URL: 'ROUTER/SET_LANDING_URL',
    CLEAR_LANDING_URL: 'ROUTER/CLEAR_LANDING_URL',
});

/**
 * Action that sets the active route.
 */
type ChangeRouteAction = {
    type: Values<typeof Actions>;
    name: string;
    params: any;
    url: string;
    originalURL: string;
    referrer: string;
    landingURL?: null | string;
    originalReferrer?: null | string;
};

type SetReferrerAction = {
    type: Values<typeof Actions>;
    referrer: string;
};

type SetLandingURLAction = {
    type: Values<typeof Actions>;
    landingURL: string;
};

type ClearLandingURLAction = {
    type: Values<typeof Actions>;
};

type SetOriginalReferrerAction = {
    type: Values<typeof Actions>;
    originalReferrer: string;
};

/**
 * Router state.
 */
export type State = {
    routeName: Values<typeof RouteNames> | null;
    routeParams: null | any;
    url: null | string;
    originalURL: null | string;
    referrer: null | string;
    /**
     * The initial HTTP referrer
     */
    originalReferrer: null | string;
    /**
     * The first URL hit during the current browsing session
     */
    landingURL: null | string;
};

/**
 * Default state for the router.
 */
const defaultState: State = {
    routeName: null,
    routeParams: null,
    url: null,
    originalURL: null,
    referrer: null,
    originalReferrer: null,
    landingURL: null,
};

/**
 * Reducers for the router.
 */
const routerReducer = (
    state: State | null | undefined = defaultState,
    action: ChangeRouteAction,
): State => {
    switch (action.type) {
        case Actions.CHANGE_ROUTE:
            // @ts-expect-error - TS2322 - Type '{ routeName: string; routeParams: any; url: string; originalURL: string | null; referrer: string; originalReferrer?: string | null | undefined; landingURL?: string | null | undefined; }' is not assignable to type 'State'.
            return {
                ...state,
                routeName: action.name,
                routeParams: action.params,
                url: action.url,
                originalURL: action.originalURL || null,
                referrer: action.referrer,
            };
        case Actions.SET_REFERRER:
            // @ts-expect-error - TS2322 - Type '{ referrer: string; routeName?: string | null | undefined; routeParams?: any; url?: string | null | undefined; originalURL?: string | null | undefined; originalReferrer?: string | null | undefined; landingURL?: string | ... 1 more ... | undefined; }' is not assignable to type 'State'.
            return {
                ...state,
                referrer: action.referrer,
            };
        case Actions.SET_LANDING_URL:
            // @ts-expect-error - TS2322 - Type '{ landingURL: string | null; routeName?: string | null | undefined; routeParams?: any; url?: string | null | undefined; originalURL?: string | null | undefined; referrer?: string | null | undefined; originalReferrer?: string | ... 1 more ... | undefined; }' is not assignable to type 'State'.
            return {
                ...state,
                landingURL: action?.landingURL ?? null,
            };
        case Actions.CLEAR_LANDING_URL:
            // @ts-expect-error - TS2322 - Type '{ landingURL: null; routeName?: string | null | undefined; routeParams?: any; url?: string | null | undefined; originalURL?: string | null | undefined; referrer?: string | null | undefined; originalReferrer?: string | ... 1 more ... | undefined; }' is not assignable to type 'State'.
            return { ...state, landingURL: null };
        case Actions.SET_ORIGINAL_REFERRER:
            // @ts-expect-error - TS2322 - Type '{ originalReferrer: string | null; routeName?: string | null | undefined; routeParams?: any; url?: string | null | undefined; originalURL?: string | null | undefined; referrer?: string | null | undefined; landingURL?: string | ... 1 more ... | undefined; }' is not assignable to type 'State'.
            return {
                ...state,
                originalReferrer: action?.originalReferrer ?? null,
            };
        default:
            // @ts-expect-error - TS2322 - Type 'State | null' is not assignable to type 'State'.
            return state;
    }
};

/**
 * Sets the name of the currently active route.
 * @param name The name of the route that is currently active.
 * @param params The parameters that were passed to the route
 * that is currently active.
 * @param url The URL that routed to the specified route.
 * @param originalURL The URL that routed to the specified route,
 * but before it was processed by a URL processor.
 * @param referrer The previous URL.
 * @param originalReferrer The original website referrer URL.
 * @param landingURL The first URL which the user accessed on our
 * platform.
 */
const changeRoute = (
    name: string,
    params: any,
    url: string,
    originalURL: string,
    referrer: string,
    originalReferrer: string,
    landingURL: string,
): ChangeRouteAction => ({
    type: Actions.CHANGE_ROUTE,
    name,
    url,
    originalURL,
    params,
    referrer,
    originalReferrer,
    landingURL,
});

/**
 * Sets the current referrer.
 */
const setReferrer = (referrer: string): SetReferrerAction => ({
    type: Actions.SET_REFERRER,
    referrer,
});

/**
 * Sets the original landing URL.
 */
const setLandingURL = (landingURL: string): SetLandingURLAction => ({
    type: Actions.SET_LANDING_URL,
    landingURL,
});

const clearLandingURL = (): ClearLandingURLAction => ({ type: Actions.CLEAR_LANDING_URL });

/**
 * Sets the original referrer (usually external or null if direct traffic).
 */
const setOriginalReferrer = (originalReferrer: string): SetOriginalReferrerAction => ({
    type: Actions.SET_ORIGINAL_REFERRER,
    originalReferrer,
});

/**
 * Extended context with a Redux store that is provided to route handlers.
 */
class RoutingContextRedux {
    /**
     * Main Redux store.
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    store: Store;

    /**
     * Initializes a new instance of {@see RoutingContextRedux}.
     * @param store Redux store
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    constructor(store: Store) {
        this.store = store;
    }
}

export {
    RoutingContextRedux,
    routerReducer,
    setReferrer,
    setOriginalReferrer,
    setLandingURL,
    clearLandingURL,
    Actions,
};

/**
 * Creates a new routing middleware that provides access
 * to the Redux store to routes and syncs the current rout
 * to the Redux store.
 * @param store The Redux store to use.
 * @returns A new routing middleware.
 */
// @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
export default (store: Store): RoutingMiddleware =>
    (context: RoutingContext): RoutingContextRedux => {
        const router = store.getState().router;
        const routeNameChanged = !isEqual(router.routeName, context.match.route.name);
        const routeParamsChanged = !isEqual(router.routeParams, context.match.params);

        if (!router.landingURL) {
            store.dispatch(setLandingURL(context.match.url));
        }

        if (routeNameChanged || routeParamsChanged) {
            store.dispatch(
                changeRoute(
                    context.match.route.name,
                    context.match.params,
                    context.match.url,
                    context.match.originalURL,
                    router.url || router.referrer,
                    router.originalReferrer,
                    router.landingURL,
                ),
            );
        }

        return new RoutingContextRedux(store);
    };
