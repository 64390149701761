import type { LiteAd } from '../types';

const mergeNewSearchHits = (
    existingHits: Array<LiteAd>,
    adsResponseHits: Array<LiteAd>,
): Array<LiteAd> => {
    if (!existingHits || existingHits.length === 0) {
        return adsResponseHits;
    }

    if (!adsResponseHits || adsResponseHits.length === 0) {
        return existingHits;
    }
    // filter out all new hits which were already fetched
    // this should happen very very rarely if we publish lots of hits before user presses load more
    // existingHits have the length of: Page * 20
    // new hits have length of : 20
    // for each page load we'll have O(20 * 20 * Page) which should be fine
    const existingHitsExternalIDs = existingHits.map((hit) => hit.externalID);
    const filteredNewHits = adsResponseHits.filter(
        (hit) => !existingHitsExternalIDs.includes(hit.externalID),
    );

    return [...existingHits, ...filteredNewHits];
};

export default mergeNewSearchHits;
