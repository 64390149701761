import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import EmptyChatWEBP from '@app/assets/images/emptyChat.webp';
import EmptyChatPNG from '@app/assets/images/emptyChat.png';

import styles from './styles/emptyConversationList.cssm';

type Props = Record<any, any>;

const EmptyConversationList = () => {
    return (
        <Flex column justifyCenter alignCenter className={styles.container}>
            <picture>
                <source srcSet={EmptyChatWEBP} type="image/webp" />
                <img className={styles.chatImage} src={EmptyChatPNG} alt="Chat" />
            </picture>
            <Text.Large center>
                <Trans>No chats basis current filter selection</Trans>
            </Text.Large>
        </Flex>
    );
};

export default React.memo<Props>(EmptyConversationList);
