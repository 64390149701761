import { PropertyData } from 'strat/property';

import { PropertyDetailsSections } from './propertyDetails';

const PROPERTY_DETAILS_DISPLAYED_SECTIONS: ReadonlyArray<
    ExtractValues<typeof PropertyDetailsSections>
> = Object.freeze([
    PropertyDetailsSections.TYPE,
    PropertyDetailsSections.PURPOSE,
    PropertyDetailsSections.REFERENCE_NUMBER,
    PropertyDetailsSections.RESIDENCE_TYPE,
    PropertyDetailsSections.COMPLETION_STATUS,
    PropertyDetailsSections.FURNISHING_STATUS,
    PropertyDetailsSections.VERIFICATION_DATE,
    PropertyDetailsSections.ABSOLUTE_CREATION_DATE,
    PropertyDetailsSections.OWNERSHIP,
]);

const getPropertyDetailsDisplayedSections = (_: PropertyData) =>
    PROPERTY_DETAILS_DISPLAYED_SECTIONS;

export default getPropertyDetailsDisplayedSections;
