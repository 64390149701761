import type { MixedAd } from 'horizontal/types';

import useCleanedDescription from '../useCleanedDescription';

import type { ProductSchema } from './types';
import useOfferSchema from './useOfferSchema';
import selectCoverPhotoURL from './selectCoverPhotoURL';
import useAdExtraFieldLabel from './useAdExtraFieldLabel';

const useProductSchema = (ad?: MixedAd | null): ProductSchema | null | undefined => {
    const offerSchema = useOfferSchema(ad);
    const description = useCleanedDescription(ad);
    const brand = useAdExtraFieldLabel(ad, 'make');

    if (!ad) {
        return null;
    }

    const schema: ProductSchema = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: ad.title,
        sku: ad.externalID,
        offers: offerSchema ? [offerSchema] : [],
    };

    if (description) {
        schema.description = description;
    }

    const coverPhotoURL = selectCoverPhotoURL(ad);
    if (coverPhotoURL) {
        schema.image = coverPhotoURL;
    }

    if (brand) {
        schema.brand = brand;
    }

    return schema;
};

export default useProductSchema;
