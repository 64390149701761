import * as React from 'react';

import type { CategoryFieldNoChoices } from 'horizontal/types';

import { SecondaryPriceField } from './commonPostingFields';

const useSecondaryPriceFieldIndex = <T extends CategoryFieldNoChoices>(categoryFields: Array<T>) =>
    React.useMemo(
        () =>
            categoryFields.findIndex((field) => field.attribute === SecondaryPriceField.attribute),
        [categoryFields],
    );

export default useSecondaryPriceFieldIndex;
