// @ts-expect-error - TS2614 - Module '"@sector-labs/fe-search-redux/searchJob"' has no exported member 'SlotRulesConfig'. Did you mean to use 'import SlotRulesConfig from "@sector-labs/fe-search-redux/searchJob"' instead?
import type { SlotRulesConfig } from '@sector-labs/fe-search-redux/searchJob';
import { ExactFilter, ExclusionFilter } from '@sector-labs/fe-search-redux/filters';

type Options = {
    readonly indexName: string;
    readonly hitsPerSlot: [number, number];
    readonly carModel: string;
};

const createRelatedCarsSlottingConfig = ({
    indexName,
    hitsPerSlot,
    carModel,
}: Options): SlotRulesConfig => ({
    activeFor: indexName,
    default: {
        hitsPerSlot,
        slots: [
            {
                filters: [
                    new ExactFilter({
                        attribute: 'extraFields.model',
                        value: carModel,
                    }).serialize(),
                ],
            },
            {
                filters: [
                    new ExclusionFilter({
                        attribute: 'extraFields.model',
                        value: carModel,
                    }).serialize(),
                ],
            },
        ],
    },
});

export default createRelatedCarsSlottingConfig;
