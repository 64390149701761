//@ts-nocheck
export default Object.freeze({
    platform: {
        IPHONE: 'iPhone',
        ANDROID: 'Android',
    },
    os: {
        OSX: 'OS X',
        LINUX: 'Linux',
    },
    browser: {
        SAFARI: 'Safari',
    },
});
