import settings from '@app/branding/settings';

import type { MixedAd, Agency } from 'horizontal/types';

const useAdAgency = (ad?: MixedAd | null): Agency | null | undefined => {
    if (!settings.disableAgency && ad?.agency && (!('active' in ad.agency) || ad.agency?.active)) {
        return ad.agency;
    }
    return null;
};

export default useAdAgency;
