import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import type { I18n } from '@lingui/core';

import { withI18n } from 'strat/i18n/language/withI18n';
import { Currency } from 'strat/i18n/money';
import { localesFromLanguage } from 'strat/i18n/language/locales';
import { selectNumberFormatterFactory } from 'strat/i18n/language/selectors';

import { type NumberFormatterFactory } from '../language';

import {
    formatPrice as formatPriceBase,
    formatLocalPriceValue as formatLocalPriceValueBase,
    toLocalPrice as toLocalPriceBase,
} from './localPriceFormatting';
import convertPrice from './convertPrice';
import { selectCurrentCurrency } from './selectors';

/**
 * Props for {@see LocalPriceConnector}.
 */
type Props = {
    exchangeRates: any;
    baseCurrency: string;
    currency: string;
    i18n: I18n;
    intlNumberFormat: NumberFormatterFactory;
};

export type ConnectedLocalPriceProps = {
    currencyAcronym: string;
    formatPrice: (value: number, currency: string, options: Record<string, string>) => string;
    formatPriceValue: (value: number, options: Record<string, string>) => string;
    formatLocalPriceValue: (value: number, options: Record<string, string>) => string;
    fromLocalPrice: (value: number) => number;
    toLocalPrice: (value: number) => number;
};

/**
 * This is deprecated, please use {@see useLocalPrice}
 *
 * Connects local currency/price information into the wrapped
 * component, allowing it to convert to/from the configured currency.
 */
export default <T extends Props>(component: React.ComponentType<T>) => {
    class LocalPriceConnector extends React.Component<T> {
        constructor(props: T) {
            super(props);
            autoBind(this);
        }

        /**
         * Formats the specified price, observing the
         * base currency's rules for formatting.
         */
        formatPrice(
            value: number,
            currency: string = this.props.baseCurrency,
            options: any = {},
        ): string {
            return formatPriceBase(value, currency, options, this.props.intlNumberFormat);
        }

        /**
         * Formats the specified price, but without the currency name
         * or currency symbol, observing the base currency's rules for formatting.
         */
        formatPriceValue(value: number, currency: string = this.props.baseCurrency): string {
            return this.formatPrice(value, currency, { style: 'decimal' });
        }

        /**
         * Formats the specified price, but without the currency
         * name or currency symbol, observing the current currency's
         * rules for formatting.
         */
        formatLocalPriceValue(value: number, options?: any): string {
            return formatLocalPriceValueBase(
                value,
                this.props.currency,
                this.props.intlNumberFormat,
                options,
            );
        }

        /**
         * Converts the specified price, noted in the current
         * currency into a valid price in the base currency with no more than 2 decimals.
         */
        fromLocalPrice(value: number): number {
            return (
                Math.round(
                    convertPrice({
                        price: value,
                        from: this.props.currency,
                        to: this.props.baseCurrency,
                        exchangeRates: this.props.exchangeRates,
                    }) * 100,
                ) / 100
            );
        }

        /**
         * Converts the specified price, noted in the base
         * currency into a valid price in the local currency.
         *
         * If fixRoundTripping, round away single-digit rounding errors due to conversions
         * like 1000000 => base currency => 1000001
         */
        toLocalPrice(value: number, from = null, to = null, fixRoundTripping = false): number {
            const fromCurrency = from || this.props.baseCurrency;
            const toCurrency = to || this.props.currency;

            const convertedValue = toLocalPriceBase(
                value,
                fromCurrency,
                toCurrency,
                this.props.exchangeRates,
            );

            if (fixRoundTripping && fromCurrency !== toCurrency && convertedValue >= 9999) {
                const roundedValue = Math.round(convertedValue * 0.1) * 10;
                if (Math.abs(roundedValue - convertedValue) <= 1) {
                    return roundedValue;
                }
            }
            return convertedValue;
        }

        /**
         * Renders the wrapped component.
         */
        render(): any {
            const { i18n } = this.props;
            const props = {
                ...this.props,
                currencyAcronym: Currency.acronym(i18n, this.props.currency),
                formatPrice: this.formatPrice,
                formatPriceValue: this.formatPriceValue,
                formatLocalPriceValue: this.formatLocalPriceValue,
                fromLocalPrice: this.fromLocalPrice,
                toLocalPrice: this.toLocalPrice,
            } as const;

            return React.createElement(component, props);
        }
    }

    return connect((state: any) => ({
        exchangeRates: state.i18n.money.exchangeRates,
        baseCurrency: state.i18n.money.baseCurrency,
        currency: selectCurrentCurrency(state),
        locales: localesFromLanguage(state.i18n.language),
        intlNumberFormat: selectNumberFormatterFactory(state),
        // @ts-expect-error
    }))(withI18n()(LocalPriceConnector));
};
