import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import EMPTY_ARRAY from 'strat/empty/array';
import { LoadingAnimation, Text } from 'strat/components';

import type { FlatCategoryField } from 'horizontal/types';
import { trackFilterChange } from 'horizontal/gtm';
import { SearchLink, useCurrentSearchRouteParams } from 'horizontal/search/routing';
import useRefineFilter from '@app/search/filters/useRefineFilter';

import refineSearchRouteParams from '../routing/refineSearchRouteParams';

import useFilter from './useFilter';
import useFacetedChoices from './useFacetedChoices';
import useDisplayPriorityFilteredChoices from './useDisplayPriorityFilteredChoices';
import Expandable from './expandable';
import styles from './styles/headerFilter.cssm';

type Props = {
    readonly title?: React.ReactNode;
    readonly field: FlatCategoryField;
    readonly loading: boolean;
};

const SearchHeaderFilter = ({ title, field, loading }: Props) => {
    const i18n = useI18n();
    const filter: any = useFilter(field, EMPTY_ARRAY);
    const choices: any[] = useDisplayPriorityFilteredChoices(
        useFacetedChoices(field, { showChoicesWithNoFacets: true }),
    );
    const refine = useRefineFilter(field.attribute);
    const currentSearchParams = useCurrentSearchRouteParams();

    const areAllOptionsSelected = !loading
        ? filter?.value?.sort()?.toString() ===
          choices
              ?.map((choice) => choice.value)
              ?.sort()
              ?.toString()
        : false;

    const isSeparatorHidden = (choices: any[], position: number) => {
        if (position === 0) {
            return !!(
                filter?.value?.includes(choices[position].value) ||
                !filter?.value?.length ||
                areAllOptionsSelected
            );
        }

        return (
            (filter?.value?.includes(choices[position - 1]?.value) ||
                filter?.value?.includes(choices[position]?.value)) &&
            !areAllOptionsSelected
        );
    };

    const newParams = React.useCallback(
        (value) => ({
            extraFields: { [field.attribute]: filter.value !== value ? value : null },
        }),
        [filter.value, field.attribute],
    );

    const maxLabelLength = Math.max(
        ...choices.map((choice) => {
            return choice?.label?.length;
        }),
    );

    return loading ? (
        <LoadingAnimation className={styles.loadingAnimation} />
    ) : (
        <Expandable className={styles.headerFilterContainer} ariaLabel="Search header filter">
            {title && <Text.Large className={styles.title}>{title}</Text.Large>}
            <div className={styles.choicesContainer}>
                <span
                    style={{ width: `${maxLabelLength}ch` }}
                    className={classNames(styles.headerFilter, {
                        [styles.filterSelected]: !filter?.value?.length || areAllOptionsSelected,
                    })}
                    onClick={() => refine('')}
                >
                    {t(i18n)`All`}
                </span>
                {choices.map((choice, index) => (
                    <React.Fragment key={choice?.value}>
                        <div
                            className={classNames(styles.separator, {
                                [styles.hidden]: isSeparatorHidden(choices, index),
                            })}
                        />
                        <SearchLink
                            key={choice?.value}
                            params={refineSearchRouteParams(
                                currentSearchParams,
                                newParams(choice?.value),
                            )}
                            onClick={() => trackFilterChange(field.attribute, choice?.value)}
                            className={classNames(styles.headerFilter, {
                                [styles.filterSelected]:
                                    filter?.value?.includes(choice?.value) &&
                                    !areAllOptionsSelected,
                            })}
                        >
                            <span
                                style={{ width: `${maxLabelLength}ch` }}
                                className={styles.labelContainer}
                            >
                                {choice?.label}
                            </span>
                        </SearchLink>
                    </React.Fragment>
                ))}
            </div>
        </Expandable>
    );
};

export default SearchHeaderFilter;
