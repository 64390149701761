import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveUser } from 'strat/user/session';
import { useI18n } from 'strat/i18n/language';
import { LoadingSpinner } from 'strat/loadable';
import EMPTY_OBJECT from 'strat/empty/object';
import { Flex, Text, DialogWithHeader } from 'strat/components';

import { fetchAddressBook } from 'horizontal/userOrders/addresses/state';
import { useAddressDialogDetails } from 'horizontal/userOrders/hooks';
import IconPlusCircle from 'horizontal/assets/icons/iconCirclePlus.svg';
import type { AppDispatch } from 'horizontal/state';

import { AddressFormActions } from '../types';

import { selectAddressBook, selectAddressBookLoading } from './selectors';
import AddressBook from './addressBook';
import styles from './styles/addresses.cssm';
import AddressItem from './addressItem';
import { changeAddressInfo } from './AddressBookActions';
import EmptyAddressBook from './emptyAddressBook';
import ConfirmActionDialog from './actionConfirmDialog';

function Addresses() {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector(selectActiveUser);
    const addressBook = useSelector(selectAddressBook);
    const loading = useSelector(selectAddressBookLoading);
    const userExternalID = activeUser?.externalID;
    const [visible, setVisible] = React.useState(false);
    const [confirmDialogStatus, setConfirmDialogStatus] = React.useState(false);
    const [currentAction, setAddressAction] = React.useState('');
    const [selectedAddress, setSelectedAddress] = React.useState({});
    const dialogDetails = useAddressDialogDetails(currentAction, i18n);

    const resetStatus = () => {
        setConfirmDialogStatus(false);
        setAddressAction('');
        setSelectedAddress(EMPTY_OBJECT);
        setVisible(false);
    };

    const getAddressList = React.useCallback(() => {
        dispatch(fetchAddressBook({ userExternalID }));
        resetStatus();
    }, [dispatch, userExternalID]);

    const proceedWithAction = React.useCallback(
        (address, action) => {
            if (userExternalID) {
                changeAddressInfo(address, userExternalID, action, getAddressList);
            }
        },
        [getAddressList, userExternalID],
    );

    React.useEffect(() => {
        if (currentAction === AddressFormActions.EDIT) {
            setVisible(true);
        } else if (
            currentAction === AddressFormActions.DELETE ||
            currentAction === AddressFormActions.DEFAULT
        ) {
            setConfirmDialogStatus(true);
        }
    }, [currentAction]);

    React.useEffect(() => {
        dispatch(fetchAddressBook({ userExternalID }));
    }, [dispatch, userExternalID]);

    return (
        <Flex stretchWidth column className={styles.container}>
            <DialogWithHeader
                visible={visible}
                onVisibilityChanged={setVisible}
                title={
                    currentAction === AddressFormActions.EDIT
                        ? t(i18n)`Edit an Address`
                        : t(i18n)`Add a new Address`
                }
                onBack={resetStatus}
                className={styles.dialogContainer}
            >
                <AddressBook
                    closeDialog={resetStatus}
                    editAddressValues={selectedAddress}
                    getAddressList={getAddressList}
                />
            </DialogWithHeader>
            {confirmDialogStatus && (
                <ConfirmActionDialog
                    dialogDetails={dialogDetails}
                    resetStatus={resetStatus}
                    setConfirmDialogStatus={setConfirmDialogStatus}
                    proceedWithAction={proceedWithAction}
                    selectedAddress={selectedAddress}
                    currentAction={currentAction}
                />
            )}

            <Flex stretchWidth className={styles.title}>
                <Text.XLarge bold>
                    <Trans>Address information</Trans>
                </Text.XLarge>
            </Flex>
            <Flex column className={styles.mainSection}>
                <Flex className={styles.actions}>
                    <Text.Regular bold block>
                        <Trans>Customer Address</Trans>
                    </Text.Regular>
                    <div className={styles.addAddress} onClick={() => setVisible(true)}>
                        <IconPlusCircle />
                        <p>{t(i18n)`Add Address`}</p>
                    </div>
                </Flex>
                {!!addressBook &&
                    addressBook.length > 0 &&
                    !loading &&
                    addressBook.map((address) => {
                        return (
                            <AddressItem
                                address={address}
                                key={address.id}
                                setAddressAction={setAddressAction}
                                setSelectedAddress={setSelectedAddress}
                                selectedAddress={selectedAddress}
                            />
                        );
                    })}
                {loading && <LoadingSpinner />}
                {!loading && !addressBook?.length && <EmptyAddressBook />}
            </Flex>
        </Flex>
    );
}

export default Addresses;
