import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Drawer } from 'strat/modal/compact';
import Scroll from 'strat/util/scroll';
import DialogNames from 'strat/dialogs/dialogNames';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import IconCheck from '@app/assets/icons/iconCheck.svg';
import { Button, ListEntry, Text, BackButton, Header, InputContainer } from 'strat/components';
import type { ChoiceValue, Choice } from 'strat/components/types';

import useMultiSelectSlideInSelectedText from './useMultiSelectSlideInSelectedText';
import styles from './styles/multiSelectSlideIn.cssm';

const RenderSelectedTick = () => (
    <div className={styles.iconTick}>
        <IconCheck />
    </div>
);

const SCROLL_KEY_NAME = 'multi-select-slide-in';

type Props = {
    readonly title?: string;
    readonly choices: Array<Choice<ChoiceValue>>;
    readonly selectedValues: Set<ChoiceValue>;
    readonly onChange: (arg1: Set<ChoiceValue>) => void;
    readonly readOnly?: boolean;
    readonly boldLabel?: boolean;
};

const MultiSelectSlideIn = ({
    title,
    choices,
    selectedValues,
    onChange,
    readOnly,
    boldLabel,
}: Props) => {
    const i18n = useI18n();
    const [modalVisible, setModalVisibility] = React.useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const triggerText = useMultiSelectSlideInSelectedText(choices, selectedValues);

    const onSelect = React.useCallback(
        (option) => {
            if (!onChange) {
                return;
            }
            const newValues = new Set(selectedValues);
            if (selectedValues.has(option)) {
                newValues.delete(option);
                onChange(newValues);
                return;
            }
            newValues.add(option);
            onChange(newValues);
        },
        [selectedValues, onChange],
    );

    const onOpen = React.useCallback(() => {
        if (readOnly) {
            return;
        }
        Scroll.disable(SCROLL_KEY_NAME);
        setIsFullScreen(true);
        setModalVisibility(true);
    }, [readOnly]);

    const onBack = () => {
        Scroll.enable(SCROLL_KEY_NAME);
        setIsFullScreen(false);
        setModalVisibility(false);
    };

    const isSelected = React.useCallback(
        (choice) => selectedValues.has(choice.value),
        [selectedValues],
    );

    const onClear = React.useCallback(() => {
        onChange?.(new Set());
    }, [onChange]);

    const shouldBeAccepted = selectedValues.size !== 0;

    return (
        <div>
            <InputContainer
                title={title}
                accepted={shouldBeAccepted}
                readOnly={readOnly}
                boldLabel={boldLabel}
            >
                <div onClick={onOpen} className={styles.buttonContainer}>
                    <Text.Base className={styles.triggerText}>{triggerText}</Text.Base>
                    <div className={styles.arrowRightContainer}>
                        <IconArrowRight className={styles.arrowRight} />
                    </div>
                </div>
            </InputContainer>
            <Drawer
                className={styles.modalStyle}
                name={DialogNames.MULTI_SELECT_COMPACT_DIALOG}
                visible={modalVisible}
                onVisibilityChanged={setModalVisibility}
                renderHeader={() => (
                    <Header className={styles.header}>
                        <BackButton onBack={onBack} />
                        <Text.Base className={styles.headerTitle} bold>
                            {title}
                        </Text.Base>
                        <div
                            className={selectedValues.size === 0 ? styles.clearDisabled : ''}
                            onClick={onClear}
                            role="button"
                        >
                            <Text.Regular bold>{t(i18n)`Clear`}</Text.Regular>
                        </div>
                    </Header>
                )}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
            >
                <div className={styles.choices}>
                    {choices.map((choice) => (
                        <div key={choice.value} className={styles.choice}>
                            <ListEntry
                                renderRight={isSelected(choice) ? RenderSelectedTick : null}
                                onClick={() => onSelect(choice.value)}
                            >
                                <Text.Regular>{choice.label}</Text.Regular>
                            </ListEntry>
                        </div>
                    ))}
                </div>
                <div className={styles.footer}>
                    <Button disabled={!shouldBeAccepted} onClick={onBack} stretch>
                        {t(i18n)`Apply`}
                    </Button>
                </div>
            </Drawer>
        </div>
    );
};

export default MultiSelectSlideIn;
