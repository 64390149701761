import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { StopEventsPropagation } from 'strat/util';

/**
 * Properties for {@see OffScreen}.
 */
type Props = {
    readonly name: string;
    readonly children: React.ReactNode;
    readonly className?: string;
};

/**
 * Renders its content in a magical place far away where it doesn't
 * interfere with the rest of the content on the page.
 */
class OffScreen extends Component<Props> {
    /**
     * DOM node in which this component is rendered into.
     */
    domContainer: null | HTMLElement;

    /**
     * Body wrapper DOM node in which all the dialogs should render.
     */
    rootContainer: null | HTMLElement;

    /**
     * Initializes a new instance of {@see OffScreen}.
     */
    constructor(props: Props) {
        super(props);

        this.domContainer = null;
        this.rootContainer = null;
    }

    /**
     * Gets the DOM container in which to render the portal.
     */
    container() {
        if (this.domContainer) {
            return this.domContainer;
        }

        this.domContainer = document.getElementById(this.containerName());
        if (!this.domContainer) {
            this.domContainer = document.createElement('div');
            this.domContainer.setAttribute('id', this.containerName());
            if (this.props.className) {
                this.domContainer.classList.add(this.props.className);
            }
        }

        return this.domContainer;
    }

    /**
     * Gets the unique name of the container
     * the React portal will render into.
     */
    containerName(): string {
        return `strat-${this.props.name}-container`;
    }

    /**
     * Unmounts the container when this component unmounts.
     */
    componentWillUnmount(): void {
        if (!this.domContainer || !this.rootContainer) {
            return;
        }

        if (!this.rootContainer.contains(this.domContainer)) {
            return;
        }

        if (this.rootContainer && this.domContainer) {
            this.rootContainer.removeChild(this.domContainer);
        }
    }

    componentDidMount(): void {
        this.rootContainer = document.getElementById('body-wrapper');
        if (this.rootContainer) {
            this.rootContainer.appendChild(this.container());
        }
    }

    /** Prevents events bubbling through portal in the dom tree
     * https://reactjs.org/docs/portals.html#event-bubbling-through-portals */
    offScreenContent() {
        return <StopEventsPropagation>{this.props.children}</StopEventsPropagation>;
    }

    /**
     * Renders nothing, actual rendering happens in a off-page
     * container that is rendered into in {@see componentDidUpdate}.
     */
    render() {
        if (process.env.IS_SERVER) {
            return null;
        }

        return ReactDOM.createPortal(this.offScreenContent(), this.container());
    }
}

export default OffScreen;
