import { t } from '@lingui/macro';
import * as React from 'react';
import type { CancelablePromise } from 'strat/util';
import { ProductType } from '@app/types';
import settings from '@app/branding/settings';
import { I18n } from '@lingui/core';

export type ProductPackageOffer = {
    readonly external_id: string;
    readonly quantity?: number;
    readonly ad_id?: number;
};

export type PaymentProducts = Array<ProductPackageOffer>;

export const PaymentOrderPlatform = Object.freeze({
    WEB: 'web',
    TELESALES: 'telesales',
});

export type PaymentOrderPayload = {
    readonly product_package_offers: PaymentProducts;
    readonly platform: Values<typeof PaymentOrderPlatform>;
};

export type PayWhatYouWantOrderPayload = {
    readonly product_package_offers: PaymentProducts;
    readonly platform: Values<typeof PaymentOrderPlatform>;
    readonly total: number;
};

export type CheckoutHostedPaymentsPayload = {
    readonly order_external_id: string;
    readonly success_url: string;
    readonly cancel_url: string;
    readonly failure_url: string;
};

export type CheckoutPaymentsPayload = {
    readonly instrument_id: string;
    readonly order_external_id: string;
};

export type EasyPaisaHostedPaymentsPayload = {
    readonly orderExternalID: string;
    readonly amount: number;
    readonly postBackURL: string;
};

export const PaymentSource = Object.freeze({
    AREEBA: 'areeba',
    AREEBA_CARD_USD: 'areeba_card_usd',
    CHECKOUT: 'checkout',
    JAZZ_CASH_VOUCHER: 'jazz_cash_voucher',
    JAZZ_CASH_MOBILE_WALLET: 'jazz_cash_mobile_wallet',
    BANK_TRANSFER: 'bank_transfer',
    PLUTUS: 'plutus',
    BEE: 'bee',
    FAWRY: 'fawry',
    TPAY: 'tpay',
    WALLET: 'wallet',
    ADMIN_TOOL: 'admin_tool',
    FAWRY_PAY_REFERENCE_NUMBER: 'fawry_pay_reference_number',
    PAYMOB_WALLET: 'paymob_wallet',
    PAYMOB: 'paymob',
    PAYMOB_BANK_INSTALLMENT: 'paymob_bank_installment',
    CASH: 'cash',
    WHISH: 'whish',
    OMT: 'omt',
    EASYPAISA: 'easypaisa',
    VODAFONE_CASH: 'vodafone_cash',
    ORANGE_CASH: 'orange_cash',
    ETISALAT_CASH: 'etisalat_cash',
    BANK_WALLET: 'bank_wallet',
    CASH_UNITED: 'cash_united',

    text(i18n: I18n, paymentSource: any) {
        const brandName = settings.getBrandName(i18n);
        switch (paymentSource) {
            case this.AREEBA:
                return t(i18n)`AREEBA (LBP)`;
            case this.AREEBA_CARD_USD:
                return t(i18n)`AREEBA (USD)`;
            case this.CHECKOUT:
                return t(i18n)`Checkout`;
            case this.JAZZ_CASH_VOUCHER:
                return t(i18n)`Jazz cash voucher`;
            case this.JAZZ_CASH_MOBILE_WALLET:
                return t(i18n)`Jazz cash mobile wallet`;
            case this.PLUTUS:
                return t(i18n)`Plutus`;
            case this.BEE:
                return t(i18n)`BEE`;
            case this.FAWRY:
                return t(i18n)`FAWRY`;
            case this.TPAY:
                return t(i18n)`TPAY`;
            case this.WALLET:
                return t(i18n)`${brandName} Wallet`;
            case this.ADMIN_TOOL:
                return t(i18n)`Admin tool`;
            case this.PAYMOB_WALLET:
                return t(i18n)`Paymob Wallet`;
            case this.PAYMOB:
                return t(i18n)`Paymob`;
            case this.CASH:
                return t(i18n)`Cash`;
            case this.VODAFONE_CASH:
                return t(i18n)`Vodafone Cash`;
            case this.ORANGE_CASH:
                return t(i18n)`Orange Cash`;
            case this.ETISALAT_CASH:
                return t(i18n)`Etisalat Cash`;
            case this.BANK_WALLET:
                return t(i18n)`Bank Wallet`;
            case this.CASH_UNITED:
                return t(i18n)`Cash United`;
            case this.OMT:
                return t(i18n)`OMT`;
            default:
                return paymentSource;
        }
    },
});

export const DiscountTypes = Object.freeze({
    PROVIDER_CASHBACK: 'provider_cashback',
    PAYMENT_AMOUNT_DEDUCTION: 'payment_amount_deduction',
});

export const AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE = 'free-ad-commission';

export type PaymentSourceType = Values<typeof PaymentSource>;

export type BoughtProductPackage = {
    id: number;
    name: string;
    currentPrice: number;
    quantity: number;
};

export type PaymentDiscount = {
    readonly name: string;
    readonly discountPercent: number;
    readonly description: string;
    readonly discountType: string;
};

export type Payment = {
    id: number;
    createdAt: number;
    source: Values<typeof PaymentSource>;
    total: number;
    items: Array<BoughtProductPackage>;
    orderExternalID?: string;

    status?: Values<typeof PaymentStatus>;
};

export const PaymentMethodRoles = Object.freeze({
    ALLOW_PARTIAL_PAYMENT: 'allow_wallet_partial_payments',
});

export type LitePaymentMethodDetails = {
    name: string;
    description: string;
    source: Values<typeof PaymentSource>;
    readonly discount?: PaymentDiscount | null | undefined;
    readonly roles?: Array<string>;
};

export type PaymentMethodDetails = LitePaymentMethodDetails & {
    renderComponent?: (arg1?: any, arg2?: any) => React.ReactElement;
    renderIcon: (arg1: string) => React.ReactElement;
};

export const JazzCashPaymentMethod = Object.freeze({
    MOBILE_WALLET: 'MWALLET',
    VOUCHER: 'OTC',
});

export type JazzCashMobilePaymentPayload = {
    readonly orderExternalID: string;
    readonly phoneNumber: string;
    readonly cnic: string;
};

export type JazzCashPayloadNotification = {
    readonly pp_TxnType: string;
    readonly pp_TxnRefNo: string;
    readonly pp_RetrievalReferenceNo: string;
    readonly pp_SecureHash: string;
    readonly pp_ResponseCode: string;
};

export enum JazzCashResponseCode {
    SUCCESS = '000',
    INSUFFICIENT_FUNDS = '004',
    VOUCHER_PENDING = '124',
    MWALLET_PENDING = '157',
    AUTHORIZATION_PENDING = '210',
    INVALID_VALUE = '110',
    NONEXISTENT_ACCOUNT = '156',
}

export const CheckoutResponseCode = Object.freeze({
    RISK_ENGINE_LOWEST_CODE: 40000,
    RISK_ENGINE_HIGHEST_CODE: 49999,
});

export const PaymentStatus = Object.freeze({
    CAPTURED: 'captured',
    PENDING: 'pending',
    DECLINED: 'declined',
    PENDING_AT_PROVIDER: 'pending_at_provider',
    FAILURE: 'failure',
    // Payment for a product bought for an ad, but the ad was rejected meanwhile
    AD_REJECTED: 'adRejected',
    // When a user tries to make a payment and there is missing information on the user profile that need to be filled
    MISSING_INFO: 'missingInfo',
});

export const PaymentOrderStatus = Object.freeze({
    PENDING: 'pending',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    SUCCESS: 'success',
});
export const PaymentOrderRoles = Object.freeze({
    PARTIAL_PAYMENT: 'partial_payments',
});

export type PaymentProductItem = {
    readonly duration: number;
    readonly frequency: number | null;
    readonly frequencyTimeUnit: string;
    readonly id: number;

    readonly quantity: number;

    readonly type: Values<typeof ProductType>;
};
export type PaymentProductPackage = {
    readonly description: string;
    readonly name: string;
    readonly validFor: number;
    readonly valueProposition: string | null | undefined;
    readonly productsQuantity: number;
    readonly productItems: Array<PaymentProductItem>;
};
export type PaymentProductPackageOffer = {
    readonly cartQuantity: number;
    readonly discountPrice: number | null;
    readonly grossPrice: number;

    readonly price: number;
    readonly packageOfferID: string;
    readonly productPackage: PaymentProductPackage;
};
export type PaymentOrder = {
    readonly externalID: string;
    readonly status: Values<typeof PaymentOrderStatus>;
    readonly productPackageNames: string;
    readonly total: number;
    readonly grossTotal: number;
    readonly productIDs: Array<string>;
    readonly adExternalID: string | null | undefined;
    readonly latestPaymentResponseSummary: string | null | undefined;
    readonly latestPaymentResponseCode: string | null | undefined;
    readonly latestPaymentMethod: string | null | undefined;
    readonly paymentMethods: Array<Values<typeof PaymentSource>> | null | undefined;
    readonly categoryExternalID: string | null | undefined;
    readonly productCategoryExternalID: string | null | undefined;
    readonly roles: Array<Values<typeof PaymentOrderRoles>> | null | undefined;
    readonly payments?: Array<Payment>;
    // actually type below is CartPackage
    readonly offers: Array<PaymentProductPackageOffer>;

    readonly createdAt: number;
};

export type AreebaPaymentPayload = {
    readonly sessionID: string;
    readonly gatewaySessionID: string;
    readonly language: string;
};

export const FawryPaymentMethod = Object.freeze({
    REFERENCE_NUMBER: 'reference_number',
});

export type FawryPaymentPayload = {
    readonly orderExternalId: string;
    readonly paymentMethod: Values<typeof FawryPaymentMethod>;
};

export type PaymentHookParams = {
    readonly order?: PaymentOrder;
    readonly setIsLoading: (arg1: boolean) => void;
    readonly setPaymentPromise: (arg1?: CancelablePromise | null) => void;
};

export type ProviderCashPaymentPayload = {
    readonly paymentSource: Values<typeof PaymentSource>;
};

export type CheckoutCreditCard = {
    readonly id: string;
    readonly last4: string;
    readonly expiryMonth: string;
    readonly expiryYear: string;
    readonly scheme: string;
};

export type PaymentMethodSelectedType = {
    source: PaymentSourceType;
    card?: CheckoutCreditCard;
    name?: string;
};
