import React from 'react';
import settings from '@app/branding/settings';
import Category from '@app/branding/category';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';
import useRouter from 'react-true-router/useRouter';
import { useActiveUser } from 'strat/user/session';
import { RouteNames } from 'strat/routes';

import { useAd, useAdIsLoading } from 'horizontal/ad/state';
import { AdFormat } from 'horizontal/types';
import {
    useFilteredRelatedAds,
    useFilteredRelatedAdsFilters,
    useGenericRelatedAds,
    useGenericRelatedAdsFilters,
} from 'horizontal/adDetails/relatedAds';
import { useLocalizedAdDescription } from 'horizontal/ad';
import { isAdEditor } from 'horizontal/util';
import { useFullLocationsHierarchy } from 'horizontal/location';

import useAdFeatures from './useAdFeatures';
import useFilteredAdDetails, { useAdHighlightedDetails } from './useAdDetails';
import { RelatedAdsSection, Section } from './section';
import {
    Description,
    Details,
    Features,
    HighlightedDetails,
    LoadingDescription,
    LocationAndId,
    Overview,
} from './components';
import styles from './styles/adInfo.cssm';

const AdInfo = () => {
    const ad = useAd();
    const user = useActiveUser();
    const router = useRouter();
    const loading = useAdIsLoading();
    const highlightedFields = useAdHighlightedDetails(ad);
    const detailsFormattedFields = useFilteredAdDetails(ad);
    const featuresFormattedFields = useAdFeatures(ad);
    const adCategory = ad?.category[ad.category.length - 1];
    const genericRelatedAds = useGenericRelatedAds();
    const genericRelatedAdsFilters = useGenericRelatedAdsFilters();
    const filteredRelatedAds = useFilteredRelatedAds();
    const filteredAdsFilters = useFilteredRelatedAdsFilters();
    const localizedAdDescription = useLocalizedAdDescription(ad);
    const isOwnAd = ad && user && isAdEditor(ad, user.externalID);
    const fullLocations = useFullLocationsHierarchy(ad?.location || []);

    if (!ad) {
        return null;
    }
    return (
        <>
            <Section label="Overview">
                <Overview ad={ad} />
            </Section>

            {!!highlightedFields.length && (
                <>
                    <Section>
                        <Text.Regular bold className={styles.highlightsLabel}>
                            <Trans>Highlights</Trans>
                        </Text.Regular>
                        <Flex aria-label="Highlighted Details" justifySpaceAround>
                            <HighlightedDetails fields={highlightedFields} ad={ad} />
                        </Flex>
                    </Section>
                </>
            )}
            {!!detailsFormattedFields.length && (
                <Section label="Details">
                    <Details formattedExtraFields={detailsFormattedFields} />
                </Section>
            )}
            {!!featuresFormattedFields.length && (
                <Section label="Features">
                    {featuresFormattedFields.map((featureField, index) => (
                        <Features
                            key={index}
                            formattedExtraField={featureField}
                            isProperty={!!Category.isOfPropertySubtype(adCategory)}
                        />
                    ))}
                </Section>
            )}
            {ad.format === AdFormat.FULL && !!localizedAdDescription && (
                <Section label="Description">
                    <Description description={localizedAdDescription} />
                </Section>
            )}
            {loading && <LoadingDescription />}
            {settings.enableAdDetailsMap && (
                <LocationAndId ad={ad} isOwnAd={isOwnAd} location={fullLocations} />
            )}
            {!!genericRelatedAds.length && (
                <RelatedAdsSection
                    relatedAds={genericRelatedAds}
                    category={adCategory}
                    seeMoreLimit={15}
                    handleSeeMore={() =>
                        router.pushRoute(RouteNames.SEARCH, genericRelatedAdsFilters)
                    }
                />
            )}
            {!!filteredRelatedAds.length && (
                <RelatedAdsSection
                    relatedAds={filteredRelatedAds}
                    category={adCategory}
                    seeMoreLimit={15}
                    handleSeeMore={() => router.pushRoute(RouteNames.SEARCH, filteredAdsFilters)}
                    isUsed
                />
            )}
        </>
    );
};
export default AdInfo;
