import { t } from '@lingui/macro';
import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import { I18n } from '@lingui/core';

import { withI18n } from 'strat/i18n/language/withI18n';
import mapPin from '@app/assets/icons/iconNeighborhoodPin.svg';
import { LocationNodeData } from 'strat/property/types';

import withPropertyGeography from './withPropertyGeography';
import styles from './styles/propertyLocationFallback.cssm';

type ProvidedProps = {
    pin: React.ReactElement<any>;
    markerText: React.ReactElement<any> | string;
};

const withPropertyLocationFallback = <T extends any>(
    component: Flow.AbstractComponent<any>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'ProvidedProps'.
): Flow.AbstractComponent<Flow.Diff<T, ProvidedProps>> => {
    const LocationFallback = ({
        i18n,
        hasExactGeography,
        location,
        ...props
    }: {
        i18n: I18n;
        hasExactGeography: boolean;
        location: LocationNodeData;
    }) =>
        React.createElement(component, {
            ...props,
            ...(!hasExactGeography && {
                pin: mapPin,
                markerText: (
                    <div className={styles.popup}>
                        <span>{t(i18n)`Exact property location unavailable.`}</span>
                        <span>{t(i18n)`Showing location for ${location}.`}</span>
                    </div>
                ),
            }),
        });

    // @ts-expect-error - TS2322 - Type 'ComponentType<Omit<withI18nProps, "i18n">>' is not assignable to type 'AbstractComponent<Diff<T, ProvidedProps>, any>'. | TS2345 - Argument of type 'AbstractComponent<Diff<unknown, ProvidedProps>, any>' is not assignable to parameter of type 'ComponentType<withI18nProps>'.
    return withI18n()(withPropertyGeography(LocationFallback));
};

export default withPropertyLocationFallback;
