import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { TemplateType } from 'strat/templates';

import type { MixedAd } from 'horizontal/types';
import { selectCustomMetaDescription } from 'horizontal/urlSpecificSEO/selectors';

import useCleanedDescription from './useCleanedDescription';
import useAdDetailTemplate from './useAdDetailTemplate';

const useMetaDescription = (ad?: MixedAd | null): string => {
    const i18n = useI18n();
    const description = useCleanedDescription(ad) || ad?.title;
    const customMetaDescription = useSelector(selectCustomMetaDescription);
    const renderedTemplate = useAdDetailTemplate(TemplateType.META, ad);

    if (!ad || !description) {
        return '';
    }

    // a custom description takes precedence over a renderedTemplate, which in turn takes precedence over the default naming scheme
    if (customMetaDescription) {
        return customMetaDescription;
    }
    if (renderedTemplate) {
        return renderedTemplate;
    }

    const categoryName = ad.category.slice(-1)[0]?.name;

    if (categoryName) {
        return t(i18n)`${description} - ${categoryName}`;
    }

    return description;
};

export default useMetaDescription;
