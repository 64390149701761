import * as React from 'react';
import Logo from '@app/branding/logo';
import { PageWidthWrapper } from 'strat/generic';
import { useDialog } from 'strat/dialogs/dialogContext';
import { BurgerButton } from 'strat/navigation';
import { Flex, Header as BaseHeader } from 'strat/components';
import { KeycloakGoogleOneTap } from 'strat/auth/keycloak';

import { ActiveLocationDialogTrigger } from 'horizontal/search/location/compact';
import { FreeTextSearchDialogTrigger } from 'horizontal/search/freeText/compact';
import { DialogNames } from 'horizontal/dialogs';

import ActivityIndicator from '../activityIndicator';

import styles from './styles/simpleHeader.cssm';

type Props = {
    readonly withLocationsDialog?: boolean;
    readonly withFreeTextSearchDialog?: boolean;
};

const SimpleHeader = ({ withLocationsDialog, withFreeTextSearchDialog }: Props) => {
    const [, setUserCenterDialogVisible] = useDialog(DialogNames.USER_CENTER_DIALOG);

    return (
        <BaseHeader>
            <PageWidthWrapper className={styles.content}>
                <Flex alignCenter>
                    <BurgerButton
                        className="dark"
                        onClick={() => setUserCenterDialogVisible(true)}
                    />
                    <Logo />
                </Flex>
                <Flex alignCenter className={styles.widgetContainer}>
                    <ActivityIndicator />
                    {withLocationsDialog && <ActiveLocationDialogTrigger />}
                    <KeycloakGoogleOneTap />
                </Flex>
            </PageWidthWrapper>
            {withFreeTextSearchDialog && <FreeTextSearchDialogTrigger />}
        </BaseHeader>
    );
};

export default SimpleHeader;
