import type { Dispatch } from 'redux';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { FilterValues } from 'strat/search';
import brandingSettings from '@app/branding/settings';

import { StratAPI } from 'horizontal/api';
import { setPopularSearches } from 'horizontal/sitemap/state/state';

/**
 * Fetch popular searches to be displayed on the search page.
 */

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const fetchFrequentSearches = (dispatch: Dispatch, filterCollection: FilterCollection) => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const categoryId = filterCollection.getFilterValue(FilterValues.category.attribute, {}).id;
    const location =
        filterCollection.getFilterValue(FilterValues.location.attribute) ||
        brandingSettings.topLevelLocation;

    if (
        filterCollection.filters.query?.value &&
        brandingSettings.hidePopularSearchesOnQueryFilter
    ) {
        // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'Location | null | undefined'.
        dispatch(setPopularSearches({ location, popularSearches: [] }));
        return null;
    }

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    return new StratAPI().popularSearchesList(location.id, categoryId).then((result) => {
        const { data, status } = result;
        if (status !== 200) {
            return;
        }

        // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'Location | null | undefined'.
        dispatch(setPopularSearches({ location, popularSearches: data }));
    });
};

export default fetchFrequentSearches;
