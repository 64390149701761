import React from 'react';
import { Trans } from '@lingui/macro';
import RequestDeliveryDescription from '@app/adDetails/components/requestDeliveryDescription';
import { Flex, Text, Button } from 'strat/components';

import RequestDeliveryTitle from '@app/adDetails/components/requestDeliveryTitle';
import IconDelivery from 'horizontal/assets/icons/iconDelivery.svg';
import { MixedAd, DeliveryType } from 'horizontal/types';

import useRequestDelivery from '../requestDelivery/useRequestDelivery';
import useCallToActionsSticky from '../compact/useCallToActionsSticky';
import { Section } from '../section';

import RequestSelfDeliveryTitle from './requestSelfDeliveryTitle';
import RequestSelfDeliveryDescription from './requestSelfDeliveryDescription';
import styles from './styles/requestDeliveryButton.cssm';

type Props = {
    readonly ad: MixedAd;
    readonly hideButton?: boolean;
};

const renderIcon = () => <IconDelivery className={styles.icon} />;

const RequestDeliverySection = ({ ad }: Props) => {
    const { canBuyNow, showBuyWithDeliveryOnly } = useCallToActionsSticky({ ad });

    const { isAdOrdered, redirect } = useRequestDelivery(ad);
    const showDeliveryButton = canBuyNow || showBuyWithDeliveryOnly || isAdOrdered;
    const isSelfDelivery =
        CONFIG.build.ENABLE_SELF_DELIVERY &&
        ad.deliveryInformation?.deliveryType === DeliveryType.SELF_DELIVERY;

    return (
        <Section>
            <div className={styles.title}>
                {isSelfDelivery ? <RequestSelfDeliveryTitle /> : <RequestDeliveryTitle />}
            </div>
            <div className={styles.descriptionContainer}>
                {isSelfDelivery ? (
                    <RequestSelfDeliveryDescription />
                ) : (
                    <RequestDeliveryDescription />
                )}
            </div>

            {showDeliveryButton && (
                <Button renderIcon={renderIcon} secondary stretch onClick={redirect}>
                    <Flex justifySpaceBetween alignCenter className={styles.buttonContent}>
                        <Text.Large>
                            {isAdOrdered ? (
                                <Trans>Check delivery status</Trans>
                            ) : (
                                <Trans>Buy with delivery</Trans>
                            )}
                        </Text.Large>
                    </Flex>
                </Button>
            )}
        </Section>
    );
};

export default RequestDeliverySection;
