import * as React from 'react';
import autoBind from 'react-autobind';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'react-transition-group/Transition'. 'node_modules/react-transition-group/Transition.js' implicitly has an 'any' type.
import Transition from 'react-transition-group/Transition';

import type { InlineStyle } from 'strat/types';

/**
 * Properties for {@see Toast}.
 */
type Props = {
    /**
     * How much should the toast take before fading-out.
     */
    readonly timeout: number;
    /**
     * How much should the fading-out animation take to complete.
     */
    readonly fadeOutDuration: number;
    /**
     * How much should the fading-in animation take to complete.
     */
    readonly fadeInDuration: number;
    /**
     * Callback for when the toast has been hidden.
     */
    readonly onHide: () => void;
    /**
     * The contant that should be toasted.
     */
    readonly children?: React.ReactElement;
};

type State = {
    /**
     * Whether the toaster is visible or not.
     */
    visible: boolean;
};

/**
 * Renders a toast which starts fading out after a certain amount of time.
 */
class Toast extends React.Component<Props, State> {
    static defaultProps = {
        fadeOutDuration: 500,
        fadeInDuration: 500,
        timeout: 2000,
    };

    hideTimeout: number | null = null;
    onHideTimeout: number | null = null;

    /**
     * Initializes a new instance of {@see Toast}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = { visible: true };
    }

    componentWillUnmount() {
        // Clear timeouts to avoid updating an unmounted component
        this.clearTimeout(this.hideTimeout);
        this.clearTimeout(this.onHideTimeout);
    }

    clearTimeout(timeoutId: number | null) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }
    /**
     * The styles for each transition step.
     */
    styles(): {
        [key: string]: InlineStyle;
    } {
        return {
            entering: {
                opacity: 0,
            },
            entered: {
                opacity: 1,
                transition: `opacity ${this.props.fadeInDuration}ms`,
            },
            exited: {
                opacity: 0,
                transition: `opacity ${this.props.fadeOutDuration}ms`,
            },
        };
    }

    /**
     * Hides the toaster, after the given timeout.
     */
    hide(): void {
        this.clearTimeout(this.hideTimeout);

        // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
        this.hideTimeout = setTimeout(() => this.setState({ visible: false }), this.props.timeout);
    }

    /**
     * Notifies the toaster is hidden, after the fadeOut animation is done.
     */
    onHide(): void {
        this.clearTimeout(this.onHideTimeout);

        // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'number'.
        this.onHideTimeout = setTimeout(() => this.props.onHide(), this.props.fadeOutDuration);
    }

    /**
     * Renders the toaster.
     */
    render() {
        if (!this.props.children) {
            return null;
        }

        return (
            <Transition
                in={this.state.visible}
                appear
                timeout={0}
                onEntered={this.hide}
                onExited={this.onHide}
            >
                {/* @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. */}
                {(state) => {
                    if (!this.props.children) {
                        return null;
                    }
                    return React.cloneElement(React.Children.only(this.props.children), {
                        style: {
                            ...this.styles()[state],
                        },
                        className: state,
                    });
                }}
            </Transition>
        );
    }
}

export default Toast;
