import * as React from 'react';

import SMSIcon from 'horizontal/assets/icons/sms.svg';
import type { ChatAd } from 'horizontal/chat/types';
import { PhoneNumberLinkType } from 'horizontal/contact';
import { ContactPersonRole } from 'horizontal/types';

import PhoneContactButton from './phoneContactButton';

type Props = {
    readonly className?: string;
    readonly ad: ChatAd | null | undefined;
    readonly ariaLabel?: string;
};

const SMSButton = ({ className, ad }: Props) => {
    const isShortNumber = ad?.contactInfo?.roles?.includes(ContactPersonRole.SHOW_SHORT_NUMBER);

    if (isShortNumber) {
        return null;
    }

    return (
        <PhoneContactButton
            icon={<SMSIcon className={className} />}
            linkType={PhoneNumberLinkType.SMS}
            ad={ad}
            ariaLabel={'SMS button'}
        />
    );
};

export default SMSButton;
