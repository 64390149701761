import * as React from 'react';

export enum ProjectBadgeKey {
    HANDOVER = 'handover',
    ORIGINAL_PRICE = 'original-price',
    AMOUNT_PAID = 'amount-paid',
    PERCENTAGE_PAID = 'percentage-paid',
    PAYMENT_PLAN = 'payment-plan',
    STARTING_FROM = 'starting-from',
}

export enum ProjectBadgeStyle {
    SMALL_CARD = 'small-card',
    LARGE_CARD = 'large-card',
    SMALL_CHIP = 'small-chip',
    LARGE_SEGMENTED_BAR = 'large-segmented-bar',
    SMALL_SEGMENTED_BAR = 'small-segmented-bar',
}

export type BadgeProps = {
    renderTitle: () => React.ReactNode;
    renderValue: () => React.ReactElement;
    renderInformation?: () => React.ReactElement;
    large?: boolean;
    label?: string;
};
