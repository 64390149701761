import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectCustomContent } from './selectors';
import styles from './styles/customURLSpecificContent.cssm';

const CustomURLSpecificContent = () => {
    const content = useSelector(selectCustomContent);

    return (
        <div className={styles.container}>
            {content && (
                <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            )}
        </div>
    );
};

export default CustomURLSpecificContent;
