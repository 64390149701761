import { combineReducers } from 'redux';

import { reducers as horiznotalReducers } from 'horizontal/state';
//importing from index causes circular dependency
// eslint-disable-next-line no-restricted-imports
import {
    agentProductPurchasesReducer,
    fetchAgentProductPurchasesByStatusReducer,
} from 'horizontal/agencyPortal/agentDetail/agentUsedQuotas/state';
import agentDetailsReducer from 'horizontal/agencyPortal/agentDetail/state';
import agentsReducer, { ownerAgentReducer } from 'horizontal/agencyPortal/agents/state';
import { agencyActivityLogReducer } from 'horizontal/agencyPortal/activityLog/state';
import agencyPortalSideMenuReducer from 'horizontal/agencyPortal/sideMenu/state';
import {
    agencyProductPurchases,
    productPurchaseAgentsReducer,
} from 'horizontal/agencyPortal/packages/state';
import { agencyAdsManagementReducer } from 'horizontal/agencyPortal/agencyAds/state';
import { agencyCategoryFiltersReducer } from 'horizontal/agencyPortal/agency/state';
import leadsReducer from 'horizontal/agencyPortal/leads/state';

const reducers = {
    ...horiznotalReducers,
    agencyPortal: combineReducers({
        sideMenu: agencyPortalSideMenuReducer,
        agents: agentsReducer,
        ownerAgent: ownerAgentReducer,
        // @ts-expect-error - TS2322 - Type 'FetchReducer' is not assignable to type 'Reducer<any>'.
        agencyActivityLog: agencyActivityLogReducer,
        agentProductPurchases: agentProductPurchasesReducer,
        agentProductPurchasesByStatus: fetchAgentProductPurchasesByStatusReducer,
        agencyProductPurchases: agencyProductPurchases,
        agentDetails: agentDetailsReducer,
        agencyAdsManagement: agencyAdsManagementReducer,
        agencyCategoryFilters: agencyCategoryFiltersReducer,
        productPurchaseAgents: productPurchaseAgentsReducer,
        leads: leadsReducer,
    }),
} as const;

export default reducers;
