import * as React from 'react';
import classNames from 'classnames';

import { BreadcrumbType } from './types';
import type { Breadcrumb } from './types';
import BreadcrumbList from './breadcrumbList';
import BreadcrumbListItem from './breadcrumbListItem';
import BreadcrumbLink from './breadcrumbLink';
import styles from './styles/breadcrumbsWithSlashes.cssm';

type Props = {
    readonly breadcrumbs: Array<Breadcrumb>;
    readonly scrollable?: boolean;
    readonly withShortTitles?: boolean;
};

const renderSeparator = (key: string) => (
    <li key={key} className={classNames(styles.crumbText, styles.separator)}>
        {'/'}
    </li>
);

const renderListItems = (breadcrumbs: Array<Breadcrumb>, withShortTitles?: boolean) =>
    breadcrumbs
        .filter(({ type }) => type === BreadcrumbType.LINK)
        .reduce<Array<any>>((acc, crumb, index) => {
            const separators = index !== 0 ? [renderSeparator(`sep-${index}`)] : [];

            return [
                ...acc,
                ...separators,
                <BreadcrumbListItem key={index} index={index} className={styles.crumb}>
                    <BreadcrumbLink
                        breadcrumb={crumb}
                        className={styles.crumbText}
                        withShortTitle={withShortTitles}
                    />
                </BreadcrumbListItem>,
            ];
        }, []);

const BreadcrumbsWithSlashes = ({ breadcrumbs, scrollable, withShortTitles }: Props) => {
    const children = renderListItems(breadcrumbs, withShortTitles);

    // render the children in reverse order because we use
    // flex-direction: row-reverse to make the scroll start at the end
    if (scrollable) {
        children.reverse();
    }

    return (
        <div
            className={classNames(styles.container, { [styles.scrollable]: scrollable })}
            aria-label="Breadcrumb"
        >
            <BreadcrumbList
                className={classNames(styles.list, { [styles.scrollable]: scrollable })}
            >
                {children}
            </BreadcrumbList>
        </div>
    );
};

export default React.memo<Props>(BreadcrumbsWithSlashes);
