import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';

import { BusinessPackagesFilter, BusinessPackagesHeader } from 'horizontal/businessPackages';

import PrivateNonIndexableHead from './privateNonIndexableHead';

const BusinessPackagesPage = {
    head: () => <PrivateNonIndexableHead />,
    body: () => (
        <>
            <BusinessPackagesHeader secondary />
            <PageWidthWrapper>
                <BusinessPackagesFilter />
            </PageWidthWrapper>
        </>
    ),
} as const;

export default BusinessPackagesPage;
