import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/tabSwitcher.cssm';

type Tab<T> = T & {
    name: string;
    route?: string;
    hard?: boolean;
};

export type TabRenderProps<T> = {
    tab: Tab<T>;
    className?: string;
    onClick: () => void;
};

type Props<T> = {
    activeTabIndex: number;
    setActiveTabIndex: (arg1: number) => void;
    tabs: Array<
        T & {
            name?: string;
            route?: string;
            hard?: boolean;
        }
    >;
    className?: string;
    activeClassName?: string;
    renderTabComponent?: (props: TabRenderProps<any>) => React.ReactElement;
    coloredSelectedTab?: boolean;
    withDelimiter?: boolean;
    allowMixedCase?: boolean;
};

const renderDefaultTab = <T extends any>({ tab, className, onClick }: TabRenderProps<T>) => (
    <span key={tab.name} className={className} onClick={onClick}>
        {tab.name}
    </span>
);

/**
 * A generic presentational stateless component that renders a tab switcher.
 */
const TabSwitcher = <T extends any>({
    activeTabIndex,
    setActiveTabIndex,
    tabs,
    className,
    activeClassName,
    renderTabComponent = renderDefaultTab,
    withDelimiter = false,
    coloredSelectedTab = true,
    allowMixedCase = false,
}: Props<T>) => {
    const activeStyle: string = coloredSelectedTab ? styles.activeColored : styles.active;
    return (
        <>
            {tabs.map((tab, index) => {
                const computedClassName = classNames(styles.tab, className, {
                    [activeClassName || activeStyle]: activeTabIndex === index,
                    [styles.noTextTransform]: allowMixedCase,
                });
                const onClick = () => setActiveTabIndex(index);

                return renderTabComponent({ tab, onClick, className: computedClassName });
            })}
            {withDelimiter && <div className={styles.tabDelimiter} />}
        </>
    );
};

export default TabSwitcher;
