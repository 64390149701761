import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';

import IconCheck from 'horizontal/assets/icons/iconCheck.svg';
import type { SearchSortOption } from 'horizontal/types';

import styles from './styles/sortOptionsDropdownItem.cssm';

type Props = {
    readonly option: SearchSortOption;
    readonly isSelected: boolean;
    readonly onClick: () => void;
};

const SortOptionsDropdownItem = ({ option, isSelected, onClick }: Props) => (
    <li
        role="option"
        aria-selected={isSelected}
        className={classNames(styles.item, {
            [styles.selected]: isSelected,
        })}
        onClick={onClick}
    >
        {isSelected && <IconCheck className={styles.checkedIcon} />}
        <Text.Regular>{option.label}</Text.Regular>
    </li>
);

export default SortOptionsDropdownItem;
