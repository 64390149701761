import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import { numberOfHits, isBedroomPage as selectIsBedroomPage } from '@app/branding/search';
import settings from '@app/branding/settings';
import filterValues from '@app/branding/filterValues';

import type { GlobalState } from 'strat/state';
import Purpose from 'strat/purpose';
import { canonicalDomain } from 'strat/routing';
import computeDefaultTitle from 'strat/searchComponents/defaultTitle';
import {
    TemplateType,
    TemplatePage,
    findTemplate,
    renderTemplate,
    selectTemplates,
} from 'strat/templates';
import { FilterValues } from 'strat/search';
import { useI18n } from 'strat/i18n/language';
import {
    type LocationNodeData,
    type CategoryNodeData,
    PropertyType,
    PropertyCompletionStatus,
} from 'strat/property/types';
import { useProjectSearchTemplateOverride } from 'strat/project';

import useListingTemplateReplaceParameters from './useListingTemplateReplaceParameters';

type UseListingTemplate = {
    readonly text: string;
    readonly isDefault: boolean;
};

const getTopLevelLocation = (language: string) => ({
    ...settings.topLevelLocation,
    hierarchy: [
        {
            // @ts-ignore
            ...settings.topLevelLocation.hierarchy[0],
            // @ts-ignore
            slug: filterValues.location.placeholders[language],
        },
    ],
});

const RuleBasedTemplateCompletionStatus = Object.freeze({
    ...PropertyCompletionStatus,
    PROJECT: 'project',
});

const mapRuleBasedTemplateCompletionStatus = (
    completionStatus: Values<typeof RuleBasedTemplateCompletionStatus> | undefined,
    adType: Values<typeof PropertyType>,
) => {
    if (!completionStatus) {
        return undefined;
    }

    if (
        CONFIG.build.STRAT_ENABLE_NEW_PROJECTS_LPV &&
        completionStatus === RuleBasedTemplateCompletionStatus.OFF_PLAN &&
        adType === PropertyType.PROJECT
    ) {
        return RuleBasedTemplateCompletionStatus.PROJECT;
    }

    return completionStatus;
};

const useListingTemplate = (type: Values<typeof TemplateType>): UseListingTemplate => {
    const i18n = useI18n();
    const templates = useSelector(selectTemplates);
    const isBedroomPage = useSelector(selectIsBedroomPage);
    const isAreaPage = useSelector((state: GlobalState) => state.search.pageParameters.isAreaPage);
    const nbHits = useSelector(numberOfHits);
    const replaceParameters = useListingTemplateReplaceParameters();

    const templateOverrides = useSelector((state: GlobalState) => state.seo.pageInfo);
    const projectTemplateOverride = useProjectSearchTemplateOverride(type);

    const filters = useSelector(getFilterCollection);
    const categoryFilter = filters.getFilterValue<CategoryNodeData>(
        FilterValues.category.attribute,
        {
            slug: null,
            name: null,
        },
    );
    const completionStatus = filters.getFilterValue<Values<typeof PropertyCompletionStatus>>(
        FilterValues.completionStatus.attribute,
        undefined,
    ) as string | undefined;
    const locationFilter = filters.getFirstFilterValue<LocationNodeData>(
        FilterValues.location.attribute,
        getTopLevelLocation(i18n.locale),
    );
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const locationType = locationFilter?.type ?? null;

    const adType = filters.getFilterValue<PropertyType>(
        FilterValues.type.attribute,
        FilterValues.type.default,
    ) as Values<typeof PropertyType>;

    const purpose = filters.getFilterValue<Values<typeof Purpose>>(FilterValues.purpose.attribute);
    const currentPage = filters.getFilterValue<number>(FilterValues.page.attribute, 1);

    const domainName = canonicalDomain().rootDomainTitle;
    const defaultTextBase = computeDefaultTitle(filters, i18n);

    const defaultTitle = [
        nbHits > 0 ? nbHits.toString() : null,
        defaultTextBase,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2554 - Expected 1 arguments, but got 2.
        currentPage > 1 ? t(i18n)`Page-${currentPage}` : null,

        t(i18n)`| ${domainName}`,
    ]
        .filter((part) => !!part)
        .join(' ');

    const defaultText = type === TemplateType.TITLE ? defaultTitle : defaultTextBase;

    let overridenTemplate = null;
    // eslint-disable-next-line default-case
    switch (type) {
        case TemplateType.TITLE:
            overridenTemplate = templateOverrides.pageTitle;
            break;

        case TemplateType.META:
            overridenTemplate = templateOverrides.description;
            break;

        case TemplateType.H1:
            overridenTemplate = templateOverrides.h1Title;
            break;
    }

    if (
        adType === PropertyType.PROJECT &&
        !overridenTemplate &&
        !CONFIG.build.STRAT_ENABLE_NEW_PROJECTS_LPV
    ) {
        overridenTemplate = projectTemplateOverride;
    }

    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    let locationHierarchy: LocationNodeData[] = locationFilter.hierarchy || [];
    // Zameen: insert city into location hierarchy, if not already set
    const cityFilter = filters.getFirstFilterValue<LocationNodeData>(FilterValues.city.attribute);
    if (
        cityFilter &&
        cityFilter.level !== undefined &&
        cityFilter.slug &&
        !locationHierarchy.find((x) => x.level === cityFilter.level)
    ) {
        locationHierarchy = [...locationHierarchy, cityFilter];
        // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
        locationHierarchy.sort((a, b) => a.level > b.level);
    }

    const template =
        overridenTemplate ||
        findTemplate({
            type,
            page: TemplatePage.SEARCH,
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            categorySlug: categoryFilter.slug,
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            categoryExternalID: categoryFilter.externalID,
            locationSlugs: locationHierarchy.map((item) => item.slug).reverse(),
            locationType,
            purpose,
            templates,
            bedroomPage: isBedroomPage,
            areaPage: isAreaPage,
            completionStatus: mapRuleBasedTemplateCompletionStatus(completionStatus, adType),
        });

    if (!template) {
        return { text: defaultText, isDefault: true };
    }

    // @ts-expect-error - TS2322 - Type '{ purpose: any; priceMin: any; priceMax: any; currency: any; areaMin: any; areaMax: any; areaUnit: any; areaPageValueSqYd: string | null; city: any; location: any; locationType: any; locationWithParent: string; ... 24 more ...; areaPageValue: number | null; }' is not assignable to type 'ReplaceParameters'.
    const renderedTemplate = renderTemplate({ template, replaceParameters });
    if (!renderedTemplate) {
        return { text: defaultText, isDefault: true };
    }

    return { text: renderedTemplate, isDefault: false };
};

export default useListingTemplate;
