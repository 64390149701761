import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';
import { useActiveUserExternalID } from 'strat/user/session';
import { useIsProfolioUser } from 'strat/user';
import { CategoryChoice } from 'strat/components/types';

import { Pagination } from 'horizontal/pagination';
import { buildChoices } from 'horizontal/util/categoryFilter';

import { selectCategories } from '../categories';

import StateFilter from './stateFilter';
import styles from './styles/adManagement.cssm';
import useMyAds from './useMyAds';
import QueryFilter from './queryFilter';
import { DEFAULT_PAGE_SIZE, useActivePage, useTotalPages } from './usePagination';
import { selectIsLoadingMyAds } from './selectors';
import useRefreshMyAds from './useRefreshMyAds';
import BusinessPackagesBanner from './businessPackagesBanner';
import useFetchAdMetrics from './useFetchAdMetrics';
import useAdManagementFilters from './useAdManagementFilters';
import FilterableCategoryDropdown from './filterableCategoryDropdown';
import useSetAdManagementFilters from './useSetAdManagementFilters';
import ListEntry from './listEntry';
import LoadingMyAdCard from './loadingMyAdCard';

const MyAds = () => {
    const i18n = useI18n();
    const ads = useMyAds();
    const loading = useSelector(selectIsLoadingMyAds);
    const isProfolioUser = useIsProfolioUser();

    const userExternalID = useActiveUserExternalID();
    const totalPages = useTotalPages();
    const activePage = useActivePage();
    const routeParams = useAdManagementFilters();
    const loadAds = useRefreshMyAds();
    const setAdManagementFilters = useSetAdManagementFilters();
    const placeholder = t(i18n)`Filter By Category`;
    useFetchAdMetrics();

    React.useEffect(() => {
        if (!userExternalID) {
            return;
        }

        loadAds().then((loadedAds) =>
            trigger(Triggers.MY_ADS_LOADED, {
                // @ts-expect-error - TS7006 - Parameter 'ad' implicitly has an 'any' type.
                ad_ids: loadedAds.data.results.map((ad) => ad.externalID),
                view_section: ViewSections.USER_DETAIL,
            }),
        );
    }, [loadAds, userExternalID]);

    const adEntries = React.useMemo(() => {
        if (loading) {
            return Array.from(Array(DEFAULT_PAGE_SIZE).keys(), (index: number) => (
                <LoadingMyAdCard key={`${index}`} />
            ));
        }

        return ads.map((ad) => <ListEntry ad={ad} key={ad.id} />);
    }, [ads, loading]);
    const filterByCategory = !settings.disableFilterByCategory;
    const orderedCategories = useSelector(selectCategories);

    const choices = React.useMemo(() => {
        return buildChoices(orderedCategories);
    }, [orderedCategories]);

    const getSelectedChoiceValue = React.useCallback(
        (
            choices: Array<CategoryChoice<string>> | undefined,
            targetParam: string | null | undefined,
        ): CategoryChoice<string> | null => {
            if (!choices) {
                return null;
            }
            for (const choice of choices) {
                if (choice.value === targetParam) {
                    return choice;
                }
                if (choice.children?.length !== 0) {
                    const result = getSelectedChoiceValue(choice.children, targetParam);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        },
        [],
    );

    const selectedChoiceValue = getSelectedChoiceValue(choices, routeParams.category);

    const initialValue = selectedChoiceValue?.label ? selectedChoiceValue.label : placeholder;
    const onSubmitCategory = (category: any) => {
        setAdManagementFilters({ category, stateFilter: null });
    };

    const shouldShowBusinessPackagesBanner = settings.enableBusinessPackages && !isProfolioUser;

    return (
        <Flex column className={styles.container} fillContainer>
            {loading ? null : (
                <>
                    <Flex className={styles.filters} wrap>
                        {filterByCategory && (
                            <div className={styles.filterCategory}>
                                <FilterableCategoryDropdown
                                    name="category"
                                    choices={choices}
                                    value={initialValue}
                                    onSelectCategory={(category) => {
                                        onSubmitCategory(category);
                                    }}
                                />
                            </div>
                        )}
                        <Flex className={styles.input}>
                            <QueryFilter />
                        </Flex>
                    </Flex>

                    <Flex className={styles.stateFilter}>
                        <StateFilter />
                    </Flex>
                    {shouldShowBusinessPackagesBanner && <BusinessPackagesBanner />}
                </>
            )}
            <div>{adEntries}</div>
            <Pagination
                activePage={activePage}
                totalPages={totalPages}
                routeName={RouteNames.AD_MANAGEMENT}
                routeParams={routeParams}
            />
        </Flex>
    );
};

export default MyAds;
