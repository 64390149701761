import { t } from '@lingui/macro';
import * as React from 'react';
import { ChoiceValue } from 'strat/components/types';
import { Dropdown } from 'strat/modal';
import classNames from 'classnames';
import { Input } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import DropdownChoice from '../dropdownChoice';
import DropdownTrigger from '../dropdownTrigger';
import { FilterableDropdownProps } from '../types';

import styles from './styles/regular.cssm';

interface Props<T extends ChoiceValue> extends FilterableDropdownProps<T> {
    shouldHideInput?: boolean;
    searchQuery: string | null;
    setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedValueLabel?: string;
}

const RegularDropdownVariant = <T extends ChoiceValue>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    onChange,
    value = '',
    choices,
    dropdownTriggerCustomClass,
    choicesContainerCustomClass,
    placeholder,
    stretch,
    renderSuggestions,
    readOnly,
    noOverflow,
    dropdownTriggerIcon,
    titleCustomClass,
    renderIcon,
    shouldHideInput,
    searchQuery,
    setSearchQuery,
    isOpen,
    setIsOpen,
    selectedValueLabel,
    onBlur,
    boldLabel,
    isAutofilled,
}: Props<T>) => {
    const i18n = useI18n();

    return (
        <div className={styles.container} aria-label="Filterable input">
            <Dropdown
                triggerIsDismissble
                open={isOpen}
                className={isAutofilled ? styles.autofilled : undefined}
                renderTrigger={() => (
                    <DropdownTrigger
                        className={dropdownTriggerCustomClass}
                        renderSelectionIcon={dropdownTriggerIcon}
                        title={title}
                        description={description}
                        name={name}
                        errorMessage={errorMessage}
                        accepted={accepted}
                        stretch={stretch}
                        setIsOpen={(open) => setIsOpen(!readOnly && open)}
                        value={value}
                        placeholder={placeholder}
                        isOpen={isOpen}
                        noOverflow={noOverflow}
                        selectedValueLabel={selectedValueLabel}
                        titleCustomClass={titleCustomClass}
                        boldLabel={boldLabel}
                    />
                )}
                onDismissed={() => setIsOpen(false)}
            >
                <div
                    className={classNames(choicesContainerCustomClass, styles.content)}
                    aria-label="Filterable dropdown"
                >
                    {!shouldHideInput ? (
                        <div className={styles.searchbox}>
                            <Input
                                onChange={(query) => setSearchQuery(query)}
                                value={searchQuery ?? ''}
                                placeholder={t(i18n)`Search`}
                                borderless
                                onBlur={onBlur}
                            />
                        </div>
                    ) : null}
                    {renderSuggestions && !searchQuery ? renderSuggestions(onChange) : null}
                    {choices.map((choice) => (
                        <DropdownChoice
                            choice={choice}
                            onClick={onChange}
                            key={choice.value}
                            selected={value === choice.value}
                            renderIcon={renderIcon}
                        />
                    ))}
                </div>
            </Dropdown>
        </div>
    );
};

export default RegularDropdownVariant;
