import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import CountryBounds from '@app/branding/countryBounds';
import FilterValues from 'strat/search/filterValues';

import * as PackedListing from '../packedListing';
import {
    isContained,
    isInBounds,
    isInRange,
    isInSelectedLocation,
    isRangeFilterMatched,
} from '../filterConditions';

import type { PackedProjectType } from './types';
import * as PackedProject from './packedProject';

export const filterProjectListings = (
    packedListings: Array<PackedProjectType>,
    filters: FilterCollection,
): Array<PackedProjectType> => {
    if (!packedListings) {
        return [];
    }

    const areaFilter = filters.getFilterValue(FilterValues.area.attribute, {
        min: null,
        max: null,
    });
    const priceFilter = filters.getFilterValue(FilterValues.price.attribute, {
        min: null,
        max: null,
    });
    const bedFilter = filters.getFilterValue(FilterValues.beds.attribute);
    const bathsFilter = filters.getFilterValue(FilterValues.baths.attribute);
    const completionDateFilter = filters.getFilter(FilterValues.handoverDate.attribute);
    const completionPercentageFilter = filters.getFilter(
        FilterValues.completionPercentage.attribute,
    );
    const preHandoverPaymentFilter = filters.getFilter(FilterValues.preHandoverPayment.attribute);
    const locationFilter = filters.getFilterValue(FilterValues.location.attribute);

    return packedListings.filter((entry) => {
        if (
            !isInBounds(
                CountryBounds,
                PackedListing.longitude(entry),
                PackedListing.latitude(entry),
            )
        ) {
            return false;
        }

        if (!isInRange(priceFilter, PackedProject.price(entry))) {
            return false;
        }

        if (!isInRange(areaFilter, PackedProject.area(entry))) {
            return false;
        }

        if (
            bedFilter &&
            (!Array.isArray(PackedProject.unitRooms(entry)) ||
                !PackedProject.unitRooms(entry).some((room) => isContained(bedFilter, room)))
        ) {
            return false;
        }

        if (
            bathsFilter &&
            (!Array.isArray(PackedProject.unitBaths(entry)) ||
                !PackedProject.unitBaths(entry).some((bath) => isContained(bathsFilter, bath)))
        ) {
            return false;
        }

        if (!isRangeFilterMatched(completionDateFilter, PackedProject.completionDate(entry))) {
            return false;
        }

        if (
            !isRangeFilterMatched(
                completionPercentageFilter,
                PackedProject.completionPercentage(entry),
            )
        ) {
            return false;
        }

        if (
            !isRangeFilterMatched(preHandoverPaymentFilter, PackedProject.preHandoverPayment(entry))
        ) {
            return false;
        }

        if (
            locationFilter &&
            !isInSelectedLocation(locationFilter, PackedProject.locationHierarchy(entry))
        ) {
            return false;
        }

        return true;
    });
};
