import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import Footer from 'horizontal/footer';
import { UserOrders } from 'horizontal/userOrders';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

import styles from './styles/userOrdersPageDesktop.cssm';
import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly productPurchaseID: number;
};

const UserOrdersPageDesktop = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
            <GTMDataLayer pageType={PageType.PROFILE} />
        </>
    ),
    body: ({ productPurchaseID }: Props) => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <PageWidthWrapper className={styles.pageWidthWrapper}>
                <Flex fillContainer stretchWidth column className={styles.container}>
                    <UserOrders productPurchaseID={productPurchaseID} />
                </Flex>
            </PageWidthWrapper>
            <Footer />
        </Flex>
    ),
} as const;

export default UserOrdersPageDesktop;
