import { RoutingContext } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import Page from 'horizontal/agencyPortal/pages/page';
import SelectPackageSuccessCommonRoute from 'horizontal/routes/selectPackageSuccessCommonRoute';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

export type SelectPackageSuccessRouteParams = {
    adExternalID: string;
    packageID: string;
};

class AgencyPortalSelectPackageSuccessRoute extends SelectPackageSuccessCommonRoute {
    constructor() {
        super(
            RouteNames.AGENCY_PORTAL_SELECT_PACKAGE_SUCCESS,
            '/agencyPortal/packages/consume/boost/',
            Page.AGENCY_PORTAL_SELECT_PACKAGE_SUCCESS,
            Page.AGENCY_PORTAL_REJECTED_AD,
            Page.AGENCY_PORTAL_PACKAGES_NOT_FOUND,
        );
    }

    hasAccess(context: RoutingContext): boolean {
        // @ts-expect-error - TS2345 - Argument of type 'import("strat/react-true-router/routingContext").default' is not assignable to parameter of type 'import("strat/strat/app/app").RoutingContext'.
        if (!ensureCanAccessAgencyPortal(context)) {
            return false;
        }
        return true;
    }
}

export default new AgencyPortalSelectPackageSuccessRoute();
