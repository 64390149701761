//@ts-nocheck
import Layout from './layout';

/**
 * Available layout actions.
 */
const Actions = Object.freeze({
    SET_LAYOUT: 'LAYOUT/SET',
});

/**
 * Default state for the layout.
 */
const defaultState = Layout.MOBILE;

/**
 * Reducer for the layout.
 */
const layoutReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_LAYOUT:
            return action.layout;

        default:
            return state;
    }
};

/**
 * Sets the active layout.
 */
const setLayout = (layout: any) => ({
    type: Actions.SET_LAYOUT,
    layout,
});

export { setLayout };

export default layoutReducer;
