/**
 * Those keys are mainly concerned with fields of the Property
 * and Cars categories. These are the categories that are usually
 * verticalized.
 */
export enum AdExtraFieldsKeys {
    PRICE = 'price',
    PRICE_TYPE = 'price_type',
    SECONDARY_PRICE = 'secondary_price',
    MAKE = 'make',
    MODEL = 'model',
    AGENT_CODE = 'agent_code',
    MILEAGE = 'mileage',
    CONDITION = 'new_used',
    OWNERSHIP = 'ownership',
    FREE_AD_UNDERTAKING = 'free-ad-commission',
    PETROL = 'petrol',
    FURNISHED = 'furnished',
    AREA = 'ft',
    BEDROOMS = 'rooms',
    BATHROOMS = 'bathrooms',
    SALARY_PERIOD = 'salary_period',
    SALARY_FROM = 'salary_from',
    SALARY_TO = 'salary_to',
    ROOMS = 'rooms',
    DOWN_PAYMENT = 'down_payment',
    INSTALLMENT_PLAN = 'installment_plan',
    PAYMENT_OPTIONS = 'payment_option',
    FT_UNIT = 'ft_unit',
    YEAR = 'year',
    RENTAL_PERIOD = 'rental_period',
    INSURANCE = 'insurance',
    VARIANT = 'variant',
    TRANSMISSION = 'transmission',
    ENGINE_CAPACITY = 'engine_capacity',
    /* Some countries have type like PK/EG others have property_type like LB */
    TYPE = 'type',
    PROPERTY_TYPE = 'property_type',
    VIDEO = 'video',
    PANORAMA = 'panorama',
}
