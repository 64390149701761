import React from 'react';

import type { ProjectUnit } from 'strat/project/types';
import { useI18n } from 'strat/i18n/language';
import { Accordion } from 'strat/generic/accordion';

import CollapsibleUnitGroup from '../collapsibleUnitGroup';
import { groupUnitsByBeds } from '../unitListTransformers';
import { titleForBeds } from '../unitTitle';

import CompactUnitsList from './compactUnitsList';
import styles from './styles/unitBedsGroupList.cssm';

type Props = {
    units: ProjectUnit[];
    initialExpanded?: boolean;
    isCommercial: boolean;
};

const CompactUnitBedsGroupList = ({ units, initialExpanded, isCommercial }: Props) => {
    const i18n = useI18n();
    const bedsWithUnitsList = groupUnitsByBeds(units);

    return (
        <Accordion initialExpanded={!!initialExpanded}>
            {bedsWithUnitsList.map((bedsWithUnits) => (
                <CollapsibleUnitGroup
                    key={bedsWithUnits.bedsCount}
                    renderTitle={() => (
                        <div className={styles.title}>
                            {titleForBeds(i18n, bedsWithUnits, isCommercial)}
                        </div>
                    )}
                    startingPrice={bedsWithUnits.startingPrice}
                >
                    <CompactUnitsList units={bedsWithUnits.units} />
                </CollapsibleUnitGroup>
            ))}
        </Accordion>
    );
};

export default CompactUnitBedsGroupList;
