import * as React from 'react';
import { Text, Flex } from 'strat/components';

import PaymentInfoText from '@app/userWallet/paymentInfoText';

import HowToUseUserWallet from './howToUseUserWallet';
import WalletBalance from './walletBalance';
import styles from './styles/overview.cssm';

const Overview = () => {
    return (
        <Flex stretchWidth column>
            <Flex alignCenter className={styles.balanceContainer}>
                <div className={styles.backgroundWrapper}>
                    <div className={styles.backgroundImage}></div>
                    <Flex column alignCenter className={styles.balanceSection}>
                        <WalletBalance />
                        <div className={styles.balanceSection}>
                            <Text.Large>
                                <PaymentInfoText />
                            </Text.Large>
                        </div>
                    </Flex>
                </div>
            </Flex>

            <Flex column alignCenter className={styles.container}>
                <HowToUseUserWallet />
            </Flex>
        </Flex>
    );
};

export default Overview;
