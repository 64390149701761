import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDeactivateUser } from '@sector-labs/fe-auth-redux/thunk';
import getDeleteUrl from '@app/user/getDeleteUrl';
import { selectLanguage } from 'strat/i18n/language/selectors';
import settings from '@app/branding/settings';
import { useLogout } from 'strat/auth';
import { useKeycloakSiteConfigWithCallbacks } from 'strat/auth/keycloak';

import { useMakeCancelable } from 'horizontal/util';

import DeleteProfileForm from './deleteProfileForm';

const DeleteProfile = ({
    renderForm,
}: {
    renderForm: (props: React.ComponentProps<typeof DeleteProfileForm>) => React.ReactElement;
}) => {
    const language = useSelector(selectLanguage);

    const logout = useLogout();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const deactivateUser = useDeactivateUser();
    const makeCancelable = useMakeCancelable();

    const onDelete = React.useCallback(() => {
        // @ts-ignore
        const url = getDeleteUrl(settings.targetingParamsPrefix, language);
        window.open(url);
    }, [language]);

    const onDeactivate = () => {
        makeCancelable(deactivateUser(keycloakSiteConfig)).then(() => {
            logout();
        });
    };

    const handlers: React.ComponentProps<typeof DeleteProfileForm> = {
        onDeactivate,
        onDelete,
    };

    return renderForm(handlers);
};

export default DeleteProfile;
