import type { CategoryFieldNoChoices } from 'strat/types';

import type { CategoryFields } from './types';

type Callback = (arg1: CategoryFieldNoChoices) => boolean;

const filterCategoryFields = (
    categoryFields: CategoryFields,
    callback: Callback,
): CategoryFields => ({
    ...categoryFields,
    flatFields: categoryFields.flatFields.filter((field) => callback(field)),
    childrenFields: categoryFields.childrenFields.filter((field) => callback(field)),
});

export default filterCategoryFields;
