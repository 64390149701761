import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { selectIsLanguageRTL } from 'strat/i18n/language';
import { selectUserRoles } from 'strat/user/session';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import IconArrowLeft from '@app/assets/icons/iconArrowLeft.svg';
import { Flex, Text } from 'strat/components';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';

import { BusinessPackagesLink, BusinessPackagesLinkSource } from 'horizontal/businessPackages';

import styles from './styles/businessPackagesBanner.cssm';

const BusinessPackagesBanner = () => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);

    const userRoles = useSelector(selectUserRoles);
    if (isProAgent(userRoles) && !isProAgencyOwner(userRoles)) {
        return null;
    }

    return (
        <Flex fillContainer className={styles.container}>
            <Flex className={styles.message}>
                <Text.Regular>
                    <Trans>Heavy discount on Packages</Trans>
                </Text.Regular>
            </Flex>
            <BusinessPackagesLink source={BusinessPackagesLinkSource.MY_ADS_BANNER}>
                <div className={styles.link}>
                    <Text.Regular bold>
                        <Trans>View Packages</Trans>
                    </Text.Regular>
                    {isLanguageRTL ? (
                        <IconArrowLeft className={styles.arrowIcon} />
                    ) : (
                        <IconArrowRight className={styles.arrowIcon} />
                    )}
                </div>
            </BusinessPackagesLink>
        </Flex>
    );
};

export default BusinessPackagesBanner;
