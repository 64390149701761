import * as React from 'react';
import { useActiveUser } from 'strat/user/session';
import { useKeycloakLoginDialog } from 'strat/auth/keycloak';
import type { KeycloakUser } from 'strat/auth/keycloak/user';

type UseEnsureHasActiveUser = () => boolean;

type Props = {
    readonly onDone?: (user: KeycloakUser) => void;
    readonly onLoginDialogOpen?: () => void;
};

const useEnsureHasActiveUser = ({ onDone, onLoginDialogOpen }: Props): UseEnsureHasActiveUser => {
    const user = useActiveUser();
    const [, setLoginDialogVisibility] = useKeycloakLoginDialog();

    return React.useCallback(() => {
        if (!user) {
            if (onLoginDialogOpen) {
                onLoginDialogOpen();
            }

            setLoginDialogVisibility(true, {
                onDone,
                disableEmailLink: false,
            });

            return false;
        }

        if (onDone) {
            onDone(user);
        }

        return true;
    }, [user, setLoginDialogVisibility, onDone, onLoginDialogOpen]);
};

export default useEnsureHasActiveUser;
