import React from 'react';
import { Trans } from '@lingui/macro';

import { useTitle } from 'horizontal/adDetails/seo';
import { useAd } from 'horizontal/ad/state';

const RequestDeliveryHead = () => {
    const ad = useAd();
    const title = useTitle(ad);

    return (
        <title>
            <Trans>Buy with delivery</Trans> | {title}
        </title>
    );
};

export default RequestDeliveryHead;
