import Page from 'horizontal/pages/page';

export default Object.freeze({
    ...Page,
    AGENCY_PORTAL_ACTION_CENTER: 'agencyPortalActionCenter',
    AGENCY_PORTAL_ADS: 'agencyPortalAds',
    AGENCY_PORTAL_AGENTS: 'agencyPortalAgents',
    AGENCY_PORTAL_COMPANY_PROFILE: 'agencyPortalCompanyProfile',
    AGENCY_PORTAL_CREATE_AD: 'agencyPortalCreateAd',
    AGENCY_PORTAL_UPDATE_AD: 'agencyPortalUpdateAd',
    AGENCY_PORTAL_POST_AD_SUCCESS: 'agencyPortalPostAdSuccess',
    AGENCY_PORTAL_REJECTED_AD: 'agencyPortalPostRejectedAd',
    AGENCY_PORTAL_APPLY_AD_LIMIT: 'agencyPortalPostApplyAdLimit',
    AGENCY_PORTAL_PACKAGES_NOT_FOUND: 'agencyPortalPackagesNotFound',
    AGENCY_PORTAL_NO_LIMIT_PACKAGES: 'agencyPortalNoLimitPackages',
    AGENCY_PORTAL_HELP: 'agencyPortalHelp',
    AGENCY_PORTAL_HOME: 'agencyPortalHome',
    AGENCY_PORTAL_LEADS: 'agencyPortalLeads',
    AGENCY_PORTAL_PACKAGES: 'agencyPortalPackages',
    AGENCY_PORTAL_PACKAGE_DETAIL: 'agencyPortalPackageDetail',
    AGENCY_PORTAL_SELECT_PACKAGE_SUCCESS: 'agencyPortalSelectPackageSuccess',
    AGENCY_PORTAL_AGENT_DETAIL: 'agencyPortalAgentDetail',
    AGENCY_PORTAL_AD_DETAIL: 'agencyPortalAdDetail',
    AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST: 'agencyPortalApplyAdProductsCreditCost',
    AGENCY_PORTAL_CREDIT_INFO: 'agencyPortalCreditInfo',
    AGENCY_PORTAL_NOT_FOUND: 'agencyPortalNotFound',
    NOT_FOUND: 'notFound',
});
