import * as React from 'react';
import autoBind from 'react-autobind';

import Dismissible from './dismissible';
import HidingWrapper, { HidingMethod } from './hidingWrapper';

type Props = {
    /**
     * The children of the component.
     */
    readonly children: React.ReactNode;
    /**
     * Method to use for hiding the drop down contents.
     */
    readonly hidingMethod?: Values<typeof HidingMethod>;
    /**
     * Callback function to be used to render the trigger. It will call it with a callback function
     * which can be used to open the dropdown, and the current state of the dropdown.
     */
    readonly renderTrigger?: (
        openDropdown: () => void,
        visible: boolean,
        extraRenderTriggerParams?: any,
    ) => React.ReactElement;
    /**
     * Extra params passed to the renderTrigger function in case some extra functionality
     * is needed
     */
    readonly extraRenderTriggerParams?: any;
    /**
     * State of the component in case it is controlled by another component.
     */
    readonly open?: boolean;
    /**
     * Optional callback triggered when the Dismissable is dismissed.
     */
    readonly onDismissed?: () => void;
    /**
     * Whether the trigger is part of the dismissible, this can be used
     * when the trigger is an input.
     */
    readonly triggerIsDismissble?: boolean;
    /**
     * Whether dropdown is part of stack of dismissibles
     */
    readonly stackGroup?: string;
    readonly className?: string;
};

type State = {
    /**
     * Whether the dropdown is currently open or not.
     */
    open: boolean;
};

/**
 * Renders a trigger and the children children using a Dismissible.
 */
class Dropdown extends React.Component<Props, State> {
    state: State = {
        open: false,
    };

    static defaultProps = {
        hidingMethod: HidingMethod.NO_RENDER,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);
    }

    close() {
        this.setState({ open: false });
        if (this.props.onDismissed) {
            this.props.onDismissed();
        }
    }

    open() {
        this.setState({ open: true });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open && this.props.open !== this.state.open) {
            // eslint-disable-next-line react/no-did-update-set-state
            // @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
            this.setState({ open: this.props.open });
        }
    }

    render() {
        const {
            renderTrigger,
            extraRenderTriggerParams,
            children,
            hidingMethod,
            open = false,
            triggerIsDismissble,
            className,
        } = this.props;

        const currentState = open || this.state.open;

        const contents = (
            <HidingWrapper display={currentState} hidingMethod={hidingMethod}>
                {children}
            </HidingWrapper>
        );

        const trigger = renderTrigger
            ? renderTrigger(this.open, this.state.open, extraRenderTriggerParams)
            : null;

        return (
            <>
                {!triggerIsDismissble && trigger}
                <Dismissible
                    onDismissed={this.close}
                    enabled={currentState}
                    key="dropdown-dismissible"
                    stacked={this.props.stackGroup ? true : false}
                    stackGroup={this.props.stackGroup}
                    className={className}
                >
                    {triggerIsDismissble && trigger}
                    {contents}
                </Dismissible>
            </>
        );
    }
}

export default Dropdown;
