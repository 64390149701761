import { t } from '@lingui/macro';
import React from 'react';
import { useSelector } from 'react-redux';

import { logError } from 'strat/error/log';
import { makeCancelable } from 'strat/util';
import ShareLoadingDialog from 'strat/modal/compact/shareLoadingDialog';
import { selectIsMobile } from 'strat/platform/selectors';
import { buildCanonicalURLWithoutLanguage } from '@app/routing';
import { useI18n } from 'strat/i18n/language';
import { ContextfulError } from 'strat/error/context';

type Props = {
    generate: () => Promise<any>;
};

const useShareMobileWithLoading = ({
    generate,
}: Props): [(() => Promise<void>) | undefined, () => JSX.Element | null] => {
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobile);
    const [isShareModalLoading, setIsShareModalLoading] = React.useState(false);
    const [canShare, setCanShare] = React.useState(false);

    React.useEffect(() => {
        if (navigator && !!navigator.share) {
            setCanShare(true);
        }
    }, []);

    const share = React.useCallback(async () => {
        setIsShareModalLoading(true);
        makeCancelable(generate())
            .then(async ({ data, status }) => {
                if (status !== 200 && status !== 201) {
                    throw new ContextfulError(
                        'Unexpected response status code while generating short link',
                        { status, data },
                    );
                }
                const url =
                    data.shortenedLink &&
                    buildCanonicalURLWithoutLanguage(`/l/${data.shortenedLink}`);
                setIsShareModalLoading(false);
                await navigator.share({
                    url: url,
                    title: t(i18n)`Share`,
                });
            })
            .catch((e) => {
                logError({
                    e,
                    msg: 'Unable to generate short link',
                });

                setIsShareModalLoading(false);
            });
    }, [setIsShareModalLoading, generate, i18n]);

    const renderDialog = React.useCallback(
        () => (
            <ShareLoadingDialog
                visible={isShareModalLoading}
                onVisibilityChanged={setIsShareModalLoading}
            />
        ),
        [isShareModalLoading, setIsShareModalLoading],
    );

    if (!CONFIG.build.STRAT_ENABLE_MOBILE_SHARE || !isMobile || !canShare) {
        return [undefined, () => null];
    }

    return [share, renderDialog];
};

export default useShareMobileWithLoading;
