import settings from '@app/branding/settings';

import { useConvertedPrice, useExtraChargesPrice } from 'horizontal/util';

import { SupportedCurrencies } from './types';

const useCalculatePrice = (
    price: number,
    extraCharge: number | undefined,
    currency: Values<typeof SupportedCurrencies>,
) => {
    const extraChargesPrice = useExtraChargesPrice(price, extraCharge);
    // @ts-expect-error - TS2345 Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const secondaryPrice = useConvertedPrice(extraChargesPrice, settings.secondaryCurrency);

    return currency === SupportedCurrencies.USD ? price : secondaryPrice;
};

export default useCalculatePrice;
