import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID, selectUserRoles } from 'strat/user/session';
import { isAgencyOwner } from 'strat/user/roles';

import Page from 'horizontal/agencyPortal/pages/page';
import { fetchUserAgencies } from 'horizontal/agent/state';
import { CreditsViewScope } from 'horizontal/agencyPortal/types';

import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';
import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

export type CreditsInfoRouteParams = {
    readonly scope: CreditsViewScope;
};

class AgencyPortalCreditInfoRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_CREDIT_INFO, [
            [
                '^/agencyPortal/creditInfo',
                { name: 'scope', pattern: `(?:/(${Object.values(CreditsViewScope).join('|')}))?` },
            ],
        ]);
    }

    createURL(
        params: CreditsInfoRouteParams,
        context: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const {
            redux: {
                store: { getState },
            },
        } = context;
        const userRoles = selectUserRoles(getState());

        const { scope } = params;
        let computedScope = scope || CreditsViewScope.ALL;
        if (!isAgencyOwner(userRoles)) {
            computedScope = CreditsViewScope.SELF;
        }

        return {
            pathname: `/agencyPortal/creditInfo/${computedScope}`,
        };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
        return Promise.resolve();
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context) && !ensureHasAccessToStratCredits(context)) {
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { scope },
            },
        } = context;

        context.rendering.renderPage(Page.AGENCY_PORTAL_CREDIT_INFO, {
            scope: scope,
        });

        const userExternalID = selectUserExternalID(getState());

        context.promise.wait(Promise.all([dispatch(fetchUserAgencies({ userExternalID }))]));
    }
}

export default new AgencyPortalCreditInfoRoute();
