import React from 'react';
import IconAppStore from '@app/assets/icons/iconAppStoreEN_noinline.svg';
import IconGooglePlay from '@app/assets/icons/iconGooglePlayEN_noinline.svg';
import settings from '@app/branding/settings';
import { trigger, Triggers } from 'strat/gtm';
import { Flex } from 'strat/components';

import IconAppGallery from '@app/assets/icons/iconAppGallery_noinline.svg';

import styles from './styles/mobileAppDownload.cssm';

type Props = {
    readonly iconClassName?: string;
};

const MobileAppDownload = ({ iconClassName }: Props) => (
    <Flex alignCenter className={styles.container}>
        <a
            onClick={() => trigger(Triggers.CLICK_DOWNLOAD_IOS)}
            // @ts-ignore
            href={settings.linkAppStore}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={IconAppStore} alt="App Store" className={iconClassName || styles.icon} />
        </a>
        <a
            onClick={() => trigger(Triggers.CLICK_DOWNLOAD_ANDROID)}
            // @ts-ignore
            href={settings.linkGooglePlayDesktop}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={IconGooglePlay} alt="Google Play" className={iconClassName || styles.icon} />
        </a>
        <a
            onClick={() => trigger(Triggers.CLICK_DOWNLOAD_APPGALLERY)}
            href={settings.linkAppGallery}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={IconAppGallery} alt="App Gallery" className={iconClassName || styles.icon} />
        </a>
    </Flex>
);

export default MobileAppDownload;
