import {
    selectActiveSearchBackend,
    setFilters,
    SearchActions,
} from '@sector-labs/fe-search-redux/state';
import { SearchJob } from '@sector-labs/fe-search-redux';
import isEqual from 'lodash/isEqual';
import { FilterCollection, RefinementFilter } from '@sector-labs/fe-search-redux/filters';
import settings from '@app/branding/settings';
import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import { RouteNames, MultiJobSearchHandler } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import { RouteParameters } from 'react-true-router/route';
import { selectSearchHistory } from 'strat/search/selectors';
import { determineLocationsIndexName } from 'strat/search/indexNames';

import Page from 'horizontal/pages/page';
import { fetchFavoriteAds } from 'horizontal/favorites';

import {
    SearchJobName,
    createCategoryWithHitsSearchJob,
    processCategoriesWithHitsResponse,
    updateSearchHistoryWithLocation,
} from './home';
import getSelectedLocationFilter from './home/getSelectedLocationFilter';
import processPromotedSearchesResponse from './home/processPromotedSearchesResponse';

class HomeRoute extends Route {
    constructor() {
        super(RouteNames.HOME, [
            ['^/(?:(?:\\?.*)?(?:#.*)?)?$'],
            // legacy routes
            ['^/m/(?:(?:\\?.*)?(?:#.*)?)?$'],
            ['^/home(?:(?:/)?(?:\\?.*)?(?:#.*)?)?$'],
        ]);
    }

    createURL(_params: RouteParameters, _: RoutingContextWithMiddlewares): EnhancedLocation {
        return { pathname: '/', search: {} };
    }

    /**
     * Detects inconsistencies in the URL and redirects to the canonical
     * version of the URL.
     */
    correctURL(url: string): string | null | undefined {
        let redirectURL = url;

        // A URL starting with m should redirect to the same version, without m
        if (redirectURL.startsWith('/m')) {
            redirectURL = redirectURL.replace(/m\//, '');
        }

        // A URL starting with home should redirect to the same version, without m
        if (redirectURL.startsWith('/home')) {
            redirectURL = redirectURL.replace(/\/home(\/)?/, '/');
        }

        if (isEqual(url, redirectURL)) {
            return null;
        }

        return redirectURL;
    }

    addPromotedSearchesLocationsJob(language: string, jobSearchHandler: MultiJobSearchHandler) {
        const locationIds = settings.homePagePromotedSearches.map(
            ({ locationExternalID }) => locationExternalID,
        );
        const job = new SearchJob(
            determineLocationsIndexName({ language }),
            new FilterCollection().refine(
                new RefinementFilter({
                    attribute: 'externalID',
                    value: locationIds,
                }),
            ),
            {
                hitsPerPage: settings.homePagePromotedSearches.length,
                exhaustiveNbHits: false,
            },
        );
        jobSearchHandler.addNamedJob(SearchJobName.promotedSearchesLocations(), job);
    }

    addPromotedSearchesHitsJobs(
        context: RoutingContextWithMiddlewares,
        jobSearchHandler: MultiJobSearchHandler,
    ) {
        const {
            i18n: { locale: language },
            redux: {
                store: { getState },
            },
        } = context;

        settings.homePagePromotedSearches.forEach(({ displayCategorySlug, locationExternalID }) => {
            const job = createCategoryWithHitsSearchJob(
                getState,
                displayCategorySlug,
                language,
                locationExternalID,
            );
            jobSearchHandler.addNamedJob(
                SearchJobName.promotedSearchHits(displayCategorySlug, locationExternalID),
                job,
            );
        });
    }

    onEnter(context: any): void {
        const {
            redux: {
                store: { dispatch, getState },
            },
            i18n: { locale: language },
            match: { originalURL },
        } = context;

        const correctedURL = this.correctURL(originalURL);
        if (correctedURL) {
            context.http.redirect(correctedURL, { status: 301 });
            return;
        }

        const state = getState();

        dispatch(fetchFavoriteAds());
        context.rendering.renderPage(Page.HOME);

        const jobSearchHandler = new MultiJobSearchHandler();

        dispatch({ type: SearchActions.FETCH });
        settings.homePageCategoriesWithHitsIdentifiers.forEach((categoryIdentifier) => {
            const job = createCategoryWithHitsSearchJob(getState, categoryIdentifier, language);
            jobSearchHandler.addNamedJob(SearchJobName.categoryWithHits(categoryIdentifier), job);
        });
        const filters = new FilterCollection();
        const locationFilter = getSelectedLocationFilter(getState);
        if (locationFilter) {
            filters.refine(locationFilter);
        }
        dispatch(setFilters(filters));
        const searchHistory = selectSearchHistory(state);

        updateSearchHistoryWithLocation(dispatch, searchHistory, locationFilter);

        this.addPromotedSearchesLocationsJob(context.i18n.locale, jobSearchHandler);
        this.addPromotedSearchesHitsJobs(context, jobSearchHandler);

        const promises = Promise.all([
            // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
            jobSearchHandler.search({ backend: selectActiveSearchBackend(state) }),
            dispatch(fetchCategoryFields()),
        ]);

        context.promise.wait(
            promises.then(() => {
                processCategoriesWithHitsResponse(context, jobSearchHandler);
                processPromotedSearchesResponse(context, jobSearchHandler);
            }),
        );
    }
}

export default new HomeRoute();
