import * as React from 'react';
import { useSelector } from 'react-redux';

import { useRouter } from 'react-true-router';
import { serializeLocation } from 'react-true-router/location';
import { selectUserID } from 'strat/user/selectors';
import { trigger } from 'strat/gtm';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { asNumberOrString } from 'strat/util';
import type { BreadcrumbsElement } from 'strat/navigation/breadcrumbs/types';
import { selectProperty } from 'strat/property/selectors';

import generateUniqueEventID from './generateUniqueEventID';
import Triggers from './triggers';
import PageTypes from './pageType';

// @ts-expect-error - TS2749 - 'PageTypes' refers to a value, but is being used as a type here. Did you mean 'typeof PageTypes'?
const useTrackBreadCrumbClick = (pageType: PageTypes) => {
    const language = useSelector(selectLanguage);
    const userID = useSelector(selectUserID);
    const router = useRouter();
    const ad = useSelector(selectProperty);
    const adExternalID = asNumberOrString(ad?.externalID);

    return React.useCallback(
        (breadcrumb: BreadcrumbsElement) => {
            const destinationLink = breadcrumb.route
                ? serializeLocation(router.getRouteURL(breadcrumb.route, breadcrumb.params), {
                      encodePathname: true,
                  })
                : '';
            trigger(Triggers.CLICK_BREADCRUMB, {
                language,
                destination_link: destinationLink,
                user_id: asNumberOrString(userID),
                event_unique_id: generateUniqueEventID(),
                page_type: pageType,
                listing_id: adExternalID,
            });
        },
        [userID, language, adExternalID, router, pageType],
    );
};

export default useTrackBreadCrumbClick;
