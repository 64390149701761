//@ts-nocheck
import { t } from '@lingui/macro';
import { rentForSearchForm, saleForSearchForm } from '@app/branding/textDisplay';

const LEADERBOARD_CUBE_PURPOSE = {
    ANY: 'total',
    FOR_SALE: '1_buy',
    FOR_RENT: '2_rent',
};

/**
 * How the purpose text should be rendered.
 */
const PurposeTextDisplay = Object.freeze({
    // To Rent / For Sale
    NORMAL: 'normal',

    // Rent / Sale
    SHORT: 'short',

    // For Rent / For Sale
    CONSISTENT_PREPOSITION: 'consistentPreposition',

    // To Rent / For Sale but with a "special" arabic translation.
    FOR_SEARCH_FORM: 'searchForm',

    // for Rent / for Sale
    FOR_TEMPLATES: 'forTemplates',

    // Rent / Sell
    FOR_OWNER: 'forOwner',

    // Rented / Sold
    FOR_TRANSACTIONS: 'forTransactions',
});

export const PurposeValues = Object.freeze({
    FOR_RENT: 'for-rent',
    FOR_SALE: 'for-sale',
    ANY: 'any',
    WANTED: 'wanted',
});

/**
 * Available purposes in this app.
 */
const Purpose = Object.freeze({
    FOR_RENT: PurposeValues.FOR_RENT,
    FOR_SALE: PurposeValues.FOR_SALE,
    ANY: PurposeValues.ANY,
    WANTED: PurposeValues.WANTED,

    /**
     * Gets the value for the specified purpose.
     *
     * NORMAL = 'to-rent' and 'for-sale'
     * CONSISTENT_PREPOSITION = 'for-rent' and 'for-sale'
     */
    value(purpose: any, displayOption: PurposeTextDisplays = PurposeTextDisplay.NORMAL) {
        if (displayOption === PurposeTextDisplay.CONSISTENT_PREPOSITION) {
            return purpose;
        }

        return purpose === this.FOR_RENT ? 'to-rent' : 'for-sale';
    },

    /**
     * Gets the human-readable text for the specified purpose.
     * @returns {string}
     */
    text(i18n: any, purpose: any, displayOption: PurposeTextDisplay = PurposeTextDisplay.NORMAL) {
        switch (purpose) {
            case this.FOR_RENT:
                switch (displayOption) {
                    case PurposeTextDisplay.CONSISTENT_PREPOSITION:
                        return t(i18n)`For Rent`;
                    case PurposeTextDisplay.FOR_SEARCH_FORM:
                        return rentForSearchForm(i18n);
                    case PurposeTextDisplay.FOR_TEMPLATES:
                        return t(i18n)`for Rent`;
                    case PurposeTextDisplay.SHORT:
                        return t(i18n)`Rent`;
                    case PurposeTextDisplay.FOR_TRANSACTIONS:
                        return t(i18n)`Rented`;
                    default:
                        return t(i18n)`To Rent`;
                }

            case this.FOR_SALE:
                switch (displayOption) {
                    case PurposeTextDisplay.CONSISTENT_PREPOSITION:
                        return t(i18n)`For Sale`;
                    case PurposeTextDisplay.FOR_SEARCH_FORM:
                        return saleForSearchForm(i18n);
                    case PurposeTextDisplay.FOR_TEMPLATES:
                        return t(i18n)`for Sale`;
                    case PurposeTextDisplay.SHORT:
                        return t(i18n)`Sale`;
                    case PurposeTextDisplay.FOR_TRANSACTIONS:
                        return t(i18n)`Sold`;
                    default:
                        return t(i18n)`For Sale`;
                }

            default:
                return displayOption === PurposeTextDisplay.CONSISTENT_PREPOSITION
                    ? t(i18n)`For Rent`
                    : t(i18n)`To Rent`;
        }
    },

    /**
     * Gets the human-readable text for the specified purpose
     * as a verb "for sale" -> "Buy" "for-rent" -> "Rent"
     * @returns {string}
     */
    textAsVerb(i18n: any, purpose: any, displayOption?: PurposeTextDisplay) {
        switch (purpose) {
            case this.FOR_SALE:
                switch (displayOption) {
                    case PurposeTextDisplay.FOR_OWNER:
                        return t(i18n)`Sell`;
                    default:
                        return t(i18n)`Buy`;
                }

            default:
                return t(i18n)`Rent`;
        }
    },

    /**
     * Gets the human-readable text for the specified purpose
     * as an adverb "for sale" -> "to buy" "for-rent" -> "to rent"
     * @returns {string}
     */
    textAsAdverb(i18n: any, purpose: any, uppercaseVerb = false) {
        switch (purpose) {
            case this.FOR_SALE:
                if (uppercaseVerb) {
                    return t(i18n)`to Buy`;
                }
                return t(i18n)`to buy`;

            case this.FOR_RENT:
            default:
                if (uppercaseVerb) {
                    return t(i18n)`to Rent`;
                }
                return t(i18n)`to rent`;
        }
    },

    /**
     * Gets a list of all available purposes.
     * @returns {array}
     */
    all() {
        return [this.FOR_RENT, this.FOR_SALE];
    },

    /**
     * Gets a unique ID for the specified purpose.
     */
    id(purpose: any) {
        switch (purpose) {
            case this.FOR_SALE:
                return 1;
            case this.FOR_RENT:
                return 2;
            case this.WANTED:
                return 3;
            default:
                return 1;
        }
    },

    isForRent(purpose: any) {
        return purpose === this.FOR_RENT;
    },

    isForSale(purpose: any) {
        return purpose === this.FOR_SALE;
    },

    isAny(purpose: any) {
        return purpose === this.ANY;
    },

    purposeStringToPurposeFilterValue(slug: string | null): string | null {
        if (slug !== 'for-sale' && slug !== 'to-rent' && slug !== 'for-rent') {
            return null;
        }

        switch (slug) {
            case 'for-rent':
                return Purpose.FOR_RENT;
            case 'to-rent':
                return Purpose.FOR_RENT;
            case 'for-sale':
                return Purpose.FOR_SALE;
            default:
                return null;
        }
    },

    leaderboardCubeValue(purpose: any) {
        switch (purpose) {
            case this.FOR_SALE:
                return LEADERBOARD_CUBE_PURPOSE.FOR_SALE;
            case this.FOR_RENT:
                return LEADERBOARD_CUBE_PURPOSE.FOR_RENT;
            default:
                return LEADERBOARD_CUBE_PURPOSE.ANY;
        }
    },
});

export default Purpose;
export { PurposeTextDisplay };
