import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import { SectionTitle } from '../section';

import styles from './styles/description.cssm';
import DescriptionText from './descriptionText';

type Props = {
    readonly description: string;
};

const Description = ({ description }: Props) => {
    const i18n = useI18n();

    return (
        <>
            <SectionTitle title={t(i18n)`Description`} />
            <div className={styles.descriptionContent}>
                {/* @ts-expect-error - TS2786 - 'DescriptionText' cannot be used as a JSX component. */}
                <DescriptionText description={description} />
            </div>
        </>
    );
};

export default Description;
