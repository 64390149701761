import React from 'react';
import { Button } from 'strat/components';
import { Trans } from '@lingui/macro';

import IconDelivery from 'horizontal/assets/icons/iconDelivery.svg';
import useRequestDelivery from 'horizontal/adDetails/requestDelivery/useRequestDelivery';
import { MixedAd } from 'horizontal/types';

type Props = {
    ad: MixedAd;
    className?: string;
    small?: boolean;
    smallIcon?: boolean;
};

const BuyWithDeliveryButton = ({ ad, className, small = true, smallIcon = true }: Props) => {
    const { isAdOrdered, redirect } = useRequestDelivery(ad);

    return (
        <Button
            renderIcon={(className) => <IconDelivery className={className} />}
            small={small}
            smallIcon={smallIcon}
            stretch
            onClick={() => {
                redirect();
            }}
            className={className}
        >
            {isAdOrdered ? <Trans>Check delivery status</Trans> : <Trans>Buy with delivery</Trans>}
        </Button>
    );
};

export default BuyWithDeliveryButton;
