import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import FetcherFactory from 'strat/fetcher';
import ValhallaAPI from 'strat/api/valhalla';
import type { CommuteLocations } from 'strat/search/mapBasedSearch/commute';
import { Isochrone, IsochroneError } from 'strat/search/mapBasedSearch/types';

export type Params = {
    sources: CommuteLocations;
    maxTime: number;
};

const contours = [2, 5, 8, 10, 15, 20, 25, 30, 40, 45, 50, 60].map((time) => ({ time }));
const EMPTY_ISOCHRONE: Isochrone = { features: [], type: 'FeatureCollection' };

const getIsochroneFactory = new FetcherFactory(
    'isochrone',
    ({ sources }: Params) => {
        if (!sources) {
            return Promise.resolve({ data: null, status: 200 });
        }
        const keys = Object.keys(sources);

        return Promise.all(
            keys
                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CommuteLocations'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CommuteLocations'.
                .map((key) => ({ lat: sources[key]?.latitude, lon: sources[key]?.longitude }))

                .map((source) => new ValhallaAPI().isochrone([source], contours)),
        ).then((results) => {
            const data: Record<string, Isochrone> = {};

            let status = 200;
            for (let i = 0; i < results.length; i++) {
                data[keys[i]] = results[i].data;
                if (results[i].status !== 200) {
                    status = results[i].status;
                    data[keys[i]] = {
                        ...EMPTY_ISOCHRONE,
                        error: results[i].data as IsochroneError,
                    };
                }
            }

            return { status, data };
        });
    },
    {
        // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
        getParams: (params, getState) => {
            const state = getState();

            const filterCollection = getFilterCollection(state);
            const commute = filterCollection.getFilterValue('latLong');

            if (!commute) {
                return {
                    sources: null,
                    targets: null,
                    names: null,
                    ...params,
                };
            }

            return {
                sources: commute,
                ...params,
            };
        },
        successCodes: [200, 400],
    },
);

const fetchIsochrone = getIsochroneFactory.creator();
const fetchIsochronesFetcherReducer = getIsochroneFactory.reducer();

export { fetchIsochrone };
export default fetchIsochronesFetcherReducer;
