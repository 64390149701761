import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import styles from './styles/soldMessage.cssm';

const SoldMessage = () => {
    const i18n = useI18n();

    return (
        <Flex noShrink wrap className={styles.container}>
            <div className={styles.soldLabel}>
                <Text.Regular bold>{t(i18n)`Sold`}&nbsp;•</Text.Regular>
            </div>
            <Text.Base className={styles.soldText}>{t(i18n)`This ad has been sold!`}</Text.Base>
        </Flex>
    );
};

export default SoldMessage;
