import { AdVirtualState, AdState } from 'horizontal/types';
import type { Ad } from 'horizontal/types';

const isAdInactive = (ad?: Ad | null) => {
    return (
        ad?.state === AdState.ACTIVE &&
        [AdVirtualState.EXPIRED, AdVirtualState.OUTDATED].includes(ad?.virtualState)
    );
};

export default isAdInactive;
