import InternalAPI from 'strat/api/internal';
import { selectLanguage } from 'strat/i18n/language';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import type { FetchState, FetchAction } from 'strat/fetcher';
import { selectUserExternalID } from 'strat/user/session';
import { isKeycloakEnabled } from 'strat/auth/keycloak/config';
import SavedSearchesAPI from '@app/api/savedSearchesAPI';

const factory = new FetcherFactory(['search', 'saved'], (params: any, state: any) => {
    const language = selectLanguage(state);
    const userExternalID = selectUserExternalID(state);

    if (isKeycloakEnabled()) {
        if (!userExternalID) {
            throw new Error('Missing userID');
        }

        return new SavedSearchesAPI(language)
            .getSavedSearches(userExternalID)
            .then(({ status, data }) => {
                if (status !== 200) {
                    return { status, data: null };
                }
                return { status, data };
            });
    }

    return new InternalAPI(language).getSavedSearches(userExternalID);
});

const fetchSavedSearches = factory.creator();

const clearSavedSearches = () => ({
    type: factory.actions.clear,
});

const defaultState: FetchState = buildDefaultState();

const savedSearchFetcherReducer = factory.reducer();

export default savedSearchFetcherReducer;
export { clearSavedSearches, fetchSavedSearches, defaultState };
export type { FetchState, FetchAction };
