import * as React from 'react';

import { BreadcrumbsWithSlashes } from 'horizontal/breadcrumbs';
import { useAd } from 'horizontal/ad/state';

import { useBreadcrumbs } from './seo';

const AdHeading = () => {
    const ad = useAd();
    const breadcrumbs = useBreadcrumbs(ad);

    return <BreadcrumbsWithSlashes breadcrumbs={breadcrumbs} />;
};

export default AdHeading;
