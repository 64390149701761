import * as React from 'react';

import IconChatSend from 'horizontal/assets/icons/iconChatSend.svg';

type Props = {
    readonly className?: string;
    readonly onSendMesssage: () => void;
};

const SendButton = ({ onSendMesssage, className }: Props) => {
    return (
        <button onClick={onSendMesssage} className={className}>
            <IconChatSend />
        </button>
    );
};

export default SendButton;
