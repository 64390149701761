import type { NudgeManagerState } from 'strat/nudges/state';

const getNudgesGTMVars = (
    propertyExternalID: string | null | undefined,
    nudges: NudgeManagerState,
) => {
    if (propertyExternalID !== nudges.lastDisplayedPropertyExternalID) {
        return {};
    }

    return {
        nudge_banner_type: nudges.lastDisplayedBannerType,
    };
};

export { getNudgesGTMVars };
