import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Checkbox, Flex } from 'strat/components';

import { ShortNumberField } from 'horizontal/fields/commonPostingFields';

import styles from './styles/hotlineCheckbox.cssm';

type Props = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    checked?: boolean;
    disabled?: boolean;
};

const HotlineCheckbox = ({ disabled, checked, setFieldValue }: Props) => {
    const i18n = useI18n();
    return (
        <Flex className={styles.container}>
            <Checkbox
                checked={checked}
                name={ShortNumberField.attribute}
                label={t(i18n)`Use Hotline instead of Mobile Phone Number`}
                // @ts-expect-error - TS2322 - Type '(value: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'value' implicitly has an 'any' type.
                onChange={(value) => {
                    setFieldValue(ShortNumberField.attribute, value?.target.checked);
                }}
                withHighlightLabel={false}
                disabled={disabled}
            />
        </Flex>
    );
};

export default HotlineCheckbox;
