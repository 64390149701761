import { AdFormat } from 'horizontal/types';
import type { MixedAd } from 'horizontal/types';

// eslint-disable-next-line camelcase
const useLocalizedAdTitle = (ad: MixedAd): string => ad.title || ad.title_l1;

// eslint-disable-next-line camelcase
const useLocalizedAdDescription = (ad?: MixedAd | null): string | null | undefined => {
    if (!ad || ad.format === AdFormat.LITE) {
        return null;
    }
    return ad.description || ad.description_l1;
};

export { useLocalizedAdTitle, useLocalizedAdDescription };
