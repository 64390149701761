import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';

import IconHeart from '@app/assets/icons/iconHeart.svg';
import IconHeartFilled from '@app/assets/icons/iconHeartFilled.svg';

import styles from './styles/saveSearchButtonContent.cssm';
import { selectCurrentSearchIfSaved } from './selectors';

const SaveSearchButtonContent = ({ onClick }: { onClick: () => void }) => {
    const currentSavedSearch = useSelector(selectCurrentSearchIfSaved);

    return (
        <a className={styles.button} onClick={onClick}>
            <Flex alignCenter justifyCenter>
                {currentSavedSearch ? (
                    <>
                        <IconHeartFilled className={styles.iconOpenDialog} />
                        <Trans>Saved search</Trans>
                    </>
                ) : (
                    <>
                        <IconHeart className={styles.iconOpenDialog} />
                        <Trans>Save search</Trans>
                    </>
                )}
            </Flex>
        </a>
    );
};

export default SaveSearchButtonContent;
