import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { computeProjectHandoverQuarter } from 'strat/project';

import ProjectTopDetailsItem from './projectTopDetailsItem';

type Props = {
    date: number;
};

const ProjectHandoverInformationSection = ({ date }: Props) => {
    const i18n = useI18n();
    const handover = computeProjectHandoverQuarter({ completionDate: date });

    if (!handover) {
        return null;
    }

    const { quarter, year } = handover;

    return (
        <ProjectTopDetailsItem
            renderLabel={() => t(i18n)`Handover`}
            renderInfo={() => (
                <Trans>
                    Q{quarter} {year}
                </Trans>
            )}
        />
    );
};

export default ProjectHandoverInformationSection;
