export const BusinessPackageExampleType = Object.freeze({
    FEATURED: 'featuredExample',
    BOOST_TO_TOP: 'boostToTopExample',
});

export const FrequencyTimeUnitType = Object.freeze({
    DAYS: 'days',
    HOURS: 'hours',
});

export type BusinessPackageExampleTypeValues = Values<typeof BusinessPackageExampleType>;
