import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Text } from 'strat/components';

import { selectPopularSearches } from './state/selectors';

const PopularSearchesBreadcrumbs = () => {
    const { location, category } = useSelector(selectPopularSearches);
    return (
        (location || category) && (
            <div>
                <Link route={RouteNames.SITEMAP}>
                    <Text.XSmall>
                        <Trans>Popular Searches</Trans>
                    </Text.XSmall>
                </Link>
                {location?.name && (
                    <Link route={RouteNames.SITEMAP} params={{ location }}>
                        <Text.XSmall> {` / ${location.name}`}</Text.XSmall>
                    </Link>
                )}
                {category?.name && <Text.XSmall> {` / ${category.name}`}</Text.XSmall>}
            </div>
        )
    );
};

export default PopularSearchesBreadcrumbs;
