import isEqual from 'lodash/isEqual';
import type { Store } from 'redux';
import { createSelector } from 'reselect';

import Cookies from './cookies';
import CookieNames from './cookieNames';

// @ts-expect-error - TS2769 - No overload matches this call.
const getOldCookies = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'cookie' implicitly has an 'any' type.
    (cookie) => cookie,
    (_: string) =>
        Cookies.get([
            CookieNames.SETTINGS,
            CookieNames.EXPERIMENTS,
            CookieNames.BANNERS,
            CookieNames.USER_CITY,
            CookieNames.USER_LOCATION,
            CookieNames.USER_GEOLOCATION,
            CookieNames.NOTIFICATIONS_PROMPT_POSTPONED,
        ]),
);

// @ts-expect-error - TS2769 - No overload matches this call.
const getOldSubscriptionPromptCookie = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'cookie' implicitly has an 'any' type.
    (cookie) => cookie,
    (_: string) => Cookies.get([CookieNames.NOTIFICATIONS_PROMPT_POSTPONED]),
);

// @ts-expect-error - TS2769 - No overload matches this call.
const getOldReferrerDataCookies = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'cookie' implicitly has an 'any' type.
    (cookie) => cookie,
    (_: string) =>
        Cookies.get([CookieNames.REFERRER, CookieNames.ORIGINAL_REFERRER, CookieNames.LANDING_URL]),
);

/**
 * Syncs the Redux state to cookies.
 */
// @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
export default (store: Store) => (next: any) => (action: any) => {
    const result = next(action);
    const { banners, settings, user, router, experiments, notifications } = store.getState();
    const userCity = user.city;
    const userLocation = user.location;
    const userGeoLocation = user.geoLocation;

    const documentCookie = document.cookie;
    // get existing cookies, merge with new ones to get
    // a complete list of new cookies
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '((...args: unknown[]) => {}) | { [x: string]: any; }'.
    const oldCookies = getOldCookies(documentCookie);

    const newCookies: Record<Values<typeof CookieNames>, string> = {
        [CookieNames.SETTINGS]: settings,
    };

    if (experiments && Object.keys(experiments).length) {
        newCookies[CookieNames.EXPERIMENTS] = experiments;
    }

    if (banners && Object.keys(banners).length) {
        newCookies[CookieNames.BANNERS] = banners;
    }

    if (CONFIG.build.ENABLE_GEO_IP_LOCATION) {
        if (userCity) {
            // userCity.externalID is really a string, but Cookies.get() parses the values with JSON.parse()
            // and converts it to an integer.
            // better make externalID an integer so the oldCookies and newCookies can be equal.
            // @ts-expect-error - TS2542 - Index signature in type '{ readonly [x: string]: any; }' only permits reading.
            newCookies[CookieNames.USER_CITY] = parseInt(userCity.externalID, 10);
        }

        if (userLocation?.countryCode || userLocation?.countryName || userLocation?.cityName) {
            newCookies[CookieNames.USER_LOCATION] = userLocation;
        }
    }

    if (userGeoLocation?.closestLocation || userGeoLocation?.coordinates) {
        newCookies[CookieNames.USER_GEOLOCATION] = userGeoLocation;
    }

    // write cookies if we're in the browser and the cookies
    // changes compared to the old ones
    if (!isEqual(oldCookies, newCookies)) {
        Cookies.clear(Object.keys(oldCookies));
        Cookies.set(
            Cookies.encodeValues(newCookies),
            2592000, // 30 days
        );
    }

    // Keep the referrer in the cookies so when the user refresh
    // will not create a new GA session.
    const { referrer, originalReferrer, landingURL } = router;
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '((...args: unknown[]) => {}) | { [x: string]: any; }'.
    const oldReferrerData = getOldReferrerDataCookies(documentCookie);
    if (
        referrer !== oldReferrerData[CookieNames.REFERRER] ||
        originalReferrer !== oldReferrerData[CookieNames.ORIGINAL_REFERRER] ||
        landingURL !== oldReferrerData[CookieNames.LANDING_URL]
    ) {
        Cookies.clear([
            CookieNames.REFERRER,
            CookieNames.ORIGINAL_REFERRER,
            CookieNames.LANDING_URL,
        ]);
        Cookies.set(
            Cookies.encodeValues({
                [CookieNames.REFERRER]: referrer,
                [CookieNames.ORIGINAL_REFERRER]: originalReferrer,
                [CookieNames.LANDING_URL]: landingURL,
            }),
            30 * 60,
        );
    }

    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '((...args: unknown[]) => {}) | { [x: string]: any; }'.
    const oldSubscriptionPromptCookie = getOldSubscriptionPromptCookie(documentCookie);
    const newSubscriptionPromptCookie = {
        [CookieNames.NOTIFICATIONS_PROMPT_POSTPONED]: notifications.promptPostponed,
    } as const;

    if (
        newSubscriptionPromptCookie[CookieNames.NOTIFICATIONS_PROMPT_POSTPONED] &&
        !isEqual(oldSubscriptionPromptCookie, newSubscriptionPromptCookie)
    ) {
        Cookies.clear([CookieNames.NOTIFICATIONS_PROMPT_POSTPONED]);
        Cookies.set(Cookies.encodeValues(newSubscriptionPromptCookie), 7200); // 2 hours
    }

    return result;
};
