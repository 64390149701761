import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text, Flex } from 'strat/components';

import IconCheck from 'horizontal/assets/icons/iconCheckThick.svg';

import styles from './styles/packagesAdvantages.cssm';

const BoostToTopAdvantages = () => (
    <Flex column className={styles.container}>
        <Text.Large bold>
            <Trans>What is Boost To Top?</Trans>
        </Text.Large>
        <Flex alignCenter className={styles.featureItems}>
            <IconCheck className={styles.iconCheckMark} />
            <Text.Base className={styles.sellFasterText}>
                <Trans>Back on top like a new ad Package available for 90 days</Trans>
            </Text.Base>
        </Flex>
        <Flex alignCenter className={styles.featureItems}>
            <IconCheck className={styles.iconCheckMark} />
            <Text.Base className={styles.sellFasterText}>
                <Trans>Package available for 90 days</Trans>
            </Text.Base>
        </Flex>
    </Flex>
);

export default BoostToTopAdvantages;
