import * as React from 'react';
import type { I18n } from '@lingui/core';
import { QueryFilterFuzzyOptions } from '@sector-labs/fe-search-redux/filters/queryFilter';

import { SortByValues } from 'strat/search/sortValues';
import Area from 'strat/i18n/area';
import type { PropertyVerificationBadge } from 'strat/property/badges/propertyVerificationBadges';
import { CommonAdData } from 'strat/property/types';
import { ProjectPageSections } from 'strat/searchComponents/project/types';

const AreaGuideContentMapping = Object.freeze({
    purpose_name: 'Purpose',
    property_type_name: 'PropType',
    location_name: 'Loc',
    bed_type: 'BedType',
    avg_listing_price_aed: 'AvgListingPrice',
    min_listing_price_aed: 'MinPriceActiveListings',
    max_listing_price_aed: 'MaxPriceActiveListings',
    avg_transaction_price_aed: 'AvgTransactionPrice',
    min_transaction_price_aed: 'MinTransactionPrice',
    max_transaction_price_aed: 'MaxTransactionPrice',
    gross_rental_yield: 'ROI',
    num_transactions: 'TransactionVolume',
    transaction_amount_aed: 'TransactionValue',
    listing_price_roc: 'ChangeAvgListingPrice',
    transaction_amount_roc: 'ChangeAvgTransactionPrice',
    top_agency_name_1: 'TopAgency1',
    top_agency_name_2: 'TopAgency2',
    top_agency_name_3: 'TopAgency3',
    popular_child_location_name_1: 'PopularChildLoc1',
    popular_child_location_name_2: 'PopularChildLoc2',
    popular_child_location_name_3: 'PopularChildLoc3',
    popular_child_location_name_4: 'PopularChildLoc4',
    popular_child_location_name_5: 'PopularChildLoc5',
    nearest_location_name_1: 'NearbyLoc1',
    nearest_location_name_2: 'NearbyLoc2',
    nearest_location_name_3: 'NearbyLoc3',
    nearest_location_name_4: 'NearbyLoc4',
    nearest_location_name_5: 'NearbyLoc5',
    nearest_school_name_1: 'NearbySchool1',
    nearest_school_name_2: 'NearbySchool2',
    nearest_school_name_3: 'NearbySchool3',
    nearest_nursery_name_1: 'NearbyNursery1',
    nearest_nursery_name_2: 'NearbyNursery2',
    nearest_nursery_name_3: 'NearbyNursery3',
    nearest_university_name_1: 'NearbyUniversity1',
    nearest_university_name_2: 'NearbyUniversity2',
    nearest_university_name_3: 'NearbyUniversity3',
    nearest_restaurant_name_1: 'NearbyRestaurant1',
    nearest_restaurant_name_2: 'NearbyRestaurant2',
    nearest_restaurant_name_3: 'NearbyRestaurant3',
    nearest_restaurant_name_4: 'NearbyRestaurant4',
    nearest_restaurant_name_5: 'NearbyRestaurant5',
    nearest_hospital_name_1: 'NearbyHospital1',
    nearest_hospital_name_2: 'NearbyHospital2',
    nearest_park_name_1: 'NearbyPark1',
    nearest_park_name_2: 'NearbyPark2',
    nearest_school_article_title_1: 'NearbySchoolTitle1',
    nearest_school_article_title_2: 'NearbySchoolTitle2',
    nearest_school_article_title_3: 'NearbySchoolTitle3',
    nearest_nursery_article_title_1: 'NearbyNurseryTitle1',
    nearest_nursery_article_title_2: 'NearbyNurseryTitle2',
    nearest_nursery_article_title_3: 'NearbyNurseryTitle3',
});

type LiteHierarchicalLocation = {
    id?: number;
    externalID: string;
    level: number;
    name: string;
    slug: string;
    hierarchy?: readonly LiteHierarchicalLocation[];
};

export type LanguageSwitcher = {
    [key: string]: {
        image: string;
        style?: {
            [key: string]: string;
        };
        renderQLSLinkContent?: (targetLanguage: string) => React.ReactNode;
    };
};

/**
 * Tree of property categories.
 */
export type CategoryTree = {
    [key: string]: {
        name: string;
        nameSingular: string;
        localizedSlug?: string;
        externalID: string;
        children: {
            [key: string]: {
                name: string;
                nameSingular: string;
                localizedSlug?: string;
                externalID: string;
            };
        };
    };
};

export type FilterValues = {
    location: {
        placeholder: string;
        placeholderName: (i18n: I18n) => string;
        placeholders: {
            [key: string]: string;
        };
        default?: string;
    };
    category: {
        tree: (i18n: I18n) => CategoryTree;
    };
    agencyType?: {
        choices: any;
        titaniumChoices: Array<string>;
    };
};

export type StaticPage = {
    route: string;
    title: (i18n: I18n) => string;
    description: (i18n: I18n) => string;
    path: (language: string, layout: string) => string;
    script?: string;
};

export const LoginFormType = Object.freeze({
    DEFAULT: 'default', // this includes email, phone, google, facebook login
    GOOGLE_FACEBOOK_EMAIL: 'google_facebook_email', // this includes google, facebook, email
});

export type Language = {
    lang: string;
    name: string;
    locales: string;
    numberFormattingLocale?: string;
    rtl?: boolean;
    dateLocales?: string;
    isDisabled?: boolean;
    getLanguagePrefix?: (requestTranslatedPage: boolean) => string;
};

/**
 * Object containing settings for the configured branding.
 */
export type BrandingSettings = {
    languages: Language[];
    defaultLanguage?: string;
    defaultPhoneNumber: {
        country: string;
        prefix: string;
        trunk_prefix: string;
        mobile_prefix?: string;
    };
    baseCurrencyName: string;
    defaultCurrencyName: string;
    secondaryCurrency?: string;
    defaultAreaUnit: Values<typeof Area>;
    selectAreaUnit: (state: any) => {
        areaUnit: Values<typeof Area>;
        isDefaultAreaUnit: boolean;
    };
    countryName: (i18n: I18n) => string;
    countryNameAbbreviation?: (i18n: I18n) => string;
    countryCode?: string;
    officialLanguages?: Array<string>;
    topLevelLocation: LiteHierarchicalLocation;
    maxAmenitiesVisible?: number;
    disableLogin?: boolean;
    disableSignUp?: boolean;
    enableRecentBlogPosts?: boolean;
    disableSavedSearches?: boolean;
    disableFavorites?: boolean;
    hideFavoritesAndSavedSearchesIfZero?: boolean;
    disableAlerts?: boolean;
    disableBackOfficeSettingsPersistence?: boolean;
    showMobileAppLinks?: boolean;
    showMobileAppDownload?: boolean;
    proxyMobileAppLink?: string;
    appDeepLink?: string;
    deepLinkAppName?: string;
    linkAppStore: null | string;
    linkAppStoreAgentApp?: string;
    linkGooglePlayMobile: null | string;
    linkGooglePlayDesktop: null | string;
    linkGooglePlayMobileAgent?: string;
    linkAppGallery?: string;
    agentApp?: {
        link: string;
        packageName: string;
    };
    consumerApp?: {
        link: string;
        packageName: string;
    };
    getBrandName: (i18n: I18n) => string;
    getLinkedBrandName?: (i18n: I18n) => string;
    getDomainName: (i18n: I18n) => string;
    getTopLevelLocation: (i18n: I18n) => LiteHierarchicalLocation;
    getMobileAppName?: (i18n: I18n) => string;
    getNumberOfAppInstalls?: (i18n: I18n) => string;
    seo?: {
        homePageTitle: (i18n: I18n) => string;
        homePageDescription: (i18n: I18n) => string;
        agencyHomePageTitle?: (i18n: I18n) => string;
        agencyHomePageDescription?: (i18n: I18n) => string;
        agencySearchPageTitle?: (i18n: I18n, page: string, mainLocation: string) => string;
        agencySearchPageDescription?: (i18n: I18n, page: string, mainLocation: string) => string;
        agencyDetailPageTitle?: (
            i18n: I18n,
            page: string,
            agencyName: string,
            listingsCount: number,
            mainLocation: string,
        ) => string;
        agentDetailPageTitle?: (
            i18n: I18n,
            agentName: string,
            listingsCount: number,
            mainLocation: string,
        ) => string;
        truValuePageTitle?: (i18n: I18n) => string;
        truValuePageDescription?: (i18n: I18n) => string;
        truEstimatePageTitle?: (i18n: I18n) => string;
        truEstimatePageDescription?: (i18n: I18n) => string;
    };
    appStoreID?: string;
    areaUnits: Array<string>;
    currencies: Array<string>;
    social?: {
        twitterSite: string;
    };
    isCallableOnCompactButtonCall?: boolean;
    sendEmailFromAddress?: string;
    defaultInstallBanner: boolean;
    installBannerVersion?: number;
    installAppBannerBottom?: boolean;
    templates?: {
        tryAllMatchesForProperty?: boolean;
    };
    dateTimeFormat?: string;
    hideRentFrequency?: boolean;
    hideSaleCompletionStatus?: boolean;
    hideRentCompletionStatus?: boolean;
    enableResidenceTypeFilter?: boolean;
    enableResidenceTypeDetail?: boolean;
    enableOccupancyStatusFilter?: boolean;
    enableFurnishingStatusForRent?: boolean;
    enableFurnishingStatusForSale?: boolean;
    enableVerifiedSorting?: boolean;
    limitVerifiedSortingToRegions?: Array<string>;
    enableReportProperty?: boolean;
    enableMapBasedSearch?: boolean;
    disablePOTW?: boolean;
    enableInactiveFilter?: boolean;
    showAllPropsFilter?: boolean;
    targetingParamsPrefix?: string;
    enableUserRolesInMobileRegistration?: boolean;
    enableFloorPlanFilter?: boolean;
    campaignURL?: string;
    awardsCampaignURL?: string;
    recommendedPropertiesCountDesktop?: number;
    recommendedPropertiesCountMobile?: number;
    recommendedPropertiesSortBy?: Values<typeof SortByValues> | null | undefined;
    enableSearchRecommendedPropertiesWithLocationFallback?: boolean;
    loginFormType?: Values<typeof LoginFormType>;
    multipleUserRoleSelectOnRegistration?: boolean;
    propertyNavbarVisibleIfNotDetached?: boolean;
    mainCityLocationIDs?: Array<string>;
    enabledVerificationBadges?: Array<PropertyVerificationBadge>;
    showSearchBreadCrumbsOnBottom?: boolean;
    enableQuickFilters?: boolean;
    disableTransactionHistoryFilter?: boolean;
    leaderboardPageSize?: number;
    leaderboardSmallPageSize?: number;
    leaderboardAgentPageSize?: number;
    headerMaxLocationLevel?: number;
    enableAuthenticationLinkedAccountsInfoBanner?: boolean;
    enableMultiLanguageLogo?: boolean;
    showProductLabels?: boolean;
    enableProductLabelScoreSorting?: boolean;
    keywordFilterAmenitiesBlacklist?: [string];
    propertyPermitsInformationNavBarSectionTitle?: (i18n: I18n) => string;
    propertyPermitSectionTitle?: (i18n: I18n) => string;
    enableDesktopWhatsappButton?: boolean;
    postLoginNotificationMessage?: (i18n: I18n) => string[] | null;
    hideAgencyPermits?: boolean;
    displayEmaiLinkInLoginOptions?: boolean;
    defaultMapCenter: { lat: number; lng: number };
    expandInternalLinks?: boolean;
    enableNonBusinessUserExperience?: boolean;
    autoAdjustableListingCards?: boolean;
    fallbackPropertyReferenceNumber: (property: CommonAdData) => string;
    showOnlyProxyPhoneContactDetail?: boolean;
    projectPageSectionVisibility?: { [section in ProjectPageSections]: boolean };
    showSetProfileDialogBeforeOTPEmailRegister?: boolean;
    showRecommendedPropertiesAsRemarketed?: boolean;
    showPurposeInSearchBox?: boolean;
    enableSearchHitsHeaderNextToTitle?: boolean;
    usePrefixedCommonTargetingParams?: boolean;
    homepagePopularLocations?: string[];
    bottomBannerTrackingParams?: { [key: string]: string };
    enableSearchPageInterposedDeveloperListings?: boolean;
    routePathPrefixesWithExternallyManagedSession?: string[];
    locationSearchFuzzySettings?: QueryFilterFuzzyOptions;
    fetchCategoryFields?: boolean;
    useBottomSheetsInsteadOfPopUpsOnMweb?: boolean;
};

export type LogoButtonProps = {
    onClick?: (event: React.SyntheticEvent<any>) => void;
    style?: string;
    title?: string;
    className?: string;
};

export type AlternativeSite = {
    /**
     * The name of the country.
     */
    name: string;
    /**
     * The web address.
     */
    site: string;
    /**
     * The style for the country flag.
     */
    style: any;
    /**
     * Indicates whether the cross site link should be a dofollow link.
     */
    isDofollowLink: boolean;
    /**
     * True if the link should be hidden for the other sites
     */
    hide?: boolean;
};

export { AreaGuideContentMapping };
