import React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import { Header } from 'horizontal/header';
import { ChatCenter } from 'horizontal/chat/widgets/large';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

import styles from './styles/chatPageDesktop.cssm';

const ChatPageDesktop = {
    head: () => (
        <>
            <title>Chat with someone</title>
            <GTMDataLayer pageType={PageType.CHAT} />
        </>
    ),
    body: () => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <PageWidthWrapper className={styles.container}>
                <ChatCenter inboxPageSize={20} ensureFirebaseAuth={true} />
            </PageWidthWrapper>
        </Flex>
    ),
} as const;

export default ChatPageDesktop;
