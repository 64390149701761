import * as React from 'react';
import { useSelector } from 'react-redux';
import { PageWidthWrapper } from 'strat/generic';

import { selectPageType } from './state/selectors';
import SitemapPageType from './sitemapPageType';
import SitemapMostPopular from './sitemapMostPopular';
import SitemapCategories from './sitemapCategories';
import SitemapProvinces from './sitemapProvinces';
import SitemapCities from './sitemapCities';
import SitemapPopularSearches from './sitemapPopularSearches';
import styles from './styles/sitemapContent.cssm';

const SitemapContent = () => {
    const sitemapCategory = useSelector(selectPageType);

    let content = null;
    switch (sitemapCategory) {
        case SitemapPageType.MOST_POPULAR:
            content = <SitemapMostPopular />;
            break;
        case SitemapPageType.CATEGORIES:
            content = <SitemapCategories />;
            break;
        case SitemapPageType.PROVINCES:
            content = <SitemapProvinces />;
            break;
        case SitemapPageType.CITIES:
            content = <SitemapCities />;
            break;
        case SitemapPageType.POPULAR_SEARCHES:
            if (CONFIG.runtime.WITH_SITEMAP_POPULAR_SEARCHES) {
                content = <SitemapPopularSearches />;
            }
            break;
        default:
            content = null;
            break;
    }

    return <PageWidthWrapper className={styles.pageWidthWrapper}>{content}</PageWidthWrapper>;
};

export default SitemapContent;
