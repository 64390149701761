import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { LocalCurrency, LocalPrice } from 'strat/i18n/money';
import type { PropertyData } from 'strat/property/types';
import withGTMPropertyTrendsTracking from 'strat/gtm/withGTMPropertyTrendsTracking';
import { PriceContainer } from 'strat/misc';

import useAverageRentValue from './useAverageRentValue';
import { selectHasMonthlyAveragePrice } from './selectors';
import styles from './styles/averageRent.cssm';

type Props = {
    trackRentalYieldInteraction: () => void;
    className: string;
    noValueClassName: string;
};

type AvgContainerProps = {
    price: number;
};

const renderFrequency = () => (
    <span className={styles.frequency} aria-label="Frequency">
        <Trans>Yearly</Trans>
    </span>
);

/**
 * Displays average rent (currency, price, frequency) based on the language.
 * Average rent frequency is calculated on an yearly frequency.
 * en -> USD 100.000 Yearly
 * bn/ar -> 100.000 USD Yearly
 */
const AvgRentContainer = (props: AvgContainerProps) => (
    <div className={styles.avgRentContainer}>
        <PriceContainer currency={<LocalCurrency />} price={<LocalPrice price={props.price} />} />
        {renderFrequency()}
    </div>
);

const Text = ({
    trackRentalYieldInteraction,
    className,
    noValueClassName,
}: Props): React.ReactElement => {
    const hasAveragePrice = useSelector(selectHasMonthlyAveragePrice);

    React.useEffect(() => {
        trackRentalYieldInteraction();
    }, [trackRentalYieldInteraction]);

    return (
        <div className={classNames(className, { [noValueClassName]: !hasAveragePrice })}>
            <span>
                <Trans>
                    Average rent is an estimate of the expected yearly income if this property is
                    rented. This calculation is based on currently advertised prices on Bayut and
                    does not reflect actual market transactions.
                </Trans>
            </span>
            {!hasAveragePrice && (
                <div className={styles.text}>
                    <Trans>
                        Currently, the data for similar properties is insufficient to generate an
                        accurate average yearly rent for this unit.
                    </Trans>
                </div>
            )}
        </div>
    );
};

// @ts-expect-error - TS2345 - Argument of type '({ trackRentalYieldInteraction, className, noValueClassName, }: Props) => React.ReactElement' is not assignable to parameter of type 'AbstractComponent<any, any>'.
const AverageRentText = withGTMPropertyTrendsTracking(Text);

const AverageRentValue = ({ property }: { property: PropertyData }) => {
    const { externalID, area } = property;
    const [averageRentValue, loading] = useAverageRentValue(externalID, area);
    const value = averageRentValue || <Trans>Not available</Trans>;

    if (loading) {
        return <div className={styles.loadingAnimation} />;
    }

    return isNaN(value) ? value : <AvgRentContainer price={parseInt(value, 10)} />;
};

export { AverageRentText, AverageRentValue };
