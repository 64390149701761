const emptyDefaultCatalogs = { en: {}, ar: {}, ur: {}, bn: {} } as const;

const getCatalogs = () => {
    if (process.env.IS_SERVER) {
        // @ts-expect-error - TS2339 - Property 'catalogs' does not exist on type 'Global'.
        return global.catalogMessages || emptyDefaultCatalogs;
    }

    return window.catalogMessages || emptyDefaultCatalogs;
};

export default getCatalogs;
