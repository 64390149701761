import * as React from 'react';

import Triggers from './triggers';
import withGTM from './withGTM';

export type GTMCommuteSurveyTrackingProps = {
    trackCommuteSurveyResponse: (value: string, name: string) => void;
    trackCommuteSurveyImpression: () => void;
};

const withGTMCommuteSurvey = <T extends GTMCommuteSurveyTrackingProps>(
    component: React.ComponentType<T>,
): React.ComponentType<T> => {
    // @ts-expect-error - TS2339 - Property 'trigger' does not exist on type 'unknown'. | TS2700 - Rest types may only be created from object types.
    const GTMCommuteSurveyTracker = ({ trigger, ...props }: T) => {
        return React.createElement(component, {
            ...props,
            trackCommuteSurveyResponse: (response: string, userInput?: string) => {
                const value = userInput ? `${response}-${userInput}` : response;
                trigger(Triggers.COMMUTE_SURVEY_SUBMIT, {
                    value,
                    name: 'nbs_survey_response',
                });
            },
            trackCommuteSurveyImpression: () => {
                trigger(Triggers.COMMUTE_SURVEY_IMPRESSION, {
                    name: 'nbs_survey_impression',
                });
            },
        } as T);
    };
    // @ts-expect-error - TS2322 - Type 'AbstractComponent<Diff<T, GTMProps>, any>' is not assignable to type 'AbstractComponent<Diff<T, GTMCommuteSurveyTrackingProps>, any>'.
    return withGTM(GTMCommuteSurveyTracker);
};

export default withGTMCommuteSurvey;
