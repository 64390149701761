import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import Logo from '@app/branding/logo';
import { Flex, Text, BackButton, Header } from 'strat/components';

import { useIsAccountSuspended } from 'horizontal/user';
import useIsInAgencyPortal from 'horizontal/user/useIsInAgencyPortal';
import {
    selectAgencyCategoryFilters,
    selectAgencyCategoryFiltersIsLoaded,
} from 'horizontal/agencyPortal/agency/state';

import { CategoryPicker } from './categoryPicker';
import AdDetailsForm from './adDetailsForm';
import Context from './context';
import useAdCreationForm from './useAdCreationForm';
import { selectBundle } from './selectors';
import styles from './styles/adCreationForm.cssm';

const AdCreationForm = () => {
    const isInAgencyPortal = useIsInAgencyPortal();
    const agencyCategoryFilters = useSelector(selectAgencyCategoryFilters);
    const agencyCategoryFiltersIsLoaded = useSelector(selectAgencyCategoryFiltersIsLoaded);
    const adCreationSource = useSelector(selectBundle);
    const { isFillingForm, activeCategory, setActiveCategory, onBack } =
        useAdCreationForm(adCreationSource);

    const isUserBanned = useIsAccountSuspended();

    return (
        <Context.Provider value={[activeCategory, setActiveCategory]}>
            {!isInAgencyPortal && (
                <Header className={styles.header}>
                    <BackButton onBack={onBack} />
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                </Header>
            )}
            {isUserBanned ? (
                <Flex justifyCenter className={styles.title}>
                    <Text.XLarge>
                        <Trans>Your account has been suspended from posting new ads.</Trans>
                    </Text.XLarge>
                </Flex>
            ) : (
                <>
                    <Flex justifyCenter className={styles.title}>
                        <Text.XLarge uppercase bold>
                            <Trans>Post your ad</Trans>
                        </Text.XLarge>
                    </Flex>
                    <PageWidthWrapper className={styles.wrapper}>
                        <div className={styles.content}>
                            {isFillingForm ? (
                                <AdDetailsForm activeCategory={activeCategory} />
                            ) : (
                                (!isInAgencyPortal || agencyCategoryFiltersIsLoaded) && (
                                    <CategoryPicker
                                        displayCategories={
                                            isInAgencyPortal ? agencyCategoryFilters : null
                                        }
                                    />
                                )
                            )}
                        </div>
                    </PageWidthWrapper>
                </>
            )}
        </Context.Provider>
    );
};

export default AdCreationForm;
