import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import { PercentageProjectPaymentPlan } from 'strat/project/types';

import usePercentagePaymentTimeline, {
    PercentagePaymentPlanMilestone,
} from '../usePercentagePaymentTimeline';

import { MilestoneAxisSegment } from './paymentPlanTable';
import styles from './styles/paymentPlanTable.cssm';

type MilestoneContentProps = {
    milestone: PercentagePaymentPlanMilestone;
};

const MilestoneContent = ({ milestone }: MilestoneContentProps) => (
    <>
        <Flex justifySpaceBetween>
            <div className={styles.cell}>{milestone.title}</div>
            <div className={classNames(styles.cell, styles.boldText)}>
                {milestone.valuePercentage}%
            </div>
        </Flex>
        {milestone.projectCompletion && (
            <div className={styles.completionPercentage}>
                <Trans>{milestone.projectCompletion}% PROJECT COMPLETION</Trans>
            </div>
        )}
    </>
);

type Props = {
    paymentPlan: PercentageProjectPaymentPlan;
};

const PaymentPlanTable = ({ paymentPlan }: Props) => {
    const timeline = usePercentagePaymentTimeline(paymentPlan);

    return (
        <Flex column>
            <div className={styles.title}>
                <Trans>% of property value to be paid upon project completion</Trans>
            </div>
            <div>
                {timeline.map((milestone, index) => (
                    <Flex key={index} className={styles.element}>
                        <MilestoneAxisSegment />
                        <Flex column className={styles.content}>
                            <MilestoneContent milestone={milestone} />
                        </Flex>
                    </Flex>
                ))}
            </div>
        </Flex>
    );
};

export default PaymentPlanTable;
