import isNil from 'lodash/isNil';

import type { PhoneNumberType, PropertyPhoneNumberData } from 'strat/property/types';

export const PhoneNumberPriority = Object.freeze({
    FAVOR_FETCHED: 'favor-fetched',
    FAVOR_PROPERTY: 'favor-property',
});

const ALL_KEYS = ['mobileNumbers', 'phoneNumbers', 'proxyPhone', 'proxyMobile', 'whatsapp'];

const isNotEmpty = (phoneNumberData?: PropertyPhoneNumberData | null): boolean => {
    if (isNil(phoneNumberData)) {
        return false;
    }
    const data = phoneNumberData || {};
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'PropertyPhoneNumberData'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'PropertyPhoneNumberData'.
    return ALL_KEYS.filter((key) => data[key] && data[key].length).length > 0;
};

const selectData = (
    data1?: PropertyPhoneNumberData | null,
    data2?: PropertyPhoneNumberData | null,
): PropertyPhoneNumberData | null | undefined => {
    if (isNotEmpty(data1)) {
        return data1;
    }
    // @ts-expect-error - TS2322 - Type 'PropertyPhoneNumberData | {} | null | undefined' is not assignable to type 'PropertyPhoneNumberData | null | undefined'.
    return isNotEmpty(data2) ? data2 : {};
};

const selectPhoneNumberData = (
    propertyData: PropertyPhoneNumberData | null | undefined,
    fetchedData: PropertyPhoneNumberData | null | undefined,
    phoneNumberPriority: Values<typeof PhoneNumberPriority>,
): PropertyPhoneNumberData | null | undefined => {
    if (phoneNumberPriority === PhoneNumberPriority.FAVOR_FETCHED) {
        return selectData(fetchedData, propertyData);
    }
    return selectData(propertyData, fetchedData);
};

const mobileFirst = (phone: PhoneNumberType, mobile: PhoneNumberType) => {
    if (phone.type === mobile.type) {
        return 0;
    }

    return phone.type > mobile.type ? 1 : -1;
};

const mergePhones = (
    proxyNumber: string | null | undefined,
    numbers: Array<string>,
    showAllNumbers?: boolean,
): Array<string> => {
    if (proxyNumber) {
        return [proxyNumber];
    }
    if (numbers && numbers.length > 0) {
        return showAllNumbers ? numbers : numbers.slice(0, 1);
    }
    return [];
};
const mergePhoneNumbers = (
    propertyNumberData: PropertyPhoneNumberData | null | undefined,
    fetchedNumberData: PropertyPhoneNumberData | null | undefined,
    phoneNumberPriority: Values<typeof PhoneNumberPriority>,
    showAllNumbers?: boolean,
): Array<PhoneNumberType> => {
    const usedPhoneNumber =
        selectPhoneNumberData(propertyNumberData, fetchedNumberData, phoneNumberPriority) || {};
    const phoneNumbers = {
        mobileNumbers: mergePhones(
            // @ts-expect-error - TS2339 - Property 'proxyMobile' does not exist on type '{}'.
            usedPhoneNumber.proxyMobile,
            // @ts-expect-error - TS2339 - Property 'mobileNumbers' does not exist on type '{}'.
            usedPhoneNumber.mobileNumbers,
            showAllNumbers,
        ),
        phoneNumbers: mergePhones(
            // @ts-expect-error - TS2339 - Property 'proxyPhone' does not exist on type '{}'.
            usedPhoneNumber.proxyPhone,
            // @ts-expect-error - TS2339 - Property 'phoneNumbers' does not exist on type '{}'.
            usedPhoneNumber.phoneNumbers,
            showAllNumbers,
        ),
        // @ts-expect-error - TS2339 - Property 'proxyMobile' does not exist on type '{}'. | TS2339 - Property 'proxyMobile' does not exist on type '{}'.
        proxyMobile: usedPhoneNumber.proxyMobile ? [usedPhoneNumber.proxyMobile] : [],
        // @ts-expect-error - TS2339 - Property 'proxyPhone' does not exist on type '{}'. | TS2339 - Property 'proxyPhone' does not exist on type '{}'.
        proxyPhone: usedPhoneNumber.proxyPhone ? [usedPhoneNumber.proxyPhone] : [],
    } as const;
    const phoneNumbersArray = Object.keys(phoneNumbers).map(
        (type: string): PhoneNumberType => ({
            type,
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly mobileNumbers: string[]; readonly phoneNumbers: string[]; readonly proxyMobile: any[]; readonly proxyPhone: any[]; }'.
            numbers: phoneNumbers[type],
        }),
    );

    return phoneNumbersArray.sort(mobileFirst);
};

export default mergePhoneNumbers;
