export const LeaderboardRankingTypes = Object.freeze({
    LAST_3_MONTHS: 'last3Months',
    ALL_TIME: 'allTime',
});

export enum MiniLeaderBoardXpEarnedKey {
    XP_EARNED_ALL_TIME = 'xp_earned_overall',
    XP_EARNED_LAST_3_MONTHS = 'xp_earned_past_3_months',
    COMBO_XP = 'combo_xp',
}

export enum MiniLeaderBoardRankKey {
    GLOBAL_RANKING = 'truBrokerRanking',
    GLOBAL_RANKING_LAST_3_MONTHS = 'truBrokerRankingLast3Months',
    LOCATION_CATEGORY_PURPOSE_RANKING = 'cubeLocationCategoryPurposeTruBrokerRanking',
    CATEGORY_RANKING = 'cubeCategoryTruBrokerRanking',
    PURPOSE_RANKING = 'cubePurposeTruBrokerRanking',
    CATEGORY_PURPOSE_RANKING = 'cubeCategoryPurposeTruBrokerRanking',
    LOCATION_RANKING = 'cubeLocationTruBrokerRanking',
    LOCATION_CATEGORY_RANKING = 'cubeLocationCategoryTruBrokerRanking',
    LOCATION_PURPOSE_RANKING = 'cubeLocationPurposeTruBrokerRanking',
}

/**
 * This object is different from `STRAT_RANKING_FIELDS_MAPPING` from `useAgentRank`.
 * This is used to map the ranking fields that must be used for the mini leaderboard
 * from the agent detail page. `STRAT_RANKING_FIELDS_MAPPING` is used to map the
 * ranking fields that should be used in agent search.
 */
export const MiniLeaderboardIndexRankKeyMapping: Record<
    Values<typeof MiniLeaderBoardRankKey>,
    string
> = Object.freeze({
    [MiniLeaderBoardRankKey.GLOBAL_RANKING]: 'trubroker_first_ranking_overall',
    [MiniLeaderBoardRankKey.GLOBAL_RANKING_LAST_3_MONTHS]: 'trubroker_ranking_past_3_months',
    [MiniLeaderBoardRankKey.LOCATION_CATEGORY_PURPOSE_RANKING]:
        'top_location_category_purpose_trubroker_first_ranking',
    [MiniLeaderBoardRankKey.CATEGORY_RANKING]: 'top_category_trubroker_first_ranking',
    [MiniLeaderBoardRankKey.PURPOSE_RANKING]: 'top_purpose_trubroker_first_ranking',
    [MiniLeaderBoardRankKey.CATEGORY_PURPOSE_RANKING]:
        'top_category_purpose_trubroker_first_ranking',
    [MiniLeaderBoardRankKey.LOCATION_RANKING]: 'top_location_trubroker_first_ranking',
    [MiniLeaderBoardRankKey.LOCATION_CATEGORY_RANKING]:
        'top_location_category_trubroker_first_ranking',
    [MiniLeaderBoardRankKey.LOCATION_PURPOSE_RANKING]:
        'top_location_purpose_trubroker_first_ranking',
});
