import * as React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { GlobalState } from '@app/state';
import { useIsStratCreditUser } from 'strat/user';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import type { Ad, ProductPurchase, PackageTypeValues, ProductPackage } from 'horizontal/types';
import { getAdVars } from 'horizontal/gtm/utils';
import { AdVirtualState } from 'horizontal/types';
import { isRefreshPackageOnly } from 'horizontal/packages/utils';
import { useNavigateToApplyAdProductCreditCosts } from 'horizontal/adProductCreditCost';
import useCreditSystemGuard from 'horizontal/authorization/useCreditSystemGuard';

import { useNavigateToPackageSelection } from './navigation';
import {
    selectConsumableLimitPackagesByAd,
    selectProductPurchaseById,
    selectProductPurchasePackageType,
    selectProductPurchases,
} from './selectors';

const useProductPurchases = () => useSelector(selectProductPurchases);

const useConsumableLimitPackages = (ad?: Ad | null): Array<ProductPurchase> =>
    useSelector(
        React.useCallback(
            (state: GlobalState) => selectConsumableLimitPackagesByAd(state, { ad }),
            [ad],
        ),
    );

const useNavigateToAdUpgrade = (ad?: Ad | null, withoutSkipButton = false) => {
    const i18n = useI18n();
    const isStratCreditUser = useIsStratCreditUser();
    const navigateToPackageSelection = useNavigateToPackageSelection();
    const navigateToApplyAdProductCreditCosts = useNavigateToApplyAdProductCreditCosts();
    const brandName = settings.getAgencyPortalBrandName(i18n);
    const withCreditSystemGuard = useCreditSystemGuard(
        t(i18n)`You can only upgrade ads through ${brandName} Pro.`,
    );

    return React.useCallback(() => {
        if (!ad) {
            return;
        }
        withCreditSystemGuard(() => {
            trigger(Triggers.CLICK_SELL_FASTER, {
                ...getAdVars(ad),
                view_section: ViewSections.BODY,
            });
            if (!isStratCreditUser) {
                navigateToPackageSelection({ externalID: ad.externalID, withoutSkipButton });
                return;
            }
            navigateToApplyAdProductCreditCosts({ adExternalID: ad.externalID });
        });
    }, [
        ad,
        withCreditSystemGuard,
        withoutSkipButton,
        isStratCreditUser,
        navigateToPackageSelection,
        navigateToApplyAdProductCreditCosts,
    ]);
};

const useProductPurchase = (id?: string | null): ProductPurchase | undefined =>
    useSelector(
        React.useCallback(
            (state: GlobalState) => {
                if (!id) {
                    return undefined;
                }

                return selectProductPurchaseById(state, id);
            },
            [id],
        ),
    );

const useProductPurchasePackageType = (id?: string | null): PackageTypeValues | null =>
    useSelector((state: GlobalState) => {
        if (!id) {
            return null;
        }

        return selectProductPurchasePackageType(state, id);
    });

const useShouldShowBTTForFeaturedAd = (
    productPackage: ProductPackage,
    ad: Ad | null | undefined,
) => {
    return (
        CONFIG.build.STRAT_ENABLE_FEATURED_BTT &&
        isRefreshPackageOnly(productPackage.productItems) &&
        ad?.virtualState === AdVirtualState.FEATURED
    );
};

export {
    useConsumableLimitPackages,
    useProductPurchases,
    useNavigateToAdUpgrade,
    useProductPurchase,
    useProductPurchasePackageType,
    useShouldShowBTTForFeaturedAd,
};
