import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import autoBind from 'react-autobind';
import classNames from 'classnames';

import IconArrowUp from '@app/assets/icons/iconTriangleUp.svg';
import { Dropdown, HidingMethod } from 'strat/modal';
import { stopEventPropagation } from 'strat/util';

import styles from './styles/droppableFilter.cssm';

export const DropdownPosition = Object.freeze({
    TOP: 'top',
    BOTTOM: 'bottom',
});
/**
 * Properties for {@see DroppableFilter}.
 */
type Props = {
    /**
     * Sets whether the drop down should be open.
     */
    open?: boolean | undefined;
    /**
     * Unique name for this droppable filter.
     */
    name?: string;
    /**
     * Text to display when there is no value.
     */
    placeholder?: string | React.ReactElement;
    /**
     * Value to display (this hides the placeholder).
     */
    value?: string | null | undefined;
    /**
     * CSS class name to style the value container
     */
    valueClassName?: string;
    /**
     * Method to call to render the current value.
     * (value prop takes precedence).
     */
    renderValue?: () => React.ReactElement | null;
    /**
     * Callback for when the drop down is opened/closed.
     */
    onOpenChanged: (open: boolean) => void;
    /**
     * Element to be displayed (as a drop-down) when
     * this is clicked.
     */
    children?: React.ReactElement;
    /**
     * Accessibility label
     */
    label?: string;
    /**
     * Indiciates whether this filter is clickable.
     */
    clickable?: boolean;
    /**
     * Optional CSS class name for additional styling.
     */
    className?: string;
    /**
     * Whether the dropdown of this droppable filter should be a stacked dismissible.
     */
    stacked?: boolean;
    /**
     * The stack group of the stacked dismissible dropdown.
     */
    stackGroup?: string;
    /**
     * Optional CSS class for DropDown input
     */
    dropDownInputClassName?: string;
    /**
     * Optional CSS class for DropDown container
     */
    dropDownClassName?: string;
    /**
     * Current active design
     */
    design?: string;
    /**
     * Do not display any type of icon
     */
    noIcon?: boolean;
    /**
     * Current active icon. Default: IconArrow Up/Down
     */
    icon?: React.ReactElement | null;
    /**
     * Show icon left on the side of the text.
     */
    showIconLeft?: boolean;
    /**
     * Render custom main input element
     */
    renderInput?: () => React.ReactElement;
    /**
     * Optional CSS class name for additional styling of the icon
     */
    iconClassName?: string;
    /**
     * Allows to display the dropdown above the input,
     * in case it is at the bottom of the page.
     */
    position?: Values<typeof DropdownPosition>;
    /**
     * Whether this filter has a selected value or not
     */
    selected?: boolean;
    /**
     * What hiding method to use for the dropdown.
     */
    hidingMethod?: Values<typeof HidingMethod>;
    /**
     * Applies secondary filter style, with smaller font
     */
    secondary?: boolean;
};

/**
 * Simple non-styled class which renders a disabled input box,
 * with the intention that a drop down appears when clicked.
 *
 * Specified children will be used as the drop down.
 */
class DroppableFilter extends Component<Props> {
    static defaultProps = {
        stacked: false,
        position: DropdownPosition.BOTTOM,
    };

    /**
     * Initializes a new instance of {@see DroppableFilter}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);
    }

    /**
     * Toggles the drop down, closing it if its open
     * and opening it if its closed.
     */
    toggleDropDown(event: React.MouseEvent<any>): void {
        stopEventPropagation(event);
        this.props.onOpenChanged(!this.props.open);
    }

    onDismissed() {
        this.props.onOpenChanged(false);
    }

    /**
     * Renders the input element and the drop down itself
     * if it needs to be open.
     */
    render(): React.ReactElement {
        let value = !isNil(this.props.value) ? (
            <span className={this.props.valueClassName || 'fontCompensation'}>
                {this.props.value}
            </span>
        ) : null;

        if (this.props.renderValue) {
            value = this.props.renderValue();
        }

        const defaultInputClassname = classNames(styles.input, this.props.design, {
            [styles.placeholder]: isNil(value),
            [styles.secondary]: this.props.secondary,
        });
        const className = this.props.dropDownInputClassName || defaultInputClassname;

        const dropDownClassName =
            this.props.dropDownClassName ||
            classNames(styles.dropDown, {
                [styles.positionTop]: this.props.position === DropdownPosition.TOP,
            });

        const iconClassName = classNames(this.props.iconClassName, {
            [styles.iconArrowUp]: this.props.open,
            [styles.iconArrowDown]: !this.props.open,
        });
        const icon = this.props.icon || (
            <IconArrowUp className={classNames(iconClassName, this.props.design)} />
        );

        const mainElement = this.props.renderInput ? (
            this.props.renderInput()
        ) : (
            <div
                key="input"
                role="button"
                aria-haspopup="true"
                aria-label={this.props.label}
                className={this.props.className || classNames(styles.container, this.props.design)}
                onClick={this.props.clickable ? this.toggleDropDown : () => {}}
            >
                {!this.props.noIcon && this.props.showIconLeft && icon}
                <span className={className}>
                    {/* do not remove, this makes ellipsis overflow
                in case the parent <span> is a flex item */}
                    {value || <span className="fontCompensation">{this.props.placeholder}</span>}
                </span>
                {!this.props.noIcon && !this.props.showIconLeft && icon}
            </div>
        );
        const elements = [mainElement];

        elements.push(
            <Dropdown
                key={'droppable-filter-dropdown'}
                open={this.props.open}
                stackGroup={this.props.stackGroup}
                className={dropDownClassName}
                onDismissed={this.onDismissed}
                hidingMethod={this.props.hidingMethod}
            >
                {this.props.children}
            </Dropdown>,
        );

        // @ts-expect-error - TS2740 - Type '(Element | Node)[]' is missing the following properties from type 'Node': baseURI, childNodes, firstChild, isConnected, and 46 more.
        return elements;
    }
}

export default DroppableFilter;
