import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Trans } from '@lingui/macro';

import { GTMPackageCartDataLayer } from 'horizontal/gtm/components';
import ViewBusinessPackageCart from 'horizontal/businessPackages/viewBusinessPackageCart';
import { BusinessPackagesHeader } from 'horizontal/businessPackages';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/viewBusinessPackageCartPage.cssm';

const ChoosePackageMultiPage = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
            <GTMPackageCartDataLayer />
        </>
    ),
    body: () => (
        <>
            <BusinessPackagesHeader label={<Trans>Your Cart</Trans>} />
            <PageWidthWrapper className={styles.wrapper}>
                <ViewBusinessPackageCart />
            </PageWidthWrapper>
        </>
    ),
} as const;

export default ChoosePackageMultiPage;
