import { getItem, setItem } from 'strat/misc/localStorage';

import { AutoTranslateDescriptionOption } from './constants';

const validOptions: Array<ExtractValues<typeof AutoTranslateDescriptionOption>> = [
    AutoTranslateDescriptionOption.ENABLED,
    AutoTranslateDescriptionOption.DISABLED,
];

export const getIsAutoTranslateDescriptionFeatureEnabled = () =>
    CONFIG.build.STRAT_ENABLE_AUTO_TRANSLATE_DESCRIPTION_FRONTEND &&
    CONFIG.build.STRAT_ENABLE_AUTO_TRANSLATE_DESCRIPTION;

export const setAutoTranslateDescriptionOption = (
    option: ExtractValues<typeof AutoTranslateDescriptionOption>,
) => {
    if (validOptions.includes(option)) {
        setItem(AutoTranslateDescriptionOption.KEY, option);
    }
};

export const getAutoTranslateDescriptionOption = () => {
    const option = getItem(AutoTranslateDescriptionOption.KEY);

    if (validOptions.includes(option)) {
        return option;
    }

    return AutoTranslateDescriptionOption.NOT_SET;
};
