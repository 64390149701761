import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';
import {
    RoomSectionSelection,
    UnitPlan,
    UnitPlanResources,
    UnitPlanRoom,
    UnitPlanRoomPolygon,
    RawUnitPlan,
    RawUnitPlanRoom,
} from 'strat/unitPlan/types';
import EMPTY_ARRAY from 'strat/empty/array';
import { PropertyPhotoData } from 'strat/property';

const selectRawUnitPlan = (state: GlobalState): RawUnitPlan | null => state.unitPlan.data || null;

const computePolygonArea = (polygon: UnitPlanRoomPolygon): number => {
    const points = [...polygon.polygon, polygon.polygon[0]];

    let area = 0;

    for (let i = 0; i < points.length - 1; i++) {
        const [x1, y1] = points[i];
        const [x2, y2] = points[i + 1];

        area += (x2 + x1) * (y2 - y1);
    }

    return Math.abs(area / 2);
};

const computeRoomArea = (room: RawUnitPlanRoom): number =>
    room.outline.reduce((acc, polygon) => acc + computePolygonArea(polygon), 0);

const selectUnitPlan = createSelector(
    selectRawUnitPlan,
    (unitPlan: RawUnitPlan | null): UnitPlan | null => {
        if (!unitPlan) {
            return null;
        }

        return {
            ...unitPlan,
            rooms: unitPlan.rooms.map((room) => ({
                ...room,
                area: computeRoomArea(room),
            })),
        };
    },
);

const selectUnitPlanResources = createSelector(
    selectUnitPlan,
    (unitPlan: UnitPlan | null): UnitPlanResources | null => {
        if (!unitPlan) {
            return null;
        }

        const unitPlanOBJ = unitPlan.resources.find(
            (resource) => resource.name === 'unit_plan.obj',
        );

        const unitPlanMTL = unitPlan.resources.find(
            (resource) => resource.name === 'unit_plan.mtl',
        );

        if (!unitPlanOBJ || !unitPlanMTL) {
            return null;
        }

        return {
            objURL: unitPlanOBJ.url,
            mtlURL: unitPlanMTL.url,
        };
    },
);

const selectCurrentSection = createSelector(
    (state: GlobalState) => state.unitPlan,
    (unitPlan) => unitPlan.selectedSection,
);

const selectCurrentRoom = createSelector(
    selectUnitPlan,
    selectCurrentSection,
    (
        unitPlan: UnitPlan | null,
        selectedSection: RoomSectionSelection | null,
    ): UnitPlanRoom | null => {
        if (!unitPlan || !selectedSection) {
            return null;
        }

        return unitPlan.rooms.find((room) => room.id === selectedSection.roomID) || null;
    },
);

const selectWallMeasurementsVisible = createSelector(
    [(state: GlobalState) => state.unitPlan, selectCurrentRoom],
    (unitPlan, currentRoom) => {
        if (!currentRoom) {
            return false;
        }

        return unitPlan.wallMeasurementsVisible;
    },
);

const selectImageSidebarVisible = createSelector(
    [(state: GlobalState) => state.unitPlan, selectCurrentRoom],
    (unitPlan, currentRoom) => {
        if (!currentRoom) {
            return false;
        }

        return unitPlan.imageSidebarVisible;
    },
);

const selectCurrentRoomPolygons = createSelector(
    selectUnitPlan,
    selectCurrentRoom,
    (unitPlan: UnitPlan | null, room: UnitPlanRoom | null): readonly UnitPlanRoomPolygon[] => {
        if (!unitPlan) {
            return EMPTY_ARRAY;
        }

        if (room) {
            return room.outline;
        }

        return unitPlan.rooms.flatMap((room) => room.outline);
    },
);

const selectCurrentImages = createSelector(
    selectUnitPlan,
    selectCurrentRoom,
    (unitPlan, room): readonly PropertyPhotoData[] => {
        if (!unitPlan) {
            return EMPTY_ARRAY;
        }

        if (room) {
            return room.image_mappings.map((mapping) => ({ id: mapping.image_id }));
        }

        return unitPlan.rooms.flatMap((room) =>
            room.image_mappings.map((mapping) => ({ id: mapping.image_id })),
        );
    },
);

export {
    selectUnitPlan,
    selectUnitPlanResources,
    selectCurrentSection,
    selectCurrentRoom,
    selectCurrentImages,
    selectCurrentRoomPolygons,
    selectWallMeasurementsVisible,
    selectImageSidebarVisible,
};
