import React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import { MilestonesProjectPaymentPlan } from 'strat/project/types';

import useMilestonesPaymentTimeline, {
    MilestonesPaymentPlanMilestone,
} from '../useMilestonesPaymentTimeline';
import MilestoneInfo from '../milestoneInfo';

import { MilestoneAxisSegment } from './paymentPlanTable';
import PaymentInstallmentsDetails from './paymentInstallmentsDetails';
import styles from './styles/paymentPlanTable.cssm';

type MilestoneContentProps = {
    milestone: MilestonesPaymentPlanMilestone;
};

const MilestoneContent = ({ milestone }: MilestoneContentProps) => (
    <Flex justifySpaceBetween>
        <Flex className={styles.cell}>
            {milestone.title}
            <MilestoneInfo
                milestone={milestone}
                renderInfo={(payment: MilestonesPaymentPlanMilestone) => (
                    <PaymentInstallmentsDetails milestone={payment} />
                )}
            />
        </Flex>
        <div className={classNames(styles.cell, styles.boldText)}>{milestone.valuePercentage}%</div>
    </Flex>
);

type Props = {
    paymentPlan: MilestonesProjectPaymentPlan;
};

const MilestonesPaymentPlan = ({ paymentPlan }: Props) => {
    const timeline = useMilestonesPaymentTimeline(paymentPlan);

    return (
        <div>
            {timeline.map((milestone, index) => (
                <Flex key={index} className={styles.element}>
                    <MilestoneAxisSegment />
                    <Flex column className={styles.content}>
                        <MilestoneContent milestone={milestone} />
                    </Flex>
                </Flex>
            ))}
        </div>
    );
};

export default MilestonesPaymentPlan;
