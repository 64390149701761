import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { useFormattedPrice, formatDate } from 'horizontal/util';
import type { Payment } from 'horizontal/payment/types';

import formatPaymentSource from './formatPaymentSource';
import PaymentEntryBreakdown from './paymentEntryBreakdown';
import styles from './styles/paymentEntry.cssm';

type Props = {
    readonly payment: Payment;
};

const PaymentEntry = ({ payment }: Props) => {
    const i18n = useI18n();
    const details = [
        { key: t(i18n)`Date`, value: formatDate(i18n, payment.createdAt * 1000) },
        { key: t(i18n)`Order ID`, value: payment.orderExternalID },
        { key: t(i18n)`Payment method`, value: formatPaymentSource(i18n, payment.source) },
    ];
    return (
        <Flex column className={styles.wrapper}>
            <Flex column className={styles.header}>
                <Flex justifySpaceBetween>
                    <Text.Regular gray>{t(i18n)`Amount`}</Text.Regular>
                    <Text.Large bold>{useFormattedPrice(payment.total)}</Text.Large>
                </Flex>
                <PaymentEntryBreakdown packages={payment.items} />
            </Flex>
            {details.map(({ key, value }, index) => (
                <Flex key={index} justifySpaceBetween className={styles.detailLine}>
                    <Text.Small gray>{key}</Text.Small>
                    <Text.Small>{value || '-'}</Text.Small>
                </Flex>
            ))}
        </Flex>
    );
};

export default PaymentEntry;
