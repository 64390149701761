import type { Locales } from '@lingui/core';

import parseFormattedNumber from './parseFormattedNumber';
import { makeNumberFormatterFactory } from './numberFormatter';

const westernArabicNumeralsRegex = /1|2|3|4|5|6|7|8|9|0/;
const arabicNumeralsRegex = /٠|١|٢|٣|٤|٥|٦|٧|٨|٩/;

/**
 * Formats a user input number. It tries to preserve the same locales used by
 * the user to write the numerals.
 */
const formatUserInputNumber = (
    value: string,
    defaultLocales: Locales | null | undefined,
): string => {
    if (!value || value.length === 0) {
        return value;
    }

    let inferredLocales = defaultLocales;
    if (arabicNumeralsRegex.test(value)) {
        inferredLocales = 'ar-SA';
    } else if (westernArabicNumeralsRegex.test(value)) {
        inferredLocales = 'en';
    }

    const numericValue = parseFormattedNumber(value, inferredLocales);
    if (isNaN(numericValue)) {
        return value;
    }

    const formatter = makeNumberFormatterFactory(inferredLocales)();
    const newValue = formatter.format(numericValue);

    return newValue;
};

export default formatUserInputNumber;
