import { useSelector } from 'react-redux';
import { selectSearchSortOption } from '@sector-labs/fe-search-redux/state';
import brandingSettings from '@app/branding/settings';

import type { Category } from 'horizontal/types';
import type { SearchRouteParams } from 'horizontal/routes';
import { selectDefaultSortOption, SortOptionKey } from 'horizontal/search/sorting';
import { SearchPageSearchType } from 'horizontal/search/types';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { CategoryRole, AdProduct } from 'horizontal/types';

const determineSearchPageType = (
    category?: Category | null,
    sortOption?: Values<typeof SortOptionKey> | null,
    searchRouteParams?: SearchRouteParams,
): Values<typeof SearchPageSearchType> => {
    if (searchRouteParams?.product === AdProduct.ALL) {
        return SearchPageSearchType.PROMOTED_ONLY;
    }

    if (sortOption === SortOptionKey.VERIFIED_ACCOUNT_FIRST) {
        return SearchPageSearchType.PROMOTED_ONLY;
    }

    const searchType =
        SearchPageSearchType.fromCategoryRole(
            (category?.roles || []).find((role) => CategoryRole.searchPageTypes().includes(role)),
        ) ?? brandingSettings.searchPageSearchType;

    /*
     * 1. If for the current category we have PAID_FIRST or PAID_FIRST_SLOTTED search type,
     * we should only apply the search type if the sort is MOST_RELEVANT
     * 2. The rest of the search types should be applied regardless of the sort.
     * 3. If there is no search type for the category, we should use the default one for the portal.
     * */

    if (
        CONFIG.runtime.STRAT_ENABLE_AUTOMATIC_EXPANDED_RADIUS &&
        searchType === SearchPageSearchType.FEATURED_REGULAR_GROUPED
    ) {
        return SearchPageSearchType.EXPANDED_RADIUS_PRODUCT_GROUPED;
    }

    const onlyAppliesForMostRelevant = [
        SearchPageSearchType.PAID_FIRST,
        SearchPageSearchType.PAID_FIRST_SLOTTED,
    ].includes(searchType);

    if (onlyAppliesForMostRelevant) {
        if (sortOption === SortOptionKey.MOST_RELEVANT) {
            return searchType;
        }
        return brandingSettings.searchPageSearchType;
    }

    return searchType;
};

const useSearchPageType = () => {
    const activeCategory = useCategoryFilterValue();
    const sortOption = useSelector(selectSearchSortOption);
    const defaultSortOption = useSelector(selectDefaultSortOption);

    return determineSearchPageType(activeCategory, sortOption?.key || defaultSortOption.key);
};

export { useSearchPageType };

export default determineSearchPageType;
