import StratDialogs from 'strat/dialogs/desktop';
import { registerDialog } from 'strat/dialogs/dialogContext';
import { KeycloakAuthDialog } from 'strat/auth/keycloak';
import { InternalErrorDialog } from 'strat/error';

import { ReportAdDialog } from 'horizontal/report';
import {
    ChatAskForMoreDialog,
    ChatAttachmentDialog,
    ChatBlockUserDialog,
    ChatGalleryDialog,
    ChatShareLocationDialog,
    ReportAndBlockUserDialog,
} from 'horizontal/chat/dialogs/large';
import { PhoneNumberDialog } from 'horizontal/contact';
import { UnlinkSocialLoginDialog } from 'horizontal/user/socialLogin';
import { CategoryPickerDialog } from 'horizontal/adCreation/categoryPicker';
import MarkAsSoldDialog from 'horizontal/adManagement/markAsSoldDialog';
import RemoveAdDialog from 'horizontal/adManagement/removeAdDialog';
import DisableAdDialog from 'horizontal/adManagement/disableAdDialog';
import LearnMoreDialog from 'horizontal/adManagement/learnMoreDialog';
import UserImageFailDialog from 'horizontal/user/userImageFailDialog';
import AdExtraFeaturesDialog from 'horizontal/adDetails/adExtraFeaturesDialog';
import AdCreationGroupedChoicesDialog from 'horizontal/fields/adCreationGroupedChoicesDialog';
import MileageInfoTooltipDialog from 'horizontal/fields/mileageInfoTooltipDialog';
import CTADialog from 'horizontal/adDetails/components/ctaDialog';
import Toast from 'horizontal/components/toast';

import DialogNames from './dialogNames';

const RegisteredReportAdDialog = registerDialog(DialogNames.REPORT_AD_DIALOG, ReportAdDialog);
const RegisteredReportUserDialog = registerDialog(
    DialogNames.REPORT_USER_DIALOG,
    ReportAndBlockUserDialog,
);
const RegisteredChatAttachmentDialog = registerDialog(
    DialogNames.CHAT_ATTACHMENT_DIALOG,
    ChatAttachmentDialog,
);

const RegisteredChatShareLocationDialog = registerDialog(
    DialogNames.CHAT_SHARE_LOCATION_DIALOG,
    ChatShareLocationDialog,
);
const RegisteredKeycloakLoginDialog = registerDialog(DialogNames.LOGIN, KeycloakAuthDialog);

const RegisteredChatGalleryDialog = registerDialog(
    DialogNames.CHAT_GALLERY_DIALOG,
    ChatGalleryDialog,
);

const RegisteredChatAskForMoreDialog = registerDialog(
    DialogNames.CHAT_ASK_FOR_MORE_DIALOG,
    ChatAskForMoreDialog,
);

const RegisteredChatBlockUserDialog = registerDialog(
    DialogNames.CHAT_BLOCK_USER_DIALOG,
    ChatBlockUserDialog,
);

const RegisteredPhoneNumberDialog = registerDialog(
    DialogNames.PHONE_NUMBER_DIALOG,
    PhoneNumberDialog,
);

const RegisteredMarkAsSoldDialog = registerDialog(DialogNames.MARK_AS_SOLD, MarkAsSoldDialog);

const RegisteredRemoveAdDialog = registerDialog(DialogNames.REMOVE_AD_DIALOG, RemoveAdDialog);

const RegisteredDisableAdDialog = registerDialog(DialogNames.DISABLE_AD_DIALOG, DisableAdDialog);

const RegisteredLearnMoreDialog = registerDialog(DialogNames.LEARN_MORE_DIALOG, LearnMoreDialog);

const RegisteredUnlinkSocialLoginDialog = registerDialog(
    DialogNames.UNLINK_SOCIAL_LOGIN_DIALOG,
    UnlinkSocialLoginDialog,
);

const RegisteredUserImageFailDialog = registerDialog(
    DialogNames.USER_IMAGE_FAIL_DIALOG,
    UserImageFailDialog,
);

const RegisteredCategoryPickerDialog = registerDialog(
    DialogNames.POST_AD_CATEGORY_PICKER_DIALOG,
    CategoryPickerDialog,
);

const RegisteredAdDetailesFeaturesDialog = registerDialog(
    DialogNames.AD_DETAILS_FEATURES_DIALOG,
    AdExtraFeaturesDialog,
);

const RegisteredPAAMileageInfoDialog = registerDialog(
    DialogNames.POST_AD_MILEAGE_INFO_DIALOG,
    MileageInfoTooltipDialog,
);

const RegisteredToast = registerDialog(DialogNames.TOAST, Toast);

const RegisteredExtraFeaturesDialog = registerDialog(
    DialogNames.AD_CREATION_GROUPED_CHOICES_DIALOG,
    AdCreationGroupedChoicesDialog,
);

const RegisteredCTADialog = registerDialog(DialogNames.CTA_DIALOG, CTADialog);

const RegisteredInternalErrorDialog = registerDialog(
    DialogNames.INTERNAL_ERROR_DIALOG,
    InternalErrorDialog,
);

const RegisteredDialogs = {
    ...StratDialogs,
    [DialogNames.REPORT_AD_DIALOG]: RegisteredReportAdDialog,
    [DialogNames.REPORT_USER_DIALOG]: RegisteredReportUserDialog,
    [DialogNames.CHAT_ATTACHMENT_DIALOG]: RegisteredChatAttachmentDialog,
    [DialogNames.CHAT_SHARE_LOCATION_DIALOG]: RegisteredChatShareLocationDialog,
    [DialogNames.CHAT_GALLERY_DIALOG]: RegisteredChatGalleryDialog,
    [DialogNames.CHAT_ASK_FOR_MORE_DIALOG]: RegisteredChatAskForMoreDialog,
    [DialogNames.CHAT_BLOCK_USER_DIALOG]: RegisteredChatBlockUserDialog,
    [DialogNames.LOGIN]: RegisteredKeycloakLoginDialog,
    [DialogNames.PHONE_NUMBER_DIALOG]: RegisteredPhoneNumberDialog,
    [DialogNames.MARK_AS_SOLD]: RegisteredMarkAsSoldDialog,
    [DialogNames.REMOVE_AD_DIALOG]: RegisteredRemoveAdDialog,
    [DialogNames.DISABLE_AD_DIALOG]: RegisteredDisableAdDialog,
    [DialogNames.LEARN_MORE_DIALOG]: RegisteredLearnMoreDialog,
    [DialogNames.UNLINK_SOCIAL_LOGIN_DIALOG]: RegisteredUnlinkSocialLoginDialog,
    [DialogNames.USER_IMAGE_FAIL_DIALOG]: RegisteredUserImageFailDialog,
    [DialogNames.POST_AD_CATEGORY_PICKER_DIALOG]: RegisteredCategoryPickerDialog,
    [DialogNames.AD_DETAILS_FEATURES_DIALOG]: RegisteredAdDetailesFeaturesDialog,
    [DialogNames.POST_AD_MILEAGE_INFO_DIALOG]: RegisteredPAAMileageInfoDialog,
    [DialogNames.TOAST]: RegisteredToast,
    [DialogNames.AD_CREATION_GROUPED_CHOICES_DIALOG]: RegisteredExtraFeaturesDialog,
    [DialogNames.CTA_DIALOG]: RegisteredCTADialog,
    [DialogNames.INTERNAL_ERROR_DIALOG]: RegisteredInternalErrorDialog,
} as const;

export default RegisteredDialogs;
