import { createSelector } from 'reselect';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import type { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import EMPTY_ARRAY from 'strat/empty/array';
import { LocationNodeData } from 'strat/property';
import { selectTruBrokerPurpose } from 'strat/search/selectors';
import { selectAgentUserProfile } from 'strat/user/selectors';
import { selectAgent } from 'strat/contactAgents/selectors';
import { selectProperty } from 'strat/property/selectors';
import { selectRouteParamsInput } from 'strat/search/url';
import { FilterValues } from 'strat/search';
import { HitValue } from 'strat/search/searchBox';
import { GlobalState } from 'strat/state';
import { TruBrokerLocations } from 'strat/leaderboard/types';

import { AgentSearchState } from './agent';

type GetFilterCollection = (state: GlobalState) => FilterCollection;

export const TabNames = Object.freeze({
    OVERVIEW: 'overview',
    PROFILE: 'profile',
    ACTIVE_LISTINGS: 'active_listings',
});
/**
 * Return whether a logged-in user is accessing their
 * own profile.
 */
export const selectIsOwnProfile = createSelector(
    selectAgent,
    selectAgentUserProfile,
    // DO NOT use in contexts requiring strict security.
    (agentData, loggedInAgent) =>
        CONFIG.build.STRAT_ENABLE_2022_AGENT_PROFILE_REDESIGN &&
        loggedInAgent != null &&
        agentData.external_id != null &&
        agentData.external_id === loggedInAgent.id,
);

export const selectIsOverviewTabAllowed = createSelector(
    selectAgent,
    selectIsOwnProfile,
    (agentData, isOwnProfle) =>
        isOwnProfle &&
        (agentData.activeInEmirate == TruBrokerLocations.DUBAI.name ||
            agentData.activeInEmirate == TruBrokerLocations.ABU_DHABI.name),
);

/**
 * Return whether a logged-in user is accessing their
 * own listing.
 */
export const selectIsAgentOwnListing = createSelector(
    selectProperty,
    selectAgentUserProfile,
    (propertyData, loggedInAgent) =>
        CONFIG.build.STRAT_ENABLE_2022_AGENT_PROFILE_REDESIGN &&
        loggedInAgent &&
        loggedInAgent.id === propertyData?.userExternalID,
);

export const selectAgentIDs = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) => {
        const filterValue = filters.getFilterValue(
            FilterValues.agentOwner.attribute,
            EMPTY_ARRAY,
        ) as Array<string>;
        if (!Array.isArray(filterValue)) {
            return EMPTY_ARRAY;
        }
        return filterValue;
    },
);

export const selectIsAgentFilterApplied = createSelector(
    selectAgentIDs,
    (agentIDs) => !!agentIDs.length,
);

export const selectCurrentAgent = (state: AgentSearchState) => state.agent?.currentAgent;

export const selectHasMultipleLocationFilters = createSelector(
    selectRouteParamsInput,
    ({ filters }) => {
        const locationsWithAds: Array<any> = filters.getFilterValue(
            FilterValues.agentLocation.attribute,
            [],
        );
        return locationsWithAds.length > 1;
    },
);

export const selectHasLocationFilters = createSelector(selectRouteParamsInput, ({ filters }) => {
    const locationsWithAds: Array<LocationNodeData> = filters.getFilterValue(
        FilterValues.agentLocation.attribute,
        [],
    );
    return locationsWithAds.length !== 0;
});

export const selectShouldShowAgentXP = createSelector(
    selectAgentUserProfile,
    selectRouteParamsInput,
    (isLoggedInAgent, { filters }) => {
        const locationsWithAds: Array<LocationNodeData> = filters.getFilterValue(
            FilterValues.agentLocation.attribute,
            [],
        );

        const emirateLocations = locationsWithAds.filter((loc) => loc.hierarchy?.length === 2);
        return (
            isLoggedInAgent &&
            locationsWithAds.length !== 0 &&
            emirateLocations.length !== locationsWithAds.length
        );
    },
);

export const selectHasAnyFilterAddedOnAgentSearch = createSelector(
    selectRouteParamsInput,
    ({ filters }) => {
        const languages: Array<any> = filters.getFilterValue(FilterValues.languages.attribute, []);
        const locationsWithAds: Array<any> = filters.getFilterValue(
            FilterValues.agentLocation.attribute,
            [],
        );
        const hasAnyFilterAdded = languages.length !== 0 || locationsWithAds.length > 1;
        return CONFIG.build.STRAT_ENABLE_LEADERBOARD && hasAnyFilterAdded;
    },
);

export const selectShouldShowOnlyAgentHit = createSelector(
    selectHasAnyFilterAddedOnAgentSearch,
    selectAgentIDs,
    selectCurrentAgent,
    selectRouteParamsInput,
    (hasAnyFilterAdded, agentIDs, currentAgent) => {
        return (
            CONFIG.build.STRAT_ENABLE_LEADERBOARD &&
            hasAnyFilterAdded &&
            agentIDs?.length !== 0 &&
            currentAgent != null
        );
    },
);

export const selectIsAgentTruBroker = createSelector(
    selectAgent,
    (agent) => CONFIG.build.STRAT_ENABLE_LEADERBOARD && agent?.isTruBroker,
);

export const selectCurrentAgentMatchesFilters = createSelector(
    selectRouteParamsInput,
    selectCurrentAgent,
    selectShouldShowOnlyAgentHit,
    ({ filters }, currentAgent, shouldShowCurrentAgent) => {
        if (!shouldShowCurrentAgent || !currentAgent) {
            return false;
        }
        const { stats, language_codes } = currentAgent;
        const languages: Array<any> = filters.getFilterValue(FilterValues.languages.attribute, []);
        const locationsWithAdsIDs: Array<any> = filters
            .getFilterValue(FilterValues.agentLocation.attribute, [] as HitValue[])
            .map((location: HitValue) => location.id);

        const atLeastOneLanguageIncluded =
            !languages.length || languages.some((language) => language_codes.includes(language));
        const atLeastOneLocationIncluded =
            !locationsWithAdsIDs.length ||
            locationsWithAdsIDs.some((location) => stats.locationsWithAds.includes(location));

        return (
            CONFIG.build.STRAT_ENABLE_LEADERBOARD &&
            atLeastOneLanguageIncluded &&
            atLeastOneLocationIncluded
        );
    },
);

export const selectEmiratesFromFilters = createSelector(selectRouteParamsInput, ({ filters }) => {
    const locationsWithAds: Array<any> = filters.getFilterValue(
        FilterValues.agentLocation.attribute,
        [],
    );
    const emiratesExternalIDs = locationsWithAds
        .filter((location) => (location.hierarchy?.length ?? 0) >= 2)
        .map((loc) => loc.hierarchy[1].externalID);

    const uniqueEmirateExternalIDs = new Set(emiratesExternalIDs);

    return [...uniqueEmirateExternalIDs];
});

/**
 * We should show the ranking if there is only one location filter applied (that is in the supported emirates).
 * If there are no filters applied the rank won't be shown.
 * If there is an agent filter applied, we should always show the ranking, except when there are multiple
 * location filters, or we have one or more language filters.
 * However, these two cases are handled by the former 2 conditions.
 **/
export const selectShouldDisplayAgentRank = createSelector(
    selectRouteParamsInput,
    selectEmiratesFromFilters,
    ({ filters }, addedEmirates) => {
        const languages: Array<any> = filters.getFilterValue(FilterValues.languages.attribute, []);
        const locationsWithAds: Array<any> = filters.getFilterValue(
            FilterValues.agentLocation.attribute,
            [],
        );
        if (locationsWithAds.length === 0) {
            return false;
        }
        const hasOneLocationFilter = locationsWithAds.length === 1;
        const hasLanguageFilter = languages.length !== 0;
        const hasLocationFilters = locationsWithAds.length > 0;

        const truBrokerEmirates = Object.values(TruBrokerLocations).map(
            (location) => location.externalID,
        );

        const invalidEmirates = addedEmirates.filter((value) => !truBrokerEmirates.includes(value));
        const emirateLocations = locationsWithAds.filter((loc) => loc.hierarchy.length === 2);
        if (invalidEmirates.length >= 1 || emirateLocations.length === locationsWithAds.length) {
            return false;
        }

        if (hasOneLocationFilter && !hasLanguageFilter) {
            return true;
        }
        return !hasLocationFilters && !hasLanguageFilter;
    },
);

/*
 * If the location filter is outside the supported emirates, we should not show the agent badges
 * in agent search
 */
export const selectShouldDisplayAgentBadges = createSelector(
    selectEmiratesFromFilters,
    (addedEmirates) => {
        const truBrokerEmirates = Object.values(TruBrokerLocations).map(
            (location) => location.externalID,
        );
        const invalidEmirates = addedEmirates.filter((value) => !truBrokerEmirates.includes(value));
        return invalidEmirates.length === 0 || invalidEmirates.length !== addedEmirates.length;
    },
);

export const selectAgentActiveTab = (state: AgentSearchState) => state.agent.activeTab || null;

/**
 * Selects the purpose currently being filtered on with a custom fallback value.
 */
export const selectPurposeWithDefaultValue = (defaultValue: string) =>
    createSelector(selectTruBrokerPurpose, (purpose) => purpose || defaultValue);

export const selectAgentSaleCategory = createSelector(selectRouteParamsInput, ({ filters }) => {
    return filters.getFilterValue(
        FilterValues.agentSaleCategory.attribute,
        FilterValues.agentSaleCategory.default,
    );
});

export const selectAgentRentalCategory = createSelector(selectRouteParamsInput, ({ filters }) => {
    return filters.getFilterValue(
        FilterValues.agentRentalCategory.attribute,
        FilterValues.agentRentalCategory.default,
    );
});

export const selectProboardLocations = createSelector(selectRouteParamsInput, ({ filters }) => {
    return filters.getFilterValue(FilterValues.agentLocation.truBrokerAttribute, null);
});
