import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const AlertType = Object.freeze({
    EXPLICIT: 'explicit',
    SILENT: 'silent',
    SAVED_SEARCH: 'saved_search',
    IMPORTED: 'imported',
});

const NotificationType = Object.freeze({
    PUSH_NOTIFICATION: 'push_notification',
    EMAIL: 'email',
    RECOMMENDED_EMAIL: 'recommended_email',
    RECENTLY_VIEWED_EMAIL: 'recently_viewed_email',
});

const AppType = Object.freeze({
    WEB_APP: 'web',
    MOBILE_APP: 'mobile',
});

// @ts-expect-error - TS7022 - 'AlertFrequency' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
const AlertFrequency = Object.freeze({
    AUTO: 'auto',
    TWICE_A_DAY: 'twice_a_day',
    DAILY: 'daily',
    ONE_DAY_AFTER: 'one_day_after',
    TWO_DAY_AFTER: 'two_day_after',
    WEEKLY: 'weekly',

    // eslint-disable-next-line no-use-before-define
    text(i18n: I18n, frequency: Values<typeof AlertFrequency>) {
        switch (frequency) {
            case this.TWICE_A_DAY:
                return t(i18n)`Twice a day`;
            case this.DAILY:
                return t(i18n)`Daily`;
            case this.ONE_DAY_AFTER:
                return t(i18n)`Every two days`;
            case this.TWO_DAY_AFTER:
                return t(i18n)`Every three days`;
            case this.WEEKLY:
                return t(i18n)`Weekly`;
            case this.AUTO:
                return t(i18n)`Auto`;
            default:
                return '';
        }
    },

    all() {
        return [
            this.AUTO,
            this.TWICE_A_DAY,
            this.DAILY,
            this.ONE_DAY_AFTER,
            this.TWO_DAY_AFTER,
            this.WEEKLY,
        ];
    },

    simplified() {
        return [this.DAILY, this.ONE_DAY_AFTER, this.WEEKLY];
    },
});

type MinMaxField = {
    min: string;
    max: string;
};

type EmailAlertType = {
    area: MinMaxField | null | undefined;
    price: MinMaxField | null | undefined;
    rentFrequency: string | null | undefined;
    sortBy: string | null | undefined;
    type: string;
    purpose: string;
    beds: Array<string>;
    baths: Array<string>;
    keywords: Array<string>;
    name: string | null | undefined;
    category:
        | {
              id: number;
              external_id: string;
              name: {
                  [key: string]: string | null | undefined;
              };
              name_singular: {
                  [key: string]: string | null | undefined;
              };
              slug: {
                  [key: string]: string | null | undefined;
              };
          }
        | null
        | undefined;
    locations: Array<{
        name: {
            [key: string]: string | null | undefined;
        };
        slug: {
            [key: string]: string | null | undefined;
        };
    }>;
    agencies: Array<any>;
};

type Subscription = {
    id: string;
    autoFrequency: boolean;
    frequency: Values<typeof AlertFrequency>;
    active: boolean;
};

export type EmailAlertSubscriptionType = EmailAlertType & Subscription;
export type PushNotificationsSubscriptionType = Subscription;
export type RecommendedEmailAlertSubscriptionType = Subscription;
export type RecentlyViewedEmailAlertSubscriptionType = Subscription;

export { AlertType, AlertFrequency, NotificationType, AppType };
