import { t } from '@lingui/macro';
import * as React from 'react';

import { useI18n, parseFormattedNumber } from 'strat/i18n/language';
import { numberFormatterLocaleFromLanguage } from 'strat/i18n/language/locales';

import FilterValueButton from './filterValueButton';
import styles from './styles/filterValueOptions.cssm';

type Value = null | number | string;

type Props = {
    showAny?: boolean;
    placeholderText?: string;
    label: string;
    value: Value;
    options: Array<number>;
    setValue: (value: Value) => void;
    renderOption?: (value: number) => string | React.ReactNode;
    design?: string;
};

const FilterValueOptions = ({
    showAny,
    placeholderText,
    label,
    value,
    options,
    setValue,
    renderOption = (optionValue: number) => optionValue,
    design,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const locale = numberFormatterLocaleFromLanguage(i18n.locale);

    const selectedValue = parseFormattedNumber(value, locale);

    return (
        <>
            {showAny && (
                <FilterValueButton
                    selected={!value}
                    onClick={() => setValue(null)}
                    className={styles.button}
                    design={design}
                >
                    {placeholderText}
                </FilterValueButton>
            )}
            {options.map((optionValue) => (
                <FilterValueButton
                    key={optionValue}
                    selected={optionValue === selectedValue}
                    onClick={() => setValue(optionValue)}
                    className={styles.button}
                    design={design}
                >
                    {optionValue === 0 &&
                    i18n.locale === 'ar' &&
                    (label === t(i18n)`min` || label === t(i18n)`Minimum`)
                        ? placeholderText
                        : renderOption(optionValue)}
                </FilterValueButton>
            ))}
        </>
    );
};

export default FilterValueOptions;
