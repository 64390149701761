import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import type { UserOrdersRouteParams } from 'horizontal/routes';

import { UserOrdersSection, DeliveryRequestStatus } from '../types';

const useDeliveryOrderNavStatus = (): Values<typeof DeliveryRequestStatus> | null | undefined => {
    const params: UserOrdersRouteParams = useSelector(selectRouteParams);
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    if (![UserOrdersSection.BUYING, UserOrdersSection.SELLING].includes(params.section)) {
        return null;
    }

    return params.deliveryOrderStatus || DeliveryRequestStatus.ACTIVE;
};

export default useDeliveryOrderNavStatus;
