import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import RouteNames from 'strat/routes/routeNames';
import { Button, Flex, Text } from 'strat/components';

import EditProfileSection from 'horizontal/user/editProfileSection';

import styles from './styles/invalidPhoneNumberMessage.cssm';

const InvalidPhoneNumberMessage = () => {
    const i18n = useI18n();
    return (
        <Flex className={styles.container} column id="invalid-phone-number-message">
            <Flex column>
                <Text.Base className={styles.title} uppercase bold>
                    {t(i18n)`To purchase business packages, please verify your phone number.`}
                </Text.Base>
            </Flex>
            <Flex column>
                <Text.Base className={styles.subtitle}>
                    {t(
                        i18n,
                    )`To verify your phone number, go to "Account" > "View and Edit profile", then add your Phone Number.`}
                </Text.Base>
            </Flex>
            <Flex justifyCenter>
                <Link
                    className={styles.profileLink}
                    route={RouteNames.EDIT_PROFILE}
                    params={{ section: EditProfileSection.INFO }}
                >
                    {/* @ts-expect-error - TS2322 - Type '{ children: string; stretch: true; primary: true; ariaLabel: string; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button stretch primary ariaLabel="go-to-profile-button">
                        {t(i18n)`Go to Profile`}
                    </Button>
                </Link>
            </Flex>
        </Flex>
    );
};

export default InvalidPhoneNumberMessage;
