import * as React from 'react';
import { useSessionStatus, SessionStatus } from '@sector-labs/fe-auth-redux/state';
import { LoadingSpinner } from 'strat/components';

type Props = {
    readonly className?: string;
};

const activityIndicatingSessionStatuses = [SessionStatus.LOGGING_IN, SessionStatus.LOGGING_OUT];

/**
 * General activity indicator that can be used to
 * indicate to the user that _something_ is happening.
 *
 * Extend with more use cases as needed.
 */
const ActivityIndicator = ({ className }: Props) => {
    const sessionStatus = useSessionStatus();

    if (activityIndicatingSessionStatuses.includes(sessionStatus)) {
        return <LoadingSpinner dark delayWithSize className={className} />;
    }

    return null;
};

export default ActivityIndicator;
