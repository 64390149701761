import * as React from 'react';
import usePreviousState from 'strat/util/usePreviousState';

import type { ChatMessageLocationContent } from 'horizontal/chat/types';

type Props = {
    readonly map?: MapType;
    readonly onLocationChange: (center: ChatMessageLocationContent) => void;
    readonly onMoveStart: () => void;
    readonly currentLocation:
        | {
              latitude: number;
              longitude: number;
          }
        | null
        | undefined;
    readonly currentLocationLoading: boolean;
};

const MapboxPanController = ({
    map,
    onLocationChange,
    onMoveStart,
    currentLocation,
    currentLocationLoading,
}: Props) => {
    const previousLocationLoading = usePreviousState(currentLocationLoading);
    const onNewLocation = React.useCallback(() => {
        if (map) {
            const center = map.getCenter();
            onLocationChange({ lat: center.lat, lng: center.lng });
        }
    }, [map, onLocationChange]);

    React.useEffect(() => {
        if (map && currentLocation && !currentLocationLoading && previousLocationLoading) {
            map.jumpTo({ center: [currentLocation.longitude, currentLocation.latitude], zoom: 17 });
        }
    }, [currentLocation, map, currentLocationLoading, previousLocationLoading]);

    React.useEffect(() => {
        const handleMoveEnd = () => {
            onNewLocation();
        };

        const handleMoveStart = () => {
            onMoveStart();
        };
        onNewLocation();

        map?.on('moveend', handleMoveEnd);
        map?.on('movestart', handleMoveStart);

        return () => {
            map?.off('moveend', handleMoveEnd);
            map?.off('movestart', handleMoveStart);
        };
    }, [map, onLocationChange, onMoveStart, onNewLocation]);

    return null;
};

export default MapboxPanController;
