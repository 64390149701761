import * as React from 'react';
import { Text } from 'strat/components';

type Props = {
    item: Record<string, unknown>;
    valueProvider: string | ((item: Record<string, unknown>) => string | React.ReactNode);
};

const TeleSalesTableCellValue = ({ item, valueProvider }: Props) => {
    let value = undefined;

    if (typeof valueProvider === 'string') {
        value = item[valueProvider];
    } else if (typeof valueProvider === 'function') {
        value = valueProvider(item);
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return <Text.Regular>{value}</Text.Regular>;
    }

    return <>{value}</>;
};

export default TeleSalesTableCellValue;
