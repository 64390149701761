import { DEFAULT_STACK_GROUP, useDismissibleStacksContext } from './dismissibleStackContext';

/**
 * Scopes the actions from the dismissible stacks context
 * down to a certain stack
 */
const useDismissibleStack = (stackGroup: string = DEFAULT_STACK_GROUP) => {
    const { pushToStack, removeFromStack, topOfStack } = useDismissibleStacksContext();

    return {
        push: (dismissibleId: number) => pushToStack(dismissibleId, stackGroup),
        remove: (dismissibleId: number) => removeFromStack(dismissibleId, stackGroup),
        top: () => topOfStack(stackGroup),
    };
};

export default useDismissibleStack;
