import Category from '@app/branding/category';

import type { MixedAd } from 'horizontal/types';

import type { Schema } from './types';
import useProductSchema from './useProductSchema';
import useCarProductSchema from './useCarProductSchema';
import usePropertyProductSchema from './usePropertyProductSchema';

const useSchema = (ad?: MixedAd | null): Schema | null | undefined => {
    const productSchema = useProductSchema(ad);
    const carProductSchema = useCarProductSchema(ad);
    const propertyProductSchema = usePropertyProductSchema(ad);

    const isCar =
        ad &&
        ad.category.some(
            (category) =>
                Category.isOfCarsType(category) || Category.isOfCarsOnInstallmentsType(category),
        );
    if (isCar) {
        return carProductSchema;
    }

    const isProperty =
        ad &&
        ad.category.some(
            (category) => Category.isOfHouseType(category) || Category.isOfApartmentType(category),
        );
    if (isProperty) {
        return propertyProductSchema;
    }

    const isJob = ad && ad.category.some((category) => Category.isOfJobsType(category));
    if (isJob) {
        // TODO: useJobSchema(ad) when possible - this should be temporary https://www.pivotaltracker.com/story/show/182941329
        return null;
    }

    return productSchema;
};

export default useSchema;
