//@ts-nocheck
import React, { Component } from 'react';
import classNames from 'classnames';
import autoBind from 'react-autobind';
import isNil from 'lodash/isNil';
import SwipeableViews from 'react-swipeable-views';

import styles from './styles/carousel.cssm';

import './styles/arrow.css';
import './styles/carousel.css';

const CarouselContentStyles = {
    FLEX_START: 'carouselGroupContentStart',
    FLEX_CENTER: 'carouselGroupContentCenter',
    FLEX_SPACE_BETWEEN: 'carouselGroupContentSpaceBetween',
    FLEX_SPACE_AROUND: 'carouselGroupContentSpaceAround',
} as const;

type Props = {
    children: Array<React.ReactNode>;
    className?: string;
    focusedIndex?: number;
    fadeNavigation?: boolean;
    groupItemsAlignmentStyle?: string;
    renderPreviousButton?: (previous: () => void, canGoPrevious: boolean) => React.ReactNode;
    renderNextButton?: (next: () => void, canGoNext: boolean) => React.ReactNode;
    reversed?: boolean;
    computeGroupSize?: (windowWidth: number) => number;
    getGroupAlignmentStyle?: (groupLength: number) => string;
    activeGroupIndex?: number;
    onArrowClick?: ({
        currentSlide,
        previousSlide,
    }: {
        currentSlide: number;
        previousSlide: number;
    }) => void;
    groupSize?: number;
    ariaLabel?: string;
    hideIncompletePages?: boolean;
    navigationLeftClassName?: string;
    navigationRightClassName?: string;
    showNextGroupItem?: boolean;
};

/**
 * Hack-ish component for a carousel, based on react-swipeable-views.
 * TODO:// refactor this component
 */
class Carousel extends Component {
    /**
     * Get the amount of items to display one at the time based
     * on the current screen width.
     * @returns {number}
     */
    static computeGroupSize(windowWidth: any) {
        let result = 3;
        if (windowWidth <= CONFIG.build.DEVICE_WIDTH_TABLET) {
            result = 2;
            if (windowWidth <= CONFIG.build.DEVICE_WIDTH_MOBILE) {
                result = 1;
            }
        }
        return result;
    }

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = this.computeState(this.props, CONFIG.build.DEVICE_WIDTH_DESKTOP);
    }

    /**
     * Re-compute state when the component mounted.
     */
    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState(this.computeState(this.props));
        window.addEventListener('resize', this.onResize);
    }

    /**
     * Re-render when for example the focused index changes.
     */
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState(this.computeState(nextProps));
    }

    /**
     * Detach window resize handler.
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    /**
     * Force re-render when the window size changes.
     */
    onResize() {
        this.setState(this.computeState(this.props));
    }

    /**
     * Callback for when the currently active slide changes.
     */
    onSlideSwitch(index: any) {
        // round index in order to 'snap' to a valid slide position after scrolling
        this.setState({ index: Math.round(index) });
    }

    /**
     * Computes the new state of the component.
     * @returns {Object}
     */
    computeState(props: any, windowWidth: any) {
        const realWidth = windowWidth || window.innerWidth;
        const computeGroupSize = this.props.computeGroupSize || Carousel.computeGroupSize;
        const groupSize = this.props.groupSize || computeGroupSize(realWidth);

        const groups = props.children
            .map((item, index) =>
                index % groupSize === 0 ? props.children.slice(index, index + groupSize) : null,
            )
            .filter((item) => {
                if (!item) {
                    return false;
                }
                return !this.props.hideIncompletePages || item.length === groupSize;
            });

        // initialize state if it wasn't yet
        const state = this.state || {};
        let index = state.index || 0;

        if (props.focusedIndex >= 0) {
            // move to the focused index if any
            index = Math.floor(props.focusedIndex / groupSize);
        } else if (!isNil(props.activeGroupIndex)) {
            index = props.activeGroupIndex;
        } else if (state.groups) {
            let groupItemIndex = 0;
            const group = state.groups[state.index];

            // we're trying to determine which item to keep in focus when the page is
            // resizing, depending on which group is the active group,
            // we want different behavior:
            // - first group, keep the left most item focused
            // - last group, keep the right most item focused
            // - middle group, keep middle item focused
            if (state.index === 0) {
                groupItemIndex = 0;
            } else if (state.index === state.groups.length - 1) {
                groupItemIndex = group.length - 1;
            } else {
                groupItemIndex = Math.ceil(group.length / 2) - 1;
            }

            // compute the absolute item index of the item to keep in focus
            const focusedIndex = (state.groupSize * state.index || 0) + groupItemIndex;

            // compute the new focused index that the item resides in
            index = Math.floor(focusedIndex / groupSize);
        }

        return { index, groups, groupSize };
    }

    /**
     * Gets whether you can scroll backward.
     */
    get canGoBackward() {
        return this.state.index !== 0 && this.props.focusedIndex < 0;
    }

    /**
     * Gets whether you can scroll forward.
     */
    get canGoForward() {
        return this.state.index < this.state.groups.length - 1 && this.props.focusedIndex < 0;
    }

    /**
     * Returns the next group's first item, if it has one.
     */
    nextGroupItem(index: number) {
        return this.state.groups[index + 1] && this.state.groups[index + 1][0];
    }

    /**
     * Goes to the previous slide.
     */
    previous() {
        if (!this.canGoBackward) {
            return;
        }

        this.setState(
            {
                index: this.state.index - 1,
            },
            () =>
                this.props.onArrowClick?.({
                    currentSlide: this.state.index,
                    previousSlide: this.state.index + 1,
                }),
        );
    }

    /**
     * Goes to the next slide.
     */
    next() {
        if (!this.canGoForward) {
            return;
        }
        this.setState(
            {
                index: this.state.index + 1,
            },
            () =>
                this.props.onArrowClick?.({
                    currentSlide: this.state.index,
                    previousSlide: this.state.index - 1,
                }),
        );
    }

    /**
     * Renders the carousel.
     */
    render() {
        const style = {
            overflow: 'hidden',
            display: 'flex',
            flexGrow: 1,
        } as const;

        const navigationLeftClassName =
            this.props.navigationLeftClassName ||
            classNames({
                'navigation left': !this.props.fadeNavigation,
                'navigationFade left': this.props.fadeNavigation && this.canGoBackward,
            });

        const navigationRightClassName =
            this.props.navigationRightClassName ||
            classNames({
                'navigation right': !this.props.fadeNavigation,
                'navigationFade right': this.props.fadeNavigation && this.canGoForward,
            });

        const carouselClassName = classNames('carousel', this.props.className);

        const previousFunction = !this.props.reversed ? this.previous : this.next;
        const nextFunction = !this.props.reversed ? this.next : this.previous;
        const canGoPrevious = !this.props.reversed ? this.canGoBackward : this.canGoForward;
        const canGoNext = !this.props.reversed ? this.canGoForward : this.canGoBackward;

        return (
            <div className={carouselClassName}>
                <div className={navigationLeftClassName}>
                    {this.props.renderPreviousButton ? (
                        this.props.renderPreviousButton(previousFunction, canGoPrevious)
                    ) : (
                        <button
                            onClick={previousFunction}
                            className={styles.button}
                            style={{
                                visibility: canGoPrevious ? 'visible' : 'hidden',
                            }}
                        >
                            <i className="arrow left" />
                        </button>
                    )}
                </div>
                <SwipeableViews
                    style={style}
                    containerStyle={{ width: '100%' }}
                    disabled={this.props.focusedIndex >= 0}
                    index={this.state.index}
                    onSwitching={this.onSlideSwitch}
                    axis={!this.props.reversed ? 'x' : 'x-reverse'}
                >
                    {this.state.groups.map((group, index) => (
                        <div
                            aria-label={this.props.ariaLabel}
                            className={
                                this.props.getGroupAlignmentStyle
                                    ? this.props.getGroupAlignmentStyle(group.length)
                                    : this.props.groupItemsAlignmentStyle
                            }
                            key={index}
                        >
                            {group}
                            {this.props.showNextGroupItem && this.nextGroupItem(index)}
                        </div>
                    ))}
                </SwipeableViews>
                <div className={navigationRightClassName}>
                    {this.props.renderNextButton ? (
                        this.props.renderNextButton(nextFunction, canGoNext)
                    ) : (
                        <button
                            onClick={nextFunction}
                            className={styles.button}
                            style={{
                                visibility: canGoNext ? 'visible' : 'hidden',
                            }}
                        >
                            <i className="arrow right" />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

Carousel.defaultProps = {
    focusedIndex: -1,
    fadeNavigation: false,
    groupItemsAlignmentStyle: CarouselContentStyles.FLEX_CENTER,
    reversed: false,
};

export default Carousel;
export { CarouselContentStyles };
