import * as React from 'react';
import { useActiveUser } from 'strat/user/session';

import { ContactInfoFields } from 'horizontal/fields/commonPostingFields';
import {
    useFetchUserSettings,
    useUserSetting,
    useUserSettingsLoaded,
} from 'horizontal/userSettings/hooks';
import { UserSettingsKey } from 'horizontal/userSettings/types';
import { ContactPersonRole } from 'horizontal/types';
import type { AdContactInfo } from 'horizontal/types';

const useDefaultSellerFieldsValues = (): AdContactInfo => {
    const user = useActiveUser();
    const fetchUserSettings = useFetchUserSettings();
    const userSettingsLoaded = useUserSettingsLoaded();
    // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'keyof UserSettings'.
    const defaultShowPhoneNumber = useUserSetting(UserSettingsKey.SHOW_PHONE_NUMBER_IN_ADS);
    const defaultAllowChatCommunication = useUserSetting(
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'keyof UserSettings'.
        UserSettingsKey.ALLOW_CHAT_COMMUNICATION_IN_ADS,
    );

    React.useEffect(() => fetchUserSettings(), [fetchUserSettings]);

    // @ts-expect-error - TS2322 - Type '{ name: Primitive; mobile: Primitive; roles: string[] | Primitive; }' is not assignable to type 'AdContactInfo'.
    return React.useMemo(() => {
        const name = user?.name || ContactInfoFields.name.defaultValue;
        const mobile = user?.phoneNumber || ContactInfoFields.phone_number.defaultValue;

        const roles = [];
        if (defaultAllowChatCommunication) {
            roles.push(ContactPersonRole.ALLOW_CHAT_COMMUNICATION);
        }
        if (defaultShowPhoneNumber) {
            roles.push(ContactPersonRole.SHOW_PHONE_NUMBER);
        }

        return {
            name,
            mobile,
            roles: userSettingsLoaded ? roles : ContactInfoFields.roles.defaultValue,
        };
    }, [user, defaultAllowChatCommunication, defaultShowPhoneNumber, userSettingsLoaded]);
};

export default useDefaultSellerFieldsValues;
