import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

// Different portals receive different condition values from Elasticsearch.
export const CarUsageConditions = Object.freeze({
    NEW: ['1', 'new', 'New'],
    USED: ['2', 'used', 'Used'],
});

const useGetCarMileage = (usageCondition: string | undefined, mileage: string) => {
    const i18n = useI18n();

    if (!usageCondition) {
        return null;
    }

    if (CarUsageConditions.NEW.includes(usageCondition)) {
        return t(i18n)`New`;
    } else if (mileage && CarUsageConditions.USED.includes(usageCondition)) {
        return t(i18n)`${mileage} km`;
    }

    return null;
};

export default useGetCarMileage;
