import * as React from 'react';
import { GTMLeadTrackingProps, withGTMLeadTracking } from 'strat/gtm';

import {
    ChatMessageType,
    ChatNegotiationState,
    ChatRoomMemberRole,
} from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ActionButton, ChatRoom } from 'horizontal/chat/types';
import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';

import { MakeOfferBuyer } from './buyer';
import MakeOfferForm from './makeOfferForm';
import { MakeOfferSeller } from './seller';

type OwnProps = {
    readonly conversation: ChatRoom;
    readonly actionButtons: Array<ActionButton>;
    readonly priceFormVisible: boolean;
    readonly setPriceFormVisible: (arg1: boolean) => void;
};

type Props = GTMLeadTrackingProps & OwnProps;

const MakeOffer = ({
    conversation: chatRoom,
    actionButtons,
    priceFormVisible,
    setPriceFormVisible,
    trackChatLead,
}: Props) => {
    const sdk = useChatSDK();
    const { ad } = sdk.useRoomContactInfo(chatRoom);
    const negotiation = sdk.useRoomNegotiationInfo(chatRoom.xid);
    const getNegotiationActions = sdk.useSendNegotiationMessagesMap();
    const adPrice: number | null | undefined = ad?.extraFields?.price;
    const isSeller = chatRoom.myRole !== ChatRoomMemberRole.BUYER;
    const lastOfferPrice = negotiation.value;
    const negotiationState = negotiation.state ?? ChatNegotiationState.INITIATED;
    const lastRejectedPrice = negotiation.lastRejectedBid;
    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);

    if (adPrice == null) {
        return null;
    }

    if (priceFormVisible) {
        return (
            <MakeOfferForm
                ad={ad}
                onSendClick={async (price) => {
                    const actions = await getNegotiationActions();
                    if (!actions) {
                        return;
                    }
                    const sendMessage = actions[ChatNegotiationState.BUYER_MADE_OFFER];
                    if (!sendMessage) {
                        return;
                    }

                    const messageSentResponse = await sendMessage(price ?? undefined);

                    if (messageSentResponse) {
                        trackSentChatMessageEvents(
                            messageSentResponse,
                            ChatMessageType.NEGOTIATION,
                        );
                    }

                    setPriceFormVisible(false);
                }}
                adPrice={adPrice}
                rejectedPrice={lastRejectedPrice}
                lastSellerOfferPrice={
                    negotiationState === ChatNegotiationState.SELLER_MADE_OFFER
                        ? lastOfferPrice
                        : undefined
                }
            />
        );
    }

    if (isSeller) {
        return (
            <MakeOfferSeller
                negotiationState={negotiationState}
                actionButtons={actionButtons}
                adPrice={adPrice}
                lastOfferPrice={lastOfferPrice ?? 0}
                lastRejectedPrice={lastRejectedPrice}
            />
        );
    }
    return (
        <MakeOfferBuyer
            ad={ad}
            negotiationState={negotiationState}
            actionButtons={actionButtons}
            adPrice={adPrice}
            lastOfferPrice={lastOfferPrice ?? 0}
            lastRejectedPrice={lastRejectedPrice}
        />
    );
};

export default withGTMLeadTracking(React.memo<Props>(MakeOffer));
