import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { useRouter } from 'react-true-router';

import { getHomePageDescription, getHomePageTitle } from 'horizontal/home/seo';

import CommonHead from './commonHead';

type Props = {
    readonly title?: React.ReactNode;
};

const PrivateNonIndexableHead = ({ title }: Props) => {
    const i18n = useI18n();
    const router = useRouter();
    const brandName = settings.getBrandName(i18n);
    const description = getHomePageDescription(i18n);

    return (
        <>
            <title>{title ? t(i18n)`${title} | ${brandName}` : getHomePageTitle(i18n)}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="noindex, nofollow" />
            <CommonHead url={router?.context?.match?.url} alternateURLs={[]} />
        </>
    );
};

export default PrivateNonIndexableHead;
