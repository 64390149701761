import * as React from 'react';
import isNil from 'lodash/isNil';

type Props = {
    data: any | null | undefined;
};

export default ({ data }: Props) =>
    !isNil(data) &&
    Object.keys(data).length > 0 && (
        <script
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(data),
            }}
            type="application/ld+json"
        />
    );
