import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

import { useNavigateToAdsManagement } from 'horizontal/packages/navigation';

import { PackagesFlow } from '../types';

import PackagesCTASection from './packagesCTASection';
import UpsellingCTASection from './upsellingCTASection';

const useCreatePackagesSectionCTAs = ({
    flow,
    hasItems,
    viewAd,
    mainCTA,
}: React.ComponentProps<typeof PackagesCTASection>): React.ComponentProps<
    typeof UpsellingCTASection
> | null => {
    const i18n = useI18n();
    const navigateToAdManagement = useNavigateToAdsManagement();

    switch (flow) {
        case PackagesFlow.DISCOUNTED_PACKAGES_PURCHASE_FROM_AN_AD:
        case PackagesFlow.UPSELLING_AD_AFTER_POSTING_AD_SUCCESSFULLY: {
            const ctaActionsForExistingOffers = {
                mainCTA: mainCTA,
                secondaryCTA: {
                    text: t(i18n)`View Your Ad`,
                    action: viewAd,
                },
            };

            const ctaActionsForEmptyOffers = {
                mainCTA: {
                    text: t(i18n)`View Your Ad`,
                    action: viewAd,
                },
                secondaryCTA: {
                    action: navigateToAdManagement,
                    text: t(i18n)`Go to My Ads`,
                },
            };

            return hasItems ? ctaActionsForExistingOffers : ctaActionsForEmptyOffers;
        }
        case PackagesFlow.UPSELLING_AD_FROM_USER_ADS: {
            const ctaActionsForExistingOffers = {
                mainCTA: mainCTA,
            };

            const ctaActionsForEmptyOffers = {
                mainCTA: {
                    text: t(i18n)`View Your Ad`,
                    action: viewAd,
                },
            };

            return hasItems ? ctaActionsForExistingOffers : ctaActionsForEmptyOffers;
        }
        case PackagesFlow.LIMITED_AD: {
            const ctaActionsForExistingItems = {
                mainCTA: mainCTA,
                secondaryCTA: {
                    text: t(i18n)`View Your Ad`,
                    action: viewAd,
                },
            };

            const ctaActionsForEmptyItems = {
                mainCTA: {
                    text: t(i18n)`View Your Ad`,
                    action: viewAd,
                },
            };

            return hasItems ? ctaActionsForExistingItems : ctaActionsForEmptyItems;
        }
        default:
            //If in the future we had a logInfo, please add a log here
            //so we can know that this is being called with
            //either a unknown flow, or a flow with no branch in the switch.
            return null;
    }
};

export default useCreatePackagesSectionCTAs;
