import * as React from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import Purpose from 'strat/purpose';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { useNavigateToRefinedSearch } from 'horizontal/search/routing';
import { selectCategoryByExternalID } from 'horizontal/categories';

import { SearchParamsType } from './types';
import Form from './searchForm';

const Search = () => {
    const navigateToRefinedSearch = useNavigateToRefinedSearch();
    const propertiesCategory = useSelector((state) =>
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'never'. | TS2339 - Property 'verticalProperties' does not exist on type 'HorizontalSettings'.
        selectCategoryByExternalID(state, settings.verticalProperties.externalID),
    );

    const initialValues = {
        price: { min: null, max: null },
        area: { min: null, max: null },
        beds: [],
        baths: [],
        purpose: Purpose.FOR_SALE,
    } as const;

    const onSubmit = React.useCallback(
        (values) => {
            const searchParams: SearchParamsType = {
                category: values.selectedCategory || propertiesCategory,
                location: values.location,
                extraFields: {
                    rooms: values.beds,
                    bathrooms: values.baths,
                    price: values.price,
                    ft: values.area,
                },
            };
            navigateToRefinedSearch(searchParams);
        },
        [navigateToRefinedSearch, propertiesCategory],
    );

    return (
        <Flex>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(props: React.ComponentProps<typeof Form>) => (
                    // @ts-expect-error - TS2322 - Type 'Category | null | undefined' is not assignable to type 'Category'.
                    <Form {...props} propertiesCategory={propertiesCategory} />
                )}
            </Formik>
        </Flex>
    );
};

export default Search;
