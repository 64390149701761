import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useUnlinkIdentityProvider } from '@sector-labs/fe-auth-redux/thunk';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import {
    useKeycloakSiteConfigWithCallbacks,
    determineKeycloakThirdPartyProviderID,
} from 'strat/auth/keycloak';
import { Flex, Button, Text, Dialog } from 'strat/components';
import { ThirdPartyProvider } from 'strat/auth/keycloak/types';

import { useMakeCancelable } from 'horizontal/util';
import { trackUnlinkSocialAccount } from 'horizontal/gtm';

import styles from './styles/unlinkSocialAccountDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly provider: ThirdPartyProvider;
};

const UnlinkSocialLoginDialog = ({ visible, onVisibilityChanged, provider }: Props) => {
    const unlinkIdentityProvider = useUnlinkIdentityProvider();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const makeCancelable = useMakeCancelable();
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    const onUnlinkProvider = () => {
        makeCancelable(
            unlinkIdentityProvider(keycloakSiteConfig, {
                provider: determineKeycloakThirdPartyProviderID(provider),
                removeAssociatedData: true,
            }),
        )
            .then(() => {
                trackUnlinkSocialAccount(provider, true);
                onVisibilityChanged(false);
            })
            .catch(() => {
                trackUnlinkSocialAccount(provider, false);
            });
    };

    return (
        <Dialog
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            withCloseButton
        >
            <Flex column alignCenter className={styles.content}>
                <Text.XXLarge bold>
                    <Trans>Unlink Account?</Trans>
                </Text.XXLarge>
                <Text.Base className={styles.description}>
                    <Trans>
                        You will loose access to {brandName} using this account! If this is
                        you&apos;re only way to login, you will lose access to your {brandName}{' '}
                        account!
                    </Trans>
                </Text.Base>
                <Flex className={styles.buttonsWrapper}>
                    <Button
                        stretch
                        // @ts-expect-error - TS2322 - Type '{ children: Element; stretch: true; primary: true; onClick: () => void; }' is not assignable to type 'IntrinsicAttributes & Props'.
                        primary
                        onClick={() => {
                            unlinkIdentityProvider(keycloakSiteConfig, {
                                provider: determineKeycloakThirdPartyProviderID(provider),
                                removeAssociatedData: false,
                            })
                                .then(() => {
                                    trackUnlinkSocialAccount(provider, true);
                                    onVisibilityChanged(false);
                                })
                                .catch(() => {
                                    trackUnlinkSocialAccount(provider, false);
                                });
                        }}
                    >
                        <Trans>Unlink</Trans>
                    </Button>
                    <Button stretch danger onClick={onUnlinkProvider}>
                        <Trans>Unlink and remove associated data</Trans>
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default UnlinkSocialLoginDialog;
