import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, LoadingSpinnerOverlay } from 'strat/components';

import JazzCashLogo from 'horizontal/assets/icons/iconJazzCash_noinline.svg';
import useNavigateToPaymentSelectionFreeAd from 'horizontal/payment/hooks/useNavigateToPaymentSelectionFreeAd';

import { useNavigateToPaymentSelection } from '../hooks';
import type { PaymentOrder } from '../types';
import Header from '../header';

import MobileWalletPaymentForm from './mobileWalletPaymentForm';
import OrderSummary from './orderSummary';
import styles from './styles/paymentJazzCashMWallet.cssm';
import useMakeMobileWalletPayment from './useMakeMobileWalletPayment';

type Props = {
    readonly order: PaymentOrder;
    readonly fromFreeAdUndertaking?: boolean;
    readonly renderLogo?: (
        arg0?: string,
    ) => React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;
    readonly title: React.ReactNode;
    readonly explanatoryNotes: React.ReactNode;
    readonly productsBoxClassName?: string;
};

const defaultRenderLogo = (className?: string) => (
    <img src={JazzCashLogo} alt={'Jazz Cash logo'} className={className} />
);

const PaymentJazzCashMWallet = ({
    order,
    fromFreeAdUndertaking,
    renderLogo = defaultRenderLogo,
    title,
    explanatoryNotes,
    productsBoxClassName = undefined,
}: Props) => {
    const i18n = useI18n();
    const navigateToPaymentSelection = useNavigateToPaymentSelection();
    const navigateToPaymentSelectionFreeAd = useNavigateToPaymentSelectionFreeAd();
    const { adExternalID, externalID } = order;

    const navigateBack = React.useCallback(
        (status) => {
            if (fromFreeAdUndertaking && adExternalID) {
                navigateToPaymentSelectionFreeAd({
                    adExternalID,
                });
            } else if (externalID) {
                navigateToPaymentSelection({ orderExternalID: externalID, status });
            }
        },
        [
            navigateToPaymentSelection,
            navigateToPaymentSelectionFreeAd,
            fromFreeAdUndertaking,
            externalID,
            adExternalID,
        ],
    );

    const { onSubmit, loading } = useMakeMobileWalletPayment(order, navigateBack);

    return (
        <Flex stretchHeight column>
            <LoadingSpinnerOverlay
                visible={loading}
                text={t(i18n)`Enter the MPIN on your phone to confirm the transaction...`}
            />
            <Header
                popHistory={!order}
                // @ts-expect-error - TS2322 - Type '(status: any) => void' is not assignable to type '() => void'.
                onBack={navigateBack}
                renderLogo={renderLogo}
            />
            <Flex column className={styles.content}>
                {title}
                <div className={productsBoxClassName}>
                    <OrderSummary
                        productPackageNames={order?.productPackageNames}
                        productsPrice={order?.grossTotal || order?.total}
                        loading={!order}
                    />
                </div>
                <MobileWalletPaymentForm onSubmit={onSubmit} explanatoryNotes={explanatoryNotes} />
            </Flex>
        </Flex>
    );
};

export default PaymentJazzCashMWallet;
