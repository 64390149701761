/**
 * Lists all badge types supported in Strat.
 *
 * Controlling which badges are supported on
 * which portals is determined in
 * settings.enabledVerificationBadges.
 */
export const PropertyVerificationBadges = Object.freeze({
    TRUCHECKED: 'truchecked',
    CHECKED: 'checked',
    VERIFIED: 'verified',
});

export type PropertyVerificationBadge = Values<typeof PropertyVerificationBadges>;
