import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

import type { LiteHierarchicalLocation } from 'horizontal/types';

const selectLocationNameWithParent = <T extends LiteHierarchicalLocation>(
    i18n: I18n,
    hierarchy: readonly T[] | undefined,
): string => {
    if (!hierarchy || !hierarchy.length) {
        return settings.countryName(i18n);
    }

    const location = hierarchy[hierarchy.length - 1];

    const mainLocationName =
        typeof location.name === 'object' ? location.name[i18n.locale] : location.name;
    if (hierarchy.length < 2) {
        if (mainLocationName && location.level > 0) {
            return t(i18n)`${mainLocationName}, ${settings.countryName(i18n)}`;
        }
        return settings.countryName(i18n);
    }

    let parentLocation;
    switch (hierarchy.length) {
        case 2:
            parentLocation = {
                ...hierarchy[0],
                hierarchy: hierarchy.slice(0, 1),
            };
            break;
        case 3:
            parentLocation = {
                ...hierarchy[1],
                hierarchy: hierarchy.slice(0, 2),
            };
            break;
        default:
            parentLocation = {
                ...hierarchy[CONFIG.build.CITY_LEVEL],
                hierarchy: hierarchy.slice(0, CONFIG.build.CITY_LEVEL + 1),
            };
            break;
    }

    const parentLocationName = parentLocation?.name;
    return parentLocation ? t(i18n)`${mainLocationName}, ${parentLocationName}` : '';
};

export default selectLocationNameWithParent;
