import * as React from 'react';
import HorizontalLocationDropdown from 'horizontal/search/location/locationDropdown';
import type { LocationDropdownProps } from 'horizontal/search/location/locationDropdown';
import { useActiveCategoryHierarchy } from 'horizontal/categories';
import brandingSettings from '@app/branding/settings';

import CategoryTypeChecker from '../../branding/category';

const LocationDropdown = (props: LocationDropdownProps) => {
    const { maxLevel } = props;

    const categories = useActiveCategoryHierarchy();

    const isProperty = categories.find((category) =>
        CategoryTypeChecker.isOfPropertyType(category),
    );
    const maxLocationLevel =
        maxLevel || (isProperty ? undefined : brandingSettings.headerMaxLocationLevel);

    return <HorizontalLocationDropdown {...props} maxLevel={maxLocationLevel} />;
};

export default LocationDropdown;
