//@ts-nocheck
import React, { Component } from 'react';
import mapKeys from 'lodash/mapKeys';
import classNames from 'classnames';
import manifestJson from '@app/assets/manifest-pwa.json';
import appleTouchIcon from '@app/favicons/apple-touch-icon.png';
import favicon32x32 from '@app/favicons/favicon-32x32.png';
import favicon16x16 from '@app/favicons/favicon-16x16.png';
import safariPinnedTab from '@app/favicons/safari-pinned-tab.svg';
import settings from '@app/branding/settings';

import CrispScript from '@app/branding/crispScript';
import runtimeSettings from 'strat/runtimeSettings';
import { connectAppRenderer } from 'strat/rendering';
import { isLanguageRTL } from 'strat/i18n/language';
import RedirectToErrorPage from 'strat/util/redirectToErrorPage';
import { DNSPreFetch } from 'strat/seo';
import { GTMScript, GTMFrame, GTMCumulativeLayoutShiftTracker } from 'strat/gtm';
import { useResizeObserver, withErrorBoundary } from 'strat/util';
import { OneSignalScript } from 'strat/notifications';
import Layout from 'strat/layout';
import MoEngage from 'strat/moEngage';
import { TopLoadingBar } from 'strat/loadable';
import { ZendeskScript } from 'strat/zendesk/zendeskScript';

import styles from './styles/renderer.cssm';

const VWValueObserver = () => {
    const onResize = React.useCallback(() => {
        const vw = document.documentElement.clientWidth / 100;
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    }, []);

    const observe = useResizeObserver(onResize);

    if (process.env.IS_BROWSER) {
        observe(document?.documentElement);
    }

    return null;
};

/**
 * Renders a page's <head> and <body>
 */
// eslint-disable-next-line react/prefer-stateless-function
class Renderer extends Component {
    render() {
        return (
            <html
                lang={this.props.language}
                dir={isLanguageRTL(this.props.language) ? 'rtl' : 'ltr'}
                itemScope
                {...mapKeys(this.props.experiments, (v, k) => k.toLowerCase())}
                itemType="http://schema.org/WebPage"
            >
                <head>
                    <meta charSet="UTF-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, user-scalable=0"
                    />
                    <DNSPreFetch urls={CONFIG.build.DNS_PREFETCHED_URLS} />
                    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
                    <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
                    <link rel="manifest" href={manifestJson} />
                    <link rel="mask-icon" href={safariPinnedTab} color="#28b16d" />
                    <meta name="theme-color" content={settings.themeColor || '#ffffff'} />
                    {this.props.head}
                    <script
                        id="runtimeSettings"
                        dangerouslySetInnerHTML={{
                            __html: `window.CONFIG={runtime: Object.assign(${JSON.stringify(
                                runtimeSettings,
                            )}, (window.CONFIG && window.CONFIG.runtime) ? window.CONFIG.runtime : {})};`,
                        }}
                    />
                    <script
                        id="fontsScript"
                        dangerouslySetInnerHTML={{
                            __html: this.props.bundles['fonts.js'].contents,
                        }}
                    />
                    <style
                        id="browserStyles"
                        data-href={this.props.bundles['browser.css'].publicPath}
                        dangerouslySetInnerHTML={{
                            __html: this.props.bundles['browser.css'].contents,
                        }}
                    />
                    {(this.props.bundles.preloadedStyles || []).map((entry) => (
                        <style
                            key={entry.publicPath}
                            data-href={entry.publicPath}
                            data-preload-type="style"
                            dangerouslySetInnerHTML={{
                                __html: entry.contents,
                            }}
                        />
                    ))}
                    {this.props.translationURLs.map((url) => (
                        <link key={`link-${url}`} rel="preload" as="script" href={url} />
                    ))}
                    <link
                        id="browserMainScript"
                        rel="preload"
                        as="script"
                        href={this.props.bundles['browser.js'].publicPath}
                    />
                    {(this.props.bundles.preloadedScripts || []).map((entry) => (
                        <link
                            key={entry.publicPath}
                            rel="preload"
                            as="script"
                            data-preload-type="script"
                            href={entry.publicPath}
                        />
                    ))}
                    <GTMScript />
                    <GTMCumulativeLayoutShiftTracker />
                    {CONFIG.build.ENABLE_PUSH_NOTIFICATIONS && <OneSignalScript />}
                    {CONFIG.build.ENABLE_MO_ENGAGE_PUSH_NOTIFICATIONS && <MoEngage />}
                    <CrispScript />
                </head>
                <body>
                    <VWValueObserver />
                    <GTMFrame />
                    <div
                        id="body-wrapper"
                        className={classNames(styles.bodyWrapper, {
                            [styles.mobile]: this.props.layout === Layout.MOBILE,
                            [styles.desktop]: this.props.layout !== Layout.MOBILE,
                        })}
                    >
                        {this.props.body}
                        {this.props.dialogs}
                    </div>
                    <TopLoadingBar />
                    <script
                        dangerouslySetInnerHTML={{
                            __html: this.props.js,
                        }}
                    />
                    {this.props.translationURLs.map((url) => (
                        <script src={url} key={url} defer />
                    ))}
                    <script src={this.props.bundles['browser.js'].publicPath} defer />
                    <script src={this.props.bundles['lazyLoad.js'].publicPath} defer />
                    {CONFIG.build.ZENDESK_KEY && <ZendeskScript />}
                </body>
            </html>
        );
    }
}

export default withErrorBoundary(connectAppRenderer(Renderer), RedirectToErrorPage);
