import React from 'react';
import omit from 'lodash/omit';
import { isNil } from 'lodash';
import type { Locales } from '@lingui/core';

import { Input } from 'strat/forms';
import type { InputProps } from 'strat/forms';
import { formatUserInputNumber, parseFormattedNumber } from 'strat/i18n/language';

/**
 * A value that can be displayed by {@see NumberSelector}.
 */
type Value = null | number | string;

type Props = Omit<InputProps, 'value' | 'onChange'> & {
    value: Value;
    locales: Locales | null | undefined;
    onChange: (value: number) => void;
    defaultValue?: number;
    inputClassName?: string;
    id?: string;
};

/**
 * Component that can be used to enter numbers represented using different
 * locales. For example these numbers represents the same value:
 *
 *  * 1234
 *  * 1,234
 *  * ١٢٣٤
 *  * ١,٢٣٤
 *  * ١٬٢٣٤
 *
 *  While the component will continue to display whatever the user entered
 *  the onChange() callback will be called with the parsed value.
 */
const MultiLanguageNumericInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const initialValue = !isNil(props.defaultValue) ? `${props.defaultValue}` : '';
    const [value, setValue] = React.useState(initialValue);

    const onChange = (event: React.ChangeEvent<any>) => {
        const textValue = event.target.value;
        const numericValue = parseFormattedNumber(textValue, props.locales);
        const formattedValue = formatUserInputNumber(textValue, props.locales);
        if (formattedValue !== textValue || formattedValue !== value) {
            setValue(formattedValue);
        }
        props.onChange(numericValue);
    };

    const oldIntValue = parseFormattedNumber(value, props.locales);
    const newIntValue = parseFormattedNumber(props.value, props.locales);
    const newValueIsInvalid = props.value === null || props.value === undefined;
    let displayValue = value;
    if (!(oldIntValue === newIntValue || newValueIsInvalid)) {
        displayValue = (props.value || '').toString();
        setValue(displayValue);
    }

    return (
        <Input {...{ ...omit(props, ['defaultValue']), onChange, value: displayValue }} ref={ref} />
    );
});

export default MultiLanguageNumericInput;
