import * as React from 'react';

import { Link as RouterLink, useRouter } from 'react-true-router';

export type Props = React.ComponentProps<typeof RouterLink>;

const Link = ({ route, hard, onClick, ...props }: Props) => {
    const router = useRouter();

    // @ts-expect-error - TS2339 - Property 'pages' does not exist on type 'HistoryRouter'.
    const routerPages = router?.pages || {};
    const isRouteInBundle = (route || '') in routerPages;

    return (
        <RouterLink route={route} hard={hard || !isRouteInBundle} onClick={onClick} {...props}>
            {props.children}
        </RouterLink>
    );
};

export default Link;
