import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { PaymentSelectionFreeAdUndertakingRouteParams } from 'horizontal/routes/paymentSelectionFreeAdUndertakingRoute';

type UseNavigateToPaymentSelectionFreeAd = (
    arg1?: PaymentSelectionFreeAdUndertakingRouteParams | null | undefined,
) => void;

const useNavigateToPaymentSelectionFreeAd = (): UseNavigateToPaymentSelectionFreeAd => {
    const router = useRouter();

    return React.useCallback(
        (params?: PaymentSelectionFreeAdUndertakingRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_SELECTION_FREE_AD_UNDERTAKING, params);
        },
        [router],
    );
};

export default useNavigateToPaymentSelectionFreeAd;
