import * as React from 'react';
import { Trans } from '@lingui/macro';

import type { PropertyData } from 'strat/property/types';
import { Flex } from 'strat/components';
import InfoIcon from 'strat/property/infoIcon';
import { ResponsiveBottomSheet } from 'strat/modal/compact/bottomSheet';

import styles from './styles/propertyHandoverBottomSheet.cssm';

/**
 * Properties for {@see HandoverBottomSheet}.
 */
type Props = {
    property: PropertyData;
    renderInformation: (property: PropertyData) => JSX.Element;
    renderTrigger: () => JSX.Element;
};

const HandoverBottomSheet = ({ property, renderInformation, renderTrigger }: Props) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(false);

    return (
        <Flex onClick={() => setIsBottomSheetOpen(true)}>
            {renderTrigger()}
            <div className={styles.infoIconContainer}>
                <InfoIcon />
            </div>
            <ResponsiveBottomSheet
                name={property.externalID}
                onClose={() => setIsBottomSheetOpen(false)}
                isOpen={isBottomSheetOpen}
            >
                {renderInformation(property)}
                <div
                    onClick={() => setIsBottomSheetOpen(false)}
                    className={styles.closeButton}
                    aria-label="Close"
                >
                    <Trans>Close</Trans>
                </div>
            </ResponsiveBottomSheet>
        </Flex>
    );
};

export default HandoverBottomSheet;
