import * as React from 'react';

import styles from './styles/paginationCell.cssm';

export type Props = {
    title: string;
    content: React.ReactNode;
    isCurrentPage?: boolean;
};

const PaginationCell = ({ title, content, isCurrentPage }: Props) => (
    <div
        title={title}
        className={[styles.paginationCell, isCurrentPage ? styles.currentPage : ''].join(' ')}
    >
        {content}
    </div>
);

export default PaginationCell;
