import * as React from 'react';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { makeCancelable } from 'strat/util';
import { selectUserExternalID } from 'strat/user/session';
import { useI18n } from 'strat/i18n/language';

import { StratAPI } from 'horizontal/api';
import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldRole } from 'horizontal/types';
import { UserAgency } from 'horizontal/agent/state';

import {
    FIELD_ATTRIBUTES_AFTER_PRICE_TYPE,
    FIELD_ATTRIBUTES_BEFORE_PRICE,
} from './postingConstants';
import {
    AgentCodeField,
    CategoryPriceField,
    PriceTypeField,
    SecondaryPriceField,
    ReferenceIDField,
} from './commonPostingFields';

const useRenderedFields = (
    allCategoryFields: Array<FlatCategoryField>,
): [boolean, Array<FlatCategoryField>] => {
    const [agencies, setAgencies] = React.useState<UserAgency[]>([]);
    const [loading, setLoading] = React.useState(true);
    const userExternalID = useSelector(selectUserExternalID);
    const i18n = useI18n();
    React.useEffect(() => {
        setLoading(true);
        const cancelablePromise = makeCancelable(
            settings.disableAgency || !userExternalID
                ? Promise.resolve({ data: [] as UserAgency[], status: 200 })
                : new StratAPI(i18n.locale).userAgencies(userExternalID),
        );
        cancelablePromise.then((response) => {
            setLoading(false);
            if (response.status === 200) {
                setAgencies(response.data);
            }
        });
        return () => {
            cancelablePromise.cancel();
        };
    }, [userExternalID, i18n]);

    return React.useMemo(() => {
        const attributesToSkip = [
            ...FIELD_ATTRIBUTES_BEFORE_PRICE,
            CategoryPriceField.attribute,
            PriceTypeField.attribute,
            SecondaryPriceField.attribute,
            ...FIELD_ATTRIBUTES_AFTER_PRICE_TYPE,
        ];
        if (!(agencies?.length > 0)) {
            // @ts-expect-error - TS2345 - Argument of type '"reference_id"' is not assignable to parameter of type '"price" | "price_type" | "secondary_price"'.
            attributesToSkip.push(ReferenceIDField.attribute);
            // @ts-expect-error - TS2345 - Argument of type '"agent_code"' is not assignable to parameter of type '"price" | "price_type" | "secondary_price"'.
            attributesToSkip.push(AgentCodeField.attribute);
        }
        attributesToSkip.push(
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"price" | "price_type" | "secondary_price"'.
            ...allCategoryFields
                .filter((val) => val.roles.includes(CategoryFieldRole.EXCLUDE_FROM_POST_AN_AD))
                .map((val) => val.attribute),
        );

        return [
            loading,
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"price" | "price_type" | "secondary_price"'.
            allCategoryFields.filter((field) => !attributesToSkip.includes(field.attribute)),
        ];
    }, [allCategoryFields, agencies, loading]);
};

export default useRenderedFields;
