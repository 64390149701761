import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text, Flex } from 'strat/components';

import WordCloud from './wordCloud';
import PopularSearchesBreadcrumbs from './popularSearchesBreadcrumbs';
import PopularSearchesRegions from './popularSearchesRegions';
import PopularSearchesCategory from './popularSearchesCategory';
import styles from './styles/sitemapPopularSearches.cssm';

const SitemapPopularSearches = () => {
    return (
        <div>
            <div>
                <PopularSearchesBreadcrumbs />
            </div>
            <div>
                <Text.XXLarge bold>
                    <Trans>Popular Searches</Trans>
                </Text.XXLarge>
            </div>
            <div className={styles.wordCloudContainer}>
                <WordCloud />
            </div>
            <Flex className={styles.linksContainer}>
                <div className={styles.regionContainer}>
                    <PopularSearchesRegions />
                </div>
                <div className={styles.categoryContainer}>
                    <PopularSearchesCategory />
                </div>
            </Flex>
        </div>
    );
};

export default SitemapPopularSearches;
