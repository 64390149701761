import * as React from 'react';

import type { AnyAdData } from 'strat/types';

import ThumbnailImage from './thumbnailImage';
import { ThumbnailSizes } from './thumbnailSizes';

type Props = {
    size: Values<typeof ThumbnailSizes>;
    property: AnyAdData;
    className?: string;
    title?: string;
    imageID?: number | null | undefined;
    thumbnailURL?: (arg1?: any, arg2?: any, arg3?: any) => any;
    lazyLoad?: boolean;
    onLoad?: () => void;
};

const PropertyThumbnailImage = ({ lazyLoad = true, ...props }: Props): React.ReactElement => {
    const title = props.title ? props.title : props.property.coverPhotoTitle;
    return (
        <ThumbnailImage
            imageID={
                props.imageID || props.property.coverPhotoID || (props.property.coverPhoto || {}).id
            }
            size={props.size}
            alt={title}
            title={title}
            label="Listing photo"
            thumbnailURL={props.thumbnailURL}
            className={props.className}
            lazyLoad={lazyLoad}
            onLoad={props.onLoad}
        />
    );
};

export default PropertyThumbnailImage;
