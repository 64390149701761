import { t } from '@lingui/macro';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { SearchRequestSortType, SearchRequestOrder } from '@sector-labs/fe-search-redux/backend';
import settings from '@app/branding/settings';
import { selectI18n } from 'strat/i18n/language/selectors';
import { selectCategory } from 'strat/search/selectors';
import { GlobalState } from 'strat/state';

import type { Category, SearchSortOption } from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';

export const SortOptionKey = Object.freeze({
    NEWLY_LISTED: 'desc-creation',
    MOST_RELEVANT: 'desc-relevance',
    LOWEST_PRICE: 'asc-price',
    HIGHEST_PRICE: 'desc-price',
    VERIFIED_ACCOUNT_FIRST: 'verified-account-first',
});

export const selectKeyedSortOptions = createSelector(selectI18n, (i18n) => ({
    [SortOptionKey.NEWLY_LISTED]: {
        type: SearchRequestSortType.ATTRIBUTES,
        key: SortOptionKey.NEWLY_LISTED,
        label: t(i18n)`Newly listed`,
        attributes: [
            { name: 'timestamp', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    } as SearchSortOption,
    [SortOptionKey.MOST_RELEVANT]: CONFIG.runtime.STRAT_PREFER_PHRASE_FOR_MOST_RELEVANT
        ? ({
              type: SearchRequestSortType.CONSTANT_SCORE,
              key: SortOptionKey.MOST_RELEVANT,
              label: t(i18n)`Most relevant`,
              attributes: [
                  { name: '_score', order: SearchRequestOrder.DESC },
                  { name: 'timestamp', order: SearchRequestOrder.DESC },
              ],
          } as SearchSortOption)
        : ({
              type: SearchRequestSortType.ATTRIBUTES,
              key: SortOptionKey.MOST_RELEVANT,
              label: t(i18n)`Most relevant`,
              attributes: settings.useMostRelevantAsNewlyListed
                  ? [
                        { name: 'timestamp', order: SearchRequestOrder.DESC },
                        { name: 'id', order: SearchRequestOrder.DESC },
                    ]
                  : [{ name: '_score', order: SearchRequestOrder.DESC }],
          } as SearchSortOption),
    [SortOptionKey.LOWEST_PRICE]: {
        type: SearchRequestSortType.ATTRIBUTES,
        key: SortOptionKey.LOWEST_PRICE,
        label: t(i18n)`Lowest price`,
        attributes: [
            ...settings.priceSortFields.map((name) => ({
                name,
                order: SearchRequestOrder.ASC,
            })),
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    } as SearchSortOption,
    [SortOptionKey.HIGHEST_PRICE]: {
        type: SearchRequestSortType.ATTRIBUTES,
        key: SortOptionKey.HIGHEST_PRICE,
        label: t(i18n)`Highest price`,
        attributes: [
            ...settings.priceSortFields.map((name) => ({
                name,
                order: SearchRequestOrder.DESC,
            })),
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    } as SearchSortOption,
    ...(CONFIG.runtime.ENABLE_USER_VERIFICATION_SORTING
        ? {
              [SortOptionKey.VERIFIED_ACCOUNT_FIRST]: {
                  type: SearchRequestSortType.ATTRIBUTES,
                  key: SortOptionKey.VERIFIED_ACCOUNT_FIRST,
                  label: t(i18n)`Verified accounts`,
                  attributes: [
                      { name: 'extraFields.seller_verified', order: SearchRequestOrder.DESC },
                      { name: 'productScore', order: SearchRequestOrder.DESC },
                      { name: 'timestamp', order: SearchRequestOrder.DESC },
                      { name: 'id', order: SearchRequestOrder.DESC },
                  ],
              } as SearchSortOption,
          }
        : {}),
}));

export const selectDefaultSortOption = createSelector(
    selectKeyedSortOptions,
    selectCategory,
    (_: GlobalState, category?: Category | null) => category,
    (_: GlobalState, __?: Category | null, withFreeTextQuery?: boolean | null) => withFreeTextQuery,
    (sortOptions, filtersCategory, category, withFreeTextQuery) => {
        const categoryRoles = (category || filtersCategory)?.roles || [];
        if (
            CategoryRole.isPayedSearchType(categoryRoles) ||
            (CONFIG.runtime.STRAT_PREFER_PHRASE_FOR_MOST_RELEVANT && withFreeTextQuery)
        ) {
            return sortOptions[SortOptionKey.MOST_RELEVANT];
        }
        return sortOptions[SortOptionKey.NEWLY_LISTED];
    },
);

export const useSortOptions = () => {
    const keyedSortOptions = useSelector(selectKeyedSortOptions);
    return Object.values(keyedSortOptions);
};

export const useSortOption = (sortType: Values<typeof SortOptionKey>) => {
    const sortingOptions = useSelector(selectKeyedSortOptions);
    return sortingOptions[sortType];
};

export const useDefaultSortOption = (category: Category | null | undefined): SearchSortOption => {
    return useSelector((state: GlobalState) => selectDefaultSortOption(state, category));
};
