import pick from 'lodash/pick';

import FetcherFactory from 'strat/fetcher';
import { StratAPI, InternalAPI } from 'strat/api';
import { ContextfulError, ErrorContext } from 'strat/error/context';

export class AgentReviewNotFoundError extends ContextfulError {
    constructor(msg: string, context?: ErrorContext) {
        super(msg, context);

        Object.setPrototypeOf(this, AgentReviewNotFoundError.prototype);
    }
}

/**
 * Fetcher factory for fetching the agent review.
 */
const factory = new FetcherFactory('agentReview', ({ externalID }) =>
    new StratAPI().getAgentReviews({ externalID }).then(({ status, data }) => {
        if (data.count === 0) {
            throw new AgentReviewNotFoundError('No agent reviews found', {
                id: externalID,
                data,
            });
        }

        let review = data.results[0];

        review.wasFilled = review.rating != null;
        review = pick(review, [
            'externalID',
            'wasFilled',
            'agentExternalID',
            'agentName',
            'agentPhotoURL',
            'agentSlug',
            'agencyName',
            'agencyPhotoURL',
        ]);
        return { status, data: review };
    }),
);

/**
 * Fetcher factory for fetching all the accepted reviews of an agent.
 */
const factoryAcceptedReviews = new FetcherFactory(
    'acceptedReviews',
    ({ agentExternalID, page, pageSize }) =>
        new InternalAPI()
            .acceptedAgentReviews(agentExternalID, page, pageSize)
            .then(({ status, data }) => {
                if (status !== 200) {
                    throw new ContextfulError(
                        'Unexpected response status while fetching accepted agent reviews',
                        { status, data },
                    );
                }
                return { status, data: data.results };
            }),
);

/**
 * Action creator for fetching a agent review from
 * the frontend server.
 */
const fetchAgentReview = factory.creator();

/**
 * Action creator for fetching all the accepted reviews of an agent from
 * the frontend server.
 */
const fetchAcceptedReviews = factoryAcceptedReviews.creator();

/**
 * Reducers for agentReview.
 */
const agentReviewReducer = factory.reducer();

/**
 * Reducers for acceptedReviews.
 */
const acceptedReviewsReducer = factoryAcceptedReviews.reducer();

export { fetchAgentReview, fetchAcceptedReviews, acceptedReviewsReducer };

export default agentReviewReducer;
