import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Text, Flex } from 'strat/components';

import { ChoicePageLayout } from 'horizontal/generic/choicePage';
import { PromoStrip } from 'horizontal/packages/individual';
import type { Ad, ProductPackageOffer, ProductPurchase } from 'horizontal/types';
import ChangeCurrencySection from 'horizontal/businessPackages/changeCurrencySection';
import useFetchPackagesExchangeRates from 'horizontal/businessPackages/useFetchPackagesExchangeRates';
import useIsInAgencyPortal from 'horizontal/user/useIsInAgencyPortal';
import getTaxInfoFromOffers from '@app/util/tax';

import ChoicesSection from './choicesSection';
import LimitedQuotaMessage from './limitedQuotaMessage';
import { useCTA } from './useCTA';
import { useChoices } from './useChoices';
import type { LimitPackageChoice } from './types';
import styles from './styles/applyLimitPackage.cssm';
import { LimitPackageChoiceType } from './types';

export const getEnabledChoicesIDs = (choices: Array<LimitPackageChoice>): Array<number> => {
    return choices
        .filter(
            (value) =>
                value.type !== LimitPackageChoiceType.PURCHASED || value.availableQuota !== 0,
        )
        .map((value) => value.id);
};

export const getDefaultLimitPackageChoice = (
    choices: Array<LimitPackageChoice>,
): LimitPackageChoice | undefined => {
    return choices.find(
        (value) => value.type !== LimitPackageChoiceType.PURCHASED || value.availableQuota !== 0,
    );
};

type Props = {
    readonly ad: Ad | null | undefined;
    readonly productPurchases: Array<ProductPurchase> | null | undefined;
    readonly offers: Array<ProductPackageOffer> | null | undefined;
};

const ApplyLimitPackage = ({ ad, productPurchases, offers }: Props) => {
    const i18n = useI18n();
    const isInAgencyPortal = useIsInAgencyPortal();

    const choices = useChoices(productPurchases, offers);
    const enabledChoicesId = getEnabledChoicesIDs(choices);
    const defaultChoice = getDefaultLimitPackageChoice(choices);

    const [userSelectedChoice, setSelectedChoice] = React.useState<
        LimitPackageChoice | null | undefined
    >();

    const onLimitPackageChoiceClick = React.useCallback(
        (choice: LimitPackageChoice) => {
            if (choice.type === LimitPackageChoiceType.PURCHASED && choice.availableQuota === 0) {
                return;
            }

            setSelectedChoice(choice);
        },
        [setSelectedChoice],
    );

    const selectedChoice = userSelectedChoice || defaultChoice;
    const [taxActive, taxPercentage] = getTaxInfoFromOffers(offers);

    useFetchPackagesExchangeRates();

    const { loading: isRunningCTA, onClick: onCTAClick, isError } = useCTA(selectedChoice, ad);

    const choicesSectionTitle = offers
        ? t(i18n)`What to do next`
        : t(i18n)`Select package to post the Ad`;

    return (
        <ChoicePageLayout
            isLoading={!ad || isRunningCTA}
            showCTA={!!selectedChoice}
            ctaText={t(i18n)`Upgrade Your Ad`}
            onCTAClick={onCTAClick}
            ad={ad}
            taxActive={taxActive}
            taxPercentage={taxPercentage}
        >
            <LimitedQuotaMessage ad={ad} />
            {settings.multipleCurrencyPackages && (
                <Flex className={styles.currencySwitcherContainer}>
                    <ChangeCurrencySection />
                </Flex>
            )}
            <Flex column className={styles.productsContainer}>
                <ChoicesSection
                    ad={ad}
                    title={choicesSectionTitle}
                    choices={choices}
                    onClick={onLimitPackageChoiceClick}
                    selectedChoiceID={selectedChoice?.id}
                    enabledChoicesId={enabledChoicesId}
                />
                {!isInAgencyPortal && (
                    <PromoStrip
                        text={t(i18n)`Heavy discount on Business Packages`}
                        ad={ad}
                        availableAdPackages={offers}
                    />
                )}
            </Flex>
            {isError && (
                <Text.Base error className={styles.errorText}>
                    {t(i18n)`Something went wrong. Please try again.`}
                </Text.Base>
            )}
        </ChoicePageLayout>
    );
};

export default ApplyLimitPackage;
