import type { PropertyData } from 'strat/property/types';

/**
 * Return the variables if the property has hidePrice = true
 */
const getHidePriceGTMVars = (propertyData: PropertyData): any => {
    if (!propertyData.hidePrice) {
        return {};
    }
    return {
        name: 'listing_type',
        value: 'CFP',
    };
};

export default getHidePriceGTMVars;
