import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveUser } from 'strat/user/session';
import { useI18n } from 'strat/i18n/language';
import { LoadingSpinner } from 'strat/loadable';

import { fetchUserBuyingList } from 'horizontal/userOrders/buyingOrders';
import type { AppDispatch } from 'horizontal/state';

import { useDeliveryOrdersNavStatus } from '../hooks';
import { EmptyDeliveryOrders } from '../deliveryOrders';
import { DeliveryRequestStatus } from '../types';

import { selectBuyerList, selectBuyerListLoaded } from './selectors';
import BuyerOrdersCard from './buyerOrdersCard';

const BuyingOrdersList = () => {
    const activeUser = useSelector(selectActiveUser);
    const userExternalID = activeUser?.externalID;
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    const status = useDeliveryOrdersNavStatus();
    const buyerList = useSelector(selectBuyerList);
    const loading = useSelector(selectBuyerListLoaded);
    const emptyPageMsg =
        status === DeliveryRequestStatus.ACTIVE
            ? t(
                  i18n,
              )`You don’t have any active orders. Buy now any item that is eligible for delivery & receive it to your doorstep!`
            : t(
                  i18n,
              )`You don’t have any archived orders. Buy now any item that is eligible for delivery & receive it to your doorstep!`;

    React.useEffect(() => {
        dispatch(fetchUserBuyingList({ offersStatus: status }));
    }, [dispatch, status]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!userExternalID || buyerList.length === 0) {
        return <EmptyDeliveryOrders msg={emptyPageMsg} />;
    }

    return (
        <>
            {buyerList.map((order) => {
                return (
                    <BuyerOrdersCard
                        key={order.id}
                        id={order.id}
                        reqStatus={order.status}
                        coverPhoto={order.ad.coverPhoto}
                        userExternalID={userExternalID}
                        currentTab={status}
                        ad={order.ad}
                        price={order.price}
                        deliveryType={order.deliveryType}
                    />
                );
            })}
        </>
    );
};

export default BuyingOrdersList;
