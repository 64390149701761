import * as React from 'react';
import { useActiveUser } from 'strat/user/session';

import { UserState } from 'horizontal/types';

import styles from './styles/useIsAccountSuspended.cssm';

const useIsAccountSuspended = (): boolean => {
    const activeUser = useActiveUser();
    return activeUser?.state === UserState.LIGHT_BANNED || activeUser?.state === UserState.BANNED;
};

const useIsChatSuspended = (): boolean => {
    const isUserBanned = useIsAccountSuspended();
    const activeUser = useActiveUser();
    return isUserBanned || activeUser?.state === UserState.CHAT_DISABLED;
};

const useRenderErrorIfAccountSuspended = (message: string) => {
    const isUserBanned = useIsAccountSuspended();

    if (!isUserBanned) {
        return null;
    }

    return () => <div className={styles.suspended}>{message}</div>;
};

export { useIsAccountSuspended, useIsChatSuspended };

export default useRenderErrorIfAccountSuspended;
