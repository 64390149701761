import React from 'react';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'horizontal/state';

import { fetchMyAds } from './state';
import useRouteParams from './useRouteParams';

const useRefreshMyAds = () => {
    const dispatch = useDispatch<AppDispatch>();
    const params = useRouteParams();

    return React.useCallback(() => dispatch(fetchMyAds(params)), [dispatch, params]);
};

export default useRefreshMyAds;
