import * as React from 'react';
import { Flex } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';

import IconInfoCircle from 'horizontal/assets/icons/iconInfoCircle.svg';
import { DialogNames } from 'horizontal/dialogs';

import styles from './styles/inputInfoTooltip.cssm';

type Props = {
    readonly title: string;
};

const InputInfoTooltip = ({ title }: Props) => {
    const [, setDialogVisible] = useDialog(DialogNames.POST_AD_MILEAGE_INFO_DIALOG);

    return (
        <Flex alignCenter>
            {title}
            <IconInfoCircle onClick={() => setDialogVisible(true)} className={styles.icon} />
        </Flex>
    );
};

export default InputInfoTooltip;
