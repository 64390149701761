import * as React from 'react';
import { Flex, Text } from 'strat/components';

import ChoiceButton from './choiceButton';
import type { BasicChoice, ChoiceBoxProps } from './types';

type SimpleChoice = {
    id: string;
    title: string;
} & BasicChoice;

const SimpleChoiceBox = <T extends SimpleChoice>({
    isSelected,
    choice,
    isEnabled,
}: ChoiceBoxProps<T>) => (
    <Flex>
        <ChoiceButton isSelected={isSelected} isEnabled={isEnabled} />
        <Text.Large bold>{choice.title}</Text.Large>
    </Flex>
);

export default SimpleChoiceBox;
