import * as React from 'react';
import { useActiveUserExternalID } from 'strat/user/session';

import { ChatFeatureStatus } from 'horizontal/chat/constants';
import { useChatSDKModule } from 'horizontal/chat/sdk';
import { ChatSDKContext } from 'horizontal/chat/state';
import { ChatPropsContextType } from 'horizontal/chat/types';

export type WithChatStateProps = ChatPropsContextType & {
    readonly inboxPageSize?: number | null | undefined;
    readonly ensureFirebaseAuth?: boolean | null;
};

/**
 * Lazy loads chat libs and wrap component with chat state provider.
 */
const withChatState = <T extends WithChatStateProps>(
    Component: React.FC<T & { chatStatus: Values<typeof ChatFeatureStatus> }>,
    FallbackComponent?: React.FC<T & { chatStatus: Values<typeof ChatFeatureStatus> }>,
): React.FC<T> => {
    return (props: T) => {
        const activeUserXID = useActiveUserExternalID();
        const { ChatProvider, chatStatus, chatSDK } = useChatSDKModule();

        if (chatStatus === ChatFeatureStatus.LOADED && ChatProvider && chatSDK) {
            return (
                <ChatSDKContext.Provider value={chatSDK}>
                    <ChatProvider activeUserXID={activeUserXID} {...props}>
                        <Component {...props} chatStatus={chatStatus} />
                    </ChatProvider>
                </ChatSDKContext.Provider>
            );
        }

        return FallbackComponent ? <FallbackComponent {...props} chatStatus={chatStatus} /> : null;
    };
};

export default withChatState;
