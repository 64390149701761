import * as React from 'react';

import { useChatSDK } from 'horizontal/chat/hooks';
import type { ActionButton, ChatRoom } from 'horizontal/chat/types';
import useChatGTMVarsProps from 'horizontal/chat/useChatGTMVarsProps';
import { useTrackChatReply } from 'horizontal/gtm';

import Messages, { useMessageReply } from './messages';
import ScrollContainer from './scrollContainer';

type Props = {
    readonly className?: string;
    readonly actionButtons?: Array<ActionButton>;
    readonly conversation: ChatRoom;
};

const MessagesContainer = ({ className, actionButtons, conversation }: Props) => {
    const sdk = useChatSDK();
    const { messages, loadMore, isLoading, isEndReached } = sdk.useChatMessages({
        roomXID: conversation.xid,
        draftRoomXID: conversation.draftXID,
        loadOnMount: true,
        pageSize: 20,
    });

    const props = {
        actionButtons,
        conversation,
        messages,
        loadMore,
        isLoading,
        isEndReached,
    } as const;

    const gtmProps = useChatGTMVarsProps();
    const trackChatReply = useTrackChatReply(gtmProps);
    useMessageReply(messages, trackChatReply);

    return (
        <ScrollContainer {...props} className={className}>
            <Messages {...props} />
        </ScrollContainer>
    );
};

export default MessagesContainer;
