import React from 'react';
import { useSelector } from 'react-redux';

import type {
    CategoryField,
    CategoryFieldChoice,
    CategoryFieldsFilters,
    FlatCategoryField,
} from 'strat/types';
import type { CategoryFields } from 'strat/categoryFields';
import { GlobalState } from 'strat/state';

import { selectCategoryFields } from './selectors';
import combineCategoryFields from './combineCategoryFields';
import flattenCategoryFields from './flattenCategoryFields';

const useCategoryFields = (categoryID?: number | null): CategoryFields =>
    useSelector((state: GlobalState) => selectCategoryFields(state, categoryID));

const useCategoryFieldChoices = (
    attribute: string,
    categoryID?: number,
    parentValues?: CategoryFieldsFilters,
): CategoryFieldChoice[] => {
    const flatCategoryFields = useFlatCategoryFields(categoryID, parentValues);
    return flatCategoryFields.find((field) => field.attribute === attribute)?.choices || [];
};

const useFlatCategoryFields = (
    categoryID?: number | null,
    formValues?: CategoryFieldsFilters,
): Array<FlatCategoryField> => {
    const categoryFields = useCategoryFields(categoryID);
    return React.useMemo(
        () => flattenCategoryFields(categoryFields, formValues),
        [categoryFields, formValues],
    );
};

const useCategoryFieldsAreLoading = () =>
    useSelector((state: GlobalState) => state.categoryFields.loading);

const useCombinedCategoryFields = (categoryID?: number): Array<CategoryField> => {
    const categoryFields = useCategoryFields(categoryID);
    return React.useMemo(() => combineCategoryFields(categoryFields), [categoryFields]);
};
export {
    useCategoryFields,
    useFlatCategoryFields,
    useCategoryFieldsAreLoading,
    useCategoryFieldChoices,
    useCombinedCategoryFields,
};
