import { t } from '@lingui/macro';
import memoize from 'fast-memoize';
import {
    SearchRequestSortType,
    SearchRequestOrder,
    SearchResponseHit,
} from '@sector-labs/fe-search-redux/backend';
import { ElasticSuggestMode, useElasticSuggestions } from '@sector-labs/fe-search-redux/suggestion';
import { ExistFilter } from '@sector-labs/fe-search-redux/filters';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';
import { determineFrequentSearchQueriesIndexName } from 'strat/search/indexNames';

import type { FreeTextSuggestion, LiteCategory } from 'horizontal/types';

const createOptions = memoize((indexName: string) => ({
    indexName,
    minimumQueryLength: 2,
    suggestOptions: {
        attribute: 'query',
        mode: ElasticSuggestMode.COMPLETION,
        length: 5,
    },
    exactMatchOptions: {
        attribute: 'query.exact',
        length: 3,
        filters: [new ExistFilter({ attribute: 'category' })],
        sort: {
            type: SearchRequestSortType.ATTRIBUTES,
            key: 'frequency',
            attributes: [
                {
                    name: 'adCount',
                    order: SearchRequestOrder.DESC,
                },
            ],
        },
    },
}));

type SuggestedHit = SearchResponseHit & {
    id: number;
    query: {
        input: string;
    };
    category?: LiteCategory[];
};

type ExactMatchHit = SuggestedHit & {
    category: LiteCategory[];
};

const extractLeafCategory = (hit: ExactMatchHit) => hit.category[hit.category.length - 1];

/**
 * Maps exact match hits to free text suggestions.
 *
 * The highlighted query for exact match query will be `{query} in {category level 2}
 * In case there are multiple hits with the same category level 2 name but different category level 1 like Apartments & Flats that can be both in Property for sale and Property for rent,
 * the highlighted query will be `{query} in {category level 1} - {category level 2}
 */
const exactMatchHitToFreeTextSuggestions = (
    hits: ExactMatchHit[],
    i18n: I18n,
): FreeTextSuggestion[] => {
    const categoriesFrequencies = hits.reduce<Record<string, any>>((acc, hit) => {
        const categoryName = extractLeafCategory(hit).name;

        acc[categoryName] = acc[categoryName] ? acc[categoryName] + 1 : 1;

        return acc;
    }, {});

    // @ts-expect-error - TS2322 - Type '{ id: number; highlightedQuery: string; searchParams: { freeTextQuery: string; category: LiteCategory; }; }[]' is not assignable to type 'FreeTextSuggestion[]'.
    return hits.map((hit) => {
        const category = extractLeafCategory(hit);

        const categoryLabel =
            categoriesFrequencies[category.name] > 1
                ? hit.category.map((categoryNode) => categoryNode.name).join(' - ')
                : category.name;

        return {
            id: hit.id,
            highlightedQuery: t(i18n)`<em>${hit.query.input}</em> in ${categoryLabel}`,
            searchParams: {
                freeTextQuery: hit.query.input,
                category,
            },
        };
    });
};

const suggestedHitToFreeTextSuggestions = (hits: SuggestedHit[]): FreeTextSuggestion[] =>
    hits
        .filter((hit: SuggestedHit) => !hit.category)
        .map((hit: SuggestedHit) => ({
            id: hit.id,
            highlightedQuery: hit._highlightResult?.query?.[0]?.value || hit.query.input,
            searchParams: {
                freeTextQuery: hit.query.input,
            },
        }));

const useFreeTextSuggestions = (query: string): Array<FreeTextSuggestion> => {
    const i18n = useI18n();
    const indexName = determineFrequentSearchQueriesIndexName({ language: i18n.locale });

    const { suggestions, hits } = useElasticSuggestions<ExactMatchHit, SuggestedHit>(
        query,
        // @ts-expect-error - TS2345 - Argument of type '{ indexName: string; minimumQueryLength: number; suggestOptions: { attribute: string; mode: ElasticSuggestMode; length: number; }; exactMatchOptions: { attribute: string; length: number; filters: ExistFilter[]; sort: { ...; }; }; }' is not assignable to parameter of type 'ElasticSuggestionsOptions'.
        createOptions(indexName),
    );

    return [
        // @ts-expect-error - TS2345 - Argument of type 'readonly ExactMatchHit[] | undefined' is not assignable to parameter of type 'ExactMatchHit[]'.
        ...exactMatchHitToFreeTextSuggestions(hits, i18n),
        // @ts-expect-error - TS2345 - Argument of type 'readonly SuggestedHit[]' is not assignable to parameter of type 'SuggestedHit[]'.
        ...suggestedHitToFreeTextSuggestions(suggestions),
    ];
};

export default useFreeTextSuggestions;
