import { t } from '@lingui/macro';
import * as React from 'react';
import { ThumbnailSizes } from 'strat/image';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import { formatDate } from 'horizontal/util';
import { CoverPhoto, Link } from 'horizontal/adCard';
import { useAdPhotos, useLocalizedAdTitle } from 'horizontal/ad';
import AdStatePill from 'horizontal/adManagement/statePill';
import extractProductInfo from 'horizontal/userOrders/orders/extractProductInfo';
import type { FullAd } from 'horizontal/types';

import styles from './styles/listEntryCompact.cssm';

const ListEntryCompact = ({ ad, productPurchaseID }: { ad: FullAd; productPurchaseID: number }) => {
    const i18n = useI18n();
    const [coverPhoto] = useAdPhotos(ad);
    const adLocalizedTitle = useLocalizedAdTitle(ad);

    const { allProductConsumptions } = ad;
    let productInfo;
    if (allProductConsumptions) {
        productInfo = extractProductInfo(allProductConsumptions, productPurchaseID);
    }
    if (!productInfo) {
        return null;
    }

    return (
        <div className={styles.container}>
            {/* @ts-expect-error - TS2741 - Property 'preloadedAd' is missing in type '{ children: Element; params: { slug: string; externalID: string; }; }' but required in type '{ readonly preloadedAd: MixedAd | null | undefined; }'. */}
            <Link params={{ slug: ad.slug, externalID: ad.externalID }}>
                <Flex className={styles.ad} fillContainer>
                    <Flex column alignCenter fillContainer>
                        <Flex alignCenter className={styles.top}>
                            <Flex alignCenter className={styles.datesOfInterest}>
                                <Text.Regular>{t(i18n)`Created On`}: </Text.Regular>
                                <Text.Regular bold aria-label="Ad creation date">
                                    {formatDate(i18n, ad.createdAt * 1000)}
                                </Text.Regular>
                            </Flex>
                        </Flex>
                        <Flex column className={styles.details} stretchWidth fillContainer>
                            <Flex>
                                <Flex className={styles.image}>
                                    <CoverPhoto
                                        cover={coverPhoto}
                                        size={ThumbnailSizes.TINY}
                                        lazyLoad
                                        category={ad.category}
                                    />
                                </Flex>
                                <Flex className={styles.title} column>
                                    <Text.Regular aria-label="Ad title" bold>
                                        {adLocalizedTitle}
                                    </Text.Regular>
                                </Flex>
                                <Flex alignCenter>
                                    <Flex className={styles.field} column>
                                        <Text.Regular>{t(i18n)`Ad ID`}: </Text.Regular>
                                        <Text.Regular aria-label="Ad ID" bold>
                                            {ad.externalID}
                                        </Text.Regular>
                                    </Flex>

                                    <Flex className={styles.field} column>
                                        <Text.Regular>{t(i18n)`Applied At`}: </Text.Regular>
                                        <Text.Regular aria-label="Applied At" bold>
                                            {formatDate(i18n, productInfo?.appliedAt * 1000)}
                                        </Text.Regular>
                                    </Flex>

                                    <Flex className={styles.field} column>
                                        <Text.Regular>{t(i18n)`Expired At`}: </Text.Regular>
                                        <Text.Regular aria-label="Expired At" bold>
                                            {formatDate(i18n, productInfo?.expiresAt * 1000)}
                                        </Text.Regular>
                                    </Flex>

                                    <Flex className={styles.field}>
                                        <AdStatePill ad={ad} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Link>
        </div>
    );
};

export default ListEntryCompact;
