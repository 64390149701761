import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import { GlobalState } from 'horizontal/state';

const factory = new FetcherFactory(
    ['sellerProfile'],
    ({ externalID, adExternalID }, state: GlobalState) => {
        if (externalID) {
            return new StratAPI(state.i18n.language).user(externalID, 'lite');
        }

        return new StratAPI(state.i18n.language).userFromAd(adExternalID, 'lite');
    },
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

const fetchSellerProfile = factory.creator();
const sellerProfileReducer = factory.reducer();

const clearSellerProfile = () => ({
    type: factory.actions.clear,
});

export { fetchSellerProfile, clearSellerProfile };

export default sellerProfileReducer;
