import * as React from 'react';
import { Trans } from '@lingui/macro';

import type { AnyAdData } from 'strat/types';

import computeProjectHandoverQuarter from '../computeProjectHandoverQuarter';

import Badge from './badge';
import { ProjectBadgeStyle } from './types';

type Props = {
    ad: AnyAdData;
    style: ProjectBadgeStyle;
};

const HandoverBadge = ({ ad, style }: Props) => {
    const handover = computeProjectHandoverQuarter(ad.completionDetails);

    if (!handover) {
        return null;
    }

    const { quarter, year } = handover;

    return (
        <Badge
            style={style}
            renderTitle={() => <Trans>Handover</Trans>}
            renderValue={() => (
                <Trans>
                    Q{quarter} {year}
                </Trans>
            )}
            label="Handover"
        />
    );
};

export default HandoverBadge;
