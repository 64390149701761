import Purpose from 'strat/purpose';

// TODO: delete this when api endpoint is implemented
const useMostPopularCategories = () => [
    {
        id: 12,
        name: 'Mobiles',
        externalID: '1411',
        slug: 'mobiles',
        level: 0,
        parentID: null,
        displayPriority: 100,
        children: [],
        purpose: Purpose.FOR_SALE,
        roles: [],
    },
    {
        id: 7,
        name: 'Electronics & Home Appliances',
        externalID: '99',
        slug: 'electronics-home-appliances',
        level: 0,
        parentID: null,
        displayPriority: 500,
        children: [],
        purpose: Purpose.FOR_SALE,
        roles: [],
    },
    {
        id: 4,
        name: 'Vehicles',
        externalID: '5',
        slug: 'vehicles',
        level: 0,
        parentID: null,
        displayPriority: 200,
        children: [],
        purpose: Purpose.FOR_SALE,
        roles: [],
    },
    {
        id: 10,
        name: 'Furniture & Home Decor',
        externalID: '628',
        slug: 'furniture-home-decor',
        level: 0,
        parentID: null,
        displayPriority: 900,
        children: [],
        purpose: Purpose.FOR_SALE,
        roles: [],
    },
    {
        id: 5,
        name: 'Fashion & Beauty',
        externalID: '87',
        slug: 'fashion-beauty',
        level: 0,
        parentID: null,
        displayPriority: 1100,
        children: [],
        purpose: Purpose.FOR_SALE,
        roles: [],
    },
];

export default useMostPopularCategories;
