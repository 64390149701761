import type { CategoryFieldsState, CategoryFields } from './types';

export const COMMON_CATEGORY_FIELDS = 'common_category_fields';

const addCommonCategoryFields = (
    categoryFieldsState: CategoryFieldsState,
    categoryFields: CategoryFields,
): CategoryFields => {
    if (!categoryFieldsState[COMMON_CATEGORY_FIELDS]) {
        return categoryFields;
    }

    return {
        flatFields: [
            ...categoryFields.flatFields,
            ...categoryFieldsState[COMMON_CATEGORY_FIELDS].flatFields,
        ],
        childrenFields: [
            ...categoryFields.childrenFields,
            ...categoryFieldsState[COMMON_CATEGORY_FIELDS].childrenFields,
        ],
        parentFieldLookup: {
            ...categoryFields.parentFieldLookup,
            ...categoryFieldsState[COMMON_CATEGORY_FIELDS].parentFieldLookup,
        },
    };
};

export default addCommonCategoryFields;
