import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { useActiveUserExternalID } from 'strat/user/session';
import type { AppDispatch } from 'strat/state';

import { PackageTypeValues, ProductType } from 'horizontal/types';
import { usePayProductPackages } from 'horizontal/payment';
import { setAdOrderDetails } from 'horizontal/businessPackages/state';
import { trackViewExampleClick, trackSelectPackage, trackPayPackage } from 'horizontal/gtm';
import type { FullAd, ProductPackageOffer } from 'horizontal/types';

const useSinglePackages = (
    offers?: Array<ProductPackageOffer> | null,
    ad?: FullAd | null,
    defaultSelectedOffer?: ProductPackageOffer | null,
) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    const category = ad?.category[0];
    const {
        onPayProductPackages,
        loading: checkoutLoading,
        errorMessage,
    } = usePayProductPackages();
    const [price, setPrice] = React.useState<number | null | undefined>(null);
    const [selectedValue, setSelectedValue] = React.useState<string | null | undefined>(null);
    const [activeExample, setActiveExample] = React.useState<string | null | undefined>(null);
    const userId = useActiveUserExternalID();

    React.useEffect(() => {
        if (defaultSelectedOffer && !price && !selectedValue) {
            trackSelectPackage({ selectedPackage: defaultSelectedOffer });
            setPrice(defaultSelectedOffer.discountPrice || defaultSelectedOffer.price);
            setSelectedValue(defaultSelectedOffer.packageOfferID);
        }
    }, [defaultSelectedOffer, price, selectedValue]);

    const handleClick = React.useCallback(
        (choice: ProductPackageOffer) => {
            trackSelectPackage({ selectedPackage: choice });
            setPrice(choice.discountPrice || choice.price);
            setSelectedValue(choice.packageOfferID);
        },
        [setPrice, setSelectedValue],
    );

    const openExample = React.useCallback(
        (type: Values<typeof ProductType> | PackageTypeValues) => {
            trackViewExampleClick();
            setActiveExample(type);
        },
        [setActiveExample],
    );

    const closeExample = React.useCallback(() => setActiveExample(null), [setActiveExample]);

    const onCheckoutSinglePackages = React.useCallback(() => {
        if (!selectedValue || !ad?.id) {
            return;
        }
        dispatch(setAdOrderDetails(''));
        trackPayPackage({ productIDs: [selectedValue], price, ad, userExternalId: userId });
        onPayProductPackages([
            {
                external_id: selectedValue,
                ad_id: ad.id,
            },
        ]);
    }, [selectedValue, dispatch, onPayProductPackages, ad, price, userId]);

    return {
        i18n,
        category,
        price,
        selectedValue,
        handleClick,
        activeExample,
        openExample,
        closeExample,
        onCheckoutSinglePackages,
        loading: !offers,
        checkoutLoading,
        errorMessage,
    };
};

export default useSinglePackages;
