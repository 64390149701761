import {
    CategoryPriceField,
    DownPaymentField,
    InsuranceAmountField,
    PaymentOptionField,
    RentalPeriodField,
} from './commonPostingFields';

// these fields are shown first in the "Set a price" section, right below the price
export const FIELD_ATTRIBUTES_BEFORE_PRICE = Object.freeze([PaymentOptionField.attribute]);

// these fields are shown last in the "Set a price" section, right after the price type checkboxes
export const FIELD_ATTRIBUTES_AFTER_PRICE_TYPE = Object.freeze([
    RentalPeriodField.attribute,
    DownPaymentField.attribute,
    InsuranceAmountField.attribute,
]);

// these are some fields which represent price so we need to render a different type of input for them
export const PRICE_FIELDS = Object.freeze([
    CategoryPriceField.attribute,
    DownPaymentField.attribute,
    InsuranceAmountField.attribute,
]) as string[];
