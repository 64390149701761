import { CancelableRoute } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import { catchCanceled } from 'strat/util';
import { selectUserExternalID } from 'strat/user/session';

import { isAdEditor } from 'horizontal/util';
import { getStratAPI } from 'horizontal/api';
import Page from 'horizontal/pages/page';
import { AdState } from 'horizontal/types';
import type { FullAd } from 'horizontal/types';

import { fetchProductPurchases } from '../packages/state';
import { selectProductPurchases } from '../packages/selectors';

import ensureHasActiveUser from './ensureHasActiveUser';

export type SelectPackageSuccessRouteParams = {
    adExternalID: string;
    packageID: string;
};

class SelectPackageSuccessCommonRoute extends CancelableRoute {
    routeName: string;
    initialPattern: string;
    successPage: string;
    applyPackageErrorPage: string;
    packagesNotFoundPage: string;

    constructor(
        routeName: string,
        initialPattern: string,
        successPage: string,
        applyPackageErrorPage: string,
        packagesNotFoundPage: string,
    ) {
        super(routeName, [
            [
                `^${initialPattern}`,
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '/success/',
                {
                    name: 'packageID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
        this.routeName = routeName;
        this.initialPattern = initialPattern;
        this.successPage = successPage;
        this.applyPackageErrorPage = applyPackageErrorPage;
        this.packagesNotFoundPage = packagesNotFoundPage;
    }

    createURL(
        { adExternalID, packageID }: SelectPackageSuccessRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `${this.initialPattern}${adExternalID}/success/${packageID}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!this.hasAccess(context)) {
            return;
        }

        // render the page so we can display the loading spinner
        context.rendering.renderPage(this.successPage);

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { packageID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        const dataPromise = Promise.all([
            this.fetchAd(context),
            this.cancelable(dispatch(fetchProductPurchases({ bundleProductPurchases: true }))),
        ]);

        context.promise.wait(
            dataPromise
                .then(([ad]: [FullAd | null | undefined, unknown]) => {
                    if (!ad || !isAdEditor(ad, userExternalID)) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return;
                    }
                    if (
                        ![AdState.PENDING, AdState.ACTIVE].some((adState) => adState === ad.state)
                    ) {
                        context.rendering.renderPage(this.applyPackageErrorPage);
                        return;
                    }

                    const productPurchases = selectProductPurchases(getState());
                    const activeUserPackage = productPurchases.find(
                        (userPackage) => String(userPackage.id) === packageID,
                    );

                    if (!activeUserPackage) {
                        context.http.status(404);
                        context.rendering.renderPage(this.packagesNotFoundPage);
                        return;
                    }

                    context.rendering.renderPage(this.successPage, {
                        ad,
                        productPurchase: activeUserPackage,
                    });
                })
                .catch(catchCanceled),
        );
    }

    hasAccess(context: RoutingContextWithMiddlewares): boolean {
        if (!ensureHasActiveUser(context)) {
            return false;
        }
        return true;
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<FullAd | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { adExternalID },
            },
        } = context;

        const state = getState();
        const userExternalID = selectUserExternalID(state);
        if (!userExternalID) {
            return Promise.resolve(null);
        }

        return this.cancelable(getStratAPI(state).userAd(userExternalID, adExternalID)).then(
            (response) => (response.status < 300 ? response.data : null),
        );
    }
}

export default SelectPackageSuccessCommonRoute;
