import * as React from 'react';
import { useSearchFacetValues } from '@sector-labs/fe-search-redux/state';

const useCategoryFacetCount = (externalID: string, level: number): number | null | undefined => {
    const facets = useSearchFacetValues(`category.lvl${level}.externalID`);
    return React.useMemo(() => {
        return !CONFIG.runtime.DISABLE_SEARCH_FACETING
            ? facets.find((facet) => facet.key === externalID)?.count || 0
            : null;
    }, [externalID, facets]);
};

export default useCategoryFacetCount;
