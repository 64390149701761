import React from 'react';

import { PaymentOrder, PaymentStatus } from 'horizontal/payment';
import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';
import { useNavigateAfterPayment, useNavigateToSuccessPage } from 'horizontal/payment/hooks';

import useCallbackWithInterval from './useCallbackWithInterval';

type Props = {
    order: PaymentOrder;
    paymentID: string;
};

const useWaitForPaymentToComplete = ({ order, paymentID }: Props) => {
    const makeCancelable = useMakeCancelable();
    const navigateAfterPayment = useNavigateAfterPayment();
    const navigateToPaymentSuccess = useNavigateToSuccessPage();

    const fetchPaymentOrder = React.useCallback(
        (retries: React.MutableRefObject<number>, maxRetries: number) => {
            if (!paymentID || retries.current === maxRetries) {
                return;
            }
            retries.current += 1;
            makeCancelable(
                new StratAPI().getPaymentStatus(paymentID).then(({ status, data: payment }) => {
                    const { paymentStatus } = payment;
                    if (status !== 200) {
                        return;
                    }

                    if (paymentStatus == PaymentStatus.CAPTURED) {
                        navigateAfterPayment({
                            order: order,
                            status: PaymentStatus.CAPTURED,
                        });

                        return;
                    }

                    if (
                        paymentStatus &&
                        paymentStatus !== PaymentStatus.PENDING &&
                        paymentStatus !== PaymentStatus.PENDING_AT_PROVIDER
                    ) {
                        navigateToPaymentSuccess({
                            orderExternalID: order.externalID,
                            status: paymentStatus,
                        });
                        return;
                    }

                    if (retries.current >= maxRetries) {
                        navigateToPaymentSuccess({
                            orderExternalID: order.externalID,
                            status: PaymentStatus.PENDING,
                        });
                        return;
                    }
                }),
            );
        },
        [makeCancelable, paymentID, navigateToPaymentSuccess, navigateAfterPayment, order],
    );

    const { trigger } = useCallbackWithInterval({
        callback: fetchPaymentOrder,
        immediate: true,
        retriesInterval: 10,
    });

    React.useEffect(() => {
        trigger();
    }, [trigger]);
};

export default useWaitForPaymentToComplete;
