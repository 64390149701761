import type { RoutingContextWithMiddlewares } from 'strat/app';
import { selectUserRoles } from 'strat/user/session';
import { isProfolioUser, isStratCreditUser } from 'strat/user/roles';
import { EnhancedLocation } from 'react-true-router/location';

import NotFoundRoute from './notFoundRoute';

/**
 * We have 2 types of users which share same restrictions to some pages:
 * 1- Profolio users
 * 2- Credit system users
 */
const ensureActiveUserIsAllowedAccessAndRedirect = (
    context: RoutingContextWithMiddlewares,
    redirectURL: EnhancedLocation = NotFoundRoute.createURL({}),
): boolean => {
    const userRoles = selectUserRoles(context.redux.store.getState());

    if (!isProfolioUser(userRoles) && !isStratCreditUser(userRoles)) {
        return true;
    }

    context.http.redirect(redirectURL.pathname);
    return false;
};

export default ensureActiveUserIsAllowedAccessAndRedirect;
