import * as React from 'react';
import { Flex } from 'strat/components';

import { useChatSDK } from 'horizontal/chat/hooks';
import { useChatRouteParams } from 'horizontal/chat/state';

import Conversation from './conversation';
import NoSelectedConversation from './noSelectedConversation';
import styles from './styles/conversationGuard.cssm';

const ConversationGuard = () => {
    const routeConversation = useChatRouteParams();
    const { contactXID, subjectXID } = routeConversation;

    const sdk = useChatSDK();
    const isChatLoggedIn = sdk.useIsLoggedIn();

    sdk.useAutoSetActiveChatRoom({ adXID: subjectXID, contactXID });

    if (!isChatLoggedIn) {
        return (
            <Flex column className={styles.container}>
                <NoSelectedConversation />
            </Flex>
        );
    }

    return (
        <Flex column className={styles.container} aria-label="Current conversation">
            {contactXID && subjectXID ? <Conversation /> : <NoSelectedConversation />}
        </Flex>
    );
};

export default ConversationGuard;
