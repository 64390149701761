import FetcherFactory from 'strat/fetcher';

import { StratAPI } from 'horizontal/api';
import type { Category } from 'horizontal/types';

const factory = new FetcherFactory(
    'topSearches',
    (
        {
            locationID,
            category,
        }: {
            locationID: string;
            category: Category;
        },
        state: any,
    ) => {
        // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
        return new StratAPI(state.i18n.language).popularSearchesList(locationID, category.id);
    },
);

const fetchTopSearches = factory.creator();
const topSearchesReducer = factory.reducer();

export { fetchTopSearches };

export default topSearchesReducer;
