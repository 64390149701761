import * as React from 'react';

import { useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoomsQuery } from 'horizontal/chat/types';

import EmptyConversationList from './emptyConversationList';
import ListItem from './listItem';
import ScrollContainer from './scrollContainer';

type Props = {
    className?: string;
    query: ChatRoomsQuery;
    pageSize: number;
    renderListItem?: any;
};

const List = ({ pageSize, query, renderListItem }: Props) => {
    const sdk = useChatSDK();

    const { rooms, loadMore, isEndReached, isLoading } = sdk.useChatRooms({
        pageSize,
        loadOnMount: true,
        query,
    });

    if (rooms.length === 0 && isEndReached) {
        return <EmptyConversationList />;
    }

    return (
        <ScrollContainer loadMore={loadMore} isLoading={isLoading} isEndReached={isEndReached}>
            {rooms.map((room) => (
                <ListItem key={room.xid} conversation={room} renderListItem={renderListItem} />
            ))}
        </ScrollContainer>
    );
};

export default React.memo<Props>(List);
