import * as React from 'react';
import { useDispatch } from 'react-redux';
import { isCanceled } from 'strat/util';

import { fetchAreebaSession } from 'horizontal/payment/areeba';
import { useMakeCancelable } from 'horizontal/util';
import type { AppDispatch } from 'horizontal/state';

import { PaymentSource, PaymentStatus } from '../types';
import type { PaymentHookParams } from '../types';
import useNavigateToPaymentFailed from '../hooks/useNavigateToPaymentFailed';
import useTrackPaymentInitiated from '../hooks/useTrackPaymentInitiated';

import { SupportedCurrencies } from './types';
import useNavigateToAreebaPayment from './useNavigateToAreebaPayment';

const mapCurrencyToPaymentSource = (
    currency: Values<typeof SupportedCurrencies>,
): Values<typeof SupportedCurrencies> => {
    switch (currency) {
        case SupportedCurrencies.USD:
            return PaymentSource.AREEBA_CARD_USD;
        default:
            return PaymentSource.AREEBA;
    }
};

const useAreebaPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigateToAreebaPayment = useNavigateToAreebaPayment();
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();

    return React.useCallback(
        (currency: Values<typeof SupportedCurrencies>) => {
            if (!order || !order.externalID || !(currency in SupportedCurrencies)) {
                return;
            }
            setIsLoading(true);

            const cancelablePromise = makeCancelable(
                dispatch(fetchAreebaSession({ externalID: order.externalID, currency: currency })),
            );
            cancelablePromise
                .then(({ data, status }: { data: { sessionID?: number }; status: number }) => {
                    if (status !== 200) {
                        setIsLoading(false);
                        navigateToPaymentFailed(PaymentStatus.FAILURE);
                        return;
                    }

                    if (data?.sessionID) {
                        trackPaymentInitiated(mapCurrencyToPaymentSource(currency));

                        navigateToAreebaPayment({
                            orderExternalID: order.externalID,
                            sessionID: data.sessionID,
                            currency: currency,
                        });
                    }
                })
                // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
                .catch((error) => {
                    if (isCanceled(error)) {
                        return;
                    }
                    setIsLoading(false);
                });

            setPaymentPromise(cancelablePromise);
        },
        [
            order,
            setIsLoading,
            setPaymentPromise,
            dispatch,
            navigateToAreebaPayment,
            navigateToPaymentFailed,
            trackPaymentInitiated,
            makeCancelable,
        ],
    );
};

export default useAreebaPayment;
