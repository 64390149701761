import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import { PurposeValues } from 'strat/purpose';
import type { Geoloc } from 'strat/map/types';
import { LocationTypes } from 'strat/locations/locationTypes';
import type { Installment } from 'strat/installments';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';

/**
 * DLD confidence level - we don't show DLD information below this threshold
 * @type {number}
 */
const DLD_CONFIDENCE_LEVEL_THRESHOLD = 1;

/**
 * Available products a property can have.
 */
const PropertyProduct = Object.freeze({
    SIGNATURE: 'superhot',
    HOT: 'hot',
    PAID: 'paid',
    FEATURED: 'featured',
    PREMIUM: 'premium',
    DEVELOPER: 'developer',
    FREE: 'free',
    VIDEO: 'trusted',
    PROPERTY_OF_THE_WEEK: 'propertyOfTheWeek',
});

/**
 * Available product labels.
 *
 * The product label controls how a certain product is displayed.
 */
const PropertyProductLabel = Object.freeze({
    DEFAULT: 'default',
    DEVELOPER: 'developer',
});

export type PropertyFormattedExtraField = {
    readonly name: string;
    readonly attribute: string;
    readonly formattedValue: string | string[] | CategoryFieldGroupedChoices[];
};

/**
 * Types of ads we have.
 */
enum PropertyType {
    PROPERTY = 'property',
    PROJECT = 'project',
}

/**
 * States a property can be in.
 */
const PropertyState = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DELETED: 'deleted',
    ARCHIVED: 'archived',
});

/**
 * Possible ad verification statuses
 */
const PropertyVerificationStatus = Object.freeze({
    UNVERIFIED: 'unverified',
    IN_REVIEW: 'review',
    VERIFIED: 'verified',
    REJECTED: 'rejected',
});

const PropertyResidenceType = Object.freeze({
    FAMILY: 'family',
    SINGLES: 'singles',
});

const PropertyCompletionStatus = Object.freeze({
    USED: 'used',
    NEW: 'new',
    COMPLETED: 'completed',
    OFF_PLAN: 'under-construction',
    ANY: 'any',
});

const PropertyOccupancyStatus = Object.freeze({
    VACANT: 'vacant',
    OCCUPIED: 'occupied',
    ANY: 'any',
});

const PropertyfurnishingStatus = Object.freeze({
    FURNISHED: 'furnished',
    UNFURNISHED: 'unfurnished',
    ANY: 'any',
});

export const PropertyOwnership = Object.freeze({
    PRIMARY: 'primary',
    RESALE: 'resale',
});

export const PropertyAvailability = Object.freeze({
    SOLD: 'sold',
    RENTED: 'rented',
    AVAILABLE: 'available',
});

export const OffPlanPropertySaleType = Object.freeze({
    ANY: 'any',
    RESALE: 'resale',
    INITIAL: 'new',
});

export type VideoData = {
    id: number;
    url: string;
    title?: string;
    main?: boolean;
    host: string;
};

export const AgencyType = Object.freeze({
    REAL_ESTATE_AGENCY: 'agency',
    REAL_ESTATE_DEVELOPER: 'developer',
    AUTO_DEALER: 'auto_dealer',
    UNKNOWN: 'unknown',
    REAL_ESTATE_INDIVIDUAL_AGENCY: 'individual_agency',
});

export type AgencyVideoData = VideoData;

export type AgencyData = {
    name: string;
    nameTranslated?: string;
    externalID: string;
    logo: null | {
        id: number;
        url: string;
    };
    licenses: Array<{
        authority: string;
        number: string;
    }>;
    product: string;
    slug: string;
    video: AgencyVideoData;
    tr?: number; // stands for tier, but it's sensitive, thus the obscure name
    type?: Values<typeof AgencyType>;
};

export type CategoryNodeData = {
    id: number;
    slug: string;
    name: string;
    level: number;
    nameSingular: string;
    externalID: string;
    roles?: string[];
    parent?: CategoryNodeData;
};

export type LocationNodeData = {
    id: number;
    externalID: string;
    name: string;
    slug: string;
    level: number;
    hierarchy?: Array<LocationNodeData>;
    geography: Geoloc;
    type: Values<typeof LocationTypes>;
    isMain: boolean;
    hasBuilding: boolean;
    trackID: string;
    completionStatus?: string;
    hasChildren?: boolean;
};

export type LocationData = Array<LocationNodeData>;

export type LocalizedLocationNodeData = {
    en: LocationNodeData;
    [key: string]: LocationNodeData | null | undefined;
};

export type LocalizedCategoryNode = {
    en: CategoryNodeData;
    [key: string]: CategoryNodeData | null | undefined;
};

export type PropertyPhotoData = {
    id: number;
    url?: string;
    title?: string;
    alt?: string | null | undefined;
    main?: boolean;
    host?: string;
};

export type PropertyVideoData = VideoData;

export enum FloorPlanType {
    FLOOR_PLAN = 'floor_plan',
    MASTER_PLAN = 'master_plan',
}

export type PropertyFloorPlanImageData = {
    id: number;
    url: string;
    title: string;
    alt?: string | null | undefined;
    type: FloorPlanType;
};

export type FloorPlanImage = {
    image2D: {
        id: number;
    };
    image3D:
        | {
              id: number;
          }
        | null
        | undefined;
    label?: string;
    modelEmbedURL: string | null | undefined;
};

export type FloorPlanModel = {
    id: number;
    externalID: string;
    orderIndex: number;
    placeholderImage: {
        id: number;
    };
    label?: string | null | undefined;
};

export type FloorPlanPropertyData = {
    id: number;
    typeIdentifier: string;
    typeIdentifierValue: string;
    images: Array<FloorPlanImage>;
    models: Array<FloorPlanModel>;
};

export type PropertyAmenityData = {
    externalID: number;
    slug: string;
    icon: string;
    rank: number;
    format: string;
    text: string;
    value: string | null | undefined;
};

export type PropertyAmenityGroupData = {
    text: string;
    externalGroupID: number;
    groupRank: number;
    amenities: Array<PropertyAmenityData>;
};

export type PropertyPhoneNumberData = {
    phoneNumbers: Array<string>;
    mobileNumbers: Array<string>;
    proxyMobile?: string | null;
    proxyPhone?: string | null;
    whatsapp?: string | null;
};

export type PropertyLabelData = {
    categoryName: string;
    locationNames: Array<string>;
    locationHierarchy: string;
};

export type PropertyVerificationData = {
    status: string;
    eligible: boolean;
    comment: string;
    updatedAt: number;
    verifiedAt: number;
};

export type PropertyCommute = {
    [key: string]: {
        time: number;
        distance: number;
    };
};

export type PropertyDealData = {
    externalID: string;
    startTime: number;
    endTime: number;
};

export type ProjectUnit = {
    id: number;
    objectID: number;
    externalID: string;
    active: boolean;
    name: string;
    category: Array<CategoryNodeData>;
    areaMin?: number | null;
    areaMax?: number | null;
    priceMin?: number | null;
    priceMax?: number | null;
    rooms: number | null;
    baths: number;
    photos?: PropertyPhotoData[];
    order?: number | null;
};

enum RegaExtraFields {
    LOCATION_REGION = 'rega_location_region',
    LOCATION_CITY = 'rega_location_city',
    LOCATION_DISTRICT = 'rega_location_district',
    LOCATION_STREET_NAME = 'rega_location_street_name',
    LOCATION_POSTAL_CODE = 'rega_location_postal_code',
    LOCATION_BUILDING_NUMBER = 'rega_location_building_number',
    LOCATION_ADDITIONAL_NUMBER = 'rega_location_additional_number',
    LOCATION_LATITUDE = 'rega_location_latitude',
    LOCATION_LONGITUDE = 'rega_location_longitude',
    LICENSE_INFO_START_DATE = 'rega_license_info_start_date',
    LICENSE_INFO_END_DATE = 'rega_license_info_end_date',
}

export type PropertyExtraFields = {
    dldBuildingNK?: string;
    hasRentTransactions?: boolean;
    hasSaleTransactions?: boolean;
    ownership?: Values<typeof PropertyOwnership>;
    brokerage_and_marketing_license_number?: string;
    ad_license_expiry_date?: number;
    [RegaExtraFields.LOCATION_REGION]?: { [key: string]: string };
    [RegaExtraFields.LOCATION_CITY]?: { [key: string]: string };
    [RegaExtraFields.LOCATION_DISTRICT]?: { [key: string]: string };
    [RegaExtraFields.LOCATION_STREET_NAME]?: { [key: string]: string };
    [RegaExtraFields.LOCATION_POSTAL_CODE]?: string;
    [RegaExtraFields.LOCATION_BUILDING_NUMBER]?: string;
    [RegaExtraFields.LOCATION_ADDITIONAL_NUMBER]?: string;
    [RegaExtraFields.LOCATION_LATITUDE]?: string;
    [RegaExtraFields.LOCATION_LONGITUDE]?: string;
    [RegaExtraFields.LICENSE_INFO_START_DATE]?: string;
    [RegaExtraFields.LICENSE_INFO_END_DATE]?: string;
};

export type AdScoreDetails = {
    readonly cvrPdpSku: number;
    readonly ctrClpSku: number;
    readonly leadScoreSku: number;
    readonly viewScoreSku: number;
    readonly cplScoreSku: number;
    readonly topPosition: number;
    readonly hasProjectId: number;
};

export type OffPlanPropertyDetails = {
    saleType: Values<typeof OffPlanPropertySaleType>;
    originalPrice?: number;
    paidPrice?: number;
    dldWaiver?: number | null;
};

export type AdCompletionDetails = {
    startDate?: number | null;
    completionDate?: number | null;
    completionPercentage?: number | null;
};

export type AdPaymentPlanSummary = {
    preHandoverPercentageSum: number | null;
    postHandoverPercentageSum: number | null;
    breakdown: {
        downPaymentPercentage: number | null;
        preHandoverPercentage: number | null;
        handoverPercentage: number | null;
        postHandoverPercentage: number | null;
    };
};

export type CommonAdData = {
    id: number;
    objectID: number;
    type: PropertyType;
    agency: null | AgencyData;
    slug: string;
    link?: string;
    state: Values<typeof PropertyState>;
    active: boolean;
    purpose: Values<typeof PurposeValues>;
    externalID: string;
    sourceID: number;
    ownerID: number;
    title: string;
    titleTranslated?: string;
    location: LocationData;
    locations: LocationData;
    locationTranslations: Array<LocalizedLocationNodeData>;
    locationsTranslations: Array<LocalizedLocationNodeData>;
    project?: {
        id: number;
        externalID: string;
    };
    category: Array<CategoryNodeData>;
    categoryTranslations: Array<LocalizedCategoryNode>;
    description: null | string;
    descriptionTranslated?: boolean | null;
    mainDescription: null | string;
    price: number;
    labels: PropertyLabelData;
    rentFrequency: null | string;
    baths: number;
    rooms: number;
    area: number;
    plotArea?: number;
    product?: null | Values<typeof PropertyProduct>;
    productLabel: Values<typeof PropertyProductLabel>;
    contactName: null | string;
    permitNumber: null | string;
    permitUrl?: null | string;
    referenceNumber: string;
    coverPhoto: PropertyPhotoData;
    coverPhotoID: number | null | undefined;
    coverPhotoTitle: string;
    coverVideo: PropertyVideoData;
    photos: Array<PropertyPhotoData>;
    photoCount: number;
    videoCount: number;
    panoramaCount: number;
    videos?: Array<PropertyVideoData>;
    panoramas?: Array<PropertyVideoData>;
    floorPlans: Array<PropertyFloorPlanImageData>;
    floorPlan?: FloorPlanPropertyData;
    floorPlanID?: number;
    phoneNumber: null | PropertyPhoneNumberData;
    amenities: Array<PropertyAmenityGroupData>;
    geography: Geoloc;
    verification: PropertyVerificationData;
    completionStatus: Values<typeof PropertyCompletionStatus>;
    residenceType?: Values<typeof PropertyResidenceType>;
    requiresLogin?: boolean;
    furnishingStatus: Values<typeof PropertyfurnishingStatus>;
    photoIDs?: Array<number>;
    reactivatedAt?: number | null;
    pickedUnits?: Array<ProjectUnit>;
    extraFields?: PropertyExtraFields;
    formattedExtraFields?: PropertyFormattedExtraField[];
    deal?: PropertyDealData;
    projectNumber?: string;
    scoreDetails?: AdScoreDetails;
    _score?: number;
    ownerAgent?: {
        externalID: string;
        isTruBroker: boolean;
        name: string;
        name_l1: string;
        name_l2: string;
        user_image: string;
        user_image_id: number;
        state: string;
        isComplete: boolean;
    };
    userExternalID?: string;
    availabilityStatus?: Values<typeof PropertyAvailability>;
    downPayment?: number;

    // Fields for Off-Plan properties
    offplanDetails?: OffPlanPropertyDetails;
    completionDetails?: AdCompletionDetails;
    paymentPlanSummaries?: Array<AdPaymentPlanSummary>;
    unitCategories?: CategoryNodeData[][];

    // additional properties added by the transformer, but
    // not returned by the API, they just present data in
    // a more comfortable format
    primaryPhoneNumber?: null | string;
    mobilePhoneNumber?: null | string;
    isStudio?: boolean;
    isVerified?: boolean;
    propertyTour?: boolean;
    createdAt?: number;
    updatedAt?: number;
    shortDescription?: string;
    hasEmail?: boolean;
    requiresLoginForContact?: boolean;
    commute?: PropertyCommute;
    hidePrice?: boolean;
    installments?: Installment;
    locationPurposeTier?: number;

    isOfTitaniumAgency?: boolean;
    isOfTitaniumPlusAgency?: boolean;
    isOfFeaturedAgency?: boolean;

    hasExactGeography?: boolean;
    isDeveloper: boolean;
};

export type PropertyData = CommonAdData & {
    type: PropertyType.PROPERTY;
};

export type PhoneNumberType = {
    type: string;
    numbers: Array<string>;
};

const PropertyRentFrequency = Object.freeze({
    YEARLY: 'yearly',
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    DAILY: 'daily',
    ANY: 'any',

    text(i18n: I18n, rentFrequency: string) {
        switch (rentFrequency) {
            case this.YEARLY:
                return t(i18n)`Yearly`;
            case this.MONTHLY:
                return t(i18n)`Monthly`;
            case this.WEEKLY:
                return t(i18n)`Weekly`;
            case this.DAILY:
                return t(i18n)`Daily`;
            case this.ANY:
                return t(i18n)`Any`;
            default:
                return '';
        }
    },

    noun(i18n: I18n, rentFrequency: string) {
        switch (rentFrequency) {
            case this.YEARLY:
                return t(i18n)`Year`;
            case this.MONTHLY:
                return t(i18n)`Month`;
            case this.WEEKLY:
                return t(i18n)`Week`;
            case this.DAILY:
                return t(i18n)`Day`;
            case this.ANY:
                return t(i18n)`Any`;
            default:
                return '';
        }
    },

    choices() {
        return [this.YEARLY, this.MONTHLY, this.WEEKLY, this.DAILY];
    },
});

export {
    PropertyProduct,
    PropertyProductLabel,
    PropertyType,
    PropertyState,
    PropertyVerificationStatus,
    PropertyResidenceType,
    PropertyCompletionStatus,
    PropertyOccupancyStatus,
    PropertyfurnishingStatus,
    DLD_CONFIDENCE_LEVEL_THRESHOLD,
    PropertyRentFrequency,
    RegaExtraFields,
};
