import * as React from 'react';
import { Flex } from 'strat/components';

import styles from './styles/badge.cssm';

type Props = {
    readonly count: number;
};

const Badge = ({ count }: Props) => {
    if (!count) {
        return null;
    }

    return (
        <Flex alignCenter justifyCenter className={styles.badge} aria-label="Unseen messages badge">
            {count}
        </Flex>
    );
};

export default Badge;
