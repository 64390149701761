import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import { PaymentSource } from 'horizontal/payment/types';

type ProviderCashRoutePayload = {
    readonly referenceNumber: string;
    readonly cashPaymentSource: Values<typeof PaymentSource>;
};

type UseNavigateToProviderCashPayment = (
    arg1?: ProviderCashRoutePayload | null | undefined,
) => void;

const useNavigateToProviderCashPayment = (): UseNavigateToProviderCashPayment => {
    const router = useRouter();

    return React.useCallback(
        (params?: ProviderCashRoutePayload | null) => {
            router.pushRoute(RouteNames.PROVIDER_CASH_PAYMENT, params);
        },
        [router],
    );
};

export default useNavigateToProviderCashPayment;
