import * as React from 'react';
import { Image } from 'strat/util';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';

import type { CarouselBanner } from 'horizontal/types';

import styles from './styles/banner.cssm';

type Props = {
    readonly ovation: OvationCallbackProps;
    readonly banner: CarouselBanner;
};

const Banner = ({ ovation, banner }: Props) => {
    return (
        <div className={styles.bannerContainer}>
            <a
                href={banner.url}
                title={banner.title}
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => ovation.ingestBannerLead(banner.identifier)}
            >
                <div className={styles.imageContainer}>
                    <Image
                        className={styles.picture}
                        image={banner.image}
                        imgClassName={styles.image}
                    />
                </div>
            </a>
        </div>
    );
};

// @ts-expect-error - TS2345 - Argument of type '({ ovation, banner }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<OvationProps>'.
export default withOvation(Banner);
