import * as React from 'react';
import { Flex } from 'strat/components';

import { useChatRoomOptions } from 'horizontal/chat/hooks';
import MoreButton from 'horizontal/chat/moreButton';
import type { ChatRoom } from 'horizontal/chat/types';

import SafetyTips from './safetyTips';
import styles from './styles/conversationMoreButton.cssm';

type Props = {
    readonly conversation: ChatRoom;
};

const ConversationMoreButton = ({ conversation }: Props) => {
    const [safetyTipsVisible, setSafetyTipsVisible] = React.useState(false);

    const openSafetyTips = () => setSafetyTipsVisible(true);

    const options = useChatRoomOptions({
        chatRoom: conversation,
        onSafetyTips: openSafetyTips,
    });

    if (!options) {
        return null;
    }

    return (
        <Flex alignCenter className={styles.icon}>
            <MoreButton options={options} />
            <SafetyTips isVisible={safetyTipsVisible} setIsVisible={setSafetyTipsVisible} />
        </Flex>
    );
};

export default ConversationMoreButton;
