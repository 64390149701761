import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import type { MixedAd } from 'horizontal/types';
import adTransformer from 'horizontal/ad/adTransformer';

export type FetchAdParams = {
    externalID?: string | null | undefined;
};

/**
 * Fetcher factory for fetching ads.
 */
const factory = new FetcherFactory(
    'ad',
    (params: FetchAdParams, state: any) => {
        const apiParams = { external_id: params.externalID } as const;
        // @ts-expect-error - TS2345 - Argument of type '{ readonly external_id: string | null | undefined; }' is not assignable to parameter of type 'AdIdentificationParams'.
        return new StratAPI(state.i18n.language).ad(apiParams).then((ad) => ({
            data: adTransformer(ad.data, state.i18n.language, CONFIG.build.ENABLE_MERGED_INDEX),
            status: ad.status,
        }));
    },
    {
        successCodes: [200, 404],
        skipParamsCheck: true,
    },
);

/**
 * Action creator for fetching an ad from
 * the back-end.
 */
const fetchAd = factory.creator();

/**
 * Action creator for pre-loading an ad from
 * search results.
 */
const preloadAd = (ad: MixedAd) => ({
    type: factory.actions.preload,
    params: { externalID: ad.externalID },
    data: ad,
});

/**
 * Action creator for clearing ad data
 */

const clearAd = () => ({
    type: factory.actions.clear,
});

/**
 * Reducers for ad.
 */
const adReducer = factory.reducer();

export { fetchAd, preloadAd, clearAd };

export default adReducer;
