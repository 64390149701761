import { t } from '@lingui/macro';
import * as React from 'react';
import { useDialog } from 'strat/dialogs/dialogContext';
import useI18n from 'strat/i18n/language/useI18n';

import type { Ad, FullAd } from 'horizontal/types';
import { useNavigateToUpdateAd } from 'horizontal/adUpdating';
import { DialogNames } from 'horizontal/dialogs';
import { getMainCTAType, getMenuCTATypes, getSecondaryCTAType } from '@app/adManagement/getAdCTAs';
import useNavigateToPaymentSelectionFreeAd from 'horizontal/payment/hooks/useNavigateToPaymentSelectionFreeAd';
import { AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE } from 'horizontal/payment/types';
import { AdVirtualState } from 'horizontal/types';
import { useNavigateToAdUpgrade } from 'horizontal/packages/hooks';
import { isDefined } from 'horizontal/util';

import { useAutoActivateAd, useOpenMarkAsSoldDialog, useIsBTTEnabledForFeaturedAds } from './hooks';
import useRefreshMyAds from './useRefreshMyAds';
import { CTAType, UpdateAdAction } from './types';
import useRemoveAdByID from './useRemoveAdById';

export type CTAInfo = {
    readonly title: string;
    readonly url?: string;
    readonly onClick: () => void;
};

const useCTAs = (
    ad: Ad,
    ctaTypes: Array<Values<typeof CTAType> | null | undefined>,
): Array<CTAInfo> => {
    const i18n = useI18n();
    const navigateToUpdateAd = useNavigateToUpdateAd();
    const activateAd = useAutoActivateAd();
    const navigateToAdUpgrade = useNavigateToAdUpgrade(ad);

    const [, setRemoveAdVisible] = useDialog(DialogNames.REMOVE_AD_DIALOG);
    const [, setDisableAdVisible] = useDialog(DialogNames.DISABLE_AD_DIALOG);
    const [, setLearnMoreVisible] = useDialog(DialogNames.LEARN_MORE_DIALOG);

    const refreshMyAds = useRefreshMyAds();
    const removeAdByID = useRemoveAdByID();
    const openMarkAsSoldDialog = useOpenMarkAsSoldDialog(refreshMyAds);

    const navigateToPaymentSelectionFreeAd = useNavigateToPaymentSelectionFreeAd();
    return React.useMemo(
        () =>
            ctaTypes
                .map((ctaType) => {
                    switch (ctaType) {
                        case CTAType.EDIT:
                            return {
                                title: t(i18n)`Edit`,
                                onClick: () => {
                                    navigateToUpdateAd({
                                        externalID: ad.externalID,
                                        action: UpdateAdAction.EDIT,
                                    });
                                },
                            };
                        case CTAType.LEARN_MORE:
                            return {
                                title: t(i18n)`Learn more`,
                                url: `#${ad.slug}`,
                                onClick: () => {
                                    setLearnMoreVisible(true, {
                                        ad,
                                        onSuccess: () => {
                                            setLearnMoreVisible(false);
                                            if (
                                                ad.virtualState ===
                                                AdVirtualState.MODERATION_SOFT_REJECT
                                            ) {
                                                navigateToUpdateAd({
                                                    externalID: ad.externalID,
                                                    action: UpdateAdAction.EDIT,
                                                });
                                            } else {
                                                refreshMyAds();
                                            }
                                        },
                                        onCancel: () => setLearnMoreVisible(false),
                                    });
                                },
                            };
                        case CTAType.DEACTIVATE:
                            return {
                                title: t(i18n)`Deactivate`,
                                url: `#${ad.slug}`,
                                onClick: () => {
                                    setDisableAdVisible(true, {
                                        ad,
                                        onSuccess: () => {
                                            if (
                                                ad.extraFields &&
                                                ad.extraFields[
                                                    AD_UNDERTAKEN_CATEGORY_FIELD_ATTRIBUTE
                                                ]
                                            ) {
                                                setDisableAdVisible(false);
                                                navigateToPaymentSelectionFreeAd({
                                                    adExternalID: ad.externalID,
                                                });
                                            } else {
                                                refreshMyAds();
                                                setDisableAdVisible(false);
                                            }
                                        },
                                        onCancel: () => setDisableAdVisible(false),
                                    });
                                },
                            };
                        case CTAType.MARK_AS_SOLD:
                            return {
                                title: t(i18n)`Mark as sold`,
                                url: `#${ad.slug}`,
                                onClick: () => {
                                    openMarkAsSoldDialog(ad);
                                },
                            };
                        case CTAType.POST:
                            return {
                                title: t(i18n)`Post now`,
                                onClick: () => {
                                    activateAd(ad);
                                },
                            };
                        case CTAType.REMOVE:
                            return {
                                title: t(i18n)`Remove`,
                                url: `#${ad.slug}`,
                                onClick: () => {
                                    setRemoveAdVisible(true, {
                                        ad,
                                        onSuccess: () => {
                                            removeAdByID(ad.id);
                                            setRemoveAdVisible(false);
                                        },
                                        onCancel: () => setRemoveAdVisible(false),
                                    });
                                },
                            };
                        case CTAType.REPUBLISH:
                            return {
                                title: t(i18n)`Republish`,
                                onClick: () => {
                                    navigateToUpdateAd({
                                        externalID: ad.externalID,
                                        action: UpdateAdAction.REPUBLISH,
                                    });
                                },
                            };
                        case CTAType.UPGRADE:
                            return {
                                title: t(i18n)`Sell faster now`,
                                onClick: navigateToAdUpgrade,
                            };
                        default:
                            return null;
                    }
                })
                .filter(isDefined),
        [
            ctaTypes,
            i18n,
            ad,
            activateAd,
            navigateToUpdateAd,
            navigateToAdUpgrade,
            setRemoveAdVisible,
            refreshMyAds,
            openMarkAsSoldDialog,
            setDisableAdVisible,
            setLearnMoreVisible,
            removeAdByID,
            navigateToPaymentSelectionFreeAd,
        ],
    );
};

export type MainCTAInfoType = {
    cta: CTAInfo | null | undefined;
    ctaType: Values<typeof CTAType> | null | undefined;
};

export const useMainCTAInfo = (ad: Ad): CTAInfo | undefined => {
    const isEnabledBTTForFeaturedAd = useIsBTTEnabledForFeaturedAds(ad);

    const ctaTypes = React.useMemo(
        () => [getMainCTAType(ad, isEnabledBTTForFeaturedAd)],
        [ad, isEnabledBTTForFeaturedAd],
    );
    const [cta] = useCTAs(ad, ctaTypes);

    return cta;
};

export const useSecondaryCTAInfo = (ad: FullAd): CTAInfo | undefined => {
    const ctaTypes = React.useMemo(() => [getSecondaryCTAType(ad)], [ad]);
    const [cta] = useCTAs(ad, ctaTypes);

    return cta;
};

export const useMenuCTAInfo = (ad: Ad): Array<CTAInfo> => {
    const isBTTFeaturedEnabledForCategory = useIsBTTEnabledForFeaturedAds(ad);
    const ctaTypes = React.useMemo(
        () => getMenuCTATypes(ad, isBTTFeaturedEnabledForCategory),
        [ad, isBTTFeaturedEnabledForCategory],
    );

    return useCTAs(ad, ctaTypes);
};
