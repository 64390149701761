import * as React from 'react';
import { LoadingAnimation } from 'strat/components';

import styles from './styles/loadingFilters.cssm';

type Props = {
    readonly widthTitle: number;
    readonly widthContent: number;
};

const LoadingFilter = ({ widthTitle, widthContent }: Props) => (
    <div className={styles.container}>
        <LoadingAnimation height={'2.5rem'} width={`${widthTitle}%`} />
        <LoadingAnimation height={'1.6rem'} width={`${widthContent}%`} />
    </div>
);

const LoadingFilters = () => (
    <>
        <LoadingFilter widthTitle={90} widthContent={30} />
        <LoadingFilter widthTitle={25} widthContent={20} />
        <LoadingFilter widthTitle={15} widthContent={30} />
        <LoadingFilter widthTitle={70} widthContent={20} />
        <LoadingFilter widthTitle={60} widthContent={15} />
        <LoadingFilter widthTitle={25} widthContent={20} />
    </>
);

export default LoadingFilters;
