import * as React from 'react';
import classNames from 'classnames';

import Flex from 'strat/components/flex';

import styles from './styles/radioInput.cssm';

type Props = {
    index: number;
    value: string;
    onValueChanged: () => void;
    isChecked: boolean;
};

const RadioInput = (props: Props) => {
    const { value, index, onValueChanged, isChecked } = props;
    const cursorStyle = {
        [styles.defaultCursor]: isChecked,
        [styles.pointerCursor]: !isChecked,
    } as const;
    return (
        <Flex stretchWidth className={classNames(styles.container, cursorStyle)}>
            <input
                type={'radio'}
                // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
                id={index}
                value={index}
                onChange={onValueChanged}
                checked={isChecked}
            />
            <label
                className={classNames(isChecked ? styles.checked : styles.label, cursorStyle)}
                // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
                htmlFor={index}
            >
                {value}
            </label>
        </Flex>
    );
};

export default RadioInput;
