import * as React from 'react';

import { DialogComponentProps } from '../types';

import { useDialog } from './dialogContext';

const registerDialog =
    <T = any>(name: string, component: React.ComponentType<DialogComponentProps<T>>) =>
    (props: T) => {
        const [visible, setVisible] = useDialog(name);

        return React.createElement(component, {
            visible,
            onVisibilityChanged: setVisible,
            ...props,
        } as DialogComponentProps<T>);
    };

export default registerDialog;
