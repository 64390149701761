import * as React from 'react';

import type { CategoryFieldNoChoices } from 'horizontal/types';

import { CategoryPriceField } from './commonPostingFields';

// We need to extract the price and make/model fields from the categoryFields, if it's there,
// so we can show it in a different section.
const useFieldIndex = <T extends CategoryFieldNoChoices>(
    fieldAttribute: string,
    categoryFields: Array<T>,
): number =>
    React.useMemo(
        () => categoryFields.findIndex((field) => field.attribute === fieldAttribute),
        [categoryFields, fieldAttribute],
    );

export const usePriceFieldIndex = <T extends CategoryFieldNoChoices>(
    categoryFields: Array<T>,
): number => useFieldIndex(CategoryPriceField.attribute, categoryFields);

export default useFieldIndex;
