import StratAPI from './strat';

class FavoritesAPI extends StratAPI {
    /**
     * Retrieves the list of favorites from strat DB
     * @param userID
     */
    favorites(
        userID: string | number,
        getInactives: boolean | null | undefined = false,
    ): Promise<{ status: number; data: { adExternalID: string }[] | null | undefined }> {
        const params = getInactives ? { getInactives: true } : null;
        return super.request(`/api/user/${userID}/favorites/`, params);
    }

    /**
     * Toggles a favorites ad on and off the favorites list
     */
    toggleFavorite(userID: string, adExternalID: string) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return super.post(`/api/user/${userID}/favorites/${adExternalID}/toggle/`);
    }

    /**
     * Adds a list of favorites to strat DB.
     */
    addFavorites(userID: string, ads: Array<string>) {
        return super.post(`/api/user/${userID}/favorites/add/`, { ads });
    }
}

export default FavoritesAPI;
