import type { Category } from 'horizontal/types';

// @ts-expect-error - TS7023 - 'findCategoryNodeByID' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
export const findCategoryNodeByID = (category: Category, nodeID: number) => {
    if (category.id === nodeID) {
        return category;
    } else if (category.children) {
        let result = null;
        for (let i = 0; result === null && i < category.children.length; i++) {
            result = findCategoryNodeByID(category.children[i], nodeID);
        }
        return result;
    }
    return null;
};
