import { isCanceled, makeCancelable } from 'strat/util';
import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';
import { PaymentStatus, PaymentOrderStatus } from 'horizontal/payment';
import { setPaymentOrderExternalID } from 'horizontal/businessPackages/state';
import { fetchWalletBalance } from 'horizontal/payment/wallet/state';
import { getStratAPI } from 'horizontal/api';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type PaymentSelectionRouteParams = {
    readonly status?: Values<typeof PaymentStatus>;
    readonly orderExternalID: string;
};

class PaymentSelectionRoute extends Route {
    constructor() {
        super(RouteNames.PAYMENT_SELECTION, [
            [
                '^/payment-selection/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        params: PaymentSelectionRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams: Record<string, any> = {};
        if (params?.status) {
            queryParams.status = params.status;
        }
        return { pathname: `/payment-selection/${params?.orderExternalID}`, search: queryParams };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.NOT_FOUND);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const {
            match: {
                params: { orderExternalID, status: paymentStatus },
            },
            redux: {
                store: { getState, dispatch },
            },
        } = context;

        if (!orderExternalID) {
            this.renderNotFoundPage(context);
            return;
        }

        if (paymentStatus === PaymentStatus.AD_REJECTED) {
            context.rendering.renderPage(Page.APPLY_AD_SINGLE_PACKAGE_ERROR);
            return;
        }

        if (paymentStatus === PaymentStatus.MISSING_INFO) {
            context.rendering.renderPage(Page.MISSING_INFO_ERROR);
            return;
        }

        context.rendering.renderPage(Page.PAYMENT_SELECTION, {
            order: null,
        });

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionRoute'.
        this.cancelablePromise = makeCancelable(
            Promise.all([
                getStratAPI(getState()).getOrder(orderExternalID),
                dispatch(fetchWalletBalance()),
            ]),
        );
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionRoute'.
        const promise = this.cancelablePromise.then(
            ([{ data: order, status }]: [any]) => {
                if (status !== 200 || order.status === PaymentOrderStatus.ACTIVE) {
                    this.renderNotFoundPage(context);
                    return;
                }

                dispatch(setPaymentOrderExternalID(orderExternalID));

                context.rendering.renderPage(Page.PAYMENT_SELECTION, {
                    order,
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentSelectionRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new PaymentSelectionRoute();
