import * as React from 'react';
import { useSelector } from 'react-redux';
import type { I18n } from '@lingui/core';
import brandingSettings from '@app/branding/settings';

const useRecentLocations = (maximumAllowedLocations: number, i18n: I18n) => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const searchHistory = useSelector((state) => state.user.searchHistory);
    const topLevelLocation = brandingSettings.getTopLevelLocation(i18n);
    const searchHistoryLocations = React.useMemo(
        () =>
            searchHistory
                ?.map((searchHistoryEntry: any) => {
                    return searchHistoryEntry['location.externalID']?.value;
                })
                .filter((item: any) => item)
                .filter((location: any) => location.id !== topLevelLocation.id)
                .filter(
                    (element: any, index: any, self: any) =>
                        // @ts-expect-error - TS7006 - Parameter 't' implicitly has an 'any' type.
                        index === self.findIndex((t) => t.id === element.id),
                ),
        [searchHistory, topLevelLocation],
    );

    if (searchHistoryLocations.length > maximumAllowedLocations) {
        searchHistoryLocations.length = maximumAllowedLocations;
    }

    return searchHistoryLocations;
};

export default useRecentLocations;
