//@ts-nocheck
import featuredAgenciesReducer from 'strat/featuredAgencies/state';
import recentBlogPostsReducer from 'strat/home/recentBlogs/state';
import { agentReducer } from 'strat/agency/agent/state/agent';
import agencyReducer from 'strat/agency/state/agency';
import internalLinksReducer from 'strat/search/internalLinks/state';
import { listingEvaluationReducer } from 'strat/truValue';
import { browsePropertiesLinksReducer } from 'strat/home/browseProperties';
import { adSearchConfigurationReducer } from 'strat/search/adSearchConfiguration';
import isochroneReducer from 'strat/search/state/isochrone';

import defaultReducers from './defaultReducers';

export default {
    ...defaultReducers,
    featuredAgencies: featuredAgenciesReducer,
    recentBlogPosts: recentBlogPostsReducer,
    internalLinks: internalLinksReducer,
    browsePropertiesLinks: browsePropertiesLinksReducer,
    agency: agencyReducer,
    agent: agentReducer,
    listingEvaluation: listingEvaluationReducer,
    adSearchConfiguration: adSearchConfigurationReducer,
    isochrone: isochroneReducer,
};
