import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { stopSyntheticEventPropagation } from 'strat/util';

import CheckMarkIcon from 'horizontal/assets/icons/iconCheckMarkBlue_noinline.svg';
import DefaultProfilePicture from '@app/profile/defaultProfilePicture';

import styles from './styles/buyersList.cssm';
import { BuyerInfo, BuyerOptionsProps } from './types';

type BuyerOptionProps = {
    user: BuyerInfo;
    onClick: (externalID: string) => void;
    selected: boolean;
};

const BuyerOption = ({ user, onClick, selected }: BuyerOptionProps) => {
    const onSelected = React.useCallback(
        (event) => {
            stopSyntheticEventPropagation(event);
            onClick(user.externalID);
        },
        [user, onClick],
    );

    return (
        <Flex alignCenter className={styles.element} aria-label="Buyer option" onClick={onSelected}>
            <Flex column>
                {selected ? (
                    <img alt="Avatar" src={CheckMarkIcon} className={styles.avatar} />
                ) : (
                    <DefaultProfilePicture
                        userDisplayName={user.name}
                        className={styles.avatar}
                        label="Avatar"
                    />
                )}
            </Flex>
            <Flex column alignCenter>
                <Text.Base className={styles.buyerName} bold>
                    {user.name}
                </Text.Base>
            </Flex>
        </Flex>
    );
};

const BuyerOptions = ({ users, onBuyerSelected, selectedBuyerExternalID }: BuyerOptionsProps) => (
    <>
        {users.map((user) => (
            <BuyerOption
                key={user.externalID}
                user={user}
                onClick={onBuyerSelected}
                selected={selectedBuyerExternalID === user.externalID}
            />
        ))}
    </>
);

export default BuyerOptions;
