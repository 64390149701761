import { createSelector } from 'reselect';
import { SearchStateConstants } from '@sector-labs/fe-search-redux/state';

import FetcherFactory from 'strat/fetcher';
import { RecommenderBackEndAPI } from 'strat/api';
import { selectLanguage } from 'strat/i18n/language';
import { prioritizeCategoryPurposeHits } from 'strat/recommendations/utils';
import { determineAdsIndexName } from 'strat/search/indexNames';

import propertyTransformer from '../propertyTransformer';
import { selectPropertyInteractionParams } from '../selectors';

import fetchFromAlgolia from './fetchFromAlgolia';

const selectAlgoliaParams = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state[SearchStateConstants.REDUCER_NAME],
    // @ts-expect-error - TS7006 - Parameter 'algoliaReducer' implicitly has an 'any' type.
    (algoliaReducer) => ({
        appId: algoliaReducer.appId,
        apiKey: algoliaReducer.apiKey,
        indexName: algoliaReducer.indexName,
    }),
);

/**
 * Factory for fetching recommended properties.
 */
const recommenderFactory = new FetcherFactory(
    'recommendedProperties',
    (params: any) =>
        new RecommenderBackEndAPI()
            .trackVisitAndFetchRecommendations(params.visitInformation)
            .then(({ data, status }) => {
                const sortedIndex = params.sortBy
                    ? determineAdsIndexName({ sortBy: params.sortBy, language: params.language })
                    : null;

                return fetchFromAlgolia(
                    { slicedData: data, status },
                    params.appId,
                    params.apiKey,
                    sortedIndex || params.indexName,
                    params.count,
                    'objectID',
                );
            })
            .then(({ data: { hits }, status }) => {
                const propertyType =
                    params.visitInformation.category[params.visitInformation.category.length - 1]
                        .slug;
                const selectedHits = prioritizeCategoryPurposeHits(
                    // @ts-expect-error - TS2345 - Argument of type 'never[] | readonly SearchResponseHit[]' is not assignable to parameter of type 'CommonAdData[]'.
                    hits,
                    propertyType,
                    params.visitInformation.purpose,
                    params.count,
                );
                const processedHits = selectedHits.map((hit) =>
                    propertyTransformer(hit, params.language, CONFIG.build.ENABLE_MERGED_INDEX),
                );

                return { data: { hits: processedHits }, status };
            }),
    {
        successCodes: [200],
        // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
        getParams: (params, getState) => {
            const state = getState();
            return {
                language: selectLanguage(state),
                visitInformation: selectPropertyInteractionParams(state),
                count: params.count ?? 3,
                sortBy: params.sortBy,
                ...selectAlgoliaParams(state),
            };
        },
    },
);

/**
 * Fetches recommended properties.
 */
const trackAndFetchRecommendations = recommenderFactory.creator();

/**
 * Reducer for recommended properties.
 */
const recommenderPropertiesReducer = recommenderFactory.reducer();
export { trackAndFetchRecommendations };
export default recommenderPropertiesReducer;
