import * as React from 'react';

/* Preserves the html that was rendered on the server side
 */

// See: https://github.com/facebook/react/issues/12617
type Props = {
    children: React.ReactNode;
};

const useStaticContent = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [shouldRender, setShouldRender] = React.useState(typeof window === 'undefined');

    React.useEffect(() => {
        // check if the innerHTML is empty as client side navigation
        // needs to render the component without server-side backup
        const isEmpty = ref.current?.innerHTML === '';
        if (isEmpty) {
            setShouldRender(true);
        }
    }, []);

    return { shouldRender, ref };
};

const PreserveServerSideRendering = ({ children }: Props) => {
    const { shouldRender, ref } = useStaticContent();

    // If we're in the server or a client navigation, just render the component
    if (shouldRender) {
        return <div>{children}</div>;
    }

    // Avoid re-render on the client
    return <div ref={ref} dangerouslySetInnerHTML={{ __html: '' }} suppressHydrationWarning />;
};

export default PreserveServerSideRendering;
