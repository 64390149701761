import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type MobilePaymentOTPRouteParams = {
    readonly sessionID: string;
    readonly phoneNumber: string;
    readonly orderExternalID: string;
};

class PaymentTPayOTPRoute extends Route {
    constructor() {
        super(RouteNames.PAYMENT_TPAY_OTP, [
            [
                '^/tpay/otp/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { sessionID, phoneNumber, orderExternalID }: MobilePaymentOTPRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/tpay/otp/${orderExternalID}?sessionID=${sessionID}&phoneNumber=${phoneNumber}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { sessionID, phoneNumber, orderExternalID },
            },
        } = context;

        context.rendering.renderPage(Page.PAYMENT_TPAY_OTP, {
            sessionID,
            phoneNumber,
            orderExternalID,
        });
    }
}

export default new PaymentTPayOTPRoute();
