import * as React from 'react';
import { logError } from 'strat/error/log';
import noop from 'strat/empty/noop';

import { PackageTypeValues, ProductType } from 'horizontal/types';
import { hasFeaturedExample, hasRefreshExample } from 'horizontal/packages/utils';

import FeatureAdExample from './featuredAdExample';
import BoostToTopAdExample from './boostToTopAdExample';

type Props = {
    readonly activeExample: null | undefined | PackageTypeValues | Values<typeof ProductType>;
    readonly closeExample: () => void;
};

const PackagesExamples = ({ activeExample, closeExample }: Props) => {
    if (!activeExample) {
        return null;
    }

    if (hasFeaturedExample(activeExample)) {
        return <FeatureAdExample onClick={closeExample} />;
    } else if (hasRefreshExample(activeExample)) {
        return <BoostToTopAdExample onClick={closeExample} />;
    } else {
        logError({
            msg: 'unknown example passed to PackagesExamples',
            context: { activeExample: activeExample },
        }).then(noop);
        return null;
    }
};

export default PackagesExamples;
