import type { Dispatch } from 'redux';

import { fetchLocationsAttributes } from 'strat/search/algolia';
import FetcherFactory from 'strat/fetcher';
import { InternalAPI } from 'strat/api';
import type { FetchState } from 'strat/fetcher';

/**
 * Fetcher factory for fetching agent reviews for a user. We also need to fetch the locations
 */
const factory = new FetcherFactory('managedAgentReviews', ({ userEmail, language }) =>
    new InternalAPI().agentReviewsByReviewerEmail(userEmail).then(({ status, data }) => {
        const results = data.results;
        // @ts-expect-error - TS7006 - Parameter 'acc' implicitly has an 'any' type. | TS7006 - Parameter 'elem' implicitly has an 'any' type.
        const locationIDs = results.reduce((acc, elem) => {
            acc.add(elem.locationExternalID);
            return acc;
        }, new Set());

        if (locationIDs.size === 0) {
            return { status, data };
        }

        return fetchLocationsAttributes(
            locationIDs,
            ['name', 'externalID', 'objectID', 'slug'],
            language,
        ).then((externalIDsToLocation) => {
            results.forEach(
                // @ts-expect-error - TS7006 - Parameter 'review' implicitly has an 'any' type.
                (review) => {
                    review.location = externalIDsToLocation.get(review.locationExternalID);
                },
            );
            return { status, data: { count: results.length, results } };
        });
    }),
);

const fetchAgentReviewByUserEmail = factory.creator();

const managedAgentReviewsReducer = factory.reducer();

type State = FetchState & {
    editMode: boolean;
};

const defaultState: State = {
    editMode: false,
    loading: false,
    loaded: false,
    enabled: true,
    params: null,
    data: null,
    error: null,
    preloaded: false,
};

const Actions = Object.freeze({
    REMOVE_REVIEW: 'REVIEWS_MANAGER/REMOVE_REVIEW',
    SET_EDIT_MODE: 'REVIEWS_MANAGER/SET_EDIT',
});

type RemoveReview = {
    type: 'REVIEWS_MANAGER/REMOVE_REVIEW';
    externalID: string;
};

type SetEditMore = {
    type: 'REVIEWS_MANAGER/SET_EDIT';
    editMode: boolean;
};

type Action = RemoveReview | SetEditMore;

const reviewsReducer = (state: State | null | undefined = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.REMOVE_REVIEW:
            return {
                ...state,
                data: {
                    // @ts-expect-error - TS2531 - Object is possibly 'null'.
                    ...state.data,
                    // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2531 - Object is possibly 'null'.
                    count: state.data?.count ? state.data.count - 1 : 0,
                    // @ts-expect-error - TS2531 - Object is possibly 'null'.
                    results: state.data?.results
                        ? // @ts-expect-error - TS2531 - Object is possibly 'null'.
                          state.data.results.filter(
                              // @ts-expect-error - TS7006 - Parameter 'review' implicitly has an 'any' type. | TS2339 - Property 'externalID' does not exist on type 'Action'.
                              (review) => review.externalID !== action.externalID,
                          )
                        : [],
                },
            };
        case Actions.SET_EDIT_MODE:
            return {
                ...state,
                // @ts-expect-error - TS2339 - Property 'editMode' does not exist on type 'Action'.
                editMode: action.editMode,
            };
        default:
            return managedAgentReviewsReducer(state, action);
    }
};

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const removeReview = (externalID: string) => (dispatch: Dispatch) =>
    dispatch({ type: Actions.REMOVE_REVIEW, externalID });

// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const setEditMode = (editMode: boolean) => (dispatch: Dispatch) =>
    dispatch({ type: Actions.SET_EDIT_MODE, editMode });

export { fetchAgentReviewByUserEmail, removeReview, setEditMode };

export default reviewsReducer;
