import EMPTY_ARRAY from 'strat/empty/array';

import type { CartPackage } from 'horizontal/packages/types';
import { PaymentProductPackageOffer } from 'horizontal/payment';

import Actions from './actions';
import type { BusinessPackageCartActions } from './actions';

export type BusinessPackageCartState = {
    businessPackageCartOffers: PaymentProductPackageOffer[] | CartPackage[];
    orderExternalID?: string | null;
    adExternalID?: string | null;
};

const defaultState: BusinessPackageCartState = {
    businessPackageCartOffers: [],
    orderExternalID: null,
    adExternalID: null,
};

const EMPTY_OFFER = {
    packageOfferID: '',
    productPackage: {
        packageID: '',
        productItems: [],
        name: '',
        description: '',
        valueProposition: '',
        validFor: 0,
    },
    price: 0,
    discountPrice: 0,
    grossPrice: 0,
    categoryID: null,
    categoryLabel: null,
    locationID: null,
    locationLabel: null,
    updatedAt: '',
    createdAt: '',
    cartQuantity: 0,
} as const;

export default (
    state: BusinessPackageCartState | null | undefined = defaultState,
    action: BusinessPackageCartActions,
): BusinessPackageCartState => {
    const cartOffer = (state?.businessPackageCartOffers as any[])?.find(
        (hit: PaymentProductPackageOffer | CartPackage) =>
            // @ts-expect-error - TS2339 - Property 'packageOfferID' does not exist on type 'BusinessPackageCartActions'.
            hit.packageOfferID === action?.packageOfferID,
    );
    const quantity = cartOffer?.cartQuantity || 1;
    let updatedEntry = cartOffer || EMPTY_OFFER;

    switch (action.type) {
        case Actions.ADD_BUSINESS_PACKAGE_OFFER:
            return {
                ...state,
                businessPackageCartOffers: [
                    action.businessPackageOffer,
                    ...(state?.businessPackageCartOffers || EMPTY_ARRAY),
                ],
            };
        case Actions.REMOVE_BUSINESS_PACKAGE_OFFER:
            return {
                ...state,
                businessPackageCartOffers: (state?.businessPackageCartOffers as any[])?.filter(
                    (hit: PaymentProductPackageOffer | CartPackage) =>
                        hit.packageOfferID !== action.packageOfferID,
                ),
            };
        case Actions.SET_BUSINESS_PACKAGE_CART:
            return {
                ...state,
                businessPackageCartOffers: action.businessPackageOffers,
            };
        case Actions.CLEAR_BUSINESS_PACKAGE_CART:
            return defaultState;
        case Actions.ADD_TO_CART_COUNTER:
            updatedEntry = { ...updatedEntry, cartQuantity: quantity + 1 };
            return {
                ...state,
                businessPackageCartOffers: [
                    ...(state?.businessPackageCartOffers as any[]).filter(
                        (hit: PaymentProductPackageOffer | CartPackage) =>
                            hit.packageOfferID !== action.packageOfferID,
                    ),
                    updatedEntry,
                ],
            };
        case Actions.SUBTRACT_FROM_CART_COUNTER:
            updatedEntry = { ...updatedEntry, cartQuantity: quantity - 1 };
            return {
                ...state,
                businessPackageCartOffers: [
                    ...(state?.businessPackageCartOffers as any[]).filter(
                        (hit: PaymentProductPackageOffer | CartPackage) =>
                            hit.packageOfferID !== action.packageOfferID,
                    ),
                    updatedEntry,
                ],
            };
        case Actions.SET_PAYMENT_ORDER_EXTERNAL_ID:
            // @ts-expect-error - TS2322 - Type '{ orderExternalID: any; businessPackageCartOffers?: CartPackage[] | undefined; }' is not assignable to type 'State'.
            return {
                ...state,
                orderExternalID: action.orderExternalID,
            };
        case Actions.SET_AD_ORDER_DETAILS:
            // @ts-expect-error - TS2322 - Type '{ adExternalID: string; businessPackageCartOffers?: PaymentProductPackageOffer[] | CartPackage[] | undefined; orderExternalID?: string | ... 1 more ... | undefined; }' is not assignable to type 'BusinessPackageCartState'.
            return {
                ...state,
                adExternalID: action.adExternalID,
            };
        default:
            // @ts-expect-error - TS2322 - Type 'State | null' is not assignable to type 'State'.
            return state;
    }
};
