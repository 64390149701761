import React from 'react';
import { Dialog, Flex, Text, Button } from 'strat/components';
import { Trans } from '@lingui/macro';

import styles from './styles/autofillDialog.cssm';

const AutofillDialog = () => {
    const [visible, setVisible] = React.useState(true);
    const onCancel = () => setVisible(false);

    return (
        <Dialog visible={visible} onVisibilityChanged={onCancel}>
            <Flex column alignCenter className={styles.content}>
                <Text.XLarge bold>Good news!</Text.XLarge>
                <div className={styles.text}>
                    <Text.Regular>
                        <Trans>
                            The car was found in our database. Some details are pre-filled.{' '}
                            <b>Please review them carefully.</b>
                        </Trans>
                    </Text.Regular>
                </div>
                <div className={styles.button}>
                    <Button small onClick={onCancel}>
                        <Trans>Ok, got it</Trans>
                    </Button>
                </div>
            </Flex>
        </Dialog>
    );
};

export default AutofillDialog;
