import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import iconNotifications from 'horizontal/assets/icons/iconNotifications_noinline.svg';

import styles from './styles/notificationsIcon.cssm';

type Props = {
    readonly onClick?: () => void;
    readonly newNotifications: boolean;
};

const NotificationsIcon = ({ onClick, newNotifications }: Props) => (
    <Flex
        className={classNames(styles.container, {
            [styles.newNotifications]: newNotifications,
        })}
        onClick={onClick}
    >
        <img src={iconNotifications} alt={'Notifications icon'} />
    </Flex>
);

export default NotificationsIcon;
