import { fetchFrequentSearchQueries } from 'horizontal/search/frequentSearchQueries/state';

import { MatchParams } from './types';

const fetchAndStoreFrequentSearchQueries = (
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch,
    { categorySlug, categoryExternalID }: MatchParams,
    language: string,
): Promise<void> => {
    return dispatch(
        fetchFrequentSearchQueries({
            language,
            categorySlug,
            categoryExternalID,
        }),
    );
};

export default fetchAndStoreFrequentSearchQueries;
