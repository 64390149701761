import * as React from 'react';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import { EditProfile, EditProfileForm, DeleteProfile, DeleteProfileForm } from 'horizontal/user';
import Footer from 'horizontal/footer';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

import styles from './styles/userSettingsPageDesktop.cssm';
import UserSettingsHead from './userSettingsHead';

const EditProfilePageDesktop = {
    head: () => (
        <>
            <UserSettingsHead />
            <GTMDataLayer pageType={PageType.PROFILE} />
        </>
    ),
    body: () => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <Flex alignCenter fillContainer stretchWidth column className={styles.container}>
                <EditProfile renderForm={(props) => <EditProfileForm {...props} />} />
            </Flex>
            <Flex alignCenter fillContainer stretchWidth column className={styles.container}>
                <DeleteProfile renderForm={DeleteProfileForm} />
            </Flex>
            <Footer />
        </Flex>
    ),
} as const;

export default EditProfilePageDesktop;
