import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const computeBedsText = (bedsCount: number | string | undefined, i18n: I18n) => {
    if (typeof bedsCount === 'string') {
        return t(i18n)`${bedsCount} Bedroom`;
    }

    return t(i18n)`${plural(bedsCount, {
        '=0': 'Studio',
        one: '# Bedroom',
        two: '# Bedroom',
        few: '# Bedroom',
        many: '# Bedroom',
        other: '# Bedroom',
    })}`;
};

export default computeBedsText;
