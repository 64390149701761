import * as React from 'react';
import isNil from 'lodash/isNil';
import { FlatCategoryField } from 'strat/types';

import Slider from './slider';
import useRefineFilter from './useRefineFilter';
import styles from './styles/rangeSlider.cssm';

type Props = {
    field: FlatCategoryField;
    min: number;
    max: number;
    defaultMin: number | null | undefined;
    defaultMax: number | null | undefined;
    onChange: (arg1: any) => void;
    shouldRefineOnUpdate: React.MutableRefObject<boolean>;
    className?: string;
};

const RangeSlider = ({
    field,
    min,
    max,
    defaultMin,
    defaultMax,
    onChange,
    className = styles.slider,
}: Props) => {
    const shouldRefineOnUpdate = React.useRef(false);
    const refine = useRefineFilter(field.attribute);

    const values = React.useMemo(() => {
        const guardedMin = Math.min(min, max);
        const guardedMax = Math.max(min, max);
        return [guardedMin, guardedMax];
    }, [min, max]);

    const onSliderDragEnd = React.useCallback(() => {
        refine({ min, max });
    }, [min, max, refine]);

    const onClick = React.useCallback(() => {
        shouldRefineOnUpdate.current = true;
    }, [shouldRefineOnUpdate]);

    if (isNil(defaultMin) && isNil(defaultMax)) {
        return null;
    }
    return (
        <div className={className}>
            <Slider
                min={defaultMin as number}
                max={defaultMax as number}
                values={values}
                onValuesUpdated={onChange}
                onSliderDragEnd={onSliderDragEnd}
                onClick={onClick}
            />
        </div>
    );
};

export default RangeSlider;
