import * as React from 'react';

import { withChatState, WithChatStateProps } from 'horizontal/chat/hoc';
import { useChatSDK } from 'horizontal/chat/hooks';

import UnreadChatsBadge from './unreadChatsBadge';

type Props = WithChatStateProps;

const UnreadChatsBadgeWrapper = () => {
    const sdk = useChatSDK();
    const isChatLoggedIn = sdk.useIsLoggedIn();

    if (isChatLoggedIn) {
        return <UnreadChatsBadge />;
    }
    return null;
};

export default withChatState<Props>(UnreadChatsBadgeWrapper);
