import * as React from 'react';
import { useSearchFilterOfType } from '@sector-labs/fe-search-redux/state';
import {
    ExactFilter,
    RefinementFilter,
    RangeFilter,
    Filter,
} from '@sector-labs/fe-search-redux/filters';

import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldFilterType } from 'horizontal/types';

const FilterTypeToFeSearchReduxFilter = {
    [CategoryFieldFilterType.SINGLE_CHOICE]: ExactFilter,
    [CategoryFieldFilterType.MULTIPLE_CHOICES]: RefinementFilter,
    [CategoryFieldFilterType.RANGE]: RangeFilter,
} as const;

const useFilter = <T extends any>(targetField: FlatCategoryField, defaultValue: T): Filter<T> => {
    const FilterType = FilterTypeToFeSearchReduxFilter[targetField?.filterType] || RangeFilter;
    const attribute = targetField.attribute;

    // @ts-expect-error - TS2345 - Argument of type 'typeof ExactFilter | typeof RefinementFilter | typeof RangeFilter' is not assignable to parameter of type 'typeof Filter'.
    const filter = useSearchFilterOfType(FilterType, `extraFields.${attribute}`);
    const defaultFilter = React.useMemo(
        () =>
            new FilterType({
                attribute,
                // @ts-expect-error - TS2322 - Type 'T' is not assignable to type 'T & RefinementFilterValue<T> & RangeFilterValue'.
                value: defaultValue,
            }),
        [attribute, defaultValue, FilterType],
    );

    if (!targetField) {
        // @ts-expect-error - TS2322 - Type 'T' is not assignable to type 'Filter<T>'.
        return defaultValue;
    }

    if (!filter) {
        // @ts-expect-error - TS2322 - Type 'RefinementFilter<T> | RangeFilter | ExactFilter<T>' is not assignable to type 'Filter<T>'.
        return defaultFilter;
    }

    // @ts-expect-error - TS2322 - Type 'Filter<unknown>' is not assignable to type 'Filter<T>'.
    return filter;
};

export default useFilter;
