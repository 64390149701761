import * as React from 'react';

import { ChoiceBoxGroup } from 'horizontal/generic/choicePage';
import type { BasicChoice } from 'horizontal/generic/choicePage/types';
import type { Ad } from 'horizontal/types';

import ChoiceBox from './choiceBox';
import styles from './styles/choicesSection.cssm';

type Props<T extends BasicChoice> = {
    readonly onClick: (arg1: T) => void;
    readonly choices: Array<T>;
    readonly selectedValue: string | number | null | undefined;
    readonly enabledIds?: Array<number>;
    readonly ad: Ad | null | undefined;
};

const ChoicesSection = <T extends BasicChoice>({
    choices,
    onClick,
    selectedValue,
    enabledIds,
    ad,
}: Props<T>) => {
    const selectedChoices = React.useMemo(
        () => (selectedValue ? [selectedValue] : []),
        [selectedValue],
    );

    return (
        <ChoiceBoxGroup
            className={styles.choiceBoxGroup}
            idField={'id'}
            choices={choices}
            // @ts-expect-error - TS2322 - Type '({ isSelected, choice, ad, }: { isSelected: boolean; choice: ProductPurchase; ad: Ad | null | undefined; }) => JSX.Element' is not assignable to type 'ComponentType<ChoiceBoxProps<T>> | undefined'.
            choiceComponent={ChoiceBox}
            selectedChoiceIds={selectedChoices}
            enabledIds={enabledIds}
            onChoiceClick={onClick}
            ad={ad}
        />
    );
};

export default ChoicesSection;
