import React from 'react';
import { Trans } from '@lingui/macro';
// @ts-expect-error - TS2724 - '"react"' has no exported member named 'Element'. Did you mean 'CElement'?
import type { Element } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SaveSearchDialog from 'strat/modal/saveSearchDialog';
import { useSavedSearches } from 'strat/search/savedSearches';
import { removeSavedSearch } from 'strat/search/savedSearches/state';
import { Flex } from 'strat/components';
import type { AppDispatch } from 'strat/state';

import { useEnsureHasActiveUser } from 'horizontal/user';
import IconHeart from '@app/assets/icons/iconHeart.svg';
import SaveSearchButtonContent from '@app/savedSearches/saveSearchButtonContent';

import styles from './styles/saveSearchButton.cssm';
import SaveSearchDialogContent from './saveSearchDialogContent';
import SavedSearchSuccessfullyDialog from './savedSearchSuccessfullyDialog';
import { selectCurrentSearchIfSaved } from './selectors';
import useExtraFieldsAndSuggestedTitle from './useExtraFieldsAndSuggestedTitle';
import useShowSaveSearchButton from './useShowSaveSearchButton';

const SaveSearchButton = ({
    renderSaveButtonContent,
}: {
    renderSaveButtonContent?: (onClick: () => void) => Element<any>;
}) => {
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const onSaveSearchClicked = () => setDialogVisible(true);
    const onClickButton = useEnsureHasActiveUser({ onDone: onSaveSearchClicked });
    useSavedSearches();
    const dispatch = useDispatch<AppDispatch>();
    const currentSavedSearch = useSelector(selectCurrentSearchIfSaved);
    const { suggestedTitle } = useExtraFieldsAndSuggestedTitle();
    const saveSearchButtonVisible = useShowSaveSearchButton();

    const onSavedSearchButtonClick = React.useCallback(() => {
        if (currentSavedSearch) {
            dispatch(removeSavedSearch(currentSavedSearch.id));
            return;
        }
        onClickButton();
    }, [currentSavedSearch, dispatch, onClickButton]);

    if (!saveSearchButtonVisible) {
        return null;
    }

    const renderSubmitButtons = (disableSubmit: boolean) => (
        <button type="submit" disabled={disableSubmit} className={styles.submitButton}>
            <Flex alignCenter justifyCenter>
                <IconHeart className={styles.iconSubmitButton} />
                <span>
                    <Trans>Save this search</Trans>
                </span>
            </Flex>
        </button>
    );

    const renderSaveSearchButton =
        renderSaveButtonContent ||
        ((onClick: () => void) => <SaveSearchButtonContent onClick={onClick} />);

    return (
        <>
            {renderSaveSearchButton(onSavedSearchButtonClick)}
            <SaveSearchDialog
                visible={dialogVisible}
                onVisibilityChanged={setDialogVisible}
                styles={styles}
                renderInput={(field) => <SaveSearchDialogContent field={field} />}
                renderSubmitButtons={renderSubmitButtons}
                setShowSuccessMessage={setShowSuccessMessage}
                initialValue={suggestedTitle || ''}
            />
            <SavedSearchSuccessfullyDialog
                visible={showSuccessMessage}
                onVisibilityChanged={() => setShowSuccessMessage(!showSuccessMessage)}
            />
        </>
    );
};

export default SaveSearchButton;
