import * as React from 'react';
import { TemplateType } from 'strat/templates';
import AdCardSubtitleCategories from '@app/branding/adCardSubtitleCategories';
import Category from '@app/branding/category';

import type { MixedAd, AdExtraFields } from 'horizontal/types';

import AdCarSubtitle from './adCarSubtitle';
import AdPropertySubtitle from './adPropertySubtitle';
import useLiteAdTemplate from './useLiteAdTemplate';

const hasFields = (
    extraFields?: AdExtraFields | null,
    fields?: {
        [key: string]: any;
    } | null,
) => {
    if (!fields || !extraFields) {
        return false;
    }
    return Object.keys(fields)
        .map((key) => fields[key])
        .every((field) => field in extraFields);
};

const useAdSubtitle = (ad: MixedAd): React.ReactNode | null | undefined => {
    const subtitle = useLiteAdTemplate(TemplateType.SUBTITLE, ad);
    const adCategory = ad?.category[0];

    if (!adCategory) {
        return null;
    }

    // Check for Vehicle ads
    if (Category.isOfVehiclesType(adCategory)) {
        if (hasFields(ad?.extraFields, AdCardSubtitleCategories?.VEHICLES.fields)) {
            return <AdCarSubtitle ad={ad} />;
        }
    }

    // Check for Property ads
    if (Category.isOfPropertyType(adCategory)) {
        if (hasFields(ad?.extraFields, AdCardSubtitleCategories?.PROPERTIES.fields)) {
            return <AdPropertySubtitle ad={ad} />;
        }
    }

    return subtitle;
};

export default useAdSubtitle;
