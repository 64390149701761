import React from 'react';
import useI18n from 'strat/i18n/language/useI18n';
import settings from '@app/branding/settings';

import StratAPI from 'horizontal/api/strat';
import { useMakeCancelable } from 'horizontal/util';
import { ProductType } from 'horizontal/types';
import type { FullAd } from 'horizontal/types';

import type { ProductPackageOffer } from '../types';

const useFreeAdCommission = (ad: FullAd) => {
    const minFreeAdCommission = settings.minFreeAdCommission.toString();
    const [commissionAmount, setCommissionAmount] = React.useState<string>('');
    const [error, setError] = React.useState(false);
    const i18n = useI18n();
    const topLevelLocation = settings.getTopLevelLocation(i18n);
    const makeCancelable = useMakeCancelable();
    const [packages, setPackages] = React.useState<Array<ProductPackageOffer>>([]);

    const price = ad.extraFields?.price || ad.price;

    React.useEffect(() => {
        if (price) {
            const adPrice = 0.01 * Number(price);
            const commissionPrice =
                adPrice > Number(minFreeAdCommission) ? adPrice : minFreeAdCommission;
            setCommissionAmount(commissionPrice.toString());
        }
    }, [price, minFreeAdCommission]);

    const fetchProductPackageOffer = React.useCallback(
        (categoryID) => {
            return makeCancelable(
                new StratAPI(i18n.locale).getProductPackageOffers(
                    categoryID,
                    topLevelLocation.externalID,
                    {
                        types: [ProductType.FREE_AD_WITH_COMMISION],
                        show_all_bundles: true,
                    },
                ),
                // @ts-expect-error - TS7006 - Parameter 'response' implicitly has an 'any' type.
            ).then((response) => (response.status < 300 ? response.data : null));
        },
        [topLevelLocation.externalID, makeCancelable, i18n.locale],
    );

    const adCategoryLength = ad?.category?.length;
    const adCategoryExternalId = adCategoryLength && ad.category[adCategoryLength - 1].externalID;

    React.useEffect(() => {
        if (adCategoryExternalId) {
            fetchProductPackageOffer(adCategoryExternalId).then(
                (data: Array<ProductPackageOffer>) => {
                    setPackages(data);
                },
            );
        }
    }, [adCategoryExternalId, fetchProductPackageOffer]);

    const validateCommission = React.useCallback(() => {
        if (
            !commissionAmount ||
            Number(commissionAmount) < Number(minFreeAdCommission) ||
            Number(commissionAmount) > settings.maxFreeAdCommission
        ) {
            setError(true);
            setCommissionAmount(minFreeAdCommission);
        } else {
            setError(false);
        }
    }, [commissionAmount, minFreeAdCommission]);

    // cleanup function
    React.useEffect(() => {
        return () => {
            setCommissionAmount(minFreeAdCommission);
            setError(false);
            setPackages([]);
        };
    }, [minFreeAdCommission]);

    const onCommissionChange = (val: string) => {
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
        if (val.trim() === '' || !isNaN(val)) {
            setCommissionAmount(val.trim());
        }
    };

    return {
        commissionAmount,
        validateCommission,
        error,
        onCommissionChange,
        packages,
    };
};

export default useFreeAdCommission;
