import { createSelector } from 'reselect';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import { selectIsProjectSearchEnabled } from 'strat/search/selectors';
import EMPTY_ARRAY from 'strat/empty/array';

import { selectInternalLinks } from '../selectors';

import { shouldRenderChildLocations } from './shouldRenderChildLocations';

const selectShouldRenderChildLocations = createSelector(getFilterCollection, (filters) =>
    shouldRenderChildLocations(filters),
);

const selectChildLocations = createSelector(
    selectInternalLinks,
    selectIsProjectSearchEnabled,
    (internalLinks, isProjectSearchEnabled) =>
        internalLinks?.data?.childLocationsNew?.length
            ? internalLinks.data.childLocationsNew
            : CONFIG.build.STRAT_ENABLE_NEW_PROJECTS_LPV &&
                isProjectSearchEnabled &&
                internalLinks?.data?.childLocations?.length
              ? internalLinks?.data?.childLocations
              : EMPTY_ARRAY,
);

export { selectShouldRenderChildLocations, selectChildLocations };
