import { SearchPurposeType } from 'strat/search/types';
import { GlobalState } from 'strat/state';

const Actions = Object.freeze({
    SET_ACTIVE_TAB: 'MINI_FORM/SET_ACTIVE_TAB' as const,
});

const setMiniFormActiveTab = (activeTab: Values<typeof SearchPurposeType> | null) => ({
    type: Actions.SET_ACTIVE_TAB,
    activeTab,
});

const defaultState = null;

const miniFormActiveTabReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_ACTIVE_TAB:
            return action.activeTab;
        default:
            return state;
    }
};

const selectMiniFormActiveTab = (state: GlobalState) => state.miniFormActiveTab;

export { setMiniFormActiveTab, selectMiniFormActiveTab };

export default miniFormActiveTabReducer;
