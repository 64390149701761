import * as React from 'react';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { ViewSections } from 'strat/gtm';
import { Text } from 'strat/components';

import type { LiteAd } from 'horizontal/types';

import HitList from './hitList';
import styles from './styles/fallbackHitList.cssm';

type Props = {
    readonly viewType: Values<typeof SearchHitsLayout>;
    readonly hits: Array<LiteAd>;
    readonly loading?: boolean;
    readonly loadingHitsCount?: number;
    readonly emptyDivPosition?: number;
    readonly CTAPosition?: number;
    readonly viewSection: Values<typeof ViewSections>;
    readonly title?: string;
};

const FallbackHitList = ({
    viewType,
    hits,
    loading,
    loadingHitsCount,
    emptyDivPosition,
    CTAPosition,
    viewSection,
    title,
}: Props) => {
    return (
        <div className={styles.container} aria-label="Fallback hits container">
            {!!hits.length && <Text.Base className={styles.title}>{title}</Text.Base>}
            <HitList
                viewType={viewType}
                hits={hits}
                loading={loading}
                loadingHitsCount={loadingHitsCount}
                emptyDivPosition={emptyDivPosition}
                CTAPosition={CTAPosition}
                viewSection={viewSection}
            />
        </div>
    );
};

export default FallbackHitList;
