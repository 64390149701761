const ViewSections = Object.freeze({
    /* home page */
    HOME_PAGE: 'home_page',
    PROPERTY_SEARCH: 'property_search',
    DUBAI_TRANSACTIONS: 'dubai_transactions',

    /* search results page */
    SEARCH_RESULTS: 'search_results',
    EMAIL_POPUP: 'email_popup',
    PROJECT_CARD: 'search_project_card',
    STORIES: 'stories',
    DEVELOPER_PROPERTY: 'developer_pusher',

    /* details page */
    PROPERTY_DETAIL: 'property_detail',
    DESCRIPTION: 'description',
    DIRECTIONS: 'directions',
    MORTGAGE: 'mortgage',
    TRENDS: 'trends',
    PHOTO_GALLERY: 'photo_gallery',
    GALLERY_VIEW: 'gallery_view',
    MAP_POPUP: 'map_popup',
    REQUEST_FLOORPLAN: 'request_floorplan',
    REQUEST_VIDEO: 'request_video',
    CONTENT_BUTTON: 'content_button',
    MAP_SECTION: 'map_section',

    /* agency pages */
    AGENCY_DETAIL: 'agency_detail',
    AGENCY_SEARCH: 'agency_search',

    /* agent pages */
    AGENT_DETAIL: 'agent_detail',
    AGENT_SEARCH: 'agent_search',

    /* featured agents carousel */
    FEATURED_AGENTS: 'featured_agents',

    /* recommended properties */
    RECOMMENDED_PROPERTIES: 'recommended_properties',
    RECOMMENDED_PROPERTIES_PAGE: 'recommended_properties_page',
    SEARCH_RECOMMENDATIONS: 'search_recommendations',

    /* similar properties */
    SIMILAR_PROPERTIES: 'similar_properties',

    /* notifications tracking */
    SEARCH_PAGE_BOTTOM: 'search_page_bottom',
    PROPERTY_PAGE_BOTTOM: 'property_page_bottom',
    LEAD_CALL_DIALOG: 'lead_call_dialog',
    LEAD_EMAIL_SUCCESS_DIALOG: 'lead_email_success_dialog',
    SETTINGS_NOTIFICATIONS: 'notifications',

    /* map based search */
    MAP_BASED_SEARCH: 'map_based_search',
    MAP_NEARBY_PROPERTIES: 'map_nearby_properties',

    /* commute search */
    COMMUTE_SEARCH: 'commute_search',
    FILTER_SECTION: 'filter_section',

    /* floor plans */
    FLOOR_PLANS_DETAIL: 'floor_plans_detail',

    /* favorites */
    FAVORITES: 'favorites',

    /* remarketing */
    REMARKETING: 'remarketing',
    OFF_PLAN_REMARKETING: 'remarketing_offplan',

    /* recommended sellers */
    RECOMMENDED_SELLERS: 'recommended_sellers',

    BODY: 'body',
    HEADER: 'header',
    FOOTER: 'footer',
    CATEGORY_DROPDOWN: 'all_categories_menu',
    LOGIN_DIALOG: 'login_popup',

    FILTER: 'filters',

    SELLER_PROFILE: 'seller_profile',
    AGENCY_PROFILE: 'agency_profile',
    USER_DETAIL: 'user_detail',

    FITOUT_CALC: 'fitout_calculator',
    RENT_INSTALLMENT_CALC: 'rent_installment_calculator',

    /* Homeowner module */
    ADD_PROPERTY: 'add_property',

    /* New projects */
    NEW_PROJECTS: 'new_projects',

    /* User Activities experiment */
    ACTIVITY_SECTION: 'activity_section',

    BILLING_FORM: 'billing_form',

    SMART_SHARE: 'smartshare',
    SMART_SHARE_OTHER_AGENT_LISTINGS: 'smartshare_other',

    NBS_CARD: 'card',
    NBS_BODY_EXPERIMENT: 'body_experiment',

    /* DLD Transactions */
    TRANSACTIONS_PAGE: 'transactions_page',
    TRANSACTIONS_MAP: 'transactions_map',

    /* TruEstimate */
    TRUESTIMATE_REPORT: 'truestimate_report',
});

export default ViewSections;
