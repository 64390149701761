import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import type { UserOrdersRouteParams } from 'horizontal/routes';

import { UserOrdersSection, ProductPurchaseVirtualStatus } from '../types';

const useProductPurchaseStatusParam = ():
    | Values<typeof ProductPurchaseVirtualStatus>
    | null
    | undefined => {
    const params: UserOrdersRouteParams = useSelector(selectRouteParams);
    if (params.section !== UserOrdersSection.ORDERS) {
        return null;
    }

    return params.ordersStatus || ProductPurchaseVirtualStatus.ACTIVE;
};

export default useProductPurchaseStatusParam;
