//@ts-nocheck
import { createSelector } from 'reselect';

const selectCurrentCurrency = (state: any) => state.settings.currency;

const selectBaseCurrency = (state: any) => state.i18n.money.baseCurrency;

const selectExchangeRates = (state: any) => state.i18n.money.exchangeRates;

const selectCurrency = createSelector(
    [selectCurrentCurrency, selectBaseCurrency, selectExchangeRates],
    (currency, baseCurrency, exchangeRates) => ({ currency, baseCurrency, exchangeRates }),
);

export { selectCurrentCurrency };

export default selectCurrency;
