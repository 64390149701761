import * as React from 'react';
import brandingSettings from '@app/branding/settings';

import type { Category } from 'horizontal/types';

import CategoryLink from './categoryLink';
import styles from './styles/topSubcategories.cssm';

type Props = {
    readonly categories: Array<Category>;
    readonly topSubcategoryExternalIDs?: Array<string>;
};

const TopSubcategories = ({
    categories,
    topSubcategoryExternalIDs = brandingSettings.topCategories,
}: Props): React.ReactElement => {
    const subCategories = React.useMemo(
        () => categories.flatMap((category) => category.children),
        [categories],
    );

    const topSubcategories = React.useMemo((): Array<Category> => {
        return topSubcategoryExternalIDs.reduce<Array<any>>((acc, externalID: string) => {
            const firstSubCategory = subCategories.find(
                (subCategory) => subCategory.externalID === externalID,
            );
            return firstSubCategory ? [...acc, firstSubCategory] : acc;
        }, []);
    }, [subCategories, topSubcategoryExternalIDs]);

    // @ts-expect-error - TS2322 - Type 'Element[]' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
    return topSubcategories.map((category) => (
        <div key={category.id} className={styles.topSubcategory}>
            <CategoryLink category={category} />
        </div>
    ));
};

export default TopSubcategories;
