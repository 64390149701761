import * as React from 'react';
import classNames from 'classnames';

import { useRouter } from 'react-true-router';
import IconBack from '@app/assets/icons/iconBack.svg';

import Flex from './flex';
import styles from './styles/backButton.cssm';

type Props = {
    className?: string;
    onBack?: () => void;
    popHistory?: boolean;
    renderIcon?: () => React.ReactNode;
};

const BackButton = ({ className, onBack, popHistory, renderIcon }: Props) => {
    const router = useRouter();

    const handleClick = React.useCallback(() => {
        if (popHistory) {
            router.pop();
        }

        if (onBack) {
            onBack();
        }
    }, [onBack, popHistory, router]);

    return (
        <Flex
            alignCenter
            onClick={handleClick}
            className={classNames({ [styles.container]: !renderIcon })}
            aria-label={`Back button`}
        >
            {renderIcon ? renderIcon() : <IconBack className={className || styles.back} />}
        </Flex>
    );
};

export default BackButton;
