import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { FilterValues } from 'strat/search';
import { Text } from 'strat/components';
import IconArrowLeft from '@app/assets/icons/iconArrowLeft.svg';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import { selectIsLanguageRTL } from 'strat/i18n/language';

import { AdProduct } from 'horizontal/types';
import { RefinedSearchLink } from 'horizontal/search/routing';

import styles from './styles/viewAllAdsOfProduct.cssm';

type Props = {
    readonly product: AdProduct;
    className?: string;
};

const ViewAllAdsOfProduct = ({ product }: Props) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);
    return (
        <RefinedSearchLink
            className={styles.viewAll}
            params={{ [FilterValues.product.attribute]: product }}
        >
            <Text.Base bold className={styles.text}>
                <Trans>View all</Trans>
            </Text.Base>
            {isLanguageRTL ? (
                <IconArrowLeft className={styles.arrow} />
            ) : (
                <IconArrowRight className={styles.arrow} />
            )}
        </RefinedSearchLink>
    );
};

export default ViewAllAdsOfProduct;
