import React from 'react';
import IconCall from '@app/assets/icons/iconCall.svg';

import { useI18n } from 'strat/i18n/language';
import { PhoneNumberLink } from 'strat/contact/phoneNumber';
import { Phone } from 'strat/contact/contactNumbersDisplay';

import styles from './styles/proxyPhoneNumber.cssm';

/**
 * Properties for {@see ContactFormSlideIn}.
 */
type Props = {
    /**
     * Method to call when a phone number is clicked.
     */
    onPhoneNumberClick?: (event: React.SyntheticEvent<any>) => void;
    proxyMobile?: null | string;
    proxyPhone?: null | string;
};

const CallIcon = () => <IconCall className={styles.circledCallIcon} />;

export const ProxyPhoneNumber = (props: Props) => {
    const i18n = useI18n();
    const shouldRenderBothNumbers = props.proxyMobile && props.proxyPhone;

    return (
        <>
            {props.proxyMobile && (
                <div className={styles.row} key={'mobileProxy'}>
                    <span className={styles.key}>
                        {shouldRenderBothNumbers ? Phone(i18n, 'mobile') : <CallIcon />}
                    </span>
                    <span className={`${styles.value} ${styles.prominent}`}>
                        <PhoneNumberLink
                            aria-label="Listing phone number"
                            // @ts-expect-error - TS2322 - Type '{ "aria-label": string; numbers: string[]; className: any; onClick: (event: SyntheticEvent<any, Event>) => void; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, WithCleanPhoneNumberProps> & RefAttributes<any> & { children?: ReactNode; }'.
                            numbers={[props.proxyMobile]}
                            className={styles.phoneNumber}
                            onClick={(event: React.SyntheticEvent<any>): void => {
                                if (props.onPhoneNumberClick) {
                                    props.onPhoneNumberClick(event);
                                }
                            }}
                        />
                    </span>
                </div>
            )}
            {props.proxyPhone && (
                <div className={styles.row} key={'phoneProxy'}>
                    <span className={styles.key}>
                        {shouldRenderBothNumbers ? Phone(i18n, 'phone') : <CallIcon />}
                    </span>
                    <span className={`${styles.value} ${styles.prominent}`}>
                        <PhoneNumberLink
                            aria-label="Listing phone number"
                            // @ts-expect-error - TS2322 - Type '{ "aria-label": string; numbers: string[]; className: any; onClick: (event: SyntheticEvent<any, Event>) => void; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, WithCleanPhoneNumberProps> & RefAttributes<any> & { children?: ReactNode; }'.
                            numbers={[props.proxyPhone]}
                            className={styles.phoneNumber}
                            onClick={(event: React.SyntheticEvent<any>): void => {
                                if (props.onPhoneNumberClick) {
                                    props.onPhoneNumberClick(event);
                                }
                            }}
                        />
                    </span>
                </div>
            )}
        </>
    );
};

export default ProxyPhoneNumber;
