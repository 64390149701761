import * as React from 'react';

import type { AgencyData } from 'strat/property';
import type { AgencyForAgentData } from 'strat/agency/agent/types';
import { ThumbnailImage, ThumbnailSizes, NoImage } from 'strat/image';
import Link from 'react-true-router/link';
import RouteNames from 'strat/routes/routeNames';

import styles from './styles/agencyLogo.cssm';

/**
 * Properties for {@see AgencyLogo}.
 */
type Props = {
    agency: AgencyForAgentData | AgencyData;
    agencyLogoClassName?: string;
    agencyPhotoClassName?: string;
    showNoImage?: boolean;
    isLinkEnabled?: boolean;
    noImageClassName?: string;
};

const AgencyLogo = ({
    agency,
    agencyLogoClassName = styles.agencyLogoImage,
    agencyPhotoClassName = styles.agencyLogo,
    showNoImage = false,
    isLinkEnabled = true,
    noImageClassName,
}: Props) => {
    if (!agency.logo) {
        return showNoImage ? <NoImage className={noImageClassName} /> : null;
    }

    const logo = (
        <ThumbnailImage
            imageID={(agency.logo || {}).id}
            className={agencyLogoClassName}
            size={ThumbnailSizes.SMALL}
            alt={agency.name}
            title={agency.name}
            label="Agency logo"
            lazyLoad
        />
    );

    return (
        <div className={agencyPhotoClassName}>
            {CONFIG.build.ENABLE_AGENCY ? (
                <Link
                    hard
                    route={RouteNames.AGENCY_DETAIL}
                    params={{ agencySlug: agency.slug }}
                    enabled={isLinkEnabled}
                >
                    {logo}
                </Link>
            ) : (
                logo
            )}
        </div>
    );
};

export default AgencyLogo;
