import * as React from 'react';
import { useSelector } from 'react-redux';
import useRouter from 'react-true-router/useRouter';

import type { Ad } from 'horizontal/types';
import { AdCreationSourceRoutes } from 'horizontal/constants';

import { selectBundle } from './selectors';

const useNavigateToPostAdSuccess = (ad?: Ad | null) => {
    const router = useRouter();
    const creationSource = useSelector(selectBundle);

    return React.useCallback(
        (hidePurchaseSuggestions?: boolean) => {
            router.pushRoute(AdCreationSourceRoutes[creationSource].POST_AD_SUCCESS, {
                adExternalID: ad?.externalID,
                hidePurchaseSuggestions,
            });
        },
        [router, creationSource, ad],
    );
};

export { useNavigateToPostAdSuccess };
