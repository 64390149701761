import * as React from 'react';
import { Formik } from 'formik';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { Button, Input, Text } from 'strat/components';
import { useActiveUserPhoneNumber } from 'strat/user/session';

import {
    FieldAttribute,
    generateInitialValues,
    makeValidationSchema,
    MobileWalletPaymentFields,
} from './mobileWalletFields';
import styles from './styles/mobileWalletPaymentForm.cssm';

type FormProps = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
};

const Form = ({ setFieldValue, values, handleBlur, touched, errors, handleSubmit }: FormProps) => {
    const i18n = useI18n();
    return (
        <div className={styles.formContainer}>
            <Text.Large block bold>
                <Trans>Payment details</Trans>
            </Text.Large>
            {MobileWalletPaymentFields.map((field) => (
                <Input
                    fixUnitPosition
                    name={field.attribute}
                    title={field.title(i18n)}
                    type={field.type}
                    key={field.attribute}
                    unit={field.unit}
                    onChange={(value) => setFieldValue(field.attribute, value)}
                    onBlur={handleBlur}
                    value={values[field.attribute]}
                    errorMessage={touched[field.attribute] && errors[field.attribute]}
                    accepted={touched[field.attribute] && !errors[field.attribute]}
                />
            ))}
            <Text.Regular block bold gray>
                <Trans>
                    After clicking Pay, you will receive OTP on your phone. You have to enter it
                    beside your mobile wallet MPIN (Mobile Banking PIN) to confirm the transaction.
                </Trans>
            </Text.Regular>
            <Text.Regular block bold gray>
                <Trans>
                    If you don&apos;t fill it in time, please try to make the payment again.
                </Trans>
            </Text.Regular>
            <Button onClick={handleSubmit} stretch>
                <Trans>Pay</Trans>
            </Button>
        </div>
    );
};

const renderForm = (props: React.ComponentProps<typeof Form>) => <Form {...props} />;

type Props = {
    readonly onSubmit: (arg1?: any) => void;
};

const PaymobMobileWalletPaymentForm = ({ onSubmit }: Props) => {
    const i18n = useI18n();
    const userPhoneNumber = useActiveUserPhoneNumber();

    const validationSchema = React.useMemo(() => makeValidationSchema(i18n), [i18n]);

    const initialValues = React.useMemo(() => {
        const values = generateInitialValues();
        const countryPrefix = settings.defaultPhoneNumber.prefix;
        if (userPhoneNumber && userPhoneNumber.startsWith(countryPrefix)) {
            return {
                ...values,
                [FieldAttribute.PHONE_NUMBER]: `0${userPhoneNumber.replace(countryPrefix, '')}`,
            };
        }
        return values;
    }, [userPhoneNumber]);

    const handleSubmit = (values: any) => {
        const phoneNumber = values[FieldAttribute.PHONE_NUMBER];
        if (!phoneNumber.startsWith('0')) {
            values[FieldAttribute.PHONE_NUMBER] = `0${phoneNumber}`;
        }

        onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
};

export default PaymobMobileWalletPaymentForm;
