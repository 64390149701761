import * as React from 'react';
import { trigger, Triggers, ViewSections } from 'strat/gtm';

import { AppBundles } from 'horizontal/types';

import useNavigateToCreateAd from './useNavigateToCreateAd';

const useOnSellButtonClick = (source: Values<typeof AppBundles> = AppBundles.MAIN_BUNDLE_NAME) => {
    const navigateToCreateAd = useNavigateToCreateAd(source);

    return React.useCallback(() => {
        trigger(Triggers.CLICK_SELL, { view_section: ViewSections.HEADER });
        navigateToCreateAd();
    }, [navigateToCreateAd]);
};

export default useOnSellButtonClick;
