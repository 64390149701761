import * as React from 'react';
import IconEmail from '@app/assets/icons/iconEmail.svg';
import ContactFormButtons from '@app/contact/contactFormModalButtons';

import { withGTMLeadTracking, GTMLeadTrackingProps } from 'strat/gtm';
import WhatsAppOrSmsButtons from '@app/listing/compact/whatsAppOrSmsButtons';
import { ButtonVariant } from 'strat/components';

import ContactFormCallButton from '../contactFormCallButton';
import ContactFormEmailButton from '../contactFormEmailButton';

import styles from './styles/contactButtonsWhatsApp.cssm';

type Props = GTMLeadTrackingProps &
    JSX.LibraryManagedAttributes<
        typeof ContactFormButtons,
        React.ComponentProps<typeof ContactFormButtons>
    >;

const renderIcon = () => <IconEmail className={styles.icon} />;

const ContactButtonsWhatsApp = (props: Props) => {
    const { trackCallView, trackCallLead, trackEmailView, viewSection } = props;

    const onCallClick = React.useCallback(
        () => trackCallView(viewSection),
        [trackCallView, viewSection],
    );

    const onPhoneNumberClick = React.useCallback(
        () => trackCallLead(viewSection),
        [trackCallLead, viewSection],
    );

    const onEmailClick = React.useCallback(
        () => trackEmailView(viewSection),
        [trackEmailView, viewSection],
    );

    return (
        <div className={styles.container}>
            <ContactFormEmailButton
                sendEmail={props.sendEmail}
                renderIcon={props.withIcon && renderIcon}
                className={styles.emailButton}
                emailButtonTitle={props.emailButtonTitle}
                // @ts-expect-error - TS2322 - Type '{ sendEmail: any; renderIcon: any; className: string; emailButtonTitle: any; onClick: () => any; }' is not assignable to type 'IntrinsicAttributes & Props'.
                onClick={onEmailClick}
            />
            <div className={styles.innerContainer}>
                <ContactFormCallButton
                    className={styles.callButton}
                    phoneNumber={props.phoneNumber}
                    onClick={onCallClick}
                    onPhoneNumberClick={onPhoneNumberClick}
                    externalID={props.externalID}
                    isNewDevelopment={props.isNewDevelopment}
                    withIcon={props.withIcon}
                    requiresLoginForContact={props.requiresLoginForContact}
                />
                <WhatsAppOrSmsButtons
                    property={props.property}
                    className={styles.whatsappButton}
                    viewSection={props.viewSection}
                    variant={ButtonVariant.FILLED}
                />
            </div>
        </div>
    );
};

export default withGTMLeadTracking(ContactButtonsWhatsApp);
