/**
 * Describes the position in the list of breadcrumbs
 * in which the category is displayed.
 */
const DisplayCategory = Object.freeze({
    /*
     * Display category only within the last element.
     * Example: a, b, c, d {categoryName}
     */
    LAST: 'last',
    /*
     * Display category within before the last element.
     * Example: a, b, c {categoryName}, d
     */
    BEFORE_LAST: 'before-last',
    NONE: 'none',
});

export { DisplayCategory };
