import CookieNames from 'strat/cookies/cookieNames';

/**
 * List of horizontal cookies we set.
 */
export default {
    // Strat specific cookies
    ...CookieNames,
    // Olx-pk specific cookies
    USER_CURRENT_LOCATION: 'userCurrentLocation',
} as const;
