import { useSelector } from 'react-redux';

import { selectProductPurchases } from 'horizontal/packages/selectors';
import type { ProductPurchase } from 'horizontal/types';

import { ProductPurchaseVirtualStatus } from '../types';

import getProductPurchaseVirtualStatus from './getProductPurchaseVirtualStatus';

const useProductPurchases = (
    orderStatus?: Values<typeof ProductPurchaseVirtualStatus> | null,
): Array<ProductPurchase> => {
    const productPurchases = useSelector(selectProductPurchases);

    if (!orderStatus) {
        return [];
    }

    return productPurchases.filter(
        (productPurchase) => getProductPurchaseVirtualStatus(productPurchase) === orderStatus,
    );
};

export default useProductPurchases;
