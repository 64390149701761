import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import IconArea from '@app/assets/icons/iconArea.svg';
import settings from '@app/branding/settings';

import IconBeds from 'horizontal/assets/icons/iconBeds.svg';
import IconBaths from 'horizontal/assets/icons/iconBaths.svg';
import { BasicTag } from 'horizontal/adCard/tags';
import { AdCommonExtraField } from 'horizontal/branding/adExtraFields';
import type { LiteAd } from 'horizontal/types';

import useFormatAdExtraFieldsToObject from './useFormatAdExtraFieldsToObject';
import { areaUnitAbbreviations } from './constants';
import styles from './styles/adSubtitleUpdatedDesign.cssm';

type Props = {
    ad: LiteAd;
};

const AdPropertySubtitle = ({ ad }: Props) => {
    const i18n = useI18n();

    const extraFields = useFormatAdExtraFieldsToObject(ad);

    const beds = extraFields[AdCommonExtraField.BEDROOMS];
    const bathrooms = extraFields[AdCommonExtraField.BATHROOMS];

    const areaUnitField = extraFields[AdCommonExtraField.AREA_UNIT] || settings.defaultAreaUnit;
    const areaUnit =
        areaUnitField in areaUnitAbbreviations
            ? areaUnitAbbreviations[areaUnitField]
            : areaUnitField;
    const area = extraFields[AdCommonExtraField.AREA]
        ? t(i18n)`${extraFields[AdCommonExtraField.AREA]} ${areaUnit}`
        : '';

    return (
        <Flex className={styles.container}>
            {!!beds && (
                <BasicTag
                    text={beds}
                    renderIcon={() => <IconBeds className={styles.icon} />}
                    ariaLabel={'Bedrooms'}
                />
            )}
            {!!bathrooms && (
                <BasicTag
                    text={bathrooms}
                    renderIcon={() => <IconBaths className={styles.icon} />}
                    ariaLabel={'Bathrooms'}
                />
            )}
            {!!area && (
                <BasicTag
                    text={area}
                    renderIcon={() => <IconArea className={styles.icon} />}
                    ariaLabel={'Area'}
                    withEllipsis
                />
            )}
        </Flex>
    );
};

export default AdPropertySubtitle;
