import * as React from 'react';
import type { FormikValues } from 'formik';
import EMPTY_ARRAY from 'strat/empty/array';
import { dictionaryToFlatArray } from 'strat/util';

import { getParentField } from 'horizontal/categoryFields';

import type {
    CategoryField,
    CategoryFieldChoice,
    CategoryFieldCombination,
    FlatCategoryField,
} from '../types';

import { useCategoryFieldCombinations } from './hooks';
import flattenFieldsWithCombinations from './flattenFieldsWithCombinations';

type FieldGroupIndex = {
    [attributeValue: string]: number;
};

export type AttributeChoices = {
    [attributeValue: string]: {
        [choiceValue: string]: CategoryFieldChoice;
    };
};

export type ParentFields = {
    [fieldId: number]: CategoryField;
};

function getPreviousGroup(
    combinationsByGroupIndex: Array<Set<CategoryFieldCombination>>,
    groupIndex?: number,
): Set<CategoryFieldCombination> {
    if (!groupIndex) {
        return combinationsByGroupIndex[0];
    }

    let currentIndex = groupIndex - 1;
    while (currentIndex >= 0) {
        if (combinationsByGroupIndex[currentIndex]) {
            return combinationsByGroupIndex[currentIndex];
        }
        currentIndex -= 1;
    }
    return combinationsByGroupIndex[0];
}

const filterChoices = (
    choices: Array<CategoryFieldChoice> | undefined,
    combinations: Array<CategoryFieldCombination>,
): Array<CategoryFieldChoice> | undefined => {
    return choices?.filter((choice: CategoryFieldChoice) =>
        combinations.some((combination: CategoryFieldCombination) =>
            combination.attributes.has(choice.id),
        ),
    );
};

/**
 * Choice reducer based on combinations for SubCategoryFields
 * SubCategoryFields has a parent and we need to restrict its choices based on each of the parent's choices
 */
const fieldChoiceReducer = (
    fields: Array<CategoryField>,
    combinations: Array<Set<CategoryFieldCombination>>,
): Array<CategoryField> => {
    return fields.map((field: CategoryField) => {
        if (!field.choices && !field.choiceGroups) {
            return field;
        }

        if (field.choices) {
            const matchedChoices = Object.keys(field.choices).reduce<Record<string, any>>(
                (choices, currentChoiceKey) => {
                    const filteredChoices = filterChoices(
                        field.choices?.[currentChoiceKey],
                        Array.from(getPreviousGroup(combinations, field.groupIndex)),
                    );
                    choices[currentChoiceKey] = filteredChoices;
                    return choices;
                },
                {},
            );

            return {
                ...field,
                combinationChoices: matchedChoices,
            };
        }
        if (field.choiceGroups) {
            const matchedChoices = Object.values(field.choiceGroups).reduce(
                (choices, group) => {
                    const filteredChoices = filterChoices(
                        group.choices.all,
                        Array.from(getPreviousGroup(combinations, field.groupIndex)),
                    );

                    return [...choices, ...(filteredChoices || [])];
                },

                [] as any[],
            );
            return {
                ...field,
                combinationChoices: matchedChoices,
            };
        }

        return field;
    });
};

/**
 * Calculates a map of fields' attributes to their groupIndex.
 *
 * @returns Dict {
 *     [CategoryField.attribute]: CategoryField.groupIndex,
 * }
 */
function calculateFieldGroupIndex(fields: Array<CategoryField>): FieldGroupIndex {
    return fields.reduce<Record<string, any>>(
        (accumulator, field) => ({
            ...accumulator,
            [field.attribute]: field.groupIndex,
        }),
        {},
    );
}

/**
 * Calculates a map of attribute values to their relevant choice and field.
 *
 * @returns Dict {
 *     [CategoryField.attribute]: Dict {
 *         [CategoryField.choice.value]: Choice,
 *     }
 * }
 */
function calculateAttributeChoicesLookup(fields: Array<CategoryField>): AttributeChoices {
    return fields.reduce<Record<string, any>>((accumulator, field) => {
        // @ts-expect-error - TS7034 - Variable 'fieldAttributeValue' implicitly has type 'any[]' in some locations where its type cannot be determined.
        let fieldAttributeValue = [];
        if (field.choices) {
            fieldAttributeValue = dictionaryToFlatArray(field.choices).reduce<Record<string, any>>(
                // @ts-expect-error - TS2345 - Argument of type '(choicesAccumulator: Record<string, any>, choice: CategoryFieldChoice) => { [x: string]: any; }' is not assignable to parameter of type '(previousValue: Record<string, any>, currentValue: CategoryFieldChoice[], currentIndex: number, array: CategoryFieldChoice[][]) => Record<...>'.
                (choicesAccumulator, choice: CategoryFieldChoice) => ({
                    ...choicesAccumulator,
                    [choice.value]: choice,
                }),
                {},
            );
        }
        return {
            ...accumulator,
            // @ts-expect-error - TS7005 - Variable 'fieldAttributeValue' implicitly has an 'any[]' type.
            [field.attribute]: fieldAttributeValue,
        };
    }, {});
}

/**
 * Calculates a map of field ids to their parent field.
 * The parent relationship in CategoryFields is extracted from the parent relationship on their enum_values(choices).
 *
 * Note!: The field categoryField.parentID is deprecated in favor of categoryFieldEnumValue.parentID.
 * (https://github.com/SectorLabs/maple/pull/15933)
 *
 * @returns Dict {
 *  [CategoryField.id]: CategoryField,
 * }
 */
function calculateParentFieldLookup(fields: Array<CategoryField>): ParentFields {
    return fields.reduce<Record<string, any>>(
        (accumulator, field) => ({
            ...accumulator,
            [field.id]: getParentField(field, fields),
        }),
        {},
    );
}

/**
 * Calculates a map of categoryField ids to combinations that match selected value for that categoryField.
 *
 * @returns Dict {
 *     [attribute-id]: Array [Combination]
 * }
 */
function calculateMatchingCombinations(
    values: FormikValues,
    combinations: Array<CategoryFieldCombination>,
    fieldGroupIndex: FieldGroupIndex,
    attributeChoicesLookup: AttributeChoices,
): Array<Set<CategoryFieldCombination>> {
    const combinationsByGroupIndex = [new Set(combinations)];

    Object.keys(values).forEach((attribute) => {
        const value = values[attribute];
        if (!value || Array.isArray(value)) {
            return;
        }

        const groupIndex = fieldGroupIndex[attribute];
        const choice = attributeChoicesLookup[attribute]?.[value];
        if (!choice) {
            return;
        }

        const combinationsMatches = Array.from(
            getPreviousGroup(combinationsByGroupIndex, groupIndex),
        ).filter((combination) => combination.attributes.has(choice.id));

        combinationsByGroupIndex[groupIndex] = new Set([
            ...(combinationsByGroupIndex[groupIndex] ?? EMPTY_ARRAY),
            ...combinationsMatches,
        ]);
    });

    return combinationsByGroupIndex;
}

const useEnforceCategoryFieldCombination = (
    categoryID: number | null | undefined,
    fields: Array<CategoryField>,
    values: FormikValues,
): Array<FlatCategoryField> => {
    const combinations = useCategoryFieldCombinations(categoryID);

    const { fieldGroupIndex, attributeChoicesLookup, parentFieldLookup } = React.useMemo(
        () => ({
            fieldGroupIndex: calculateFieldGroupIndex(fields),
            attributeChoicesLookup: calculateAttributeChoicesLookup(fields),
            parentFieldLookup: calculateParentFieldLookup(fields),
        }),
        [fields],
    );

    const matchingCombinations = React.useMemo(
        () =>
            calculateMatchingCombinations(
                values,
                combinations,
                fieldGroupIndex,
                attributeChoicesLookup,
            ),
        [values, combinations, fieldGroupIndex, attributeChoicesLookup],
    );

    const choiceReducedFields = React.useMemo(
        () => fieldChoiceReducer(fields, matchingCombinations),
        [fields, matchingCombinations],
    );

    return React.useMemo(
        () =>
            flattenFieldsWithCombinations(
                choiceReducedFields,
                values,
                attributeChoicesLookup,
                parentFieldLookup,
            ),
        [choiceReducedFields, values, attributeChoicesLookup, parentFieldLookup],
    );
};

export default useEnforceCategoryFieldCombination;
