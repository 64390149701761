import { t } from '@lingui/macro';
import React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { useCategoryFieldChoices } from 'strat/categoryFields/hooks';
import { S3Api } from '@app/api';
import type { FormikValues } from 'formik';
import settings from '@app/branding/settings';
import { useRouter } from 'react-true-router';
import transformLocalizedFields from 'strat/mergedIndex/transformLocalizedFields';
import { Choice } from 'strat/components/types';

import { selectCategoryBySlug } from 'horizontal/categories';
import { GlobalState } from 'horizontal/state';

type LocationTree = {
    readonly cities: Record<string, { id: string; city_name: string; city_name_ar: string }>;
    readonly governates: Record<
        string,
        { id: string; governate_name: string; governate_name_ar: string; regions: string[] }
    >;
    readonly regions: Record<
        string,
        { id: string; region_name: string; region_name_ar: string; cities: string[] }
    >;
};

const sortFn = (a: Choice<any>, b: Choice<any>) => (a.label < b.label ? -1 : 1);

const useSellCarFormChoices = (values: FormikValues) => {
    const i18n = useI18n();
    const router = useRouter();
    const [locationTree, setLocationTree] = React.useState<LocationTree | null>(null);
    const carsForSaleCategory = useSelector((state: GlobalState) =>
        selectCategoryBySlug(state, 'cars-for-sale'),
    );

    const makeChoices = useCategoryFieldChoices('make', carsForSaleCategory?.id);
    const makeChoicesLocalized = makeChoices.map((choice: any) => ({
        ...choice,
        value: transformLocalizedFields(choice, i18n.locale, true).label,
    }));
    makeChoicesLocalized.sort(sortFn);

    const currentMake = makeChoices.find(
        (e) => transformLocalizedFields(e, i18n.locale, true).label === values['makeName'],
    );

    const modelChoicesLocalized = useCategoryFieldChoices('model', carsForSaleCategory?.id, {
        make: currentMake?.value,
    }).map((choice: any) => ({
        ...choice,
        value: transformLocalizedFields(choice, i18n.locale, true).label,
    }));
    modelChoicesLocalized.sort(sortFn);

    React.useEffect(() => {
        new S3Api()
            .fetchCashMyCarLocationTree()
            .then((response) => setLocationTree(response.data))
            .catch(() => {});
    }, [router]);

    const currentYear = new Date().getFullYear();
    const yearChoices = [
        ...Array(currentYear - (settings.cashMyCar.firstYearOption || currentYear) + 1).keys(),
    ].map((e): Choice<number> => ({ label: (currentYear - e).toString(), value: currentYear - e }));

    const isRegisteredChoices: Choice<string>[] = [
        { label: t(i18n)`Yes`, value: 'yes' },
        { label: t(i18n)`No`, value: 'no' },
    ];

    const governateChoices: Choice<string>[] = [];
    const regionsChoices: Choice<string>[] = [];
    const cityChoices: Choice<string>[] = [];

    if (locationTree) {
        for (const governateId in locationTree.governates) {
            governateChoices.push({
                label:
                    i18n.locale === 'en'
                        ? locationTree.governates[governateId].governate_name
                        : locationTree.governates[governateId].governate_name_ar,
                value: locationTree.governates[governateId].governate_name,
            });
        }

        if (values['governateName']) {
            const governateId = Object.keys(locationTree.governates).find(
                (key) => locationTree.governates[key].governate_name === values['governateName'],
            );
            if (governateId) {
                for (const regionId of locationTree.governates[governateId].regions) {
                    regionsChoices.push({
                        label:
                            i18n.locale === 'en'
                                ? locationTree.regions[regionId].region_name
                                : locationTree.regions[regionId].region_name_ar,
                        value: locationTree.regions[regionId].region_name,
                    });
                }
            }
        }

        if (values['regionName']) {
            const regionId = Object.keys(locationTree.regions).find(
                (key) => locationTree.regions[key].region_name === values['regionName'],
            );
            if (regionId) {
                for (const cityId of locationTree.regions[regionId].cities) {
                    cityChoices.push({
                        label:
                            i18n.locale === 'en'
                                ? locationTree.cities[cityId].city_name
                                : locationTree.cities[cityId].city_name_ar,
                        value: locationTree.cities[cityId].city_name,
                    });
                }
            }
        }
    }

    return {
        makeChoices: makeChoicesLocalized,
        modelChoices: modelChoicesLocalized,
        yearChoices,
        isRegisteredChoices,
        governateChoices,
        regionsChoices,
        cityChoices,
    };
};
export default useSellCarFormChoices;
