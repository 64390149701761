import * as React from 'react';
import { ViewSections } from 'strat/gtm';

import { HitList } from 'horizontal/search/results';
import { useRemarketedListings } from 'horizontal/search/state';
import SearchHitsLayout from 'horizontal/branding/searchHitsLayout';

const RemarketingAd = () => {
    const hits = useRemarketedListings();

    return (
        <HitList
            viewType={SearchHitsLayout.LIST}
            hits={hits}
            viewSection={ViewSections.SEARCH_RESULTS}
            highlighted
        />
    );
};

export default RemarketingAd;
