import * as React from 'react';
import classNames from 'classnames';

import type { ChatMessageImagesContent } from 'horizontal/chat/types';

import ImagePreview from './imagePreview';
import styles from './styles/imagesMessage.cssm';

type Props = {
    readonly images: ChatMessageImagesContent;
    readonly className: string;
    readonly renderFooter: () => React.ReactElement;
    readonly onClick: (index: number) => void;
};

const MAX_IMAGES_SHOW = 3;

const renderImagePreview = ({
    images,
    count,
    onClick,
}: {
    images: ChatMessageImagesContent;
    count: number;
    onClick: (index: number) => void;
}) => {
    if (!images.length) {
        return <ImagePreview imageUrl={null} />;
    }

    return images.map((image, index) => (
        <ImagePreview
            onClick={() => {
                onClick(index);
            }}
            key={image?.url ?? index.toString()}
            imageUrl={image?.url}
            moreImageCount={index + 1 === MAX_IMAGES_SHOW ? count : undefined}
        />
    ));
};

const ImagesMessage = ({ images, className, renderFooter, onClick }: Props) => {
    const isTruncated = React.useMemo(() => images.length > MAX_IMAGES_SHOW, [images]);
    const truncatedImagesCount = React.useMemo(
        () => Math.max(0, images.length - MAX_IMAGES_SHOW),
        [images],
    );
    const displayableImages = React.useMemo(
        () => (isTruncated ? images.slice(0, MAX_IMAGES_SHOW) : images),
        [images, isTruncated],
    );

    return (
        <div className={classNames(styles.container, className)} aria-label="Images message">
            <div className={styles.imagesContainer}>
                {renderImagePreview({
                    images: displayableImages,
                    count: truncatedImagesCount,
                    onClick,
                })}
            </div>
            <div className={styles.footer}>{renderFooter()}</div>
        </div>
    );
};

export default React.memo<Props>(ImagesMessage);
