import * as React from 'react';
import { StylelessButton } from 'strat/generic';
import { Flex } from 'strat/components';

import IconClose from 'horizontal/assets/icons/iconCloseThick.svg';

import styles from './styles/example.cssm';

export type RenderIconProps = {
    readonly onClick: () => void;
};

const defaultRenderIcon = ({ onClick }: RenderIconProps) => (
    <StylelessButton onClick={onClick} className={styles.closeButton}>
        <IconClose className={styles.closeIcon} />
    </StylelessButton>
);

type Props = {
    readonly src: string;
    readonly alt: string;
    readonly onClick: () => void;
    readonly renderIcon?: (arg1: RenderIconProps) => React.ReactElement;
    readonly children?: React.ReactNode;
};

const Example = ({ src, alt, onClick, renderIcon = defaultRenderIcon, children }: Props) => (
    <Flex className={styles.container} column onClick={onClick}>
        <div className={styles.header}>{renderIcon && renderIcon({ onClick })}</div>
        <Flex className={styles.content} justifyCenter>
            <img className={styles.image} src={src} alt={alt} />
            {children}
        </Flex>
    </Flex>
);

export default Example;
