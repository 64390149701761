import haversine from 'haversine-distance';
import * as React from 'react';
import autoBind from 'react-autobind';
import type { I18n } from '@lingui/core';
import isEqual from 'lodash/isEqual';
import bbox from '@turf/bbox';
import { point, featureCollection } from '@turf/helpers';

import { Script, makeCancelable, Stylesheet } from 'strat/util';
import type { CancelablePromise } from 'strat/util';
import { useI18n } from 'strat/i18n/language';
import { Triggers } from 'strat/gtm';

import MapStyleTypes, { MAPBOX_OWNER, DEFAULT_MAPBOX_OWNER } from './mapStyles';
import type { Geoloc, MarkerProps } from './types';
import Marker from './marker';
import styles from './styles/mapbox.cssm';
import isCustomID from './customElements';
import { ControlsPosition } from './types';

const MAPBOX_LANGUAGE_PLUGIN_URL =
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-language/v0.10.0/mapbox-gl-language.js';
const MAPBOX_RTL_SCRIPT_URL =
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js';

const SUPPORTED_LANGUAGES = ['ar', 'en'];

export const MapProviders: {
    [key: string]: string;
} = Object.freeze({
    MAPBOX: 'mapbox',
    GEOAPIFY: 'geoapify',
});

declare let mapboxgl: MapboxGLGlobal;

export const mapProviderProperties = {
    mapbox: {
        accessToken: CONFIG.build.MAPBOX_API_KEY,
        style: (id: string, owner = 'mapbox') =>
            `https://api.mapbox.com/styles/v1/${owner}/${id}?access_token=${CONFIG.build.MAPBOX_API_KEY}`,
        loadMapStyle: (id: string, owner = 'mapbox') =>
            mapProviderProperties.mapbox.style(id, owner),
        loadMap: () =>
            Script.load('https://api.tiles.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.js').then(
                () => mapboxgl,
            ),
        STYLESHEET_URL:
            'https://api.tiles.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css?optimize=true',
    },
    geoapify: {
        style: () => '',
        loadMapStyle: () =>
            import(/* webpackChunkName: 'geoapifyMapStyle' */ './json/geoapifyMapStyle').then(
                (style) => style.default,
            ),
        STYLESHEET_URL: '/static-assets/css/maplibre-gl-2.4.0.css',
        loadMap: () => import(/* webpackChunkName: 'maplibreGL' */ 'maplibre-gl'),
    },
} as const;

const getMarkers = (
    nodes?: React.ReactElement<any> | Array<React.ReactElement<any>>,
): MarkerProps[] => {
    if (!nodes) {
        return [];
    }
    const potentialMarkers: Array<React.ReactElement<any>> = Array.isArray(nodes) ? nodes : [nodes];
    const markers: Array<MarkerProps> = [];

    potentialMarkers.forEach((node) => {
        if (node && node.props && node.type === Marker) {
            markers.push({
                // @ts-expect-error - TS2322 - Type 'Key | null' is not assignable to type 'string | null'.
                key: node.key,
                position: node.props.position,
                autoFit: node.props.autoFit,
            });
        }
    });

    return markers;
};

type Props = {
    /**
     * The current zoom level for map.
     */
    defaultZoom: number;
    /**
     * The current center of the map.
     */
    defaultCenter: Geoloc;
    /**
     * The initial map bounds.
     * This help to "start-up" the map at a desired location
     * specified by bounds not by Geolocation and zoom.
     * Only matters at map initialization.
     */
    defaultBounds?: [Geoloc, Geoloc] | ReadonlyArray<Geoloc>;
    /**
     * Component(s) to be renders on the map.
     */
    children?: any;
    /**
     * The style of the map. Currently we use the Streets style.
     * We can create custom style on mapbox studio and use it.
     */
    mapStyle?: Values<typeof MapStyleTypes>;
    /**
     * Callback to be called everytime a user drag or zoom the map.
     */
    onMapMoveEnd?: (center: Geoloc, radius: number) => void;
    /**
     * Callback to be called when the map fully loads.
     */
    onMapLoad?: (map: MapType) => void;
    /**
     * Callback to be called when the map is clicked.
     */
    onMapClick?: (e: any, map?: MapType | null | undefined) => void;
    /**
     * Callback to be called when the map is right clicked.
     */
    onMapContextMenu?: (e: any, map?: MapType | null | undefined) => void;
    /**
     * A ratio used to compute a circular area around the
     * center of the map in order to get places inside this area.
     *
     * We multiply this ratio with the distance between the center of the map and one of the corners.
     */
    distanceRatio?: number;
    /**
     * i18n for current language
     */
    i18n?: I18n;
    /**
     * Whether an attribution control widget should be displayed by default.
     */
    attributionControl?: boolean;
    /**
     * The position of the MapBox logo. Valid options are `top-left`, `top-right`, `bottom-left` or `bottom-right`
     */
    logoPosition?: Values<typeof ControlsPosition>;
    /**
     * The maximum zoom level of the map (0-24). Default: 22
     */
    maxZoom?: number;
    /**
     * The minimum zoom level of the map (0-24). Default: 0
     */
    minZoom?: number;
    /**
     * The navigable bounds.
     */
    maxBounds?: [Geoloc, Geoloc];
    /**
     * Toggle Map controls
     */
    controlsEnabled?: number;
    /**
     * Mapbox owner account, used when fetching styles
     */
    mapboxOwnerAccount?: string;
    onMapMove?: (center: Geoloc) => void;
    mapProvider?: Values<typeof MapProviders>;
    fitMapToMarkersOptions?: FitBoundsOptions;
};

type State = {
    map: MapType | null | undefined;
};

class Map extends React.Component<Props, State> {
    /**
     * The element in which the map gets rendered.
     */
    element: HTMLElement | null | undefined;

    /**
     * Promise loading the Google Maps script
     */
    promise: CancelablePromise | null | undefined;
    loadStylePromise: CancelablePromise | null | undefined;
    mapboxGL: MapboxGLGlobal | null | undefined;

    static defaultProps = {
        mapStyle: MapStyleTypes.STRAT,
        mapboxOwnerAccount: MAPBOX_OWNER,
        distanceRatio: 0.7,
        attributionControl: true,
        logoPosition: ControlsPosition.BOTTOM_LEFT,
        mapProvider: MapProviders.MAPBOX,
        fitMapToMarkersOptions: { padding: 64, maxZoom: 15 },
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);
        this.state = { map: null };

        this.promise = null;
        this.loadStylePromise = null;
        this.mapboxGL = null;
    }

    provider() {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly mapbox: { readonly accessToken: string; readonly style: (id: string, owner?: string) => string; readonly loadMapStyle: (id: string, owner?: string) => string; readonly loadMap: () => Promise<MapboxGLGlobal>; readonly STYLESHEET_URL: "https://api.tiles.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css?optimize=...'.
        return mapProviderProperties[this.props.mapProvider || MapProviders.MAPBOX];
    }

    initializeMap(globalInstance: MapboxGLGlobal, mapRenderingStyle: string | any) {
        const {
            defaultCenter,
            defaultZoom,
            defaultBounds,
            onMapMoveEnd,
            onMapLoad,
            distanceRatio,
            logoPosition,
            attributionControl,
            maxZoom,
            minZoom,
            maxBounds,
            controlsEnabled,
            onMapMove,
        } = this.props;

        this.mapboxGL = globalInstance;

        this.mapboxGL.accessToken = this.provider().accessToken;

        // @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
        const map = new this.mapboxGL.Map({
            container: this.element,
            center: defaultCenter,
            bounds: defaultBounds,
            zoom: defaultZoom,
            minZoom: minZoom || 0,
            maxZoom: maxZoom || 22,
            style: mapRenderingStyle,
            attributionControl,
            logoPosition,
            maxBounds,
        });

        // Needed in order to enable canvas recording for Smartlook.
        map.getCanvas().dataset.sl = 'canvas-mq';

        // The int to toggle map controls (0: disable, 1: enable). Default: 1
        if (controlsEnabled === 0) {
            map.scrollZoom.disable();
            map.dragPan.disable();
            map.doubleClickZoom.disable();
            map.touchZoomRotate.disable();
        }
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        if (SUPPORTED_LANGUAGES.includes(this.props.i18n.locale)) {
            try {
                this.mapboxGL.setRTLTextPlugin(MAPBOX_RTL_SCRIPT_URL);
            } catch (err: any) {
                // setRTLTextPlugin throws an exception when it gets called a second time
            }
            map.addControl(
                new MapboxLanguage({
                    // $FlowFixMe i18n is always initialized
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    defaultLanguage: this.props.i18n.locale,
                }),
            );
        }
        this.setState({ map });
        if (onMapMoveEnd) {
            map.on('moveend', () => {
                const center = map.getCenter();
                const northEast = map.getBounds().getNorthEast();
                const radius = haversine(center, northEast);
                // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                return onMapMoveEnd(center, distanceRatio * Math.floor(radius));
            });
        }

        if (onMapMove) {
            map.on('move', () => {
                return onMapMove(map.getCenter());
            });
        }

        if (onMapLoad) {
            map.on('load', () => onMapLoad(map));
        }

        map.on('click', this.onMapClick);

        map.on('contextmenu', this.onMapContextMenu);

        if (CONFIG.build.STRAT_ENABLE_UPDATED_SEARCH_TRACKING) {
            map.on('touchend', () => {
                window.dispatchEvent(new Event(Triggers.SEARCH_TRIGGERING_EVENT));
            });
        }

        this.fitMapToMarkers();
    }

    componentDidMount() {
        if (!CONFIG.build.MAPBOX_API_KEY || CONFIG.runtime.DISABLE_MAPBOX_API) {
            return;
        }

        const { mapStyle, mapboxOwnerAccount } = this.props;

        this.promise = makeCancelable(
            Promise.all([
                this.provider().loadMap(),
                this.provider().loadMapStyle(mapStyle, mapboxOwnerAccount),
                Stylesheet.load(this.provider().STYLESHEET_URL),
                Script.load(MAPBOX_LANGUAGE_PLUGIN_URL),
            ]),
        );

        this.promise.then(([globalInstance, mapRenderStyle]: [any, any]) => {
            this.initializeMap(globalInstance, mapRenderStyle);
        });
    }

    componentWillUnmount() {
        Stylesheet.remove(this.provider().STYLESHEET_URL);

        if (this.promise) {
            this.promise.cancel();
        }
        if (this.loadStylePromise) {
            this.loadStylePromise.cancel();
        }

        const { map } = this.state;

        if (map) {
            map.remove();
        }
    }

    updateStyle = (mapStyle: Values<typeof MapStyleTypes>) => {
        const { map } = this.state;
        const mapboxOwnerAccount =
            mapStyle !== MapStyleTypes.STRAT ? DEFAULT_MAPBOX_OWNER : this.props.mapboxOwnerAccount;

        if (!map) {
            return;
        }

        this.loadStylePromise = makeCancelable(
            fetch(this.provider().style(mapStyle, mapboxOwnerAccount)),
        );
        this.loadStylePromise
            // @ts-expect-error - TS7006 - Parameter 'response' implicitly has an 'any' type.
            .then((response) => response.json())
            // @ts-expect-error - TS7006 - Parameter 'jsonStyle' implicitly has an 'any' type.
            .then((jsonStyle) => {
                const prevStyle = map.getStyle();
                // @ts-expect-error - TS2339 - Property 'layers' does not exist on type 'Object'. | TS7006 - Parameter 'layer' implicitly has an 'any' type.
                const persistentLayers = prevStyle.layers.filter((layer) => isCustomID(layer.id));
                // @ts-expect-error - TS2339 - Property 'sources' does not exist on type 'Object'.
                Object.keys(prevStyle.sources)
                    .filter((sourceName) => isCustomID(sourceName))
                    .forEach((sourceName) => {
                        // @ts-expect-error - TS2339 - Property 'sources' does not exist on type 'Object'.
                        jsonStyle.sources[sourceName] = prevStyle.sources[sourceName];
                    });
                jsonStyle.layers = [...jsonStyle.layers, ...persistentLayers];
                map.setStyle(jsonStyle);
            });
    };

    onMapClick(e: any) {
        if (this.props.onMapClick) {
            const { map } = this.state;
            this.props.onMapClick(e, map);
        }
    }

    onMapContextMenu(e: any) {
        if (this.props.onMapContextMenu) {
            const { map } = this.state;
            this.props.onMapContextMenu(e, map);
        }
    }

    fitMapToMarkers(prevProps?: Props) {
        const { map } = this.state;

        // Get the markers from the Mapbox component's children
        const markers = getMarkers(this.props.children);
        const prevMarkers = prevProps && prevProps.children ? getMarkers(prevProps.children) : [];

        // Don't re-fit the map if the user just clicks it or triggers a re-render
        if (isEqual(markers, prevMarkers)) {
            return;
        }

        if (markers) {
            const markersPolygon: Array<any> = [];
            // Get the bounding box from the autoFit markers
            markers.forEach((marker) => {
                if (marker && marker.position.lng && marker.position.lat && marker.autoFit) {
                    markersPolygon.push(point([marker.position.lng, marker.position.lat]));
                }
            });
            if (map && markersPolygon && markersPolygon.length) {
                const pointsCollection = featureCollection(markersPolygon);
                const boundingBox = bbox(pointsCollection) as BoundsArray;

                map.fitBounds(boundingBox, this.props.fitMapToMarkersOptions);
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { map } = this.state;
        if (prevProps.mapStyle !== this.props.mapStyle && map) {
            // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
            this.updateStyle(this.props.mapStyle);
        }
        this.fitMapToMarkers(prevProps);
    }

    render() {
        return (
            <div
                className={styles.container}
                ref={(element) => {
                    this.element = element;
                }}
            >
                {this.state.map &&
                    React.Children.map(this.props.children, (child) => {
                        if (!child) {
                            return child;
                        }

                        return React.cloneElement(child, {
                            map: this.state.map,
                            mapboxGL: this.mapboxGL,
                        });
                    })}
            </div>
        );
    }
}

export default React.forwardRef<any, Props>((props, ref) => {
    const i18n = useI18n();
    return <Map ref={ref} i18n={i18n} {...props} />;
});

export { Map as MapComponent };
