import {
    SearchRequestFacetType,
    SearchRequestFacetsOption,
} from '@sector-labs/fe-search-redux/backend';
import { ExactFilter } from '@sector-labs/fe-search-redux/filters';

const createCategoryFreeTextFacetingConfig = (freeTextQuery: string): SearchRequestFacetsOption => {
    return freeTextQuery
        ? [
              {
                  type: SearchRequestFacetType.SIMPLE,
                  attribute: 'category.lvl0.externalID',
                  filters: [
                      new ExactFilter({
                          attribute: 'count',
                          value: 5,
                      }).serialize(),
                  ],
              },
          ]
        : [];
};

export default createCategoryFreeTextFacetingConfig;
