import type { UserRole } from 'strat/user/roles';

import type { LoginType } from './types';

export interface AuthenticationContextState {
    languageCode: string | null;
    keycloakAccessToken: string | null;
    expressSessionCookie: string | null;
    mpaCDCookie: string | null;
    api6UserKey: string | null;
    userExternalID: string | null;
    userEmail: string | null;
    userRoles: UserRole[] | null;
}

export interface AuthenticationState {
    context: AuthenticationContextState;
    loginType: LoginType | null;
}

enum Actions {
    ADD_CONTEXT = 'AUTHENTICATION/ADD_CONTEXT',
    CLEAR_CONTEXT = 'AUTHENTICATION/CLEAR_CONTEXT',
    SET_LOGIN_TYPE = 'AUTHENTICATION/SET_LOGIN_TYPE',
}

type AddAuthenticationContextAction = {
    type: Actions.ADD_CONTEXT;
    context: Partial<AuthenticationContextState>;
};

type ClearAuthenticationContextAction = {
    type: Actions.CLEAR_CONTEXT;
};

type SetAuthenticationLoginType = {
    type: Actions.SET_LOGIN_TYPE;
    loginType: LoginType | null;
};

const defaultState: AuthenticationState = {
    context: {
        languageCode: null,
        keycloakAccessToken: null,
        expressSessionCookie: null,
        mpaCDCookie: null,
        api6UserKey: null,
        userExternalID: null,
        userEmail: null,
        userRoles: null,
    },
    loginType: null,
};

const authenticationReducer = (
    state: AuthenticationState = defaultState,
    action?:
        | AddAuthenticationContextAction
        | ClearAuthenticationContextAction
        | SetAuthenticationLoginType,
): AuthenticationState => {
    switch (action?.type) {
        case Actions.ADD_CONTEXT:
            return {
                ...state,
                context: {
                    ...state.context,
                    ...action.context,
                },
            };

        case Actions.CLEAR_CONTEXT:
            return {
                ...state,
                context: defaultState.context,
            };

        case Actions.SET_LOGIN_TYPE:
            return {
                ...state,
                loginType: action.loginType,
            };

        default:
            return state;
    }
};

export const addAuthenticationContext = (
    context: Partial<AuthenticationContextState>,
): AddAuthenticationContextAction => ({
    type: Actions.ADD_CONTEXT,
    context,
});

export const clearAuthenticationContext = (): ClearAuthenticationContextAction => ({
    type: Actions.CLEAR_CONTEXT,
});

export const setAuthenticationLoginType = (
    loginType: LoginType | null,
): SetAuthenticationLoginType => ({
    type: Actions.SET_LOGIN_TYPE,
    loginType: loginType,
});

export const clearAuthenticationLoginType = (): SetAuthenticationLoginType => ({
    type: Actions.SET_LOGIN_TYPE,
    loginType: null,
});

export default authenticationReducer;
