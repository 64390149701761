import { trigger, Triggers, ViewSections } from 'strat/gtm';

import type { MixedAd } from 'horizontal/types';
import { AdFormat } from 'horizontal/types';

import { getAdVars } from './utils';

const trackNotificationClick = () =>
    trigger(Triggers.OPEN_NOTIFICATIONS, {
        view_section: ViewSections.HEADER,
    });

const trackLoginClick = () =>
    trigger(Triggers.CLICK_LOGIN, {
        view_section: ViewSections.LOGIN_DIALOG,
    });

const trackProfileChanges = (change: string) => {
    trigger(Triggers.EDIT_PROFILE_SAVE_CHANGES, {
        filer_name: 'profile_changes',
        filter_value: change,
        view_section: ViewSections.BODY,
    });
};

const trackReportUser = (ad: MixedAd, reportReason: string) => {
    trigger(Triggers.REPORT_ABUSE, {
        ...getAdVars(ad),
        number_of_photos: ad.format === AdFormat.FULL ? ad?.photos?.length : 0,
        filter_name: 'chat-report-reason',
        filter_value: reportReason,
        view_section: ViewSections.HEADER,
    });
};

const trackBlockUser = (ad: MixedAd, isBlocked: boolean) => {
    trigger(Triggers.BLOCK_CHAT, {
        ...getAdVars(ad),
        number_of_photos: ad.format === AdFormat.FULL ? ad?.photos?.length : 0,
        filter_name: 'chat-action',
        filter_value: isBlocked ? 'blocked' : 'unblocked',
        view_section: ViewSections.HEADER,
    });
};

const trackOpenAccount = () => {
    trigger(Triggers.OPEN_ACCOUNT, {
        view_section: ViewSections.USER_DETAIL,
    });
};

const trackUnlinkSocialAccount = (provider: string, isSuccess: boolean) => {
    trigger(Triggers.UNLINK_SOCIAL_PROVIDER, {
        name: provider,
        value: isSuccess ? 'Success' : 'Fail',
    });
};

export {
    trackNotificationClick,
    trackLoginClick,
    trackProfileChanges,
    trackReportUser,
    trackBlockUser,
    trackOpenAccount,
    trackUnlinkSocialAccount,
};
