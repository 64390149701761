import cookie from 'cookie';

import { CookieNames } from 'strat/cookies';

const getDeeplinkCookie = () => {
    const cookies = cookie.parse(document.cookie);
    const currentCookie = parseInt(cookies[CookieNames.DEEPLINK_DISMISSED], 10);
    if (Number.isNaN(currentCookie)) {
        return 0;
    }
    return currentCookie;
};

const setDeeplinkCookie = (newCookieValue = 0) => {
    if (process.env.IS_BROWSER) {
        document.cookie = cookie.serialize(
            CookieNames.DEEPLINK_DISMISSED,
            newCookieValue.toString(),
            {
                path: '/',
                maxAge: 365 * 24 * 3600,
                secure: window.location.protocol === 'https',
                httpOnly: false,
                sameSite: true,
            },
        );
    }
};

const resetDeeplinkCookieToZero = () => {
    setDeeplinkCookie(0);
};

const incrementDeeplinkCookieByOne = () => {
    setDeeplinkCookie(getDeeplinkCookie() + 1);
};

export {
    getDeeplinkCookie,
    resetDeeplinkCookieToZero,
    incrementDeeplinkCookieByOne,
    setDeeplinkCookie,
};
