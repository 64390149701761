import { t } from '@lingui/macro';
import * as React from 'react';

import { useI18n } from 'strat/i18n/language';
import { Text, Flex } from 'strat/components';
import iconNotFoundPNG from '@app/assets/icons/iconNotFound.png';
import iconNotFoundWEBP from '@app/assets/icons/iconNotFound.webp';

import styles from './styles/notFoundError.cssm';

const NotFoundError = () => {
    const i18n = useI18n();

    return (
        <Flex alignCenter className={styles.container}>
            <div className={styles.left}>
                <h1 className={styles.title}>{t(i18n)`Oops!`}</h1>
                <span className={styles.subtitle}>
                    {t(i18n)`We can't seem to find that.`}
                    <br />
                    {t(i18n)`Try searching for it`}
                </span>
                <div className={styles.bottom}>
                    <Text.Base className={styles.error}>{t(i18n)`Error 404`}</Text.Base>
                </div>
            </div>
            <picture>
                <source srcSet={iconNotFoundWEBP} type="image/webp" />
                <source srcSet={iconNotFoundPNG} type="image/png" />
                <img src={iconNotFoundPNG} alt={t(i18n)`Not found`} className={styles.icon} />
            </picture>
        </Flex>
    );
};

export default NotFoundError;
