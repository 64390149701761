import React from 'react';
import ReactLoadable, { type LoadableOptions } from '@sector-labs/react-loadable-revised';
import ErrorBoundary, { withErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { Trans } from '@lingui/macro';

import { logError } from 'strat/error/log';

import Page from './page';
import PreserveServerSideRendering from './preserveServerSideRendering';

type LoadingProps = {
    error?: Error;
    retry?: () => any;
};

type Module<ModuleProps> = {
    default: React.ComponentType<ModuleProps>;
};

type OwnProps<LoadingComponentProps> = {
    fallback?: React.ComponentType<FallbackProps>;
    loadingFallback?: React.ComponentType<FallbackProps>;
    loading?: React.ComponentType<LoadingComponentProps>;
};

type LoadableConfig<T, P, LoadingComponentProps> = LoadableOptions<T, P> &
    OwnProps<LoadingComponentProps>;

/*
 * Load a component async and display a customizable
 * fallback if the component crashes.
 */
const Loadable = <T, P, M extends Module<P> & T, LoadingComponentProps>(
    loadableConfig: LoadableConfig<T, P, LoadingComponentProps>,
) => {
    const FallbackComponent = loadableConfig.fallback ? loadableConfig.fallback : () => <div />;

    const handleError = (error: Error, componentStack: string) =>
        logError({
            e: error,
            msg: 'Failed to load component async',
            context: {
                stack: componentStack,
            },
        });

    const defaultLoadingFallback = () => (
        <div>
            <Trans>Sorry, This section is not available at the moment</Trans>
        </div>
    );

    const loadingFallbackComp = loadableConfig.loadingFallback
        ? loadableConfig.loadingFallback
        : defaultLoadingFallback;

    const loadingComp = loadableConfig.loading;
    const interceptedLoadingComp = (props: LoadingComponentProps & LoadingProps) => {
        if (props.error) {
            return React.createElement(loadingFallbackComp, props);
        }

        return (
            <PreserveServerSideRendering>
                {React.createElement(loadingComp)}
            </PreserveServerSideRendering>
        );
    };

    // @ts-expect-error loadableConfig.loading expects a component with a `retry()` function but
    // we're not usually sending it, and I don't think it should be mandatory anyway
    loadableConfig.loading = withErrorBoundary(
        interceptedLoadingComp,
        loadingFallbackComp,
        handleError,
    );

    return ReactLoadable({
        render: (loaded: M, props: P) => (
            <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
                {React.createElement(loaded.default, props)}
            </ErrorBoundary>
        ),
        ...loadableConfig,
    });
};

Loadable.Page = Page;

export default Loadable;
