import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';

import type { RouteParameters } from 'react-true-router/route';
import { useRedirect } from 'strat/util';
import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { InternalError } from 'strat/error';
import { KeycloakAuthDialog } from 'strat/auth/keycloak';

type Props = {
    redirectRouteName?: string;
    redirectParams?: RouteParameters;
    externalRedirectPath?: string;
};

const Head = () => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <>
            <title>{t(i18n)`Login | ${brandName}`}</title>
            <meta name="robots" content="noindex, nofollow" />
        </>
    );
};

const Dialog = ({ externalRedirectPath, redirectRouteName, redirectParams }: Props) => {
    const { redirect, goBack } = useRedirect({
        externalRedirectPath,
        redirectParams,
        redirectRouteName,
    });

    return (
        <KeycloakAuthDialog
            visible
            dismissible={false}
            withOverlay={!externalRedirectPath}
            onDone={redirect}
            onCancel={goBack}
        />
    );
};

const Fallback = () => {
    const i18n = useI18n();

    return (
        <InternalError
            title={t(i18n)`Authentication required`}
            message={t(i18n)`You are not authorized to view this page.`}
        />
    );
};

const LoginPage = {
    head: () => <Head />,
    body: ({ externalRedirectPath, redirectRouteName, redirectParams }: Props) => (
        <>
            <Dialog
                redirectRouteName={redirectRouteName}
                redirectParams={redirectParams}
                externalRedirectPath={externalRedirectPath}
            />
            {!externalRedirectPath && (
                <Flex alignCenter justifyCenter stretchWidth stretchHeight column>
                    <Fallback />
                </Flex>
            )}
        </>
    ),
} as const;

export default LoginPage;
