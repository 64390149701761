import * as React from 'react';
import autoBind from 'react-autobind';

import MoreLessButton from './moreLessButton';
import Expandable from './expandable';

type Props = {
    readonly height: number;
    readonly textMore: string;
    readonly textLess: string;
    readonly children: React.ReactNode;
    readonly className?: string;
    readonly buttonClassName?: string;
    readonly scrollToElementTop: (arg1: HTMLDivElement) => void;
    readonly showMoreLessButton?: boolean;
    readonly arrowClassName?: string;
    readonly overlayStyle?: string;
    readonly hrSeparatorClassName?: string | null | undefined;
    readonly expanded: boolean;
    readonly onButtonClick?: () => void;
    readonly setExpanded: (expanded: boolean) => void;
};

type State = {
    readonly showMoreLessButton: boolean;
};

class CollapsibleContent extends React.Component<Props, State> {
    element: HTMLDivElement | null;
    observer: MutationObserver | null;

    static defaultProps = {
        showMoreLessButton: true,
        expanded: false,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.state = { showMoreLessButton: false };
        this.element = null;
        this.observer = null;
    }

    toggleState() {
        this.props.setExpanded(!this.props.expanded);

        if (this.element && this.props.expanded) {
            this.props.scrollToElementTop(this.element);
        }
    }

    onButtonClick() {
        this.toggleState();
        this.props.onButtonClick?.();
    }

    setRef(element: HTMLDivElement | null): HTMLDivElement | null {
        this.element = element;

        if (element) {
            this.observer = new MutationObserver(this.addMoreLessButtonIfNeeded);
            this.observer.observe(element, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        } else if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }

        return element;
    }

    addMoreLessButtonIfNeeded() {
        const clientHeight = this.element ? this.element.clientHeight : 0;
        if (clientHeight > this.props.height && this.props.showMoreLessButton) {
            this.setState({ showMoreLessButton: true });
        } else {
            this.setState({ showMoreLessButton: false });
        }
    }

    componentDidMount() {
        this.addMoreLessButtonIfNeeded();
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    showMoreLessButton() {
        if (this.state.showMoreLessButton) {
            return (
                <MoreLessButton
                    key="moreLess"
                    textMore={this.props.textMore}
                    textLess={this.props.textLess}
                    expanded={this.props.expanded}
                    onClick={this.onButtonClick}
                    className={this.props.buttonClassName}
                    arrowClassName={this.props.arrowClassName}
                />
            );
        }

        return null;
    }

    render() {
        const moreLessButton = this.showMoreLessButton();
        const divSeparator =
            !this.state.showMoreLessButton && this.props.hrSeparatorClassName ? (
                <div style={{ height: '4rem' }} />
            ) : null;
        const hrSeparator = this.props.hrSeparatorClassName ? (
            <hr className={this.props.hrSeparatorClassName} />
        ) : null;

        return (
            <>
                <Expandable
                    key="expandable"
                    expanded={this.props.expanded}
                    height={`${this.props.height}px`}
                    className={this.props.className}
                >
                    <div ref={this.setRef}>
                        {!this.props.expanded &&
                            this.props.overlayStyle &&
                            this.state.showMoreLessButton && (
                                <div className={this.props.overlayStyle} />
                            )}
                        {React.Children.toArray(this.props.children)}
                    </div>
                </Expandable>
                {moreLessButton}
                {divSeparator}
                {hrSeparator}
            </>
        );
    }
}

type UncontrolledCollapsibleContentProps = Omit<Props, 'setExpanded' | 'expanded'> & {
    expanded?: boolean;
};

const UncontrolledCollapsibleContent = (props: UncontrolledCollapsibleContentProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(props.expanded || false);
    return (
        <CollapsibleContent {...props} expanded={expanded} setExpanded={setExpanded}>
            {props.children}
        </CollapsibleContent>
    );
};

export { CollapsibleContent };
export default UncontrolledCollapsibleContent;
