import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { matchValueInChoices } from 'strat/util';
import type { ChoiceValue } from 'strat/components/types';
import { Input as BaseInput } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { CategoryFieldGroupedChoices } from 'strat/types/categoryFields';

import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldValueType, CategoryFieldRole } from 'horizontal/types';
import useIsImpersonated from 'horizontal/adUpdating/hooks/useIsImpersonated';

import { useApplyFieldCombinations } from '../categoryFieldCombinations';

import styles from './styles/field.cssm';
import EnumInput from './enumInput';

type Props = {
    readonly field: FlatCategoryField;
    readonly values: FormikValues;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly setFieldValue: (arg1: string, arg2: string | ChoiceValue[]) => void;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly isEditMode?: boolean;
    readonly warnings?: Record<string, string>;
    readonly title?: React.ReactNode;
    readonly placeholder?: string;
    readonly radioThreshold?: number;
};

const Field = ({
    field,
    touched,
    values,
    errors,
    setFieldValue,
    onBlur,
    isEditMode,
    warnings = {},
    title: fieldTitle,
    placeholder: fieldPlaceholder,
    radioThreshold = 3,
}: Props) => {
    const i18n = useI18n();
    const [autoFillValues, setAutoFilledValues] = React.useState<ChoiceValue[]>([]);
    const errorMessage = touched[field.attribute] && errors[field.attribute];
    const accepted = touched[field.attribute] && !errors[field.attribute];
    const currentValue = values[field.attribute];
    const isImpersonated = useIsImpersonated();

    const readOnly =
        !isImpersonated && isEditMode && field.roles?.includes(CategoryFieldRole.NON_EDITABLE);

    React.useEffect(() => {
        if (currentValue && field.choices && !matchValueInChoices(currentValue, field.choices)) {
            setFieldValue(field.attribute, '');
        }
    }, [setFieldValue, field.attribute, field.choices, currentValue]);

    useApplyFieldCombinations(field, values, setFieldValue, setAutoFilledValues);

    const onChange = React.useCallback(
        (value) => {
            setFieldValue(field.attribute, value);
            setAutoFilledValues([]);
        },
        [setFieldValue, field.attribute],
    );

    const hasChoices = field.choices?.length;
    const hasGroupChoices = field?.choiceGroups && Object.keys(field?.choiceGroups).length;
    const isNumber = [CategoryFieldValueType.INTEGER, CategoryFieldValueType.FLOAT].includes(
        field.valueType,
    );
    if ((!hasChoices && !hasGroupChoices) || isNumber) {
        const type = isNumber ? 'number' : 'text';
        let title = field.name || field.attribute;
        title = field.isMandatory ? title.concat('*') : title;
        const placeholder = title ? t(i18n)`Enter ${title.toLowerCase()}` : '';
        const autofilledValue = autoFillValues?.[0] as string;
        return (
            <BaseInput
                title={fieldTitle || title}
                placeholder={fieldPlaceholder || placeholder}
                name={field.attribute}
                value={values[field.attribute] ?? autofilledValue}
                type={type}
                errorMessage={errorMessage}
                onChange={onChange}
                onBlur={onBlur}
                className={autoFillValues.length > 0 ? styles.autofilled : undefined}
                accepted={accepted}
                readOnly={readOnly}
                leftSideLabel
                lightPlaceholder
                boldLabel
                warningMessage={warnings[field.attribute]}
            />
        );
    }

    const choiceGroupAllChoices = Object.values(field?.choiceGroups ?? {})?.flatMap(
        (choiceGroup: CategoryFieldGroupedChoices) => choiceGroup?.choices?.all,
    );

    const fieldWithAllChoices: FlatCategoryField = {
        ...field,
        choices: field.choices ?? choiceGroupAllChoices,
    };

    const title = fieldWithAllChoices.name || fieldWithAllChoices.attribute;
    const placeholder = title ? t(i18n)`Select ${title.toLowerCase()}` : '';

    return (
        <EnumInput
            key={`${fieldWithAllChoices.id}-${
                fieldWithAllChoices.combinationChoices?.length ?? ''
            }`}
            value={values[fieldWithAllChoices.attribute]}
            field={fieldWithAllChoices}
            onChange={onChange}
            accepted={accepted}
            errorMessage={errorMessage}
            onBlur={onBlur}
            radioThreshold={radioThreshold}
            placeholder={placeholder}
            readOnly={readOnly}
            autoFilledValues={autoFillValues}
            setAutoFilledValues={setAutoFilledValues}
            boldLabel
        />
    );
};

export default Field;
