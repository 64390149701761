import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import { Text, Button } from 'strat/components';

import type { FullAd } from 'horizontal/types';

import useTerms from './useTerms';
import styles from './styles/terms.cssm';
import BankDetails from './bankDetails';

type Props = {
    ad: FullAd;
};

const AdUndertakingTerms = ({ ad }: Props) => {
    const { currentLanguage, isRTL, declaration, featureTheAd, onAgree } = useTerms({ ad });

    if (!settings.freeAdTerms) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.sectionsContainer}>
                <div className={styles.headerSection}>
                    <Text.Large className={styles.title}>
                        <Trans>Free Ad Undertaking</Trans>
                    </Text.Large>
                </div>
                <div className={styles.sectionTwo}>
                    <div className={styles.declaration}>
                        <Text.Base>
                            <Trans>Declaration</Trans>
                        </Text.Base>
                    </div>
                    <div
                        className={classNames(styles.noteText, {
                            [styles.urdu]: isRTL,
                        })}
                    >
                        <Text.Base>{declaration}</Text.Base>
                    </div>
                    <div
                        className={classNames(styles.noteText, {
                            [styles.urdu]: isRTL,
                        })}
                    >
                        <Text.Base>{settings.freeAdTerms.note[currentLanguage]}</Text.Base>
                    </div>
                    {settings.hasBankPaymentMethodEnabled && <BankDetails />}
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerContainer}>
                    <button onClick={featureTheAd} className={styles.cancel}>
                        <Trans>Feature Your Ad</Trans>
                    </button>
                    <div className={styles.agree}>
                        <Button onClick={onAgree} stretch>
                            <Trans>I Agree</Trans>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdUndertakingTerms;
