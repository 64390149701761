import * as React from 'react';
import { LoadingAnimation, Text } from 'strat/components';

import type { ChatAd } from 'horizontal/chat/types';
import { useFormattedPrice } from 'horizontal/util';

import styles from './styles/adPrice.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined;
    readonly isLoading: boolean;
};

const AdPrice = ({ ad, isLoading }: Props) => {
    const { price } = ad?.extraFields || {};
    const priceLabel = useFormattedPrice(price ?? 0);

    if (isLoading) {
        return <LoadingAnimation className={styles.loading} />;
    }

    if (!price) {
        return null;
    }
    return (
        <Text.Base className={styles.priceLabel} aria-label="Ad price">
            {priceLabel}
        </Text.Base>
    );
};

export default AdPrice;
