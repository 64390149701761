import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type TPayPaymentRouteParams = {
    readonly orderExternalID: string;
    readonly sessionID: string;
};

class PaymentTPayRoute extends Route {
    constructor() {
        super(RouteNames.PAYMENT_TPAY, [
            [
                '^/tpay/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { orderExternalID, sessionID }: TPayPaymentRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/tpay/${orderExternalID}?sessionID=${sessionID}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { orderExternalID, sessionID },
            },
        } = context;

        if (!orderExternalID || !sessionID) {
            // @ts-expect-error - TS2339 - Property 'renderNotFoundPage' does not exist on type 'PaymentTPayRoute'.
            this.renderNotFoundPage(context);
            return;
        }

        context.rendering.renderPage(Page.PAYMENT_TPAY, {
            orderExternalID,
            sessionID,
        });
    }
}

export default new PaymentTPayRoute();
