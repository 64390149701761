import * as React from 'react';
import settings from '@app/branding/settings';
import getVerifiedProfileBadgeConfig from '@app/adCard/getVerifiedProfileBadgeConfig';
import { ViewSections } from 'strat/gtm';
import classNames from 'classnames';

import { selectAdBadges } from 'horizontal/util';
import { AdBadgeType, LiteAd } from 'horizontal/types';
import { AdTag } from 'horizontal/adCard/tags';
import { useAdAgency } from 'horizontal/ad';

import styles from './styles/adBadges.cssm';

type Props = {
    ad: LiteAd;
    viewSection: Values<typeof ViewSections>;
};

const AdBadges = ({ ad, viewSection }: Props) => {
    const agency = useAdAgency(ad);
    const maxNumberOfBadges = Math.min(settings.maxNumberOfBadgesForAdListItem || 3, 2);
    const verifiedProfileBadgeConfig = getVerifiedProfileBadgeConfig(ad, false, true, viewSection);

    let badges = selectAdBadges(
        ad,
        maxNumberOfBadges,
        !!agency,
        verifiedProfileBadgeConfig.show && verifiedProfileBadgeConfig.includeInBadgesList,
    )
        .filter((badge) => badge.type !== AdBadgeType.VIDEO)
        .map((badge) => <AdTag badgeType={badge.type} key={badge.type} />)
        .filter(Boolean);

    const customBadges = ad.adTags?.map((adTag) => <AdTag name={adTag.name} />) || [];

    badges = badges.concat(customBadges);

    badges = maxNumberOfBadges ? badges.slice(0, maxNumberOfBadges) : badges;

    if (!badges || !badges.length) {
        return null;
    }

    return <div className={classNames(styles.badgesWrapper)}>{badges}</div>;
};

export default AdBadges;
