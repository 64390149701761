import type { EnhancedLocation } from 'react-true-router/location';
import type { LoginType, AuthFlow } from 'strat/auth/types';

/**
 * Parameters that can be passed to the callback page.
 */
export type LoginCallbackParams = {
    // redirects the user to a specific path after the login is successful
    redirectPath?: string;
    loginType?: LoginType;
    authFlow?: AuthFlow;
    code?: string;
    session_state?: string;
    subscribeToMarketingEmails?: boolean;
};

const createURL = (params: LoginCallbackParams): EnhancedLocation => {
    return {
        pathname: '/account/callback',
        search: {
            redirectPath: params.redirectPath,
            loginType: params.loginType,
            authFlow: params.authFlow,
            subscribeToMarketingEmails: params.subscribeToMarketingEmails,
        },
        hash: {
            code: params.code,
            session_state: params.session_state,
        },
    };
};

export default createURL;
