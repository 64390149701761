import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';
import { selectUserExternalID } from 'strat/user/session/selectors';

import { PAYMENTS_PAGE_SIZE } from 'horizontal/userOrders/payment';

type Params = {
    readonly page: string;
    readonly sorting: string;
};

const factory = new FetcherFactory(
    'payments',
    ({ page, sorting }: Params, state: any) => {
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Cannot fetch user payments due to missing user ID.');
        }

        return new StratAPI(state.i18n.language).payments(
            userExternalID,
            page,
            sorting,
            PAYMENTS_PAGE_SIZE,
        );
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const userPaymentOrdersFactory = new FetcherFactory(
    'paymentOrders',
    ({ page, sorting }: Params, state: any) => {
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Cannot fetch user payment orders due to missing user ID.');
        }

        return new StratAPI(state.i18n.language).paymentOrders(
            userExternalID,
            page,
            sorting,
            PAYMENTS_PAGE_SIZE,
        );
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const fetchPayments = factory.creator();
const paymentsReducer = factory.reducer();

const fetchUserPaymentOrders = userPaymentOrdersFactory.creator();
const userPaymentOrdersReducer = userPaymentOrdersFactory.reducer();

export { fetchPayments, fetchUserPaymentOrders, userPaymentOrdersReducer };

export default paymentsReducer;
