import * as React from 'react';
import { isCanceled } from 'strat/util';

import { StratAPI } from 'horizontal/api';
import { useMakeCancelable } from 'horizontal/util';

import { PaymentSource, PaymentStatus } from '../types';
import type { PaymentHookParams } from '../types';

import useNavigateToPaymentFailed from './useNavigateToPaymentFailed';
import useTrackPaymentInitiated from './useTrackPaymentInitiated';
import useNavigateToProviderCashPayment from './useNavigateToProviderCashPayment';

const providerCashPaymentStatus = Object.freeze({
    SUCCESS: 'SUCCESS',
});

const useProviderCashPayment = ({ order, setIsLoading, setPaymentPromise }: PaymentHookParams) => {
    const navigateToProviderCashPayment = useNavigateToProviderCashPayment();
    const navigateToPaymentFailed = useNavigateToPaymentFailed(order);
    const trackPaymentInitiated = useTrackPaymentInitiated(order);
    const makeCancelable = useMakeCancelable();

    return React.useCallback(
        (cashPaymentSource: Values<typeof PaymentSource>) => {
            if (!order || !order.externalID) {
                return;
            }
            setIsLoading(true);

            const cancelablePromise = makeCancelable(
                // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
                new StratAPI().providerCashPayment(order.externalID, cashPaymentSource),
            );

            cancelablePromise
                // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
                .then(({ data, status }) => {
                    if (status === 422) {
                        setIsLoading(false);
                        navigateToPaymentFailed(PaymentStatus.MISSING_INFO);
                        return;
                    }

                    if (
                        status !== 200 ||
                        data.status !== providerCashPaymentStatus.SUCCESS ||
                        data.sessionID === undefined
                    ) {
                        setIsLoading(false);
                        navigateToPaymentFailed(PaymentStatus.FAILURE);
                        return;
                    }

                    trackPaymentInitiated(cashPaymentSource);
                    navigateToProviderCashPayment({
                        referenceNumber: data.sessionID,
                        cashPaymentSource,
                    });
                })
                // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
                .catch((error) => {
                    if (isCanceled(error)) {
                        return;
                    }
                    setIsLoading(false);
                });

            setPaymentPromise(cancelablePromise);
        },
        [
            order,
            setIsLoading,
            setPaymentPromise,
            navigateToProviderCashPayment,
            navigateToPaymentFailed,
            trackPaymentInitiated,
            makeCancelable,
        ],
    );
};

export default useProviderCashPayment;
