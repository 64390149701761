import * as React from 'react';
import { Text } from 'strat/components';

import styles from './styles/choiceCountLabel.cssm';

type Props = {
    readonly count: number;
};

const ChoiceCountLabel = ({ count }: Props) => (
    <Text.Base className={styles.text}>({count})</Text.Base>
);

export default ChoiceCountLabel;
