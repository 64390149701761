import * as React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import IconInfo from 'strat/assets/icons/iconInfo_noinline.svg';

import type { BadgeProps } from './types';
import BadgeBottomSheet from './badgeBottomSheet';
import styles from './styles/smallChipBadge.cssm';

const SmallChipBadge = ({ renderTitle, renderValue, renderInformation, label }: BadgeProps) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(false);
    const shouldRenderMoreInfo = !!renderInformation;
    const ariaLabel =
        `${label || ''}${shouldRenderMoreInfo ? ' Hoverable ' : ' '}Card Badge`.trim();

    return (
        <Flex
            alignCenter
            className={classNames(styles.container, {
                [styles.clickable]: shouldRenderMoreInfo,
            })}
            onClick={() => setIsBottomSheetOpen(true)}
            aria-label={ariaLabel}
        >
            <div className={styles.title}>{renderTitle()}:</div>
            <div className={styles.value}>{renderValue()}</div>
            {shouldRenderMoreInfo && (
                <>
                    <svg className={styles.infoIcon}>
                        <use xlinkHref={`${IconInfo}#info`} />
                    </svg>
                    <BadgeBottomSheet
                        isOpen={isBottomSheetOpen}
                        onClose={() => setIsBottomSheetOpen(false)}
                    >
                        {renderInformation()}
                    </BadgeBottomSheet>
                </>
            )}
        </Flex>
    );
};

export default SmallChipBadge;
