import * as React from 'react';
import { Dropdown } from 'strat/modal';

import type {
    FreeTextSuggestion,
    Category,
    FreeTextSuggestionSearchParams,
} from 'horizontal/types';
import { useListKeyNavigation } from 'horizontal/generic';

import FreeTextInput from './freeTextInput';
import useFreeTextSuggestions from './useFreeTextSuggestions';
import styles from './styles/freeTextSearchDropdown.cssm';
import FreeTextSuggestionList from './freeTextSuggestionList';

type Props = {
    readonly query: string;
    readonly activeCategory: Category | null | undefined;
    readonly onCategoryChanged: (arg1?: Category | null | undefined) => void;
    readonly onQueryChange: (arg1: string) => void;
    readonly onQuerySelected: (arg1: FreeTextSuggestionSearchParams) => void;
    readonly onSubmit: () => void;
    readonly searchInputClassName?: string;
};

const FreeTextSearchDropdown = ({
    query,
    activeCategory,
    onCategoryChanged,
    onQueryChange,
    onQuerySelected,
    onSubmit,
    searchInputClassName,
}: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const suggestions = useFreeTextSuggestions(query);

    const submit = React.useCallback(() => {
        onSubmit();
        setIsOpen(false);
    }, [onSubmit, setIsOpen]);

    const selectSuggestion = React.useCallback(
        (suggestion: FreeTextSuggestion) => {
            onQuerySelected(suggestion.searchParams);
            setIsOpen(false);
        },
        [onQuerySelected],
    );

    const onFocusChanged = React.useCallback(
        (isFocused: boolean) => {
            if (isFocused) {
                setIsOpen(true);
            }
        },
        [setIsOpen],
    );

    const {
        onKeyDown,
        selectedIndex,
        reset: resetIndex,
    } = useListKeyNavigation(suggestions, selectSuggestion);

    React.useEffect(() => {
        resetIndex();
    }, [query, isOpen, resetIndex]);

    const renderTrigger = React.useCallback(
        () => (
            <FreeTextInput
                query={query}
                activeCategory={activeCategory}
                onCategoryChanged={onCategoryChanged}
                onChange={onQueryChange}
                onFocusChanged={onFocusChanged}
                onKeyDown={onKeyDown}
                onSubmit={submit}
                searchInputClassName={searchInputClassName}
            />
        ),
        [
            query,
            activeCategory,
            onCategoryChanged,
            onQueryChange,
            onFocusChanged,
            onKeyDown,
            submit,
            searchInputClassName,
        ],
    );

    return (
        <div className={styles.container}>
            <Dropdown
                triggerIsDismissble
                open={isOpen}
                renderTrigger={renderTrigger}
                onDismissed={() => setIsOpen(false)}
            >
                <div className={styles.content}>
                    <FreeTextSuggestionList
                        suggestions={suggestions}
                        selectedIndex={selectedIndex}
                        onSuggestionClicked={selectSuggestion}
                    />
                </div>
            </Dropdown>
        </div>
    );
};

export default FreeTextSearchDropdown;
