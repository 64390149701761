import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';

import type { ProductPackageOffer } from 'horizontal/types';
import IconCheck from 'horizontal/assets/icons/iconCheck.svg';

import { useBusinessPackages } from './hooks';
import styles from './styles/packagesPills.cssm';

type Props = {
    productOffers?: ProductPackageOffer[];
};

const PackagesPills = ({ productOffers }: Props) => {
    const i18n = useI18n();
    const {
        postMoreAndFeature,
        postMoreAndFeatureEqualDuration,
        postMoreAndBoost,
        postMoreAndBoostEqualDuration,
        postMoreAndBoostNonDaily,
        postMoreAndBoostNonDailyEqualDuration,
        postMoreAds,
        featureAds,
        boostToTop,
        autoBoostToTopDaily,
        autoBoostToTopNonDaily,
        eliteAds,
    } = useBusinessPackages(productOffers);

    const GenericRoundedButtonChoice = ({ label }: { label: string }) => {
        return (
            <Flex key={label} className={styles.choice}>
                <IconCheck className={styles.checkIcon} />
                <Text.Regular>{label}</Text.Regular>
            </Flex>
        );
    };
    const isLimitProductAvailable =
        !!postMoreAndFeature.length ||
        !!postMoreAndBoost.length ||
        !!postMoreAds.length ||
        !!postMoreAndBoostEqualDuration.length ||
        !!postMoreAndBoostNonDaily.length ||
        !!postMoreAndBoostNonDailyEqualDuration.length ||
        !!postMoreAndFeatureEqualDuration.length;

    return (
        <>
            {isLimitProductAvailable && (
                <GenericRoundedButtonChoice label={t(i18n)`Post more Ads`} />
            )}
            {!!eliteAds.length && <GenericRoundedButtonChoice label={t(i18n)`Elite`} />}
            {!!featureAds.length && <GenericRoundedButtonChoice label={t(i18n)`Feature`} />}
            {!!boostToTop.length && <GenericRoundedButtonChoice label={t(i18n)`Boost`} />}
            {(!!autoBoostToTopDaily.length || !!autoBoostToTopNonDaily.length) && (
                <GenericRoundedButtonChoice label={t(i18n)`Auto Boost`} />
            )}
        </>
    );
};

export default PackagesPills;
