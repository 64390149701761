import * as React from 'react';
import { Text, Flex } from 'strat/components';

import styles from './styles/card.cssm';

type Props = {
    readonly title: string;
    readonly children: React.ReactNode;
};

const Card = ({ title, children }: Props) => {
    return (
        <Flex stretchWidth column className={styles.container}>
            <div className={styles.section}>
                <Text.XLarge bold>{title}</Text.XLarge>
            </div>

            {React.Children.map(children, (child) => (
                <div className={styles.section}>{child}</div>
            ))}
        </Flex>
    );
};

export default Card;
