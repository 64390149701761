import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import IconArrowLeft from 'strat/assets/icons/iconArrowLeft.svg';
import IconArrowRight from 'strat/assets/icons/iconArrowRight.svg';
import { selectIsLanguageRTL } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { selectIsMobile } from 'strat/platform/selectors';

import styles from './styles/sectionTitle.cssm';

const SectionTitleButton = () => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);
    const isMobile = useSelector(selectIsMobile);

    if (isMobile) {
        return (
            <span className={styles.seeAll}>
                <Trans>See all</Trans>
            </span>
        );
    }
    return (
        <Flex alignCenter>
            <span className={styles.viewMore}>
                <Trans>View more</Trans>
            </span>
            {isLanguageRTL ? (
                <IconArrowLeft className={styles.arrow} />
            ) : (
                <IconArrowRight className={styles.arrow} />
            )}
        </Flex>
    );
};

export default SectionTitleButton;
