import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import RouteNames from 'strat/routes/routeNames';
import { makeCancelable, isCanceled } from 'strat/util';
import { fetchCategoryFields } from 'strat/categoryFields/state';
import settings from '@app/branding/settings';

import type { Ad } from 'horizontal/types';
import { fetchAd, clearAd } from 'horizontal/ad/state';
import Page from 'horizontal/agencyPortal/pages/page';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

export type UpdateAdRouteParams = {
    readonly externalID: string;
};

class AgencyPortalUpdateAdRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_UPDATE_AD, [
            [
                '^/agencyPortal/edit/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL({ externalID }: UpdateAdRouteParams): EnhancedLocation {
        return { pathname: `/agencyPortal/edit/${externalID}` };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context)) {
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const getAdData = () => getState().ad.data;
        const getCategoryFields = () => getState().categoryFields.data;

        context.promise.wait(dispatch(clearAd()));

        const dataPromise = dispatch(fetchAd({ externalID }));

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AgencyPortalUpdateAdRoute'.
        this.cancelablePromise = makeCancelable(dataPromise);

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AgencyPortalUpdateAdRoute'.
        const promise = this.cancelablePromise.then(
            () => {
                const ad: Ad = getAdData();
                if (!ad) {
                    context.http.status(404);
                    context.rendering.renderPage(Page.NOT_FOUND);
                    return Promise.resolve();
                }

                return dispatch(
                    fetchCategoryFields({
                        categoryExternalIDs: [ad.category.slice(-1)[0].externalID],
                        includeWithoutCategory: !settings.disableCategoryFieldsWithoutCategory,
                    }),
                ).then(() => {
                    const categoryFields = getCategoryFields();

                    if (!categoryFields) {
                        context.http.status(404);
                        context.rendering.renderPage(Page.NOT_FOUND);
                        return Promise.resolve();
                    }

                    context.rendering.renderPage(Page.AGENCY_PORTAL_UPDATE_AD);

                    return Promise.resolve();
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );

        context.promise.wait(promise);
    }

    onLeave(): void {
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AgencyPortalUpdateAdRoute'.
        if (this.cancelablePromise) {
            // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'AgencyPortalUpdateAdRoute'.
            this.cancelablePromise.cancel();
        }
    }
}

export default new AgencyPortalUpdateAdRoute();
