//@ts-nocheck
import { t } from '@lingui/macro';

const Currency = Object.freeze({
    AED: 'AED',
    EGP: 'EGP',
    EUR: 'EUR',
    GBP: 'GBP',
    INR: 'INR',
    PKR: 'PKR',
    RUB: 'RUB',
    SAR: 'SAR',
    USD: 'USD',
    CAD: 'CAD',
    JOD: 'JOD',
    XPF: 'XPF',
    ANG: 'ANG',
    NPR: 'NPR',
    QAR: 'QAR',
    OMR: 'OMR',
    BHD: 'BHD',
    KWD: 'KWD',
    CNY: 'CNY',
    LBP: 'LBP',
    PHP: 'PHP',

    text(i18n: any, currency: any) {
        switch (currency) {
            case this.AED:
                return t(i18n)`United Arab Emirates (AED)`;
            case this.EGP:
                return t(i18n)`Egypt (EGP)`;
            case this.EUR:
                return t(i18n)`European Union (EUR)`;
            case this.GBP:
                return t(i18n)`United Kingdom (GBP)`;
            case this.INR:
                return t(i18n)`India (INR)`;
            case this.PKR:
                return t(i18n)`Pakistan (PKR)`;
            case this.RUB:
                return t(i18n)`Russian Federation (RUB)`;
            case this.SAR:
                return t(i18n)`Saudi Arabia (SAR)`;
            case this.USD:
                return t(i18n)`United States of America (USD)`;
            case this.CAD:
                return t(i18n)`Canadian dollar (CAD)`;
            case this.JOD:
                return t(i18n)`Jordan (JOD)`;
            case this.XPF:
                return t(i18n)`New Caledonia (XPF)`;
            case this.ANG:
                return t(i18n)`Netherlands Antilles (ANG)`;
            case this.NPR:
                return t(i18n)`Nepal (NPR)`;
            case this.QAR:
                return t(i18n)`Qatar (QAR)`;
            case this.OMR:
                return t(i18n)`Oman (OMR)`;
            case this.BHD:
                return t(i18n)`Bahrain (BHD)`;
            case this.KWD:
                return t(i18n)`Kuwait (KWD)`;
            case this.CNY:
                return t(i18n)`Chinese Yuan (CNY)`;
            case this.LBP:
                return t(i18n)`Lebanese pound (LBP)`;
            case this.PHP:
                return t(i18n)`Philippine peso (PHP)`;
            default:
                return t(i18n)`United Arab Emirates (AED)`;
        }
    },

    acronym(i18n: any, currency: any) {
        switch (currency) {
            case this.AED:
                return t(i18n)`AED`;
            case this.EUR:
                return t(i18n)`EUR`;
            case this.EGP:
                return t(i18n)`EGP`;
            case this.GBP:
                return t(i18n)`GBP`;
            case this.INR:
                return t(i18n)`INR`;
            case this.PKR:
                return t(i18n)`PKR`;
            case this.RUB:
                return t(i18n)`RUB`;
            case this.SAR:
                return t(i18n)`SAR`;
            case this.USD:
                return t(i18n)`USD`;
            case this.CAD:
                return t(i18n)`CAD`;
            case this.JOD:
                return t(i18n)`JOD`;
            case this.XPF:
                return t(i18n)`XPF`;
            case this.ANG:
                return t(i18n)`ANG`;
            case this.NPR:
                return t(i18n)`NPR`;
            case this.QAR:
                return t(i18n)`QAR`;
            case this.OMR:
                return t(i18n)`OMR`;
            case this.BHD:
                return t(i18n)`BHD`;
            case this.KWD:
                return t(i18n)`KWD`;
            case this.CNY:
                return t(i18n)`CNY`;
            case this.LBP:
                return t(i18n)`LBP`;
            case this.PHP:
                return t(i18n)`PHP`;
            default:
                return t(i18n)`AED`;
        }
    },

    /**
     * Gets whether the specified value is a valid currency.
     */
    isValidValue(value: string): boolean {
        return this.all().includes(value);
    },

    symbol(i18n: any, currency: any) {
        switch (currency) {
            case this.AED:
                return 'د.إ';
            case this.EUR:
                return '€';
            case this.EGP:
                return t(i18n)`EGP`;
            case this.GBP:
                return '£';
            case this.INR:
                return '₹';
            case this.PKR:
                return 'Rs';
            case this.RUB:
                return '₽';
            case this.SAR:
                return t(i18n)`SR`;
            case this.USD:
                return t(i18n)`USD`;
            case this.CAD:
                return '$';
            case this.JOD:
                return 'JOD';
            case this.XPF:
                return 'XPF';
            case this.ANG:
                return 'NAƒ';
            case this.NPR:
                return 'रु';
            case this.QAR:
                return t(i18n)`QAR`;
            case this.OMR:
                return t(i18n)`OMR`;
            case this.BHD:
                return t(i18n)`BHD`;
            case this.KWD:
                return t(i18n)`KWD`;
            case this.CNY:
                return '¥';
            case this.LBP:
                return t(i18n)`LBP`;
            case this.PHP:
                return '₱';
            default:
                return '$';
        }
    },

    all() {
        return [
            this.AED,
            this.EGP,
            this.EUR,
            this.GBP,
            this.INR,
            this.LBP,
            this.PKR,
            this.RUB,
            this.SAR,
            this.USD,
            this.CAD,
            this.JOD,
            this.XPF,
            this.ANG,
            this.NPR,
            this.QAR,
            this.OMR,
            this.BHD,
            this.KWD,
            this.CNY,
            this.PHP,
        ];
    },
});

export default Currency;
