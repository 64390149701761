import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Flex, FloatingDialogActionButtons, LoadingSpinnerOverlay, Text } from 'strat/components';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from 'strat/state';

import { AdSoldMedium } from 'horizontal/constants';
import { setAdOrderDetails } from 'horizontal/businessPackages/state';
import type { FullAd } from 'horizontal/types';

import styles from './styles/removeAdContent.cssm';
import { useSubmitDisableAd } from './useDisableAd';

type Props = {
    ad: FullAd;
    title: string;
    description: string;
    disableButtonText: string;
    reason: string;
    onCancelPress: () => void;
    onSuccess: (soldType?: Values<typeof AdSoldMedium>) => void;
};

const RemoveAdContent = (props: Props) => {
    const { ad, reason, title, description, disableButtonText, onCancelPress, onSuccess } = props;
    const i18n = useI18n();
    const { loading, isError, submitAd } = useSubmitDisableAd({
        adExternalID: ad.externalID,
        reason,
        onSuccess,
    });
    const dispatch = useDispatch<AppDispatch>();

    const handleSubmitAd = () => {
        // Clear any previous Ad packages order data stored in redux/localStorage
        dispatch(setAdOrderDetails(''));
        submitAd();
    };

    if (loading) {
        return (
            <Flex className={styles.content}>
                <LoadingSpinnerOverlay visible />;
            </Flex>
        );
    }

    return (
        <Flex column className={styles.content}>
            <Flex justifyCenter>
                <Text.XLarge bold>{title}</Text.XLarge>
            </Flex>
            <Flex className={styles.descriptionContainer}>
                <Text.Base className={styles.description}>{description}</Text.Base>
            </Flex>
            {isError ? (
                <Text.Regular error bold>
                    <Trans>There was a problem. Please retry</Trans>
                </Text.Regular>
            ) : null}
            <FloatingDialogActionButtons
                primaryButtonAction={handleSubmitAd}
                primaryButtonText={isError ? t(i18n)`RETRY` : disableButtonText}
                secondaryButtonAction={onCancelPress}
                secondaryButtonText={t(i18n)`CANCEL`}
            />
        </Flex>
    );
};

export default RemoveAdContent;
