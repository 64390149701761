import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { PaymentSelectionRouteParams } from 'horizontal/routes';

type UseNavigateToPaymentSelection = (
    arg1?: PaymentSelectionRouteParams | null | undefined,
) => void;

const useNavigateToPaymentSelection = (): UseNavigateToPaymentSelection => {
    const router = useRouter();

    return React.useCallback(
        (params?: PaymentSelectionRouteParams | null) => {
            router.pushRoute(RouteNames.PAYMENT_SELECTION, params);
        },
        [router],
    );
};

export default useNavigateToPaymentSelection;
