import type { SavedSearchesAction } from './actions';
import type { FetchState, FetchAction } from './factory';
import Actions from './actions';
import savedSearchFetcherReducer, { defaultState } from './factory';

const savedSearchesReducer = (
    state: FetchState | null | undefined = defaultState,
    action: SavedSearchesAction | FetchAction,
) => {
    switch (action.type) {
        case Actions.SET_SAVED_SEARCHES:
            return {
                ...state,
                // $FlowFixMe
                // @ts-expect-error - TS2339 - Property 'savedSearches' does not exist on type 'FetchAction | SavedSearchesAction'.
                data: action.savedSearches,
            };

        case Actions.ADD_SAVED_SEARCH:
            return {
                ...state,
                // $FlowFixMe
                // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2339 - Property 'search' does not exist on type 'FetchAction | SavedSearchesAction'.
                data: [action.search, ...(state.data || [])],
            };

        case Actions.REMOVE_SAVED_SEARCH:
            return {
                ...state,
                // $FlowFixMe
                // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS7006 - Parameter 'savedSearch' implicitly has an 'any' type. | TS2339 - Property 'id' does not exist on type 'FetchAction | SavedSearchesAction'.
                data: [...(state.data || []).filter((savedSearch) => savedSearch.id !== action.id)],
            };

        default:
            return savedSearchFetcherReducer(state, action);
    }
};

export default savedSearchesReducer;
