import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { Trans } from '@lingui/macro';

import MobileCarIcon from 'horizontal/assets/icons/mobileCarIcon.svg';
import KeyCarIcon from 'horizontal/assets/icons/keyCarIcon.svg';
import LaptopCarIcon from 'horizontal/assets/icons/laptopCarIcon.svg';

import styles from './styles/exploreSellingWays.cssm';

const ExploreSellingWays = () => (
    <Flex column alignCenter className={styles.exploreSellingWaysContainer}>
        <h2 className={styles.header}>
            <Trans>Explore new ways of selling your car</Trans>
        </h2>
        <Text.XLarge>
            <Trans>Pick the option that suits you</Trans>
        </Text.XLarge>
        <Flex stretchWidth justifySpaceAround className={styles.optionsContainer}>
            <Flex column alignCenter>
                <MobileCarIcon className={styles.exploreWaysIcon} />
                <Text.XXLarge bold>
                    <Trans>Cash my car</Trans>
                </Text.XXLarge>
                <Text.Regular>
                    <Trans>Book a free inspection and get an offer in 48 hours</Trans>
                </Text.Regular>
            </Flex>
            <Flex column alignCenter>
                <KeyCarIcon className={styles.exploreWaysIcon} />
                <Text.XXLarge bold>
                    <Trans>Sell my car</Trans>
                </Text.XXLarge>
                <Text.Regular>
                    <Trans>Let our experts sell your car at the price you set</Trans>
                </Text.Regular>
            </Flex>
            <Flex column alignCenter>
                <LaptopCarIcon className={styles.exploreWaysIcon} />
                <Text.XXLarge bold>
                    <Trans>List my car</Trans>
                </Text.XXLarge>
                <Text.Regular>
                    <Trans>Create your own listing on dubizzle</Trans>
                </Text.Regular>
            </Flex>
        </Flex>
    </Flex>
);

export default ExploreSellingWays;
