import React from 'react';
import { useDispatch } from 'react-redux';
import { useActiveUser } from 'strat/user/session';

import { UserAgency, fetchUserAgencies, useIsAnyTypeOfAgent } from 'horizontal/agent/state';
import type { AppDispatch } from 'horizontal/state';

import { fetchMyProfile } from '../state';

const useFetchMyProfile = () => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useActiveUser();
    const isAgent = useIsAnyTypeOfAgent();

    React.useEffect(() => {
        if (!user) {
            return;
        }
        if (isAgent) {
            dispatch(fetchUserAgencies({ userExternalID: user.externalID })).then(
                ({ status, data }: { status: number; data: UserAgency[] }) => {
                    if (status === 200 && data.length) {
                        dispatch(fetchMyProfile({ agencyExternalID: data[0].externalID }));
                    }
                },
            );
        } else {
            dispatch(fetchMyProfile({ externalID: user.externalID }));
        }
    }, [dispatch, isAgent, user]);
};

export default useFetchMyProfile;
