import { t } from '@lingui/macro';
import * as React from 'react';

import { ShareIconAllDevices } from 'strat/social';
import { generatePropertyLink } from 'strat/social/shortlink';
import type { PropertyData } from 'strat/property';
import { useI18n } from 'strat/i18n/language';
import { PageTypes } from 'strat/gtm';
import Triggers from 'strat/gtm/triggers';
import { useShareMobileWithLoading } from 'strat/social/hooks';

import useShareLinkDialog from '../social/shortlink/dialogs/useShareLinkDialog';

type Props = {
    property: PropertyData;
    className: string;
    iconStyle?: string;
    pageType: Values<typeof PageTypes>;
};

const SharePropertyLinkButton = ({ property, className, iconStyle, pageType }: Props) => {
    const i18n = useI18n();
    const title = t(i18n)`Share this listing`;
    const generate = React.useCallback(
        () => generatePropertyLink(property, i18n.locale),
        [property, i18n],
    );
    const [mobileShare, renderMobileShareDialog] = useShareMobileWithLoading({ generate });

    const openShareLinkDialog = useShareLinkDialog(
        generate,
        title,
        Triggers.SHARE_PROPERTY,
        pageType,
        property,
    );

    const onClick = async () => {
        if (mobileShare) {
            await mobileShare();
        } else {
            openShareLinkDialog();
        }
    };

    return (
        <>
            <button className={className} title={title} onClick={onClick} aria-label="Share">
                <ShareIconAllDevices className={iconStyle} />
                <span>{t(i18n)`Share`}</span>
            </button>
            {renderMobileShareDialog()}
        </>
    );
};

export default SharePropertyLinkButton;
