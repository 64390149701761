import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';
import RouteNames from 'strat/routes/routeNames';
import Link from 'react-true-router/link';
import type { AgencyData } from 'strat/property';
import { ViewSections, withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm';
import styles from '@app/contact/styles/viewAllProperties.cssm';

/**
 * Properties for {@see ViewAllProperties}.
 */
type Props = GTMLeadTrackingProps & {
    agency: null | AgencyData;
    className?: string;
    viewSection: Values<typeof ViewSections>;
    iconRight: boolean;
    viewAllPropertiesText?: string;
};

const ViewAllProperties = ({ iconRight = true, ...props }: Props) => {
    const defaultClassName = CONFIG.build.STRAT_ENABLE_LEADERBOARD
        ? styles.viewAllPropertiesButton
        : styles.agencyViewAllPropertiesButton;

    const className = classNames(defaultClassName, props.className);

    return props.agency ? (
        <Link
            aria-label="View all properties"
            hard
            route={RouteNames.AGENCY_DETAIL}
            params={{ agencySlug: props.agency.slug }}
            className={className}
            onClick={() => props.trackViewAgencyProperties(props.viewSection)}
        >
            {props.viewAllPropertiesText || <Trans>View all properties</Trans>}
            {iconRight && <IconArrowRight className={styles.arrow} />}
        </Link>
    ) : null;
};

export default withGTMLeadTracking(ViewAllProperties);
