import { ProductPackage, ProductPackageOffer, ProductPurchase } from 'horizontal/types';

import { PackagesSection, PackagesFlowTypeValues } from './types';
import useGroupProductPackagesByType from './useGroupProductPackagesByType';
import usePackagesSectionsConfiguration from './usePackagesSectionsConfiguration';

const useConfigurePackagesSections = <T extends ProductPackageOffer | ProductPurchase>(
    items: Array<T> | null | undefined,
    flow: PackagesFlowTypeValues,
    selectProductPackage: (item: T) => ProductPackage,
): Array<PackagesSection<T>> | null => {
    const configs = usePackagesSectionsConfiguration(flow);
    const groupedPackages = useGroupProductPackagesByType(items, selectProductPackage);

    if (!items || items.length == 0) {
        return null;
    }

    return Object.entries(configs)
        .filter(([key, value]) => value.show && groupedPackages[key].length > 0)
        .map(([key, value]): PackagesSection<T> => {
            return {
                packageType: key,
                title: value.title,
                description: value.description,
                items: groupedPackages[key],
                order: value.order,
                valuePropositions: value.valuePropositions,
            };
        })
        .sort((a, b) => a.order - b.order);
};

export default useConfigurePackagesSections;
