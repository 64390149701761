import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';
import { useI18n } from 'strat/i18n/language';

import { DialogNames } from 'horizontal/dialogs';
import type { ChatMessageLocationContent } from 'horizontal/chat/types';
import IconLocation from 'horizontal/assets/icons/iconLocation.svg';

import styles from './styles/openDialogButton.cssm';

type Props = {
    readonly sendLocation: (location: ChatMessageLocationContent) => void;
};

const OpenDialogButton = ({ sendLocation }: Props) => {
    const [, setVisible] = useDialog(DialogNames.CHAT_SHARE_LOCATION_DIALOG);

    const i18n = useI18n();

    const openDialog = React.useCallback(() => {
        setVisible(true, { sendLocation });
    }, [setVisible, sendLocation]);

    return (
        <button className={styles.optionButton} onClick={openDialog}>
            <Flex column alignCenter>
                <IconLocation className={styles.icon} />
                <Text.Regular>{t(i18n)`Location`}</Text.Regular>
            </Flex>
        </button>
    );
};

export default React.memo<Props>(OpenDialogButton);
