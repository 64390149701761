import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import ErrorIcon from 'strat/assets/icons/iconError.svg';
import { Dialog, Text, Flex, LoadingSpinner } from 'strat/components';

import { selectDialogStack } from 'horizontal/dialogs/selectors';
import IconRefresh from '@app/assets/icons/iconRefresh.svg';

import styles from './styles/reportUserStatusDialog.cssm';

const UserComplaintError = Object.freeze({
    USER_BANNED: 'user_banned',
});

type Props = {
    readonly success: boolean;
    readonly error: Values<typeof UserComplaintError> | null | undefined;
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly onCancel: (arg1?: any) => void;
    readonly userBlocked: boolean;
    readonly isUserBlockedLoading: boolean;
    readonly setUserBlocked?: (arg1: boolean) => void;
    readonly userName: string;
    readonly shouldShowBlockingInfo: boolean;
};

const getErrorMessage = (
    error: Values<typeof UserComplaintError> | null | undefined,
    brandName: string,
) => {
    switch (error) {
        case UserComplaintError.USER_BANNED:
            return <Trans>This person has been removed from {brandName}.</Trans>;
        default:
            return <Trans>Could not send report. Please try again later.</Trans>;
    }
};

const ReportUserStatusDialog = ({
    success,
    error,
    visible,
    onVisibilityChanged,
    onCancel,
    userBlocked,
    isUserBlockedLoading,
    setUserBlocked,
    userName,
    shouldShowBlockingInfo,
}: Props) => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);
    const { stacked, stackGroup } = useSelector(selectDialogStack);

    return (
        <Dialog
            className={styles.dialog}
            onCancel={onCancel}
            visible={visible}
            withCloseButton
            onVisibilityChanged={onVisibilityChanged}
            stacked={stacked}
            stackGroup={stackGroup}
        >
            {success ? (
                <Flex column className={styles.center}>
                    <Text.Base bold className={styles.title}>
                        <Trans>Your report was sent</Trans>
                    </Text.Base>
                    <Text.Base className={styles.text}>
                        <Trans>
                            Thank you for helping make {brandName} a better, safer place for
                            everyone.
                        </Trans>
                    </Text.Base>
                    {shouldShowBlockingInfo &&
                        setUserBlocked &&
                        (!isUserBlockedLoading ? (
                            <>
                                {userBlocked && (
                                    <Text.Base className={styles.text}>
                                        <Trans>
                                            You have also blocked <strong>{userName}</strong> and
                                            they will no longer be able to send you messages.
                                        </Trans>
                                    </Text.Base>
                                )}
                                <Flex
                                    onClick={() => setUserBlocked(!userBlocked)}
                                    alignCenter
                                    role="button"
                                    className={classNames(styles.text, styles.toggleBlockButton)}
                                >
                                    <IconRefresh className={styles.iconRefresh} />
                                    <Text.Regular bold>
                                        {userBlocked ? (
                                            <Trans>Unblock</Trans>
                                        ) : (
                                            <Trans>Block user</Trans>
                                        )}
                                    </Text.Regular>
                                </Flex>
                            </>
                        ) : (
                            <LoadingSpinner dark className={styles.loadingSpinner} />
                        ))}
                </Flex>
            ) : (
                <Flex column alignCenter className={styles.center}>
                    <ErrorIcon className={styles.iconError} />
                    <Text.Base className={classNames(styles.title, styles.extraLargeText)}>
                        {getErrorMessage(error, brandName)}
                    </Text.Base>
                </Flex>
            )}
        </Dialog>
    );
};

export default ReportUserStatusDialog;
