import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { Trans } from '@lingui/macro';
import { Flex, Button, LoadingSpinnerOverlay, Text } from 'strat/components';

import { trackPayPackage, useCategoryVars } from 'horizontal/gtm';
import { usePayProductPackages } from 'horizontal/payment';
import { ErrorBanner } from 'horizontal/packages/individual';

import {
    selectBusinessPackageCartOffers,
    selectBusinessPackageCartTotalPrice,
    selectBusinessPackageCartTotalPriceWithTax,
} from './selectors';
import CartPriceDetails from './cartPriceDetails';
import CartEntry from './cartEntryRedesign';
import styles from './styles/viewBusinessPackageCart.cssm';

const ViewBusinessPackageCart = () => {
    const i18n = useI18n();
    const packages = useSelector(selectBusinessPackageCartOffers);
    const totalPrice = useSelector(selectBusinessPackageCartTotalPrice);
    const totalPriceWithTax = useSelector(selectBusinessPackageCartTotalPriceWithTax);

    const { categoryID = null } =
        packages?.[0] && 'categoryID' in packages?.[0] ? packages?.[0] : {};
    const categoryVars = useCategoryVars(categoryID);

    const showTaxDetails = totalPriceWithTax !== 0;
    const { onPayProductPackages, loading, errorMessage } = usePayProductPackages();

    const finalTotalPrice = showTaxDetails ? totalPriceWithTax : totalPrice;

    const onPayClick = React.useCallback(() => {
        trackPayPackage({
            // @ts-expect-error - TS7006 - Parameter 'pack' implicitly has an 'any' type.
            productIDs: packages.map((pack) => pack.productPackage.packageID),
            price: finalTotalPrice,
            categoryVars,
        });
        onPayProductPackages(
            packages.map(({ packageOfferID, cartQuantity }) => ({
                external_id: packageOfferID,
                quantity: cartQuantity,
            })),
        );
    }, [onPayProductPackages, packages, finalTotalPrice, categoryVars]);

    const isPaymentDisabled = packages.length === 0;

    return (
        <Flex column className={styles.container}>
            {errorMessage && <ErrorBanner error={errorMessage} />}
            <Flex justifyCenter className={styles.title}>
                <Text.XXLarge bold>
                    <Trans>View Cart</Trans>
                </Text.XXLarge>
            </Flex>
            <Flex column className={styles.cartContent}>
                <Flex column justifyCenter className={styles.entryContainer}>
                    {packages.map((packageOffer) => (
                        <CartEntry packageOffer={packageOffer} key={packageOffer.packageOfferID} />
                    ))}
                </Flex>
                <Flex className={styles.cartPriceDetails}>
                    <CartPriceDetails showTaxDetails={showTaxDetails} />
                </Flex>
            </Flex>
            <Flex justifyCenter className={styles.payButtonContainer}>
                <Button stretch onClick={onPayClick} disabled={isPaymentDisabled}>
                    {t(i18n)`Proceed to Pay`}
                </Button>
            </Flex>
            <LoadingSpinnerOverlay visible={loading} />
        </Flex>
    );
};

export default ViewBusinessPackageCart;
