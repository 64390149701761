import * as React from 'react';
import { Trans } from '@lingui/macro';

import { PropertyData } from 'strat/property';
import { LocalCurrency } from 'strat/i18n/money';
import LocalPrice from 'strat/i18n/money/localPrice';

import PaymentPlanBreakdown from '../paymentPlanBreakdown';

import Badge from './badge';
import { ProjectBadgeStyle } from './types';

const FORMATTING_OPTIONS = { notation: 'compact', maximumFractionDigits: 1 } as const;

type Props = {
    property: PropertyData;
    style: ProjectBadgeStyle;
};

const AmountPaidBadge = ({ property, style }: Props) => {
    if (!property.offplanDetails?.paidPrice || !property.offplanDetails?.originalPrice) {
        return null;
    }

    const renderValue = () => (
        <>
            <LocalCurrency />{' '}
            <LocalPrice price={property.offplanDetails?.paidPrice} options={FORMATTING_OPTIONS} />
        </>
    );

    return (
        <Badge
            style={style}
            renderTitle={() => <Trans>Amount Paid</Trans>}
            renderValue={renderValue}
            renderInformation={() => <PaymentPlanBreakdown property={property} />}
        />
    );
};

export default AmountPaidBadge;
