import React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import styles from './styles/copyright.cssm';

const Copyright = (_props: { className?: string }) => {
    const i18n = useI18n();
    const country = settings.countryName(i18n);
    const brandName = settings.getBrandName(i18n);

    return (
        <div className={styles.container}>
            <Trans>
                <span>Free Classifieds in {country}</span> . © 2006-{new Date().getFullYear()}{' '}
                {brandName}
            </Trans>
        </div>
    );
};

export default Copyright;
