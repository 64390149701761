import { ChoiceValue } from 'strat/components/types';
import type { Primitive, CategoryFieldChoice, CategoryFieldFilterValue } from 'strat/types';

export const matchChoiceValue = <
    T extends {
        readonly value: ChoiceValue;
    },
>(
    choice?: T,
    value?: CategoryFieldFilterValue,
): boolean => {
    // choice.value can be both string or number
    return choice?.value === value || choice?.value === `${value}`;
};

export function matchValueInChoices(
    value: Primitive | Primitive[],
    choices: Array<CategoryFieldChoice>,
) {
    if (Array.isArray(value)) {
        return choices.some(
            (choice) => value.includes(choice.value) || value.includes(`${choice.value}`),
        );
    }
    return choices.some((choice) => matchChoiceValue(choice, value));
}
