//@ts-nocheck
import type { Dispatch, GetState } from 'redux';
import brandingSettings from '@app/branding/settings';

import { InternalAPI } from 'strat/api';
import { setPageParameters } from 'strat/search/state';

import type { SettingsData } from './types';
import Settings from './settings';

/**
 * Available settings actions.
 */
const Actions = Object.freeze({
    SET_SETTINGS: 'SETTINGS/SET',
});

/**
 * Default settings.
 */
const defaultState: SettingsData = Object.assign({}, Settings.defaultValues());

/**
 * Settings reducers.
 */
const settingsReducer = (state: SettingsData | null | undefined = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_SETTINGS:
            return Settings.normalize({ ...state, ...action.settings });
        default:
            return state;
    }
};

/**
 * Sets the settings.
 */
const setSettings =
    (settings: Partial<SettingsData>) => (dispatch: Dispatch, getState: GetState) => {
        dispatch({
            type: Actions.SET_SETTINGS,
            settings,
        });

        dispatch(setPageParameters({ isAreaPage: false, areaPageValue: null, areaPageUnit: null }));
        if (
            process.env.IS_SERVER ||
            !getState().user.loggedIn ||
            brandingSettings.disableBackOfficeSettingsPersistence
        ) {
            return Promise.resolve({ settings });
        }

        return new InternalAPI().saveSettings(settings);
    };

export { setSettings };

export default settingsReducer;
