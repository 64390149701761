import * as React from 'react';
import { Text, Checkbox } from 'strat/components';
import type { RenderChoiceProps } from 'strat/components/options';
import type { ChoiceValue } from 'strat/components/types';
import classNames from 'classnames';

import styles from './styles/checkboxOptionsChoice.cssm';

const CheckboxOptionsChoice = <T extends ChoiceValue>({
    option,
    isSelected,
    isAutofilled,
    onChange,
}: RenderChoiceProps<T>) => (
    <div key={option.id}>
        <Checkbox
            label={
                <Text.Regular
                    onClick={() => {
                        onChange(option.value);
                    }}
                    className={classNames(styles.choiceLabel, {
                        [styles.autofilled]: isAutofilled,
                    })}
                >
                    {option.label}
                </Text.Regular>
            }
            checked={isSelected(option.value)}
            onChange={() => {
                onChange(option.value);
            }}
        />
    </div>
);

export default CheckboxOptionsChoice;
