import { useSelector } from 'react-redux';
import EMPTY_OBJECT from 'strat/empty/object';
import { selectLanguage } from 'strat/i18n/language';

import { useFlatCategoryFields } from 'horizontal/categoryFields';
import type { CategoryFieldsFilters, LiteCategory } from 'horizontal/types';

import type { ExtraFieldsTargetingParams } from './types';
import { getActiveRangesOther, getOneStepRanges } from './valueRanges';
import formatSearchExtraField from './formatSearchExtraField';

const DEFAULT_MIN_YEAR = 1970;

const useSearchExtraFieldParams = (
    category?: LiteCategory | null,
    extraFields?: CategoryFieldsFilters | null,
): ExtraFieldsTargetingParams => {
    const language = useSelector(selectLanguage);
    // @ts-expect-error - TS2345 - Argument of type 'Partial<{ [key: string]: CategoryFieldFilterValue; }> | null | undefined' is not assignable to parameter of type '{ [key: string]: Primitive | Primitive[]; } | null | undefined'.
    const categoryFields = useFlatCategoryFields(category?.id, extraFields);

    if (!category || !extraFields) {
        return EMPTY_OBJECT;
    }

    const { make, model, petrol, year, mileage, new_used: condition, furnished } = extraFields;
    return {
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'. | TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Primitive[] | null | undefined'.
        model: formatSearchExtraField(language, model, 'model', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'. | TS2322 - Type 'string | number | true | Range | Primitive[]' is not assignable to type 'Primitive'.
        make: make && formatSearchExtraField(language, [make], 'make', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'. | TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Primitive[] | null | undefined'.
        fuel: formatSearchExtraField(language, petrol, 'petrol', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'. | TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Primitive[] | null | undefined'.
        year: formatSearchExtraField(language, year, 'year', categoryFields, (v) =>
            // @ts-expect-error - TS2345 - Argument of type 'Primitive' is not assignable to parameter of type 'Range'.
            getOneStepRanges(v, DEFAULT_MIN_YEAR, new Date().getFullYear()),
        ),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        condition: formatSearchExtraField(
            language,
            // @ts-expect-error - TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Primitive[] | null | undefined'.
            [condition],
            'new_used',
            categoryFields,
            undefined,
        ),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        mileage: formatSearchExtraField(
            language,
            // @ts-expect-error - TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Primitive[] | null | undefined'.
            mileage,
            'mileage',
            categoryFields,
            getActiveRangesOther,
        ),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        furnished: formatSearchExtraField(
            language,
            // @ts-expect-error - TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Primitive[] | null | undefined'.
            furnished,
            'furnished',
            categoryFields,
            undefined,
            // @ts-expect-error - TS2339 - Property 'toLowerCase' does not exist on type 'Primitive'.
            (v) => v.toLowerCase(),
        ),
        // @ts-expect-error - TS2322 - Type 'CategoryFieldFilterValue | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        area_unit: extraFields?.ft_unit, // eslint-disable-line camelcase
    };
};

export default useSearchExtraFieldParams;
