import * as React from 'react';

import { LocationTypes } from 'strat/locations/locationTypes';
import { PropertyType } from 'strat/property/types';

export type SearchLocationNode = {
    slug: string;
};

/**
 * Parameters that can be specified to the search route.
 */
export type SearchParams = Partial<{
    purpose: string;
    locations: Array<SearchLocationNode>;
    locationsByLanguage: {
        [key: string]: Array<SearchLocationNode>;
    };
    locationPrefix: string | null | undefined;
    locationData: Partial<Record<Values<typeof LocationTypes>, number>>;
    agencies: Array<string> | null | undefined;
    agents: Array<string> | null | undefined;
    page: number;
    category: string | null | undefined;
    rentFrequency: string;
    price:
        | {
              min: number | null | undefined;
              max: number | null | undefined;
          }
        | null
        | undefined;
    area:
        | {
              min: number | null | undefined;
              max: number | null | undefined;
          }
        | null
        | undefined;
    areaRange: string | null | undefined;
    priceRange: string | null | undefined;
    landArea:
        | {
              min: number | null | undefined;
              max: number | null | undefined;
          }
        | null
        | undefined;
    beds: Array<number | string> | null | undefined;
    baths: Array<number | string> | null | undefined;
    keywords: Array<string> | null | undefined;
    sortBy: string;
    occupancyStatus: string | null | undefined;
    ownershipStatus: string | null | undefined;
    isVerified: boolean;
    propertyTour: boolean;
    timeSinceCreation: string | null | undefined;
    isAreaPage: boolean;
    isBedroomPage: boolean;
    isCustomPage: boolean;
    areaPageUnit: string | null | undefined;
    areaPageValue: string | null | undefined;
    basePath: string | null | undefined;
    language: string;
    pagetype: string;
    remarketingPropertyID: string;
    completionStatus: string;
    videoCount?:
        | {
              min?: number | null | undefined;
              max?: number | null | undefined;
          }
        | null
        | undefined;
    panoramaCount?:
        | {
              min?: number | null | undefined;
              max?: number | null | undefined;
          }
        | null
        | undefined;
    photoCount?:
        | {
              min?: number | null | undefined;
              max?: number | null | undefined;
          }
        | null
        | undefined;
    hasFloorPlan?: boolean;
    hasTransactionHistory?: boolean;
    searchMode: string | null | undefined;
    city: string | null | undefined;
    areaSlug: string | null | undefined;
    bedroomSlug: string | null | undefined;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    utm_term: string;
    custom_traffic_source: string | null | undefined;
    gclid: string;
    wbraid: string;
    agencySlug: string;
    agencyTier: string;
    commute: string;
    furnishingStatus: string;
    saleType: string;
    productType: Array<string> | null | undefined;
    residenceType: string | null | undefined;
    paymentType: string | null | undefined;
    foreclosures: boolean | null | undefined;
    shortTerm: boolean | null | undefined;
    amenities: Array<string> | null | undefined;
    mapActive: boolean | null | undefined;
    mapZoom: number | null | undefined;
    mapCenterLat: number | null | undefined;
    mapCenterLong: number | null | undefined;
    clusterId: number | null | undefined;
    mapView: string | null | undefined;
    scriptExternalID: string | null | undefined;
    showListingScore: boolean | null | undefined;
    showTimeFrameInfo: boolean | null | undefined;
    leadID?: string | null;
    showDataFor?: string;
    showCurrentMonth?: string;
    contractRenewalStatus?: string;
    listingType?: Values<typeof PropertyType>;
}>;

export type EmailAlertParams = SearchParams & {
    locations: Array<string>;
};

export type SearchLink = {
    params: SearchParams;
};

export type SearchHitsBanner = {
    /**
     * Represents the position in the search listings after which the banner will be inserted
     * (see @strat/searchHits.jsx).
     */
    index: number;
    /**
     * Represents a unique key in the context of the listings.
     */
    key: string;
    /**
     * Renders the banner component itself.
     */
    renderBanner: (arg1?: string | null | undefined) => React.ReactElement;
};

/**
 * Tuple consisting of index and banner component (see @SearchHitsBanner).
 */
export type BannerTuple = [number, React.ReactNode];

export const ProjectSearchType = {
    PROJECT_PAGE: 'project_page',
    PROJECT_VIEW: 'project_view',
};

export const SearchListingType = {
    LISTING: 'listing',
    PROJECT: 'project',
};

export const SearchPurposeType = {
    PROPERTIES: 'properties',
    NEW_PROJECTS: 'new_projects',
    TRANSACTIONS: 'transactions',
    TRU_ESTIMATE: 'truEstimate',
    AGENTS: 'agents',
};

/**
 * Range value.
 */
export type RangeValue = {
    min: null | number;
    max: null | number;
};
