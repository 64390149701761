import * as React from 'react';
import { Trans } from '@lingui/macro';

import { Link } from 'horizontal/adCard';
import IconArrowRight from 'horizontal/assets/icons/iconArrowRight.svg';
import type { ChatAd } from 'horizontal/chat/types';
import { AdState } from 'horizontal/types';

import styles from './styles/viewAdButton.cssm';

type Props = {
    readonly buttonWithText: boolean;
    readonly ad: ChatAd;
    readonly isSeller: boolean;
};

const ViewAdButton = ({ buttonWithText, ad, isSeller }: Props) => {
    if (!isSeller && ad.state !== AdState.ACTIVE) {
        return null;
    }

    return (
        <Link
            preloadedAd={ad}
            params={{ slug: ad.slug, externalID: ad.externalID }}
            className={styles.link}
        >
            {buttonWithText ? (
                <div className={styles.viewAdButton} aria-label="View ad button">
                    <Trans>View Ad</Trans>
                </div>
            ) : (
                <IconArrowRight className={styles.arrowRight} aria-label="View ad button" />
            )}
        </Link>
    );
};

export default ViewAdButton;
