import React from 'react';
import omit from 'lodash/omit';

import IconClose from '@app/assets/icons/iconClose.svg';

import styles from './styles/closeButton.cssm';

type Props = {
    readonly transparent?: boolean;
    readonly className?: string;
    readonly onClick: (event: React.MouseEvent<any>) => void;
};

/**
 * Little gray button with a cross in it, closes something.
 */
const CloseButton = (props: Props) => {
    const buttonClassName = props.transparent
        ? [styles.closeButton, styles.transparent].join(' ')
        : styles.closeButton;

    return (
        <button
            className={[buttonClassName, props.className].join(' ')}
            {...omit(props, ['className', 'children', 'transparent'])}
            aria-label="Close button"
        >
            <IconClose className={styles.closeIcon} />
        </button>
    );
};

export default CloseButton;
