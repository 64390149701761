import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import { ProductPurchaseStatus } from 'horizontal/packages/types';

export type FetchAgentProductPurchasesParams = {
    readonly agencyExternalID: string;
    readonly agentExternalID?: string;
};

export type FetchAgentProductPurchasesByStatusParams = {
    readonly agencyExternalID: string;
    readonly status?: Values<typeof ProductPurchaseStatus>;
    readonly agentExternalID?: string;
};

const factory = new FetcherFactory(
    ['agencyPortal', 'agentProductPurchases'],
    (params: FetchAgentProductPurchasesParams | null | undefined, state: any) => {
        if (!params?.agencyExternalID) {
            throw new Error('Missing agency ID.');
        }

        return new StratAPI(state.i18n.language).agencyProductPurchases({
            agencyExternalID: params?.agencyExternalID,
            agent_external_id: params.agentExternalID,
        });
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const fetchAgentProductPurchases = factory.creator();
const agentProductPurchasesReducer = factory.reducer();

const fetchAgentProductPurchasesByStatusFactory = new FetcherFactory(
    ['agencyPortal', 'agentProductPurchasesByStatus'],
    (params: FetchAgentProductPurchasesByStatusParams | null | undefined, state: any) => {
        if (!params?.agencyExternalID) {
            throw new Error('Missing agency ID.');
        }

        if (!params?.status) {
            throw new Error('Missing status.');
        }

        return new StratAPI(state.i18n.language).agencyProductPurchases({
            agencyExternalID: params?.agencyExternalID,
            agent_external_id: params.agentExternalID,
            status: params.status,
        });
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const fetchAgentProductPurchasesByStatus = fetchAgentProductPurchasesByStatusFactory.creator();
const fetchAgentProductPurchasesByStatusReducer =
    fetchAgentProductPurchasesByStatusFactory.reducer();

export {
    fetchAgentProductPurchases,
    agentProductPurchasesReducer,
    fetchAgentProductPurchasesByStatus,
    fetchAgentProductPurchasesByStatusReducer,
};
