import * as React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { stopEventPropagation } from 'strat/util';
import { ViewSections } from 'strat/gtm';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';
import NO_OP from 'strat/empty/noop';
import { Flex } from 'strat/components';
import type { AppDispatch } from 'strat/state';

import FavoriteIconUnselected from 'horizontal/assets/icons/iconFavoriteUnselected_noinline.svg';
import FavoriteIconSelected from 'horizontal/assets/icons/iconFavoriteSelected_noinline.svg';
import { useEnsureHasActiveUser } from 'horizontal/user';
import { trackFavoriteToggle } from 'horizontal/gtm/adTracking';

import type { MixedAd } from '../types';

import styles from './styles/favoriteButton.cssm';
import { useIsFavoriteAd, useIsCreationLoading } from './hooks';
import { toggleFavoriteAd } from './state';
import FavoritesLimitExceededPopUp from './favoritesLimitExceededPopUp';

type Props = {
    readonly ad: MixedAd;
    readonly viewSection: Values<typeof ViewSections>;
    readonly ovation: OvationCallbackProps;
};

const FavoriteButton = ({ ad, viewSection }: Props) => {
    const isSelected = useIsFavoriteAd(ad.externalID);
    const dispatch = useDispatch<AppDispatch>();
    const loading = useIsCreationLoading(ad);

    const onFavouriteAdToggle = useEnsureHasActiveUser({
        onDone: () => {
            trackFavoriteToggle(ad, viewSection, !isSelected);
            dispatch(toggleFavoriteAd(ad.externalID, NO_OP));
        },
    });

    const onClick = (event: React.ChangeEvent<any>) => {
        onFavouriteAdToggle();
        stopEventPropagation(event);
    };
    const icon = isSelected ? FavoriteIconSelected : FavoriteIconUnselected;

    return (
        <Flex className={styles.iconContainer} onClick={onClick}>
            <svg
                aria-label="Favorite icon"
                className={classNames(styles.icon, {
                    [styles.selectedIcon]: isSelected,
                    [styles.loadingIcon]: loading,
                })}
            >
                <use xlinkHref={`${icon}#icon`} />
            </svg>
            <FavoritesLimitExceededPopUp ad={ad} />
        </Flex>
    );
};

// @ts-expect-error - TS2345 - Argument of type '({ ad, viewSection }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<OvationProps>'.
export default withOvation(FavoriteButton);
