import * as React from 'react';

const usePreviousState = <T>(value?: T | null): T | null | undefined => {
    const ref = React.useRef<T | undefined | null>(null);

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default usePreviousState;
