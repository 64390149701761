// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import { selectSearchSettings } from '@sector-labs/fe-search-redux/state';
import brandingSettings from '@app/branding/settings';
import { SearchRequestOrder } from '@sector-labs/fe-search-redux/backend';
import { createPaidFirstWithFallbackSlottingConfig } from '@sector-labs/strat-horizontal-core';
import { selectLanguage } from 'strat/i18n/language';

import getAdsSearchIndex from '@app/search/hits/getAdsSearchIndex';
import { createRandomSeed, locationByPriority } from 'horizontal/search/execution';
import { SearchPageSearchType } from 'horizontal/search/types';
import { selectKeyedSortOptions, SortOptionKey } from 'horizontal/search/sorting';

import type { SearchProps } from '../types';
import getHitsPerPage from '../getHitsPerPage';
import createAdsSearchFacets from '../common/createAdsSearchFacets';

const createAdsSearchSettings = ({ getState, searchRouteParams }: SearchProps): SearchSettings => {
    const state = getState();
    const language = selectLanguage(state);
    const indexName = getAdsSearchIndex(language);
    const sortOptions = selectKeyedSortOptions(state);
    const mostRelevantSortOption = sortOptions[SortOptionKey.MOST_RELEVANT];
    const sortOption = {
        ...mostRelevantSortOption,
        attributes: [
            { name: 'productScore', order: SearchRequestOrder.DESC },
            { name: 'timestamp', order: SearchRequestOrder.DESC },
            { name: 'id', order: SearchRequestOrder.DESC },
        ],
    } as const;
    const facets = createAdsSearchFacets({ state, searchRouteParams, withProductFacet: true });

    const location = searchRouteParams.location || brandingSettings.topLevelLocation;
    const userLocation = state.user.geoLocation;

    // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData | Location' is not assignable to parameter of type 'Location'.
    const [priorityLocation, coordinates] = locationByPriority(userLocation, location);

    const oldSettings = selectSearchSettings(state);
    const slotRules = createPaidFirstWithFallbackSlottingConfig({
        indexName,
        hitsPerSlot: [getHitsPerPage(SearchPageSearchType.PAID_FIRST)],
        cityLevel: CONFIG.build.CITY_LEVEL,
        searchParams: {
            // @ts-expect-error - TS2322 - Type 'import("horizontal/horizontal/types").Location | null | undefined' is not assignable to type 'import("packages/strat-horizontal-core/dist/search/slotting/types").Location | null | undefined'.
            location: priorityLocation,
            closestLocationCoordinates: coordinates,
            sortOption: searchRouteParams.sortOption,
        },
        fetchNearbyFeaturedAds: true,
    });

    return {
        hitsPerPage: getHitsPerPage(SearchPageSearchType.PAID_FIRST),
        maxValuesPerFacet: 20,
        activeSlotRule: 'default',
        sort: sortOption,
        // Reset the random seed when on the first page. Like this,
        // the seed stays stable when navigating between pages.
        randomSeed: !searchRouteParams.page ? createRandomSeed() : oldSettings.randomSeed,
        facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING ? facets : [],
        // Do not count more than 200k hits. If there are more,
        // we'll display '200k+ hits'. Setting an upper limit
        // prevents ES from endlessly iterating the hits just
        // to get an exhaustive count.
        exhaustiveNbHits: CONFIG.runtime.ADS_SEARCH_EXHAUSTIVE_NB_HITS || 200000,
        maxPageAllowed: 50,
        slotRules,
    };
};

export default createAdsSearchSettings;
