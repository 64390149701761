import * as React from 'react';

import { ContactForm } from 'strat/contact';
import styles from '@app/contact/styles/contactFormModalButtons.cssm';

import ContactFormButtons from './contactFormButtons';

type ContactFormProps = React.ComponentProps<typeof ContactForm>;

const ContactFormModalButtons = (props: ContactFormProps) => (
    <div className={styles.buttons}>
        <ContactFormButtons
            emailButtonClassName={styles.emailButton}
            callButtonClassName={styles.callButton}
            withIcon
            {...props}
        />
    </div>
);

export default ContactFormModalButtons;
