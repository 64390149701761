import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import Page from 'horizontal/pages/page';
import EditProfileSection from 'horizontal/user/editProfileSection';

import ensureHasActiveUser from './ensureHasActiveUser';

export type EditProfileRouteParams = {
    readonly section?: Values<typeof EditProfileSection>;
};

class EditProfileRoute extends Route {
    constructor() {
        super(RouteNames.EDIT_PROFILE, [
            [
                '^/editProfile/',
                { name: 'section', pattern: `(${Object.values(EditProfileSection).join('|')}?)` },
            ],
        ]);
    }

    createURL({ section }: EditProfileRouteParams): EnhancedLocation {
        if (section) {
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'EnhancedLocation'.
            return `/editProfile/${section}`;
        }
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'EnhancedLocation'.
        return '/editProfile';
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context)) {
            return;
        }

        const { section } = context.match.params;

        context.rendering.renderPage(Page.EDIT_PROFILE, { section });
    }
}

export default new EditProfileRoute();
