import RouteNames from 'strat/routes/routeNames';
import AgentSearchSortByValues from 'strat/searchComponents/agentSearchSortByValues';
import { SortByValues } from 'strat/search/sortValues';

export const defaultSort = {
    [RouteNames.AGENT_DETAIL]: AgentSearchSortByValues.DEFAULT,
    DEFAULT: SortByValues.DEFAULT,
};

const getDefaultSortBasedOnRoute = (routeName: string | null) => {
    if (!routeName) {
        return defaultSort.DEFAULT;
    }

    return defaultSort[routeName] || defaultSort.DEFAULT;
};

export default getDefaultSortBasedOnRoute;
