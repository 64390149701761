import type { FullAd } from 'horizontal/types';
import { AdFormat } from 'horizontal/types';

import { getAdVars } from './utils';

export default (ad: FullAd, currentLanguage: string | null = null): any => {
    return {
        ...getAdVars(ad),
        number_of_photos: ad?.format === AdFormat.FULL ? ad?.photos?.length : 0,
        currentLanguage,
    };
};
