import * as React from 'react';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { CloseButton, GalleryDialog, Tab } from 'strat/modal';
import { ImageSlideshow, ClickBehavior, VideoSlideshow, VideoThumbnailImage } from 'strat/image';
import Triggers from 'strat/gtm/triggers';
import Arrow from 'strat/assets/icons/iconArrowStickRight.svg';
import { VideoData } from 'strat/property';
import { TabView } from 'strat/modal';
import { trigger } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';
import { selectUserExternalID } from 'strat/user/session';
import { TabData } from 'strat/property/gallery/galleryTabView';
import TabSwitcher, { TabRenderProps } from 'strat/generic/tabSwitcher';

import IconPhotos from '@app/assets/icons/iconPhotoCountBlack.svg';
import IconVideos from '@app/assets/icons/iconVideoOutline.svg';
import { MixedAd } from 'horizontal/types';
import { isAdEditor } from 'horizontal/util';
import TabSwitchButton from 'horizontal/adDetails/components/tabSwitchButton';

import AdPhotosGalleryTab from './adPhotoGalleryTab';
import arrowStyles from './styles/slideshowArrows.cssm';
import dialogStyles from './styles/dialogStyles.cssm';
import AdOwnerInfo from './adOwnerInfo';
import styles from './styles/galleryDialogFullscreen.cssm';

type Props = {
    ad?: MixedAd | null;
    autoPlay?: boolean;
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
    onGalleryImageChange?: () => void;
};

const renderCloseButton = (onClick: () => void) => (
    <CloseButton className={dialogStyles.closeButton} onClick={onClick} />
);
const renderTabComponent = ({ tab, onClick, className }: TabRenderProps<TabData>) => (
    <TabSwitchButton title={tab.title} Icon={tab.icon} onClick={onClick} className={className} />
);
const renderSwitcher = (
    tabsData: TabData[],
    openedTab: number,
    switchToTab: (index: number) => void,
) => (
    <div className={styles.tabsContainer}>
        <TabSwitcher<TabData>
            tabs={tabsData}
            activeTabIndex={openedTab}
            setActiveTabIndex={switchToTab}
            renderTabComponent={renderTabComponent}
            className={styles.tab}
            activeClassName={styles.activeTab}
            allowMixedCase
        />
    </div>
);

const renderVideoThumbnail = (video: VideoData, coverPhotoID?: string) => (
    <VideoThumbnailImage
        coverPhotoID={coverPhotoID}
        video={video}
        className={styles.slideshowItem}
    />
);

const GalleryDialogFullScreen = (props: Props) => {
    const [photoViewVisible, setPhotoViewVisible] = React.useState(false);

    const loggedUserExternalId = useSelector(selectUserExternalID);
    const i18n = useI18n();

    const isOwnAd = isAdEditor(props.ad, loggedUserExternalId);
    React.useEffect(() => {
        ImageSlideshow.preload();
    }, []);

    const renderPhotosTab = () => {
        const { ad } = props;
        const photos = ad?.photos?.filter((photo) => !!photo) || [];
        return (
            <AdPhotosGalleryTab
                key="photos-tab"
                photos={photos}
                photoViewVisible={photoViewVisible}
                onPhotoViewVisibilityChange={(photoViewVisible: boolean) => {
                    setPhotoViewVisible(photoViewVisible);
                }}
                onImageChange={props.onGalleryImageChange}
            />
        );
    };

    const onVideoError = (errorMessage: string) => {
        trigger(Triggers.VIDEO_PLAY_ERROR, {
            value: errorMessage,
        });
    };
    const renderVideosTab = () => {
        const { ad, visible, autoPlay } = props;

        return (
            <Tab key="videos-tab">
                <div className={styles.contentContainer}>
                    <VideoSlideshow
                        autoPlay={visible && autoPlay}
                        showFullscreenButton={false}
                        clickBehavior={ClickBehavior.NEXT}
                        videos={
                            ad?.videos?.map((video) => {
                                return { ...video, id: Number(video.externalID) };
                            }) || []
                        }
                        triggerName={Triggers.VIDEO_PLAY}
                        customArrow={Arrow}
                        leftArrowStyle={arrowStyles.leftArrowContainer}
                        rightArrowStyle={arrowStyles.rightArrowContainer}
                        arrowIconStyle={arrowStyles.arrow}
                        renderVideoThumbnail={renderVideoThumbnail}
                        onError={onVideoError}
                    />
                </div>
            </Tab>
        );
    };

    const onVisibilityChanged = (visible: boolean) => {
        props.onVisibilityChanged(visible);
        setPhotoViewVisible(false);
    };

    const getTabsData = (i18n: I18n, ad?: MixedAd | null) => {
        const tabsList: TabData[] = [
            {
                title: t(i18n)`Photos (${ad?.photos?.length})`,
                icon: IconPhotos,
                renderTab: renderPhotosTab,
            },
        ];
        if (ad?.videoCount != 0) {
            tabsList.push({
                title: t(i18n)`Video`,
                icon: IconVideos,
                renderTab: renderVideosTab,
            });
        }
        return tabsList;
    };

    const tabs = getTabsData(i18n, props.ad);

    return (
        <GalleryDialog
            preRender
            dismissible
            visible={props.visible}
            onVisibilityChanged={onVisibilityChanged}
            className={classNames(dialogStyles.dialog, {
                [dialogStyles.hidden]: photoViewVisible,
                [isOwnAd ? dialogStyles.dialogFixedHeightOwnAd : dialogStyles.dialogFixedHeight]:
                    props.ad?.photos?.length && props.ad?.photos.length > 1,
            })}
            renderCloseButton={renderCloseButton}
        >
            <div className={classNames(styles.dialogPadding)}>
                <TabView
                    defaultTab={0}
                    renderSwitcher={(...params) => renderSwitcher(tabs, ...params)}
                    alwaysRenderSwitcher
                    renderSwitcherOnTop
                >
                    {tabs.map((tabData) => tabData.renderTab())}
                </TabView>
                {!isOwnAd && (
                    <div className={styles.contactSection}>
                        {props.ad && <AdOwnerInfo ad={props.ad} />}
                    </div>
                )}
            </div>
        </GalleryDialog>
    );
};

export default GalleryDialogFullScreen;
