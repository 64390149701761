import * as React from 'react';

import styles from './styles/scrollContainer.cssm';

type Props = {
    readonly children: React.ReactNode | Array<React.ReactNode>;
    readonly loadMore: () => Promise<void>;
    readonly isLoading: boolean;
    readonly isEndReached: boolean;
};

const ScrollContainer = ({ children, loadMore, isLoading, isEndReached }: Props) => {
    const onScroll = React.useCallback(
        (event: React.SyntheticEvent<HTMLDivElement>) => {
            const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
            if (scrollTop + clientHeight >= scrollHeight - 5 && !isEndReached && !isLoading) {
                loadMore();
            }
        },
        [isEndReached, isLoading, loadMore],
    );

    return (
        <div onScroll={onScroll} className={styles.container}>
            {children}
        </div>
    );
};
export default ScrollContainer;
