import { t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import {
    MilestonesPaymentPlanInstallment,
    MilestonesPaymentPlanPayment,
    MilestonesProjectPaymentPlan,
} from 'strat/project/types';

export type MilestonesPaymentPlanMilestone = {
    title: string;
    valuePercentage: number;
    installments?: MilestonesPaymentPlanInstallment[];
};

const computeStepPaymentMilestone = (
    payment: MilestonesPaymentPlanPayment,
    installments: MilestonesPaymentPlanInstallment[],
) => {
    if (payment?.valuePercentage) {
        return {
            valuePercentage: payment.valuePercentage,
            installments,
        };
    }

    if (installments) {
        return {
            valuePercentage: installments.reduce(
                (acc, installment) => acc + (installment.valuePercentage || 0),
                0,
            ),
            installments,
        };
    }

    return null;
};

const useMilestonesPaymentTimeline = (
    paymentPlan: MilestonesProjectPaymentPlan,
): Array<MilestonesPaymentPlanMilestone> => {
    const i18n = useI18n();
    const timeline = [];

    const downPayment = paymentPlan.downPayment;
    if (downPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`Down Payment`,
            valuePercentage: downPayment.valuePercentage,
        });
    }

    const preHandoverPayment = computeStepPaymentMilestone(
        paymentPlan.preHandoverPayment,
        paymentPlan.preHandoverInstallments,
    );
    if (preHandoverPayment?.valuePercentage) {
        timeline.push({
            title: t(i18n)`Pre Handover`,
            ...preHandoverPayment,
        });
    }

    const handoverPayment = paymentPlan.handoverPayment;
    if (handoverPayment.valuePercentage) {
        timeline.push({
            title: t(i18n)`On Handover`,
            valuePercentage: handoverPayment.valuePercentage,
        });
    }

    const postHandoverPayment = computeStepPaymentMilestone(
        paymentPlan.postHandoverPayment,
        paymentPlan.postHandoverInstallments,
    );
    if (postHandoverPayment?.valuePercentage) {
        timeline.push({
            title: t(i18n)`Post Handover`,
            ...postHandoverPayment,
        });
    }

    return timeline;
};

export default useMilestonesPaymentTimeline;
