import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectIsMobile } from 'strat/platform/selectors';

import type { LiteAd } from 'horizontal/types';

import useCategoriesWithHits, { CategoryWithHits } from './useCategoriesWithHits';

export const HITS_PER_CATEGORY = 4;

export const shouldRenderCategoryWithHits = (hits: Array<LiteAd>, isMobile: boolean): boolean => {
    const minHitsForDisplay = isMobile ? 2 : HITS_PER_CATEGORY;
    return hits.length >= minHitsForDisplay;
};

const useRenderedCategoriesWithHits = (): Array<CategoryWithHits> => {
    const categoriesWithHits = useCategoriesWithHits();
    const isMobile = useSelector(selectIsMobile);

    return React.useMemo(
        () =>
            categoriesWithHits.filter((entry) =>
                shouldRenderCategoryWithHits(entry.hits, isMobile),
            ),
        [categoriesWithHits, isMobile],
    );
};

export default useRenderedCategoriesWithHits;
