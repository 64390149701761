import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useDispatch, useStore } from 'react-redux';

import { trackLoginOpen, trackSignUpClick, trackSignupOpen, Triggers } from 'strat/gtm';
import Loadable from 'strat/loadable';
import { Dialog, LoadingSpinnerOverlay, Text } from 'strat/components';
import { useActiveUser, selectActiveUser } from 'strat/user/session';
import { addRecentlyViewedEmailAlert, addRecommendedEmailAlert } from 'strat/alerts/state';
import { AppDispatch } from 'strat/state';

import useKeycloakThirdPartyLogin from '../hooks/useKeycloakThirdPartyLogin';
import { LoginType, AuthFlow } from '../../types';
import { setAuthenticationLoginType } from '../../state';
import type { KeycloakUser } from '../user';
import { DialogScreen, Flow, ThirdPartyProvider } from '../types';
import MarketingEmailsOptInContext from '../marketingEmailsOptInContext';
import useMarketingEmailsOtpIn from '../hooks/useMarketingEmailsOtpIn';

type Props = {
    name?: DialogScreen;
    dismissible?: boolean;
    visible: boolean;
    authType?: DialogScreen;
    onDone?: (arg1: KeycloakUser) => void;
    onCancel?: () => void;
    onVisibilityChanged?: (arg1: boolean) => void;
    userPath?: Values<typeof Triggers>;
    stackGroup?: string;
    withOverlay?: boolean;
    renderHeader?: () => React.ReactElement;
    disableRegister?: boolean;
    disableEmailLink?: boolean;
    disableFacebook?: boolean;
};

const LoadingKeycloakAuthDialogContent = () => <LoadingSpinnerOverlay visible />;

const KeycloakAuthDialogContentErrorFallback = () => (
    <Text.Regular bold>
        <Trans>Something went wrong. Please try again.</Trans>
    </Text.Regular>
);

const KeycloakAuthDialogContent = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ './keycloakAuthDialogContent'),
    loading: LoadingKeycloakAuthDialogContent,
    fallback: KeycloakAuthDialogContentErrorFallback,
    loadingFallback: KeycloakAuthDialogContentErrorFallback,
});

const KeycloakAuthDialog = ({
    dismissible = true,
    visible,
    name,
    onDone,
    onCancel,
    onVisibilityChanged,
    userPath,
    stackGroup,
    withOverlay,
    renderHeader,
    disableRegister,
    disableEmailLink,
    disableFacebook,
}: Props) => {
    const store = useStore();
    const activeUser = useActiveUser();
    const dispatch: AppDispatch = useDispatch();

    const [screen, setScreen] = React.useState<DialogScreen | null | undefined>(name);
    const [loginType, setLoginType] = React.useState<LoginType | null>(null);
    const [flow, setFlow] = React.useState<Flow | null>(null);

    const {
        subscribeToMarketingEmails,
        setSubscribeToMarketingEmails,
        renderMarketingEmailsOptIn,
    } = useMarketingEmailsOtpIn();

    const loginWithGoogle = useKeycloakThirdPartyLogin({
        provider: ThirdPartyProvider.GOOGLE,
        authFlow: AuthFlow.LOGIN,
    });

    const registerWithGoogle = useKeycloakThirdPartyLogin({
        provider: ThirdPartyProvider.GOOGLE,
        authFlow: AuthFlow.REGISTER,
        subscribeToMarketingEmails,
    });

    const loginWithFacebook = useKeycloakThirdPartyLogin({
        provider: ThirdPartyProvider.FACEBOOK,
        authFlow: AuthFlow.LOGIN,
    });

    const registerWithFacebook = useKeycloakThirdPartyLogin({
        provider: ThirdPartyProvider.FACEBOOK,
        authFlow: AuthFlow.REGISTER,
        subscribeToMarketingEmails,
    });

    React.useEffect(() => {
        if (visible) {
            setScreen(name);
        }
    }, [name, visible]);

    const onRegisterReset = React.useCallback(() => {
        setSubscribeToMarketingEmails(CONFIG.build.STRAT_ENABLE_SHOW_MARKETING_CHECKBOX_ON_SIGNUP);
        setScreen(DialogScreen.REGISTER);
    }, [setSubscribeToMarketingEmails]);

    const reset = React.useCallback(() => {
        setSubscribeToMarketingEmails(CONFIG.build.STRAT_ENABLE_SHOW_MARKETING_CHECKBOX_ON_SIGNUP);
        setScreen(null);
        setLoginType(null);
    }, [setSubscribeToMarketingEmails]);

    const done = React.useCallback(() => {
        if (onVisibilityChanged) {
            onVisibilityChanged(false);
        }

        // Query the store here instead of using the value provided
        // by the `useActiveUser` hook. This function might be
        // called by a downstream component before we had the
        // chance to re-render and get the latest value of the
        // store.
        const currentUser = selectActiveUser(store.getState());
        if (currentUser && onDone) {
            onDone(currentUser);
        }
    }, [onVisibilityChanged, onDone, store]);

    React.useEffect(() => {
        if (!activeUser || !loginType) {
            return;
        }
        dispatch(setAuthenticationLoginType(loginType));

        switch (flow) {
            case Flow.PHONE_REGISTER:
            case Flow.EMAIL_REGISTER:
                // do nothing,
                // in these 2 cases the components will implicitly call done()
                return;
        }

        switch (loginType) {
            case LoginType.EMAIL_LINK:
                break;
            default:
                done();
        }
    }, [dispatch, loginType, done, activeUser, flow]);

    const onCreateAnAccount = React.useCallback(() => {
        setScreen(DialogScreen.REGISTER);
        trackSignUpClick();
    }, [setScreen]);

    const onAccountCreated = React.useCallback(() => {
        if (subscribeToMarketingEmails) {
            // Query the store here instead of using the value provided
            // by the `useActiveUser` hook. This function might be
            // called by a downstream component before we had the
            // chance to re-render and get the latest value of the
            // store.
            const currentUser = selectActiveUser(store.getState());
            if (currentUser?.email) {
                dispatch(addRecommendedEmailAlert());
                dispatch(addRecentlyViewedEmailAlert());
            }
        }
    }, [dispatch, store, subscribeToMarketingEmails]);

    const onFlowChosen = React.useCallback(
        (flow: Flow) => {
            switch (flow) {
                case Flow.PHONE_LOGIN:
                    setScreen(DialogScreen.PHONE_LOGIN);
                    setFlow(Flow.PHONE_LOGIN);
                    setLoginType(null);
                    break;
                case Flow.PHONE_REGISTER:
                    setScreen(DialogScreen.PHONE_REGISTER);
                    setFlow(Flow.PHONE_REGISTER);
                    setLoginType(null);
                    break;

                case Flow.EMAIL_LOGIN:
                    setScreen(DialogScreen.EMAIL_LOGIN);
                    setFlow(Flow.EMAIL_LOGIN);
                    setLoginType(null);
                    break;
                case Flow.EMAIL_REGISTER:
                    setScreen(DialogScreen.EMAIL_REGISTER);
                    setFlow(Flow.EMAIL_REGISTER);
                    setLoginType(null);
                    break;

                case Flow.EMAIL_LINK_LOGIN:
                    setScreen(DialogScreen.EMAIL_LINK_LOGIN);
                    setFlow(Flow.EMAIL_LINK_LOGIN);
                    setLoginType(null);
                    break;
                case Flow.EMAIL_LINK_REGISTER:
                    setScreen(DialogScreen.EMAIL_LINK_REGISTER);
                    setFlow(Flow.EMAIL_LINK_REGISTER);
                    setLoginType(null);
                    break;

                case Flow.GOOGLE_LOGIN:
                    setLoginType(loginWithGoogle());
                    setFlow(Flow.GOOGLE_LOGIN);
                    break;

                case Flow.GOOGLE_REGISTER:
                    setLoginType(registerWithGoogle());
                    setFlow(Flow.GOOGLE_REGISTER);
                    break;

                case Flow.FACEBOOK_LOGIN:
                    setLoginType(loginWithFacebook());
                    setFlow(Flow.FACEBOOK_LOGIN);
                    break;

                case Flow.FACEBOOK_REGISTER:
                    setLoginType(registerWithFacebook());
                    setFlow(Flow.FACEBOOK_REGISTER);
                    break;
            }
        },
        [loginWithFacebook, loginWithGoogle, registerWithFacebook, registerWithGoogle],
    );

    const onTypeChosen = React.useCallback((loginType: LoginType) => {
        setLoginType(loginType);
    }, []);

    React.useEffect(() => {
        if (!visible) {
            reset();
        }
    }, [visible, reset]);

    React.useEffect(() => {
        if (!activeUser) {
            reset();
        }
    }, [activeUser, reset]);

    React.useEffect(() => {
        if (visible) {
            if (name === DialogScreen.REGISTER) {
                trackSignupOpen(userPath);
            } else {
                trackLoginOpen(userPath);
            }
        }
    }, [visible, userPath, name]);

    return (
        <Dialog
            dismissible={dismissible}
            withCloseButton
            visible={visible}
            onVisibilityChanged={onVisibilityChanged || reset}
            onCancel={onCancel}
            zIndex={101}
            stackGroup={stackGroup}
            withOverlay={withOverlay}
        >
            <MarketingEmailsOptInContext.Provider value={subscribeToMarketingEmails}>
                <KeycloakAuthDialogContent
                    screen={screen}
                    setScreen={setScreen}
                    onRegisterReset={onRegisterReset}
                    onBack={reset}
                    onDone={done}
                    onCreateAnAccount={onCreateAnAccount}
                    onAccountCreated={onAccountCreated}
                    onFlowChosen={onFlowChosen}
                    onTypeChosen={onTypeChosen}
                    renderHeader={renderHeader}
                    renderMarketingEmailsOptIn={
                        CONFIG.build.STRAT_ENABLE_SHOW_MARKETING_CHECKBOX_ON_SIGNUP
                            ? renderMarketingEmailsOptIn
                            : undefined
                    }
                    disableRegister={disableRegister}
                    disableEmailLink={disableEmailLink}
                    disableFacebook={disableFacebook}
                />
            </MarketingEmailsOptInContext.Provider>
        </Dialog>
    );
};

export default KeycloakAuthDialog;
