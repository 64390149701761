import { t } from '@lingui/macro';
import React from 'react';
import { useI18n } from 'strat/i18n/language';
import Script from 'strat/util/script';

import { useMakeCancelable } from 'horizontal/util';
import { StratAPI } from 'horizontal/api';

import { NavigateAfterPaymentParams } from '../hooks/useNavigateAfterPayment';
import { PaymentOrder } from '../types';

type Params = {
    readonly merchantID: string | undefined;
    readonly order: PaymentOrder;
    readonly sessionID: string;
    readonly navigateAfterPayment: (params: NavigateAfterPaymentParams) => void;
    readonly createGenericConfirmForm: (html: string) => void;
    readonly setFormErrors: (formError: string) => void;
};

const useAreebaFormConfiguration = ({
    merchantID,
    order,
    sessionID,
    navigateAfterPayment,
    createGenericConfirmForm,
    setFormErrors,
}: Params) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
    const paymentContainer = React.useRef<HTMLElement | null | undefined>(null);
    const makeCancelable = useMakeCancelable();
    const i18n = useI18n();

    const handleClickJacking = () => {
        if (!paymentContainer.current) {
            return;
        }

        if (self === top) {
            paymentContainer.current.style.display = 'block';
        } else {
            // @ts-expect-error - TS2531 - Object is possibly 'null'.
            top.location = self.location;
        }
    };

    React.useEffect(() => {
        const check3dsEnrollment = (gatewaySessionID: any) => {
            const cancelablePromise = makeCancelable(
                new StratAPI().areebaPayment({
                    sessionID,
                    gatewaySessionID,
                    language: i18n.locale,
                }),
            );

            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            cancelablePromise.then(({ data, status }) => {
                if (status === 200 && data.status === 'success') {
                    if (data?.data?.html) {
                        createGenericConfirmForm(data.data.html);
                    } else if (data.data) {
                        navigateAfterPayment({
                            order,
                        });
                    }
                } else {
                    setLoading(false);
                    setFormErrors(data.errors);
                    setFormDisabled(false);
                }
            });
        };

        const configurePayment = () => {
            // @ts-expect-error - TS2339 - Property 'PaymentSession' does not exist on type 'Window & typeof globalThis'.
            window.PaymentSession.configure({
                fields: {
                    // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
                    card: {
                        number: '#card-number',
                        securityCode: '#security-code',
                        expiryMonth: '#expiry-month',
                        expiryYear: '#expiry-year',
                        nameOnCard: '#card-name',
                    },
                },
                frameEmbeddingMitigation: ['javascript'],
                callbacks: {
                    initialized(response: any) {
                        if (response.status === 'ok') {
                            setLoading(false);
                        }
                    },
                    formSessionUpdate(response: any) {
                        if (response.status) {
                            if (response.status === 'ok') {
                                if (response.sourceOfFunds.provided.card.securityCode) {
                                    check3dsEnrollment(response.session.id);
                                } else {
                                    setFormErrors(t(i18n)`Security code is required`);
                                }
                            } else if (response.status === 'fields_in_error') {
                                if (response.errors.cardNumber) {
                                    setFormErrors(t(i18n)`Incorrect or missing Card Number`);
                                } else if (response.errors.expiryMonth) {
                                    setFormErrors(t(i18n)`Incorrect or missing Expiry Month`);
                                } else if (response.errors.expiryYear) {
                                    setFormErrors(t(i18n)`Incorrect or missing Expiry Year`);
                                } else if (response.errors.securityCode) {
                                    setFormErrors(t(i18n)`Incorrect or missing Security Code`);
                                }
                            } else if (response.status === 'request_timeout') {
                                setFormErrors(
                                    t(
                                        i18n,
                                    )`Session update failed with request timeout: ${response.errors.message}`,
                                );
                            } else if (response.status === 'system_error') {
                                setFormErrors(
                                    t(
                                        i18n,
                                    )`Session update failed with system error: ${response.errors.message}`,
                                );
                            }
                        } else {
                            setFormErrors(t(i18n)`Session update failed: ${response}`);
                        }

                        const noErrors =
                            response.status === 'ok' &&
                            response.sourceOfFunds.provided.card.securityCode;
                        setFormDisabled(!!noErrors);
                        if (noErrors) {
                            setLoading(true);
                            return;
                        }

                        setLoading(false);
                    },
                },
                interaction: {
                    displayControl: {
                        formatCard: 'EMBOSSED',
                        invalidFieldCharacters: 'REJECT',
                    },
                },
            });
        };

        if (merchantID) {
            Script.load(
                `https://epayment.areeba.com/form/version/55/merchant/${merchantID}/session.js`,
            ).then(() => {
                configurePayment();
                handleClickJacking();
            });
        }
        return () => {
            if (merchantID) {
                Script.removeScript(
                    `https://epayment.areeba.com/form/version/55/merchant/${merchantID}/session.js`,
                );
            }
        };
    }, [
        navigateAfterPayment,
        merchantID,
        order,
        sessionID,
        makeCancelable,
        i18n,
        createGenericConfirmForm,
        setFormErrors,
    ]);

    return {
        loading,
        setLoading,
        formDisabled,
        setFormDisabled,
        paymentContainer,
    };
};

export default useAreebaFormConfiguration;
