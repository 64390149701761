import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

export const DeleteFormText = () => null;

export const DeleteButtonText = () => {
    const i18n = useI18n();
    return <>{t(i18n)`See more info`}</>;
};

export default DeleteFormText;
