import { t } from '@lingui/macro';
import * as React from 'react';
import { RouteNames } from 'strat/routes';
import { useI18n } from 'strat/i18n/language';
import { ViewSections } from 'strat/gtm';

import {
    trackSitemap,
    trackTermOfUse,
    trackPrivacyPolicy,
    trackClickHelp,
} from 'horizontal/gtm/pageTracking';
import { SitemapPageType } from 'horizontal/sitemap';

import type { FooterLink } from './types';

const useOtherLinks = (): ReadonlyArray<FooterLink> => {
    const i18n = useI18n();

    return React.useMemo(
        () => [
            {
                name: t(i18n)`Help`,
                url: t(i18n)`help_url`,
                event: () => trackClickHelp(ViewSections.FOOTER),
            },
            {
                name: t(i18n)`Sitemap`,
                route: RouteNames.SITEMAP,
                routeParams: { sitemapViewSlug: SitemapPageType.MOST_POPULAR },
                event: trackSitemap,
            },
            {
                name: t(i18n)`Terms of use`,
                url: t(i18n)`terms_url`,
                event: trackTermOfUse,
            },
            {
                name: t(i18n)`Privacy Policy`,
                url: t(i18n)`privacy_url`,
                event: trackPrivacyPolicy,
            },
        ],
        [i18n],
    );
};

export default useOtherLinks;
