import Purpose from 'strat/purpose';

const range = (start: number, end: number) => {
    const numbers: Array<any | number> = [];

    const breakpoints = [
        { value: 100, step: 50 },
        { value: 500, step: 100 },
        { value: 1000, step: 500 },
        { value: 8000, step: 1000 },
        { value: 10000, step: 10000 },
        { value: 60000, step: 25000 },
        { value: 350000, step: 50000 },
        { value: 800000, step: 100000 },
        { value: 1000000, step: 1000000 },
    ];

    let step = 0;
    for (let i = start; i <= end; i += step) {
        numbers.push(i);

        // eslint-disable-next-line no-restricted-syntax, prefer-const
        for (const breakpoint of breakpoints) {
            if (i >= breakpoint.value) {
                step = breakpoint.step;
            }
        }
    }

    return numbers;
};

const priceChoiceBucketsValues = {
    [Purpose.FOR_RENT]: {
        null: {
            AED: {
                min: [0, ...range(20000, 1000000)],
                max: range(50000, 2000000),
            },
            EUR: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            GBP: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            INR: {
                min: [0, ...range(400000, 18000000)],
                max: range(800000, 35000000),
            },
            PKR: {
                min: [0, ...range(600000, 25000000)],
                max: range(1000000, 30000000),
            },
            RUB: {
                min: [0, ...range(200000, 9000000)],
                max: range(4000000, 180000000),
            },
            SAR: {
                min: [0, ...range(20000, 1000000)],
                max: range(50000, 2000000),
            },
            CAD: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            USD: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            JOD: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            QAR: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            OMR: {
                min: [0, ...range(2000, 100000)],
                max: range(5000, 200000),
            },
            BHD: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            KWD: {
                min: [0, ...range(5000, 250000)],
                max: range(10000, 300000),
            },
            CNY: {
                min: [0, 2500, 10000, 15000, 25000, 40000, 65000, 100000, 200000, 250000, 400000],
                max: [
                    2500, 10000, 15000, 25000, 40000, 65000, 100000, 200000, 250000, 400000, 530000,
                ],
            },
            EGP: {
                min: [0, ...range(400000, 18000000)],
                max: range(800000, 35000000),
            },
        },
    },
    [Purpose.FOR_SALE]: {
        null: {
            AED: {
                min: [0, ...range(200000, 9000000)],
                max: range(400000, 10000000),
            },
            EUR: {
                min: [0, ...range(40000, 2500000)],
                max: range(100000, 3000000),
            },
            GBP: {
                min: [0, ...range(50000, 2500000)],
                max: range(100000, 3000000),
            },
            INR: {
                min: [0, ...range(4000000, 180000000)],
                max: range(8000000, 350000000),
            },
            PKR: {
                min: [0, ...range(6000000, 250000000)],
                max: range(10000000, 300000000),
            },
            RUB: {
                min: [0, ...range(2000000, 90000000)],
                max: range(40000000, 1800000000),
            },
            SAR: {
                min: [0, ...range(200000, 9000000)],
                max: range(400000, 10000000),
            },
            CAD: {
                min: [0, ...range(50000, 2500000)],
                max: range(100000, 3000000),
            },
            USD: {
                min: [0, ...range(50000, 2500000)],
                max: range(100000, 3000000),
            },
            JOD: {
                min: [0, ...range(40000, 2500000)],
                max: range(100000, 3000000),
            },
            QAR: {
                min: [0, ...range(40000, 2500000)],
                max: range(100000, 3000000),
            },
            OMR: {
                min: [0, ...range(20000, 900000)],
                max: range(40000, 1000000),
            },
            BHD: {
                min: [0, ...range(40000, 2500000)],
                max: range(100000, 3000000),
            },
            KWD: {
                min: [0, ...range(40000, 2500000)],
                max: range(100000, 3000000),
            },
            CNY: {
                min: [
                    0, 110000, 220000, 440000, 660000, 1100000, 2200000, 3300000, 4400000, 6600000,
                    11000000,
                ],
                max: [
                    110000, 220000, 440000, 660000, 1100000, 2200000, 3300000, 4400000, 6600000,
                    11000000, 22000000,
                ],
            },
            EGP: {
                min: [0, ...range(4000000, 180000000)],
                max: range(8000000, 350000000),
            },
        },
    },
} as const;

const areaChoiceBucketsValues = {
    SQFT: {
        null: {
            min: [
                0,
                800,
                ...range(1000, 10000),
                11000,
                12000,
                13000,
                14000,
                15000,
                17500,
                20000,
                22500,
                25000,
                30000,
                35000,
            ],
            max: [
                800,
                ...range(1000, 10000),
                11000,
                12000,
                13000,
                14000,
                15000,
                17500,
                20000,
                22500,
                30000,
                35000,
            ],
        },
    },
    SQYD: {
        null: {
            min: [
                0,
                ...range(100, 1000),
                1150,
                1250,
                1350,
                1450,
                1600,
                1700,
                1950,
                2250,
                2500,
                2800,
                3350,
                3900,
            ],
            max: [
                ...range(100, 1000),
                1150,
                1250,
                1350,
                1450,
                1600,
                1700,
                1950,
                2250,
                2500,
                2800,
                3350,
                3900,
            ],
        },
    },
    SQM: {
        null: {
            min: [
                0,
                ...range(100, 1000),
                1050,
                1150,
                1250,
                1350,
                1400,
                1650,
                1900,
                2100,
                2350,
                2800,
                3300,
            ],
            max: [
                ...range(100, 1000),
                1050,
                1150,
                1250,
                1350,
                1400,
                1650,
                1900,
                2100,
                2350,
                2800,
                3300,
            ],
        },
    },
} as const;

export { priceChoiceBucketsValues, areaChoiceBucketsValues, range };
