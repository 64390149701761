import * as React from 'react';
import { useSelector } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { useDialog } from 'strat/dialogs/dialogContext';
import { selectLanguage, useI18n } from 'strat/i18n/language';
import { selectIsMobile } from 'strat/platform/selectors';

import { CTA, MixedAd } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';
import { DialogNames } from 'horizontal/dialogs';
import { shouldDisplayProAgencyDataRegulationsDisclaimer } from 'horizontal/agency/roles';

import getPhoneMessageContent from './getPhoneMessageContent';

type Params = {
    readonly ad?: MixedAd | null;
    readonly viewSection: Values<typeof ViewSections>;
    readonly trackWhatsAppLead: (viewSection: Values<typeof ViewSections>) => Promise<string>;
    readonly shouldOpenCTADialog?: boolean;
    readonly whatsAppPhoneNumber?: string | null;
};

const useOnWhatsAppButtonClick = ({
    ad,
    viewSection,
    trackWhatsAppLead,
    shouldOpenCTADialog,
    whatsAppPhoneNumber,
}: Params) => {
    const i18n = useI18n();
    const adExternalID = ad?.externalID;
    const [, setCTADialogVisible] = useDialog(DialogNames.CTA_DIALOG);
    const [, setInternalErrorDialogVisible] = useDialog(DialogNames.INTERNAL_ERROR_DIALOG);
    const makeCancelable = useMakeCancelable();
    const isMobile = useSelector(selectIsMobile);
    const language = useSelector(selectLanguage);

    const trackWhatsAppLeadAndRedirect = React.useCallback(
        (ad: MixedAd, whatsapp: string) => {
            makeCancelable(
                trackWhatsAppLead(viewSection).then((traceID) => {
                    const message = getPhoneMessageContent(ad?.externalID, traceID, i18n, language);
                    const whatsAppURL = `https://wa.me/${whatsapp}?text=${encodeURI(message)}`;

                    if (isMobile) {
                        window.location.href = whatsAppURL;
                    } else {
                        window.open(whatsAppURL);
                    }
                }),
            );
        },
        [i18n, isMobile, language, makeCancelable, trackWhatsAppLead, viewSection],
    );

    return React.useCallback(() => {
        if (!adExternalID || !whatsAppPhoneNumber) {
            setInternalErrorDialogVisible(true, {
                dismissible: true,
            });
            return;
        }
        if (isMobile && !shouldDisplayProAgencyDataRegulationsDisclaimer(ad?.agency)) {
            trackWhatsAppLeadAndRedirect(ad, whatsAppPhoneNumber);
        } else {
            shouldOpenCTADialog
                ? setCTADialogVisible(true, {
                      ad,
                      phoneNumber: whatsAppPhoneNumber,
                      ctaType: CTA.WHATSAPP,
                      onClick: () => {
                          trackWhatsAppLeadAndRedirect(ad, whatsAppPhoneNumber);
                      },
                  })
                : trackWhatsAppLeadAndRedirect(ad, whatsAppPhoneNumber);
        }
    }, [
        ad,
        adExternalID,
        isMobile,
        setCTADialogVisible,
        setInternalErrorDialogVisible,
        shouldOpenCTADialog,
        trackWhatsAppLeadAndRedirect,
        whatsAppPhoneNumber,
    ]);
};

export default useOnWhatsAppButtonClick;
