/* eslint-disable */

import * as React from 'react';

import IconFilters from 'horizontal/assets/icons/iconFilters.svg';
import {
    Button,
    Input,
    RadioGroup,
    Toggle,
    Text,
    Pill,
    Select,
    ListEntry,
    Section,
    Box,
    LoadingSpinnerOverlay,
    LoadingAnimation,
    Flex,
    MessageBox,
    Checkbox,
    Header,
} from 'strat/components';

import styles from './styles/components.cssm';
import { CircleRadioButtonsGroup } from 'strat/components';
import { ArrowDirection, ArrowPosition, MessageBoxType } from 'strat/components/messageBox';

const Components = () => {
    const [toggled, setToggled] = React.useState(false);
    const [classicSelectedRadioValue, setClassicSelectedRadioValue] = React.useState('default');
    const [selectedRadioValue, setSelectedRadioValue] = React.useState('default');
    const [loading, setLoading] = React.useState(false);

    return (
        <div className={styles.components}>
            <h2>0. Text</h2>
            <div>
                <Text.Small>Small text</Text.Small>
            </div>
            <div>
                <Text.Regular>Regular text</Text.Regular>
            </div>
            <div>
                <Text.Large>Large text</Text.Large>
            </div>
            <div>
                <Text.XLarge>XLarge text</Text.XLarge>
            </div>
            <div>
                <Text.XLarge bold uppercase>
                    You can use bold uppercase for heading
                </Text.XLarge>
            </div>

            <h2>1. Inputs</h2>

            <h3>1.1 Regular input</h3>
            <Input
                title="Title"
                description="This is the input's description"
                maxLength={70}
                onChange={() => undefined}
                name="name"
            />

            <h3>1.2 Error input</h3>
            <Input
                title="Title"
                description="This is the input's description"
                errorMessage="Wohoho there sailor"
                maxLength={70}
                onChange={() => undefined}
                name="name2"
            />

            <h3>1.3 Accepted input</h3>
            <Input
                title="Title"
                description="This is the input's description"
                maxLength={70}
                onChange={() => undefined}
                accepted
                name="name3"
            />

            <h3>1.4 With unit</h3>
            <Input
                title="Price"
                description="What's the price my man"
                maxLength={70}
                onChange={() => undefined}
                unit="Rs"
                name="price"
            />

            <h3>1.5 Numeric </h3>
            <Input
                title="Price"
                description="What's the price my man"
                maxLength={70}
                onChange={() => undefined}
                unit="Rs"
                type="number"
                name="price"
            />

            <h3>1.6 Select </h3>
            <Select
                title="Mood"
                name="mood1"
                description="What's the mood"
                onChange={() => undefined}
                maxLength={70}
                choices={[
                    { id: 1, value: 'meh', label: 'Meh' },
                    { id: 2, value: 'woo', label: 'Woo' },
                    { id: 3, value: 'party kip', label: 'Party Kip' },
                ]}
            />

            <h3>1.6 Select accepted </h3>
            <Select
                title="Mood"
                name="mood2"
                description="What's the mood"
                onChange={() => undefined}
                maxLength={70}
                accepted
                choices={[
                    { id: 1, value: 'meh', label: 'Meh' },
                    { id: 2, value: 'woo', label: 'Woo' },
                    { id: 3, value: 'party kip', label: 'Party Kip' },
                ]}
            />

            <h2>2. Buttons</h2>

            <h3>2.1 Primary button</h3>
            <Button onClick={() => alert('woo')}>Login</Button>

            <h3>2.2 Full button</h3>
            <Button onClick={() => alert('woo')} stretch>
                Login
            </Button>

            <h3>2.3 Secondary button</h3>
            <Button onClick={() => alert('woo')} secondary>
                Edit profile
            </Button>

            <h3>2.4 Mask button</h3>
            <div className={styles.maskButtonContainer}>
                <div className={styles.maskButton}>
                    <Button onClick={() => alert('woo')} mask>
                        View packages
                    </Button>
                </div>
            </div>

            <h3>2.5 With icon</h3>
            <Button
                onClick={() => alert('woo')}
                renderIcon={(className) => <IconFilters className={className} />}
            >
                Filters
            </Button>

            <h3>2.6 With icon full</h3>
            <Button
                onClick={() => alert('woo')}
                stretch
                renderIcon={(className) => <IconFilters className={className} />}
            >
                Filters
            </Button>

            <h3>2.7 With icon reversed</h3>
            <Button
                onClick={() => alert('woo')}
                secondary
                renderIcon={(className) => <IconFilters className={className} />}
            >
                Filters
            </Button>

            <h3>2.8 With icon mask</h3>
            <div className={styles.maskButtonContainer}>
                <div className={styles.maskButton}>
                    <Button
                        onClick={() => alert('woo')}
                        mask
                        renderIcon={(className) => <IconFilters className={className} />}
                    >
                        View Filters
                    </Button>
                </div>
            </div>

            <h3>2.9 Disabled primary button</h3>
            <Button onClick={() => alert('woo')} disabled>
                Post now
            </Button>

            <h3>2.10 Disabled secondary button</h3>
            <Button onClick={() => alert('woo')} secondary disabled>
                Post now
            </Button>

            <h3>2.11 Tertiary button</h3>
            <Button onClick={() => alert('woo')} tertiary>
                Mark as sold
            </Button>

            <h2>3. Radio Group</h2>
            <h3>3.1 Circle</h3>
            <CircleRadioButtonsGroup
                title="You can only select one"
                selectedValue={classicSelectedRadioValue}
                // @ts-expect-error - TS2345 - Argument of type 'ChoiceValue' is not assignable to parameter of type 'SetStateAction<string>'.
                onChange={(value) => setClassicSelectedRadioValue(value)}
                choices={[
                    { id: 1, value: 'this', label: 'This' },
                    { id: 2, value: 'or this', label: 'Or this' },
                    { id: 3, value: 'default', label: 'This is the default' },
                    { id: 4, value: 'or maybe this', label: 'Or maybe this' },
                ]}
            />
            <h3>3.2 Usual</h3>
            <RadioGroup
                title="You can only select one"
                selectedValue={selectedRadioValue}
                // @ts-expect-error - TS2345 - Argument of type 'ChoiceValue' is not assignable to parameter of type 'SetStateAction<string>'.
                onChange={(value) => setSelectedRadioValue(value)}
                choices={[
                    { id: 1, value: 'this', label: 'This' },
                    { id: 2, value: 'or this', label: 'Or this' },
                    { id: 3, value: 'default', label: 'This is the default' },
                    { id: 4, value: 'or maybe this', label: 'Or maybe this' },
                ]}
            />

            <h2>4. Misc</h2>

            <h3>4.1 Toggle</h3>
            <Toggle checked={toggled} onClick={() => setToggled(!toggled)} />

            <h3>4.2 Pills</h3>
            <Pill.Regular>Regular pill</Pill.Regular>
            <Pill.Error>Error pill</Pill.Error>
            <Pill.Accentuated>Accentuated pill</Pill.Accentuated>

            <h2>5. Lists</h2>
            <Box>
                <ListEntry>Change password</ListEntry>
                <ListEntry>Edit user</ListEntry>
                <ListEntry renderRight={null}>This one doesn't have an icon content</ListEntry>
                <ListEntry>Control your content</ListEntry>
                <ListEntry borderless>We don't</ListEntry>
                <ListEntry borderless>have any borders</ListEntry>
                <ListEntry>since we're a logical structure</ListEntry>
                <ListEntry
                    renderRight={() => (
                        <Toggle checked={toggled} onClick={() => setToggled(!toggled)} />
                    )}
                >
                    Show my phone number in ads
                </ListEntry>
            </Box>

            <h2>6. Sections</h2>
            <div>These widgets don't work because they're stateless</div>
            <br />
            <div className={styles.border}>
                <Header compact>Filters</Header>
                <Section title="Category">
                    <Select
                        name="category"
                        onChange={() => undefined}
                        description="Choose a category"
                        maxLength={70}
                        choices={[
                            { id: 1, value: 'meh', label: 'House' },
                            { id: 2, value: 'woo', label: 'Car' },
                            { id: 3, value: 'party kip', label: 'Amulet' },
                        ]}
                    />
                </Section>
                <Section title="Type">
                    <RadioGroup
                        selectedValue={'this'}
                        onChange={() => undefined}
                        choices={[
                            { id: 1, value: 'this', label: 'Van' },
                            { id: 2, value: 'or this', label: 'Automobile' },
                            { id: 3, value: 'or maybe this', label: 'RV' },
                        ]}
                    />
                </Section>
                <Section title="Engine type">
                    <Select
                        maxLength={70}
                        name="engine_type"
                        onChange={() => undefined}
                        choices={[
                            { id: 1, value: 'meh', label: 'Petrol' },
                            { id: 2, value: 'woo', label: 'Gas' },
                            { id: 3, value: 'party kip', label: 'Hybrid' },
                        ]}
                    />
                </Section>
                <Section title="Old">
                    <RadioGroup
                        selectedValue={'this'}
                        onChange={() => undefined}
                        choices={[
                            { id: 1, value: 'this', label: 'Yes' },
                            { id: 2, value: 'or this', label: 'Very old' },
                        ]}
                    />
                </Section>
            </div>

            <h2>7. The box</h2>
            <Box>
                <div>
                    <Text.XLarge bold>Am just a box</Text.XLarge>
                </div>
                <br />
                <div>
                    <Text.Regular>
                        I can fit listings, property details or settings or pretty much anything on
                        the website
                    </Text.Regular>
                </div>
            </Box>

            <h2>8. Loading</h2>
            <h3>8.1. Loading Spinner</h3>
            <Button
                onClick={() => {
                    setLoading(true);
                    setTimeout(() => setLoading(false), 2000);
                }}
            >
                Display loading spinner
            </Button>
            <LoadingSpinnerOverlay visible={loading} />

            <h3>8.2. Loading Animation</h3>
            <LoadingAnimation width={'30%'} height={'3rem'} />
            <LoadingAnimation width={'50%'} height={'2rem'} className={styles.loadingAnimation} />
            <LoadingAnimation width={'40%'} height={'2rem'} className={styles.loadingAnimation} />

            <h3>9. Message Box</h3>
            <Flex justifySpaceBetween>
                <MessageBox
                    renderTitle={() => 'This is a good message box'}
                    renderContent={() => 'It is!'}
                    type={MessageBoxType.NEUTRAL}
                    centeredText
                    arrowDirection={ArrowDirection.DOWN}
                    arrowPosition={ArrowPosition.CENTER}
                />
                <MessageBox
                    renderTitle={() =>
                        'This is a very good message box. We also have other types😁'
                    }
                    renderContent={() => 'Custom arrow position available!'}
                    type={MessageBoxType.ACCEPTED}
                    arrowDirection={ArrowDirection.UP}
                    arrowPosition={ArrowPosition.RIGHT}
                    renderIcon={() => <IconFilters />}
                />
            </Flex>

            <h2>10. Check Box</h2>
            <Flex justifySpaceBetween column className={styles.checkboxContainer}>
                <Checkbox label={'Unchecked checkbox'} />
                <Checkbox checked label={'Checked checkbox'} />
                <Checkbox disabled label={'Disabled unchecked checkbox'} />
                <Checkbox disabled checked label={'Disabled checked checkbox'} />
                <Checkbox
                    withRoundedCorners={false}
                    checked
                    label={'Checked checkbox without rounded corners'}
                />
            </Flex>
        </div>
    );
};

const ComponentsPage = {
    head: () => <title>OLX PK</title>,
    body: () => <Components />,
} as const;

export default ComponentsPage;
