import * as React from 'react';

type Props = {
    readonly scrollContainer: HTMLElement;
    readonly children?: React.ReactNode;
};

/**
 * Keeps the scroll container DOM node in the context.
 */
const ScrollContainerContext = React.createContext<HTMLElement | null | undefined>(null);

/**
 * Provider for {@see ScrollContainerContext}.
 */
const ScrollContainerContextProvider = ({ children, scrollContainer }: Props) => (
    <ScrollContainerContext.Provider value={scrollContainer}>
        {children}
    </ScrollContainerContext.Provider>
);

export { ScrollContainerContext, ScrollContainerContextProvider };
