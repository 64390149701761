import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

import SitemapPageType from './sitemapPageType';

const useSitemapNavbarItems = () => {
    const i18n = useI18n();

    return React.useMemo(() => {
        const items = [
            {
                name: t(i18n)`Most Popular`,
                pageType: SitemapPageType.MOST_POPULAR,
            },
            {
                name: t(i18n)`Categories`,
                pageType: SitemapPageType.CATEGORIES,
            },
            {
                name: t(i18n)`Provinces`,
                pageType: SitemapPageType.PROVINCES,
            },
            {
                name: t(i18n)`Cities`,
                pageType: SitemapPageType.CITIES,
            },
        ];
        if (CONFIG.runtime.WITH_SITEMAP_POPULAR_SEARCHES) {
            items.push({
                name: t(i18n)`Popular Searches`,
                pageType: SitemapPageType.POPULAR_SEARCHES,
            });
        }

        return items;
    }, [i18n]);
};

export default useSitemapNavbarItems;
