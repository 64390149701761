import qs from 'qs';
import { logout, loginWithAuthorizationCode } from '@sector-labs/fe-auth-redux/thunk';
import {
    type SessionData,
    SessionActionKey,
    SessionError,
    SessionErrorKey,
    SetErrorAction,
} from '@sector-labs/fe-auth-redux';

import { logError } from 'strat/error/log';
import Page from 'strat/pages/page';
import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import RouteNames from 'strat/routes/routeNames';
import { isKeycloakEnabled } from 'strat/auth/keycloak/config';
import { redirectToURISafely } from 'strat/routing';
import { resetLoginWelcomeDisplayed } from 'strat/auth';
import { trackLinkSocialAccount, trackLoginSuccess, trackSignupSuccess } from 'strat/gtm';
import { buildCanonicalURL } from 'strat/routing';
import { AuthFlow, LoginType } from 'strat/auth/types';
import { selectActiveUser } from 'strat/user/session';
import { setAuthenticationLoginType } from 'strat/auth/state';
import {
    KeycloakCrossWindowNotificationKey,
    sendKeycloakCrossWindowLoginCompleteNotification,
    type KeycloakSiteConfigWithCallbacks,
    createKeycloakSiteConfigWithCallbacks,
} from 'strat/auth/keycloak';
import { selectDriftCorrectedClock } from 'strat/clock/selectors';
import { addRecentlyViewedEmailAlert, addRecommendedEmailAlert } from 'strat/alerts/state';

import createLoginCallbackURL from './createLoginCallbackURL';
import type { LoginCallbackParams } from './createLoginCallbackURL';

class LoginCallbackRoute extends Route {
    constructor() {
        super(RouteNames.LOGIN_CALLBACK, [['^/account/callback(?:(?:\\?.*)?(?:#.*)?)?$']]);
    }

    createURL(params: LoginCallbackParams, _: RoutingContextWithMiddlewares): EnhancedLocation {
        return createLoginCallbackURL(params);
    }

    processClientParams(
        params: LoginCallbackParams,
        _: RoutingContextWithMiddlewares,
    ): LoginCallbackParams {
        return Object.assign(params, qs.parse(window.location.hash));
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!isKeycloakEnabled()) {
            context.http.redirect('/', { status: 303 });
            return;
        }

        context.rendering.renderPage(Page.LOGIN_CALLBACK);
    }

    onClientEnter(context: RoutingContextWithMiddlewares): void {
        const { dispatch, getState } = context.redux.store;

        const { code: authorizationCode } = qs.parse(window.location.hash) as Record<
            string,
            string | null
        >;

        if (!authorizationCode) {
            dispatch<SetErrorAction>({
                type: SessionActionKey.SET_ERROR,
                error: new SessionError('Authorization code missing', {
                    name: SessionErrorKey.ALREADY_IN_USE,
                    extra: {
                        code: authorizationCode,
                    },
                }),
            });
            return;
        }

        const { subscribeToMarketingEmails } = qs.parse(window.location.search) as Record<
            string,
            string | null
        >;

        const state = getState();

        const siteConfig = createKeycloakSiteConfigWithCallbacks(
            {
                languageCode: context.i18n.locale,
                clock: selectDriftCorrectedClock(state),
            },
            dispatch,
        );

        const callbackRedirectPath = context.match.params.redirectPath || null;
        const callbackLoginType = (context.match.params.loginType || null) as LoginType | null;
        const callbackAuthFlow = (context.match.params.authFlow || null) as AuthFlow | null;
        const callbackRedirectURI = buildCanonicalURL(context.match.url, context.i18n.locale);

        const handleMarketingEmailSubscription = (sessionData: SessionData) => {
            if (subscribeToMarketingEmails && sessionData.userProfile?.email) {
                return Promise.allSettled([
                    dispatch(addRecommendedEmailAlert()),
                    dispatch(addRecentlyViewedEmailAlert()),
                ]);
            }
            return Promise.resolve();
        };

        this.logoutIfNeeded(context, siteConfig)
            .then(
                () =>
                    dispatch(
                        loginWithAuthorizationCode(siteConfig, {
                            authorizationCode,
                            redirectURI: callbackRedirectURI,
                        }),
                    ),
                (e) => {
                    if (e instanceof SessionError) {
                        return;
                    }

                    const wrappedError = new SessionError(
                        'Unknown error while exchanging authorization code',
                        {
                            name: SessionErrorKey.UNKNOWN,
                            cause: e,
                        },
                    );

                    dispatch<SetErrorAction>({
                        type: SessionActionKey.SET_ERROR,
                        error: wrappedError,
                    });

                    logError({
                        e,
                        msg: wrappedError.message,
                    });
                },
            )
            .then(
                (sessionData) => {
                    if (!sessionData) {
                        return;
                    }

                    handleMarketingEmailSubscription(sessionData).finally(() => {
                        const loginType = callbackLoginType || this.guessLoginType(sessionData);

                        this.track(loginType, callbackAuthFlow);
                        this.notifyCrossWindow(loginType, sessionData);

                        dispatch(setAuthenticationLoginType(loginType));
                        resetLoginWelcomeDisplayed();

                        redirectToURISafely(context, callbackRedirectPath);
                    });
                },
                (e) => {
                    const wrappedError = new SessionError(
                        'Unknown error while processing session data',
                        {
                            name: SessionErrorKey.UNKNOWN,
                            cause: e,
                        },
                    );

                    dispatch<SetErrorAction>({
                        type: SessionActionKey.SET_ERROR,
                        error: wrappedError,
                    });

                    logError({
                        e,
                        msg: wrappedError.message,
                    });
                },
            );
    }

    private logoutIfNeeded(
        context: RoutingContextWithMiddlewares,
        siteConfig: KeycloakSiteConfigWithCallbacks,
    ): Promise<void> {
        const state = context.redux.store.getState();
        const activeUser = selectActiveUser(state);

        if (!activeUser) {
            return Promise.resolve();
        }

        return context.redux.store.dispatch(logout(siteConfig));
    }

    private guessLoginType(sessionData: SessionData): LoginType {
        if (sessionData.impersonationID) {
            return LoginType.IMPERSONATION_REDIRECT;
        }

        if (window.opener || window.parent) {
            return LoginType.UNKNOWN_POPUP;
        }

        return LoginType.UNKNOWN_REDIRECT;
    }

    private track(loginType: LoginType, authFlow: AuthFlow | null): void {
        switch (authFlow) {
            case AuthFlow.LOGIN:
                trackLoginSuccess(loginType);
                break;

            case AuthFlow.REGISTER:
                trackSignupSuccess(loginType);
                break;
        }

        trackLinkSocialAccount(loginType);
    }

    private notifyCrossWindow(loginType: LoginType, sessionData: SessionData): void {
        switch (loginType) {
            case LoginType.GOOGLE_POPUP:
            case LoginType.FACEBOOK_POPUP:
                {
                    sendKeycloakCrossWindowLoginCompleteNotification(
                        KeycloakCrossWindowNotificationKey.POPUP_LOGIN_COMPLETE,
                        sessionData as SessionData,
                    );

                    window.close();
                }
                return;

            case LoginType.EMAIL_LINK:
                {
                    sendKeycloakCrossWindowLoginCompleteNotification(
                        KeycloakCrossWindowNotificationKey.LINK_LOGIN_COMPLETE,
                        sessionData as SessionData,
                    );
                }
                break;
        }
    }
}

export default new LoginCallbackRoute();
