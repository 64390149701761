import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Currency } from 'strat/i18n/money';
import { selectNumberFormatterFactory, useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex, LoadingAnimation } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';
import Layout from 'strat/layout';

import { useFormattedPrice, useConvertedPrice } from 'horizontal/util';
import { DiscountTypes } from '@app/payment/types';

import styles from './styles/paymentProducts.cssm';

type Props = {
    readonly productPackageNames: string | null | undefined;
    readonly productsPrice: number | null | undefined;
    readonly loading: boolean;
    readonly discount?: any;
};

const PaymentProducts = ({ productPackageNames, productsPrice, loading, discount }: Props) => {
    const i18n = useI18n();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const layout = useSelector(selectLayout);
    // @ts-expect-error - TS2339 - Property 'secondaryCurrency' does not exist on type 'HorizontalSettings'.
    const secondaryPrice = useConvertedPrice(productsPrice, settings.secondaryCurrency);

    const totalPrice = useFormattedPrice(productsPrice || 0);
    const secondaryCurrencySymbol = Currency.symbol(i18n, settings.secondaryCurrency);
    const productPriceDiscount =
        discount?.discountPercent &&
        productsPrice &&
        discount.discountType === DiscountTypes.PAYMENT_AMOUNT_DEDUCTION
            ? Math.ceil((productsPrice * discount?.discountPercent) / 100)
            : 0;
    const discountAmount = useFormattedPrice(productPriceDiscount);
    const discountPrice = useFormattedPrice((productsPrice || 0) - productPriceDiscount);
    const isPartialPaymentEnabled = CONFIG.build.ENABLE_PARTIAL_PAYMENT;

    const secondaryTotalPrice = settings.multipleCurrencyPackages
        ? t(i18n)`${secondaryCurrencySymbol} ${numberFormatterFactory().format(secondaryPrice)}`
        : null;

    return (
        <Flex
            column
            className={classNames(styles.products, {
                [styles.partialPaymentStyles]: isPartialPaymentEnabled,
            })}
        >
            <Flex justifySpaceBetween alignCenter>
                {loading ? (
                    <>
                        <LoadingAnimation width={'60%'} height={'3rem'} />
                        <LoadingAnimation width={'10%'} height={'3rem'} />
                    </>
                ) : (
                    <>
                        <span>{productPackageNames}</span>

                        <Flex alignCenter>
                            {isPartialPaymentEnabled && layout != Layout.MOBILE && (
                                <span>{t(i18n)`Total amount to be paid:`} </span>
                            )}
                            <span
                                className={classNames(styles.price, {
                                    [styles.partialPaymentPrice]: isPartialPaymentEnabled,
                                })}
                                aria-label="Total price"
                            >
                                {totalPrice}
                                {settings.multipleCurrencyPackages && (
                                    <span className={styles.secondaryPrice}>
                                        {secondaryTotalPrice}
                                    </span>
                                )}
                            </span>
                        </Flex>
                    </>
                )}
            </Flex>
            {discount && (
                <>
                    <Flex justifySpaceBetween alignCenter className={styles.discount}>
                        <span>{discount.description}</span>
                        <span aria-label="Discount description">
                            {productPriceDiscount ? discountAmount : '-'}
                        </span>
                    </Flex>
                    <Flex justifySpaceBetween alignCenter className={styles.discount}>
                        <span>{t(i18n)`Total Price`}</span>
                        <span aria-label="Discount Price">{discountPrice}</span>
                    </Flex>
                </>
            )}
        </Flex>
    );
};

export default PaymentProducts;
