import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';

// transforms the raw experiments saved into the store according to environment variables
// DISABLE_EXPERIMENTS - forces original for all experiments
// EXPERIMENTS_OVERRIDE_VARIANT - forces the experiment variant to a specific value
const selectExperiments = createSelector(
    (state: GlobalState) => state.experiments,
    (experiments) => {
        let overrides: Record<string, any> = {};
        if (CONFIG.build.DISABLE_EXPERIMENTS) {
            Object.keys(experiments).forEach((key) => {
                overrides[key] = 'original';
            });

            return overrides;
        }

        try {
            overrides = JSON.parse(CONFIG.runtime.EXPERIMENTS_OVERRIDE_VARIANT);
        } catch (e: any) {
            console.error('Wrong JSON format for custom experiments!');
        }

        return {
            ...experiments,
            ...overrides,
        };
    },
);

export { selectExperiments };
