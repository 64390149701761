// @ts-expect-error - TS2614 - Module '"@sector-labs/fe-search-redux/searchJob"' has no exported member 'SlotRulesConfig'. Did you mean to use 'import SlotRulesConfig from "@sector-labs/fe-search-redux/searchJob"' instead?
import type { SlotRulesConfig } from '@sector-labs/fe-search-redux/searchJob';

type Options = {
    readonly indexName: string;
    readonly hitsPerSlot: [number];
};

const createEmptySlottingConfig = ({ indexName, hitsPerSlot }: Options): SlotRulesConfig => ({
    activeFor: indexName,
    default: { hitsPerSlot, slots: [{ filters: [] }] },
});

export default createEmptySlottingConfig;
