import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';

type Props = {
    readonly className?: string;
};

const BreadcrumbHomeLink = ({ className }: Props) => {
    const i18n = useI18n();
    const title = t(i18n)`Home`;

    return (
        // @ts-expect-error - TS2322 - Type '{ children: (string | Element)[]; itemProp: string; route: string; title: string; className: string | undefined; }' is not assignable to type 'IntrinsicAttributes & Props'.
        <Link itemProp="item" route={RouteNames.HOME} title={title} className={className}>
            {title}
            <meta itemProp="name" content={title} />
        </Link>
    );
};

export default BreadcrumbHomeLink;
