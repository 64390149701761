import { logError } from 'strat/error/log';
import { catchCanceled, makeCancelable } from 'strat/util';

import { ChatFeatureStatus } from 'horizontal/chat/constants';
import { selectChatFeatureAvailability } from 'horizontal/chat/state';
import type { SDKModule, SDKModuleConfig, SDKModuleLoader } from 'horizontal/chat/types';

type StatusChangeListener = (status: Values<typeof ChatFeatureStatus>) => unknown;

class SDKModuleInitializer {
    loadStatus: Values<typeof ChatFeatureStatus> | null | undefined = null;
    loader: SDKModuleLoader | null | undefined = null;
    listeners: Array<StatusChangeListener> = [];
    sdkModule: SDKModule | null | undefined | void = null;
    _config: SDKModuleConfig | null | undefined = null;

    get config(): SDKModuleConfig {
        if (!this._config) {
            throw new Error('Chat SDK Module is not yet configured.');
        }
        return this._config;
    }

    setStatus(status: Values<typeof ChatFeatureStatus>): void {
        if (this.loadStatus === status) {
            return;
        }

        this.loadStatus = status;
        this.listeners.forEach((listener) => listener(status));
    }

    onStatusChange(listener: (arg1: Values<typeof ChatFeatureStatus>) => unknown): () => void {
        if (!this.listeners.includes(listener)) {
            this.listeners.push(listener);
        }

        if (this.loadStatus) {
            listener(this.loadStatus);
        }

        return () => {
            this.listeners = this.listeners.filter((x) => x !== listener);
        };
    }

    configure(config: SDKModuleConfig) {
        this._config = config;
    }

    getAvailabilityStatus(): Values<typeof ChatFeatureStatus> {
        const store = this.config.getStore();
        return selectChatFeatureAvailability(store.getState());
    }

    init(): void {
        this.destroy(ChatFeatureStatus.LOADING);
        this.loader = this.load();
        this.loader.promise
            .then((sdkModule) => {
                this.loader = null;
                this.sdkModule = sdkModule;

                if (this.sdkModule) {
                    this.sdkModule.init?.(this.config);
                    this.setStatus(ChatFeatureStatus.LOADED);
                } else {
                    this.setStatus(ChatFeatureStatus.AVAILABLE);
                }
            })
            .catch((e) => {
                this.destroy(ChatFeatureStatus.ERROR);
                logError({
                    e,
                    msg: 'Error loading chat sdk module',
                });
            });
    }

    destroy(reason: Values<typeof ChatFeatureStatus>): void {
        this.loader?.cancel();
        this.loader = null;
        this.sdkModule?.destroy?.(this.config);
        this.sdkModule = null;
        this.setStatus(reason);
    }

    load(): SDKModuleLoader {
        const loadPromise = import(/* webpackChunkName: 'cable' */ './cable');

        const cancelablePromise = makeCancelable(loadPromise);
        const promise: Promise<SDKModule | null | undefined | void> =
            cancelablePromise.catch(catchCanceled);

        return { cancel: (): void => cancelablePromise.cancel(), promise };
    }

    refresh(): void {
        const featureStatus = this.getAvailabilityStatus();
        if (featureStatus !== ChatFeatureStatus.AVAILABLE) {
            this.destroy(featureStatus);
            return;
        }

        if (
            this.loadStatus !== ChatFeatureStatus.LOADED &&
            this.loadStatus !== ChatFeatureStatus.LOADING
        ) {
            this.init();
        }
    }
}

export default new SDKModuleInitializer();
