import type { PropertyData } from 'strat/property/types';
import { getPanoramaCount, getPropertyFloorPlansCount } from 'strat/property/utility';

/**
 * Concatenated content for: p - photos, v - videos,
 * t - tours (panoramas), f - floor plans separated throught comma
 */
const getContentPVTF = (propertyData: PropertyData) => {
    const propertyPhotoCount = propertyData.photoCount || 0;
    const propertyVideoCount = propertyData.videoCount || 0;
    const propertyPanoramaTours = getPanoramaCount(propertyData);
    const propertyFloorPlansCount = getPropertyFloorPlansCount(propertyData);

    return `${propertyPhotoCount},${propertyVideoCount},${propertyPanoramaTours},${propertyFloorPlansCount}`;
};

export default getContentPVTF;
