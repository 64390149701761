import type { AdDetailsRouteParams } from 'horizontal/routes';
import { useAd } from 'horizontal/ad/state';

const useCurrentAdRouteParams = (): AdDetailsRouteParams | null | undefined => {
    const ad = useAd();
    if (!ad) {
        return null;
    }

    return { slug: ad.slug, externalID: ad.externalID };
};

export default useCurrentAdRouteParams;
