import { useSelector } from 'react-redux';

import { GlobalState } from 'horizontal/state';
import { selectCategoryHierarchyByExternalID } from 'horizontal/categories/selectors';
import { Category } from 'horizontal/types';

const useCategoryHierarchyByExternalID = (
    categoryExternalID: string | null | undefined,
): Category[] | null => {
    // @ts-expect-error TS2322: Type 'Omit<never, "children">[]' is not assignable to type 'Category[]'.
    return useSelector((state: GlobalState) =>
        selectCategoryHierarchyByExternalID(state, categoryExternalID),
    );
};

export default useCategoryHierarchyByExternalID;
