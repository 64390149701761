import { t } from '@lingui/macro';
import React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
import useI18n from 'strat/i18n/language/useI18n';
import classNames from 'classnames';

import { DeliveryFields } from 'horizontal/fields';
import { DeliveryType } from 'horizontal/types';

import styles from './styles/multipleDeliveriesFields.cssm';
import ErrorMessage from './errorMessage';
import ServiceDeliveryField from './serviceDeliveryField';
import useMultiDeliveryFieldErrors from './useMultiDeliveryFieldErrors';
import useUpdateMultiDeliveryFields from './useUpdateMultiDeliveryFields';

type MultipleDeliveriesFieldsProps = {
    setFieldValue: (field: string | number, value: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
    onBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly isEdit?: boolean;
};

const MultipleDeliveriesFields = ({
    setFieldValue,
    touched,
    errors,
    values,
    onBlur,
    isEdit,
}: MultipleDeliveriesFieldsProps) => {
    const i18n = useI18n();

    const {
        serviceDeliveryError,
        selfDeliveryError,
        isServiceDeliveryDisabled,
        isSelfDeliveryDisabled,
    } = useMultiDeliveryFieldErrors({ setFieldValue, values });

    const { serviceDeliverySelected, selfDeliverySelected, handleSelection } =
        useUpdateMultiDeliveryFields({
            setFieldValue,
            isEdit,
            values,
            serviceDeliveryError,
            selfDeliveryError,
        });

    return (
        <div>
            <div
                className={classNames(styles.deliveryType, {
                    [styles.diabledDeliveryTypes]: isServiceDeliveryDisabled,
                })}
            >
                <div className={styles.deliveryOptionContainer}>
                    <input
                        type="radio"
                        value={DeliveryType.SERVICE_DELIVERY}
                        name="deliveryType"
                        disabled={isServiceDeliveryDisabled}
                        onChange={() => handleSelection(DeliveryType.SERVICE_DELIVERY)}
                        checked={serviceDeliverySelected}
                        className={styles.radioButton}
                    />
                    <ServiceDeliveryField />
                </div>

                <div>
                    {serviceDeliveryError && (
                        <div className={styles.errorMessage}>{serviceDeliveryError}</div>
                    )}
                </div>
            </div>
            <div
                className={classNames(styles.serviceDeliveryForm, {
                    [styles.hideServiceDeliveryForm]: !serviceDeliverySelected,
                })}
            >
                {serviceDeliverySelected && !isServiceDeliveryDisabled && (
                    <DeliveryFields
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                        onBlur={onBlur}
                    />
                )}
            </div>
            <div
                className={classNames(styles.deliveryType, {
                    [styles.diabledDeliveryTypes]: isSelfDeliveryDisabled,
                })}
            >
                <div className={styles.deliveryOptionContainer}>
                    <input
                        type="radio"
                        value={DeliveryType.SELF_DELIVERY}
                        name="deliveryType"
                        disabled={isSelfDeliveryDisabled}
                        onChange={() => handleSelection(DeliveryType.SELF_DELIVERY)}
                        checked={selfDeliverySelected}
                        className={styles.radioButton}
                    />
                    <span>{t(i18n)`Self-Delivery`} </span>
                </div>
                <div>
                    {selfDeliverySelected &&
                        t(
                            i18n,
                        )`You will have to contract a delivery service provider to send the order to the buyers.`}
                </div>
                <div>
                    {selfDeliveryError && (
                        <div className={styles.selfDeliveryError}>
                            {selfDeliveryError}{' '}
                            <a
                                href={t(i18n)`how_to_get_verified_url`}
                                target="_blank noopener noreferrer"
                            >
                                {t(i18n)`Learn more about `}
                                <span className={styles.getVerifiedLink}>
                                    {t(i18n)`how to get verified on the dubizzle app.`}
                                </span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
            {errors?.delivery_type && <ErrorMessage>{errors.delivery_type}</ErrorMessage>}
        </div>
    );
};

export default MultipleDeliveriesFields;
