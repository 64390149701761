import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';

import { GlobalState } from 'horizontal/agencyPortal/state';
import { Agent, AgentRoles } from 'horizontal/types';

const selectAll = (state: GlobalState) => state.agencyPortal.agents || EMPTY_OBJECT;

const selectAgents = createSelector(
    (state: GlobalState) => state.agencyPortal.agents,
    (agents) =>
        agents.data?.filter((agent) => !agent.roles?.includes(AgentRoles.OWNER)) ||
        (EMPTY_ARRAY as Agent[]),
);

const selectOwnerFromAgents = createSelector(
    selectAll,
    (ownerAgent) =>
        ownerAgent.data?.filter((agent: any) => agent.roles?.includes(AgentRoles.OWNER))?.[0] ||
        null,
);

const selectAgentsError = createSelector(selectAll, (agents) => agents.error);

const selectIsLoadingAgents = createSelector(selectAll, (agents) => agents.loading);

const selectIsLoadedAgents = createSelector(selectAll, (agents) => agents.loaded);

const selectAgent = createSelector(
    selectAgents,
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'agentID' implicitly has an 'any' type.
    (state, agentID) => agentID,
    // @ts-expect-error - TS7006 - Parameter 'agents' implicitly has an 'any' type. | TS7006 - Parameter 'agentID' implicitly has an 'any' type. | TS7006 - Parameter 'agent' implicitly has an 'any' type.
    (agents, agentID) => agents.find((agent) => agent.external_id === agentID),
);

const selectFilteredAgent = createSelector(
    selectAgents,
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'query' implicitly has an 'any' type.
    (state, query) => query,
    // @ts-expect-error - TS7006 - Parameter 'agents' implicitly has an 'any' type. | TS7006 - Parameter 'query' implicitly has an 'any' type.
    (agents, query) => {
        return query
            ? agents.filter(
                  // @ts-expect-error - TS7006 - Parameter 'agent' implicitly has an 'any' type.
                  (agent) =>
                      agent.external_id?.toUpperCase().includes(query.toUpperCase()) ||
                      agent.name?.toUpperCase().includes(query.toUpperCase()) ||
                      agent.phoneNumber?.toUpperCase().includes(query.toUpperCase()),
              )
            : agents;
    },
);

const selectOwnerAgent = (state: any) => state.agencyPortal?.ownerAgent || EMPTY_OBJECT;

const selectAgencyOwnerAgent = createSelector(
    selectOwnerAgent,
    (ownerAgent) => ownerAgent.data?.[0] || EMPTY_OBJECT,
);

export {
    selectAgents,
    selectOwnerFromAgents,
    selectAgentsError,
    selectIsLoadingAgents,
    selectIsLoadedAgents,
    selectAgent,
    selectFilteredAgent,
    selectAgencyOwnerAgent,
};
