import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

type TPayPaymentRouteParams = {
    readonly orderExternalID: string;
    readonly sessionID: string;
};

type UseNavigateToTPayPayment = (arg1?: TPayPaymentRouteParams | null | undefined) => void;

const useNavigateToTPayPayment = (): UseNavigateToTPayPayment => {
    const router = useRouter();

    return React.useCallback(
        (params?: TPayPaymentRouteParams | null) =>
            router.pushRoute(RouteNames.PAYMENT_TPAY, params),
        [router],
    );
};

export default useNavigateToTPayPayment;
