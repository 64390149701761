import { t } from '@lingui/macro';
import * as React from 'react';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { isBoostedWithSingleBoost } from 'horizontal/adManagement/utils';
import { AdVirtualState } from 'horizontal/types';
import type { FullAd } from 'horizontal/types';
import { useCanPostAdForFree, useDurationTillNextFreePost } from 'horizontal/adLimits/hooks';
import { useHumanizedPeriod } from 'horizontal/util';

import { useGetProductFormattedExpiryDate, adHasAutoBoost } from './utils';
import styles from './styles/stateDescriptionText.cssm';

type Props = {
    ad: FullAd;
    showExtraDescriptionText?: boolean;
};

/**
 * Get the time difference between the current date and the last time this ad was boosted by single boost package (refresh_ad product).
 * The last time this ad was boosted is determined by the "singleBoostAppliedAt" field,
 * and this calculation applies only to Active, Featured, and Elite ads.
 *
 *
 * @param ad: The ad for which to calculate the last boost period.
 * @returns {number | undefined}
 */
const getLastBoostPeriod = (ad: FullAd): number | undefined => {
    if (
        !ad.singleBoostAppliedAt ||
        ![AdVirtualState.ACTIVE, AdVirtualState.FEATURED, AdVirtualState.ELITE].includes(
            ad.virtualState,
        )
    ) {
        return undefined;
    }

    return Date.now() - ad.singleBoostAppliedAt * 1000;
};

/**
 * Custom hook to retrieve the state description text for a specific ad. It returns the ad's description based on its virtual state.
 *
 * If an ad has multiple applied products, this hook provides the normal state description.
 * To obtain the extra description as a second state, set showExtraDescriptionText to true.
 *
 * @param ad The ad for which you want to retrieve the description text.
 * @param showExtraDescriptionText A boolean that, when set to true, forces the display of the Boosted description text. This is needed to have separate state for boosted ads.
 * @returns {string | null}
 */
const useDescriptionText = (ad: FullAd, showExtraDescriptionText?: boolean): string | null => {
    const i18n = useI18n();

    const canPostAdForFree = useCanPostAdForFree(ad);
    const durationTillNextFreePost = useDurationTillNextFreePost(ad);

    const hasAutoBoost = adHasAutoBoost(ad);
    const hasSingleBoost = isBoostedWithSingleBoost(ad);

    const lastBoostPeriod = getLastBoostPeriod(ad);
    const lastBoostPeriodHumanized = useHumanizedPeriod(lastBoostPeriod);

    const expiryDate = useGetProductFormattedExpiryDate(ad, showExtraDescriptionText);

    const expiryMessage = expiryDate ? t(i18n)`expires after ${expiryDate}` : null;
    const boostedMessage = lastBoostPeriodHumanized
        ? t(i18n)`Ad boosted ${lastBoostPeriodHumanized} ago.`
        : null;

    if (showExtraDescriptionText || ad.virtualState === AdVirtualState.ACTIVE) {
        if (hasAutoBoost) {
            return expiryMessage;
        }
        if (hasSingleBoost) {
            return boostedMessage;
        }
        return null;
    }

    switch (ad.virtualState) {
        case AdVirtualState.FEATURED:
        case AdVirtualState.ELITE:
            return expiryMessage;

        case AdVirtualState.MODERATION_HARD_REJECT:
            return t(i18n)`Unfortunately this ad did not pass the check-up.`;

        case AdVirtualState.MODERATION_SOFT_REJECT:
            return t(i18n)`You can edit the ad and try posting it again.`;

        case AdVirtualState.MODERATION_PENDING:
        case AdVirtualState.FEATURED_PENDING:
        case AdVirtualState.ELITE_PENDING:
            return t(i18n)`This ad is being reviewed and it will be live soon.`;

        case AdVirtualState.LIMITED:
            if (canPostAdForFree) {
                return t(i18n)`You can post this ad for free.`;
            }

            if (durationTillNextFreePost) {
                return t(i18n)`Wait ${durationTillNextFreePost} or pay to post now.`;
            }

            return t(i18n)`No free ads for this category. Pay to post now.`;

        case AdVirtualState.OUTDATED:
            return t(i18n)`This ad expired. If you sold it, please mark it as sold.`;
        default:
            return null;
    }
};

const StateDescriptionText = ({ ad, showExtraDescriptionText }: Props) => {
    const description = useDescriptionText(ad, showExtraDescriptionText);

    return (
        <div aria-label="Ad state description">
            <Text.Base className={styles.description}>{description}</Text.Base>
        </div>
    );
};

export default StateDescriptionText;
