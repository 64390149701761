import propertyTransformer from 'strat/property/propertyTransformer';
import { transformLocalizedFields } from 'strat/mergedIndex';

import type { ProjectData, ProjectUnit } from './types';

const transformProjectUnit = (unit: ProjectUnit, lang: string): ProjectUnit => {
    const transformedUnit = transformLocalizedFields(unit, lang, true);
    transformedUnit.category = transformedUnit.category.map((category) =>
        transformLocalizedFields(category, lang, true),
    );

    return transformedUnit;
};

export const projectTransformer = (data: ProjectData, lang: string): ProjectData => {
    // TODO: Lift parts of the property transformer out so we can re-use them
    // here and have project-specific transformers. For now, there's so much
    // overlap that it gets us there for 90%. We'll refactor this as soon
    // as we have more project-specific data that requires transforming.
    const transformedData = propertyTransformer<ProjectData>(data, lang, true);

    if (transformedData.units) {
        transformedData.units = transformedData.units.map((unit) =>
            transformProjectUnit(unit, lang),
        );
    }

    return transformedData;
};
