import { t } from '@lingui/macro';
import * as React from 'react';

import { ResponsiveBottomSheet } from 'strat/modal/compact/bottomSheet';
import { useI18n } from 'strat/i18n/language';

import styles from './styles/badgeBottomSheet.cssm';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

const BadgeBottomSheet = ({ isOpen, onClose, children }: Props) => {
    const i18n = useI18n();

    return (
        <ResponsiveBottomSheet name="small-chip-bottom-sheet" onClose={onClose} isOpen={isOpen}>
            {children}
            <div onClick={onClose} className={styles.closeButton} aria-label="Close">
                {t(i18n)`Close`}
            </div>
        </ResponsiveBottomSheet>
    );
};

export default BadgeBottomSheet;
