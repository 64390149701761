import React from 'react';
import { Trans } from '@lingui/macro';

import ErrorIcon from '@app/assets/icons/iconError.svg';

import MessageDialog from './messageDialog';
import styles from './styles/errorDialog.cssm';

/**
 * Properties for {@see EmailSentDialog}.
 */
type Props = {
    visible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
    children?: React.ReactNode;
    text?: string;
};

/**
 * Displays a dialog informing the user that the e-mail
 * was succesfully send.
 */
export default ({ visible, onVisibilityChanged, children, text }: Props) => (
    <MessageDialog
        // @ts-expect-error - TS2322 - Type 'null' is not assignable to type '((props: FooterProps) => ReactElement<any, string | JSXElementConstructor<any>> | null | undefined) | undefined'.
        renderFooter={null}
        visible={visible}
        onVisibilityChanged={onVisibilityChanged}
        title={<Trans>Error!</Trans>}
        className={styles.container}
        icon={<ErrorIcon className={styles.icon} />}
    >
        {children || text}
    </MessageDialog>
);
