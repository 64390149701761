// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { GetState } from 'redux';
import { SearchRequestSortOption } from '@sector-labs/fe-search-redux/backend';
import { MultiJobSearchHandler } from 'strat/routes';

import type { SearchRouteParams } from 'horizontal/routes';
import type {
    CategoryConfigurations,
    LiteCategory,
    LiteHierarchicalLocation,
} from 'horizontal/types';

export type SearchProps = {
    readonly getState: GetState;
    readonly searchRouteParams: SearchRouteParams;
    readonly featuredSort?: SearchRequestSortOption | null | undefined;
    readonly categoryConfigurations: CategoryConfigurations;
};

export type UpdatedAdResponseProps = {
    searchHandler: MultiJobSearchHandler;
    location: LiteHierarchicalLocation;
    category: LiteCategory | null | undefined;
};

export const SearchJobName = Object.freeze({
    REGULAR: 'regular',
    FEATURED: 'featured',
    ELITE: 'elite',
    REMARKETING: 'remarketing',
});
