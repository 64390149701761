import React from 'react';
import { I18n } from '@lingui/core';
import { selectIsMobile } from 'strat/platform/selectors';
import { Flex, Text } from 'strat/components';
import { useSelector } from 'react-redux';

import { UserDTO } from 'horizontal/dtos';
import { defaultUserName } from 'horizontal/user';
import VerifiedProfileIcon from 'horizontal/profile/verifiedProfileIcon';
import VerifiedProfileBadge from 'horizontal/profile/verifiedProfileBadge';
import { CreationDate } from 'horizontal/profile';
import { Agency } from 'horizontal/types';

import styles from './styles/adDetailsSellerDetails.cssm';

type Props = {
    readonly i18n: I18n;
    readonly seller: UserDTO | Agency;
    readonly isVerified?: boolean;
    readonly isAgency?: boolean;
};
const AdDetailsSellerDetails = ({ i18n, seller, isVerified, isAgency }: Props) => {
    const sellerName = seller.name || defaultUserName(i18n);
    const isMobile = useSelector(selectIsMobile);

    return (
        <Flex column justifyCenter className={styles.name}>
            <Flex alignCenter>
                <Text.Regular bold>{sellerName}</Text.Regular>
                {!isMobile && isVerified && <VerifiedProfileIcon leftMargin large />}
            </Flex>
            {isMobile && isVerified && (
                <div className={styles.verifiedBadge}>
                    <VerifiedProfileBadge isAgency={isAgency} />
                </div>
            )}
            <div className={styles.creationDate}>
                <CreationDate user={seller} />
            </div>
        </Flex>
    );
};

export default AdDetailsSellerDetails;
