import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';

const Area = Object.freeze({
    SQFT: 'SQFT',
    SQYD: 'SQYD',
    SQM: 'SQM',
    MARLA: 'MARLA',
    KANAL: 'KANAL',
    SQWA: 'SQWA',
    RAI: 'RAI',
    NGAN: 'NGAN',
    KATHA: 'KATHA',

    text(i18n: I18n, area: any) {
        switch (area) {
            case this.SQFT:
                return t(i18n)`Square Feet`;
            case this.SQYD:
                return t(i18n)`Square Yards`;
            case this.SQM:
                return t(i18n)`Square Meters`;
            case this.MARLA:
                return t(i18n)`Marla`;
            case this.KANAL:
                return t(i18n)`Kanal`;
            case this.SQWA:
                return t(i18n)`Square Wa`;
            case this.RAI:
                return t(i18n)`Rai`;
            case this.NGAN:
                return t(i18n)`Ngan`;
            case this.KATHA:
                return t(i18n)`Katha`;
            default:
                return t(i18n)`Square Feet`;
        }
    },

    abbreviation(i18n: I18n, area: any) {
        switch (area) {
            case this.SQFT:
                return t(i18n)`sqft`;
            case this.SQYD:
                return t(i18n)`Sq. Yd.`;
            case this.SQM:
                return t(i18n)`Sq. M.`;
            case this.MARLA:
                return t(i18n)`Marla`;
            case this.KANAL:
                return t(i18n)`Kanal`;
            case this.SQWA:
                return t(i18n)`Sq. Wa`;
            case this.RAI:
                return t(i18n)`Rai`;
            case this.NGAN:
                return t(i18n)`Ngan`;
            case this.KATHA:
                return t(i18n)`Katha`;
            default:
                return t(i18n)`sqft`;
        }
    },

    formattingSettings(area: any) {
        switch (area) {
            case this.KANAL:
            case this.MARLA:
                return {
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 0,
                };
            case this.KATHA:
                return {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                };
            default:
                return {};
        }
    },

    /**
     * Gets whether rounding should be applied to the unit.
     */
    roundingFunction(area: any) {
        if (
            area === this.KANAL ||
            area === this.MARLA ||
            area === this.SQWA ||
            area === this.NGAN
        ) {
            // If the area is like x.y5 Kanal, use x.y instead of x.(y+1)
            // (e.g. 0.25 => 0.2, instead of 0.3).
            // This cannot be done using Intl.NumberFormat, so we have to treat it here.
            return (x: any) =>
                x * 10 - Math.floor(x * 10) === 0.5
                    ? Math.floor(x * 10) / 10
                    : Math.round(x * 10) / 10;
        }

        if (area === this.RAI || area === this.KATHA) {
            // for Rai and Katha we should use 2 decimals
            return (x: any) =>
                x * 10 - Math.floor(x * 10) === 0.5
                    ? Math.floor(x * 100) / 100
                    : Math.round(x * 100) / 100;
        }

        return Math.round;
    },

    /**
     * Gets whether the specified value is a valid area.
     */
    isValidValue(value: string): boolean {
        return this.all().includes(value);
    },

    /**
     * Gets all available area units.
     */
    all() {
        return [
            this.SQFT,
            this.SQYD,
            this.SQM,
            this.MARLA,
            this.KANAL,
            this.SQWA,
            this.RAI,
            this.NGAN,
            this.KATHA,
        ];
    },
});

export default Area;
