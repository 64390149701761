import { SessionActionKey } from '@sector-labs/fe-auth-redux';
import type { Action, Store } from 'redux';
import { getKeycloakSessionRefreshTimer } from 'strat/auth/keycloak';

import chatSDKModuleInitializer from 'horizontal/chat/sdk';

const TOKEN_FETCH_REQUEST_ACTION = 'cable/connection/refreshFetch';

const appTokenRefreshMiddleware =
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    (_: Store) => (next: (arg1: Action) => unknown) => (action: Action) => {
        if ([SessionActionKey.STORE, SessionActionKey.RESET].includes(action.type)) {
            // Set timeout because we want this to apply after the store is updated by this action.
            setTimeout(() => chatSDKModuleInitializer.refresh(), 0);
        } else if (action.type === TOKEN_FETCH_REQUEST_ACTION) {
            // Cable chat sends out hints that token is about to expire and the app
            // should refresh it.
            getKeycloakSessionRefreshTimer()?.refresh();
        }

        return next(action);
    };

const middlewares = [appTokenRefreshMiddleware];

export default middlewares;
