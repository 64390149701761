import logger from 'strat/logger';

import {
    PackageType,
    PackageTypeValues,
    ProductPackage,
    ProductPackageOffer,
    ProductPurchase,
} from 'horizontal/types';
import { getProductPackageType } from 'horizontal/packages/utils';

const useGroupProductPackagesByType = <T extends ProductPackageOffer | ProductPurchase>(
    items: Array<T> | undefined | null,
    selectProductPackage: (item: T) => ProductPackage,
): {
    [key in PackageTypeValues]: Array<T>;
} => {
    const groupedItems: {
        [key in PackageTypeValues]: Array<T>;
    } = {};
    Object.values(PackageType).forEach((key: PackageTypeValues) => {
        groupedItems[key] = [];
    });

    if (items) {
        items.forEach((item) => {
            const productPackageOfferType = getProductPackageType(selectProductPackage(item));

            if (productPackageOfferType) {
                groupedItems[productPackageOfferType].push(item);
            } else {
                logger.error(
                    `${JSON.stringify(PackageType)} package has unsupported package type, skipped.`,
                );
            }
        });
    }
    return groupedItems;
};

export default useGroupProductPackagesByType;
