import shuffle from 'lodash/shuffle';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { FilterCollection, ExactFilter, RangeFilter } from '@sector-labs/fe-search-redux/filters';

import { getAgencySearchFilters } from '@app/agency/filters/getAgencySearchFilters';
import FetcherFactory from 'strat/fetcher';
import { determineAgenciesIndexName } from 'strat/search/indexNames';

/**
 * Factory for creating action creators and reducers
 * for fetching featured agencies from the back-end.
 */
const featuredAgenciesFactory = new FetcherFactory(
    'featuredAgencies',
    (params: any, state: any) => {
        // Override the attributes to retrieve.
        // This is different from the default index and attributes.
        const attributesToRetrieve = [
            'id',
            'name',
            'externalID',
            'logo',
            'agentsCount',
            'locations',
            'slug',
            'stats',
            'phoneNumber',
            'isFeatured',
        ];

        const settings = Object.assign({}, state.algolia.settings, { attributesToRetrieve });

        const filterCollection = new FilterCollection();
        filterCollection.refine(new ExactFilter({ attribute: 'isFeatured', value: true }));
        filterCollection.refine(
            new RangeFilter({ attribute: 'stats.adsCount', value: { min: 1, max: null } }),
        );

        const job = new SearchJob(
            determineAgenciesIndexName({ language: state.i18n.language }),
            getAgencySearchFilters(filterCollection),
            settings,
        );

        const backend = selectActiveSearchBackend(state);
        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend });

        return service
            .fetchJob(job)
            .then((content) => {
                const shuffledAgencies = shuffle(content.hits);

                return {
                    data: {
                        hits: shuffledAgencies,
                    },
                    status: 200,
                };
            })
            .catch(() => ({
                data: {
                    hits: [],
                },
                status: 404,
            }));
    },
);

/**
 * Fetches featured agencies from algolia.
 */
const fetchFeaturedAgencies = featuredAgenciesFactory.creator();

/**
 * Reducer for featured agencies.
 */
const featuredAgenciesReducer = featuredAgenciesFactory.reducer();

export { fetchFeaturedAgencies };

export default featuredAgenciesReducer;
