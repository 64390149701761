import * as React from 'react';
import { Trans } from '@lingui/macro';

const PaymentInfoText = () => {
    return (
        <Trans>
            <b>Pay in full </b> using the amount of credit in your dubizzle Wallet.
        </Trans>
    );
};

export default PaymentInfoText;
