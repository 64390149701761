import { t } from '@lingui/macro';
import * as React from 'react';
import { SessionErrorKey, useSessionError, useResetSessionError } from '@sector-labs/fe-auth-redux';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

/**
 * The profile fields that can be edited.
 */
export const ProfileField = Object.freeze({
    EMAIL: 'email',
    PHONE: 'phone',
});

const getValidationErrorMessage = (
    i18n: I18n,
    editedProfileField?: Values<typeof ProfileField>,
): string => {
    switch (editedProfileField) {
        case ProfileField.EMAIL:
            return t(i18n)`Invalid email was provided`;
        case ProfileField.PHONE:
            return t(i18n)`Invalid phone number was provided`;
        default:
            return t(i18n)`Invalid input was provided`;
    }
};

const getAlreadyInUseErrorMessage = (
    i18n: I18n,
    editedProfileField?: Values<typeof ProfileField>,
): string => {
    switch (editedProfileField) {
        case ProfileField.EMAIL:
            return t(i18n)`This email is already used for another account`;
        case ProfileField.PHONE:
            return t(i18n)`This phone number is already used for another account`;
        default:
            return t(i18n)`Input already used for another account`;
    }
};

const useProfileEditErrorMessage = (
    editedProfileField?: Values<typeof ProfileField>,
): [string | null | undefined, () => void, Error | null] => {
    const i18n = useI18n();
    const error = useSessionError();
    const resetError = useResetSessionError();

    const errorMessage = React.useMemo(() => {
        if (!error) {
            return null;
        }

        const validationErrorMessage = getValidationErrorMessage(i18n, editedProfileField);
        const alreadyInUseErrorMessage = getAlreadyInUseErrorMessage(i18n, editedProfileField);

        switch (error.name) {
            case SessionErrorKey.VALIDATION_ERROR:
                return validationErrorMessage;
            case SessionErrorKey.ALREADY_IN_USE:
                return alreadyInUseErrorMessage;
            case SessionErrorKey.INVALID_OTP_TOKEN:
                return t(i18n)`The provided code is not correct`;
            default:
                return t(i18n)`An unknown error occurred.`;
        }
    }, [error, i18n, editedProfileField]);

    return [errorMessage, resetError, error];
};

export default useProfileEditErrorMessage;
