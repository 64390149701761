import * as React from 'react';
import { ScheduleScope } from 'strat/scheduler';
import { trigger, Triggers, ViewSections } from 'strat/gtm';

import type { Location } from 'horizontal/types';

import { getLocationVars } from './utils';

const useTrackLocationClick = () => {
    return React.useCallback((triggerName: Values<typeof Triggers>, location?: Location | null) => {
        const locationVariables = location ? getLocationVars(location.hierarchy) : {};
        trigger(
            triggerName,
            {
                ...locationVariables,
                view_section: ViewSections.HEADER,
            },
            ScheduleScope.AFTER_RENDER,
        );
    }, []);
};

export default useTrackLocationClick;
