import type { LocationData } from 'strat/property';

import joinList from './joinList';
import getTrackingLocationID from './getTrackingLocationID';

const getLocationsVariables = (locations: LocationData) => {
    const variables: Record<string, any> = {};
    for (let level = 1; level <= 4; level++) {
        const locationsWithLevel = locations
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            .filter((loc) => !!loc.hierarchy[level])
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            .map((loc) => loc.hierarchy[level]);
        variables[`loc_${level}_id`] = joinList(locationsWithLevel.map(getTrackingLocationID));
        variables[`loc_${level}_name`] = joinList(locationsWithLevel.map((loc) => loc.name));
    }
    return variables;
};

export default getLocationsVariables;
