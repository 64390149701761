import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Logo from '@app/branding/logo';
import { PageWidthWrapper } from 'strat/generic';
import { useDialog } from 'strat/dialogs/dialogContext';
import { useIsScrollingUp } from 'strat/util';
import Category from '@app/branding/category';
import { selectRouteName } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';
import useHeaderLinks from '@app/header/compact/useHeaderLinks';
// eslint-disable-next-line no-restricted-imports
import styles from '@app/header/compact/styles/headerWithVerticals.cssm';
import { Header as BaseHeader } from 'strat/components';
import { Flex } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { KeycloakGoogleOneTap } from 'strat/auth/keycloak';

import HeaderLinks from '@app/header/compact/headerLinks';
import { ActiveLocationDialogTrigger } from 'horizontal/search/location/compact';
import { FreeTextSearchDialogTrigger } from 'horizontal/search/freeText/compact';
import { DialogNames } from 'horizontal/dialogs';
import { Notifications } from 'horizontal/notifications/compact';
import MenuIcon from 'horizontal/assets/icons/menuIcon_noinline.svg';
import { useCategoryFilterValue } from 'horizontal/search/category';
import { useAd } from 'horizontal/ad/state';

import ActivityIndicator from '../activityIndicator';

type Props = {
    readonly withLocationsDialog?: boolean;
    readonly withFreeTextSearchDialog?: boolean;
    readonly className?: string;
    readonly withHighlightedVertical?: boolean;
};

const HeaderWithVerticals = ({
    withLocationsDialog,
    withFreeTextSearchDialog,
    className,
    withHighlightedVertical,
}: Props) => {
    const user = useActiveUser();
    const [, setUserCenterDialogVisible] = useDialog(DialogNames.USER_CENTER_DIALOG);
    const isScrollingUp = useIsScrollingUp();
    const categoryFilterValue = useCategoryFilterValue();
    const ad = useAd();
    const router = useRouter();
    const routeName = useSelector(selectRouteName);
    const category =
        routeName === RouteNames.SEARCH ? categoryFilterValue : ad?.category?.slice(-1)[0];

    const headerLinks = useHeaderLinks();

    const isHighlightedVertical =
        withHighlightedVertical &&
        // @ts-expect-error - TS2531 - Object is possibly 'null'.
        (router.context.match.params.vertical ||
            Category.isOfCarsOrCarsOnInstallmentsType(category) ||
            Category.isOfPropertySubtype(category));

    return (
        <>
            <PageWidthWrapper
                className={classNames(styles.content, className, {
                    [styles.verticalScrollUp]: isScrollingUp,
                })}
            >
                <Flex column stretchWidth>
                    <Flex alignCenter stretchWidth className={styles.verticalContainer}>
                        <span
                            className={styles.burgerButton}
                            aria-label="Menu"
                            onClick={() => setUserCenterDialogVisible(true)}
                        >
                            <svg className={styles.icon}>
                                <use xlinkHref={`${MenuIcon}#menu`} />
                            </svg>
                        </span>
                        <Flex
                            className={classNames(styles.logoContainer, {
                                [styles.updatedLogoContainerMargin]:
                                    CONFIG.runtime.ENABLE_OLX_HEADER_UPDATED_DESIGN,
                            })}
                        >
                            <Logo
                                small
                                className={classNames({
                                    [styles.noHighlight]: isHighlightedVertical,
                                    [styles.hideOnSmallScreens]: headerLinks.length >= 2,
                                })}
                            />
                            <Logo
                                tiny
                                className={classNames(styles.hide, {
                                    [styles.showOnSmallScreens]: headerLinks.length >= 2,
                                })}
                            />
                            <HeaderLinks withHighlightedVertical={withHighlightedVertical} />
                        </Flex>
                    </Flex>
                    <div
                        className={classNames(styles.locationWrapper, {
                            [styles.extraTopMargin]: !!withLocationsDialog,
                        })}
                    >
                        <ActivityIndicator />
                        {withLocationsDialog && <ActiveLocationDialogTrigger reversed />}
                        <KeycloakGoogleOneTap />
                        {withLocationsDialog && user && <Notifications hiddenTitle />}
                    </div>
                </Flex>
            </PageWidthWrapper>
            {withFreeTextSearchDialog && (
                <BaseHeader
                    className={classNames(styles.scrollDownHeader, {
                        [styles.scrollUpHeader]: isScrollingUp,
                        [styles.scrollUpHeaderLocation]: isScrollingUp && withLocationsDialog,
                        [styles.scrollUpHeaderActiveUser]:
                            isScrollingUp && !!user && withLocationsDialog,
                    })}
                >
                    <FreeTextSearchDialogTrigger className={styles.searchBar} />
                </BaseHeader>
            )}
        </>
    );
};

export default HeaderWithVerticals;
