import { useSelector } from 'react-redux';
import EMPTY_ARRAY from 'strat/empty/array';
import { FilterValues } from 'strat/search';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import type { GlobalState } from 'horizontal/state';
import type { LiteAd } from 'horizontal/types';
import { AdExtraFieldsKeys } from 'horizontal/ad';

const useGenericRelatedAds = (): ReadonlyArray<LiteAd> =>
    useSelector(
        (state: GlobalState) => state.relatedAds?.data?.genericRelatedAds.ads || EMPTY_ARRAY,
    );

const useGenericRelatedAdsFilters = () => {
    const stateFilters = useSelector(
        (state: GlobalState) => state.relatedAds?.data?.genericRelatedAds.filters,
    );

    if (!stateFilters) {
        return {};
    }

    const filters = new FilterCollection(stateFilters);

    const externalID = filters?.getFilterValue(FilterValues.externalID.attribute);
    const extraFields = {
        price: filters?.getFilterValue('extraFields.price'),
        [FilterValues.locationCoordinates.attribute]: filters?.getFilterValue(
            FilterValues.locationCoordinates.attribute,
        ),
    };
    const category = filters?.getFilterValue(FilterValues.category.attribute);
    const geoPoint = filters?.getFilterValue(FilterValues.locationCoordinates.attribute);

    return { externalID, extraFields, category, geoPoint };
};

const useFilteredRelatedAds = (): ReadonlyArray<LiteAd> =>
    useSelector(
        (state: GlobalState) => state.relatedAds?.data?.filteredRelatedAds.ads || EMPTY_ARRAY,
    );

const useFilteredRelatedAdsFilters = () => {
    const stateFilters = useSelector(
        (state: GlobalState) => state.relatedAds?.data?.filteredRelatedAds.filters,
    );

    if (!stateFilters) {
        return {};
    }

    const filters = new FilterCollection(stateFilters);

    const externalID = filters?.getFilterValue(FilterValues.externalID.attribute);
    const extraFields = {
        price: filters?.getFilterValue('extraFields.price'),
        [AdExtraFieldsKeys.CONDITION]: filters?.getFilterValue(
            `extraFields.${AdExtraFieldsKeys.CONDITION}`,
        ),
        [FilterValues.locationCoordinates.attribute]: filters?.getFilterValue(
            FilterValues.locationCoordinates.attribute,
        ),
    };
    const category = filters?.getFilterValue(FilterValues.category.attribute);
    const geoPoint = filters?.getFilterValue(FilterValues.locationCoordinates.attribute);

    return { externalID, extraFields, category, geoPoint };
};

const useRelatedAdsAreLoading = () =>
    useSelector((state: GlobalState) => state.relatedAds?.loading);

export {
    useGenericRelatedAds,
    useGenericRelatedAdsFilters,
    useFilteredRelatedAds,
    useFilteredRelatedAdsFilters,
    useRelatedAdsAreLoading,
};
