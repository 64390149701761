import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

import { defaultPaginationParams } from './state';
import type { NotificationsPageMap, PaginatedNotificationsState } from './state';

const selectUserNotificationsState = (state: any) => state.userNotifications;

export const selectLoading = createSelector(selectUserNotificationsState, (state) => state.loading);

export const selectUserNotificationsPages = createSelector(
    selectUserNotificationsState,
    (state: PaginatedNotificationsState) => state.pages || {},
);

export const selectNotifications = createSelector(
    selectUserNotificationsPages,
    (state: NotificationsPageMap) =>
        Object.entries(state)
            .sort(([pageID, _]: [any, any], [otherPageID, __]: [any, any]) =>
                pageID < otherPageID ? -1 : 1,
            )
            .flatMap(([_, page]: [any, any]) => {
                if (typeof page === 'object' && page !== null) {
                    return page.results;
                }
                return EMPTY_ARRAY;
            }),
);

export const selectNotificationsCount = createSelector(
    selectUserNotificationsPages,
    (state: NotificationsPageMap) => state[defaultPaginationParams.page]?.count || 0,
);
