import * as React from 'react';
import classNames from 'classnames';

import * as Text from './text';
import type { RenderChoiceProps } from './options';
import type { ChoiceValue } from './types';
import styles from './styles/squareishButtonChoice.cssm';

const SquareishButtonChoice = <T extends ChoiceValue>({
    option,
    isSelected,
    onChange,
    isAutofilled,
}: RenderChoiceProps<T>) => (
    <div
        key={option.id}
        className={classNames(styles.choice, {
            [styles.selected]: isSelected(option.value),
            [styles.autofilled]: isAutofilled,
        })}
        onClick={() => onChange(option.value)}
    >
        <Text.Regular>{option.label}</Text.Regular>
    </div>
);

export default SquareishButtonChoice;
