import type { Primitive } from 'strat/types';

import type { FlatCategoryField } from 'horizontal/types';

import useDisplayPriorityFilteredChoices from './useDisplayPriorityFilteredChoices';
import useSortedFacetedChoices from './useSortedFacetedChoices';
import useFacetedChoices from './useFacetedChoices';

const useDisplayPriorityFilteredFacetedChoices = (
    field: FlatCategoryField,
    filterValue: Primitive,
) =>
    useDisplayPriorityFilteredChoices(
        // @ts-expect-error - TS2345 - Argument of type 'Primitive' is not assignable to parameter of type 'ChoiceValue | ChoiceValue[] | undefined'.
        useSortedFacetedChoices(useFacetedChoices(field), filterValue),
    );

export default useDisplayPriorityFilteredFacetedChoices;
