import { useSelector } from 'react-redux';
import { selectSearchHitsLayout } from 'strat/settings/selectors';
import settings from '@app/branding/settings';

import SearchHitsLayout from 'horizontal/branding/searchHitsLayout';

const useSearchHitsLayout = (): Values<typeof SearchHitsLayout> => {
    const selectedLayout = useSelector(selectSearchHitsLayout);
    if (settings.enableLayoutSwitcher) {
        // We don't directly return selectedLayout because the user might have an outdated version in his settings, like GALLERY
        return selectedLayout === SearchHitsLayout.GRID
            ? SearchHitsLayout.GRID
            : SearchHitsLayout.LIST;
    }
    return SearchHitsLayout.LIST;
};

export default useSearchHitsLayout;
