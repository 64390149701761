import { useSelector } from 'react-redux';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import { AgentForSearchCard } from 'strat/leaderboard/types';
import shouldFetchFromCube from 'strat/agency/agent/state/shouldFetchFromCube';
import { getCubeRankingField } from 'strat/agency/search/form/fetchDataFromProBoard';

/**
 * This object is different from `MiniLeaderboardIndexRankKeyMapping` from `minileaderboard/types`.
 * This is used to map the ranking fields that should be used in agent search.
 * `MiniLeaderboardIndexRankKeyMapping` is used to map the ranking fields that must be used for
 * the mini leaderboard from the agent detail page.
 */

export const STRAT_RANKING_FIELDS_MAPPING: { [key: string]: keyof AgentForSearchCard } = {
    top_location_category_purpose_trubroker_first_ranking:
        'cubeLocationCategoryPurposeTruBrokerRanking',
    top_location_purpose_trubroker_first_ranking: 'cubeLocationPurposeTruBrokerRanking',
    top_location_category_trubroker_first_ranking: 'cubeLocationCategoryTruBrokerRanking',
    top_location_trubroker_first_ranking: 'cubeLocationTruBrokerRanking',
    top_category_purpose_trubroker_first_ranking: 'cubeCategoryPurposeTruBrokerRanking',
    top_purpose_trubroker_first_ranking: 'cubePurposeTruBrokerRanking',
    top_category_trubroker_first_ranking: 'cubeCategoryTruBrokerRanking',
    trubroker_first_ranking_overall: 'truBrokerRanking',
};

const useAgentRank = (agent: AgentForSearchCard) => {
    const filters = useSelector(getFilterCollection);
    const shouldUseCubeValues = shouldFetchFromCube(filters);
    if (!shouldUseCubeValues) {
        return agent.truBrokerRanking;
    }
    const cubeRankingField = getCubeRankingField(filters, []);
    const stratRankingField = STRAT_RANKING_FIELDS_MAPPING[cubeRankingField];
    return agent[stratRankingField] as number;
};

export default useAgentRank;
