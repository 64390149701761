import * as React from 'react';
import classNames from 'classnames';
import { PaginationContainer } from 'strat/search/pagination';
import { PaginationRenderer } from 'strat/searchComponents/pagination';
import Link from 'strat/navigation/link';

import styles from './styles/pagination.cssm';

type Props = {
    readonly activePage: number;
    readonly totalPages: number;
    readonly maxVisiblePages?: number;
    readonly routeName: string;
    readonly routeParams: any;
};

const Pagination = ({
    activePage,
    maxVisiblePages = 2,
    totalPages,
    routeName,
    routeParams,
}: Props) => {
    const renderElement = React.useCallback(
        (element: React.ReactNode, page: number, isCurrentPage: boolean): React.ReactElement => {
            if (isCurrentPage) {
                return (
                    <div className={classNames(styles.element, styles.currentPage)}>{element}</div>
                );
            }

            const onClick = () => {
                window.scrollTo({ top: 0 });
            };

            return (
                <Link
                    className={styles.element}
                    route={routeName}
                    params={{ ...routeParams, page }}
                    onClick={onClick}
                >
                    {element}
                </Link>
            );
        },
        [routeName, routeParams],
    );

    return (
        <PaginationContainer className={styles.pagination}>
            <PaginationRenderer
                activePage={activePage}
                pageCount={totalPages}
                renderElement={renderElement}
                maxVisible={maxVisiblePages}
                withFirstPageButton={false}
            />
        </PaginationContainer>
    );
};

export default Pagination;
