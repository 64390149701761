import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex, Text } from 'strat/components';
import { LoadingSpinner } from 'strat/loadable';
import { useI18n } from 'strat/i18n/language';

import { ErrorBanner } from 'horizontal/packages/individual';
import type { FullAd, ProductPackageOffer } from 'horizontal/types';
import { hasExample } from 'horizontal/packages/utils';
import { PackageTypeValues, ProductType } from 'horizontal/types';
import { UpsellingCTA } from 'horizontal/packages/components/types';

import PackagesOffersPromo from '../components/packagesOffersPromo';
import OfferPackageOptionsContainer from '../offerPackageOptionsContainer';
import { UpsellingCurrencySelection, PackagesCTASection, PackagesExamples } from '../components';
import { PackagesFlowTypeValues, PackagesSection } from '../types';

import styles from './styles/selectSinglePackagePageMobile.cssm';

type Props = {
    readonly loading: boolean;
    readonly activeExample?: string | null;
    readonly closeExample: () => void;
    readonly offerPackages: Array<ProductPackageOffer> | null | undefined;
    readonly offerPackagesSections: Array<PackagesSection<ProductPackageOffer>> | null;
    readonly ad: FullAd | null | undefined;
    readonly selectedValue: string | null | undefined;
    readonly flow: PackagesFlowTypeValues;
    readonly viewAd: () => void;
    readonly onItemClicked: (choice: ProductPackageOffer) => void;
    readonly openExample?: (type: Values<typeof ProductType> | PackageTypeValues) => void;
    readonly mainCTA: UpsellingCTA;
    readonly renderBanner: (() => React.ReactNode) | null;
    readonly errorMessage?: string;
};

const isOfferPackagesEmpty = (offerPackages: Array<ProductPackageOffer> | null | undefined) => {
    return offerPackages && offerPackages.length == 0;
};

const SelectSingleOfferPackage = ({
    loading,
    activeExample,
    closeExample,
    offerPackages,
    offerPackagesSections,
    ad,
    selectedValue,
    flow,
    viewAd,
    onItemClicked,
    openExample,
    mainCTA,
    renderBanner,
    errorMessage,
}: Props) => {
    const i18n = useI18n();

    const offerPackagesEmpty = isOfferPackagesEmpty(offerPackages);

    if (loading) {
        return (
            <Flex justifyCenter alignCenter fillContainer>
                <LoadingSpinner />
            </Flex>
        );
    }

    return (
        <>
            <PackagesExamples activeExample={activeExample} closeExample={closeExample} />
            <div className={styles.contentScrollContainer} aria-label="Package offer screen">
                <Flex column className={styles.content}>
                    {renderBanner?.()}
                    {errorMessage ? <ErrorBanner error={errorMessage} /> : null}
                    {!offerPackagesEmpty ? <UpsellingCurrencySelection /> : null}
                    <>
                        {offerPackagesSections?.map((section) => (
                            <OfferPackageOptionsContainer
                                key={section.packageType}
                                ad={ad}
                                onOptionClicked={onItemClicked}
                                title={section.title}
                                description={section.description}
                                choices={section.items}
                                packageType={section.packageType}
                                selectedChoice={selectedValue}
                                openExample={
                                    hasExample(section.packageType) && openExample
                                        ? () => openExample(section.packageType)
                                        : undefined
                                }
                            />
                        ))}
                    </>
                    <Flex column alignCenter>
                        {offerPackagesEmpty ? (
                            <Text.Large className={styles.promoHint}>{t(
                                i18n,
                            )`Discover how you can promote your ad.`}</Text.Large>
                        ) : null}
                        <PackagesOffersPromo
                            text={t(i18n)`Buy in bundles and pay less!`}
                            ad={ad}
                            availableAdPackages={offerPackages || []}
                        />
                    </Flex>
                </Flex>
            </div>
            <PackagesCTASection
                flow={flow}
                mainCTA={mainCTA}
                viewAd={viewAd}
                hasItems={!offerPackagesEmpty}
            />
        </>
    );
};

export default SelectSingleOfferPackage;
