import * as React from 'react';
import { useSelector } from 'react-redux';
import { TemplateType } from 'strat/templates';
import { useListingTemplate, ListingPageTitle, ListingMetaDescription } from 'strat/listing';

import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import {
    useTitle,
    useMetaDescription,
    useCanonicalURL,
    useMetaRobotsDirective,
    PaginationLinks,
} from 'horizontal/search/seo';
import {
    selectCustomMetaDescription,
    selectCustomMetaTitle,
} from 'horizontal/urlSpecificSEO/selectors';

import CommonHead from './commonHead';

const SearchHead = () => {
    const params = useCurrentSearchRouteParams();
    const title = useTitle(params);
    const metaDescription = useMetaDescription(params);
    const { url, alternateURLs } = useCanonicalURL(params);
    const robotsDirective = useMetaRobotsDirective(params).result;
    // If there are specific custom metaTitle/metaDescriptions available, don't use templates
    const useTitleTemplate = !useSelector(selectCustomMetaTitle);
    const useMetaDescriptionTemplate = !useSelector(selectCustomMetaDescription);

    const { text: titleTemplate, isDefault: isDefaultTitleTemplate } = useListingTemplate(
        TemplateType.TITLE,
    );
    const { text: metaDescriptionTemplate, isDefault: isDefaultMetaDescriptionTemplate } =
        useListingTemplate(TemplateType.META);

    // Extra fields or the free text query cannot be used in templates yet
    // so they're pretty useless. We disable templating for pages with extra
    // field filters or a free text query so that the template doesn't override
    // when those filters are active. Remove this condition when templates
    // support extra fields and/or free text queries.
    const useTemplates =
        params.extraFields && !Object.keys(params.extraFields).length && !params.freeTextQuery;

    return (
        <>
            <ListingPageTitle>
                {useTitleTemplate && useTemplates && titleTemplate && !isDefaultTitleTemplate
                    ? titleTemplate
                    : title}
            </ListingPageTitle>
            <ListingMetaDescription>
                {useMetaDescriptionTemplate &&
                useTemplates &&
                metaDescriptionTemplate &&
                !isDefaultMetaDescriptionTemplate
                    ? metaDescriptionTemplate
                    : metaDescription}
            </ListingMetaDescription>
            {!!robotsDirective && <meta name="robots" content={robotsDirective} />}
            <CommonHead url={url} alternateURLs={alternateURLs} />
            <PaginationLinks />
        </>
    );
};

export default SearchHead;
