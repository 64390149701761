import AgencyPortalActionCenterRoute from './agencyPortalActionCenterRoute';
import AgencyPortalAdsRoute from './agencyPortalAdsRoute';
import AgencyPortalAgentsRoute from './agencyPortalAgentsRoute';
import AgencyPortalCompanyProfileRoute from './agencyPortalCompanyProfileRoute';
import AgencyPortalCreateAdRoute from './agencyPortalCreateAdRoute';
import AgencyPortalUpdateAdRoute from './agencyPortalUpdateAdRoute';
import AgencyPortalPostAdSuccessRoute from './agencyPortalPostAdSuccessRoute';
import AgencyPortalApplyAdLimitRoute from './agencyPortalApplyAdLimitRoute';
import AgencyPortalHelpRoute from './agencyPortalHelpRoute';
import AgencyPortalHomeRoute from './agencyPortalHomeRoute';
import AgencyPortalLeadsRoute from './agencyPortalLeadsRoute';
import AgencyPortalPackagesRoute from './agencyPortalPackagesRoute';
import AgencyPortalPackageDetailRoute from './agencyPortalPackageDetailRoute';
import AgencyPortalSelectPackageSuccessRoute from './agencyPortalSelectPackageSuccessRoute';
import AgencyPortalNotFoundRoute from './agencyPortalNotFoundRoute';
import AgencyPortalAgentDetailRoute from './agencyPortalAgentDetailRoute';
import AgencyPortalApplyAdProductsCreditCostRoute from './agencyPortalApplyAdProductsCreditCostRoute';
import AgencyPortalCreditInfoRoute from './agencyPortalCreditInfoRoute';

export {
    AgencyPortalActionCenterRoute,
    AgencyPortalAdsRoute,
    AgencyPortalAgentsRoute,
    AgencyPortalCompanyProfileRoute,
    AgencyPortalCreateAdRoute,
    AgencyPortalUpdateAdRoute,
    AgencyPortalPostAdSuccessRoute,
    AgencyPortalApplyAdLimitRoute,
    AgencyPortalHelpRoute,
    AgencyPortalHomeRoute,
    AgencyPortalLeadsRoute,
    AgencyPortalPackagesRoute,
    AgencyPortalPackageDetailRoute,
    AgencyPortalSelectPackageSuccessRoute,
    AgencyPortalAgentDetailRoute,
    AgencyPortalApplyAdProductsCreditCostRoute,
    AgencyPortalCreditInfoRoute,
    AgencyPortalNotFoundRoute,
};

export default CONFIG.build.ENABLE_OLX_AGENCY_PORTAL
    ? [
          AgencyPortalActionCenterRoute,
          AgencyPortalAdsRoute,
          AgencyPortalAgentsRoute,
          AgencyPortalCompanyProfileRoute,
          AgencyPortalCreateAdRoute,
          AgencyPortalUpdateAdRoute,
          AgencyPortalPostAdSuccessRoute,
          AgencyPortalApplyAdLimitRoute,
          AgencyPortalHelpRoute,
          AgencyPortalHomeRoute,
          AgencyPortalLeadsRoute,
          AgencyPortalPackagesRoute,
          AgencyPortalPackageDetailRoute,
          AgencyPortalSelectPackageSuccessRoute,
          AgencyPortalAgentDetailRoute,
          AgencyPortalApplyAdProductsCreditCostRoute,
          AgencyPortalCreditInfoRoute,
          AgencyPortalNotFoundRoute,
      ]
    : [];
