import * as React from 'react';
import { Flex, Text } from 'strat/components';

import { FullAd, PackageTypeValues } from 'horizontal/types';
import type { ProductPurchase } from 'horizontal/types';
import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';

import ProductPurchaseChoiceBox from './components/productPurchaseChoiceBox';
import styles from './styles/packageOptionsContainer.cssm';

type Props = {
    ad: FullAd | null | undefined;
    choices: Array<ProductPurchase>;
    onOptionClicked: (choice: ProductPurchase) => void;
    selectedChoice: number | null | undefined;
    title: string;
    packageType: PackageTypeValues;
};

const ProductPurchaseOptionsContainer = ({
    ad,
    onOptionClicked,
    choices,
    selectedChoice,
    title,
    packageType,
}: Props) => {
    const iconSrc = usePackagesIcons({ type: packageType });

    if (!choices.length) {
        return null;
    }

    return (
        <Flex column className={styles.container} aria-label="Package offer screen">
            <Text.Large>{title}</Text.Large>
            <>
                {choices.map((choice) => (
                    <ProductPurchaseChoiceBox
                        ad={ad}
                        icon={iconSrc}
                        onClick={() => onOptionClicked(choice)}
                        key={choice.id}
                        choice={choice}
                        selected={selectedChoice === choice.id}
                    />
                ))}
            </>
        </Flex>
    );
};

export default ProductPurchaseOptionsContainer;
