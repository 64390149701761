import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import { CategoryListItem } from '@app/adCreation/categoryPicker/categoryListItem';
import { selectCategories } from 'horizontal/categories';
import { Category } from 'horizontal/types';

import SubcategoryPicker from './subcategoryPicker';
import styles from './styles/categoryPicker.cssm';

const getNumberOfCategoryLevels = (category: Category): number => {
    return category.children?.length > 0
        ? Math.max(...category.children.map((c) => getNumberOfCategoryLevels(c))) + 1
        : 1;
};

interface Props {
    onLeafCategorySelected?: () => void;
    displayCategories?: Category[] | null;
}

const CategoryPicker = ({ onLeafCategorySelected, displayCategories }: Props) => {
    const fullCategoriesTree = useSelector(selectCategories);
    const categories =
        displayCategories && displayCategories.length ? displayCategories : fullCategoriesTree;
    const numberOfLevels = React.useMemo(
        () => Math.max(...categories.map((category) => getNumberOfCategoryLevels(category))),
        [categories],
    );

    const [selectedCategories, setSelectedCategories] = React.useState<Array<Category | null>>(
        new Array(numberOfLevels ?? 0).fill(null),
    );

    const onSelectCategory = (category: Category) => {
        const newCategories = Array.from(selectedCategories);

        newCategories[0] = category;

        setSelectedCategories(newCategories);
    };

    if (!selectedCategories[0]) {
        return (
            <Flex stretchWidth column className={styles.categoriesListContainer}>
                <Text.XLarge bold className={styles.categoriesListLabel}>
                    <Trans>Choose a category</Trans>
                </Text.XLarge>
                <div className={styles.categoriesList}>
                    {categories.map((category) => (
                        <CategoryListItem
                            key={category.id}
                            category={category}
                            onSelectCategory={onSelectCategory}
                        />
                    ))}
                </div>
            </Flex>
        );
    }

    return (
        <SubcategoryPicker
            displayCategories={categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            numberOfLevels={numberOfLevels}
            onLeafCategorySelected={onLeafCategorySelected}
        />
    );
};

export default CategoryPicker;
