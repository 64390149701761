import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import { useChatListItemOptions, useChatSDK } from 'horizontal/chat/hooks';
import type { ChatRoom } from 'horizontal/chat/types';

import Badge from '../badge';
import MoreButton from '../moreButton';

import styles from './styles/options.cssm';
import Timestamp from './timestamp';

type Props = {
    readonly conversation: ChatRoom;
    readonly agencyMode?: boolean;
};

const Options = ({ conversation, agencyMode = false }: Props) => {
    const sdk = useChatSDK();
    const unreadCount = sdk.useRoomUnreadCount(conversation.xid);
    const options = useChatListItemOptions({ chatRoom: conversation });

    return (
        <Flex
            column
            stretchHeight
            className={classNames(styles.container, { [styles.bottom]: agencyMode })}
        >
            {!agencyMode && <Timestamp timestamp={conversation.lastActivityAt} />}
            <Flex alignCenter className={styles.badgeContainer}>
                <Badge count={unreadCount} />
            </Flex>
            {!agencyMode && <MoreButton options={options} />}
        </Flex>
    );
};

export default React.memo<Props>(Options);
