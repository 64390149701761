import { FilterCollection, ExactFilter, FilterKeys } from '@sector-labs/fe-search-redux/filters';
import { SearchJob } from '@sector-labs/fe-search-redux';
// @ts-expect-error - TS2305 - Module '"@sector-labs/fe-search-redux/state"' has no exported member 'SearchSettings'.
import type { SearchSettings } from '@sector-labs/fe-search-redux/state';
import { FilterValues } from 'strat/search';

const remarketingAdSearchJob = (
    indexName: string,
    filters: FilterCollection,
    remarketingAdID: string,
): SearchJob => {
    const settings: SearchSettings = {
        hitsPerPage: 1,
        exhaustiveNbHits: 1,
    };

    const remarketingAdsFilter = filters.copy([FilterKeys.PAGE, FilterValues.externalID.attribute]);

    remarketingAdsFilter.refine(
        new ExactFilter({
            attribute: FilterValues.externalID.attribute,
            value: remarketingAdID,
        }),
    );
    return new SearchJob(indexName, remarketingAdsFilter, settings);
};

export default remarketingAdSearchJob;
