import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type FawryPaymentRouteParams = {
    readonly referenceNumber: string;
};

class PaymentFawryRoute extends Route {
    constructor() {
        super(RouteNames.PAYMENT_FAWRY, [
            [
                '^/fawry/referenceNumber/',
                {
                    name: 'referenceNumber',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { referenceNumber }: FawryPaymentRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/fawry/referenceNumber/${referenceNumber}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { referenceNumber },
            },
        } = context;

        context.rendering.renderPage(Page.PAYMENT_FAWRY, {
            referenceNumber,
        });
    }
}

export default new PaymentFawryRoute();
