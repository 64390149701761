import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { selectRouteParams } from 'strat/routes/selectors';

export const SortOptionKey = Object.freeze({
    NEWEST_FIRST: 'desc-date',
    OLDEST_FIRST: 'asc-date',
});

type SearchSortOption = {
    readonly label: string;
    readonly key: Values<typeof SortOptionKey>;
};

export const DEFAULT_SEARCH_SORT_OPTION_KEY = SortOptionKey.NEWEST_FIRST;

export const useSortOptions = (): Array<SearchSortOption> => {
    const i18n = useI18n();
    return React.useMemo(
        () => [
            { label: t(i18n)`Newest first`, key: SortOptionKey.NEWEST_FIRST },
            { label: t(i18n)`Oldest first`, key: SortOptionKey.OLDEST_FIRST },
        ],
        [i18n],
    );
};

export const useSearchSortOption = (): SearchSortOption | null | undefined => {
    const options = useSortOptions();
    const params = useSelector(selectRouteParams);

    return (
        options.find((x) => x.key === params.sorting) ||
        options.find((x) => x.key === DEFAULT_SEARCH_SORT_OPTION_KEY)
    );
};
