import type {
    ConfigsWithExpress,
    ConfigsWithExpressAndCallbacks,
} from '@sector-labs/fe-auth-redux/express';
import { SessionError, type SessionData } from '@sector-labs/fe-auth-redux';

import type { AppDispatch } from 'strat/state';
import { cookieDomain } from 'strat/cookies';
import { canonicalDomain } from 'strat/routing';
import { InternalAPI } from 'strat/api';
import { setUserFromKeycloakSessionData, nukeUserAndClearEverything } from 'strat/user/state';
import { logError } from 'strat/error/log';
import { addAuthenticationContext } from 'strat/auth/state';
import { ServerAuthContext } from 'strat/server/serverAuthContext';
import type { UserRole } from 'strat/user/roles';

import { createKeycloakConfig, type KeycloakConfigOptions } from './config';
import { getKeycloakSessionRefreshTimer } from './keycloakSessionRefreshTimer';

export type KeycloakSiteConfig = ConfigsWithExpress;

export type KeycloakSiteConfigWithCallbacks = ConfigsWithExpressAndCallbacks;

export const createKeycloakSiteConfig = (options: KeycloakConfigOptions): ConfigsWithExpress => ({
    keycloakConfig: createKeycloakConfig(options),
    domain: cookieDomain(),
    secure: CONFIG.runtime.ENABLE_SECURE_COOKIES && canonicalDomain().protocol === 'https',
});

enum KeycloakSessionSource {
    SELF = 'self', // this tab/window
    OTHER = 'other', // other tab/window
}

interface KeycloakSessionContext {
    dispatch: AppDispatch;
    source: KeycloakSessionSource;
    options: KeycloakConfigOptions;
}

const saveCookiesIfNeeded = (
    sessionData: SessionData,
    { source, options }: KeycloakSessionContext,
): Promise<void> => {
    // We're on the server, this code is rarely ran on the server, but if it
    // is, the caller is responsible for setting the tokens as cookies.
    if (process.env.IS_SERVER) {
        return Promise.resolve();
    }

    // The session was handed to us by another tab/service worker, the caller
    // is responsible for managing the cookies and the interaction with Keycloak.
    if (source === KeycloakSessionSource.OTHER) {
        return Promise.resolve();
    }

    // We initiated the session creation/refresh and must make a request that
    // saves the tokens as cookies by serving `Set-Cookie` headers.
    return new InternalAPI(options.languageCode).saveKeycloakSession(sessionData);
};

const updateAuthenticationContext = (
    sessionData: SessionData,
    { dispatch, options }: KeycloakSessionContext,
): void => {
    if (process.env.IS_SERVER) {
        ServerAuthContext.populateFromKeycloakSession(sessionData, options);
        dispatch(addAuthenticationContext(ServerAuthContext.asState()));
        return;
    }

    dispatch(
        addAuthenticationContext({
            keycloakAccessToken: sessionData.accessToken,
            userExternalID: (sessionData.userProfile?.external_id || null) as string | null,
            userEmail: (sessionData.userProfile?.email || null) as string | null,
            userRoles: (sessionData.userProfile?.realm_roles || null) as UserRole[] | null,
        }),
    );
};

const startSession = (sessionData: SessionData, context: KeycloakSessionContext): Promise<void> => {
    const { dispatch } = context;

    getKeycloakSessionRefreshTimer()?.reset(null);

    // We're doing this sequentially so that we don't set the
    // cookies if for some reason setting the user fails.
    dispatch(setUserFromKeycloakSessionData(sessionData));

    return saveCookiesIfNeeded(sessionData, context).then(() => {
        getKeycloakSessionRefreshTimer()?.reset(sessionData);

        updateAuthenticationContext(sessionData, context);
    });
};

const endSession = ({ dispatch, options }: KeycloakSessionContext): Promise<void> => {
    // We're doing this sequentially so that we don't clear
    // the cookies if for some reason clearing out the Redux
    // store fails.
    dispatch(nukeUserAndClearEverything());

    getKeycloakSessionRefreshTimer()?.reset(null);

    getKeycloakSessionRefreshTimer()?.reset(null);

    if (process.env.IS_SERVER) {
        ServerAuthContext.clear();
    } else {
        return new InternalAPI(options.languageCode).forgetKeycloakSession();
    }

    return Promise.resolve();
};

export const createKeycloakSiteConfigWithCallbacks = (
    options: KeycloakConfigOptions,
    dispatch: AppDispatch,
): ConfigsWithExpressAndCallbacks => ({
    ...createKeycloakSiteConfig(options),
    // Do not store errors in Redux when we're running on the
    // server. Without this, any errors that occurred while
    // refreshing the user's session quietely might leak
    // to the front-end through the Redux store. Usually
    // users are not aware of what cookies they own etc.
    // We also don't need to bother them with errors.
    //
    // The error callbacks still get invoked and the
    // error will be logged.
    storeErrors: !process.env.IS_SERVER,
    callbacks: {
        onSessionStore: (sessionData: SessionData) =>
            startSession(sessionData, {
                dispatch,
                source: KeycloakSessionSource.OTHER,
                options,
            }),
        onSessionUpdate: (sessionData: SessionData) =>
            startSession(sessionData, {
                dispatch,
                source: KeycloakSessionSource.SELF,
                options,
            }),
        onLogoutInit: () =>
            endSession({
                dispatch,
                source: KeycloakSessionSource.SELF,
                options,
            }),
        onSessionRefreshError: (error, willResetSession) => {
            if (willResetSession) {
                return endSession({
                    dispatch,
                    source: KeycloakSessionSource.SELF,
                    options,
                });
            }

            getKeycloakSessionRefreshTimer()?.scheduleRetry();
            return Promise.resolve();
        },
        onSessionError: (error: SessionError) => {
            logError({
                e: error,
                msg: error.message,
            });
        },
    },
});
