import * as React from 'react';

import useFieldsBeforePrice from './useFieldsBeforePrice';
import useFieldsAfterPriceType from './useFieldsAfterPriceType';
import usePriceFields from './usePriceFields';
import usePriceTypeFields from './usePriceTypeFields';
import { PriceRelatedFieldProps } from './types';

const usePriceRelatedFields = (props: PriceRelatedFieldProps): React.ReactElement[] => {
    const fieldsBeforePrice = useFieldsBeforePrice(props);
    const priceFields = usePriceFields(props);
    const priceTypeFields = usePriceTypeFields(props);
    const fieldsAfterPriceType = useFieldsAfterPriceType(props);

    return React.useMemo(
        () => [...fieldsBeforePrice, ...priceFields, ...priceTypeFields, ...fieldsAfterPriceType],
        [fieldsBeforePrice, priceFields, priceTypeFields, fieldsAfterPriceType],
    );
};

export default usePriceRelatedFields;
