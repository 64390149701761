import { t } from '@lingui/macro';
import * as React from 'react';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

import { StratAPI } from 'horizontal/api';
import type { MixedAd } from 'horizontal/types';
import { trackReportUser } from 'horizontal/gtm/userTracking';

import ReportDialog from './reportDialog';
import ReportForm from './reportForm';
import type { BaseReportFormProps } from './types';
import ReportUserStatusDialog from './reportUserStatusDialog';

const reportReasons = (i18n: I18n) => [
    { id: 1, label: t(i18n)`Spam`, value: 'spam' },
    { id: 2, label: t(i18n)`Fraud`, value: 'fraud' },
    { id: 3, label: t(i18n)`Inappropriate profile picture`, value: 'profile_picture' },
    { id: 4, label: t(i18n)`This user is threatening me`, value: 'threaten' },
    { id: 5, label: t(i18n)`This user is insulting me`, value: 'insult' },
    { id: 6, label: t(i18n)`Other`, value: 'other' },
];

const makeValidationSchema = (i18n: I18n) =>
    Yup.object().shape({
        reason: Yup.string().required(t(i18n)`Please select a report reason`),
        comment: Yup.string().required(
            t(i18n)`Please add a comment to help us understand what's wrong with this user.`,
        ),
    });

export type ReportUserDialogProps = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly reportedUserExternalID: string | null | undefined;
    readonly reportedUserName: string | null | undefined;
    readonly ad?: MixedAd;
    readonly isUserBlocked?: boolean;
    readonly isBlockingLoading?: boolean;
    readonly blockUser?: (arg1: boolean) => void;
};

const ReportUserDialog = ({
    visible,
    onVisibilityChanged,
    reportedUserExternalID,
    reportedUserName,
    ad,
    blockUser,
    isUserBlocked = false,
    isBlockingLoading = false,
}: ReportUserDialogProps) => {
    const i18n = useI18n();
    const [reportStatus, setReportStatus] = React.useState({
        visible: false,
        success: false,
        error: null,
    });

    const isBlockingEnabled = !!blockUser;
    const [reportDialogVisibility, setReportDialogVisibility] = React.useState(true);
    const [isShouldBlockChecked, setShouldBlockChecked] = React.useState(false);

    React.useEffect(() => {
        setReportDialogVisibility(visible);
        if (!visible) {
            setShouldBlockChecked(false);
        }
    }, [visible]);

    const onSubmit = React.useCallback(
        (values) => {
            new StratAPI()
                // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
                .reportUser(reportedUserExternalID, values)
                .then(({ status, data }) => {
                    if (ad) {
                        trackReportUser(ad, values.reason);
                    }

                    const success = status === 200;
                    const { error } = data;

                    setReportDialogVisibility(false);
                    setReportStatus({
                        visible: true,
                        success,
                        error,
                    });

                    if (blockUser && !isUserBlocked && success) {
                        blockUser(isShouldBlockChecked);
                    }
                })
                .catch(() => {
                    setReportDialogVisibility(false);
                    setReportStatus({
                        visible: true,
                        success: false,
                        error: null,
                    });
                });
        },
        [reportedUserExternalID, ad, blockUser, isShouldBlockChecked, isUserBlocked],
    );

    const validationSchema = React.useMemo(() => makeValidationSchema(i18n), [i18n]);
    const renderForm = React.useCallback(
        (props: BaseReportFormProps) => (
            <ReportForm
                {...props}
                reportReasons={reportReasons(i18n)}
                isBlockingEnabled={isBlockingEnabled}
                isUserBlocked={isUserBlocked}
                isUserBlockedLoading={isBlockingLoading}
                shouldBlockUser={isShouldBlockChecked}
                setShouldBlockUser={setShouldBlockChecked}
                title={t(i18n)`User report`}
            />
        ),
        [i18n, isBlockingEnabled, isShouldBlockChecked, isUserBlocked, isBlockingLoading],
    );

    if (!reportedUserExternalID || !reportedUserName) {
        return null;
    }

    return (
        <>
            <ReportUserStatusDialog
                visible={reportStatus.visible}
                onVisibilityChanged={onVisibilityChanged}
                onCancel={() =>
                    setReportStatus({
                        visible: false,
                        success: reportStatus.success,
                        error: null,
                    })
                }
                success={reportStatus.success}
                error={reportStatus.error}
                shouldShowBlockingInfo={isShouldBlockChecked}
                userBlocked={isUserBlocked}
                isUserBlockedLoading={isBlockingLoading}
                setUserBlocked={blockUser}
                userName={reportedUserName}
            />
            <ReportDialog
                visible={reportDialogVisibility}
                onVisibilityChanged={onVisibilityChanged}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                renderForm={renderForm}
            />
        </>
    );
};

export default ReportUserDialog;
