import { t } from '@lingui/macro';
import * as React from 'react';
import { Button, Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { type AdPhoto, type FullAd, PriceType, AdState } from 'horizontal/types';
import CoverPhoto from 'horizontal/adCard/coverPhoto';
import { useFormattedPrice } from 'horizontal/util';
import { formatDate } from 'horizontal/util';

import styles from './styles/adSummary.cssm';

type Props = {
    readonly ad: FullAd | null | undefined;
    readonly onViewAdButtonClick?: () => void;
};

const AdSummary = ({ ad, onViewAdButtonClick }: Props) => {
    const i18n = useI18n();
    const price = useFormattedPrice(ad?.extraFields?.price || ad?.price || 0);

    if (!ad) {
        return null;
    }

    const coverPhoto =
        ad.photos?.find((photo: AdPhoto) => photo.main) || (ad.photos && ad.photos[0]) || null;
    const showPrice =
        ad.extraFields?.price_type === PriceType.FIXED_PRICE ||
        ad.extraFields?.price_type === PriceType.NEGOTIABLE;
    const showAdDuration = ad.state !== AdState.LIMITED;
    const categoryName = ad?.category.slice(-1)[0]?.name;
    const location = ad.location[ad.location.length - 1].name;
    const createdAt = formatDate(i18n, (ad?.createdAt || 0) * 1000);
    const expiresAt = formatDate(i18n, (ad?.expiresAt || 0) * 1000);

    return (
        <Flex column className={styles.adContainer}>
            <Flex>
                <Text.XLarge bold>{t(i18n)`Your Ad`}</Text.XLarge>
            </Flex>
            <Flex className={styles.adInformationContainer} column>
                <Flex className={styles.upperSection} alignCenter>
                    <Flex className={styles.adImage}>
                        <CoverPhoto cover={coverPhoto} category={ad?.category} />
                    </Flex>
                    <Flex className={styles.titleAndPrice} column>
                        <div>
                            <Text.Large bold>{ad?.title}</Text.Large>
                        </div>
                        {showPrice && (
                            <div>
                                <Text.Large>{price}</Text.Large>
                            </div>
                        )}
                    </Flex>
                </Flex>
                <Flex className={styles.lowerSection} column>
                    <Flex>
                        <Text.Large>{t(i18n)`Category: ${categoryName}`}</Text.Large>
                    </Flex>
                    <Flex>
                        <Text.Large>{t(i18n)`Location: ${location}`}</Text.Large>
                    </Flex>
                    {showAdDuration && (
                        <Flex>
                            <Text.Large>{t(
                                i18n,
                            )`Active from ${createdAt} to ${expiresAt}`}</Text.Large>
                        </Flex>
                    )}
                </Flex>
            </Flex>
            <Flex className={styles.cta} rowReverse>
                <Button onClick={onViewAdButtonClick} tertiary>
                    {t(i18n)`View Your Ad`}
                </Button>
            </Flex>
        </Flex>
    );
};

export default AdSummary;
