import type { PropertyPhoneNumberData } from 'strat/property/types';

type OptionalPhoneNumberData = PropertyPhoneNumberData | Record<any, any> | null;

const nonProxyPrimaryPhoneNumber = (phoneNumberData: OptionalPhoneNumberData) =>
    (phoneNumberData?.mobileNumbers && phoneNumberData.mobileNumbers?.[0]) ||
    (phoneNumberData?.phoneNumbers && phoneNumberData.phoneNumbers?.[0]) ||
    '';

const getPrimaryPhoneNumber = (phoneNumberData: OptionalPhoneNumberData) =>
    phoneNumberData?.proxyMobile ||
    phoneNumberData?.proxyPhone ||
    nonProxyPrimaryPhoneNumber(phoneNumberData);

export { nonProxyPrimaryPhoneNumber };
export default getPrimaryPhoneNumber;
