import { useSelector } from 'react-redux';
import { useIsMobileLayout } from 'strat/layout';
import SearchHitsLayout from 'strat/branding/searchHitsLayout';
import { selectRouteParams } from 'strat/routes/selectors';
import PageType from 'strat/gtm/pageType';
import Category from '@app/branding/category';
import useIsOfCarsForSaleType from '@app/adCard/useIsOfCarsForSaleType';

import { selectAdCategories } from 'horizontal/ad/state/selectors';

const useIsCarsOrPropertiesLPV = () => {
    const { parentCategorySlug, categorySlug, categorySlugWithOptionalPrefix } =
        useSelector(selectRouteParams);

    const isCarsForSale = useIsOfCarsForSaleType();
    // The category path param in Dubizzle can be accessed through parentCategorySlug or categorySlug based on the filters
    // while in PK can be accessed through categorySlugWithOptionalPrefix
    const isProperties =
        Category.isOfPropertiesType(parentCategorySlug) ||
        Category.isOfPropertySubtype(categorySlug) ||
        Category.isOfPropertySubtype(categorySlugWithOptionalPrefix);

    return !!(isCarsForSale || isProperties);
};

const useIsCarsOrPropertiesRemarketingPage = () => {
    const { pagetype } = useSelector(selectRouteParams);
    const adCategories = useSelector(selectAdCategories);

    const isRemarketingPage = pagetype === PageType.REMARKETING;

    return (
        isRemarketingPage &&
        adCategories?.some(
            (category) =>
                Category.isOfPropertiesType(category) ||
                Category.isOfPropertySubtype(category) ||
                Category.isOfCarsType(category),
        )
    );
};

const useIsHeroCategoryDesignEnabledForMWeb = (): boolean => {
    const isMobile = useIsMobileLayout();

    return !!(isMobile && CONFIG.runtime.STRAT_ENABLE_CARS_AND_PROPERTIES_LPV_UPDATED_DESIGN);
};

const useIsHeroCategoryDesignEnabledForDesktop = (): boolean => {
    //tablets are considered as desktop for now.
    const isDesktop = !useIsMobileLayout();

    return !!(
        isDesktop && CONFIG.runtime.STRAT_ENABLE_CARS_AND_PROPERTIES_LPV_UPDATED_DESIGN_DESKTOP
    );
};

const useShowHeroCategoryAdCardDesign = (viewType?: Values<typeof SearchHitsLayout>): boolean => {
    const isListLayout = viewType === SearchHitsLayout.LIST;

    const isHeroCategoryDesignEnabledForMWeb = useIsHeroCategoryDesignEnabledForMWeb();
    const isHeroCategoryDesignEnabledForDesktop = useIsHeroCategoryDesignEnabledForDesktop();
    const isCarsOrPropertiesLPV = useIsCarsOrPropertiesLPV();
    const isCarsOrPropertiesRemarketingPage = useIsCarsOrPropertiesRemarketingPage();

    return !!(
        isListLayout &&
        (isHeroCategoryDesignEnabledForMWeb || isHeroCategoryDesignEnabledForDesktop) &&
        (isCarsOrPropertiesLPV || isCarsOrPropertiesRemarketingPage)
    );
};

export { useShowHeroCategoryAdCardDesign };
