import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language';

import useFetchChildLocations from 'horizontal/search/location/useFetchChildLocations';
import { fetchMultipleLocationsByExternalID } from 'horizontal/search/location';
import { useMakeCancelable } from 'horizontal/util';
import type { Location, LiteHierarchicalLocation } from 'horizontal/types';

type Props = {
    isOpen: boolean;
    location: Location | LiteHierarchicalLocation;
    activeLocation: Location | LiteHierarchicalLocation;
    setLocationTree: (locationTree: Array<Location>) => void;
    setActiveLocation: (location: Location | LiteHierarchicalLocation) => void;
};
/**
 * hook that handles restoring the 'active' location menu to:
 * 1- the selected location if it has children
 * or
 * 2- the parent of the selected location in case
 * the selected location has no children.
 */
const useRestoreLocationDropdown = ({
    isOpen,
    location,
    activeLocation,
    setLocationTree,
    setActiveLocation,
}: Props) => {
    const language = useSelector(selectLanguage);
    const backend = useSelector(selectActiveSearchBackend);

    const fetchChildLocations = useFetchChildLocations();
    const makeCancelable = useMakeCancelable();

    React.useEffect(() => {
        if (isOpen) {
            return;
        }
        /**
         * top level location which is retrieved by calling:
         * settings.getTopLevelLocation(i18n)
         * doesn't have children, therefore we need to distinguish
         * it from `leaf` locations that have no children, this
         * is done by checking the `hierarchy` instead.
         */
        if (
            'hierarchy' in location &&
            location.hierarchy.length === 1 &&
            location.externalID === activeLocation.externalID
        ) {
            return;
        }
        /**
         * if active location and selected location are different,
         * then reset 'active location' back to the current 'location'
         * ONLY if there are no children in 'active' location OR if
         * selected location has children.
         */
        if (
            !('hasChildren' in activeLocation) ||
            ('hasChildren' in location &&
                location.hasChildren &&
                !(location.externalID === activeLocation.externalID))
        ) {
            fetchChildLocations(location).then((data) => {
                setLocationTree(data || []);
                setActiveLocation(location);
            });
            return;
        }
        /**
         * if the selected location has no children, then it requires
         * special handling, where we will fetch the parent of the
         * 'selected' location and set that as our new 'active' location
         */
        if (!('hasChildren' in location && location.hasChildren)) {
            const parentLocation = ('hierarchy' in location && location.hierarchy.slice(-2)[0]) || {
                externalID: null,
            };
            if (parentLocation && parentLocation.externalID === activeLocation.externalID) {
                return;
            }

            if (parentLocation.externalID) {
                makeCancelable(
                    fetchMultipleLocationsByExternalID(
                        [parentLocation.externalID],
                        language,
                        // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
                        backend,
                        1,
                    ),
                ).then((locationData: Array<Location>) => {
                    if (!locationData.length) {
                        return;
                    }

                    setActiveLocation(locationData[0]);
                });
                fetchChildLocations(parentLocation).then((data) => {
                    setLocationTree(data || []);
                });
            }
        }
    }, [
        isOpen,
        location,
        activeLocation,
        fetchChildLocations,
        backend,
        language,
        makeCancelable,
        setLocationTree,
        setActiveLocation,
    ]);
};

export default useRestoreLocationDropdown;
