import { Dispatch } from 'redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import SegmentFetcherFactory from 'strat/segmentFetcher';
import { FetchAction } from 'strat/fetcher';

import fetchMultipleLocationsByExternalID from 'horizontal/search/location/fetchMultipleLocationsByExternalID';
import type { LocationNodeData } from 'horizontal/property';

const SEGMENT_FETCHER_OPTIONS = {
    cached: true,
    batched: true,
} as const;

type LocationFetcherParams = {
    externalID: string;
};

const factory = new SegmentFetcherFactory<LocationFetcherParams>(
    ['locations'],
    'externalID',
    ({ params, state }) => {
        const externalIDs = (params as Array<LocationFetcherParams>).map((item) => item.externalID);
        return fetchMultipleLocationsByExternalID(
            externalIDs,
            state.i18n.language,
            // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
            selectActiveSearchBackend(state),
            externalIDs.length,
        ).then((hits) => ({ data: hits, status: 200 }));
    },
    SEGMENT_FETCHER_OPTIONS,
);

const fetchLocations = factory.creator();

const setInitialLocationState = (dispatch: Dispatch<FetchAction>, payload: LocationNodeData) =>
    dispatch({
        type: factory.actions.success,
        data: payload,
        params: { externalID: payload.externalID },
    });

const locationsReducer = factory.reducer();

export { fetchLocations, setInitialLocationState };

export default locationsReducer;
