import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { ViewSections } from 'strat/gtm';

import EliteStrip from '@app/branding/eliteStrip';
import VerifiedProfileBadge from 'horizontal/profile/verifiedProfileBadge';
import FavoriteButton from 'horizontal/adCard/favoriteButton';
import { useIsFavoriteAd } from 'horizontal/favorites';
import { AdPhoto, LiteAd } from 'horizontal/types';

import AdGallery from '../adGallery';
import CardCTAs from '../adCardCTAs';
import AdTags from '../adTags';
import AdDetails from '../adDetails';
import CoverPhoto from '../coverPhoto';
import { FeaturedTag } from '../tags';

import styles from './styles/adCardForHeroCategory.cssm';

type Props = {
    ad: LiteAd;
    link: React.ReactElement;
    coverPhoto: AdPhoto | null;
    lazyLoadPhoto: boolean;
    isOwnAd: boolean;
    priceLabel: string | null | undefined;
    title: string;
    location: string;
    viewSection: Values<typeof ViewSections>;
    highlighted?: boolean;
    showElite?: boolean;
    showFeatured?: boolean;
    coverPhotoSize?: number;
};

const AdCard = ({
    ad,
    link,
    coverPhoto,
    lazyLoadPhoto,
    isOwnAd,
    priceLabel,
    title,
    location,
    viewSection,
    highlighted,
    showElite,
    showFeatured,
    coverPhotoSize,
}: Props) => {
    const isFavoriteAd = useIsFavoriteAd(ad.externalID);

    return (
        <article
            className={classNames(styles.container, {
                [styles.elite]: showElite,
                [styles.highlighted]: highlighted,
            })}
        >
            {showElite && (
                <div className={styles.eliteContainer}>
                    {link}
                    <EliteStrip className={styles.eliteStrip} />
                </div>
            )}
            <div className={styles.photoContainer}>
                {CONFIG.runtime.STRAT_HIDE_CARS_AND_PROPERTIES_LPV_UPDATED_DESIGN_GALLERY ? (
                    <CoverPhoto
                        cover={coverPhoto}
                        lazyLoad={lazyLoadPhoto}
                        category={ad.category}
                        size={coverPhotoSize}
                        className={classNames(styles.image, {
                            [styles.coverRoundedCorners]: !showElite,
                        })}
                    />
                ) : (
                    <AdGallery
                        ad={ad}
                        link={link}
                        lazyLoadPhoto={lazyLoadPhoto}
                        isElite={!!showElite}
                        viewSection={viewSection}
                    />
                )}
                <Flex className={styles.badgesContainer}>
                    {showFeatured && <FeaturedTag />}
                    {ad.isSellerVerified && <VerifiedProfileBadge shortText />}
                </Flex>
                {!isOwnAd && (
                    <div
                        className={classNames(styles.favoriteButton, {
                            [styles.whiteFavoriteIcon]: !isFavoriteAd,
                            [styles.favoriteIcon]: isFavoriteAd,
                        })}
                    >
                        {/* @ts-expect-error - TS2322 - Type '{ ad: LiteAd; viewSection: string; }' is not assignable to type 'IntrinsicAttributes & { ref?: Ref<unknown> | undefined; key?: Key | null | undefined; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } & { ...; }'. */}
                        <FavoriteButton ad={ad} viewSection={ViewSections.BODY} />
                    </div>
                )}
                <AdTags ad={ad} />
            </div>
            <AdDetails
                ad={ad}
                link={link}
                title={title}
                priceLabel={priceLabel}
                location={location}
            />
            <div className={styles.ctaContainer}>
                {!isOwnAd && <CardCTAs ad={ad} viewSection={viewSection} />}
            </div>
        </article>
    );
};

export default AdCard;
