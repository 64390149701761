//@ts-nocheck
// flow

import { useSelector } from 'react-redux';

import { ThumbnailSizes } from 'strat/image';

import { PropertyState } from './types';
import { selectProperty } from './selectors';

/**
 On PropertyPage we want to show XL thumbnails if they are available.
 To be available the property must be active and thumbnails generated on the backend.
 */
const usePropertyThumbnailSize = (): ThumbnailSizes => {
    const property = useSelector(selectProperty);

    if (!CONFIG.build.STRAT_X_LARGE_IMAGE_SHOW || property?.state !== PropertyState.ACTIVE) {
        return ThumbnailSizes.LARGE;
    }
    return ThumbnailSizes.X_LARGE;
};

export default usePropertyThumbnailSize;
