import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { selectLanguage } from 'strat/i18n/language';

import type { LiteHierarchicalLocation, Location } from 'horizontal/types';

import fetchLocationsByParent from './fetchLocationsByParent';

const useFetchChildLocations = () => {
    const backend = useSelector(selectActiveSearchBackend);
    const language = useSelector(selectLanguage);
    return React.useCallback(
        (loc: Location | LiteHierarchicalLocation) =>
            // @ts-expect-error - TS2345 - Argument of type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to parameter of type 'SearchBackend'.
            fetchLocationsByParent(loc, language, backend).then((data) => {
                if (!data.length) {
                    return [];
                }

                data.sort((a, b) => a.name.localeCompare(b.name));
                return data;
            }),
        [backend, language],
    );
};

export default useFetchChildLocations;
