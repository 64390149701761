import type { LiteHierarchicalLocation, Location } from 'horizontal/types';
import { useFullLocations } from 'horizontal/location';

const useFullLocationsHierarchy = (
    hierarchy: readonly LiteHierarchicalLocation[],
): Array<Location> => {
    const fullLocations = Object.values(
        useFullLocations(hierarchy.map((loc) => loc.externalID) || []),
    ) as any;
    // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
    return fullLocations.sort((a, b) => a.level - b.level) || [];
};

export default useFullLocationsHierarchy;
