import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import Currency from 'strat/i18n/money/currency';
import settings from '@app/branding/settings';
import { Flex, Text, Button, Dialog } from 'strat/components';

import iconWallet from '@app/assets/icons/iconWallet_noinline.svg';

import styles from './styles/confirmPaymentDialog.cssm';

type Props = {
    dialogVisible: boolean;
    setDialogVisible: (flag: boolean) => void;
    disableActions?: boolean;
    amount: number;
    onConfirm: () => void;
    partialPayment?: boolean;
    selectPaymentMethodName?: string;
    onDismiss: () => void;
};

const ConfirmPaymentDialogContent = ({
    partialPayment,
    selectPaymentMethodName,
    amount,
}: {
    partialPayment?: boolean;
    amount: number;
    selectPaymentMethodName?: string;
}) => {
    const i18n = useI18n();
    const baseCurrency = settings.baseCurrencyName;
    if (partialPayment) {
        return (
            <Flex column className={styles.content}>
                <Text.Large bold center>
                    <Trans>Finalize purchase with {selectPaymentMethodName}</Trans>
                </Text.Large>
                <Flex className={styles.walletCard}>
                    <img alt={'My Wallet'} src={iconWallet} />
                    <Text.Regular>
                        <Trans>
                            By continuing, your {settings.getBrandName(i18n)} Wallet credit of{' '}
                            <span className={styles.boldText}>
                                {amount} {Currency.symbol(i18n, baseCurrency)}
                            </span>{' '}
                            will automatically be withdrawn.
                        </Trans>
                    </Text.Regular>
                </Flex>
                <Text.Regular>
                    <Trans>
                        <span className={styles.boldText}>
                            Your credit will be restored to your dubizzle Wallet within 24h
                        </span>{' '}
                        in case of an incomplete transaction.
                    </Trans>
                </Text.Regular>
                <Text.Regular>
                    <Trans>Are you sure you want to proceed with {selectPaymentMethodName} ?</Trans>
                </Text.Regular>
            </Flex>
        );
    }
    return (
        <Flex column className={styles.content}>
            <Text.XLarge bold>
                <Trans>
                    Pay {amount} {Currency.symbol(i18n, baseCurrency)} using your wallet balance?
                </Trans>
            </Text.XLarge>

            <Text.Regular>
                <Trans>
                    We will deduct {amount} {Currency.symbol(i18n, baseCurrency)} from your wallet
                    balance.
                </Trans>
            </Text.Regular>
        </Flex>
    );
};

const ConfirmPaymentDialog = ({
    dialogVisible,
    setDialogVisible,
    disableActions,
    amount,
    onConfirm,
    partialPayment,
    selectPaymentMethodName,
    onDismiss,
}: Props) => {
    const i18n = useI18n();
    const onVisibilityChanged = (visiable: boolean) => {
        if (!visiable) {
            onDismiss();
        }
        setDialogVisible(visiable);
    };
    return (
        <Dialog
            visible={dialogVisible}
            onVisibilityChanged={onVisibilityChanged}
            className={styles.popUp}
            dismissible
            onCancel={() => onDismiss()}
            withCloseButton
        >
            <ConfirmPaymentDialogContent
                partialPayment={partialPayment}
                selectPaymentMethodName={selectPaymentMethodName}
                amount={amount}
            />
            <Flex className={styles.buttonsContainer} justifyCenter>
                <Button
                    stretch
                    secondary
                    onClick={() => {
                        setDialogVisible(false);
                        onDismiss();
                    }}
                >
                    <Text.Regular>{t(i18n)`Cancel`}</Text.Regular>
                </Button>
                <Button stretch onClick={onConfirm} disabled={disableActions}>
                    <Text.Regular>
                        {partialPayment ? t(i18n)`Yes, continue` : t(i18n)`Yes, pay`}
                    </Text.Regular>
                </Button>
            </Flex>
        </Dialog>
    );
};

export default ConfirmPaymentDialog;
