import FetcherFactory from 'strat/fetcher';
import { fetchFromPopularityTrends } from 'strat/search/elasticSearch';
import { BayutCompat } from 'strat/util';
import Purpose from 'strat/purpose';

const factory = new FetcherFactory(
    'priceTrends',
    (params: any) => {
        if (!params.locationID) {
            return Promise.resolve({ data: {}, status: 200 });
        }

        return fetchFromPopularityTrends(
            CONFIG.runtime.STRAT_SEARCH_INDEX_NAME_MONTHLY_PRICE_TRENDS,
            params,
            {
                hitsPerPage: 1,
                sort: {
                    type: 'attributes',
                    key: 'monthsSort',
                    name: 'months sort',
                    attributes: [{ name: 'month', order: 'desc' }],
                },
            },
        ).then((data) => {
            return { data: data[0] || {}, status: 200 };
        });
    },
    {
        // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
        getParams: (params, getState) => {
            const property = getState().property.data || {};
            const locationExternalID =
                property.locations[3]?.externalID || property.locations[2]?.externalID;

            return {
                purposeID: BayutCompat.Purpose.toID(Purpose.FOR_RENT),
                propertyTypeID: property.category[property.category.length - 1].externalID,
                bedrooms: Math.min(property.rooms, 8),
                locationID: locationExternalID,
            };
        },
    },
);

const fetchPriceTrends = factory.creator();

const priceTrendsReducer = factory.reducer();

export { fetchPriceTrends };

export default priceTrendsReducer;
