import React from 'react';
import { Flex } from 'strat/components';
import IconInfo from '@app/assets/icons/iconInfo_noinline.svg';
import Tooltip from 'strat/generic/tooltip';
import { Trans } from '@lingui/macro';

import styles from './styles/requestablePriceTooltip.cssm';

const TooltipTrigger = () => (
    <Flex justifyCenter>
        <svg className={styles.infoIcon}>
            <use xlinkHref={`${IconInfo}#info`} />
        </svg>
    </Flex>
);

const RequestablePriceTooltip = () => {
    return (
        <Tooltip
            timeToHide={10000}
            clickable
            attachment={{ vertical: 'top', horizontal: 'center' }}
            targetAttachment={{ vertical: 'middle', horizontal: 'center' }}
            renderTrigger={TooltipTrigger}
            renderContent={() => (
                <div className={styles.tooltipContent}>
                    <Trans>
                        Start a conversation with seller to get a price offer, negotiate and close
                        the deal!
                    </Trans>
                </div>
            )}
            anchorClassName={styles.anchor}
        />
    );
};

export default RequestablePriceTooltip;
