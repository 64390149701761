import * as React from 'react';

import { useRouter } from 'react-true-router';

type Props = {
    name: string;
};

const idleCallback = window.requestIdleCallback || setTimeout; // setTimeout is not a replacement for requestIdleCallback but in this case they behave almost identically

const PrefetchPage = ({ name }: Props) => {
    const router = useRouter();
    // @ts-expect-error - TS2339 - Property 'pages' does not exist on type 'HistoryRouter'.
    const pages = router.pages;

    React.useEffect(() => {
        if (
            pages &&
            pages[name] &&
            pages[name].state &&
            pages[name].state.loaded === false &&
            pages[name].preload
        ) {
            idleCallback(() => pages[name].preload());
        }
    }, [name, pages]);

    return null;
};

export default PrefetchPage;
