import type { GlobalState } from 'strat/state';

import type { LoginType } from './types';

export const selectKeycloakAccessToken = (state: GlobalState): string | null =>
    state.authentication.context.keycloakAccessToken;

export const selectExpressSessionCookie = (state: GlobalState): string | null =>
    state.authentication.context.expressSessionCookie;

export const selectMPACDCookie = (state: GlobalState): string | null =>
    state.authentication.context.mpaCDCookie;

export const selectAPI6UserKey = (state: GlobalState): string | null =>
    state.authentication.context.api6UserKey;

export const selectLoginType = (state: GlobalState): LoginType | null =>
    state.authentication.loginType || null;
