import React from 'react';
import { RouteNames } from 'strat/routes';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';

import useAdAgency from 'horizontal/ad/useAdAgency';
import { useSellerProfile } from 'horizontal/seller/hooks';
import { MixedAd } from 'horizontal/types';
import BuyWithDeliveryButton from 'horizontal/adCard/buyWithDeliveryButton';

import useRequestDelivery from '../requestDelivery/useRequestDelivery';
import useCallToActionsSticky from '../compact/useCallToActionsSticky';
import SellerProfilePicture from '../components/sellerProfilePicture';
import AdDetailsSellerDetails from '../components/adDetailsSellerDetails';
import SellerCTAs from '../components/sellerCTAs';

import styles from './styles/adOwnerInfo.cssm';

type Props = { ad: MixedAd };

const AdOwnerInfo = ({ ad }: Props) => {
    const i18n = useI18n();

    const adAgency = useAdAgency(ad);
    const seller = useSellerProfile();

    const { canBuyNow, showBuyWithDeliveryOnly } = useCallToActionsSticky({ ad });

    const { isAdOrdered } = useRequestDelivery(ad);
    const showDeliveryButton = canBuyNow || showBuyWithDeliveryOnly || isAdOrdered;

    const adExternalId = ad?.externalID;

    const route: string = adAgency ? RouteNames.AGENCY_PROFILE : RouteNames.SELLER_PROFILE;
    const params = React.useMemo(
        () => (adAgency ? { agencySlug: adAgency.slug } : { externalID: seller?.externalID }),
        [adAgency, seller],
    );

    if (!seller?.id || !adExternalId) {
        return null;
    }
    const sellerWithAgentID = { ...seller, agentExternalID: seller.externalID };

    return (
        <>
            <Link route={route} params={params}>
                <Flex alignCenter justifySpaceBetween={!!adAgency}>
                    {adAgency ? (
                        <>
                            <AdDetailsSellerDetails
                                seller={adAgency}
                                i18n={i18n}
                                isVerified={ad?.isSellerVerified}
                                isAgency
                            />
                            <SellerProfilePicture adAgency={adAgency} seller={adAgency} />
                        </>
                    ) : (
                        <>
                            <SellerProfilePicture adAgency={adAgency} seller={seller} />
                            <AdDetailsSellerDetails
                                seller={sellerWithAgentID}
                                i18n={i18n}
                                isVerified={seller.isVerified || ad?.isSellerVerified}
                            />
                        </>
                    )}
                </Flex>
            </Link>
            <div className={styles.contactButtons}>
                {showDeliveryButton && (
                    <BuyWithDeliveryButton
                        ad={ad}
                        className={styles.deliveryButton}
                        small={false}
                        smallIcon={false}
                    />
                )}

                <SellerCTAs route={route} seller={sellerWithAgentID} isDisplayHorizontal />
            </div>
        </>
    );
};

export default AdOwnerInfo;
