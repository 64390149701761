import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex } from 'strat/components';
import { trigger, PageTypes, Triggers } from 'strat/gtm';

import { Category, Location, TransactionPurpose } from 'horizontal/types';
// eslint-disable-next-line no-restricted-imports
import {
    PurposeFilter,
    PriceRangeFilter,
    PropertyTypeFilter,
    BedsAndBathsFilter,
    AreaRangeFilter,
} from 'horizontal/verticals/properties/searchBox/widgets';
import { useNavigateToAgencyPage } from 'horizontal/agency/routing';
import { useAgencyProfile } from 'horizontal/agency/state';
import { defaultProfileFilterValues } from 'horizontal/agency/routing/constants';
import { propertyFiltersToExtraFields } from 'horizontal/agency/routing/mappers';
import { AgencyProfileRouteParams } from 'horizontal/routes';
import { useDebounce } from 'horizontal/util';
import IconClose from 'horizontal/assets/icons/iconClose.svg';

import usePropertyFilters from './hooks/usePropertyFilters';
import LocationFilter from './locationFilter';
import { isProfileFiltered } from './utils';
import styles from './styles/realEstateFilters.cssm';

type Props = {
    readonly location: Location | null;
};

const RealEstateAgencyFilters = ({ location }: Props) => {
    const i18n = useI18n();
    const {
        clearExtraFields,
        extraFilters,
        categoryFilter,
        purpose,
        category,
        setExtraFilters,
        setCategory,
        categoryList,
        setPurpose,
        setCategoryList,
        propertiesCategory,
        setFilter,
        hideBedsAndBathFilter,
        shoudHideBedsAndBathFilter,
        routeParams,
    } = usePropertyFilters();
    const isFiltered = isProfileFiltered({ location, purpose });
    const { slug: agencySlug } = useAgencyProfile() || {};
    const navigatePage = useNavigateToAgencyPage();

    const params = {
        agencySlug: agencySlug,
        purpose: purpose,
        locationSlug: location?.slug,
        categorySlug: category?.slug,
        extraFields: propertyFiltersToExtraFields(extraFilters),
    };

    const filterParams = (category: Category) => {
        if (shoudHideBedsAndBathFilter(category)) {
            return {
                ...params,
                extraFields: {
                    ...params.extraFields,
                    rooms: defaultProfileFilterValues.beds,
                    bathrooms: defaultProfileFilterValues.baths,
                },
            };
        } else {
            return {
                ...params,
                extraFields: {
                    ...params.extraFields,
                    ft: defaultProfileFilterValues.area,
                },
            };
        }
    };

    const onCategoryChange = (slug: string) => {
        trigger(Triggers.CLICK_FILTER, {
            page_type: PageTypes.AGENCY_PROFILE,
        });
        const category = propertiesCategory?.children.find(
            (categoryElement) => categoryElement.slug === slug,
        );
        if (category) {
            setCategory(category);
            setExtraFilters(defaultProfileFilterValues);
            const filteredParams: Partial<AgencyProfileRouteParams> = filterParams(category);
            navigatePage({
                ...filteredParams,
                categorySlug: category?.slug,
                purpose,
                extraFields: {},
            });
        }
    };

    const onPurposeChange = (value: Values<typeof TransactionPurpose>) => {
        if (value !== purpose) {
            trigger(Triggers.CLICK_FILTER, {
                page_type: PageTypes.AGENCY_PROFILE,
            });
            const propertiesCategoriesUnderPurpose = propertiesCategory?.children
                .filter((category) => category.purpose === value)
                .map((category) => ({
                    label: category.name,
                    value: category.slug,
                }));
            setCategoryList(propertiesCategoriesUnderPurpose || []);
            setCategory(null);
            setPurpose(value);
            navigatePage({
                categorySlug: propertiesCategoriesUnderPurpose?.[0]?.value,
                agencySlug,
                purpose: value,
                locationSlug: location?.slug,
                extraFields: {},
            });
        }
    };

    const onExtraFilterChange = (attribute: string, value: any) => {
        trigger(Triggers.CLICK_FILTER, {
            page_type: PageTypes.AGENCY_PROFILE,
        });
        const extraFields = propertyFiltersToExtraFields({
            ...extraFilters,
            [attribute]: value,
        });
        navigatePage({ ...params, extraFields });
    };

    const debouncedPriceFilterChange = useDebounce({
        callback: ({ filterType, value }: { filterType: string; value: any }) =>
            onExtraFilterChange(filterType, value),
        milliseconds: 800,
    });

    const getBeds = () => {
        if (extraFilters.beds?.length) {
            return extraFilters.beds;
        } else if (routeParams.beds?.length) {
            return routeParams.beds;
        }
        return [];
    };

    const getBaths = () => {
        if (extraFilters.baths?.length) {
            return extraFilters.baths;
        } else if (routeParams.baths?.length) {
            return routeParams.baths;
        }
        return [];
    };

    const onBedsAndBathsReset = () => {
        trigger(Triggers.CLICK_FILTER, {
            page_type: PageTypes.AGENCY_PROFILE,
        });
        const extraFields = propertyFiltersToExtraFields({
            ...extraFilters,
            beds: defaultProfileFilterValues.beds,
            baths: defaultProfileFilterValues.baths,
        });
        navigatePage({ ...params, extraFields });
    };

    const onLocationChange = (selectedLocation: Location | null) => {
        trigger(Triggers.CLICK_FILTER, {
            page_type: PageTypes.AGENCY_PROFILE,
        });
        navigatePage({ ...params, locationSlug: selectedLocation?.slug });
    };

    const clearFilters = () => {
        clearExtraFields();
        setCategory(null);
        navigatePage({ agencySlug });
    };

    React.useEffect(() => {
        if (categoryFilter && categoryFilter !== category) {
            setCategory(categoryFilter);
        }
    }, [categoryFilter, category, setFilter, setCategory]);

    return (
        <>
            <Text.Large bold>{t(i18n)`Filter by:`}</Text.Large>
            <Flex column>
                <Flex className={styles.filtersContainer}>
                    <div className={styles.purposeFilter}>
                        <PurposeFilter
                            value={purpose}
                            setValue={onPurposeChange}
                            titleCustomClass={styles.filterTitle}
                            showAllPurpose
                        />
                    </div>
                    <div className={styles.locationFilter}>
                        <LocationFilter
                            value={location || routeParams.location}
                            onLocationSelected={onLocationChange}
                        />
                    </div>

                    <div className={styles.propertyTypeFilter}>
                        <PropertyTypeFilter
                            value={purpose !== TransactionPurpose.ALL ? category?.slug || '' : ''}
                            setValue={onCategoryChange}
                            choices={categoryList}
                            titleCustomClass={styles.filterTitle}
                            disabled={purpose === TransactionPurpose.ALL}
                        />
                    </div>
                    {purpose !== TransactionPurpose.ALL && (
                        <>
                            <div className={styles.bedsAndBathsFilter}>
                                {hideBedsAndBathFilter ? (
                                    <AreaRangeFilter
                                        area={extraFilters.area || defaultProfileFilterValues.area}
                                        setArea={(value) => onExtraFilterChange('area', value)}
                                        titleClassname={styles.filterTitle}
                                    />
                                ) : (
                                    <BedsAndBathsFilter
                                        title={`${t(i18n)`Beds`} / ${t(i18n)`Baths`}`}
                                        setFieldValue={(filterType, value) => {
                                            setExtraFilters({
                                                ...extraFilters,
                                                [filterType]: value,
                                            });
                                            debouncedPriceFilterChange({ filterType, value });
                                        }}
                                        beds={getBeds()}
                                        baths={getBaths()}
                                        titleCustomClass={styles.filterTitle}
                                        onReset={onBedsAndBathsReset}
                                    />
                                )}
                            </div>
                            <div className={styles.priceFilter}>
                                <PriceRangeFilter
                                    value={extraFilters.price || defaultProfileFilterValues.price}
                                    setValue={(value) => {
                                        setExtraFilters({
                                            ...extraFilters,
                                            price: value,
                                        });
                                        debouncedPriceFilterChange({ filterType: 'price', value });
                                    }}
                                    titleCustomClass={styles.filterTitle}
                                />
                            </div>
                        </>
                    )}
                </Flex>
                <Flex className={styles.clearContainer} justifyEnd>
                    {isFiltered && (
                        <Flex
                            onClick={() => clearFilters()}
                            className={styles.clearFilters}
                            alignCenter
                        >
                            <IconClose className={styles.closeIcon} />
                            <span>{t(i18n)`Clear Filters`}</span>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default RealEstateAgencyFilters;
