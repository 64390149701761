import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';
import { Dropdown } from 'strat/modal';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import { SortOptionsDropdownItem, SortOptionsDropdownTrigger } from 'horizontal/search/sorting';
import type { SearchSortOption } from 'horizontal/types';
import FilterIcon from 'horizontal/assets/icons/filter_noinline.svg';

import useSetSearchOptions from './useSetSearchOptions';
import { useSearchSortOption, useSortOptions } from './sortOptions';
import styles from './styles/sortPaymentOrdersDropDown.cssm';

const SortPaymentOrdersDropDown = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const options = useSortOptions();
    const currentOption = useSearchSortOption();
    const setSearchOptions = useSetSearchOptions();
    const isMobile = useSelector(selectIsMobileLayout);

    const renderTrigger = React.useCallback(() => {
        const onClick = () => setIsOpen(!isOpen);
        if (isMobile) {
            return (
                <img
                    src={FilterIcon}
                    role="button"
                    onClick={onClick}
                    alt={'Filter button'}
                    className={classNames({
                        [styles.openFilterIcon]: isOpen,
                        [styles.closedFilterIcon]: !isOpen,
                    })}
                />
            );
        }
        return (
            <SortOptionsDropdownTrigger
                label={currentOption?.label || ''}
                isOpen={isOpen}
                onClick={onClick}
            />
        );
    }, [currentOption, setIsOpen, isOpen, isMobile]);

    const selectOption = React.useCallback(
        (option: SearchSortOption) => {
            setSearchOptions({ sortOption: option });
            setIsOpen(false);
        },
        [setSearchOptions],
    );

    return (
        <Flex alignCenter className={styles.container}>
            <Dropdown
                open={isOpen}
                renderTrigger={renderTrigger}
                onDismissed={() => setIsOpen(false)}
            >
                <ul role="listbox" className={styles.content}>
                    {/* @ts-expect-error - TS2345 - Argument of type '(option: SearchSortOption) => JSX.Element' is not assignable to parameter of type '(value: SearchSortOption, index: number, array: SearchSortOption[]) => Element'. */}
                    {options.map((option: SearchSortOption) => (
                        <SortOptionsDropdownItem
                            key={option.key}
                            option={option}
                            isSelected={currentOption?.key === option.key}
                            onClick={() => selectOption(option)}
                        />
                    ))}
                </ul>
            </Dropdown>
        </Flex>
    );
};

export default SortPaymentOrdersDropDown;
