import type { FetchState, FetchAction } from 'strat/fetcher';

import type { FavoritesAction } from './actions';
import Actions from './actions';
import favoritesFetcherReducer, { defaultState } from './factory';

/**
 * Reducer for favorites related actions.
 */
const favoritesReducer = (
    state: FetchState | null | undefined = defaultState,
    action: FavoritesAction | FetchAction,
): FetchState => {
    switch (action.type) {
        case Actions.SET_FAVORITES:
            // @ts-expect-error - TS2322 - Type '{ data: any; loaded: true; loading?: boolean | undefined; preloaded?: boolean | undefined; params?: any; error?: FetchError | null | undefined; }' is not assignable to type 'FetchState'.
            return {
                ...state,
                // $FlowFixMe
                // @ts-expect-error - TS2339 - Property 'favorites' does not exist on type 'FetchAction | FavoritesAction'.
                data: action.favorites,
                loaded: true,
            };

        case Actions.ADD_FAVORITE:
            // @ts-expect-error - TS2322 - Type '{ data: any[]; loading?: boolean | undefined; loaded?: boolean | undefined; preloaded?: boolean | undefined; params?: any; error?: FetchError | null | undefined; }' is not assignable to type 'FetchState'.
            return {
                ...state,
                // $FlowFixMe
                // @ts-expect-error - TS2339 - Property 'property' does not exist on type 'FetchAction | FavoritesAction'. | TS2531 - Object is possibly 'null'.
                data: [action.property, ...(state.data || [])],
            };

        case Actions.REMOVE_FAVORITE:
            // @ts-expect-error - TS2322 - Type '{ data: any[]; loading?: boolean | undefined; loaded?: boolean | undefined; preloaded?: boolean | undefined; params?: any; error?: FetchError | null | undefined; }' is not assignable to type 'FetchState'.
            return {
                ...state,
                // $FlowFixMe
                // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS7006 - Parameter 'hit' implicitly has an 'any' type. | TS2339 - Property 'externalID' does not exist on type 'FetchAction | FavoritesAction'.
                data: [...(state.data || []).filter((hit) => hit.externalID !== action.externalID)],
            };

        default:
            // $FlowFixMe
            return favoritesFetcherReducer(state, action);
    }
};

export default favoritesReducer;
