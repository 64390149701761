import Loadable, { LoadingSpinner } from 'strat/loadable';

/**
 * Load the actual pop up contents component async.
 */
const LoginPopUpContents = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ './loginPopUpContents'),
    loading: LoadingSpinner,
});

export const LoginPopUpContentsPreloaded = Loadable({
    loader: () => import(/* webpackChunkName: 'preloadedLogin' */ './loginPopUpContents'),
    loading: LoadingSpinner,
});

export default LoginPopUpContents;
