import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSearchSortOption } from '@sector-labs/fe-search-redux/state';
import { selectRouteParams } from 'strat/routes/selectors';

import { useNavigateToRefinedSearch } from 'horizontal/search/routing';
import { trackSortChange, trackShowVerifiedAds } from 'horizontal/gtm';
import { selectCategoryBySlug } from 'horizontal/categories';
import { GlobalState } from 'horizontal/state';

import { useDefaultSortOption, useSortOption, SortOptionKey } from './sortOptions';

const useSelectSortingOption = () => {
    const { categorySlug } = useSelector(selectRouteParams);
    const category = useSelector((state: GlobalState) => selectCategoryBySlug(state, categorySlug));
    const verifiedSorting = useSortOption(SortOptionKey.VERIFIED_ACCOUNT_FIRST);
    const currentOption = useSearchSortOption();
    const navigateToSearch = useNavigateToRefinedSearch();
    const defaultSortOption = useDefaultSortOption(category);
    const [isSortedByVerified, setIsSortedByVerified] = React.useState(
        currentOption?.key === SortOptionKey.VERIFIED_ACCOUNT_FIRST,
    );

    /**
     * this method handles toggling between verified sorting and
     * non verified sorting. If the page currently is not sorted
     * by verified, then when this method is called, it means we
     * are now sorting by verified. This logic here also indirectly
     * affects any component that renders based on the current
     * sorting option, or some similar description based on the
     * outcome of this call.
     */
    const selectOption = () => {
        const appliedSort = isSortedByVerified ? defaultSortOption : verifiedSorting;
        trackSortChange(appliedSort.key);
        if (!isSortedByVerified) {
            trackShowVerifiedAds();
        }
        navigateToSearch({ sortOption: appliedSort });
        setIsSortedByVerified(!isSortedByVerified);
    };

    React.useEffect(
        () => setIsSortedByVerified(currentOption?.key === SortOptionKey.VERIFIED_ACCOUNT_FIRST),
        [currentOption, setIsSortedByVerified],
    );

    return { isSortedByVerified, selectOption };
};

export default useSelectSortingOption;
