import * as React from 'react';
import { useRouter } from 'react-true-router';
import { serializeLocation } from 'react-true-router/location';
import { buildCanonicalURLWithoutLanguage } from 'strat/routing';
import type { Primitive } from 'strat/types';

type Params = {
    [key: string]: Primitive;
};

const useExternalWindowNavigation = () => {
    const router = useRouter();

    return React.useCallback(
        (routeName: string, params: Params) => {
            window.open(
                buildCanonicalURLWithoutLanguage(
                    serializeLocation(
                        router.getRouteURL(routeName, {
                            ...params,
                        }),
                    ),
                ),
                '_blank',
            );
        },
        [router],
    );
};

export default useExternalWindowNavigation;
