const CommonSavedSearchParams = Object.freeze([
    'purpose',
    'baths',
    'beds',
    'agencies',
    'price',
    'area',
    'rentFrequency',
    'completionStatus',
    'keywords',
    'panoramaCount',
    'videoCount',
    'hasFloorPlan',
    'agents',
]);

/**
 * The list of search route params that are part of
 * a saved search and are sent to the backend.
 * Must be from the fields of {@see SavedSearchParams},
 */
const BackendSavedSearchParams = Object.freeze([
    ...CommonSavedSearchParams,
    'categoryExternalID',
    'locationExternalIDs',
]);

/**
 * The list of search route params that are part of
 * a saved search and are used on the frontend.
 * Must be from the fields of {@see SavedSearchParams},
 */
const SavedSearchParameters = Object.freeze([...CommonSavedSearchParams, 'category', 'locations']);

export { BackendSavedSearchParams, SavedSearchParameters };
