import React from 'react';
import { Flex } from 'strat/components';
import classNames from 'classnames';

import { UserDTO } from 'horizontal/dtos';
import { ProfilePicture } from 'horizontal/profile';
import { Agency } from 'horizontal/types';

import styles from './styles/sellerProfilePicture.cssm';

type SellerProfilePictureProps = {
    readonly adAgency?: Agency | null;
    readonly seller: UserDTO | Agency;
};
const SellerProfilePicture = ({ adAgency, seller }: SellerProfilePictureProps) => (
    <Flex column>
        <ProfilePicture
            user={seller}
            className={classNames({
                [styles.agencyProfilePicture]: !!adAgency,
                [styles.profilePicture]: !adAgency,
            })}
        />
    </Flex>
);

export default SellerProfilePicture;
