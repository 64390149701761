import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Formik } from 'formik';
import { RouteNames } from 'strat/routes';
import { useI18n } from 'strat/i18n/language';
import { useRouter } from 'react-true-router';
import settings from '@app/branding/settings';
import { Flex, Text } from 'strat/components';

import { StratAPI } from 'horizontal/api';
import XLoaderIcon from 'horizontal/assets/icons/x_loader.svg';
import { useMakeCancelable } from 'horizontal/util';

import Header from '../header';

import Form, { makeValidationSchema, TPayPaymentField } from './tPayForm';
import styles from './styles/tPayPayment.cssm';
import useValidPhone from './useValidPhone';

type Props = {
    readonly orderExternalID: string;
    readonly sessionID: string;
};

const renderForm = (errorMessage: string) => (props: React.ComponentProps<typeof Form>) => (
    <Form {...props} errorMessage={errorMessage} />
);

const TPayPayment = ({ orderExternalID, sessionID }: Props) => {
    const router = useRouter();
    const i18n = useI18n();

    const [loadingPayment, setLoadingPayment] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const validationSchema = React.useMemo(() => makeValidationSchema(i18n), [i18n]);
    const makeCancelable = useMakeCancelable();
    const { phone: validPhone, loading: loadingPhone } = useValidPhone();

    const onSubmit = React.useCallback(
        (phoneNumber: string, setSubmitting: (arg1: boolean) => void) => {
            const cancelablePromise = makeCancelable(
                new StratAPI().tpayCheckout({
                    sessionID,
                    phoneNumber: `${settings.defaultPhoneNumber.prefix}${phoneNumber}`,
                    language: i18n.locale,
                }),
            );

            setLoadingPayment(true);

            // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
            cancelablePromise.then(({ data, status }) => {
                setLoadingPayment(false);

                if (status !== 200) {
                    setSubmitting(false);
                    setErrorMessage(t(i18n)`Unexpected error occurred. Please try again later.`);
                }
                if (data?.status === 'SUCCESS') {
                    router.pushRoute(RouteNames.PAYMENT_TPAY_OTP, {
                        phoneNumber,
                        sessionID,
                        orderExternalID,
                    });
                }
            });
        },
        [orderExternalID, sessionID, router, makeCancelable, i18n],
    );

    // @ts-expect-error - TS7031 - Binding element 'setSubmitting' implicitly has an 'any' type.
    const handleSubmit = (values: any, { setSubmitting }) => {
        const phoneNumber = values[TPayPaymentField.attribute];

        onSubmit(phoneNumber, setSubmitting);
    };

    const loading = loadingPayment || loadingPhone;

    return (
        <section>
            <Header onBack={() => router.pop()} />
            <Flex column>
                {loading ? (
                    <div className={styles.loaderDim}>
                        <XLoaderIcon className={styles.loaderIcon} />
                    </div>
                ) : (
                    <Flex column className={styles.wrapper}>
                        <Text.Base className={styles.mainText}>
                            <Trans>Enter your mobile number to pay using your mobile credit.</Trans>
                        </Text.Base>
                        <Formik
                            initialValues={{
                                [TPayPaymentField.attribute]: validPhone,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {renderForm(errorMessage)}
                        </Formik>
                    </Flex>
                )}
            </Flex>
        </section>
    );
};

export default TPayPayment;
