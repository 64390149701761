import { Category } from 'horizontal/types';
import { findCategoryByExternalID, findCategoryBySlug } from 'horizontal/categories';

import { MatchParams } from './types';

const selectMatchedCategory = (
    categories: Category[],
    { categoryExternalID, categorySlug }: MatchParams,
): Category | null | undefined => {
    if (categoryExternalID) {
        return findCategoryByExternalID(categories, categoryExternalID);
    }
    if (categorySlug) {
        return findCategoryBySlug(categories, categorySlug);
    }
    return null;
};

export default selectMatchedCategory;
