import { t } from '@lingui/macro';
import React from 'react';
import { Flex, Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';

import { AdDocumentType, MixedAd } from 'horizontal/types';
import DownloadIcon from 'horizontal/assets/icons/iconDownload_noinline.svg';
import AttachmentVideoIcon from 'horizontal/assets/icons/attachmentVideoIcon_noinline.svg';
import AttachmentCarDocumentIcon from 'horizontal/assets/icons/attachmentsCarDocumentsIcon_noinline.svg';
import AttachmentAuctionSheetIcon from 'horizontal/assets/icons/attachmentsAuctionSheetIcon_noinline.svg';
import AttachmentInspectionSheetIcon from 'horizontal/assets/icons/attachmentsInspectionSheet_noinline.svg';
import { extractAdPanoramas } from 'horizontal/ad';

import styles from './styles/adAttachmentButton.cssm';

type Props = {
    readonly icon?: string;
    readonly text: string;
    readonly url?: string;
};
const AdAttachmentButton = ({ icon, text, url }: Props) => {
    return (
        <Flex className={styles.button} onClick={() => url && window.open(url, '_blank')}>
            {icon && <img src={icon} alt={''} className={styles.icon} />}
            <Text.Base>{text}</Text.Base>
        </Flex>
    );
};

const getVerifiedDocs = (ad: MixedAd, attachment: Values<typeof AdDocumentType>) => {
    return ad.documents?.filter((document) => document.tag === attachment) || [];
};

const getDocument = (ad: MixedAd, attachment: Values<typeof AdDocumentType>) => {
    return ad.documents?.find((document) => document.tag === attachment);
};

type ButtonsProps = {
    readonly ad: MixedAd;
};

const AdAttachmentButtons = ({ ad }: ButtonsProps) => {
    const i18n = useI18n();
    const hasVideo = ad?.extraFields?.video === 'yes';
    const carDocument = getDocument(ad, AdDocumentType.CAR_DOCUMENT);
    const auctionSheet = getDocument(ad, AdDocumentType.AUCTION_SHEET);
    const inspectionSheet = getDocument(ad, AdDocumentType.INSPECTION_SHEET);
    const managedDocument = getDocument(ad, AdDocumentType.MANAGED);
    const document = getDocument(ad, AdDocumentType.DOCUMENT);
    const verifiedDocs = getVerifiedDocs(ad, AdDocumentType.VERIFIED);
    const [firstPanorama] = extractAdPanoramas(ad);
    const shouldShow =
        hasVideo ||
        carDocument ||
        auctionSheet ||
        inspectionSheet ||
        !!verifiedDocs.length ||
        managedDocument ||
        document ||
        firstPanorama;
    if (!shouldShow) {
        return null;
    }

    return (
        <Flex alignStart className={styles.mobileContainer}>
            {hasVideo && (
                <AdAttachmentButton
                    icon={AttachmentVideoIcon}
                    text={t(i18n)`See video`}
                    url={ad.videos?.[0].url}
                />
            )}
            {firstPanorama && (
                <AdAttachmentButton text={t(i18n)`Virtual`} url={firstPanorama.url} />
            )}
            {carDocument && (
                <AdAttachmentButton
                    icon={AttachmentCarDocumentIcon}
                    text={t(i18n)`Car Documents`}
                    url={carDocument?.fileSource}
                />
            )}
            {auctionSheet && (
                <AdAttachmentButton
                    icon={AttachmentAuctionSheetIcon}
                    text={t(i18n)`Auction Sheet`}
                    url={auctionSheet?.fileSource}
                />
            )}
            {inspectionSheet && (
                <AdAttachmentButton
                    icon={AttachmentInspectionSheetIcon}
                    text={t(i18n)`Car Inspection`}
                    url={inspectionSheet?.fileSource}
                />
            )}
            {managedDocument && (
                <AdAttachmentButton
                    icon={AttachmentInspectionSheetIcon}
                    text={t(i18n)`Download Managed Doc.`}
                    url={managedDocument?.fileSource}
                />
            )}
            {document && (
                <AdAttachmentButton
                    icon={AttachmentInspectionSheetIcon}
                    text={t(i18n)`Download Doc.`}
                    url={document?.fileSource}
                />
            )}
            {!!verifiedDocs.length &&
                verifiedDocs.map((document) => (
                    <AdAttachmentButton
                        icon={DownloadIcon}
                        text={t(i18n)`Download Verified Doc.`}
                        url={document?.fileSource}
                    />
                ))}
        </Flex>
    );
};
export default AdAttachmentButtons;
