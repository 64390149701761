import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';
import { Trans } from '@lingui/macro';

import { Header } from 'horizontal/header';
import { CategoryDropdownSwitcher } from 'horizontal/categorySwitcher';
import Footer from 'horizontal/footer';
import MyAds from 'horizontal/adManagement/myAds';
import ProfileHeader from 'horizontal/profileHeader';

import AdManagementHead from './adManagementHead';
import styles from './styles/adManagementPageDesktop.cssm';

const AdManagementPageDesktop = {
    head: () => <AdManagementHead />,
    body: () => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <CategoryDropdownSwitcher />
            <PageWidthWrapper className={styles.container}>
                <ProfileHeader header={<Trans>Manage and view your Ads</Trans>} />
                <MyAds />
            </PageWidthWrapper>
            <Footer />
        </Flex>
    ),
} as const;

export default AdManagementPageDesktop;
