import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

const selectSellingList = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.sellingOrders?.data || EMPTY_ARRAY,
    // @ts-expect-error - TS7006 - Parameter 'order' implicitly has an 'any' type.
    (order) => order,
);

const selectSellingListLoaded = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.sellingOrders?.loaded || false,
    // @ts-expect-error - TS7006 - Parameter 'loaded' implicitly has an 'any' type.
    (loaded) => loaded,
);

export { selectSellingList, selectSellingListLoaded };
