import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { createSelector } from 'reselect';
import settings from '@app/branding/settings';

import { withI18n } from 'strat/i18n/language/withI18n';
import type { Range } from 'strat/types';

import { connectLanguage } from './language';
import { Unit, UnitType } from './units';
import Area from './area';
import convertRange from './convertRange';
import {
    toLocalUnits as toLocalUnitsBase,
    roundLocalUnitsValue as roundLocalUnitsValueBase,
    areaInLocalUnits as areaInLocalUnitsBase,
    formatLocalUnitsValue as formatLocalUnitsValueBase,
} from './localUnitsFormatting';

export type LocalUnitsProps = {
    isDefaultAreaUnit: boolean;
    // @ts-expect-error - TS2749 - 'UnitType' refers to a value, but is being used as a type here. Did you mean 'typeof UnitType'?
    currentUnits: Partial<Record<UnitType, typeof UnitType>>;
};

export type ConnectedLocalUnitsProps = LocalUnitsProps & {
    convertRange: (
        value: Range | null | undefined,
        fromUnit: string,
        toUnit: string,
    ) => Range | null | undefined;
    areaInLocalUnits: (
        value: number,
        unit: Values<typeof Area>,
        currentUnit?: string | null | undefined,
    ) => number;
    toLocalUnits: (
        value: number,
        unitType: Values<typeof UnitType>,
        baseUnit: Values<typeof Unit>,
        currentUnit?: string | null | undefined,
    ) => number;
    formatLocalUnitsValue: (
        value: number,
        unitType: Values<typeof UnitType>,
        unit: Values<typeof Unit>,
    ) => number;
    roundLocalUnitsValue: (
        value: number,
        unitType: Values<typeof UnitType>,
        unit: Values<typeof Unit>,
    ) => number;
};

const connectLocalUnits = <T extends any>(
    component: Flow.AbstractComponent<T>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'T & LocalUnitsProps & { convertRange: (value: Range | null | undefined, fromUnit: string, toUnit: string) => Range | null | undefined; areaInLocalUnits: (value: number, unit: string | ... 5 more ... | (() => string[]), currentUnit?: string | ... 1 more ... | undefined) => number; toLocalUnits: (value: number, unitTy...'.
): Flow.AbstractComponent<Flow.Diff<T, T & ConnectedLocalUnitsProps>> => {
    class LocalUnitsConnector<Y extends any> extends React.Component<Y & LocalUnitsProps> {
        constructor(props: Y & LocalUnitsProps) {
            super(props);
            autoBind(this);
        }

        /**
         * Converts the specified area, noted in the base
         * area units into a valid area in the local current area units.
         */
        areaInLocalUnits(value: number, unit = Area.SQM, currentUnit = null): number {
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'. | TS2538 - Type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...' cannot be used as an index type.
            return areaInLocalUnitsBase(value, unit, currentUnit || this.props.currentUnits[Area]);
        }

        /**
         * Converts the specified value, noted in the base
         * units into a valid value in the local current units.
         */
        toLocalUnits(
            value: number,
            unitType: any,
            baseUnit: Values<typeof Unit>,
            convertedUnit = null,
        ): number {
            return toLocalUnitsBase(
                value,
                unitType,
                baseUnit,
                // @ts-expect-error - TS2345 - Argument of type 'Readonly<{ Area: Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: num...' is not assignable to parameter of type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
                convertedUnit || this.props.currentUnits[unitType],
            );
        }

        /**
         * Formats the specified value using the current locales.
         */
        formatLocalUnitsValue(value: number, unitType = null, unit = null): string {
            // @ts-expect-error - TS2339 - Property 'intlNumberFormat' does not exist on type 'Readonly<Y & LocalUnitsProps> & Readonly<{ children?: ReactNode; }>'.
            return formatLocalUnitsValueBase(value, unitType, unit, this.props.intlNumberFormat);
        }

        /**
         * Rounds the specified value using the current locales.
         */
        roundLocalUnitsValue(value: number, unitType = null, unit = null): number {
            // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'Readonly<{ SQFT: string; SQYD: string; SQM: string; MARLA: string; KANAL: string; SQWA: string; RAI: string; NGAN: string; KATHA: string; text(i18n: any, area: any): any; abbreviation(i18n: any, area: any): any; formattingSettings(area: any): { ...; } | { ...; }; roundingFunction(area: any): (x: number) => number; i...'.
            return roundLocalUnitsValueBase(value, unitType, unit);
        }

        /**
         * Renders the wrapped component.
         */
        render(): any {
            const props = {
                ...this.props,
                convertRange,
                areaInLocalUnits: this.areaInLocalUnits,
                toLocalUnits: this.toLocalUnits,
                formatLocalUnitsValue: this.formatLocalUnitsValue,
                roundLocalUnitsValue: this.roundLocalUnitsValue,
            } as const;
            // @ts-expect-error - TS2769 - No overload matches this call.
            return React.createElement(component, props);
        }
    }

    const selectCurrentUnits = createSelector(settings.selectAreaUnit, ({ areaUnit }) => ({
        // @ts-expect-error - TS2464 - A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
        [Area]: areaUnit,
    }));

    // @ts-expect-error - TS2322 - Type 'ConnectedComponent<ComponentType<Omit<withI18nProps, "i18n">>, { context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } | { ...; }>' is not assignable to type 'AbstractComponent<Diff<T, T & LocalUnitsProps & { convertRange: (value: Range | null | undefined, fromUnit: string, toUnit: string) => Range | null | undefined; areaInLocalUnits: (value: number, unit: string | ... 5 more ... | (() => string[]), currentUnit?: string | ... 1 more ... | undefined) => number; toLocalUni...'.
    return connect((state) => {
        const { areaUnit, isDefaultAreaUnit } = settings.selectAreaUnit(state);
        return {
            areaUnit,
            isDefaultAreaUnit,
            // for compatability, usage of this property should
            // be discouraged as much as possible..., use 'areaUnit'
            // instead
            // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '{}'.
            currentUnits: selectCurrentUnits(state),
        };
    })(withI18n()(connectLanguage(LocalUnitsConnector)));
};

export default connectLocalUnits;
