import * as React from 'react';
import { Flex, Text } from 'strat/components';

import type { Location } from 'horizontal/types';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import styles from '../styles/nestedLocationDropdown.cssm';

export type Props = {
    readonly locationTree: Array<Location>;
    readonly setActiveLocation: (location: Location) => void;
    readonly setLocationTree: (locationList: Array<Location>) => void;
    readonly onLocationClicked: (location: Location) => void;
};

const LocationListOptions = ({
    locationTree,
    setActiveLocation,
    onLocationClicked,
    setLocationTree,
}: Props) => {
    return (
        <>
            {locationTree.map((item) => (
                <Flex
                    alignCenter
                    key={item.id}
                    className={[styles.locationElement, styles.locationElementHover].join(' ')}
                    onClick={() => {
                        if (item.hasChildren) {
                            setActiveLocation(item);
                        } else {
                            onLocationClicked(item);
                            setLocationTree([]);
                        }
                    }}
                >
                    <Text.Regular>{item.name}</Text.Regular>
                    {item.hasChildren && (
                        <img src={iconArrowDown} alt={'Select location'} className={styles.arrow} />
                    )}
                </Flex>
            ))}
        </>
    );
};

export default LocationListOptions;
