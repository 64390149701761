import * as React from 'react';

type Props = {
    readonly children: React.ReactNode;
    readonly className?: string;
};

const BreadcrumbList = ({ children, className }: Props) => (
    <ul itemScope itemType="https://schema.org/BreadcrumbList" className={className}>
        {children}
    </ul>
);

export default BreadcrumbList;
