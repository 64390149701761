import * as React from 'react';

import { ProjectBadgeStyle, type BadgeProps } from './types';
import CardBadge from './cardBadge';
import SmallChipBadge from './smallChipBadge';
import SegmentedBarBadge from './segmentedBarBadge';

type Props = BadgeProps & {
    style: ProjectBadgeStyle;
};

const Badge = ({ style, ...props }: Props) => {
    switch (style) {
        case ProjectBadgeStyle.SMALL_CARD:
            return <CardBadge {...props} />;

        case ProjectBadgeStyle.LARGE_CARD:
            return <CardBadge {...props} large />;

        case ProjectBadgeStyle.SMALL_SEGMENTED_BAR:
            return <SegmentedBarBadge {...props} />;

        case ProjectBadgeStyle.LARGE_SEGMENTED_BAR:
            return <SegmentedBarBadge {...props} large />;

        case ProjectBadgeStyle.SMALL_CHIP:
        default:
            return <SmallChipBadge {...props} />;
    }
};

export default Badge;
