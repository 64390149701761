import * as React from 'react';
import { logError } from 'strat/error/log';
import { isCanceled } from 'strat/util';

import { StratAPI } from 'horizontal/api';
import type { AdContactInfo } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';

type UseFetchAdContactInfo = {
    readonly fetchContactInfo: () => void;
    readonly contactInfo: AdContactInfo | null | undefined;
    readonly loading: boolean;
    readonly isError: boolean;
};

const useFetchAdContactInfo = (adExternalID?: string | null): UseFetchAdContactInfo => {
    const [contactInfo, setContactInfo] = React.useState<AdContactInfo | null | undefined>();
    const [loading, setLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const makeCancelable = useMakeCancelable();

    const fetchContactInfo = React.useCallback(() => {
        if (!adExternalID) {
            return;
        }

        setLoading(true);
        setIsError(false);
        makeCancelable(
            new StratAPI().adContactInfo(adExternalID).then(
                ({ data, status }) => {
                    if (status >= 400) {
                        logError({
                            msg: `There was an error fetching ad contact info`,
                            context: {
                                adExternalID,
                                status,
                                data,
                            },
                        });

                        setIsError(true);
                        setLoading(false);
                        return;
                    }
                    setContactInfo(data);
                    setLoading(false);
                },
                (e) => {
                    if (isCanceled(e)) {
                        return;
                    }
                    setIsError(true);
                    setLoading(false);

                    logError({
                        e,
                        msg: 'Error while fetching ad contact info',
                        context: { adExternalID },
                    });
                },
            ),
        );
    }, [adExternalID, makeCancelable]);

    return {
        fetchContactInfo,
        contactInfo,
        loading,
        isError,
    };
};

export default useFetchAdContactInfo;
