import * as React from 'react';
import classNames from 'classnames';

import IconVerified from 'horizontal/assets/icons/iconVerified_noinline.svg';

import styles from './styles/verifiedProfileIcon.cssm';

type Props = {
    readonly large?: boolean;
    readonly leftMargin?: boolean;
};

const VerifiedProfileIcon = ({ large, leftMargin }: Props) => {
    return (
        <img
            className={classNames(styles.icon, {
                [styles.largeIcon]: large,
                [styles.leftMargin]: leftMargin,
            })}
            src={IconVerified}
            alt={'Verified Badge'}
        />
    );
};

export default VerifiedProfileIcon;
