import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { useI18n } from 'strat/i18n/language';
import { selectCurrentPage } from 'strat/search/selectors';
import { Text } from 'strat/components';

import { trackMyAdsAdFilter } from 'horizontal/gtm';

import { useProductPurchaseStatusParam } from '../hooks';
import { UserOrdersSection, ProductPurchaseVirtualStatus } from '../types';

import styles from './styles/productPurchasesStatusNavBar.cssm';

const ProductPurchasesStatusNavBar = ({ className }: { readonly className?: string }) => {
    const i18n = useI18n();
    const status = useProductPurchaseStatusParam();
    const pageNumber = useSelector(selectCurrentPage);

    return (
        <ul className={classNames(styles.list, className)}>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_ORDERS}
                    params={{ section: UserOrdersSection.ORDERS }}
                    title={t(i18n)`Active`}
                    onClick={() => trackMyAdsAdFilter('active', pageNumber)}
                    className={classNames(styles.link, {
                        [styles.selected]: status === ProductPurchaseVirtualStatus.ACTIVE,
                    })}
                >
                    <Text.Regular>{t(i18n)`Active`}</Text.Regular>
                </Link>
            </li>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_ORDERS}
                    params={{
                        section: UserOrdersSection.ORDERS,
                        ordersStatus: ProductPurchaseVirtualStatus.SCHEDULED,
                    }}
                    title={t(i18n)`Scheduled`}
                    className={classNames(styles.link, {
                        [styles.selected]: status === ProductPurchaseVirtualStatus.SCHEDULED,
                    })}
                    onClick={() => trackMyAdsAdFilter('scheduled', pageNumber)}
                >
                    <Text.Regular>{t(i18n)`Scheduled`}</Text.Regular>
                </Link>
            </li>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_ORDERS}
                    params={{
                        section: UserOrdersSection.ORDERS,
                        ordersStatus: ProductPurchaseVirtualStatus.EXPIRED,
                    }}
                    title={t(i18n)`Expired`}
                    className={classNames(styles.link, {
                        [styles.selected]: status === ProductPurchaseVirtualStatus.EXPIRED,
                    })}
                    onClick={() => trackMyAdsAdFilter('expired', pageNumber)}
                >
                    <Text.Regular>{t(i18n)`Expired`}</Text.Regular>
                </Link>
            </li>
        </ul>
    );
};

export default ProductPurchasesStatusNavBar;
