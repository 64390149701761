import * as React from 'react';

import { useI18n } from 'strat/i18n/language';
import { formatDate } from 'strat/i18n/language/dateTimeFormatter';

const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
} as const;

type Props = {
    timestampMillis: number;
    className: string;
};

const ReviewDate = ({ timestampMillis, className }: Props) => {
    const i18n = useI18n();
    const date = React.useMemo(
        () => formatDate(new Date(timestampMillis * 1000), i18n.locale, dateOptions),
        [timestampMillis, i18n],
    );

    return <div className={className}>{date}</div>;
};

export default ReviewDate;
