import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';

import { ChatNegotiationState } from 'horizontal/chat/constants';

const messageText = (i18n: I18n) =>
    ({
        [ChatNegotiationState.INITIATED]: t(
            i18n,
        )`Please make an offer first to close the deal faster to buy this product.`,
        [ChatNegotiationState.OFFER_REJECTED]: t(
            i18n,
        )`Sorry I cannot sell the item at this price. Please give me a better offer.`,
        [ChatNegotiationState.ACCEPTED]: t(
            i18n,
        )`I accept your offer. Let's move towards the final deal`,
    }) as const;

const getLoggedInUserOfferText = (i18n: I18n) => t(i18n)`Your offer`;
const getBuyerOfferText = (i18n: I18n) => t(i18n)`Buyer's offer`;
const getSellerOfferText = (i18n: I18n) => t(i18n)`Seller's offer`;

const getLastMessageText = (
    state: ChatNegotiationState,
    isMessageFromUser: boolean,
    i18n: I18n,
): string => {
    switch (state) {
        case ChatNegotiationState.INITIATED:
        case ChatNegotiationState.OFFER_REJECTED:
        case ChatNegotiationState.ACCEPTED:
            return messageText(i18n)[state];
        case ChatNegotiationState.BUYER_MADE_OFFER:
            return isMessageFromUser ? getLoggedInUserOfferText(i18n) : getBuyerOfferText(i18n);
        case ChatNegotiationState.SELLER_MADE_OFFER:
        default:
            return isMessageFromUser ? getLoggedInUserOfferText(i18n) : getSellerOfferText(i18n);
    }
};

const getMessageText = (state: keyof ReturnType<typeof messageText>, i18n: I18n): string => {
    return messageText(i18n)[state];
};

const useNegotiationReplies = () => {
    const i18n = useI18n();
    return messageText(i18n);
};

const getInfoBoxText = (
    state: ChatNegotiationState,
    isMessageFromUser: boolean,
    isLoggedInUserSeller: boolean,
    i18n: I18n,
): string => {
    const loggedInUserOffer = getLoggedInUserOfferText(i18n);
    const buyerOffer = getBuyerOfferText(i18n);
    const sellerOffer = getSellerOfferText(i18n);

    switch (state) {
        case ChatNegotiationState.BUYER_MADE_OFFER:
            return isMessageFromUser ? loggedInUserOffer : buyerOffer;
        case ChatNegotiationState.SELLER_MADE_OFFER:
            return isMessageFromUser ? loggedInUserOffer : sellerOffer;
        case ChatNegotiationState.OFFER_REJECTED:
            return isMessageFromUser ? buyerOffer : loggedInUserOffer;
        case ChatNegotiationState.ACCEPTED: {
            if (!isMessageFromUser) {
                return loggedInUserOffer;
            }
            return isLoggedInUserSeller ? buyerOffer : sellerOffer;
        }

        default:
            return isMessageFromUser ? loggedInUserOffer : sellerOffer;
    }
};

export { useNegotiationReplies, messageText, getMessageText, getInfoBoxText, getLastMessageText };
