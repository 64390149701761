import { useSearchFacetValues } from '@sector-labs/fe-search-redux/state';
import { FilterValues } from 'strat/search';

import { AdProduct } from 'horizontal/types';

const useFeaturedAdsCount = () => {
    const productFacets = useSearchFacetValues(FilterValues.product.attribute);

    const totalProductFacets = useSearchFacetValues(FilterValues.totalProductCount);

    return {
        featuredCount:
            productFacets.find((facet) => facet.value === AdProduct.FEATURED)?.count || 0,
        totalFeaturedCount:
            totalProductFacets.find((facet) => facet.value === AdProduct.FEATURED)?.count || 0,
    };
};

export default useFeaturedAdsCount;
