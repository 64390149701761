import * as React from 'react';

import { useCreationDate } from 'horizontal/generic';

const CreationDate = ({ createdAt }: { createdAt: number }) => {
    const creationDateString = useCreationDate(createdAt);

    return <span aria-label="Creation date">{creationDateString}</span>;
};

export default CreationDate;
