import { makeCancelable } from 'strat/util';

import { StratAPI } from 'horizontal/api';

import { AddressFormActions } from '../types';
import type { AddressInfo } from '../types';

const changeAddressInfo = (
    address: AddressInfo,
    userExternalID: string,
    action: string,
    getAddressList?: () => void,
) => {
    const data = { ...address } as const;
    if (action === AddressFormActions.DEFAULT) {
        // @ts-expect-error - TS2540 - Cannot assign to 'isDefault' because it is a read-only property.
        data.isDefault = true;
    } else if (action === AddressFormActions.DELETE) {
        // @ts-expect-error - TS2540 - Cannot assign to 'isDeleted' because it is a read-only property.
        data.isDeleted = true;
    }
    const cancelablePromise = makeCancelable(
        // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'string'.
        new StratAPI().updateUserAddress(userExternalID, data, address?.id),
    );
    cancelablePromise.then(() => {
        if (getAddressList) {
            getAddressList();
        }
    });
};
const addUserAddress = (
    address: AddressInfo,
    userExternalID: string,
    closeDialog: () => void,
    getAddressList?: () => void,
) => {
    const cancelablePromise = makeCancelable(
        new StratAPI().addUserAddress(userExternalID, address),
    );

    cancelablePromise.then((response) => {
        if (response.status === 200 || response.status === 201) {
            if (getAddressList) {
                getAddressList();
            }
            closeDialog();
        }
    });
};

export { changeAddressInfo, addUserAddress };
