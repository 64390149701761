/**
 * Computes where a certain element would stop if it would simply move with the velocity
 * of the swipe.
 *
 * @param startPosition - The position relative to which to calculate the projection.
 * @param velocity - The velocity (in px/ms) the element was swiped with.
 * @param decay - The rate (in px/ms) at which the velocity gradually reduces
 */
const computeProjection = (startPosition: number, velocity: number, decay = 0.998) =>
    startPosition + (velocity * decay) / (1 - decay);

/**
 * Given a projection, and some thresholds, computes the state with the
 * threshold closest to the projection.
 *
 * Example:
 *
 * Given a projection and two states, A and B, with the following thresholds:
 *
 *      -----
 *     |     |
 *     |     |
 *     |- x -| - projection
 *     |     |
 *     |- - -| - Threshold for state A
 *     |     |
 *     |- - -| - Threshold for state B
 *      -----
 *
 * the function will return `A`
 */
const findStateClosestToProjection = (
    projection: number,
    stateThresholds: {
        [key: string]: number;
    },
): string => // $FlowFixMe
    Object.entries(stateThresholds).reduce(
        // @ts-expect-error - TS2769 - No overload matches this call.
        ({ min, targetState }, [key, value]: [any, any]) => {
            const distance = Math.abs(window.innerHeight - value - projection);

            if (!targetState || min === null || distance < min) {
                return {
                    targetState: key,
                    min: distance,
                };
            }

            return { min, targetState };
        },
        { min: null, targetState: null },
        // @ts-expect-error - TS2339 - Property 'targetState' does not exist on type '[string, number]'.
    ).targetState;

export { computeProjection, findStateClosestToProjection };
