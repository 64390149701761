import type { RoutingMiddleware } from '../routingMiddleware';
import type { URLProcessor } from '../urlProcessor';

/**
 * HTTP headers to include in a HTTP request or response.
 */
type HttpHeaders = {
    [key: string]: string;
};

/**
 * Options the user can specify for redirects.
 */
type RedirectOptions = {
    isAbsolute?: boolean;
    status?: number;
};

/**
 * Allows routes to return information to the server
 * about what to return to the client.
 */
class RoutingContextHttp {
    /**
     * HTTP status code to return to the client.
     */
    _status: number;

    /**
     * HTTP headers to return to the client.
     */
    _headers: HttpHeaders;

    /**
     * HTTP body to return to the client.
     */
    _body: unknown;

    /**
     * URL processor for pre/post processing of URLs
     */
    _urlProcessor: URLProcessor | null;

    /**
     * Initializes a new instance of {@see RoutingContextHttp}.
     */
    constructor(urlProcessor: URLProcessor | null = null) {
        this._status = 200;
        this._headers = {};
        this._body = null;
        this._urlProcessor = urlProcessor;
    }

    /**
     * Gets or sets the HTTP status code to return to the client.
     * @param status The HTTP status code to return to the client.
     * @returns The HTTP status code to return to the client.
     */
    status(status?: number | null): number {
        if (status) {
            this._status = status;
        }

        return this._status;
    }

    /**
     * Gets or sets the value of a HTTP header to return to the client.
     * @param name The name of the HTTP header to return to the client.
     * @param value The value of the HTTP header to return to the client.
     * @returns The value of the header with the specified name.
     */
    header(name: string, value?: string | null): string {
        if (value === undefined) {
            return this._headers[name];
        }

        this._headers[name] = value || '';
        return this._headers[name];
    }

    /**
     * Gets or sets the HTTP headers to return to the client.
     */
    headers(headers?: HttpHeaders | null): HttpHeaders {
        if (headers) {
            this._headers = headers;
        }

        return this._headers;
    }

    /**
     * Gets or sets the HTTP body to return to the client.
     */
    body(body: unknown): unknown {
        if (body) {
            this._body = body;
        }
        return this._body;
    }

    /**
     * Gets or sets the HTTP body as a JSON to return to the client.
     */
    json(data: any): unknown {
        if (data) {
            this._body = JSON.stringify(data);
            this.header('Content-Type', 'application/json');
        }
        return this._body;
    }

    /**
     * Redirect to a given URL.
     */
    redirect(url: string, options: RedirectOptions = {}): string {
        let redirectURL = url;

        if (this._urlProcessor && !options.isAbsolute) {
            redirectURL = this._urlProcessor.outbound(redirectURL);
        }

        this.header('Location', redirectURL);
        this.status(options.status || 301);

        return redirectURL;
    }

    /**
     * Gets whether the current status code would imply a re-direct.
     */
    isRedirecting(): boolean {
        const hasRedirectStatusCode = this.status() >= 300 && this.status() <= 399;
        if (!hasRedirectStatusCode) {
            return false;
        }

        return !!this.headers().Location;
    }
}

export { RoutingContextHttp };

/**
 * Creates a new routing middleware that lets route
 * report back on HTTP stuff (status code, headers etc).
 * @returns A new routing middleware.
 */
export default (): RoutingMiddleware =>
    (context): RoutingContextHttp =>
        new RoutingContextHttp(context.urlProcessor);
