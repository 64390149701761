import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';
import { Text, ListEntry } from 'strat/components';

import iconHelp from 'horizontal/assets/icons/iconHelp_noinline.svg';
import { trackClickHelp } from 'horizontal/gtm';

const renderLeft = () => <img alt={'Help'} src={iconHelp} />;

const HelpLink = () => {
    const i18n = useI18n();
    return (
        <a href={t(i18n)`help_url`} target="_blank noopener noreferrer">
            <ListEntry
                borderless
                hoverable
                renderLeft={renderLeft}
                renderRight={null}
                onClick={() => trackClickHelp(ViewSections.USER_DETAIL)}
            >
                <Text.Large>
                    <Trans>Help</Trans>
                </Text.Large>
            </ListEntry>
        </a>
    );
};

export default HelpLink;
