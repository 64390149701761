import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectGeoLocationData } from 'strat/user/selectors';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Button, Flex } from 'strat/components';
import type { Choice } from 'strat/components/types';

import LocationDropdown from 'horizontal/search/location/locationDropdown';
import type { Category } from 'horizontal/types';
import { useRefineLocationFilter } from 'horizontal/search/location';
import BrandingCategory from 'horizontal/branding/category';
import type { AppDispatch } from 'horizontal/state';

import { fetchTopSearches } from '../suggestedSearch';

import styles from './styles/searchForm.cssm';
import {
    AreaRangeFilter,
    PurposeFilter,
    PropertyTypeFilter,
    PriceRangeFilter,
    BedsAndBathsFilter,
} from './widgets';

type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly handleSubmit: (arg1: FormikValues) => void;
    readonly propertiesCategory: Category;
};

const SearchForm = ({ values, setFieldValue, propertiesCategory, handleSubmit }: Props) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    const { loading, closestLocation } = useSelector(selectGeoLocationData);
    const [selectCurrentLocationFlag, setSelectCurrentLocationFlag] = React.useState(false);
    const [categoryList, setCategoryList] = React.useState<Array<Choice<string>>>([]);
    const refineLocation = useRefineLocationFilter();

    React.useEffect(() => {
        if (selectCurrentLocationFlag && loading === false) {
            setFieldValue('location', closestLocation || settings.topLevelLocation);
        }
    }, [closestLocation, selectCurrentLocationFlag, setFieldValue, loading]);

    React.useEffect(() => {
        if (propertiesCategory?.children.length) {
            setCategoryList(
                propertiesCategory.children
                    .filter((categoryElement) => categoryElement?.purpose === values.purpose)
                    .map((categoryElement) => ({
                        label: categoryElement.name,
                        value: categoryElement.externalID,
                    })),
            );
        }
    }, [values.purpose, propertiesCategory]);

    const setSubPropertiesCategory = React.useCallback(
        (categoryExternalID) => {
            const propertyType = propertiesCategory.children.find(
                (categoryElement) => categoryElement.externalID === categoryExternalID,
            );

            setFieldValue('selectedCategory', propertyType);
            dispatch(fetchTopSearches({ category: propertyType }));
        },
        [dispatch, propertiesCategory, setFieldValue],
    );

    React.useEffect(() => {
        if (categoryList.length) {
            setSubPropertiesCategory(categoryList[0].value);
        }
    }, [categoryList, setSubPropertiesCategory]);

    const hideBedsAndBathFilter = React.useMemo(
        () =>
            BrandingCategory.isOfCommercialSubtype(values.selectedCategory) ||
            BrandingCategory.isOfLandSubtype(values.selectedCategory),
        [values.selectedCategory],
    );

    const onBedsAndBathsReset = () => {
        setFieldValue('beds', []);
        setFieldValue('baths', []);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Flex column className={styles.filtersContainer}>
                <Flex alignCenter className={styles.filtersContainer}>
                    <div className={styles.filter}>
                        <PurposeFilter
                            value={values.purpose || ''}
                            setValue={(value) => setFieldValue('purpose', value)}
                        />
                    </div>
                    <div className={styles.locationFilter}>
                        <label className={styles.filterTitle}>{t(i18n)`Location`}</label>
                        <LocationDropdown
                            loading={loading}
                            searchInputClassName={styles.input}
                            renderSearchIcon={false}
                            onLocationSelected={(value) => {
                                refineLocation(value);
                                setFieldValue('location', value);
                            }}
                            onCurrentLocationSelected={() => setSelectCurrentLocationFlag(true)}
                            location={values.location}
                            shouldRenderTriggerRight={false}
                            dropdownContainerClassname={styles.locationDropdownContent}
                        />
                    </div>
                    <div className={styles.filter}>
                        <PropertyTypeFilter
                            value={values.selectedCategory?.externalID || ''}
                            setValue={setSubPropertiesCategory}
                            choices={categoryList}
                        />
                    </div>
                </Flex>
                <Flex className={styles.filtersContainer}>
                    {!hideBedsAndBathFilter && (
                        <div className={styles.filter}>
                            <BedsAndBathsFilter
                                title={`${t(i18n)`Beds`} / ${t(i18n)`Bathrooms`}`}
                                setFieldValue={setFieldValue}
                                beds={values.beds || []}
                                baths={values.baths || []}
                                onReset={onBedsAndBathsReset}
                            />
                        </div>
                    )}
                    <div className={styles.filter}>
                        <AreaRangeFilter
                            area={values.area}
                            setArea={(value) => setFieldValue('area', value)}
                        />
                    </div>
                    <div
                        className={classNames(styles.filter, {
                            [styles.stretchFilter]: hideBedsAndBathFilter,
                        })}
                    >
                        <PriceRangeFilter
                            value={values.price}
                            setValue={(value) => setFieldValue('price', value)}
                        />
                    </div>
                    <Flex alignEnd className={styles.buttonContainer}>
                        <Button stretch>{t(i18n)`Find`}</Button>
                    </Flex>
                </Flex>
            </Flex>
        </form>
    );
};

export default SearchForm;
