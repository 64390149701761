import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const PriceAlphabeticalForm = Object.freeze({
    THOUSAND: 'THOUSAND',
    LAC: 'LAC',
    LACS: 'LACS',
    CRORE: 'CRORE',

    text(i18n: I18n, price: string) {
        switch (price) {
            case this.THOUSAND:
                return t(i18n)`Thousand`;
            case this.LAC:
                return t(i18n)`Lac`;
            case this.LACS:
                return t(i18n)`Lacs`;
            case this.CRORE:
                return t(i18n)`Crore`;
            default:
                return ``;
        }
    },
});

const priceAlphabeticalFormTable = new Map<string, (arg1: number) => number>([
    [PriceAlphabeticalForm.THOUSAND, (value: number) => value / 1000],
    [PriceAlphabeticalForm.LAC, (value: number) => value / 100000],
    [PriceAlphabeticalForm.LACS, (value: number) => value / 100000],
    [PriceAlphabeticalForm.CRORE, (value: number) => value / 10000000],
]);

const convertPriceToText = (price: number, i18n: I18n) => {
    switch (true) {
        case price < 10000:
            return price;
        case price < 100000:
            // @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
            return `${priceAlphabeticalFormTable.get(PriceAlphabeticalForm.THOUSAND)(
                price,
            )} ${PriceAlphabeticalForm.text(i18n, PriceAlphabeticalForm.THOUSAND)}`;
        case price < 1000000:
            // @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
            return `${priceAlphabeticalFormTable.get(PriceAlphabeticalForm.LAC)(
                price,
            )} ${PriceAlphabeticalForm.text(i18n, PriceAlphabeticalForm.LAC)}`;
        case price < 10000000:
            // @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
            return `${priceAlphabeticalFormTable.get(PriceAlphabeticalForm.LACS)(
                price,
            )} ${PriceAlphabeticalForm.text(i18n, PriceAlphabeticalForm.LACS)}`;
        default:
            // @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
            return `${priceAlphabeticalFormTable.get(PriceAlphabeticalForm.CRORE)(
                price,
            )} ${PriceAlphabeticalForm.text(i18n, PriceAlphabeticalForm.CRORE)}`;
    }
};

const convertPriceToAlphabeticalForm = (price: number, i18n: I18n) => {
    if (!price) {
        return '';
    }

    return t(i18n)`PKR ${convertPriceToText(price, i18n)}`;
};

export { convertPriceToAlphabeticalForm, convertPriceToText };
