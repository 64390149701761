import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import settings from '@app/branding/settings';

import type { Category } from 'horizontal/types';
import { sortByAdCount } from 'horizontal/util';

import styles from './styles/categoryGroup.cssm';
import CategoryLink from './categoryLink';

type Props = {
    readonly category: Category;
    readonly onClick: (arg1: React.SyntheticEvent<any>) => void;
};

const CategoryGroup = ({ category, onClick }: Props) => {
    const childCategories = React.useMemo(
        () =>
            settings.enableL2CategoriesSort
                ? [...category.children].sort(sortByAdCount)
                : category.children,
        [category],
    );

    return (
        <div className={styles.category}>
            <div className={styles.title}>
                <CategoryLink
                    category={category}
                    onClick={onClick}
                    viewSection={ViewSections.CATEGORY_DROPDOWN}
                />
            </div>
            {childCategories.map((child) => (
                <div key={child.id} className={styles.child}>
                    <CategoryLink
                        category={child}
                        key={child.id}
                        onClick={onClick}
                        viewSection={ViewSections.CATEGORY_DROPDOWN}
                    />
                </div>
            ))}
        </div>
    );
};

export default CategoryGroup;
