import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { PageWidthWrapper } from 'strat/generic';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { Text } from 'strat/components';

import useSitemapNavbarItems from './useSitemapNavbarItems';
import styles from './styles/sitemapNavbar.cssm';
import { selectPageType } from './state/selectors';

const SitemapNavbar = () => {
    const navbarItems = useSitemapNavbarItems();
    const selectedItem = useSelector(selectPageType);

    return (
        <div className={styles.sitemapNavbar}>
            <PageWidthWrapper className={styles.pageWidthWrapper}>
                <ul className={styles.itemList}>
                    {navbarItems.map((item) => {
                        const itemStyles = classNames(styles.item, {
                            [styles.selectedItem]: selectedItem === item.pageType,
                        });

                        return (
                            <li key={item.name} className={itemStyles}>
                                <Link
                                    route={RouteNames.SITEMAP}
                                    params={{ sitemapViewSlug: item.pageType }}
                                    className={styles.itemLink}
                                >
                                    <Text.Regular uppercase>{item.name}</Text.Regular>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </PageWidthWrapper>
        </div>
    );
};

export default SitemapNavbar;
