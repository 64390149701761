import type { SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';

import type { PropertyData } from 'strat/property/types';
import type { FetchState } from 'strat/fetcher';
import { buildDefaultState } from 'strat/fetcher';
import type { GeoLocationData } from '@app/user/types';

import type { UserProfileData, UserLocationData, UserEvent } from '../types';

import Actions, {
    SetCityAction,
    SetClientIDAction,
    SetDebugMode,
    SetDeviceIDAction,
    SetFBCIDAction,
    SetFBPIDAction,
    SetGeoLocation,
    SetGeoLocationError,
    SetLoadingAction,
    SetLocationAction,
    SetProfileAction,
    SetRecentlyViewedPropertiesAction,
    SetSearchHistoryAction,
    SetAnonymousSessionIDAction,
    SetUserAgent,
    SetUserIP,
    SetVisitorIDAction,
    TrackEventAction,
} from './actions';
import type { UserAction } from './actions';

/**
 * State for the user.
 */
export type UserState = FetchState & {
    readonly loggedIn: boolean;
    readonly profile: UserProfileData | null | undefined;
    readonly location: UserLocationData | null | undefined;
    readonly city: any | null | undefined;
    readonly searchHistory: Array<SerializedFilterCollection>;
    readonly recentlyViewedProperties: Array<PropertyData>;
    readonly visitorID: string | null | undefined;
    readonly deviceID: string | null | undefined;
    readonly clientID: string | null | undefined;
    readonly anonymousSessionID: string | null | undefined;
    readonly eventsHistory: Array<UserEvent>;
    readonly userAgent: string | null | undefined;
    readonly geoLocation: GeoLocationData;
    readonly userIP?: string;
    readonly debugMode?: boolean | undefined;
    readonly fbpID: string | null;
    readonly fbcID: string | null;
};

/**
 * Default user state.
 */
const defaultState: UserState = {
    ...buildDefaultState(),
    loggedIn: false,
    profile: null,
    location: null,
    city: null,
    searchHistory: [],
    recentlyViewedProperties: [],
    visitorID: null,
    clientID: null,
    anonymousSessionID: null,
    fbpID: null,
    fbcID: null,
    deviceID: null,
    eventsHistory: [],
    userAgent: null,
    geoLocation: {
        coordinates: null,
        closestLocation: null,
        loading: false,
        error: null,
    },
};

/**
 * Reducer for user related actions.
 */
export default (state: UserState = defaultState, action: UserAction): UserState => {
    switch (action.type) {
        case Actions.SET_LOADING: {
            return {
                ...state,
                loading: (action as SetLoadingAction).loading,
            };
        }
        case Actions.TRACK_EVENT:
            return {
                ...state,
                eventsHistory: [
                    ...state.eventsHistory.slice(-10),
                    {
                        eventType: (action as TrackEventAction).eventType,
                        timestamp: (action as TrackEventAction).timestamp,
                    },
                ],
            };

        case Actions.SET_PROFILE:
            return {
                ...state,
                loggedIn: true,
                profile: (action as SetProfileAction).profile,
                loading: false,
            };

        case Actions.NUKE_PROFILE:
            return {
                ...state,
                loggedIn: false,
                profile: null,
                loading: false,
            };

        case Actions.SET_CITY:
            return {
                ...state,
                city: (action as SetCityAction).city || null,
            };

        case Actions.SET_LOCATION:
            return {
                ...state,
                location: (action as SetLocationAction).location || null,
            };

        case Actions.SET_SEARCH_HISTORY:
            return {
                ...state,
                searchHistory: (action as SetSearchHistoryAction).searchHistory,
            };

        case Actions.SET_RECENTLY_VIEWED_PROPERTIES:
            return {
                ...state,
                recentlyViewedProperties: (action as SetRecentlyViewedPropertiesAction)
                    .recentlyViewedProperties,
            };

        case Actions.SET_VISITOR_ID:
            return {
                ...state,
                visitorID: (action as SetVisitorIDAction).visitorID,
            };

        case Actions.SET_CLIENT_ID:
            return {
                ...state,
                clientID: (action as SetClientIDAction).clientID,
            };

        case Actions.SET_FBP_ID:
            return {
                ...state,
                fbpID: (action as SetFBPIDAction).fbpID,
            };

        case Actions.SET_FBC_ID:
            return {
                ...state,
                fbcID: (action as SetFBCIDAction).fbcID,
            };

        case Actions.SET_DEVICE_ID:
            return {
                ...state,
                deviceID: (action as SetDeviceIDAction).deviceID,
            };

        case Actions.SET_ANONYMOUS_SESSION_ID:
            return {
                ...state,
                anonymousSessionID: (action as SetAnonymousSessionIDAction).anonymousSessionID,
            };

        case Actions.SET_USER_AGENT:
            return {
                ...state,
                userAgent: (action as SetUserAgent).userAgent,
            };

        case Actions.SET_USER_IP:
            return {
                ...state,
                userIP: (action as SetUserIP).userIP,
            };

        case Actions.SET_DEBUG_MODE:
            return {
                ...state,
                debugMode: (action as SetDebugMode).debugMode,
            };

        case Actions.SET_GEOLOCATION: {
            const setGeolocationAction = action as SetGeoLocation;
            return {
                ...state,
                geoLocation: {
                    coordinates: setGeolocationAction.coordinates,
                    closestLocation: setGeolocationAction.closestLocation,
                    error: null,
                    loading: false,
                },
            };
        }

        case Actions.FETCH_GEOLOCATION:
            return {
                ...state,
                geoLocation: {
                    coordinates: null,
                    closestLocation: null,
                    error: null,
                    loading: true,
                },
            };

        case Actions.SET_GEOLOCATION_ERROR:
            return {
                ...state,
                geoLocation: {
                    coordinates: null,
                    closestLocation: null,
                    error: (action as SetGeoLocationError).error,
                    loading: false,
                },
            };
        default:
            return state;
    }
};
