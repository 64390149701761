import { SearchResponseHit } from '@sector-labs/fe-search-redux/backend';

import { type AgencyData } from 'strat/agency/types';

import transformLocalizedFields from './transformLocalizedFields';
import { locationTransformer } from './locationTransformer';
import agentTransformer from './agentTransformer';

const agencyTransformer = (
    agency: SearchResponseHit,
    language: string,
    mergedIndex: boolean,
): AgencyData => {
    // If we are not on a merged index, or there is no data, there is nothing to transform
    if (!mergedIndex || !agency) {
        return agency as AgencyData;
    }

    const transformedAgency = transformLocalizedFields(agency, language, mergedIndex) as AgencyData;

    if (transformedAgency.stats) {
        transformedAgency.stats = transformLocalizedFields(
            transformedAgency.stats,
            language,
            mergedIndex,
        );
    }

    if (transformedAgency.agents) {
        transformedAgency.agents = transformedAgency.agents?.map((agent) =>
            agentTransformer(agent, language, mergedIndex),
        );
    }

    if (transformedAgency.locations) {
        transformedAgency.locations = transformedAgency.locations?.map((location) =>
            locationTransformer(location, language, mergedIndex),
        );
    }

    return transformedAgency;
};

const autocompleteAgencyTransformer = (
    agency: SearchResponseHit,
    language: string,
    mergedIndex: boolean,
): AgencyData & SearchResponseHit => {
    // If we are not on a merged index, or there is no data, there is nothing to transform
    if (!mergedIndex || !agency) {
        return agency as AgencyData;
    }

    if (agency._highlightResult) {
        agency._highlightResult = transformLocalizedFields(
            agency._highlightResult,
            language,
            mergedIndex,
        );
    }

    return agencyTransformer(agency, language, mergedIndex);
};

export { agencyTransformer, autocompleteAgencyTransformer };
