import { t } from '@lingui/macro';
import * as React from 'react';

import { useI18n } from 'strat/i18n/language';
import { LoadingSpinner } from 'strat/loadable';
import { Flex, OTPInput } from 'strat/components';
import { useRecaptcha } from 'strat/captcha';

import { OTPTriggerFlow } from '../types';
import useResendCountDown, { secondsTillResend } from '../hooks/useResendCountDown';
import useSupportedOTPChannels, { RequestCodeType } from '../hooks/useSupportedOTPChannels';
import { isOTPChannelEnabled } from '../utils';

import styles from './styles/otpForm.cssm';

type Props = {
    readonly value: string;
    readonly onChange: (arg1: string) => void;
    readonly onSubmit: (arg1?: OTPTriggerFlow) => void;
    readonly onRequestNewCode: (arg1: {
        captcha: string | null | undefined;
        channel: string | null | undefined;
        otpTriggerFlow?: OTPTriggerFlow;
    }) => void;
    readonly onRequestPhoneCall?: (arg1?: string | null | undefined, arg2?: OTPTriggerFlow) => void;
    readonly errorMessage?: string | null | undefined;
    readonly enableResendCode: boolean;
    readonly isPhone: boolean;
    readonly withCaptcha?: boolean;
    readonly isLoading?: boolean;
    readonly otpTriggerFlow?: OTPTriggerFlow;
};

const otpLength = 4;

const OTPSubForm = ({
    value,
    onChange,
    onSubmit,
    onRequestNewCode,
    onRequestPhoneCall,
    errorMessage,
    enableResendCode,
    isPhone,
    withCaptcha = true,
    isLoading,
    otpTriggerFlow,
}: Props) => {
    const i18n = useI18n();
    const [resetTimer, setResetTimer] = React.useState(false);
    const [requestCodeType, setRequestCodeType] = React.useState<any>(null);
    const timeLeft = useResendCountDown(secondsTillResend, resetTimer);
    const {
        defaultChannel,
        secondaryChannel,
        defaultChannelActionStatement,
        secondaryChannelActionStatement,
        secondaryChannelResendLabel,
    } = useSupportedOTPChannels(isPhone);
    const isSMSEnabled = isOTPChannelEnabled(RequestCodeType.SMS);

    React.useEffect(() => {
        if (value.length === otpLength) {
            onSubmit(otpTriggerFlow);
        }
        if (enableResendCode) {
            setResetTimer(false);
        }
    }, [otpTriggerFlow, value, onSubmit, enableResendCode]);

    const requestForCodeType = React.useCallback(
        (channel: string, captcha?: string | null) => {
            setResetTimer(true);
            if (onRequestPhoneCall && channel === RequestCodeType.VOICE) {
                onRequestPhoneCall(captcha, otpTriggerFlow);
            } else {
                onRequestNewCode({ captcha, channel, otpTriggerFlow });
            }
        },
        [otpTriggerFlow, onRequestPhoneCall, onRequestNewCode],
    );

    const requestCallback = React.useCallback(
        (captcha?: string | null) => {
            requestForCodeType(requestCodeType, captcha);
            setRequestCodeType(null);
        },
        [requestCodeType, setRequestCodeType, requestForCodeType],
    );

    const { executeRecaptcha, isRecaptchaActive, isRecaptchaCheckbox, renderRecaptcha } =
        useRecaptcha(requestCallback, true);

    const displayCaptcha = React.useMemo(() => {
        if (isRecaptchaActive && withCaptcha && !isRecaptchaCheckbox) {
            return true;
        }

        return !!requestCodeType && isRecaptchaActive && withCaptcha;
    }, [requestCodeType, isRecaptchaActive, isRecaptchaCheckbox, withCaptcha]);

    const displayResendOptions = React.useMemo(() => {
        return (
            !isRecaptchaActive ||
            !withCaptcha ||
            !isRecaptchaCheckbox ||
            (isRecaptchaCheckbox && !requestCodeType)
        );
    }, [isRecaptchaActive, isRecaptchaCheckbox, requestCodeType, withCaptcha]);

    const onRequestClick = React.useCallback(
        (requestType) => {
            const codeRequestType = isSMSEnabled ? requestType : RequestCodeType.VOICE;
            setRequestCodeType(codeRequestType);
            if (!isRecaptchaActive || !withCaptcha) {
                requestForCodeType(codeRequestType);
                return;
            }

            if (!isRecaptchaCheckbox) {
                executeRecaptcha();
            }
        },
        [
            isSMSEnabled,
            withCaptcha,
            setRequestCodeType,
            isRecaptchaActive,
            isRecaptchaCheckbox,
            executeRecaptcha,
            requestForCodeType,
        ],
    );
    return (
        <>
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <OTPInput
                    length={otpLength}
                    value={value}
                    errorMessage={errorMessage}
                    onChange={onChange}
                />
            )}
            {displayResendOptions && (
                <Flex column>
                    <div className={styles.resendOption}>
                        {timeLeft > 0 && !enableResendCode && (
                            <span className={styles.resendLabel} aria-label="Time left section">
                                {t(i18n)`After ${timeLeft} seconds you can`}
                            </span>
                        )}
                        <button
                            type={'button'}
                            className={styles.resendOTPButton}
                            disabled={isLoading || !enableResendCode}
                            onClick={() => onRequestClick(defaultChannel)}
                            aria-label="Resend Code"
                        >
                            {defaultChannelActionStatement}
                        </button>
                    </div>
                    {!!onRequestPhoneCall && secondaryChannel && (
                        <div className={styles.resendOption}>
                            <span className={styles.resendLabel}>
                                {secondaryChannelResendLabel}
                            </span>
                            {secondaryChannelActionStatement && (
                                <button
                                    type="button"
                                    className={styles.resendOTPButton}
                                    disabled={isLoading || !enableResendCode}
                                    onClick={() => onRequestClick(secondaryChannel)}
                                >
                                    {secondaryChannelActionStatement}
                                </button>
                            )}
                        </div>
                    )}
                </Flex>
            )}
            {displayCaptcha && renderRecaptcha()}
        </>
    );
};

export default OTPSubForm;
