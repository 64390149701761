import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import EMPTY_ARRAY from 'strat/empty/array';
import { useI18n } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';
import { Button, Flex, Text } from 'strat/components';
import { useActiveUserPhoneNumber } from 'strat/user/session';

import LocationDropdown from 'horizontal/search/location';
import { selectCategories } from 'horizontal/categories';
import type { Location } from 'horizontal/types';
import { useRenderErrorIfAccountSuspended } from 'horizontal/user';

import CategorySelector from './categorySelector';
import styles from './styles/businessPackagesFilter.cssm';
import { useNavigateToSelectMultiplePackages } from './hooks';
import InvalidPhoneNumberMessage from './invalidPhoneNumberMessage';

const BusinessPackagesFilter = () => {
    const i18n = useI18n();
    const categories = useSelector(selectCategories);

    const [location, setLocation] = React.useState<Location | null | undefined>(
        // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData' is not assignable to parameter of type 'Location | (() => Location | null | undefined) | null | undefined'.
        brandingSettings.topLevelLocation,
    );
    const [selectedCategoryExternalID, setSelectedCategoryExternalID] = React.useState('');
    const [selectedSubcategoryExternalID, setSelectedSubcategoryExternalID] = React.useState('');
    const onCategoryChange = React.useCallback(
        (categoryID) => setSelectedCategoryExternalID(categoryID),
        [setSelectedCategoryExternalID],
    );
    const onSubcategoryChange = React.useCallback(
        (subcategoryID) => setSelectedSubcategoryExternalID(subcategoryID),
        [setSelectedSubcategoryExternalID],
    );
    const onLocationSelected = React.useCallback(
        (selectedLocation) => setLocation(selectedLocation),
        [setLocation],
    );

    const disabled = selectedCategoryExternalID === '';

    const selectedCategory = React.useCallback(
        (allCategories) => {
            return allCategories.find(
                // @ts-expect-error - TS7006 - Parameter 'category' implicitly has an 'any' type.
                (category) => category.externalID === selectedCategoryExternalID,
            );
        },
        [selectedCategoryExternalID],
    );
    const navigateToChoosePackageMulti = useNavigateToSelectMultiplePackages();
    const userPhoneNumber = useActiveUserPhoneNumber();
    const renderErrorIfAccountSuspended = useRenderErrorIfAccountSuspended(
        t(i18n)`Your account has been suspended from buying packages.`,
    );
    if (renderErrorIfAccountSuspended) {
        return renderErrorIfAccountSuspended();
    }

    if (CONFIG.runtime.OLX_ENABLE_ONLY_PHONE_VERIFIED_ORDERS && !userPhoneNumber) {
        return <InvalidPhoneNumberMessage />;
    }
    return (
        <Flex className={styles.container} column>
            <Flex column>
                <Text.Base className={styles.title} uppercase bold>
                    {t(i18n)`Select options to show packages`}
                </Text.Base>
            </Flex>
            <Flex className={styles.inputContainer} alignCenter column>
                <div className={styles.input}>
                    <CategorySelector
                        name={'parentCategory'}
                        categories={categories}
                        value={selectedCategoryExternalID || ''}
                        onChange={onCategoryChange}
                        defaultChoiceLabel={t(i18n)`Select Category *`}
                    />
                </div>
                <div className={styles.input}>
                    <CategorySelector
                        name={'subcategory'}
                        categories={selectedCategory(categories)?.children || EMPTY_ARRAY}
                        value={selectedSubcategoryExternalID || ''}
                        onChange={onSubcategoryChange}
                        defaultChoiceLabel={t(i18n)`Select Subcategory`}
                    />
                </div>
                {/* @ts-expect-error - TS2339 - Property 'showBusinessPackageLocationFilter' does not exist on type 'HorizontalSettings'. */}
                {brandingSettings.showBusinessPackageLocationFilter && (
                    <div className={styles.input}>
                        <LocationDropdown
                            location={location}
                            onLocationSelected={onLocationSelected}
                            className={styles.input}
                        />
                    </div>
                )}
            </Flex>
            <Flex justifyCenter className={styles.submitButton}>
                <Button
                    onClick={() =>
                        navigateToChoosePackageMulti({
                            locationExternalID:
                                location?.externalID ||
                                brandingSettings.topLevelLocation.externalID,
                            categoryExternalID:
                                selectedSubcategoryExternalID || selectedCategoryExternalID,
                        })
                    }
                    stretch
                    // @ts-expect-error - TS2322 - Type '{ children: string; onClick: () => void; stretch: true; primary: true; disabled: boolean; }' is not assignable to type 'IntrinsicAttributes & Props'.
                    primary
                    disabled={disabled}
                >
                    {t(i18n)`Show packages`}
                </Button>
            </Flex>
        </Flex>
    );
};

export default BusinessPackagesFilter;
