import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Button, Flex } from 'strat/components';

import { useNavigateToAdDetails } from 'horizontal/adDetails';
import { trackClickPreviewAd } from 'horizontal/gtm';
import type { FullAd } from 'horizontal/types';

import useNavigateToOathPage from './hooks/useNavigateToOathPage';
import useIsAdUndertaking from './hooks/useIsAdUndertaking';
import styles from './styles/paymentProcessingCTAs.cssm';

type Props = {
    readonly ad?: FullAd | null;
};

const PaymentProcessingCTAs = ({ ad }: Props) => {
    const navigateToAdDetails = useNavigateToAdDetails(ad);

    const navigateToOathPage = useNavigateToOathPage(ad);
    const isAdUndertaking = useIsAdUndertaking(ad);

    const onViewAdButtonClick = React.useCallback(() => {
        trackClickPreviewAd(ad);
        if (isAdUndertaking) {
            navigateToOathPage();
        } else {
            navigateToAdDetails();
        }
    }, [navigateToAdDetails, navigateToOathPage, isAdUndertaking, ad]);

    return (
        <Flex alignCenter stretchWidth justifySpaceBetween className={styles.buttonsContainer}>
            {!isAdUndertaking && (
                <Link route={RouteNames.AD_MANAGEMENT} className={styles.link}>
                    <Button stretch secondary className={styles.secondaryCTA}>
                        <Trans>Go to My Ads</Trans>
                    </Button>
                </Link>
            )}
            <Button stretch onClick={onViewAdButtonClick} type="button">
                <Trans>View Your Ad</Trans>
            </Button>
        </Flex>
    );
};

export default PaymentProcessingCTAs;
