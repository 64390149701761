import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import { isValidPhoneNumber } from 'strat/i18n/phone';

const minLengthText = (i18n: I18n, _minLength: number) =>
    t(i18n)`A minimum length of ${_minLength} characters is required. Please edit the field.`;
const maxLengthText = (i18n: I18n, _maxLength: number) =>
    t(i18n)`A maximum length of ${_maxLength} characters is allowed. Please edit the field.`;

function validationSchema(i18n: I18n) {
    // @ts-expect-error - TS7006 - Parameter 'message' implicitly has an 'any' type.
    Yup.addMethod(Yup.string, 'phone', function method(message) {
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        return this.test(
            'phone',
            message || t(i18n)`The phone number must be valid.`,
            (phone?: string | null) => {
                if (phone && phone.length > 0) {
                    return isValidPhoneNumber(phone);
                }
                return true;
            },
        );
    });

    return Yup.object().shape({
        delivery_address: Yup.string().required(t(i18n)`Please choose your address`),
        name: Yup.string()
            .min(3, minLengthText(i18n, 3))
            .max(50, maxLengthText(i18n, 50))
            .required(t(i18n)`Please enter your name`),

        phone_number: Yup.string()
            .phone(t(i18n)`Please enter a valid international phone number`)
            .required(t(i18n)`Please enter your phone number`),

        alternative_phone: Yup.string().phone(
            t(i18n)`Please enter a valid international phone number`,
        ),

        notes: Yup.string().max(4096, maxLengthText(i18n, 4096)),
    });
}

export { validationSchema };
