import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'strat/modal';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { selectRouteParams } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';
import { Text, LoadingAnimation } from 'strat/components';

import { selectUserPayments } from 'horizontal/payment/selectors';
import { Pagination, usePagination } from 'horizontal/pagination';

import LoadingPaymentEntry from './loadingPaymentEntry';
import PaymentEntry from './paymentEntry';
import SortPaymentsDropDown from './sortPaymentsDropDown';
import { PAYMENTS_PAGE_SIZE } from './constants';
import EmptyPayments from './emptyPayments';
import styles from './styles/paymentsSheet.cssm';

const PaymentsSheet = () => {
    const {
        items: payments,
        isLoading,
        activePage,
        totalPages,
    } = usePagination(selectUserPayments, PAYMENTS_PAGE_SIZE);
    const routeParams = useSelector(selectRouteParams);

    const i18n = useI18n();
    const { width } = useWindowSize();
    const columnsCount = React.useMemo(() => {
        if (!width) {
            return 0;
        }
        if (width <= CONFIG.build.DEVICE_WIDTH_MOBILE) {
            return 1;
        }
        if (width <= CONFIG.build.DEVICE_WIDTH_TABLET) {
            return 2;
        }
        return 3;
    }, [width]);

    const paymentEntries = React.useMemo(() => {
        if (isLoading) {
            return Array.from(Array(PAYMENTS_PAGE_SIZE).keys(), (index: number) => (
                <LoadingPaymentEntry key={index} />
            ));
        }

        // @ts-expect-error - TS7006 - Parameter 'payment' implicitly has an 'any' type.
        return payments.map((payment) => <PaymentEntry payment={payment} key={payment.id} />);
    }, [payments, isLoading]);

    const columns = React.useMemo(() => {
        return Array.from(Array(columnsCount).keys(), (columnIndex: number) =>
            // @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'entryIndex' implicitly has an 'any' type.
            paymentEntries.filter((_, entryIndex) => entryIndex % columnsCount === columnIndex),
        );
    }, [columnsCount, paymentEntries]);

    return (
        <Flex column stretchWidth>
            {(!!payments.length || isLoading) && (
                <div className={styles.headerContainer}>
                    <Flex justifySpaceBetween>
                        <Text.Large bold>{t(i18n)`Payment history`}</Text.Large>
                        <SortPaymentsDropDown />
                    </Flex>
                </div>
            )}
            {!payments.length && !isLoading && <EmptyPayments />}
            <Flex fillContainer className={styles.columnsWrapper}>
                {columns.map((entries, columnIndex) => (
                    <Flex column stretchWidth key={columnIndex}>
                        {entries}
                    </Flex>
                ))}
            </Flex>
            {!columns.length && <LoadingAnimation className={styles.loadingAnimation} />}
            <Pagination
                activePage={activePage}
                totalPages={totalPages}
                routeName={RouteNames.USER_ORDERS}
                routeParams={routeParams}
            />
        </Flex>
    );
};

export default PaymentsSheet;
