import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

const factory = new FetcherFactory(
    'categories',
    (params: any, state: any) => new StratAPI(state.i18n.language).categories(params),
    {
        catchServerErrors: true,
    },
);

const fetchCategories = factory.creator();
const categoriesReducer = factory.reducer();

export { fetchCategories };

export default categoriesReducer;
