import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { Dropdown } from 'strat/modal';
import { useI18n } from 'strat/i18n/language';

import type { AddressInfo } from 'horizontal/userOrders/types';
import { DropdownTrigger } from 'horizontal/components';

import styles from './styles/chooseAddress.cssm';

type Props = {
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly addressBook: AddressInfo[];
    readonly defaultAddress: AddressInfo | null;
    readonly fieldName?: string;
};

const ChooseAddressDropdown = ({
    values,
    errors,
    touched,
    setFieldValue,
    addressBook,
    defaultAddress,
    fieldName = 'delivery_address',
}: Props) => {
    const i18n = useI18n();
    const [isDropdownOpen, setIsDropDownOpen] = React.useState(false);
    const [selectedAddress, setSelectedAddress] = React.useState(defaultAddress);

    const toggleDropdown = () => setIsDropDownOpen(!isDropdownOpen);

    const renderTrigger = () => (
        <DropdownTrigger
            title={t(i18n)`Choose delivery address`}
            name={fieldName}
            errorMessage={touched[fieldName] && errors[fieldName]}
            accepted={touched[fieldName] && !errors[fieldName]}
            setIsOpen={toggleDropdown}
            value={selectedAddress?.name}
            isOpen={isDropdownOpen}
            selectedValueLabel={selectedAddress?.name ?? ''}
            loading={false}
        />
    );

    return (
        <div className={styles.dropDownContainer}>
            <Dropdown
                triggerIsDismissble
                open={isDropdownOpen}
                renderTrigger={renderTrigger}
                onDismissed={toggleDropdown}
            >
                <div className={styles.content} aria-label="Addresses">
                    {addressBook.map((address) => (
                        <div
                            onClick={() => {
                                setIsDropDownOpen(false);
                                setFieldValue(fieldName, address.id);
                                setSelectedAddress(address);
                            }}
                            className={classNames(styles.entry, {
                                [styles.selected]: values[fieldName] === address.id,
                            })}
                            key={address.id}
                        >
                            {address.name}
                        </div>
                    ))}
                </div>
            </Dropdown>
        </div>
    );
};

export default ChooseAddressDropdown;
