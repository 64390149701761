import { t } from '@lingui/macro';
import * as React from 'react';
import { Link } from 'strat/navigation';
import { useI18n } from 'strat/i18n/language';
import RouteNames from 'strat/routes/routeNames';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { Text, ListEntry } from 'strat/components';

import iconSettings from 'horizontal/assets/icons/iconFilters_noinline.svg';
import { UserSettingsSection } from 'horizontal/userSettings';

const renderLeft = () => <img alt={'Settings'} src={iconSettings} />;

const UserSettingsLink = ({ onClick }: { onClick: (arg1: React.MouseEvent<any>) => void }) => {
    const i18n = useI18n();
    const onTrackedClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            trigger(Triggers.CLICK_SETTINGS, { view_section: ViewSections.USER_DETAIL });
            onClick(event);
        },
        [onClick],
    );

    return (
        <Link
            route={RouteNames.USER_SETTINGS}
            params={{ section: UserSettingsSection.PRIVACY }}
            title={t(i18n)`Settings`}
            onClick={onTrackedClick}
        >
            <ListEntry borderless hoverable renderLeft={renderLeft} renderRight={null}>
                <Text.Large>{t(i18n)`Settings`}</Text.Large>
            </ListEntry>
        </Link>
    );
};

export default UserSettingsLink;
