import type { PropertyData } from 'strat/property/types';
import { asNumberOrString } from 'strat/util';
import type { PropertyOfTheWeekData } from 'strat/propertyOfTheWeek/types';

import getProjectOrPropertyID from './getProjectOrPropertyID';

/**
 * Gets variables related to POTW when the specified property
 * is a property of the week property.
 */
const getPropertyOfTheWeekGTMVars = (
    property: PropertyData,
    propertyOfTheWeek?: PropertyOfTheWeekData | null,
): any => {
    if (!propertyOfTheWeek) {
        return {};
    }

    if (getProjectOrPropertyID(property).toString() !== propertyOfTheWeek.externalID?.toString()) {
        return {};
    }

    return {
        product_id: propertyOfTheWeek.id,
        deal_user_id: asNumberOrString(propertyOfTheWeek.creatorID),
        product_title: 'potw',
    };
};

export default getPropertyOfTheWeekGTMVars;
