import * as React from 'react';
import { connect } from 'react-redux';
import settings from '@app/branding/settings';

import { Scrollable } from 'strat/modal';
import { Triggers } from 'strat/gtm';

import UserPopUp from './userPopUp';
import RegisterPopUp from './registerPopUp';
import ForgotPassPopUp from './forgotPassPopUp';
import EmailSentPopUp from './emailSentPopUp';
import LoginPopUpContents from './loginPopUpContentsAsync';
import styles from './styles/popUp.cssm';

/**
 * Properties for {@see LoginPopUp}.
 */
export type Props = {
    /**
     * Optional classname to apply to the dialog.
     */
    className?: string;
    visible: boolean;
    onVisibilityChanged: (loginFormVisible: boolean) => void;
    onDone?: () => void;
    email?: string;
    userPath?: Values<typeof Triggers>;
    stackGroup?: string;
};

const LoginPopUp = (props: Props) => {
    const [registerFormVisible, setRegistrationFormVisible] = React.useState(false);
    const [forgotPassFormVisible, setForgotPassFormVisible] = React.useState(false);
    const [emailSentFormVisible, setEmailSentFormVisible] = React.useState(false);

    const openLoginPopUp = () => {
        setRegistrationFormVisible(false);
        setForgotPassFormVisible(false);
        setEmailSentFormVisible(false);
        props.onVisibilityChanged(true);
    };

    const openRegisterPopUp = () => {
        setRegistrationFormVisible(true);
        props.onVisibilityChanged(false);
    };

    const openForgotPassword = () => {
        setForgotPassFormVisible(true);
        props.onVisibilityChanged(false);
    };

    const openPhoneLogin = () => {
        props.onVisibilityChanged(false);
    };

    const openEmailSent = () => {
        setForgotPassFormVisible(false);
        setEmailSentFormVisible(true);
        props.onVisibilityChanged(false);
    };

    const closeEmailSent = () => {
        setEmailSentFormVisible(false);
        props.onVisibilityChanged(false);
    };

    const popUpProps = {
        stackGroup: props.stackGroup || 'login',
        withoutCloseButton: true,
        withHeaderCloseButton: true,
        containerClassName: styles.popUpContainer,
    };

    return (
        <>
            <UserPopUp
                visible={props.visible}
                onVisibilityChanged={props.onVisibilityChanged}
                {...popUpProps}
            >
                <Scrollable className={styles.scrollable}>
                    <LoginPopUpContents
                        close={() => props.onVisibilityChanged(false)}
                        onRegisterClick={openRegisterPopUp}
                        onForgotPasswordClick={openForgotPassword}
                        onPhoneLoginClick={openPhoneLogin}
                        onLoginSuccess={props.onDone}
                        email={props.email}
                        userPath={props.userPath}
                    />
                </Scrollable>
            </UserPopUp>
            {!settings.disableSignUp && (
                <RegisterPopUp
                    visible={registerFormVisible}
                    onVisibilityChanged={setRegistrationFormVisible}
                    onLoginClick={openLoginPopUp}
                    onBackClick={openLoginPopUp}
                />
            )}
            <ForgotPassPopUp
                visible={forgotPassFormVisible}
                onVisibilityChanged={setForgotPassFormVisible}
                onBackClick={openLoginPopUp}
                initialValues={{ email: props.email }}
                onSuccess={openEmailSent}
                stackGroup={popUpProps.stackGroup}
            />
            <EmailSentPopUp
                visible={emailSentFormVisible}
                onVisibilityChanged={setEmailSentFormVisible}
                onBackClick={openLoginPopUp}
                onCloseClick={closeEmailSent}
                stackGroup={popUpProps.stackGroup}
            />
        </>
    );
};

export default connect(
    (state) => ({
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        loggedIn: state.user.loggedIn,
    }),
    null,
)(LoginPopUp);
