import React from 'react';

import type { ThumbnailImageProps } from './thumbnailImage';
import ThumbnailImage from './thumbnailImage';

export type ThumbnailImageWithFallbackProps = ThumbnailImageProps & {
    readonly fallbackUrl?: string;
};

const ThumbnailImageWithFallback = (props: ThumbnailImageWithFallbackProps) => {
    const [fallback, setFallback] = React.useState(false);

    const onError = () => {
        setFallback(true);
    };

    if (fallback) {
        return (
            <img
                onLoad={props.onLoad}
                alt={props.alt}
                title={props.title}
                aria-label={props.label}
                className={props.className}
                src={props.fallbackUrl}
                onError={props.onError}
            />
        );
    }

    return <ThumbnailImage {...props} onError={onError} />;
};

export default ThumbnailImageWithFallback;
