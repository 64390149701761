import * as React from 'react';
import settings from '@app/branding/settings';
import { useSelector } from 'react-redux';

import { ProjectSearchType } from 'strat/search/types';
import BreadcrumbsSearch from 'strat/navigation/breadcrumbsSearch';
import { Flex } from 'strat/components';
import type { ProjectData } from 'strat/project/types';
import { GTMSearchTrackingProps, withGTMSearchTracking } from 'strat/gtm';
import { selectBottomAreaGuide } from 'strat/areaGuide/selectors';

import ProjectExperienceBackground from './projectExperienceBackground';
import ProjectTopDetails from './projectTopDetails';
import ProjectTopSectionHeader from './projectTopSectionHeader';
import ProjectTopSlideshow from './projectTopSlideshow';
import ProjectTopDescription from './projectTopDescription';
import styles from './styles/projectTopSection.cssm';
import useProjectTopLinkTiles from './useProjectTopLinkTiles';
import { ProjectPageSections } from './types';

type Props = GTMSearchTrackingProps & {
    project: ProjectData;
};
const ProjectTopSection = ({ project, trackProjectPageView }: Props) => {
    React.useEffect(() => {
        trackProjectPageView(ProjectSearchType.PROJECT_PAGE);
    }, [trackProjectPageView]);

    const linkTiles = useProjectTopLinkTiles(project);
    const areaGuide = useSelector(selectBottomAreaGuide);
    const isAreaGuideDescription = !!areaGuide?.summary;
    const description = isAreaGuideDescription ? areaGuide.summary : project.description;

    const renderLinkTiles = () => <Flex className={styles.tiles}>{linkTiles}</Flex>;

    return (
        <div className={styles.container} aria-label="Project Section">
            <ProjectExperienceBackground />
            <Flex column className={styles.contentContainer}>
                <BreadcrumbsSearch />
                <Flex className={styles.sections}>
                    <Flex column className={styles.leftSection}>
                        <ProjectTopSectionHeader project={project} />
                        <ProjectTopDetails project={project} />
                        {!!project.description &&
                            settings.projectPageSectionVisibility?.[
                                ProjectPageSections.DESCRIPTION
                            ] && (
                                <ProjectTopDescription
                                    isAreaGuideDescription={isAreaGuideDescription}
                                    description={description}
                                />
                            )}
                        {linkTiles.length <= 2 && renderLinkTiles()}
                    </Flex>
                    <ProjectTopSlideshow photos={project.photos} />
                </Flex>
                {linkTiles.length > 2 && renderLinkTiles()}
            </Flex>
        </div>
    );
};

export default withGTMSearchTracking(ProjectTopSection);
