import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';

import type { Category } from 'horizontal/types';

import styles from './styles/moreCategoriesGroupEntry.cssm';

type Props = {
    readonly category: Category;
    readonly onCategoryChange: (arg1: Category) => void;
    readonly activeCategory: Category | null | undefined;
};

const MoreCategoriesGroupEntry = ({
    category,
    onCategoryChange,
    activeCategory,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const isActive = activeCategory?.id === category.id;
    const separator = t(i18n)`,`;
    const slicedChildren = category.children.slice(0, 3);
    const categoryNameSubtitle = slicedChildren.map((child) => child.name).join(`${separator} `);

    return (
        <div
            className={classNames(styles.categoryContainer, {
                [styles.activeCategory]: isActive,
            })}
            onMouseEnter={() => {
                onCategoryChange(category);
            }}
        >
            <Flex justifySpaceBetween alignCenter>
                <div className={styles.categoryNameContainer}>
                    <div className={styles.categoryName}>{category.name}</div>
                    <div className={styles.subHeaderCategoryName}>{categoryNameSubtitle}</div>
                </div>
                {isActive && <IconArrowRight className={styles.arrow} />}
            </Flex>
        </div>
    );
};

export default MoreCategoriesGroupEntry;
