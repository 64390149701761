const ASCII_CHARACTERS = '0123456789abcdefghijklmnopqrstuwvxyzABCDEFGHIJKLMNOPQRSTUWVXYZ';

const asciiCharacterBaseToDecimal = (character: string, exponent: number): number =>
    ASCII_CHARACTERS.indexOf(character) * Math.pow(ASCII_CHARACTERS.length, exponent);

export const decryptInt = (hash: string): number | null | undefined => {
    if (!hash) {
        return null;
    }

    return Array.from(hash)
        .reverse()
        .reduce((sum, character, index) => sum + asciiCharacterBaseToDecimal(character, index), 0);
};

const decryptExternalID = (hash: string): string => {
    const externalID = decryptInt(hash);
    if (!externalID) {
        return '';
    }

    return externalID.toString(10);
};

const getAdExternalID = (externalID: string | null | undefined, hash: string) => {
    return externalID || decryptExternalID(hash);
};

export default getAdExternalID;
