import React from 'react';

import styles from './styles/pageWidthWrapper.cssm';

/**
 * Properties for {@see PageWidthWrapper}.
 */
type Props = {
    className?: string;
    containerClassName?: string;
    enabled?: boolean;
    children?: Node | React.ReactNode;
    label?: string;
};

/**
 * Wraps the specified children and puts them in a container
 * that is set to have the fixed page width.
 */
const PageWidthWrapper = ({
    className = styles.container,
    containerClassName,
    enabled = true,
    children,
    label,
}: Props) => {
    let computedClassName = containerClassName || className;

    if (!enabled) {
        computedClassName = '';
    }

    return (
        <div className={computedClassName} aria-label={label}>
            {children}
        </div>
    );
};

export default PageWidthWrapper;
