import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';

import { GlobalState } from 'horizontal/state';

const selectAdManagement = (state: GlobalState) => state.adManagement || EMPTY_OBJECT;

const selectMyAds = createSelector(
    selectAdManagement,
    (adManagement) => adManagement.data?.results || EMPTY_ARRAY,
);

const selectMyAdsCount = createSelector(
    selectAdManagement,
    (adManagement) => adManagement.data?.count || 0,
);

const selectMyAdsCountByState = createSelector(
    selectAdManagement,
    (adManagement) => adManagement.data?.countByState || EMPTY_OBJECT,
);
const selectIsLoadingMyAds = createSelector(
    selectAdManagement,
    (adManagement) => adManagement.loading,
);

const selectIsLoadedMyAds = createSelector(
    selectAdManagement,
    (adManagement) => adManagement.loaded,
);

const selectMyAdsErrors = createSelector(selectAdManagement, (adManagement) => adManagement.error);

export {
    selectMyAds,
    selectMyAdsCount,
    selectMyAdsCountByState,
    selectIsLoadingMyAds,
    selectIsLoadedMyAds,
    selectMyAdsErrors,
};
