import * as React from 'react';

import { useRouter } from 'react-true-router';

const withCloseOnUrlChange = <T extends any>(
    component: React.ComponentType<T>,
): React.ComponentType<T> => {
    const WithCloseOnUrlChange = (props: T) => {
        const router = useRouter();
        let callbackIndex = 0;
        let backEventTriggered: boolean;

        React.useEffect(() => {
            return () => {
                router.unsubscribe(callbackIndex);
            };
        }, [callbackIndex, router]);

        const handleVisibilityChange = (
            visible: boolean,
            setNotVisible: () => void,
            hash = 'SlideIn',
        ) => {
            const locationHash = `#?${hash}`;
            if (visible) {
                const newHash = window.location.hash === locationHash ? `${hash}s` : hash;
                router.pushURL(
                    // @ts-expect-error - TS2345 - Argument of type '{ pathname: string; hash: string; }' is not assignable to parameter of type 'string'.
                    { pathname: window.location.pathname, hash: newHash },
                    false,
                );

                callbackIndex = router.listen((_, action) => {
                    if (action === 'POP') {
                        backEventTriggered = true;
                        if (window.location.hash !== `#?${newHash}`) {
                            setNotVisible();
                        }
                    }
                });
            } else {
                router.unsubscribe(callbackIndex);
                if (!backEventTriggered) {
                    router.pop(false);
                }
                backEventTriggered = false;
            }

            // @ts-expect-error - TS2339 - Property 'handleVisibilityChange' does not exist on type 'Readonly<T> & Readonly<{ children?: ReactNode; }>'.
            if (props.handleVisibilityChange) {
                // @ts-expect-error - TS2339 - Property 'handleVisibilityChange' does not exist on type 'Readonly<T> & Readonly<{ children?: ReactNode; }>'.
                props.handleVisibilityChange(visible, setNotVisible);
            }
        };

        // @ts-ignore
        return React.createElement(component, {
            handleVisibilityChange: handleVisibilityChange,
            // @ts-expect-error - TS2698 - Spread types may only be created from object types.
            ...props,
        });
    };

    return WithCloseOnUrlChange;
};

export default withCloseOnUrlChange;
