import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import Profile from 'horizontal/profile';
import Footer from 'horizontal/footer';
import { GTMProfileDataLayer, PageType } from 'horizontal/gtm/components';
import { HeaderWithoutFilters } from 'horizontal/header';
import { Location } from 'horizontal/types';

import styles from './styles/sellerPageDesktop.cssm';
import SellerPageHead from './sellerPageHead';

const SellerPageDesktop = {
    head: () => (
        <>
            <SellerPageHead />
            {/* @ts-expect-error - TS2322 - Type '{ pageType: string; }' is not assignable to type 'IntrinsicAttributes & { ref?: Ref<unknown> | undefined; key?: Key | null | undefined; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: any; } & { ...; }'. */}
            <GTMProfileDataLayer pageType={PageType.SELLER} />
        </>
    ),
    body: (props: { location: Location | null; loading: boolean }) => (
        <Flex column stretchHeight>
            <HeaderWithoutFilters />
            <PageWidthWrapper className={styles.wrapper}>
                <Profile {...props} />
            </PageWidthWrapper>
            <Footer />
        </Flex>
    ),
} as const;

export default SellerPageDesktop;
