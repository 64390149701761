import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

import { SearchLink } from '../routing';

import type { FrequentSearchQuery } from './types';
import styles from './styles/frequentSearchQueries.cssm';

type Props = {
    queries: ReadonlyArray<FrequentSearchQuery>;
};

const FrequentSearchQueries = ({ queries }: Props) => {
    const items = React.useMemo(
        () =>
            queries.map((params, index) => (
                <React.Fragment key={params.freeTextQuery}>
                    <li className={styles.item}>
                        <SearchLink className={styles.link} encodePathname params={params}>
                            {params.freeTextQuery}
                        </SearchLink>
                    </li>
                    {index !== queries.length - 1 && (
                        <Text.Base className={styles.separator}>{'-'}</Text.Base>
                    )}
                </React.Fragment>
            )),
        [queries],
    );

    return (
        <div className={styles.container}>
            <Text.Base bold className={styles.label}>
                <Trans>Popular Searches:</Trans>
            </Text.Base>
            <ul className={styles.list}>{items}</ul>
        </div>
    );
};

export default FrequentSearchQueries;
