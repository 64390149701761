import { t } from '@lingui/macro';
import * as React from 'react';

import iconInternalErrorPNG from '@app/assets/icons/iconInternalError.png';
import iconInternalErrorWEBP from '@app/assets/icons/iconInternalError.webp';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { Flex, Text, Button } from 'strat/components';

import styles from './styles/internalError.cssm';

type Props = {
    readonly title?: string;
    readonly message?: string | null;
    readonly isLikelyToBeRenderedInPopup?: boolean;
    readonly hideGoToHomeButton?: boolean;
};

const InternalErrorGoToHomeButton = ({
    isLikelyToBeRenderedInPopup,
}: Pick<Props, 'isLikelyToBeRenderedInPopup'>) => {
    const i18n = useI18n();
    const [isInPopUp, setIsInPopup] = React.useState(!!isLikelyToBeRenderedInPopup);

    React.useEffect(() => {
        if (window.opener) {
            setIsInPopup(true);
        }
    }, []);

    if (isInPopUp) {
        return <Button secondary onClick={window.close}>{t(i18n)`Go back`}</Button>;
    }

    return (
        <Link hard route={RouteNames.HOME} title={t(i18n)`Go to home`}>
            <Button secondary>{t(i18n)`Go to home`}</Button>
        </Link>
    );
};

const InternalError = ({
    title,
    message,
    hideGoToHomeButton,
    isLikelyToBeRenderedInPopup,
}: Props) => {
    const i18n = useI18n();

    return (
        <Flex column className={styles.container}>
            <picture>
                <source srcSet={iconInternalErrorWEBP} type="image/webp" />
                <source srcSet={iconInternalErrorPNG} type="image/png" />
                <img
                    src={iconInternalErrorPNG}
                    alt={t(i18n)`Internal error`}
                    className={styles.icon}
                />
            </picture>
            <Text.Base bold className={styles.title}>
                {title || t(i18n)`Something went wrong`}
            </Text.Base>
            <Text.Base className={styles.subtitle}>
                {message || t(i18n)`We are having some difficulties, please try again.`}
            </Text.Base>
            {!hideGoToHomeButton && (
                <div className={styles.controls}>
                    <InternalErrorGoToHomeButton
                        isLikelyToBeRenderedInPopup={isLikelyToBeRenderedInPopup}
                    />
                </div>
            )}
        </Flex>
    );
};

export default InternalError;
