import { languages } from '@app/branding/languages';

/**
 * Returns the locales of a given language.
 * For example for the 'en' language the locales can be 'en-UK' or 'en-US'.
 * @param lang the language code
 */
export const localesFromLanguage = (lang: string): string =>
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    languages.find((configLang) => configLang.lang === lang).locales;

export const numberFormatterLocaleFromLanguage = (lang: string): string => {
    const language = languages.find((configLang) => configLang.lang === lang);
    if (!language) {
        return '';
    }

    return language.numberFormattingLocale || language.locales;
};
