import { t } from '@lingui/macro';
import React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import LinkedInIcon from 'horizontal/assets/icons/iconLinkedIn_noinline.svg';
import FacebookIcon from '@app/assets/icons/iconFacebook_noinline.svg';
import TwitterIcon from '@app/assets/icons/iconTwitter_noinline.svg';
import YoutubeIcon from '@app/assets/icons/iconYoutube_noinline.svg';
import InstagramIcon from '@app/assets/icons/iconInstagram_noinline.svg';
import TikTokIcon from '@app/assets/icons/iconTikTok_noinline.svg';
import styles from '@app/footer/styles/socialMedia.cssm';

const SocialMedia = () => {
    const i18n = useI18n();

    return (
        <Flex alignCenter className={styles.container}>
            {settings.showTwitterSocialMediaIconInFooter && (
                <a href={t(i18n)`twitter_url`} target="_blank" rel="noopener noreferrer">
                    <img src={TwitterIcon} alt={'Twitter Logo'} />
                </a>
            )}
            {settings.showLinkedInSocialMediaIconInFooter && (
                <a href={t(i18n)`linkedin_url`} target="_blank" rel="noopener noreferrer">
                    <img src={LinkedInIcon} alt={'LinkedIn Logo'} />
                </a>
            )}
            <a href={t(i18n)`facebook_url`} target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt={'Facebook Logo'} />
            </a>
            <a href={t(i18n)`youtube_url`} target="_blank" rel="noopener noreferrer">
                <img src={YoutubeIcon} alt={'Youtube Logo'} />
            </a>
            <a href={t(i18n)`instagram_url`} target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt={'Instagram Logo'} />
            </a>
            {settings.showTikTokSocialMediaIconInFooter && (
                <a href={t(i18n)`tiktok_url`} target="_blank" rel="noopener noreferrer">
                    <img src={TikTokIcon} alt={'TikTok Logo'} />
                </a>
            )}
        </Flex>
    );
};

export default SocialMedia;
