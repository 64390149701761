import type { Ad, FullAd } from 'horizontal/types';
import { AdVirtualState } from 'horizontal/types';
import { adHasAutoBoost } from 'horizontal/adManagement/utils';

import { CTAType } from './types';

export const getMainCTAType = (
    ad: Ad,
    isBTTFeaturedEnabledForCategory = false,
    isStratCreditUser = false,
): Values<typeof CTAType> | null | undefined => {
    switch (ad.virtualState) {
        case AdVirtualState.ELITE:
        case AdVirtualState.OUTDATED:
            return CTAType.MARK_AS_SOLD;

        case AdVirtualState.FEATURED:
            return (!adHasAutoBoost(ad) && isStratCreditUser) || isBTTFeaturedEnabledForCategory
                ? CTAType.UPGRADE
                : CTAType.MARK_AS_SOLD;

        case AdVirtualState.ACTIVE:
        case AdVirtualState.MODERATION_PENDING:
            return CTAType.UPGRADE;

        case AdVirtualState.MODERATION_SOFT_REJECT:
            return CTAType.EDIT;

        case AdVirtualState.MODERATION_HARD_REJECT:
            return CTAType.LEARN_MORE;

        case AdVirtualState.EXPIRED:
        case AdVirtualState.DISABLED:
            return CTAType.REPUBLISH;

        case AdVirtualState.LIMITED:
            return CTAType.POST;

        default:
            return null;
    }
};

export const getMenuCTATypes = (
    ad: Ad,
    isBTTFeaturedEnabledForCategory?: boolean,
): Array<Values<typeof CTAType> | null | undefined> => {
    switch (ad.virtualState) {
        case AdVirtualState.FEATURED:
            return isBTTFeaturedEnabledForCategory
                ? [CTAType.DEACTIVATE, CTAType.MARK_AS_SOLD]
                : [CTAType.DEACTIVATE];

        case AdVirtualState.ELITE:
            return [CTAType.DEACTIVATE];

        case AdVirtualState.ACTIVE:
            return [CTAType.DEACTIVATE, CTAType.MARK_AS_SOLD];

        case AdVirtualState.EXPIRED:
            return [CTAType.EDIT, CTAType.REMOVE];

        case AdVirtualState.LIMITED:
        case AdVirtualState.MODERATION_PENDING:
        case AdVirtualState.MODERATION_SOFT_REJECT:
            return [CTAType.REMOVE];

        default:
            return [];
    }
};

export const getSecondaryCTAType = (ad: Ad): Values<typeof CTAType> | null => {
    switch (ad.virtualState) {
        case AdVirtualState.ELITE:
        case AdVirtualState.FEATURED:
        case AdVirtualState.ACTIVE:
        case AdVirtualState.LIMITED:
        case AdVirtualState.MODERATION_PENDING:
            return CTAType.EDIT;

        case AdVirtualState.OUTDATED:
        case AdVirtualState.DISABLED:
        case AdVirtualState.MODERATION_HARD_REJECT:
        case AdVirtualState.SOLD:
            return CTAType.REMOVE;

        case AdVirtualState.EXPIRED:
            return CTAType.MARK_AS_SOLD;

        case AdVirtualState.MODERATION_SOFT_REJECT:
            return CTAType.LEARN_MORE;

        default:
            return null;
    }
};

export const isCTAAllowed = (
    ctaType: Values<typeof CTAType>,
    ad: FullAd,
    isStratCreditUser = false,
) => {
    const allowedMenuActions = getMenuCTATypes(ad);
    const allowedMainButtonAction = getMainCTAType(ad, false, isStratCreditUser);
    const allowedSecondaryButtonAction = getSecondaryCTAType(ad);
    return [...allowedMenuActions, allowedMainButtonAction, allowedSecondaryButtonAction].includes(
        ctaType,
    );
};
