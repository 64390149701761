import { FilterValues } from 'strat/search';

const commonExcludedFiltersFromFaceting = () => {
    return [
        // we exclude elite ads from results. We should not exclude them from facets
        {
            attribute: FilterValues.product.attribute,
            exact: true,
        },
        // we exclude remarketing ads from results. We should not exclude them from facets
        {
            attribute: FilterValues.externalID.attribute,
            exact: true,
        },
    ];
};

export default commonExcludedFiltersFromFaceting;
