import { StratAPI } from 'strat/api';
import { FetcherFactoryWithContext } from 'strat/fetcher';
import type { CategoryNodeData, LocationNodeData } from 'strat/property';
import PageRuleType from 'strat/pages/pageRule';

type Params = {
    purpose: string;
    locationHierarchy: Array<LocationNodeData>;
    categoryHierarchy: Array<CategoryNodeData>;
    beds: string | null | undefined;
    isCustomPage: boolean;
    exactCategoryMatch: boolean | null | undefined;
};

/**
 * Rule for a page that tells us what status code to return
 * and what page to render.
 */
type PageRule = {
    statusCode: number;
    page: string;
    showRecommendations: boolean;
};

/**
 * Fetches the rule about what to display for the
 * specified parameters.
 *
 * This comes from the back-end and allows us
 * to control for what pages to show recommended listings.
 */
const getPageRule = (
    language: string,
    params: Params,
): Promise<{
    data: PageRule | null | undefined;
    status: number;
}> => {
    // we always want recommendations for custom pages,
    // (also known as long tail pages) so we hard code the rule
    if (params.isCustomPage) {
        return Promise.resolve({
            status: 200,
            data: {
                statusCode: 200,
                page: PageRuleType.SEARCH_PAGE,
                showRecommendations: true,
            },
        });
    }

    return new StratAPI(language).pageRule(
        params.purpose,
        params.categoryHierarchy.map((c) => c.externalID),

        params.locationHierarchy.map((l) => l.externalID),
        // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
        params.beds,
        params.exactCategoryMatch,
    );
};

const factory = new FetcherFactoryWithContext<Params>(
    ['search', 'pageRule'],
    ({ params, state }) => {
        const language = state.i18n.language;

        return getPageRule(language, params);
    },
    {
        catchServerErrors: true,
        successCodes: [200, 404],
    },
);

/**
 * Reducer for recommendations.
 */
const pageRuleReducer = factory.reducer();

/**
 * Fetches recommendations based on the current search filters.
 */
const fetchPageRule = factory.creator();

export type { PageRule };
export { fetchPageRule, getPageRule };
export default pageRuleReducer;
