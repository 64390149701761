import * as React from 'react';

import type { LiteHierarchicalLocation } from 'horizontal/types';
import { SearchLink } from 'horizontal/search/routing';

import styles from './styles/mostPopularLocations.cssm';

type Props = {
    readonly locations: Array<LiteHierarchicalLocation>;
};

const MostPopularLocations = ({ locations }: Props) => {
    return (
        <div className={styles.container}>
            {locations.map((location) => (
                <SearchLink
                    // @ts-expect-error - TS2322 - Type 'LiteHierarchicalLocation' is not assignable to type 'Location'.
                    params={{ location }}
                    className={styles.category}
                    key={location.externalID}
                >
                    {location.name}
                </SearchLink>
            ))}
        </div>
    );
};

export default MostPopularLocations;
