import React from 'react';

import type { FlatCategoryFieldHierarchy } from 'horizontal/types';

import FilterContainer from './filterContainer';
import SingleChoice from './singleChoice';
import useDisplayedPriceTypeFilter from './useDisplayedPriceTypeFilter';

type Props = {
    field: FlatCategoryFieldHierarchy;
    className?: string;
    uppercaseTitle?: boolean;
};
const PriceTypeFilter = ({ field, className, uppercaseTitle }: Props) => {
    const modifiedField = useDisplayedPriceTypeFilter(field);
    if (!modifiedField) {
        return null;
    }
    const title = field.name || field.attribute;

    return (
        <FilterContainer title={title} className={className} uppercaseTitle={uppercaseTitle}>
            <SingleChoice field={modifiedField} />
        </FilterContainer>
    );
};

export default PriceTypeFilter;
