import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Button, CircleRadioButtonsGroup, Flex, Text } from 'strat/components';
import { GTMLeadTrackingProps, withGTMLeadTracking } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';

import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';
import { useChatSDK } from 'horizontal/chat/hooks';
import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';

import PriceInput from '../priceInput';

import styles from './styles/askForMore.cssm';

type OwnProps = {
    readonly adPrice: number;
    readonly lastOfferPrice: number;
    readonly onVisibilityChanged: (arg1: boolean) => void;
};

type Props = GTMLeadTrackingProps & OwnProps;

const AskForMore = ({ adPrice, lastOfferPrice, onVisibilityChanged, trackChatLead }: Props) => {
    const i18n = useI18n();
    const [option, setOption] = React.useState<ChatNegotiationState | null | undefined>(null);
    const [price, setPrice] = React.useState<number | null | undefined>(adPrice);
    const sdk = useChatSDK();
    const getNegotiationActions = sdk.useSendNegotiationMessagesMap();

    const choices = React.useMemo(
        () => [
            {
                id: 1,
                label: t(i18n)`My counter offer is:`,
                value: ChatNegotiationState.SELLER_MADE_OFFER,
                renderBody: ({ isSelected }: { isSelected: boolean }) => (
                    <PriceInput
                        price={price}
                        setPrice={(priceOffer?: number | null) => setPrice(priceOffer)}
                        className={classNames(styles.priceInput, {
                            [styles.selected]: isSelected,
                        })}
                    />
                ),
            },
            {
                id: 2,
                label: t(i18n)`Give me a better offer.`,
                value: ChatNegotiationState.OFFER_REJECTED,
            },
        ],
        [i18n, price, setPrice],
    );

    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);

    const sendButtonDisabled =
        !price ||
        !option ||
        (option === ChatNegotiationState.SELLER_MADE_OFFER && price < lastOfferPrice);
    return (
        <Flex column className={styles.container}>
            <Text.Base className={styles.title}>
                <Trans>Select reason for not accepting the offer.</Trans>
            </Text.Base>
            <CircleRadioButtonsGroup
                choices={choices}
                selectedValue={option}
                onChange={setOption as any}
            />
            <Flex>
                <div className={styles.buttonContainer}>
                    <Button stretch onClick={() => onVisibilityChanged(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        stretch
                        disabled={sendButtonDisabled}
                        onClick={async () => {
                            const actions = await getNegotiationActions();

                            onVisibilityChanged(false);
                            if (!option) {
                                return;
                            }
                            const sendMessage = actions[option];
                            if (!sendMessage) {
                                return;
                            }

                            const messageSentResponse = price
                                ? await sendMessage(price)
                                : await sendMessage();

                            if (messageSentResponse) {
                                trackSentChatMessageEvents(
                                    messageSentResponse,
                                    ChatMessageType.NEGOTIATION,
                                );
                            }
                        }}
                    >
                        <Trans>Send</Trans>
                    </Button>
                </div>
            </Flex>
        </Flex>
    );
};

export default withGTMLeadTracking(React.memo<Props>(AskForMore));
