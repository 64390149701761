import { t } from '@lingui/macro';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { I18n } from '@lingui/core';

import {
    type CategoryNodeData,
    PropertyType,
    PropertyCompletionStatus,
} from 'strat/property/types';

import FilterValues from '../search/filterValues';
import Purpose, { PurposeTextDisplay } from '../purpose';

const getLocationTextFromFilters = (filters: FilterCollection, i18n: I18n) => {
    const cityFilter = filters.getFilterValue(FilterValues.city.attribute);

    const locations = filters.getFilterValue(FilterValues.location.attribute, [
        {
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            name: cityFilter ? cityFilter.name : FilterValues.location.placeholderName(i18n),
        },
    ]);

    const locationNames = (Array.isArray(locations) ? locations : [locations]).map(
        (location) => location.name,
    );
    const joinSeparator = `${t(i18n)`,`} `;
    let locationText = locationNames.join(joinSeparator);
    if (locationNames.length > 1) {
        locationText = t(i18n)`${locationNames
            .slice(0, -1)
            .join(joinSeparator)} and ${locationNames.pop()}`;
    }

    return locationText;
};

const defaultTitle = (filters: FilterCollection, i18n: I18n, isCommuteActive = false) => {
    const listingType = filters.getFilterValue<PropertyType>(
        FilterValues.type.attribute,
        FilterValues.type.default,
    );

    const category = filters.getFilterValue<CategoryNodeData>(FilterValues.category.attribute);
    const purpose = Purpose.text(
        i18n,
        filters.getFilterValue(FilterValues.purpose.attribute),
        PurposeTextDisplay.CONSISTENT_PREPOSITION,
    ).toLowerCase();
    const completionStatus = filters.getFilterValue(FilterValues.completionStatus.attribute, null);

    const listingTypeText =
        listingType === PropertyType.PROJECT ? t(i18n)`Projects` : t(i18n)`Properties`;
    const locationText = getLocationTextFromFilters(filters, i18n);
    const categoryText =
        listingType === PropertyType.PROJECT ? listingTypeText : category?.name || listingTypeText;

    if (isCommuteActive) {
        return t(i18n)`${categoryText} ${purpose} near your selected locations`;
    }

    if (
        CONFIG.build.STRAT_ENABLE_OFF_PLAN_PURPOSE_FILTERS &&
        completionStatus === PropertyCompletionStatus.OFF_PLAN
    ) {
        const lowerCaseCategoryText = categoryText.toLowerCase();

        switch (listingType) {
            case PropertyType.PROJECT:
                return t(i18n)`Off-Plan ${lowerCaseCategoryText} in ${locationText}`;

            case PropertyType.PROPERTY:
            default:
                return t(i18n)`Off-Plan ${lowerCaseCategoryText} ${purpose} in ${locationText}`;
        }
    }

    return t(i18n)`${categoryText} ${purpose} in ${locationText}`;
};

export { getLocationTextFromFilters };

export default defaultTitle;
