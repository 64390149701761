import EMPTY_ARRAY from 'strat/empty/array';
import type {
    CategoryField,
    CategoryFieldChoice,
    CategoryFieldsFilters,
    FlatCategoryField,
    FlatCategoryFieldHierarchy,
} from 'strat/types';
import { sortByDisplayPriority, matchChoiceValue, dictionaryToFlatArray } from 'strat/util';
import { CategoryFieldChoicesKeys } from 'strat/types';
import { CategoryFieldChoicesKeysType } from 'strat/types/categoryFields';

import getParentField from './getParentField';
import flattenCategoryFieldsChildren, {
    flattenCategoryFieldChildrenChoices,
} from './flattenCategoryFieldsChildren';
import type { CategoryFields } from './types';

export const flattenCategoryFieldChoices = (
    choices?: Partial<Record<CategoryFieldChoicesKeysType, Array<CategoryFieldChoice>>>,
    parentID: CategoryFieldChoicesKeysType = CategoryFieldChoicesKeys.ALL,
): Array<CategoryFieldChoice> => {
    if (!choices) {
        return EMPTY_ARRAY as any[];
    }

    if (Array.isArray(choices)) {
        return choices;
    }

    return (choices?.[parentID] ?? EMPTY_ARRAY).map((choice) => {
        return {
            ...choice,
            parentID: parentID !== CategoryFieldChoicesKeys.ALL ? parentID : null,
        };
    });
};

/**
 * Will flatten the category fields choices into arrays.
 * The choices of the categoryFields that have a parent will be reduced based on the selected value for the parent field
 *
 * @param categoryFields: CategoryField array
 * @param values: a dictionary with keys the field attributes and values the selected values
 * @returns Array<FlatCategoryField>
 */
const flattenCategoryFields = (
    categoryFields: CategoryFields | Array<CategoryField>,
    values?: CategoryFieldsFilters,
): Array<FlatCategoryField> => {
    if (!Array.isArray(categoryFields)) {
        return flattenCategoryFieldsChildren(categoryFields, values);
    }

    return categoryFields.reduce<Array<any>>((acc, field) => {
        const parentField = getParentField(field, categoryFields);
        const selectedChoice = parentField
            ? dictionaryToFlatArray(parentField.choices).find(
                  (choice) =>
                      !Array.isArray(choice) &&
                      matchChoiceValue(choice, values?.[parentField.attribute]),
              )
            : null;

        const flattenedField: FlatCategoryField = {
            ...field,
            // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'CategoryFieldChoice[]'.
            choices: flattenCategoryFieldChoices(field.choices, selectedChoice?.id).sort(
                sortByDisplayPriority,
            ),
            combinationChoices: flattenCategoryFieldChoices(
                field.combinationChoices,
                // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'CategoryFieldChoice[]'.
                selectedChoice?.id,
            ).sort(sortByDisplayPriority),
        };

        return [...acc, flattenedField];
    }, []);
};

/**
 * Will flatten the category fields choices into arrays and restructure fields into an hierarchy.
 * The categoryFields that have a parent will be in the `parent.children` array and will not be added to the
 * returned array.
 *
 * @param categoryFields: CategoryField array
 * @param values: a dictionary with keys the field attributes and values the selected values
 * @returns Array<FlatCategoryFieldHierarchy>
 */
export const flattenCategoryFieldsWithHierarchy = (
    categoryFields: CategoryFields,
    values?: CategoryFieldsFilters,
): Array<FlatCategoryFieldHierarchy> => {
    const fieldsHierarchy = categoryFields.flatFields.reduce<
        Record<string, FlatCategoryFieldHierarchy>
    >(
        (fields, field) => ({
            ...fields,
            [field.attribute]: {
                ...field,
                children: [],
                choices: field.choices || [],
                combinationChoices: field.combinationChoices || [],
            },
        }),
        {},
    );
    categoryFields.childrenFields.forEach((child) => {
        const parentAttribute = categoryFields.parentFieldLookup[child.attribute];
        if (!fieldsHierarchy[parentAttribute]) {
            return;
        }
        const parentValue = (values || {})[parentAttribute];
        if (typeof parentValue !== 'string' && typeof parentValue !== 'number') {
            fieldsHierarchy[parentAttribute].children.push({
                ...child,
                choices: [],
                combinationChoices: [],
            });
            return;
        }
        fieldsHierarchy[parentAttribute].children.push({
            ...child,
            choices: flattenCategoryFieldChildrenChoices(child.choices, parentValue),
            combinationChoices: flattenCategoryFieldChildrenChoices(
                child.combinationChoices,
                parentValue,
            ),
        });
    });
    return dictionaryToFlatArray(fieldsHierarchy).sort(sortByDisplayPriority);
};

export const transformToCategoryFieldsHierarchy = (
    categoryFields: Array<FlatCategoryField>,
): Array<FlatCategoryFieldHierarchy> =>
    Object.values(
        categoryFields.reduce<Record<string, any>>((fieldsDictionary, field) => {
            const parentField = getParentField(field, categoryFields);
            if (parentField) {
                fieldsDictionary[parentField.id] = {
                    ...parentField,
                };
                if (!fieldsDictionary[parentField.id].children) {
                    fieldsDictionary[parentField.id].children = [];
                }
                fieldsDictionary[parentField.id].children.push(field);
                return fieldsDictionary;
            }

            fieldsDictionary[field.id] = {
                ...field,
                children: fieldsDictionary?.[field.id]?.children,
            };
            return fieldsDictionary;
        }, {}),
    ) as any;

export default flattenCategoryFields;
