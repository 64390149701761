//@ts-nocheck
/**
 * Available search views.
 */
const SearchView = Object.freeze({
    SEARCH_RESULTS: 'SEARCH_RESULTS',
    FAVORITES: 'FAVORITES',
    RECOMMENDATIONS: 'RECOMMENDATIONS',
    MAP_BASED_SEARCH: 'MAP_BASED_SEARCH',
    COMMUTE_SEARCH: 'COMMUTE_SEARCH',
});

export default SearchView;
