import { createSelector } from 'reselect';
import settings from '@app/branding/settings';

import EMPTY_ARRAY from 'strat/empty/array';
import type { PropertyData } from 'strat/property/types';
import { selectIsUserLoggedIn } from 'strat/user/selectors';
import { selectIsFavoritesView } from 'strat/search/selectors';
import { GlobalState } from 'strat/state';

const selectFavoritesLoaded = (state: GlobalState) => state.favorites.loaded;

const selectFavoritesLoading = (state: GlobalState) => state.favorites.loading;

const selectShouldFetchFavorites = createSelector(
    selectIsUserLoggedIn,
    selectFavoritesLoaded,
    selectFavoritesLoading,
    (userLoggedIn, favoritesLoaded, favoritesLoading) =>
        !(settings.disableFavorites && !CONFIG.build.STRAT_FORCE_ENABLE_FAVORITES) &&
        userLoggedIn &&
        !favoritesLoaded &&
        !favoritesLoading,
);

/**
 * Note that this selectors implies that favorites data is already in the
 * store. If this is not guaranteed, use `useFavorites` hook instead, which
 * will also fetch them, in case they are not already loaded.
 */
const selectFavorites = (state: GlobalState) => state.favorites.data || EMPTY_ARRAY;

const selectInactiveFavoritesIDs = (state: GlobalState) =>
    state.inactiveFavorites.data || EMPTY_ARRAY;

const selectFavoritesIDs = createSelector(
    selectFavorites,
    selectInactiveFavoritesIDs,
    (activeFavorites, inactiveFavoritesIDs) => {
        const activeFavoriteIDs = activeFavorites.map(
            (favorite: PropertyData) => favorite.externalID,
        );

        if (CONFIG.build.STRAT_DISPLAY_INACTIVE_FAVORITES) {
            return [...activeFavoriteIDs, ...inactiveFavoritesIDs];
        }

        return activeFavoriteIDs;
    },
);

const selectShouldSetFavoritesHits = createSelector(
    selectIsFavoritesView,
    selectFavoritesLoaded,
    (favoritesPage, favoritesLoaded) => favoritesPage && favoritesLoaded,
);

export {
    selectFavorites,
    selectFavoritesIDs,
    selectFavoritesLoaded,
    selectInactiveFavoritesIDs,
    selectShouldFetchFavorites,
    selectShouldSetFavoritesHits,
};
