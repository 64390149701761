import { t } from '@lingui/macro';
import React from 'react';
import type { FormikValues, FormikTouched, FormikErrors } from 'formik';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import { useI18n } from 'strat/i18n/language';
import { isValidPhoneNumber } from 'strat/i18n/phone';
import settings from '@app/branding/settings';
import { Input, Button } from 'strat/components';

type FormProps = {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly values: FormikValues;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly handleSubmit: (arg1?: any) => void;
    readonly isSubmitting: boolean;
    readonly errorMessage: string;
};

const FieldAttribute = Object.freeze({
    PHONE_NUMBER: 'phoneNumber',
});

export type Field = {
    readonly attribute: string;
    readonly unit?: string;
    readonly initial: string;
};

export const TPayPaymentField: Field = {
    attribute: FieldAttribute.PHONE_NUMBER,
    unit: settings.defaultPhoneNumber.prefix,
    initial: '',
};

const Errors = {
    invalidPhone: (i18n: I18n) => t(i18n)`Please enter a valid phone number`,
} as const;

export const makeValidationSchema = (i18n: I18n) =>
    Yup.object().shape({
        phoneNumber: Yup.string()
            .required(t(i18n)`Please enter a valid phone number`)
            // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
            .test('validPhone', Errors.invalidPhone(i18n), (value) =>
                isValidPhoneNumber(
                    (value || '').startsWith('+')
                        ? value
                        : `${settings.defaultPhoneNumber.prefix}${value}`,
                ),
            ),
    });

const Form = ({
    setFieldValue,
    values,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    errorMessage,
}: FormProps) => {
    const i18n = useI18n();

    return (
        <>
            <Input
                name="phone"
                type="tel"
                autoComplete="tel"
                unit={TPayPaymentField.unit}
                placeholder={t(i18n)`Phone number`}
                onChange={(value) => setFieldValue(TPayPaymentField.attribute, value)}
                onBlur={handleBlur}
                value={values[TPayPaymentField.attribute]}
                errorMessage={
                    errorMessage ||
                    (touched[TPayPaymentField.attribute] && errors[TPayPaymentField.attribute])
                }
                accepted={
                    touched[TPayPaymentField.attribute] && !errors[TPayPaymentField.attribute]
                }
                onSubmit={handleSubmit}
                autoFocus
            />
            <Button type="button" stretch onClick={handleSubmit} disabled={isSubmitting}>
                {t(i18n)`Continue`}
            </Button>
        </>
    );
};

export default Form;
