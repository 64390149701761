import { OvationUserData } from 'strat/api/ovationAPI';
import { UserProfileData } from 'strat/user/types';

export const userData = (activeUserProfile?: UserProfileData | null): OvationUserData => {
    return {
        user_external_id: activeUserProfile?.id ? String(activeUserProfile?.id) : null,
        user_name: activeUserProfile?.name,
        user_email_address: activeUserProfile?.email,
        user_phone_number: activeUserProfile?.phoneNumber,
    };
};
