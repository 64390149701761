import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import { useRefreshSession } from '@sector-labs/fe-auth-redux/thunk';
import { isCanceled } from 'strat/util';
import { useDialog } from 'strat/dialogs/dialogContext';
import { useI18n } from 'strat/i18n/language';
import { StratAPI } from 'strat/api';
import { useKeycloakSiteConfigWithCallbacks } from 'strat/auth/keycloak';
import { Text, Flex, Button } from 'strat/components';
import { useActiveUser } from 'strat/user/session';

import useUploadImage from 'horizontal/util/useUploadImage';

import { DialogNames } from '../dialogs';
import IconInfo from '../assets/icons/iconInfoCircle.svg';
import { useMakeCancelable } from '../util';

import styles from './styles/userImageUpload.cssm';

type Props = {
    setSubmitting: (arg1: boolean) => void;
    userImageSizes: {
        min: number;
        max: number;
    };
};

const UserImageUpload = ({ setSubmitting, userImageSizes }: Props) => {
    const { uploadImage } = useUploadImage();
    const refreshSession = useRefreshSession();
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const user = useActiveUser();
    const [, setShowUserImageFailDialog] = useDialog(DialogNames.USER_IMAGE_FAIL_DIALOG);
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();

    const handleUploadPhotoClick = (event: any) => {
        const [file] = event.target.files;
        if (!file) {
            return;
        } // sometimes code is executed even when file browser is Canceled
        setSubmitting(true);
        const imageID = uuid4();
        uploadImage(imageID, file)
            ?.then((url: string) => {
                // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                makeCancelable(new StratAPI().saveUserImage(user?.externalID, url))
                    .then(() => {
                        // @ts-expect-error - TS2339 - Property 'then' does not exist on type '(dispatch: Dispatch<RefreshAction>, getState: () => GlobalState) => Promise<SessionData | null>'.
                        refreshSession(keycloakSiteConfig).then(setSubmitting(false));
                    })
                    // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
                    .catch((error) => {
                        if (isCanceled(error)) {
                            return;
                        }
                        setSubmitting(false);
                        setShowUserImageFailDialog(true, {
                            message: 'Failed uploading user image',
                        });
                    });
            })
            .catch(() => {
                setShowUserImageFailDialog(true, {
                    message: 'Failed uploading user image',
                });
            });
    };

    const handleRemovePhotoClick = () => {
        setSubmitting(true);
        // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        makeCancelable(new StratAPI().deleteUserImage(user?.externalID))
            .then(() => {
                // @ts-expect-error - TS2339 - Property 'then' does not exist on type '(dispatch: Dispatch<RefreshAction>, getState: () => GlobalState) => Promise<SessionData | null>'.
                refreshSession(keycloakSiteConfig).then(setSubmitting(false));
            })
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            .catch((error) => {
                if (isCanceled(error)) {
                    return;
                }
                setSubmitting(false);
                setShowUserImageFailDialog(true, { message: 'Failed removing user image' });
            });
    };

    return (
        <Flex column>
            <Flex className={styles.uploadPhotoDiv}>
                <div className={styles.userImageButtonDiv}>
                    <label className={classNames(styles.button)}>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleUploadPhotoClick}
                            hidden
                        />
                        {t(i18n)`Upload Photo`}
                    </label>
                </div>
                {user?.profileImageID && (
                    <div className={styles.userImageButtonDiv}>
                        <Button
                            className={styles.wideButton}
                            secondary
                            stretch
                            stretchContent
                            onClick={handleRemovePhotoClick}
                        >
                            {t(i18n)`Remove`}
                        </Button>
                    </div>
                )}
            </Flex>
            <Flex className={styles.imageInfoDiv}>
                <IconInfo />
                <Text.Base className={styles.imageInfoText}>
                    {t(
                        i18n,
                    )`JPG, JPEG, PNG Min: ${userImageSizes.min}px, Max: ${userImageSizes.max}px`}
                </Text.Base>
            </Flex>
        </Flex>
    );
};

export default UserImageUpload;
