import * as React from 'react';

type TouchEvent = React.TouchEvent<HTMLElement> & {
    touches: Array<Touch>;
};

export const getSingleTouchEvent = (event: TouchEvent) => {
    const touches = event.touches;
    if (touches.length !== 1) {
        return null;
    }

    return touches[0];
};

type TrackedTouch =
    | {
          touch: Touch;
          time: number;
      }
    | null
    | undefined;

export const useVelocityTracking = () => {
    const [previousTouch, setPreviousTouch] = React.useState<TrackedTouch>(null);
    const [velocity, setVelocity] = React.useState<any>(null);

    const trackVelocity = (currentTouch?: Touch | null) => {
        if (!currentTouch) {
            return null;
        }

        if (!previousTouch) {
            setPreviousTouch({ touch: currentTouch as Touch, time: performance.now() });
            return null;
        }

        const now = performance.now();
        const newVelocity =
            (currentTouch.clientY - previousTouch.touch.clientY) / (now - previousTouch.time);

        setPreviousTouch({ touch: currentTouch as Touch, time: now });
        setVelocity(newVelocity);
        return newVelocity;
    };

    const clearVelocity = React.useCallback(() => {
        setPreviousTouch(null);
        setVelocity(0);
    }, []);

    return [velocity, trackVelocity, clearVelocity];
};
