import { t } from '@lingui/macro';
import * as React from 'react';
import {
    SessionErrorKey,
    SessionError,
    useSessionError,
    useResetSessionError,
} from '@sector-labs/fe-auth-redux';

import { useI18n } from 'strat/i18n/language';

export type KeycloakErrorData = {
    /** Optional custom title set for the error */
    readonly title?: string;
    /** Custom user friendly error message */
    readonly message: string;
    /** Keycloak generated error description */
    readonly description: string;
    /** Keycloak SessionErrorKey */
    readonly type: SessionErrorKey;
};

const useKeycloakErrorData = (): [KeycloakErrorData | null, () => void] => {
    const i18n = useI18n();
    const error = useSessionError();
    const resetError = useResetSessionError();

    const errorData = React.useMemo(() => {
        if (!error) {
            return null;
        }

        const description =
            error instanceof SessionError
                ? error.extra?.httpResponseBody?.error_description || error.message
                : error.name;

        switch (error.name) {
            case SessionErrorKey.AUTH_REQUIRED:
                return {
                    title: t(i18n)`Invalid password`,
                    message: t(i18n)`Please try again or use Forgot Password`,
                    type: SessionErrorKey.AUTH_REQUIRED,
                    description: t(i18n)`Invalid credentials.`,
                };
            case SessionErrorKey.NO_ACCOUNT_ASSOCIATED:
                return {
                    title: t(i18n)`No account associated`,
                    message: t(i18n)`No account associated`,
                    type: SessionErrorKey.NO_ACCOUNT_ASSOCIATED,
                    description: description,
                };

            case SessionErrorKey.ACCESS_DENIED:
                return {
                    message: t(i18n)`Access denied.`,
                    type: SessionErrorKey.ACCESS_DENIED,
                    description: description,
                };

            case SessionErrorKey.CONFLICT:
                return {
                    message: t(
                        i18n,
                    )`Your request seems to be in conflict with our data. Please refresh and try again.`,
                    type: SessionErrorKey.CONFLICT,
                    description: description,
                };

            case SessionErrorKey.INPUT:
                return {
                    message: t(i18n)`Invalid request.`,
                    type: SessionErrorKey.INPUT,
                    description: description,
                };

            case SessionErrorKey.VALIDATION_ERROR:
                return {
                    message: t(i18n)`Invalid request.`,
                    type: SessionErrorKey.VALIDATION_ERROR,
                    description: description,
                };

            case SessionErrorKey.PRECONDITION_FAILED:
                return {
                    message: t(i18n)`Invalid request.`,
                    type: SessionErrorKey.VALIDATION_ERROR,
                    description: description,
                };

            case SessionErrorKey.NOT_FOUND:
                return {
                    message: t(
                        i18n,
                    )`We could not find the data you have requested. Please try again.`,
                    type: SessionErrorKey.NOT_FOUND,
                    description: description,
                };

            case SessionErrorKey.ALREADY_LOGGED_IN:
                return {
                    message: t(i18n)`You are already logged in.`,
                    type: SessionErrorKey.ALREADY_LOGGED_IN,
                    description: description,
                };

            case SessionErrorKey.ALREADY_IN_USE:
                return {
                    message: error.message,
                    type: SessionErrorKey.ALREADY_IN_USE,
                    description: description,
                };

            case SessionErrorKey.TIMEOUT:
                return {
                    message: t(i18n)`The operation timed out. Please try again.`,
                    type: SessionErrorKey.TIMEOUT,
                    description: t(i18n)`The operation timed out. Please try again.`,
                };

            case SessionErrorKey.SERVER:
                return {
                    message: t(i18n)`We are having some trouble right now.`,
                    type: SessionErrorKey.SERVER,
                    description: description,
                };

            case SessionErrorKey.SERVER_GATEWAY:
                return {
                    message: t(i18n)`We are having some trouble right now.`,
                    type: SessionErrorKey.SERVER_GATEWAY,
                    description: description,
                };

            case SessionErrorKey.UNKNOWN_SERVER:
                return {
                    message: t(i18n)`We are having some trouble right now.`,
                    type: SessionErrorKey.UNKNOWN_SERVER,
                    description: description,
                };

            case SessionErrorKey.NETWORK:
                return {
                    message: t(i18n)`We could not reach our servers.`,
                    type: SessionErrorKey.NETWORK,
                    description: description,
                };

            case SessionErrorKey.GONE:
                return {
                    message: t(i18n)`This data is no longer available.`,
                    type: SessionErrorKey.GONE,
                    description: description,
                };

            case SessionErrorKey.OTP_CODE_EXPIRED:
                return {
                    message: t(i18n)`Your code has expired. Request another one and try again.`,
                    type: SessionErrorKey.OTP_CODE_EXPIRED,
                    description: t(i18n)`Your code has expired. Request another one and try again.`,
                };

            case SessionErrorKey.TOO_MANY_REQUESTS:
                return {
                    message: t(i18n)`Too many attempts. Please wait and try again.`,
                    type: SessionErrorKey.TOO_MANY_REQUESTS,
                    description: t(i18n)`Too many attempts. Please wait and try again.`,
                };

            case SessionErrorKey.USER_DISABLED:
                return {
                    message: t(i18n)`The account for this user has been deactivated.`,
                    type: SessionErrorKey.USER_DISABLED,
                    description: description,
                };

            case SessionErrorKey.CODE_SEND_FAILED:
                return {
                    message: t(i18n)`Sending the one time password failed.`,
                    type: SessionErrorKey.CODE_SEND_FAILED,
                    description: description,
                };

            case SessionErrorKey.PASSWORD_POLICY_FAILED:
            case SessionErrorKey.PASSWORD_POLICY_BLACKLIST_FAILED:
            case SessionErrorKey.PASSWORD_POLICY_HISTORY_FAILED:
            case SessionErrorKey.PASSWORD_POLICY_REGEX_FAILED:
            case SessionErrorKey.PASSWORD_POLICY_MIN_LENGTH_FAILED:
            case SessionErrorKey.PASSWORD_POLICY_NOT_EMAIL_FAILED:
                return {
                    message: description,
                    type: error.name,
                    description: description,
                };

            case SessionErrorKey.LOGIN_TYPE_DENIED:
                return {
                    message: description,
                    type: SessionErrorKey.LOGIN_TYPE_DENIED,
                    description: description,
                };

            default:
                return {
                    message: t(i18n)`An unknown error occurred.`,
                    type: SessionErrorKey.UNKNOWN,
                    description: description,
                };
        }
    }, [i18n, error]);

    return [errorData, resetError];
};

export default useKeycloakErrorData;
