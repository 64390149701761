// flow strict

import * as React from 'react';
import classNames from 'classnames';

import type { RenderChoiceProps } from './options';
import type { ChoiceValue } from './types';
import * as Text from './text';
import styles from './styles/roundedButtonChoice.cssm';

const RoundedButtonChoice = <T extends ChoiceValue>({
    option,
    isSelected,
    onChange,
    secondary,
}: RenderChoiceProps<T>) => (
    <div
        key={option.id}
        className={classNames(styles.choice, {
            [styles.selected]: isSelected(option.value) && !secondary,
            [styles.selectedSecondary]: isSelected(option.value) && secondary,
        })}
        onClick={() => onChange(option.value)}
    >
        <Text.Regular>{option.label}</Text.Regular>
    </div>
);

export default RoundedButtonChoice;
