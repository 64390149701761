import * as React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { adData } from '@app/ovation/extractAdData';
import type { LegacyStatsAPI, LegacyStatsResources } from '@app/legacyStatsTracking';
import { createLegacyStatsAPI, selectLegacyStatsResources } from '@app/legacyStatsTracking';
import { userData } from 'strat/ovation/extractUserData';
import OvationAPI from 'strat/api/ovationAPI';
import RouteNames from 'strat/routes/routeNames';
import { selectRouteName } from 'strat/routes/selectors';
import ViewSections from 'strat/gtm/viewSections';
import {
    selectOvationAppType,
    selectOvationUser,
    selectTrackingData,
    selectExperimentsData,
} from 'strat/ovation/selectors';
import { selectPropertyOfTheWeekExternalID } from 'strat/propertyOfTheWeek/selectors';
import type { ClientData, TrackingData } from 'strat/api/ovationAPI';
import type { PropertyData } from 'strat/property/types';
import { GlobalState } from 'strat/state';
import { selectUserProfile } from 'strat/user/selectors';
import { UserProfileData } from 'strat/user/types';
import { APIResponse } from 'strat/api/types';
import { AgentData } from 'strat/agency/agent/types';

import {
    OvationAppType,
    OvationMetricEntity,
    OvationMetricSource,
    OvationMetricAction,
    FavoriteAction,
    AdPartialData,
} from './types';
import { adData as adStratData } from './extractAdData';

type Props = {
    appType: Values<typeof OvationAppType>;
    user: any;
    routeName: string;
    forwardRef: any | null | undefined;
    potwExternalID: string | null | undefined;
    activeUserProfile?: UserProfileData | null;
    clientData: ClientData;
    legacyStats: LegacyStatsResources;
    trackingData: TrackingData;
    experiments: Array<string>;
};

export type EmailLeadData = {
    userName: string;
    userEmailAddress: string;
    userPhoneNumber: string;
    emailBody: string;
};

export type SellerLeadData = {
    reportExternalID: string;
    truestimate: number;
    commission: number;
    agentEmail: string;
    userName: string;
    userEmailAddress: string;
    userPhoneNumber: string;
    emailBody: string;
};

type ChatLeadData = {
    userName: string;
    userEmailAddress: string;
    userPhoneNumber: string;
    message: string;
};

export type OvationCallbackProps = {
    ingestAdView: (ad: AdPartialData, uniqueEventID: string) => void;
    ingestAdImpressions: (ads: ReadonlyArray<AdPartialData>) => void;
    ingestAdPhoneView: (
        ad: PropertyData,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAdSMSView: (
        ad: PropertyData,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAdWhatsAppView: (
        ad: PropertyData,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAdEmailView: (
        ad: PropertyData,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAdEmailLead: (
        ad: PropertyData,
        email: EmailLeadData,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestSellerLeads: (
        agents: Array<AgentData>,
        propertyData: Partial<PropertyData>,
        sellerLead: SellerLeadData,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAgentPhoneView: (
        agentExternalID: string,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAgentEmailView: (
        agentExternalID: string,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAgentEmailLeads: (
        agentExternalIDs: Array<string>,
        email: EmailLeadData,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAgencyPhoneView: (
        agencyExternalID: string,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAgencyEmailView: (
        agencyExternalID: string,
        viewSection: Values<typeof ViewSections>,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAgencyEmailLeads: (
        agencyExternalIDs: Array<string>,
        email: EmailLeadData,
        uniqueEventID: string,
    ) => Promise<any>;
    ingestAdChatLead: (ad: PropertyData, chat: ChatLeadData, uniqueEventID: string) => Promise<any>;
    ingestRecommendedAdsImpressions: (ads: Array<AdPartialData>) => void;
    ingestBannerView: (bannerIdentifier: string) => void;
    ingestBannerLead: (bannerIdentifier: string) => void;
    ingestAdFavorite: (ad: AdPartialData, isSelected: boolean) => void;
};

export type OvationProps = Props & {
    ovation: OvationCallbackProps;
};

const mapStateToProps = (state: GlobalState) => ({
    appType: selectOvationAppType(state),
    clientData: selectOvationUser(state),
    routeName: selectRouteName(state),
    potwExternalID: selectPropertyOfTheWeekExternalID(state),
    activeUserProfile: selectUserProfile(state),
    legacyStats: selectLegacyStatsResources(state),
    trackingData: selectTrackingData(state),
    experiments: selectExperimentsData(state),
});

const withOvation = <T extends OvationProps>(component: React.ComponentType<T>) => {
    class OvationHOC extends React.Component<T> {
        api: OvationAPI;
        legacyStatsApi: LegacyStatsAPI;

        constructor(props: T) {
            super(props);
            autoBind(this);

            this.api = new OvationAPI();

            this.legacyStatsApi = createLegacyStatsAPI(
                // @ts-ignore
                this.props.legacyStats && this.props.legacyStats.i18n.locale,
            );
        }

        /**
         * Ingests a user clicking the call button.
         */
        ingestAdPhoneView(
            ad: PropertyData,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            if (this.legacyStatsApi) {
                // @ts-ignore
                return this.legacyStatsApi.ingestAdPhoneView({
                    // @ts-ignore
                    property: ad,
                    // @ts-ignore
                    legacyStatUser: this.props.legacyStats && this.props.legacyStats.legacyStatUser,
                    // @ts-ignore
                    campaign: this.props.legacyStats && this.props.legacyStats.campaign,
                    // @ts-ignore
                    routeName: this.props.routeName,
                });
            }
            return this.api.ingestAdMetrics([
                {
                    ...this.props.clientData,
                    ...this.props.trackingData,
                    ...adData(ad, this.props.potwExternalID, this.props.experiments),
                    ...userData(this.props.activeUserProfile),
                    metric_entity: OvationMetricEntity.PHONE,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    app_type: this.props.appType,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests a user clicking the SMS button.
         */
        ingestAdSMSView(
            ad: PropertyData,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            if (this.legacyStatsApi) {
                // @ts-ignore
                return this.legacyStatsApi.ingestAdSMSView({
                    // @ts-ignore
                    property: ad,
                    // @ts-ignore
                    legacyStatUser: this.props.legacyStats && this.props.legacyStats.legacyStatUser,
                    // @ts-ignore
                    campaign: this.props.legacyStats && this.props.legacyStats.campaign,
                    routeName: this.props.routeName,
                });
            }
            return this.api.ingestAdMetrics([
                {
                    ...this.props.clientData,
                    ...this.props.trackingData,
                    ...adData(ad, this.props.potwExternalID, this.props.experiments),
                    ...userData(this.props.activeUserProfile),
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.SMS,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests a user clicking the WhatsApp button.
         */
        ingestAdWhatsAppView(
            ad: PropertyData,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            if (this.legacyStatsApi) {
                // @ts-ignore
                return this.legacyStatsApi.ingestAdWhatsAppView({
                    // @ts-ignore
                    property: ad,
                    // @ts-ignore
                    legacyStatUser: this.props.legacyStats && this.props.legacyStatUser,
                    // @ts-ignore
                    campaign: this.props.legacyStats && this.props.legacyStats.campaign,
                    routeName: this.props.routeName,
                });
            }

            return this.api.ingestAdMetrics([
                {
                    ...this.props.clientData,
                    ...this.props.trackingData,
                    ...adData(ad, this.props.potwExternalID, this.props.experiments),
                    ...userData(this.props.activeUserProfile),
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.WHATSAPP,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests a user clicking the E-mail button.
         */
        ingestAdEmailView(
            ad: PropertyData,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            return this.api.ingestAdMetrics([
                {
                    ...this.props.clientData,
                    ...this.props.trackingData,
                    ...adData(ad, this.props.potwExternalID, this.props.experiments),
                    ...userData(this.props.activeUserProfile),
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.EMAIL,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests an e-mail lead against a ad/listing/property.
         */
        ingestAdEmailLead(ad: PropertyData, email: EmailLeadData, uniqueEventID: string) {
            if (this.legacyStatsApi) {
                // @ts-expect-error - TS2339 - Property 'ingestAdEmailLead' does not exist on type 'never'.
                return this.legacyStatsApi.ingestAdEmailLead(ad, email);
            }
            return this.api.ingestAdEmailLeads([
                {
                    ...this.props.clientData,
                    ...adData(ad, this.props.potwExternalID, this.props.experiments),
                    ...userData(this.props.activeUserProfile),
                    app_type: this.props.appType,
                    metric_source: this.metricSource(),
                    user_name: email.userName,
                    user_email_address: email.userEmailAddress,
                    user_phone_number: email.userPhoneNumber,
                    email_body: email.emailBody,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests e-mail leads for the specified list of agency
         * external ID's.
         */
        ingestAgencyEmailLeads(
            agencyExternalIDs: Array<string>,
            email: EmailLeadData,
            uniqueEventID: string,
        ) {
            const leads = agencyExternalIDs.map((agencyExternalID) => ({
                ...this.props.clientData,
                ...userData(this.props.activeUserProfile),
                agency_type: 'agency',
                agency_external_id: agencyExternalID,
                app_type: this.props.appType,
                metric_source: this.metricSource(),
                user_name: email.userName,
                user_email_address: email.userEmailAddress,
                user_phone_number: email.userPhoneNumber,
                email_body: email.emailBody,
                unique_event_id: uniqueEventID,
            }));

            return this.api.ingestAgencyEmailLeads(leads);
        }

        /**
         * Ingests e-mail leads for the specified agent.
         */
        ingestAgentEmailLeads(
            agentExternalIDs: Array<string>,
            email: EmailLeadData,
            uniqueEventID: string,
        ) {
            const leads = agentExternalIDs.map((agentExternalID) => ({
                ...this.props.clientData,
                ...userData(this.props.activeUserProfile),
                agent_external_id: agentExternalID,
                app_type: this.props.appType,
                metric_source: this.metricSource(),
                user_name: email.userName,
                user_email_address: email.userEmailAddress,
                user_phone_number: email.userPhoneNumber,
                email_body: email.emailBody,
                unique_event_id: uniqueEventID,
            }));

            return this.api.ingestAgentEmailLeads(leads);
        }

        /**
         * Ingests seller leads for the specified agent.
         */
        ingestSellerLeads(
            agents: Array<AgentData>,
            propertyData: Partial<PropertyData>,
            sellerLead: SellerLeadData,
            uniqueEventID: string,
        ): Promise<APIResponse<unknown>> {
            const leads = agents.map((agent) => ({
                ...this.props.clientData,
                ...adData(propertyData),
                ...userData(this.props.activeUserProfile),
                ad_plot_area: propertyData.plotArea,
                report_external_id: sellerLead.reportExternalID,
                ad_agent_email: sellerLead.agentEmail,
                ad_agency_external_id: agent.agency?.external_id,
                truestimate: sellerLead.truestimate,
                commission: sellerLead.commission,
                agent_external_id: agent.external_id,
                app_type: this.props.appType,
                user_name: sellerLead.userName,
                user_email_address: sellerLead.userEmailAddress,
                user_phone_number: sellerLead.userPhoneNumber,
                message_body: sellerLead.emailBody,
                unique_event_id: uniqueEventID,
            }));

            return this.api.ingestSellerLeads(leads);
        }

        ingestAgentPhoneView(
            agentExternalID: string,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            return this.api.ingestAgentMetrics([
                {
                    ...this.props.clientData,
                    agent_external_id: agentExternalID,
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.PHONE,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        ingestAgentEmailView(
            agentExternalID: string,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            return this.api.ingestAgentMetrics([
                {
                    ...this.props.clientData,
                    agent_external_id: agentExternalID,
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.EMAIL,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        ingestAgencyPhoneView(
            agencyExternalID: string,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            return this.api.ingestAgencyMetrics([
                {
                    ...this.props.clientData,
                    agency_type: 'agency',
                    agency_external_id: agencyExternalID,
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.PHONE,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        ingestAgencyEmailView(
            agencyExternalID: string,
            viewSection: Values<typeof ViewSections>,
            uniqueEventID: string,
        ) {
            return this.api.ingestAgencyMetrics([
                {
                    ...this.props.clientData,
                    agency_type: 'agency',
                    agency_external_id: agencyExternalID,
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.EMAIL,
                    metric_source: this.metricSource(viewSection),
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests an chat lead against a ad/listing/property.
         */
        ingestAdChatLead(ad: PropertyData, chat: ChatLeadData, uniqueEventID: string) {
            return this.api.ingestAdChatLeads([
                {
                    ...this.props.clientData,
                    ...adData(ad, this.props.potwExternalID, this.props.experiments),
                    ...userData(this.props.activeUserProfile),
                    app_type: this.props.appType,
                    metric_source: this.metricSource(),
                    user_name: chat.userName,
                    user_email_address: chat.userEmailAddress,
                    user_phone_number: chat.userPhoneNumber,
                    message: chat.message,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests a user viewing an ad/listing/property's details page.
         */
        ingestAdView(ad: AdPartialData, uniqueEventID: string) {
            if (this.legacyStatsApi) {
                // @ts-expect-error - TS2339 - Property 'ingestAdView' does not exist on type 'never'.
                return this.legacyStatsApi.ingestAdView(ad);
            }

            return this.api.ingestAdMetrics([
                {
                    ...this.props.clientData,
                    ...this.props.trackingData,
                    ...adStratData(ad, this.props.potwExternalID, this.props.experiments),
                    app_type: this.props.appType,
                    metric_entity: OvationMetricEntity.AD,
                    metric_source: OvationMetricSource.DETAILS,
                    metric_action: OvationMetricAction.VIEW,
                    unique_event_id: uniqueEventID,
                },
            ]);
        }

        /**
         * Ingests a user viewing an ad/listing/property in
         * the search results.
         */
        ingestAdImpressions(ads: ReadonlyArray<AdPartialData>, attributions: Array<string> = []) {
            if (!ads.length) {
                return Promise.resolve({ status: 201, data: ads });
            }

            if (this.legacyStatsApi) {
                // @ts-ignore
                const promises = [this.legacyStatsApi.ingestAdImpressions(ads)];
                if (this.props.routeName === RouteNames.SEARCH) {
                    promises.push(
                        this.legacyStatsApi &&
                            // @ts-ignore
                            this.legacyStatsApi.ingestCategoriesImpressions(
                                this.props.legacyStats &&
                                    // @ts-ignore
                                    this.props.legacyStats.categoryImpressionFilters,
                                // @ts-ignore
                                this.props.legacyStats && this.props.legacyStats.i18n,
                            ),
                    );
                }
                return Promise.all(promises);
            }

            const metrics = ads.map((hit) => ({
                ...this.props.clientData,
                ...this.props.trackingData,
                ...adStratData(hit, this.props.potwExternalID, this.props.experiments),
                app_type: this.props.appType,
                metric_entity: OvationMetricEntity.AD,
                metric_source: OvationMetricSource.SEARCH,
                metric_action: OvationMetricAction.VIEW,
                attributions: attributions || [],
            }));

            return this.api.ingestAdMetrics(metrics);
        }

        /**
         * Ingests a user viewing a recommended ad/listing/property in
         * the details page.
         */
        ingestRecommendedAdsImpressions(ads: Array<AdPartialData>) {
            if (!ads.length || !this.legacyStatsApi) {
                return Promise.resolve({ status: 201, data: ads });
            }

            // @ts-expect-error - TS2339 - Property 'ingestAdImpressions' does not exist on type 'never'.
            return this.legacyStatsApi.ingestAdImpressions(ads);
        }

        /**
         * Ingests a user viewing a banner.
         */
        ingestBannerView(bannerIdentifier: string) {
            return this.api.ingestBannerMetrics([
                {
                    ...this.props.clientData,
                    banner_identifier: bannerIdentifier,
                    metric_action: OvationMetricAction.VIEW,
                    app_type: this.props.appType,
                },
            ]);
        }

        /**
         * Ingests a user clicking a banner.
         */
        ingestBannerLead(bannerIdentifier: string) {
            return this.api.ingestBannerMetrics([
                {
                    ...this.props.clientData,
                    banner_identifier: bannerIdentifier,
                    metric_action: OvationMetricAction.LEAD,
                    app_type: this.props.appType,
                },
            ]);
        }

        /**
         * Increments or decrements the favorite count of an ad.
         */
        ingestAdFavorite(ad: AdPartialData, isSelected: boolean) {
            return this.api.ingestAdFavorite({
                ...this.props.clientData,
                ...adStratData(ad),
                favorite_action: isSelected ? FavoriteAction.UNLIKE : FavoriteAction.LIKE,
            });
        }

        metricSource(
            viewSection: Values<typeof ViewSections> | null = null,
        ): Values<typeof OvationMetricSource> {
            switch (this.props.routeName) {
                case RouteNames.PROPERTY:
                case RouteNames.AD_DETAILS:
                case RouteNames.PROJECT:
                    return viewSection === ViewSections.EMAIL_POPUP
                        ? OvationMetricSource.EMAIL_POPUP_DETAILS
                        : OvationMetricSource.DETAILS;
                case RouteNames.AGENCY_DETAIL:
                case RouteNames.AGENT_DETAIL:
                    return viewSection === ViewSections.EMAIL_POPUP
                        ? OvationMetricSource.EMAIL_POPUP_SEARCH_AGENTS
                        : OvationMetricSource.SEARCH_AGENTS;
                default:
                    return viewSection === ViewSections.EMAIL_POPUP
                        ? OvationMetricSource.EMAIL_POPUP_SEARCH
                        : OvationMetricSource.SEARCH;
            }
        }

        render() {
            const callbacks = {
                // ad tracking related callbacks
                ingestAdView: this.ingestAdView,
                ingestAdImpressions: this.ingestAdImpressions,
                ingestAdPhoneView: this.ingestAdPhoneView,
                ingestAdSMSView: this.ingestAdSMSView,
                ingestAdWhatsAppView: this.ingestAdWhatsAppView,
                ingestAdEmailView: this.ingestAdEmailView,
                ingestAdEmailLead: this.ingestAdEmailLead,
                ingestAdChatLead: this.ingestAdChatLead,
                ingestRecommendedAdsImpressions: this.ingestRecommendedAdsImpressions,
                ingestAdFavorite: this.ingestAdFavorite,

                // agency tracking related callbacks
                ingestAgencyPhoneView: this.ingestAgencyPhoneView,
                ingestAgencyEmailView: this.ingestAgencyEmailView,
                ingestAgencyEmailLeads: this.ingestAgencyEmailLeads,

                // agent tracking related callbacks
                ingestAgentPhoneView: this.ingestAgentPhoneView,
                ingestAgentEmailView: this.ingestAgentEmailView,
                ingestAgentEmailLeads: this.ingestAgentEmailLeads,

                ingestSellerLeads: this.ingestSellerLeads,

                // banner tracking
                ingestBannerView: this.ingestBannerView,
                ingestBannerLead: this.ingestBannerLead,
            } as const;

            return React.createElement(component, {
                ...this.props,
                ref: this.props.forwardRef,
                ovation: callbacks,
            });
        }
    }

    return connect(mapStateToProps, null, null, { forwardRef: true })(
        // eslint-disable-next-line react/no-multi-comp
        // @ts-expect-error - TS2740 - Type '{ forwardRef: ((instance: unknown) => void) | MutableRefObject<unknown> | null; children?: ReactNode; }' is missing the following properties from type 'Readonly<Props>': appType, user, routeName, potwExternalID, and 4 more.
        React.forwardRef((props, ref) => <OvationHOC {...props} forwardRef={ref} />),
    );
};

export default withOvation;
