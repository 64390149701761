import * as React from 'react';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { getDefaultDataLayerVariables } from 'strat/gtm';

export type TrackGallerySwipeCounterType = {
    incrementSwipeCounter: () => void;
    swipeCounter: React.RefObject<number>;
};
const useTrackGallerySwipeCounter = (ad: any): TrackGallerySwipeCounterType => {
    const swipeCounter = React.useRef<number>(0);
    const { externalID } = ad;
    React.useEffect(() => {
        const dataLayerVariables = getDefaultDataLayerVariables();
        return () => {
            if (swipeCounter.current != 0) {
                trigger(Triggers.SWIPE_IMAGE, {
                    view_section: ViewSections.BODY,
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    image_position: swipeCounter.current,
                    ...dataLayerVariables,
                });
                swipeCounter.current = 0;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalID]);

    const incrementSwipeCounter = React.useCallback(() => {
        swipeCounter.current += 1;
    }, []);
    return { swipeCounter, incrementSwipeCounter };
};
export default useTrackGallerySwipeCounter;
