import * as React from 'react';
import settings from '@app/branding/settings';
import { Flex } from 'strat/components';

import { useIsSearchLoading } from 'horizontal/search/hooks';
import useCategoryFilterValue from 'horizontal/search/category/useCategoryFilterValue';
import SearchHeaderFilter from 'horizontal/search/filters/searchHeaderFilter';
import { useShowRemarketedListing } from 'horizontal/search/state';
import { VerticalSeparator } from 'horizontal/components';

import { SortOptionsDropdown } from '../sorting';
import SortOptionCheckbox from '../sorting/sortOptionCheckbox';

import LayoutSwitcher from './layoutSwitcher';
import styles from './styles/hitListHeader.cssm';
import useHeaderCategoryField from './useHeaderCategoryField';

const HitListHeader = () => {
    const activeCategory = useCategoryFilterValue();
    const loading = useIsSearchLoading();
    const headerField = useHeaderCategoryField(activeCategory);
    const showExtraHeaderFilters = headerField !== null;

    const title = headerField?.name;
    const showRemarketedListing = useShowRemarketedListing();
    return (
        <>
            <Flex
                className={styles.container}
                alignCenter
                justifySpaceBetween={
                    showExtraHeaderFilters || CONFIG.runtime.ENABLE_USER_VERIFICATION_SORTING
                }
                justifyRight={
                    !showExtraHeaderFilters && !CONFIG.runtime.ENABLE_USER_VERIFICATION_SORTING
                }
            >
                <Flex alignCenter>
                    {showExtraHeaderFilters ? (
                        <SearchHeaderFilter title={title} field={headerField} loading={loading} />
                    ) : null}
                    {CONFIG.runtime.ENABLE_USER_VERIFICATION_SORTING && (
                        <>
                            {showExtraHeaderFilters ? <VerticalSeparator /> : null}
                            <SortOptionCheckbox />
                        </>
                    )}
                </Flex>
                <Flex className={styles.settings}>
                    {settings.enableLayoutSwitcher && <LayoutSwitcher />}
                    <SortOptionsDropdown />
                </Flex>
            </Flex>
            {!showRemarketedListing && <div className={styles.delimiter} />}
        </>
    );
};

export default HitListHeader;
