import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import { getPaidFirstSlotRadii } from '@sector-labs/strat-horizontal-core';

import type { LiteAd, LiteHierarchicalLocation } from 'horizontal/types';

import type { UpdatedAdResponseProps } from '../types';
import updateResponseIndexToSlotIndex from '../common/updateResponseIndexToSlotIndex';
import { SearchJobName } from '../types';

const isHitInExactLocation = (hit: LiteAd, locationExternalID: string): boolean =>
    (hit.location || []).some((loc) => loc.externalID === locationExternalID);

const groupPageHits = (
    hits: any,
    location: LiteHierarchicalLocation,
    fallbackSlotThresholdIndex: number,
) => {
    // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
    const exactHitList = hits.filter((hit) => isHitInExactLocation(hit, location.externalID));

    const fallbackHitList = hits.filter(
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        (hit) =>
            !isHitInExactLocation(hit, location.externalID) &&
            hit.requestIndex < fallbackSlotThresholdIndex,
    );
    const additionalFallbackHitList = hits.filter(
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        (hit) =>
            !isHitInExactLocation(hit, location.externalID) &&
            hit.requestIndex >= fallbackSlotThresholdIndex,
    );

    return { exactHitList, fallbackHitList, additionalFallbackHitList };
};

const updatedAdsResponse = ({
    searchHandler,
    location,
}: UpdatedAdResponseProps): SearchResponse | null => {
    const job = searchHandler.getJobByJobName(SearchJobName.REGULAR);
    const response = searchHandler.getResponseByJobName(SearchJobName.REGULAR);
    if (!job || !response) {
        return null;
    }
    const updatedResponse = updateResponseIndexToSlotIndex(job, response);
    // @ts-expect-error - TS2341 - Property 'options' is private and only accessible within class 'SearchJob'.
    const sortOption = job.options.sort;

    const fallbackSlotThresholdIndex = 1 + getPaidFirstSlotRadii(sortOption).length;

    const { exactHitList, fallbackHitList, additionalFallbackHitList } = groupPageHits(
        updatedResponse.hits,
        location,
        fallbackSlotThresholdIndex,
    );

    const hits = [
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        ...exactHitList.map((hit) => ({ ...hit, requestIndex: 0 })),
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        ...fallbackHitList.map((hit) => ({ ...hit, requestIndex: 1 })),
        // @ts-expect-error - TS7006 - Parameter 'hit' implicitly has an 'any' type.
        ...additionalFallbackHitList.map((hit) => ({ ...hit, requestIndex: 2 })),
    ];

    return { ...updatedResponse, hits };
};

export default updatedAdsResponse;
