import * as React from 'react';

import PropertyInformationBottomSheet, {
    PropertyInformationBottomSheetContent,
} from './propertyInformationBottomSheet';

type PropertyDetailsBottomSheetProps = {
    name: string;
    title: React.ReactNode;
    description: React.ReactNode;
    renderContent: (props: {
        onClick: (event: React.SyntheticEvent<any>) => void;
    }) => React.ReactElement;
};

const PropertyDetailsBottomSheet = ({
    name,
    title,
    description,
    renderContent,
}: PropertyDetailsBottomSheetProps) => {
    return (
        <PropertyInformationBottomSheet
            name={name}
            renderContent={renderContent}
            renderBottomSheetContent={() => (
                <PropertyInformationBottomSheetContent title={title} text={description} />
            )}
        />
    );
};

export default PropertyDetailsBottomSheet;
