import * as React from 'react';
import { useSearchFacetValues } from '@sector-labs/fe-search-redux/state';
import { dictionaryToFlatArray } from 'strat/util';
import EMPTY_ARRAY from 'strat/empty/array';
import { matchChoiceValue } from 'strat/util';

import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldRole } from 'horizontal/types';

import type { FacetedChoice } from './types';

/**
 * Gets a list of choices for the specified filter, mixed in with facet
 * counts.
 *
 * Facet counts tell the user how many results there would be if the
 * were applied. Choices with 0 results are filtered out so the user
 * doesn't pointlessly uses the filter.
 */
const useFacetedChoices = (
    field: FlatCategoryField,
    { showChoicesWithNoFacets = false }: { showChoicesWithNoFacets?: boolean } = {},
): Array<FacetedChoice<string>> => {
    const facets = useSearchFacetValues(`extraFields.${field.attribute}`);

    // @ts-expect-error - TS2322 - Type 'readonly never[] | { count: null; value: string; slug?: string | undefined; label: string; id: number; parentID: number | null; displayPriority: number; }[] | { count: number; value: string; slug?: string | undefined; label: string; id: number; parentID: number | null; displayPriority: number; }[]' is not assignable to type 'FacetedChoice<string>[]'.
    return React.useMemo(() => {
        if (!field.choices) {
            return EMPTY_ARRAY;
        }

        const flatChoices = dictionaryToFlatArray(field.choices);
        const nonFaceted = field.roles?.includes(CategoryFieldRole.NON_FACETED);
        // Faceting can be disabled in emergency situations to reduce
        // load on the search cluster. In this case we return all
        // choices and hide the counts. The user will have a degraded
        // experience, but it's better than no experience at all.
        if (nonFaceted || CONFIG.runtime.DISABLE_SEARCH_FACETING) {
            return flatChoices.map((choice) => ({
                ...choice,
                count: null,
            }));
        }

        return flatChoices
            .map((choice) => {
                // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Primitive'.
                const facet = facets.find(({ value }) => matchChoiceValue(choice, value));

                return {
                    ...choice,
                    count: facet?.count || 0,
                };
            })
            .filter(({ count }) => (showChoicesWithNoFacets ? true : !!count));
    }, [showChoicesWithNoFacets, field.choices, facets, field.roles]);
};

export default useFacetedChoices;
