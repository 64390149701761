import Category from '@app/branding/category';

import type { NearbyLink } from './types';

export const STUDIO_CATEGORIES = [
    // @ts-ignore
    Category.RESIDENTIAL,
    // @ts-ignore
    Category.APARTMENTS,
    // @ts-ignore
    Category.HOTEL_APARTMENTS,
] as const;

/**
 * Filter the internal links to not contain Studio SearchPages for wrong category.
 * On the backend we compute the internal links without any restrictions but on frontend
 * we use some additional filters for Studio SearchPage. For example if you search by
 * Villas and Studio there will be no listings on SearchPage even if there are listings for
 * this combination of Category and Beds. This is due to `processBedsFilter` which automatically
 * add the filter of Apartments or Hotel-Apartments if Studio is selected.
 */
const hideStudioInternalLinks = (links: Array<NearbyLink>): Array<NearbyLink> =>
    links.filter((link) => {
        if (link.bed !== 0) {
            return true;
        }

        return STUDIO_CATEGORIES.find((category) => category === link.category?.slug);
    });

export default hideStudioInternalLinks;
