import { createSelector } from 'reselect';

import type { GlobalState } from 'horizontal/state';

const selectAgencyProfile = createSelector(
    (state: GlobalState) => state.agencyProfile,
    (agency) => agency.data || null,
);

const selectIsAgencyProfileLoading = createSelector(
    (state: GlobalState) => state.agencyProfile,
    (agency) => agency?.loading || false,
);

export { selectAgencyProfile, selectIsAgencyProfileLoading };
