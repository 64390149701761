import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { Text, Flex } from 'strat/components';
import EliteIcon from '@app/assets/icons/iconElite_noinline.svg';

import styles from './styles/eliteTag.cssm';

type Props = {
    readonly elevated?: boolean;
};
const EliteTag = ({ elevated }: Props) => {
    return (
        <Flex
            alignCenter
            aria-label="Elite"
            className={classNames(styles.container, {
                [styles.elevated]: elevated,
            })}
        >
            <img src={EliteIcon} alt={'logo'} className={styles.logo} />
            <Text.Small bold>
                <Trans>Elite</Trans>
            </Text.Small>
        </Flex>
    );
};

export default EliteTag;
