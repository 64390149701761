import * as React from 'react';

import styles from './styles/sectionHeader.cssm';

/**
 * Properties for {@see SectionHeader}.
 */
type Props = {
    className?: string;
    children?: React.ReactNode | Array<any> | any;
    title?: string;
};

/**
 * Renders the header of a section.
 * It may contain the title and/or other components.
 */
const SectionHeader = ({
    className = styles.header,
    children,
    title,
}: Props): React.ReactElement | null =>
    title || children ? (
        <div className={className}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {children && <div className={styles.content}>{children}</div>}
        </div>
    ) : null;

export default SectionHeader;
