import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import { Flex, Text, Button } from 'strat/components';

import EmptyOrders from '@app/assets/icons/iconEmptyDeliveyOrder_noinline.svg';

import styles from './styles/emptyDeliveryOrders.cssm';

type Props = {
    readonly msg: string;
};

const EmptyDeliveryOrders = ({ msg }: Props) => {
    const i18n = useI18n();
    return (
        <Flex fillContainer alignCenter column className={styles.emptyOrders}>
            <img src={EmptyOrders} alt="no delivery orders" className={styles.image} />
            <Text.Base className={styles.description} bold>
                {msg}
            </Text.Base>
            {!settings.deliverySettings?.disableLinks && (
                <Button
                    secondary
                    onClick={() => window.open(t(i18n)`aboutDeliveryService_url`, '_blank')}
                >
                    <Trans>Know more</Trans>
                </Button>
            )}
        </Flex>
    );
};

export default EmptyDeliveryOrders;
