import type { AdDocument, AdPanorama, AdVideo, MixedAd } from 'horizontal/types';

const extractAdDocuments = (
    ad?: MixedAd | null,
): [AdDocument | null | undefined, Array<AdDocument> | null | undefined, number] => {
    if (!ad) {
        return [null, null, 0];
    }
    const documents = (ad.documents || []).filter((document) => document.isActive);
    const firstDocument = documents[0];
    return [firstDocument, documents, documents.length];
};

const extractAdPanoramas = (
    ad?: MixedAd | null,
): [AdPanorama | null | undefined, Array<AdPanorama> | null | undefined, number] => {
    if (!ad) {
        return [null, null, 0];
    }
    const panoramas = (ad.panoramas || []).sort(
        (firstPanorama, secondPanorama) => firstPanorama.orderIndex - secondPanorama.orderIndex,
    );
    const firstPanorama = panoramas[0];
    return [firstPanorama, panoramas, panoramas.length];
};

const extractAdVideos = (
    ad?: MixedAd | null,
): [AdVideo | null | undefined, Array<AdVideo> | null | undefined, number] => {
    if (!ad) {
        return [null, null, 0];
    }
    const videos = (ad.videos || []).sort(
        (firstVideo, secondVideo) => firstVideo.orderIndex - secondVideo.orderIndex,
    );
    const firstVideo = videos[0];
    return [firstVideo, videos, videos.length];
};

export { extractAdDocuments, extractAdVideos, extractAdPanoramas };
