import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { Dropdown } from 'strat/modal';
import type { ChoiceValue, CategoryChoice } from 'strat/components/types';
import { Input } from 'strat/components';

import { filterCategories } from 'horizontal/util/categoryFilter';
import { DropdownTrigger } from 'horizontal/components';

import styles from './styles/filterableCategoryDropdown.cssm';

type Props<T extends ChoiceValue> = {
    readonly title?: string;
    readonly description?: string;
    readonly name: string;
    readonly errorMessage?: string;
    readonly accepted?: boolean;
    readonly onSelectCategory: (arg1: T) => void;
    readonly value?: string | null;
    readonly choices: Array<CategoryChoice<T>>;
    readonly placeholder?: string;
    readonly stretch?: boolean;
};

const FilterableCategoryDropdown = <TC extends ChoiceValue, OtherProps extends any>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    onSelectCategory,
    value = '',
    choices,
    placeholder,
    stretch,
}: OtherProps & Props<TC>) => {
    const i18n = useI18n();
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const finalPlaceholder = placeholder || t(i18n)`Filter By Category`;
    const filteredCategories = React.useCallback(filterCategories, [choices]);

    const filteredChoices = React.useMemo(() => {
        const query = searchQuery.toLowerCase();
        return filteredCategories(choices, query);
    }, [choices, searchQuery, filteredCategories]);

    const onOptionSelected = React.useCallback(
        (clickedOption) => {
            setIsOpen(false);
            const clickedOptionId = clickedOption !== null ? clickedOption?.value : null;
            onSelectCategory(clickedOptionId);
            setSearchQuery('');
        },
        [setIsOpen, onSelectCategory],
    );

    return (
        <div className={styles.container} aria-label="Filterable input">
            <Dropdown
                triggerIsDismissble
                open={isOpen}
                renderTrigger={() => (
                    <DropdownTrigger
                        title={title}
                        description={description}
                        name={name}
                        errorMessage={errorMessage}
                        accepted={accepted}
                        stretch={stretch}
                        setIsOpen={setIsOpen}
                        value={value}
                        placeholder={value ? `` : finalPlaceholder}
                        isOpen={isOpen}
                        selectedValueLabel={value}
                        noOverflow
                    />
                )}
                onDismissed={() => setIsOpen(false)}
            >
                <div className={styles.categoryDropdownContent}>
                    {choices.length >= 10 && (
                        <div className={styles.searchbox}>
                            <Input
                                onChange={(query) => setSearchQuery(query)}
                                value={searchQuery}
                                placeholder={t(i18n)`Search`}
                                borderless
                            />
                        </div>
                    )}
                    <div
                        onClick={() => onOptionSelected(null)}
                        className={classNames(styles.entryAll, {
                            [styles.selected]: value === null,
                        })}
                        key={null}
                    >
                        {t(i18n)`All`}
                    </div>
                    {filteredChoices.map((choice) => (
                        <React.Fragment key={choice.id}>
                            <div
                                className={classNames(styles.entriesTitle, {
                                    [styles.selected]: value === choice.value,
                                })}
                                onClick={() => onOptionSelected(choice)}
                            >
                                {choice.label}
                            </div>
                            {choice.children?.map((child) =>
                                child.children && child.children.length > 0 ? (
                                    <div key={child.id}>
                                        <div
                                            className={classNames(styles.entriesSubTitle, {
                                                [styles.selected]: value === child.value,
                                            })}
                                            onClick={() => onOptionSelected(child)}
                                        >
                                            {child.label}
                                        </div>
                                        {child.children?.map((grandchild) => (
                                            <React.Fragment key={grandchild.id}>
                                                <div
                                                    onClick={() => onOptionSelected(grandchild)}
                                                    className={classNames(
                                                        styles.entriesSubChoices,
                                                        {
                                                            [styles.selected]:
                                                                value === grandchild.value,
                                                        },
                                                    )}
                                                    key={grandchild.value}
                                                >
                                                    {grandchild.label}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                ) : (
                                    <div key={child.id}>
                                        <div
                                            onClick={() => onOptionSelected(child)}
                                            className={classNames(styles.entry, {
                                                [styles.selected]: value === child.value,
                                            })}
                                            key={child.value}
                                        >
                                            {child.label}
                                        </div>
                                    </div>
                                ),
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Dropdown>
        </div>
    );
};

export default FilterableCategoryDropdown;
