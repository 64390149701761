import PCancelable from 'p-cancelable';
import type { PatternsCollection } from 'react-true-router';
import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import { isCanceled, makeCancelable } from 'strat/util';
import { disableURLUpdater } from 'strat/search/state';

import Page from 'horizontal/pages/page';
import { clearRelatedAds } from 'horizontal/adDetails/relatedAds/state';

import {
    createAdDetailsURL,
    renderPageVariation,
    getAdData,
    fetchRequiredData,
    defaultRoutePatterns,
} from './adDetails';
import type { AdDetailsRouteParams } from './createAdDetailsURL';

class AdDetailsRoute extends Route {
    cancelablePromise: PCancelable<any>;

    constructor(
        routeName: string = RouteNames.AD_DETAILS,
        patterns: PatternsCollection = defaultRoutePatterns,
    ) {
        super(routeName, patterns);
        this.cancelablePromise = makeCancelable(Promise.resolve([]));
    }

    createURL(params: AdDetailsRouteParams): EnhancedLocation {
        return createAdDetailsURL(params);
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const {
            redux: {
                store: { dispatch },
            },
        } = context;

        if (getAdData(context)) {
            context.rendering.renderPage(Page.AD_DETAILS);
        }

        dispatch(clearRelatedAds());
        this.cancelablePromise = makeCancelable(fetchRequiredData(context));
        const promise = this.cancelablePromise.then(
            () => renderPageVariation(context, this.createURL),
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }

    onLeave(
        oldContext: RoutingContextWithMiddlewares,
        _newContext: RoutingContextWithMiddlewares,
    ): void {
        const {
            redux: {
                store: { dispatch },
            },
        } = oldContext;
        if (this.cancelablePromise) {
            this.cancelablePromise.cancel();
        }
        dispatch(disableURLUpdater());
    }
}

export { AdDetailsRoute as AdDetailsRouteClass };

export default new AdDetailsRoute();
