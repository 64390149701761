import { t } from '@lingui/macro';
import * as React from 'react';
import settings from '@app/branding/settings';
import useCanUserSellWithDelivery from '@app/userOrders/hooks/useCanUserSellWithDelivery';
import { useI18n } from 'strat/i18n/language';
import { trigger, ViewSections, Triggers } from 'strat/gtm';
import { useIsProfolioUser, useIsStratCreditUser } from 'strat/user';

import { UserOrdersSection } from './types';

type SectionLink = {
    name: string;
    section: Values<typeof UserOrdersSection>;
    event?: () => void;
};

const useSectionLinks = (): Array<SectionLink> => {
    const i18n = useI18n();
    const canUserSellWithDelivery = useCanUserSellWithDelivery();
    const isProfolioUser = useIsProfolioUser();
    const isStratCreditUser = useIsStratCreditUser();
    const showOrdersAndPayments = !isProfolioUser && !isStratCreditUser;

    return React.useMemo(() => {
        const sectionLinks: Array<SectionLink> = [];
        if (CONFIG.build.SHOW_PAYMENTS_TAB && showOrdersAndPayments) {
            sectionLinks.push({
                name: t(i18n)`Payments`,
                section: UserOrdersSection.PAYMENTS,
            });
        }

        if (CONFIG.build.SHOW_PAYMENT_ORDERS_TAB && showOrdersAndPayments) {
            sectionLinks.push({
                name: t(i18n)`Payment Orders`,
                section: UserOrdersSection.PAYMENT_ORDERS,
            });
        }

        if (showOrdersAndPayments) {
            sectionLinks.push(
                {
                    name: t(i18n)`Bought Packages`,
                    section: UserOrdersSection.ORDERS,
                    event: () =>
                        trigger(Triggers.VIEW_BUY_HISTORY, { view_section: ViewSections.BODY }),
                },
                {
                    name: t(i18n)`Billing`,
                    section: UserOrdersSection.BILLING,
                    event: () =>
                        trigger(Triggers.CLICK_BILLING, { view_section: ViewSections.BODY }),
                },
            );
        }

        if (settings.deliverySettings && !isProfolioUser) {
            sectionLinks.push({
                name: t(i18n)`Addresses`,
                section: UserOrdersSection.Addresses,
            });
        }

        if (settings.deliverySettings) {
            sectionLinks.push({
                name: t(i18n)`Buying Orders`,
                section: UserOrdersSection.BUYING,
            });
        }

        if (settings.deliverySettings && canUserSellWithDelivery) {
            sectionLinks.push({
                name: t(i18n)`Selling Orders`,
                section: UserOrdersSection.SELLING,
            });
        }

        return sectionLinks;
    }, [i18n, canUserSellWithDelivery, isProfolioUser, showOrdersAndPayments]);
};

export default useSectionLinks;
