import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Trans } from '@lingui/macro';

import { type ProductPackageOffer, PackageType } from 'horizontal/types';
import { FeatureAdExample } from 'horizontal/packages/components';
import { trackViewExampleClick } from 'horizontal/gtm';
import { BusinessPackageSubsection } from 'horizontal/packages/types';
import usePackagesIcons from '@app/businessPackages/selectMultiplePackagesSection/usePackagesIcons';

import { BusinessPackageExampleType } from '../types';
import BusinessPackageExampleContext from '../businessPackageExampleContext';
import { useLocalizedDays } from '../hooks';

import Section from './sectionRedesign';

const FeatureAdSection = ({ packageOffers }: { packageOffers: Array<ProductPackageOffer> }) => {
    const i18n = useI18n();
    const iconSrc = usePackagesIcons({ type: PackageType.FEATURED });
    const localizedDays = useLocalizedDays();

    const [activeExample, setActiveExample] = React.useContext(BusinessPackageExampleContext);
    const openExample = React.useCallback(() => {
        trackViewExampleClick();
        setActiveExample(BusinessPackageExampleType.FEATURED);
    }, [setActiveExample]);
    const closeExample = React.useCallback(() => setActiveExample(null), [setActiveExample]);

    const details = [t(i18n)`Get noticed with 'Featured' tag among the top`];

    const getSubsectionsByDuration = React.useMemo(() => {
        packageOffers.sort(
            (a, b) =>
                (a.productPackage.productItems[0].duration || 0) -
                (b.productPackage.productItems[0].duration || 0),
        );

        let duration =
            packageOffers[0]?.productPackage.productItems[0] &&
            packageOffers[0]?.productPackage.productItems[0].duration;
        let currentSubsection: Array<ProductPackageOffer> = [];
        const subsections: Array<BusinessPackageSubsection> = [];

        packageOffers.forEach((offer) => {
            if (offer.productPackage.productItems[0].duration !== duration) {
                subsections.push({
                    title: (
                        <Trans>
                            Feature Service valid for <b>{localizedDays(duration)}</b>
                        </Trans>
                    ),
                    packageOffers: currentSubsection,
                });
                currentSubsection = [];
                duration = offer.productPackage.productItems[0].duration;
            }
            currentSubsection.push(offer);
        });
        if (currentSubsection) {
            subsections.push({
                title: (
                    <Trans>
                        Feature Service valid for <b>{localizedDays(duration)}</b>
                    </Trans>
                ),
                packageOffers: currentSubsection,
            });
        }

        return subsections;
    }, [packageOffers, localizedDays]);

    return (
        <>
            {activeExample === BusinessPackageExampleType.FEATURED && (
                <FeatureAdExample onClick={closeExample} />
            )}
            <Section
                title={t(i18n)`Feature Service`}
                details={details}
                showExampleButton
                exampleText={t(i18n)`Featured Ad`}
                onClickSeeExample={openExample}
                subsections={getSubsectionsByDuration}
                renderIcon={() => iconSrc && <img src={iconSrc} alt="Get Featured" />}
                disclaimer={t(i18n)`Can only be used on active ad`}
            />
        </>
    );
};

export default FeatureAdSection;
