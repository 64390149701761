/**
 * Enumeration of available triggers.
 */
export default Object.freeze({
    CLICK_PROJECT: 'click_project',
    VIEW_ALL: 'view_all',
    PROJECT_PUSHER_CLICK: 'project_pusher_click',
    FEATURED_AGENCIES: 'featured_agencies',
    SEE_FEATURED_AGENT: 'see_featured_agent',
    TITANIUM_AGENCIES: 'titanium_agencies',
    SIMILAR_PROPERTIES: 'similar_properties_impressions',
    RECOMMENDED_PROPERTIES: 'recommended_properties_impressions',

    HOME_VIEW: 'home_view',
    PROPERTY_VIEW: 'property_view',
    PROPERTY_SEARCH: 'property_search',
    PROPERTY_SEARCH_INTENT: 'property_search_intent',
    FILTER_CHANGE: 'click_filter',
    FILTER_APPLY: 'apply_filter',
    PROPERTY_SETUP_ALERT: 'property_setup_alert',
    PROPERTY_SAVE_SEARCH: 'property_save_search',
    CLICK_SAVE_SEARCH_BUTTON: 'save_search_click',
    CLICK_FIND: 'click_find',

    NEW_PROJECTS_INTENT: 'new_projects_intent',
    CHANGE_VIEW: 'change_view',
    PROJECT_PAGE_VIEW: 'page_view',
    PROJECT_PAGE_VIEW_DETAILS: 'view_project_details',

    DEAL_OF_THE_WEEK: 'deal_of_the_week',
    PROPERTY_OF_THE_WEEK: 'property_of_the_week',
    LEGACY_BANNERS_IMPRESSION: 'legacy_banners_impression',
    OFFICIAL_PARTNERS_IMPRESSION: 'official_partners_impression',
    SMART_BANNER_INSTALL_CLICK: 'smart_banner_install_click',
    STATIC_BANNER_INSTALL_CLICK: 'static_banner_install_click',
    SMART_BANNER_VIEW: 'smart_banner_view',
    APP_PITCH: 'app_pitch',

    FAVORITE_TOGGLE: 'favourite_toggle',
    FAVORITE_HEADER: 'favourite_header',
    SHARE_PROPERTY: 'share_property',
    SHARE_TRANSACTIONS: 'share_transactions',
    CLICK_BUY_WITH_DELIVERY: 'buy_with_delivery',
    CLICK_CHECK_DELIVERY: 'check_delivery',
    CLICK_PLACE_ORDER: 'place_order',

    VIEW_AGENCY_PROPERTIES: 'view_agency_properties',
    SIMILAR_PROPERTIES_TOGGLE: 'similar_properties_toggle',

    VIEW_SAVED_SEARCHES: 'view_saved_searches',
    VIEW_FAVORITES: 'view_favourites',
    VIEW_FAVOURITES_SAVED_SEARCHES: 'view_favourites_saved_searches',
    VIEW_PUBLIC_PROFILE: 'view_public_profile',
    VIEW_WALLET: 'view_wallet',

    PHONE_VIEW_ATTEMPT: 'phone_view_attempt',
    PHONE_VIEW: 'phone_view',
    PHONE_LEAD: 'phone_lead',

    SMS_ATTEMPT: 'sms_attempt',
    SMS_LEAD: 'sms_lead',

    WHATSAPP_LEAD: 'whatsapp_lead',
    WHATSAPP_RENT: 'WhatsApp_Rent',
    WHATSAPP_SALE: 'WhatsApp_Sale',
    WHATSAPP_VIEW: 'whatsapp_view',

    EMAIL_VIEW: 'email_view',
    EMAIL_LEAD: 'email_lead',

    OFFER_VIEW: 'offer_view',

    CHAT_ATTEMPT: 'chat_attempt',
    CHAT_LEAD: 'chat_lead',
    CHAT_INIT: 'chat_initiated',
    CHAT_MACRO_LEAD: 'chat_macro_lead',
    CHAT_RENT: 'Chat_Rent',
    CHAT_SALE: 'Chat_Sale',
    CHAT_CLOSED: 'chat_closed',
    CHAT_MESSAGE_SENT: 'chat_message_sent',
    CHAT_MESSAGE_RECEIVED: 'chat_message_received',
    SHOW_CHAT: 'show_chat',
    OPEN_CHAT: 'open_chat',
    FIRST_CHAT_INTENT: 'first_chat_intent',
    FIRST_CHAT_START: 'first_chat_start',
    CONTINUE_CHAT: 'continue_chat',
    CHAT_REPLY: 'chat_reply',
    CHAT_CLOSE_NO_ACTION: 'chat_close_no_action',
    CHAT_FILTER: 'chat_filter',
    CHAT_UNREAD_MESSAGES: 'chat_unread_message',
    SELLER_SENT_MESSAGE: 'seller_sent_message',
    SELLER_FIRST_RESPONSE: 'seller_first_response',
    BUYER_SENT_MESSAGE: 'buyer_sent_message',

    AGENCY_HOME_VIEW: 'agency_home_view',
    AGENCY_SEARCH_VIEW: 'agency_search_view',
    AGENCY_DETAILS_VIEW: 'agency_details_view',
    AGENT_DETAILS_VIEW: 'agent_details_view',
    AGENT_REVIEW_CLICK: 'write_agent_review',
    AGENT_REVIEW_EDIT: 'edit_review',
    AGENT_REVIEW_DELETE: 'delete_review',
    AGENT_REVIEW_MANAGER_OPEN: 'open_reviews',
    AGENT_REVIEW_SUBMIT: 'submit_review',
    AGENT_SEARCH_VIEW: 'agent_search_view',

    FLOOR_PLANS_HOME_VIEW: 'floor_plans_home_view',
    FLOOR_PLANS_LOCATION_VIEW: 'floor_plans_location_view',
    FLOOR_PLANS_DETAILS_VIEW: 'floor_plans_details_view',
    FLOOR_PLAN_PROPERTY_PAGE_VIEW: 'floorplan_view',

    VIRTUAL_TOUR_GALLERY_OPEN: 'virtual_tour_open',
    GALLERY_SWIPE: 'gallery_swipe',

    MAP_INTERACTION: 'map_interaction',
    MAP_VIEW: 'map_view',
    PLACES_VIEW: 'places_view',
    DISTANCE_VIEW: 'distance_view',
    MAP_PLACES: 'map_places',
    MAP_NAVIGATE: 'map_navigate',

    SELECT_CLUSTER_MULTIPLE_BUILDINGS: 'select_cluster_multiple_buildings',
    SELECT_CLUSTER_SINGLE_BUILDING: 'select_cluster_single_building',

    LISTING_CLICK: 'listing_click',
    CLICK_POTW: 'click_potw',
    INSIDE_IMAGE_VIEW: 'inside_image_view',
    PANORAMA_VIEW: 'panorma_view',
    VIDEO_PLAY: 'video_play',
    VIDEO_PLAY_ERROR: 'video_play_error',
    CARD_LIST_VIEW_TOGGLE: 'card_list_view_toggle',
    CLICK_TOP_CROSSLINK: 'click_top_crosslink',
    CLICK_SEO_CROSSLINK: 'seo_crosslinks_click',
    CLICK_PROPERTY_DETAIL_CHIP: 'property_detail_chip',
    CLICK_BREADCRUMB: 'breadcrumb_click',
    CLICK_HEADER: 'header_click',

    SEARCH_HISTORY_CLICKED: 'search_history_clicked',

    SETTINGS_CHANGE: 'settings_change',
    SORTING_CHANGE: 'sorting_change',
    FURNISHING_STATUS_CHANGE: 'furnishing_status_change',
    COMPLETION_STATUS_CHANGE: 'completion_status_change',
    SALE_TYPE_CHANGE: 'sale_type_change',

    NOTIFICATIONS_PROMPT_POSTPONED: 'notifications_prompt_postponed',
    NOTIFICATIONS_PROMPT_CLOSED: 'notifications_prompt_closed',
    NOTIFICATIONS_PROMPT_ACCEPTED: 'notifications_prompt_accepted',
    NOTIFICATIONS_PROMPT_IMPRESSION: 'notifications_prompt_impression',

    ENQUIRE_NOW_CLICK: 'enquire_now_click',
    FIT_OUT_SIZE: 'fit_out_size',
    FIT_OUT_CURRENT_STATUS: 'fit_out_current_status',
    FIT_OUT_INCLUDES_FURNISHINGS: 'fit_out_includes_furnishings',
    FIT_OUT_EDIT_VALUES: 'fit_out_edit_values',

    SHARE_BUTTON: 'button',
    SHARE_PROFILE_LINK: 'share_profile',

    MAP_TOGGLE_ON: 'map_toggle_on',
    MAP_TOGGLE_OFF: 'map_toggle_off',
    BOTTOM_SHEET: 'bottom_sheet',

    READ_DESCRIPTION: 'read_description',
    VIEW_AMENITIES: 'view_amenities',
    EDIT_MORTGAGE: 'edit_mortgage',
    VIEW_SALE_TRENDS: 'view_sale_trends',
    REPORT_ABUSE: 'report_abuse',

    ACTIVE_LISTINGS_TOGGLE: 'active_listings_toggle',

    CLICK_LOGIN: 'click_login',
    LOGIN_OPEN: 'login_open',
    LOGIN_ATTEMPT: 'login_attempt',
    LOGIN_SUCCESSFUL: 'login_successful',
    LOGIN_SUCCESS: 'login_success',
    LOGIN_FAILED: 'login_failed',
    LOGIN_ABORTED: 'login_aborted',

    NAVBAR_LOGIN_CLICK: 'navbar_login_click',
    SIDE_MENU_LOGIN_CLICK: 'side_menu_login_click',

    LOGOUT: 'logout',

    REGISTER_OPEN: 'register_open',
    REGISTER_ATTEMPT: 'register_attempt',
    REGISTER_FAILED: 'register_failed',
    REGISTER_SUCCESSFUL: 'register_successful',
    REGISTER_EMAIL: 'register_email',
    REGISTER_PHONE: 'register_phone',
    REGISTER_APPLE: 'register_apple',
    REGISTER_GOOGLE: 'register_google',
    REGISTER_FACEBOOK: 'register_facebook',

    CLICK_FORGOT_PASSWORD: 'click_forgot_password',
    CLICK_SIGNUP: 'click_signup',
    SIGNUP_ATTEMPT: 'signup_attempt',
    SIGNUP_SUCCESSFUL: 'signup_successful',
    SIGNUP_FAILED: 'signup_failed',
    SIGNUP_OPEN: 'signup_open',
    CLICK_SUBSCRIBE_MARKETING_EMAILS: 'click_subscribe_marketing_emails',

    SET_NEW_PASSWORD: 'set_new_password',
    FORGOT_PASSWORD_OPEN: 'forgot_password_open',
    FORGOT_PASSWORD_ATTEMPT: 'forgot_password_attempt',
    FORGOT_PASSWORD_SUCCESSFUL: 'forgot_password_successful',
    FORGOT_PASSWORD_FAILED: 'forgot_password_failed',

    REQUEST_VIDEO: 'request_video',

    REQUEST_FLOORPLAN: 'request_floorplan',
    REQUEST_FLOORPLAN_EMAIL: 'request_floorplan_email_lead',
    REQUEST_FLOORPLAN_PHONE: 'request_floorplan_phone_view',
    REQUEST_FLOOR_PLAN_IMPRESSION: 'request_floorplan_impression',

    OPEN_SIDE_MENU: 'open_side_menu',

    MORTGAGE_ENGAGEMENT: 'mortgage_calculator_engagement',
    MORTGAGE_APPLY_FOR_LOAN: 'mortgage_calculator_apply',
    MORTGAGE_SUBMIT_LOAN_APPLICATION: 'mortgage_calculator_submit',
    MORTGAGE_EDIT_VALUES: 'mortgage_calculator_open',
    MORTGAGE_VIEW_BANKS: 'view_banks',

    HOMEPAGE_TRUCHECK: 'homepage_trucheck',
    HOMEPAGE_MAP: 'homepage_map',
    HOMEPAGE_COMMUTE: 'homepage_commute',
    HOMEPAGE_TRANSACTIONS: 'homepage_transactions',
    HOMEPAGE_AGENT: 'homepage_agent',

    HOMEPAGE_NEWS: 'homepage_news',
    HOMEPAGE_FLOORPLAN: 'homepage_floorplan',
    HOMEPAGE_VIDEOTOUR: 'homepage_videotour',
    HOMEPAGE_VIRTUALTOUR: 'homepage_virtualtour',
    HOMEPAGE_MORTGAGE: 'homepage_mortgage',
    AGENCY_CLICK: 'agency_click',
    FEATURED_AGENCIES_IMPRESSION: 'featured_agencies_impression',
    SWIPE: 'swipe',

    PHONE_VERIFICATION_START: 'phone_verification_start',
    PHONE_VERIFICATION_FINISH: 'phone_verification_done',

    CLICK_INFO: 'click_info',

    GUIDE_LINK: 'guide_link',

    ANCHOR_LINK: 'anchor_link',

    NBS_POI_SEARCH_START: 'poi_search_start',
    NBS_POI_SEARCH_PICKED: 'poi_search_picked',
    NBS_POI_MAP_PICKED: 'poi_map_pick',
    NBS_START: 'nbs_start',
    NBS_PIN: 'nbs_pin',

    AD_DETAIL_VIEW: 'ad_view',
    VIEW_MY_ADS: 'view_my_ads',
    SELECT_MULTIPLE_PACKAGES_VIEW: 'select_multiple_packages_view',
    SELECT_SINGLE_PACKAGE_VIEW: 'select_single_package_view',
    SELECT_PAYMENT_VIEW: 'select_payment_view',

    CLICK_SELL: 'click_sell',
    CLICK_CATEGORY: 'click_category',
    CLICK_AD: 'click_ad',

    CLICK_CURRENT_LOCATION: 'select_loc_current',
    CLICK_LOCATION: 'select_loc_list',
    TYPE_LOCATION_BOX: 'type_loc_box',
    FREE_TEXT_SEARCH: 'search_bar',
    FREE_TEXT_SEARCH_HIT_CLICK: 'search_bar_hit_click',
    CLICK_CATEGORY_TREE: 'click_cat_tree',
    CLICK_POPULAR_CATEGORY: 'popular_categories',
    CLICK_AD_CATEGORY: 'select_ad_category',
    CHANGE_CATEGORY_FILTER: 'filter_category',

    EDIT_PROFILE_SAVE_CHANGES: 'save_changes',
    OPEN_NOTIFICATIONS: 'open_notifications',
    CLICK_BUY_PACKAGES: 'view_packages',
    VIEW_CART: 'view_cart',
    CLICK_EXAMPLE: 'click_example',
    SELECT_PACKAGE: 'select_package',

    CLICK_HELP: 'click_help',
    CLICK_SETTINGS: 'click_settings',

    CLICK_VIEW_MORE_SEARCH_RECOMMENDATIONS: 'view_more',
    CLICK_VERTICAL: 'click_vertical',

    CHANGE_LAYOUT: 'click_view_change',
    CLICK_LOAD_MORE: 'click_load_more',

    CLICK_IMAGE: 'click_image',

    CLICK_CLOSE_BANNER: 'click_close_banner',
    CLICK_CONTINUE_BROWSER: 'click_continue_browser',
    SWIPE_IMAGE: 'swipe_image',

    AD_CREATION_SELECT_CATEGORY: 'click_category',
    AD_CREATION_FAILED: 'ad_failed',
    AD_CREATION_FILL_FORM: 'add_listing_info_view',
    CLICK_SELL_FASTER: 'sell_faster',
    CLICK_PREVIEW_AD: 'preview_ad',

    PAY_PACKAGE: 'pay_package',
    SELECT_PAYMENT_METHOD: 'payment_channel',
    PAYMENT_INITIATED: 'payment_initiated',
    PAYMENT_FAILED: 'payment_failed',
    PAYMENT_ABORT: 'payment_abort',
    ABORT_PACKAGE_SELECT: 'abort_package_select',
    ABORT_CART: 'abort_cart',
    PAYMENT_SUCCESS: 'payment_success',

    TRU_VALUE_INTENT: 'truvalue_intent',
    TRANSACTIONS_INTENT: 'transactions_search_intent',
    GET_TRANSACTIONS: 'get_transactions',
    UPDATE_TRANSACTIONS: 'update_transactions',
    TRU_VALUE_RESULTS: 'truvalue_show_results',
    TRU_VALUE_CHANGE_RESULTS: 'truvalue_change_results',
    TRU_VALUE_NO_RESULTS: 'truvalue_no_results',
    TRU_VALUE_PAGE_VIEW: 'truvalue_page_view',
    TRU_VALUE_SHOW_DETAILS: 'truvalue_show_details',

    TRANSACTIONS_EMPTY: 'transactions_empty',
    TRANSACTIONS_SUGGESTION_1: 'transactions_suggestion_1',
    TRANSACTIONS_SUGGESTION_2: 'transactions_suggestion_2',

    PASSWORD_CHANGE: 'password_change',
    PASSWORD_CHANGE_SUCCESSFUL: 'password_change_successful',
    PASSWORD_CHANGE_FAILED: 'password_change_failed',

    LOGIN_DIALOG_OPEN: 'login_dialog_open',
    LOGIN_FACEBOOK: 'login_facebook',
    LOGIN_GOOGLE: 'login_google',
    LOGIN_PHONE: 'login_mobile',
    LOGIN_EMAIL: 'login_email',
    OTP_REQUEST_SENT: 'otp_request_sent',
    OTP_REQUEST_RESENT: 'otp_request_resent',
    EMAIL_LINK_SENT: 'email_link_sent',
    EMAIL_LINK_RESENT: 'email_link_resent',
    UNLINK_SOCIAL_PROVIDER: 'unlink_social_provider',
    LINK_SOCIAL_ACCOUNT: 'link_social_account',
    UNLINK_SOCIAL_ACCOUNT: 'unlink_social_account',
    TYPE_OTP: 'type_otp',

    MAKE_AN_OFFER: 'make_an_offer',
    MAKE_AN_OFFER_SENT: 'make_an_offer_sent',
    PUBLISH_AD: 'post_now',

    NUDGE_BANNER_VIEW: 'nudge_banner_view',

    PRICE_HISTORY_INTERACTION: 'price_history_interaction',
    RENTAL_YIELD_INTERACTION: 'rental_yield_interaction',

    CUMULATIVE_LAYOUT_SHIFT: 'cumulative_layout_shift',

    FOOTER_INSTALL_CLICK: 'footer_install_click',

    SUGGESTED_HISTORY: 'suggested_history',
    RELATED_ADS: 'related_ads',
    MANAGE_AD: 'manage_ad',
    MY_ADS_LOADED: 'my_ads_loaded',

    HIDE_NUMBER: 'hide_number',
    BLOCK_CHAT: 'block_chat',

    PROPERTY_NAVIGATION: 'property_navigation',

    OPEN_ACCOUNT: 'open_account',

    STOP_OFFERS: 'stop_comms_offers',
    STOP_RECOMMEND: 'stop_comms_recommend',
    STOP_SAFETY: 'stop_comms_safety',
    APPLY_PACKAGES: 'apply',
    AD_SUCCESS: 'ad_success',

    STORY_IMPRESSION: 'story_impression',
    STORY_VIEW: 'story_view',

    CLICK_INSTALL_BANNER: 'click_install_banner',
    DISMISS_INSTALL_BANNER: 'dismiss_install_banner',

    TRU_VALUE_SURVEY_IMPRESSION: 'truvalue_survey_impression',
    TRU_VALUE_ROLE: 'truvalue_role',
    TRU_VALUE_SUBMIT: 'truvalue_submit',

    CLICK_CLOSE_CHAT: 'click_close_chat',
    CLICK_DOWNLOAD_IOS: 'click_download_ios',
    CLICK_DOWNLOAD_ANDROID: 'click_download_android',
    CLICK_DOWNLOAD_APPGALLERY: 'click_download_appgallery',
    CLICK_TRENDING_SEARCHES: 'click_trending_searches',
    CLICK_ABOUT_EMPG: 'click_about_empg',
    CLICK_OLX_BLOG: 'click_olx_blog',
    CLICK_CONTACT_US: 'click_contact_us',
    CLICK_OLX_BUSINESS: 'click_olx_business',
    CLICK_CONTACT_OUR_SALES: 'click_contact_our_sales',
    CLICK_SITEMAP: 'click_sitemap',
    CLICK_TERMOFUSE: 'click_termofuse',
    CLICK_PRIVACYPOLICY: 'click_privacypolicy',
    AD_FILTER: 'ad_filter',
    CLICK_ADD_QUANTITY: 'click_add_quantity',
    CLICK_SUBSTRACT_QUANTITY: 'click_substract_quantity',
    VIEW_BUY_HISTORY: 'view_buy_history',
    CLICK_BILLING: 'click_billing',
    CLICK_FILTER: 'click_filter',
    CLICK_POPULAR_SEARCHES: 'click_popular_searches',
    CLICK_CHANGE_CATEGORY: 'click_change_category',
    CLICK_UPLOAD_PHOTO: 'click_upload_photo',
    CLICK_LIST_TOGGLE: 'click_list_toggle',
    CLICK_MAP_TOGGLE: 'click_map_toggle',

    EXPLORE_TOOLS: 'explore_tools',

    FILTER_RESET: 'filter_reset',

    QUICK_FILTERS_CLICK: 'quick_filters_click',
    QUICK_FILTERS_APPLY: 'quick_filters_apply',
    QUICK_FILTERS_RESET: 'quick_filters_reset',
    QUICK_FILTERS_CLEAR: 'quick_filters_clear',
    ALL_FILTERS_CLICK: 'all_filters_click',

    SMART_SHARE_OPEN_LINK: 'open_link',
    SMART_SHARE_MODAL: 'smartshare_modal',
    SMART_SHARE_MODAL_IMPRESSION: 'smartshare_modal_impression',
    SMART_SHARE_ADD_PROPERTY_CLICK: 'add_property',
    SMART_SHARE_RECOMMENDATIONS_ADDED: 'property_list',
    SMART_SHARE_COPY_LINK: 'copy_link',
    SMART_SHARE_RECOMMENDATION_IMPRESSION: 'like_property',
    SMART_SHARE_HIDE_RECOMMENDATION: 'hide_property',
    SMART_SHARE_PAGE_VIEW: 'page_view',
    SMART_SHARE_LOGIN: 'smartshare_login',

    COMMUTE_SURVEY_IMPRESSION: 'nbs_survey_impression',
    COMMUTE_SURVEY_SUBMIT: 'nbs_survey_submit',

    BROWSE_PROPERTIES_LINK_CLICK: 'browse_properties_link_click',

    DUBAI_TRANSACTIONS_NAVIGATION_HISTORY: 'navigate_history',
    DUBAI_TRANSACTIONS_CLICK_BREAKDOWN: 'click_breakdown',
    DISPLAY_INFO: 'display_info',
    DUBAI_TRANSACTION_SORTING_CHANGE: 'sorting_change',
    MAP_TYPE_TOGGLE: 'map_type_toggle',

    FREE_AD_CONFIRM: 'free_ad_confirm',
    FREE_AD_CANCEL: 'free_ad_cancel',
    FREE_AD_PAYMENT: 'free_ad_payment',

    OPEN_TRANSACTIONS: 'open_transactions',
    DUBAI_TRANSACTIONS_CLICK_MAP: 'click_dt_map',

    LANGUAGE_SWITCH: 'language_switch',
    LANGUAGE_MODAL_IMPRESSION: 'language_modal_impression',
    SELECT_LANGUAGE_MODAL: 'select_language_modal',

    TRANSLATE_DESCRIPTION: 'translate_description',

    NEW_SEARCH: 'new_search',
    EDIT_SEARCH: 'edit_search',

    SCROLL_25: 'scroll_25',
    SCROLL_50: 'scroll_50',
    SCROLL_90: 'scroll_90',

    INTERNAL_LINK_REDIRECT: 'internal_link_redirect',
    HOME_PAGE_REDIRECT: 'home_page_redirect',

    SEARCH_TRIGGERING_EVENT: 'search_triggering_event',

    VERIFIED_ADS_SORT: 'show_verified',

    DLD_LINK: 'dld_link',

    TRU_ESTIMATE_PAGE_VIEW: 'page_view',
    TRU_ESTIMATE_DETAIL_CHANGE: 'truestimate_detail_change',
    TRU_ESTIMATE_CONFIRM: 'truestimate_confirm',
    TRU_ESTIMATE_GET: 'truestimate_get',
    TRU_ESTIMATE_OPEN_REPORT: 'truestimate_open_report',
    TRU_ESTIMATE_VIEW_MORE: 'view_more',
    TRU_ESTIMATE_VIEW_LESS: 'view_less',
    TRU_ESTIMATE_VIEW_ALL: 'view_all',
    TRU_ESTIMATE_CONTACT: 'truestimate_contact',
    TRU_ESTIMATE_LEAD: 'truestimate_lead',
    TRU_ESTIMATE_AGENT_DETAILS_VIEW: 'agent_details_view',
    TRU_ESTIMATE_SUBMIT_FEEDBACK: 'submit_feedback',
    TRU_ESTIMATE_INTENT: 'truestimate_intent',
    TRU_ESTIMATE_HOMEPAGE: 'homepage_truestimate',
    TRU_ESTIMATE_DOWNLOAD_REPORT: 'download_report',
    TRU_ESTIMATE_EXPORT_SUCCESSFUL: 'export_successful',
    TRU_ESTIMATE_EXPORT_FAILED: 'export_failed',
    TRU_ESTIMATE_SHARE_REPORT_ATTEMPT: 'share_report_attempt',
    TRU_ESTIMATE_SHARE_REPORT_SUCCESS: 'share_report_success',
    TRU_ESTIMATE_SHARE_REPORT_WHATSAPP: 'share_report_whatsapp',
    TRU_ESTIMATE_FEEDBACK: 'report_feedback',
    TRU_ESTIMATE_FEEDBACK_SUBMIT: 'submit_feedback',
    TRUESTIMATE_LISTING_CLICK: 'property_card_click',
    TRU_ESTIMATE_GET_ESTIMATE: 'get_estimate',
    TRU_ESTIMATE_EXPIRED_REPORT: 'expired_report',
    TRU_ESTIMATE_GET_REPORT: 'get_report',
    TRU_ESTIMATE_SEARCH_AGENTS: 'search_agents',
    TRU_ESTIMATE_SECTION_IMPRESSION: 'section_impression',
    TRU_ESTIMATE_INLINE_IMPRESSION: 'inline_impression',
    TRU_ESTIMATE_ADD_DETAILS: 'add_details',

    EXPIRED_BANNER_CLICK: 'expired_banner_click',

    CLICK_CUSTOMER_SUPPORT: 'customer_support',

    CLICK_TOPUP_CAMPAIGN_ACCEPT: 'click_topup_campaign_accept',
    CLICK_TOPUP_CAMPAIGN_REJECT: 'click_topup_campaign_reject',

    DEVELOPER_PROPERTY_IMPRESION: 'developer_pusher_impression',
});
