import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Image } from 'strat/util';
import { Flex, Text } from 'strat/components';

import EmptyInvoicePNG from '@app/assets/images/emptyInvoice.png';
import EmptyInvoiceWEBP from '@app/assets/images/emptyInvoice.webp';

import styles from './styles/emptyPaymentOrders.cssm';

const EmptyPaymentOrders = () => (
    <Flex column alignCenter fillContainer className={styles.container}>
        <Image
            image={{
                source: EmptyInvoicePNG,
                sourceWEBP: EmptyInvoiceWEBP,
            }}
            className={styles.image}
        />
        <Text.Base bold className={styles.title}>
            <Trans>You don&apos;t have payments</Trans>
        </Text.Base>
        <Text.Base className={styles.description}>
            <Trans>Haven&apos;t you tried our featured ads yet? Increase your views!</Trans>
        </Text.Base>
    </Flex>
);

export default EmptyPaymentOrders;
