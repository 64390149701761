import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import { Checkbox, Text, LoadingSpinner } from 'strat/components';

import styles from './styles/reportBlockingCheckbox.cssm';

type Props = {
    readonly isUserBlocked?: boolean;
    readonly isUserBlockedLoading?: boolean;
    readonly shouldBlockUser?: boolean;
    readonly setShouldBlockUser?: (arg1: boolean) => void;
};

const ReportBlockingCheckbox = ({
    isUserBlocked,
    setShouldBlockUser,
    shouldBlockUser,
    isUserBlockedLoading,
}: Props) => {
    const i18n = useI18n();

    if (!setShouldBlockUser) {
        return null;
    }
    if (isUserBlockedLoading) {
        return <LoadingSpinner dark className={styles.loadingSpinner} />;
    }

    if (isUserBlocked) {
        return (
            <Text.Base gray className={styles.chatBlocked}>
                <Trans>Chatting with this user is blocked</Trans>
            </Text.Base>
        );
    }
    return (
        <div className={styles.reportCheckbox}>
            <Checkbox
                name={'blockUser'}
                label={t(i18n)`I also want to block this user`}
                onChange={() => setShouldBlockUser(!shouldBlockUser)}
                checked={shouldBlockUser}
            />
        </div>
    );
};

export default ReportBlockingCheckbox;
