import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import type { FullAd } from 'horizontal/types';
import { AdUndertakingTerms } from 'horizontal/adUndertakingTerms';

import { ApplyAdLimitPackagePageHeader } from './applyAdLimitPackagePageDesktop';
import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/adUndertakingSuccessPageDesktop.cssm';

type Props = {
    readonly ad: FullAd;
};

const AdUndertakingSuccessPageDesktop = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
        </>
    ),
    body: ({ ad }: Props) => (
        <Flex column stretchHeight>
            <ApplyAdLimitPackagePageHeader />
            <PageWidthWrapper className={styles.pageWidthWrapper}>
                <AdUndertakingTerms ad={ad} />
            </PageWidthWrapper>
        </Flex>
    ),
} as const;

export default AdUndertakingSuccessPageDesktop;
