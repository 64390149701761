import * as React from 'react';

import styles from './styles/headingTitle.cssm';

type Props = {
    readonly children: React.ReactNode;
};

const HeadingTitle = ({ children }: Props) => <h1 className={styles.title}>{children}</h1>;

export default HeadingTitle;
