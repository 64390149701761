import * as React from 'react';
import { PageWidthWrapper } from 'strat/generic';

import { BusinessPackagesHeader, SelectMultiplePackagesForm } from 'horizontal/businessPackages';
import type { ProductPackageOffer } from 'horizontal/types';
import { GTMSelectMultiplePackagesDataLayer } from 'horizontal/gtm/components';

import PrivateNonIndexableHead from './privateNonIndexableHead';
import styles from './styles/selectMultiplePackagesPage.cssm';

type Props = {
    productOffers?: ProductPackageOffer[];
    categoryID: string;
    locationID: string;
};

const SelectMultiplePackagesPageDesktop = {
    head: ({ productOffers, categoryID, locationID }: Props) => (
        <>
            <PrivateNonIndexableHead />
            <GTMSelectMultiplePackagesDataLayer
                productOffers={productOffers}
                categoryID={categoryID}
                locationID={locationID}
            />
        </>
    ),
    body: ({ productOffers }: Props) => (
        <>
            <BusinessPackagesHeader withCategory />
            <PageWidthWrapper className={styles.wrapper}>
                <SelectMultiplePackagesForm productOffers={productOffers} />
            </PageWidthWrapper>
        </>
    ),
} as const;

export default SelectMultiplePackagesPageDesktop;
