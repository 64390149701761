import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';

type UseGetGeoLocation = {
    getGeoLocation: () => void;
    coordinates:
        | {
              latitude: number;
              longitude: number;
          }
        | null
        | undefined;
    error: string | null | undefined;
    loading: boolean;
};

const useGetGeoLocation = (): UseGetGeoLocation => {
    const i18n = useI18n();

    const errorMessage = t(i18n)`Location blocked. Check browser/phone settings`;

    const [{ loading, coordinates, error }, setState] = React.useState({
        coordinates: null,
        error: null,
        loading: false,
    });

    const onSuccess = React.useCallback((position) => {
        setState((previousState) => ({
            ...previousState,
            loading: false,
            coordinates: position.coords,
        }));
    }, []);

    const onError = React.useCallback(() => {
        // @ts-expect-error - TS2345 - Argument of type '(previousState: { coordinates: null; error: null; loading: boolean; }) => { loading: false; error: string; coordinates: null; }' is not assignable to parameter of type 'SetStateAction<{ coordinates: null; error: null; loading: boolean; }>'.
        setState((previousState) => ({
            ...previousState,
            loading: false,
            error: errorMessage,
        }));
    }, [errorMessage]);

    const getGeoLocation = React.useCallback(() => {
        if (loading) {
            return;
        }

        if (!navigator.geolocation) {
            // @ts-expect-error - TS2345 - Argument of type '(previousState: { coordinates: null; error: null; loading: boolean; }) => { error: string; coordinates: null; loading: boolean; }' is not assignable to parameter of type 'SetStateAction<{ coordinates: null; error: null; loading: boolean; }>'.
            setState((previousState) => ({
                ...previousState,
                error: errorMessage,
            }));
            return;
        }

        setState((previousState) => ({
            ...previousState,
            loading: true,
        }));

        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, [loading, onSuccess, onError, errorMessage]);

    return {
        getGeoLocation,
        coordinates,
        error,
        loading,
    };
};

export default useGetGeoLocation;
