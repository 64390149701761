import { catchCanceled } from 'strat/util';
import { CancelableRoute } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import Page from 'horizontal/pages/page';
import { PaymentOrderStatus, PaymentStatus } from 'horizontal/payment';
import { getStratAPI } from 'horizontal/api';
import type { CheckoutCreditCard, PaymentOrder } from 'horizontal/payment/types';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type SaveCardRouteParams = {
    readonly orderExternalID: string;
    readonly status?: Values<typeof PaymentStatus>;
    readonly instrumentID?: string;
    readonly paymentID?: string;
};

type QueryParams = {
    status?: string;
    instrumentID?: string;
    paymentID?: string;
};

type CustomerInstrumentsResponseType = {
    data: Array<CheckoutCreditCard>;
};

type OrderResponseType = {
    data: {
        status: Values<typeof PaymentStatus>;
        data: Array<PaymentOrder>;
    };
    status: number;
};

class SaveCardRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.SAVE_CARD, [
            [
                '^/payments/packages/saveCard/',
                {
                    name: 'orderExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { orderExternalID, status, instrumentID, paymentID }: SaveCardRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams: QueryParams = {};
        if (status) {
            queryParams['status'] = status;
        }
        if (instrumentID) {
            queryParams['instrumentID'] = instrumentID;
        }
        if (paymentID) {
            queryParams['paymentID'] = paymentID;
        }
        const pathname = `/payments/packages/saveCard/${orderExternalID}`;
        return { pathname, search: queryParams };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { orderExternalID, instrumentID },
            },
        } = context;

        const dataPromise = this.cancelable(
            Promise.all([
                getStratAPI(getState()).getOrder(orderExternalID),
                getStratAPI(getState()).getCustomerInstruments(instrumentID),
            ]),
        );

        context.promise.wait(
            dataPromise
                .then(
                    ([orderResponse, customerInstrumentsResponse]: [
                        OrderResponseType,
                        CustomerInstrumentsResponseType,
                    ]) => {
                        const status = orderResponse.status;
                        const paymentStatus = orderResponse?.data.status;
                        const customerInstrumentsData = customerInstrumentsResponse?.data || [];
                        const orderData = orderResponse?.data;

                        if (status !== 200 && paymentStatus !== PaymentOrderStatus.ACTIVE) {
                            context.http.status(404);
                            context.rendering.renderPage(Page.NOT_FOUND);
                            return;
                        }

                        if (customerInstrumentsData.length === 0) {
                            context.rendering.renderPage(Page.PAYMENT_SUCCESS, { ...orderData });
                            return;
                        }

                        context.rendering.renderPage(Page.SAVE_CARD, {
                            ...orderData,
                            orderExternalID,
                            instrument: customerInstrumentsData[0],
                        });
                    },
                )
                .catch(catchCanceled),
        );
    }
}

export default new SaveCardRoute();
