import { t } from '@lingui/macro';
import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';
import EMPTY_OBJECT from 'strat/empty/object';
import { AgencyDetailState } from 'strat/agency/state/agency';
import { selectIsMobile } from 'strat/platform/selectors';
import { selectI18n } from 'strat/i18n/language';

export const TabNames = Object.freeze({
    PROFILE: 'profile',
    ACTIVE_LISTINGS: 'active_listings',
    TEAM: 'team',
});
/**
 * Selects the whole agency object from the state
 */
const selectAgency = (state: AgencyDetailState) => (state.agency || {}).data || EMPTY_OBJECT;

const selectAgencyLoading = (state: AgencyDetailState) => state.agency?.loading;

export const selectAgencyActiveTab = (state: AgencyDetailState) => state.agency.activeTab;

export const selectAgencyDetailTabs = createSelector(
    selectI18n,
    selectIsMobile,
    selectAgency,
    (i18n, isMobile, agency) => {
        const tabs = new Map();
        if (isMobile) {
            tabs.set(TabNames.PROFILE, { title: t(i18n)`Profile` });
        }
        tabs.set(TabNames.ACTIVE_LISTINGS, { title: t(i18n)`Active listings` });
        if (agency?.agents?.length) {
            tabs.set(TabNames.TEAM, { title: t(i18n)`Agents` });
        }
        return tabs;
    },
);

export const selectDefaultTabName = createSelector(
    selectAgencyActiveTab,
    selectAgencyDetailTabs,
    (activeTab, tabs) => {
        return activeTab && CONFIG.build.STRAT_ENABLE_LEADERBOARD
            ? activeTab
            : tabs.keys().next().value;
    },
);

export const selectHitsPerPage = createSelector(
    (state: GlobalState) => state.algolia.settings || EMPTY_OBJECT,
    (settings) => Number(settings.hitsPerPage) || 0,
);

export { selectAgency, selectAgencyLoading };
