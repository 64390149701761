import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';
import { isValidPhoneNumber } from 'strat/i18n/phone';

export const FieldAttribute = Object.freeze({
    PHONE_NUMBER: 'phoneNumber',
});

export type Field = {
    readonly type: string;
    readonly attribute: string;
    readonly title: (arg1: I18n) => string;
    readonly unit?: string;
    readonly initial: string;
};

export const MobileWalletPaymentFields: Array<Field> = [
    {
        type: 'text',
        attribute: FieldAttribute.PHONE_NUMBER,
        title: (i18n: I18n) => t(i18n)`Phone Number`,
        unit: settings.defaultPhoneNumber.prefix,
        initial: '',
    },
];

const Errors = {
    invalidPhone: (i18n: I18n) => t(i18n)`Please enter a valid phone number`,
} as const;

export const makeValidationSchema = (i18n: I18n) =>
    Yup.object().shape({
        phoneNumber: Yup.string()
            .required(Errors.invalidPhone(i18n))
            // @ts-expect-error - TS7006 - Parameter 'phone' implicitly has an 'any' type.
            .test('validPhone', Errors.invalidPhone(i18n), (phone) =>
                isValidPhoneNumber(
                    (phone || '').startsWith('+')
                        ? phone
                        : `${settings.defaultPhoneNumber.prefix}${phone}`,
                ),
            ),
    });

export const generateInitialValues = () =>
    MobileWalletPaymentFields.reduce<Record<string, any>>((acc, field) => {
        acc[field.attribute] = field.initial;
        return acc;
    }, {});
