import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';
import { GlobalState } from '@app/state';

import type { Ad, ProductPurchase } from 'horizontal/types';
import { ProductType } from 'horizontal/types';
import { getProductPackageType } from 'horizontal/packages/utils';

const selectAdFromProps = (state: GlobalState, { ad }: { ad: Ad | null | undefined }) => ad;

const selectProductPurchases = (state: GlobalState): Array<ProductPurchase> =>
    state.productPurchases?.data || (EMPTY_ARRAY as ProductPurchase[]);

const selectProductPurchasesLoading = (state: any): boolean =>
    state.productPurchases?.loading ?? false;

const selectConsumableUserPackages = createSelector(
    selectProductPurchases,
    (packages: Array<ProductPurchase>) =>
        packages.filter((p) => {
            const startTimestamp = new Date(p.startDate).getTime();
            const endTimestamp = new Date(p.expiresAt).getTime();
            const now = new Date();
            // @ts-expect-error - TS2365 - Operator '<=' cannot be applied to types 'number' and 'Date'. | TS2365 - Operator '<' cannot be applied to types 'Date' and 'number'.
            const isActive = startTimestamp <= now && now < endTimestamp;

            return isActive && p.usageLimit - p.usages > 0;
        }),
);

const selectConsumableLimitPackages = createSelector(
    selectConsumableUserPackages,
    (packages: Array<ProductPurchase>) =>
        packages.filter((p) =>
            p.productPackageOffer.productPackage.productItems.some(
                (items) => items.type === ProductType.LIMIT_BUMP,
            ),
        ),
);

const selectConsumableLimitPackagesByAd = createSelector(
    selectConsumableLimitPackages,
    selectAdFromProps,
    (packages: Array<ProductPurchase>, ad?: Ad | null) => {
        if (!packages || !ad) {
            return [];
        }

        return packages.filter((p) =>
            ad.category.some(
                (category) => category.externalID === p.productPackageOffer?.categoryID,
            ),
        );
    },
);

const selectProductPurchaseById = createSelector(
    selectProductPurchases,
    (state: GlobalState, id: string) => id,
    (productPurchases: Array<ProductPurchase>, id: string) =>
        productPurchases.find((productPurchase) => productPurchase.id === +id),
);

const selectProductPurchasePackageType = createSelector(
    selectProductPurchases,
    (state: GlobalState, id: string) => id,
    (productPurchases: Array<ProductPurchase>, id: string) => {
        const productPurchase = productPurchases.find(
            (productPurchase) => productPurchase.id === +id,
        );
        return getProductPackageType(productPurchase?.productPackageOffer.productPackage);
    },
);

export {
    selectProductPurchases,
    selectProductPurchasesLoading,
    selectConsumableLimitPackagesByAd,
    selectProductPurchaseById,
    selectProductPurchasePackageType,
};
