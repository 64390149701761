import * as React from 'react';
import { thumbnailURL } from 'strat/image';
import { SocialImageTags } from 'strat/social/tags';

import { useAd } from 'horizontal/ad/state';
import { useAdPhotos } from 'horizontal/ad';

const AdImageTags = () => {
    const ad = useAd();
    const coverPhoto = useAdPhotos(ad)[0];

    if (!coverPhoto) {
        return null;
    }

    return <SocialImageTags image={thumbnailURL(coverPhoto.id, 'jpeg')} />;
};

export default AdImageTags;
