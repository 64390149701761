import { isAdEditor } from 'horizontal/util';
import type { Ad } from 'horizontal/types';
import { AdState } from 'horizontal/types';
import isAdInactive from '@app/adDetails/isAdInactive';

const isAdAccessible = (ad: Ad | null | undefined, userExternalID: string): boolean => {
    if (!ad) {
        return false;
    }

    if (ad.state === AdState.ACTIVE || isAdInactive(ad)) {
        return true;
    }

    if (
        [
            AdState.PENDING,
            AdState.REJECTED,
            AdState.EXPIRED,
            AdState.LIMITED,
            AdState.DELETED,
            AdState.SOLD,
            AdState.SOFT_REJECTED,
        ].includes(ad.state)
    ) {
        return isAdEditor(ad, userExternalID);
    }

    return false;
};

export default isAdAccessible;
