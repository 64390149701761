import * as React from 'react';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectActiveSearchBackend,
    setContent,
    setSettings,
} from '@sector-labs/fe-search-redux/state';
import type { SearchRequestFacetsOption } from '@sector-labs/fe-search-redux/backend';
import { FilterValues } from 'strat/search';
import { selectLanguage } from 'strat/i18n/language';
import brandingSettings from '@app/branding/settings';
import { determineAdsIndexName } from 'strat/search/indexNames';

import { useFlatCategoryFields } from 'horizontal/categoryFields';
import type {
    FlatCategoryField,
    LiteCategory,
    LiteHierarchicalLocation,
    Location,
} from 'horizontal/types';
import {
    createCategoryFieldsFacetingConfig,
    createCommonFieldsFacetingConfig,
} from 'horizontal/search/execution';

const createSearchFacets = (
    categoryFields: Array<FlatCategoryField>,
    locationHierarchy: Array<LiteHierarchicalLocation>,
) => {
    return [
        ...createCommonFieldsFacetingConfig(locationHierarchy),
        ...createCategoryFieldsFacetingConfig(categoryFields),
    ];
};

const createSearchSettings = (facets: Array<SearchRequestFacetsOption>) => {
    return {
        hitsPerPage: 0,
        maxValuesPerFacet: 1000,
        facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING ? facets : [],
    };
};

const createSearchFilters = (category: LiteCategory | null | undefined, location: Location) => {
    const filters = new FilterCollection();
    if (category) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.category.attribute,
                value: category,
            }),
        );
    }

    if (location) {
        filters.refine(
            new ObjectExactFilter({
                attribute: FilterValues.location.attribute,
                value: location,
            }),
        );
    }

    return filters;
};

const useFetchFacetsForAdCreation = (
    activeCategory?: LiteCategory | null,
    activeLocation?: Location | null,
) => {
    const backend = useSelector(selectActiveSearchBackend);
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const categoryFields = useFlatCategoryFields(activeCategory?.id);
    const location = activeLocation || brandingSettings.topLevelLocation;

    React.useEffect(() => {
        // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData[] | LiteHierarchicalLocation[] | undefined' is not assignable to parameter of type 'LiteHierarchicalLocation[]'.
        const facets = createSearchFacets(categoryFields, location.hierarchy);
        // @ts-expect-error - TS2345 - Argument of type 'SearchRequestFacet[]' is not assignable to parameter of type 'SearchRequestFacetsOption[]'.
        const settings = createSearchSettings(facets);
        // @ts-expect-error - TS2345 - Argument of type 'LocationNodeData | Location' is not assignable to parameter of type 'Location'.
        const filters = createSearchFilters(activeCategory, location);
        const indexName = determineAdsIndexName({ language });
        // @ts-expect-error - TS2345 - Argument of type '{ hitsPerPage: number; maxValuesPerFacet: number; facets: SearchRequestFacetsOption[]; }' is not assignable to parameter of type 'Partial<SearchJobOptions>'.
        const job = new SearchJob(indexName, filters, settings);
        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend });

        service.fetchJob(job).then((response) => {
            // @ts-expect-error - TS2341 - Property 'options' is private and only accessible within class 'SearchJob'.
            dispatch(setSettings(job.options));
            dispatch(setContent(response));
        });
    }, [backend, language, activeCategory, dispatch, categoryFields, location]);
};

export default useFetchFacetsForAdCreation;
