import React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

const RequestDeliveryDescription = () => {
    return (
        <Text.Large>
            <Trans>This delivery will be fulfilled by OLX Move.</Trans>
        </Text.Large>
    );
};

export default RequestDeliveryDescription;
