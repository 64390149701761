import memoize from 'fast-memoize';

import type { Category } from 'horizontal/types';

import { findCategoryNodeByID } from './findCategoryNodeByID';
import { getAllDescendantsIDs } from './getAllDescendantsIDs';

export const getCategoryIDsDescendants = memoize(
    (
        categoryIDs: Array<number> | null | undefined,
        categories: Array<Category>,
        includeSelf = true,
    ): Array<number> => {
        let result: Array<number> = [];

        (categoryIDs || []).forEach((id) => {
            let childrenIDs: Array<never> | Array<number> = [];
            for (let i = 0; i < categories.length; i++) {
                const categoryNode = findCategoryNodeByID(categories[i], id);
                if (categoryNode) {
                    childrenIDs = getAllDescendantsIDs(categoryNode, [], includeSelf);
                    break;
                }
            }
            result = result.concat(childrenIDs);
        });

        return result;
    },
);
