import { LiteAd } from 'horizontal/types';
import { ViewSections } from 'strat/gtm';

export type VerifiedProfileBadgeConfigType = {
    show: boolean;
    includeInBadgesList: boolean;
};

const getVerifiedProfileBadgeConfig = (
    ad: LiteAd,
    isMobile: boolean,
    isListLayout: boolean,
    viewSection: Values<typeof ViewSections>,
): VerifiedProfileBadgeConfigType => {
    const isExcludedSections = [
        ViewSections.SELLER_PROFILE,
        ViewSections.AGENCY_PROFILE,
        ViewSections.HOME_PAGE,
    ].includes(viewSection);
    return {
        show: !isExcludedSections && !!ad.isSellerVerified,
        includeInBadgesList: false,
    };
};

export default getVerifiedProfileBadgeConfig;
