import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex } from 'strat/components';
import { selectIsMobile } from 'strat/platform/selectors';

import { AgencyType, Location } from 'horizontal/types';

import styles from './styles/filters.cssm';
import LocationFilter from './locationFilter';
import RealEstateAgencyFilters from './realEstateAgencyFilters';
import RealEstateAgencyFiltersCompact from './compact/realEstateAgencyFilters';

type Props = {
    location: Location | null;
    onLocationSelected: (selectedLocation: Location | null) => void;
    isAgency?: boolean;
    agencyType?: Values<typeof AgencyType>;
};

const Filters = ({ isAgency, agencyType, location, onLocationSelected }: Props) => {
    const i18n = useI18n();

    const isRealEstateAgency = isAgency && agencyType === AgencyType.REAL_ESTATE;
    const isMobile = useSelector(selectIsMobile);

    if (isRealEstateAgency) {
        if (isMobile) {
            return (
                <div className={styles.container}>
                    <RealEstateAgencyFiltersCompact location={location} />
                </div>
            );
        } else {
            return (
                <div className={styles.container}>
                    <RealEstateAgencyFilters location={location} />
                </div>
            );
        }
    }

    return (
        <div className={styles.container}>
            <Text.Large bold>{t(i18n)`Filter by:`}</Text.Large>
            <Flex className={styles.filtersContainer}>
                <div className={styles.locationFilter}>
                    <LocationFilter value={location} onLocationSelected={onLocationSelected} />
                </div>
            </Flex>
        </div>
    );
};

export default Filters;
