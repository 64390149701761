import areaGuideFetcher from '@app/branding/areaGuideFetcher';
import FetcherFactory from 'strat/fetcher';
import Purpose from 'strat/purpose';
import { PropertyCompletionStatus } from 'strat/property/types';

export type AreaGuideParams = {
    isLongtailPage: boolean;
    page: number;
    beds: Array<any>;
    completionStatus: Values<typeof PropertyCompletionStatus>;
    purpose: Values<typeof Purpose>;
    location: string;
    category: string;
    customSearchPageSlug: string;
};

/**
 * Factory for creating action creators and reducers
 * for fetching area guides from the back-end.
 */
const areaGuideFactory = new FetcherFactory('areaGuide', areaGuideFetcher, {
    successCodes: [200, 404],
    catchServerErrors: true,
    // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
    getParams: (params, getState) => {
        const state = getState();
        return {
            isLongtailPage:
                state.search.pageParameters.isBedroomPage || state.search.pageParameters.isAreaPage,
        };
    },
});

/**
 * Reducer for area guides.
 */
const areaGuideReducer = areaGuideFactory.reducer();

/**
 * Fetches an area guide from the back-end.
 */
const fetchAreaGuide = areaGuideFactory.creator();

/**
 * Enables fetching of area guides.
 */
const enableAreaGuide = () => ({
    type: areaGuideFactory.actions.enable,
});

/**
 * Disables and blocks fetching of area guides.
 */
const disableAreaGuide = () => ({
    type: areaGuideFactory.actions.disable,
});

export { fetchAreaGuide, enableAreaGuide, disableAreaGuide };

export default areaGuideReducer;
