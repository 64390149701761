import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import type { I18n } from '@lingui/core';

import { withI18n } from 'strat/i18n/language/withI18n';
import { ThumbnailSizes, PropertyThumbnailImage } from 'strat/image';
import type { AnyAdData } from 'strat/types';
import Purpose from 'strat/purpose/purpose';

type Props = {
    size: Values<typeof ThumbnailSizes>;
    property: AnyAdData;
    className?: string;
    i18n: I18n;
    lazyLoad?: boolean;
    imageID?: number;
    onLoad?: () => void;
};

// Return 'Apartment' or 'Flat' based on the externalId's parity to improve SEO searches
const getApartmentsOrFlatBasedOnExternalId = (property: AnyAdData, i18n: I18n) => {
    const number = Number(property.externalID);
    const isEven = number && number % 2 === 0;
    return isEven ? t(i18n)`Apartment` : t(i18n)`Flat`;
};

const getPropertyType = (property: AnyAdData, i18n: I18n) => {
    if (property.category.length <= 0) {
        return '';
    }
    const category = property.category[property.category.length - 1];

    if (category.slug !== 'apartments') {
        return category.nameSingular;
    }
    return Number(property.rooms) >= 1
        ? getApartmentsOrFlatBasedOnExternalId(property, i18n)
        : t(i18n)`Studio`;
};

const getPurposeName = (property: AnyAdData, i18n: I18n) =>
    Purpose.isForSale(property.purpose) ? t(i18n)`for Sale` : t(i18n)`for Rent`;

const getLocation = (property: AnyAdData, i18n: I18n) => {
    if (property.location.length <= 1) {
        return '';
    }
    const separator = `${t(i18n)`,`} `;

    return property.location
        .slice(1, 3)
        .reverse()
        .map((l) => l.name)
        .join(separator);
};

const getCoverPhotoTitle = (property: AnyAdData, i18n: I18n) => {
    const existingCoverPhotoTitle =
        property.coverPhoto && property.coverPhoto.title
            ? property.coverPhoto.title
            : property.title;
    const nrOfBedRooms = Number(property.rooms)
        ? t(i18n)`${plural(Number(property.rooms), {
              one: '# Bedroom',
              two: '# Bedroom',
              few: '# Bedroom',
              many: '# Bedroom',
              other: '# Bedroom',
          })}`
        : '';
    const propertyType = getPropertyType(property, i18n);
    const purpose = getPurposeName(property, i18n);
    const location = getLocation(property, i18n);

    return t(
        i18n,
    )`${nrOfBedRooms} ${propertyType} ${purpose} in ${location} - ${existingCoverPhotoTitle}`;
};

const ThumbnailImageWithTitle = ({ lazyLoad = true, ...props }: Props): React.ReactElement => (
    <PropertyThumbnailImage
        size={props.size}
        property={props.property}
        className={props.className}
        title={getCoverPhotoTitle(props.property, props.i18n)}
        lazyLoad={lazyLoad}
        imageID={props.imageID}
        onLoad={props.onLoad}
    />
);

export { ThumbnailImageWithTitle };
export default withI18n()(ThumbnailImageWithTitle);
