import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/chipList.cssm';

export type ChipData<T> = {
    value: T;
    name: string | number;
};

export type Props<T> = {
    chipData: ChipData<T>;
    onChipClick: (value: T) => void;
    isSelected: boolean;
    selectedChipIcon?: React.ReactNode;
    chipClassName?: string;
    selectedChipClassName: string;
    isDisabled?: boolean;
    disabledChipClassName: string;
};

const Chip = <T,>({
    chipData,
    isSelected,
    onChipClick,
    chipClassName = styles.chip,
    selectedChipClassName = styles.selected,
    selectedChipIcon,
    isDisabled,
    disabledChipClassName = styles.disabled,
}: Props<T>) => {
    return (
        <li
            key={`${chipData.name}${isSelected ? '-selected' : ''}`}
            role="button"
            onClick={() => onChipClick(chipData.value)}
            className={classNames(chipClassName, {
                [selectedChipClassName]: isSelected,
                [disabledChipClassName]: isDisabled,
            })}
        >
            {isSelected && selectedChipIcon}
            {chipData.name}
        </li>
    );
};

export default Chip;
