import * as React from 'react';
import { useSelector } from 'react-redux';

import { useCategoryFilterValue } from 'horizontal/search/category';
import { findDeepList } from 'horizontal/util/findDeep';

import { selectCategories } from './selectors';

const useActiveCategoryHierarchy = () => {
    const activeCategory = useCategoryFilterValue();
    const categories = useSelector(selectCategories);

    return React.useMemo(
        () => findDeepList(categories, (category) => category.id === activeCategory?.id, true),
        [categories, activeCategory],
    );
};

export default useActiveCategoryHierarchy;
