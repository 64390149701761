export const priceFilterMaximumOptions = [
    10000, 50000, 100000, 500000, 1000000, 5000000, 10000000, 50000000, 100000000, 500000000,
];

export const priceFilterMinimumOptions = [
    1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000, 10000000,
];

export const areaFilterMaximumOptions = [1000, 2000, 3000, 4000, 5000];

export const areaFilterMinimumOptions = [40, 50, 100, 150, 200];

export const bedsFilterValues = {
    attribute: 'beds',
    choices: () => [...['1', '2', '3', '4', '5', '6'].map((value) => ({ value, name: value }))],
    default: null,
} as const;

export const bathsFilterValues = {
    attribute: 'baths',
    choices: () => [...['1', '2', '3', '4', '5'].map((value) => ({ value, name: value }))],
    default: null,
} as const;
