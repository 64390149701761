import React from 'react';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import { SellCarSuccessMessage } from 'horizontal/verticals/cars';

const CashMyCarSuccessPageDesktop = {
    head: () => <title>Vertical Cars</title>,
    body: ({ requestID }: { requestID: string }) => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <SellCarSuccessMessage requestID={requestID} />
        </Flex>
    ),
};

export default CashMyCarSuccessPageDesktop;
