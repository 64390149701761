import * as React from 'react';
import { Trans } from '@lingui/macro';
import { ViewSections } from 'strat/gtm';
import { Flex, Text, Dialog } from 'strat/components';

import type { ChatAd } from 'horizontal/chat/types';
import SMSIcon from 'horizontal/assets/icons/sms.svg';
import PhoneIcon from 'horizontal/assets/icons/phone.svg';
import { PhoneNumberLinkType } from 'horizontal/contact';
import { useOnCallButtonClick, useOnSMSButtonClick } from 'horizontal/adDetails';

import styles from './styles/phoneNumberDialog.cssm';

type Props = {
    readonly ad: ChatAd | null | undefined; // required for lead tracking,
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly phoneNumber?: string;
    readonly linkType?: Values<typeof PhoneNumberLinkType>;
    readonly trackCallLead: (viewSection: Values<typeof ViewSections>) => void;
    readonly trackSMSLead: (viewSection: Values<typeof ViewSections>) => Promise<string>;
};

const PhoneNumberDialog = ({
    visible,
    onVisibilityChanged,
    ad,
    phoneNumber,
    linkType,
    trackCallLead,
    trackSMSLead,
}: Props) => {
    const viewSection = ViewSections.HEADER;
    const onSMSButtonClick = useOnSMSButtonClick({ ad, viewSection, trackSMSLead });
    const onCallButtonClick = useOnCallButtonClick({ viewSection, trackCallLead });

    const onClick = React.useCallback(() => {
        if (phoneNumber && linkType) {
            if (linkType === PhoneNumberLinkType.CALL) {
                onCallButtonClick(phoneNumber);
            } else {
                onSMSButtonClick(phoneNumber);
            }
        }
    }, [phoneNumber, linkType, onCallButtonClick, onSMSButtonClick]);

    if (!phoneNumber || !linkType) {
        return null;
    }

    return (
        <Dialog
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            withCloseButton
        >
            <Flex column alignCenter justifyCenter>
                <Text.XXLarge bold>
                    <Trans>Phone</Trans>
                </Text.XXLarge>
                <Flex className={styles.phoneNumberContainer}>
                    {linkType === PhoneNumberLinkType.SMS ? (
                        <SMSIcon className={styles.icon} />
                    ) : (
                        <PhoneIcon className={styles.icon} />
                    )}
                    <Text.Base bold underlined onClick={onClick} className={styles.phoneNumber}>
                        {phoneNumber}
                    </Text.Base>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default PhoneNumberDialog;
