import type { RoutingContextWithMiddlewares } from 'strat/app';
import { fetchCategoryFields } from 'strat/categoryFields/state';

import { fetchAd } from 'horizontal/ad/state';
import type { Ad } from 'horizontal/types';
import { fetchUserBuyingList } from 'horizontal/userOrders/buyingOrders';
import { fetchSellerProfile } from 'horizontal/seller/state';

import getAdExternalID from './getAdExternalID';
import getAdData from './getAdData';

const fetchRequiredData = (context: RoutingContextWithMiddlewares) => {
    const {
        redux: {
            store: { dispatch },
        },
        match: {
            params: { externalID, hash },
        },
    } = context;
    const adExternalID = getAdExternalID(externalID, hash);

    const dataPromise = Promise.all([
        dispatch(fetchSellerProfile({ adExternalID })),
        dispatch(fetchAd({ externalID: adExternalID })),
        dispatch(fetchUserBuyingList()), // todo fetch later when we know from ad whether it has delivery, don't bother fetching this otherwise
    ]);

    return dataPromise.then(() => {
        const ad: Ad = getAdData(context);
        const categoryExternalID = ad?.category?.slice(-1)[0]?.externalID;
        if (categoryExternalID) {
            return dispatch(
                fetchCategoryFields({
                    categoryExternalIDs: [categoryExternalID],
                    includeChildCategories: true,
                    lite: false,
                    groupChoicesBySection: true,
                }),
            );
        }
        return Promise.resolve();
    });
};

export default fetchRequiredData;
