import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { CollapsibleContent } from 'strat/generic';
import { useI18n } from 'strat/i18n/language';
import { scrollToElementTop } from 'strat/navigation/scroll';
import { PopularPropertyContainer, PopularPropertySection, Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';

import { PopularPropertySectionWrapper, sectionHasMore } from './popularProperty';
import styles from './styles/popularProperty.cssm';

const PopularPropertyWrapper = ({
    section,
    className,
    containerIndex,
    hardLinks,
}: {
    // @ts-expect-error - TS2749 - 'PopularPropertySection' refers to a value, but is being used as a type here. Did you mean 'typeof PopularPropertySection'?
    section: PopularPropertySection;
    className?: string;
    containerIndex?: number;
    hardLinks?: boolean;
}) => {
    const i18n = useI18n();
    const isPopularPropertySearchesTab = containerIndex === 2;
    const hasMore = sectionHasMore(section);
    return (
        <div key={containerIndex}>
            {isPopularPropertySearchesTab && section.title && (
                <h3 className={styles.headingTitle}>
                    {isPopularPropertySearchesTab && section.title}
                </h3>
            )}
            <CollapsibleContent
                height={180} /* 5 links of 28px each and the title of 40px (24px + 16px margin)  */
                textMore={t(i18n)`View all`}
                textLess={t(i18n)`View less`}
                className={styles.collapsibleContainer}
                key={containerIndex}
                buttonClassName={classNames(styles.button, styles.buttonHr)}
                arrowClassName={styles.arrow}
                scrollToElementTop={(node) => scrollToElementTop(node)}
                showMoreLessButton={!isPopularPropertySearchesTab}
                hrSeparatorClassName={
                    !isPopularPropertySearchesTab && !hasMore ? styles.hrSeparatorOnly : undefined
                }
            >
                <PopularPropertyContainer
                    className={styles.popularPropertyContainer}
                    key={containerIndex}
                    titleClassName={styles.containerTitle}
                >
                    {/* @ts-expect-error - TS7006 - Parameter 'subSection' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type. */}
                    {section.content.map((subSection, index) => {
                        let subSectionTitle;
                        if (subSection.title) {
                            subSectionTitle = !subSection.title.linkContent ? (
                                subSection.title
                            ) : (
                                <Link
                                    key={subSection.title.title}
                                    scrollUp
                                    route={RouteNames.SEARCH}
                                    className={[styles.link, className].join(' ')}
                                    params={{
                                        category: {
                                            slug: subSection.title.linkContent.category,
                                        },
                                    }}
                                    hard={hardLinks}
                                    title={
                                        subSection.title.customTitleContent ||
                                        subSection.title.title
                                    }
                                >
                                    {subSection.title.title}
                                </Link>
                            );
                        }
                        return (
                            <PopularPropertySectionWrapper
                                subSectionTitle={subSectionTitle}
                                key={index}
                                subSection={subSection}
                                // @ts-expect-error - TS2322 - Type '{ subSectionTitle: any; key: any; subSection: any; isPopularPropertySearchesTab: boolean; }' is not assignable to type 'IntrinsicAttributes & { subSectionTitle: Link; subSection: PopularPropertySubsection; className?: string | undefined; sectionTitleStyle?: string | undefined; }'.
                                isPopularPropertySearchesTab={isPopularPropertySearchesTab}
                            />
                        );
                    })}
                </PopularPropertyContainer>
            </CollapsibleContent>
        </div>
    );
};

export { PopularPropertyWrapper };
