import React from 'react';
import { useSelector } from 'react-redux';

import { selectCategories } from 'horizontal/categories';

import { getDeepCategoryVars } from './utils';

const useCategoryVars = (category?: string | null) => {
    const categories = useSelector(selectCategories);
    return React.useMemo(() => getDeepCategoryVars(categories, category), [categories, category]);
};

export default useCategoryVars;
