import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';
import MessageBox, {
    ArrowDirection,
    ArrowPosition,
    MessageBoxType,
} from 'strat/components/messageBox';

import type { ActionButton } from 'horizontal/chat/types';

import ActionButtons from '../actionButtons';
import { getPriceOfferType, PriceOfferType } from '../price';
import PriceLabel from '../priceLabel';

import styles from './styles/buyerMadeOfferSeller.cssm';

type Props = {
    readonly actionButtons: Array<ActionButton>;
    readonly adPrice: number;
    readonly lastOfferPrice: number;
};

const getMessageBox = (adPrice: number, lastOfferPrice: number): React.ReactElement => {
    const messageBoxProps = {
        centeredText: true,
        arrowDirection: ArrowDirection.DOWN,
        arrowPosition: ArrowPosition.CENTER,
        renderContent: () => <Trans>Respond quickly</Trans>,
    } as const;

    return getPriceOfferType(adPrice, lastOfferPrice) === PriceOfferType.VERY_GOOD ? (
        <MessageBox
            renderTitle={() => <Trans>Very good offer received!</Trans>}
            type={MessageBoxType.ACCEPTED}
            {...messageBoxProps}
        />
    ) : (
        <MessageBox
            renderTitle={() => <Trans>Good offer received!</Trans>}
            type={MessageBoxType.NEUTRAL}
            {...messageBoxProps}
        />
    );
};

const BuyerMadeOffer = ({ adPrice, lastOfferPrice, actionButtons }: Props) => {
    const messageBox = React.useMemo(() => {
        return getMessageBox(adPrice, lastOfferPrice);
    }, [adPrice, lastOfferPrice]);

    return (
        <Flex column className={styles.container}>
            <Flex column fillContainer alignCenter>
                <div className={styles.messageBoxContainer}>{messageBox}</div>
                <div>
                    <Text.Base bold uppercase className={styles.sellerTitle}>
                        <Trans>{"Buyer's offer:"}</Trans>
                    </Text.Base>
                    <PriceLabel price={lastOfferPrice} />
                </div>
            </Flex>
            <ActionButtons actionButtons={actionButtons} />
        </Flex>
    );
};

export default BuyerMadeOffer;
