import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import PlaceTypeDataColors from '@app/branding/placeTypeDataColors';
import schoolPinIcon from '@app/assets/icons/iconSchoolView_noinline.svg';
import hospitalPinIcon from '@app/assets/icons/iconHospitalView_noinline.svg';
import restaurantPinIcon from '@app/assets/icons/iconRestaurantsView_noinline.svg';
import parkPinIcon from '@app/assets/icons/iconParksView_noinline.svg';

import mapPin from '@app/assets/icons/iconLocationPinWithHouse_noinline.svg';
import propertyIcon from '@app/assets/icons/iconProperty.svg';
import schoolIcon from '@app/assets/icons/iconSchool.svg';
import hospitalIcon from '@app/assets/icons/iconHospital.svg';
import restaurantIcon from '@app/assets/icons/iconRestaurants.svg';
import parkIcon from '@app/assets/icons/iconParks.svg';
// eslint-disable-next-line
import hospitalCardIcon from '@app/assets/icons/iconHospitalCardView_noinline.svg';
import schoolCardIcon from '@app/assets/icons/iconSchoolCardView_noinline.svg';
import restaurantCardIcon from '@app/assets/icons/iconRestaurantCardView_noinline.svg';
import parkCardIcon from '@app/assets/icons/iconParkCardView_noinline.svg';
import mapCardIcon from '@app/assets/icons/iconLocationCardView_noinline.svg';

import { PlacesType as MapboxPlacesType } from './types';

type PlaceTypeData = {
    /**
     * The title used for a place type.
     */
    title: string;
    /**
     * The subtitle used for a place type.
     */
    subtitle: string;
    /**
     * Every place type has a particular color.
     */
    accentColor: string;
    /**
     * Every place type has a particular pin icon.
     */
    icon: any;
    /**
     * Every place type has a particular icon for the slider card.
     */
    cardIcon: any;
    /**
     * Every place type has a particular icon that can be used inline.
     */
    iconInline: any;
};

const getPlaceTypeData = (
    i18n: I18n,
    type: Values<typeof MapboxPlacesType> | null,
): PlaceTypeData => {
    // we treat type === null as the property
    if (!type) {
        return {
            title: t(i18n)`Location`,
            subtitle: '',
            // @ts-expect-error - TS2339 - Property 'LOCATION' does not exist on type 'Readonly<{ EDUCATION: string; HOSPITALS: string; RESTAURANTS: string; RECREATION: string; }>'.
            accentColor: PlaceTypeDataColors.LOCATION,
            icon: mapPin,
            cardIcon: mapCardIcon,
            iconInline: propertyIcon,
        };
    }

    const types = {
        [MapboxPlacesType.EDUCATION]: {
            title: t(i18n)`Schools`,
            subtitle: t(i18n)`View schools, colleges and universities nearby`,
            accentColor: PlaceTypeDataColors.EDUCATION,
            icon: schoolPinIcon,
            cardIcon: schoolCardIcon,
            iconInline: schoolIcon,
        },
        [MapboxPlacesType.HOSPITALS]: {
            title: t(i18n)`Hospitals`,
            subtitle: t(i18n)`View hospitals, clinics and health centres nearby`,
            accentColor: PlaceTypeDataColors.HOSPITALS,
            icon: hospitalPinIcon,
            cardIcon: hospitalCardIcon,
            iconInline: hospitalIcon,
        },
        [MapboxPlacesType.RESTAURANTS]: {
            title: t(i18n)`Restaurants`,
            subtitle: t(i18n)`View eateries, restaurants and cafés nearby`,
            accentColor: PlaceTypeDataColors.RESTAURANTS,
            icon: restaurantPinIcon,
            cardIcon: restaurantCardIcon,
            iconInline: restaurantIcon,
        },
        [MapboxPlacesType.RECREATION]: {
            title: t(i18n)`Parks`,
            subtitle: t(i18n)`See parks nearby`,
            accentColor: PlaceTypeDataColors.RECREATION,
            icon: parkPinIcon,
            cardIcon: parkCardIcon,
            iconInline: parkIcon,
        },
    } as const;

    return types[type];
};

export default getPlaceTypeData;
