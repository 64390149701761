import React from 'react';
import BrandingSettings from '@app/branding/settings';

/**
 * Properties for {@see ScoialTitleTags}.
 */
type Props = {
    title?: string;
};

const SocialTitleTags = (props: Props) => (
    <>
        <meta key="og" property="og:title" content={props.title} />
        <meta key="card" name="twitter:card" content="summary_large_image" />
        <meta key="site" name="twitter:site" content={BrandingSettings.social?.twitterSite} />
        <meta key="twitter" name="twitter:title" content={props.title} />
        <meta key="schema.org" itemProp="name" content={props.title} />
    </>
);

export default SocialTitleTags;
