import { t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import type { AnyAdData } from 'strat/types';
import { PaymentStep } from 'strat/searchComponents/project/paymentPlans';

import { selectProjectFirstPaymentPlanSummary } from './selectors';

type PaymentPlanBreakdown = {
    percentageSteps: PaymentStep[];
    priceSteps?: PaymentStep[];
};

const usePaymentPlanBreakdown = (ad: AnyAdData): PaymentPlanBreakdown | null => {
    const i18n = useI18n();
    const paymentPlanSummary = selectProjectFirstPaymentPlanSummary(ad);

    const percentageSteps = [];

    if (paymentPlanSummary) {
        const downPayment = paymentPlanSummary.breakdown.downPaymentPercentage;
        if (downPayment) {
            percentageSteps.push({
                label: t(i18n)`Down payment`,
                value: downPayment,
            });
        }

        const preHandoverPayment = paymentPlanSummary.breakdown.preHandoverPercentage;
        if (preHandoverPayment) {
            percentageSteps.push({
                label: t(i18n)`During construction`,
                value: preHandoverPayment,
            });
        }

        const handoverPayment = paymentPlanSummary.breakdown.handoverPercentage;
        if (handoverPayment) {
            percentageSteps.push({
                label: t(i18n)`On handover`,
                value: handoverPayment,
            });
        }

        const postHandoverPayment = paymentPlanSummary.breakdown.postHandoverPercentage;
        if (postHandoverPayment) {
            percentageSteps.push({
                label: t(i18n)`Post handover`,
                value: postHandoverPayment,
            });
        }
    }

    if (!percentageSteps.length) {
        return null;
    }

    return { percentageSteps };
};

export default usePaymentPlanBreakdown;
