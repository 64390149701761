import { t } from '@lingui/macro';
import React from 'react';
import settings from '@app/branding/settings';
import amenityIcon from '@app/assets/icons/iconAmenities_noinline.svg';

import propertiesIcon from 'strat/assets/icons/iconProperties_noinline.svg';
import paymentIcon from 'strat/assets/icons/iconPayment_noinline.svg';
import masterPlanIcon from 'strat/assets/icons/iconMasterPlan_noinline.svg';
import IconFloorPlans from 'strat/assets/icons/iconFloorPlans.svg';
import { renderNavSection } from 'strat/property/propertyContent';
import { useI18n } from 'strat/i18n/language';
import { SearchElementIdentifiers } from 'strat/search';

import DescriptionSection from './descriptionSection';
import PaymentPlanSection from './paymentPlanSection';
import UnitsSection from './unitsSection';
import AmenitiesSection from './amenitiesSection';
import MasterPlanSection from './masterPlanSection';
import useProject from './useProject';
import useProjectMasterPlans from './useProjectMasterPlans';
import styles from './styles/projectPage.cssm';
import ProjectNavBar from './projectNavBar';
import { ProjectPageSections } from './types';

// Search results section has 16px top margin
const FIRST_SECTION_OFFSET = 16;

const Section = ({ children }: { children: React.ReactNode }) => (
    <div className={styles.section}>{children}</div>
);

type Props = {
    children: React.ReactNode;
};
const ProjectPage = ({ children }: Props): JSX.Element => {
    const project = useProject();
    const i18n = useI18n();
    const masterPlans = useProjectMasterPlans(project);

    const filtersHeaderHeight =
        document.getElementById?.(SearchElementIdentifiers.searchPageHeaderId)?.offsetHeight || 0;

    const [navBarHeight, setNavBarHeight] = React.useState(0);

    if (!project) {
        return <>{children}</>;
    }

    const propertiesSection = renderNavSection(
        t(i18n)`Properties`,
        <svg className={styles.icon}>
            <use xlinkHref={`${propertiesIcon}#properties`} />
        </svg>,
        children,
    );

    const paymentPlansSection =
        settings.projectPageSectionVisibility?.[ProjectPageSections.PAYMENT_PLANS] &&
        !!project.paymentPlans?.length &&
        renderNavSection(
            t(i18n)`Payment Plan`,
            <svg className={styles.icon}>
                <use xlinkHref={`${paymentIcon}#payment`} />
            </svg>,
            <Section>
                <PaymentPlanSection paymentPlans={project.paymentPlans} />
            </Section>,
        );

    const unitsSection =
        settings.projectPageSectionVisibility?.[ProjectPageSections.AVAILABLE_UNITS] &&
        !!project.units?.length &&
        renderNavSection(
            t(i18n)`Unit Types`,
            <IconFloorPlans className={styles.iconUnits} />,
            <Section>
                <UnitsSection units={project.units} />
            </Section>,
        );

    const amenitiesSection =
        settings.projectPageSectionVisibility?.[ProjectPageSections.AMENITIES] &&
        !!project.amenities?.length &&
        renderNavSection(
            t(i18n)`Amenities`,
            <svg className={styles.icon}>
                <use xlinkHref={`${amenityIcon}#swimming-pool`} />
            </svg>,
            <Section>
                <AmenitiesSection amenityGroups={project.amenities} />
            </Section>,
        );

    const masterPlanSection =
        settings.projectPageSectionVisibility?.[ProjectPageSections.MASTER_PLAN] &&
        !!masterPlans.length &&
        renderNavSection(
            t(i18n)`Master Plan`,
            <svg className={styles.icon}>
                <use xlinkHref={`${masterPlanIcon}#masterPlan`} />
            </svg>,
            <Section>
                <MasterPlanSection masterPlans={project.floorPlans} />
            </Section>,
        );

    return (
        <ProjectNavBar
            compensation={filtersHeaderHeight + navBarHeight}
            topCompensationDelta={-navBarHeight + FIRST_SECTION_OFFSET}
            setNavBarHeight={setNavBarHeight}
            scrollDuration={1000}
        >
            {propertiesSection}
            {settings.projectPageSectionVisibility?.[ProjectPageSections.DESCRIPTION] && (
                <Section>
                    <DescriptionSection project={project} />
                </Section>
            )}
            {paymentPlansSection}
            {unitsSection}
            {masterPlanSection}
            {amenitiesSection}
        </ProjectNavBar>
    );
};

export default ProjectPage;
