import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';

import type { Location } from 'horizontal/types';
import { selectLocationNameWithParent } from 'horizontal/location';

import styles from './styles/noExactHits.cssm';

type Props = {
    location: Location;
};

const NoExactHits = ({ location }: Props) => {
    const i18n = useI18n();
    const locationName = selectLocationNameWithParent(i18n, location.hierarchy);
    return (
        <Flex className={styles.container}>
            <div className={styles.title}>
                {t(i18n)`Unfortunately we haven't found anything in ${locationName}.`}
            </div>
        </Flex>
    );
};

export default NoExactHits;
