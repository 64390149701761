import { t } from '@lingui/macro';
import React from 'react';

import { useI18n } from 'strat/i18n/language';
import IconInfo from 'strat/assets/icons/iconInfo_noinline.svg';
import { BadgeBottomSheet } from 'strat/project/badges';
import { isNonEmptyArray } from 'strat/types/conditions';

import { MilestonesPaymentPlanMilestone } from '../useMilestonesPaymentTimeline';
import { mapInstallmentsToPaymentSteps } from '../paymentInstallmentsTooltip';
import PaymentPercentagesTimeline from '../paymentPercentagesTimeline';

import styles from './styles/paymentInstallmentsDetails.cssm';

type Props = {
    milestone: MilestonesPaymentPlanMilestone;
};

const PaymentInstallmentsDetails = ({ milestone }: Props) => {
    const i18n = useI18n();
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(false);
    const displaySteps = mapInstallmentsToPaymentSteps(milestone.installments);

    if (!isNonEmptyArray(displaySteps)) {
        return null;
    }

    return (
        <>
            <svg className={styles.infoIcon} onClick={() => setIsBottomSheetOpen(true)}>
                <use xlinkHref={`${IconInfo}#info`} />
            </svg>
            <BadgeBottomSheet
                isOpen={isBottomSheetOpen}
                onClose={() => setIsBottomSheetOpen(false)}
            >
                <PaymentPercentagesTimeline title={t(i18n)`Payment details`} steps={displaySteps} />
            </BadgeBottomSheet>
        </>
    );
};

export default PaymentInstallmentsDetails;
