import settings from '@app/branding/settings';

import type { TargetingParameters } from './types';

const prefixTargetingParams = (params: TargetingParameters) =>
    settings.targetingParamsPrefix
        ? Object.keys(params).reduce<Record<string, any>>((result, item) => {
              result[`${settings.targetingParamsPrefix}_${item}`] = params[item];
              return result;
          }, {})
        : params;

export default prefixTargetingParams;
