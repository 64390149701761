//@ts-nocheck
import FetcherFactory from 'strat/fetcher';
import { FrontEndAPI } from 'strat/api';

/**
 * Factory for fetching popularity trends.
 */
const factory = new FetcherFactory(
    'popularityTrends',
    (params: any, state: any) =>
        new FrontEndAPI(state.i18n.language).popularityTrends(params.externalID),
    {
        getParams: (params, getState) => {
            const property = getState().property.data;
            return {
                externalID: property.externalID,
            };
        },
    },
);

/**
 * Fetches popularity trends.
 */
const fetchPopularityTrends = factory.creator();

/**
 * Reducer for popularity trends.
 */
const popularityTrendsReducer = factory.reducer();

export { fetchPopularityTrends };

export default popularityTrendsReducer;
