import React, { Component } from 'react';
import autoBind from 'react-autobind';

/**
 * Properties for {@see Expandable}.
 */
type Props = {
    readonly children?: React.ReactElement;
    readonly expanded: boolean;
};

/**
 * Takes care of hiding overflow when the children are
 * collapsed and makes the overflow visible when the
 * children are expanded.
 *
 * When an element is "collapsed" by setting the height
 * or min-height to 0, the elements might still be visible
 * if the "overflow: hidden" property is not set. However,
 * when the element is expanded, you'd want to allow over-
 * flowing in order to allow drop-downs.
 *
 * This means that you have to toggle showing overflow
 * when the element is expanded/collapsed. You do however
 * want to do this after the transition completed.
 *
 * You can use either CSS3 transitions or animations
 * with {@see Expandable}.
 *
 * ONLY <div> CHILD elements are allowed.
 */
class Expandable extends Component<Props> {
    /**
     * Child elements that are being expanded/collapsed.
     */
    elements: Array<HTMLElement>;

    /**
     * Initializes a new instance of the {@see Expandable}.
     */
    constructor(props: Props) {
        super(props);
        autoBind(this);

        this.elements = [];
    }

    /**
     * Gets the {@see HTMLElement} instances for each child component.
     */
    childElements(): Array<HTMLElement> {
        return this.elements.filter((element) => !!element);
    }

    /**
     * Component gets mounted in the browser's DOM, attach
     * an event listener for the end of transitions.
     */
    componentDidMount(): void {
        this.childElements().forEach((element: HTMLElement) => {
            element.addEventListener('transitionend', this.onTransitionEnd);

            element.addEventListener('animationend', this.onTransitionEnd);
        });
    }

    /**
     * Component gets unmounted from the browsers' DOM,
     * remove the event listener for the end of transitions.
     */
    componentWillUnmount(): void {
        this.childElements().forEach((element: HTMLElement) => {
            element.removeEventListener('transitionend', this.onTransitionEnd);

            element.removeEventListener('animationend', this.onTransitionEnd);
        });
    }

    /**
     * Component is receiving new prosp, most likely
     * the "expanded" state is being changed.
     *
     * If the element is being collapsed, hide the overflow.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.expanded === false) {
            this.childElements().forEach((element: HTMLElement) => {
                element.style.overflow = 'hidden';
            });
        }
    }

    /**
     * Transition is ending, if the transition expanded
     * the element, make the overflow visible.
     */
    onTransitionEnd(): void {
        if (this.props.expanded) {
            this.childElements().forEach((element: HTMLElement) => {
                element.style.overflow = 'visible';
            });
        }
    }

    /**
     * Render the children without any intermediate elements.
     */
    render() {
        if (!this.props.children) {
            return null;
        }

        return React.Children.map(this.props.children, (child: React.ReactElement) =>
            React.cloneElement(child, {
                ref: (element: HTMLElement) => {
                    this.elements.push(element);
                },
            }),
        );
    }
}

export default Expandable;
