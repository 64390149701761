// @ts-expect-error - TS2305 - Module '"redux"' has no exported member 'GetState'.
import type { Dispatch, GetState } from 'redux';
import type { FetchState } from 'strat/fetcher';
import FetcherFactory, { buildDefaultState } from 'strat/fetcher';
import { selectActiveUser } from 'strat/user/session';

import { StratAPI } from 'horizontal/api';

import type { UserSettings } from './types';
import { UserSettingsOperation, UserSettingsKey } from './types';

const getUserSettingsFromApiResponse = (response: any) => {
    return {
        [UserSettingsKey.SHOW_PHONE_NUMBER_IN_ADS]: response.data.showPhoneNumberInAds,
        [UserSettingsKey.ALLOW_CHAT_COMMUNICATION_IN_ADS]:
            response.data.allowChatCommunicationInAds,
        [UserSettingsKey.CHAT_SAFETY_TIPS_ACTIVE]: response.data.chatSafetyTipsActive,
        [UserSettingsKey.OFFERS_NOTIFICATIONS_ACTIVE]: response.data.offersNotificationsActive,
        [UserSettingsKey.RECOMMENDATIONS_NOTIFICATIONS_ACTIVE]:
            response.data.recommendationsNotificationsActive,
    };
};

/**
 * User settings actions.
 */
const Actions = Object.freeze({
    SET_USER_SETTINGS: 'USER_SETTINGS/SET',
    SAVE_USER_SETTINGS: 'USER_SETTINGS/SAVE',
    SET_USER_SETTINGS_ERROR: 'USER_SETTINGS/SET_ERROR',
} as const);

/**
 * An action that sets user settings.
 */
type SetUserSettingsAction = {
    type: 'USER_SETTINGS/SET';
    userSettings: UserSettings | null | undefined;
};

/**
 * An action that marks that a save request is in progress.
 */
type SaveUserSettingsAction = {
    type: 'USER_SETTINGS/SAVE';
    updatedFields: string[];
};

/**
 * An action that resets user settings.
 */
type SetUserSettingsErrorAction = {
    type: 'USER_SETTINGS/SET_ERROR';
    error: Error;
};

/**
 * Default user settings.
 */
const defaultState: FetchState = buildDefaultState();

const setUserSettings = (userSettings?: UserSettings | null): SetUserSettingsAction => ({
    type: Actions.SET_USER_SETTINGS,
    userSettings,
});

const setUserSettingsError = (error: Error): SetUserSettingsErrorAction => ({
    type: Actions.SET_USER_SETTINGS_ERROR,
    error,
});

/**
 * Fetcher factory for fetching user settings.
 */
const factory = new FetcherFactory(
    'userSettings',
    (
        {
            userExternalID,
        }: {
            userExternalID: string;
        },
        state: any,
    ) => {
        if (!userExternalID) {
            throw new Error('Missing user ID when requesting user settings.');
        }

        return new StratAPI(state.i18n.language).userSettings(userExternalID);
    },
);

const fetchUserSettingsById = factory.creator();

/**
 * Action creator for fetching user settings.
 *
 * By using a wrapper function instead of returning factory.creator()
 * the caching is maintained for the authenticated user but a new refresh
 * is made if the authenticated user changes.
 */
// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const fetchUserSettings = (dispatch: Dispatch, getState: GetState) => {
    const profile = selectActiveUser(getState());
    const userExternalID = profile?.externalID;

    dispatch(fetchUserSettingsById({ operation: UserSettingsOperation.FETCH, userExternalID }));
};

const fetchUserSettingsReducer = factory.reducer();

/**
 * Save the user settings.
 */
const saveUserSettings =
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    (userSettings: Partial<UserSettings>) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();

        const profile = selectActiveUser(state);
        const userExternalID = profile?.externalID;

        if (!userExternalID) {
            throw new Error('Missing user ID when saving user settings.');
        }

        dispatch({ type: Actions.SAVE_USER_SETTINGS, updatedFields: Object.keys(userSettings) });
        new StratAPI(state.i18n.language).saveUserSettings(userExternalID, userSettings).then(
            (response) => {
                if (response.status !== 200) {
                    const error = new Error(
                        `Cannot update user settings: Server returned ${response.status}`,
                    );
                    dispatch(setUserSettingsError(error));
                    return;
                }

                const newSettings = getUserSettingsFromApiResponse(response);

                // @ts-expect-error - TS2345 - Argument of type '{ [x: string]: any; }' is not assignable to parameter of type 'UserSettings'.
                dispatch(setUserSettings(newSettings));
            },
            (error) => dispatch(setUserSettingsError(error)),
        );
    };

type Action = SetUserSettingsAction | SaveUserSettingsAction | SetUserSettingsErrorAction;

/**
 * Settings reducers.
 */
const userSettingsReducer = (
    state: FetchState | null | undefined = defaultState,
    action: Action,
) => {
    switch (action.type) {
        case Actions.SET_USER_SETTINGS:
            return {
                ...state,
                loaded: true,
                loading: false,
                data: action.userSettings,
            };

        case Actions.SAVE_USER_SETTINGS:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: null,
                params: {
                    operation: UserSettingsOperation.SAVE,
                    updatedFields: action.updatedFields,
                },
            };

        case Actions.SET_USER_SETTINGS_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error || null,
            };

        default:
            return fetchUserSettingsReducer(state, action);
    }
};

export { setUserSettings, setUserSettingsError, fetchUserSettings, saveUserSettings };

export default userSettingsReducer;
