//@ts-nocheck
/**
 * Available dialog actions.
 */
const Actions = Object.freeze({
    SET_OPEN_DIALOG_ONCE: 'OPEN_DIALOG_ONCE/SET',
});

/**
 * Default state for the openDialogOnce.
 */
const defaultState = '';

/**
 * Reducer for the openDialogOnce.
 */
const openDialogOnceReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_OPEN_DIALOG_ONCE:
            return action.openDialogOnce;
        default:
            return state;
    }
};

/**
 * Sets the openDialogOnce.
 */
const setOpenDialogOnce = (openDialogOnce: any) => ({
    type: Actions.SET_OPEN_DIALOG_ONCE,
    openDialogOnce,
});

export { setOpenDialogOnce };

export default openDialogOnceReducer;
