import isNil from 'lodash/isNil';
import memoize from 'fast-memoize';
import settings from '@app/branding/settings';

// @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
const isInvalid = (data) => isNil(data) || (typeof data === 'string' && data.trim() === '');
const isValid = (data: any) => !isInvalid(data);

const isLocalisedKey = memoize((key: string) => !!key.match(new RegExp('(.*)_l[0-9]', 'g')));

const isCurrentLanguageKey = memoize((key: string, langIndex: number) =>
    langIndex === 0 ? !isLocalisedKey(key) : !!key.match(new RegExp(`(.*)_l${langIndex}`, 'g')),
);

const getBaseKey = memoize((key: string) =>
    isLocalisedKey(key) ? key.split(new RegExp('_l[0-9]', 'g'))[0] : key,
);

/**
 *  Determines if the base key should be suffixed with `l0`.
 *  If the attribute is localizable, `key_l1` must be in the input data.
 */
const isLocalizableBaseKey = <T extends object, K extends keyof T>(key: string, data: Partial<T>) =>
    !isLocalisedKey(key) && isValid(data[`${key}_l1` as K]);

const isBaseOrCurrentLanguageKey = (key: string, langIndex: number) =>
    !isLocalisedKey(key) || isCurrentLanguageKey(key, langIndex);

/**
 * Normalized data retrieved from merged or non-merged index.
 * For merged index, the english data will be set in normal fields, and arabic data will be set in language specific
 * fields (like "title_l1")
 * For non-merged index, normal fields will contain data translated in the current language, the language specific
 * fields will contain the other value
 * @param data the JSON object from backend
 * @param lang the current language
 * @param mergedIndex defines if we use a merged index
 * @param keepOriginalTranslations defines whether we want to keep all translation values for localised fields
 *      Example with keepOriginalTranslations (for Bayut with merged index, with current language = ar):
 *          - before: `name` (en), `name_l1` (ar), `name_l2` (zh)
 *          - after: `name` (ar), `name_l0` (en), `name_l1` (ar), `name_l2` (zh)
 *      If this is false, all fields except `name` in current language will be discarded after the transformation
 */
const transformLocalizedFields = <T extends object, K extends keyof T>(
    data: T,
    lang: string,
    mergedIndex: boolean,
    keepOriginalTranslations = false,
): T => {
    // Exception handled in case if language is not available then pick default Language
    const langIndex = settings.languages.findIndex(
        (obj) => obj.lang === (!lang ? CONFIG.build.LANGUAGE_CODE : lang),
    );
    const computedLangIndex = mergedIndex ? langIndex : 0;

    const transformedData: Partial<T> = {};
    let keys = Object.keys(data).sort();
    // For merged index, we want to set localized values before base value, which will be mapped to `_l0`
    if (computedLangIndex > 0) {
        keys = keys.reverse();
    }

    keys.forEach((key) => {
        const baseKey = getBaseKey(key);

        if (keepOriginalTranslations) {
            if (isLocalizableBaseKey(key, data)) {
                transformedData[`${key}_l0` as K] = data[key as K];
            }

            // Localized fields should have the same name in the result
            if (isLocalisedKey(key)) {
                transformedData[key as K] = data[key as K];
            }
        }

        if (
            isBaseOrCurrentLanguageKey(key, langIndex) &&
            isValid(data[key as K]) &&
            isInvalid(transformedData[baseKey as K])
        ) {
            transformedData[baseKey as K] = data[key as K];
        }
    });
    // @ts-expect-error - TS2322 - Type 'Partial<T>' is not assignable to type 'T'.
    return transformedData;
};

export default transformLocalizedFields;
