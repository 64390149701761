import React from 'react';
import classNames from 'classnames';

import IconArrow from '@app/assets/icons/iconArrowDown.svg';

import styles from './styles/moreLessButton.cssm';

/**
 * Properties for {@see MoreLessButton}.
 */
type Props = {
    textMore: string;
    textLess: string;
    expanded: boolean;
    onClick: (event: React.SyntheticEvent<any>) => void;
    className?: string;
    arrowClassName?: string;
};

const MoreLessButton = (props: Props): React.ReactElement => (
    <div
        role="button"
        className={props.className || styles.toggle}
        onClick={props.onClick}
        aria-label={props.expanded ? 'View Less' : 'View More'}
    >
        {props.expanded ? props.textLess : props.textMore}
        <IconArrow
            className={classNames(props.arrowClassName || styles.arrow, {
                [styles.expanded]: props.expanded,
            })}
        />
    </div>
);

export default MoreLessButton;
