import { createSelector } from 'reselect';

import EMPTY_OBJECT from 'strat/empty/object';
import type { GlobalState } from 'strat/state';
import { isKeycloakEnabled } from 'strat/auth/keycloak/config';
import { isAgent, isProfolioPaidUser, isProfolioPackageUser, UserRole } from 'strat/user/roles';

import { selectIsUserLoggedIn as selectIsKeycloakUserLoggedIn } from './session';

/**
 * Selects the whole user object from the state.
 */
const selectUser = createSelector(
    (state: GlobalState) => state.user,
    (user) => user,
);

/**
 * Selects the user's unique visitor ID (set by Google Analytics).
 */
const selectVisitorID = createSelector(selectUser, (user) => user.visitorID || null);

/**
 * Selects the user's unique client ID: visitorID + timestamp when it was set (by Google Analytics).
 */
const selectClientID = createSelector(selectUser, (user) => user.clientID || null);

/**
 * Selects the user's unique device ID (set by us).
 */
const selectDeviceID = createSelector(selectUser, (user) => user.deviceID || null);

/**
 * Selects the user's anonymous session ID.
 */
const selectAnonymousSessionID = createSelector(
    selectUser,
    (user) => user?.anonymousSessionID || null,
);

/**
 * Selects the user's location.
 */
const selectUserLocation = createSelector(selectUser, (user) => user.location);

/**
 * Selects the user's profile (only available if the user is logged in).
 */
const selectUserProfile = createSelector(selectUser, (user) => user.profile || null);

/**
 * Selects ths user's external ID (from the API6).
 *
 * \todo rename this to selectUserExternalID. Not an internal ID,
 * it comes from API6.
 */
const selectUserID = createSelector(selectUserProfile, (profile) => (profile ? profile.id : null));

/**
 * Selects ths user's profile if that user is an agent.
 */
const selectAgentUserProfile = createSelector(selectUserProfile, (profile) =>
    profile && isAgent(profile.roles as UserRole[]) ? profile : null,
);

/**
 * Selects the user's email.
 */
const selectUserEmail = createSelector(selectUserProfile, (profile) =>
    profile ? profile.email : null,
);

/**
 * Selects the user's name.
 */
const selectUserName = createSelector(selectUserProfile, (profile) => profile?.name ?? null);

/**
 * Selects the user's hash. (If provided from email URL parameters)
 */
const selectUserHash = createSelector(
    (state: GlobalState) => state.unknownParams,
    (unknownParams) => unknownParams.u_h,
);

const selectIsProfolioPaidUser = createSelector(
    selectUserProfile,
    (profile): boolean => !!profile && isProfolioPaidUser(profile.roles),
);

const selectIsProfolioPackageUser = createSelector(
    selectUserProfile,
    (profile): boolean => !!profile && isProfolioPackageUser(profile.roles),
);

/**
 * Selects the user authentication status.
 * If Keycloak is enabled, we need to check the Keycloak selector as well to avoid
 * having the Keycloak user data not loaded when displaying a component
 */
const selectIsUserLoggedIn = createSelector(
    selectUser,
    selectIsKeycloakUserLoggedIn,
    (user, isKeycloakUserLoggedIn): boolean =>
        user.loggedIn && (isKeycloakEnabled() ? isKeycloakUserLoggedIn : true),
);

const selectUserIP = createSelector(selectUser, (user) => (user ? user.userIP : null));

const selectDebugMode = createSelector(selectUser, (user) => user?.debugMode);

/**
 * Selects from the user profile the phone numbers.
 */
const selectUserPhoneNumbers = createSelector(selectUserProfile, (profile) =>
    profile ? profile.phoneNumber : EMPTY_OBJECT,
);

const selectUserPhone = createSelector(selectUserPhoneNumbers, (phoneNumbers) =>
    phoneNumbers ? phoneNumbers.phone || phoneNumbers.mobile : null,
);

const selectGeoLocationData = createSelector(selectUser, (user) => user.geoLocation);

const selectClosestLocation = createSelector(
    selectGeoLocationData,
    (geoLocationData) => geoLocationData.closestLocation,
);

const selectFBPID = createSelector(selectUser, (user) => user.fbpID || null);

const selectFBCID = createSelector(selectUser, (user) => user.fbcID || null);

const selectIsUserLoading = createSelector(selectUser, (user) => user.loading || null);

const selectUserAgent = createSelector(selectUser, (user) => user.userAgent || null);

const selectLoggedInAgentData = (state: GlobalState) => state.loggedInAgentData?.data;

const selectLoggedInAgentLoading = (state: GlobalState) => state.loggedInAgentData?.loading;

export {
    selectUser,
    selectVisitorID,
    selectClientID,
    selectFBPID,
    selectFBCID,
    selectUserEmail,
    selectUserName,
    selectUserHash,
    selectDeviceID,
    selectAnonymousSessionID,
    selectUserLocation,
    selectUserProfile,
    selectUserID,
    selectIsProfolioPaidUser,
    selectIsProfolioPackageUser,
    selectIsUserLoggedIn,
    selectUserIP,
    selectDebugMode,
    selectUserPhone,
    selectGeoLocationData,
    selectLoggedInAgentData,
    selectIsUserLoading,
    selectUserAgent,
    selectClosestLocation,
    selectAgentUserProfile,
    selectLoggedInAgentLoading,
};
