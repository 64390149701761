import * as React from 'react';
import { Dropdown } from 'strat/modal';
import { Flex, InputContainer } from 'strat/components';
import classNames from 'classnames';
import { ChoiceValue } from 'strat/components/types';

import IconSearch from 'horizontal/assets/icons/iconSearch.svg';

import DropdownChoice from '../dropdownChoice';
import { FilterableDropdownProps } from '../types';

import styles from './styles/textInput.cssm';

interface Props<T extends ChoiceValue> extends FilterableDropdownProps<T> {
    shouldHideInput?: boolean;
    searchQuery: string | null;
    setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedValueLabel?: string;
}

const TextInputDropdownVariant = <T extends ChoiceValue>({
    title,
    description,
    name,
    errorMessage,
    accepted,
    onChange,
    value = '',
    choices,
    dropdownTriggerCustomClass,
    choicesContainerCustomClass,
    placeholder,
    stretch,
    renderSuggestions,
    readOnly,
    titleCustomClass,
    renderIcon,
    searchQuery,
    setSearchQuery,
    isOpen,
    setIsOpen,
    selectedValueLabel,
    onBlur,
    lightPlaceholder,
    boldLabel,
    isAutofilled,
}: Props<T>) => {
    const renderTrigger = () => (
        <Flex
            alignCenter
            stretchHeight
            className={dropdownTriggerCustomClass ?? styles.inputWrapper}
        >
            <IconSearch className={styles.icon} />
            <input
                className={classNames(styles.input, {
                    [styles.lightPlaceholder]: !!lightPlaceholder,
                })}
                id={name}
                name={name}
                spellCheck={false}
                onChange={(event) => setSearchQuery(event.target.value)}
                onFocus={() => (!readOnly ? setIsOpen(true) : void 0)}
                onClick={() => (!readOnly ? setIsOpen(true) : void 0)}
                placeholder={placeholder}
                value={searchQuery ?? selectedValueLabel ?? ''}
                onBlur={onBlur}
                autoComplete="off"
            />
        </Flex>
    );

    return (
        <InputContainer
            name={name}
            description={description}
            accepted={accepted}
            stretch={stretch}
            title={title}
            errorMessage={errorMessage}
            titleClassname={titleCustomClass}
            leftSideLabel
            boldLabel={boldLabel}
            className={isAutofilled ? styles.autofilled : undefined}
        >
            <div className={styles.container} aria-label="Filterable input">
                <Dropdown
                    triggerIsDismissble
                    open={isOpen}
                    renderTrigger={renderTrigger}
                    onDismissed={() => setIsOpen(false)}
                    className={styles.dropdown}
                >
                    <div
                        aria-label="Filterable dropdown"
                        className={classNames(choicesContainerCustomClass, styles.content)}
                    >
                        {renderSuggestions && !searchQuery && renderSuggestions(onChange)}
                        {choices.map((choice) => (
                            <DropdownChoice<T>
                                key={choice.value}
                                choice={choice}
                                selected={choice.value === value}
                                onClick={onChange}
                                renderIcon={renderIcon}
                            />
                        ))}
                    </div>
                </Dropdown>
            </div>
        </InputContainer>
    );
};

export default TextInputDropdownVariant;
