import React from 'react';
import { Route } from 'react-true-router';
import { isCanceled } from 'strat/util';
import type { CancelablePromise } from 'strat/util';

import { PaymentSelectionFreeAdUndertakingRoute } from 'horizontal/routes';
import StratAPI from 'horizontal/api/strat';
import { useMakeCancelable } from 'horizontal/util';

import {
    PaymentOrderPlatform,
    ProductPackageOffer,
    PaymentOrder,
    CheckoutCreditCard,
} from '../types';

import useCheckoutSavedPayment from './useCheckoutSavedPayment';

type CheckoutPaymentFunction = (order: PaymentOrder, route: Route) => void;

type CheckoutPayWhatYouWantOptions = {
    checkoutPayment: CheckoutPaymentFunction;
    setPaymentError: (errorMessage: string) => void;
    setIsLoading: (arg1: boolean) => void;
    setPaymentPromise: (arg1?: CancelablePromise | null) => void;
};

type CheckoutPayWhatYouWantResponse = {
    checkoutPayWhatYouWant: (
        packages: Array<ProductPackageOffer>,
        commissionAmount: string,
        adID: number,
        card?: CheckoutCreditCard,
    ) => void;
};

const useCheckoutPayWhatYouWant = ({
    checkoutPayment,
    setPaymentError,
    setIsLoading,
    setPaymentPromise,
}: CheckoutPayWhatYouWantOptions): CheckoutPayWhatYouWantResponse => {
    const makeCancelable = useMakeCancelable();
    const savedCardPayment = useCheckoutSavedPayment({ setPaymentPromise, setIsLoading });

    const checkoutPayWhatYouWant = React.useCallback(
        (
            packages: Array<ProductPackageOffer>,
            commissionAmount: string,
            adID: number,
            card?: CheckoutCreditCard,
        ) => {
            if (!packages.length) {
                setPaymentError(
                    'There was an issue with the payment service. Please refresh the page.',
                );
                return;
            }
            // TODO: fix package types which are duplicate between horizontal/types && horizontal/payment/types
            // @ts-expect-error
            const packageOfferID = packages[0].packageOfferID;
            if (!packageOfferID) {
                setPaymentError(
                    'There was an issue with the payment service. Please refresh the page.',
                );
                return;
            }

            setIsLoading(true);

            const cancelablePromise = makeCancelable(
                new StratAPI()
                    .createPayWhatYouWantOrder({
                        product_package_offers: [{ external_id: packageOfferID, ad_id: adID }],
                        platform: PaymentOrderPlatform.WEB,
                        total: Number(commissionAmount),
                    })
                    .then(({ data }) => data.orderExternalID)
                    .then((orderExternalID: string) => new StratAPI().getOrder(orderExternalID))
                    .then((response) => {
                        return response.data;
                    }),
            );

            cancelablePromise
                .then((newOrder: PaymentOrder) => {
                    if (card?.id) {
                        savedCardPayment(card, newOrder);
                        return;
                    }
                    checkoutPayment(newOrder, PaymentSelectionFreeAdUndertakingRoute);
                })
                // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
                .catch((error) => {
                    if (isCanceled(error)) {
                        return;
                    }
                    setIsLoading(false);
                });
            return;
        },
        [checkoutPayment, setPaymentError, setIsLoading, makeCancelable, savedCardPayment],
    );

    return {
        checkoutPayWhatYouWant,
    };
};

export default useCheckoutPayWhatYouWant;
