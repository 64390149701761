import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { getTimeAsHour } from 'strat/util';

import { ChatMessageType, ChatNegotiationState } from 'horizontal/chat/constants';
import MessageSeenStatus from 'horizontal/chat/messageSeenStatus';
import { NegotiationMessage } from 'horizontal/chat/negotiation';
import type { ActionButton, ChatMessage } from 'horizontal/chat/types';

import AttachmentMessage from './attachmentMessage';
import AudioMessage from './audioMessage';
import ImagesMessage from './imagesMessage';
import LocationMessage from './locationMessage';
import styles from './styles/message.cssm';
import SystemMessage from './systemMessage';
import TextMessage from './textMessage';
import ViewPhoneNumberMessage from './viewPhoneNumberMessage';

type Props = {
    readonly message: ChatMessage;
    readonly isSeen: boolean;
    readonly isReceived: boolean;
    readonly isLoggedInUserSeller: boolean;
    readonly isLoggedInUserAuthor: boolean;
    readonly isPreviousMessageFromOtherAuthor: boolean;
    readonly isNextMessageFromOtherAuthor: boolean;
    readonly actionButtons: Array<ActionButton>;
    readonly onImageClick: (messageXID: string, index: number) => void;
};

const Message = ({
    message,
    isSeen,
    isReceived,
    isLoggedInUserSeller,
    isLoggedInUserAuthor,
    isPreviousMessageFromOtherAuthor,
    isNextMessageFromOtherAuthor,
    actionButtons,
    onImageClick,
}: Props) => {
    const i18n = useI18n();

    const commonContainerStyling = classNames(styles.container, {
        [styles.isFromOwner]: isLoggedInUserAuthor,
        [styles.isFromOther]: !isLoggedInUserAuthor,
        [styles.roundedTop]: isPreviousMessageFromOtherAuthor,
        [styles.roundedBottom]: isNextMessageFromOtherAuthor,
    });

    const wrapperStyling = classNames({
        [styles.marginTop]: isPreviousMessageFromOtherAuthor,
        [styles.marginBottom]: isNextMessageFromOtherAuthor,
    });

    const containerStyling = classNames(commonContainerStyling, wrapperStyling);

    const renderFooter = React.useCallback(() => {
        return (
            <div className={styles.timestamp}>
                <Text.Small>{getTimeAsHour(message.createdAt, i18n.locale)}</Text.Small>
                {isLoggedInUserAuthor && (
                    <MessageSeenStatus
                        className={styles.seenStatus}
                        isReceived={isReceived}
                        isSeen={isSeen}
                    />
                )}
            </div>
        );
    }, [i18n.locale, isLoggedInUserAuthor, isSeen, isReceived, message.createdAt]);

    const onImageMessageClick = React.useCallback(
        (index: number) => {
            onImageClick(message.xid, index);
        },
        [message.xid, onImageClick],
    );

    if (message?.isModerationRejected) {
        return (
            <TextMessage
                className={commonContainerStyling}
                isFromOwner={isLoggedInUserAuthor}
                wrapperClassName={wrapperStyling}
                text={t(i18n)`Hidden due to inappropriate content`}
                renderFooter={renderFooter}
                moderated
            />
        );
    }

    const { content } = message;

    if (content[ChatMessageType.NEGOTIATION]) {
        const state = content[ChatMessageType.NEGOTIATION]?.state;

        const isOfferMessage = state
            ? [
                  ChatNegotiationState.BUYER_MADE_OFFER,
                  ChatNegotiationState.SELLER_MADE_OFFER,
              ].includes(state)
            : false;

        return (
            <NegotiationMessage
                isLoggedInUserSeller={isLoggedInUserSeller}
                message={message}
                isSeen={isSeen}
                className={classNames(containerStyling, { [styles.isOffer]: isOfferMessage })}
                renderFooter={renderFooter}
                actionButtons={actionButtons}
            />
        );
    } else if (content[ChatMessageType.IMAGES]) {
        return (
            <ImagesMessage
                className={containerStyling}
                onClick={onImageMessageClick}
                images={content[ChatMessageType.IMAGES] ?? []}
                renderFooter={renderFooter}
            />
        );
    } else if (content[ChatMessageType.FILES]) {
        return (
            <AttachmentMessage
                className={commonContainerStyling}
                wrapperClassName={wrapperStyling}
                attachments={content[ChatMessageType.FILES] ?? []}
                renderFooter={renderFooter}
            />
        );
    } else if (content[ChatMessageType.LOCATION]) {
        return (
            <LocationMessage
                className={containerStyling}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                location={content[ChatMessageType.LOCATION]!}
                renderFooter={renderFooter}
            />
        );
    } else if (content[ChatMessageType.PHONE_VIEW]) {
        return <ViewPhoneNumberMessage message={message} />;
    } else if (content[ChatMessageType.AUDIO]) {
        const url = content[ChatMessageType.AUDIO]?.url;
        if (!url) {
            return null;
        }

        return (
            <AudioMessage className={containerStyling} audioUrl={url} renderFooter={renderFooter} />
        );
    } else if (content[ChatMessageType.TEXT]) {
        return (
            <TextMessage
                className={commonContainerStyling}
                isFromOwner={isLoggedInUserAuthor}
                wrapperClassName={wrapperStyling}
                text={content[ChatMessageType.TEXT] ?? ''}
                renderFooter={renderFooter}
            />
        );
    } else if (content[ChatMessageType.SYSTEM]) {
        return <SystemMessage message={message} />;
    }

    return null;
};

export default React.memo<Props>(Message);
