import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

const AdManagementHead = () => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <>
            <title>
                {t(i18n)`My Ads`} | {brandName}
            </title>
            <GTMDataLayer pageType={PageType.AD_MANAGEMENT} />
        </>
    );
};

export default AdManagementHead;
