import * as React from 'react';
import { connect } from 'react-redux';

import { usePreviousState } from 'strat/util';

import { useDialog } from './dialogContext';

const authorizedDialog = (
    authorizationDialogName: string,
    dialogName: string,
    dialogToRender: React.ComponentType<any>,
) =>
    // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
    connect((state) => ({ isAuthorized: state.user.loggedIn, isLoggingIn: state.user.loading }))(
        // @ts-expect-error - TS2700 - Rest types may only be created from object types.
        ({ isAuthorized, isLoggingIn, ...props }): React.ReactElement => {
            const [visible, setVisible] = useDialog(dialogName);
            const [authorizationDialogVisible, setAuthorizationDialogVisible] =
                useDialog(authorizationDialogName);

            const wasAuthorizationDialogVisible = usePreviousState(authorizationDialogVisible);

            React.useEffect(() => {
                if (
                    visible &&
                    wasAuthorizationDialogVisible &&
                    !authorizationDialogVisible &&
                    !isAuthorized &&
                    !isLoggingIn
                ) {
                    setVisible(false);
                    return;
                }

                if (
                    visible &&
                    !wasAuthorizationDialogVisible &&
                    !isAuthorized &&
                    !isLoggingIn &&
                    !authorizationDialogVisible
                ) {
                    setAuthorizationDialogVisible(true);
                }
            }, [
                isAuthorized,
                wasAuthorizationDialogVisible,
                authorizationDialogVisible,
                visible,
                isLoggingIn,
                setAuthorizationDialogVisible,
                setVisible,
            ]);

            return React.createElement(dialogToRender, {
                ...props,
                visible: visible && isAuthorized,
            });
        },
    );

export default authorizedDialog;
