import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { useI18n } from 'strat/i18n/language';
import { Text } from 'strat/components';

import { useDeliveryOrdersNavStatus } from '../hooks';
import { UserOrdersSection, DeliveryRequestStatus } from '../types';

import styles from './styles/sellingOrdersNavbar.cssm';

type Props = {
    readonly className?: string;
};

const SellingOrdersStatusNavbar = ({ className }: Props) => {
    const i18n = useI18n();
    const deliveryOrderStatus = useDeliveryOrdersNavStatus();

    return (
        <ul className={classNames(styles.list, className)}>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_ORDERS}
                    params={{ section: UserOrdersSection.SELLING }}
                    title={t(i18n)`Active`}
                    className={classNames(styles.link, {
                        [styles.selected]: deliveryOrderStatus === DeliveryRequestStatus.ACTIVE,
                    })}
                >
                    <Text.Regular>{t(i18n)`Active`}</Text.Regular>
                </Link>
            </li>
            <li className={styles.listItem}>
                <Link
                    route={RouteNames.USER_ORDERS}
                    params={{
                        section: UserOrdersSection.SELLING,
                        deliveryOrderStatus: DeliveryRequestStatus.ARCHIVED,
                    }}
                    title={t(i18n)`ARCHIVED`}
                    className={classNames(styles.link, {
                        [styles.selected]: deliveryOrderStatus === DeliveryRequestStatus.ARCHIVED,
                    })}
                >
                    <Text.Regular>{t(i18n)`ARCHIVED`}</Text.Regular>
                </Link>
            </li>
        </ul>
    );
};

export default SellingOrdersStatusNavbar;
