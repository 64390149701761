import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { selectActiveSearchBackend, getFilterCollection } from '@sector-labs/fe-search-redux/state';
import { FilterCollection, RangeFilter } from '@sector-labs/fe-search-redux/filters';
import sample from 'lodash/sample';

import { FilterValues } from 'strat/search';
import { propertyTransformer } from 'strat/property';
import { PropertyType } from 'strat/property/types';
import FetcherFactory from 'strat/fetcher';
import { determineAdsIndexName } from 'strat/search/indexNames';

const filterAds = (ads: any, minLevel: number) => {
    // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
    const currentTimestamp = new Date() / 1000;
    return ads.filter(
        // @ts-expect-error - TS7006 - Parameter 'ad' implicitly has an 'any' type.
        (ad) =>
            ad.deal.startTime <= currentTimestamp &&
            currentTimestamp <= ad.deal.endTime &&
            ad.location.length >= minLevel,
    );
};

const getLocationFiltersMaxLevel = (locations: any) => {
    // @ts-expect-error - TS7006 - Parameter 'maxLevel' implicitly has an 'any' type. | TS7006 - Parameter 'location' implicitly has an 'any' type.
    return locations.reduce((maxLevel, location) => Math.max(maxLevel, location.level), 0);
};

/**
 * Factory for creating action creators and reducers for fetching the
 * current property of the week, using Algolia as the data source.
 */
const potwFactory = new FetcherFactory(
    'propertyOfTheWeek',
    (params: any, state: any) => {
        const algoliaSettings = state.algolia.settings;

        const { language } = state.i18n;

        // Get only the relevant filters for POTW
        const currentFilters = new FilterCollection(params);

        // It's called PROPERTY of the week, not SOMETHING of the week.
        // Do not fetch if we're not searching for properties.
        const adType = currentFilters.getFilterValue(
            FilterValues.type.attribute,
            FilterValues.type.default,
        );
        if (adType !== PropertyType.PROPERTY) {
            return Promise.resolve({ data: null, status: 200 });
        }

        const neighborhoodLevel = CONFIG.build.CITY_LEVEL + 1;
        const locationFiltersMaxLevel = getLocationFiltersMaxLevel(
            currentFilters.getFilterValue(FilterValues.location.attribute, []),
        );

        if (locationFiltersMaxLevel < neighborhoodLevel) {
            return Promise.resolve({ data: null, status: 200 });
        }

        // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
        const currentTimestamp = new Date() / 1000;

        currentFilters.refine(
            new RangeFilter({
                attribute: 'deal.startTime',
                value: { max: currentTimestamp },
            }),
        );
        currentFilters.refine(
            new RangeFilter({
                attribute: 'deal.endTime',
                value: { min: currentTimestamp },
            }),
        );

        const job = new SearchJob(
            determineAdsIndexName({ language: state.i18n.language }),
            currentFilters,
            {
                ...algoliaSettings,
                attributesToRetrieve: [...algoliaSettings.attributesToRetrieve, 'deal'],
            },
        );

        const backend = selectActiveSearchBackend(state);
        // @ts-expect-error - TS2322 - Type 'AlgoliaSearchBackend | ElasticSearchBackend | null' is not assignable to type 'SearchBackend'.
        const service = new SearchService({ backend });

        return service
            .fetchJob(job)
            .then((content) => {
                const filteredAds = filterAds(content.hits, neighborhoodLevel);

                // Currently, a random property from the available ones is picked
                const property = sample(filteredAds) || null;

                return {
                    data: {
                        id: property?.deal?.externalID,
                        creatorID: property?.userExternalID,
                        externalID: property?.externalID,
                        property: propertyTransformer(
                            property,
                            language,
                            CONFIG.build.ENABLE_MERGED_INDEX,
                        ),
                    },
                    status: 200,
                };
            })
            .catch(() => ({
                data: null,
                status: 404,
            }));
    },
    {
        successCodes: [200, 404],
        // @ts-expect-error - TS7006 - Parameter 'params' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type.
        getParams: (params, getState) => {
            const state = getState();
            const filters = getFilterCollection(state).subset([
                FilterValues.type.attribute,
                FilterValues.purpose.attribute,
                FilterValues.location.attribute,
                FilterValues.beds.attribute,
                FilterValues.category.attribute,
                FilterValues.completionStatus.attribute,
            ]);

            return filters.serialize();
        },
    },
);

/**
 * Fetches the current property of the week from the back-end.
 */
const fetchPropertyOfTheWeek = potwFactory.creator();

/**
 * Reducer for the property of the week.
 */
const propertyOfTheWeekReducer = potwFactory.reducer();

export { fetchPropertyOfTheWeek };

export default propertyOfTheWeekReducer;
