import * as React from 'react';
import sortBy from 'lodash/sortBy';
import settings from '@app/branding/settings';
import EMPTY_ARRAY from 'strat/empty/array';

import type { CategoryFieldChoice, FlatCategoryField } from 'horizontal/types';

import useFacetedChoices from './useFacetedChoices';

const usePopularChoices = (
    field: FlatCategoryField,
    popularCount: number = settings.popularChoicesNumber,
): Array<CategoryFieldChoice> => {
    const [popularChoices, setPopularChoices] = React.useState(EMPTY_ARRAY);
    const facetedChoices = useFacetedChoices(field || EMPTY_ARRAY);
    React.useEffect(() => {
        if (!facetedChoices.length) {
            return;
        }

        const choices = sortBy(
            facetedChoices.filter(({ count }) => !!count),
            ['count'],
        )
            .reverse()
            .slice(0, popularCount);
        setPopularChoices(choices);
    }, [facetedChoices, popularCount]);

    // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'CategoryFieldChoice[]'.
    return popularChoices;
};

export default usePopularChoices;
