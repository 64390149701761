import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Checkbox } from 'strat/components';

import { PriceType } from 'horizontal/types';

import { PriceTypeField } from './commonPostingFields';

const NegotiableField = ({
    values,
    setFieldValue,
}: {
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly values: FormikValues;
}) => {
    const i18n = useI18n();

    const isNegotiable = values[PriceTypeField.attribute] === PriceType.NEGOTIABLE;
    const onChange = React.useCallback(() => {
        const newValue = isNegotiable ? PriceType.FIXED_PRICE : PriceType.NEGOTIABLE;
        setFieldValue(PriceTypeField.attribute, newValue);
    }, [isNegotiable, setFieldValue]);

    return (
        <Checkbox
            checked={isNegotiable}
            name={PriceType.NEGOTIABLE}
            label={t(i18n)`Negotiable`}
            onChange={onChange}
        />
    );
};

export default NegotiableField;
