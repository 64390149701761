import * as React from 'react';
import { Text } from 'strat/components';

import styles from './styles/descriptionLink.cssm';

type Props = {
    readonly onClick?: () => void;
    readonly text: string;
};

const DescriptionLink = ({ onClick, text }: Props) => {
    return (
        <Text.Base className={styles.link} onClick={onClick}>
            {text}
        </Text.Base>
    );
};

export default DescriptionLink;
