import { createSelector } from 'reselect';

import EMPTY_OBJECT from 'strat/empty/object';

const selectAgentReview = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.agentReview || EMPTY_OBJECT,
    // @ts-expect-error - TS7006 - Parameter 'review' implicitly has an 'any' type.
    (review) => review.data,
);

const selectAcceptedReviews = (state: any) => (state.acceptedReviews || EMPTY_OBJECT).data;

const selectAcceptedReviewsLoading = (state: any) =>
    (state.acceptedReviews || EMPTY_OBJECT).loading;

const selectAcceptedReviewsLoaded = (state: any) => (state.acceptedReviews || EMPTY_OBJECT).loaded;

const selectAcceptedReviewsError = (state: any) => (state.acceptedReviews || EMPTY_OBJECT).error;

const selectAgencyAgentsReviews = (state: any) => (state.agencyAgentsReviews || EMPTY_OBJECT).data;

const selectAgencyAgentsReviewsLoading = (state: any) =>
    (state.agencyAgentsReviews || EMPTY_OBJECT).loading;

const selectAgencyAgentsReviewsLoaded = (state: any) =>
    (state.agencyAgentsReviews || EMPTY_OBJECT).loaded;

const selectAgencyAgentsReviewsError = (state: any) =>
    (state.agencyAgentsReviews || EMPTY_OBJECT).error;

export {
    selectAgentReview,
    selectAcceptedReviews,
    selectAcceptedReviewsLoading,
    selectAcceptedReviewsLoaded,
    selectAcceptedReviewsError,
    selectAgencyAgentsReviews,
    selectAgencyAgentsReviewsLoading,
    selectAgencyAgentsReviewsLoaded,
    selectAgencyAgentsReviewsError,
};
