import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Tooltip, { HorizontalPosition, VerticalPosition } from 'strat/generic/tooltip';
import { selectIsLanguageRTL } from 'strat/i18n/language';

import styles from './styles/listingMoreInfoTooltip.cssm';

type Props = {
    renderTrigger: () => React.ReactElement;
    renderInformation: () => React.ReactElement;
};

const ListingMoreInfoTooltip = ({ renderTrigger, renderInformation }: Props) => {
    const isLanguageRTL = useSelector(selectIsLanguageRTL);

    const attachment = isLanguageRTL
        ? {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.RIGHT,
          }
        : {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.LEFT,
          };

    const targetAttachment = isLanguageRTL
        ? {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.LEFT,
          }
        : {
              vertical: VerticalPosition.MIDDLE,
              horizontal: HorizontalPosition.RIGHT,
          };

    return (
        <Tooltip
            timeToRender={100}
            triggerContainerClassName={styles.trigger}
            anchorClassName={styles.anchor}
            renderTrigger={renderTrigger}
            renderContent={() => (
                <div className={classNames(styles.content)}>{renderInformation()}</div>
            )}
            attachment={attachment}
            targetAttachment={targetAttachment}
            tooltipShouldIgnoreMouseEvents
        />
    );
};

export default ListingMoreInfoTooltip;
