import { t, plural } from '@lingui/macro';
import React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';

const RequestDeliveryTitle = () => {
    const i18n = useI18n();
    return (
        <Trans>
            Delivery within{' '}
            {t(i18n)`${plural(CONFIG.runtime.STRAT_AD_DELIVERY_MAX_TIME_TO_SHIP_DAYS, {
                one: '# day',
                other: '# days',
            })}`}
        </Trans>
    );
};

export default RequestDeliveryTitle;
