import type { Geoloc } from 'strat/map/types';

const CountryBounds: ReadonlyArray<Geoloc> = Object.freeze([
    { lng: -180, lat: -90 },
    { lng: 180, lat: 90 },
]);

export const FocusedBounds: ReadonlyArray<Geoloc> = Object.freeze([
    { lng: -180, lat: -90 },
    { lng: 180, lat: 90 },
]);

export default CountryBounds;
