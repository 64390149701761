import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import RadioButtonChoice from 'strat/components/radioButtonChoice';
import { RadioGroup } from 'strat/components';

import useContactMethodChoices, {
    ContactSettings,
} from 'horizontal/fields/useContactMethodChoices';

import SettingsToggle from './settingsToggle';
import styles from './styles/contactMethodControl.cssm';

const ContactMethodControl = ({
    trackShowPhoneNumber,
    onSettingsChanged,
    showPhoneNumber,
    allowChatCommunication,
}: {
    trackShowPhoneNumber?: (arg1: boolean) => void;
    onSettingsChanged?: (arg1: ContactSettings) => void;
    showPhoneNumber?: boolean | null | undefined;
    allowChatCommunication?: boolean | null | undefined;
}) => {
    const i18n = useI18n();

    const { enableContactMethodControl, selectedValue, choices, saveSettings } =
        useContactMethodChoices({
            showPhoneNumber,
            allowChatCommunication,
            trackShowPhoneNumber,
            onSettingsChanged,
        });

    if (!enableContactMethodControl) {
        return (
            <SettingsToggle
                value={showPhoneNumber}
                onClick={() => saveSettings(!showPhoneNumber)}
                title={t(i18n)`Show my phone number in ads`}
            />
        );
    }

    return (
        <div>
            <h3 className={styles.contactMethodTitle}>{t(i18n)`Contact Method`}</h3>
            <RadioGroup
                selectedValue={selectedValue}
                choices={choices}
                onChange={saveSettings}
                renderChoice={RadioButtonChoice}
            />
        </div>
    );
};

export default ContactMethodControl;
