import * as React from 'react';
import { Trans } from '@lingui/macro';
import isNil from 'lodash/isNil';

import type { AnyAdData } from 'strat/types';

import { selectProjectFirstPaymentPlanSummary } from '../selectors';
import PaymentPlanBreakdown from '../paymentPlanBreakdown';

import Badge from './badge';
import { ProjectBadgeStyle } from './types';

type Props = {
    ad: AnyAdData;
    style: ProjectBadgeStyle;
};

const PaymentPlanBadge = ({ ad, style }: Props) => {
    const paymentPlanSummary = selectProjectFirstPaymentPlanSummary(ad);

    if (!paymentPlanSummary) {
        return null;
    }

    const { preHandoverPercentageSum, postHandoverPercentageSum } = paymentPlanSummary;

    if (isNil(preHandoverPercentageSum) && isNil(postHandoverPercentageSum)) {
        return null;
    }

    return (
        <Badge
            style={style}
            renderTitle={() => <Trans>Payment Plan</Trans>}
            renderValue={() => (
                <div>
                    {preHandoverPercentageSum || 0}/{postHandoverPercentageSum || 0}
                </div>
            )}
            renderInformation={() => <PaymentPlanBreakdown property={ad} />}
            label="Payment Plan"
        />
    );
};

export default PaymentPlanBadge;
