const translateList = (list: any, lang: string) =>
    list[lang] && list[lang].length > 0 ? list[lang] : list.en;

/**
 * This can be removed once the agent APIs can be safely updated, so the agent can use the localized
 * serialisation (field, field_l1, field_l2). Then, only agentTransformer should be used.
 */
const agentTransformer = (data: any, lang: string) => {
    if (!data) {
        return data;
    }

    const transformedData = Object.assign({}, data);

    // Some agents can have no agency attached.
    if (!('agency' in transformedData) || transformedData.agency == null) {
        transformedData.agency = {};
    }

    const agency = (data.agency && data.agency.name) || { en: null };
    transformedData.agency.name = agency[lang] || agency.en;

    if (Object.prototype.hasOwnProperty.call(data, 'specialities')) {
        transformedData.specialities = translateList(data.specialities, lang);
    }
    if (Object.prototype.hasOwnProperty.call(data, 'service_areas')) {
        transformedData.service_areas = translateList(data.service_areas, lang);
    }
    // Horizontal agent has no user_langs
    if (Object.prototype.hasOwnProperty.call(data, 'user_langs')) {
        transformedData.user_langs = translateList(data.user_langs, lang);
    }
    return transformedData;
};

export default agentTransformer;
