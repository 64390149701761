import type { Dispatch } from 'redux';
import EMPTY_ARRAY from 'strat/empty/array';

import { GlobalState, AppDispatch } from 'horizontal/state';
import type { CartPackage } from 'horizontal/packages/types';
import {
    setBusinessPackageCartInLocalStorage,
    setAdOrderDetailsInLocalStorage,
} from 'horizontal/app';
import type { ProductPackageOffer } from 'horizontal/types';
import type { PaymentProductPackageOffer } from 'horizontal/payment';

import Actions from './actions';
import type { SetPaymentOrderExternalID } from './actions';

/**
 * Toggles whether the given business package offer is in the cart or not.
 */
const toggleBusinessPackageOffer =
    (businessPackageOffer: ProductPackageOffer) =>
    (dispatch: Dispatch<AppDispatch>, getState: () => GlobalState): boolean => {
        const state = getState();
        const businessProductPackageIDs = state.businessPackageCart
            ? (state.businessPackageCart.businessPackageCartOffers || EMPTY_ARRAY).map(
                  (hit) => hit?.packageOfferID,
              )
            : [];
        const isSelected = businessProductPackageIDs.includes(businessPackageOffer.packageOfferID);

        if (isSelected) {
            dispatch({
                type: Actions.REMOVE_BUSINESS_PACKAGE_OFFER,
                businessPackageOffer,
                packageOfferID: businessPackageOffer.packageOfferID,
            });
        } else {
            dispatch({
                type: Actions.ADD_BUSINESS_PACKAGE_OFFER,
                businessPackageOffer: { ...businessPackageOffer, cartQuantity: 1 },
            });
        }

        setBusinessPackageCartInLocalStorage(
            getState().businessPackageCart.businessPackageCartOffers,
        );

        return isSelected;
    };

const addToCartCounter =
    (businessPackageOffer: CartPackage | PaymentProductPackageOffer) =>
    (dispatch: Dispatch<AppDispatch>, getState: () => GlobalState): void => {
        dispatch({
            type: Actions.ADD_TO_CART_COUNTER,
            businessPackageOffer,
            packageOfferID: businessPackageOffer.packageOfferID,
        });

        setBusinessPackageCartInLocalStorage(
            getState().businessPackageCart.businessPackageCartOffers,
        );
    };

const subtractFromCartCounter =
    (businessPackageOffer: CartPackage | PaymentProductPackageOffer) =>
    (dispatch: Dispatch<AppDispatch>, getState: () => GlobalState): void => {
        dispatch({
            type: Actions.SUBTRACT_FROM_CART_COUNTER,
            businessPackageOffer,
            packageOfferID: businessPackageOffer.packageOfferID,
        });

        setBusinessPackageCartInLocalStorage(
            getState().businessPackageCart.businessPackageCartOffers,
        );
    };

const removeFromCartCounter =
    (businessPackageOffer: CartPackage | PaymentProductPackageOffer) =>
    (dispatch: Dispatch<AppDispatch>, getState: () => GlobalState): void => {
        dispatch({
            type: Actions.REMOVE_BUSINESS_PACKAGE_OFFER,
            businessPackageOffer,
            packageOfferID: businessPackageOffer.packageOfferID,
        });

        setBusinessPackageCartInLocalStorage(
            getState().businessPackageCart.businessPackageCartOffers,
        );
    };

const setBusinessPackageCartOffers =
    (businessPackageOffers: Array<CartPackage | PaymentProductPackageOffer>) =>
    (dispatch: Dispatch<AppDispatch>, getState: () => GlobalState): void => {
        dispatch({
            type: Actions.SET_BUSINESS_PACKAGE_CART,
            businessPackageOffers,
        });

        setBusinessPackageCartInLocalStorage(
            getState().businessPackageCart.businessPackageCartOffers,
        );
    };

const clearBusinessPackageCart =
    () =>
    (dispatch: Dispatch<AppDispatch>, getState: () => GlobalState): void => {
        dispatch({
            type: Actions.CLEAR_BUSINESS_PACKAGE_CART,
        });

        setBusinessPackageCartInLocalStorage(
            getState().businessPackageCart.businessPackageCartOffers,
        );
    };

const setPaymentOrderExternalID = (orderExternalID: string): SetPaymentOrderExternalID => ({
    type: Actions.SET_PAYMENT_ORDER_EXTERNAL_ID,
    orderExternalID,
});

const setAdOrderDetails =
    (adExternalID: string) =>
    (dispatch: Dispatch<AppDispatch>): void => {
        dispatch({
            type: Actions.SET_AD_ORDER_DETAILS,
            adExternalID,
        });

        setAdOrderDetailsInLocalStorage(adExternalID);
    };

export {
    toggleBusinessPackageOffer,
    addToCartCounter,
    subtractFromCartCounter,
    removeFromCartCounter,
    setBusinessPackageCartOffers,
    clearBusinessPackageCart,
    setPaymentOrderExternalID,
    setAdOrderDetails,
};
