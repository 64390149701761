import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { Button } from 'strat/components';
import settings from '@app/branding/settings';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import withGTMLeadTracking from 'strat/gtm/withGTMLeadTracking';
import { useActiveUser } from 'strat/user/session';

import { useFetchAdContactInfo } from 'horizontal/ad';
import { useAd } from 'horizontal/ad/state';
import IconCall from 'horizontal/assets/icons/iconCall.svg';
import { ChatPhoneViewType } from 'horizontal/chat/constants';
import { PhoneViewChatNotifier } from 'horizontal/chat/widgets/large';
import { useEnsureHasActiveUser } from 'horizontal/user';
import { ContactPersonRole } from 'horizontal/types';

import useIsPhoneNumberPublicForCategory from '../useIsPhoneNumberPublicForCategory';

import styles from './styles/phoneNumberGuardedButton.cssm';
import PhoneNumberLink from './phoneNumberLink';

type Props = GTMLeadTrackingProps & {
    readonly adExternalID?: string | null | undefined;
    readonly viewSection: string;
    readonly onShowButtonClick?: () => boolean | null | undefined;
    readonly shortNumber?: string | null | undefined;
    readonly className?: string;
};

const PhoneNumberGuardedButton = ({
    adExternalID,
    viewSection,
    onShowButtonClick,
    className,
    shortNumber = '',
    trackPhoneViewAttempt,
}: Props) => {
    const i18n = useI18n();
    const [isShowingNumber, setShowingNumber] = React.useState(false);
    const [clickedCount, setClickedCount] = React.useState(0);
    const isPhoneNumberPublic: boolean = useIsPhoneNumberPublicForCategory();
    const ad = useAd();
    const activeUser = useActiveUser();

    const {
        fetchContactInfo: fetchPhoneNumber,
        contactInfo,
        loading: isLoading,
        isError,
    } = useFetchAdContactInfo(adExternalID);

    const isShortNumber = contactInfo?.roles?.includes(ContactPersonRole.SHOW_SHORT_NUMBER);

    const phoneNumber = isShortNumber
        ? shortNumber
        : contactInfo?.proxyMobile || contactInfo?.mobile;

    let handleShowButtonClick = () => {
        if (!isShowingNumber && (!onShowButtonClick || onShowButtonClick())) {
            setShowingNumber(true);
            fetchPhoneNumber();
            setClickedCount((lastValue) => lastValue + 1);
        }
    };
    const guardedClickHandler = useEnsureHasActiveUser({
        onDone: handleShowButtonClick,
        onLoginDialogOpen: () => {
            trackPhoneViewAttempt(viewSection);
        },
    });
    handleShowButtonClick = isPhoneNumberPublic ? handleShowButtonClick : guardedClickHandler;

    React.useEffect(() => {
        if (isShowingNumber && !isPhoneNumberPublic && !activeUser) {
            setShowingNumber(false);
        }
    }, [isShowingNumber, isPhoneNumberPublic, activeUser]);

    return (
        <>
            {ad && ad.userExternalID && (
                <PhoneViewChatNotifier
                    adXID={ad.externalID}
                    linkType={ChatPhoneViewType.CALL}
                    counter={phoneNumber ? clickedCount : 0}
                />
            )}
            <Button
                stretch
                className={!settings.allowsPrimaryContactButton ? className : undefined}
                onClick={() => {
                    handleShowButtonClick();
                }}
                renderIcon={(_) => <IconCall className={styles.phoneIcon} />}
            >
                <>
                    <PhoneNumberLink
                        lightTheme
                        largeText
                        isLoading={isLoading}
                        isError={isError}
                        phoneNumber={isShowingNumber ? phoneNumber : null}
                        viewSection={viewSection}
                        lightThemeClass={
                            !settings.allowsPrimaryContactButton ? styles.lightText : undefined
                        }
                    />
                    {!isShowingNumber && !isLoading && !isError && t(i18n)`Show phone number`}
                </>
            </Button>
        </>
    );
};

export default withGTMLeadTracking<Props>(PhoneNumberGuardedButton);
