import * as React from 'react';

import styles from './styles/popularPropertyContainer.cssm';

type Props = {
    title?: string;
    className?: string;
    titleClassName?: string;
    children: React.ReactNode | Array<any> | any;
};

const PopularPropertyContainer = ({
    title,
    children,
    className,
    titleClassName,
}: Props): React.ReactElement => (
    <div className={className || styles.container}>
        <div className={titleClassName || styles.title}>{title}</div>
        <div className={styles.childrenContainer}>{children}</div>
    </div>
);

export default PopularPropertyContainer;
