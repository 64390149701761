import { useSelector } from 'react-redux';
import { selectLanguage } from 'strat/i18n/language';
import EMPTY_OBJECT from 'strat/empty/object';

import { useFlatCategoryFields } from 'horizontal/categoryFields';
import type { AdExtraFields, LiteCategory } from 'horizontal/types';

import type { ExtraFieldsTargetingParams } from './types';
import formatAdExtraField from './formatAdExtraField';

const useAdExtraFieldParams = (
    categories: Array<LiteCategory>,
    extraFields?: AdExtraFields,
): ExtraFieldsTargetingParams => {
    const primaryCategory = categories[categories.length - 1];

    const language = useSelector(selectLanguage);
    const categoryFields = useFlatCategoryFields(primaryCategory?.id, extraFields);

    if (!extraFields) {
        return EMPTY_OBJECT;
    }

    const { make, model, petrol, new_used: condition, furnished, mileage, year } = extraFields;

    return {
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        model: formatAdExtraField(language, model, 'model', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        make: formatAdExtraField(language, make, 'make', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        fuel: formatAdExtraField(language, petrol, 'petrol', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        condition: formatAdExtraField(language, condition, 'new_used', categoryFields, (v) =>
            // @ts-expect-error - TS2339 - Property 'toLowerCase' does not exist on type 'Primitive'.
            v.toLowerCase(),
        ),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'. | TS2345 - Argument of type 'AdExtraFieldValue' is not assignable to parameter of type 'Primitive | null | undefined'.
        furnished: formatAdExtraField(language, furnished, 'furnished', categoryFields, (v) =>
            // @ts-expect-error - TS2339 - Property 'toLowerCase' does not exist on type 'Primitive'.
            v.toLowerCase(),
        ),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        mileage: formatAdExtraField(language, mileage, 'mileage', categoryFields),
        // @ts-expect-error - TS2322 - Type 'Primitive | null | undefined' is not assignable to type 'TargetingParamValue | undefined'.
        year: formatAdExtraField(language, year, 'year', categoryFields),
        beds: extraFields?.rooms,
        baths: extraFields?.bathrooms,
        area: extraFields?.ft,
        area_unit: extraFields?.ft_unit, // eslint-disable-line camelcase
    };
};

export default useAdExtraFieldParams;
