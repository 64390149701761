import * as React from 'react';
import classNames from 'classnames';

import * as Text from './text';
import styles from './styles/inputMessage.cssm';

type Props = {
    readonly children: React.ReactNode;
    readonly isError?: boolean;
    readonly isWarning?: boolean;
};

const InputMessage = ({ children, isError, isWarning }: Props) => {
    let labelState = '';
    if (isError) {
        labelState = 'error ';
    } else if (isWarning) {
        labelState = 'warning ';
    }

    return (
        <span
            className={classNames(styles.text, {
                [styles.error]: isError,
                [styles.warning]: !isError && isWarning,
            })}
        >
            <Text.Small aria-label={`Input ${labelState}message`}>{children}</Text.Small>
        </span>
    );
};

export default InputMessage;
