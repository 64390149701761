import * as React from 'react';
import { useSelector } from 'react-redux';
import { statelessUrlProcessor } from '@app/branding/urlProcessor';
import { processLanguageInUrl } from 'strat/i18n/language';
import { useRouter } from 'react-true-router';
import { parseLocation, serializeLocation } from 'react-true-router/location';
import RouteNames from 'strat/routes/routeNames';
import { createSearchURLCore } from '@app/routes/createSearchURL';
import { useCategoryFields } from '@app/categoryFields';

import { GlobalState } from 'horizontal/state';
import { useDefaultSortOption } from 'horizontal/search/sorting/sortOptions';
import useCategoryHierarchyByExternalID from 'horizontal/categories/hooks';

/**
 * Updates the URL in real-time, but only when running in the browser.
 *
 * This is a component that doesn't actually render anything, it renders
 * into the browser URL. It needs to be a component, because it needs
 * to get updated on render cycles with the latest version of the search state.
 */
const SearchUrlUpdater = () => {
    /**
     * ID that was last pushed.
     */
    const [lastPushedId, setLastPushedId] = React.useState(0);
    const route = RouteNames.SEARCH;
    const urlState = useSelector((state: GlobalState) => state.search.url);
    const routeParams = urlState.params || {};
    const unknownParams = useSelector((state: GlobalState) => state.unknownParams);
    const router = useRouter();
    const categoryFields = useCategoryFields(routeParams.category?.id);
    const category = routeParams.category?.roles && routeParams.category;
    const defaultSortOption = useDefaultSortOption(category);
    const categoryHierarchy = useCategoryHierarchyByExternalID(category?.externalID);
    /**
     * Determines whether the URL should update.
     */
    const shouldUpdateURL = (): boolean => {
        if (!urlState.enabled || !routeParams) {
            return false;
        }

        const currentUrl = processLanguageInUrl(
            statelessUrlProcessor.inbound(window.location.href),
        ).newUrl;
        const currentLocation = parseLocation(currentUrl);
        const searchUrl = createSearchURLCore(
            routeParams,
            categoryFields,
            defaultSortOption,
            categoryHierarchy,
        );
        const newLocation = parseLocation(
            statelessUrlProcessor.outbound(serializeLocation(searchUrl)),
        );

        const sameURLs =
            serializeLocation(newLocation, { sort: true }) ===
            serializeLocation(currentLocation, { sort: true });

        return lastPushedId !== urlState.id && !sameURLs;
    };

    if (process.env.IS_BROWSER && shouldUpdateURL()) {
        if (urlState.options.replace) {
            router.replaceRoute(
                route,
                {
                    ...routeParams,
                    ...(unknownParams || {}),
                },
                false /* invoke router */,
            );
        } else {
            router.pushRoute(route, routeParams, false /* invoke router */);
        }

        setLastPushedId(urlState.id);
    }
    return null;
};

export default SearchUrlUpdater;
