import { createSelector } from 'reselect';

import { selectProperty } from 'strat/property/selectors';
import EMPTY_OBJECT from 'strat/empty/object';

/**
 * Selects the whole agent object from the state.
 */
const selectAgent = (state: any) => (state.agent || {}).data || EMPTY_OBJECT;
const selectRawAgent = (state: any) => (state.agent || {}).data;
const selectAgentLoaded = (state: any) => (state.agent || {}).loaded;
const selectAgentLoading = (state: any) => (state.agent || {}).loading;
const selectAgentError = (state: any) => (state.agent || {}).error;

const selectAgentFromPropertyData = createSelector(
    selectProperty,
    selectAgent,
    (property, agent) => ({
        name: property.contactName || agent.name,
        external_id: property.ownerID,
        agency_name: (property.agency || {}).name,
        user_image: agent.user_image,
        user_image_id: agent.user_image_id,
    }),
);

export {
    selectAgent,
    selectRawAgent,
    selectAgentLoaded,
    selectAgentLoading,
    selectAgentError,
    selectAgentFromPropertyData,
};
