import * as React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';

import ToolTip, { ToolTipPosition } from './toolTip';
import styles from './styles/input.cssm';

/**
 * Properties for {@see Input}.
 */
export type Props = {
    value?: string;
    type?: string;
    onChange?: (event: React.SyntheticEvent<any>) => void;
    onBlur?: (event: React.SyntheticEvent<any>) => void;
    onFocus?: (event: React.SyntheticEvent<any>) => void;
    placeholder?: string;
    className?: string;
    errorMessage?: string;
    onDismissError?: () => void;
    toolTipPosition: Values<typeof ToolTipPosition>;
    toolTipClassName?: string;
    inputClassName?: string;
    renderRightIcon?: () => React.ReactNode;
    label?: string;
    id?: string;
    ariaLabel?: string;
};

/**
 * Renders a <input> element for the login form.
 */
const Input = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const { errorMessage, value = '', label = '', renderRightIcon, ariaLabel } = props;
    const [displayLabel, setDisplayLabel] = React.useState(!!value && !!label);

    const containerClassName = props.className || styles.container;
    const defaultInputClassName = classNames(styles.input, {
        [styles.error]: errorMessage,
    });
    const inputClassName = props.inputClassName || defaultInputClassName;

    const excludeProps = [
        'hasError',
        'errorMessage',
        'onDismissError',
        'toolTipPosition',
        'toolTipClassName',
        'inputClassName',
        'label',
        'renderRightIcon',
        'ariaLabel',
    ];

    const onFocus = (event: any) => {
        props.onFocus?.(event);

        if (!label) {
            return;
        }
        setDisplayLabel(false);
    };

    const onBlur = (event: any) => {
        props.onBlur?.(event);

        if (!value || !label) {
            return;
        }
        setDisplayLabel(true);
    };

    const displayValue = displayLabel && value ? `${value} ${label}` : props.value;

    return (
        <div className={containerClassName} aria-label={ariaLabel}>
            {errorMessage && (
                <ToolTip
                    text={errorMessage}
                    onCloseClick={props.onDismissError}
                    position={props.toolTipPosition}
                    className={props.toolTipClassName}
                />
            )}
            <input
                {...omit(props, excludeProps)}
                value={displayValue}
                spellCheck={false}
                className={inputClassName}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={ref}
            />

            {renderRightIcon && (
                <div className={styles.rightIconContainer}>{renderRightIcon()}</div>
            )}
        </div>
    );
});

export default Input;
