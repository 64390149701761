import { createSelector } from 'reselect';
import { TemplateType, TemplatePage } from '@sector-labs/templates-renderer';

import { LocationTypes } from 'strat/locations/locationTypes';
import type { LocationData } from 'strat/property';
import {
    selectPurpose,
    selectLocationHierarchy,
    selectCategory,
    selectBeds,
} from 'strat/search/selectors';
import { selectProperty } from 'strat/property/selectors';
import type { GlobalState } from 'strat/state';

import { findTemplate } from './find';

/**
 * Selects all available rule-based templates.
 */
const selectTemplates = createSelector(
    (state: GlobalState) => state.templates.data,
    (templates) => templates || [],
);

/**
 * Selects the template to use for the given template type based on the current search filters.
 */
const createSelectorForTemplate = (selectTemplateTypeFindParameters: any, templateType: any) =>
    createSelector(selectTemplateTypeFindParameters, (findParameters) =>
        findTemplate({ type: templateType, ...findParameters }),
    );

/**
 * Takes in a location hierarchy (either from property data or search
 * filters) and extracts the relevant location filtering options for
 * findTemplate.
 */
const extractLocationAttributes = (
    hierarchy: LocationData,
): {
    locationSlugs: string[] | null;
    locationType: Values<typeof LocationTypes>;
} => {
    // The hierarchy is reversed as this is what the templates-renderer
    // library expects. In a reversed hierarchy, index 0 contains the
    // current location.
    const reversedHierarchy = hierarchy.slice().reverse();
    return {
        locationSlugs: reversedHierarchy?.length
            ? reversedHierarchy.map((node) => node.slug)
            : null,
        locationType: reversedHierarchy?.[0]?.type ?? null,
    };
};

/**
 * Selects parameters to find templates for beds and nearby links based on
 * the currently selected search filters.
 */
const selectBedsAndNearbyLinksTemplateFindParameters = createSelector(
    [selectTemplates, selectPurpose, selectLocationHierarchy, selectCategory, selectBeds],
    (templates, purpose, locationHierarchy, category, beds) => ({
        templates,
        page: TemplatePage.SEARCH,
        purpose,
        categorySlug: category ? category.slug : null,
        ...extractLocationAttributes(locationHierarchy),
        bedroomPage: beds.length > 0,
    }),
);

/**
 * Selects parameters to find templates for beds and nearby links based on
 * the currently selected search filters.
 */
const selectPropertyLinksTemplateFindParameters = createSelector(
    [selectTemplates, selectProperty],
    (templates, property) => ({
        templates,
        page: TemplatePage.PROPERTY,
        purpose: property.purpose,
        categorySlug: property.category ? property.category.slice(-1)[0].slug : null,
        ...extractLocationAttributes(property.location),
    }),
);

/**
 * Selects the template to use for beds links text based on the current search filters.
 */
const selectBedsLinkSearchTextTemplate = createSelectorForTemplate(
    selectBedsAndNearbyLinksTemplateFindParameters,
    TemplateType.BEDS_LINK_TEXT,
);

/**
 * Selects the template to use for beds links title based on the current search filters.
 */
const selectBedsLinkSearchTitleTemplate = createSelectorForTemplate(
    selectBedsAndNearbyLinksTemplateFindParameters,
    TemplateType.BEDS_LINK_TITLE,
);

/**
 * Selects the template to use for beds links text based on the current Ad.
 */
const selectBedsLinkPropertyTextTemplate = createSelectorForTemplate(
    selectPropertyLinksTemplateFindParameters,
    TemplateType.BEDS_LINK_TEXT,
);

/**
 * Selects the template to use for beds links title based on the current search filters.
 */
const selectBedsLinkPropertyTitleTemplate = createSelectorForTemplate(
    selectPropertyLinksTemplateFindParameters,
    TemplateType.BEDS_LINK_TITLE,
);

/**
 * Selects the template to use for nearby links text based on the current search filters.
 */
const selectNearbyLinkSearchTextTemplate = createSelectorForTemplate(
    selectBedsAndNearbyLinksTemplateFindParameters,
    TemplateType.NEARBY_LINK_TEXT,
);

/**
 * Selects the template to use for nearby links title based on the current search filters.
 */
const selectNearbyLinkSearchTitleTemplate = createSelectorForTemplate(
    selectBedsAndNearbyLinksTemplateFindParameters,
    TemplateType.NEARBY_LINK_TITLE,
);

/**
 * Selects the template to use for area links text based on the current Ad.
 */
const selectAreaLinkPropertyTextTemplate = createSelectorForTemplate(
    selectPropertyLinksTemplateFindParameters,
    TemplateType.AREA_LINK_TEXT,
);

/**
 * Selects the template to use for area links title based on the current Ad.
 */
const selectAreaLinkPropertyTitleTemplate = createSelectorForTemplate(
    selectPropertyLinksTemplateFindParameters,
    TemplateType.AREA_LINK_TITLE,
);

export {
    selectTemplates,
    selectBedsLinkSearchTextTemplate,
    selectBedsLinkSearchTitleTemplate,
    selectBedsLinkPropertyTextTemplate,
    selectBedsLinkPropertyTitleTemplate,
    selectNearbyLinkSearchTextTemplate,
    selectNearbyLinkSearchTitleTemplate,
    selectAreaLinkPropertyTextTemplate,
    selectAreaLinkPropertyTitleTemplate,
};
