import * as React from 'react';
import { Trans } from '@lingui/macro';

import { Flex, Text, Button } from 'strat/components';

import styles from './styles/otpError.cssm';
import FormContainer from './formContainer';

type Props = {
    readonly phoneNumber: string;
    readonly onDialogDismiss: (arg1: boolean) => void;
};

const OTPError = ({ phoneNumber, onDialogDismiss }: Props) => {
    return (
        <FormContainer title={<Trans>Invalid number used</Trans>} contentClassName={styles.content}>
            <Flex column>
                <Flex alignCenter justifyCenter>
                    <Text.Regular>
                        <Trans>The number {phoneNumber} is already used by another account.</Trans>
                        <br />
                        <Trans>If you are the account owner then you can try signing in.</Trans>
                    </Text.Regular>
                </Flex>
                <Button onClick={() => onDialogDismiss(false)} stretch className={styles.button}>
                    <Trans>Use Different Phone Number</Trans>
                </Button>
            </Flex>
        </FormContainer>
    );
};

export default OTPError;
