import omit from 'lodash/omit';

import { Actions } from '../types';
import type { Action, DialogState, DialogsCollection, State } from '../types';

export const reducer = (state: State = {} as State, action: Action): State => {
    switch (action.type) {
        case Actions.SET_VISIBLE: {
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    visible: action.visible,
                    props: action.props,
                },
            };
        }

        case Actions.SET_DEFAULT_VISIBLE_ALERT: {
            return {
                ...state,
                defaultVisibleAlert: action.name,
            } as State;
        }

        case Actions.CLOSE_ALL_DIALOGS: {
            const dialogsState = omit(state, ['defaultVisibleAlert']);

            const closedDialogs = Object.entries(dialogsState).map(
                ([dialogName, dialogState]: [string, DialogState]) => [
                    dialogName,
                    {
                        ...dialogState,
                        visible: false,
                    },
                ],
            );

            return {
                ...Object.fromEntries(closedDialogs),
                defaultVisibleAlert: state.defaultVisibleAlert,
            };
        }
        default:
            throw new Error();
    }
};

export const computeDefaultState = (registeredDialogs = {} as DialogsCollection): State =>
    Object.freeze(
        Object.keys(registeredDialogs).reduce(
            (state, key) => ({
                ...state,
                [key]: {
                    visible: false,
                    props: {},
                },
            }),
            {} as State,
        ),
    );
