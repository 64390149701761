import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Flex } from 'strat/components';

import { selectCategories } from 'horizontal/categories';

import PopularCategorySection from './popularCategorySection';
import styles from './styles/popularCategories.cssm';

const PopularCategories = () => {
    const categories = useSelector(selectCategories);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Trans>Popular Categories</Trans>
            </div>
            <Flex wrap className={styles.categoriesWrapper}>
                {categories.map((rootCategory) => (
                    <PopularCategorySection key={rootCategory.id} rootCategory={rootCategory} />
                ))}
            </Flex>
        </div>
    );
};

export default PopularCategories;
