import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSearchFacetValues } from '@sector-labs/fe-search-redux/state';

import { useCategoryChoicesTree, ListNode } from 'horizontal/search/filters';
import { selectCategories } from 'horizontal/categories';

import { SearchLink } from '../routing';
import { useLocationFilterValue } from '../location';
import { useFreeTextQuery } from '../freeText';

import styles from './styles/categoryFilterList.cssm';

// @ts-expect-error - TS7031 - Binding element 'category' implicitly has an 'any' type. | TS7031 - Binding element 'location' implicitly has an 'any' type. | TS7031 - Binding element 'freeTextQuery' implicitly has an 'any' type.
const CategoryListNode = ({ category, location, freeTextQuery }) => {
    return (
        <SearchLink params={{ location, category, freeTextQuery }}>
            <ListNode label={`${category.name}(${category.count})`} />
        </SearchLink>
    );
};

const CategoryFilterList = () => {
    const freeTextQuery = useFreeTextQuery();
    const categoryChoicesTree = useCategoryChoicesTree();

    let countsByCategory = useSearchFacetValues('category.lvl0.externalID');
    let categories = useSelector(selectCategories);
    const location = useLocationFilterValue();

    if (!freeTextQuery || categoryChoicesTree) {
        return null;
    }

    countsByCategory = countsByCategory.slice(0, 5);
    const mappedCategories: Record<string, any> = {};

    countsByCategory.forEach((el) => {
        // @ts-expect-error - TS2538 - Type 'unknown' cannot be used as an index type.
        mappedCategories[el.key] = el.count;
    });

    categories = categories
        .filter((category) => category.externalID in mappedCategories)
        .map((category) => ({
            ...category,
            count: mappedCategories[category.externalID],
        }))
        .sort((first, second) => second.count - first.count);

    return (
        <div className={styles.categoryList}>
            {categories.map((category) => (
                <CategoryListNode
                    key={category.externalID}
                    category={category}
                    location={location}
                    freeTextQuery={freeTextQuery}
                />
            ))}
        </div>
    );
};

export default CategoryFilterList;
