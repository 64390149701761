import * as React from 'react';

import { trackPaymentInitiated } from 'horizontal/gtm';
import { useCategoryVars } from 'horizontal/gtm';

import type { PaymentOrder } from '../types';

const useTrackPaymentInitiated = (order?: PaymentOrder) => {
    const categoryVars = useCategoryVars(
        order?.productCategoryExternalID || order?.categoryExternalID,
    );

    return React.useCallback(
        (paymentSource: string) => {
            if (order) {
                trackPaymentInitiated(order, categoryVars, paymentSource);
            }
        },
        [order, categoryVars],
    );
};

export default useTrackPaymentInitiated;
