import { PropertyType, OffPlanPropertySaleType } from 'strat/property/types';
import { LocationCompletionStatus } from 'strat/locations/types';
import { PropertyCompletionStatus, PropertyData, LocationNodeData } from 'strat/property/types';
import type { AnyAdData } from 'strat/types';

import type { ProjectData } from './types';

export const isProjectLocation = ({
    completionStatus,
}: Partial<Pick<LocationNodeData, 'level' | 'completionStatus'>>): boolean => {
    return (
        !!completionStatus &&
        [LocationCompletionStatus.MIXED, LocationCompletionStatus.OFF_PLAN].includes(
            completionStatus,
        )
    );
};

export const isProject = (ad: AnyAdData): ad is ProjectData => ad.type === PropertyType.PROJECT;

export const isProjectLinked = (property: PropertyData): boolean =>
    property.completionStatus === PropertyCompletionStatus.OFF_PLAN &&
    [OffPlanPropertySaleType.RESALE, OffPlanPropertySaleType.INITIAL].includes(
        property.offplanDetails?.saleType || '',
    );

export const isResaleProjectLinked = (property: PropertyData): boolean =>
    isProjectLinked(property) &&
    property.offplanDetails?.saleType === OffPlanPropertySaleType.RESALE;

export const isNewProjectLinked = (property: PropertyData): boolean =>
    isProjectLinked(property) &&
    property.offplanDetails?.saleType === OffPlanPropertySaleType.INITIAL;

export const isUnderConstruction = (property: PropertyData): boolean =>
    property.completionStatus === PropertyCompletionStatus.OFF_PLAN;

export const isProjectOverdue = (project: ProjectData): boolean =>
    project.completionStatus === PropertyCompletionStatus.OFF_PLAN &&
    !!project.completionDetails?.completionDate &&
    project.completionDetails.completionDate < Math.floor(Date.now() / 1000);
