import React from 'react';

import type { ProjectUnit } from 'strat/project/types';
import { Flex } from 'strat/components';

import UnitsTable from '../unitTable';
import { MAX_MINIMIZED_UNITS_COUNT } from '../unitBedsGroupList';
import ExpandUnitsButton from '../expandUnitsButton';

type Props = {
    units: ProjectUnit[];
};

const CompactUnitsList = ({ units }: Props) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const showExpandButton = units.length > MAX_MINIMIZED_UNITS_COUNT;
    const unitsDisplayedList =
        isExpanded || !showExpandButton ? units : units.slice(0, MAX_MINIMIZED_UNITS_COUNT);

    return (
        <Flex column ref={containerRef}>
            <UnitsTable units={unitsDisplayedList} compact />
            {showExpandButton && (
                <ExpandUnitsButton
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    containerElement={containerRef}
                />
            )}
        </Flex>
    );
};

export default CompactUnitsList;
