import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { stopSyntheticEventPropagation } from 'strat/util';
import { ThumbnailImage } from 'strat/image';
import { Flex, Text } from 'strat/components';

import iconClose from 'horizontal/assets/icons/iconCloseThick_noinline.svg';
import iconUploadImage from 'horizontal/assets/icons/iconAddPhoto_noinline.svg';

import styles from './styles/image.cssm';

type Props = {
    readonly loading?: boolean | null;
    readonly imageID?: number | null | undefined;
    readonly src?: string | null | undefined;
    readonly remove?: () => void | null;
    readonly isCoverImage?: boolean;
    readonly isFileTooLarge?: boolean;
    readonly isResolutionTooHigh?: boolean;
    readonly uploadFailed?: boolean;
    readonly retry?: () => void | null;
    readonly isCompact?: boolean;
};

const Image = ({
    loading,
    src,
    imageID,
    remove,
    isCoverImage,
    isFileTooLarge,
    isResolutionTooHigh,
    uploadFailed,
    retry,
    isCompact,
}: Props) => {
    const onErrorClick = React.useCallback(
        (event) => {
            if (!uploadFailed) {
                return;
            }

            stopSyntheticEventPropagation(event);
            retry?.();
        },
        [retry, uploadFailed],
    );

    const errorLabel = React.useMemo(() => {
        if (!src || (!isFileTooLarge && !isResolutionTooHigh && !uploadFailed)) {
            return null;
        }

        return (
            <Flex
                justifyCenter
                className={classNames(styles.errorLabel, { [styles.clickable]: uploadFailed })}
                onClick={onErrorClick}
            >
                <Text.XSmall uppercase bold className={styles.text}>
                    {uploadFailed ? <Trans>Retry</Trans> : <Trans>Error</Trans>}
                </Text.XSmall>
            </Flex>
        );
    }, [src, isFileTooLarge, isResolutionTooHigh, uploadFailed, onErrorClick]);

    return (
        <Flex
            alignCenter
            justifyCenter
            className={classNames(styles.borderedImage, {
                [styles.loading]: loading,
                [styles.error]: isFileTooLarge || isResolutionTooHigh || uploadFailed,
                [styles.compact]: isCompact,
            })}
        >
            {imageID ? (
                <ThumbnailImage imageID={imageID} className={styles.image} />
            ) : (
                <img
                    className={src ? styles.image : styles.uploadIcon}
                    src={src || iconUploadImage}
                    alt={''}
                />
            )}
            <div className={styles.overlay} />
            {src && !loading && (
                <Flex
                    className={styles.remove}
                    onClick={(event) => {
                        remove?.();
                        stopSyntheticEventPropagation(event);
                    }}
                >
                    <img src={iconClose} alt={'Remove'} />
                </Flex>
            )}
            {src && isCoverImage && !isFileTooLarge && !isResolutionTooHigh && !uploadFailed && (
                <Flex justifyCenter className={styles.cover}>
                    <Text.XSmall uppercase bold className={styles.text}>
                        <Trans>Cover</Trans>
                    </Text.XSmall>
                </Flex>
            )}
            {errorLabel}
        </Flex>
    );
};

export default Image;
