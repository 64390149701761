import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import FilterValues from 'strat/search/filterValues';
import type { GetFilterCollection } from 'strat/search';
import type { CategoryFields } from 'strat/categoryFields';

import type { Category, FlatCategoryField } from 'horizontal/types';
import { CategoryFieldRole } from 'horizontal/types';
import {
    filterCategoryFields,
    flattenCategoryFields,
    useCategoryFields,
} from 'horizontal/categoryFields';

const selectCategoryID = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) => filters.getFirstFilterValue<Category>(FilterValues.category.attribute)?.id || null,
);

const useFilterableCategoryFields = (): CategoryFields => {
    const categoryID = useSelector(selectCategoryID);
    const categoryFields = useCategoryFields(categoryID);

    return React.useMemo(
        () =>
            filterCategoryFields(categoryFields, (field) =>
                field.roles?.includes(CategoryFieldRole.FILTERABLE),
            ),
        [categoryFields],
    );
};

const useFlatFilterableCategoryFields = (): FlatCategoryField[] => {
    const filterableCategoryFields = useFilterableCategoryFields();
    return React.useMemo(
        () => flattenCategoryFields(filterableCategoryFields, {}),
        [filterableCategoryFields],
    );
};

export { useFilterableCategoryFields, useFlatFilterableCategoryFields };
