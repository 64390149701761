import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { SortByValues } from 'strat/search/sortValues';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { selectSearchHitsLayout } from 'strat/settings/selectors';
import { selectCurrentPage, selectProject } from 'strat/search/selectors';
import { selectSortValue } from 'strat/search/state/selectors';

import ViewSections from './viewSections';

/**
 * Selects the SortByValue of the ad sorting option.
 */
const selectSortBy = createSelector(selectSortValue, (sortBy) => sortBy || SortByValues.DEFAULT);

export type ItemReferral = {
    /**
     * The referring view section.
     */
    readonly viewSection: Values<typeof ViewSections>;
    /**
     * The sort type used in the view section.
     */
    readonly viewSort?: Values<typeof SortByValues>;
    /**
     * The layout of the view section.
     */
    readonly viewLayout?: Values<typeof SearchHitsLayout>;
    /**
     * The position of the item in the view section.
     */
    readonly viewPosition?: number;
    /**
     * The offset of the item position in the view section list.
     * Used to compute the 'viewPosition' if it is not already specified.
     * (e.g. if we are on the Nth page, and there are M items per page, listOffset = (N - 1) * M)
     */
    readonly listOffset?: number;
    /**
     * The number of the page the item resides on.
     */
    readonly pageNumber?: number;

    /*
     * If the search page is a project page, we pass the project external id.
     */
    readonly projectExternalID?: string;
};

type Props = ItemReferral & {
    readonly children?: React.ReactNode;
};

/**
 * Keeps the item referral data in the context.
 */
const ItemReferralContext = React.createContext<ItemReferral | null | undefined>(null);

/**
 * Provider for {@see ItemReferralContext}.
 */
const ItemReferralContextProvider = ({ children, ...itemReferral }: Props) => (
    <ItemReferralContext.Provider value={itemReferral}>{children}</ItemReferralContext.Provider>
);

/**
 * Provider for {@see ItemReferralContext} which automatically sets the viewSort, viewLayout and
 * pageNumber.
 */
const SearchItemReferralContextProvider = ({ children, viewSection, listOffset }: Props) => {
    const viewSort = useSelector(selectSortBy);
    const viewLayout = useSelector(selectSearchHitsLayout);
    const pageNumber = useSelector(selectCurrentPage);
    const project = useSelector(selectProject);
    return (
        <ItemReferralContextProvider
            viewSection={viewSection}
            viewSort={viewSection === ViewSections.SEARCH_RESULTS ? viewSort : undefined}
            viewLayout={viewSection === ViewSections.SEARCH_RESULTS ? viewLayout : undefined}
            listOffset={listOffset}
            pageNumber={viewSection === ViewSections.SEARCH_RESULTS ? pageNumber : undefined}
            projectExternalID={project ? project.externalID : undefined}
        >
            {children}
        </ItemReferralContextProvider>
    );
};

export { ItemReferralContext, ItemReferralContextProvider, SearchItemReferralContextProvider };
