import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trigger, Triggers } from 'strat/gtm';
import { Counter } from 'strat/components';

import type { AppDispatch } from 'horizontal/state';
import iconPlus from '@app/assets/icons/cartPlusIcon_noinline.svg';
import iconMinus from '@app/assets/icons/cartMinusIcon_noinline.svg';
import { useCategoryVars } from 'horizontal/gtm';
import type { CartPackage } from 'horizontal/packages/types';
import type { PaymentProductPackageOffer } from 'horizontal/payment';

import { selectBusinessPackageCartOffers } from './selectors';
import { addToCartCounter, subtractFromCartCounter, removeFromCartCounter } from './state';

const MIN_VALUE = 1;
const MAX_VALUE = 5;

const CartEntryCounter = ({
    packageOffer,
}: {
    packageOffer: PaymentProductPackageOffer | CartPackage;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { categoryID = null } = 'categoryID' in packageOffer ? packageOffer : {};
    const categoryVars = useCategoryVars(categoryID);
    const offers = useSelector(selectBusinessPackageCartOffers);
    const displayValue = React.useMemo(() => {
        // @ts-expect-error - TS7006 - Parameter 'offer' implicitly has an 'any' type.
        const entry = offers.find((offer) => offer.packageOfferID === packageOffer.packageOfferID);
        if (entry) {
            return entry.cartQuantity;
        }
        return 0;
    }, [packageOffer, offers]);

    const isSingleItem = React.useMemo(() => displayValue === MIN_VALUE, [displayValue]);
    const addDisabled = React.useMemo(() => displayValue === MAX_VALUE, [displayValue]);
    const productItemsType = React.useMemo(
        () =>
            (packageOffer.productPackage.productItems as any[]).reduce(
                (reducer, productItem) => [...reducer, productItem.type],
                [],
            ),
        [packageOffer],
    );

    const onAdd = React.useCallback(() => {
        if (!addDisabled) {
            trigger(Triggers.CLICK_ADD_QUANTITY, {
                package_type: productItemsType,
                ...categoryVars,
            });

            dispatch(addToCartCounter(packageOffer));
        }
    }, [dispatch, packageOffer, addDisabled, productItemsType, categoryVars]);

    const onSubtract = React.useCallback(() => {
        if (!isSingleItem) {
            trigger(Triggers.CLICK_SUBSTRACT_QUANTITY, {
                package_type: productItemsType,
                ...categoryVars,
            });
            dispatch(subtractFromCartCounter(packageOffer));
        } else {
            dispatch(removeFromCartCounter(packageOffer));
        }
    }, [dispatch, packageOffer, isSingleItem, productItemsType, categoryVars]);

    return (
        <Counter
            onAdd={onAdd}
            onSubtract={onSubtract}
            displayValue={displayValue}
            addDisabled={addDisabled}
            subtractIcon={iconMinus}
            addIcon={iconPlus}
        />
    );
};

export default CartEntryCounter;
