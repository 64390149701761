import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLanguageRTL, useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Text, Flex } from 'strat/components';
import IconArrowLeft from 'strat/assets/icons/iconArrowLeft.svg';
import IconArrowRight from 'strat/assets/icons/iconArrowRight.svg';

import IconInfoCircle from 'horizontal/assets/icons/iconInfoCircle.svg';
import IconBulletsList from 'horizontal/assets/icons/iconBulletsList_noinline.svg';
import { UserWalletsSection } from 'horizontal/userWallet/userWallet';

import styles from './styles/menu.cssm';

type Props = {
    readonly section: Values<typeof UserWalletsSection> | null | undefined;
};

const Menu = ({ section }: Props) => {
    const i18n = useI18n();
    const isLanguageRTL = useSelector(selectIsLanguageRTL);

    const renderArrow = (): React.ReactElement =>
        isLanguageRTL ? (
            <IconArrowLeft className={styles.arrow} />
        ) : (
            <IconArrowRight className={styles.arrow} />
        );
    return (
        <ul className={styles.list}>
            <li className={styles.listItem} key={UserWalletsSection.OVERVIEW}>
                <Link
                    route={RouteNames.WALLET}
                    title={UserWalletsSection.OVERVIEW}
                    params={{ section: UserWalletsSection.OVERVIEW }}
                    className={styles.link}
                >
                    <Flex>
                        <div className={styles.iconContainer}>
                            <img src={IconBulletsList} className={styles.icon} />
                        </div>
                        <Text.Large>{t(i18n)`Overview`}</Text.Large>
                        {section == UserWalletsSection.OVERVIEW && renderArrow()}
                    </Flex>
                </Link>
            </li>
            <li className={styles.listItem} key={UserWalletsSection.INFO}>
                <Link
                    route={RouteNames.WALLET}
                    params={{ section: UserWalletsSection.INFO }}
                    title={UserWalletsSection.INFO}
                    className={styles.link}
                >
                    <Flex>
                        <div className={styles.iconContainer}>
                            <IconInfoCircle className={styles.icon} />
                        </div>
                        <Text.Large>{t(i18n)`Learn More`}</Text.Large>
                        {section == UserWalletsSection.INFO && renderArrow()}
                    </Flex>
                </Link>
            </li>
        </ul>
    );
};

export default Menu;
