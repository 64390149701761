import * as React from 'react';
import { Flex, Text } from 'strat/components';

import { ChoiceBoxGroup } from 'horizontal/generic/choicePage';
import type { Ad } from 'horizontal/types';

import type { LimitPackageChoice } from './types';
import PackageChoiceBox from './packageChoiceBox';
import styles from './styles/choicesSection.cssm';

type Props = {
    readonly title: string;
    readonly onClick: (arg1: LimitPackageChoice) => void;
    readonly choices: Array<LimitPackageChoice>;
    readonly selectedChoiceID: number | undefined | null;
    readonly ad?: Ad | null | undefined;
    readonly enabledChoicesId: Array<number>;
};

const ChoicesSection = ({
    title,
    choices,
    onClick,
    selectedChoiceID,
    ad,
    enabledChoicesId,
}: Props) => {
    const selectedChoices = React.useMemo(
        () => (selectedChoiceID !== undefined ? [selectedChoiceID] : []),
        [selectedChoiceID],
    );

    return (
        <Flex column className={styles.container}>
            <Flex className={styles.sectionTitle} justifySpaceBetween>
                <Text.Large bold uppercase>
                    {title}
                </Text.Large>
            </Flex>
            <ChoiceBoxGroup
                className={styles.choiceBoxGroup}
                choices={choices}
                choiceComponent={PackageChoiceBox}
                selectedChoiceIds={selectedChoices}
                enabledIds={enabledChoicesId}
                onChoiceClick={onClick}
                ad={ad}
            />
        </Flex>
    );
};

export default ChoicesSection;
