import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import RecordButton from './recordButton';
import SendButton from './sendButton';
import buttonStyles from './styles/button.cssm';
import styles from './styles/sendOrRecordButton.cssm';

type Props = {
    readonly shouldShowSendButton: boolean;
    readonly onSendMesssage: () => void;
};

const SendOrRecordButton = ({ shouldShowSendButton, onSendMesssage }: Props) => {
    return (
        <Flex alignCenter className={styles.container}>
            {shouldShowSendButton ? (
                <SendButton
                    onSendMesssage={onSendMesssage}
                    className={classNames(
                        buttonStyles.button,
                        styles.button,
                        styles.fadeInAnimationButton,
                    )}
                />
            ) : (
                <RecordButton className={classNames(buttonStyles.button, styles.button)} />
            )}
        </Flex>
    );
};

export default SendOrRecordButton;
