import { createSelector } from 'reselect';

import EMPTY_ARRAY from 'strat/empty/array';
import {
    selectUserID,
    selectDeviceID,
    selectAnonymousSessionID,
    selectClientID,
    selectFBCID,
    selectFBPID,
} from 'strat/user/selectors';
import { selectUserExternalID } from 'strat/user/session';
import Layout from 'strat/layout';
import { selectExperiments } from 'strat/experiments';
import { GlobalState } from 'strat/state';
import { selectLayout } from 'strat/layout/selectors';

import { OvationAppType } from './types';

const selectOvationAppType = createSelector(selectLayout, (layout) => {
    switch (layout) {
        case Layout.DESKTOP:
            return OvationAppType.WEB_DESKTOP;
        case Layout.TABLET:
            return OvationAppType.WEB_TABLET;
        default:
            return OvationAppType.WEB_MOBILE;
    }
});

/**
 * Selects a part of the user object for ovation from the state.
 */
const selectOvationUser = createSelector(
    selectUserID,
    selectUserExternalID,
    selectDeviceID,
    selectAnonymousSessionID,
    (userID, userExternalID, deviceID, anonymousSessionID) => ({
        client_device_id: deviceID,
        client_user_external_id: userID ? userID.toString() : userExternalID || anonymousSessionID,
        client_session_id: anonymousSessionID,
    }),
);

/**
 * Selects Google/Facebook data to be sent to Ovation for tracking
 */
const selectTrackingData = createSelector(
    selectClientID,
    selectFBPID,
    selectFBCID,
    (state: GlobalState) => state.campaign,
    (googleClientID, facebookBrowserID, facebookClickID, campaign) => ({
        google_click_id: campaign?.gclid,
        campaign_source: campaign?.source,
        campaign_medium: campaign?.medium,
        google_client_id: googleClientID,
        facebook_browser_id: facebookBrowserID,
        facebook_click_id: facebookClickID,
    }),
);

/**
 * Selects experiments currently running which should be logged to Ovation.
 * This is useful when we want to determine what is the impact of an
 * experiment on the confirmed leads (which are available just on Ovation).
 * As we will send it along the attributions variable, it has to be an array.
 * format: experiment_[experiment-name]_[experiment-variant]
 */
const selectExperimentsData = createSelector(selectExperiments, (activeExperiments) => {
    if (
        // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'string[]' and 'number' have no overlap.
        Object.keys(activeExperiments) === 0 ||
        !CONFIG.build.EXPERIMENTS ||
        CONFIG.build.DISABLE_EXPERIMENTS
    ) {
        return EMPTY_ARRAY;
    }
    const experimentAttributions: Array<any | string> = [];

    // all defined experiments - contains additional options that the active experiments don't
    const definedExperiments = CONFIG.build.EXPERIMENTS || {};
    // check which experiments must be logged to Ovation
    Object.keys(activeExperiments).forEach((expName) => {
        if (definedExperiments[expName]?.logToOvation) {
            const tag = `experiment_${expName}_${activeExperiments[expName]}`;
            experimentAttributions.push(tag);
        }
    });

    return experimentAttributions;
});

export { selectOvationUser, selectOvationAppType, selectTrackingData, selectExperimentsData };
