const NBS_SURVEY_OPTIONS = Object.freeze([
    'property_found',
    'property_not_found',
    'inaccurate_data',
    'difficult_use',
    'not_in_budget',
    'other',
]);

export { NBS_SURVEY_OPTIONS };
