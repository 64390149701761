import type { LoginResponseData } from 'strat/api/types';
import { ExpressSessionData } from 'strat/server/expressSession';

/**
 * Forward convert express session data.
 *
 * This is needed whenever we make breaking changes to
 * the format to how Express session data is stored.
 * Although any changes to the format apply to sessions
 * created _after_ the changes rolled out, existing
 * sessions aren't automagically updated.
 *
 * To allow the rest of the code to start working
 * with the new format, we perform some forwards
 * compatibility here.
 */
export const createForwardCompatibleExpressSessionData = (
    data: LoginResponseData | ExpressSessionData,
): ExpressSessionData => {
    const forwardCompatibleData: ExpressSessionData = {
        ...data,
        profile: {
            ...data.profile,
            // At some point, all IDs used to be integers. Depending
            // on the portal, this is a UUID or we encode an integer
            // as a string so that we don't care about its contents
            // ever.
            id: data.profile.id?.toString(),
            // Older sessions might be missing the `roles` field
            // because it was added only later.
            roles: data.profile.roles || [],
            // Overwritten with the correct value below
            phoneNumber: null,
        },
    };

    // `profile.phoneNumber` used to be an object split into N
    // phone numbers. Since we always just care about the user's
    // mobile number and it is rarely used, we reduced it down
    // to a single phone number.
    if (
        !!data.profile.phoneNumber &&
        typeof data.profile.phoneNumber === 'object' &&
        !Array.isArray(data.profile.phoneNumber)
    ) {
        const { mobile, phone } = data.profile.phoneNumber;
        forwardCompatibleData.profile.phoneNumber = mobile || phone || null;
    } else if (typeof data.profile.phoneNumber === 'string') {
        forwardCompatibleData.profile.phoneNumber = data.profile.phoneNumber;
    }

    return forwardCompatibleData;
};
