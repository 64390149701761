import { SearchResponseFacetValue } from '@sector-labs/fe-search-redux/backend';

import { Location, TransactionPurpose, Category } from 'horizontal/types';

type ProfileFilteredParams = {
    readonly purpose: Values<typeof TransactionPurpose>;
    readonly location: Location | null;
};

export const isProfileFiltered = ({ purpose, location }: ProfileFilteredParams) => {
    if (purpose !== TransactionPurpose.ALL || location?.level) {
        return true;
    }
    return false;
};

/**
 * utility function to calculate the counts of `for-sale` or `for-rent`
 * properties across multiple categories, these counts depend on the
 * return value of the facets aggregations in ElasticSearch
 */
export const calculatePurposesCount = (
    categoryFacets: SearchResponseFacetValue[],
    propertiesCategory?: Category | null,
) => {
    if (!categoryFacets || !categoryFacets.length) {
        return [undefined, undefined, undefined];
    }
    let forSale = 0,
        forRent = 0;
    for (let i = 0; i < categoryFacets.length; i++) {
        const matchedCategory = propertiesCategory?.children.find(
            (category) => categoryFacets[i].value === category.externalID,
        );
        if (matchedCategory?.purpose === TransactionPurpose.FOR_SALE) {
            forSale += categoryFacets[i].count;
        } else if (matchedCategory?.purpose === TransactionPurpose.FOR_RENT) {
            forRent += categoryFacets[i].count;
        }
    }
    return [forSale + forRent, forSale, forRent];
};
