import { fetchCategoryFields } from 'strat/categoryFields/state';

import { MatchParams } from 'horizontal/routes/search/types';

const fetchAndStoreCategoryFields = (
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    dispatch: Dispatch,
    { categorySlug, categoryExternalID }: MatchParams,
): Promise<void> => {
    return dispatch(
        fetchCategoryFields({
            ...(categorySlug && { categorySlugs: [categorySlug] }),
            ...(categoryExternalID && { categoryExternalIDs: [categoryExternalID] }),
            includeChildCategories: true,
        }),
    );
};

export default fetchAndStoreCategoryFields;
