import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import Currency from 'strat/i18n/money';
import settings from '@app/branding/settings';

import { priceFilterMaximumOptions, priceFilterMinimumOptions } from '../constants';
import { RangeFilterType } from '../types';

import RangeFilter from './verticalRangeFilter';

type Props = {
    readonly value: RangeFilterType;
    readonly titleCustomClass?: string;
    readonly setValue: (value: RangeFilterType) => void;
};

const PriceRangeFilter = ({ value, setValue, titleCustomClass }: Props) => {
    const i18n = useI18n();

    return (
        <RangeFilter
            maxChoices={priceFilterMaximumOptions}
            minChoices={priceFilterMinimumOptions}
            placeholderMax={t(i18n)`Any`}
            placeholderMin={'0'}
            filterUnit={Currency.symbol(i18n, settings.defaultCurrencyName)}
            value={value}
            onValueSelected={(value) => setValue(value)}
            title={t(i18n)`Price`}
            displayCompact
            titleClassname={titleCustomClass}
        />
    );
};
export default PriceRangeFilter;
