import * as React from 'react';

import useShouldShowHitsAndNoHits from 'horizontal/search/useShouldShowHitsAndNoHits';

import { useShowRemarketedListing } from './state';
import { FeaturedAgencies } from './featuredAgencies';
import SearchResults from './searchResults';
import styles from './styles/mainSearchPageContent.cssm';
import { RemarketingAd } from './remarketing';
import { RemarketingSectionDelimiter } from './results';

const MainSearchPageContent = () => {
    const showRemarketedListing = useShowRemarketedListing();
    const { shouldShowHitsSection, shouldShowNoHitsSection } = useShouldShowHitsAndNoHits();
    return (
        <div className={styles.results}>
            {showRemarketedListing && (
                <>
                    <RemarketingAd />
                    {(shouldShowHitsSection || shouldShowNoHitsSection) && (
                        <RemarketingSectionDelimiter />
                    )}
                </>
            )}
            {!showRemarketedListing && <FeaturedAgencies />}
            <SearchResults />
        </div>
    );
};

export default MainSearchPageContent;
