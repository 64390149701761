import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { StylelessButton } from 'strat/generic';
import { selectIsLanguageRTL } from 'strat/i18n/language/selectors';
import { Flex } from 'strat/components';
import { stopSyntheticEventPropagation } from 'strat/util';

import IconMasterCard from 'horizontal/assets/icons/iconMasterCard.svg';
import IconVisa from 'horizontal/assets/icons/iconVisa.svg';
import IconDelete from 'horizontal/assets/icons/iconDelete.svg';
import { useMonthTranslator } from 'horizontal/util';
import DeleteSavedCardConfirmationDialog from 'horizontal/payment/deleteSavedCardConfirmationDialog';

import type { CheckoutCreditCard } from './types';
import styles from './styles/creditCardItem.cssm';
import useCheckoutDeleteCard from './hooks/useCheckoutDeleteCard';

const CHECKOUT_SCHEME_TYPES = Object.freeze({
    VISA: 'Visa',
    MASTERCARD: 'Mastercard',
});

type CreditCardItemProps = {
    disabled?: boolean;
    card: CheckoutCreditCard;
    onClick?: (card: CheckoutCreditCard) => void;
};

const CreditCardItem = (props: CreditCardItemProps) => {
    const { card, onClick, disabled } = props;
    const { id, scheme, last4, expiryMonth, expiryYear } = card;
    const translateMonth = useMonthTranslator();
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);
    const { deleteCard } = useCheckoutDeleteCard(card, () => setIsDeleted(true));
    const isRTL = useSelector(selectIsLanguageRTL);

    const icon = React.useCallback((cardType) => {
        switch (cardType) {
            case CHECKOUT_SCHEME_TYPES.MASTERCARD:
                return <IconMasterCard />;

            case CHECKOUT_SCHEME_TYPES.VISA:
            default:
                return <IconVisa />;
        }
    }, []);

    const onItemSelected = (card: CheckoutCreditCard) => {
        onClick?.(card);
    };

    if (isDeleted) {
        return null;
    }

    return (
        <StylelessButton
            key={id}
            onClick={() => onItemSelected(card)}
            className={classNames(styles.cardItemContainer, { [styles.disabled]: disabled })}
        >
            <div>{scheme && icon(scheme)}</div>
            <div className={styles.cardText}>
                <div dir={isRTL ? 'rtl' : 'ltr'}>
                    <b>
                        {scheme} **** {last4}
                    </b>
                </div>
                <div className={styles.cardTextFootnote}>
                    <Trans>
                        Expiry Date {translateMonth(parseInt(expiryMonth))}/{expiryYear}
                    </Trans>
                </div>
            </div>
            {!disabled && (
                <Flex justifyEnd fillContainer>
                    <IconDelete
                        className={styles.deleteButton}
                        onClick={(event) => {
                            stopSyntheticEventPropagation(event);
                            setDeleteDialogVisible(true);
                        }}
                    />
                </Flex>
            )}
            <DeleteSavedCardConfirmationDialog
                onConfirm={deleteCard}
                onCancel={() => setDeleteDialogVisible(false)}
                visible={deleteDialogVisible}
            />
        </StylelessButton>
    );
};

export default CreditCardItem;
