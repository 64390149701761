import * as React from 'react';
import { Dropdown } from 'strat/modal';
import LoadingSpinner from 'strat/components/loadingSpinner';

import { trackNotificationClick } from 'horizontal/gtm';

import useNotifications from './useNotifications';
import NotificationsIcon from './notificationsIcon';
import ObservedNotificationsList from './observedNotificationsList';
import styles from './styles/notificationsDropdown.cssm';

const NotificationsDropdown = () => {
    const {
        notifications,
        fetchNotificationsIfViewed,
        markAsViewed,
        markAsRead,
        fetchNextPage,
        newNotifications,
        loading,
        totalNotificationsCount,
    } = useNotifications();

    const renderTrigger = React.useCallback(
        (openDropdown, isOpen) => {
            const onClick = () => {
                openDropdown();
                if (!isOpen) {
                    trackNotificationClick();
                    markAsViewed();
                } else {
                    fetchNotificationsIfViewed();
                }
            };
            return <NotificationsIcon onClick={onClick} newNotifications={newNotifications} />;
        },
        [markAsViewed, fetchNotificationsIfViewed, newNotifications],
    );

    return (
        <div className={styles.container}>
            <Dropdown renderTrigger={renderTrigger} onDismissed={fetchNotificationsIfViewed}>
                <div className={styles.dropdown}>
                    <ObservedNotificationsList
                        notifications={notifications}
                        loading={loading}
                        totalNotificationsCount={totalNotificationsCount}
                        markAsRead={markAsRead}
                        fetchNextPage={fetchNextPage}
                    />
                    {loading && (
                        <div className={styles.loadingSpinnerWrapper}>
                            <LoadingSpinner dark />
                        </div>
                    )}
                </div>
            </Dropdown>
        </div>
    );
};

export default NotificationsDropdown;
