import * as React from 'react';

import { getBrandName } from 'strat/branding';

import styles from './styles/loadingPage.cssm';

const LoadingPage = {
    head: () => <title>{getBrandName()}</title>,
    body: () => (
        <div className={styles.loadingContainer}>
            <div className={styles.ldsHourglass} />
        </div>
    ),
} as const;

export default LoadingPage;
