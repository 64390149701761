import { t } from '@lingui/macro';
import * as React from 'react';
import { ThumbnailSizes } from 'strat/image';
import { useI18n } from 'strat/i18n/language';
import { formatDate } from 'strat/i18n/language/dateTimeFormatter';
import { makeCancelable } from 'strat/util';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Flex, Text, Pill } from 'strat/components';

import { CoverPhoto } from 'horizontal/adCard';
import { useFormattedPrice } from 'horizontal/util';
import { AdPhoto, DeliveryBuyingOrder } from 'horizontal/types';
import { StratAPI } from 'horizontal/api';
import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';

import { DeliveryRequestStatus } from '../types';

import styles from './styles/sellingOrderCard.cssm';
import SellingOrderCardExpansion from './sellingOrderCardExpansion';

type props = {
    readonly userExternalID: string;
    readonly adExternalID: string;
    readonly currentTab: Values<typeof DeliveryRequestStatus> | null | undefined;
    readonly coverPhoto: AdPhoto;
    readonly sellingOrderPrice: number;
    readonly adCreatedAt?: number;
    readonly title?: string;
    readonly adSlug: string;
};

const FORMATTER_OPTIONS = {
    year: '2-digit',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
} as const;

const SellingOrdersCard = ({
    userExternalID,
    adExternalID,
    currentTab,
    adCreatedAt,
    adSlug,
    title,
    coverPhoto,
    sellingOrderPrice,
}: props) => {
    const i18n = useI18n();
    const price = useFormattedPrice(sellingOrderPrice);
    const formattedDate = adCreatedAt
        ? formatDate(new Date(adCreatedAt * 1000), i18n.locale, FORMATTER_OPTIONS)
        : '';
    const [orderDetails, setOrderDetails] = React.useState<DeliveryBuyingOrder[]>([]);
    const [orderDetailExpanded, setOrderDetailExpanded] = React.useState(false);

    React.useEffect(() => {
        const cancelablePromise = makeCancelable(
            // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
            new StratAPI(i18n.locale).sellingOrderDetail(adExternalID, currentTab),
        );
        cancelablePromise.then((response) => {
            setOrderDetails(response.data);
        });
        return () => {
            cancelablePromise.cancel();
        };
    }, [userExternalID, i18n, adExternalID, currentTab]);

    return (
        <div className={styles.sellingOrdersCard}>
            <Flex className={styles.cardHeader}>
                <Flex column fillContainer className={styles.date} justifyCenter alignCenter>
                    <Text.Regular aria-label="Order date">{formattedDate}</Text.Regular>
                </Flex>

                <Flex column fillContainer>
                    <Flex className={styles.details} alignCenter justifySpaceBetween>
                        <Link
                            route={RouteNames.AD_DETAILS}
                            params={{ slug: adSlug, externalID: adExternalID }}
                        >
                            <Flex className={styles.adTitledImage}>
                                <Flex className={styles.coverPhoto}>
                                    <CoverPhoto
                                        cover={coverPhoto}
                                        size={ThumbnailSizes.TINY}
                                        lazyLoad
                                    />
                                </Flex>
                                <Flex className={styles.title} aria-label="order title" alignCenter>
                                    <Text.Regular bold>{title}</Text.Regular>
                                </Flex>
                            </Flex>
                        </Link>
                        <Flex className={styles.price} justifySpaceBetween>
                            <Text.Base>{price}</Text.Base>
                            <Pill.Accentuated>
                                <Text.Regular>{t(
                                    i18n,
                                )`${orderDetails.length} orders`}</Text.Regular>
                            </Pill.Accentuated>
                        </Flex>
                        <div
                            onClick={() => setOrderDetailExpanded(!orderDetailExpanded)}
                            className={
                                orderDetailExpanded ? styles.iconArrowUp : styles.iconArrowDown
                            }
                        >
                            <img src={iconArrowDown} alt={'Select toggle'} />
                        </div>
                    </Flex>
                </Flex>
            </Flex>
            {orderDetailExpanded && (
                <Flex column className={styles.cardExpansionArea}>
                    {orderDetails.length > 0 &&
                        orderDetails.map((order) => (
                            <SellingOrderCardExpansion
                                key={order.id}
                                orderStatus={order.status}
                                deliveryAddress={order.deliveryAddress}
                                userExternalID={userExternalID}
                                createdAt={order.createdAt}
                                buyerName={order.buyer.name}
                                // @ts-expect-error
                                buyerPhoneNumber={order.buyer.phoneNumber}
                                offerID={order.id}
                                price={order.price || sellingOrderPrice}
                                deliveryType={order.deliveryType}
                            />
                        ))}
                </Flex>
            )}
        </div>
    );
};

export default SellingOrdersCard;
