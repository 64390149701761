import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';
import { selectIsMobileLayout } from 'strat/layout/selectors';
import { Link } from 'strat/navigation';

import type { ChatRoom } from 'horizontal/chat/types';
import { useProfileDetails } from 'horizontal/chat/hooks';
import { Username } from 'horizontal/chat/components';

import OnlineStatus from './onlineStatus';
import styles from './styles/userDetails.cssm';

type Props = {
    readonly conversation: ChatRoom;
};

const UserDetails = ({ conversation }: Props) => {
    const { contactXID, routeName, routeParams } = useProfileDetails(conversation);
    const isMobile = useSelector(selectIsMobileLayout);

    return (
        <Flex column className={styles.container}>
            <Flex alignCenter>
                <Link route={routeName} params={routeParams} className={styles.link}>
                    <Username conversation={conversation} compact={isMobile} />
                </Link>
            </Flex>
            {contactXID && <OnlineStatus userId={contactXID} />}
        </Flex>
    );
};

export default UserDetails;
