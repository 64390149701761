import { Range } from 'strat/types';

import { ProtoolAgencyLeadsFilterParams, LeadType } from 'horizontal/agencyPortal/types';

const decodeLeadsFilter = (filter?: string | null): Partial<ProtoolAgencyLeadsFilterParams> => {
    if (!filter) {
        return {};
    }

    return decodeURIComponent(filter)
        .split(',')
        .map((item) => item.split('_eq_') as [string, string])
        .reduce<Record<string, any>>((acc, [key, value]) => {
            switch (key) {
                case 'q':
                    acc.freeTextSearch = value;
                    break;
                case 'leadType':
                    acc.leadType = value as LeadType;
                    break;
                case 'adID':
                    acc.adExternalID = value;
                    break;
                case 'agent':
                    acc.agentExternalIDs = [value];
                    break;
                case 'createdAt':
                    acc.timestamp = extractEncodedDate(value);
                    break;
                case 'excludeAnonymous':
                    acc.excludeAnonymousLead = value == 'yes' ? true : undefined;
                    break;
            }
            return acc;
        }, {});
};

const encodeLeadFilter = (
    filter: Partial<ProtoolAgencyLeadsFilterParams>,
): string | null | undefined => {
    const items: Array<[string, string]> = [];

    if (filter.freeTextSearch) {
        items.push(['q', filter.freeTextSearch]);
    }

    if (filter.agentExternalIDs) {
        items.push(['agent', filter.agentExternalIDs[0]]);
    }
    if (filter.leadType) {
        items.push(['leadType', filter.leadType]);
    }

    if (filter.adExternalID) {
        items.push(['adID', filter.adExternalID]);
    }
    if (filter.timestamp) {
        items.push(['createdAt', `from${filter.timestamp.min}to${filter.timestamp.max}`]);
    }

    if (filter.excludeAnonymousLead) {
        items.push(['excludeAnonymous', 'yes']);
    }

    const result = items.map((item) => item.join('_eq_')).join(',');
    return result ? encodeURIComponent(result) : undefined;
};

const extractEncodedDate = (date: string): Range | null | undefined => {
    if (date.includes('from') && date.includes('to')) {
        const splittedDate = [
            date.slice(date.indexOf('from'), date.indexOf('to')),
            date.slice(date.indexOf('to')),
        ];
        return {
            min: Number(splittedDate[0].split('from')[1]),
            max: Number(splittedDate[1].split('to')[1]),
        };
    }
    return null;
};

export { decodeLeadsFilter, encodeLeadFilter };
