import React from 'react';

import { TPayPaymentOTP } from 'horizontal/payment/tpay';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly phoneNumber: string;
    readonly sessionID: string;
    readonly orderExternalID: string;
};

const PaymentTPayOTPPage = {
    head: () => <PrivateNonIndexableHead />,
    body: (props: Props) => {
        return <TPayPaymentOTP {...props} />;
    },
} as const;

export default PaymentTPayOTPPage;
