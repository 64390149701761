import { t } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import Category from '@app/branding/category';

import { MixedAd } from 'horizontal/types';

const useRentalPeriod = (ad: MixedAd) => {
    const i18n = useI18n();
    const rentalPeriod = ad.extraFields?.rental_period;

    if (!rentalPeriod) {
        return '';
    }
    const adCategory = ad?.category[0];

    const rentalPeriodValuesForProperties: { [key: string]: string } = {
        '1': t(i18n)`Daily`,
        '2': t(i18n)`Weekly`,
        '3': t(i18n)`Monthly`,
        '4': t(i18n)`Yearly`,
    };

    const rentalPeriodValuesForVehicle: { [key: string]: string } = {
        '1': t(i18n)`Daily`,
        '2': t(i18n)`Monthly`,
        '3': t(i18n)`Yearly`,
    };

    if (Category.isOfPropertyType(adCategory)) {
        return rentalPeriodValuesForProperties[rentalPeriod] || '';
    } else {
        return rentalPeriodValuesForVehicle[rentalPeriod] || '';
    }
};

export default useRentalPeriod;
