import { makeCancelable } from 'strat/util';

import { StratAPI } from 'horizontal/api';
import { PaymentOrderPayload, PaymentOrderPlatform } from 'horizontal/payment/types';

export default function createOrderAndPayment(
    userExternalID: string,
    applyService: boolean,
    adID: number | undefined,
    packageOfferId: string,
    successCallback: (successMsg: string) => void,
    errorCallback: (error: string) => void,
    setLoading: (loading: boolean) => unknown,
) {
    const params: PaymentOrderPayload = {
        platform: PaymentOrderPlatform.TELESALES,
        product_package_offers: applyService
            ? [{ external_id: packageOfferId, ad_id: adID }]
            : [{ external_id: packageOfferId, quantity: 1 }],
    };

    setLoading(true);

    const cancelablePromise = makeCancelable(new StratAPI().createOrder(params, userExternalID));
    cancelablePromise
        .then((response) => {
            if (response.data.orderExternalID) {
                const fawryParams = {
                    orderExternalId: response.data.orderExternalID.toString(),
                    paymentMethod: 'reference_number',
                } as const;

                return new StratAPI()
                    .fawryPayment(fawryParams, userExternalID)
                    .then((paymentRes) => {
                        successCallback(
                            `Payment order is created with payment ID ${paymentRes.data.referenceNumber} and order ID ${response.data.orderExternalID}`,
                        );
                    })
                    .catch(() => {
                        errorCallback(
                            'Error in sending payment link to user, Fawry gateway returned error',
                        );
                    });
            }
        })
        .catch(() => {
            errorCallback("Couldn't create payment source order");
        })
        .finally(() => {
            setLoading(false);
        });
    return () => {
        cancelablePromise.cancel();
    };
}
