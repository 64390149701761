import FilterValues from '@app/search/filterValues';
import { updateSearchHistory } from '@app/user/state';
import type { Dispatch } from 'redux';
import { FilterCollection, SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';
import { ObjectExactFilter } from '@sector-labs/fe-search-redux/filters';

import { Location } from 'horizontal/types';

const updateSearchHistoryWithLocation = (
    // @ts-ignore
    dispatch: Dispatch,
    searchHistory: Array<SerializedFilterCollection>,
    locationFilter: ObjectExactFilter<Location> | null,
) => {
    if (searchHistory?.length) {
        const filters = new FilterCollection(searchHistory[0]);
        const lastSearchHistoryLocation = filters.getFilterValue<Location, null>(
            FilterValues.location.attribute,
            FilterValues.location.default,
        );
        const currentLocation = locationFilter?.value;
        if (lastSearchHistoryLocation?.externalID !== currentLocation?.externalID) {
            filters.refine(locationFilter);
            dispatch(updateSearchHistory(filters));
        }
    } else {
        const filters = new FilterCollection();

        if (locationFilter) {
            filters.refine(locationFilter);
        }
        dispatch(updateSearchHistory(filters));
    }
};

export default updateSearchHistoryWithLocation;
