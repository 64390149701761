import type { LiteCategory, LiteHierarchicalLocation, Location } from 'horizontal/types';

import SitemapPageType from '../sitemapPageType';
import type { SitemapCategory } from '../types';

const Actions = Object.freeze({
    SET_PAGE_TYPE: 'SITEMAP/SET_PAGE_TYPE',
    SET_POPULAR_SEACHES: 'SITEMAP/SET_POPULAR_SEARCHES',
    SET_SITEMAP_CATEGORIES: 'SITEMAP/SET_SITEMAP_CATEGORIES',
});

type CategoryPopularSearch = {
    readonly category?: LiteCategory | null | undefined;
    readonly popularSearchEntry: Array<string>;
};

type SitemapElement = LiteHierarchicalLocation & {
    readonly categories: Array<SitemapCategory>;
};

type PopularSearches = {
    readonly category?: LiteCategory | null | undefined;
    readonly location?: Location | null | undefined;
    readonly popularSearches?: Array<CategoryPopularSearch> | null | undefined;
    readonly childLocations?: Array<Location | null | undefined>;
};

type SetPageTypeAction = {
    readonly type: Values<typeof Actions>;
    readonly pageType: Values<typeof SitemapPageType>;
    readonly popularSearches?: PopularSearches;
    readonly categories?: SitemapElement;
};

type SetPopularSearchesAction = {
    readonly type: Values<typeof Actions>;
    readonly popularSearches: PopularSearches;
    readonly pageType?: Values<typeof SitemapPageType>;
    readonly categories?: SitemapElement;
};

type SetSitemapCategoriesAction = {
    readonly type: Values<typeof Actions>;
    readonly popularSearches: PopularSearches;
    readonly pageType?: Values<typeof SitemapPageType>;
    readonly categories?: SitemapElement;
};

export type State = {
    readonly pageType: Values<typeof SitemapPageType>;
    readonly popularSearches?: PopularSearches;
};

type Action = SetPageTypeAction | SetPopularSearchesAction | SetSitemapCategoriesAction;

const defaultState = {
    pageType: SitemapPageType.MOST_POPULAR,
} as const;

const sitemapReducer = (state: State | null | undefined = defaultState, action: Action) => {
    switch (action.type) {
        case Actions.SET_PAGE_TYPE:
            return {
                ...state,
                pageType: action.pageType,
            };
        case Actions.SET_POPULAR_SEACHES:
            return {
                ...state,
                popularSearches: action.popularSearches,
            };
        case Actions.SET_SITEMAP_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        default:
            return {
                ...state,
                // @ts-expect-error - TS2531 - Object is possibly 'null'.
                pageType: state.pageType || SitemapPageType.MOST_POPULAR,
            };
    }
};

const setPageType = (pageType: Values<typeof SitemapPageType>) => ({
    type: Actions.SET_PAGE_TYPE,
    pageType,
});

const setPopularSearches = (popularSearches: PopularSearches) => ({
    type: Actions.SET_POPULAR_SEACHES,
    popularSearches,
});

const setSitemapCategories = (categories: Array<any>) => ({
    type: Actions.SET_SITEMAP_CATEGORIES,
    categories,
});

export { setPageType, setPopularSearches, setSitemapCategories };

export default sitemapReducer;
