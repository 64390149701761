import * as React from 'react';
import { Trans } from '@lingui/macro';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { Text, ListEntry } from 'strat/components';
import iconLogout from '@app/assets/icons/iconLogout_noinline.svg';

type Props = {
    readonly onClick: () => void;
    readonly ariaLabel?: string;
};

const renderLeft = () => <img alt={'Logout link'} src={iconLogout} />;

const LogoutLink = ({ onClick, ariaLabel }: Props) => {
    const onTrackedClick = React.useCallback(() => {
        trigger(Triggers.LOGOUT, { view_section: ViewSections.USER_DETAIL });
        onClick();
    }, [onClick]);

    return (
        <ListEntry
            borderless
            hoverable
            renderLeft={renderLeft}
            renderRight={null}
            onClick={onTrackedClick}
            ariaLabel={ariaLabel}
        >
            <Text.Large>
                <Trans>Logout</Trans>
            </Text.Large>
        </ListEntry>
    );
};

export default LogoutLink;
