import * as React from 'react';
import { RouteNames } from 'strat/routes';
import { useRouter } from 'react-true-router';

import type { SearchRouteParams } from 'horizontal/routes';

import useUpdateFiltersPreemptively from './useUpdateFiltersPreemptively';

type UseNavigateToSearch = (arg1: SearchRouteParams) => void;

const useNavigateToSearch = (): UseNavigateToSearch => {
    const router = useRouter();
    const updateFiltersPreemptively = useUpdateFiltersPreemptively();

    return React.useCallback(
        (params: SearchRouteParams) => {
            updateFiltersPreemptively(params);
            router.pushRoute(RouteNames.SEARCH, params);
        },
        [router, updateFiltersPreemptively],
    );
};

export default useNavigateToSearch;
