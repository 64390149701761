import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';
import MobileAppDownload from '@app/branding/mobileAppDownload';
import { Flex } from 'strat/components';

import olxMobileAppWEBP from 'horizontal/assets/images/olxMobileApp.webp';
import olxMobileAppJPG from 'horizontal/assets/images/olxMobileApp.jpg';

import styles from './styles/appInstallBanner.cssm';

const AppInstallBanner = () => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    return (
        <Flex stretchWidth justifyCenter className={styles.container}>
            <Flex justifySpaceBetween={styles.content}>
                <picture>
                    <source srcSet={olxMobileAppWEBP} type="image/webp" />
                    <source srcSet={olxMobileAppJPG} type="image/jpg" />
                    <img className={styles.image} src={olxMobileAppJPG} alt={'OLX mobile app'} />
                </picture>

                <div className={styles.middleText}>
                    <div>
                        <Trans>Try the {brandName} app</Trans>
                    </div>
                    <div>
                        <Trans>
                            Buy, sell and find just about anything using the app on your mobile.
                        </Trans>
                    </div>
                </div>

                <Flex alignCenter>
                    <div className={styles.divider} />
                </Flex>

                <div className={styles.appLinks}>
                    <div>
                        <Trans>Get your app today</Trans>
                    </div>
                    <MobileAppDownload iconClassName={styles.icon} />
                </div>
            </Flex>
        </Flex>
    );
};

export default AppInstallBanner;
