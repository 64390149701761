import * as React from 'react';

import { SocialTitleTags } from 'strat/social/tags';

type Props = {
    children: string;
};

const ListingPageTitle = ({ children }: Props) => (
    <>
        <title>{children}</title>
        <SocialTitleTags title={children} />
    </>
);

export default ListingPageTitle;
