import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import Area from 'strat/i18n/area';
import Currency from 'strat/i18n/money/currency';
import { type BrandingSettings, LoginFormType } from 'strat/branding/types';
import Layout from 'strat/layout';
import SearchHitsLayout from '@app/branding/searchHitsLayout';
import { Slots } from '@sector-labs/fe-search-redux/slots';
import { QueryFilterFuzzyOptions } from '@sector-labs/fe-search-redux/filters/queryFilter';

import { TransactionPurpose } from 'horizontal/types';
import { AdCommonExtraField } from '@app/branding/adExtraFields';
import { SearchPageSearchType } from 'horizontal/search/types';
import { trackAboutEMPG, trackContactUs } from 'horizontal/gtm/pageTracking';
import { PaginationType, CTA } from 'horizontal/types';

import { languages } from './languages';
import Category from './category';

const topLevelLocationWithoutHierarchy = {
    id: 1,
    externalID: '1000001',
    level: 0,
    name: 'Pakistan',
    slug: 'pakistan_g1000001',
};

export type HorizontalSettings = BrandingSettings & {
    topCategories: string[];
    popularCategories: readonly string[];
    themeColor: string;
    featuredAdsGroupSizes: (_arg: number) => { default: number };
    enableFeaturedBadge: boolean;
    enableContactMethodControl: boolean;
    enableSocialMedia: boolean;
    enableBusinessPackages: boolean;
    paginationType: Values<typeof PaginationType>;
    defaultAllowChatCommunication: boolean;
    priceSortFields: string[];
    searchPageSearchType: Values<typeof SearchPageSearchType>;
    eliteAdsPerPageCount: number;
    aboutUsLinks: (i18n: I18n) => {
        name: string;
        url: string;
        event?: () => void;
    }[];
    nativeDfpCount: { initial_step: number; step: number };
    enableNativeDfp: {
        categories: Values<typeof Category>[];
        layouts: Values<typeof Layout>[];
    };
    enableLockMileageField: boolean;
    maxNumberOfBadgesForAdListItem: number;
    enableOtpValidation: boolean;
    posting_guidelines: {
        [language in 'en' | 'ar']: string;
    };
    tipsForImprovingAdSellingChances: {
        [language in 'en' | 'ar']: string;
    };
    allAboutPostingAds: {
        [language in 'en' | 'ar']: string;
    };
    support_request: {
        [language in 'en' | 'ar']: string;
    };
    categoriesWithCustomBreadcrumbs: string[];

    bumpupStateDescriptionText: boolean;
    allowSearchByAdExternalID: boolean;
    installAppBannerSubtitle: (i18n: I18n) => string;
    maxLocationLevelIndexationByCategory: {};
    enableL2CategoriesSort: boolean;
    maxSearchPages: number; // nginx redirects on page 200
    defaultDaysDuration: number;
    splitCategoriesDropdown: boolean;
    extraLocationsCheckRule: boolean;
    favoriteAdsThreshold: number;
    popularChoicesNumber: number;
    useMostRelevantAsNewlyListed: boolean;
    enableAdDetailsMap: boolean;
    enableAdDetailsAppInstallBanner: boolean;
    enableHomePageWallpaper: boolean;
    enableHomePageAllCategories: boolean;
    enableHomePagePopularCategories: boolean;
    enableHomePageSearchHistoryRecommendations: boolean;
    homePageCategoriesWithHitsIdentifiers: string[];
    homePagePromotedSearches: {
        displayCategorySlug: string;
        searchCategorySlug: string;
        locationExternalID: string;
        displayTitle: (i18n: I18n) => string;
    }[];
    hasBankPaymentMethodEnabled: boolean;
    freeAdTerms: {
        pledge: {
            [language: string]: string;
        };
        author: { [language: string]: string };
        note: { [language: string]: string };
    };
    minFreeAdCommission: number;
    maxFreeAdCommission: number;
    enableLayoutSwitcher: number;
    useOlxSitemap?: boolean;
    favouriteAdsLayoutMobile: Values<typeof SearchHitsLayout>;
    disableWallet?: boolean;
    disableAgency?: boolean;
    disableCommercialNumber?: number;
    startPaginationFromPageZero?: boolean;
    showWelcomeLocationSelectionDialog?: boolean;
    hidePopularSearchesOnQueryFilter?: boolean;
    disableCategoryFieldsWithoutCategory?: boolean;
    disableFilterByCategory?: boolean;
    disableFilterByAdId?: boolean;
    vatNumberRequired?: boolean;
    searchFuzzySettings?: QueryFilterFuzzyOptions;
    deliverySettings?: {
        allowDeliveryFromAnyLocation: boolean;
        disableTermsConditionsCheckbox: boolean;
        disableLinks: boolean;
        displayDeliveryPricingButton: boolean;
    };
    appDescription?: {
        iosAppName: string;
        androidAppName: string;
    };
    parseLocationNameWithCity?: boolean;
    enableMobileBannersCarousel?: boolean;
    verticalsInCategoryWidget?: {
        name: string;
        slug: string;
    }[];
    featuredSort?: {
        type: string;
        key: string;
        name: string;
        attributes: { name: string; order: string }[];
    };
    multipleCurrencyPackages?: boolean;
    commercialNumberRequired?: boolean;
    enableCustomizedPackagesOptions?: boolean;
    extendedAdCardHeight?: true;
    areaFilterAttributeKey?: string;
    enableBlog?: boolean;
    enableContactSalesTeam?: boolean;
    verticalProperties: {
        externalID: string;
        categoryID: number;
        promotedAdsSearchParams: {
            exactFilters: {
                attribute: string;
                value: string;
            }[];
            refineFilters: {
                attribute: string;
                value: string[];
            }[];
            rangeFilters: {
                attribute: string;
                value: { min?: number; max?: number };
            }[];
        };
    };
    cashMyCar: {
        enabled: boolean;
        verifiedCarAgencySlug?: string;
        termsConditionsLink?: string;
        firstYearOption?: number;
    };
    showTwitterSocialMediaIconInFooter: boolean;
    showLinkedInSocialMediaIconInFooter: boolean;
    showTikTokSocialMediaIconInFooter: boolean;
    headerWithoutFiltersSmallLogo?: boolean;
    homePageLimitedLocations: string[];
    agencyProfileDefaultCategoryFilter: {
        [purpose: string]: string[];
    };
    combineMakeAndModelOnBreadcrumbs: boolean;
    breadcrumbsCityLevel: number;
    allowsPrimaryContactButton: boolean;
    dpvCTAConfigs: {
        [key in CTA]: {
            order: number;
            orderWithDelivery: number;
            canBePrimary: boolean;
        };
    };
    packagesUsageDuration: number;
    displayEmaiLinkInLoginOptions?: boolean;
    lpvCTAConfigs: {
        [key in CTA]: {
            order: number;
            canBeSecondary: boolean;
        };
    };
    agencyCTAButtonsConfig: {
        [key in CTA]: {
            order: number;
            orderWithDelivery: number;
            canBePrimary: boolean;
        };
    };
    addressPlaceholderText: (i18n: I18n) => {
        addressLineOne: string;
        addressLineTwo: string;
    };
    deepLinkAppName: string;
    routePathPrefixesWithExternallyManagedSession: string[];
    searchFiltersMaxVisibileCount?: number;
    hideRentalPeriodInAdCard?: boolean;
    downPaymentValidationMultiplier?: number;
    phoneInputFormat?: string;
    getAgencyPortalBrandName: (i18n: I18n) => string;
};

// @ts-expect-error - TS2322 - Type '{ readonly getBrandName: (i18n: I18n) => string; readonly getDomainName: (i18n: I18n) => string; readonly countryName: (i18n: I18n) => string; readonly getTopLevelLocation: (i18n: I18n) => { name: string; hierarchy: { name: string; id: 1; externalID: "1000001"; level: 0; slug: "pakistan_g1000001"; }[]; id: 1; extern...' is not assignable to type 'HorizontalSettings'.
const settings: HorizontalSettings = {
    getBrandName: (i18n: I18n): string => t(i18n)`OLX`,
    getDomainName: (i18n: I18n): string => t(i18n)`OLX Pakistan`,
    countryName: (i18n: I18n): string => t(i18n)`Pakistan`,
    getTopLevelLocation: (i18n: I18n) => ({
        ...topLevelLocationWithoutHierarchy,
        name: t(i18n)`Pakistan`,
        hierarchy: [
            {
                ...topLevelLocationWithoutHierarchy,
                name: t(i18n)`Pakistan`,
            },
        ],
    }),
    addressPlaceholderText: (i18n: I18n) => ({
        addressLineOne: t(i18n)`Street, Building`,
        addressLineTwo: t(i18n)`Floor, Apartment, etc`,
    }),
    defaultPhoneNumber: {
        country: 'pak',
        prefix: '+92',
        trunk_prefix: '0',
    },
    selectAreaUnit: () => ({
        areaUnit: Area.SQM,
        isDefaultAreaUnit: true,
    }),
    languages,
    areaUnits: Area.all(),
    currencies: Currency.all(),
    topLevelLocation: {
        ...topLevelLocationWithoutHierarchy,
        hierarchy: [topLevelLocationWithoutHierarchy],
    },
    topCategories: ['1453', '84', '81', '1721', '729', '1455', '40'],
    popularCategories: ['mobiles', 'vehicles', 'property-for-sale', 'property-for-rent'],
    social: { twitterSite: '@OLX_Pakistan' },
    themeColor: '#002f34',
    baseCurrencyName: Currency.PKR,
    defaultCurrencyName: Currency.PKR,
    defaultAreaUnit: Area.SQM,
    deepLinkAppName: 'app-olxpk',
    linkAppStore: 'https://apps.apple.com/pk/app/olx-pakistan/id1551315538',
    linkGooglePlayMobile: 'market://details?id=com.olx.pk',
    linkGooglePlayDesktop: 'https://play.google.com/store/apps/details?id=com.olx.pk',
    linkAppGallery: 'https://appgallery.huawei.com/#/app/C104375435',
    targetingParamsPrefix: 'pk',
    defaultInstallBanner: true,
    featuredAdsGroupSizes: (_: number) => ({ default: 6 }),
    enableFeaturedBadge: true,
    enableContactMethodControl: true,
    enableSocialMedia: true,
    enableBusinessPackages: true,
    paginationType: PaginationType.PAGINATION,
    defaultAllowChatCommunication: true,
    loginFormType: LoginFormType.DEFAULT,
    priceSortFields: [
        `extraFields.${AdCommonExtraField.PRICE}`,
        `extraFields.${AdCommonExtraField.SALARY_TO}`,
    ],
    searchPageSearchType: SearchPageSearchType.FEATURED_REGULAR_EACH_PAGE,
    eliteAdsPerPageCount: Slots.DISABLED, // = -1, this means that the slot is both empty and not available for borrowing
    aboutUsLinks: (i18n: I18n) => {
        return [
            {
                name: t(i18n)`About Dubizzle Group`,
                url: t(i18n)`about_link`,
                event: trackAboutEMPG,
            },
            {
                name: t(i18n)`Careers`,
                url: t(i18n)`careers_url`,
            },
            {
                name: t(i18n)`Contact Us`,
                url: t(i18n)`contact_url`,
                event: trackContactUs,
            },
        ];
    },
    nativeDfpCount: { initial_step: 6, step: 12 },
    enableNativeDfp: {
        categories: [Category.VEHICLES, Category.JOBS, Category.PROPERTIES, Category.OTHERS],
        layouts: [Layout.MOBILE, Layout.DESKTOP],
    },
    enableLockMileageField: true,
    maxNumberOfBadgesForAdListItem: 3,
    enableOtpValidation: false,
    posting_guidelines: {
        en: 'https://help.olx.com.pk/hc/en-us/sections/4403040419087-Posting-guidelines',
        ar: 'https://help.olx.com.pk/hc/en-us/sections/4403040419087-Posting-guidelines',
    },
    tipsForImprovingAdSellingChances: {
        en: 'https://help.olx.com.pk/hc/en-us/articles/6411709268111-How-to-post-a-good-ad',
        ar: 'https://help.olx.com.pk/hc/en-us/articles/6411709268111-How-to-post-a-good-ad',
    },
    allAboutPostingAds: {
        en: 'https://help.olx.com.pk/hc/en-us/categories/4403034834447-Posting-and-Managing-Ads',
        ar: 'https://help.olx.com.pk/hc/en-us/categories/4403034834447-Posting-and-Managing-Ads',
    },
    support_request: {
        en: 'https://help.olx.com.pk/hc/en-us/requests/new',
        ar: 'https://help.olx.com.pk/hc/en-us/requests/new',
    },
    bumpupStateDescriptionText: false,
    allowSearchByAdExternalID: false,
    installAppBannerSubtitle: (i18n: I18n): string => t(i18n)`All the features and more!`,
    maxLocationLevelIndexationByCategory: {},
    enableL2CategoriesSort: false,
    maxSearchPages: 199, // nginx redirects on page 200
    defaultDaysDuration: 30,
    splitCategoriesDropdown: false,
    extraLocationsCheckRule: false,
    favoriteAdsThreshold: 150,
    popularChoicesNumber: 3,
    useMostRelevantAsNewlyListed: true,
    enableAdDetailsMap: true,
    enableAdDetailsAppInstallBanner: false,
    // <home page>
    enableHomePageWallpaper: true,
    enableHomePageAllCategories: true,
    enableHomePagePopularCategories: false,
    enableHomePageSearchHistoryRecommendations: true,
    // identifier list for categories.
    // For most portals the identifier is the slug. For olx-pk it's the external_id
    homePageCategoriesWithHitsIdentifiers: [
        'properties-for-rent',
        'properties-for-sale',
        'cars-for-sale',
        'car-accessories',
        'mobile-phones',
        'mobile-accessories',
        'tv-audio-video',
        'computers-tablets',
    ],
    homePagePromotedSearches: [],
    // </home page>

    hasBankPaymentMethodEnabled: false,
    freeAdTerms: {
        pledge: {
            en: "Honour Allah's covenant when you make a pledge, and do not break your oaths after confirming them, having made Allah your guarantor. Surely Allah knows all you do.",
            ar: 'وَأَوْفُواْ بعَهْد ألله إذَا عَهَدتُمْ ولا تَنقُضُواْ الأيمَانَ بَعْدَ تَوْكيدهَا وَقَدْ جّعّلْتُمُ ألله عَلَيْكُمْ كَفيلاً إنَّ ألله يَعْلَمُ مَا تَفعَلُونَ',
        },

        author: {
            en: 'Surah An-Nahl - 91',
            ar: 'سورة النحل - 91',
        },
        note: {
            en: 'Note: This pledge is only applicable to free ad posting. If you purchase a Featured Ad, this pledge will not apply to you.',
            ar: 'ملاحظة: هذا التعهد ينطبق فقط على الإعلانات المجانية. إذا قمت بشراء إعلان مميز ، فلن ينطبق هذا التعهد عليك.',
        },
    },
    minFreeAdCommission: 0,
    maxFreeAdCommission: 10000000,
    enableLayoutSwitcher: true,
    useOlxSitemap: true,
    favouriteAdsLayoutMobile: SearchHitsLayout.GRID,
    verticalProperties: {
        externalID: '123',
    },
    cashMyCar: {
        enabled: false,
    },
    showTwitterSocialMediaIconInFooter: true,
    showLinkedInSocialMediaIconInFooter: false,
    showTikTokSocialMediaIconInFooter: false,
    headerWithoutFiltersSmallLogo: true,
    homePageLimitedLocations: [],
    agencyProfileDefaultCategoryFilter: {
        [TransactionPurpose.ALL]: ['properties'],
        [TransactionPurpose.FOR_SALE]: ['properties'],
        [TransactionPurpose.FOR_RENT]: ['properties'],
    },
    categoriesWithCustomBreadcrumbs: [],
    combineMakeAndModelOnBreadcrumbs: false,
    displayEmaiLinkInLoginOptions: false,
    breadcrumbsCityLevel: CONFIG.build.CITY_LEVEL - 1,
    /**
     * PK contact buttons design is based on the concept of Primary
     * and Secondary buttons. Where there is a button that is distinguished
     * from the others.
     */
    allowsPrimaryContactButton: true,
    dpvCTAConfigs: {
        [CTA.SMS]: { order: 1, orderWithDelivery: 1, canBePrimary: false },
        [CTA.CALL]: { order: 2, orderWithDelivery: 2, canBePrimary: false },
        [CTA.CHAT]: { order: 3, orderWithDelivery: 3, canBePrimary: true },
        [CTA.BUY]: { order: 4, orderWithDelivery: 4, canBePrimary: true },
        [CTA.WHATSAPP]: { order: 5, orderWithDelivery: 5, canBePrimary: false },
    },
    packagesUsageDuration: 30,
    lpvCTAConfigs: {
        [CTA.SMS]: { order: 1, canBeSecondary: true },
        [CTA.CALL]: { order: 2, canBeSecondary: true },
        [CTA.CHAT]: { order: 3, canBeSecondary: false },
        [CTA.BUY]: { order: 4, canBeSecondary: false },
        [CTA.WHATSAPP]: { order: 5, canBeSecondary: false },
    },
    agencyCTAButtonsConfig: {
        [CTA.SMS]: { order: 1, orderWithDelivery: 0, canBePrimary: false },
        [CTA.CALL]: { order: 2, orderWithDelivery: 3, canBePrimary: false },
        [CTA.CHAT]: { order: 3, orderWithDelivery: 1, canBePrimary: true },
        [CTA.BUY]: { order: 4, orderWithDelivery: 3, canBePrimary: true },
        [CTA.WHATSAPP]: { order: 5, orderWithDelivery: 2, canBePrimary: false },
    },

    searchFuzzySettings: {
        active: false,
    },
    routePathPrefixesWithExternallyManagedSession: ['/widgets/chat'],
    searchFiltersMaxVisibileCount: 8,
    downPaymentValidationMultiplier: 5,
    phoneInputFormat: '...-.',
    getAgencyPortalBrandName: (i18n: I18n) => t(i18n)`OLX`,
} as const;

export default settings;
