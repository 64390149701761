import React from 'react';

import useDismissibleStack from './useDismissibleStack';

export type DissmissibleStacksProps = {
    dismissibleStack: ReturnType<typeof useDismissibleStack>;
    stackGroup?: string;
};

type AdditionalHOCProps = {
    stackGroup?: string;
};

const withDismissibleStack =
    <P extends object>(Component: React.ComponentType<P & DissmissibleStacksProps>) =>
    (props: P & AdditionalHOCProps) => {
        const dismissibleStackProps = useDismissibleStack(props.stackGroup);

        return <Component dismissibleStack={dismissibleStackProps} {...props} />;
    };

export default withDismissibleStack;
