import { TemplateType } from 'strat/templates';

import type { MixedAd } from 'horizontal/types';

import useLiteAdTemplate from '../useLiteAdTemplate';

const useAdSecondaryPriceLabel = (ad: MixedAd): string | null | undefined =>
    useLiteAdTemplate(TemplateType.SECONDARY_PRICE_LABEL, ad);

export default useAdSecondaryPriceLabel;
