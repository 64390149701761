import { RouteNames } from 'strat/routes';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import { selectBusinessPackageCartOfferAdID } from 'horizontal/businessPackages/selectors';

type RouteParams = {
    readonly status: string;
    readonly orderExternalID: string;
    readonly paymentID?: string;
};

const useSavedCardRouting = ({
    status,
    orderExternalID,
}: {
    status: string;
    orderExternalID: string;
}) => {
    const { paymentID } = useSelector(selectRouteParams);
    const orderAdID = useSelector(selectBusinessPackageCartOfferAdID);

    let route;
    let params: RouteParams = { status, orderExternalID };
    if (CONFIG.runtime.STRAT_ENABLE_AFTER_PAYMENT_PROCESSING_PAGE_REDIRECTION && orderAdID) {
        route = RouteNames.PAYMENT_PROCESSING;
        params = { ...params, paymentID };
    } else {
        route = RouteNames.PAYMENT_SUCCESS;
    }

    return { route, params };
};

export default useSavedCardRouting;
