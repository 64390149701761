import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import type { Geoloc } from 'strat/map/types';
import { MapStyleTypes, StaticMap } from 'strat/map';
import { Dialog } from 'strat/modal';
import Layout from 'strat/layout';
import settings from '@app/branding/settings';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { Flex, Button } from 'strat/components';
import { selectLayout } from 'strat/layout/selectors';

import type { Location } from 'horizontal/types';
import MapPlaceholder from 'horizontal/assets/icons/mapPlaceholder_noinline.svg';
import { selectLocationNameWithParent } from 'horizontal/location';
import IconClose from 'horizontal/assets/icons/iconClose.svg';
import IconLocation from 'horizontal/assets/icons/iconLocation.svg';

import { SectionTitle } from '../section';

import styles from './styles/mapDialog.cssm';

const COUNTRY_GEOLOCATION = { lat: 30.46, lng: 70.29 } as const;

const DialogTrigger = ({ onClick }: { onClick: () => void }) => (
    <div className={styles.mapPlaceholderContainer}>
        <div className={styles.mapOverlay}>
            <img alt="Map placeholder" src={MapPlaceholder} />
            <div className={styles.seeLocationButtonContainer}>
                <Button
                    onClick={onClick}
                    secondary
                    renderIcon={(_) => <IconLocation className={styles.locationIcon} />}
                >
                    <Trans>See location</Trans>
                </Button>
            </div>
        </div>
    </div>
);

const CloseDialogButton = ({ onClick }: { onClick: () => void }) => (
    <div onClick={onClick} className={styles.closeButton}>
        <IconClose />
    </div>
);

type Props = {
    location: Array<Location>;
    geography: Geoloc | null | undefined;
};

const MapDialog = ({ location, geography }: Props) => {
    const i18n = useI18n();
    const layout = useSelector(selectLayout);
    const [visible, setVisible] = React.useState(false);
    const closeDialog = () => setVisible(false);

    const adGeography = geography || COUNTRY_GEOLOCATION;

    const options = React.useMemo(
        () =>
            layout === Layout.DESKTOP
                ? {
                      zoom: geography ? 14 : 2,
                      width: 1000,
                      height: 500,
                  }
                : {
                      zoom: geography ? 12.5 : 2,
                      width: 400,
                      height: 800,
                  },
        [layout, geography],
    );

    const adLocationName = selectLocationNameWithParent(i18n, location);

    const onMapClick = React.useCallback(() => {
        setVisible(true);
        trigger(Triggers.MAP_VIEW, {
            view_section: ViewSections.BODY,
        });
    }, [setVisible]);

    return (
        <Flex stretchWidth column>
            <SectionTitle title={t(i18n)`Location`} />
            <Flex className={styles.locationContainer}>
                <IconLocation className={styles.locationIcon} />
                <div className={styles.adLocationName}>{adLocationName}</div>
            </Flex>
            {settings.enableAdDetailsMap && <DialogTrigger onClick={onMapClick} />}
            <Dialog dismissible visible={visible} onVisibilityChanged={closeDialog}>
                <CloseDialogButton onClick={closeDialog} />
                <div className={styles.mapContainer}>
                    <div className={classNames(styles.map, styles.mapOverlay)}>
                        <StaticMap
                            geolocation={adGeography}
                            options={{
                                type: MapStyleTypes.STREETS,
                                ...options,
                            }}
                            className={styles.image}
                            alt={adLocationName}
                        />
                    </div>
                </div>
            </Dialog>
        </Flex>
    );
};

export default MapDialog;
