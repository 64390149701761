import * as React from 'react';

import PauseAudioIcon from 'horizontal/assets/icons/pauseAudio_noinline.svg';
import PlayAudioIcon from 'horizontal/assets/icons/playAudio_noinline.svg';

type Props = {
    readonly paused: boolean;
    readonly className: string;
    readonly onClick: () => void;
};

const PlayPauseButton = ({ paused, className, onClick }: Props) => {
    if (paused) {
        return <img alt={'Play'} onClick={onClick} src={PlayAudioIcon} className={className} />;
    }
    return <img alt={'Pause'} onClick={onClick} src={PauseAudioIcon} className={className} />;
};

export default PlayPauseButton;
