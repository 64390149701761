export const CTAType = Object.freeze({
    UPGRADE: 'upgrade',
    POST: 'post',
    REPUBLISH: 'republish',
    EDIT: 'edit',
    REMOVE: 'remove',
    DEACTIVATE: 'deactivate',
    MARK_AS_SOLD: 'mark_as_sold',
    LEARN_MORE: 'learn_more',
    CHANGE_AGENT: 'change_agent',
    ASSIGN_AGENT: 'assign_agent',
    UNASSIGN_AGENT: 'unassign_agent',
});

export const UpdateAdAction = Object.freeze({
    EDIT: 'edit',
    REPUBLISH: 'republish',
});
