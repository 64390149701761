import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';
import { Currency } from 'strat/i18n/money';
import type { Primitive } from 'strat/types';
import { transformLocalizedFieldToEnglish } from 'strat/util';

import type { LiteCategory, LiteHierarchicalLocation, FlatCategoryField } from 'horizontal/types';

import type {
    CategoryTargetingParams,
    LocationTargetingParams,
    TargetingParamValue,
} from './types';

const categoryParams = (
    categories: Array<LiteCategory>,
    make?: TargetingParamValue | null,
): Partial<CategoryTargetingParams> => {
    return categories
        ? {
              ad_unit: [...categories.map((cat) => cat.slug), make].filter((cat) => !!cat),
          }
        : EMPTY_OBJECT;
};

const locationParams = (
    language: string,
    locations: Array<LiteHierarchicalLocation>,
): Partial<LocationTargetingParams> => {
    const cityLevel = CONFIG.build.CITY_LEVEL;
    const locationNames = locations.map((loc) =>
        transformLocalizedFieldToEnglish(loc, 'name', language, false),
    );

    return locationNames.length
        ? {
              state: cityLevel > 0 ? locationNames[cityLevel - 1] : null,
              city: locationNames[cityLevel],
              neighbourhood: locationNames[cityLevel + 1],
          }
        : EMPTY_OBJECT;
};

const formattedFieldParam = (
    language: string,
    values: Array<Primitive>,
    attribute: string,
    categoryFields: Array<FlatCategoryField>,
): Array<string> => {
    if (!values?.length) {
        // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'string[]'.
        return EMPTY_ARRAY;
    }
    const field = categoryFields.find((innerField) => innerField.attribute === attribute);

    if (!field || !field.choices) {
        // @ts-expect-error - TS2322 - Type 'Primitive[]' is not assignable to type 'string[]'.
        return values;
    }

    return values.map((value) => {
        const choice = field.choices.find((innerChoice) => innerChoice.value === value);
        return choice
            ? transformLocalizedFieldToEnglish(choice, 'label', language, false) || choice.label
            : value;
    });
};

const currencyMapping = (currency: Values<typeof Currency>) => {
    if (currency === Currency.PKR) {
        return 'Rs';
    }

    return currency;
};

export { categoryParams, locationParams, formattedFieldParam, currencyMapping };
