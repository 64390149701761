import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Flex, Text } from 'strat/components';

import { CoverPhoto } from 'horizontal/adCard';
import { useLocalizedAdTitle, useAdPhotos } from 'horizontal/ad';
import { useFormattedPrice } from 'horizontal/util';
import DeliveryCostNote from '@app/adDetails/requestDelivery/deliveryCostNote';
import { DeliveryType, type MixedAd } from 'horizontal/types';

import styles from './styles/adCardPreview.cssm';

type AdPreviewProps = {
    readonly ad: MixedAd;
    readonly showAdPrice: boolean;
    readonly deliveryType?: DeliveryType;
};

const AdPreview = ({
    ad,
    showAdPrice,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: AdPreviewProps) => {
    const adLocalizedTitle = useLocalizedAdTitle(ad);
    const priceLabel = useFormattedPrice(ad?.extraFields?.price || 0);
    const [coverPhoto] = useAdPhotos(ad);

    const coverParams = React.useMemo(() => {
        if (!coverPhoto) {
            return null;
        }
        return {
            ...coverPhoto,
            title: adLocalizedTitle,
        };
    }, [adLocalizedTitle, coverPhoto]);

    return (
        <Flex column fillContainer className={styles.adPreviewCard}>
            <Flex className={styles.coverPhoto}>
                <CoverPhoto cover={coverParams} lazyLoad={false} category={ad.category} />
            </Flex>
            <Flex column justifyCenter className={styles.adDetail}>
                <Flex className={styles.title} aria-label="order title" alignCenter>
                    <Text.XLarge bold>{adLocalizedTitle}</Text.XLarge>
                </Flex>
                {showAdPrice && (
                    <div>
                        <Flex justifySpaceBetween>
                            <Text.Regular>
                                <Trans>Product price:</Trans>
                            </Text.Regular>
                            <Text.Regular>{priceLabel}</Text.Regular>
                        </Flex>
                        {CONFIG.build.ENABLE_SELF_DELIVERY &&
                        deliveryType === DeliveryType.SELF_DELIVERY ? null : (
                            <DeliveryCostNote />
                        )}
                    </div>
                )}
            </Flex>
        </Flex>
    );
};

export default AdPreview;
