import { useCallback } from 'react';

import styles from './styles/disableBodyScroll.cssm';

// Useful for when we want to open a modal or dialog above
// the main page and block further scroll in the background
const useDisableBodyScroll = () => {
    const enableBodyScroll = useCallback(() => {
        const body = document.querySelector('body');
        if (!body) {
            return;
        }
        body.classList.add(styles.noOverflow);
    }, []);

    const disableBodyScroll = useCallback(() => {
        const body = document.querySelector('body');
        if (!body) {
            return;
        }
        body.classList.remove(styles.noOverflow);
    }, []);

    return { enableBodyScroll, disableBodyScroll };
};

export default useDisableBodyScroll;
