import { LocationNodeData } from 'horizontal/property';
import { SearchRouteParams } from 'horizontal/routes/createSearchURL';

export type MatchParams = {
    categorySlug?: string;
    categoryExternalID?: string;
    categoryFieldsWithLocationSlug?: string;
    locationSlug?: string | null;
    locationExternalID?: string;
    locationRadius?: string;
    freeTextQuery?: string;
    page?: string;
    product?: string;
    remarketingAdID?: string;
    filter?: string;
    matchParams?: string;
    pagetype?: string;
    minPhotoCount?: string;
    tier?: string;
    geoPoint?: string;
};

export type MatchParamsWithDisambiguatedData = MatchParams & {
    location?: LocationNodeData | null;
    pathCategoryFieldsSlugs?: string[] | null;
};

export type SearchParamsForPageRendering = SearchRouteParams & {
    categoryExternalID?: string;
    locationExternalID?: string;
};

/**
 * We include category fields in path using the form <attribute>-<choiceSlug>. For example /model-x5/year-2024/.
 * Make is the only field which wont have the attribute: /mercedes-benz/model-a150/year-2024/
 */
export const CATEGORY_FIELD_WITHOUT_PATH_ATTRIBUTE = 'make';
