import invariant from 'invariant';

/**
 * A named regular expression group.
 */
type NamedGroup = {
    name: string;
    pattern: string;
};

/**
 * A list of named and unnamed patterns.
 */
export type Patterns = Array<string | NamedGroup>;
export type PatternsCollection = Array<Patterns>;

/**
 * Group values that were captured when matching the expression.
 */
export type CapturedGroups = {
    [key: string]: [string];
};

/**
 * Builds a plain old regular expression out of the specified
 * named/unnamed patterns.
 * @returns The plain regular expression and a list of named groups
 * that were extracted from the patterns.
 */
type BuiltExpression = {
    groups: Array<NamedGroup>;
    expression: string;
};
const buildExpression = (patterns: Patterns): BuiltExpression => {
    // build basic expression and collect named groups
    let expression = '';
    const groups: Array<NamedGroup> = [];
    patterns.forEach((pattern) => {
        if (typeof pattern === 'string') {
            expression += pattern;
            return;
        }

        expression += `${pattern.pattern}`;
        groups.push(pattern);
    });

    // build a fully valid regular expression
    expression = expression.replace(/\//g, '\\/');

    return { groups, expression };
};

/**
 * Attempts to match the specified patterns against the
 * specified string.
 * @param str The string to match the patterns against.
 * @param patterns A list of patterns that are concatenated,
 * where each item can be a stand-alone, non-capturing group,
 * or a named group.
 * @returns An object with the values that were matched for
 * each named group. Null if the patterns could not be matched
 * against the specified string.
 */
const match = (str: string, patterns: PatternsCollection): null | CapturedGroups => {
    // eslint-disable-next-line no-restricted-syntax
    for (const pattern of patterns) {
        const { expression, groups } = buildExpression(pattern);

        // execute the regular expression
        const result = str.match(new RegExp(expression, 'i'));

        if (result) {
            // discard the first result because that is the full match
            const values = result.slice(1);

            // map captured groups to named groups
            const resultGroups: Record<string, any> = {};
            values.forEach((value, index) => {
                if (!value) {
                    return;
                }

                const namedGroup = groups[index];
                invariant(
                    !!namedGroup,
                    `More regex groups found then defined, text: ${str}, pattern: ${pattern.toString()}`,
                );

                resultGroups[namedGroup.name] = value;
            });

            return resultGroups;
        }
    }

    return null;
};

export default match;
export { buildExpression };
