import * as React from 'react';
import { Trans } from '@lingui/macro';
import { StylelessButton } from 'strat/generic';

import IconArrowUp from 'horizontal/assets/icons/iconArrowUp.svg';
import IconArrowDown from 'horizontal/assets/icons/iconArrowDown.svg';

import styles from './styles/collapsibleTrigger.cssm';

type Props = {
    readonly expanded: boolean;
    readonly setExpanded: (arg1: boolean) => void;
};

const CollapsibleTrigger = ({ expanded, setExpanded }: Props) => {
    const onClick = React.useCallback(() => {
        setExpanded(!expanded);
    }, [expanded, setExpanded]);

    return (
        <StylelessButton className={styles.trigger} onClick={onClick}>
            {expanded ? (
                <>
                    <Trans>View less</Trans>
                    <IconArrowUp className={styles.iconArrow} />
                </>
            ) : (
                <>
                    <Trans>View more</Trans>
                    <IconArrowDown className={styles.iconArrow} />
                </>
            )}
        </StylelessButton>
    );
};

export default CollapsibleTrigger;
