import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { PageWidthWrapper } from 'strat/generic';

import Category from 'horizontal/categorySwitcher/category';
import { selectCategories } from 'horizontal/categories';
import { Category as CategoryType } from 'horizontal/types';
import { useCurrentLocation } from 'horizontal/location';
import { SearchLink } from 'horizontal/search/routing';

import SectionTitle from './sectionTitle';
import styles from './styles/allCategoriesSection.cssm';

const AllCategoriesSection = () => {
    const i18n = useI18n();
    const categories = useSelector(selectCategories) as CategoryType[];
    const currentLocation = useCurrentLocation();

    return (
        <PageWidthWrapper>
            <Flex column>
                <SectionTitle title={t(i18n)`All categories`} />
                <Flex wrap className={styles.categoriesContainer}>
                    {categories.map((category) => (
                        <SearchLink
                            key={category.id}
                            params={{ category, location: currentLocation }}
                        >
                            <Category category={category} />
                        </SearchLink>
                    ))}
                </Flex>
            </Flex>
        </PageWidthWrapper>
    );
};

export default AllCategoriesSection;
