import * as React from 'react';

import type { Category } from 'horizontal/types';

const Context = React.createContext<
    [Category | null | undefined, (arg1?: Category | null | undefined) => void]
>([null, () => {}]);

Context.displayName = 'ActiveCategoryContext';

export default Context;
