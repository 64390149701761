import type { CategoryFields } from 'strat/categoryFields';
import { filterCategoryFields } from 'strat/categoryFields';
import { CategoryFieldFilterType, StringMap } from 'strat/types';
import { sortByDisplayPriority } from 'strat/util';

import type { CategoryField } from 'horizontal/types';
import { CategoryFieldChoice, CategoryFieldRole } from 'horizontal/types';
import { CATEGORY_FIELD_WITHOUT_PATH_ATTRIBUTE } from 'horizontal/routes/search/types';

type PathCategoryFieldSlug = {
    slug: string;
    slugIndex: number;
};

type PathCategoryFieldValue = {
    value: string;
    slugIndex: number;
    displayPriority: number;
    filterType: Values<typeof CategoryFieldFilterType>;
};

const isCategoryFieldIncludeInPath = (field: CategoryField) =>
    field.roles.includes(CategoryFieldRole.INCLUDED_IN_PATHNAME);

const findChoiceValue = (choices: CategoryFieldChoice[], choiceSlug: string) =>
    choices.find((c) => c.slug === choiceSlug)?.value || null;

const getFieldsAttributes = (categoryFields: CategoryFields) => {
    return [
        ...categoryFields.flatFields.map((field) => field.attribute),
        ...Object.keys(categoryFields.parentFieldLookup),
    ];
};

/**
 * Checks if the category fields from path are in the correct order. The order is currently defined by display priority.
 * For example, having make/extra_features in URL with display priority 2/1, we will return false, since extra_features
 * has a lower display priority.
 */
const arePathCategoryFieldsInTheCorrectOrder = (pathFields: StringMap<PathCategoryFieldValue>) => {
    // [#187242262] TODO we should use another value to define the slug order of fields in path, display priority can change
    const sortedFields = Object.values(pathFields).sort(sortByDisplayPriority);
    for (let index = 0; index < sortedFields.length - 1; index += 1) {
        if (sortedFields[index].slugIndex > sortedFields[index + 1].slugIndex) {
            return false;
        }
    }
    return true;
};

/**
 * Decodes category fields from path and returns them under the form {[attribute]: { slug: fieldChoiceSlug, slugIndex: number }}
 * The category fields are encoded in path as `attribute-field-choice-slug`, extra_features-air-conditioning.
 * The only category field without an attribute is the make which we need to take into account.
 *
 * @param categoryFields filtered so only the ones with role INCLUDED_IN_PATHNAME are present
 * @param pathCategoryFieldSlugs array with the fields slugs, [bmw, model-x5]
 * @return object with mapping between attributes and slugs, { make: { slug: 'bmw', slugIndex: 1 }, model: { slug: 'x5', slugIndex: 2 }}
 */
export const decodeCategoryFields = (
    categoryFields: CategoryFields,
    pathCategoryFieldSlugs: string[],
) => {
    const fieldAttributes = getFieldsAttributes(categoryFields);

    return pathCategoryFieldSlugs.reduce(
        (acc: StringMap<PathCategoryFieldSlug>, pathFieldSlug, slugIndex) => {
            const matchingAttribute = fieldAttributes.find((attribute) =>
                pathFieldSlug.includes(attribute),
            );
            if (matchingAttribute) {
                const slug = pathFieldSlug.replace(`${matchingAttribute}-`, '');
                if (slug) {
                    return {
                        ...acc,
                        [matchingAttribute]: {
                            slug,
                            slugIndex,
                        },
                    };
                }
            }

            return {
                ...acc,
                [CATEGORY_FIELD_WITHOUT_PATH_ATTRIBUTE]: {
                    slug: pathFieldSlug,
                    slugIndex,
                },
            };
        },
        {},
    );
};

/**
 * Returns the category fields choices values based on their slugs, for flatFields, which are category fields without
 * a parent. For example, having the field with attribute make and the choice { slug: 'bmw', value: '11', displayPriority: 1 }
 * and the pathCategoryFields as { make: { slug: 'bmw', slugIndex: 1 }} we will return { make: { value: '11', slugIndex: 1, displayPriority: 1 }}
 *
 * @param categoryFields filtered so only the ones with role INCLUDED_IN_PATHNAME are present
 * @param pathCategoryFields mapping between attributes and choice slugs { attribute: 'choice_slug', }
 */
const getFlatCategoryFieldsValuesFromSlugs = (
    categoryFields: CategoryFields,
    pathCategoryFields: StringMap<PathCategoryFieldSlug>,
) =>
    categoryFields.flatFields.reduce((extraFields: StringMap<PathCategoryFieldValue>, field) => {
        if (!pathCategoryFields[field.attribute]) {
            return extraFields;
        }

        const choiceSlug = pathCategoryFields[field.attribute].slug;
        if (!choiceSlug) {
            return extraFields;
        }

        if (!Array.isArray(field.choices)) {
            return extraFields;
        }

        const value = findChoiceValue(field.choices, choiceSlug);
        if (!value) {
            return extraFields;
        }

        return {
            ...extraFields,
            [field.attribute]: {
                value,
                slugIndex: pathCategoryFields[field.attribute].slugIndex,
                displayPriority: field.displayPriority,
                filterType: field.filterType,
            },
        };
    }, {});

/**
 * Returns the category fields choices values based on their slugs, for childrenFields. To correctly identify the choice,
 * the parent choice value should already be determined. For example, having the field with attribute model and the choice
 * { slug: 'x5', value: '22' }, which has the parent make with the choice { slug: 'bmw': value: '11' } and the
 * pathCategoryFields as { make: { slug: 'bmw', slugIndex: 1 }, model: { slug: 'x5', slugIndex: 2 }} we will return
 * { make: { slug: 'bmw', slugIndex: 1, displayPriority: 1 }, model: { slug: 'x5', slugIndex: 2, displayPriority: 2 }}.
 *
 * @param categoryFields filtered so only the ones with role INCLUDED_IN_PATHNAME are present
 * @param pathCategoryFields mapping between attributes and choice slugs { attribute: 'choice_slug', }
 * @param parentValues mapping between attributes and choice value. It should contain the choice values for the fields
 * defined in parentFieldLookup which are included in path.
 */
const getChildrenCategoryFieldsValuesFromSlugs = (
    categoryFields: CategoryFields,
    pathCategoryFields: StringMap<PathCategoryFieldSlug>,
    parentValues: StringMap<PathCategoryFieldValue>,
) =>
    categoryFields.childrenFields.reduce((extraFields, field) => {
        if (!pathCategoryFields[field.attribute]) {
            return extraFields;
        }

        const choiceSlug = pathCategoryFields[field.attribute].slug;
        if (!choiceSlug) {
            return extraFields;
        }

        const parentAttribute = categoryFields.parentFieldLookup[field.attribute];
        if (!parentAttribute || !parentValues[parentAttribute]) {
            return extraFields;
        }

        if (!field.choices || Array.isArray(field.choices)) {
            return extraFields;
        }
        const parentValue = parentValues[parentAttribute].value;

        const choicesForParent = field.choices[parentValue];
        if (!choicesForParent || !Array.isArray(choicesForParent)) {
            return extraFields;
        }

        const value = findChoiceValue(choicesForParent, choiceSlug);
        if (!value) {
            return extraFields;
        }

        return {
            ...extraFields,
            [field.attribute]: {
                value,
                slugIndex: pathCategoryFields[field.attribute].slugIndex,
                displayPriority: field.displayPriority,
                filterType: field.filterType,
            },
        };
    }, {});

/**
 * Extract category fields from path and returns a mapping between the category field attribute and it's choice value.
 * The category fields are encoded in path as `<attribute>-<choice-slug>`, excepting the attribute make, which is
 * encoded as `<choice-slug>` only.
 *
 * The argument pathCategoryFieldsSlugs contains an array with those encodings ['honda', 'model-cr-v-5', 'extra_features-abs'],
 * having the attribute encoded we can identify the category fields, and return the correct values for each field:
 * { make: '11', model: '22', extra_features: '33'} for example. If the length of the pathCategoryFieldsSlugs and the
 * length of the keys in the resulted mapping is different, it means some fields couldn't be identified, and `null` will be
 * returned. We also make sure that the category fields appear in URL in the order defined by their display priority.
 */
const getExtraFieldsFromPath = (
    pathCategoryFieldsSlugs: string[] | null | undefined,
    categoryFields: CategoryFields,
): StringMap<string | string[]> | null => {
    if (!pathCategoryFieldsSlugs) {
        return {};
    }

    const filteredCategoryFields = filterCategoryFields(
        categoryFields,
        isCategoryFieldIncludeInPath,
    );

    const pathCategoryFields = decodeCategoryFields(
        filteredCategoryFields,
        pathCategoryFieldsSlugs,
    );

    const pathFlatFields = getFlatCategoryFieldsValuesFromSlugs(
        filteredCategoryFields,
        pathCategoryFields,
    );

    const pathChildrenFields = getChildrenCategoryFieldsValuesFromSlugs(
        filteredCategoryFields,
        pathCategoryFields,
        pathFlatFields,
    );

    const pathFields = Object.assign({}, pathFlatFields, pathChildrenFields);

    if (pathCategoryFieldsSlugs.length !== Object.keys(pathFields).length) {
        return null;
    }

    if (!arePathCategoryFieldsInTheCorrectOrder(pathFields)) {
        return null;
    }

    return Object.keys(pathFields).reduce((acc, attribute) => {
        const isMultipleChoiceField =
            pathFields[attribute].filterType === CategoryFieldFilterType.MULTIPLE_CHOICES;
        const value = pathFields[attribute].value;
        return {
            ...acc,
            [attribute]: isMultipleChoiceField ? [value] : value,
        };
    }, {});
};

export default getExtraFieldsFromPath;
