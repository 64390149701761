import { Route } from 'react-true-router';
import type { RouteParameters } from 'react-true-router/route';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID } from 'strat/user/session';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import { selectUserAgencies } from 'horizontal/agent/state';
import { fetchUserAgencies } from 'horizontal/agent/state';
import Page from 'horizontal/agencyPortal/pages/page';
import { fetchAgencyCategoryFilters } from 'horizontal/agencyPortal/agency/state';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

class AgencyPortalCreateAdRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_CREATE_AD, [
            ['^/agencyPortal/post', { name: 'attributes', pattern: '(/attributes)?' }, '$'],
        ]);
    }

    createURL(params: RouteParameters): EnhancedLocation {
        const pathname = params?.attributes
            ? '/agencyPortal/post/attributes'
            : '/agencyPortal/post';

        return { pathname };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const isMobileLayout = selectIsMobileLayout(context.redux.store.getState());
        if (!ensureCanAccessAgencyPortal(context) || isMobileLayout) {
            context.http.redirect('/');
            return;
        }

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { attributes },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());

        if (process.env.IS_SERVER && attributes) {
            // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
            context.http.redirect(this.createURL({}));
            return;
        }

        context.rendering.renderPage(Page.AGENCY_PORTAL_CREATE_AD);
        context.promise.wait(
            dispatch(fetchUserAgencies({ userExternalID })).then(() => {
                const userAgencies = selectUserAgencies(getState());
                const agencyExternalID = userAgencies[0]?.externalID;
                return dispatch(fetchAgencyCategoryFilters({ agencyExternalID: agencyExternalID }));
            }),
        );
    }
}

export default new AgencyPortalCreateAdRoute();
