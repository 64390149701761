import * as React from 'react';

import type { CategoryFieldsFilters } from 'horizontal/types';
import { CategoryFieldRole } from 'horizontal/types';
import { useFlatCategoryFields } from 'horizontal/categoryFields';

/**
 * Gets a reduced version of the specified extra fields.
 *
 * The reduced version is used when computing the canonical URL or
 * determining whether to make a page indexable.
 *
 * In the reduced version, everything but the first value for a
 * multiple choice field is removed. "First" is defined by the
 * order in which the choices are defined.
 */
const useReducedExtraFields = (
    categoryID?: number | null,
    extraFields?: CategoryFieldsFilters | null,
): CategoryFieldsFilters => {
    // @ts-expect-error - TS2345 - Argument of type 'Partial<{ [key: string]: CategoryFieldFilterValue; }> | null | undefined' is not assignable to parameter of type '{ [key: string]: Primitive | Primitive[]; } | null | undefined'.
    const flatCategoryFields = useFlatCategoryFields(categoryID, extraFields);

    return React.useMemo(() => {
        if (!extraFields) {
            return {};
        }

        return Object.entries(extraFields).reduce<Record<string, any>>(
            (acc, [attribute, value]: [any, any]) => {
                if (!Array.isArray(value)) {
                    return { ...acc, [attribute]: value };
                }

                const field = flatCategoryFields.find(
                    (innerField) => innerField.attribute === attribute,
                );
                if (!field) {
                    return { ...acc, [attribute]: value };
                }

                if (!field.roles?.includes(CategoryFieldRole.FILTERABLE)) {
                    return { ...acc, [attribute]: value };
                }

                const firstChoice = field.choices.find((choice) => value[0] === choice.value);

                if (!firstChoice) {
                    return { ...acc, [attribute]: value };
                }

                return { ...acc, [attribute]: [firstChoice.value] };
            },
            {},
        );
    }, [flatCategoryFields, extraFields]);
};

export default useReducedExtraFields;
