import { FilterValues } from 'strat/search';

export default [
    FilterValues.price.attribute,
    FilterValues.baths.attribute,
    FilterValues.area.attribute,
    FilterValues.keywords.attribute,
    FilterValues.agency.attribute,
    FilterValues.agentOwner.attribute,
    FilterValues.saleType.attribute,
    FilterValues.completionPercentage.attribute,
    FilterValues.handoverDate.attribute,
    FilterValues.preHandoverPayment.attribute,
];
