export const sanitizeBoolean = (value?: any): boolean => !!value && value !== '0';

export const sanitizeNumber = (value?: any) => {
    if (!value && value !== 0) {
        return null;
    }
    const sanitizedValue = Number(value);
    if (Number.isNaN(sanitizedValue)) {
        return null;
    }
    return sanitizedValue;
};

export const sanitizePositiveNumber = (value?: any) => {
    const sanitizedValue = sanitizeNumber(value);
    if (sanitizedValue === null || sanitizedValue <= 0) {
        return null;
    }
    return sanitizedValue;
};

export const sanitizeNaturalNumber = (value?: any) => {
    const sanitizedValue = sanitizeNumber(value);
    if (sanitizedValue === null || sanitizedValue < 0) {
        return null;
    }
    return sanitizedValue;
};

export const sanitizePercentage = (value?: any) => {
    const sanitizedValue = sanitizeNumber(value);
    if (sanitizedValue === null || sanitizedValue < 0 || sanitizedValue > 100) {
        return null;
    }
    return sanitizedValue;
};

export const sanitizeDate = (value?: any) => {
    if (!value || value === '1970-01-01') {
        return null;
    }

    // iOS does not support yyyy-mm-dd, so we need yyyy/mm/dd
    const formattedDate = value.replace(/-/g, '/');

    return new Date(formattedDate).getTime() || null;
};

export const sanitizeLocalizedValue = (
    rawData: any,
    rawFieldName: string,
    language: string,
): string | null | undefined => {
    return (
        rawData[`${rawFieldName}_${language}`] ||
        rawData[`${rawFieldName}_${CONFIG.build.LANGUAGE_CODE}`] ||
        null
    );
};

const UNKNOWN = ['', 'Unknown', 'unknown'];

export const sanitizeChoiceOfType = <T extends Record<string, string>>(
    value: unknown,
    choiceType: T,
): T[keyof T] | null => {
    if (!value || typeof value !== 'string' || UNKNOWN.includes(value)) {
        return null;
    }

    return choiceType[value.toUpperCase() as keyof T] || null;
};
