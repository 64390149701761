export const BirthDatePart = Object.freeze({
    day: 'day',
    month: 'month',
    year: 'year',
});

export const PartSelectedActionType = 'part/selected';
export const PartClickedActionType = 'part/clicked';
export const DateUpdatedActionType = 'all/updated';
export const ResetActionType = 'all/reset';

export type BirthDateType = Partial<Record<Values<typeof BirthDatePart>, number | null>>;

export type PartSelectedAction = {
    readonly type: typeof PartSelectedActionType;
    readonly payload: {
        part: Values<typeof BirthDatePart>;
        value: number;
    };
};

export type PartClickedAction = {
    readonly type: typeof PartClickedActionType;
    readonly payload: {
        part: Values<typeof BirthDatePart>;
        isOpened: boolean;
    };
};

export type DateUpdatedAction = {
    readonly type: typeof DateUpdatedActionType;
    readonly payload: Partial<Record<Values<typeof BirthDatePart>, number>>;
};

export type ResetAction = {
    readonly type: typeof ResetActionType;
};

export type Actions = PartSelectedAction | PartClickedAction | DateUpdatedAction | ResetAction;

type DatePickerPartState = {
    value: number | null;
    isOpened: boolean;
};

export type DatePickerState = Partial<Record<Values<typeof BirthDatePart>, DatePickerPartState>>;
