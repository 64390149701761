import { values } from 'horizontal/util';
import { ProductType } from 'horizontal/types';
import type { AdActiveProducts, AdActiveProductEntity } from 'horizontal/types';

const extractProductInfo = (allProductConsumption: AdActiveProducts, productPurchaseID: number) =>
    values<AdActiveProductEntity, Values<typeof ProductType>>(allProductConsumption).find(
        (allProductConsumptions?: AdActiveProductEntity | null) => {
            return allProductConsumptions?.productPurchaseId === Number(productPurchaseID);
        },
    );
export default extractProductInfo;
