import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import { decodeStateFilter } from './stateFilterEncoding';
import type { StateFilterParams } from './stateFilterEncoding';

const useAdManagementFilters = (): StateFilterParams => {
    const { filter } = useSelector(selectRouteParams);

    return React.useMemo(() => decodeStateFilter(filter), [filter]);
};

export default useAdManagementFilters;
