import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link as BaseLink } from 'strat/navigation';
import { RouteNames } from 'strat/routes';

import type { MixedAd } from 'horizontal/types';
import { preloadAd } from 'horizontal/ad/state';

type Props = Flow.Diff<
    React.ComponentProps<typeof BaseLink>,
    {
        route: string;
    }
> & {
    readonly preloadedAd: MixedAd | null | undefined;
};

const Link = ({ preloadedAd, children, onClick, ...props }: Props) => {
    const dispatch = useDispatch();

    const onLinkClick = React.useCallback(
        (event: React.MouseEvent<any>) => {
            if (onClick) {
                onClick(event);
            }
            if (preloadedAd) {
                dispatch(preloadAd(preloadedAd));
            }
        },
        [preloadedAd, dispatch, onClick],
    );

    return (
        <BaseLink
            route={RouteNames.AD_DETAILS}
            title={preloadedAd?.title}
            onClick={onLinkClick}
            scrollUp
            {...props}
        >
            {children}
        </BaseLink>
    );
};

export default Link;
