import { CategoryFieldGroupedChoices, FlatCategoryField } from 'strat/types/categoryFields';
import { Primitive } from 'strat/types';

const formatEnumArrayValue = (
    value: Array<Primitive>,
    field: FlatCategoryField,
): string[] | CategoryFieldGroupedChoices[] | null => {
    let choice: string[] | CategoryFieldGroupedChoices[] = [];
    // we either have choices or choiceGroups => [{ choices: { all: []}, displayPriority,  },...]
    if (field.choices?.length) {
        choice = (field.choices || [])
            .filter((innerChoice) => value.includes(innerChoice.value))
            .map((innerChoice) => innerChoice.label);
    } else if (field.choiceGroups && Object.keys(field.choiceGroups).length) {
        const groups = Object.values(field.choiceGroups);
        const outcome: CategoryFieldGroupedChoices[] = [];
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].choices.all.length; j++) {
                if (value.includes(groups[i].choices.all[j].value)) {
                    const matchingChoice = groups[i].choices.all[j];
                    const choices = {
                        all: outcome[i]?.choices.all.concat(matchingChoice) || [matchingChoice],
                    };
                    outcome[i] = {
                        ...groups[i],
                        choices,
                    };
                }
            }
        }
        choice = outcome;
    }

    if (!choice.length) {
        return null;
    }
    return choice;
};

export default formatEnumArrayValue;
