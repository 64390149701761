import * as React from 'react';
import { useSelector } from 'react-redux';

import KnownURLParams from 'strat/branding/knownParameters';
import type { LocationData } from 'strat/property';
import { processLanguageInUrl, useLanguage } from 'strat/i18n/language';
import { getLocalizedAttribute } from 'strat/i18n';
import { parseLocation, serializeLocation } from 'react-true-router/location';
import type { CanonicalURL } from 'strat/routing';

const removeFiltersOnLanguageChange = (url: string): string => {
    const parsedLocation = parseLocation(url);
    const queryParams = parsedLocation.search;

    if (!queryParams) {
        return url;
    }

    // reset the amenities filter if the language changes
    if (queryParams[KnownURLParams.AMENITIES]) {
        delete queryParams[KnownURLParams.AMENITIES];
    }

    // reset the keywords filter if the language changes
    if (queryParams[KnownURLParams.KEYWORDS]) {
        delete queryParams[KnownURLParams.KEYWORDS];
    }

    return serializeLocation({
        ...parsedLocation,
        search: queryParams,
    });
};

const useLanguageSwitcherURL = (
    targetLanguage: string,
    alternateURLs: CanonicalURL[] | null | undefined,
    locations: LocationData,
) => {
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const path = useSelector((state) => state.router.originalURL);
    const currentLanguage = useLanguage();
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const languageSwitchURL = useSelector((state) => state.seo.pageInfo.languageSwitchURLs)?.[
        targetLanguage
    ];

    const translatedURL = React.useMemo(() => {
        const newURL = alternateURLs?.find(
            (element: any) => element.language === targetLanguage,
        )?.alternateURL;

        if (!newURL) {
            return null;
        }

        const queryParams = parseLocation(languageSwitchURL || path).search;
        if (!queryParams) {
            return newURL;
        }

        // translate location slugs
        if (queryParams[KnownURLParams.LOCATIONS]) {
            queryParams[KnownURLParams.LOCATIONS] = locations
                .map(
                    (location) =>
                        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'LocationNodeData'.
                        location[
                            getLocalizedAttribute(
                                'slug',
                                currentLanguage,
                                targetLanguage,
                                CONFIG.build.ENABLE_MERGED_INDEX,
                            )
                        ],
                )
                .slice(1)
                .join(',');
        }

        return serializeLocation({
            pathname: newURL,
            search: queryParams,
        });
    }, [alternateURLs, locations, targetLanguage, path, languageSwitchURL, currentLanguage]);

    return React.useMemo(() => {
        const { newUrl } = processLanguageInUrl(languageSwitchURL || path);
        let newPath =
            targetLanguage !== CONFIG.build.LANGUAGE_CODE ? `/${targetLanguage}${newUrl}` : newUrl;

        if (CONFIG.build.LOCALIZED_ROUTES && translatedURL) {
            newPath = translatedURL;
        }

        return targetLanguage !== currentLanguage
            ? removeFiltersOnLanguageChange(newPath)
            : newPath;
    }, [languageSwitchURL, path, translatedURL, targetLanguage, currentLanguage]);
};

export const useLanguageSwitcherURLsList = (
    alternateURLs: CanonicalURL[] | null | undefined,
    locations: LocationData,
) => {
    return {
        en: useLanguageSwitcherURL('en', alternateURLs, locations),
        ar: useLanguageSwitcherURL('ar', alternateURLs, locations),
        ur: useLanguageSwitcherURL('ur', alternateURLs, locations),
        zh: useLanguageSwitcherURL('zh', alternateURLs, locations),
        ru: useLanguageSwitcherURL('ru', alternateURLs, locations),
    };
};

export default useLanguageSwitcherURL;
