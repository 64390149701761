import type { SearchRouteParams } from 'horizontal/routes';

export const BreadcrumbType = Object.freeze({
    LINK: 'link' as const,
});

export type BreadcrumbHomeLink = {
    type: 'link';
    route: 'home';
    params?: SearchRouteParams;
};

export type BreadcrumbSearchLink = {
    type: 'link';
    route: 'search';
    params: SearchRouteParams;
};

export type BreadcrumbLink = BreadcrumbHomeLink | BreadcrumbSearchLink;

export type Breadcrumb = BreadcrumbLink;
