import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

import type { Ad } from 'horizontal/types';

const useNavigateToAdDetailsWithProductPurchaseID = () => {
    const router = useRouter();

    return React.useCallback(
        (ad?: Ad | null, productPurchaseID?: number) => {
            if (!ad) {
                return;
            }
            const params = {
                slug: ad.slug,
                externalID: ad.externalID,
                productPurchaseID: productPurchaseID,
            };

            router.pushRoute(RouteNames.AD_DETAILS, params);
        },
        [router],
    );
};

export default useNavigateToAdDetailsWithProductPurchaseID;
