import React from 'react';

import connectLocalUnits from './connectLocalUnits';
import { UnitType } from './units';
import { useI18n } from './language';

const UnitSystemDisplay = Object.freeze({
    /**
     * Show everything with abbreviations (e.g. Sq. Ft.)
     */
    ABBREVIATION: 'abbreviation',

    /**
     * Show everything with text (e.g. Square Yards)
     */
    TEXT: 'text',

    /**
     * Don't render the unit system.
     */
    NONE: 'none',
});

type Props = {
    /**
     * The value to convert.
     */
    value: number;
    /**
     * The type of the unit.
     */
    unitType: ExtractValues<typeof UnitType>;
    /**
     * The base of the conversion.
     */
    baseUnit: keyof typeof UnitType;
    /**
     * The current units of each unitType.
     */
    // @ts-expect-error - TS2749 - 'UnitType' refers to a value, but is being used as a type here. Did you mean 'typeof UnitType'?
    currentUnits: Partial<Record<UnitType, Values<typeof UnitType>>>;
    /**
     * The active current unit used.
     */
    currentUnit?: Values<typeof UnitType>;
    /**
     * How to render the unit system.
     */
    unitSystemDisplay: Values<typeof UnitSystemDisplay>;
    /**
     * Converts the specified area, noted in the base
     * area units into a valid area in the local current area units.
     */
    areaInLocalUnits: (
        value: number,
        baseUnit?: keyof typeof UnitType | null | undefined,
        currentUnit?: keyof typeof UnitType | null | undefined,
    ) => number;
    /**
     * Formats the specified value using the current locales.
     */
    formatLocalUnitsValue: (value: number, unitType?: any, unit?: string) => string;
    /**
     * A set of css rules to apply on the resulted text.
     */
    className?: string;
    /**
     * Return a h4 element instead of span.
     */
    isHeader?: boolean;
};

const LocalUnits = ({ unitSystemDisplay = UnitSystemDisplay.NONE, ...props }: Props) => {
    const i18n = useI18n();
    // @ts-expect-error - The keys of `currentUnits` are actually like this
    const currentUnit = props.currentUnit || props.currentUnits[props.unitType];
    const unit = props.formatLocalUnitsValue(
        props.areaInLocalUnits(props.value, props.baseUnit, currentUnit),
        props.unitType,
        currentUnit,
    );
    let unitSystem = null;
    switch (unitSystemDisplay) {
        case UnitSystemDisplay.ABBREVIATION:
            unitSystem = props.unitType.abbreviation
                ? props.unitType.abbreviation(i18n, currentUnit)
                : null;
            break;
        case UnitSystemDisplay.TEXT:
            unitSystem = props.unitType.text ? props.unitType.text(i18n, currentUnit) : null;
            break;
        case UnitSystemDisplay.NONE:
        default:
            break;
    }

    let renderedString = unit;
    if (unitSystem) {
        renderedString += ` ${unitSystem}`;
    }

    return props.isHeader ? (
        <h4 className={props.className}>{renderedString}</h4>
    ) : (
        <span className={props.className}>{renderedString}</span>
    );
};

export { UnitSystemDisplay };

export default connectLocalUnits(LocalUnits);
