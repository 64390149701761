import * as React from 'react';
import debounce from 'just-debounce';

/**
 * DEPRECATED: Use useWindowSize() instead.
 *
 * Whenever we have a component which renders both on the server and
 * on the client, we should use useWindowSize.
 * Very strange bugs can happen if you don't. Trust me. Some components won't rerender
 * when they should.
 * See:
 *    https://reactjs.org/docs/react-dom.html#hydrate
 *    https://github.com/facebook/react/issues/13260
 *
 * Ideally we should not use this anymore but this hook is used in
 * multiple places so we'll have to gradually change them.
 * */

const useWindowSizeWithoutSSR = () => {
    const [windowSize, setWindowSize] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        let mounted = true;
        const onResize = debounce(() => {
            if (mounted) {
                setWindowSize({ height: window.innerHeight, width: window.innerWidth });
            }
        }, 200);

        window.addEventListener('resize', onResize, { passive: true });
        return () => {
            mounted = false;
            // @ts-expect-error - TS2769 - No overload matches this call.
            window.removeEventListener('resize', onResize, { passive: true });
        };
    }, []);

    return windowSize;
};

export default useWindowSizeWithoutSSR;
