import * as React from 'react';
import classNames from 'classnames';
import { Text, Flex } from 'strat/components';

import styles from './styles/upSellingBanner.cssm';

export type BannerType = 'success' | 'info';

type Props = {
    title: string;
    ariaLabel: string;
    renderIcon: (iconStyle: typeof styles.icon) => React.ReactElement;
    description?: string | null;
    type?: BannerType;
};

const UpSellingBanner = ({
    title,
    renderIcon,
    ariaLabel,
    description,
    type = 'success',
}: Props) => (
    <Flex
        className={classNames(styles.container, {
            [styles.success]: type === 'success',
            [styles.info]: type === 'info',
        })}
        alignCenter
        justifySpaceBetween
        aria-label={ariaLabel}
    >
        <Flex column className={styles.message}>
            <Text.XLarge bold>{title}</Text.XLarge>
            <Text.Regular>{description}</Text.Regular>
        </Flex>
        {renderIcon?.(styles.icon)}
    </Flex>
);

export default UpSellingBanner;
