import memoize from 'fast-memoize';

import { dateLocalesFromLanguage } from 'strat/i18n/language/dateLocales';

const DEFAULT_OPTIONS = { year: 'numeric', month: 'short' } as const;

const getDateTimeFormatter = memoize(
    (locales, options) => new Intl.DateTimeFormat(locales, options),
);

const formatDate = (
    date: Date,
    language: string,
    options: Intl.DateTimeFormatOptions = DEFAULT_OPTIONS,
    // @ts-expect-error - TS2724 - 'Intl' has no exported member named 'Locales'. Did you mean 'Locale'?
    localesParam: string | null | undefined | Intl.Locales | null | undefined = null,
) => {
    const locales = localesParam || dateLocalesFromLanguage(language);

    const formatter = getDateTimeFormatter(locales, options);
    return formatter.format(date);
};

export default getDateTimeFormatter;
export { formatDate };
