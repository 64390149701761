import * as React from 'react';

import type { PropertyAmenityData } from 'strat/property/types';

import styles from './styles/amenityGroup.cssm';
import amenityStyles from './styles/propertyAmenity.cssm';
import PropertyAmenity from './propertyAmenity';

const defaultRenderAmenity = (amenity: PropertyAmenityData) => (
    <PropertyAmenity
        amenity={amenity}
        key={`${amenity.text}-${amenity.externalID}`}
        className={amenityStyles.amenity}
    />
);

/**
 * Renders the property amenities in generic form
 */
const AmenityGroup = ({
    amenities,
    renderAmenity = defaultRenderAmenity,
}: {
    amenities: Array<PropertyAmenityData>;
    renderAmenity?: (amenity: PropertyAmenityData, index: number) => React.ReactElement;
}): React.ReactElement => (
    <div className={styles.amenityGroupContainer}>
        {amenities.map((amenity, index) => renderAmenity(amenity, index))}
    </div>
);

export default AmenityGroup;
