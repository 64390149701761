import * as React from 'react';

import styles from './styles/stylelessButton.cssm';

// Unfortunately, flow has no way to inherit the props for a standard
// HTML element. So we have to re-declare them. Please add any missing props.
type Props = {
    readonly className?: string;
    readonly type?: 'button' | 'submit' | 'reset';
    readonly title?: string;
    readonly ['aria-label']?: string;
    readonly onClick?: (event: React.MouseEvent<any>) => void;
    readonly children?: React.ReactNode;
    readonly disabled?: boolean;
};

/**
 * Extremely thin wrapper for the standard <button> that removes
 * all default styles.
 *
 *
 * This prevents you from having to reset all the standard styles
 * <button> comes with everytime you want a button without a background,
 * border, padding, margin or outline.
 */
const StylelessButton = React.forwardRef<HTMLButtonElement, Props>(
    ({ className = styles.button, children, ...props }: Props, ref) => (
        <button {...props} ref={ref} className={className}>
            {children}
        </button>
    ),
);

export default StylelessButton;
