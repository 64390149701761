import * as React from 'react';
import classNames from 'classnames';

import { Flex } from 'strat/components';
import IconInfo from 'strat/assets/icons/iconInfo_noinline.svg';

import type { BadgeProps } from './types';
import BadgeBottomSheet from './badgeBottomSheet';
import styles from './styles/segmentedBarBadge.cssm';

const SegmentedBarBadge = ({
    renderTitle,
    renderValue,
    renderInformation,
    large,
    label,
}: BadgeProps) => {
    const shouldRenderMoreInfo = !!renderInformation && large;
    const ariaLabel =
        `${label || ''}${shouldRenderMoreInfo ? ' Hoverable ' : ' '}Card Badge`.trim();
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(false);

    return (
        <Flex
            alignCenter
            justifyCenter
            column
            className={classNames(styles.container, {
                [styles.tappable]: shouldRenderMoreInfo,
                [styles.small]: !large,
            })}
            onClick={() => setIsBottomSheetOpen(true)}
            aria-label={ariaLabel}
        >
            <div className={styles.title}>{renderTitle()}</div>
            <Flex alignCenter className={styles.value}>
                {renderValue()}
                {shouldRenderMoreInfo && (
                    <>
                        <svg className={styles.infoIcon}>
                            <use xlinkHref={`${IconInfo}#info`} />
                        </svg>
                        <BadgeBottomSheet
                            isOpen={isBottomSheetOpen}
                            onClose={() => setIsBottomSheetOpen(false)}
                        >
                            {renderInformation()}
                        </BadgeBottomSheet>
                    </>
                )}
            </Flex>
        </Flex>
    );
};

export default SegmentedBarBadge;
