import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import { AddressFormActions } from '../types';

const useAddressDialogDetails = (action: string, i18n: I18n) => {
    switch (action) {
        case AddressFormActions.DELETE:
            return {
                title: t(i18n)`Are you sure you want to delete this address?`,
                description: t(
                    i18n,
                )`Please note: Deleting this address will not delete any pending delivery orders being shipped to this address.`,
                confirm: t(i18n)`Yes, Delete`,
                cancel: t(i18n)`Cancel`,
            };

        default:
            return {
                title: t(i18n)`Would you like to set this as your default address?`,
                description: t(
                    i18n,
                )`Please note: The default address information will be used as your Billing Address.`,
                confirm: t(i18n)`Confirm`,
                cancel: t(i18n)`Cancel`,
            };
    }
};

export default useAddressDialogDetails;
