import { createSelector } from 'reselect';

import EMPTY_ARRAY from 'strat/empty/array';

import type { AreaGuide } from './types';

/**
 * Selects the list of area guides
 */
const selectAreaGuidesList = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state) => state.areaGuide,
    // @ts-expect-error - TS7006 - Parameter 'areaGuides' implicitly has an 'any' type.
    (areaGuides) => areaGuides.data || EMPTY_ARRAY,
);

/**
 * Check if the areaguide has the required attributes to be individually displayed
 */
const hasTitle = (areaGuide: AreaGuide) => areaGuide && areaGuide.body && areaGuide.title;

/**
 * Check if the area guide has the required attributes to be used as tab
 */
const hasTabName = (areaGuide: AreaGuide) => areaGuide && areaGuide.body && areaGuide.tab_name;

/**
 * Selects a valid area guide
 *
 * @returns {AreaGuide|null} - the first area guide if it has a title
 */
const selectAreaGuide = createSelector(selectAreaGuidesList, (areaGuides: AreaGuide[]) => {
    return areaGuides.length !== 0 && hasTitle(areaGuides[0]) ? areaGuides[0] : null;
});

/**
 * Selects the area guides which can be displayed in tab view
 */
const selectAreaGuideTabs = createSelector(selectAreaGuidesList, (areaGuides) =>
    areaGuides.filter(hasTabName),
);

/**
 * Select an area guide if it is meant to be displayed at the bottom of the page (the default)
 */
// @ts-expect-error - TS2769 - No overload matches this call. | TS7006 - Parameter 'areaGuide' implicitly has an 'any' type.
const selectBottomAreaGuide = createSelector(selectAreaGuide, (areaGuide: AreaGuide) =>
    areaGuide && !areaGuide.display_top ? areaGuide : null,
);

/**
 * Select area guide params
 */
const selectAreaGuideParams = (state: any) => state.areaGuide.params;

const selectAreaGuideLoading = (state: any) => state.areaGuide.loading;

export {
    selectAreaGuide,
    selectBottomAreaGuide,
    selectAreaGuideParams,
    selectAreaGuideTabs,
    selectAreaGuideLoading,
};
