import * as React from 'react';

import styles from './styles/imageCollectionInput.cssm';

type Ref = React.ElementRef<any>;
type Event = React.DragEvent<any>;

const useDragEvents = <T>(elementOrder: Array<T>, setElementOrder: (arg1: Array<T>) => void) => {
    const onDragStart = React.useCallback(
        (index: number) => (e: Event, _: Ref) => {
            // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
            e.dataTransfer.setData('sourceIndex', index);
            e.dataTransfer.dropEffect = 'move';
        },
        [],
    );

    const onDragLeave = React.useCallback((_: Event, ref: Ref) => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        ref.classList.remove(styles.hovered);
    }, []);

    const onDragOver = React.useCallback((event: Event, ref: Ref) => {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        ref.classList.add(styles.hovered);
        event.preventDefault();
    }, []);

    const onDrop = React.useCallback(
        (index: number) => (e: Event, ref: Ref) => {
            e.preventDefault();

            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            ref.classList.remove(styles.hovered);

            const sourceIndex = parseInt(e.dataTransfer.getData('sourceIndex'), 10);
            const newIndices = [...elementOrder];
            [newIndices[index], newIndices[sourceIndex]] = [
                elementOrder[sourceIndex],
                elementOrder[index],
            ];

            setElementOrder(newIndices);
        },
        [elementOrder, setElementOrder],
    );

    return {
        onDragStart,
        onDragLeave,
        onDragOver,
        onDrop,
    };
};

export default useDragEvents;
