import * as React from 'react';
import { GTMDataLayerVariables } from 'strat/gtm';

import { usePageViewTrigger } from 'horizontal/gtm';

import useGlobalGTMVars from '../useGlobalGTMVars';

import PageType from './pageType';

const GTMDataLayer = ({ pageType }: { pageType: Values<typeof PageType> }) => {
    const globalGTMVars = useGlobalGTMVars();

    usePageViewTrigger(`${pageType}_view`, { ...globalGTMVars, page_type: pageType });

    return (
        <GTMDataLayerVariables
            variables={{
                ...globalGTMVars,
                page_type: pageType,
            }}
        />
    );
};

export default GTMDataLayer;
