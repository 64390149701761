import * as React from 'react';

/**
 * Properties for {@see StopEventsPropagation}.
 */
type Props = {
    children: React.ReactNode;
};

/**
 *  Prevents all events propagation into the doom tree
 */
const StopEventsPropagation = ({ children }: Props) => {
    const stopPropagation = (event: React.SyntheticEvent<any>) => event.stopPropagation();
    return (
        <div
            onClick={stopPropagation}
            onContextMenu={stopPropagation}
            onDoubleClick={stopPropagation}
            onDrag={stopPropagation}
            onDragEnd={stopPropagation}
            onDragEnter={stopPropagation}
            onDragExit={stopPropagation}
            onDragLeave={stopPropagation}
            onDragOver={stopPropagation}
            onDragStart={stopPropagation}
            onDrop={stopPropagation}
            onMouseDown={stopPropagation}
            onMouseEnter={stopPropagation}
            onMouseLeave={stopPropagation}
            onMouseMove={stopPropagation}
            onMouseOver={stopPropagation}
            onMouseOut={stopPropagation}
            onMouseUp={stopPropagation}
            onKeyDown={stopPropagation}
            onKeyPress={stopPropagation}
            onKeyUp={stopPropagation}
            onFocus={stopPropagation}
            onBlur={stopPropagation}
            onChange={stopPropagation}
            onInput={stopPropagation}
            onInvalid={stopPropagation}
            onSubmit={stopPropagation}
        >
            {children}
        </div>
    );
};

export default StopEventsPropagation;
