import * as React from 'react';
import { useActiveUserExternalID } from 'strat/user/session';

import { ChatRoomMemberRole, CHAT_SUPPLIER_ROLES } from 'horizontal/chat/constants';
import { ChatMessage, ChatRoom, ChatUserXID } from 'horizontal/chat/types';

const useIsActiveUserMessageSender = ({ chatRoom }: { chatRoom: ChatRoom }) => {
    const { myRole, otherMembers, xid } = chatRoom;
    const userXID = useActiveUserExternalID();

    const memberRolesByUserXID = React.useMemo(() => {
        return otherMembers.reduce(
            (prev, member) => {
                prev[member.userXID] = member.role;

                return prev;
            },
            {} as Record<ChatUserXID, ChatRoomMemberRole>,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xid]);

    return ({ message }: { message?: ChatMessage | null }) => {
        if (!message) {
            return false;
        }

        const isUserSender = message.senderXID === userXID;

        if (isUserSender) {
            return true;
        }

        const isUserSupplier = CHAT_SUPPLIER_ROLES.includes(myRole);
        const isSenderSupplier = CHAT_SUPPLIER_ROLES.includes(
            memberRolesByUserXID[message.senderXID],
        );

        return isUserSupplier === isSenderSupplier;
    };
};

export default useIsActiveUserMessageSender;
