import * as React from 'react';

import { CloseDialogButton } from 'strat/modal';

import styles from './styles/popUpHeader.cssm';

type Props = {
    renderBackButton?: () => React.ReactElement | null;
    renderTitle?: () => React.ReactElement | null;
    withCloseButton?: boolean;
};

const PopUpHeader = ({
    renderBackButton = () => null,
    renderTitle = () => null,
    withCloseButton = false,
}: Props) => (
    <div className={styles.container} aria-label={'Header'}>
        <div className={styles.contentContainer}>
            {renderBackButton()}
            {renderTitle()}
            {withCloseButton && <CloseDialogButton className={styles.closeButton} />}
        </div>
    </div>
);

export default PopUpHeader;
