import * as React from 'react';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import { Link } from 'strat/navigation';
import { Button, Flex, Text } from 'strat/components';

import useCheckoutDeleteCard from 'horizontal/payment/hooks/useCheckoutDeleteCard';
import { CreditCardItem } from 'horizontal/payment';

import type { CheckoutCreditCard } from './types';
import PaymentSuccessHeader from './paymentSuccessHeader';
import PaymentSuccessContent from './paymentSuccesContent';
import useSavedCardRouting from './hooks/useSavedCardRouting';
import styles from './styles/paymentSuccess.cssm';

const SaveCard = ({
    productPackageNames,
    status,
    instrument,
    orderExternalID,
}: {
    productPackageNames: string;
    status: string;
    instrument: CheckoutCreditCard;
    orderExternalID: string;
}) => {
    const { deleteCard } = useCheckoutDeleteCard(instrument);
    const { route, params } = useSavedCardRouting({ status, orderExternalID });

    return (
        <>
            <PaymentSuccessHeader />
            <PageWidthWrapper>
                <PaymentSuccessContent productPackageNames={productPackageNames} />
                <Flex alignCenter column stretchWidth className={styles.buttons}>
                    <div className={styles.instrumentsActions}>
                        <div className={styles.questionTitle}>
                            <Text.Base bold>
                                <Trans>Do you want to save this card for future payments?</Trans>
                            </Text.Base>
                        </div>

                        <CreditCardItem disabled={true} card={instrument} />
                        <div className={styles.buttonsContainer}>
                            <Link route={route} params={params}>
                                <div
                                    onClick={deleteCard}
                                    role="button"
                                    className={styles.dismissAction}
                                >
                                    <Text.Base bold>
                                        <Trans>Do not save card info</Trans>
                                    </Text.Base>
                                </div>
                            </Link>
                            <Link route={route} params={params}>
                                <Button>
                                    <Trans>Save Card</Trans>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Flex>
            </PageWidthWrapper>
        </>
    );
};

export default SaveCard;
