import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useI18n } from 'strat/i18n/language';
import { withGTMLeadTracking, GTMLeadTrackingProps, ShareMediums, PageTypes } from 'strat/gtm';
import type { PropertyData } from 'strat/property/types';
import Triggers from 'strat/gtm/triggers';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import styles from './styles/links.cssm';

/**
 * Properties for social share links.
 */
type Props = {
    text: string;
    url: string;
    onClick?: () => void;
    children: React.ReactNode;
    pageType?: Values<typeof PageTypes>;
    triggerName?: string;
    property?: PropertyData | null;
    emailBody?: string | null;
};

type ShareLinkProps = GTMLeadTrackingProps & {
    title: string;
    url: string;
    onClick?: () => void;
    shareType: Values<typeof ShareMediums>;
    target: string;
    children: React.ReactNode;
    pageType?: Values<typeof PageTypes>;
    triggerName?: string;
};

/**
 * Renders a link, but targeted towards links that share content.
 */
export const ShareLink = withGTMLeadTracking(
    ({
        title,
        url,
        onClick,
        trackShareSocialButton,
        trackShareTransactions,
        shareType,
        target,
        children,
        pageType = PageTypes.DETAIL,
        triggerName,
        property,
    }: ShareLinkProps) => (
        <a
            target={target}
            href={url}
            title={title}
            className={styles.link}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
                if (triggerName === Triggers.SHARE_TRANSACTIONS) {
                    trackShareTransactions(shareType, pageType);
                } else {
                    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string | null'.
                    trackShareSocialButton(shareType, pageType, triggerName, property);
                }
            }}
        >
            {children}
        </a>
    ),
);

/**
 * Link which shares the specified URL/text to Whatsapp
 */
const WhatsAppShareLink = ({
    url,
    text,
    onClick,
    children,
    pageType,
    triggerName,
    property,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const safeText = encodeURIComponent(`${text} ${url}`);
    const link = `https://wa.me/?text=${safeText}`;

    return (
        <ShareLink
            url={link}
            title={t(i18n)`Share on WhatsApp`}
            onClick={onClick}
            shareType={ShareMediums.WHATSAPP}
            target="_blank"
            pageType={pageType}
            triggerName={triggerName}
            property={property}
        >
            {children}
        </ShareLink>
    );
};

/**
 * Link which shares the specified URL/text to Facebook.com.
 */
const FacebookShareLink = ({
    url,
    onClick,
    children,
    pageType,
    triggerName,
    property,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const link = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

    return (
        <ShareLink
            url={link}
            title={t(i18n)`Share on Facebook`}
            onClick={onClick}
            shareType={ShareMediums.FACEBOOK}
            target="_blank"
            pageType={pageType}
            triggerName={triggerName}
            property={property}
        >
            {children}
        </ShareLink>
    );
};

/**
 * Link which shares the specified URL/text to Twitter.
 */
const TwitterShareLink = ({
    url,
    text,
    onClick,
    children,
    pageType,
    triggerName,
    property,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const safeText = encodeURIComponent(text);
    const link = `https://twitter.com/intent/tweet?text=${safeText} - url: ${url}`;

    return (
        <ShareLink
            url={link}
            title={t(i18n)`Share on Twitter`}
            onClick={onClick}
            shareType={ShareMediums.TWITTER}
            target="_blank"
            pageType={pageType}
            triggerName={triggerName}
            property={property}
        >
            {children}
        </ShareLink>
    );
};

/**
 * Link which shares the specified URL/text to Facebook Messenger.
 *
 * TODO: Currently this is a deep-link to the Android/iOS app. As far
 *       as I could find there's no way to open messenger.com when on
 *       desktop. But there might be a way.
 */
const FBMessengerShareLink = ({
    url,
    onClick,
    children,
    pageType,
    triggerName,
    property,
}: Props): React.ReactElement | null => {
    const i18n = useI18n();
    const isMobile = useSelector(selectIsMobileLayout);
    const link = `fb-messenger://share/?link=${url}`;

    if (isMobile) {
        return (
            <ShareLink
                url={link}
                title={t(i18n)`Share on Messenger`}
                onClick={onClick}
                shareType={ShareMediums.MESSENGER}
                target="_self"
                pageType={pageType}
                triggerName={triggerName}
                property={property}
            >
                {children}
            </ShareLink>
        );
    }
    return null;
};

/**
 * Link which shares the specified URL/text to GMail.
 */
const GMailShareLink = ({
    url,
    text,
    onClick,
    children,
    pageType,
    triggerName,
    property,
    emailBody,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const safeText = encodeURIComponent(text);
    const safeBody = emailBody ? encodeURIComponent(`${emailBody}\n${url}`) : url;
    const link = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to&su=${safeText}&body=${safeBody}&ui=2&tf=1`;

    return (
        <ShareLink
            url={link}
            title={t(i18n)`Send via GMail`}
            onClick={onClick}
            shareType={ShareMediums.GMAIL}
            target="_blank"
            pageType={pageType}
            triggerName={triggerName}
            property={property}
        >
            {children}
        </ShareLink>
    );
};

/**
 * Link which shares the specified URL/text through an e-mail.
 */
const EmailShareLink = ({
    url,
    text,
    onClick,
    children,
    pageType,
    triggerName,
    property,
    emailBody,
}: Props): React.ReactElement => {
    const i18n = useI18n();
    const safeBody = emailBody ? encodeURIComponent(`${emailBody}\n${url}`) : url;
    const link = `mailto:?subject=${text}&body=${safeBody}`;

    return (
        <ShareLink
            url={link}
            title={t(i18n)`Send via E-Mail`}
            onClick={onClick}
            shareType={ShareMediums.EMAIL}
            target="_self"
            pageType={pageType}
            triggerName={triggerName}
            property={property}
        >
            {children}
        </ShareLink>
    );
};

export {
    WhatsAppShareLink,
    FacebookShareLink,
    TwitterShareLink,
    FBMessengerShareLink,
    GMailShareLink,
    EmailShareLink,
};
