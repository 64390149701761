import * as React from 'react';
import { Trans } from '@lingui/macro';

import { PropertyData } from 'strat/property';

import Badge from './badge';
import PriceValue from './priceValue';
import { ProjectBadgeStyle } from './types';

type Props = {
    property: PropertyData;
    style: ProjectBadgeStyle;
};

const OriginalPriceBadge = ({ property, style }: Props) => {
    if (!property.offplanDetails?.originalPrice) {
        return null;
    }

    return (
        <Badge
            style={style}
            renderTitle={() => <Trans>Original price</Trans>}
            renderValue={() => (
                <PriceValue price={property.offplanDetails?.originalPrice || 0} style={style} />
            )}
        />
    );
};

export default OriginalPriceBadge;
