import * as React from 'react';
import { Flex } from 'strat/components';

import { useMetaRobotsDirective } from 'horizontal/search/seo';
import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import { RobotsIndexReason } from 'horizontal/search/seo/useMetaRobotsDirective';

// @ts-expect-error - TS7006 - Parameter 'reason' implicitly has an 'any' type.
const getReasonText = (reason) => {
    switch (reason) {
        case RobotsIndexReason.MULTIPLE_CHOICES_SELECTED:
            return 'Multiple Filter Selection from Same Type: Non-Indexable';
        case RobotsIndexReason.ALWAYS_INDEXABLE_LOCATION:
            return 'Legacy Location Node: Indexable';
        case RobotsIndexReason.NON_INDEXABLE_QUERY_PAGE:
            return 'New Location Node Query Page: Non-Indexable';
        case RobotsIndexReason.LOCATION_LEVEL_NON_INDEXABLE:
            return 'Max Allowed Indexation Limit Surpassed: Non-Indexable';
        case RobotsIndexReason.PAGE_RULE_DIRECTED:
            return 'Inactive Ads History Surpassed Limit of 10: Indexable';
        case RobotsIndexReason.DEFAULT:
            return 'No Page Rule Robots: Non-Indexable';
        default:
            return 'Unknown reason';
    }
};

const SeoTab = () => {
    const params = useCurrentSearchRouteParams();
    const { result, reason } = useMetaRobotsDirective(params);
    return (
        <Flex column>
            <div>Robots meta: {result}</div>
            <div>Reason for robots meta: {getReasonText(reason)}</div>
        </Flex>
    );
};

export default SeoTab;
