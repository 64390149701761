import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import type { ShortReviewData } from 'strat/reviewsManager/types';
import { ThumbnailImage, ThumbnailSizes } from 'strat/image';
import NoAvatar from '@app/assets/icons/noAvatar.svg';

import styles from './styles/reviewAgentInfo.cssm';

type Props = {
    review: {
        externalID: string;
        isVerified: boolean;
        comment: string;
        createdAt: number;
        reviewerName?: string | null | undefined;
        reply?: ShortReviewData | null | undefined;
        agentName: string;
        agentPhotoURL: string;
        agentPhotoID: string;
    };
};

const ReviewAgentInfo = ({ review }: Props) => {
    const i18n = useI18n();

    return (
        <div className={styles.agentInfo}>
            <span className={styles.text}>
                <Trans>
                    for <b>{review.agentName}</b>
                </Trans>
            </span>
            <div className={styles.photo}>
                {review.agentPhotoID ? (
                    <ThumbnailImage
                        imageID={review.agentPhotoID}
                        className={styles.agentLogo}
                        size={ThumbnailSizes.SMALL}
                        alt={review.agentName}
                        title={review.agentName}
                        label={t(i18n)`Agent avatar`}
                    />
                ) : (
                    // @ts-expect-error - TS2322 - Type '{ className: any; alt: string; }' is not assignable to type 'IntrinsicAttributes & SVGAttributes<SVGElement> & { children?: ReactNode; }'.
                    <NoAvatar className={styles.photo} alt={review.agentName} />
                )}
            </div>
        </div>
    );
};

export default ReviewAgentInfo;
