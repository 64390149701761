/**
 * Available Agency Portal side menu actions.
 */
const Actions = Object.freeze({
    SET_MENU_STATE: 'AGENCY_PORTAL/SIDE_MENU/SET_MENU_STATE',
});

/**
 * The type of the dispatched action.
 */
type Action = {
    type: string;
    open?: boolean;
};

/**
 * The state type.
 */
type State = {
    readonly open: boolean;
};

/**
 * Default state for the side menu.
 */
const defaultState: State = {
    open: false,
};

/**
 * Reducer for the side menu.
 */
const agencyPortalSideMenuReducer = (
    state: State | null | undefined = defaultState,
    action: Action,
) => {
    switch (action.type) {
        case Actions.SET_MENU_STATE:
            return {
                ...state,
                open: action.open,
            };
        default:
            return state;
    }
};

/**
 * Setter for the side menu state.
 */
const setSideMenuState = (open: boolean): Action => ({
    type: Actions.SET_MENU_STATE,
    open,
});

export { setSideMenuState };

export default agencyPortalSideMenuReducer;
