import * as React from 'react';
import getVideoId from 'get-video-id';

import { isCanceled, makeCancelable } from 'strat/util';
import type { PropertyVideoData } from 'strat/property';

import YoutubeIframeApi from './youtubeIframeApi';

const getId = (url: any) => (getVideoId(url) || { id: 'unknown' }).id;

type Props = {
    video: PropertyVideoData;
    className: string;
    autoplay: boolean;
    pause?: boolean;
    onVideoPlaying?: () => void;
    onError?: (errorMessage: string) => void;
};

const PlayerState = Object.freeze({
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    VIDEO_CUED: 5,
});

type Player = {
    playVideo: () => void;
    pauseVideo: () => void;
    getPlayerState: () => Values<typeof PlayerState>;
};

const YoutubePlayer = (props: Props) => {
    const playerRef = React.useRef(null);
    const youtubePlayerAnchor = React.useRef(null);
    const previousPause = React.useRef(null);

    const getPlayer = (): Player | null => playerRef.current;

    const { onVideoPlaying, pause } = props;

    const getPlayerState = React.useCallback((): Values<typeof PlayerState> | null => {
        const player = getPlayer();
        if (player) {
            return player.getPlayerState();
        }
        return null;
    }, []);

    const pauseVideo = React.useCallback(() => {
        const player = getPlayer();
        if (player) {
            player.pauseVideo();
        }
    }, []);

    const playVideo = React.useCallback(() => {
        const player = getPlayer();
        if (player) {
            player.playVideo();
        }
    }, []);

    const onPlayerReady = React.useCallback(() => {
        // when player is ready play the video (~autoplay)
        if (props.autoplay) {
            playVideo();
        }
    }, [props.autoplay, playVideo]);

    const onPlayerStateChange = React.useCallback(
        (event: any) => {
            if (event.data === PlayerState.PLAYING && onVideoPlaying) {
                onVideoPlaying();
            }
        },
        [onVideoPlaying],
    );
    const { onError } = props;
    const onPlayerError = React.useCallback(
        (event: any) => {
            if (onError) {
                onError(event.data);
            }
        },
        [onError],
    );
    React.useEffect(() => {
        const promise = makeCancelable(YoutubeIframeApi.load());

        promise.then((YT) => {
            playerRef.current = new YT.Player(
                youtubePlayerAnchor.current,
                {
                    videoId: getId(props.video.url),
                    showinfo: false,
                    events: {
                        onReady: onPlayerReady,
                        onStateChange: onPlayerStateChange,
                        onError: onPlayerError,
                    },
                },
                (error: any) => {
                    if (isCanceled(error)) {
                        return;
                    }
                    if (promise && promise.isCanceled) {
                        return;
                    }
                    throw error;
                },
            );
        });

        return () => {
            if (promise && !promise.isCanceled) {
                promise.cancel();
            }
        };
    }, [onPlayerReady, onPlayerStateChange, onPlayerError, props.video.url]);

    React.useEffect(() => {
        if (previousPause.current !== pause) {
            if (pause) {
                if (getPlayerState() === PlayerState.PLAYING) {
                    pauseVideo();
                }
            }
        }
        // @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'null'.
        previousPause.current = pause;
    }, [pause, getPlayerState, pauseVideo]);

    return (
        <iframe
            className={props.className}
            ref={youtubePlayerAnchor}
            src={`https://www.youtube.com/embed/${getId(props.video.url)}?showinfo=0&enablejsapi=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
        />
    );
};

export default YoutubePlayer;
