import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';

import { PropertyCompletionStatus } from 'strat/property/types';

const getPropertyCompletionStatusToTextMapping = (
    i18n: I18n,
    completionStatus: Values<typeof PropertyCompletionStatus>,
) => {
    switch (completionStatus) {
        case PropertyCompletionStatus.OFF_PLAN:
            return t(i18n)`Off Plan`;
        default:
            return null;
    }
};

export default getPropertyCompletionStatusToTextMapping;
