import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import Currency from 'strat/i18n/money/currency';
import { Text, Flex } from 'strat/components';

import CoverPhoto from 'horizontal/adCard/coverPhoto';
import { useAdPriceLabel } from 'horizontal/ad';
import type { AdPhoto, FullAd } from 'horizontal/types';

import styles from './styles/adCard.cssm';

type Props = {
    readonly ad: FullAd;
    readonly commission?: string;
};
const AdCard = ({ ad, commission }: Props) => {
    const coverPhoto =
        ad.photos?.find((photo: AdPhoto) => photo.main) || (ad.photos && ad.photos[0]) || null;
    const i18n = useI18n();
    const baseCurrency = settings.baseCurrencyName;
    const priceLabel = useAdPriceLabel(ad);

    return (
        <Flex className={classNames(styles.wrapper, { [styles.withCommission]: !!commission })}>
            <div className={styles.adPhotoContainer}>
                <CoverPhoto cover={coverPhoto} category={ad.category} />
            </div>
            <Flex column justifyCenter fillContainer>
                <div>
                    <Text.Regular bold>{ad.title}</Text.Regular>
                </div>
                <div>
                    <Text.XLarge>{priceLabel}</Text.XLarge>
                </div>
            </Flex>
            {commission && (
                <Flex column justifyCenter className={styles.commissionContainer}>
                    <div className={styles.commission}>
                        <Text.Regular bold>
                            <Trans>
                                {commission} {Currency.symbol(i18n, baseCurrency)}
                            </Trans>
                        </Text.Regular>
                    </div>
                    <div className={styles.commissionAmount}>
                        <Text.Regular>
                            <Trans>*Fee Amount</Trans>
                        </Text.Regular>
                    </div>
                </Flex>
            )}
        </Flex>
    );
};

export default AdCard;
