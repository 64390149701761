import { useSelector } from 'react-redux';

import type { FullAd } from 'horizontal/types';
import type { GlobalState } from 'horizontal/state';

import { selectMyAds } from './selectors';

const useMyAds = (): readonly FullAd[] => useSelector((state: GlobalState) => selectMyAds(state));

export default useMyAds;
