import * as React from 'react';

import { useDialog } from 'strat/dialogs/dialogContext';

type ProvidedProps = {
    visible: boolean;
    onVisibilityChanged: (arg1: boolean) => void;
    isFullScreen: boolean;
    setIsFullScreen: (arg1: boolean) => void;
};

type MinimizeFunction = () => void;
const RegisteredDrawers: {
    [key: string]: MinimizeFunction;
} = {};

const registerDrawer =
    <T extends ProvidedProps>(name: string, component: React.ComponentType<T>) =>
    (props: Omit<T, keyof ProvidedProps>) => {
        const [visible, setVisible] = useDialog(name);
        const [isFullScreen, setIsFullScreen] = React.useState(false);

        React.useEffect(() => {
            RegisteredDrawers[name] = () => {
                setIsFullScreen(false);
            };
            return () => {
                delete RegisteredDrawers[name];
            };
        }, []);

        return React.createElement(component, {
            visible,
            onVisibilityChanged: setVisible,
            isFullScreen,
            setIsFullScreen,
            ...props,
        } as T);
    };

export const minimizeAllDrawers = () =>
    (Object.values(RegisteredDrawers) as Array<MinimizeFunction>).forEach((minimize) => minimize());

export default registerDrawer;
