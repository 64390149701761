import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/choiceButton.cssm';

type Props = {
    readonly isSelected: boolean;
    readonly isEnabled?: boolean;
};

const ChoiceButton = ({ isSelected, isEnabled = true }: Props) => (
    <div
        className={classNames(styles.radioButton, {
            [styles.selected]: isSelected,
            [styles.disabled]: !isEnabled,
        })}
    />
);

export default ChoiceButton;
