import * as React from 'react';
import { Trans } from '@lingui/macro';

import PaymentTitle from '../paymentTitle';

const JazzCashFormTitle = () => {
    return (
        <PaymentTitle>
            <Trans>Pay via your JazzCash app</Trans>
        </PaymentTitle>
    );
};

export default JazzCashFormTitle;
