import * as React from 'react';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import { Text } from 'strat/components';

import type { Category, Location } from 'horizontal/types';
import { SearchLink } from 'horizontal/search/routing';

import SitemapList from './sitemapList';
import type { PopularSearchEntry } from './types';
import styles from './styles/popularSearchesItems.cssm';

const MAX_WORD_COUNT = 10;

const renderSearchLinkTitle = (category: Category, location: Location) => (
    <SearchLink params={{ location, category }} key={category.externalID}>
        <Text.Base bold className={styles.title}>
            {category?.name}
        </Text.Base>
    </SearchLink>
);

const renderTitle = (category: Category, location: Location) =>
    !category.children || category.children.length === 0 ? (
        renderSearchLinkTitle(category, location)
    ) : (
        <Link route={RouteNames.SITEMAP} params={{ location, category }} key={category.externalID}>
            <Text.Base bold className={styles.title}>
                {category?.name}
            </Text.Base>
        </Link>
    );

const renderListItems = (
    words: Array<PopularSearchEntry>,
    category: Category,
    location: Location,
) =>
    words.map((word, index) => (
        <SearchLink
            params={{ freeTextQuery: word.value, location, category }}
            key={index}
            className={styles.wordLink}
        >
            {word.value}
        </SearchLink>
    ));

const PopularSearchesItems = ({
    category,
    location,
    popularWords,
}: {
    category: Category;
    location: Location;
    popularWords: Array<PopularSearchEntry>;
}) => {
    if (!popularWords || popularWords.length === 0) {
        return null;
    }

    const reducedPopularWords = popularWords.slice(0, MAX_WORD_COUNT);

    return (
        <SitemapList
            renderTitle={() => renderTitle(category, location)}
            // @ts-expect-error - TS2322 - Type 'Element[]' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
            renderListItems={() => renderListItems(reducedPopularWords, category, location)}
            key={`${category.externalID}${location.externalID}`}
            containerClass={styles.listContainer}
        />
    );
};

export default PopularSearchesItems;
