import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';
import { logError } from 'strat/error/log';
import settings from '@app/branding/settings';

import type { DateRange } from 'horizontal/agencyPortal/types';
import {
    FullAd,
    ProductType,
    AdVirtualStateValues,
    AdProductTypeValues,
    AdVirtualState,
    MixedAd,
    ConsumedProductAd,
    Ad,
} from 'horizontal/types';
import { useHumanizedPeriod } from 'horizontal/util';

export const TimeOfDay = Object.freeze({
    START: 'start',
    END: 'end',
    DEFAULT: 'default',
});

// JavaScript date object is always one day off if it is without specified TimeZone
// https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
export const correctDateOffset = (date: Date): Date => {
    const dateWithOffset = new Date(date);
    return new Date(
        dateWithOffset.getTime() + Math.abs(dateWithOffset.getTimezoneOffset() * 60 * 1000),
    );
};

export const isoDate = (date: Date | null | undefined, time: Values<typeof TimeOfDay>) => {
    if (!date) {
        return undefined;
    }
    /**
     * The date picker always returns Time in the certain point of 22:00:00
     * so if the user selects 'from' date as 1/1/2020, the time will be 22:00:00
     * if the user selects 'to' date as 1/1/2020, the time will be 22:00:00
     * this is incorrect,the from time should be from 00:00:00 and the to
     * time should be 23:59:59
     */
    if (time === TimeOfDay.START) {
        return new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString().split('.')[0];
    }
    if (time === TimeOfDay.END) {
        return new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString().split('.')[0];
    }
    if (time === TimeOfDay.DEFAULT) {
        return correctDateOffset(date).toISOString().split('.')[0];
    }

    return undefined;
};

export const extractEncodedDate = (
    date: string,
): [Date | null | undefined, Date | null | undefined] => {
    if (date.includes('from') && date.includes('to')) {
        const splittedDate = [
            date.slice(date.indexOf('from'), date.indexOf('to')),
            date.slice(date.indexOf('to')),
        ];
        return [
            new Date(splittedDate[0].split('from')[1]),
            new Date(splittedDate[1].split('to')[1]),
        ];
    }
    if (date.includes('from')) {
        return [new Date(date.split('from')[1]), null];
    }
    if (date.includes('to')) {
        return [null, new Date(date.split('to')[1])];
    }
    return [null, null];
};

export const constructDateRangeEncoding = (dateFilter?: DateRange | null): string => {
    const finalFilterValues = [
        dateFilter && dateFilter.from && isoDate(dateFilter.from, TimeOfDay.DEFAULT),
        dateFilter && dateFilter.to && isoDate(dateFilter.to, TimeOfDay.DEFAULT),
    ].map((dateString) => {
        if (dateString) {
            return dateString.split('T')[0];
        }
        return null;
    });

    if (finalFilterValues[0] && finalFilterValues[1]) {
        return `from${finalFilterValues[0]}to${finalFilterValues[1]}`;
    }
    if (finalFilterValues[0]) {
        return `from${finalFilterValues[0]}`;
    }
    if (finalFilterValues[1]) {
        return `to${finalFilterValues[1]}`;
    }
    return ``;
};

const adHasProduct = (ad: Ad | ConsumedProductAd | null, productType: Values<typeof ProductType>) =>
    !!ad?.activeProducts?.[productType];

export const adHasAutoBoost = (ad: Ad | ConsumedProductAd | null): boolean =>
    adHasProduct(ad, ProductType.AUTO_REFRESH_AD);

export const adHasFeature = (ad: Ad | ConsumedProductAd | null): boolean =>
    adHasProduct(ad, ProductType.FEATURED);

export const adHasElite = (ad: Ad | ConsumedProductAd | null): boolean =>
    adHasProduct(ad, ProductType.ELITE);

export const isBoostedWithSingleBoost = (ad: MixedAd | ConsumedProductAd): boolean =>
    'singleBoostAppliedAt' in ad && !!ad.singleBoostAppliedAt;

export const getPillLabels = (i18n: I18n) => ({
    BUMP_UP: t(i18n)`Bump Up`,
    FEATURED: t(i18n)`Featured`,
    ELITE: t(i18n)`Elite`,
    ACTIVE: t(i18n)`Active`,
    EXPIRED: t(i18n)`Expired`,
    DISABLED: t(i18n)`Disabled`,
    REJECTED: t(i18n)`Rejected`,
    NOT_POSTED: t(i18n)`Not posted`,
    PENDING: t(i18n)`Pending`,
    SOLD: t(i18n)`Sold`,
});

const adVirtualStateToProductTypeMap: Partial<Record<AdVirtualStateValues, AdProductTypeValues>> = {
    [AdVirtualState.FEATURED]: ProductType.FEATURED,
    [AdVirtualState.ELITE]: ProductType.ELITE,
    [AdVirtualState.ACTIVE]: ProductType.AUTO_REFRESH_AD,
};

export const useGetProductFormattedExpiryDate = (ad: FullAd, showBoosted?: boolean) => {
    const adProductType = showBoosted
        ? ProductType.AUTO_REFRESH_AD
        : adVirtualStateToProductTypeMap[ad.virtualState];
    const expiresAt = adProductType ? ad.activeProducts?.[adProductType]?.expiresAt : null;

    return useHumanizedPeriod(expiresAt ? expiresAt * 1000 - Date.now() : null, {
        units: ['d', 'h', 'm'],
        round: true,
    });
};

export const getLangaugeSupportLink = (language: string): string | undefined => {
    const langaguesHaveSupportLinks = ['ar', 'en'];
    if (langaguesHaveSupportLinks.includes(language)) {
        return settings.support_request[language as 'en' | 'ar'];
    } else {
        logError({
            msg: `No customer support link for ${language} language`,
        });
        return undefined;
    }
};
