import type {
    Ad,
    AdExtraFields,
    FreeAdLimitUsage,
    AdLimitExtraFieldCondition,
    AdLimitConditionOperatorValues,
    AdLimitConditionMatchOperators,
} from 'horizontal/types';
import { AdLimitConditionOperator } from 'horizontal/types';

const matchConditionOperators = (
    operators: AdLimitConditionMatchOperators | null | undefined,
    value: any,
): boolean => {
    if (!operators) {
        return true;
    }

    return Object.keys(operators).every((operator: AdLimitConditionOperatorValues) => {
        const conditionValue: any = operators[operator];

        switch (operator) {
            case AdLimitConditionOperator.ONE_OF:
                return conditionValue.includes(value);
            case AdLimitConditionOperator.MISSING: {
                const isMissing = value == null;
                return conditionValue === isMissing;
            }
            case AdLimitConditionOperator.LESS_THAN:
                return value != null && value < conditionValue;
            case AdLimitConditionOperator.LESS_THAN_OR_EQUAL:
                return value != null && value <= conditionValue;
            case AdLimitConditionOperator.GREATER_THAN:
                return value != null && value > conditionValue;
            case AdLimitConditionOperator.GREATER_THAN_OR_EQUAL:
                return value != null && value >= conditionValue;
            default:
                return true;
        }
    });
};

const matchConditionExtraFields = (
    condition?: AdLimitExtraFieldCondition | null,
    extraFields?: AdExtraFields | null,
): boolean => {
    if (!condition) {
        return true;
    }

    return Object.keys(condition).every((fieldName: string) => {
        const fieldValue = extraFields?.[fieldName];
        return matchConditionOperators(condition[fieldName], fieldValue);
    });
};

export const findFreeLimitUsageByAd = (
    freeUsage?: Array<FreeAdLimitUsage> | null,
    ad?: Ad | null,
): FreeAdLimitUsage | null | undefined => {
    if (!freeUsage || !ad?.category?.length) {
        return undefined;
    }

    const adCategoryIDs: Array<number> = ad.category.map((x) => x.id);
    const mainAdCategoryID = adCategoryIDs.slice(-1)[0];

    // Based on the assumption that the quotas are sorted so that the first
    // match is the one for where quota gets subtracted in the backend.
    return freeUsage.find(({ conditions, category }) => {
        if (conditions == null) {
            if (!category) {
                return false;
            }

            // Keep using old quota matching method in case we want to quickly
            // revert to old behaviour.
            return adCategoryIDs.includes(category.id);
        }

        return [
            matchConditionOperators(conditions.category, mainAdCategoryID),
            matchConditionExtraFields(conditions.extraFields, ad.extraFields),
        ].every(Boolean);
    });
};
