import React from 'react';
import { Trans } from '@lingui/macro';

import { Flex } from 'strat/components';
import { PercentageProjectPaymentPlan } from 'strat/project/types';

import { CommonHeader, MilestoneAxisSegment } from './paymentPlanTable';
import styles from './styles/paymentPlanTable.cssm';
import usePercentagePaymentTimeline, {
    PercentagePaymentPlanMilestone,
} from './usePercentagePaymentTimeline';

type ProgressBarProps = {
    percentage: number;
};

const ProgressBar = ({ percentage }: ProgressBarProps) => (
    <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: `${percentage}%` }}></div>
        </div>
    </div>
);

const Header = () => (
    <tr className={styles.threeColumns}>
        <CommonHeader />
        <th>
            <Trans>Project Completion</Trans>
        </th>
    </tr>
);

type RowProps = {
    milestone: PercentagePaymentPlanMilestone;
};

const Row = ({ milestone }: RowProps) => (
    <tr>
        <MilestoneAxisSegment />
        <td>{milestone.title}</td>
        <td className={styles.boldText}>{milestone.valuePercentage}%</td>
        <td>
            {milestone.projectCompletion ? (
                <Flex>
                    <div className={styles.projectCompletion}>{milestone.projectCompletion}%</div>
                    <ProgressBar percentage={milestone.projectCompletion || 0} />
                </Flex>
            ) : (
                '-'
            )}
        </td>
    </tr>
);

type Props = {
    paymentPlan: PercentageProjectPaymentPlan;
};

const PercentagePaymentPlan = ({ paymentPlan }: Props) => {
    const timeline = usePercentagePaymentTimeline(paymentPlan);

    return (
        <>
            <Header />
            {timeline.map((milestone, index) => (
                <Row key={index} milestone={milestone} />
            ))}
        </>
    );
};

export default PercentagePaymentPlan;
