import * as React from 'react';
import omit from 'lodash/omit';
import classNames from 'classnames';

import defaultStyles from './styles/listingButton.cssm';

/**
 * Props for {@see ListingButton}.
 */
type Props = {
    className?: string;
    styles?: {
        [key: string]: string;
    };
    icon?: React.ReactElement<any> | null;
    children?: React.ReactNode | Array<any> | any;
    disabled?: boolean;
    onClick?: (event: React.SyntheticEvent<any>) => void;
};

/**
 * Renders a button to be embedded in a listing.
 */
const ListingButton = (props: Props): React.ReactElement => {
    const styles = props.styles || defaultStyles;
    const className = classNames(styles.button, props.className);
    const icon = props.icon ? React.cloneElement(props.icon, { className: styles.icon }) : null;

    return (
        <button className={className} {...omit(props, ['className', 'styles', 'icon'])}>
            {icon}
            {props.children && (
                <span className={icon ? styles.childrenContainer : null}> {props.children} </span>
            )}
        </button>
    );
};

export default ListingButton;
