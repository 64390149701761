import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Flex, Text } from 'strat/components';

import iconArrowDown from 'horizontal/assets/icons/iconArrowDown_noinline.svg';
import { PaymentProductPackageOffer } from 'horizontal/payment/types';

import Package from './package';
import styles from './styles/paymentOrderEntryBreakdown.cssm';

type Props = {
    productPackageOffers: Array<PaymentProductPackageOffer>;
};

const PaymentOrdersEntryBreakdown = ({ productPackageOffers }: Props) => {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <div className={styles.container}>
            <Flex alignCenter className={styles.trigger} onClick={() => setExpanded((exp) => !exp)}>
                <Text.Small bold>
                    <Trans>View breakdown</Trans>
                </Text.Small>
                <img
                    src={iconArrowDown}
                    className={classNames(styles.icon, { [styles.expanded]: expanded })}
                    alt={'Dropdown arrow'}
                />
            </Flex>
            {expanded && (
                <Flex column className={styles.packagesContainer}>
                    <Flex justifySpaceBetween alignCenter key={'header'} className={styles.package}>
                        <Text.Base bold className={styles.packageName}>
                            <Trans>Name</Trans>
                        </Text.Base>
                        <Text.Small gray bold>
                            <Trans>Current package price</Trans>
                        </Text.Small>
                    </Flex>
                    {productPackageOffers.map((productPackageOffer: PaymentProductPackageOffer) => (
                        <Package
                            productPackageOffer={productPackageOffer}
                            key={productPackageOffer.packageOfferID}
                        />
                    ))}
                </Flex>
            )}
        </div>
    );
};

export default PaymentOrdersEntryBreakdown;
