export const sortByDisplayPriority = <
    T extends {
        readonly displayPriority: number;
    },
>(
    a: T,
    b: T,
): number => a.displayPriority - b.displayPriority;

export const sortByGroupIndex = <
    T extends {
        readonly groupIndex?: number;
        readonly displayPriority: number;
    },
>(
    a: T,
    b: T,
): number =>
    a.groupIndex !== b.groupIndex
        ? (a.groupIndex ?? 0) - (b.groupIndex ?? 0)
        : a.displayPriority - b.displayPriority;

export const sortByLabel = <
    T extends {
        readonly label: string;
    },
>(
    a: T,
    b: T,
): number => (a.label > b.label ? 1 : -1);

// Sort <decreasing> by Ad Count
export const sortByAdCount = <
    U extends {
        readonly activeCount: number;
    },
    T extends {
        readonly statistics: U;
    },
>(
    a: T,
    b: T,
): number => b.statistics.activeCount - a.statistics.activeCount;
