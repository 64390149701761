import * as React from 'react';

import styles from './styles/subsection.cssm';

/**
 * Properties for {@see SubSection}.
 */
type Props = {
    title?: string | React.ReactElement;
    renderTitle?: (title: string | React.ReactElement) => React.ReactElement;
    className?: string;
    children?: React.ReactNode;
    id?: string;
};

/**
 * Renders a titled? content subsection.
 */
const SubSection = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            renderTitle = (title: string | React.ReactElement) => (
                <h3 className={styles.title}>{title}</h3>
            ),
            className = styles.subsection,
            ...props
        },
        ref,
    ) => (
        <div className={className} id={props.id} ref={ref}>
            {props.title && renderTitle(props.title)}
            {props.children}
        </div>
    ),
);

export default SubSection;
