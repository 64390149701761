import type { PropertyData } from 'strat/property/types';

const SmartShareRecommendationStatus = Object.freeze({
    RECENTLY_ADDED: 'recently_added',
    VIEWED: 'viewed',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
});

export type SmartShareRecommendationData = {
    id: number;
    hidden: boolean;
    status: Values<typeof SmartShareRecommendationStatus>;
    adExternalID: string;
    property: PropertyData;
};

export type Choice = {
    name: string;
    value: number | string;
};

export type RecommenderUserDetailsData = {
    purpose: Choice;
    category: string;
    bedrooms: Array<Choice>;
    bathrooms: Array<Choice>;
    location?: string;
};

export type SmartShareCommentData = {
    id: number;
    text?: string;
    addedRecommendationsCount?: number;
    createdAt: string;
    updatedAt?: string;
};

export type SmartShareInformationData = {
    id: number;
    leadID: string;
    clientID: string;
    ad: PropertyData;
    adExternalID: string;
    agentExternalID: string;
    userLastVisited?: string;
    agentLastVisited?: string;
    createdAt: string;
    updatedAt?: string;
    language: string;
    phoneNumber?: string;
};

const SmartShareLeadType = Object.freeze({
    SMS: 'sms',
    WHATSAPP: 'whatsapp',
});

const SmartShareUserType = Object.freeze({
    AGENT: 'agent',
    USER: 'user',
    OTHER: 'other',
});

/*
    USER - the link is created by the user and send it to the agent
    AGENT - the link is created by the agent and send it to the user
 */
const SmartShareLinkType = Object.freeze({
    USER: 'lead_link',
    AGENT: 'shared_link',
});

export {
    SmartShareRecommendationStatus,
    SmartShareLeadType,
    SmartShareUserType,
    SmartShareLinkType,
};
