import * as React from 'react';
import classNames from 'classnames';

import { PropertyState } from 'strat/property/types';
import WhatsappButton from 'strat/contact/buttons/whatsappButton';
import SMSButton from 'strat/contact/buttons/smsButton';
import { ViewSections, withGTMLeadTracking } from 'strat/gtm';
import type { GTMLeadTrackingProps } from 'strat/gtm';
import type { PropertyData } from 'strat/property';
import { stopEventPropagation } from 'strat/util';
import { ButtonVariant } from 'strat/components';

import styles from './styles/listingCompact.cssm';

/**
 * Properties for {@see WhatsAppOrSmsButtons}.
 */
export type WhatsappOrSmsButtonsProps = GTMLeadTrackingProps & {
    property: PropertyData;
    index?: number;
    /**
     * View section this component is placed on.
     */
    viewSection: Values<typeof ViewSections>;
    displayText?: boolean;
    className?: string;
    listingPageButtons?: boolean;
    trackLeadPropertyInteraction?: (property: PropertyData) => void;
    withIcon?: boolean;
    whatsAppTextClassName?: string;
    variant?: Values<typeof ButtonVariant>;
};

/**
 * Renders whatsapp and sms buttons for a listing.
 */
const WhatsAppOrSmsButtons = ({
    property,
    index,
    viewSection,
    displayText = false,
    className,
    variant = ButtonVariant.LIGHT,
    listingPageButtons,
    trackLeadPropertyInteraction,
    withIcon = true,
    whatsAppTextClassName,
}: WhatsappOrSmsButtonsProps) => {
    const phoneNumber = property.phoneNumber || {};
    const buttonsDisabled = property.state === PropertyState.INACTIVE;
    const smsClassName = classNames(className, { [styles.hideableIcon]: !listingPageButtons });
    const trackInteraction = React.useCallback(() => {
        if (trackLeadPropertyInteraction) {
            trackLeadPropertyInteraction(property);
        }
    }, [trackLeadPropertyInteraction, property]);

    // @ts-expect-error - TS2339 - Property 'whatsapp' does not exist on type '{}'.
    return phoneNumber.whatsapp ? (
        <WhatsappButton
            className={classNames(className, styles.whatsappButton)}
            index={index}
            property={property}
            onClick={(event: React.SyntheticEvent<any>) => {
                stopEventPropagation(event);
                trackInteraction();
            }}
            viewSection={viewSection}
            displayText={displayText}
            withIcon={withIcon}
            textClassName={whatsAppTextClassName}
            variant={variant}
        />
    ) : property.mobilePhoneNumber ? (
        <SMSButton
            disabled={buttonsDisabled}
            property={property}
            className={smsClassName}
            onClick={(event: React.SyntheticEvent<any>) => {
                stopEventPropagation(event);
                trackInteraction();
            }}
            viewSection={viewSection}
            requiresLoginForContact={property.requiresLoginForContact}
            withIcon={withIcon}
            variant={variant}
        />
    ) : null;
};

export default withGTMLeadTracking(WhatsAppOrSmsButtons);
