import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ViewSections } from 'strat/gtm';
import { RouteNames } from 'strat/routes';
import { selectRouteName } from 'strat/routes/selectors';
import type { AppDispatch } from 'strat/state';
import { updateBottomBanerState } from 'strat/appInstallBanners';
import { incrementDeeplinkCookieByOne } from 'strat/header/compact/bannerUtils';

import Link from 'horizontal/adCard/link';
import { updateRecentlyViewedAds } from 'horizontal/search/recentlyViewed';
import { LiteAd } from 'horizontal/types';

type Props = {
    readonly ad: LiteAd;
    readonly viewSection: Values<typeof ViewSections>;
    readonly isMobile: boolean;
    readonly children?: React.ReactNode;
};

const AdLink = ({ ad, isMobile, children }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const route = useSelector(selectRouteName);

    return (
        <Link
            preloadedAd={ad}
            params={{ slug: ad.slug, externalID: ad.externalID }}
            onClick={() => {
                dispatch(updateRecentlyViewedAds(ad));
                if (isMobile && route === RouteNames.AD_DETAILS) {
                    incrementDeeplinkCookieByOne();
                    dispatch(updateBottomBanerState());
                }
            }}
        >
            {children}
        </Link>
    );
};

export default AdLink;
