import { SmartShareRecommendationStatus, SmartShareLeadType } from 'strat/smartShare/types';

import StratAPI from './strat';

class SmartShareAPI extends StratAPI {
    getSmartShare(leadID: string) {
        return super.request(`/api/smartShare/${leadID}`);
    }

    createSmartShare(
        leadID: string,
        leadType: Values<typeof SmartShareLeadType>,
        clientID: string | null,
        adExternalID: string,
        language: string,
    ) {
        const body = { leadID, clientID, adExternalID, language } as const;
        return super.post(`/api/smartShare`, body);
    }

    getRecommendations(leadID: string) {
        return super.request(`/api/smartShare/${leadID}/recommendations`);
    }

    updateSmartShareVisited(leadID: string, isAgentView: boolean) {
        const userType = isAgentView ? 'agent' : 'user';
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return super.post(`/api/smartShare/${leadID}/visited/${userType}`);
    }

    getComments(leadID: string) {
        return super.request(`/api/smartShare/${leadID}/comments`);
    }

    updateRecommendationStatus(
        recommendationID: number,
        status: Values<typeof SmartShareRecommendationStatus>,
    ) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return super.post(`/api/smartShare/recommendations/${recommendationID}/status/${status}`);
    }

    toggleHiddenRecommendation(userID: string, recommendationID: number) {
        // @ts-expect-error - TS2554 - Expected 2-3 arguments, but got 1.
        return super.post(
            `/api/user/${userID}/smartShare/recommendations/${recommendationID}/toggleHidden`,
        );
    }

    addRecommendations(userID: string, leadID: string, adExternalIDs: Array<string>) {
        return super.post(`/api/user/${userID}/smartShare/${leadID}/recommendations`, {
            adExternalIDs,
        });
    }

    addPhoneNumber(userID: string, leadID: string, phoneNumber: string) {
        return super.post(`/api/user/${userID}/smartShare/${leadID}/phoneNumber`, { phoneNumber });
    }

    addComment(
        userID: string,
        leadID: string,
        payload: {
            text?: string;
            addedRecommendationsCount?: number;
        },
    ) {
        return super.post(`/api/user/${userID}/smartShare/${leadID}/comments`, payload);
    }

    updateComment(userID: string, commentID: string, text: string) {
        return super.put(`/api/user/${userID}/smartShare/comments/${commentID}`, { text });
    }

    deleteComment(userID: string, commentID: string) {
        // @ts-expect-error - TS2339 - Property 'delete' does not exist on type 'StratAPI'.
        return super.delete(`/api/user/${userID}/smartShare/comments/${commentID}`);
    }
}

export default SmartShareAPI;
