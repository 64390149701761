import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';
import { RecaptchaUsageConditions } from 'strat/captcha';

import { OTPTriggerFlow } from '../types';
import { isVoiceOTPFirst } from '../utils';

import styles from './styles/otpForm.cssm';
import FormContainer from './formContainer';
import OTPSubForm from './otpSubForm';

type Props = {
    readonly value: string;
    readonly onChange: (arg1: string) => void;
    readonly onSubmit: (arg1?: OTPTriggerFlow) => void;
    readonly onBack: () => void;
    readonly onRequestNewCode: (arg1: {
        captcha: string | null | undefined;
        channel: string | null | undefined;
        otpTriggerFlow?: OTPTriggerFlow;
    }) => void;
    readonly onRequestPhoneCall?: (arg1?: string | null | undefined, arg2?: OTPTriggerFlow) => void;
    readonly userDescriptor: string;
    readonly errorMessage?: string | null | undefined;
    readonly enableResendCode: boolean;
    readonly isPhone: boolean;
    readonly withCaptcha?: boolean;
    readonly isLoading?: boolean | null | undefined;
    readonly otpTriggerFlow?: OTPTriggerFlow;
};

const otpLength = 4;

const OTPForm = ({ userDescriptor, onBack, isLoading, isPhone, withCaptcha, ...props }: Props) => {
    const i18n = useI18n();
    const subtitle = t(i18n)`Enter the ${otpLength}-digit code we sent to `;

    return (
        <FormContainer
            title={t(i18n)`Enter confirmation code`}
            onBack={onBack}
            renderFooter={withCaptcha ? () => <RecaptchaUsageConditions /> : undefined}
        >
            <Flex alignCenter justifyCenter>
                <Text.Regular className={styles.subtitle}>
                    {isPhone && isVoiceOTPFirst() ? (
                        <Trans>
                            You will receive a {otpLength}-digit code through a call on{' '}
                            <strong>{userDescriptor}</strong>
                        </Trans>
                    ) : (
                        <span>
                            {subtitle} <strong>{userDescriptor}</strong>
                        </span>
                    )}
                </Text.Regular>
            </Flex>
            <OTPSubForm
                isLoading={!!isLoading}
                isPhone={isPhone}
                withCaptcha={withCaptcha}
                {...props}
            />
        </FormContainer>
    );
};

export default OTPForm;
