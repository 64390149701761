import React from 'react';

import { PaymentAreeba } from 'horizontal/payment/areeba';
import type { PaymentOrder } from 'horizontal/payment';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly orderExternalID: string;
    readonly sessionID: string;
    readonly order: PaymentOrder;
};

const PaymentAreebaPage = {
    head: () => <PrivateNonIndexableHead />,
    body: (props: Props) => <PaymentAreeba {...props} />,
} as const;

export default PaymentAreebaPage;
