import { CancelableRoute } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID, selectUserRoles } from 'strat/user/session';
import { catchCanceled } from 'strat/util';
import { isProAgent, isProAgencyOwner } from 'strat/user/roles';

import { preloadAd } from 'horizontal/ad/state';
import { isAdEditor } from 'horizontal/util';
import { getStratAPI, StratAPI } from 'horizontal/api';
import Page from 'horizontal/pages/page';
import {
    filterEligibleProductPurchases,
    ProductPurchaseStatus,
    packagePurchaseErrorTypes,
} from 'horizontal/packages';
import { AdState, ProductPackageOffer } from 'horizontal/types';
import type { FullAd } from 'horizontal/types';

import { fetchProductPurchases } from '../packages/state';
import { selectProductPurchases } from '../packages/selectors';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type SelectPackageRouteParams = {
    externalID: string;
    withoutSkipButton?: boolean;
};

class SelectPackageRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.SELECT_PACKAGE, [
            [
                '^/payments/packages/consume/boost/',
                {
                    name: 'externalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { externalID, withoutSkipButton }: SelectPackageRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        const queryParams = { withoutSkipButton: withoutSkipButton ? 'true' : undefined };
        return {
            pathname: `/payments/packages/consume/boost/${externalID}`,
            search: queryParams,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }

        // render the page so we can display the loading spinner
        context.rendering.renderPage(Page.SELECT_PACKAGE);

        const {
            redux: {
                store: { dispatch, getState },
            },
            match: {
                params: { externalID, withoutSkipButton },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        const dataPromise = Promise.all([
            this.fetchAd(context),
            this.fetchOffers(context),
            this.cancelable(
                dispatch(
                    fetchProductPurchases({
                        adExternalID: externalID,
                        status: ProductPurchaseStatus.ACTIVE,
                        bundleProductPurchases: true,
                    }),
                ),
            ),
        ]);

        context.promise.wait(
            dataPromise
                .then(([ad, packageOffers]) => {
                    if (!ad || !isAdEditor(ad, userExternalID)) {
                        context.http.status(404);
                        return context.rendering.renderPage(Page.NOT_FOUND);
                    }

                    if ([AdState.REJECTED, AdState.SOFT_REJECTED].includes(ad.state)) {
                        return context.rendering.renderPage(Page.APPLY_AD_SINGLE_PACKAGE_ERROR);
                    }

                    if (![AdState.ACTIVE, AdState.PENDING].includes(ad.state)) {
                        context.http.status(404);
                        return context.rendering.renderPage(Page.NOT_FOUND);
                    }

                    const productPurchases = selectProductPurchases(getState());
                    const eligibleProductPurchases = filterEligibleProductPurchases(
                        ad,
                        productPurchases,
                    );

                    if (eligibleProductPurchases.length > 0) {
                        return context.rendering.renderPage(Page.SELECT_PACKAGE, {
                            ad,
                            purchasePackages: eligibleProductPurchases,
                            withoutSkipButton: withoutSkipButton === 'true',
                        });
                    }

                    const userRoles = selectUserRoles(context.redux.store.getState());
                    if (isProAgent(userRoles) && !isProAgencyOwner(userRoles)) {
                        return context.rendering.renderPage(Page.PACKAGE_PURCHASE_ERROR, {
                            errorStatus: packagePurchaseErrorTypes.proAgent,
                        });
                    }

                    dispatch(preloadAd(ad));
                    return context.rendering.renderPage(Page.SELECT_SINGLE_PACKAGE, {
                        offerPackages: packageOffers,
                        ad,
                        withoutSkipButton: withoutSkipButton === 'true',
                    });
                })
                .catch(catchCanceled),
        );
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<FullAd | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { externalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!externalID || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) => api.userAd(userExternalID, externalID));
    }

    fetchOffers(
        context: RoutingContextWithMiddlewares,
    ): Promise<ProductPackageOffer[] | null | undefined> {
        const {
            match: {
                params: { externalID },
            },
        } = context;

        if (!externalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) =>
            api.getAdProductPackageOffers(externalID, { sortBy: '-price' }),
        );
    }

    private makeStratAPIRequest<T>(
        context: RoutingContextWithMiddlewares,
        callback: (api: StratAPI) => Promise<{ status: number; data: T }>,
    ) {
        const {
            redux: {
                store: { getState },
            },
        } = context;

        const api = getStratAPI(getState());
        const request = this.cancelable(callback(api));

        return request.then((response) => (response.status < 300 ? response.data : null));
    }
}

export default new SelectPackageRoute();
