import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';
import { sanitizeBoolean } from 'strat/sanitizers/commonSanitizers';

const HIDE_PAYMENT_HEADER_KEY = 'hidePaymentHeader';

const useShouldHidePaymentHeader = (): boolean => {
    const [shouldHideHeader, setShouldHideHeader] = React.useState(false);
    const routeParams = useSelector(selectRouteParams);
    const hideHeaderParam = (routeParams || {}).hideHeader || null;

    React.useEffect(() => {
        const hidePaymentHeader = localStorage.getItem(HIDE_PAYMENT_HEADER_KEY);

        if (hideHeaderParam) {
            setShouldHideHeader(hideHeaderParam);
            localStorage.setItem(HIDE_PAYMENT_HEADER_KEY, String(hideHeaderParam));
        } else if (hidePaymentHeader) {
            setShouldHideHeader(sanitizeBoolean(hidePaymentHeader));
        }
    }, [hideHeaderParam]);

    return shouldHideHeader;
};

export default useShouldHidePaymentHeader;
