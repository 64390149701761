import * as React from 'react';

import { PropertyDetailsMetaData, PropertyItemPageMetaData } from 'strat/schema';
import type { PropertyData } from 'strat/property/types';

type Props = {
    property: PropertyData;
};

const PropertyMetadata = ({ property }: Props) => (
    <>
        <PropertyItemPageMetaData property={property} />
        {/* @ts-expect-error - TS2322 - Type '{ property: PropertyData; }' is not assignable to type 'IntrinsicAttributes & Diff<{ areaInLocalUnits: any; formatLocalUnitsValue: any; property: any; }, { areaInLocalUnits: any; formatLocalUnitsValue: any; property: any; } & LocalUnitsProps & { ...; }> & RefAttributes<...> & { ...; }'. */}
        <PropertyDetailsMetaData property={property} />
    </>
);

export default PropertyMetadata;
