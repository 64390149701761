import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import classNames from 'classnames';
import { Draggable } from 'strat/generic';
import { Flex, Text } from 'strat/components';
import { useActiveUser } from 'strat/user/session';
import { Trans } from '@lingui/macro';

import AddIcon from 'horizontal/assets/icons/addCardButton.svg';
import { UploadedPhoto } from 'horizontal/types';

import Image from '../image';

import styles from './styles/imageCollectionInput.cssm';
import { ImageType, UploadingImage, useUploadImages } from './useUploadImages';
import ImageInputFooter from './imageInputFooter';
import useDragEvents from './useDragEvents';
import { ImageCollectionInputProps } from './types';

const generateImageID = (prefix: string) => `${prefix}-${uuid4()}`;

const mapImages = (images: Array<ImageType>): Array<UploadedPhoto> =>
    (images.filter((image) => !!image && !!image.src) as Array<UploadingImage>).map((image) => ({
        id: image.imageID,
        // we only set the url for new images (those do not have imageID)
        url: image.imageID ? null : image.src,
        externalID: image.externalID as string,
        width: image.width,
        height: image.height,
    }));

const ImageCollectionInput = ({
    maxImagesCount = 20,
    initialImagesCount = 13,
    initialValue = [],
    setFieldValue,
    touched,
    errors,
    onBlur,
    setLoading,
    field,
}: ImageCollectionInputProps) => {
    const user = useActiveUser();

    const {
        images,
        setImages,
        remove,
        retry,
        anyImageTooLarge,
        anyImageResolutionTooHigh,
        anyImageLoading,
        anyUploadFailed,
        uploadImages,
    } = useUploadImages({
        initialImagesCount,
        maxImagesCount,
        generateImageID: () => generateImageID(String(user?.id)),
        initialValue,
    });

    const { onDragStart, onDragLeave, onDragOver, onDrop } = useDragEvents(images, setImages);

    const onChange = React.useCallback(
        (event) => {
            const files = Array.from(event.target.files) as File[];

            uploadImages(files);

            event.target.value = null;
        },
        [uploadImages],
    );

    React.useEffect(() => {
        setLoading(anyImageLoading);
        if (anyImageTooLarge || anyUploadFailed || anyImageResolutionTooHigh) {
            setLoading(true);
        }
    }, [anyImageLoading, anyImageTooLarge, anyUploadFailed, anyImageResolutionTooHigh, setLoading]);

    React.useEffect(() => {
        setFieldValue(field.attribute, mapImages(images));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);

    return (
        <Flex justifySpaceBetween className={styles.fieldWrapper}>
            <Text.Regular bold>
                <Trans>Upload Images</Trans>
            </Text.Regular>
            <Flex column fillContainer className={styles.imageInputContainer}>
                <label className={styles.imageInput}>
                    <input
                        type="file"
                        name={field.attribute}
                        accept="image/png, image/jpeg"
                        autoComplete="off"
                        multiple
                        className={styles.input}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                    <Flex justifyCenter alignCenter className={styles.addImageButton}>
                        <AddIcon className={styles.addIcon} />
                    </Flex>
                    {images.map((image: ImageType, index: number) => (
                        <Draggable
                            className={classNames({ [styles.draggable]: !!image?.src })}
                            key={`${String(image?.src)}-${index}`}
                            onDragStart={onDragStart(index)}
                            onDragLeave={onDragLeave}
                            onDragOver={onDragOver}
                            onDrop={onDrop(index)}
                        >
                            <Image
                                {...image}
                                retry={() => (image ? retry(image) : null)}
                                remove={() => (image ? remove(image) : null)}
                                isCoverImage={index === 0}
                            />
                        </Draggable>
                    ))}
                </label>
                <ImageInputFooter
                    errorMessage={errors[field.attribute]}
                    anyImageTooLarge={anyImageTooLarge}
                    anyImageResolutionTooHigh={anyImageResolutionTooHigh}
                    anyUploadFailed={anyUploadFailed}
                    isTouched={touched[field.attribute]}
                />
            </Flex>
        </Flex>
    );
};

export default ImageCollectionInput;
