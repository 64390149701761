import type { Dispatch as ReduxDispatch } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { GlobalState } from '@app/state';

import supportsWebP from 'strat/util/supportsWebp';

const Actions = Object.freeze({
    SET_WALLPAPER: 'WALLPAPER/SET',
    SET_WALLPAPER_TAKEOVER: 'WALLPAPER/SET_TAKEOVER',
    CLEAR_WALLPAPER_TAKEOVER: 'WALLPAPER/CLEAR_TAKEOVER',
} as const);

type SetWallpaperTakeoverAction = {
    readonly type: typeof Actions.SET_WALLPAPER_TAKEOVER;
    readonly targetURL: string;
    readonly imageURL: string;
    readonly webpImageURL: string;
    readonly smallImageURL?: string | null;
    readonly webpSmallImageURL?: string | null;
    readonly title?: string | null;
    readonly titleColor?: string | null;
};

type SetWallpaper = {
    readonly type: typeof Actions.SET_WALLPAPER;
    readonly imageURL: string | null;
    readonly webpImageURL?: string | null;
};

type ClearWallpaperTakeover = {
    readonly type: typeof Actions.CLEAR_WALLPAPER_TAKEOVER;
};

export type WallpaperTakeover = {
    readonly imageURL: string | null;
    readonly webpImageURL: string | null;
    readonly smallImageURL?: string | null;
    readonly webpSmallImageURL?: string | null;
    readonly targetURL: string | null;
    readonly title?: string | null;
    readonly titleColor?: string | null;
};

export type MainWallpaper = {
    readonly imageURL: string | null;
    readonly webpImageURL?: string | null;
};

export type WallpaperState = {
    readonly mainWallpaper: MainWallpaper;
    readonly wallpaperTakeover: WallpaperTakeover;
};

type WallpaperDispatch = ReduxDispatch<
    ThunkDispatch<GlobalState, unknown, SetWallpaperTakeoverAction>
>;

/**
 * Action creator for changing the wallpaper of the homepage
 * @param targetURL - the URL the homepage link box should point to (campaign website)
 * @param imageURL - URL of the image to display as wallpaper
 * @param webpImageURL - URL of the image in webp format to display as wallpaper
 * @param smallImageURL - URL of the image to display as wallpaper for screens smaller than 1366 px width
 * @param webpSmallImageURL - URL of the image in webp format to display as wallpaper for screens smaller than
 *                            1366 px width
 * @param title - URL of the image to display as wallpaper
 * @param titleColor - background color for the mobile button containing the title
 */
const setWallpaperTakeover =
    (
        targetURL: string | null | undefined,
        imageURL: string,
        webpImageURL: string,
        smallImageURL: string,
        webpSmallImageURL: string,
        title: string,
        titleColor?: string | null,
    ) =>
    (dispatch: WallpaperDispatch): void => {
        const image = new Image();

        image.onload = () => {
            dispatch({
                type: Actions.SET_WALLPAPER_TAKEOVER,
                targetURL,
                imageURL,
                webpImageURL,
                smallImageURL,
                webpSmallImageURL,
                title,
                titleColor,
            });
        };

        supportsWebP().then((canLoadWebP) => {
            image.src = canLoadWebP && webpImageURL ? webpImageURL : imageURL;
        });
    };

/**
 * Action creator for clearing the wallpaper of the homepage
 */
const clearWallpaperTakeover = () => ({
    type: Actions.CLEAR_WALLPAPER_TAKEOVER,
});

const setMainWallpaper = (imageURL: string, webpImageURL: string) => ({
    type: Actions.SET_WALLPAPER,
    imageURL,
    webpImageURL,
});

const defaultState: WallpaperState = Object.freeze({
    wallpaperTakeover: Object.freeze({
        imageURL: null,
        webpImageURL: null,
        smallImageURL: null,
        webpSmallImageURL: null,
        targetURL: null,
        title: null,
        titleColor: null,
    }),
    mainWallpaper: Object.freeze({
        imageURL: null,
        webpImageURL: null,
    }),
});

const wallPaperReducer = (
    state: WallpaperState = defaultState,
    action: SetWallpaperTakeoverAction | SetWallpaper | ClearWallpaperTakeover,
): WallpaperState => {
    switch (action.type) {
        case Actions.SET_WALLPAPER:
            return {
                ...state,
                mainWallpaper: {
                    imageURL: action.imageURL,
                    webpImageURL: action.webpImageURL,
                },
            };
        case Actions.SET_WALLPAPER_TAKEOVER:
            return {
                ...state,
                wallpaperTakeover: {
                    imageURL: action.imageURL,
                    webpImageURL: action.webpImageURL,
                    smallImageURL: action.smallImageURL,
                    webpSmallImageURL: action.webpSmallImageURL,
                    targetURL: action.targetURL,
                    title: action.title,
                    titleColor: action.titleColor,
                },
            };
        case Actions.CLEAR_WALLPAPER_TAKEOVER:
            return {
                ...state,
                wallpaperTakeover: {
                    ...defaultState.wallpaperTakeover,
                },
            };
        default:
            return state;
    }
};

export { setWallpaperTakeover, clearWallpaperTakeover, setMainWallpaper };

export default wallPaperReducer;
