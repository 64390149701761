import { t } from '@lingui/macro';
import * as React from 'react';
import { Text } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { v4 as uuid4 } from 'uuid';

import type { ChatMessageFileUploadData } from 'horizontal/chat/types';
import IconImage from 'horizontal/assets/icons/iconImage.svg';

import styles from './styles/dialogContent.cssm';

type Props = {
    readonly sendImages: (files: Array<ChatMessageFileUploadData>) => void;
    readonly onSubmit: () => void;
    readonly setErrorMessage: (arg1: string) => void;
};

const acceptedFileTypes = ['image/png', 'image/jpeg'];

const UploadImageButton = ({ sendImages, onSubmit, setErrorMessage }: Props) => {
    const i18n = useI18n();

    const uploadImages = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const maxUploadImagesCount = 20;
            const maxUploadImageSize = CONFIG.build.TEMP_IMAGE_MAX_SIZE_MB;
            // @ts-expect-error - TS2769 - No overload matches this call.
            const files = Array.from(event.target.files).map((file) => ({ file, id: uuid4() }));

            if (files.length > maxUploadImagesCount) {
                setErrorMessage(t(i18n)`Cannot upload more than ${maxUploadImagesCount} photos`);
                return;
            }

            if (
                files.some(
                    ({ file }) => file.size / 1024 ** 2 > CONFIG.build.TEMP_IMAGE_MAX_SIZE_MB,
                )
            ) {
                setErrorMessage(t(i18n)`Maximum file size is ${maxUploadImageSize}MB`);
                return;
            }

            if (files.some(({ file }) => !acceptedFileTypes.includes(file.type))) {
                setErrorMessage(t(i18n)`The only allowed file types are png, jpg and jpeg`);
                return;
            }

            sendImages(files);
            onSubmit();
        },
        [sendImages, onSubmit, i18n, setErrorMessage],
    );

    return (
        <>
            <input
                type="file"
                className={styles.hideDefaultInput}
                multiple
                accept={acceptedFileTypes.join(',')}
                onChange={uploadImages}
            />
            <IconImage className={styles.icon} />
            <Text.Regular>{t(i18n)`Gallery`}</Text.Regular>
        </>
    );
};

export default React.memo<Props>(UploadImageButton);
