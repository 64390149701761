import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import LogoIcon from '@app/assets/icons/logo_noinline.svg';
import { selectLanguage } from 'strat/i18n/language/selectors';
import { RouteNames } from 'strat/routes';
import { Link } from 'strat/navigation';
import BrandIcon from '@app/assets/icons/iconLogo.svg';
import BrandIconAR from '@app/assets/icons/iconLogoAR.svg';
import IconLogoTiny from '@app/assets/icons/iconLogoTiny.svg';
import styles from '@app/branding/styles/logo.cssm';

type Props = {
    readonly className?: string;
    readonly displaySearchInputLogo?: boolean;
    readonly small?: boolean;
    readonly tiny?: boolean;
    readonly onClick?: () => void | null | undefined;
};

const Logo = ({ className, displaySearchInputLogo, small, tiny, onClick = undefined }: Props) => {
    const language = useSelector(selectLanguage);
    const renderIcon = () => {
        if (tiny) {
            // @ts-expect-error - TS2322 - Type '{ alt: string; className: string; }' is not assignable to type 'IntrinsicAttributes & SVGAttributes<SVGElement> & { children?: ReactNode; }'.
            return <IconLogoTiny alt={'Logo'} className={classNames(className, styles.tiny)} />;
        }
        if (small || displaySearchInputLogo) {
            const BrandIconComponent = language === 'ar' ? BrandIconAR : BrandIcon;
            return (
                <BrandIconComponent
                    // @ts-expect-error - TS2322 - Type '{ alt: string; className: string; }' is not assignable to type 'IntrinsicAttributes & SVGAttributes<SVGElement> & { children?: ReactNode; }'.
                    alt={'Logo'}
                    className={classNames({
                        [styles.searchInputLogo]: displaySearchInputLogo,
                        [styles.small]: small,
                    })}
                />
            );
        }
        if (settings.enableMultiLanguageLogo) {
            return (
                // @ts-expect-error - TS2322 - Type '{ children: Element; className: any; alt: string; }' is not assignable to type 'SVGProps<SVGSVGElement>'.
                <svg className={styles.small} alt={'Logo'}>
                    <use xlinkHref={`${LogoIcon}#${language}`} />
                </svg>
            );
        }

        return <img src={`${LogoIcon}`} alt={'Logo'} className={styles.img} />;
    };

    return (
        <div
            onClick={onClick}
            className={classNames(className, {
                [styles.searchInputLogolDiv]: displaySearchInputLogo,
            })}
        >
            <Link route={RouteNames.HOME} className={classNames(styles.link)}>
                {renderIcon()}
            </Link>
        </div>
    );
};

export default Logo;
