import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';

import { useI18n } from 'strat/i18n/language';
import { Flex } from 'strat/components';
import { CompletedStarRating } from 'strat/agency/agent/review';

import styles from './styles/reviewInfo.cssm';

type Props = {
    reviewCount: string | number | null;
    isCompact?: boolean;
    avgStarRating?: number | null;
};

const ReviewInfo = ({ reviewCount, avgStarRating = 0, isCompact = false }: Props) => {
    const i18n = useI18n();

    if (!reviewCount) {
        return <>{t(i18n)`No reviews yet`}</>;
    }
    return (
        <Flex alignCenter>
            <CompletedStarRating starRating={avgStarRating} isCompact={isCompact} />
            <span className={styles.separator}>&middot;</span>
            {t(i18n)`${plural(reviewCount, {
                zero: '# reviews',
                one: '# review',
                two: '# reviews',
                few: '# reviews',
                many: '# reviews',
                other: '# reviews',
            })}`}
        </Flex>
    );
};

export default ReviewInfo;
