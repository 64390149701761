import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { I18n } from '@lingui/core';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import Category from '@app/branding/category';

import { AdsCarousel } from 'horizontal/ad';
import type { LiteAd, LiteCategory } from 'horizontal/types';

import styles from './styles/relatedAdsSection.cssm';

const generateTitle = (i18n: I18n, isUsed: boolean, category?: LiteCategory) => {
    if (Category.isOfCarsType(category)) {
        if (isUsed) {
            return t(i18n)`New cars for similar price`;
        }
        return t(i18n)`Similar cars`;
    }
    return t(i18n)`Related ads`;
};

type Props = {
    readonly category?: LiteCategory;
    readonly relatedAds: ReadonlyArray<LiteAd>;
    readonly seeMoreLimit?: number;
    readonly handleSeeMore?: () => void;
    readonly isUsed?: boolean; // signals if the ad is new or used product/service
};

const RelatedAdsSection = ({
    relatedAds,
    category,
    seeMoreLimit = 0,
    handleSeeMore,
    isUsed = false,
}: Props) => {
    const i18n = useI18n();
    const title = generateTitle(i18n, isUsed, category);

    React.useEffect(() => {
        if (!relatedAds) {
            return;
        }
        trigger(Triggers.RELATED_ADS, {
            related_ads: relatedAds.map((relatedAd) => relatedAd.externalID),
            view_section: ViewSections.BODY,
        });
    }, [relatedAds]);

    return (
        <div className={styles.mainContainer} aria-label="Related Ads">
            <div className={styles.title}>{title}</div>
            <div className={styles.adsContainer}>
                <AdsCarousel
                    ads={relatedAds}
                    hideAgency
                    seeMoreLimit={seeMoreLimit}
                    handleSeeMore={handleSeeMore}
                />
            </div>
        </div>
    );
};

export default RelatedAdsSection;
