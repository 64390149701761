import * as React from 'react';

import { ChatSDKContext } from 'horizontal/chat/state';
import type { ChatSDK } from 'horizontal/chat/types';

const useChatSDK = (): ChatSDK => {
    const sdk = React.useContext(ChatSDKContext);
    if (sdk == null) {
        throw new Error('Component tree must be wrapped with ChatSDKContext.Provider');
    }

    return sdk;
};

export default useChatSDK;
