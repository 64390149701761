import { CommonAdData } from 'strat/property/types';
import Category from 'strat/branding/category';

export const prioritizeCategoryPurposeHits = (
    hits: Array<CommonAdData>,
    propertyTypeSlug: string,
    purpose: string,
    count: number,
) => {
    const sameCategoryPurposeHits = hits.filter(
        (hit) =>
            Category.isOfType(hit.category[hit.category.length - 1], propertyTypeSlug) &&
            hit.purpose === purpose,
    );
    const otherHits = hits.filter(
        (hit) => !Category.isOfType(hit.category[hit.category.length - 1], propertyTypeSlug),
    );

    return sameCategoryPurposeHits.length > count
        ? sameCategoryPurposeHits
        : sameCategoryPurposeHits.concat(
              otherHits.slice(0, count - sameCategoryPurposeHits.length),
          );
};
