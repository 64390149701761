import * as React from 'react';
import { AppType } from 'strat/alerts/types';
import { Flex } from 'strat/components';

import { UserOrdersSection } from './types';
import Menu from './menu';
import OrdersSheet, { ProductConsumption } from './orders';
import BuyingOrdersSheet from './buyingOrders';
import { useOrdersSectionParam } from './hooks';
import BillingSheet from './billingSheet';
import PaymentsSheet from './payment/paymentsSheet';
import PaymentOrdersSheet from './paymentOrders/paymentOrdersSheet';
import SellingOrdersSheet from './sellingOrders';
import AddressesSheet from './addresses/addresses';

const Sheet = ({
    section,
    productPurchaseID,
}: {
    section: Values<typeof UserOrdersSection> | null | undefined;
    productPurchaseID?: number;
}) => {
    switch (section) {
        case UserOrdersSection.PAYMENTS:
            return <PaymentsSheet />;
        case UserOrdersSection.PAYMENT_ORDERS:
            return <PaymentOrdersSheet />;
        case UserOrdersSection.ORDERS:
            if (productPurchaseID) {
                return (
                    <ProductConsumption
                        platform={AppType.WEB_APP}
                        productPurchaseID={productPurchaseID}
                    />
                );
            }
            return <OrdersSheet />;
        case UserOrdersSection.BILLING:
            return <BillingSheet />;
        case UserOrdersSection.Addresses:
            return <AddressesSheet />;
        case UserOrdersSection.BUYING:
            return <BuyingOrdersSheet />;
        case UserOrdersSection.SELLING:
            return <SellingOrdersSheet />;
        default:
            return null;
    }
};

const UserOrders = ({ productPurchaseID }: { productPurchaseID?: number }) => {
    const section = useOrdersSectionParam();

    return (
        <Flex fillContainer>
            <Menu section={section} />
            <Sheet section={section} productPurchaseID={productPurchaseID} />
        </Flex>
    );
};

export default UserOrders;
