import type { PageCollection } from 'strat/rendering/types';
import CaptchaPage from 'strat/pages/captchaPage';
import LoginPage from 'strat/pages/loginPage';
import LoginCallbackPage from 'strat/pages/loginCallbackPage';

import Page from './page';
import HomePageDesktop from './homePageDesktop';
import SearchPageDesktop from './searchPageDesktop';
import ChatPage from './chatPageDesktop';
import ComponentsPage from './componentsPage';
import NotFoundPageDesktop from './notFoundPageDesktop';
import InternalErrorPage from './internalErrorPage';
import CreateAdPageDesktop from './createAdPageDesktop';
import PostAdSuccessPageDesktop from './postAdSuccessPageDesktop';
import AdUndertakingSuccessPageDesktop from './adUndertakingSuccessPageDesktop';
import UpdateAdPageDekstop from './updateAdPageDesktop';
import AdDetailsPageDesktop from './adDetailsPageDesktop';
import AdManagementPageDesktop from './adManagementPageDesktop';
import ApplyAdLimitPackagePageDesktop from './applyAdLimitPackagePageDesktop';
import SellerPageDesktop from './sellerPageDesktop';
import AgencyPageDesktop from './agencyPageDesktop';
import SitemapPageDesktop from './sitemapPageDesktop';
import PaymentSelectionDesktop from './paymentSelectionDesktop';
import PaymentSelectionFreeAdUndertakingDesktop from './paymentSelectionFreeAdUndertakingDesktop';
import PaymentBankTransferPage from './paymentBankTransferPage';
import UserSettingsPageDesktop from './userSettingsPageDesktop';
import EditProfilePageDesktop from './editProfilePageDesktop';
import PaymentSuccessPage from './paymentSuccessPageDesktop';
import PaymentProcessingPage from './paymentProcessingPageDesktop';
import SelectSinglePackagePage from './selectSinglePackagePage';
import PaymentFawryPage from './paymentFawryPage';
import BusinessPackagesPage from './businessPackagesPage';
import SelectMultiplePackagesPageDesktop from './selectMultiplePackagesPageDesktop';
import ViewBusinessPackageCartPage from './viewBusinessPackageCartPage';
import PackagesNotFoundPage from './packagesNotFoundPage';
import UserOrdersPageDesktop from './userOrdersPageDesktop';
import SelectPackagePage from './selectPackagePage';
import SelectPackageSuccessPage from './selectPackageSuccessPage';
import ApplyAdSinglePackageErrorPage from './applyAdSinglePackageErrorPage';
import PaymentAreebaPage from './paymentAreebaPage';
import LinkIdentityProviderPage from './linkIdentityProviderPage';
import PaymentJazzCashMWalletPageDesktop from './paymentJazzCashMWalletPageDesktop';
import PaymentPaymobMWalletPage from './paymentPaymobMWalletPage';
import PaymentTPayPage from './paymentTPayPage';
import PaymentTPayOTPPage from './paymentTPayOTPPage';
import RequestDeliveryPage from './requestDeliveryPage';
import MissingInfoErrorPage from './missingInfoErrorPage';
import VerticalProperties from './verticalPropertiesDesktop';
import VerticalCars from './verticalCarsDesktop';
import CashMyCarSuccessPage from './cashMyCarSuccessPageDesktop';
import ProviderCashPaymentPage from './providerCashPaymentPage';
import TeleSalesTool from './teleSalesTool';
import PackagePurchaseError from './packagePurchaseError';
import FavouritesAndSavedSearchesPageDesktop from './favouritesAndSavedSearchesPageDesktop';
import SaveCardPage from './saveCardPage';
import walletPageDesktop from './walletPageDesktop';
import LoadingTransitionPage from './loadingTransitionPage';
import ChatWidgetPage from './chatWidgetPage';

const desktopPages: PageCollection = {
    [Page.HOME]: HomePageDesktop,
    [Page.COMPONENTS]: ComponentsPage,
    [Page.SEARCH]: SearchPageDesktop,
    [Page.AD_DETAILS]: AdDetailsPageDesktop,
    [Page.AD_MANAGEMENT]: AdManagementPageDesktop,
    [Page.APPLY_AD_LIMIT_PACKAGE]: ApplyAdLimitPackagePageDesktop,
    [Page.FAVORITE_ADS]: FavouritesAndSavedSearchesPageDesktop,
    [Page.SAVED_SEARCHES]: FavouritesAndSavedSearchesPageDesktop,
    [Page.NOT_FOUND]: NotFoundPageDesktop,
    [Page.CREATE_AD]: CreateAdPageDesktop,
    [Page.POST_AD_SUCCESS]: PostAdSuccessPageDesktop,
    [Page.AD_UNDERTAKING_TERMS]: AdUndertakingSuccessPageDesktop,
    [Page.UPDATE_AD]: UpdateAdPageDekstop,
    [Page.INTERNAL_ERROR]: InternalErrorPage,
    [Page.NOT_FOUND]: NotFoundPageDesktop,
    [Page.CHAT]: ChatPage,
    [Page.CHAT_WIDGET]: ChatWidgetPage,
    [Page.SELLER_PROFILE]: SellerPageDesktop,
    [Page.AGENCY_PROFILE]: AgencyPageDesktop,
    [Page.SITEMAP]: SitemapPageDesktop,
    [Page.PAYMENT_SELECTION]: PaymentSelectionDesktop,
    [Page.PAYMENT_SELECTION_FREE_AD_UNDERTAKING]: PaymentSelectionFreeAdUndertakingDesktop,
    [Page.PAYMENT_AREEBA]: PaymentAreebaPage,
    [Page.PAYMENT_SUCCESS]: PaymentSuccessPage,
    [Page.PAYMENT_PROCESSING]: PaymentProcessingPage,
    [Page.PAYMENT_BANK_TRANSFER]: PaymentBankTransferPage,
    [Page.PAYMENT_FAWRY]: PaymentFawryPage,
    [Page.LOGIN]: LoginPage,
    [Page.LOGIN_CALLBACK]: LoginCallbackPage,
    [Page.USER_SETTINGS]: UserSettingsPageDesktop,
    [Page.EDIT_PROFILE]: EditProfilePageDesktop,
    [Page.SELECT_SINGLE_PACKAGE]: SelectSinglePackagePage,
    [Page.APPLY_AD_SINGLE_PACKAGE_ERROR]: ApplyAdSinglePackageErrorPage,
    [Page.MISSING_INFO_ERROR]: MissingInfoErrorPage,
    [Page.BUSINESS_PACKAGE]: BusinessPackagesPage,
    [Page.SELECT_MULTIPLE_PACKAGES]: SelectMultiplePackagesPageDesktop,
    [Page.VIEW_BUSINESS_PACKAGE_CART]: ViewBusinessPackageCartPage,
    [Page.PACKAGES_NOT_FOUND]: PackagesNotFoundPage,
    [Page.USER_ORDERS]: UserOrdersPageDesktop,
    [Page.SELECT_PACKAGE]: SelectPackagePage,
    [Page.SELECT_PACKAGE_SUCCESS]: SelectPackageSuccessPage,
    [Page.LINK_IDENTITY_PROVIDER]: LinkIdentityProviderPage,
    [Page.PAYMENT_JAZZ_CASH_MWALLET]: PaymentJazzCashMWalletPageDesktop,
    [Page.PAYMENT_PAYMOB_MWALLET]: PaymentPaymobMWalletPage,
    [Page.PAYMENT_TPAY]: PaymentTPayPage,
    [Page.PAYMENT_TPAY_OTP]: PaymentTPayOTPPage,
    [Page.CAPTCHA_CHALLENGE]: CaptchaPage,
    [Page.REQUEST_DELIVERY]: RequestDeliveryPage,
    [Page.VERTICAL_PROPERTIES]: VerticalProperties,
    [Page.VERTICAL_CARS]: VerticalCars,
    [Page.CASH_MY_CAR_SUCCESS]: CashMyCarSuccessPage,
    [Page.PROVIDER_CASH_PAYMENT]: ProviderCashPaymentPage,
    [Page.TELE_SALES]: TeleSalesTool,
    [Page.PACKAGE_PURCHASE_ERROR]: PackagePurchaseError,
    [Page.SAVE_CARD]: SaveCardPage,
    [Page.WALLET]: walletPageDesktop,
    [Page.TRANSITION]: LoadingTransitionPage,
};

export default desktopPages;
