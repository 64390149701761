import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectI18n } from 'strat/i18n/language/selectors';
import { SchemaType } from 'strat/schema/types';
import { ThumbnailSizes, ThumbnailSizeValues } from 'strat/image';
import type { PropertyData } from 'strat/property/types';

import MetaData from '../metaData';
import { addContextTag, addTypeTag, addNameTag } from '../tagsGeneral';
import {
    addPropertyURLTag,
    addAlternateNameTag,
    addPropertyDescriptionTag,
    addPropertyImageTag,
    addOfferTags,
    addPriceSpecificationTags,
    addOfferedByTags,
    addPropertyParentOrganizationTag,
} from '../tagsProperty';

type Props = {
    property: PropertyData;
};

const PropertyItemPageMetaData = ({ property }: Props) => {
    const i18n = useSelector(selectI18n);

    // we do not want to generate item page for ads that do not have a price
    if (!property.price) {
        // @ts-expect-error - TS2786 - 'MetaData' cannot be used as a JSX component.
        return <MetaData data={null} />;
    }

    const itemPage: Record<string, any> = {};
    addContextTag(itemPage);
    addTypeTag(itemPage, [SchemaType.ITEM_PAGE]);

    const mainEntity: Record<string, any> = {};
    addTypeTag(mainEntity, [SchemaType.PRODUCT], property);
    addNameTag(mainEntity, property.title);
    addPropertyURLTag(mainEntity, property, i18n.locale);
    addAlternateNameTag(mainEntity, property, i18n);
    addPropertyDescriptionTag(mainEntity, property);
    addPropertyImageTag(mainEntity, property, ThumbnailSizeValues[ThumbnailSizes.LARGE]);
    addOfferTags(mainEntity, property, i18n.locale);
    addPriceSpecificationTags(mainEntity.offers[0], property);
    addOfferedByTags(mainEntity.offers[0], property);
    if (mainEntity.offers[0].offeredBy) {
        // only add this tag if off offeredBy tag exists, as it is a subtag of it
        addPropertyParentOrganizationTag(mainEntity.offers[0].offeredBy, property, i18n.locale);
    }

    itemPage.mainEntity = mainEntity;

    // @ts-expect-error - TS2786 - 'MetaData' cannot be used as a JSX component.
    return <MetaData data={itemPage} />;
};

export default PropertyItemPageMetaData;
