import * as React from 'react';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';

import { Field } from 'horizontal/fields';
import type { FlatCategoryField } from 'horizontal/types';
import { CategoryFieldValueType } from 'horizontal/types';

import PriceField from '../priceField';
import { PRICE_FIELDS } from '../postingConstants';

import { SetFieldValue } from './types';

export type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: SetFieldValue;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly onBlur: (arg1: React.SyntheticEvent<any>) => void;
};

const renderFields = (fields: FlatCategoryField[], otherProps: Props) =>
    fields.map((field) =>
        PRICE_FIELDS.includes(field.attribute) &&
        [CategoryFieldValueType.FLOAT, CategoryFieldValueType.INTEGER].includes(field.valueType) ? (
            <PriceField key={field.id} field={field} {...otherProps} />
        ) : (
            <Field key={field.id} field={field} {...otherProps} />
        ),
    );

export default renderFields;
