import isNil from 'lodash/isNil';
import { Filter } from '@sector-labs/fe-search-redux/filters';

import type { Geoloc } from 'strat/map/types';
import { mockedI18n } from 'strat/i18n/language';
import FilterValues from 'strat/search/filterValues';
import { LocationNodeData } from 'strat/property/types';

export const isInRange = (range: any, value: number) => {
    if (range.min && value < range.min) {
        return false;
    }

    if (range.max && value > range.max) {
        return false;
    }

    return true;
};

export const isContained = (options: any, value: number): boolean => {
    if (isNil(value) || !options) {
        return true;
    }
    if (options.includes(value)) {
        return true;
    }

    const greaterThanFilter = options.find(
        (option: any) => option.endsWith && option.endsWith('+'),
    );
    if (greaterThanFilter) {
        const greaterThanValue = parseInt(greaterThanFilter, 10);
        if (value >= greaterThanValue) {
            return true;
        }
    }
    return false;
};

export const isInBounds = (
    bounds: Array<Geoloc> | ReadonlyArray<Geoloc>,
    longitude: number,
    latitude: number,
) => {
    if (longitude < bounds[0].lng) {
        return false;
    }

    if (longitude > bounds[1].lng) {
        return false;
    }

    if (latitude < bounds[0].lat) {
        return false;
    }

    if (latitude > bounds[1].lat) {
        return false;
    }

    return true;
};

export const isInSelectedLocation = (
    locationFilter: any,
    locationHierarchy: Array<number | string>,
    useExternalID = false,
): boolean => {
    if (!locationHierarchy || !Array.isArray(locationHierarchy)) {
        return false;
    }

    if (!locationFilter) {
        return true;
    }

    return locationFilter.some((location: LocationNodeData) =>
        locationHierarchy.includes(useExternalID ? location.externalID : location.id),
    );
};

const studioCategories = [
    ((FilterValues.category.find(mockedI18n, 'apartments') || {}).externalID || '').toString(),
    (
        (FilterValues.category.find(mockedI18n, 'hotel-apartments') || {}).externalID || ''
    ).toString(),
];

export const isStudio = (categoryExternalID: string | number): boolean => {
    return studioCategories.includes(categoryExternalID.toString());
};

export const isRangeFilterMatched = (filter: Filter<any> | null, value: any) => {
    // If the filter is not active, any value is valid
    if (!filter?.active) {
        return true;
    }

    return value && isInRange(filter.value, value);
};
