import { AdPaymentPlanSummary } from 'strat/property/types';
import type { AnyAdData } from 'strat/types';
import type { GlobalState } from 'strat/state';
import EMPTY_ARRAY from 'strat/empty/array';

import type { ProjectData, ProjectPaymentPlan } from './types';
import { isProject } from './conditions';

const selectProjectPaymentPlans = (project: ProjectData): readonly ProjectPaymentPlan[] => {
    return project.paymentPlans || EMPTY_ARRAY;
};

const selectProjectFirstPaymentPlan = (project: ProjectData): ProjectPaymentPlan | null => {
    return project.paymentPlans?.[0] || null;
};

const selectProjectPaymentPlanSummaries = (project: AnyAdData): readonly AdPaymentPlanSummary[] => {
    if (!isProject(project)) {
        return EMPTY_ARRAY;
    }

    return project.paymentPlanSummaries || EMPTY_ARRAY;
};

const selectProjectFirstPaymentPlanSummary = (ad: AnyAdData): AdPaymentPlanSummary | null => {
    return ad.paymentPlanSummaries?.[0] || null;
};

const selectProjects = (state: GlobalState) => state.projects.data || EMPTY_ARRAY;

const selectProjectLocationSlugFilter = (state: GlobalState): string | null =>
    state.projects.locationSlugFilter;

const selectChildProjectCount = (state: GlobalState): number =>
    state.projects.childProjectCount || 0;

const isDescriptionExpanded = (state: GlobalState) => state.projects.descriptionExpanded;

export {
    selectProjectPaymentPlans,
    selectProjectFirstPaymentPlan,
    selectProjectPaymentPlanSummaries,
    selectProjectFirstPaymentPlanSummary,
    selectProjects,
    selectProjectLocationSlugFilter,
    selectChildProjectCount,
    isDescriptionExpanded,
};
