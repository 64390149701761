import React from 'react';

import type { PaymentOrder } from 'horizontal/payment';
import PaymentJazzCashMWallet from 'horizontal/payment/jazzCash';
import JazzCashFormTitle from '@app/payment/jazzCash/jazzCashFormTitle';
import JazzCashExplanatoryNotes from '@app/payment/jazzCash/jazzCashExplanatoryNotes';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly order: PaymentOrder;
    readonly fromFreeAdUndertaking?: boolean;
};

const PaymentJazzCashMWalletPageDesktop = {
    head: () => (
        <>
            <PrivateNonIndexableHead />
        </>
    ),
    body: ({ order, fromFreeAdUndertaking }: Props) => (
        <PaymentJazzCashMWallet
            order={order}
            fromFreeAdUndertaking={fromFreeAdUndertaking}
            title={<JazzCashFormTitle />}
            explanatoryNotes={<JazzCashExplanatoryNotes />}
        />
    ),
} as const;

export default PaymentJazzCashMWalletPageDesktop;
