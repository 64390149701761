import * as React from 'react';
import { Flex } from 'strat/components';
import { Trans } from '@lingui/macro';

import AccordionContent from 'horizontal/userWallet/accordionContent';

import styles from './styles/learnMore.cssm';

const LearnMore = () => {
    return (
        <Flex stretchWidth column>
            <Flex stretchWidth column className={styles.container}>
                <Flex column className={styles.header}>
                    <span className={styles.title}>
                        <Trans>Learn More</Trans>
                    </span>
                    <span className={styles.description}>
                        <Trans>
                            Everything you need to know about
                            <b> dubizzle </b>
                            Wallet and how it works.
                        </Trans>
                    </span>
                </Flex>
                <AccordionContent />
            </Flex>
        </Flex>
    );
};

export default LearnMore;
