//@ts-nocheck
/**
 * Available Bundle actions.
 */
const Actions = Object.freeze({
    SET_BUNDLE: 'BUNDLE/SET',
});

/**
 * Default state for the Bundle.
 */
const defaultState = 'main';

/**
 * Reducer for the Bundle.
 */
const bundleReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET_BUNDLE:
            return action.bundle;

        default:
            return state;
    }
};

/**
 * Sets the active Bundle.
 */
const setBundle = (bundle: any) => ({
    type: Actions.SET_BUNDLE,
    bundle,
});

export { setBundle };

export default bundleReducer;
