import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { SearchListingType } from 'strat/search/types';
import { useI18n } from 'strat/i18n/language';
import { Button } from 'strat/components';
import {
    selectIsProjectSearchEnabled,
    selectIsProjectSearchAvailable,
} from 'strat/search/selectors';
import ListingSearchIcon from '@app/assets/icons/listingSearchIcon.svg';
import ProjectSearchIcon from '@app/assets/icons/projectSearchIcon.svg';
import useSwitchListingTypeSearch from 'strat/search/useSwitchListingTypeSearch';
import { GTMSearchTrackingProps, withGTMSearchTracking } from 'strat/gtm';
import { ProjectSearchType } from 'strat/search/types';

import styles from './styles/projectSearchSwitcher.cssm';

type Props = GTMSearchTrackingProps & {
    onClick?: () => void;
    withDebounce?: boolean;
    checkAvailability?: boolean;
};

const ProjectSearchSwitcher = ({
    onClick,
    withDebounce,
    checkAvailability = true,
    trackSearchProjectChangeView,
    trackProjectPageView,
}: Props) => {
    const isProjectSearchEnabled = useSelector(selectIsProjectSearchEnabled);
    const isProjectSearchAvailable = useSelector(selectIsProjectSearchAvailable);
    const switchListingTypeSearch = useSwitchListingTypeSearch();

    React.useEffect(() => {
        if (isProjectSearchEnabled && isProjectSearchAvailable) {
            trackProjectPageView(ProjectSearchType.PROJECT_VIEW);
        }
    }, [trackProjectPageView, isProjectSearchEnabled, isProjectSearchAvailable]);

    const i18n = useI18n();

    const text = isProjectSearchEnabled
        ? t(i18n)`Switch to Listings View`
        : t(i18n)`Switch to Projects View`;

    const renderIcon = React.useCallback(
        (className: string) => {
            const Icon = isProjectSearchEnabled ? ListingSearchIcon : ProjectSearchIcon;
            return <Icon className={classNames(className, styles.icon)} />;
        },
        [isProjectSearchEnabled],
    );

    const onClickCallback = () => {
        onClick?.();
        switchListingTypeSearch();
        trackSearchProjectChangeView(
            isProjectSearchEnabled ? SearchListingType.LISTING : SearchListingType.PROJECT,
        );
    };

    if (checkAvailability && !isProjectSearchAvailable) {
        return null;
    }

    return (
        <Button
            small
            renderIcon={renderIcon}
            onClick={withDebounce ? debounce(onClickCallback, 500) : onClickCallback}
        >
            <div className={styles.text}>{text}</div>
        </Button>
    );
};

export default withGTMSearchTracking(ProjectSearchSwitcher);
