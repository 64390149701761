import { useSelector } from 'react-redux';

import type { GlobalState } from 'horizontal/state';

import { selectAgencyProfile, selectIsAgencyProfileLoading } from './selectors';

const useAgencyProfile = () => useSelector((state: GlobalState) => selectAgencyProfile(state));

const useAgencyIsLoading = () =>
    useSelector((state: GlobalState) => selectIsAgencyProfileLoading(state));

export { useAgencyProfile, useAgencyIsLoading };
