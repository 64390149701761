import * as React from 'react';

import * as Text from './text';
import styles from './styles/pill.cssm';
import Flex from './flex';

type Props = {
    readonly className?: string;
    readonly children: React.ReactNode;
    readonly icon?: string;
};

type PillVariantProps = {
    children: React.ReactNode;
};

const Pill = ({ className, children, icon }: Props) => (
    <Flex alignCenter justifyCenter className={className || styles.pill} aria-label={'Ad state'}>
        {icon && <img src={icon} alt={'Ad state Icon'} />}
        <Text.Small bold>{children}</Text.Small>
    </Flex>
);

const Featured = ({ children }: PillVariantProps) => (
    <Pill className={styles.featured}>{children}</Pill>
);

const Error = ({ children }: PillVariantProps) => <Pill className={styles.error}>{children}</Pill>;

const Accentuated = ({ children }: PillVariantProps) => (
    <Pill className={styles.accentuated}>{children}</Pill>
);

const Disabled = ({ children }: PillVariantProps) => (
    <Pill className={styles.disabled}>{children}</Pill>
);

const Elite = ({ children }: PillVariantProps) => <Pill className={styles.elite}>{children}</Pill>;

const Boosted = ({ children }: PillVariantProps) => (
    <Pill className={styles.boosted}>{children}</Pill>
);

const Accepted = ({ children }: PillVariantProps) => (
    <Pill className={styles.accepted}>{children}</Pill>
);

const Finished = ({ children }: PillVariantProps) => (
    <Pill className={styles.finished}>{children}</Pill>
);

const Recent = ({ children }: PillVariantProps) => (
    <Pill className={styles.recent}>{children}</Pill>
);

export {
    Pill as Regular,
    Error,
    Accentuated,
    Featured,
    Disabled,
    Elite,
    Boosted,
    Accepted,
    Finished,
    Recent,
};
