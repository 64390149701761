import React from 'react';

import AdUpdatingForm from 'horizontal/adUpdating';
import { UpdateAdAction } from 'horizontal/adManagement/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly action?: Values<typeof UpdateAdAction>;
};

const CreateAdPageDesktop = {
    head: () => <PrivateNonIndexableHead />,
    body: ({ action }: Props) => <AdUpdatingForm action={action} />,
} as const;

export default CreateAdPageDesktop;
