import { t } from '@lingui/macro';
import * as React from 'react';
import { Flex } from 'strat/components';
import useI18n from 'strat/i18n/language/useI18n';

import { ProductPackageOffer } from 'horizontal/types';

import useAvailableBusinessPackageOffers from './hooks/useAvailableBusinessPackageOffers';
import styles from './styles/telesalesTool.cssm';
import TeleSalesTable from './teleSalesTable';
import BusinessPackagesFilter from './packagesFilter';

type Props = Readonly<{
    selectedPackageOfferID: string | undefined;
    onPackageOfferSelectionChange: (packageOffer: ProductPackageOffer | undefined) => unknown;
    onError: (error: string | undefined) => unknown;
}>;

const BusinessPackageOffersCard = ({
    selectedPackageOfferID,
    onPackageOfferSelectionChange,
    onError,
}: Props) => {
    const i18n = useI18n();
    const [loading, setLoading] = React.useState(false);
    const [categoryExternalID, setCategoryExternalID] = React.useState<string | undefined>();

    const { packageOffers, fetchPackageOffers } = useAvailableBusinessPackageOffers(
        setLoading,
        onError,
    );

    const handleCategorySelectionChange = React.useCallback(
        (categoryExternalID: string | undefined) => {
            setCategoryExternalID(categoryExternalID || undefined);
        },
        [],
    );

    const handlePackageOfferSelectionChange = React.useCallback(
        (packageOfferID: string | undefined) => {
            let selectedPackageOffer: ProductPackageOffer | undefined;

            if (packageOfferID) {
                selectedPackageOffer = packageOffers.find(
                    (packageOffer) => packageOffer.packageOfferID == packageOfferID,
                );
            }

            onPackageOfferSelectionChange(selectedPackageOffer);
        },
        [packageOffers, onPackageOfferSelectionChange],
    );

    React.useEffect(() => {
        fetchPackageOffers(categoryExternalID);
    }, [categoryExternalID, fetchPackageOffers]);

    React.useEffect(() => {
        handlePackageOfferSelectionChange(undefined);
    }, [packageOffers, handlePackageOfferSelectionChange]);

    return (
        <>
            <BusinessPackagesFilter setCategoryID={handleCategorySelectionChange} />

            {categoryExternalID && (
                <Flex column className={styles.cardElement}>
                    <TeleSalesTable
                        loading={loading}
                        setSelectedItem={handlePackageOfferSelectionChange}
                        items={packageOffers}
                        headers={[
                            {
                                text: t(i18n)`Discount price`,
                                value: (packageOffer: ProductPackageOffer) =>
                                    packageOffer.discountPrice ?? packageOffer.price,
                            },
                            {
                                text: t(i18n)`Package Label`,
                                value: (packageOffer: ProductPackageOffer) =>
                                    packageOffer.label || packageOffer.productPackage?.name,
                            },
                            { text: t(i18n)`Category`, value: 'categoryLabel' },
                            { text: t(i18n)`Regular price`, value: 'price' },
                            {
                                text: t(i18n)`Pricing code`,
                                value: (offer: ProductPackageOffer) =>
                                    offer?.adPattern?.externalID || '',
                            },
                        ]}
                        itemKey="packageOfferID"
                        radioInputName="package"
                        tableTitle={t(i18n)`Choose the correct package`}
                        emptyTableMessage={t(i18n)`There are no applicable packages`}
                        selectedItem={selectedPackageOfferID}
                    />
                </Flex>
            )}
        </>
    );
};

export default React.memo(BusinessPackageOffersCard);
