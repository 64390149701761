import Loadable, { LoadingSpinner } from 'strat/loadable';

const ListingMoreInfoTooltip = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'listingMoreInfoTooltip' */
            './listingMoreInfoTooltip'
        ),
    loading: LoadingSpinner,
});

export default ListingMoreInfoTooltip;
