import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app/app';

import Page from 'horizontal/agencyPortal/pages/page';

import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';
import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';

class AgencyPortalHelpRoute extends Route {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_HELP, [['^/agencyPortal/help$']]);
    }

    createURL(): EnhancedLocation {
        return { pathname: '/agencyPortal/help' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context) && !ensureHasAccessToStratCredits(context)) {
            return;
        }
        context.rendering.renderPage(Page.AGENCY_PORTAL_HELP);
    }
}

export default new AgencyPortalHelpRoute();
