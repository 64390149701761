import type { SavedSearchData } from '../types';

/**
 * Available actions for saved searches.
 */
const Actions = Object.freeze({
    SET_SAVED_SEARCHES: 'SEARCH/SET_SAVED_SEARCHES' as const,
    ADD_SAVED_SEARCH: 'SEARCH/ADD_SAVED_SEARCH' as const,
    REMOVE_SAVED_SEARCH: 'SEARCH/REMOVE_SAVED_SEARCH' as const,
});

/**
 * Type for the {@see Actions.SET_SAVED_SEARCHES}.
 */
export type SetSavedSearchesAction = {
    type: 'SEARCH/SET_SAVED_SEARCHES';
    savedSearches: Array<SavedSearchData>;
};

/**
 * Type for the {@see Actions.ADD_SAVED_SEARCH}.
 */
export type AddSavedSearchAction = {
    type: 'SEARCH/ADD_SAVED_SEARCH';
    search: SavedSearchData;
};

/**
 * Type for the {@see Actions.REMOVE_SAVED_SEARCH}.
 */
export type RemoveSavedSearchAction = {
    type: 'SEARCH/REMOVE_SAVED_SEARCH';
    id: string;
};

/**
 * Union type for all available Saved Searches related actions.
 */
export type SavedSearchesAction =
    | SetSavedSearchesAction
    | AddSavedSearchAction
    | RemoveSavedSearchAction;
export default Actions;
