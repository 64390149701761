import * as React from 'react';
import classNames from 'classnames';

import type { ChoiceValue } from './types';
import RadioGroup from './radioGroup';
import styles from './styles/circleRadioButtonsGroup.cssm';
import * as Text from './text';
import Flex from './flex';
import type { RenderChoiceProps } from './options';
import type { RadioGroupProps } from './radioGroup';

const renderChoice = <T extends ChoiceValue>({
    option,
    isSelected,
    onChange,
    ariaLabel = 'Choice',
}: RenderChoiceProps<T>) => {
    const { renderBody } = option;
    return (
        <Flex key={option.id} column onClick={() => onChange(option.value)} aria-label={ariaLabel}>
            <Flex className={styles.item}>
                <div
                    className={classNames(styles.radioButton, {
                        [styles.selectedIcon]: isSelected(option.value),
                    })}
                />
                <Text.Regular
                    className={classNames(styles.label, {
                        [styles.selectedLabel]: isSelected(option.value),
                    })}
                >
                    {option.label}
                </Text.Regular>
            </Flex>
            {renderBody ? renderBody({ isSelected: isSelected(option.value) }) : null}
        </Flex>
    );
};

const CircleRadioButtonsGroup = <T extends ChoiceValue>(props: RadioGroupProps<T>) => (
    <RadioGroup {...props} renderChoice={renderChoice} />
);

export default CircleRadioButtonsGroup;
