import * as React from 'react';
import useI18n from 'strat/i18n/language/useI18n';

import { StratAPI } from 'horizontal/api';
import { AdState, FullAd } from 'horizontal/types';
import { useMakeCancelable } from 'horizontal/util';

const useFetchUserAds = (
    setLoading: (loading: boolean) => unknown,
    setError: (error: string | undefined) => unknown,
) => {
    const i18n = useI18n();
    const makeCancelable = useMakeCancelable();

    const [userAds, setUserAds] = React.useState<FullAd[]>([]);

    const fetchUserAds = React.useCallback(
        (userExternalID: string | undefined): void => {
            setUserAds([]);

            if (!userExternalID) {
                return;
            }

            setLoading(true);
            setError(undefined);

            makeCancelable(
                new StratAPI(i18n.locale)
                    .userAds(userExternalID, {
                        states: [AdState.LIMITED, AdState.ACTIVE],
                        page_size: 100,
                    })
                    .then((response) => {
                        if (response.status >= 400) {
                            throw new Error(`Unexpected response status: ${response.status}`);
                        }

                        setUserAds(response.data.results);
                    })
                    .catch(() => {
                        setError("Couldn't fetch user's ads");
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            );
        },
        [setLoading, setError, makeCancelable, i18n.locale],
    );

    return { fetchUserAds, userAds };
};

export default useFetchUserAds;
