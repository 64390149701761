import * as React from 'react';
import { useSelector } from 'react-redux';
import Logo from '@app/branding/logo';
import { Header } from 'strat/components';
import { selectIsMobileLayout } from 'strat/layout/selectors';

import CompactHeader from 'horizontal/header/compact';
import { useShouldHidePaymentHeader } from 'horizontal/payment/hooks';

import styles from './styles/paymentSuccessHeader.cssm';

const PaymentSuccessHeader = () => {
    const shouldHidePaymentHeader = useShouldHidePaymentHeader();
    const isMobile = useSelector(selectIsMobileLayout);

    return React.useMemo(() => {
        if (shouldHidePaymentHeader && isMobile) {
            return null;
        }
        return isMobile ? (
            <CompactHeader />
        ) : (
            <Header className={styles.header}>
                <Logo className={styles.logo} />
            </Header>
        );
    }, [isMobile, shouldHidePaymentHeader]);
};

export default PaymentSuccessHeader;
