import React from 'react';

import { LoadingSpinner } from 'strat/loadable';
import type { PropertyVideoData } from 'strat/property';

import ThumbnailImage from './thumbnailImage';
import { ThumbnailSizes } from './thumbnailSizes';
import VideoHostAdapter from './videoHostAdapter';
import styles from './styles/videoThumbnailImage.cssm';

type Props = {
    coverPhotoID?: string;
    video: PropertyVideoData;
    className?: string;
};

const VideoThumbnailImage = ({ coverPhotoID, video, className = styles.thumbnailImage }: Props) => {
    const thumbnailURL = !coverPhotoID
        ? (
              id: number | string,
              encoding: string,
              size: {
                  height: number;
                  width: number;
              },
          ) => VideoHostAdapter.videoThumbnailURL(video.host, video.url, encoding, size)
        : null;

    return (
        <ThumbnailImage
            imageID={coverPhotoID || video.id}
            className={className}
            // @ts-expect-error - TS2322 - Type '((id: string | number, encoding: string, size: { height: number; width: number; }) => string) | null' is not assignable to type '((imageID: string | number, encoding: string, size: { width: number; height: number; }) => string | null | undefined) | undefined'.
            thumbnailURL={thumbnailURL}
            size={ThumbnailSizes.LARGE}
            loadingIndicator={<LoadingSpinner />}
        />
    );
};

export default VideoThumbnailImage;
