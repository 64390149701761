import { t } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';

import { RouteParameters } from 'react-true-router/route';
import { useI18n } from 'strat/i18n/language';
import { Link } from 'strat/navigation';
import { Image } from 'strat/util';
import stratMapViewToggle from 'strat/assets/images/stratMapViewToggle.png';
import stratMapViewToggleWEBP from 'strat/assets/images/stratMapViewToggle.webp';
import satelliteMapViewToggle from 'strat/assets/images/satelliteMapViewToggle.png';
import satelliteMapViewToggleWEBP from 'strat/assets/images/satelliteViewToggle.webp';

import { MapViewName } from './mapStyles';
import styles from './styles/mapViewToggle.cssm';

type ContainerProps = {
    usesGeoapify?: boolean;
    route?: string;
    routeParams?: RouteParameters;
    onClick?: (e: React.SyntheticEvent) => void;
    children?: React.ReactNode;
};

type Props = ContainerProps & {
    mapView: Values<typeof MapViewName>;
};

const Container = ({ usesGeoapify, route, routeParams, onClick, children }: ContainerProps) => {
    if (route && routeParams) {
        return (
            <Link
                hard
                className={classNames(styles.container, {
                    [styles.containerLower]: usesGeoapify,
                })}
                route={route}
                params={routeParams}
                onClick={onClick}
            >
                {children}
            </Link>
        );
    }
    return (
        <div
            className={classNames(styles.container, {
                [styles.containerLower]: usesGeoapify,
            })}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const MapViewToggle = ({ mapView, route, routeParams, onClick, usesGeoapify = false }: Props) => {
    const i18n = useI18n();
    const defaultMap = {
        image: {
            source: stratMapViewToggle,
            sourceWEBP: stratMapViewToggleWEBP,
        },
        text: t(i18n)`Default`,
    };
    const satelliteMap = {
        image: {
            source: satelliteMapViewToggle,
            sourceWEBP: satelliteMapViewToggleWEBP,
        },
        text: t(i18n)`Satellite`,
    };
    const map = mapView === MapViewName.SATELLITE ? satelliteMap : defaultMap;

    return (
        <Container
            route={route}
            routeParams={routeParams}
            onClick={onClick}
            usesGeoapify={usesGeoapify}
        >
            <Image image={map.image} alt="Map style toggle" imgClassName={styles.image} />
            <div className={styles.text}>{map.text}</div>
            <div
                className={classNames(styles.containerOverlay, {
                    [styles.containerOverlaySatellite]: mapView === MapViewName.SATELLITE,
                    [styles.containerOverlayDefault]: mapView === MapViewName.DEFAULT,
                })}
            />
        </Container>
    );
};

export default MapViewToggle;
