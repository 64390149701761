import pick from 'lodash/pick';

import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';
import Page from 'strat/pages/page';
import RouteNames from 'strat/routes/routeNames';

type RouteParams = {
    readonly externalRedirectPath?: string;
};

class CaptchaRoute extends Route {
    constructor() {
        super(RouteNames.CAPTCHA_CHALLENGE, [['^/captchaChallenge(?:(?:\\?.*)?(?:#.*)?)?$']]);
    }

    createURL(params: RouteParams): EnhancedLocation {
        const search = pick(params, ['externalRedirectPath']);
        return { pathname: '/captchaChallenge', search };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const externalRedirectPath = context.match.params.externalRedirectPath || '/';
        context.rendering.renderPage(Page.CAPTCHA_CHALLENGE, { externalRedirectPath });
    }
}

export default new CaptchaRoute();
