import { getCategoryVariables, joinList, getSellerType } from 'strat/gtm';

import type { Location, LiteHierarchicalLocation, Category, Ad } from 'horizontal/types';
import { findDeepList } from 'horizontal/util/findDeep';

const getLocationID = (location: Location | LiteHierarchicalLocation) => location.externalID;

const MAX_LOCATION_DEPTH = 4;

/**
 * Compute hierarchy data for an array of locations. Last entry in array is the current location.
 * First entry in array is the country. Also, on each call of this function we clear the locations
 * of the previous call, because GTM caches the entries if they are not set again!
 */
const getLocationHierarchyData = (locations: LiteHierarchicalLocation[]) => {
    return locations
        .filter((location) => location.level > 0)
        .concat(Array.from({ length: MAX_LOCATION_DEPTH - (locations.length - 1) }))
        .reduce(
            (acc, currentLocation, index) => {
                if (currentLocation) {
                    acc[`loc_${index + 1}_id`] = getLocationID(currentLocation);
                    acc[`loc_${index + 1}_name`] = currentLocation.name;
                } else {
                    acc[`loc_${index + 1}_id`] = undefined;
                    acc[`loc_${index + 1}_name`] = undefined;
                }

                return acc;
            },
            {} as Record<string, string | number | undefined>,
        );
};

const getLocationVars = (locations: LiteHierarchicalLocation[]) => {
    const mainLocation = locations.slice(-1)[0] || null;

    if (!mainLocation) {
        return {};
    }

    const breadcrumb = locations.map(getLocationID);
    const hierarchyData = getLocationHierarchyData(locations);

    return {
        loc_id: getLocationID(mainLocation),
        loc_name: mainLocation.name,
        loc_breadcrumb: joinList(breadcrumb),
        ...hierarchyData,
    };
};

const getDeepCategoryVars = (
    categories?: Array<Category> | null,
    activeCategoryID?: string | null,
) => {
    if (!categories || !activeCategoryID) {
        return {};
    }
    const relevantCategories = findDeepList(
        categories,
        (category) => category.externalID === activeCategoryID,
        true,
    );
    // @ts-expect-error - TS2345 - Argument of type 'Omit<never, "children">[]' is not assignable to parameter of type 'CategoryNodeData[]'.
    return getCategoryVariables(relevantCategories);
};

const getCompanyId = (ad: any) => {
    return ad?.agency ? parseInt(ad.agency.externalID, 10) : null;
};

const getAdVars = (ad?: Ad | null): { [key: string]: any } => {
    if (!ad) {
        return {};
    }

    const adVars: { [key: string]: any } = {
        ...ad.extraFields,
        // @ts-expect-error - TS2345 - Argument of type 'LiteCategory[]' is not assignable to parameter of type 'CategoryNodeData[]'.
        ...getCategoryVariables(ad.category),
        ...getLocationVars(ad.location),
        ad_id: ad.externalID,
        ad_title: ad.title,
        seller_id: ad.userExternalID,
        // eslint-disable-next-line camelcase
        ad_condition: ad.extraFields?.new_used,
        package_type: ad.product,
        // @ts-expect-error - TS2345 - Argument of type 'Ad' is not assignable to parameter of type 'PropertyData'.
        seller_type: getSellerType(ad),
        company_ids: getCompanyId(ad),
    };
    if (ad?.agents) {
        adVars['agent_id'] = ad.agents[0].user_external_id;
        adVars['agent_name'] = ad.agents[0].name;
    }
    return adVars;
};

export { getLocationVars, getDeepCategoryVars, getAdVars };
