import type * as SentryExports from '@sentry/core';

export type Sentry = typeof SentryExports;

interface Logger {
    error(context: Record<string, unknown>, message: string): void;
}

export const obtainSentry = (): Promise<Sentry | null> => {
    if (process.env.IS_SERVER && CONFIG.build.SENTRY_DSN) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        return import('@sentry/node') as unknown as Promise<Sentry>;
    }

    if (process.env.IS_BROWSER && CONFIG.build.SENTRY_DSN) {
        return import(
            /* webpackChunkName: 'sentry' */ '@sentry/react'
        ) as unknown as Promise<Sentry>;
    }

    return Promise.resolve(null);
};

export const obtainLogger = (): Promise<Logger> => {
    if (process.env.IS_SERVER) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        return import('strat/logger').then((m) => m.default);
    }

    return Promise.resolve(console);
};
