import * as React from 'react';
import { Flex } from 'strat/components';

import SellingOrdersNavbar from './sellingOrdersNavbar';
import styles from './styles/sellingOrdersSheet.cssm';
import SellingOrdersList from './sellingOrdersList';

function SellingOrdersSheet() {
    return (
        <Flex column stretchWidth>
            <SellingOrdersNavbar className={styles.menu} />
            <Flex column className={styles.ordersList}>
                <SellingOrdersList />
            </Flex>
        </Flex>
    );
}

export default SellingOrdersSheet;
