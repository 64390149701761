import * as React from 'react';
import { Flex } from 'strat/components';

import BuyingOrderStatusNavbar from './buyingOrdersStatusNavbar';
import styles from './styles/buyingOrdersSheet.cssm';
import BuyingOrdersList from './buyingOrdersList';

function ProductPurchasesSheet() {
    return (
        <Flex column stretchWidth>
            <BuyingOrderStatusNavbar className={styles.menu} />
            <Flex column className={styles.ordersList}>
                <BuyingOrdersList />
            </Flex>
        </Flex>
    );
}

export default ProductPurchasesSheet;
