import { useI18n } from 'strat/i18n/language';
import Purpose, { PurposeTextDisplay } from 'strat/purpose';

import type { Category } from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';

const usePurposeAdjective = (
    category: Category | null | undefined,
    role: Values<typeof CategoryRole>,
): string => {
    const i18n = useI18n();

    if (!category || !category.purpose) {
        return '';
    }

    const shouldUsePurpose = category.roles && category.roles.includes(role);
    if (!shouldUsePurpose) {
        return '';
    }

    return Purpose.text(
        i18n,
        category.purpose,
        PurposeTextDisplay.CONSISTENT_PREPOSITION,
    ).toLowerCase();
};

export default usePurposeAdjective;
