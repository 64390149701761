import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import type { PropertyData } from 'strat/property/types';

/**
 * Saves the user current city in local storage so we can be persistent with Laravel.
 */
const saveUserCityInLocalStorage = (cityID: string | number) => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        localStorage.setItem(
            'activity',
            JSON.stringify({
                ...activity,
                last_selected_city: [{ city_id: cityID }],
            }),
        );
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

const saveSearchHistory = (searchHistory: Array<FilterCollection>) => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        localStorage.setItem(
            'activity',
            JSON.stringify({
                ...activity,
                search_history: searchHistory,
            }),
        );
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

const saveRecentlyViewedProperties = (recentlyViewedProperties: Array<PropertyData>) => {
    try {
        const activityItem = localStorage.getItem('activity');
        const activity = activityItem ? JSON.parse(activityItem) : {};

        localStorage.setItem(
            'activity',
            JSON.stringify({
                ...activity,
                recently_viewed: recentlyViewedProperties,
            }),
        );
    } catch (err: any) {
        // eslint-disable-next-line
    }
};

export { saveUserCityInLocalStorage, saveSearchHistory, saveRecentlyViewedProperties };
