import { isCanceled, makeCancelable } from 'strat/util';
import { Route } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import type { EnhancedLocation } from 'react-true-router/location';

import { fetchAd } from 'horizontal/ad/state';
import Page from 'horizontal/pages/page';

import ensureHasActiveUser from './ensureHasActiveUser';
import ensureActiveUserIsAllowedAccessAndRedirect from './ensureActiveUserIsAllowedAccessAndRedirect';

export type BankTransferPaymentRouteParams = {
    readonly adExternalID: string;
    readonly commission: number;
};

class PaymentBankTransferRoute extends Route {
    constructor() {
        super(RouteNames.PAYMENT_BANK_TRANSFER, [
            [
                '^/bankTransfer/',
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '/',
                {
                    name: 'commission',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { adExternalID, commission }: BankTransferPaymentRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/bankTransfer/${adExternalID}/${commission}`,
        };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureHasActiveUser(context) || !ensureActiveUserIsAllowedAccessAndRedirect(context)) {
            return;
        }
        const {
            match: {
                params: { adExternalID, commission },
            },
            redux: {
                store: { dispatch },
            },
        } = context;

        if (!adExternalID) {
            // @ts-expect-error - TS2339 - Property 'renderNotFoundPage' does not exist on type 'PaymentBankTransferRoute'.
            this.renderNotFoundPage(context);
            return;
        }

        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentBankTransferRoute'.
        this.cancelablePromise = makeCancelable(
            Promise.all([dispatch(fetchAd({ externalID: adExternalID }))]),
        );
        // @ts-expect-error - TS2339 - Property 'cancelablePromise' does not exist on type 'PaymentBankTransferRoute'.
        const promise = this.cancelablePromise.then(
            ([{ data: ad, status }]: [any]) => {
                if (status !== 200) {
                    // @ts-expect-error - TS2339 - Property 'renderNotFoundPage' does not exist on type 'PaymentBankTransferRoute'.
                    this.renderNotFoundPage(context);
                    return;
                }
                context.rendering.renderPage(Page.PAYMENT_BANK_TRANSFER, {
                    ad,
                    commission,
                });
            },
            // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
            (error) => {
                if (isCanceled(error)) {
                    return;
                }

                throw error;
            },
        );
        context.promise.wait(promise);
    }
}

export default new PaymentBankTransferRoute();
