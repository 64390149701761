import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import settings from '@app/branding/settings';

const getHomePageTitle = (i18n: I18n) => {
    const countryName = settings.countryName(i18n);
    const brandName = settings.getBrandName(i18n);
    return t(
        i18n,
    )`${brandName} - Buy and Sell for free anywhere in ${countryName} with ${brandName} online classifieds`;
};

export default getHomePageTitle;
