import type { Store } from 'redux';

import { Actions } from 'react-true-router/middlewares/redux';

import TaskScheduler, { ScheduleScope } from './taskScheduler';

/**
 * listen to store events and fire the task scheduler for registered events.
 */
// @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
export default (_store: Store) => (next: Function) => (action: any) => {
    if (action.type === Actions.CHANGE_ROUTE) {
        TaskScheduler.run(ScheduleScope.AFTER_CHANGE_ROUTE, 0, action);
    }

    return next(action);
};
