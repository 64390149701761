import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { getFilterCollection, selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import {
    ExactFilter,
    ExclusionFilter,
    SerializedFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SlotBorrowStrategy } from '@sector-labs/fe-search-redux/slots';
import type { SearchStateSettings } from '@sector-labs/fe-search-redux/state';
import { Slot } from '@sector-labs/fe-search-redux/slots';

import { FilterValues } from 'strat/search';
import { PropertyType } from 'strat/property/types';
import type { PropertyData } from 'strat/property/types';
import type { LocationNodeData } from 'strat/property/types';
import { selectSortValue } from 'strat/search/state/selectors';
import type { GlobalState } from 'strat/state';
import { determineAdsIndexName } from 'strat/search/indexNames';

import { selectLocationHierarchy } from '../selectors';

const createSearchSlots = (locationHierarchy: LocationNodeData[]) => {
    if (!locationHierarchy.length) {
        return [
            {
                filters: [],
                counted: true,
            },
        ];
    }

    const locationFallbackSlots = [];
    for (let i = locationHierarchy.length - 1; i > 0; i--) {
        locationFallbackSlots.push({
            filters: [
                new ExclusionFilter({
                    attribute: `location.externalID`,
                    value: locationHierarchy[i].externalID,
                }).serialize(),
                new ExactFilter({
                    attribute: `location.slug`,
                    value: locationHierarchy[i - 1].slug,
                }).serialize(),
            ] as SerializedFilter[],
            counted: true,
        });
    }

    return locationFallbackSlots;
};

const createSearchSettings = (
    settings: Partial<SearchStateSettings>,
    locationFallbackSlots: Slot[],
    count: number,
) => ({
    ...settings,
    hitsPerPage: count,
    slotRules: {
        activeFor: settings.slotRules?.activeFor || null,
        default: {
            hitsPerSlot: locationFallbackSlots.map((_, index) => (index === 0 ? count : 0)),
            slots: locationFallbackSlots,
            borrowStrategy: SlotBorrowStrategy.FROM_FOLLOWING,
        },
    },
});

const fetchRecommendationAdsByLocationFallback = (
    count: number,
    state: GlobalState,
): Promise<PropertyData[]> => {
    const locationHierarchy = selectLocationHierarchy(state);
    const filters = getFilterCollection(state).subset([
        FilterValues.category.attribute,
        FilterValues.purpose.attribute,
    ]);
    const backend = selectActiveSearchBackend(state) as SearchBackend;
    const searchSettings = state.algolia.settings;
    const sortBy = selectSortValue(state);
    const adIndex = determineAdsIndexName({
        sortBy,
        language: state.i18n.language,
        adType: PropertyType.PROPERTY,
    });

    const locationFallbackSlots = createSearchSlots(locationHierarchy);
    const newSettings = createSearchSettings(searchSettings, locationFallbackSlots, count);
    const job = new SearchJob(adIndex, filters, newSettings);
    const service = new SearchService({ backend });
    return service.fetchJob(job).then((response) => response.hits) as Promise<PropertyData[]>;
};

export default fetchRecommendationAdsByLocationFallback;
