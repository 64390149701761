import {
    ExactFilter,
    FilterCollection,
    ObjectRefinementFilter,
    Operator,
    RefinementFilter,
} from '@sector-labs/fe-search-redux/filters';
import { SearchJob } from '@sector-labs/fe-search-redux';
import type { SearchStateSettings } from '@sector-labs/fe-search-redux/state';

import { FilterValues } from 'strat/search';

export const getProjectsJob = (
    projectIds: string[],
    filters: FilterCollection,
    indexName: string,
    settings: Partial<SearchStateSettings>,
) => {
    const projectFilters = new FilterCollection();
    projectFilters.refine(
        new RefinementFilter({
            attribute: FilterValues.externalID.attribute,
            value: projectIds,
        }),
    );
    projectFilters.refine(
        new ExactFilter({
            attribute: FilterValues.page.attribute,
            value: filters.getFilterValue(FilterValues.page.attribute, 1),
        }),
    );

    const agencyFilterValues = filters.getFilterValue(FilterValues.agency.attribute) as [];
    agencyFilterValues?.forEach((agencyFilterValue) =>
        projectFilters.refine(
            new ObjectRefinementFilter({
                attribute: FilterValues.agency.attribute,
                value: [agencyFilterValue],
                operator: Operator.OR,
            }),
        ),
    );

    return new SearchJob(indexName, projectFilters, settings);
};
