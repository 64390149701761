import * as React from 'react';
import { Trans } from '@lingui/macro';
import { PageWidthWrapper } from 'strat/generic';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Button, Flex } from 'strat/components';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';

import { UserOrdersSection } from 'horizontal/userOrders';
import UpSelling from 'horizontal/adCreation/upSelling';
import { PaymentStatus } from 'horizontal/payment';
import { PackagesFlow } from 'horizontal/packages/types';
import { FullAd, ProductPurchase } from 'horizontal/types';

import PaymentSuccessHeader from './paymentSuccessHeader';
import PaymentSuccessContent from './paymentSuccesContent';
import PaymentSuccessPending from './paymentSuccessPending';
import useHandlePaymentSuccessRedirection from './hooks/useHandlePaymentSuccessRedirection';
import styles from './styles/paymentSuccess.cssm';

type Props = {
    readonly productPackageNames: string;
    readonly packageOffers?: Array<ProductPurchase> | null;
    readonly ad?: FullAd | null;
};

const PaymentSuccess = ({ productPackageNames, packageOffers, ad }: Props) => {
    useHandlePaymentSuccessRedirection({ packageOffers });
    const { adExternalID, status } = useSelector(selectRouteParams);
    const isPaymentPending =
        adExternalID && [PaymentStatus.PENDING, PaymentStatus.PENDING_AT_PROVIDER].includes(status);

    if (isPaymentPending) {
        return <PaymentSuccessPending ad={ad} />;
    }

    return (
        <>
            <PaymentSuccessHeader />
            <PageWidthWrapper>
                {ad && packageOffers && (
                    <UpSelling
                        ad={ad}
                        productPurchases={packageOffers}
                        productOffers={null}
                        hidePromos={true}
                        flow={PackagesFlow.DISCOUNTED_PACKAGES_PURCHASE_FROM_AN_AD}
                        purchasePackageSuccess
                    />
                )}
                {!(ad && packageOffers) && (
                    <>
                        <PaymentSuccessContent productPackageNames={productPackageNames} />
                        <Flex alignCenter column stretchWidth className={styles.buttons}>
                            <Link route={RouteNames.AD_MANAGEMENT} className={styles.link}>
                                <Button stretch>
                                    <Trans>Go to My Ads</Trans>
                                </Button>
                            </Link>

                            <Link
                                route={RouteNames.USER_ORDERS}
                                params={{ section: UserOrdersSection.BILLING }}
                                className={styles.link}
                            >
                                <Button secondary stretch>
                                    <Trans>Add billing information</Trans>
                                </Button>
                            </Link>
                        </Flex>
                    </>
                )}
            </PageWidthWrapper>
        </>
    );
};

export default PaymentSuccess;
