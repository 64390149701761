import FetcherFactory from 'strat/fetcher';
import { AdVirtualStateValues } from '@app/types';
import { selectUserExternalID } from 'strat/user/session/selectors';
import type { GlobalState } from 'strat/state';

import { StratAPI } from 'horizontal/api';
import { DateRange } from 'horizontal/agencyPortal/types';
import { isoDate, TimeOfDay } from 'horizontal/adManagement';

export type ConsumedProductAdsParams = {
    productPurchaseID: string;
    page: string;
    pageSize: string;
    agentExternalID?: string;
    states?: Array<AdVirtualStateValues>;
    createDate?: DateRange;
};

const factory = new FetcherFactory(
    'consumedProductAds',
    (params: ConsumedProductAdsParams, state: GlobalState) => {
        const userExternalID = selectUserExternalID(state);

        if (!userExternalID) {
            throw new Error('Missing user ID when request my ads.');
        }

        return new StratAPI(state.i18n.language).userConsumedProductAds(
            userExternalID,
            // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
            params.productPurchaseID,
            {
                page: params.page,
                page_size: params.pageSize,
                created_at_gte:
                    params.createDate && isoDate(params.createDate?.from, TimeOfDay.START),
                created_at_lte: params.createDate && isoDate(params.createDate?.to, TimeOfDay.END),
                agent_external_id: params.agentExternalID,
                states: params.states,
            },
        );
    },
    {
        catchServerErrors: true,
        skipParamsCheck: true,
    },
);

const fetchConsumedProductAds = factory.creator();
const consumedProductAdsReducer = factory.reducer();

export { fetchConsumedProductAds };

export default consumedProductAdsReducer;
