import * as React from 'react';
import { Flex } from 'strat/components';
import { trigger, Triggers, ViewSections } from 'strat/gtm';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';

import iconChat from 'horizontal/assets/icons/iconChat_noinline.svg';

import styles from './styles/chatLink.cssm';
import UnreadChatsBadgeWrapper from './unreadChatsBadgeWrapper';

const trackChatClick = () =>
    trigger(Triggers.OPEN_CHAT, {
        view_section: ViewSections.HEADER,
    });

const ChatLink = () => (
    <Link route={RouteNames.CHAT} onClick={trackChatClick}>
        <div className={styles.wrapper}>
            <Flex className={styles.container}>
                <img src={iconChat} alt={'Go to chat'} />
            </Flex>
            <UnreadChatsBadgeWrapper />
        </div>
    </Link>
);

export default ChatLink;
