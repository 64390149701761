import * as React from 'react';

import type { LiteAd } from 'horizontal/types';

type Hits = {
    hits: Array<LiteAd>;
};

const useCTAPosition = <T extends Hits>(sectionHits: Array<T>, CTAOffset = 9) => {
    return React.useMemo(() => {
        let position = CTAOffset;
        for (let i = 0; i < sectionHits.length; i++) {
            if (sectionHits[i].hits.length >= position) {
                return [position, i];
            }
            position -= sectionHits[i].hits.length;
        }
        return [0, CTAOffset];
    }, [sectionHits, CTAOffset]);
};

export default useCTAPosition;
