import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { selectGeoLocationData } from 'strat/user/selectors';
import settings from '@app/branding/settings';

import type { LiteHierarchicalLocation } from 'horizontal/types';

const useTitleLocationName = (location?: LiteHierarchicalLocation | null): string => {
    const i18n = useI18n();
    const userLocation = useSelector(selectGeoLocationData);

    return location?.name || userLocation.closestLocation?.name || settings.countryName(i18n);
};

export default useTitleLocationName;
