//@ts-nocheck
import type { PropertyData } from 'strat/property/types';

export const getAgencyAndLocationPurposeTierGTMVars = (property: PropertyData) => {
    const agencyTier = property.agency?.tr || 0;
    const locationPurposeTier = property.locationPurposeTier || 0;

    return {
        _tal: `${agencyTier}${locationPurposeTier}`,
    };
};
