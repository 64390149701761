import { connect } from 'react-redux';

import { setSections, scrollToSection } from './state';

const withScrollNav = connect(
    (state) => ({
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        sections: state.scrollSections,
    }),
    (dispatch) => ({
        // @ts-expect-error - TS7006 - Parameter 'sections' implicitly has an 'any' type.
        setSections: (sections) => dispatch(setSections(sections)),
        // @ts-expect-error - TS7006 - Parameter 'sectionTitle' implicitly has an 'any' type. | TS2345 - Argument of type '(dispatch: any, getState: GetState) => void' is not assignable to parameter of type 'Action'.
        scrollToSection: (sectionTitle) => dispatch(scrollToSection(sectionTitle)),
    }),
);

export default withScrollNav;
