import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import EMPTY_FUNCTION from 'strat/empty/function';
import { ListingCardSwipeableGallery, PropsPhoto } from 'strat/listing';

import { LiteAd } from 'horizontal/types';

import GalleryListingPhoto from './galleryListingPhoto';
import styles from './styles/adGallery.cssm';

const GALLERY_SWIPE_LIMIT = 5;

type Props = {
    readonly ad: LiteAd;
    readonly link: React.ReactElement;
    readonly lazyLoadPhoto: boolean;
    readonly isElite: boolean;
    readonly viewSection: Values<typeof ViewSections>;
};

const AdGallery = ({ ad, link, lazyLoadPhoto, isElite, viewSection }: Props) => {
    const [slideIndex, setSlideIndex] = React.useState(0);

    const photos = React.useMemo(() => {
        const photos = ad.photos?.slice(0, GALLERY_SWIPE_LIMIT) || [];
        // repeat the last image to add see more overlay over it
        if (ad.photos && ad.photos.length > GALLERY_SWIPE_LIMIT) {
            photos.push({ ...photos[GALLERY_SWIPE_LIMIT - 1] });
        }
        return photos;
    }, [ad.photos]);

    const showSeeMoreOverlay =
        ad.photos && ad.photos.length > GALLERY_SWIPE_LIMIT && slideIndex > GALLERY_SWIPE_LIMIT - 1;

    return (
        <ListingCardSwipeableGallery
            coverPhotoID={ad.coverPhoto?.id}
            lazyLoadCoverPhoto={lazyLoadPhoto}
            photos={photos}
            renderPhoto={(extraProps: PropsPhoto) => (
                <GalleryListingPhoto
                    ad={ad}
                    isElite={isElite}
                    viewSection={viewSection}
                    showSeeMoreOverlay={!!showSeeMoreOverlay}
                    {...extraProps}
                />
            )}
            renderPhotoOverlay={EMPTY_FUNCTION}
            renderListingLink={() => link}
            className={styles.gallery}
            setCurrentSlideIndex={setSlideIndex}
            showBullets={!showSeeMoreOverlay}
        />
    );
};

export default AdGallery;
