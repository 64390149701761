import transform from 'lodash/transform';
import omit from 'lodash/omit';

type Entry<T> = T & {
    readonly level: number;
    readonly children: Array<Entry<T>>;
};

type FlatEntry<T> = Omit<T, 'children'>;

type FindDeepCallback<T> = (item: FlatEntry<T>) => boolean;

/**
 * Returns the first category in the tree (pre-order traversal) for which
 * the provided callback returns true.
 *
 * @param entries - the tree in which to search
 * @param callback - provided function used to check whether a category matches
 */
const findDeepExact = <T>(entries: Array<Entry<T>>, callback: FindDeepCallback<T>) => {
    let result = null;

    transform(entries, (_, entry) => {
        if (callback(entry)) {
            result = entry;
            return false;
        }

        const found = findDeepExact(entry.children, callback);
        if (found) {
            result = found;
            return false;
        }

        return true;
    });

    return result;
};

/**
 * Returns the first category in the tree and its ancestors, in an object form representing
 * the path to that category.
 *
 * @param entries - the tree in which to search
 * @param callback - provided function used to check whether a category matches
 * @param includeLeafNode - if this param is false, and the found category has no other
 * descendants, it wil _not_ be included in the result (i.e. the last object in the chain
 * will be its direct parent).
 */
const findDeepObject = <T>(
    entries: Array<Entry<T>>,
    callback: FindDeepCallback<T>,
    includeLeafNode = false,
): Entry<T> | null => {
    let result = null;

    transform(entries, (_, entry) => {
        if (callback(entry)) {
            result = entry;
            return false;
        }

        const found = findDeepObject(entry.children, callback, includeLeafNode);
        if (found) {
            if (includeLeafNode || (found.children && found.children.length > 0)) {
                result = {
                    ...entry,
                    children: [found],
                };
            } else {
                result = entry;
            }
        }

        return true;
    });

    return result;
};
/**
 *
 * Returns the first category in the tree and its ancestors, in a list representing
 * the path to that category.
 *
 * @param entries - the tree in which to search
 * @param callback - provided function used to check whether a category matches
 * @param includeLeafNode - if this param is false, and the found category has no other
 * descendants, it wil _not_ be included in the result (i.e. the last object in the list
 * will be its direct parent).
 */
const findDeepList = <T>(
    entries: Array<Entry<T>>,
    callback: FindDeepCallback<T>,
    includeLeafNode = false,
): Array<FlatEntry<T>> => {
    const result = findDeepObject(entries, callback, includeLeafNode);
    if (!result) {
        return [];
    }

    const resultAsList = [omit(result, ['children'])];

    let child = result.children[0];
    while (child) {
        resultAsList.push(omit(child, ['children']));
        child = child.children[0];
    }

    const lastChild = resultAsList.find(callback);
    if (!lastChild) {
        return [];
    }

    return [...resultAsList.slice(0, lastChild.level + 1)];
};

export { findDeepObject, findDeepList, findDeepExact };
