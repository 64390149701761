import { combineReducers, Dispatch } from 'redux';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { selectActiveSearchBackend } from '@sector-labs/fe-search-redux/state';
import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import shuffle from 'lodash/shuffle';
import sample from 'lodash/sample';

import { FilterValues } from 'strat/search';
import FetcherFactory from 'strat/fetcher';
import { determineAdsIndexName } from 'strat/search/indexNames';
import { SortByValues } from 'strat/search/sortValues';

/**
 * Factory for creating action creators and reducers
 * for fetching developer properties from Algolia.
 */
const developerPropertiesFactory = new FetcherFactory(
    ['developerProperties', 'listings'],
    (params: any, state: any) => {
        const algoliaSettings = state.algolia.settings;

        const language = state.i18n.language;

        const currentFilters = new FilterCollection(params);

        currentFilters.replace(
            new ExactFilter({
                attribute: FilterValues.productLabel.attribute,
                value: FilterValues.productLabel.choices.DEVELOPER,
            }),
        );

        const allAdIndexes = [
            determineAdsIndexName({ language }),
            determineAdsIndexName({ language, sortBy: SortByValues.CITY_LEVEL_SCORE }),
            determineAdsIndexName({ language, sortBy: SortByValues.DATE_DESC }),
            determineAdsIndexName({ language, sortBy: SortByValues.VERIFIED_SCORE }),
        ] as const;
        const randomAdIndex = sample(allAdIndexes) as string;

        const job = new SearchJob(randomAdIndex, currentFilters, {
            ...algoliaSettings,
            // This is sufficient to get all developer listings for a specific location
            hitsPerPage: 50,
        });

        const backend = selectActiveSearchBackend(state) as SearchBackend;
        const service = new SearchService({ backend });

        return service
            .fetchJob(job)
            .then((content) => {
                const shuffledAds = shuffle(content.hits);
                return {
                    data: {
                        hits: shuffledAds,
                    },
                    status: 200,
                };
            })
            .catch(() => ({
                data: {
                    hits: [],
                },
                status: 404,
            }));
    },
);

/**
 * Fetches developer properties from algolia.
 */
const fetchDeveloperProperties = developerPropertiesFactory.creator();

const ActionTypes = Object.freeze({
    SET_LISTING_ON_PAGE: 'DEVELOPER_PROPERTY/SET_LISTING_ON_PAGE',
    CLEAR_USED_LISTINGS: 'DEVELOPER_PROPERTY/CLEAR_USED_LISTINGS',
});

type SetDeveloperListingOnPage = {
    type: 'DEVELOPER_PROPERTY/SET_LISTING_ON_PAGE';
    pageNumber: number;
    externalID: string;
    product: string;
};

type ClearUsedDeveloperListings = {
    type: 'DEVELOPER_PROPERTY/CLEAR_USED_LISTINGS';
};

type DeveloperPropertiesAction = SetDeveloperListingOnPage | ClearUsedDeveloperListings;

const clearUsedDeveloperListings = () => (dispatch: Dispatch<ClearUsedDeveloperListings>) => {
    dispatch({
        type: ActionTypes.CLEAR_USED_LISTINGS,
    });
};

export type DeveloperPropertyManagerState = {
    externalIds: {
        [key: string]: number;
    };
};

const defaultState: DeveloperPropertyManagerState = {
    externalIds: {},
};

const developerReducer = (
    state: DeveloperPropertyManagerState | null | undefined = defaultState,
    action: DeveloperPropertiesAction,
) => {
    if (action.type === ActionTypes.SET_LISTING_ON_PAGE) {
        const newExternalID: Record<string, any> = {};
        // @ts-expect-error - TS2339 - Property 'externalID' does not exist on type 'DeveloperPropertiesAction'. | TS2339 - Property 'pageNumber' does not exist on type 'DeveloperPropertiesAction'.
        newExternalID[action.externalID] = { page: action.pageNumber, product: action.product };
        return {
            ...state,
            // @ts-expect-error - TS2531 - Object is possibly 'null'.
            externalIds: { ...state.externalIds, ...newExternalID },
        };
    } else if (action.type === ActionTypes.CLEAR_USED_LISTINGS) {
        return defaultState;
    }
    return state;
};

const setDeveloperListingOnPage =
    (pageNumber: number, propertyExternalId: string, product: string) =>
    (dispatch: Dispatch<SetDeveloperListingOnPage>) => {
        dispatch({
            type: ActionTypes.SET_LISTING_ON_PAGE,
            pageNumber,
            externalID: propertyExternalId,
            product,
        });
    };

/**
 * Reducer for developer properties.
 */
const developerPropertiesReducer = combineReducers({
    listings: developerPropertiesFactory.reducer(),
    usedListings: developerReducer,
});

export { fetchDeveloperProperties, setDeveloperListingOnPage, clearUsedDeveloperListings };

export default developerPropertiesReducer;
