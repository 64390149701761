import { ContextfulError } from 'strat/error/context';

type Props = { id: string };

class Script {
    /**
     * Starts the loading of a given JS file.
     *
     * Appends the URL to the body.
     * Creates a new object that keeps track of the the status of this script.
     * Returns a promise which will get resolved when the script is loaded.
     *
     * @param {*} url The URL to load the JavaScript file from.
     */
    static iniateScriptLoading(url: string, props?: Props): Promise<any> {
        const promise = new Promise(
            (
                resolve: (result: Promise<undefined> | undefined) => void,
                reject: (error?: any) => void,
            ) => {
                const script = document.createElement('script');
                if (CONFIG.build.DANGEROUSLY_ENABLE_BROWSER_MEMORY_DEBUGGING) {
                    script.crossOrigin = 'anonymous';
                }
                script.src = url;
                script.async = true;
                if (props) {
                    const scriptKeys = Object.keys(props) as (keyof Props)[];
                    for (let i = 0; i < scriptKeys.length; i++) {
                        script[scriptKeys[i]] = props[scriptKeys[i]];
                    }
                }
                script.onerror = (event) => {
                    reject(
                        new ContextfulError(
                            `Failed to load script from JS using Strat's Script.load`,
                            {
                                url,
                                data: props,
                                message: (event as ErrorEvent)?.message,
                                cause: event,
                            },
                        ),
                    );
                };

                window.scriptStatus[url] = {
                    loaded: false,
                };

                script.onload = () => {
                    window.scriptStatus[url].loaded = true;
                    // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
                    resolve();
                };

                if (document.body) {
                    document.body.appendChild(script);
                }
            },
        );

        window.scriptStatus[url].promise = promise;
        return promise;
    }

    /**
     * Removes a pre-existing script based on the url
     * and deletes the scriptStatus entry for that url
     * @param url The URL of script tag that was loaded before
     */
    static removeScript(url: string): void {
        const oldScript = document.querySelector(`script[src="${url}"]`);
        oldScript?.remove();
        delete window.scriptStatus[url];
    }

    /**
     * Loads a JavaScript file from the specified URL.
     *
     * If the script was already loaded, it won't loaded
     * again and the promise will be resolved immediately.
     *
     * If the script is loading, it returns the promise that
     * was created when initiating it, which will resolve when
     * the script finishes loading.
     *
     * @param url The URL to load the JavaScript file from.
     * @returns A promise that resolves when the file has been downloaded
     * and loaded.
     */
    static load(url: string, props?: Props): Promise<any> {
        if (!window.scriptStatus) {
            window.scriptStatus = {};
        }

        const scriptStatus = window.scriptStatus[url];
        if (!scriptStatus) {
            return Script.iniateScriptLoading(url, props);
        }

        if (scriptStatus.loaded) {
            return Promise.resolve();
        }

        // @ts-expect-error - TS2322 - Type 'Promise<unknown> | undefined' is not assignable to type 'Promise<any>'.
        return scriptStatus.promise;
    }
}

export default Script;
