import * as React from 'react';

import { Flex } from 'strat/components';
import { isNonEmptyArray } from 'strat/types/conditions';

import { PaymentStep } from './types';
import styles from './styles/paymentPercentagesTimeline.cssm';

type Props = {
    title: string;
    steps: PaymentStep[];
};

const PaymentPercentagesTimeline = ({ title, steps }: Props) => (
    <Flex column className={styles.container}>
        <div className={styles.title}>{title}</div>
        {isNonEmptyArray(steps) &&
            steps.map((step) => (
                <Flex key={step.label} justifySpaceBetween className={styles.stepContainer}>
                    <div className={styles.stepLabel}>{step.label}</div>
                    <div className={styles.stepValue}>{step.value}%</div>
                </Flex>
            ))}
    </Flex>
);

export default PaymentPercentagesTimeline;
