import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'strat/modal';
import { Flex } from 'strat/components';
import { useI18n } from 'strat/i18n/language';
import { selectRouteParams } from 'strat/routes/selectors';
import { RouteNames } from 'strat/routes';
import { Text, LoadingAnimation } from 'strat/components';

import { selectUserPaymentOrders } from 'horizontal/payment/selectors';
import { Pagination, usePagination } from 'horizontal/pagination';

import LoadingPaymentOrderEntry from './loadingPaymentOrderEntry';
import PaymentOrderEntry from './paymentOrderEntry';
import SortPaymentOrdersDropDown from './sortPaymentOrdersDropDown';
import { PAYMENT_ORDERS_PAGE_SIZE } from './constants';
import EmptyPaymentOrders from './emptyPaymentOrders';
import styles from './styles/paymentOrdersSheet.cssm';

const PaymentOrdersSheet = () => {
    const {
        items: paymentOrders,
        isLoading,
        activePage,
        totalPages,
    } = usePagination(selectUserPaymentOrders, PAYMENT_ORDERS_PAGE_SIZE);
    const routeParams = useSelector(selectRouteParams);

    const i18n = useI18n();
    const { width } = useWindowSize();
    const columnsCount = React.useMemo(() => {
        if (!width) {
            return 0;
        }
        if (width <= CONFIG.build.DEVICE_WIDTH_MOBILE) {
            return 1;
        }
        if (width <= CONFIG.build.DEVICE_WIDTH_TABLET) {
            return 2;
        }
        return 3;
    }, [width]);

    const paymentOrderEntries = React.useMemo(() => {
        if (isLoading) {
            return Array.from(Array(PAYMENT_ORDERS_PAGE_SIZE).keys(), (index: number) => (
                <LoadingPaymentOrderEntry key={index} />
            ));
        }

        // @ts-expect-error - TS7006 - Parameter 'payment' implicitly has an 'any' type.
        return paymentOrders.map((paymentOrder) => (
            <PaymentOrderEntry paymentOrder={paymentOrder} key={paymentOrder.externalID} />
        ));
    }, [paymentOrders, isLoading]);

    const columns = React.useMemo(() => {
        return Array.from(Array(columnsCount).keys(), (columnIndex: number) =>
            paymentOrderEntries.filter(
                // @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'entryIndex' implicitly has an 'any' type.
                (_, entryIndex) => entryIndex % columnsCount === columnIndex,
            ),
        );
    }, [columnsCount, paymentOrderEntries]);

    return (
        <Flex column stretchWidth>
            {(!!paymentOrders.length || isLoading) && (
                <div className={styles.headerContainer}>
                    <Flex justifySpaceBetween>
                        <Text.Large bold>{t(i18n)`Payment Orders history`}</Text.Large>
                        <SortPaymentOrdersDropDown />
                    </Flex>
                </div>
            )}
            {!paymentOrders.length && !isLoading && <EmptyPaymentOrders />}
            <Flex fillContainer className={styles.columnsWrapper}>
                {columns.map((entries, columnIndex) => (
                    <Flex column stretchWidth key={columnIndex}>
                        {entries}
                    </Flex>
                ))}
            </Flex>
            {!columns.length && <LoadingAnimation className={styles.loadingAnimation} />}
            <Pagination
                activePage={activePage}
                totalPages={totalPages}
                routeName={RouteNames.USER_ORDERS}
                routeParams={routeParams}
            />
        </Flex>
    );
};

export default PaymentOrdersSheet;
