import { t } from '@lingui/macro';
import * as React from 'react';

import { ProjectPaymentPlan } from 'strat/project/types';
import { Flex } from 'strat/components';
import { scrollToElement } from 'strat/navigation/scroll';
import { ProjectIdentifiers } from 'strat/searchComponents/project';
import IconInfoSvg from 'strat/assets/icons/iconInfo_noinline.svg';
import { SearchElementIdentifiers } from 'strat/search';
import { useI18n } from 'strat/i18n/language';

import ProjectTopDetailsItem from '../projectTopDetailsItem';

import styles from './styles/paymentPlanInformationSection.cssm';
import PaymentPlanTooltipTable from './paymentPlanTooltipTable';
import PaymentPlanMoreDetailsTooltip from './paymentPlanMoreDetailsTooltip';

const IconInfo = () => (
    <svg className={styles.infoIcon}>
        <use xlinkHref={`${IconInfoSvg}#info`} />
    </svg>
);

type Props = {
    paymentPlans: NonEmptyArray<readonly ProjectPaymentPlan[]>;
};

const PaymentPlanInfo = ({ paymentPlans }: Props) => {
    const paymentPlan = paymentPlans[0];

    return (
        <div className={styles.tooltipContainer}>
            <PaymentPlanMoreDetailsTooltip
                renderTrigger={() => (
                    <Flex alignCenter>
                        {paymentPlan.preHandoverSum} / {paymentPlan.postHandoverSum}
                        <IconInfo />
                    </Flex>
                )}
                renderContent={() => (
                    <PaymentPlanTooltipTable
                        preHandoverPercentage={paymentPlan.preHandoverSum}
                        postHandoverPercentage={paymentPlan.postHandoverSum}
                        hasOtherPaymentPlans={paymentPlans.length > 1}
                    />
                )}
            />
        </div>
    );
};

const PaymentPlanInformationSection = ({ paymentPlans }: Props) => {
    const i18n = useI18n();

    return (
        <ProjectTopDetailsItem
            renderLabel={() => t(i18n)`Payment Plan`}
            renderInfo={() => <PaymentPlanInfo paymentPlans={paymentPlans} />}
            linkOnClick={() => {
                const headerHeight =
                    document.getElementById(SearchElementIdentifiers.searchPageHeaderId)
                        ?.offsetHeight || 0;
                scrollToElement(
                    document.getElementById(ProjectIdentifiers.paymentPlanSectionElementID),
                    -(headerHeight + 30),
                );
            }}
            linkText={t(i18n)`View complete plan`}
        />
    );
};

export default PaymentPlanInformationSection;
