import * as React from 'react';

import SlideIn from './slideIn';
import withCloseOnUrlChange from './withCloseOnUrlChange';

type Props = React.ComponentProps<typeof SlideIn> & {
    setNotVisible: () => void;
};

const ResponsiveSlideIn = (props: Props) => <SlideIn {...props} />;

export default withCloseOnUrlChange<Props>(ResponsiveSlideIn);
