import { t } from '@lingui/macro';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import * as Yup from 'yup';
import type { I18n } from '@lingui/core';
import { isValidPhoneNumber } from 'strat/i18n/phone';
import settings from '@app/branding/settings';
import { dictionaryToFlatArray } from 'strat/util';
import { convertPriceToAlphabeticalForm } from '@app/fields/convertPriceToAlphabeticalForm';
import { matchChoiceValue } from 'strat/util';
import Category from '@app/branding/category';

import type { Category as CategoryType, FlatCategoryField } from 'horizontal/types';
import {
    CategoryFieldRole,
    CategoryFieldValueType,
    CategoryRole,
    PriceType,
    DeliveryType,
    PropertiesPaymentOptionType,
    VehiclesPaymentOptionType,
} from 'horizontal/types';
import {
    PriceTypeField,
    CategoryPriceField,
    ShortNumberField,
    ContactInfoFields,
    DownPayment,
    PaymentOption,
    MileageField,
    ConditionField,
    RentalPeriodField,
    ExtraFields,
} from 'horizontal/fields/commonPostingFields';
import { getParentField } from 'horizontal/categoryFields';
import { CarUsageConditions } from 'horizontal/ad/useGetCarMileage';

const Errors = {
    minLength: (i18n: I18n, minLength: number) =>
        t(i18n)`A minimum length of ${minLength} characters is allowed. Please edit the field.`,
    maxLength: (i18n: I18n, maxLength: number) =>
        t(i18n)`A maximum length of ${maxLength} characters is allowed. Please edit the field.`,
    min: (i18n: I18n, min: number | string) => t(i18n)`The minimum allowed value is ${min}`,
    max: (i18n: I18n, max: number | string) => t(i18n)`The maximum allowed value is ${max}`,
    required: (i18n: I18n) => t(i18n)`This field is required`,
    type: (i18n: I18n) => t(i18n)`Please input a valid number`,
    naturalNumber: (i18n: I18n) => t(i18n)`Please enter a value without decimal point`,
    invalidPhone: (i18n: I18n) => t(i18n)`Please enter a valid phone number`,
    invalidDecimalNumber: (i18n: I18n) =>
        t(i18n)`Please make sure maximum of 2 digits after decimal point`,
    missingOTPValidation: (i18n: I18n) => t(i18n)`OTP verification required`,
    missingDeliveryType: (i18n: I18n) => t(i18n)`Please select the delivery type.`,
} as const;

const validateNumber = (
    i18n: I18n,
    minValue?: number | null,
    maxValue?: number | null,
    customRangeFormatter?: (arg1: number, arg2: I18n) => string | null | undefined,
) => {
    let rule;
    rule = Yup.number().nullable().typeError(Errors.type(i18n));

    if (minValue !== undefined && minValue !== null) {
        const formattedMinValue = customRangeFormatter
            ? customRangeFormatter(minValue, i18n)
            : minValue;
        rule = rule.min(minValue, Errors.min(i18n, formattedMinValue || minValue));
    }
    if (maxValue !== undefined && maxValue !== null) {
        const formattedMaxValue = customRangeFormatter
            ? customRangeFormatter(maxValue, i18n)
            : maxValue;
        rule = rule.max(maxValue, Errors.max(i18n, formattedMaxValue || maxValue));
    }
    return rule;
};

const getAlphanumericLength = (value?: string | null) => {
    if (!value) {
        return 0;
    }
    const invalidRanges = /[^0-9a-zA-Z\u0621-\u064A\u0980-\u09FB]+/g;
    const cleanedValue = value.replace(invalidRanges, '');
    return cleanedValue.length;
};

/**
 * Ensures enums are required only when we have available choices for them.
 * e.g. We have both make and model which are required.
 * A make has multiple models.
 * But not all makes have models. Some makes(e.g. `other-make`) can't be split into models.
 * In those cases the model should not be mandatory.
 *
 * @param field: the current enum field
 * @param allFields: all category fields are required in order to check parent relationships and validate properly
 * @param values: the selected values for the form attributes
 * @returns {boolean}
 */
const isEnumValueValid = (
    field: FlatCategoryField,
    allFields: FlatCategoryField[],
    values: any,
) => {
    if (values[field.attribute]) {
        // If we have a value for the field it's fine
        return true;
    }

    const parentField = getParentField(field, allFields);
    if (!parentField) {
        return false;
    }

    const parentChoice = dictionaryToFlatArray(parentField.choices).find((choice) =>
        matchChoiceValue(choice, `${values[parentField.attribute]}`),
    );
    if (!parentChoice) {
        // If the value of the parent does not exist, we don't know if it's a valid config.
        // But an error will be thrown for the parent if it's value is not supposed to be empty.
        // So we can safely say there is no error for the current field.
        return true;
    }

    return field.choices.length === 0;
};

const validation = (i18n: I18n, field: FlatCategoryField, allFields: FlatCategoryField[]) => {
    const { valueType, minLength, maxLength, minValue, maxValue, isMandatory, attribute } = field;

    let rule;
    const customRangeFormatter = PriceTypeField.attribute.startsWith(attribute)
        ? convertPriceToAlphabeticalForm
        : undefined;

    switch (valueType) {
        case CategoryFieldValueType.STRING: {
            rule = Yup.string();
            if (minLength) {
                rule = rule.min(minLength, Errors.minLength(i18n, minLength));
            }

            if (maxLength) {
                rule = rule.max(maxLength, Errors.maxLength(i18n, maxLength));
            }

            break;
        }
        case CategoryFieldValueType.INTEGER:
            // @ts-ignore
            rule = validateNumber(i18n, minValue, maxValue, customRangeFormatter);
            rule = rule.integer(Errors.naturalNumber(i18n));
            break;
        case CategoryFieldValueType.FLOAT: {
            // @ts-ignore
            rule = validateNumber(i18n, minValue, maxValue, customRangeFormatter);
            break;
        }
        case CategoryFieldValueType.ENUM_MULTIPLE: {
            rule = Yup.string().nullable();
            break;
        }
        default:
            rule = Yup.string();
    }

    if (isMandatory) {
        if (valueType === CategoryFieldValueType.ENUM) {
            rule = rule.test(
                'requiredIfHasChoice',
                Errors.required(i18n),
                function requiredIfHasChoice() {
                    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
                    return isEnumValueValid(field, allFields, this.parent);
                },
            );
        } else {
            rule = rule.required(Errors.required(i18n));
        }
    }
    return rule;
};

const generateValidationSchema = (
    i18n: I18n,
    fields: Array<FlatCategoryField>,
    roles?: Array<Values<typeof CategoryRole>>,
    activeCategory?: CategoryType | null | undefined,
) => {
    const extraValidation = fields.reduce<Record<string, any>>((acc, value) => {
        // this is needed to skip tha validation for price field in jobs categories
        if (!value.roles.includes(CategoryFieldRole.EXCLUDE_FROM_POST_AN_AD)) {
            acc[value.attribute] = validation(i18n, value, fields);
        }
        return acc;
    }, {});

    if (!extraValidation[PriceTypeField.attribute]) {
        extraValidation[PriceTypeField.attribute] = Yup.string().default(PriceType.FIXED_PRICE);
    }

    if (
        extraValidation[PriceTypeField.attribute] &&
        extraValidation[CategoryPriceField.attribute]
    ) {
        extraValidation[CategoryPriceField.attribute] = Yup.number().when(
            [PriceTypeField.attribute],
            {
                // @ts-expect-error - TS7006 - Parameter 'type' implicitly has an 'any' type.
                is: (type) =>
                    [PriceType.FREE, PriceType.EXCHANGE, PriceType.REQUESTABLE].includes(type),
                then: extraValidation[CategoryPriceField.attribute].nullable(),
                otherwise: extraValidation[CategoryPriceField.attribute]
                    .required(Errors.required(i18n))
                    // TODO: replace this with moreThan(0) once we update Yup to ^0.24.1
                    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                    .test('positiveNumber', Errors.required(i18n), (value) => value > 0),
            },
        );
    }
    if (
        extraValidation[CategoryPriceField.attribute] &&
        extraValidation[DownPayment.attribute] &&
        extraValidation[RentalPeriodField.attribute]
    ) {
        extraValidation[DownPayment.attribute] = extraValidation[DownPayment.attribute].test(
            'moreThanPrice',
            t(
                i18n,
            )`down payment value can be up to ${settings.downPaymentValidationMultiplier || 5} times the price`,
            function validateDownPayment(
                this: { parent: { [key: string]: number | string } },
                value: number | string,
            ) {
                const price = Number(this.parent['price']);
                return !value || value <= price * (settings.downPaymentValidationMultiplier || 5);
            },
        );
    }
    // Always validate that down payment is less than the price if down payment has a value and the price field exists.
    else if (
        extraValidation[CategoryPriceField.attribute] &&
        extraValidation[DownPayment.attribute]
    ) {
        extraValidation[DownPayment.attribute] = extraValidation[DownPayment.attribute].test(
            'lessThanPrice',
            t(i18n)`down payment must be less than the price`,
            function validateDownPayment(value: number | string) {
                // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
                const price = this.parent['price'];
                return !value || value < price;
            },
        );
    }
    if (
        extraValidation[PaymentOption.attribute] &&
        extraValidation[DownPayment.attribute] &&
        extraValidation[ExtraFields.make.attribute]
    ) {
        extraValidation[DownPayment.attribute] = Yup.number().when(PaymentOption.attribute, {
            is: (type: Values<typeof VehiclesPaymentOptionType>) =>
                VehiclesPaymentOptionType.INSTALLMENT === type,
            then: extraValidation[DownPayment.attribute],
            otherwise: Yup.number().nullable(),
        });
    } else if (extraValidation[PaymentOption.attribute] && extraValidation[DownPayment.attribute]) {
        extraValidation[DownPayment.attribute] = Yup.number().when(PaymentOption.attribute, {
            is: (type: Values<typeof PropertiesPaymentOptionType>) =>
                [
                    PropertiesPaymentOptionType.INSTALLMENT,
                    PropertiesPaymentOptionType.CASH_OR_INSTALLMENT,
                ].includes(type),
            then: extraValidation[DownPayment.attribute],
            otherwise: Yup.number().nullable(),
        });
    }

    const isPhotoOptional = (roles || []).includes(CategoryRole.ALLOW_AD_WITHOUT_PHOTO);

    if (extraValidation[MileageField.attribute]) {
        if (Category.isOfCarsType(activeCategory)) {
            const mileageField = fields.find(
                (field) =>
                    field.attribute === MileageField.attribute &&
                    field.valueType === CategoryFieldValueType.INTEGER,
            );

            if (mileageField) {
                const minValue = mileageField.minValue || 0;

                extraValidation[MileageField.attribute] = Yup.number().when(
                    ConditionField.attribute,
                    {
                        is: (value: string) => CarUsageConditions.NEW.includes(value),
                        then: Yup.number()
                            .required()
                            .min(minValue, Errors.min(i18n, minValue))
                            .max(minValue, Errors.max(i18n, minValue)),
                        otherwise: extraValidation[MileageField.attribute].min(
                            1,
                            Errors.min(i18n, 1),
                        ),
                    },
                );
            }
        }
    }

    const shape = {
        title: Yup.string()
            .min(5, Errors.minLength(i18n, 5))
            .test(
                'valid-title-length',
                t(
                    i18n,
                )`Title should contain at least 5 alphanumeric characters. Please edit the field`,
                // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                (value) => getAlphanumericLength(value) >= 5,
            )
            .required(Errors.required(i18n)),
        description: Yup.string()
            .min(20, Errors.minLength(i18n, 20))
            .test(
                'valid-description-length',
                t(
                    i18n,
                )`Description should contain at least 10 alphanumeric characters. Please edit the field`,
                // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                (value) => getAlphanumericLength(value) >= 10,
            )
            .required(Errors.required(i18n)),
        ...(!isPhotoOptional && {
            photos: Yup.array()
                // List of absolute URL's
                .of(Yup.string())
                .required(t(i18n)`Please provide an image`),
        }),
        location_id: Yup.string().required(Errors.required(i18n)),
        category_id: Yup.string().required(Errors.required(i18n)),
        short_number: Yup.boolean(),
        name: Yup.string().min(3, Errors.minLength(i18n, 3)).required(Errors.required(i18n)),
        phone_number: Yup.string().when(ShortNumberField.attribute, {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string()
                .required(Errors.required(i18n))
                .test(
                    'validPhone',
                    Errors.invalidPhone(i18n),
                    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                    (value) => isValidPhoneNumber(value),
                )
                .test('otp_verified', Errors.missingOTPValidation(i18n), function isOTPVerified() {
                    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
                    return this?.parent?.[ContactInfoFields.is_otp_verified.attribute];
                }),
        }),
        is_deliverable: Yup.boolean(),
        delivery_type: Yup.string().when('is_deliverable', {
            is: true,
            then: Yup.string().required(Errors.missingDeliveryType(i18n)),
        }),

        weight: Yup.number().when('delivery_type', {
            is: DeliveryType.SERVICE_DELIVERY,
            then: Yup.number()
                // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                .test('positiveNumber', Errors.min(i18n, 0.01), (value) => value >= 0.01)
                .max(
                    CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_WEIGHT,
                    Errors.max(i18n, CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_WEIGHT),
                )
                .required(Errors.required(i18n))
                .test(
                    'is-decimal',
                    Errors.invalidDecimalNumber(i18n),
                    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                    (value) => Math.floor(value * 100) === value * 100,
                ),
        }),
        ...(CONFIG.runtime.STRAT_ENABLE_AD_PRODUCT_DIMENSIONS && {
            length: Yup.number().when('delivery_type', {
                is: DeliveryType.SERVICE_DELIVERY,
                then: Yup.number()
                    .test(
                        'positiveNumber',
                        Errors.min(i18n, 0.01),
                        (value: number) => value >= 0.01,
                    )
                    .max(
                        CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_LENGTH,
                        Errors.max(i18n, CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_LENGTH),
                    )
                    .required(Errors.required(i18n))
                    .test(
                        'is-decimal',
                        Errors.invalidDecimalNumber(i18n),
                        (value: number) => Math.floor(value * 100) === value * 100,
                    ),
            }),
            height: Yup.number().when('delivery_type', {
                is: DeliveryType.SERVICE_DELIVERY,
                then: Yup.number()
                    .test(
                        'positiveNumber',
                        Errors.min(i18n, 0.01),
                        (value: number) => value >= 0.01,
                    )
                    .max(
                        CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_HEIGHT,
                        Errors.max(i18n, CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_HEIGHT),
                    )
                    .required(Errors.required(i18n))
                    .test(
                        'is-decimal',
                        Errors.invalidDecimalNumber(i18n),
                        (value: number) => Math.floor(value * 100) === value * 100,
                    ),
            }),
            width: Yup.number().when('delivery_type', {
                is: DeliveryType.SERVICE_DELIVERY,
                then: Yup.number()
                    .test(
                        'positiveNumber',
                        Errors.min(i18n, 0.01),
                        (value: number) => value >= 0.01,
                    )
                    .max(
                        CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_WIDTH,
                        Errors.max(i18n, CONFIG.runtime.STRAT_AD_DELIVERY_MAX_ITEM_WIDTH),
                    )
                    .required(Errors.required(i18n))
                    .test(
                        'is-decimal',
                        Errors.invalidDecimalNumber(i18n),
                        (value: number) => Math.floor(value * 100) === value * 100,
                    ),
            }),
        }),
        pickup_address_id: Yup.string().when('delivery_type', {
            is: DeliveryType.SERVICE_DELIVERY,
            then: Yup.string().required(Errors.required(i18n)),
        }),
        ...(!settings.deliverySettings?.disableTermsConditionsCheckbox && {
            delivery_terms: Yup.boolean().when('delivery_type', {
                is: DeliveryType.SERVICE_DELIVERY,
                then: Yup.boolean().test(
                    'acceptedTerms',
                    t(i18n)`Must Accept Terms & Conditions`,
                    // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                    (value) => value === true,
                ),
            }),
        }),
        ...extraValidation,
    } as const;

    return Yup.object().shape(shape);
};

export { generateValidationSchema };
