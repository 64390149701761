import * as React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { GlobalState } from '@app/state';

import { selectIsProjectSearchEnabled, selectProject } from 'strat/search/selectors';
import { ProjectSearchType } from 'strat/search/types';
import Purpose from 'strat/purpose';
import { withOvation, type OvationProps } from 'strat/ovation';
import type { PropertyData } from 'strat/property/types';
import type { AnyAdData } from 'strat/types';
import { PropertyType } from 'strat/property/types';
import { BayutCompat, asNumberOrString } from 'strat/util';
import selectGTMAdData from '@app/gtm/selectGTMAdData';
import {
    selectUserID,
    selectAnonymousSessionID,
    selectAgentUserProfile,
} from 'strat/user/selectors';
import { selectUserExternalID } from 'strat/user/session';
import type { StoryData } from 'strat/stories/types';
import { selectLayout } from 'strat/layout/selectors';
import type { NudgeManagerState } from 'strat/nudges/state';
import { transformLocalizedFieldToEnglish } from 'strat/util';

import generateUniqueEventID from './generateUniqueEventID';
import withGTM, { type GTMProps } from './withGTM';
import Triggers from './triggers';
import ShareMediums from './shareMediums';
import ViewSections from './viewSections';
import ViewElements from './viewElements';
import withGTMLegacyStats, { type GTMLegacyStatsProps } from './withGTMLegacyStats';
import getPropertyOfTheWeekGTMVars from './getPropertyOfTheWeekGTMVars';
import PhoneMessageResponse from './phoneMessageResponses';
import PageSections from './pageSections';
import getProjectOrPropertyID from './getProjectOrPropertyID';
import { getPropertyReferralGTMVars } from './propertyReferral';
import withItemReferral from './withItemReferral';
import { getNudgesGTMVars } from './nudges';
import getHidePriceGTMVars from './getHidePriceGTMVars';
import PageTypes from './pageType';
import { getAgencyAndLocationPurposeTierGTMVars } from './tierData';

/**
 * Properties needed by the HOC which are passed from where the component is used
 */
type ExternalProps<T = PropertyData> = {
    property?: PropertyData | null;
    ad?: T;
    story?: StoryData | null;
};

/**
 * Properties for {@see GTMLeadTracking}.
 */
type Props = GTMLegacyStatsProps &
    GTMProps &
    OvationProps &
    ExternalProps & {
        property: PropertyData;
        nudges: NudgeManagerState;
        propertyOfTheWeek?: any | null;
        platform?: any | null;
        loggedInAgent: {
            externalID: string;
            name: string;
        };
        isProjectPage?: boolean;
        isProjectView?: boolean;
    };

/**
 * Properties passed to the trackChatLead
 */
export type TrackChatLead = (
    viewSection: Values<typeof ViewSections>,
    name: string,
    email: string,
    phone: string | null | undefined,
    userType: string | null | undefined,
    message: string | File,
) => void;

/**
 * Properties passed to the wrapped component.
 */
export type GTMLeadTrackingProps = Props & {
    trackSMSAttempt: (viewSection: Values<typeof ViewSections>) => void;
    trackChatAttempt: (viewSection: Values<typeof ViewSections>, property: PropertyData) => void;
    trackPhoneViewAttempt: (viewSection: Values<typeof ViewSections>) => void;
    trackCallView: (viewSection: Values<typeof ViewSections>) => void;
    trackOvationPhoneView: (viewSection: Values<typeof ViewSections>) => void;
    trackCallLead: (viewSection: Values<typeof ViewSections>) => void;
    getSMSVariables: (viewSection: Values<typeof ViewSections>) => any;
    trackSMSLead: (viewSection: Values<typeof ViewSections>) => Promise<string>;
    trackWhatsAppLead: (viewSection?: Values<typeof ViewSections>) => Promise<string>;
    trackEmailView: (viewSection: Values<typeof ViewSections>) => void;
    trackWhatsappView: (viewSection: Values<typeof ViewSections>) => void;
    trackEmailLead: (viewSection: Values<typeof ViewSections>) => void;
    trackFavoriteToggle: (viewSection: Values<typeof ViewSections>, toggle: boolean) => void;
    trackListingClick: (viewSection: Values<typeof ViewSections>) => void;
    trackAlternativeSearchListingClick: (listingPosition: number) => void;
    trackPOTWCLick: (viewSection: Values<typeof ViewSections>) => void;
    trackViewAgencyProperties: (viewSection: Values<typeof ViewSections>) => void;
    trackShareSocialButton: (
        shareType: Values<typeof ShareMediums>,
        pageType: Values<typeof PageTypes>,
        triggerName: Values<typeof Triggers> | null,
        propertyData: PropertyData,
    ) => void;
    trackShareTransactions: (
        shareType: Values<typeof ShareMediums>,
        pageType: Values<typeof PageTypes>,
    ) => void;
    trackChatLead: TrackChatLead;
    trackMapViewToggle: (
        viewSection: Values<typeof ViewSections>,
        toggleState: boolean,
        pageType: Values<typeof PageTypes>,
    ) => void;
    trackEmailForRequestFloorPlan: (viewSection: Values<typeof ViewSections>) => void;
    trackCallForRequestFloorPlan: (viewSection: Values<typeof ViewSections>) => void;
    trackRequestFloorPlan: (viewSection: Values<typeof ViewSections>) => void;
    trackOfferView: (viewSection: Values<typeof ViewSections>) => void;
    trackRequestVideo: (viewSection: Values<typeof ViewSections>) => void;
    trackCalculatorEnquiry: (viewSection: Values<typeof ViewSections>) => void;
    trackShareAgentProfileLink: (shareType: Values<typeof ShareMediums>) => void;
    trackSharePropertyLink: (
        property: PropertyData,
        shareType: Values<typeof ShareMediums>,
        pageType: Values<typeof PageTypes>,
    ) => void;
};

/**
 * Provides convenience functions for lead tracking.
 */
const withGTMLeadTracking = <T extends GTMLeadTrackingProps, U = PropertyData>(
    component: React.ComponentType<T>,
): React.ComponentType<Omit<T, keyof GTMLeadTrackingProps> & ExternalProps<U>> => {
    class GTMLeadTracking extends React.Component<Props> {
        constructor(props: T) {
            super(props);
            autoBind(this);
        }

        /**
         * Determines the view type based on the specified view section.
         */
        static determineViewType(viewSection: Values<typeof ViewSections>): number {
            switch (viewSection) {
                case ViewSections.SEARCH_RESULTS:
                case ViewSections.EMAIL_POPUP:
                    return 2;

                default:
                    return 3;
            }
        }

        /**
         * Returns a promise that resolves to PhoneMessageResponse.TIMEOUT if the promise
         * doesn't resolve in a certain time
         */
        static timeoutPromise<T extends string>(
            promise: Promise<T>,
            time: number,
        ): Promise<T | typeof PhoneMessageResponse.TIMEOUT> {
            const timeout = new Promise(
                (resolve: (result: typeof PhoneMessageResponse.TIMEOUT) => void) =>
                    setTimeout(() => resolve(PhoneMessageResponse.TIMEOUT), time),
            );
            return Promise.race([promise, timeout]);
        }

        /**
         * Gets a set of variables that are send in all events.
         */
        commonVariables(
            viewSection: Values<typeof ViewSections>,
            property: AnyAdData = this.props.property,
        ): any {
            // @ts-expect-error - TS2339 - Property 'itemReferral' does not exist on type 'Readonly<T> & Readonly<{ children?: ReactNode; }>'. | TS2339 - Property 'nudges' does not exist on type 'Readonly<T> & Readonly<{ children?: ReactNode; }>'.
            const { itemReferral, nudges } = this.props;
            const propertyID = getProjectOrPropertyID(property);
            const uniqueEventID = generateUniqueEventID();

            const propertyType = property.type === PropertyType.PROJECT || property.project ? 7 : 1;
            const propertyTypeName =
                property.type === PropertyType.PROJECT || property.project ? 'project' : 'property';
            let pageGroup = this.props.isProjectView ? ProjectSearchType.PROJECT_VIEW : null;
            if (this.props.isProjectPage) {
                pageGroup = ProjectSearchType.PROJECT_PAGE;
            }

            const featuresFormattedValues = property?.formattedExtraFields?.map(
                (formattedField) => {
                    if (formattedField.attribute === 'features') {
                        return transformLocalizedFieldToEnglish(
                            formattedField,
                            'formattedValue',
                            CONFIG.build.LANGUAGE_CODE,
                            false,
                        );
                    }
                    return [];
                },
            );

            const isFreehold = featuresFormattedValues?.some((featureList) =>
                (featureList as string[]).includes('Freehold'),
            );

            const variables = {
                event_unique_id: uniqueEventID,
                listing_id: propertyID,
                object_id: propertyID,
                object_type: propertyType,
                object_type_name: propertyTypeName,
                view_section: viewSection,
                view_type: GTMLeadTracking.determineViewType(viewSection),
                phone_numbers: property.phoneNumber ? Object.values(property.phoneNumber) : [],
                reference_id: property.referenceNumber || null,
                platform: this.props.platform,
                // @ts-expect-error - TS2339 - Property 'userExternalId' does not exist on type 'NonNullable<Readonly<T> & Readonly<{ children?: ReactNode; }>>'.
                user_id: asNumberOrString(this.props?.userExternalId),
                ownership_type: isFreehold ? 'freehold' : 'non-freehold',
                page_group: pageGroup,
                ...(Purpose.isForRent(property.purpose)
                    ? {
                          furnishing_status: property.furnishingStatus,
                      }
                    : { completion_status: property.completionStatus }),
                ...(property.type === PropertyType.PROPERTY
                    ? getPropertyOfTheWeekGTMVars(property, this.props.propertyOfTheWeek)
                    : {}),
                ...(property.type === PropertyType.PROPERTY
                    ? getPropertyReferralGTMVars(property.externalID, itemReferral)
                    : {}),
                ...(property.type === PropertyType.PROPERTY
                    ? getNudgesGTMVars(property.externalID, nudges)
                    : {}),
                ...(property.type === PropertyType.PROPERTY
                    ? getAgencyAndLocationPurposeTierGTMVars(property)
                    : {}),
            } as const;

            if (viewSection === ViewSections.RECOMMENDED_PROPERTIES) {
                variables.page_section = PageSections.RECOMMENDED_LISTING_LIST;
            } else if (viewSection === ViewSections.SEARCH_RESULTS) {
                variables.page_section = PageSections.LISTING_LIST;
            } else if (viewSection === ViewSections.GALLERY_VIEW) {
                variables.page_section = PageSections.GALLERY_VIEW;
            }

            return variables;
        }

        /**
         * Tracks a logged-out user clicking the "SMS" button.
         */
        trackSMSAttempt(viewSection: Values<typeof ViewSections>): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);

            const variables = {
                ...commonVariables,
                ...getHidePriceGTMVars(this.props.property),
                view_element: ViewElements.SMS_BUTTON,
            } as const;

            this.props.trigger(Triggers.SMS_ATTEMPT, variables);
        }
        /**
         * Tracks a logged-out user clicking the "Call" button.
         */
        trackPhoneViewAttempt(viewSection: Values<typeof ViewSections>): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);

            const variables = {
                ...commonVariables,
                ...this.props.callLeadVars(this.props.property),
                ...getHidePriceGTMVars(this.props.property),
                view_element: ViewElements.CALL_BUTTON,
            } as const;

            this.props.trigger(Triggers.PHONE_VIEW_ATTEMPT, variables);
        }

        /**
         * Tracks a logged-out user clicking the "Chat" button.
         */
        trackChatAttempt(viewSection: Values<typeof ViewSections>, property: PropertyData): void {
            if (!this.props.canIngestAd(property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);

            const variables = {
                ...commonVariables,
                ...getHidePriceGTMVars(property),
                view_element: ViewElements.CHAT_BUTTON,
            } as const;

            this.props.trigger(Triggers.CHAT_ATTEMPT, variables);
        }

        /**
         * Tracks the user clicking the "Call" button.
         */
        trackCallView(viewSection: Values<typeof ViewSections>): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);

            const variables = {
                ...commonVariables,
                ...this.props.callLeadVars(this.props.property),
                ...getHidePriceGTMVars(this.props.property),
                view_element: ViewElements.CALL_BUTTON,
            } as const;

            this.props.trigger(Triggers.PHONE_VIEW, variables);
            if (CONFIG.build.STRAT_ENABLE_UPDATED_SEARCH_TRACKING) {
                window.dispatchEvent(new Event(Triggers.SEARCH_TRIGGERING_EVENT));
            }
            this.trackOvationPhoneView(viewSection, commonVariables);
        }

        /*
         * Ingests a phone view event to Ovation
         */
        trackOvationPhoneView(
            viewSection: Values<typeof ViewSections>,
            // @ts-expect-error - TS2554 - Expected 1-2 arguments, but got 0.
            variables: any | null = this.commonVariables(),
        ): void {
            this.props.ovation.ingestAdPhoneView(
                this.props.property,
                viewSection,
                // eslint-disable-next-line camelcase
                variables?.event_unique_id,
            );
        }

        /**
         * Tracks the user clicking the phone number.
         */
        trackCallLead(viewSection: Values<typeof ViewSections>): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }

            const commonVariables = this.commonVariables(viewSection);
            const isReferredByTruEstimate = document.referrer?.includes('tru-estimate');
            const flowType = isReferredByTruEstimate ? 'truestimate' : commonVariables.flow_type;

            this.props.trigger(Triggers.PHONE_LEAD, {
                ...commonVariables,
                ...this.props.callLeadVars(this.props.property),
                ...getHidePriceGTMVars(this.props.property),
                view_element: ViewElements.CALL_BUTTON,
                flow_type: flowType,
            });
        }

        getSMSVariables(viewSection: Values<typeof ViewSections>): any {
            const variables = {
                ...this.commonVariables(viewSection),
                ...this.props.smsLeadVars(this.props.property),
                view_element: ViewElements.SMS_BUTTON,
            } as const;

            return variables;
        }

        /**
         * Tracks the user clicking the "SMS" button.
         */
        trackSMSLead(viewSection: Values<typeof ViewSections>): Promise<string | null | undefined> {
            if (!this.props.canIngestAd(this.props.property)) {
                // @ts-expect-error - TS2322 - Type 'Promise<void>' is not assignable to type 'Promise<string | null | undefined>'.
                return Promise.resolve();
            }
            const commonVariables = this.commonVariables(viewSection);
            const isReferredByTruEstimate = document.referrer?.includes('tru-estimate');
            const flowType = isReferredByTruEstimate ? 'truestimate' : commonVariables.flow_type;

            const promise: Promise<
                typeof PhoneMessageResponse.UNDEFINED | typeof PhoneMessageResponse.ERROR
            > = this.props.ovation
                .ingestAdSMSView(this.props.property, viewSection, commonVariables.event_unique_id)
                .then((response) =>
                    response.status === 501
                        ? PhoneMessageResponse.UNDEFINED
                        : response.data[0].trace_id,
                )
                .catch(() => PhoneMessageResponse.ERROR);
            // If the call to ovation is slow/does not work, we want the sms button to still work
            return GTMLeadTracking.timeoutPromise(promise, 3000).then((traceID) => {
                const eventUniqueID = Object.values(PhoneMessageResponse).includes(traceID)
                    ? null
                    : traceID;
                const variables = {
                    ...omit(commonVariables, ['event_unique_id']),
                    ...this.props.smsLeadVars(this.props.property),
                    ...getHidePriceGTMVars(this.props.property),
                    view_element: ViewElements.SMS_BUTTON,
                    // the traceID returned by Ovation was introduced first as event_unique_id
                    // specifically for SMS and Whatsapp leads; but it cannot be changed to the
                    // ID generated from strat, until implementation is not refactored
                    event_unique_id: eventUniqueID,
                    flow_type: flowType,
                } as const;

                this.props.trigger(Triggers.SMS_LEAD, variables);

                return traceID;
            });
        }

        /**
         * Tracks the user clicking the "WhatsApp" button.
         */
        trackWhatsAppLead(viewSection: Values<typeof ViewSections>) {
            if (!this.props.canIngestAd(this.props.property)) {
                return Promise.resolve();
            }
            const commonVariables = this.commonVariables(viewSection);

            const promise: Promise<
                typeof PhoneMessageResponse.UNDEFINED | typeof PhoneMessageResponse.ERROR
            > = this.props.ovation
                .ingestAdWhatsAppView(
                    this.props.property,
                    viewSection,
                    commonVariables.event_unique_id,
                )
                .then((response) =>
                    response.status === 501
                        ? PhoneMessageResponse.UNDEFINED
                        : response.data[0].trace_id,
                )
                .catch(() => PhoneMessageResponse.ERROR);

            if (CONFIG.build.STRAT_ENABLE_UPDATED_SEARCH_TRACKING) {
                window.dispatchEvent(new Event(Triggers.SEARCH_TRIGGERING_EVENT));
            }

            // If the call to ovation is slow/does not work, we want the whatsApp button to still work
            return GTMLeadTracking.timeoutPromise(promise, 3000).then((traceID) => {
                const eventUniqueID = Object.values(PhoneMessageResponse).includes(traceID)
                    ? null
                    : traceID;
                const isReferredByTruEstimate = document.referrer?.includes('tru-estimate');
                const flowType = isReferredByTruEstimate
                    ? 'truestimate'
                    : commonVariables.flow_type;
                const variables = {
                    ...omit(commonVariables, ['event_unique_id']),
                    ...this.props.whatsappLeadVars(this.props.property),
                    ...getHidePriceGTMVars(this.props.property),
                    view_element: ViewElements.WHATSAPP_BUTTON,
                    // the traceID returned by Ovation was introduced first as event_unique_id
                    // specifically for SMS and Whatsapp leads; but it cannot be changed to the
                    // ID generated from strat, until implementation is not refactored
                    event_unique_id: eventUniqueID,
                    flow_type: flowType,
                } as const;

                this.props.trigger(Triggers.WHATSAPP_LEAD, variables);

                const triggerName =
                    this.props.property.purpose === Purpose.FOR_RENT
                        ? Triggers.WHATSAPP_RENT
                        : Triggers.WHATSAPP_SALE;

                this.props.trigger(triggerName, variables);
                return traceID;
            });
        }

        /**
         * Tracks the user clicking the "Email" button.
         */
        trackEmailView(viewSection: Values<typeof ViewSections>): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);

            this.props.trigger(Triggers.EMAIL_VIEW, {
                ...commonVariables,
                ...this.props.emailLeadVars(this.props.property),
                ...getHidePriceGTMVars(this.props.property),
                view_element: ViewElements.EMAIL_BUTTON,
            });

            if (CONFIG.build.STRAT_ENABLE_UPDATED_SEARCH_TRACKING) {
                window.dispatchEvent(new Event(Triggers.SEARCH_TRIGGERING_EVENT));
            }

            this.props.ovation.ingestAdEmailView(
                this.props.property,
                viewSection,
                commonVariables.event_unique_id,
            );
        }

        /**
         * Tracks the user views the "WhatsApp" button.
         */
        trackWhatsappView(viewSection: Values<typeof ViewSections>): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);

            this.props.trigger(Triggers.WHATSAPP_VIEW, {
                ...commonVariables,
                ...this.props.whatsappLeadVars(this.props.property),
                ...getHidePriceGTMVars(this.props.property),
                view_element: ViewElements.WHATSAPP_BUTTON,
            });

            this.props.ovation.ingestAdWhatsAppView(
                this.props.property,
                viewSection,
                commonVariables.event_unique_id,
            );
        }

        /**
         * Tracks the user clicking the "Email" button to send an e-mail.
         */
        trackEmailLead(
            viewSection: Values<typeof ViewSections>,
            name: string,
            email: string,
            phone: string,
            body: string,
            userType: string | null = null,
            keepMeInformed = false,
        ): void {
            if (!this.props.canIngestAd(this.props.property)) {
                return;
            }
            const commonVariables = this.commonVariables(viewSection);
            const isReferredByTruEstimate = document.referrer?.includes('tru-estimate');
            const flowType = isReferredByTruEstimate ? 'truestimate' : commonVariables.flow_type;

            const variables = {
                ...commonVariables,
                ...this.props.emailLeadVars(this.props.property),
                ...getHidePriceGTMVars(this.props.property),
                inquirer_name: name,
                inquirer_email: email,
                inquirer_phone: phone,
                inquirer_body: body,
                inquiry_body: body,
                keep_me_informed: keepMeInformed ? 1 : 0,
                user_type: userType,
                flow_type: flowType,
            } as const;

            this.props.trigger(Triggers.EMAIL_LEAD, variables);

            this.props.ovation.ingestAdEmailLead(
                this.props.property,
                {
                    userName: name,
                    userEmailAddress: email,
                    userPhoneNumber: phone,
                    emailBody: body,
                },
                commonVariables.event_unique_id,
            );
        }

        /**
         * Tracks the user clicking the "Offer" button.
         */
        trackOfferView(viewSection: Values<typeof ViewSections>): void {
            this.props.trigger(Triggers.OFFER_VIEW, {
                ...this.commonVariables(viewSection),
                ...this.props.emailLeadVars(this.props.property),
            });
        }

        /**
         * Tracks the user opening chat with the seller.
         */
        trackChatLead(
            viewSection: Values<typeof ViewSections>,
            name: string,
            email: string,
            phone: string,
            userType: string | null | undefined = null,
            message: string | File,
        ): void {
            const commonVariables = this.commonVariables(viewSection);

            const property = this.props.property;
            // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'string | File'.
            const textMessage = message.name || message;
            const variables = {
                ...commonVariables,
                ...this.props.emailLeadVars(property),
                ...getHidePriceGTMVars(property),
                inquirer_name: name,
                inquirer_email: email,
                inquirer_phone: phone,
                user_type: userType,
                textMessage,
            } as const;

            const triggerName =
                property.purpose === Purpose.FOR_RENT ? Triggers.CHAT_RENT : Triggers.CHAT_SALE;

            this.props.trigger(triggerName, variables);
            this.props.trigger(Triggers.CHAT_LEAD, variables);

            this.props.ovation.ingestAdChatLead(
                property,
                {
                    userName: name,
                    userEmailAddress: email,
                    userPhoneNumber: phone,
                    message: textMessage,
                },
                commonVariables.event_unique_id,
            );
        }

        /**
         * Tracks the user toggling the "Favorite" button.
         */
        trackFavoriteToggle(viewSection: Values<typeof ViewSections>, toggle: boolean): void {
            this.props.trigger(Triggers.FAVORITE_TOGGLE, {
                ...this.commonVariables(viewSection),
                favorite: toggle ? 1 : 0,
            });
        }

        /**
         * Tracks the user clicking the "View all our properties" button.
         */
        trackViewAgencyProperties(viewSection: Values<typeof ViewSections>): void {
            this.props.trigger(Triggers.VIEW_AGENCY_PROPERTIES, {
                view_section: viewSection,
                platform: this.props.platform,
                event_unique_id: generateUniqueEventID(),
            });
        }

        /**
         * Tracks the user clicking a listing, storing the information of the source listing
         * (from which the click was made) and the information of the destination listing
         * (the one the click is made to).
         */
        trackListingClick(viewSection: Values<typeof ViewSections>): void {
            this.props.trigger(Triggers.LISTING_CLICK, {
                clicked_listing: { ...this.commonVariables(viewSection) },
            });
        }

        trackAlternativeSearchListingClick(listingPosition: number): void {
            this.props.trigger(Triggers.LISTING_CLICK, {
                clicked_listing: {
                    ...this.commonVariables(ViewSections.PROPERTY_DETAIL),
                    flow_type: 'alternative_search_results',
                    listing_position: listingPosition,
                },
            });
        }

        trackPOTWCLick(viewSection: Values<typeof ViewSections>): void {
            this.props.trigger(Triggers.CLICK_POTW, {
                ...this.commonVariables(viewSection, this.props.propertyOfTheWeek?.property),
                listing_type: this.props.propertyOfTheWeek?.property.type,
            });
        }

        /**
         * Tracks the user clicking a specific Share button from the SocialShareDropdown.
         */
        trackShareSocialButton(
            shareType: Values<typeof ShareMediums>,
            pageType: Values<typeof PageTypes>,
            triggerName: Values<typeof Triggers> | null,
            propertyData: PropertyData,
        ): void {
            const variables = !triggerName
                ? {
                      ...this.commonVariables(ViewSections.PROPERTY_DETAIL),
                      share_type: shareType,
                  }
                : {
                      // eslint-disable-next-line camelcase
                      logged_in_agent: this.props.loggedInAgent?.externalID,
                      page_type: pageType,
                      name: 'medium',
                      value: shareType,
                      ...(triggerName === Triggers.SHARE_PROPERTY &&
                          this.commonVariables(ViewSections.PROPERTY_DETAIL, propertyData)),
                  };

            this.props.trigger(triggerName || Triggers.SHARE_PROPERTY, variables);
        }

        trackShareTransactions(
            shareType: Values<typeof ShareMediums>,
            pageType: Values<typeof PageTypes>,
        ): void {
            this.props.trigger(Triggers.SHARE_TRANSACTIONS, {
                page_type: pageType,
                value: shareType,
            });
        }

        /**
         * Tracks the user clicking on Map View toggle button
         * @param {Values<typeof ViewSections>} viewSection
         * @param {boolean} toggleState On/Off state of the toggle
         * @param {Values<typeof PageTypes>} pageType pageType
         */
        trackMapViewToggle(
            viewSection: Values<typeof ViewSections>,
            toggleState: boolean,
            pageType: Values<typeof PageTypes> = PageTypes.HOME,
        ): void {
            this.props.trigger(toggleState ? Triggers.MAP_TOGGLE_ON : Triggers.MAP_TOGGLE_OFF, {
                page_type: pageType,
                view_section: viewSection,
                event_unique_id: generateUniqueEventID(),
            });
        }

        trackEmailForRequestFloorPlan(viewSection: Values<typeof ViewSections>): void {
            const property = this.props.property;
            this.props.trigger(Triggers.REQUEST_FLOORPLAN_EMAIL, {
                website_section: BayutCompat.Purpose.textAsVerb(property.purpose),
                ...this.commonVariables(viewSection),
            });
        }

        trackCallForRequestFloorPlan(viewSection: Values<typeof ViewSections>): void {
            const property = this.props.property;
            this.props.trigger(Triggers.REQUEST_FLOORPLAN_PHONE, {
                website_section: BayutCompat.Purpose.textAsVerb(property.purpose),
                ...this.commonVariables(viewSection),
            });
        }

        trackRequestFloorPlan(viewSection: Values<typeof ViewSections>): void {
            const property = this.props.property;
            this.props.trigger(Triggers.REQUEST_FLOORPLAN, {
                website_section: BayutCompat.Purpose.textAsVerb(property.purpose),
                ...this.commonVariables(viewSection),
            });
        }

        trackRequestVideo(viewSection: Values<typeof ViewSections>): void {
            const property = this.props.property;
            this.props.trigger(Triggers.REQUEST_VIDEO, {
                website_section: BayutCompat.Purpose.textAsVerb(property.purpose),
                ...this.commonVariables(viewSection),
            });
        }

        trackCalculatorEnquiry(viewSection: Values<typeof ViewSections>): void {
            this.props.trigger(Triggers.ENQUIRE_NOW_CLICK, {
                ...this.commonVariables(viewSection),
            });
        }

        /**
         * Tracks the user clicking a share agent profile button
         */
        trackShareAgentProfileLink(shareType: Values<typeof ShareMediums>): void {
            this.props.trigger(Triggers.SHARE_PROFILE_LINK, {
                // eslint-disable-next-line camelcase
                logged_in_agent: this.props.loggedInAgent?.externalID,
                page_type: PageTypes.AGENT,
                name: 'medium',
                value: shareType,
            });
        }

        /**
         * Tracks the user clicking a share agent profile button
         */
        trackSharePropertyLink(
            property: PropertyData,
            shareType: Values<typeof ShareMediums>,
            pageType: Values<typeof PageTypes>,
        ): void {
            this.props.trigger(Triggers.SHARE_PROPERTY, {
                // eslint-disable-next-line camelcase
                logged_in_agent: this.props.loggedInAgent?.externalID,
                page_type: pageType,
                name: 'medium',
                value: shareType,
                ...this.commonVariables(ViewSections.SEARCH_RESULTS, property),
            });
        }

        /**
         * Renders the wrapped component and exposes convenience
         * functions to the wrapped component through props.
         */
        render() {
            const props = {
                ...omit(this.props, ['itemReferral']),
                trackSMSAttempt: this.trackSMSAttempt,
                trackChatAttempt: this.trackChatAttempt,
                trackPhoneViewAttempt: this.trackPhoneViewAttempt,
                trackCallView: this.trackCallView,
                trackOvationPhoneView: this.trackOvationPhoneView,
                trackCallLead: this.trackCallLead,
                getSMSVariables: this.getSMSVariables,
                trackSMSLead: this.trackSMSLead,
                trackWhatsAppLead: this.trackWhatsAppLead,
                trackEmailView: this.trackEmailView,
                trackWhatsappView: this.trackWhatsappView,
                trackEmailLead: this.trackEmailLead,
                trackFavoriteToggle: this.trackFavoriteToggle,
                trackListingClick: this.trackListingClick,
                trackAlternativeSearchListingClick: this.trackAlternativeSearchListingClick,
                trackPOTWCLick: this.trackPOTWCLick,
                trackViewAgencyProperties: this.trackViewAgencyProperties,
                trackShareSocialButton: this.trackShareSocialButton,
                trackChatLead: this.trackChatLead,
                trackMapViewToggle: this.trackMapViewToggle,
                trackEmailForRequestFloorPlan: this.trackEmailForRequestFloorPlan,
                trackCallForRequestFloorPlan: this.trackCallForRequestFloorPlan,
                trackRequestFloorPlan: this.trackRequestFloorPlan,
                trackOfferView: this.trackOfferView,
                trackRequestVideo: this.trackRequestVideo,
                trackCalculatorEnquiry: this.trackCalculatorEnquiry,
                trackShareAgentProfileLink: this.trackShareAgentProfileLink,
                trackSharePropertyLink: this.trackSharePropertyLink,
                trackShareTransactions: this.trackShareTransactions,
            } as T;

            return React.createElement(component, props);
        }
    }
    // @ts-expect-error - TS2322 - Type 'ConnectedComponent<ComponentType<ItemReferralProps>, { readonly itemReferral?: ItemReferral | null | undefined; property?: PropertyData | undefined; ad?: PropertyData | undefined; story?: StoryData | ... 1 more ... | undefined; context?: Context<...> | undefined; store?: any; } | { ...; }>' is not assignable
    return connect((state: GlobalState, ownProps: ExternalProps<U>) => ({
        nudges: state.nudges,
        propertyOfTheWeek: state.propertyOfTheWeek.data,
        platform: selectLayout(state).toLowerCase(),
        property: ownProps.property || ownProps.story || ownProps.ad || selectGTMAdData(state),
        userExternalId:
            selectUserID(state) || selectUserExternalID(state) || selectAnonymousSessionID(state),
        loggedInAgent: selectAgentUserProfile(state),
        isProjectPage: CONFIG.runtime.STRAT_ENABLE_PROJECT_PAGE && !!selectProject(state),
        isProjectView: selectIsProjectSearchEnabled(state),
        // @ts-expect-error - TS2345 - Argument of type 'ComponentType<GTMProps>' is not assignable to parameter of type 'AbstractComponent<any, any>'. | TS2345 - Argument of type 'AbstractComponent<Diff<unknown, OvationProps>, any>' is not assignable to parameter of type 'ComponentType<GTMProps>'.
    }))(withItemReferral(withGTMLegacyStats(withGTM(withOvation(GTMLeadTracking)))));
};

export default withGTMLeadTracking;
