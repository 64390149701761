import * as React from 'react';

import type { ChoiceValue } from './types';
import Options from './options';

export type RadioGroupProps<T> = Omit<React.ComponentProps<typeof Options>, 'isSelected'> & {
    selectedValue: T | null | undefined;
    readOnly?: boolean;
};

const RadioGroup = <T extends ChoiceValue>({
    title,
    choices,
    selectedValue,
    onChange,
    compact,
    errorMessage,
    renderChoice,
    choiceAriaLabel,
    leftSideLabel,
    boldLabel,
    autoFilledValues,
    readOnly,
}: RadioGroupProps<T>) => (
    <Options
        title={title}
        choices={choices}
        isSelected={(value) => selectedValue === value}
        autoFilledValues={autoFilledValues}
        onChange={onChange}
        compact={compact}
        errorMessage={errorMessage}
        renderChoice={renderChoice}
        choiceAriaLabel={choiceAriaLabel}
        leftSideLabel={leftSideLabel}
        boldLabel={boldLabel}
        readOnly={readOnly}
    />
);

export default RadioGroup;
