import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import type { Ad } from 'horizontal/types';

import DefaultChoiceBox from './simpleChoiceBox';
import styles from './styles/choiceBoxGroup.cssm';
import type { BasicChoice, ChoiceBoxProps } from './types';

type Props<T extends BasicChoice> = {
    readonly idField?: string;
    readonly className?: string;
    readonly choices: Array<T>;
    readonly selectedChoiceIds: Array<string | number | null>;
    readonly enabledIds?: Array<number>;
    readonly onChoiceClick: (choice: T) => void;
    readonly choiceComponent?: React.ComponentType<ChoiceBoxProps<T>>;
    readonly ad: Ad | null | undefined;
};

const ChoiceBoxGroup = <T extends BasicChoice>({
    idField = 'id',
    className,
    choices,
    // @ts-expect-error - TS2322 - Type '<T extends SimpleChoice>({ isSelected, choice }: ChoiceBoxProps<T>) => JSX.Element' is not assignable to type 'ComponentType<ChoiceBoxProps<T>>'.
    choiceComponent = DefaultChoiceBox,
    onChoiceClick,
    selectedChoiceIds,
    enabledIds,
    ad,
}: Props<T>) => (
    <Flex className={className} column>
        {choices.map((choice) => {
            const isEnabled = enabledIds ? enabledIds.includes(choice[idField]) : true;
            const isSelected = isEnabled ? selectedChoiceIds.includes(choice[idField]) : false;
            const cardClassName = classNames(styles.card, {
                [styles.selectedCard]: isSelected,
                [styles.disabledCard]: !isEnabled,
            });
            return (
                <Flex
                    key={choice[idField]}
                    className={cardClassName}
                    onClick={() => onChoiceClick(choice)}
                >
                    <Flex column stretchWidth>
                        {React.createElement(choiceComponent, {
                            choice,
                            isSelected,
                            ad,
                            isEnabled,
                        })}
                    </Flex>
                </Flex>
            );
        })}
    </Flex>
);

export default ChoiceBoxGroup;
