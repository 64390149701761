import * as React from 'react';
import { Trans } from '@lingui/macro';

import Loadable from 'strat/loadable';

import Flex from './flex';
import LoadingAnimation from './loadingAnimation';
import * as Text from './text';
import InputMessage from './inputMessage';
import styles from './styles/otpInput.cssm';

type Props = {
    readonly length: number;
    readonly value: string;
    readonly onChange: (arg1: string) => void;
    readonly errorMessage?: string | null | undefined;
};

const LoadingOTPInput = () => (
    <div className={styles.inputContainer}>
        <LoadingAnimation className={styles.loadingPlaceholder} />
        <LoadingAnimation className={styles.loadingPlaceholder} />
        <LoadingAnimation className={styles.loadingPlaceholder} />
        <LoadingAnimation className={styles.loadingPlaceholder} />
    </div>
);

const OTPInputErrorFallback = () => (
    <Text.Base className={styles.errorFallback}>
        <Trans>Something went wrong. Please go back to try again.</Trans>
    </Text.Base>
);

const Input = Loadable({
    loader: () => import(/* webpackChunkName: 'otpInput' */ 'react-otp-input'),
    loading: LoadingOTPInput,
    fallback: OTPInputErrorFallback,
    loadingFallback: OTPInputErrorFallback,
});

const OTPInput = ({ length, value, onChange, errorMessage }: Props) => (
    <Flex justifyCenter className={styles.container}>
        <Flex column wrap>
            <Input
                value={value}
                onChange={onChange}
                isInputNum
                numInputs={length}
                shouldAutoFocus
                inputStyle={styles.newLoginDesignInput}
                focusStyle={styles.focused}
                containerStyle={styles.inputContainer}
            />
            {!!errorMessage && <InputMessage isError>{errorMessage}</InputMessage>}
        </Flex>
    </Flex>
);

export default OTPInput;
