import { t } from '@lingui/macro';
import * as React from 'react';
import { TrackChatLead } from '@app/gtm/withGTMLeadTracking';
import { useDialog } from 'strat/dialogs/dialogContext';
import EMPTY_ARRAY from 'strat/empty/array';
import { useI18n } from 'strat/i18n/language';

import {
    ChatMessageType,
    ChatNegotiationState,
    ChatRoomMemberRole,
} from 'horizontal/chat/constants';
import { useChatSDK } from 'horizontal/chat/hooks';
import type { ActionButton, ChatRoom, ChatSendMessageResult } from 'horizontal/chat/types';
import { DialogNames } from 'horizontal/dialogs';
import { useTrackSentChatMessageEvents } from 'horizontal/gtm/leadTracking';

const useActionButtons = (
    chatRoom: ChatRoom | null | undefined,
    setPriceFormVisible: (arg1: boolean) => void,
    trackChatLead: TrackChatLead,
): ActionButton[] => {
    const i18n = useI18n();
    const sdk = useChatSDK();
    const { disabled } = sdk.useRoomDisabledInfo(chatRoom ?? undefined);
    const { ad } = sdk.useRoomContactInfo(chatRoom ?? undefined);
    const getNegotiationActions = sdk.useSendNegotiationMessagesMap();
    const negotiation = sdk.useRoomNegotiationInfo(chatRoom?.xid);

    const [, setDialogVisible] = useDialog(DialogNames.CHAT_ASK_FOR_MORE_DIALOG);

    const isSeller = chatRoom?.myRole !== ChatRoomMemberRole.BUYER;
    const adPrice: number | null | undefined = ad?.extraFields?.price;
    const lastOfferPrice = negotiation.value;
    const negotiationState = negotiation.state;
    const trackSentChatMessageEvents = useTrackSentChatMessageEvents(trackChatLead);

    return React.useMemo((): ActionButton[] => {
        if (disabled) {
            return EMPTY_ARRAY as ActionButton[];
        }

        const onAcceptedClick = async (): Promise<ChatSendMessageResult | undefined> => {
            const actions = await getNegotiationActions();
            const sendMessage = actions[ChatNegotiationState.ACCEPTED];
            if (!sendMessage) {
                return;
            }
            const messageSentResponse = await sendMessage();

            if (messageSentResponse) {
                trackSentChatMessageEvents(messageSentResponse, ChatMessageType.NEGOTIATION);
            }
        };

        const onInitiatedClick = async (): Promise<ChatSendMessageResult | undefined> => {
            const actions = await getNegotiationActions();
            const sendMessage = actions[ChatNegotiationState.INITIATED];
            if (!sendMessage) {
                return;
            }
            const messageSentResponse = await sendMessage();

            if (messageSentResponse) {
                trackSentChatMessageEvents(messageSentResponse, ChatMessageType.NEGOTIATION);
            }
        };

        switch (negotiationState) {
            case ChatNegotiationState.BUYER_MADE_OFFER:
                if (isSeller) {
                    return [
                        {
                            label: t(i18n)`Ask for more`,
                            onClick: () => {
                                setDialogVisible(true, { adPrice, lastOfferPrice });
                            },
                            secondary: true,
                        },
                        {
                            label: t(i18n)`Let's go ahead`,
                            onClick: onAcceptedClick,
                        },
                    ];
                }
                return [
                    {
                        label: t(i18n)`Edit`,
                        onClick: () => {
                            setPriceFormVisible(true);
                        },
                        secondary: true,
                    },
                ];
            case ChatNegotiationState.SELLER_MADE_OFFER:
                if (isSeller) {
                    return EMPTY_ARRAY as ActionButton[];
                }
                return [
                    {
                        label: t(i18n)`Make new offer`,
                        onClick: () => {
                            setPriceFormVisible(true);
                        },
                        secondary: true,
                    },
                    {
                        label: t(i18n)`Let's go ahead`,
                        onClick: onAcceptedClick,
                    },
                ];
            case ChatNegotiationState.OFFER_REJECTED:
                if (isSeller) {
                    return EMPTY_ARRAY as ActionButton[];
                }
                return [
                    {
                        label: t(i18n)`Make new offer`,
                        onClick: () => {
                            setPriceFormVisible(true);
                        },
                        secondary: true,
                    },
                ];
            case ChatNegotiationState.ACCEPTED:
                return [
                    {
                        label: t(i18n)`Let's meet`,
                        onClick: onAcceptedClick,
                    },
                ];
            case ChatNegotiationState.INITIATED:
                if (isSeller) {
                    return [
                        {
                            label: t(i18n)`Request offer`,
                            onClick: onInitiatedClick,
                        },
                    ];
                }
                return EMPTY_ARRAY as ActionButton[];
            default:
                return EMPTY_ARRAY as ActionButton[];
        }
    }, [
        i18n,
        getNegotiationActions,
        isSeller,
        setPriceFormVisible,
        adPrice,
        lastOfferPrice,
        negotiationState,
        setDialogVisible,
        disabled,
        trackSentChatMessageEvents,
    ]);
};

export default useActionButtons;
