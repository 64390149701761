import * as React from 'react';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { ThumbnailSizes } from 'strat/image';
import { useI18n } from 'strat/i18n/language';
import { formatDate } from 'strat/i18n/language/dateTimeFormatter';
import { Link } from 'strat/navigation';
import { RouteNames } from 'strat/routes';
import { Flex, Text, Button } from 'strat/components';
import { StylelessButton } from 'strat/generic';

import { DeliveryType } from 'horizontal/types';
import { CoverPhoto } from 'horizontal/adCard';
import { fetchUserBuyingList } from 'horizontal/userOrders/buyingOrders';
import { useFormattedPrice } from 'horizontal/util';
import type { AdPhoto, DeliveryOrderAd } from 'horizontal/types';
import type { AppDispatch } from 'horizontal/state';

import { useDeliveryOrderStatusDetails } from '../hooks';
import { DeliveryRequestStatus, UserDeliveryOrderStatus } from '../types';
import { updateDeliveryOrderStatus, DeliveryOrderStatusPill } from '../deliveryOrders';

import styles from './styles/buyerOrdersCard.cssm';

type Props = {
    readonly userExternalID: string;
    readonly currentTab: Values<typeof DeliveryRequestStatus> | null | undefined;
    readonly coverPhoto?: AdPhoto;
    readonly id: number;
    readonly reqStatus: string;
    readonly ad: DeliveryOrderAd;
    readonly price: number | null;
    readonly deliveryType?: DeliveryType;
};

const FORMATTER_OPTIONS = {
    year: '2-digit',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
} as const;

const BuyerOrdersCard = ({
    userExternalID,
    currentTab,
    id,
    reqStatus,
    coverPhoto,
    ad,
    price,
    deliveryType = DeliveryType.SERVICE_DELIVERY,
}: Props) => {
    const i18n = useI18n();
    const dispatch = useDispatch<AppDispatch>();
    // display the price at the time the offer was made. If not available, display the ad price or 0
    const displayedPrice = useFormattedPrice(price || ad.extraFields?.price || 0);
    const formattedDate = formatDate(new Date(ad.createdAt * 1000), i18n.locale, FORMATTER_OPTIONS);

    const getNewestBuyingList = React.useCallback(() => {
        dispatch(fetchUserBuyingList({ offersStatus: currentTab }));
    }, [currentTab, dispatch]);

    const markOrderAsDelivered = () => {
        updateDeliveryOrderStatus(
            id,
            UserDeliveryOrderStatus.DELIVERED,
            userExternalID,
            getNewestBuyingList,
        );
    };

    const cancelOrder = () => {
        updateDeliveryOrderStatus(
            id,
            UserDeliveryOrderStatus.CANCELED_BY_BUYER,
            userExternalID,
            getNewestBuyingList,
        );
    };

    const statusDetails = useDeliveryOrderStatusDetails({
        status: reqStatus,
        isSeller: false,
        deliveryType: deliveryType,
        i18n: i18n,
    });

    return (
        <Flex className={styles.buyerOrdersCard}>
            <Flex column className={styles.date} justifyCenter alignCenter>
                <Text.Regular aria-label="Order date">{formattedDate}</Text.Regular>
            </Flex>

            <Flex column fillContainer>
                <Flex className={styles.details} justifySpaceBetween alignCenter>
                    <Link
                        route={RouteNames.AD_DETAILS}
                        params={{ slug: ad.slug, externalID: ad.externalID }}
                    >
                        <Flex className={styles.image}>
                            <CoverPhoto cover={coverPhoto} size={ThumbnailSizes.TINY} lazyLoad />
                            <Flex className={styles.title} aria-label="order title" alignCenter>
                                <Text.Regular bold>{ad.title}</Text.Regular>
                            </Flex>
                        </Flex>
                    </Link>
                    <Text.Base className={styles.detailsPrice} bold>
                        {displayedPrice}
                    </Text.Base>
                    <Text.Base className={styles.detailsDescription}>
                        {statusDetails.description || ''}
                    </Text.Base>
                    {currentTab !== DeliveryRequestStatus.ARCHIVED && (
                        <Button
                            disabled={!statusDetails.enableCancelOrder}
                            onClick={cancelOrder}
                            secondary={statusDetails.enableCancelOrder}
                        >
                            {t(i18n)`Cancel order`}
                        </Button>
                    )}
                </Flex>
                <Flex justifySpaceBetween>
                    <Flex
                        justifySpaceBetween
                        className={styles.status}
                        alignCenter
                        aria-label="order state"
                    >
                        <DeliveryOrderStatusPill
                            orderStatus={reqStatus}
                            title={statusDetails.title}
                            deliveryType={deliveryType}
                        />
                        {!statusDetails.enableCancelOrder &&
                            currentTab !== DeliveryRequestStatus.ARCHIVED &&
                            deliveryType !== DeliveryType.SELF_DELIVERY && (
                                <Text.Regular>
                                    {t(i18n)`Cancel is no longer available for this order.`}
                                </Text.Regular>
                            )}
                    </Flex>
                    {CONFIG.build.ENABLE_SELF_DELIVERY &&
                        currentTab !== DeliveryRequestStatus.ARCHIVED &&
                        reqStatus === UserDeliveryOrderStatus.OUT_FOR_DELIVERY &&
                        deliveryType === DeliveryType.SELF_DELIVERY && (
                            <Flex>
                                <Text.Regular>{t(i18n)`Did you received the item?`}</Text.Regular>
                                <StylelessButton
                                    className={styles.markAsDeliveredButton}
                                    onClick={markOrderAsDelivered}
                                >
                                    {t(i18n)`Mark it as Delivered`}
                                </StylelessButton>
                            </Flex>
                        )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default BuyerOrdersCard;
