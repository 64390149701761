import * as React from 'react';
import { plural, t } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';

/**
 * Properties for {@see BathRoomsLabel}.
 */
type Props = {
    children: string | number;
};

/**
 * renders the amount of bath rooms as a label.
 */
export default (props: Props) => {
    const i18n = useI18n();
    const number = props.children;

    return (
        <>
            {t(i18n)`${plural(number, {
                '=0': '0',
                one: '# Bath',
                two: '# Baths',
                few: '# Baths',
                many: '# Baths',
                other: '# Baths',
            })}`}
        </>
    );
};
