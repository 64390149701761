import type { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import type { Store } from 'redux';
import { selectSessionTokenData } from '@sector-labs/fe-auth-redux';
import HumbuckerFetchInterceptor from '@sector-labs/humbucker-fetch-interceptor';
import { languages } from '@app/branding/languages';

import { createKeycloakCrossWindowListener } from 'strat/auth/keycloak/keycloakCrossWindow';
import {
    isRoutePathWithExternallyManagedSession,
    isKeycloakEnabled,
} from 'strat/auth/keycloak/config';
import {
    initKeycloakSessionRefreshTimer,
    createKeycloakSiteConfigWithCallbacks,
} from 'strat/auth/keycloak';
import { logError } from 'strat/error/log';
import { initSentryBrowserAsync } from 'strat/sentry/initSentryBrowserAsync';
import { RouteConfig } from 'react-true-router';
import { switchRendering } from 'strat/rendering/state';
import type { PageCollection } from 'strat/rendering/types';
import type { DialogsCollection } from 'strat/dialogs/types';
import { setWallpaperTakeover } from 'strat/wallpaper/state';
import { RouteNames } from 'strat/routes';
import { selectLanguage } from 'strat/i18n/language';
import { selectDriftCorrectedClock } from 'strat/clock/selectors';

import 'strat/styles/browser.css';

import App from './app';

/**
 * A browser app serving a website build with the Strat platform.
 */
class BrowserApp {
    /**
     * List of routes available for routing.
     */
    routes: RouteConfig;

    /**
     * Collection of pages made available to routes for rendering.
     */
    pages: PageCollection;

    /**
     * Collection of dialogs available throughout the app.
     */
    dialogs: DialogsCollection;

    /**
     * Component to use to render pages.
     */
    renderer: ComponentType<any>;

    /**
     * Redux store.
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    store: Store;

    /*
     * Strat app
     */
    // @ts-expect-error - TS2564 - Property 'app' has no initializer and is not definitely assigned in the constructor.
    app: App;

    /**
     * Initializes a new instance of {@see BrowserApp}.
     * @param routes A list of routes the server should serve.
     * @param renderer Component that renders a page.
     * @param pages List of pages that a route can render.
     * @param dialogs The dialogs available in the app.
     */
    constructor(
        routes: RouteConfig,
        pages: PageCollection,
        dialogs: DialogsCollection,
        renderer: ComponentType<any>,
    ) {
        this.routes = routes;
        this.pages = pages;
        this.renderer = renderer;
        // @ts-expect-error - TS2339 - Property 'state' does not exist on type 'Window & typeof globalThis'.
        this.store = this.createStore(window.state || {});
        if (!this.store.getState().rendering.enabled) {
            this.store.pauseSubscriptions();
        }
        this.dialogs = dialogs;

        // @ts-expect-error - TS2322 - Type '(request: RequestInfo | URL, options?: RequestInit | undefined) => Promise<Response | undefined>' is not assignable to type '((input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>) & ((input: RequestInfo, init?: RequestInit | undefined) => Promise<...>)'. | TS2345 - Argument of type '((input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>) & ((input: RequestInfo, init?: RequestInit | undefined) => Promise<...>)' is not assignable to parameter of type 'Fetcher'.
        window.fetch = new HumbuckerFetchInterceptor(window.fetch, {
            baseURL: CONFIG.runtime.HUMBUCKER_BASE_URL ? CONFIG.runtime.HUMBUCKER_BASE_URL : null,
            useJSCookies: CONFIG.runtime.HUMBUCKER_ENABLE_JS_COOKIES,
            useCrossSiteJSCookies:
                CONFIG.runtime.HUMBUCKER_ENABLE_JS_COOKIES && !!CONFIG.runtime.HUMBUCKER_BASE_URL,
            useCrossSiteRequests: !!CONFIG.runtime.HUMBUCKER_BASE_URL,
            useCrossSiteSessionStorage: !!CONFIG.runtime.HUMBUCKER_BASE_URL,
            exceptionLogger: (error: Error, message: string | null) =>
                logError({
                    e: error,
                    msg: message || 'Unknown error generated by Humbucker fetch interceptor',
                    context: {
                        source: 'humbucker',
                    },
                }),
        }).fetcher();

        initSentryBrowserAsync();
    }

    /**
     * Creates a new Redux store with the specified
     * initial state.
     * @param initialState The initial state for
     * the newly created
     * @returns A newly created Redux store.
     */
    // @ts-expect-error - TS2314 - Generic type 'Store<S>' requires 1 type argument(s).
    createStore(_initialState: any): Store {
        throw new Error('Implement this');
    }

    /* move the styles from where the server placed them to their correct place in the DOM */
    moveStyles() {
        const linkTags = document.querySelectorAll('[data-preload-type="style"]');
        const head = document.head;
        const insertPosition =
            document.getElementById('browserStyles')?.nextSibling || head?.firstChild;

        if (head && insertPosition) {
            // remove in two steps to prevent flickering
            for (let i = 0; i < linkTags.length; ++i) {
                head.insertBefore(linkTags[i].cloneNode(true), insertPosition);
            }
            for (let i = 0; i < linkTags.length; ++i) {
                if (linkTags[i].parentNode) {
                    linkTags[i].parentNode?.removeChild(linkTags[i]);
                }
            }
        }
    }

    /* move the scripts from where the server placed them to their correct place in the DOM */
    moveScripts() {
        const linkTags = document.querySelectorAll('[data-preload-type="script"]');
        const head = document.head;
        const insertPosition =
            document.getElementById('browserMainScript')?.nextSibling || head?.firstChild;

        if (head && insertPosition) {
            for (let i = 0; i < linkTags.length; ++i) {
                head.insertBefore(linkTags[i], insertPosition);
            }
        }
    }

    /**
     * Runs the browser app.
     */
    run(): void {
        const htmlElement = document;
        if (!htmlElement) {
            throw new Error('Could not find <html> tag to render into.');
        }

        const pageName = this.store.getState().rendering.page;
        const page = this.pages[pageName];

        const catalogs: Record<string, Record<string, string>> = {};

        if (CONFIG.build.LOCALIZED_ROUTES) {
            languages.forEach((language) => {
                catalogs[language.lang] = window.translations[language.lang].messages;
            });
        } else {
            const language = this.store.getState().i18n.language;
            catalogs[language] = window.translations[language].messages;
        }

        window.catalogMessages = catalogs;

        this.app = new App({
            store: this.store,
            routes: this.routes,
            pages: this.pages,
            renderer: this.renderer,
            dialogs: this.dialogs,
        });

        this.app.hydrateContext();

        this.moveStyles();
        this.moveScripts();

        if (page.preload) {
            /* preload async pages before rendering to prevent a brief empty page render */
            page.preload()
                .then(() => {
                    ReactDOM.hydrate(
                        this.app.render(),
                        htmlElement,
                        this.onHydrationFinished.bind(this),
                    );
                })
                .catch((e) => {
                    logError({
                        e: e,
                        msg: 'Page preload failed',
                        context: {
                            name: pageName,
                        },
                    });

                    this.app.pushRoute(RouteNames.INTERNAL_ERROR, { origin: 'pagePreloadFail' });
                });
        } else {
            ReactDOM.hydrate(this.app.render(), htmlElement, this.onHydrationFinished.bind(this));
        }
    }

    /**
     * Ran after hydration finishes.
     */
    onHydrationFinished() {
        const state = this.store.getState();

        if (!state.rendering.enabled) {
            this.app
                // @ts-expect-error - TS2345 - Argument of type 'Location' is not assignable to parameter of type 'string'.
                .route(window.location)
                // @ts-expect-error - TS2339 - Property 'promise' does not exist on type 'RoutingContext'.
                .promise.wait()
                .finally(() => {
                    this.store.dispatch(switchRendering(true));
                    this.store.resumeSubscriptions(true);
                });
        }

        this.app.hydrateRoute();

        if (
            isKeycloakEnabled() &&
            !isRoutePathWithExternallyManagedSession(window.location.pathname)
        ) {
            const languageCode = selectLanguage(state);
            const clock = selectDriftCorrectedClock(state);
            const siteConfig = createKeycloakSiteConfigWithCallbacks(
                { languageCode, clock },
                this.store.dispatch,
            );

            const timer = initKeycloakSessionRefreshTimer(this.store, siteConfig);
            timer.reset(selectSessionTokenData(this.store.getState()));

            createKeycloakCrossWindowListener(this.store, siteConfig);
        }
    }

    setupWallpaperTakeover() {
        // create a hook into the Redux state so external code, such as
        // DFP code snippets can trigger the wallpaper change
        // @ts-expect-error - TS2339 - Property '_setWallpaper' does not exist on type 'Window & typeof globalThis'.
        // eslint-disable-next-line no-underscore-dangle
        window._setWallpaper = (wallpaper: any) => {
            const {
                targetUrl,
                imageUrl,
                webpImageUrl,
                smallImageUrl,
                webpSmallImageUrl,
                title,
                titleColor,
            } = wallpaper;
            this.store.dispatch(
                setWallpaperTakeover(
                    targetUrl,
                    imageUrl,
                    webpImageUrl,
                    smallImageUrl,
                    webpSmallImageUrl,
                    title,
                    titleColor,
                ),
            );
        };

        // this function is to be compatible with the legacy laravel
        // way of doing things.. ideally all DFP banners use the
        // function above instead of this one
        // @ts-expect-error - TS2339 - Property 'replaceHomeWallpaper' does not exist on type 'Window & typeof globalThis'.
        window.replaceHomeWallpaper = (title: any, imageURL: any) => {
            this.store.dispatch(
                setWallpaperTakeover(null, imageURL, imageURL, imageURL, imageURL, title),
            );
        };

        // if the tag ran before this code, it set a value on window
        // @ts-expect-error - TS2339 - Property '_wallpaperTakeover' does not exist on type 'Window & typeof globalThis'.
        // eslint-disable-next-line no-underscore-dangle
        if (window._wallpaperTakeover) {
            // @ts-expect-error - TS2339 - Property '_setWallpaper' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property '_wallpaperTakeover' does not exist on type 'Window & typeof globalThis'.
            // eslint-disable-next-line no-underscore-dangle
            window._setWallpaper(window._wallpaperTakeover);
        }
    }
}

export default BrowserApp;
