import * as React from 'react';
import { useSelector } from 'react-redux';

import { OffScreen } from 'strat/modal';
import { selectPageIsLoading } from 'strat/rendering/selectors';

import styles from './styles/topLoadingBar.cssm';

/*
 * Display the Top Loading Bar.
 */
const TopLoadingBar = () => {
    const pageLoading = useSelector(selectPageIsLoading);

    return (
        <OffScreen name="top-loading-bar">
            {pageLoading && (
                <div className={styles.container}>
                    <div className={styles.bar} />
                </div>
            )}
        </OffScreen>
    );
};

export default TopLoadingBar;
