import React from 'react';
import { Trans } from '@lingui/macro';
import { Flex } from 'strat/components';

import { Header } from 'horizontal/header';
import Footer from 'horizontal/footer';
import TeleSalesToolPage from 'horizontal/McLaren/teleSalesTool/telesalesTool';

const TeleSalesToolHead = () => {
    return (
        <title>
            <Trans>Tele Sales Tool</Trans>
        </title>
    );
};

const RequestDeliveryPage = {
    head: () => <TeleSalesToolHead />,
    body: () => (
        <Flex column stretchHeight fillContainer>
            <Header />
            <Flex fillContainer>
                <TeleSalesToolPage />
            </Flex>
            <Footer />
        </Flex>
    ),
} as const;

export default RequestDeliveryPage;
