import type { SearchJobOptions } from '@sector-labs/fe-search-redux/searchJobOptions';
import { selectSearchSettings } from '@sector-labs/fe-search-redux/state';
import { SearchRequestOrder } from '@sector-labs/fe-search-redux/backend';

import { createRandomSeed } from 'horizontal/search/execution';
import { SearchPageSearchType } from 'horizontal/search/types';
import { selectKeyedSortOptions, SortOptionKey } from 'horizontal/search/sorting';

import type { SearchProps } from '../types';
import getHitsPerPage from '../getHitsPerPage';
import createAdsSearchFacets from '../common/createAdsSearchFacets';

const createAdsSearchSettings = ({
    getState,
    searchRouteParams,
}: SearchProps): Partial<SearchJobOptions> => {
    const state = getState();
    const facets = createAdsSearchFacets({ state, searchRouteParams, withProductFacet: true });

    const sortOptions = selectKeyedSortOptions(state);
    const sorting =
        sortOptions[
            (searchRouteParams?.sortOption?.key as Values<typeof SortOptionKey>) ||
                SortOptionKey.MOST_RELEVANT
        ];
    const sortOption = searchRouteParams.product
        ? {
              ...sortOptions[
                  (searchRouteParams?.sortOption?.key as Values<typeof SortOptionKey>) ||
                      SortOptionKey.MOST_RELEVANT
              ],
              attributes: [
                  /**
                   * this is saying that productScore is the top sorting field when we have
                   * product=all in our route params, if the user selects other sorting
                   * mechanisms then they are applied as secondart, which means that Elite
                   * (currently it is the highest product) is going to be shown first in
                   * our ads followed by Featured. Those Elite/Featured are going to be
                   * then sorted among themselves by whatever sorting the user selected.
                   */
                  { name: 'productScore', order: SearchRequestOrder.DESC },
                  // @ts-expect-error - Property 'attributes' does not exist on type 'SearchRequestSortByRandomOption & { label: string; }'.ts(2339)
                  ...sorting?.attributes,
                  { name: 'timestamp', order: SearchRequestOrder.DESC },
                  { name: 'id', order: SearchRequestOrder.DESC },
              ],
          }
        : { ...sorting };

    const oldSettings = selectSearchSettings(state);
    return {
        hitsPerPage: getHitsPerPage(SearchPageSearchType.PROMOTED_ONLY),
        maxValuesPerFacet: 20,
        activeSlotRule: 'default',
        sort: sortOption,
        // Reset the random seed when on the first page. Like this,
        // the seed stays stable when navigating between pages.
        randomSeed: !searchRouteParams.page ? createRandomSeed() : oldSettings.randomSeed,
        facets: !CONFIG.runtime.DISABLE_SEARCH_FACETING ? facets : [],
        // Do not count more than 200k hits. If there are more,
        // we'll display '200k+ hits'. Setting an upper limit
        // prevents ES from endlessly iterating the hits just
        // to get an exhaustive count.
        exhaustiveNbHits: CONFIG.runtime.ADS_SEARCH_EXHAUSTIVE_NB_HITS || 200000,
        maxPageAllowed: 50,
    };
};

export default createAdsSearchSettings;
