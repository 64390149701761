import * as React from 'react';
import isNil from 'lodash/isNil';
import {
    connectOneToKOrMore,
    connectObjectRefinementList,
    connectMenu,
    connectRange,
} from '@sector-labs/fe-search-redux/connectors';
import { isEqual } from 'lodash';

import { ChipList } from 'strat/generic';
import { useI18n } from 'strat/i18n/language';
import { defaultRenderSingleChip } from 'strat/generic/chipList';
import { Props as ChipProps } from 'strat/generic/chip';
import FilterLink from 'strat/search/filterLink';

type Props<T extends any = string | number> = {
    refine: (value: Array<T> | null) => Promise<void>;
    currentRefinement: Array<T> | null;
    filter: any;
    singleValue?: boolean;
    range?: boolean;
    choiceOptions?: any;
    onChange?: (value: Array<T>) => void;
    filterClassName?: string;
    chipClassName?: string;
    selectedChipClassName?: string;
    icon?: React.ReactNode;
    ariaLabel?: string;
    /**
     * Used for TruValue filters like Beds or Baths where the single value is still
     * an array and the singleValue prop is used to define filters like
     * Completion Status or Rent Frequency which have only one value (not an array)
     */
    clearOnRefine?: boolean;
    filterChoices?: Array<any>;
    deselectOnSecondClick?: boolean;
    checkSelectedChip?: (selectedValue?: any, chipValue?: any) => boolean;
    getMultiRefinementValue?: (value: any, choices: Array<any>) => any;
    /**
     * Used specifically for SEO purposes, so filter pages can be indexable
     */
    withFilterLinks?: boolean;
};

const FilterChips = <T extends any = string | number>({
    icon,
    filter,
    refine,
    ariaLabel,
    filterChoices,
    currentRefinement,
    checkSelectedChip,
    filterClassName,
    getMultiRefinementValue,
    choiceOptions = {},
    onChange = () => {},
    singleValue = false,
    range = false,
    clearOnRefine = false,
    deselectOnSecondClick = false,
    withFilterLinks = false,
    ...props
}: Props<T>) => {
    const i18n = useI18n();
    const choices = filterChoices || filter.choices(i18n, choiceOptions);

    const getValue = () => {
        return currentRefinement || (singleValue ? null : []);
    };

    const checkIfChipIsSelected = (chipValue: any) => {
        if (checkSelectedChip) {
            return checkSelectedChip(getValue(), chipValue);
        }

        return singleValue || range ? getValue() === chipValue : !!getValue()?.includes(chipValue);
    };

    const getNewMultiValueRefinement = (clickedValue: any) => {
        const newRefinement = [...(currentRefinement || [])];

        const foundIndex = newRefinement.findIndex((existingValue) =>
            isEqual(existingValue, clickedValue),
        );

        // If the clicked value wasn't in the current refinement,
        // it means it is newly selected, therefore we add it
        if (foundIndex === -1) {
            newRefinement.push(clickedValue);
            return newRefinement;
        }

        // If the clicked value was already in the current refinement,
        // it means it was deselected, therefore we remove it
        newRefinement.splice(foundIndex, 1);
        return newRefinement;
    };

    const refineMultiValue = (clickedValue: any) => {
        if (!refine) {
            return;
        }

        const clickedFilterValue = getMultiRefinementValue
            ? getMultiRefinementValue(clickedValue, choices)
            : // @ts-expect-error - TS7006 - Parameter 'cat' implicitly has an 'any' type.
              choices.find((cat) => cat.value.toString() === clickedValue.toString());

        if (clearOnRefine) {
            refine([]);
        }

        refine(!isNil(clickedFilterValue) ? [clickedFilterValue.value] : []).then(() => {
            // When refining with an empty array, the filter values are reset
            const newRefinement = !isNil(clickedFilterValue)
                ? getNewMultiValueRefinement(clickedFilterValue.value)
                : [];

            onChange(newRefinement);
        });
    };

    const refineSingleValue = (clickedValue: any) => {
        if (deselectOnSecondClick && checkIfChipIsSelected(clickedValue)) {
            refine(null);
            return;
        }

        refine(clickedValue);
        onChange(clickedValue);
    };

    const renderFilterLinkChip =
        <T extends any>(filter: any) =>
        (chipProps: ChipProps<T>) => (
            <FilterLink
                filterValue={filter}
                filterChoice={chipProps.chipData}
                key={`filter-link-${chipProps.chipData?.value}`}
            >
                {defaultRenderSingleChip(chipProps)}
            </FilterLink>
        );
    return (
        <ChipList
            {...props}
            {...(withFilterLinks ? { renderSingleChip: renderFilterLinkChip(filter) } : {})}
            containerClassName={filterClassName}
            selectedChips={currentRefinement || []}
            chipsData={choices}
            onChipClick={(chipValue) => {
                if (singleValue || range) {
                    refineSingleValue(chipValue);
                } else {
                    refineMultiValue(chipValue);
                }
            }}
            selectedChipIcon={!singleValue && !clearOnRefine && icon}
            checkIfChipIsSelected={checkIfChipIsSelected}
            filter={filter}
            ariaLabel={ariaLabel}
        />
    );
};

type ConnectedFilterProps = Props & {
    refineNonNumericValues?: boolean;
    attributeName: string;
};

const connectorFromProps = ({
    singleValue,
    range,
    refineNonNumericValues,
}: ConnectedFilterProps) => {
    if (range) {
        return connectRange;
    }

    if (singleValue) {
        return connectMenu;
    }

    if (refineNonNumericValues) {
        return connectObjectRefinementList;
    }

    return connectOneToKOrMore;
};

const ConnectedFilter = (props: ConnectedFilterProps) => {
    const connector = connectorFromProps(props);

    const Filter = connector(FilterChips);

    return <Filter {...props} />;
};

export default ConnectedFilter;
export { FilterChips as FilterChipsComponent };
