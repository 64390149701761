import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'strat/components';

import type { ProductPackageOffer } from 'horizontal/types';

import PackageOfferCard from './packageOfferCard';
import styles from './styles/packageOffers.cssm';

type Props = {
    readonly offers: Array<ProductPackageOffer>;
};

const PackageOffers = ({ offers }: Props) => {
    return (
        <Flex className={classNames(styles.container, styles.containerRedesign)}>
            {offers.map((offer) => (
                <PackageOfferCard offer={offer} key={offer.packageOfferID} />
            ))}
        </Flex>
    );
};

export default PackageOffers;
