import { StratAPI } from 'strat/api';
import { CashMyCarFormPayload } from 'strat/api/types';
import { RouteNames } from 'strat/routes';
import type { FormikValues } from 'formik';
import { HistoryRouter } from 'react-true-router';

import { VerticalsRouteClass } from 'horizontal/routes';

const useOnSubmit =
    (showCashMyCarForm: boolean, router: HistoryRouter) =>
    (values: FormikValues, { setSubmitting }: any) => {
        values['type'] = showCashMyCarForm ? 'cash_my_car' : 'sell_my_car';
        if (showCashMyCarForm) {
            values['askingPrice'] = undefined;
        }
        new StratAPI()
            .sendCashMyCarForm(values as CashMyCarFormPayload)
            .then((response) => {
                setSubmitting(false);
                router.pushRoute(RouteNames.HORIZONTAL_VERTICALS, {
                    vertical: VerticalsRouteClass.verticals.CARS,
                    requestID: response.data.requestID,
                });
            })
            .catch(() => router.pushRoute(RouteNames.INTERNAL_ERROR));
    };

export default useOnSubmit;
