import { AlgoliaSearchBackend } from '@sector-labs/fe-search-redux/backend/algolia';

import fetchListingsFromSearchIndex from './fetchListingsFromSearchIndex';

const fetchListings = (
    indexName: string,
    attribute: string,
    uniqueAttributes: Array<string>,
): Promise<any> => {
    const configuredBackend = new AlgoliaSearchBackend({
        appId: CONFIG.build.ALGOLIA_APP_ID,
        apiKey: CONFIG.build.ALGOLIA_SEARCH_API_KEY,
    });

    return fetchListingsFromSearchIndex(configuredBackend, indexName, attribute, uniqueAttributes);
};

const fetchListingsByExternalID = (indexName: string, externalIDs: Array<string>): Promise<any> =>
    fetchListings(indexName, 'externalID', externalIDs);

const fetchListingsByObjectID = (indexName: string, objectIDs: Array<string>): Promise<any> =>
    fetchListings(indexName, 'objectID', objectIDs);

export { fetchListingsByExternalID, fetchListingsByObjectID };
