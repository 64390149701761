import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/loadingSpinner.cssm';

type Props = {
    readonly className?: string;
    dark?: boolean;
    delayWithOpacity?: boolean;
    delayWithSize?: boolean;
};

const LoadingSpinner = ({ className, dark, delayWithOpacity, delayWithSize }: Props) => (
    <div
        className={classNames({
            [styles.delayWithOpacity]: delayWithOpacity,
            [styles.delayWithSize]: delayWithSize,
        })}
    >
        <div
            className={classNames(styles.spinner, className, {
                [styles.dark]: dark,
            })}
            aria-label="Loading spinner"
        />
    </div>
);

export default LoadingSpinner;
