import { RefinementFilter, FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { AgencyType } from 'strat/property/types';

export const getAgencySearchFilters = (
    filters: FilterCollection,
    type: Values<typeof AgencyType> = AgencyType.REAL_ESTATE_AGENCY,
) => {
    if (CONFIG.build.STRAT_ENABLE_AGENCY_TYPE_FILTER) {
        // Did a deep copy, because it can effect the filters of other SearchJobs
        const newFilters = filters.copy();
        newFilters.refine(
            new RefinementFilter({
                attribute: 'type',
                value: [type],
            }),
        );
        return newFilters;
    }
    return filters;
};
