import type { BirthDateType } from './datePicker';

const FEBRUARY = 2;

const convertEpochTimeToDateParts = (timestampInSeconds?: number | null) => {
    if (!timestampInSeconds) {
        return null;
    }

    // the backend returns UNIX/epoch time which is in seconds
    // the frontend Date function takes the time in milliseconds
    // this is why we multiply by 1000.
    const date = new Date(timestampInSeconds * 1000);
    return {
        day: date.getDate(),
        // we want to display the correct number for the user
        // Date returns months as values between 0 & 11, this
        // is why we need to add 1 for the value so that in
        // in our dropdown we don't show incorrect value
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    };
};

type DatePartsType = {
    day: number;
    month: number;
    year: number;
};

const convertDatePartsToEpochTime = (dateParts?: DatePartsType | null) => {
    if (!dateParts) {
        return null;
    }
    // 1- Backend expects date to be UNIX timestamp as a string.
    // 2- Date.UTC returns milliseconds but our backend wants
    // UNIX/epoch which is in seconds, this is why we divide by 1000
    // 3- the date should be sent as universal time with
    // disregard to timezones.
    // 4- Months start from 0, so if month 1 is selected by user
    // then it is mapped to 0 (Jan is 0, Dec is 11)
    return `${Date.UTC(dateParts.year, dateParts.month - 1, dateParts.day, 0, 0, 0) / 1000}`;
};

const isLeapYear = (year: number): boolean => year % 4 === 0 && year % 100 !== 0;

const areDatePartsValid = (
    day: number | null,
    month: number | null,
    year: number | null,
): boolean => {
    if (!day || !month || !year) {
        return false;
    }
    if (month === FEBRUARY) {
        if (isLeapYear(year)) {
            return day <= 29;
        }

        return day <= 28;
    }

    const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
    if (monthsWith31Days.includes(month)) {
        return day <= 31;
    }

    return day <= 30;
};

const areAgePartsInvalid = (date: BirthDateType) => {
    if (
        !date ||
        !(date.year && date.month && date.day) ||
        typeof date.year !== 'number' ||
        typeof date.month !== 'number'
    ) {
        return true;
    }
    return false;
};

const isMinimumAgeSatisfied = (date: BirthDateType, acceptableAgeMinimum = 13) => {
    if (areAgePartsInvalid(date)) {
        return false;
    }
    const currentDate = new Date();

    if (currentDate.getFullYear() - Number(date.year) < acceptableAgeMinimum) {
        return false;
    }

    // if difference between current year & the year selected by the user is
    // equal to 'acceptableAgeMinimum' then we will need to check the months
    // and days to verify they selected an age that is greater than 13.
    // since you can still select an date that has 'acceptableAgeMinimum'
    // 13 (if acceptableAgeMinimum = 13) years difference, but when it comes to
    // calculating months and days it will eventually be under 12
    if (currentDate.getFullYear() - Number(date.year) === acceptableAgeMinimum) {
        if (currentDate.getMonth() + 1 < Number(date.month)) {
            return false;
        }

        if (currentDate.getMonth() + 1 === Number(date.month)) {
            if (currentDate.getDate() < Number(date.day)) {
                return false;
            }
        }
    }

    return true;
};

export {
    convertEpochTimeToDateParts,
    convertDatePartsToEpochTime,
    areDatePartsValid,
    isMinimumAgeSatisfied,
};
