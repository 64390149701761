import * as React from 'react';
import { useSelector } from 'react-redux';
import { GTMLeadTrackingProps, ViewSections, withGTMLeadTracking } from 'strat/gtm';
import classNames from 'classnames';
import { Button } from 'strat/components';
import { useDialog } from 'strat/dialogs/dialogContext';

import { DialogNames } from 'horizontal/dialogs';
import { useOnCallButtonClick, useOnSMSButtonClick } from 'horizontal/adDetails';
import { PhoneViewChatNotifier } from 'horizontal/chat/widgets/large';
import { ChatPhoneViewType } from 'horizontal/chat/constants';
import { useFetchAdContactInfo } from 'horizontal/ad';
import { useEnsureHasActiveUser } from 'horizontal/user';
import { selectShouldShowPhoneNumberByCategory } from 'horizontal/categories/selectors';
import { LiteAd } from 'horizontal/types';
import { GlobalState } from 'horizontal/state';
import { PhoneNumberLinkType } from 'horizontal/contact';

import styles from './styles/contactButtons.cssm';

type Props = GTMLeadTrackingProps & {
    readonly ad: LiteAd;
    readonly viewSection: Values<typeof ViewSections>;
    readonly phoneNumberLinkType: Values<typeof PhoneNumberLinkType>;
    readonly text: string;
    readonly renderIcon: (className: string) => React.ReactElement;
    readonly fullWidth?: boolean;
    readonly className?: string;
    readonly secondary?: boolean;
};

const CallCTA = ({
    ad,
    viewSection,
    phoneNumberLinkType,
    text,
    renderIcon,
    fullWidth,
    className,
    secondary,
    trackPhoneViewAttempt,
    trackCallView,
    trackCallLead,
    trackSMSLead,
    trackSMSAttempt,
}: Props) => {
    const [isRedirectToPhoneURL, setIsRedirectToPhoneURL] = React.useState(false);
    const [clickedCount, setClickedCount] = React.useState(0);
    const [, setInternalErrorDialogVisible] = useDialog(DialogNames.INTERNAL_ERROR_DIALOG);

    const isCallButton = phoneNumberLinkType === PhoneNumberLinkType.CALL;
    const categoryExternalID = ad.category[ad.category.length - 1].externalID;
    const isPhoneNumberPublic: boolean = useSelector((state: GlobalState) =>
        selectShouldShowPhoneNumberByCategory(state, categoryExternalID),
    );

    const { contactInfo, loading, fetchContactInfo, isError } = useFetchAdContactInfo(
        ad.externalID,
    );
    const callPhoneNumber = contactInfo?.proxyMobile || contactInfo?.mobile;
    const smsPhoneNumber = contactInfo?.mobile;
    const phoneNumber = isCallButton ? callPhoneNumber : smsPhoneNumber;
    const contactSeller = () => {
        fetchContactInfo();
        setIsRedirectToPhoneURL(true);
        setClickedCount((lastValue) => lastValue + 1);
    };

    const guardedContactSeller = useEnsureHasActiveUser({
        onDone: contactSeller,
        onLoginDialogOpen: () => {
            isCallButton ? trackPhoneViewAttempt(viewSection) : trackSMSAttempt(viewSection);
        },
    });
    const onCallClick = isPhoneNumberPublic ? contactSeller : guardedContactSeller;
    const onCallButtonClick = useOnCallButtonClick({
        ad,
        viewSection,
        trackCallLead,
        trackCallView,
        shouldOpenCTADialog: true,
    });
    const onSMSButtonClick = useOnSMSButtonClick({
        ad,
        viewSection,
        trackSMSLead,
        shouldOpenCTADialog: true,
    });

    React.useEffect(() => {
        if (isError) {
            setInternalErrorDialogVisible(true, {
                hideGoToHomeButton: true,
            });
            return;
        }

        if (!loading && phoneNumber && isRedirectToPhoneURL) {
            if (isCallButton) {
                onCallButtonClick(phoneNumber);
            } else {
                onSMSButtonClick(phoneNumber);
            }
            setIsRedirectToPhoneURL(false);
        }
    }, [
        loading,
        phoneNumber,
        isRedirectToPhoneURL,
        isCallButton,
        onCallButtonClick,
        onSMSButtonClick,
        isError,
        ad,
        setInternalErrorDialogVisible,
    ]);

    return (
        <>
            {ad.userExternalID && (
                <PhoneViewChatNotifier
                    adXID={ad.externalID}
                    linkType={isCallButton ? ChatPhoneViewType.CALL : ChatPhoneViewType.SMS}
                    counter={phoneNumber ? clickedCount : 0}
                />
            )}
            <div
                className={classNames(styles.buttonWrapper, { [styles.fullWidth]: fullWidth })}
                onClick={onCallClick}
                aria-label={isCallButton ? 'Call' : 'SMS'}
            >
                <Button
                    stretch
                    secondary={secondary}
                    className={className}
                    small
                    smallIcon
                    loading={loading}
                    renderIcon={renderIcon}
                >
                    {text}
                </Button>
            </div>
        </>
    );
};

export default withGTMLeadTracking<Props, LiteAd>(CallCTA);
