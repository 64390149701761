//@ts-nocheck
import { fx } from 'money';
import isNil from 'lodash/isNil';

/**
 * Converts a price from a given currency to another given currency,
 * using some given exchange rates.
 * @param price The price to convert.
 * @param from The currency from which to convert.
 * @param to The currency to which to convert.
 * @param exchangeRates The exchange rates to be used when converting.
 */
export default ({ price, from, to, exchangeRates }) => {
    if (isNil(price)) {
        return null;
    }

    if (from === to) {
        return price;
    }

    fx.rates = exchangeRates.rates;
    fx.base = exchangeRates.base;

    // some magic here to convert the price to a fixed point,
    // without any decimals... toFixed() returns a string so
    // we have to convert it to a number again
    return Number(fx(price).from(from).to(to));
};
