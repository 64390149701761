import type { OvationCallbackProps } from 'strat/ovation/withOvation';

import useTrackSearchImpressions from './useTrackSearchImpressions';

/**
 * Tracks search impressions for HOME page.
 */
const useTrackHomeImpressions = (ovation: OvationCallbackProps) => {
    useTrackSearchImpressions(ovation);
};

export default useTrackHomeImpressions;
