import * as React from 'react';
import { Trans } from '@lingui/macro';
import settings from '@app/branding/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { setCampaign } from 'strat/campaign/state';
import { useRegisterDialog } from 'strat/auth';
import { trigger, Triggers } from 'strat/gtm';
import { DialogScreen } from 'strat/auth/keycloak/types';
import { selectUserRoles, useActiveUser } from 'strat/user/session';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { selectCurrency } from 'strat/i18n/money';
import Currency from 'strat/i18n/money/currency';
import { KeycloakUser } from 'strat/auth/keycloak/user';
import { Button, Dialog, Flex, Text } from 'strat/components';
import LazyLoad from 'react-lazyload';
import { isAgent } from 'strat/user/roles';

import walletTopUpCampaignLogo from 'horizontal/assets/images/walletTopUpCampaignLogo.png';
import EditProfileSection from 'horizontal/user/editProfileSection';

import WalletTopUpSuccessToast from './successToast';
import { selectIsWalletTopUpCampaignActive } from './selectors';
import styles from './walletTopUpCampaign.cssm';

type DialogDescriptionProps = {
    isCreateAccountFlow: boolean;
    brandName: string;
};

const DialogDescription = ({ isCreateAccountFlow, brandName }: DialogDescriptionProps) => {
    if (isCreateAccountFlow) {
        return (
            <>
                <div>
                    <Text.Regular bold>
                        <Trans>Create your account using your phone number</Trans>
                    </Text.Regular>
                </div>
                <Text.Regular>
                    <Trans>and receive the gift in your {brandName} Wallet.</Trans>
                </Text.Regular>
            </>
        );
    }
    return (
        <>
            <Text.Regular>
                <Trans>
                    Become more reachable to buyers by{' '}
                    <Text.Regular bold>adding your phone number to your account</Text.Regular> and
                    receive the gift in your {brandName} Wallet.
                </Trans>
            </Text.Regular>
        </>
    );
};

const WalletTopUpCampaign = () => {
    const i18n = useI18n();
    const dispatch = useDispatch();
    const [, setShowRegisterDialog] = useRegisterDialog();
    const activeUser = useActiveUser();
    const router = useRouter();
    const brandName = settings.getBrandName(i18n);
    const shouldDisplayCampaignDialog = useSelector(selectIsWalletTopUpCampaignActive);
    const [isDialogVisible, setIsDialogVisible] = React.useState(shouldDisplayCampaignDialog);
    const { baseCurrency } = useSelector(selectCurrency);
    const userRoles = useSelector(selectUserRoles);
    const isAgentUser = isAgent(userRoles);

    const [isToastVisible, setIsToastVisible] = React.useState<boolean>(false);
    const onDismiss = (isRejected = false) => {
        if (isRejected) {
            trigger(Triggers.CLICK_TOPUP_CAMPAIGN_REJECT);
        }
        setIsDialogVisible(false);
        dispatch(setCampaign(null));
    };

    const onConfirmCreateNewAccount = () => {
        trigger(Triggers.CLICK_TOPUP_CAMPAIGN_ACCEPT);
        setShowRegisterDialog(true, {
            name: DialogScreen.PHONE_REGISTER,
            disableEmailLink: true,
            onDone: (activeUser: KeycloakUser) => {
                if (
                    !isAgentUser &&
                    shouldDisplayCampaignDialog &&
                    activeUser &&
                    activeUser.phoneNumber
                ) {
                    setIsToastVisible(true);
                }
                onDismiss();
            },
        });
    };

    const onConfirmAddPhoneNumber = () => {
        trigger(Triggers.CLICK_TOPUP_CAMPAIGN_ACCEPT, {
            user_id: activeUser?.externalID,
        });
        setIsDialogVisible(false);
        router.pushRoute(RouteNames.EDIT_PROFILE, { section: EditProfileSection.INFO });
    };
    return (
        <LazyLoad>
            {isToastVisible && (
                <WalletTopUpSuccessToast
                    isCreateAccountFlow={true}
                    setToastVisible={setIsToastVisible}
                />
            )}
            <Dialog
                visible={isDialogVisible && !isAgentUser}
                onVisibilityChanged={setIsDialogVisible}
                withCloseButton={true}
            >
                <Flex column justifyCenter alignCenter>
                    <div className={styles.container}>
                        <img
                            src={walletTopUpCampaignLogo}
                            alt={'wallet top up'}
                            className={styles.image}
                        />

                        <div className={styles.title}>
                            <Text.XLarge bold>
                                <Trans>
                                    Receive a gift of{' '}
                                    {CONFIG.runtime.STRAT_WALLET_TOP_UP_CAMPAIGN_GIFT_VALUE}{' '}
                                    {Currency.symbol(i18n, baseCurrency)}
                                </Trans>
                            </Text.XLarge>
                        </div>

                        <DialogDescription
                            isCreateAccountFlow={!activeUser}
                            brandName={brandName}
                        ></DialogDescription>
                    </div>
                </Flex>
                <Flex className={styles.buttonsContainer} justifyCenter>
                    <Button stretch secondary onClick={() => onDismiss(true)}>
                        <Text.Regular>
                            <Trans>Maybe later</Trans>
                        </Text.Regular>
                    </Button>
                    <Button
                        stretch
                        onClick={!activeUser ? onConfirmCreateNewAccount : onConfirmAddPhoneNumber}
                    >
                        <Text.Regular>
                            {!activeUser ? <Trans>Create Account</Trans> : <Trans>Add phone</Trans>}
                        </Text.Regular>
                    </Button>
                </Flex>
            </Dialog>
        </LazyLoad>
    );
};

export default WalletTopUpCampaign;
