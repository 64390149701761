import { t } from '@lingui/macro';
import * as React from 'react';
import type { FormikTouched, FormikErrors, FormikValues } from 'formik';
import { useI18n } from 'strat/i18n/language';
import { Input } from 'strat/components';
import { LoadingSpinner } from 'strat/loadable';
import EMPTY_ARRAY from 'strat/empty/array';

import { ContactInfoFields } from 'horizontal/fields/commonPostingFields';

import styles from './styles/whatsappPhoneNumberField.cssm';

type Props = {
    readonly values: FormikValues;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly touched: FormikTouched<FormikValues>;
    readonly errors: FormikErrors<FormikValues>;
    readonly title?: string | null;
    readonly isWhatsappLoading: boolean;
};

const WhatsappPhoneNumberField = ({
    setFieldValue,
    values,
    handleBlur,
    touched,
    errors,
    title,
    isWhatsappLoading = true,
}: Props) => {
    const i18n = useI18n();
    const name = ContactInfoFields.whatsappContactPhoneNumber.attribute;

    const whatsappPhoneNumber = values[name];

    const onChange = (value: string) => {
        if (!value.length) {
            setFieldValue(name, EMPTY_ARRAY);
            return;
        }
        setFieldValue(name, [value]);
    };

    return (
        <div>
            {isWhatsappLoading ? (
                <LoadingSpinner className={styles.spinner} />
            ) : (
                <Input
                    type="text"
                    name={name}
                    title={title || ''}
                    placeholder={t(i18n)`WhatsApp`}
                    value={whatsappPhoneNumber}
                    onBlur={handleBlur}
                    errorMessage={touched[name] && errors[name]}
                    accepted={touched[name] && !errors[name]}
                    onChange={onChange}
                    fixUnitPosition
                    lightPlaceholder
                />
            )}
        </div>
    );
};

export default WhatsappPhoneNumberField;
