import * as React from 'react';
import { useIsStratCreditUser } from 'strat/user';

import IconLogoMini from '@app/assets/icons/iconLogoMini.svg';
import { useIsProAgent, useIsProOwnerAgent } from 'horizontal/agent/state';

import HomeLink from './homeLink';
import AgencyPortalLink from './agencyPortalLink';
import useIsInAgencyPortal from './useIsInAgencyPortal';
import styles from './styles/switchPortalLink.cssm';

// @ts-expect-error - TS2322 - Type '{ alt: string; className: string; }' is not assignable to type 'IntrinsicAttributes & SVGAttributes<SVGElement> & { children?: ReactNode; }'.
const renderLeft = () => <IconLogoMini alt={'Switch to pro'} className={styles.logo} />;

const SwitchPortalLink = ({ onClick }: { onClick: (arg1: React.MouseEvent<any>) => void }) => {
    const isInAgencyPortal = useIsInAgencyPortal();
    const isProAgent = useIsProAgent();
    const isProAgencyOwner = useIsProOwnerAgent();
    const isStratCreditUser = useIsStratCreditUser();
    const canAccessAgencyPortal =
        isStratCreditUser || CONFIG.build.ENABLE_STRAT_AGENCY_PORTAL_FOR_AGENT
            ? isProAgent
            : isProAgencyOwner;

    if (!CONFIG.build.ENABLE_OLX_AGENCY_PORTAL || !canAccessAgencyPortal) {
        return null;
    }

    return (
        <div className={styles.container}>
            {isInAgencyPortal ? (
                <HomeLink onClick={onClick} renderLeft={renderLeft} />
            ) : (
                <AgencyPortalLink onClick={onClick} renderLeft={renderLeft} />
            )}
        </div>
    );
};

export default SwitchPortalLink;
