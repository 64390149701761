import * as React from 'react';
import classNames from 'classnames';

import IconArrowRight from '@app/assets/icons/iconArrowRight.svg';

import Flex from './flex';
import styles from './styles/listEntry.cssm';

const renderArrow = () => <IconArrowRight className={styles.arrowRight} />;

type Props = {
    readonly className?: string;
    readonly onClick?: () => void;
    readonly children: React.ReactNode;
    readonly renderRight?: (() => React.ReactElement | null | undefined) | null;
    readonly renderLeft?: (() => React.ReactElement | null | undefined) | null;
    readonly borderless?: boolean;
    readonly hoverable?: boolean;
    readonly ariaLabel?: string;
    readonly smallContainer?: boolean;
    readonly borderClass?: string;
};

const ListEntry = ({
    onClick,
    children,
    renderLeft,

    // $FlowFixMe
    renderRight = renderArrow,

    borderless,
    borderClass,
    hoverable,
    ariaLabel,
    smallContainer,
}: Props) => (
    <Flex
        alignCenter
        className={classNames(styles.entry, borderClass, {
            [styles.bordered]: !borderless && !borderClass,
            [styles.hoverable]: hoverable,
            [styles.smallContainer]: smallContainer,
        })}
        onClick={onClick}
        aria-label={ariaLabel}
    >
        {renderLeft && (
            <Flex alignCenter className={styles.left}>
                {renderLeft()}
            </Flex>
        )}
        {children}
        {renderRight && (
            <Flex alignCenter className={styles.rightIconContainer}>
                {renderRight()}
            </Flex>
        )}
    </Flex>
);

export default ListEntry;
