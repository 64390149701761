import * as React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Text } from 'strat/components';

import VerifiedProfileIcon from './verifiedProfileIcon';
import styles from './styles/verifiedProfileBadge.cssm';

type Props = {
    readonly isAgency?: boolean;
    readonly shortText?: boolean;
    readonly large?: boolean;
    readonly stretch?: boolean;
};

const VerifiedProfileBadge = ({ isAgency, shortText, large, stretch }: Props) => {
    return (
        <span
            className={classNames(styles.container, {
                [styles.stretch]: stretch,
            })}
        >
            <VerifiedProfileIcon />
            <Text.Base bold className={large ? styles.largeText : styles.text}>
                {shortText ? (
                    <Trans>Verified</Trans>
                ) : isAgency ? (
                    <Trans>Verified Business</Trans>
                ) : (
                    <Trans>Verified User</Trans>
                )}
            </Text.Base>
        </span>
    );
};

export default VerifiedProfileBadge;
