import { t } from '@lingui/macro';
import { plural } from '@lingui/macro';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'strat/i18n/language';
import { Text, Flex } from 'strat/components';

import { useHumanizedPeriod } from 'horizontal/util';
import { FullAd, ProductPurchase } from 'horizontal/types';
import { useShouldShowBTTForFeaturedAd } from '@app/packages';

import styles from './styles/productChoiceBox.cssm';

type Props = {
    ad: FullAd | null | undefined;
    choice: ProductPurchase;
    selected: boolean;
    onClick: () => void;
    icon: string;
};

const ProductPurchaseChoiceBox = ({ ad, choice, selected, onClick, icon }: Props) => {
    const i18n = useI18n();
    const shouldShowBTTForFeaturedAd = useShouldShowBTTForFeaturedAd(
        choice.productPackageOffer.productPackage,
        ad,
    );
    const humanizedPeriodText = useHumanizedPeriod(
        new Date(choice.expiresAt).getTime() - Date.now(),
    );

    return (
        <div
            className={classNames(styles.container, { [styles.selected]: selected })}
            onClick={onClick}
        >
            <Flex alignCenter className={styles.content}>
                <div
                    className={classNames(styles.radioCheck, {
                        [styles.radioCheckSelected]: selected,
                    })}
                />
                <Flex column>
                    <Text.Large bold>{choice.productPackageOffer.productPackage.name}</Text.Large>
                    <Text.Regular className={styles.productAmount} aria-label="Product amount">
                        {choice.available} {t(i18n)`left`}
                    </Text.Regular>
                    {shouldShowBTTForFeaturedAd ? (
                        <Text.Regular bold className={styles.consumptionCreditsProposition}>
                            {t(i18n)`${plural(choice.productConsumptionWeight || 1, {
                                one: '# credit',
                                other: '# credits',
                            })}`}{' '}
                            {t(i18n)`to be used to boost the ad`}
                        </Text.Regular>
                    ) : null}
                    <Text.Regular>{t(
                        i18n,
                    )`Package expires in ${humanizedPeriodText}`}</Text.Regular>
                </Flex>
                <img src={icon} className={styles.icon} />
            </Flex>
        </div>
    );
};

export default ProductPurchaseChoiceBox;
