import { t } from '@lingui/macro';
import * as React from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from 'strat/i18n/language';
import { Flex, Text } from 'strat/components';
import type { AnyAdData } from 'strat/types';
import PaymentPercentagesTimeline from 'strat/searchComponents/project/paymentPlans/paymentPercentagesTimeline';

import usePaymentPlanBreakdown from './usePaymentPlanBreakdown';
import { selectProjectPaymentPlanSummaries } from './selectors';
import styles from './styles/paymentPlanBreakdown.cssm';

type Props = {
    property: AnyAdData;
};

const PaymentPlanBreakdown = ({ property }: Props) => {
    const i18n = useI18n();
    const paymentPlanBreakdown = usePaymentPlanBreakdown(property);
    const hasOtherPaymentPlans = selectProjectPaymentPlanSummaries(property).length > 1;

    if (!paymentPlanBreakdown) {
        return null;
    }

    const { percentageSteps } = paymentPlanBreakdown;

    return (
        <Flex column className={styles.container} aria-label="Payment Plan Breakdown">
            <PaymentPercentagesTimeline
                title={t(i18n)`Payment Plan Breakdown`}
                steps={percentageSteps}
            />
            {hasOtherPaymentPlans && (
                <div className={styles.disclaimer}>
                    <Text.Small>
                        <Trans>Note: Other payment plans are also available</Trans>
                    </Text.Small>
                </div>
            )}
        </Flex>
    );
};

export default PaymentPlanBreakdown;
