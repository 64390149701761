import {
    PartSelectedActionType,
    PartClickedActionType,
    DateUpdatedActionType,
    ResetActionType,
} from './types';
import type {
    DatePickerState,
    PartClickedAction,
    PartSelectedAction,
    DateUpdatedAction,
    ResetAction,
    Actions,
} from './types';

const DatePickerInitialState = {
    day: {
        value: null,
        isOpened: false,
    },
    month: {
        value: null,
        isOpened: false,
    },
    year: {
        value: null,
        isOpened: false,
    },
} as const;

export const handleDatePartClicked = (
    prevState: DatePickerState,
    action: PartClickedAction,
    // @ts-expect-error - TS2322 - Type '{ [x: string]: DatePickerPartState | { isOpened: boolean; value?: number | null | undefined; } | undefined; }' is not assignable to type 'Partial<Record<string, DatePickerPartState>>'.
): DatePickerState => ({
    ...prevState,
    [action.payload.part as string]: {
        ...prevState[action.payload.part],
        isOpened: action.payload.isOpened,
    },
});

export const handleDatePartSelected = (
    prevState: DatePickerState,
    action: PartSelectedAction,
    // @ts-expect-error - TS2322 - Type '{ [x: string]: DatePickerPartState | { value: number; isOpened?: boolean | undefined; } | undefined; }' is not assignable to type 'Partial<Record<string, DatePickerPartState>>'.
): DatePickerState => ({
    ...prevState,
    [action.payload.part as string]: {
        ...prevState[action.payload.part],
        value: action.payload.value,
    },
});

export const handleUpdateDateAction = (
    prevState: DatePickerState,
    action: DateUpdatedAction,
): DatePickerState => ({
    ...prevState,
    day: {
        ...prevState.day,
        // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number | null'.
        value: action.payload.day,
    },
    month: {
        ...prevState.month,
        // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number | null'.
        value: action.payload.month,
    },
    year: {
        ...prevState.year,
        // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number | null'.
        value: action.payload.year,
    },
});

export const handleResetAction = (_: DatePickerState, __: ResetAction): DatePickerState => {
    return DatePickerInitialState;
};

const DatePickerReducer = (state: DatePickerState, action: Actions): DatePickerState => {
    switch (action.type) {
        case PartSelectedActionType:
            return handleDatePartSelected(state, action);
        case PartClickedActionType:
            return handleDatePartClicked(state, action);
        case DateUpdatedActionType:
            return handleUpdateDateAction(state, action);
        case ResetActionType:
            return handleResetAction(state, action);
        default:
            return state;
    }
};

export { DatePickerInitialState, DatePickerReducer };
