import { Flow } from 'flow-to-typescript-codemod';
import * as React from 'react';
import { connect } from 'react-redux';

import RouteNames from 'strat/routes/routeNames';

import type { GTMProps } from './withGTM';
import withGTM from './withGTM';
import Triggers from './triggers';
import ViewSections from './viewSections';
import type { GTMLegacyStatsProps } from './withGTMLegacyStats';
import withGTMLegacyStats from './withGTMLegacyStats';

/**
 * Properties for {@see GTMNavigationBarTracking}.
 */
type Props = GTMProps &
    GTMLegacyStatsProps & {
        routeName: Values<typeof RouteNames>;
    };

/**
 * Properties passed to the wrapped component.
 */
export type GTMNavigationBarTrackingProps = {
    trackViewSavedSearches: () => void;
    trackViewFavoriteProperties: () => void;
    trackSettingsChange: () => void;
    trackOpenSideMenu: () => void;
    trackOpenReviewsManager: () => void;
    trackFavoriteHeader: () => void;
};

/**
 * Determines the view section based on the route name.
 */
export const determineViewSection = (
    routeName: Values<typeof RouteNames> | null,
): Values<typeof ViewSections> => {
    switch (routeName) {
        case RouteNames.SEARCH:
            return ViewSections.SEARCH_RESULTS;
        case RouteNames.PROPERTY:
            return ViewSections.PROPERTY_DETAIL;
        case RouteNames.AGENCY_SEARCH:
            return ViewSections.AGENCY_SEARCH;
        case RouteNames.AGENCY_DETAIL:
            return ViewSections.AGENCY_DETAIL;
        case RouteNames.AGENT_DETAIL:
            return ViewSections.AGENT_DETAIL;
        default:
            return ViewSections.HOME_PAGE;
    }
};

/**
 * Provides convenience functions for navigation bar tracking.
 */
const withGTMNavigationBarTracking = <T extends any>(
    component: Flow.AbstractComponent<any>,
    // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'GTMNavigationBarTrackingProps'.
): Flow.AbstractComponent<Flow.Diff<T, GTMNavigationBarTrackingProps>> => {
    const GTMNavigationBarTracking = (props: Props) =>
        React.createElement(component, {
            ...props,
            trackViewSavedSearches: () => {
                props.trigger(Triggers.VIEW_SAVED_SEARCHES, {
                    view_section: determineViewSection(props.routeName),
                });
            },
            trackViewFavoriteProperties: () => {
                props.trigger(Triggers.VIEW_FAVORITES, {
                    view_section: determineViewSection(props.routeName),
                });
            },
            trackFavoriteHeader: () => {
                props.trigger(Triggers.FAVORITE_HEADER, {
                    view_section: determineViewSection(props.routeName),
                });
            },
            trackSettingsChange: () => {
                props.trigger(Triggers.SETTINGS_CHANGE, {
                    view_section: determineViewSection(props.routeName),
                });
            },
            trackOpenSideMenu: () => {
                props.trigger(Triggers.OPEN_SIDE_MENU, {
                    view_section: determineViewSection(props.routeName),
                });
            },
            trackOpenReviewsManager: () => {
                props.trigger(Triggers.AGENT_REVIEW_MANAGER_OPEN, {
                    view_section: determineViewSection(props.routeName),
                });
            },
        });

    // @ts-expect-error - TS2322 - Type 'ConnectedComponent<AbstractComponent<Diff<unknown, GTMLegacyStatsProps>, any>, { ref?: Ref<any> | undefined; key?: Key | null | undefined; context?: Context<...> | undefined; store?: any; } | { ...; }>' is not assignable to type 'AbstractComponent<Diff<T, GTMNavigationBarTrackingProps>, any>'.
    return connect((state) => ({
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        routeName: state.router.routeName,
    }))(withGTMLegacyStats(withGTM(GTMNavigationBarTracking)));
};

export default withGTMNavigationBarTracking;
