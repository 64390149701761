import React from 'react';

import styles from './styles/burgerButton.cssm';

type Props = {
    className: string;
    onClick: () => void;
};

const BurgerButton = (props: Props) => (
    <button
        aria-label="Menu"
        className={`${styles.burgerButton} ${props.className}`}
        onClick={props.onClick}
    />
);

export default BurgerButton;
