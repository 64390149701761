import * as React from 'react';
import omit from 'lodash/omit';
import { Text } from 'strat/components';

import type { LiteCategory, Location } from 'horizontal/types';
import { SearchLink } from 'horizontal/search/routing';
import { sortByDisplayPriority } from 'horizontal/util';

import type { SitemapLocation, SitemapCategoryChild, SitemapFilter } from './types';
import styles from './styles/renderList.cssm';

const renderSubtitle = (location: Location, category: LiteCategory) => {
    if (!location.hierarchy) {
        // @ts-expect-error - TS2540 - Cannot assign to 'hierarchy' because it is a read-only property.
        location.hierarchy = [];
    }
    return (
        // @ts-expect-error - TS2322 - Type 'LiteCategory' is not assignable to type 'Category | null | undefined'.
        <SearchLink params={{ location, category }}>
            <Text.Regular>{category.name}</Text.Regular>
        </SearchLink>
    );
};

const renderCategoryItem = (
    item: SitemapCategoryChild,
    location: Location,
    filter: string | SitemapFilter,
    index: number,
) => {
    const filterValues = typeof filter === 'string' ? JSON.parse(filter) : filter;
    return (
        <li className={styles.listItem} key={`${filterValues.value}${index}`}>
            <SearchLink
                params={{
                    location,
                    // @ts-expect-error - TS2322 - Type 'SitemapCategory' is not assignable to type 'Category | null | undefined'.
                    category: item.category,
                    extraFields: filterValues.extraFields,
                }}
                className={styles.listItemLink}
            >
                <Text.Regular capitalized>{filterValues.value}</Text.Regular>
            </SearchLink>
        </li>
    );
};

const renderCategoryList = (item: SitemapCategoryChild, location: Location) => (
    <li className={styles.listItem} key={item.category.externalID}>
        {/* @ts-expect-error - TS2322 - Type 'SitemapCategory' is not assignable to type 'Category | null | undefined'. */}
        <SearchLink params={{ location, category: item.category }} className={styles.listItemLink}>
            {item.category.name}
        </SearchLink>
        <ul className={styles.subList}>
            <div>
                {item.filters &&
                    item.filters.map((filter, subIndex) =>
                        renderCategoryItem(item, location, filter, subIndex),
                    )}
            </div>
            <div>
                {item.category.children &&
                    item.category.children.map((categoryItem) =>
                        renderCategoryList(categoryItem, location),
                    )}
            </div>
        </ul>
    </li>
);

const renderListItems = (item: SitemapLocation) => {
    const location = { ...omit(item, ['categories']) } as const;
    const { categories } = item;
    const sortedCategories = categories.sort(sortByDisplayPriority);

    return (
        <>
            {sortedCategories.map((category, index) => (
                <div className={styles.listItems} key={`${category.externalID}${index}`}>
                    {/* @ts-expect-error - TS2345 - Argument of type '{ readonly name: string; readonly id: number; readonly externalID: string; readonly slug: string; readonly level: number; }' is not assignable to parameter of type 'Location'. */}
                    {renderSubtitle(location, category)}
                    <ul className={styles.mainList}>
                        {category.children.map((categoryItem) =>
                            // @ts-expect-error - TS2345 - Argument of type '{ readonly name: string; readonly id: number; readonly externalID: string; readonly slug: string; readonly level: number; }' is not assignable to parameter of type 'Location'.
                            renderCategoryList(categoryItem, location),
                        )}
                    </ul>
                </div>
            ))}
        </>
    );
};

const renderTitle = (item: SitemapLocation) => {
    const location = { ...omit(item, ['categories']) } as const;
    // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type '{ readonly name: string; readonly id: number; readonly externalID: string; readonly slug: string; readonly level: number; }'.
    if (!location.hierarchy) {
        // @ts-expect-error - TS2339 - Property 'hierarchy' does not exist on type '{ readonly name: string; readonly id: number; readonly externalID: string; readonly slug: string; readonly level: number; }'.
        location.hierarchy = [];
    }

    return (
        // @ts-expect-error - TS2322 - Type '{ readonly name: string; readonly id: number; readonly externalID: string; readonly slug: string; readonly level: number; }' is not assignable to type 'Location'.
        <SearchLink params={{ location }}>
            <Text.Large bold>{location.name}</Text.Large>
        </SearchLink>
    );
};

export { renderTitle, renderListItems };
