/* All possible ad sort types */
const Values = {
    PRICE_ASC: 'price_asc',
    PRICE_DESC: 'price_desc',
    DATE_ASC: 'date_asc',
    DATE_DESC: 'date_desc',
    CITY_LEVEL_SCORE: 'popular',
    POPULAR: 'popular',
    VERIFIED_SCORE: 'verified',
    PRODUCT_LABEL_SCORE: 'product_label_score',
    ADS: 'ads',
} as const;

export default Values;
