import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import settings from '@app/branding/settings';
import amenityIcons from '@app/branding/amenityIcons';

import FilterValues from 'strat/search/filterValues';
import locationHierarchyToString from 'strat/misc/locationHierarchyToString';
import getPrimaryPhoneNumber from 'strat/contact/getPrimaryPhoneNumber';
import { transformLocalizedFields } from 'strat/mergedIndex';

import {
    PropertyType,
    PropertyProductLabel,
    PropertyState,
    PropertyVerificationStatus,
} from './types';
import type {
    CommonAdData,
    ProjectUnit,
    LocationNodeData,
    CategoryNodeData,
    PropertyAmenityGroupData,
    LocalizedCategoryNode,
    LocalizedLocationNodeData,
    PropertyAmenityData,
} from './types';

const getBaseKey = (key: string, index: number) =>
    key.match(new RegExp(`(.*)_l${index}`, 'g')) ? key.split('_')[0] : key;

const isPropertyStudio = (data: CommonAdData) => {
    return (
        data.rooms === 0 &&
        data.category &&
        (data.category.slice(-1)[0].slug === 'apartments' ||
            data.category.slice(-1)[0].slug === 'apartment' ||
            data.category.slice(-1)[0].slug === 'condo' ||
            data.category.slice(-1)[0].slug === 'hotel-apartments')
    );
};

const formatDescription = (description: string) =>
    // For the property description to be properly formatted, we need to convert the newline characters the we might get with <br /> tags.
    description.replace(/\n/g, '<br />');

const getDescriptionFields = (
    data: CommonAdData,
    localizedData: CommonAdData,
    mergedIndex: boolean,
    lang: string,
) => {
    const mainLanguageIndex = settings.languages.findIndex(
        (l) => l.lang === settings.defaultLanguage,
    );
    const description = formatDescription(localizedData.description || '');
    const localizedDataWithOriginalTranslations: any = transformLocalizedFields(
        data,
        lang,
        mergedIndex,
        true,
    );
    const mainDescription = formatDescription(
        localizedDataWithOriginalTranslations[`description_l${mainLanguageIndex}`] || '',
    );

    const langIndex = settings.languages.findIndex(
        (obj) => obj.lang === (!lang ? CONFIG.build.LANGUAGE_CODE : lang),
    );
    const computedLangIndex = mergedIndex ? langIndex : 0;
    const descriptionTranslated =
        localizedDataWithOriginalTranslations[`descriptionTranslated_l${computedLangIndex}`];

    if (!CONFIG.build.STRAT_ENABLE_AUTO_TRANSLATE_DESCRIPTION) {
        // this check is required if the feature gets enabled and then disabled afterwards
        // because the description in a specific language might be translated and we don't want to show them
        return { description: descriptionTranslated ? mainDescription : description };
    }

    return {
        description,
        mainDescription,
        descriptionTranslated,
    };
};

const transformAmenities = (amenity: PropertyAmenityData): PropertyAmenityData => {
    const value = amenity.value ?? '';
    switch (amenity.slug) {
        case 'pet-policy':
            return value.toLowerCase().includes('not')
                ? ({
                      ...amenity,
                      slug: 'pets-not-allowed',
                      text: 'Pets Not Allowed',
                      text_l1: 'غير مسموح باصطحاب الحيوانات الأليف',
                      text_l2: '不允许携带宠物',
                      text_l3: 'Домашние животные не допускаются',
                  } as PropertyAmenityData)
                : ({
                      ...amenity,
                      slug: 'pets-allowed',
                      text: 'Pets Allowed',
                      text_l1: 'مسموح باصطحاب الحيوانات الأليف',
                      text_l2: '允许携带宠物',
                      text_l3: 'Разрешено с животными',
                  } as PropertyAmenityData);
        default:
            return amenity;
    }
};

/**
 * Return the English version of the requested field.
 * For merged index, the English data will be set in normal fields, and arabic data will be set in language specific
 * fields (like "title_l1")
 * For non-merged index, normal fields will contain data translated in the current language, the language specific
 * fields will contain the other value
 * @param {*} data the JSON object from backend
 * @param {*} baseKey the key for which we want the value in English
 * @param {*} mergedIndex tells if we are on a merged index or not
 * @param {*} language the current language
 */
const transformLocalizedFieldToEnglish = (
    data: CommonAdData,
    baseKey: string,
    language: string,
    mergedIndex: boolean,
) => {
    const key = `${baseKey}${mergedIndex || language === 'en' ? '' : '_l1'}`;
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CommonAdData'.
    return data[key];
};

/**
 * Return a key pair object with en;ar;ur;bn;.. data.
 *
 * @param data the JSON object from backend
 * @param language the current websites language
 * @param mergedIndex tells if we are on a merged index or not
 */
const transformAndTranslateData = (data: any, language: string, mergedIndex: boolean) => {
    const keys = Object.keys(data);

    //  Hardcoded en string since English is not the primary language on all of our platforms
    const englishIsMain = mergedIndex || language === 'en';
    const languagesInOrder = [englishIsMain ? 'en' : language]
        .concat(
            settings.languages
                .map((l) => l.lang)
                .filter((lang) => lang !== (englishIsMain ? 'en' : language)),
        )
        .filter(Boolean);

    const translatedKeyNames = keys.filter((key) => key.match(/(.*)_l[1-9]$/g));
    const baseData = omit(data, translatedKeyNames);
    const keysByLanguage = groupBy(translatedKeyNames, (key) => key.slice(-1));

    return languagesInOrder.reduce<Record<string, any>>(
        (coll, lang) => ({
            ...coll,
            [lang]: {
                ...baseData,
                ...keysByLanguage[`${languagesInOrder.indexOf(lang)}`]?.reduce(
                    (map: any, key: any) => {
                        map[getBaseKey(key, languagesInOrder.indexOf(lang))] = data[key];
                        return map;
                    },
                    {},
                ),
            },
        }),
        {},
    );
};

const propertyTransformer = <T extends CommonAdData>(
    data: T,
    lang: string,
    mergedIndex: boolean,
): T => {
    if (!data) {
        return data;
    }

    const localizedData = transformLocalizedFields(data, lang, mergedIndex);
    const title = localizedData.title;
    const category: Array<CategoryNodeData> = data.category.map((item) =>
        transformLocalizedFields(item, lang, mergedIndex),
    );
    // @ts-expect-error - TS2322 - Type 'Record<string, any>[]' is not assignable to type 'LocalizedCategoryNode[]'.
    const categoryTranslations: Array<LocalizedCategoryNode> = data.category.map((item) =>
        transformAndTranslateData(item, lang, mergedIndex),
    );

    const pickedUnits: Array<ProjectUnit> =
        data.pickedUnits?.map((item: any) => ({
            ...item,
            // @ts-expect-error - TS7006 - Parameter 'crtCategory' implicitly has an 'any' type.
            category: item.category.map((crtCategory) =>
                transformLocalizedFields(crtCategory, lang, mergedIndex),
            ),
        })) || [];

    // Note: 'location' is deprecated in favour of 'locations', so in order to
    // facilitate the migration to 'locations', the new field will fallback to
    // the old 'location' field.
    const location: Array<LocationNodeData> = data.location.map((item) =>
        transformLocalizedFields(item, lang, mergedIndex),
    );
    // @ts-expect-error - TS2322 - Type 'Record<string, any>[]' is not assignable to type 'LocalizedLocationNodeData[]'.
    const locationTranslations: Array<LocalizedLocationNodeData> = data.location.map((item) =>
        transformAndTranslateData(item, lang, mergedIndex),
    );
    const locationsData = data?.locations?.length
        ? data.locations
        : // 'location' always contains the primary location, so we add the isMain field
          // and set it for forward compatibility.
          data.location.map((node) => ({ ...node, isMain: true }));
    const locations: LocationNodeData[] = locationsData.map((item) =>
        transformLocalizedFields(item, lang, mergedIndex),
    );
    // @ts-expect-error - TS2322 - Type 'Record<string, any>[]' is not assignable to type 'LocalizedLocationNodeData[]'.
    const locationsTranslations: LocalizedLocationNodeData[] = locationsData.map((item) =>
        transformAndTranslateData(item, lang, mergedIndex),
    );

    const hasPhotos = data.photos && data.photos.length > 0;
    const hasPhotoIDs = data.photoIDs && data.photoIDs.length > 0;
    let photos = hasPhotos ? sortBy(data.photos, ['main']) : [];
    if (!hasPhotos && hasPhotoIDs) {
        // photoIDs is a list of numbers, should be mapped to photo object type
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        photos = data.photoIDs.map((photoID) => ({ id: photoID }));
    }

    /* prettier-ignore */
    // eslint-disable-next-line no-nested-ternary
    const coverPhoto = data.coverPhoto ?
        data.coverPhoto :
        (hasPhotos || hasPhotoIDs) ? photos.find((photo) => photo && photo.main) || photos[0] : null;

    if (coverPhoto && !coverPhoto.title) {
        coverPhoto.title = title;
    }

    photos = photos.map((photo, index) => {
        // photos can be an array of undefined objects when pre-loading the
        // property page
        if (!photo) {
            return photo;
        }
        return {
            ...photo,
            // TODO: deal with having no title
            title: `${index + 1} ${photo.title || ''}`,
        };
    });
    // the cover photo is always the first one
    if (photos && photos.length === 0 && coverPhoto) {
        photos.push(coverPhoto); // eslint-disable-line
    }
    const phoneNumbersData = data.phoneNumber || {};
    // @ts-expect-error - TS2339 - Property 'proxyMobile' does not exist on type '{}'. | TS2339 - Property 'proxyPhone' does not exist on type '{}'. | TS2339 - Property 'whatsapp' does not exist on type '{}'.
    const { proxyMobile, proxyPhone, whatsapp } = phoneNumbersData;
    // Amenities that don't have icons are not displayed. We filter them here to reduce complexity
    // in the presentational component.
    // Empty amenity groups, as a result of filtering amenities, are also filtered out.
    const filteredAmenities: Array<PropertyAmenityGroupData> = (data.amenities || [])
        .map((group) => ({
            ...transformLocalizedFields(group, lang, mergedIndex),
            amenities: (group.amenities || [])
                // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 'completion-year': string; view: string; 'balcony-or-terrace': string; 'barbeque-area': string; 'lobby-in-building': string; 'double-glazed-windows': string; 'centrally-air-conditioned': string; ... 68 more ...; 'central-air-con-and-heating': string; }'.
                .filter((amenity) => !!amenityIcons[amenity.slug])
                .map(transformAmenities)
                .map((amenity) => ({
                    ...transformLocalizedFields(amenity, lang, mergedIndex),
                    value: amenity.value && amenity.format === 'number' ? amenity.value : '',
                    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 'completion-year': string; view: string; 'balcony-or-terrace': string; 'barbeque-area': string; 'lobby-in-building': string; 'double-glazed-windows': string; 'centrally-air-conditioned': string; ... 68 more ...; 'central-air-con-and-heating': string; }'.
                    icon: amenityIcons[amenity.slug],
                })),
        }))
        .filter((group) => group.amenities.length > 0);

    let referenceNumber = data.referenceNumber && data.referenceNumber.trim();

    if (!data.referenceNumber || referenceNumber.length < 2) {
        referenceNumber = settings.fallbackPropertyReferenceNumber(data);
    }

    let isOfTitaniumPlusAgency = false;
    let isOfTitaniumAgency = false;
    let isOfFeaturedAgency = false;
    let agency = null;

    if (data.agency) {
        isOfTitaniumPlusAgency =
            data.agency?.product === (FilterValues.agencyType.choices || {}).TITANIUM_PLUS;
        isOfTitaniumAgency =
            data.agency?.product === (FilterValues.agencyType.choices || {}).TITANIUM;
        isOfFeaturedAgency =
            data.agency?.product === (FilterValues.agencyType.choices || {}).FEATURED;
        agency = {
            ...transformLocalizedFields(data.agency, lang, mergedIndex),
        };
    }

    const descriptionFields = getDescriptionFields(data, localizedData, mergedIndex, lang);

    const verification = data.verification || {};
    if (!verification.status) {
        verification.status = data.isVerified
            ? PropertyVerificationStatus.VERIFIED
            : PropertyVerificationStatus.UNVERIFIED;
    }
    verification.eligible = verification.eligible || false;

    // @ts-expect-error - TS2339 - Property 'mobileNumbers' does not exist on type '{}'.
    const mobileNumbers = phoneNumbersData.mobileNumbers || [];
    // @ts-expect-error - TS2339 - Property 'phoneNumbers' does not exist on type '{}'.
    const phoneNumbers = phoneNumbersData.phoneNumbers || [];

    const requiresLoginForContact = !!data.requiresLoginForContact || !!data.requiresLogin;

    const hasEmail = data?.hasEmail ?? !requiresLoginForContact;
    const reactivatedAt = data?.reactivatedAt || null;

    const hidePrice = data?.hidePrice || false;

    const isDeveloper = data.productLabel === PropertyProductLabel.DEVELOPER;

    return {
        ...omit(localizedData, ['descriptionTranslated']),
        type: data.type || PropertyType.PROPERTY,
        state: data.state || PropertyState.DELETED,
        productLabel: data.productLabel || PropertyProductLabel.DEFAULT,
        isDeveloper,
        photos,
        coverVideo: data.coverVideo || null,
        videoCount: !isNil(data.videoCount) ? data.videoCount : 0,
        isStudio: isPropertyStudio(data),
        primaryPhoneNumber: getPrimaryPhoneNumber(phoneNumbersData),
        mobilePhoneNumber: mobileNumbers[0] || null,
        coverPhoto,
        coverPhotoID: data.coverPhoto ? data.coverPhoto.id : null,
        coverPhotoTitle: data.coverPhoto && data.coverPhoto.title ? data.coverPhoto.title : title,
        labels: {
            categoryName: category[category.length - 1].name,
            locationNames: location.map((loc) => loc.name) as Array<string>,
            locationHierarchy: locationHierarchyToString(location),
        },
        amenities: filteredAmenities,
        phoneNumber: {
            mobileNumbers,
            phoneNumbers,
            proxyMobile: proxyMobile || null,
            proxyPhone: proxyPhone || null,
            whatsapp: whatsapp || null,
        },
        // ads present in algolia are by default active
        // we need to set this explicitly as the soft 404
        // logic needs the active flag
        active: isNil(data.state) ? true : data.state === PropertyState.ACTIVE,
        // fall back to bayut id if the ad has no
        // reference number
        referenceNumber,
        // short cut to figuring out whether this listing
        // belongs to a titanium or featured agency
        isOfTitaniumPlusAgency,
        isOfTitaniumAgency,
        isOfFeaturedAgency,
        // Location, Category data with the current Language
        location,
        locationTranslations,
        locations,
        locationsTranslations,
        category,
        categoryTranslations,
        ...(pickedUnits.length ? { pickedUnits } : {}),
        agency,
        verification,
        requiresLoginForContact,
        hasEmail,
        reactivatedAt,
        hidePrice,
        ...descriptionFields,
    } as T;
};
export default propertyTransformer;
export { isPropertyStudio, transformLocalizedFieldToEnglish, formatDescription };
