import { determineLocationsIndexName } from 'strat/search/indexNames';
import { FilterCollection, ExactFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';

import { LocationNodeData } from 'horizontal/property';

type OptionalLocationNodeData = LocationNodeData | null;

const fetchLocationByExternalID = (
    locationExternalID: string,
    language: string,
    activeSearchBackend: SearchBackend,
): Promise<LocationNodeData | null> => {
    const realLocationFilter = new FilterCollection();
    if (locationExternalID) {
        realLocationFilter.refine(
            new ExactFilter({
                attribute: 'externalID',
                value: locationExternalID,
            }),
        );
    }
    const searchJob = new SearchJob(determineLocationsIndexName({ language }), realLocationFilter, {
        hitsPerPage: 1,
        exhaustiveNbHits: false,
    });

    return new SearchService({ backend: activeSearchBackend })
        .fetchJobs([searchJob])
        .then((response) => (response[0]?.hits?.[0] || null) as OptionalLocationNodeData);
};

export default fetchLocationByExternalID;
