import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';

const selectDefaultVisibleAlert = createSelector(
    (state: GlobalState) => state.router.routeParams,
    (params: { [key: string]: string }) => (params || {}).alert,
);

export { selectDefaultVisibleAlert };
