import Loadable, { LoadingSpinner } from 'strat/loadable';

/**
 * Loads the mortgage calculator component async.
 */
export default Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'mortgageCalculator' */
            './mortgageCalculator'
        ),
    loading: LoadingSpinner,
});
