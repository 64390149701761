import { InsideCircleFilter } from '@sector-labs/fe-search-redux/filters';
import { useSearchFilterValueOfType } from '@sector-labs/fe-search-redux/state';
import FilterValues from 'strat/search/filterValues';

const useLocationRadiusFilterValue = (): number =>
    parseInt(
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2769 - No overload matches this call.
        useSearchFilterValueOfType(InsideCircleFilter, FilterValues.locationRadius.attribute)
            ?.radius,
        10,
    ) || 0;

export default useLocationRadiusFilterValue;
