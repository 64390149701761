import type { Dispatch } from 'redux';
import { FilterCollection, RefinementFilter } from '@sector-labs/fe-search-redux/filters';
import { SearchBackend, SearchResponseHit } from '@sector-labs/fe-search-redux/backend';
import type { SearchResponse } from '@sector-labs/fe-search-redux/backend';
import type { I18n } from '@lingui/core';

import EMPTY_ARRAY from 'strat/empty/array';
import { FilterValues } from 'strat/search';
import type { GlobalState, AppDispatch } from 'strat/state';
import { AgentSortByValues } from 'strat/searchComponents';
import type { RawLeaderboardEntry } from 'strat/leaderboard/types';
import { AgentData } from 'strat/agency/agent/types';
import {
    checkHasAgentCategory,
    checkHasAgentLocation,
    checkHasAgentPurpose,
} from 'strat/agency/search/form/filters';
import shouldFetchFromCube from 'strat/agency/agent/state/shouldFetchFromCube';
import { getCubeSearchFilters, getSearchFilters } from 'strat/agency/search/form/computeFilters';
import fetchFromElasticSearch from 'strat/search/elasticSearch/fetchFromElasticSearch';
import type { FullLeaderboardEntry, RawLocationRankingData } from 'strat/leaderboard/types';

import { getFetchingOptions, getLocationIndexFetchingOptions } from './computeFetchingOptions';

/**
 * This object helps us get the correct ranking field for sorting according to this table:
 * |Location | Purpose | Category | field                                                    |
 * |---------|---------|----------|----------------------------------------------------------|
 * |   X     |    -    |    -     | top_location_trubroker_first_ranking                  |
 * |---------|---------|----------|----------------------------------------------------------|
 * |    -    |    X    |     -    | top_purpose_trubroker_first_ranking                  |
 * |---------|---------|----------|----------------------------------------------------------|
 * |    -    |   -     |     X    | trubroker_first_ranking_overall - default sort option          |
 * |---------|---------|----------|----------------------------------------------------------|
 * |   X     |    X    |    -     | top_location_purpose_trubroker_first_ranking         |
 * |---------|---------|----------|----------------------------------------------------------|
 * |   X     |    -    |     X    | top_location_trubroker_first_ranking (the same as the first)|
 * |---------|---------|----------|----------------------------------------------------------|
 * |    -    |    X    |     X    | top_category_purpose_trubroker_first_ranking          |
 * |---------|---------|----------|----------------------------------------------------------|
 * |   X     |    X    |     X    | top_location_category_purpose_trubroker_first_ranking |
 *
 * If there is no purpose added, we can ignore the category as the option will not show in UI.
 * The usage: CUBE_RANKING_FIELDS[hasLocationFilter][hasPurposeFilter][hasCategoryFilter]
 */
export const CUBE_RANKING_FIELDS = {
    1: {
        1: {
            1: 'top_location_category_purpose_trubroker_first_ranking',
            0: 'top_location_purpose_trubroker_first_ranking',
        },
        0: {
            1: 'top_location_trubroker_first_ranking',
            0: 'top_location_trubroker_first_ranking',
        },
    },
    0: {
        1: {
            1: 'top_category_purpose_trubroker_first_ranking',
            0: 'top_purpose_trubroker_first_ranking',
        },
        0: {
            1: 'trubroker_first_ranking_overall',
            0: 'trubroker_first_ranking_overall',
        },
    },
} as { [key: number]: { [key: number]: { [key: number]: string } } };

export const getCubeRankingField = (
    filters: FilterCollection,
    locationsWithAds: Array<any> | null = null,
): string => {
    const hasLocationFilter = checkHasAgentLocation(filters, locationsWithAds);
    const hasPurposeFilter = checkHasAgentPurpose(filters);
    const hasCategoryFilter = checkHasAgentCategory(filters);
    return CUBE_RANKING_FIELDS[+hasLocationFilter][+hasPurposeFilter][+hasCategoryFilter];
};

const fetchLeaderboardAgents = (
    externalIDs: Array<string>,
    locationRankingData: Array<RawLocationRankingData>,
    totalHits: number,
    totalPages: number,
    credentials = CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_CREDENTIALS,
) =>
    fetchFromElasticSearch(
        CONFIG.runtime.STRAT_INDEX_NAME_AGENT_LEADERBOARD,
        {},
        {},
        getFetchingOptions(externalIDs.length),
        [
            new RefinementFilter({
                attribute: 'agent_external_id',
                value: externalIDs,
            }),
        ],
        credentials,
    ).then((leaderboardResponse) => {
        const leaderboardData: Array<FullLeaderboardEntry> = leaderboardResponse.hits;
        leaderboardResponse.hits = locationRankingData.map((locationData) => {
            const agent = leaderboardData.find(
                (agent) => agent.agent_external_id === locationData.agent_external_id,
            );
            return {
                ...locationData,
                ...agent,
            };
        });
        return { ...leaderboardResponse, nbHits: totalHits, nbPages: totalPages };
    });

export const fetchAgentsFromCubeIndex = (
    hitsPerPage: number,
    sortBy: Values<typeof AgentSortByValues> | null,
    filters: FilterCollection,
    i18n: I18n,
    locationsWithAds: Array<any>,
    useLast3MonthData = false,
    shouldMergeWithActivityData = true,
    credentials = CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_CREDENTIALS,
) =>
    fetchFromElasticSearch(
        CONFIG.runtime.STRAT_INDEX_AGENT_LOCATION_RANKING,
        {},
        {},
        getLocationIndexFetchingOptions(hitsPerPage, sortBy, locationsWithAds, filters),
        getCubeSearchFilters(filters, i18n, locationsWithAds, useLast3MonthData),
        credentials,
    ).then((locationRankingResponse) => {
        if (!shouldMergeWithActivityData) {
            return locationRankingResponse;
        }
        const locationRankingData: Array<RawLocationRankingData> = locationRankingResponse.hits;
        const uniqueLocationData = [
            ...new Map(locationRankingData.map((item) => [item.agent_external_id, item])).values(),
        ];
        const agentExternalIDs = uniqueLocationData.map((hit) => hit.agent_external_id);

        return fetchLeaderboardAgents(
            agentExternalIDs,
            uniqueLocationData,
            locationRankingResponse.nbHits,
            locationRankingResponse.nbPages,
            credentials,
        );
    });

export const fetchAgentsFromActivityIndex = (
    hitsPerPage: number,
    sortBy: Values<typeof AgentSortByValues> | null,
    filters: FilterCollection,
    _i18n: I18n,
    _locationsWithAds: Array<any> = [],
    useLast3MonthData = false,
    _shouldMergeWithActivityData: boolean,
    credentials = CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_CREDENTIALS,
): Promise<SearchResponse<RawLeaderboardEntry>> =>
    fetchFromElasticSearch(
        CONFIG.runtime.STRAT_INDEX_NAME_AGENT_LEADERBOARD,
        {},
        {},
        getFetchingOptions(hitsPerPage, sortBy),
        getSearchFilters(filters, useLast3MonthData),
        credentials,
    );

export const mergeBIDataWithAlgoliaData = (
    _response: SearchResponse<RawLeaderboardEntry>,
    _language: string,
    _backend: SearchBackend | null,
): Promise<SearchResponse<AgentData>[]> => {
    return new Promise(() => [
        {
            hits: null,
        },
    ]);
};

/** When fetching the agents form the BI indices, we also need to merge that data
 with the data from our algolia index. This is needed because of 2 reasons:
 1. The BI leaderboard indices are based on the production database. Therefore,
 the agent logo image URLs are the production ones. This leads to broken images
 dev and stage environments.
 2. The BI indices are updated once per day before 8AM Dubai time. Our agent sync runs
 multiple times a day, so an agent can be updated in our DB and index at any time,
 but that update will not e reflected in the BI index. So, this merge is needed in
 order to keep the agent info up-to-date.
 */
export const fetchDataFromProboard = (
    locationsWithAds: Array<any>,
    sortBy: Values<typeof AgentSortByValues> | null,
    i18n: I18n,
    backend: SearchBackend | null,
    filters: FilterCollection,
    hitsPerPage: number,
    mergeWithAlgolia = true,
    useLast3MonthData = false,
    shouldMergeWithActivityData = true,
    credentials = CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_CREDENTIALS,
): Promise<SearchResponse<SearchResponseHit>[] | null> => {
    const page: number = filters.getFilterValue(FilterValues.page.attribute, 0);
    if (page === -1) {
        return Promise.resolve(EMPTY_ARRAY as SearchResponse<SearchResponseHit>[]);
    }

    const fetchingFunction = shouldFetchFromCube(filters, locationsWithAds)
        ? fetchAgentsFromCubeIndex
        : fetchAgentsFromActivityIndex;

    return fetchingFunction(
        hitsPerPage,
        sortBy,
        filters,
        i18n,
        locationsWithAds,
        useLast3MonthData,
        shouldMergeWithActivityData,
        credentials,
    ).then((response) => {
        if (mergeWithAlgolia) {
            return mergeBIDataWithAlgoliaData(response, i18n.locale, backend);
        }
        return [response];
    });
};

const fetchFromProboardAndUpdateState =
    (_hitsPerPage?: number) =>
    (
        _dispatch: Dispatch<AppDispatch>,
        _getState: () => GlobalState,
    ): Promise<SearchResponse<SearchResponseHit>[] | null> => {
        return new Promise(() => [
            {
                hits: null,
            },
        ]);
    };

export default fetchFromProboardAndUpdateState;
