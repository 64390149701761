import * as React from 'react';
import { withOvation } from 'strat/ovation';
import type { OvationCallbackProps } from 'strat/ovation/withOvation';
import { GTMDataLayerVariables } from 'strat/gtm';

import { usePageViewTrigger } from 'horizontal/gtm';

import useGlobalGTMVars from '../useGlobalGTMVars';
import useTrackSearchImpressions from '../useTrackSearchImpressions';

import PageType from './pageType';

type Props = {
    ovation: OvationCallbackProps;
    pageType: Values<typeof PageType>;
};

const GTMProfileDataLayer = ({ ovation, pageType }: Props) => {
    useTrackSearchImpressions(ovation);

    const globalGTMVars = useGlobalGTMVars();

    usePageViewTrigger(`${pageType}_view`, { ...globalGTMVars, page_type: pageType });

    return (
        <GTMDataLayerVariables
            variables={{
                ...globalGTMVars,
                page_type: pageType,
            }}
        />
    );
};

// @ts-expect-error - TS2345 - Argument of type '({ ovation, pageType }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<OvationProps>'.
export default withOvation(GTMProfileDataLayer);
