import { t } from '@lingui/macro';
import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { useI18n } from 'strat/i18n/language';

import { useSearchHitsLayout } from 'horizontal/layout';
import { useHitsPerPage } from 'horizontal/search/hooks';
import {
    ExactHitList,
    FallbackHitList,
    useEmptyDivPosition,
    useCTAPosition,
} from 'horizontal/search/results';

import useSectionHits from './useSectionHits';

type Props = {
    readonly loading?: boolean;
};

const SectionHits = ({ loading }: Props) => {
    const { exactHitList, fallbackHitList, additionalFallbackHitList } = useSectionHits();
    const selectedLayout = useSearchHitsLayout();
    const hitsPerPage = useHitsPerPage();
    const i18n = useI18n();

    const [emptyDivPosition, emptyDivIndex] = useEmptyDivPosition([
        { hits: exactHitList },
        { hits: fallbackHitList },
        { hits: additionalFallbackHitList },
    ]);

    const [CTAPosition, CTAIndex] = useCTAPosition([
        { hits: exactHitList },
        { hits: fallbackHitList },
        { hits: additionalFallbackHitList },
    ]);

    const commonParams = {
        viewType: selectedLayout,
        viewSection: ViewSections.SEARCH_RESULTS,
        loading,
    } as const;

    return (
        <>
            <ExactHitList
                {...commonParams}
                hits={exactHitList}
                loadingHitsCount={!fallbackHitList.length ? hitsPerPage : 0}
                emptyDivPosition={emptyDivIndex === 0 ? emptyDivPosition : undefined}
                CTAPosition={CTAIndex === 0 ? CTAPosition : undefined}
            />
            {!!fallbackHitList.length && (
                <FallbackHitList
                    {...commonParams}
                    hits={fallbackHitList}
                    loadingHitsCount={!additionalFallbackHitList.length ? hitsPerPage : 0}
                    emptyDivPosition={emptyDivIndex === 1 ? emptyDivPosition : undefined}
                    CTAPosition={CTAIndex === 1 ? CTAPosition : undefined}
                    title={t(i18n)`Displaying nearby ads`}
                />
            )}
            {!!additionalFallbackHitList.length && (
                <FallbackHitList
                    {...commonParams}
                    hits={additionalFallbackHitList}
                    loadingHitsCount={hitsPerPage}
                    emptyDivPosition={emptyDivIndex === 2 ? emptyDivPosition : undefined}
                    CTAPosition={CTAIndex === 2 ? CTAPosition : undefined}
                    title={t(i18n)`Displaying more ads`}
                />
            )}
        </>
    );
};

export default SectionHits;
