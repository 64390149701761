import * as React from 'react';
import classNames from 'classnames';
import { stopEventPropagation } from 'strat/util';
import { StylelessButton } from 'strat/generic';
import SavedSearchLink from 'strat/navigation/savedSearchLink';
import { Flex } from 'strat/components';

import IconDelete from 'horizontal/assets/icons/iconSearchTrash.svg';

import type { SavedSearchData } from './types';
import styles from './styles/savedSearchesCard.cssm';
import SavedSearchesCardFilters from './savedSearchesCardFilters';

const SavedSearchesCard = ({
    savedSearch,
    removeSearch,
    removing,
}: {
    savedSearch: SavedSearchData;
    removeSearch: (id: number) => void;
    removing: Array<number>;
}) => {
    const { id, params } = savedSearch;
    const entryClassName = classNames(styles.listEntry, {
        [styles.removing]: removing?.includes(id),
    });
    const hasFilters =
        params?.category ||
        params?.location ||
        (params?.extraFields?.length && params?.extraFields?.length !== 0) ||
        params?.freeTextQuery ||
        params?.locationRadius;

    return (
        <Flex column className={entryClassName} aria-label="Saved searches card">
            <Flex justifySpaceBetween className={styles.titleWrapper}>
                {/* @ts-expect-error - TS2322 - Type '{ savedSearch: SavedSearchData; className: any; }' is not assignable to type 'IntrinsicAttributes & Diff<unknown, GTMNavigationBarTrackingProps> & RefAttributes<any> & { children?: ReactNode; }'. */}
                <SavedSearchLink savedSearch={savedSearch} className={styles.title} />
                <StylelessButton
                    className={styles.deleteButton}
                    onClick={(event) => {
                        stopEventPropagation(event);
                        removeSearch(id);
                    }}
                >
                    <IconDelete className={styles.deleteIcon} />
                </StylelessButton>
            </Flex>
            {hasFilters && <SavedSearchesCardFilters savedSearch={savedSearch} />}
        </Flex>
    );
};

export default SavedSearchesCard;
