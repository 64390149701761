import { AgencyExtraFilters } from 'horizontal/profile/types';

import { AgencyExtraFields } from './types';
import { defaultProfileFilterValues } from './constants';

const propertyExtraFieldsToFilters = (fields: AgencyExtraFields): AgencyExtraFilters => ({
    price: fields?.price || defaultProfileFilterValues.price,
    area: fields?.ft || defaultProfileFilterValues.area,
    beds: fields?.rooms || defaultProfileFilterValues.beds,
    baths: fields?.bathrooms || defaultProfileFilterValues.baths,
});

const propertyFiltersToExtraFields = (filters: AgencyExtraFilters): AgencyExtraFields => ({
    price: filters?.price,
    ft: filters?.area,
    rooms: filters?.beds,
    bathrooms: filters?.baths,
});

export { propertyExtraFieldsToFilters, propertyFiltersToExtraFields };
