import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

import { useI18n } from 'strat/i18n/language';
import { CategoryNodeData } from 'strat/property';
import { FilterValues } from 'strat/search';

const extractLeafCategoryName = (i18n: I18n, unitCategory: CategoryNodeData[]) => {
    if (!unitCategory.length) {
        return '';
    }

    const leafCategory = unitCategory[unitCategory.length - 1];
    return FilterValues.category.render(i18n, leafCategory.slug, true);
};

const useProjectCategoryNames = (unitCategories?: CategoryNodeData[][]): string | null => {
    const i18n = useI18n();

    if (!unitCategories?.length) {
        return null;
    }

    const categoryNames = unitCategories
        .map((category: CategoryNodeData[]) => extractLeafCategoryName(i18n, category))
        .filter(Boolean);

    if (categoryNames.length > 3) {
        return t(i18n)`${categoryNames[0]}, ${categoryNames[1]} and ${
            categoryNames.length - 2
        } other property types`;
    }
    if (categoryNames.length === 3) {
        return t(i18n)`${categoryNames[0]}, ${categoryNames[1]} & ${categoryNames[2]}`;
    }
    if (categoryNames.length === 2) {
        return t(i18n)`${categoryNames[0]} & ${categoryNames[1]}`;
    } else {
        return categoryNames[0] || null;
    }

    return null;
};

export default useProjectCategoryNames;
