import * as React from 'react';
import { Button, Flex } from 'strat/components';

import styles from './styles/upsellingCTASection.cssm';
import { UpsellingCTA } from './types';

type Props = {
    mainCTA: UpsellingCTA;
    secondaryCTA?: UpsellingCTA;
};

const UpsellingCTASection = ({ mainCTA, secondaryCTA }: Props) => {
    return (
        <Flex justifySpaceBetween className={styles.upsellingCTASection} alignCenter>
            {secondaryCTA ? (
                <Button onClick={secondaryCTA.action} tertiary stretch>
                    {secondaryCTA.text}
                </Button>
            ) : null}
            <Button stretch onClick={mainCTA.action}>
                {mainCTA.text}
            </Button>
        </Flex>
    );
};

export default UpsellingCTASection;
