import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectNumberFormatterFactory } from 'strat/i18n/language/selectors';

export const NumberFormat = ({ value }: { value: number }) => {
    const numberFormatFactory = useSelector(selectNumberFormatterFactory);
    const formatter = numberFormatFactory();

    return <>{formatter.format(value)}</>;
};
