import { useSelector } from 'react-redux';
import { selectLanguage } from 'strat/i18n/language';

const useCommonTargetingParams = () => {
    const language = useSelector(selectLanguage);

    return {
        language,
        debug: CONFIG.build.DEBUG_GOOGLE_AD_MANAGER,
        env: CONFIG.build.STRAT_ENVIRONMENT,
    };
};

export default useCommonTargetingParams;
