import * as React from 'react';
import type { FormikValues } from 'formik';
import brandingSettings from '@app/branding/settings';
import Category from '@app/branding/category';
import { arrayToObject, usePreviousState } from 'strat/util';

import {
    type Category as CategoryType,
    type CategoryField,
    type FlatCategoryField,
    CategoryFieldValueType,
} from 'horizontal/types';
import CommonPostingFields, {
    ConditionField,
    ExtraFields,
    MileageField,
} from 'horizontal/fields/commonPostingFields';
import { TITLE_MAX_CHARACTERS } from 'horizontal/fields/titleField';
import { CarUsageConditions } from 'horizontal/ad/useGetCarMileage';

import Context from './context';

interface Props {
    categoryFields: Array<CategoryField>;
    flatCategoryFields: Array<FlatCategoryField>;
    values: FormikValues;
    setFieldValue: (field: keyof FormikValues, value?: any) => void;
    adCategory: CategoryType | null | undefined;
    isEditMode?: boolean;
}

/*
 * Hook that contains side effects that happen on cars category only
 * */
export const useCarsSideEffects = ({
    categoryFields,
    values,
    setFieldValue,
    flatCategoryFields,
    adCategory,
    isEditMode,
}: Props) => {
    const [activeCategory] = React.useContext(Context);
    const [mileageFieldHidden, setIsMileageFieldHidden] = React.useState(false);
    const userChangedTitle = React.useRef(false);
    const previousCategory = usePreviousState(activeCategory);

    const categoryFieldsByAttribute = React.useMemo(
        () => arrayToObject(categoryFields, (categoryField) => categoryField.attribute),
        [categoryFields],
    );

    const { brandField, mileageField, conditionField } = React.useMemo(
        () => ({
            brandField: categoryFieldsByAttribute[ExtraFields.make.attribute],
            mileageField: categoryFieldsByAttribute[MileageField.attribute],
            conditionField: categoryFieldsByAttribute[ConditionField.attribute],
        }),
        [categoryFieldsByAttribute],
    );

    const {
        brandFieldValue,
        modelFieldValue,
        conditionFieldValue,
        versionFieldValue,
        yearFieldValue,
        mileageFieldValue,
    } = React.useMemo(
        () => ({
            brandFieldValue: values[ExtraFields.make.attribute],
            modelFieldValue: values[ExtraFields.model.attribute],
            versionFieldValue: values[ExtraFields.version.attribute],
            yearFieldValue: values[ExtraFields.year.attribute],
            conditionFieldValue: values[ConditionField.attribute],
            mileageFieldValue: values[MileageField.attribute],
        }),
        [values],
    );

    const flatBrandField = React.useMemo(() => {
        const brandFieldChoices = brandField?.choices?.all;

        if (!brandFieldValue || !brandFieldChoices?.length) {
            return null;
        }

        return brandFieldChoices.find((choice) => choice.value === brandFieldValue);
    }, [brandField, brandFieldValue]);

    const flatModelField = React.useMemo(() => {
        if (!brandFieldValue || !modelFieldValue) {
            return null;
        }

        const flatModelField = flatCategoryFields.find(
            (field) => field.attribute === ExtraFields.model.attribute,
        );

        return flatModelField?.choices?.find((choice) => choice.value === modelFieldValue);
    }, [flatCategoryFields, brandFieldValue, modelFieldValue]);

    //set default model value when a car brand is selected
    React.useEffect(() => {
        if (!isEditMode && brandFieldValue) {
            setFieldValue(ExtraFields.model.attribute, ExtraFields.model.defaultValue);
        }
    }, [setFieldValue, brandFieldValue, isEditMode]);

    // locks mileage field based on config
    React.useEffect(() => {
        if (
            brandingSettings.enableLockMileageField &&
            Category.isOfCarsType(adCategory) &&
            mileageField?.valueType === CategoryFieldValueType.INTEGER
        ) {
            if (
                conditionField &&
                mileageField &&
                CarUsageConditions.NEW.includes(conditionFieldValue)
            ) {
                setFieldValue(
                    MileageField.attribute,
                    mileageField.minValue || MileageField.defaultValue,
                );

                setIsMileageFieldHidden(true);
            } else {
                setIsMileageFieldHidden(false);
                setFieldValue(MileageField.attribute, mileageFieldValue || '');
            }
        }
    }, [
        setFieldValue,
        conditionField,
        mileageField,
        conditionFieldValue,
        mileageFieldValue,
        adCategory,
    ]);

    //auto generate title for cars category based on brands model version and year fields
    React.useEffect(() => {
        if (
            isEditMode ||
            userChangedTitle.current ||
            !flatBrandField?.label ||
            !flatModelField?.label ||
            flatModelField?.slug === 'other' ||
            flatBrandField?.slug === 'other-make'
        ) {
            return;
        }

        const autoGeneratedTitle = [
            flatBrandField?.label,
            flatModelField?.label,
            yearFieldValue,
            versionFieldValue,
        ]
            .filter(Boolean)
            .join(' ')
            .slice(0, TITLE_MAX_CHARACTERS);

        setFieldValue(CommonPostingFields.title.attribute, autoGeneratedTitle);
    }, [
        setFieldValue,
        userChangedTitle,
        flatBrandField,
        flatModelField,
        versionFieldValue,
        yearFieldValue,
        isEditMode,
    ]);

    React.useEffect(() => {
        if (isEditMode) {
            return;
        }

        const isSameCategory = activeCategory?.id === previousCategory?.id;
        const shouldClearTitle = !isSameCategory;
        const allowTitleAutoGeneration = !isSameCategory || !userChangedTitle.current;

        if (shouldClearTitle) {
            setFieldValue(CommonPostingFields.title.attribute, ' ');
        }

        if (allowTitleAutoGeneration) {
            userChangedTitle.current = false;
        }
    }, [activeCategory, previousCategory, userChangedTitle, setFieldValue, isEditMode]);

    return [mileageFieldHidden, userChangedTitle] as const;
};
