import type { UserRole } from 'strat/user/roles';

export enum KeycloakUserGender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}

export enum KeycloakUserState {
    CONFIRMED = 'confirmed',
    CHAT_DISABLED = 'chat_disabled',
    LIGHT_BANNED = 'light_banned',
    BANNED = 'banned',
}

export type KeycloakUser = {
    readonly id: number;
    readonly externalID: string;
    readonly sessionID: string;
    readonly name: string;
    readonly description: string;
    readonly image: string | null | undefined;
    readonly email: string | null | undefined;
    readonly birthday: number | null | undefined;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly phoneNumber: string | null | undefined;
    readonly isPasswordlessLogin: boolean | null | undefined;
    readonly identityProviders: string[] | null | undefined;
    readonly isPhoneNumberVisible: boolean | null | undefined;
    readonly state: KeycloakUserState;
    readonly additionalPhoneNumbers: string[] | null | undefined;
    readonly profileImageID: string | null | undefined;
    readonly gender: KeycloakUserGender | null | undefined;
    readonly realmRoles: UserRole[] | null | undefined;
};
