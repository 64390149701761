import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { FilterCollection, ExactFilter, Filter } from '@sector-labs/fe-search-redux/filters';
import { ElasticSearchBackend } from '@sector-labs/fe-search-redux/backend/elastic';

import { logError } from 'strat/error/log';

const fetchFromElasticSearch = (
    indexName: string,
    filterData: any,
    propertyToAttributeMap: any,
    fetchingOptions: any = {},
    extraFilters: Array<Filter> = [],
    credentials: string = CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_CREDENTIALS,
    timeoutSeconds: number = CONFIG.runtime.STRAT_ES_REQUESTS_TIMEOUT_SECONDS,
): Promise<any> => {
    // TODO: Rename ELASTIC_POPULARITY_TRENDS_SERVER_URL and ELASTIC_POPULARITY_TRENDS_CREDENTIALS
    const elasticSearchBackend = new ElasticSearchBackend({
        url: CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_SERVER_URL,
        credentials,
        requestCredentials: CONFIG.runtime.ELASTIC_POPULARITY_TRENDS_ENABLE_COOKIES
            ? 'include'
            : undefined,
    });

    const filters = new FilterCollection();
    const filtersToRefine = Object.keys(filterData)
        .filter((value) => propertyToAttributeMap[value])
        .map(
            (value) =>
                new ExactFilter({
                    attribute: propertyToAttributeMap[value],
                    value: filterData[value]?.toString(),
                }),
        );
    filters.refineMultiple([...filtersToRefine, ...extraFilters]);

    const job = new SearchJob(indexName, filters, {
        hitsPerPage: fetchingOptions.hitsPerPage || 5,
        attributesToRetrieve: fetchingOptions.attributesToRetrieve || [],
        sort: fetchingOptions.sort,
        exhaustiveNbHits: fetchingOptions.exhaustiveNbHits || 10000,
        facets: fetchingOptions.facets,
        collapseOptions: fetchingOptions.collapseOptions || {},
        timeoutSeconds: timeoutSeconds,
    });

    return new SearchService({ backend: elasticSearchBackend }).fetchJob(job).catch((e) => {
        logError({
            e,
            msg: 'Failed to fetch from ElasticSearch',
            context: {
                name: indexName,
            },
        });

        if (CONFIG.build.DEBUG) {
            throw e;
        }
    });
};

export default fetchFromElasticSearch;
