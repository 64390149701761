import { Route } from 'react-true-router';

import Page from 'horizontal/pages/page';

class ComponentsRoute extends Route {
    constructor() {
        super(Page.COMPONENTS, [['^/components$']]);
    }

    onEnter(context: any): void {
        context.rendering.renderPage(Page.COMPONENTS);
    }
}

export default new ComponentsRoute();
