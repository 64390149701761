import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFilterCollection, search } from '@sector-labs/fe-search-redux/state';
import { ExactFilter } from '@sector-labs/fe-search-redux/filters';

import { PropertyType } from 'strat/property/types';
import type { AppDispatch } from 'strat/state';

import FilterValues from './filterValues';
import { selectListingType } from './selectors';

const useSwitchListingTypeSearch = () => {
    const listingType = useSelector(selectListingType);
    const filters = useSelector(getFilterCollection);
    const dispatch = useDispatch<AppDispatch>();

    return React.useCallback(() => {
        const newFilters = filters.copy();
        newFilters.refineMultiple([
            new ExactFilter({
                attribute: FilterValues.type.attribute,
                value:
                    listingType === PropertyType.PROJECT
                        ? PropertyType.PROPERTY
                        : PropertyType.PROJECT,
                active: false,
            }),
            new ExactFilter({
                attribute: FilterValues.saleType.attribute,
                value: FilterValues.saleType.default,
            }),
        ]);

        dispatch(search(newFilters));
    }, [listingType, dispatch, filters]);
};

export default useSwitchListingTypeSearch;
