import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

export const getLocationRadiusOptionName = (i18n: I18n, option: number) => t(i18n)`+${option} km`;

const useLocationRadiusOptions = () => {
    return [0, 5, 10, 15, 30, 50, 75, 100];
};

export default useLocationRadiusOptions;
