import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import Category from '@app/branding/category';

import type { RoutingContextWithMiddlewares } from 'strat/app/app';
import { forceFetchRecommendations } from 'strat/search/state';
import type { RouteParameters } from 'react-true-router/route';
import type { EnhancedLocation } from 'react-true-router/location';
import { FilterValues, resetFilters } from 'strat/search';
import {
    selectCategory,
    selectIsMapBasedSearchActive,
    selectIsProjectPage,
    selectIsProjectSearchEnabled,
} from 'strat/search/selectors';
import PageRuleType from 'strat/pages/pageRule';
import NonIndexableFilters from 'strat/seo/nonIndexableFilters';
import { PropertyCompletionStatus, PropertyType } from 'strat/property/types';

import { renderNotFoundPage, renderSearchPage, renderLongTailPage } from './rendering';

/**
 * Handles an error, which results in a 404 - Not Found.
 */
const handleError = (context: RoutingContextWithMiddlewares, statusCode = 404): void => {
    context.redux.store.dispatch(resetFilters(context.i18n));
    renderNotFoundPage(context, statusCode);
};

/**
 * Inspects the current state and determines what to render.
 */
const handleResult = (context: RoutingContextWithMiddlewares, extraQueryParams: Object = {}) => {
    // if we're re-redirecting, do not proceed because code
    // below might change the status code based on the current state
    if (context.http.isRedirecting()) {
        return Promise.resolve();
    }

    const { dispatch, getState } = context.redux.store;
    const state = getState();

    const isMapBasedSearchActive = selectIsMapBasedSearchActive(state);
    if (isMapBasedSearchActive) {
        return renderSearchPage(context, 200, extraQueryParams);
    }

    // if no search was initiated, it is most likely an invalid url
    // for example, we can end up here from an non-existent long tail url
    // or a search page that failed the parser
    if (!state.algolia.loaded && !state.algolia.loading) {
        return handleError(context, 404);
    }

    const searchContent = state.algolia.content || {};
    const hasNoHits = !searchContent.nbHits || searchContent.hits.length === 0;

    const filterCollection = getFilterCollection(state);

    const dynamicFilters = filterCollection.pick([
        ...NonIndexableFilters,
        FilterValues.beds.attribute,
    ]);

    const isProjectSearchEnabled = selectIsProjectSearchEnabled(state);

    const fetchRecommendationsPromise = !isProjectSearchEnabled
        ? dispatch(forceFetchRecommendations())
        : Promise.resolve();

    // @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
    return fetchRecommendationsPromise.then((result) => {
        // if no recommendations were fetched and there's no
        // page rule to tell us what to do, follow standard behaviour
        if (!result || !result.data || !result.data.pageRule) {
            if (hasNoHits && !state.search.pageParameters.isCustomPage) {
                const category = selectCategory(state);
                const isProjectPage =
                    filterCollection.getFilterValue(FilterValues.type.attribute) ===
                    PropertyType.PROJECT;
                const isProjectAdsPage = selectIsProjectPage(state);
                const isOffPlanPage =
                    filterCollection.getFilterValue(FilterValues.completionStatus.attribute) ===
                    PropertyCompletionStatus.OFF_PLAN;

                if (
                    CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
                    isOffPlanPage &&
                    !(isProjectPage || isProjectAdsPage)
                ) {
                    return renderSearchPage(context, 404, extraQueryParams);
                }

                if (
                    CONFIG.build.STRAT_ENABLE_NEW_PROJECTS_LPV &&
                    (isProjectPage || isProjectAdsPage)
                ) {
                    return renderSearchPage(context, 200, extraQueryParams);
                }

                const statusCode =
                    Object.keys(dynamicFilters).length === 0 &&
                    // @ts-ignore
                    (Category.isOfResidentialType(category) ||
                        // @ts-ignore
                        Category.isOfCommercialType(category))
                        ? 200
                        : 404;
                return renderSearchPage(context, statusCode, extraQueryParams);
            }
            return renderSearchPage(context, 200, extraQueryParams);
        }

        const { statusCode, page } = result.data.pageRule;
        if (page === PageRuleType.SEARCH_PAGE) {
            return renderSearchPage(context, statusCode, extraQueryParams);
        }

        return handleError(context, statusCode);
    });
};

/**
 * Checks if the current URL could be a URL that classifies
 * as a long-tail search page, if so, renders it, otherwise
 * does nothing.
 *
 * Long-tail search pages are pre-defined in the back-end
 * and can be under any URL. The route regular expressions
 * are set up so that normal search URL's match first and
 * if none of those match, `basePath` will refer to the
 * path. We ask the back-end if there's any pre-defined
 * search page with that path and render it if needed.
 */
const handleLongTailURL = (
    context: RoutingContextWithMiddlewares,
    createURL: (params: RouteParameters) => EnhancedLocation,
    urlParams?: any,
): boolean => {
    if (!context.match.params.basePath) {
        return false;
    }

    // @ts-ignore
    renderLongTailPage(context, createURL, urlParams);
    return true;
};

export { handleResult, handleError, handleLongTailURL };
