import { t } from '@lingui/macro';
import type { I18n } from '@lingui/core';

const errorTypes = Object.freeze({
    teleSales: 'telesales_agent_user',
    proAgent: 'pro_agent_user',
});

const usePackagePurchaseError = (errorStatus: string | null | undefined, i18n: I18n) => {
    // @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type '{ title: string; description: string; subTitle: string; callToActionText?: string | undefined; buttonFn?: (() => void) | undefined; }': title, description, subTitle
    const error: {
        title: string;
        description: string;
        subTitle: string;
        callToActionText?: string;
        buttonFn?: () => void;
    } = {};
    switch (errorStatus) {
        case errorTypes.proAgent:
            error.title = t(i18n)`Your purchase is not complete`;
            error.subTitle = t(i18n)`Need to add packages?`;
            error.description = t(
                i18n,
            )`Please, contact the agency admin to get more package quota.`;
            break;

        case errorTypes.teleSales:
            error.title = t(i18n)`Your purchase is not complete`;
            error.subTitle = t(i18n)`Need to buy packages?`;
            error.description = t(
                i18n,
            )`Please, contact technical support to check if you can buy packages`;
            break;

        default:
            error.title = t(i18n)`Your purchase is not complete`;
            error.subTitle = t(i18n)`Need to buy packages?`;
            error.description = t(
                i18n,
            )`Please, contact technical support to get more package quota`;
    }
    return error;
};

export { errorTypes as packagePurchaseErrorTypes };

export default usePackagePurchaseError;
