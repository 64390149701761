import { SerializedFilterCollection } from '@sector-labs/fe-search-redux/filters';
import type { LocationNodeData } from '@app/property';

import type { PropertyData } from 'strat/property/types';

import { EventType } from '../types';
import type { UserProfileData, UserLocationData, Coordinates } from '../types';

/**
 * Available user actions.
 */
const Actions = Object.freeze({
    SET_PROFILE: 'USER/SET_PROFILE' as const,
    NUKE_PROFILE: 'USER/NUKE_PROFILE' as const,
    SET_CITY: 'USER/SET_CITY' as const,
    SET_LOCATION: 'USER/SET_LOCATION' as const,

    FETCH_GEOLOCATION: 'USER/FETCH_GEOLOCATION' as const,
    SET_GEOLOCATION: 'USER/SET_GEOLOCATION' as const,
    SET_GEOLOCATION_ERROR: 'USER/SET_GEOLOCATION_ERROR' as const,

    UPDATE_SEARCH_HISTORY: 'USER/UPDATE_SEARCH_HISTORY' as const,
    SET_SEARCH_HISTORY: 'USER/SET_SEARCH_HISTORY' as const,

    SET_RECENTLY_VIEWED_PROPERTIES: 'USER/SET_RECENTLY_VIEWED_PROPERTIES' as const,

    SET_VISITOR_ID: 'USER/SET_VISITOR_ID' as const,
    SET_CLIENT_ID: 'USER/SET_CLIENT_ID' as const,
    SET_FBP_ID: 'USER/SET_FBP_ID' as const,
    SET_FBC_ID: 'USER/SET_FBC_ID' as const,
    SET_DEVICE_ID: 'USER/SET_DEVICE_ID' as const,
    SET_ANONYMOUS_SESSION_ID: 'USER/SET_ANONYMOUS_SESSION_ID' as const,
    TRACK_EVENT: 'USER/TRACK_EVENT' as const,

    SET_LOADING: 'USER/SET_LOADING' as const,
    SET_USER_AGENT: 'USER/SET_USER_AGENT' as const,
    SET_USER_IP: 'USER/SET_USER_IP' as const,
    SET_DEBUG_MODE: 'USER/SET_DEBUG_MODE' as const,
});

/**
 * Type for the {@see Actions.SET_PROFILE}.
 */
export type SetProfileAction = {
    type: 'USER/SET_PROFILE';
    profile: UserProfileData;
};

/**
 * Type for the {@see Actions.NUKE_PROFILE}.
 */
export type NukeProfileAction = {
    type: 'USER/NUKE_PROFILE';
};

export type SetCityAction = {
    type: 'USER/SET_CITY';
    city: any | null | undefined;
};

export type SetLocationAction = {
    type: 'USER/SET_LOCATION';
    location: UserLocationData | null | undefined;
};

export type SetSearchHistoryAction = {
    type: 'USER/SET_SEARCH_HISTORY';
    searchHistory: Array<SerializedFilterCollection>;
};

export type SetRecentlyViewedPropertiesAction = {
    type: 'USER/SET_RECENTLY_VIEWED_PROPERTIES';
    recentlyViewedProperties: Array<PropertyData>;
};

export type SetVisitorIDAction = {
    type: 'USER/SET_VISITOR_ID';
    visitorID: string;
};

export type SetClientIDAction = {
    type: 'USER/SET_CLIENT_ID';
    clientID: string;
};

export type SetDeviceIDAction = {
    type: 'USER/SET_DEVICE_ID';
    deviceID: string;
};

export type SetAnonymousSessionIDAction = {
    type: 'USER/SET_ANONYMOUS_SESSION_ID';
    anonymousSessionID: string;
};

export type TrackEventAction = {
    type: 'USER/TRACK_EVENT';
    eventType: Values<typeof EventType>;
    timestamp: number;
};

export type SetLoadingAction = {
    type: 'USER/SET_LOADING';
    loading: boolean;
};

export type SetUserAgent = {
    type: 'USER/SET_USER_AGENT';
    userAgent: string;
};

export type SetUserIP = {
    type: 'USER/SET_USER_IP';
    userIP: string;
};

export type SetDebugMode = {
    type: 'USER/SET_DEBUG_MODE';
    debugMode: boolean;
};

export type FetchGeoLocation = {
    readonly type: 'USER/FETCH_GEOLOCATION';
};

export type SetGeoLocation = {
    readonly type: 'USER/SET_GEOLOCATION';
    readonly coordinates: Coordinates | null | undefined;
    readonly closestLocation: LocationNodeData | null | undefined;
};

export type SetGeoLocationError = {
    readonly type: 'USER/SET_GEOLOCATION_ERROR';
    readonly error: string;
};

export type SetFBPIDAction = {
    readonly type: 'USER/SET_FBP_ID';
    readonly fbpID: string;
};

export type SetFBCIDAction = {
    readonly type: 'USER/SET_FBC_ID';
    readonly fbcID: string;
};

/**
 * Union type for all available user related actions.
 */
export type UserAction =
    | SetProfileAction
    | NukeProfileAction
    | SetCityAction
    | SetLocationAction
    | SetSearchHistoryAction
    | SetRecentlyViewedPropertiesAction
    | SetVisitorIDAction
    | SetClientIDAction
    | SetDeviceIDAction
    | SetAnonymousSessionIDAction
    | TrackEventAction
    | SetLoadingAction
    | SetUserAgent
    | SetUserIP
    | SetDebugMode
    | FetchGeoLocation
    | SetGeoLocation
    | SetGeoLocationError
    | SetFBPIDAction
    | SetFBCIDAction;

export default Actions;
