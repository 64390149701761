import FetcherFactory from 'strat/fetcher';
import { InternalAPI } from 'strat/api';
import { ContextfulError } from 'strat/error/context';

/**
 * Fetcher factory for fetching the agent review.
 */
const factory = new FetcherFactory(
    'agencyAgentsReviews',
    ({ agencyExternalID, page, pageSize, state }) =>
        new InternalAPI()
            .agencyAgentsReviews(agencyExternalID, page, pageSize, state)
            .then(({ status, data }) => {
                if (status !== 200) {
                    throw new ContextfulError(
                        'Unexpected response status while fetching agent reviews',
                        { status, data, id: agencyExternalID },
                    );
                }

                return { status, data: data.results };
            }),
);

const fetchAgencyAgentsReviews = factory.creator();

const agencyAgentsReviewsReducer = factory.reducer();

export { fetchAgencyAgentsReviews };

export default agencyAgentsReviewsReducer;
