import { FilterValues } from 'strat/search';

import { findDeepExact } from 'horizontal/util/findDeep';
import type { Category } from 'horizontal/types';

const findCategoryByExternalID = (
    categories: Category[],
    externalID?: string | null,
): Category | null | undefined =>
    findDeepExact(categories, (category) => category.externalID === externalID);

const findCategoryBySlug = (categories: Category[], slug: string): Category | null | undefined =>
    findDeepExact(categories, (category) => category.slug === slug);

const findCategoryByIdentifier = (
    categories: Category[],
    identifier: string,
): Category | null | undefined =>
    findDeepExact(
        categories,
        (category) =>
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Entry<PartialCategory & { readonly id: number; readonly level: number; readonly externalID: string; readonly locationDepthLimits?: LocationDepthLimits | null | undefined; } & { ...; }>'.
            category[FilterValues.category.attribute.replace('category.', '')] === identifier,
    );

export { findCategoryByExternalID, findCategoryBySlug, findCategoryByIdentifier };
