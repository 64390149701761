import React from 'react';

import { Dialog, CloseDialogButton } from 'strat/modal';

import PopUpHeader from './popUpHeader';
import styles from './styles/userPopUp.cssm';

/**
 * Properties for {@see UserPopUp}.
 */
type Props = {
    /**
     * Indicates whether the pop up should be visible.
     */
    visible: boolean;
    /**
     * Method to call when the visibility of the pop up changes.
     */
    onVisibilityChanged: (visible: boolean) => void;
    /**
     * Contents for this pop up.
     */
    children: Node | React.ReactNode;
    /**
     * Whether the pop up should have a close dialog button.
     */
    withoutCloseButton?: boolean;
    /**
     * Method to render title inside pop up header.
     */
    renderHeaderTitle?: () => Node;
    /**
     * Method to render the back button inside pop up header.
     */
    renderHeaderBackButton?: () => Node;
    /**
     * Whether to render close button inside header.
     */
    withHeaderCloseButton?: boolean;
    /**
     * Custom container styles for the pop up dialog.
     * Use default styles if this is undefined.
     */
    containerClassName?: string;
    stackGroup?: string;
};

/**
 * Pop up used for logging in, registering users and requesting a new password.
 */
const UserPopUp = ({
    visible,
    onVisibilityChanged,
    children,
    withoutCloseButton,
    renderHeaderTitle,
    renderHeaderBackButton,
    withHeaderCloseButton,
    containerClassName,
    stackGroup,
}: Props) => {
    const withHeader = renderHeaderTitle || renderHeaderBackButton || withHeaderCloseButton;
    const dialogProps = {
        className:
            containerClassName ||
            (withHeader ? styles.containerWithHeader : styles.containerRedesign),
        overlayClassName: styles.overlay,
        stackGroup,
    };

    return (
        <Dialog
            dismissible
            visible={visible}
            onVisibilityChanged={() => onVisibilityChanged(false)}
            zIndex={110}
            {...dialogProps}
        >
            {withHeader && (
                <PopUpHeader
                    // @ts-expect-error - TS2322 - Type '(() => Node) | undefined' is not assignable to type '(() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined'.
                    renderTitle={renderHeaderTitle}
                    // @ts-expect-error - TS2322 - Type '(() => Node) | undefined' is not assignable to type '(() => ReactElement<any, string | JSXElementConstructor<any>>) | undefined'.
                    renderBackButton={renderHeaderBackButton}
                    withCloseButton={withHeaderCloseButton}
                />
            )}
            {!withoutCloseButton && <CloseDialogButton className={styles.closeButton} />}
            {children}
        </Dialog>
    );
};

export default UserPopUp;
