import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePreviousState } from 'strat/util';
import { setGeoLocation } from 'strat/user/state';
import useLocationSuggestions from 'strat/search/useLocationSuggestions';

import type { Location } from 'horizontal/types';
import { useDisabledLocations } from 'horizontal/search/location';
import { selectCategories } from 'horizontal/categories';
import { useCategoryFilterValue } from 'horizontal/search/category';

import LocationList from './locationList';
import DefaultLocationList from './defaultLocationList';
import useRecentLocations from './useRecentLocations';

type Props = {
    readonly input: string;
    readonly onLocationClicked: (arg1: Location) => void;
    readonly onGeoPositionSelected: () => void;
    readonly showCurrentLocationSelector?: boolean;
    readonly maxLevel?: number;
};

const LocationSuggestionList = ({
    input,
    onLocationClicked,
    onGeoPositionSelected,
    showCurrentLocationSelector,
    maxLevel,
}: Props) => {
    const dispatch = useDispatch();
    const suggestedLocs = useLocationSuggestions(input) as Array<Location>;
    const categories = useSelector(selectCategories);
    const activeCategory = useCategoryFilterValue();

    const suggestedLocations = useDisabledLocations(suggestedLocs, categories, activeCategory);

    const finalSuggestedLocations = maxLevel
        ? suggestedLocations.filter((location) => location.level <= maxLevel)
        : suggestedLocations;
    const recentLocations = useRecentLocations();

    const [showLocationList, setShowLocationList] = React.useState(false);
    const previousInput = usePreviousState(input);

    React.useEffect(() => {
        if (input.trim() && previousInput && input !== previousInput) {
            setShowLocationList(true);
        }
    }, [previousInput, input]);

    const onLocationSelected = (location: Location) => {
        dispatch(setGeoLocation(null, null));
        onLocationClicked(location);
    };

    return (
        <>
            {showLocationList && (
                <LocationList
                    locations={finalSuggestedLocations}
                    onLocationClicked={onLocationSelected}
                />
            )}
            {!showLocationList && (
                <DefaultLocationList
                    maxLevel={maxLevel}
                    // @ts-expect-error - TS4104 - The type 'readonly never[]' is 'readonly' and cannot be assigned to the mutable type 'Location[]'.
                    recentLocations={recentLocations}
                    onLocationClicked={onLocationSelected}
                    onGeoPositionSelected={onGeoPositionSelected}
                    showCurrentLocationSelector={showCurrentLocationSelector}
                />
            )}
        </>
    );
};

export default LocationSuggestionList;
