import * as React from 'react';
import { useSelector } from 'react-redux';

import { useLocalPrice } from 'strat/i18n/money';
import { selectNumberFormatterFactory } from 'strat/i18n/language';
import { Area, UnitType, areaInLocalUnits, formatLocalUnitsValue, useLocalUnits } from 'strat/i18n';
import { PROJECT_PRICE_FORMATTING_OPTIONS } from 'strat/project';

type UnitRangeValue = [number, number] | [number] | [];

export const createUnitRangeValue = (min?: number | null, max?: number | null): UnitRangeValue => {
    if (min && max) {
        if (min === max) {
            return [min];
        }

        return [min, max].sort((a, b) => a - b) as UnitRangeValue;
    }

    if (min) {
        return [min];
    }

    if (max) {
        return [max];
    }

    return [];
};

export const UnitAreaRange = ({ range }: { range: UnitRangeValue }) => {
    const { areaUnit } = useLocalUnits();
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    if (!range.length) {
        return <>{'-'}</>;
    }

    const label = range
        .map((value) =>
            formatLocalUnitsValue(
                areaInLocalUnits(
                    value,
                    Area.SQM as Values<typeof UnitType>,
                    areaUnit as Values<typeof UnitType>,
                ),
                Area,
                areaUnit,
                numberFormatterFactory,
            ),
        )
        .join(' - ');

    return <>{label}</>;
};

export const UnitPriceRange = ({ range }: { range: UnitRangeValue }) => {
    const { toLocalPrice, formatLocalPriceValue } = useLocalPrice();

    if (!range.length) {
        return <>{'-'}</>;
    }

    const label = range
        .map((value) =>
            formatLocalPriceValue(toLocalPrice(value), PROJECT_PRICE_FORMATTING_OPTIONS),
        )
        .join(' - ');
    return <>{label}</>;
};
