import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'yup'. 'node_modules/yup/lib/index.js' implicitly has an 'any' type.
import Yup from 'yup';
import { Formik } from 'formik';
import type { I18n } from '@lingui/core';
import {
    useEditUserProfile,
    useSessionCompleteUserProfile,
} from '@sector-labs/fe-auth-redux/thunk';
import { useI18n } from 'strat/i18n/language';
import { ValidationSchema } from 'strat/form/validation';
import { useKeycloakSiteConfigWithCallbacks } from 'strat/auth/keycloak';
import { useActiveUser } from 'strat/user/session';
import isValidPhoneNumber from 'strat/i18n/phone/isValidPhoneNumber';
import EMPTY_ARRAY from 'strat/empty/array';

import { selectUserAgencies, useIsAnyTypeOfAgent } from 'horizontal/agent/state';
import { AgencyRoles, Gender } from 'horizontal/types';
import { trackProfileChanges } from 'horizontal/gtm/userTracking';

import {
    convertEpochTimeToDateParts,
    convertDatePartsToEpochTime,
    areDatePartsValid,
    isMinimumAgeSatisfied,
} from './editUserBirthdayMapper';
import EditProfileForm from './editProfileForm';

const makeValidationSchema = (i18n: I18n) =>
    Yup.object().shape({
        name: ValidationSchema(i18n).name,
        description: ValidationSchema(i18n).description,
        whatsappContactPhoneNumber: Yup.string()
            .nullable()
            .test(
                'isPhoneNumberValid',
                t(i18n)`The phone number must be a valid international phone number.`,
                function isWhatsappPhoneNumberValid(value?: string) {
                    if (!value) {
                        return true;
                    }
                    return isValidPhoneNumber(value);
                },
            ),
        birthday: Yup.object()
            .nullable()
            .test(
                'isBirthdayValid',
                t(i18n)`Please select a valid Birthday`,
                function isBirthdayValid(value: any) {
                    if (!value) {
                        return true;
                    } // case where no birthday is filled
                    return areDatePartsValid(value.day, value.month, value.year);
                },
            )
            .test(
                'isAgeOldEnough',
                t(i18n)`Age must be 13 years or over`,
                // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
                function isAgeOldEnough(value) {
                    if (!value) {
                        return true;
                    } // case where no birthday is filled
                    return isMinimumAgeSatisfied(value);
                },
            ),
    });

const EditProfile = ({
    renderForm,
}: {
    renderForm: (props: React.ComponentProps<typeof EditProfileForm>) => React.ReactElement;
}) => {
    const i18n = useI18n();
    const user = useActiveUser() || {};
    const [whatsappContactPhoneNumber, setWhatsappContactPhoneNumber] = React.useState([]);
    const [isWhatsappLoading, setIsWhatsappLoading] = React.useState(true);
    const keycloakSiteConfig = useKeycloakSiteConfigWithCallbacks();
    const editUserProfile = useEditUserProfile();
    const completeSessionUserProfile = useSessionCompleteUserProfile();

    const userAgencies = useSelector(selectUserAgencies) || EMPTY_ARRAY;
    const isAgent = useIsAnyTypeOfAgent();
    const shouldDisplayWhatsappInput =
        isAgent && userAgencies[0]?.roles?.includes(AgencyRoles.ALLOW_AGENTS_WHATSAPP_NUMBER);

    React.useEffect(() => {
        completeSessionUserProfile(keycloakSiteConfig).then((data: any) => {
            setWhatsappContactPhoneNumber(data.whatsapp_contact_phone_numbers || []);
            setIsWhatsappLoading(false);
        });
    }, [completeSessionUserProfile, keycloakSiteConfig]);

    const validationSchema = React.useMemo(() => makeValidationSchema(i18n), [i18n]);
    const initialValues = {
        // @ts-expect-error - TS2339 - Property 'name' does not exist on type '{}'.
        name: user.name,
        // @ts-expect-error - TS2339 - Property 'description' does not exist on type '{}'.
        description: user.description || '',
        // @ts-expect-error - TS2339 - Property 'birthday' does not exist on type '{}'.
        birthday: convertEpochTimeToDateParts(user.birthday),
        // @ts-expect-error - TS2339 - Property 'phoneNumber' does not exist on type '{}'.
        phone_number: user.phoneNumber || '',
        whatsappContactPhoneNumber: whatsappContactPhoneNumber,
        // @ts-expect-error - TS2339 - Property 'email' does not exist on type '{}'.
        email: user.email || '',
        // @ts-expect-error - TS2339 - Property 'profileImageID' does not exist on type '{}'.
        imageId: user.profileImageID,
        // @ts-expect-error - TS2339 - Property 'gender' does not exist on type '{}'.
        gender: user.gender || '',
    } as const;

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                if (!user) {
                    setSubmitting(false);
                }
                trackProfileChanges('save');
                editUserProfile(keycloakSiteConfig, {
                    // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'object'.
                    name: values.name,
                    // @ts-expect-error - TS2339 - Property 'description' does not exist on type 'object'.
                    description: values.description,
                    // @ts-expect-error - TS2339 - Property 'birthday' does not exist on type 'object'.
                    ...(values?.birthday && {
                        // @ts-expect-error - TS2339 - Property 'birthday' does not exist on type 'object'.
                        birthday: convertDatePartsToEpochTime(values.birthday),
                    }),
                    // @ts-expect-error - TS2339 - Property 'gender' does not exist on type 'object'.
                    gender: values.gender || Gender.PREFER_NOT_TO_SAY,
                    // @ts-expect-error - TS2339 - Property 'whatsappContactPhoneNumber' does not exist on type 'object'.
                    whatsappContactPhoneNumbers: values.whatsappContactPhoneNumber,
                }).then(() => {
                    setSubmitting(false);
                });
            }}
        >
            {({
                errors,
                touched,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
                isSubmitting,
                setSubmitting,
                setFieldTouched,
            }: React.ComponentProps<typeof EditProfileForm>) =>
                renderForm({
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                    setFieldTouched,
                    isWhatsappLoading,
                    shouldDisplayWhatsappInput,
                })
            }
        </Formik>
    );
};

export default EditProfile;
