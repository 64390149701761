import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import type { I18n } from '@lingui/core';
import classNames from 'classnames';
import settings from '@app/branding/settings';
import phoneNumberFetcher from '@app/branding/phoneNumberFetcher';

import { withI18n } from 'strat/i18n/language/withI18n';
import { isCanceled } from 'strat/util';
import { LoadingSpinner } from 'strat/loadable';
import type { CancelablePromise } from 'strat/util';
import ProxyPhoneNumber from 'strat/contact/proxyPhoneNumber';
import ContactNumbersDisplay from 'strat/contact/contactNumbersDisplay';
import { PopUp } from 'strat/modal';
import type { PropertyPhoneNumberData } from 'strat/property/types';
import { CallDialogNotificationsFooter } from 'strat/notifications';
import { Flex } from 'strat/components';
import { selectIsMobileLayout } from 'strat/layout/selectors';
import { GlobalState } from 'strat/state';
import { ResponsiveBottomSheet } from 'strat/modal/compact/bottomSheet';
import BottomSheetCloseButton from 'strat/property/compact/bottomSheetCloseButton';
import { selectIsNotificationsFooterVisible } from 'strat/notifications/selectors';

import mergePhoneNumbers, { PhoneNumberPriority } from './mergePhoneNumbers';
import styles from './styles/contactDetailsDialog.cssm';
import { splitPhoneNumbers } from './phoneNumber';

/**
 * Properties for {@see ContactDetailsDialog}.
 */
type Props = {
    /**
     * Indicates whether the dialog should be visible.
     */
    visible: boolean;
    /**
     * Method to call when the visibility of the dialog changes.
     */
    onVisibilityChanged: (visible: boolean) => void;
    /**
     * Phone numbers to display in the dialog.
     */
    phoneNumberData: PropertyPhoneNumberData | null | undefined;
    /**
     * Name of the agency to display.
     */
    agencyName: string | null | undefined;
    /**
     * Name of the agent / contact person to display.
     */
    contactName: string | null | undefined;
    /**
     * Property reference number to display.
     */
    referenceNumber: string | null | undefined;
    /**
     * Method to call when a phone number is clicked.
     */
    onPhoneNumberClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Method to call when the call button is clicked to fetch phone numbers from strat api.
     */
    phoneFetcher: (language: string, externalID: number) => CancelablePromise;
    /**
     * priority between the fetched number and property number.
     */
    phoneNumberPriority: Values<typeof PhoneNumberPriority>;
    showAllNumbers?: boolean;
    /**
     * Property external id for proxy call.
     */
    externalID: number;
    /**
     * Translations.
     */
    i18n: I18n;
    /**
     * Flag indicating whether we should show proxy phone
     * number if possible.
     */
    showProxy?: boolean | null | undefined;
    isMobile: boolean;
    name?: string;
    isNotificationsFooterVisible: boolean;
};

type State = {
    phoneNumberData: any | null | undefined;
    agencyName: string | null | undefined;
    loading: boolean;
};

const hasProxyNumber = (phoneNumberData?: PropertyPhoneNumberData | null) =>
    phoneNumberData && (phoneNumberData.proxyMobile || phoneNumberData.proxyPhone);

/**
 * Displays a dialog with all the contact information
 * for a property.
 */
class ContactDetailsDialog extends Component<Props, State> {
    promise: CancelablePromise | null | undefined = null;

    state: State = {
        phoneNumberData: null,
        agencyName: null,
        loading: true,
    };

    static defaultProps = {
        phoneFetcher: phoneNumberFetcher,
        phoneNumberPriority: PhoneNumberPriority.FAVOR_FETCHED,
        showAllNumbers: false,
        showProxy: settings.showOnlyProxyPhoneContactDetail,
    };

    constructor(props: Props) {
        super(props);
        autoBind(this);
    }

    /**
     * Fetch phone number from API.
     */
    fetchPhoneNumbers() {
        const promise = this.props.phoneFetcher(this.props.i18n.locale, this.props.externalID);
        promise.then(
            // @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
            (data) => {
                const incomingData = data || {};
                const phoneNumberData = this.props.phoneNumberData || {};
                const mobileNumbers = splitPhoneNumbers(
                    incomingData.mobile,
                    // @ts-expect-error - TS2339 - Property 'mobileNumbers' does not exist on type '{}'.
                    phoneNumberData.mobileNumbers,
                );
                const phoneNumbers = splitPhoneNumbers(
                    incomingData.phone,
                    // @ts-expect-error - TS2339 - Property 'phoneNumbers' does not exist on type '{}'.
                    phoneNumberData.phoneNumbers,
                );
                const newPhoneNumberData = {
                    mobileNumbers,
                    phoneNumbers,
                    // @ts-expect-error - TS2339 - Property 'proxyMobile' does not exist on type '{}'.
                    proxyMobile: incomingData.proxyMobile || phoneNumberData.proxyMobile,
                    // @ts-expect-error - TS2339 - Property 'proxyPhone' does not exist on type '{}'.
                    proxyPhone: incomingData.proxyPhone || phoneNumberData.proxyPhone,
                } as const;

                let agencyName = incomingData.agent_name;
                if (typeof agencyName === 'object' && agencyName !== null) {
                    agencyName = agencyName[this.props.i18n.locale];
                }
                this.setState({ phoneNumberData: newPhoneNumberData, agencyName, loading: false });
            },
            // @ts-expect-error - TS7006 - Parameter 'reason' implicitly has an 'any' type.
            (reason) => {
                if (!isCanceled(reason) || reason.name === 'TimeOutError') {
                    this.setState({
                        phoneNumberData: this.props.phoneNumberData || {},
                        loading: false,
                    });
                }
            },
        );
        return promise;
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (this.props.visible === props.visible || !props.visible) {
            return;
        }

        if (hasProxyNumber(this.props.phoneNumberData)) {
            this.setState({ loading: false });
            return;
        }

        this.promise = this.fetchPhoneNumbers();
    }

    componentDidMount() {
        // if visible on mount
        if (this.props.visible) {
            this.promise = this.fetchPhoneNumbers();
        }
    }

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    renderUpdatedContent(
        agencyName: string | null | undefined,
        phoneNumberDisplay: JSX.Element,
        referenceNumber: string | null | undefined,
        i18n: I18n,
    ) {
        return (
            <>
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {phoneNumberDisplay}
                        {CONFIG.build.ENABLE_AGENCY && agencyName && (
                            <Flex
                                justifyCenter
                                className={classNames(styles.agentContainer, {
                                    [styles.agentWithBorder]: !!referenceNumber,
                                })}
                            >
                                <div className={styles.agentLabel}>
                                    <Trans>Agent:</Trans>
                                </div>
                                <div className={styles.agentName}>
                                    {this.props.contactName || agencyName}
                                </div>
                            </Flex>
                        )}
                    </>
                )}
                {referenceNumber && (
                    <span className={styles.infoMessage}>
                        <Trans>
                            Please quote property reference
                            <div className={styles.referenceNumber}>
                                {settings.getBrandName(i18n)} - {this.props.referenceNumber}
                            </div>
                            when calling us.
                        </Trans>
                    </span>
                )}
            </>
        );
    }

    render() {
        const { i18n, visible, referenceNumber, isMobile, isNotificationsFooterVisible } =
            this.props;
        const agencyName = this.state.agencyName || this.props.agencyName;
        const proxyMobile = (this.props.phoneNumberData || {}).proxyMobile;
        const proxyPhone = (this.props.phoneNumberData || {}).proxyPhone;
        const showProxy = this.props.showProxy ?? !isMobile;

        const phoneNumberDisplay =
            hasProxyNumber(this.props.phoneNumberData) && showProxy ? (
                <ProxyPhoneNumber
                    proxyMobile={proxyMobile}
                    proxyPhone={proxyPhone}
                    onPhoneNumberClick={this.props.onPhoneNumberClick}
                />
            ) : (
                <ContactNumbersDisplay
                    phoneNumbers={mergePhoneNumbers(
                        this.props.phoneNumberData,
                        this.state.phoneNumberData,
                        this.props.phoneNumberPriority,
                        this.props.showAllNumbers,
                    )}
                    onPhoneNumberClick={this.props.onPhoneNumberClick}
                />
            );

        const dialogContent = (
            <>
                <span className={styles.title}>
                    <Trans>Contact Us</Trans>
                </span>
                {agencyName && (
                    <span aria-label="Agency Name" className={styles.subTitle}>
                        {agencyName}
                    </span>
                )}
                {this.renderUpdatedContent(agencyName, phoneNumberDisplay, referenceNumber, i18n)}
                {isNotificationsFooterVisible && (
                    <CallDialogNotificationsFooter
                        onClick={() => this.props.onVisibilityChanged(false)}
                    />
                )}
            </>
        );

        if (isMobile) {
            return (
                <ResponsiveBottomSheet
                    name={`contact-details-${this.props.name}-bottom-sheet`}
                    onClose={() => this.props.onVisibilityChanged(false)}
                    isOpen={visible}
                >
                    <div className={styles.bottomSheetContainer}>
                        {dialogContent}
                        {!isNotificationsFooterVisible && (
                            <BottomSheetCloseButton
                                onClick={() => this.props.onVisibilityChanged(false)}
                            />
                        )}
                    </div>
                </ResponsiveBottomSheet>
            );
        }

        return (
            <PopUp
                visible={visible}
                onVisibilityChanged={this.props.onVisibilityChanged}
                className={styles.popupContainer}
            >
                {dialogContent}
            </PopUp>
        );
    }
}

export default connect((state: GlobalState) => ({
    isMobile: selectIsMobileLayout(state),
    isNotificationsFooterVisible: selectIsNotificationsFooterVisible(state),
}))(withI18n()(ContactDetailsDialog));
