import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';
import brandingSettings from '@app/branding/settings';

import { selectPopularSearches } from 'horizontal/sitemap/state/selectors';
import type { CategoryPopularSearch } from 'horizontal/sitemap/types';

import { useCategoryFilterValue } from '../category';
import { useLocationFilterValue } from '../location';

import type { FrequentSearchQuery } from './types';

const getFrequentSearchQueries = (popularSearches: Array<CategoryPopularSearch>) => {
    if (!popularSearches || popularSearches.length === 0) {
        return [];
    }

    const popularWords = popularSearches[0].popularSearchEntry;
    return popularWords.map((entry) => entry.value);
};

const useFrequentSearchQueries = (): ReadonlyArray<FrequentSearchQuery> => {
    const category = useCategoryFilterValue();
    const location = useLocationFilterValue();

    const { popularSearches } = useSelector(selectPopularSearches);
    const freeTextQueries = getFrequentSearchQueries(popularSearches);

    const params = useSelector(selectRouteParams);
    let query = params?.freeTextQuery;
    if (query) {
        query = query.replace(/-/g, ' ');
    }

    const filteredFreeTextQueries =
        !brandingSettings.hidePopularSearchesOnQueryFilter && query
            ? freeTextQueries.filter((search) => search !== query)
            : freeTextQueries;

    return React.useMemo(
        () =>
            filteredFreeTextQueries.map((freeTextQuery) => ({
                category,
                location,
                freeTextQuery,
            })),
        [category, location, filteredFreeTextQueries],
    );
};

export default useFrequentSearchQueries;
