import { t } from '@lingui/macro';
import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import { RouteNames } from 'strat/routes';
import { Flex, Button } from 'strat/components';
import WhatsappIcon from 'strat/assets/icons/iconWhatsapp.svg';
import { ViewSections, withGTMLeadTracking } from 'strat/gtm';
import settings from '@app/branding/settings';
import type { GTMLeadTrackingProps } from 'strat/gtm/withGTMLeadTracking';
import classNames from 'classnames';

import type { LiteUserDTO } from 'horizontal/dtos';
import { ChatRoomLink } from 'horizontal/chat/widgets/large';
import { useAdAgents, useAd } from 'horizontal/ad/state';
import { useTrackChatInit } from 'horizontal/gtm';
import type { Agency } from 'horizontal/types';
import { AgencyRoles } from 'horizontal/types';
import ChatIcon from '@app/branding/chatIcon';
import { useShowWhatsAppButton, useOnWhatsappCTACLick } from 'horizontal/adDetails';

import ContactInfo from './contactInfo';
import styles from './styles/sellerCTAs.cssm';
import useAvailableCTAButtons from './useAvailableCTAButtons';

type Props = GTMLeadTrackingProps & {
    readonly route: string;
    readonly seller: Partial<LiteUserDTO & Agency>;
    readonly isDisplayHorizontal?: boolean;
};

const SellerCTAs = ({ route, seller, trackWhatsAppLead, isDisplayHorizontal = false }: Props) => {
    const i18n = useI18n();
    const ad = useAd();
    const adExternalID = ad?.externalID;
    const trackChatInit = useTrackChatInit();
    const adAgents = useAdAgents();
    const sellerID =
        route === RouteNames.AGENCY_PROFILE
            ? // eslint-disable-next-line camelcase
              adAgents?.[0]?.user_external_id || seller?.agentExternalID
            : adAgents?.[0]?.user_external_id || seller.externalID;

    const isAgencyExternallyManaged = ad?.agency?.roles?.includes(
        AgencyRoles.MANAGED_BY_EXTERNAL_SOURCE,
    );

    const { allowChatCommunication, showPhoneNumber, showWhatsappPhoneNumber } =
        useAvailableCTAButtons();

    const showWhatsappButton = useShowWhatsAppButton({
        isWhatsAppActive: showWhatsappPhoneNumber,
        isShowPhoneNumberActive: showPhoneNumber,
        agency: ad?.agency,
    });
    const showChatButton = isAgencyExternallyManaged
        ? allowChatCommunication && !showWhatsappButton
        : allowChatCommunication;

    const onWhatsAppButtonClick = useOnWhatsappCTACLick({
        ad,
        trackWhatsAppLead,
        viewSection: ViewSections.BODY,
    });

    if (!sellerID || !adExternalID) {
        return null;
    }
    return (
        <>
            {showPhoneNumber && (
                <ContactInfo
                    adExternalID={adExternalID}
                    shortNumber={seller?.shortNumber}
                    className={classNames({
                        [styles.phoneButtonHorizontal]: isDisplayHorizontal,
                        [styles.contactButton]: !isDisplayHorizontal,
                    })}
                />
            )}
            {showChatButton && (
                <Flex
                    className={classNames({
                        [styles.chatContainerHorizontal]: isDisplayHorizontal,
                        [styles.cta]: !isDisplayHorizontal,
                    })}
                    fillContainer
                >
                    <ChatRoomLink
                        adXID={adExternalID}
                        sellerXID={sellerID}
                        className={styles.chatWithSellerLink}
                    >
                        <Button
                            stretch
                            secondary={settings.allowsPrimaryContactButton}
                            className={
                                !settings.allowsPrimaryContactButton
                                    ? classNames({
                                          [styles.chatButtonHorizontal]: isDisplayHorizontal,
                                          [styles.contactButton]: !isDisplayHorizontal,
                                      })
                                    : undefined
                            }
                            onClick={trackChatInit}
                            renderIcon={(_) => <ChatIcon className={styles.chatIcon} />}
                        >
                            {t(i18n)`Chat`}
                        </Button>
                    </ChatRoomLink>
                </Flex>
            )}
            {showWhatsappButton && (
                <Flex className={styles.cta} fillContainer>
                    <div className={styles.whatsAppButtonWrapper}>
                        <Button
                            stretch
                            className={classNames({
                                [styles.whatsappButtonHorizontal]: isDisplayHorizontal,
                                [styles.whatsAppButton]: !isDisplayHorizontal,
                            })}
                            renderIcon={() => <WhatsappIcon className={styles.whatsAppIcon} />}
                            onClick={onWhatsAppButtonClick}
                        >
                            {t(i18n)`WhatsApp`}
                        </Button>
                    </div>
                </Flex>
            )}
        </>
    );
};

export default withGTMLeadTracking(SellerCTAs);
