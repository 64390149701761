import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsUserLoggedIn } from 'strat/user/selectors';
import { stopEventPropagation } from 'strat/util';
import { useLoginDialog } from 'strat/auth';
import { Triggers } from 'strat/gtm';

/**
 * Calls onClick callback only if the user is logged in, otherwise displays login form
 *  @param onClick the callback that is login restricted
 *  @param onLogin callback to call after login from the login popup dialog
 *  @param shouldCallOnLogin condition that tells when to execute the onLogin callback
 *  This parameter is useful when we don't want to automatically call the onLogin callback
 *  after login, but rather when a condition is satisfied for it. Default value is True,
 *  meaning that onCall callback will be automatically called after user is logged in.
 *  @param userPath used for tracking to know what triggered the login popup to open
 */

export type Props = {
    onClick: (event: React.ChangeEvent<any>) => void;
    onLogin?: ((event: React.ChangeEvent<any>) => void) | null;
    shouldCallOnLogin?: boolean;
    userPath?: Values<typeof Triggers>;
};

const useLoginRestrictedCallback = ({
    onClick,
    onLogin,
    shouldCallOnLogin = true,
    userPath,
}: Props) => {
    const [isLoginDialogVisible, setLoginDialogVisible] = useLoginDialog();
    const isLoggedIn = useSelector(selectIsUserLoggedIn);

    const [persistedEvent, setPersistedEvent] = React.useState<any>(undefined);

    // use this state variable to detect if login dialog
    // was closed and opened again from another component
    const [diaOpenCount, setDiaOpenCount] = React.useState(0);

    React.useEffect(() => {
        if (persistedEvent && isLoginDialogVisible) {
            // login form may become visible as a result
            // of another component
            setDiaOpenCount((x) => x + 1);
        }
    }, [isLoginDialogVisible, persistedEvent]);

    React.useEffect(() => {
        if (!isLoggedIn || !persistedEvent) {
            return;
        }

        if (diaOpenCount < 2 && onLogin) {
            if (shouldCallOnLogin) {
                onLogin(persistedEvent);
                setPersistedEvent(undefined);
            }
        } else {
            /** *
             * We disregard the event in either cases:
             * - diaOpenCount is >=2, because then the login is not a direct result of our initiated popup
             * - onLoginSuccess is undefined, because then there is no callback to be called
             */
            setPersistedEvent(undefined);
        }
    }, [persistedEvent, onLogin, isLoggedIn, diaOpenCount, shouldCallOnLogin]);

    return React.useCallback(
        (event: React.ChangeEvent<any>) => {
            if (!isLoggedIn) {
                stopEventPropagation(event);
                if (onLogin) {
                    event.persist();
                    setDiaOpenCount(0);
                    setPersistedEvent(event);
                }
                setLoginDialogVisible(true, { userPath, disableEmailLink: true });
                return;
            }
            onClick(event);
        },
        [isLoggedIn, onClick, setLoginDialogVisible, onLogin, userPath],
    );
};

export default useLoginRestrictedCallback;
