import * as React from 'react';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';

const useNavigateToAgencyAds = () => {
    const router = useRouter();

    return React.useCallback(() => {
        router.pushRoute(RouteNames.AGENCY_PORTAL_ADS);
    }, [router]);
};

export default useNavigateToAgencyAds;
