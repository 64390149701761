import type { LocationNodeData, PropertyData } from 'strat/property/types';
import { SchemaType, PostalAddressTag } from 'strat/schema/types';
import type { MetaData } from 'strat/schema/types';

import { getResidenceType } from './utility';

/**
 * The below tags are general tags and can be used in the context of multiple type of markups (e.g. property, agency and agent)
 */

const addContextTag = (metadata: MetaData) => {
    metadata['@context'] = 'https://schema.org';
};

const addNameTag = (metadata: MetaData, name: string) => {
    if (name) {
        metadata.name = name;
    }
};

const addImageTag = (metadata: MetaData, image: string) => {
    if (image) {
        metadata.image = image;
    }
};

const addURLTag = (metadata: MetaData, url: string) => {
    if (url) {
        metadata.url = url;
    }
};

const addDescriptionTag = (metadata: any, description: string) => {
    if (description) {
        metadata.description = description;
    }
};

const addTypeTag = (
    metadata: MetaData,
    requiredSchemaTypes: Array<string>,
    property?: PropertyData,
) => {
    if (!requiredSchemaTypes) {
        return;
    }
    const category = property ? property.category : null;

    let typeTag = requiredSchemaTypes.map((type) =>
        SchemaType.isCategoryBased(type) ? getResidenceType(category) : type,
    );

    // @ts-expect-error - TS2322 - Type 'string | string[]' is not assignable to type 'string[]'.
    typeTag = typeTag.length === 1 ? typeTag[0] : typeTag;

    metadata['@type'] = typeTag;
};

const addGeoTags = (metadata: MetaData, propertyLocation: LocationNodeData | PropertyData) => {
    if (!propertyLocation.geography) {
        return;
    }

    const geo: Record<string, any> = {};
    addTypeTag(geo, [SchemaType.GEO_COORDINATES]);
    geo.latitude = propertyLocation.geography.lat;
    geo.longitude = propertyLocation.geography.lng;

    metadata.geo = geo;
};

const addAddressTag = (postalAddress: MetaData, tag: string, address: string | null) => {
    if (address) {
        postalAddress[tag] = address;
    }
};

const addPostalAddressTags = (
    metadata: MetaData,
    locations: Array<LocationNodeData>,
    tagsToAdd: Array<string>,
) => {
    if (!locations || locations.length === 0) {
        return;
    }

    const postalAddress: Record<string, any> = {};
    addTypeTag(postalAddress, [SchemaType.POSTAL_ADDRESS]);

    tagsToAdd.forEach((tag) => {
        addAddressTag(
            postalAddress,
            tag,
            PostalAddressTag.getLocationAddressForTag(tag, locations),
        );
    });

    metadata.address = postalAddress;
};

const addTelephoneTag = (metadata: MetaData, phoneNumber: any) => {
    if (!phoneNumber || Object.keys(phoneNumber).length === 0) {
        return;
    }

    // phone fields were serialized in different formats for property and agency/agent
    // in order to have one function for this, I check each field format for the value

    // priority has the mobile then the phone
    const proxyMobile = phoneNumber.proxyMobile || phoneNumber.proxy_mobile;
    const proxyPhone = phoneNumber.proxyPhone || phoneNumber.proxy_phone;
    const telephone = proxyMobile || proxyPhone;
    if (telephone) {
        metadata.telephone = telephone;
    }
};

export {
    addContextTag,
    addTypeTag,
    addNameTag,
    addImageTag,
    addURLTag,
    addDescriptionTag,
    addTelephoneTag,
    addPostalAddressTags,
    addGeoTags,
};
