import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import { FilterValues } from 'strat/search';

import type { GuideLinksData } from '../internalLinks';

import { fetchAreaGuideLink, selectGuideLinks } from './state';

const useSearchGuideLinks = (): GuideLinksData | null | undefined => {
    const dispatch = useDispatch();

    const filters: FilterCollection = useSelector(getFilterCollection);
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const adsLoaded: boolean = useSelector((state) => state.algolia.loaded);
    const guideLinks: GuideLinksData = useSelector(selectGuideLinks);

    React.useEffect(() => {
        if (!adsLoaded) {
            return;
        }

        const locations = filters.getFilterValue(FilterValues.location.attribute, []);
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
        let location = locations[0] instanceof Object ? locations[0].slug : locations[0];

        if (!location) {
            // @ts-expect-error - TS2571 - Object is of type 'unknown'.
            location = filters.getFilterValue(FilterValues.city.attribute, {
                slug: null,
            }).slug;
        }
        // @ts-expect-error - TS2345 - Argument of type '() => Promise<{ data: any; status: number; }>' is not assignable to parameter of type 'Action'.
        dispatch(fetchAreaGuideLink(location));
    }, [adsLoaded, filters, dispatch]);

    if (!adsLoaded || !guideLinks) {
        return null;
    }

    return guideLinks;
};

export default useSearchGuideLinks;
