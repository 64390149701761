import React from 'react';
import getVideoId from 'get-video-id';

import type { PropertyVideoData } from 'strat/property';

import YoutubePlayer from './youtubePlayer';

const getId = (url: string) => (getVideoId(url) || { id: 'unknown' }).id;

const videoThumbnailURL = (
    host: string,
    url: string,
    encoding: string,
    size: {
        width: number;
        height: number;
    },
) => {
    let thumbName = 'sddefault.jpg';
    switch (host) {
        case 'youtube':
            thumbName = size.width > 240 ? 'sddefault.jpg' : 'default.jpg';
            return `https://img.youtube.com/vi/${getId(url)}/${thumbName}`;
        case 'vimeo':
            return 'https://secure-a.vimeocdn.com/logo.svg?color=ffffff';
        case 'dailymotion':
            return `https://www.dailymotion.com/thumbnail/video/${getId(url)}`;
        default:
            return '';
    }
};

const videoEmbedURL = (host: string, url: string) => {
    switch (host) {
        case 'youtube':
            return `https://www.youtube.com/embed/${getId(url)}?showinfo=0&enablejsapi=1`;
        case 'vimeo':
            return `https://player.vimeo.com/video/${getId(url)}?autoplay=1&showinfo=0`;
        case 'dailymotion':
            return `https://www.dailymotion.com/embed/video/${getId(url)}`;
        default:
            return '';
    }
};

const renderVideo = (
    video: PropertyVideoData,
    className: string,
    onError?: (errorMessage: string) => void,
) => {
    switch (video.host) {
        case 'youtube':
            return <YoutubePlayer video={video} className={className} onError={onError} autoplay />;
        case 'vimeo':
            return (
                <iframe
                    className={className}
                    src={videoEmbedURL(video.host, video.url)}
                    frameBorder="0"
                    allowFullScreen
                />
            );
        case 'dailymotion':
            return (
                <iframe
                    className={className}
                    src={videoEmbedURL(video.host, video.url)}
                    frameBorder="0"
                    allowFullScreen
                />
            );
        case '3d_view':
            return <iframe className={className} src={video.url} frameBorder="0" allowFullScreen />;
        default:
            return null;
    }
};

export default { videoThumbnailURL, videoEmbedURL, renderVideo };
