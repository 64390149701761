import { createSelector } from 'reselect';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import type { I18n } from '@lingui/core';
import { isNil, isNull, isEmpty } from 'lodash';
import settings from '@app/branding/settings';
import { SearchResponse, SearchResponseHit } from '@sector-labs/fe-search-redux/backend';
import type { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import type { ProjectData } from 'strat/project/types';
import { isProjectLocation } from 'strat/project/conditions';
import { selectProjects, selectChildProjectCount } from 'strat/project/selectors';
import { selectI18n, selectLanguage } from 'strat/i18n/language/selectors';
import { BayutCompat } from 'strat/util';
import type { LocationNodeData, PropertyData, LocationData } from 'strat/property';
import {
    OffPlanPropertySaleType,
    PropertyCompletionStatus,
    PropertyfurnishingStatus,
    PropertyType,
    PropertyRentFrequency,
} from 'strat/property/types';
import Purpose from 'strat/purpose';
import Layout from 'strat/layout';
import EMPTY_ARRAY from 'strat/empty/array';
import EMPTY_OBJECT from 'strat/empty/object';
import ViewSections from 'strat/gtm/viewSections';
import RouteNames from 'strat/routes/routeNames';
import { selectRouteName } from 'strat/routes/selectors';
import propertyTransformer from 'strat/property/propertyTransformer';
import { selectSortValue } from 'strat/search/state/selectors';
import { GlobalState } from 'strat/state';
import type { City } from 'strat/agency/types';
import { selectIsMobileLayout, selectLayout } from 'strat/layout/selectors';
import { PropertyTypes } from 'strat/branding';
import { PurposeWithOffplanValue } from 'strat/search/findPurposeWithOffplanName';
import type { Range } from 'strat/types';
import AdSortTypeValues from 'strat/search/sortValues/values';
import { SortByValues } from 'strat/search/sortValues';

import FilterValues from './filterValues';
import SearchView from './searchView';
import {
    selectCategoryFromFilters,
    selectRootCategoryFromFilters,
    selectIsRootCategoryResidentialFromFilters,
} from './filterSelectors';

export type GetFilterCollection = (state: GlobalState) => FilterCollection;
type RangeFilterType = {
    readonly min: number | null;
    readonly max: number | null;
};

/**
 * Selects the purpose currently being filtered on.
 */
const selectPurpose = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) =>
        filters.getFilterValue(FilterValues.purpose.attribute, null) as Values<typeof Purpose>,
);
const selectTruBrokerPurpose = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) =>
        filters.getFilterValue(FilterValues.purpose.truBrokerAttribute, null) as Values<
            typeof Purpose
        >,
);

/**
 * Selects if current purpose is for sale
 */
const selectIsPurposeForSale = createSelector(selectPurpose, (purpose) =>
    Purpose.isForSale(purpose),
);

/**
 * Selects if current purpose is for rent
 */
const selectIsPurposeForRent = createSelector(selectPurpose, (purpose) =>
    Purpose.isForRent(purpose),
);

/**
 * Selects the city that is being filtered on.
 */
const selectCity = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) => filters.getFilterValue(FilterValues.city.attribute, null) as City,
);

/**
 * Selects all locations that are currently being filtered on.
 */
const selectLocations = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters): LocationNodeData[] => {
        const locationFilterValue = filters.getFilterValue(
            FilterValues.location.attribute,
            EMPTY_ARRAY,
        );

        return (
            Array.isArray(locationFilterValue) ? locationFilterValue : [locationFilterValue]
        ) as LocationNodeData[];
    },
);

/**
 * Selects the first location that is currently being filtered on.
 */
const selectFirstLocation = createSelector(
    selectLocations,
    (locations: LocationNodeData[]): LocationNodeData | null => locations[0] || null,
);

/**
 * Selects the type (see @strat/locations/locationTypes.jsx) of the first location
 * that is currently being filtered on.
 */
const selectFirstLocationType = createSelector(
    selectFirstLocation,
    (location) => location?.type ?? null,
);

/**
 * Selects the category that is currently being filtered on.
 */
const selectCategory = createSelector(
    getFilterCollection as GetFilterCollection,
    selectCategoryFromFilters,
);

/**
 * Selects the furnishing status that is currently being filtered on.
 */
const selectFurnishingStatus = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) => filters.getFilterValue(FilterValues.furnishingStatus.attribute, null),
);

/**
 * Selects the active root category (example: Residential for Apartments
 * or Commercial for Shops).
 */
const selectRootCategory = createSelector(
    [selectI18n, getFilterCollection],
    selectRootCategoryFromFilters,
);

/**
 * Selects whether the active root category is residential.
 */
const selectIsRootCategoryResidential = createSelector(
    [selectI18n, getFilterCollection as GetFilterCollection],
    selectIsRootCategoryResidentialFromFilters,
);

/**
 * Selects the bed counts that are currently being filtered on.
 */
const selectBeds = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) =>
        filters.getFilterValue(FilterValues.beds.attribute, EMPTY_ARRAY) as Array<number | string>,
);

const selectBaths = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) =>
        filters.getFilterValue(FilterValues.baths.attribute, EMPTY_ARRAY) as Array<number | string>,
);

const selectArea = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) =>
        filters.getFilterValue<number>(
            FilterValues.area.attribute,
            EMPTY_OBJECT,
        ) as RangeFilterType,
);

const selectPrice = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) =>
        filters.getFilterValue<number>(
            FilterValues.price.attribute,
            EMPTY_OBJECT,
        ) as RangeFilterType,
);

const selectRentFrequency = createSelector(getFilterCollection as GetFilterCollection, (filters) =>
    filters.getFilterValue<string>(FilterValues.rentFrequency.attribute),
);

const selectSaleType = createSelector(getFilterCollection as GetFilterCollection, (filters) =>
    filters.getFilterValue(FilterValues.saleType.attribute, null),
);

const selectHandoverDate = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters): Range | null => filters.getFilterValue(FilterValues.handoverDate.attribute),
);

const selectCompletionPercentage = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters): Range | null => filters.getFilterValue(FilterValues.completionPercentage.attribute),
);

const selectPaymentPlan = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters): Range | null => filters.getFilterValue(FilterValues.preHandoverPayment.attribute),
);

/**
 * Selects the name of the city that is
 * currently being filtered on.
 */
const selectCityName = createSelector(selectCity, (city) => (city ? city.name : null));

/**
 * Select the value of the purpose in the appropriate language.
 */
const selectPurposeName = createSelector(selectI18n, selectPurpose, (i18n, purpose) =>
    Purpose.text(i18n, purpose),
);

/**
 * Selects the name of the first location that is
 * currently being filtered on.
 */
const selectLocationName = createSelector(selectI18n, selectFirstLocation, (i18n, location) =>
    location ? location.name : FilterValues.location.placeholderName(i18n),
);

/**
 * Selects the name of the category that is currently being filtered on.
 */
const selectCategoryName = createSelector(selectI18n, selectCategory, (i18n, category) =>
    category ? category.name : FilterValues.category.placeholderName(i18n),
);

/**
 * Selects the hierarchy of the first location that is
 * current being filtered on.
 */
const selectLocationHierarchy = createSelector(
    selectFirstLocation,
    (location) => ((location || {}).hierarchy || EMPTY_ARRAY) as LocationData,
);

/**
 * Selects the hierarchy of the category that is
 * currently being filtered on.
 */
const selectCategoryHierarchy = createSelector(selectCategory, (category) => {
    if (category) {
        const hierarchy: Array<any> = [];
        if (category.parent) {
            hierarchy.push(category.parent);
        }

        hierarchy.push(category);

        return hierarchy;
    }

    return EMPTY_ARRAY;
});

const selectSearchResponse = (state: GlobalState) => state.algolia.content;

/**
 * Selects the total amount of hits we got to a search query.
 */
const selectTotalHitCount = createSelector(
    (state: GlobalState) =>
        state.algolia.content || (EMPTY_OBJECT as Partial<SearchResponse<SearchResponseHit>>),
    (content) => content.nbHits || 0,
);

/**
 * Selects the total amount of pages we got to a search query.
 */
const selectTotalPagesCount = createSelector(
    (state: GlobalState) =>
        state.algolia.content || (EMPTY_OBJECT as Partial<SearchResponse<SearchResponseHit>>),
    (content) => content.nbPages || 0,
);

/**
 * Selects the page number that is currently being filtered on.
 */
const selectCurrentPage = createSelector(getFilterCollection as GetFilterCollection, (filters) =>
    Number(filters.getFilterValue(FilterValues.page.attribute, 1)),
);

/**
 * Selects the number of hits per page for a search query.
 */
const selectHitsPerPage = createSelector(
    (state: GlobalState) =>
        state.algolia.content || (EMPTY_OBJECT as Partial<SearchResponse<SearchResponseHit>>),
    (content) => Number(content.hitsPerPage) || 0,
);

/**
 * Selects the offset of a hit considering the page number and the number of hits per page.
 */
const selectHitOffset = createSelector(
    selectCurrentPage,
    selectHitsPerPage,
    (currentPage, hitsPerPage) => (currentPage - 1) * hitsPerPage,
);

/**
 * Selects whether we have any hits in response to a search query.
 */
const selectHasHits = createSelector(selectTotalHitCount, (hitCount) => hitCount > 0);

/**
 * Selects whether hits are being loaded at the moment.
 */
const selectAreHitsLoaded = createSelector(
    (state: GlobalState) => state.algolia,
    (algolia) => algolia.loaded,
);

/**
 * Take in a list of Algolia hits and map it to an array of
 * transformed properties.
 */
const transformProperties = (hits: readonly SearchResponseHit[], language: string) => {
    if (!hits?.length) {
        return EMPTY_ARRAY;
    }

    return hits.map<PropertyData>((hit) =>
        propertyTransformer(hit as PropertyData, language, CONFIG.build.ENABLE_MERGED_INDEX),
    );
};

/**
 * Selects the popular searches data (long tail page internal linking).
 */
const selectPopularSearches = createSelector(
    (state: GlobalState) => state.seo.popularSearches.data,
    (popularSearches) => popularSearches,
);

const selectMapAlgoliaHitValues = createSelector(
    (state: GlobalState) => state.map.algoliaHits || {},
    (algoliaHits) => Object.values(algoliaHits) || EMPTY_ARRAY,
);

const selectMapPropertyHits = createSelector(
    [selectMapAlgoliaHitValues, selectLanguage],
    transformProperties,
);

/**
 * Selects the hits we got to a search query.
 */
const selectHits = (state: GlobalState) =>
    (state.algolia.content || {}).hits || (EMPTY_ARRAY as readonly SearchResponseHit[]);
const selectHitExternalIDs = createSelector(selectHits, (hits) =>
    hits.map((hit) => hit.externalID),
);

const selectPropertyHits = createSelector([selectHits, selectLanguage], transformProperties);

/**
 * Selects whether non empty hits are available at the moment.
 */
const selectAreHitsAvailable = createSelector(
    selectHits,
    selectAreHitsLoaded,
    (hits, hitsLoaded) => hitsLoaded && hits.length > 0,
);

/**
 * Selects search based recommendations.
 */
const selectRecommendations = (state: GlobalState) =>
    state.search.recommendations?.data?.recommenderHits || (EMPTY_ARRAY as PropertyData[]);

/**
 * Selects search recommendations from parent location
 */
const selectLocationFallbackRecommendations = (state: GlobalState) =>
    state.search.recommendations?.data?.locationFallbackHits || (EMPTY_ARRAY as PropertyData[]);

/**
 * Selects the amount of search based recommendations currently loaded.
 */
const selectRecommendationsCount = createSelector(
    selectRecommendations,
    (recommendations) => recommendations.length as number,
);

/**
 * Selects whether we currently have any recommendations.
 */
const selectHasRecommendations = createSelector(
    selectRecommendationsCount,
    (recommendationsCount) => recommendationsCount > 0,
);

/**
 * Selects whether we currently have any location fallback recommendations.
 */
const selectHasLocationFallbackRecommendations = createSelector(
    selectLocationFallbackRecommendations,
    (recommendations) => recommendations.length > 0,
);

/**
 * Selects whether we have recommendations from recommender or from location fallback.
 */
const selectHasSearchRecommendations = createSelector(
    selectHasRecommendations,
    selectHasLocationFallbackRecommendations,
    (hasRecommenderRecommendations, hasLocationFallbackRecommendations) =>
        hasRecommenderRecommendations || hasLocationFallbackRecommendations,
);

/**
 * Selects whether recommendations are currently being loaded.
 */
const selectAreRecommendationsLoading = createSelector(
    (state: GlobalState) => state.search.recommendations,
    (recommendations) => recommendations?.loading || false,
);

/**
 * Selects search page parameters from the Redux store.
 */
const selectSearchPageParameters = createSelector(
    (state: GlobalState) => state.search.pageParameters,
    (pageParams) => pageParams,
);

/**
 * Selects whether the current page is a custom page.
 */
const selectIsCustomPage = createSelector(
    selectSearchPageParameters,
    (pageParams) => pageParams.isCustomPage,
);

const selectSearchInteractionParams = createSelector(
    [
        selectPurpose,
        selectCategoryHierarchy,
        selectLocations,
        selectCity,
        selectArea,
        selectBeds,
        selectBaths,
        selectPrice,
        selectRentFrequency,
    ],
    (purpose, categoryHierarchy, locations, city, area, beds, baths, price, rentFrequency) => ({
        purpose,
        area,
        price,
        rentFrequency,
        // @ts-expect-error - TS7006 - Parameter 'bed' implicitly has an 'any' type.
        beds: beds.map((bed) => parseInt(bed, 10)),
        // @ts-expect-error - TS7006 - Parameter 'bath' implicitly has an 'any' type.
        baths: baths.map((bath) => parseInt(bath, 10)),
        locationID: [...locations, city, { externalID: null }].filter((entry) => !!entry)[0]
            .externalID as string | null,
        categoryID: [{ externalID: null }, ...categoryHierarchy]
            .filter((entry) => !!entry)
            .slice(-1)[0].externalID as string | null,
    }),
);

const selectIsRemarketingPage = createSelector(
    selectSearchPageParameters,
    (pageParams) => pageParams.pagetype === BayutCompat.ListingPagetype.REMARKETING,
);

const selectRemarketedListings = createSelector(
    (state: GlobalState) => state.search.remarketedListings as PropertyData[],
    (remarketedListings) => remarketedListings || EMPTY_ARRAY,
);

const selectRemarketedListing = createSelector(
    selectRemarketedListings,
    (remarketedListings) => remarketedListings?.[0],
);

/**
 * Checks whether the remarketed listing matches the filters.
 */
const selectShowRemarketedListing = createSelector(
    selectRemarketedListing,
    selectIsRemarketingPage,
    selectSearchInteractionParams,
    (remarketedListing, isRemarketingPage, searchInteractionParams): boolean => {
        if (!remarketedListing || !isRemarketingPage) {
            return false;
        }

        const { purpose, locationID, rentFrequency, price, area } = searchInteractionParams;
        const categoryID = searchInteractionParams.categoryID
            ? searchInteractionParams.categoryID.toString()
            : null;

        const isListingInSelectedCategory = !!remarketedListing.category.find(
            (cat) => cat.externalID === categoryID,
        );

        // if no location is set, still show the remarketed listing
        const isListingInSelectedLocation = locationID
            ? !!remarketedListing.location.find((loc) => loc.externalID === locationID)
            : true;

        return (
            !!purpose &&
            purpose === remarketedListing.purpose &&
            isListingInSelectedCategory &&
            isListingInSelectedLocation &&
            (!rentFrequency ||
                !remarketedListing.rentFrequency ||
                rentFrequency === remarketedListing.rentFrequency) &&
            (isEmpty(price) ||
                // @ts-expect-error TS2531: Object is possibly 'null'.
                (price.min <= remarketedListing.price &&
                    // @ts-expect-error TS2531: Object is possibly 'null'.
                    (remarketedListing.price <= price.max || !price.max))) &&
            (isEmpty(area) ||
                // @ts-expect-error TS2531: Object is possibly 'null'.
                (area.min <= remarketedListing.area &&
                    // @ts-expect-error TS2531: Object is possibly 'null'.
                    (remarketedListing.area <= area.max || !area.max)))
        );
    },
);

const selectSearchView = createSelector(
    (state: GlobalState) => state.search.hits,
    (hits) => hits.view,
);

const selectIsSearchResultsView = createSelector(
    selectSearchView,
    (view) => view === SearchView.SEARCH_RESULTS,
);

const selectIsRecommendationsView = createSelector(
    selectSearchView,
    (view) => view === SearchView.RECOMMENDATIONS,
);

const selectIsFavoritesView = createSelector(
    selectSearchView,
    (view) => view === SearchView.FAVORITES,
);

const selectShowNewRecommendations = createSelector(
    selectRemarketedListings,
    selectIsRecommendationsView,
    (remarketedListings, isRecommendationsView) => {
        return isRecommendationsView && remarketedListings.length > 0;
    },
);

const selectIsMapBasedSearchActive = (state: GlobalState) =>
    state.search.hits?.view === SearchView.MAP_BASED_SEARCH ||
    state.search.hits?.view === SearchView.COMMUTE_SEARCH;

const selectIsCommuteActive = (state: GlobalState) =>
    (CONFIG.build.ENABLE_NEEDS_BASED_SEARCH &&
        (state.search.hits?.view === SearchView.MAP_BASED_SEARCH ||
            state.search.hits?.view === SearchView.COMMUTE_SEARCH) &&
        state.map.commuteActive) ||
    false;

const selectIsSearchPageWithMapActive = createSelector(
    selectIsMapBasedSearchActive,
    selectRouteName,
    (isMapBasedSearchActive, routeName) =>
        isMapBasedSearchActive && routeName === RouteNames.SEARCH,
);

const selectIsMobileMapBasedSearch = createSelector(
    selectIsMapBasedSearchActive,
    selectIsMobileLayout,
    (isMapBasedSearchActive, isMobile) => isMapBasedSearchActive && isMobile,
);

const selectShowMapBasedSearchToggle = createSelector(
    selectIsFavoritesView,
    selectIsCommuteActive,
    (isFavoritesView, isCommuteActive) =>
        settings.enableMapBasedSearch && !isCommuteActive && !isFavoritesView,
);

const selectFiltersListVisible = createSelector(
    (state: GlobalState) => state.search,
    (search) => search.filtersListVisible,
);

const selectHasOtherPurpose = createSelector(
    (state: GlobalState) => state.internalLinks.data,
    (data) => (data || {}).otherPurpose,
);

const selectIsCompletionStatusFilterEnabled = createSelector(
    [selectIsPurposeForSale, selectIsPurposeForRent],
    (isPurposeForSale: boolean, isPurposeForRent: boolean) =>
        (isPurposeForSale && !settings.hideSaleCompletionStatus) ||
        (isPurposeForRent && !settings.hideRentCompletionStatus),
);

const selectIsRentFrequencyFilterEnabled = createSelector(
    [selectIsPurposeForRent],
    (isPurposeForRent: boolean) => isPurposeForRent && !settings.hideRentFrequency,
);

const selectIsResidenceTypeFilterEnabled = createSelector(
    selectIsRootCategoryResidential,
    (isRootCategoryResidential) => settings.enableResidenceTypeFilter && isRootCategoryResidential,
);

const selectIsOccupancyStatusFilterEnabled = createSelector(
    selectIsPurposeForRent,
    (isPurposeForRent) => isPurposeForRent && settings.enableOccupancyStatusFilter,
);

const selectIsFurnishingStatusFilterEnabled = createSelector(
    selectIsPurposeForRent,
    (isPurposeForRent) =>
        isPurposeForRent
            ? settings.enableFurnishingStatusForRent
            : settings.enableFurnishingStatusForSale,
);

const selectIsProductLabelScoreSortingEnabled = createSelector(
    selectIsPurposeForSale,
    selectIsCommuteActive,
    (isPurposeForSale, isCommuteActive) =>
        isPurposeForSale && !isCommuteActive && settings.enableProductLabelScoreSorting,
);

const selectSummaryIsAtTop = createSelector(
    [
        selectIsCompletionStatusFilterEnabled,
        selectIsResidenceTypeFilterEnabled,
        selectIsOccupancyStatusFilterEnabled,
        selectIsFurnishingStatusFilterEnabled,
        selectIsProductLabelScoreSortingEnabled,
    ],
    (
        isCompletionStatusFilterEnabled,
        isResidenceTypeFilterEnabled,
        isOccupancyStatusFilterEnabled,
        isFurnishingStatusFilterEnabled,
        selectIsProductLabelScoreSortingEnabled,
    ) =>
        !isCompletionStatusFilterEnabled &&
        !isResidenceTypeFilterEnabled &&
        !isOccupancyStatusFilterEnabled &&
        !isFurnishingStatusFilterEnabled &&
        !selectIsProductLabelScoreSortingEnabled &&
        !settings.enableSearchHitsHeaderNextToTitle,
);

/**
 * Selects whether the current category is a commercial category or not.
 */
const selectIsCommercialCategory = createSelector(
    selectI18n,
    getFilterCollection as GetFilterCollection,
    (i18n: I18n, filters) => {
        const category = filters.getFilterValue(FilterValues.category.attribute);
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const parentCat = category ? category.parent : null;

        // @ts-ignore
        const commercialCatID = FilterValues.category.tree(i18n).commercial?.externalID;
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        const catIsCommercial = category && category.externalID === commercialCatID;
        const parentCatIsCommercial = parentCat && parentCat.externalID === commercialCatID;

        return catIsCommercial || parentCatIsCommercial;
    },
);

/**
 * Selects whether search hits are being loaded.
 *
 * This is a convenience selector, just to make it easier to think logically
 * when using it together with selectAreRecommendationsLoading
 */
const selectAreHitsLoading = createSelector(selectAreHitsLoaded, (areHitsLoaded) => !areHitsLoaded);

/**
 * Selects whether to display the RecommendationsInfoBox component.
 *
 * It should only be displayed if
 *   - hits and recommendations have loaded
 *   - and there are no hits on the page
 *   - and there are recommendations on the page
 */
const selectShowRecommendationsInfo = createSelector(
    selectAreHitsLoading,
    selectAreRecommendationsLoading,
    selectHasHits,
    selectHasSearchRecommendations,
    (hitsLoading, recommendationsLoading, hasHits, hasSearchRecommendations) =>
        !(hitsLoading || recommendationsLoading || hasHits) && hasSearchRecommendations,
);

/**
 * Selects whether to display the RecommendedSearchHits component.
 *
 * It should only be displayed if
 *   - hits have loaded and there are none
 *   - and recommendations are loading or there are recommendations on the page
 */
const selectShowRecommendationHits = createSelector(
    selectAreHitsLoading,
    selectAreRecommendationsLoading,
    selectTotalHitCount,
    selectHasRecommendations,
    (hitsLoading, recommendationsLoading, totalHitCount, hasRecommendations) =>
        !hitsLoading && totalHitCount < 5 && (recommendationsLoading || hasRecommendations),
);

/**
 * Selects whether to display the RecommendedSearchHits component.
 *
 * It should only be displayed if
 *   - hits have loaded and there are none
 *   - and recommendations are loading or there are recommendations on the page
 */
const selectShowLocationFallbackRecommendationHits = createSelector(
    selectAreHitsLoading,
    selectAreRecommendationsLoading,
    selectHasLocationFallbackRecommendations,
    (hitsLoading, recommendationsLoading, hasRecommendations) =>
        settings.enableSearchRecommendedPropertiesWithLocationFallback &&
        !hitsLoading &&
        (recommendationsLoading || hasRecommendations),
);

const selectListingType = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters): Values<typeof PropertyType> =>
        filters.getFilterValue(FilterValues.type.attribute, FilterValues.type.default),
);

const selectIsProjectSearchEnabled = createSelector(
    selectListingType,
    (listingType) =>
        listingType === PropertyType.PROJECT && CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH,
);

const selectIsProjectMapSearchEnabled = createSelector(
    selectIsMobileLayout,
    (isMobile) =>
        CONFIG.runtime.STRAT_ENABLE_PROJECT_MAP_SEARCH &&
        (!isMobile || CONFIG.runtime.STRAT_ENABLE_PROJECT_MAP_SEARCH_MOBILE),
);

const selectIsProjectMapSearchActive = createSelector(
    selectIsProjectMapSearchEnabled,
    selectIsMapBasedSearchActive,
    selectIsCommuteActive,
    selectIsProjectSearchEnabled,
    (isProjectMapSearchEnabled, isMapBasedSearchActive, isCommuteActive, isProjectSearchEnabled) =>
        isProjectMapSearchEnabled &&
        isProjectSearchEnabled &&
        isMapBasedSearchActive &&
        !isCommuteActive,
);

const selectEnabledListingType = createSelector(
    selectIsMapBasedSearchActive,
    selectIsProjectSearchEnabled,
    selectIsProjectMapSearchActive,
    selectListingType,
    (
        isMapBasedSearchActive,
        isProjectSearchEnabled,
        isProjectMapSearchActive,
        listingType,
    ): Values<typeof PropertyType> => {
        if (!isProjectSearchEnabled || (isMapBasedSearchActive && !isProjectMapSearchActive)) {
            return FilterValues.type.default;
        }

        return listingType;
    },
);

/**
 * Selects whether to display the SearchHits component.
 *
 * It should only be displayed if
 *   - hits are loading
 *   - or there are hits to be displayed
 *   - or recommendations have loaded and there are none
 */
const selectShowSearchHits = createSelector(
    selectAreHitsLoading,
    selectAreRecommendationsLoading,
    selectHasHits,
    selectHasSearchRecommendations,
    selectIsProjectSearchEnabled,
    (
        hitsLoading,
        recommendationsLoading,
        hasHits,
        hasSearchRecommendations,
        isProjectSearchEnabled,
    ) =>
        hitsLoading ||
        hasHits ||
        !(recommendationsLoading || (!isProjectSearchEnabled && hasSearchRecommendations)),
);

/**
 * Selects ad sorting option
 */
const selectSortPreference = createSelector(selectSortValue, (sortBy) =>
    sortBy === SortByValues.DEFAULT ? 'default' : sortBy,
);

/**
 * Selects the value of the completion status filter
 */
const selectCompletionStatus = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters): string | null =>
        filters.getFilterValue(FilterValues.completionStatus.attribute, null) as Values<
            typeof PropertyCompletionStatus
        > | null,
);

/**
 * Selects whether or not a page has the project completion status
 */
const selectIsProjectCompletionStatusPage = createSelector(
    selectCompletionStatus,
    (completionStatus) =>
        CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
        completionStatus === PropertyCompletionStatus.OFF_PLAN,
);

const selectIsSaleTypeFilterEnabled = createSelector(
    [
        selectIsPurposeForSale,
        selectCompletionStatus,
        selectIsProjectSearchEnabled,
        selectIsMapBasedSearchActive,
    ],
    (
        isPurposeForSale: boolean,
        completionStatus: string | null,
        isProjectSearchEnabled,
        isMapBasedSearchActive,
    ) =>
        CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
        CONFIG.runtime.STRAT_ENABLE_SALE_TYPE_FILTER &&
        !isProjectSearchEnabled &&
        !isMapBasedSearchActive &&
        isPurposeForSale &&
        completionStatus === PropertyCompletionStatus.OFF_PLAN,
);

/**
 * Selects purpose and off-plan status
 */
const selectPurposeWithOffplan = createSelector(
    selectPurpose,
    selectCompletionStatus,
    (purpose, completionStatus) =>
        ({
            purpose,
            isOffplan: completionStatus === PropertyCompletionStatus.OFF_PLAN,
        }) as PurposeWithOffplanValue,
);

const selectProject = createSelector(
    [selectProjects, selectListingType, selectPurposeWithOffplan, selectLocations],
    (projects, listingType, { purpose, isOffplan }, locations): ProjectData | null => {
        if (listingType !== PropertyType.PROPERTY) {
            return null;
        }

        if (!Purpose.isForSale(purpose) || !isOffplan) {
            return null;
        }

        if (!locations?.length || locations.length > 1) {
            return null;
        }

        const location = locations[0];
        if (!isProjectLocation(location)) {
            return null;
        }

        const matchingProject = projects.find((project) => {
            const leafLocation = project.location[project.location.length - 1];
            if (!leafLocation) {
                return false;
            }

            return leafLocation.id === location.id;
        });

        return matchingProject || null;
    },
);

const selectIsProjectPage = createSelector(selectProject, (project) => !!project);

const selectIsProjectSearchAvailable = createSelector(
    selectIsProjectSearchEnabled,
    selectPurposeWithOffplan,
    selectChildProjectCount,
    selectIsProjectPage,
    (isProjectSearchEnabled, { purpose, isOffplan }, childProjectCount, isProjectPage) =>
        CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH &&
        // When in the project view, you should always be able to switch back.
        (isProjectSearchEnabled ||
            // Only allow switching when filtering for off-plan properties
            // when there are projects available and we aren't on a project page.
            (purpose === Purpose.FOR_SALE && isOffplan && childProjectCount > 0 && !isProjectPage)),
);

const selectIsHandoverDateFilterEnabled = createSelector(
    selectPurposeWithOffplan,
    ({ purpose, isOffplan }) =>
        CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
        CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH &&
        purpose === Purpose.FOR_SALE &&
        isOffplan,
);

const selectIsCompletionPercentageFilterEnabled = createSelector(
    selectPurposeWithOffplan,
    ({ purpose, isOffplan }) =>
        CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
        CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH &&
        purpose === Purpose.FOR_SALE &&
        isOffplan,
);

const selectIsPaymentPlanFilterEnabled = createSelector(
    selectPurposeWithOffplan,
    ({ purpose, isOffplan }) =>
        CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN &&
        CONFIG.runtime.STRAT_ENABLE_PROJECT_SEARCH &&
        purpose === Purpose.FOR_SALE &&
        isOffplan,
);

/**
 * Selects the value of the time since creation filter
 */
const selectTimeSinceCreation = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) => filters.getFilterValue(FilterValues.timeSinceCreation.attribute, null),
);

/**
 * Selects the value of the contract renewal status filter
 */
const selectContractRenewalStatus = createSelector(
    getFilterCollection as GetFilterCollection,
    (filters) => filters.getFilterValue(FilterValues.contractRenewalStatus.attribute, null),
);

const selectShowProjectRecommendations = createSelector(
    selectIsProjectSearchEnabled,
    selectAreHitsLoading,
    selectTotalHitCount,
    (isProjectEnabled, hitsLoading, hitCount) => isProjectEnabled && !hitsLoading && hitCount < 5,
);

const selectIsVerifiedSortingEnabled = createSelector(
    selectLayout,
    selectPurpose,
    selectLocations,
    selectIsCommuteActive,
    selectCategory,
    (layout: Values<typeof Layout>, purpose, filterLocations, isCommuteActive, category) => {
        const fitsLayout = layout !== Layout.TABLET;
        const purposeNotSelected = !!purpose;
        const categoryHasTruCheck = FilterValues.category.canBeTruChecked(category?.externalID);

        /**
         * This value specifies whether the VS should be enabled considering the selected locations in filters.
         * If there is no limitation of VS to specific regions, then show the option.
         * If there is limitation of VS to specific regions, but there is no value selected in location filter, then show the option.
         * If there is limitation of VS to specific regions and there are also values in location filter, then check whether one
         * of those locations is part of the specific regions, and if so, then show the option, otherwise hide it.
         */
        const regionSlugs = settings.limitVerifiedSortingToRegions;
        const showForLocations =
            regionSlugs && filterLocations.length !== 0
                ? filterLocations.some((location) =>
                      regionSlugs.some((slug) => location.slug.includes(slug)),
                  )
                : true;

        return (
            !isCommuteActive &&
            settings.enableVerifiedSorting &&
            fitsLayout &&
            purposeNotSelected &&
            showForLocations &&
            categoryHasTruCheck
        );
    },
);

const selectSearchUnknownParams = (state: GlobalState) => state.search?.url?.params?.unknownParams;

const selectSearchHistory = (state: GlobalState) => state.user?.searchHistory;

const selectRecentlyViewed = (state: GlobalState) => state.user?.recentlyViewedProperties;

/**
 * Selects whether featured agencies have been loaded.
 */
const selectFeaturedAgenciesLoaded = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state: GlobalState) => state.featuredAgencies,
    (featuredAgencies) => featuredAgencies?.loaded,
);

/**
 * Selects the featured agencies
 */
const selectFeaturedAgenciesHits = createSelector(
    // @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
    (state: GlobalState) => state.featuredAgencies,
    (featuredAgencies) => featuredAgencies?.data?.hits || EMPTY_ARRAY,
);

/**
 * Selects the view section for the SearchHits component.
 */
const selectSearchHitsViewSection = createSelector(
    selectIsRemarketingPage,
    selectRemarketedListing,
    selectIsSearchResultsView,
    selectIsRecommendationsView,
    selectIsFavoritesView,
    (
        isRemarketingPage,
        remarketedListing,
        isSearchResultsView,
        isRecommendationsView,
        isFavoritesView,
    ) => {
        if (
            isRemarketingPage &&
            remarketedListing?.completionStatus === PropertyCompletionStatus.OFF_PLAN
        ) {
            return ViewSections.OFF_PLAN_REMARKETING;
        }

        if (isRemarketingPage) {
            return ViewSections.REMARKETING;
        }

        if (isSearchResultsView) {
            return ViewSections.SEARCH_RESULTS;
        }

        if (isRecommendationsView) {
            return ViewSections.RECOMMENDED_PROPERTIES_PAGE;
        }

        if (isFavoritesView) {
            return ViewSections.FAVORITES;
        }

        return null;
    },
);

/**
 * Multiple selectors which determine if a filter is active or not.
 * We consider a filter active, when it narrows down the results.
 */
const selectIsBathsFilterActive = createSelector(selectBaths, (baths) => !isEmpty(baths));

const selectIsBedsFilterActive = createSelector(selectBeds, (beds) => !isEmpty(beds));

const selectIsCategoryFilterActive = createSelector(
    selectCategory,
    (category) => !isNull(category) && category.slug !== PropertyTypes.ANY,
);

const selectIsCompletionStatusFilterActive = createSelector(
    selectCompletionStatus,
    (completionStatus) =>
        !isNull(completionStatus) && completionStatus !== PropertyCompletionStatus.ANY,
);

const selectIsFurnishingStatusFilterActive = createSelector(
    selectFurnishingStatus,
    (furnishingStatus) =>
        !isNull(furnishingStatus) && furnishingStatus !== PropertyfurnishingStatus.ANY,
);

const selectIsAreaFilterActive = createSelector(
    selectArea,
    (area) => !!area.min || !isNil(area.max),
);

const selectIsPriceFilterActive = createSelector(
    selectPrice,
    (price) => !!price.min || !isNil(price.max),
);

const selectIsPurposeFilterActive = createSelector(selectPurpose, (purpose) => !isNull(purpose));

const selectIsRentFrequencyFilterActive = createSelector(
    selectRentFrequency,
    (rentFrequency) => !isNil(rentFrequency) && rentFrequency !== PropertyRentFrequency.ANY,
);

const selectIsSaleTypeFilterActive = createSelector(
    selectSaleType,
    (saleType) => !isNil(saleType) && saleType !== OffPlanPropertySaleType.ANY,
);

const selectIsHandoverDateFilterActive = createSelector(
    selectHandoverDate,
    (handoverDate) => !FilterValues.handoverDate.isDefault(handoverDate),
);

const selectCompletionPercentageFilterActive = createSelector(
    selectCompletionPercentage,
    (completionPercentage) => !FilterValues.completionPercentage.isDefault(completionPercentage),
);

const selectPaymentPlanFilterActive = createSelector(
    selectPaymentPlan,
    (paymentPlan) => !FilterValues.preHandoverPayment.isDefault(paymentPlan),
);

const selectIsTimeSinceCreationFilterEnabled = createSelector(
    selectTimeSinceCreation,
    (timeSinceCreation) => !isNil(timeSinceCreation),
);

const selectSearchMode = (state: GlobalState) => state.searchMode;

const selectIsTransactionsPage = (state: GlobalState) => state.transactionsPage != null;

const selectIsAgencySearch = createSelector(selectRouteName, (routeName) =>
    [RouteNames.AGENCY_DETAIL, RouteNames.AGENT_DETAIL].includes(routeName),
);

const selectSummaryElements = createSelector(
    selectIsCommuteActive,
    selectIsCompletionStatusFilterEnabled,
    selectIsOccupancyStatusFilterEnabled,
    selectIsFurnishingStatusFilterEnabled,
    selectIsSaleTypeFilterEnabled,
    selectIsVerifiedSortingEnabled,
    selectIsMapBasedSearchActive,
    selectIsProductLabelScoreSortingEnabled,
    selectRouteName,
    selectIsSearchResultsView,
    selectIsRemarketingPage,
    (
        isCommuteActive,
        isCompletionStatusFilterEnabled,
        isOccupancyStatusFilterEnabled,
        isFurnishingStatusFilterEnabled,
        isSaleTypeFilterEnabled,
        isVerifiedSortingEnabled,
        isMapBasedSearchActive,
        isProductLabelScoreSortingEnabled,
        routeName,
        isSearchResultsView,
        isRemarketingPage,
    ) => ({
        [FilterValues.completionStatus.attribute]:
            !isCommuteActive &&
            !!isCompletionStatusFilterEnabled &&
            routeName !== RouteNames.SEARCH,
        [FilterValues.occupancyStatus.attribute]: !!isOccupancyStatusFilterEnabled,
        [FilterValues.furnishingStatus.attribute]:
            !isCommuteActive && !!isFurnishingStatusFilterEnabled,
        [FilterValues.saleType.attribute]:
            routeName === RouteNames.SEARCH &&
            isSearchResultsView &&
            !isRemarketingPage &&
            !!isSaleTypeFilterEnabled,
        [AdSortTypeValues.VERIFIED_SCORE]:
            !!isVerifiedSortingEnabled &&
            !(routeName === RouteNames.SEARCH && !isMapBasedSearchActive),
        [AdSortTypeValues.PRODUCT_LABEL_SCORE]:
            !!isProductLabelScoreSortingEnabled &&
            !(routeName === RouteNames.SEARCH && !isMapBasedSearchActive),
    }),
);

const selectSummaryElementsCount = createSelector(
    selectSummaryElements,
    (summaryElements) => Object.values(summaryElements).filter((value) => !!value).length,
);

const selectIsSummaryEmpty = createSelector(
    selectSummaryElementsCount,
    (summaryElementsCount) => summaryElementsCount === 0,
);

export {
    selectIsCommercialCategory,
    selectPurpose,
    selectIsPurposeForRent,
    selectIsPurposeForSale,
    selectLocations,
    selectCategory,
    selectFurnishingStatus,
    selectRootCategory,
    selectIsRootCategoryResidential,
    selectBeds,
    selectBaths,
    selectArea,
    selectCity,
    selectCityName,
    selectPurposeName,
    selectLocationName,
    selectCategoryName,
    selectLocationHierarchy,
    selectCategoryHierarchy,
    selectSearchResponse,
    selectTotalHitCount,
    selectCurrentPage,
    selectHitsPerPage,
    selectHitOffset,
    selectHasHits,
    selectHits,
    selectMapAlgoliaHitValues,
    selectAreHitsLoaded,
    selectPopularSearches,
    selectRecommendations,
    selectLocationFallbackRecommendations,
    selectShowLocationFallbackRecommendationHits,
    selectRecommendationsCount,
    selectHasRecommendations,
    selectAreRecommendationsLoading,
    selectSearchPageParameters,
    selectIsCustomPage,
    selectSearchInteractionParams,
    selectIsRemarketingPage,
    selectRemarketedListing,
    selectShowRemarketedListing,
    selectRemarketedListings,
    selectIsRecommendationsView,
    selectShowNewRecommendations,
    selectIsMapBasedSearchActive,
    selectIsCommuteActive,
    selectIsSearchPageWithMapActive,
    selectFiltersListVisible,
    selectHasOtherPurpose,
    selectPrice,
    selectRentFrequency,
    selectIsCompletionStatusFilterEnabled,
    selectIsRentFrequencyFilterEnabled,
    selectIsResidenceTypeFilterEnabled,
    selectIsOccupancyStatusFilterEnabled,
    selectIsFurnishingStatusFilterEnabled,
    selectIsTimeSinceCreationFilterEnabled,
    selectIsVerifiedSortingEnabled,
    selectSummaryIsAtTop,
    selectIsMobileMapBasedSearch,
    selectShowMapBasedSearchToggle,
    selectFirstLocation,
    selectFirstLocationType,
    selectIsFavoritesView,
    selectAreHitsLoading,
    selectShowRecommendationHits,
    selectShowRecommendationsInfo,
    selectShowSearchHits,
    selectSortPreference,
    selectTotalPagesCount,
    selectSearchUnknownParams,
    selectFeaturedAgenciesLoaded,
    selectFeaturedAgenciesHits,
    selectSearchView,
    selectIsSearchResultsView,
    selectSearchHitsViewSection,
    selectHitExternalIDs,
    selectSearchHistory,
    selectRecentlyViewed,
    selectPropertyHits,
    selectMapPropertyHits,
    transformProperties,
    selectCompletionStatus,
    selectTimeSinceCreation,
    selectContractRenewalStatus,
    selectAreHitsAvailable,
    selectIsAreaFilterActive,
    selectIsBathsFilterActive,
    selectIsBedsFilterActive,
    selectIsCategoryFilterActive,
    selectIsCompletionStatusFilterActive,
    selectIsFurnishingStatusFilterActive,
    selectIsPriceFilterActive,
    selectIsPurposeFilterActive,
    selectIsRentFrequencyFilterActive,
    selectIsProductLabelScoreSortingEnabled,
    selectSearchMode,
    selectPurposeWithOffplan,
    selectProject,
    selectIsProjectPage,
    selectSaleType,
    selectIsSaleTypeFilterActive,
    selectIsSaleTypeFilterEnabled,
    selectIsTransactionsPage,
    selectIsProjectCompletionStatusPage,
    selectListingType,
    selectIsProjectSearchEnabled,
    selectIsProjectSearchAvailable,
    selectIsProjectMapSearchEnabled,
    selectIsProjectMapSearchActive,
    selectEnabledListingType,
    selectIsHandoverDateFilterActive,
    selectPaymentPlanFilterActive,
    selectCompletionPercentageFilterActive,
    selectHandoverDate,
    selectCompletionPercentage,
    selectPaymentPlan,
    selectIsHandoverDateFilterEnabled,
    selectIsPaymentPlanFilterEnabled,
    selectIsCompletionPercentageFilterEnabled,
    selectTruBrokerPurpose,
    selectSummaryElements,
    selectIsSummaryEmpty,
    selectSummaryElementsCount,
    selectIsAgencySearch,
    selectShowProjectRecommendations,
};
