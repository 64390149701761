import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRouteParams } from 'strat/routes/selectors';
import settings from '@app/branding/settings';
import { Choice } from 'strat/components/types';

import { GlobalState } from 'horizontal/state';
import { selectLocationBySlug } from 'horizontal/location';
import { defaultProfileFilterValues } from 'horizontal/agency/routing/constants';
import { propertyExtraFieldsToFilters } from 'horizontal/agency/routing/mappers';
import { selectCategoryByExternalID, selectCategoryBySlug } from 'horizontal/categories';
import { Category, TransactionPurpose } from 'horizontal/types';
import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import BrandingCategory from 'horizontal/branding/category';

import { AgencyExtraFilters } from '../types';

import useProfileParams from './useProfileParams';

export const useExtraSearchFilterValues = (category: Category | null | undefined) => {
    const { extraFields } = useCurrentSearchRouteParams();
    if (!extraFields) {
        return defaultProfileFilterValues;
    }

    return {
        ...propertyExtraFieldsToFilters(extraFields),
        purpose: category?.purpose || TransactionPurpose.ALL,
    };
};

export const useCategoryFilter = (): Category | null => {
    const { category: categorySlug } = useSelector(selectRouteParams);
    const category = useSelector((state: GlobalState) => selectCategoryBySlug(state, categorySlug));
    return category || null;
};

const usePropertyFilters = () => {
    const categoryFilter = useCategoryFilter();
    const { purpose: routePurpose, extraFields, locationSlug } = useProfileParams();
    const location = useSelector((state: any) => selectLocationBySlug(state, locationSlug || ''));
    const [purpose, setPurpose] = React.useState<Values<typeof TransactionPurpose>>(
        routePurpose || TransactionPurpose.ALL,
    );
    const [category, setCategory] = React.useState<Category | null>(categoryFilter);
    const [categoryList, setCategoryList] = React.useState<Array<Choice<string>>>([]);
    const [extraFilters, setExtraFilters] = React.useState<AgencyExtraFilters>(
        (extraFields && {
            price: { min: extraFields.price?.min || null, max: extraFields.price?.max || null },
            area: { min: extraFields.ft?.min || null, max: extraFields.ft?.max || null },
            beds: extraFields.rooms || [],
            baths: extraFields.bathrooms || [],
        }) ||
            defaultProfileFilterValues,
    );

    const setFilter = React.useCallback(
        (attribute: string, value: any) => {
            setExtraFilters({
                ...extraFilters,
                [attribute]: value,
            });
        },
        [extraFilters, setExtraFilters],
    );

    const shoudHideBedsAndBathFilter = React.useCallback(
        (category: Category) =>
            BrandingCategory.isOfCommercialSubtype(category) ||
            BrandingCategory.isOfLandSubtype(category),
        [],
    );

    const hideBedsAndBathFilter = React.useMemo(
        () => category && shoudHideBedsAndBathFilter(category),
        [category, shoudHideBedsAndBathFilter],
    );

    const propertiesCategory = useSelector((state: GlobalState) =>
        selectCategoryByExternalID(state, settings.verticalProperties?.externalID),
    );

    const clearExtraFields = () => {
        setExtraFilters(defaultProfileFilterValues);
        setPurpose(TransactionPurpose.ALL);
    };

    React.useEffect(() => {
        if (
            purpose !== TransactionPurpose.ALL &&
            propertiesCategory?.children.length &&
            categoryList.length === 0
        ) {
            const propertiesCategoriesUnderPurpose = propertiesCategory.children
                .filter((category) => category.purpose === purpose)
                .map((category) => ({
                    label: category.name,
                    value: category.slug,
                }));
            if (propertiesCategoriesUnderPurpose.length) {
                setCategoryList(propertiesCategoriesUnderPurpose);
            }
        }
    }, [purpose, propertiesCategory, categoryList, setCategoryList]);

    React.useEffect(() => {
        if (routePurpose) {
            setPurpose(routePurpose);
            return;
        }
        setPurpose(TransactionPurpose.ALL);
    }, [routePurpose]);

    React.useEffect(() => {
        setExtraFilters({
            price: extraFields?.price || defaultProfileFilterValues.price,
            area: extraFields?.ft || defaultProfileFilterValues.area,
            beds: extraFields?.rooms || defaultProfileFilterValues.beds,
            baths: extraFields?.bathrooms || defaultProfileFilterValues.baths,
        });
    }, [extraFields.ft, extraFields.bathrooms, extraFields.rooms, extraFields.price]);

    return {
        clearExtraFields,
        extraFilters,
        purpose,
        category,
        categoryFilter,
        categoryList,
        propertiesCategory,
        setExtraFilters,
        setFilter,
        setPurpose,
        setCategory,
        setCategoryList,
        hideBedsAndBathFilter,
        shoudHideBedsAndBathFilter,
        routeParams: {
            price: extraFields.price,
            beds: extraFields.rooms,
            baths: extraFields.bathrooms,
            location,
        },
    };
};

export default usePropertyFilters;
