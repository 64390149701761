import * as React from 'react';
import classNames from 'classnames';
import { AdBanner } from 'strat/tagmanager';
import type { Size } from 'strat/tagmanager';

import useAdUnitName from './useAdUnitName';
import { PageType, Position } from './types';
import useHomeTargetingParams from './useHomeTargetingParams';
import styles from './styles/banner.cssm';

type Props = {
    readonly sizes?: Array<Size>;
    readonly position: Values<typeof Position>;
    readonly slotIndex?: number;
    readonly className?: string;
};

const HomeBanner = ({ sizes, position, slotIndex, className }: Props) => {
    const name = useAdUnitName(PageType.HOME);
    const targetingParams = useHomeTargetingParams(position, slotIndex);

    return (
        <AdBanner
            name={name}
            parameters={targetingParams}
            index={slotIndex}
            sizes={sizes}
            className={classNames(styles.banner, className)}
        />
    );
};

export default HomeBanner;
