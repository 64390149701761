import React from 'react';
import classNames from 'classnames';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';
import { Breadcrumbs } from '@app/navigation/breadcrumbs';
import Category from '@app/branding/category';
import { useSelector } from 'react-redux';

import { BreadcrumbsElement } from 'strat/navigation/breadcrumbs';
import type { LocationNodeData } from 'strat/property/types';
import { FilterValues } from 'strat/search';
import { selectIsProjectCompletionStatusPage } from 'strat/search/selectors';
import { PropertyCompletionStatus } from 'strat/property/types';
import { selectPageParameters } from 'strat/search/state/selectors';
import { useI18n } from 'strat/i18n/language';

import { DisplayCategory } from './breadcrumbs/displayCategory';

type Props = {
    className?: string;
    displayCategory?: Values<typeof DisplayCategory>;
    withRootLocation?: boolean;
};

const BreadcrumbsSearch = ({
    className,
    displayCategory = DisplayCategory.NONE,
    withRootLocation,
}: Props) => {
    const i18n = useI18n();
    const filters = useSelector(getFilterCollection);
    const { breadcrumbLeafText, isCustomPage } = useSelector(selectPageParameters);
    const isProjectCompletionStatusPage = useSelector(selectIsProjectCompletionStatusPage);
    // get the needed values of the filters
    const locations: Array<LocationNodeData> | null = filters.getFilterValue(
        FilterValues.location.attribute,
    );
    const mainLocationHierarchy = locations ? locations[0].hierarchy : locations;
    if (!mainLocationHierarchy || mainLocationHierarchy.length <= 1) {
        return null;
    }
    const listingType = filters.getFilterValue(
        FilterValues.type.attribute,
        FilterValues.type.default,
    );
    const purpose = filters.getFilterValue(
        FilterValues.purpose.attribute,
        FilterValues.purpose.default,
    );
    const category = filters.getFilterValue(
        FilterValues.category.attribute,
        FilterValues.category.default,
    );
    const beds = filters.getFilterValue(FilterValues.beds.attribute);

    // sort the location hierarchy based on the location levels (increasing order)
    mainLocationHierarchy.sort((a, b) => a.level - b.level);

    // limit the location hierarchy if needed. +1 because we want to include the country level 0.
    const slicedLocationHierarchy = mainLocationHierarchy.slice(
        0,
        CONFIG.build.AD_LOCATION_BREADCRUMB_URL_MAX_LEVEL + 1,
    );

    // get the last location from the location hierarchy (with the greatest level)
    const lastLocation = slicedLocationHierarchy.slice(-1)[0];

    // construct the last breadcrumb element
    let lastBreadcrumbElement: BreadcrumbsElement = Object.assign({}, lastLocation);
    lastBreadcrumbElement.category = category;
    /**
     * There are 2 cases for displaying the last breadcrumb, since category always exists (selected, or default)
     * - CASE 1: only category is selected: the last breadcrumb contains the last location and the category name
     * - CASE 2: both category and beds are selected: the last breadcrumb contains the last location, the category name and the
     *   number of beds
     */

    let breadcrumbElements: Array<any> = [];
    const categoryParent = category?.parent
        ? FilterValues.category
              .choices(i18n)
              .find((cat) => cat.externalID === category?.parent?.externalID)
        : null;
    // @ts-ignore
    const isResidential = categoryParent && categoryParent.slug === Category.RESIDENTIAL;

    const hasBedsFilter = Array.isArray(beds) && beds.length === 1;

    breadcrumbElements = withRootLocation
        ? slicedLocationHierarchy
        : slicedLocationHierarchy.slice(1);

    if ((isResidential && hasBedsFilter) || isCustomPage) {
        /**
         * CASE 2
         * put the last element in the elements array as well;
         * the last location will appear two times, but the since the last element will contain possibly beds number (beside category location, this makes sense)
         * */
        if (hasBedsFilter) {
            lastBreadcrumbElement.beds = beds[0];
        }

        if (lastBreadcrumbElement.level === 1 && breadcrumbElements.length >= 1) {
            // this is very important; there is a bug if we do not do this
            // slice(1) -> means that the last breadcrumb will be duplicated, so there will be 2 elements with level 1
            // update the level of the last element, in case the breadcrumb array already contains an element (which will have level 1)
            // the new value is not relevant, the idea is that it must not be 1!
            lastBreadcrumbElement.level = 2;
        }
    } else {
        /**
         * CASE 1
         * we do not have beds number, we do not add the last location in the array; no purpose in duplicating it
         * the last element will contain it along with the category
         */
        breadcrumbElements = breadcrumbElements.slice(0, -1);
    }

    if (breadcrumbLeafText) {
        // overwrite last breadcrumb element if such an element is specified
        lastBreadcrumbElement = { name: breadcrumbLeafText, slug: '' };
    }

    if (!hasBedsFilter || !categoryParent || breadcrumbLeafText) {
        breadcrumbElements.push(lastBreadcrumbElement);
    }
    if (hasBedsFilter && breadcrumbElements.length > 0) {
        const duplicatedElement = Object.assign({}, breadcrumbElements[0]);
        breadcrumbElements.unshift(duplicatedElement);
        breadcrumbElements = breadcrumbElements.map((element, index) => {
            if (index !== 0) {
                return Object.assign({}, element, { beds: beds[0] });
            }
            return element;
        });
    }
    if (breadcrumbElements.length > 0) {
        const lastElement = breadcrumbElements[breadcrumbElements.length - 1];
        breadcrumbElements[breadcrumbElements.length - 1] = { ...lastElement, disabled: true };
    }

    if (isProjectCompletionStatusPage) {
        breadcrumbElements = breadcrumbElements.map((element) => ({
            ...element,
            completionStatus: PropertyCompletionStatus.OFF_PLAN,
        }));
    }

    if (listingType) {
        breadcrumbElements = breadcrumbElements.map((element) => ({ ...element, listingType }));
    }

    return (
        <Breadcrumbs
            className={classNames(className, 'fontCompensation')}
            // @ts-ignore
            elements={breadcrumbElements}
            purpose={purpose}
            category={category}
            displayCategory={displayCategory}
            withPurposeHeading
        />
    );
};

export default BreadcrumbsSearch;
