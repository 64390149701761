import { Agency } from 'horizontal/types';

import useIsAgencyExternallyManaged from './useIsAgencyExternallyManaged';

type Props = {
    readonly isWhatsAppActive: boolean;
    readonly isShowPhoneNumberActive: boolean | undefined;
    readonly agency: Agency | undefined;
};
const useShowWhatsAppButton = ({
    isWhatsAppActive,
    isShowPhoneNumberActive,
    agency,
}: Props): boolean | undefined => {
    const isAgencyExternallyManaged = useIsAgencyExternallyManaged(agency);

    // For Strat Agencies and normal users ads, whatsApp will only be displayed if phone is enabled as well
    return isAgencyExternallyManaged
        ? isWhatsAppActive
        : isWhatsAppActive && isShowPhoneNumberActive;
};

export default useShowWhatsAppButton;
