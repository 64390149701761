import { CancelableRoute } from 'react-true-router';
import { EnhancedLocation, serializeLocation } from 'react-true-router/location';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';
import { selectUserExternalID, selectUserRoles } from 'strat/user/session';
import { isProAgencyOwner } from 'strat/user/roles';

import Page from 'horizontal/agencyPortal/pages/page';
import { getStratAPI, StratAPI } from 'horizontal/api';
import {
    type AdProductCreditCost,
    AdState,
    type AgencyProductCreditSummary,
    type AgentProductCreditSummary,
    type FullAd,
} from 'horizontal/types';
import { fetchUserAgencies, UserAgency } from 'horizontal/agent/state';
import { createAdDetailsURL } from 'horizontal/routes/adDetails';

import ensureHasAccessToStratCredits from './ensureHasAccessToStratCredits';
import ensureCanAccessAgencyPortal from './ensureCanAccessAgencyPortal';

export type AgencyPortalApplyAdProductsCreditCostRouteParams = {
    adExternalID: string;
};

class AgencyPortalApplyAdProductsCreditCostRoute extends CancelableRoute {
    constructor() {
        super(RouteNames.AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST, [
            [
                '^/agencyPortal/posting/credit/apply/',
                {
                    name: 'adExternalID',
                    pattern: '([0-9]+)',
                },
                '(?:\\?)?',
            ],
        ]);
    }

    createURL(
        { adExternalID }: AgencyPortalApplyAdProductsCreditCostRouteParams,
        _: RoutingContextWithMiddlewares,
    ): EnhancedLocation {
        return {
            pathname: `/agencyPortal/posting/credit/apply/${adExternalID}`,
        };
    }

    renderNotFoundPage(context: RoutingContextWithMiddlewares) {
        context.http.status(404);
        context.rendering.renderPage(Page.AGENCY_PORTAL_NOT_FOUND);
        return Promise.resolve();
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        if (!ensureCanAccessAgencyPortal(context) || !ensureHasAccessToStratCredits(context)) {
            return;
        }

        context.rendering.renderPage(Page.AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST, {
            ad: null,
            adProductsCreditCost: [],
            userAvailableCredits: null,
        });

        const dataPromise = Promise.all([
            this.fetchAd(context),
            this.fetchAdProductCreditCosts(context),
        ]);

        context.promise.wait(
            dataPromise.then(([ad, adProductsCreditCost]) => {
                if (!ad) {
                    return this.renderNotFoundPage(context);
                }

                if (![AdState.ACTIVE, AdState.PENDING, AdState.LIMITED].includes(ad.state)) {
                    const adDetailsURL = serializeLocation(
                        createAdDetailsURL({ externalID: ad.externalID, slug: ad.slug }),
                    );
                    context.http.redirect(adDetailsURL, { status: 302 });
                    return;
                }

                return this.fetchUserAvailableCredits(context).then((userAvailableCredits) => {
                    context.rendering.renderPage(Page.AGENCY_PORTAL_APPLY_AD_PRODUCTS_CREDIT_COST, {
                        ad,
                        adProductsCreditCost,
                        userAvailableCredits,
                    });
                });
            }),
        );
    }

    fetchAd(context: RoutingContextWithMiddlewares): Promise<FullAd | null | undefined> {
        const {
            redux: {
                store: { getState },
            },
            match: {
                params: { adExternalID },
            },
        } = context;

        const userExternalID = selectUserExternalID(getState());
        if (!adExternalID || !userExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) => api.userAd(userExternalID, adExternalID));
    }

    fetchAdProductCreditCosts(
        context: RoutingContextWithMiddlewares,
    ): Promise<AdProductCreditCost[] | null | undefined> {
        const {
            match: {
                params: { adExternalID },
            },
        } = context;

        if (!adExternalID) {
            return Promise.resolve(null);
        }

        return this.makeStratAPIRequest(context, (api) =>
            api.getAdProductCreditCosts(adExternalID),
        );
    }

    fetchUserAvailableCredits(context: RoutingContextWithMiddlewares): Promise<number | null> {
        const {
            redux: {
                store: { getState, dispatch },
            },
        } = context;

        const isOwner = isProAgencyOwner(selectUserRoles(getState()));
        const userExternalID = selectUserExternalID(getState());
        if (!userExternalID) {
            // this should never happen since we check that the user exists onEnter
            return Promise.resolve(null);
        }

        return dispatch(fetchUserAgencies({ userExternalID })).then(
            ({ data: userAgencies }: { data: UserAgency[] }) => {
                if (isOwner) {
                    return this.makeStratAPIRequest(context, (api) =>
                        api.getAgencyProductCreditSummary(userAgencies[0]?.externalID),
                    ).then(
                        (creditSummary: AgencyProductCreditSummary | null) =>
                            creditSummary?.availableCredits,
                    );
                }
                return this.makeStratAPIRequest(context, (api) =>
                    api.getAgentUserProductCreditSummary(
                        userAgencies[0]?.externalID,
                        userExternalID,
                    ),
                ).then(
                    (creditSummary: AgentProductCreditSummary | null) => creditSummary?.available,
                );
            },
        );
    }

    private makeStratAPIRequest<T>(
        context: RoutingContextWithMiddlewares,
        callback: (api: StratAPI) => Promise<{ status: number; data: T }>,
    ) {
        const {
            redux: {
                store: { getState },
            },
        } = context;

        const api = getStratAPI(getState());
        const request = this.cancelable(callback(api));

        return request.then((response) => (response.status < 300 ? response.data : null));
    }
}

export default new AgencyPortalApplyAdProductsCreditCostRoute();
