import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';

import { fetchSellerProfile } from 'horizontal/seller/state';
import { clearAgency } from 'horizontal/agency/state';
import Page from 'horizontal/pages/page';

import ProfileRoute from './profileRoute';
import { convertInt } from './uuid';

export type SellerProfileRouteParams = {
    readonly externalID: string;
    readonly page?: number;
};

class SellerProfileRoute extends ProfileRoute {
    constructor() {
        super(
            RouteNames.SELLER_PROFILE,
            [
                [
                    '^/profile/',
                    {
                        name: 'legacyExternalID',
                        pattern: '([0-9]+)',
                    },
                    '(?:\\?.*)?$',
                ],
                [
                    '^/profile/',
                    {
                        name: 'externalID',
                        pattern: '([\\w]{8}(?:-[\\w]{4}){3}-[\\w]{12})',
                    },
                    '/',
                    { name: 'locationSlug', pattern: '((?!q-)[A-z0-9-_&]*)' },
                    '/?(?:(?:\\?|#).*)?$',
                ],
                [
                    '^/profile/',
                    {
                        name: 'externalID',
                        pattern: '([\\w]{8}(?:-[\\w]{4}){3}-[\\w]{12})',
                    },
                    '(?:\\?.*)?$',
                ],
                ['^/myaccount'],
            ],
            'userExternalID',
            'profile',
            Page.SELLER_PROFILE,
        );
    }

    handleNoExternalID(context: RoutingContextWithMiddlewares) {
        const {
            match: {
                params: { legacyExternalID, page, locationSlug },
            },
        } = context;
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
        const uuid = convertInt(legacyExternalID);
        if (!uuid) {
            context.http.status(500);
            context.rendering.renderPage(Page.INTERNAL_ERROR);
            return;
        }

        // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
        const url = this.createURL({
            externalID: uuid,
            locationSlug,
            page,
        });

        // @ts-expect-error - TS2345 - Argument of type 'EnhancedLocation' is not assignable to parameter of type 'string'.
        context.http.redirect(url, { status: 301 });
    }

    getProfileExternalID(context: RoutingContextWithMiddlewares) {
        const {
            match: {
                params: { externalID },
            },
        } = context;
        return externalID;
    }

    fetchProfile(context: RoutingContextWithMiddlewares): Promise<any> {
        const {
            redux: {
                store: { dispatch },
            },
            match: {
                params: { externalID },
            },
        } = context;

        dispatch(clearAgency());
        return Promise.all([dispatch(fetchSellerProfile({ externalID }))]);
    }
}

export default new SellerProfileRoute();
