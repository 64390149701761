import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import EMPTY_ARRAY from 'strat/empty/array';

import type { GlobalState } from 'horizontal/state';
import { findDeepList } from 'horizontal/util/findDeep';
import { Category, CategoryRole, CategoryConfigurations } from 'horizontal/types';
import { sortByDisplayPriority } from 'horizontal/util';

import { findCategoryByExternalID, findCategoryBySlug } from './findCategoriesBy';

const selectCategories = createSelector(
    (state: GlobalState) => state.categories.data || (EMPTY_ARRAY as Category[]),
    (categories) =>
        categories.sort(sortByDisplayPriority).map((category) => ({
            ...category,
            children: category.children.sort(sortByDisplayPriority),
        })),
);

const selectCategoryByExternalID = createSelector(
    selectCategories,
    (_: any, externalID?: string | null) => externalID,
    (categories, externalID) => findCategoryByExternalID(categories, externalID),
);

const selectCategoryHierarchyByExternalID = createSelector(
    selectCategories,
    (_: any, externalID?: string | null) => externalID,
    (categories, externalID) =>
        findDeepList(categories, (category) => category.externalID === externalID, true),
);

const selectCategoryBySlug = createSelector(
    selectCategories,
    (_: any, slug: string) => slug,
    (categories, slug) => findCategoryBySlug(categories, slug),
);

const selectCategoryHierarchyBySlug = createSelector(
    selectCategories,
    (_: GlobalState, slug?: string | null) => slug,
    (categories, slug) =>
        findDeepList<Category>(categories, (category) => category.slug === slug, true),
);

const selectShouldShowPhoneNumberByCategory = createSelector(
    selectCategoryByExternalID,
    (category): boolean => (category?.roles || []).includes(CategoryRole.SHOW_PHONE_NUMBER),
);

const selectIsBTTFeaturedEnabledForCategory = createSelector(
    selectCategoryHierarchyByExternalID,
    (categories) => {
        if (!categories) {
            return false;
        }
        return (
            CONFIG.build.STRAT_ENABLE_FEATURED_BTT &&
            categories.some((category): boolean => category.roles.includes(CategoryRole.ALLOWS_BTT))
        );
    },
);

const selectCategoryConfigurations = createSelector(
    selectCategoryHierarchyByExternalID,
    (categoryHierarchy): CategoryConfigurations => {
        const categoryWithConfigurations = [...categoryHierarchy]
            .reverse()
            .find(
                (category) =>
                    !isEmpty(category.configurations) &&
                    Object.values(category.configurations).some((configValue) => !!configValue),
            );
        return (categoryWithConfigurations?.configurations ?? {}) as CategoryConfigurations;
    },
);

export {
    selectCategories,
    selectCategoryByExternalID,
    selectCategoryHierarchyByExternalID,
    selectCategoryBySlug,
    selectCategoryHierarchyBySlug,
    selectShouldShowPhoneNumberByCategory,
    selectIsBTTFeaturedEnabledForCategory,
    selectCategoryConfigurations,
};
