import { useDispatch } from 'react-redux';
import * as React from 'react';

import type { AppDispatch } from 'horizontal/state';

import { fetchAdMetrics } from '../adMetrics';

import useMyAds from './useMyAds';

const useFetchAdMetrics = () => {
    const dispatch = useDispatch<AppDispatch>();
    const ads = useMyAds();
    const adExternalIDs = React.useMemo(() => ads.slice(0, 30).map((ad) => ad.externalID), [ads]);

    React.useEffect(() => {
        if (adExternalIDs && adExternalIDs.length > 0) {
            dispatch(fetchAdMetrics({ adExternalIDs }));
        }
    }, [dispatch, adExternalIDs]);
};

export default useFetchAdMetrics;
