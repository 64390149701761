import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import CommonHead from './commonHead';

const InternalErrorHead = () => {
    const i18n = useI18n();
    const domainName = settings.getDomainName(i18n);

    return (
        <>
            <title>
                <Trans>Internal error | {domainName}</Trans>
            </title>
            <meta name="robots" content="noindex, nofollow" />
            <CommonHead />
        </>
    );
};

export default InternalErrorHead;
