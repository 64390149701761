import settings from '@app/branding/settings';

import { HTTPApi, BayutCompat } from 'strat/util';
import type { UserRegistrationData } from 'strat/user/types';
import { ContextfulError } from 'strat/error/context';

/**
 * Provides access to the Bayut front end (Laravel) API.
 */
class BayutFrontEndAPI extends HTTPApi {
    /**
     * Turns the specified relative path into a absolute URL.
     * @param {string} path The path to turn into an absolute URl.
     * @returns {string} An absolute URL to the specified relative path.
     */
    buildAbsoluteUrl(path: string) {
        const localizedPath =
            this.language === CONFIG.build.LANGUAGE_CODE ? path : `/${this.language}${path}`;

        if (process.env.IS_SERVER) {
            return `${CONFIG.build.BAYUT_MPA_URL}${localizedPath}`;
        }

        return localizedPath;
    }

    /**
     * Makes a request to the Bayut API.
     */
    request(url: string, parameters: Record<any, any> | null = null, init: Record<any, any> = {}) {
        const requestUrl = this.buildAbsoluteUrl(url);
        return super.request(requestUrl, parameters, { ...init, credentials: 'include' });
    }

    /**
     * Makes a POST request to the Bayut API.
     */
    post(url: string, body: Record<any, any>) {
        const requestUrl = this.buildAbsoluteUrl(url);
        return super.post(requestUrl, body);
    }

    /*
     * POST request to /show-numbers.
     * Please catch promise response since it can return a status !== 200.
     * @param {externalID} externalID The property external id.
     */
    showNumbers(externalID: string) {
        const options = {
            method: 'POST',
            body: `property_id=${externalID}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } as const;

        return this.request('/show-numbers/', null, options).then(({ data, status }) => {
            if (status !== 200 || data.result.number.status !== 200) {
                throw new ContextfulError(
                    `Unexpected response status while fetching phone number`,
                    { status, data, id: externalID },
                );
            }

            return data.result.number;
        });
    }

    /**
     * Gets /featuredAgencies
     * @returns {Promise}
     */
    featuredAgencies() {
        return this.request('/featuredAgencies').then(({ data, status }) => ({
            data: {
                // @ts-expect-error - TS7006 - Parameter 'agency' implicitly has an 'any' type.
                fAgents: data.fAgents.map((agency) => ({
                    agent_id: agency.agent_id,
                    saleCount: agency.sale_count,
                    rentCount: agency.rent_count,
                    name: agency.name[this.language],
                    image: {
                        url: agency.images_list[0].base_url,
                        filePath: agency.images_list[0].image_file_path,
                    },
                    locality: (agency.location_breadcrumb.slice(-1) || [{}])[0].id,
                })),
            },
            status,
        }));
    }

    /**
     * Gets /ajax/get_popularity_trends
     */
    popularityTrends(externalID: string, categoryID?: number, purpose?: string): Record<any, any> {
        const promise = this.request('/ajax/get_popularity_trends', {
            property_id: externalID,
            proptype: categoryID,
            // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string | ((purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any) => any) | ((i18n: any, purpose: any, uppercaseVerb?: boolean) => any) | (() => string[]) | ((purpose: any) => 1 | ... 1 more ... | 3) | ((purpose:...'.
            purpose: BayutCompat.Purpose.toID(purpose),
        });

        return promise.then((response) => ({
            ...response,
            data: {
                icon: response.data.selected_svg || null,
                towers: Object.keys(response.data)
                    .filter((key) => key !== 'selected_svg')
                    .map((key) => response.data[key]),
            },
        }));
    }

    /*
     * Gets /ajax/get_property_search_index
     */
    areaTrends(externalID: string, purpose: string) {
        const promise = this.request('/ajax/get_property_search_index', {
            property_id: externalID,
            purpose: BayutCompat.Purpose.toID(purpose),
        });

        return promise;
    }

    /**
     * Registers a user using an ajax POST request.
     */
    register(userData: UserRegistrationData) {
        const promise = this.post('/registration_ajax/', {
            name: userData.name,
            email: userData.email,
            password: userData.password,
            users_roles: userData.userRoles ? userData.userRoles.join(',') : '',
            update: userData.withNotificationsSubscription,
            mobile: userData.phoneNumber,
        });
        return promise.then(({ data }) => data);
    }

    /**
     * Send multiple emails to agents.
     */
    sendMultipleEmails(
        senderName: string,
        senderEmail: string,
        senderPhone: string,
        senderMessage: string,
        agentIDs: string[] | number[],
        userType?: string,
        userExternalID?: string | null,
        _?: boolean /* keepMeInformed, used by Mustang */,
    ) {
        const options = {
            method: 'post',
            body: HTTPApi.buildQueryString({
                sender_name: senderName,
                sender_email: senderEmail,
                sender_phone: senderPhone,
                sender_message: senderMessage,
                agent_ids: (agentIDs || []).join(','),
                actor_user_id: userExternalID,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } as const;

        if (CONFIG.build.DISABLE_BAYUT_MPA || CONFIG.build.ENABLE_STRAT_EMAILS) {
            // @ts-expect-error - TS2540 - Cannot assign to 'body' because it is a read-only property.
            options.body += `&send_email_from_address=${settings.sendEmailFromAddress}`;
            return this.request('/api/send-multiple-emails/', null, options);
        }

        return this.request('/send-multiple-emails', null, options);
    }

    places(center: LatLng, category: string, radius: number) {
        return this.request('/api/places', {
            latitude: center.lat,
            longitude: center.lng,
            category,
            radius,
        }).then(({ data, status }) => ({
            // @ts-expect-error - TS7006 - Parameter 'place' implicitly has an 'any' type.
            data: ((data || {}).data || []).map((place) => ({
                name: place.name,
                rating: place.overall_star_rating,
                center: place.location
                    ? {
                          lat: place.location.latitude,
                          lng: place.location.longitude,
                      }
                    : null,
                category: (place.matched_categories || [])[0],
                id: place.id,
            })),

            status,
        }));
    }

    mapBasedSearch(purpose: string, category: string, location?: Array<string> | null) {
        // @ts-expect-error - TS2345 - Argument of type '{ purpose: string; location: string[] | null | undefined; category: string; }' is not assignable to parameter of type 'null | undefined'.
        return this.requestBinary(`/api/mapBasedSearch/`, {
            purpose,
            location,
            category,
        });
    }
}

export default BayutFrontEndAPI;
