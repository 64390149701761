import * as React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'strat/modal';

import { DropdownTrigger } from 'horizontal/components';

import { BirthDatePart } from './types';
import useDatePartValues from './useDatePartValues';
import styles from './styles/datePartPicker.cssm';

type Props = {
    readonly part: Values<typeof BirthDatePart>;
    readonly isOpened: boolean;
    readonly value?: number | null;
    readonly placeholder?: string;
    readonly onSelect: (part: Values<typeof BirthDatePart>, value: number) => void;
    readonly onClick: (part: Values<typeof BirthDatePart>, currentlyOpened: boolean) => void;
};

const DatePartPicker = ({
    part,
    isOpened,
    value = null,
    placeholder = '',
    onSelect,
    onClick,
}: Props) => {
    const datePartValues = useDatePartValues(part);

    const renderDropDownTrigger = () => (
        <DropdownTrigger
            isOpen={isOpened}
            placeholder={value ? '' : placeholder} // when a value is selected we don't want to have a placeholder
            setIsOpen={(open: boolean) => onClick(part, open)}
            value={value}
            name={part}
            selectedValueLabel={value ? value.toString() : null}
        />
    );

    return (
        <div className={styles.datePartPickerDropDownContainer}>
            <Dropdown
                triggerIsDismissble
                open={isOpened}
                onDismissed={() => {
                    onClick(part, false);
                }}
                renderTrigger={renderDropDownTrigger}
            >
                <div className={styles.datePartPickerValuesContainer}>
                    {/* @ts-expect-error - TS2532 - Object is possibly 'undefined'. */}
                    {datePartValues.map((dpv) => {
                        return (
                            <div
                                key={dpv}
                                onClick={() => {
                                    onSelect(part, dpv);
                                    onClick(part, false);
                                }}
                                className={classNames(styles.datePartPickerValueItem, {
                                    [styles.selectedValue]: dpv === value,
                                })}
                            >
                                {dpv}
                            </div>
                        );
                    })}
                </div>
            </Dropdown>
        </div>
    );
};

export default DatePartPicker;
