//@ts-nocheck
import Area from './area';
import Distance from './distance';
import ConversionTable from './conversionTable';

/**
 * Possible unit types.
 */
const UnitType = Object.freeze({
    Area,
    Distance,
});

/**
 * A unit of something (meters, kilometres etc).
 *
 * Assists in converting values between units.
 */
class Unit {
    /**
     * Constructs a new instance of {@link Unit}.
     * @param {number} value The unit value to represent.
     * @param {UnitType} unitType The type of the unit.
     * @param {$Keys<typeof UnitType>} baseUnit The base unit for the conversions.
     */
    constructor(value: any, unitType: any, baseUnit: any) {
        this.value = value;
        this.unitType = unitType;
        this.baseUnit = baseUnit;

        this.conversionMap = ConversionTable.get(this.baseUnit);
    }

    /**
     * Converts this unit value into another unit value.
     *
     * @param {string} unit The unit/type to convert to.
     * @returns {number} The converted value.
     */
    convert(unit: any) {
        if (this.conversionMap.has(unit)) {
            return this.conversionMap.get(unit)(this.value);
        }
        return this.value;
    }
}

export { Unit, UnitType };
