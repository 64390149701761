import * as React from 'react';

import usePriceRelatedFields from './usePriceRelatedFields';
import { PriceRelatedFieldProps } from './types';
import styles from './styles/priceRelatedFieldsSection.cssm';

const PriceRelatedFieldsSection = (props: PriceRelatedFieldProps) => {
    const priceRelatedFields = usePriceRelatedFields(props);

    if (priceRelatedFields.length === 0) {
        return null;
    }

    return <div className={styles.section}>{priceRelatedFields}</div>;
};

export default PriceRelatedFieldsSection;
