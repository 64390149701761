import type { Action, Dispatch, Store } from 'redux';
import { createSelector } from 'reselect';
import { SearchActions, fetchStandalone } from '@sector-labs/fe-search-redux/state';
import {
    FilterCollection,
    ExactFilter,
    RefinementFilter,
} from '@sector-labs/fe-search-redux/filters';
import settings from '@app/branding/settings';

import type { GlobalState, AppDispatch } from 'strat/state';
import {
    recommendationCountFor404Pages,
    recommendationCountForSearchPages,
} from 'strat/branding/search';
import { getPageRule } from 'strat/search/state/pageRule';
import FetcherFactory from 'strat/fetcher';
import { RecommenderBackEndAPI } from 'strat/api';
import { propertyTransformer } from 'strat/property';
import type { PropertyData } from 'strat/property';
import type { LocationNodeData, CategoryNodeData } from 'strat/property/types';
import {
    selectPurpose,
    selectBeds,
    selectLocationHierarchy,
    selectCategoryHierarchy,
    selectTotalHitCount,
    selectIsCustomPage,
    selectHitExternalIDs,
    selectIsProjectSearchEnabled,
    selectSearchResponse,
} from 'strat/search/selectors';
import { selectVisitorID, selectDeviceID, selectUserEmail } from 'strat/user/selectors';
import { prioritizeCategoryPurposeHits } from 'strat/recommendations/utils';

import fetchRecommendationAdsByLocationFallback from './recommendationsWithLocationFallback';

/**
 * Parameters based on which recommendations are fetched.
 */
type Params = {
    purpose: string;
    locationHierarchy: Array<LocationNodeData>;
    categoryHierarchy: Array<CategoryNodeData>;
    beds: string | null | undefined;
    totalHitCount: number;
    visitorID: string | null | undefined;
    isCustomPage: boolean;
    userEmail: string | null | undefined;
    deviceID: string;
    searchHitExternalIDs: string[];
    count: number | null | undefined;
    exactCategoryMatch?: boolean | null | undefined;
    hasSearchResponse: boolean;
};

/**
 * Selects the parameters that are send to the recommender.
 */
const selectRecommendationParams = createSelector(
    [
        selectPurpose,
        selectLocationHierarchy,
        selectCategoryHierarchy,
        selectBeds,
        selectTotalHitCount,
        selectIsCustomPage,
        selectVisitorID,
        selectDeviceID,
        selectUserEmail,
        selectHitExternalIDs,
        selectSearchResponse,
    ],
    (
        purpose,
        locationHierarchy,
        categoryHierarchy,
        beds,
        totalHitCount,
        isCustomPage,
        visitorID,
        deviceID,
        userEmail,
        searchHitExternalIDs,
        searchResponse,
    ) => ({
        purpose,
        locationHierarchy,
        categoryHierarchy,
        beds,
        totalHitCount,
        visitorID,
        isCustomPage,
        deviceID,
        userEmail,
        searchHitExternalIDs,
        hasSearchResponse: !!searchResponse,
    }),
);

/**
 * Sanitizes and transforms the response from the
 * recommender back-end into a flat list of ad ID's.
 */
// @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'status' implicitly has an 'any' type.
const sanitizeResponse = ({ data, status }) => {
    if (!Array.isArray(data) || status !== 200) {
        return [];
    }

    return data;
};

/**
 * Fetches ads with the specified ID's from Algolia.
 */
// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const fetchFromAlgolia = (adIDs: Array<string>, dispatch: Dispatch): Promise<Array<any>> => {
    if (!adIDs || !adIDs.length) {
        return Promise.resolve([]);
    }

    const filters = new FilterCollection();
    filters.refine(new ExactFilter({ attribute: 'page', value: 1 }));
    filters.refine(
        new RefinementFilter({
            attribute: 'objectID',
            value: adIDs,
        }),
    );

    return dispatch(
        fetchStandalone(filters, {
            facets: [],
            // @ts-expect-error - TS2345 - Argument of type '{ facets: never[]; count: number; }' is not assignable to parameter of type 'Partial<SearchStateSettings>'.
            count: adIDs.length,
        }),
    );
};

/**
 * Transforms the response from Algolia into a typical fetch
 * response that the fetcher factory understands.
 */
const transformAlgoliaResponse = (data: any) => ({
    data: (data || {}).hits || [],
    status: 200,
});

/**
 * Fetches recommendations from the recommender
 * for the specified parameters.
 */
// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const fetchFromRecommender = (params: Params, dispatch: Dispatch): Promise<any> =>
    new RecommenderBackEndAPI()
        // @ts-expect-error TS2345: Argument of type '{ purpose: string; location: LocationNodeData[]; category: CategoryNodeData[]; clientID: string | null | undefined; userEmail: string | null │ undefined; sessionID: string; count: number | ... 1 more ... | undefined; }' is not assignable to parameter of type 'PropertyInteractionParams'.
        .trackVisitAndFetchRecommendations({
            purpose: params.purpose,
            location: params.locationHierarchy,
            category: params.categoryHierarchy,
            clientID: params.visitorID,
            userEmail: params.userEmail,
            sessionID: params.deviceID,
            count: params.count,
        })
        .then(sanitizeResponse)
        .then((adIDs) => fetchFromAlgolia(adIDs, dispatch))
        .then(transformAlgoliaResponse);

/**
 * Filter recommendations taking into consideration search hits
 * in order to prevent duplicates.
 */
const filterSearchCollisions = (
    recommenderHits: PropertyData[],
    searchHitExternalIDs: string[],
): PropertyData[] => {
    const externalIDs = new Set(searchHitExternalIDs);
    return recommenderHits.filter((hit) => !externalIDs.has(hit.externalID));
};

const processHits = (hits: PropertyData[], searchHitExternalIDs: string[], language: string) =>
    filterSearchCollisions(hits, searchHitExternalIDs).map((hit) =>
        propertyTransformer(hit, language, CONFIG.build.ENABLE_MERGED_INDEX),
    );

const getRecommendationsThreshold = (hitsPerPage?: number) => {
    if (settings.enableSearchRecommendedPropertiesWithLocationFallback && hitsPerPage) {
        return hitsPerPage;
    }
    return parseInt(CONFIG.build.RECOMMENDED_ADS_FILLER_TRESHOLD, 10);
};

const fetchRecommenderRecommendations = (
    params: Params,
    dispatch: Dispatch<AppDispatch>,
    language: string,
) =>
    fetchFromRecommender(params, dispatch).then(({ data, status }) => ({
        hits: processHits(data, params.searchHitExternalIDs, language),
        status,
    }));

const fetchLocationFallbackRecommendations = (
    params: Params,
    state: GlobalState,
    language: string,
    count: number,
) =>
    fetchRecommendationAdsByLocationFallback(count, state).then((hits) =>
        processHits(hits, params.searchHitExternalIDs, language),
    );

const fetchSearchRecommendations = (
    params: Params,
    dispatch: Dispatch<AppDispatch>,
    state: GlobalState,
) => {
    const language = state.i18n.language;
    const locationFallbackHitsCount =
        getRecommendationsThreshold(state.algolia.settings?.hitsPerPage) - params.totalHitCount;

    if (!settings.enableSearchRecommendedPropertiesWithLocationFallback) {
        return Promise.all([
            Promise.resolve([]),
            fetchRecommenderRecommendations(params, dispatch, language),
        ]);
    }

    return fetchLocationFallbackRecommendations(
        params,
        state,
        language,
        locationFallbackHitsCount,
    ).then((hits) => {
        if (hits.length < locationFallbackHitsCount) {
            return Promise.all([
                Promise.resolve(hits),
                fetchRecommenderRecommendations(params, dispatch, language),
            ]);
        }
        return Promise.all([Promise.resolve(hits), Promise.resolve({ hits: [], status: 200 })]);
    });
};

/**
 * Factory for fetching recommendations based on the current
 * search filters.
 */
const factory = new FetcherFactory(
    ['search', 'recommendations'],
    // @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
    (params: Params, state: any, dispatch: Dispatch) => {
        const language = state.i18n.language;
        const threshold = getRecommendationsThreshold(state.algolia.settings?.hitsPerPage);
        const areRecommendationsEnabled =
            CONFIG.build.ENABLE_RECOMMENDER && !CONFIG.build.DISABLE_SEARCH_RECOMMENDATIONS;

        if (
            params.totalHitCount >= threshold ||
            !areRecommendationsEnabled ||
            !params.hasSearchResponse
        ) {
            return Promise.resolve({ data: [], status: 200 });
        }

        // @ts-expect-error - TS2345 - Argument of type 'import("/frontend/strat/strat/search/state/recommendations").Params' is not assignable to parameter of type 'Params'.
        return getPageRule(language, params).then((response) => {
            const pageRule = response.data;

            if (response.status !== 200 && response.status !== 404) {
                return { data: [], status: response.status };
            }

            // fetch less recommendations if no page rule and no results
            if ((!pageRule || response.status === 404) && params.totalHitCount === 0) {
                params.count = recommendationCountFor404Pages;
            }

            //fetch twice as many recommendations to have a better pool for filtering based on category
            const noOfAds = params?.count || recommendationCountForSearchPages;
            params.count = 2 * noOfAds;

            return fetchSearchRecommendations(params, dispatch, state).then(
                ([locationFallbackHits, recommenderResponse]) => {
                    const propertyType =
                        params.categoryHierarchy?.[params.categoryHierarchy.length - 1]?.slug;

                    const recommenderHits = prioritizeCategoryPurposeHits(
                        recommenderResponse.hits,
                        propertyType,
                        params.purpose,
                        noOfAds,
                    ).slice(0, noOfAds);

                    if (response.status === 404) {
                        return {
                            data: {
                                recommenderHits,
                                locationFallbackHits,
                                pageRule: undefined,
                            },
                            status: 404,
                        };
                    }

                    return {
                        data: {
                            recommenderHits,
                            locationFallbackHits,
                            pageRule,
                        },
                        status: recommenderResponse.status,
                    };
                },
            );
        });
    },
    {
        catchServerErrors: true,
        // @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'getState' implicitly has an 'any' type. | TS2322 - Type 'unknown' is not assignable to type 'Params'.
        getParams: (_, getState): Params => selectRecommendationParams(getState()),
        successCodes: [200, 404],
    },
);

/**
 * Reducer for recommendations.
 */
const recommendationsReducer = factory.reducer();

/**
 * Fetches recommendations based on the current search filters.
 */
const fetchRecommendations = factory.creator();

/**
 * Clears all recommendations and resets the factory.
 */
const clearRecommendations = () => ({
    type: factory.actions.clear,
});

/**
 * Forcefully fetch recommendations, regardless of whether the
 * parameters changed or not.
 */
// @ts-expect-error - TS2314 - Generic type 'Dispatch<S>' requires 1 type argument(s).
const forceFetchRecommendations = () => (dispatch: Dispatch) => {
    dispatch(clearRecommendations());
    return dispatch(fetchRecommendations());
};

/**
 * Redux middleware that fetches recommendations whenever
 * search results are fetched.
 *
 * This needs to happen after the search results are fetched
 * because based on the number of hits, we either fetch
 * recommendations or note.
 */
const recommendationsMiddleware =
    (store: Store<GlobalState>) => (next: (action: Action) => void) => (action: Action) => {
        const result = next(action);

        if (!process.env.IS_BROWSER) {
            return result;
        }

        const isProjectSearchEnabled = selectIsProjectSearchEnabled(store.getState());
        if (!isProjectSearchEnabled && action.type === SearchActions.STORE_RESULTS) {
            (store.dispatch as AppDispatch)(fetchRecommendations());
        }

        return result;
    };

export {
    fetchRecommendations,
    clearRecommendations,
    forceFetchRecommendations,
    recommendationsMiddleware,
};

export type { Params };

export default recommendationsReducer;
