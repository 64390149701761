import * as React from 'react';
import { useI18n } from 'strat/i18n/language';
import settings from '@app/branding/settings';

import type { FooterLink } from './types';

const useAboutUsLinks = (): ReadonlyArray<FooterLink> => {
    const i18n = useI18n();

    return React.useMemo(() => settings.aboutUsLinks(i18n), [i18n]);
};

export default useAboutUsLinks;
