import { createSelector } from 'reselect';

import EMPTY_ARRAY from 'strat/empty/array';
import { createSearchRouteParams, selectRouteParamsInput } from 'strat/search/url';
import { selectLocations, selectCategory, selectCity } from 'strat/search/selectors';
import { selectIsUserLoggedIn } from 'strat/user/selectors';
import type { GlobalState } from 'strat/state';

import { compareSavedSearchParams } from './savedSearches';
import { isSavedSearchesEnabled } from './isSavedSearchesEnabled';

const selectSavedSearchesLoaded = (state: GlobalState) => state.search.saved?.loaded;

const selectSavedSearchesLoading = (state: GlobalState) => state.search.saved?.loading;

const selectShouldFetchSavedSearches = createSelector(
    selectIsUserLoggedIn,
    selectSavedSearchesLoaded,
    selectSavedSearchesLoading,
    (userLoggedIn, savedSearchesLoaded, savedSearchesLoading) =>
        CONFIG.runtime.ENABLE_STRAT_SAVED_SEARCHES &&
        isSavedSearchesEnabled() &&
        userLoggedIn &&
        !savedSearchesLoaded &&
        !savedSearchesLoading,
);

/**
 * Note that this selector implies that saved searches data is already in the
 * store. If this is not guaranteed, use `useSavedSearches` hook instead, which
 * will also fetch them, in case they are not already loaded.
 */
const selectSavedSearches = (state: GlobalState) => state.search.saved?.data || EMPTY_ARRAY;

const selectSavedSearchesAvailable = createSelector(
    selectSavedSearchesLoaded,
    selectIsUserLoggedIn,
    (savedSearchesLoaded, isUserLoggedIn) => {
        if (CONFIG.runtime.ENABLE_STRAT_SAVED_SEARCHES) {
            return isUserLoggedIn && savedSearchesLoaded;
        }
        return isUserLoggedIn;
    },
);

/**
 * Takes the current search route params that should be saved
 */
const selectRouteSearchParams = createSelector(selectRouteParamsInput, (routeParamsInput) => {
    const routeParams = createSearchRouteParams(
        routeParamsInput.filters,
        routeParamsInput.extraParams,
    );

    const locations = routeParams.locations?.map((loc: any) => loc.slug) || [];
    if (locations.length === 0 && routeParams.city) {
        locations.push(routeParams.city);
    }
    return {
        ...routeParams,
        locations,
    };
});

/**
 * Takes the current search route parameters that will be sent
 * to the Strat/Node Saved Searches endpoint.
 *
 * Locations, city and category are taken from the filters, as API requires
 * both externalIDs and slugs for them (but search route parameters has just slugs).
 *
 * Note! Be careful when updating returned parameter names,
 * as they are passed directly to the backend!
 *
 * For more details about this, see saved search docs
 * {@see maple/docs/savedSearches/savedSearches.md}
 */
const selectSavedSearchParams = createSelector(
    selectRouteSearchParams,
    selectLocations,
    selectCategory,
    selectCity,
    (routeSearchParams, filterLocationsData, filterCategoryData, filterCityData) => {
        const locationSlugs: Array<any> = [];
        const locationExternalIDs: Array<any> = [];
        filterLocationsData.forEach((location) => {
            locationExternalIDs.push(location.externalID);
            locationSlugs.push(location.slug);
        });

        const categorySlug = filterCategoryData?.slug;
        const categoryExternalID = filterCategoryData?.externalID;

        const cityExternalID = filterCityData?.externalID;
        const citySlug = filterCityData?.slug;
        if (locationExternalIDs.length === 0 && cityExternalID && citySlug) {
            // no location filter, so add city as location (otherwise city is implied)
            locationExternalIDs.push(cityExternalID);
            locationSlugs.push(citySlug);
        }

        return {
            ...routeSearchParams,
            // overwrite locations and category
            locations: locationSlugs,
            category: categorySlug,
            // beside the search route params, API also needs some external IDs
            locationExternalIDs,
            categoryExternalID,
        };
    },
);

/**
 * Selects the saved search that matches the currently active search parameters (criteria).
 */
const selectCurrentSearchAsSaved = createSelector(
    selectSavedSearches,
    selectRouteSearchParams,
    (savedSearches, routeSearchParams) => {
        // @ts-expect-error - TS7006 - Parameter 'search' implicitly has an 'any' type.
        const savedSearch = savedSearches.find((search) =>
            compareSavedSearchParams(search.params, routeSearchParams),
        );
        return savedSearch || null;
    },
);

export {
    selectSavedSearchParams,
    selectCurrentSearchAsSaved,
    selectSavedSearches,
    selectShouldFetchSavedSearches,
    selectSavedSearchesAvailable,
    selectRouteSearchParams,
    selectSavedSearchesLoaded,
};
