//@ts-nocheck
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { AgentRoles } from 'horizontal/types';
import brandingSettings from '@app/branding/settings';

import type {
    CreatedAgentReview,
    DecidedAgentReview,
    FilledAgentReview,
} from 'strat/agency/agent/review/types';
import { HTTPApi } from 'strat/util';
import type { RequestOptions } from 'strat/util/httpApi';
import Purpose from 'strat/purpose';
import type { SearchParams } from 'strat/search/types';
import { agentTransformer } from 'strat/agency/agent';
import { AlertType, AppType } from 'strat/alerts/types';
import type { Report } from 'strat/report/types';
import type { UserProfileData } from 'strat/user/types';
import type { AreaGuide } from 'strat/areaGuide/types';
import { ServerAuthContext } from 'strat/server/serverAuthContext';
import {
    SaveTruEstimateReportResponse,
    SendReportParams,
    TruEstimateReport,
    ReportHistoryInformation,
} from 'strat/truEstimate/types';
import { type UnitPlan, UnitPlanStatus } from 'strat/unitPlan/types';
import { ProjectData } from 'strat/project/types';
import { PropertyProduct } from 'strat/property/types';
import { thumbnailURL } from 'strat/image';
import { ConversionTable, Area } from 'strat/i18n';

import type {
    APIResponse,
    ApplyProductPayload,
    CashMyCarFormPayload,
    PaginatedAPIResponse,
} from './types';

export type PaginationParams = {
    page?: number;
    pageSize?: number;
};

export type AdIdentificationParams = {
    external_id?: string;
    slug?: string;
    type?: string;
};

export type LocationByCoordinatesParams = {
    max_location_depth?: number | null;
};

export type DateFilters = {
    created_at_gte?: string;
    created_at_lte?: string;
    updated_at_gte?: string;
    updated_at_lte?: string;
};

export type AssignAgentQuotaParams = {
    readonly product_purchase_id: number;
    readonly quantity: number;
};

export type AgencyMetricParams = {
    readonly agencyExternalIDs: Array<string>;
    readonly startTimestamp?: number;
    readonly endTimestamp?: number;
};

export type UserAdsQueryParams = {
    /**
     * Whether to include the count of ads for each state in the metadata response
     */
    readonly countByState?: boolean;
    /**
     * Only retrieve ads posted by specific agent.
     */
    readonly agent_external_id?: string;
    /**
     * Only retrieve the ads that have the specified states. Omit to retrieve all states.
     */
    readonly states?: Array<string>;
    /**
     * Only retrieve the ads that have the specified states. Omit to retrieve all states.
     */
    readonly dateFilters?: DateFilters;
    /**
     * Only retrieve ads that contain this text in the title
     */
    readonly query?: string;
    /**
     * Only retrieve ads that contain either Agent Code or Refernce Id in them
     */
    readonly code?: string;
    /**
     * The page of the query set to be retrieved. Defaults to 1.
     */
    readonly page?: number;
    /**
     * The size of the page to be retrieved.
     */
    readonly page_size?: number;
    /**
     * Only retrieve ads that Consumed This Product Purchase.
     */
    readonly product_purchase_id?: number;
};

export type AgencyAdsQueryParams = {
    /**
     * Whether to include the count of ads for each state in the metadata response
     */
    readonly count_by_state?: boolean;
    /**
     * Only retrieve ads posted by specific agent.
     */
    readonly agent_external_id?: string;
    /**
     * Only retrieve ads posted under this category.
     */
    readonly category_external_id?: string;
    /**
     * Only retrieve ads posted in this location.
     */
    readonly location_external_id?: string;
    /**
     * Only retrieve the ads that have the specified states. Omit to retrieve all states.
     */
    readonly states?: Array<string>;
    /**
     * Only retrieve the ads that was created after that date.
     */
    readonly created_at_gte?: string;
    /**
     * Only retrieve the ads that was created before that date.
     */
    readonly created_at_lte?: string;
    /**
     * Only retrieve the ads that was updated after that date.
     */
    readonly updated_at_gte?: string;
    /**
     * Only retrieve the ads that was updated before that date.
     */
    readonly updated_at_lte?: string;
    /**
     * Only retrieve ads that contain this text in the title.
     */
    readonly query_text?: string;
    /**
     * Only retrieve ads with this agent code.
     */
    readonly agent_code?: string;
    /**
     * Only retrieve ads with this phone number.
     */
    readonly phone_number?: string;
    /**
     * The page of the query set to be retrieved. Defaults to 1.
     */
    readonly page?: number;
    /**
     * The size of the page to be retrieved.
     */
    readonly page_size?: number;
    /**
     * Include the children categories.
     */
    readonly include_category_descendants?: boolean;
};

export type ConsumedAdsQueryParams = {
    /**
     * Only retrieve ads that Consumed This Product Purchase.
     */
    readonly product_purchase_id?: number;
    /**
     * Only retrieve ads posted by specific agent.
     */
    readonly agent_external_id?: string;
    /**
     * Only retrieve the ads that have the specified states. Omit to retrieve all states.
     */
    readonly states?: Array<string>;
    /**
     * Only retrieve the ads that was created after that date.
     */
    readonly created_at_gte?: string;
    /**
     * Only retrieve the ads that was created before that date.
     */
    readonly created_at_lte?: string;
    /**
     * The page of the query set to be retrieved. Defaults to 1.
     */
    readonly page?: number;
    /**
     * The size of the page to be retrieved.
     */
    readonly page_size?: number;
};
/**
 * Provides access to the Strat API.
 */
class StratAPI extends HTTPApi {
    /**
     * Returns headers to add to every request to forward credentials
     * the server received from the client.
     */
    authHeader() {
        if (process.env.IS_SERVER) {
            return ServerAuthContext.asHeaders();
        }

        return super.authHeader();
    }

    /**
     * Turns the specified relative path into a absolute URL.
     * @param {string} path The path to turn into an absolute URl.
     * @returns {string} An absoluate URL to the specified relative path.
     */
    static buildAbsoluteUrl(path: any) {
        if (process.env.IS_SERVER) {
            const baseURL = process.env.STRAT_API_SERVER || CONFIG.build.STRAT_API;
            return `${baseURL}${path}`;
        }

        return path;
    }
    /**
     * Takes a object and transform it to a HTTP query string.
     * @param {Object} parameters The parameters to transform into a query string.
     * @returns {string} The query string.
     *
     * Motivation: As there is no settled way of handling arrays in query parameters
     * each framework adopts their own strategy. In Django, array items are concatenated
     * with the same key.
     */
    static buildQueryString(parameters: any) {
        return Object.entries(parameters)
            .filter(([_, value]: [any, any]) => !isNil(value))
            .map(([key, value]: [any, any]) => {
                if (Array.isArray(value)) {
                    return `${key}=${value.map((v) => encodeURIComponent(v)).join(`&${key}=`)}`;
                }
                return `${key}=${encodeURIComponent(value)}`;
            })
            .join('&');
    }

    static firstOrNull<T>({
        data: results,
        status,
    }: APIResponse<Array<T>>): APIResponse<T | null | undefined> {
        const result = results?.[0] ?? null;
        return { data: result, status };
    }

    buildRequest(url: any, init = {}) {
        const requestUrl = this.constructor.buildAbsoluteUrl(url);

        return {
            requestUrl,
            init: {
                ...init,
                headers: {
                    'Accept-Language': this.language,
                    ...(init.headers || {}),
                    ...this.authHeader(),
                },
            },
        };
    }

    /**
     * Makes a request to the specified URL.
     */
    request(
        url: any,
        parameters: Object | null | undefined = null,
        init = {},
        disableCaching = false,
    ) {
        const { requestUrl, init: requestInit } = this.buildRequest(url, init);
        return super.request(requestUrl, parameters, requestInit, disableCaching);
    }

    requestBinary(url: any, parameters = null, init = {}, disableCaching = false) {
        const { requestUrl, init: requestInit } = this.buildRequest(url, init);
        return super.requestBinary(requestUrl, parameters, requestInit, disableCaching);
    }

    post(url: string, body: Record<any, any>, init = {}, options: RequestOptions = {}) {
        const { requestUrl, init: requestInit } = this.buildRequest(url, init);
        return super.postJSON(requestUrl, body, requestInit, options);
    }

    patch(url: string, body: Record<any, any>) {
        const requestUrl = StratAPI.buildAbsoluteUrl(url);
        return super.patch(requestUrl, body);
    }

    put(url: string, body: Record<any, any>, options: RequestOptions = {}) {
        const requestUrl = StratAPI.buildAbsoluteUrl(url);
        return super.put(requestUrl, body, options);
    }

    /**
     * Makes a request to the specified URL without using the cache.
     */
    uncachedRequest(url: any, parameters = null, init = {}) {
        return this.request(url, parameters, init, true);
    }

    validateCaptcha(token: string) {
        return this.postJSON('/.humbucker/challenge/captcha/validate', { token });
    }

    /**
     * Gets /api/homepageLocations
     * @param {string} purpose The purpose to filter on.
     * @returns {Promise}
     */
    homepageLocations(purpose: any) {
        return this.request('/api/homepageLocations/', { purpose });
    }

    /**
     * Track a phone/sms/mobile lead
     * @param adId id of that a phone number was request for
     * @returns {Promise}
     */
    trackPhoneNumberLead(adId: any) {
        return this.request('/api/stats/leads/', null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                adId,
            }),
        });
    }

    /**
     * Gets /api/listing. In case ENABLE_MERGED_INDEX is set, the request should be in english version because
     * the result will be retrieved in the format of non-merged index, meaning the normal fields (like 'title'' will
     * contain the information translated in requested language, and the language specific fields (like 'title_l1')
     * will contain the other value
     * @param params - contains the identifier of the ad to be retrieved - can be either the external_id or the slug
     * @returns {Promise}
     */
    ad(params: AdIdentificationParams): Promise<any> {
        const init = CONFIG.build.ENABLE_MERGED_INDEX
            ? {
                  headers: {
                      'Accept-Language': CONFIG.build.LANGUAGE_CODE,
                  },
              }
            : {};
        return this.request(`/api/listing/`, params, init);
    }

    /**
     * Returns a list of multiple ads.
     * Makes a POST request to /api/listing/
     * @param params - list of ads external IDs.
     * @returns {Promise}
     */
    ads(adExternalIDs: string[]): Promise<any> {
        return this.post(`/api/listing/`, {
            listings: adExternalIDs,
        });
    }

    /**
     * Gets /api/agency. In case ENABLE_AGENCY_MERGED_INDEX is set, the request should be in english version because
     * the result will be retrieved in the format of non-merged index, meaning the normal fields (like 'name'' will
     * contain the information translated in requested language, and the language specific fields (like 'name_l1')
     * will contain the other value.
     * @param {string} externalID The externalID of the agency to be retrieved
     * @returns {Promise}
     */
    agency(externalID: string) {
        const init = CONFIG.build.ENABLE_AGENCY_MERGED_INDEX
            ? {
                  headers: {
                      'Accept-Language': CONFIG.build.LANGUAGE_CODE,
                  },
              }
            : {};
        return this.request(`/api/agency/${externalID}`, null, init);
    }

    /**
     * Gets /api/agency/metrics
     * @param {AgencyMetricParams} params Contains a list of agencies external-ids that we want to fetch their metrics
     * @returns {Promise}
     */
    agencyMetrics(params: AgencyMetricParams) {
        const queryData = {
            agency_external_ids: params.agencyExternalIDs,
            ...(params?.startTimestamp && {
                start_date: new Date(new Date(params.startTimestamp * 1000).setHours(0, 0, 0, 0))
                    .toISOString()
                    .split('.')[0],
            }),
            ...(params?.endTimestamp && {
                end_date: new Date(new Date(params.endTimestamp * 1000).setHours(23, 59, 59, 999))
                    .toISOString()
                    .split('.')[0],
            }),
        } as const;

        return this.uncachedRequest('/api/agency/metrics/', queryData);
    }

    agencyActivityLog(externalID: string, limit: number | null = null, before: Date | null = null) {
        const serializedBefore = before && new Date(before).toISOString();
        const queryParams = `?${StratAPI.buildQueryString({
            limit,
            before: serializedBefore,
        })}`;
        const isoStringToJsTime = (isoString: any) => new Date(isoString).getTime();
        return this.uncachedRequest(`/api/agency/${externalID}/activityLog${queryParams}`).then(
            (response) => {
                response.data.items.forEach((item) => {
                    item.timestamp = isoStringToJsTime(item.timestamp);
                });
                return response;
            },
        );
    }

    agencyProductPurchases({
        agencyExternalID,
        status,
        agent_external_id,
        category_external_id,
        product_types,
    }: {
        readonly agencyExternalID: string;
        readonly status?: string;
        readonly agent_external_id?: string;
        readonly category_external_id?: string;
        readonly product_types?: any;
    }) {
        const url = `/api/agency/${agencyExternalID}/productPurchases`;
        if (status || agent_external_id || category_external_id || product_types) {
            return this.request(url, {
                status,
                agent_external_id,
                category_external_id,
                product_types,
            });
        }
        return this.request(url);
    }

    /**
     * Gets /api/agency/:externalID/agents
     * @param {string} externalID The externalID of the agency
     * @returns {Promise}
     */
    agents(
        externalID: string,
        transform = true,
        countAdsByState?: boolean,
        roles?: AgentRoles[] | null,
        getCreditBalance?: boolean,
    ) {
        const queryString = StratAPI.buildQueryString({ countAdsByState, roles, getCreditBalance });
        return this.request(`/api/agency/${externalID}/agents?${queryString}`).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: null };
            }

            return {
                ...result,
                data: transform
                    ? result.data.map((agent) => agentTransformer(agent, this.language))
                    : result.data,
            };
        });
    }

    /**
     * Gets /api/agent/byad/:externalID
     * @param {string} adExternalID The externalID of the property
     * @param {string} state The agent state
     * @returns {Promise}
     */
    agentByAd(adExternalID: string, state: string) {
        return this.request(
            `/api/agent/byad/${adExternalID}?${StratAPI.buildQueryString({ state })}`,
        ).then((result) => this.agentRequestResultHandler(result));
    }

    /**
     * Gets /api/agency/agents/:agentExternalID
     * @param {string} agentExternalID The externalID of the agent
     * @param {string} state The state of the agent
     * @param {boolean} countAdsByState get agent number of ads grouped by status
     * @param incomplete get the agent even if it is incomplete
     * @returns {Promise}
     */
    agent(
        agentExternalID: string,
        state?: string,
        countAdsByState?: boolean,
        incomplete?: boolean,
    ) {
        return this.request(
            `/api/agency/agents/${agentExternalID}?${StratAPI.buildQueryString({
                state,
                countAdsByState,
                incomplete,
            })}`,
        ).then((result) => this.agentRequestResultHandler(result));
    }

    /**
     * Gets /api/agency/agents/
     * @param {string} agentSlug The slug of the agent
     * @param {string} state The state of the agent
     * @param {boolean} countAdsByState get agent number of ads grouped by status
     * @returns {Promise}
     */
    agentBySlug(agentSlug: string, state?: string, countAdsByState?: boolean) {
        return this.request(
            `/api/agency/agents/?${StratAPI.buildQueryString({
                slug: agentSlug,
                state,
                countAdsByState,
            })}`,
        ).then((result) => this.agentRequestResultHandler(result));
    }

    /**
     * Fetch agent data of complete agent profiles from Strat, cross-validating using email.
     * Gets /api/agency/agents/:agentExternalID
     * @param {string} agentExternalID The externalID of the agent
     * @param {string} email The email of the agent - optional
     * @returns {Promise}
     */
    agentCompleteProfile(agentExternalID: string | number, email?: string | null) {
        return this.request(
            `/api/agency/agents/${agentExternalID}?${StratAPI.buildQueryString({
                incomplete: true,
            })}`,
        )
            .then((result) => this.agentRequestResultHandler(result))
            .then(({ status, data }) => {
                if (email && data?.email !== email) {
                    /**
                     * BackOffice API will return the data in the same format regardless
                     * of what entity it represents (agent, agency, free user).
                     * BackOffice role_id does not perfectly map to Strat entities.
                     * Strat needs to identify which users have a complete agent profile
                     * on its side. In lack of a better mechanism, we compare email to
                     * check if the login matches a Strat agent and is not just an
                     * external ID collision with another entity.
                     */
                    return { status: 404, data: null };
                }

                return { status, data };
            });
    }

    agentRequestResultHandler(result) {
        if (result.status !== 200) {
            return { ...result, data: null };
        }

        return { ...result, data: agentTransformer(result.data, this.language) };
    }

    /**
     * Gets /api/agent/featured
     * @returns {Promise}
     */
    featuredAgents() {
        return this.request(`/api/agent/featured`).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: null };
            }
            return {
                ...result,
                data: result.data.map((agent) => agentTransformer(agent, this.language)),
            };
        });
    }

    /**
     * Stores agent's data in the database
     */
    saveAgentData(agentExternalID: string, data: any) {
        return this.post(`/api/agent/${agentExternalID}/bayutpro/data`, data);
    }

    /**
     * Gets the most recent blog posts
     * @returns {Promise}
     */
    recentBlogPosts() {
        return this.request('/api/recentblogs', { language: this.language });
    }

    /**
     * Gets /api/browseProperties
     * @param {string} cityID The id of the city for which to request browse properties links
     * @param {string} purpose The purpose (as a string) for which to request browse properties links
     */
    browsePropertiesLinks(cityID: any, purpose: any) {
        return this.request(`/api/browseProperties/${cityID}/${purpose}/`);
    }

    /**
     * Gets /api/internalLinks
     * @param {string} purpose The purpose (as a string) for which to request the internalLinks.
     * @param {string} category The desired category (as a slug) for which to request the internalLinks.
     * @param {string} location The desired location (as a slug) for which to request the internalLinks.
     * @param {number} beds The desired number of beds (as a single number) for which to request the internalLinks.
     * @param {string} completionStatus  The completion status (as a string) for which to request the internalLinks.
     * @param {string} listingType The Ad type (as a string) for which to request the links.
     * @param {boolean} includeNextLevelLocations Whether or not to retrieve children of all child locations.
     */
    internalLinks(
        purpose: any,
        category: any,
        location: any,
        beds: any,
        completionStatus?: any,
        listingType?: any,
        includeNextLevelLocations = false,
    ) {
        return this.request('/api/internalLinks/', {
            purpose: purpose === Purpose.value(Purpose.FOR_RENT) ? Purpose.FOR_RENT : purpose,
            location,
            category,
            beds: typeof beds === 'string' ? parseInt(beds, 10) || null : beds,
            ...(CONFIG.build.STRAT_ENABLE_OFF_PLAN_LPV_REDESIGN ? { completionStatus } : {}),
            ...(CONFIG.build.STRAT_ENABLE_NEW_PROJECTS_LPV ? { type: listingType } : {}),
            includeNextLevelLocations,
        });
    }

    /**
     * Gets /api/agency/
     * @param {string} agencyID The agencyID whose phone numbers have to be fetched.
     */
    agencyPhoneNumbers(agencyID: any) {
        return this.request(`/api/agency/${agencyID}`);
    }

    /**
     * Gets /api/internalLinks/homepage
     * @param {string} category The desired category (as a slug) for which to request the internalLinks.
     */
    homepageInternalLinks(category: any) {
        return this.request('/api/internalLinks/homepage/', { category });
    }

    /**
     * Gets /api/internalLinks/searchPage
     * @param {string} purpose The purpose (as a string) for which to request the internalLinks.
     * @param {string} category The desired category (as a slug) for which to request the internalLinks.
     * @param {string} location The desired location (as a slug) for which to request the internalLinks.
     * @param {?string} area The desired area (as a slug) for which to request the purposeSwitchLink.
     */
    searchPageInternalLinks(
        purpose: string,
        category: string,
        location: string,
        area?: string | null,
    ) {
        return this.request('/api/internalLinks/searchPage/', {
            purpose: purpose === Purpose.value(Purpose.FOR_RENT) ? Purpose.FOR_RENT : purpose,
            location,
            category,
            area,
        });
    }

    /**
     * Gets /api/internalLinks/flooplans
     * @param {string} location The desired location (as a slug) for which to request the internalLinks.
     * @param {string} category The desired category (as a slug) for which to request the internalLinks. Optional.
     * @param {number} beds The desired number of beds for the category and location combination. Optional
     */
    floorplansInternalLinks(location: string, category?: string, beds?: number) {
        return this.request('/api/internalLinks/floorplans/', {
            location,
            category,
            beds,
        });
    }

    /**
     * Gets /api/internalLinks/propertyPage
     * @param params - contains the external_id  or slug of an ad for which to request the internalLinks.
     * @returns {Promise}
     */
    propertyPageInternalLinks(params: AdIdentificationParams): Promise<any> {
        return this.request('/api/internalLinks/propertyPage/', params);
    }

    /**
     * Gets /api/internalLinks/propertyMarketAnalysisPage
     * @param {string} purpose The purpose (as a string) for which to request the internalLinks.
     * @param {?string} category The desired category (as a slug) for which to request the internalLinks.
     * @param {string} location The desired location (as a slug) for which to request the internalLinks.
     * @param {?string} beds The number of beds for which to request the internalLinks
     */
    propertyMarketAnalysisPageInternalLinks(
        purpose: string,
        category: string | null,
        location: string,
        beds: string | null,
    ) {
        return this.request('/api/internalLinks/propertyMarketAnalysisPage/', {
            purpose,
            category,
            location,
            beds,
        });
    }

    /**
     * Looks up the user's location based on IP address.
     */
    ipLocation(ip: string) {
        return this.request('/api/ipToLocation/', {
            ip,
        }).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: null };
            }

            return result;
        });
    }

    /**
     * Gets /api/areaguide
     * @param purpose The purpose for which to request an area guide.
     * @param location The slug of the location for which to
     * request an area guide for.
     * @param category The slug of the category to request
     * an area guide for.
     * @param beds The bed count to request an area guide for.
     */
    areaGuide(
        purpose: string,
        location: string,
        category: string,
        beds?: number | null,
        completionStatus?: string,
    ): Promise<APIResponse<Array<AreaGuide>>> {
        return this.request(`/api/areaguide/`, {
            locationSlug: location,
            categorySlug: category,
            purpose,
            beds,
            completionStatus,
        });
    }

    /**
     * Gets /api/areaguide
     * @param customSearchPageSlug The long tail slug to request
     * an area guide for.
     */
    areaGuideBySlug(customSearchPageSlug: string) {
        return this.request(`/api/areaguide/`, {
            customSearchPageSlug,
        });
    }

    areaGuideLink(locationSlug: string) {
        return this.request(`/api/areaGuideLink/`, { locationSlug });
    }

    /**
     * Gets /api/wallpaper
     */
    wallpaper() {
        return this.request('/api/wallpaper/');
    }

    /**
     * Gets /api/templates
     * @returns {Promise}
     */
    templates() {
        return this.request('/api/templates/');
    }

    /**
     * Gets then index for the search page sitempa.
     */
    siteMapSearchIndex() {
        return this.uncachedRequest('/api/sitemap');
    }

    /**
     * Gets the index for the property sitemap.
     */
    siteMapPropertyIndex() {
        return this.uncachedRequest('/api/sitemap');
    }

    /**
     * Gets the index for the project ads sitemap.
     */
    siteMapProjectAdsIndex() {
        return this.uncachedRequest('/api/sitemap/ads/project');
    }

    /**
     * Gets sitemap entries for search pages.
     */
    siteMapSearchLinks(purpose: string, category: string, page?: number | null) {
        if (category && page) {
            return this.uncachedRequest(`/api/sitemap/searches/${purpose}/${category}/${page}`);
        } else if (category) {
            return this.uncachedRequest(`/api/sitemap/searches/${purpose}/${category}`);
        }
        return this.uncachedRequest(`/api/sitemap/searches/${purpose}`);
    }

    /**
     * Gets sitemap entries for properties.
     */
    siteMapPropertiesLinks(purpose: string, category: string, page: number) {
        return this.uncachedRequest(`/api/sitemap/properties/${purpose}/${category}/${page}`);
    }

    /**
     * Gets the index for the propertyMarketAnalysis sitemap.
     */
    siteMapPropertyMarketAnalysisIndex() {
        return this.uncachedRequest('/api/sitemap/propertyMarketAnalysis');
    }

    /**
     * Gets sitemap entries for propertyMarketAnalysis pages.
     */
    siteMapPropertyMarketAnalysisLinks(purpose: string, category: string, page?: number | null) {
        if (category && page) {
            return this.uncachedRequest(
                `/api/sitemap/propertyMarketAnalysis/${purpose}/${category}/${page}`,
            );
        } else if (category) {
            return this.uncachedRequest(
                `/api/sitemap/propertyMarketAnalysis/${purpose}/${category}`,
            );
        }
        return this.uncachedRequest(`/api/sitemap/propertyMarketAnalysis/${purpose}`);
    }

    /**
     * Gets sitemap entries for Project (Off-Plan) Ads search pages.
     */
    siteMapProjectAdsSearchLinks(category: string) {
        return this.uncachedRequest(`/api/sitemap/searches/ads/project/${category}`);
    }

    /**
     * Gets sitemap entries for Project search pages.
     */
    siteMapProjectSearchLinks() {
        return this.uncachedRequest(`/api/sitemap/project/`);
    }

    /**
     * Gets sitemap entries for the agency section.
     */
    siteMapAgencySection(entryType: string) {
        return this.uncachedRequest(`/api/sitemap/agency/${entryType}`);
    }

    /**
     * Gets sitemap entries for the agency section.
     */
    siteMapAgentsSection() {
        return this.uncachedRequest(`/api/sitemap/agency/brokers/`);
    }

    /**
     * Gets all e-mail alerts for the specified user ID.
     */
    getAlerts(userID: string) {
        return this.uncachedRequest(`/api/emailalerts/${userID}/`);
    }

    /**
     * Gets the recommended email alert for the specified user ID
     */
    getRecommendedEmailAlerts(userID: string) {
        return this.uncachedRequest(`/api/emailalerts/recommendations/${userID}`);
    }

    /**
     * Gets the recently viewed email alert for the specified user ID
     */
    getRecentlyViewedEmailAlerts(userID: string) {
        return this.uncachedRequest(`/api/emailalerts/recentlyviewed/${userID}`);
    }

    getSubscriptions(userID: string, deviceID?: string, appType: string = AppType.WEB_APP) {
        return this.uncachedRequest(`/api/subscriptions/`, { userID, deviceID, appType });
    }

    updateSubscription(body: any) {
        return this.uncachedRequest(`/api/subscriptions/`, null, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    }

    /**
     * Adds an e-mail alert for the current user.
     */
    addAlert(user: UserProfileData, params: SearchParams, type: string = AlertType.EXPLICIT) {
        return this.uncachedRequest(`/api/emailalerts/${user.id}/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type,
                ...user,
                ...params,
            }),
        });
    }

    /**
     * Adds a recommended email alert for the user with the specified email.
     */
    addRecommendedEmailAlertByEmail(email: string, id: string, type: string = AlertType.EXPLICIT) {
        return this.uncachedRequest(`/api/emailalerts/recommendations/byemail/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                id,
                type,
            }),
        });
    }

    /**
     * Adds a recommended email alert for the current user
     */
    addRecommendedEmailAlert(user: UserProfileData, type: string = AlertType.EXPLICIT) {
        return this.uncachedRequest(`/api/emailalerts/recommendations/${user.id}/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type,
                ...user,
            }),
        });
    }

    /**
     * Adds a recently viewed email alert for the user with the specified email.
     */
    addRecentlyViewedEmailAlertByEmail(email: string, id: string) {
        return this.uncachedRequest(`/api/emailalerts/recentlyviewed/byemail/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                id,
            }),
        });
    }

    /**
     * Adds a recently viewed email alert for the current user
     */
    addRecentlyViewedEmailAlert(user: UserProfileData) {
        return this.uncachedRequest(`/api/emailalerts/recentlyviewed/${user.id}/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...user,
            }),
        });
    }

    /**
     * Adds an e-mail alert for the user with the specified email.
     */
    addAlertByEmail(
        email: string,
        id: string,
        params: SearchParams,
        type: string = AlertType.EXPLICIT,
    ) {
        return this.uncachedRequest(`/api/emailalerts/byemail/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                id,
                type,
                ...params,
            }),
        });
    }

    /*
     * Remove an alert from the backend based on
     * the primary key alertHash.
     */
    removeAlert(userID: string, alertHash: string) {
        return this.uncachedRequest(`/api/emailalerts/${userID}/${alertHash}/`, null, {
            method: 'DELETE',
        });
    }

    /*
     * Remove a recommended email alert from the backend based on the primary key alertHash
     */
    removeRecommendedEmailAlert(userID: string, alertHash: string) {
        return this.uncachedRequest(
            `/api/emailalerts/recommendations/${userID}/${alertHash}`,
            null,
            {
                method: 'DELETE',
            },
        );
    }

    /*
     * Remove a recently viewed email alert from the backend based on the primary key alertHash
     */
    removeRecentlyViewedEmailAlert(userID: string, alertHash: string) {
        return this.uncachedRequest(
            `/api/emailalerts/recentlyviewed/${userID}/${alertHash}`,
            null,
            {
                method: 'DELETE',
            },
        );
    }

    /*
     * Remove all the alerts from the current user.
     */
    removeAlerts(userID: string) {
        return this.uncachedRequest(`/api/emailalerts/${userID}/`, null, {
            method: 'DELETE',
        });
    }

    /*
     * Get a particular alert without Django Auth Token.
     * This is used by ViewAlertRoute which is used in the alert email for "View All properties button"
     */
    viewAlert(alertHash: string): Promise<any> {
        return this.uncachedRequest(`/api/emailalerts/${alertHash}/view/`, null, {
            method: 'GET',
        });
    }

    /**
     * Make a password reset request for the user.
     */
    resetPassword(email: string): Promise<any> {
        return this.uncachedRequest(`/api/reset-password/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
            }),
        });
    }

    /**
     * Confirm a reset password request from the API
     * by verifying the token.
     */
    resetPasswordConfirm(token: string): Promise<any> {
        return this.uncachedRequest('/api/reset-password/confirm/', { token });
    }

    /**
     * Get new developments to inject into the search results.
     * In case ENABLE_MERGED_INDEX is set, the request should be in the English version because
     * the result will be retrieved in the format of non-merged index, meaning the normal fields (like 'title'' will
     * contain the information translated in the requested language, and the language specific fields (like 'title_l1')
     * will contain the other value
     */
    newDevelopments(locationHierarchy: Array<string>): Promise<any> {
        const init = CONFIG.build.ENABLE_MERGED_INDEX
            ? {
                  headers: {
                      'Accept-Language': 'en',
                  },
              }
            : {};
        return this.request(
            '/api/searchinjections/',
            {
                location_hierarchy: locationHierarchy,
            },
            init,
        );
    }

    /**
     * Gets the side banners to display on the search page.
     */
    sideBanners(locationID: string) {
        const params = { locationID } as const;

        return this.request('/api/sideBanners/', params).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: null };
            }

            return result;
        });
    }

    /**
     * Apply for mortgage.
     */
    applyForMortgage(params: any) {
        return this.request('/api/applyForMortgage/', params).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: null };
            }

            return result;
        });
    }

    /**
     * Gets the properties for a pre-defined search page
     * with the specified url.
     */
    searchPage(url: string) {
        return this.request('/api/searchPage/', { url });
    }

    /**
     * Gets popular searches for the specified puprose,
     * category and location slug.
     */
    popularSearches(
        purpose: string,
        categorySlug: string,
        locationSlug: string,
        withRelatedCategory?: string,
    ) {
        let params = { purpose, categorySlug, locationSlug };
        if (withRelatedCategory) {
            params = { ...params, withRelatedCategory };
        }
        return this.request('/api/popularSearches/', params);
    }

    /**
     * Gets location redirects that start with the specified city.
     * If city is null all redirects are returned
     */
    locationRedirects(city: string = null) {
        const params = { city } as const;
        return this.request('/api/locationredirects/', params);
    }

    /**
     * Gets all floor plans in the specified location.
     */
    allFloorPlans(locationSlug: string = null) {
        const key = `location_slug_${this.language}`;
        const parameters = {
            [key]: locationSlug,
            state: 'active',
        } as const;
        return this.request('/api/floorPlans/', parameters);
    }

    /**
     * Gets a floor plan with the specified ID.
     */
    floorPlan(floorPlanId: string = null) {
        const parameters = {
            id: floorPlanId,
        } as const;
        return this.request('/api/floorPlans/', parameters);
    }

    /**
     * Gets all the matching floor plans
     */
    matchingFloorPlans(
        locationSlug: string = null,
        categorySlug: string = null,
        bedsCount: number = null,
        bathsCount: number = null,
    ) {
        const parameters = {
            location_slug_en: locationSlug,
            category_slug_en: categorySlug,
            bedroom_count: bedsCount,
            bathroom_count: bathsCount,
            state: 'active',
            quality_state: 'valid',
        } as const;
        return this.request('/api/floorPlans/', parameters);
    }

    citiesStatistics(purpose: any, categoryExternalID: string) {
        return this.request('/api/citiesStatistics/', {
            purpose,
            category: categoryExternalID,
        }).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: [] };
            }

            return {
                ...result,
                data: result.data.map((element) => ({
                    count: {
                        inactive: element.inactive_count,
                        active: element.active_count,
                    },
                    location: {
                        name: element.location_name,
                        slug: element.location_slug,
                    },
                    category: {
                        name: element.category_name,
                        slug: element.category_slug,
                    },
                })),
            };
        });
    }

    /**
     * Gets a page rule for the specified combination of
     * purpose, category, location and beds.
     */
    pageRule(
        purpose: string,
        categoryExternalIDHierarchy: Array<string>,
        locationExternalIDHierarchy: Array<string>,
        beds: string,
        exactCategoryMatch?: boolean | null,
    ): Promise<any> {
        return this.request('/api/pageRule/', {
            purpose,
            categoryExternalIDHierarchy: categoryExternalIDHierarchy.join(','),
            locationExternalIDHierarchy: locationExternalIDHierarchy.join(','),
            beds,
            exactCategoryMatch,
        });
    }

    subscribeForNotifications(
        userID: string | null | undefined,
        appType: string = AppType.WEB_APP,
    ): Promise<any> {
        return this.post('/api/notifications/subscribe/', { userID, appType });
    }

    unsubscribeFromNotifications(
        userID: string | null | undefined,
        appType: string = AppType.WEB_APP,
    ): Promise<any> {
        return this.post('/api/notifications/unsubscribe/', { userID, appType });
    }

    /**
     * Retrieves a single node in the tree of floor plans.
     */
    floorPlanTreeNode(locationSlug: string | null = null): Promise<any> {
        return this.request('/api/floorPlans/tree/', { locationSlug });
    }

    /**
     * Retrieves a single floor plan by ID.
     */
    floorPlanDetail(id: number): Promise<any> {
        return this.request(`/api/floorPlans/${id}/`).then(({ status, data }) => {
            if (status !== 200 || !data) {
                return { status, data };
            }

            const floorPlans = data.floorPlans || [];

            const floorPlan = floorPlans.find((innerFloorPlan) => {
                const floorPlanID = (innerFloorPlan || {}).id;
                return floorPlanID === id;
            });

            if (floorPlan) {
                floorPlan.related = floorPlans.filter((innerFloorPlan) => {
                    const floorPlanID = (innerFloorPlan || {}).id;
                    return floorPlanID !== id;
                });
            }

            return { status, data: floorPlan };
        });
    }

    /**
     * Gets verification information about the specified ad.
     */
    getAdVerification(externalID: string): Promise<any> {
        return this.request(`/api/listing/${externalID}/verification/`);
    }

    /**
     * Sends the image url for the verify property action
     */
    submitAdVerification(externalID: string, data: Record<any, any>): Promise<any> {
        return this.request(`/api/listing/${externalID}/verification/`, null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    mapBasedSearch(
        purpose: string,
        category: string,
        location?: Array<string> | null,
        accurateLocation: boolean | null = true,
    ) {
        return this.requestBinary(`/api/mapBasedSearch/`, {
            purpose,
            location,
            category,
            accurateLocation,
        });
    }

    projectMapBasedSearch(category: string, location?: Array<string> | null) {
        return this.requestBinary(`/api/mapBasedSearch/projects/`, {
            location,
            category,
        });
    }

    transactionClusters(
        purpose: string,
        category: string,
        locations?: Array<string> | null,
        startTimestamp = 0,
        endTimestamp = 0,
    ) {
        return this.requestStream(`/api/mapBasedSearch/transactions/`, {
            purpose,
            locations,
            category,
            startTimestamp,
            endTimestamp,
        });
    }

    /**
     * Upserts a list of ads/listings.
     */
    upsertAds(ads: Array<any>): Promise<any> {
        return this.post(`/api/updateListings/`, ads);
    }

    /**
     * Reports a property
     * @param propertyID the primary key of the property
     * @param report the payload with the report data
     * @returns {*} a promise containing the response
     */
    sendReport(propertyID: string, report: Report): Promise<any> {
        return this.post(`/api/listing/id/${propertyID}/complaints/`, report);
    }

    getFloorPlanSitemapIndex() {
        return this.uncachedRequest('/api/sitemap/floorPlans/').then(({ status, data }) => {
            if (status !== 200 || !data) {
                return [];
            }

            const entries = data.map((entry) => ({
                page: parseInt(entry.page, 10),
                entryType: entry.entry_type,
            }));

            return { status, entries };
        });
    }

    getFloorPlanSitemapPage(entryType: string, page: number) {
        return this.uncachedRequest(`/api/sitemap/floorPlans/${entryType}/${page}`);
    }

    getAgentChatAvailability(userExternalID: string) {
        return this.request(`/api/chat/availability/${userExternalID}`);
    }

    getOrCreateChatAccount() {
        return this.post('/api/chat/account');
    }

    getChatAccount() {
        return this.request('/api/chat/account');
    }

    enableChatNotification() {
        return this.post('/api/chat/webhooks/sendMessage');
    }

    getEvents() {
        return this.request(`/api/events/`);
    }

    getEventRequest(eventID: string, agentExternalID: string): Promise<any> {
        return this.request(`/api/events/${eventID}/request/${agentExternalID}/`);
    }

    createEventRequest(eventID: string, agentExternalID: string, extra: any): Promise<any> {
        return this.post(`/api/events/${eventID}/request/${agentExternalID}/`, { extra });
    }

    updateEventRequest(eventID: string, agentExternalID: string, extra: any): Promise<any> {
        return this.patch(`/api/events/${eventID}/request/${agentExternalID}/`, { extra });
    }

    /**
     * Creates a unique agent review ID
     */
    postAgentReview(agentReview: CreatedAgentReview): Promise<any> {
        return this.post('/api/agent/reviews', agentReview);
    }

    /**
     * Partially updates an agent review
     */
    patchAgentReview(agentReview: FilledAgentReview | DecidedAgentReview): Promise<any> {
        return this.uncachedRequest(`/api/agent/reviews/${agentReview.externalID}`, null, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(omit(agentReview, 'externalID')),
        });
    }

    /**
     * Gets all the agent reviews for an agent(with some filters)
     */
    getAgentReviews(parameters: any): Promise<any> {
        return this.request(`/api/agent/reviews?${StratAPI.buildQueryString(parameters)}`);
    }

    /**
     * Gets all the reviews for all the agents of an agency
     */
    getAgencyAgentsReviews(parameters: any): Promise<any> {
        return this.request(
            `/api/agency/${parameters.agencyExternalID}/agents/reviews?${StratAPI.buildQueryString(
                omit(parameters, 'agencyExternalID'),
            )}`,
        );
    }

    /**
     * Fetch agency ads
     */
    agencyAds(agencyExternalID: string, queryParams?: AgencyAdsQueryParams) {
        const url = `/api/agency/${agencyExternalID}/ads`;
        let urlWithParams = url;
        /**
         * HTTPApi buildQueryString does not encode array GET params in a Django-compatible way
         * it merges all the array items into 1 key e.g. status=active%2pending
         * StratAPI buildQueryString encoding each item in a separate key
         * e.g. status=active&status=pending
         */
        urlWithParams += `?${StratAPI.buildQueryString(queryParams)}`;

        return this.uncachedRequest(urlWithParams);
    }

    /**
     * Change the agent for list of ads
     */
    changeAdsAgent(
        agencyExternalID: string,
        agentExternalID: string,
        params: {
            readonly ad_external_ids?: string[];
            readonly current_agent_external_id?: string;
        },
    ): Promise<any> {
        return this.patch(`/api/agency/${agencyExternalID}/agent/${agentExternalID}/ads`, params);
    }

    /**
     * Remove the agent and replace them with different agent
     */
    removeAgent(
        agencyExternalID: string,
        agentExternalID: string,
        params: {
            readonly replacement_agent_external_id: string;
        },
    ): Promise<any> {
        return this.post(`/api/agency/${agencyExternalID}/agent/${agentExternalID}`, params);
    }

    /**
     * Add/Update Quota for a specific Agent on a specific Packages
     */
    assignAgentQuota(
        agencyExternalID: string,
        agentExternalID: string,
        params: AssignAgentQuotaParams,
    ): Promise<any> {
        return this.post(`/api/agency/${agencyExternalID}/agent/${agentExternalID}/quota/`, params);
    }

    /**
     * Add/Update Credits for a specific Agent from a specfic agency
     */
    assignAgentCredits(
        agencyExternalID: string,
        agentExternalID: string,
        params: { credit: number },
    ): Promise<any> {
        return this.post(
            `/api/agency/${agencyExternalID}/agentUser/${agentExternalID}/productCredit/`,
            params,
        );
    }

    /**
     * Retrieves consumption breakdown for either the Agency owner or agents
     */
    creditConsumptionBreakdown(
        agencyExternalID: string,
        params: { scope: string; agent_user_external_id?: string },
    ): Promise<any> {
        return this.request(`/api/agency/${agencyExternalID}/productCredit/breakDown`, params);
    }

    /**
     * Retrieves consumption logs for either the Agency owner or agents
     */
    creditConsumptionLogs(
        agencyExternalID: string,
        params: { scope: string; page: number; agent_user_external_id?: string },
    ): Promise<any> {
        return this.request(`/api/agency/${agencyExternalID}/productCredit/consumptionLog`, params);
    }

    deleteAgentReview(reviewExternalID: string, reviewerEmail: string): Promise<any> {
        return this.uncachedRequest(`/api/agent/reviews/${reviewExternalID}`, null, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ reviewerEmail }),
        });
    }

    addAgentReviewReply(
        reviewExternalID: string,
        parameters: {
            agentExternalID: string;
            reply: string;
        },
    ): Promise<any> {
        return this.post(`/api/agent/reviews/${reviewExternalID}/reply`, parameters);
    }

    /**
     * Confirm a reviewer email address and update review state
     */
    putEmailConfirmation(token: string): Promise<any> {
        return this.put(`/api/agent/reviews/confirm/${token}`);
    }

    /**
     * Retrieves the leaderboard stats for an agent
     */
    getAgentLeaderboardStats(agentExternalID: string) {
        return this.request(`/api/agent/${agentExternalID}/stats/`);
    }

    /**
     * Retrieves the feeds for an agent
     */
    getAgentFeeds(agentExternalID: string, updatedAt: number) {
        return this.request(
            `/api/agent/${agentExternalID}/feeds?${StratAPI.buildQueryString({ updatedAt })}`,
        );
    }

    categories(params: any) {
        return this.request(`/api/categories`, params);
    }

    categoryFields({
        categoryExternalIDs,
        categorySlugs,
        roles,
        lite,
        includeChildCategories,
        includeWithoutCategory,
        splitByCategoryIDs,
        flatChoices,
        groupChoicesBySection,
    }: {
        categoryExternalIDs?: Array<string>;
        roles?: Array<string>;
        lite?: boolean;
        includeChildCategories?: boolean;
        includeWithoutCategory?: boolean;
        splitByCategoryIDs?: boolean;
        flatChoices?: boolean;
        groupChoicesBySection?: boolean;
    }) {
        const queryString = StratAPI.buildQueryString({
            categoryExternalIDs,
            categorySlugs,
            roles,
            lite,
            includeChildCategories,
            includeWithoutCategory,
            splitByCategoryIDs,
            flatChoices,
            groupChoicesBySection,
            flat: true,
        });

        return this.request(`/api/categoryFields?${queryString}`);
    }

    categoryFieldCombinations({ categoryID }: { categoryID: string | number }) {
        return this.request(`/api/categoryFieldCombinations/${categoryID}`, null, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    /**
     * Returns presigned url for the filed identified by `folderName/fileName`
     * @param fileName the name of the file
     * @param folderName the name of the folder
     * @param method type of action for which presigned url is needed GET / PUT / POST
     * @param extraParams extra payload parameters to pass to presigned url request
     */
    presignedURL(fileName: string, folderName: string, method: string, extraParams: any) {
        return this.post(`/api/file/presigned_url`, {
            fileName,
            folderName,
            method,
            ...extraParams,
        });
    }

    user(externalID: string, format?: string | null) {
        const queryString = StratAPI.buildQueryString({ externalIDs: [externalID], format });
        return this.request(`/api/users/search/?${queryString}`).then(StratAPI.firstOrNull);
    }

    users(externalIDs: Array<string>) {
        // make sure that no matter how many external ids we have the request is successful
        // for a GET request the maximum number is around 53-55
        // for POST number is not limited. This is needed, for example when we need to see
        // all buyer's profiles for a specific ad.
        return this.post(`/api/users/search/`, { externalIDs });
    }

    userAd(userExternalID: string, adExternalID: string) {
        const url = `/api/user/${userExternalID}/ads/${adExternalID}/`;
        return this.uncachedRequest(url);
    }

    adStateDetails(userExternalID: string, adExternalID: string) {
        const url = `/api/user/${userExternalID}/ads/${adExternalID}/stateDetails`;
        return this.uncachedRequest(url);
    }

    userAds(userExternalID: string, queryParams?: UserAdsQueryParams) {
        const url = `/api/user/${userExternalID}/ads/`;
        let urlWithParams = url;
        const { dateFilters, ...restQueryParams } = queryParams;

        if (restQueryParams && Object.keys(restQueryParams).length > 0) {
            // HTTPApi does not encode array GET params in a Django-compatible way.
            urlWithParams += `?${StratAPI.buildQueryString(restQueryParams)}`;
        }

        if (dateFilters && Object.keys(dateFilters).length > 0) {
            Object.keys(dateFilters).forEach((key) => {
                if (!dateFilters[key]) {
                    return;
                }
                if (url !== urlWithParams) {
                    urlWithParams += `&${key}=${dateFilters[key]}`;
                } else {
                    urlWithParams += `?${key}=${dateFilters[key]}`;
                }
            });
        }

        return this.uncachedRequest(urlWithParams);
    }

    userAdLimits(userExternalID: string, categoryExternalID?: string | null) {
        const url = `/api/user/${userExternalID}/limits/`;

        if (categoryExternalID) {
            return this.uncachedRequest(url, { category: categoryExternalID });
        }

        return this.uncachedRequest(url);
    }

    userFromAd(adExternalID: string, format?: string | null) {
        const queryString = StratAPI.buildQueryString({ adExternalIDs: [adExternalID], format });
        return this.request(`/api/users/search/?${queryString}`).then(StratAPI.firstOrNull);
    }

    userNotifications(userExternalID: string, params: PaginationParams) {
        const { page, pageSize } = params;
        const queryString = `page=${page}&page_size=${pageSize}`;
        return this.request(`/api/user/${userExternalID}/notifications?${queryString}`);
    }

    userConsumedProductAds(
        userExternalID: string,
        productPurchaseID: number,
        queryParams?: ConsumedAdsQueryParams,
    ) {
        let url = `/api/user/${userExternalID}/productPurchases/${productPurchaseID}/ads/`;

        if (queryParams && Object.keys(queryParams).length > 0) {
            // HTTPApi does not encode array GET params in a Django-compatible way.
            url += `?${StratAPI.buildQueryString(queryParams)}`;
        }

        return this.uncachedRequest(url);
    }

    productPurchases({
        adExternalID,
        userExternalID,
        status,
        productTypes,
        categoryID,
        IDs,
        bundleProductPurchases,
    }: {
        readonly userExternalID: string;
        readonly adExternalID?: string;
        readonly status?: string;
        readonly productTypes?: any;
        readonly categoryID?: string;
        readonly IDs?: string[];
        readonly bundleProductPurchases?: boolean;
    }) {
        const url = `/api/user/${userExternalID}/productPurchases`;
        if (adExternalID || status || productTypes || categoryID || IDs) {
            return this.request(url, {
                adExternalID,
                status,
                productTypes,
                categoryID,
                IDs,
                bundleProductPurchases,
            });
        }
        return this.request(url);
    }

    payments(userExternalID: string, page: string, sorting: string, pageSize: number) {
        const url = `/api/user/${userExternalID}/payments`;
        return this.request(url, { page_size: pageSize, page, sorting });
    }

    paymentOrders(userExternalID: string, page: string, sorting: string, pageSize: number) {
        const url = `/api/user/${userExternalID}/payments/orders`;
        return this.request(url, { page_size: pageSize, page, sorting });
    }

    viewNotifications(notificationIDs: Array<number>, userExternalID: string) {
        return this.post(`/api/user/${userExternalID}/notifications/view?lite=true`, {
            notificationIDs,
        });
    }

    readNotification(notificationID: number, userExternalID: string) {
        return this.post(`/api/user/${userExternalID}/notifications/read?lite=true`, {
            notificationID,
        });
    }

    health() {
        return this.request('/api/health/backend');
    }

    applyProduct(adExternalID: string, payload: ApplyProductPayload): Promise<any> {
        return this.post(`/api/listing/${adExternalID}/product/`, payload)
            .catch(this.constructor.handleException)
            .then(this.constructor.handleResponse);
    }

    adMetrics(adsExternalIDs: Array<string>) {
        const queryString = StratAPI.buildQueryString({ ads_external_ids: adsExternalIDs });

        return this.uncachedRequest(`/api/listing/metrics/?${queryString}`);
    }

    building(locationSlug: string) {
        return this.request(`/api/building/`, { locationSlug });
    }

    locationByCoordinates(
        longitude: number,
        latitude: number,
        params: LocationByCoordinatesParams,
    ) {
        return this.request(`/api/location/coordinates/${longitude}/${latitude}`, params);
    }

    adContactInfo(externalID: string) {
        return this.request(`/api/listing/${externalID}/contactInfo/`);
    }

    featuredAgencies(categoryExternalID: string) {
        return this.request(`/api/agencies/`, {
            categoryExternalID,
            product: 'featured',
        });
    }

    titaniumAgencies(cityID: number) {
        return this.request(`/api/titaniumAgencies/city/${cityID}`).then((result) => {
            if (result.status !== 200) {
                return { ...result, data: null };
            }

            return result;
        });
    }

    cpmlProjects() {
        return this.request('/api/cpmlProjects?random=1 ').then((response) => {
            if (response.status !== 200) {
                return { ...response, data: null };
            }

            const convertArea = ConversionTable.get(Area.SQM).get(Area.SQFT);
            const transformProject = (cpmlProject) => {
                const minArea = Math.min(
                        ...(cpmlProject.units || [])
                            .map((unit) => unit.areaMin)
                            .filter((area) => area !== null),
                    ),
                    maxArea = Math.max(
                        ...(cpmlProject.units || [])
                            .map((unit) => unit.areaMax)
                            .filter((area) => area !== null),
                    ),
                    minPrice = Math.min(
                        ...(cpmlProject.units || [])
                            .map((unit) => unit.priceMin)
                            .filter((price) => price !== null),
                    ),
                    maxPrice = Math.max(
                        ...(cpmlProject.units || [])
                            .map((unit) => unit.priceMax)
                            .filter((price) => price !== null),
                    );

                return {
                    id: +cpmlProject.externalID,
                    url: `/new-projects/${cpmlProject.slug}.html`,
                    slug: cpmlProject.slug,
                    images: [{ url: thumbnailURL(cpmlProject.coverPhoto?.id, 'jpeg') }],
                    title: cpmlProject.title,
                    city:
                        cpmlProject.location.find(
                            (location) => location.level === CONFIG.build.CITY_LEVEL,
                        )?.name ?? '',
                    location: cpmlProject.location.at(-1)?.name ?? '',
                    type: [
                        ...new Set(
                            (cpmlProject.units || []).map(
                                (unit) => unit.category.at(-1)?.name ?? '',
                            ),
                        ),
                    ].join(','),
                    product: PropertyProduct.HOT,
                    area: {
                        min: convertArea(minArea ?? 0),
                        max: convertArea(maxArea ?? 0),
                        area: brandingSettings.defaultAreaUnit,
                    },
                    price: {
                        min: minPrice,
                        max: maxPrice,
                        unit: brandingSettings.defaultCurrencyName,
                    },
                };
            };

            return {
                data: {
                    newProjects: (response.data?.results || []).slice(0, 5).map(transformProject),
                },
                status: response.status,
            };
        });
    }

    recentSocialPosts() {
        return this.request('/api/recentSocialPosts');
    }

    paymentMethods() {
        return this.request('/api/paymentmethods');
    }

    createShortLink(destinationURL: string): Promise<APIResponse> {
        return this.post(`/api/shortLink`, { destinationURL });
    }

    saveUserImage(userExternalID: string, fileURL: string) {
        if (!userExternalID || !fileURL) {
            return Promise.reject(new Error('User external id or file url is undefined'));
        }
        return this.post(`/api/user/${userExternalID}/image`, {
            fileURL,
        });
    }

    deleteUserImage(userExternalID: string) {
        if (!userExternalID) {
            return Promise.reject(new Error('User external id is undefined'));
        }
        return this.request(`/api/user/${userExternalID}/image`, null, {
            method: 'DELETE',
        });
    }

    adSearchConfiguration(externalID: string) {
        return this.request(`/api/ad/searchConfigurations/${externalID}`);
    }

    sendCashMyCarForm(payload: CashMyCarFormPayload): Promise<any> {
        return this.post(`/api/cashMyCar`, payload);
    }

    /**
     * Sent bayut gpt message
     */
    sendGPTMessage(user, messageList, platform, frontEndEnvironment): Promise<any> {
        return this.requestStream(
            `/api/gpt/chat`,
            null,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user,
                    messageList,
                    platform,
                    frontEndEnvironment,
                }),
            },
            { timeoutMS: 90000, disableCaching: true },
        );
    }

    sendGPTFeedback(
        responseID: string,
        feedbackType: string,
        feedbackReason: string,
    ): Promise<any> {
        return this.post(
            '/api/gpt/feedback',
            {
                responseID,
                feedbackType,
                feedbackReason,
            },
            { timeoutMS: 30000, disableCaching: true },
        );
    }

    updateAdDescriptionWithTranslation(
        adExternalID: string,
        languageCode: string,
        translation: string,
    ): Promise<any> {
        return this.put(
            `/api/updateAdDescriptionWithTranslation/${adExternalID}/${languageCode}`,
            {
                translation,
            },
            {
                timeoutMS: 3000,
            },
        );
    }

    saveTruEstimateReport(
        request: SendReportParams,
    ): Promise<APIResponse<SaveTruEstimateReportResponse>> {
        return this.post('/api/truEstimate/report', request);
    }

    getTruEstimateReport(reportID: string): Promise<APIResponse<TruEstimateReport>> {
        return this.request(`/api/truEstimate/report/${reportID}`);
    }

    getTruEstimateUserReportHistory(
        userID: string,
        page: number,
        resultsOnPage: number,
    ): Promise<APIResponse<List<ReportHistoryInformation>>> {
        return this.request(
            `/api/truEstimate/report/history/${userID}/${page}?resultsOnPage=${resultsOnPage}`,
        );
    }

    unitPlan(adExternalID: string): Promise<APIResponse<UnitPlan>> {
        return this.request('/api/unitPlan', {
            ad_external_id: adExternalID,
            status: UnitPlanStatus.READY,
            enabled: true,
        });
    }

    projects({
        locationSlug,
    }: {
        locationSlug: string;
    }): Promise<PaginatedAPIResponse<ProjectData>> {
        return this.request(`/api/projects`, { locationSlug });
    }
}

export default StratAPI;
