import { PropertyCompletionStatus } from 'strat/property/types';

const getConditionFromCompletionStatus = (
    completionStatus: Values<typeof PropertyCompletionStatus>,
) => {
    if (
        completionStatus === PropertyCompletionStatus.NEW ||
        completionStatus === PropertyCompletionStatus.USED
    ) {
        return completionStatus;
    }
    return PropertyCompletionStatus.ANY;
};

export default getConditionFromCompletionStatus;
