import React from 'react';
import { PageWidthWrapper } from 'strat/generic';
import { Flex } from 'strat/components';

import Footer from 'horizontal/footer';
import {
    BuyVerifiedCarBanner,
    CallForInspection,
    ExploreSellingWays,
    SellCarFormContainer,
    SellYourCarHero,
} from 'horizontal/verticals/cars';
import { Header } from 'horizontal/header';
import AppInstallBanner from '@app/home/appInstallBanner';
import VerticalHeader from 'horizontal/verticals/verticalHeader';
import { GTMDataLayer, PageType } from 'horizontal/gtm/components';

import styles from './styles/verticalCarsPageDesktop.cssm';
import VerticalCarsHead from './verticalCarsHead';

const VerticalCarsPageDesktop = {
    head: () => (
        <>
            <VerticalCarsHead />
            <GTMDataLayer pageType={PageType.CARS_VERTICAL} />
        </>
    ),
    body: () => (
        <Flex column stretchHeight fillContainer>
            <VerticalHeader renderHeader={() => <Header withHighlightedVertical />} />
            <PageWidthWrapper className={styles.pageWidthWrapper}>
                <SellYourCarHero />
                <SellCarFormContainer />
                <ExploreSellingWays />
                <BuyVerifiedCarBanner />
                <CallForInspection />
            </PageWidthWrapper>
            <AppInstallBanner />
            <Footer />
        </Flex>
    ),
};

export default VerticalCarsPageDesktop;
