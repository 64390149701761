import React from 'react';
import settings from '@app/branding/settings';

import type { Location, LocationChoices } from 'horizontal/types';
import { LocationRole } from 'horizontal/types';

const useIsLocationDeliverable = (choices: LocationChoices) => {
    const isLocationDeliverable = React.useCallback(
        (selectedLocation?: Location) => {
            if (
                settings.deliverySettings?.allowDeliveryFromAnyLocation ||
                selectedLocation?.roles?.includes(LocationRole.ALLOWS_DELIVERY)
            ) {
                return true;
            }
            if (!choices.length) {
                return false;
            }
            const deliveryLocation = choices
                .slice(-1)[0]
                .find((location) => location?.externalID === selectedLocation?.externalID);
            return deliveryLocation?.roles?.includes(LocationRole.ALLOWS_DELIVERY);
        },
        [choices],
    );
    return isLocationDeliverable;
};

export default useIsLocationDeliverable;
