import Loadable, { LoadingSpinner } from 'strat/loadable';

import { shouldRenderFloorPlanSection, hasFloorPlans } from './utility';
import useMatchingFloorPlans from './useMatchingFloorPlans';
import useFloorPlansTabs from './useFloorPlansTabs';
import { selectHasMatchingFloorPlans } from './selectors';
import { clearMatchingFloorPlans } from './state';

export default Loadable({
    loader: () =>
        import(
            /* webpackChunkName: 'floorPlansSection' */
            './floorPlansSection'
        ),
    loading: LoadingSpinner,
});

export {
    shouldRenderFloorPlanSection,
    useMatchingFloorPlans,
    useFloorPlansTabs,
    hasFloorPlans,
    selectHasMatchingFloorPlans,
    clearMatchingFloorPlans,
};
