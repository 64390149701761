import * as React from 'react';

import {
    getNegotiableLabel,
    useAdSecondaryPriceLabel,
    useGetDownPaymentLabel,
} from 'horizontal/ad';
import { LiteAd } from 'horizontal/types';
import useRentalPeriod from 'horizontal/adCard/useRentalPeriod';

import styles from './styles/adAdditionalPriceInfo.cssm';

type Props = {
    ad: LiteAd;
    readonly isGridLayout: boolean;
    readonly isMobile: boolean;
};

const AdAdditionalPriceInfo = ({ ad, isGridLayout, isMobile }: Props) => {
    const secondaryPriceLabel = useAdSecondaryPriceLabel(ad);
    const negotiableLabel = getNegotiableLabel(ad);
    const downPaymentLabel = useGetDownPaymentLabel(ad);
    const rentalPeriod = useRentalPeriod(ad);
    const priceInfoLabel = CONFIG.build.STRAT_ENABLE_DOWN_PAYMENT_REDESIGN
        ? rentalPeriod || downPaymentLabel
        : null;

    const additionalPriceInfo = negotiableLabel || secondaryPriceLabel;

    return isGridLayout || isMobile ? (
        <>
            {additionalPriceInfo && (
                <span className={styles.additionalPriceInfo}>{additionalPriceInfo}</span>
            )}
        </>
    ) : (
        <>
            {secondaryPriceLabel && (
                <span className={styles.additionalPriceInfo}>{secondaryPriceLabel}</span>
            )}
            {priceInfoLabel && (
                <>
                    <span
                        className={
                            priceInfoLabel === downPaymentLabel
                                ? styles.downPaymentLabel
                                : styles.additionalPriceInfo
                        }
                    >
                        {priceInfoLabel}
                    </span>
                </>
            )}
            {negotiableLabel && !priceInfoLabel && (
                <span className={styles.additionalPriceInfo}>{negotiableLabel}</span>
            )}
        </>
    );
};

export default AdAdditionalPriceInfo;
