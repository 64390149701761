import React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'strat/i18n/language';
import { useRouter } from 'react-true-router';
import { RouteNames } from 'strat/routes';
import { selectRouteParams } from 'strat/routes/selectors';
import canonicalUrlLanguagePrefix from '@app/branding/canonicalUrlLanguagePrefix';
import { serializeLocation } from 'react-true-router/location';

import SelectBusinessPackagesRoute from 'horizontal/routes/selectMultiplePackagesRoute';
import { ProductType } from 'horizontal/types';

import type { BusinessPackageExampleTypeValues } from './types';
import { BusinessPackageExampleType } from './types';

const getExampleTypeFromHash = (hash: string): BusinessPackageExampleTypeValues | null => {
    if (!hash) {
        return null;
    }

    const exampleName = hash.slice(1);
    const example: BusinessPackageExampleTypeValues | null | undefined = Object.values(
        BusinessPackageExampleType,
    ).find((name) => name === exampleName) as any;

    return example || null;
};

const ResetHashMethod = Object.freeze({
    REPLACE_ROUTE: 'replace_route',
    POP_ROUTE: 'pop_route',
});

type ResetHashMethodValues = Values<typeof ResetHashMethod>;

const useHashBusinessPackageExample = (): {
    activeExample: BusinessPackageExampleTypeValues | null;
    setActiveExample: (example: BusinessPackageExampleTypeValues | null) => void;
} => {
    const router = useRouter();
    const language = useI18n().locale;
    const routeParams = useSelector(selectRouteParams);

    const [example, setExample] = React.useState<any>(null);
    const [resetMethod, setResetMethod] = React.useState<ResetHashMethodValues>(
        ResetHashMethod.POP_ROUTE,
    );
    const productTypes = routeParams?.products?.split('_and_') as Values<typeof ProductType>[];

    const resetHash = React.useCallback(() => {
        if (resetMethod === ResetHashMethod.POP_ROUTE) {
            router.pop(false);
            return;
        }

        router.replaceRoute(
            RouteNames.SELECT_MULTIPLE_PACKAGES,
            {
                locationExternalID: routeParams?.location,
                categoryExternalID: routeParams?.category,
                adExternalID: routeParams?.adExternalID,
                productTypes: productTypes,
            },
            false,
        );
        setResetMethod(ResetHashMethod.POP_ROUTE);
    }, [router, routeParams, resetMethod, setResetMethod, productTypes]);

    const setActiveExample = React.useCallback(
        (value: BusinessPackageExampleTypeValues | null) => {
            if (value) {
                const hash = `#${value}`;
                const languagePrefix = canonicalUrlLanguagePrefix(language, hash);
                const rawLocalizedLocation = SelectBusinessPackagesRoute.createURL({
                    locationExternalID: routeParams?.location,
                    categoryExternalID: routeParams?.category,
                    adExternalID: routeParams?.adExternalID,
                    productTypes: productTypes,
                });
                const rawUrl = serializeLocation(rawLocalizedLocation);
                // We need to build the url to be the same as the page we are on + contain a hash.
                // Simply putting the hash won't work because the language will be set to the default one by the
                // url processor.
                const url = `${languagePrefix}${rawUrl}${hash}`;
                router.pushURL(url, false, true);
            } else {
                resetHash();
            }

            setExample(value);
        },
        [router, setExample, resetHash, language, routeParams, productTypes],
    );

    React.useEffect(() => {
        if (!process.env.IS_BROWSER) {
            return;
        }

        // These should be executed only once in the browser
        // if we land on the page with the hash already set.
        // - open the example dialog
        // - ensure that when the dialog is closed we stay on the same page
        const newExample = getExampleTypeFromHash(window.location.hash);
        if (newExample) {
            setResetMethod(ResetHashMethod.REPLACE_ROUTE);
            setExample(newExample);
        }
    }, []);

    React.useEffect(() => {
        const onHashChanged = () => {
            setExample(getExampleTypeFromHash(window.location.hash));
        };

        window.addEventListener('hashchange', onHashChanged);
        return () => window.removeEventListener('hashchange', onHashChanged);
    }, [setExample]);

    return {
        activeExample: example,
        setActiveExample,
    };
};

export default useHashBusinessPackageExample;
