export const UserOrdersSection = Object.freeze({
    ...(CONFIG.build.SHOW_PAYMENTS_TAB ? { PAYMENTS: 'payments' } : {}),
    ...(CONFIG.build.SHOW_PAYMENT_ORDERS_TAB ? { PAYMENT_ORDERS: 'payment_orders' } : {}),
    ORDERS: 'orders',
    BILLING: 'billing',
    Addresses: 'addresses',
    BUYING: 'buying',
    SELLING: 'selling',
});

export const UserOrdersSubSection = Object.freeze({
    CONSUMPTION: 'consumption',
});

export const ProductPurchaseVirtualStatus = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    SCHEDULED: 'scheduled',
    EXPIRED: 'expired',
    DISABLED: 'disabled',
});

export const OrderItemStatus = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
});

export const DeliveryRequestStatus = Object.freeze({
    ACTIVE: 'active',
    ARCHIVED: 'archived',
});

export const UserDeliveryOrderStatus = Object.freeze({
    NEW: 'new',
    ACCEPTED: 'accepted',
    VERIFIED: 'verified',
    REJECTED: 'rejected',
    OUT_FOR_DELIVERY: 'out_for_delivery',
    DELIVERED: 'delivered',
    PAYMENT_EN_ROUTE: 'payment_en_route',
    PAYMENT_DELIVERED: 'payment_delivered',
    CANCELED_BY_BUYER: 'canceled_by_buyer',
    CANCELED_BY_SELLER: 'canceled_by_seller',
    RETURN_EN_ROUTE: 'return_en_route',
    RETURNED: 'returned',
    NOT_VERIFIED: 'not_verified',
    CLOSED_BY_ADMIN: 'closed_by_admin',
    EXPIRED: 'expired',
    AWAIT_PICKUP: 'await_pickup',
});

export const CustomerType = Object.freeze({
    BUSINESS: 'business',
    RESIDENTIAL: 'residential',
});

export const AddressFormActions = Object.freeze({
    EDIT: 'edit',
    DEFAULT: 'save',
    DELETE: 'delete',
});

export type Invoice = {
    readonly externalID: string;
    readonly createdAt: string;
    readonly link: string;
    readonly amount: number;
};

export const AddressRole = Object.freeze({
    ALLOWS_DELIVERY: 'allows_delivery',
});

export type AddressInfo = {
    name?: string;
    addressLineOne?: string;
    addressLineTwo?: string;
    isDeleted?: boolean;
    isDefault?: boolean;
    location?: string;
    readonly id?: number;
    locationPath?: Array<any>;
    notes?: string;
    roles?: Array<Values<typeof AddressRole>>;
};

export type OrderInvoices = {
    readonly orderID: string;
    readonly createdAt: string;
    readonly invoices: Array<Invoice>;
};

export type BillingInfo = {
    readonly customerType?: string;
    readonly email?: string;
    readonly customerName?: string;
    readonly businessName?: string;
    readonly phoneNumber?: string;
    readonly addressLineOne?: string;
    readonly addressLineTwo?: string;
    readonly state?: string;
    readonly city?: string;
    readonly vatNumber?: string;
    readonly commercialNumber?: string;
};
