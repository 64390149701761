import React from 'react';

import { PaymentSuccess } from 'horizontal/payment';
import { GTMPaymentSuccessDataLayer } from 'horizontal/gtm/components';
import type { PaymentOrder } from 'horizontal/payment';
import type { FullAd, ProductPurchase } from 'horizontal/types';

import PrivateNonIndexableHead from './privateNonIndexableHead';

type Props = {
    readonly productPackageNames: string;
    readonly packageOffers?: Array<ProductPurchase> | null;
    readonly ad?: FullAd | null;
};

const PaymentSuccessPage = {
    head: (paymentOrder: PaymentOrder) => (
        <>
            <PrivateNonIndexableHead />
            <GTMPaymentSuccessDataLayer paymentOrder={paymentOrder} />
        </>
    ),
    body: ({ productPackageNames, packageOffers, ad }: Props) => (
        <PaymentSuccess
            productPackageNames={productPackageNames}
            packageOffers={packageOffers}
            ad={ad}
        />
    ),
} as const;

export default PaymentSuccessPage;
