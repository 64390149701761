import * as React from 'react';
import { Trans } from '@lingui/macro';
import { selectNumberFormatterFactory } from 'strat/i18n/language';
import { useSelector } from 'react-redux';
import Category from '@app/branding/category';

import { useFormattedPrice } from 'horizontal/util';
import type { MixedAd } from 'horizontal/types';
import { DownPaymentField } from 'horizontal/fields/commonPostingFields';
import { PropertiesPaymentOptionType, VehiclesPaymentOptionType } from 'horizontal/types';

const useGetDownPaymentLabel = (
    ad: MixedAd,
    isCompact = false,
    hideCurrency?: boolean,
): React.ReactElement | null => {
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);
    const formatterSettings = hideCurrency ? { style: 'decimal' } : {};

    const downPayment = ad.extraFields?.down_payment || 0;
    const isPropertyCategory = Category.isOfPropertySubtype(ad.category?.[0]);
    const isInstallmentPaymentType = isPropertyCategory
        ? ad.extraFields?.payment_option !== PropertiesPaymentOptionType.CASH
        : ![VehiclesPaymentOptionType.CASH, VehiclesPaymentOptionType.EXCHANGE].includes(
              String(ad.extraFields?.payment_option) || '',
          );
    const downPaymentFormattedPrice = useFormattedPrice(downPayment);

    // for all property ads display the down payment label even the down payment is 0 or null
    if (
        !isInstallmentPaymentType ||
        (ad.extraFields && !(DownPaymentField.attribute in ad.extraFields))
    ) {
        return null;
    }
    const downPaymentFormattedPriceWithoutCurrency =
        numberFormatterFactory(formatterSettings).format(downPayment);
    const downPaymentFormattedCompactPrice = numberFormatterFactory({
        notation: 'compact',
        ...formatterSettings,
    }).format(downPayment);

    let label = '';
    if (isCompact) {
        label = downPaymentFormattedCompactPrice;
    } else if (hideCurrency) {
        label = downPaymentFormattedPriceWithoutCurrency;
    } else {
        label = downPaymentFormattedPrice;
    }

    return <Trans>{downPayment === 0 ? '0%' : label} Down Payment</Trans>;
};

export default useGetDownPaymentLabel;
