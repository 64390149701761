import type { ProductPackageOffer } from '../types';

const getTaxInfoFromOffers = (offers?: ProductPackageOffer[] | null): [boolean, number] => {
    if (!offers || !offers.length) {
        return [false, 0];
    }
    const firstOffer: ProductPackageOffer | null | undefined = offers.find(
        (offer: ProductPackageOffer) => offer.grossPrice !== 0,
    );
    if (firstOffer == null) {
        return [false, 0];
    }
    return [
        true,
        Math.round(
            (firstOffer.grossPrice / (firstOffer.discountPrice || firstOffer.price) - 1) * 100,
        ),
    ];
};

export default getTaxInfoFromOffers;
