/**
 * https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro
 * This is a function whose return type is a type predicate, in other words it will narrow the variable to that specific type
 * if the original type is compatible.
 * @param arg that will be tested for truthiness
 * @returns {boolean} that signals if the {arg} is defined or not
 */
const isDefined = <T>(arg: T | null): arg is T => {
    return !!arg;
};

export default isDefined;
