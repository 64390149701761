export const PhoneNumberLinkType = Object.freeze({
    SMS: 'sms',
    CALL: 'tel',
});

const redirectToPhoneURL = (
    phoneNumber: string,
    linkType: Values<typeof PhoneNumberLinkType>,
    body?: string | null,
) => {
    let url = `${linkType}:${phoneNumber}`;
    if (linkType == PhoneNumberLinkType.SMS && body) {
        url = `${url}?&body=${encodeURI(body)}`;
    }
    window.open(url, '_self');
};

export default redirectToPhoneURL;
