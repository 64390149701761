import * as React from 'react';
import { Trans } from '@lingui/macro';
import { Text } from 'strat/components';

import styles from './styles/featuredBadge.cssm';

const FeaturedBadge = () => {
    return (
        <div className={styles.container}>
            <Text.Small bold>
                <Trans>Featured</Trans>
            </Text.Small>
        </div>
    );
};

export default FeaturedBadge;
