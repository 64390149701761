import React from 'react';

import Loadable, { LoadingSpinner } from 'strat/loadable';
import IconBack from '@app/assets/icons/iconBack.svg';

import UserPopUp from './userPopUp';
import styles from './styles/popUp.cssm';

const EmailSentPopUpContents = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ './emailSentContents'),
    loading: LoadingSpinner,
});

/**
 * Properties for {@see EmailSentPopUp}.
 */
type Props = {
    /**
     * Indicates whether the dialog should be visible.
     */
    visible: boolean;
    /**
     * Method to call when the visibility of the dialog changes.
     */
    onVisibilityChanged: (visible: boolean) => void;
    /**
     * Method to call when the "Back To Login" button is clicked.
     */
    onBackClick?: (event: React.SyntheticEvent<any>) => void;
    /**
     * Method to call when the "Close" button is clicked.
     */
    onCloseClick?: (event: React.SyntheticEvent<any>) => void;
    stackGroup?: string;
};

const EmailSentPopUp = (props: Props) => {
    const renderBackButton = () => (
        <IconBack onClick={props.onBackClick} className={styles.backButton} />
    );

    return (
        <UserPopUp
            visible={props.visible}
            onVisibilityChanged={props.onVisibilityChanged}
            withoutCloseButton
            // @ts-expect-error - TS2322 - Type '() => JSX.Element' is not assignable to type '() => Node'.
            renderHeaderBackButton={renderBackButton}
            stackGroup={props.stackGroup || 'emailSent'}
        >
            <EmailSentPopUpContents onCloseClick={props.onCloseClick} />
        </UserPopUp>
    );
};

export default EmailSentPopUp;
