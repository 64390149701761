import React from 'react';

import styles from './styles/loadingSpinner.cssm';

/**
 * Properties for {@see LoadingSpinner}.
 */
type Props = {
    className?: string;
    delay?: boolean;
    darkCircleClassName?: string;
    retry?: () => any;
};

/**
 * Spinner for indicating loading.
 */
const LoadingSpinner = ({ className, delay, darkCircleClassName = styles.darkCircle }: Props) => {
    const classNames = [styles.spinner];
    if (delay) {
        classNames.push(styles.delay);
    }
    if (className) {
        classNames.push(className);
    }

    return (
        <svg aria-label="Loading spinner" viewBox="0 0 48 48" className={classNames.join(' ')}>
            <circle cx="24" cy="24.01" r="21" className={styles.lightCircle} />
            <circle
                cx="24"
                cy="24.01"
                r="21"
                className={darkCircleClassName}
                transform="rotate(-90 24 24)"
            />
        </svg>
    );
};

export default LoadingSpinner;
