/**
 * Available actions for unknown parameters.
 */
const Actions = Object.freeze({
    SET_UNKNOWN_PARAMETERS: 'UNKNOWN_PARAMETERS/SET',
} as const);

/**
 * An action that sets an unknown parameter.
 */
type SetUnknownParametersAction = {
    type: 'UNKNOWN_PARAMETERS/SET';
    unknownParams: any | null | undefined;
};

/**
 * Default unknown parameter state.
 */
const defaultState: any | null | undefined = {};

/**
 * unknownParameter reducer.
 */
const unknownParametersReducer = (
    state: any | null | undefined = defaultState,
    action: SetUnknownParametersAction,
) => {
    switch (action.type) {
        case Actions.SET_UNKNOWN_PARAMETERS:
            return action.unknownParams;
        default:
            return state;
    }
};

/**
 * Sets the active campaign.
 */
const setUnknownParameters = (unknownParams?: any | null): SetUnknownParametersAction => ({
    type: Actions.SET_UNKNOWN_PARAMETERS,
    unknownParams,
});

export { setUnknownParameters };

export default unknownParametersReducer;
