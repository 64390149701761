import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentPage } from 'strat/search/selectors';
import { RadioGroup, RoundedButtonChoice } from 'strat/components';
import { RenderChoiceProps } from 'strat/components/options';
import { ChoiceValue } from 'strat/components/types';

import { trackMyAdsAdFilter } from 'horizontal/gtm';

import useStateFilter from './useStateFilter';
import useSetAdManagementFilters from './useSetAdManagementFilters';
import useAdManagementFilters from './useAdManagementFilters';

const SecondaryRoundedButtonChoice = <T extends ChoiceValue>({
    option,
    isSelected,
    onChange,
}: RenderChoiceProps<T>) => (
    <RoundedButtonChoice option={option} isSelected={isSelected} onChange={onChange} secondary />
);

const StateFilter = () => {
    const choices = useStateFilter();

    const setAdManagementFilters = useSetAdManagementFilters();
    const filters = useAdManagementFilters();
    const pageNumber = useSelector(selectCurrentPage);

    const onChange = React.useCallback(
        (value: any) => {
            setAdManagementFilters({ stateFilter: value });
            trackMyAdsAdFilter(value || 'all', pageNumber);
        },
        [setAdManagementFilters, pageNumber],
    );

    return (
        <RadioGroup
            selectedValue={filters.stateFilter || null}
            choices={choices}
            compact
            onChange={onChange}
            renderChoice={SecondaryRoundedButtonChoice}
        />
    );
};

export default StateFilter;
