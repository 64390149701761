//@ts-nocheck
const Actions = Object.freeze({
    SET: 'SEO/PAGE_INFO/SET',
    RESET: 'SEO/PAGE_INFO/RESET',
});

/**
 * On-page SEO info.
 */
export type PageInfo = {
    pageTitle: string | null | undefined;
    description: string | null | undefined;
    h1Title: string | null | undefined;
    indexable: boolean | null | undefined;
    canonicalURL: string | null | undefined;
    localizedURLs: {
        [key: string]: [string];
    };
    languageSwitchURLs:
        | {
              [key: string]: [string];
          }
        | null
        | undefined;
};

/**
 * Default state.
 *
 * By default, none of of the page info properties
 * are set. These should only be used to override
 * the default behaviour. Each value is applied
 * without modifications.
 */
const defaultState = {
    // <title>{pageTitle}</title>
    pageTitle: null,

    // <meta name="description" value={description} />
    description: null,

    // <h1>{h1Title</h1>
    h1Title: null,

    // null = default/detect, false = noindex, true = index
    // <meta
    //      name="robots"
    //      value={indexable ? "index follow" : "noindex nofollow"}
    // />
    indexable: null,

    // <meta name="canonical" value={canonicalURL} />
    canonicalURL: null,

    // dict of the current URL in other languages, key
    // is the language, value is the URL
    // <link rel="alternate" hreflang="{lang}" href="{url}" />
    localizedURLs: null,

    // url to that the language switch button should point to
    languageSwitchURLs: null,
} as const;

const pageInfoReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.SET:
            return {
                ...state,
                pageTitle: action.pageTitle,
                description: action.description,
                h1Title: action.h1Title,
                indexable: action.indexable,
                canonicalURL: action.canonicalURL,
                localizedURLs: action.localizedURLs,
                languageSwitchURLs: action.languageSwitchURLs,
            };

        case Actions.RESET:
            return defaultState;

        default:
            return state;
    }
};

/**
 * Sets on-page SEO info such as title, meta description.
 */
const setPageInfo = (pageInfo: PageInfo) => ({
    type: Actions.SET,
    ...pageInfo,
});

/**
 * Resets the entire seo state to the default state.
 */
const resetPageInfo = () => ({
    type: Actions.RESET,
});

export { setPageInfo, resetPageInfo };

export default pageInfoReducer;
