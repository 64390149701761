import React from 'react';
import { useSelector } from 'react-redux';
import { TemplatePage, TemplateType } from '@sector-labs/templates-renderer';
import { selectNumberFormatterFactory } from 'strat/i18n/language';
import { selectTemplates } from 'strat/templates/selectors';
import { findTemplate, renderTemplate } from 'strat/templates';

const DEFAULT_CATEGORY_SLUG = 'cars';

const useFormattedPrice = (
    price: number,
    templateType: Values<typeof TemplateType> = TemplateType.PRICE_LABEL,
    categorySlug: string = DEFAULT_CATEGORY_SLUG,
    page: TemplatePage = TemplatePage.LITE_AD,
): string => {
    const templates = useSelector(selectTemplates);
    const numberFormatterFactory = useSelector(selectNumberFormatterFactory);

    return React.useMemo(() => {
        const template = findTemplate({
            type: templateType,
            page,
            purpose: null,
            categorySlug,
            locationSlugs: null,
            templates,
        });
        let formattedPrice = null;

        if (template) {
            formattedPrice = renderTemplate({
                template,
                replaceParameters: {
                    extraFields: {
                        price: numberFormatterFactory().format(price),
                        secondary_price: numberFormatterFactory().format(price),
                    },
                },
            });
        }

        return formattedPrice || numberFormatterFactory().format(price);
    }, [categorySlug, numberFormatterFactory, price, templates, page, templateType]);
};

export default useFormattedPrice;
