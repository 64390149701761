import isEqual from 'lodash/isEqual';
import { SearchService, SearchJob } from '@sector-labs/fe-search-redux';
import { AlgoliaSearchBackend } from '@sector-labs/fe-search-redux/backend/algolia';
import { FilterCollection, RefinementFilter } from '@sector-labs/fe-search-redux/filters';
import brandingSettings from '@app/branding/settings';

import Area from 'strat/i18n/area';
import Purpose from 'strat/purpose';
import { determineLocationsIndexName } from 'strat/search/indexNames';
import FilterValues from 'strat/search/filterValues';
import { mockedI18n } from 'strat/i18n/language';
import type { SavedSearchParams } from 'strat/search/savedSearches/types';

const attributeToNumber = (
    object:
        | {
              max?: number | null | undefined;
              min?: number | null | undefined;
          }
        | null
        | undefined,
    attribute: string,
    defaultValue: null | number,
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ max?: number | null | undefined; min?: number | null | undefined; }'.
) => (object ? parseFloat(object[attribute]) || defaultValue : defaultValue);
const toNumbers = (values: Array<string | number> | null | undefined) =>
    // @ts-expect-error - TS2345 - Argument of type 'string | number' is not assignable to parameter of type 'string'.
    (values || []).map((value) => parseInt(value, 10));

const joinStringList = (list?: Array<string> | null): string | null | undefined => {
    if (!list || !Array.isArray(list)) {
        return null;
    }

    const joinedStrings = list.join(',');
    if (joinedStrings.length === 0) {
        return null;
    }

    return joinedStrings;
};

type BayutParams = Partial<{
    purpose_id: number | null | undefined;
    price_min: number | null | undefined;
    price_max: number | null | undefined;
    area_min: number | null | undefined;
    area_max: number | null | undefined;
    beds_min: number | null | undefined;
    beds_max: number | null | undefined;
    baths_min: number | null | undefined;
    baths_max: number | null | undefined;
    city_id: string;
    property_type: string;
    occupancy: string;
    ownership: string;
    days_old: string;
    agent_id: string | null | undefined;
    owner_id: string | null | undefined;
    keyword: string | null | undefined;
    classification: string;
    completion_status: string | null | undefined;
    location_ids: string | Array<string>;
    rent_frequency: string;
    type_id: number | null | undefined;
    advanced_filter: string | null | undefined;
}>;
/**
 * Compatibility functions for converting to and from
 * area values from Bayut.
 */
const AreaCompat = Object.freeze({
    abbreviation: (area: string): string => {
        switch (area || brandingSettings.defaultAreaUnit) {
            case Area.SQFT:
                return 'Sq. Ft.';
            case Area.SQYD:
                return 'Sq. Yd.';
            case Area.SQM:
                return 'Sq. M.';
            case Area.MARLA:
                return 'Marla';
            case Area.KANAL:
                return 'Kanal';
            case Area.SQWA:
                return 'Sq. Wa';
            case Area.NGAN:
                return 'Ngan';
            case Area.RAI:
                return 'Rai';
            default:
                // Fall back to branding default
                return AreaCompat.abbreviation(brandingSettings.defaultAreaUnit);
        }
    },
    fromAbbreviation: (abbreviation: string): string => {
        switch (abbreviation) {
            case 'Sq. Ft.':
                return Area.SQFT;
            case 'Sq. Yd.':
                return Area.SQYD;
            case 'Sq. M.':
                return Area.SQM;
            case 'Marla':
                return Area.MARLA;
            case 'Kanal':
                return Area.KANAL;
            case 'Sq. Wa':
                return Area.SQWA;
            case 'Ngan':
                return Area.NGAN;
            case 'Rai':
                return Area.RAI;
            default:
                return brandingSettings.defaultAreaUnit; // Use branding default
        }
    },
    toLegionAbbreviation: (area: (typeof Area)[keyof typeof Area]): string => {
        switch (area || brandingSettings.defaultAreaUnit) {
            case Area.SQFT:
                return 'square_feet';
            case Area.SQYD:
                return 'square_yards';
            case Area.SQM:
                return 'square_meters';
            case Area.MARLA:
                return 'marla';
            case Area.KANAL:
                return 'kanal';
            default:
                // Fall back to branding default
                return AreaCompat.toLegionAbbreviation(brandingSettings.defaultAreaUnit);
        }
    },
    fromLegionAbbreviation: (abbreviation: string): string => {
        switch (abbreviation) {
            case 'square_feet':
                return Area.SQFT;

            case 'square_yards':
                return Area.SQYD;

            case 'square_meters':
                return Area.SQM;

            case 'marla':
                return Area.MARLA;

            case 'kanal':
                return Area.KANAL;

            default:
                return AreaCompat.fromAbbreviation(abbreviation);
        }
    },
    convertTo: (
        area?: {
            min?: number | null | undefined;
            max?: number | null | undefined;
        } | null,
    ):
        | {
              area_min: number | null | undefined;
              area_max: number | null | undefined;
          }
        | null
        | undefined => ({
        area_min: attributeToNumber(area, 'min', 0),
        area_max: attributeToNumber(area, 'max', null),
    }),
});

/**
 * Compatibility functions for converting to and from
 * purpose values from Bayut.
 */
const PurposeCompat = Object.freeze({
    fromID: (purposeID: number): Values<typeof Purpose> => {
        switch (purposeID) {
            case 1:
                return Purpose.FOR_SALE;
            case 2:
                return Purpose.FOR_RENT;
            default:
                // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string | ((purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any) => any) | ((i18n: any, purpose: any, uppercaseVerb?: boolean) => any) | (() => string[]) | ((purpose: any) => 1 | ... 1 more ... | 3) | ((purpose:...'.
                return null;
        }
    },
    toID: (purpose: Values<typeof Purpose>): number | null => {
        switch (purpose) {
            case Purpose.FOR_SALE:
                return 1;
            case Purpose.FOR_RENT:
                return 2;
            default:
                return null;
        }
    },
    textAsVerb: (purpose: string): string | null => {
        switch (purpose) {
            case Purpose.FOR_RENT:
                return 'Rent';

            case Purpose.FOR_SALE:
                return 'Buy';

            default:
                return null;
        }
    },
    text: (purpose: string): string => {
        switch (purpose) {
            case Purpose.FOR_RENT:
                return 'For Rent';

            case Purpose.FOR_SALE:
                return 'For Sale';

            default:
                return 'For Sale';
        }
    },
});

const CategoryCompat = Object.freeze({
    convertTo: (category?: string | null): number | null | undefined => {
        const categoryObject = FilterValues.category
            .choices(mockedI18n)
            .find((cat) => cat.slug === category);

        return categoryObject ? parseInt(categoryObject.externalID, 10) : null;
    },
});

const PriceCompat = Object.freeze({
    convertTo: (
        price?: {
            min?: number | null | undefined;
            max?: number | null | undefined;
        } | null,
    ):
        | {
              price_min: number | null | undefined;
              price_max: number | null | undefined;
          }
        | null
        | undefined => ({
        price_min: attributeToNumber(price, 'min', 0),
        price_max: attributeToNumber(price, 'max', null),
    }),
});

const BedsCompat = Object.freeze({
    convertTo: (
        beds?: Array<string | number> | null,
    ):
        | {
              beds_min: number | null | undefined;
              beds_max: number | null | undefined;
          }
        | null
        | undefined => {
        const bedsToNumbers = toNumbers(beds);
        return {
            beds_min: Math.min(...bedsToNumbers),
            beds_max: Math.max(...bedsToNumbers),
        };
    },
});

const BathsCompat = Object.freeze({
    convertTo: (
        baths?: Array<string | number> | null,
    ):
        | {
              baths_min: number | null | undefined;
              baths_max: number | null | undefined;
          }
        | null
        | undefined => {
        const bathsToNumbers = toNumbers(baths);
        return {
            baths_min: Math.min(...bathsToNumbers),
            baths_max: Math.max(...bathsToNumbers),
        };
    },
});

/**
 * Compatibility functions for converting to the Bayut's representation
 * of rentFrequency.
 */
const RentFrequencyCompat = Object.freeze({
    convertTo: (rentFrequency?: string | null): string =>
        // @ts-expect-error - TS2531 - Object is possibly 'null'.
        (
            FilterValues.rentFrequency.choice(mockedI18n, rentFrequency) ||
            FilterValues.rentFrequency.choice(mockedI18n, FilterValues.rentFrequency.default)
        ).name,
    convertFrom: (rentFrequency: string): string =>
        rentFrequency === '' ? FilterValues.rentFrequency.disablingValue : rentFrequency,
});

const CompletionStatusCompat = Object.freeze({
    convertTo: (
        completionStatus?: string | null,
        purpose?: string | null,
    ): string | null | undefined =>
        purpose === Purpose.FOR_SALE ? completionStatus : FilterValues.completionStatus.default,
});

const AgencyCompat = Object.freeze({
    convertTo: (agencies?: Array<string> | null): string | null | undefined =>
        joinStringList(agencies),
    convertFrom: (agentID?: string): Array<string> => (agentID ? agentID.split(',') : []),
});

const KeywordsCompat = Object.freeze({
    convertTo: (keywords?: Array<string> | null): string | null | undefined =>
        joinStringList(keywords),
    convertFrom: (keywords?: string): Array<string> => (keywords ? keywords.split(',') : []),
});

const AdvancedFilterCompat = Object.freeze({
    // @ts-expect-error - TS7006 - Parameter 'panoramaCount' implicitly has an 'any' type. | TS7006 - Parameter 'videoCount' implicitly has an 'any' type. | TS7006 - Parameter 'hasFloorPlan' implicitly has an 'any' type.
    convertTo: (panoramaCount, videoCount, hasFloorPlan): string | null | undefined => {
        if (hasFloorPlan) {
            return FilterValues.floorPlanID.displayName(mockedI18n);
        }
        if (panoramaCount?.min) {
            return FilterValues.panoramaCount.displayName(mockedI18n);
        }
        if (videoCount?.min) {
            return FilterValues.videoCount.displayName(mockedI18n);
        }
        return 'Any';
    },
});

/**
 * Compatibility functions for converting to and from
 * search parameters from Bayut.
 */
const SearchParamsCompat = Object.freeze({
    /**
     * Converts from Bayut's format for search parameters to a
     * internal {@see SearchParams}.
     */
    convertFrom: (params: any, locations?: Array<string>): SavedSearchParams => {
        const purpose = PurposeCompat.fromID(parseInt(params.purpose_id, 10));

        const category = FilterValues.category
            .choices(mockedI18n)
            .find((cat) => cat.externalID === params.property_type);

        // bayut returns the baths/beds filter as a range rather
        // than a list of choices.. we'll just grab all values inbetween
        const rangeToMultipleChoice = (choices: any, min: any, max: any) => {
            const startIndex = choices.findIndex(
                // @ts-expect-error - TS7006 - Parameter 'choice' implicitly has an 'any' type.
                (choice) =>
                    choice.name === min || choice.value === `${min}+` || `${choice.value}` === min,
            );

            const endIndex = choices.findIndex(
                // @ts-expect-error - TS7006 - Parameter 'choice' implicitly has an 'any' type.
                (choice) => choice.name === max || choice.value === `${max}+`,
            );

            // @ts-expect-error - TS7006 - Parameter 'choice' implicitly has an 'any' type.
            return choices.slice(startIndex, endIndex + 1).map((choice) => choice.value);
        };

        const baths = rangeToMultipleChoice(
            FilterValues.baths.choices(mockedI18n),
            params.baths_min,
            params.baths_max,
        );

        const beds = rangeToMultipleChoice(
            FilterValues.beds.choices(mockedI18n),
            params.beds_min,
            params.beds_max,
        );

        return {
            purpose,
            category: category ? category.slug : null,
            // @ts-expect-error - TS2322 - Type 'string[] | undefined' is not assignable to type '(SearchLocationNode[] & string[]) | undefined'.
            locations,
            baths: baths.length > 0 ? baths : null,
            beds: beds.length > 0 ? beds : null,
            price: {
                min: params.price_min,
                max: params.price_max > 0 ? params.price_max : null,
            },
            area: {
                min: params.area_min,
                max: params.area_max > 0 ? params.area_max : null,
            },
            agencies: AgencyCompat.convertFrom(params.agent_id),
            agents: AgencyCompat.convertFrom(params.owner_id),
            rentFrequency: RentFrequencyCompat.convertFrom(params.rent_frequency),
            completionStatus: params.completion_status,
            keywords: KeywordsCompat.convertFrom(params.keyword),
            panoramaCount:
                params.advanced_filter === FilterValues.panoramaCount.displayName(mockedI18n)
                    ? { min: 1, max: null }
                    : null,
            videoCount:
                params.advanced_filter === FilterValues.videoCount.displayName(mockedI18n)
                    ? { min: 1, max: null }
                    : null,
            hasFloorPlan:
                params.advanced_filter === FilterValues.floorPlanID.displayName(mockedI18n),
        };
    },

    canonicalForm: (
        params: SavedSearchParams,
        locationIds: string | Array<string>,
    ): BayutParams => {
        let canonicalForm: BayutParams = {
            // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string | ((purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any, displayOption?: PurposeTextDisplay) => any) | ((i18n: any, purpose: any) => any) | ((i18n: any, purpose: any, uppercaseVerb?: boolean) => any) | (() => string[]) | ((purpose: any) => 1 | ... 1 more ... | 3) | ((purpose:...'.
            purpose_id: PurposeCompat.toID(params.purpose),
            type_id: CategoryCompat.convertTo(params.category),
            agent_id: AgencyCompat.convertTo(params.agencies),
            location_ids: locationIds,
            rent_frequency: RentFrequencyCompat.convertTo(params.rentFrequency).toLowerCase(),
            completion_status: CompletionStatusCompat.convertTo(
                params.completionStatus,
                params.purpose,
            ),
            keyword: KeywordsCompat.convertTo(params.keywords),
            advanced_filter: AdvancedFilterCompat.convertTo(
                params.panoramaCount,
                params.videoCount,
                params.hasFloorPlan,
            ),
        };

        const ownerID = AgencyCompat.convertTo(params.agents);
        const area = AreaCompat.convertTo(params.area);
        const price = PriceCompat.convertTo(params.price);
        const beds = BedsCompat.convertTo(params.beds);
        const baths = BathsCompat.convertTo(params.baths);

        if (ownerID) {
            canonicalForm = {
                ...canonicalForm,
                owner_id: ownerID,
            };
        }

        if (area) {
            canonicalForm = {
                ...canonicalForm,
                ...area,
            };
        }

        if (price) {
            canonicalForm = {
                ...canonicalForm,
                ...price,
            };
        }

        if (beds) {
            canonicalForm = {
                ...canonicalForm,
                ...beds,
            };
        }

        if (baths) {
            canonicalForm = {
                ...canonicalForm,
                ...baths,
            };
        }

        return canonicalForm;
    },

    /**
     * Converts from a internal {@see SearchParams} object to Bayut's
     * format for search parameters.
     */
    convertTo: (params: SavedSearchParams): Promise<SavedSearchParams> => {
        // dragons be here!! bayut expects the list of locations
        // to be ID's... since we want to keep our public API
        // clean, we get slugs... so we have to fetch the ID's
        // from Algolia... not the greatest... sorrrrry :/

        let locationSlugs: Array<never> = [];
        if (Array.isArray(params.locations) && params.locations.length) {
            // @ts-expect-error - TS2322 - Type 'SearchLocationNode[] & string[]' is not assignable to type 'never[]'.
            locationSlugs = params.locations;
        } else if (params.city) {
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
            locationSlugs = [params.city];
        }

        const filters = new FilterCollection();
        filters.refine(
            new RefinementFilter({
                attribute: 'slug',
                value: locationSlugs,
            }),
        );

        const algoliaBackend = new AlgoliaSearchBackend({
            appId: CONFIG.build.ALGOLIA_APP_ID,
            apiKey: CONFIG.build.ALGOLIA_SEARCH_API_KEY,
        });

        const searchService = new SearchService({ backend: algoliaBackend });

        const algoliaResponse = searchService.fetchJob(
            new SearchJob(
                determineLocationsIndexName({ language: CONFIG.build.LANGUAGE_CODE }),
                filters,
                {
                    // @ts-expect-error - TS2322 - Type '"externalID"' is not assignable to type 'string[] | "*" | ["*"] | undefined'.
                    attributesToRetrieve: 'externalID',
                    hitsPerPage: locationSlugs.length,
                },
            ),
        );

        // @ts-expect-error - TS2322 - Type 'Promise<Partial<Partial<Partial<{ purpose: string; locations: SearchLocationNode[]; locationsByLanguage: { [key: string]: SearchLocationNode[]; }; locationPrefix: string | ... 1 more ... | undefined; ... 56 more ...; showListingScore: boolean | ... 1 more ... | undefined; }> & { ...; }> & { ...; }> | Partial<...>>' is not assignable to type 'Promise<Partial<Partial<Partial<{ purpose: string; locations: SearchLocationNode[]; locationsByLanguage: { [key: string]: SearchLocationNode[]; }; locationPrefix: string | ... 1 more ... | undefined; ... 56 more ...; showListingScore: boolean | ... 1 more ... | undefined; }> & { ...; }> & { ...; }>>'.
        return algoliaResponse.then((response) => {
            const locationIds = response.hits.map((hit) => hit.externalID).join(',');

            return SearchParamsCompat.canonicalForm(params, locationIds);
        });
    },

    compare: (params1: SavedSearchParams, params2: SavedSearchParams) => {
        const locationSlugs = (params: SavedSearchParams) => {
            let slugs: Array<never> = [];
            if (Array.isArray(params.locations) && params.locations.length) {
                // @ts-expect-error - TS2322 - Type 'SearchLocationNode[] & string[]' is not assignable to type 'never[]'.
                slugs = params.locations;
            } else if (params.city) {
                // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'never'.
                slugs = [params.city];
            }

            return [...new Set(slugs)].sort();
        };

        return isEqual(
            SearchParamsCompat.canonicalForm(params1, locationSlugs(params1)),
            SearchParamsCompat.canonicalForm(params2, locationSlugs(params2)),
        );
    },
});

/**
 * Compatibility functions for converting to the Bayut's representation
 * of bedrooms count.
 */
const BedroomsCompat = Object.freeze({
    convertTo: (bedrooms: number): number => (bedrooms === 0 ? -1 : bedrooms),
});

/**
 * Compatibility functions for website section values.
 * e.g Agents, Developments, Property
 */
const WebsiteSectionCompat = Object.freeze({
    AGENCY: 'Agents',
});

/**
 * Compatibility functions for listing page type values.
 */
const ListingPagetypeCompat = Object.freeze({
    HOME: 'home',
    SEARCH: 'searchresults',
    DETAIL: 'offerdetail',
    AGENT: 'agent',
    AGENCY_DETAIL: 'agencydetailpage',
    AGENCY_SEARCH: 'searchagencies',
    AGENT_SEARCH: 'searchagents',
    REMARKETING: 'alt_offerdetail',
    LEGACY_REMARKETING: 'alt_propertydetail',
    TRUVALUE_LANDING: 'truvalue_landing',
    TRUVALUE_PAGE: 'tru-value_page',
    TRUESTIMATE_PAGE: 'tru-estimate_page',
    REMARKETING_SEARCH: 'rempage_searchresults',
    REMARKETING_DETAIL: 'rempage_offerdetail',
    FAVORITE_LIST: 'favorite_list',
    FAVORITES: 'favorites',
});

/**
 * Compatibility functions for converting to the popularity ES index year quarter format.
 */
const PopularityESIndexYearQuarterCompat = Object.freeze({
    convertTo: (year: number, quarter: number): string =>
        `${year}-${quarter === 4 ? '10' : `0${(quarter - 1) * 3 + 1}`}`,
});

/**
 * Functions for transformations from/to Bayut's formats.
 */
export default Object.freeze({
    Area: AreaCompat,
    Purpose: PurposeCompat,
    SearchParams: SearchParamsCompat,
    Bedrooms: BedroomsCompat,
    RentFrequency: RentFrequencyCompat,
    WebsiteSection: WebsiteSectionCompat,
    ListingPagetype: ListingPagetypeCompat,
    PopularityESIndexYearQuarter: PopularityESIndexYearQuarterCompat,
});
