import * as React from 'react';
import { Formik } from 'formik';
import { Dialog } from 'strat/components';
import { useSelector } from 'react-redux';

import { selectDialogStack } from 'horizontal/dialogs/selectors';

import styles from './styles/reportDialog.cssm';
import type { BaseReportFormProps } from './types';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly onSubmit: (arg1: any) => void;
    readonly validationSchema: any;
    readonly renderForm: (arg1: BaseReportFormProps) => React.ReactElement;
};

const initialValues = { reason: '', comment: '' } as const;

const ReportDialog = ({
    visible,
    onVisibilityChanged,
    onSubmit,
    validationSchema,
    renderForm,
    ...rest
}: Props) => {
    const { stacked, stackGroup } = useSelector(selectDialogStack);

    if (!visible) {
        return null;
    }

    return (
        <Dialog
            dismissible
            visible={visible}
            onVisibilityChanged={onVisibilityChanged}
            withCloseButton
            stacked={stacked}
            stackGroup={stackGroup}
            {...rest}
        >
            <div className={styles.content}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {renderForm}
                </Formik>
            </div>
        </Dialog>
    );
};

export default ReportDialog;
