import { createSelector } from 'reselect';

import type { GlobalState } from 'horizontal/state';

const selectMyProfile = createSelector(
    (state: GlobalState) => state.myProfile,
    (profile) => profile.data || null,
);

const selectIsMyProfileLoaded = createSelector(
    (state: GlobalState) => state.myProfile,
    (profile) => profile.loaded || false,
);

const selectIsMyProfileLoading = createSelector(
    (state: GlobalState) => state.myProfile,
    (profile) => profile.loading || false,
);

export { selectMyProfile, selectIsMyProfileLoaded, selectIsMyProfileLoading };
