import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { GlobalState } from 'strat/state';

import isLanguageRTL from './rtl';
import { localesFromLanguage } from './locales';
import { selectNumberFormatterFactory } from './selectors';

export type NumberFormatterFactory = (settings: Record<any, any>) => {
    format: (value: number) => string;
};

const getLocales = (state: any) => localesFromLanguage(state.i18n.language);

const selectFormatNumber = createSelector(
    selectNumberFormatterFactory,
    (factory) => factory().format,
);

/**
 * Injects the current language into the wrapped component.
 */
const connectLanguage = connect((state: GlobalState) => ({
    language: state.i18n.language,
    rtl: isLanguageRTL(state.i18n.language),
    locales: getLocales(state),
    formatNumber: selectFormatNumber(state),
    intlNumberFormat: selectNumberFormatterFactory(state),
}));

export default connectLanguage;
