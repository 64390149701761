import React from 'react';

import { MilestonesProjectPaymentPlan } from 'strat/project/types';
import { Flex } from 'strat/components';

import { CommonHeader, MilestoneAxisSegment } from './paymentPlanTable';
import useMilestonesPaymentTimeline, {
    MilestonesPaymentPlanMilestone,
} from './useMilestonesPaymentTimeline';
import PaymentInstallmentsTooltip from './paymentInstallmentsTooltip';
import styles from './styles/paymentPlanTable.cssm';
import MilestoneInfo from './milestoneInfo';

const Header = () => (
    <tr className={styles.twoColumns}>
        <CommonHeader />
    </tr>
);

type RowProps = {
    milestone: MilestonesPaymentPlanMilestone;
};

const Row = ({ milestone }: RowProps) => (
    <tr>
        <MilestoneAxisSegment />
        <td>
            <Flex alignCenter className={styles.entryContainer}>
                {milestone.title}
                <MilestoneInfo
                    milestone={milestone}
                    renderInfo={(payment: MilestonesPaymentPlanMilestone) => (
                        <PaymentInstallmentsTooltip installments={payment.installments} />
                    )}
                />
            </Flex>
        </td>
        <td className={styles.boldText}>{milestone.valuePercentage}%</td>
    </tr>
);

type Props = {
    paymentPlan: MilestonesProjectPaymentPlan;
};

const MilestonesPaymentPlan = ({ paymentPlan }: Props) => {
    const timeline = useMilestonesPaymentTimeline(paymentPlan);

    return (
        <>
            <Header />
            {timeline.map((milestone, index) => (
                <Row key={index} milestone={milestone} />
            ))}
        </>
    );
};

export default MilestonesPaymentPlan;
