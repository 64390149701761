import FetcherFactory from 'strat/fetcher';

import { StratAPI } from 'horizontal/api';

const factory = new FetcherFactory(
    ['areebaSession'],
    ({ externalID, currency }) => new StratAPI().areebaSession(externalID, currency),
    {
        catchServerErrors: true,
        skipParamsCheck: true,
    },
);

const fetchAreebaSession = factory.creator();

const areebaSessionReducer = factory.reducer();

export { fetchAreebaSession };

export default areebaSessionReducer;
