import * as React from 'react';
import { ThumbnailImage } from 'strat/image';
import type { CategoryFieldChoice, FlatCategoryField } from 'strat/types';

import type { Category } from 'horizontal/types';

import useCategoryFieldChoiceIconURL from './useCategoryFieldChoiceIconURL';
import styles from './styles/categoryFieldChoiceIcon.cssm';

type Props = {
    readonly choice: CategoryFieldChoice;
    readonly field: FlatCategoryField['attribute'];
    readonly className?: string;
    readonly category?: Category | null;
};

const CategoryFieldChoiceIcon = ({ choice, field, className, category }: Props) => {
    const thumbnailURL = useCategoryFieldChoiceIconURL(field, category, choice);

    if (!thumbnailURL) {
        return null;
    }

    return (
        <ThumbnailImage
            imageID={choice.slug as string}
            alt={choice.label}
            title={choice.label}
            label={field}
            thumbnailURL={() => thumbnailURL}
            pictureClassName={className || styles.pictureClassName}
            className={className}
            lazyLoad
        />
    );
};

export default CategoryFieldChoiceIcon;
