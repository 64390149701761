import { Route } from 'react-true-router';
import type { EnhancedLocation } from 'react-true-router/location';
import type { RouteParameters } from 'react-true-router/route';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { RouteNames } from 'strat/routes';

import Page from 'horizontal/pages/page';

class InternalErrorRoute extends Route {
    constructor() {
        super(RouteNames.INTERNAL_ERROR, [['^/error.html', '(?:\\?)?']]);
    }

    createURL(_params: RouteParameters): EnhancedLocation {
        return { pathname: '/error.html' };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        context.http.status(500);
        context.rendering.renderPage(Page.INTERNAL_ERROR);
    }
}

export default new InternalErrorRoute();
