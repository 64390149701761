import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { FormikErrors, FormikValues, FormikTouched } from 'formik';
import useI18n from 'strat/i18n/language/useI18n';
import SellerFields from '@app/adUpdating/sellerFields';
import { useIsAdDeliverable } from '@app/userOrders/hooks';
import { Button, LoadingSpinnerOverlay, LoadingSpinner, Flex } from 'strat/components';

import { selectCategoryByExternalID } from 'horizontal/categories/selectors';
import type {
    AdPhoto,
    LiteCategory,
    LiteHierarchicalLocation,
    CategoryField,
    FlatCategoryField,
} from 'horizontal/types';
import { CategoryRole } from 'horizontal/types';
import { flattenCategoryFields, useCategoryFieldsAreLoading } from 'horizontal/categoryFields';
import {
    AdDeliveryFields,
    AgentCodeField,
    ContactInfoFields,
    MileageField,
} from 'horizontal/fields/commonPostingFields';
import AdCreationContext from 'horizontal/adCreation/context';
import {
    CommonPostingFields,
    useTouchedFields,
    CommonFields,
    Field,
    PriceRelatedFieldsSection,
    DeliverySection,
    ImageInput,
} from 'horizontal/fields';
import { MultipleDeliveriesSection } from 'horizontal/fields';
import { selectCategories } from 'horizontal/categories';
import { GlobalState } from 'horizontal/state';
import {
    useCarsFormWarnings,
    getInputFieldTitle,
    getInputFieldPlaceholder,
} from 'horizontal/adCreation';
import { useCarsSideEffects } from 'horizontal/adCreation/useCarsSideEffects';

import useGroupedRenderedFields from '../fields/useGroupedRenderedFields';
import { useEnforceCategoryFieldCombination } from '../categoryFieldCombinations';

import useIsImpersonated from './hooks/useIsImpersonated';
import styles from './styles/form.cssm';

const pickCategoryByExternalID = (category: any) => (testCategory: any) => {
    if (testCategory.children && testCategory.children.length > 0) {
        return testCategory.children.find(pickCategoryByExternalID(category));
    }
    return testCategory.externalID === category.externalID;
};

type Props = {
    readonly categoryFields: Array<CategoryField>;
    readonly initialLocation: Array<LiteHierarchicalLocation>;
    readonly initialPhotos: Array<AdPhoto>;
    readonly values: FormikValues;
    readonly errors: FormikErrors<FormikValues>;
    readonly touched: FormikTouched<FormikValues>;
    readonly handleBlur: (arg1: React.SyntheticEvent<any>) => void;
    readonly setFieldValue: (field: keyof FormikValues, value?: any) => void;
    readonly isSubmitting: boolean;
    readonly handleSubmit: (arg1?: any) => void;
    readonly liteCategory: LiteCategory;
};

const Form = ({
    categoryFields,
    initialLocation,
    initialPhotos,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    liteCategory,
}: Props) => {
    const i18n = useI18n();

    const loading = useCategoryFieldsAreLoading();
    const category = useSelector((state: GlobalState) =>
        selectCategoryByExternalID(state, liteCategory.externalID),
    );
    const isImpersonated = useIsImpersonated();
    const readOnlyLocation =
        !isImpersonated && category?.roles?.includes(CategoryRole.NON_EDITABLE_LOCATION);
    const [activeCategory, setActiveCategory] = React.useContext(AdCreationContext);
    const location = initialLocation.length ? initialLocation[initialLocation.length - 1] : null;
    const categories = useSelector(selectCategories);

    React.useEffect(() => {
        if (category && categories.length) {
            const activeCat = categories.find(pickCategoryByExternalID(category));
            setActiveCategory(activeCat);
        }
        return () => setActiveCategory(null);
    }, [category, setActiveCategory, categories]);

    const isDeliverable = useIsAdDeliverable(category);

    const allFields = React.useMemo(() => {
        return [
            ...categoryFields,
            ...Object.values(CommonPostingFields),
            ...Object.values(ContactInfoFields),
            ...Object.values(isDeliverable ? AdDeliveryFields : {}),
        ];
    }, [categoryFields, isDeliverable]);

    const touchedFields = useTouchedFields(allFields, touched, isSubmitting);

    const flatCategoryFields = flattenCategoryFields(categoryFields, values);
    const choiceReducedFields = useEnforceCategoryFieldCombination(
        liteCategory?.id,
        categoryFields,
        values,
    );
    const [loadingRenderedFields, renderedFields] = useGroupedRenderedFields(
        choiceReducedFields,
        values,
        setFieldValue,
    );

    const [mileageFieldHidden] = useCarsSideEffects({
        categoryFields,
        flatCategoryFields: renderedFields,
        values,
        setFieldValue,
        adCategory: category,
        isEditMode: true,
    });

    const renderedFilteredFields = renderedFields.filter(
        (field) =>
            (field.attribute !== MileageField.attribute || !mileageFieldHidden) &&
            field.attribute !== AgentCodeField.attribute,
    );

    const [imagesLoading, setImagesLoading] = React.useState(false);

    const warnings = useCarsFormWarnings(values, errors, category, categoryFields);

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.firstSection}>
                <ImageInput
                    field={CommonPostingFields.photos}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    setLoading={setImagesLoading}
                    initialValue={initialPhotos}
                />
                {renderedFilteredFields.length ? <div className={styles.separator} /> : null}
                <LoadingSpinnerOverlay visible={loading || loadingRenderedFields} />
                {!loading && (
                    /* This div prevents the UI from mixing up, removing it will causes divs to get
                    reshuffled if the render was server render */
                    <div className={styles.dynamicFieldsContainer}>
                        {renderedFilteredFields.map((field: FlatCategoryField) => (
                            <Field
                                key={`${field.id}-${field.combinationChoices?.length}`}
                                field={field}
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touchedFields}
                                onBlur={handleBlur}
                                isEditMode
                                warnings={warnings}
                                title={getInputFieldTitle(field, category)}
                                placeholder={getInputFieldPlaceholder(i18n, field, category)}
                            />
                        ))}
                    </div>
                )}
                <div className={styles.separator} />
                <CommonFields
                    activeCategory={activeCategory}
                    onBlur={handleBlur}
                    touched={touchedFields}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    readonlyLocation={readOnlyLocation}
                    initialLocation={location}
                />
            </div>
            <PriceRelatedFieldsSection
                categoryFields={flatCategoryFields}
                values={values}
                errors={errors}
                touched={touchedFields}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
            />

            {isDeliverable &&
                (CONFIG.build.ENABLE_SELF_DELIVERY ? (
                    <div className={styles.section}>
                        <MultipleDeliveriesSection
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touchedFields}
                            values={values}
                            onBlur={handleBlur}
                            isEdit
                        />
                    </div>
                ) : (
                    <div className={styles.section}>
                        <DeliverySection
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touchedFields}
                            values={values}
                            onBlur={handleBlur}
                            isEdit
                        />
                    </div>
                ))}
            <div className={styles.section}>
                <SellerFields
                    handleBlur={handleBlur}
                    touched={touchedFields}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                />
                <div className={styles.separator} />
                <Flex justifyEnd stretchWidth className={styles.buttonWrapper}>
                    {/* @ts-expect-error - TS2322 - Type '{ children: (string | false | Element)[]; disabled: boolean; stretch: true; primary: true; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
                    <Button disabled={imagesLoading || isSubmitting} primary>
                        {!isSubmitting && t(i18n)`Continue`}
                        {isSubmitting && <LoadingSpinner />}
                    </Button>
                </Flex>
            </div>
        </form>
    );
};

export default Form;
