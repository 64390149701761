import { SearchJob, SearchService } from '@sector-labs/fe-search-redux';
import { SearchBackend } from '@sector-labs/fe-search-redux/backend';
import { FilterCollection } from '@sector-labs/fe-search-redux/filters';

import { logError } from 'strat/error/log';

const fetchListingsFromFilters = (
    configuredBackend: SearchBackend,
    filters: FilterCollection,
    indexName: string,
    hitsPerPage: number,
) => {
    const job = new SearchJob(indexName, filters, {
        facets: [],
        hitsPerPage,
    });

    const service = new SearchService({ backend: configuredBackend });

    return service.fetchJob(job).catch((e) => {
        logError({
            e,
            msg: 'Failed to fetch listings from search',
            context: {
                name: indexName,
            },
        });

        if (CONFIG.build.DEBUG) {
            throw e;
        }
    });
};

export default fetchListingsFromFilters;
