import { t } from '@lingui/macro';
import settings from '@app/branding/settings';

import { TemplateType } from 'strat/templates';
import { useI18n } from 'strat/i18n/language';

const convertToRuleBasedTemplate = (template: string): string =>
    template.replace(/\[/g, '{{').replace(/\]/g, '}}');

const useProjectSearchTemplateOverride = (type: Values<typeof TemplateType>): string | null => {
    const i18n = useI18n();
    const brandName = settings.getBrandName(i18n);

    // We use `[location]` as placeholders because Lingui does
    // not like using `{{location}}` placeholders.
    //
    // These are rule-based templates that should eventually
    // be moved into the back-end when we add support for
    // different templates based on ad type.
    switch (type) {
        case TemplateType.H1:
            return convertToRuleBasedTemplate(t(i18n)`Off-Plan projects in [location]`);

        case TemplateType.TITLE:
            return convertToRuleBasedTemplate(
                t(
                    i18n,
                )`Off-Plan projects in [location] Page-[pageNumber|"Page-1 ":""] | ${brandName}`,
            );

        case TemplateType.META:
            return convertToRuleBasedTemplate(
                t(
                    i18n,
                )`View off plan projects in [location] with details including payment plan, handover date, completion status, available units & amenities. Page-[pageNumber|" Page-1":""]`,
            );

        default:
            return null;
    }
};

export default useProjectSearchTemplateOverride;
