import { t } from '@lingui/macro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import settings from '@app/branding/settings';
import { useI18n } from 'strat/i18n/language';
import { Flex, Input } from 'strat/components';

import IconSearch from 'horizontal/assets/icons/iconSearch.svg';
import { selectUserAgencies } from 'horizontal/agent/state';

import useAdManagementFilters from './useAdManagementFilters';
import useSetAdManagementFilters from './useSetAdManagementFilters';
import styles from './styles/queryFilter.cssm';

const renderIconSearch = () => (
    <Flex alignCenter justifyCenter className={styles.iconSearch}>
        <IconSearch />
    </Flex>
);

const QueryFilter = () => {
    const filters = useAdManagementFilters();
    const setAdManagementFilters = useSetAdManagementFilters();
    const userAgencies = useSelector(selectUserAgencies);
    const i18n = useI18n();
    // We use local state because performing route change while editing inputs can lead to poor UX.
    const [query, setQuery] = React.useState(filters.query || '');
    const [agentCode, setAgentCode] = React.useState(filters.agentCode || '');

    const filterByAdId = !settings.disableFilterByAdId;

    const titlePlaceholder = filterByAdId
        ? t(i18n)`Search by Ad Title or ID`
        : t(i18n)`Search by Ad Title`;
    const onSubmitQuery = () => setAdManagementFilters({ query, stateFilter: null });

    const onSubmitCode = () => setAdManagementFilters({ agentCode, stateFilter: null });

    return (
        <Flex>
            <div className={styles.filterText}>
                <Input
                    placeholder={titlePlaceholder}
                    onChange={setQuery}
                    onBlur={onSubmitQuery}
                    onSubmit={onSubmitQuery}
                    value={query}
                    renderLeft={renderIconSearch}
                />
            </div>
            {userAgencies?.length > 0 && (
                <div className={styles.filterText}>
                    <Input
                        placeholder={t(i18n)`Search by Agent or Ref.`}
                        onChange={setAgentCode}
                        onBlur={onSubmitCode}
                        onSubmit={onSubmitCode}
                        value={agentCode}
                        renderLeft={renderIconSearch}
                    />
                </div>
            )}
        </Flex>
    );
};

export default QueryFilter;
