/* eslint-disable no-underscore-dangle, no-undef */
const getRuntimeSettings = () => {
    if (process.env.IS_SERVER) {
        // __getStratRuntimeSettings and __getProjectRuntimeSettings
        // are injected by DefinePlugin at build time
        // Because they are lambda functions they are evaluated when the server is running the server bundle
        return {
            ...__getStratRuntimeSettings(),
            ...__getProjectRuntimeSettings(),
        };
    }
    if (process.env.IS_BROWSER) {
        // @ts-expect-error - TS2339 - Property 'CONFIG' does not exist on type 'Window & typeof globalThis'.
        return window.CONFIG.runtime;
    }
    return undefined;
};

const runtimeSettings = getRuntimeSettings();

export default runtimeSettings;
