import * as React from 'react';
import { useSelector } from 'react-redux';
import { TemplateType, TemplatePage, findAllTemplates, renderAllTemplates } from 'strat/templates';
import { selectTemplates } from 'strat/templates/selectors';
import { selectCurrency } from 'strat/i18n/money';

import type { MixedAd } from 'horizontal/types';

import useAdFormattedExtraFields from './useAdFormattedExtraFields';

const useLiteAdTemplate = (
    type: Values<typeof TemplateType>,
    ad: MixedAd,
): string | null | undefined => {
    const formattedExtraFields = useAdFormattedExtraFields(ad);
    const templates = useSelector(selectTemplates);
    const { currency } = useSelector(selectCurrency);

    return React.useMemo(() => {
        const categoryExternalID = ad.category.slice(-1)[0]?.externalID;
        if (!categoryExternalID) {
            return null;
        }

        const foundTemplates = findAllTemplates({
            type,
            page: TemplatePage.LITE_AD,
            purpose: ad.purpose,
            categoryExternalID,
            locationSlugs: ad.location.map(({ slug }) => slug),
            templates,
        });

        if (!foundTemplates.length) {
            return null;
        }

        const renderedTemplate = renderAllTemplates({
            templates: foundTemplates,
            replaceParameters: {
                currency,
                extraFields: formattedExtraFields.reduce<Record<string, any>>(
                    (acc, field) => ({
                        ...acc,
                        [field.attribute]: field.formattedValue,
                    }),
                    {},
                ),
            },
        });

        return renderedTemplate || null;
    }, [type, ad.category, ad.location, ad.purpose, formattedExtraFields, templates, currency]);
};

export default useLiteAdTemplate;
