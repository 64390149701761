import * as React from 'react';

import iconLocation from 'horizontal/assets/icons/iconLocation_noinline.svg';

type Props = {
    readonly className?: string;
};

const LocationIcon = ({ className }: Props) => (
    <svg className={className} aria-label={'Location Icon'}>
        <use xlinkHref={`${iconLocation}#svg`} />
    </svg>
);

export default LocationIcon;
