import React from 'react';
import { OffScreen, Dialog } from 'strat/modal';
import { Flex, Text } from 'strat/components';

import styles from './styles/userImageFailDialog.cssm';

type Props = {
    readonly visible: boolean;
    readonly onVisibilityChanged: (arg1: boolean) => void;
    readonly message: string;
};

const UserImageFailDialog = ({ visible, onVisibilityChanged, message }: Props) => {
    if (!visible || !message) {
        return null;
    }

    return (
        <OffScreen name="user-image-fail-dialog">
            <Dialog
                dismissible
                visible={visible}
                onVisibilityChanged={onVisibilityChanged}
                zIndex={110}
            >
                <Flex alignCenter column justifyCenter className={styles.container}>
                    <Flex className={styles.textDiv}>
                        <Text.XLarge bold>{message}</Text.XLarge>
                    </Flex>
                </Flex>
            </Dialog>
        </OffScreen>
    );
};

export default UserImageFailDialog;
