import * as React from 'react';
import classNames from 'classnames';

import styles from './styles/toggle.cssm';

type Props = {
    readonly checked?: boolean;
    readonly disabled?: boolean;
    onClick: () => void;
    readonly name?: string;
    readonly className?: string;
};

const ToggleButton = ({ checked, onClick, name, className, disabled }: Props) => {
    return (
        <div
            className={classNames(styles.toggleContainer, className, {
                [styles.checked]: checked,
                [styles.disabled]: disabled,
            })}
            onClick={onClick}
        >
            <input
                type="checkbox"
                readOnly
                className={styles.toggleInput}
                checked={checked}
                name={name}
            />
            <div className={styles.slider}>
                <div
                    className={classNames(styles.innerSlider, {
                        [styles.innerSliderChecked]: checked,
                        [styles.innerSliderDisabled]: disabled,
                    })}
                />
            </div>
        </div>
    );
};

export default ToggleButton;
