import * as React from 'react';
import { Flex, Text } from 'strat/components';

import CoverPhoto from 'horizontal/adCard/coverPhoto';
import type { FullAd } from 'horizontal/types';
import { useAdPriceLabel } from 'horizontal/ad/price';
import useAdPhotos from 'horizontal/ad/useAdPhotos';

import styles from './styles/adContent.cssm';

type Props = {
    ad: FullAd;
};

const AdContent = (props: Props) => {
    const { ad } = props;
    const priceLabel = useAdPriceLabel(ad);
    const [coverPhoto] = useAdPhotos(ad);

    return (
        <Flex>
            <Flex column>
                <div className={styles.coverImage}>
                    <CoverPhoto cover={coverPhoto} category={ad.category} />
                </div>
            </Flex>
            <Flex column wrap className={styles.adDetails}>
                <Text.Regular>{priceLabel}</Text.Regular>
                <Text.Base className={styles.title}>{ad.title}</Text.Base>
            </Flex>
        </Flex>
    );
};

export default AdContent;
