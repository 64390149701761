import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getFilterCollection } from '@sector-labs/fe-search-redux/state';

import type { GlobalState, AppDispatch } from 'strat/state';
import { RecommenderBackEndAPI } from 'strat/api';
import {
    selectVisitorID,
    selectDeviceID,
    selectUserEmail,
    selectUserHash,
} from 'strat/user/selectors';
import { trackEvent } from 'strat/user/state/creators';
import {
    selectPropertyInteractionParams,
    getRelevantPropertyAttributes,
} from 'strat/property/selectors';
import { selectSearchInteractionParams } from 'strat/search/selectors';
import type { PropertyData } from 'strat/property';
import { FilterValues } from 'strat/search';

import { EventType } from './types';

/**
 * Whether to send the search event to recommender ot not.
 */
const shouldSendSearchToRecommender = (state: GlobalState) =>
    getFilterCollection(state).getFilterValue<number>(FilterValues.page.attribute, 1) === 1;

const trackSearch = (state: GlobalState, dispatch: AppDispatch) => {
    if (CONFIG.build.ENABLE_RECOMMENDER && shouldSendSearchToRecommender(state)) {
        new RecommenderBackEndAPI().trackSearch({
            ...selectSearchInteractionParams(state),
            userEmail: selectUserEmail(state),
            clientID: selectVisitorID(state),
            sessionID: selectDeviceID(state),
            userHash: selectUserHash(state),
        });
    }

    dispatch(trackEvent(EventType.SEARCH_PAGE_VIEW, Date.now()));
};

const trackPropertyView = (state: GlobalState, dispatch: AppDispatch) => {
    if (CONFIG.build.ENABLE_RECOMMENDER) {
        new RecommenderBackEndAPI().trackPropertyInteraction(
            selectPropertyInteractionParams(state),
        );
    }

    dispatch(trackEvent(EventType.PROPERTY_PAGE_VIEW, Date.now()));
};

export type UserTrackingProps = {
    trackLeadPropertyInteraction: (property: PropertyData) => void;
};

const selectLeadPropertyInteraction = createSelector(
    selectVisitorID,
    selectUserEmail,
    selectDeviceID,
    (clientID, userEmail, sessionID) => (property: PropertyData) =>
        CONFIG.build.ENABLE_RECOMMENDER
            ? new RecommenderBackEndAPI().trackPropertyInteraction({
                  ...getRelevantPropertyAttributes(property),
                  clientID,
                  userEmail,
                  sessionID,
                  isLead: true,
              })
            : {},
);

const withUserTracking = connect((state: GlobalState) => ({
    trackLeadPropertyInteraction: selectLeadPropertyInteraction(state),
}));

export { trackSearch, trackPropertyView, withUserTracking };
