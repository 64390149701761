import { AdCommonExtraField } from '@app/branding/adExtraFields';

const AdCardSubtitleCategories = {
    VEHICLES: {
        fields: {
            mileage: AdCommonExtraField.VEHICLE_MILEAGE,
            year: AdCommonExtraField.YEAR,
        },
    },
    PROPERTIES: {
        fields: {
            bathrooms: AdCommonExtraField.BATHROOMS,
            bedrooms: AdCommonExtraField.BEDROOMS,
            area: AdCommonExtraField.AREA,
            area_unit: AdCommonExtraField.AREA_UNIT,
        },
    },
} as const;

export default AdCardSubtitleCategories;
