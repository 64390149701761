import type { CategoryNodeData } from 'strat/property';

const getCategoryVariables = (category: Array<CategoryNodeData>) => {
    const variables: Record<string, any> = {};
    for (let index = 1; index <= 3; index++) {
        const cat = category[index - 1] || {};
        variables[`category_${index}_id`] = cat.externalID ? parseInt(cat.externalID, 10) : '';
        variables[`category_${index}_name`] = cat.slug || (cat.nameSingular || '').toLowerCase();
    }

    return variables;
};

export default getCategoryVariables;
