/**
 * Transforms an array of type T to a Record<Key,T>
 *
 * @template T
 * @param {T[]} array - The array to be transformed
 * @param {function(): string} keyGenerator - A function which returns a string, will map the element of the array to the key returned by the function.
 * @returns {{Object.<string, T>}} The record the array was transformed into.
 */

export const arrayToObject = <T>(
    array: Array<T>,
    keyGenerator: (element: T) => string,
): Record<string, T> => {
    const obj: Record<string, T> = {};

    for (const element of array) {
        const key = keyGenerator(element);

        if (typeof key === 'string') {
            obj[key] = element;
        }
    }

    return obj;
};
