import * as React from 'react';
import isNil from 'lodash/isNil';
import { prefixTargetingParams } from 'strat/tagmanager';

import { useCurrentSearchRouteParams } from 'horizontal/search/routing';

import useLocationParams from './useLocationParams';
import { Position } from './types';
import useCommonTargetingParams from './useCommonTargetingParams';

const useHomeTargetingParams = (position: Values<typeof Position>, slotIndex?: number) => {
    const commonParams = useCommonTargetingParams();
    const locationParams = useLocationParams();
    const { page } = useCurrentSearchRouteParams();

    return React.useMemo(
        () => ({
            page_type: 'home',
            pos: position,
            page_index: position === Position.TOP ? 1 : page,
            slot_index: isNil(slotIndex) ? null : `${slotIndex}`,
            ...prefixTargetingParams(locationParams),
            ...commonParams,
        }),
        [position, page, locationParams, slotIndex, commonParams],
    );
};

export default useHomeTargetingParams;
