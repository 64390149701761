import * as React from 'react';
import { useRouter } from 'react-true-router';

import Header from './header';

const PaymentBankTrasferHeader = () => {
    const router = useRouter();

    const onBack = React.useCallback(() => {
        router.pop();
    }, [router]);

    return <Header onBack={onBack} />;
};

export default PaymentBankTrasferHeader;
