import searchReducer, {
    showFavorites,
    showSearchResults,
    setAdvancedFiltersVisibility,
    showUnsubscribeBanner,
    Actions,
    setWPTO,
    setPageParameters,
    resetPageParameters,
    showRecommendations,
    setRemarketedListing,
    clearRemarketedListings,
    saveScrollYPosition,
    restoreScrollYPosition,
    resetScrollYPosition,
    setRemarketedListings,
    showMap,
    showCommute,
} from './search';
import { PageParameters } from './search';
import {
    fetchRecommendations,
    forceFetchRecommendations,
    clearRecommendations,
    recommendationsMiddleware,
} from './recommendations';
import type { Params as RecommendationsParams } from './recommendations';
import { updateURL, enableURLUpdater, disableURLUpdater } from './urlUpdater';
import type { PageRule } from './pageRule';
import { useRemarketedListing, useShowRemarketedListing } from './hooks';

export {
    Actions,
    updateURL,
    enableURLUpdater,
    disableURLUpdater,
    showFavorites,
    showSearchResults,
    setAdvancedFiltersVisibility,
    showUnsubscribeBanner,
    setWPTO,
    setPageParameters,
    resetPageParameters,
    fetchRecommendations,
    forceFetchRecommendations,
    clearRecommendations,
    recommendationsMiddleware,
    showRecommendations,
    setRemarketedListing,
    clearRemarketedListings,
    saveScrollYPosition,
    restoreScrollYPosition,
    resetScrollYPosition,
    setRemarketedListings,
    showMap,
    showCommute,
    useRemarketedListing,
    useShowRemarketedListing,
};
export type { PageRule, RecommendationsParams, PageParameters };

export default searchReducer;
