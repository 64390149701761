import * as React from 'react';
import { GTMDataLayerVariables, Triggers } from 'strat/gtm';

import type { FullAd, ProductPackageOffer } from 'horizontal/types';
import { usePageViewTrigger } from 'horizontal/gtm';

import useGlobalGTMVars from '../useGlobalGTMVars';
import { getAdVars } from '../utils';

import PageType from './pageType';

type Props = {
    readonly packages: Array<ProductPackageOffer> | null | undefined;
    readonly ad: FullAd | null | undefined;
};

const GTMSelectSinglePackageDataLayer = ({ packages, ad }: Props) => {
    const globalVars = useGlobalGTMVars();

    const variables = {
        ...globalVars,
        ...getAdVars(ad),
        product_id: packages ? packages.map((pack) => pack.packageOfferID) : [],
        page_type: PageType.SINGLE_PACKAGE,
    } as const;

    usePageViewTrigger(Triggers.SELECT_SINGLE_PACKAGE_VIEW, variables, !packages || !ad);

    return <GTMDataLayerVariables variables={variables} />;
};

export default GTMSelectSinglePackageDataLayer;
