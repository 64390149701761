import * as React from 'react';

import LeafCategory from './leafCategory';
import NestedCategory from './nestedCategory';
import type { CategoryLinkProps } from './types';

type Props = CategoryLinkProps & {
    readonly noGrid?: boolean;
    readonly noWrapText?: boolean;
};

const CategoryDropdownEntry = ({ category, onClick, noGrid, noWrapText }: Props) => {
    const hasChildren = category.children.length > 0;

    if (hasChildren) {
        return (
            <NestedCategory
                isMoreCategoriesItem={noGrid}
                category={category}
                onClick={onClick}
                noWrapText={noWrapText}
            />
        );
    }

    return (
        <LeafCategory
            category={category}
            onClick={onClick}
            // @ts-expect-error - TS2322 - Type '{ category: Category; onClick: (arg1: SyntheticEvent<any, Event>) => void; noGrid: boolean | undefined; noWrapText: boolean | undefined; }' is not assignable to type 'IntrinsicAttributes & CategoryLinkProps & { readonly noWrapText?: boolean | undefined; }'.
            noGrid={noGrid}
            noWrapText={noWrapText}
        />
    );
};

export default CategoryDropdownEntry;
