import FilterValues from 'strat/search/filterValues';
import type { LocationNodeData } from 'strat/property';

export const getAlertParams = (property: any, getState: any) => {
    const params = property ? propertyAlertParams(property) : getState().search.url.params;

    /**
     * Email alerts are created based on the slug of the location
     */
    return {
        ...params,
        locations:
            params.locations.length === 0 && params.city
                ? [params.city]
                : params.locations.map((location: LocationNodeData) => location.slug),
    };
};

/* generate alert params according to https://www.pivotaltracker.com/story/show/159265320
 * Quote from that story:
 *
 * if user generates an email lead, an auto email alert would be set up based on the following criteria:
 * - Location: Loc 3 (the current property's loc3 location)
 * - Property type: Same as that of the current property
 * - Bed count:  If applicable on the current property type, set it to be the same as that of the current property.
 */
export const propertyAlertParams = (property: any) => {
    const category = property.category[property.category.length - 1].slug;
    const cityLevel = CONFIG.build.CITY_LEVEL;
    const neighborhoodLevel = cityLevel + 2;
    const neighborhood =
        property.location[Math.min(property.location.length - 1, neighborhoodLevel)];

    return {
        purpose: property.purpose,
        locations: [neighborhood],
        category,
        rentFrequency: property.rentFrequency || FilterValues.rentFrequency.default,
        beds: property.rooms ? [property.rooms] : [],
        baths: [],
        keywords: [],
        agencies: [],
        price: null,
        area: null,
    };
};
