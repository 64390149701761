import * as React from 'react';
import isNil from 'lodash/isNil';
import { prefixTargetingParams } from 'strat/tagmanager';
import settings from '@app/branding/settings';

import { useSearchHitsLayout } from 'horizontal/layout';
import { useCurrentSearchRouteParams } from 'horizontal/search/routing';
import type { LiteCategory } from 'horizontal/types';
import SearchHitsLayout from 'horizontal/branding/searchHitsLayout';
import { PaginationType } from 'horizontal/types';

import useLocationParams from './useLocationParams';
import { Position } from './types';
import { categoryParams, currencyMapping } from './utils';
import { getActiveRangesPrice } from './valueRanges';
import useSearchExtraFieldsParams from './useSearchExtraFieldsParams';
import useCommonTargetingParams from './useCommonTargetingParams';

const HitsLayoutMapping = Object.freeze({
    [SearchHitsLayout.GRID]: 'mosaic',
    [SearchHitsLayout.LIST]: 'list',
});

const useSearchTargetingParams = (
    position: Values<typeof Position>,
    slotIndex: number | null | undefined,
    categories: Array<LiteCategory>,
) => {
    const commonParams = useCommonTargetingParams();
    const locationParams = useLocationParams();
    const { extraFields, freeTextQuery, page } = useCurrentSearchRouteParams();
    const hitsLayout = useSearchHitsLayout();
    const extraFieldsParams = useSearchExtraFieldsParams(categories.slice(-1)[0], extraFields);
    const currency = currencyMapping(settings.baseCurrencyName);

    return React.useMemo(
        () => ({
            page_type: 'results',
            pos: position,
            slot_index: isNil(slotIndex) || slotIndex < 0 ? null : `${slotIndex}`,
            page_index:
                settings.paginationType === PaginationType.INFINITE_SCROLLING &&
                position === Position.TOP
                    ? 1
                    : page,
            search_term: freeTextQuery,
            visualisation: HitsLayoutMapping[hitsLayout],
            // @ts-expect-error - TS2345 - Argument of type 'CategoryFieldFilterValue | undefined' is not assignable to parameter of type 'Range'.
            [`price_band_${currency}`]: getActiveRangesPrice(extraFields?.price),
            ...prefixTargetingParams(locationParams),
            ...prefixTargetingParams(categoryParams(categories, extraFieldsParams?.make)),
            ...extraFieldsParams,
            ...commonParams,
        }),
        [
            position,
            page,
            slotIndex,
            freeTextQuery,
            hitsLayout,
            extraFields,
            locationParams,
            categories,
            extraFieldsParams,
            currency,
            commonParams,
        ],
    );
};

export default useSearchTargetingParams;
