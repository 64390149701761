import pick from 'lodash/pick';

import { Route } from 'react-true-router';
import type { RoutingContextWithMiddlewares } from 'strat/app';
import { redirectToURISafely } from 'strat/routing';
import type { EnhancedLocation } from 'react-true-router/location';
import Page from 'strat/pages/page';
import RouteNames from 'strat/routes/routeNames';
import { selectIsUserLoggedIn } from 'strat/user/selectors';
import { isKeycloakEnabled } from 'strat/auth/keycloak/config';

type RouteParams = {
    readonly externalRedirectPath?: string;
};

class LoginRoute extends Route {
    constructor() {
        super(RouteNames.LOGIN, [['^/account(?:(?:\\?.*)?(?:#.*)?)?$']]);
    }

    createURL(params: RouteParams): EnhancedLocation {
        const search = pick(params, ['externalRedirectPath']);
        return { pathname: '/account', search };
    }

    onEnter(context: RoutingContextWithMiddlewares): void {
        const externalRedirectPath = context.match.params.externalRedirectPath || '/';

        const isUserLoggedIn = selectIsUserLoggedIn(context.redux.store.getState());
        if (isUserLoggedIn) {
            redirectToURISafely(context, externalRedirectPath);
            return;
        }

        const page = isKeycloakEnabled() ? Page.LOGIN : Page.EXPRESS_LOGIN;
        context.rendering.renderPage(page, { externalRedirectPath });
    }
}

export default new LoginRoute();
