import * as React from 'react';
import { Flex } from 'strat/components';
import { InternalError } from 'strat/error';

import InternalErrorHead from './internalErrorHead';

const InternalErrorPage = {
    head: () => <InternalErrorHead />,
    body: () => (
        <Flex alignCenter justifyCenter stretchWidth stretchHeight fillContainer>
            <InternalError />
        </Flex>
    ),
} as const;

export default InternalErrorPage;
