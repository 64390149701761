import * as React from 'react';
import { ViewSections } from 'strat/gtm';
import { Flex } from 'strat/components';
import SearchHitsLayout from '@app/branding/searchHitsLayout';

import AdCard from '@app/adCard/adCard';
import styles from '@app/ad/styles/adsCarouselCompact.cssm';
import type { LiteAd } from 'horizontal/types';

import { AdsSeeMoreButton } from './adsSeeMoreButton';

const renderSeeMore = (onClickSeeMore: () => void) => {
    return (
        <div className={styles.seeMoreContainer} key="see-more">
            <AdsSeeMoreButton onClick={onClickSeeMore} />
        </div>
    );
};

type Props = {
    ads: ReadonlyArray<LiteAd>;
    seeMoreLimit: number;
    handleSeeMore?: () => void;
};

const AdsCarouselCompact = ({ ads, seeMoreLimit, handleSeeMore }: Props) => {
    const entries = React.useMemo(
        () =>
            ads
                .slice(...(handleSeeMore && seeMoreLimit ? [0, seeMoreLimit] : []))
                .map((ad) => (
                    <div key={ad.id} className={styles.listingContainer} aria-label="Listing">
                        <AdCard
                            ad={ad}
                            viewType={SearchHitsLayout.GRID}
                            viewSection={ViewSections.BODY}
                        />
                    </div>
                ))
                .concat(
                    handleSeeMore && ads.length >= seeMoreLimit
                        ? [renderSeeMore(handleSeeMore)]
                        : [],
                ),
        [ads, handleSeeMore, seeMoreLimit],
    );

    return <Flex className={styles.container}>{entries}</Flex>;
};

export default AdsCarouselCompact;
