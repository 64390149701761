import { useSelector } from 'react-redux';

import {
    selectActiveUser,
    selectUserExternalID,
    selectUserIdentityProviders,
    selectUserEmail,
    selectUserPhoneNumber,
    selectIsPasswordlessLogin,
} from './selectors';

export const useActiveUser = () => useSelector(selectActiveUser);

export const useActiveUserExternalID = () => useSelector(selectUserExternalID);

export const useActiveUserIdentityProviders = () => useSelector(selectUserIdentityProviders);

export const useActiveUserEmail = () => useSelector(selectUserEmail);

export const useActiveUserPhoneNumber = () => useSelector(selectUserPhoneNumber);

export const useIsPasswordlessLogin = (): boolean => useSelector(selectIsPasswordlessLogin);
