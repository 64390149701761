import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import { ProductPurchaseStatus } from 'horizontal/packages/types';
import { StratAPI as StratHorizontalAPI } from 'horizontal/api';
import { ITEMS_PER_PAGE } from 'horizontal/agencyPortal/utils';

export type AgencyProductPurchaseParams = {
    readonly agencyExternalID: string;
    readonly status?: Values<typeof ProductPurchaseStatus>;
    readonly agentExternalID?: string;
    readonly categoryExternalID?: string;
};

export type ProductPurchaseAgentsParams = {
    agencyExternalID: string;
    packageID: string;
    page: number;
};

const factory = new FetcherFactory(
    ['agencyPortal', 'agencyProductPurchases'],
    (params: AgencyProductPurchaseParams | null | undefined, state: any) => {
        if (!params?.agencyExternalID) {
            throw new Error('Missing agency ID.');
        }

        return new StratAPI(state.i18n.language).agencyProductPurchases({
            agencyExternalID: params?.agencyExternalID,
            status: params?.status,
            agent_external_id: params?.agentExternalID,
            category_external_id: params?.categoryExternalID,
        });
    },
    { successCodes: [200, 404], skipParamsCheck: true },
);

const fetchAgencyProductPurchases = factory.creator();
const agencyProductPurchases = factory.reducer();

const consumedProductAgentsFactory = new FetcherFactory(
    ['agencyPortal', 'productPurchaseAgents'],
    ({ agencyExternalID, packageID, page }: ProductPurchaseAgentsParams, state: any) => {
        return new StratHorizontalAPI(state.i18n.language).productPurchaseAgents(
            agencyExternalID,
            packageID,
            page || 1,
            ITEMS_PER_PAGE,
        );
    },
    {
        catchServerErrors: true,
        skipParamsCheck: true,
    },
);
const fetchProductPurchaseAgents = consumedProductAgentsFactory.creator();
const productPurchaseAgentsReducer = consumedProductAgentsFactory.reducer();

export {
    fetchAgencyProductPurchases,
    agencyProductPurchases,
    fetchProductPurchaseAgents,
    productPurchaseAgentsReducer,
};
