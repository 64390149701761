import FetcherFactory from 'strat/fetcher';
import { StratAPI } from 'strat/api';

import { GlobalState } from 'horizontal/state';

const factory = new FetcherFactory(
    ['myProfile'],
    ({ externalID, agencyExternalID }, state: GlobalState) => {
        if (agencyExternalID) {
            return new StratAPI(state.i18n.language).agency(agencyExternalID);
        }
        return new StratAPI(state.i18n.language).user(externalID, 'lite');
    },
    {
        catchServerErrors: true,
        successCodes: [200],
    },
);

const fetchMyProfile = factory.creator();
const myProfileReducer = factory.reducer();

const clearMyProfile = () => ({
    type: factory.actions.clear,
});

export { fetchMyProfile, clearMyProfile };

export default myProfileReducer;
