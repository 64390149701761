import * as React from 'react';

import defaultStyles from './styles/toggleButton.cssm';

type Props = {
    onClick: (event: React.SyntheticEvent<any>) => void;
    checked: boolean;
    value: boolean;
    styles?: Partial<Record<any, string>>;
};

const ToggleButton = (props: Props) => {
    const styles = props.styles || defaultStyles;

    return (
        <div
            className={
                props.checked
                    ? `${styles.toggleContainer} ${styles.checked}`
                    : styles.toggleContainer
            }
            onClick={props.onClick}
        >
            <input
                type="checkbox"
                readOnly
                className={styles.toggleInput}
                checked={props.checked}
                // @ts-expect-error - TS2322 - Type 'boolean' is not assignable to type 'string | number | readonly string[] | undefined'.
                value={props.value}
            />
            <div className={styles.slider} />
        </div>
    );
};

export default ToggleButton;
